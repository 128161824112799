import { Dropdown } from "primereact/dropdown"
import { Controller } from "react-hook-form"
import { HeaderSheet } from "./HeaderSheet"

export const DropDownSheet = ({question, control, numberQuestion, readOnly}) => {
  return(
    <div className='py-4 px-4 bg-gray-100 shadow border-2 flex flex-col page-break' key={question.id}>
      <HeaderSheet numberQuestion={numberQuestion} question={question}/>
      <Controller
        control={control}
        rules={{ required: {
          value: question.isRequired,
          message: `La pregunta ${numberQuestion} es Requerida`
        }}}
        render={({
            field: { onChange, value },
            fieldState: { error },
        }) => {
          return(
            <div>
              <Dropdown
                  disabled={readOnly}
                  options={question.options}
                  optionLabel="name"
                  value={value}
                  // onChange={(e) => onChangeWithOther(onChange, e.value, `question_${index+1}`, error)}
                  onChange={onChange} 
                  showClear
                  className={`w-1/2 ${error ? 'p-invalid' : ''}`}
              />
            </div>
          )}}
        name={`question_${question.id}`}
      />
    </div>
  )
}