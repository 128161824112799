import { apiService } from "core/services/ApiService";

const searchCourses = (criteria) => {
  return apiService.Post("customer/api/Course/Search", criteria);
};

const saveCourses = (data) => {
  return apiService.Post("customer/api/Course/Save", data);
};

const getCourseById = (courseId) => {
  return apiService.Get("customer/api/Course/GetCourseById?id=" + courseId);
};

const generateReport = (payload) => {
  return apiService.Post("customer/api/Course/GenerateReport", payload, {
    responseType: 'blob',
  })
};

const getDataMaster = () => {
  return apiService.Post("customer/api/Course/GetDataMaster");

};

const deleteCourse = (courseId) => {
  return apiService.Delete("customer/api/Course/Delete?id=" + courseId);
};

export const CoursesService = {
  searchCourses,
  saveCourses,
  getCourseById,
  deleteCourse,
  getDataMaster,
  generateReport,

}