
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Menu } from 'primereact/menu';
import moment from 'moment';
import { Checkbox } from 'primereact/checkbox';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PaymentCouseAction } from 'features/coursesManagment/store/actions/PaymentCourseAction';
import { useClaimPaymentCourse } from 'features/coursesManagment/hooks/useClaimPaymentCourse';
import { Link } from 'react-router-dom';
import { CoreConstants } from 'core/commons/CoreConstants';

export const TableComponent = () => {

  const advancedFilter = useSelector((state) => state.paymentCourseReducer.advancedFilter);
  const { data } = useSelector((state) => state.paymentCourseReducer.paymentCourses);
  // const listUsers = useSelector((state) => state.paymentCourseReducer.users.data?.users);
  const { isDeleted } = useSelector((state) => state.paymentCourseReducer.studentSelected);

  const history = useHistory()

  const { isView ,isEdit, isDelete } = useClaimPaymentCourse();

  const columns = [
    { field: 'source', header: 'Proceso'},
  ];

  const [selectedColumns, setSelectedColumns] = useState(columns);
  const actionsHeader = useRef(null);
  const menu = useRef(null);
  const [showConfirmDialog, setshowConfirmDialog] = useState(false);
  const [paymentSelected, setPaymentSelected] = useState(null);
  const [isFirstLoading, setFirstLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState(null);
  const [expandedRowsAnid, setExpandedRowsAnid] = useState(null);

  const dispatch = useDispatch()

  useEffect(() => {
    if (isDeleted) {
        setshowConfirmDialog(false);
        dispatch(PaymentCouseAction.resetAfterSaved());
        dispatch(PaymentCouseAction.search());
    }
}, [isDeleted])

  let items = [
    {
        label: 'Editar',
        icon: 'pi pi-pencil',
        command: (e) => {
            history.push('/curso/pagos/' + paymentSelected.id);
        }
    },
    {
        label: 'Eliminar',
        icon: 'pi pi-trash',
        command: (e) => {
            setshowConfirmDialog(true);
        }
    },
  ];
  if (!isEdit)
    items = items.filter(c => c.label !== 'Editar');
  if (!isDelete)
    items = items.filter(c => c.label !== 'Eliminar');


  const getViewField = (rowData) => {

        if (isView)
          return <Link className="underline text-blue-700" to={{ pathname: `/curso/pagos/visualizar/` + rowData.id }} >{rowData.studentName}</Link>
        else
          return rowData.studentName;
    }

  const actionBodyTemplate = (rowData) => {
    if(isEdit||isDelete){
      return <i
          key={rowData.id}
          className="pi pi-ellipsis-v cursor-pointer"
          onClick={(event) => {
              setPaymentSelected(rowData);
              menu.current.toggle(event)
          }
          }></i>
    }else{
      return <div></div>
    }
  }

  const onColumnToggle = (e, item) => {
    let _selectedColumns = [...selectedColumns];

    if (e.checked)
        _selectedColumns.push(item);
    else
        _selectedColumns = _selectedColumns.filter(c => { return c.field !== e.value });

    setSelectedColumns(_selectedColumns);
    localStorage.setItem("paymentCourse-filter", JSON.stringify(_selectedColumns));
}

const getColumns = () => {
    let array = [];

    columns.map((item) => {
        const column = <div key={item.field} className="p-field-checkbox py-1">
            <Checkbox
                inputId={item.field}
                name="column"
                value={item.field}
                onChange={(e) => onColumnToggle(e, item)}
                checked={selectedColumns.some((x) => x.field === item.field)}
            />
            <label className="ml-1" htmlFor={item.field}>{item.header}</label>
        </div>
        array.push(column);
    });
    return array;
};

const confirmDelete = () => {
  dispatch(PaymentCouseAction.deletedPayment(paymentSelected.id))
}

const cancelDelete = () => {
    setshowConfirmDialog(false);
}

const [rows, setRows] = useState(10)

const onPageChange = (e) => {
    setRows(e.rows);
    advancedFilter.page = e.page + 1;
    advancedFilter.pageSize = e.rows;
    dispatch(PaymentCouseAction.updateFilter(advancedFilter));
    // dispatch(PaymentCouseAction.search());
}

const onRowExpand = (event) => {
    // toast.current.show({severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000});
}


const getCreationDate = (rowData) => { return moment(rowData).format(CoreConstants.Format.LOCALE); }


const rowFirstLevel = (e, nameRow) => {
  if(nameRow!=='issueDate'){
    return <div>{e.data[nameRow]}</div>
  }else{
    return <div>{getCreationDate(e.data[nameRow])}</div>
  }
}

const getCreationPrice = (row) => { return "S/." + row.toFixed(2)}

const rowExpansionTemplate = ({children}) => {
    return (
      <div className="tasks-subtable">
        <DataTable value={children} header={null} className='ml-0' 
            // lazy loading={loading}
          emptyMessage="No se encontraron tareas">
          <Column field="studentName" header="Nombre" body={(rowData) => getViewField(rowData)} />
          <Column field="issueDate" header="Fecha de Inicio" body={(e)=> getCreationDate(e.issueDate)}/>
          <Column field="dueDate" header="Fecha de Fin" body={(e)=> getCreationDate(e.dueDate)}/>
          <Column field="amount" header="Precio" body={(e)=> getCreationPrice(e.amount)}/>
          <Column header={<div></div>} body={actionBodyTemplate} ></Column>
        </DataTable>
      </div>
    );
  };


  return (
    <>
    <div className="table-main table-contact mb-3 mt-2">
                    <DataTable
                        value={data}
                        emptyMessage="No se encontraron datos"
                        stripedRows
                        // rows={5}
                        editMode="row"
                        dataKey="key" //TODO: Cambiar luego por id
                        rowHover
                        size="small"
                        responsiveLayout="scroll"
                        rowExpansionTemplate={rowExpansionTemplate}
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        onRowExpand={(e) => onRowExpand(e.data)}
                        lazy
                    >
                        {/* <Column expander style={{ width: "2em" }} hidden={isNewProject} />   */}
                        <Column expander style={{ width: '3em' }} />
                        <Column field="courseName" header="Alumno" body={(e)=>rowFirstLevel(e,'courseName')}/>
                        <Column field="noHay" header="Fecha de Vencimiento de Pago" body={(e)=>rowFirstLevel(e,'noHay')}/>
                        <Column header="Monto"/>
                        {/* <Column header={<div></div>} body={actionBodyTemplate} ></Column> */}
                        {/* {columnComponents}  */}
                        {/* <Column header={<div></div>} hidden={isNewProject} body={RowAddTasks}></Column> */}
                    </DataTable>
                </div>


            {/* <Paginator
                key={"pag-1"}
                className="paginator-custom mt-4"
                rows={rows}
                first={(data?.currentPage - 1) * rows}
                rowsPerPageOptions={[10,20,30]}
                totalRecords={data?.rowCount}
                onPageChange={onPageChange}
            ></Paginator> */}

            <OverlayPanel ref={actionsHeader} showCloseIcon style={{ width: '300px' }}>{getColumns()}</OverlayPanel>

            <Menu model={items} popup ref={menu} id="popup_menu" />
            <ConfirmDialog
                visible={showConfirmDialog}
                onHide={cancelDelete}
                message="¿Está seguro que desea eliminar el registro?"
                header="Eliminar"
                icon="pi pi-trash" 
                accept={confirmDelete}
                reject={cancelDelete}
            />
    </>
  )
}
