export class Piner {
  constructor({values, fieldNameConstant}){
    this.fieldNameConstant = fieldNameConstant;
    this.values = values;
  }

  getPiner(){
    return { 
      field: this.fieldNameConstant,
      value: this.values[this.fieldNameConstant].pinerValue,
      formValue: this.values[this.fieldNameConstant].value,
      searchValue: this.values[this.fieldNameConstant].searchValue,
    }
  }
}