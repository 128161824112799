/* eslint-disable import/no-anonymous-default-export */

import { PublicConstants } from "features/public/commons/PublicConstants";


const initialState = {
  survey: {
    data: null,
    activity: null,
    isLoading: false,
    error: false
  },
  answers: {
    isSaving: false,
    isSaved: false,
    message: null,
    error: false
  },
  dataMaster: {
    isLoading: false,
    surveySectionTypes: [],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PublicConstants.Accion.SurveyPublic.GET_SURVEY_BY_GUID:
      return {
        ...state,
        survey: {
            data: action.payload.data,
            activity: action.payload.activity,
            isLoading: action.payload.isLoading,
            error: action.payload.error
        }
      };
    case PublicConstants.Accion.SurveyPublic.SAVE_SURVEY_ANSWER:
      return {
        ...state,
        answers: {
            isSaving: action.payload.isSaving,
            isSaved: action.payload.isSaved,
            message: action.payload.message,
            error: action.payload.error
        }
      };

    case PublicConstants.Accion.SurveyPublic.GET_DATA_MASTER:
      return {
        ...state,
        dataMaster: {
            surveySectionTypes: action.payload.surveySectionTypes,
            isLoading: action.payload.isLoading,
        }
      };
    
    default: 
    return state;
  }

}