/* eslint-disable import/no-anonymous-default-export */
import { SubscriptionConstants } from "features/subscription/commons/SubscriptionConstants";

const initialState = {
  dataMaster: {
    isLoading: false,
    options: [],
    dataOptions: {}
  },
  solution: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false
  },
  solutions: {
    isLoading: false,
    data: null
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'name',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {}
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SubscriptionConstants.Accion.Solution.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
      case SubscriptionConstants.Accion.Solution.UPDATE_FILTER:
        return {
            ...state,
            advancedFilter: state.advancedFilter
        };

    case SubscriptionConstants.Accion.Solution.SEARCH_SOLUTIONS:
        return {
            ...state,
            solutions: {
                isLoading: action.payload.isLoading,
                data: action.payload.data
            }
        };
    case SubscriptionConstants.Accion.Solution.GET_SOLUTION_BY_ID:
      return {
          ...state,
          solution: {
              data: action.payload.data,
              isSaving: false,
              isLoading: action.payload.isLoading
          }
        };

    case SubscriptionConstants.Accion.Solution.SAVE_SOLUTION:
        return {
            ...state,
            solution: {
                data: action.payload.currentSolution,
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };

    case SubscriptionConstants.Accion.Solution.DELETE_SOLUTION:
        return {
            ...state,
            solution: {
                isDeleted: action.payload.isDeleted
            }
        };

    case SubscriptionConstants.Accion.Solution.RESET_AFTER_SAVED:
        return {
            ...state,
            solution: {
                data: null,
                isSaving: false,
                isSaved: false,
                isLoading: false,
                isDeleted: false
            }
        };

      case SubscriptionConstants.Accion.Solution.GET_DATA_MASTER:
          return {
              ...state,
              dataMaster: {
                  options: action.payload.options,
                  dataOptions: action.payload.data,
                  isLoading: action.payload.isLoading
              }
          };

      case SubscriptionConstants.Accion.Solution.DOWNLOAD_REPORT_STATUS:
          return {
              ...state,
              solution: {
                  ...state,
                  isLoading: action.payload.isLoading,
              },
          };
      
      default: 
      return state;
  }

}