import { useForm } from 'react-hook-form';

const useProceseSheet = () => {

  let defaultValuesGeneral = {
    producedBy: null,
    dateElaboration: null,
    lastUpdate: null,
    version: null,
    updateBy: null,
    status: null,
    flowId: null,
  };

  const {
    // register,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({ defaultValuesGeneral });

  const valuesFields = watch();
  const existError = Object.keys(errors).length > 0

  const validateLength = (value) => {
    if (typeof value === 'string') {
      return value.replace(/<[^>]+>/g, '')?.length;
    } else if (value?.htmlValue) {
      return value.htmlValue.replace(/<[^>]+>/g, '').length;
    }
    return '0';
  };

  return {
    control,
    errors,
    valuesFields,
    existError,
    setValue,
    handleSubmit,
    validateLength,
  }
}

export default useProceseSheet