import { apiService } from "core/services/ApiService";
const currentModule = "customer/api/BusinessSheet";
// const currentModule = 'customer/api/Business/Report';

const search = ({ fields = {} }) => {
  let initialFields = {
    page: 1,
    pageSize: 10,
    columnOrder: "name",
    order: "asc",
    ...fields,
  };

  return apiService.Post(`${currentModule}/SearchBusinessSheet`, initialFields);
};

const generateReport = (payload) => {
  return apiService.Post(`${currentModule}/GenerateReport`, payload, {
    responseType: "blob",
  });
};

const dataMaster = () => {
  return apiService.Post(`${currentModule}/GetDataMaster`);
};

const remove = (id) => {
  return apiService.Delete(`${currentModule}/DeleteBusinessSheet?id=${id}`);
};

const getById = (id) => {
  return apiService.Get(`${currentModule}/GetBusinessSheetById?id=${id}`);
};

const getListControls = () => {
  return apiService.Get(`${currentModule}/ListControls`);
};

const save = (data) => {
  return apiService.Post(`${currentModule}/SaveBusinessSheet`, data);
};

const share = (id) => {
  return apiService.Post(`${currentModule}/Share?id=${id}`);
};

const close = (id) => {
  return apiService.Post(`${currentModule}/Close?businessSheetId=${id}`);
};

const businessSheetsTemplate = () => {
  return apiService.Get(`${currentModule}/GetBusinessSheetsTemplate`);
};

export const BusinessSheetService = {
  search,
  generateReport,
  dataMaster,
  remove,
  getById,
  getListControls,
  save,
  share,
  close,
  businessSheetsTemplate,
};
