import { apiService } from "core/services/ApiService";

const searchHome = (criteria) => {
  return apiService.Post("customer/api/Task/Dashboard/Search", criteria);
}

const getOptions = () => {
  return apiService.Get("customer/api/Task/Dashboard/GetDataMaster");
}

const getIndentifiers = () => {
  return apiService.Get("management/api/DashboardIndicator");
}

const saveHome = (data) => {
  return apiService.Post("customer/api/JobPosition/Save", data);
}

const getHomeById = (taskId) => {
  return apiService.Get("customer/api/JobPosition/GetJobPositionById?id=" + taskId);
}

const generateReport = (payload) => {
  return apiService.Post("customer/api/Task/GenerateReport", payload, {
    responseType: 'blob',
  })
}

const deleteHome = (taskId) => {
  return apiService.Delete("customer/api/JobPosition/Delete?id=" + taskId);
}

const searchSummary = (payload) => {
  return apiService.Post("customer/api/Task/Dashboard/Summary", payload);
}

export const HomeService = {
  searchHome,
  saveHome,
  getHomeById,
  generateReport,
  deleteHome,
  getOptions,
  searchSummary,
  getIndentifiers
};