import { apiService } from "core/services/ApiService";

// En proceso 

const searchIndicators = (criterio) => {
  return apiService.Post("management/api/Indicator/SearchIndicators", criterio);
}

const generateReport = (payload) => {
  return apiService.Post("management/api/Indicator/GenerateReport", payload, {
    responseType: 'blob',
  })
}

const saveIndicator = (data) => {
  return apiService.Post("management/api/Indicator/Save", data);
}

const saveAndGenerateIndicator = (data) => {
  return apiService.Post("management/api/Indicator/SaveAndGenerate", data);
}

const getIndicatorById = (indicatorId) => {
  return apiService.Get("management/api/Indicator/GetIndicatorById?indicatorId=" + indicatorId);
}

const getDataMaster = () => {
  return apiService.Post("management/api/Indicator/GetDataMaster");
}

const getIndicatorUnitMeasure = () => {
  return apiService.Get("management/api/ApplicationSetting/GetApplicationSettingsUnitsOfMeasure");
}

const getIndicatorByVersion = (indicadorId, versionNumber) => {
  return apiService.Get(`management/api/Indicator/GetIndicatorVersionByVersionNumber?indicatorId=${indicadorId}&versionNumber=${versionNumber}`);
}

const saveAndGenerateVersion = (data) => {
  return apiService.Post("management/api/Indicator/SaveAndGenerateVersion", data);
}

const downloadTemplate = (fields ={}) =>{
  return apiService.Post('management/api/Indicator/BulkUpload/DownloadTemplate', fields, {
    responseType: 'blob',
  });
}

const uploadBatchInfo = (file) =>{
  return apiService.Post('management/api/Indicator/BulkUpload/UploadBatchInfo', file);
}

const copyIndicator = (indicatorId) => {
  return apiService.Post("management/api/Indicator/Copy?id=" + indicatorId);
}

const saveIndicatorValues = (data) => {
  return apiService.Post("management/api/Indicator/SaveIndicatorValues", data);
}

const deleteIndicator = (indicatorId) => {
  return apiService.Delete("management/api/Indicator/Delete?id=" + indicatorId);
}


export const IndicatorsService = {
  searchIndicators,
  generateReport,
  saveIndicator,
  getIndicatorById,
  getDataMaster,
  getIndicatorUnitMeasure,
  getIndicatorByVersion,
  saveAndGenerateVersion,
  downloadTemplate,
  copyIndicator,
  uploadBatchInfo,
  saveIndicatorValues,
  deleteIndicator,
  saveAndGenerateIndicator,
};