import React, { useState } from 'react'
import { bar, line, barh, pie, doughnut } from 'shared/assets/img/images';
import { Chart } from 'primereact/chart';
// import { useSelector } from 'react-redux';
// import { Skeleton } from 'primereact/skeleton';
// import moment from 'moment';


const SurveyAnalysisModalChart = ({typeModal, labels, data}) => {
    const backgroundColor = ['#F87171', '#FB923C', '#FBBF24', '#A3E635', '#60A5FA', '#9CA3AF', '#818CF8', '#F472B6', '#22D3EE', '#34D399', '#A3E635', '#A8A29F', '#FACC15', '#F87171', '#FB923C', '#FBBF24', '#A3E635', '#60A5FA', '#9CA3AF', '#818CF8', '#F472B6', '#22D3EE', '#34D399', '#A3E635', '#A8A29F', '#FACC15']
    const hoverBackgroundColor = ['#E76060', '#EA812B', '#EAAE13', '#92D524', '#5094E9', '#8B929E', '#707BE7', '#E361A5', '#11C2DD', '#23C288', '#92D524', '#97918E', '#E9BB04', '#E76060', '#EA812B', '#EAAE13', '#92D524', '#5094E9', '#8B929E', '#707BE7', '#E361A5', '#11C2DD', '#23C288', '#92D524', '#97918E', '#E9BB04',]
    const [selectedChart, setSelectedChart] = useState('barv')

    const [barData] = useState({
        labels,
        datasets: [
            {
                label: 'Indicadores Bar',
                backgroundColor,
                data,
                // barThickness: 30,
                maxBarThickness: 30,
            },
        ]
    });

    const [lineData] = useState({
        labels,
        datasets: [
            {
                label: 'Indicadores Line',
                backgroundColor: ['#EC3C1D'],
                data,
                tension: 0,
                borderColor: '#EC3C1D'
            },
        ]
    });

    const [pieData] = useState({
        labels,
        datasets: [
            {
                data,
                backgroundColor,
                hoverBackgroundColor
            }
        ]
    });

    const [doughnutData] = useState(pieData)

    const getLightTheme = () => {
        let barOptions = {
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 1.9,
            plugins: {
                legend: {
                    /* TODO: Poner varios titulos legends por cada valor */
                    labels: {
                        color: '#495057',
                    },
                    display: false,
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057',
                        
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057',
                        // stepSize: 1,
                        // suggestedMax: 10
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            },
        };
    
    let lineOptions = { ...barOptions, aspectRatio: 1.9}
    let barhOptions = { ...barOptions, indexAxis: 'y' }
    let pieOptions = { ...barOptions, aspectRatio: 1.6, plugins: {
        legend: {
            labels: {
                color: '#495057'
            },
        }
    }}
    let doughnutOptions = { ...pieOptions }

    return {
            barOptions,
            barhOptions,
            lineOptions,
            pieOptions,
            doughnutOptions
        } 
    }

  const { barOptions, barhOptions, lineOptions, pieOptions, doughnutOptions } = getLightTheme();

  return (
    <>
        <div className='w-full h-full'>
            {
                selectedChart === 'barh' && (
                    <div className="" style={{width: '100%'}}>
                        <Chart type="bar" data={barData} options={barhOptions} />
                    </div>
                    )
            }  
            {
                selectedChart === 'barv' && (
                    <div className="" style={{width: '100%'}}>
                        <Chart type="bar" data={barData} options={barOptions} />
                    </div>
                    )
            }  
            {
                selectedChart === 'line' && (
                    <div className="" style={{width: '100%'}}>
                        <Chart type="line" data={lineData} options={lineOptions} />
                    </div>
                    )
            } 
            {
                selectedChart === 'pie' && (
                    <div className="" style={{width: '100%'}}>
                        <Chart type="pie" data={pieData} options={pieOptions} />
                    </div>
                    )
            } 
            {
                selectedChart === 'doughnut' && (
                    <div className="" style={{width: '100%'}}>
                        <Chart type="doughnut" data={doughnutData} options={doughnutOptions} />
                    </div>
                    )
            } 
        </div>
        <div className='flex items-center gap-x-5 mt-1'>
            <div className='flex'>
                <img 
                    src={bar} alt="barv" 
                    className={`w-24 cursor-pointer hover:bg-gray-100 hover:shadow-lg rounded ${selectedChart === 'barv' ? 'bg-gray-200 shadow-lg' : 'shadow-md'} `} 
                    onClick={() => setSelectedChart('barv')} 
                />
            </div>
            <div className='flex'>
                <img 
                    src={barh} alt="barh" 
                    className={`w-24 cursor-pointer hover:bg-gray-100 hover:shadow-lg rounded ${selectedChart === 'barh' ? 'bg-gray-200 shadow-lg' : 'shadow-md'} `} 
                    onClick={() => setSelectedChart('barh')} 
                />
            </div>
            <div className='flex'>
                <img 
                    src={line} alt="line" 
                    className={`w-24 cursor-pointer hover:bg-gray-100 hover:shadow-lg rounded ${selectedChart === 'line' ? 'bg-gray-200 shadow-lg' : 'shadow-md'} `} 
                    onClick={() => setSelectedChart('line')} 
                />
            </div>
            {
                (typeModal === 'totalQuestion' || typeModal === 'answerQuestion') && (
                    <>
                        <div className='flex'>
                            <img 
                                src={pie} alt="pie" 
                                className={`w-24 cursor-pointer hover:bg-gray-100 hover:shadow-lg rounded ${selectedChart === 'pie' ? 'bg-gray-200 shadow-lg' : 'shadow-md'} `} 
                                onClick={() => setSelectedChart('pie')} 
                            />
                        </div>
                        <div className='flex'>
                            <img 
                                src={doughnut} alt="doughnut" 
                                className={`w-24 cursor-pointer hover:bg-gray-100 hover:shadow-lg rounded ${selectedChart === 'doughnut' ? 'bg-gray-200 shadow-lg' : 'shadow-md'} `} 
                                onClick={() => setSelectedChart('doughnut')} 
                            />
                        </div>
                    </>
                )
            }
        </div>
    </>
  )
}

export default SurveyAnalysisModalChart