import { Fragment, useEffect, useState } from 'react';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import TableComponent from 'shared/components/home/TableComponent';
import AdvancedFilterComponent from 'shared/components/home/AdvacedFilterComponents';
import FilterComponent from 'shared/components/home/FilterComponent';
import { useDispatch, useSelector } from 'react-redux';
import { HomeActions } from 'shared/store/actions/HomeAction';
import ModalFormTaskComponent from 'features/customers/components/tasks/ModalFormComponent';
import { CoreConstants } from 'core/commons/CoreConstants';
import { ContainerCardComponent } from 'shared/components/home/ContainerCardComponent';
import { TabPanel, TabView } from 'primereact/tabview';
import { IndicatorsComponent } from 'shared/components/home/IndicatorsComponent';
import ModalDocumentLibrary from 'features/document/components/DocumentLibrary/ModalDocumentLibrary';

const SearchHomePage = () => {
    const { updateTitle } = useSetTitlePage();
    const [selectedSumary, setSelectedSumary] = useState(null)
    const advancedFilter = useSelector((state) => state.homeReducer.advancedFilter);
    const [displayModal, setDisplayModal] = useState(false)
    const [selectedNode, setSelectedNode] = useState('');
    const [actionSelected, setActionSelected] = useState(null)
    const [mode, setMode] = useState(null)
    const [workFlowDocId, setWorkFlowDocId] = useState(null)

    const dispatch = useDispatch();
    const summaryData = useSelector(state => state.homeReducer.Summary.data?.cards)
    const isLoading = useSelector(state => state.homeReducer.Summary.isLoading)
    const [showTaskModal, setShowTaskModal] = useState({showModal: false, selectedTask: false, viewMode: false});
    const editTaskData = useSelector(state => state.tasksReducer.editTask?.data)

    useEffect(() => {
        updateTitle({
            title: 'Inicio',
            subtitle : 'Tareas'
        });
        dispatch(HomeActions.searchSummary())
    }, []);

    const filterCardStatus = (NumStatus, id) => {
        if(id===selectedSumary){
            setSelectedSumary(null)
            NumStatus = null
        }else{
            setSelectedSumary(id)
        }
        advancedFilter.status = NumStatus

        dispatch(HomeActions.updateFilter(advancedFilter))
        dispatch(HomeActions.search())
    }

    useEffect(()=> {
        if(editTaskData?.id && showTaskModal.selectedTask && !showTaskModal.showModal){
            setShowTaskModal({...showTaskModal, showModal: true})
        }
    },[editTaskData])

    return (
        <TabView>
            <TabPanel header="Tareas">
                <Fragment>
                    <FilterComponent />
                    <ContainerCardComponent data={summaryData} selected={selectedSumary} filterCardStatus={filterCardStatus} isLoading={isLoading}/>
                    <TableComponent setMode={setMode} selectedSumary setShowTaskModal={setShowTaskModal} isLoading={isLoading} setDisplayModal={setDisplayModal} setWorkFlowDocId={setWorkFlowDocId}/>
                    <AdvancedFilterComponent />
                    <ModalFormTaskComponent 
                        isOpen={showTaskModal.showModal} 
                        onClose={() => 
                            {
                                if(!showTaskModal.viewMode){
                                    dispatch(HomeActions.search())
                                }
                                setShowTaskModal({showModal: false, selectedTask: false, viewMode: showTaskModal.viewMode})
                            }
                        }
                        viewMode={showTaskModal.viewMode}
                        // sourceDocumentCode={editTaskData?.SourceId} 
                        // sourceDocumentId={editTaskData?.DocumentId} 
                        sourceDocumentId={editTaskData?.documentId} 
                        taskId={editTaskData?.id}
                        searchHomePage={true}
                    />
                    <ModalDocumentLibrary
                        displayModal={displayModal}  
                        setDisplayModal={setDisplayModal} 
                        selectedNode={selectedNode} 
                        actionSelected={actionSelected} 
                        setActionSelected={setActionSelected}
                        mode={mode}
                        documentCode={workFlowDocId}
                    />
                </Fragment>
            </TabPanel>
            <TabPanel header='Indicadores'>
                <IndicatorsComponent />
            </TabPanel>
        </TabView>
    );
};

export default SearchHomePage;