export class BusinessSheetPublicConstants {
  static Action = class {
    static BusinessSheetPublic = class {
      static get BUSINESS_SHEET() {
        return '@BUSINESS-SHEET-PUBLIC.BUSINESS_SHEET';
      }
      static get SAVE() {
        return '@BUSINESS-SHEET-PUBLIC.SAVE';
      }
    };
  };
}