import { CoreConstants } from 'core/commons/CoreConstants';
import moment from 'moment';
import { toastAction } from "core/store/actions/ToastAction";
import FileSaver from 'file-saver';
import { DinamicProcessView } from "features/configuration/services/DinamicProcessView";
import { ConfigurationConstants } from "features/configuration/commons/ConfigurationConstants";

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: ConfigurationConstants.Accion.DinamicProcess.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};
const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: ConfigurationConstants.Accion.DinamicProcess.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload;

    const processSelected = getState().dinamicProcessReducer.process.data
    const processSections = getState().dinamicProcessReducer.process.data?.sections

    let controls = []
    processSections.forEach(section=> {
      section.controls.forEach(control => {
        controls.push(control)
        return control
      })
      return section
    })

    const getFieldId = (field) => {
      const control = controls.find((control) => control.identifier === field);
      return control.id;
    }

    if (advancedFilter.filter[processSelected?.configSearch.simpleFilters[0]]){
      const field = processSelected?.configSearch.simpleFilters[0]
      pines.push({ 
        field,
        // value: advancedFilter.filter[processSelected?.configSearch.simpleFilters[0]] ,
        processFormControlId: getFieldId(field), 
        valueText: advancedFilter.filter[processSelected?.configSearch.simpleFilters[0]]
      });
    }

      processSelected?.configSearch.advacedFilters.map(field => {
        if(advancedFilter.filter[field] && field !== processSelected?.configSearch.simpleFilters[0]){
          if(Array.isArray(advancedFilter.filter[field])){
            advancedFilter.filter[field].forEach(value => {
              pines.push({
                field: field,
                processFormControlId: getFieldId(field), 
                valueText: value.value
              })
            })
            // pines.push({
            //   field: field,
            //   processFormControlId: getFieldId(field), 
            //   valueText: advancedFilter.filter[field]
            // })
          }else if(typeof advancedFilter.filter[field] === "string"){
            if(advancedFilter.filter[field]!==""){
              pines.push({
                field: field, 
                valueText: advancedFilter.filter[field],
                processFormControlId: getFieldId(field), 
              })
            }
          }else if(advancedFilter.filter[field] instanceof Date){
            pines.push({
              field: field,
              valueText: moment(advancedFilter.filter[field]).format(
                CoreConstants.Format.LOCALE
              ),
              valueTextDate: moment(advancedFilter.filter[field]).format(CoreConstants.Format.LOCALE),
              // valueText: moment(advancedFilter.filter[field]).format(
              //   CoreConstants.Format.DDMMYYYY
              // ),
              processFormControlId: getFieldId(field), 
            });
          }else if(advancedFilter.filter[field]?.id){
            pines.push({
              field: field, 
              valueText: advancedFilter.filter[field]?.text,
              processFormControlId: getFieldId(field), 
            })
          }else{
            pines.push({
              field: field, 
              valueText: advancedFilter.filter[field],
              processFormControlId: getFieldId(field), 
            })
          }
        }
        return field
      })

    // if (advancedFilter.filter.from)
    //   
    //   

    // if (advancedFilter.filter.to)
    //   pines.push({
    //     field: 'to',
    //     value: moment(advancedFilter.filter.to).format(
    //       CoreConstants.Format.DDMMYYYY
    //     ),
    // });
    advancedFilter.values = pines;
    dispatch({
      type: ConfigurationConstants.Accion.DinamicProcess.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setView = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ConfigurationConstants.Accion.DinamicProcess.SEARCH_PROCESSES,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const occupations = getState().DinamicProcessView.occupations;
    const processSelected = getState().DinamicProcessView.process.data?.configSearch
    setView({
      dispatch: dispatch,
      isLoading: true,
      data: occupations.data,
    });

    const criteria = {};
    const advancedFilter = getState().dinamicProcessReducer.advancedFilter;
    const filter = getState().dinamicProcessReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter[processSelected.simpleFilters[0]];
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    DinamicProcessView.searchProcessesView(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setView({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
        setView({ dispatch: dispatch, isLoading: false, data: null });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setView({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Ocupaciones', data.message));
        }
      },
      () => {
        setView({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  };
};

const getProcessById = (processId) => {
  const setProcess = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ConfigurationConstants.Accion.DinamicProcess.GET_PROCESS_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setProcess({ dispatch: dispatch, isLoading: true });
    DinamicProcessView.getProcessById(processId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setProcess({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setProcess({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Procesos', data.message));
          // dispatch({
          //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
          //   toast: {
          //     titulo: 'Departamento',
          //     mensaje: data.message,
          //     severidad: 'warn',
          //   },
          // });
        }
      },
      () => {
        setProcess({ dispatch: dispatch, isLoading: false });
      }
    );
  };
};

const restoreProcessById = () => {

  return (dispatch) => {
    dispatch({
      type: ConfigurationConstants.Accion.DinamicProcess.RESTORE_PROCESS,
    });
  }
}

const restoreSaved = () => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentFormulary }) => {
    dispatch({
      type: ConfigurationConstants.Accion.DinamicProcess.SAVE_PROCESS,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };
  return (dispatch, getState) => {
    isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
  }
}


const saveProcesses = (formulary) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentFormulary }) => {
    dispatch({
      type: ConfigurationConstants.Accion.DinamicProcess.SAVE_PROCESS,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    const currentFormulary = getState().DinamicProcessView.formularies;
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
     currentFormulary: currentFormulary,
    });
    DinamicProcessView.saveProcesses(formulary).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Formulario', 'El formulario se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentFormulary, });
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.error('Formulario', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentFormulary });}
        else
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });

      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    );
  };
};



const deleteProcess = (occupationId) => {

  return (dispatch, getState) => {

    DinamicProcessView.deleteProcess(occupationId).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Proceso Dinámico', 'El proceso se eliminó correctamente'));
          dispatch({ type: ConfigurationConstants.Accion.DinamicProcess.DELETE_PROCESS, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Proceso Dinámico', data.message));
          dispatch({ type: ConfigurationConstants.Accion.dinamicProcessReducer.DELETE_PROCESS, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: ConfigurationConstants.Accion.dinamicProcessReducer.DELETE_PROCESS, payload: { isDeleted: false } });
      }
    );
  };
};

const generateReport = (payload) => {
  const setOccupations = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ConfigurationConstants.Accion.DinamicProcess.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setOccupations({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().DinamicProcessView.advancedFilter.filter;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    DinamicProcessView.generateReport(criteria)
      .then(({ data }) => {
        setOccupations({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'GradosAcademicos.xls');
      })
      .catch((err) => {
        setOccupations({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Ocupación', 'Hubo un error al descargar el archivo'));
      });
  };
};

//* Implementados
const searchListControls = () => {
  const setControls = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ConfigurationConstants.Accion.DinamicProcess.LIST_CONTROLS,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    DinamicProcessView.searchListControls().then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setControls({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        }else if (data.status > CoreConstants.HttpResponse.OK) {
          setControls({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Lista de Controles', data.message));
        }
    })
  }
}

const getConfigDynamicProcessByIdentifier = (identifier) => {
  const setProcess = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ConfigurationConstants.Accion.DinamicProcess.GET_CONFIG_DINAMIC_PROCESS_BY_IDENTIFIER,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setProcess({ dispatch: dispatch, isLoading: true });
    DinamicProcessView.getConfigDynamicProcessByIdentifier(identifier).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setProcess({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setProcess({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Procesos', data.message));
        }
      },
      () => {
        setProcess({ dispatch: dispatch, isLoading: false });
      }
    );
  };
};

const getDynamicProcessById = (id) => {
  const setProcess = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ConfigurationConstants.Accion.DinamicProcess.GET_DYNAMIC_PROCESS_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setProcess({ dispatch: dispatch, isLoading: true, data: null });
    DinamicProcessView.getDynamicProcessById(id).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setProcess({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setProcess({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Procesos', data.message));
        }
      },
      () => {
        setProcess({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  };
};


const searchDynamicProcess = (id) => {
  const setView = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ConfigurationConstants.Accion.DinamicProcess.SEARCH_DYNAMIC_PROCESS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    // const occupations = getState().DinamicProcessView.occupations;
    // const processSelected = getState().DinamicProcessView.process.data?.configSearch
    // setView({
    //   dispatch: dispatch,
    //   isLoading: true,
    //   data: occupations.data,
    // });

    // const criteria = [];
    // const advancedFilter = getState().dinamicProcessReducer.advancedFilter;
    // const filter = getState().dinamicProcessReducer.advancedFilter.filter;
    const processSections = getState().dinamicProcessReducer.process.data?.sections

    let controls = []
    processSections?.forEach(section=> {
      section.controls.forEach(control => {
        controls.push(control)
        return control
      })
      return section
    })

    const controlsDateId = controls?.reduce((acc, cur) => {
      if(cur.type === 'FECHA' || cur.type === 'FECHA_HORA' ) acc.push(cur.id)
      return acc
    }, [])

    const values = getState().dinamicProcessReducer.advancedFilter.values
    const newValues = values.map(value => {
      if(controlsDateId.includes(value.processFormControlId)){
        const model = {...value, valueText: value.valueTextDate}
        delete model.valueTextDate
        return model
      }
      return value
    })

    // criteria.columnOrder = advancedFilter.columnOrder;
    // criteria.order = advancedFilter.order;
    // criteria.page = advancedFilter.page;
    // criteria.pageSize = advancedFilter.pageSize;

    // criteria.name = filter[processSelected.simpleFilters[0]];
    // criteria.toDate = filter.to;
    // criteria.fromDate = filter.from;

    const model = {
      processIdentifier: id,
      criteria: newValues
    }
    setView({ dispatch: dispatch, isLoading: true, data: null });

    DinamicProcessView.searchDynamicProcess(model).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setView({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
        setView({ dispatch: dispatch, isLoading: false, data: null });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setView({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Proceso Dinámico', data.message));
        }
      },
      () => {
        setView({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  };
};

const nextStep = (processId) => {
  const isLoading = ({ dispatch, isSended }) => {
    dispatch({
      type: ConfigurationConstants.Accion.DinamicProcess.NEXT_STEP,
      payload: {
        isSended,
      },
    });
  };

  return (dispatch, getState) => {
    // const currentFormulary = getState().DinamicProcessView.formularies;
    isLoading({
      dispatch: dispatch,
      isSended: false,
    });
    DinamicProcessView.nextStep(processId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          // dispatch(toastAction.success('Proceso', 'Se guardó y envio correctamente'));
          isLoading({ dispatch: dispatch, isSended: true, });
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.error('Proceso', "No se pudo guardar la información."));
          isLoading({ dispatch: dispatch, isSended: false });}
        else
          isLoading({ dispatch: dispatch, isSended: false });

      },
      () => {
        isLoading({ dispatch: dispatch, isSended: false });
      }
    );
  };
};

const backStep = (processId) => {
  const isLoading = ({ dispatch, isSended }) => {
    dispatch({
      type: ConfigurationConstants.Accion.DinamicProcess.BACK_STEP,
      payload: {
        isSended,
      },
    });
  };

  return (dispatch, getState) => {
    // const currentFormulary = getState().DinamicProcessView.formularies;
    isLoading({ dispatch: dispatch, isSended: false});
    DinamicProcessView.backStep(processId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Proceso', 'Se rechazo correctamente'));
          isLoading({ dispatch: dispatch, isSended: true, });
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.error('Proceso', "No se pudo rechazar la información."));
          isLoading({ dispatch: dispatch, isSended: false });}
        else
          isLoading({ dispatch: dispatch, isSended: false });

      },
      () => {
        isLoading({ dispatch: dispatch, isSended: false });
      }
    );
  };
};

const saveProcessData = (processData, type, nextStepAction) => {
  const isLoading = ({ dispatch, isSaving, isSaved }) => {
    dispatch({
      type: ConfigurationConstants.Accion.DinamicProcess.SAVE_PROCESS_DATA,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    // const currentFormulary = getState().DinamicProcessView.formularies;
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
    });
    DinamicProcessView.saveProcessData(processData).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Proceso', 'Se guardó correctamente'));
          if (type) {
            isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, });
            nextStepAction()
          } else {
            isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, });
          }
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          // dispatch(toastAction.error('Proceso', "No se pudo guardar la información."));
          dispatch(toastAction.error('Proceso', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });}
        else
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });

      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    );
  };
};

// const getDataMaster = () =>{
//   const setDataMaster = ({ dispatch, isLoading, data }) => {
//     dispatch({
//       type: ConfigurationConstants.Accion.DinamicProcess.GET_DATA_MASTER,
//       payload: { isLoading, data },
//     });
//   };
//   return (dispatch) => {
//     DinamicProcessView.getDataMaster().then(
//       ({ data }) => {
//         if (data.status === CoreConstants.HttpResponse.OK) {
//           setDataMaster({
//             dispatch: dispatch,
//             isLoading: false,
//             controlUser: data.controlUser,
//             controlData: data.controlData,
//             controlProcess: data.controlProcess,
//           });
//         }else if (data.status > CoreConstants.HttpResponse.OK) {
//           setDataMaster({ dispatch: dispatch, isLoading: false });
//           dispatch(toastAction.warn('Lista de responsables', data.message));
//         }
//     })
//   }
// }

const deleteProcessData = (processId) => {
  const isLoading = ({ dispatch, isSended }) => {
    dispatch({
      type: ConfigurationConstants.Accion.DinamicProcess.NEXT_STEP,
      payload: {
        isSended,
      },
    });
  };

  return (dispatch) => {
    isLoading({
      dispatch: dispatch,
      isSended: false,
    });

    DinamicProcessView.deleteProcessData(processId)
      .then(({data}) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Proceso', 'Se eliminó el proceso correctamente'));
          isLoading({ dispatch: dispatch, isSended: true, });
        } else {
          dispatch(toastAction.error('Proceso', "No se pudo el proceso"));
          isLoading({ dispatch: dispatch, isSended: false });
        }
      })
      .catch(() => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        dispatch(toastAction.error('Proceso', "No se pudo el proceso"));
      })
      .finally(() => {
        isLoading({ dispatch: dispatch, isSended: false });
      })
  }
}


export const DinamicProcessAction = {
  toggleAdvancedFilter,
  updateFilter,
  resetAfterSaved,

  //* Implementados Temporal
  search,
  saveProcesses,
  restoreSaved,
  deleteProcess,
  generateReport,
  getProcessById,
  restoreProcessById,
  
  //* Implementados
  searchListControls,
  getConfigDynamicProcessByIdentifier,
  getDynamicProcessById,
  saveProcessData,
  searchDynamicProcess,
  nextStep,
  backStep,
  deleteProcessData,
  // getDataMaster,
}
