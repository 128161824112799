import { Fragment, useState } from "react";
import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Skeleton } from "primereact/skeleton";

import { useCompany } from "features/customers/hooks/companies/useCompany";
import Message from "shared/components/messages/Message";
import { CompaniesTableData } from "features/customers/data/companies/CompaniesTableData";
import { Calendar } from "primereact/calendar";
import { DynamicFieldList } from "shared/components/dynamicFields/DynamicFieldList";


export const PageCompany = ({ title, mode }) => {
  const errorsArray = [];
  const [visible, setVisible] = useState(false);
  const [idDelete, setidDelete] = useState(0);
  const {
    cancelAccept,
    cancelDenied,
    setShowModalCancel,
    showModalCancel,
    isLoading,
    isLoadingCompany,
    isLoadingDataMaster,
    dataMaster,
    control,
    handleSubmit,
    onSubmit,
    errors,
    contactList,
    createContact,
    updateContact,
    contactDelete,
    contactErrors,
    valuesFields,
    dynamicFieldsDatosGenerales,
    dynamicFieldsOficinaPrincipal,
    dynamicFieldsInformacionContacto,
    dynamicFieldsContactos,
  } = useCompany({ title });

  const deleteContact = () => {
    contactDelete(idDelete);
  };

  // const [name_, setName] = useState("");
  // const [nameField, setNameField] = useState("");
  // const [idContact, setIdContact] = useState("");
  // const Field = ({ name, row }) => {
  //   if (name === "positionId")
  //     return <Dropdown 
  //       options={dataMaster.contactPosition} 
  //       placeholder="Seleccione cargo" 
  //       optionValue='id' 
  //       optionLabel="description" 
  //       value={row[name]}
  //       onChange={(e) => {
  //         setName(e.target.name);
  //         setNameField(e.target.name);
  //         updateContact({
  //           id: row.id,
  //           name: name,
  //           value: e.value,
  //         })}
  //       }/>
  //   else
  //     return (
  //       <InputText
  //         type={
  //           name === "emailAddress"
  //             ? "email"
  //             : name === "phoneNo"
  //             ? "number"
  //             : "text"
  //         }
  //         autoFocus={
  //           name_ === name && nameField === name && row.id === idContact
  //             ? true
  //             : false
  //         }
  //         onChange={({ target }) => {
  //           setIdContact(row.id);
  //           setName(target.name);
  //           setNameField(target.name);
  //           if (name === "phoneNo" && Math.sign(target.value) === -1) {
  //             target.value = "";
  //           }
  //           updateContact({
  //             id: row.id,
  //             name: target.name,
  //             value: target.value,
  //           });
  //         }}
  //         value={row[name]}
  //         name={name}
  //       />
  //     );
  // };
  const RowDelete = ({ id }) => {
    return (
      <i
        onClick={() => {
          setVisible(true);
          setidDelete(id);
        }}
        className="pi pi-trash cursor-pointer"
      ></i>
    );
  };

  const [countrySelected, setCountrySelected] = useState("");
  const ColumnComponents = CompaniesTableData.contactColumns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable
        // body={(row) => <Field name={col.field} row={row} />}
        body={(row) => {
          if (col.field === "positionId")
            return <Dropdown 
              options={dataMaster.contactPosition} 
              placeholder="Seleccione cargo" 
              optionValue='id' 
              optionLabel="description" 
              value={row[col.field]}
              onChange={(e) => {
                updateContact({
                  id: row.id,
                  name: col.field,
                  value: e.value,
                })}
              }/>
          else
            return (
              <InputText
                type={
                  col.field === "emailAddress"
                    ? "email"
                    : col.field === "phoneNo"
                    ? "number"
                    : "text"
                }
                onChange={({ target }) => {
                  if (col.field === "phoneNo" && Math.sign(target.value) === -1) {
                    target.value = "";
                  }
                  updateContact({
                    id: row.id,
                    name: target.name,
                    value: target.value,
                  });
                }}
                value={row[col.field]}
                name={col.field}
              />
            );
        }}
      />
    );
  });

  return (
    <>
      <div className="bg-white">
        <form onSubmit={handleSubmit(onSubmit)} noValidate="novalidate">
          <Panel header="DATOS GENERALES" toggleable>
            <div className="flex gap-3 justify-between mb-6 mt-4">
              {!isLoading && !isLoadingDataMaster ? (
                <Controller
                  control={control}
                  rules={{
                    required: "El Nombre de la empresa es obligatorio. ",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <span className="p-float-label w-full">
                      <InputText
                        type="text"
                        id="Name"
                        className={`w-full ${
                          errors.Name?.type === "required" ? " p-invalid" : ""
                        }`}
                        onChange={(e) => {
                          // setName(e.target.name);
                          // setNameField(e.target.name);
                          onChange(e);
                        }}
                        value={value || ""}
                        name="Name"
                      />
                      <label htmlFor="Name">Nombre * </label>
                    </span>
                  )}
                  name="Name"
                />
              ) : (
                <Skeleton
                  width="100%"
                  height="2.5rem"
                  className="mb-3 w-full"
                />
              )}
            </div>
            <div className="flex lg:flex-row flex-col lg:gap-3 gap-6 justify-between lg:mt-4 mb-6">
              {!isLoading && !isLoadingDataMaster ? (
                <Fragment>
                  <Controller
                    control={control}
                    rules={{ required: "El Número de Ruc es obligatorio" }}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <InputText
                          type="number"
                          className={`w-full ${
                            errors.DocumentNumber?.type === "maxLength" || errors.DocumentNumber?.type === "required"
                              ? " p-invalid"
                              : ""
                          }`}
                          onChange={(e) => {
                            if(e.target.value?.length > 50){
                              e.target.value = e.target.value.slice(0,50)
                            }
                            // setName(e.target.name);
                            // setNameField(e.target.name);
                            onChange(e);
                          }}
                          name="DocumentNumber"
                          value={value || ""}
                        />
                        <label htmlFor="DocumentNumber">Número de Ruc *</label>
                      </span>
                    )}
                    name="DocumentNumber"
                  />
                  <Controller
                    name="RegistrationDate"
                    control={control}
                    rules={{ required: "La Fecha de Registro es obligatoria" }}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Calendar
                          monthNavigator
                          yearNavigator
                          yearRange="1950:2050"
                          showIcon
                          onChange={onChange}
                          value={value}
                          className={`w-full ${
                            errors.RegistrationDate?.type === "required"
                              ? " p-invalid"
                              : ""
                          }`}
                          id="RegistrationDate"
                        ></Calendar>
                        <label htmlFor="RegistrationDate">Fecha de Registro *</label>
                      </span>
                    )}
                  />
                </Fragment>
              ) : (
                [1, 2].map((i) => (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                    key={i}
                  />
                ))
              )}
            </div>
            <div className="flex lg:flex-row flex-col lg:gap-3 gap-6 justify-between mt-4">
              {!isLoading && !isLoadingDataMaster ? (
                <Fragment>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                      <span className="p-float-label w-full">
                        <Dropdown
                          options={dataMaster.businessSectors}
                          optionLabel="description"
                          filter
                          filterBy="description"
                          showClear
                          className="w-full"
                          onChange={onChange}
                          value={value || ""}
                        />
                        <label htmlFor="dropdown">Rubro</label>
                      </span>
                    )}}
                    name="BusinessSectorId"
                  />
                  <Controller
                    control={control}
                    rules={{
                      required: "La Línea de Negocio es obligatorio. ",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          className={`w-full ${
                            errors.BusinessLineId?.type === "required"
                              ? " p-invalid"
                              : ""
                          }`}
                          options={dataMaster.businessLines}
                          optionLabel="description"
                          filter
                          filterBy="description"
                          showClear
                          onChange={onChange}
                          value={value || ""}
                        />
                        <label htmlFor="dropdown">Línea de Negocio *</label>
                      </span>
                    )}
                    name="BusinessLineId"
                  />
                </Fragment>
              ) : (
                [1, 2].map((i) => (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                    key={i}
                  />
                ))
              )}
            </div>

            <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-6">
              {
                (!isLoading && !isLoadingDataMaster) &&
                <DynamicFieldList control={control} fields={dynamicFieldsDatosGenerales} />
              }
            </div>
          </Panel>
          <Panel header="OFICINA PRINCIPAL" toggleable>
            <div className="flex lg:flex-row flex-col lg:gap-3 gap-6 mt-4 mb-6">
              {!isLoading && !isLoadingDataMaster ? (
                <Fragment>
                  <Controller
                    control={control}
                    rules={{
                      required: "El País es obligatorio. ",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          options={dataMaster.countries}
                          optionLabel="niceName"
                          filter
                          filterBy="niceName"
                          showClear
                          className={`w-full ${
                            errors.CountryId?.type === "required"
                              ? " p-invalid"
                              : ""
                          }`}
                          onChange={(e) => {
                            setCountrySelected(e.value);
                            onChange(e);
                          }}
                          value={value || ""}
                        />
                        <label htmlFor="dropdown">País * </label>
                      </span>
                    )}
                    name="CountryId"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          disabled={
                            countrySelected && countrySelected.isoCode !== "PE"
                          }
                          options={dataMaster.departments?.filter( item => {
                            if( valuesFields.CountryId?.id ) return item.countryId === valuesFields.CountryId?.id
                            else return true
                          })}
                          optionLabel="name"
                          filter
                          filterBy="name"
                          showClear
                          className={
                            countrySelected && countrySelected.isoCode !== "PE"
                              ? "disabled"
                              : "w-full"
                          }
                          onChange={onChange}
                          value={value || ""}
                        />
                        <label htmlFor="dropdown">Departamento</label>
                      </span>
                    )}
                    name="DepartmentId"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          disabled={
                            countrySelected && countrySelected.isoCode !== "PE"
                          }
                          options={dataMaster.cities?.filter( item => 
                            {
                              if( valuesFields.DepartmentId?.id ) return item.departmentId === valuesFields.DepartmentId?.id
                              else return true
                            }
                          )}
                          optionLabel="name"
                          filter
                          filterBy="name"
                          showClear
                          className={
                            countrySelected && countrySelected.isoCode !== "PE"
                              ? "disabled"
                              : "w-full"
                          }
                          onChange={onChange}
                          value={value || ""}
                        />
                        <label htmlFor="dropdown">Provincia</label>
                      </span>
                    )}
                    name="CityId"
                  />
                </Fragment>
              ) : (
                [1, 2, 3].map((i) => (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                    key={i}
                  />
                ))
              )}
            </div>
            <div className="flex lg:flex-row flex-col lg:gap-3 gap-6 justify-between mt-4">
              {!isLoading && !isLoadingDataMaster ? (
                <Fragment>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          disabled={
                            countrySelected && countrySelected.isoCode !== "PE"
                          }
                          options={dataMaster.districts?.filter( item => 
                            {
                              if( valuesFields.CityId?.id ) return item.cityId === valuesFields.CityId?.id
                              else return true
                            }
                          )}
                          optionLabel="name"
                          filter
                          filterBy="name"
                          showClear
                          className={
                            countrySelected && countrySelected.isoCode !== "PE"
                              ? "disabled"
                              : "w-full"
                          }
                          onChange={onChange}
                          value={value || ""}
                        />
                        <label htmlFor="dropdown">Distrito</label>
                      </span>
                    )}
                    name="DistrictId"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <InputText
                          type="text"
                          id="Address"
                          onChange={(e) => {
                            // setName(e.target.name);
                            // setNameField(e.target.name);
                            onChange(e);
                          }}
                          name="Address"
                          value={value || ""}
                        />
                        <label htmlFor="Address">Dirección</label>
                      </span>
                    )}
                    name="Address"
                  />
                </Fragment>
              ) : (
                [1, 2].map((i) => (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                    key={i}
                  />
                ))
              )}
            </div>

            <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-6">
              {
                (!isLoading && !isLoadingDataMaster) &&
                <DynamicFieldList control={control} fields={dynamicFieldsOficinaPrincipal} />
              }
            </div>
          </Panel>
          <Panel header="INFORMACIÓN DE CONTACTO" toggleable>
            <div className="flex lg:flex-row flex-col lg:gap-3 gap-6 justify-between mt-4 mb-6">
              {!isLoading && !isLoadingDataMaster ? (
                <Fragment>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <InputText
                          type="number"
                          id="PhoneNo"
                          onChange={(e) => {
                            const value = e.target.value;

                            // setName(e.target.name);
                            // setNameField(e.target.name);
                            if (Math.sign(value) === -1) {
                              e.target.value = "";
                            }
                            onChange(e);
                          }}
                          name="PhoneNo"
                          value={value || ""}
                        />
                        <label htmlFor="PhoneNo">Teléfono</label>
                      </span>
                    )}
                    name="PhoneNo"
                  />

                  <Controller
                    control={control}
                    rules={{
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message:
                          "El Formato de correo electrónico no es válido",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <InputText
                          type="email"
                          id="EmailAddress"
                          onChange={(e) => {
                            // setName(e.target.name);
                            // setNameField(e.target.name);
                            onChange(e);
                          }}
                          name="EmailAddress"
                          value={value || ""}
                          pattern=".+@globex\.com"
                        />
                        <label htmlFor="EmailAddress">Correo eléctronico</label>
                      </span>
                    )}
                    name="EmailAddress"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <InputText
                          type="text"
                          id="WebPageAddress"
                          onChange={(e) => {
                            // setName(e.target.name);
                            // setNameField(e.target.name);
                            onChange(e);
                          }}
                          name="WebPageAddress"
                          value={value || ""}
                        />
                        <label htmlFor="WebPageAddress">Página web</label>
                      </span>
                    )}
                    name="WebPageAddress"
                  />
                </Fragment>
              ) : (
                [1, 2, 3].map((i) => (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                    key={i}
                  />
                ))
              )}
            </div>

            <div className="flex lg:flex-row flex-col lg:gap-3 gap-6 mb-6">
              {!isLoading || !isLoadingDataMaster ? (
                <Fragment>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <InputText
                          type="text"
                          id="FacebookProfileAddress"
                          onChange={(e) => {
                            // setName(e.target.name);
                            // setNameField(e.target.name);
                            onChange(e);
                          }}
                          name="FacebookProfileAddress"
                          value={value || ""}
                        />
                        <label htmlFor="FacebookProfileAddress">Facebook</label>
                      </span>
                    )}
                    name="FacebookProfileAddress"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <InputText
                          type="text"
                          id="LinkednProfileAddress"
                          onChange={(e) => {
                            // setName(e.target.name);
                            // setNameField(e.target.name);
                            onChange(e);
                          }}
                          name="LinkednProfileAddress"
                          value={value || ""}
                        />
                        <label htmlFor="LinkednProfileAddress">LinkedIn</label>
                      </span>
                    )}
                    name="LinkednProfileAddress"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <InputText
                          type="text"
                          id="InstagramProfileAddress"
                          value={value || ""}
                          onChange={(e) => {
                            // setName(e.target.name);
                            // setNameField(e.target.name);
                            onChange(e);
                          }}
                          name="InstagramProfileAddress"
                        />
                        <label htmlFor="InstagramProfileAddress">
                          Instagram
                        </label>
                      </span>
                    )}
                    name="InstagramProfileAddress"
                  />
                </Fragment>
              ) : (
                [1, 2, 3].map((i) => (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                    key={i}
                  />
                ))
              )}
            </div>

            <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-6">
              {
                (!isLoading && !isLoadingDataMaster) &&
                <DynamicFieldList control={control} fields={dynamicFieldsInformacionContacto} />
              }
            </div>
          </Panel>
          <Panel header="CONTACTOS" toggleable>
            <Button
              icon="pi pi-plus"
              type="button"
              label="Añadir nuevo contacto"
              onClick={createContact}
              loading={isLoadingCompany || isLoadingDataMaster || isLoading}
              className="btn btn-primary mt-2 mb-4"
            />

            <div className="table-main table-contact mb-3">
              <DataTable
                value={contactList}
                emptyMessage="No se encontraron contactos"
                stripedRows
                rows={5}
                editMode="row"
                dataKey="id"
                rowHover
                size="small"
                responsiveLayout="scroll"
                paginator
              >
                {ColumnComponents}
                <Column header={<div></div>} body={RowDelete}></Column>
              </DataTable>
            </div>

            <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-6">
              {
                (!isLoading && !isLoadingDataMaster) &&
                <DynamicFieldList control={control} fields={dynamicFieldsContactos} />
              }
            </div>
            {contactErrors.length > 0 && (
              <Message messages={contactErrors} status="error" />
            )}
          </Panel>
          {Object.entries(errors).forEach((key, value) => {
            errorsArray.push(key[1].message);
          })}
          {errorsArray.length > 0 && (
            <Message messages={errorsArray} status="error" />
          )}

          {(!isLoading || !isLoadingDataMaster) && (
            <div className="flex justify-end gap-4 mt-3">
              <Button
                icon="pi pi-times"
                type="button"
                label="Cancelar"
                disabled={isLoadingCompany || isLoadingDataMaster || isLoading}
                onClick={() => setShowModalCancel(true)}
                className="sig-button  sig-secondary mt-4"
              />
              {
                (mode!=='view')&&(
                  <Button
                    icon="pi pi-save"
                    type="submit"
                    label="Guardar"
                    loading={isLoadingCompany || isLoadingDataMaster || isLoading}
                    className="sig-button sig-primary mt-4"
                  />
                )
              }
            </div>
          )}
        </form>
        <ConfirmDialog
          visible={showModalCancel}
          onHide={() => setShowModalCancel(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={cancelAccept}
          reject={cancelDenied}
        />
         <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea eliminar el contacto?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={() => deleteContact()}
          reject={() => setVisible(false)}
        /> 
      </div>
    </>
  );
};
