import FileSaver from 'file-saver';
import { toastAction } from 'core/store/actions/ToastAction';
import { CoreConstants } from 'core/commons/CoreConstants';
import moment from 'moment';

import { SubscriptionConstants } from 'features/subscription/commons/SubscriptionConstants';
import { ModulesIsoService } from 'features/subscription/services/ModulesIsoService';

const setModules = (fields) => {
  return (dispatch) =>
    dispatch({
      type: SubscriptionConstants.Accion.ModulesIso.SEARCH_MODULES,
      payload: { ...fields },
    });  
};

const setDataMaster = (infoData) => {
  return (dispatch) =>
    dispatch({
      type: SubscriptionConstants.Accion.ModulesIso.DATA_MASTER,
      payload: infoData,
    });
}

const setModuleEdit = (info) =>{
  return (dispatch) =>
    dispatch({
      type: SubscriptionConstants.Accion.ModulesIso.EDIT_MODULES,
      payload: info,
    });
}

const searchModules = (valueSearch) => {
  return (dispatch, getState) => {
    let { valuesModule } = getState().modulesIsoReducer.moduleFilter;
    let { status } = getState().modulesIsoReducer.dataMaster;

    const fields = getFilterValues(valuesModule);
    fields.name = valueSearch.name;
    const hasValue = Object.values(valueSearch).length > 0;
    let newValues = [];
    if (valuesModule.length === 0 && hasValue) {
      newValues = [{ field: 'name', value: valueSearch.name }];
    } else if (valuesModule.length > 0 && hasValue) {
      const currentValues = valuesModule.filter(({ field }) => field !== 'name');
      newValues = [
        { field: 'name', valuesModule: valueSearch.name },
        ...currentValues,
      ];
    } else if (valuesModule.length > 0 && !hasValue) {
      newValues = [...valuesModule];
    }
    dispatch({
      type: SubscriptionConstants.Accion.ModulesIso.SET_FILTER_VALUES,
      payload: { valuesModule: newValues },
    });
    dispatch(getModules(fields));
    dispatch(setDataMaster({loadingDataMaster:false,status}))
  };
};

const getFields = (values) => {
  let fields = {};
  values.forEach(value => {
    if (value.field === "fromDate" || value.field === "toDate") {
      fields[value.field] = moment.parseZone(value.date).utc().format();
    } else {
      if(value.field !== "name")
        fields[value.field] = parseInt(value.id)
      else
        fields[value.field] = value.value
    }
  })
  return fields;
}

const generateReport = (fields) =>{
  let new_fields = getFilterValues(fields);
  new_fields = {
    name:new_fields.name ?? null,
    fromDate:new_fields.fromDate ?? null,
    toDate:new_fields.toDate ?? null,
    statusId:new_fields.statusId ?? null
  }
  return (dispatch) => {
    dispatch(setReport({ loadingReport: true }));
    ModulesIsoService.generateReport(new_fields).then(({data }) => {
      let objFields = {
        loadingReport: false,
      };
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      });
      FileSaver.saveAs(blob, 'Modulos.xls');
      dispatch(setReport(objFields));
    });
  };
}
const setReport = (payload) => {
  return (dispatch) =>
    dispatch({
      type: SubscriptionConstants.Accion.ModulesIso.GET_REPORT,
      payload,
    });
};

const removeFilterValues = (removeField) => {
  return (dispatch, getState) => {
    let { valuesModule } = getState().modulesIsoReducer.moduleFilter;
    
    const newValues = valuesModule.filter(({ field }) => field !== removeField);
    let payload = [];
    let fields = getFields(payload);

    dispatch(getModules({ change: true, fields }));
    dispatch({
      type: SubscriptionConstants.Accion.ModulesIso.REMOVE_FILTER_VALUES,
      payload: { valuesModule: newValues },
    });
  };
};


const getModules = (fields) => {
  return (dispatch, getState) => {
    let { dataModules } = getState().modulesIsoReducer.modules;
    dispatch(setModules({ loading: true , dataModules  }));
    ModulesIsoService.modulesSearch({fields, change:true}).then(({ data }) => {
      const { results, ...rest } = data.data;
      results.forEach(result =>{
        result.creationDate =  moment(result.creationDate.substring(0,10)).format(CoreConstants.Format.LOCALE)
      })
      let objFields = {
        loading: false,
        dataModules: results,
        currentData: results,
        pagination: { ...rest },
      };
      if (fields.columnOrder) objFields.currentCol = fields.columnOrder;
      dispatch(setModules(objFields));
    });
  };
};



const deleteModuleId = (id) =>{
  return (dispatch) => {
    dispatch(setModules({ loading: true }));
    ModulesIsoService.deleteModuleId(id).then(({data}) =>{
      if (data.status === CoreConstants.HttpResponse.OK) {
        dispatch(toastAction.success('Gestión de Modulos ISO', "El modulo ah sido eliminado exitósamente"));
        dispatch(getModules({}))
        return;
      } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
        dispatch(toastAction.warn('Roles y Privilegios', data.message));
        dispatch(setModules({ loading: false }));
      }else{
        dispatch(setModules({ loading: false }));
      }
    }).catch((error)=>{
      dispatch(setModules({ loading: false }))
    });
  }
}

const getFilterValues = (newValues) => {
  const newFilterValues = {};

  newValues.forEach((val) => {
    const field = val.field.toLocaleLowerCase();
    if (field.includes('date')) newFilterValues[val.field] = val.date;
    else if (field.includes('id') && field !== 'statusid')
      newFilterValues[val.field] = val.option.id;
    else if (field === 'statusId') newFilterValues[val.field] = val.optionId;
    else if (field === 'name') newFilterValues[val.field] = val.value;
  });
  return newFilterValues;
};

const searchTable = ({page}) =>{  
  return (dispatch,getState) =>{
    let { values } = getState().modulesIsoReducer.moduleFilter;
    const {fields } = getFilterValues(values);
    fields.page = page;
    dispatch(getModules({fields}));
  }
}

const clearValues = () => {
  return (dispatch) =>{
    let payload = []
    dispatch({
      type: SubscriptionConstants.Accion.ModulesIso.SET_FILTER_VALUES,
      payload,
    });
  }
}

const saveModule = (info) => {
  return (dispatch) => {
    dispatch(setDataMaster({ loadingDataMaster: true }));
    ModulesIsoService.saveModule(info).then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
           let infoData = {
             loadingSave: false,
             isSave: true
           }
          dispatch(toastAction.success("Registro de Modulo" ,'Modulo registrado exitosamente'));
          dispatch(setModuleEdit(infoData));
          dispatch(setDataMaster({ loadingDataMaster: false }));
          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.success('Roles y Privilegios', data.message));
          dispatch(setDataMaster({ loadingDataMaster: false }));
        }else{
        dispatch(setDataMaster({ loadingDataMaster: false }));
        }
      }).catch((error)=>{
        dispatch(setDataMaster({ loadingDataMaster: false }));
      });
  };
}

const getDataMaster = (title,id) => {
  return (dispatch) => {
    dispatch(setDataMaster({ loadingDataMaster: true }));
    ModulesIsoService.getDataMaster().then(({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          /* let { countries, employeeTypes, employees, isAcademicDegree, 
            isEmployeeSituation, maritalStatus, positions, departments,
            cities , districts , isDocumentType , isGender ,  branches , workDays , areas } = data.data; */
          let infoData = {
            loadingDataMaster: false,
            dataMaster: {
              /* employees: employees,
              departments: departments,
              nationalities: countries,
              employeesTypes: employeeTypes,
              instructions: isAcademicDegree,
              positions: positions,
              statusCivil: maritalStatus,
              status: isEmployeeSituation,
              cities: cities,
              gender:isGender,
              districts:districts,
              documentsTypes:isDocumentType,
              branches:branches,
              workDays:workDays,
              areas:areas */
            }
          }
          dispatch(setDataMaster(infoData));
          dispatch(getModules({}));
          if(title!==undefined && title.title === "Editar Modulo"){
            dispatch(getModuleId(id));
          }
          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.success('Roles y Privilegios', data.message));
        }
      }).catch((error)=>{
        dispatch(setDataMaster({ loadingDataMaster: false }));
      });
  };
}

const getModuleId = (id) => {
  return (dispatch) => {
    dispatch(setDataMaster({ loadingDataMaster: true }));
    ModulesIsoService.getModuleById(id).then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          let info = {
            dataModule: data.data,
            loadingSave: false,
          };
          dispatch(setModuleEdit(info));
          dispatch(setDataMaster({ loadingDataMaster: false }));
        } else if (data.Status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.success('Roles y Privilegios', data.message));
          dispatch(setDataMaster({ loadingDataMaster: false }));
        }else{
          dispatch(setDataMaster({ loadingDataMaster: false }));
        }
      }).catch((error)=>{
        dispatch(setDataMaster({ loadingDataMaster: false }));
      });
  };
}

export const ModulesActions = {
  setModules,
  setDataMaster,
  setModuleEdit,
  searchModules,
  getFields,
  generateReport,
  removeFilterValues,
  getModules,
  deleteModuleId,
  searchTable,
  clearValues,
  saveModule,
  getDataMaster,
  getModuleId
};

