import { useSelector } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { InputSwitch } from 'primereact/inputswitch';

import Message from 'shared/components/messages/Message';
import useRole from 'features/configuration/hooks/roles/useRole';
import './RolePrivilege.scss';

import { TabPanel, TabView } from 'primereact/tabview';

const RolPrivilegioPage = ({ title = 'NUEVO ROL', mode }) => {
  const {
    handleSubmit,
    showField,
    roles,
    handleChange,
    errors,
    showSkeleton,
    showRoles,
    options,
    isChecked,
    handleCheckbox,
    visible,
    setVisible,
    accept,
    reject,
  } = useRole({
    title,
  });

  const { status } = useSelector((state) => state.roleReducer.saveRole);

  const OptionSkeleton = () => {
    const subSkeletons = [1, 2, 3, 4];
    return (
      <div className="mb-6">
        <Skeleton width="80%" height="2rem" className="mb-4" />
        {subSkeletons.map((s) => (
          <Skeleton width="70%" height="1.5rem" className="mb-3" key={s} />
        ))}
      </div>
    );
  };

  return (
    <div className="bg-white  ">
      <form className="form-custom p-0" onSubmit={handleSubmit}>
        <Panel header={title} toggleable>
          {showField() && (
            <div className="mb-6 lg:w-2/5 mt-5">
              <span className="p-float-label w-full">
                <InputText
                  type="text"
                  id="rol_name"
                  value={roles.name}
                  onChange={handleChange}
                  className={errors.showInRole ? 'p-invalid' : ''}
                  readOnly={mode === 'view'}
                />
                <label htmlFor="rol_name">Nombre del rol *</label>
              </span>

            </div>
          )}
          <div className="rol-title">
            <h2 className="font-semibold">Listado de Opciones *</h2>
          </div>
          <br />
          {showSkeleton() &&
            [1, 2, 3, 4, 5].map((i) => <OptionSkeleton key={i} />)}
          {showRoles() &&
            <div className="card tabs-vertical ">
              <TabView className="tabview-custom" orientation="bottom">
                {

                  options.map((parent, index) => {

                    return <TabPanel header={parent.name} leftIcon={parent.icon} key={parent.id}>
                      <div className="grid-roles scroll mt-8 px-3">
                        {parent.childrens.map((option, i) => (
                          <div className="rol " key={index}>
                            <div className="rol-title">
                              <h2 className="font-semibold">{option.name}</h2>
                            </div>
                            <div className="flex gap-3"></div>
                            <div className="rol-list mt-2">
                              {option.actions.map((action, i) => (
                                <div className="mb-4 flex gap-3" key={i}>
                                  <InputSwitch
                                    inputId={action.name}
                                    id={action.id}
                                    checked={isChecked(action)}
                                    onChange={handleCheckbox}
                                    className={errors.showInActions ? 'p-invalid' : ''}
                                    disabled={mode === 'view'}
                                  />
                                  <p>{action.name}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabPanel>
                  })
                }
              </TabView>
            </div>
          }

          {(errors.showInRole ||
            errors.showInActions ||
            errors.list.length > 0) && (
              <Message messages={errors.list} status="error" />
            )}

          {showRoles() && (
            <div className="flex gap-3 items-center justify-end mt-3 mb-3">
              <Button
                icon="pi pi-times"
                type="button"
                label="Cancelar"
                className="sig-button  sig-secondary mt-4"
                onClick={() => setVisible(true)}
                disabled={status === ''}
              />
              {
                (mode !== 'view')&&(
                  <Button
                    icon="pi pi-save"
                    type="submit"
                    label="Guardar"
                    loading={status === ''}
                    className="sig-button sig-primary mt-4"
                  />
                )
              }
            </div>
          )}
        </Panel>
      </form>
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="¿Está seguro que desea cancelar?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
    </div>
  );
};

export default RolPrivilegioPage;
