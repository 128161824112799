import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { StudentsAction } from "features/customers/store/actions/StudentsAction";
import { useForm } from "shared/hooks/useForm";
import { CoreConstants } from "core/commons/CoreConstants";

export const useAdvancedFilterStudents = () => {
  const dispatch = useDispatch();

  const studentsLoading = useSelector(
    (state) => state.studentsReducer.students.loading
  );
  const reportLoading = useSelector(
    (state) => state.studentsReducer.downloadReport.loading
  );

  const dataMaster = useSelector(
    (state) => state.studentsReducer.dataMaster.data
  );

  const { values, showModal } = useSelector(
    (state) => state.studentsReducer.advancedFilter
  );

  const showPiners = values.length > 0;

  const initialValues = {
    names: null,
    fromDate: null,
    toDate: null,
    companyId: null,
    businessLineId: null,
    countryId: null,
    documentNo: null,
  };
  const [formValues, handleChange, reset] = useForm(initialValues);
  const [errors, setErrors] = useState([]);

  const onToggleModal = (showModal) => {
    setErrors([]);
    dispatch(StudentsAction.setAdvancedFilter({ showModal }));
  };
  const clearFields = () => {
    reset(initialValues);
    setErrors([]);
  };

  useEffect(() => {
    if (values.length === 0) {
      clearFields();
      setErrors([]);
    }
  }, [values]);

  const buildFilterValues = () => {
    const currentValues = { ...formValues };
    const currentPiners = [];
    for (let valueKey in currentValues) {
      const key = valueKey.toLocaleLowerCase();
      const field = valueKey;
      const isHasValue = formValues[valueKey];
      if (isHasValue) {
        if (key.includes("date")) {
          const value = moment(currentValues[valueKey]).format(
            CoreConstants.Format.LOCALE
          );
          const date = currentValues[valueKey];
          currentPiners.push({ field, value, date });
        } else if (key.includes("id")) {
          const option = currentValues[valueKey];
          let value = "";
          if (currentValues[valueKey].description) {
            value = currentValues[valueKey].description;
          } else if (currentValues[valueKey].niceName) {
            value = currentValues[valueKey].niceName;
          } else if (currentValues[valueKey].businessName) {
            value = currentValues[valueKey].businessName;
          }
          currentValues[valueKey] = option.id;
          currentPiners.push({ field, value, option });
        } else {
          if (key === "documentNumber") {
            currentValues[valueKey] += "";
          }

          const value = currentValues[valueKey];
          currentPiners.push({ field, value });
        }
      }
    }
    return {
      currentPiners,
      currentValues,
    };
  };

  const onAdvancedFilter = (e) => {
    e.preventDefault();
    setErrors([]);
    if (formValues.documentNo) {
      const document = formValues.documentNo + "";

      if (document.length > 12) {
        setErrors(["El campo documento admite entre 8 a 11 dígitos"]);
        return;
      }
    }
    let { currentValues, currentPiners } = buildFilterValues();
    const currentSearchValue =
      values.find(({ field }) => field === "names") ?? {};
    if (Object.values(currentSearchValue).length > 0) {
      currentValues.names = currentSearchValue.value;
      currentPiners = [
        ...currentPiners.filter((p) => p.field !== "names"),
        currentSearchValue,
      ];
    }

    dispatch(
      StudentsAction.setAdvancedFilter({
        showModal: false,
        values: currentPiners,
      })
    );
    dispatch(
      StudentsAction.getStudents({ fields: currentValues, change: true })
    );
  };
  const removePiner = (field) => {
    dispatch(StudentsAction.removeFilterValues(field));
  };

  return {
    onToggleModal,
    clearFields,
    showModal,
    onAdvancedFilter,
    studentsLoading,
    reportLoading,
    dataMaster,
    formValues,
    handleChange,
    showPiners,
    piners: values,
    removePiner,
    errors,
  };
};
