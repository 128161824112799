import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SearchDocumentLibraryPage from './pages/DocumentLibraries/Search/SearchDocumentLibraryPage';
import SearchDocumentManagementPage from './pages/DocumentManagement/Search/SearchDocumentManagementPage';



import { documentLibraryReducer, documentManagementReducer } from './store/reducers';


export default function DocumentRouting(reducerRegistry) {
  const { path } = useRouteMatch();
  reducerRegistry.register({ documentLibraryReducer });
  reducerRegistry.register({ documentManagementReducer });

  return (
    <>
      <Switch>
        <Route path={`${path}/biblioteca`}>
          <Switch>
            <Route
              exact
              path={`${path}/biblioteca`}
              component={SearchDocumentLibraryPage}
              ren
            />
          </Switch>
        </Route>

        <Route path={`${path}/administracion`}>
          <Switch>
            <Route
              exact
              path={`${path}/administracion`}
              component={SearchDocumentManagementPage}
              ren
            />
          </Switch>
        </Route>
      </Switch>
    </>
  )
}