/* eslint-disable react-hooks/exhaustive-deps */
import { FilterComponent } from "features/configuration/components/dynamicFields/FilterComponent";
import { TableComponent } from "features/configuration/components/dynamicFields/TableComponent";
import { AdvancedFilterComponent } from 'features/configuration/components/dynamicFields/AdvancedFilterComponent';
import { DynamicFieldsAction } from "features/configuration/store/actions/DynamicFieldsAction";
import { Button } from "primereact/button";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import scrollTop from "shared/utils/scrollTop";

export const SearchDynamicFieldsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getDinamycFields =  useSelector(
    (state) => state.dynamicFieldsReducer.dataMaster.getDinamycFields
  );
  const { values } = useSelector(
    (state) => state.dynamicFieldsReducer.advancedFilter
  );
  const isSearch = true
  const isDownload = true
  const isCreate = true


  useEffect(() => {
    dispatch(DynamicFieldsAction.getDataMaster());
    dispatch(DynamicFieldsAction.setCurrentDynamicFields({ errors: [] }));

    return () => {
      dispatch(DynamicFieldsAction.setGetDataMaster({ isLoadingDataMaster: true }));
      dispatch(DynamicFieldsAction.setAdvancedFilter({values: []}))
    }

  }, []);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    if(getDinamycFields){
      const currentValues = {}
      const currentPiners = [...values];
      currentPiners.forEach((piner) => {
        if (piner.option) {
          currentValues[piner.field] = piner.option.id;
        } else if (piner.field.includes("Date")) {
          currentValues[piner.field] = piner.date;
        } else {
          currentValues[piner.field] = piner.value;
        }
      });
      dispatch(
        DynamicFieldsAction.getDynamicFields({ fields: currentValues, change: true })
      );
      dispatch(DynamicFieldsAction.setGetDataMaster({ getDinamycFields: false }));
    }
  },[getDinamycFields])

  return (
   
          <>
              {
                  (isSearch||isDownload)&&(
                      <FilterComponent />
                  )
              }
              {
                isCreate&&(
                  <Button
                    icon="pi pi-plus"
                    type="button"
                    label="Nuevo"
                    className="sig-button sig-dark mt-4"
                    onClick={() => {
                      history.push('/configuracion/campos-dinamicos/nuevo');
                    }}
                  />
                )
              }

              <TableComponent />
              <AdvancedFilterComponent />
          </>
  )
}