import React from 'react';
import ExpedienteMatriz from './ExpedienteMatriz';
import ExpedienteEstandar from './ExpedienteEstandar';

const Expediente = ({rowData, getValues, column, control, setValue}) => {

  const type = rowData[column.field].evaluationType

	if(type === "RMMAT"){
    return (
      <ExpedienteMatriz rowData={rowData} getValues={getValues} column={column} control={control} setValue={setValue}/>
    )
  } else if( type === "RMEST"){
    return <ExpedienteEstandar rowData={rowData} getValues={getValues} column={column} control={control} setValue={setValue}/>
  }
};

export default Expediente;