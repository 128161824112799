import React from "react";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { RiskMatrixConstans } from "features/riskManagement/commons/RiskMatrixConstans";
import { InputTextarea } from "primereact/inputtextarea";

const InputCustom = ({ rowData, control, column }) => {
  if (
    rowData[column.field]?.code ===
    RiskMatrixConstans.Types.MatrixManagement.TEXTO
  ) {
    return (
      <Controller
        control={control}
        name={`${column.field}-row-${rowData.id}`}
        render={({ field: { onChange, value } }) => (
          <InputTextarea autoResize rows={1} value={value || ""} onChange={onChange} />
        )}
      />
    );
  }
  if (
    rowData[column.field]?.code ===
    RiskMatrixConstans.Types.MatrixManagement.SELECCION_SIMPLE
  ) {
    return (
      <Controller
        control={control}
        name={`${column.field}-row-${rowData.id}`}
        render={({ field: { onChange, value } }) => {
          return (
            <Dropdown
              options={rowData[column.field].details.map((item) => ({
                id: item.id,
                text: item.text,
                itemValue: item.value,
              }))}
              optionLabel="text"
              value={value || ""}
              onChange={onChange}
            />
          );
        }}
      />
    );
  }
  if (
    rowData[column.field]?.code ===
    RiskMatrixConstans.Types.MatrixManagement.SELECCION_MULTIPLE
  ) {
    return (
      <Controller
        control={control}
        name={`${column.field}-row-${rowData.id}`}
        render={({ field: { onChange, value } }) => (
          <MultiSelect
            filter
            filterBy="text"
            emptyFilterMessage="No se encontró elementos"
            display="chip"
            options={rowData[column.field].details.map((item) => ({
              id: item.id,
              text: item.text,
              itemValue: item.value,
            }))}
            optionLabel="text"
            value={value || ""}
            onChange={onChange}
          />
        )}
      />
    );
  }

  return null;
};

export default InputCustom;
