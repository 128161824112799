/* eslint-disable default-case */
import { useClaimProjectExpenses } from "features/projects/hooks/useClaimProjectExpenses";
import { ProjectExpensesAction } from "features/projects/store/actions/ProjectExpensesActions";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PinerComponent from "shared/components/Piner/PinerComponent";

const FilterComponent = () => {
    const dispatch = useDispatch();
    const criteriaIndicator = useRef(null);
    const advancedFilter = useSelector((state) => state.projectExpensesReducer.advancedFilter);
    const values = useSelector((state) => state.projectExpensesReducer.advancedFilter.values);
    const { isLoading } = useSelector((state) => state.projectExpensesReducer.ProjectExpenses)
    
    const openModal = () => {
        dispatch(ProjectExpensesAction.toggleAdvancedFilter({ showModal: true}));
    };

    const { isSearch, isDownload } = useClaimProjectExpenses();

    const showPiners = () => {
        if (values?.length > 0)
            return true;
        return false;
    }

    const removePiner = (field) => {
        switch (field) {
            case 'name':
                advancedFilter.filter.name = null;
                criteriaIndicator.current.value = '';
                break;
            case 'to':
                advancedFilter.filter.to = null;
                break;
            case 'from':
                advancedFilter.filter.from = null;
                break;
            case 'category':
                advancedFilter.filter.category = null;
                break;
        }

        dispatch(ProjectExpensesAction.updateFilter(advancedFilter));
        dispatch(ProjectExpensesAction.search());
    };

    const search = () => {
        const value = criteriaIndicator.current.value;
        if(value !== null && value !== undefined && value.trim().length > 0) {
            advancedFilter.filter.name = value;
            dispatch(ProjectExpensesAction.updateFilter(advancedFilter));
        }
        dispatch(ProjectExpensesAction.search());
    }

    const handleKeyDown = (event) => {
        if(event.key === 'Enter') {
            search();
        }
    }

    const generateReport = () => {
        const value = criteriaIndicator.current.value;
        if(value !== null && value !== undefined && value.trim().length > 0) {
            advancedFilter.filter.name = value;
            dispatch(ProjectExpensesAction.updateFilter(advancedFilter));
        }
        dispatch(ProjectExpensesAction.generateReport());
    }

  return (
    <Panel header={`${isSearch?"FILTRO POR":"DESCARGA"}`} toggleable>
        <div className="filter-components">
            <div className={`${isSearch?'visible':'invisible'}`}>
                <div className="w-full ">
                    <span className="p-float-label p-input-icon-left w-full ">
                        <i className="pi pi-search" />
                        <InputText
                            id="input-search"
                            ref={criteriaIndicator}
                            onKeyDown={handleKeyDown}
                        />
                        <label htmlFor="input-search">Buscar por nombre del proyecto</label>
                    </span>
                    {showPiners() && (
                        <div className="filter-piners mt-3">
                            {values.map(({ value, field }, index) => (
                                <PinerComponent
                                    name={value}
                                    field={field}
                                    removePiner={removePiner}
                                    key={index}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>

            
                <div className="filter-users-action">
                    <div className="flex gap-3 w-full">
                        {
                            isSearch&&(
                                <Button
                                    icon="pi pi-search"
                                    type="button"
                                    label="Buscar"
                                    onClick={search}
                                    loading={isLoading}
                                    className="sig-button sig-primary w-full"
                                />
                            )
                        }
                        {
                            isDownload&&(
                                <Button
                                    icon="pi pi-download"
                                    type="button"
                                    loading={isLoading}
                                    onClick={generateReport}
                                    label="Descargar"
                                    className="sig-button sig-success  w-full"
                                />
                            )
                        }
                    </div>
                    <div className="flex mt-3">
                        {
                            isSearch&&(
                                <Button
                                    type="button"
                                    label="Búsqueda avanzada"
                                    className="sig-button  sig-secondary w-full"
                                    onClick={openModal}
                                />
                            )
                        }
                    </div>
                </div>
            
        </div>
    </Panel>
  )
}

export default FilterComponent;