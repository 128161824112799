import React from 'react'
import pulgar from 'shared/assets/img/pulgar-arriba.png'

const ThankYouMessage = ({title="¡Muchas gracias por realizar la encuesta!", message}) => {
  
  return (
    <div className='h-screen flex justify-center items-center '>
      <div className='h-1/2 md:h-2/3 w-2/3 lg:w-1/2 flex flex-col justify-center items-center'>
        <div className='h-1/2 w-full border-4 sig-primary flex justify-center items-center rounded-t'>
          <img className='h-44 md:h-52' alt='pulgar' src={pulgar}  />
        </div>
        <div className='h-1/2 w-full flex border-2 px-3 py-2 bg-gray-100 flex-col justify-center items-center gap-y-3 md:gap-y-5 rounded-b shadow-lg'>
          <h1 className='text-2xl md:text-4xl font-light text-center '>{title}</h1>
          {/* <h2>Sus respuestas se estarán procesando</h2> */}
          <h2 className='text-base md:text-lg'>{message}</h2>
        </div>
      </div>
    </div>
  )
}

export default ThankYouMessage