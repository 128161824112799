import { ProgressBar } from 'primereact/progressbar';
import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import { getBase64 } from 'shared/utils/getBase64';
import './AttachmentInput.scss'

const AttachmentInput = ({typeControl, readOnly, onChange, errors, setValue, identifier, valuesField}) => {
  const [fileValue, setFileValue] = useState(null)
  const [value1, setValue1] = useState(0);
  const interval = useRef(null);

  const data = {
    fileName: valuesField[identifier],
    fileUrl: valuesField[identifier + '_b64'],
  }

  // const iconTypeOfFile = (file) => {
  //   if (!file) return
  //     const fileSplit = file.name.split('.')
  //     if (fileSplit.includes('docx')) {
  //         return <div className="icon-word-table w-12 h-12"></div>
  //     } else if(fileSplit.includes('xlsx') || fileSplit.includes('xls') ) {
  //         return <div className="icon-excel-table w-12 h-12"></div>
  //     } else if(file.type === 'application/pdf') {
  //         return <img alt='icon-pdf' className='icon-image w-12 h-12' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAACQ0lEQVRoge2Yz0tUQRzAP293rTRb09rMwl1UkFqQ9tAhKPDQrT8gTx5FCtFLvy4SnbJDoKQSdIjoEBoFEZ66RF7qaAgF4rLs0masWSIsbaTTYZR3eNX63sw2uzAfWN7Mm+/MfD/Mm/fePrBYlHDKBYi+TlGRmQV3nNn0DdVhQjpy8YtoaQOH6+Ji55jqWEYENkemEIeOaZEwIkBzK5vDk1okzAiANglzAqBFwqwAKEuYFwAlieoQgMAS1SMAgSSqSwA8EuXCI/8jJ8+kw2e1jVV9K+ATK2AaK2AaK2CamhcI9iA73g0j9936zxJk3sPzcVj7DB09cGnCbf+ah9dP4N2crPf0Qv9N77jjA5Bf9pVKsBUIh6EhCqEIZD9C8TukzsPQFET2QGi73QnBpyWItUP/Lejt2+4fke1bv2T/nV/ph+9U1F4lVnMweRkcB648gkQSuk9DqSjbV9IwMQixOIw+hQsD8GbW7b+8AA+uKqWgZw8IAV8yshw97G0vZKGQg/0Hofmolil3UFuBun0QPwkNByB5Rp7LL0HdXm9scUMem2Luua5TMDQty4UczNz2nYKaQGsCrj126/PPIPsBulLe2D99HquPQvyELIfDgVJQE9hYg7cv5TGzCOmFv8fWN8rj+qq7CovzyntATWC9AC/ulY9raYMj7fIy+rYiLztNVPYPTSwBg3ehIyVvna8egtjSOkVlBRqbIHlO3m7npuUe0Yy5r9O7xJlJ/zPHmn8XsgKmsQKmsQKmsQKmsQIWS43zG41eqcJmZbBOAAAAAElFTkSuQmCC"/>
  //     } else if(file.type === 'image/jpeg'|| file.type === 'image/jpg' || file.type === 'image/png' ) {
  //         return <img alt='icon-img' className='icon-image w-12 h-12' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAADI0lEQVRoge2ZyW7TUBRAT9IhcdKgoqqTmjKpn4DER7CDBX/AT8CSNWv4A9ix4xtYtBJCqBIFVEpoaZqENB4S2/G7LNKiUPxc22ncIuVI2dzr4R7fN0mBCRMmTLhMcrrEgwO5L8JLgbUsCwqhRo7Hb1Zzb8OSed1dSnhxBYoHqCK81CW1AkB1DMWkZV2XiBL4L/jvBaazfuHdGYdHRpvqlEctmOVVd55Nv5T6eZl24O6Mw5PKIRvTLsWcsDHt8rRyyL1ZJ/UzMxV4ZLRD4w+L4fE4ZCqwNuWHxquaeBwyFfgRzITGa5p4HDIVeNWdD42/1sTjkKnApl/imbnMTr9AT/Ls9As8M5dHWoVGXkaPj3p0Gi7kYX6pSOV6IfL6Tb80UsFnSS0gAgdfO7TrvT8xs+WyWC2zuF6+kOLikEpARKh96tBp9P7J1fcsVCAs35obubg4JBYQEb5vH9Npudprjmo2AqxkIJFIQAJh9+MvrLZ37rVHexaIsHK78lfc+uVitTwEuLZQoDw/m6jgs8QWCPqK3Q8t7OP4m079m4UIrN6pgEBz36bTdBE16KTd9ri+YrCwNuZVqO8pvrxv0bWS75iHJxKzhTx2SOea+w4qEBZvpJv45wr4vYDPW0163X6qFwAc7poYczMY5fDXtQ4cRGDpZnKJSAGvG7Cz1cB10hd/itPxECUUdRL7DiAs3Uw28bUCPavPp80GvhskemAUjumjFNpONH8MOpFkCdYKbL+r0/dV8irPwTE9EH0nmjUbEVi5HU9CK3CRX/4sdsdDBYIxF34KbXy3B0vwnUpofhitgEj6AuPgWD4CFEvhJRzt2YiC1Y1oCb2AGrMBQxM7QgKiJSI6MH4BGMwJpRRGKXxHrn+zyEUc+rUC6uLnrxbH7CMKjHL4nPj5xdTee6lDaBjH9E8kkp0vL30IDeNYHqIUxXL8A15EBy6kpsQ41mA4FTXD6SwRcyD7DpxiW6c79vkSV2oIDePYHoJglKIlrtwQGmZwdhJKERJRq1CNK/AfQdf0QXGsy2u3iECpxyJSExEu85fL5wIVyPPxfJ4JEyZMGJXfXUfSuVI0IAgAAAAASUVORK5CYII="/>
  //     } else {
  //         return <div className="icon-file-table"></div>
  //     }
  // }

  const iconTypeOfFile = (file) => {
    if (!file) return
    if (file.name) {
      const fileSplit = file.name.split('.')
      if (fileSplit.includes('docx') || fileSplit.includes('doc')) {
          return <div className="icon-word-table h-10 w-10"></div>
      } else if(fileSplit.includes('xlsx') || fileSplit.includes('xls')) {
          return <div className="icon-excel-table h-10 w-10"></div>
      } else if(file.type === 'application/pdf') {
          return <img alt='icon-pdf' className='icon-image h-10 w-10' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAACQ0lEQVRoge2Yz0tUQRzAP293rTRb09rMwl1UkFqQ9tAhKPDQrT8gTx5FCtFLvy4SnbJDoKQSdIjoEBoFEZ66RF7qaAgF4rLs0masWSIsbaTTYZR3eNX63sw2uzAfWN7Mm+/MfD/Mm/fePrBYlHDKBYi+TlGRmQV3nNn0DdVhQjpy8YtoaQOH6+Ji55jqWEYENkemEIeOaZEwIkBzK5vDk1okzAiANglzAqBFwqwAKEuYFwAlieoQgMAS1SMAgSSqSwA8EuXCI/8jJ8+kw2e1jVV9K+ATK2AaK2AaK2CamhcI9iA73g0j9936zxJk3sPzcVj7DB09cGnCbf+ah9dP4N2crPf0Qv9N77jjA5Bf9pVKsBUIh6EhCqEIZD9C8TukzsPQFET2QGi73QnBpyWItUP/Lejt2+4fke1bv2T/nV/ph+9U1F4lVnMweRkcB648gkQSuk9DqSjbV9IwMQixOIw+hQsD8GbW7b+8AA+uKqWgZw8IAV8yshw97G0vZKGQg/0Hofmolil3UFuBun0QPwkNByB5Rp7LL0HdXm9scUMem2Luua5TMDQty4UczNz2nYKaQGsCrj126/PPIPsBulLe2D99HquPQvyELIfDgVJQE9hYg7cv5TGzCOmFv8fWN8rj+qq7CovzyntATWC9AC/ulY9raYMj7fIy+rYiLztNVPYPTSwBg3ehIyVvna8egtjSOkVlBRqbIHlO3m7npuUe0Yy5r9O7xJlJ/zPHmn8XsgKmsQKmsQKmsQKmsQIWS43zG41eqcJmZbBOAAAAAElFTkSuQmCC"/>
      } else if(file.type === 'image/jpeg'|| file.type === 'image/jpg' || file.type === 'image/png' ) {
          return <img alt='icon-img' className='icon-image h-10 w-10' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAADI0lEQVRoge2ZyW7TUBRAT9IhcdKgoqqTmjKpn4DER7CDBX/AT8CSNWv4A9ix4xtYtBJCqBIFVEpoaZqENB4S2/G7LNKiUPxc22ncIuVI2dzr4R7fN0mBCRMmTLhMcrrEgwO5L8JLgbUsCwqhRo7Hb1Zzb8OSed1dSnhxBYoHqCK81CW1AkB1DMWkZV2XiBL4L/jvBaazfuHdGYdHRpvqlEctmOVVd55Nv5T6eZl24O6Mw5PKIRvTLsWcsDHt8rRyyL1ZJ/UzMxV4ZLRD4w+L4fE4ZCqwNuWHxquaeBwyFfgRzITGa5p4HDIVeNWdD42/1sTjkKnApl/imbnMTr9AT/Ls9As8M5dHWoVGXkaPj3p0Gi7kYX6pSOV6IfL6Tb80UsFnSS0gAgdfO7TrvT8xs+WyWC2zuF6+kOLikEpARKh96tBp9P7J1fcsVCAs35obubg4JBYQEb5vH9Npudprjmo2AqxkIJFIQAJh9+MvrLZ37rVHexaIsHK78lfc+uVitTwEuLZQoDw/m6jgs8QWCPqK3Q8t7OP4m079m4UIrN6pgEBz36bTdBE16KTd9ri+YrCwNuZVqO8pvrxv0bWS75iHJxKzhTx2SOea+w4qEBZvpJv45wr4vYDPW0163X6qFwAc7poYczMY5fDXtQ4cRGDpZnKJSAGvG7Cz1cB10hd/itPxECUUdRL7DiAs3Uw28bUCPavPp80GvhskemAUjumjFNpONH8MOpFkCdYKbL+r0/dV8irPwTE9EH0nmjUbEVi5HU9CK3CRX/4sdsdDBYIxF34KbXy3B0vwnUpofhitgEj6AuPgWD4CFEvhJRzt2YiC1Y1oCb2AGrMBQxM7QgKiJSI6MH4BGMwJpRRGKXxHrn+zyEUc+rUC6uLnrxbH7CMKjHL4nPj5xdTee6lDaBjH9E8kkp0vL30IDeNYHqIUxXL8A15EBy6kpsQ41mA4FTXD6SwRcyD7DpxiW6c79vkSV2oIDePYHoJglKIlrtwQGmZwdhJKERJRq1CNK/AfQdf0QXGsy2u3iECpxyJSExEu85fL5wIVyPPxfJ4JEyZMGJXfXUfSuVI0IAgAAAAASUVORK5CYII="/>
      } else {
          return <div className="icon-file-table"></div>
      }
    }
    const fileSplit = file.split('.')
    if (fileSplit.includes('docx') || fileSplit.includes('doc')) {
      return <div className="icon-word-table h-10 w-10"></div>
    } else if(fileSplit.includes('xlsx') || fileSplit.includes('xls')) {
        return <div className="icon-excel-table h-10 w-10"></div>
    } else if(fileSplit.includes('pdf')) {
        return <img alt='icon-pdf' className='icon-image h-10 w-10' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAACQ0lEQVRoge2Yz0tUQRzAP293rTRb09rMwl1UkFqQ9tAhKPDQrT8gTx5FCtFLvy4SnbJDoKQSdIjoEBoFEZ66RF7qaAgF4rLs0masWSIsbaTTYZR3eNX63sw2uzAfWN7Mm+/MfD/Mm/fePrBYlHDKBYi+TlGRmQV3nNn0DdVhQjpy8YtoaQOH6+Ji55jqWEYENkemEIeOaZEwIkBzK5vDk1okzAiANglzAqBFwqwAKEuYFwAlieoQgMAS1SMAgSSqSwA8EuXCI/8jJ8+kw2e1jVV9K+ATK2AaK2AaK2CamhcI9iA73g0j9936zxJk3sPzcVj7DB09cGnCbf+ah9dP4N2crPf0Qv9N77jjA5Bf9pVKsBUIh6EhCqEIZD9C8TukzsPQFET2QGi73QnBpyWItUP/Lejt2+4fke1bv2T/nV/ph+9U1F4lVnMweRkcB648gkQSuk9DqSjbV9IwMQixOIw+hQsD8GbW7b+8AA+uKqWgZw8IAV8yshw97G0vZKGQg/0Hofmolil3UFuBun0QPwkNByB5Rp7LL0HdXm9scUMem2Luua5TMDQty4UczNz2nYKaQGsCrj126/PPIPsBulLe2D99HquPQvyELIfDgVJQE9hYg7cv5TGzCOmFv8fWN8rj+qq7CovzyntATWC9AC/ulY9raYMj7fIy+rYiLztNVPYPTSwBg3ehIyVvna8egtjSOkVlBRqbIHlO3m7npuUe0Yy5r9O7xJlJ/zPHmn8XsgKmsQKmsQKmsQKmsQIWS43zG41eqcJmZbBOAAAAAElFTkSuQmCC"/>
    } else if(fileSplit.includes('jpeg') || fileSplit.includes('jpg')  || fileSplit.includes('png') ) {
        return <img alt='icon-img' className='icon-image h-10 w-10' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAADI0lEQVRoge2ZyW7TUBRAT9IhcdKgoqqTmjKpn4DER7CDBX/AT8CSNWv4A9ix4xtYtBJCqBIFVEpoaZqENB4S2/G7LNKiUPxc22ncIuVI2dzr4R7fN0mBCRMmTLhMcrrEgwO5L8JLgbUsCwqhRo7Hb1Zzb8OSed1dSnhxBYoHqCK81CW1AkB1DMWkZV2XiBL4L/jvBaazfuHdGYdHRpvqlEctmOVVd55Nv5T6eZl24O6Mw5PKIRvTLsWcsDHt8rRyyL1ZJ/UzMxV4ZLRD4w+L4fE4ZCqwNuWHxquaeBwyFfgRzITGa5p4HDIVeNWdD42/1sTjkKnApl/imbnMTr9AT/Ls9As8M5dHWoVGXkaPj3p0Gi7kYX6pSOV6IfL6Tb80UsFnSS0gAgdfO7TrvT8xs+WyWC2zuF6+kOLikEpARKh96tBp9P7J1fcsVCAs35obubg4JBYQEb5vH9Npudprjmo2AqxkIJFIQAJh9+MvrLZ37rVHexaIsHK78lfc+uVitTwEuLZQoDw/m6jgs8QWCPqK3Q8t7OP4m079m4UIrN6pgEBz36bTdBE16KTd9ri+YrCwNuZVqO8pvrxv0bWS75iHJxKzhTx2SOea+w4qEBZvpJv45wr4vYDPW0163X6qFwAc7poYczMY5fDXtQ4cRGDpZnKJSAGvG7Cz1cB10hd/itPxECUUdRL7DiAs3Uw28bUCPavPp80GvhskemAUjumjFNpONH8MOpFkCdYKbL+r0/dV8irPwTE9EH0nmjUbEVi5HU9CK3CRX/4sdsdDBYIxF34KbXy3B0vwnUpofhitgEj6AuPgWD4CFEvhJRzt2YiC1Y1oCb2AGrMBQxM7QgKiJSI6MH4BGMwJpRRGKXxHrn+zyEUc+rUC6uLnrxbH7CMKjHL4nPj5xdTee6lDaBjH9E8kkp0vL30IDeNYHqIUxXL8A15EBy6kpsQ41mA4FTXD6SwRcyD7DpxiW6c79vkSV2oIDePYHoJglKIlrtwQGmZwdhJKERJRq1CNK/AfQdf0QXGsy2u3iECpxyJSExEu85fL5wIVyPPxfJ4JEyZMGJXfXUfSuVI0IAgAAAAASUVORK5CYII="/>
    } else {
        return <div className="icon-file-table"></div>
    }
  }

  const changeImage = (e) => {
    if (typeControl === 'IMAGEN') {
      if (e.target.files.length === 0) return
      const typeFileSplit = e.target.files[0].type.split('/')
      if (!typeFileSplit.includes('image')) return
      setFileValue(e.target.files[0])
      onChange(e.target.files[0])
      getBase64(e.target.files[0])
            .then(data => {
              let dataBase64 = data.split("base64,")[1]
              setValue(identifier + '_b64', dataBase64)  
            })
            .catch(err => console.log(err))
      return
    }
    setFileValue(e.target.files[0])
    onChange(e.target.files[0])
    getBase64(e.target.files[0])
            .then(data => {
              let dataBase64 = data.split("base64,")[1]
              setValue(identifier + '_b64', dataBase64)  
            })
            .catch(err => console.log(err))
  };

  useEffect(() => {
    setValue1(0)
    if (fileValue) {
      
      let val = value1;
      interval.current = setInterval(() => {
          val += Math.floor(Math.random() * 10) + 6;
  
          if (val >= 100) {
              val = 100;
              clearInterval(interval.current);
          }
          setValue1(val);
      }, 300);
  
      return () => {
          if (interval.current) {
              clearInterval(interval.current);
              interval.current = null;
          }
      }
    }
  }, [fileValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRemoveFile = () => {
    onChange(null)
    setValue(identifier + '_b64', null) 
    setFileValue(null)
    setValue1(0)
  }

  return (
    <div>
      {
        typeof(data.fileName) === 'string'
        ? (
            // <div className='flex justify-center items-center h-full mt-1 p-1 gap-x-3'>
            //   {iconTypeOfFile(data?.fileName)}
            //   <div className="flex underline">
            //     <a href={data?.url} target="_blank" rel="noreferrer noopener" className='hover:text-blue-500' >{data?.fileName}</a>
            //   </div>
            // </div>
            <div className='style-drag-area-process mt-2'>
              <div className={`image-upload-wrap border-gray-400 bg-opacity-25   h-16 bg-gray-400`}>
                <div className="h-full flex flex-col justify-center">
                  <div className='flex flex-col justify-center items-center h-full gap-y-4'>
                    <div className='flex justify-center items-center gap-x-5'>
                      {iconTypeOfFile(data.fileName)}
                      <div className="flex flex-col ">
                        <div className="flex underline">
                          <a href={data?.fileUrl} download="Acme Documentation (ver. 2.0.1).txt" target="_blank" rel="noreferrer noopener" className='hover:text-blue-500' >{data.fileName}</a>
                        </div>
                      </div>
                      <i className="pi pi-times z-50 cursor-pointer hover:text-gray-500 text-lg" onClick={onRemoveFile}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        : (
            <div className='style-drag-area-process mt-2'>
              {/* <div className={`image-upload-wrap bg-opacity-25  border-gray-400 h-16 border-dashed bg-gray-400`}> */}
              <div className={`image-upload-wrap ${errors ? ' border-red-400' : 'border-gray-400'} bg-opacity-25   h-16 bg-gray-400`}>
                <input
                  className="file-upload-input"
                  type="file"
                  accept={`${typeControl === 'IMAGEN' ? 'image/*' : '*'}`}
                  multiple
                  disabled={readOnly ? true : false}
                  onChange={(e) => {
                    changeImage(e);
                  }}
                />
                <div className="h-full flex flex-col justify-center">
                  {
                    !fileValue ?
                    (
                      <div className='flex flex-col justify-center items-center gap-y-2'>
                        <i 
                          className={`pi ${typeControl === 'IMAGEN' ? 'pi-image' : 'pi-cloud-download'} pi-image mt-3 p-5 `} 
                          style={{'fontSize': '2.5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}>
                        </i>
                        <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}}>
                        {
                          typeControl === 'IMAGEN' 
                            ? 'Selecciona el área o arrastra tu imagen para adjuntar' 
                            : 'Selecciona el área o arrastra tu archivo para adjuntar'
                        }
                        </span>
                      </div>
                    ) :
                    (
                      <div className='flex flex-col justify-center items-center h-full gap-y-4'>
                        <div className='flex justify-center items-center gap-x-5'>
                          {iconTypeOfFile(fileValue)}
                          <div className="flex flex-col ">
                            <p>{fileValue.name}</p>
                            <p>{fileValue.size}B</p>
                          </div>
                          <i className="pi pi-times z-50 cursor-pointer hover:text-gray-500 text-lg" onClick={onRemoveFile}></i>
                        </div>
                        <ProgressBar className='w-4/5' value={value1}></ProgressBar>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          )
      }
    </div>
  )
}

export default AttachmentInput