import { apiService } from "core/services/ApiService";

// En proceso 

const searchDocumentLibraries = (criterio) => {
  return apiService.Post("storage/api/Storage/Search", criterio);
}

const generateReport = (payload) => {
  return apiService.Post("storage/api/Storage/GenerateReport", payload, {
    responseType: 'blob',
  })
}


// APIS Funcionando

const getDataMaster = () => {
  return apiService.Post("storage/api/Storage/GetDataMaster");
}

const uploadFile = (data) => {
  return apiService.Post("storage/api/Storage/SaveFile", data);
}

const uploadandApproveFile = (data) => {
  return apiService.Post("storage/api/Storage/SaveFileAndSubmitWorkflow", data);
}

const getDataListFolders = () => {
  return apiService.Get("storage/api/Storage/ListFolders");
}

const getDataListFilesAndFolders = (path = '') => {
  return apiService.Get("storage/api/Storage/ListFilesAndFolders?path=" + path);
}

const getDocumentLibraryById = (documentId) => {
  return apiService.Get("storage/api/Storage/GetDocumentStorageById?id=" + documentId);
}

const getDocumentForVersion = (id, versionNumber) => {
  return apiService.Get(`storage/api/Storage/GetDocumentStorageByVersionNumber?id=${id}&versionNumber=${versionNumber}`);
}

const deleteDocumentLibrary = (documentId, documentName) => {
  return apiService.Delete(`storage/api/Storage/DeleteFile?id=${documentId}&fileName=${documentName}`);
}

const copyFile = (data) => {
  return apiService.Post("storage/api/Storage/CopyFile", data);
}

const moveFile = (data) => {
  return apiService.Post("storage/api/Storage/MoveFile", data);
}

const renameFile = (data) => {
  return apiService.Post("storage/api/Storage/RenameFile", data);
}

const getRecentFiles = () => {
  return apiService.Get("storage/api/Storage/RecentFiles");
}

const getCurrentFiles = () => {
  return apiService.Get("storage/api/Storage/CurrentFiles");
}

const getMyFiles = () => {
  return apiService.Get("storage/api/Storage/MyFiles");
}

const getTrashFiles = () => {
  return apiService.Get("storage/api/Storage/TrashFiles");
}

const isStorageEnabled = () => {
  return apiService.Get("storage/api/Storage/IsStorageEnabled");
}

const generateVersion = (reason) => {
  return apiService.Post("management/api/ConfigDynamicProcessSheet/GenerateVersion", reason)
}

const approvedDocument = (reason) => {
  return apiService.Post("management/api/Workflow/ApproveDocument", reason)
}

const rejectDocument = (reason) => {
  return apiService.Post("management/api/Workflow/RejectDocument", reason)
}

export const DocumentLibrariesService = {
  getDocumentForVersion,
  generateVersion,
  searchDocumentLibraries,
  generateReport,
  uploadandApproveFile,
  uploadFile,
  getDataListFolders,
  getDataListFilesAndFolders,
  getDocumentLibraryById,
  deleteDocumentLibrary,
  copyFile,
  moveFile,
  renameFile,
  getRecentFiles,
  getCurrentFiles,
  getMyFiles,
  getTrashFiles,
  getDataMaster,
  isStorageEnabled,
  approvedDocument,
  rejectDocument
};