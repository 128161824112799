export class RiskConstants {
  static Accion = class {
    static RiskManagement = class {
      static get GET_DATA_MASTER() {
        return '@RISK_MANAGEMENT.GET_DATA_MASTER';
      };
      static get GET_DATA(){
        return '@RISK_MANAGEMENT.GET_DATA'
      };
      static get SET_SELECT_SQUARE(){
        return '@RISK_MANAGEMENT.SET_SELECT_SQUARE'
      };
      static get SET_NEW_ID(){
        return '@RISK_MANAGEMENT.SET_NEW_ID'
      };
      static get SET_SELECT_COLOR(){
        return '@RISK_MANAGEMENT.SET_SELECT_COLOR'
      };

      static get GET_ALL_MATRIX(){
        return '@RISK_MANAGEMENT.GET_ALL_MATRIX'
      }
    };
    static RiskInventory = class {
      static get GET_DATA(){
        return '@RISK_INVENTORY.GET_DATA';
      };
      static get GET_DATA_MASTER() {
        return '@RISK_INVENTORY.GET_DATA_MASTER';
      };
      static get GET_DATA_BY_ID(){
        return '@RISK_INVENTORY.GET_DATA_BY_ID';
      };
      static get GET_DOWNLOAD_STATUS(){
        return '@RISK_INVENTORY.GET_DOWNLOAD_STATUS';
      };
      static get UPDATE_FILTER(){
        return '@RISK_INVENTORY.UPDATE_FILTER';
      };
    };
    static RiskModel = class {
      static get ADVANCED_FILTER_TOGGLE(){
        return '@RISK_MODEL.ADVANCED_FILTER_TOGGLE';
      };
      static get GET_DATA(){
        return '@RISK_MODEL.GET_DATA';
      };
      static get GET_DATA_MASTER() {
        return '@RISK_MODEL.GET_DATA_MASTER';
      };
      static get GET_DATA_MASTER_EVALUATION() {
        return '@RISK_MODEL.GET_DATA_MASTER_EVALUATION';
      };
      static get GET_DATA_BY_ID(){
        return '@RISK_MODEL.GET_DATA_BY_ID';
      };
      static get DOWNLOAD_REPORT_STATUS(){
        return '@RISK_MODEL.DOWNLOAD_REPORT_STATUS';
      };
      static get UPDATE_FILTER(){
        return '@RISK_MODEL.UPDATE_FILTER';
      };
      static get GET_CONFIGURATION_ITEMS() {
        return '@RISK_MODEL.GET_CONFIGURATION_ITEMS';
      };
      static get SET_ITEMS_SELECTED() {
        return '@RISK_MODEL.SET_ITEMS_SELECTED';
      };
      static get SET_ITEM_FILTER() {
        return '@RISK_MODEL.SET_ITEM_FILTER';
      };
      static get ADD_CRITERIA() {
        return '@RISK_MODEL.ADD_CRITERIA';
      };
      static get GET_EVALUACION() {
        return '@RISK_MODEL.GET_EVALUACION';
      };
      static get GET_OPERATOR() {
        return '@RISK_MODEL.GET_OPERATOR';
      };
      static get SET_FORMULA() {
        return '@RISK_MODEL.SET_FORMULA';
      };
      static get SET_FORMULA_INPUT() {
        return '@RISK_MODEL.SET_FORMULA_INPUT';
      };
      static get ADD_CONDITION() {
        return '@RISK_MODEL.ADD_CONDITION';
      };
      static get TOGGLE_STATE_EVALUATION() {
        return '@RISK_MODEL.TOGGLE_STATE_EVALUATION';
      };
      static get TOGGLE_STATE_COPY() {
        return '@RISK_MODEL.TOGGLE_STATE_COPY';
      };
    }
  };
  static Constants = class {
    static RiskInventory = class {
      static get CODE_INVENTORY_TYPE_MANUAL(){
        return 'RITM';
      };
      static get CODE_INVENTORY_TYPE_DINAMYC_PROCESS(){
        return 'RIDP';
      };
    };
  };
};
