
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Menu } from 'primereact/menu';
import moment from 'moment';
import { Checkbox } from 'primereact/checkbox';
import { ApprovalFlowActions } from 'features/configuration/store/actions/ApprovalFlowAction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CoreConstants } from 'core/commons/CoreConstants';
import { useClaimApprovalFlow } from 'features/configuration/hooks/useClaimApprovalFlow';
import { Link } from 'react-router-dom';


export const ApprovalFlowTable = () => {

  const advancedFilter = useSelector((state) => state.approvalFlowReducer.advancedFilter);
  const { data } = useSelector((state) => state.approvalFlowReducer.approvalFlow);
  const listUsers = useSelector((state) => state.approvalFlowReducer.users.data?.users);
  const { isDeleted } = useSelector((state) => state.approvalFlowReducer.flow);

  const history = useHistory()

  const { isView ,isEdit, isDelete } = useClaimApprovalFlow();

  const columns = [
    { field: 'source', header: 'Proceso'},
  ];

  const [selectedColumns, setSelectedColumns] = useState(columns);
  const actionsHeader = useRef(null);
  const menu = useRef(null);
  const [showConfirmDialog, setshowConfirmDialog] = useState(false);
  const [flowSelected, setFlowSelected] = useState(null);
  const [isFirstLoading, setFirstLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState(null);
  const [expandedRowsAnid, setExpandedRowsAnid] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const dispatch = useDispatch()

  useEffect(() => {
    if (isDeleted) {
        setshowConfirmDialog(false);
        dispatch(ApprovalFlowActions.resetAfterSaved());
        dispatch(ApprovalFlowActions.search());
    }
}, [isDeleted])


  useEffect(() => {
    if (isFirstLoading) {
        // tryParseFilter();
        setFirstLoading(false);
        setSortField("sourceTypeName");
        setSortOrder(1);
        // dispatch(MonitoringAction.search());
    }
  }, []);

//   useEffect(() => {
//     if (isSaved) {
//         // dispatch(MonitoringAction.resetAfterSaved());
//         dispatch(MonitoringAction.search());
//     }
// }, [isSaved])

let items = [
  {
      label: 'Editar',
      icon: 'pi pi-pencil',
      command: (e) => {
          history.push('/configuracion/flujo-aprobacion/editar/' + flowSelected.data.id);
      }
  },
  {
      label: 'Eliminar',
      icon: 'pi pi-trash',
      command: (e) => {
          setshowConfirmDialog(true);
      }
  },
];

  if (!isEdit)
    items = items.filter(c => c.label !== 'Editar');
  if (!isDelete)
    items = items.filter(c => c.label !== 'Eliminar');


const actionHeaderTemplate = (rowData) => {
  return <i
      key="action"
      className="pi pi-ellipsis-v cursor-pointer"
      onClick={(e) => actionsHeader.current.toggle(e)}></i>;
  }

  const actionBodyTemplate = (rowData) => {
    if(isEdit || isDelete){
      return <i
          key={rowData.key}
          className="pi pi-ellipsis-v cursor-pointer"
          onClick={(event) => {
              setFlowSelected(rowData);
              menu.current.toggle(event)
          }
          }></i>
    }else{
      return <div></div>
    }
  }

  const tryParseFilter = () => {
    try {
        const filters = JSON.parse(localStorage.getItem("approvalFlow-filter") || '[]');
        const myFilters = filters.filter(item => item.field === 'source');
        if (filters)
            setSelectedColumns(myFilters);
        else
            setSelectedColumns(columns);
    } catch (error) {
        localStorage.removeItem('approvalFlow-filter');
    }
  }

  const onColumnToggle = (e, item) => {
    let _selectedColumns = [...selectedColumns];

    if (e.checked)
        _selectedColumns.push(item);
    else
        _selectedColumns = _selectedColumns.filter(c => { return c.field !== e.value });

    setSelectedColumns(_selectedColumns);
    localStorage.setItem("approvalFlow-filter", JSON.stringify(_selectedColumns));
}

const getColumns = () => {
    let array = [];
    
    columns.map((item) => {
        const column = <div key={item.field} className="p-field-checkbox py-1">
            <Checkbox
                inputId={item.field}
                name="column"
                value={item.field}
                onChange={(e) => onColumnToggle(e, item)}
                checked={selectedColumns.some((x) => x.field === item.field)}
            />
            <label className="ml-1" htmlFor={item.field}>{item.header}</label>
        </div>
        array.push(column);
    });
    return array;
};

const confirmDelete = () => {
  dispatch(ApprovalFlowActions.deletedFlow(flowSelected.data.id))
}

const cancelDelete = () => {
    setshowConfirmDialog(false);
}

const [rows, setRows] = useState(10)

const onPageChange = (e) => {
    setRows(e.rows);
    advancedFilter.page = e.page + 1;
    advancedFilter.pageSize = e.rows;
    dispatch(ApprovalFlowActions.updateFilter(advancedFilter));
    dispatch(ApprovalFlowActions.search());
}

const onSort = (e) => {
  setSortField(e.sortField);
  setSortField(e.sortOrder);

  advancedFilter.columnOrder = e.sortField;
  // advancedFilter.order = e.sortOrder === 1 ? 'asc' : 'desc';
  advancedFilter.order = advancedFilter.order === 'asc' ? 'desc' : 'asc';
  advancedFilter.page = 1;
  dispatch(ApprovalFlowActions.updateFilter(advancedFilter));
  dispatch(ApprovalFlowActions.search());
}


const getCreationDate = (rowData) => { return moment(rowData.fromDate).format(CoreConstants.Format.LOCALE); }


const approvedRow = (e, name) => {
  return <div>{listUsers.find(el => el.id===e[name])?.fullName}</div>
}

const rowFirstLevel = (e, nameRow) => {

  if(nameRow==='sourceTypeName'){
    if(isView){
      return <Link className="underline text-blue-700" to={{ pathname: `/configuracion/flujo-aprobacion/visualizar/` + e.data.id }} >{e.data.sourceTypeName}</Link>
    }else{
      return <div>{e.data[nameRow]}</div>
    }
  }
  else if(nameRow!=='applicationDate'){
    return <div>{e.data[nameRow]}</div>
  }
  else{
    return <div>{getCreationDate(e.data[nameRow])}</div>
  }
}

const rowExpansionTemplate = ({children}) => {
    return (
      <div className="tasks-subtable">
        <DataTable value={children} header={null} className='ml-0' 
            // lazy loading={loading}
          emptyMessage="No se encontraron tareas">
          <Column field="approvalLevelName" header="Nivel de Aprobación"/>
          <Column field='mainApproverId' header="Aprobador Principal" body={(e) => approvedRow(e,'mainApproverId')}/>
          <Column field="substituteApprovedId" header="Aprobador Sustituto" body={(e) => approvedRow(e,'substituteApprovedId')}/>
          <Column field="rejectReturnLevel" header="Nivel de Rechazo"/>
          {/* <Column field="from" header="Fecha de creación" body={getCreationDate} /> */}
        </DataTable>
      </div>
    );
  };


  return (
    <>
    <div className="table-main table-contact mb-3 mt-2">
                    <DataTable
                        value={data}
                        emptyMessage="No se encontraron datos"
                        stripedRows
                        // rows={5}
                        editMode="row"
                        dataKey="key" //TODO: Cambiar luego por id
                        rowHover
                        size="small"
                        responsiveLayout="scroll"
                        rowExpansionTemplate={rowExpansionTemplate}
                        onSort={(e)=> onSort(e)}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        // onRowExpand={(e) => onRowExpand(e.data)}
                        lazy
                    >
                        {/* <Column expander style={{ width: "2em" }} hidden={isNewProject} />   */}
                        <Column expander style={{ width: '3em' }} />
                        <Column field="sourceTypeName" header="Tipo de Solicitud" body={(e)=>rowFirstLevel(e,'sourceTypeName')} sortable/>
                        <Column field="name" header="Nombre del Flujo" body={(e)=>rowFirstLevel(e,'name')}/>
                        <Column field="rejectionDays" header="Plazo Aprobación Principal" body={(e)=>rowFirstLevel(e,'rejectionDays')}/>
                        <Column field="applicationDate" header="Fecha de Creación" body={(e)=>rowFirstLevel(e,'applicationDate')} sortable/>
                        <Column header={<div></div>} body={actionBodyTemplate} ></Column>
                        {/* {columnComponents}  */}
                        {/* <Column header={<div></div>} hidden={isNewProject} body={RowAddTasks}></Column> */}
                    </DataTable>
                </div>


            {/* <Paginator
                key={"pag-1"}
                className="paginator-custom mt-4"
                rows={rows}
                first={(data?.currentPage - 1) * rows}
                rowsPerPageOptions={[10,20,30]}
                totalRecords={data?.rowCount}
                onPageChange={onPageChange}
            ></Paginator> */}

            <OverlayPanel ref={actionsHeader} showCloseIcon style={{ width: '300px' }}>{getColumns()}</OverlayPanel>

            <Menu model={items} popup ref={menu} id="popup_menu" />
            <ConfirmDialog
                visible={showConfirmDialog}
                onHide={cancelDelete}
                message="¿Está seguro que desea eliminar el registro?"
                header="Eliminar"
                icon="pi pi-trash" 
                accept={confirmDelete}
                reject={cancelDelete}
            />
    </>
  )
}
