import { BusinessSheetConstants } from "features/business/commons/BusinessSheetConstants"; 
import { Piner } from "shared/helpers/Piner";

export const getFilterValuesFromPiners = (piners) => {
  const filterValues = {}
  const fieldNames = BusinessSheetConstants.FieldName.Filter
  
  piners.forEach(piner => {
    if(piner.field === fieldNames.NAME)
      filterValues[fieldNames.NAME] = piner.searchValue
    if(piner.field === fieldNames.CODE) 
      filterValues[fieldNames.CODE] = piner.searchValue
    if(piner.field === fieldNames.STATUS_ID) 
      filterValues[fieldNames.STATUS_ID] = piner.searchValue
    if(piner.field === fieldNames.TYPE_ID) 
      filterValues[fieldNames.TYPE_ID] = piner.searchValue
    if(piner.field === fieldNames.FROM_DATE) 
      filterValues[fieldNames.FROM_DATE] = piner.searchValue
    if(piner.field === fieldNames.TO_DATE) 
      filterValues[fieldNames.TO_DATE] = piner.searchValue
  });

  return filterValues
}

export const getPinersValues = (values) => {
  const piners = []
  const fieldNames = BusinessSheetConstants.FieldName.Filter

  if(values[fieldNames.NAME]?.value) piners.push(new Piner({
      values: values,
      fieldNameConstant: fieldNames.NAME,
    }).getPiner())

  if(values[fieldNames.CODE]?.value) piners.push(new Piner({
    values: values,
    fieldNameConstant: fieldNames.CODE,
  }).getPiner())

  if(values[fieldNames.STATUS_ID]?.value) piners.push(new Piner({
    values: values,
    fieldNameConstant: fieldNames.STATUS_ID,
  }).getPiner())

  if(values[fieldNames.TYPE_ID]?.value) piners.push(new Piner({
    values: values,
    fieldNameConstant: fieldNames.TYPE_ID,
  }).getPiner())
  
  if(values[fieldNames.FROM_DATE]?.value) piners.push(new Piner({
    values: values,
    fieldNameConstant: fieldNames.FROM_DATE,
  }).getPiner())

  if(values[fieldNames.TO_DATE]?.value) piners.push(new Piner({
    values: values,
    fieldNameConstant: fieldNames.TO_DATE,
  }).getPiner())

  return piners
}