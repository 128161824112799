import { apiService } from 'core/services/ApiService';
const currentModule = 'customer/api/Task';

const searchTaskByCustomer = ({ fields = {}, change, documentId, sourceId }) => {
  let initialFields = {
    page: 1,
    pageSize: 10,
    columnOrder: 'scheduleDate',
    documentId: documentId,
    sourceId: sourceId,
    order: 'desc',
  };
  if (change) initialFields = { ...initialFields, ...fields };
  return apiService.Post(`${currentModule}/Search`, initialFields);
};

const deleteTask = (taskId) => {
  return apiService.Delete(`${currentModule}/Delete?id=${taskId}`);
};

const getDataMaster = () => {
  return apiService.Post(`${currentModule}/GetDataMaster`);
};

const saveCustomerTask = (payload) => {
  return apiService.Post(`${currentModule}/Save`, payload);
};

const getTaskById = (id) => {
  return apiService.Get(`${currentModule}/GetTaskById?id=${id}`
  );
};

const completeTask = (id) => {
  return apiService.Post(`${currentModule}/Complete?id=${id}`);
};

const getCommentsByTaskId = (id) => {
  return apiService.Get(`${currentModule}/GetCommentsByTaskId?id=${id}`);
}

const addComment = (payload) => {
  return apiService.Post(`${currentModule}/AddComment`, payload);
}

const deleteComment = (id) => {
  return apiService.Delete(`${currentModule}/DeleteComment?id=${id}`);
}

const generateReport = (payload) => {
  return apiService.Post(`${currentModule}/GenerateReport`, payload, {
    responseType: 'blob',
  });
};

const openTask = (id) => {
  return apiService.Post(`${currentModule}/ReOpen?id=${id}`);
};


export const TaskService = {
  addComment,
  deleteComment,
  getCommentsByTaskId,
  getDataMaster,
  getTaskById,
  searchTaskByCustomer,
  saveCustomerTask,
  deleteTask,
  completeTask,
  generateReport,
  openTask,
};