import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { StudentsAction } from "features/customers/store/actions/StudentsAction";
import scrollTop from "shared/utils/scrollTop";
import { lowercaseFirstLetter } from "shared/utils/textTransform";
import { getDynamicFields, getDynamicFieldsList } from "shared/utils/getDynamicFields";
import { StudentsConstants } from "features/customers/commons/StudentsConstants";

export const useStudent = ({ title }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const previousUrl = "/cliente/alumnos";
  const { updateTitle } = useSetTitlePage();
  const pageTitle = {
    title: "Clientes",
    subtitle: "Alumnos",
    description: title,
    previousUrl: previousUrl,
  };

  useEffect(() => {
    updateTitle(pageTitle);
  }, []);

  const { data, isLoadingDataMaster } = useSelector(
    (state) => state.studentsReducer.dataMaster
  );

  useEffect(() => {
    dispatch(StudentsAction.getDataMaster());
  }, []);

  let defaultValues = {
    firstName: null,
    fatherLastName: null,
    documentTypeId: null,
    documentNumber: null,
    businessLineId: null,
    birthday: null,
    countryId: null,
    departmentId: null,
    cityId: null,
    districtId: null,
    address: null,
    phoneNo: null,
    emailAddress: null,
    facebookProfileAddress: null,
    linkedinProfileAddress: null,
    instagramProfileAddress: null,
    professionId: null,
    professionalDegreeId: null,
    academicInstitutionId: null,
    employerId: null,
    positionId: null,
    professionalProfileId: null,
    professionalSectorId: null,
    socialNetworkId: null,
  };
  const {
    control,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });
  const valuesFields = watch();
  
  const {
    isLoading,
    isLoadingStudent,
    status: currentStatusStudent,
    data: currentStudent,
    errors: fieldsErrors,
  } = useSelector((state) => state.studentsReducer.editStudent);
  
  const params = useParams();
  const currentID = params.id;

  const isNewStudent = title === "Nuevo Alumno";

  const dynamicFields = data?.dynamicFields || []

  const dynamicFieldsDatosGenerales = getDynamicFields(dynamicFields, StudentsConstants.SectionsName.DATOS_GENERALES)
  const dynamicFieldsInformacionDeContacto = getDynamicFields(dynamicFields, StudentsConstants.SectionsName.INFORMACION_DE_CONTACTO)
  const dynamicFieldsDireccion = getDynamicFields(dynamicFields, StudentsConstants.SectionsName.DIRECCION)
  const dynamicFieldsInformacionAcademica = getDynamicFields(dynamicFields, StudentsConstants.SectionsName.INFORMACION_ACADEMICA)
  const dynamicFieldsInformacionLaboral = getDynamicFields(dynamicFields, StudentsConstants.SectionsName.INFORMACION_LABORAL)

  useEffect(() => {
    if (currentID) {
      dispatch(StudentsAction.editStudent(currentID));
    }
  }, [currentID]);

  useEffect(() => {
    scrollTop();
  }, []);
  useEffect(() => {
    const finishLoading = !isLoadingDataMaster && !isLoadingStudent;
    const hasValues = Object.values(currentStudent).length > 0;

    const datas = {
      businessLineId: "businessLines",
      countryId: "countries",
      departmentId: "departments",
      cityId: "cities",
      districtId: "districts",
      documentTypeId: "documentTypes",
      professionalDegreeId: "professionalDegrees",
      academicInstitutionId: "academicInstitutions",
      professionId: "professions",
      employerId: "employers",
      professionalSectorId: "professionalSectors",
      professionalProfileId: "professionalProfiles",
      positionId: "positions",
      socialNetworkId: "socialNetworks",
    };

    if (!isNewStudent && finishLoading && hasValues && currentID) {
      for (let key in defaultValues) {
        const keyLowercase = key.toLowerCase();
        if (currentStudent[key]) {
          if (keyLowercase.includes("id")) {
            const ID = currentStudent[key];
            const value = data[datas[key]].filter(({ id }) => id === ID)[0];
            setValue(key, value);
          } else if (keyLowercase === "birthday") {
            let date = new Date(currentStudent[key]);
            setValue(key, date);
          }
          else if (keyLowercase === "fatherlastname") {
            let fullName =
              currentStudent["fatherLastName"] +
              // " " + (currentStudent["motherLastName"] || "");
              (currentStudent["motherLastName"] ? ( " " + currentStudent["motherLastName"] ) : "");
            setValue(key, fullName);
          } else {
            setValue(key, currentStudent[key]);
          }
        } else {
          if(currentStudent.dynamicFieldValues?.length > 0){
            currentStudent.dynamicFieldValues.forEach( dynamicFieldValue => {
              setValue(`dynamicFieldData:${dynamicFieldValue.identifier}`, dynamicFieldValue.value)
            })
          }
          setValue(key, null);
        }
      }
    }
  }, [isLoadingDataMaster, isLoadingStudent]);

  useEffect(() => {
    if (!isLoading && currentStatusStudent === 0) {
      dispatch(StudentsAction.setCurrentStudent({ status: null }));
      history.push(previousUrl);
    }
  }, [currentStatusStudent, isLoading]);

  const onSubmit = (data) => {
    const student = {};
    const dynamicDataValues = getDynamicFieldsList(data, dynamicFields, isNewStudent, currentStudent)

    student.dynamicDataValues = dynamicDataValues

    for (let key in data) {
      if (data[key]) {
        const keyLowercase = key.toLowerCase();
        const key_ = lowercaseFirstLetter(key);

        if (key === "documentNumber") {
          student["documentNumber"] = data[key];
        } else if (keyLowercase.includes("id")) {
          student[key_] = data[key].id;
        } else {
          student[key_] = data[key];
        }

        // if (key === "fatherLastName") {
        //   const fullName = data[key].split(" ");

        //   if (fullName.length > 1) {
        //     student["fatherLastName"] = fullName[0];
        //     student["motherLastName"] = fullName[1];
        //     if (!isNewStudent) {
        //       data["motherLastName"] = fullName[1];
        //     }
        //   }
        // }
        if (key === "fatherLastName") {
          const fullName = data[key].split(" ");
          console.log(fullName)
          if (fullName.length > 1) {
            student["fatherLastName"] = fullName[0];
            student["motherLastName"] = fullName[1];
            if (!isNewStudent) {
              data["motherLastName"] = fullName[1];
            }
          }
        }
      }
    }

    if (!isNewStudent) {
      student.id = Number(currentID);
    } else {
      student.id = null;
    }

    dispatch(StudentsAction.saveStudent({ student, isNewStudent }));
  };
  const [showModalCancel, setShowModalCancel] = useState(false);
  const cancelAccept = () => {
    const { description, ...rest } = pageTitle;
    updateTitle(rest);
    history.push(previousUrl);
  };

  const cancelDenied = () => {
    setShowModalCancel(false);
  };

  return {
    cancelAccept,
    cancelDenied,
    setShowModalCancel,
    showModalCancel,
    isLoading,
    isLoadingStudent,
    isLoadingDataMaster,
    data,
    control,
    valuesFields,
    handleSubmit,
    onSubmit,
    fieldsErrors,
    errors,
    dynamicFieldsDatosGenerales,
    dynamicFieldsInformacionDeContacto,
    dynamicFieldsDireccion,
    dynamicFieldsInformacionAcademica,
    dynamicFieldsInformacionLaboral,
  };
};
