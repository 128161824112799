import { AttachmentsConstants } from "core/commons/AttachmentsConstants";
const initialState = {
  Attachments: {
    file: [],
    isLoading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AttachmentsConstants.Accion.Attachments.SAVE_ATTACHMENTS:
      return {
        ...state,
        Attachments: {
          ...state.Attachments,
          ...action.payload,
        },
      };
    // case RrhhConstants.Accion.Employees.SEARCH_EMPLOYEES:
    //   return {
    //     ...state,
    //     employees: {
    //       ...state.employees,
    //       ...action.payload,
    //     },
    //   };
    // case RrhhConstants.Accion.Employees.ADVANCED_FILTER_TOGGLE:
    //   return {
    //     ...state,
    //     employeeFilter: {
    //       ...state.employeeFilter,
    //       ...action.payload,
    //     },
    //   };
    // case RrhhConstants.Accion.Employees.REMOVE_FILTER_VALUES:
    //   return {
    //     ...state,
    //     employeeFilter: {
    //       values: [...action.payload],
    //     },
    //   };
    // case RrhhConstants.Accion.Employees.MODALS:
    //   return {
    //     ...state,
    //     employeeEdit: {
    //       ...state.employeeFilter,
    //       ...action.payload,
    //     },
    //   };
    // case RrhhConstants.Accion.Employees.SET_FILTER_VALUES:
    //   return {
    //     ...state,
    //     employeeFilter: {
    //       ...state.employeeFilter,
    //       ...action.payload,
    //     },
    //   };
    // case RrhhConstants.Accion.Employees.DATA_MASTER:
    //   return {
    //     ...state,
    //     dataManager: {
    //       ...state.dataManager,
    //       ...action.payload,
    //     },
    //   };
    default:
      return state;
  }
};
