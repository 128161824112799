import { useState, useEffect, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router";
import { Controller, useForm } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";
import { toastAction } from 'core/store/actions/ToastAction';

import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { Editor } from "primereact/editor";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { ProcessManagementServices } from "../services/ProcessManagementServices";
import { ProcessManagementAction } from "../store/actions/ProcessManagementAction";
import { ProcessDownload } from "../components/ProcessDownload";

import html2pdf from "html2pdf.js";

import { CoreConstants } from 'core/commons/CoreConstants';
import { Ficha } from "../components/Ficha";
import { GeneralDataProcess } from "../components/GeneralDataProcess";
import { ButtonMultipleSend } from "../components/ProceseSheet/ButtonMultipleSend";
import { Aprobacion } from "../components/Aprobacion";
import './PageEditProcess.scss'
import { VersionHistory } from "shared/components/processManagement/VersionHistory";
import { DocumentComponent } from "../components/Documents/DocumentComponent";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { ProcessManagementConstans } from "../commons/ProcessManagementConstans";
import { ModalSelectFlow } from "shared/components/ModalSelectFlow/ModalSelectFlow";

const header = <>
<span className="ql-formats">
	<button className="ql-bold" aria-label="Bold"></button>
	<button className="ql-italic" aria-label="Italic"></button>
	<button className="ql-underline" aria-label="Underline"></button>
</span>
<span className="ql-formats">
	<button
		className="ql-list"
		aria-label="Ordered"
		value="ordered"
	></button>
	<button className="ql-list" aria-label="Bullet" value="bullet"></button>
</span>
</>

const validateLength = (value) => {
	if (typeof value === 'string') {
		return value.replace(/<[^>]+>/g, '')?.length;
	} else if (value?.htmlValue) {
		return value.htmlValue.replace(/<[^>]+>/g, '').length;
	}
	return '0';
};

const flows = [
  {
    id: 1,
    name: 'Flujo 1'
  },
  {
    id: 2,
    name: 'Flujo 2'
  },
  {
    id: 3,
    name: 'Flujo 3'
  },
]
export const PageEditProcess = () => {

	const dispatch = useDispatch()
	const dataMaster = useSelector(state => state.ProcessManagementReducer?.dataMaster)
	const isLoadingMaster = useSelector(state => state.ProcessManagementReducer?.dataMaster.isLoading)
	const [isLoading, setIsLoading] = useState(true)
	const [visible, setVisible] = useState(false)
	const [versions, setVersions] = useState([])
	const [version, setVersion] = useState()
	const [lastVersion, setLastVersion] = useState()
	const [approvers, setApprovers] = useState([])
	const [isLoadingVersion, setIsLoadingVersion] = useState(false)
	const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
	const [isDownlading, setIsDownlading] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [isLast, setIsLast] = useState(true)
	const [processFormId, setProcessFormId] = useState()
	const [list, setList] = useState({})
	const [processFormSectionId, setProcessFormSectionId] = useState()
	const [position, setPosition] = useState()
	const [row, setRow] = useState(1)
	const [rowLec, setRowLec] = useState(false)
	const [historyVersions, setHistoryVersions] = useState([])
	const [borrar, setBorrar] = useState([])
	const [controls, setControls] = useState([])
	const [type, setType] = useState([])
	const [isLoadingSheet, setIsLoadingSheet] = useState(true)
	const [isLoadingControls, setIsLoadingControls] = useState(true)
	const [viewModalDocument, setViewModalDocument] = useState(false)
	const [listDocuments, setListDocuments] = useState([])
	const [visibleDocument, setVisibleDocument] = useState(false)
	const [selectDoc, setSelectDoc] = useState(null)
  const [displayFlowModal, setDisplayFlowModal] = useState(false)

	const history = useHistory();
	const [data, setData] = useState({})
	const {id, show} = useParams()
	const map = useRef()
	const {updateTitle } = useSetTitlePage()

	const querys = new URLSearchParams(useLocation().search)
	const modeview = querys.get("modeview") === "readonly"
	const isDraft = data?.process?.statusCode === ProcessManagementConstans.Aprobacion.Estado.BORRADOR
  const isPublished = data?.process?.statusCode === ProcessManagementConstans.Aprobacion.Estado.PUBLICADO
  const { roles } = useSelector(state => state.authReducer.user);
  const isAdmin = roles.some( rol => rol.codigo === CoreConstants.Roles.ADMIN )
  const [ showConfirmAdminSave, setShowConfirmAdminSave] = useState(false)
 
	const download= async() => {
		// map.current.classList.remove("hidden")
		setIsDownlading(true)
		setTimeout(()=>{
			const element = map.current
				html2pdf().set({
        margin: 0.5,
        filename: `${data?.process?.name} - v${version}`,
        image: {
            type: 'jpeg',
            quality: 0.98
        },
        html2canvas: {
            scale: 3, // A mayor escala, mejores gráficos, pero más peso
            letterRendering: true,
        },
        jsPDF: {
            unit: "in",
            format: "a4",
            orientation: 'landscape' // landscape o portrait
        }
    })
		.from(element)
    .save()
		.then((res) => {
			setIsDownlading(false)
			// map.current.classList.add("hidden")
		})
    .catch(err => console.log(err));
		}, 1500)
	}

	useEffect(()=> {
		if(show==="ShowWf"){
			updateTitle({
				title: "Aprobación Proceso",
				subtitle: "Mis Tareas",
				description: "Aprobar Proceso",
				previousUrl: "/inicio",
			})
		}else{
			updateTitle({
				title: "Gestión de Procesos",
				subtitle: "Mapa de Procesos",
				description: "Editar proceso",
				previousUrl: "/mapa-procesos/mapa-proceso",
			})
		}
  }, [])

	useEffect(()=>{
		setIsLoading(true)
		dispatch(ProcessManagementAction.getDataMaster())
		ProcessManagementServices.getProcessById(id).then(({data})=>{
			if(data.status===CoreConstants.HttpResponse.OK){
				setApprovers(data.data.approvers)
				setPosition(data.data.process.position)
				setData(data.data)
				setVersions(data?.data?.versions)
				setVersion(data.data?.versions?.length)
				setLastVersion(data.data?.versions?.length)
				setListDocuments(data.data.process.documents?.map(el=> {
					return {
						id: el.id,
						mode: "edit",
						documentId: el.documentId,
						name: el.title,
						owner: el.owner,
						latestChangeDate: el.latestChangeDate,
						url: el.url
				}
				}))
				setIsLoading(false)
			}else if(data.status===CoreConstants.HttpResponse.ERROR_FUNTIONAL){
				dispatch(toastAction.error("Proceso" , data.message))
				history.push('/inicio')
			}else{
				dispatch(toastAction.error("Proceso" , data.message))
				history.push('/inicio')
			}
		}).catch((error)=>{
    });
	},[])


	const getCreationDate = (rowData) => {
		if(rowData.creationDate)
			return moment(rowData.creationDate).format(CoreConstants.Format.LOCALE)
		else
			return null
	}

	const getEndedDate = (rowData) => {
		if(rowData.latestChangeDate)
			return moment(rowData.latestChangeDate).format(CoreConstants.Format.LOCALE)
		else
			return null
	}

	const columns = [
    { field: 'name', header: 'Nombre' },
    { field: 'owner', header: 'Propietario' },
    { field: 'latestChangeDate', header: 'Última Modificación' }
  ];


	const getNameColumn = (rowData) => {
		return <div><a href={rowData.url}>{rowData.name}</a></div>
	}

	const columnComponents = columns.map(col => {

		if (col.field === 'creationDate')
				return <Column key={col.field} field={col.field} header={col.header} body={getCreationDate} sortable />;

		if (col.field === 'latestChangeDate')
				return <Column key={col.field} field={col.field} header={col.header} body={getEndedDate} sortable />;

		if (col.field === 'name')
				return <Column key={col.field} field={col.field} header={col.header} body={getNameColumn} sortable />;

		return <Column key={col.field} field={col.field} header={col.header} sortable />;
});

// console.log(isLast)

const actionBodyTemplate = (row) => {
	return <div className="flex justify-center"><i className="pi pi-trash cursor-pointer" onClick={()=> {
		setSelectDoc(row)
		setVisibleDocument(true)
	}}></i></div>
}

	useEffect(()=>{
		if(version){
			ProcessManagementServices.getVersionsDetailedList(id, version).then(({data})=>{
				setHistoryVersions(data.data)
			})
		}
	},[version])

	const {
		control,
		handleSubmit,
		setValue,
		watch,
		reset,
		formState: { errors },
} = useForm({
		defaultValues: {
				code: "",
				name: "",
				processTypeId: "",
				responsibleId: "",
				description: "",
				scope: "",
				processDate: "",
				elaboratedById: "",
				flowId: null,
		}
});

const howManyTimes = (array) => {
  let obj={}
  for(let i=0; i<array.length; i++){
    if(obj[array[i]]){
      obj[array[i]]++
    } else {
      obj[array[i]]=1
    }
  }
  return Math.max(...Object.values(obj), 0)
}

useEffect(()=>{
	if(!isLoadingMaster && !isLoading){
		setRowLec(false)
		reset({})
		reset({
			code: data.process?.code,
			name: data.process?.name,
			description: data.process?.description,
			scope: data.process?.scope,
			processTypeId: "",
			responsibleId: "",
			processDate: new Date(data.process?.processDate),
			elaboratedById: data.process?.elaboratedById,
		})
		// setValue("processDate", new Date(data.Process?.ProcessDate))
		setValue("processTypeId", data.process?.processTypeId)
		setValue("responsibleId", data.process?.responsibleId)
		const aux = []
		data.details.forEach((item) => {
			item.details.forEach((item1)=>{
				if(aux.includes(item1?.processFormControlId?.toString())){
					if(item1.valueReferenceId){
						setValue(item1?.processFormControlId?.toString()+`-${Number(aux.filter(item2 => item2 === item1?.processFormControlId?.toString()).length)+1}`,item1.valueReferenceId)
					}else{
						setValue(item1?.processFormControlId?.toString()+`-${Number(aux.filter(item2 => item2 === item1?.processFormControlId?.toString()).length)+1}`,item1.valueText)
					}
				}else{
					if(item1.valueReferenceId){
						setValue(item1?.processFormControlId?.toString(),item1.valueReferenceId)
					}else{	
						setValue(item1?.processFormControlId?.toString(),item1.valueText)
					}
				}
				if(item.controlId === 23)	aux.push(item1?.processFormControlId?.toString())
			})
		})
		setRow(prev => howManyTimes(aux))
		setRowLec(true)
	}
},[isLoadingMaster, isLoading])

useEffect(()=>{
	setIsLoadingSheet(true)
	setIsLoadingControls(true)
	ProcessManagementServices.getActiveSheet().then(({data})=>{
		setProcessFormId(data.data?.processId)
		if(data.data?.section){
			const aux = {}
			data.data?.section?.controls.filter((item)=>item.controlId === 21).forEach((item)=>{
				aux[item.id] = item.details
			})
			data.data?.section?.controls.filter((item)=>item.controlId === 22).forEach((item)=>{
				aux[item.id] = item.details
			})
			setProcessFormSectionId(data.data?.section?.id)
			setList(aux)
			setControls(data.data?.section?.controls)
			setIsLoadingSheet(false)
		}else{
			setControls([])
			setIsLoadingSheet(false)
		}
	})
	ProcessManagementServices.listControls().then(({data})=>{
		
		setType(data.data)
		setIsLoadingControls(false)
	})
},[])

	const valuesFields = watch()

	const submit = (data, type,value) => {
		const {
			code,
			name,
			processTypeId,
			responsibleId,
			description,
			scope,
			processDate,
			elaboratedById,
      flowId,
			...details
		} = data

		Object.keys(details).forEach(key=>{
			if(details[key]===undefined){
				delete details[key]
			}
		})
		const aux = Object.keys(details).map(key=>{
			if(Object.keys(list).includes(key)){
				return {processFormControlId: Number(key), valueText: list[key].filter(item=>Number(item.id)===Number(details[key]))[0].text, processFormSectionId, valueReferenceId: Number(details[key])}
			}
			return {processFormControlId: isNaN(Number(key))?Number(key.split("-")[0]):Number(key),  valueText: details[key] ? String(details[key]) : '', processFormSectionId, valueReferenceId: null}
		
		})
		const send = {
			processFormId,
			code,
			name,
			processTypeId,
			responsibleId,
			description,
			scope,
			processDate,
			elaboratedById,
			details: aux,
			documents: listDocuments.map(el=> {
				return {
					id: el.mode==='edit'?el.id:null,
					processId: processFormId,
					documentId: el.documentId
				}
			})
		}

		Object.keys(send).forEach((key)=>{
			if(send[key] === ""){
				delete send[key]
			}
		})

		if(type === 1){

	
			setIsLoadingSubmit(true)
			ProcessManagementServices.saveProcess({...send,position,id: Number(id)}).then(({data})=>{
				setIsLoadingSubmit(false)
				if(data.status === CoreConstants.HttpResponse.OK){
					dispatch(toastAction.success("Proceso" ,'Guardado correctamente'))
					history.push('/mapa-procesos/mapa-proceso')
				}else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
					dispatch(toastAction.warn('Error', data.message))
				}else if(data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS){
					dispatch(toastAction.warn('Error', data.message))
				}
			}).catch((error)=>{
				setIsLoadingSubmit(false)
			})
		}else if(type === 2){
			send.workflowId = data.flowId
			setIsLoadingSubmit(true)
			ProcessManagementServices.saveProcessAndSubmitWorkflow({...send,position,id: Number(id)}).then(({data})=>{
				setIsLoadingSubmit(false)
				if(data.status === CoreConstants.HttpResponse.OK){
					dispatch(toastAction.success("Proceso" ,'Guardado correctamente'))
					history.push('/mapa-procesos/mapa-proceso')
				}else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
					dispatch(toastAction.warn('Error', data.message))
				}else if(data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS){
					dispatch(toastAction.warn('Error', data.message))
				}
				else{
					dispatch(toastAction.error("Proceso" ,data.message))
				}
			}).catch((error)=>{
				setIsLoadingSubmit(false)
			});
		}else if(type === 3){
			if(Object.keys(errors).length === 0){
				setIsLoadingVersion(true)
				ProcessManagementServices.saveProcessAndGenerateVersion({...send, processId: Number(id), position, reason: value}).then((data)=>{
					setIsLoadingVersion(false)
					if(data.data.status === CoreConstants.HttpResponse.OK){
						setShowModal(false)
						setIsLoading(true)
						ProcessManagementServices.getProcessById(id).then(({data})=>{
							setApprovers(data.data.approvers)
							setPosition(data.data.process.position)
							setData(data.data)
							setVersions(data?.data?.versions)
							
							setVersion(data.data?.versions?.length)
							setLastVersion(data.data?.versions?.length)
							setIsLoading(false)
						})
					}else if(data.data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
						setShowModal(false)
						setIsLoadingVersion(false)
						dispatch(toastAction.warn("Proceso" ,data.message))
					}else if(data.data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS){
						setShowModal(false)
						setIsLoadingVersion(false)
						dispatch(toastAction.warn("Proceso" ,data.message))
					}else {
						setShowModal(false)
						setIsLoadingVersion(false)
						dispatch(toastAction.error("Proceso" ,data.message))
					}
				}).catch((error)=>{
					setIsLoadingVersion(false)
					setShowModal(false)
				})
			}else{
				setShowModal(false)
			}
		}

	}

	const items = [
    {
        label: 'Guardar',
        icon: "pi pi-save",
        type: "submit",
        command: ()=>{
          handleSubmit((e)=>{submit(e,1)})()
        }
    },
    {
      label: 'Guardar y Aprobar',
      icon: "pi pi-upload",
      command: ()=>{
				setDisplayFlowModal(true)
        // handleSubmit((e)=>{submit(e,2)})()
      }
  }
  ];

	const onSubmitApprove = () => {
    handleSubmit((e)=>{submit(e,2)})()
  }

	return (
		<div className="bg-white">
			<div className="bg-white flex gap-4 flex-wrap md:flex-nowrap">
			<div style={{width: "-webkit-fill-available"}}>
				<form
						className="form-custom p-0"
				>
						<div className="form-modal p-0 w-auto">
								<Panel header="DATOS GENERALES" toggleable>
										<div className="grid sm:grid-cols-3 md:grid-cols-3 gap-2">
												{isLoading || isLoadingMaster? (
														<Skeleton height="2.2rem" />
												) : (
														<span className="p-float-label w-full mt-4"  style={{minWidth: 145}}>
																<Controller
																		control={control}
																		render={({
																				field: { onChange, onBlur, value, name, ref },
																				fieldState: { invalid, isTouched, isDirty, error },
																		}) => (
																				<InputText
																						type="text"
																						id="code"
																						name="code"
																						onChange={onChange}
																						onBlur={onBlur}
																						value={value}
																						maxLength={10}
																						disabled={show === "ShowWf" || show === "readonly"}
																						className={errors?.code ? 'p-invalid w-full' : (show === "ShowWf" || show === "readonly"? 'disabled w-full':'w-full')}
																				/>
																		)}
																		name="code"
																		rules={{
																				required: 'El codigo es requerido',
																		}}
																/>
																<label htmlFor="code">Código del Proceso *</label>
														</span>
												)}

												<span className="p-float-label w-full mt-4 hidden md:block">
														&nbsp;
												</span>
												<span className="p-float-label w-full mt-4 hidden md:block">
														&nbsp;
												</span>
										</div>

										{/* PROCESS NAME */}

										<div className="grid  sm:grid-cols-1 md:grid-cols-1 gap-2 mt-2 ">
												{isLoading || isLoadingMaster? (
																<Skeleton height="2.2rem" width="20rem"/>
														) : (
																<span className="p-float-label w-full mt-4">

																		<Controller
																				control={control}
																				render={({
																						field: { onChange, onBlur, value, name, ref },
																						fieldState: { invalid, isTouched, isDirty, error },
																				}) => (
																						<InputText
																								disabled={show === "ShowWf" || show === "readonly"}
																								id="name"
																								name="name"                         
																								onChange={onChange}
																								onBlur={onBlur}
																								value={value}
																								className={errors?.name ? 'p-invalid w-full' : (show === "ShowWf" || show === "readonly"?'w-full disabled': 'w-full')}
																						/>
																				)}
																				name="name"
																				rules={{
																				required: 'El Nombre de Proceso es requerido',
																		}}
																		/>
																		<label htmlFor="name">Nombre del Proceso *</label>
																</span>
																
												)}
										</div>

										{/* PROCESS TYPE */}

										<div className="grid sm:grid-cols-3 md:grid-cols-3 gap-2 mt-2">
												{isLoading || isLoadingMaster? (
														<Skeleton height="2.2rem" />
												) : (
														<span className="p-float-label w-full mt-4"  style={{minWidth: 145}}>
																<Controller
																		control={control}
																		render={({
																				field: { onChange, onBlur, value, name, ref },
																				fieldState: { invalid, isTouched, isDirty, error },
																		}) => (
																				<Dropdown
																						disabled={show === "ShowWf" || show === "readonly"}
																						showClear
																						inputRef={ref}
																						id="processTypeId"
																						name="processTypeId"
																						options={dataMaster.data?.processTypes?.map(item=> ({label: item.name, value: item.id}))}  
																						onChange={onChange}
																						value={value}
																						className={errors?.processTypeId ? 'p-invalid w-full' : (show === "ShowWf" || show === "readonly"?'w-full disabled': 'w-full')}
																				/>
																		)}
																		name="processTypeId"
																		rules={{
																				required: 'El tipo es requerido',	
																		}}
																/>
																<label htmlFor="processTypeId">Tipo del proceso *</label>
														</span>
												)}
												</div>

												<div className="grid sm:grid-cols-3 md:grid-cols-3 gap-2 mt-2">
												{isLoading || isLoadingMaster? (
														<Skeleton height="2.2rem" />
												) : (
														<span className="p-float-label w-full mt-4" style={{minWidth: 145}}>

																<Controller
																		control={control}
																		render={({
																				field: { onChange, onBlur, value, name, ref },
																				fieldState: { invalid, isTouched, isDirty, error },
																		}) => (
																				<Dropdown
																						disabled={show === "ShowWf" || show === "readonly"}
																						optionLabel="label"
																						filter
																						filterBy="label"
																						showClear
																						inputRef={ref}
																						id="responsibleId"
																						name="responsibleId"
																						options={dataMaster.data?.responsibles?.map(item=> ({label: item.fullName, value: item.id}))}     
																						onChange={onChange}
																						value={value}
																						className={errors?.responsibleId ? 'p-invalid w-full' : (show === "ShowWf" || show === "readonly"?'w-full disabled': 'w-full')}
																				/>
																		)}
																		name="responsibleId"
																/>
																<label htmlFor="responsibleId">Responsable del proceso</label>
														</span>
												)}
												<span className="p-float-label w-full mt-4 hidden md:block">
														&nbsp;
												</span>
										</div>

                    {/* <div className="grid sm:grid-cols-3 md:grid-cols-3 gap-2 mt-2">
												{isLoading || isLoadingMaster ? (
														<Skeleton height="2.2rem" />
												) : (
														<span className="p-float-label w-full mt-4"  style={{minWidth: 145}}>
																<Controller
                                  control={control}
                                  name="sectionNumber"
                                  rules={{
                                      required: 'La sección es requerida',	
                                  }}
                                  render={({field: { onChange, value }}) => (
                                    <Dropdown
                                      options={(() => {
                                        const quantity = dataMaster.data?.processTypes
                                        ?.find( proc => proc.id ===  valuesFields.processTypeId)?.sectionQuantity

                                        const options = []

                                        for( let i = 0; i<quantity; i++){
                                          options.push(
                                            {
                                              value: i+1,
                                              label: `Sección ${i+1}`
                                            }
                                          )
                                        }
                                        return options
                                      })()}  
                                      name="sectionNumber"
                                      showClear
                                      onChange={onChange}
                                      value={value}
                                      className={errors?.sectionNumber ? 'p-invalid w-full' : 'w-full'}
                                    />
                                  )}
																/>
																<label htmlFor="sectionNumber">Sección *</label>
														</span>
												)}
                    </div> */}

							<div className="mt-5">
								{isLoading || isLoadingMaster? (
														<Skeleton height="2.2rem" />
												) : (
                <Controller
									name="description"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <span className="w-full mt-4">
											<label htmlFor="description" style={{fontSize: "0.75rem", color:"#ced4da"}}>Objetivo del proceso</label>
                      <Editor
												readOnly={show === "ShowWf" || show === "readonly"}
												id="description"
                        headerTemplate={header}
                        onTextChange={(e) => onChange(e.htmlValue)}
                        value={value}
												name="description"
                        className={`${errors.description?.type ==='required' ? 'p-invalid': (show === "ShowWf" || show === "readonly"?'disabled': '')}`}
                      />
                      <p className="font-semibold text-base text-gray-500 lg:mt-1 mt-3 text-right">
                        Caracteres{' '}
                        {validateLength(
                          valuesFields.description
                        )}{' '}
                        / 4000{' '}
                      </p>
                    </span>
                  )}
                />)
												}
            	</div>          

							<div className="mt-5">
							{isLoading || isLoadingMaster? (
														<Skeleton height="2.2rem" />
												) : (
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <span className="w-full mt-4">
											<label htmlFor="description" style={{fontSize: "0.75rem", color:"#ced4da"}}>Alcance del proceso</label>
                      <Editor
												readOnly={show === "ShowWf" || show === "readonly"}
                        headerTemplate={header}
                        onTextChange={(e) => onChange(e.htmlValue)}
                        value={value}
												name="scope"
												id="scope"
                        className={`${errors.scope?.type ==='required' ? 'p-invalid': (show === "ShowWf" || show === "readonly"?'disabled': '')}`}
                        
                      />
                      <p className="font-semibold text-base text-gray-500 lg:mt-1 mt-3 text-right">
                        Caracteres{' '}
                        {validateLength(
                          valuesFields.scope
                        )}{' '}
                        / 4000{' '}
                      </p>
                    </span>
                  )}
                  name="scope"
                />)
							}
            </div>    

								</Panel>
							<Panel header="FICHA DE CARACTERIZACIÓN " toggleable>
								<div>
								<Ficha type={type} isLoadingSheet={isLoadingSheet} controls={controls} isLoadingControls={isLoadingControls} control={control} errors={errors} list={list} setList={setList} setProcessFormSectionId={setProcessFormSectionId} setProcessFormId={setProcessFormId} show={show} data={data} setValue={setValue} isLoadingMaster={isLoadingMaster} isLoading={isLoading} row={row} rowLec={rowLec}/>
								</div>
							</Panel>
							<Panel header="DOCUMENTOS">
								<Button 
								className={`sig-button sig-primary mb-3 ${(show === "ShowWf" || show === "readonly")?'hidden':'visible'}`}
								type="button"
								label="Añadir Documento"
								disabled={show === "ShowWf" || show === "readonly"}
								onClick={()=> setViewModalDocument(true)}/>
								<DataTable
									value={listDocuments}
									stripedRows
									responsiveLayout="scroll"
									size="small"
									emptyMessage="No se encontraron documentos"
								>
									{columnComponents}
									<Column header="" body={actionBodyTemplate} ></Column>
								</DataTable>
							</Panel>
							{historyVersions.length > 0 && <VersionHistory historyVersions={historyVersions}/>}

{
	(Object.keys(errors).length > 0) && 
		<div className='message error'>
			<ul>
					{
						Object.keys(errors).map((item, index)=>{return(
									<li key={index} className="p-error">{errors[item].message}</li>
						)})
					}      
			</ul>
		</div>
}

						</div>
				</form>
				<ConfirmDialog
						visible={visible}
						onHide={() => setVisible(false)}
						message="¿Está seguro que desea cancelar?"
						header="Cancelar"
						icon="pi pi-exclamation-triangle"
						accept={() => { 
							// dispatch(BusinessAction.getDataMaster())
							history.push('/mapa-procesos/mapa-proceso')}}
				/>
        <ConfirmDialog
          visible={showConfirmAdminSave}
          onHide={() => setShowConfirmAdminSave(false)}
          message="Está a punto de modificar un proceso PUBLICADO. Sus cambios no requieren aprobación. ¿Desea continuar?"
          header="Guardar"
          icon="pi pi-exclamation-triangle"
          accept={() => { 
            // dispatch(BusinessAction.getDataMaster())
            handleSubmit((e)=>{submit(e,1)})()
          }}
				/>
				<ConfirmDialog
						visible={visibleDocument}
						onHide={() => {
							setVisibleDocument(false)
							setSelectDoc(null)
						}}
						message="¿Está seguro que desea eliminar el documento?"
						header="Eliminar"
						icon="pi pi-exclamation-triangle"
						accept={() => {
							setListDocuments(listDocuments.filter(el=> el.documentId!==selectDoc?.documentId))
						}}
				/>
			</div>

			<div style={{width: '20rem'}}> 
				<form>
					<GeneralDataProcess isLoading={isLoading} isLoadingMaster={isLoadingMaster} control={control} errors={errors} dataMaster={dataMaster} data={data} handleSubmit={handleSubmit} submit={submit} approvers={approvers} setApprovers={setApprovers} isLoadingVersion={isDownlading || isLoadingVersion || isLoadingSubmit} isNew={false} id={id} isLast={isLast} setIsLast={setIsLast} setIsLoading={setIsLoading} setData={setData} versions={versions} version={version} setVersion={setVersion} lastVersion={lastVersion} showModal={showModal} setShowModal={setShowModal} show={show}/>
				</form> 
			</div>
			</div>
			{
				show==="ShowWf" ? (<Aprobacion source="process" modeview={modeview} id={id}/>) : 
				(
					<div className="flex justify-end gap-4 mt-3">
				<Button
						icon="pi pi-times"
						type="button"
						label="Cancelar"
						loading={isDownlading || isLoadingVersion || isLoadingSubmit}
						onClick={() => { setVisible(true); }}
						className="sig-button sig-secondary mt-4"
				/>
				{
					// (isLast && (show !== "readonly")  )&& 
					( !isLast || show === "readonly" || !isDraft ) ? null : 
					<ButtonMultipleSend 
						isLoading={isDownlading | isLoadingVersion || isLoadingSubmit}
						items={items}
					/>
				}
        {
          ( isPublished && isAdmin ) &&
            <Button
              icon="pi pi-save"
              type="button"
              label="Guardar"
              loading={isDownlading || isLoadingVersion || isLoadingSubmit}
              onClick={() => { setShowConfirmAdminSave(true); }}
              className="sig-button sig-primary mt-4"
            />
        }
				{ 
					<Button
						loading={isDownlading | isLoadingVersion || isLoadingSubmit}
						icon="pi pi-download"
						type="button"
						label="Descargar"
						className="sig-button sig-success mt-4"
						onClick={download}
				/>
				}
			</div>
				)
			}

      {
        isDownlading &&
        <div ref={map} className="processEditDownload mt-12" id="processEditDownload">
            <ProcessDownload data={data} dataMaster={dataMaster} version={version} id={id} approvers={approvers}>
              <Ficha controls={controls} type={type} isLoadingSheet={isLoadingSheet} isLoadingControls={isLoadingControls} control={control} errors={errors} list={list} setList={setList} setProcessFormSectionId={setProcessFormSectionId} setProcessFormId={setProcessFormId} data={data} setValue={setValue} isLoadingMaster={isLoadingMaster} isLoading={isLoading} row={row} rowLec={rowLec} forDownload={true}/>
            </ProcessDownload>
        </div>
      }

			<ModalSelectFlow 
        title={"Seleccione el flujo"}
        description={"Seleccione el flujo al que desea enviar el documento"}
        setDisplayModal={setDisplayFlowModal}
        displayModal={displayFlowModal}
        flows={dataMaster.data?.workflowDocumentTypes}
        onSubmitApprove={onSubmitApprove}
        setValueField={setValue}
        field="flowId"
      />
			<DocumentComponent visible={viewModalDocument} setVisible={setViewModalDocument} listDocuments={listDocuments} setListDocuments={setListDocuments}/>
		</div>

)
}