import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TabView, TabPanel } from 'primereact/tabview';

import ColorCorporateComponent from 'features/configuration/components/sistem/ColorCorporateComponent';
import LogoCompanyComponent from 'features/configuration/components/sistem/logo-empresa/LogoCompanyComponent';
import TypeMoneyComponent from 'features/configuration/components/sistem/TypeMoneyComponent';
import UnitMeasureComponent from 'features/configuration/components/sistem/unit-measure/UnitMeasureComponent';
import './SistemPage.scss';
import { AppSettingsAction } from 'features/configuration/store/actions/AppSettingsAction';
import scrollTop from 'shared/utils/scrollTop';

const SistemPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    dispatch(AppSettingsAction.getLogos());
  }, []);

  useEffect(() => {
    dispatch(AppSettingsAction.getColors());
  }, []);

  useEffect(() => {
    dispatch(AppSettingsAction.getTypeMoney());
  }, []);

  useEffect(() => {
    dispatch(AppSettingsAction.getUnitsOfMeasure());
  }, []);

  return (
    <div className="card tabs-sistem">
      <TabView className="tabview-custom" orientation="bottom">
        <TabPanel header="Logo de Empresa" leftIcon="pi pi-calendar">
          <LogoCompanyComponent />
        </TabPanel>
        <TabPanel header="Color Corporativo" leftIcon="pi pi-palette">
          <ColorCorporateComponent />
        </TabPanel>
        <TabPanel header="Tipo de Moneda" leftIcon="pi pi-money-bill">
          <TypeMoneyComponent />
        </TabPanel>
        <TabPanel header="Unidad de medida" leftIcon="pi pi-search">
          <UnitMeasureComponent />
        </TabPanel>
      </TabView>
    </div>
  );
};

export default SistemPage;
