import { Tooltip } from 'primereact/tooltip'
import React from 'react'

const SurveyAnalysisCard = ({data}) => {

  const getUnitByType = (card) => {
    if (card.code === 'TAC') {
      return <p>{card.count} %</p>
    }
    if (card.code === 'TAT') {
      return <p>{card.count} <span className='text-base'>{card.count === 1 ? ' min': 'mins'}</span></p>
    }
    return <p>{card.count}</p>
  }

  return (
    <div className='flex justify-center sm:justify-between w-full items-center flex-wrap my-4 gap-4'>
    {
      data && <>
        {
          data.map((item, index) =>
            <div key={index} >
              <Tooltip target=".surveyAnalysisCard" mouseTrack mouseTrackLeft={10}/>
              <div 
                className={`surveyAnalysisCard w-48 md:w-56  xl:w-64 hover:bg-gray-100  text-center justify-center items-center flex cursor-pointer rounded-lg border-2 shadow-xl h-28`} 
                // style={{minWidth: '20rem'}} 
                data-pr-tooltip={item.tooltipText} data-pr-position="top"
              >
                {/* <div className='w-20 ml-2 flex justify-center items-center'>
                  <i className={`pi ${item.icon} h-full`} style={{fontSize: "30px", color:"#54AEFD"}}/>
                </div> */}
                <div className='w-full px-2 flex flex-col gap-y-1'>
                  <div className="w-full h-1/2 flex justify-center items-center text-3xl">
                    {getUnitByType(item)}
                    
                  </div>
                  <div className="w-full h-1/2 flex justify-center items-center">
                    <p className='text-base'>{item.description}</p>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </>
    }
  </div>
  )
}

export default SurveyAnalysisCard