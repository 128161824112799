import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SistemPage from './pages/sistem/SistemPage';
import UsersPage from './pages/users/UsersPage';
import UserPage from './pages/users/UserPage';
import MyAccountPage from './pages/my-account/MyAccountPage';
import RolPrivilegePage from './pages/roles-privileges/RolPrivilegePage';
import RolesPrivilegesPage from './pages/roles-privileges/RolesPrivilegesPage';
import { SearchDynamicFieldsPage } from 'features/configuration/pages/dynamicFields/search/SearchDynamicFieldsPage'
import { DynamicFieldsPage } from 'features/configuration/pages/dynamicFields/dynamicFieldsViews/DynamicFieldsPage';

import roleReducer from 'features/configuration/store/reducers/RolesReducer';
import userReducer from 'features/configuration/store/reducers/UserReducer';
import appSettingsReducer from 'features/configuration/store/reducers/AppSettingsReducer';
import SearchProcessesPage from './pages/processes/search/SearchProcessesPage';
import ProcessesPage from './pages/processes/processes/ProcessesPage';
import processReducer from 'features/configuration/store/reducers/ProcessesReducer';
import SearchApprovalFlow from './pages/approval-flow/search/SearchApprovalFlow';
import approvalFlowReducer from 'features/configuration/store/reducers/ApprovalFlowReducer';
import tasksReducer from "features/customers/store/reducers/TasksReducer";
import { NewApprovalFlow } from './components/approval-flow/NewApprovalFlow';
// import SearchDinamicProcess from 'features/configuration/pages/dinamicProcess/search/SearchDinamicProcess';
// import DinamicProcessViewPage from './pages/dinamicProcess/dinamicProcessView/DinamicProcessViewPage';
// import DinamicProcessViewPage from 'features/configuration/pages/dinamicProcess/dinamicProcessView/DinamicProcessViewPage';
import dinamicProcessReducer from 'features/configuration/store/reducers/DinamicProcessReducer';
import dynamicFieldsReducer from 'features/configuration/store/reducers/DynamicFieldsReducer';
import { ProcessPage } from './pages/Process/Process/ProcessPage';

const ConfigurationRouting = (reducerRegistry) => {
  const { path } = useRouteMatch();
  reducerRegistry.register({ roleReducer });
  reducerRegistry.register({ userReducer });
  reducerRegistry.register({ appSettingsReducer });
  reducerRegistry.register({ processReducer });
  reducerRegistry.register({ dinamicProcessReducer });
  reducerRegistry.register({ dynamicFieldsReducer });
  reducerRegistry.register({ approvalFlowReducer })
  reducerRegistry.register({ tasksReducer })

  return (
    <Switch>
      <Route exact path={`${path}/sistema`} component={SistemPage} />
      <Route path={`${path}/mi-cuenta`} component={MyAccountPage} />
      <Route path={`${path}/usuarios`} component={UsersPage}>
        <Route exact path={`${path}/usuarios`} component={UsersPage} />
        <Route
          exact
          path={`${path}/usuarios/nuevo`}
          component={() => <UserPage title="Nuevo Usuario" />}
        />
        <Route
          path={`${path}/usuarios/editar/:id`}
          component={() => <UserPage title="Editar Usuario" />}
        />
        <Route
          path={`${path}/usuarios/visualizar/:id`}
          component={() => <UserPage title="Ver Usuario" mode='view' />}
        />
      </Route>
      <Route path={`${path}/rol-privilegios`} component={RolesPrivilegesPage}>
        <Route
          exact
          path={`${path}/rol-privilegios`}
          component={RolesPrivilegesPage}
        />
        <Route
          exact
          path={`${path}/rol-privilegios/nuevo`}
          component={() => <RolPrivilegePage title="NUEVO ROL" />}
        />
        <Route
          exact
          path={`${path}/rol-privilegios/editar/:id`}
          component={() => <RolPrivilegePage title="EDITAR ROL" />}
        />
        <Route
          exact
          path={`${path}/rol-privilegios/visualizar/:id`}
          component={() => <RolPrivilegePage title="VER ROL" mode='view' />}
        />
      </Route>
      <Route path={`${path}/flujo-aprobacion`}>
        <Switch>
          <Route
              exact
              path={`${path}/flujo-aprobacion`}
              component={SearchApprovalFlow}
            />
            <Route
              exact
              path={`${path}/flujo-aprobacion/nuevo`}
              component={() => <NewApprovalFlow title="Configuración" description="Nuevo flujo" subtitle="Flujo de Aprobación" />}
            />
            <Route
              exact
              path={`${path}/flujo-aprobacion/editar/:id`}
              component={() => <NewApprovalFlow title="Configuración" description="Editar flujo" subtitle="Flujo de Aprobación" />}
            />
            <Route
              exact
              path={`${path}/flujo-aprobacion/visualizar/:id`}
              component={() => <NewApprovalFlow title="Configuración" description="Ver flujo" subtitle="Flujo de Aprobación" mode='view' />}
            />
        </Switch>
      </Route>
      <Route path={`${path}/procesos`}>
        <Switch>
            <Route
              exact
              path={`${path}/procesos`}
              component={SearchProcessesPage}
            />
            <Route
              exact
              path={`${path}/procesos/nuevo`}
              component={() => <ProcessesPage title="Nuevo" />}
            />
            <Route
              exact
              path={`${path}/procesos/:id`}
              component={() => <ProcessesPage title="Editar" />}
            />
        </Switch>
      </Route>

      <Route path={`${path}/mapa-procesos`}>
        <Switch>
          <Route
            exact
            path={`${path}/mapa-procesos`}
            component={ProcessPage}
          />
        </Switch>
      </Route>

      <Route path={`${path}/campos-dinamicos`}>
        <Switch>
          <Route
            exact
            path={`${path}/campos-dinamicos`}
            component={SearchDynamicFieldsPage}
          />
          <Route
            exact
            path={`${path}/campos-dinamicos/nuevo`}
            component={DynamicFieldsPage}
          />
          <Route
            exact
            path={`${path}/campos-dinamicos/editar/:id`}
            component={DynamicFieldsPage}
          />
          <Route
            exact
            path={`${path}/campos-dinamicos/view/:id`}
            component={() => <DynamicFieldsPage mode='view' />}
          />
        </Switch>
      </Route>
      {/* <Route path={`${path}/procesos-dinamicos`}>
        <Switch>
          <Route
            exact
            path={`${path}/procesos-dinamicos`}
            component={()=> <SearchDinamicProcess title="Proceso Dinámico" />}
          />
          <Route
            exact
            path={`${path}/procesos-dinamicos/:id`}
            component={()=> <DinamicProcessViewPage title="Proceso Dinámico" />}
          />
        </Switch>
      </Route> */}
      {/* <Route path={`/proceso-dinamico`}>
        <Switch>
          <Route
            exact
            path={`/proceso-dinamico/:id`}
            component={()=> <DinamicProcessViewPage title="Proceso Dinámico" />}
          />
        </Switch>
      </Route> */}
    </Switch>
  );
};

export default ConfigurationRouting;
