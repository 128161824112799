import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from "core/store/actions/ToastAction";
import { RiskModelServices } from 'features/riskManagement/services/RiskModelServices';
import moment from 'moment';
import FileSaver from 'file-saver';
import { RiskConstants } from 'features/riskManagement/commons/RiskConstants';

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: RiskConstants.Accion.RiskModel.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const setConfiguration = (payload) => ({
	type: RiskConstants.Accion.RiskModel.GET_CONFIGURATION_ITEMS,
	payload,
})

const setSelected = (payload) => ({
	type: RiskConstants.Accion.RiskModel.SET_ITEMS_SELECTED,
	payload,
})

const setItemFilter = (payload) => ({
	type: RiskConstants.Accion.RiskModel.SET_ITEM_FILTER,
	payload,
})

const setCriteria = (payload) => ({
	type: RiskConstants.Accion.RiskModel.ADD_CRITERIA,
	payload
})

const setEvaluacion = (payload) => ({
	type: RiskConstants.Accion.RiskModel.GET_EVALUACION,
	payload
})

const setFormula = (payload) => ({
	type: RiskConstants.Accion.RiskModel.SET_FORMULA,
	payload
})

const setFormulaInput = (payload) => ({
	type: RiskConstants.Accion.RiskModel.SET_FORMULA_INPUT,
	payload
})

const onNewModelEvaluation = () => ({
	type: RiskConstants.Accion.RiskModel.TOGGLE_STATE_EVALUATION,
	payload: {value: true}
})

const offNewModelEvaluation = () => ({
	type: RiskConstants.Accion.RiskModel.TOGGLE_STATE_EVALUATION,
	payload: {value: false}
})

const setCondition = (payload) => ({
	type: RiskConstants.Accion.RiskModel.ADD_CONDITION,
	payload
})

const setDataMaster = (payload) => ({
	type: RiskConstants.Accion.RiskModel.GET_DATA_MASTER,
	payload
})

const setCopyData = (payload) => ({
  type: RiskConstants.Accion.RiskModel.TOGGLE_STATE_COPY,
  payload
})

const getDataMaster = () => (dispatch) => {
	dispatch(setDataMaster({isLoading: true}))
	dispatch(setConfiguration({isLoading: true}))
	RiskModelServices.getDataMaster().then(({data})=>{
		if (data.status === CoreConstants.HttpResponse.OK){
			const items = data.data.inventory.sort((a, b) => {
				const nameA = a.name.toUpperCase();
				const nameB = b.name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				return 0;
			})
			dispatch(setConfiguration({isLoading: false, items}))
			dispatch(setDataMaster({isLoading: false, inventory: data.data.inventory}))
		}
	})
}

const update = () => (dispatch, getState) => {
	const { items } = getState().riskModelReducer.itemsConfiguration;
	const { items: itemsSelected } = getState().riskModelReducer.itemsSelection;
	const itemsSelectedId = itemsSelected.map( item => item.inventoryId)
	// console.log(itemsSelectedId)
	const newItems = items.filter( item => !itemsSelectedId.includes(item.inventoryId))
	dispatch(setConfiguration({isLoading: false, items: newItems}))
}

const deleteInventory = (id) => (dispatch, getState) => {
	const { items } = getState().riskModelReducer.itemsConfiguration;
	const newItems = items.filter( item => Number(item.inventoryId) !== Number(id.slice(4)))
	dispatch(setConfiguration({isLoading: false, items: newItems}))
}

const setSelectedItems = ({id, order}) => (dispatch, getState) => {
	const { items } = getState().riskModelReducer.itemsConfiguration;
	const item = items.find( item => Number(item.inventoryId) === Number(id.slice(4)))
	const { items: itemsSelected } = getState().riskModelReducer.itemsSelection;
	if(!itemsSelected.map( item => item.inventoryId).includes(item.inventoryId)){
		const newSelected = [...itemsSelected]
		newSelected.splice(order, 0, item)
		dispatch(setSelected({items: newSelected}))
	}
}

const setSelectedFilterItem = ({id}) => (dispatch, getState) => {
	const { items } = getState().riskModelReducer.itemsSelection;
	const item = items.find( item => Number(item.inventoryId) === Number(id.slice(4)))
	dispatch(setItemFilter({item: [{...item}]}))
}

const removeSelectedFilterItem = () => (dispatch) => {
	dispatch(setItemFilter({item: []}))
}

const removeSelectedItems = (id) => (dispatch, getState) => {
	const { items } = getState().riskModelReducer.itemsSelection;
	const { items: itemsConfiguration } = getState().riskModelReducer.itemsConfiguration;
	const newSelected = items.filter(item => Number(item.inventoryId) !== Number(id))
	const item = items.find( item => Number(item.inventoryId) === Number(id))
	let newItemsConfiguration = itemsConfiguration.concat([item])
	newItemsConfiguration = newItemsConfiguration.sort((a, b) => {
		const nameA = a.name.toUpperCase();
		const nameB = b.name.toUpperCase();
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}
		return 0;
	})
	dispatch(setSelected({items: newSelected}))
	dispatch(setConfiguration({items: newItemsConfiguration}))
}

const changeOrder = ({start, end}) => (dispatch, getState) => {
	const { items } = getState().riskModelReducer.itemsSelection;
	const newSelected = [...items]
	const aux = newSelected[start]
	newSelected.splice(start,1)
	newSelected.splice(end,0,aux)
	dispatch(setSelected({items: newSelected}))
}

const addCriteria = (idCriteria, name) => (dispatch, getState) => {
	const { data: auxData } = getState().riskModelReducer.criteria;
	const data = auxData || []
	const newData = [...data];
	const id = idCriteria || Math.min(...newData.map(item => Number(item.id)), 0) - 1
	const newCriteria = {
		id: `${id}`,
		code: '',
		name: name || '',
		type: null,
		registro: [],
		isCorrect: true,
	}
	dispatch(setCriteria({data: newData.concat([newCriteria])}))
}

const changeNameCriteria = (name, id) => (dispatch, getState) => {
	const { data: auxData } = getState().riskModelReducer.criteria;
	const data = auxData || []
	const criteria = data.find( item => Number(item.id) === Number(id))
	criteria.name = name
}

const deleteCriteria = (select) => (dispatch, getState) => {
	const { data } = getState().riskModelReducer.criteria;
	let newData = [...data];
	newData = newData.filter( item => item.id !== select.id)
	dispatch(setCriteria({data: newData}))
}

const clearCriteria = () => (dispatch) => {
	dispatch(setCriteria({data: null}))
}

const addRegister = (selectId, registerId) => (dispatch, getState) => {
	const { data } = getState().riskModelReducer.criteria;
	
	const newData = [];
	data.forEach( criteria => {
		if(Number(criteria.id) !== Number(selectId)){
			newData.push(criteria)
		}else{
			const newRegistro = criteria.registro
			const id = Math.min(...newRegistro.map( item => Number(item.idRegister)), 0) - 1
			newRegistro.push({
				idRegister: `${registerId || id}`,
				nameRegister: '',
				value: null,
			})
			
			const newCriteria = {
				...criteria,
				registro: newRegistro
			}
			newData.push(newCriteria)
		}
	})	
	dispatch(setCriteria({data: newData}))
}

const deleteRegister = (select, idCriteria) => (dispatch, getState) => {
	const { data } = getState().riskModelReducer.criteria;
	const newData = [];
	data?.forEach( criteria => {
		if(Number(criteria.id) !== Number(idCriteria)){
			newData.push(criteria)
		}else{
			const newRegistro = criteria.registro.filter( item => item.idRegister !== select.idRegister)
			const newCriteria = {
				...criteria,
				registro: newRegistro
			}
			newData.push(newCriteria)
		}
	})	
	dispatch(setCriteria({data: newData}))
}

const updateFormula = ({idSetting, order, isEvaluation}) => (dispatch, getState) => {
	const { data: itemsEva } = getState().riskEvaluationReducer.dataEvaluations;
	const { mathOperators: itemsOp } = getState().riskModelReducer.dataMasterEvaluation.data;
	const { data: itemsCriteria } = getState().riskModelReducer.criteria;
	const { data: formula } = getState().riskModelReducer.formula;
	const { value } = getState().riskModelReducer.formulaInput;
	let settings = []
	if( isEvaluation ){
		settings = [...itemsEva, ...itemsOp]
	} else{
		settings = [...itemsOp, ...itemsCriteria]
	}
	
	const item = settings.find( item => Number(item.id) === Number(idSetting))
	const id = Math.min(...formula.map(item => item.id),0) - 1
	const newItem = {
		id,
		idSetting: item.id,
		value: item.description || item.name,
	}
	const newFormula = [...formula]
	const newValue = [...value, newItem.value]
	// newFormula.splice(order, 0, newItem)
	newFormula.push(newItem)
	dispatch(setFormula({data: newFormula}))
	dispatch(setFormulaInput({value: newValue}))
}

const clearFormula = () => (dispatch) => {
	dispatch(setFormula({data: []}))
	dispatch(setFormulaInput({value: []}))
}

const changeOrderFormula = ({start, end}) => (dispatch, getState) => {
	const { data: formula } = getState().riskModelReducer.formula;
	const aux = formula[start];
	const newFormula = [...formula]
	newFormula.splice(start,1)
	newFormula.splice(end,0,aux)
	
	dispatch(setFormula({data: newFormula}))
}

const deleteFormula = (id) => (dispatch, getState) => {
	const { data: formula } = getState().riskModelReducer.formula;
	const { value } = getState().riskModelReducer.formulaInput;
	const element = formula.find( item => Number(item.id) === Number(id) ).value
	const newFormula = formula.filter( item => Number(item.id) !== Number(id) )
	const newValue = value.filter( item => item !== element)
	dispatch(setFormula({data: newFormula}))
	dispatch(setFormulaInput({value: newValue}))
}

const deleteFormulaInput = (valueFormula, isEvaluation) => (dispatch, getState) => {
	const lastItem = valueFormula[valueFormula.length - 1]
	// if(isNaN(lastItem) && !['*','-','+','/'].includes(lastItem)) return;
	const { data: itemsEva } = getState().riskEvaluationReducer.dataEvaluations;
	const { mathOperators: itemsOp } = getState().riskModelReducer.dataMasterEvaluation.data;
	const { data: itemsCriteria } = getState().riskModelReducer.criteria;
	let settings = []
	if(isEvaluation){
		settings = [...itemsEva, ...itemsOp]
	}else{
		settings = [...itemsCriteria, ...itemsOp]
	}
	
	const newFormula = []

	

	valueFormula.forEach( (item, key) => {
		if( settings.map( val => val.name).includes(item) || settings.map( val => val.description).includes(item)){
			const newItem = settings.find( val => val.description === item || val.name === item)
			newFormula.push({
				id: -1*(key+1),
				idSetting: newItem.id,
				value: newItem.description || newItem.name
			})
		}
	})

	dispatch(setFormula({data: newFormula}))
	dispatch(setFormulaInput({value: valueFormula}))
}

const addCondition = (conditionId) => (dispatch, getState) => {
	const { data } = getState().riskModelReducer.condition;
	const newData = [...data];
	const id = Math.min(...newData.map(item => Number(item.id)), 0) - 1
	const newCondition = {
		id: `${conditionId || id}`,
		name: "",
		firstCondition: "",
		firstValue: "",
		operator: "",
		secondCondition: "",
		secondValue: "",
		color: "",
	}
	dispatch(setCondition({data: newData.concat([newCondition])}))
}

const deleteCondition = (select) => (dispatch, getState) => {
	const { data } = getState().riskModelReducer.condition;
	let newData = [...data];
	newData = newData.filter( item => item.id !== select.id)
	dispatch(setCondition({data: newData}))
}

const clearCondition = () => (dispatch) => {
	dispatch(setCondition({data: []}))
}

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.name) pines.push({ field: 'name', value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });

    advancedFilter.values = pines;

    dispatch({
      type: RiskConstants.Accion.RiskModel.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setModels = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: RiskConstants.Accion.RiskModel.GET_DATA,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const models = getState().riskModelReducer.models;
    setModels({
      dispatch: dispatch,
      isLoading: true,
      data: models.data,
    });

    const criteria = {};
    const advancedFilter = getState().riskModelReducer.advancedFilter;
    const filter = getState().riskModelReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    RiskModelServices.searchModel(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          setModels({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
          setModels({ dispatch: dispatch, isLoading: false, data: null });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setModels({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Modelo de Riesgo', data.message));
        }
      },
      () => {
        setModels({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  };
};

const save = (data, history) => (dispatch) => {
	dispatch(setDataMaster({isLoading: true}))
	RiskModelServices.saveModel(data).then(({data})=>{
		if (data.status === CoreConstants.HttpResponse.OK){
			dispatch(setDataMaster({isLoading: false}))
			dispatch(toastAction.success('Modelo de Riesgo', 'Se guardó correctamente'))
			history.push('/gestion-riesgos/modelo')
		}else if(data.status < CoreConstants.HttpResponse.OK){
			dispatch(setDataMaster({isLoading: false}))
		}else if(data.status > CoreConstants.HttpResponse.OK){
			dispatch(setDataMaster({isLoading: false}))
			dispatch(toastAction.warn('Modelo de Riesgo', data.message));
		}
	}).catch(()=>{
		dispatch(setDataMaster({isLoading: false}))
	})
}

const deleteModel = (id) => (dispatch) => {
	RiskModelServices.deleteModel(id).then(({data})=>{
		if(data.status === CoreConstants.HttpResponse.OK){
			dispatch(toastAction.success('Modelo de Riesgos', 'El Modelo se eliminó correctamente'));
			dispatch(search())
		}else if(data.status > CoreConstants.HttpResponse.OK){
			dispatch(toastAction.warn('Modelo de Riesgos', data.message));
		}else{
			dispatch(toastAction.error('Modelo de Riesgos', data.message));
		}
	}).catch(e => {
		console.log(e)
		dispatch(toastAction.error('Modelo de Riesgos', 'Error'));
	})
}

const setModel = (payload) => ({
	type: RiskConstants.Accion.RiskModel.GET_DATA_BY_ID,
	payload,
})

const clearSelected = () => dispatch => {
	
	dispatch(setSelected({items: [], isLoading: true}))
}

const getDataById = (id) => {
	return (dispatch) => {
		dispatch(setModel({isLoading: true}))
		RiskModelServices.getModelById(id).then(({data})=> {
			if(data.status === CoreConstants.HttpResponse.OK){
				const newSelected = data.data.configuration.map( item => ({
					inventoryId: item.inventoryId,
					name: item.inventoryName || "prueba por que no hay nombre",
				}))
				const newFilter = data.data.filter.map( item => ({
					inventoryId: item.inventoryId,
					name: item.inventoryName || "prueba por que no hay nombre",
				}))

				dispatch(setSelected({items: newSelected, isLoading: false}))
				dispatch(setItemFilter({item: newFilter, isLoading: false}))
				dispatch(setModel({data: data.data, isLoading: false}))
			}else if(data.status > CoreConstants.HttpResponse.OK){
				dispatch(setModel({isLoading: false}))
        dispatch(toastAction.warn('Inventario de Riesgos', data.message));
      }else{
				dispatch(setModel({isLoading: false}))
        dispatch(toastAction.error('Inventario de Riesgos', data.message));
      }
		}).catch(e => {
      console.log(e)
      dispatch(toastAction.error('Inventario de Riesgos', 'Error'));
      dispatch(setModel({isLoading: false, error: true}))
    })
	}
}

const getCopyById = (id) => {
	return (dispatch) => {
		dispatch(setModel({isLoading: true}))
		RiskModelServices.copyModel(id).then(({data})=> {
			if(data.status === CoreConstants.HttpResponse.OK){
				const newSelected = data.data.configuration.map( item => ({
					inventoryId: item.inventoryId,
					name: item.inventoryName || "prueba por que no hay nombre",
				}))
				const newFilter = data.data.filter.map( item => ({
					inventoryId: item.inventoryId,
					name: item.inventoryName || "prueba por que no hay nombre",
				}))

				dispatch(setSelected({items: newSelected, isLoading: false}))
				dispatch(setItemFilter({item: newFilter, isLoading: false}))
				dispatch(setModel({data: data.data, isLoading: false}))
			}else if(data.status > CoreConstants.HttpResponse.OK){
				dispatch(setModel({isLoading: false}))
        dispatch(toastAction.warn('Inventario de Riesgos', data.message));
      }else{
				dispatch(setModel({isLoading: false}))
        dispatch(toastAction.error('Inventario de Riesgos', data.message));
      }
		}).catch(e => {
      console.log(e)
      dispatch(toastAction.error('Inventario de Riesgos', 'Error'));
      dispatch(setModel({isLoading: false, error: true}))
    })
	}
}

const clearModel = () => ({
	type: RiskConstants.Accion.RiskModel.GET_DATA_BY_ID,
	payload: {data: {}}
})

const generateReport = (payload) => {

  const setModels = (payload) => ({
      type: RiskConstants.Accion.RiskModel.DOWNLOAD_REPORT_STATUS,
      payload,
  });

  return (dispatch, getState) => {
    dispatch(setModels({ isLoading: true }));

    const criteria = {};
    //    const advancedFilter = getState().departmentReducer.advancedFilter;
		const filter = getState().riskModelReducer.advancedFilter.filter;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    RiskModelServices.generateReport(criteria)
      .then(({ data }) => {
        dispatch(setModels({ isLoading: false }));
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Modelos.xls');
      })
      
      .catch((err) => {
        dispatch(setModels({ isLoading: false }));
        dispatch(toastAction.error('Modelo de Riesgo', 'Hubo un error al descargar el archivo'));
      });
  };
};

const getEvaluationModelById = id => dispatch => {
	RiskModelServices.getEvaluationByModelId(id).then(({data})=>{
		// console.log(data)
	})
}

const getDataMasterEvaluation = () => dispatch => {
	const setDataMasterEvaluation = (payload) => ({
		type: RiskConstants.Accion.RiskModel.GET_DATA_MASTER_EVALUATION,
		payload,
	})

	dispatch(setDataMasterEvaluation({isLoading: true}))
	RiskModelServices.getDataMasterEvaluation().then(({data})=>{
		if(data.status === CoreConstants.HttpResponse.OK){
			dispatch(setDataMasterEvaluation({isLoading: false, data: data.data}))
		}else if(data.status > CoreConstants.HttpResponse.OK){
				dispatch(setDataMasterEvaluation({isLoading: false, error: true}))
        dispatch(toastAction.warn('Modelos de Riesgo', data.message));
      }else{
				dispatch(setDataMasterEvaluation({isLoading: false, error: true}))
        dispatch(toastAction.error('Modelos de Riesgo', data.message));
      }
	}).catch(e => {
		console.log(e)
		dispatch(toastAction.error('Modelos de Riesgo', 'Error'));
		dispatch(setDataMasterEvaluation({isLoading: false, error: true}))
	})

}

export const RiskModelAction = {
	getDataMaster,
	update,
	setSelectedItems,
	setSelectedFilterItem,
	removeSelectedFilterItem,
	deleteInventory,
	removeSelectedItems,
	changeOrder,
	addCriteria,
	changeNameCriteria,
	deleteCriteria,
	clearCriteria,
	addRegister,
	deleteRegister,
	updateFormula,
	changeOrderFormula,
	deleteFormula,
	clearFormula,
	addCondition,
	deleteCondition,
	clearCondition,
	onNewModelEvaluation,
	offNewModelEvaluation,
	deleteFormulaInput,
	toggleAdvancedFilter,
	search,
	updateFilter,
	save,
	deleteModel,
	getDataById,
	clearModel,
	clearSelected,
	generateReport,
	getEvaluationModelById,
	getDataMasterEvaluation,
  setCopyData,
  getCopyById,
}