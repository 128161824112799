import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

export const useClaimSystemConfiguration = () => {

  const menu = useSelector((state) => state.authReducer.user.menuPrincipal);
  const pathName = useLocation()?.pathname;
  const [isCreate, setCreate] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isDeleted, setDeleted] = useState(false);

  useEffect(() => {
    create();
    edit();
    deleted();
  }, [menu]);

  const create = () => {
    const actionCode = 'MANAGEMENT_APPLICATION-SETTING_CREATE';
    const optionCode = "CONSIS";
    setCreate(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const edit = () => {
    const actionCode = 'MANAGEMENT_APPLICATION-SETTING_EDIT';
    const optionCode = "CONSIS";
    setEdit(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
 };

  const deleted = () => {
    const actionCode = 'MANAGEMENT_APPLICATION-SETTING_DELETE';
    const optionCode = "CONSIS";
    setDeleted(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
 };


  return {
    isCreate,
    isEdit,
    isDeleted
  }
}