
import { useEffect , useState } from "react"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import moment from 'moment'
import { SubscriptionsAction } from "../store/actions/SubscriptionsAction";
import { CoreConstants } from "core/commons/CoreConstants";
const useFilter = () =>{
  const { dataManager , loadingDataManager , empresas } = useSelector((state) => state.subscriptionsReducer.dataManager);
  const subscriptions = useSelector((state) => state.subscriptionsReducer.subscriptions);
  const { showModal , values   } = useSelector((state) => state.subscriptionsReducer.subscriptionsFilter);
  const dispatch = useDispatch()
  const initialState = {
    "from": null,
    "to": null,
    "customerId": null,
    "usersQuantity": null,
    "numberActiveUsers": null,
    "statusId": null
  } 
  const [formValues , setFormValues] = useState(initialState)
  const [ customerName , setCustomerName] = useState(null); 

  useEffect(() => {
    let newFormValue = {};
    values.forEach(value =>{
      if(value.date){
        newFormValue[value.field] = value.date
      }else if(value.number){
        newFormValue[value.field] = value.number
      }else{
        newFormValue[value.field] = `${value.id}-${value.value}`
      }
    })
    setFormValues({...initialState,...newFormValue})
  },[values])

  const openModal = () => {
    dispatch(SubscriptionsAction.toggleModalFilters({ showModal: true }));
  };
  const closeModal = () =>{
    dispatch(SubscriptionsAction.toggleModalFilters({ showModal: false }));

  }

  const getValues = () =>{    
    return {values:[{"field":"customerName","value":customerName}]};
  }

  const clearFields = () =>{
    dispatch(SubscriptionsAction.setFilterValues({values:[]}));
    setFormValues(initialState)
    dispatch(SubscriptionsAction.getSubscriptions({}));
  }

  const search = () =>{
    if(customerName?.length >= 3){
      const  { newValues , fields } = getFields();
      if (values.length > 0) {
        for (let key in fields) {
          if (!fields[key]) delete fields[key];
        }
      }

      fields.customerName = customerName
      newValues.customerName = customerName

      const arrValues = [];
      Object.entries(newValues).forEach(([key,value]) =>{
        if (key === "from" && value !== null)
          arrValues.push({
            field: key,
            value: moment(value).format(CoreConstants.Format.LOCALE),
            date: value,
        });

        if (key === "to" && value !== null)
          arrValues.push({
            field: key,
            value: moment(value).format(CoreConstants.Format.LOCALE),
            date: value,
        });
        if( key === "customerName" && value !== null && value !== "" ){
          arrValues.push({
            field:"customerName",
            value:customerName,
          })
        } else if(key !== "to" && key !== "from" && value !== null && value !== "" && typeof value !== "number"){
          let arrayValue = (value+"").split('-');
          arrValues.push({
            field:key,
            value:arrayValue[1],
            id:arrayValue[0]
          })
        }else if(typeof value === "number"){
          arrValues.push({
            field:key,
            number:value,
            value:key === "userQuantity" ? `${value} Usuarios` : `${value} Usuarios Activos`
          })
        }
      })

      dispatch(SubscriptionsAction.setFilterValues({values:arrValues}));
      dispatch(SubscriptionsAction.getSubscriptions({...fields}));
    }
  }
  const showPiners = () => {
    if (values.length > 0) return true;
    else return false;
  };

  const removePiner = (field) => {
    dispatch(SubscriptionsAction.removeFilterValues(field));
  };

  const getFields = () => {
    let fields = {};
    let newValues  ={}
      Object.entries(formValues).forEach(([key,value]) =>{
        if(value!==null){
          if(typeof value === 'object'){
            fields[key] =  moment.parseZone(value).utc().format()
            newValues[key] = value
          }else{
            if(value !== undefined && typeof value !== "number"){
              if(value.includes('-')){
                let arrayValue = value.split('-');
                fields[key] = parseInt(arrayValue[0])
                newValues[key] = value
              }else{
                fields[key] = value
                newValues[key] = value
              }
            }else{
              fields[key] = value
              newValues[key] = value
            }
          }
        }
      });
   
    return  {fields , newValues}
  }

  const handleSubmit = () =>{
    const  { newValues , fields } = getFields();
    if (values.length > 0) {
      const fieldName = values.filter((val) => val.field === 'customerName');
      if (fieldName.length > 0) fields.customerName = fieldName[0].value;
      for (let key in fields) {
        if (!fields[key]) delete fields[key];
      }
    }

    const arrValues = [];
    Object.entries(newValues).forEach(([key,value]) =>{
      if (key === "from" && value !== null)
        arrValues.push({
          field: key,
          value: moment(value).format(CoreConstants.Format.LOCALE),
          date: value,
      });

      if (key === "to" && value !== null)
        arrValues.push({
          field: key,
          value: moment(value).format(CoreConstants.Format.LOCALE),
          date: value,
      });
      if(key !== "to" && key !== "from" && value !== null && value !== "" && typeof value !== "number"){
        let arrayValue = (value+"").split('-');
        arrValues.push({
          field:key,
          value:arrayValue[1],
          id:arrayValue[0]
        })
      }else if(typeof value === "number"){
        arrValues.push({
          field:key,
          number:value,
          value:key === "userQuantity" ? `${value} Usuarios` : `${value} Usuarios Activos`
        })
      }
    })
    dispatch(SubscriptionsAction.setFilterValues({values:arrValues}));
    dispatch(SubscriptionsAction.getSubscriptions({...fields}));
    dispatch(SubscriptionsAction.toggleModalFilters({ showModal: false }));

  }

  return {
    dataManager,
    openModal,
    showModal,
    empresas,
    showPiners,
    closeModal,
    loadingDataManager,
    values,
    customerName,
    setCustomerName,
    search,
    removePiner,
    formValues,
    setFormValues,
    clearFields,
    handleSubmit
  }
}


export default useFilter