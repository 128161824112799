export class BusinessConstants {
  static Action = class {
    static Business = class {
      static get ADVANCED_FILTER() {
        return '@BUSINESS.ADVANCED-FILTER';
      }
      static get SEARCH_BUSINESS() {
        return '@BUSINESS.SEARCH-BUSINESS';
      }
      static get DATA_MASTER() {
        return '@BUSINESS.DATA-MASTER';
      }
      static get CURRENT_ACTION() {
        return '@BUSINESS.CURRENT-ACTION';
      }
      static get EDIT_BUSINESS() {
        return '@BUSINESS.EDIT-COMPANY';
      }
      static get REPORT_STATUS() {
        return '@BUSINESS.REPORT-STATUS';
      }
    };
  };

  static SectionsName = class {
    static get INFORMACION() {
      return 'INFORMACIÓN';
    }
  }
}
