import AdvancedFilterComponent from 'features/coursesManagment/components/CoursesMonitoring/AdvanceFilterComponent';
import FilterComponent from 'features/coursesManagment/components/CoursesMonitoring/FilterComponent';
import TableComponent from 'features/coursesManagment/components/CoursesMonitoring/TableComponent';
import { useClaimMonitoring } from 'features/coursesManagment/hooks/useClaimMonitoring';
import React, { Fragment, useEffect } from 'react'
import useSetTitlePage from 'shared/hooks/useSetTitlePage';

const SearchCourseMonitoring = () => {

  const { updateTitle } = useSetTitlePage();

  const { isSearch, isDownload } = useClaimMonitoring();

    useEffect(() => {
        updateTitle({
            title: 'Cursos',
            subtitle: 'Seguimiento',
        })
    }, [])

  return (
    <Fragment>
        {
          (isSearch||isDownload)&&(
            <FilterComponent />
          )
        }
        <TableComponent />
        <AdvancedFilterComponent />
    </Fragment>
  )
}

export default SearchCourseMonitoring