export const DownArrowSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={26}
    viewBox="0 0 158 70"
    {...props}
  >
    <path d="M39 18v17H19.3C8.1 35 0 35.4.5 35.9c.6.4 18.2 8.3 39.2 17.5L77.9 70l38.3-16.7c21.1-9.1 38.7-17 39.3-17.5.5-.4-8-.8-18.8-.8H117V1H39v17z" />
  </svg>
)