import { useState, useEffect, Fragment } from "react";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { useHistory } from "react-router";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import Message from "shared/components/messages/Message";
import useSite from "features/maintenance/hooks/Sites/useSite";
import { Skeleton } from "primereact/skeleton";
import useStatus from "features/maintenance/hooks/Sites/useStatus";
import { useForm, Controller } from "react-hook-form";
import { setArgs } from "@craco/craco/lib/args";
import { useClaimSites } from "features/maintenance/hooks/useClaimSites";

const Site = ({ title, mode }) => {
  const { updateTitle } = useSetTitlePage();
  const errorsArray = [];
  const {
    formState: { errors },
    handleSubmit,
    control,
    setError,
    setValue,
  } = useForm();
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  const { isCreate, isEdit } = useClaimSites()

  const {
    saveSite,
    isSave,
    loadingSaveOrEdit,
    clearSave,
    dataSite,
  } = useSite(title);
  const { status } = useStatus();
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (status.length > 0 && (title !== "Editar Sede" || title!=='Ver Sede')){
      setValue("statusId",status[0]?.id)
    };
  }, [status]);

  const SkeletonCustom = ({ width = "100%" }) => {
    return <Skeleton width={width} height="2.2rem" />;
  };
  useEffect(() => {
    updateTitle({
      title: "Mantenimiento",
      subtitle: "Sede",
      description: title,
      previousUrl: "/mantenimiento/sede/",
    });
  });

  useEffect(() => {
    if (isSave) {
      clearSave();
      history.push("/mantenimiento/sede/");
    }
  }, [isSave]);

  useEffect(() => {
    if (title === "Editar Sede" || mode==='view'){
      if(dataSite !== undefined && status.length > 0){
        setValue("name",dataSite.name)
        setValue("address",dataSite.address)
        setValue("statusId",dataSite.statusId)
        setIsActive(dataSite.statusId === status[0].id ? true : false)
        setValue("locationId",dataSite.id)
      }
    };
  }, [dataSite]);

  const submit = (data) => {
    saveSite(data)
  };

  return (
    <Fragment>
      <form className="site-container" onSubmit={handleSubmit(submit)}>
        <Panel header="DATOS GENERALES" toggleable>
          <div className="grid grid-cols-2 w-10/12 mt-4">
            <span className="p-float-label">
              {loadingSaveOrEdit ? (
                <SkeletonCustom />
              ) : (
                <Controller
                  control={control}
                  rules={{
                    required: "EL Nombre de la Sede es obligatoria",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputText
                      id="name"
                      className={
                        errors.name?.type === "required" ? "p-invalid" : ""
                      }
                      autoFocus
                      onChange={onChange}
                      value={value}
                    />
                  )}
                  name="name"
                />
              )}

              <label htmlFor="name">Nombre de Sede *</label>
            </span>
            <div className="flex flex-col items-end w-8/12">
              {loadingSaveOrEdit ? (
                <SkeletonCustom />
              ) : (
                <InputSwitch
                  disabled={title === "Nueva Sede"}
                  checked={isActive}
                  onChange={(e) => {
                    setValue("statusId",e.target.value ? status[0].id : status[1].id)
                    setIsActive(!isActive);
                  }}
                  name="statusId"
                  id="statusId"
                />
              )}
              <label htmlFor="statusId">
                {isActive ? "Activo" : "Inactivo"}
              </label>
            </div>
            <span className="p-float-label col-span-2 mt-4 w-10/12">
              {loadingSaveOrEdit ? (
                <SkeletonCustom />
              ) : (
                <Controller
                  control={control}
                  
                  render={({ field: { onChange, value } }) => (
                    <InputText
                      id="address"
                      name="address"
                      onChange={onChange}
                      className={
                        errors.address?.type === "required" ? "p-invalid" : ""
                      }
                      value={value}
                    />
                  )}
                  name="address"
                />
              )}
              <label htmlFor="address">Dirección</label>
            </span>
          </div>
          {Object.entries(errors).forEach((key, value) => {
            errorsArray.push(key[1].message);
          })}
          {errorsArray.length > 0 && (
            <Message messages={errorsArray} status="error" />
          )}
        </Panel>
        <div className="flex justify-end gap-4 mt-3">
          <Button
            icon="pi pi-times"
            type="button"
            label="Cancelar"
            onClick={() => setVisible(true)}
            disabled={loadingSaveOrEdit}
            className="sig-button sig-secondary mt-4"
          />
          {
            (mode!=='view')&&(<>
              {
                (isCreate || isEdit)&&
              <Button
                icon="pi pi-save"
                type="submit"
                label="Guardar"
                disabled={loadingSaveOrEdit}
                loading={loadingSaveOrEdit}
                className="sig-button sig-primary mt-4"
              />
              }
            </>
            )
          }
        </div>
        <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={() => history.push("/mantenimiento/sede")}
        />
      </form>
    </Fragment>
  );
};

export default Site;
