import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PositionsTableData } from 'features/rrhh/data/positions/PositionsDataTable';
import validateTypeValue from 'shared/helpers/validateTypeValue';
import { CoreConstants } from 'core/commons/CoreConstants';
import { PositionsAction } from 'features/rrhh/store/actions/PositionsAction';
import { useClaimPositions } from '../useClaimPositions';

const useTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const positions = useSelector((state) => state.positionsReducer.positions);
  const { isEdit, isDelete } = useClaimPositions();

  /* TYPE TABLE VIEW ---------------- */
  const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);

  /* TABLE -------------------------- */
  const [tablePositions, setTablePositions] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState([]);
  useEffect(() => {
    const { data, pagination, loading } = positions;
    setTablePositions(validateTypeValue(data));
    setTablePagination(pagination);
    setTableLoading(loading);
  }, [positions]);

  /* TABLE HEADER COLUMNS */
  const [selectColumns, setSelectColumns] = useState(
    PositionsTableData.columns
  );

  useEffect(() => {
    if (localStorage.getItem('columnas-cargos')) {
      setSelectColumns(JSON.parse(localStorage.getItem('columnas-cargos')));
    }
  }, []);

  const onColumnToggle = (e, item) => {
    let _selectedColumns = [...selectColumns];

    if (e.checked) _selectedColumns.push(item);
    else _selectedColumns = _selectedColumns.filter((c) => c.field !== e.value);

    if (_selectedColumns.length === 0) return;
    localStorage.setItem('columnas-cargos', JSON.stringify(_selectedColumns));
    setSelectColumns(_selectedColumns);
  };

  /* TABLE HEADER ACTIONS */
  const headerActions = useRef(null);

  /* CURRENT POSITION ------------------- */
  const positionId = useSelector(
    (state) => state.positionsReducer.currentAction.positionId
  );
  const setCurrentPosition = (positionId) => {
    dispatch(PositionsAction.currentAction({ positionId }));
  };

  /* TABLE CURRENT ACTION ------------- */
  const [currentAction, setCurrentAction] = useState('');
  const onCurrentAction = ({ item: { name } }) => setCurrentAction(name);
  useEffect(() => {
    if (positionId && currentAction) {
      const action_ = currentAction.toLocaleLowerCase();
      if (action_ === 'editar') {
        history.push(`/rrhh/cargos/editar/${positionId}`);
      } else if (action_ === 'eliminar') {
        dispatch(PositionsAction.currentAction({ isOpen: true }));
      }
    }
    setCurrentAction('');
  }, [currentAction]);

  /* TABLE ACTIONS -------------------- */
  const positionOptions = useSelector(
    (state) => state.positionsReducer.actions.items
  );
  const [tableActions, setTableActions] = useState([]);
  const [showActions, setShowActions] = useState(false);
  const tableCurrentActions = useRef(null);
  useEffect(() => {
    if (positionOptions.length > 0) {
      let newActions = positionOptions.map((option) => {
        option.label = option.name;
        option.command = onCurrentAction;
        return option;
      });
      if (!isEdit)
        newActions = newActions.filter(c => c.label !== 'Editar');
      if (!isDelete)
        newActions = newActions.filter(c => c.label !== 'Eliminar');
      setTableActions(newActions);
    }
    setShowActions(positionOptions.length > 0);
  }, [isEdit, isDelete]);

  /* MODAL DELETE (ACTIONS) */
  const confirmDelete = () => {
    dispatch(PositionsAction.deletePosition(positionId));
  };
  const cancelDelete = () => {
    dispatch(PositionsAction.currentAction({ isOpen: false, positionId: '' }));
  };

  /* TABLE PAGINATION --------------- */
  const positionsSearch = useSelector(
    (state) => state.positionsReducer.positions
  );
  const [tableRows, setTableRows] = useState(10);
  const [tableNum, setTableNum] = useState(0);
  const [page, setPage] = useState(1);
  let currentFilterFields = {
    columnOrder: positionsSearch.columnOrder,
    order: positionsSearch.order,
  };

  const filterValues = useSelector(
    (state) => state.positionsReducer.advancedFilter.values
  );
  const buildFilterValues = () => {
    if (filterValues.length > 0) {
      filterValues.forEach(({ field, value, date, option }) => {
        let currentValue = '';
        const key = field.toLocaleLowerCase();
        if (key.includes('date')) currentValue = date;
        else if (key === 'departmentid') currentValue = option.id;
        else currentValue = value;
        currentFilterFields[field] = currentValue;
      });
    }
  };

  const onChangePage = ({ rows, page }) => {
    buildFilterValues();
    currentFilterFields.page = page + 1;
    currentFilterFields.pageSize = rows;
    setTableRows(rows);
    setTableNum(rows * page);
    setPage(page + 1);
    const fields = currentFilterFields;
    dispatch(PositionsAction.getPositions({ change: true, fields }));
  };

  /* TABLE ORDER SORT */
  const [tableSort, setTableSort] = useState({
    sortField: 'name',
    sortOrder: '1',
  });
  const onSort = (event) => {
    buildFilterValues();
    const params = {
      first: tableNum,
      rows: tableRows,
      multiSortMeta: [],
      filters: null,
      ...event,
    };

    currentFilterFields.order = event.sortOrder + '' === '1' ? 'asc' : 'desc';
    currentFilterFields.columnOrder = event.sortField;
    currentFilterFields.pageSize = tableRows;
    currentFilterFields.page = page;
    const fields = currentFilterFields;
    dispatch(PositionsAction.getPositions({ change: true, fields }));
    setTableSort(params);
  };
  return {
    typeView,
    setTypeView,
    onColumnToggle,
    selectColumns,
    tablePositions,
    tableLoading,
    headerActions,
    tableActions,
    showActions,
    tableCurrentActions,
    setCurrentPosition,
    confirmDelete,
    cancelDelete,
    tablePagination,
    onChangePage,
    tableNum,
    tableRows,
    tableSort,
    onSort,
  };
};

export default useTable;
