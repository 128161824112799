import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { Divider } from 'primereact/divider'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

const getListStyle = isDraggingOver => ({
  // border: isDraggingOver ? "1px dashed gray" : "none",
  padding: 2,
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // userSelect: "none",
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,
  // background: isDragging ? "lightgreen" : "white",
  ...draggableStyle
});

export const ModalMoveSections = ({displayModal, setDisplayModal, listSections, setListSections, onDragEnd}) => {

  const [temporalSections, setTemporalSections] = useState([])

  useEffect(() => {
    setTemporalSections(listSections)
  }, [listSections])
  

  const onHide = () => {
    setDisplayModal(false)
    setTemporalSections(listSections)
    // setSurveySelected(null)
  }

  const onConfirm = () => {
    // Logica para guardar los cambios del orden
    setListSections(temporalSections)
    onHide()
  }
  
  return (
    <Dialog header={'Reordenar Secciones'} visible={displayModal} className='w-4/5 max-h-4/5 md:w-1/2 xl:w-1/3' onHide={onHide}>
      <Divider />
      {/* <DragDropContext onDragEnd={(result) => onDragEnd(result, listSections, setListSections)}> */}
      <DragDropContext onDragEnd={(result) => onDragEnd(result, temporalSections, setTemporalSections)}>
        <Droppable droppableId="droppable_sections_compact">
          {
            (provided, snapshot) => (
              <div 
                className="flex flex-col"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {
                  temporalSections.map((section, index) => (
                    <Draggable 
                      key={`section_compact_${section.id}`} 
                      draggableId={`section_compact_${section.id}`} 
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div 
                          className="flex flex-wrap px-2 py-3 items-center gap-3 border"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          // {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <i 
                            { ...provided.dragHandleProps} 
                            className={`pi pi-th-large cursor-pointer hover:text-gray-900 py-2 px-3 text-lg`} 
                          />
                          <div className="flex-1 flex flex-col">
                              <span className='text-sm'>{`Sección ${section.position} de ${temporalSections.length}`}</span>
                              <span className="font-bold">{section.title}</span>
                          </div>
                        </div>
                    )}
                    </Draggable>
                  ))
                }
                {provided.placeholder}
              </div>
            )
          }
        </Droppable>
      </DragDropContext>
      <div className="flex justify-end gap-4 mt-3">
        <Button
            icon="pi pi-times"
            type="button"
            label="Cancelar"
            onClick={onHide}
            className="sig-button sig-secondary mt-4"
        />
        <Button 
            icon="pi pi-save"
            type="button"
            label="Aceptar"
            className="sig-button sig-primary mt-4"
            onClick={() => onConfirm()}
        />
      </div>
    </Dialog>
  )
}
