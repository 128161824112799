import { Skeleton } from 'primereact/skeleton'
import { Tooltip } from 'primereact/tooltip'
import React from 'react'
import { MaintenanceConstants } from 'shared/commons/MaintenanceConstans'

export const ContainerCardComponent = ({ data, selected, filterCardStatus,isLoading }) => {
  
  return (
    <div className={`flex ${isLoading? 'justify-center':'justify-center'} items-center w-auto flex-wrap`}>
      {
        isLoading ? 
        (
          <>
          <div className="w-80 sm:w-1/3 p-4">
            <Skeleton height='7rem'/>
          </div>
          <div className="w-80 sm:w-1/3 p-4">
            <Skeleton height='7rem'/>
          </div>
          <div className="w-80 sm:w-1/3 p-4">
            <Skeleton height='7rem'/>
          </div>
{/*           
          <Skeleton width='30%' height='6rem'/>
          <Skeleton width='30%' height='6rem'/> */}
          </>
        ) 
        : 
        (
          data&&<>
          {
            data.map((elem, index) =>
              <div key={index} className="w-80 sm:w-1/3">
                <Tooltip target=".customClassCard" mouseTrack mouseTrackLeft={10}/>
                <div className={`customClassCard hover:bg-gray-100 m-4 text-center justify-center items-center flex cursor-pointer rounded-lg border-2 shadow-xl h-28 ${selected===elem.id?'bg-gray-200 border-gray-200':'border-gray-100'}`} 
                  onClick={()=> {
                    const datMap = {
                      "Asignado": MaintenanceConstants.Constats.Home.Asignado,
                      "En Progreso": MaintenanceConstants.Constats.Home.EnProgreso,
                      "Por Vencer": MaintenanceConstants.Constats.Home.PorVencer,
                      "Completados": MaintenanceConstants.Constats.Home.Completados,
                    }
                    filterCardStatus(datMap[elem.description], elem.id)
                  }} 
                  data-pr-tooltip={elem.tooltipText} data-pr-position="top">
                  <div>
                    <i className={`pi ${elem.icon?elem.icon:"pi-check"} mr-2 h-full`} style={{fontSize: "30px", color:"#54AEFD"}}/>
                  </div>
                  <div className='w-3/5'>
                    <div className="w-full h-1/2 flex justify-center items-center text-3xl mb-3">
                      <p>{elem.count}</p>
                    </div>
                  <div className="w-full h-1/2 flex justify-center items-center">
                    <p>{elem.description}</p>
                  </div>
                  </div>
                </div>
              </div>
            )
          }
        </>
        )
      }
    </div>
  )
}
