import { useForm } from "react-hook-form";


const useModalAction = () => {
  let defaultValues = {
    // sourceFileName: '',
    targetPath: '',
    newName: '',
  };

  const { setValue, handleSubmit, control, watch, formState: { errors } } = useForm({ defaultValues });

  const valuesFields = watch();
  const existError = Object.keys(errors).length > 0

  return {
    control,
    errors,
    valuesFields,
    existError,
    setValue,
    handleSubmit
  }
}

export default useModalAction