import { useEffect, useState } from "react"
import { Controller } from "react-hook-form"

import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { Panel } from "primereact/panel"
import { Skeleton } from "primereact/skeleton"

import moment from "moment"

import { ModalGenerateVersion } from "./ProceseSheet/ModalGenerateVersion"
import { ProcessManagementServices } from "../services/ProcessManagementServices";
import { useDispatch } from "react-redux"
import { toastAction } from "core/store/actions/ToastAction"
import { Approvers } from "shared/components/processManagement/Approvers"
import { ProcessManagementConstans } from "../commons/ProcessManagementConstans"
import { CoreConstants } from "core/commons/CoreConstants"
import { useClaimProcessManagment } from "../hooks/useClaimProcessManagment"

const Users = ({labor, nombre, fecha, rol, className}) => {
	return (
		<div className={`flex flex-col pl-2 ${className}`}>
			<div>
				<span className="font-bold">{labor.toUpperCase()}: </span>{fecha}
			</div>
			<div>
				{nombre} - {rol}
			</div>
		</div>
	)
}

export const GeneralDataProcess = ({isLoading=false, isLoadingMaster, control, errors, dataMaster, data, handleSubmit, submit, approvers,setApprovers, isLoadingVersion, isNew=true, id, setIsLoading, setData, isLast, setIsLast, versions, version, setVersion, lastVersion,showModal, setShowModal, show }) => {

	const dispatch = useDispatch()

	const onSubmitValue = (value) => {
		handleSubmit((data)=>{submit(data,3,value)})()
	}

	const {isGenerateVersionProcess} = useClaimProcessManagment()

	const changeVersion = (value) => {
		setIsLoading(true)
		if(Number(value)<Number(lastVersion)){
			ProcessManagementServices.getProcessVersionByVersionNumber(id, value).then(({data: newData})=>{
				const message = newData.message
				console.log(newData.data)
				if(newData.data){
					setIsLast(false)
					setData(newData.data)
					setApprovers(newData.data.approvers)
					setVersion(Number(value))
					setIsLoading(false)
				}else{
					ProcessManagementServices.getProcessById(id).then(({data: newData})=>{
						setData(newData.data)
						setApprovers(newData.data.approvers)
						setVersion(Number(lastVersion))
						setIsLoading(false)
						setIsLast(true)
						dispatch(toastAction.info("Error" , message))
					})	
				}

				
			})
		} else {
			ProcessManagementServices.getProcessById(id).then(({data: newData})=>{
				setData(newData.data)
				setApprovers(newData.data.approvers)
				setVersion(value)
				setIsLoading(false)
				setIsLast(true)
			})	
		}
	}

	// console.log(show)
	
	return(
		<Panel header='DATOS GENERALES'>
		{!isNew && <div><p><strong>Estado: {data?.process?.statusCode}</strong></p></div>}
		<div className='mb-4'>
		<label htmlFor="Create" className="whitespace-nowrap">Fecha de Elaboración *</label>
		{
			isLoading || isLoadingMaster? (
				<Skeleton height="2.2rem" />
		) : (
			<Controller 
				rules={{
					required: 'La Fecha de Elaboración es requerida',
				}}
				name="processDate"
				control={control}
				render={({ field: { onChange, value } }) => (
					<Calendar
						disabled={show === "ShowWf" || show === "readonly"}
						monthNavigator
						yearNavigator
						yearRange="1950:2050"
						showIcon
						id="processDate"
						value={value}
						maxDate={new Date()}
						onChange={onChange}
						className={errors?.processDate ? 'p-invalid w-full' : (show === "ShowWf" || show === "readonly"?'w-full disabled': 'w-full')}
					></Calendar>
				)}
			/>

		)
		}
			
			</div>
			<div className='mb-4'>
			<label htmlFor="CreateFor">Elaborado Por *</label>
			{
			isLoading || isLoadingMaster? (
				<Skeleton height="2.2rem" />
		) : (
			<Controller 
			rules={{
				required: 'El Elaborador es requerido',
			}}
			name="elaboratedById"
			control={control}
			render={({ field: { onChange, value } }) => (
				<Dropdown
				disabled={show === "ShowWf" || show === "readonly"}
				filter
				optionLabel="fullName" 
				filterBy="fullName"
				id="elaboratedById"
				name="elaboratedById"
				options={isNew? dataMaster?.responsibles:dataMaster.data?.responsibles}  
				optionValue="id"   
				onChange={onChange}
				value={value}
				className={errors?.elaboratedById ? 'p-invalid w-full' : (show === "ShowWf" || show === "readonly"?'w-full disabled': 'w-full')}
				/>
				)}
			/>
			)}
			</div>
			<div className='mb-4'>
			<label htmlFor="Update">Última Actualización</label>
			{
			isLoading || isLoadingMaster? (
				<Skeleton height="2.2rem" />
		) : (<InputText
				type="text"
				id="Update"
				maxLength={100}
				readOnly
				disabled
				className="disabled"
				value={(data?.process?.modificationDate && moment(data?.process?.modificationDate).format(CoreConstants.Format.LOCALE)) || ""}
			/>)}
			</div>
			<div className='mb-4'>
			<label htmlFor="UpdateFor">Actualizado por</label>
			{
			isLoading || isLoadingMaster? (
				<Skeleton height="2.2rem" />
		) : (
			<InputText
				type="text"
				id="UpdateFor"
				maxLength={100}
				readOnly
				disabled
				className="disabled"
				value={data?.process?.modificationUser || ""}
			/>)}
			</div>
			<div className='mb-4'>
			<label htmlFor="Version">Versión</label>
			{
			isLoading || isLoadingMaster? (
				<Skeleton height="2.2rem" />
		) : (

			isNew ? 
			<InputText
				type="text"
				id="Version"
				maxLength={100}
				readOnly
				disabled
				className="disabled"
				value={data?.process?.versionNumber || ""}
			/> : 
			<Dropdown
				
				disabled={show === "ShowWf" || show === "readonly"}
				optionLabel="versionNumberLabel" 
				filterBy="versionNumberLabel"
				id="Version"
				name="Version"
				options={versions}  
				optionValue="versionNumber"   
				onChange={(e)=>{changeVersion(e.value)}}
				value={version}
				className={(show === "ShowWf" || show === "readonly"?'w-full disabled': 'w-full')}
				/>
			)
			}
			</div>
			{
				(!isNew && isLast && show !== "ShowWf" && show !== "readonly" && data?.process?.statusCode === ProcessManagementConstans.Aprobacion.Estado.PUBLICADO)  && 
				<div className="flex justify-center">
					{
						isGenerateVersionProcess&&(
							<Button
								type="button"
								className="sig-button sig-primary mt-4"
								label="Generar Versión"
								onClick={()=>setShowModal(true)}
								loading={isLoadingVersion}
								// onClick={handleSubmit(generarVersion)}
							/>
						)
					}
				</div>
			}
			<div>
				<h1 className="underline mt-6 mb-2 font-semibold">APROBADORES</h1>
			</div>
			<Approvers approvers={approvers}/>
		<ModalGenerateVersion visibleModalVersion={showModal} setVisibleModalVersion={setShowModal}  onSubmitValue={onSubmitValue} isLoading={isLoadingVersion}/>
	</Panel>
	)
}