import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputSwitch } from 'primereact/inputswitch'
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { useSelector } from 'react-redux'


const StudentsTableUploadNotes = ({listStudents, setListStudents}) => {

  const [selectedRow, setSelectedRow] = useState(null)
  const {data} = useSelector(state => state.studentsReducer.bulkLoad)

  const columns = [
    {field: 'documentTypeName', header: 'Tipo Documento'},
    {field: 'documentNumber', header: 'Nro Documento'},
    {field: 'fullName', header: 'Nombre Completo'},
    {field: 'businessLineName', header: 'Línea de Negocio'},
    {field: 'countryName', header: 'País'},
    {field: 'emailAddress', header: 'Correo'},
    {field: 'profession', header: 'Profesión'},
    {field: 'employerName', header: 'Empresa'},
    {field: 'jobPosition', header: 'Cargo'},
    {field: 'courseCode', header: 'Codigo Curso'},
    {field: 'courseName', header: 'Nombres Curso'},
  ];

  const dynamicColumns = columns.map((col,i) => {
    return <Column key={col.field} field={col.field} header={col.header} />;
  });

  const switchBodyTemplate = (rowData) => {
    return <SwitchBody rowData={rowData} setListStudents={setListStudents} listStudents={listStudents} />
  }

  const rowClass = (data) => {
    return {
        'row-errors': data.error === true
    }
  }

  const newData = data.map((student, i) => {
    const newStudent = {
      documentTypeName: student.documentTypeName,
      documentNumber: student.documentNumber,
      fullName: student.fullName,
      businessLineName: student.businessLineName,
      countryName: student.countryName,
      emailAddress: student.emailAddress,
      profession: student.profession,
      employerName: student.employerName,
      jobPosition: student.jobPosition,
      courseCode: student.courseCode,
      courseName: student.courseName,
      // contractStartDate: moment(student.contractStartDate).format(),
      documentTypeId: student.documentTypeId,
      names: student.names,
      lastName: student.lastName,
      birthDate: moment(student.birthDate).format(),
      businessLineId: student.businessLineId,
      countryId: student.countryId,
      phone: student.phone,
      academicDegree: student.academicDegree,
      academicInstitution: student.academicInstitution,
      courseType: student.courseType,
      courseMode: student.courseMode,
      courseCategory: student.courseCategory,
      month: student.month,
      year: student.year,
      registrationDate: moment(student.registrationDate).format(),
      statusId: student.statusId,
      statusName: student.statusName,

      error: student.error
    }
    return newStudent
  })


  const newListStudents = newData.filter(student => student.error === false)

  useEffect(() => {
    setListStudents(newListStudents)
  }, [data])

  return (
    <DataTable 
      value={newData} size="small" responsiveLayout="scroll" className='empleoyees-table-upload'
      rowClassName={rowClass} 
      selection={selectedRow} onSelectionChange={e => setSelectedRow(e.value)} dataKey="numeroDocumento"
      paginator rows={10} 
    >
      <Column header="" body={switchBodyTemplate}></Column>
        {dynamicColumns}
    </DataTable>
  )
}

export default StudentsTableUploadNotes

const SwitchBody = ({rowData, setListStudents, listStudents}) => {
  const {position, ...newRowData} = rowData

  const handleChange = (e) => {
    const id = e.target.id
    let listOptions = []
    if (listStudents.length === 0) {
      listOptions.push(newRowData)
    } else {
      const hasOption = listStudents.some(student => student.documentNumber === id );
      if (hasOption) {
        listOptions = listStudents.filter((solOption) =>  solOption.documentNumber !== id)
      } else {
        listOptions = [...listStudents, newRowData];
      }
    }
    setListStudents([...listOptions])
  }

  const isChecked = (newRowData) => {
    if (listStudents.length === 0) return false;
    if (listStudents.length > 0) {
       return listStudents.some(student => student.documentNumber === newRowData.documentNumber)
    }
    return false;
  };

  return <InputSwitch inputId={newRowData.documentNumber} id={newRowData.documentNumber} checked={isChecked(newRowData)} onChange={handleChange} disabled={rowData.error} />
}