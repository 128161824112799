/* eslint-disable react-hooks/exhaustive-deps */


import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { Paginator } from 'primereact/paginator';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { MonitoringAction } from 'features/indicators/store/actions/MonitoringAction';
import { Tooltip } from 'primereact/tooltip';
import { useClaimIndicatorMonitoring } from 'features/indicators/hooks/useClaimIndicatorMonitoring';

// const staticData = [
//     {
//         id: '1',
//         source: 'Proceso 1',
//         data: [
//             {
//                 id: '1-1',
//                 code: 'COD001',
//                 name: 'Indicador 1-1',
//                 meta: 10,
//                 unit: 'cm',
//                 frequency: 'Mensual',
//                 values: [
//                     { key: 'v1-1-1',value: 5, date: '2022/07/1',  color: 'red' },
//                     { key: 'v1-1-2',value: 15, date: '2022/07/2', color: 'green' },
//                     { key: 'v1-1-3',value: 10, date: '2022/07/3', color: 'yellow' },
//                     { key: 'v1-1-4',value: 8, date: '2022/07/4', color: 'blue' }
//                 ]
//             },
//         ]
//     },
// ]

const TableComponent = ({setDisplayModal, setIndicatorSelected, setDisplayActionPlanModal, setTypeModal}) => {
    
    const dispatch = useDispatch();
    
    
    const advancedFilter = useSelector((state) => state.monitoringReducer.advancedFilter);
    const { data } = useSelector((state) => state.monitoringReducer.monitorings);
    const { isSaved } = useSelector((state) => state.monitoringReducer.monitoringValues);

    // const getFromDate = (rowData) => moment(rowData.from).format(CoreConstants.Format.LOCALE); 

    const columns = [
        { field: 'source', header: 'Proceso'},
    ];

    const { isView , isTracking, isGraphic, isActionPlan } = useClaimIndicatorMonitoring();

    const [selectedColumns, setSelectedColumns] = useState(columns);
    const actionsHeader = useRef(null);
    const menu = useRef(null);
    const [showConfirmDialog, setshowConfirmDialog] = useState(false);
    const [isFirstLoading, setFirstLoading] = useState(true); 
    // const [expandedRows, setExpandedRows] = useState(null);

    const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState(null);

    useEffect(() => {
        if (isFirstLoading) {
            tryParseFilter();
            setFirstLoading(false);
            setSortField("code");
            setSortOrder(1);
            dispatch(MonitoringAction.search());
        }
    }, []);

    let items = [
        {
            label: 'Seguimiento',
            icon: 'pi pi-eye',
            command: (e) => {
                setDisplayModal(true)
                setTypeModal('seguimiento');
                // setIndicatorData(indicatorSelected);
                // history.push('/indicadores/indicador/' + indicatorSelected.id);
            }
        },
        {
            label: 'Gráfico',
            icon: 'pi pi-chart-bar',
            command: (e) => {
                setDisplayModal(true)
                setTypeModal('grafico');
            }
        },
        {
            label: 'Plan de Acción',
            icon: 'pi pi-user-plus',
            command: (e) => {
                setDisplayActionPlanModal(true)
                // setshowConfirmDialog(true);
            }
        },
    ];

    if (!isTracking)
        items = items.filter(c => c.label !== 'Seguimiento');
    if (!isGraphic)
        items = items.filter(c => c.label !== 'Gráfico');
    if (!isActionPlan)
        items = items.filter(c => c.label !== 'Plan de Acción');

    const onSortCustom = (field) => {
        sortField === field ? setSortOrder(sortOrder * -1) : setSortField(field)
        // setSortField(field);
        // setSortOrder((prev) => (prev === 1 ? -1 : 1));
        
        advancedFilter.columnOrder = field;
        advancedFilter.order = (sortOrder * (sortField === field ? -1 : 1)) === 1 ? 'asc' : 'desc';
        // advancedFilter.order = sortOrder === 1 ? 'asc' : 'desc';
        advancedFilter.page = 1;
        dispatch(MonitoringAction.updateFilter(advancedFilter));
		dispatch(MonitoringAction.search());
    }

    const headerTemplateWithToogleSort = (rowData) => {
        return (
            <div 
                className="flex justify-between items-center gap-x-2 cursor-pointer" 
                onClick={(() => onSortCustom(rowData.field))}
            >
                <div>{rowData.header}</div>
                <div className="flex">
                    {/* <i 
                        className="pi pi-sort-amount-up-alt " 
                        // onClick={() => sortField === rowData.field ? setSortOrder(sortOrder * -1) : setSortField(rowData.field)}
                    ></i> */}
                    {
                        sortOrder === 1 && sortField === rowData.field ? <i className="pi pi-sort-amount-up-alt"></i> : <i className="pi pi-sort-amount-down-alt"></i>
                    }
                </div>
            </div>
        )
    }

    const onOpenView = (rowData) => {
        if(isView){
            return <div className='underline text-blue-700 cursor-pointer' onClick={()=>{
                setIndicatorSelected(rowData);
                setDisplayModal(true)
                setTypeModal('vista');
            }}>{rowData.code}</div>
        }else{
            return rowData.code
        }
    }

    // useEffect(() => {
    //     if (isDeleted) {
    //         setshowConfirmDialog(false);
    //         dispatch(MonitoringAction.resetAfterSaved());
    //         dispatch(MonitoringAction.search());
    //     }
    // }, [isDeleted])

    useEffect(() => {
        if (isSaved) {
            // dispatch(MonitoringAction.resetAfterSaved());
            dispatch(MonitoringAction.search());
        }
    }, [isSaved])

    // const columnComponents = selectedColumns.map(col => {

    //     if (col.field === 'to') 
    //         return <Column key={col.field} field={col.field} header={col.header} body={getToDate} sortable />;

    //     if (col.body)
    //         return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable />;

    //     return <Column key={col.field} field={col.field} header={col.header} sortable />;
    // });

    // const actionHeaderTemplate = (rowData) => {
    //     return <i
    //         key="action"
    //         className="pi pi-ellipsis-v cursor-pointer"
    //         onClick={(e) => actionsHeader.current.toggle(e)}></i>;
    // }

    const actionBodyTemplate = (rowData) => {
        if(isTracking || isGraphic || isActionPlan){
            return <i
                key={rowData.id}
                className="pi pi-ellipsis-v cursor-pointer"
                onClick={(event) => {
                    setIndicatorSelected(rowData);
                    menu.current.toggle(event)
                }
                }></i>
        }else{
            return <div></div>
        }
    }

    const tryParseFilter = () => {
        try {
            const filters = JSON.parse(localStorage.getItem("gestion-indicator_monitoring-filter") || '[]');
            const myFilters = filters.filter(item => item.field === 'source');
            if (filters)
                setSelectedColumns(myFilters);
            else
                setSelectedColumns(columns);
        } catch (error) {
            localStorage.removeItem('gestion-indicator_monitoring-filter');
        }
    }

    const onColumnToggle = (e, item) => {
        let _selectedColumns = [...selectedColumns];

        if (e.checked)
            _selectedColumns.push(item);
        else
            _selectedColumns = _selectedColumns.filter(c => { return c.field !== e.value });

        setSelectedColumns(_selectedColumns);
        localStorage.setItem("gestion-indicator_monitoring-filter", JSON.stringify(_selectedColumns));
    }

    const getColumns = () => {
        let array = [];
        
        columns.forEach((item) => {
            const column = <div key={item.field} className="p-field-checkbox py-1">
                <Checkbox
                    inputId={item.field}
                    name="column"
                    value={item.field}
                    onChange={(e) => onColumnToggle(e, item)}
                    checked={selectedColumns.some((x) => x.field === item.field)}
                />
                <label className="ml-1" htmlFor={item.field}>{item.header}</label>
            </div>
            array.push(column);
        });
        return array;
    };

    const confirmDelete = () => {
        // dispatch(EmployerAction.deleteEmployer(indicatorSelected.id));
    }

    const cancelDelete = () => {
        setshowConfirmDialog(false);
    }

    const [rows, setRows] = useState(10)

    const onPageChange = (e) => {
        setRows(e.rows);
        advancedFilter.page = e.page + 1;
        advancedFilter.pageSize = e.rows;
        dispatch(MonitoringAction.updateFilter(advancedFilter));
        dispatch(MonitoringAction.search());
    }

    const onSort = (e) => {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
		
		advancedFilter.columnOrder = e.sortField;
		advancedFilter.order = e.sortOrder === 1 ? 'asc' : 'desc';
		advancedFilter.page = 1;
		dispatch(MonitoringAction.updateFilter(advancedFilter));
		dispatch(MonitoringAction.search());
    }

    const getClassColor = (color) => {
        switch (color) {
            case 'green':
                return 'indicator-green'
            case 'red':
                return 'indicator-red'
            case 'yellow':
                return 'indicator-yellow'
            default:
                return '';
        }
    }

    const valuesBodyTemplate = (rowData) => {
        return (
            <div className='flex gap-x-2'>
                {
                    rowData.values.map((item, index) => {
                        return (
                            <div key={item.id} >
                                <Tooltip className='ml-1' target={`.tt-${item.id}`} />
                                <div 
                                    // className={`flex justify-center items-center rounded-full tt-${item.id} bg-${item.color}-600 h-5 w-5 z-50`}
                                    className={`flex justify-center items-center rounded-full tt-${item.id} ${getClassColor(item.color)} h-5 w-5 z-50`}
                                    data-pr-tooltip={moment(item.valueDate).format('DD-MM-YYYY') + '\n' + item.value}
                                >
                                    {/* <p className='text-white font-bold'>{item.value}</p> */}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    // const rowExpansionTemplate = (data) => {    
    //     return (
    //       <div className="tasks-subtable">
    //         <DataTable value={data.indicators} header={null} className='ml-0' 
    //             // lazy loading={loading}
    //           emptyMessage="No se encontraron tareas">
    //           <Column field="code" header="Código" sortable/>
    //           <Column field="name" header="Indicador" />
    //           <Column field="goal" header="Meta" />
    //           <Column field="goalValue" header="Valor Meta" align='right' />
    //           <Column field="measureUnit" header="Unidad Medida"/>
    //           <Column field="frequency" header="Frecuencia"/>
    //           <Column field="values" body={valuesBodyTemplate} header="Últimos 5 valores"/>
    //           {/* <Column header={<div></div>} body={(e) => RowEditTask(e, data.id)}></Column>
    //           <Column header={<div></div>} body={(e) => RowDeleteTask(e, data.id)}></Column> */}
    //             <Column header={<div></div>} body={actionBodyTemplate} ></Column>
    //         </DataTable>
    //       </div>
    //     );
    //   };

    return (
        <Fragment>
            {/* <div className="table-main table-contact mb-3">
                <DataTable
                    value={data}
                    emptyMessage="No se encontraron entregables"
                    stripedRows
                    rows={5}
                    editMode="row"
                    dataKey="sourceName"
                    rowHover
                    size="small"
                    responsiveLayout="scroll"
                    rowExpansionTemplate={rowExpansionTemplate}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    onRowExpand={(e) => onRowExpand(e.data)}
                    lazy
                >                      
                    <Column expander style={{ width: '3em' }} />
                    <Column field="sourceName" header="Origen"/>
                </DataTable>
            </div>
            */}
            <div className="table-main table-contact mb-3">
                <DataTable 
                    value={data?.results} 
                    responsiveLayout="scroll"
                    header={null} 
                    className='ml-0' 
                    // lazy loading={loading}
                    emptyMessage="No se encontraron indicadores"

                    sortField={sortField}
                    // sortOrder={sortOrder}
                    onSort={onSort}
                >
                    {/* <Column field="code" header="Código" body={onOpenView} sortable/> */}
                    <Column 
                        body={onOpenView} 
                        field="code" 
                        // header="Código" 
                        // sortable
                        header={() => headerTemplateWithToogleSort({field: 'code', header: 'Código'})} 
                        headerClassName={'code' === sortField ? `p-sortable-column p-highlight` : ''}
                    />
                    <Column field="name" header="Indicador" />
                    <Column field="period" header="Periodo" />
                    <Column field="sourceName" header="Origen" />
                    <Column field="goal" header="Meta" />
                    <Column field="goalValue" header="Valor Meta" align='right' />
                    <Column field="measureUnit" header="Unidad Medida"/>
                    <Column field="frequency" header="Estado"/>
                    <Column field="values" body={valuesBodyTemplate} header="Últimos 5 valores"/>
                    {/* <Column header={<div></div>} body={(e) => RowEditTask(e, data.id)}></Column>
                    <Column header={<div></div>} body={(e) => RowDeleteTask(e, data.id)}></Column> */}
                    <Column header={<div></div>} body={actionBodyTemplate} ></Column>
                </DataTable>
            </div>
            

            <Paginator
                key={"pag-1"}
                className="paginator-custom mt-4"
                rows={rows} 
                first={(data?.currentPage - 1) * rows}
                rowsPerPageOptions={[10,20,30]}
                totalRecords={data?.rowCount}
                onPageChange={onPageChange}
            ></Paginator>

            <OverlayPanel ref={actionsHeader} showCloseIcon style={{ width: '300px' }}>{getColumns()}</OverlayPanel>

            <Menu model={items} popup ref={menu} id="popup_menu" />
            <ConfirmDialog
                visible={showConfirmDialog}
                onHide={cancelDelete}
                message="¿Está seguro que desea eliminar el registro?"
                header="Eliminar"
                icon="pi pi-trash" 
                accept={confirmDelete}
                reject={cancelDelete}
            />
        </Fragment >
    );
};

export default TableComponent;
