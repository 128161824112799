import { SubscriptionConstants } from "features/subscription/commons/SubscriptionConstants";
const initialState = {
  subscriptions: {
    currentData: [],
    data: [],
    loading: false,
    pagination: {},
    currentCol: "customerName",
    order: "desc",
  },
  subscriptionsFilter: {
    showModal: false,
    values: [],
  },
  subscriptionEdit: {
    loadingEdit: false,
    loadingSave:false,
    showModalState:false,
    typeModal:"",
    isSave:false,
    EmpresaSelect:{},
    customers:[],
    dataSubscription: {},
    users: []
  },
  dataManager: {
    loadingDataManager: true,
    dataManager: {
      customers: [],
      status: [],
      countries: [],
      isos:[],
    },
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case SubscriptionConstants.Accion.Subscriptions.SEARCH_SUBSCRIPCIONES:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          ...action.payload,
        },
      };
    case SubscriptionConstants.Accion.Subscriptions.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        subscriptionsFilter: {
          ...state.subscriptionsFilter,
          ...action.payload,
        },
      };
    case SubscriptionConstants.Accion.Subscriptions.REMOVE_FILTER_VALUES:
      return {
        ...state,
        subscriptionsFilter: {
          values: [...action.payload],
        },
      };
    case SubscriptionConstants.Accion.Subscriptions.MODALS:
      return {
        ...state,
        subscriptionEdit: {
          ...state.subscriptionEdit,
          ...action.payload,
        },
      };
    case SubscriptionConstants.Accion.Subscriptions.SET_FILTER_VALUES:
      return {
        ...state,
        subscriptionsFilter: {
          ...state.subscriptionsFilter,
          ...action.payload,
        },
      };

    case SubscriptionConstants.Accion.Subscriptions.DATA_MASTER:
      return {
        ...state,
        dataManager: {
          ...state.dataManager,
          ...action.payload,
        },
      };
    case SubscriptionConstants.Accion.Subscriptions.EDIT_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptionEdit: {
          ...state.subscriptionEdit,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
