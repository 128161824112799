import { apiService } from 'core/services/ApiService';

const getAppSettings = () => {
  return apiService.Get('management/api/ApplicationSetting/GetApplicationSettings')
}



export const SharedService = {
  getAppSettings
};