import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SurveyAction } from 'features/survey/store/actions/SurveyAction'
import useSetTitlePage from 'shared/hooks/useSetTitlePage'
import SurveyAnalysisCard from 'features/survey/components/Survey/SurveyAnalysisCard'
import SurveyAnalysisCardLarge from 'features/survey/components/Survey/SurveyAnalysisCardLarge'
import SurveyAnalysisModalAnswer from 'features/survey/components/Survey/SurveyAnalysisModalAnswer'
import SurveyAnalysisModalTotalQuestion from 'features/survey/components/Survey/SurveyAnalysisModalTotalQuestion'
import SurveyAnalysisModalTrend from 'features/survey/components/Survey/SurveyAnalysisModalTrend'

const SurveyAnalysis = ({title}) => {

  const { updateTitle } = useSetTitlePage();
  const params = useParams();
  const dispatch = useDispatch();
  const { data: dataCards, title: surveyTitle } = useSelector((state) => state.surveyReducer.surveySummary);
  const [displayModalTrend, setDisplayModalTrend] = useState(false)
  const [displayModalTotalQuestion, setDisplayModalTotalQuestion] = useState(false)
  const [displayModalAnswers, setDisplayModalAnswers] = useState(false)

  const data2 = [
    {
      icon: 'pi-chart-line',
      description: 'Tendencias',
      code: 'TE',
      openModal: () => setDisplayModalTrend(true),
    },
    {
      icon: 'pi-chart-pie',
      description: 'Totales por pregunta',
      code: 'TP',
      openModal: () => setDisplayModalTotalQuestion(true),
    },
    {
      icon: 'pi-calendar-plus',
      description: 'Respuestas Individuales',
      code: 'RI',
      openModal: () => setDisplayModalAnswers(true),
    }
  ]

  useEffect(() => {
    updateTitle({
      title: "Gestión de Encuestas",
      subtitle: "Encuestas",
      description: title,
      previousUrl: "/encuestas/encuesta/"
    })
    if (params.id)  dispatch(SurveyAction.getSurveySummaryById(params.id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return (
    <>
      <div className='flex justify-center w-full'>
        <div className='flex flex-col w-4/5'>
          <SurveyAnalysisCard data={dataCards} />
          <SurveyAnalysisCardLarge data={data2} />
        </div>
      </div>

      <SurveyAnalysisModalTrend displayModal={displayModalTrend} setDisplayModal={setDisplayModalTrend} surveyTitle={surveyTitle}  />
      <SurveyAnalysisModalTotalQuestion displayModal={displayModalTotalQuestion} setDisplayModal={setDisplayModalTotalQuestion} surveyTitle={surveyTitle}  />
      <SurveyAnalysisModalAnswer displayModal={displayModalAnswers} setDisplayModal={setDisplayModalAnswers} surveyTitle={surveyTitle}  />
    </>
  )
}

export default SurveyAnalysis