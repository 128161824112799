

import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SelectButton } from 'primereact/selectbutton';
import moment from 'moment';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import { CoreConstants } from 'core/commons/CoreConstants';
import { Menu } from 'primereact/menu';
import { Paginator } from 'primereact/paginator';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CoursesMonitoringAction } from "features/coursesManagment/store/actions/CourseMonitoringAction";
import { useClaimMonitoring } from 'features/coursesManagment/hooks/useClaimMonitoring';
import { Link } from 'react-router-dom';

const TableComponent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const typeViews = [
        { icon: 'pi pi-list', value: CoreConstants.TypeView.LIST },
        { icon: 'pi pi-microsoft', value: CoreConstants.TypeView.CANVA }
    ];
    const { isView ,isEdit } = useClaimMonitoring();
    const { data } = useSelector((state) => state.courseMonitoringReducer.courses);
    const advancedFilter = useSelector((state) => state.courseMonitoringReducer.advancedFilter);
    const getInitialDate = (rowData) => { return moment(rowData.scheduleInitialDate).format(CoreConstants.Format.LOCALE); }
    const getEndDate = (rowData) => {
        if(rowData.scheduleEndDate !== null) {
            return  moment(rowData.scheduleEndDate).format(CoreConstants.Format.LOCALE) || "No precisado"; 
        }else {
            return "No precisado";
        }
    }

    const columns = [
        { field: 'name', header: 'Nombre' },
        { field: 'category', header: 'Cateogoría' },
        { field: 'courseType', header: 'Tipo de Curso' },
        { field: 'mode', header: 'Modalidad' },
        { field: 'service', header: 'Servicio' },
        { field: 'scheduleInitialDate', header: 'Fecha Inicio', body: getInitialDate },
        { field: 'scheduleEndDate', header: 'Fecha Fin', body: getEndDate},
    ];

    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);
    const actionsHeader = useRef(null);
    const menu = useRef(null);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [courseSelected, setCourseSelected] = useState(null);
    const [isFirstLoading, setFirstLoading] = useState(true); 

    useEffect(() => {
        if (isFirstLoading) {
            tryParseFilter();
            setFirstLoading(false);
            setSortField("name");
            setSortOrder(1);
            dispatch(CoursesMonitoringAction.search());
        }
    }, []);

    let items = [
        {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: (e) => {
                history.push('/curso/seguimiento/' + courseSelected.id);
            }
        }
    ];

    if (!isEdit)
        items = items.filter(c => c.label !== 'Editar');

    const getViewField = (rowData, col) => {

        if (isView)
          return <Link className="underline text-blue-700" to={{ pathname: `/curso/seguimiento/visualizar/` + rowData.id }} >{rowData.code}</Link>
        else
          return rowData.code;
    }


    const columnComponents = selectedColumns.map(col => {

        if (col.field === 'scheduleInitialDate')
            return <Column key={col.field} field={col.field} header={col.header} body={getInitialDate} sortable />;

        if (col.field === 'scheduleEndDate')
            return <Column key={col.field} field={col.field} header={col.header} body={getEndDate} sortable />;

        if (col.body)
            return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable />;

        return <Column key={col.field} field={col.field} header={col.header} sortable />;
    });


    const onPage = (event) => {
        advancedFilter.page = event.page + 1;
        dispatch(CoursesMonitoringAction.updateFilter(advancedFilter));
        dispatch(CoursesMonitoringAction.search());
    }

    const onSort = (e) => {

        setSortField(e.sortField);
        setSortOrder(e.sortOrder);

        advancedFilter.columnOrder = e.sortField;
        advancedFilter.order = e.sortField === 1 ? 'asc' : 'desc';
        advancedFilter.page = 1;
        dispatch(CoursesMonitoringAction.updateFilter(advancedFilter));
        dispatch(CoursesMonitoringAction.search());
    }

    const actionHeaderTemplate = (rowData) => {
        return <i
            key="action"
            className="pi pi-ellipsis-v cursor-pointer"
            onClick={(e) => actionsHeader.current.toggle(e)}></i>;
    }

    const actionBodyTemplate = (rowData) => {
        if(isEdit){
            return <i
                key={rowData.id}
                className="pi pi-ellipsis-v cursor-pointer"
                onClick={(event) => {
                    setCourseSelected(rowData);
                    menu.current.toggle(event)
                }
                }></i>
        }else{
            return <div></div>
        }
    }

    const tryParseFilter = () => {
        try {
            const filters = JSON.parse(localStorage.getItem("monitoringCourse-filter"));
            //debugger;
            if (filters)
                setSelectedColumns(filters);
            else
                setSelectedColumns(columns);
        } catch (error) {
            localStorage.removeItem('monitoringCourse-filter');
        }
    }

    const onColumnToggle = (e, item) => {
        let _selectedColumns = [...selectedColumns];

        if (e.checked)
            _selectedColumns.push(item);
        else
            _selectedColumns = _selectedColumns.filter(c => { return c.field !== e.value });

        setSelectedColumns(_selectedColumns);
        localStorage.setItem("monitoringCourse-filter", JSON.stringify(_selectedColumns));
        ;
    }

    const getColumns = () => {
        let array = [];
        columns.map((item, index) => {
            const column = <div key={item.field} className="p-field-checkbox py-1">
                <Checkbox
                    inputId={item.field}
                    name="column"
                    value={item.field}
                    onChange={(e) => onColumnToggle(e, item)}
                    checked={selectedColumns.some((x) => x.field === item.field)}
                />
                <label className="ml-1" htmlFor={item.field}>{item.header}</label>
            </div>
            array.push(column);
        });
        return array;
    };

    return (
        <div className='mt-2'>
            <div className="flex justify-end mb-2">
                <SelectButton
                    unselectable={false}
                    value={typeView}
                    options={typeViews}
                    onChange={(e) => setTypeView(e.value)}
                    itemTemplate={(option) => { return <i className={option.icon}></i>; }}
                />
            </div>

            {typeView === CoreConstants.TypeView.CANVA && <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2  gap-2">
                {
                    data?.results.map((item, index) => {
                        return (
                            <div key={"card" + item.id}
                                className="
                                block 
                                p-4 
                                max-w-sm 
                                bg-white 
                                rounded-lg 
                                border 
                                border-gray-200 
                                shadow-md 
                                hover:bg-gray-100 
                                dark:bg-gray-800 
                                dark:border-gray-700 
                                dark:hover:bg-gray-700">

                                <div>
                                    <div className="flex justify-between">
                                        {
                                            isView?(
                                                <Link className="underline text-blue-700" to={{ pathname: `/curso/seguimiento/` + item.id }} ><h5>{item.code} - {item.name}</h5></Link>
                                            ):(
                                                <h5
                                                key={"title" + item.id}
                                                className="mb-1  font-semibold text-sm  text-gray-900 dark:text-white"
                                                >
                                                {item.code} - {item.name}
                                                </h5>
                                            )
                                        }
                                        {
                                            (isEdit)&&(
                                                <i
                                                key={"options" + item.id}
                                                className="pi pi-ellipsis-v cursor-pointer"
                                                onClick={(event) => {
                                                    // console.log("hola");
                                                    setCourseSelected(item);
        
                                                    menu.current.toggle(event);
                                                }}
                                                ></i>
                                            )
                                        }
                                    </div>
                                    <hr />
                                    {/* <div className="flex items-center gap-x-2">  */}
                                    <div className="grid text-xs text-gray-600 dark:text-gray-400 mt-2">
                                        <h5 key={"category" + item.id}>
                                        <span className="text-xs text-gray-900 ">Categoría:</span>{" "}
                                        {item.category}
                                        </h5>
                                        <h5>
                                        <span className="text-xs text-gray-900">
                                            Tipo de curso:
                                        </span>{" "}
                                        {item.courseType}
                                        <br></br>
                                        <span className="text-xs text-gray-900">
                                            Modalidad:
                                        </span>{" "}
                                        {item.mode}
                                        </h5>
                                        <h5>
                                        <span className="text-xs text-gray-900">Servicio:</span>{" "}
                                        {item.service}
                                        <br></br>
                                        <span className="text-xs text-gray-900">
                                            Correo:
                                        </span>{" "}
                                        {item.mail}
                                        </h5>
                                        <i className="pi pi-calendar">
                                        {" "}
                                        {moment(item.scheduleInitialDate).format(CoreConstants.Format.LOCALE)}-
                                        {moment(item.scheduleEndDate).format(CoreConstants.Format.LOCALE)}
                                        </i>
                                    </div>

                                    {/* </div>  */}
                                </div>
                                <div className="flex justify-between">
                                    <p className="text-xs text-gray-700 dark:text-gray-400">
                                        {item.parentCompanyAreaName
                                        ? item.parentCompanyAreaName + " -"
                                        : ""}{" "}
                                        {item.manager}
                                    </p>
                                </div>
                            </div>
                        );
                    })
                }

            </div>}

            {typeView === CoreConstants.TypeView.LIST &&
                <div className="mt-3 " style={{ maxWidth: (window.innerWidth - 100) + 'px' }}>                    
                    <DataTable
                        value={data?.results}
                        stripedRows
                        responsiveLayout="scroll"
                        size="small"
                        sortField={sortField}
                        sortOrder={sortOrder}
                        emptyMessage="No se encontraron cursos"
                        onSort={(e) => onSort(e)}>
                        <Column field="code" header="Codigo" body={(rowData) => getViewField(rowData)} sortable />
                        {columnComponents}
                        <Column header={actionHeaderTemplate} body={actionBodyTemplate} ></Column>
                    </DataTable>
                </div>
            }

            <Paginator
                key={"pag-1"}
                className="paginator-custom mt-4"
                rows={10}
                first={(data?.currentPage - 1) * data?.pageSize}
                totalRecords={data?.rowCount}
                onPageChange={(e) => { onPage(e); }}
            ></Paginator>

            <OverlayPanel ref={actionsHeader} showCloseIcon style={{ width: '300px' }}>{getColumns()}</OverlayPanel>

            <Menu model={items} popup ref={menu} id="popup_menu" />
        </div>
    );
};

export default TableComponent;
