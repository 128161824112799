import { Link } from "react-router-dom";

export const TableCard = ({ id, name, creationDate, selectedRow }) => {
  return (
    <div className="block p-4 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
      <div className="flex justify-between">
        <Link
          className="mb-1 text-md font-bold underline text-blue-700"
          to={{ pathname: `/mantenimiento/ficha/visualizar/` + id }}
        >
          {name}
        </Link>
        <i
          className="pi pi-ellipsis-v cursor-pointer"
          onClick={(event) => selectedRow(event, { id })}
        ></i>
      </div>
      <hr />
      <div className="grid grid-cols-1 mt-3 text-gray-700 dark:text-gray-400">
        <div>
          <p className="text-xs font-bold">Fecha:</p>
          <p className="text-xs">{creationDate}</p>
        </div>
      </div>
    </div>
  );
};
