import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';

import ModalFilterComponent from 'shared/components/modal-filter/ModalFilterComponent';
import useAdvancedFilter from 'features/rrhh/hooks/positions/useAdvancedFilter';

const AdvancedFilterComponent = () => {
  const {
    departments,
    onToggleModal,
    showModal,
    formValues,
    handleChangeInput,
    clearFields,
    onAdvancedFilter,
  } = useAdvancedFilter();

  if (showModal) {
    return (
      <ModalFilterComponent
        closeModal={() => onToggleModal(false)}
        clearFields={clearFields}
        handleSubmit={onAdvancedFilter}
      >
        <div className="flex gap-4 pt-3">
          <div className="w-full">
            <span className="p-float-label">
              <Calendar
                name="fromDate"
                value={formValues.fromDate}
                onChange={handleChangeInput}
              />
              <label htmlFor="desde">Desde</label>
            </span>
          </div>
          <div className="w-full">
            <span className="p-float-label">
              <Calendar
                name="toDate"
                value={formValues.toDate}
                onChange={handleChangeInput}
              />
              <label htmlFor="hasta">Hasta</label>
            </span>
          </div>
        </div>
        <div className="w-full pt-7">
          <span className="p-float-label">
            <Dropdown
              options={departments}
              name="departmentId"
              optionLabel="name"
              filter
              showClear
              className="w-full"
              onChange={handleChangeInput}
              value={formValues.departmentId}
            />

            <label htmlFor="name">Departamento</label>
          </span>
        </div>
        <div className="w-full pt-7">
          <span className="p-float-label w-full">
            <InputNumber
              inputId="quantity"
              name="quantityRequiredEmployees"
              className="w-full"
              value={formValues.quantityRequiredEmployees}
              onValueChange={handleChangeInput}
            />
            <label>N° Empleados Requeridos</label>
          </span>
        </div>
        <div className="w-full pt-7">
          <span className="p-float-label w-full">
            <InputNumber
              name="quantityActiveEmployees"
              className="w-full"
              value={formValues.quantityActiveEmployees}
              onValueChange={handleChangeInput}
            />
            <label htmlFor="quantityActiveEmployees">
              N° Empleados Activos
            </label>
          </span>
        </div>
      </ModalFilterComponent>
    );
  }
  return null;
};

export default AdvancedFilterComponent;
