import { apiService } from "core/services/ApiService";

const searchBusinessLines = (criteria) => {
  return apiService.Post("customer/api/BusinessLine/Search", criteria);
}

const saveBusinessLines = (data) => {
  return apiService.Post("customer/api/BusinessLine/Save", data);
}

const getBusinessLineById = (businessLineId) => {
  return apiService.Get("customer/api/BusinessLine/GetBusinessLineById?id=" + businessLineId);
}

const generateReport = (payload) => {
  return apiService.Post("customer/api/BusinessLine/GenerateReport", payload, {
    responseType: 'blob',
  })
} 

const deleteBusinessLine = (businessLineId) => {
  return apiService.Delete("customer/api/BusinessLine/Delete?id=" + businessLineId);
}

export const BusinessLinesService = {
  searchBusinessLines,
  saveBusinessLines,
  getBusinessLineById,
  generateReport,
  deleteBusinessLine,
};