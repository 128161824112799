import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputSwitch } from 'primereact/inputswitch'
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { useSelector } from 'react-redux'
import { CoreConstants } from 'core/commons/CoreConstants';

const IndicatorTableUpload = ({listIndicators, setListIndicators}) => {
  const [selectedRow, setSelectedRow] = useState(null)
  const {data} = useSelector(state => state.indicatorReducer.bulkLoad)

  // console.log(data)
  
  const columns = [
    {field: 'code', header: 'Código'},	
    {field: 'valueDate', header: 'Fecha'},
    {field: 'value', header: 'Valor'},
  ];

  const getValueDate = (rowData) => { return rowData.valueDate ? moment(rowData.valueDate).format(CoreConstants.Format.LOCALE) : ''}

  const dynamicColumns = columns.map((col,i) => {
    if (col.field === 'valueDate') {
        return <Column key={col.field} field={col.field} header={col.header} body={getValueDate} sortable />;
      }
    

    return <Column key={col.field} field={col.field} header={col.header} />;
  });

  const switchBodyTemplate = (rowData) => {
    return <SwitchBody rowData={rowData} setListIndicators={setListIndicators} listIndicators={listIndicators} />
  }

  const newData = data.map((indicator) => {
    // const newEmployee = {
    //   firstName: indicator.names,
    //   fatherLastName: indicator.fatherLastName,
    //   motherLastName: indicator.motherLastName,
    // }
    return indicator
  })

  const newListIndicator = data.filter((indicator) => indicator.error === false)

  useEffect(() => {
    setListIndicators(newListIndicator)
  }, [data])

  const rowClass = (data) => {
    return {
        'row-errors': data.error === true
    }
  }

  return (
      <DataTable 
        value={newData} size="small" responsiveLayout="scroll" 
        rowClassName={rowClass} 
        className='empleoyees-table-upload w-full'
        selection={selectedRow} onSelectionChange={e => setSelectedRow(e.value)} dataKey="code"
        paginator rows={10} 
      >
        <Column header="" body={switchBodyTemplate}></Column>
          {dynamicColumns}
      </DataTable>
  )
}

export default IndicatorTableUpload

const SwitchBody = ({rowData, setListIndicators, listIndicators}) => {
  const {position, ...newRowData} = rowData
  
  const handleChange = (e) => {
    const id = e.target.id
    let listOptions = []
    if (listIndicators.length === 0) {
      listOptions.push(newRowData)
    } else {
      const hasOption = listIndicators.some(indicator => indicator.index === id );
      if (hasOption) {
        listOptions = listIndicators.filter((solOption) =>  solOption.index !== id)
      } else {
        listOptions = [...listIndicators, newRowData];
      }
    }
    setListIndicators([...listOptions])
  }

  const isChecked = (newRowData) => {
    if (listIndicators.length === 0) return false;
    if (listIndicators.length > 0) {
       return listIndicators.some(indicator => indicator.index === newRowData.index)
    }
    return false;
  };

  return <InputSwitch inputId={newRowData.index} id={newRowData.index} checked={isChecked(newRowData)} onChange={handleChange} disabled={rowData.error} />
}