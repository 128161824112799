import { Link } from "react-router-dom";

export const TableCard = ({ id, code, name, status, type, selectedRow, statusCode }) => {
  return (
    <div className="block p-4 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
      <div className="flex justify-between">
        <Link
          className="mb-1 text-md font-bold underline text-blue-700"
          to={{ pathname: `/negocios/fichas/configuracion/` + id }}
        >
          {code}
        </Link>
        <i
          className="pi pi-ellipsis-v cursor-pointer"
          onClick={(event) => selectedRow(event, { id, statusCode })}
        ></i>
      </div>
      <hr />
      <div className="grid grid-cols-1 mt-3 text-gray-700 dark:text-gray-400">
        <div>
          <p className="text-xs font-bold">Nombre:</p>
          <p className="text-xs">{name}</p>
        </div>
      </div>
      <div className="grid grid-cols-2 text-gray-700 dark:text-gray-400 mt-3">
        <div>
          <p className="text-xs font-bold">Estado:</p>
          <p className="text-xs">{status}</p>
        </div>
        <div>
          <p className="text-xs font-bold">Tipo:</p>
          <p className="text-xs">{type}</p>
        </div>
      </div>
    </div>
  );
};
