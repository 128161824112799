import { Button } from 'primereact/button';
import LogoInputFile from './LogoInputFile';
import useLogos from 'features/configuration/hooks/sistem/useLogos';
import { Skeleton } from 'primereact/skeleton';
import './LogoCompanyComponent.scss';
import { useClaimSystemConfiguration } from 'features/configuration/hooks/useClaimSystemConfiguration';

const LogoCompanyComponent = () => {
  const {
    setFiles,
    handlerSubmit,
    isLoadingSave,
    isLoading,
    urlImage,
    setUrlImage,
  } = useLogos();

  const { isCreate, isEdit, isDeleted } = useClaimSystemConfiguration();

  return (
    <div className="content-tab-sistem">
      <h2 className="mt-lg-0 mt-3">Logos de Empresa</h2>
      <p className="preference">
      Elige una imagen de preferencia de 220px por 40px de medida
      </p>
      <p className="description mb-3 mt-5 font-semibold">
        Aquí puedes agregar el logo que se visualizará en la barra de navegación{' '}
      </p>
      <div className="logo-company">
        {isLoading ? (
          [1].map((n) => (
            <Skeleton width="40%" height="4rem" className="mt-4" key={n} />
          ))
        ) : (
          <>
            <LogoInputFile
              name="LOGO_DEFAULT"
              setFiles={setFiles}
              setUrlImage={setUrlImage}
              urlImage={urlImage.LOGO_DEFAULT}
              label="Adjuntar logo "
              srcImg="/images/logos/main-logo.png"
            />
          </>
        )}
      </div>

      {!isLoading && (
        <div className="flex gap-3 items-center justify-start mt-6">
          {
            (isCreate && isEdit && isDeleted)&&
            <Button
              onClick={handlerSubmit}
              loading={isLoadingSave}
              // disabled
              type="button"
              label="Guardar"
              className="sig-button sig-primary"
            />
          }
        </div>
      )}
    </div>
  );
};

export default LogoCompanyComponent;
