import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from 'core/store/actions/ToastAction';
import { RrhhConstants } from 'features/rrhh/commons/RrhhConstants';
import { OrganizationChartService } from 'features/rrhh/services/OrganizationChartServices';

// Action Usadas

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: RrhhConstants.Accion.OrganizationChart.RESET_AFTER_SAVED });
};

const saveCompanyArea = (position) => {
  const isLoading = ({ dispatch, isSaving, isSaved }) => {
    dispatch({
      type: RrhhConstants.Accion.OrganizationChart.SAVE_COMPANY_AREA,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch) => {
    isLoading({ dispatch: dispatch, isSaving: true, isSaved: false });
    OrganizationChartService.saveCompanyArea(position).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Recursos Humanos', 'El área se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true });
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL)
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false});
        else
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      },
    );
  };
};


const saveNode = (nodeData) => {
  const isLoading = ({ dispatch, isSaving, isSaved }) => {
    dispatch({
      type: RrhhConstants.Accion.OrganizationChart.SAVE_NODE,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch) => {
    isLoading({ dispatch: dispatch, isSaving: true, isSaved: false });
    OrganizationChartService.saveNode(nodeData).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Recursos Humanos', 'El área se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false});
          dispatch(toastAction.warn('Recursos Humanos', data.message));
        }else{
          dispatch(toastAction.error('Recursos Humanos', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }
      },
    ).catch((error)=>{
    });
  };
};

const getDataMaster = () => {
  const setDataMaster = ({ dispatch, isLoading, areas, positions, employees }) => {
    dispatch({
      type: RrhhConstants.Accion.OrganizationChart.GET_DATA_MASTER,
      payload: { isLoading, areas, positions, employees },
    });
  };

  return (dispatch) => {
    setDataMaster({ dispatch: dispatch, isLoading: true, areas: null, positions: [], modalities: null });

    OrganizationChartService.getDataMaster().then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            areas: data.data.areas,
            positions: data.data.positions,
            employees: data.data.employees,
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            areas: [],
            positions: [],
            employees: [],
          });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            areas: [],
            positions: [],
            employees: [],
          });
          dispatch(toastAction.warn('Organigrama', data.message));
        }
      },
    );
  };
};

const adapterGetOrganizationChart = (nodes) => {
  nodes.forEach(node => {
    if(node.data.name === " ") {
      node.data.name = "-"
    }
    if(node.children.length > 0) adapterGetOrganizationChart(node.children)
  });
}

const getOrganizationChart = () => {
  const setNodes = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: RrhhConstants.Accion.OrganizationChart.GET_ORGANIZATION_CHART,
      payload: { isLoading, data },
    });
  };
  const setAreas = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: RrhhConstants.Accion.OrganizationChart.GET_COMPANY_AREA_PARENTS,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setNodes({ dispatch: dispatch, isLoading: true, data: [] });

    OrganizationChartService.getOrganizationChart().then(
      ({ data }) => {
        // console.log(data)
        if (data.status === CoreConstants.HttpResponse.OK){
          const organizationChart = [data.data.organizationChart];
          adapterGetOrganizationChart(organizationChart)
          setNodes({ dispatch: dispatch, isLoading: false, data: organizationChart });
          setAreas({ dispatch: dispatch, isLoading: false, data: data.data.areas });
        }
        else if (data.status < CoreConstants.HttpResponse.OK) {
          setNodes({ dispatch: dispatch, isLoading: false, data: []});
          setAreas({ dispatch: dispatch, isLoading: false, data: []});
        }
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setNodes({ dispatch: dispatch, isLoading: false, data: []});
          setAreas({ dispatch: dispatch, isLoading: false, data: []});
          dispatch(toastAction.warn('Organigrama', data.message));
        }
      },
    );
  };
};

const deleteNode = (nodeId) => {
  return (dispatch) => {
    OrganizationChartService.deleteNode(nodeId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Organigrama', 'El área se eliminó correctamente'));
          dispatch({ type: RrhhConstants.Accion.OrganizationChart.DELETE_NODE, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Organigrama', data.message));
          dispatch({ type: RrhhConstants.Accion.OrganizationChart.DELETE_NODE, payload: { isDeleted: false } });
        }
      }
    );
  };
};

const getNodeById = (nodeId) => {
  const setNode = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: RrhhConstants.Accion.OrganizationChart.GET_NODE_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setNode({ dispatch: dispatch, isLoading: true, data: null });
    OrganizationChartService.getNodeById(nodeId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setNode({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setNode({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Organigrama', data.message));
        }
      }
    );
  };
};



export const OrganizationChartAction = {
  getNodeById,
  deleteNode,
  saveCompanyArea,
  resetAfterSaved,
  saveNode,
  getDataMaster,
  getOrganizationChart,
};
