import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

export const useClaimPeople = () => {

  const menu = useSelector((state) => state.authReducer.user.menuPrincipal);
  const pathName = useLocation()?.pathname;
  const [isSearch, setSearch] = useState(false);
  const [isDownload, setDownload] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isCreate, setCreate] = useState(false);
  const [isView, setView] = useState(false);
  const [isImport, setImport] = useState(false);

  useEffect(() => {
    search();
    download();
    remove();
    edit();
    create();
    view();
    Import();
  }, [menu]);

  const search = () => {
    const actionCode = 'HUMAN-RESOURCE_EMPLOYEE_SEARCH';
    const optionCode = "EMPLE";
    setSearch(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const download = () => {
    const actionCode = 'HUMAN-RESOURCE_EMPLOYEE_DOWNLOAD';
    const optionCode = "EMPLE";
    setDownload(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
 };

  const remove = () => {
    const actionCode = 'HUMAN-RESOURCE_EMPLOYEE_DELETE';
    const optionCode = "EMPLE";
    setDelete(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const edit = () => {
    const actionCode = 'HUMAN-RESOURCE_EMPLOYEE_EDIT';
    const optionCode = "EMPLE";
    setEdit(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const create = () => {
    const actionCode = 'HUMAN-RESOURCE_EMPLOYEE_CREATE';
    const optionCode = "EMPLE";
    setCreate(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const view = () => {
    const actionCode = 'HUMAN-RESOURCE_EMPLOYEE_VIEW';
    const optionCode = "EMPLE";
    setView(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const Import = () => {
    const actionCode = 'HUMAN-RESOURCE_EMPLOYEE_IMPORT';
    const optionCode = "EMPLE";
    setImport(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  return {
    isSearch,
    isDownload,
    isCreate,
    isDelete,
    isEdit,
    isView,
    isImport
  }
}