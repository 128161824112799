import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ProcessMap } from "./pages/PageProcessMap";


import processeSheetReducer from './store/reducers/ProcesseSheetReducer'
import tasksReducer from 'features/customers/store/reducers/CompaniesReducer';
import companiesReducer from 'features/customers/store/reducers/CompaniesReducer'
import { PageNewProceseSheet } from "./pages/ProceseSheet/ProceseSheet/PageNewProceseSheet";
import { PageNewProcess } from "./pages/PageNewProcess";
import { PageEditProcess } from "./pages/PageEditProcess";
import ProcessManagementReducer from "./store/reducers/ProcessManagementReducer";
import DocumentsReducer from "./store/reducers/DocumentsReducer";

export default function ProcessMapRouting(reducerRegistry) {
  const { path } = useRouteMatch();
  reducerRegistry.register({ processeSheetReducer });
  reducerRegistry.register({ tasksReducer });
  reducerRegistry.register({ companiesReducer });
  reducerRegistry.register({ ProcessManagementReducer });
  reducerRegistry.register({ DocumentsReducer });

  return (
    <>
      <Switch>
        <Route path={`${path}/nuevo-proceso`} component={PageNewProcess}/>
        <Route exact path={`${path}/editar-proceso/:id`} component={PageEditProcess}/>
        <Route exact path={`${path}/editar-proceso/:id/:show`} component={PageEditProcess}/>
        <Route exact path={`${path}/fichaProcesos/ShowWf`} component={()=> <PageNewProceseSheet title='Aprobación Ficha' subtitle='Mis Tareas' description='Aprobar Ficha' previousUrl="/inicio" disabled={true}/>}/>
        <Route path={`${path}/mapa-proceso`} component={ProcessMap}/>
        <Route path={`${path}/fichaProcesos`}>
          <Route exact path={`${path}/fichaProcesos`} component={()=> <PageNewProceseSheet title='Gestión de Procesos' subtitle='Mapa Procesos' description='Ficha de Procesos' previousUrl="/mapa-procesos/mapa-proceso" disabled={false}/>}/>
        </Route>
			</Switch>
    </>
  );
}
