import { Switch, Route, useRouteMatch } from 'react-router-dom';
import IndicatorPage from './pages/Indicator/Indicator/IndicatorPage';
import SearchIndicator from './pages/Indicator/Search/SearchIndicator';
import SearchManagmentPage from './pages/Management/Search/SearchManagmentPage';
import SearchMonitoring from './pages/Monitoring/Search/SearchMonitoring';
import indicatorReducer from './store/reducers/IndicatorReducer';
import monitoringReducer from './store/reducers/MonitoringReducer';
import managmentReducer from './store/reducers/ManagmentReducer';
import ManagmentPage from './pages/Management/Managment/ManagmentPage';

export default function IndicatorsRouting(reducerRegistry) {
  const { path } = useRouteMatch();
  reducerRegistry.register({ indicatorReducer });
  reducerRegistry.register({ monitoringReducer });
  reducerRegistry.register({ managmentReducer })

  return (
    <>
      <Switch>
        <Route path={`${path}/administracion`}>
          <Switch>
            <Route
            exact
            path={`${path}/administracion`}
            component={SearchManagmentPage}
            />
            <Route
            exact
            path={`${path}/administracion/nuevo`}
            component={()=> <ManagmentPage title={"Nuevo"}/>}
            />
            <Route
            exact
            path={`${path}/administracion/:id`}
            component={()=> <ManagmentPage title={"Editar"}/>}
            />
            <Route
            exact
            path={`${path}/administracion/visualizar/:id`}
            component={()=> <ManagmentPage title="Ver Administración" typeMode="view"/>}
            />
          </Switch>
        </Route>
        <Route path={`${path}/indicador`}>
          <Switch>
            <Route
              exact
              path={`${path}/indicador`}
              component={SearchIndicator}
            />
            <Route
              path={`${path}/indicador/nuevo`}
              exact
              component={() => <IndicatorPage title="Nuevo Indicador" />}
            />
            <Route
              path={`${path}/indicador/:id`}
              exact
              component={() => <IndicatorPage title="Editar Indicador" />}
            />
            <Route
              path={`${path}/indicador/visualizar/:id`}
              exact
              component={() => <IndicatorPage title="Ver Indicador" mode='view'/>}
            />
          </Switch>
        </Route>
        <Route path={`${path}/seguimiento`}>
          <Switch>
            <Route
              exact
              path={`${path}/seguimiento`}
              component={SearchMonitoring}
            />
            <Route
              path={`${path}/seguimiento/nuevo`}
              exact
              component={() => <IndicatorPage title="Nuevo Indicador" />}
            />
            <Route
              path={`${path}/seguimiento/:id`}
              exact
              component={() => <IndicatorPage title="Editar Indicador" />}
            />
          </Switch>
        </Route>
      </Switch>
    </>
  )
}