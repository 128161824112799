import { BusinessViewConstants } from "features/business/commons/BusinessViewConstants";
import { ViewActions } from "features/business/store/actions/ViewAction";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const useFilter = () => {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(initFilter);

  const setValue = (e) => {
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  };

  const setValueFilter = (criteria) => {
    setFormValues(criteria);
  }

  const clearFields = () => {
    setFormValues(initFilter);
    dispatch({
      type: BusinessViewConstants.Action.BusinessView.SEARCH,
      payload: {
        results: [],
        // currentPage: 1,
        // pageCount: 1,
        // pageSize: 10,
        // rowCount: 0,
      },
    });
  };

  const applyFilter = () => {
    const filter = {
      // page: 1,
      // pageSize: 10,
      // columnOrder: "name",
      // order: "asc",
    };
    if (formValues.customerId) filter["customerId"] = formValues.customerId;
    if (formValues.stageId) filter["stageId"] = formValues.stageId;
    if (formValues.responsibleId)
      filter["responsibleId"] = formValues.responsibleId;
    if (formValues.openDate) filter["openDate"] = formValues.openDate;
    if (formValues.closeDate) filter["closeDate"] = formValues.closeDate;
    if (formValues.pipelineId) filter["pipelineId"] = formValues.pipelineId;
    dispatch(ViewActions.search(filter));
  };

  return {
    setValueFilter,
    formValues,
    setValue,
    clearFields,
    applyFilter,
  };
};

const initFilter = {
  pipelineId: "",
  customerId: "",
  responsibleId: "",
  stageId: "",
  openDate: "",
  closeDate: "",
};
