import FileSaver from 'file-saver';
import { toastAction } from 'core/store/actions/ToastAction';
import { ConfigurationConstants } from 'features/configuration/commons/ConfigurationConstants';
import { RoleService } from 'features/configuration/services/RoleService';
import { CoreConstants } from 'core/commons/CoreConstants';

const setRoles = (fields) => {
  return (dispatch) =>
    dispatch({
      type: ConfigurationConstants.Accion.Roles.SETLIST,
      payload: { ...fields },
    });
};

const toggleModalFilters = (payload) => {
  return (dispatch) =>
    dispatch({
      type: ConfigurationConstants.Accion.Roles.CHANGEFILTERMODAL,
      payload,
    });
};

const getRoles = (fields) => {
  return (dispatch) => {
    dispatch(setRoles({ loading: true }));
    RoleService.getRoles(fields)
      .then(({ data }) => {
        const status = data?.status ?? '';
        if (status === CoreConstants.HttpResponse.OK) {
          const { results, ...rest } = data.data;
          let objFields = {
            loading: false,
            data: results,
            currentData: results,
            pagination: { ...rest },
          };
          if (fields.fields?.columnOrder) {
            objFields.currentCol = fields.fields.columnOrder;
          }
          if (fields.fields?.order) {
            objFields.order = fields.fields.order;
          }
          dispatch(setRoles(objFields));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn('Roles y Privilegios', data.message));
        }else{
          dispatch(toastAction.error('Roles y Privilegios', data.message));
        }
        dispatch(setRoles({ loading: false }));
      })
      .catch((error)=>{
        dispatch(setRoles({ loading: false }));
      });
  };
};

const setActions = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ConfigurationConstants.Accion.Roles.ACTIONS,
      payload,
    });
  };
};
const setRolesOptions = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ConfigurationConstants.Accion.Roles.LISTOPTIONS,
      payload,
    });
  };
};

const getRolesOptions = () => {
  return (dispatch) => {
    dispatch(setRolesOptions({ loading: true }));
    dispatch(setActions({ loading: true }));

    RoleService.getRolesOptions()
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(setRolesOptions({ loading: false, options: data.data }));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.error('Roles y Privilegios', data.message));
        }
        dispatch(setRolesOptions({ loading: false }));
      })
      .catch((error)=>{
        dispatch(setRolesOptions({ loading: false }));
      });
  };
};

const setUserRoleById = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ConfigurationConstants.Accion.Roles.GET_USER_BY_ROLE_ID,
      payload,
    });
  };
};

const getUserByRoleId = (id) => {
  return (dispatch) => {
    dispatch(setUserRoleById({ loading: true }));
    RoleService.getUserByRoleId(id)
      .then(({ data }) => {
        const status = data?.status ?? '';
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(setUserRoleById({ loading: false, data: data.data }));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.error('Roles y Privilegios', data.message));
        }
        dispatch(setUserRoleById({ loading: false, data: [] }));
      })
      .catch((error)=>{
        dispatch(setUserRoleById({ loading: false, data: [] }));
      });
  };
};

const setRoleById = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ConfigurationConstants.Accion.Roles.EDIT_ROLE,
      payload,
    });
  };
};

const getRoleById = (roleId) => {
  return (dispatch) => {
    dispatch(setRoleById({ loading: true }));
    RoleService.getRoleById(roleId)
      .then(({ data }) => {
        const status = data?.status ?? '';
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(setRoleById({ loading: false, data: data.data }));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn('Roles y Privilegios', data.message));
        }else{
          dispatch(toastAction.error('Roles y Privilegios', data.message));
        }
        dispatch(setRoleById({ loading: false, data: {} }));
      })
      .catch((error)=>{
        dispatch(setRoleById({ loading: false, data: {} }));
      });
  };
};

const setFilterValues = (payload) => {
  return (dispatch, getState) => {
    let { values } = getState().roleReducer.filterRole;
    if (values.length > 0) {
      const fields = payload.map(({ field }) => field);
      const removeValues = values.filter((val) => fields.includes(val.field));
      if (removeValues.length > 0) {
        removeValues.forEach(({ field }) => {
          dispatch(removeFilterValues(field));
        });
      }
    }
    dispatch({
      type: ConfigurationConstants.Accion.Roles.SET_FILTER_VALUES,
      payload,
    });
  };
};

const removeFilterValues = (field) => {
  return (dispatch, getState) => {
    let { values } = getState().roleReducer.filterRole;

    let payload = [];
    if (values.length > 0)
      payload = values.filter((val) => val.field !== field);

    dispatch({
      type: ConfigurationConstants.Accion.Roles.REMOVE_FILTER_VALUES,
      payload,
    });
  };
};

const saveRoleStatus = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ConfigurationConstants.Accion.Roles.SAVE_ROLE_STATUS,
      payload,
    });
  };
};

const saveRole = (role, message) => {
  return (dispatch) => {
    dispatch(saveRoleStatus({ status: '' }));
    RoleService.saveRole(role)
      .then(({ data }) => {
        const status = data?.status ?? '';
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Roles y Privilegios', message));
          dispatch(saveRoleStatus({ status }));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn('Roles y Privilegios', data.message));
        }else{
          dispatch(toastAction.error('Roles y Privilegios', data.message));
        }
        dispatch(saveRoleStatus({ status }));
      })
      .catch((error)=>{
      });
  };
};

const showDeleteRoleModal = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ConfigurationConstants.Accion.Roles.SHOW_DELETE_ROLE_MODAL,
      payload,
    });
  };
};

const deleteRoleModal = (roleId) => {
  return (dispatch) => {
    RoleService.deleteRole(roleId)
      .then(({ data }) => {
        const status = data?.status ?? '';
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(showDeleteRoleModal({ isOpen: false, roleId: '' }));
          dispatch(getRoles({ change: false }));
          dispatch(toastAction.success('Roles y Privilegios', 'Rol Eliminado'));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn('Roles y Privilegios', data.message));
        }else{
          dispatch(toastAction.error('Roles y Privilegios', data.message));
        }
        showDeleteRoleModal({ isLoading: false, isOpen: false, roleId: '' });
      })
      .catch((error)=>{
        dispatch(
          showDeleteRoleModal({ isLoading: false, isOpen: false, roleId: '' })
        );
      });
  };
};

const roleReportStatus = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ConfigurationConstants.Accion.Roles.DOWNLOAD_REPORT_STATUS,
      payload,
    });
  };
};

const generateRoleReport = (payload) => {
  return (dispatch) => {
    dispatch(roleReportStatus(true));

    RoleService.generateReport(payload)
      .then(({ data }) => {
        dispatch(roleReportStatus(false));
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });
        FileSaver.saveAs(blob, 'Rol.xls');
      })
      .catch((err) => {
        dispatch(roleReportStatus(false));
        dispatch(
          toastAction.error(
            'Roles y Privilegios',
            'Hubo un error al descargar el archivo'
          )
        );
        console.log(err);
      });
  };
};

const setCurrentUser = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ConfigurationConstants.Accion.Roles.CURRENT_USER,
      payload,
    });
  };
};

export const RolesAction = {
  getRoles,
  toggleModalFilters,
  getRolesOptions,
  getUserByRoleId,
  setUserRoleById,
  getRoleById,
  setFilterValues,
  removeFilterValues,
  saveRole,
  saveRoleStatus,
  showDeleteRoleModal,
  deleteRoleModal,
  generateRoleReport,
  setCurrentUser,
};
