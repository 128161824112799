import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RiskManagementAction } from "../store/actions/RiskManagementAction";


const useData = () => {

	const dispatch = useDispatch();
	const { data, probability, impact, level, status } = useSelector(state => state.riskManagementReducer.data)
  const { conditions, colors, logicalOperators, processes	} = useSelector(state => state.riskModelReducer.dataMasterEvaluation.data)

	return {
		data,
		probability,
		impact,
		level,
    processes,
	};
};

export default useData;