import FileSaver from "file-saver";
import { CoreConstants } from "core/commons/CoreConstants";
import { StudentsConstants } from "features/customers/commons/StudentsConstants";
import { StudentService } from "features/customers/services/StudentService";
import { toastAction } from "core/store/actions/ToastAction";

const setStudents = (fields) => {
  return (dispatch) =>
    dispatch({
      StudentsConstants,
      type: StudentsConstants.Accion.Students.SEARCH_STUDENTS,
      payload: { ...fields },
    });
};

const getStudents = (fields) => {
  return (dispatch) => {
    dispatch(setStudents({ loading: true }));
    StudentService.searchStudent(fields)
      .then(({ data }) => {
        const status = data?.status ?? "";
        if (status === CoreConstants.HttpResponse.OK) {
          const { results, ...rest } = data.data;
          let currentFields = {
            loading: false,
            data: results,
            pagination: { ...rest },
          };
          if (fields?.fields?.order) {
            currentFields.order = fields.fields.order;
          }
          if (fields?.fields?.columnOrder) {
            currentFields.columnOrder = fields.fields.columnOrder;
          }
          dispatch(setStudents(currentFields));
          return;
        }else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Alumnos", data.message));
        }else{
          dispatch(toastAction.error("Alumnos", data.message));
        }
      })
      .catch((error)=>{
      })
      .finally(() => {
        dispatch(setStudents({ loading: false }));
      });
  };
};

const setAdvancedFilter = (payload) => {
  return (dispatch) => {
    dispatch({
      type: StudentsConstants.Accion.Students.ADVANCED_FILTER,
      payload,
    });
  };
};

const getFilterValues = (newValues) => {
  const newFilterValues = {};
  newValues.forEach((val) => {
    const field = val.field.toLocaleLowerCase();
    if (field.includes("date")) newFilterValues[val.field] = val.date;
    else if (field.includes("id")) newFilterValues[val.field] = val.option.id;
    else newFilterValues[val.field] = val.value;
  });
  return newFilterValues;
};

const removeFilterValues = (removeField) => {
  return (dispatch, getState) => {
    let { values } = getState().studentsReducer.advancedFilter;
    const newValues = values.filter(({ field }) => field !== removeField);
    const fields = getFilterValues(newValues);
    dispatch(getStudents({ change: true, fields }));
    dispatch(setAdvancedFilter({ values: newValues }));
  };
};

const setGetDataMaster = (payload) => {
  return (dispatch) => {
    dispatch({
      type: StudentsConstants.Accion.Students.DATA_MASTER,
      payload,
    });
  };
};

const getDataMaster = () => {
  return (dispatch) => {
    dispatch(setGetDataMaster({ isLoadingDataMaster: true }));
    StudentService.getDataMaster()
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setGetDataMaster({ data: data.data }));
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setGetDataMaster({ isLoadingDataMaster: false }));
      });
  };
};

const currentAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: StudentsConstants.Accion.Students.CURRENT_ACTION,
      payload,
    });
  };
};
const setCurrentStudent = (payload) => {
  return (dispatch) => {
    dispatch({
      type: StudentsConstants.Accion.Students.EDIT_STUDENT,
      payload,
    });
  };
};

const editStudent = (id) => {
  return (dispatch) => {
    dispatch(setCurrentStudent({ isLoadingStudent: true }));
    StudentService.getStudentById(id)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          const student = data.data.result;
          student.dynamicFieldValues = data.data?.dynamicData
          dispatch(setCurrentStudent({ data: student }));
        }else if(status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Alumnos", data.message));
        }else{
          dispatch(toastAction.error("Alumnos", data.message));
        }
      })
      .catch((error)=>{
      })
      .finally(() => {
        dispatch(setCurrentStudent({ isLoadingStudent: false }));
      });
  };
};

const reportStatus = (payload) => {
  return (dispatch) => {
    dispatch({
      type: StudentsConstants.Accion.Students.REPORT_STATUS,
      payload,
    });
  };
};

const generateReport = () => {
  return (dispatch, getState) => {
    dispatch(reportStatus(true));
    let { values } = getState().studentsReducer.advancedFilter;
    const fieldValues = getFilterValues(values);
    StudentService.generateReport(fieldValues)
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        FileSaver.saveAs(blob, "Alumnos");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(reportStatus(false));
      });
  };
};

const saveStudent = ({ student, isNewStudent }) => {
  return (dispatch) => {
    const currentLoading = { isLoadingStudent: true };

    dispatch(setCurrentStudent(currentLoading));
    StudentService.saveStudent(student)
      .then(({ data }) => {
        const status = data.status;
        if (data.status === CoreConstants.HttpResponse.OK) {
          const message = isNewStudent
            ? "¡Registro exitoso!"
            : "¡Edición exitosa!";
          dispatch(toastAction.success("Alumnos", message));
          dispatch(setCurrentStudent({ errors: [] }));
        } else if (
          data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS &&
          data.data.length > 0
        ) {
          dispatch(toastAction.warn("Alumnos", data.message));
          dispatch(setCurrentStudent({ errors: data.data }));
        }else if(data.status===CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.error("Alumnos", data.message));
        }
        dispatch(setCurrentStudent({ status }));
      })
      .catch((error)=>{
        dispatch(setCurrentStudent({ status: 2 }));
      })
      .finally(() => {
        currentLoading.isLoadingStudent = false;
        dispatch(setCurrentStudent(currentLoading));
      });
  };
};

const deleteStudent = (id) => {
  return (dispatch) => {
    StudentService.deleteStudent(id)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success("Alumnos", "Alumno Eliminado"));
          dispatch(getStudents({ change: false }));
        }else if(status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Alumnos", data.message));
        }else{
          dispatch(toastAction.error("Alumnos", data.message));
        }
      })
      .catch((error)=>{
      });
  };
};

const downloadTemplate = () => {
  return (dispatch) => {
    dispatch(setGetDataMaster({ loadingDataTemplate: true }));
    StudentService.downloadTemplate().then(({data}) =>{
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      });
      FileSaver.saveAs(blob, 'Alumnos.xlsx');
      dispatch(setGetDataMaster({ loadingDataTemplate: false }));
      return;
    }).catch((err) =>{
      dispatch(setGetDataMaster({ loadingDataTemplate: false }));
    });
  }
}

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: StudentsConstants.Accion.Students.RESET_AFTER_SAVED });
};

const uploadBatchInfo = (file) => {

  const setUpload = (isLoading, data, errors) =>{
    return (dispatch) =>
      dispatch({
        type: StudentsConstants.Accion.Students.UPLOAD_BATCH_INFO,
        payload: isLoading, data, errors
      });
  }

  return (dispatch) => {
    dispatch(setUpload({ isLoading: true, data: null, errors: [] }));
    StudentService.uploadBatchInfo(file)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setUpload({ 
            isLoading: false, 
            data: data.data.pageCompanies,
            errors: data.data.errors,
          }));

          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn('Alumnos', data.message));
          dispatch(setUpload({ isLoading: false, data: null }));
        }else{
        dispatch(setUpload({ isLoading: false, data: null, errors: [] }));
        }
      })
      .catch((error)=>{
        dispatch(setUpload({ isLoading: false, data: null, errors: [] }));
      });
  };
}

const saveStudentBulk = (data, lastRow) => {
  const setSave = (isSaved, isLoading) =>{
    return (dispatch) =>
      dispatch({
        type: StudentsConstants.Accion.Students.SAVE_STUDENTS_BULK,
        payload: isSaved, isLoading
      });
  }

  return (dispatch) => {
    // if (lastRow) dispatch(setSave({ isSaved: false }));
    dispatch(setSave({ isSaved: false, isLoading: true }));
    StudentService.saveStudents(data)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          // if (lastRow) {
            dispatch(setSave({ isSaved: true, isLoading: false }));
            dispatch(toastAction.success("Alumno" ,'Registro exitoso'));
          // }
          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.success('Alumnos', data.message));
          dispatch(setSave({ isSaved: false, isLoading: false }));
        }else{
        dispatch(setSave({ isSaved: false, isLoading: false }));
        }
      }).then(()=> {
        dispatch(setSave({ isSaved: false, isLoading: false }));
      })
      .catch((error)=>{
        dispatch(setSave({ isSaved: false, isLoading: false }));
      });
  };
}

export const StudentsAction = {
  setAdvancedFilter,
  getStudents,
  getDataMaster,
  currentAction,
  editStudent,
  generateReport,
  deleteStudent,
  saveStudent,
  setCurrentStudent,
  removeFilterValues,
  downloadTemplate,
  resetAfterSaved,
  uploadBatchInfo,
  saveStudentBulk
};
