import { Controller } from 'react-hook-form';
import { Skeleton } from 'primereact/skeleton';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { Editor } from 'primereact/editor';
import HistoryComponent from 'shared/components/history/HistoryComponent';
import './PositionPage.scss';

import usePosition from 'features/rrhh/hooks/positions/usePosition';
import HeaderEditor from 'shared/components/header-editor/HeaderEditor';
import { ConfirmDialog } from 'primereact/confirmdialog';
import Message from 'shared/components/messages/Message';
import { useClaimPositions } from 'features/rrhh/hooks/useClaimPositions';

const PositionPage = ({ title = 'NUEVO CARGO', mode }) => {
  // const isNew = title === 'NUEVO CARGO';
  const {
    dataMaster,
    dataMasterLoading,
    handleSubmit,
    onSubmit,
    errors,
    control,
    isLoading,
    isLoadingPosition,
    showModalCancel,
    setShowModalCancel,
    cancelAccept,
    cancelDenied,
    isActive,
    setIsActive,
    isNewPosition,
    valuesFields,
    fieldsErrors,
  } = usePosition({
    title,
  });
  const {
    areas: departments,
    positions,
    positionTypes: modalities,
  } = dataMaster;

  const { isCreate, isEdit } = useClaimPositions();

  const dataFakeHistorial = [
    {
      user: {
        url: 'https://www.imgonline.com.ua/examples/rays-of-light-in-the-sky-mini.jpg',
        name: 'Juan Carlos Tenorio',
        date: '16/03/2022 10:45:21 am',
      },
      changes: {
        area: 'Datos Generales',
        state: 'edit',
        detail: '',
        info: [
          {
            campo: 'Departamento Padre',
            beforeValue: '',
            afterValue: 'Pepito',
            state: 'add',
          },
          {
            campo: 'Departamento Padre',
            beforeValue: 'Pepito',
            afterValue: 'Carlos',
            state: 'edit',
          },
          {
            campo: 'Departamento Padre',
            beforeValue: 'Pepito',
            afterValue: '',
            state: 'delete',
          },
        ],
      },
    },
    {
      user: {
        url: 'https://www.imgonline.com.ua/examples/rays-of-light-in-the-sky-mini.jpg',
        name: 'Juan Carlos Tenorio',
        date: '16/03/2022 10:45:21 am',
      },
      changes: {
        area: 'Experiencia Laboral',
        state: 'new',
        detail: 'EL usuario agrego una nueva experiencia laboral',
        info: [],
      },
    },
    {
      user: {
        url: 'https://www.imgonline.com.ua/examples/rays-of-light-in-the-sky-mini.jpg',
        name: 'Juan Carlos Tenorio',
        date: '16/03/2022 10:45:21 am',
      },
      changes: {
        area: 'Experiencia Laboral',
        state: 'delete',
        detail: 'EL usuario ha eliminado una experiencia laboral',
        info: [],
      },
    },
  ];

  const header = HeaderEditor();

  const validateLength = (value) => {
    if (typeof value === 'string') {
      return value.replace(/<[^>]+>/g, '')?.length;
    } else if (value?.htmlValue) {
      return value.htmlValue.replace(/<[^>]+>/g, '').length;
    }
    return '0';
  };

  return (
    <div className="bg-white">
      <form className="form-custom p-0" onSubmit={handleSubmit(onSubmit)}>
        <Panel header="DATOS GENERALES" toggleable>
          <div className="position-pages-form-data lg:mt-6 mt-4">
            <div className="form-data-fields mb-4 lg:order-0 order-1">
              {dataMasterLoading || isLoadingPosition ? (
                <Skeleton width="100%" height="2.5rem" className="mb-3" />
              ) : (
                <div>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label">
                        <InputText
                          onChange={onChange}
                          value={value}
                          className={`w-full p-inputtext p-component ${
                            errors.name?.type === 'required' ? 'p-invalid' : ''
                          }`}
                        />{' '}
                        <label htmlFor="rol_name">Nombre del cargo *</label>
                      </span>
                    )}
                    name="name"
                  />
                </div>
              )}

              <div className="flex lg:flex-row flex-col mt-7 lg:gap-4 gap-8">
                {dataMasterLoading || isLoadingPosition ? (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                  />
                ) : (
                  <span className="p-float-label w-full">
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <InputNumber
                          onValueChange={onChange}
                          value={value}
                          className={`w-full ${
                            errors.quantityEmployeesRequired?.type ===
                            'required'
                              ? ' p-invalid'
                              : ''
                          }`}
                        />
                      )}
                      name="quantityEmployeesRequired"
                    />
                    <label htmlFor="rol_name">
                      Número de empleados Requeridos *
                    </label>
                  </span>
                )}

                <div className="w-full">
                  {dataMasterLoading || isLoadingPosition ? (
                    <Skeleton
                      width="100%"
                      height="2.5rem"
                      className="mb-3 w-full"
                    />
                  ) : (
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <Dropdown
                            options={positions}
                            optionLabel="name"
                            filter
                            showClear
                            filterBy="name"
                            value={value}
                            className="w-full"
                            onChange={onChange}
                            inputRef={ref}
                          />
                        )}
                        name="parentPositionId"
                      />
                      <label htmlFor="dropdown">Cargo al que reporta</label>
                    </span>
                  )}
                </div>
              </div>
              <div className="flex lg:flex-row flex-col mt-7 lg:gap-4 gap-8">
                <div className="w-full">
                  {dataMasterLoading || isLoadingPosition ? (
                    <Skeleton
                      width="100%"
                      height="2.5rem"
                      className="mb-3 w-full"
                    />
                  ) : (
                    <span className="p-float-label">
                      <Controller
                        control={control}
                       
                        render={({ field: { onChange, value, ref } }) => (
                          <Dropdown
                            options={departments}
                            optionLabel="name"
                            filter
                            showClear
                            filterBy="name"
                            value={value}
                            className={`w-full ${
                              errors.companyAreaId?.type === 'required'
                                ? 'p-invalid'
                                : ''
                            }`}
                            onChange={onChange}
                            inputRef={ref}
                          />
                        )}
                        name="companyAreaId"
                      />
                      <label htmlFor="dropdown">Departamento</label>
                    </span>
                  )}
                </div>
                <div className="w-full">
                  {dataMasterLoading || isLoadingPosition ? (
                    <Skeleton
                      width="100%"
                      height="2.5rem"
                      className="mb-3 w-full"
                    />
                  ) : (
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange, value, ref } }) => (
                          <Dropdown
                            options={modalities}
                            optionLabel="description"
                            filter
                            showClear
                            filterBy="description"
                            value={value}
                            className={`w-full ${
                              errors.positionTypeId?.type === 'required'
                                ? 'p-invalid'
                                : ''
                            }`}
                            onChange={onChange}
                            inputRef={ref}
                          />
                        )}
                        name="positionTypeId"
                      />
                      <label htmlFor="dropdown">Modalidad *</label>
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex gap-4 justify-end lg:order-1 order-0">
              {dataMasterLoading || isLoading ? (
                <Skeleton width="80%" height="2.5rem" className="mb-3 w-full" />
              ) : (
                <>
                  <p className=" text-gray-300">ESTADO</p>
                  <InputSwitch
                    checked={isActive}
                    disabled={isNewPosition}
                    onChange={(e) => setIsActive(e.value)}
                  />
                </>
              )}
            </div>
          </div>
        </Panel>
        <Panel header="INFORMACIÓN DEL CARGO" toggleable>
          {dataMasterLoading || isLoadingPosition ? (
            [1, 2, 3].map((k) => (
              <Skeleton
                width="100%"
                height="3.5rem"
                className="mb-3 w-full"
                key={k}
              />
            ))
          ) : (
            <>
              <div className="mb-3">
                <h5 className="font-semibold text-gray-500 mb-2">
                  FUNCIONES DE LA POSICIÓN *
                </h5>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Editor
                        headerTemplate={header}
                        onTextChange={onChange}
                        value={value}
                        className={`${
                          errors.responsibilitiesDescription?.type ===
                          'required'
                            ? 'p-invalid'
                            : ''
                        }`}
                        placeholder="Añade las funciones de la posición"
                      />
                      <p className="font-semibold text-base text-gray-500 lg:mt-1 mt-3 text-right">
                        Caracteres{' '}
                        {validateLength(
                          valuesFields.responsibilitiesDescription
                        )}{' '}
                        / 4000{' '}
                      </p>
                    </>
                  )}
                  name="responsibilitiesDescription"
                />
              </div>
              <div className="mb-3">
                <h5 className="font-semibold text-gray-500 mb-2">
                  PERFIL DEL CARGO
                </h5>

                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Editor
                        headerTemplate={header}
                        onTextChange={onChange}
                        value={value}
                        placeholder="Añade el perfil del cargo"
                      />
                      <p className="font-semibold text-base text-gray-500 lg:mt-1 mt-3 text-right">
                        Caracteres{' '}
                        {validateLength(valuesFields.profileDescription)} / 4000{' '}
                      </p>
                    </>
                  )}
                  name="profileDescription"
                />
              </div>
              <div className="mb-3">
                <h5 className="font-semibold text-gray-500 mb-2">
                  COMPETENCIAS NECESARIAS
                </h5>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Editor
                        headerTemplate={header}
                        onTextChange={onChange}
                        value={value}
                        placeholder="Añade las competencias necesarios para la posición"
                      />
                      <p className="font-semibold text-base text-gray-500 lg:mt-1 mt-3 text-right">
                        Caracteres{' '}
                        {validateLength(valuesFields.skillsDescription)} / 4000{' '}
                      </p>
                    </>
                  )}
                  name="skillsDescription"
                />
              </div>
            </>
          )}
        </Panel>
        {fieldsErrors.length > 0 && (
          <Message messages={fieldsErrors} status="error" />
        )}
        {/*  {!isNew && (
         <Panel header="HISTORIAL" toggleable>
           {dataFakeHistorial.length > 0 && (
             <HistoryComponent history={dataFakeHistorial} />
           )}
         </Panel>
       )} */}
        {(!dataMasterLoading || !isLoadingPosition) && (
          <div className="flex justify-end gap-4 mt-3">
            <Button
              icon="pi pi-times"
              type="button"
              label="Cancelar"
              disabled={isLoading}
              onClick={() => setShowModalCancel(true)}
              className="sig-button sig-secondary mt-4"
            />
            {
              (mode!=='view')&&(<>
              {
                (isCreate || isEdit)&&
                  <Button
                    icon="pi pi-save"
                    type="submit"
                    label="Guardar"
                    loading={isLoading}
                    className="sig-button sig-primary mt-4"
                  />
              }
              </>
              )
            }
          </div>
        )}
      </form>
      <ConfirmDialog
        visible={showModalCancel}
        onHide={() => setShowModalCancel(false)}
        message="¿Está seguro que desea cancelar?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={cancelAccept}
        reject={cancelDenied}
      />
    </div>
  );
};

export default PositionPage;
