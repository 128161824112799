import React, { useState } from "react";
import { SelectButton } from "primereact/selectbutton";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { CoreConstants } from "core/commons/CoreConstants";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import { Paginator } from "primereact/paginator";
import { useTableBusiness } from "features/customers/hooks/business/useTableBusiness";
import { BusinessTableData } from "features/customers/data/business/BusinessTableData";
import TableCard from "./TableCard";
import { Skeleton } from "primereact/skeleton";

export const TableComponent = () => {
  const {
    selectColumns,
    typeView,
    setTypeView,
    headerActions,
    showActions,
    tableBusiness,
    tableLoading,
    tablePagination,
    tableCurrentActions,
    tableActions,
    tableNum,
    tableRows,
    tableSort,
    onSort,
    onChangePage,
    onColumnToggle,
    setCurrentBusiness,
  } = useTableBusiness();

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const showPagination = !tableLoading && tableBusiness.length > 0;

  const ColumnComponents = selectColumns.map((col) => {
    return (
      <Column key={col.field} field={col.field} header={col.header} sortable />
    );
  });

  const typeViews = [
    { icon: "pi pi-list", value: CoreConstants.TypeView.LIST },
    { icon: "pi pi-microsoft", value: CoreConstants.TypeView.CANVA },
  ];

  const TableHeaderActions = () => {
    return (
      <i
        key="action"
        className="pi pi-ellipsis-v cursor-pointer ml-1"
        onClick={(e) => headerActions.current.toggle(e)}
      ></i>
    );
  };

  const selectedRow = (event, { id }) => {
    setCurrentBusiness(id);
    tableCurrentActions.current.toggle(event);
  };

  const TableBodyActions = (selectedItem) => {
    return (
      <i
        onClick={(event) => selectedRow(event, selectedItem)}
        className="pi pi-ellipsis-v cursor-pointer"
      ></i>
    );
  };

  const HeaderColumns = () => {
    return BusinessTableData.columns.map(({ field, header }) => (
      <div key={field} className="p-field-checkbox py-1">
        <Checkbox
          inputId={field}
          name="column"
          value={field}
          onChange={(e) => onColumnToggle(e, { field, header })}
          checked={selectColumns.some((x) => x.field === field)}
        />
        <label className="ml-1" htmlFor={field}>
          {header}
        </label>
      </div>
    ));
  };

  return (
    <div className="mt-5">
      <div className="flex justify-end mb-2">
        <SelectButton
          unselectable={false}
          value={typeView}
          options={typeViews}
          onChange={(e) => setTypeView(e.value)}
          itemTemplate={(option) => {
            return <i className={option.icon}></i>;
          }}
        />
      </div>

      {typeView === CoreConstants.TypeView.LIST && (
        <div
          className="mt-3 "
          style={{ maxWidth: window.innerWidth - 100 + "px" }}
        >
          {
            tableLoading ? <Skeleton height="8rem"/> :
            <DataTable
            value={tableBusiness}
            stripedRows
            rows={10}
            rowHover
            responsiveLayout="scroll"
            size="small"
            first={tableSort?.first}
            sortField={tableSort?.sortField}
            sortOrder={tableSort?.sortOrder}
            filters={tableSort?.filters}
            emptyMessage="No se encontraron negocios"
            onSort={onSort}
          >
            {ColumnComponents}
            {showActions && (
              <Column
                header={TableHeaderActions}
                body={TableBodyActions}
              ></Column>
            )}
          </DataTable>
          }
        </div>
      )}

      {typeView === CoreConstants.TypeView.CANVA && (
        <div className="grid grid-cols-3 gap-2">
          {tableBusiness.map((item, index) => (
            <TableCard
              {...item}
              key={index}
              id={item.id}
              selectedRow={selectedRow}
            />
          ))}
        </div>
      )}

      {showPagination && typeView === CoreConstants.TypeView.LIST && (
        <Paginator
          className="paginator-custom mt-4"
          rowsPerPageOptions={[5, 10, 15]}
          first={tableNum}
          rows={tableRows}
          totalRecords={tablePagination?.rowCount}
          onPageChange={onChangePage}
        ></Paginator>
      )}

      <OverlayPanel
        ref={headerActions}
        showCloseIcon
        style={{ width: "300px" }}
      >
        <HeaderColumns />
      </OverlayPanel>

      <Menu model={tableActions} popup ref={tableCurrentActions} />
    </div>
  );
};
