import React, { useRef } from 'react';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { RiskMatrixAction } from 'features/riskManagement/store/actions/RiskMatrixAction';
import { useParams } from 'react-router-dom';

const FilterComponent = () => {
  
  const { isLoading } = useSelector( state => state.riskMatrixReducer.matrix)
  const { isLoading: isLoadingDownload } = useSelector( state => state.riskMatrixReducer.download)
  const criteriaSearch = useRef()
  const dispatch = useDispatch()
  const { id } = useParams()
  const { data } = useSelector( state => state.riskMatrixReducer.matrix)
  
  const generateReport = () => {
    dispatch(RiskMatrixAction.generateReport(id))
  }

  const search = (event) => {
    event.preventDefault()
    const payload = {
      processIdentifier: id,
      criteria: [
        {
          processFormControlId: data.filter[0].id,
          valueText: criteriaSearch.current.value,
        },
      ],
    };

    dispatch(RiskMatrixAction.getData(payload));
  }

  return (
    <Panel header="FILTRO POR" toggleable>
      <form className="filter-components" onSubmit={search}>
        <div>
            <div className="w-full ">
                <span className="p-float-label p-input-icon-left w-full ">
                    <i className="pi pi-search" />
                    <InputText
                        id="inputSearch"
                        name="inputSearch"
                        ref={criteriaSearch}
                    />
                    <label htmlFor="inputSearch">Buscar por riesgo</label>
                </span>
            </div>
        </div>

        

            <div className="filter-users-action">
                <div className="flex gap-3 w-full">
										
											<Button
                        loading={isLoading || isLoadingDownload}
                        icon="pi pi-search"
                        type="submit"
                        label="Buscar"
                        className="sig-button sig-primary w-full"
											/>  
										
											<Button
                        loading={isLoading || isLoadingDownload}
                        icon="pi pi-download"
                        type="button"
                        label="Descargar"
                        className="sig-button sig-success w-full"
                        onClick={()=> generateReport()}
											/>
										
                </div>
            </div>
        
      </form>
    </Panel>
	);
};

export default FilterComponent;