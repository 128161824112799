import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Panel } from "primereact/panel"
import { Skeleton } from "primereact/skeleton"
import { Fragment, useState } from "react"
import { ProjectTableData } from 'features/customers/data/project/ProjectTableData';
import { Calendar } from "primereact/calendar"
import { CoreConstants } from "core/commons/CoreConstants"
import { InputText } from "primereact/inputtext"
import { Tooltip } from "primereact/tooltip"
import { Controller } from "react-hook-form"
import Message from "shared/components/messages/Message"

export const Deliverables = ({createDeliverable, deliverableList, loading, setShowTaskModal, setidDeliverableShow, setIdTaskShow, setVisibleTask, setidDeleteTask, updateTasksFromDeliverable, isNewProject, updateDeliverable, setVisibleDeliverable, setidDeleteDeliverable, control, errors}) => {
  const [expandedRows, setExpandedRows] = useState(null);
  const errorsArray = [];

  const RowEditTask = (task, documentId) => {      
    return (
      <i
        onClick={() => {
          setShowTaskModal(true);
          setidDeliverableShow(documentId);
          setIdTaskShow(task.id);
        }}
        className="pi pi-pencil cursor-pointer"
      ></i>
    );
  };

  const RowDeleteTask = (task, documentId) => {   
    return (
      <i
        onClick={() => {
          setVisibleTask(true);
          setidDeliverableShow(documentId);
          setidDeleteTask(task.id);
        }}
        className="pi pi-trash cursor-pointer"
      ></i>
    );
  };

  const rowExpansionTemplate = (data) => { 
    return (
      <div className="tasks-subtable">
        {
          loading[data.Id] ? <Skeleton width="100%" height="4.5rem" className="mb-3 w-full" /> :
          <DataTable value={data.Tasks} header={null} lazy
            emptyMessage="No se encontraron tareas">
            <Column field="title" header="Tarea" sortable/>
            <Column field="scheduleDate" header="Inicio" />
            <Column field="endDate" header="Fin" />
            <Column field="responsibleName" header="Responsable"/>
            <Column field="progress" header="Avance"/>
            <Column field="status" header="Estado"/>
            <Column header={<div></div>} body={(e) => RowEditTask(e, data.id)}></Column>
            <Column header={<div></div>} body={(e) => RowDeleteTask(e, data.id)}></Column>
          </DataTable>
        }
      </div>
    );
  };

  const FieldDeliverable = (name) => (row) => {    

    const value = row[name]

    if(name === "beginDate" || name === "endDate") {
      return (
        <Controller
          control={control}
          name={`deliverable_${name}_${row.id}`}
          rules={{required: `Fecha de ${name === "beginDate"? "Inicio": "Fin"} es obligatorio`}}
          defaultValue={row[name] == null? null:new Date(row[name])}
          render={({field: {onChange, ref}, fieldState: {error}}) => {
            return(
            <Calendar
              ref={ref}
              monthNavigator 
              yearNavigator 
              yearRange="1950:2050"
              showIcon={true}      
              className={`w-full ${error ? ' p-invalid' : ''}`}
              dateFormat={CoreConstants.Format.ddmmyy}
              onChange={( e ) => {
                onChange(e)
                updateDeliverable({
                  id: row.id,
                  name: name,
                  value: e.value,
                });
              }}
              value={row[name] == null? null:new Date(row[name])}
            />
          )}}
        />
      )
    }
    else
      return (
        <Controller
          control={control}
          name={`deliverable_${name}_${row.id}`}
          rules={{required: `Nombre del Entregable es obligatorio`}}
          defaultValue={row[name]}
          render={({field: {onChange, ref}, fieldState: {error}}) => {
            return(
            <InputText
              ref={ref}
              type={"text"}
              onChange={(e) => {
                onChange(e)
                updateDeliverable({
                  id: row.id,
                  name: e.target.name,
                  value:  e.target.value,
                });
              }}
              value={value}
              name={name}
              className={`w-full ${error ? ' p-invalid' : ''}`}
            />
          )}}
        />
      );
  };

  const ColumnDeliverableComponents = ProjectTableData.deliverableColumns.map((col) => {  
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable
        style={{width: col.field === 'name'? '50%':'20%', minWidth: 140}}
        // body={(row) => <FieldDeliverable name={col.field} row={row} />}
        body={FieldDeliverable(col.field)}
      />
    );
  });  

  const RowAddTasks = ({id}) => {      
    return (
      <Fragment>
        <Tooltip target=".add-tasks" position="top"/>
        <i
          onClick={() => {
            setShowTaskModal(true);
            setidDeliverableShow(id);
            setIdTaskShow(null);
          }}
          className="pi pi-book cursor-pointer add-tasks"
          data-pr-tooltip="Añadir tarea"
        ></i>
      </Fragment>
    );
  };

  const RowDeleteDeliverable = ({ id }) => {
    return (
      <i
        onClick={() => {
          setVisibleDeliverable(true);
          setidDeleteDeliverable(id);
        }}
        className="pi pi-trash cursor-pointer"
      ></i>
    );
  };

  return(
    <>
      <Button
        icon="pi pi-plus"
        type="button"
        label="Añadir nuevo entregable"
        onClick={createDeliverable}
        className="btn btn-primary mt-2 mb-4"
      />
      <div className="table-main table-contact mb-3">
        <DataTable
          value={deliverableList}
          emptyMessage="No se encontraron entregables"
          stripedRows
          rows={5}
          editMode="row"
          dataKey="id"
          rowHover
          size="small"
          responsiveLayout="scroll"
          tableStyle={{ minWidth: '700px' }}
          rowExpansionTemplate={rowExpansionTemplate}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          onRowExpand={(e) => updateTasksFromDeliverable(e.data.id)}
          lazy
          
        >
          <Column expander style={{ width: "2em" }} hidden={isNewProject} />  
          {ColumnDeliverableComponents} 
          <Column header={<div></div>} hidden={isNewProject} body={RowAddTasks}></Column>
          <Column header={<div></div>} body={RowDeleteDeliverable}></Column>
        </DataTable>
      </div>
      {Object.entries(errors).forEach((key, value) => {
        if(key[0].startsWith("deliverable")) errorsArray.push(key[1].message);
      })}
      {errorsArray.length > 0 && (
        <Message messages={[...new Set(errorsArray)]} status="error" />
      )}

    </>
  )
}