import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

export const NavigationSnapShot = ({snapShotName, projectName, projectId, close, visible }) => {
  const history = useHistory()
  const { createId } = useSelector(
    (state) => state.projectsReducer.snapshot
  );

  return(
    <Dialog header={<i style={{color: '#84d56c', fontSize: '25px'}} className="pi pi-check-circle"/>} style={{padding: 0}} visible={visible} onHide={close}>
      <p className="mt-6">Se ha generado la planificación {snapShotName} para el proyecto {projectName}</p>
      <div className="flex justify-end gap-4 mt-4">
        <Button
          icon="pi pi-times"
          type="button"
          onClick={close}
          label="Cerrar"
          className="sig-button sig-secondary"
          style={{maxHeight:"2rem"}}
          />
        <Button
          icon="pi pi-save"
          type="button"
          label="Ver Planificación"
          onClick={() => { close(); history.push(`/proyecto/vista-proyectos/planificacion/${createId}`)}}
          className="sig-button sig-primary"
          style={{maxHeight:"2rem"}}
        />
        <Button
          icon="pi pi-save"
          type="button"
          label="Ver Todas"
          onClick={() => { close(); history.push(`/proyecto/vista-proyectos/planificaciones/${projectId}`)}}
          className="sig-button sig-success"
          style={{maxHeight:"2rem"}}
        />
      </div>
    </Dialog>
  )
}