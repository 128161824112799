import { Controller } from 'react-hook-form'
import { RadioButton } from "primereact/radiobutton"
import { useState } from "react";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import './SeleccionSimpleCustom.scss'

export const SeleccionSimpleCustom = (props) => {
	if(props.forDownload){
		return(
			<Controller
			rules={!props.required ? {} : {
				required: `${props.text} es requerido`,
		}}
			control={props.control}
			name={props.id.toString()}
			render={({field: { onChange, value}})=>{
				if((!value || value === "") && props.forDownload){
					return <div></div>
				}
				return (
				<div className='mb-4'>
					<div style={{fontSize: "0.75rem", color: "black",fontWeight:"bold"}}>
						{props.text}
					</div>
					<div style={{fontSize: "0.75rem", color: "#495057"}}>
						{props.details.find((item)=> Number(item.id) === Number(value))?.text}
					</div>
				</div>
			)}}
		/>)
	}else{
		return (
		<div className='mb-6'>
			<h5 style={{fontSize: "0.75rem", color:"#ced4da"}}>{props.text}</h5>
			<div className="">
				{
					<Controller
					rules={!props.required ? {} : {
						required: `${props.text} es requerido`,
				}}
					control={props.control}
					name={props.id.toString()} 
					render={({field: { onChange, value}})=>(
						props.details.map(item => {
							return (
							 <div key={item.id} className={`flex simple-selection-ficha mt-2 ${props.errors[props.id.toString()] ? 'p-invalid' : ''}`}>
								 <input type="radio" id={item.id} checked={item.id === Number(value)} name={props.id} value={item.id} onChange={onChange} disabled={props.show === "ShowWf"}/>
								 <label className="pl-2 my-auto" style={{fontSize: "0.8rem", }} htmlFor={item.id}>{item.text}</label>	
							 </div>
							)
						})
					)}
					/>
		 	}
			</div>
		</div>
		// <>
		// 	<h5>{props.text}</h5>
		// 	{
		// 		props.details.map(item => {
		// 			return (
		// 				<div className="field-radiobutton" key={item.id}>
		// 					<RadioButton inputId={item.id} name={props.id} value={item.id} onChange={(e)=>{console.log({[props.id]: e.target.value}); props.setRes({...props.res, [props.id]: e.target.value})}} checked={props.res[props.id] == item.id}/>
		// 					<label htmlFor={item.id}>{item.text} </label>
		// 				</div>
		// 			)
		// 		})
		// 	}
		// </>
	)
	}
}

// onChange={(e) => setCity(e.value)} checked={city === 'Chicago'}
// onChange={(e) => setCity(e.value)} checked={city === 'Los Angeles'} 