import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import { Controller } from 'react-hook-form'

export const ControllerText = ({control, errors, ValidateInput, validateDuplicate}) => {

  return (
    <>
      <span className="col-span-2 p-float-label w-full mt-4">
          <Controller
              control={control}
              render={({
                  field: { onChange, onBlur, value }
              }) => (
                <InputText
                    type="text"
                    id="fieldLabel"
                    name="fieldLabel"
                    onChange={onChange}
                    autoFocus
                    onBlur={onBlur}
                    value={value}
                    className={errors?.fieldLabel ? 'p-invalid w-full' : 'w-full'}
                />
              )}
              name="fieldLabel"
              rules={{
                  required: 'La etiqueta del campo es requerida.',
                  validate: ValidateInput
              }}
          />
          <label htmlFor="fieldLabel">Etiqueta del campo  *</label>
      </span>
      <div className='col-span-2 p-float-label w-full mt-4 '>
        <span className="p-float-label">
            <Controller
                control={control}
                render={({
                    field: { onChange, value }
                }) => (
                  <div className='flex items-center'>
                    <InputSwitch checked={value} onChange={onChange} />
                    <h5 className='ml-2'>Requerido</h5>
                  </div>
                )}
                name="required"
            />
        </span>
      <div className={`rounded bg-red-200 p-5 mt-6 text-sm text-red-600 ${validateDuplicate===false&&'hidden'}`}>
        <ul>
          <li>La etiqueta del campo, ya se encuentra en uso.</li>
        </ul>
      </div>
      </div>
    </>
  )
}
