import { apiService } from "core/services/ApiService";

//* Endpoints Implementadas
const searchCourseExpenses = (criterio) => {
  return apiService.Post("customer/api/CourseExpense/Search", criterio);
}

const getDataMaster = () => {
  return apiService.Post("customer/api/CourseExpense/GetDataMaster");
}

const saveCourseExpense = (data) => {
  return apiService.Post("customer/api/CourseExpense/Save", data);
}

const deleteCourseExpense = (courseId) => {
  return apiService.Delete(`customer/api/CourseExpense/Delete?id=${courseId}`);
}

const generateReport = (payload) => {
  return apiService.Post("customer/api/CourseExpense/GenerateReport", payload, {
    responseType: 'blob',
  })
}

const getCourseExpenseById = (id) => {
  return apiService.Get("customer/api/CourseExpense/GetCourseExpenseById?id=" + id);
}

const addComment = (payload) => {
  return apiService.Post(`customer/api/Task/AddComment`, payload);
}

const deleteComment = (id) => {
  return apiService.Delete(`customer/api/Task/DeleteComment?id=${id}`);
}

const getCommentsByCourseExpenseId = (id) => {
  return apiService.Get(`customer/api/CourseExpense/GetCommentsByCourseExpenseId?id=${id}`);
}
//* Endpoints sin Implementar

// const addAttachment = (payload) => {
//   return apiService.Post(`customer/api/ProjectExpense/AddAttachment`, payload);
// }

// const deleteAttachment = (id) => {
//   return apiService.Delete(`customer/api/ProjectExpense/DeleteAttachment?id=${id}`);
// }

export const CoursesExpensesService = {
  //* Endpoints Implementadas
  searchCourseExpenses,
  getDataMaster,
  saveCourseExpense,
  deleteCourseExpense,
  generateReport,
  
  getCourseExpenseById,
  addComment,
  deleteComment,
  getCommentsByCourseExpenseId,
  //* Endpoints sin Implementar
  // addAttachment,
  // deleteAttachment,
}