import { useState } from 'react';
import { useDispatch } from 'react-redux';
// import { RecoveryPasswordAction } from 'features/public/store/actions/RecoveryPasswordAction';
import PublicLayout from 'shared/components/public-layout/PublicLayout';
import './ActivateAccountPage.scss';
import { useParams } from 'react-router';
// import SuccessRecoveryPasswordModal from 'features/public/components/recovery-password/SuccessRecoveryPasswordModal';
import { userService } from 'features/public/services/UserService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from 'core/store/actions/ToastAction';
import { authAction } from 'core/store/actions/AuthAction';
import { useFormPassword } from 'features/public/hooks/useFormPassword';
import { PasswordForm } from 'features/public/components/PasswordForm';

const ActivateAccountPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const {checkErrors, dataForm, errors, handleChange} = useFormPassword()

  const { token } = useParams();

  const submit = (e) => {
    e.preventDefault()
    const model = {
      token: token,
      confirmPassword: dataForm.confirmPassword,
      password: dataForm.password
    }
    
    const isValidate = checkErrors()
    if(isValidate){
      setIsLoading(true);
      userService.activarCuenta(model)
        .then(data => {
          setIsLoading(false);

          if (data.data.status === CoreConstants.HttpResponse.OK) {
            dispatch(toastAction.success('Activar cuenta', 'Tu cuenta se activó correctamente'));
            dispatch(authAction.logout());
            history.push('/seguridad/inicio-sesion');
          }

          if (data.data.status > CoreConstants.HttpResponse.OK)
            dispatch(toastAction.warn('Activar cuenta', data.data.message));

        }, (error) => {

          setIsLoading(false);
        });
    }
    

    // console.log('Enviando');
    // dispatch(RecoveryPasswordAction.recuperarContrasena(token, data.password));
  };

  return (
    <PublicLayout page="new-password">
      <div className='form-custom'>
        <header className="header">
          <div className="title">
            <h3 className="mb-2 font-bold">Activar cuenta</h3>
          </div>
          <div className="description text-left">
            <p className="text-sm">
              Bienvenido a Calidar para confirmar tu cuenta ingresa una contraseña segura
            </p>
          </div>
        </header>
        <PasswordForm
          dataForm={dataForm}
          errors={errors}
          handleChange={handleChange}
          isLoading={isLoading}
          submit={submit}
        />
      </div>
        {/* <div className="fields">
          <span className="p-float-label field w-full">
            <Password
              type="password"
              toggleMask
              name="password"
              feedback={false}
              className='w-full'
            />
            <label htmlFor="password">Contraseña</label>
          </span>

          <span className="p-float-label field w-full">
            <Password
              type="password"
              toggleMask
              name="confirmPassword"
              feedback={false}
              className='w-full'
            />
            <label htmlFor="confirmPassword">Confirmar contraseña</label>
          </span>
        </div>
        <div className='mt-2'>
          {
            dataForm.password && 
            errors.filter(error => error.type !== 'required').map( error => (
              <div
                className={error.status ? "p-error":"text-green-500"}
                key={error.type} style={{display: 'grid', gridTemplateColumns: "10px 1fr", gap: "4px"}}
              >
                <small>
                  {
                    error.status ?
                    <i className="pi pi-times" style={{ fontSize: '10px' }}></i>
                    :
                    <i className="pi pi-check" style={{ fontSize: '10px' }}></i>
                  }
                </small>
                <small>
                  {error.message}
                </small>
              </div>
            ))
          }
        </div>
        <div className="text-center mt-2">
          <Button
            label="Activar cuenta"
            loading={isLoading}
            className="btn btn-primary" />
        </div> */}
    </PublicLayout>
  );
};

export default ActivateAccountPage;
