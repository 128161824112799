import { Controller } from "react-hook-form"

import { Button } from "primereact/button"
import { ColorPicker } from 'primereact/colorpicker';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Panel } from 'primereact/panel';
import { Skeleton } from 'primereact/skeleton';

import { useConfigurationMap } from 'features/configuration/hooks/mapProcessConfiguration/useConfigurationMap';
import './ProcessPage.scss'
import { ConfirmDialog } from "primereact/confirmdialog";

export const ProcessPage = () => {

  const  { 
    handleSubmit,
    submit,
    isLoading,
    control,
    processTypes,
    watch,
    addGroup,
    deleteRow,
    errors,
    loadingSave,
    sections,
    optionsSections,
    onChangeSection,
    addSection,
    expandedRows,
    setExpandedRows,
    filterOptions,
    removeSection,
    errorSections,
    setErrorSections,
    showDeleteGroup,
    setShowDeleteGroup,
    showDeleteSection,
    setShowDeleteSection,
    elementSelected,
    showAlert,
    setShowAlert,
    maxSections,
  } = useConfigurationMap()

  const values = watch()

  const rowExpansionTemplate = (id, name) => ({groups, sectionKey}) => {
    return (
      <DataTable
        id="table-groups-process-map"
        value={groups || []}
        emptyMessage="No existen grupos"
        dataKey='id'
        tableStyle={{width: "100%"}}
      >
        <Column field='rowNumber' header='Grupo' style={{width: "50px"}}/>
        <Column field='input' header='Procesos' body={({id: groupId, rowNumber}) => {
          return (
          <Controller
            rules={{required: `No debe existir grupos vacíos, elimine el grupo ${rowNumber} de ${name}`}}
            name={`group_${id}_${sectionKey}_${groupId}`}
            control={control}
            render={( { field: { onChange, value }, fieldState: {error} }) => (
              <span className="p-float-label">
                <MultiSelect
                  emptyMessage="asd"
                  showSelectAll={false}
                  display="chip"
                  optionLabel='name'
                  options={filterOptions(values[`section_${id}_${sectionKey}`], id, sectionKey, groupId)}
                  name={`group_${id}_${sectionKey}_${groupId}`}
                  value={value}
                  className={`w-full${ error ? ' p-invalid' : '' }`}
                  onChange={onChange}
                />
              </span>
            )}
          />
        )}}/>
        <Column field='delete'
          header=''
          style={{width: "32px"}}
          body={({id: groupId}) =>
            <i
              onClick={() => {setShowDeleteGroup(true); elementSelected.current = {id, groupId, sectionKey}}}
              className="pi pi-trash cursor-pointer text-center text-lg"
            >
            </i>
          }
        />
      </DataTable>
    );
  };

  return(
    <form onSubmit={handleSubmit(submit)}>
      <Panel header="CONFIGURACIÓN" id='form-mantenimiento-mapa-procesos'>
        <Panel header="Configuración General">
          {
            isLoading ? <Skeleton height="5rem" /> :
            <>
              <div className='flex gap-4 mb-6 mt-2 w-96'>
                <Controller
                  control={control}
                  name="inputMapProcess"
                  render={( {field: { value, onChange }} ) => (
                    <span className="p-float-label w-full">
                      <InputText
                        name="inputMapProcess"
                        value={value || ""}
                        onChange={onChange}
                        maxLength={50}
                      />
                      <label htmlFor='inputMapProcess'>Entrada</label>
                    </span>
                  )}
                />
              </div>
              <div className='flex gap-4 mb-2 w-96'>
                <Controller
                  control={control}
                  name="outputMapProcess"
                  render={( {field: { value, onChange }} ) => (
                    <span className="p-float-label w-full">
                      <InputText
                        name="outputMapProcess"
                        value={value || ""}
                        onChange={onChange}
                        maxLength={50}
                      />
                      <label htmlFor='outputMapProcess'>Salida</label>
                    </span>
                  )}
                />
              </div>
            </>
          }
        </Panel>
        {
          processTypes.map(({name, id}) => {
            return(
              <Panel header={name} key={id}>
                {
                  isLoading ? <Skeleton height="8rem" /> :
                  <>
                    <div className='flex gap-4'>
                      <Controller
                        control={control}
                        name={`backgroundColor_${id}`}
                        render={( {field: { value, onChange }} ) => (
                          <div className='colorPicker'>
                            <div>
                            Color de fondo
                            </div>
                            <ColorPicker
                              value={value || ""}
                              onChange={onChange}
                            />
                          </div>
                        )}
                      />

                      <Controller
                        control={control}
                        name={`processColor_${id}`}
                        render={( {field: { value, onChange }} ) => (
                          <div className='colorPicker'>
                            <div>
                            Color de proceso
                            </div>
                            <ColorPicker
                              value={value || ""}
                              onChange={onChange}
                            />
                          </div>
                        )}
                      />

                      <Controller
                        control={control}
                        name={`textColor_${id}`}
                        render={( {field: { value, onChange }} ) => (
                          <div className='colorPicker'>
                            <div>
                            Color de texto
                            </div>
                            <ColorPicker
                              value={value || ""}
                              onChange={onChange}
                            />
                          </div>
                        )}
                      />
                    </div>
                    <div className='mt-8'>
                      <Button
                        type='button'
                        label='Añadir Sección'
                        className='sig-button sig-primary mb-6'
                        onClick={addSection(id)}
                      />
                      <DataTable
                        id="table-groups-process-map"
                        value={sections[id] || []}
                        emptyMessage="No existen secciones"
                        dataKey='id'
                        tableStyle={{width: "100%"}}
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        rowExpansionTemplate={rowExpansionTemplate(id,name)}
                      >
                        <Column expander style={{ width: "2em" }}/>
                        <Column field='sectionId' header='Sección' style={{width: "50px"}}/>
                        <Column field='input' header='Procesos' body={({sectionId, sectionKey}) => {
                          return (
                          <Controller
                            rules={{required: `No debe existir secciones vacias, elimine la sección ${sectionId} de ${name}`}}
                            name={`section_${id}_${sectionKey}`}
                            control={control}
                            render={( { field: { onChange, value }, fieldState: {error} }) => (
                              <span className="p-float-label">
                                <MultiSelect
                                  emptyMessage="asd"
                                  showSelectAll={false}
                                  display="chip"
                                  optionLabel='name'
                                  options={optionsSections[`section_${id}_${sectionKey}`]}
                                  dataKey="id"
                                  name={`section_${id}_${sectionKey}`}
                                  value={value}
                                  className={`w-full${ error ? ' p-invalid' : '' }`}
                                  onChange={onChangeSection(value, sectionKey, id, onChange)}
                                />
                              </span>
                            )}
                          />
                        )}}/>
                        <Column field='add'
                          header=''
                          style={{width: "32px"}}
                          body={({id: sectionId}) => 
                            <i
                              onClick={addGroup(id, sectionId)}
                              className="pi pi-plus cursor-pointer text-center text-lg"
                            ></i>
                          }
                        />
                        <Column field='delete'
                          header=''
                          style={{width: "32px"}}
                          body={({sectionKey}) => 
                            <i
                            onClick={() => {setShowDeleteSection(true); elementSelected.current = {id,sectionKey}}}
                            className="pi pi-trash cursor-pointer text-center text-lg"
                            ></i>
                          }
                        />
                      </DataTable>
                    </div>
                    <div className='mt-4'>
                      <div 
                        className='flex items-center'
                        style={{background: `#${watch(`backgroundColor_${id}`)}`, width: "100%", height: "80px", borderRadius: "10px"}}>
                        {
                          (() => {
                            const render = []
                            for(let i = 0; i < (watch(`sections_${id}`) ?? 1); i++){
                              render.push( 
                              <div
                                className='flex items-center'
                                key={i}
                                style={{flex: 1, height: '80%', borderLeft: i > 0 ? 'solid black' : ''}}
                              >
                                <div 
                                  className='ml-4 flex items-center justify-center p-2'
                                  style={{background: `#${watch(`processColor_${id}`)}`, width: "30%", height: "50px", borderRadius: "5px", minWidth: "66px"}}>
                                  <p style={{color: `#${watch(`textColor_${id}`)}`}}>
                                    Proceso
                                  </p>
                                </div>
                              </div>)
                            }
                            return render
                          })()
                        } 
                      </div>
                    </div>
                  </>
                }
              </Panel>
            )
          })
        }
      {
         Object.entries(errors).length > 0 &&
          <ul className='message error'>
            {
              Object.entries(errors).map( ( error ) => {
                return <li className="p-error" key={error[0]}>{error[1].message}</li>
              })
            }
          </ul>
      }
      <div className="flex justify-end gap-4 mt-3">
        <Button
          icon="pi pi-save"
          type="submit"
          label="Guardar"
          loading={loadingSave}
          className="sig-button sig-primary mt-4"
        />
      </div>
      </Panel>
      <Dialog header={'Advertencia'} visible={errorSections.state} className={`w-2/5`} onHide={() => setErrorSections({ state: false, message: [] })}>
        <div>
          <div className="flex gap-4">
            <div className="flex items-center">
              <i className="pi pi-exclamation-triangle text-center text-4xl"></i>
            </div>
            <div className="flex flex-col justify-center">
              {
                errorSections.message.map((message, index) => (
                  <p key={index}>
                    {message}
                  </p>
                ))
              }
            </div>
          </div>
          <div className='flex justify-end items-center gap-x-2'>
            <Button
              type="button"
              label="Aceptar"
              className={`sig-button sig-primary mt-4`}
              onClick={() => setErrorSections({ state: false, message: [] })}
            />
          </div>
        </div>
      </Dialog>

      <Dialog header={'Advertencia'} visible={showAlert} className={`w-2/5`} onHide={() => setShowAlert(false)}>
        <div>
          <div className="flex gap-4">
            <p>
              No se puede crear más de {maxSections} secciones.
            </p>
          </div>
          <div className='flex justify-end items-center gap-x-2'>
            <Button
              type="button"
              label="Aceptar"
              className={`sig-button sig-primary mt-4`}
              onClick={() => setShowAlert(false)}
            />
          </div>
        </div>
      </Dialog>
 
      <ConfirmDialog
        visible={showDeleteGroup}
        onHide={() =>{setShowDeleteGroup(false)}}
        message="¿Está seguro que desea eliminar el grupo?"
        header="Eliminar"
        icon="pi pi-trash" 
        accept={deleteRow}
        reject={() => setShowDeleteGroup(false)}
      />

      <ConfirmDialog
        visible={showDeleteSection}
        onHide={() =>{setShowDeleteSection(false)}}
        message={<div><p>¿Está seguro que desea eliminar la sección?</p><p>Se eliminará los grupos pertenecientes a esta sección</p></div>}
        header="Eliminar"
        icon="pi pi-trash" 
        accept={removeSection}
        reject={() =>{setShowDeleteSection(false)}}
      />
    </form>
  )
}