import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CompaniesTableData } from 'features/customers/data/companies/CompaniesTableData';
import { CoreConstants } from 'core/commons/CoreConstants';
import validateTypeValue from 'shared/helpers/validateTypeValue';
import { CompaniesAction } from 'features/customers/store/actions/CompaniesAction';
import { useClaimCompanies } from '../useClaimCompanies';
// import { useEffect } from 'react';

export const useTableCompanies = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams()
  const companies = useSelector((state) => state.companiesReducer.companies);

  const { isView ,isEdit, isDelete } = useClaimCompanies();
  /* TYPE TABLE VIEW ---------------- */
  const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);

  /* TABLE -------------------------- */
  const [tableCompanies, setTableCompanies] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState([]);
  useEffect(() => {
    const { data, pagination, loading } = companies;
    setTableCompanies(validateTypeValue(data));
    setTablePagination(pagination);
    setTableLoading(loading);
  }, [companies]);

  /* TABLE HEADER COLUMNS */
  const [selectColumns, setSelectColumns] = useState(
    CompaniesTableData.columns
  );

  useEffect(() => {
    if (localStorage.getItem('columnas-empresas')) {
      setSelectColumns(JSON.parse(localStorage.getItem('columnas-empresas')));
    }
  }, []);
  const onColumnToggle = (e, item) => {
    let _selectedColumns = [...selectColumns];

    if (e.checked) _selectedColumns.push(item);
    else _selectedColumns = _selectedColumns.filter((c) => c.field !== e.value);

    if (_selectedColumns.length === 0) return;
    localStorage.setItem('columnas-empresas', JSON.stringify(_selectedColumns));
    setSelectColumns(_selectedColumns);
  };

  /* TABLE HEADER ACTIONS */
  const headerActions = useRef(null);

  /* CURRENT COMPANY ------------------- */
  const companyIdSelect = useSelector(
    (state) => state.companiesReducer.currentAction.companyId
  );

  const companyId = Number( params.customerId || companyIdSelect)
  
  const setCurrentCompany = (companyId) => {
    dispatch(CompaniesAction.currentAction({ companyId }));
  };

  /* TABLE CURRENT ACTION ------------- */
  const [currentAction, setCurrentAction] = useState('');
  const onCurrentAction = ({ item: { name } }) => setCurrentAction(name);
  useEffect(() => {
    const action_ = currentAction.toLocaleLowerCase();
    if (action_ === 'editar') {
      history.push(`/cliente/empresas/editar/${companyId}`);
    } else if (action_ === 'eliminar') {
      dispatch(CompaniesAction.currentAction({ isOpen: true }));
    }
    setCurrentAction('');
  }, [currentAction]);

  /* TABLE ACTIONS -------------------- */
  const companiesOptions = useSelector(
    (state) => state.companiesReducer.actions.items
  );

  const [tableActions, setTableActions] = useState([]);
  const [showActions, setShowActions] = useState(false);
  const tableCurrentActions = useRef(null);

  useEffect(() => {
    if (companiesOptions.length > 0) {
      let newActions = companiesOptions.map((option) => {
        option.label = option.name;
        option.command = onCurrentAction;
        return option;
      });
      if (!isEdit)
        newActions = newActions.filter(c => c.label !== 'Editar');
      if (!isDelete)
        newActions = newActions.filter(c => c.label !== 'Eliminar');
      setTableActions(newActions);
    }
    setShowActions(companiesOptions.length > 0);
  }, [isEdit, isDelete]);

  /* MODAL DELETE (ACTIONS) */
  const isOpenModalDelete = useSelector(
    (state) => state.companiesReducer.currentAction.isOpen
  );
  const confirmDelete = () => {
    dispatch(CompaniesAction.deleteCompany(companyId));
  };
  const cancelDelete = () => {
    dispatch(CompaniesAction.currentAction({ isOpen: false, companyId: '' }));
  };

  /* TABLE PAGINATION --------------- */
  const companiesSearch = useSelector(
    (state) => state.companiesReducer.companies
  );
  const [tableRows, setTableRows] = useState(10);
  const [tableNum, setTableNum] = useState(0);
  const [page, setPage] = useState(1);
  let currentFilterFields = {
    columnOrder: companiesSearch.columnOrder,
    order: companiesSearch.order,
  };

  const filterValues = useSelector(
    (state) => state.companiesReducer.advancedFilter.values
  );
  const buildFilterValues = () => {
    if (filterValues.length > 0) {
      filterValues.forEach(({ field, value, option }) => {
        let currentValue = '';
        const key = field.toLocaleLowerCase();
        if (key.includes('id')) currentValue = option.id;
        else currentValue = value;
        currentFilterFields[field] = currentValue;
      });
    }
  };

  const onChangePage = ({ rows, page }) => {
    buildFilterValues();
    currentFilterFields.page = page + 1;
    currentFilterFields.pageSize = rows;
    setTableRows(rows);
    setTableNum(rows * page);
    setPage(page + 1);
    const fields = currentFilterFields;
    dispatch(CompaniesAction.getCompanies({ change: true, fields }));
  };

  /* TABLE ORDER SORT */
  const [tableSort, setTableSort] = useState({
    sortField: 'name',
    sortOrder: '1',
  });
  const onSort = (event) => {
    buildFilterValues();
    const params = {
      first: tableNum,
      rows: tableRows,
      multiSortMeta: [],
      filters: null,
      ...event,
    };

    currentFilterFields.order = event.sortOrder + '' === '1' ? 'asc' : 'desc';
    currentFilterFields.columnOrder = event.sortField;
    currentFilterFields.pageSize = tableRows;
    currentFilterFields.page = page;
    const fields = currentFilterFields;
    dispatch(CompaniesAction.getCompanies({ change: true, fields }));
    setTableSort(params);
  };

  const onChangeTab = (e) => {
    if(e === 'info') {
      history.push(`/cliente/empresas/editar/${companyId}`);
    } else {
      if (e === 'task') {
        history.push(`/cliente/empresas/editar/${companyId}/tareas`);
      } else {
        if (e === 'business') {
          history.push(`/cliente/empresas/editar/${companyId}/negocios`);
        } else {
          if (e === 'project') {
            history.push(`/cliente/empresas/editar/${companyId}/proyectos`);
          }
        }
      }
    }
  }

  return {
    selectColumns,
    typeView,
    setTypeView,
    headerActions,
    onColumnToggle,
    showActions,
    tableCurrentActions,
    tableActions,
    tableCompanies,
    tableLoading,
    tablePagination,
    setCurrentCompany,
    confirmDelete,
    cancelDelete,
    isOpenModalDelete,
    tableSort,
    tableNum,
    tableRows,
    onSort,
    onChangePage,
    onChangeTab,
  };
};
