import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { FilterComponent } from 'features/customers/components/business/FilterComponent';
import { TableComponent } from 'features/customers/components/business/TableComponent';
import { BusinessAction } from 'features/customers/store/actions/BusinessAction';
import { useTableBusiness } from 'features/customers/hooks/business/useTableBusiness';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { AdvancedFilterComponent } from 'features/customers/components/business/AdvancedFilterComponent';
import scrollTop from 'shared/utils/scrollTop';

export const PageBusinessList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { customerId, confirmDelete, cancelDelete, isOpenModalDelete, setNewPage } = useTableBusiness();

  useEffect(() => {
    dispatch(BusinessAction.getBusiness({ change: true, fields: {customerId: customerId }}));
    dispatch(BusinessAction.getDataMaster());
    dispatch(BusinessAction.setCurrentBusiness({ errors: [] }));

    return ()=>{
      dispatch(BusinessAction.setAdvancedFilter({values: []}));
    }
  }, []);

  useEffect(() => {
    scrollTop();
  }, []);
  return (
    <Fragment>
      <FilterComponent/>
      <Button
        icon="pi pi-plus"
        type="button"
        label="Nuevo"
        className="sig-button sig-dark mt-4"
        onClick={(e) => { setNewPage(); }}
      />
      <TableComponent />
      <AdvancedFilterComponent/>
      <ConfirmDialog
        visible={isOpenModalDelete}
        onHide={cancelDelete}
        message="¿Está seguro que desea eliminar el Negocio?"
        header="Eliminar"
        icon="pi pi-trash"
        accept={confirmDelete}
        reject={cancelDelete}
      />
    </Fragment>
  );
};
