import { Editor } from "primereact/editor"
import { Controller } from 'react-hook-form'
import './TextoDinamicoCustom.scss'

const header = <>
<span className="ql-formats">
	<button className="ql-bold" aria-label="Bold"></button>
	<button className="ql-italic" aria-label="Italic"></button>
	<button className="ql-underline" aria-label="Underline"></button>
</span>
<span className="ql-formats">
	<button
		className="ql-list"
		aria-label="Ordered"
		value="ordered"
	></button>
	<button className="ql-list" aria-label="Bullet" value="bullet"></button>
</span>
</>

export const TextoDinamicoCustom = (props) => {

  function convertirListaHTMLAString(htmlString) {

    var tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;

    var listasUl = tempElement.querySelectorAll('ul');
    var listasOl = tempElement.querySelectorAll('ol');

    listasOl.forEach(function(lista) {
        var items = lista.querySelectorAll('li');
        items.forEach(function(item, index) {
            // Reemplazar el elemento li con un string enumerado
            item.outerHTML = `<pre>\t${index + 1}. </pre>${item.innerHTML}<pre style="display: block;">\n</pre>`;
        });

        // Reemplazar la lista (ol o ul) con los elementos li modificados
        lista.outerHTML = lista.innerHTML;
    });

    listasUl.forEach(function(lista) {
      var items = lista.querySelectorAll('li');
      items.forEach(function(item, index) {
          // Reemplazar el elemento li con un string enumerado
          item.outerHTML = '\t- ' + item.innerHTML+'\n';
      });

      // Reemplazar la lista (ol o ul) con los elementos li modificados
      lista.outerHTML = '<pre>'+lista.innerHTML+'</pre>';
  });

    // Obtener el HTML modificado como string
    var nuevoHtmlString = tempElement.innerHTML;
    return nuevoHtmlString ?? '';
  }

  function convertirCopia(textoOriginal ) {
    let response
    if(!textoOriginal || textoOriginal==='') return textoOriginal
    const regexUnOrder = /<p>(.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.+?)<\/p>/g;
    const regexOrder = /<p>((?:\d{1,2}|[a-zA-Z]{1,5})(?:[.)])&nbsp;+.+?)<\/p>/g;

    response = textoOriginal.replace(regexUnOrder, '<ul><li>$1</li></ul>');
    response = response.replace(/.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/g, '')
    response = response.replace(regexOrder, '<ol><li>$1</li></ol>');
    response = response.replace(/(?:\d{1,2}|[a-zA-Z]{1,5})(?:[.)])&nbsp;+/g, '')
    response = response.replace(/<\/ol><ol>/g, '')
    response = response.replace(/<\/ul><ul>/g, '')
    return response;

  }

	return(
		<Controller
				rules={!props.required ? {} : {
					required: `${props.text} es requerido`,
			}}
        control={props.control}
				name={props.id.toString()}
				render={({field: { onChange, value}})=>{
					if((!value || value === "") && props.forDownload){
						return <div></div>
					}else if(props.forDownload) {
            // console.log(convertirListaHTMLAString(value))
						return (
							<div className="mb-4">
							<span className="w-full">
                {
                  !props.isTable &&
								  <label htmlFor={props.id} style={{fontSize: "0.75rem", color:"#ced4da"}}>{props.text}</label>
                }
                <div className="dinamicForDownload" dangerouslySetInnerHTML={{__html: convertirListaHTMLAString(value)}}>

                </div>
								{/* <Editor
                  style={{fontSize: "0.75rem"}}
									id={props.id}
									readOnly={props.show === "ShowWf"}
									headerTemplate={header}
									onTextChange={(e) => onChange(e.htmlValue)}
									value={value}
									className={`${props.errors[props.id.toString()] ? 'p-invalid': (props.show === "ShowWf"?'disabled': '')}`}
								/> */}
							</span>
							</div>)
					}
				return	(
					<div className={ props.isTable ? '' : `mb-6`}>
					<span className="w-full">
						{ !props.isTable && <label htmlFor={props.id} style={{fontSize: "0.75rem", color:"#ced4da"}}>{props.text}</label>}
						<Editor
							id={props.id}
							readOnly={props.show === "ShowWf"}
							headerTemplate={header}
							onTextChange={(e) => {onChange(convertirCopia(e.htmlValue))}}
							value={value}
							className={`${props.errors[props.id.toString()] ? 'p-invalid': (props.show === "ShowWf"?'disabled': '')}`}
						/>
					</span>
					</div>
				)}}
			/>
	)
}