import { SurveyAction } from 'features/survey/store/actions/SurveyAction'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Chip } from 'primereact/chip';
import { Tooltip } from 'primereact/tooltip'

const temporalData = [
	{
		id: 1,
		name: 'Rodrigo Matteo Castillo',
		email: 'rmatteo@manglar.pe',
		type: 'Contacto',
	},
]

const ShareSurvey = ({control, selectedContact, setSelectedContact, onCopyLink, actionSelected, valuesFields, surveyId, toast}) => {

	const dispatch = useDispatch()
  const {data, isLoading} = useSelector((state) => state.surveyReducer.contacts);
	const debounceRef = useRef()

	useEffect(() => {
		if (actionSelected === 'share') {
			dispatch(SurveyAction.searchContacts());
		}
  }, [actionSelected])

	const onSearch = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			e.stopPropagation();
			dispatch(SurveyAction.searchContacts(e.target.value));
		}
	}

	const onRemoveContact = (id) => {
		setSelectedContact( prev => prev.filter((item) => item.id	!== id))
	}

	const templateContact = (rowData) => {
		return (
			<>
				<Tooltip target={`.tt_contact_${rowData.id}`} />
				<div className={`tt_contact_${rowData.id} flex gap-x-1`} data-pr-tooltip={rowData.emailAddress} >
					<span>{rowData.name || rowData.emailAddress}</span>
					<span 
						className='flex justify-center items-center cursor-pointer'
						onClick={() => onRemoveContact(rowData.id)}
					>
						<i className="pi pi-times-circle"></i>
					</span> 
				</div>
			</>
		)
	}

	const onSearchChange = (e, onChange) => {
		onChange(e.target.value)

		if (debounceRef.current) {
			clearTimeout(debounceRef.current)
		}

		if (e.target.value.length > 3) {
			debounceRef.current = setTimeout(( ) => {
				dispatch(SurveyAction.searchContacts(e.target.value));
			}, 1200)
		}
	}

	const onSendEmail = () => {
		// validar que el email de valuesFields.email no este vacio y que sea un email valido
		if (valuesFields.email.trim() === '') {
			toast.current.show({severity:'error', summary: 'Gestión de Encuestas', detail:'El campo email no debe estar vacio', life: 3000});
			return
		}
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		if (!emailRegex.test(valuesFields.email)) {
			toast.current.show({severity:'error', summary: 'Gestión de Encuestas', detail:'Email no valido', life: 3000});
			return
		}

		const model = {
			surveyId,
			recipients: [{
				email: valuesFields.email,
				fullName: ''
			}]
		}

		dispatch(SurveyAction.shareSendByEmail( model ))
	}

	const onChangeAndSetValidEmailContact = (e, onChange) => {
		onChange(e.target.value)
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		if (emailRegex.test(e.target.value)) {
			const personalContact = selectedContact.find((item) => item.id === 'customEmail')
			const newContact = {
				emailAddress: e.target.value,
				name: '',
				id: 'customEmail'
			}
			if (personalContact) {
				const newSelectedContact = selectedContact.map((item) => {
					if (item.id === 'customEmail') {
						return newContact
					}
					return item
				})
				setSelectedContact(newSelectedContact)
				return
			}
			const newSelectedContact = [...selectedContact, newContact]
			setSelectedContact(newSelectedContact)
		} else {
			const newSelectedContact = selectedContact.filter((item) => item.id !== 'customEmail')
			setSelectedContact(newSelectedContact)
		}
	}

  return (
    <div className='my-3 flex flex-col gap-y-5'>
			<div className='flex flex-col px-4 py-5 border-2'>
				<div className='flex gap-x-2'>
					<span className="p-float-label w-full">
							<Controller
									control={control}
									render={({
											field: { onChange, onBlur, value },
									}) => (
										<InputText
											onChange={onChange}
											onBlur={onBlur}
											value={value}
											className='disabled'
											disabled
										/>
									)}
									name="link"
							/>
							<label htmlFor="link">Compartir enlace:</label>
					</span>
					<span 
						onClick={(e) => onCopyLink(e)}
						className="p-inputgroup-addon p-0 cursor-pointer hover:text-gray-800 hover:bg-gray-200">
							<i className="pi pi-copy text-xl"></i>
					</span>
				</div>

				<div className='flex gap-x-2 mt-5'>
					<span className="p-float-label w-full ">
							<Controller
									control={control}
									render={({
											field: { onChange, onBlur, value },
									}) => (
										<InputText
												// onChange={onChange}
												onChange={(e) => onChangeAndSetValidEmailContact(e, onChange)}
												onBlur={onBlur}
												value={value}
										/>
									)}
									name="email"
							/>
							<label htmlFor="email">Enviar a un buzón de correo:</label>
					</span>
					{/* <span 
						onClick={onSendEmail}
						className="p-inputgroup-addon p-0 cursor-pointer hover:text-gray-800 hover:bg-gray-200">
							<i className="pi pi-envelope text-xl"></i>
					</span> */}
				</div>
			</div>
			<div className='flex flex-col px-4 py-5 border-2'>
				<span className="p-float-label w-full mt-1">
						<Controller
								control={control}
								render={({
										field: { onChange, onBlur, value },
								}) => (
									<InputText
											onChange={e => onSearchChange(e, onChange)}
											onBlur={onBlur}
											value={value}
											onKeyDown={onSearch}
									/>
								)}
								name="name"
						/>
						<label htmlFor="name">Buscar por nombre de empresa, contacto o empleado:</label>
				</span>
				<div className='flex flex-wrap gap-x-2 gap-y-2 mt-2 '>
					{
						selectedContact?.length > 0 && (
							selectedContact.map(contact => (
								<>
									<Chip template={() => templateContact(contact)} />
								</>
							))
						)
					}
				</div>
				<div className='mt-4'>
					<DataTable 
						className='tableSurvey' value={data} dataKey="id"
						loading={isLoading}
						selection={selectedContact} onSelectionChange={e => setSelectedContact(e.value)} 
						responsiveLayout="scroll"  scrollHeight="350px" scrollable 
						emptyMessage="No hay contactos disponibles"
					>
							{/* <Column selectionMode="single" headerStyle={{width: '3em'}}></Column> */}
							<Column selectionMode="multiple" style={{ maxWidth: '50px' }}></Column>
							<Column field="name" header="Nombre" style={{ minWidth: '180px' }}></Column>
							<Column field="emailAddress" header="Correo" style={{ minWidth: '180px' }}></Column>
							{/* <Column field="type" header="Tipo"></Column> */}
					</DataTable>
				</div>
			</div>
		</div>
  )
}

export default ShareSurvey