import { Menu } from "primereact/menu";
import { useRef } from "react";

export const BusinessSheetQuestion = ({
  children,
  draggableProvided,
  index,
  addQuestion,
  removeQuestion,
  idQuestion,
  withDelete,
  addSection,
  section,
}) => {
  const menu = useRef(null);
  const itemsWithDelete = () => {
    const items = [
      {
        label: "Agregar Sección",
        icon: "pi pi-file",
        command: (e) => {
          addSection(index);
        },
      },
      {
        label: "Agregar Pregunta",
        icon: "pi pi-pencil",
        command: (e) => {
          addQuestion(index, section);
        },
      },
      {
        label: "Eliminar Pregunta",
        icon: "pi pi-times",
        command: (e) => {
          removeQuestion(idQuestion);
        },
      },
    ];

    if(!section) return items;
    
    const [_, ...itemsWithSection] = items;

    return itemsWithSection;
  };

  const itemsWithOutDelete = [
    {
      label: "Agregar Sección",
      icon: "pi pi-file",
      command: (e) => {
        addSection(index);
      },
    },
    {
      label: "Agregar Pregunta",
      icon: "pi pi-pencil",
      command: (e) => {
        addQuestion(index, section);
      },
    },
  ];

  const items = withDelete ? itemsWithDelete() : itemsWithOutDelete;

  return (
    <div className="flex flex-col mb-2 bg-white border-2 b-l-2 border-gray-300">
      <div
        {...draggableProvided.dragHandleProps}
        className="flex justify-between p-2 border-b-2 border-gray-300 bg-gray-100"
      >
        <h2 className="font-semibold">{`Pregunta ${index + 1}`}</h2>
        <button type="button">
          <i
            className={`pi pi-ellipsis-v block mr-1 cursor-pointer hover:text-gray-900`}
            onClick={(event) => {
              menu.current.toggle(event);
            }}
          ></i>
        </button>
      </div>
      {children}
      <Menu model={items} popup ref={menu} id="popup_menu" />
    </div>
  );
};
