import Modal from 'features/configuration/components/processes/Formulario/Modal';
import { NewProceseSheet } from 'features/processManagement/components/ProceseSheet/NewProceseSheet';
import useProceseSheet from 'features/processManagement/hooks/useProceseSheet';
import { ProcesseSheetActions } from 'features/processManagement/store/actions/ProcesseSheetActions';
import { v4 as uuidv4 } from 'uuid';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { ButtonMultipleSend } from 'features/processManagement/components/ProceseSheet/ButtonMultipleSend';
import { Aprobacion } from 'features/processManagement/components/Aprobacion';
import { useLocation } from 'react-router-dom';
import { useClaimProcessSheet } from 'features/processManagement/hooks/useClaimProcessSheet';
import { ProcessManagementConstans } from 'features/processManagement/commons/ProcessManagementConstans';
import { ModalSelectFlow } from 'shared/components/ModalSelectFlow/ModalSelectFlow';


const flows = [
  {
    id: 1,
    name: 'Flujo 1'
  },
  {
    id: 2,
    name: 'Flujo 2'
  },
  {
    id: 3,
    name: 'Flujo 3'
  },
]

export const PageNewProceseSheet = ({title, subtitle, description, previousUrl, disabled}) => {
  const { updateTitle } = useSetTitlePage();

  const history = useHistory();
  const dispatch = useDispatch()

  
  
  const {isEdit} = useClaimProcessSheet();
  
  // const institution = useSelector((state) => state.institutionReducer.institution);
  const [visible, setVisible] = useState(false);

  //Estado posición del control al destino de la etapa a la que va
  const [destinationControl, setDestinationControl] = useState(0)

  //Estados Formulario
  const [dinamicStage, setDinamicStage] = useState([])
  const [dinamicInput, setDinamicInput] = useState([])
  const [listStages,setListStage] = useState([])
  const [positionSoruce, setPositionSource] = useState(null)
  const [typeModal, setTypeModal] = useState(null)
  const [displayModal, setDisplayModal] = useState(false);
  const [optionSelected, setOptionSelected] = useState(null)
  const [inputSelected, setInputSelected] = useState(null)
  const [displayFlowModal, setDisplayFlowModal] = useState(false)
  const querys = new URLSearchParams(useLocation().search)
  const modeview = querys.get("modeview") === "readonly"
  const id = querys.get("id")
  
  const { control, errors, valuesFields, existError, setValue, handleSubmit, validateLength} = useProceseSheet()
  
  const processSelected = useSelector(state => state.processeSheetReducer.processSheet) // cambiar por la ficha actual de procesos
  // console.log('processSelected', processSelected)
  console.log(processSelected)
  const listOptions = useSelector(state => state.processeSheetReducer.optionsDropDown?.data)
  const producedBy = useSelector(state => state.processeSheetReducer.producedBy?.data?.producedBy)
  const dataMaster = useSelector(state => state.processeSheetReducer.producedBy)
  const formulary = useSelector(state => state.processeSheetReducer.formularies)
  const listVersions = useSelector(state => state.processeSheetReducer.listVersions)
  const [lastVersion, setLastVersion] = useState({id: 0, status: false})
  
  //state de error diferentes pantallas por falta de datos
  const [stateInsufficient, setStateInsufficient] = useState('')
  
  //estado Configuración
  const [stateConfigPage, setStateConfigPage] = useState({advanceFilters: [],simpleFilters: [], searchColumns: []})
  
	const isDraft = processSelected?.data?.statusName === ProcessManagementConstans.Aprobacion.Estado.Borrador

  // console.log(isDraft)
  const redirectToSearch = () => {
    history.push("/mapa-procesos/mapa-proceso");
    // dispatch(InstitutionAction.resetAfterSaved());
  }

  const scrollBottom = () => {
    const element = document.getElementById('content-main2');
    element.scrollTop = element.scrollHeight;
  };

    useEffect(() => {
    updateTitle({
      title: title,
      subtitle : subtitle,
      description: description,
      previousUrl: previousUrl
    });
    if(!listOptions){
      dispatch(ProcesseSheetActions.getOptionsDropDown())
    }
    if(!producedBy){
      dispatch(ProcesseSheetActions.getProducedBy())
    }
    if(id){
      dispatch(ProcesseSheetActions.getProcessSheetById(Number(id)))
    }else{
      dispatch(ProcesseSheetActions.getLastSheet())
    }
  }, []);

  const reject = () => {
    setVisible(false);
};

// revisa si el formulario se guardó
useEffect(()=> {
  if(formulary?.isSaved){
    setDinamicStage([])
    dispatch(ProcesseSheetActions.restoreSaved())
    redirectToSearch()
  }
},[formulary.isSaved])

//Cargar data al iniciar
useEffect(()=> {

  if (processSelected.data && listOptions){
    let charginData = chargeProcess()
    if(charginData){
      setDinamicStage(charginData)
    }
  }
  if(processSelected.data===null && dinamicStage.length===0){
    const newId = uuidv4()
    setDinamicStage([{
      id: newId,
      mode: "new",
      text: "",
      dinamicInput: []
    }])
    setListStage([...listStages, newId])

    const fecha = new Date()
    setValue('dateElaboration', fecha)
    setValue('lastUpdate', '')
    setValue('updateBy', '')
  }
  if(listVersions?.data && processSelected?.data){
    setLastVersion({id: listVersions?.data[listVersions?.data.length - 1].sheetId ,status:processSelected?.data.processId===listVersions?.data[listVersions?.data.length - 1]?.sheetId})
  }
},[processSelected?.data, listVersions?.data])

  //Cargar data al editar formulario

  const chargeProcess = () => {
    setValue('version', processSelected.data.processId)
    setValue('dateElaboration', processSelected.data.dateElaboration)
    setValue('lastUpdate', processSelected.data.modifyDate)
    setValue('updateBy', processSelected.data.modifyName)

    //Descomentar cuando se solucione el endpoint de Producido por
    if(processSelected.data.producedById || processSelected.data.producedByName){
      setValue('producedBy', processSelected.data?.producedById)
    }else{
      setValue('producedBy', null)
    }

    const newSections = [{
        id: 'Dropable-'+processSelected.data.section?.id,
        mode: "edit",
        position: processSelected.data.section.position,
        text: processSelected.data.section.title,
        dinamicInput: processSelected.data.section.controls.map((element)=> {
          let typeControl = ((listOptions.find((el) => el.id===element.controlId)))
          if(typeControl.code==="ID"){

            let viewID = ""
              if(element.prefix && !element.sufix){
                viewID = element.prefix + "-" + element.startSequence
              }else if (element.sufix && !element.prefix){
                viewID = element.startSequence + "-" + element.sufix
            }

            return {
              id: element.id,
              description: null,
              fieldLabel: element.text,
              formatSecuency: (!element.prefix&&!element.sufix)?"":element.prefix?element.prefix:element.sufix,
              idTypeControl: element.controlId,
              identifier: element.identifier,
              initialSecuency: element.startSequence,
              visibleSearch:typeControl?.visibleSearch,
              visibleAdvancedFilter: typeControl?.visibleAdvancedFilter,
              visibleSimpleFilter: typeControl?.visibleSearch,
              mode: "edit",
              viewID: viewID,
              prefix: element.prefix?true:false,
              required: true,
              stageId: 'Dropable-'+processSelected.data.section.id,
              sufix: element.sufix?true:false,
              typeOptionId: "ID",
              position: element.position,
              }
          }else if(typeControl.code==="TEXTO" || typeControl.code==="TEXTO_LARGO" || typeControl.code==="TEXTO_DINAMICO" || typeControl.code==="NUMERO" || typeControl.code==="FECHA" || typeControl.code==="FECHA_HORA" || typeControl.code==="IMAGEN" || typeControl.code==="ADJUNTAR_ARCHIVO"){
            return {
              id: element.id,
              defaultValue: null,
              fieldLabel: element.text,
              formatSecuency: null,
              visibleSearch:typeControl?.visibleSearch,
              visibleAdvancedFilter: typeControl?.visibleAdvancedFilter,
              visibleSimpleFilter: typeControl?.visibleSearch,
              position: element.position,
              stageId: 'Dropable-'+processSelected.data.section.id,
              idTypeControl: element.controlId,
              prefix: false,
              required: element.required,
              sufix: false,
              typeOptionId: typeControl.code,
              identifier: element.identifier,
              mode: 'edit'
            }
          }else if(typeControl.code==="LISTA_DESPLEGABLE" || typeControl.code==="SELECCION_MULTIPLE" || typeControl.code==="SELECCION_SIMPLE"){
            return {
              id: element.id,
              fieldLabel: element.text,
              idTypeControl: element.controlId,
              identifier: element.identifier,
              visibleSearch:typeControl?.visibleSearch,
              visibleAdvancedFilter: typeControl?.visibleAdvancedFilter,
              visibleSimpleFilter: typeControl?.visibleSearch,
              stageId: 'Dropable-'+processSelected.data.section.id,
              position: element.position,
              options: element.details.map((elem, indice)=> {
                return {
                  id: elem.id,
                  mode: 'edit',
                  name: elem.text,
                  code: (((elem.text).substr(0,2)).toUpperCase()) + "-" + indice,
                  disabled: 'disabled'
                }
              }),
              required: element.required,
              typeOptionId: typeControl.code,
              mode: 'edit'
            }
          }else if(typeControl.code==="TABLA"){
            return{
              id: element.id,
              fieldLabel: element.text,
              idTypeControl: element.controlId,
              mode: "edit",
              numRows: [''],
              visibleSearch:typeControl?.visibleSearch,
              visibleAdvancedFilter: typeControl?.visibleAdvancedFilter,
              visibleSimpleFilter: typeControl?.visibleSearch,
              options: element.details.map((elem, indice)=> {
                return{
                  id: elem.id,
                  mode: 'edit',
                  name: elem.text,
                  Column: listOptions.find(elm => elm.id === elem.controlId)
                }
              }),
              readOnly: element.readonly?element.readonly:false,
              required: true,
              identifier: element.identifier,
              showHeader: element.showHeader?element.showHeader:false,
              stageId: 'Dropable-'+processSelected.data.section.id,
              typeOptionId: typeControl.code,
              visible: true
            }
          }else if(typeControl.code==="MONEDA"){
            return {
              id: element.id,
              fieldLabel: element.text,
              identifier: element.identifier,
              stageId: 'Dropable-'+processSelected.data.section.id,
              visibleSearch:typeControl?.visibleSearch,
              visibleAdvancedFilter: typeControl?.visibleAdvancedFilter,
              visibleSimpleFilter: typeControl?.visibleSearch,
              position: element.position,
              idTypeControl: element.controlId,
              options: [],
              required: element.required,
              typeOptionId: typeControl.code,
              mode: 'edit'
            }
          }
        })
    }]

    //reordenamiento por posición
    // newSections.sort((val1, val2) => val1.position - val2.position)

    const listStag = []

    const newSections2 = newSections.map(elem1 => {
      listStag.push(elem1.id)
      // elem1.dinamicInput.sort((a, b) => a.position - b.position)
      return elem1
    })

    setStateConfigPage({
      advanceFilters: null,
      simpleFilters: null,
      searchColumns: null})

    setListStage(newSections[0].id)

    return newSections2
  }

  const onSubmit = (data, typeSend) => {

    let verifyNull = false

    dinamicStage.map(elem=> {
      if(elem.dinamicInput.length===0){
        verifyNull=true
      }
      return elem
    })

    if(verifyNull===true){
      setStateInsufficient('Debe existir mínimo un control en la plantilla')
      scrollBottom()
    }else{

      if(dinamicStage.length===0){
        setStateInsufficient('Error, No existe Plantilla')
        scrollBottom()
      }else{
        const newData = {
          id: processSelected.data?.processId?processSelected.data.processId:null,
          producedById: data.producedBy?data.producedBy:null,
          dateOfElaboration: data.dateElaboration?data.dateElaboration:null
        }

        const newSections = dinamicStage.map((elem, ind)=>{
          return {
            id: elem.mode==="edit"?Number((elem.id).substr(9)):null,
            controls: elem.dinamicInput?.map((el, index)=> {
              if(el.typeOptionId==='ID'){
                return {
                  id: el.mode==='edit'?Number(el.id):null,
                  text: el.fieldLabel,
                  position: (index+1),
                  prefix: (el.prefix&&el.prefix===true)?el.formatSecuency:null,
                  sufix: (el.sufix&&el.sufix===true)?el.formatSecuency:null,
                  startSequence: Number(el.initialSecuency),
                  identifier: el.identifier,
                  required: true,
                  controlId: el.idTypeControl,
                  readonly: false,
                  showHeader: true,
                  details: []
                }
              }else if(el.typeOptionId==='TEXTO' || el.typeOptionId==='TEXTO_LARGO' || el.typeOptionId==='TEXTO_DINAMICO' || el.typeOptionId==='NUMERO' || el.typeOptionId==='FECHA' || el.typeOptionId==='FECHA_HORA' || el.typeOptionId==='IMAGEN' || el.typeOptionId==='ADJUNTAR_ARCHIVO'){
                  return{
                    id: el.mode==='edit'?Number(el.id):null,
                    text: el.fieldLabel,
                    position: (index+1),
                    prefix: (el.prefix&&el.prefix===true)?el.formatSecuency:null,
                    sufix: (el.sufix&&el.sufix===true)?el.formatSecuency:null,
                    startSequence: el.initialSecuency?Number(el.initialSecuency):null,
                    identifier: el.identifier,
                    required: el.required?el.required:false,
                    controlId: el.idTypeControl,
                    readonly: false,
                    showHeader: true,
                    details: []
                  }
              }else if(el.typeOptionId==='LISTA_DESPLEGABLE' || el.typeOptionId==='SELECCION_MULTIPLE' || el.typeOptionId==='SELECCION_SIMPLE'){
                    return{
                      id: el.mode==='edit'?Number(el.id):null,
                      text: el.fieldLabel,
                      position: (index+1),
                      prefix: (el.prefix&&el.prefix===true)?el.formatSecuency:null,
                      sufix: (el.sufix&&el.sufix===true)?el.formatSecuency:null,
                      startSequence: el.initialSecuency?Number(el.initialSecuency):null,
                      identifier: el.identifier,
                      required: el.required?el.required:false,
                      controlId: el.idTypeControl,
                      readonly: true,
                      showHeader: true,
                      details: el.options.map(elem => {
                        return {
                          id: elem.mode==='edit'?Number(elem.id):null,
                          text: elem.name,
                          controlId: null
                        }
                      })
                    }
                  }
                  else if(el.typeOptionId==='TABLA'){
                    return{
                      id: el.mode==='edit'?Number(el.id):null,
                      text: el.fieldLabel,
                      position: (index+1),
                      prefix: null,
                      sufix: null,
                      startSequence: null,
                      identifier: el.identifier,
                      required: true,
                      controlId: el.idTypeControl,
                      readonly: el.readOnly?(!el.readOnly):false,
                      showHeader: el.showHeader?(el.showHeader):false,
                      details: el.options.map(elem => {
                        return {
                          id: elem.mode==='edit'?Number(elem.id):null,
                          text: elem.name,
                          controlId: elem.Column.id,
                          details: []
                        }
                      })
                    }
                  }
                  else if(el.typeOptionId==='MONEDA'){
                    return{
                      id: el.mode==='edit'?Number(el.id):null,
                      text: el.fieldLabel,
                      position: (index+1),
                      prefix: (el.prefix&&el.prefix===true)?el.formatSecuency:null,
                      sufix: (el.sufix&&el.sufix===true)?el.formatSecuency:null,
                      startSequence: el.initialSecuency?Number(el.initialSecuency):null,
                      identifier: el.identifier,
                      required: el.required?el.required:false,
                      controlId: el.idTypeControl,
                      readonly: false,
                      showHeader: true,
                      details: [{
                        id: elem.mode==='edit'?Number(elem.id):null,
                        text: el.selectedCoin.typeColumn
                      }]
                    }
                  }
                  else if(el.typeOptionId==='ETIQUETA'){
                    return{
                      id: el.mode==='edit'?Number(el.id):null,
                      text: el.fieldLabel,
                      // description: el.description?el.description:'',
                      position: (index+1),
                      prefix: (el.prefix&&el.prefix===true)?el.formatSecuency:null,
                      sufix: (el.sufix&&el.sufix===true)?el.formatSecuency:null,
                      startSequence: el.initialSecuency?Number(el.initialSecuency):null,
                      identifier: el.identifier,
                      required: el.required?el.required:false,
                      controlId: el.idTypeControl,
                      readonly: false,
                      showHeader: true,
                      details: []
                    }
                  }
            })
          }
        })

        newData.section = newSections[0]

        //Cambiar por las acciones de guardado para las ficha de procesos
        if(typeSend===1){ //guardar
          dispatch(ProcesseSheetActions.restoreProcesSheetById())
          dispatch(ProcesseSheetActions.saveProcessesSheet(newData))
        }else if(typeSend===2){ //guardar y aprobar
          newData.workflowId = data.flowId
          dispatch(ProcesseSheetActions.restoreProcesSheetById())
          dispatch(ProcesseSheetActions.saveSheetAndSubmitWorkflow(newData))
        }
      }
    }
    return
  };

  const items = [
    {
        label: 'Guardar',
        icon: "pi pi-save",
        type: "submit",
        command: ()=>{
          handleSubmit((e)=>{onSubmit(e,1)})()
        }
    },
    {
      label: 'Guardar y Aprobar',
      icon: "pi pi-upload",
      command: ()=>{
				setDisplayFlowModal(true)
        // handleSubmit((e)=>{onSubmit(e,2)})()
      }
    }
  ];

  const onSubmitApprove = () => {
    handleSubmit((e)=>{onSubmit(e,2)})()
  }

  return (
    <div>
      <form
        className="form-custom p-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <NewProceseSheet
          modeview={modeview}
          disabled={disabled}
          lastVersion={lastVersion}
          setVisible={setVisible}
          dinamicStage={dinamicStage}
          setDinamicStage={setDinamicStage}
          control={control}
          errors={errors}
          valuesFields={valuesFields}
          existError={existError}
          setValue={setValue}
          validateLength={validateLength}
          listOptions={listOptions}
          listStages={listStages}
          setListStage={setListStage}
          positionSoruce={positionSoruce}
          setPositionSource={setPositionSource}
          typeModal={typeModal}
          setTypeModal={setTypeModal}
          displayModal={displayModal}
          setDisplayModal={setDisplayModal}
          optionSelected={optionSelected}
          setOptionSelected={setOptionSelected}
          inputSelected={inputSelected}
          setInputSelected={setInputSelected}
          stateConfigPage={stateConfigPage}
          setStateConfigPage={setStateConfigPage}
          stateInsufficient={stateInsufficient}
          setDestinationControl={setDestinationControl}
          destinationControl={destinationControl}
          producedBy={producedBy}
          setStateInsufficient={setStateInsufficient}
          title={title}
        >
          <div>
          {
            (errors.dateElaboration || errors.producedBy ) && <div className='message error'>
                <ul>
                    {errors.dateElaboration ? (
                        <>
                            <li className="p-error">{errors.dateElaboration.message}</li>
                        </>
                    ) : null}
                </ul>
                <ul>
                    {errors.producedBy ? (
                        <>
                            <li className="p-error">{errors.producedBy.message}</li>
                        </>
                    ) : null}
                </ul>
            </div>
          }
          </div>
          <div className="col-span-9 lg:col-span-8 flex justify-end gap-4 mt-3">
                {
                  (disabled) 
                  ? (<Aprobacion id={id} source="ficha" modeview={modeview}/>) 
                  : 
                  (
                    <div className={`mr-3`}>
                      <Button
                        icon="pi pi-times"
                        type="button"
                        label="Cancelar"
                        loading={formulary.isSaving}
                        onClick={() => { setVisible(true); }}
                        className="sig-button sig-secondary mt-4 mr-3"
                      />
                      {
                        // <ButtonMultipleSend items={isEdit ? items : [items[0]]} disabled={processSelected.data?.isReadOnly} isLoading={formulary.isSaving}/>
                        true && (
                        // isDraft && (
                          <ButtonMultipleSend 
                            items={items} 
                            // disabled={processSelected.data?.isReadOnly} 
                            isLoading={formulary.isSaving}
                            className={`${!isDraft ? 'block' : 'hidden'}`}
                          />
                        )
                      }
                    </div>
                  )
              }
          </div>
        </NewProceseSheet>
      </form>
      <Modal
        positionSoruce={positionSoruce}
        dinamicStage={dinamicStage}
        setDinamicStage={setDinamicStage}
        typeModal={typeModal}
        displayModal={displayModal} 
        optionSelected={optionSelected} 
        dinamicInput={dinamicInput} 
        inputSelected={inputSelected}
        setDisplayModal={setDisplayModal} 
        setDinamicInput={setDinamicInput} 
        setOptionSelected={setOptionSelected}
        setInputSelected={setInputSelected}
        listOptions={listOptions}
        destinationControl={destinationControl}
      />
      <ModalSelectFlow 
        title={"Seleccione el flujo"}
        description={"Seleccione el flujo al que desea enviar el documento"}
        setDisplayModal={setDisplayFlowModal}
        displayModal={displayFlowModal}
        flows={dataMaster.data?.workflowDocumentTypes}
        onSubmitApprove={onSubmitApprove}
        setValueField={setValue}
        field="flowId"
      />
      <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={redirectToSearch}
          reject={reject}
      />
    </div>
  )
}
