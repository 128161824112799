import { MaintenanceConstants } from "features/maintenance/commons/MaintenanceConstans";
import { CoreConstants } from "core/commons/CoreConstants";
import moment from "moment";
import { toastAction } from "core/store/actions/ToastAction";
import FileSaver from "file-saver";
import { ProjectRoleService } from "features/maintenance/services/ProjectRoleServices";

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: MaintenanceConstants.Accion.ProjectRole.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};
const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({
      type: MaintenanceConstants.Accion.ProjectRole.RESET_AFTER_SAVED,
    });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload;

    if (advancedFilter.filter.name)
      pines.push({ field: "name", value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: "from",
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: "to",
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });

    advancedFilter.values = pines;
    dispatch({
      type: MaintenanceConstants.Accion.ProjectRole.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setProjectRoles = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.ProjectRole.SEARCH_PROJECT_ROLES,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const projectRoles = getState().projectRoleReducer.projectRole;
    setProjectRoles({
      dispatch: dispatch,
      isLoading: true,
      data: projectRoles.data,
    });

    const criteria = {};
    const advancedFilter = getState().projectRoleReducer.advancedFilter;
    const filter = getState().projectRoleReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    ProjectRoleService.searchProjectRole(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          setProjectRoles({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Rol de proyecto", data.message));
          setProjectRoles({
            dispatch: dispatch,
            isLoading: false,
            data: null,
          });
        }else{
          setProjectRoles({
            dispatch: dispatch,
            isLoading: false,
            data: null,
          });
          dispatch(toastAction.error("Rol de proyecto", data.message));
        }
      },
      () => {
        setProjectRoles({ dispatch: dispatch, isLoading: false, data: null });
      }
    ).catch((error)=>{
    });
  };
};

const getProjectRoleById = (projectRoleId) => {
  const setProjectRole = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.ProjectRole.GET_PROJECT_ROLE_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setProjectRole({ dispatch: dispatch, isLoading: true });
    ProjectRoleService.getProjectRoleById(projectRoleId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setProjectRole({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setProjectRole({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn("Rol de proyecto", data.message));
          // dispatch({
          //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
          //   toast: {
          //     titulo: 'Departamento',
          //     mensaje: data.message,
          //     severidad: 'warn',
          //   },
          // });
        }else{
          setProjectRole({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.error("Rol de proyecto", data.message));
        }
      }
      //() => {
      //setBusinessLine({ dispatch: dispatch, isLoading: false });
      //}
    ).catch((error)=>{
    });
  };
};

const saveProjectRole = (projectRole) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentProjectRole }) => {
    dispatch({
      type: MaintenanceConstants.Accion.ProjectRole.SAVE_PROJECT_ROLE,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    const currentProjectRole = getState().projectRoleReducer.projectRole;
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
      //currentBusinessLine: currentBusinessLine,
    });
    ProjectRoleService.saveProjectRole(projectRole).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(
            toastAction.success(
              "Rol de proyecto",
              "El rol se guardó correctamente"
            )
          );
          isLoading({
            dispatch: dispatch,
            isSaving: false,
            isSaved: true,
            currentProjectRole,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          isLoading({
            dispatch: dispatch,
            isSaving: false,
            isSaved: false,
            currentProjectRole,
          });
          dispatch(toastAction.warn("Rol de proyecto", data.message));
        }else{
          dispatch(toastAction.error("Rol de proyecto", data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }
      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};

const deleteProjectRole = (projectRoleId) => {
  return (dispatch, getState) => {
    ProjectRoleService.deleteProjectRole(projectRoleId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(
            toastAction.success(
              "Rol de proyecto",
              "El rol se eliminó correctamente"
            )
          );
          dispatch({
            type: MaintenanceConstants.Accion.ProjectRole.DELETE_PROJECT_ROLE,
            payload: { isDeleted: true },
          });
        } else {
          dispatch(toastAction.warn("Rol de proyecto", data.message));
          dispatch({
            type: MaintenanceConstants.Accion.ProjectRole.DELETE_PROJECT_ROLE,
            payload: { isDeleted: false },
          });
        }
      },
      () => {
        dispatch({
          type: MaintenanceConstants.Accion.ProjectRole.DELETE_PROJECT_ROLE,
          payload: { isDeleted: false },
        });
      }
    );
  };
};

const generateReport = (payload) => {
  const setProjectRoles = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.ProjectRole.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setProjectRoles({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().projectRoleReducer.advancedFilter.filter;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    ProjectRoleService.generateReport(criteria)
      .then(({ data }) => {
        setProjectRoles({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });

        FileSaver.saveAs(blob, "Rol_proyecto.xls");
      })
      .catch((err) => {
        setProjectRoles({ dispatch: dispatch, isLoading: false });
        dispatch(
          toastAction.error(
            "Rol de proyecto",
            "Hubo un error al descargar el archivo"
          )
        );
      });
  };
};

export const ProjectRoleAction = {
  toggleAdvancedFilter,
  updateFilter,
  search,
  saveProjectRole,
  deleteProjectRole,
  generateReport,
  resetAfterSaved,
  getProjectRoleById,
};
