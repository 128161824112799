import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import FilterComponent from "features/coursesManagment/components/attendance/FilterComponent";
import TableComponent from "features/coursesManagment/components/attendance/TableComponent";
import AdvancedFilterComponent from "features/coursesManagment/components/attendance/AdvanceFilterComponent";
import { useClaimAttendance } from 'features/coursesManagment/hooks/useClaimAttendance';

const SearchAttendancePage = () => {
    const history = useHistory();
    const { updateTitle } = useSetTitlePage();
    const [isFirstLoading, setFirstLoading] = useState(true);
    const dispatch = useDispatch();

    const { isSearch, isDownload} = useClaimAttendance();
     useEffect(() => {
        updateTitle({
            title: 'Cursos',
            subtitle: 'Participantes',
        });
    }, []);

   return (
    <Fragment>
        {
            (isSearch||isDownload)&&(
                <FilterComponent />
            )
        }
      <TableComponent />
      <AdvancedFilterComponent />
</Fragment>
   );
   
};

export default SearchAttendancePage;
