import File from "./File";

const ListFiles = ({ files, confirmDelete, isLoading }) => {
  return (
    <div className="mt-5 grid grid-cols-1">
      {files.map((file, index) => {
        return (
          <File
            file={file}
            confirmDelete={confirmDelete}
            isLoading={isLoading}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default ListFiles;
