/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { usePagination } from "shared/hooks/usePagination"

export const useFilter = (initialColumnOrder, getFilterValuesFromPiners, getPinersValues, searchAction, downloadAction) => {
  const { values, onChangeValues, search, setRowCount, currentPage, pageSize, columnOrder, order, onSubmit, showModal, changeShowModal, piners, removePiner, cancelSearch, download, cancelDownload, onDownload, resetValues, resetState } = usePagination(getPinersValues)
  const dispatch = useDispatch()

  useEffect(() => {
    if(search){
      const filterValues = getFilterValuesFromPiners(piners)
      const searchFields = { 
        page: currentPage,
        pageSize,
        columnOrder: columnOrder ?? initialColumnOrder,
        order,
        ...filterValues,
      }
      dispatch(searchAction(searchFields, setRowCount))
      cancelSearch()
    }
  }, [search])

  useEffect(() => {
    if(download){
      const filterValues = getFilterValuesFromPiners(piners)
      const searchFields = { 
        ...filterValues,
      }
      dispatch(downloadAction(searchFields))
      cancelDownload()
    }
  }, [download])

  return {
    piners,
    removePiner,
    showModal,
    changeShowModal,
    onSubmit,
    values,
    onChangeValues,
    onDownload,
    resetValues,
    resetState,
  }
}