import { Fragment, useEffect, useRef, useState } from "react";

export const TableDownload = ({props, prueba}) => {
  const id = props.id
  const tablaOriginalRef = useRef(null);
  const [anchosPrimerFila, setAnchosPrimerFila] = useState([]);
  const [mostrarTablaOriginal, setMostrarTablaOriginal] = useState(true);
  const [ isRender, setIsRender ] = useState(false)
  const cabecera = props.details
  const columnas = cabecera.map(item => item.id);

  useEffect(()=>{
    if(!isRender) setIsRender(true)
  },[])

  useEffect(() => {
    if (tablaOriginalRef.current && isRender) {
      setTimeout(() => {
        const primerFilaDivs = tablaOriginalRef.current.querySelectorAll('.header');
        const anchos = Array.from(primerFilaDivs).map(div => {
          return div.getBoundingClientRect().width
        });
        setAnchosPrimerFila(anchos);
        setMostrarTablaOriginal(false); // Ocultar la tabla original después de obtener las dimensiones
      }, 1000)
    }
  }, [isRender]);

  return (
    <>
      {
        mostrarTablaOriginal && 
        <div className="mb-4" style={{width: 1026}}>
          <div className="font-bold mb-2">{props.text}</div>
          <div style={{paddingTop: '1px'}} className="page-break">
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: `repeat(${columnas.length - 1}, auto) 1fr`,
                width: '100%', // O el ancho máximo que desees
                margin: '0 auto',
                border: '1px solid black'
              }}
              ref={tablaOriginalRef}
            >
            {
              cabecera.map((item, colIndex) => (
                <div key={item.id} className="header" style={{ textAlign: 'center', padding: '0 8px 16px 8px', borderRight: colIndex === cabecera.length - 1? '' : '1px solid black', fontSize: 14}} role="columnheader">
                  {item.text}
                </div>
              ))
            }
            {prueba.map((fila, index) => (
              <Fragment key={fila.id}>
                {columnas.map((columna, colIndex) => (
                  <div key={columna} style={{ padding: '0 8px 0 8px', borderBottom: index === prueba.length - 1? '' : '1px solid black', borderRight: colIndex === cabecera.length - 1? '' : '1px solid black'}}>
                    {fila[columna]}
                  </div>
                ))}
              </Fragment>
            ))}
            </div>
          </div>
        </div>
      }

        <div className="mb-4" style={{width: 1026}}>
            <div>
              <div style={{paddingTop: '1px'}} className="page-break">
              <div className="font-bold mb-2">{props.text}</div>
                <div style={{
                  width: '100%', // O el ancho máximo que desees
                  margin: '0 auto',
                  border: '1px solid black'
                }}>
                  <div  className="flex">
                    {
                      cabecera.map((item, colIndex) => (
                        <div key={item.id} style={{ textAlign: 'center', padding: '0 8px 16px 8px', borderRight: colIndex === cabecera.length - 1? '' : '1px solid black', fontSize: 14, width: anchosPrimerFila[colIndex]}} role="columnheader">
                          {item.text}
                        </div>
                      ))
                    }
                  </div>
                  <div className="flex">
                    {columnas.map((columna, colIndex) => (
                      <div key={columna} style={{ padding: '0 8px 0 8px', borderRight: colIndex === cabecera.length - 1? '' : '1px solid black', width: anchosPrimerFila[colIndex]}}>
                        {prueba[0]?.[columna]}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            {prueba.filter( (item, index ) => index !== 0).map((fila) => (
              <div style={{ width: '100%', borderLeft: '1px solid black', borderRight: '1px solid black', paddingTop: '2px'}} key={fila.id} className="page-break">
                <div className="flex" style={{width: '100%'}}>
                  {columnas.map((columna, colIndex) => (
                    <div key={columna} style={{ padding: '0 8px 0 8px', borderTop: '1px solid black', borderBottom: '1px solid black', borderRight: colIndex === cabecera.length - 1? '' : '1px solid black', width: anchosPrimerFila[colIndex]}}>
                      {fila[columna]}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            
          </div>
        </div>
    </>
  )
}