import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Panel } from "primereact/panel";

import { GeneralData } from "features/projects/components/viewDeliverables/GeneralData"
import { ProjectsActions } from "features/projects/store/actions/ProjectsActions";
import { TableComponent } from "features/projects/components/viewDeliverables/TableComponent";

import useSetTitlePage from "shared/hooks/useSetTitlePage";

export const ViewSnapshoot = () => {
  const { updateTitle } = useSetTitlePage();
  const dispatch = useDispatch()
  const params = useParams();
  const history = useHistory();
  const { data , loading, downloadLoading } = useSelector(
    (state) => state.projectsReducer.snapshot
  );
  // console.log(data)
  /* TITLE ------------------------- */
  const pageTitle = {
    title: "Gestión de Proyectos",
    subtitle: "Ver Proyectos",
    description: "Ver Planificación",
    previousUrl: "/proyecto/vista-proyectos/",
  };
  useEffect(() => {
    updateTitle(pageTitle);
    dispatch(ProjectsActions.getSnapshotById(params.id))
  }, []);

  return(
    <div className="bg-white">
      <div className="div-custom p-0">
        <GeneralData
          data={data}
          download={() => dispatch(ProjectsActions.downloadSnapshot(Number(params.id)))}
          isDownloadEnable
          loadingEdit={loading}
          loadingReport={downloadLoading}
          onClickCancel={() => history.push("/proyecto/vista-proyectos")}
          textCancelar={'Regresar'}
          iconCancelar={'pi-arrow-left'}
          isSnapshot
        />
        <Panel header="ENTREGABLES" toggleable={true}>
          <div className="w-full">
            <TableComponent dataProject={data} isSnapshot/>
          </div>
        </Panel>
      </div>
    </div>
  )
}