import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from 'core/store/actions/ToastAction';
import { CoursesManagmentConstants } from "features/coursesManagment/commons/CoursesManagmentConstants";
import { CoursesMonitoringService } from 'features/coursesManagment/services/CoursesMonitoringServices';
import FileSaver from 'file-saver';
import moment from 'moment';

const saveCourse = (course) => {
  const isLoading = ({ dispatch, isSaving, isSaved }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.CoursesMonitoring.SAVE_COURSE_MONITORING,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
    });
    CoursesMonitoringService.saveCourseMonitoring(course).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Curso', 'El Curso se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Seguimiento de Curso', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }else{
          dispatch(toastAction.error('Seguimiento de Cursos', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }
      },
    ).catch((error)=>{
    });
  };
};



const getDataMaster = () => {
  const setDataMaster = ({
    dispatch,
    isLoading,
    category,
    mode,
    service,
    status,
    // type,
    // scale
  }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.CoursesMonitoring.GET_DATA_MASTER,
      payload: { isLoading, category, mode, service, status },
    });
  };

  return (dispatch, getState) => {
    setDataMaster({
      dispatch: dispatch,
      isLoading: true,
      category: null,
      mode: null,
      service: null,
      type: null,
      scale: null
    });

    CoursesMonitoringService.getDataMaster().then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setDataMaster({
            dispatch,
            isLoading: false,
            category: data.data.categories,
            mode: data.data.modes,
            service: data.data.serviceTypes,
            status: data.data.status,
            // type: data.data.courseTypes,
            // scale: data.data.scales,
          });
        }
        else if (data.status < CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            category: null,
            mode: null,
            service: null,
            type: null,
            scale: null
          });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            category: null,
            mode: null,
            service: null,
            type: null,
            scale: null
          });
          dispatch(toastAction.warn('Curso', data.message));
        }
      },
      // () => {
      //   setDataMaster({
      //     dispatch: dispatch,
      //     isLoading: false,
      //     category: null,
      //     mode: null,
      //     service: null,
      //     type: null,
      //     scale: null
      //   });
      // }
    );
  };
};


//* En Desarrollo
const generateReport = (payload) => {

  const setCourses = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.CoursesMonitoring.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setCourses({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    //const advancedFilter = getState().courseReducer.advancedFilter;
    const filter = getState().courseReducer.advancedFilter.filter;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    criteria.parentCourseId = filter?.courseParent?.id;
    criteria.managerId = filter.manager?.id;

    CoursesMonitoringService.generateReport(criteria)
      .then(({ data }) => {
        setCourses({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Courseos.xls');
      })
      
      .catch((err) => {
        setCourses({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Courseo', 'Hubo un error al descargar el archivo'));
      });
  };
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 

    if (advancedFilter.filter.name)
      pines.push({ field: 'name', value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.category)
      pines.push({
        field: 'category',
        value: advancedFilter.filter.category.description,
      });

    if (advancedFilter.filter.mode)
      pines.push({
        field: 'mode',
        value: advancedFilter.filter.mode.description,
      });

    
    if (advancedFilter.filter.service)
      pines.push({
        field: 'service',
        value: advancedFilter.filter.service.description,
      });  

    advancedFilter.values = pines;

    dispatch({
      type: CoursesManagmentConstants.Accion.CoursesMonitoring.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setCourses = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.CoursesMonitoring.SEARCH_COURSES_MONITORING,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const courses = getState().courseMonitoringReducer.courses;
    setCourses({
      dispatch: dispatch,
      isLoading: true,
      data: courses.data,
    });

    const criteria = {};
    const advancedFilter = getState().courseMonitoringReducer.advancedFilter;
    const filter = getState().courseMonitoringReducer.advancedFilter.filter;
    
    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    criteria.categoryId = filter?.category?.id;
    criteria.modeId = filter?.mode?.id;
    criteria.serviceTypeId = filter?.service?.id;
    
  
    CoursesMonitoringService.searchCoursesMonitoring(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          setCourses({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          setCourses({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Seguimiento de Cursos', data.message));
        }else{
          setCourses({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Seguimiento de Cursos', data.message));
        }
      },
      () => {
        setCourses({ dispatch: dispatch, isLoading: false, data: null });
      }
    ).catch((error)=>{
    });
  };
};

const getCourseById = (courseId) => {
  const setCourse = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.CoursesMonitoring.GET_COURSE_MONITORING_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setCourse({ dispatch: dispatch, isLoading: true });
    CoursesMonitoringService.getCourseMonitoringById(courseId).then(
      ({ data }) => {
        if (data.Status === CoreConstants.HttpResponse.OK) {  
          setCourse({
            dispatch: dispatch,
            isLoading: false,
            data: data.Data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setCourse({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Seguimiento de Cursos', data.message));
        }else{
          setCourse({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.error('Seguimiento de Cursos', data.message));
        }
      },
      () => {
        setCourse({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};

// const getIsApproved = (evaluations) => {
  
//   try {
//      CoursesMonitoringService.getIsApproved(evaluations).then(
//       ({ data }) => {
        
//           return data
//       },
//     );
//   } catch (error) {
//     console.log(error)
//   }
// };

const getIsApproved = (evaluations) => {
  const setCourse = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.CoursesMonitoring.GET_IS_APPROVED,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setCourse({ dispatch: dispatch, isLoading: true });
    CoursesMonitoringService.getIsApproved(evaluations).then(
      ({ data }) => {
        // console.log(data)
        if (data.Status === CoreConstants.HttpResponse.OK) {  
          setCourse({
            dispatch: dispatch,
            isLoading: false,
            data: data.Data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setCourse({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Seguimiento de Cursos', data.message));
        }
      },
      () => {
        setCourse({ dispatch: dispatch, isLoading: false });
      }
    );
  };
};

//* En Uso
const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: CoursesManagmentConstants.Accion.CoursesMonitoring.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: CoursesManagmentConstants.Accion.CoursesMonitoring.RESET_AFTER_SAVED });
};

export const CoursesMonitoringAction = {
  toggleAdvancedFilter,
  search,
  updateFilter,
  getCourseById,
  saveCourse,
  getDataMaster,
  resetAfterSaved,
  generateReport,
  getIsApproved
};
