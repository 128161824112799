import { useRef } from "react";
import { useClaimProcessManagment } from "../hooks/useClaimProcessManagment";

export const DraggableCustom = (props) => {

	const { isEdit } = useClaimProcessManagment();

	const element = useRef()
	
  const onDragStart = (e, source_dropid, source_order, dragid) => {
		if(isEdit){
			e.dataTransfer.setData("source_dropid", source_dropid)
			e.dataTransfer.setData("source_order", source_order)
			e.dataTransfer.setData("source_dragid", dragid)
			element.current.classList.add("opacity-10")
		}
  }

	const onDragEnd = (e) => {
		if(isEdit){
			element.current.classList.remove("opacity-10")
		}
	}
	
	return (
	<div ref={element} order={props.order} dropid={props.dropid} dragid={props.dragid} onDragStart={(e)=>onDragStart(e, props.dropid, props.order, props.dragid)} draggable={props.isLastVersion} onDragEnd={onDragEnd} className="m-4" style={{backgroundColor: props.bgcolor}}> {props.children} </div>
)}