/* eslint-disable import/no-anonymous-default-export */

import { DocumentConstants } from "features/document/commons/DocumentConstants";


const initialState = {
  dataMaster: {
    isLoading: false,
    listFolders: [],
    dropdowns: []
  },
  folder: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false,
    isUpdated: false,
  },
  listPermissions: {
    isLoading: false,
    data: null
  },
  permission: {
    data: null,
    isSaving: false,
    isSaved: false,
    isLoading: false,
    isUpdated: false,
  },
  listFolders: {
    isLoading: false,
    data: null
  },
  listFoldersTree: {
    isLoading: false,
    data: []
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'name',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {}
  },
  isStorageEnabled: {
    isLoading: false,
    isEnabled: null
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DocumentConstants.Accion.DocumentManagement.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
    case DocumentConstants.Accion.DocumentManagement.UPDATE_FILTER:
      return {
          ...state,
          advancedFilter: state.advancedFilter
      };

    // case DocumentConstants.Accion.DocumentManagement.SEARCH_DOCUMENT_LIBRARIES:
    //     return {
    //         ...state,
    //         documents: {
    //             isLoading: action.payload.isLoading,
    //             data: action.payload.data
    //         }
    //     };
    case DocumentConstants.Accion.DocumentManagement.GET_DOCUMENT_MANAGEMENT_BY_ID:
      return {
          ...state,
          folder: {
              data: action.payload.data,
              isSaving: false,
              isLoading: action.payload.isLoading
          }
        };

    case DocumentConstants.Accion.DocumentManagement.RENAME_DOCUMENT_MANAGEMENT:
        return {
            ...state,
            folder: {
                isUpdated: action.payload.isUpdated
            }
        };
    case DocumentConstants.Accion.DocumentManagement.CREATE_FOLDER:
        return {
            ...state,
            folder: {
                data: action.payload.currentDocument,
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };

    case DocumentConstants.Accion.DocumentManagement.DELETE_DOCUMENT_MANAGEMENT:
        return {
            ...state,
            folder: {
                isDeleted: action.payload.isDeleted
            }
        };

    case DocumentConstants.Accion.DocumentManagement.RESET_AFTER_SAVED:
        return {
            ...state,
            folder: {
                data: null,
                isSaving: false,
                isSaved: false,
                isLoading: false,
                isDeleted: false,
                isUpdated: false,
            },
            permission: {
              data: null,
              isSaving: false,
              isSaved: false,
              isLoading: false,
              isUpdated: false,
            },
        };

    case DocumentConstants.Accion.DocumentManagement.GET_DATA_MASTER:
        return {
            ...state,
            dataMaster: {
                dropdowns: action.payload.data,
                isLoading: action.payload.isLoading
            }
        };
    case DocumentConstants.Accion.DocumentManagement.GET_DATA_LIST_FOLDERS:
        return {
            ...state,
            listFoldersTree: {
              data: action.payload.data,
              isLoading: action.payload.isLoading
            }
        };
    case DocumentConstants.Accion.DocumentManagement.GET_LIST_FOLDERS_TABLE:
        return {
            ...state,
            listFolders: {
                data: action.payload.data,
                isLoading: action.payload.isLoading
            }
        };
    case DocumentConstants.Accion.DocumentManagement.DOWNLOAD_REPORT_STATUS:
        return {
            ...state,
            folder: {
                ...state,
                isLoading: action.payload.isLoading,
            },
        };
    case DocumentConstants.Accion.DocumentManagement.GET_PERMISSION_BY_PATH:
      return {
          ...state,
          listPermissions: {
            data: action.payload.data,
            isLoading: action.payload.isLoading
        }
      };

    case DocumentConstants.Accion.DocumentManagement.SAVE_FOLDER_PERMISSIONS:
      return {
          ...state,
          permission: {
              isSaving: action.payload.isSaving,
              isSaved: action.payload.isSaved
          }
      };

    case DocumentConstants.Accion.DocumentManagement.IS_STORAGE_ENABLED:
      return {
          ...state,
          isStorageEnabled: {
              isLoading: action.payload.isLoading,
              isEnabled: action.payload.isEnabled,
          },
      };
      
    default: 
    return state;
  }

}