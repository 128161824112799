const columns = [
  // { field: "fullName", header: "Alumno" },
  { field: "fullName", header: "Alumno" },
  { field: "documentType", header: "Tipo" },
  { field: "documentNumber", header: "Nro Documento" },
  { field: "businessLine", header: "Línea de negocio" },
  { field: "country", header: "País" },
  { field: "emailAddress", header: "Correo" },
  { field: "phoneNumber", header: "Teléfono" },
];

export const StudentsTableData = {
  columns,
};
