import React from 'react';

const HeaderEditor = () => {
  return (
    <>
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
      </span>
      <span className="ql-formats">
        <button
          className="ql-list"
          aria-label="Ordered"
          value="ordered"
        ></button>
        <button className="ql-list" aria-label="Bullet" value="bullet"></button>
      </span>
    </>
  );
};

export default HeaderEditor;
