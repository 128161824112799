export class ProjectsConstants {
  static Accion = class {
    static Projects = class {
      static get SEARCH_PROJECTS() {
        return '@PROJECTS.SEARCH_PROJECTS';
      }
      // static get REMOVE_FILTER_VALUES() {
      //   return '@PROJECTS.REMOVE_FILTER_VALUES';
      // }
      static get SET_FILTER_VALUES() {
        return '@PROJECTS.SET_FILTER_VALUES';
      }
      // static get ADVANCED_FILTER_TOGGLE() {
      //   return '@PROJECTS.ADVANCED-FILTER-TOGGLE';
      // }
     
      static get DATA_MASTER() {
        return '@PROJECTS.DATA_MASTER'
      }
      static get EDIT_TASKS() {
        return '@PROJECTS.EDIT_TASKS';
      }
      static get GET_TASKS() {
        return '@PROJECTS.GET_TASKS';
      }
      static get CREATE_SNAPSHOT() {
        return '@PROJECTS.CREATE_SNAPSHOT';
      }
    };
    static PaymentProjects = class {
      static get SEARCH() {
        return '@PAYMENT_PROJECT.SEARCH';
      }

      static get SAVE_PAYMENT_PROJECT() {
        return '@PAYMENT_PROJECT.SAVE_PAYMENT_PROJECT';
      }

      static get ADVANCED_FILTER_TOGGLE() {
        return '@PAYMENT_PROJECT.ADVANCED_FILTER_TOGGLE';
      }

      static get UPDATE_FILTER() {
        return '@PAYMENT_PROJECT.UPDATE_FILTER';
      }

      static get GET_COURSES_AND_PAYMENT() {
        return '@PAYMENT_PROJECT.GET_COURSES_AND_PAYMENT';
      }

      static get GET_LIST_PROJECTS() {
        return '@PAYMENT_PROJECT.GET_LIST_PROJECTS';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@PAYMENT_PROJECT.DOWNLOAD_REPORT_STATUS';
      }

      static get RESET_AFTER_SAVED() {
        return '@PAYMENT_PROJECT.RESET_AFTER_SAVED';
      }

      static get GET_COURSE_PAYMENT_BY_ID() {
        return '@PAYMENT_PROJECT.GET_COURSE_PAYMENT_BY_ID';
      }

      static get DELETE_PAYMENT() {
        return '@PAYMENT_PROJECT.DELETE_PAYMENT';
      }
    };

  };
  static SectionsPaymentProjectsName = class {
    static get DATOS_DEL_PROYECTO() {
      return 'DATOS DEL PROYECTO';
    }
    static get DATOS_DEL_PAGO() {
      return 'DATOS DEL PAGO';
    }
  }
}