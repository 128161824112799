import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { PageViewProjects } from './pages/viewProjects/PageViewProjects';
import projectReducer from 'features/customers/store/reducers/ProjectReducer';
import projectsReducer from './store/reducers/ProjectsReducer';
import paymentProjectReducer from './store/reducers/PaymentProjectReducer';
import tasksReducer from "../customers/store/reducers/TasksReducer";
import companiesReducer from "../customers/store/reducers/CompaniesReducer";

import { PageViewDeliverables } from './pages/viewProjects/PageViewDeliverables';

import SearchProjectExpensesPage from './pages/ProjectExpenses/Search/SearchProjectExpensesPage';
import ProjectExpensesPage from './pages/ProjectExpenses/ProjectExpenses/ProjectExpensesPage';
import projectExpensesReducer from './store/reducers/ProjectExpensesReducer';


import SearchPaymentCourse from 'features/projects/pages/PaymentProjects/Search/SearchPaymentCourse';
import {PaymentProjectPage} from 'features/projects/pages/PaymentProjects/PaymentProjectPage/PaymentProjectPage';
import { ViewSnapshoot } from './pages/SnapShot/ViewSnapShot';
import { PageViewSnapshots } from './pages/SnapShot/PageViewSnapshots';

export const ProjectsRouting = (reducerRegistry) => {
  const { path } = useRouteMatch();
  reducerRegistry.register({ tasksReducer });
  reducerRegistry.register({ companiesReducer });

  reducerRegistry.register({ projectReducer });
  reducerRegistry.register({ projectsReducer });

  reducerRegistry.register({ projectExpensesReducer });

  reducerRegistry.register({ paymentProjectReducer })

  return (
    <Switch>
      <Route path={`${path}/vista-proyectos`}>
        <Route exact path={`${path}/vista-proyectos`} component={PageViewProjects} />
        <Route exact path={`${path}/vista-proyectos/proyecto/:id`} component={()=> <PageViewDeliverables title='Editar Proyecto'/>} />
        <Route exact path={`${path}/vista-proyectos/planificacion/:id`} component={()=> <ViewSnapshoot />} />
        <Route exact path={`${path}/vista-proyectos/planificaciones/:projectId`} component={()=> <PageViewSnapshots />} />
        <Route exact path={`${path}/vista-proyectos/proyecto/visualizar/:id`} component={()=> <PageViewDeliverables title='Ver Proyecto' mode='view' />} />
      </Route>

      <Route path={`${path}/gastos`}>
        <Switch>
          <Route
            exact
            path={`${path}/gastos`}
            component={SearchProjectExpensesPage}
          />
          <Route
            path={`${path}/gastos/nuevo`}
            exact
            component={() => <ProjectExpensesPage title="Nuevo Gasto" />}
          />
          <Route
            path={`${path}/gastos/:id`}
            exact
            component={() => <ProjectExpensesPage title="Editar Gasto" />}
          />
          <Route
            path={`${path}/gastos/visualizar/:id`}
            exact
            component={() => <ProjectExpensesPage title="Ver Gasto" mode='view' />}
          />
        </Switch>
      </Route>
      <Route path={`${path}/pagos`}>
            <Switch>
              <Route
                exact
                path={`${path}/pagos`}
                component={SearchPaymentCourse}
              />
              <Route
                exact
                path={`${path}/pagos/nuevo`}
                component={()=> <PaymentProjectPage description="Nuevo Pago" />}
              />
              <Route
                exact
                path={`${path}/pagos/:id`}
                component={()=> <PaymentProjectPage description="Editar Pago" />}
              />
              <Route
                exact
                path={`${path}/pagos/visualizar/:id`}
                component={()=> <PaymentProjectPage description="Ver Pago" mode='view' />}
              />
            </Switch>
      </Route>
    </Switch>
  );
};
