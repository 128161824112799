import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button';
import { Tree } from 'primereact/tree';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'primereact/skeleton';
import './SidebarMenu.scss'
import { DocumentManagementAction } from 'features/document/store/actions/DocumentManagementAction';
import { useClaimDocumentManagment } from 'features/document/hooks/useClaimDocumentManagment';


const SidebarMenu = ({setSelectedNode, setDisplayModal, setActionSelected}) => {
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [isFirstLoading, setFirstLoading] = useState(true); 
  const [expandedKeys, setExpandedKeys] = useState({});
  const dispatch = useDispatch()
  const { isLoading , data } = useSelector(state => state.documentManagementReducer.listFoldersTree)
  const { isEnabled } = useSelector((state) => state.documentManagementReducer.isStorageEnabled);

  const {isCreate} = useClaimDocumentManagment()

  useEffect(() => {
    if (isFirstLoading) {
      setFirstLoading(false);
      dispatch(DocumentManagementAction.getDataListFolders());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pathDocument = (path) => {
    if (!path) return
    const pathSplit = path.split('/')
    pathSplit.shift();
    const newPath = pathSplit.join('/')
    return newPath
  }

  const onSelect = (event) => {
    // console.log(event.node)
    if (event.node.label === 'Todos') {
      setSelectedNode('')
      return
    }
    
    // setSelectedNode(event.node.path)
    setSelectedNode(pathDocument(event.node.path))
  }

  const onCreate = () => {
    setActionSelected('create')
    setDisplayModal(true)
  }

  return (
    <div className='flex flex-col items-center justify-center gap-2'>
      <div className='w-full flex justify-center'>
        {
          isCreate&&(
            <Button 
              icon="pi pi-plus" type="button" label="Nuevo" className="sig-button sig-dark w-36 pointer" 
              onClick={onCreate}
              disabled={isEnabled ? false : true }
            />  
          )
        }
      </div>
      <div className='w-full menu-document'>
        {
          isLoading ?(
            <Skeleton className='mt-3' height="6.2rem" />
          ) : (
            <Tree 
              className='px-0' value={data} selectionMode="single" selectionKeys={selectedNodeKey}
              onSelectionChange={e => setSelectedNodeKey(e.value)} onSelect={onSelect} 
              expandedKeys={expandedKeys} onToggle={e => setExpandedKeys(e.value)}
              disabled={isEnabled ? false : true }
            />  
          )
        }
      </div>           
    </div>
  )
}

export default SidebarMenu