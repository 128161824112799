import { Fragment, useEffect } from "react";
import { useHistory } from "react-router";
import { Button } from "primereact/button";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import TableComponent from "features/maintenance/components/Institutions/TableComponent";
import AdvancedFilterComponent from "features/maintenance/components/Institutions/AdvacedFilterComponents";
import FilterComponent from "features/maintenance/components/Institutions/FilterComponent";
import { useClaimInstitution } from "features/maintenance/hooks/useClaimInstitution";

const SearchInstitutionsPage = () => {
  const history = useHistory();
  const { updateTitle } = useSetTitlePage();
  // const [isFirstLoading, setFirstLoading] = useState(true);
  // const dispatch = useDispatch();

  const { isSearch, isDownload, isCreate } = useClaimInstitution();

  useEffect(() => {
    updateTitle({
      title: "Mantenimiento",
      subtitle: "Institución",
    });
  }, []);

  return (
    <Fragment>
            {
                (isSearch||isDownload)&&(
                    <FilterComponent />
                )
            }
            {
                isCreate&&(
                  <Button
                    icon="pi pi-plus"
                    type="button"
                    label="Nuevo"
                    className="sig-button sig-dark mt-4"
                    onClick={() => {
                      history.push("/mantenimiento/institucion/nuevo");
                    }}
                  />
                )
            }
      <TableComponent />
      <AdvancedFilterComponent />
    </Fragment>
  );
};

export default SearchInstitutionsPage;
