import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import TableComponent from "features/indicators/components/Management/TableComponent";
import FilterComponent from "features/indicators/components/Management/FilterComponent";
import AdvancedFilterComponent from "features/indicators/components/Management/AdvanceFilterComponent";
import { ManagmentAction } from 'features/indicators/store/actions/ManagmentAction';
import { useClaimIndicatorAdministration } from 'features/indicators/hooks/useClaimIndicatorAdministration';

const SearchManagmentPage = () => {
    const history = useHistory();
    const { updateTitle } = useSetTitlePage();
    const [isFirstLoading, setFirstLoading] = useState(true);
    const dispatch = useDispatch();
    //comentario test
    useEffect(() => {
        updateTitle({
            title: 'Indicadores',
            subtitle: 'Administración',
        });
        dispatch(ManagmentAction.resetAfterSaved())
    }, []);

    const { isSearch, isDownload, isCreate } = useClaimIndicatorAdministration();


    return (
        <Fragment>
            {
                (isSearch||isDownload)&&(
                    <FilterComponent />
                )
            }
            {
                isCreate&&(
                    <Button
                        icon="pi pi-plus"
                        type="button"
                        label="Nuevo"
                        className="sig-button sig-dark mt-4"
                        onClick={() => {
                            history.push('/indicadores/administracion/nuevo');
                        }}
                    />
                )
            }
            <TableComponent />
            <AdvancedFilterComponent /> 
        </Fragment>
    );
};

export default SearchManagmentPage;
