/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";

export const useNotifications = (newQuestionsTemplate, setValue) => {
  const [notifications, setNotifications] = useState([])
  const businessSheet = useSelector(
    (state) => state.businessSheetReducer.data.businessSheet
  );
  const listControls = useSelector(
    (state) => state.businessSheetReducer.listControls.listControls
  );

  const setNotificationsData = () => {
    const notifications = []
    businessSheet.notifications?.forEach(notification => {
      setValue(`notification_name_${notification.id}`, notification.name)
      setValue(`notification_email_${notification.id}`, notification.email)
      notifications.push({
        id: notification.id,
        name: notification.name,
        email: notification.email,
      })
    })
    setNotifications(notifications)
  }

  useEffect(() => {
    if(businessSheet.id && Object.entries(newQuestionsTemplate).length > 0 && listControls?.length > 0){
      // setGeneralData()
      // setQuestionsData()
      setNotificationsData()
    }
  },[businessSheet.id, newQuestionsTemplate, listControls?.length])

  return {
    notifications,
    setNotifications,
  }
}