import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BusinessAction } from "features/customers/store/actions/BusinessAction";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import scrollTop from "shared/utils/scrollTop";
import { lowercaseFirstLetter } from "shared/utils/textTransform";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { TaskAction } from "features/customers/store/actions/TaskAction";
import { getBase64 } from "shared/utils/getBase64";
import { getDynamicFields } from "shared/utils/getDynamicFields";
import { BusinessConstants } from "features/customers/commons/BusinessConstants";

export const useBusiness = (title) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const customerId = params.customerId;
  const currentId = params.id;

  const [businessTasks, setbusinessTasks] = useState([]);

  const querys = new URLSearchParams(useLocation().search)

  const sourceData = querys.get("source") || '';

  let previousUrl = `/cliente/empresas/editar/${customerId}/negocios`;

  if (sourceData==="CRM"){
    previousUrl = '/negocios/ver-negocios'
  }

  const { updateTitle } = useSetTitlePage();
  const description = title === "Nuevo" ? "Nuevo Negocio" : "Editar Negocio";
  const pageTitle = {
    title: "Clientes",
    subtitle: "Empresas",
    description,
    previousUrl: previousUrl,
  };

  useEffect(() => {
    updateTitle(pageTitle);
  }, []);

  const { data, isLoadingDataMaster } = useSelector(
    (state) => state.businessReducer.dataMaster
  );

  useEffect(() => {
    dispatch(BusinessAction.getDataMaster());
  }, []);

  const { 
    isLoading,
    isLoadingBusiness, 
    status: currentStatus,
    data: currentBusiness,
    attachments,
    errors: fieldsErrors 
  } = useSelector(
    (state) => state.businessReducer.editBusiness
  );

  const dynamicFields = data?.dynamicFields || []

  const dynamicFieldsInformacion = getDynamicFields(dynamicFields, BusinessConstants.SectionsName.INFORMACION)

  // console.log(currentBusiness)
  // console.log(attachments)

  let defaultValues = {
    CustomerId: null,
    Name: null,
    Code: null,
    Description: null,
    CurrencyId: null,
    PipelineId: null,
    StageId: null,
    ResponsibleId: null,
    BusinessValue: null,
    OpenDate: null,
    CloseDate: null,


    savedFiles: [],
    addedFiles: [],
    // savedFiles: [
    //   {
    //       "Id": 20,
    //       "FileName": "Alumnos.xlsx",
    //       "URL": null,
    //       "SourceId": 68,
    //       "DocumentId": 11,
    //       "CreatedBy": "info@calidar.pe",
    //       "CreationDate": "2022-04-09T16:55:02.9345072+00:00"
    //   },
    //   {
    //       "Id": 22,
    //       "FileName": "SDM Gestor de cr\u00E9ditos - Alertas de tiempos de atenci\u00F3n.xlsx",
    //       "URL": null,
    //       "SourceId": 68,
    //       "DocumentId": 11,
    //       "CreatedBy": "info@calidar.pe",
    //       "CreationDate": "2022-04-09T16:56:49.8579203+00:00"
    //   },
    // ]
  };

  const {
    control,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues, shouldUnregister: true });
  const valuesFields = watch();

  const isNewBusiness = title === "Nuevo";
  // console.log(valuesFields)

  useEffect(() => {
    if (!isNewBusiness) {
      dispatch(BusinessAction.editBusiness(currentId));
      getTasks(currentId);
    } else {
      getTasks(-1);
    }
  }, []);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    const finishLoading = !isLoadingDataMaster && !isLoadingBusiness;
    const hasValues = Object.values(currentBusiness).length > 0;

    const datas = {
      PipelineId: "pipelines",
      StageId: "stages",
      ResponsibleId: "responsibles",
      CurrencyId: "currencies",
    };

    if (!isNewBusiness && finishLoading && hasValues && currentId) {
      if(currentBusiness.dynamicFieldValues?.length > 0){
        currentBusiness.dynamicFieldValues.forEach( dynamicFieldValue => {
          setValue(`dynamicFieldData:${dynamicFieldValue.identifier}`, dynamicFieldValue.value)
        })
      }
      for (let key in defaultValues) {
        const keyLowercase = key.toLowerCase();
        // convertir la primera letra en minuscula
        const keyLowercaseFirstLetter = lowercaseFirstLetter(key);
        if (currentBusiness[keyLowercaseFirstLetter]) {
          if (keyLowercase.includes("id") && keyLowercase !== 'customerid') {
            // const ID = currentBusiness[key];
            const ID = currentBusiness[keyLowercaseFirstLetter];
            const value = data[datas[key]].filter(
            // const value = data[datas[keyLowercaseFirstLetter]].filter(
              ({ id }) => id === ID
            )[0];
            setValue(key, value);
          } else {
            setValue(key, currentBusiness[keyLowercaseFirstLetter]);
          }
        } else {
          // setValue(key, null);
          // setValue(key, key==='savedFiles' ? [] : null);
          if (key === 'savedFiles') return setValue(key, attachments);
          if (key === 'addedFiles') return setValue(key, []);
          setValue(key, null);
          // setValue(key, key === 'savedFiles' ? attachments : null);
        }
      }
    }
  }, [isLoadingDataMaster, isLoadingBusiness]);

  useEffect(() => {
    if (!isLoading && currentStatus === 0) {
      dispatch(BusinessAction.setCurrentBusiness({ status: null }));
      history.push(previousUrl);
    }
  }, [currentStatus, isLoading]);

  // "savedFiles": [
  //   0
  // ],
  // "addedFiles": [
  //   {
  //     "fileName": "string",
  //     "fileType": "string",
  //     "fileBase64": "string"
  //   }
  // ]
  const onSubmit = async(data) => {
    const dynamicDataValues = []
    for( const [key, value] of Object.entries(data)){
      if(key.startsWith("dynamicFieldData")){
        const identifier = key.split(":")[1]
        const dynamicField = dynamicFields.find( ( field ) => field.identifier === identifier)
        let id = null
        if(!isNewBusiness){
          id = currentBusiness.dynamicFieldValues.find( field => field.identifier === identifier)?.id
        }
        dynamicDataValues.push(
          {
            id,
            entityConfigurationItemId: dynamicField.id,
            value: value || null,
            identifier,
          }
          )
          delete data[key]
      }
    }
      
    const business = {};
    business.dynamicDataValues = dynamicDataValues

    for (let key in data) {
      if (data[key]) {
        const keyLowercase = key.toLowerCase();
        const key_ = lowercaseFirstLetter(key);

      if (keyLowercase.includes("id")) {
          business[key_] = data[key].id;
        } else {
          business[key_] = data[key];
        }
      }
    }

    business.savedFiles = data.savedFiles?.map(({ id }) => id);
    business.addedFiles = []
    if(data.addedFiles?.length > 0) {
      const addedFiles = await Promise.all(data.addedFiles.map(async(file) => {
        delete file.id;
        const fileBase64 = await getBase64(file);
        let dataBase64 = fileBase64.split("base64,")[1]
        const newFile = {
          fileName: file.name,
          fileType: file.type,
          fileBase64: dataBase64
        }
        return newFile;
      }));
  
      business.addedFiles = addedFiles;
    }

    business.customerId = Number(customerId);

    if (!isNewBusiness) {
      business.id = Number(currentId);
    } else {
      business.id = null;
    }

    dispatch(BusinessAction.save({ business, isNewBusiness }));
  };

  const [showModalCancel, setShowModalCancel] = useState(false);
  const cancelAccept = () => {
    const { description, ...rest } = pageTitle;
    updateTitle(rest);
    history.push(previousUrl);
  };

  const cancelDenied = () => {
    setShowModalCancel(false);
  };

  const setActiveIndex = (v) => {
    history.push("/cliente/empresas/negocios");
  };

  const getTasksByBusinessId = (id) => {
    dispatch(TaskAction.getTasksByCustomer({ change: false, documentId: Number(id), sourceId: 69 }));
  }

  const getTasks = (businessId) => { 
    BusinessAction.getTasks(businessId).then((response) => { 
      setbusinessTasks(response);
    });
  }

  const taskDelete = async (taskId, businessId) => {
    await TaskAction.deleteTaskBoolean(taskId)
    .then((success) => {if(success) 
      getTasks(businessId);
    })
  }

  return {
    cancelAccept,
    cancelDenied,
    setShowModalCancel,
    setActiveIndex,
    showModalCancel,
    currentId,
    isLoading,
    isLoadingBusiness,
    isLoadingDataMaster,
    data,
    control,
    valuesFields,
    handleSubmit,
    onSubmit,
    getTasksByBusinessId,
    getTasks,
    taskDelete,
    fieldsErrors,
    errors,
    isNewBusiness,
    businessTasks,

    
    setValue,
    dynamicFieldsInformacion
  };
}