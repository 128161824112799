import { useSelector, useDispatch } from 'react-redux';
import ModalFilterComponent from 'shared/components/modal-filter/ModalFilterComponent';
import { Calendar } from 'primereact/calendar';
import { useEffect, useState } from 'react';
import { ManagmentAction } from 'features/indicators/store/actions/ManagmentAction';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';

const AdvancedFilterComponent = () => {
    const dispatch = useDispatch();
    const [formValues, setValues] = useState({
        from: null,
        to: null,
        name: null,
        roleId:null
    });

    const advancedFilter = useSelector((state) => state.managmentReducer.advancedFilter);
    const { showModal } = useSelector((state) => state.managmentReducer.advancedFilter);
    const controlsDataMaster = useSelector((state) => state.managmentReducer.controlsDataMaster.data);

    useEffect(() => {
        setValues({
            from: advancedFilter.filter.from,
            to: advancedFilter.filter.to,
            name:advancedFilter.filter.name,
            roleId: advancedFilter.filter.roleId
        });
    }, [advancedFilter]);


    const clearFields = () => {
        setValues({
            from: null,
            to: null,
            name: null,
            roleId:null
        });
    };
    const closeModal = () => {
        dispatch(ManagmentAction.toggleAdvancedFilter({ showModal: false }));
    };

    // useEffect(() => {
    //     dispatch(HomeActions.getOptionsDropDown());
    // },[])

    const handleSubmit = (e) => {
        e.preventDefault();
        const fields = formValues;

        if (fields?.from)
            advancedFilter.filter.from = fields.from;
        else
            advancedFilter.filter.from = null;

        if (fields?.to)
            advancedFilter.filter.to = fields.to;
        else
            advancedFilter.filter.to = null;

        if (fields?.name)
            advancedFilter.filter.name = fields.name;
        else
            advancedFilter.filter.name = null;

        if (fields?.roleId)
            advancedFilter.filter.roleId = fields.roleId;
        else
            advancedFilter.filter.roleId = null;

        dispatch(ManagmentAction.updateFilter(advancedFilter));
        dispatch(ManagmentAction.search());
        dispatch(ManagmentAction.toggleAdvancedFilter({ showModal: false }));
    }

    const onChangeFrom = (e) => {
        if (e.value)
            formValues.to = null;
        setValues({
            ...formValues,
            [e.target.name]: e.value,
        });
    }

    const onChangeTo = (e) => {
        setValues({
            ...formValues,
            [e.target.name]: e.value,
        });
    }

    const onChangeText = (e) => {
        setValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    }

    const onChangeRole = (e) => {
        setValues({
            ...formValues,
            ['roleId']: e.value,
        });
    }


    if (showModal)
        return (
            <ModalFilterComponent
                disabledBtn={false}
                closeModal={closeModal}
                clearFields={clearFields}
                handleSubmit={handleSubmit}
                searchLoading={advancedFilter?.loading}
            >

                <div>
                    <div className="flex gap-4 pt-3">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                value={formValues.from}
                                name="from"
                                id="desde"
                                onChange={(e) => onChangeFrom(e)}
                            />
                            <label htmlFor="desde">Desde</label>
                        </span>
                    </div>
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                value={formValues.to}
                                name="to"
                                id="hasta"
                                minDate={formValues?.from}
                                onChange={(e) => onChangeTo(e)}
                            />
                            <label htmlFor="hasta">Hasta</label>
                        </span>
                    </div>
                    </div>

                    <div className="pt-7">
                        <div className="w-full mb-6">
                        <span className="p-float-label">
                            <InputText
                                name='name'
                                id='nombr'
                                value={formValues?.name ? formValues?.name: ""}
                                onChange={onChangeText}
                            />
                            <label htmlFor='nombr'>Nombre</label>
                        </span>
                        </div>
                        <div className="w-full">
                            <span className="p-float-label">
                                <Dropdown
                                    options={controlsDataMaster?.roles}
                                    optionLabel="name"
                                    value={formValues?.roleId}
                                    onChange={(e) => { onChangeRole(e) }}
                                    filter
                                    showClear
                                    filterBy="code"
                                    className="w-full"
                                />

                                <label htmlFor="dropdown">Seleccione Roles</label>
                            </span>
                        </div>
                    </div>
                </div>

            </ModalFilterComponent>
        );
    else
        return null;
}

export default AdvancedFilterComponent;