

import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Skeleton } from 'primereact/skeleton';
import { CoursesExpensesAction } from 'features/coursesManagment/store/actions/CourseExpensesAction';
import { useClaimCoursesExpenses } from 'features/coursesManagment/hooks/useClaimCoursesExpenses';
import { Link } from 'react-router-dom';
import { CoreConstants } from 'core/commons/CoreConstants';

const TableComponent = () => {
    
    const dispatch = useDispatch();
    const history = useHistory();
    
    const advancedFilter = useSelector((state) => state.courseExpensesReducer.advancedFilter);
    const { data } = useSelector((state) => state.courseExpensesReducer.courseExpenses);
    const courseExpenses = useSelector((state) => state.courseExpensesReducer.courseExpenses);
    const {isDeleted} = useSelector((state) => state.courseExpensesReducer.courseExpense);
    const { categories } = useSelector((state) => state.courseExpensesReducer.dataMaster);

    const columns = [
        { field: 'source', header: 'Curso'},
    ];

    const { isView ,isEdit, isDelete } = useClaimCoursesExpenses();

    const [selectedColumns, setSelectedColumns] = useState(columns);
    const actionsHeader = useRef(null);
    const menu = useRef(null);
    const [showConfirmDialog, setshowConfirmDialog] = useState(false);
    const [isFirstLoading, setFirstLoading] = useState(true); 
    const [expandedRows, setExpandedRows] = useState(null);
    const [courseExpenseSelected, setCourseExpenseSelected] = useState(null);

    useEffect(() => {
        if (isFirstLoading) {
            tryParseFilter();
            setFirstLoading(false);
            // setSortField("name");
            // setSortOrder(1);
            dispatch(CoursesExpensesAction.search());
        }
    }, []);


    useEffect(() => {
        if (isDeleted) {
            setshowConfirmDialog(false);
            dispatch(CoursesExpensesAction.resetAfterSaved());
            dispatch(CoursesExpensesAction.search());
        }
    }, [isDeleted])

    // const actionHeaderTemplate = (rowData) => {
    //     return <i
    //         key="action"
    //         className="pi pi-ellipsis-v cursor-pointer"
    //         onClick={(e) => actionsHeader.current.toggle(e)}></i>;
    // }

    let items = [
        {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: (e) => {
                history.push('/curso/gastos/' + courseExpenseSelected.id);
            }
        },
        {
            label: 'Eliminar',
            icon: 'pi pi-times',
            command: (e) => {
                setshowConfirmDialog(true);
            }
        },
    ];

    if (!isEdit)
        items = items.filter(c => c.label !== 'Editar');
    if (!isDelete)
        items = items.filter(c => c.label !== 'Eliminar');


    const actionBodyTemplate = (rowData) => {
        if(isEdit||isDelete){
            return <i
                key={rowData.id}
                className="pi pi-ellipsis-v cursor-pointer"
                onClick={(event) => {
                    setCourseExpenseSelected(rowData);
                    menu.current.toggle(event)
                }
                }></i>
        }else{
            return <div></div>
        }
    }

    const tryParseFilter = () => {
        try {
            const filters = JSON.parse(localStorage.getItem("curses-expenses-filter") || '[]');
            const myFilters = filters.filter(item => item.field === 'source');
            if (filters)
                setSelectedColumns(myFilters);
            else
                setSelectedColumns(columns);
        } catch (error) {
            localStorage.removeItem('curses-expenses-filter');
        }
    }

    const onColumnToggle = (e, item) => {
        let _selectedColumns = [...selectedColumns];

        if (e.checked)
            _selectedColumns.push(item);
        else
            _selectedColumns = _selectedColumns.filter(c => { return c.field !== e.value });

        setSelectedColumns(_selectedColumns);
        localStorage.setItem("curses-expenses-filter", JSON.stringify(_selectedColumns));
    }

    const getColumns = () => {
        let array = [];
        
        columns.map((item) => {
            const column = <div key={item.field} className="p-field-checkbox py-1">
                <Checkbox
                    inputId={item.field}
                    name="column"
                    value={item.field}
                    onChange={(e) => onColumnToggle(e, item)}
                    checked={selectedColumns.some((x) => x.field === item.field)}
                />
                <label className="ml-1" htmlFor={item.field}>{item.header}</label>
            </div>
            array.push(column);
        });
        return array;
    };

    const confirmDelete = () => {
        dispatch(CoursesExpensesAction.deleteCourseExpense(courseExpenseSelected.id));
    }

    const cancelDelete = () => {
        setshowConfirmDialog(false);
    }

    const [rows, setRows] = useState(10)

    const onPageChange = (e) => {
        setRows(e.rows);
        advancedFilter.page = e.page + 1;
        advancedFilter.pageSize = e.rows;
        dispatch(CoursesExpensesAction.updateFilter(advancedFilter));
        dispatch(CoursesExpensesAction.search());
    }

    const onRowExpand = (event) => {
        // console.log(event)
        // toast.current.show({severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000});
    }

    const getFromDate = (rowData) => rowData.from ? moment(rowData.from).format(CoreConstants.Format.LOCALE) : ''
    const getToDate = (rowData) => rowData.to ? moment(rowData.to).format(CoreConstants.Format.LOCALE) : ''

    const getCategory = (rowData) => {
        const category = categories.find(project => project.id === rowData.categoryId);
        if (isView)
          return <Link className="underline text-blue-700" to={{ pathname: `/curso/gastos/visualizar/` + rowData.id }} >{category.description}</Link>
        else
          return category.description
    }
    const getAmount = (rowData) => {
        return <p>S/. {rowData.totalAmount}</p>
    }

    const rowExpansionTemplate = (data) => {
        return (
          <div className="tasks-subtable">
            <DataTable value={data.children} header={null} className='ml-0' 
                // lazy loading={loading}
                emptyMessage="No se encontraron gastos">
                <Column field="categoryName" header="Categoria" body={getCategory} style={{width: '25%'}}   />
                <Column field="from" header="Inicio" body={getFromDate} style={{width: '25%'}} sortable />
                <Column field="to" header="Fin" body={getToDate} style={{width: '25%'}} sortable />
                {/* <Column field="amount" header="Monto" style={{textAlign: 'right'}} /> */}
                <Column field="totalAmount" header="Monto" body={getAmount} style={{width: '25%'}} />
                <Column header={<div></div>} body={actionBodyTemplate} ></Column>
            </DataTable>
          </div>
        );
      };

    const courseBodyTemplate = (rowData) => {
        return <span className=''>{rowData.data.courseName}</span>;
    } 

    return (
        <Fragment>
            {
                courseExpenses.isLoading ? <Skeleton height='12rem' /> : (
                    <div className="">
                        <DataTable
                            value={data}
                            emptyMessage="No se encontraron cursos"
                            stripedRows
                            paginator rows={10}
                            dataKey="key"
                            rowHover
                            size="small"
                            responsiveLayout="scroll"
                            rowExpansionTemplate={rowExpansionTemplate}
                            expandedRows={expandedRows}
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            onRowExpand={(e) => onRowExpand(e.data)}
                        >                      
                            <Column expander style={{ width: '3em' }} />
                            <Column field="project" header="Curso" body={courseBodyTemplate} sortable />
                        </DataTable>
                    </div>
                )
            }

            <OverlayPanel ref={actionsHeader} showCloseIcon style={{ width: '300px' }}>{getColumns()}</OverlayPanel>

            <Menu model={items} popup ref={menu} id="popup_menu" />
            <ConfirmDialog
                visible={showConfirmDialog}
                onHide={cancelDelete}
                message="¿Está seguro que desea eliminar el registro?"
                header="Eliminar"
                icon="pi pi-trash" 
                accept={confirmDelete}
                reject={cancelDelete}
            />
        </Fragment >
    );
};

export default TableComponent;
