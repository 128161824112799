import { apiService } from "core/services/ApiService";

// En proceso 

const searchMonitorings = (criterio) => {
  return apiService.Post("management/api/Indicator/SearchIndicators", criterio);
}

const SearchIndicatorsMonitoring = (criterio) => {
  return apiService.Post("management/api/Indicator/SearchIndicatorsFollowUp", criterio);
}

const generateReport = (payload) => {
  return apiService.Post("management/api/Indicator/GenerateReport", payload, {
    responseType: 'blob',
  })
}

const saveMonitoring = (data) => {
  return apiService.Post("management/api/Indicator/Save", data);
}

const getMonitoringById = (monitoringId) => {
  return apiService.Get("management/api/Indicator/GetIndicatorById?indicatorId=" + monitoringId);
}

const getDataMaster = () => {
  return apiService.Post("management/api/Indicator/GetDataMaster");
}

const getValuesByIndicatorId = (id) => {
  return apiService.Get(`management/api/Indicator/GetValuesByIndicatorId?id=${id}`);
}

const getValuesIndicatorByIdRange = (id, from, to) => {
  return apiService.Get(`management/api/Indicator/GetValuesAndPeriodByIndicatorIdDateRange?id=${id}&from=${from}&to=${to}`);
}

const saveIndicatorValues = (data) => {
  return apiService.Post("management/api/Indicator/SaveIndicatorValues", data);
}

const addComment = (payload) => {
  return apiService.Post(`customer/api/Task/AddComment`, payload);
}

const deleteComment = (id) => {
  return apiService.Delete(`customer/api/Task/DeleteComment?id=${id}`);
}

const getCommentsByIndicatorId = (id) => {
  return apiService.Get(`management/api/Indicator/GetCommentsByIndicatorId?id=${id}`);
}

const saveIndicatorActionPlan = (payload) => {
  return apiService.Post(`management/api/Indicator/SaveIndicatorActionPlan`, payload);
}

const getIndicatorActionPlanByIndicatorId = (id) => {
  return apiService.Get(`management/api/Indicator/GetIndicatorActionPlanByIndicatorId?id=${id}`);
}

const generateFollowUpReport = (indicatorId) => {
  return apiService.Post(`management/api/Indicator/GenerateFollowUpReport?indicatorId=${indicatorId}`, null , {
    responseType: 'blob',
  })
}

export const MonitoringService = {
  searchMonitorings,
  SearchIndicatorsMonitoring,
  generateReport,
  saveMonitoring,
  getMonitoringById,
  getDataMaster,
  getValuesByIndicatorId,
  getValuesIndicatorByIdRange,
  saveIndicatorValues,

  addComment,
  deleteComment,
  getCommentsByIndicatorId,
  saveIndicatorActionPlan,
  getIndicatorActionPlanByIndicatorId,
  generateFollowUpReport
};