export class BusinessSheetConstants {
  static Action = class {
    static BusinessSheet = class {
      static get SEARCH() {
        return '@BUSINESS-SHEET.SEARCH';
      }
      static get DATA_MASTER() {
        return '@BUSINESS-SHEET.DATA-MASTER';
      }
      static get EDIT() {
        return '@BUSINESS-SHEET.EDIT';
      }
      static get DOWNLOAD_STATUS() {
        return '@BUSINESS-SHEET.DOWNLOAD-STATUS';
      }
      static get LIST_CONTROLS(){
        return '@BUSINESS-SHEET.LIST-CONTROLS';
      }
      static get SAVE(){
        return '@BUSINESS-SHEET.SAVE';
      }
      static get SHARE(){
        return '@BUSINESS-SHEET.SHARE';
      }
      static get TEMPLATES(){
        return '@BUSINESS-SHEET.TEMPLATES';
      }
    };
  };

  static Status = class {
    static get DRAFT() {
      return "BSNS_SHT_DRFT";
    }

    static get PUBLISHED() {
      return "BSNS_SHT_PUBL";
    }

    static get CLOSED() {
      return "BSNS_SHT_CLSD";
    }
  }

  static FieldName = class {
    static Filter = class {
      static get NAME(){
        return 'name'
      }
      static get CODE(){
        return 'code'
      }
      static get STATUS_ID(){
        return 'statusId'
      }
      static get TYPE_ID(){
        return 'typeId'
      }
      static get FROM_DATE(){
        return 'fromDate'
      }
      static get TO_DATE(){
        return 'toDate'
      }
    }
  }
}