import { useEffect } from "react"
import { TableComponent } from "features/business/components/businessSheet/TableComponent";
import useSetTitlePage from "shared/hooks/useSetTitlePage"
import { FilterComponent } from "features/business/components/businessSheet/FilterComponent";
import { useDispatch } from "react-redux";
import { BusinessSheetAction } from "features/business/store/actions/BusinessSheetAction"; 

export const PageBusinessSheetSearch = () => {
  const { updateTitle } = useSetTitlePage();
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(BusinessSheetAction.getDataMaster())
    updateTitle({
      title: "Negocios",
      subtitle: "Fichas de Negocios"
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return(
    <>
      <FilterComponent />
      <div className="mt-5">
        <TableComponent />
      </div>
    </>
  )
}