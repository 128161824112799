import { PageView } from "features/business/pages/view/PageView";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { viewBusinessReducer } from "features/business/store/reducers/BusinessReducer";
import businessSheetReducer from "features/business/store/reducers/BusinessSheetReducer";
import { PageConfigurationBusinessSheet } from "features/business/pages/businessSheet/PageConfigurationBusinessSheet";
import { PageBusinessSheetSearch } from "./pages/businessSheet/PageBusinessSheetSearch";
import { PageBusinessSheetAnswersSearch } from "./pages/businessSheet/PageBusinessSheetAnswersSearch";
import { businessSheetAnswerReducer } from "features/business/store/reducers/BusinessSheetAnswerReducer";
import { PageBusinessSheetAnswer } from "./pages/businessSheet/PageBusinessSheetAnswer";

export const BusinessRouting = (reducerRegistry) => {
  const { path } = useRouteMatch();
  reducerRegistry.register({ viewBusinessReducer });
  reducerRegistry.register({ businessSheetReducer });
  reducerRegistry.register({ businessSheetAnswerReducer });

  return (
    <Switch>
      <Route path={`${path}/ver-negocios`} component={PageView} />
      <Route path={`${path}/fichas/configuracion/nuevo`} component={PageConfigurationBusinessSheet} />
      <Route path={`${path}/fichas/configuracion/:id`} component={PageConfigurationBusinessSheet} />
      <Route path={`${path}/fichas/respuestas/:id/respuesta/:idAnswer`} component={PageBusinessSheetAnswer} />
      <Route path={`${path}/fichas/respuestas/:id`} component={PageBusinessSheetAnswersSearch} />
      <Route path={`${path}/fichas`} component={PageBusinessSheetSearch} />
    </Switch>
  );
};
