import { Fragment } from "react";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useAdvancedFilterStudents } from "features/customers/hooks/students/useAdvancedFilterStudents";
import { useStudents } from "features/customers/hooks/students/useStudents";
import PinerComponent from "shared/components/Piner/PinerComponent";
import { useClaimStudents } from "features/customers/hooks/useClaimStudents";

export const FilterComponent = () => {
  const {
    onToggleModal,
    studentsLoading,
    reportLoading,
    showPiners,
    piners,
    removePiner,
    onAdvancedFilter,
    formValues,
    handleChange,
  } = useAdvancedFilterStudents();
  const { isSearch, isDownload } = useClaimStudents();
  const { generateReport } = useStudents();
  return (
    <Fragment>
      <Panel header={`${isSearch?"FILTRO POR":"DESCARGA"}`} toggleable>
        <form className="filter-roles" onSubmit={onAdvancedFilter}>
        <div className={`w-full ${isSearch?'visible':'invisible'}`}>
            <span className="p-float-label p-input-icon-left w-full">
              <i className="pi pi-search" />
              <InputText
                id="input-search"
                name="names"
                value={formValues?.names ? formValues?.names : ""}
                onChange={handleChange}
              />
              <label htmlFor="input-search">
                Buscar por apellidos y nombres del alumno{" "}
              </label>
            </span>
            {showPiners && (
              <div className="filter-piners mt-3">
                {piners.map(({ value, field }, index) => (
                  <PinerComponent
                    name={value}
                    field={field}
                    removePiner={removePiner}
                    key={index}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="filter-users-action">
            <div className="flex gap-3 w-full">
              {
                isSearch&&(
                  <Button
                    icon="pi pi-search"
                    type="submit"
                    label="Buscar"
                    loading={studentsLoading || reportLoading}
                    className="sig-button sig-primary w-full"
                  />
                )
              }
              {
                isDownload&&(
                  <Button
                    icon="pi pi-download"
                    type="button"
                    onClick={generateReport}
                    loading={reportLoading}
                    disabled={studentsLoading || reportLoading}
                    label="Descargar"
                    className="sig-button sig-success w-full"
                  />
                )
              }
            </div>
            <div className="flex mt-3">
              {
                isSearch&&(
                  <Button
                    type="button"
                    label="Búsqueda Avanzada"
                    disabled={studentsLoading || reportLoading}
                    className="sig-button  sig-secondary w-full"
                    onClick={() => onToggleModal(true)}
                  />
                )
              }
            </div>
          </div>
        </form>
      </Panel>
    </Fragment>
  );
};
