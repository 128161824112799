import { Switch, Route, useRouteMatch } from 'react-router-dom';
import riskManagementReducer from './store/reducers/RiskManagementReducer'
import riskInventoryReducer from './store/reducers/RiskInventoryReducer'
import riskModelReducer from './store/reducers/RiskModelReducer'
import riskEvaluationReducer from './store/reducers/RiskEvaluationReducer'
import riskMatrixReducer from './store/reducers/RiskMatrixReducer'
import RiskConfigurationPage from './pages/RiskConfigurationPage';
import RiskManagementPage from './pages/RiskManagementPage';
import RiskMapPage from './pages/RiskMapPage';
import RiskInventoryPage from 'features/riskManagement/pages/RiskInventoryPages/RiskInventoryPage';
import NewRiskInventoryPage from 'features/riskManagement/pages/RiskInventoryPages/NewRiskInventoryPage';
import ModelManagementPage from './pages/ModelManagementPages/ModelManagementPage';
import NewModelManagementPage from './pages/ModelManagementPages/NewModelManagementPage';
import ModelEvaluacionPage from './pages/ModelManagementPages/ModelEvaluacionPage';
import EvaluacionComponent from './components/ModelManagement/Evaluacion/EvaluacionComponent';
import RiskMatrixPage from 'features/riskManagement/pages/RiskMatrixPages/RiskMatrixPage';

export default function RiskManagementRouting(reducerRegistry){
	const { path } = useRouteMatch();
	reducerRegistry.register({ riskManagementReducer });
	reducerRegistry.register({ riskInventoryReducer });
	reducerRegistry.register({ riskModelReducer });
	reducerRegistry.register({ riskEvaluationReducer });
	reducerRegistry.register({ riskMatrixReducer });
	
	return(
		<>
			<Switch>
				<Route exact path={`${path}/configuracion`} component={RiskConfigurationPage}/>
				{/* <Route exact path={`${path}/matriz/:id`} component={RiskMapPage}/> */}
				<Route exact path={`${path}/matrices`} component={RiskManagementPage}/>
				<Route exact path={`${path}/inventario/editar/:id`} component={NewRiskInventoryPage}/>
				<Route exact path={`${path}/inventario/visualizar/:id`} component={()=> <NewRiskInventoryPage mode='view'/>}/>
				<Route exact path={`${path}/inventario/nuevo`} component={NewRiskInventoryPage}/>
				<Route exact path={`${path}/inventario`} component={RiskInventoryPage}/>
				<Route exact path={`${path}/modelo/editar/:id`} component={NewModelManagementPage}/>
				<Route exact path={`${path}/modelo/visualizar/:id`} component={()=> <NewModelManagementPage mode="view" />}/>
				<Route exact path={`${path}/modelo/nuevo`} component={NewModelManagementPage}/>
				<Route exact path={`${path}/modelo/:idModelo/evaluaciones/evaluacion/nuevo`} component={ModelEvaluacionPage}/>
				<Route exact path={`${path}/modelo/:idModelo/evaluaciones/evaluacion/:id`} component={ModelEvaluacionPage}/>
				<Route exact path={`${path}/modelo`} component={ModelManagementPage}/>
				<Route exact path={`${path}/modelo/:idModelo/evaluaciones`} component={EvaluacionComponent}/>
				<Route exact path={`${path}/:id`} component={RiskMatrixPage}/>
			</Switch>
		</>
	)

}