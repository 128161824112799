const columns = [
  { field: 'name', header: 'Nombre' },
  { field: 'responsible', header: 'Responsable' },
  { field: 'status', header: 'Estado' }, 
  { field: 'estimatedOpenDate', header: 'Apertura Estimada' },
  { field: 'realOpenDate', header: 'Apertura Real' },
  { field: 'estimatedCloseDate', header: 'Cierre Estimado' },
  { field: 'realCloseDate', header: 'Cierre Real' },
  { field: 'situation', header: 'Situación' },  
  { field: 'assignedAmount', header: 'Monto Adjudicado' },
];

const colaboratorColumns = [
  { field: 'memberId', header: 'Nombre' },
  { field: 'roleId', header: 'Rol' },
];

const deliverableColumns = [
  { field: 'name', header: 'Nombre' },
  { field: 'beginDate', header: 'Inicio' },
  { field: 'endDate', header: 'Fin' },
];

export const ProjectTableData = {
  columns,
  colaboratorColumns,
  deliverableColumns
};
