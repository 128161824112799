import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { Rating } from 'primereact/rating';
import { Controller } from 'react-hook-form';
import './ListQuestionSurvey.scss'

const QuestionTitle = ({question, index, isRequired}) => {
  return (
    <div 
      className='flex font-semibold gap-x-1'
    >
      {index + 1}. 
      {/* {question.position}.  */}
      <div dangerouslySetInnerHTML={{ __html: question.body }} />
      {/* <p>(Sección {question.surveySectionId})</p> */}
      <p className='text-red-500'>{isRequired ? '*' : ''}</p>
    </div>
  )
}

const getFormErrorMessage = (error) => {
  // console.log(error)
  return error?.message ? <small className="p-error">{error.message}</small> : <small className="p-error">&nbsp;</small>;
};

const ListQuestionSurvey = ({question, control, index, indexTotal, valuesFields, onQuestionChange, onChangeWithOther, onChangeSaveTime, onBlurSaveTime, onChangeText}) => {
  //* onChange
  // console.log(indexTotal)
  // console.log(valuesFields)
  if (question?.questionType?.code === 'TX') {
    return (
      <div className='py-4 px-4 bg-gray-100 shadow border-2 flex flex-col' key={question.id}>
        <QuestionTitle question={question} index={index} isRequired={question.isRequired} />
        <Controller
          control={control}
          rules={{ required: {
            value: question.isRequired,
            message: 'Esta pregunta es requerida'
          }}}
          // shouldUnregister={true} 
          render={({
              field: { onChange, value },
              fieldState: { error },
          }) => (
            <div className='w-full flex flex-col items-start gap-1'>
              <InputTextarea 
                value={value} 
                // onChange={(e) => onChange(e.target.value)} 
                onChange={(e) => onChangeText(onChange, e.target.value, error, indexTotal)} 
                onBlur={() => onBlurSaveTime(indexTotal)}
                rows={5} cols={30} 
                className={`w-full ${error ? 'p-invalid' : ''}`}
              />
              {getFormErrorMessage(error)}
            </div>
            )}
          // name={`question_${index+1}`}
          name={`question_${indexTotal}`}
        />
      </div>
  )}

  //* onQuestionChange
  if (question?.questionType?.code === 'OM') {
    return (
      <div className='py-4 px-4 bg-gray-100 shadow border-2 flex flex-col' key={question.id}>
        <QuestionTitle question={question} index={index} isRequired={question.isRequired} />
        <span className="p-float-label w-full md:w-1/2 my-3">
          <Controller
              control={control}
              // Validacion de requerido para checkbox
              rules={{ 
                required: {
                  value: question.isRequired,
                  message: 'Esta pregunta es requerida',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div className='w-full flex flex-col items-start gap-4'>
                  {
                    question?.options.map((option) => (
                        <div key={option.id} className='flex justify-center items-center gap-x-2'>
                          <Checkbox 
                            inputId={option.id} 
                            value={option} 
                            // onChange={(e) => onQuestionChange(e, `question_${index+1}`, `question_${index+1}`)} 
                            // onChange={(e) => onQuestionChange(e, `question_${index+1}`, onChange, error)} 
                            onChange={(e) => onQuestionChange(e, `question_${indexTotal}`, onChange, error, indexTotal)} 
                            // checked={valuesFields[`question_${index + 1}`].some((item) => item.id === option.id)} 
                            checked={valuesFields[`question_${indexTotal}`].some((item) => item.id === option.id)} 
                            className={`${error ? 'p-invalid' : ''}`}
                          />
                          <p>{option.caption}</p>
                        </div>
                      )
                    )
                  }
                  {getFormErrorMessage(error)}
                </div>
                )}
              // name={`question_${index+1}`}
              name={`question_${indexTotal}`}
          />
        </span>
        {
          valuesFields[`question_${index + 1}`].some(q => q.caption === 'Otra') && (
            <span className="w-full md:w-2/3 lg:w-1/2">
              <Controller
                  control={control}
                  render={({
                      field: { onChange, onBlur, value },
                  }) => (
                    <InputText
                      // onChange={(e) => onChangeText(onChange, e.target.value)}
                      // onBlur={onBlur}
                    
                      onChange={onChange}
                      // onBlur={onBlurSaveTime}
                      onBlur={() => onBlurSaveTime(indexTotal)}
                      value={value}
                      className={'w-full'}
                    />
                  )}
                  name={`question_${index + 1}_other`}
              />
            </span>
          )
        }
      </div>
    )
  }
  if (question?.questionType?.code === 'CV') {
    return (
      <div className='py-4 px-4 bg-gray-100 shadow border-2 flex flex-col' key={question.id}>
        <QuestionTitle question={question} index={index} isRequired={question.isRequired} />
        <span className="p-float-label w-full md:w-1/2 my-3">
          <Controller
              control={control}
              rules={{ required: {
                value: question.isRequired,
                message: 'Esta pregunta es requerida'
              }}}
              render={({
                  field: { onChange, value },
                  fieldState: { error },
              }) => (
                <div className='w-full flex flex-col items-start gap-4'>
                  {
                    question.options.map((question) => (
                        <div key={question.id} className='flex justify-center items-center gap-x-2'>
                          <RadioButton 
                            inputId={question.id} 
                            value={question} 
                            onChange={(e) => onChangeWithOther(onChange, e.value, `question_${indexTotal}`, error, indexTotal)} 
                            checked={valuesFields[`question_${index + 1}`].id === question.id} 
                            className={`${error ? 'p-invalid' : ''}`}
                          />
                          <p>{question.caption}</p>
                        </div>
                      )
                    )
                  }
                  {getFormErrorMessage(error)}
                </div>
                )}
              // name={`question_${index+1}`}
              name={`question_${indexTotal}`}
          />
        </span>
        {
          valuesFields[`question_${index + 1}`]?.caption === 'Otra' && (
            <span className="w-full md:w-2/3 lg:w-1/2">
              <Controller
                  control={control}
                  render={({
                      field: { onChange, onBlur, value },
                  }) => (
                    <InputText
                        // onChange={(e) => onChangeText(onChange, e.target.value)}
                        // onBlur={onBlur}
                        onChange={onChange}
                        // onBlur={onBlurSaveTime}
                        onBlur={() => onBlurSaveTime(indexTotal)}
                        value={value}
                        className={'w-full'}
                    />
                  )}
                  name={`question_${index + 1}_other`}
              />
            </span>
          )
        }
      </div>
    )
  }
  if (question?.questionType?.code === 'LD') {
    return (
      <div className='py-4 px-4 bg-gray-100 shadow border-2 flex flex-col' key={question.id}>
        <QuestionTitle question={question} index={index} isRequired={question.isRequired} />
        <span className="p-float-label w-full md:w-2/3 lg:w-1/2 my-3">
          <Controller
              control={control}
              rules={{ required: {
                value: question.isRequired,
                message: 'Esta pregunta es requerida'
              }}}
              render={({
                  field: { onChange, value },
                  fieldState: { error },
              }) => (
                <div>
                  <Dropdown
                      options={question.options}
                      optionLabel="caption"
                      value={value}
                      // onChange={(e) => onChangeWithOther(onChange, e.value, `question_${index+1}`, error)}
                      onChange={(e) => onChangeWithOther(onChange, e.value, `question_${indexTotal}`, error, indexTotal)} 
                      showClear
                      className={`w-1/2 ${error ? 'p-invalid' : ''}`}
                  />
                  {getFormErrorMessage(error)}
                </div>
                
              )}
              // name={`question_${index+1}`}
              name={`question_${indexTotal}`}
          />
          {/* <label htmlFor={`question_${index}`}>Categoría:</label> */}
        </span>
        {
            valuesFields[`question_${index + 1}`]?.caption === 'Otra' && (
              <span className="w-full md:w-2/3 lg:w-1/2 mt-0">
                <Controller
                    control={control}
                    render={({
                        field: { onChange, onBlur, value },
                    }) => (
                      <InputText
                          // onChange={(e) => onChangeText(onChange, e.target.value)}
                          // onBlur={onBlur}
                          onChange={onChange}
                          // onBlur={onBlurSaveTime}
                          onBlur={() => onBlurSaveTime(indexTotal)}
                          value={value}
                          className={'w-full'}
                      />
                    )}
                    name={`question_${index + 1}_other`}
                />
              </span>
            )
          }
      </div>
    )
  }
  if (question.questionType.code === 'RE') {
    return (
      <div className='py-4 px-4 bg-gray-100 shadow border-2 flex flex-col' key={question.id}>
        <QuestionTitle question={question} index={index} isRequired={question.isRequired} />
        <span className="p-float-label w-full md:w-1/2 my-3">
          <Controller
              control={control}
              rules={{ required: {
                value: question.isRequired,
                message: 'Esta pregunta es requerida'
              }}}
              render={({
                  field: { onChange, value },
                  fieldState: { error },
              }) => (
                <div className={`${!error ? '' : 'p-invalid error-rating'}`}>
                  <Rating 
                    value={value} 
                    // onIcon="pi pi-circle-fill" offIcon="pi-circle"
                    stars={question.rankingQuantity}
                    // onChange={(e) => onChangeSaveTime(onChange, e.value, error, index)} 
                    onChange={(e) => onChangeSaveTime(onChange, e.value, error, indexTotal)} 
                    cancel={false} 
                    
                    // className={`${error ? 'error-rating' : ''}`}
                  />
                  {getFormErrorMessage(error)}
                </div>
              )}
              // name={`question_${index+1}`}
              name={`question_${indexTotal}`}
          />
        </span>
      </div>
    )
  }
  return null
}

export default ListQuestionSurvey