import { ProcessManagementConstans } from "../../commons/ProcessManagementConstans";
import { CoreConstants } from 'core/commons/CoreConstants';
import moment from 'moment';
import { toastAction } from "core/store/actions/ToastAction";
import FileSaver from 'file-saver';
import { ProcesseSheetServices } from "../../services/ProcesseSheetServices";

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: ProcessManagementConstans.Accion.ProcesseSheet.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};
const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: ProcessManagementConstans.Accion.ProcesseSheet.RESET_AFTER_SAVED });
};

const getOptionsDropDown = () =>{
  const setOptionsDropDown = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ProcessManagementConstans.Accion.ProcesseSheet.GET_OPTIONS_DROPDOWN,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    ProcesseSheetServices.getOptions().then(
      ({data}) => {
        if (data.status === CoreConstants.HttpResponse.OK){
          setOptionsDropDown({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        }else if (data.status < CoreConstants.HttpResponse.OK){
          setOptionsDropDown({ dispatch: dispatch, isLoading: false, data: null });
        }
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setOptionsDropDown({ dispatch: dispatch, isLoading: false, data: null });
        }
      },
      () => {
        setOptionsDropDown({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  }
}

const getListVersions = () =>{
  const setListVersions = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ProcessManagementConstans.Accion.ProcesseSheet.GET_LIST_VERSIONS,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    ProcesseSheetServices.getListVersions().then(
      ({data}) => {
        if (data.status === CoreConstants.HttpResponse.OK){
          setListVersions({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        }else if (data.status < CoreConstants.HttpResponse.OK){
          setListVersions({ dispatch: dispatch, isLoading: false, data: null });
        }
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setListVersions({ dispatch: dispatch, isLoading: false, data: null });
        }
      },
      () => {
        setListVersions({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  }
}
const getProducedBy = () =>{
  const getProducedBy = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ProcessManagementConstans.Accion.ProcesseSheet.GET_PRODUCED_BY,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    ProcesseSheetServices.getProducedBy().then(
      ({data}) => {
        if (data.status === CoreConstants.HttpResponse.OK){
          getProducedBy({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        }else if (data.status < CoreConstants.HttpResponse.OK){
          getProducedBy({ dispatch: dispatch, isLoading: false, data: null });
        }
        else if (data.status > CoreConstants.HttpResponse.OK) {
          getProducedBy({ dispatch: dispatch, isLoading: false, data: null });
        }
      },
      () => {
        getProducedBy({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  }
}




const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload;

    if (advancedFilter.filter.name)
      pines.push({ field: 'name', value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.DDMMYYYY
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.DDMMYYYY
        ),
      });

      // console.log(advancedFilter)

    advancedFilter.values = pines;
    dispatch({
      type: ProcessManagementConstans.Accion.ProcesseSheet.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = (NumStatus) => {
  const setProcesSheet = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ProcessManagementConstans.Accion.ProcesseSheet.SEARCH_PROCESSESHEET,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const dataProcesSheet = getState().processeSheetReducer.dataProcesSheet;
    setProcesSheet({
      dispatch: dispatch,
      isLoading: true,
      data: dataProcesSheet.data,
    });

    const criteria = {};
    const advancedFilter = getState().processeSheetReducer.advancedFilter;
    const filter = getState().processeSheetReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter.name?filter.name:null
    criteria.toDate = filter.to?filter.to:null;
    criteria.fromDate = filter.from?filter.from:null;
    // criteria.title = filter.title?filter.title:null;
    // criteria.customerId = filter.customers?.id?filter.customers.id:null;
    // criteria.projectId = filter.projects?.id?filter.projects.id:null;
    // criteria.sourceId = filter.taskSource?.id?filter.taskSource.id:null;
    // criteria.statusId = filter.taskStatus?.id?filter.taskStatus.id:null;

    // criteria.status = advancedFilter.status?advancedFilter.status:null

    ProcesseSheetServices.searchProcesseSheet(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setProcesSheet({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
        setProcesSheet({ dispatch: dispatch, isLoading: false, data: null });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setProcesSheet({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Ocupaciones', data.message));
        }
      },
      () => {
        setProcesSheet({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  };
};

const getProcessSheetById = (ProcessId) => {
  const setProcess = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ProcessManagementConstans.Accion.ProcesseSheet.GET_PROCESSESHEET_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setProcess({ dispatch: dispatch, isLoading: true });
    ProcesseSheetServices.getProcesseSheetById(ProcessId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setProcess({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setProcess({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Ficha Proceso', data.message));
        }else{
          setProcess({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.error('Ficha Proceso', data.message));
        }
      },
      () => {
        setProcess({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};

const getLastSheet = () => {
  const setLastSheet = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ProcessManagementConstans.Accion.ProcesseSheet.GET_LAST_SHEET,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setLastSheet({ dispatch: dispatch, isLoading: true });
    ProcesseSheetServices.getLastSheet().then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setLastSheet({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setLastSheet({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Ficha Proceso', data.message));
          // dispatch({
          //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
          //   toast: {
          //     titulo: 'Departamento',
          //     mensaje: data.message,
          //     severidad: 'warn',
          //   },
          // });
        }
      },
      () => {
        setLastSheet({ dispatch: dispatch, isLoading: false });
      }
    );
  };
};

const getProcessSheetByVersion = (ProcessId) => {
  const setProcessVersion = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ProcessManagementConstans.Accion.ProcesseSheet.GET_PROCESSESHEET_BY_ID,
      payload: { isLoading, data },
    });
  };


  return (dispatch, getState) => {
    setProcessVersion({ dispatch: dispatch, isLoading: true});
    ProcesseSheetServices.getProcesseSheetByVersion(ProcessId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setProcessVersion({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setProcessVersion({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Ficha Proceso', data.message));
        }else{
          setProcessVersion({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.error('Ficha Proceso', data.message));
        }
      },
      () => {
        setProcessVersion({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};

const getActiveSheet = () => {
  const setActiveSheet = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ProcessManagementConstans.Accion.ProcesseSheet.GET_ACTIVE_SHEET,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setActiveSheet({ dispatch: dispatch, isLoading: true });
    ProcesseSheetServices.getActiveSheet().then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setActiveSheet({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setActiveSheet({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Ficha Activa de Proceso', data.message));
          // dispatch({
          //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
          //   toast: {
          //     titulo: 'Departamento',
          //     mensaje: data.message,
          //     severidad: 'warn',
          //   },
          // });
        }
      },
      () => {
        setActiveSheet({ dispatch: dispatch, isLoading: false });
      }
    );
  };
};

const restoreProcesSheetById = () => {

  return (dispatch) => {
    dispatch({
      type: ProcessManagementConstans.Accion.ProcesseSheet.RESTORE_PROCESSHEET,
    });
  }
}

const saveProcessesSheet = (formulary) => {
  const isLoading = ({ dispatch, isSaving, isSaved}) => {
    dispatch({
      type: ProcessManagementConstans.Accion.ProcesseSheet.SAVE_PROCESSESHEET,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    // const currentFormulary = getState().processeSheetReducer.formularies;
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
    });
    ProcesseSheetServices.saveProcessesSheet(formulary).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Ficha', 'La ficha se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true });
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.error('Ficha', "No se puede guardar la configuración de la ficha porque su estado es distinto a borrador."));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }
        else
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });

      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    );
  };
};

const saveSheetAndSubmitWorkflow = (formulary) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentFormulary }) => {
    dispatch({
      type: ProcessManagementConstans.Accion.ProcesseSheet.SAVE_PROCESSESHEET,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    const currentFormulary = getState().processeSheetReducer.formularies;
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
     currentFormulary: currentFormulary,
    });
    ProcesseSheetServices.saveSheetAndSubmitWorkflow(formulary).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Formulario', 'El formulario se envió correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentFormulary, });
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL)
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentFormulary });
        else
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });

      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    );
  };
};

const generateVersion = (reason, currentId) => {
  const isLoading = ({ dispatch, isLoading, isSaving, isSaved, data }) => {
    dispatch({
      type: ProcessManagementConstans.Accion.ProcesseSheet.GENERATE_VERSION,
      payload: { isLoading, isSaving, isSaved, data },
    });
  };

  return (dispatch, getState) => {
    isLoading({ dispatch: dispatch, isSaving: true, isSaved: false});
    ProcesseSheetServices.generateVersion(reason).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Versión', 'La versión se generó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, data: data.data});
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Versión', data.message))
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }else{
          dispatch(toastAction.error('Versión', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }
      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};



const deleteProcesSheet = (procesSheetId) => {

  return (dispatch, getState) => {

    ProcesseSheetServices.deleteProcesseSheet(procesSheetId).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Ficha Proceso', 'La ficha de proceso se eliminó correctamente'));
          dispatch({ type: ProcessManagementConstans.Accion.ProcesseSheet.DELETE_PROCESSESHEET, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Ficha Proceso', data.message));
          dispatch({ type: ProcessManagementConstans.Accion.ProcesseSheet.DELETE_PROCESSESHEET, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: ProcessManagementConstans.Accion.ProcesseSheet.DELETE_PROCESSESHEET, payload: { isDeleted: false } });
      }
    );
  };
};

const generateReport = (payload) => {
  const setProcesSheet = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ProcessManagementConstans.Accion.ProcesseSheet.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setProcesSheet({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().processeSheetReducer.advancedFilter.filter;

    criteria.title = filter.title?filter.title:null;
    criteria.toDate = filter.to?filter.to:null;
    criteria.fromDate = filter.from?filter.from:null;

    criteria.isCompleted = false

    ProcesseSheetServices.generateReport(criteria)
      .then(({ data }) => {
        setProcesSheet({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Tareas.xls');
      })
      .catch((err) => {
        setProcesSheet({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Tareas', 'Hubo un error al descargar el archivo'));
      });
  };
};

const searchSummary = () => {
  const setSummary = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ProcessManagementConstans.Accion.ProcesseSheet.SEARCH_SUMMARY,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    ProcesseSheetServices.searchSummary().then(
      ({data}) => {
        if (data.status === CoreConstants.HttpResponse.OK){
          setSummary({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        }else if (data.status < CoreConstants.HttpResponse.OK){
          setSummary({ dispatch: dispatch, isLoading: false, data: null });
        }
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setSummary({ dispatch: dispatch, isLoading: false, data: null });
        }
      },
      () => {
        setSummary({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  }
}


const restoreSaved = () => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentFormulary }) => {
    dispatch({
      type:  ProcessManagementConstans.Accion.ProcesseSheet.SAVE_PROCESSESHEET,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };
  return (dispatch, getState) => {
    isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
  }
}


export const ProcesseSheetActions = {
  toggleAdvancedFilter,
  restoreProcesSheetById,
  updateFilter,
  search,
  saveProcessesSheet,
  deleteProcesSheet,
  generateReport,
  resetAfterSaved,
  getProcessSheetById,
  getProcessSheetByVersion,
  getOptionsDropDown,
  searchSummary,
  restoreSaved,
  getProducedBy,
  getActiveSheet,
  generateVersion,
  getListVersions,
  getLastSheet,
  saveSheetAndSubmitWorkflow,
}