import axios from 'axios';
import { CoreConstants } from 'core/commons/CoreConstants';
import { authAction } from 'core/store/actions/AuthAction';
import { toastAction } from 'core/store/actions/ToastAction';

const axiosMiddleware = (store) => {
  const { dispatch } = store;

  axios.interceptors.request.use(
    (req) => {
      const user = JSON.parse(localStorage.getItem('sig-session'));
      req.headers.Authorization = 'Bearer ' + user?.token;
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
  axios.interceptors.response.use(
    (res) => {
      if (res.data.status == CoreConstants.HttpResponse.REQUIRED_FIELDS) {
        dispatch(toastAction.warn(res.data.message, res.data.data.join(', ')));
      }

      if (res.data.status <= CoreConstants.HttpResponse.ERROR_TECHNICAL) {
        dispatch(
          toastAction.error(
            res.status + ' Se produjo un error técnico',
            'Código del error: ' + res.data.transactionId
          )
        );
      }

      return res;
    },
    (err) => {

      if(err.response.status===CoreConstants.HttpResponse.FORBIDDEN){
        if(err.response.data && err.response.data.status===CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Error de permisos', err.response.data.message));
        }
      }

      if( err.response.status === 401){
          dispatch(authAction.logout(true));
      }
      else{
        dispatch(
          toastAction.error(
            'Error',
            'Se produjo un error al intentar conectarse con el servidor'
          )
        );
      }
      return Promise.reject(err);
    }
  );
};
export default axiosMiddleware;
