import { MaintenanceConstants } from "features/maintenance/commons/MaintenanceConstans";
const initialState = {
 siteFilter:{
   showModal:false,
   values:[]
 },
 sites:{
  currentData: [],
  data: [],
  loading: false,
  pagination: {},
  currentCol: 'firstName',
  order: 'asc',
 },
 site:{
   dataSite:{},
   loadingSaveOrEdit:false,
   isSave:false,
 },
 dataMaster:{
   status:[],
   loadingDataMaster:true,
 },
 report:{
   loadingReport : false
 }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case MaintenanceConstants.Accion.Sites.SEARCH_SITES:
      return {
        ...state,
        sites: {
          ...state,
          ...action.payload,
        },
      };
      case MaintenanceConstants.Accion.Sites.ADVANCED_FILTER:
        return {
          ...state,
          siteFilter: {
            ...state.siteFilter,
            ...action.payload,
          },
  
        };
      case MaintenanceConstants.Accion.Sites.SAVE_SITE:
        return {
          ...state,
          site: {
            ...state,
            ...action.payload,
          },
  
        };
        case MaintenanceConstants.Accion.Sites.DATA_MASTER:
          return {
            ...state,
            dataMaster: {
              ...state,
              ...action.payload,
            },
    
          };

          case MaintenanceConstants.Accion.Sites.GET_REPORT:{
            return {
              ...state,
              report:{
                ...state,
                ...action.payload
              }
            }
          }
      default:
        return state;
  }
};
