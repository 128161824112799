import { Controller } from "react-hook-form"
import { HeaderSheet } from "./HeaderSheet"
import { DataTable } from "primereact/datatable"
import { useState } from "react"
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

export const TableSheet = ({question, control, numberQuestion, readOnly, totalRows}) => {
  const [columns] = useState(() => question.columns.map((col, index) => ({field: col.header, header: col.header, id: col.id, colNumber: index + 1})));
  const [value, setValue] = useState(() => {
    if(!question.firstColumnFixed){
      if(totalRows){
        const data = [];
        for(let i = 0; i < totalRows; i++){
          const prevRow = {id: i, rowNumber: i+1}
          question.columns.forEach((col, index) => {
            prevRow[col.header] = null;
            prevRow['colNumber'] = index + 1
          })
          data.push(prevRow)
        }
        return data
      } else if(question.addRowsAllowed) {
        const data = [];
        const prevRow = {id: -1, rowNumber: 1}
        question.columns.forEach((col, index) => {
          prevRow[col.header] = null;
          prevRow['colNumber'] = index + 1
        })
        data.push(prevRow)
        return data
      }
      else {
        const data = [];
        question.rows.forEach((row, index) => {
          const prevRow = {id: row.id, rowNumber: index+1}
          question.columns.forEach((col, index) => {
            prevRow[col.header] = null;
            prevRow['colNumber'] = index + 1
          })
          data.push(prevRow)
        });
        return data
      }
    }
    const data = [];
    question.rows.forEach((row, index) => {
      const prevRow = {id: row.id, rowNumber: index + 1}
      question.columns.forEach((col, index) => {
        if(index === 0)prevRow[col.header] = row.label;
        else prevRow[col.header] = null;
        prevRow['colNumber'] = index + 1
      })
      data.push(prevRow)
    });
    return data
  })

  const body = (index, col) => (data) => {
    if(!question.firstColumnFixed || index > 0){
      return (
        <Controller
          control={control}
          rules={{ required: {
            value: question.isRequired,
            message: `La celda de la Fila ${data.rowNumber} y de la Columna ${col.colNumber} pregunta ${numberQuestion} es Requerida`
          }}}
          render={({
              field: { onChange, value },
              fieldState: { error },
          }) => (
            <div className='w-full flex flex-col items-start gap-1'>
              <InputText
                value={value || ""} 
                onChange={onChange} 
                className={`w-full ${error ? 'p-invalid' : ''}`}
                readOnly={readOnly}
              />
            </div>
            )}
          name={`question_${question.id}_table_${data.rowNumber}_${col.colNumber}`}
        />
      )
    }
    return <strong>{data[col.field]}</strong>
  }
  
  const addRow = () => {
    setValue( prevValue => {
      const newId = Math.min( ...prevValue.map(val => val.id) || [], 0) - 1
      const newRowNumber = Math.max( ...prevValue.map(val => val.rowNumber) || [], 0) + 1
      const newValue = {
        id: newId,
        rowNumber: newRowNumber,
      }
      question.columns.forEach((col, index) => {
        newValue[col.header] = null;
        newValue['colNumber'] = index + 1
      })
      return [...prevValue, newValue]
    })
  }

  const removeRow = () => {
    setValue(prevValue => {
      const newValue = prevValue.filter((value, index) => index !== prevValue.length-1)
      return newValue
    })
  }

  return(
    <div className='py-4 px-4 bg-gray-100 shadow border-2 flex flex-col page-break' key={question.id}>
      <HeaderSheet numberQuestion={numberQuestion} question={question}/>
      <div>
        {
          question.addRowsAllowed &&
          <>
            <Button
              type="button"
              label='Añadir Fila'
              icon="pi pi-plus"
              className="sig-button sig-primary my-2 button-print"
              onClick={addRow}
            />

            {
              value.length > 1 &&
              <Button
                type="button"
                label='Eliminar Fila'
                icon="pi pi-trash"
                className="sig-button sig-primary my-2 ml-2 button-print"
                onClick={removeRow}
              />
            }
          </>
        }
        <DataTable
          size="small"
          value={value}
        >
          {
            columns.map((col, index) => <Column key={col.field} field={col.field} header={<strong>{col.header}</strong>} body={body(index, col)}/>)
          }
        </DataTable>
      </div>
    </div>
  )
}