import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const useCourseMonitoring = () => {
  let defaultValues = {
    code: '',
    name: '',
  };

  const { setValue, handleSubmit, control, watch, formState: { errors } } = useForm({ defaultValues });

  const valuesFields = watch();
  const existError = Object.keys(errors).length > 0

  const {data} = useSelector((state) => state.courseMonitoringReducer.course);

  const isPositiveInteger = (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
        return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  }

  const getEvaluationRange = (stringRange) => {
    const rangeSplit = stringRange.split('-')
    const range = {
      min: Number(rangeSplit[0].trim()),
      max: Number(rangeSplit[1].trim()),
    }
    return range
  }

  // const arrayEvaluationId = (evaluations, rowData) => {
  //   const listEvaluationId = evaluations.map((evaluation, index) => {
  //     const evaluationId = {
  //       courseSessionId: evaluation.CourseSessionId,
  //       evaluation: rowData[`evaluation${index+1}`]
  //     }
  //     return evaluationId
  //   })

  //   return listEvaluationId
  // }
  
  return {
    control,
    errors,
    valuesFields,
    existError,
    data,
    setValue,
    handleSubmit,
    isPositiveInteger,
    getEvaluationRange
  }
}

export default useCourseMonitoring