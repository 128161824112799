import { FilterPaginationConstants } from "shared/commons/FilterPaginationConstants";

const initialState = {
  advancedFilter: {
    showModal: false,
    values: [],
    loading: false,
    order: 'asc',
    pagination: {
      currentPage: 1,
      pageSize: 10,
      rowCount: 0,
    },
    columnOrder: null,
    search: false,
    download: false,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action ) => {
  switch (action.type) {
    case FilterPaginationConstants.Accion.FilterPagination.CHANGE_PAGE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          pagination: {
            ...state.advancedFilter.pagination,
            ...action.payload,
          }
        },
      };

    case FilterPaginationConstants.Accion.FilterPagination.CHANGE_SORT:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload,
        },
      };
    
    case FilterPaginationConstants.Accion.FilterPagination.RESET_REDUCER:
      return initialState

    default:
      return state;
  }
}