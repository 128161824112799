export const RightArrowSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={59}
    viewBox="0 0 71 156"
    {...props}
  >
    <path d="M36 19.8V39H2v79h34l.2 19.1.3 19.1 16.8-38.3C62.5 96.8 70 79.1 70 78.6c0-.8-32.9-76.7-33.6-77.5-.2-.2-.4 8.2-.4 18.7z" />
  </svg>
)
