import { ProjectConstants } from 'features/customers/commons/ProjectConstants';

const initialState = {
  project: {
    data: [],
    loading: false,
    order: 'asc',
    pagination: {},
    columnOrder: 'name',
  },
  actions: {
    loading: false,
    items: [
      { name: 'Editar', icon: 'pi pi-pencil' },
      { name: 'Eliminar', icon: 'pi pi-trash' },
    ],
  },
  advancedFilter: {
    showModal: false,
    values: [],
  },
  downloadReport: {
    loading: false,
  },
  currentAction: {
    isOpen: false,
    customerId: null,
    projectId: null,
  },
  editProject: {
    isLoading: false,
    isLoadingProject: false,
    data: {},
    status: null,
    errors: [],
  },
  dataMaster: {
    isLoadingDataMaster: false,
    data: {
      status: [],
      situation: [],
      responsibles: [],
      businessList: [],
      roles: [],
      companyCustomers: []
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ProjectConstants.Action.Project.ADVANCED_FILTER:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload,
        },
      };
    case ProjectConstants.Action.Project.SEARCH_PROJECT:
      return {
        ...state,
        project: {
          ...state.project,
          ...action.payload,
        },
      };
    case ProjectConstants.Action.Project.DATA_MASTER:
      return {
        ...state,
        dataMaster: {
          ...state.dataMaster,
          ...action.payload,
        },
      };
    case ProjectConstants.Action.Project.CURRENT_ACTION:
      return {
        ...state,
        currentAction: {
          ...state.currentAction,
          ...action.payload,
        },
      };
    case ProjectConstants.Action.Project.EDIT_PROJECT:
      return {
        ...state,
        editProject: {
          ...state.editProject,
          ...action.payload,
        },
      };
    case ProjectConstants.Action.Project.DOWNLOAD_REPORT_STATUS:
      return {
        ...state,
        downloadReport: {
          loading: action.payload,
        },
      };
    default:
      return state;
  }
};