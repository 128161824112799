import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import AdvancedFilterComponent from 'features/riskManagement/components/ModelManagement/AdvancedFilterComponent';
import FilterComponent from 'features/riskManagement/components/ModelManagement/FilterComponent';
import TableComponent from 'features/riskManagement/components/ModelManagement/TableComponent';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { useSelector } from 'react-redux';
import { useClaimModels } from 'features/riskManagement/hooks/useClaimModels';

const ModelManagementPage = () => {

	const { showModal } = useSelector((state) => state.riskModelReducer.advancedFilter);
	const history = useHistory();

	const { updateTitle } = useSetTitlePage();

	const { isCreate, isDownload, isSearch } = useClaimModels();

	useEffect(()=>{
		updateTitle({
			title: "Gestión de Riesgos",
			subtitle: "Modelo de Gestión",
		})
	},[])

	return (
		<div>
			{
				(isSearch || isDownload)&&
					<FilterComponent />
			}
			{
				isCreate&&
				<div className="flex items-center justify-between p-4">
					<Button
						icon="pi pi-plus"
						type="button"
						label="Nuevo"
						className="sig-button sig-dark pointer"
						onClick={()=>history.push('/gestion-riesgos/modelo/nuevo')} 
					/>
				</div>
			}
			<TableComponent />
			<AdvancedFilterComponent /> 
		</div>
	);
};

export default ModelManagementPage;