import { Fragment } from 'react';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useAdvancedFilterTasks } from 'features/customers/hooks/tasks/useAdvancedFilterTasks';
import PinerComponent from 'shared/components/Piner/PinerComponent';

export const FilterComponent = () => {
  const { tasksLoading, reportLoading, formValues, handleChange, showPiners, piners, onToggleModal, removePiner, onAdvancedFilter, generateReport } = useAdvancedFilterTasks(true);

  return (
    <Fragment>
      <Panel header="FILTRO POR" toggleable>
        <form className="filter-roles" onSubmit={onAdvancedFilter}>
          <div className="w-full">
            <span className="p-float-label p-input-icon-left w-full">
              <i className="pi pi-search" />
              <InputText
                id="input-search"
                name="title"
                value={formValues?.title ? formValues?.title : ''}
                onChange={handleChange}
              />
              <label htmlFor="input-search">Buscar por título de la tarea</label>
            </span>
            {showPiners && (
              <div className="filter-piners mt-3">
                {piners.map(({ value, field }, index) => (
                  <PinerComponent
                    name={value}
                    field={field}
                    removePiner={removePiner}
                    key={index}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="filter-users-action">
            <div className="flex gap-3 w-full">
              <Button
                icon="pi pi-search"
                type="submit"
                label="Buscar"
                loading={tasksLoading || reportLoading}
                className="sig-button sig-primary w-full"
              />
              <Button
                icon="pi pi-download"
                type="button"
                label="Descargar"
                className="sig-button sig-success w-full"
                loading={tasksLoading || reportLoading}
                onClick={generateReport}
              />
            </div>
            <div className="flex mt-3">
              <Button
                type="button"
                label="Búsqueda avanzada"
                className="sig-button  sig-secondary w-full"
                onClick={() => onToggleModal(true)}
              />
            </div>
          </div>
        </form>
      </Panel>
    </Fragment>
  )
}