export class IndicatorsConstants {
  static Accion = class {

    //INDICATORS CONSTANTS
    static Indicators = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return '@INDICATORS.ADVANCED-FILTER-TOGGLE';
      }

      static get SEARCH_INDICATORS() {
        return '@INDICATORS.SEARCH_INDICATORS';
      }

      static get SAVE_INDICATOR() {
        return '@INDICATORS.SAVE_INDICATOR';
      }

      static get UPDATE_FILTER() {
        return '@INDICATORS.UPDATE_FILTER';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@INDICATORS.DOWNLOAD_REPORT_STATUS';
      }

      static get GET_DATA_MASTER() {
        return '@INDICATORS.GET_DATA_MASTER';
      }

      static get GET_INDICATOR_BY_ID() {
        return '@INDICATORS.GET_INDICATOR_BY_ID';
      }

      static get DELETE_INDICATOR() {
        return '@INDICATORS.DELETE_INDICATOR';
      }

      static get RESET_AFTER_SAVED() {
        return '@INDICATORS.RESET_AFTER_SAVED';
      }

      static get GET_UNIT_MEASURE() {
        return '@INDICATORS.GET_UNIT_MEASURE';
      }

      static get GET_VERSIONS() {
        return '@INDICATORS.GET_VERSIONS';
      }

      static get GET_INDICATOR_BY_VERSION() {
        return '@INDICATORS.GET_INDICATOR_BY_VERSION';
      }

      static get SAVE_AND_GENERATE_VERSION() {
        return '@INDICATORS.SAVE_AND_GENERATE_VERSION';
      }

      static get DOWNLOAD_TEMPLATE() {
        return '@INDICATORS.DOWNLOAD_TEMPLATE';
      }

      static get UPLOAD_BATCH_INFO() {
        return '@INDICATORS.UPLOAD_BATCH_INFO';
      }

      static get SAVE_INDICATOR_BULK() {
        return '@INDICATORS.SAVE_INDICATOR_BULK';
      }

      static get COPY_INDICATOR() {
        return '@INDICATORS.COPY_INDICATOR';
      }
      static get SAVE_INDICATOR_VALUES() {
        return '@INDICATORS.SAVE_INDICATOR_VALUES';
      }
      
    };

    //MONITORING CONSTANTS
    static Monitoring = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return '@MONITORING.ADVANCED-FILTER-TOGGLE';
      }

      static get SEARCH_MONITORING() {
        return '@MONITORING.SEARCH_MONITORING';
      }

      static get SAVE_MONITORING() {
        return '@MONITORING.SAVE_MONITORING';
      }

      static get UPDATE_FILTER() {
        return '@MONITORING.UPDATE_FILTER';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@MONITORING.DOWNLOAD_REPORT_STATUS';
      }

      static get GET_DATA_MASTER() {
        return '@MONITORING.GET_DATA_MASTER';
      }

      static get GET_MONITORING_BY_ID() {
        return '@MONITORING.GET_MONITORING_BY_ID';
      }

      static get DELETE_MONITORING() {
        return '@MONITORING.DELETE_MONITORING';
      }

      static get RESET_AFTER_SAVED() {
        return '@MONITORING.RESET_AFTER_SAVED';
      }

      static get GET_VALUES_INDICATOR_BY_ID() {
        return '@MONITORING.GET_VALUES_INDICATOR_BY_ID';
      }

      static get GET_GOAL() {
        return '@MONITORING.GET_GOAL';
      }

      static get SAVE_INDICATOR_VALUES() {
        return '@MONITORING.SAVE_INDICATOR_VALUES';
      }

      static get GET_VALUES_INDICATOR_BY_ID_AND_RANGE() {
        return '@MONITORING.GET_VALUES_INDICATOR_BY_ID_AND_RANGE';
      }

      static get GET_COMMENTS_BY_INDICATORID() {
        return '@MONITORING.GET_COMMENTS_BY_INDICATORID';
      }
      
      static get ADD_COMMENT() {
        return '@MONITORING.ADD_COMMENT';
      }

      static get DELETE_COMMENT() {
        return '@MONITORING.DELETE_COMMENT';
      }
      static get GET_INDICATOR_ACTION_PLAN_BY_ID() {
        return '@MONITORING.GET_INDICATOR_ACTION_PLAN_BY_ID';
      }
      static get SAVE_INDICATOR_ACTION_PLAN() {
        return '@MONITORING.SAVE_INDICATOR_ACTION_PLAN';
      }

      static get DOWNLOAD_FOLLOW_UP_REPORT() {
        return '@MONITORING.DOWNLOAD_FOLLOW_UP_REPORT';
      }
    };

    static Managment = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return "@MANAGMENT.ADVANCED-FILTER-TOGGLE";
      }

      static get SEARCH_MANAGMENTS() {
        return "@MANAGMENT.SEARCH_MANAGMENTS";
      }

      static get RESET_DATA_INDICATOR() {
        return "@MANAGMENT.RESET_DATA_INDICATOR";
      }

      static get UPDATE_FILTER() {
        return "@MANAGMENT.UPDATE_FILTER";
      }

      static get GET_MANAGMENT_BY_ID() {
        return "@MANAGMENT.GET_MANAGMENT_BY_ID";
      }

      static get GET_MANAGMENT_GRAPHICS_BY_ID() {
        return "@MANAGMENT.GET_MANAGMENT_GRAPHICS_BY_ID";
      }

      static get GET_MANAGMENT_TABLE_BY_ID() {
        return "@MANAGMENT.GET_MANAGMENT_TABLE_BY_ID";
      }

      static get SAVE_MANAGMENT() {
        return "@MANAGMENT.SAVE_MANAGMENT";
      }

      static get DELETE_MANAGMENT() {
        return "@MANAGMENT.DELETE_MANAGMENT";
      }

      static get GET_DATA_MASTER() {
        return "@MANAGMENT.GET_DATA_MASTER";
      }

      static get RESET_AFTER_SAVED() {
        return "@MANAGMENT.RESET_AFTER_SAVED";
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return "@MANAGMENT.DOWNLOAD_REPORT_STATUS";
      }

      static get GET_DATAMASTER() {
        return "@MANAGMENT.GET_DATAMASTER";
      }
    };
  };
  static Constans = class {
    static Indicators = class {
      static get STATUS_CREATE(){
        return "INCR";
      }
      static get SOURCE_NONE(){
        return "INON";
      }
    }
  }
  static SectionsName = class {
    static get ORIGEN() {
      return 'ORIGEN';
    }
    static get DATOS_DEL_INDICADOR() {
      return 'DATOS DEL INDICADOR';
    }
    static get META() {
      return 'META';
    }
  }
}
