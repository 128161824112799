import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import  { useForm } from 'shared/hooks/useForm';
import { useHistory } from 'react-router';
import moment from 'moment';

import { ModulesActions } from 'features/subscription/store/actions/ModulesIsoActions';

const useModuleIso = (title) => {
  
  const dispatch = useDispatch();
  const { loadingDataMaster , dataMaster } = useSelector((state) => state.modulesIsoReducer.dataMaster);
  const { dataModules , pagination } = useSelector((state) => state.modulesIsoReducer.modules);
  const history = useHistory();
  const {loadingEdit , isSave , dataModule} = useSelector((state) => state.modulesIsoReducer.moduleEdit);  
  const [isActive, setIsActive] = useState(true);
  const [visible, setVisible] = useState(false);
  const [disabledButtonState, setDisabledButtonState] = useState(false);
  const [errors,setErrors] = useState({});
  const [form, handleInputChange, reset] = useForm();

  const [payload, setPayload] = useState({
    id: null,
    Name: null,
    fromDate: null,
    toDate: null,
    rowStatus: true    
  })
  const params = useParams();
  useEffect(() =>{
    dispatch(ModulesActions.getDataMaster(title,params.id))
  },[]);

  useEffect(() =>{
    if(title !== undefined && title.title === "Nuevo Modulo"){
      dispatch(ModulesActions.setModuleEdit());
    }
  },[]);

  const accept = () => {
    history.push('/suscripcion/modulo_iso');
  };

  const reject = () => {
    setVisible(false);
  };

  const createOrEditModule = (data, titleView) =>{
    if(titleView === "Editar Modulo"){
      setDates()
    }else{
      setDates(data)
    }
    
    let infoData = {...payload,...data}
    dispatch(ModulesActions.saveModule(infoData));
  }
  
  const clearSave = () =>{
    dispatch(ModulesActions.setModuleEdit({isSave:false}));
  }

  const deleteModuleId = (id) => {
    dispatch(ModulesActions.deleteModuleId(id));
  }

  const setDates = (data = payload ) =>{
    Object.entries(data).forEach(([key,value]) =>{
      if(typeof value === "object" && value !== null){
        if(!Array.isArray(value)){
          let newPayload = payload;
          newPayload[key] = moment.parseZone(value).utc().format();
          setPayload({...newPayload});
        }
      }
    })
  }

  
  return {
    loadingDataMaster , dataMaster,
    dataModules , pagination,
    loadingEdit , isSave , dataModule,
    isActive, setIsActive,
    visible, setVisible,
    disabledButtonState, setDisabledButtonState,
    errors,setErrors,
    form, handleInputChange, reset,
    accept, reject,
    createOrEditModule,
    clearSave,    
    deleteModuleId,
    setDates
  };
};

export default useModuleIso;