import { useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import { SitesAction } from '../../store/actions/SitesAction';
const useStatus = () =>{
  const { loadingDataMaster , status } = useSelector((state) => state.sitesReducer.dataMaster )
  const dispatch = useDispatch();
  useEffect(() =>{
    dispatch(SitesAction.getDataMaster())
  },[])
  return {
    loadingDataMaster,
    status
  } 
}

export default useStatus