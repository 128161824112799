import { Link } from "react-router-dom";

export const TableCard = ({
  id,
  companyName,
  createdBy,
  creationDate,
  entityName,
  status,
  selectedRow,
  isView,
  isEdit,
  isDelete,
}) => {
  return (
    <div
      className="block p-4 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
    >
      <div className="flex justify-between">
        {
          isView?(
            <Link
              className="underline text-blue-700"
              to={`/configuracion/campos-dinamicos/view/${id}`} >{entityName}</Link>
          ):(
          <h5 className="mb-1 text-md font-bold text-gray-900 dark:text-white">
            {entityName}
          </h5>
          )
        }
        {
          (isEdit||isDelete)&&(
            <i
              className="pi pi-ellipsis-v cursor-pointer"
              onClick={(event) => selectedRow(event, { id })}
            ></i>
          )
        }
      </div>
      <hr />
      <div className="grid grid-cols-2 mt-3 text-gray-700 dark:text-gray-400">
        <div>
          <p className="text-xs">Cliente:</p>
          <p className="text-xs">{companyName}</p>
        </div>
        <div>
          <p className="text-xs">Estado:</p>
          <p className="text-xs">{status}</p>
        </div>
      </div>
      <div className="grid text-gray-700 dark:text-gray-400 mt-3">
        <div>
          <p className="text-xs">Creado por:</p>
          <p className="text-xs">{createdBy}</p>
          
        </div>
      </div>
      <div className="grid grid-cols-1 text-gray-700 dark:text-gray-400 mt-3">
        <div>
          <p className="text-xs">Fecha de Creación:</p>
          <p className="text-xs">
            {creationDate}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TableCard;
