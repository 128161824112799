import { MaintenanceConstants } from "features/maintenance/commons/MaintenanceConstans"; 
import { CoreConstants } from 'core/commons/CoreConstants';
import moment from 'moment';
import { toastAction } from "core/store/actions/ToastAction";
import FileSaver from 'file-saver';
import { EmployersService } from "features/maintenance/services/EmployerService";


const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: MaintenanceConstants.Accion.Employer.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: MaintenanceConstants.Accion.Employer.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.name)
      pines.push({ field: 'name', value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });


    advancedFilter.values = pines;
    dispatch({
      type: MaintenanceConstants.Accion.Employer.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setEmployers = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Employer.SEARCH_EMPLOYERS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const employers = getState().employerReducer.employers;
    setEmployers({
      dispatch: dispatch,
      isLoading: true,
      data: employers.data,
    });

    const criteria = {};
    const advancedFilter = getState().employerReducer.advancedFilter;
    const filter = getState().employerReducer.advancedFilter.filter;
    
    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;
    
    EmployersService.searchEmployers(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setEmployers({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });          
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Empleador', data.message));
          setEmployers({ dispatch: dispatch, isLoading: false, data: null });
        }else{
          setEmployers({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Empleador', data.message));
        }
      },
      // () => {
      //   setEmployers({ dispatch: dispatch, isLoading: false, data: null });
      // }
    ).catch((error)=>{
    });
  };
};

const getEmployerById = (employerId) => {
  const setEmployer = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Employer.GET_EMPLOYER_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setEmployer({ dispatch: dispatch, isLoading: true });
    EmployersService.getEmployerById(employerId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setEmployer({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setEmployer({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Empleador', data.message));
        }else{
          setEmployer({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.error('Empleador', data.message));
        }
      },
      () => {
        setEmployer({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};

const saveEmployer = (employer) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentEmployer }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Employer.SAVE_EMPLOYER,
      payload: {
        isSaving,
        isSaved,
        // currentEmployer
      },
    });
  };

  return (dispatch, getState) => {
    const currentEmployer = getState().employerReducer.employer;

    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
      // currentEmployer,
    });
    EmployersService.saveEmployers(employer).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Empleador', 'El empleador se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentEmployer });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Empleador', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentEmployer });
        }else{
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
          dispatch(toastAction.error('Empleador', data.message));
        }

      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};



const deleteEmployer = (employerId) => {

  return (dispatch, getState) => {

    EmployersService.deleteEmployer(employerId).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Empleador', 'El empleador se eliminó correctamente'));
          dispatch({ type: MaintenanceConstants.Accion.Employer.DELETE_EMPLOYER, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Empleador', data.message));
          dispatch({ type: MaintenanceConstants.Accion.Employer.DELETE_EMPLOYER, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: MaintenanceConstants.Accion.Employer.DELETE_EMPLOYER, payload: { isDeleted: false } });
      }
    );
  };
};

const generateReport = (payload) => {
  const setEmployers = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Employer.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setEmployers({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().employerReducer.advancedFilter.filter;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    EmployersService.generateReport(criteria)
      .then(({ data }) => {
        setEmployers({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Empleador.xls');
      })
      .catch((err) => {
        setEmployers({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Empleador', 'Hubo un error al descargar el archivo'));
      });
  };
};

export const EmployerAction = {
  toggleAdvancedFilter,
  updateFilter,
  search,
  saveEmployer,
  deleteEmployer,
  generateReport,
  resetAfterSaved,
  getEmployerById
}