const columns = [
  { field: 'Name', header: 'Entregable' },
  { field: 'BeginDate', header: 'Fecha de Inicio'},
  { field: 'EndDate', header: 'Fecha de Fin' },
  { field: 'Progress', header: '% Avance' },
  // { field: 'responsibleName', header: 'Inicio' },
  // { field: 'team', header: 'Fin' },
  // { field: 'progress', header: 'Responsable' },
  // { field: 'startDate', header: 'Avance' },
  // { field: 'statusName', header: 'Estado' }
];

const deliverables = [
  // { id: '1', name:'', customerName: 'Inicio', statusName: '', responsibleName: '', team: '', progress: '', startDate: '', endDate: '', tasks:[
  //   { id: '1', name:'Asesoria y Capacitación ISO 9001, ISO 37001', customerName: 'PHARMA HOSTING PERÚ', statusName: 'En Progreso', responsibleName: 'Estela Carmona Molina', team: '', progress: '90.18%', startDate: '2021-10-01', endDate: '2022-02-15'},
  //   { id: '2', name:'Mantenimiento ISO 9001:2015', customerName: 'CALIDAR', statusName: 'En Planificación', responsibleName: 'Estela Carmona Molina', team: '', progress: '40.00%', startDate: '2021-10-01', endDate: '2022-12-31'},
  //   { id: '3', name:'Implementación OEA', customerName: 'HENKEL PERUANA', statusName: 'En Progreso', responsibleName: 'Estela Carmona Molina', team: '', progress: '65.91%', startDate: '2021-01-30', endDate: '2022-12-31'},
  // ]
  //  },
  // { id: '2', name:'', customerName: 'Capacitación Interpretación de Requisitos', statusName: '', responsibleName: '', team: '', progress: '', startDate: '', endDate: '' , tasks:[
  //   { id: '1', name:'Asesoria y Capacitación ISO 9001, ISO 37001', customerName: 'PHARMA HOSTING PERÚ', statusName: 'En Progreso', responsibleName: 'Estela Carmona Molina', team: '', progress: '90.18%', startDate: '2021-10-01', endDate: '2022-02-15'},
  //   { id: '2', name:'Mantenimiento ISO 9001:2015', customerName: 'CALIDAR', statusName: 'En Planificación', responsibleName: 'Estela Carmona Molina', team: '', progress: '40.00%', startDate: '2021-10-01', endDate: '2022-12-31'},
  //   { id: '3', name:'Implementación OEA', customerName: 'HENKEL PERUANA', statusName: 'En Progreso', responsibleName: 'Estela Carmona Molina', team: '', progress: '65.91%', startDate: '2021-01-30', endDate: '2022-12-31'},
  // ]
  // },
  // { id: '3', name:'', customerName: 'Asesoría', statusName: '', responsibleName: '', team: '', progress: '', startDate: '', endDate: '',tasks:[
  //   { id: '1', name:'Asesoria y Capacitación ISO 9001, ISO 37001', customerName: 'PHARMA HOSTING PERÚ', statusName: 'En Progreso', responsibleName: 'Estela Carmona Molina', team: '', progress: '90.18%', startDate: '2021-10-01', endDate: '2022-02-15'},
  //   { id: '2', name:'Mantenimiento ISO 9001:2015', customerName: 'CALIDAR', statusName: 'En Planificación', responsibleName: 'Estela Carmona Molina', team: '', progress: '40.00%', startDate: '2021-10-01', endDate: '2022-12-31'},
  //   { id: '3', name:'Implementación OEA', customerName: 'HENKEL PERUANA', statusName: 'En Progreso', responsibleName: 'Estela Carmona Molina', team: '', progress: '65.91%', startDate: '2021-01-30', endDate: '2022-12-31'},
  // ]
  // },
]

export const TasksViewTableData = {
  columns,
  // deliverables,
};
