import { RrhhConstants } from "features/rrhh/commons/RrhhConstants";

const initialState = {
  getLastFilePeople: {
    data: null,
    isLoading: false
  },
  generateVersion: {
    isLoading: false,
    isSaved: false
  },
  listVersions:{
    data: [],
    isLoading: false
  },
  listControls: {
    data: null
  },
  listResponsibles: {
    data: []
  },
  filePeople: {
    data: {},
    isSaved: false,
    isSaving: false,
    isDeleted: false,
    isLoading: false
  },
  versionList: {
    data: [],
    isLoading: false
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RrhhConstants.Accion.CustomField.GET_LAST_FILE_PEOPLE:
      return {
        ...state,
        getLastFilePeople: {
          data: action.payload?.sheet,
          isLoading: action.payload?.isLoading
        },
        listVersions: {
          data: action.payload?.versions,
          isLoading: action.payload?.isLoading
        }
      }
      case RrhhConstants.Accion.CustomField.GENERATE_VERSION:
        return {
          ...state,
          generateVersion: {
            isLoading: action.payload?.isLoading,
            isSaved: action.payload?.isSaved
          },
        };
    case RrhhConstants.Accion.CustomField.GET_FILE_PEOPLE_BY_ID:
          return {
            ...state,
            getLastFilePeople: {
              data: action.payload?.data,
              isLoading: action.payload?.isLoading
            }
          }
    case RrhhConstants.Accion.CustomField.GET_VERSION_LIST:
        return {
          ...state,
          versionList: {
            data: action.payload?.data,
            isLoading: action.payload?.isLoading
          }
        }
    case RrhhConstants.Accion.CustomField.SAVE_FILE_PEOPLE:
        return {
          ...state,
          filePeople: {
            ...state.isDeleted,
            ...state.isLoading,
            isSaving: action.payload?.isSaving,
            isSaved: action.payload?.isSaved,
            data: {}
          },
  
        };
    case RrhhConstants.Accion.CustomField.LIST_RESPONSIBLES:
        return {
          ...state,
          listResponsibles: action.payload
        }
    case RrhhConstants.Accion.CustomField.LIST_CONTROLS:
        return {
          ...state,
          listControls: {
            data: action.payload
        }
      }
      default:
      return state;
  }
};