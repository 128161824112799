import { MaintenanceConstants } from "features/maintenance/commons/MaintenanceConstans";
import { SitesService } from "features/maintenance/services/SitesService";
import { CoreConstants } from "core/commons/CoreConstants";
import { toastAction } from 'core/store/actions/ToastAction';
import FileSaver from 'file-saver';
import moment from 'moment';

const toggleModalFilters = (payload) => {
  return (dispatch) =>
    dispatch({
      type: MaintenanceConstants.Accion.Sites.ADVANCED_FILTER,
      payload,
    });
};

const searchSites = (valueSearch) =>{
  return (dispatch, getState) => {
    let { values } = getState().sitesReducer.siteFilter;
    let { status } = getState().sitesReducer.dataMaster;
    const fields = getFilterValues(values);
    fields.name = valueSearch.name;
    const hasValue = Object.values(valueSearch).length > 0;
    let newValues = [];
    if (values.length === 0 && hasValue) {
      newValues = [{ field: 'name', value: valueSearch.name }];
    } else if (values.length > 0 && hasValue) {
      const currentValues = values.filter(({ field }) => field !== 'name');
      newValues = [
        { field: 'name', value: valueSearch.name },
        ...currentValues,
      ];
    } else if (values.length > 0 && !hasValue) {
      newValues = [...values];
    }
    dispatch({
      type: MaintenanceConstants.Accion.Sites.ADVANCED_FILTER,
      payload: { values: newValues },
    });
    dispatch(SitesAction.getSites(fields));
    dispatch(setDataMaster({loadingDataMaster:false,status}))
  };
}
const searchSitesAdvanced = (payLoad) =>{
  return (dispatch, getState) => {
    let { status } = getState().sitesReducer.dataMaster;
    
    dispatch(setDataMaster({loadingDataMaster:true,status}))
    const {fields , newValues} = getFilterValuesAdvanced(payLoad);

   
    dispatch({
      type: MaintenanceConstants.Accion.Sites.ADVANCED_FILTER,
      payload: { values:  newValues},
    });
    dispatch(SitesAction.toggleModalFilters({showModal:false}))
    dispatch(SitesAction.getSites(fields));
    dispatch(setDataMaster({loadingDataMaster:false,status}))
  };
}

const searchTable = ({page}) =>{
  return (dispatch,getState) =>{
    let { values } = getState().sitesReducer.siteFilter;
    const {fields } = getFilterValuesAdvanced(values);
    dispatch(getSites({...fields,page}))
  }
}

const setReport = (payload) => {
  return (dispatch) =>
    dispatch({
      type: MaintenanceConstants.Accion.Sites.GET_REPORT,
      payload,
    });
};

const generateReport = (fields) =>{
  let new_fields = getFilterValues(fields);
  new_fields = {
    name:new_fields.name ?? null,
    fromDate:new_fields.fromDate ?? null,
    toDate:new_fields.toDate ?? null,
    statusId:new_fields.statusId ?? null
  }
  return (dispatch) => {
    dispatch(setReport({ loadingReport: true }));
    SitesService.generateReport(new_fields).then(({data }) => {
      let objFields = {
        loadingReport: false,
      };
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      });
      FileSaver.saveAs(blob, 'Sedes.xls');
      dispatch(setReport(objFields));
    });
  };
}
const getFilterValues = (newValues) => {
  const newFilterValues = {};

  newValues.forEach((val) => {
    const field = val.field.toLocaleLowerCase();
    if (field.includes('date')) newFilterValues[val.field] = val.date;
    else if (field.includes('id') && field !== 'statusid')
      newFilterValues[val.field] = val.option.id;
    else if (field === 'statusId') newFilterValues[val.field] = val.optionId;
    else if (field === 'name') newFilterValues[val.field] = val.value;
  });
  return newFilterValues;
};

const getFilterValuesAdvanced = (fields) =>{
  let newValues = [];
  if(!Array.isArray(fields)){
    Object.entries(fields).forEach(([key,value]) =>{
      let newValue = value
      if(typeof value === 'object'){
        fields[key] = moment.parseZone(value).utc().format();
        newValue = moment.parseZone(value).utc().format().substring(0,10)
        newValue = newValue.replaceAll("-","/");
        newValue = `${newValue.substr(8,2)}/${newValue.substr(5,2)}/${newValue.substr(0,4)}`
      }else{
        if(value.includes('-')){
          let arrayValue = value.split('-');
          fields[key] = arrayValue[0];
          newValue = arrayValue[1]
        }
      }
      newValues.push({field:key,value:newValue})
    });
  }else{
    let fieldsTemp = {}
    fields.forEach(field => {
      fieldsTemp[field.field] =field.value;
    })
    fields = fieldsTemp
  }

  
  return  {
    fields,
    newValues
  }
}

const setSites = (fields) => {
  return (dispatch) =>
    dispatch({
      type: MaintenanceConstants.Accion.Sites.SEARCH_SITES,
      payload: { ...fields },
    });
};

const setSite = (infoData) => {
  return (dispatch) =>
  dispatch({
    type: MaintenanceConstants.Accion.Sites.SAVE_SITE,
    payload: infoData,
  });
}

const deleteSite = (locationId) => {
  return (dispatch,getState) => {
    let { data } = getState().sitesReducer.sites;
    dispatch(SitesAction.setSites({loading:true,data}))
    SitesService.removeSite(locationId)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(getSites({ change: false }));
          dispatch(
            toastAction.success('Gestión de Sedes', 'La sede se eliminó correctamente.')
          );
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(getSites({ change: false }));
          dispatch(toastAction.error('Gestión de Sedes', data.message));
        }
      })
      .catch((error)=>{
        dispatch(setSites({ loading: false }));
      });
  };
}

const getSites = (fields) => {
  return (dispatch,getState) => {
    let { data } = getState().sitesReducer.sites;
    dispatch(setSites({ loading: true , data  }));
    SitesService.sitesSearch({fields,change:true}).then(({ data }) => {
      if (data.status === CoreConstants.HttpResponse.OK){
      const { results, ...rest } = data.data;
      results.forEach(result =>{
        result.creationDate =  moment(result.creationDate.substring(0,10)).format(CoreConstants.Format.DDMMYYYY)
      })
      let objFields = {
        loading: false,
        data: results,
        currentData: results,
        pagination: { ...rest },
      };
      if (fields.columnOrder) objFields.currentCol = fields.columnOrder;
      dispatch(setSites(objFields));
    }else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
      dispatch(toastAction.warn('Error', data.message));
    }else{
      dispatch(toastAction.error('Error', data.message));
    }
    }).catch((error)=>{
    });
  };
};


const saveSite = (siteInfo) =>{
  return (dispatch) => {
    SitesService.saveSite(siteInfo).then(({ data }) => {
      const status = data.status;
      if (status === CoreConstants.HttpResponse.OK) {
        dispatch(
          toastAction.success('Gestión de Sedes',"La sede se guardó correctamente.")
        );
        dispatch(setSite({ loadingSaveOrEdit: false , isSave : true }));

      }else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
        dispatch(toastAction.warn('Gestión de Sedes', data.message));
        dispatch(setSite({ loadingSaveOrEdit: false  }));

        return
      }else{
        dispatch(toastAction.error('Gestión de Sedes', data.message));
        dispatch(setSite({ loadingSaveOrEdit: false  }));;
        return
      }
    }).catch((error)=>{
      dispatch(setSite({ loadingSaveOrEdit: false }));
    });
  };
}

const setDataMaster = (infoData) => {
  return (dispatch) =>
  dispatch({
    type: MaintenanceConstants.Accion.Sites.DATA_MASTER,
    payload: infoData,
  });
}

const getDataMaster = () =>{
  return (dispatch) => {
    SitesService.getDataMaster().then(({ data }) => {
      const { status }  = data.data;
      let dataMaster = {
        status: status,
      };
      dispatch(searchSites({name:null}));
      dispatch(setDataMaster(dataMaster));
    }).catch( (err) =>{
      toastAction.success('Gestión de Sedes', err.msg)
      dispatch(setDataMaster({ loadingDataMaster: false }));
    });
  };
}

const getSiteById = (idLocation) => {
  return (dispatch) => {
    dispatch(setSite({ loadingSaveOrEdit: true }))
    SitesService.getSiteById(idLocation).then(({ data }) => {
      if (data.status === CoreConstants.HttpResponse.OK) {
      let dataSite = {
        loadingSaveOrEdit:false,
        dataSite:data.data
      };
      dispatch(setSite({ loadingSaveOrEdit: false }))
      dispatch(setSite(dataSite));

      }else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
        dispatch(toastAction.warn('Error', data.message))
      }else{
        dispatch(toastAction.error('Error', data.message))
      }
    }).catch((error)=>{
      dispatch(setSite({ loadingSaveOrEdit: false }));
    });
  };
}


const removeFilterValues = (removeField) => {
  return (dispatch, getState) => {
    let { values } = getState().sitesReducer.siteFilter;
    let { status } = getState().sitesReducer.dataMaster
    const newValues = values.filter(({ field }) => field !== removeField);
    const  { fields } = getFilterValuesAdvanced(newValues);
    
    if(fields.statusId){
      const statusFound = status.filter(p=>p.description === fields.statusId)[0]
      fields.statusId = statusFound.id
    }

    dispatch(SitesAction.getSites({ change: true, fields }));
    dispatch({
      type: MaintenanceConstants.Accion.Sites.ADVANCED_FILTER,
      payload: { values: newValues },
    });
  };
};
export const SitesAction = {
  toggleModalFilters,
  removeFilterValues,
  searchSites,
  getSites,
  setSites,
  deleteSite,
  setSite,
  saveSite,
  getDataMaster,
  setDataMaster,
  getSiteById,
  generateReport,
  searchSitesAdvanced,
  searchTable
};
