import { CoreConstants } from 'core/commons/CoreConstants';
import moment from 'moment';
import FileSaver from 'file-saver';
import { toastAction } from "core/store/actions/ToastAction";
import { DocumentsConstants } from 'features/processManagement/commons/DocumentsConstants';
import { DocumentServices } from 'features/processManagement/services/DocumentsServices';

const isStorageEnabled = () => {
  const setIsStorageEnabled = ({ dispatch, isLoading, isEnabled }) => {
    dispatch({
      type: DocumentsConstants.Accion.Documents.IS_STORAGE_ENABLED,
      payload: { isLoading, isEnabled },
    });
  };
  return (dispatch) => {
    setIsStorageEnabled({ dispatch: dispatch, isLoading: true });
    DocumentServices.isStorageEnabled()
      .then(({ data }) => {
        setIsStorageEnabled({ dispatch: dispatch, isLoading: false, isEnabled: data.data});
      })
      .catch((err) => {
        setIsStorageEnabled({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error, inténtelo despues'));
      });
  }
};

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: DocumentsConstants.Accion.Documents.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.title) pines.push({ field: 'title', value: advancedFilter.filter.title });
    if (advancedFilter.filter.fileName) pines.push({ field: 'fileName', value: advancedFilter.filter.fileName });
    if (advancedFilter.filter.users) pines.push({ field: 'users', value: advancedFilter.filter.users.fullName });
    if (advancedFilter.filter.itemType) pines.push({ field: 'itemType', value: advancedFilter.filter.itemType.description });
    if (advancedFilter.filter.formatFile) pines.push({ field: 'formatFile', value: advancedFilter.filter.formatFile.description });
    if (advancedFilter.filter.fileStatus) pines.push({ field: 'fileStatus', value: advancedFilter.filter.fileStatus.description });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.DDMMYYYY
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.DDMMYYYY
        ),
      });

    advancedFilter.values = pines;

    dispatch({
      type: DocumentsConstants.Accion.Documents.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setDocuments = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentsConstants.Accion.Documents.SEARCH,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const listFilesFolders = getState().DocumentsReducer.listFilesFolders;
    setDocuments({
      dispatch: dispatch,
      isLoading: true,
      data: listFilesFolders.data,
    });

    const criteria = {};
    // const advancedFilter = getState().DocumentsReducer.advancedFilter;
    const filter = getState().DocumentsReducer.advancedFilter.filter;
    
    // criteria.columnOrder = advancedFilter.columnOrder;
    // criteria.order = advancedFilter.order;
    // criteria.page = advancedFilter.page;
    // criteria.pageSize = advancedFilter.pageSize;

    criteria.title = filter.title;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;
    criteria.fileName = filter.fileName;
    criteria.ownerId = filter.users?.id;
    criteria.itemTypeId = filter.itemType?.id;
    criteria.formatFile = filter.formatFile?.id;
    criteria.statusId = filter.fileStatus?.id;

    DocumentServices.searchDocumentLibraries(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setDocuments({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
        setDocuments({ dispatch: dispatch, isLoading: false, data: null });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setDocuments({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Gestión Documental', data.message));
        }
      },
      // () => {
      //   setDocuments({ dispatch: dispatch, isLoading: false, data: null });
      // }
    );
  };
};

const generateReport = (payload) => {
  const setDocuments = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentsConstants.Accion.Documents.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setDocuments({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().DocumentsReducer.advancedFilter.filter;

    criteria.title = filter.title;
    // criteria.toDate = filter.to;
    // criteria.fromDate = filter.from;

    DocumentServices.generateReport(criteria)
      .then(({ data }) => {
        setDocuments({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Gestión Documental.xls');
      })
      .catch((err) => {
        setDocuments({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Gestión Documental', 'Hubo un error al descargar el archivo'));
      });
  };
};

const getDataListFolders = () => {
  const setListFolders = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentsConstants.Accion.Documents.GET_DATA_LIST_FOLDERS,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setListFolders({ dispatch: dispatch, isLoading: true });

    DocumentServices.getDataListFolders()
      .then(({ data }) => {
        setListFolders({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setListFolders({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error al descargar el archivo'));
      });
  }
};

const getRecentFiles = () => {
  const setListFolders = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentsConstants.Accion.Documents.GET_RECENT_FILES,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setListFolders({ dispatch: dispatch, isLoading: true });

    DocumentServices.getRecentFiles()
      .then(({ data }) => {
        setListFolders({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setListFolders({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error al descargar el archivo'));
      });
  }
};

const getCurrentFiles = () => {
  const setListFolders = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentsConstants.Accion.Documents.GET_CURRENT_FILES,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setListFolders({ dispatch: dispatch, isLoading: true });

    DocumentServices.getCurrentFiles()
      .then(({ data }) => {
        setListFolders({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setListFolders({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error al descargar el archivo'));
      });
  }
};

const getMyFiles = () => {
  const setListFolders = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentsConstants.Accion.Documents.GET_MY_FILES,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setListFolders({ dispatch: dispatch, isLoading: true });

    DocumentServices.getMyFiles()
      .then(({ data }) => {
        setListFolders({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setListFolders({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error al descargar el archivo'));
      });
  }
};
const getTrashFiles = () => {
  const setListFolders = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentsConstants.Accion.Documents.GET_TRASH_FILES,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setListFolders({ dispatch: dispatch, isLoading: true });

    DocumentServices.getTrashFiles()
      .then(({ data }) => {
        setListFolders({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setListFolders({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error al descargar el archivo'));
      });
  }
};

const getDataListFilesAndFolders = (path) => {
  const setListFilesFolders = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentsConstants.Accion.Documents.GET_DATA_LIST_FILES_AND_FOLDERS,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setListFilesFolders({ dispatch: dispatch, isLoading: true });

    DocumentServices.getDataListFilesAndFolders(path)
      .then(({ data }) => {
        setListFilesFolders({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setListFilesFolders({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error al descargar el archivo'));
      });
  }
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: DocumentsConstants.Accion.Documents.RESET_AFTER_SAVED });
};

const getDocumentById = (institutionId) => {
  const setDocument = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentsConstants.Accion.Documents.GET_DOCUMENT_LIBRARY_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setDocument({ dispatch: dispatch, isLoading: true });
    DocumentServices.getDocumentLibraryById(institutionId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setDocument({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setDocument({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Gestión Documental', data.message));
        }
      },
      () => {
        setDocument({ dispatch: dispatch, isLoading: false });
      }
    );
  };
};

const deleteDocument = (id, fileName) => {

  return (dispatch, getState) => {

    DocumentServices.deleteDocumentLibrary(id, fileName).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gestión Documental', 'El archivo se eliminó correctamente'));
          dispatch({ type: DocumentsConstants.Accion.Documents.DELETE_DOCUMENT_LIBRARY, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Gestión Documental', data.message));
          dispatch({ type: DocumentsConstants.Accion.Documents.DELETE_DOCUMENT_LIBRARY, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: DocumentsConstants.Accion.Documents.DELETE_DOCUMENT_LIBRARY, payload: { isDeleted: false } });
      }
    );
  };
};

const moveDocument = (dataPath) => {
  const setDocument = ({ dispatch, isUpdated }) => {
    dispatch({
      type: DocumentsConstants.Accion.Documents.MOVE_DOCUMENT_LIBRARY,
      payload: { isUpdated },
    });
  };

  return (dispatch) => {
    DocumentServices.moveFile(dataPath).then(
      ({ data }) => {
        // console.log(data)
        if (data.status === CoreConstants.HttpResponse.OK) {
          setDocument({ dispatch, isUpdated: true });
          dispatch(toastAction.success('Gestión Documental', 'El archivo se movió correctamente'));
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setDocument({ dispatch, isUpdated: false });
          dispatch(toastAction.warn('Gestión Documental', data.message));
        }
      }
    );
  };
};

const uploadFile = (archivo) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentFile }) => {
    dispatch({
      type: DocumentsConstants.Accion.Documents.UPLOAD_FILE,
      payload: {
        isSaving,
        isSaved,
        // currentFile
      },
    });
  };

  return (dispatch, getState) => {
    const currentFile = getState().DocumentsReducer.document;

    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
      // currentInstitution,
    });
    DocumentServices.uploadFile(archivo).then(
      ( {data} ) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gestión Documental', 'El archivo se subió correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentFile });
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL)
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentFile });
        else
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    );
  };
};

const copyDocument = (dataPath) => {
  const setDocument = ({ dispatch, isUpdated }) => {
    dispatch({
      type: DocumentsConstants.Accion.Documents.COPY_DOCUMENT_LIBRARY,
      payload: { isUpdated },
    });
  };

  return (dispatch) => {
    DocumentServices.copyFile(dataPath).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setDocument({ dispatch, isUpdated: true });
          dispatch(toastAction.success('Gestión Documental', 'El archivo se copió correctamente'));
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setDocument({ dispatch, isUpdated: false });
          dispatch(toastAction.warn('Gestión Documental', data.message));
        }
      }
    );
  };
};

const renameDocument = (dataName) => {
  const setDocument = ({ dispatch, isUpdated }) => {
    dispatch({
      type: DocumentsConstants.Accion.Documents.RENAME_DOCUMENT_LIBRARY,
      payload: { isUpdated },
    });
  };

  return (dispatch) => {
    DocumentServices.renameFile(dataName).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setDocument({ dispatch, isUpdated: true });
          dispatch(toastAction.success('Gestión Documental', 'El archivo se renombró correctamente'));
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setDocument({ dispatch, isUpdated: false });
          dispatch(toastAction.warn('Gestión Documental', data.message));
        }
      }
    );
  };
};

const getDataMaster = () => {
  const setDataMaster = ({ dispatch, isLoading, users, itemType, formatFile, fileStatus }) => {
    dispatch({
      type: DocumentsConstants.Accion.Documents.GET_DATA_MASTER,
      payload: { isLoading, users, itemType, formatFile, fileStatus },
    });
  };

  return (dispatch) => {
    setDataMaster({ dispatch: dispatch, isLoading: true, users: null, itemType: null, formatFile: null, fileStatus: null });

    DocumentServices.getDataMaster().then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            users: data.data.users,
            itemType: data.data.itemType,
            formatFile: data.data.formatFile,
            fileStatus: data.data.fileStatus,
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            users: null,
            itemType: null,
            formatFile: null,
            fileStatus: null,
          });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            users: null,
            itemType: null,
            formatFile: null,
            fileStatus: null,
          });
          dispatch(toastAction.warn('Gestión Documental', data.message));
        }
      },
      () => {
        setDataMaster({ dispatch: dispatch, isLoading: false, users: null, itemType: null, formatFile: null, fileStatus: null });
      }
    );
  };
};


export const DocumentActions = {
  isStorageEnabled,
  toggleAdvancedFilter,
  search,
  generateReport,
  getDataListFolders,
  getRecentFiles,
  getCurrentFiles,
  getMyFiles,
  getTrashFiles,
  getDataListFilesAndFolders,
  resetAfterSaved,
  getDocumentById,
  deleteDocument,
  moveDocument,
  copyDocument,
  renameDocument,
  getDataMaster,
  updateFilter,
  uploadFile
}