import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import HeaderEditor from 'shared/components/header-editor/HeaderEditor';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Skeleton } from 'primereact/skeleton';
import { Controller } from 'react-hook-form';
import { Editor } from 'primereact/editor';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { CoreConstants } from 'core/commons/CoreConstants';
import { Message } from 'primereact/message';
import { TableCommentsComponent } from 'shared/components/comments/TableCommentsComponent';
import Attachment from 'features/coursesManagment/components/CoursesExpenses/Attachment';
import { CoursesExpensesAction } from 'features/coursesManagment/store/actions/CourseExpensesAction';
import useCourseExpenses from 'features/coursesManagment/hooks/CourseExpenses/useCourseExpenses';
import './CoursesExpensesPage.scss'
import { useClaimCoursesExpenses } from 'features/coursesManagment/hooks/useClaimCoursesExpenses';

const Field = ({ name, row, updateExpense, paymentModes, onBlurAmount }) => {
  if (name === 'amount') {
      return (
          <InputText 
              type='number'
              placeholder='Monto *'
              name={name}
              // value={row[name].toFixed(2)}
              value={row[name]}
              className='text-right'
              onChange={(e) => {
                  updateExpense({
                      id: row.id,
                      value: e.target.value,
                      name: name,
                  })}
              }
              onBlur={() => onBlurAmount(row.id)}
              step='0.01'
          />
      )
  } else if (name === 'paymentDate') {
    return (
      <Calendar
          monthNavigator
          yearNavigator
          yearRange="1950:2050"
          showIcon={true}
          // dateFormat={CoreConstants.Format.LOCALE}
          value={row[name] === null ? null : new Date(row[name])}
          name={name}
          className='w-full'
          placeholder='Fecha *'
          onChange={(e) => {
              updateExpense({
                  id: row.id,
                  value: e.value,
                  name: name,
              })}
          }
      ></Calendar>
  )
  } else if (name === 'paymentMode') {
    return <Dropdown 
      options={paymentModes} 
      placeholder="Modo *" 
      optionValue='id' 
      optionLabel="description" 
      value={row[name]}
      onChange={(e) => {
        updateExpense({
          id: row.id,
          value: e.value,
          name: name,
        })}
      }/>
  } 
};

const CoursesExpensesPage = ({title, mode}) => {
  const { updateTitle } = useSetTitlePage();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const courseExpense = useSelector((state) => state.courseExpensesReducer.courseExpense);
  const {data: dataCourseExpense} = useSelector((state) => state.courseExpensesReducer.courseExpense);
  const { categories, paymentModes, types, courses } = useSelector((state) => state.courseExpensesReducer.dataMaster);
  const { data: commentsData, isLoading: isLoadingComments } = useSelector(state => state.courseExpensesReducer.comments);
  const { isDeleted: isDeletedComment, isSaved: isSavedComment  } = useSelector(state => state.courseExpensesReducer.comment);
  const [visible, setVisible] = useState(false);
  const [visibleClose, setVisibleClose] = useState(false);
  const [isFirstLoading, setFirstLoading] = useState(true);
  const [idDelete, setIdDelete] = useState(0);
  const [displayModal, setDisplayModal] = useState(false)
  const [dataRowExpense, setDataRowExpense] = useState(null)
  const [isSended, setIsSended] = useState(false)

  const { isCreate, isEdit } = useClaimCoursesExpenses();

  const {
    control, errors, valuesFields, setValue, handleSubmit,
    existError, validateLength,
    expensesList, setExpensesList, expenseError, setExpenseError,
    createExpense, deleteExpense, updateExpense, onBlurAmount,
    iconTypeOfFileTable,
    existRowEmpty
  } = useCourseExpenses();

  // console.log(valuesFields)
  
  const onHide = () => {
    // setValue("name", ''); 
    // reset(defaultValues)
    // setListQuestion([])
    dispatch(CoursesExpensesAction.resetAfterSaved());
  }

  const redirectToSearch = () => {
    history.push("/curso/gastos/");
    onHide()
  }

  const reject = () => {
    setVisible(false);
  };

  const header = HeaderEditor();

  useEffect(()=> {
    updateTitle({
      title: 'Cursos',
      subtitle: 'Gastos',
      description: title,
      previousUrl: "/curso/gastos/"
    })
    if (isFirstLoading) {
      setFirstLoading(false);
    }
    if (!params.id) onHide()
    if (params.id) {
      dispatch(CoursesExpensesAction.getCourseExpenseById(params.id))
    }

    return () => {
      onHide()
    }
  }, [params.id])
    
  const getPaymentsRows = (payments) => {
    const modelPayments = payments.map((payment) => {
      const newPayment = {
        id: payment.id,
        paymentMode: payment.modeId,
        paymentDate: payment.expenseDate,
        amount: payment.amount.toFixed(2),
        file: 'payment.attachment',
        // file: payment.attachment,
        attachmentId: payment.attachmentId,
        // projectExpenseId: payment.projectExpenseId,
      }
      
      return newPayment
    })
    return modelPayments
  }

  useEffect(() => {
    if (dataCourseExpense) {
        setValue("course", dataCourseExpense.course.id); 
        setValue("category", dataCourseExpense.categoryId); 
        setValue("type", dataCourseExpense.expenseTypeId); 
        setValue("description", dataCourseExpense.description) ;  
        setValue("initialDate", dataCourseExpense.from ? new Date(dataCourseExpense.from) : null); 
        setValue("endDate", dataCourseExpense.to ? new Date(dataCourseExpense.to) : null); 
        setValue("consultantName", dataCourseExpense.instructorName); 
        setValue("total", dataCourseExpense.totalAmount.toFixed(2)); 

        setValue("createdBy", dataCourseExpense.creationUser); 
        setValue("creationDate", dataCourseExpense.creationDate ? new Date(dataCourseExpense.creationDate) : ''); 
        setValue("updatedBy", dataCourseExpense.modificationUser); 
        setValue("updateDate", dataCourseExpense.modificationDate ? new Date(dataCourseExpense.modificationDate) : ''); 

        setExpensesList(getPaymentsRows(dataCourseExpense.payments));
    }
  }, [dataCourseExpense]);

  useEffect(() => {
    if (courseExpense?.isSaved) redirectToSearch();
  }, [courseExpense]);

  useEffect(() => {
    dispatch(CoursesExpensesAction.getDataMaster());
  }, [])

  const columns = [
    { field: 'paymentDate', header: 'Fecha *' },
    { field: 'paymentMode', header: 'Modo *' },
    { field: 'amount', header: 'Monto *' },
    { field: 'attachmentDocument', header: 'Documento' },
  ];

  const onSelectRowEspense = (name, row) => {
    setDataRowExpense({ name, row })
    setDisplayModal(true)
  }

  const FieldFile = ({ name, row }) => {
      return (
        <>
          {
            row.file
            ? (
              <div className='flex gap-x-1 p-inputgroup  '>
              <InputText
                  // onChange={onChange}
                  name={name}
                  // value={row.file}
                  value={row.attachmentDocument ? row.attachmentDocument?.name  : 'Archivo.pdf'}
                  readOnly
                  placeholder='Documento'
                  className='disabled'
              />
              <span 
                className="p-inputgroup-addon p-0 cursor-pointer hover:text-gray-800 hover:bg-gray-200">
                  <i className="pi pi-paperclip text-xl" onClick={() => onSelectRowEspense(name, row)} ></i>
              </span>
            </div>
              )
            : (
                <div className='flex gap-x-1 p-inputgroup  '>
                  <InputText
                      // onChange={onChange}
                      name={name}
                      value={row[name]?.name}
                      className='disabled'
                      readOnly
                      placeholder='Documento'
                  />
                  <span 
                    className="p-inputgroup-addon p-0 cursor-pointer hover:text-gray-800 hover:bg-gray-200">
                      <i className="pi pi-paperclip text-xl" onClick={() => onSelectRowEspense(name, row)} ></i>
                  </span>
                </div>
            )
          }
          
        </>
    )
  };

  const ColumnComponents = columns.map((col) => {
    if (col.field === 'paymentDate' || col.field === 'paymentMode' || col.field === 'amount') {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                sortable={col.field === 'paymentDate' || col.field === 'paymentMode' ? true : false}
                style={{width: '23%'}}
                body={(row) => <Field name={col.field} row={row} updateExpense={updateExpense} paymentModes={paymentModes} onBlurAmount={onBlurAmount} />}
            />
        );
    }
    if (col.field === 'attachmentDocument') {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                style={{width: '23%'}}
                body={(row) => <FieldFile name={col.field} row={row} updateExpense={updateExpense} />}
            />
        );
    }
    
    return <Column key={col.field} field={col.field} header={col.header} />;
  });

  const deletePermissionConfirm = () => {
    deleteExpense(idDelete);
  };

  const RowDelete = ({ id }) => {
    return (
        <i
          onClick={() => {
              setVisible(true);
              setIdDelete(id);
          }}
          className="pi pi-trash cursor-pointer text-center text-lg"
        ></i>
    );
  };

  const getModelPayments = (payments) => {
    const modelPayments = payments.map((payment) => {
      const {id, fileBase64, file, ...modelPayment} = payment
      modelPayment.amount = Number(payment.amount)
      if (params.id) {
        modelPayment.courseExpenseId = Number(params.id)
        if (!isNaN(id)) modelPayment.id = id
      }
      modelPayment.attachmentDocument = fileBase64 ? fileBase64 : null
        
      return modelPayment
    })
    return modelPayments
  }

  const onSubmit = (data) => {
    setIsSended(true)
    const { isRowEmpty, errorRows } = existRowEmpty(expensesList)
    if (isRowEmpty) {
      setExpenseError(errorRows)
      return
    }
    
    const model = {
      courseId: data.course,
      category: data.category,
      expenseType: data.type,
      description: data.description,
      from: data.initialDate,
      to: data.endDate,
      coachName: data.consultantName,
      totalAmount: Number(data.total),
      payments: getModelPayments(expensesList),
    }
    setExpenseError([])
    // console.log(model)
    if (params.id) {
      model.id = Number(params.id)
      dispatch(CoursesExpensesAction.saveCourseExpense(model));
      // console.log(model)
      return
    }
    dispatch(CoursesExpensesAction.saveCourseExpense(model));
    // console.log(model)
  };

  return (
    <div className="bg-white">
      <form 
        className="grid grid-cols-12 gap-x-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form-modal p-0 col-span-12 md:col-span-9 2xl:col-span-10">
          <Panel header="INFORMACIÓN DEL GASTO" toggleable>
            <div className="">
                  {courseExpense.isLoading ? (
                      <Skeleton />
                    ): (
                      <span className="p-float-label w-full md:w-1/2 my-2 pr-0 md:pr-2">
                          <Controller
                              control={control}
                              rules={{required: 'El proyecto es requerido'}}
                              render={({
                                  field: { onChange, value },
                              }) => (
                                <Dropdown
                                    options={courses}
                                    optionLabel="name"
                                    optionValue="id"
                                    value={value}
                                    onChange={onChange}
                                    showClear
                                    className={errors?.course ? 'p-invalid w-1/2' : 'w-1/2'}
                                />
                                
                              )}
                              name="course"
                          />
                          <label htmlFor="course">Seleccione Curso: *</label>
                      </span>
                  )}
              {courseExpense.isLoading ? (
                  <Skeleton />
                ): (
                  <span className="p-float-label w-full md:w-1/2 my-5 pr-0 md:pr-2">
                      <Controller
                          control={control}
                          rules={{required: 'La categoría es requerida'}}
                          render={({
                              field: { onChange, value },
                          }) => (
                            <Dropdown
                                options={categories}
                                optionValue="id"
                                optionLabel="description"
                                value={value}
                                onChange={onChange}
                                showClear
                                className={errors?.category ? 'p-invalid w-1/2' : 'w-1/2'}
                            />
                            
                          )}
                          name="category"
                      />
                      <label htmlFor="category">Seleccione Categoría: *</label>
                  </span>
              )}
              {courseExpense.isLoading ? (
                  <Skeleton />
                ): (
                  <span className="p-float-label w-full md:w-1/2 my-4 pr-0 md:pr-2">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, value },
                          }) => (
                            <Dropdown
                                options={types}
                                optionLabel="description"
                                optionValue="id"
                                value={value}
                                onChange={onChange}
                                showClear
                                className={errors?.type ? 'p-invalid w-1/2' : 'w-1/2'}
                            />
                            
                          )}
                          name="type"
                      />
                      <label htmlFor="type">Seleccione Tipo:</label>
                  </span>
              )}
              {courseExpense.isLoading ? (
                <Skeleton />
                  ): (
                    <span className="p-float-label w-full mt-5 mb-2">
                        <Controller
                            control={control}
                            rules={{required: 'La descripción es requerida'}}
                            render={({
                                field: { onChange, value },
                            }) => (
                              <>
                                <Editor
                                    headerTemplate={header}
                                    className={errors?.description ? 'p-invalid w-full survey-editor' : 'w-full survey-editor'}
                                    value={value} onTextChange={(e) => onChange(e.htmlValue)}
                                    placeholder="Descripción *"
                                />
                                <p className="font-semibold text-base text-gray-500 lg:mt-1 mt-3 text-right">
                                    Caracteres{' '}
                                    {validateLength(valuesFields.description)}{' '}/ 4000{' '}
                                </p>
                            </>
                            )}
                            name="description"
                        />
                    </span>
                )}
            </div>
          </Panel>
          <Panel header="Datos del Gasto" toggleable>
            <div>
              <div className='flex justify-between gap-x-4 mt-2 mb-4 w-full'>
                  {courseExpense.isLoading ? (
                      <Skeleton />
                    ): (
                      <div className='w-full'>
                        <span className="p-float-label w-full lg:w-1/2">
                            <Controller
                                control={control}
                                render={({
                                    field: { onChange, value },
                                }) => (
                                  <Calendar
                                    monthNavigator
                                    yearNavigator
                                    yearRange="1950:2050"
                                    showIcon={true}
                                    // dateFormat={CoreConstants.Format.LOCALE}
                                    value={value}
                                    onChange={onChange}
                                    className='w-full'
                                />
                                  
                                )}
                                name="initialDate"
                            />
                            <label htmlFor="initialDate">Fecha Inicio:</label>
                        </span>
                      </div>
                  )}
                  {courseExpense.isLoading ? (
                    <Skeleton />
                  ): (
                    <div className='w-full'>
                      <span className="p-float-label w-full lg:w-1/2">
                          <Controller
                              control={control}
                              render={({
                                  field: { onChange, value, onBlur },
                              }) => (
                                <Calendar
                                    monthNavigator
                                    yearNavigator
                                    yearRange="1950:2050"
                                    showIcon={true}
                                    // dateFormat={CoreConstants.Format.LOCALE}
                                    minDate={valuesFields?.initialDate}
                                    value={value}
                                    onChange={onChange}
                                    className='w-full'
                                />
                                
                              )}
                              name="endDate"
                          />
                          <label htmlFor="endDate">Fecha Fin</label>
                      </span>
                    </div>
                )}
              </div>
              {courseExpense.isLoading ? (
                    <Skeleton />
                  ): (
                    <span className="p-float-label w-full my-5">
                        <Controller
                            control={control}
                            render={({
                                field: { onChange, value, onBlur },
                            }) => (
                              <InputText
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                  className='w-full'
                              />
                              
                            )}
                            name="consultantName"
                        />
                        <label htmlFor="consultantName">Nombre del Instructor:</label>
                    </span>
                )}
              {courseExpense.isLoading ? (
                    <Skeleton />
                  ): (
                    <span className="p-float-label w-full sm:w-1/2 lg:w-1/4 mt-5 mb-2 pr-0 sm:pr-2 ">
                        <Controller
                            control={control}
                            rules={{required: 'El monto total a pagar es requerido'}}
                            render={({
                                field: { onChange, value, onBlur },
                            }) => (
                              <InputText
                                  type='number'
                                  onChange={onChange}
                                  // onBlur={onBlur}
                                  value={value}
                                  onBlur={ () => setValue('total', Number(valuesFields.total).toFixed(2))}
                                  step="0.01"
                                  className={errors?.total ? 'p-invalid w-full text-right' : 'w-full text-right'}
                              />
                            )}
                            name="total"
                        />
                        <label htmlFor="total">Monto Total a pagar: *</label>
                    </span>
                )}
            </div>
          </Panel>
          {
            params.id && (
              <Panel header="Comentarios" toggleable>
                <TableCommentsComponent 
                    enableComments={true}
                    sourceId={params.id}
                    comments={commentsData}
                    Action={CoursesExpensesAction}
                    isDeletedComment={isDeletedComment} 
                    isSavedComment={isSavedComment} 
                    isLoadingComments={isLoadingComments} 
                    sourceCode={CoreConstants.Task.Source.COURSE_EXPENSE} 
                />
              </Panel>
            )
          }
          <Panel header="Información de los Pagos" toggleable>
          <div className="">
              <div className=''>
                  <Button
                      icon="pi pi-plus"
                      type="button"
                      label="Añadir Gasto"
                      onClick={() => {
                        createExpense()
                        // setIsSended(false)
                      }}
                      className="sig-button sig-primary mt-2 mb-4"
                  />
              </div>
              {
                <div className="courses-expenses-table mb-3">
                    <DataTable
                        value={expensesList}
                        emptyMessage="No se encontraron gastos del proyecto"
                        scrollable scrollHeight="320px" loading={courseExpense.isLoading}
                        stripedRows
                        editMode="row"
                        dataKey="id"
                        rowHover
                        size="small"
                        responsiveLayout="scroll"
                        className='text-center'
                    >
                        {ColumnComponents}
                        <Column header={<div></div>} body={RowDelete} style={{width: '8%'}}></Column>
                    </DataTable>
                </div>
              }
                {
                  expenseError && (
                    <div className='flex justify-center'>
                      {
                        (expenseError.length > 0 && isSended) && (
                          <div className='message error w-full'>
                            {
                              expenseError.map((error) => (
                                <li className="p-error">{`Fila ${error.errorRow}: Debe ingresar ${error.errorField}`}</li>
                              ))
                            }
                          </div>
                        )
                      }
                    </div>
                  )
                }
          </div>
          {
            (existError) && <div className='message error'>
              <ul>
                {errors.course ? (
                    <>
                        <li className="p-error">{errors.course.message}</li>
                    </>
                ) : null}
                {errors.category ? (
                    <>
                        <li className="p-error">{errors.category.message}</li>
                    </>
                ) : null}
                {errors.description ? (
                    <>
                        <li className="p-error">{errors.description.message}</li>
                    </>
                ) : null}
                {errors.total ? (
                    <>
                        <li className="p-error">{errors.total.message}</li>
                    </>
                ) : null}
              </ul>
            </div>
          }
          <div className="flex justify-end gap-4 mt-1">
              <Button
                  icon="pi pi-times"
                  type="button"
                  label="Cancelar"
                  onClick={() => setVisibleClose(true)}
                  className="sig-button sig-secondary mt-4"
              />
              {
                (mode!=='view' && (isEdit || isCreate))&&(
                  <Button
                      icon="pi pi-save"
                      type="submit"
                      // disabled={existError ? true : false}
                      label="Guardar"
                      loading={courseExpense?.isSaving || courseExpense?.isLoading}
                      className="sig-button sig-primary mt-4"
                  />
                )
              }
          </div>
          </Panel>
        </div>
        <div className='col-span-12 md:col-span-3 2xl:col-span-2 order-first md:order-none'>
          <Panel header="DATOS GENERALES" toggleable>
            <div className="h-full">
                {courseExpense.isLoading ? (
                  <Skeleton />
                ): (
                  <span className="p-float-label w-full mt-2">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value },
                          }) => (
                            <InputText
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                className='disabled'
                                disabled
                            />
                          )}
                          name="createdBy"
                      />
                      <label htmlFor="createdBy">Creado por:</label>
                  </span>
                )}
                {courseExpense.isLoading ? (
                    <Skeleton />
                  ): (
                    <span className="p-float-label w-full my-5">
                        <Controller
                            control={control}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                              <Calendar
                                  value={value}
                                  onChange={onChange}
                                  className='w-full disabled'
                                  disabled
                              />
                            )}
                            name="creationDate"
                        />
                        <label htmlFor="creationDate">Fecha Creación:</label>
                    </span>
                )}
                {courseExpense.isLoading ? (
                  <Skeleton />
                ): (
                  <span className="p-float-label w-full my-5">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value },
                          }) => (
                            <InputText
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                className='disabled'
                                disabled
                            />
                          )}
                          name="updatedBy"
                      />
                      <label htmlFor="updatedBy">Modificador por:</label>
                  </span>
                )}
              
                {courseExpense.isLoading ? (
                  <Skeleton />
                ): (
                  <span className="p-float-label w-full mt-5">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value },
                          }) => (
                            <Calendar
                                value={value}
                                onChange={onChange}
                                className='w-full disabled'
                                disabled
                            />
                          )}
                          name="updateDate"
                      />
                      <label htmlFor="updateDate">Fecha Modificación:</label>
                  </span>
                )}
              </div>
          </Panel>
        </div>
      </form>
      <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea eliminar el gasto?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={() => deletePermissionConfirm()}
          reject={() => setVisible(false)}
      />
      <Attachment 
          displayModal={displayModal}  setDisplayModal={setDisplayModal} 
          iconTypeOfFileTable={iconTypeOfFileTable}
          updateExpense={updateExpense}
          dataRowExpense={dataRowExpense}  setDataRowExpense={setDataRowExpense} 
        />
        <ConfirmDialog
          visible={visibleClose}
          onHide={() => setVisibleClose(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={redirectToSearch}
          reject={reject}
      />
    </div>
  )
}

export default CoursesExpensesPage