import { toHaveFormValues } from "@testing-library/jest-dom/dist/matchers";
import { CoreConstants } from "core/commons/CoreConstants";
import { ProjectsActions } from "features/projects/store/actions/ProjectsActions";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import useSetTitlePage from "shared/hooks/useSetTitlePage";

export const usePageSnapshots = () => {
  const dispatch = useDispatch()
  const menu = useRef()
  const history = useHistory()
  const params = useParams()
  const { updateTitle } = useSetTitlePage();
  const dataTable = useSelector( state => state.projectsReducer.snapshot.dataTable)
  const loading = useSelector( state => state.projectsReducer.snapshot.loading)
  const rowCount = useSelector( state => state.projectsReducer.snapshot.pagination.rowCount)
  const currentPage = useSelector( state => state.projectsReducer.snapshot.pagination.currentPage)
  const first = (currentPage-1) * 10
  const [ showModal, setShowModal] = useState(false)
  const [ pageSize, setPageSize ] = useState(10)
  const [ piners , setPiners ] = useState([])
  const [ values, setValues ] = useState({
    name: null,
    fromDate: null,
    toDate: null,
  })
  const [ showConfirmDialog, setShowConfirmDialog ] = useState(false)
  const closeDialog = () => setShowConfirmDialog(false)

  const initialPagination = {
    page: 1,
    pageSize: pageSize,
    columnOrder: "creationDate",
    order: "desc",
  }

  const initialOrder = {
    columnOrder: "creationDate",
    order: "desc",
  }

  const [ sortOrder, setSortOrder ] = useState({
    columnOrder: "creationDate",
    order: "desc",
  })

  const onSort = (event) => {
    const newOrder = {
      columnOrder: event.sortField,
      order: event.sortOrder === 1 ? 'asc' : 'desc'
    }
    setSortOrder(newOrder)
    const payload = {
      ...values,
      pageSize: pageSize,
      page: currentPage,
      ...newOrder,
      projectId: Number(params.projectId),
    }
    dispatch(ProjectsActions.searchSnapshot(payload))
  }

  const pagination = {
    ...initialPagination,
    ...sortOrder,
  }

  const deleteSnapshotById = () => {
    const snapShotId = menu.current.rowId
    setPiners([])
    setValues( prev => ({
      ...prev,
      name: null,
      fromDate: null,
      toDate: null,
    }))
    setSortOrder(initialOrder)
    const payload = {
      page: 1,
      pageSize: pageSize,
      columnOrder: "creationDate",
      order: "desc",
      projectId: Number(params.projectId),
    }
    dispatch(ProjectsActions.deleteSnapshotById(Number(snapShotId), payload))
  }

  let items = [
    {
      label: "Ver",
      icon: "pi pi-pencil",
      command: () => {
        const snapShotId = menu.current.rowId
        history.push(`/proyecto/vista-proyectos/planificacion/${snapShotId}`)

      },
    },
    {
      label: "Eliminar",
      icon: "pi pi-trash",
      command: () => setShowConfirmDialog(true),
    },
  ];

  const handleChange = ( e ) => {
    setValues( prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const clearFields = () => {
    setPiners( prev => prev.filter( ({field}) => "name" === field ))
    setValues( prev => ({
      name: prev.name,
      fromDate: null,
      toDate: null,
    }))
  }

  const removePiner = ( fieldPiner ) => {
    setPiners( prev => prev.filter( ({field}) => fieldPiner !== field ))
    const newValues = {
      ...values,
      [fieldPiner]: null,
    }
    setValues( prev => ({
      ...prev,
      [fieldPiner]: null,
    }))
    const payload = {
      ...newValues,
      ...initialPagination,
      projectId: Number(params.projectId),
    }
    dispatch(ProjectsActions.searchSnapshot(payload))
    closeModal()
  }

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const search = (e) => {
    e.preventDefault();
    const _piners = [];
    for( const [key, value] of Object.entries(values)){
      if(value && value !== ''){
        if(key === 'name'){
          _piners.push({
            name: value,
            field: key,
            removePiner,
          })
        } else {
          _piners.push({
            name: moment(value).format(
              CoreConstants.Format.LOCALE
            ),
            field: key,
            removePiner,
          })
        }
      }
    }
    setPiners( _piners )
    
    const payload = {
      ...values,
      ...initialPagination,
      projectId: Number(params.projectId),
    }

    dispatch(ProjectsActions.searchSnapshot(payload))
    closeModal()
  }

  const onPageChange = ({page, rows}) => {
    const payload = {
      ...values,
      ...pagination,
      pageSize: rows,
      page: page + 1,
      projectId: Number(params.projectId),
    }
    setPageSize(rows)

    dispatch(ProjectsActions.searchSnapshot(payload))
  }

  const download = () => {
    const payload = {
      ...values,
      projectId: Number(params.projectId),
    }

    dispatch(ProjectsActions.downloadSearchSnapshot(payload))
  }

  /* TITLE ------------------------- */
  const pageTitle = {
    title: "Gestión de Proyectos",
    subtitle: "Ver Proyectos",
    description: "Ver Planificaciones",
    previousUrl: "/proyecto/vista-proyectos/",
  };
  useEffect(() => {
    updateTitle(pageTitle);
    
    const payload = {
      ...initialPagination,
      projectId: Number(params.projectId),
    }

    dispatch(ProjectsActions.searchSnapshot(payload))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      field: 'projectName',
      header: 'Nombre',
    },
    {
      field: 'name',
      header: 'Planificación',
    },
    {
      field: 'creationDate',
      header: 'Fecha',
      body: ( rowData) => moment(rowData.creationDate).format(CoreConstants.Format.LOCALE)
    },
    {
      field: 'responsible',
      header: 'Responsable',
    },
  ]

  return {
    dataTable,
    loading,
    rowCount,
    first,
    showModal,
    piners,
    items,
    handleChange,
    clearFields,
    openModal,
    search,
    onPageChange,
    download,
    columns,
    values,
    removePiner,
    menu,
    closeModal,
    onSort,
    sortOrder,
    deleteSnapshotById,
    pageSize,
    showConfirmDialog,
    closeDialog,
  }
}