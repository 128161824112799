import { Fragment } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import AdvancedFilterComponent from './AdvancedFilterComponent';
import PinerComponent from 'shared/components/Piner/PinerComponent';
import useAdvancedFilter from 'features/rrhh/hooks/positions/useAdvancedFilter';
import useFilter from 'features/rrhh/hooks/positions/useFilter';
import { useClaimPositions } from 'features/rrhh/hooks/useClaimPositions';

const PositionsFilterComponent = () => {
  const {
    onToggleModal,
    showPiners,
    piners,
    removePiner,
    formValues,
    handleChangeInput,
    onAdvancedFilter,
  } = useAdvancedFilter();
  const { positionsLoading, reportLoading, downloadReport } = useFilter();

  const { isSearch, isDownload } = useClaimPositions()

  return (
    <Fragment>
      <Panel header={`${isSearch?"FILTRO POR":"DESCARGA"}`} toggleable>
        <form className="filter-positions" onSubmit={onAdvancedFilter}>
        <div className={`w-full ${isSearch?'visible':'invisible'}`}>
            <span className="p-float-label p-input-icon-left w-full">
              <i className="pi pi-search" />
              <InputText
                id="input-search"
                name="name"
                value={formValues?.name ? formValues?.name : ''}
                onChange={handleChangeInput}
              />
              <label htmlFor="input-search">Buscar por cargo</label>
            </span>
            {showPiners && (
              <div className="filter-piners mt-3">
                {piners.map(({ value, field }, index) => (
                  <PinerComponent
                    name={value}
                    field={field}
                    removePiner={removePiner}
                    key={index}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="filter-users-action">
            <div className="flex gap-3 w-full">
              {
                isSearch&&(
                  <Button
                    icon="pi pi-search"
                    type="submit"
                    label="Buscar"
                    loading={positionsLoading || reportLoading}
                    className="sig-button sig-primary w-full"
                  />
                )
              }
              {
                isDownload&&(
                  <Button
                    icon="pi pi-download"
                    type="button"
                    onClick={downloadReport}
                    loading={reportLoading}
                    disabled={positionsLoading}
                    label="Descargar"
                    className="sig-button sig-success w-full"
                  />
                )
              }
            </div>
            <div className="flex mt-3">
              {
                isSearch&&(
                  <Button
                    type="button"
                    label="Búsqueda avanzada"
                    className="sig-button  sig-secondary w-full"
                    onClick={() => onToggleModal(true)}
                  />
                )
              }
            </div>
          </div>
        </form>
      </Panel>
      <AdvancedFilterComponent />
    </Fragment>
  );
};

export default PositionsFilterComponent;