import { useAttendance } from "features/coursesManagment/pages/courses/attendance/useAttendance";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { Fragment, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { ConfirmDialog } from "primereact/confirmdialog";
import { AttendanceAction } from "features/coursesManagment/store/actions/AttendanceAction";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { AttendancesTableData } from "features/coursesManagment/pages/courses/attendance/AttendancesTableData";
import { Column } from "primereact/column";
import { CoreConstants } from "core/commons/CoreConstants";
import { useClaimAttendance } from "features/coursesManagment/hooks/useClaimAttendance";

const AttendancePage = ({ title, mode }) => {
  const history = useHistory();
  const { updateTitle } = useSetTitlePage();
  const dispatch = useDispatch();
  const attendance = useSelector((state) => state.attendanceReducer.attendance);
  const [isFirstLoading, setFirstLoading] = useState(true);
  const dataMaster = useSelector((state) => state.attendanceReducer.dataMaster);
  const [visible, setVisible] = useState(false);
  const [visibleDialogAttendance, setVisibleAttendance] = useState(false);
  const [idDeleteAttendance, setidDeleteAttendance] = useState(0);
  const [name_, setName] = useState("");
  const [nameField, setNameField] = useState("");

  const { isEdit } = useClaimAttendance();

  const params = useParams();

  const {
    data,
    setAttendanceList,
    attendanceList,
    createAttendance,
    updateAttendance,
    attendanceDelete,
  } = useAttendance();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      //name: "",
      id: null,
      //courseScheduleId: null,
      studentId: null,
      enrollmentDate: null,
      statusId: null,
      // rowStatus: true,
    },
  });

  const redirectToSearch = () => {
    history.push("/curso/participantes");
    dispatch(AttendanceAction.resetAfterSaved());
  };

  useEffect(() => {
    //aqui hay que ponerle una condicional
    // console.log(attendance.data?.Students);
    if (attendance?.data) {
      //seteamos los valores de datos generales
      setValue("name", attendance.data?.Name);
      setValue("id", attendance.data?.Id);
      setValue("code", attendance.data?.Code);
      //course id de participantes

      setAttendanceList(attendance.data?.Students);
    }

    if (attendance?.isSaved) redirectToSearch();
  }, [dataMaster, attendance]);

  useEffect(() => {
    setValue("name", undefined);
    setValue("id", undefined);
    setValue("code", undefined);

    // setValue("studentId", undefined);
    // setValue("statusId", undefined);

    updateTitle({
      title: "Cursos",
      subtitle: "Participantes",
      description: title,
      previousUrl: "/curso/participantes/",
    });

    if (isFirstLoading) {
      setFirstLoading(false);
      dispatch(AttendanceAction.getDataMaster());
      // console.log(params);
      if (params?.id) dispatch(AttendanceAction.getAttendanceById(+params?.id));
    }
  }, []);

  // const generateStagesToPass = (currentStage, id) => {
  //   return [...currentStage].map((stage) => {
  //     const { id, ...rest } = stage;
  //     const newStage = rest;
  //     newStage.StatusId = newStage.StatusId.id;

  //     return newStage;
  //   });
  // };

  const firstLetterCapitalize = (text) => {
    return text.charAt(0).toLowerCase() + text.slice(1);
  };

  const generateStagesToPass2 = (data3) => {
    const students = data3.map((stage) => {
      const {
        Course,
        Evaluations,
        CreationUser,
        CreationDate,
        ModificationDate,
        ModificationUser,
        Status,
        Student,
        ...rest2
      } = stage;
      for (let [key, value] of Object.entries(rest2)) {
        rest2[firstLetterCapitalize(key)] = value;
        delete rest2[key];
      }
      return rest2;
    });
    return students;
  };

  const onSubmit = (data) => {
    //console.log(frecuencyList)
    const model = {
      id: data.id,

      students: generateStagesToPass2(attendanceList),
    };

    // console.log(model);

    dispatch(AttendanceAction.saveAttendance(model));
  };

  const reject = () => {
    setVisible(false);
  };

  const deleteAttendance = () => {
    attendanceDelete(idDeleteAttendance);
  };

  const FieldAttendance = ({ name, row }) => {
    // console.log(name + "-" + row[name]);
    if (name === "StudentId")
      return (
        <Dropdown
          options={dataMaster.students}
          optionLabel="fullName"
          filter
          filterBy="description"
          showclear="true"
          className="w-full"
          placeholder="Seleccione participante"
          optionValue="id"
          value={row[name]}
          onChange={(e) => {
            setName(e.target.name);
            setNameField(e.target.name);
            updateAttendance({
              id: row.id,
              name: name,
              value: e.value,
            });
          }}
        />
      );
    else if (name === "StatusId")
      return (
        <Dropdown
          options={dataMaster.status}
          optionLabel="description"
          filter
          filterBy="description"
          showclear="true"
          className="w-full"
          placeholder="Seleccione estado"
          optionValue="id"
          value={row[name]}
          onChange={(e) => {
            setName(e.target.name);
            setNameField(e.target.name);
            updateAttendance({
              id: row.id,
              name: name,
              value: e.value,
            });
          }}
        />
      );
    else {
      return (
        <Calendar
          name="EnrollmentDate"
          monthNavigator
          yearNavigator
          yearRange="1950:2050"
          showIcon={true}
          className="w-full"
          onChange={(e) => {
            setName(e.target.name);
            setNameField(e.target.name);
            updateAttendance({
              id: row.id,
              name: name,
              value: e.value,
            });
          }}
          value={row[name] == null ? null : new Date(row[name])}
        ></Calendar>
      );
    }
  };

  const ColumnAttendanceComponents = AttendancesTableData.attendanceColumns.map(
    (col) => {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          style={{ width: col.field == "name" ? "50%" : "35%" }}
          body={(row) => <FieldAttendance name={col.field} row={row} />}
        />
      );
    }
  );

  const RowDeleteAttendance = ({ id }) => {
    return (
      <i
        onClick={() => {
          setVisibleAttendance(true);
          setidDeleteAttendance(id);
        }}
        className="pi pi-trash cursor-pointer"
      ></i>
    );
  };

  return (
    <div className="bg-white">
      <form className="form-custom p-0" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-modal p-0">
          <Panel header="DATOS GENERALES" toggleable>
            <div className="grid sm:grid-cols-3 md:grid-cols-3 gap-2">
              {attendance.isLoading ? (
                <Skeleton height="2.2rem" />
              ) : (
                <span className="p-float-label w-full mt-4">
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                    }) => (
                      <InputText
                        readOnly
                        disabled
                        //type="text"
                        id="code"
                        name="code"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        // className={errors?.code ? "p-invalid w-full" : "w-full"}
                      />
                    )}
                    name="code"
                    // rules={{
                    //   required: "El codigo es requerido",
                    // }}
                  />
                  <label htmlFor="code">Código del Curso *</label>
                </span>
              )}

              <span className="p-float-label w-full mt-4 hidden md:block">
                &nbsp;
              </span>
              <span className="p-float-label w-full mt-4 hidden md:block">
                &nbsp;
              </span>
            </div>

            {/* COURSE NAME */}

            <div className="grid sm:grid-cols-1 md:grid-cols-1 gap-2 mt-2">
              {attendance.isLoading ? (
                <Skeleton height="2.2rem" />
              ) : (
                <span className="p-float-label w-full mt-4">
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                    }) => (
                      <InputText
                        id="name"
                        name="name"
                        readOnly
                        disabled
                        showclear="true"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        // className={errors?.name ? "p-invalid w-full" : "w-full"}
                      />
                    )}
                    name="name"
                    // rules={{
                    //   required: "El Nombre de Curso es requerido",
                    // }}
                  />
                  <label htmlFor="name">Nombre del Curso</label>
                </span>
              )}
            </div>
          </Panel>
        </div>
        <Panel header="PARTICIPANTES" toggleable>
          {/* AQUIva el button */}
          <Button
            icon="pi pi-plus"
            type="button"
            label="Añadir participantes"
            onClick={createAttendance}
            className="btn btn-primary mt-2 mb-4"
          />
          <div className="table-main table-contact mb-3">
            {/* {console.log(attendanceList)} */}
            <DataTable
              //className='grid'
              value={attendanceList}
              emptyMessage="No se encontro participantes"
              stripedRows
              rows={5}
              editMode="row"
              dataKey="id"
              rowHover
              size="small"
              responsiveLayout="scroll"
            >
              {ColumnAttendanceComponents}
              <Column header={<div></div>} body={RowDeleteAttendance}></Column>
            </DataTable>
          </div>
        </Panel>

        <div className="flex justify-end gap-4 mt-3">
          <Button
            icon="pi pi-times"
            type="button"
            label="Cancelar"
            onClick={() => {
              setVisible(true);
            }}
            className="sig-button sig-secondary mt-4"
          />
          {
            (mode!=='view' && isEdit)&&(
              <Button
                icon="pi pi-save"
                type="submit"
                label="Guardar"
                loading={attendance?.isSaving || attendance?.isLoading}
                className="sig-button sig-primary mt-4"
              />
            )
          }
        </div>
      </form>

      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="¿Está seguro que desea cancelar?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={redirectToSearch}
        reject={reject}
      />

      <ConfirmDialog
        visible={visibleDialogAttendance}
        onHide={() => setVisibleAttendance(false)}
        message="¿Está seguro que desea eliminar el registro?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={() => deleteAttendance()}
        reject={() => setVisibleAttendance(false)}
      />
    </div>
  );
};

export default AttendancePage;
