
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Skeleton } from 'primereact/skeleton';
import { useHistory, useParams } from 'react-router-dom';
import { CoreConstants } from 'core/commons/CoreConstants';
import { SelectButton } from 'primereact/selectbutton';
import { Panel } from 'primereact/panel';
import { Badge } from 'primereact/badge';
import { Tooltip } from 'primereact/tooltip';
import './TableComponent.scss'
import TableCardComponent from './TableCardComponent';
import { DinamicProcessAction } from 'features/configuration/store/actions/DinamicProcessAction';

// const temporalData = [
//   {
//     id: 1,
//     texto_nombre: 'Juan',
//     fecha_inicial: '2022-09-30T17:58:33.3451744+00:00',
//     simple_edad: '20',
//   },
//   {
//     id: 2,
//     texto_nombre: 'Jose',
//     fecha_inicial: '2022-09-30T17:58:33.3451744+00:00',
//     simple_edad: '25',
//   },
//   {
//     id: 3,
//     texto_nombre: 'Marco',
//     fecha_inicial: '2022-09-30T17:58:33.3451744+00:00',
//     simple_edad: '30',
//   },
// ]

const TableComponent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const typeViews = [
        { icon: 'pi pi-list', value: CoreConstants.TypeView.LIST },
        { icon: 'pi pi-microsoft', value: CoreConstants.TypeView.CANVA }
    ];
    // const { data } = useSelector((state) => state.indicatorReducer.indicators);
    // const { isDeleted, isSaved } = useSelector((state) => state.indicatorReducer.indicator);

    const {data: processSelected, isLoading: isLoadingProcess} = useSelector(state => state.dinamicProcessReducer.process)
    const {data: listProcess, isLoading } = useSelector(state => state.dinamicProcessReducer.listProcess)
    
    const advancedFilter = useSelector((state) => state.dinamicProcessReducer.advancedFilter);

    const processSections = useSelector(state => state.dinamicProcessReducer.process.data?.sections)
    const listControls = useSelector(state => state.dinamicProcessReducer.listControls.data)

    const [selectedColumns, setSelectedColumns] = useState([]);
    const [columns, setColumns] = useState([])
    const [listSteps, setListSteps] = useState([])
    const [typeView, setTypeView] = useState(CoreConstants.TypeView.CANVA);
    const [listColumns, setListColumns] = useState([])
    const [principalColumn, setPrincipalColumn] = useState([])

    useEffect(() => {
      if(processSelected && listControls && advancedFilter){

        const valuesModal = []
        processSections.map(elem=> {
          elem.controls.map(item => {
            if(processSelected?.configSearch.searchColumns.includes(item.identifier)){
              const modelItem = {
                field: item.identifier,
                header: item.text,
                type: (listControls.find(el => el.id===item.controlId)).code,
              }
              valuesModal.push(modelItem)
              
            }
            return item
          })
          return elem
        })
        setColumns([...valuesModal, {field: 'CurrentWorkFlowName', header: 'Paso Actual', type: 'TEXTO'}])
        setSelectedColumns([...valuesModal, {field: 'CurrentWorkFlowName', header: 'Paso Actual', type: 'TEXTO'}])
      }
    }, [advancedFilter, processSelected, listControls]);

    useEffect(() => {
      if(processSelected?.workFlowSteps){
        // TODO: Cambiar validacion por el tipo de paso
        // const steps = processSelected.workFlowSteps.filter(step => step.code.includes('Paso') )
        const steps = processSelected.workFlowSteps.filter(step => step.typeCode === 'STEP' || step.typeCode === 'END' )
        setListSteps(steps)
      }
    }, [processSelected]);

    useEffect(() => {
      if(processSelected?.configSearch){
        const newPrincipalColumn = [...processSelected.configSearch.simpleFilters]
        const listColumnExceptPrincipal = processSelected.configSearch.searchColumns.filter(col => !newPrincipalColumn.includes(col))
        setListColumns(listColumnExceptPrincipal)
        setPrincipalColumn(newPrincipalColumn)
        // setListSteps(steps)
      }
    }, [processSelected?.configSearch]);

    

    const actionsHeader = useRef(null);
    const menu = useRef(null);
    const [showConfirmDialog, setshowConfirmDialog] = useState(false);
    const [rowSelected, setRowSelected] = useState(null);
    const [isFirstLoading, setFirstLoading] = useState(true); 

    useEffect(() => {
        if (isFirstLoading) {
            tryParseFilter();
            setFirstLoading(false);
            // dispatch(IndicatorsAction.search());
        }
    }, []);


    // useEffect(() => {
    //     if (isDeleted) {
    //         setshowConfirmDialog(false);
    //         dispatch(IndicatorsAction.resetAfterSaved());
    //         dispatch(IndicatorsAction.search());
    //     }
    // }, [isDeleted])

    const getDate = (rowData, field) => {
      return rowData[field.field] ? moment(rowData[field.field]).format(CoreConstants.Format.LOCALE) : null
    }

    const getDinamicText = (rowData, field) => {
        return <div dangerouslySetInnerHTML={{ __html: rowData[field.field] }} />
    }

    const columnComponents = selectedColumns.map(col => {
        if (col.type === 'FECHA_HORA' || col.type === 'FECHA') 
            return <Column key={col.field} field={col.field} header={col.header} body={(e) => getDate(e, col)} sortable />;

        if (col.type === 'TEXTO_DINAMICO') 
            return <Column key={col.field} field={col.field} header={col.header} body={(e) => getDinamicText(e, col)} sortable />;

        if (col.type === 'MONEDA') 
            return <Column key={col.field} field={col.field} header={`${col.header} ($)`} sortable />

        if (col.body)
            return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable />;

        return <Column key={col.field} field={col.field} header={col.header} sortable />;
    });

    const actionHeaderTemplate = (rowData) => {
        return <i
            key="action"
            className="pi pi-ellipsis-v cursor-pointer"
            onClick={(e) => actionsHeader.current.toggle(e)}></i>;
    }

    const actionBodyTemplate = (rowData) => {
        return <i
            key={rowData.id}
            className="pi pi-ellipsis-v cursor-pointer"
            onClick={(event) => {
                setRowSelected(rowData);
                menu.current.toggle(event)
            }
            }></i>
    }

    const tryParseFilter = () => {
        try {
            const filters = JSON.parse(localStorage.getItem("occupation-filter") || '[]');
            const myFilters = filters.filter(item => item.field === 'code'  || item.field === 'from' || item.field === 'to');
            if (filters)
                setSelectedColumns(myFilters);
            else
                setSelectedColumns(columns);
        } catch (error) {
            localStorage.removeItem('occupation-filter');
        }
    }

    const onColumnToggle = (e, item) => {
        let _selectedColumns = [...selectedColumns];

        if (e.checked)
            _selectedColumns.push(item);
        else
            _selectedColumns = _selectedColumns.filter(c => { return c.field !== e.value });

        setSelectedColumns(_selectedColumns);
        localStorage.setItem("occupation-filter", JSON.stringify(_selectedColumns));
    }

    const getColumns = () => {
        let array = [];
        
        columns.map((item) => {
            const column = <div key={item.field} className="p-field-checkbox py-1">
                <Checkbox
                    inputId={item.field}
                    name="column"
                    value={item.field}
                    onChange={(e) => onColumnToggle(e, item)}
                    checked={selectedColumns.some((x) => x.field === item.field)}
                />
                <label className="ml-1" htmlFor={item.field}>{item.header}</label>
            </div>
            array.push(column);
        });
        return array;
    };

    const items = [
        {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: (e) => {
                history.push(`/proceso-dinamico/${params.id}/${rowSelected.ProcessId}`);
            }
        },
        {
            label: 'Eliminar',
            icon: 'pi pi-times',
            command: (e) => {
                setshowConfirmDialog(true);
                
            }
        }
    ];

    const confirmDelete = () => {
      dispatch(DinamicProcessAction.deleteProcessData(rowSelected.ProcessId));
    }

    const cancelDelete = () => {
        setshowConfirmDialog(false);
    }

    const headerTemplate = (options, step) => {
        // if (data === null || data === undefined)
        //   return ('');
        return (
          <div className="flex p-1 pb-2 gap-x-2 justify-between"
            style={{
              border: '1px solid #dee2e6',
              background: '#f8f9fa',
              color: '#495057',
            //   padding: '3px'
            }}>
            <div className="step-title-container">
                {/* <Tooltip target={`.tooltip-title-step-${step?.id}`} /> */}
                <label 
                    className={`text-sm font-bold step-title-container tooltip-title-step-${step?.id}`}
                    // data-pr-tooltip={step?.name} 
                    // data-pr-position="top"
                >{step.name}
                </label>
            </div>
            <div className="">
                <Badge
                    // value={data?.filter(c => c.stageId === step.id).length}
                    value={listProcess?.filter(p => p.CurrentWorkFlowCode === step.code).length}
                    style={{ width: '24px', height: '24px' }}
                    severity="info"
                ></Badge>
            </div>
          </div>
        );
      }

    const headerTemplateSkeleton = () => {
        // if (data === null || data === undefined)
        //   return ('');
        return (
    
          <div className="flex p-1 pb-2"
            style={{
              border: '1px solid #dee2e6',
              background: '#f8f9fa',
              color: '#495057',
            //   padding: '3px'
            }}>
            <div className="flex-1">
                <Skeleton width="100%" height="20px" />
            </div>
          </div>
        );
      }

    return (
        <Fragment>
            <div className="flex justify-end mb-2">
                <SelectButton
                    unselectable={false}
                    value={typeView}
                    options={typeViews}
                    onChange={(e) => setTypeView(e.value)}
                    itemTemplate={(option) => { return <i className={option.icon}></i>; }}
                />
            </div>

            {
                typeView === CoreConstants.TypeView.CANVA && (
                    isLoadingProcess 
                    ? (
                        <div className='mt-3 flex canva-process-dinamic-skeleton'>
                            {
                                [1,2,3,4].map((item) => (
                                    <Panel
                                        headerTemplate={headerTemplateSkeleton}
                                        // header={step.name}
                                        className='flex-1 h-64'
                                        key={item}
                                        // style={{minWidth: '200px'}}
                                    ></Panel>
                                ))
                            }
                        </div>
                    )
                    : (
                        <div
                            // className="mt-3 inline-flex"
                            className="mt-3 flex w-full  gap-x-2 overflow-x-auto steps-scroll-horizontal"
                            style={{ maxWidth: window.innerWidth - 100 + "px",  }}
                        >
                            {
                                listSteps?.map((step, ind) => (
                                    <Panel
                                        headerTemplate={(event) => headerTemplate(event, step)}
                                        header={step.name}
                                        className='flex-1 canva-process-dinamic'
                                        key={step?.code}
                                        // style={{maxHeight: '480px'}}
                                    >
                                        {
                                            listProcess?.filter(p => p.CurrentWorkFlowCode === step.code).map((process, index) => (

                                                <TableCardComponent 
                                                    key={process.id}
                                                    history={history}
                                                    params={params}
                                                    principalColumn={principalColumn}
                                                    listColumns={listColumns}
                                                    columns={columns}
                                                    process={process}
                                                    menu={menu}
                                                    setRowSelected={setRowSelected}
                                                />
                                                // <div
                                                //     style={{
                                                //         display: "flex",
                                                //         justifyContent: "space-around",
                                                //     }}
                                                //     className=''
                                                //     key={process.id}
                                                // >
                                                //     <div 
                                                //         className="card-canva my-1 cursor-pointer h-20"
                                                //         onClick={() => { history.push(`/proceso-dinamico/${params.id}/${process.ProcessId}`) }}
                                                //     >
                                                //         {/* HEADER */}
                                                //         <div className="flex justify-between">
                                                //             <Tooltip target={`.tooltip-${process.ProcessId}`} />
                                                //             <div className={`tooltip-${process.ProcessId}`}
                                                //                 data-pr-tooltip={ process[principalColumn[0]] && (`${process[principalColumn[0]]}`)}
                                                //                 data-pr-position="top" >
                                                //             <p  className='title truncate-text '>
                                                //                 {
                                                //                     process[principalColumn[0]]
                                                //                 }
                                                //             </p>
                                                //             </div>
                                                //         </div>
                                                //         <hr />
                                                //         {/* BODY */}
                                                //         <div className="body flex flex-col gap-y-2">
                                                //             {
                                                //                 listColumns.filter(col => Object.keys(process).includes(col)).map(column => (
                                                //                     // <div className='flex gap-x-1'>
                                                //                     <div className='' key={column}>
                                                //                         <p className="text-xs capitalize">{column}:</p>
                                                //                         <p className="text-xs font-bold">
                                                //                             {
                                                //                                 columns?.find(c => c.field === column)?.type === ('FECHA' || 'FECHA_HORA') ?
                                                //                                 moment(process[column]).format('DD/MM/YYYY')
                                                //                                 :
                                                //                                 process[column]
                                                //                             }
                                                //                         </p>
                                                //                     </div>
                                                //                 ))
                                                //             }
                                                //             {/* <div className=''>
                                                //                 <p className="text-xs capitalize">Paso Actual:</p>
                                                //                 <p className="text-xs font-bold">{process.CurrentWorkFlowName}</p>
                                                //             </div> */}
                                                //         </div>
                                                //     </div>
                                                // </div>
                                            ))
                                        }
                                    </Panel>
                                ))
                            }
                        </div>
                    )
                )
            }

            {
                typeView === CoreConstants.TypeView.LIST && (
                    <div className="mt-3 " style={{ maxWidth: (window.innerWidth - 100) + 'px' }}>                    
                        <DataTable
                            value={listProcess}
                            stripedRows
                            responsiveLayout="scroll"
                            size="small"
                            emptyMessage="No se encontraron registros"
                            key='id'
                            paginator rows={10}
                            >
                            {columnComponents}
                            <Column header={actionHeaderTemplate} body={actionBodyTemplate} style={{width: '5%'}} ></Column>
                        </DataTable>
                    </div>
                )
            }

            <OverlayPanel ref={actionsHeader} showCloseIcon style={{ width: '300px' }}>{getColumns()}</OverlayPanel>

            <Menu model={items} popup ref={menu} id="popup_menu" />
            <ConfirmDialog
                visible={showConfirmDialog}
                onHide={cancelDelete}
                message="¿Está seguro que desea eliminar el registro?"
                header="Eliminar"
                icon="pi pi-trash" 
                accept={confirmDelete}
                reject={cancelDelete}
            />
        </Fragment >
    );
};

export default TableComponent;
