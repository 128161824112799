import useModalPositions from 'features/rrhh/hooks/OrganizationChart/useModalPositions';
import { OrganizationChartAction } from 'features/rrhh/store/actions/OrganizationChartAction';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown';
import { Editor } from 'primereact/editor';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import HeaderEditor from 'shared/components/header-editor/HeaderEditor';


const ModalPositions = ({displayModal, setDisplayModal}) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const header = HeaderEditor();
  const {isLoading, areas, employees} = useSelector(state => state.organizationChartReducer.dataMaster);
  const {isSaved} = useSelector(state => state.organizationChartReducer.companyArea);
  const { control, errors, valuesFields, existError, setValue, handleSubmit, validateLength, reset } = useModalPositions()

  // const statusActive = status ? status.reduce((acc, cur) => {
  //   if (cur.code === 'ACTI') {
  //     acc = cur;
  //   }
  //   return acc;
  // }, 0) : []

  useEffect(() => {
    if (isSaved) {
      onHide()
      dispatch(OrganizationChartAction.resetAfterSaved());
    }
  }, [isSaved])

  const onHide = () => {
    setValue('name', '')
    setValue('parentDepartmentId', null)
    setValue('managerId', null)
    reset()
    setDisplayModal(false)
  }

  const reject = () => {
    setVisible(false);
  };

  const onSubmit = (newFile) => {
    const model = {
      name: newFile.name,
      parentDepartmentId: newFile.parentDepartmentId,
      managerId: newFile.managerId,
      // statusId: statusActive.id
    }

    dispatch(OrganizationChartAction.saveCompanyArea(model));
  };

  return (
    <Dialog header={`Crear nueva área`} visible={displayModal} className='w-3/4 lg:w-3/5 xl:1/2' onHide={onHide}>
      <form 
        className="form-custom p-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <hr className='mb-2'/>
        <div className="form-modal p-0">
          {/* <Panel header="DATOS GENERALES" toggleable> */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-2">
              <div className='col-span-2 sm:col-span-2 gap-5'>
                {document.isLoading ? (
                  <Skeleton />
                ): (
                  <span className="p-float-label w-full my-2">
                      <Controller
                          control={control}
                          rules={{
                            required: 'El nombre es requerido',
                          }}
                          render={({
                              field: { onChange, onBlur, value},
                          }) => (
                            <InputText
                              type="text"
                              id="code"
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                          />
                          )}
                          name="name"
                      />
                      <label htmlFor="name">Nombre del Departamento *</label>
                  </span>
                )}         
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 col-span-2 sm:col-span-2 gap-4 mt-2'>
              {isLoading ? (
                  <Skeleton />
                ): (
                    <span className="col-span-2 sm:col-span-1 p-float-label w-full">
                        <Controller
                            control={control}
                            rules={{
                              required: 'El area es requerido',
                            }}
                            render={({
                                field: { onChange, onBlur, value},
                            }) => (
                              <Dropdown
                                  options={areas}
                                  optionLabel="name"
                                  optionValue="id"
                                  value={value}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  filter
                                  showClear
                                  filterBy="name"
                                  className="w-full"
                              />
                            )}
                            name="parentDepartmentId"
                        />
                        <label htmlFor="parentDepartmentId">Departamento Principal *</label>
                    </span>
                )}  
                {isLoading ? (
                  <Skeleton />
                ): (
                    <span className="col-span-2 sm:col-span-1 p-float-label w-full">
                        <Controller
                            control={control}
                            rules={{
                              required: 'El empleado es requerido',
                            }}
                            render={({
                                field: { onChange, onBlur, value},
                            }) => (
                              <Dropdown
                                  options={employees}
                                  optionLabel="fullName"
                                  optionValue="id"
                                  value={value}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  filter
                                  showClear
                                  filterBy="description"
                                  className="w-full"
                              />
                            )}
                            name="managerId"
                        />
                        <label htmlFor="managerId">Gerente o Jefe *</label>
                    </span>
                )}         
              </div>
              {/* <div className='col-span-2 mt-2'>
                 <Controller
                  control={control}
                  rules={{
                    required: 'La descripción del cargo es requerido',
                  }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Editor
                        headerTemplate={header}
                        onTextChange={(e) => onChange(e.htmlValue)}
                        value={value}
                        placeholder="Añade las funciones del cargo"
                      />
                      <p className="font-semibold text-base text-gray-500 lg:mt-1 mt-3 text-right">
                        Caracteres{' '}
                        {validateLength(
                          valuesFields.responsibilitiesDescription
                        )}{' '}
                        / 4000{' '}
                      </p>
                    </>
                  )}
                  name="responsibilitiesDescription"
                /> 
            </div> */}
            </div>            
          {/* </Panel> */}
        </div>
        {
          (existError) && <div className='message error'>
              <ul>
                  {errors.name ? (
                      <>
                          <li className="p-error">{errors.name.message}</li>
                      </>
                  ) : null}
                  {errors.parentDepartmentId ? (
                      <>
                          <li className="p-error">{errors.parentDepartmentId.message}</li>
                      </>
                  ) : null}
                  {errors.managerId ? (
                      <>
                          <li className="p-error">{errors.managerId.message}</li>
                      </>
                  ) : null}
              </ul>

          </div>
          }
          <div className="flex justify-end gap-4 mt-3">
              <Button
                  icon="pi pi-times"
                  type="button"
                  label="Cancelar"
                  onClick={() => { setVisible(true); }}
                  className="sig-button sig-secondary mt-4"
              />
              <Button
                  icon="pi pi-save"
                  type="submit"
                  disabled={(existError) ? true : false}
                  label="Guardar"
                  loading={document?.isSaving || document?.isLoading}
                  className="sig-button sig-primary mt-4"
              />
          </div>
      </form>
      <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={onHide}
          reject={reject}
      />
    </Dialog>
  )
}

export default ModalPositions