import { CoursesManagmentConstants } from "features/coursesManagment/commons/CoursesManagmentConstants";

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  dataMaster: {
    isLoading: false,
    category: null,
    mode: null,
    service: null,
    scale: null,
    type: null,
    passingGrade: null,
  },
  course: {
      data: null,
      attachments: [],
      isSaving: false,
      isSaved: false,
      isDeleted: false,
      isLoading: false
  },
  courses: {
      isLoading: false,
      data: null
  },
  advancedFilter: {
      showModal: false,
      values: [],
      columnOrder: 'name',
      order: 'asc',
      page: 1,
      pageSize: 10,
      filter: {}
  },
}

export default (state = initialState, action) => {
  switch (action.type) {

    case CoursesManagmentConstants.Accion.Courses.ADVANCED_FILTER_TOGGLE:
        return {
            ...state,
            advancedFilter: {
                ...state.advancedFilter,
                ...action.payload
            }
        };

    case CoursesManagmentConstants.Accion.Courses.UPDATE_FILTER:
        return {
            ...state,
            advancedFilter: state.advancedFilter
        };

    case CoursesManagmentConstants.Accion.Courses.SEARCH_COURSES:
        return {
            ...state,
            courses: {
                isLoading: action.payload.isLoading,
                data: action.payload.data
            }
        };

    case CoursesManagmentConstants.Accion.Courses.GET_COURSE_BY_ID:
        return {
            ...state,
            course: {
                ...state.course,
                ...action.payload,      
                // data: action.payload.data,
                // isSaving: false,
                // isLoading: action.payload.isLoading
            }
        };

    case CoursesManagmentConstants.Accion.Courses.SAVE_COURSE:
        return {
            ...state,
            course: {
                data: action.payload.currentCourse,
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };

    case CoursesManagmentConstants.Accion.Courses.DELETE_COURSE:
        return {
            ...state,
            course: {
                isDeleted: action.payload.isDeleted
            }
        };

    case CoursesManagmentConstants.Accion.Courses.RESET_AFTER_SAVED:

        return {
            ...state,
            course: {
                data: null,
                isSaving: false,
                isSaved: false,
                isLoading: false,
                isDeleted: false
            }
        };

    case CoursesManagmentConstants.Accion.Courses.GET_DATA_MASTER:
        return {
            ...state,
            dataMaster: {
                category: action.payload.category,
                mode: action.payload.mode,
                service: action.payload.service,
                type: action.payload.type,
                scale: action.payload.scale,
                passingGrade: action.payload.passingGrade,
                isLoading: action.payload.isLoading,
                dynamicFields: action.payload.dynamicFields
            }
        };

    case CoursesManagmentConstants.Accion.Courses.DOWNLOAD_REPORT_STATUS:
        return {
            ...state,
            courses: {
                ...state,
                isLoading: action.payload.isLoading,
            },
        };
    default:
        return state;

}
}