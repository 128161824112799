import React, { useRef, useState } from 'react'
import { Timeline } from 'primereact/timeline';
import { Panel } from 'primereact/panel';
import { PrevisualizationRender } from '../Formulario/controllers/PrevisualizationRender';
import { Menu } from 'primereact/menu';
import { useEffect } from 'react';
import { PanelMenu } from 'primereact/panelmenu';
import './Permission.scss'
import { Ripple } from 'primereact/ripple';

const Permission = ({ dinamicStage, processFields, typeModal, setTypeModal, workflow, setWorkflow, getTypeById }) => {
  const [inputSelected, setInputSelected] = useState(null)
  const [stepSelected, setStepSelected] = useState(null)
  const [firstLoading, setFirstLoading] = useState(true)
  // const state = useRef(null)
  // console.log(state.current)
  const isDecision = (step) => {
    const prefixStep = step?.code.split('__')[0]
    if (prefixStep === 'decision') return true
    return false
  }

  const isBranch = (step) => {
    const prefixStep = step.code.split('__')[0]
    if (prefixStep === 'rama') return true
    return false
  }

  const sortBySuccessorCode = (arr) => {
    let sorted = [];
    
    const visited = new Set();
  
    const dfs = (obj) => {
      // visited.add(obj.code);
      if (isDecision(obj)) {
        const branchs = obj?.successorCode.split(',')
        // sorted.unshift(obj);
        sorted = [...sorted, obj]
        branchs.forEach((branch, index) => {
          // visited.add(branch);
          const branchStep = arr.find(step => step.code === branch)
          sorted = [...sorted, branchStep]
          // sorted.unshift(branchStep);
          if (index === branchs.length - 1 && obj.children.length > 0) {
            dfs(obj.children[0]);
          }
        })
        return
      } 
      sorted = [...sorted, obj]
      // sorted.unshift(obj);
      arr.forEach(item => {
        if (item.code === obj.successorCode && !visited.has(item.code)) {
          dfs(item);
        }
      });
    }
  
    // arr.forEach((item, index) => {
    //   if (!visited.has(item.code) && index === 0) {
    //     dfs(item);
    //   }
    // });
    dfs(arr[0])
    return sorted;
  }
  

  const sortedWorflow = sortBySuccessorCode(workflow)
  // console.log(sortedWorflow)

  const workFLowSteps = sortedWorflow.filter(workFLowStep => getTypeById(workFLowStep.typeId)?.code !== 'START' && !isDecision(workFLowStep) && !workFLowStep.branch && !workFLowStep.stepTo)
  // console.log(workflow)
  // console.log(stepSelected)

  const sections = [{name: 'Inicio', id: '1'}, ...workFLowSteps, {name: 'Fin', id: '999'}];
  const menu = useRef(null);

  useEffect(() => {
    if (workflow && firstLoading) {
      const firstStepCode = workflow.find(workFLowStep => getTypeById(workFLowStep.typeId)?.code === 'START')?.successorCode
      const firstStep = workflow.find(workFLowStep => workFLowStep.code === firstStepCode)
      setStepSelected(firstStep)
      if (firstStep) {
        setFirstLoading(false)
      }
    }
  }, [workflow, firstLoading])
  
  //* type: isHidden | isReadOnly
  const setPermissionByInputAndType = (input, type) => {
    const permissionsByStep = stepSelected.permissions.find(permission => permission.identifierControl ===  input.identifier)
    if (permissionsByStep) {
      const workflowUpdated = workflow.map(workfLowStep => {
        if (workfLowStep.code === stepSelected.code) {
          workfLowStep.permissions.map(permission => {
            if (permission.identifierControl === input.identifier) {
              permission[type] = !permission[type]
            }
            return permission
          })
        }
        return workfLowStep
      })
      setWorkflow(workflowUpdated)
      return
    }
    const model = {
      isReadOnly: false,
      isHidden: false,
      identifierControl: input.identifier
    }
    model[type] = true

    const workflowUpdated = workflow.map(workfLowStep => {
      if (workfLowStep.code === stepSelected.code) {
        workfLowStep.permissions.push(model)
      }
      return workfLowStep
    })
    setWorkflow(workflowUpdated)
  }

  const items = [
    {
        label: 'Lectura',
        icon: 'pi pi-lock',
        command: (e) => {
          // const permissionsByStep = stepSelected.permissions.find(permission => permission.identifierControl ===  inputSelected.identifier)
          //   if (permissionsByStep) {
          //     const workflowUpdated = workflow.map(workfLowStep => {
          //       if (workfLowStep.code === stepSelected.code) {
          //         workfLowStep.permissions.map(permission => {
          //           if (permission.identifierControl === inputSelected.identifier) {
          //             permission.isReadOnly = !permission.isReadOnly
          //           }
          //           return permission
          //         })
          //       }
          //       return workfLowStep
          //     })
          //     setWorkflow(workflowUpdated)
          //     return
          //   }
          //   const model = {
          //     isReadOnly: true,
          //     isHidden: false,
          //     identifierControl: inputSelected.identifier
          //   }
          //   const workflowUpdated = workflow.map(workfLowStep => {
          //     if (workfLowStep.code === stepSelected.code) {
          //       workfLowStep.permissions.push(model)
          //     }
          //     return workfLowStep
          //   })
          //   setWorkflow(workflowUpdated)
          setPermissionByInputAndType(inputSelected, 'isReadOnly')
        }
    },
    {
        label: 'Ocultar',
        icon: 'pi pi-eye',
        command: (e) => {
          setPermissionByInputAndType(inputSelected, 'isHidden')
        }
    }
  ];
  // console.log(dinamicStage)

  const customizedMarker = (item) => {
    return (
        (item.name === 'Fin') ? ( 
          <div className='p-timeline-last'></div>
        ) : (
          <div className='p-timeline-normal'></div>
        )
    );
  };

  // const selectMenuBranch = (step) => {
  //   //TODO: cambiar predecesor por el nuevo campo de idBranch o codeBranch
  //   // const children = workflow.filter(workFLowStep => workFLowStep.predecessorCode === step.code)
  //   const children = workflow.filter(workFLowStep => `rama__${workFLowStep.branch}` === step.code)
  //   const modelChildren = children.map(child => {
  //     const childModel = {
  //       ...child,
  //       label: child.name,
  //       command: () => {
  //         // console.log(child.code)
  //         // e.originalEvent.preventDefault()
  //         // e.originalEvent.isDefaultPrevented()
  //         // e.originalEvent.isPropagationStopped()
  //         // console.log(e.originalEvent)
          
  //         // e.preventDefault()
  //         // console.log('hola')
  //         // state.current = child
  //         setStepSelected(child)
  //       },
  //     }
  //     return childModel
  //   })

  //   // console.log(modelChildren)

  //   return [{
  //     label: step.name,
  //     items: modelChildren,
  //     expanded: true
  //     // items:[
  //     //     {
  //     //         label:'Left',
  //     //         command: (e) => {
  //     //           // setPermissionByInputAndType(inputSelected, 'isHidden')
  //     //           console.log('Hola')
  //     //         }
  //     //     },
  //     //     {
  //     //         label:'Right',
  //     //     },
  //     // ]
  //   }]
  // }

  const templatePanel = (options, title) => {
      const toggleIcon = options.collapsed ? 'pi pi-angle-right text-xl' : 'pi pi-chevron-down text-xl';
      const className = `${options.className} justify-start`;
      const titleClassName = `text-base`;

      return (
          <div className={className}>
              <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                  <span className={toggleIcon}></span>
                  <Ripple />
              </button>
              <span className={titleClassName}>
                  {options.props.header}
              </span>
          </div>
      )
  }

  const CustomizedContentOptions = ({step}) => {
    const children = workflow.filter(workFLowStep => `rama__${workFLowStep.branch}` === step.code)
    return (
      <div className='flex flex-col '>
        {
          children.filter(child => !child.stepTo).map(child => (
            <p 
              className={`w-full hover:bg-gray-50 cursor-pointer py-2 px-4 ${stepSelected?.code === child.code ? 'bg-gray-100  border border-gray-200' : ''} `}
              key={child.code}
              onClick={() => setStepSelected(child)}
            // >{child.typeCode === 'GOTO' ? child.name + child?.stepTo.name : child.name}</p>
            // >{child.stepTo ? child.name + child?.stepTo.name : child.name}</p>
            >{child.name}</p>
          ))
        }
      </div>
    )
  }
  
  const customizedContent = (item) => {
      return (
        <div>
          {
            (item.name === 'Inicio' || item.name === 'Fin') ? (
              <div className={`flex items-center ${item.name === 'Fin' ? 'mb-0' : 'mb-10'}`}>
                <h2 className='font-normal mb-2'>{item.name}</h2>
              </div>
            ) : (getTypeById(item.typeId)?.code === 'CONDITION') ? (
              <div className='process-permission-branch mb-3'>
                {/* <PanelMenu model={selectMenuBranch(item)} /> */}
                 <Panel headerTemplate={(options) => templatePanel(options)} header={item.name} toggleable collapsed={true}>
                    <CustomizedContentOptions step={item} />
                </Panel> 
              </div>
            )
            : (
              <div 
                className={`flex items-center shadow py-2 px-2 mb-8 hover:bg-gray-50 cursor-pointer ${stepSelected?.code === item.code ? 'bg-gray-100 shadow-md border border-gray-200' : ''}`} 
                onClick={() => setStepSelected(item)}
              >
                <h2 className='font-normal mb-2'>{item.name}</h2>
                {/* <h2 className='font-normal mb-2'>{item.stepTo ? item.name + item?.stepTo.name : item.name}</h2> */}
              </div>
            )
          }
        </div>
      );
  };

  const setPermissionsBySection = (section, type) => {
    if (section?.dinamicInput.length > 0) {
      section.dinamicInput.forEach(input => {
        setPermissionByInputAndType(input, type)
      });
    }
  }

  const template = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} justify-content-start`;
    return (
        <div className={className}>
            <div className='flex w-full justify-between items-center'>
              <div className='w-4/5 lg:w-4/5 xl:w-3/5'>
                <h2 className='font-bold'>{dinamicStage[options.props.positionP].text}</h2>
              </div>
              <div className='flex items-center justify-between gap-x-2'>
                <div className='flex gap-x-2'>
                  {/* <i 
                    className="pi pi-trash cursor-pointer" 
                    // onClick={()=> {setIdDeleteStage(options.props.valueID)}}
                  ></i> */}
                  <i 
                    className="pi pi-lock cursor-pointer"
                    onClick={()=> {setPermissionsBySection(dinamicStage[options.props.positionP], 'isReadOnly')}}
                  ></i>
                  <i
                   className="pi pi-eye cursor-pointer"
                   onClick={()=> {setPermissionsBySection(dinamicStage[options.props.positionP], 'isHidden')}}
                  ></i>
                </div>
                <button className={`${options.togglerClassName}`} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                </button>
              </div>
            </div>
        </div>
    )
  }

  return (
    <div className='content-scroll scroll'>
      <div className='grid grid-cols-12 gap-x-3'>
        {/* Timeline de etapas */}
        <div className='col-span-12 md:col-span-4'>
          <div className="process-permission-timeline border rounded py-5 px-2">
            <Timeline 
              value={sections} 
              className="customized-timeline" 
              marker={customizedMarker} 
              content={customizedContent} 
            />
          </div>
        </div>
        {/* Accordion de etapas */}
        <div className='col-span-12 md:col-span-8'>
            {
              dinamicStage?.map((stage, indexStage) => {

                const getPermission = (step, inputIdentifier, key) => {
                  const permissions = step?.permissions
                  if (permissions?.length > 0) {
                    const permission = permissions.find(permission => permission.identifierControl === inputIdentifier)
                    if (permission) {
                      return permission[key]
                    }
                    return false
                  } 
                  return false
                }
                
                return (
                  <Panel 
                    key={stage.id} 
                    headerTemplate={template} 
                    // header={stage.text}
                    positionP={indexStage} valueID={stage.id} toggleable className='mt-1 mb-1'
                    collapsed={indexStage !== 0}
                  >
                    <div className='flex flex-col gap-2'>
                      {
                        stage.dinamicInput.map((input, indexInput) => {
                          return (
                            <div 
                              className={`w-full input-dinamic-hover py-3 px-5 block`} key={input.id}
                            >
                              <div className='flex justify-between'>
                                  {
                                    (input.typeOptionId !== "ETIQUETA") ? (
                                      <div className='flex gap-x-1'>
                                        <p className='text-sm text-gray-300'>
                                          <span className={`${ getPermission(stepSelected, input.identifier, 'isHidden') ? 'line-through' : '' }`}>{input.fieldLabel}</span>
                                          <span>{input.required ? '*' : ''}</span>
                                        </p>
                                        <i className={`pi pi-lock text-gray-300 ${ getPermission(stepSelected, input.identifier, 'isReadOnly') ? '' : 'hidden' } `}></i>
                                      </div>
                                    ): <p></p>
                                  }
                                  <i
                                    className="pi pi-ellipsis-v cursor-pointer"
                                    onClick={(event) => {
                                        setInputSelected(input);
                                        menu.current.toggle(event)
                                        const idsModal1 = ['ID','TEXTO','TEXTO_LARGO','TEXTO_DINAMICO','NUMERO','FECHA','FECHA_HORA','IMAGEN','ADJUNTAR_ARCHIVO','ETIQUETA']
                                        const idsModal2 = ['LISTA_DESPLEGABLE','SELECCION_MULTIPLE','SELECCION_SIMPLE','TABLA','MONEDA']
                                        if (idsModal1.includes(input.typeOptionId)) {
                                          setTypeModal(1)
                                        }else if (idsModal2.includes(input.typeOptionId)) {
                                          setTypeModal(2)
                                        }
                                    }}
                                  ></i>
                                </div>
                              <PrevisualizationRender 
                                input={input} 
                                // dropRows={dropRows} 
                                // addRows={addRows} 
                                // index={index}
                              />
                            </div>
                          )
                        })
                      }
                    </div>
                  </Panel>
                )
              })
            }
            {/* <div className='mt-3'>
              <p className='font-bold'>Configurar Acciones</p>
            </div>
            <div className="flex justify-between gap-4 mt-3 px-1 py-1">
              <div className='flex gap-x-2'>
                <Button
                    type="button"
                    label="Mostrar"
                    className="sig-button sig-primary"
                />
                <Button
                    type="button"
                    label="Ocultar"
                    className="sig-button sig-secondary"
                />
              </div>
              <div className='flex gap-x-2'>
                <Button
                    type="button"
                    label="Retornar"
                    className="sig-button sig-primary"
                />
                <Button
                    type="button"
                    label="Enviar"
                    className="sig-button sig-primary"
                />
                <Button
                    type="button"
                    label="Rechazar"
                    className="sig-button sig-secondary"
                />
                <Button
                    type="button"
                    label="Aprobar"
                    className="sig-button sig-secondary"
                />
              </div>
            </div> */}
        </div>
      </div>
      <Menu model={items} popup ref={menu} id="popup_menu" />
    </div>
  )
}

export default Permission