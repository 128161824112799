import { RiskInventoryAction } from 'features/riskManagement/store/actions/RiskInventoryAction';
import { Calendar } from 'primereact/calendar';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalFilterComponent from 'shared/components/modal-filter/ModalFilterComponent';

const AdvancedFilterComponent = ({showModal, setShowModal}) => {

  const dispatch = useDispatch()
  const { filter } = useSelector( state => state.riskInventoryReducer.advancedFilter)
  const advancedFilter = useSelector((state) => state.riskInventoryReducer.advancedFilter);

  const [ formValues, setFormValues] = useState({
    name: null,
    from: null,
    to: null,
  })

  useEffect(() => {
    setFormValues({
        from: filter.from,
        to: filter.to,
        name: filter.name,
    });
}, [filter]);

  const onChangeFrom = (e) => {
    if (e.value)
      formValues.to = null;
    setFormValues({
      ...formValues,
      [e.target.name]: e.value,
    });
  }

  const onChangeTo = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.value,
    });
  }

  const handleSubmit = (e) => {
      e.preventDefault()
      advancedFilter.page = 1;
      dispatch(RiskInventoryAction.setFilter({filter: formValues}))
      setShowModal(false)
      dispatch(RiskInventoryAction.getData())
  }

  const clearFields = () => {
    setFormValues({
      ...formValues,
      from: null,
      to: null,
    });
  }

	if(showModal)
		return (
			<ModalFilterComponent
                disabledBtn={false}
                closeModal={()=> setShowModal(false)}
                clearFields={clearFields}
                handleSubmit={handleSubmit}
                // searchLoading={advancedFilter?.loading}
            >

                <div className="flex gap-4 pt-3">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                value={formValues.from || ""}
                                name="from"
                                id="desde"
                                onChange={(e) => onChangeFrom(e)}
                            />
                            <label htmlFor="desde">Desde</label>
                        </span>
                    </div>
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                value={formValues.to}
                                name="to"
                                id="hasta"
                                minDate={formValues?.from}
                                onChange={(e) => onChangeTo(e)}
                            />
                            <label htmlFor="hasta">Hasta</label>
                        </span>
                    </div>

                </div>
            </ModalFilterComponent>
		);
	return null;
};

export default AdvancedFilterComponent;