import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { Controller } from 'react-hook-form'

export const InputTextCustom = (props) => {

	return(
		<Controller
				rules={!props.required ? {} : {
					required: `${props.text} es requerido`,
			}}
        control={props.control}
				name={props.id.toString()}
				render={({field: { onChange, value}})=>
				{
					if((!value || value === "") && props.forDownload ){
						return <div></div>
					}else if(props.forDownload && !props.isTable){
						return (
						<div className='mb-4'>
							<div style={{fontSize: "0.75rem", color: "black",fontWeight:"bold"}}>
								{props.text}
							</div>
							<div style={{fontSize: "0.75rem", color: "#495057"}}>
								{value}
							</div>
						</div>
						)
					} else if(props.forDownload){
            return (
              <div style={{fontSize: "0.75rem", color: "#495057"}}>
                {value}
              </div>
            )
          } else if(props.show === "ShowWf"){
            return (
              <div className={`${!props.isTable && 'mb-6'}`}>
                <span className={`p-float-label w-full ${!props.isTable && 'mt-4'}`}>
                  <InputTextarea
                    disabled={props.show === "ShowWf"}
                    rows={1}
                    className={`w-5 ${props.errors[props.id.toString()] ? 'p-invalid w-full' : 'w-full'}`}
                    autoResize
                    value={value}
                    onChange={onChange}
                  />
                  {/* <InputText 
                    id={props.id} 
                    disabled={props.show === "ShowWf"}
                    value={value || ""} 
                    onChange={onChange} 
                    className={props.errors[props.id.toString()] ? 'p-invalid w-full' :  (props.show === "ShowWf"? 'disabled w-full':'w-full')}
                  /> */}
                  {
                    !props.isTable && <label htmlFor={props.id}>{props.isTable ? "": props.text}</label>
                  }			
                </span>
              </div>
            )
          }
					return (
					<div className={`${!props.isTable && 'mb-6'}`}>
						<span className={`p-float-label w-full ${!props.isTable && 'mt-4'}`}>
							<InputText 
								id={props.id} 
								disabled={props.show === "ShowWf"}
								value={value || ""} 
								onChange={onChange} 
								className={props.errors[props.id.toString()] ? 'p-invalid w-full' :  (props.show === "ShowWf"? 'disabled w-full':'w-full')}
							/>
							{
								!props.isTable && <label htmlFor={props.id}>{props.isTable ? "": props.text}</label>
							}			
						</span>
					</div>
				)}
				}
			/>
	)
}