import { useState , useEffect } from "react"
import { ProjectsActions } from '../store/actions/ProjectsActions';
import { TaskAction } from "features/customers/store/actions/TaskAction";
import { useDispatch , useSelector } from "react-redux"
const useTable = () =>{

  const { isLoadingTask }= useSelector(
    (state) => state.tasksReducer.editTask
  );

  const  { isLoadingTasks , tasks , pagination } =  useSelector(
    (state) => state.projectsReducer.tasks
  );

 
  const dispatch = useDispatch();
  const [mode,setMode] = useState("new")
  const [rowsTable , setRows] = useState(10)
  const [first,setFirst] = useState(0)  
  const [idTask, setIdTask] = useState("");
  const [documentId, setDocumentId] = useState(0);
  const [isAddEdit , setIsAddEdit] = useState(false)
  const search = (id,page = 1) => {
    dispatch(ProjectsActions.getTasksDeliverable(parseInt(id),page))
  }

  const onPage = ({rows , page , first}) =>{
    search(documentId,page+1)
    setFirst(first)
  }

  useEffect(() =>{
    if(isLoadingTask && !isAddEdit){
      setIsAddEdit(true)
    }else if(!isLoadingTask && isAddEdit){
      search(documentId)
      setIsAddEdit(false)
    }
  },[isLoadingTask])

  return {
    idTask,
    setIdTask,
    setDocumentId,
    documentId,
    onPage,
    isLoadingTask,
    first,
    setFirst,
    search,
    rowsTable,
    pagination,
    tasks,
    isLoadingTasks,
    mode,
    setMode
  }
}

export default useTable