const { AppConstans } = require("shared/commons/AppConstans");

const setParameters = (payload) => ({
  type: AppConstans.Accion.Parameters,
  payload,
});

export const AppActions = {
  setParameters,
};
