import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useForm } from 'shared/hooks/useForm';
import { ConfigurationConstants } from 'features/configuration/commons/ConfigurationConstants';
import { UsersAction } from 'features/configuration/store/actions/UsersAction';
import { CoreConstants } from 'core/commons/CoreConstants';
import { useEffect } from 'react';

const useAdvancedFilter = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.userReducer.dataManager);
  const { values } = useSelector((state) => state.userReducer.userFilter);

  const [formValues, handleInputChange, reset] = useForm({
    fromDate: null,
    toDate: null,
    companyId: null,
    locationId: null,
    roleId: null,
    statusId: null,
  });

  useEffect(()=> {
    if(data){
      if((data.status.find(el => el.code === "ACTI"))){
        const initialStatusId = {
          target: {
            name: "statusId",
            value: (data.status.find(el => el.code === "ACTI")).id
          }
        }
        handleInputChange(initialStatusId)
      }
    }
  },[data])

  const companies = data?.company ?? [];
  const roles = data?.roles ?? [];
  const status = data?.status ?? [];

  const [locations, setLocations] = useState([]);
  const formKeys = {
    companyId: 'businessName',
    locationId: 'name',
    roleId: 'description',
  };

  useEffect(() => {
    if (values.length === 0) reset();
  }, [values]);

  const showPiners = values.length > 0;


  const buildFilterValues = () => {
    const currentValues = { ...formValues };
    const currentPiners = [];
    for (let valueKey in formValues) {
      const key = valueKey.toLocaleLowerCase();
      const field = valueKey;
      const isHasValue = formValues[valueKey];
      if (!isHasValue) {
        delete formValues[valueKey];
      } else if (key.includes('date')) {
        const value = moment(formValues[valueKey]).format(
          CoreConstants.Format.LOCALE
        );
        const date = formValues[valueKey];
        currentPiners.push({ field, value, date });
      } else if (key.includes('id') && key !== 'statusid' && isHasValue) {
        const value = formValues[valueKey][formKeys[valueKey]];
        const option = formValues[valueKey];
        currentPiners.push({ field, value, option });
        currentValues[valueKey] = formValues[valueKey].id;
      } else if (key === 'statusid') {
        const value = status.filter((s) => s.id === formValues[valueKey])[0]
          ?.description;
        const optionId = formValues[valueKey];
        currentPiners.push({ field, value, optionId });
      }
    }

    return {
      currentPiners,
      currentValues,
    };
  };
  const clearFields = () => reset();
  const advancedfilter = (e) => {
    e.preventDefault();
    let { currentValues, currentPiners } = buildFilterValues();

    const currentSearchValue =
      values.find(({ field }) => field === 'fullName') ?? {};

    if (Object.values(currentSearchValue).length > 0) {
      currentValues.fullName = currentSearchValue.value;
      currentPiners = [...currentPiners, currentSearchValue];
    }

    dispatch(UsersAction.getUsers({ fields: currentValues, change: true }));
    dispatch({
      type: ConfigurationConstants.Accion.Users.CHANGE_FILTER_VALUES,
      payload: { showModal: false, values: currentPiners },
    });
  };

  const handleChange = (e) => {
    const {
      target: { name, value = null },
    } = e;
    if (name === 'companyId') setLocations(value?.locations);
    handleInputChange(e);
  };

  const toggleModal = (isOpen) => {
    dispatch({
      type: ConfigurationConstants.Accion.Users.CHANGE_FILTER_VALUES,
      payload: { showModal: isOpen },
    });
  };

  const reportLoading = useSelector(
    (state) => state.userReducer.downloadReport.loading
  );
  const generateReport = () => dispatch(UsersAction.generateUserReport());
  return {
    formValues,
    advancedfilter,
    handleChange,
    companies,
    locations,
    roles,
    status,
    toggleModal,
    generateReport,
    reportLoading,
    showPiners,
    piners: values,
    clearFields,
  };
};

export default useAdvancedFilter;
