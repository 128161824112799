
import { MonitoringAction } from 'features/indicators/store/actions/MonitoringAction';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid';

const useMonitoringModal = (indicatorSelected) => {
  
  const [employeesList, setEmployeesList] = useState([])
  // const [employeeError, setEmployeeError] = useState(null)
  const [employeeErrors, setEmployeeErrors] = useState([])
  const { isSaved } = useSelector((state) => state.monitoringReducer.monitoringValues);
  const dispatch = useDispatch()

  useEffect(() => {
    if (indicatorSelected) {
        dispatch(MonitoringAction.getValuesByIndicatorId(indicatorSelected.id));
    }
}, [indicatorSelected, dispatch])

  useEffect(() => {
    if (isSaved) {
        dispatch(MonitoringAction.getValuesByIndicatorId(indicatorSelected.id));
    }
}, [isSaved, dispatch])


  const createEmployee = () => {
    setEmployeesList((prev) => [
      ...prev,
      {
        id: uuidv4(),
        valueDate: null,
        creationDate: '',
        creationUser: '',
        value: '',
        comments: ''
      },
    ]);
  };

  const deleteEmployee = (id) => {
    const currentEmployeesList = employeesList.filter((employee) => employee.id !== id);
    setEmployeesList(currentEmployeesList);
  };

  const updateEmployee = ({ id, value, name}) => {
    const currentEmployeesList = employeesList.map((employee) => {
      // console.log(first)
      if (employee.id === id) {
        // if (name === 'value' && value >= 0) employee[name] = Number(value);
        if (name === 'value')  {
          // if (value === '-') {
          //   employee[name] = '-';
          // } 
          // else if (isNaN(value)) {}
          // else {
          //   employee[name] = Number(value);
          // }

          employee[name] = value;
        }
        // if (name === 'value')  employee[name] =  value === '-' ? '-' : Number(value);
        if (name === 'valueDate') employee[name] = value;
        if (name === 'comments') employee[name] = value;
      }
      return employee;
    });

    setEmployeesList(currentEmployeesList);

    const existErrorInEmployee = employeeErrors.find((employeeError) => employeeError.id === id)
    if (existErrorInEmployee) {
      const currentEmployeeErrors = employeeErrors.map((employeeError) => {
        if (employeeError.id === id) {
          return {
            ...employeeError,
            [name]: false
          }
        }
        return employeeError;
      });
      setEmployeeErrors(currentEmployeeErrors)
    }
  };

  const onBlurValueDecimals = ({ id, name}) => {
    const currentEmployeesList = employeesList.map((employee) => {
      if (employee.id === id) {
        if (name === 'value')  {
          // employee[name] = Number(employee.value).toFixed(2);
          employee[name] = Number(employee.value).toFixed(indicatorSelected?.decimalPositions || 0);
        }
      }
      return employee;
    });

    setEmployeesList(currentEmployeesList);
  }


  return {
    employeesList,
    // employeeError,
    createEmployee,
    deleteEmployee,
    updateEmployee,
    onBlurValueDecimals,
    setEmployeesList,
    dispatch,
    // setEmployeeError,


    employeeErrors,
    setEmployeeErrors
  }
}

export default useMonitoringModal