import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";
import { CoreConstants } from 'core/commons/CoreConstants';
import { TaskAction } from "features/customers/store/actions/TaskAction";
import useSetTitlePage from 'shared/hooks/useSetTitlePage';

export const useCustomerTask = (props) => {
  const pageCustomerTask = props?.pageCustomerTask
  const dispatch = useDispatch();
  const history = useHistory();
  const { updateTitle } = useSetTitlePage();

  const newPagePath = useSelector((state) => state.tasksReducer.currentAction.newPagePath);
  const companyIdSelect = useSelector((state) => state.companiesReducer.currentAction.companyId);
  const params = useParams();
  const companyId = Number( params.customerId || companyIdSelect)
  const taskId = params.id;

  const { pathname } = history.location;

  /* TITLE ------------------------- */
  const pageTitle = {
    title: "Clientes",
    subtitle: "Empresas",
    description: 'Tareas',
    previousUrl: `/cliente/empresas/editar/${companyId}`
  };
  
  const setNewPage = () => {history.push(newPagePath);};

  useEffect(() => {
    if(pathname==='/inicio'){
      updateTitle({
        title: "Inicio"
      })
    }else{
      updateTitle(pageTitle);
    }
  }, []);
  
  useEffect(() => {
    if(!pageCustomerTask) dispatch(TaskAction.getDataMaster());
  }, []);
  
  const dataMaster = useSelector((state) => state.tasksReducer.dataMaster.data);
  const sourceId = dataMaster["taskSources"]?.filter(
    ({ code }) => code === CoreConstants.Task.Source.CUSTOMER
  )[0].id;

  useEffect(() => {
    dispatch(TaskAction.currentAction({ 
      sourceId: sourceId, 
      companyId: companyId,
      listPagePath: `/cliente/empresas/editar/${companyId}/tareas`,
      newPagePath: `/cliente/empresas/editar/${companyId}/tareas/nuevo`,
      editPagePath: `/cliente/empresas/editar/${companyId}/tareas/editar`
    }));
  }, [companyId, sourceId]);

  const addComment = (e, sourceId, comment) => {
    e.preventDefault();
    dispatch(TaskAction.addComment(Number(sourceId), comment));
  }

  const deleteComment = (e, commentId, documentId) => {
    e.preventDefault();
    dispatch(TaskAction.deleteComment(commentId, Number(documentId)));
  }

  const getComments = (taskId) => {
    dispatch(TaskAction.getCommentsByTaskById(Number(taskId)));
  }

  return {
    companyId,
    sourceId,
    taskId,
    addComment,
    deleteComment,
    getComments,
    setNewPage
  }
}