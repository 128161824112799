import { CoreConstants } from "core/commons/CoreConstants";
import { toastAction } from "core/store/actions/ToastAction";
import { RiskMatrixConstans } from "features/riskManagement/commons/RiskMatrixConstans";
import { RiskMatrixServices } from "features/riskManagement/services/RiskMatrixServices";
import FileSaver from "file-saver";

const setData = (payload) => ({
  type: RiskMatrixConstans.Accion.MatrixManagement.GET_DATA,
  payload,
});

const setTable = (payload) => ({
  type: RiskMatrixConstans.Accion.MatrixManagement.SET_TABLE,
  payload,
});

const setMatrix = (payload) => ({
  type: RiskMatrixConstans.Accion.MatrixManagement.SET_MATRIX,
  payload,
});

const setDownload = (payload) => ({
  type: RiskMatrixConstans.Accion.MatrixManagement.SET_DOWNLOAD,
  payload,
});

const generateReport = (id) => (dispatch, getState) => {
  dispatch(setDownload({ isLoading: true }));
  const { data } = getState().riskMatrixReducer.matrix;
  const payload = {
    processIdentifier: id,
    criteria: [
      {
        processFormControlId: data.filter[0].id,
        valueText: "",
      },
    ],
  };
  RiskMatrixServices.generateReport(payload)
    .then(({ data }) => {
      dispatch(setDownload({ isLoading: false }));
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      });

      FileSaver.saveAs(blob, "Matriz.xls");
    })

    .catch((err) => {
      dispatch(setDownload({ isLoading: false }));
      dispatch(
        toastAction.error(
          "Matriz de Riesgo",
          "Hubo un error al descargar el archivo"
        )
      );
    });

  // console.log(payload)
};

const getData = (payload) => (dispatch, getState) => {
  dispatch(
    setData({
      isLoadingData: true
    })
  )
  
  RiskMatrixServices.searchMatrix(payload).then(({ data }) => {
    const d = getState().riskMatrixReducer.table.model;

    if (data.data.items.length > 0) {
      const dataModel = [];
      const aux = [];
      const initial = {};

      data.data.items.forEach((item) => {
        const row = {};
        const model = JSON.parse(JSON.stringify(d));

        item.inventories.forEach((inv) => {
          row[`${inv.columnNumber - 1}-InventoryId_${inv.inventoryId}`] =
            inv.valueText;
          model[
            `${inv.columnNumber - 1}-InventoryId_${inv.inventoryId}`
          ].idItem = inv.id;
          if (inv.valueIdList) {
            initial[
              `${inv.columnNumber - 1}-InventoryId_${inv.inventoryId}-row-${
                item.id
              }`
            ] = inv.valueText.map((ele, index) => ({
              id: Number(inv.valueIdList[index]),
              text: ele,
              itemValue: null,
            }));
          } else {
            if (inv.valueText) {
              initial[
                `${inv.columnNumber - 1}-InventoryId_${inv.inventoryId}-row-${
                  item.id
                }`
              ] = inv.valueText[0];
            } else {
              initial[
                `${inv.columnNumber - 1}-InventoryId_${inv.inventoryId}-row-${
                  item.id
                }`
              ] = undefined;
            }
          }
        });

        let index = 0;

        item.evaluations.forEach((eva) => {
          eva.criteria.forEach((crit) => {
            row[`${crit.columnNumber - 1}-CriteriaId_${crit.criteriaId}`] = [
              crit.selectedValueText,
            ];
            model[
              `${crit.columnNumber - 1}-CriteriaId_${crit.criteriaId}`
            ].idItem = crit.id;
            initial[
              `${crit.columnNumber - 1}-CriteriaId_${crit.criteriaId}-row-${
                item.id
              }`
            ] = {
              id: crit.selectedValueId,
              itemValue: crit.values.find(
                (ele) => ele.name === crit.selectedValueText
              ).value,
              text: crit.selectedValueText,
            };
          });
          
          if(eva.impact){
            row[`${eva.impact.columnNumber - 1}-ImpactId_${eva.evaluationId}`] = [
              eva.impact.valueText,
            ];
            model[
              `${eva.impact.columnNumber - 1}-ImpactId_${eva.evaluationId}`
            ].idItem = eva.impact.id;
            initial[
              `${eva.impact.columnNumber - 1}-ImpactId_${eva.evaluationId}-row-${
                item.id
              }`
            ] = {
              id: eva.impact.valueId,
              itemValue: eva.impact.sourceValue,
              text: eva.impact.valueText,
            };
          }

          if(eva.probability){
            row[`${eva.probability.columnNumber - 1}-ProbabilityId_${eva.evaluationId}`] = [
              eva.probability.valueText,
            ];
            model[
              `${eva.probability.columnNumber - 1}-ProbabilityId_${eva.evaluationId}`
            ].idItem = eva.probability.id;
            initial[
              `${eva.probability.columnNumber - 1}-ProbabilityId_${eva.evaluationId}-row-${
                item.id
              }`
            ] = {
              id: eva.probability.valueId,
              itemValue: eva.probability.sourceValue,
              text: eva.probability.valueText,
            };
          }

          if(eva.process){
            row[`${eva.process.columnNumber - 1}-LevelId_${eva.evaluationId}`] = [
              eva.process.valueText,
            ];
            model[
              `${eva.process.columnNumber - 1}-LevelId_${eva.evaluationId}`
            ].idItem = eva.process.id;
            initial[
              `${eva.process.columnNumber - 1}-LevelId_${eva.evaluationId}-row-${
                item.id
              }`
            ] = {
              Id: eva.process.valueId,
              Text: eva.process.valueText,
            };
          }

          row[`${eva.result.columnNumber - 1}-formula`] = [
            eva.level?.valueText ||
            eva.result.resultValue.toFixed(eva.result.decimalPositions),
            eva.result.colorCode,
          ];
          model[`${eva.result.columnNumber - 1}-formula`].idItem =
            eva.result.id;
          index = Math.max(index, eva.result.columnNumber);
        });

        row.id = item.id;
        model.id = item.id;

        aux.push(row);
        dataModel.push(model);
      });

      dispatch(
        setData({
          data: aux,
          initialValues: initial,
          dataInitialModel: dataModel,
        })
      );
    }
  }).finally(()=>{
    dispatch(
      setData({
        isLoadingData: false
      })
    )
  })
};

const setValues = (payload) => ({
  // {id: valor}
  type: RiskMatrixConstans.Accion.MatrixManagement.SET_VALUES,
  payload,
});

const getMatrixStructure = (id) => (dispatch) => {
  dispatch(setMatrix({ isLoading: true }));
  RiskMatrixServices.getMatrixStructure(id)
    .then(({ data }) => {
      if (data.status === CoreConstants.HttpResponse.OK) {
        const newData = data.data;
        const model = {};
        const columns = [];
        let cont = 0;
        newData.inventory.forEach((element, index) => {
          if (element.code === "SELECCION_MULTIPLE") {
            element.column = cont + 1;
            element.idItem = null;
            model[`${cont}-InventoryId_${element.id}`] = element;
            columns.push({ field: `${cont}-InventoryId_${element.id}` });
          } else {
            element.column = cont + 1;
            element.idItem = null;
            model[`${cont}-InventoryId_${element.id}`] = element;
            columns.push({ field: `${cont}-InventoryId_${element.id}` });
          }
          cont++;
        });

        let decimal = 0;

        newData.evaluations.forEach((eva, indexEva) => {
          if(eva.formula === ""){
            columns.push({ field: `${cont}-ProbabilityId_${eva.probabilities.id}` });
            model[`${cont}-ProbabilityId_${eva.probabilities.id}`] = {...eva.probabilities, evaluationId: eva.id, idItem: null, column: cont + 1,};
            cont++;

            columns.push({ field: `${cont}-ImpactId_${eva.impacts.id}` });
            model[`${cont}-ImpactId_${eva.probabilities.id}`] = {...eva.impacts, evaluationId: eva.id, idItem: null, column: cont + 1,};
            cont++;
            
            columns.push({ field: `${cont}-LevelId_${eva.level.id}` });
            model[`${cont}-LevelId_${eva.level.id}`] = {
              formula:`[ProbabilityId:${eva.probabilities.id}] [*] [ImpactId:${eva.impacts.id}]`,
              rowId: `ProbabilityId_${eva.probabilities.id}`,
              colId: `ImpactId_${eva.impacts.id}`,
              evaluationId: eva.id,
              column: cont + 1,
              idItem: null,
              decimalPositions: eva.decimalPositions,
              matrix: eva.matrix,
              evaluationType: "RMMAT"
            };;
            cont++;

            model[`${cont}-formula`] = {
              formula:`[ProbabilityId:${eva.probabilities.id}] [*] [ImpactId:${eva.impacts.id}]`,
              rowId: `ProbabilityId_${eva.probabilities.id}`,
              colId: `ImpactId_${eva.impacts.id}`,
              evaluationId: eva.id,
              column: cont + 1,
              idItem: null,
              decimalPositions: eva.decimalPositions,
              matrix: eva.matrix,
              evaluationType: "RMMAT"
            };
            columns.push({ field: `${cont}-formula` });
            cont++;

            decimal = Math.max(decimal, eva.decimalPositions);
          } else {
            eva.criterias.forEach((criteria, indexCri) => {
              if (criteria.code === "SELECCION_MULTIPLE") {
                criteria.column = cont + 1;
                criteria.evaluationId = eva.id;
                criteria.idItem = null;
                columns.push({ field: `${cont}-CriteriaId_${criteria.id}` });
                model[`${cont}-CriteriaId_${criteria.id}`] = criteria;
              } else {
                criteria.column = cont + 1;
                criteria.evaluationId = eva.id;
                criteria.idItem = null;
                model[`${cont}-CriteriaId_${criteria.id}`] = criteria;
                columns.push({ field: `${cont}-CriteriaId_${criteria.id}` });
              }
              cont++;
            });
            model[`${cont}-LevelId`] = {...eva, evaluationId: eva.id, idItem: null, column: cont + 1,}
            columns.push({ field: `${cont}-LevelId` });
            cont++;

            model[`${cont}-formula`] = {
              formula: eva.formula,
              evaluationId: eva.id,
              column: cont + 1,
              idItem: null,
              decimalPositions: eva.decimalPositions,
              condition: eva.condition
            };
            columns.push({ field: `${cont}-formula` });
            cont++;
            decimal = Math.max(decimal, eva.decimalPositions);
          }
        });

        // model[`${cont}-resultado`] = "";
        // model[`${cont + 1}-condicion`] = newData.results.condition;
        // model[`${cont + 2}-expediente`] = newData.results.condition.find(
        //   (item) => item.showFileRecord
        // ) || {
        //   id: 2,
        //   name: "a",
        //   showFileRecord: true,
        //   from: 19,
        //   to: 21,
        //   values: null,
        //   colorCode: "#1281F8",
        //   data: [
        //     { text: "opcion 1", id: 1 },
        //     { text: "opcion 2", id: 2 },
        //   ],
        //   dynamicProcessId: null,
        // };
        // columns.push({ field: `${cont}-resultado` });
        // columns.push({ field: `${cont + 1}-condicion` });
        // columns.push({ field: `${cont + 2}-expediente` });

        const payload = {
          processIdentifier: id,
          criteria: [
            {
              processFormControlId: newData.filter[0].id,
              valueText: "",
            },
          ],
        };
        dispatch(
          setMatrix({
            isLoading: false,
            data: newData,
            decimalPositions: decimal,
          })
        );
        dispatch(setTable({ isLoading: false, model, columns }));
        dispatch(getData(payload));
      } else if (data.status > CoreConstants.HttpResponse.OK) {
        dispatch(setMatrix({ isLoading: false }));
        dispatch(toastAction.warn("Matrices de riesgos", data.message));
      } else {
        dispatch(setMatrix({ isLoading: false }));
        dispatch(toastAction.error("Matrices de riesgos", data.message));
      }
    })
    .catch((e) => {
      console.log(e);
      dispatch(toastAction.error("Matrices de riesgos", "Error"));
      dispatch(setMatrix({ isLoading: false }));
    });
  // dispatch(setTable({data: table, isLoading: false}))
};

const clearMatrixStructure = () => ({
  type: RiskMatrixConstans.Accion.MatrixManagement.SET_MATRIX,
  payload: { data: {}, isLoading: true, decimalPositions: 0 },
});

const clearTable = () => ({
  type: RiskMatrixConstans.Accion.MatrixManagement.SET_TABLE,
  payload: { data: [], model: {}, columns: [], isLoading: true },
});

const clearData = () => ({
  type: RiskMatrixConstans.Accion.MatrixManagement.GET_DATA,
  payload: {
    data: [],
    initialValues: {},
    dataInitialModel: [],
  },
});

const saveMatrix = (send, id, setMode) => (dispatch, getState) => {
  dispatch(setMatrix({ isSaved: false }));
  RiskMatrixServices.saveMatrix(send)
    .then(({ data }) => {
      if (data.status === CoreConstants.HttpResponse.OK) {
        const data = getState().riskMatrixReducer.matrix.data
        const payload = {
          processIdentifier: id,
          criteria: [
            {
              processFormControlId: data.filter[0].id,
              valueText: "",
            },
          ],
        };
        dispatch(getData(payload));
        dispatch(setMatrix({ isSaved: true }));
        dispatch(
          toastAction.success("Matriz de Riesgos", "Se guardó correctamente")
        );
        setMode("lectura");
      } else if (data.status > CoreConstants.HttpResponse.OK) {
        dispatch(setMatrix({ isSaved: true }));
        dispatch(toastAction.warn("Matriz de Riesgos", data.message));
      } else {
        dispatch(setMatrix({ isSaved: true }));
        dispatch(toastAction.error("Matriz de Riesgos", data.message));
      }
    })
    .catch((e) => {
      console.log(e);
      dispatch(setMatrix({ isSaved: true }));
      dispatch(toastAction.error("Matriz de riesgos", "Error"));
    });
};

const setColumn = (payload) => ({
  type: "setColumn",
  payload,
});

export const RiskMatrixAction = {
  setColumn,
  getData,
  setValues,
  getMatrixStructure,
  clearMatrixStructure,
  clearTable,
  clearData,
  saveMatrix,
  generateReport,
};
