import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { DinamicProcessAction } from "features/configuration/store/actions/DinamicProcessAction";
import RenderedControls from "features/configuration/components/dinamicProcess/dinamicProcessView/RenderedControls";
import useDinamicProcess from "features/configuration/hooks/dinamicProcess/useDinamicProcess";
import { v4 as uuidv4 } from 'uuid';
import { useForm } from 'react-hook-form';

const DinamicProcessFormPage = ({title}) => {
  
  const {updateTitle } = useSetTitlePage()
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  
  const {data: processSelected, isLoading} = useSelector(state => state.dinamicProcessReducer.process)
  const {data: processData, isLoading: isLoadingProcessData, isSaved, isSaving} = useSelector(state => state.dinamicProcessReducer.processData)
  const advancedFilter = useSelector((state) => state.dinamicProcessReducer.advancedFilter);
  const processSections = useSelector(state => state.dinamicProcessReducer.process.data?.sections)
  const listControls = useSelector(state => state.dinamicProcessReducer.listControls.data)
  const {isSended} = useSelector(state => state.dinamicProcessReducer.stepStatus)

  
  const currentStepWorkFlowCode = processData?.workFlow
  const workFlow = processSelected?.workFlowSteps

  //* Si es el primer paso
  // const isFirstStep = currentStepWorkFlowCode?.code === workFlow?.[1]?.code
  const isInitialProcess = params.idrow ? false : true
  const isFirstStep = currentStepWorkFlowCode?.code === workFlow?.find(step => step.order === 2)?.code
  const isLastStep = currentStepWorkFlowCode?.code === workFlow?.find(step => step.typeCode === 'END')?.code
  //* Encontrar los permisos del paso actual y mostrarlos en los controles
  // const currentStepWorkFlow = workFlow?.find(step => {
  //   if (isInitialProcess) {
  //     return step.order === 2
  //   }
  //   return step.code === currentStepWorkFlowCode?.code
  // })
  const currentStepWorkFlow = workFlow?.find(step => {
    return isInitialProcess ? (step.order === 2) : (step.code === currentStepWorkFlowCode?.code)
  })
  const currentStepPermissions = currentStepWorkFlow?.permissions
  const currentStepPermissionsControls = currentStepPermissions?.map(permission => permission.identifierControl)
  const currentStepPermissionsHiddenControls = currentStepPermissions?.filter(permission => permission.isHidden === true).map(permission => permission.identifierControl)
  
  const [isFirstLoading, setFirstLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [formControls, setFormControls] = useState([])
  const [formSections, setFormSections] = useState([])
  const [formIds, setFormIds] = useState({})
  const [tasks, setTasks] = useState({})
  
  //* Obtener las secciones cuyos controles no tienen permisos de visibilidad (isHidden)
  const formSectionsPermissions = formSections.filter(section => {
    //* Validar si todos sus controles estan incluidos en los controles sin permisos de visibilidad (currentStepPermissionsHiddenControls)
    const controlsWithNoVisiblesPermissions = section.controls.every(control => currentStepPermissionsHiddenControls?.includes(control.identifier))
    //* Filtrar las secciones que tienen todos sus controles sin permisos de visibilidad
    if (controlsWithNoVisiblesPermissions) {
      return false
    }
    return true
  })

  let controlsNoHidden = []

  //* Obtener los controles que no tienen permisos de visibilidad (isHidden)
  formControls.forEach(control => {
    if (!currentStepPermissionsHiddenControls?.includes(control.identifier)) {
      controlsNoHidden.push(control)
    }
  })


  const {
    control: controlInput, errors, valuesFields, existError, setValue, handleSubmit, reset
  } = useDinamicProcess()

  const {
    handleSubmit: handleSubmitValidation,
    control: controlValidation,
    formState: { errors: errorsValidation },
  } = useForm({shouldUnregister: true});

  const redirectToSearch = () => {
    history.push(`/proceso-dinamico/${params.id}`);
    // dispatch(EmployerAction.resetAfterSaved());
  }
  
  useEffect(()=> {
    updateTitle({
      title: "Proceso Dinámico",
      subtitle: processSelected?.name,
      description: title,
      previousUrl: `/proceso-dinamico/${params.id}`
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processSelected])

  useEffect(() => {
    if (isFirstLoading) {
      setFirstLoading(false);
      if (params.id)
          getById()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  useEffect(() => {
    if (params.idrow) {
      dispatch(DinamicProcessAction.getDynamicProcessById(params.idrow))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.idrow])

  useEffect(() => {
    if (isSaved) {
      dispatch(DinamicProcessAction.resetAfterSaved())
      reset()
      redirectToSearch()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaved])

  useEffect(() => {
    if (isSended) {
      dispatch(DinamicProcessAction.resetAfterSaved())
      reset()
      redirectToSearch()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSended])

  //* Establecer los controles y secciones del formulario
  // TODO: Agregar de usuario si es single o multiple
  useEffect(() => {
    const fileControls = ['IMAGEN', 'ADJUNTAR_ARCHIVO']
    if(processSelected && listControls && advancedFilter){
      let valuesFilters = {}
      let controls = []
      //* Recorrido de las etapas
      const sections = processSections.map(section=> {
        //* Recorrido de los controles de cada etapa
        section.controls.map(control => {
          const typeControl = listControls.find(el => el.id === control.controlId).code
          control.type = typeControl
          control.sectionId = section.id
          valuesFilters[control.identifier] = (typeControl === 'SELECCION_MULTIPLE' || typeControl === 'TABLA' || (typeControl === 'USUARIOS' && control.isMultiple) || typeControl === 'CONEXION_A_DATOS' || typeControl === 'CONEXION_A_PROCESOS') ? [] : null
          if(fileControls.includes(typeControl)) {
            valuesFilters[control.identifier + '_b64'] = null
          }
          //* Recorrido de details de Control Tabla para el control de las celdas
          const newControlDetails = control?.details?.map(detail => {
              if (typeControl === 'TABLA') {
                const detailTypeControl = listControls.find(el => el.id === detail.controlId).code
                detail.type = detailTypeControl
              }
              detail.controlId = control.controlId //* Se agrega el id del control al detail
              return detail
            })
          control.details = newControlDetails || [] // TODO
          
          // if (typeControl !== 'ID' && typeControl !== 'ETIQUETA') controls.push(control)
          if (typeControl !== 'ETIQUETA') controls.push(control)
          return control
        })
        return section
      })

      reset(valuesFilters)
      setFormSections(sections)
      setFormControls(controls)
    }
  }, [advancedFilter, processSelected, listControls, processSections]);
  // }, [advancedFilter, processSelected, listControls]);

  
  useEffect(() => {
    // TODO: CONEXION_A_DATOS CONEXION_A_PROCESOS USUARIOS
    const textControls = ['TEXTO', 'TEXTO_LARGO', 'TEXTO_DINAMICO', 'NUMERO', 'MONEDA']
    const dateControls = ['FECHA', 'FECHA_HORA']
    const referenceIdControls = ['LISTA_DESPLEGABLE', 'SELECCION_SIMPLE']
    const multipleControls = ['SELECCION_MULTIPLE']
    const tablaControls = ['TABLA']
    const fileControls = ['IMAGEN', 'ADJUNTAR_ARCHIVO']
    if (params.idrow) {
      if(processData && listControls){
        let ids = {}
        processData?.details.map(detail => {
          const typeControl = listControls.find(control => control.id === detail.controlId).code
          const control = formControls.find(control => control.id === detail.details[0].processFormControlId)
          //* Para control tipo multiple
          if (multipleControls.includes(typeControl) ) {
            let detailIdentifier = ''
            const newDetailDetails = detail.details.map((detailDetail) => {
              const control = controlsNoHidden.find(control => control.id === detailDetail.processFormControlId)
              detailIdentifier = control?.identifier
              const model = {
                id: detailDetail?.valueReferenceId,
                text: detailDetail?.valueText,
                details: [],
                controlId: detailDetail.controlId,
                processDataDetailId: detailDetail.processDataDetailId
              }
              return model
            })
            setValue(detailIdentifier, newDetailDetails)
            return detail
          } else if(tablaControls.includes(typeControl)) {
            let detailIdentifier = ''
            let contador = 0
            let dataTable = []
            let model = {}
            // detail.details.forEach((detailDetail) => {
            const elements = detail.details.sort((a, b) => {return a.processDataDetailId - b.processDataDetailId})

            const rowsExtraValue = []

            elements.forEach( detailDetail => {
              if(!rowsExtraValue.includes(detailDetail.extraValue) && detailDetail.extraValue){
                rowsExtraValue.push(detailDetail.extraValue)
              }
            })

            const groupedArray = elements.reduce((acc, currentValue) => {
              const key = currentValue.extraValue;
              if (!acc[key]) {
                  acc[key] = [];
              }
              acc[key].push(currentValue);
              return acc;
            }, {});
            
            const resultArray = Object.values(groupedArray);
            const tasks = {}
            let task = {};

            if(elements[0].extraValue){
              resultArray.forEach((detailDetail) => {
                const control = controlsNoHidden.find(control => control.id === detailDetail[0].processFormControlId)
                detailIdentifier = control?.identifier
                if (control?.details) {
                  const indexResponsible = control.details.findIndex(col => col.fieldCode === "TASK_FIELD_RESPONSIBLE")
                  const indexTitle = control.details.findIndex(col => col.fieldCode === "TASK_FIELD_TITLE")
                  const indexDate = control.details.findIndex(col => col.fieldCode === "TASK_FIELD_SCHEDULE_DATE")

                  if(control?.details[contador].isMultiple){

                    model[control?.details[contador].text] = detailDetail.map( detail => ({
                      id: detail.valueReferenceId,
                      text: detail.valueText,
                    }))
                  } else {
                    model[control?.details[contador].text] = detailDetail[0].valueReferenceId ? {
                      id: detailDetail[0].valueReferenceId,
                      text: detailDetail[0].valueText
                    } : detailDetail[0].valueText

                    if(indexResponsible === contador){
                      task.documentId = detailDetail[0].processDataDetailId
                      task.responsibleId = detailDetail[0].valueReferenceId
                    } else if(indexTitle === contador){
                      task.title = detailDetail[0].valueText
                    } else if(indexDate === contador){
                      task.scheduleDate = detailDetail[0].valueText
                    }

                  }

                  if (contador <  control?.details.length - 1) {
                    model.id = contador === 0 ? `${detailDetail[0].processDataDetailId}` : model.id.concat(`-${detailDetail[0].processDataDetailId}`)
                    contador++
                  } else {
                    model.id = model.id.concat(`-${detailDetail[0].processDataDetailId}`)
                    model.isNewRow = false
                    contador = 0
                    dataTable.push(model)
                    tasks[model.id] = task
                    model = {}
                    task = {}
                  }
                }
              })
            } else {
              elements.forEach((detailDetail) => {
                const control = controlsNoHidden.find(control => control.id === detailDetail.processFormControlId)
                detailIdentifier = control?.identifier
                if (control?.details) {
                  if (contador <  control?.details.length - 1) {
                    model[control?.details[contador].text] = detailDetail.valueReferenceId ? {
                      id: detailDetail.valueReferenceId,
                      text: detailDetail.valueText
                    } : detailDetail.valueText
                    model.id = contador === 0 ? `${detailDetail.processDataDetailId}` : model.id.concat(`-${detailDetail.processDataDetailId}`)
                    contador++
                  } else {
                    model[control?.details[contador].text] = detailDetail.valueReferenceId ? {
                      id: detailDetail.valueReferenceId,
                      text: detailDetail.valueText
                    } : detailDetail.valueText
                    model.id = model.id.concat(`-${detailDetail.processDataDetailId}`)
                    model.isNewRow = false
                    contador = 0
                    dataTable.push(model)
                    model = {}
                  }
                }
              })
            }
            setTasks(tasks)
            setValue(detailIdentifier, dataTable)
            return detail
          } 
          else if(typeControl === "USUARIOS" && control?.isMultiple === true){
            const idsSelected = detail.details.map(x => x.valueReferenceId)
            const newDetailDetails = control.details.filter(x => idsSelected.includes(x.id))
            const controlNoHidden = controlsNoHidden.find(control => control.id === detail.details[0].processFormControlId)
            const detailIdentifier = controlNoHidden.identifier
            setValue(detailIdentifier, newDetailDetails)
            //   detailIdentifier = control?.identifier
            return detail
          }
          else {
            //* Para los demas controles
            detail.details.map(detailDetail => {
              const control = controlsNoHidden.find(control => control.id === detailDetail.processFormControlId)
              detailDetail.identifier = control?.identifier
              if (textControls.includes(typeControl)) {
                setValue(detailDetail.identifier, detailDetail?.valueText)
              }
              if (dateControls.includes(typeControl)) {
                setValue(detailDetail.identifier, new Date(detailDetail?.valueText))
              }
              if (fileControls.includes(typeControl)) {
                setValue(detailDetail.identifier, detailDetail?.valueText) // nombre del archivo
                setValue(detailDetail.identifier + '_b64', detailDetail?.extraValue) // url del archivo
              }
              if (referenceIdControls.includes(typeControl)) {
                const model = {
                  id: detailDetail?.valueReferenceId,
                  text: detailDetail?.valueText,
                  details: [],
                  controlId: detailDetail.controlId,
                }
                setValue(detailDetail.identifier, model)
              }
              if(typeControl === "USUARIOS" && control?.isMultiple === false){
                const model = control.details.find(x => x.id === detailDetail?.valueReferenceId)
                setValue(detailDetail.identifier, model)
              }
              ids[detailDetail.identifier] = detailDetail.processDataDetailId
              return detailDetail
            })
  
            detail.typeControl = typeControl
            return detail
          }
        })
        setFormIds(ids)
      }
    }
  }, [processData, listControls, params.idrow, formControls, setValue]);

  // useEffect(() => {
  //   // TODO: CONEXION_A_DATOS CONEXION_A_PROCESOS USUARIOS
  //   const textControls = ['TEXTO', 'TEXTO_LARGO', 'TEXTO_DINAMICO', 'NUMERO', 'MONEDA']
  //   const dateControls = ['FECHA', 'FECHA_HORA']
  //   const referenceIdControls = ['LISTA_DESPLEGABLE', 'SELECCION_SIMPLE']
  //   const multipleControls = ['SELECCION_MULTIPLE']
  //   const tablaControls = ['TABLA']
  //   const fileControls = ['IMAGEN', 'ADJUNTAR_ARCHIVO']

  //   if (params.idrow) {
  //     if(processData && listControls){
  //       let ids = {}
  //       processData?.details.map(detail => {
  //         const typeControl = listControls.find(control => control.id === detail.controlId).code

  //         //* Para control tipo multiple
  //         if (multipleControls.includes(typeControl)) {
  //           let detailIdentifier = ''
  //           const newDetailDetails = detail.details.map((detailDetail) => {
  //             const control = formControls.find(control => control.id === detailDetail.processFormControlId)
  //             detailIdentifier = control?.identifier
  //             const model = {
  //               id: detailDetail?.valueReferenceId,
  //               text: detailDetail?.valueText,
  //               details: [],
  //               controlId: detailDetail.controlId,
  //               processDataDetailId: detailDetail.processDataDetailId
  //             }
  //             return model
  //           })
  //           setValue(detailIdentifier, newDetailDetails)
  //           return detail
  //         } else if(tablaControls.includes(typeControl)) {
  //           let detailIdentifier = ''
  //           let contador = 0
  //           let dataTable = []
  //           let model = {}
  //           // detail.details.forEach((detailDetail) => {
  //           detail.details.sort((a, b) => {return a.processDataDetailId - b.processDataDetailId}).forEach((detailDetail) => {
  //             const control = formControls.find(control => control.id === detailDetail.processFormControlId)
  //             detailIdentifier = control?.identifier
  //             if (control?.details) {
  //               if (contador <  control?.details.length - 1) {
  //                 model[control?.details[contador].text] = detailDetail.valueText
  //                 model.id = contador === 0 ? `${detailDetail.processDataDetailId}` : model.id.concat(`-${detailDetail.processDataDetailId}`)
  //                 contador++
  //               } else {
  //                 model[control?.details[contador].text] = detailDetail.valueText
  //                 model.id = model.id.concat(`-${detailDetail.processDataDetailId}`)
  //                 contador = 0
  //                 dataTable.push(model)
  //                 model = {}
  //               }
  //             }
  //           })
            

  //           setValue(detailIdentifier, dataTable)
  //           return detail
  //         } else {
  //           //* Para los demas controles
  //           detail.details.map(detailDetail => {
  //             const control = formControls.find(control => control.id === detailDetail.processFormControlId)
  //             detailDetail.identifier = control?.identifier
              
  //             if (textControls.includes(typeControl)) {
  //               setValue(detailDetail.identifier, detailDetail?.valueText)
  //             }
  //             if (dateControls.includes(typeControl)) {
  //               setValue(detailDetail.identifier, new Date(detailDetail?.valueText))
  //             }
  //             if (fileControls.includes(typeControl)) {
  //               setValue(detailDetail.identifier, detailDetail?.valueText) // nombre del archivo
  //               setValue(detailDetail.identifier + '_b64', detailDetail?.extraValue) // url del archivo
  //             }
  //             if (referenceIdControls.includes(typeControl)) {
  //               const model = {
  //                 id: detailDetail?.valueReferenceId,
  //                 text: detailDetail?.valueText,
  //                 details: [],
  //                 controlId: detailDetail.controlId,
  //               }
  //               setValue(detailDetail.identifier, model)
  //             }
  //             ids[detailDetail.identifier] = detailDetail.processDataDetailId
  //             return detailDetail
  //           })
  
  //           detail.typeControl = typeControl
  //           return detail
  //         }
  //       })
  //       setFormIds(ids)
  //     }
  //   }
  // }, [processData, listControls, params.idrow, formControls, setValue]);

  const reject = () => {
    setVisible(false);
  };

  const getById = () => {
    dispatch(DinamicProcessAction.getConfigDynamicProcessByIdentifier(params.id))
    dispatch(DinamicProcessAction.searchListControls())
    // dispatch(DinamicProcessAction.getDataMaster())
  }

  

  const getDetails = (controls) => {
    // TODO: CONEXION_A_DATOS CONEXION_A_PROCESOS USUARIOS
    const textControls = ['TEXTO', 'TEXTO_LARGO', 'TEXTO_DINAMICO', 'NUMERO', 'FECHA', 'FECHA_HORA', 'MONEDA']
    const referenceIdControls = ['LISTA_DESPLEGABLE', 'SELECCION_SIMPLE']
    const tablaControls = ['TABLA']
    const multipleControls = ['SELECCION_MULTIPLE', 'CONEXION_A_DATOS']
    const idControls = ['ID']
    const fileControls = ['IMAGEN', 'ADJUNTAR_ARCHIVO']

    let controls2 = JSON.parse(JSON.stringify(controls))

    controls2.forEach(control => {
      if(tablaControls.includes(control.type)){
        const indexResponsible = control.details.findIndex(col => col.fieldCode === "TASK_FIELD_RESPONSIBLE")
        const indexSchedule = control.details.findIndex(col => col.fieldCode === "TASK_FIELD_SCHEDULE_DATE")
        const indexTitle = control.details.findIndex(col => col.fieldCode === "TASK_FIELD_TITLE")
        if(indexResponsible === 0 && (control.cellId === null || control.cell === undefined)){
          control.fieldCode = "TASK_FIELD_RESPONSIBLE"
        }
        if(indexSchedule === 0 && (control.cellId === null || control.cell === undefined)){
          control.fieldCode = "TASK_FIELD_SCHEDULE_DATE"
        }
        if(indexTitle === 0 && (control.cellId === null || control.cell === undefined)){
          control.fieldCode = "TASK_FIELD_TITLE"
        }
      }
    })

    controls.forEach(control => {
      if (multipleControls.includes(control.type) || (control.type === 'USUARIOS' && control.isMultiple)) {
        valuesFields[control.identifier].map((detail, index) => {
          const modelControl = {...control}
          if (index !== 0) {
            modelControl.numberOption = index
            modelControl.valueId = detail.id
            controls2.push(modelControl)
            return detail
          }
          return detail
        })
      }
      if (tablaControls.includes(control.type)) {
          valuesFields[control.identifier].forEach((cell, index) => {
            const { id, ...restCell } = cell
            Object.keys(restCell).forEach((key, index2) => {
              // if (key !== 'id') {
                if (index + index2 !== 0) {
                  const modelControl = {...control}
                  const detail = control.details.find(col => col.text === key)
                  if(detail?.fieldCode === "TASK_FIELD_RESPONSIBLE"){
                    modelControl.fieldCode = "TASK_FIELD_RESPONSIBLE"
                  } else if(detail?.fieldCode === "TASK_FIELD_TITLE"){
                    modelControl.fieldCode = "TASK_FIELD_TITLE"
                  } else if(detail?.fieldCode === "TASK_FIELD_SCHEDULE_DATE"){
                    modelControl.fieldCode = "TASK_FIELD_SCHEDULE_DATE"
                  }
                  modelControl.cellPosition = [index, index2]
                  modelControl.cellValue = typeof cell[key] === "object" ? cell[key] : String(cell[key]);
                  modelControl.columnName = key
                  if (params.idrow) {
                    if (!isNaN(id.split('-')[0]) && id.split('-')[0].length <= 6) {
                      modelControl.cellId = id.split('-')[index2]
                      modelControl.rowIds = cell.id
                    } else {
                      modelControl.cellId = 'New'
                    }
                  }
                  
                  controls2.push(modelControl)
                }
              // }
            })
          })
      }
    })
    const modelDetails = []
    
    let indice = 0;
    const tasks = []

    controls2.forEach(control => {
      // if (valuesFields[control.identifier] === null) return
      const modelDetail = {
        processFormSectionId: control.sectionId,
        processFormControlId: control.id,
      }

      //* Mandarle el id para edicion
      if (params.idrow) {
        if (multipleControls.includes(control.type) || tablaControls.includes(control.type) || (control.type === 'USUARIOS' && control.isMultiple)) {
          // modelDetail.id = control.numberOption ? formIds[control.identifier][control.numberOption] : formIds[control.identifier][0]
          // if (!modelDetail.id) delete modelDetail.id
        } else {
          modelDetail.id = formIds[control.identifier]
        }
      }

      //* Para control tipo texto
      if (textControls.includes(control.type)) {
        modelDetail.valueText = valuesFields[control.identifier]
        modelDetail.valueReferenceId = null
      }
      //* Para control tipo ID
      if (idControls.includes(control.type)) {
        modelDetail.valueText = 'default'
        // modelDetail.valueReferenceId = null
      }
      //* Para control tipo seleccion de ids
      if (referenceIdControls.includes(control.type) || (control.type === 'USUARIOS' && control.isMultiple === false)) {
        modelDetail.valueReferenceId = valuesFields[control.identifier]?.id
        modelDetail.valueText = valuesFields[control.identifier]?.text
      }
      //* Para control tipo file
      if (fileControls.includes(control.type)) {
        modelDetail.valueReferenceId = null
        modelDetail.valueText = valuesFields[control.identifier]?.name ? valuesFields[control.identifier]?.name : valuesFields[control.identifier]
        if (valuesFields[control.identifier + '_b64']?.length > 400 && valuesFields[control.identifier + '_b64'] !== null) {
          modelDetail.extraValue = valuesFields[control.identifier + '_b64']
        }
        // modelDetail.extraValue = valuesFields[control.identifier + '_b64']
      }
      //* Para control tipo multiple
      if (multipleControls.includes(control.type) || (control.type === 'USUARIOS' && control.isMultiple)) {
        const multipleDetail = control.numberOption ? valuesFields[control.identifier][control.numberOption] : valuesFields[control.identifier][0]
        modelDetail.valueReferenceId = multipleDetail?.id
        modelDetail.valueText = multipleDetail?.text
        if (params.idrow && multipleDetail?.processDataDetailId) modelDetail.id = multipleDetail.processDataDetailId
      }
      //* Para control tipo tabla
      if (tablaControls.includes(control.type)) {
        if(control.columnName !== "isNewRow" && control.columnName !== "tasks" ){
          if(valuesFields[control.identifier]?.length > 0){
            const firstObject = valuesFields[control.identifier]?.[0]
            // const firstCell = Object.values(firstObject)[0]
            const firstCell = firstObject && Object.values(firstObject)?.[0]
            // const firstId = firstObject.id.split('-')[0]
            const cell = control.cellPosition 
              // '?' valuesFields[control.identifier][control.cellPosition[0]][control.columnName]
              ? control.cellValue
              : firstCell
            if(Array.isArray(cell)){
              if(cell.length >= 1){
                cell.forEach((x, index) => {
                  if(index === 0){
                    modelDetail.valueText = x.text
                    modelDetail.valueReferenceId = x.id
                    modelDetail.extraValue = `${indice}`
                  }else {
                    const modelAux = { ...modelDetail }
                    modelAux.valueText = x.text
                    modelAux.valueReferenceId = x.id
                    modelAux.extraValue = `${indice}`
                    modelDetails.push(modelAux)
                  }
                })
              }
            } else {
              let valueText = null;
              let valueReferenceId = null;

              const rowIndex = control.cellPosition?.[0] || 0
              let currentTask = tasks.find(task => task.row === rowIndex)

              if(!currentTask) {
                currentTask = { row: control.cellPosition?.[0] || 0 }
                tasks.push(currentTask)
              }
            
              if(control.fieldCode === "TASK_FIELD_RESPONSIBLE") {
                const guId = uuidv4();
                currentTask.responsibleId = cell?.id
                currentTask.guIdForTask = guId
                modelDetail.guIdForTask = guId
                currentTask.documentId = control.cellId 
                ? (control.cellId === 'New' ? control.cellId : Number(control.cellId) ) 
                : Number(firstObject.id.split('-')[0])
              
                if (currentTask.documentId  === 'New') {
                  delete currentTask.documentId
                }
              }
              if(control.fieldCode === "TASK_FIELD_TITLE") currentTask.title = cell
              if(control.fieldCode === "TASK_FIELD_SCHEDULE_DATE") currentTask.scheduleDate = cell

              if(cell?.value?.text) valueText = cell?.value?.text
              else if(cell?.text) valueText = cell?.text
              else valueText = cell

              if(cell?.value?.id) valueReferenceId = cell?.value?.id
              else if(cell?.id) valueReferenceId = cell?.id
              else valueReferenceId = null

              modelDetail.valueText = valueText
              modelDetail.valueReferenceId = valueReferenceId
  
              modelDetail.extraValue = `${indice}`
              if (params.idrow) {
                modelDetail.id = control.cellId 
                  ? (control.cellId === 'New' ? control.cellId : Number(control.cellId) ) 
                  : Number(firstObject.id.split('-')[0])
  
                if (modelDetail.id  === 'New') delete modelDetail.id
              }
            }
          }

        }
      }
      indice++;
      modelDetails.push(modelDetail) //
    })


    const modelDetailsWithoutValueTextAsNull = modelDetails.filter(detail => detail.valueText)
    return [modelDetailsWithoutValueTextAsNull, tasks]
  }
  const onNextStep = () => {
    const processId = {processId: Number(params.idrow)}
    // onSubmit('next')
    dispatch(DinamicProcessAction.nextStep(processId))
  }

  //TODO: Revisar que se haga la redirección a la pantalla de busqueda despues del nextStep
  const onSubmit = (data, type) => {
    const [details, tasks] = getDetails(controlsNoHidden)
    const model = {
      processFormId: processSelected.processId,
      details,
      tasks
    }
    if (params.idrow) {
      model.id = Number(params.idrow)
    }

    if (type === 'next') {
      dispatch(DinamicProcessAction.saveProcessData(model, type, onNextStep))
      return
    }
    dispatch(DinamicProcessAction.saveProcessData(model))
  };

  const onBackStep = () => {
    const processId = {processId: Number(params.idrow)}
    dispatch(DinamicProcessAction.backStep(processId))
    // redirectToSearch()
  }

  const headerTemplateSkeleton = () => {
    // if (data === null || data === undefined)
    //   return ('');
    return (

      <div className="flex p-1 pb-2"
        style={{
          border: '1px solid #dee2e6',
          background: '#f8f9fa',
          color: '#495057',
        //   padding: '3px'
        }}>
        <div className="flex-1">
            <Skeleton width="30%" height="20px" />
        </div>
      </div>
    );
  }
 
  return (
    <div className="bg-white">
      <form 
        className="form-custom p-0"
        onSubmit={handleSubmitValidation(() => handleSubmit((data) => onSubmit(data))())}
      >
        <div className="form-modal p-1">
          {
            ((!isLoadingProcessData && workFlow)) ? (
              formSectionsPermissions.map(section => (
                <Panel header={section.title} key={section.id} toggleable>
                  {
                    section.controls.map((control, index)=> {
                      return (
                        <div key={control.id}>
                          <RenderedControls 
                            control={control} // dataControl
                            errors={errors} controlInput={controlInput} formValues={valuesFields} setValue={setValue} // hook-form
                            isLoadingProcessData={isLoadingProcessData} // redux
                            currentStepPermissions={currentStepPermissions}
                            currentStepPermissionsControls={currentStepPermissionsControls}
                            workFlow={workFlow}
                            setTasks={setTasks}
                            controlValidation={controlValidation}
                          />
                        </div>
                      )
                    }) 
                  }
                </Panel>        
              )
            )) : (
              <div className='mt-3 flex canva-process-dinamic-skeleton'>
                  {
                      [1].map((item) => (
                          <Panel
                              headerTemplate={headerTemplateSkeleton}
                              // header={step.name}
                              className='flex-1 h-64 flex flex-col gap-y-4'
                              key={item}
                              // style={{minWidth: '200px'}}
                          >
                            {
                              [1,2,3,4].map((item) => (
                                  <Skeleton width="100%" height="35px" key={item} className='my-2' />
                              ))

                            }
                          </Panel>
                      ))
                  }
              </div>
          )
          }
        </div>
        {
          (existError || Object.values(errorsValidation).length > 0) && <div className='message error'>
              <ul>
                  {
                    Object.values(errors).map(error => (
                      <li key={error.message} className="p-error">El campo {error.message}</li>
                    ))
                  }
                  {
                    Array.from(new Set(Object.values(errorsValidation).map(error => error.message))).map(message => (
                      <li key={message} className="p-error">{message}</li>
                    ))
                  }
              </ul>
          </div>
        }
        <div className="flex justify-end gap-4 mt-3">
            <Button
                icon="pi pi-times"
                type="button"
                label="Cancelar"
                onClick={() => { setVisible(true); }}
                loading={isSaving}
                className="sig-button sig-secondary mt-4"
            />
            <Button
                icon="pi pi-save"
                type="button"
                // disabled={existError ? true : false}
                label="Rechazar"
                onClick={onBackStep}
                loading={isSaving}
                className={`sig-button sig-error mt-4 ${(isFirstStep || !params.idrow || isLastStep) ? 'hidden' : ''} `}
            />
            <Button
                icon="pi pi-save"
                type="submit"
                // disabled={existError ? true : false}
                label="Guardar"
                loading={isSaving}
                className={`sig-button sig-primary mt-4 ${(isLastStep) ? 'hidden' : ''}`}
            />
            <Button
                icon="pi pi-save"
                type="button"
                // disabled={existError ? true : false}
                label="Guardar y Enviar"
                // onClick={onNextStep}
                // onClick={() => {
                //   // onSubmit('next')
                //   handleSubmit(onSubmit('next'))
                  
                //   // onNextStep()
                // }}
                // onClick={() => handleSubmit((data) => onSubmit(data, "next"))}
                onClick={handleSubmitValidation(() => handleSubmit((data) => onSubmit(data, "next"))())}
                loading={isSaving}
                className={`sig-button sig-primary mt-4 ${(!params.idrow || isLastStep) ? 'hidden' : ''}`}
            />
        </div>
      </form>
      <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={redirectToSearch}
          reject={reject}
      />
    </div>
  )
}

export default DinamicProcessFormPage