/* eslint-disable import/no-anonymous-default-export */
import { RiskMatrixConstans } from "features/riskManagement/commons/RiskMatrixConstans";

const initialState = {
	data: {
		data: [],
		initialValues: {},
		dataInitialModel: [],
    isLoadingData: true,
	},
	matrix: {
		data: {},
		decimalPositions: 0,
		isLoading: true,
		isSaved: true,
	},
	table: {
		data: [],
		model: {},
		columns: [],
		isLoading: true,
	},	
	values: {},
  columnExpediente: {},
  download: {
    isLoading: false,
  },
}

export default (state = initialState, action) => {
	switch (action.type) {
		case RiskMatrixConstans.Accion.MatrixManagement.GET_DATA:
			return {
				...state,
				data: {
					...state.data,
					...action.payload,
				}
			}

		case RiskMatrixConstans.Accion.MatrixManagement.SET_MATRIX:
			return {
				...state,
				matrix: {
					...state.matrix,
					...action.payload,
				}
			}
			
		case RiskMatrixConstans.Accion.MatrixManagement.SET_TABLE:
			return {
				...state,
				table: {
					...state.table,
					...action.payload,
				}
			}

		case RiskMatrixConstans.Accion.MatrixManagement.SET_VALUES:
			return {
				...state,
				values: {
					...state.values,
					...action.payload,
				}
			}

    case RiskMatrixConstans.Accion.MatrixManagement.SET_DOWNLOAD:
      return {
        ...state,
        download: {
          ...state.download,
          ...action.payload,
        }
      }

    case "setColumn":
      return {
        ...state,
        columnExpediente: {
          ...state.columnExpediente,
          ...action.payload
        }
      }
			
		default:
			return state;
	}
}