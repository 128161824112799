import { Fragment } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import AdvancedFilterComponent from './AdvancedFilterComponent';
import PinerComponent from 'shared/components/Piner/PinerComponent';
import { Panel } from 'primereact/panel';
import useFilter from 'features/configuration/hooks/roles/useFilter';
import { useClaimRolesPrivileges } from 'features/configuration/hooks/useClaimRolesPrivileges';

const RolesFilterComponent = () => {
  const {
    fieldRole,
    handleChange,
    openModal,
    removePiner,
    showPiners,
    filterRole,
    values,
    generateReport,
    reportLoading,
    rolesLoading,
  } = useFilter();

  const { isSearch, isDownload } = useClaimRolesPrivileges()

  return (
    <Fragment>
      <Panel header={`${isSearch?"FILTRO POR":"DESCARGA"}`} toggleable>
        <form className="filter-roles" onSubmit={filterRole}>
          <div className={`w-full ${isSearch?'visible':'invisible'}`}>
            <span className="p-float-label p-input-icon-left w-full">
              <i className="pi pi-search" />
              <InputText
                id="input-search"
                ref={fieldRole}
                onChange={handleChange}
              />
              <label htmlFor="input-search">Buscar por rol</label>
            </span>
            {showPiners() && (
              <div className="filter-piners mt-3">
                {values.map(({ value, field }, index) => (
                  <PinerComponent
                    name={value}
                    field={field}
                    removePiner={removePiner}
                    key={index}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="filter-users-action">
            <div className="flex gap-3 w-full">
              {
                isSearch&&(
                  <Button
                    icon="pi pi-search"
                    type="submit"
                    label="Buscar"
                    loading={rolesLoading || reportLoading}
                    className="sig-button sig-primary w-full"
                  />
                )
              }
              {
                isDownload&&(
                  <Button
                    icon="pi pi-download"
                    type="button"
                    onClick={generateReport}
                    loading={reportLoading}
                    label="Descargar"
                    className="sig-button sig-success w-full"
                  />
                )
              }
            </div>
            <div className="flex mt-3">
              {
                isSearch&&(
                  <Button
                    type="button"
                    label="Búsqueda avanzada"
                    className="sig-button  sig-secondary w-full"
                    onClick={openModal}
                  />
                )
              }
            </div>
          </div>
        </form>
      </Panel>

      <AdvancedFilterComponent />
    </Fragment>
  );
};

export default RolesFilterComponent;
