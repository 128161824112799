
import moment from 'moment';
import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from "core/store/actions/ToastAction";
import FileSaver from 'file-saver';
import { IndicatorsConstants } from 'features/indicators/commons/IndicatorsConstans';
import { MonitoringService } from 'features/indicators/services/MonitoringServices';

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: IndicatorsConstants.Accion.Monitoring.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: IndicatorsConstants.Accion.Monitoring.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.title) pines.push({ field: 'title', value: advancedFilter.filter.title });
    if (advancedFilter.filter.name) pines.push({ field: 'name', value: advancedFilter.filter.name });
    if (advancedFilter.filter.code) pines.push({ field: 'code', value: advancedFilter.filter.code });
    if (advancedFilter.filter.owner) pines.push({ field: 'owner', value: advancedFilter.filter.owner.fullName });
    if (advancedFilter.filter.source) pines.push({ field: 'source', value: advancedFilter.filter.source.description });
    if (advancedFilter.filter.status) pines.push({ field: 'status', value: advancedFilter.filter.status.description });
    if (advancedFilter.filter.period) pines.push({ field: 'period', value: advancedFilter.filter.period });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });

    advancedFilter.values = pines;

    dispatch({
      type: IndicatorsConstants.Accion.Monitoring.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const generateReport = (payload) => {
  const setIndicator = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Monitoring.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setIndicator({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().monitoringReducer.advancedFilter.filter;

    criteria.title = filter.title;
    criteria.name = filter.name;
    criteria.code = filter.code;
    criteria.to = filter.to;
    criteria.from = filter.from;
    criteria.statusId = filter.status?.id;
    criteria.period = filter.period ? Number(filter.period) : null;
    criteria.responsibleId = filter.owner?.id;
    criteria.sourceId = filter.source?.id;

    MonitoringService.generateReport(criteria)
      .then(({ data }) => {
        setIndicator({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Indicador.xls');
      })
      .catch((err) => {
        setIndicator({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Indicador', 'Hubo un error al descargar el archivo'));
      });
  };
};

const search = () => {
  const setIndicators = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Monitoring.SEARCH_MONITORING,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    // const listFilesFolders = getState().indicatorReducer.listFilesFolders;
    // setIndicators({ dispatch: dispatch, isLoading: true, data: listFilesFolders.data });

    const criteria = {};
    const advancedFilter = getState().monitoringReducer.advancedFilter;
    const filter = getState().monitoringReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.title = filter.title;
    criteria.name = filter.name;
    criteria.to = filter.to;
    criteria.from = filter.from;
    criteria.code = filter.code;
    criteria.responsibleId = filter.owner?.id;
    criteria.sourceId = filter.source?.id;
    criteria.statusId = filter.status?.id;
    criteria.period = filter.period ? Number(filter.period) : null;

    MonitoringService.SearchIndicatorsMonitoring(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setIndicators({ dispatch: dispatch, isLoading: false, data: data.data});
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Indicador', data.message));
          setIndicators({ dispatch: dispatch, isLoading: false, data: [] });
        }else{
          setIndicators({ dispatch: dispatch, isLoading: false, data: [] });
          dispatch(toastAction.error('Indicador', data.message));
        }
      }
    ).catch((error)=>{
    });
  };
};

const getDataMaster = () => {
  const setDataMaster = ({ dispatch, isLoading, indicatorFrecuency, indicatorGoal, indicatorSources, indicatorType, indicatorUnitMeasure, responsibles, indicatorStatus }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Monitoring.GET_DATA_MASTER,
      payload: { isLoading, indicatorFrecuency, indicatorGoal, indicatorSources, indicatorType, indicatorUnitMeasure, responsibles, indicatorStatus },
    });
  };

  return (dispatch) => {
    setDataMaster({ dispatch: dispatch, isLoading: true, indicatorFrecuency: [], indicatorGoal: [], indicatorSources: [], indicatorType: [], indicatorUnitMeasure:[], responsibles:[], indicatorStatus: [] });

    MonitoringService.getDataMaster().then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            indicatorFrecuency: data.data.indicatorFrecuency,
            indicatorGoal: data.data.indicatorGoal,
            indicatorSources: data.data.indicatorSources,
            indicatorType: data.data.indicatorType,
            indicatorUnitMeasure: data.data.indicatorUnitMeasure,
            responsibles: data.data.responsibles,
            indicatorStatus: data.data.indicatorStatus,
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            indicatorFrecuency: [],
            indicatorGoal: [],
            indicatorSources: [],
            indicatorType: [],
            indicatorUnitMeasure: [],
            responsibles: [],
            indicatorStatus: [],
          });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            indicatorFrecuency: [],
            indicatorGoal: [],
            indicatorSources: [],
            indicatorType: [],
            indicatorUnitMeasure: [],
            responsibles: [],
            indicatorStatus: [],
          });
          dispatch(toastAction.warn('Seguimiento', data.message));
        }
      },
    );
  };
};

const getValuesByIndicatorId = (indicatorId) => {
  const setIndicator = ({ dispatch, isLoading, data, valuesData }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Monitoring.GET_VALUES_INDICATOR_BY_ID,
      payload: { isLoading, data, valuesData },
    });
  };
  const setGoal = ({ dispatch, isLoading, goalType, goalTypeRange, goalValue, maxGoalValue }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Monitoring.GET_GOAL,
      payload: { isLoading, goalType, goalTypeRange, goalValue, maxGoalValue },
    });
  };

  return (dispatch) => {
    setIndicator({ dispatch: dispatch, isLoading: true, data: null, valuesData: [] });
    setGoal({ dispatch, isLoading: true, goalType: null, goalTypeRange: null, goalValue: null, maxGoalValue: null })
    MonitoringService.getValuesByIndicatorId(indicatorId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setIndicator({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
            valuesData: data.data?.result
          });
          setGoal({
            dispatch: dispatch,
            isLoading: false,
            goalType: data.data?.goalType,
            goalTypeRange: data?.data.goalTypeRange,
            goalValue: data.data?.goalValue,
            maxGoalValue: data.data?.maxGoalValue,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setIndicator({ dispatch: dispatch, isLoading: false, data: null, valuesData: [] });
          dispatch(toastAction.warn('Indicador', data.message));
        }else{
          setIndicator({ dispatch: dispatch, isLoading: false, data: null, valuesData: [] });
          dispatch(toastAction.error('Indicador', data.message));
        }
      }
    ).catch((error)=>{
    });
  };
};

const saveIndicatorValues = (indicator) => {
  indicator?.indicatorValueList.forEach(x => {x.value = parseFloat(x.value)});
  const isLoading = ({ dispatch, isSaving, isSaved }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Monitoring.SAVE_INDICATOR_VALUES,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch) => {
    isLoading({ dispatch: dispatch, isSaving: true, isSaved: false });
    MonitoringService.saveIndicatorValues(indicator).then(
      ( {data} ) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Indicador', 'El indicador se guardo correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Indicador', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }else{
          dispatch(toastAction.error('Indicador', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }
      }
    ).catch((error)=>{
    });
  };
};

const getValuesByIndicatorIdAndRange = (indicatorId, from, to) => {
  const fromDate = from ? moment(from).format() : moment(0).format();
  const toDate = moment(to).format();

  const setIndicator = ({ dispatch, isLoading, dataSets, labels }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Monitoring.GET_VALUES_INDICATOR_BY_ID_AND_RANGE,
      payload: { isLoading, dataSets, labels },
    });
  };

  return (dispatch) => {
    setIndicator({ dispatch: dispatch, isLoading: true, data: null });
    MonitoringService.getValuesIndicatorByIdRange(indicatorId, fromDate, toDate).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setIndicator({
            dispatch: dispatch,
            isLoading: false,
            dataSets: data.data.dataSets,
            labels: data.data.labels,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setIndicator({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Indicador', data.message));
        }
      }
    );
  };
};

const getCommentsById = (id) => {
  const setComments = ({ dispatch, isLoading, data  }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Monitoring.GET_COMMENTS_BY_INDICATORID,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setComments({ dispatch: dispatch, isLoading: true, data: [] })
    MonitoringService.getCommentsByIndicatorId(id)
    .then(({ data }) => {
      if (data.Status === CoreConstants.HttpResponse.OK) {
          // setComments({ dispatch: dispatch, isLoading: true, data: [] })
          data.Data.forEach(result =>{
            result.CreationDate =  moment(result.CreationDate).format(CoreConstants.Format.DDMMYYYYhhmmss)
          })

          setComments({ dispatch: dispatch, isLoading: false, data: data.Data })
          return;
        }
      })
      .catch(console.error)
      .finally(() => {
        setComments({ dispatch: dispatch, isLoading: false, data: [] })
      });
  };
}

const addComment = (sourceCode, documentId, comment) => {
  const setComments = ({ dispatch, isSaved, isLoading  }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Monitoring.ADD_COMMENT,
      payload: { isSaved, isLoading },
    });
  };
  return (dispatch) => {
    // const currentLoading = { isLoadingTask: true };
    const payload = { sourceCode, documentId, comments: comment}

    setComments({ dispatch: dispatch, isSaved: false, isLoading: true });
    MonitoringService.addComment(payload)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          // dispatch(setCurrentTask({ errors: [] }));
          // dispatch(getCommentsById(payload.documentId));
          setComments({ dispatch: dispatch, isSaved: true, isLoading: false });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setComments({ dispatch: dispatch, isSaved: false, isLoading: false });
      });
  };
}

const deleteComment = (commentId, indicadorId) => {
  const setComments = ({ dispatch, isDeleted  }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Monitoring.DELETE_COMMENT,
      payload: { isDeleted },
    });
  };

  return (dispatch) => {
    // const currentLoading = { isLoadingTask: true };

    MonitoringService.deleteComment(commentId)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          // dispatch(getCommentsById(indicadorId));
          setComments({ dispatch: dispatch, isDeleted: true });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setComments({ dispatch: dispatch, isDeleted: false });
      });
  };
}

const getIndicatorActionPlanByIndicatorId = (indicatorId) => {
  const setIndicator = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Monitoring.GET_INDICATOR_ACTION_PLAN_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setIndicator({ dispatch: dispatch, isLoading: true, data: [] });
    MonitoringService.getIndicatorActionPlanByIndicatorId(indicatorId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setIndicator({
            dispatch: dispatch,
            isLoading: false,
            data: data.data.indicatorActionPlan,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setIndicator({ dispatch: dispatch, isLoading: false, data: [] });
          dispatch(toastAction.warn('Indicador', data.message));
        }
      }
    );
  };
};

const saveIndicatorActionPlan = (indicator) => {
  const isLoading = ({ dispatch, isSaving, isSaved }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Monitoring.SAVE_INDICATOR_ACTION_PLAN,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch) => {
    isLoading({ dispatch: dispatch, isSaving: true, isSaved: false });
    MonitoringService.saveIndicatorActionPlan(indicator).then(
      ( {data} ) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Indicador', 'El plan de acción se guardo correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true });
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL)
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        else
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    );
  };
};

const generateFollowUpReport = (indicatorId) => {
  const setIndicator = ({ dispatch, isLoading }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Monitoring.DOWNLOAD_FOLLOW_UP_REPORT,
      payload: { isLoading }
    });
  };

  return (dispatch) => {
    setIndicator({ dispatch: dispatch, isLoading: true });

    MonitoringService.generateFollowUpReport(indicatorId)
      .then(({ data }) => {
        setIndicator({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Indicador.xlsx');
      })
      .catch((err) => {
        setIndicator({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Indicador', 'Hubo un error al descargar el archivo'));
      });
  };
};

// const saveIndicator = (indicator) => {
//   const isLoading = ({ dispatch, isSaving, isSaved }) => {
//     dispatch({
//       type: IndicatorsConstants.Accion.Monitoring.SAVE_MONITORING,
//       payload: {
//         isSaving,
//         isSaved,
//       },
//     });
//   };
//   return (dispatch) => {
//     isLoading({ dispatch: dispatch, isSaving: true, isSaved: false });
//     MonitoringService.saveMonitoring(indicator).then(
//       ( {data} ) => {
//         if (data.status === CoreConstants.HttpResponse.OK) {
//           dispatch(toastAction.success('Indicador', 'El indicador se guardo correctamente'));
//           isLoading({ dispatch: dispatch, isSaving: false, isSaved: true });
//         } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL)
//           isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
//         else
//           isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
//       }
//     );
//   };
// };

// const getIndicatorById = (indicatorId) => {
//   const setIndicator = ({ dispatch, isLoading, data }) => {
//     dispatch({
//       type: IndicatorsConstants.Accion.Monitoring.GET_MONITORING_BY_ID,
//       payload: { isLoading, data },
//     });
//   };
//   return (dispatch) => {
//     setIndicator({ dispatch: dispatch, isLoading: true, data: null });
//     MonitoringService.getMonitoringById(indicatorId).then(
//       ({ data }) => {
//         if (data.status === CoreConstants.HttpResponse.OK) {
//           setIndicator({
//             dispatch: dispatch,
//             isLoading: false,
//             data: data.data.indicator,
//           });
//         } else if (data.status > CoreConstants.HttpResponse.OK) {
//           setIndicator({ dispatch: dispatch, isLoading: false, data: null });
//           dispatch(toastAction.warn('Indicador', data.message));
//         }
//       }
//     );
//   };
// };


// const deleteIndicator = (id) => {

//   return (dispatch) => {
//     MonitoringService.deleteDocumentLibrary(id).then(
//       ({ data }) => {
//         if (data.status === CoreConstants.HttpResponse.OK) {
//           dispatch(toastAction.success('Indicador', 'El indicador se eliminó correctamente'));
//           dispatch({ type: IndicatorsConstants.Accion.Monitoring.DELETE_INDICATOR, payload: { isDeleted: true } });
//         } else {
//           dispatch(toastAction.warn('Indicador', data.message));
//           dispatch({ type: IndicatorsConstants.Accion.Monitoring.DELETE_INDICATOR, payload: { isDeleted: false } });
//         }
//       },
//       () => {
//         dispatch({ type: IndicatorsConstants.Accion.Monitoring.DELETE_INDICATOR, payload: { isDeleted: false } });
//       }
//     );
//   };
// };

export const MonitoringAction = {
  toggleAdvancedFilter,
  updateFilter,
  resetAfterSaved,
  
  search,
  generateReport,
  getDataMaster,
  getValuesByIndicatorId,

  saveIndicatorValues,
  getValuesByIndicatorIdAndRange,
  getCommentsById,
  addComment,
  deleteComment,
  getIndicatorActionPlanByIndicatorId,
  saveIndicatorActionPlan,
  generateFollowUpReport
  // saveIndicator,
  // getIndicatorById,
  // deleteIndicator,
}