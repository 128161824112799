import { useClaimPositions } from "features/rrhh/hooks/useClaimPositions";
import { Link } from "react-router-dom";

const TableCard = ({
  id,
  name,
  areaName,
  quantityRequiredEmployees,
  quantityActiveEmployees,
  selectedRow,
}) => {
  const { isView ,isEdit, isDelete } = useClaimPositions();
  return (
    <div
      key={`card-${id}`}
      className="block p-4 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
    >
      <div className="flex justify-between">
        {
          isView?(
            <Link className="underline text-blue-700" to={{ pathname: `/rrhh/cargos/editar/` + id }} ><h5>{name}</h5></Link>
          ):(
          <h5
            key={`title-${id}`}
            className="mb-1 text-md font-bold text-gray-900 dark:text-white"
          >
            {name}
          </h5>
          )
        }
        {
          (isEdit || isDelete)&&(
            <i
              key={`options-${id}`}
              className="pi pi-ellipsis-v cursor-pointer"
              onClick={(event) => selectedRow(event, { id })}
            ></i>
          )
        }
      </div>
      <p className="text-xs text-gray-700 dark:text-gray-400 mt-1">
        Departamento: {areaName}
      </p>
      <p className="text-xs text-gray-700 dark:text-gray-400 mt-1">
        N° Empleados Requeridos: {quantityRequiredEmployees}
      </p>
      <p className="text-xs text-gray-700 dark:text-gray-400 mt-1">
        N° Empleado Activos: {quantityActiveEmployees}
      </p>
    </div>
  );
};

export default TableCard;
