import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
// import { Controller } from 'react-hook-form'
// import { InputText } from 'primereact/inputtext'
// import { InputSwitch } from 'primereact/inputswitch';
import useProcessModal from 'features/configuration/hooks/processes/useProcessModal';
import { InputsModalDate, InputsModalID, InputsModalSecuencyNum, InputsModalText } from './InputsModal';
import { ControllerID } from './controllers/ControllerID';
import { ControllerDate } from './controllers/ControllerDate';
import { ControllerDateAndHour } from './controllers/ControllerDateAndHour';
import { ControllerText } from './controllers/ControllerText';
import { ControllerLongText } from './controllers/ControllerLongText';
import { ControllerDinamicText } from './controllers/ControllerDinamicText';
import { ControllerImage } from './controllers/ControllerImage';
import { ControllerArchive } from './controllers/ControllerArchive';
import { ControllerLabel } from './controllers/ControllerLabel';
import { ControllerUsers } from './controllers/ControllerUsers';
import { useSelector } from 'react-redux';
import './Modal.scss'
import { ControllerConectionToProcess } from './controllers/ControllerConectionToProcess';
import { ControllerConectionToData } from './controllers/ControllerConectionToData';

const Modal = ({listOptions, positionSoruce, dinamicStage, setDinamicStage, displayModal, setDisplayModal, optionSelected, inputSelected, dinamicInput, setDinamicInput, setOptionSelected, setInputSelected, typeModal, destinationControl, dataMaster}) => {

  const numberOfEntries = [
    {name: 'Entrada única', code: 'entry_single'}, 
    {name: 'Entradas multiples', code: 'entry_multiple'},  
  ]

  const { control, errors, existError, setValue, handleSubmit, reset, valuesFields} = useProcessModal()
  const [selectForm, setSelectForm] = useState(null)
  const [titleModal, setTitleModal] = useState('')
  const [idTypeControl, setIdTypeControl] = useState(null)
  const [validateDuplicate, setValidateDuplicate] = useState(false)

  useEffect(()=>{
    listOptions?.forEach((item)=>{
      if(item.code===optionSelected || item.code===inputSelected?.typeOptionId){
        setSelectForm(item.code)
        setTitleModal(item.name.toUpperCase())
        setIdTypeControl(item.id)
      }
    })
  },[optionSelected,inputSelected])

  const onHide = () => {
    setDisplayModal(false)
    setValue('fieldLabel', '')
    setValue('required', false)
    setValue('date', null)
    setValue('DateAndHour', null)
    setValue('formatSecuency', null)
    setValue('prefix', null)
    setValue('sufix', null)
    setValue('initialSecuency', null)
    setValue('mode', 'new')
    setValue('idTypeControl', null)
    setValue('description', '')
    setValue('stageId', null)
    setValue('identifier', null)
    setValue('numberOfEntry', null)
    setValue('tableToConnect', null)
    setValue('dataSource', null)
    setValue('processToConnect', null)

    setOptionSelected(null)
    setInputSelected(null)
    setSelectForm(null)
    setValidateDuplicate(false)
    reset({
      fieldLabel: '',
      formatSecuency: null,
      prefix: null,
      sufix: null,
      required: false,
      mode: "new",
      idTypeControl: null,
      description: '',
      stageId: null,
      identifier: '',
      numberOfEntry: null,
      tableToConnect: null,
      dataSource: null,
      processToConnect: null,
    })
  }

  useEffect(() => {
    setValue('fieldLabel', '')
    setValue('required', false)
    setValue('formatSecuency', null)
    setValue('prefix', null)
    setValue('sufix', null)
    setValue('initialSecuency', null)
    setValue('mode', 'new')
    setValue('idTypeControl', null)
    setValue('description', '')
    setValue('stageId', null)
    setValue('identifier', '')
    setValue('numberOfEntry', null)
    setValue('tableToConnect', null)
    setValue('dataSource', null)
    setValue('processToConnect', null)
  }, [])

  useEffect(() => {
    if ((inputSelected!==null) && typeModal===1) {
      setValue('fieldLabel', inputSelected?.fieldLabel)
      setValue('required', inputSelected?.required)
      setValue('prefix', inputSelected?.prefix)
      setValue('sufix', inputSelected?.sufix)
      setValue('formatSecuency', inputSelected?.formatSecuency)
      setValue('initialSecuency', inputSelected?.initialSecuency)
      setValue('mode', inputSelected?.mode)
      setValue('idTypeControl', inputSelected?.idTypeControl)
      setValue('description', inputSelected?.description)
      setValue('stageId', inputSelected?.stageId)
      setValue('identifier', inputSelected?.identifier)
      setValue('numberOfEntry', inputSelected?.isMultiple ? numberOfEntries[1] : numberOfEntries[0])
      setValue('tableToConnect', dataMaster?.processFormControlDataMembers.find(data => data.id === inputSelected?.dataMemberId))
      setValue('dataSource', dataMaster?.processFormControlUserRecords.find(data => data.id === inputSelected?.dataSource?.id))
      setValue('processToConnect', dataMaster?.processFormControlProcessRecords.find(data => data.id === inputSelected?.processId))
    }else{
      setValue('fieldLabel', '')
      setValue('required', false)
      setValue('formatSecuency', null)
      setValue('prefix', null)
      setValue('sufix', null)
      setValue('initialSecuency', null)
      setValue('mode', 'new')
      setValue('idTypeControl', null)
      setValue('description', '')
      setValue('stageId', null)
      setValue('identifier', '')
      setValue('numberOfEntry', null)
      setValue('tableToConnect', null)
      setValue('dataSource', null)
      setValue('processToConnect', null)
    }
  }, [inputSelected])

  const onSubmit = (data) => {

    data.fieldLabel = data.fieldLabel.trim()

    if (!inputSelected) {
      if(optionSelected==='ID'){
        data.required = true
        if(data.formatSecuency && data.initialSecuency){
          if(data.prefix && !data.sufix){
            data.viewID = (data.formatSecuency.replace(/ /g,"")) + "-" + data.initialSecuency
          }else if(!data.prefix && data.sufix){
            data.viewID = data.initialSecuency + "-" +(data.formatSecuency.replace(/ /g,""))
          }else{
            data.prefix = true
            data.sufix = false
            data.viewID = (data.formatSecuency.replace(/ /g,"")) + "-" + data.initialSecuency
          }
        }
      }
      let validateRepeat = false
      const indentifier = normalizeIndentifier(data.fieldLabel)

      dinamicStage.map(elem =>
        elem.dinamicInput?.map(el => {
          if(el.identifier===indentifier){
            validateRepeat = true
          }
          return el
        })
      )

      if(validateRepeat===false){
        const visibleType = listOptions.find(el => el.code===optionSelected)
        data.identifier=indentifier
        data.visibleSearch=visibleType.visibleSearch
        data.visibleAdvancedFilter=visibleType.visibleAdvancedFilter
        data.visibleSimpleFilter=visibleType.visibleSimpleFilter
        data.stageId = positionSoruce
        data.typeOptionId = optionSelected
        data.id = uuidv4()
        data.mode = 'new'
        data.idTypeControl = idTypeControl
        const clone = ([...dinamicStage])
        const indexElement = (([...dinamicStage]).findIndex(elem => elem.id===positionSoruce))
        clone[indexElement].dinamicInput.splice(destinationControl,0,data)
        setValidateDuplicate(false)
        setDinamicStage(clone)
      }else{
        setValidateDuplicate(true)
        return
      }
    } else {
      const clone = (([...dinamicStage]).map(elem=> {
        if(elem.id===inputSelected.stageId){
          let newData = elem.dinamicInput.map(input => {
            if (input.id === inputSelected.id) {
              data.id = inputSelected.id
              data.typeOptionId = inputSelected.typeOptionId?inputSelected.typeOptionId:idTypeControl
              const visibleType = listOptions.find(el => el.code===(inputSelected.typeOptionId?inputSelected.typeOptionId:idTypeControl))
              data.visibleSearch=visibleType.visibleSearch
              data.visibleAdvancedFilter=visibleType.visibleAdvancedFilter
              data.visibleSimpleFilter=visibleType.visibleSimpleFilter
              data.identifier=inputSelected.identifier
              if(inputSelected.typeOptionId==='ID' || idTypeControl==='ID'){
                data.required = true
                if(data.formatSecuency && data.initialSecuency){
                  if(data.prefix && !data.sufix){
                    data.viewID = (data.formatSecuency.replace(/ /g,"")) + "-" + data.initialSecuency
                  }else if(!data.prefix && data.sufix){
                    data.viewID = data.initialSecuency + "-" +(data.formatSecuency.replace(/ /g,""))
                  }else{
                    data.prefix = true
                    data.sufix = false
                    data.viewID = (data.formatSecuency.replace(/ /g,"")) + "-" + data.initialSecuency
                  }
                }
              }
              return data
            }
            return input
          })
          elem.dinamicInput= newData
          return elem
        }
        return elem
      }))
      setDinamicStage(clone)
    }
      onHide()
  };

  const normalizeIndentifier = (str) => {
    str = (((str.trim()).replace(/ /g,"_")).toLowerCase())
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }


  const ValidateInput = (value) => {
    if(value===null){
      value=''
    }
    if(value.trim()===""){
      return "Ingrese un valor diferente en el campo etiqueta"
    }else{
      return true
    }
  }

  return (
    <Dialog header={titleModal} headerStyle={{padding: "15px"}} visible={displayModal} style={{ width: '45vw' }} onHide={() => onHide()}>
        <hr className='mt-0 mb-2' />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className=" grid grid-cols-2 gap-2 mt-0">
            {/* INPUTS */}
            {
              (selectForm && selectForm === 'ID') && <ControllerID control={control} errors={errors} selectForm={selectForm} ValidateInput={ValidateInput} validateDuplicate={validateDuplicate} setValidateDuplicate={setValidateDuplicate}/>
            }
            {
              (selectForm && selectForm === 'TEXTO') && <ControllerText control={control} errors={errors} ValidateInput={ValidateInput} validateDuplicate={validateDuplicate} setValidateDuplicate={setValidateDuplicate}/>
            }
            {
              (selectForm && selectForm === 'TEXTO_LARGO') && <ControllerLongText control={control} errors={errors} ValidateInput={ValidateInput} validateDuplicate={validateDuplicate} setValidateDuplicate={setValidateDuplicate}/>
            }
            {
              (selectForm && selectForm === 'TEXTO_DINAMICO') && <ControllerDinamicText control={control} errors={errors} ValidateInput={ValidateInput} validateDuplicate={validateDuplicate} setValidateDuplicate={setValidateDuplicate}/>
            }
            {
              (selectForm && selectForm === 'NUMERO') && <ControllerDinamicText control={control} errors={errors} ValidateInput={ValidateInput} validateDuplicate={validateDuplicate} setValidateDuplicate={setValidateDuplicate}/>
            }
            {
              (selectForm && selectForm ==='FECHA')&&<ControllerDate control={control} errors={errors} selectForm={selectForm} ValidateInput={ValidateInput} validateDuplicate={validateDuplicate} setValidateDuplicate={setValidateDuplicate}/>
            }
            {
              (selectForm && selectForm === 'FECHA_HORA') && <ControllerDateAndHour control={control} errors={errors} selectForm={selectForm} ValidateInput={ValidateInput} validateDuplicate={validateDuplicate} setValidateDuplicate={setValidateDuplicate}/>
            }
            {
              (selectForm && selectForm === 'IMAGEN') && <ControllerImage control={control} errors={errors} ValidateInput={ValidateInput} validateDuplicate={validateDuplicate} setValidateDuplicate={setValidateDuplicate}/>
            }
            {
              (selectForm && selectForm === 'ADJUNTAR_ARCHIVO' ) && <ControllerArchive control={control} errors={errors} ValidateInput={ValidateInput} validateDuplicate={validateDuplicate} setValidateDuplicate={setValidateDuplicate}/>
            }
            {
              (selectForm && selectForm === 'ETIQUETA' ) && <ControllerLabel control={control} errors={errors} ValidateInput={ValidateInput} validateDuplicate={validateDuplicate} setValidateDuplicate={setValidateDuplicate}/>
            }
            {
              (selectForm && selectForm === 'USUARIOS') && <ControllerUsers control={control} errors={errors} ValidateInput={ValidateInput} validateDuplicate={validateDuplicate} setValidateDuplicate={setValidateDuplicate} valuesFields={valuesFields} dataMaster={dataMaster} inputSelected={inputSelected} />
            }
            {
              (selectForm && selectForm === 'CONEXION_A_PROCESOS') && <ControllerConectionToProcess control={control} errors={errors} ValidateInput={ValidateInput} validateDuplicate={validateDuplicate} setValidateDuplicate={setValidateDuplicate} valuesFields={valuesFields} setValue={setValue} dataMaster={dataMaster} inputSelected={inputSelected} />
            }
            {
              (selectForm && selectForm === 'CONEXION_A_DATOS') && <ControllerConectionToData control={control} errors={errors} ValidateInput={ValidateInput} validateDuplicate={validateDuplicate} setValidateDuplicate={setValidateDuplicate} valuesFields={valuesFields} setValue={setValue} dataMaster={dataMaster} inputSelected={inputSelected} />
            }
          </div>
          {
              (existError) && <div className='message error'>
                  <ul>
                      {errors.fieldLabel ? (
                          <>
                              <li className="p-error">{errors.fieldLabel.message}</li>
                          </>
                      ) : null}
                  </ul>
                  <ul>
                      {errors?.dataSource ? (
                          <>
                              <li className="p-error">{errors?.dataSource.message}</li>
                          </>
                      ) : null}
                  </ul>
                  <ul>
                      {errors?.numberOfEntry ? (
                          <>
                              <li className="p-error">{errors?.numberOfEntry.message}</li>
                          </>
                      ) : null}
                  </ul>
                  <ul>
                      {errors.numberCharacters ? (
                          <>
                              <li className="p-error">{errors.numberCharacters.message}</li>
                          </>
                      ) : null}
                  </ul>
              </div>
            }
          <div className='flex justify-end mt-4'>
            <Button label="Cancelar" icon="pi pi-times" type='button' onClick={onHide} className="sig-button sig-secondary mr-2" />
            <Button label="Guardar" icon="pi pi-save" type="submit" disabled={existError ? true : false} autoFocus className='sig-button sig-primary' />
          </div>
        </form>
    </Dialog>
  )
}

export default Modal