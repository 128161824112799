import { Dropdown } from "primereact/dropdown"
import { Controller } from 'react-hook-form'

export const DropDownCustom = (props) => {

	return(
		
			<Controller
				rules={!props.required ? {} : {
					required: `${props.text} es requerido`,
			}}
        control={props.control}
				name={props.id.toString()}
				render={({field: { onChange, value}})=>{
					if((!value || value === "") && props.forDownload){
						return <div></div>
					}else if(props.forDownload) {
						return(
							<div className='mb-4'>
								<div style={{fontSize: "0.75rem", color: "black",fontWeight:"bold"}}>
									{props.text}
								</div>
								<div style={{fontSize: "0.75rem", color: "#495057"}}>
									{props.details.find((item)=> Number(item.id) === Number(value))?.text}
								</div>
							</div>
						)
					}
					return (
					<div className="mb-6">
					<span className="p-float-label w-full mt-4">
					<Dropdown className={`${props.errors[props.id.toString()] ? 'p-invalid w-full' :  (props.show === "ShowWf"?'w-full disabled': 'w-full')}`} id={props.id} options={props.details} optionLabel="text" optionValue="id" value={value} onChange={onChange} disabled={props.show === "ShowWf"}/>
					<label htmlFor={props.id}>{props.text}</label>
					</span>
					</div>
				)}}
			/>
	)
}