import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Message } from 'primereact/message';
import useModalPermissions from 'features/survey/hooks/SurveyManagement/useModalPermissions';
import { SurveyManagementAction } from 'features/survey/store/actions/SurveyManagementAction';

const ModalPermissions = ({displayModal, setDisplayModal, surveySelected, setSurveySelected, selectedNode, setSelectedNode}) => {
  
  const [visible, setVisible] = useState(false);
  const [idDelete, setIdDelete] = useState(0);
  const { permissionsList, dropdowns, permissionError, data, isSaved, dispatch, createPermission, deletePermission, updatePermission, setPermissionsList, titleFolder, setPermissionError } = useModalPermissions()

  // const folderName = titleFolder(surveySelected ? surveySelected.name : null)
  const folderName = surveySelected ? surveySelected.name : null

  const deletePermissionConfirm = () => {
    deletePermission(idDelete);
  };

  useEffect(() => {
    if (surveySelected) {
      // dispatch(DocumentManagementAction.getPermissionByPath(surveySelected?.name));
    }
  }, [surveySelected])

  // Si ya existen permisos por el id
  useEffect(() => {
    if (data) {
      // setPermissionsList(data.items)
      setPermissionsList(data)
    }
  }, [data])

  useEffect(() => {
    if (isSaved) {
      onHide()
    }
  }, [isSaved])

  const listSelected = (type) => {
    if (type?.code === 'DPUS') return dropdowns?.users
    if (type?.code === 'DPRL') return dropdowns?.roles
    if (type?.code === 'DPPS') return dropdowns?.positions
    if (type?.code === 'DPAR') return dropdowns?.areas
  }

  const labelSelected = (type) => {
    if (type?.code === 'DPUS') return 'fullName'
    if (type?.code === 'DPRL') return 'description'
    if (type?.code === 'DPAR' || type?.code === 'DPPS') return 'name'
  }

  const typeIdSelected = (id) => {
    if(!id) return
    if (isNaN(id)) return id
    const type = dropdowns?.permissionTypes.find(type => type.id === id)
    return type
  }

  const Field = ({ name, row }) => {
    if (name === "typeId") {
      return <Dropdown 
        options={dropdowns?.permissionTypes} 
        placeholder="Seleccione tipo" 
        // optionValue='id' 
        optionLabel="description" 
        value={typeIdSelected(row[name])}
        onChange={(e) => {
          updatePermission({
            id: row.id,
            name: name,
            value: e.value,
          })}
        }/>
    } else if(name === "allowedActionId") {
      return <Dropdown 
      options={dropdowns?.permissions} 
      placeholder="Seleccione permiso" 
      optionValue='id' 
      optionLabel="description" 
      value={row[name]}
      onChange={(e) => {
        updatePermission({
          id: row.id,
          name: name,
          value: e.value,
        })}
      }/>
    } else {
      return <Dropdown 
      options={listSelected(typeIdSelected(row.typeId))} 
      placeholder="Seleccione nombre" 
      optionValue='id' 
      optionLabel={labelSelected(typeIdSelected(row.typeId))} 
      value={row[name]}
      onChange={(e) => {
        updatePermission({
          id: row.id,
          name: name,
          value: e.value,
        })}
      }/>
    }
  };

  const columns = [
    { field: 'typeId', header: 'Tipo' },
    { field: 'sourceId', header: 'Nombre' },
    { field: 'allowedActionId', header: 'Permisos' },
  ];

  const ColumnComponents = columns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable
        body={(row) => <Field name={col.field} row={row}/>}
      />
    );
  });
  
  const onHide = () => {
    setDisplayModal(false)
    setSurveySelected(null)
    setPermissionsList([])
    setPermissionError(null)
    dispatch(SurveyManagementAction.resetAfterSaved());
  }

  const redirectToSearch = () => {
    onHide()
  }

  const RowDelete = ({ id }) => {
    return (
      <i
        onClick={() => {
          setVisible(true);
          setIdDelete(id);
        }}
        className="pi pi-trash cursor-pointer"
      ></i>
    );
  };

  const permissionListWithoutId = (list) => {
    const listWithoutId = list.map(item => {
      item.locationId = surveySelected.id
      const {id, ...rest } = item
      rest.typeId = rest.typeId.id ? rest.typeId.id : rest.typeId
      if (data.length > 0) {
        // rest.permissionId = data.id
        if (!isNaN(id)) rest.id = id
      }
      return rest
    })
    return listWithoutId
  }

  const numberOfDuplicatePermissions = (list) => {
    const permissionsDuplicates = list.reduce((acc, current) => {
      const x = acc.find(item => item[`sourceId`] === current[`sourceId`])
      if (!x) {
          return acc.concat([current])
      } else {
          return acc
      }
  }, [])

    return permissionsDuplicates.length
  }

  const existRowEmpty = (list) => {
    const isRowEmpty = list.some(permission => permission.typeId === null || permission.sourceId === null || permission.allowedActionId === null)
    return isRowEmpty
  }
  const onSubmit = (e) => {
      e.preventDefault()
      if (existRowEmpty(permissionsList)) {
        setPermissionError('Falta terminar de llenar los datos del permiso')
        return
      }
      if (numberOfDuplicatePermissions(permissionsList) < permissionsList.length) {
        setPermissionError('Existen uno o mas permisos duplicados')
        return
      }
      setPermissionError(null)
      // if (data) {
      //   const model = {
      //     id: data.id,
      //     locationId: selectedNode.id,
      //     permissions: permissionListWithoutId(permissionsList)
      //   }
      //   // dispatch(DocumentManagementAction.savePermission(model));
      //   console.log(model)
      //   return
      // }
      const model = {
        locationId: surveySelected.id,
        permissions: permissionListWithoutId(permissionsList)
      }
      // console.log(model)
      dispatch(SurveyManagementAction.savePermission(model));
  };

  return (
    <Dialog header={`Permisos - ${folderName}`} visible={displayModal} className='w-3/4 sm:w-2/3' onHide={onHide}>
      <form 
        className="form-custom p-0"
        onSubmit={onSubmit}
      >
          <div className="">
              <Button
                icon="pi pi-plus"
                type="button"
                label="Añadir permiso"
                onClick={createPermission}
                className="btn btn-primary mt-2 mb-4"
              />

              <div className="table-main table-contact mb-3">
                  <DataTable
                    value={permissionsList}
                    emptyMessage="No se encontraron permisos"
                    stripedRows
                    editMode="row"
                    dataKey="id"
                    rowHover
                    size="small"
                    responsiveLayout="scroll"
                  >
                    {ColumnComponents}
                    <Column header={<div></div>} body={RowDelete}></Column>
                  </DataTable>
              </div>
              <div className='flex justify-center'>
                {permissionError && (
                  <Message className='w-1/2' severity="error" text={permissionError} />
                )}
              </div>

        </div>
        <div className="flex justify-end gap-4 mt-3">
            <Button
                icon="pi pi-times"
                type="button"
                label="Cancelar"
                onClick={redirectToSearch}
                className="sig-button sig-secondary mt-4"
            />
            <Button
                icon="pi pi-save"
                type="submit"
                // disabled={existError ? true : false}
                label="Guardar"
                loading={document?.isSaving || document?.isLoading}
                className="sig-button sig-primary mt-4"
            />
        </div>
      </form>
      <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea eliminar el contacto?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={() => deletePermissionConfirm()}
          reject={() => setVisible(false)}
        />
    </Dialog>
  )
}

export default ModalPermissions