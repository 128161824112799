import React, { Fragment, useEffect, useState } from "react"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { InputTextCustom } from "./InputTextCustom"
import { InputTextareaCustom } from "./InputTextareaCustom"
import { TextoDinamicoCustom } from "./TextoDinamicoCustom"
import { DropDownCustom } from "./DropDownCustom"
import { SeleccionSimpleCustom } from "./SeleccionSimpleCustom"
import './TableCustom.scss'
import { TableDownload } from "./TableDownload"

export const TableCustom = ({row,rowLec, type, ...props}) => {

	const [prueba, setPrueba] = useState([])
	const [cantFilas, setCantFilas] = useState(0)
  
  const hash = {
    "TEXTO": (props) => <InputTextCustom {...props} />,
    "TEXTO_LARGO": (props) => <InputTextareaCustom {...props} /> ,
    "TEXTO_DINAMICO": (props) => <TextoDinamicoCustom {...props}/> ,
    "LISTA_DESPLEGABLE": (props) => <DropDownCustom {...props}/> ,
    "SELECCION_SIMPLE": (props) => <SeleccionSimpleCustom {...props}/> ,
    "TABLA": (props) => <TableCustom {...props} /> ,
    "ETIQUETA": (props) => <InputText />,
  }

	const generarFila = (numFila) => {
		const aux = props.details
		const row = {}
		row.id = numFila+1
		for(let i of aux){
      const code = type.find( control => control.id === i.controlId )?.code || "TEXTO"
      row[i.id] = hash[code]({ required: false, text: i.text, id: numFila === 0?i.id:`${i.id}-${numFila+1}`, control: props.control, errors: props.errors, show: props.show, forDownload: props.forDownload, isTable: true, })
			// row[i.id] = <InputTextCustom required={false} text={i.text} id={numFila === 0?i.id:`${i.id}-${numFila+1}`} control={props.control} errors={props.errors} show={props.show} forDownload={props.forDownload} isTable={true}/>
		}
		row["Eliminar"] = <i
		onClick={()=>removeRow(numFila+1)}
		className="pi pi-trash cursor-pointer"
		/>
		setPrueba(prev=>{ return [...prev,row]})
	}

	const addRow = () => {
		const cant = cantFilas + 1
		const aux = props.details
		const row = {}
		row.id = cantFilas+1
		for(let i of aux){
      const code = type.find( control => control.id === i.controlId )?.code || "TEXTO"
      row[i.id] = hash[code]({ required: false, text: i.text, id: cantFilas === 0?i.id:`${i.id}-${cantFilas+1}`, control: props.control, errors: props.errors, show: props.show, forDownload: props.forDownload, isTable: true, })
			// row[i.id] = <InputTextCustom required={false} text={i.text} id={cantFilas === 0?i.id:`${i.id}-${cantFilas+1}`} control={props.control} errors={props.errors} show={props.show} isTable={true}/>
		}
		row["Eliminar"] = <i
		onClick={()=>removeRow(cant)}
		className="pi pi-trash cursor-pointer"
		/>
		setPrueba(prev=>{ return [...prev,row]})
		setCantFilas(pre => pre+1)
	}

	const removeRow = (fil) => {
		setPrueba(prev => prev.filter((item)=>{
			if(Number(fil) === Number(item.id)){
				Object.keys(item).forEach(item1=>{
					if(fil===1) props.setValue(`${item1}`,undefined)
					else props.setValue(`${item1}-${fil}`,undefined)
				})
			}
			return Number(fil) !== Number(item.id)
		}))
	}

	useEffect(()=>{
		if(rowLec) {
			setPrueba(prev => [])
			for(let i =0; i<row;i++){
				generarFila(i);
			}
			setCantFilas(row)
		}
	},[rowLec, row])

  if( props.show === 'ShowWf'){
    const cabecera = props.details
    const columnas = cabecera.map(item => item.id);
   
    return (
      <div className="mb-4">
        <div className="font-bold mb-2">{props.text}</div>
        <div
          className="page-break rounded"
          style={{
          display: 'grid',
          gridTemplateColumns: `repeat(${columnas.length - 1}, auto) 1fr`,
          width: '100%', // O el ancho máximo que desees
          margin: '0 auto',
          border: '1px solid rgba(229, 231, 235)',
        }}
      >
        {
          cabecera.map((item, colIndex) => (
            <div key={item.id} style={{ textAlign: 'center', padding: '0 8px 16px 8px', borderRight: colIndex === cabecera.length - 1? '' : '1px solid rgba(229, 231, 235)', fontSize: 14, background: '#e9ecef'}} role="columnheader">
              {item.text}
            </div>
          ))
        }
        {prueba.map((fila, index) => (
          <Fragment key={fila.id}>
            {columnas.map((columna, colIndex) => (
              <div key={columna} style={{ padding: '0 8px 0 8px', borderBottom: index === prueba.length - 1? '' : '1px solid rgba(229, 231, 235)', borderRight: colIndex === cabecera.length - 1? '' : '1px solid rgba(229, 231, 235)'}}>
                {fila[columna]}
              </div>
            ))}
          </Fragment>
        ))}
        </div>
      </div>
    )
  }

  if( props.forDownload){    
    return <TableDownload props={props} prueba={prueba} />
  }

	return (
		<>
			<DataTable
        style={{fontFamily: 'inherit'}}
				header={<div>{props.text}</div>}
				value={prueba}
				size="small"
				responsiveLayout="scroll"
				className='overflow-x-auto mt-4 data-table-ficha page-break'
				dataKey="id"
			>
				{
					!props.forDownload && props.show !== 'ShowWf' ? 
					props.details.map((item,index)=> (
						<Column key={index} style={{minWidth: 200}} field={item.id.toString()} header={item.text}></Column>
					)).concat(<Column key="del" field="Eliminar" header=""></Column>)
					:
					props.details.map((item,index)=> (
						<Column key={index} style={{width: 'auto'}} field={item.id.toString()} header={item.text}></Column>
					))
				}
			</DataTable>
			{
				(!props.show && !props.forDownload)&& 
				<Button
				icon="pi pi-plus"
				type="button"
				label="Nueva Fila"
				className="sig-button sig-primary mt-4 mb-4 mr-4"
				onClick={addRow}
			/>
			}		
		</>
)}