import { TasksConstants } from 'features/customers/commons/TasksConstants';

const initialState = {
  tasks: {
    data: [],
    loading: false,
    order: 'desc',
    pagination: {},
    columnOrder: 'scheduleDate',
  },
  comments: {
    data: [],
    loading: false
  },
  comment: {
    isLoading: false,
    isSaved: false,
    isDeleted: false,
  },
  actions: {
    loading: false,
    items: [
      { name: 'Editar', icon: 'pi pi-pencil' },
      { name: 'Eliminar', icon: 'pi pi-trash' },
    ],
  },
  advancedFilter: {
    showModal: false,
    values: [],
  },
  downloadReport: {
    loading: false,
  },
  currentAction: {
    isOpen: false,
    taskId: null,
    companyId: null,
    sourceId: null,
    newPagePath: null,
    editPagePath: null
  },
  editTask: {
    isLoading: false,
    isLoadingTask: false,
    data: {},
    status: null,
    errors: [],
  },
  dataMaster: {
    isLoading: false,
    data: {
    },
  },
  showTaskModal: {
    visible: false,
    type: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TasksConstants.Action.Tasks.SEARCH:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...action.payload,
        },
      };
    case TasksConstants.Action.Comments.SEARCH:
      return {
        ...state,
        comments: {
          ...state.comments,
          ...action.payload,
        },
      };
      case TasksConstants.Action.Tasks.ADVANCED_FILTER:
        return {
          ...state,
          advancedFilter: {
            ...state.advancedFilter,
            ...action.payload,
          },
        };
        case TasksConstants.Action.Tasks.CURRENT_ACTION:
          return {
            ...state,
            currentAction: {
              ...state.currentAction,
              ...action.payload,
            },
          };
        case TasksConstants.Action.Tasks.EDIT_TASK:
          return {
            ...state,
            editTask: {
              ...state.editTask,
              ...action.payload,
            },
          };
        case TasksConstants.Action.Tasks.DATA_MASTER:
        return {
          ...state,
          dataMaster: {
            ...state.dataMaster,
            ...action.payload,
          },
        };
        case TasksConstants.Action.Tasks.OPEN_MODAL:
          return{
            ...state,
            showTaskModal: action.payload
          };
        case TasksConstants.Action.Tasks.CLOSE_MODAL:
            return{
              ...state,
              showTaskModal: action.payload
          }
        case TasksConstants.Action.Tasks.GET_COMMENTS_BY_INDICATORID:
          return {
              ...state,
              comments: {
                data: action.payload.data,
                isLoading: action.payload.isLoading
              },
          };
        case TasksConstants.Action.Tasks.DELETE_COMMENT:
          return {
              ...state,
              comment: {
                isDeleted: action.payload.isDeleted,
                isLoading: action.payload.isLoading,
              },
          };
        case TasksConstants.Action.Tasks.ADD_COMMENT:
          return {
              ...state,
              comment: {
                isSaved: action.payload.isSaved,
                isLoading: action.payload.isLoading,
              },
          };
        case TasksConstants.Action.Tasks.REPORT_STATUS:
          return {
            ...state,
            downloadReport: {
              loading: action.payload,
            },
          };
      default:
        return state;
  }
};