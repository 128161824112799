import { CoreConstants } from 'core/commons/CoreConstants';
import { BusinessSheetConstants } from 'features/business/commons/BusinessSheetConstants'; 
import { toastAction } from 'core/store/actions/ToastAction';
import { BusinessSheetService } from 'features/business/services/businessSheetService'; 

import FileSaver from "file-saver";
import { getByIdAdapter, sheetSearchAdapter } from 'features/business/helpers/businessSheet/BusinessSheetAdapter';

const setBusinessSheet = (payload) => ({
  type: BusinessSheetConstants.Action.BusinessSheet.SEARCH,
  payload,
});

const setEditBusinessSheet = (payload) => ({
  type: BusinessSheetConstants.Action.BusinessSheet.EDIT,
  payload,
});

const setBusinessSheetTemplates = (payload) => ({
  type: BusinessSheetConstants.Action.BusinessSheet.TEMPLATES,
  payload,
});

const search = (pagination, setRowCount) => {
  return (dispatch) => {
    dispatch(setBusinessSheet({ loading: true }));
    BusinessSheetService.search({ fields: pagination })
      .then(({ data }) => {
        const status = data?.status ?? '';
        if (status === CoreConstants.HttpResponse.OK) {
          const { results, ...rest } = data.data;
          setRowCount(rest.rowCount)
          dispatch(setBusinessSheet({ data: results.map(sheetSearchAdapter)}));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Ficha de Negocios", data.message));
        }else{
          dispatch(toastAction.error("Ficha de Negocios", data.message));
        }

      })
      .catch((error)=>{
      })
      .finally(() => {
        dispatch(setBusinessSheet({ loading: false }));
      });
  };
};

const downloadReport = (searchFields) => {
  return (dispatch) => {       
    dispatch(setBusinessSheet({ isLoadingDownload: true }));
    BusinessSheetService.generateReport(searchFields)
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        FileSaver.saveAs(blob, "FichaDeNegocio.xlsx");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(setBusinessSheet({ isLoadingDownload: false }));
      });
  };
};

const setDataMaster = (payload) => ({
  type: BusinessSheetConstants.Action.BusinessSheet.DATA_MASTER,
  payload,
})

const getDataMaster = () => {
  return (dispatch) => {
    dispatch(setDataMaster({ isLoadingDataMaster: true }));
    BusinessSheetService.dataMaster()
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setDataMaster({ data: data.data }));
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setDataMaster({ isLoadingDataMaster: false }));
      });
  };
};

const remove = (id, searchData) => {
  return (dispatch) => {
    dispatch(setBusinessSheet({ loading: true }));
    BusinessSheetService.remove(id).then(({ data }) => {
      const status = data.status;
      if (status === CoreConstants.HttpResponse.OK) {
        dispatch(toastAction.success('Ficha de Negocios', 'Ficha de Negocio Eliminado'));
        searchData()
      }
    })
    .catch(console.error)
    .finally(() => {
      dispatch(setBusinessSheet({ loading: false }));
    })
  }
}

const getById = (id, isTemplate = false) => {
  return (dispatch) => {
    dispatch(setEditBusinessSheet({ loading: true }));
    BusinessSheetService.getById(id).then(({ data }) => {
      const status = data.status;
      if (status === CoreConstants.HttpResponse.OK) {
        const businessSheet = getByIdAdapter(data.data.businessSheet, isTemplate);
        dispatch(setEditBusinessSheet({ businessSheet }));
      } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
        dispatch(toastAction.warn("Ficha de Negocios", data.message));
      }else{
        dispatch(toastAction.error("Ficha de Negocios", data.message));
      }
    })
    .catch(console.error)
    .finally(() => {
      dispatch(setEditBusinessSheet({ loading: false }));
    });
  }
}

const resetDataBusinessSheet = () => ({
  type: BusinessSheetConstants.Action.BusinessSheet.EDIT,
  payload: {
    businessSheet: {},
    loading: false,
  },
})

const setListControls = (payload) => ({
  type: BusinessSheetConstants.Action.BusinessSheet.LIST_CONTROLS,
  payload,
})

const getListControls = () => {
  return (dispatch) => {
    dispatch(setListControls({ isLoadingListControls: true }));
    BusinessSheetService.getListControls().then(({ data }) => {
      const status = data.status;
      if (status === CoreConstants.HttpResponse.OK) {

        dispatch(setListControls({ listControls: data.data }));
      } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
        dispatch(toastAction.warn("Ficha de Negocios", data.message));
      }else{
        dispatch(toastAction.error("Ficha de Negocios", data.message));
      }
    })
    .catch(console.error)
    .finally(() => {
      dispatch(setListControls({ isLoadingListControls: false }));
    });
  }
}

const resetListControls = () => ({
  type: BusinessSheetConstants.Action.BusinessSheet.LIST_CONTROLS,
  payload: {
    listControls: [],
    isLoadingListControls: false,
  },
})

const setSave = (payload) => ({
  type: BusinessSheetConstants.Action.BusinessSheet.SAVE,
  payload,
})

const save = (data, goToBack) => {
  return (dispatch) => {
    dispatch(setSave({ isLoadingSave: true }));
    BusinessSheetService.save(data)
      .then(({ data }) => {
        const status = data?.status ?? '';
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(setSave({ isLoadingSave: false }));
          dispatch(toastAction.success("Ficha de Negocios", "La Ficha de Negocios se guardó correctamente"));
          goToBack()
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Ficha de Negocios", data.message));
        }else{
          dispatch(toastAction.error("Ficha de Negocios", data.message));
        }

      })
      .catch((error)=>{
        dispatch(toastAction.error("Ficha de Negocios", error.message));
      })
      .finally(() => {
        dispatch(setSave({ isLoadingSave: false }));
      });
  }
}

const setShare = (payload) => ({
  type: BusinessSheetConstants.Action.BusinessSheet.SHARE,
  payload,
})

const share = (id) => {
  return (dispatch, getState) => {
    dispatch(setShare({ isLoadingShare: true }));
    BusinessSheetService.share(id)
      .then(({ data }) => {
        const status = data?.status ?? '';
        if (status === CoreConstants.HttpResponse.OK) {
          const prevData = getState().businessSheetReducer.businessSheet.data
          const newData = prevData.map( businessSheet =>{
            if(businessSheet.id === Number(id)) {
              businessSheet.status = data.data.status
              businessSheet.statusCode = data.data.statusCode
            }
            return businessSheet
          })
          dispatch(setBusinessSheet({ data: newData}));
          dispatch(setShare({ isLoadingShare: false, link: data.data.newGuid }));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Ficha de Negocios", data.message));
          dispatch(setShare({ isLoadingShare: false, link: null }));
        }else{
          dispatch(toastAction.error("Ficha de Negocios", data.message));
          dispatch(setShare({ isLoadingShare: false, link: null }));
        }

      })
      .catch((error)=>{
        dispatch(toastAction.error("Ficha de Negocios", error.message));
        dispatch(setShare({ isLoadingShare: false, link: null }));
      })
      .finally(() => {
        dispatch(setShare({ isLoadingShare: false }));
      });
  }
}

const close = (id, searchData = () => {}) => {
  return (dispatch, getState) => {
    BusinessSheetService.close(id).then(({ data }) => {
      const status = data.status;
      if (status === CoreConstants.HttpResponse.OK) {
        const prevData = getState().businessSheetReducer.businessSheet.data
        const newData = prevData.map( businessSheet =>{
          if(businessSheet.id === Number(id)) {
            businessSheet.status = data.data.status
            businessSheet.statusCode = data.data.statusCode
          }
          return businessSheet
        })
        dispatch(setBusinessSheet({ data: newData}));
        dispatch(toastAction.success('Ficha de Negocios', 'Ficha de Negocio Cerrada'));
        searchData()
      }
    })
    .catch(console.error)
    .finally(() => {
    })
  }
}

const getBusinessSheetsTemplate = () => {
  return (dispatch) => {
    dispatch(setBusinessSheetTemplates({ loading: true }));
    BusinessSheetService.businessSheetsTemplate().then(({ data }) => {
      const status = data.status;
      if (status === CoreConstants.HttpResponse.OK) {
        dispatch(setBusinessSheetTemplates({ data: data.data }));
      } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
        dispatch(toastAction.warn("Ficha de Negocios", data.message));
      }else{
        dispatch(toastAction.error("Ficha de Negocios", data.message));
      }
    })
    .catch(console.error)
    .finally(() => {
      dispatch(setBusinessSheetTemplates({ loading: false }));
    });
  }
}

const setCurrentTemplateId = (currentTemplateId) => ({
  type: BusinessSheetConstants.Action.BusinessSheet.TEMPLATES,
  payload: { currentTemplateId },
})

export const BusinessSheetAction = {
  search,
  getDataMaster,
  downloadReport,
  remove,
  getById,
  resetDataBusinessSheet,
  getListControls,
  save,
  share,
  resetListControls,
  close,
  getBusinessSheetsTemplate,
  setCurrentTemplateId,
}
