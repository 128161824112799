import { InputText } from 'primereact/inputtext'
import React, { useState } from 'react'
import { useSelector } from 'react-redux';

const QuestionCheckBoxes = ({addOption, listOptions}) => {
  const [newValue, setNewValue] = useState('')
  const { isCloseStatus } = useSelector((state) => state.surveyReducer.survey);

  
  const onAddOption = (newValue) => {
    if (isCloseStatus) return
    addOption(newValue)
    setNewValue('')
  }

  const onKeyHandler = (e, newValue) => {
    if (isCloseStatus) return
    if (e.key === 'Enter') {
      e.preventDefault();
      onAddOption(newValue);
    }
  };

  return (
    <div className='mt-2'>
      <div className='flex justify-center items-center gap-x-1'>
        <span className="p-float-label w-full my-5">
            <InputText
                onChange={(e) => setNewValue(e.target.value)}
                value={newValue}
                name='newValue'
                type='text'
                onKeyPress={(e) => onKeyHandler(e, newValue)}
                readOnly={isCloseStatus}
            />
            <label htmlFor="newValue">Ingrese opción de respuesta</label>
        </span>
        <button disabled={isCloseStatus} type='button' onClick={() => onAddOption(newValue)} className='h-8 w-8 flex justify-center items-center cursor-pointer'>
          <i className={`pi pi-plus text-xl text-gray-600`}></i>
        </button>
      </div>
      {
        listOptions()
      }
    </div>
  )
}

export default QuestionCheckBoxes