import { CoreConstants } from "core/commons/CoreConstants";
import { toastAction } from "core/store/actions/ToastAction";
import { RrhhConstants } from "features/rrhh/commons/RrhhConstants";
import { CustomFieldServices } from "features/rrhh/services/CustomFieldServices";


const setFilePeople = (payload) => {
  return (dispatch) =>
    dispatch({
      type: RrhhConstants.Accion.CustomField.SAVE_FILE_PEOPLE,
      payload,
    });
}

const getLastFilePeople = (data) => {
  const setLastPeople = (payload) => {
    return (dispatch) =>
      dispatch({
        type: RrhhConstants.Accion.CustomField.GET_LAST_FILE_PEOPLE,
        payload,
      });
  }
  return (dispatch) => {
    dispatch(setLastPeople({isLoading: true}))
    CustomFieldServices.getLastFilePeople(data)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setLastPeople({sheet: data.data.sheet, versions: data.data.versions, isLoading: false}))
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(setLastPeople({isLoading: false}))
          dispatch(toastAction.warn('Ficha Persona', data.message));
        }else{
          dispatch(setLastPeople({isLoading: false}))
          dispatch(toastAction.error('Ficha Persona', data.message));
        }
      }).catch((error)=>{
        dispatch(toastAction.error('Ficha Persona', "Error al ejecutar la petición"));
        dispatch(setLastPeople({isSaving: false, isSaved: false}))
      });
  };
}


const getListControls = (type) => {
  const setListControls = (payload) => {
    return (dispatch) =>
      dispatch({
        type: RrhhConstants.Accion.CustomField.LIST_CONTROLS,
        payload,
      });
  }
  return (dispatch) => {
    CustomFieldServices.getListControls(type)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setListControls(data.data))
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn('Empleados', data.message));
          dispatch(setListControls([]));
        }else{
          dispatch(toastAction.error('Empleados', data.message));
          dispatch(setListControls([]));
        }
      }).catch((error)=>{
        dispatch(setListControls([]));
      });
  };
}

const getListResponsibles = (criteria) => {
  const setListResponsibles = (payload) => {
    return (dispatch) =>
      dispatch({
        type: RrhhConstants.Accion.CustomField.LIST_RESPONSIBLES,
        payload,
      });
  }
  return (dispatch) => {
    CustomFieldServices.getListResponsibles(criteria)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setListResponsibles(data.data))
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn('Responsables', data.message));
          dispatch(setListResponsibles([]));
        }else{
          dispatch(toastAction.error('Responsables', data.message));
          dispatch(setListResponsibles([]));
        }
      }).catch((error)=>{
        dispatch(setListResponsibles([]));
      });
  };
}

const saveFilePeople = (data) => {
  return (dispatch) => {
    dispatch(setFilePeople({isSaving: true, isSaved: false}))
    CustomFieldServices.saveFilePeople(data)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Ficha Persona', data.message));
          dispatch(setFilePeople({isSaving: false, isSaved: true}))
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(setFilePeople({isSaving: false, isSaved: false}))
          dispatch(toastAction.warn('Ficha Persona', data.message));
        }else{
          dispatch(setFilePeople({isSaving: false, isSaved: false}))
          dispatch(toastAction.error('Ficha Persona', data.message));
        }
      }).then(()=> {
        dispatch(setFilePeople({isSaving: false, isSaved: false}))
      })
      .catch((error)=>{
        dispatch(toastAction.error('Ficha Persona', "Error al ejecutar la petición"));
        dispatch(setFilePeople({isSaving: false, isSaved: false}))
      });
  };
}

const saveAndApproved = (data) => {
  return (dispatch) => {
    dispatch(setFilePeople({isSaving: true, isSaved: false}))
    CustomFieldServices.saveAndApproved(data)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Ficha Persona', data.message));
          dispatch(setFilePeople({isSaving: false, isSaved: true}))
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(setFilePeople({isSaving: false, isSaved: false}))
          dispatch(toastAction.warn('Ficha Persona', data.message));
        }else{
          dispatch(setFilePeople({isSaving: false, isSaved: false}))
          dispatch(toastAction.error('Ficha Persona', data.message));
        }
      }).then(()=> {
        dispatch(setFilePeople({isSaving: false, isSaved: false}))
      })
      .catch((error)=>{
        dispatch(toastAction.error('Ficha Persona', "Error al ejecutar la petición"));
        dispatch(setFilePeople({isSaving: false, isSaved: false}))
      });
  };
}

const getVersionsDetailedList = (id, version) => {
  const setVersionList = (payload) => {
    return (dispatch) =>
      dispatch({
        type: RrhhConstants.Accion.CustomField.GET_VERSION_LIST,
        payload,
      });
  }
  return (dispatch) => {
    setFilePeople({isLoading: true})
    CustomFieldServices.getVersionsDetailedList(id, version)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setVersionList({data: data.data, isLoading: false}))
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(setVersionList({isLoading: false}))
          dispatch(toastAction.warn('Versiones', data.message));
        }else{
          dispatch(setVersionList({isLoading: false}))
          dispatch(toastAction.error('Versiones', data.message));
        }
      })
      .catch((error)=>{
        dispatch(toastAction.error('Versiones', "Error al ejecutar la petición"));
        dispatch(setVersionList({isSaving: false, isSaved: false}))
      })
  };
}

const generateVersion = (criteria) => {
  const setGenerateVersion = (payload) => {
    return (dispatch) =>
      dispatch({
        type: RrhhConstants.Accion.CustomField.GENERATE_VERSION,
        payload,
      });
  }
  return (dispatch) => {
    dispatch(setGenerateVersion({isLoading: true, isSaved: false}))
    CustomFieldServices.generateVersion(criteria)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setGenerateVersion({isLoading: false, isSaved: true}))
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(setGenerateVersion({isLoading: false, isSaved: false}))
          dispatch(toastAction.warn('Version', data.message));
        }else{
          dispatch(setGenerateVersion({isLoading: false, isSaved: false}))
          dispatch(toastAction.error('Version', data.message));
        }
      }).then(()=> {
        dispatch(setGenerateVersion({isLoading: false, isSaved: false}))
      })
      .catch((error)=>{
        dispatch(toastAction.error('Version', "Error al ejecutar la petición"));
        dispatch(setGenerateVersion({isLoading: false, isSaved: false}))
      });
  };
}

const getFilePeopleById = (data) => {
  const setPeopleById = (payload) => {
    return (dispatch) =>
      dispatch({
        type: RrhhConstants.Accion.CustomField.GET_FILE_PEOPLE_BY_ID,
        payload,
      });
  }
  return (dispatch) => {
    dispatch(setPeopleById({isLoading: true}))
    CustomFieldServices.getFilePeopleById(data)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setPeopleById({data: data.data, isLoading: false}))
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(setPeopleById({isLoading: false}))
          dispatch(toastAction.warn('Ficha Persona', data.message));
        }else{
          dispatch(setPeopleById({isLoading: false}))
          dispatch(toastAction.error('Ficha Persona', data.message));
        }
      })
      .catch((error)=>{
        dispatch(toastAction.error('Ficha Persona', "Error al ejecutar la petición"));
        dispatch(setPeopleById({isSaving: false, isSaved: false}))
      });
  };
}

const getActiveConfiguration = (data) => {
  const setActiveConfiguration = (payload) => {
    return (dispatch) =>
      dispatch({
        type: RrhhConstants.Accion.CustomField.GET_FILE_PEOPLE_BY_ID,
        payload,
      });
  }
  return (dispatch) => {
    dispatch(setActiveConfiguration({isLoading: true}))
    CustomFieldServices.setActiveConfiguration(data)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setActiveConfiguration({data: data.data, isLoading: false}))
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(setActiveConfiguration({isLoading: false}))
          dispatch(toastAction.warn('Ficha Persona', data.message));
        }else{
          dispatch(setActiveConfiguration({isLoading: false}))
          dispatch(toastAction.error('Ficha Persona', data.message));
        }
      })
      .catch((error)=>{
        dispatch(toastAction.error('Ficha Persona', "Error al ejecutar la petición"));
        dispatch(setActiveConfiguration({isSaving: false, isSaved: false}))
      })
  };
}


export const CustomFieldActions = {
  getLastFilePeople,
  getListControls,
  getListResponsibles,
  saveFilePeople,
  saveAndApproved,
  getVersionsDetailedList,
  generateVersion,
  getFilePeopleById,
  getActiveConfiguration
}