


import React, { Fragment, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { Button } from 'primereact/button';
import FilterComponent from 'features/coursesManagment/components/CoursesExpenses/FilterComponent';
import TableComponent from 'features/coursesManagment/components/CoursesExpenses/TableComponent';
import AdvancedFilterComponent from 'features/coursesManagment/components/CoursesExpenses/AdvancedFilterComponent';
import { useClaimCoursesExpenses } from 'features/coursesManagment/hooks/useClaimCoursesExpenses';

const SearchCoursesExpensesPage = () => {
  const { updateTitle } = useSetTitlePage();
  const history = useHistory();
  
  const { isSearch, isDownload, isCreate } = useClaimCoursesExpenses();
  useEffect(() => {
    updateTitle({
        title: 'Cursos',
        subtitle: 'Gastos',
    });
  });

  return (
    <Fragment>
      {
        (isSearch||isDownload)&&(
          <FilterComponent />
        )
      }
      <div className='my-3'>
        {
        isCreate&&(
              <Button
              icon="pi pi-plus"
              type="button"
              label="Nuevo"
              className="sig-button sig-dark mr-3"
              onClick={() => {
                  history.push('/curso/gastos/nuevo');
              }}
          />
          )
      }
      </div>
      <TableComponent />
      <AdvancedFilterComponent /> 
    </Fragment>
  )
}

export default SearchCoursesExpensesPage