import { CoreConstants } from 'core/commons/CoreConstants';
import moment from 'moment';
import { Chart } from 'primereact/chart';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState } from 'react'
import ReactSpeedometer from 'react-d3-speedometer';
import { useDispatch, useSelector } from 'react-redux';
import { HomeActions } from 'shared/store/actions/HomeAction';

export const IndicatorsComponent = () => {

  const dispatch = useDispatch()
  const identifiers = useSelector((state) => state.homeReducer.identifiers.data);
  const {isLoading} = useSelector((state) => state.homeReducer.identifiers);
  const getCreationDate = (rowData) => { return moment(rowData.creationDate).format(CoreConstants.Format.LOCALE); }


  const [valuesIdentifiers, setValuesIdentifiers] = useState([])


  const getLightTheme = () => {
    let barOptions = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            legend: {
               /* TODO: Poner varios titulos legends por cada valor */
                labels: {
                    color: '#495057',
                },
                display: false,
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        },
    };

    let lineOptions = { ...barOptions, aspectRatio: .8}
    let chartSelectedOptions = barOptions

    return {
        barOptions,
        lineOptions,
        chartSelectedOptions
    } 
  }

  const reduceDatasets = (data) => {
    return data?.reduce((acc, cur) => {
      acc.push(cur.value)
      return acc
    },[])
  }

  const chargeChartData = (type, chartData) => {
      if(type===CoreConstants.Entity.DashbaordIndicatorModes.BAR){
        return {
            labels: chartData.labels,
            datasets: [
                {
                    label: 'Indicadores Bar',
                    backgroundColor: ['#42A5F5', '#EC6A1D', '#ECC21D', '#B2EC1D', '#EC3C1D', '#1DECB4', '#9F1DEC', '#1D2AEC', '#1DEC48', '#EC1D4F', '#1DADEC', '#EC1DC6'],
                    data: reduceDatasets(chartData.dataSets)
                },
            ]
        }
      }else if(type===CoreConstants.Entity.DashbaordIndicatorModes.LINE){
        return {
          labels: chartData.labels,
          datasets: [
            {
              label: 'Indicadores Line',
              backgroundColor: ['#EC3C1D'],
              data: reduceDatasets(chartData.dataSets),
              tension: 0,
              borderColor: '#EC3C1D'
          },
        ]
        }
      }
    }

    useEffect(()=> {
        dispatch(HomeActions.getIndentifiers())
    },[])

    useEffect(()=> {
      if(identifiers){
        let newData = chargeData(identifiers)
        setValuesIdentifiers(newData)
      }
    },[identifiers])

    const chargeData = (data) => {
      return data.map(el=> {
        if(el.modeCode===CoreConstants.Entity.DashbaordIndicatorModes.TABLE){
          return el
        }else if(el.modeCode===CoreConstants.Entity.DashbaordIndicatorModes.CHART){
          if(el.typeCode===CoreConstants.Entity.DashbaordIndicatorModes.METER){
            return el
          }else{
            return {
              id: el.id,
              name: el.name,
              modeCode: el.modeCode,
              typeCode: el.typeCode,
              from: el.from,
              to: el.to,
              chartData: chargeChartData(el.typeCode, el.chartData)
            }
          }
        }
      })
    }



  const { barOptions, lineOptions, chartSelectedOptions } = getLightTheme();



  const columns = [
    { field: 'valueDate', header: 'Fecha', body: getCreationDate },
    { field: 'elaborator', header: 'Medido Por' },
    { field: 'value', header: 'Valor' }
  ];

  const columnComponents = columns.map(col => {

    if (col.field === 'valueDate')
        return <Column key={col.field} field={col.field} header={col.header} body={getCreationDate} sortable />;

    if (col.field === 'elaborator'|| col.field === 'meta' || col.field === 'valueMeta')
        return <Column key={col.field} field={col.field} header={col.header}/>;

    return <Column key={col.field} field={col.field} header={col.header} sortable />;
});

  const actionBodyTemplate = (e) => {
    return <div className={`bg-${e.color}-500 rounded-full h-4 w-4`}></div>
  }

  return (
    <div>
      {
        isLoading?<Skeleton width="100%" height="30rem" />:
        <div className='grid grid-cols-12 gap-3'>
          {
            valuesIdentifiers.map(item=> {
              return(<div key={item.id} className='col-span-12 lg:col-span-6 xl:col-span-4 border-2 rounded-lg box-border shadow-xl'>
                <div className='text-center p-3 border-b-2 font-bold'>{item.name}</div>
                {
                  item.modeCode===CoreConstants.Entity.DashbaordIndicatorModes.TABLE&&<DataTable
                  value={item.tableData}
                  responsiveLayout="scroll">
                    {columnComponents}
                    <Column header="" body={actionBodyTemplate}/>
                  </DataTable>
                }
                {
                  item.modeCode===CoreConstants.Entity.DashbaordIndicatorModes.CHART&&<div>
                      {
                        item.typeCode===CoreConstants.Entity.DashbaordIndicatorModes.BAR&&(<div className="" style={{width: '100%'}}>
                          <Chart type="bar" data={item.chartData} options={chartSelectedOptions} />
                        </div>)
                      }
                      {
                        item.typeCode===CoreConstants.Entity.DashbaordIndicatorModes.LINE&&(<div className="" style={{width: '100%'}}>
                          <Chart type="line" data={item.chartData} options={chartSelectedOptions} />
                        </div>)
                      }
                      {
                        item.typeCode===CoreConstants.Entity.DashbaordIndicatorModes.METER&&(<div className="" style={{width: '100%'}}>

                          <div className='w-full flex justify-center mt-4'>
                                <ReactSpeedometer
                                    maxValue={item.maxGoalValue}
                                    value={item.value}
                                    segments={item.goalValue}
                                    needleColor="black"
                                    startColor="red"
                                    endColor="green"
                                    textColor="grey"
                                    width={500}
                                    currentValueText={`Indicador ${item.value}`}
                                    // textColor={textColor}
                                />
                            </div>
                        </div>)
                      }
                    </div>
                }
              </div>)
            })
          }
        </div>
      }
    </div>
  )
}
