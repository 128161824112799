export class StudentsConstants {
  static Accion = class {
    static Students = class {
      static get ADVANCED_FILTER() {
        return "@STUDENTS.ADVANCED-FILTER";
      }
      static get SEARCH_STUDENTS() {
        return "@STUDENTS.SEARCH-STUDENTS";
      }
      static get DATA_MASTER() {
        return "@STUDENTS.DATA-MASTER";
      }
      static get CURRENT_ACTION() {
        return "@STUDENTS.CURRENT-ACTION";
      }
      static get EDIT_STUDENT() {
        return "@STUDENTS.EDIT-STUDENT";
      }
      static get REPORT_STATUS() {
        return "@STUDENTS.REPORT-STATUS";
      }
      static get RESET_AFTER_SAVED() {
        return "@STUDENTS.RESET_AFTER_SAVED";
      }
      static get UPLOAD_BATCH_INFO() {
        return "@STUDENTS.UPLOAD_BATCH_INFO";
      }
      static get SAVE_STUDENTS_BULK() {
        return "@STUDENTS.SAVE_STUDENTS_BULK";
      }
    };
  };

  static SectionsName = class {
    static get DATOS_GENERALES() {
      return 'DATOS GENERALES';
    }
    static get INFORMACION_DE_CONTACTO() {
      return 'INFORMACIÓN DE CONTACTO';
    }
    static get DIRECCION() {
      return 'DIRECCIÓN';
    }
    static get INFORMACION_ACADEMICA() {
      return 'INFORMACIÓN ACADÉMICA';
    }
    static get INFORMACION_LABORAL() {
      return 'INFORMACIÓN LABORAL';
    }
  }
}
