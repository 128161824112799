
import moment from 'moment';
import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from "core/store/actions/ToastAction";

import { DocumentConstants } from "features/document/commons/DocumentConstants";
import { DocumentManagementServices } from 'features/document/services/DocumentManagementServices';


const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: DocumentConstants.Accion.DocumentManagement.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: DocumentConstants.Accion.DocumentManagement.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.name)
      pines.push({ field: 'name', value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.DDMMYYYY
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.DDMMYYYY
        ),
      });


    advancedFilter.values = pines;
    dispatch({
      type: DocumentConstants.Accion.DocumentManagement.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const getDocumentById = (institutionId) => {
  const setDocument = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentManagement.GET_DOCUMENT_MANAGEMENT_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setDocument({ dispatch: dispatch, isLoading: true });
    DocumentManagementServices.getDocumentLibraryById(institutionId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setDocument({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setDocument({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Gestión Documental', data.message));
        }
      },
      () => {
        setDocument({ dispatch: dispatch, isLoading: false });
      }
    );
  };
};

// En uso

const createFolder = (folder) => {
  const isLoading = ({ dispatch, isSaving, isSaved }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentManagement.CREATE_FOLDER,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch) => {

    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
    });
    DocumentManagementServices.createFolder(folder).then(
      ( {data} ) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gestión Documental', 'La carpeta se creó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Gestión Documental', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }else{
          dispatch(toastAction.error('Gestión Documental', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }
      },
      // () => {
      //   isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      // }
    ).catch((error)=>{
    });
  };
};

const getDataListFolders = (selectedNode) => {
  const setListFolders = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentManagement.GET_DATA_LIST_FOLDERS,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setListFolders({ dispatch: dispatch, isLoading: true });

    DocumentManagementServices.getDataListFolders(selectedNode)
      .then(({ data }) => {
        setListFolders({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setListFolders({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error al descargar el archivo'));
      });
  }
};

const getListFoldersTable = (selectedNode) => {
  const setListFolders = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentManagement.GET_LIST_FOLDERS_TABLE,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setListFolders({ dispatch: dispatch, isLoading: true });

    DocumentManagementServices.getListFoldersTable(selectedNode)
      .then(({ data }) => {
        setListFolders({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setListFolders({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error al descargar el archivo'));
      });
  }
};

const getDataMaster = () => {
  const setDataMaster = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentManagement.GET_DATA_MASTER,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setDataMaster({ dispatch: dispatch, isLoading: true });

    DocumentManagementServices.getDataMaster()
      .then(({ data }) => {
        setDataMaster({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setDataMaster({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error al descargar el archivo'));
      });
  }
};

const deleteFolder = (folderName) => {

  return (dispatch) => {

    DocumentManagementServices.deleteFolder(folderName).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gestión Documental', 'La carpeta se eliminó correctamente'));
          dispatch({ type: DocumentConstants.Accion.DocumentManagement.DELETE_DOCUMENT_MANAGEMENT, payload: { isDeleted: true } });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Gestión Documental', data.message));
          dispatch({ type: DocumentConstants.Accion.DocumentManagement.DELETE_DOCUMENT_MANAGEMENT, payload: { isDeleted: false } });
        }else{
          dispatch(toastAction.error('Gestión Documental', data.message));
          dispatch({ type: DocumentConstants.Accion.DocumentManagement.DELETE_DOCUMENT_MANAGEMENT, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: DocumentConstants.Accion.DocumentManagement.DELETE_DOCUMENT_MANAGEMENT, payload: { isDeleted: false } });
      }
    ).catch((error)=>{
    });
  };
};

const renameFolder = (dataFolder) => {
  const setDocument = ({ dispatch, isUpdated }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentManagement.RENAME_DOCUMENT_MANAGEMENT,
      payload: { isUpdated },
    });
  };

  return (dispatch) => {
    DocumentManagementServices.renameFolder(dataFolder).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setDocument({ dispatch, isUpdated: true });
          dispatch(toastAction.success('Gestión Documental', 'La carpeta se renombró correctamente'));
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setDocument({ dispatch, isUpdated: false });
          dispatch(toastAction.warn('Gestión Documental', data.message));
        }else{
          setDocument({ dispatch, isUpdated: false });
          dispatch(toastAction.error('Gestión Documental', data.message));
        }
      }
    ).catch((error)=>{
    });
  };
};

const getPermissionByPath = (path) => {
  const setPermission = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentManagement.GET_PERMISSION_BY_PATH,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setPermission({ dispatch: dispatch, isLoading: true });
    DocumentManagementServices.getPermissionByPath(path).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setPermission({ dispatch: dispatch, isLoading: false, data: data.data});
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn('Gestión Documental', data.message));
          setPermission({ dispatch: dispatch, isLoading: false });
        }else{
          setPermission({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.error('Gestión Documental', data.message));
        }
      },
      () => {
        setPermission({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};

const savePermission = (permission) => {
  const isLoading = ({ dispatch, isSaving, isSaved }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentManagement.SAVE_FOLDER_PERMISSIONS,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch) => {

    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
    });
    DocumentManagementServices.savePermission(permission).then(
      ( {data} ) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gestión Documental', 'El permiso se guardo correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.error('Gestión Documental', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }else{
          dispatch(toastAction.error('Gestión Documental', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }
      },
      // () => {
      //   isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      // }
    ).catch((error)=>{
    });
  };
};

const isStorageEnabled = () => {
  const setIsStorageEnabled = ({ dispatch, isLoading, isEnabled }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentManagement.IS_STORAGE_ENABLED,
      payload: { isLoading, isEnabled },
    });
  };
  return (dispatch) => {
    setIsStorageEnabled({ dispatch: dispatch, isLoading: true });

    DocumentManagementServices.isStorageEnabled()
      .then(({ data }) => {
        setIsStorageEnabled({ dispatch: dispatch, isLoading: false, isEnabled: data.data});
      })
      .catch((err) => {
        setIsStorageEnabled({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error, inténtelo despues'));
      });
  }
};



export const DocumentManagementAction = {
  toggleAdvancedFilter,
  updateFilter,
  resetAfterSaved,
  
  getDocumentById,
  
  getDataListFolders,
  getListFoldersTable,
  createFolder,
  deleteFolder,
  renameFolder,
  getDataMaster,
  getPermissionByPath,
  savePermission,
  isStorageEnabled
}