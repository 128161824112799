import React, { useEffect, useState } from "react";

import useSetTitlePage from "shared/hooks/useSetTitlePage";
import "./RiskMatrixPage.scss";

import FilterComponent from "features/riskManagement/components/RiskMatrix/FilterComponent";
import MatrixComponent from "features/riskManagement/components/RiskMatrix/MatrixComponent";
import { RiskMatrixAction } from "features/riskManagement/store/actions/RiskMatrixAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const table = {
  title: "Título de la matriz",
  value: "19",
  color: "red",
  inventarios: [
    {
      id: 1,
      nombre: "Inventario 1",
      details: {
        type: 1,
        details: [
          { label: "New York", value: "NY" },
          { label: "Rome", value: "RM" },
          { label: "London", value: "LDN" },
          { label: "Istanbul", value: "IST" },
          { label: "Paris", value: "PRS" },
        ],
        value: ["NY", "RM"],
        valueText: ["New York", "Rome"],
        values: [
          {
            value: ["NY", "RM"],
            valueText: ["New York", "Rome"],
          },
          {
            value: ["NY", "RM"],
            valueText: ["New York", "Rome"],
          },
        ],
      },
    },
    {
      id: 2,
      nombre: "Inventario 2",
      details: {
        type: 2,
        value: "Prueba",
        valueText: "Prueba",
      },
    },
    {
      id: 3,
      nombre: "Inventario 3",
      details: {
        type: 3,
        details: [
          { label: "New York", value: "NY" },
          { label: "Rome", value: "RM" },
          { label: "London", value: "LDN" },
          { label: "Istanbul", value: "IST" },
          { label: "Paris", value: "PRS" },
        ],
        value: "NY",
        valueText: "New York",
      },
    },
  ],
  evaluaciones: [
    {
      id: 1,
      nombre: "Evaluación 1",
      formula: "[a] + 2 * [b] - [c]",
      criterios: [
        {
          id: 4,
          code: "a",
          nombre: "Para A segun",
          details: {
            type: 3,
            details: [
              { otro: "New York", id: "NY", idNumber: 1 },
              { otro: "Rome", id: "RM", idNumber: 2 },
              { otro: "London", id: "LDN", idNumber: 3 },
              { otro: "Istanbul", id: "IST", idNumber: 4 },
              { otro: "Paris", id: "PRS", idNumber: 5 },
            ],
            value: "LDN",
            valueText: "London",
          },
        },
        {
          id: 5,
          code: "b",
          nombre: "Para B segun",
          details: {
            type: 3,
            details: [
              { otro: "New York", id: "NY", idNumber: 1 },
              { otro: "Rome", id: "RM", idNumber: 2 },
              { otro: "London", id: "LDN", idNumber: 3 },
              { otro: "Istanbul", id: "IST", idNumber: 4 },
              { otro: "Paris", id: "PRS", idNumber: 5 },
            ],
            value: "RM",
            valueText: "Rome",
          },
        },
        {
          id: 6,
          nombre: "Para C segun",
          code: "c",
          details: {
            type: 3,
            details: [
              { otro: "New York", id: "NY", idNumber: 1 },
              { otro: "Rome", id: "RM", idNumber: 2 },
              { otro: "London", id: "LDN", idNumber: 3 },
              { otro: "Istanbul", id: "IST", idNumber: 4 },
              { otro: "Paris", id: "PRS", idNumber: 5 },
            ],
            value: "IST",
            valueText: "Istanbul",
          },
        },
      ],
      value: 2,
    },
    {
      id: 2,
      nombre: "Evaluación 2",
      formula: "[m] - 2[n]",
      criterios: [
        {
          id: 7,
          code: "m",
          nombre: "Para M segun",
          details: {
            type: 3,
            details: [
              { otro: "New York", id: "NY", idNumber: 1 },
              { otro: "Rome", id: "RM", idNumber: 2 },
              { otro: "London", id: "LDN", idNumber: 3 },
              { otro: "Istanbul", id: "IST", idNumber: 4 },
              { otro: "Paris", id: "PRS", idNumber: 5 },
            ],
            value: "NY",
            valueText: "New York",
          },
        },
        {
          id: 8,
          code: "n",
          nombre: "Para N segun",
          details: {
            type: 3,
            details: [
              { otro: "New York", id: "NY", idNumber: 1 },
              { otro: "Rome", id: "RM", idNumber: 2 },
              { otro: "London", id: "LDN", idNumber: 3 },
              { otro: "Istanbul", id: "IST", idNumber: 4 },
              { otro: "Paris", id: "PRS", idNumber: 5 },
            ],
            value: "PRS",
            valueText: "Paris",
          },
        },
      ],
      value: 8,
    },
  ],
};

const RiskMatrixPage = () => {
  const [mode, setMode] = useState("lectura");
  const { data } = useSelector((state) => state.riskMatrixReducer.matrix);
  const dispatch = useDispatch();
  const { updateTitle } = useSetTitlePage();
  const { id } = useParams();

  useEffect(() => {
    if (data.name) {
      updateTitle({
        title: "Matrices de Riesgos",
        subtitle: data.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    dispatch(RiskMatrixAction.getMatrixStructure(id));

    return () => {
      setMode("lectura");
      dispatch(RiskMatrixAction.clearData());
      dispatch(RiskMatrixAction.clearMatrixStructure());
      dispatch(RiskMatrixAction.clearTable());
    };
  }, [id, dispatch]);

  return (
    <div>
      {mode === "lectura" && <FilterComponent />}
      <MatrixComponent table={table} mode={mode} setMode={setMode} />
    </div>
  );
};

export default RiskMatrixPage;
