import { MaintenanceConstants } from "features/maintenance/commons/MaintenanceConstans"; 
import { CoreConstants } from 'core/commons/CoreConstants';
import moment from 'moment';
import { toastAction } from "core/store/actions/ToastAction";
import FileSaver from 'file-saver';

import { InstitutionsService } from "features/maintenance/services/InstitutionsServices";


const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: MaintenanceConstants.Accion.Institution.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: MaintenanceConstants.Accion.Institution.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.name)
      pines.push({ field: 'name', value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });


    advancedFilter.values = pines;
    dispatch({
      type: MaintenanceConstants.Accion.Institution.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setInstitutions = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Institution.SEARCH_INSTITUTIONS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const institutions = getState().institutionReducer.institutions;
    setInstitutions({
      dispatch: dispatch,
      isLoading: true,
      data: institutions.data,
    });

    const criteria = {};
    const advancedFilter = getState().institutionReducer.advancedFilter;
    const filter = getState().institutionReducer.advancedFilter.filter;
    
    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    InstitutionsService.searchInstitutions(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setInstitutions({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });          
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          setInstitutions({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Instituciones', data.message));
        }else{
          setInstitutions({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Instituciones', data.message));
        }
      },
      // () => {
      //   setInstitutions({ dispatch: dispatch, isLoading: false, data: null });
      // }
    ).catch((error)=>{
    });
  };
};

const getInstitutionById = (institutionId) => {
  const setInstitution = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Institution.GET_INSTITUTION_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setInstitution({ dispatch: dispatch, isLoading: true });
    InstitutionsService.getInstitutionById(institutionId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setInstitution({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setInstitution({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Instituciones', data.message));
        }else{
          dispatch(toastAction.error('Institución', data.message));
        }
      },
      () => {
        setInstitution({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};

const saveInstitution = (institution) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentInstitution }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Institution.SAVE_INSTITUTION,
      payload: {
        isSaving,
        isSaved,
        // currentInstitution
      },
    });
  };

  return (dispatch, getState) => {
    const currentInstitution = getState().institutionReducer.institution;

    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
      // currentInstitution,
    });
    InstitutionsService.saveInstitutions(institution).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Institución', 'La institución se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentInstitution });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentInstitution });
          dispatch(toastAction.warn('Institución', data.message));
        }else{
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
          dispatch(toastAction.error('Institución', data.message));
        }
      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};



const deleteInstitution = (institutionId) => {

  return (dispatch, getState) => {

    InstitutionsService.deleteInstitution(institutionId).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Institución', 'La institución se eliminó correctamente'));
          dispatch({ type: MaintenanceConstants.Accion.Institution.DELETE_INSTITUTION, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Institución', data.message));
          dispatch({ type: MaintenanceConstants.Accion.Institution.DELETE_INSTITUTION, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: MaintenanceConstants.Accion.Institution.DELETE_INSTITUTION, payload: { isDeleted: false } });
      }
    );
  };
};

const generateReport = (payload) => {
  const setInstitutions = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Institution.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setInstitutions({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().institutionReducer.advancedFilter.filter;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    InstitutionsService.generateReport(criteria)
      .then(({ data }) => {
        setInstitutions({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Institución.xls');
      })
      .catch((err) => {
        setInstitutions({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Institución', 'Hubo un error al descargar el archivo'));
      });
  };
};

const getDataCountries = () => {
  const setCountries = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Institution.GET_DATA_MASTER,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setCountries({ dispatch: dispatch, isLoading: true });

    InstitutionsService.getDataCountries()
      .then(({ data }) => {
        setCountries({ dispatch: dispatch, isLoading: false, data: data.data.categories});
      })
      .catch((err) => {
        setCountries({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Institución', 'Hubo un error al descargar el archivo'));
      });
  }
};

export const InstitutionAction = {
  toggleAdvancedFilter,
  updateFilter,
  search,
  saveInstitution,
  deleteInstitution,
  generateReport,
  resetAfterSaved,
  getInstitutionById,
  getDataCountries
}