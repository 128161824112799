import { useHistory } from 'react-router-dom';

const useCurrenItem = () => {
  const history = useHistory();

  const removeAccents = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const selectItem = () => {
    const parentItems = document.querySelectorAll(
      '.p-panelmenu .p-panelmenu-header-link'
    );

    parentItems?.forEach((ITEM) => {
      let { text, baseURI } = ITEM;
      let item = removeAccents(text).toLowerCase().replaceAll(' ', '-');
      const isSecurity =
        baseURI.includes('rol') || baseURI.includes('usuarios');
      if ('gestion-de-la-organizacion' === item) {
        item = 'rrhh';
      } else if ('seguridad' === item && isSecurity) {
        item = 'configuracion';
      }

      if (baseURI.includes(item)) {
        ITEM.click();
        setTimeout(() => {
          selectSubItems();
        }, 600);
      }
    });
  };

  const selectSubItems = () => {
    const itemsElements = document.querySelectorAll('.p-submenu-list a');
    itemsElements?.forEach((item) => {
      const currentItem = history.location.pathname;
      if (item.href.includes(currentItem)) item.classList.add('current-item');
      else item.classList.remove('current-item');
    });
  };
  return {
    selectItem,
    selectSubItems,
  };
};

export default useCurrenItem;
