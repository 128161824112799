import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu'
import React, { useRef } from 'react'

export const ButtonMultipleSend = ({items, isLoading, disabled}) => {
  const menu = useRef(null)
  return (
    <>
      <Button
        icon="pi pi-angle-double-up"
        type="button"
        label="Guardar"
        disabled={disabled&&disabled===true}
        onClick={(event) => menu.current.toggle(event)}
        loading={isLoading}
        className="sig-button sig-primary mt-4"
      />
      <Menu
         model={items}
         popup
         ref={menu}
      /></>
  )
}
