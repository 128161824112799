import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { Panel } from 'primereact/panel'
import { Skeleton } from 'primereact/skeleton'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { set } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'



export const CustomField = ({listOptions, dataExtraModal, setDataExtraModal, setMnsjValidate, mnsjValidate, isLoading, disabled}) => {


  const addElementRows = () => {
    let validate = false
    setMnsjValidate(mnsjValidate.filter(el => el!=='Debe existir mínimo un control.'))
    let mnsjs = [...mnsjValidate]
    let data = [...dataExtraModal].map(el => {
      el.collapse = false
      if(el.text===""){
        validate = true
        if(!mnsjs.some(est => est==='Debes especificar un título para el campo.')){
            mnsjs.push('Debes especificar un título para el campo.')
        }
        el.errorText = true
        el.collapse = true
      }
      if(!el.controlId){
        validate = true
        if(!mnsjs.some(est => est==='Debes especificar el tipo de control.')){
            mnsjs.push('Debes especificar el tipo de control.')
        }
        el.errorDrop = true
        el.collapse = true
      }
      if(el.typeControl === 'Selección Simple' || el.typeControl === 'Selección Múltiple'){
        if(el.details.length>0){
          el.details.map((item)=> {
            if((item.text.trim())===""){
              validate=true
              el.collapse = true
              if(!mnsjs.some(est => est==='Los nombres de las opciones son requeridas.')){
                mnsjs.push('Los nombres de las opciones son requeridas.')
              }
            }
          })
        }else{
          validate=true
          el.collapse = true
          if(!mnsjs.some(est => est==='El control debe tener mínimo una opción.')){
            mnsjs.push('El control debe tener mínimo una opción.')
          }
        }
      }
      return el
    })
    if(validate===false){
      data.push({
        id: uuidv4(),
        text: "",
        typeControl: "",
        icon: null,
        controlId: null,
        required: false,
        collapse: true,
        mode: "new",
        identifier: null,
        details: []
      })
      setDataExtraModal(data)
    }else{
      setMnsjValidate(mnsjs)
      setDataExtraModal(data)
    }
  }

  const deleteElementRow = (id) => {
    setDataExtraModal(dataExtraModal.filter(elem => elem.id!==id))
  }

  const getListStyleZone = (isDraggingOver, otro) => ({
    background: isDraggingOver ? "white" : "white",
    //rgb(191 219 254)
    border: isDraggingOver ? '1px dashed rgb(75 85 99)' : 'none',
    // borderTop: isDraggingOver ? 'none' : '1px solid rgb(75 85 99)',
    margin: '0 0 0 0'
  });

  const changeName = (e,index) => {
    const data = [...dataExtraModal]
    setMnsjValidate(mnsjValidate.filter(el => el!=='Debes especificar un título para el campo.'))
    data[index].text = e.target.value
    data[index].errorText = false
    setDataExtraModal(data)
  }

  const changeNameOption = (e,i, index) => {
    setMnsjValidate(mnsjValidate.filter(ele => ele!=='Los nombres de las opciones son requeridas.'))
    const data = [...dataExtraModal]
      data[index].details[i].text = e.target.value
    setDataExtraModal(data)
  }

  //eliminar opción por indice
  const deleteOption = (i, index) =>{
    const data = [...dataExtraModal]
    data[index].details.splice(i, 1);
    setDataExtraModal(data)
  }

  const changeOptionDropDown = (e, id) => {
    if(e.value.code){
      setMnsjValidate(mnsjValidate.filter(ele => ele!=='Debes especificar el tipo de control.'))
      setDataExtraModal(dataExtraModal.map(item => {
        if(item.id===id){
          item.controlId = e.value.id ? e.value.id : null
          let control = (listOptions.find(elem => elem.id===e.value.id))
          item.typeControl = control?.name
          item.icon= control?.icon
          item.errorDrop = false
          if(e.value.code==="TEXTO" || e.value.code==="TEXTO_LARGO" || e.value.code==="TEXTO_DINAMICO"){
            item.details = []
            item.visibleButton = false
          }else{
            item.visibleButton = true
          }
        }
        return item
      }))
    }
  }

  const addOption = (e, index) => {
    setMnsjValidate(mnsjValidate.filter(ele => ele!=='El control debe tener mínimo una opción.'))
    const data = [...dataExtraModal]
    data[index].details.push({id: uuidv4(), text: '', mode: "new"})
    setDataExtraModal(data)
    }

    const controlsOptionTemplate = (option) => {
      return (
          <div className="flex items-center">
              <i className={`pi ${option.icon} mr-2`}/>
              <div>{option.name}</div>
          </div>
      );
  }

  const selectedColumnTemplate = (option, props) => {
    const selectData = listOptions.find(el => el.id===props.value)
    if (props.value && selectData) {
        return (
            <div className="column-item column-item-value flex items-center">
                <i className={selectData?.icon?selectData?.icon:"pi pi-align-justify"} style={{'fontSize': '1em'}} />
                <div className='ml-3'>{selectData?.name}</div>
            </div>
        );
    }
    return (
      <span>
          {props.placeholder}
      </span>
  );
  }

  const changeRequired = (e, index) => {
    setDataExtraModal(dataExtraModal.map((el,i)=> {
      if(i===index){
        el.required = e.value
      }
      return el
    }))
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) return
    if ( source.index === destination.index && source.droppableId === destination.droppableId ) return
    if (source.droppableId === "items-drop" && destination.droppableId === "items-menu") return
    if ( source.droppableId === destination.droppableId && source.droppableId === "items-drop"  ) {
      setDataExtraModal((prevInputs) => reorder(prevInputs, source.index, destination.index) )
      return
    }

  };

  const normalizeIndentifier = (str) => {
    str = (((str.trim()).replace(/ /g,"_")).toLowerCase())
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const onValidateIndentifier = (e, index) => {
    if(e.target.value){
      let data = [...dataExtraModal]

      if(!data[index].identifier){
        let identf = normalizeIndentifier(e.target.value)
        let validate = dataExtraModal.some(el=> el.identifier===identf)

        if(validate===false){
          setMnsjValidate(mnsjValidate.filter(ele => ele!=='Ya existe este nombre de control.'))
          data[index].errorIdentifier = false
          data[index].identifier = identf
        }else{
          data[index].errorIdentifier = true
          if(mnsjValidate.length===0){
            setMnsjValidate(['Ya existe este nombre de control.'])
          }else{
            setMnsjValidate([...mnsjValidate,'Ya existe este nombre de control.'])
          }
        }
      }else{
        setMnsjValidate(mnsjValidate.filter(ele => ele!=='Debes especificar un título para el campo.'))
        data[index].text = (e.target.value).trim()
      }
      setDataExtraModal(data)
    }
  }

  const onCollapseElement = (index) => {
    let validate = false
    let mnsjs = [...mnsjValidate]
    let data = [...dataExtraModal].map(el => {
      el.collapse = false
      if(el.text===""){
        validate = true
        if(!mnsjs.some(est => est==='Debes especificar un título para el campo.')){
            mnsjs.push('Debes especificar un título para el campo.')
        }
        el.errorText = true
        el.collapse = true
      }
      if(!el.controlId){
        validate = true
        if(!mnsjs.some(est => est==='Debes especificar el tipo de control.')){
            mnsjs.push('Debes especificar el tipo de control.')
        }
        el.errorDrop = true
        el.collapse = true
      }
      if(el.typeControl === 'Selección Simple' || el.typeControl === 'Selección Múltiple'){
        if(el.details.length>0){
          el.details.map((item)=> {
            if((item.text.trim())===""){
              validate=true
              el.collapse = true
              if(!mnsjs.some(est => est==='Los nombres de las opciones son requeridas.')){
                mnsjs.push('Los nombres de las opciones son requeridas.')
              }
            }
          })
        }else{
          validate=true
          el.collapse = true
          if(!mnsjs.some(est => est==='El control debe tener mínimo una opción.')){
            mnsjs.push('El control debe tener mínimo una opción.')
          }
        }
      }
      return el
    })
    if(validate===false){
      data[index].collapse = true
      setDataExtraModal(data.map((elem,indice)=> {
        if(indice!==index){
          elem.collapse = false
        }else{
          elem.collapse = true
        }
        return elem
      }))
    }else{
      setMnsjValidate(mnsjs)
      setDataExtraModal(data)
    }
  }

  return (
    <Panel header="Campo Personalizado">
      {
        isLoading?(<Skeleton width="100%" height="7rem" />):
        <DragDropContext onDragEnd={(result) => onDragEnd(result) }>
          {
            disabled===false&&<Button className='sig-button sig-primary' icon="pi pi-plus" label='Agregar Campos' type='button' onClick={addElementRows}/>
          }
        <Droppable droppableId="items-drop" direction='vertical'>
          {
            (droppableProvided, snapshot) => (
              <div
                {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}
                style={getListStyleZone(snapshot.isDraggingOver, snapshot.isUsingPlaceholder)}
                className='flex flex-col items-center rounded-lg gap-y-4 px-2 sm:px-6 py-3 border-t border-gray-600'
              >
              {
                dataExtraModal?.map((elem, index)=> {
                  return (
                    <Draggable
                      key={`drag-${elem.id}`}
                      draggableId={`${elem.id}`}
                      index={index}
                      isDragDisabled={disabled}
                      >
                    {
                    (draggableProvided, snapshot) => (
                      <>
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        className='w-full bg-white'
                      >
                      {
                        elem.collapse?(
                          <div className={`${disabled===false&&'rounded-lg border-2 border-gray-400'} mt-2 w-full`}>
                    <div className='flex justify-between m-4'>
                      <div className='flex items-center'><p className='mr-2'>Obligatorio:</p>
                        <InputSwitch checked={elem.required} onChange={(e) => changeRequired(e, index)} disabled={disabled}/>
                      </div>
                      <div>
                        {
                          disabled===false&& <i className='pi pi-trash cursor-pointer text-lg' onClick={(e) => {
                            setMnsjValidate([])
                            deleteElementRow(elem.id)}}/>
                        }
                      </div>
                    </div>
                    <div className='grid grid-cols-4 mb-4'>
                        <div className='col-span-2 mx-4'>
                          <InputText value={elem?.text} disabled={disabled} className={(elem?.errorText||elem?.errorIdentifier)&&'p-invalid'} onChange={(e) => changeName(e, index)} onBlur={(e)=> onValidateIndentifier(e,index)}/>
                        </div>
                        <div className='col-span-2 mx-4'>
                          <Dropdown options={listOptions} disabled={disabled} value={elem.controlId} className={elem?.errorDrop&&'p-invalid'} valueTemplate={selectedColumnTemplate} itemTemplate={controlsOptionTemplate} optionLabel="name" onChange={(e)=>changeOptionDropDown(e,elem.id)} placeholder="Seleccione un control"/>
                        </div>
                    </div>
                    {
                      elem.visibleButton&&<div className='m-4'>
                      {
                        elem?.details?.map((el, i)=> <div key={i} className='flex w-4/12 items-center mt-2'>
                            <InputText value={el.text} className='inline rounded-lg border-indigo-400 border-2 pt-2 pb-2 pl-2' disabled={disabled} onChange={(e)=> changeNameOption(e,i, index)}/>
                            {
                              disabled===false&&<i className='pi pi-trash block ml-2' style={{'fontSize': '1.5em'}} onClick={() => {deleteOption(i, index)}}/>
                            }
                          </div>)
                      }
                      {
                       disabled===false&&<Button className='sig-button sig-primary mt-2' icon="pi pi-plus" label='Añadir' type='button' onClick={(e)=> addOption(e, index)}/>
                      }
                    </div>
                    }
                      </div>
                        ):(<div className='rounded-lg border-2 mt-2 w-full p-4' onClick={(e)=> {
                              if(disabled===false){
                              onCollapseElement(index)} }}>
                            <div className='flex justify-between'>
                              <span>{elem.text}</span>
                              <div className='flex items-center'>{elem.required?'Obligatorio':'Opcional'} | <i className={`pi text-blue-500 mx-2 ${elem.icon}`}/> <p className='text-blue-500'>{elem.typeControl}</p></div>
                            </div>
                            <hr/>
                            <div className='py-2'>
                              {
                                elem.details.length>0?`Opciones (${elem.details.length})`:<>{ elem.typeControl==='Texto'?' Este campo permite escribir 150 caracteres.':<>{elem.typeControl==='Texto Largo'&&'Este campo permite escribir 1500 caracteres.'}</>}</>
                              }
                            </div>
                        </div>
                        )
                      }
                    </div>
                    </>)
                    }
                    </Draggable>
                    )
                })
              }
              </div>)}
              </Droppable>
        </DragDropContext>
      }
    </Panel>
  )
}
