import { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';

import FilterComponent from 'features/rrhh/components/positions/FilterComponent';
import TableComponent from 'features/rrhh/components/positions/TableComponent';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { PositionsAction } from 'features/rrhh/store/actions/PositionsAction';
import { DeparmentsAction } from 'features/rrhh/store/actions/DepartmentAction';
import useFilter from 'features/rrhh/hooks/positions/useFilter';
import useTable from 'features/rrhh/hooks/positions/useTable';
import scrollTop from 'shared/utils/scrollTop';
import { useClaimPositions } from 'features/rrhh/hooks/useClaimPositions';

const PositionsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { updateTitle } = useSetTitlePage();
  const { isOpenModal } = useFilter();
  const { confirmDelete, cancelDelete } = useTable();
  const { isSearch, isDownload, isCreate } = useClaimPositions();

  useEffect(() => {
    updateTitle({
      title: 'Gestión de la Organización',
      subtitle: 'Cargos',
    });
  }, []);

  useEffect(() => {
    dispatch(PositionsAction.getPositions({ change: false }));
    dispatch(DeparmentsAction.getDataMaster());
  }, []);

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <Fragment>
      {
          (isSearch||isDownload)&&(
              <FilterComponent />
          )
      }
      {
          isCreate&&(
            <Button
              icon="pi pi-plus"
              type="button"
              label="Nuevo"
              className="sig-button sig-dark mt-4"
              onClick={() => {
                history.push('/rrhh/cargos/nuevo');
              }}
            />
          )
      }
      <TableComponent />
      <ConfirmDialog
        visible={isOpenModal}
        onHide={cancelDelete}
        message="¿Está seguro que desea eliminar el cargo?"
        header="Eliminar"
        icon="pi pi-trash"
        accept={confirmDelete}
        reject={cancelDelete}
      />
    </Fragment>
  );
};
  
export default PositionsPage;
  