import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ProjectAction } from "features/customers/store/actions/ProjectAction";
import { useForm } from "shared/hooks/useForm";
import { CoreConstants } from "core/commons/CoreConstants";
import moment from "moment";
import { useParams } from "react-router-dom";

export const useAdvancedFilterProject = () => {
 
  const dispatch = useDispatch(); 

  const projectLoading = useSelector(
    (state) => state.projectReducer.project.loading
  );
  const reportLoading = useSelector(
    (state) => state.projectReducer.downloadReport.loading
  );

  const dataMaster = useSelector(
    (state) => state.projectReducer.dataMaster.data
  );

  const { values, showModal } = useSelector(
    (state) => state.projectReducer.advancedFilter
  );  
  
  const customerId = useParams()?.customerId;

  const showPiners = values.length > 0;

  const initialValues = {
    name: null,
    customerId: customerId ? {field: 'customerId', id: +customerId} : null,
    amount: null,
    statusId: null,
    responsibleId: null,
    businessId: null,
    situationId: null,
    fromDate: null, 
    toDate: null
  };

  const viewProjectsinitialValues = {
    customerId: null,
    statusId: null,
    responsibleId: null,
    businessId: null,
    situationId: null
  };

  const [formValues, setValues] = useState(initialValues);

  const reset = (newFormState = initialValues) => {
    setValues(initialValues);
    dispatch(
      ProjectAction.setAdvancedFilter({
        showModal: false,
        values: []
      })
    );
    dispatch(
      ProjectAction.getProject({ fields: {customerId:  [+customerId]}, change: true })
    );
  };

  const handleChange = ({ target }) => {
    setValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  // const [viewFormValues, viewHandleChange, viewReset] = useForm(viewProjectsinitialValues);
  const [errors, setErrors] = useState([]);

  const onToggleModal = (showModal) => {
    setErrors([]);
    dispatch(ProjectAction.setAdvancedFilter({ showModal }));
  };

  const clearFields = () => {
    reset(initialValues);
    setErrors([]);
  };

  useEffect(() => {
    if (values.length === 0) {
      // clearFields();
      setErrors([]);
    }
  }, [values]);

  const buildFilterValues = () => {
    const currentPiners = [...values];
    for (let valueKey in formValues) {
      const key = valueKey.toLocaleLowerCase();
      const field = valueKey;
      const hasValue = formValues[valueKey];
      if (hasValue) {
        if (key.includes("date")) {
          const value = moment(formValues[valueKey]).format(
            CoreConstants.Format.DDMMYYYY
          );
          const date = formValues[valueKey];
          // currentValues[field] = date;
          const index = currentPiners
            .map((piner) => piner.field)
            .indexOf(field);
          index === -1
            ? currentPiners.push({ field, value, date })
            : currentPiners.splice(index, 1, { field, value, date });
        } else if (key.includes("id")) {
          const option = formValues[valueKey];
          let value = "";
          if (formValues[valueKey].description) {
            value = formValues[valueKey].description;
          } else if (formValues[valueKey].niceName) {
            value = formValues[valueKey].niceName;
          } else if (formValues[valueKey].fullName) {
            value = formValues[valueKey].fullName;
          } else if (formValues[valueKey].name) {
            value = formValues[valueKey].name;
          }
          // currentValues[field] = option.id;
          const index = currentPiners
            .map((piner) => piner.field)
            .indexOf(field);
          index === -1
            ? currentPiners.push({ field, value, option })
            : currentPiners.splice(index, 1, { field, value, option });
        } else if (key === "iscompleted") {
          const option = formValues[valueKey];
          const value = formValues[valueKey].description;
          // currentValues[field] = option.code === "TCO";
          const index = currentPiners
            .map((piner) => piner.field)
            .indexOf(field);
          index === -1
            ? currentPiners.push({ field, value, option })
            : currentPiners.splice(index, 1, { field, value, option });
        } else {
          const value = formValues[valueKey];
          // currentValues[field] = value;
          const index = currentPiners
            .map((piner) => piner.field)
            .indexOf(field);
          index === -1
            ? currentPiners.push({ field, value })
            : currentPiners.splice(index, 1, { field, value });
        }
      }
    }

    console.log(currentPiners)

    const currentValues = {};

    currentPiners.forEach((piner) => {
      if (piner.field === "name"){
        currentValues[piner.field] = piner.value;
      } else if (piner.field === "customerId") {
        currentValues[piner.field] = [piner.option.id];
      } else if (piner.field === "fromDate") {
        currentValues[piner.field] = piner.date;
      } else if (piner.field === "toDate") {
        currentValues[piner.field] = piner.date;
      } else if (piner.field === "responsibleId") {
        currentValues[piner.field] = [piner.option.id];
      } else if (piner.field === "amount") {
        currentValues[piner.field] = Number(piner.value);
      } else if (piner.field === "statusId") {
        currentValues[piner.field] = [piner.option.id];
      } else if (piner.field === "situationId") {
        currentValues[piner.field] = piner.option.id;
      } else if (piner.field === "businessId") {
        currentValues[piner.field] = piner.option.id;
      }
    });

    currentValues['customerId'] = [+customerId];   

    return {
      currentPiners,
      currentValues,
    };
 
  };

  const viewBuildFilterValues = () => {
    // const currentValues = { ...formValues };
    const currentPiners = [];
    const currentValues = {};
    for (let valueKey in formValues){
      if(formValues[valueKey]){
        if(Array.isArray([formValues[valueKey]])){
          if(formValues[valueKey].length>0){
            currentValues[valueKey] = formValues[valueKey].map(el => {
              if(el.name){
                currentPiners.push({ field: valueKey + "-" + el.id, value: el.name, option: el});
              }else if(el.fullName){
                currentPiners.push({ field: valueKey + "-" + el.id, value: el.fullName, option: el});
              }else if(el.description){
                currentPiners.push({ field: valueKey + "-" + el.id, value: el.description, option: el});
              }
              return el.id
            })
          }
        }else{
          currentValues[valueKey] = formValues[valueKey]
        }
      }
    }

    // for (let valueKey in currentValues) {
    //   const key = valueKey.toLocaleLowerCase();
    //   const field = valueKey;
    //   const isHasValue = formValues[valueKey];
    //   if (isHasValue) {
    //     if (key.includes("id")) {        
    //       const option = currentValues[valueKey];
    //       let value = "";
    //       if (currentValues[valueKey].description) {
    //         value = currentValues[valueKey].description;
    //       } else if (currentValues[valueKey].name) {
    //         value = currentValues[valueKey].name;
    //       } else if (currentValues[valueKey].fullName) {
    //         value = currentValues[valueKey].fullName;
    //       }
    //       currentValues[valueKey] = option.id;
    //       currentPiners.push({ field, value, option });
    //     } else {          
    //       const value = currentValues[valueKey];
    //       currentPiners.push({ field, value });
    //     }
    //   }
    // }

    return {
      currentPiners,
      currentValues,
    };
  };

  const onAdvancedFilter = (e) => {
    e.preventDefault();
    setErrors([]);

    let { currentValues, currentPiners } = buildFilterValues();

    dispatch(
      ProjectAction.setAdvancedFilter({
        showModal: false,
        values: currentPiners,
      })
    );
    dispatch(
      ProjectAction.getProject({ fields: currentValues, change: true })
    );
  };

  const viewOnAdvancedFilter = (e) => {
    e.preventDefault();
    setErrors([]); //Values se demora en obtener los valores al buscar
    viewBuildAdvancedFilters();
  };

  const buildAdvancedFilters = () => {
    let { currentValues, currentPiners } = buildFilterValues();
   
    const currentSearchValue = values.find(({ field }) => field === "name") ?? {};
   
    if (Object.values(currentSearchValue).length > 0) {
      currentValues.name = currentSearchValue.value;
      currentPiners = [
        ...currentPiners.filter((p) => p.field !== "name"),
        currentSearchValue,
      ];
    }

    dispatch(
      ProjectAction.setAdvancedFilter({
        showModal: false,
        values: currentPiners,
      })
    );
   
    dispatch(
      ProjectAction.getProject({ fields: currentValues, change: true })
    );
  }

  const viewBuildAdvancedFilters = () => {
    let { currentValues, currentPiners } = viewBuildFilterValues();    

    const currentSearchValue = values.find(({ field }) => field === "name") ?? {};

    if (Object.values(currentSearchValue).length > 0) {
      currentValues.name = currentSearchValue.value;
      currentPiners = [
        ...currentPiners.filter((p) => p.field !== "name"),
        currentSearchValue,
      ];
    }

    dispatch(
      ProjectAction.setAdvancedFilter({
        showModal: false,
        values: currentPiners,
      })
    );
   
    dispatch(
      ProjectAction.getProject({ fields: currentValues, change: true })
    );
  }

  const removePiner = (field) => {
    if(field.includes('-')){
      setValues(preValues => {
        const newForm = JSON.parse(JSON.stringify(preValues))
        const newValue = preValues[field.split('-')[0]].filter( item => Number(item.id) !== Number(field.split('-')[1]))
        return ({ ...newForm, [field.split('-')[0]]: newValue})
      })
    }
    dispatch(ProjectAction.removeFilterValues(field));
  };

  // const removePiner = (field) => {
  //   let valuesField = field.split("-");

  //   let newCurrentValues =  formValues[valuesField[0]].filter(item => {
  //     if(item.id!==Number(valuesField[1])){
  //       return item
  //     }})

  //     let newValues = {...formValues}
  //     newValues[valuesField[0]] = newCurrentValues

  //     const currentValues = {};
  //     for (let valueKey in newValues){
  //       if(newValues[valueKey]){
  //         if(Array.isArray([newValues[valueKey]])){
  //           if(newValues[valueKey].length>0){
  //             currentValues[valueKey] = newValues[valueKey].map(el => {
  //               return el.id
  //             })
  //           }
  //         }else{
  //           currentValues[valueKey] = newValues[valueKey]
  //         }
  //       }
  //     }

  //   setValues({
  //     ...formValues,
  //     [valuesField[0]]: newCurrentValues
  //   })

  //   dispatch(
  //     ProjectAction.setAdvancedFilter({
  //       showModal: false,
  //       values: values.filter(el=> el.field !== field),
  //     })
  //   );

  //   dispatch(
  //     ProjectAction.getProject({ fields: currentValues, change: true })
  //   );
  // };

  const generateReport = () => {
    let { currentValues } = buildFilterValues();
    dispatch(ProjectAction.generateReport(currentValues))
  }

  return {
    onToggleModal,
    clearFields,
    showModal,
    onAdvancedFilter,
    viewOnAdvancedFilter,
    projectLoading,
    reportLoading,
    dataMaster,
    formValues,
    handleChange,
    showPiners,
    piners: values,
    removePiner,
    errors,
    generateReport,
    viewFormValues: formValues, 
    viewHandleChange: handleChange, 
    viewReset: reset
  };
};
