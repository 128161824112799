import { TabMenu } from 'primereact/tabmenu';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTableCompanies } from 'features/customers/hooks/companies/useTableCompanies';
import './TabCustomerMenu.scss';
import { useClaimCompanies } from 'features/customers/hooks/useClaimCompanies';

export const TabCustomerMenu = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const history = useHistory();
  const { onChangeTab } = useTableCompanies();

  const { isTask,
    isBusiness,
    isProject } = useClaimCompanies()

  let items = [
    {label: 'Inicio', icon: 'pi pi-fw pi-home', className: "btn-edit-companies-class", id: 'info', command:(e)=>{onChangeTab(e.item.id);}},
    {label: 'Tareas', icon: 'pi pi-fw pi-check', className: "btn-edit-companies-class", id: 'task', command:(e)=>{ onChangeTab(e.item.id); }},
    {label: 'Negocios', icon: 'pi pi-fw pi-briefcase', className: "btn-edit-companies-class", id: 'business', command:(e)=>{ onChangeTab(e.item.id); }},
    {label: 'Proyectos', icon: 'pi pi-fw pi-share-alt', className: "btn-edit-companies-class", id: 'project', command:(e)=>{ onChangeTab(e.item.id); }},
  ];

  if (!isTask)
    items = items.filter(c => c.label !== 'Tareas');
  if (!isBusiness)
    items = items.filter(c => c.label !== 'Negocios');
  if (!isProject)
    items = items.filter(c => c.label !== 'Proyectos');



  useEffect(() => {
    let result = history.location.pathname.indexOf("tareas");
    if(result >= 0){
      setActiveIndex(1);
    } else {
      result = history.location.pathname.indexOf("negocios");
      if(result >= 0) {
       setActiveIndex(2);
      } else {
        result = history.location.pathname.indexOf("proyectos");
        if(result >= 0) {
          setActiveIndex(3);
         } else {           
          setActiveIndex(0);
         }
      }
    }
  }, []);

  return (
    <>
      <div className="bg-white">
        <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>
      </div>
    </>
  );
};