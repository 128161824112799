import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Menu } from "primereact/menu";
import { useClaimProcessManagment } from "../hooks/useClaimProcessManagment";

export const MenuCustom = ({processes, idSelect, setIdSelect, setShowDelete, order})=>{

	const history = useHistory();
	const menu = useRef(null)

	const { isDelete, isEdit } = useClaimProcessManagment()

	let items = [
		{
				label: 'Editar',
				command: (e) => {
					
					history.push('/mapa-procesos/editar-proceso/'+idSelect);
			}
		},
	// 	{
	// 		label: 'Aprobación',
	// 		command: (e) => {
				
	// 			history.push('/mapa-procesos/editar-proceso/'+idSelect+'/ShowWf');
	// 	}
	// },
		{
				label: 'Eliminar',
				command: () => {
					setShowDelete(true)
				}
		}
	]

	if (!isEdit)
    items = items.filter(c => c.label !== 'Editar');
  if (!isDelete)
    items = items.filter(c => c.label !== 'Eliminar');

	return(
		<>
			{
				(isEdit || isDelete)&&(
					<i className='pi pi-ellipsis-v' onClick={(event) => {menu?.current?.toggle(event); setIdSelect(processes.id)}} order={order}></i>
				)
			}
			<Menu model={items} popup ref={menu}/>
		</>
	)
} 