import { Dialog } from 'primereact/dialog'
import { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import Message from 'shared/components/messages/Message';
import { useForm, Controller } from "react-hook-form";
const EmployeeModalStudy = ({ showModal, isEditModal, objectEdit, setIsEditModal, closeModal, dataManager, studies, setStudies }) => {
  const { formState: { errors }, handleSubmit, control, clearErrors , resetField , setValue , setError } = useForm();
  const errorsArray = []
  
  const setInfo = () =>{
    setValue("academicInstitutionName",objectEdit.academicInstitutionName)
    setValue("professionName",objectEdit.professionName)
    setValue("professionalDegreeId",objectEdit.professionalDegreeId)
    setValue("beginMonth",objectEdit.beginMonth)
    setValue("beginYear",objectEdit.beginYear)
    setValue("endMonth",objectEdit.endMonth)
    setValue("endYear",objectEdit.endYear)

  }
  useEffect(() => {
    if (isEditModal) {
      setInfo()
    }
  }, [isEditModal])
  const months = [
    {
      month: "Enero",
      value: 1
    },
    {
      month: "Febrero",
      value: 2
    },
    {
      month: "Marzo",
      value: 3
    },
    {
      month: "Abril",
      value: 4
    },
    {
      month: "Mayo",
      value: 5
    },
    {
      month: "Junio",
      value: 6
    },
    {
      month: "Julio",
      value: 7
    },
    {
      month: "Agosto",
      value: 8
    },
    {
      month: "Septiembre",
      value: 9
    },
    {
      month: "Octubre",
      value: 10
    },
    {
      month: "Noviembre",
      value: 11
    },
    {
      month: "Diciembre",
      value: 12
    },
  ]

  const years = [];

  const currentYear = new Date().getFullYear();

  for (let i = 1950; i <= currentYear; i++) {
    years.push(i)
  }

  const header = () => {
    return (
      <div className="header-modal">
        <h5 className="line-separator">Registrar nuevo estudio realizado</h5>
      </div>
    )
  }

  const reset = () =>{
    resetField("academicInstitutionName")
    resetField("professionName")
    resetField("professionalDegreeId")
    resetField("beginMonth")
    resetField("beginYear")
    resetField("endMonth")
    resetField("endYear")
  }
  const setStudyData = (study) => {
    if (isEditModal) {
      let idReference = study.idReference;
      let isValid = validDates(study);
      if(isValid)
        studies[idReference] = study
    } else {
      let isValid = validDates(study);
      if(isValid)
        addStudy(study)
    }
    closeModal()
  }

  const addStudy = (study) =>{
    studies.push({ ...study, idReference: studies.length });
    setStudies(studies)
    setIsEditModal(false)
    reset();
    closeModal()
  }
  const validDates = (study) => {
    if(study.endYear === null  && study.endMonth === null){
      return true
    }else{
      if(study.endYear === null || study.endMonth === null){
        if(study.endYear === null)
          setError("endYear", {
            type: "manual",
            message: "El Año es Obligatorio",
          });
        if(study.endMonth === null)
          setError("endMonth", {
            type: "manual",
            message: "El Mes es obligatorio",
          });
        return false
      }else{
        if(study.endYear < study.beginYear){
          setError("endYear", {
            type: "manual",
            message: "El Año de fin no puede ser menor al Año de inicio!",
          });
          return false

        }else{
          if(study.endYear === study.beginYear){
            if(study.beginMonth > study.endMonth){
              setError("endMonth", {
                type: "manual",
                message: "El Mes de fin no puede ser menos al Mes de inicio!",
              });
              return false
            }else{
              return true;
            }
          }
          return true
        }
      }
    }
  }


  const onSubmit = (data) => {
    Object.entries(data).forEach(([key,value]) =>{
      if(value===undefined)
        data[key] = null
    })
    if(isEditModal)
      data.idReference = objectEdit.idReference
    setStudyData(data)
  }
  return (
    <Dialog header={header} visible={showModal} onHide={() => {
      setIsEditModal(false)
      clearErrors()
      reset()
      closeModal()
    }} closable breakpoints={{ '960px': '85vw', '640px': '100vw' }} style={{ width: '60vw' }}>
      <form onSubmit={handleSubmit(onSubmit)} className="form-custom grid grid-cols-1  md:grid-cols-5 gap-5 pt-4">
        <div className="col-span-3">
          <span className="p-float-label mt-1 mb-6">
            <Controller
              control={control}
              rules={{
                required: "El Centro de Estudios es obligatorio",
              }}
              render={({ field: { onChange, value } }) =>
                <>
                  <InputText
                    id="academicInstitutionName"
                    value={value}
                    className={`w-full ${errors.academicInstitutionName?.type === 'required' ? 'p-invalid' : ''}`}
                    onChange={onChange} />
                  <label htmlFor="centro">Centro de Estudios</label>
                </>
              }
              name="academicInstitutionName"
            />
          </span>

          <Controller
            control={control}
            rules={{
              required: "La Disciplina Académica es obligatoria",
            }}
            render={({ field: { onChange, value } }) =>
              <span className="p-float-label mb-6">
                <InputText
                  id="disciplina"
                  value={value}
                  onChange={onChange}
                  className={`w-full ${errors.professionName?.type === 'required' ? 'p-invalid' : ''}`}
                />
                <label htmlFor="disciplina">Disciplina Académica</label>
              </span>
            }
            name="professionName"
          />
          <Controller
            control={control}
            rules={{
              required: "El Grado es obligatoria",
            }}
            render={({ field: { onChange, value } }) =>
              <Dropdown
                onChange={onChange}
                options={dataManager.instructions}
                value={value}
                optionValue={e => {
                  return e.id
                }}
                optionLabel="description"
                filter
                showClear
                className={`w-full ${errors.professionalDegreeId?.type === 'required' ? 'p-invalid' : ''}`}
                placeholder="Grado"
              />
            }
            name="professionalDegreeId"
          />
        </div>

        <div className="grid grid-cols-2 col-span-2 gap-3">
          <h5 className="col-span-2">Inicio</h5>
          <Controller
            control={control}
            rules={{
              required: "El mes de Inicio es obligatorio",
            }}
            render={({ field: { onChange, value } }) =>
              <Dropdown
                value={value}
                options={months}
                className={`w-full ${errors.beginMonth?.type === 'required' ? 'p-invalid' : ''}`}
                onChange={onChange}
                optionValue="value"
                optionLabel='month'
                placeholder="Mes" />
            }
            name="beginMonth"
          />
          <Controller
            control={control}
            rules={{
              required: "El Año de Inicio es obligatorio",
            }}
            render={({ field: { onChange, value } }) =>
              <Dropdown
                value={value}
                options={years}
                placeholder="Año"
                className={`w-full ${errors.beginYear?.type === 'required' ? 'p-invalid' : ''}`}
                onChange={onChange}
                 />
            }
            name="beginYear"
          />
          <h5 className="col-span-2">Fin</h5>
         
          <Controller
            control={control}
            render={({ field: { onChange, value } }) =>
              <Dropdown
                value={value}
                options={months}
                onChange={onChange}
                placeholder="Mes"
                optionLabel="month"
                optionValue="value" />
            }
            name="endMonth"
          />

          <Controller
            control={control}
            render={({ field: { onChange, value } }) =>
              <Dropdown
                value={value}
                options={years}
                onChange={onChange}
                placeholder="Año" />
            }
            name="endYear"
          />

        </div>
        <div className="col-span-5 w-full">
          {Object.entries(errors).forEach((key, value) => {
            errorsArray.push(key[1].message);
          })}
          {errorsArray.length > 0 && (
            <Message messages={errorsArray} status="error" />
          )}
        </div>
        <div className="col-span-5 flex justify-end gap-2 w-full">
          <Button
            icon="pi pi-times"
            type="button"
            label="Cancelar"
            onClick={() => {
              setIsEditModal(false)
              closeModal()
              reset()
              clearErrors()
            }}
            // disabled={status === ''
            className="sig-button  sig-secondary mt-4"
          />
          <Button
            icon="pi pi-save"
            type="submit"
            label="Guardar"
            // loading={status === ''}
            className="sig-button sig-primary mt-4"
          />
        </div>

      </form>

    </Dialog>
  )
}



export default EmployeeModalStudy