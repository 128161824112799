

import React, {Fragment, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import { SelectButton } from 'primereact/selectbutton';
import { useSelector, useDispatch } from 'react-redux';
import { CoreConstants } from 'core/commons/CoreConstants';
import { Skeleton } from 'primereact/skeleton';
import './TableComponent.scss'
import { DocumentManagementAction } from 'features/document/store/actions/DocumentManagementAction';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useClaimDocumentManagment } from 'features/document/hooks/useClaimDocumentManagment';

const TableComponent = ({selectedNode, documentSelected, setDocumentSelected, setDisplayModalAction, setActionSelected, setSelectedNode, setDisplayModalPermissions}) => {
    const dispatch = useDispatch();
    const { isDeleted, isUpdated, isSaved  } = useSelector((state) => state.documentManagementReducer.folder);
    const { isLoading , data } = useSelector(state => state.documentManagementReducer.listFolders)
    const { isEnabled } = useSelector((state) => state.documentManagementReducer.isStorageEnabled);
    const width = window.innerWidth
    const {
        isDelete,
        isRename,
        isSetConfig
     } = useClaimDocumentManagment()

    const getCreationDate = (rowData) =>  {
        if(rowData.latestChangeDate !== null) {
            return  moment(rowData.latestChangeDate).format(CoreConstants.Format.LOCALE); 
        }else {
            return "No precisado";
        }
    }
    const typeViews = [
        { icon: 'pi pi-list', value: CoreConstants.TypeView.LIST },
        { icon: 'pi pi-microsoft', value: CoreConstants.TypeView.CANVA }
    ];
    const columns = [
        { field: 'name', header: 'Nombre' },
        { field: 'latestChangeDate', header: 'Ultima Modificación', body: getCreationDate }
    ];
    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);
    const actionsHeader = useRef(null);
    const menu = useRef(null);
    // const dragItem = useRef();
    // const dragOverItem = useRef();
    const [showConfirmDialog, setshowConfirmDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isFirstLoading, setFirstLoading] = useState(true); 


    // const newData = data ? data.map((folder, i) => {
    //     folder.id = uuidv4()
    //     return folder
    // }) : []

    // console.log(data)
    // console.log(selectedRow)

    useEffect(() => {
        if (isFirstLoading) {
            tryParseFilter();
            setFirstLoading(false);
        }
        // dispatch(DocumentLibraryAction.getDataListFolders(selectedNode));
        dispatch(DocumentManagementAction.getListFoldersTable(selectedNode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode]);

    useEffect(() => {
        if (isSaved){
            dispatch(DocumentManagementAction.resetAfterSaved());
            dispatch(DocumentManagementAction.getListFoldersTable(selectedNode));
            dispatch(DocumentManagementAction.getDataListFolders());
        }
      }, [isSaved])

    useEffect(() => {
        if (isDeleted) {
            setshowConfirmDialog(false);
            dispatch(DocumentManagementAction.resetAfterSaved());
            dispatch(DocumentManagementAction.getListFoldersTable(selectedNode));
            dispatch(DocumentManagementAction.getDataListFolders());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeleted])

    useEffect(() => {
        if (isUpdated) {
            dispatch(DocumentManagementAction.resetAfterSaved());
            dispatch(DocumentManagementAction.getListFoldersTable(selectedNode));
            dispatch(DocumentManagementAction.getDataListFolders());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated])

    useEffect(() => {
        if (selectedRow) {
            setSelectedRow(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRow])

    const titleFolders = (pathFolder) => {
        if (!pathFolder) return
        const pathSplit = pathFolder.split('/')
        // pathSplit.pop()
        const folderName = pathSplit[pathSplit.length-2]
        return folderName
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='flex items-center gap-x-4'>
                    <div className="icon-carpeta-table"></div>
                    <span className="flex-1 truncate w-full">{titleFolders(rowData.name)}</span>
                </div>
            </React.Fragment>
        );
    }

    let items = [
        {
            label: 'Configurar Permisos',
            icon: 'pi pi-pencil',
            command: (e) => {
                setDisplayModalPermissions(true)
            }
        },
        {
            label: 'Eliminar',
            icon: 'pi pi-times',
            command: (e) => {
                setshowConfirmDialog(true);
            }
        },
        {
            label: 'Renombrar',
            icon: 'pi pi-clone',
            command: (e) => {
                setActionSelected('rename')
                setDisplayModalAction(true)
            }
        },
    ];

    if (!isRename)
        items = items.filter(c => c.label !== 'Renombrar');
    if (!isDelete)
        items = items.filter(c => c.label !== 'Eliminar');
    if (!isSetConfig)
        items = items.filter(c => c.label !== 'Configurar Permisos');


    const columnComponents = selectedColumns.map(col => {
        if (col.field === 'latestChangeDate')
            return <Column key={col.field} field={col.field} header={col.header} body={getCreationDate} style={{minWidth: '180px'}}sortable />;
        
        if (col.field === 'name')
            // return <Column key={col.field} field={col.field} header={col.header} style={{width: '40%'}} body={nameBodyTemplate} sortable />;
            return <Column key={col.field} field={col.field} header={col.header} style={{minWidth: '300px', width: '40%'}} body={nameBodyTemplate} sortable />;

        if (col.body)
            return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable />;

        return <Column key={col.field} field={col.field} header={col.header} sortable />;
    });

    const actionHeaderTemplate = (rowData) => {
        return <i
            key="action"
            className="pi pi-ellipsis-v cursor-pointer"
            onClick={(e) => actionsHeader.current.toggle(e)}></i>;
    }

    // Si queremos crearle un menu diferente para las carpetas crear un menu personalizado para 'F'
    const actionBodyTemplate = (rowData) => {
        if(isDelete || isRename || isSetConfig){
            return (
                <i
                    key={rowData.id}
                    className="pi pi-ellipsis-v cursor-pointer z-40"
                    onClick={(event) => {
                        if (!isEnabled) return
                        setDocumentSelected(rowData)
                        menu.current.toggle(event)
                        }}
                ></i>
            )
        }else{
            return <div></div>
        }
    }

    const tryParseFilter = () => {
        try {
            const filters = JSON.parse(localStorage.getItem("document-filter"));
            if (filters)
                setSelectedColumns(filters);
            else
                setSelectedColumns(columns);
        } catch (error) {
            localStorage.removeItem('document-filter');
        }
    }

    const onColumnToggle = (e, item) => {
        let _selectedColumns = [...selectedColumns];

        if (e.checked)
            _selectedColumns.push(item);
        else
            _selectedColumns = _selectedColumns.filter(c => { return c.field !== e.value });

        setSelectedColumns(_selectedColumns);
        localStorage.setItem("document-filter", JSON.stringify(_selectedColumns));
        ;
    }

    const getColumns = () => {
        let array = [];
        columns.map((item) => {
            const column = <div key={item.field} className="p-field-checkbox py-1">
                <Checkbox
                    inputId={item.field}
                    name="column"
                    value={item.field}
                    onChange={(e) => onColumnToggle(e, item)}
                    checked={selectedColumns.some((x) => x.field === item.field)}
                />
                <label className="ml-1" htmlFor={item.field}>{item.header}</label>
            </div>
            return array.push(column);
        });
        
        return array.slice(1,3);
    };

    const onRowSelect = (data) => {
        if (!isEnabled) return
        if (data.field === 'name' || data.field === 'latestChangeDate') {
            const name = data.rowData.name.split('/')
            name.pop()
            const path = name.join('/')
            
            setSelectedNode(path)
        }
    }

    const onCanvaSelect = (data) => {
        if (!isEnabled) return
        const name = data.name.split('/')
        name.pop()
        const path = name.join('/')
        setSelectedNode(path)
    }

    const confirmDelete = () => {
        // const name = documentSelected.name.split('/')
        // name.pop()
        // const path = name.join('/')
        // dispatch(DocumentManagementAction.deleteFolder(path));
        dispatch(DocumentManagementAction.deleteFolder(documentSelected.name));
    }

    const cancelDelete = () => {
        setshowConfirmDialog(false);
    }

    const tituloDirectorio = (path) => {
        const pathSplit = path.split('/')
        const pathsList = pathSplit.map( (item, index) => {
            if (index > 0) {
                const pathSlice = pathSplit.slice(0, index + 1)
                const pathJoin = pathSlice.join('/')
                const newPath = {
                    label: `${item}`,
                    command: () => setSelectedNode(pathJoin)
                }
                return newPath
            }

            const newPath = {
                label: item,
                command: () => setSelectedNode(item)
            }
            return newPath
        })

        // const lastItemPath = [pathsList[pathsList.length - 1]]
        const extraPath = { label: "..." }
        // return lastItemPath
        // return pathsList
        const lastItemsPaths = [extraPath, pathsList[pathsList.length - 2], pathsList[pathsList.length - 1]]

        return (width < 850 && pathsList.length > 2) ? lastItemsPaths : pathsList
    }


    const home = { icon: 'pi pi-home', command: () => setSelectedNode('') }

    return (
        <Fragment>
            <div className="flex items-center justify-between px-2 mb-2">
                <BreadCrumb model={tituloDirectorio(selectedNode)} className='bread-crumb text-sm' home={home} />
                <SelectButton
                    unselectable={false}
                    value={typeView}
                    options={typeViews}
                    onChange={(e) => setTypeView(e.value)}
                    itemTemplate={(option) => { return <i className={option.icon}></i>; }}
                />
            </div>
            
            {typeView === CoreConstants.TypeView.CANVA && 
                <div 
                    className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2  gap-2"
                >
                    {
                        isLoading ? (<Skeleton className='mt-3' height="10.2rem" /> ) : 
                        (
                            data.map((item, index) => (
                                <div 
                                    key={"card" + index}
                                    data-pr-tooltip={item.name}
                                    className="flex flex-col max-w-sm gap-y-2
                                        rounded-lg border border-gray-200 hover:bg-gray-100 shadow-sm
                                        dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 document-card"
                                >
                                    <div className='flex justify-center items-center w-full h-32 cursor-pointer' onClick={() => onCanvaSelect(item)}>
                                        {
                                            item.mimeType === 'application/pdf' || item.mimeType === 'image/jpeg'|| item.mimeType === 'image/jpg' || item.mimeType === 'image/png'   || item.mimeType === 'text/plain'
                                            ?  
                                            (
                                                <object data={item.url} type={item.mimeType} width="100%" height="100%" className='object-cover'>
                                                </object>
                                            )
                                            : (
                                                <div className="icon-carpeta"></div>
                                            )
                                        }
                                    </div>
                                    <Tooltip target=".document-card" position='top'/>
                                    <div className="flex justify-between items-center  px-3 pb-2" >
                                        <h5 key={"title" + item.id} className="mb-0 text-md font-medium truncate    text-gray-900 dark:text-white">{titleFolders(item.name)}</h5>
                                        {
                                            (isDelete || isRename || isSetConfig)&&(
                                            <i
                                                key={"options" + item.id}
                                                className="pi pi-ellipsis-v cursor-pointer"
                                                onClick={(event) => {
                                                    if (!isEnabled) return 
                                                    setDocumentSelected(item);
                                                    menu.current.toggle(event)
                                                }}
                                            ></i>)
                                        }
                                    </div>
                                </div>
                            ))
                        )
                    }
                </div>
            }
            
            {typeView === CoreConstants.TypeView.LIST &&
                <div className="mt-3" style={{ maxWidth: (window.innerWidth - 100) + 'px' }}>          
                    {
                        isLoading ?(
                            <Skeleton height="14.2rem" />
                          ) : (
                            <DataTable
                                className='tableDocument'
                                value={data}
                                responsiveLayout="scroll"
                                size="small"
                                emptyMessage="No se encontraron carpetas"
                                selectionMode="single"
                                cellSelection 
                                selection={selectedRow} onSelectionChange={e => setSelectedRow(e.value)}
                                onCellSelect={onRowSelect}
                                dataKey="id"
                                // sortField={sortField} sortOrder={sortOrder} onSort={(e) => onSort(e)}
                                >
                                {columnComponents}
                                <Column header={actionHeaderTemplate} body={actionBodyTemplate} ></Column>
                            </DataTable>
                          )
                    }          
                </div>
            }

            <OverlayPanel ref={actionsHeader} showCloseIcon style={{ width: '300px' }}>{getColumns()}</OverlayPanel>

            <Menu model={items} popup ref={menu} id="popup_menu" />
            <ConfirmDialog
                visible={showConfirmDialog}
                onHide={cancelDelete}
                message="¿Está seguro de que desea eliminar la carpeta??"
                header="Eliminar"
                icon="pi pi-trash"
                accept={confirmDelete}
                reject={cancelDelete}
            />
        </Fragment >
    );
};

export default TableComponent;
