import { apiService } from "core/services/ApiService";

//* Implementados
const getConfigDynamicProcessByIdentifier = (identifier) => {
  return apiService.Get("management/api/RenderDynamicProcess/GetConfigDynamicProcessByIdentifier?identifier=" + identifier);
}

const getDynamicProcessById = (id) => {
  return apiService.Get("management/api/RenderDynamicProcess/GetDynamicProcessById?id=" + id);
}

const saveProcessData = (data) => {
  return apiService.Post("management/api/RenderDynamicProcess/SaveProcessData", data);
}

const searchDynamicProcess = (criteria) => {
  return apiService.Post("management/api/RenderDynamicProcess/SearchDynamicProcess", criteria);
}

const nextStep = (processId) => {
  return apiService.Post("management/api/RenderDynamicProcess/NextStep", processId);
}

const backStep = (processId) => {
  return apiService.Post("management/api/RenderDynamicProcess/BackStep", processId);
}

const getDataMaster = () => {
  return apiService.Post("management/api/ConfigDynamicProcess/GetDataMaster");
}

//* No Implementados

const searchProcesses = (criteria) => {
  return apiService.Post("management/api/ConfigDynamicProcess/Search", criteria);
}

const searchProcessesView = (criteria) => {
  return apiService.Post("management/api/ConfigDynamicProcess/Search", criteria);
}

const searchListControls = () => {
  return apiService.Get("management/api/ConfigDynamicProcess/ListControls");
}

const saveProcesses = (data) => {
  return apiService.Post("management/api/ConfigDynamicProcess/SaveProcess", data);
}

const getProcessById = (processesId) => {
  return apiService.Get("management/api/ConfigDynamicProcess/GetDynamicProcessById?id=" + processesId);
}

const generateReport = (payload) => {
  return apiService.Post("customer/api/JobPosition/GenerateReport", payload, {
    responseType: 'blob',
  })
}

const deleteProcess = (processId) => {
  return apiService.Delete("management/api/ConfigDynamicProcess/RemoveProcess?id=" + processId);
}

const deleteProcessData = (processId) => {
  return apiService.Delete("management/api/RenderDynamicProcess/Delete?id=" + processId);
}

export const DinamicProcessView = {
  searchProcesses,
  searchProcessesView,
  saveProcesses,
  getProcessById,
  generateReport,
  deleteProcess,
  searchListControls,
  


  //* Implementados
  getConfigDynamicProcessByIdentifier,
  getDynamicProcessById,
  saveProcessData,
  searchDynamicProcess,
  nextStep,
  backStep,
  getDataMaster,
  deleteProcessData,
};