export const getDynamicFields = (dynamicFields, sectionNameConstant) => {
  const response = []

  dynamicFields.forEach(({sectionName, label, isRequired, id, fieldTypeCode, identifier, maxLength}) => {
    if(sectionName.toUpperCase() === sectionNameConstant){
      response.push({sectionName, label, isRequired, id, fieldTypeCode, identifier, maxLength})
    }
  });

  return response
}


export const getDynamicFieldsList = (data, dynamicFields, isNewDocument, currentDocument) => {

  const dynamicDataValues = []

  for( const [key, value] of Object.entries(data)){
    if(key.startsWith("dynamicFieldData")){
      const identifier = key.split(":")[1]
      const dynamicField = dynamicFields.find( ( field ) => field.identifier === identifier)
      let id = null
      if(!isNewDocument){
        id = currentDocument?.dynamicFieldValues?.find( field => field.identifier === identifier)?.id
      }
      dynamicDataValues.push(
        {
          id,
          entityConfigurationItemId: dynamicField.id,
          value: value || null,
          identifier,
        }
      )
      delete data[key]
    }
  }

  return dynamicDataValues
}