import React, { useRef } from 'react';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import PinerComponent from 'shared/components/Piner/PinerComponent';
import { RiskModelAction } from 'features/riskManagement/store/actions/RiskModelAction';
import { useClaimModels } from 'features/riskManagement/hooks/useClaimModels';

const FilterComponent = () => {
  const dispatch = useDispatch()
  
  const { isLoading } = useSelector( state => state.riskModelReducer.models)

  const advancedFilter = useSelector((state) => state.riskModelReducer.advancedFilter);

  const values = useSelector((state) => state.riskModelReducer.advancedFilter.values);

  const openModal = () => {
    dispatch(RiskModelAction.toggleAdvancedFilter({ showModal: true }));
};

const { isSearch, isDownload } = useClaimModels();

  const criteriaSearch = useRef()

  const generateReport = () => {
    const value = criteriaSearch.current.value;
    if (value !== null && value !== undefined && value.trim().length > 0) {
        advancedFilter.filter.name = value;
        dispatch(RiskModelAction.updateFilter(advancedFilter));
    }
    dispatch(RiskModelAction.generateReport());
};

  const search = (e) => {
    e.preventDefault()
    const value = e.target.inputSearch.value 
    if(value !== null && value !== undefined && value.trim().length > 0){
      advancedFilter.filter.name = value;
      dispatch(RiskModelAction.updateFilter(advancedFilter));
    }
    dispatch(RiskModelAction.search());
  }

  const showPiners = () => {
    return values?.length > 0;
  }

  const removePiner = (field) => {
    switch (field) {
      case 'name':
        criteriaSearch.current.value = '';
        advancedFilter.filter.name = null;
        break;

      case 'from':
        advancedFilter.filter.from = null;
        break;
      
      case 'to':
        advancedFilter.filter.to = null;
        break;
    
      default:
        break;
    }

    dispatch(RiskModelAction.updateFilter(advancedFilter));
    dispatch(RiskModelAction.search());
  }

	return (
    <Panel header="FILTRO POR" toggleable>
      <form className="filter-components" onSubmit={search}>
        <div className={`${isSearch?'visible':'invisible'}`}>
            <div className="w-full ">
                <span className="p-float-label p-input-icon-left w-full ">
                    <i className="pi pi-search" />
                    <InputText
                        id="inputSearch"
                        name="inputSearch"
                        ref={criteriaSearch}
                        // onKeyDown={handleKeyDown}
                    />
                    <label htmlFor="inputSearch">Buscar por modelo de gestión</label>
                </span>
                {showPiners() && (
                    <div className="filter-piners mt-3">
                        {values.map(({ value, field }, index) => (
                            <PinerComponent
                                name={value}
                                field={field}
                                removePiner={removePiner}
                                key={index}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
        <div className="filter-users-action">
            <div className="flex gap-3 w-full">
                {
                  isSearch&&
                      <Button
                        loading={isLoading}
                        icon="pi pi-search"
                        type="submit"
                        label="Buscar"
                        className="sig-button sig-primary w-full"
                      />
                }
                {
                  isDownload&&
                      <Button
                        loading={isLoading}
                        icon="pi pi-download"
                        type="button"
                        label="Descargar"
                        className="sig-button sig-success w-full"
                        onClick={generateReport}
                      />
                }
            </div>
            {
              isSearch&&
                <div className="flex mt-3">
                    <Button
                        loading={isLoading}
                        type="button"
                        label="Búsqueda avanzada"
                        className="sig-button sig-secondary w-full"
                        onClick={openModal}
                    />
                </div>
            }
        </div>
      </form>
    </Panel>
	);
};

export default FilterComponent;