
import { ProjectsConstants } from 'features/projects/commons/ProjectsConstants';
import { TableListPayments } from 'features/projects/components/paymentProjects/TableListPayments';
import usePaymentProject from 'features/projects/hooks/PaymentProjects/usePaymentProject';
import { useClaimPaymentProject } from 'features/projects/hooks/useClaimPaymentProject';
import { PaymentProjectAction } from 'features/projects/store/actions/PaymentProjectAction';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { DynamicFieldList } from 'shared/components/dynamicFields/DynamicFieldList';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { getDynamicFields, getDynamicFieldsList } from 'shared/utils/getDynamicFields';

export const PaymentProjectPage = ({ description }) => {

  const { updateTitle } = useSetTitlePage();
  const {control,
    errors,
    valuesFields,
    existError,
    setValue,
    handleSubmit,
    validateLength,
} = usePaymentProject()
// console.log(valuesFields)

  const [visible, setVisible] = useState(false)
  const [mnsjSendValidate, setMnsjSendValidate] = useState([])
  const [dataPayment, setDataPayment] = useState([])

  const history = useHistory()
  const params = useParams()
  const isNewPayment = params?.id ? false : true
  const dispatch = useDispatch()

  const { isCreate, isEdit } = useClaimPaymentProject();

  const dataProjectsAndPayment = useSelector((state) => state.paymentProjectReducer.projectsAndPaymentMethod.data);
  const paymentSelected = useSelector((state) => state.paymentProjectReducer.studentSelected);

  // console.log(dataProjectsAndPayment)
  // console.log(paymentSelected)

  const dynamicFields = dataProjectsAndPayment?.dynamicFields || []
  const dynamicFieldsDatosProyecto = getDynamicFields(dynamicFields, ProjectsConstants.SectionsPaymentProjectsName.DATOS_DEL_PROYECTO)
  const dynamicFieldsDatosPago = getDynamicFields(dynamicFields, ProjectsConstants.SectionsPaymentProjectsName.DATOS_DEL_PAGO)

  useEffect(()=> {
    if(paymentSelected.isSaved){
      redirectToSearch()
    }
    if(paymentSelected.data && dataPayment.length===0 && params?.id && dataProjectsAndPayment){
      chargeData()
    }
  },[paymentSelected,dataProjectsAndPayment])

  useEffect(()=>{
    updateTitle({
      title: 'Proyectos',
      subtitle: 'Pagos de Proyectos',
      description: description,
      previousUrl: '/proyecto/pagos'
    });
    if(params?.id){
      dispatch(PaymentProjectAction.getPaymentById(params.id))
    }
    if(!dataProjectsAndPayment){
      dispatch(PaymentProjectAction.getCoursesAndPayment())
    }
  },[])

  const chargeData = () => {
    //Llenando pago d eproyectos

    const currentProject = dataProjectsAndPayment.payments.find(el => el.id===Number(params?.id))
    setValue('typeProject', currentProject)
    // setValue('nameCustomer', currentProject?.name)
    setValue('nameCustomer', currentProject?.customerName)

    if(paymentSelected.data.dynamicData?.length > 0){
      paymentSelected.data.dynamicData.forEach( dynamicFieldValue => {
        setValue(`dynamicFieldData:${dynamicFieldValue.identifier}`, dynamicFieldValue.value)
      })
    }

    if(paymentSelected.data?.result?.length>0){
      setDataPayment(paymentSelected.data?.result?.map(elem=> {
        return {
            id: elem.id,
            projectId: elem.projectId,
            issueDate: elem.issueDate,
            dueDate: elem.dueDate,
            paymentDate: elem.paymentDate,
            paymentModeId: elem.paymentModeId,
            amount: elem.amount.toFixed(2),
            mode: "edit"
        }
      }))
    }
  }


  const onSubmit = (data) => {
    if(dataPayment.length===0){
      setMnsjSendValidate([{id: 'notFound', message: 'Debe existir un pago como mínimo.'}])
    }else{
      let errores = []
      dataPayment.map((el,index)=> {
        if(!el.issueDate){
          errores.push({id: el.id, row: "issueDate", message: `La Fecha de Emisión es requerida en la fila ${index+1}.`})
        }
        if(!el.dueDate){
          errores.push({id: el.id, row: "dueDate", message: `La Fecha de Vencimiento es requerida en la fila ${index+1}.`})
        }
        if(!el.amount){
          errores.push({id: el.id, row: "amount", message: `El Monto es requerido en la fila ${index+1}.`})
        }
        return el
      })
      setMnsjSendValidate(errores)
      if(errores.length>0){
        return
      }else{
        const currentPayment = {
          dynamicFieldValues: paymentSelected?.data?.dynamicData || []
        }
        const dynamicDataValues = getDynamicFieldsList(data, dynamicFields, isNewPayment, currentPayment)

        const dataSend = {
          projectId: data.typeProject.id,
          payments: dataPayment.map(el=> {
            return {
              id: el.mode==="edit"?el.id:null,
              projectId: data.typeProject.id,
              issueDate: el.issueDate,
              dueDate: el.dueDate,
              paymentDate: el.paymentDate ? el.paymentDate : null,
              paymentModeId: el.paymentModeId ? el.paymentModeId : null,
              amount: Number(el.amount)
            }
          }),
          dynamicDataValues
        }

        // console.log(dataSend)

        dispatch(PaymentProjectAction.savePaymentProject(dataSend))
      }
    }
  }

  const reject = () => {
    history.push("/proyecto/pagos")
  }

  const  redirectToSearch = () => {
    history.push('/proyecto/pagos')
  }

  const autocompleteTypeProject = (e) => {
    setValue('nameCustomer', e.value ? e.value.customerName : "")
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
      <Panel header="DATOS DEL PROYECTO">
        <div className='flex'>
          <span className="p-float-label w-full sm:w-1/3 mr-4">
                  <p className='mb-2'>Proyecto *</p>
                  <Controller
                    control={control}
                    render={({
                        field: { onChange, value },
                    }) => (
                      <Dropdown
                        id="typeProject"
                        name="typeProject"
                        optionLabel="name"
                        value={value}
                        options={dataProjectsAndPayment?.payments ? dataProjectsAndPayment?.payments : null}
                        onChange={(e) =>{
                          if(dataProjectsAndPayment?.payments){
                            onChange(e)
                            autocompleteTypeProject(e)
                          }
                        }}
                        disabled={params?.id}
                        filter
                        filterBy="name"
                        placeholder="Seleccione Proyecto"
                        className={`${errors?.typeProject&&'p-invalid'} ${params?.id&&'disabled'} 'w-full'`}
                        // optionValue={(e) => {
                        //   return e.id;
                        //     }}
                          />
                    )}
                    name="typeProject"
                    rules={{
                        required: 'El proyecto es requerido.',
                    }} />
            </span>
            <span className="p-float-label w-full sm:w-1/3">
                  <p className='mb-2'>Cliente</p>
                  <Controller
                      control={control}
                      render={({
                          field: { onChange, value },
                      }) => (
                        <InputText
                          id="nameCustomer"
                          name="nameCustomer"
                          value={value}
                          onChange={onChange}
                          disabled
                          className={errors?.nameCustomer ? 'p-invalid w-full disabled' : 'w-full disabled'}
                            />
                      )}
                      name="nameCustomer"
                      // rules={{
                      //     required: 'El curso es requerido',
                      // }}
                  />
            </span>
        </div>
        <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-6">
            {
                (!paymentSelected.isLoading) &&
                <DynamicFieldList control={control} fields={dynamicFieldsDatosProyecto} />
            }
        </div>   
      </Panel>
      <Panel header="DATOS DEL PAGO">
          <TableListPayments
            mnsjSendValidate={mnsjSendValidate}
            setMnsjSendValidate={setMnsjSendValidate}
            dataPayment={dataPayment}
            setDataPayment={setDataPayment}/>
        <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-6">
            {
              (!paymentSelected.isLoading) &&
                <DynamicFieldList control={control} fields={dynamicFieldsDatosPago} />
            }
        </div>  
          {
            // (existError) && <div className='message error'>
            (errors.typeProject || mnsjSendValidate.length!==0 ) && <div className='message error'>
                {
                  mnsjSendValidate.length!==0&&<ul>
                    {
                      mnsjSendValidate.map((el,index)=> {
                        return <li key={index} className="p-error">{el.message}</li>
                      })
                    }
                  </ul>
                }
                <ul>
                    {errors.typeProject ? (
                        <>
                            <li className="p-error">{errors.typeProject.message}</li>
                        </>
                    ) : null}
                </ul>
            </div>
          }
        <div className="flex justify-end gap-4 mt-3">
                      <Button
                          icon="pi pi-times"
                          type="button"
                          label="Cancelar"
                          loading={paymentSelected?.isSaving}
                          onClick={() => { setVisible(true); }}
                          className="sig-button sig-secondary mt-4"
                      />
                      {
                        (description!=='Ver Pago')&&(<>
                          {
                            (isCreate || isEdit)&&
                              <Button
                                  icon="pi pi-save"
                                  label="Guardar"
                                  type='submit'
                                  loading={paymentSelected?.isSaving}
                                  // loading={academicDegree?.isSaving || academicDegree?.isLoading}
                                  className="sig-button sig-primary mt-4"
                              />
                          }
                        </>
                        )
                       }
                  </div>
        <ConfirmDialog
                  visible={visible}
                  onHide={() => setVisible(false)}
                  message="¿Está seguro que desea cancelar?"
                  header="Cancelar"
                  icon="pi pi-exclamation-triangle"
                  accept={redirectToSearch}
                  reject={reject}
              />
      </Panel>
      </form>
    </>
  )
}
