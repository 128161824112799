/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TaskAction } from 'features/customers/store/actions/TaskAction';
import { getAddedFilesBase64 } from 'shared/utils/getAddedFilesBase64';
import { getBase64 } from 'shared/utils/getBase64';
import { TasksConstants } from 'features/customers/commons/TasksConstants';
import { getDynamicFields } from 'shared/utils/getDynamicFields';
import { CoreConstants } from 'core/commons/CoreConstants';

export const useTask = (props) => {
  const {sourceDocumentId, taskId, returnUrl, setCloseModal, projectId, sourceDocumentCode, searchHomePage } = props;
  
  const dispatch = useDispatch();
  const history = useHistory();

  const isNewTask = taskId ? false : true;

  const [showModalCancel, setShowModalCancel] = useState(false);

  const [showModalComplete, setShowModalComplete] = useState(false);

  const [showModalOpen, setShowModalOpen] = useState(false);

  const [sourceCode, setSourceCode] = useState('')

  const [sourceName, setSourceName] = useState(null)

  let defaultValues = {
    Title: null,
    ResponsibleId: null,
    Description: null,
    ScheduleDate: null,
    EndDate: null,
    ScheduleHour: null,
    LoggedHours: null,
    TypeId: null,
    PriorityId: null,
    ReminderId: null,
    Progress: 0,
    IsCompleted: false,

    savedFiles: [],
    addedFiles: [],
  };

  const editTask = useSelector(
    (state) => state.tasksReducer?.editTask
  );

  const isLoading = editTask?.isLoading
  const isLoadingTask = editTask?.isLoadingTask
  const attachments = editTask?.attachments
  const currentTask = editTask?.data

  const isLoadingDataMaster = useSelector(
    (state) => state.tasksReducer?.dataMaster?.isLoading
  );

  const dataMaster = useSelector(
    (state) => state.tasksReducer?.dataMaster?.data
  );

  const dynamicFields = dataMaster?.dynamicFields || []

  const dynamicFieldsInformacionTarea = getDynamicFields(dynamicFields, TasksConstants.SectionsName.INFORMACION_DE_LA_TAREA)
  const dynamicFieldsDatosGenerales = getDynamicFields(dynamicFields, TasksConstants.SectionsName.DATOS_GENERALES)

  // console.log(dataMaster)
  // console.log(currentTask)

  const {
    control,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues, shouldUnregister: true });  
  
  const valuesFields = watch();
  // console.log(valuesFields)

  useEffect(() => {
    if (taskId && !searchHomePage) {
      dispatch(TaskAction.editTask(taskId));
    }
  }, [taskId]);

  useEffect(() => {
    const finishLoading = !isLoadingDataMaster && !isLoadingTask;
    const hasValues = Object.values(currentTask).length > 0;
    if (!isNewTask && finishLoading && hasValues && taskId) {
      const sourceCode = dataMaster.taskSources?.find( item => item.id === Number(currentTask.sourceId))?.code
      setSourceCode(sourceCode)
      if(sourceCode === CoreConstants.Task.Source.CUSTOMER) setSourceName("Empresa")
      if(sourceCode === CoreConstants.Task.Source.BUSINESS) setSourceName("Negocio")
      if(sourceCode === CoreConstants.Task.Source.PROJECT) setSourceName("Proyecto")

      setValue("ResponsibleId", dataMaster.responsibles.find( ({id}) => id === currentTask.responsibleId))
      setValue("TypeId", dataMaster.taskTypes.find( ({id}) => id === currentTask.typeId))
      setValue("PriorityId", dataMaster.taskPriorities.find( ({id}) => id === currentTask.priorityId))
      setValue("ReminderId", dataMaster.taskReminders.find( ({id}) => id === currentTask.reminderId))
      
      setValue("Title", currentTask.title)
      setValue("Description", currentTask.description)
      setValue("ScheduleDate", currentTask.scheduleDate)
      setValue("EndDate", currentTask.endDate)
      if(currentTask.scheduleHour) {
        const [hour, minute] = currentTask.scheduleHour.split(":")
        const ms = new Date().setHours(hour,minute);
        const date = new Date(ms)
        setValue("ScheduleHour", date)
      }
      setValue("LoggedHours", currentTask.loggedHours)
      setValue("Progress", currentTask.progress || 0)
      setValue("IsCompleted", currentTask.isCompleted)
      setValue("savedFiles", attachments)
      setValue("addedFiles", [])
      if(currentTask.dynamicFieldValues?.length > 0){
        currentTask.dynamicFieldValues.forEach( dynamicFieldValue => {
          setValue(`dynamicFieldData:${dynamicFieldValue.identifier}`, dynamicFieldValue.value)
        })
      }
    }
  }, [isLoadingDataMaster, isLoadingTask]);

  useEffect(() => {
    if(!searchHomePage){
      dispatch(TaskAction.getDataMaster());
    }
  }, []);

  // TODO: Agregar el dynamicFieldData
  const onSubmit = async(data) => {
    const sourceCode = currentTask.sourceId
    const addedFiles = await getAddedFilesBase64(data.addedFiles)

    const dynamicDataValues = []

    for( const [key, value] of Object.entries(data)){
      if(key.startsWith("dynamicFieldData")){
        const identifier = key.split(":")[1]
        const dynamicField = dynamicFields.find( ( field ) => field.identifier === identifier)
        let id = null
        if(!isNewTask){
          id = currentTask.dynamicFieldValues.find( field => field.identifier === identifier)?.id
        }
        dynamicDataValues.push(
          {
            id,
            entityConfigurationItemId: dynamicField.id,
            value: value || null,
            identifier,
          }
        )
        delete data[key]
      }
    }

    const taskRequest = {
      id: isNewTask ? null : Number(taskId),
      sourceCode: sourceDocumentCode ? sourceDocumentCode : dataMaster.taskSources?.find( item => item.id === Number(sourceCode)).code,
      documentId: Number(sourceDocumentId),
      title: data.Title,
      responsibleId: data.ResponsibleId?.id,
      description: data.Description,
      scheduleDate: data.ScheduleDate,
      scheduleHour: data.ScheduleHour ?`${data.ScheduleHour.getHours()}:${data.ScheduleHour.getMinutes()}` : null,
      loggedHours: data.LoggedHours || 0,
      typeId: data.TypeId?.id,
      priorityId: data.PriorityId?.id,
      reminderId: data.ReminderId?.id,
      savedFiles: data.savedFiles?.map(({ id }) => id) ?? [],
      endDate: data.EndDate,
      addedFiles: addedFiles,
      progress: data.Progress,
      dynamicDataValues,
    }
    dispatch(TaskAction.saveCustomerTask({ task: taskRequest, isNewTask, projectId }));

    setTimeout(() => {
      if(returnUrl){
        history.push(returnUrl);
      }
      else{
        setCloseModal(false);
      }
    }, 2000);
  };

  const cancelAccept = () => {
    if(returnUrl){
      history.push(returnUrl);
    }
    else{
      setCloseModal(false);
    }
  };

  const cancelDenied = () => {
    setShowModalCancel(false);
  };

  const confirmComplete = () => {
    dispatch(TaskAction.completeTask(taskId));
  };

  const cancelComplete = () => {
    dispatch(TaskAction.currentAction({ isOpen: false }));
  };

  const confirmOpen = () => {
    dispatch(TaskAction.openTask(taskId));
  };

  const cancelOpen = () => {
    setShowModalOpen(false);
  };

  return {
    dataMaster,
    handleSubmit,
    onSubmit,
    isLoading,
    isLoadingTask, 
    currentTask,
    isLoadingDataMaster,
    errors,
    cancelAccept,
    cancelDenied,
    setShowModalCancel,
    confirmComplete,
    cancelComplete,
    control,
    valuesFields,
    isNewTask,
    showModalCancel,
    sourceDocumentCode: sourceDocumentCode ? sourceDocumentCode : sourceCode,
    showModalComplete,
    setShowModalComplete,
    showModalOpen, 
    setShowModalOpen,
    confirmOpen,
    cancelOpen,
    setValue,
    dynamicFieldsInformacionTarea,
    dynamicFieldsDatosGenerales,
    searchHomePage,
    sourceName,
    taskId,
    sourceDocumentId,
    editTask,
  };  
}
