import { useForm } from 'react-hook-form';

const useModalNodes = () => {

  let defaultValues = {
    parentCompanyArea: '',
    companyArea: '',
  };

  const {
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const valuesFields = watch();
  const existError = Object.keys(errors).length > 0

  return {
    control,
    errors,
    valuesFields,
    existError,
    setValue,
    handleSubmit,
    reset
  }
}

export default useModalNodes