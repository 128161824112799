import { Route, Switch } from "react-router-dom";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import tasksReducer from "features/customers/store/reducers/TasksReducer";
import businessReducer from "./store/reducers/BusinessReducer";
import projectReducer from "./store/reducers/ProjectReducer";
import RequireAuth from "../../core/routes/RequiredAuth";
import { ContainerComponent } from "./components/companies/ContainerComponent";
import { PageCompany } from "./pages/Companies/PageCompany";
import { PageBusinessList } from "./pages/Business/PageBusinessList";
import { PageBusiness } from "./pages/Business/PageBusiness";
import { PageProjectList } from "./pages/Project/PageProjectList";
import { PageProject } from "./pages/Project/PageProject";
import { PageCustomerTasks } from "./pages/Tasks/PageCustomerTasks";
import { PageCustomerTask } from "./pages/Tasks/PageCustomerTask";

export const CustomersChildRouting = (reducerRegistry) => {
  const { path } = useRouteMatch();

  reducerRegistry.register({ tasksReducer });
  reducerRegistry.register({ businessReducer });
  reducerRegistry.register({ projectReducer });

  return (
    <>
      <div>
        <Switch>
          <ContainerComponent>
            <Route
              exact
              path={`${path}/tareas/nuevo`}
              component={() => <PageCustomerTask title="Nueva" />}
            />
            <Route
              exact
              path={`${path}/tareas/editar/:id`}
              component={() => <PageCustomerTask title="Editar" />}
            />
            <Route
              exact
              path={`${path}/tareas`}
              component={PageCustomerTasks}
            />

            <Route
              exact
              path={`${path}/negocios/nuevo`}
              component={() => <PageBusiness title="Nuevo" />}
            />
            <Route
              path={`${path}/negocios/editar/:id`}
              component={() => <PageBusiness title="Editar" />}
            />
            <Route
              exact
              path={`${path}/negocios`}
              component={PageBusinessList}
            />

            <Route
              exact
              path={`${path}/proyectos/nuevo`}
              component={() => <PageProject title="Nuevo" />}
            />
            <Route
              path={`${path}/proyectos/editar/:id`}
              component={() => <PageProject title="Editar" />}
            />
            <Route
              exact
              path={`${path}/proyectos`}
              component={PageProjectList}
            />
            <Route exact path={`${path}`} component={PageCompany} />
          </ContainerComponent>
        </Switch>
      </div>
    </>
  );
};
