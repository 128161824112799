import { CoreConstants } from "core/commons/CoreConstants";
import { toastAction } from "core/store/actions/ToastAction";
import { CoursesManagmentConstants } from "features/coursesManagment/commons/CoursesManagmentConstants";
import { ScheduleServices } from "features/coursesManagment/services/ScheduleServices";
import FileSaver from "file-saver";
import moment from "moment";

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: CoursesManagmentConstants.Accion.Schedule.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({
      type: CoursesManagmentConstants.Accion.Schedule.RESET_AFTER_SAVED,
    });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload;

    if (advancedFilter.filter.name)
      pines.push({ field: "name", value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: "from",
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: "to",
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.category)
      pines.push({
        field: "category",
        value: advancedFilter.filter.category.description,
      });

    if (advancedFilter.filter.mode)
      pines.push({
        field: "mode",
        value: advancedFilter.filter.mode.description,
      });

    if (advancedFilter.filter.service)
      pines.push({
        field: "service",
        value: advancedFilter.filter.service.description,
      });

    advancedFilter.values = pines;

    dispatch({
      type: CoursesManagmentConstants.Accion.Schedule.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setSchedules = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.Schedule.SEARCH_SCHEDULES,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const schedules = getState().scheduleReducer.schedules;
    setSchedules({
      dispatch: dispatch,
      isLoading: true,
      data: schedules.data,
    });

    const criteria = {};
    const advancedFilter = getState().scheduleReducer.advancedFilter;
    const filter = getState().scheduleReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    criteria.categoryId = filter?.category?.id;
    criteria.modeId = filter?.mode?.id;
    criteria.serviceTypeId = filter?.service?.id;

    ScheduleServices.searchSchedules(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          setSchedules({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          setSchedules({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn("Programación", data.message));
        }else{
          setSchedules({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error("Programación", data.message));
        }
      },
      () => {
        setSchedules({ dispatch: dispatch, isLoading: false, data: null });
      }
    ).catch((error)=>{
    });
  };
};

const getScheduleById = (scheduleId) => {
  const setSchedule = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.Schedule.GET_SCHEDULE_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setSchedule({ dispatch: dispatch, isLoading: true });
    ScheduleServices.getScheduleById(scheduleId).then(
      ({ data }) => {
        // console.log(data)
        if (data.status === CoreConstants.HttpResponse.OK) {
          setSchedule({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setSchedule({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn("Programación", data.message));
        }else{
          setSchedule({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.error("Programación", data.message));
        }
      },
      () => {
        setSchedule({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};


const saveSchedule= (schedule) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentSchedule }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.Schedule.SAVE_SCHEDULE,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    const currentSchedule = getState().scheduleReducer.schedule;
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
      currentSchedule: currentSchedule,
    });
    ScheduleServices.saveSchedules(schedule).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(
            toastAction.success(
              'Programación', 
              'La programación se guardó correctamente'
              )
            );
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentSchedule, });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentSchedule });
          dispatch(toastAction.warn("Programación", data.message));
        }else{
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
          dispatch(toastAction.error("Programación", data.message));
        }

      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};
// elimine la action delete

const setDataMaster = (payload) => {
  return (dispatch) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.Schedule.GET_DATA_MASTER,
      payload,
    });
  };
};

const getDataMaster = () => {
  return (dispatch) => {
    ScheduleServices.getDataMaster()
      .then(({ data }) => {
        const {data:data2,status,message} = data
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(
            setDataMaster({
              ...data2,
              isLoading: false,
            })
          );
        }else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
         // dispatch(toastAction.error("Gestión de Usuarios", data.message));
         dispatch(toastAction.error("Gestión de Cursos",message))
        }
      })
      .catch((error)=>{
      })
      .finally(()=>{ dispatch(
        setDataMaster({
         isLoading: false,
         })
      )})
    return;
    //elimine anterior estructura
  };
};

const generateReport = (payload) => {
  const setSchedules = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.Schedule.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setSchedules({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    //const advancedFilter = getState().courseReducer.advancedFilter;
    const filter = getState().scheduleReducer.advancedFilter.filter;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    criteria.parentScheduleId = filter?.scheduleParent?.id;
    criteria.managerId = filter.manager?.id;

    ScheduleServices.generateReport(criteria)
      .then(({ data }) => {
        setSchedules({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });

        FileSaver.saveAs(blob, "Programacion.xls");
      })

      .catch((err) => {
        setSchedules({ dispatch: dispatch, isLoading: false });
        dispatch(
          toastAction.error(
            "Programación",
            "Hubo un error al descargar el archivo"
          )
        );
      });
  };
};
//anotacion: use el save_schdule en el save en vez del edit_companys
export const ScheduleAction = {
  toggleAdvancedFilter,
  search,
  updateFilter,
  getScheduleById,
  saveSchedule,
 /*  editSchedule, */
  getDataMaster,
  resetAfterSaved,
  generateReport,
};
