import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputSwitch } from 'primereact/inputswitch'
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { useSelector } from 'react-redux'


const EmployeesTableUploadNotes = ({listEmployees, setListEmployees}) => {

  const [selectedRow, setSelectedRow] = useState(null)
  const {data} = useSelector(state => state.employeeReducer.bulkLoad)
  
  const columns = [
    {field: 'documentNo', header: 'DNI'},
    {field: 'firstName', header: 'Nombre'},
    {field: 'fatherLastName', header: 'Apellido Paterno'},
    {field: 'motherLastName', header: 'Apellido Materno'},
    {field: 'personalEmail', header: 'Email'},
    {field: 'position', header: 'Cargo'},
  ];

  // console.log(data)

  const dynamicColumns = columns.map((col,i) => {
    return <Column key={col.field} field={col.field} header={col.header} />;
  });

  const switchBodyTemplate = (rowData) => {
    return <SwitchBody rowData={rowData} setListEmployees={setListEmployees} listEmployees={listEmployees} />
  }

  const rowClass = (data) => {
    return {
        'row-errors': data.error === true
    }
  }

  const newData = data.map((employee, i) => {
    const newEmployee = {
      firstName: employee.names,
      fatherLastName: employee.fatherLastName,
      motherLastName: employee.motherLastName,
      companyEmail: employee.workMail,
      companyPhone: employee.workPhone,
      positionId: employee.positionId,
      employeeTypeId: employee.employeeTypeId,
      situationId: employee.situationId,
      countryOfBirthId: employee.countryOfBirthId,
      dateOfBirth: employee.dateBirth,
      genderId: employee.genderId,
      documentTypeId: employee.documentTypeId,
      documentNo: `${employee.documentNumber}`,
      maritalStatusId: employee.maritalStatusId,
      childrenQuantity: employee.numberChildren,
      countryOfResidenceId: employee.countryOfResidenceId,
      departmentId: employee.departmentId,
      cityId: employee.cityId,
      address: employee.address,
      personalPhoneNo: employee.phone,
      mobilePhoneNo: employee.cellPhone,
      personalEmail: employee.mail,
      emergencyPhoneNo: employee.emergencyPhone,
      emergencyContactName: employee.contact,
      //* pictureData: employee,
      branchId: employee.branchId,
      //* academicDegreeId: employee,
      workPermitExpirationDate: employee.workPermissionExpirationDate,
      contractStartDate: moment(employee.contractStartDate).format(),
      contractEndtDate: employee.contractEndDate,
      weeklyLaborHours: employee.workDays,
      workPermitNumber: employee.workPermitNumber,
      rowStatus: true,
      academicProfileList: employee.pageAppliedStudies,
      workExperienceList: employee.pageWorkExperience,
      sourceId: employee.sourceId,
      position: employee.position,
      error: employee.error
    }
    return newEmployee
  })

  const newListEmployees = newData.filter(employee => employee.error === false)

  useEffect(() => {
    setListEmployees(newListEmployees)
  }, [data])

  return (
    <DataTable 
      value={newData} size="small" responsiveLayout="scroll" className='empleoyees-table-upload'
      rowClassName={rowClass} 
      selection={selectedRow} onSelectionChange={e => setSelectedRow(e.value)} dataKey="numeroDocumento"
      paginator rows={10} 
    >
      <Column header="" body={switchBodyTemplate}></Column>
        {dynamicColumns}
    </DataTable>
  )
}

export default EmployeesTableUploadNotes

const SwitchBody = ({rowData, setListEmployees, listEmployees}) => {
  const {position, ...newRowData} = rowData

  const handleChange = (e) => {
    const id = e.target.id
    let listOptions = []
    if (listEmployees.length === 0) {
      listOptions.push(newRowData)
    } else {
      const hasOption = listEmployees.some(employee => employee.documentNo === id );
      if (hasOption) {
        listOptions = listEmployees.filter((solOption) =>  solOption.documentNo !== id)
      } else {
        listOptions = [...listEmployees, newRowData];
      }
    }
    setListEmployees([...listOptions])
  }

  const isChecked = (newRowData) => {
    if (listEmployees.length === 0) return false;
    if (listEmployees.length > 0) {
       return listEmployees.some(employee => employee.documentNo === newRowData.documentNo)
    }
    return false;
  };

  return <InputSwitch inputId={newRowData.documentNo} id={newRowData.documentNo} checked={isChecked(newRowData)} onChange={handleChange} disabled={rowData.error} />
}