import { Button } from "primereact/button"
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable"
import { Tooltip } from "primereact/tooltip";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { FieldTable } from "./FieldTable";
import ModalFormTaskComponent from "features/customers/components/tasks/ModalFormComponent";
import { CoreConstants } from "core/commons/CoreConstants";
import { deleteTaskTableControl, fetchTask } from "features/configuration/helpers/fetchTasksTableControl";
import { Skeleton } from "primereact/skeleton";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useDispatch } from "react-redux";
import { Paginator } from "primereact/paginator";

export const RenderedTableControl = ({control, formValues, setValue, currentStepPermissionsControls, currentStepPermissions, updateRow, controlValidation}) => {
  const dispatch = useDispatch()
  const [expandedRows, setExpandedRows] = useState(null);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [idRowTable, setIdRowTable] = useState(null)
  const [idTaskShow, setIdTaskShow] = useState(null)
  const [loading, setLoading] = useState({})
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [pagination, setPagination] = useState({})
  const [loadingTask, setLoadingTask] = useState(false)

  let columnsField = {}
  if (control.type === 'TABLA') {
    control.details.forEach((detail) => {
      // columnsField[detail.type.toLowerCase()] = ''
      columnsField[detail.text] = detail.type=== 'FECHA_HORA' ? null : ''
    })
  }

  const permissionNotReadOnly = currentStepPermissionsControls?.includes(control.identifier) 
  ? !(currentStepPermissions.find((permission) => permission.identifierControl === control.identifier).isReadOnly)
  : !control.readonly

  const ColumnComponents = control.details.map((col) => {
    // TODO: validar el campo para readOnly de los permisos
    // if (!control.readonly) {
    if (permissionNotReadOnly) {
        return (
            <Column
                key={col.id}
                field={col.text}
                header={col.text}
                sortable
                body={(row) => <FieldTable name={col.text} type={col.type} row={row} updateRow={updateRow} required={col.required} details={col.details} isMultiple={col.isMultiple} fieldCode={col.fieldCode} controlValidation={controlValidation}/>}
            />
        );
    }
    
    return <Column  
      key={col.text} field={col.text} // sortable
      header={() => !control.showHeader && <div className='flex items-center'>{col.text}</div>}
    />
  });

  const createRow = () => {
    columnsField.id = uuidv4()
    columnsField.isNewRow = true
    // setRowsTable((prev) => [
    //   ...prev,
    //   columnsField
    // ]);
    setValue(control.identifier, [...formValues[control.identifier], columnsField])
  };

  const RowEditTask = ({task, documentId}) => {      
    return (
      <i
        onClick={() => {
          setIdTaskShow(task.id);
          setIdRowTable(documentId);
          setShowTaskModal(true);
        }}
        className="pi pi-pencil cursor-pointer"
      ></i>
    );
  };

  const RowDeleteTask = ({task, documentId}) => {   
    return (
      <i
        onClick={() => {
          setIdRowTable(documentId);
          setIdTaskShow(task.id);
          setShowConfirmDelete(true);
        }}
        className="pi pi-trash cursor-pointer"
      ></i>
    );
  };

  const deleteTask = () => {
    deleteTaskTableControl({dispatch, documentId: idRowTable, taskId: idTaskShow, getTasks});
  };  

  const rowExpansionTemplate = (data) => { 

    if(loading[data.id]) return <Skeleton width="100%" height="4.5rem" className="mb-3 w-full" />

    return (
      <div className="tasks-subtable">
        <DataTable value={data.tasks} header={null}
          emptyMessage="No se encontraron tareas">
          <Column field="title" header="Tarea" sortable/>
          <Column field="scheduleDate" header="Inicio" />
          <Column field="responsibleName" header="Responsable"/>
          <Column field="status" header="Estado"/>
          <Column header={<div></div>} body={(e) => <RowEditTask documentId={data.id} task={e}/>}></Column>
          <Column header={<div></div>} body={(e) => <RowDeleteTask documentId={data.id} task={e}/>}></Column>
        </DataTable>
        <Paginator
          className="paginator-custom mt-4"
          first={pagination[data.id]?.first}
          rows={10}
          totalRecords={pagination[data.id]?.totalRecords}
          onPageChange={async({ first, page }) => {
            setPagination((prev) => ({
              ...prev,
              [data.id]: {
                ...prev[data.id],
                first,              }
            }))
            const rowId = data.id
            const idFirstInput = Number(rowId.split("-")[0])
            if(!idFirstInput) return
            await getTasks(data.id, page+1)
          }}
        />
      </div>
    );
  };

  const RowAddTasks = ({id, isNewRow}) => {      
    if(isNewRow) return <></>
    return (
      <>
        <Tooltip
         target=".add-tasks" position="top"/>
        <i
          onClick={async() => {
            const indexColumn = control.details.findIndex(col => col.fieldCode === "TASK_FIELD_RESPONSIBLE")
            if(indexColumn < 0) return
            setLoadingTask(true)
            const responsibleId = Number(id.split('-')[indexColumn])
            const {results: data } = await fetchTask({ documentId: responsibleId, size: 10 })
            setIdTaskShow(data[0].id);
            setIdRowTable(responsibleId);
            setShowTaskModal(true);
            setLoadingTask(false)
            // setShowTaskModal(true);
            // setIdRowTable(id);
            // setIdTaskShow(null);
          }}
          className={`${loadingTask ? "pi pi-spin pi-spinner":"pi pi-book"} cursor-pointer text-center add-tasks`}
          data-pr-tooltip="Ver tarea"
        ></i>
      </>
    );
  };

  const deleteRow = (id) => {
    // const currentRowList = rowsTable.filter((row) => row.id !== id);
    const currentRowListForm = formValues[control.identifier].filter((row) => row.id !== id);

    // setRowsTable(currentRowList);
    setValue(control.identifier, currentRowListForm)
  };

  const RowDelete = ({ id }) => {
    return (
      <>
        <Tooltip target=".delete-tasks" position="top"/>
        <i
          onClick={() => {
              deleteRow(id);
          }}
          className={`${loadingTask ? "pi pi-spin pi-spinner":"pi pi-trash"} cursor-pointer text-center delete-tasks`}
          data-pr-tooltip="Eliminar fila"
        ></i>
      </>
    );
  };

  const getTasks = async(id, page = 1) => {
    const rowId = id
    const idFirstInput = Number(rowId.split("-")[0])
    if(!idFirstInput) return
    setLoading( prev => ({
      ...prev,
      [rowId]: true
    }))
    const {results: data, rowCount } = await fetchTask({ page, documentId: idFirstInput, size: 10 })
    const dataValues = formValues[control.identifier]
    const newDataValues = dataValues.map( row => {
      if(row.id === rowId){
        return {
          ...row,
          tasks: data
        }
      }
      return row
    })
    setValue(control.identifier, newDataValues)
    setPagination(prev => ({
      ...prev,
      [rowId]: {
        ...prev[rowId],
        totalRecords: rowCount,
      }
    }))
    setLoading( prev => ({
      ...prev,
      [rowId]: false
    }))
  }
  // console.log(formValues[control.identifier])
  return (
    <div>
      <p>{control.text}:</p>
      <div className='flex text-center w-full mt-2'>
        <DataTable
          // value={rowsTable}
          value={formValues[control.identifier]}
          stripedRows
          rows={5}
          editMode="row"
          dataKey="id"
          rowHover
          size="small"
          className='overflow-x-auto w-full'
          emptyMessage="No se encontraron registros"
          rowExpansionTemplate={rowExpansionTemplate}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          onRowExpand={(e) => getTasks(e.data.id)}
        >
          {
            ColumnComponents
          }
          {
            control.generateTasks && <Column header={<div></div>} body={RowAddTasks}/>
          }
          {
            // (!control.readonly) && (
            (permissionNotReadOnly) && (
                <Column header={<div></div>} body={RowDelete}></Column>
            )
          }
        </DataTable>
      </div>
      <Button icon="pi pi-plus"
        type="button"
        label="Añadir Fila"
        // className={` sig-button sig-primary w-auto pointer mt-3 ${!control.readonly ? '' : 'hidden'}`}
        className={` sig-button sig-primary w-auto pointer mt-3 ${permissionNotReadOnly ? '' : 'hidden'}`}
        onClick={createRow} 
      />
      <ModalFormTaskComponent
        isOpen={showTaskModal} 
        onClose={() => {        
          setShowTaskModal(false);
          // getTasks(idRowTable)
          // if(!isNewProject) updateTasksFromDeliverable(idDeliverableShow);
        }} 
        sourceDocumentCode={CoreConstants.Task.Source.DYNAMIC}
        sourceDocumentId={Number(idRowTable)}
        taskId={idTaskShow}
        disableInputs={true}
      />
      <ConfirmDialog
        visible={showConfirmDelete}
        onHide={() => setShowConfirmDelete(false)}
        message="¿Está seguro que desea eliminar la tarea?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={() => deleteTask()}
        reject={() => setShowConfirmDelete(false)}        
      />
    </div>
  )
}