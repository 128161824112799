import FileSaver from 'file-saver';
import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from "core/store/actions/ToastAction";
import { RiskConstants } from '../../commons/RiskConstants';
import { RiskInventoryServices } from 'features/riskManagement/services/RiskInventoryServices';
import moment from 'moment';

const setData = (payload) => ({
	type: RiskConstants.Accion.RiskInventory.GET_DATA,
	payload,
})

const setDataMaster = (payload) => ({
	type: RiskConstants.Accion.RiskInventory.GET_DATA_MASTER,
	payload,
})

const setInventory = (payload) => ({
	type: RiskConstants.Accion.RiskInventory.GET_DATA_BY_ID,
	payload,
})

const setDownloadStatus = (payload) => ({
	type: RiskConstants.Accion.RiskInventory.GET_DOWNLOAD_STATUS,
	payload,
})

const setUpdateFilter = (payload) => ({
	type: RiskConstants.Accion.RiskInventory.UPDATE_FILTER,
	payload,
})

const getData = () => {
	return (dispatch, getState) => {
		const { values, filter, ...meta } = getState().riskInventoryReducer.advancedFilter
		dispatch(setData({isLoading: true}));
		RiskInventoryServices.searchRiskInventory({...filter, ...meta}).then(({data})=>{
			if(data.status === CoreConstants.HttpResponse.OK){
				dispatch(setData({data: data.data, isLoading: false}))
			}else if(data.status > CoreConstants.HttpResponse.OK){
        dispatch(setData({isLoading: false, error: true}))
        dispatch(toastAction.warn('Inventario de Riesgos', data.message));
      }else{
        dispatch(setData({isLoading: false, error: true}))
      }
		}).catch(e => {
      console.log(e)
      dispatch(toastAction.error('Inventario de Riesgos', 'Error'));
      dispatch(setData({isLoading: false, error: true}))
    })
	}
}

const getDataMaster = () => {
	return (dispatch) => {
		dispatch(setDataMaster({isLoading: true}))
		RiskInventoryServices.getDataMaster().then(({data})=>{
			if(data.status === CoreConstants.HttpResponse.OK){
				const idManual = data.data.inventoryTypes.find( item => item.code === RiskConstants.Constants.RiskInventory.CODE_INVENTORY_TYPE_MANUAL)
				const typeDynamicProcessId = data.data.inventoryTypes.find( item => item.code === RiskConstants.Constants.RiskInventory.CODE_INVENTORY_TYPE_DINAMYC_PROCESS)
				dispatch(setDataMaster({isLoading: false, status: data.data.status, inventoryTypes: data.data.inventoryTypes, typeManualId: idManual.id, dynamicProcesses: data.data.dynamicProcesses, typeDynamicProcessId: typeDynamicProcessId.id}))
			}else if(data.status > CoreConstants.HttpResponse.OK){
        dispatch(setDataMaster({isLoading: false, error: true}))
        dispatch(toastAction.warn('Inventario de Riesgos', data.message));
      }else{
        dispatch(setDataMaster({isLoading: false, error: true}))
      }
		}).catch(e => {
      console.log(e)
      dispatch(toastAction.error('Inventario de Riesgos', 'Error'));
      dispatch(setDataMaster({isLoading: false, error: true}))
    })
	}
}

const save = (data, history) => {
	return (dispatch) => {
		dispatch(setInventory({isLoading: true}))
		RiskInventoryServices.save(data).then(({data})=> {
			if(data.status === CoreConstants.HttpResponse.OK){
				dispatch(setInventory({isLoading: false, isSaved: true}))
				dispatch(toastAction.success('Inventario de Riesgos', 'Se guardó correctamente'));
				history.push('/gestion-riesgos/inventario')
			}else if(data.status > CoreConstants.HttpResponse.OK){
				dispatch(setInventory({isLoading: false}))
        dispatch(toastAction.warn('Inventario de Riesgos', data.message));
      }else{
				dispatch(setInventory({isLoading: false}))
        dispatch(toastAction.error('Inventario de Riesgos', data.message));
      }
		}).catch(e => {
      console.log(e)
			dispatch(setInventory({isLoading: false}))
      dispatch(toastAction.error('Inventario de Riesgos', 'Error'));
    })
	}
}

const getDataById = (id) => {
	return (dispatch) => {
		dispatch(setInventory({isLoading: true}))
		RiskInventoryServices.getInventoryById(id).then(({data})=> {
			if(data.status === CoreConstants.HttpResponse.OK){
				dispatch(setInventory({data: data.data, isLoading: false}))
			}else if(data.status > CoreConstants.HttpResponse.OK){
				dispatch(setInventory({isLoading: false}))
        dispatch(toastAction.warn('Inventario de Riesgos', data.message));
      }else{
				dispatch(setInventory({isLoading: false}))
        dispatch(toastAction.error('Inventario de Riesgos', data.message));
      }
		}).catch(e => {
      console.log(e)
      dispatch(toastAction.error('Inventario de Riesgos', 'Error'));
      dispatch(setInventory({isLoading: false, error: true}))
    })
	}
}

const clearInventory = () => ({
	type: RiskConstants.Accion.RiskInventory.GET_DATA_BY_ID,
	payload: {data: {}}
})

const deleteInventory = (id) => (dispatch) => {
	RiskInventoryServices.deleteInventory(id).then(({data})=>{
		if(data.status === CoreConstants.HttpResponse.OK){
			dispatch(toastAction.success('Inventario de Riesgos', 'El inventario se eliminó correctamente'));
			dispatch(getData())
		}else if(data.status > CoreConstants.HttpResponse.OK){
			dispatch(toastAction.warn('Inventario de Riesgos', data.message));
		}else{
			dispatch(toastAction.error('Inventario de Riesgos', data.message));
		}
	}).catch(e => {
		console.log(e)
		dispatch(toastAction.error('Inventario de Riesgos', 'Error'));
	})
}

const download = (send = {"name": ""}) => (dispatch) => {
	dispatch(setDownloadStatus({ isLoading: true }));
	RiskInventoryServices.download(send).then(({data})=> {
		
		dispatch(setDownloadStatus({ isLoading: false }));
		const blob = new Blob([data], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
		});
		FileSaver.saveAs(blob, 'Inventario.xlsx');
	}).catch((err) => {
		setDownloadStatus({ isLoading: false, error: true });
		dispatch(toastAction.error('Inventario', 'Hubo un error al descargar el archivo'));
	});
}

// {
// 	values: [],
// 	filter: {
// 		name: null,
// 		from: null,
// 		to: null,
// 	},
// 	columnOrder: 'name',
// 	order: 'asc',
// 	page: 1,
// 	pageSize: 10,
// }

const setFilter = (payload) => (dispatch, getState) => {
	let filter = {}
	let values = []
	if(payload.filter){
		filter = {...getState().riskInventoryReducer.advancedFilter.filter, ...payload.filter}
		if(filter.name !== null){
			values.push({
				field: "name",
				value: filter.name,
			})
		}
		if(filter.from !== null){
			values.push({
				field: "from",
				value: moment(filter.from).format(
          CoreConstants.Format.LOCALE
        ),
			})
		}
		if(filter.to !== null){
			values.push({
				field: "to",
				value: moment(filter.to).format(
          CoreConstants.Format.LOCALE
        ),
			})
		}
		dispatch(setUpdateFilter({filter, values}))
	}
}

export const RiskInventoryAction = {
	getData,
	getDataMaster,
	save,
	getDataById,
	clearInventory,
	deleteInventory,
	download,
	setFilter,
}