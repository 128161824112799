import React, { useEffect, useState } from 'react'
import useSurveyPreview from 'features/survey/hooks/Survey/useSurveyPreview';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { useHistory, useParams } from 'react-router-dom';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import ListQuestionSurvey from 'features/survey/components/Survey/ListQuestionSurvey';
import LogoComponent from 'shared/components/logo/LogoComponent';
import { SurveyAction } from 'features/survey/store/actions/SurveyAction';
import { Skeleton } from 'primereact/skeleton';
import MessageEmptyQuestion from 'features/survey/components/Survey/MessageEmptyQuestion';
import { SurveyPublicSection } from 'features/survey/components/Survey/SurveyPublicSection';
import { SurveyPublicSectionGrid } from 'features/survey/components/Survey/SurveyPublicSectionGrid';

const SurveySectionType = Object.freeze({
  Regular: 'STRG',
  Grid: 'STML',
})

const SurveyPreviewPage = ({title}) => {
  const {updateTitle } = useSetTitlePage()
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [isFirstLoading, setFirstLoading] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const { surveySectionTypes, isLoading: isLoadingDataMaster} = useSelector((state) => state.surveyReducer.dataMaster);

  const { control, valuesFields, setValue, surveyData, questions, trigger, clearErrors} = useSurveyPreview()

  // const setSurveySectionRegularId = () => surveySectionTypes.find(sectionType => sectionType.code === SurveySectionType.Regular)?.id
  // const setSurveySectionGridId = () => surveySectionTypes.find(sectionType => sectionType.code === SurveySectionType.Grid)?.id

  const getSurveySectionTypeCodeById = (id) => surveySectionTypes.find(sectionType => sectionType.id === id)?.code 

  const isClassicSurvey = surveyData?.surveyType.code === 'STCL'

  const questionsWithoutRepeatedGridSectionId = surveyData ? surveyData.sections.reduce((acc, cur) => {
    if (getSurveySectionTypeCodeById(cur.sectionTypeId) === SurveySectionType.Grid) {
      acc.push(cur.questions[0]) 
    } else {
      acc.push(...cur.questions)
    }
    return acc
  }, []) : []

  useEffect(()=> {
    updateTitle({
      title: "Gestión de Encuestas",
      subtitle: "Encuestas",
      description: title,
      previousUrl: "/encuestas/encuesta/"
    })
    if (isFirstLoading) {
      setFirstLoading(false);
      // if (!params.id) onHide()
      if (params.id) dispatch(SurveyAction.getSurveyById(params.id));
    }
  }, [])


  const redirect = () => {
    history.push(`/encuestas/encuesta/${params.id}`)
  }

  const onQuestionChange = (e, questionField, onChange, error, indexTotal) => {
    let _selectedAnswers = [...valuesFields[questionField]];

    if (e.checked) {
        _selectedAnswers.push(e.value);
        if (!isClassicSurvey) {
          if (error) clearErrors(questionField)
        }
    }
    else {
        for (let i = 0; i < _selectedAnswers.length; i++) {
            const selectedAnswer = _selectedAnswers[i];

            if (selectedAnswer.id === e.value.id) {
                _selectedAnswers.splice(i, 1);
                if (valuesFields[`${questionField}_other`]) {
                  setValue(`${questionField}_other`, null);
                }
                break;
            }
        }
    }

    onChange(_selectedAnswers)
    // setValue(questionField, _selectedAnswers);
  }

  const onChangeWithOther = (onChange, value, field, error, indexTotal) => {
    if (value !== 'Otra' && valuesFields[`${field}_other`]) {
      setValue(`${field}_other`, null);
    }
    // console.log(`${field}_other`)
    onChange(value)
    if (!isClassicSurvey) {
      if (error) clearErrors(field)
    }
  }

  const onChangeSaveTime = (onChange, value, error, indexTotal) => {
    onChange(value)
    if (!isClassicSurvey) {
      if (value !== '' && error) clearErrors(`question_${indexTotal}`)
    }
  }

  const onChangeText = (onChange, value, error, indexTotal) => {
    onChange(value)
    if (!isClassicSurvey) {
      if (value !== '' && error) clearErrors(`question_${indexTotal}`)
    }
  }

  const renderQuestionOrSectionBySectionTypeId = (questions, sections, currentQuestion, questionsWithoutRepeatedGridQuestion) => {
    // const section = sections.find(section => section.id === questions[currentQuestion].surveySectionId)
    const section = sections.find(section => section.id === questionsWithoutRepeatedGridQuestion[currentQuestion].surveySectionId)

    const questionTotalIndex = questions.findIndex(question => question.id === questionsWithoutRepeatedGridQuestion[currentQuestion].id)

    if (!section.sectionTypeId || getSurveySectionTypeCodeById(section.sectionTypeId) === SurveySectionType.Regular) return (
      <ListQuestionSurvey 
        // question={surveyData?.questions[currentQuestion]}
        // question={questions[currentQuestion]}
        question={questionsWithoutRepeatedGridQuestion[currentQuestion]}
        control={control} 
        index={currentQuestion} 
        indexTotal={questionTotalIndex + 1}
        valuesFields={valuesFields} 
        onQuestionChange={onQuestionChange} 
        onChangeWithOther={onChangeWithOther} 
        onChangeSaveTime={onChangeSaveTime}
        onBlurSaveTime={() => {}}
        onChangeText={onChangeText}
      />
    )
    return (
      <SurveyPublicSectionGrid 
        questions={section.questions}
        totalQuestions={questions}
        control={control}
        onChangeWithOther={onChangeWithOther}
        valuesFields={valuesFields}
      />
    )
  }
  
  return (
      !surveyData ? <Skeleton height='4rem' />
      : (
        <div className=''>
          <div className='flex flex-col p-2 '>
            <div className="menu-component rounded-t-md">
              <div className="icon-w-logo flex justify-start items-center">
                <LogoComponent noRedirect={true} />
              </div>
            </div>
            <div className='border-2 shadow bg-gray-100'>
              <h1 className={`font-semibold py-2 px-3 text-center text-2xl`}>{surveyData?.title}</h1>
              <h2 className='py-1 px-3  font-medium text-left'>
                <div dangerouslySetInnerHTML={{ __html: surveyData?.description }} />
              </h2>
            </div>
            <form 
              className=""
            >
              <div className='flex flex-col gap-y-2'>
                {
                  isClassicSurvey 
                  ? (
                    (Object.keys(valuesFields).length > 0) 
                    ? surveyData.sections.map((section, index) => (
                      <div className='flex flex-col' key={`section-${section.id}`}>
                        <SurveyPublicSection 
                          index={index + 1} 
                          title={section.title} 
                          description={section.description} 
                          totalSections={surveyData.sections.length}
                        />
                        <div className='flex flex-col gap-y-2'>
                          {
                            (!section.sectionTypeId || getSurveySectionTypeCodeById(section.sectionTypeId) === SurveySectionType.Regular ) ?
                            section.questions.map((question, index) => (
                              <ListQuestionSurvey 
                                question={question} 
                                control={control} 
                                // index={index} //*
                                index={questions?.findIndex(q => q.id === question.id)} //*
                                indexTotal={questions?.findIndex(q => q.id === question.id) + 1}
                                valuesFields={valuesFields} 
                                onQuestionChange={onQuestionChange} 
                                onChangeWithOther={onChangeWithOther} 
                                onChangeSaveTime={onChangeSaveTime}
                                onBlurSaveTime={() => {}}
                                onChangeText={onChangeText}
                                key={index}
                              />
                            )) : (
                              <SurveyPublicSectionGrid 
                                questions={section.questions}
                                totalQuestions={questions}
                                control={control}
                                onChangeWithOther={onChangeWithOther}
                                valuesFields={valuesFields}
                              />
                            )
                          }
                        </div>
                      </div>
                    ))
                    : ( <MessageEmptyQuestion /> )
                  ) 
                  : (
                    !surveyData 
                    ? <Skeleton height='0rem' />
                    : (Object.keys(valuesFields).length > 0) ? (
                      <div className='flex flex-col'>
                        <SurveyPublicSection 
                          index={surveyData.sections.findIndex(section => section.id === questionsWithoutRepeatedGridSectionId[currentQuestion].surveySectionId) + 1} 
                          title={surveyData.sections.find(section => section.id === questions[currentQuestion].surveySectionId)?.title} 
                          description={surveyData.sections.find(section => section.id === questions[currentQuestion].surveySectionId)?.description} 
                          totalSections={surveyData.sections.length}
                        />
                        {
                          renderQuestionOrSectionBySectionTypeId(questions, surveyData?.sections, currentQuestion, questionsWithoutRepeatedGridSectionId)
                        }
                      </div>
                    ) : ( <MessageEmptyQuestion /> )
                  ) 
                }
              </div>


              
              <div className={`flex ${!isClassicSurvey ? 'justify-between' : 'justify-end'} items-center gap-x-4 my-5`}>
                {
                  (!isClassicSurvey && Object.keys(valuesFields).length > 0) && (
                    <div className='font-medium'>
                      {/* <p>Pregunta {currentQuestion + 1} de {questions.length} </p> */}
                      <p><span className='hidden md:inline'>Página </span>{currentQuestion + 1} de {questionsWithoutRepeatedGridSectionId.length} </p>
                    </div>
                  )
                }
                <div className='flex items-end gap-x-4'>
                  {
                    isClassicSurvey ? (
                      <Button
                        type="button"
                        label="Volver"
                        onClick={redirect}
                        className="sig-button sig-primary "
                      />
                    ) : Object.keys(valuesFields).length > 0 && (
                      <>
                        <Button
                          type="button"
                          label="Anterior pregunta"
                          onClick={() => setCurrentQuestion( prev => prev > 0 ? prev - 1 : prev)}
                          className={`sig-button sig-primary ${currentQuestion === 0 ? 'hidden' : ''}`}
                        />
                        <Button
                          type="button"
                          label='Siguiente pregunta'
                          // onClick={() => setCurrentQuestion( prev => prev <= questions.length - 2 ? prev + 1 : prev)}
                          onClick={ async() => {
                            const isValid = await trigger(`question_${currentQuestion + 1}`)
                            if (isValid) {
                              setCurrentQuestion( prev => prev <= questions.length - 2 ? prev + 1 : prev)
                            }
                          }}
                          className={`sig-button sig-primary ${currentQuestion === questionsWithoutRepeatedGridSectionId.length - 1 ? 'hidden' : ''}`}
                        />
                      </>
                    )
                  }
                </div>
              </div>
              {
                !isClassicSurvey && (
                  <div className='flex justify-end mt-7 mb-4'>
                    <Button
                      type="button"
                      label="Volver"
                      onClick={redirect}
                      className="sig-button sig-primary "
                    />
                  </div>
                )
              } 
            </form>
          </div>
        </div>
      )
    )
}

export default SurveyPreviewPage