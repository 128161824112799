import { useClaimPipeline } from "features/maintenance/hooks/useClaimPipeline";
import { PipelineAction } from "features/maintenance/store/actions/PipelineAction";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Message from "shared/components/messages/Message";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { v4 as uuidv4 } from "uuid";

const { useForm, Controller } = require("react-hook-form")

const PipelinePage = ({title, mode}) => {
  const {updateTitle } = useSetTitlePage()
  const history = useHistory();
  const dispatch = useDispatch();
  const pipeline = useSelector((state) => state.pipelineReducer.pipeline);  
  const [isFirstLoading, setFirstLoading] = useState(true);
  const [idDelete, setidDelete] = useState(0);
  const [visible, setVisible] = useState(false);
  const [visibleCancel, setVisibleCancel] = useState(false);
  const [stageList, setStageList] = useState([]);

  const { isCreate, isEdit } = useClaimPipeline()

  const {
    setValue,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    description: "",
    id: null,
    stagesList: [],
  });


    
  const params = useParams();

  const redirectToSearch = () => {
    history.push("/mantenimiento/pipeline");
    dispatch(PipelineAction.resetAfterSaved());
  }

  useEffect(() => {
    if (pipeline?.data) {
        setValue("description", pipeline.data.description);
        setValue("id", pipeline.data.id);
        setValue("stagesList", pipeline?.data?.stages);

        if(pipeline.data.stages){
            setStageList(pipeline.data.stages);
        }
    }
    if (pipeline?.isSaved)
        redirectToSearch();

   
  }, [pipeline]);

  
  useEffect(()=> {
    setValue("description", undefined);
    setValue("id", undefined);
    setValue("stagesList", stageList);


    updateTitle({
      title: "Mantenimiento",
      subtitle: "Pipeline",
      description: title,
      previousUrl: "/mantenimiento/pipeline/"
    })
    if (isFirstLoading) {
      setFirstLoading(false);
      if (params.id)
          getPipelineById(params.id)
    }
  }, [])

  const getPipelineById = (pipelineId) => {
    dispatch(PipelineAction.getPipelineById(pipelineId));
  }

  const generateStagesToPass = (currentStage, id) => {
    return [...currentStage].map((stage) => {
      const { action, ...rest} = stage;
      rest.pipelineId = id;
      rest.probability = Number(rest.probability)
      if(rest.isNewStage) {
        delete rest.id
        delete rest.isNewStage
        rest.probability = Number(rest.probability)
      }
      return rest;
    })

  }

  const onSubmit = (data) => {

      const model = {
        id: data.id,
        description: data.description,
        stagesList: generateStagesToPass(stageList, data.id)
      }

      dispatch(PipelineAction.savePipeline(model));

    }  
  


  const reject = () => {
      setVisible(false);
  };

  const [stageName_, setStageName] = useState("");
  const [nameField, setNameField] = useState("");
  const [pipelineId, setPipelineId] = useState("");


  const updateStage = ({ id, name, value }) => {
    const currentContactList = stageList.map((contact) => {
      if (contact.id === id) contact[name] = value;
      return contact;
    });

    setStageList(currentContactList);
  };

  const Field = ({ name, row }) => {
      return (
        <InputText
          type={
            name === "stageName"
              ? "text"
              : name === "probability"
              ? "number"
              : "text"
          }
          autoFocus={
            stageName_ === name && nameField === name && row.id === pipelineId
              ? true
              : false
          }
          onChange={({ target }) => {
            setPipelineId(row.id);
            setStageName(target.name);
            setNameField(target.name);
            if (name === "probability" && Math.sign(target.value) === -1) {
              target.value = "";
            }
            updateStage({
              id: row.id,
              name: target.name,
              value: target.value,
            });
          }}
          value={row[name]}
          name={name}
        />
      );
  };

  const stageDelete = (id) => {
    const currentStagetList = stageList.filter(
      (stage) => stage.id !== id
    );
    setStageList(currentStagetList);
  };

  const deleteStage = () => {
    stageDelete(idDelete);
  }

  const RowDelete = ({ id }) => {
    return (
      <i
        onClick={() => {
          setVisible(true);
          setidDelete(id);
        }}
        className="pi pi-trash cursor-pointer"
      ></i>
    );
  };

  const pipelineColumns = [
    { field: 'stageName', header: 'Nombre' },
    { field: 'probability', header: 'Probabilidad' },
  ];

  const ColumnComponents = pipelineColumns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable
        body={(row) => <Field name={col.field} row={row} />}
      />
    );
  });


  const createStage = () => {
    setStageList((prev) => [
      ...prev,
      {
        "stageName": "",
        "probability": 0,
        pipelineId: null,
        id: uuidv4(),
        "isNewStage": true,
      },
    ]);
  };

  return (
    <div className="bg-white">
      <div 
        className="form-custom p-0"
      >
      <div className="form-modal p-0">
        <Panel header="DATOS GENERALES" toggleable>
              {
                pipeline.isLoading ?(
                  <Skeleton height="2.2rem" />
                ) : (  
                  <form id="form-pipeline-page" className=" grid  sm:grid-cols-1 md:grid-cols-1 gap-1 mt-2 mb-1" onSubmit={handleSubmit(onSubmit)}>
                    <span className="p-float-label w-full mt-4">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                          <InputText
                            type="text"
                            id="description"
                            name="description"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            className={errors?.description ? 'p-invalid w-full' : 'w-full'}
                          />
                          )}
                          name="description"
                          rules={{
                              required: 'El nombre de pipeline es requerido',
                          }}
                    />
                    
                    <label htmlFor="description">Nombre de Pipeline *</label>
                  </span>              
                  </form>
                )
              }  

              <Button
              icon="pi pi-plus"
              type="button"
              label="Añadir nueva Etapa"
              loading={pipeline?.isSaving || pipeline?.isLoading}
              onClick={createStage}
              className="btn btn-primary mt-2 mb-4"
            />

            <div className="table-main table-contact mb-3">
              <DataTable
                value={stageList}
                emptyMessage="No se encontraron etapas"
                stripedRows
                rows={5}
                editMode="row"
                dataKey="id"
                rowHover
                size="small"
                responsiveLayout="scroll"
                paginator
              >
                {ColumnComponents}
                <Column header={<div></div>} body={RowDelete}></Column>
              </DataTable>
            </div>
            {/* {contactErrors.length > 0 && (
              <Message  status="error" />
            )}                */}
        </Panel>        
      </div>
      {
        (errors.description) && <div className='message error'>
            <ul>
                {errors.description ? (
                    <>
                        <li className="p-error">{errors.description.message}</li>
                    </>
                ) : null}
            </ul>

        </div>
        }
      <div className="flex justify-end gap-4 mt-3">
                    <Button
                        icon="pi pi-times"
                        type="button"
                        label="Cancelar"
                        loading={pipeline?.isSaving || pipeline?.isLoading}
                        onClick={() => { setVisibleCancel(true); }}
                        className="sig-button sig-secondary mt-4"
                    />
                    {
                      (mode!=='view')&&(<>
                        {
                          (isCreate || isEdit)&&
                            <Button
                                icon="pi pi-save"
                                type="submit"
                                form="form-pipeline-page"
                                label="Guardar"
                                loading={pipeline?.isSaving || pipeline?.isLoading}
                                className="sig-button sig-primary mt-4"
                            />
                        }
                      </>
                      )
                    }
                </div>
      </div>
      <ConfirmDialog
                visible={visibleCancel}
                onHide={() => setVisibleCancel(false)}
                message="¿Está seguro que desea cancelar?"
                header="Cancelar"
                icon="pi pi-exclamation-triangle"
                accept={redirectToSearch}
                reject={reject}
      />
      <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea eliminar la etapa?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={() => deleteStage()}
          reject={() => setVisible(false)}
        />
    </div>
  )
}

export default PipelinePage;