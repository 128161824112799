import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

export const useClaimSurveyManagment = () => {

  const menu = useSelector((state) => state.authReducer.user.menuPrincipal);
  const pathName = useLocation()?.pathname;
  const [isDelete, setDelete] = useState(false);
  const [isConfigurate, setConfigurate] = useState(false);
  const [isCreate, setCreate] = useState(false);
  const [isRename, setRename] = useState(false);

  useEffect(() => {
    remove();
    configurateRules();
    create();
    rename();
  }, [menu]);

  const remove = () => {
    const actionCode = 'DEL';
    const optionCode = "SRMG";
    setDelete(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  const configurateRules = () => {
    const actionCode = 'VIEW';
    const optionCode = "SRMG";
    setConfigurate(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  const create = () => {
    const actionCode = 'NEW';
    const optionCode = "SRMG";
    setCreate(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
 };

  const rename = () => {
    const actionCode = 'RENAME';
    const optionCode = "SRMG";
    setRename(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  return {
    isCreate,
    isDelete,
    isConfigurate,
    isRename,
  }
}