import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import TableComponent from "features/maintenance/components/BusinessSectors/TableComponent";
import AdvancedFilterComponent from "features/maintenance/components/BusinessSectors/AdvacedFilterComponents";
import FilterComponent from "features/maintenance/components/BusinessSectors/FilterComponent";
import { useClaimBusinessSectors } from 'features/maintenance/hooks/useClaimBusinessSectors';

const SearchBusinessSectorPage = () => {
    const history = useHistory();
    const { updateTitle } = useSetTitlePage();
    const [isFirstLoading, setFirstLoading] = useState(true);
    const dispatch = useDispatch();

    const { isSearch, isDownload, isCreate } = useClaimBusinessSectors();

    //comentario test
    useEffect(() => {
        updateTitle({
            title: 'Mantenimiento',
            subtitle: 'Rubro ',
        });

    }, []);


    return (
        <Fragment>
            {
                (isSearch||isDownload)&&(
                    <FilterComponent />
                )
            }
            {
                isCreate&&(
                    <Button
                        icon="pi pi-plus"
                        type="button"
                        label="Nuevo"
                        className="sig-button sig-dark mt-4"
                        onClick={() => {
                            history.push('/mantenimiento/rubro/nuevo');
                        }}
                    />
                )
            }
            <TableComponent />
            <AdvancedFilterComponent /> 
        </Fragment>
    );
};

export default SearchBusinessSectorPage;
