import { useForm } from 'react-hook-form';

const useDinamicProcess = () => {

  const {
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    register,
    formState: { errors },
  } = useForm();

  const valuesFields = watch();
  const existError = Object.keys(errors).length > 0
  
  return {
    control, errors, valuesFields, existError, setValue, handleSubmit, reset
  }
}

export default useDinamicProcess