/* eslint-disable import/no-anonymous-default-export */
import { RiskConstants } from "features/riskManagement/commons/RiskConstants";

const initialState = {
	inventories: {
		isLoading: false,
		data: {
			results: [],
			currentPage: null,
			pageCount: null,
			pageSize: null,
			rowCount: null,
		},
		error: false,
	},
	dataMaster: {
		status: [],
		inventoryTypes: [],
		dynamicProcesses: [],
		isLoading: false,
		error: false,
		typeManualId: null,
		typeDynamicProcessId: null,
	},
	inventory: {
		isLoading: false,
		data: {},
		isSaved: false,
	},
	downloadStatus: {
		error: false,
		isDownload: false,
	},
	advancedFilter: {
		values: [],
		filter: {
			name: null,
			from: null,
			to: null,
		},
		columnOrder: 'name',
		order: 'asc',
		page: 1,
		pageSize: 10,
	},
}

export default (state = initialState, action) => {
	switch (action.type) {
		case RiskConstants.Accion.RiskInventory.GET_DATA:
			return {
				...state,
				inventories: {
					...state.inventories,
					...action.payload,
				}
			}
		
		case RiskConstants.Accion.RiskInventory.GET_DATA_MASTER:
			return {
				...state,
				dataMaster: {
					...state.dataMaster,
					...action.payload,
				}
			}

			case RiskConstants.Accion.RiskInventory.GET_DATA_BY_ID:
				return {
					...state,
					inventory: {
						...state.inventory,
						...action.payload,
					}
				}

			case RiskConstants.Accion.RiskInventory.GET_DOWNLOAD_STATUS:
				return {
					...state,
					downloadStatus: {
						...state.downloadStatus,
						...action.payload,
					}
				}

			case RiskConstants.Accion.RiskInventory.UPDATE_FILTER:
				return {
					...state,
					advancedFilter: {
						...state.advancedFilter,
						...action.payload,
						filter: {
							...state.advancedFilter.filter,
							...action.payload.filter,
						},
					}
				}

		default:
			return state;
	}
}