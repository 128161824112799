/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { BusinessSheetAction } from "features/business/store/actions/BusinessSheetAction";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

export const useFormData = (questions, newQuestionsTemplate, sections) => {
  const [errorsQuestions, setErrorsQuestions] = useState([]);
  const [errorsFields, setErrorsFields] = useState([]);
  const [errorsLinkedTo, setErrorsLinkedTo] = useState([]);
  const dataMaster = useSelector((state) => state.businessSheetReducer.dataMaster.data);
  const currentTemplateId = useSelector(
    (state) => state.businessSheetReducer.businessSheetTemplates.currentTemplateId
  );
  const { businessSheet, loading } = useSelector(
    (state) => state.businessSheetReducer.data
  );
  const listControls = useSelector(
    (state) => state.businessSheetReducer.listControls.listControls
  );
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const businessSheetId = params.id;
  const {
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({ shouldUnregister: true });

  const onError = (errors, e) => {
    e.preventDefault();
    const errorsAux = [];
    const errorsAuxFields = [];
    const errorsLinkedTo = [];

    const questionsLinkedTo = questions
      .map((question, index) => ({ ...question, numberOfQuestion: index + 1 }))
      .filter((question) => question.linkTo);

    const countQuestionsLikedTo = questionsLinkedTo.reduce((acc, obj) => {
      acc[obj.linkTo] = (acc[obj.linkTo] || []).concat(String(obj.numberOfQuestion));
      return acc;
    }, {});

    const intl = new Intl.ListFormat("es");

    Object.keys(countQuestionsLikedTo).forEach((key) => {
      if (countQuestionsLikedTo[key]?.length > 1) {
        const fieldDescription = dataMaster.businessSheetLinkedField.find(
          (item) => item.id === Number(key)
        )?.description;
        const message = `No puede existir más de una pregunta que esté asociada a ${fieldDescription}. (Preguntas ${intl.format(
          countQuestionsLikedTo[key]
        )})`;
        errorsLinkedTo.push(message);
      }
    });

    questions.forEach((q, index) => {
      if (q.type === "") {
        errorsAuxFields.push(index + 1);
      }
    });

    questions.forEach((q, index) => {
      if (!q.fieldLabel || q.fieldLabel?.trim() === "") {
        errorsAux.push(index + 1);
      }
    });

    if (errorsAuxFields.length > 0 || errorsAux.length > 0 || errorsLinkedTo.length > 0) {
      setErrorsFields(errorsAuxFields);
      setErrorsQuestions(errorsAux);
      setErrorsLinkedTo(errorsLinkedTo);
      return;
    }

    setErrorsQuestions([]);
    setErrorsFields([]);
    setErrorsLinkedTo([]);
  };

  const questionAdapter = (question, index) => {
    const questionModel = {
      id: currentTemplateId ? null : question.id < 0 ? null : question.id,
      position: index + 1,
      fieldLabel: question.fieldLabel,
      businessQuestionControl: {
        isContactList: question.isContactList,
        questionTypeId: question.controlId,
        required: question.required,
        allowAddRows: question.allowAddRows,
        linkToId: question.linkTo,
        columns: question.columns?.map((col, index) => ({
          id: currentTemplateId ? null : col.id < 0 ? null : col.id,
          position: index + 1,
          title: col.header,
        })),
        fixFirstColumn: question.fixFirstColumn,
        multiple: question.multiple,
        options: question.options?.map((option, index) => ({
          id: currentTemplateId ? null : option.id < 0 ? null : option.id,
          name: option.name,
          position: index + 1,
        })),
        rows: question.rows?.map((row, index) => ({
          id: currentTemplateId ? null : row.id < 0 ? null : row.id,
          position: index + 1,
          title: row.title || "",
        })),
      },
    };

    return questionModel;
  }

  const submit = (data, e) => {
    e.preventDefault();
    const errorsAux = [];
    const errorsAuxFields = [];
    const errorsLinkedTo = [];

    const questionsLinkedTo = questions
      .map((question, index) => ({ ...question, numberOfQuestion: index + 1 }))
      .filter((question) => question.linkTo);

    const countQuestionsLikedTo = questionsLinkedTo.reduce((acc, obj) => {
      acc[obj.linkTo] = (acc[obj.linkTo] || []).concat(String(obj.numberOfQuestion));
      return acc;
    }, {});

    const intl = new Intl.ListFormat("es");

    Object.keys(countQuestionsLikedTo).forEach((key) => {
      if (countQuestionsLikedTo[key]?.length > 1) {
        const fieldDescription = dataMaster.businessSheetLinkedField.find(
          (item) => item.id === Number(key)
        )?.description;
        const message = `No puede existir más de una pregunta que esté asociada a ${fieldDescription}. (Preguntas ${intl.format(
          countQuestionsLikedTo[key]
        )})`;
        errorsLinkedTo.push(message);
      }
    });

    questions.forEach((q, index) => {
      if (q.type === "") {
        errorsAuxFields.push(index + 1);
      }
    });

    questions.forEach((q, index) => {
      if (!q.fieldLabel || q.fieldLabel?.trim() === "") {
        errorsAux.push(index + 1);
      }
    });

    if (errorsAuxFields.length > 0 || errorsAux.length > 0 || errorsLinkedTo.length > 0) {
      setErrorsFields(errorsAuxFields);
      setErrorsQuestions(errorsAux);
      setErrorsLinkedTo(errorsLinkedTo);
      return;
    }

    setErrorsQuestions([]);
    setErrorsFields([]);
    setErrorsLinkedTo([]);

    const send = {
      id: currentTemplateId ? null : businessSheetId ? Number(businessSheetId) : null,
      typeId: data.typeId,
      name: data.name,
      description: data.description,
      code: data.code,
    };

    const sectionsModel = []
    const questionsModel = [];
    questions.forEach((question, index) => {
      console.log(question.sectionId)
      if(!question.sectionId){
        const questionModel = questionAdapter(question, index)
        questionsModel.push(questionModel);
      } else {
        const currentSection = sectionsModel.find(section => section.id === question.sectionId)
        if(currentSection) {
          const questionModel = questionAdapter(question, index)
          currentSection.questions.push(questionModel)
        } else {
          const sectionSelected = sections.find(section => section.id === question.sectionId)
          const questionModel = questionAdapter(question, index)
          const sectionModel = {
            id: sectionSelected.id,
            label: sectionSelected.description,
            questions: [questionModel],
          }
          sectionsModel.push(sectionModel)
        }
      }
    });
    send.questions = questionsModel;
    send.sections = sectionsModel.map(section => ({...section, id: section.id < 0 ? null : section.id}));

    const notifications = [];

    for (const key in data) {
      if (key.startsWith("notification_")) {
        const [_, type, id] = key.split("_");
        const notification = notifications.find((n) => n.id === Number(id));

        if (notification) {
          notification[type] = data[key];
        } else {
          notifications.push({
            id: Number(id),
            [type]: data[key],
          });
        }
      }
    }

    const updatedNotifications = notifications.map((notification) => ({
      ...notification,
      id: notification.id < 0 ? null : notification.id,
    }));

    send.notifications = updatedNotifications;
    
    console.log(send);
    
    dispatch(BusinessSheetAction.save(send, () => history.push("/negocios/fichas")));
  };

  const setGeneralData = () => {
    setValue("code", businessSheet.code);
    setValue("name", businessSheet.name);
    setValue("description", businessSheet.description);
    setValue("link", businessSheet.link);
    setValue("typeId", businessSheet.typeId);
    setValue("statusId", businessSheet.statusId);
  };

  useEffect(() => {
    if (
      businessSheet.id &&
      Object.entries(newQuestionsTemplate).length > 0 &&
      listControls?.length > 0
    ) {
      setGeneralData();
      // setQuestionsData()
      // setNotificationsData()
    }
  }, [businessSheet.id, newQuestionsTemplate, listControls?.length]);

  return {
    errorsQuestions,
    errorsFields,
    errorsLinkedTo,
    setValue,
    handleSubmit,
    control,
    watch,
    errors,
    onError,
    submit,
  };
};
