import { useBusiness } from 'features/customers/hooks/business/useBusiness';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea'
import { InputNumber } from 'primereact/inputnumber';
import { Skeleton } from 'primereact/skeleton';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Panel } from 'primereact/panel';
import { Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import Message from "shared/components/messages/Message";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState } from 'react';
import ModalFormTaskComponent from 'features/customers/components/tasks/ModalFormComponent';
import { CoreConstants } from 'core/commons/CoreConstants';
import Attachments from 'shared/components/attachments/Attachments';
import { useClaimViewBusiness } from 'features/business/hooks/useClaimViewBusiness';
import { DynamicFieldList } from 'shared/components/dynamicFields/DynamicFieldList';

export const PageBusiness = (props) => {
  const errorsArray = [];
  const { 
    showModalCancel,
    cancelAccept,
    cancelDenied,
    setShowModalCancel, 
    errors,
    handleSubmit, 
    getTasks,
    taskDelete,
    isLoadingBusiness, 
    isLoadingDataMaster, 
    currentId,
    onSubmit, 
    control, 
    data,
    isNewBusiness,
    valuesFields,
    businessTasks,
    setValue,
    dynamicFieldsInformacion
  } = useBusiness(props.title);

  // console.log(valuesFields)
  
  const [idTaskShow, setIdTaskShow] = useState(0);
  const [showTaskModal, setShowTaskModal] = useState(false)
  const [idDeleteTask, setidDeleteTask] = useState(0); 
  const [visibleDialogTask, setVisibleTask] = useState(false);
  const [pipelineSelected, setPipelineSelected] = useState(null);
  const [pipelineSelectedId, setPipelineSelectedId] = useState(null);
  const [loading, setLoading] = useState(false);

  const {isView} = useClaimViewBusiness()

  const onChangePipelineHandler = (e) => {
    setPipelineSelected(e.value);
    setPipelineSelectedId(e.value?.id);
  }

  const rowEditTask = (task) => {      
    return (
      <i
        onClick={() => {
          setShowTaskModal(true);
          setIdTaskShow(task.id);
        }}
        className="pi pi-pencil cursor-pointer"
      ></i>
    );
  };

  const rowDeleteTask = (task, documentId) => {   
    return (
      <i
        onClick={() => {
          setVisibleTask(true);
          setidDeleteTask(task.id);
        }}
        className="pi pi-trash cursor-pointer"
      ></i>
    );
  };

  const deleteTask = () => {
    taskDelete(idDeleteTask, currentId, CoreConstants.Task.Source.Business);
  };  

  const styles ={
    Completado:{
        border:"1px solid #8CC9A6",
        padding:"0rem 0.50rem",
        borderRadius:"5px"
    },
    Pendiente:{
        border:"1px solid #F6697C",
        padding:"0rem 0.50rem",
        borderRadius:"5px"
    }
  }

  const onBodyStatusTemplate  = (rowData) =>{
    return <h1 className='text-center' style={styles[rowData.status]}>{rowData.status}</h1>
  }

  return (
    <>
      <div className="bg-white">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Panel header="INFORMACIÓN" toggleable>
            {/* CODIGO */}
            <div className="flex gap-3 justify-between mb-6 mt-4">
              {!isLoadingBusiness || !isLoadingDataMaster ? (
                <Controller 
                  name="Code"
                  control={control} 
                  rules={
                    { 
                      required: "El Código es obligatorio ",
                      maxLength:{
                        message: "El código del negocio no debe exceder los 20 caracteres",
                        value:20
                      }
                    }
                  }
                  render= {({field: {onChange, value}}) => (
                    <span className="p-float-label w-full mt-1 pr-2">
                    <InputText
                        type="text"
                        id="Code"
                        maxLength={20}
                        className={`w-full ${errors.Title?.type === 'required' ? ' p-invalid' : ''}`}
                        onChange={onChange}
                        value={value}/>
                    <label htmlFor="code">Código</label>
                  </span>
                )}/>
                ) : (
                  <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
              )}
            </div>
            {/* NOMBRE */}
            <div className="flex gap-3 justify-between mb-6 mt-4">
              {!isLoadingBusiness || !isLoadingDataMaster ? (
                <Controller 
                  name="Name"
                  control={control} 
                  rules={
                    { 
                      required: "El Nombre es obligatorio ",
                      maxLength:{
                        message: "El nombre del negocio no debe exceder los 100 caracteres",
                        value:100
                      }
                    }
                  }
                  render= {({field: {onChange, value}}) => (
                    <span className="p-float-label w-full mt-1 pr-2">
                    <InputText
                        type="text"
                        id="Name"
                        maxLength={100}
                        className={`w-full ${errors.Name?.type === 'required' ? ' p-invalid' : ''}`}
                        onChange={onChange}
                        value={value}/>
                    <label htmlFor="name">Nombre *</label>
                  </span>
                )}/>
                ) : (
                  <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
              )}
            </div>
            {/* PIPELINE */}
            <div className="flex gap-3 justify-between mb-6 mt-4">
              {!isLoadingBusiness || !isLoadingDataMaster ? (
                <Controller 
                  name="PipelineId"
                  control={control} 
                  rules={{ required: "El Pipeline es obligatorio"}}
                  render= {({field: {onChange, value}}) => (
                    <span className="p-float-label w-full">
                      <Dropdown
                        options={data?.pipelines}
                        optionLabel="description"
                        filter
                        filterBy="description"
                        showClear
                        className={`w-full ${errors.PipelineId?.type === "required" ? " p-invalid" : ""}`}
                        onChange={(e) => {onChange(e); onChangePipelineHandler(e)} }
                        value={(pipelineSelected !== null) ? pipelineSelected : value}
                      />
                      <label htmlFor="dropdown">Pipeline *</label>
                    </span>
                )}/>
              ) : (
                <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
              )}
              {!isLoadingBusiness || !isLoadingDataMaster ? (
                <Controller 
                  name="StageId"
                  control={control} 
                  rules={{ required: "La Etapa es obligatoria"}}
                  render= {({field: {onChange, value}}) => (
                  <span className="p-float-label w-full">
                    <Dropdown
                      options={
                            (data?.stages.filter(stage => stage?.pipelineId === valuesFields?.PipelineId?.id)?.length === 0)? 
                            data?.stages 
                            : 
                            data?.stages.filter(stage => stage?.pipelineId === valuesFields?.PipelineId?.id)}

                      optionLabel="stageName"
                      filter
                      filterBy="stageName"
                      showClear
                      className={`w-full ${errors.StageId?.type === "required" ? " p-invalid" : ""}`}
                      onChange={onChange}
                      value={value}
                    />
                    <label htmlFor="dropdown">Etapa *</label>
                  </span>
                )}/>
              ) : (
                <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
              )}
            </div>
            {/* ETAPA */}
            <div className="flex gap-3 justify-between mb-6 mt-4">
              {!isLoadingBusiness || !isLoadingDataMaster ? (
                <Controller 
                  name="ResponsibleId"
                  control={control} 
                  rules={{ required: "El Responsable es obligatorio "}}
                  render= {({field: {onChange, value}}) => (
                    <span className="p-float-label w-full">
                      <Dropdown
                        options={data?.responsibles}
                        optionLabel="fullName"
                        filter
                        filterBy="fullName"
                        showClear
                        className={`w-full ${errors.ResponsibleId?.type === "required" ? " p-invalid" : ""}`}
                        onChange={onChange}
                        value={value}
                      />
                      <label htmlFor="dropdown">Responsable *</label>
                    </span>
                  )}/>
                ) : (
                <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
              )}
            </div>
            {/* DESCRIPCIÓN */}
            <div className="flex gap-3 justify-between mb-6 mt-4">
              {!isLoadingBusiness || !isLoadingDataMaster ? (
                <Controller 
                  name="Description"
                  control={control} 
                  render= {({field: {onChange, value}}) => (
                    <span className="p-float-label w-full mt-2">
                      <label htmlFor="inputtextarea">Descripción</label>
                      <InputTextarea 
                        id="Description" 
                        rows={3} 
                        cols={30} 
                        value={value} 
                        className={`w-full ${errors.Description?.type === 'required' ? ' p-invalid' : ''}`}
                        onChange={onChange} />
                    </span>
                  )}/>
                ) : (
                <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
              )}
            </div>
            <div className="flex gap-3 justify-between mb-6 mt-4">
              {!isLoadingBusiness || !isLoadingDataMaster ? (
                <Controller 
                  name="CurrencyId"
                  control={control} 
                  render= {({field: {onChange, value}}) => (
                    <span className="p-float-label w-full mt-2">
                      <Dropdown
                        options={data?.currencies}
                        optionLabel="description"
                        filter
                        filterBy="description"
                        showClear
                        className="w-full"
                        onChange={onChange}
                        value={value}
                      />
                      <label htmlFor="dropdown">Moneda</label>
                    </span>
                  )}/>
                ) : (
                <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
              )}
              {!isLoadingBusiness || !isLoadingDataMaster ? (
                <Controller 
                  name="BusinessValue"
                  control={control} 
                  render= {({field: {onChange, value}}) => (
                    <span className="p-float-label w-full mt-2">
                      <InputNumber
                        id="businessValue"
                        className={`w-full ${errors.businessValue?.type === 'required' ? ' p-invalid' : ''}`}
                        onValueChange={onChange}
                        inputStyle={{textAlign:'right'}} 
                        value={value}
                        mode="decimal" 
                        minFractionDigits={2} 
                        maxFracionDigits={2}/>
                      <label htmlFor="businessValue">Montos</label>
                    </span>
                  )}/>
                ) : (
                <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
              )}
            </div>
            <div className="flex gap-3 justify-between mb-6 mt-4">
              {!isLoadingBusiness || !isLoadingDataMaster ? (
                <Controller 
                  name="OpenDate"
                  control={control} 
                  render= {({field: {onChange, value}}) => (
                  <span className="p-float-label w-full mt-2">
                    <Calendar
                      monthNavigator 
                      yearNavigator 
                      yearRange="1950:2050"
                      showIcon={false} 
                      onChange={onChange}
                      value={value}
                      className={`w-full ${errors.openDate?.type === 'required' ? ' p-invalid' : ''}`}
                      id="openDate"></Calendar>
                    <label htmlFor="openDate">Apertura</label>
                  </span>
                )}/>
              ) : (
                <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
              )}
              {!isLoadingBusiness || !isLoadingDataMaster ? (
                <Controller 
                  name="CloseDate"
                  control={control} 
                  render= {({field: {onChange, value}}) => (
                  <span className="p-float-label w-full mt-2">
                    <Calendar
                      monthNavigator 
                      yearNavigator 
                      yearRange="1950:2050"
                      showIcon={false} 
                      onChange={onChange}
                      value={value}
                      className={`w-full ${errors.openDate?.type === 'required' ? ' p-invalid' : ''}`}
                      id="closeDate"></Calendar>
                    <label htmlFor="closeDate">Cierre</label>
                  </span>
                  )}/>
                ) : (
                  <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
                )}
            </div>
            <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-6">
              {
                (!isLoadingBusiness && !isLoadingDataMaster) &&
                <DynamicFieldList control={control} fields={dynamicFieldsInformacion} />
              }
            </div>
          </Panel>
          {!isNewBusiness && (
            <Panel header="TAREAS" toggleable>
              <Button
                icon="pi pi-plus"
                type="button"
                label="Nueva"
                className="sig-button sig-primary mt-4 mb-4"
                onClick={(e) => { setShowTaskModal(true); }}
              />
              <DataTable 
                value={businessTasks} 
                stripedRows
                header={null} lazy 
                loading={loading}
                size="small"
                emptyMessage="No se encontraron tareas">
                <Column field="title" header="Tarea" sortable/>
                <Column field="scheduleDate" header="Inicio" />
                <Column field="responsibleName" header="Responsable"/>
                <Column field="status" header="Estado" body={onBodyStatusTemplate}/>
                <Column header={<div></div>} body={(e) => rowEditTask(e, data?.id)}></Column>
                <Column header={<div></div>} body={(e) => rowDeleteTask(e, data?.id)}></Column>
              </DataTable>
            </Panel>)
          }
          {!isNewBusiness && (
            <Attachments
              // module="customer"
              // addEndpoint={"api/Business/AddAttachment"}
              // deleteEndpoint={"api/Business/DeleteAttachment"}
              // listEndpoint={"api/Business/GetAttachmentsByBusinessId"}
              
              setValue={setValue}
              fieldFiles="savedFiles"
              fieldNewFiles="addedFiles"
              filesValues={valuesFields?.["savedFiles"]}
              newFilesValues={valuesFields?.["addedFiles"]}
              isLoading={isLoadingBusiness}
              validExtensions={["docx", "pdf", "xlsx"]}
              // isMultiple={false}
            />)
          }
          {Object.entries(errors).forEach((key, value) => {
            errorsArray.push(key[1].message);
          })}
          {errorsArray.length > 0 && (
              <Message messages={errorsArray} status="error" />
          )}
          {(!isLoadingBusiness || !isLoadingDataMaster) && (
            <div className="flex justify-end gap-4 mt-3">
              <Button
                  icon="pi pi-times"
                  type="button"
                  label="Cancelar"
                  onClick={() => setShowModalCancel(true)}
                  className="sig-button  sig-secondary mt-4"
              />
              {
                !isView&&(
                  <Button
                      icon="pi pi-save"
                      type="submit"
                      label="Guardar"
                      disabled={false}
                      loading={isLoadingBusiness}
                      className="sig-button sig-primary mt-4"
                  />
                )
              }
            </div>
          )}
        </form>
        <ConfirmDialog
          visible={showModalCancel}
          onHide={() => setShowModalCancel(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={cancelAccept}
          reject={cancelDenied}
        />
        <ConfirmDialog
          visible={visibleDialogTask}
          onHide={() => setVisibleTask(false)}
          message="¿Está seguro que desea eliminar la tarea?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={() => deleteTask()}
          reject={() => setVisibleTask(false)}        
        />
        <ModalFormTaskComponent
          isOpen={showTaskModal}
          onClose={() => 
            {
              getTasks(currentId);
              setShowTaskModal(false);
              setIdTaskShow(null);
            }
          }
          sourceDocumentCode={CoreConstants.Task.Source.BUSINESS}
          sourceDocumentId={currentId}
          taskId={idTaskShow}
        />        
      </div>
    </>
  );
}