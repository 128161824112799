// import { DocumentLibraryAction } from './../../store/actions/DocumentLibraryAction';
import { useSelector, useDispatch } from 'react-redux';
import ModalFilterComponent from 'shared/components/modal-filter/ModalFilterComponent';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { CoursesExpensesAction } from 'features/coursesManagment/store/actions/CourseExpensesAction';

const AdvancedFilterComponent = () => {


    const dispatch = useDispatch();
    const [formValues, setValues] = useState({
        from: null,
        to: null,
        name: null,
        category: null,
    });

    const advancedFilter = useSelector((state) => state.courseExpensesReducer.advancedFilter);
    const { showModal } = useSelector((state) => state.courseExpensesReducer.advancedFilter);
    const dataMaster = useSelector((state) => state.courseExpensesReducer.dataMaster);

    useEffect(() => {
        setValues({
            from: advancedFilter.filter.from,
            to: advancedFilter.filter.to,
            name: advancedFilter.filter.name,
            category: advancedFilter.filter.category,
        });
        // dispatch(CoursesExpensesAction.getDataMaster());
    }, [advancedFilter]);

    useEffect(() => {
        dispatch(CoursesExpensesAction.getDataMaster());
    },[dispatch])


    const clearFields = () => {
        setValues({
            from: null,
            to: null,
            name: null,
            category: null,
        });
    };
    const closeModal = () => {
        clearFields()
        dispatch(CoursesExpensesAction.toggleAdvancedFilter({ showModal: false }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const fields = formValues;

        if (fields?.from)
            advancedFilter.filter.from = fields.from;
        else
            advancedFilter.filter.from = null;


        if (fields?.to)
            advancedFilter.filter.to = fields.to;
        else
            advancedFilter.filter.to = null;

        if (fields?.name)
            advancedFilter.filter.name = fields.name;
        else
            advancedFilter.filter.name = null;


        if (fields?.category)
            advancedFilter.filter.category = fields.category;
        else
            advancedFilter.filter.category = null;


        dispatch(CoursesExpensesAction.updateFilter(advancedFilter));
        dispatch(CoursesExpensesAction.search());
        dispatch(CoursesExpensesAction.toggleAdvancedFilter({ showModal: false }));
    }

    const onChangeFrom = (e) => {
        if (e.value)
            formValues.to = null;
        setValues({
            ...formValues,
            [e.target.name]: e.value,
        });
    }

    const onChangeTo = (e) => {
        setValues({
            ...formValues,
            [e.target.name]: e.value,
        });
    }

    const onChangeName = (e) => {
        setValues({
            ...formValues,
            name: e.target.value,
        });
    }

    const onChangeCategory = (e) => {
        setValues({
            ...formValues,
            category: e.value,
        });
    }
    
    if (showModal)
        return (
            <ModalFilterComponent
                disabledBtn={false}
                closeModal={closeModal}
                clearFields={clearFields}
                handleSubmit={handleSubmit}
                searchLoading={advancedFilter?.loading}
            >

                <div className="flex gap-4 pt-3">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                value={formValues.from}
                                name="from"
                                id="desde"
                                onChange={(e) => onChangeFrom(e)}
                            />
                            <label htmlFor="desde">Desde</label>
                        </span>
                    </div>
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                value={formValues.to}
                                name="to"
                                id="hasta"
                                minDate={formValues?.from}
                                onChange={(e) => onChangeTo(e)}
                            />
                            <label htmlFor="hasta">Hasta</label>
                        </span>
                    </div>
                </div>

                <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            
                             <InputText
                                type="text"
                                id='name'
                                name="name"
                                onChange={(e) => { onChangeName(e) }}
                                value={formValues?.name}
                                maxLength={100}
                            />

                            <label htmlFor="dropdown">Nombre del Curso</label>
                        </span>
                    </div>
                </div>

                <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Dropdown
                                options={dataMaster?.categories}
                                optionLabel="description"
                                value={formValues?.category}
                                onChange={(e) => { onChangeCategory(e) }}
                                filter
                                showClear
                                filterBy="description"
                                className="w-full"
                            />

                            <label htmlFor="dropdown">Categoría</label>
                        </span>
                    </div>
                </div>

            </ModalFilterComponent>
        );
    else
        return null;
}

export default AdvancedFilterComponent;