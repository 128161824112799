import { DeparmentsAction } from './../../store/actions/DepartmentAction';
import { useSelector, useDispatch } from 'react-redux';
import ModalFilterComponent from 'shared/components/modal-filter/ModalFilterComponent';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';
import { CoreConstants } from 'core/commons/CoreConstants';
import { useEffect, useState } from 'react';

const AdvancedFilterComponent = () => {
    const dispatch = useDispatch();
    const [formValues, setValues] = useState({
        from: null,
        to: null,
        departmentParent: null,
        manager: null

    });

    //const [selectedDepartmentParent, setSelectedDepartmentParent] = useState(null);
    const advancedFilter = useSelector((state) => state.departmentReducer.advancedFilter);
    const { showModal } = useSelector((state) => state.departmentReducer.advancedFilter);
    const dataMaster = useSelector((state) => state.departmentReducer.dataMaster);
    useEffect(() => {
        setValues({
            from: advancedFilter.filter.from,
            to: advancedFilter.filter.to,
            departmentParent: advancedFilter.filter.departmentParent,
            manager: advancedFilter.filter.manager
        });

    }, [advancedFilter]);


    const clearFields = () => {
        setValues({
            from: null,
            to: null,
            departmentParent: null,
            manager: null
        });
    };
    const closeModal = () => {
        dispatch(DeparmentsAction.toggleAdvancedFilter({ showModal: false }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const fields = formValues;

        if (fields?.from)
            advancedFilter.filter.from = fields.from;
        else
            advancedFilter.filter.from = null;

        if (fields?.to)
            advancedFilter.filter.to = fields.to;
        else
            advancedFilter.filter.to = null;

        if (fields?.departmentParent !== null)
            advancedFilter.filter.departmentParent = fields.departmentParent;
        else
            advancedFilter.filter.departmentParent = null;

        if (fields?.manager)
            advancedFilter.filter.manager = fields.manager;
        else
            advancedFilter.filter.manager = null;


        dispatch(DeparmentsAction.updateFilter(advancedFilter));
        dispatch(DeparmentsAction.search());
        dispatch(DeparmentsAction.toggleAdvancedFilter({ showModal: false }));
    }

    const onChangeFrom = (e) => {
        if (e.value)
            formValues.to = null;
        setValues({
            ...formValues,
            [e.target.name]: e.value,
        });
    }

    const onChangeTo = (e) => {
        setValues({
            ...formValues,
            [e.target.name]: e.value,
        });
    }

    const onChangeDepartmentParent = (e) => {
        setValues({
            ...formValues,
            ['departmentParent']: e.value,
        });
    }

    const onChangeManager = (e) => {
         
        setValues({
            ...formValues,
            ['manager']: e.value,
        });
    }


    if (showModal)
        return (

            <ModalFilterComponent
                disabledBtn={false}
                closeModal={closeModal}
                clearFields={clearFields}
                handleSubmit={handleSubmit}
                searchLoading={advancedFilter?.loading}
            >

                <div className="flex gap-4 pt-3">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                value={formValues.from}
                                name="from"
                                id="desde"
                                onChange={(e) => onChangeFrom(e)}
                            />
                            <label htmlFor="desde">Desde</label>
                        </span>
                    </div>
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                value={formValues.to}
                                name="to"
                                id="hasta"
                                minDate={formValues?.from}
                                onChange={(e) => onChangeTo(e)}
                            />
                            <label htmlFor="hasta">Hasta</label>
                        </span>
                    </div>

                </div>

                <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Dropdown
                                options={dataMaster?.departmentParent}
                                optionLabel="name"
                                value={formValues?.departmentParent}
                                onChange={(e) => { onChangeDepartmentParent(e) }}
                                filter
                                showClear
                                filterBy="name"
                                className="w-full"
                            />

                            <label htmlFor="dropdown">Por Departamento Principal</label>
                        </span>
                    </div>
                </div>

                <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Dropdown
                                filter
                                optionLabel="fullName"
                                value={formValues?.manager}
                                options={dataMaster?.manager}
                                onChange={(e) => { onChangeManager(e) }}
                                showClear
                                filterBy="fullName"
                                className="w-full"
                            />

                            <label htmlFor="dropdown">Por Gerente o jefe Encargado</label>
                        </span>
                    </div>
                </div>


            </ModalFilterComponent>
        );
    else
        return null;
}

export default AdvancedFilterComponent;