import { apiService } from "core/services/ApiService";

const searchFlow = (criteria) => {
  return apiService.Post("management/api/Workflow/Search", criteria);
}

const saveApprovalFlow = (criteria) => {
  return apiService.Post("management/api/Workflow/Save", criteria)
}

const getUsers = () => {
  return apiService.Post("management/api/Workflow/GetDataMaster");
}

const getApprovalFlowById = (Id) => {
  return apiService.Get("management/api/Workflow/GetWorkflowConfigurationById?id=" + Id);
}

const generateReport = (criteria) => {
  return apiService.Post("management/api/Workflow/GenerateReport", criteria, {
    responseType: 'blob',
  });
}

const deletedFlow = (idFlow) => {
  return apiService.Delete(`management/api/Workflow/Delete?id=${idFlow}`);
}

export const ApprovalFlowService = {
  searchFlow,
  saveApprovalFlow,
  getUsers,
  generateReport,
  deletedFlow,
  getApprovalFlowById
};