import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InstitutionAction } from "features/maintenance/store/actions/InstitutionAction";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { Dropdown } from "primereact/dropdown";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { useClaimInstitution } from "features/maintenance/hooks/useClaimInstitution";

const { useForm, Controller } = require("react-hook-form")

const InstitutionPage = ({title, mode}) => {
  const {updateTitle } = useSetTitlePage()
  const history = useHistory();
  const dispatch = useDispatch();
  // TODO: Se usa al editar 
  const institution = useSelector((state) => state.institutionReducer.institution); 
  const {countries} = useSelector((state) => state.institutionReducer.dataMaster);  
  const [isFirstLoading, setFirstLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  const { isCreate, isEdit } = useClaimInstitution();

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    name: "",
    id: null,
    country: null,
  });

  const existError = Object.keys(errors).length > 0

  const params = useParams();

  const redirectToSearch = () => {
    history.push("/mantenimiento/institucion");
    dispatch(InstitutionAction.resetAfterSaved());
  }

  useEffect(() => {
    dispatch(InstitutionAction.getDataCountries());
  }, [])

  useEffect(() => {

    if (institution?.data) {
        setValue("name", institution.data.name);
        setValue("id", institution.data.id);
        setValue("country", institution.data.countryId);
    }
    if (institution?.isSaved)
        redirectToSearch();
  }, [institution]);
  
  useEffect(()=> {
    setValue("name", '');
    setValue("id", '');
    setValue("country", '');

    updateTitle({
      title: "Mantenimiento",
      subtitle: "Institución",
      description: title,
      previousUrl: "/mantenimiento/institucion/"
    })
    if (isFirstLoading) {
      setFirstLoading(false);
      if (params.id)
          getInstitutionById(params.id)
    }
  }, [])

  const getInstitutionById = (institutionId) => {
    dispatch(InstitutionAction.getInstitutionById(institutionId));
  }

  const onSubmit = (data) => {
    if (params.id) {
        const model = {
          id: data.id,
          name: data.name,
          countryId: data.country,
        }
      dispatch(InstitutionAction.saveInstitution(model));
      return
    }
    const model = {
      name: data.name,
      countryId: data.country,
    }
    dispatch(InstitutionAction.saveInstitution(model));
  };


  const reject = () => {
      setVisible(false);
  };

  return (
    <div className="bg-white">
      <form 
        className="form-custom p-0"
        onSubmit={handleSubmit(onSubmit)}
      >
      <div className="form-modal p-0">
        <Panel header="DATOS GENERALES" toggleable>
          <div className=" grid  sm:grid-cols-1 md:grid-cols-2 gap-2 ">
          
            {institution.isLoading ? (
              <Skeleton />
            ): (
              <span className="p-float-label w-full mt-2">
                  <Controller
                      control={control}
                      render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                      }) => (
                        <InputText
                            type="text"
                            id="name"
                            name="name"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            //className="w-full"
                            className={errors?.name ? 'p-invalid w-full' : 'w-full'}
                        />
                      )}
                      name="name"
                      rules={{
                          required: 'El nombre de la institución es requerido',
                      }}
                  />
                    
                  <label htmlFor="code">Nombre de la institución  *</label>
              </span>
            )}
              <span className="p-float-label w-full mt-4 hidden md:block">
                &nbsp;
              </span>
              </div>
              {
                institution.isLoading ?(
                  <Skeleton height="2.2rem" />
                ) : (  
                  <div className="grid sm:grid-cols-1 md:grid-cols-1 gap-1 mt-2 mb-1">
                    <span className="p-float-label w-full sm:w-1/2 mt-4">
                    <Controller
                      control={control}
                      render={({
                          field: { onChange, onBlur, value, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                      }) => (
                        <Dropdown
                          id="country"
                          name="country"
                          optionLabel="name"
                          showClear
                          value={value}
                          options={countries}
                          onChange={onChange}
                          filter 
                          filterBy="name"
                          placeholder="Selecciona un pais *" 
                          className={errors?.name ? 'p-invalid w-full' : 'w-full'}
                          optionValue={(e) => {
                            return e.id;
                          }}
                        />
                      )}
                      name="country"
                      rules={{
                          required: 'El pais es requerido',
                      }}
                  />
                      {/* <label htmlFor="country">Selecciona un Pais</label> */}
                    </span>          
                  </div>
                )
              }                 
        </Panel>        
      </div>
      {
        (existError) && <div className='message error'>
            <ul>
                {errors.name ? (
                    <>
                        <li className="p-error">{errors.name.message}</li>
                    </>
                ) : null}
                {errors.country ? (
                    <>
                        <li className="p-error">{errors.country.message}</li>
                    </>
                ) : null}
            </ul>

        </div>
        }
      <div className="flex justify-end gap-4 mt-3">
                    <Button
                        icon="pi pi-times"
                        type="button"
                        label="Cancelar"
                        onClick={() => { setVisible(true); }}
                        className="sig-button sig-secondary mt-4"
                    />
                    {
                      (mode!=='view')&&(<>
                      {
                        (isCreate || isEdit)&&
                          <Button
                              icon="pi pi-save"
                              type="submit"
                              disabled={existError ? true : false}
                              label="Guardar"
                              loading={institution?.isSaving || institution?.isLoading}
                              className="sig-button sig-primary mt-4"
                          />
                      }
                      </>
                      )
                    }
                </div>
      </form>
      <ConfirmDialog
                visible={visible}
                onHide={() => setVisible(false)}
                message="¿Está seguro que desea cancelar?"
                header="Cancelar"
                icon="pi pi-exclamation-triangle"
                accept={redirectToSearch}
                reject={reject}
            />
    </div>
  )
}

export default InstitutionPage;