import { RiskConstants } from "features/riskManagement/commons/RiskConstants";
import FileSaver from "file-saver";
import moment from "moment";
import { RiskManagementAction } from "./RiskManagementAction";
const { CoreConstants } = require("core/commons/CoreConstants");
const { toastAction } = require("core/store/actions/ToastAction");
const { EvaluationConstants } = require("features/riskManagement/commons/EvaluationConstans");
const { RiskEvaluationServices } = require("features/riskManagement/services/RiskEvaluationServices");

const toggleAdvancedFilter = (payload) => (
	{
		type: EvaluationConstants.Accion.Evaluation.ADVANCED_FILTER_TOGGLE,
		payload,
	});


const setEvaluations = (payload) => ({
	type: EvaluationConstants.Accion.Evaluation.SEARCH_EVALUATIONS,
	payload,
})

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.name) pines.push({ field: 'name', value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.DDMMYYYY
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.DDMMYYYY
        ),
      });

    advancedFilter.values = pines;

    dispatch({
      type: EvaluationConstants.Accion.Evaluation.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const getDataMasterEvaluation = () => dispatch => {
	const setDataMaster = (payload) => ({
		type: EvaluationConstants.Accion.Evaluation.GET_DATA_MASTER,
		payload
	})

	dispatch(setDataMaster({isLoading: true}))
	RiskEvaluationServices.getDataMasterEvaluation().then(({data})=>{
		if(data.status === CoreConstants.HttpResponse.OK){
			dispatch(setDataMaster({isLoading: false, data: data.data}))
		}else if(data.status > CoreConstants.HttpResponse.OK){
				dispatch(setDataMaster({isLoading: false, error: true}))
        dispatch(toastAction.warn('Modelos de Riesgo', data.message));
      }else{
				dispatch(setDataMaster({isLoading: false, error: true}))
        dispatch(toastAction.error('Modelos de Riesgo', data.message));
      }
	}).catch(e => {
		console.log(e)
		
		dispatch(setDataMaster({isLoading: false, error: true}))
	})
}

const search = (modelId) => {
  const setModels = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: EvaluationConstants.Accion.Evaluation.SEARCH_EVALUATIONS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const evaluations = getState().riskEvaluationReducer.evaluations;
    setModels({
      dispatch: dispatch,
      isLoading: true,
      data: evaluations.data,
    });

    const criteria = {};
    const advancedFilter = getState().riskEvaluationReducer.advancedFilter;
    const filter = getState().riskEvaluationReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;
		criteria.modelId = Number(modelId);

    RiskEvaluationServices.searchEvaluation(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          setModels({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
          setModels({ dispatch: dispatch, isLoading: false, data: null });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setModels({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Evaluación de Modelo', data.message));
        }
      },
      () => {
        setModels({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  };
};

const clearEvaluations = () => ({
	type: EvaluationConstants.Accion.Evaluation.SEARCH_EVALUATIONS,
	payload: {
		data: {
			results: [],
			currentPage: null,
			pageCount: null,
			pageSize: null,
			rowCount: null,
		},
		isLoading: false,
		error: false,
	},
})

const setCurrentEvaluation = (payload) => ({
	type: EvaluationConstants.Accion.Evaluation.GET_DATA_BY_ID,
	payload
})

const setFormula = (payload) => ({
	type: RiskConstants.Accion.RiskModel.SET_FORMULA,
	payload
})

const setFormulaInput = (payload) => ({
	type: RiskConstants.Accion.RiskModel.SET_FORMULA_INPUT,
	payload
})

const setGetData = (payload) => ({
  type: RiskConstants.Accion.RiskManagement.GET_DATA,
  payload,
});

const editEvaluation = (id) => {
  return (dispatch, getState) => {
    dispatch(setCurrentEvaluation({ isLoading: true }));
    RiskEvaluationServices.getEvaluationById(Number(id))
			.then(({data})=>{
				if(data.status === CoreConstants.HttpResponse.OK){
					dispatch(setCurrentEvaluation({isLoading: false, data: data.data}))
          
          const childFormulas = data.data.childFormulas
          if(childFormulas.length > 0){
            const valueFormula = []
            const newFormula = []
            childFormulas.forEach(element => {
              if(element.selectedCriteria){
                valueFormula.push(element.selectedCriteria.name)
                newFormula.push({
                  id: element.id,
                  idSetting: element.selectedCriteriaId,
                  value: element.selectedCriteria.name
                })
              }else if(element.selectedEvaluation){
                valueFormula.push(element.selectedEvaluation.name)
                newFormula.push({
                  id: element.id,
                  idSetting: element.selectedEvaluationId,
                  value: element.selectedEvaluation.name
                })
              }else if(element.operatorId){
                valueFormula.push(element.operator.description)
                newFormula.push({
                  id: element.id,
                  idSetting: element.operatorId,
                  value: element.operator.description
                })
              }else{
                valueFormula.push(`${element.value}`)
              }
            });
            dispatch(setFormulaInput({value: valueFormula }))
            dispatch(setFormula({data: newFormula}))
          }
          if(data.data.evaluationTypeId === 251){
            dispatch(RiskManagementAction.getData(false, id))
          }else{
            dispatch(RiskManagementAction.getData(true))
          }
				}else if(data.status > CoreConstants.HttpResponse.OK){
					dispatch(setCurrentEvaluation({isLoading: false, error: true}))
					dispatch(toastAction.warn('Evaluación de Modelo', data.message));
				}else{
					dispatch(setCurrentEvaluation({isLoading: false, error: true}))
					dispatch(toastAction.error('Evaluación de Modelo', data.message));
				}
			})
			.catch(e => {
				console.log(e)
				
				dispatch(setCurrentEvaluation({isLoading: false, error: true}))
			})
  };
};

const clearEdit = () => ({
	type: EvaluationConstants.Accion.Evaluation.GET_DATA_BY_ID,
	payload: {data: {}},
})

const generateReport = (modelId) => {

  const setModels = (payload) => ({
      type: EvaluationConstants.Accion.Evaluation.REPORT_STATUS,
      payload,
  });

  return (dispatch, getState) => {
    dispatch(setModels({ isLoading: true }));

    const criteria = {};
    //    const advancedFilter = getState().departmentReducer.advancedFilter;
		const filter = getState().riskEvaluationReducer.advancedFilter.filter;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;
		criteria.modelId = Number(modelId);

    RiskEvaluationServices.generateEvaluationReport(criteria)
      .then(({ data }) => {
        dispatch(setModels({ isLoading: false }));
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Evaluaciones.xls');
      })
      
      .catch((err) => {
        dispatch(setModels({ isLoading: false }));
        dispatch(toastAction.error('Evaluación de modelo', 'Hubo un error al descargar el archivo'));
      });
  };
};

const saveModel = ({ model, isNewModel, history }) => {
  return (dispatch) => {
    dispatch(setCurrentEvaluation({ isLoadingSave: true }));
    RiskEvaluationServices.saveEvaluation(model)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          const message = isNewModel
            ? "¡Registro exitoso!"
            : "¡Edición exitosa!";
          dispatch(toastAction.success("Modelo de Riesgo", message));
					dispatch(setCurrentEvaluation({ isLoadingSave: false }));
					history.push(`/gestion-riesgos/modelo/${model.modelId}/evaluaciones`)
        } else if (
          data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS &&
          data.data.length > 0
        ) {
					dispatch(toastAction.warn("Modelo de Riesgo", data.message));
					dispatch(setCurrentEvaluation({ isLoadingSave: false }));
        }else{
					dispatch(toastAction.error("Modelo de Riesgo", data.message));
					dispatch(setCurrentEvaluation({ isLoadingSave: false }));
				}
      })
      .catch((error) => {
        console.error(error);
        dispatch(setCurrentEvaluation({ isLoadingSave: false }));
      })
  };
};

const deleteModel = (id, idModel) => {
  return (dispatch) => {
		dispatch(setEvaluations({isLoading: true}))
    RiskEvaluationServices.deleteEvaluation(id)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success("Modelo", "Evaluación Eliminada"));
          dispatch(search(idModel));
        }else if (status > CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.warn("Modelo", data.message));
					dispatch(setEvaluations({isLoading: false}))
        }else {
					dispatch(toastAction.error("Modelo", data.message));
					dispatch(setEvaluations({isLoading: false}))
				}
      })
      .catch(()=>{
				dispatch(setEvaluations({isLoading: false}))
			});
  };
};

const setEvaluationsById = (payload) => ({
  type: EvaluationConstants.Accion.Evaluation.GET_EVALUATIONS_BY_ID,
  payload
})

const getEvaluationsById = (id) => (dispatch) => {
  RiskEvaluationServices.getEvaluationByModelId(id)
    .then(({data})=>{
      const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(setEvaluationsById({data: data.data}));
        }else if (status > CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.warn("Modelo", data.message));
        }else {
					dispatch(toastAction.error("Modelo", data.message));
				}
    }).catch(()=>{
      dispatch(toastAction.error("Modelo", "Ocurrio un error"));
    })
}

const clearEvaluationsById = () => ({
  type: EvaluationConstants.Accion.Evaluation.GET_EVALUATIONS_BY_ID,
  payload: {data: null}
})

export const RiskEvaluationAction = {
	getDataMasterEvaluation,
	toggleAdvancedFilter,
	updateFilter,
	search,
	clearEvaluations,
	editEvaluation,
	clearEdit,
	generateReport,
	saveModel,
	deleteModel,
  getEvaluationsById,
  clearEvaluationsById,
}