import { UsersAction } from "features/configuration/store/actions/UsersAction";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import useSetTitlePage from "shared/hooks/useSetTitlePage";

const useUser = ({ title }) => {
  const history = useHistory();
  const params = useParams();
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const dataManager = useSelector((state) => state.userReducer.dataManager);
  const { userEdit } = useSelector((state) => state.userReducer);

  const [isActive, setIsActive] = useState(false);
  const [srcAvatar, setSrcAvatar] = useState(null);
  const [file, setFile] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [locationIds, setLocationIds] = useState([]);
  const [roleIds, setRoleIds] = useState([]);
  const [visible, setVisible] = useState(false);
  const [disabledButtonState, setDisabledButtonState] = useState(false);
  const [errors, setErrors] = useState({});

  const { updateTitle } = useSetTitlePage();

  let isUserNew = title === "Nuevo Usuario";
  const description = isUserNew ? "Nuevo Usuario" : "Editar Usuario";
  const pageTitle = {
    title: "Seguridad",
    subtitle: "Roles y Privilegios",
    description,
  };

  const { userName, email, firstName, lastName, employeeId } = userEdit?.data;
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    dispatch(UsersAction.getDataMaster());
  }, []);

  useEffect(() => {
    document.getElementById("content-main").scroll({
      top: 0,
      behavior: "smooth",
    });
    if (!isUserNew) dispatch(UsersAction.getUser(params.id));
  }, []);

  useEffect(() => {
    if (roleIds.indexOf(1) !== -1) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [roleIds]);

  useEffect(() => {
    if (isUserNew) {
      setIsActive(true);
      setDisabledButtonState(true);
      setIsAdmin(false);
    } else {
      setUserData({ userName, email, firstName, lastName, employeeId });
      const statusID = userEdit.data.statusId;
      const { status } = dataManager.data;
      const isActive =
        status?.find((s) => s?.id === statusID)?.description.toUpperCase() ===
        "ACTIVO";
      setIsActive(isActive);
    }
  }, [userEdit.data]);

  useEffect(() => {
    roleIds.indexOf(1) !== -1 ? setIsAdmin(true) : setIsAdmin(false);
  }, [roleIds]);

  useEffect(() => {
    updateTitle({
      title: "Configuración",
      subtitle: "Gestión de Usuarios",
      description: title,
      previousUrl: "/configuracion/usuarios",
    });
  }, []);

  const { status } = useSelector((state) => state.userReducer.saveUser);
  useEffect(() => {
    if (status === 0) {
      setTimeout(() => {
        dispatch(UsersAction.saveUserStatus({ status: null }));
        history.push("/configuracion/usuarios");
      }, 3000);
    }
  }, [status]);

  useEffect(() => {
    if (isUserNew) setSrcAvatar(null);
    else setSrcAvatar(userEdit.data?.avatar);
  }, [userEdit]);

  useEffect(() => {
    const { data } = userEdit;

    if (!isUserNew && Object.values(data).length > 0) {
      const roles = [];
      data?.roles?.forEach(({ code }) => {
        if (!code) return;
        roles.push(code.toUpperCase());
      });

      if (roles?.includes("ADMIN")) setIsAdmin(true);
      else setIsAdmin(false);
      const locationsIDs = [];

      data?.companies?.forEach(({ locations }) => {
        locations.forEach(({ id, isSelected }) => {
          if (isSelected) locationsIDs.push(id);
        });
      });

      const rolesIDs = data?.roles?.map((r) => r.id);
      setLocationIds(locationsIDs);
      setRoleIds(rolesIDs);
    }
  }, [userEdit]);

  const handleLocationChange = (e) => {
    setErrors({
      ...errors,
      locationIds: false,
    });
    if (e.target.checked) setLocationIds([...locationIds, e.target.name]);
    else {
      const newLocationIds = locationIds.filter((l) => l !== e.target.name);
      setLocationIds(newLocationIds);
    }
  };

  const handleRoleChange = (e) => {
    setErrors({
      ...errors,
      roleIds: false,
    });
    if (e.target.value && e.target.name !== 1)
      setRoleIds([...roleIds, e.target.name]);
    else if (e.target.value && e.target.name === 1) setRoleIds([e.target.name]);
    else {
      const newRoleIds = roleIds.filter((r) => r !== e.target.name);
      setRoleIds(newRoleIds);
    }
  };

  const accept = () => {
    dispatch(UsersAction.saveUserStatus({ status: null }));
    const { description, ...rest } = pageTitle;
    updateTitle(rest);
    history.push("/configuracion/usuarios");
  };

  const reject = () => {
    setVisible(false);
  };
  const onSelectedImage = ({ target }) => {
    const file = target.files[0];
    if (!file) {
      setSrcAvatar(userEdit?.data?.avatar);
      return;
    }
    setFile(file);
    const fr = new FileReader();
    fr.onloadend = () => setSrcAvatar(fr.result);
    fr.readAsDataURL(file);
  };

  const isCheckedLocation = ({ id }) => {
    if (isUserNew && locationIds?.length === 0) return false;
    else if (locationIds?.length > 0) return locationIds.includes(id);
    return false;
  };

  const isCheckedRole = ({ id }) => {
    if (isUserNew && roleIds?.length === 0) {
      return false;
    } else if (roleIds?.length > 0) {
      return roleIds.includes(id);
    }
    return false;
  };

  const validateFields = (payload) => {
    const errors = {};
    const errorMsgs = [];
    const buildMessage = (field) => `El ${field} es requerido`;

    for (let prop in payload) {
      const errorMessages = {
        roleIds: "Debes seleccionar por lo menos un Rol",
        locationIds: "Debes seleccionar por lo menos una Sede",
        userName: buildMessage("Usuario"),
        email: buildMessage("Email"),
        firstName: buildMessage("Nombres"),
        lastName: buildMessage("Apellidos"),
      };

      if (
        (!payload[prop] || payload[prop].length === 0) &&
        Object.keys(errorMessages).includes(prop)
      ) {
        errors[prop] = true;
        errorMsgs.push(errorMessages[prop]);
      }
    }
    setErrors({
      ...errors,
      list: errorMsgs,
    });
    return errorMsgs.length > 0;
  };

  const createOrEditUser = (e) => {
    e.preventDefault();
    let formData = new FormData();
    const userId = parseInt(params.id) || null;
    const avatar = srcAvatar;
    const { status } = dataManager.data;

    const statusName = isActive ? "ACTI" : "INAC";
    const statusId = status.find((s) => s.code === statusName)?.id;

    const payload = {
      userName: null,
      email: null,
      lastName: null,
      firstName: null,
      employeeId: null,
      ...userData,
      avatar,
      userId,
      statusId,
      roleIds,
      locationIds,
    };

    if (validateFields(payload)) return;

    if (!payload.avatar || payload?.avatar.startsWith("http")) {
      delete payload.avatar;
    } else {
      payload.avatar = file;
    }
    if (!payload.userId) delete payload.userId;
    if (!payload.employeeId) delete payload.employeeId;

    for (let prop in payload) {
      if (Array.isArray(payload[prop])) {
        payload[prop].forEach((val) => {
          formData.append(prop, val);
        });
      } else formData.append(prop, payload[prop]);
    }

    dispatch(UsersAction.saveUserStatus({ status: "" }));
    dispatch(
      UsersAction.saveUser(
        formData,
        isUserNew ? "¡Registro exitoso!" : "¡Edición exitosa!"
      )
    );
  };

  return {
    onSelectedImage,
    isUserNew,
    inputFile,
    srcAvatar,
    isAdmin,
    setIsAdmin,
    setUserData,
    userData,
    isActive,
    setIsActive,
    isCheckedLocation,
    isCheckedRole,
    createOrEditUser,
    setSrcAvatar,
    handleLocationChange,
    handleRoleChange,

    errors,
    setErrors,
    visible,
    setVisible,
    accept,
    reject,
    disabledButtonState,
    userEdit,
    dataManager
  };
};

export default useUser;
