import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { ProgressBar } from 'primereact/progressbar';
import { toastAction } from 'core/store/actions/ToastAction';
import { useRef } from 'react';
import IndicatorAttachment from './IndicatorAttachment';
import IndicatorTableUpload from './IndicatorTableUpload';
import { IndicatorsAction } from 'features/indicators/store/actions/IndicatorAction';
import './IndicatorTableUpload.scss'
import { ProgressSpinner } from 'primereact/progressspinner';
import Attachments from 'shared/components/attachments/Attachments';

const IndicatorModalImport = ({displayModal, setDisplayModal, title}) => {

  const dispatch = useDispatch()
  const defaultValues = { 
    file: null,
    savedFiles: [],
    addedFiles: [],
  }
  const { setValue, handleSubmit, watch } = useForm({ defaultValues });
  const valuesFields = watch();

  const [isUploaded, setIsUploaded] = useState(false)
  const [visible, setVisible] = useState(false);
  const [errors, setErrors] = useState({})
  const [listErrors, setListErrors] = useState([])
  const [listIndicators, setlistIndicators] = useState([])
  const [value1, setValue1] = useState(0);
  const buttonRef = useRef(null)
  const interval = useRef(null);
  
  const { data, isLoading } = useSelector(state => state.indicatorReducer.bulkLoad)
  const bulkLoadErrors  = useSelector(state => state.indicatorReducer.bulkLoadErrors)
  const { isSaved } = useSelector(state => state.indicatorReducer.indicatorValues)

  const existError = Object.keys(errors).length > 0

  const onHide = () => {
    setDisplayModal(false)
    setIsUploaded(false)
    setValue('file', null)
    setlistIndicators([])
    setValue1(0)
    setErrors({})
    setListErrors([])
    dispatch(IndicatorsAction.resetAfterSaved());
  }

  const redirectToSearch = () => {
    onHide()
  }

  const reject = () => {
    setVisible(false);
  };
  
  useEffect(() => {
    if (data) {
      setIsUploaded(true)
    }
  }, [data])

  useEffect(() => {
    if (bulkLoadErrors.length > 0) {
      setListErrors(bulkLoadErrors)
    }
  }, [bulkLoadErrors])

  useEffect(() => {
    if (valuesFields.addedFiles.length > 0) {
      buttonRef.current.click()
      setValue('addedFiles', [])
    }
  }, [valuesFields.addedFiles.length, setValue])

  useEffect(() => {
    if (!isSaved) {
      setValue1(0)
    }
    let val = 0;
    if (isSaved) {
      interval.current = setInterval(() => {
        val += Math.floor(Math.random() * 10) + 8;
        if (val >= 100) {
            val = 100;
            clearInterval(interval.current);
            onHide()
            dispatch(toastAction.success("Indicador" ,'Registro exitoso'));
            // dispatch(EmployeesAction.getEmployees({}))
        }
        setValue1(val);
      }, 300);

      return () => {
          if (interval.current) {
              clearInterval(interval.current);
              interval.current = null;
          }
      }
    }
  }, [isSaved])
  
  
    const newList = listIndicators.length > 0 ? listIndicators.reduce((prev, current, index, arr) => {
      const model = {
        indicatorId: current.indicatorId,
        valueDate: current.valueDate,
        value: current.value,
      }
      // let newIndicator = prev.find(x => x.indicatorId === current.indicatorId);
      let newIndicator = prev.find(x => x.indicatorId === model.indicatorId);
      if (!newIndicator) {
        newIndicator = {
          indicatorId: model.indicatorId,
          indicatorValueList: []
        }
        newIndicator.indicatorValueList.push(model)
        prev.push(newIndicator);
        return prev
      }

      newIndicator.indicatorValueList.push(model)
      return prev
    }, []) : []

  const onSubmit = (newFile) => {
    if (!isUploaded) {
      // if (Object.values(newFile).length === 0 || newFile.file === null) {
      //   setErrors({file: {type: 'required', message: 'Se requiere subir un archivo'}})
      //   return
      // }
      if (newFile.addedFiles.length === 0) {
        setErrors({file: {type: 'required', message: 'Se requiere subir un archivo'}})
        return
      }
      setErrors({})
      let bodyFormData = new FormData();
      // bodyFormData.append('File', newFile.file)
      bodyFormData.append('File', newFile.addedFiles[0])
      dispatch(IndicatorsAction.uploadBatchInfo(bodyFormData));
      return
    }
    if (listIndicators.length === 0) return

    newList.forEach((indicator, index) => {

      if (index === newList.length - 1) {
        dispatch(IndicatorsAction.saveIndicatorValues(indicator, `${index + 1}/${listIndicators.length}`))
        // console.log(indicator)
      } else {
        dispatch(IndicatorsAction.saveIndicatorValues(indicator))
        // console.log(indicator)
      }
    })
  }

  return (
    <Dialog header={title} visible={displayModal} breakpoints={{'960px': '78vw'}} style={{width: '55vw'}} onHide={onHide}>
        <form 
          className="form-custom p-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          <hr className='mb-2'/>
          {
            !isUploaded
            ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div className='col-span-2'>
                  {/* <IndicatorAttachment setValue={setValue} iconTypeOfFile={iconTypeOfFileTable} buttonRef={buttonRef} /> */}
                  {
                    isLoading 
                    ? (
                      <div className='flex justify-center items-center mt-2'>
                        <ProgressSpinner style={{width: '50px', height: '50px'}} />
                      </div>
                    )
                    : (
                      <Attachments
                        setValue={setValue}
                        fieldFiles="savedFiles"
                        fieldNewFiles="addedFiles"
                        filesValues={valuesFields?.["savedFiles"]}
                        newFilesValues={valuesFields?.["addedFiles"]}
                        isLoading={false}
                        validExtensions={["xlsx"]}
                        isMultiple={false}
                        isShowHeader={false}
                      />
                    )
                  }
                </div>
              </div>
            )
            :
            (
              <div className='flex flex-col items-center indicator-table'>
                <IndicatorTableUpload listIndicators={listIndicators} setListIndicators={setlistIndicators} />
                {
                  (value1 === 0 || value1 === 100) ? null : (<ProgressBar className='w-4/5 mt-2' value={value1}></ProgressBar>)
                }
              </div>
            )
          }
          {
            (existError || listErrors.length > 0) && <div className='message error overflow-y-auto max-h-36'>
            <ul>
                {errors.file ? (
                    <>
                        <li className="p-error">{errors.file.message}</li>
                    </>
                ) : null}
            </ul>
            {
              listErrors.map((error, index) => (
                <div key={index}>
                  <ul>
                      {error ? (
                          <>
                              <li className="p-error">{`Fila ${error.line}: ${error.error}`}</li>
                          </>
                      ) : null}
                  </ul>
              </div>
              ))
            }
            
        </div>
          }
          <div className="flex justify-end gap-4 mt-3">
              <Button
                  icon="pi pi-times"
                  type="button"
                  label="Cancelar"
                  onClick={() => { setVisible(true); }}
                  className="sig-button sig-secondary mt-4"
              />
              <Button
                  icon="pi pi-save"
                  type="submit"
                  label="Guardar"
                  ref={buttonRef}
                  // disabled={existError || listErrors.length > 0}
                  className="sig-button sig-primary mt-4"
              />
          </div>
        </form>
        <ConfirmDialog
            visible={visible}
            onHide={() => setVisible(false)}
            message="¿Está seguro que desea cancelar?"
            header="Cancelar"
            icon="pi pi-exclamation-triangle"
            accept={redirectToSearch}
            reject={reject}
        />
    </Dialog>
  )
}

export default IndicatorModalImport