import { CoreConstants } from 'core/commons/CoreConstants';
import { ProjectConstants } from 'features/customers/commons/ProjectConstants';
import { ProjectServices } from 'features/customers/services/ProjectService';
import { toastAction } from 'core/store/actions/ToastAction';
import { TaskAction } from './TaskAction';
import moment from 'moment';
import FileSaver from "file-saver";

const setProject = (fields) => {
  return (dispatch) =>
    dispatch({
      type: ProjectConstants.Action.Project.SEARCH_PROJECT,
      payload: { ...fields },
    });
};

const getProject = (fields) => {
  return (dispatch) => {
    dispatch(setProject({ loading: true }));
    ProjectServices.search(fields)
      .then(({ data }) => {
        const status = data?.status ?? '';
        if (status === CoreConstants.HttpResponse.OK) {
          const { results, ...rest } = data.data;

          let currentFields = {
            loading: false,
            data: results,
            pagination: { ...rest },
          };

          if (fields?.fields?.order) {
            currentFields.order = fields.fields.order;
          }

          if (fields?.fields?.columnOrder) {
            currentFields.columnOrder = fields.fields.columnOrder;
          }

          dispatch(setProject(currentFields));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Proyectos", data.message));
        }else{
          dispatch(toastAction.error("Proyectos", data.message));
        }

      })
      .catch((error)=>{
      })
      .finally(() => {
        dispatch(setProject({ loading: false }));
      });
  };
};

const setAdvancedFilter = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ProjectConstants.Action.Project.ADVANCED_FILTER,
      payload,
    });
  };
};

const getFilterValues = (newValues) => {
  const newFilterValues = {};
  newValues.forEach((val) => {
    const field = val.field.toLocaleLowerCase();
    if (field.includes('date')) newFilterValues[val.field] = val.date;
    else if(field === 'amount') newFilterValues[val.field] = Number(val.value);
    else if(field === 'situationid') newFilterValues[val.field] = val.option.id;
    else if (field.includes('id')) {
      if(field.includes('customerid')){
        newFilterValues['customerId'] = newFilterValues['customerId'] ? [...newFilterValues['customerId'], val.option.id] : [val.option.id]
      } else if(field.includes('responsibleid')){
        newFilterValues['responsibleId'] = newFilterValues['responsibleId'] ? [...newFilterValues['responsibleId'], val.option.id] : [val.option.id]
      } else if(field.includes('statusid')){
        newFilterValues['statusId'] = newFilterValues['statusId'] ? [...newFilterValues['statusId'], val.option.id] : [val.option.id]
      }
    } else newFilterValues[val.field] = val.value
  });
  return newFilterValues;
};

const removeFilterValues = (removeField) => {
  return (dispatch, getState) => {
    let { values } = getState().projectReducer.advancedFilter;    
    const newValues = values.filter(({ field }) => field !== removeField);
    const fields = getFilterValues(newValues);
    dispatch(getProject({ change: true, fields }));
    dispatch(setAdvancedFilter({ values: newValues }));
  };
};

const setGetDataMaster = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ProjectConstants.Action.Project.DATA_MASTER,
      payload,
    });
  };
};

const getDataMaster = () => {
  return (dispatch) => {
    dispatch(setGetDataMaster({ isLoadingDataMaster: true }));
    ProjectServices.getDataMaster()
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setGetDataMaster({ data: data.data }));
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setGetDataMaster({ isLoadingDataMaster: false }));
      });
  };
};

const currentAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ProjectConstants.Action.Project.CURRENT_ACTION,
      payload,
    });
  };
};

const setCurrentProject = (payload) => {  
  return (dispatch) => {
    dispatch({
      type: ProjectConstants.Action.Project.EDIT_PROJECT,
      payload,
    });
  };
};

const editProject = (id) => {
  return (dispatch) => {
    dispatch(setCurrentProject({ isLoadingProject: true, data: {}, attachments: [] }));
    dispatch(getProjectById(id));
  };
};

const reportStatus = (payload) => { 
  return (dispatch) => {
    dispatch({
      type: ProjectConstants.Action.Project.DOWNLOAD_REPORT_STATUS,
      payload,
    });
  };
};

const getListColaborators = (dataMembers) =>  {
  let arrayColaborators = [];  
  for(const {MemberId: memberId, RoleId: roleId, ProjectId: projectId, Id: id} of dataMembers){
    arrayColaborators.push({id, roleId, memberId, projectId})   
  }
  return arrayColaborators; 
}

const getListDeliverables = async(dataDeliverables) =>  {    
  let arrayDeliverables = [];
  if(dataDeliverables == null) return arrayDeliverables;
  for await (let {Id: id, Name: name, 
    ProjectId: projectId, BeginDate: beginDate, 
    EndDate: endDate, DeliveryDate: deliveryDate, Tasks} of dataDeliverables){
      beginDate = new Date(beginDate);
      endDate = new Date(endDate);
      deliveryDate = new Date(deliveryDate);
      Tasks = await getTasksFromDeliverable(id);
      arrayDeliverables.push({id, name, projectId, beginDate, endDate, deliveryDate, Tasks})
  }
  return arrayDeliverables;
}

const getTasksFromDeliverable = async (documentId) => {
  const fields = {
    "page": 1,
    "pageSize": 20,
    "columnOrder": "title",
    "order": "asc",
    "documentId": documentId,
    "sourceId": 70
  }
  return await TaskAction.getTasksByFields(fields); 
}

const getProjectById = (id) => {
  return (dispatch) => {
    dispatch(setCurrentProject({ isLoadingProject: true, data: {}, attachments: [] }));
    ProjectServices.getById(id)
    .then(async ({ data }) => {     
      // const status = data.Status;
      const status = data.status;
      if (status === CoreConstants.HttpResponse.OK) {
        // const project = data.Data;
        const project = data.data.project;
        project.dynamicFieldValues = data.data?.dynamicData

        const attachments = data.data?.attachment;
        // Object.entries(project).forEach(([key, value]) => {
        //   if (value !== null) {
        //     if (key.includes("OpenDate") || key.includes('CloseDate')) {
        //         let date = new Date(value);
        //         project[key] = date;               
        //     }
        //     if(key.includes("Members")) {
        //       project[key] = getListColaborators(value)
        //     }
        //   }
        // });
        Object.entries(project).forEach(([key, value]) => {
          if (value !== null) {
            if (key.includes("estimatedOpenDate") || key.includes('estimatedCloseDate') || key.includes('realCloseDate') || key.includes('realOpenDate')) {
                let date = new Date(value);
                project[key] = date;               
            }
            // if(key.includes("Members")) {
            //   project[key] = getListColaborators(value)
            // }
          }
        });

        // data.Data.Deliverables?.forEach((deliverable, key) => {
        //   data.Data.Deliverables[key] = {
        //     ...deliverable,
        //     beginDate: deliverable.BeginDate,
        //     deliveryDate: deliverable.DeliveryDate,
        //     endDate: deliverable.EndDate,
        //     id: deliverable.Id,
        //     name: deliverable.Name,
        //     project: deliverable.Project,
        //     projectId: deliverable.ProjectId,
        //   }
        // })
        // dispatch(setCurrentProject({ data: data.Data }));
        dispatch(setCurrentProject({ isLoadingProject: true, data: project, attachments }));
        // await getListDeliverables(project["Deliverables"]).then(
        //   (list) => {
        //     project["Deliverables"] = list;
        //   }
        // ).catch(project["Deliverables"] = [])
        // .finally(() => {
        // });
      }else if(status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
        dispatch(toastAction.warn("Proyectos", data.message));
      }else{
        dispatch(toastAction.error("Proyectos", data.message));
      }
    })
    .catch((error)=>{
    })
    .finally(() => {
      dispatch(setCurrentProject({ isLoadingProject: false }));
    });
  }
}

const generateReport = (currentValues) => {
  return (dispatch, getState) => {       
    const requestGenerateReport = { ...currentValues }

    if(currentValues.customerId?.length > 0) requestGenerateReport.customerId = currentValues.customerId[0]
    if(currentValues.statusId?.length > 0) requestGenerateReport.statusId = currentValues.statusId[0]
    if(currentValues.responsibleId?.length > 0) requestGenerateReport.responsibleId = currentValues.responsibleId[0]
    
    ProjectServices.generateReport(requestGenerateReport)
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        FileSaver.saveAs(blob, "Proyectos.xls");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(reportStatus(false));
      });
  };
};

const save = ({ project, isNewProject }) => {
  return (dispatch) => {
    const currentLoading = { isLoadingProject: true };
    dispatch(setCurrentProject(currentLoading));
    ProjectServices.save(project)
      .then(({ data }) => {
        const status = data.status;
        if (data.status === CoreConstants.HttpResponse.OK) {
          const message = isNewProject
            ? '¡Registro exitoso!'
            : '¡Edición exitosa!';
          dispatch(toastAction.success('Proyectos', message));
          dispatch(setCurrentProject({ errors: [] }));
        } else if (
          data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS &&
          data.data.length > 0
        ) {
          dispatch(setCurrentProject({ errors: data.data }));
          dispatch(toastAction.warn("Proyectos", data.message));
        } else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.error("Proyectos", data.message));
        }
        dispatch(setCurrentProject({ status }));
      })
      .catch((error)=>{
        dispatch(setCurrentProject({ status: 2 }));
      })
      .finally(() => {
        currentLoading.isLoadingProject = false;
        dispatch(setCurrentProject(currentLoading));
      });
  };
};

const deleteById = (id, customerId) => {
  return (dispatch) => {
    ProjectServices.deleteById(id)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Proyecto', 'Proyecto Eliminado'));
          dispatch(getProject({ change: true, fields: {customerId: customerId} }));
        }else if(status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Proyecto", data.message));
        }else{
          dispatch(toastAction.error("Proyecto", data.message));
        }
      })
      .catch((error)=>{
      });
  };
};

export const ProjectAction = {
  currentAction,
  setCurrentProject,
  setAdvancedFilter,
  removeFilterValues,
  getProject,
  getDataMaster,
  editProject,
  generateReport,
  save,
  deleteById,
  getTasksFromDeliverable
}
