


import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import AdvancedFilterComponent from 'features/projects/components/ProjectExpenses/AdvancedFilterComponent';
import FilterComponent from 'features/projects/components/ProjectExpenses/FilterComponent';
import TableComponent from 'features/projects/components/ProjectExpenses/TableComponent';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { Button } from 'primereact/button';
import { useClaimProjectExpenses } from 'features/projects/hooks/useClaimProjectExpenses';

const SearchProjectExpensesPage = () => {
  const { updateTitle } = useSetTitlePage();
  const history = useHistory();
  // const dispatch = useDispatch()
  // const [indicatorSelected, setIndicatorSelected] = useState(null)
  
  useEffect(() => {
    updateTitle({
        title: 'Gestión de Proyectos',
        subtitle: 'Gastos de Proyecto',
    });
  });

  const {isCreate, isSearch, isDownload} = useClaimProjectExpenses()

  return (
    <Fragment>
      {
          (isSearch||isDownload)&&(
            <FilterComponent />
          )
      }
      <div className='my-4'>
      {
        isCreate&&(
          <Button
              icon="pi pi-plus"
              type="button"
              label="Nuevo"
              className="sig-button sig-dark mr-3"
              onClick={() => {
                  history.push('/proyecto/gastos/nuevo');
              }}
          />
        )
      }
      </div>
      <TableComponent 
        // setIndicatorSelected={setIndicatorSelected} 
      />
      <AdvancedFilterComponent /> 
    </Fragment>
  )
}

export default SearchProjectExpensesPage