import { useForm } from 'react-hook-form';

const useProcessModal = () => {


  let defaultValuesModal = {
    fieldLabel: '',
    Date: null,
    DateAndHour: null,
    required: false,
    visible: false,
    readOnly: false,
    mode: "new",
    idTypeControl: null,
    description: '',
    stageId: null,
    identifier: '',
    //*
    numberOfEntry: null,
    tableToConnect: null
  };

  const {
    // register,
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch
  } = useForm({defaultValuesModal});

  const valuesFields = watch();
  // console.log(valuesFields)

  const existError = Object.keys(errors).length > 0

  return {
    control,
    errors,
    existError,
    setValue,
    handleSubmit,
    reset,
    valuesFields
  }
}

export default useProcessModal