import { useForm } from 'react-hook-form';

const useIndicator = () => {

  let defaultValues = {
    code: '',
    name: '',
    createdBy: '',
    updatedBy: '',
    creationDate: '',
    updateDate: '',
    version: '',

    owner: '',
    type: '',
    from: '',
    decimalPlaces: '0',
    measurementFrequency: '',
    to: '',
    formula: null,
    process: null,

    range: '',
    goalValue: '',
    highValue: '',
    unitMeasurement: '',

    period: '',
  };

  const {
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
    clearErrors
  } = useForm({ defaultValues });

  const valuesFields = watch();
  const existError = Object.keys(errors).length > 0

  const validateLength = (value) => {
    if (typeof value === 'string') {
      return value.replace(/<[^>]+>/g, '')?.length;
    } else if (value?.htmlValue) {
      return value.htmlValue.replace(/<[^>]+>/g, '').length;
    }
    return '0';
  };

  

  return {
    control,
    errors,
    valuesFields,
    existError,
    setValue,
    reset,
    handleSubmit,
    validateLength,
    clearErrors
  }
}

export default useIndicator