import { ProjectRoleAction } from "../../store/actions/ProjectRoleAction";
import { useSelector, useDispatch } from "react-redux";
import ModalFilterComponent from "shared/components/modal-filter/ModalFilterComponent";
import { Calendar } from "primereact/calendar";
import { useEffect, useState } from "react";

const AdvancedFilterComponent = () => {
  const dispatch = useDispatch();
  const [formValues, setValues] = useState({
    from: null,
    to: null,
  });

  const advancedFilter = useSelector(
    (state) => state.projectRoleReducer.advancedFilter
  );
  const { showModal } = useSelector(
    (state) => state.projectRoleReducer.advancedFilter
  );

  useEffect(() => {
    setValues({
      from: advancedFilter.filter.from,
      to: advancedFilter.filter.to,
    });
  }, [advancedFilter]);

  const clearFields = () => {
    setValues({
      from: null,
      to: null,
    });
  };
  const closeModal = () => {
    dispatch(ProjectRoleAction.toggleAdvancedFilter({ showModal: false }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const fields = formValues;

    if (fields?.from) advancedFilter.filter.from = fields.from;
    else advancedFilter.filter.from = null;

    if (fields?.to) advancedFilter.filter.to = fields.to;
    else advancedFilter.filter.to = null;

    dispatch(ProjectRoleAction.updateFilter(advancedFilter));
    dispatch(ProjectRoleAction.search());
    dispatch(ProjectRoleAction.toggleAdvancedFilter({ showModal: false }));
  };

  const onChangeFrom = (e) => {
    if (e.value) formValues.to = null;
    setValues({
      ...formValues,
      [e.target.name]: e.value,
    });
  };

  const onChangeTo = (e) => {
    setValues({
      ...formValues,
      [e.target.name]: e.value,
    });
  };

  if (showModal)
    return (
      <ModalFilterComponent
        disabledBtn={false}
        closeModal={closeModal}
        clearFields={clearFields}
        handleSubmit={handleSubmit}
        searchLoading={advancedFilter?.loading}
      >
        <div className="flex gap-4 pt-3">
          <div className="w-full">
            <span className="p-float-label">
              <Calendar
                value={formValues.from}
                name="from"
                id="desde"
                onChange={(e) => onChangeFrom(e)}
              />
              <label htmlFor="desde">Desde</label>
            </span>
          </div>
          <div className="w-full">
            <span className="p-float-label">
              <Calendar
                value={formValues.to}
                name="to"
                id="hasta"
                minDate={formValues?.from}
                onChange={(e) => onChangeTo(e)}
              />
              <label htmlFor="hasta">Hasta</label>
            </span>
          </div>
        </div>
      </ModalFilterComponent>
    );
  else return null;
};

export default AdvancedFilterComponent;
