import { Dropdown } from 'primereact/dropdown';

import ModalFilterComponent from 'shared/components/modal-filter/ModalFilterComponent';
import { useAdvancedFilterDynamicFields } from 'features/configuration/hooks/dynamicFields/useAdvancedFilterDynamicFields';

export const AdvancedFilterComponent = () => {
  const {
    onToggleModal,
    clearFields,
    onAdvancedFilter,
    showModal,
    dataMaster,
    formValues,
    handleChange,
    showRemovePinerCompany: isSystem,
  } = useAdvancedFilterDynamicFields();

  if (showModal) {
    return (
      <ModalFilterComponent
      closeModal={() => onToggleModal(false)}
      clearFields={clearFields}
      handleSubmit={onAdvancedFilter}
      >
        <div className="flex pt-7" style={{minWidth: 400}}>
          <div className="w-full">
            <span className="p-float-label">
              <Dropdown
                disabled={!isSystem}
                options={dataMaster.companies}
                optionLabel="businessName"
                filter
                filterBy="businessName"
                showClear
                className="w-full"
                name="companyId"
                value={isSystem?formValues.companyId:dataMaster.companies[0]}
                onChange={handleChange}
              />

              <label htmlFor="companyId">Por Cliente</label>
            </span>
          </div>
        </div>
        <div className="flex pt-7">
          <div className="w-full">
            <span className="p-float-label">
              <Dropdown
                options={dataMaster.status}
                optionLabel="description"
                filter
                showClear
                className="w-full"
                name="statusId"
                value={formValues.statusId}
                onChange={handleChange}
              />

              <label htmlFor="statusId">Por Estado</label>
            </span>
          </div>
        </div>
      </ModalFilterComponent>
    );
  }

  return null;
};
