import React, { useState } from 'react'
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Controller } from 'react-hook-form';
import { Editor } from 'primereact/editor';
import HeaderEditor from 'shared/components/header-editor/HeaderEditor';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputTextarea } from 'primereact/inputtextarea';
import AttachmentInput from './AttachmentInput';
import './RenderedControls.scss'
import { Skeleton } from 'primereact/skeleton';
import { MultiSelect } from 'primereact/multiselect';
import ModalDataAndProcesses from './ModalDataAndProcesses';
import { ProcessOrDataCard } from './ProcessOrDataCard';
import ModalFormTaskComponent from 'features/customers/components/tasks/ModalFormComponent';
import { CoreConstants } from 'core/commons/CoreConstants';
import { RenderedTableControl } from './RenderedTableControl';

const RenderedControls = ({control, formValues, errors, controlInput, setValue, isLoadingProcessData, currentStepPermissions, currentStepPermissionsControls, workFlow, controlValidation}) => {

  const [displayModal, setDisplayModal] = useState(false)
  const [showTaskModal, setShowTaskModal] = useState(false)
  const header = HeaderEditor();

  let columnsField = {}
  if (control.type === 'TABLA') {
    control.details.forEach((detail) => {
      columnsField[detail.text] = detail.type=== 'FECHA_HORA' ? null : ''
    })
  }

  const permissionReadOnly = currentStepPermissionsControls?.includes(control.identifier) 
  ? currentStepPermissions.find((permission) => permission.identifierControl === control.identifier).isReadOnly
  : control.readonly

  const permissionHidden = currentStepPermissionsControls?.includes(control.identifier) 
  ? currentStepPermissions.find((permission) => permission.identifierControl === control.identifier).isHidden
  : false

  const updateRow = ({ id, value, name, type}) => {
    const currentRowsTableForm = formValues[control.identifier].map((row) => {
      if (row.id === id) {
        row[name] = value
        if (type === 'NUMERO' && value >= 0) row[name] = Number(value);
      }
      return row;
    });
    setValue(control.identifier, currentRowsTableForm)
  };

  const validateLength = (value) => {
    if (typeof value === 'string') {
      return value.replace(/<[^>]+>/g, '')?.length;
    } else if (value?.htmlValue) {
      return value.htmlValue.replace(/<[^>]+>/g, '').length;
    }
    return '0';
  };

  const skeletonArray = Array.from({ length: 4 });
  const bodyTemplateSkeleton = () => {
    return <Skeleton></Skeleton>
  }

  return (
    <>
      <div className='mt-2'>
        {
          (control.type === "TABLA" && !permissionHidden) && (
            (isLoadingProcessData || !workFlow) ? (
              <DataTable value={skeletonArray} className="p-datatable-striped my-4">
                <Column field="a" header="" style={{ width: '25%' }} body={bodyTemplateSkeleton}></Column>
                <Column field="b" header="" style={{ width: '25%' }} body={bodyTemplateSkeleton}></Column>
                <Column field="c" header="" style={{ width: '25%' }} body={bodyTemplateSkeleton}></Column>
                <Column field="d" header="" style={{ width: '25%' }} body={bodyTemplateSkeleton}></Column>
              </DataTable>
            ) : (
            <RenderedTableControl
              control={control}
              currentStepPermissions={currentStepPermissions}
              currentStepPermissionsControls={currentStepPermissionsControls}
              formValues={formValues}
              setValue={setValue}
              updateRow={updateRow}
              controlValidation={controlValidation}
            />
          ))
        }
        {
          (control.type === "ID" && !permissionHidden) && (
            (isLoadingProcessData || !workFlow) ? <Skeleton className='my-4' height='2rem' /> : (
            <Controller
              control={controlInput}
              // rules={{ required: control.required ? `${control.text} es requerido.` : false }}
              render={({
                  field: { onChange, value, onBlur },
              }) => (
                <span className="p-float-label my-5">
                  <InputText 
                    // value={`${control.prefix ? control.prefix + '-' : ''}${control.startSequence ? control.startSequence : ''}${control.sufix ? '-' + control.sufix : ''}`} 
                    value={``} 
                    onChange={onChange}
                    onBlur={onBlur}
                    // className={errors?.[control.identifier] ? 'p-invalid w-full' : 'w-full'}
                    // readOnly={control.readonly}
                    readOnly={permissionReadOnly}
                    disabled
                    className='disabled'
                  />
                  <label htmlFor={control.identifier}>{control.text}: {control.required ? '*' : ''}</label>
                </span>
              )}
              name={control.identifier}
          />
          ))
        }
        {
          control.type==="ETIQUETA" && ( 
            (isLoadingProcessData || !workFlow) ? <Skeleton className='my-4' height='2rem' /> : (
            <label className='font-semibold'>{control.text}</label> 
          ))
        }
        {
          (control.type==="TEXTO" && !permissionHidden) && (
              (isLoadingProcessData || !workFlow) ? <Skeleton className='my-4' height='2rem' /> : (
                <Controller
                  control={controlInput}
                  rules={{ required: control.required ? `${control.text} es requerido.` : false }}
                  render={({
                      field: { onChange, value, onBlur },
                  }) => (
                    <span className="p-float-label my-5">
                      <InputText 
                        value={formValues[control.identifier] ? formValues[control.identifier] : ""} 
                        onChange={onChange}
                        onBlur={onBlur}
                        // className={errors?.[control.identifier] ? 'p-invalid w-full' : 'w-full'}
                        className={`${permissionReadOnly && 'disabled'}  ${errors?.[control.identifier] ? 'p-invalid w-full' : 'w-full'}`}
                        // readOnly={ control.readonly}
                        readOnly={ permissionReadOnly }
                      />
                      <label htmlFor={control.identifier}>{control.text}: {control.required ? '*' : ''}</label>
                    </span>
                  )}
                  name={control.identifier}
                />  
                //* Usuario
                //// valuesFields?.numberOfEntry?.code === 'entry_single' ? (
                //   !control.showHeader ? (
                //   <span className="col-span-2 p-float-label w-full md:w-1/2 mt-4">
                //     {/* <Controller
                //         control={control}
                //         render={({
                //             field: { onChange, onBlur, value }
                //         }) => ( */}
                //           <Dropdown 
                //             // value={value} 
                //             options={temporalData} 
                //             // // options={control.details} 
                //             // onChange={onChange} 
                //             optionLabel="fullName" 
                //             placeholder='Seleccione el proceso que desea conectar'
                //             className={errors?.[control.identifier] ? 'p-invalid w-full' : 'w-full'}
                //             readOnly={ permissionReadOnly }
                //             disabled={ permissionReadOnly }
                //           />
                //     {/*      )}
                //          name="processToConnect"
                //      /> */}
                //   </span>
                // ) : (
                //   <span className="col-span-2 p-float-label w-full mt-4 config-process-controls">
                //     {/* <Controller
                //         control={control}
                //         render={({
                //             field: { onChange, onBlur, value }
                //         }) => ( */}
                //           <div className='w-full'>
                //             <MultiSelect 
                //               // value={value} 
                //               options={temporalData} 
                //               // onChange={(e) => onChange(e.value)} 
                //               optionLabel="fullName" 
                //               maxSelectedLabels={3} 
                //               placeholder='Seleccione el proceso que desea conectar'
                //               style={{width: '50%'}}
                //               className={errors?.[control.identifier] ? 'p-invalid w-full' : 'w-full'}
                //               readOnly={ permissionReadOnly }
                //               disabled={ permissionReadOnly }
                //             />
                //           </div>
                //         {/* )}
                //         name="processToConnect"
                //     /> */}
                //   </span>
                // )
                //* Procesos
                // <div className='mb-5 mt-2'>
                //   <p>{control.text}: {control.required ? '*' : ''}</p>
                //   <div 
                //     className='flex mt-2 controls-text-primary-color text-xs gap-x-1 items-center justify-start font-bold'
                //   >
                //     <i onClick={()=> setDisplayModal(true)} className="pi pi-plus text-xs font-bold cursor-pointer"></i>
                //     <p onClick={()=> setDisplayModal(true)} className='cursor-pointer'>Añadir Registro</p>
                //   </div>
                //   <ModalDataAndProcesses 
                //     displayModal={displayModal} setDisplayModal={setDisplayModal}
                //     title={control.text} 
                //     // title='Procesos'
                //   />
                // </div>
                //* Datos
              )
          )
        }
        {
          (control.type==="USUARIOS" && !permissionHidden) && (
              (isLoadingProcessData || !workFlow) ? <Skeleton className='my-4' height='2rem' /> : (
                // valuesFields?.numberOfEntry?.code === 'entry_single' ? (
                  !control.isMultiple ? (
                  <span className="col-span-2 p-float-label w-full md:w-1/2 mt-4">
                    <Controller
                        control={controlInput}
                        render={({
                            field: { onChange, onBlur, value }
                        }) => (
                          <Dropdown 
                            value={value} 
                            // options={temporalData} 
                            options={control.details} 
                            // // options={control.details} 
                            onChange={onChange} 
                            optionLabel="text" 
                            // placeholder='Seleccione el usuario'
                            placeholder={`${control.text}: ${control.required ? '*' : ''}`}
                            className={errors?.[control.identifier] ? 'p-invalid w-full' : 'w-full'}
                            readOnly={ permissionReadOnly }
                            disabled={ permissionReadOnly }
                          />
                      )}
                        name={control.identifier}
                    />  
                  </span>
                ) : (
                  <span className="col-span-2 p-float-label w-full mt-4 config-process-controls">
                    <Controller
                        control={controlInput}
                        render={({
                            field: { onChange, onBlur, value }
                        }) => (
                          <div className='w-full'>
                            <MultiSelect 
                              value={value} 
                              options={control.details} 
                              onChange={(e) => onChange(e.value)} 
                              optionLabel="text" 
                              maxSelectedLabels={3} 
                              placeholder='Seleccione el usuario'
                              style={{width: '50%'}}
                              className={errors?.[control.identifier] ? 'p-invalid w-full' : 'w-full'}
                              readOnly={ permissionReadOnly }
                              disabled={ permissionReadOnly }
                            />
                          </div>
                        )}
                        name={control.identifier}
                    /> 
                  </span>
                )
                //* Procesos
                // <div className='mb-5 mt-2'>
                //   <p>{control.text}: {control.required ? '*' : ''}</p>
                //   <div 
                //     className='flex mt-2 controls-text-primary-color text-xs gap-x-1 items-center justify-start font-bold'
                //   >
                //     <i onClick={()=> setDisplayModal(true)} className="pi pi-plus text-xs font-bold cursor-pointer"></i>
                //     <p onClick={()=> setDisplayModal(true)} className='cursor-pointer'>Añadir Registro</p>
                //   </div>
                //   <ModalDataAndProcesses 
                //     displayModal={displayModal} setDisplayModal={setDisplayModal}
                //     title={control.text} 
                //     // title='Procesos'
                //   />
                // </div>
                //* Datos
              )
          )
        }
        {
          (control.type==="CONEXION_A_DATOS" && !permissionHidden) && (
              (isLoadingProcessData || !workFlow) ? <Skeleton className='my-4' height='2rem' /> : (
                <div className='mb-5 mt-2'>
                  <p>{control.text}: {control.required ? '*' : ''}</p>
                  <div 
                    className='flex mt-2 controls-text-primary-color text-xs gap-x-1 items-center justify-start font-bold'
                  >
                    <i onClick={()=> setDisplayModal(true)} className="pi pi-plus text-xs font-bold cursor-pointer"></i>
                    <p onClick={()=> setDisplayModal(true)} className='cursor-pointer'>Añadir Registro</p>
                  </div>
                  {
                    formValues[control.identifier] && formValues[control.identifier].length > 0 && (
                      <div className='flex flex-col gap-y-2 mt-2'>
                        {
                          formValues[control.identifier].map((process, index) => (
                            <ProcessOrDataCard
                              process={process} 
                              key={process.id}
                            />
                          ))
                        }
                      </div>   
                    )
                  }
                  <ModalDataAndProcesses 
                    displayModal={displayModal} setDisplayModal={setDisplayModal}
                    title={control.text} 
                    dataDetails={control.details}
                    isMultiple={control.isMultiple}
                    setValue={setValue}
                    control={control.identifier}
                    formValues={formValues[control.identifier]}
                  />
                </div>
                //* Datos
              )
          )
        }
        {
          (control.type==="CONEXION_A_PROCESOS" && !permissionHidden) && (
              (isLoadingProcessData || !workFlow) ? <Skeleton className='my-4' height='2rem' /> : (
                <div className='mb-5 mt-2'>
                  <p>{control.text}: {control.required ? '*' : ''}</p>
                  <div 
                    className='flex mt-2 controls-text-primary-color text-xs gap-x-1 items-center justify-start font-bold'
                  >
                    <i onClick={()=> setDisplayModal(true)} className="pi pi-plus text-xs font-bold cursor-pointer"></i>
                    <p onClick={()=> setDisplayModal(true)} className='cursor-pointer'>Añadir Registro</p>
                  </div>
                  {/* <ModalDataAndProcesses 
                    displayModal={displayModal} setDisplayModal={setDisplayModal}
                    title={control.text} 
                    dataDetails={control.details}
                    isMultiple={control.isMultiple}
                  /> */}
                </div>
                
              )
          )
        }
        {
          (control.type === "TEXTO_LARGO" && !permissionHidden) && (
            (isLoadingProcessData || !workFlow) ? <Skeleton className='my-4' height='3.5rem' /> : (
            <Controller
              control={controlInput}
              rules={{ required: control.required ? `${control.text} es requerido.` : false }}
              render={({
                  field: { onChange, value, onBlur },
              }) => (
                <span className="p-float-label my-5">
                  <InputTextarea 
                    value={value} 
                    onChange={onChange} 
                    onBlur={onBlur}
                    rows={5} 
                    cols={30} 
                    // className={`${permissionReadOnly && 'disabled'}  ${errors?.[control.identifier] ? 'p-invalid w-full' : 'w-full'}`}
                    className={`${permissionReadOnly && 'disabled'} ${errors?.[control.identifier] ? 'p-invalid w-full' : 'w-full'}`}
                    // readOnly={control.readonly}
                    readOnly={ permissionReadOnly }
                  />
                <label htmlFor={control.identifier}>{control.text}: {control.required ? '*' : ''}</label>
                </span>
              )}
              name={control.identifier}
          />
          ))
        }
        {
          (control.type === "TEXTO_DINAMICO" && !permissionHidden) && (
            (isLoadingProcessData || !workFlow) ? <Skeleton className='my-4' height='4rem' /> : (
            <Controller
              control={controlInput}
              rules={{ required: control.required ? `${control.text} es requerido.` : false }}
              render={({
                  field: { onChange, value, onBlur },
              }) => (
                <>
                  <Editor
                    headerTemplate={header}
                    className={`${permissionReadOnly && 'disabled'} ${errors?.[control.identifier] ? 'p-invalid w-full survey-editor' : 'w-full survey-editor'}`}
                    value={value} onTextChange={(e) => onChange(e.htmlValue)}
                    placeholder={`${control.text}: ${control.required ? '*' : ''}`}
                    readOnly={ permissionReadOnly }
                  />
                  <p className="font-semibold text-base text-gray-500 lg:mt-1 mt-3 text-right">
                      Caracteres{' '}
                      {validateLength(formValues[control.identifier])}{' '}/ 4000{' '}
                  </p>
                </>
              )}
              name={control.identifier}
          />
          ))
        }
        {
          (control.type === "NUMERO" && !permissionHidden) && (
            (isLoadingProcessData || !workFlow) ? <Skeleton className='my-4' height='2rem' /> : (
              <Controller
                control={controlInput}
                rules={{ required: control.required ? `${control.text} es requerido.` : false }}
                render={({
                    field: { onChange, value, onBlur },
                }) => (
                  <span className="p-float-label my-5">
                    <InputText 
                      value={formValues[control.identifier] ? formValues[control.identifier] : ""} 
                      // onChange={(e) => onChange(Number(e.target.value))}
                      onChange={onChange}
                      onBlur={onBlur}
                      // className={errors?.[control.identifier] ? 'p-invalid w-full' : 'w-full'}
                      className={`${permissionReadOnly && 'disabled'}  ${errors?.[control.identifier] ? 'p-invalid w-full' : 'w-full'}`}
                      type="number"
                      // readOnly={control.readonly}
                      readOnly={ permissionReadOnly }
                    />
                    <label htmlFor={control.identifier}>{control.text}: {control.required ? '*' : ''}</label>
                  </span>
                )}
                name={control.identifier}
              />
          ))
        }
        {
          (control.type === "MONEDA" && !permissionHidden) && (
            (isLoadingProcessData || !workFlow) ? <Skeleton className='my-4' height='2rem' /> : (
              <Controller
                control={controlInput}
                rules={{ required: control.required ? `${control.text} es requerido.` : false }}
                render={({
                    field: { onChange, value, onBlur },
                }) => (
                  <div className="p-inputgroup  my-5 w-full lg:w-1/2">
                      <span className="p-inputgroup-addon process_currency_icon">$</span>
                      <span className="p-float-label">
                        <InputText 
                          value={formValues[control.identifier] ? formValues[control.identifier] : ""} 
                          // onChange={(e) => onChange(Number(e.target.value))}
                          onChange={onChange}
                          onBlur={onBlur}
                          // className={errors?.[control.identifier] ? 'p-invalid w-full process_currency' : 'w-full process_currency'}
                          className={`${permissionReadOnly && 'disabled'}  ${errors?.[control.identifier] ? 'p-invalid w-full process_currency' : 'w-full process_currency'}`}
                          // readOnly={control.readonly}
                          readOnly={ permissionReadOnly }
                          type="number"
                          id={control.identifier}
                          step="0.01"
                        />
                        <label htmlFor={control.identifier}>{control.text}: {control.required ? '*' : ''}</label>
                      </span>
                  </div>
                )}
                name={control.identifier}
            />
          ))
        }
        {
          (control.type==="LISTA_DESPLEGABLE" && !permissionHidden) && (
            (isLoadingProcessData || !workFlow) ? <Skeleton className='my-4' width='50%' height='2rem' /> : (
              <Controller
                control={controlInput}
                rules={{ required: control.required ? `${control.text} es requerido.` : false }}
                render={({
                    field: { onChange, value, onBlur },
                }) => (
                  <span className="p-float-label my-5 w-1/2">
                    <Dropdown 
                      options={control.details} 
                      optionLabel="text" 
                      // optionValue='id'
                      value={value}
                      onChange={onChange}
                      // className={errors?.[control.identifier] ? 'p-invalid w-full' : 'w-full'}
                      className={`${permissionReadOnly && 'disabled'}  ${errors?.[control.identifier] ? 'p-invalid w-full' : 'w-full'}`}

                      // readOnly={control.readonly}
                      readOnly={ permissionReadOnly }
                      disabled={ permissionReadOnly }
                    />
                    <label htmlFor={control.identifier}>{control.text}: {control.required ? '*' : ''}</label>
                  </span>
                )}
                name={control.identifier}
              />
            ))
        }
        {
          ((control.type==="FECHA" || control.type==="FECHA_HORA") && !permissionHidden) && (
            (isLoadingProcessData || !workFlow) ? <Skeleton className='my-4' width='50%' height='2rem' /> : (
            <Controller
              control={controlInput}
              rules={{ required: control.required ? `${control.text} es requerido.` : false }}
              render={({
                  field: { onChange, value, onBlur },
              }) => (
                <span className="p-float-label my-5">
                  <Calendar 
                    showTime={control.type==="FECHA_HORA"} 
                    showIcon={true}
                    value={value} 
                    onBlur={onBlur}
                    onChange={onChange}
                    // className={errors?.[control.identifier] ? 'p-invalid ' : ''}
                    className={`${permissionReadOnly && 'disabled'}  ${errors?.[control.identifier] ? 'p-invalid' : ''}`}
                    // readOnly={control.readonly}
                    // readOnlyInput={ permissionReadOnly }
                    disabled={ permissionReadOnly }
                    // La fecha no debe ser mayor a la fecha actual
                    maxDate={new Date()}
                  />
                  <label htmlFor={control.identifier}>{control.text}: {control.required ? '*' : ''}</label>
                </span>
              )}
              name={control.identifier}
            />
          ))
        }
        {
          (control.type==="SELECCION_SIMPLE" && !permissionHidden) && (
            (isLoadingProcessData || !workFlow) ? (
              <div className='flex flex-col'>
                <Skeleton className='my-2' width='50%' height='2rem' />
                <Skeleton className='my-2' width='20%' height='1.5rem' />
                <Skeleton className='my-2' width='20%' height='1.5rem' />
                <Skeleton className='my-2' width='20%' height='1.5rem' />
              </div>
            ) : (
            <Controller
              control={controlInput}
              rules={{ required: control.required ? `${control.text} es requerido.` : false }}
              render={({
                  field: { onChange, value },
              }) => (
                <div>
                  <p>{control.text}: {control.required ? '*' : ''}</p>
                  {
                    control.details.map(option => (
                        <div key={option.id} className="flex process-radio gap-x-3">
                          <RadioButton 
                            inputId={option.id} 
                            id={option.id} 
                            checked={formValues[control.identifier]?.id === option.id} 
                            value={option} 
                            onChange={(e) => {
                              // onChange(Number(e.target.value))
                              onChange(option)
                            }}
                            className={errors?.[control.identifier] ? 'p-invalid' : ''}
                            // readOnly={control.readonly}
                            // readOnly={ permissionReadOnly }
                            disabled={ permissionReadOnly }

                          />
                          {/* <input 
                            type="radio" 
                            id={option.id} 
                            checked={formValues[control.identifier]?.id === option.id} 
                            value={option} 
                            onChange={(e) => {
                              // onChange(Number(e.target.value))
                              onChange(option)
                            }}
                            className={errors?.[control.identifier] ? 'process_radio_input border border-red-500' : 'process_radio_input border border-red-500 bg-red-500'}
                            readOnly={control.readonly}
                            style={{width: 'auto'}}
                          /> */}
                          <label htmlFor={option.id} className='flex-1'>{option.text}</label>
                        </div>
                    ))
                  }
                </div>
              )}
              name={control.identifier}
            />
          ))
        }
        {
          //* Solo almacena ids en el array de valores
          (control.type==="SELECCION_MULTIPLE" && !permissionHidden) && (
            (isLoadingProcessData || !workFlow) ? (
              <div className='flex flex-col'>
                <Skeleton className='my-2' width='50%' height='2rem' />
                <Skeleton className='my-2' width='20%' height='1.5rem' />
                <Skeleton className='my-2' width='20%' height='1.5rem' />
                <Skeleton className='my-2' width='20%' height='1.5rem' />
              </div>
            ) : (
            <Controller
              control={controlInput}
              rules={{ required: control.required ? `${control.text} es requerido.` : false }}
              render={({
                  field: { onChange, value, onBlur },
              }) => (
                  <div className='w-full flex flex-col items-start gap-y-1'>
                    <p>{control.text}: {control.required ? '*' : ''}</p>
                    {
                      control.details.map(option=> {
                        return (
                          <div key={option.id} className='flex justify-center items-center gap-x-2'>
                            <Checkbox 
                              id={option.id} 
                              inputId={option.id} 
                              name={control.identifier} 
                              value={option} 
                              onChange={(e) => {
                                if(formValues[control.identifier].length === 0){
                                  // onChange([e.value])
                                  onChange([option])
                                }else{
                                  if(e.checked){
                                    // onChange([...formValues[control.identifier], e.value])
                                    onChange([...formValues[control.identifier], option])
                                  }else{
                                    // onChange(formValues[control.identifier].filter(element=>element!==e.value))
                                    onChange(formValues[control.identifier].filter(element=> element.id !== option.id))
                                  }
                                }
                              }}
                              onBlur={onBlur}
                              className={errors?.[control.identifier] ? 'p-invalid' : ''}
                              // checked={formValues[control.identifier]?.includes(option.id)}
                              checked={formValues[control.identifier]?.some(element => element.id === option.id)}
                              // readOnly={control.readonly}
                              readOnly={ permissionReadOnly }
                            />
                            <p htmlFor={option.id}>{option.text}</p>
                          </div>
                        )
                      })
                    }
                  </div>
                )}
                name={control.identifier}
            />
          ))
        }
        {
          ((control.type === "ADJUNTAR_ARCHIVO" || control.type === "IMAGEN")  && !permissionHidden) && (
            (isLoadingProcessData || !workFlow) ? <Skeleton className='my-4' height='3.5rem' /> : (
            <Controller
              control={controlInput}
              rules={{ required: control.required ? `${control.text} es requerido.` : false }}
              render={({
                field: { onChange, value },
              }) => (
                <>
                  <p>{control.text}: {control.required ? '*' : ''}</p>
                  <AttachmentInput 
                    typeControl={control.type} 
                    // readOnly={control.readonly}  
                    readOnly={permissionReadOnly}
                    onChange={onChange}
                    errors={errors?.[control.identifier]}
                    setValue={setValue}
                    identifier={control.identifier}
                    valuesField={formValues}
                  />
                </>
                )}
              name={control.identifier}
            />
          ))
        } 
      </div>
      <ModalFormTaskComponent
        isOpen={showTaskModal} 
        onClose={() => {        
          setShowTaskModal(false);
          // if(!isNewProject) updateTasksFromDeliverable(idDeliverableShow);
        }} 
        sourceDocumentCode={CoreConstants.Task.Source.PROJECT}
        // sourceDocumentId={idDeliverableShow}
        // taskId={idTaskShow}
      />
    </>
  )
}

export default RenderedControls