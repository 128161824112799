import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import 'shared/styles/components/modals.scss';
// import './SuccessRecoveryPasswordPage.scss'
import { CoreConstants } from 'core/commons/CoreConstants';
import { useHistory } from 'react-router';

const SuccessRecoveryPasswordModal = ({ isOpen }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onHide = () => {
    dispatch({ type: CoreConstants.Accion.FeedBack.OCULTAR_MENSAJE });
    dispatch({
      type: CoreConstants.Accion.Toast.OCULTAR_MENSAJE,
    });
  };

  const redirectTo = () => {
    history.push('/seguridad/inicio-sesion');
    onHide();
  };

  const feedback = useSelector((state) => state.FeedBackReducer.feedBack);
  return (
    feedback !== null && (
      <Dialog
        visible={isOpen}
        onHide={onHide}
        style={{ width: '24vw' }}
        className="modal-custom"
        breakpoints={{ '1200px': '45vw', '640px': '80vw' }}
      >
        <div className="form-modal">
          <header className="header">
            <div className="title">
              <h3 className="mb-2 font-bold">{feedback.titulo}</h3>
            </div>
            <div className="description text-left">
              <p className="text-sm">{feedback.mensaje}</p>
            </div>
          </header>

          <div className="actions mt-3">
            <Button
              onClick={redirectTo}
              label={feedback.btnText}
              className="btn btn-primary"
            />
          </div>
        </div>
      </Dialog>
    )
  );
};

SuccessRecoveryPasswordModal.defaultProps = {
  isOpen: false,
};

SuccessRecoveryPasswordModal.propTypes = {
  isOpen: PropTypes.bool,
};

export default SuccessRecoveryPasswordModal;
