export class SurveysConstants {
  static Accion = class {

    //SURVEY  CONSTANTS
    static Survey = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return '@SURVEY.ADVANCED-FILTER-TOGGLE';
      }

      static get SEARCH_SURVEYS() {
        return '@SURVEY.SEARCH_SURVEYS';
      }

      static get GET_DATA_LIST_FOLDERS() {
        return '@SURVEY.GET_DATA_LIST_FOLDERS';
      }

      static get UPDATE_FILTER() {
        return '@SURVEY.UPDATE_FILTER';
      }

      static get UPDATE_FILTER_SURVEY_ANSWERS() {
        return '@SURVEY.UPDATE_FILTER_SURVEY_ANSWERS';
      }

      static get RESET_AFTER_SAVED() {
        return '@SURVEY.RESET_AFTER_SAVED';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@SURVEY.DOWNLOAD_REPORT_STATUS';
      }

      static get SAVE_SURVEY() {
        return '@SURVEY.SAVE_SURVEY';
      }

      static get GET_DATA_MASTER() {
        return '@SURVEY.GET_DATA_MASTER';
      }

      static get GET_SURVEY_BY_ID() {
        return '@SURVEY.GET_SURVEY_BY_ID';
      }

      static get GET_SURVEY_COPY_BY_ID() {
        return '@SURVEY.GET_SURVEY_COPY_BY_ID';
      }

      static get GET_SURVEY_BY_GUID() {
        return '@SURVEY.GET_SURVEY_BY_GUID';
      }

      static get SURVEY_SHARE() {
        return '@SURVEY.SURVEY_SHARE';
      }

      static get SAVE_SURVEY_ANSWER() {
        return '@SURVEY.SAVE_SURVEY_ANSWER';
      }

      static get RESET_AFTER_CLOSE_MODAL_ANALYSIS() {
        return '@SURVEY.RESET_AFTER_CLOSE_MODAL_ANALYSIS';
      }

      static get GET_SURVEY_SUMMARY_BY_ID() {
        return '@SURVEY.GET_SURVEY_SUMMARY_BY_ID';
      }

      static get GET_SURVEY_TREND_INFO_BY_ID() {
        return '@SURVEY.GET_SURVEY_TREND_INFO_BY_ID';
      }

      static get GET_SURVEY_TOTAL_BY_QUESTION() {
        return '@SURVEY.GET_SURVEY_TOTAL_BY_QUESTION';
      }

      static get GET_SURVEY_INFORMATION_BY_ID() {
        return '@SURVEY.GET_SURVEY_INFORMATION_BY_ID';
      }

      static get GET_SURVEY_ANSWER_PER_QUESTION() {
        return '@SURVEY.GET_SURVEY_ANSWER_PER_QUESTION';
      }

      static get GENERATE_SURVEY_TREND_INFO_REPORT() {
        return '@SURVEY.GENERATE_SURVEY_TREND_INFO_REPORT';
      }

      static get GENERATE_SURVEY_TOTAL_BY_QUESTION_REPORT() {
        return '@SURVEY.GENERATE_SURVEY_TOTAL_BY_QUESTION_REPORT';
      }

      static get GENERATE_SURVEY_ANSWER_PER_QUESTION_REPORT() {
        return '@SURVEY.GENERATE_SURVEY_ANSWER_PER_QUESTION_REPORT';
      }

      static get SEARCH_CONTACTS() {
        return '@SURVEY.SEARCH_CONTACTS';
      }

      static get SHARE_SEND_BY_EMAIL() {
        return '@SURVEY.SHARE_SEND_BY_EMAIL';
      }

      static get DELETE_SURVEY() {
        return '@SURVEY.DELETE_SURVEY';
      }
      static get CLOSE_SURVEY() {
        return '@SURVEY.CLOSE_SURVEY';
      }
      
      static get SET_CURRENT_PATH() {
        return '@SURVEY.SET_CURRENT_PATH';
      }

      static get SET_SURVEY_STATUS() {
        return '@SURVEY.SET_SURVEY_STATUS';
      }
      static get GET_SURVEY_ANSWERS() {
        return '@SURVEY.GET_SURVEY_ANSWERS';
      }
      static get GET_SURVEY_ANSWER_BY_ID() {
        return '@SURVEY.GET_SURVEY_ANSWER_BY_ID';
      }
      static get DELETE_SURVEY_RECIPIENT() {
        return '@SURVEY.DELETE_SURVEY_RECIPIENT';
      }

    };

    //SURVEY MANAGEMENT  CONSTANTS
    static SurveyManagement = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return '@SURVEY_MANAGEMENT.ADVANCED-FILTER-TOGGLE';
      }

      static get SEARCH_SURVEY_MANAGEMENT() {
        return '@SURVEY_MANAGEMENT.SEARCH_SURVEY_MANAGEMENT';
      }

      static get UPDATE_FILTER() {
        return '@SURVEY_MANAGEMENT.UPDATE_FILTER';
      }

      static get RESET_AFTER_SAVED() {
        return '@SURVEY_MANAGEMENT.RESET_AFTER_SAVED';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@SURVEY_MANAGEMENT.DOWNLOAD_REPORT_STATUS';
      }

      static get CREATE_FOLDER() {
        return '@SURVEY_MANAGEMENT.CREATE_FOLDER';
      }

      static get DELETE_FOLDER() {
        return '@SURVEY_MANAGEMENT.DELETE_FOLDER';
      }
      
      static get RENAME_FOLDER() {
        return '@SURVEY_MANAGEMENT.RENAME_FOLDER';
      }

      static get GET_DATA_MASTER() {
        return '@SURVEY_MANAGEMENT.GET_DATA_MASTER';
      }

      static get GET_SURVEY_MANAGEMENT_BY_ID() {
        return '@SURVEY_MANAGEMENT.GET_SURVEY_MANAGEMENT_BY_ID';
      }

      static get GET_LIST_FOLDERS() {
        return '@SURVEY_MANAGEMENT.GET_LIST_FOLDERS';
      }

      static get GET_LIST_FOLDERS_TABLE() {
        return '@SURVEY_MANAGEMENT.GET_LIST_FOLDERS_TABLE';
      }
      
      static get SET_CURRENT_PATH() {
        return '@SURVEY_MANAGEMENT.SET_CURRENT_PATH';
      }

      // PERMISSIONS
      static get GET_PERMISSIONS_BY_ID() {
        return '@SURVEY_MANAGEMENT.GET_PERMISSIONS_BY_ID';
      }
      static get GET_PERMISSION_BY_PATH() {
        return '@SURVEY_MANAGEMENT.GET_PERMISSION_BY_PATH';
      }
      static get SAVE_PERMISSIONS() {
        return '@SURVEY_MANAGEMENT.SAVE_PERMISSIONS';
      }

    };

  };

  static SurveyStatus = class {
    static get PUBLISHED() {
      return 'SQPB';
    }
    static get DRAFT() {
      return 'SQDR';
    }
    static get CLOSED() {
      return 'SQCL';
    }
  }
}
