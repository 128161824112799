import { HomeActions } from "../../store/actions/HomeAction";
import { useSelector, useDispatch } from 'react-redux';
import ModalFilterComponent from 'shared/components/modal-filter/ModalFilterComponent';
import { Calendar } from 'primereact/calendar';
import { useEffect, useState } from 'react';
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from 'primereact/fieldset';
import { ListBox } from 'primereact/listbox';
import { addLocale, locale } from "primereact/api";
import { CoreConstants } from "core/commons/CoreConstants";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import "./AdvacedFilterComponents.scss";
import { MultiSelect } from "primereact/multiselect";


const AdvancedFilterComponent = () => {
    const dispatch = useDispatch();
    const [formValues, setValues] = useState({
        from: null,
        to: null,
        business: null,
        customers: null,
        projects: null,
        taskSource: null,
        taskStatus: null,
        responsibles: null,
    });

    const advancedFilter = useSelector((state) => state.homeReducer.advancedFilter);
    const { showModal } = useSelector((state) => state.homeReducer.advancedFilter);

    const dataMaster = useSelector((state) => state.homeReducer.optionsDropDown.data);

    const { roles } = useSelector(state => state.authReducer.user);
    const isAdmin = roles.some( rol => rol.codigo === CoreConstants.Roles.ADMIN )

    // console.log(advancedFilter)

    // console.log(dataMaster)
    useEffect(() => {
        setValues({
            from: advancedFilter.filter.from,
            to: advancedFilter.filter.to,
            business: advancedFilter.filter.business,
            customers: advancedFilter.filter.customers,
            projects: advancedFilter.filter.projects,
            taskSource: advancedFilter.filter.taskSource,
            taskStatus: advancedFilter.filter.taskStatus,
            responsibles: advancedFilter.filter.responsibles,
        });
        // dispatch(HomeActions.getOptionsDropDown());
    }, [advancedFilter]);

    useEffect(() => {
        dispatch(HomeActions.getOptionsDropDown());
    },[])


    const clearFields = () => {
        setValues({
            from: null,
            to: null,
            business: null,
            customers: null,
            projects: null,
            taskSource: null,
            taskStatus: null,
            responsibles: null,
        });
    };

    const closeModal = () => {
        dispatch(HomeActions.toggleAdvancedFilter({ showModal: false }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const fields = formValues;

        if (fields?.from)
            advancedFilter.filter.from = fields.from;
        else
            advancedFilter.filter.from = null;


        if (fields?.to)
            advancedFilter.filter.to = fields.to;
        else
            advancedFilter.filter.to = null;


        if (fields?.business)
            advancedFilter.filter.business = fields.business;
        else 
            advancedFilter.filter.business = null;


        if (fields?.customers)
            advancedFilter.filter.customers = fields.customers;
        else
            advancedFilter.filter.customers = null;


        if (fields?.projects)
            advancedFilter.filter.projects = fields.projects;
        else
            advancedFilter.filter.projects = null;

        if (fields?.taskSource)
            advancedFilter.filter.taskSource = fields.taskSource;
        else
            advancedFilter.filter.taskSource = null;

        if (fields?.taskStatus)
            advancedFilter.filter.taskStatus = fields.taskStatus;
        else
            advancedFilter.filter.taskStatus = null;

        if (fields?.responsibles)
            advancedFilter.filter.responsibles = fields.responsibles.length > 0 ? fields.responsibles: null;
        else
            advancedFilter.filter.responsibles = null;

        dispatch(HomeActions.updateFilter(advancedFilter));
        dispatch(HomeActions.search());
        dispatch(HomeActions.toggleAdvancedFilter({ showModal: false }));
    }

    const onChangeFrom = (e) => {
        if (e.value)
            formValues.to = null;
        setValues({
            ...formValues,
            [e.target.name]: e.value,
        });
    }

    const onChangeTo = (e) => {
        setValues({
            ...formValues,
            [e.target.name]: e.value,
        });
    }

    const onChangeBusiness = (e) => {
        setValues({
            ...formValues,
            'business': e.value,
        });
    }

    const onChangeProjects = (e) => {
        setValues({
            ...formValues,
            'projects': e.value,
        });
    }

    const onChangeCustomers = (e) => {
        setValues({
            ...formValues,
            'customers': e.value,
        });
    }

    const onChangeTaskSource = (e) => {
        setValues({
            ...formValues,
            'taskSource': e.value,
        });
    }

    const onChangeTaskStatus = (e) => {
        setValues({
            ...formValues,
            'taskStatus': e.value,
        });
    }

    const onChangeTaskResponsibles = (e) => {
      setValues({
          ...formValues,
          'responsibles': e.value,
      });
    }

    const responsibleTemplate = ({id, fullName}) => {
      const firstLetter = (fullName + " ").split(' ')[0][0]?.toUpperCase() || ""
      const secondLetter = (fullName + " ").split(' ')[1][0]?.toUpperCase() || ""
      const initialLetter = firstLetter + secondLetter
      const isSelected = formValues.responsibles?.some( (responsible) => responsible.id === id)
      return (
        <div className="flex align-items-center border px-4 py-2 p-overlay-badge hover:bg-gray-100 select-none">
          {
            isSelected &&
            <Badge
              value={<i className="pi pi-check" style={{ color: 'white', fontSize: "0.75rem", background: "#05B797" }}></i>}
              style={{background: "#05B797"}}
            />
          }
          <Avatar
            shape="circle"
            label={initialLetter}
            size="normal"
            style={{ backgroundColor: '#110E99', color: '#ffffff' }}
          />
          <div className="flex items-center pl-4 font-bold select-none">
            {fullName}
          </div>
        </div>
      );
  };

    if (showModal)
        return (

            <ModalFilterComponent
                disabledBtn={false}
                closeModal={closeModal}
                clearFields={clearFields}
                handleSubmit={handleSubmit}
                searchLoading={advancedFilter?.loading}
            >

                <div className="flex gap-4 pt-3">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                value={formValues.from}
                                name="from"
                                id="desde"
                                onChange={(e) => onChangeFrom(e)}
                            />
                            <label htmlFor="desde">Desde</label>
                        </span>
                    </div>
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                value={formValues.to}
                                name="to"
                                id="hasta"
                                minDate={formValues?.from}
                                onChange={(e) => onChangeTo(e)}
                            />
                            <label htmlFor="hasta">Hasta</label>
                        </span>
                    </div>

                </div>

        
                <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Dropdown
                                options={dataMaster?.business}
                                optionLabel="code"
                                value={formValues?.business}
                                onChange={(e) => { onChangeBusiness(e) }}
                                filter
                                showClear
                                filterBy="code"
                                className="w-full"
                            />

                            <label htmlFor="dropdown">Por Negocio</label>
                        </span>
                    </div>
                </div>

                <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Dropdown
                                options={dataMaster?.projects}
                                optionLabel="name"
                                value={formValues?.projects}
                                onChange={(e) => { onChangeProjects(e) }}
                                filter
                                showClear
                                filterBy="name"
                                className="w-full"
                            />

                            <label htmlFor="dropdown">Por Proyectos</label>
                        </span>
                    </div>
                </div>

                <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Dropdown
                                options={dataMaster?.customers}
                                optionLabel="name"
                                value={formValues?.customers}
                                onChange={(e) => { onChangeCustomers(e) }}
                                filter
                                showClear
                                filterBy="name"
                                className="w-full"
                            />

                            <label htmlFor="dropdown">Por Clientes</label>
                        </span>
                    </div>
                </div>

                <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Dropdown
                                options={dataMaster?.taskSources}
                                optionLabel="description"
                                value={formValues?.taskSource}
                                onChange={(e) => { onChangeTaskSource(e) }}
                                filter
                                showClear
                                filterBy="description"
                                className="w-full"
                            />

                            <label htmlFor="dropdown">Por Origen</label>
                        </span>
                    </div>
                </div>

                <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Dropdown
                                options={dataMaster?.taskStatus}
                                optionLabel="description"
                                value={formValues?.taskStatus}
                                onChange={(e) => { onChangeTaskStatus(e) }}
                                filter
                                showClear
                                filterBy="description"
                                className="w-full"
                            />

                            <label htmlFor="dropdown">Por Estado</label>
                        </span>
                    </div>
                </div>

                {
                  isAdmin &&
                  <div className="flex pt-7" id="filterAdvancedHome">
                    <div className="w-full">
                      <span className="p-float-label">
                        <MultiSelect
                          filter
                          showClear
                          filterBy="fullName"
                          style={{minHeight: 40}} 
                          value={formValues.responsibles} 
                          options={dataMaster.responsible} 
                          onChange={onChangeTaskResponsibles} 
                          optionLabel="fullName" 
                          className="w-full"
                          display="chip"
                        />
                        <label htmlFor="dropdown">Por Responsables</label>
                      </span>
                    </div>
                  </div>
                }

                {/* {
                  isAdmin && 
                  <fieldset
                    id="filterAdvancedHome"
                    className="mt-6"
                    style={{
                      border: "1px solid #dee2e6",
                      background: "#ffffff",
                      color: "#495057",
                      borderRadius: "3px",
                      padding: "revert",
                    }}>
                    <legend
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding:" 0px 5px"
                      }}
                    >Responsables</legend>
                    <ListBox
                      style={{border: "0px"}}
                      multiple
                      options={dataMaster.responsible}
                      optionLabel="fullName"
                      onChange={onChangeTaskResponsibles}
                      value={formValues.responsibles}
                      itemTemplate={responsibleTemplate}
                    />
                  </fieldset>
                } */}

            </ModalFilterComponent>
        );
    else
        return null;
}

export default AdvancedFilterComponent;