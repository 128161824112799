import useModalGenerateFormula from 'features/configuration/hooks/processes/workflow/useModalGenerateFormula'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { RadioButton } from 'primereact/radiobutton'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

// const options = [
//   { label: '+', value: 'Option 1' },
//   { label: '-', value: 'Option 1' },
//   { label: '*', value: 'Option 1' },
//   { label: '/', value: 'Option 1' },
//   { label: '%', value: 'Option 1' },
//   { label: '^', value: 'Option 1' },
//   { label: '=', value: 'Option 1' },
//   { label: '<', value: 'Option 1' },
//   { label: '>', value: 'Option 1' },
//   { label: '<=', value: 'Option 1' },
//   { label: '>=', value: 'Option 1' },
//   { label: '!=', value: 'Option 1' },
//   { label: 'AND', value: 'Option 1' },
//   { label: 'OR', value: 'Option 1' },
// ]

const Field = ({ name, row, updateFormula, logicalOperators, conditionalOperators, listFields }) => {
  if (name === 'value') {
      return (
          <InputText 
              type='text'
              placeholder='Valor *'
              name={name}
              // value={row[name].toFixed(2)}
              value={row[name]}
              className='text-right'
              onChange={(e) => {
                  updateFormula({
                      id: row.id,
                      value: e.target.value,
                      name: name,
                  })}
              }
              // onBlur={() => onBlurAmount(row.id)}
              // step='0.01'
          />
      )
  } else if (name === 'identifierControl') {
    return <Dropdown 
      options={listFields} 
      placeholder="Campo *" 
      optionValue='identifier' 
      optionLabel="identifier" 
      // optionLabel="fieldLabel" 
      value={row[name]}
      onChange={(e) => {
        updateFormula({
          id: row.id,
          value: e.value,
          name: name,
        })}
      }/>
  } else if (name === 'conditionalOperatorId') {
    return <Dropdown 
      options={conditionalOperators} 
      placeholder="Condición *" 
      optionValue='id' 
      optionLabel="description" 
      value={row[name]}
      onChange={(e) => {
        updateFormula({
          id: row.id,
          value: e.value,
          name: name,
        })}
      }/>
  } else if (name === 'operator') {
    return <Dropdown 
      options={logicalOperators} 
      placeholder="Operador *" 
      optionValue='id' 
      optionLabel="description" 
      value={row[name]}
      onChange={(e) => {
        updateFormula({
          id: row.id,
          value: e.value,
          name: name,
        })}
      }/>
  } else {
    return (
        <InputText 
            type='number'
            placeholder='Color *'
            name={name}
            // value={row[name].toFixed(2)}
            value={row[name]}
            className='text-right'
            onChange={(e) => {
                updateFormula({
                    id: row.id,
                    value: e.target.value,
                    name: name,
                })}
            }
            // onBlur={() => onBlurAmount(row.id)}
            step='0.01'
        />
    )
}
};

const ModalGenerateFormula = ({displayModal, setDisplayModal, listFields, stepMethods, setModalValue, stepSelected}) => {
  const [visible, setVisible] = useState(false);
  const [idDelete, setIdDelete] = useState(0);
  const [isSended, setIsSended] = useState(false)

  const onHide = () => {
    stepMethods.cleanConditionsForDelete(stepSelected)
    setDisplayModal(false)
  }

  const {logicalOperators, conditionalOperators} = useSelector(state => state.processReducer.listResponsibles.data)

  const [operatorSelected, setOperatorSelected] = useState(logicalOperators[0])

  const {
    formulasList, setFormulasList, formulasError, setFormulasError,
    createFormula, deleteFormula, updateFormula,
    existRowEmpty
  } = useModalGenerateFormula();
   
  const reject = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (stepSelected && displayModal) {
      // console.log(stepSelected)
      setFormulasList(stepSelected.conditions)
    }
  }, [stepSelected, displayModal, setFormulasList])

  // console.log(formulasList)

  const columns = [
    { field: 'identifierControl', header: 'Campo *' },
    { field: 'conditionalOperatorId', header: 'Condición *' },
    { field: 'value', header: 'Valor *' },
    // { field: 'operator', header: 'Operador' },
    // { field: 'color', header: 'Color' },
  ];

  const ColumnComponents = columns.map((col) => {
    if (col.field === 'identifierControl' || col.field === 'conditionalOperatorId' || col.field === 'value') {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                sortable={col.field === 'identifierControl' ? true : false}
                style={{width: '23%'}}
                body={(row) => <Field name={col.field} row={row} updateFormula={updateFormula} logicalOperators={logicalOperators} conditionalOperators={conditionalOperators} listFields={listFields} 
              />}
            />
        );
    }
    
    return <Column key={col.field} field={col.field} header={col.header} />;
  });

  const deletePermissionConfirm = () => {
    deleteFormula(idDelete);
    if (isNaN(idDelete)) return
    stepMethods.setConditionsForDelete(idDelete, stepSelected)
  };

  const RowDelete = ({ id }) => {
    return (
        <i
          onClick={() => {
              setVisible(true);
              setIdDelete(id);
          }}
          className="pi pi-trash cursor-pointer text-center text-lg"
        ></i>
    );
  };

  const getModelFormulas = (formulas) => {
    // const modelPayments = formulas.map((payment) => {
    //   const {id, fileBase64, file, ...modelPayment} = payment
    //   modelPayment.value = Number(payment.value)
    //   modelPayment.attachmentDocument = fileBase64 ? fileBase64 : null
        
    //   return modelPayment
    // })
    // return modelPayments
    return formulas
  }

  const onSubmit = (data) => {
    setIsSended(true)
    const { isRowEmpty, errorRows } = existRowEmpty(formulasList)
    if (isRowEmpty) {
      setFormulasError(errorRows)
      return
    }
    
    const model = {
      formulasList: getModelFormulas(formulasList),
      operatorSelected: operatorSelected.id
    }
    // console.log(model)
    setModalValue('conditions', model.formulasList)
    setModalValue('logicalOperatorId', model.operatorSelected)
    // onHide()
    setDisplayModal(false)
    // stepMethods.saveConditions(model)
    
    setFormulasError([])
  };

  return (
    <Dialog header={'Generador de fórmulas'} visible={displayModal} className={`w-4/5`} onHide={onHide}>
      <form 
        className="form-custom p-0"
        onSubmit={onSubmit}
      >
        <div className="border-bottom-modal">
            <div className='mt-2 mb-3'>
                <Button
                    icon="pi pi-plus"
                    type="button"
                    label="Añadir Condición"
                    onClick={() => {
                      createFormula()
                      // setIsSended(false)
                    }}
                    className="sig-button sig-primary "
                />
            </div>
            <div className='flex my-2 gap-x-2'>
              <p>Seleccione un operador logico: </p>
              {
                  logicalOperators.map((operator) => {
                      return (
                          <div key={operator.id} className="field-radiobutton flex items-center gap-x-1">
                              <RadioButton 
                                inputId={operator.id} 
                                name="operator" 
                                value={operator} 
                                onChange={(e) => setOperatorSelected(e.value)} checked={operatorSelected.id === operator.id} 
                              />
                              <label htmlFor={operator.key}>{operator.description}</label>
                          </div>
                      )
                  })
              }
            </div>
            {
              <div className="courses-expenses-table mb-3">
                  <DataTable
                      value={formulasList}
                      emptyMessage="No se encontraron gastos del proyecto"
                      scrollable scrollHeight="320px" 
                      // loading={courseExpense.isLoading}
                      stripedRows
                      editMode="row"
                      dataKey="id"
                      rowHover
                      size="small"
                      responsiveLayout="scroll"
                      className='text-center'
                  >
                      {ColumnComponents}
                      <Column header={<div></div>} body={RowDelete} style={{width: '8%'}}></Column>
                  </DataTable>
              </div>
            }
              {
                formulasError && (
                  <div className='flex justify-center'>
                    {
                      (formulasError.length > 0 && isSended) && (
                        <div className='message error w-full'>
                          {
                            formulasError.map((error) => (
                              <li className="p-error">{`Fila ${error.errorRow}: Debe ingresar ${error.errorField}`}</li>
                            ))
                          }
                        </div>
                      )
                    }
                  </div>
                )
              }
        </div>
        <div className="flex justify-end gap-4 mt-3">
            <Button
                icon="pi pi-times"
                type="button"
                label="Cancelar"
                onClick={onHide}
                className="sig-button sig-secondary mt-4"
            />
            <Button
                icon="pi pi-save"
                type="button"
                onClick={onSubmit}
                label="Guardar"
                // loading={document?.isSaving || document?.isLoading}
                className="sig-button sig-primary mt-4"
            />
        </div>
      </form>
      <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea eliminar la condición?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={() => deletePermissionConfirm()}
          reject={() => setVisible(false)}
      />
    </Dialog>
  )
}

export default ModalGenerateFormula