export class EvaluationConstants {
  static Accion = class {
    static Evaluation = class {
      static get ADVANCED_FILTER_TOGGLE(){
        return '@EVALUATION.ADVANCED_FILTER_TOGGLE';
      };
			static get ADVANCED_FILTER() {
        return '@EVALUATION.ADVANCED-FILTER';
      };
      static get UPDATE_FILTER(){
        return '@EVALUATION.UPDATE_FILTER';
      };
      static get GET_DATA_MASTER() {
        return '@EVALUATION.GET_DATA_MASTER';
      };
			static get SEARCH_EVALUATIONS() {
        return '@EVALUATION.SEARCH_EVALUATIONS';
      };
			static get GET_DATA_BY_ID() {
        return '@EVALUATION.GET_DATA_BY_ID';
      };
			static get REPORT_STATUS() {
        return '@EVALUATION.REPORT_STATUS';
      };
      static get GET_EVALUATIONS_BY_ID() {
        return '@EVALUATION.GET_EVALUATIONS_BY_ID';
      };
		};
	};
};