import { CoreConstants } from "core/commons/CoreConstants";
import { TasksConstants } from "features/customers/commons/TasksConstants";
import { TaskService } from "features/customers/services/TaskService";
import { toastAction } from "core/store/actions/ToastAction";
import { ProjectAction } from "./ProjectAction";
import { ProjectsActions } from "features/projects/store/actions/ProjectsActions";
import FileSaver from "file-saver";
import moment from "moment";

const setTasks = (fields) => {
  return (dispatch) =>
    dispatch({
      type: TasksConstants.Action.Tasks.SEARCH,
      payload: { ...fields },
    });
};

const setTaskComments = (fields) => {
  return (dispatch) =>
    dispatch({
      type: TasksConstants.Action.Comments.SEARCH,
      payload: { ...fields },
    });
};

const getTasksByCustomer = (fields) => {
  return (dispatch) => {
    dispatch(setTasks({ loading: true }));
    TaskService.searchTaskByCustomer(fields)
      .then(({ data }) => {
        const status = data?.status ?? "";
        if (status === CoreConstants.HttpResponse.OK) {
          const { results, ...rest } = data.data;

          results.forEach((result) => {
            result.scheduleDate = moment(
              result.scheduleDate.substring(0, 10)
            ).format(CoreConstants.Format.LOCALE);
          });

          let currentFields = {
            loading: false,
            data: results,
            pagination: { ...rest },
          };
          if (fields?.fields?.order) {
            currentFields.order = fields.fields.order;
          }
          if (fields?.fields?.columnOrder) {
            currentFields.columnOrder = fields.fields.columnOrder;
          }
          dispatch(setTasks(currentFields));
          return;
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setTasks({ loading: false }));
      });
  };
};

const getTasksByFields = async (fields) => {
  return await TaskService.searchTaskByCustomer(fields)
    .then(({ data }) => {
      const status = data?.status ?? "";
      if (status === CoreConstants.HttpResponse.OK) {
        const { results, ...rest } = data.data;

        results.forEach((result) => {
          result.scheduleDate = result.scheduleDate
            ? moment(result.scheduleDate.substring(0, 10)).format(
                CoreConstants.Format.LOCALE
              )
            : "";
          result.endDate = result.endDate
            ? moment(result.endDate.substring(0, 10)).format(
                CoreConstants.Format.LOCALE
              )
            : "";
        });
        return results;
      }
    })
    .catch(() => []);
};

const setAdvancedFilter = (payload) => {
  return (dispatch) => {
    dispatch({
      type: TasksConstants.Action.Tasks.ADVANCED_FILTER,
      payload,
    });
  };
};

const getFilterValues = (newValues) => {
  const newFilterValues = {};
  newValues.forEach((val) => {
    const field = val.field.toLocaleLowerCase();
    if (field.includes("date")) newFilterValues[val.field] = val.date;
    else if (field.includes("iscompleted"))
      newFilterValues[val.field] = val.option.code === "TCO";
    else if (field.includes("id")) newFilterValues[val.field] = val.option.id;
    else newFilterValues[val.field] = val.value;
  });
  return newFilterValues;
};

const removeFilterValues = (removeField) => {
  return (dispatch, getState) => {
    let { values } = getState().tasksReducer.advancedFilter;
    let { companyId, sourceId } = getState().tasksReducer.currentAction;
    const newValues = values.filter(({ field }) => field !== removeField);
    const fields = getFilterValues(newValues);
    dispatch(
      getTasksByCustomer({
        change: true,
        fields,
        documentId: companyId,
        sourceId: sourceId,
      })
    );
    dispatch(setAdvancedFilter({ values: newValues }));
  };
};

const currentAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: TasksConstants.Action.Tasks.CURRENT_ACTION,
      payload,
    });
  };
};

const setCurrentTask = (payload) => {
  return (dispatch) => {
    dispatch({
      type: TasksConstants.Action.Tasks.EDIT_TASK,
      payload,
    });
  };
};

const editTask = (id) => {
  return (dispatch) => {
    dispatch(setCurrentTask({ isLoadingTask: true, data: {}, attachments: [] }));
    dispatch(getTaskById(id));
    // dispatch(getCommentsByTaskById(id));
  };
};

const getTaskById = (id) => {
  return (dispatch) => {
    TaskService.getTaskById(id)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          // const task = data.Data;
          const task = data.data?.task;
          task.dynamicFieldValues = data.data.dynamicData
          const attachments = data.data?.attachment;
          // Object.entries(task).forEach(([key, value]) => {
          //   if (value !== null) {
          //     if (key === "ScheduleDate" || key === "EndDate") {
          //       let date = new Date(value);
          //       task[key] = date;
          //     }
          //   }
          // });
          Object.entries(task).forEach(([key, value]) => {
            if (value !== null) {
              if (key === "scheduleDate" || key === "endDate") {
                let date = new Date(value);
                task[key] = date;
              }
            }
          });

          dispatch(setCurrentTask({ data: task, attachments, isLoadingBusiness: false }));
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setCurrentTask({ isLoadingTask: false }));
      });
  };
};

const deleteTask = (id, documentId, sourceId) => {
  return (dispatch) => {
    TaskService.deleteTask(id)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success("Tareas", "Tarea Eliminada"));
          dispatch(
            getTasksByCustomer({
              change: false,
              documentId: documentId,
              sourceId: sourceId,
            })
          );
        }
      })
      .catch(console.error);
  };
};

const deleteTaskBoolean = async (idTask) => {
  return await TaskService.deleteTask(idTask)
    .then(({ data }) => {
      const status = data.status;
      if (status === CoreConstants.HttpResponse.OK) {
        toastAction.success("Tareas", "Tarea Eliminada");
        return true;
      }
    })
    .catch(false);
};

const setGetDataMaster = (payload) => {
  return (dispatch) => {
    dispatch({
      type: TasksConstants.Action.Tasks.DATA_MASTER,
      payload,
    });
  };
};

const getDataMaster = () => {
  return (dispatch) => {
    dispatch(setGetDataMaster({ isLoading: true }));
    TaskService.getDataMaster()
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setGetDataMaster({ data: data.data }));
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setGetDataMaster({ isLoading: false }));
      });
  };
};

const saveCustomerTask = ({ task, isNewTask, projectId }) => {
  return (dispatch) => {
    const currentLoading = { isLoadingTask: true };

    dispatch(setCurrentTask(currentLoading));
    TaskService.saveCustomerTask(task)
      .then(({ data }) => {
        const status = data.status;
        if (data.status === CoreConstants.HttpResponse.OK) {
          const message = isNewTask
            ? "¡Registro exitoso!"
            : "¡Edición exitosa!";
          dispatch(toastAction.success("Tareas", message));
          dispatch(setCurrentTask({ errors: [] }));
          if (projectId) {
            dispatch(ProjectsActions.getProjectById(Number(projectId)));
          }
        } else if (
          data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS &&
          data.data.length > 0
        ) {
          dispatch(setCurrentTask({ errors: data.data }));
        }
        dispatch(setCurrentTask({ status }));
      })
      .catch((error) => {
        console.error(error);
        dispatch(setCurrentTask({ status: 2 }));
      })
      .finally(() => {
        currentLoading.isLoadingTask = false;
        dispatch(setCurrentTask(currentLoading));
      });
  };
};

const completeTask = (id) => {
  return (dispatch) => {
    const currentLoading = { isLoadingTask: true };

    dispatch(setCurrentTask(currentLoading));
    TaskService.completeTask(id)
      .then(({ data }) => {
        const status = data.status;
        if (data.status === CoreConstants.HttpResponse.OK) {
          const message = "Tarea Completada";
          dispatch(toastAction.success("Tareas", message));
          dispatch(setCurrentTask({ errors: [] }));
          dispatch(getTaskById(id));
        } else if (
          data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL &&
          data.data.length > 0
        ) {
          dispatch(toastAction.error("Tareas", data.message));
        }
        dispatch(setCurrentTask({ status }));
      })
      .catch((error) => {
        console.error(error);
        dispatch(setCurrentTask({ status: 2 }));
      })
      .finally(() => {
        currentLoading.isLoadingTask = false;
        dispatch(setCurrentTask(currentLoading));
      });
  };
};

const getCommentsByTaskById = (id) => {
  return (dispatch) => {
    dispatch(setTaskComments({ loading: true }));
    TaskService.getCommentsByTaskId(id)
      .then(({ data }) => {
        const status = data?.Status ?? "";
        if (status === CoreConstants.HttpResponse.OK) {
          data.Data.forEach((result) => {
            result.CreationDate = moment(result.CreationDate).format(
              CoreConstants.Format.DDMMYYYYhhmmss
            );
          });

          let currentFields = {
            loading: false,
            data: data.Data,
          };

          dispatch(setTaskComments(currentFields));
          return;
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setTaskComments({ loading: false }));
      });
  };
};

const addComment = (sourceCode1, documentId, comment) => {
  var sourceCode = CoreConstants.Entity.CommentsSource.TASK;
  const setComments = ({ dispatch, isSaved, isLoading  }) => {
    dispatch({
      type: TasksConstants.Action.Tasks.ADD_COMMENT,
      payload: { isSaved, isLoading },
    });
  };
  return (dispatch) => {
    // const currentLoading = { isLoadingTask: true };
    const payload = { sourceCode, documentId, comments: comment };

    TaskService.addComment(payload)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          // dispatch(setCurrentTask({ errors: [] }));
          // dispatch(getCommentsByTaskById(payload.documentId));
          setComments({ dispatch: dispatch, isSaved: true, isLoading: false });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setComments({ dispatch: dispatch, isSaved: false, isLoading: false });
      });
  };
};

const openTaskModal = (type) => {
  return (dispatch) => {
    dispatch({
      type: TasksConstants.Action.Tasks.OPEN_MODAL,
      payload: {
        visible: true,
        type: type,
      },
    });
  };
};

const closeTaskModal = () => {
  return (dispatch) => {
    dispatch({
      type: TasksConstants.Action.Tasks.CLOSE_MODAL,
      payload: {
        visible: false,
        type: null,
      },
    });
  };
};

const deleteComment = (commentId, documentId) => {
  const setComments = ({ dispatch, isDeleted  }) => {
    dispatch({
      type: TasksConstants.Action.Tasks.DELETE_COMMENT,
      payload: { isDeleted },
    });
  };
  return (dispatch) => {
    // const currentLoading = { isLoadingTask: true };

    TaskService.deleteComment(commentId)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          // dispatch(getCommentsByTaskById(documentId));
          setComments({ dispatch: dispatch, isDeleted: true });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setComments({ dispatch: dispatch, isDeleted: false });
      });
  };
};

const getCommentsById = (id) => {
  const setComments = ({ dispatch, isLoading, data  }) => {
    dispatch({
      type: TasksConstants.Action.Tasks.GET_COMMENTS_BY_INDICATORID,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setComments({ dispatch: dispatch, isLoading: true, data: [] })
    TaskService.getCommentsByTaskId(id)
    .then(({ data }) => {
      if (data.Status === CoreConstants.HttpResponse.OK) {
          // setComments({ dispatch: dispatch, isLoading: true, data: [] })
          data.Data.forEach(result =>{
            result.CreationDate =  moment(result.CreationDate).format(CoreConstants.Format.DDMMYYYYhhmmss)
          })

          setComments({ dispatch: dispatch, isLoading: false, data: data.Data })
          return;
        }
      })
      .catch(console.error)
      .finally(() => {
        setComments({ dispatch: dispatch, isLoading: false, data: [] })
      });
  };
}

// getCommentsById,
//   addComment,
//   deleteComment,

const reportStatus = (payload) => {
  return (dispatch) => {
    dispatch({
      type: TasksConstants.Action.Tasks.REPORT_STATUS,
      payload,
    });
  };
};

const generateReport = ({documentId, sourceId}) => {
  return (dispatch, getState) => {
    dispatch(reportStatus(true));
    let { values } = getState().tasksReducer.advancedFilter;
    const fieldValues = getFilterValues(values);
    TaskService.generateReport({...fieldValues, documentId, sourceId})
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        FileSaver.saveAs(blob, "Tareas");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(reportStatus(false));
      });
  };
};

const openTask = (id) => {
  return (dispatch) => {
    const currentLoading = { isLoadingTask: true };

    dispatch(setCurrentTask(currentLoading));
    TaskService.openTask(id)
      .then(({ data }) => {
        const status = data.status;
        if (data.status === CoreConstants.HttpResponse.OK) {
          const message = "Tarea Abierta";
          dispatch(toastAction.success("Tareas", message));
          dispatch(setCurrentTask({ errors: [] }));
          dispatch(getTaskById(id));
        } else if (
          data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL &&
          data.data?.length > 0
        ) {
          dispatch(toastAction.error("Tareas", data.message));
        } else {
          dispatch(toastAction.error("Tareas", data.message));
        }
        dispatch(setCurrentTask({ status }));
      })
      .catch((error) => {
        console.error(error);
        dispatch(setCurrentTask({ status: 2 }));
      })
      .finally(() => {
        currentLoading.isLoadingTask = false;
        dispatch(setCurrentTask(currentLoading));
      });
  };
};

export const TaskAction = {
  addComment,
  deleteComment,
  getCommentsById,
  currentAction,
  completeTask,
  deleteTask,
  editTask,
  getDataMaster,
  getTasksByCustomer,
  getCommentsByTaskById,
  setAdvancedFilter,
  setCurrentTask,
  saveCustomerTask,
  removeFilterValues,
  getTasksByFields,
  deleteTaskBoolean,
  openTaskModal,
  closeTaskModal,
  generateReport,
  openTask,
};
