import { RrhhConstants } from "features/rrhh/commons/RrhhConstants";

const initialState = {
    dataMaster: {
        isLoading: false,
        areas: [],
        employees: [],
        positions: [],
    },
    companyAreaParents: {
        data: [],
        isLoading: false,
    },
    companyArea: {
        isLoading: false,
        isSaving: false,
        isSaved: false,
        isDeleted: false,
    },
    node: {
        data: null,
        isLoading: false,
        isSaving: false,
        isSaved: false,
        isDeleted: false,
    },
    nodes: {
        isLoading: false,
        data: [],
    },
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {

        case RrhhConstants.Accion.OrganizationChart.SAVE_NODE:
            return {
                ...state,
                node: {
                    isSaving: action.payload.isSaving,
                    isSaved: action.payload.isSaved,
                }
            };

        case RrhhConstants.Accion.OrganizationChart.SAVE_COMPANY_AREA:
            return {
                ...state,
                companyArea: {
                    isSaving: action.payload.isSaving,
                    isSaved: action.payload.isSaved
                }
            };

        case RrhhConstants.Accion.OrganizationChart.DELETE_POSITION:
            return {
                ...state,
                companyArea: {
                    isDeleted: action.payload.isDeleted
                }
            };

        case RrhhConstants.Accion.OrganizationChart.RESET_AFTER_SAVED:

            return {
                ...state,
                companyArea: {
                    isSaving: false,
                    isSaved: false,
                    isLoading: false,
                    isDeleted: false
                },
                node: {
                    data: null,
                    isLoading: false,
                    isSaving: false,
                    isSaved: false,
                    isDeleted: false,
                },
            };

        case RrhhConstants.Accion.OrganizationChart.GET_DATA_MASTER:
            return {
                ...state,
                dataMaster: {
                    isLoading: action.payload.isLoading,
                    areas: action.payload.areas,
                    positions: action.payload.positions,
                    employees: action.payload.employees,
                }
            };

        case RrhhConstants.Accion.OrganizationChart.GET_ORGANIZATION_CHART:
            return {
                ...state,
                nodes: {
                    isLoading: action.payload.isLoading,
                    data: action.payload.data,
                }
            };
        case RrhhConstants.Accion.OrganizationChart.GET_COMPANY_AREA_PARENTS:
            return {
                ...state,
                companyAreaParents: {
                    isLoading: action.payload.isLoading,
                    data: action.payload.data,
                }
            };

        case RrhhConstants.Accion.OrganizationChart.DELETE_NODE:
            return {
                ...state,
                node: {
                    isDeleted: action.payload.isDeleted,
                }
            };
        case RrhhConstants.Accion.OrganizationChart.GET_NODE_BY_ID:
            return {
                ...state,
                node: {
                    data: action.payload.data,
                    isLoading: action.payload.isLoading,
                }
            };
        default:
            return state;

    }
}
