import { RolesAction } from 'features/configuration/store/actions/RolesAction';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useFilter = () => {
  const dispatch = useDispatch();
  const fieldRole = useRef(null);
  const { values } = useSelector((state) => state.roleReducer.filterRole);
  const rolesLoading = useSelector((state) => state.roleReducer.roles.loading);
  const reportLoading = useSelector(
    (state) => state.roleReducer.downloadReport.loading
  );

  const filterRole = (e) => {
    e.preventDefault();
    const value = fieldRole.current.value;
    if (value) {
      filterRoles();
      dispatch(RolesAction.setFilterValues([{ field: 'name', value }]));
    }else{
      fieldRole.current.value = null;
    }
  };

  const openModal = () => {
    dispatch(RolesAction.toggleModalFilters({ showModal: true }));
  };

  const handleChange = () => {
    if (fieldRole.current.value === null) {
      filterRoles();
      dispatch(RolesAction.removeFilterValues('name'));
    }
  };

  const showPiners = () => {
    if (values.length > 0) return true;
    else return false;
  };

  const buildFields = () => {
    const value = fieldRole.current.value;
    const fields = {};
    fields.name = value;

    if (values.length > 0) {
      const fieldTo = values.filter((val) => val.field === 'to');
      if (fieldTo.length > 0) fields.to = fieldTo[0].date;
      else fields.to = null;
      const fieldFrom = values.filter((val) => val.field === 'from');
      if (fieldFrom.length > 0) fields.from = fieldFrom[0].date;
      else fields.from = null;
    }
    if (!fields.name) delete fields.name;
    return fields;
  };

  const filterRoles = () => {
    dispatch(RolesAction.getRoles({ change: true, fields: buildFields() }));
  };

  useEffect(() => {
    filterRoles();
  }, [values]);

  const removePiner = (field) => {
    if (field === 'name') fieldRole.current.value = null;
    dispatch(RolesAction.removeFilterValues(field));
  };

  const generateReport = () => {
    dispatch(RolesAction.generateRoleReport(buildFields()));
  };

  return {
    fieldRole,
    handleChange,
    openModal,
    removePiner,
    showPiners,
    filterRole,
    values,
    generateReport,
    reportLoading,
    rolesLoading,
  };
};

export default useFilter;
