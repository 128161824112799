import { ManagmentAction } from "features/indicators/store/actions/ManagmentAction";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { MultiSelect } from 'primereact/multiselect';
import { IndicatorModalComponent } from "features/indicators/components/Management/IndicatorModalComponent";
import useManagment from "features/indicators/hooks/Managment/useManagment";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Menu } from "primereact/menu";
import moment from "moment";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { CoreConstants } from "core/commons/CoreConstants";
import { useClaimIndicatorAdministration } from "features/indicators/hooks/useClaimIndicatorAdministration";

const { useForm, Controller } = require("react-hook-form")

const ManagmentPage = ({title, typeMode}) => {
  const {updateTitle } = useSetTitlePage()
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const {
    control,
    errors,
    existError,
    setValue,
    handleSubmit,
    reset,
    valuesFields
  } = useManagment();

  const { isCreate, isEdit } = useClaimIndicatorAdministration();

  const [visibleModal, setVisibleModal] = useState(false)
  const [mode, setMode] = useState('new')
  const [typeModal, setTypeModal] = useState(null)
  const [dataIndicator, setDataIndicator] = useState(null)
  const [listIndicators, setListIndicators] = useState([])
  const menu = useRef(null);
  const [showConfirmDialog, setshowConfirmDialog] = useState(false);
  const [itemsSelected, setItemSelected] = useState(null)


  const controlsDataMaster = useSelector((state) => state.managmentReducer.controlsDataMaster.data);
  const dataMagment = useSelector((state) => state.managmentReducer.managment.data);
  const statusSave = useSelector((state) => state.managmentReducer.managment);

  const redirectToSearch = () => {
    history.push("/indicadores/administracion");
    // dispatch(ManagmentAction.resetAfterSaved());
  }

  useEffect(()=> {
    if(statusSave?.isSaved===true){
      redirectToSearch()
    }
  },[statusSave?.isSaving])

  const confirmDelete = () => {
    setListIndicators(listIndicators.filter(el => el.id!==itemsSelected.id));
  }


  const cancelDelete = () => {
    setshowConfirmDialog(false);
  }

  useEffect(()=>{
    updateTitle({
      title: "Indicadores",
      subtitle: "Administración",
      description: title,
      previousUrl: "/indicadores/administracion"
    })
    if(!controlsDataMaster){
      dispatch(ManagmentAction.getDataMaster())
    }
    if(params?.id){
      dispatch(ManagmentAction.getManagmentById(params?.id))
    }
  }, [])

  const items = [
    {
        label: 'Editar',
        icon: 'pi pi-pencil',
        command: (e) => {
            setDataIndicator(itemsSelected);
            if(itemsSelected.modeId.code===CoreConstants.Entity.DashbaordIndicatorModes.CHART){
              dispatch(ManagmentAction.getdataGraphics(itemsSelected.indicatorId.id,itemsSelected.from, itemsSelected.to))
            }
            setMode("edit")
            setVisibleModal(true)
        }
    },
    {
        label: 'Eliminar',
        icon: 'pi pi-times',
        command: (e) => {
            setshowConfirmDialog(true);
        }
    }
];

  const onSubmit = (data) => {

    const sendData = {
      id: params?.id?Number(params.id):null,
      isEnabled: !data.isEnabled?false:true,
      name: data.name,
      roles: data.roles,
      indicators: listIndicators?.map(el=> {
        return{
          id: el.mode==="edit"?Number(el.id):null,
          containerId: params?.id?Number(params.id):null,
          indicatorId: el.indicatorId.id,
          modeId: el.modeId.id,
          chartTypeId: el.modeId.code===CoreConstants.Entity.DashbaordIndicatorModes.CHART?el.chart?.id:null,
          from: el.modeId.code===CoreConstants.Entity.DashbaordIndicatorModes.CHART?el.from:null,
          to: el.modeId.code===CoreConstants.Entity.DashbaordIndicatorModes.CHART?el.to:null
        }
      })
    }
    // console.log(sendData)
    dispatch(ManagmentAction.saveIndicators(sendData))
  }

  //charge Data
  useEffect(()=> {
    if(dataMagment && controlsDataMaster){
      setValue('isEnabled', dataMagment.isEnabled)
      setValue('name', dataMagment.name)
      setValue('roles', dataMagment.roles.map(el => { return el.roleId }))
      setListIndicators(dataMagment.indicators.map(elem=> {
        return {
          id: elem.id,
          mode:'edit',
          containerId: Number(params?.id),
          indicatorId: controlsDataMaster?.indicators.find(item=> item.id===elem.indicatorId),
          chart: controlsDataMaster?.charts.find(itemChart=> itemChart.id===elem.chartTypeId),
          modeId: controlsDataMaster.modes.find(itemModes=> itemModes.id===elem.modeId),
          from: elem.from,
          to: elem.to
        }
      }))
    }
  },[dataMagment, controlsDataMaster])

  const addIndicator = (data) => {
    if(listIndicators.some(item => item.id===data.id)){
      let newList = [...listIndicators]
      setListIndicators(newList.map(el=> {
        if(el.id===data.id){
          return data
        }else{
          return el
        }
      }))
    }else{
      setListIndicators([...listIndicators, data])
    }
    setDataIndicator(null)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    // console.log

    if (!destination) return
    if ( source.index === destination.index && source.droppableId === destination.droppableId ) return
    if (source.droppableId === "items-drop" && destination.droppableId === "items-menu") return
    if ( source.droppableId === destination.droppableId && source.droppableId === "items-menu"  ) {
      setListIndicators((prevInputs) => reorder(prevInputs, source.index, destination.index) )
      return
    }

  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Panel header="DATOS GENERALES">
          <div className="grid grid-cols-10 gap-2">
            <div className="col-span-10 flex items-center">
                <Controller
                    control={control}
                    render={({
                        field: { onChange, onBlur, value }
                    }) => (
                      <InputSwitch 
                          id="isEnabled"
                          name="isEnabled"
                          onChange={onChange}
                          checked={value}
                      />
                    )}
                    name="isEnabled"
                />
                <p className="ml-3">{valuesFields.isEnabled?'Habilitado':'Deshabilitado'}</p>
            </div>
            <div className="col-span-10">
              <div className="w-2/4">
                <span className="col-span-2 p-float-label w-full mt-4">
                  <Controller
                      control={control}
                      render={({
                          field: { onChange, onBlur, value }
                      }) => (
                        <InputText
                            type="text"
                            id="name"
                            name="name"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            className={errors?.name ? 'p-invalid w-full' : 'w-full'}
                        />
                      )}
                      name="name"
                      rules={{
                          required: 'La Nombre es requerida.'
                      }}
                  />
                  <label htmlFor="name">Ingrese Nombre *</label>
              </span>
              </div>
              <div className="w-2/4 mt-4">
                <Controller
                    control={control}
                    render={({
                        field: { onChange, onBlur, value }
                    }) => (
                    <MultiSelect id='roles'
                    maxSelectedLabels={4}
                    name="roles"
                    value={value}
                    options={controlsDataMaster?.roles}
                    onChange={onChange}
                    optionLabel="name"
                    placeholder="Seleccione Roles"
                    selectedItemsLabel = "{0} items seleccionados"
                    className={errors?.roles ? 'p-invalid w-full' : 'w-full'}
                    optionValue={(e) => {
                      return e.id;
                        }}
                    />
                    )}
                    name="roles"
                    rules={{
                      required: 'No ha seleccionado ningún rol.'
                    }}
                    />
              </div>
            </div>
          </div>
        </Panel>
        <Panel header="INDICADORES">
          <DragDropContext onDragEnd={(result) => onDragEnd(result) } >
          <div className="grid grid-cols-6 gap-4">
            <div className="col-span-6">
              {
                (typeMode!=='view')&&(
                  <Button
                    type="button"
                    className="sig-button sig-primary"
                    label="Añadir Indicador"
                    onClick={()=> {
                      setMode("new")
                      setVisibleModal(true)
                    }}
                  />
                )
              }
            </div>
            <Droppable droppableId='items-menu' direction="vertical">
                                {
                                  (droppableProvided, snapshot) => (
                                    <div
                                      ref={droppableProvided.innerRef}
                                      // style={getListStyleOptions(snapshot.isDraggingOver)}
                                      {...droppableProvided.droppableProps}
                                      // className='bg-blue-200 lg:bg-red'
                                      className='col-span-6 grid grid-cols-6 gap-4'
                                      >
                                        {
                                          listIndicators?.map((item, index) => (
                                            <Draggable
                                                key={`drag-${item.id}`}
                                                draggableId={`${item.id}`}
                                                index={index}
                                            >
                                              {
                                                (draggableProvided, snapshot) => (
                                                  <>
                                                  <div
                                                    ref={draggableProvided.innerRef}
                                                    {...draggableProvided.draggableProps} 
                                                    {...draggableProvided.dragHandleProps}
                                                    // style={getItemStyleOptions(
                                                    //   snapshot.isDragging,
                                                    //   draggableProvided.draggableProps.style
                                                    // )}
                                                    className='col-span-3 bg-white cursor-pointer box-border'
                                                    // className='customClassName border-2 border-stone-700 flex justify-center md:justify-start items-center h-10 w-10 md:h-12 md:w-12 lg:w-full lg:rounded-lg hover:bg-gray-200 lg:bg-white'
                                                  >
                                                    <div className="border-2 hover:bg-gray-100 shadow-xl col-span-3 rounded-lg h-36">
                                                    <div className="border-b-2 m-0 p-2 flex justify-between items-center">
                                                      <p className="font-semibold">{item?.indicatorId?.name}</p>
                                                      {
                                                        (typeMode!=='view')&&(
                                                          <i
                                                            // key={rowData.id}
                                                            className="pi pi-ellipsis-v cursor-pointer"
                                                            onClick={(event) => {
                                                                setItemSelected(item);
                                                                menu.current.toggle(event)
                                                            }
                                                            }></i>
                                                        )
                                                      }
                                                    </div>
                                                    <div className="p-2">
                                                      Tipo: {item.modeId.name}
                                                    </div>
                                                    {
                                                      item.modeId.code===CoreConstants.Entity.DashbaordIndicatorModes.CHART?<>
                                                        <div className="pl-2">
                                                          Desde: {moment(item.from).format(CoreConstants.Format.LOCALE)}
                                                        </div>
                                                        <div className="pl-2 pb-2">
                                                          Hasta: {moment(item.to).format(CoreConstants.Format.LOCALE)}
                                                        </div>
                                                      </>:null
                                                    }
                                                </div>

                                                  </div>
                                                  </>)
                                              }
                                            </Draggable>))
                                        }
                                      </div>)
                                  }
            </Droppable>
          </div>
          </DragDropContext>
        </Panel>
        {
        (existError) && <div className='message error'>
            <ul>
                {errors.name ? (
                    <>
                        <li className="p-error">{errors.name.message}</li>
                    </>
                ) : null}
                {errors.roles ? (
                    <>
                        <li className="p-error">{errors.roles.message}</li>
                    </>
                ) : null}
            </ul>
        </div>
        }
        <div className="mt-4 flex justify-end">
          <Button className="sig-button sig-secondary" type="button" icon="pi pi-times" onClick={redirectToSearch} label="Cancelar" loading={statusSave?.isSaving}/>
          {
            (typeMode!=='view' && (isCreate || isEdit))&&(
              <Button className="sig-button sig-primary ml-2" icon="pi pi-check" type="submit" label="Guardar" loading={statusSave?.isSaving}/>
            )
          }
        </div>
        <IndicatorModalComponent mode={mode} visible={visibleModal} setVisible={setVisibleModal} dataValues={dataIndicator} setDataValues={setDataIndicator} addIndicator={addIndicator} itemsSelected={itemsSelected}/>
        <Menu model={items} popup ref={menu} id="popup_menu" />
        <ConfirmDialog
                visible={showConfirmDialog}
                onHide={cancelDelete}
                message="¿Está seguro que desea eliminar el indicador?"
                header="Eliminar"
                icon="pi pi-trash"
                accept={confirmDelete}
                reject={cancelDelete}
            />
      </form>
    </div>
  )
}

export default ManagmentPage;