import { useHistory } from 'react-router';
import { Button } from 'primereact/button';
import {useEffect} from 'react';
import FilterComponent from 'features/subscription/components/subscriptions/FilterComponent';
import TableComponent from 'features/subscription/components/subscriptions/TableComponent';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { useClaimSubscription } from 'features/subscription/hooks/useClaimSubscription';
import { useDispatch } from 'react-redux';
import { SubscriptionsAction } from 'features/subscription/store/actions/SubscriptionsAction';

export const SearchSubscriptionPage = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const { updateTitle } = useSetTitlePage();
  const { isSearch, isDownload, isCreate } = useClaimSubscription()

  useEffect(() => {
    dispatch(SubscriptionsAction.getDataMaster())
  },[])

  useEffect(() => {
    updateTitle({
      title: 'Administración del Servicio',
      subtitle : 'Subscripciones'
    });
  }, []);
  return(
    <>
      {
        (isSearch||isDownload)&&(
           <FilterComponent />
        )
      }
      {
        isCreate&&(
          <Button
              icon="pi pi-plus"
              type="button"
              label="Nuevo"
              className="sig-button sig-dark mt-4"
              onClick={() => { history.push('/suscripcion/suscripciones/nuevo'); }}
          />
        )
      }
      <TableComponent />
    </>
  )
}