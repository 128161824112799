import useUnitMeasure from 'features/configuration/hooks/sistem/useUnitMeasure';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';

import TableEmpty from 'shared/components/table-empty/TableEmpty';
import TableHeaderComponent from './TableHeaderComponent';
import TableRowComponent from './TableRowComponent';

const UnitMeasureComponent = () => {
  const {
    isLoading,
    listUnitMeasure,
    createUnit,
    deleteUnit,
    changeAction,
    changeField,
    isLoadingSave,
    currentUnitID,
    currentAction,
    isDisabled,
    isError,
  } = useUnitMeasure();

  return (
    <div className="content-tab-sistem">
      <h2 className="mt-lg-0 mt-3">Unidad de Medida</h2>
      <p className="description mt-5 mb-3 font-semibold">
        Aquí puedes crear y editar las unidades que utilizará la aplicación.
      </p>
      {!isLoading ? (
        <Button
          icon="pi pi-plus"
          type="button"
          label="Añadir nueva unidad de medida"
          className="btn btn-primary mb-3"
          onClick={createUnit}
          disabled={isDisabled}
        />
      ) : (
        <Skeleton width="35%" height="2.8rem" />
      )}

      {!isLoading && (
        <div className="table-main table-units mb-3">
          <TableHeaderComponent />
          {listUnitMeasure.map((unit) => (
            <TableRowComponent
              {...unit}
              key={unit.id}
              deleteUnit={deleteUnit}
              changeAction={changeAction}
              changeField={changeField}
              currentUnitID={currentUnitID}
              currentAction={currentAction}
              isLoadingSave={isLoadingSave}
              isError={isError}
            />
          ))}
          {listUnitMeasure.length === 0 && !isLoading && <TableEmpty />}
        </div>
      )}
      {isLoading && (
        <>
          <Skeleton width="100%" height="2.5rem" className="mb-4 mt-4" />
          {[1, 2, 3, 4].map((s) => (
            <Skeleton width="100%" height="2rem" className="mb-2" />
          ))}
        </>
      )}
    </div>
  );
};

export default UnitMeasureComponent;
