export class BusinessSheetTypeConstants {
  static Action = class {
    static BusinessSheet = class {
      static get SEARCH() {
        return '@BUSINESS-SHEET.SEARCH';
      }
      static get EDIT() {
        return '@BUSINESS-SHEET.EDIT';
      }
      static get DOWNLOAD_STATUS() {
        return '@BUSINESS-SHEET.DOWNLOAD-STATUS';
      }
      static get SAVE(){
        return '@BUSINESS-SHEET.SAVE';
      }
    };
  };

  static FieldName = class {
    static Filter = class {
      static get NAME(){
        return 'name'
      }
      static get FROM_DATE(){
        return 'fromDate'
      }
      static get TO_DATE(){
        return 'toDate'
      }
    }
  }
}