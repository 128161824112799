import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

export const useClaimSurvey = () => {

  const menu = useSelector((state) => state.authReducer.user.menuPrincipal);
  const pathName = useLocation()?.pathname;
  const [isSearch, setSearch] = useState(false);
  const [isDownload, setDownload] = useState(false);

  const [isShare, setShare] = useState(false)
  const [isAnalyze, setAnalyze] = useState(false)

  useEffect(() => {
    search();
    download();
    share();
    analyze();
  }, [menu]);


  const search = () => {
    const actionCode = 'SEARCH';
    const optionCode = "SRCR";
    setSearch(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  const download = () => {
    const actionCode = 'DOWNLOAD';
    const optionCode = "SRCR";
    setDownload(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  //custom
  const share = () => {
    const actionCode = 'SHARE';
    const optionCode = "SRCR";
    setShare(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  const analyze = () => {
    const actionCode = 'ANALYZE';
    const optionCode = "SRCR";
    setAnalyze(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };




  return {
    isSearch,
    isDownload,
    isShare,
    isAnalyze
  }
}