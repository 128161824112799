import { useEffect } from 'react';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import homeReducer from 'shared/store/reducers/HomeReducer'
import SearchHomePage from './Search/SearchHomePage';
import tasksReducer from '../../../features/customers/store/reducers/TasksReducer';
import companiesReducer from '../../../features/customers/store/reducers/CompaniesReducer'
import documentLibraryReducer from 'features/document/store/reducers/DocumentLibraryReducer';

const PageHomePage = (reducerRegistry) => {
  const { updateTitle } = useSetTitlePage();
  reducerRegistry.register({ homeReducer });
  reducerRegistry.register({ tasksReducer });
  reducerRegistry.register({ companiesReducer });
  reducerRegistry.register({ documentLibraryReducer });

  useEffect(() => {
    updateTitle({
      title: 'Inicio',
      subtitle : 'Dashboard'
    });
  }, []);

  return <SearchHomePage />
};

export default PageHomePage;
