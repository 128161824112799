/* eslint-disable import/no-anonymous-default-export */
import { RiskConstants } from "features/riskManagement/commons/RiskConstants";

const initialState = {
	models: {
		isLoading: false,
		data: {
			results: [],
			currentPage: null,
			pageCount: null,
			pageSize: null,
			rowCount: null,
		},
		error: false,
	},
	dataMaster: {
		inventory: [],
		isLoading: false,
		error: false,
	},
	dataMasterEvaluation: {
		data: {
			evaluationTypes: [],
			conditions: [],
			criterias: [],
			mathOperators: [],
			logicalOperators: [],
			colors: [],
		},
		isLoading: false,
		error: false,
	},
	advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'name',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {}
  },
	model : {
		isLoading: false,
		data: {},
		isSaved: false,
	},
	itemsConfiguration: {
		items: [],
		isLoading: true,
	},
	itemsSelection: {
		items: [],
		isLoading: true,
	},
	itemFilter: {
		item: [],
		isLoading: false,
	},
	criteria: {
		data: null
	},
	evaluacion: {
		data: [],
	},
	operator: {
		data: [],
	},
	formula: {
		data: [],
	},
	formulaInput: {
		value: [],
	},
	condition: {
		data: [],
	},
	isNewEvaluation: {
		value: false,
	},
  dataForCopy: {
    id: null,
    isCopy: false,
  }
}

export default (state = initialState, action) => {
	switch (action.type) {
		case RiskConstants.Accion.RiskModel.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }

		case RiskConstants.Accion.RiskModel.GET_DATA:
			return {
				...state,
				models: {
					...state.models,
					...action.payload,
				}
			}

		case RiskConstants.Accion.RiskModel.GET_DATA_MASTER:
			return {
				...state,
				dataMaster: {
					...state.dataMaster,
					...action.payload,
				}
			}

		case RiskConstants.Accion.RiskModel.GET_DATA_MASTER_EVALUATION:
			return {
				...state,
				dataMasterEvaluation: {
					...state.dataMasterEvaluation,
					...action.payload,
				}
			}

		case RiskConstants.Accion.RiskModel.GET_DATA_BY_ID:
			return {
				...state,
				model: {
					...state.model,
					...action.payload,
				}
			}

		case RiskConstants.Accion.RiskModel.DOWNLOAD_REPORT_STATUS:
			return {
				...state,
				models: {
					...state.models,
					...action.payload,
				}
			};

		case RiskConstants.Accion.RiskModel.UPDATE_FILTER:
			return {
				...state,
				advancedFilter: state.advancedFilter
		};

		case RiskConstants.Accion.RiskModel.GET_CONFIGURATION_ITEMS:
			return {
				...state,
				itemsConfiguration: {
					...state.itemsConfiguration,
					...action.payload,
				}
			}

		case RiskConstants.Accion.RiskModel.SET_ITEMS_SELECTED:
			return {
				...state,
				itemsSelection: {
					...state.itemsSelection,
					...action.payload,
				}
			}

		case RiskConstants.Accion.RiskModel.SET_ITEM_FILTER:
			return {
				...state,
				itemFilter: {
					...state.itemFilter,
					...action.payload,
				}
			}
		
		case RiskConstants.Accion.RiskModel.ADD_CRITERIA:
			return {
				...state,
				criteria: {
					...state.criteria,
					...action.payload,
				}
			}

		case RiskConstants.Accion.RiskModel.GET_EVALUACION:
			return {
				...state,
				evaluacion: {
					...state.evaluacion,
					...action.payload,
				}
			}
		
		case RiskConstants.Accion.RiskModel.GET_OPERATOR:
			return {
				...state,
				operator: {
					...state.operator,
					...action.payload,
				}
			}

		case RiskConstants.Accion.RiskModel.SET_FORMULA:
			return {
				...state,
				formula: {
					...state.formula,
					...action.payload,
				}
			}

		case RiskConstants.Accion.RiskModel.SET_FORMULA_INPUT:
		return {
			...state,
			formulaInput: {
				...state.formulaInput,
				...action.payload,
			}
		}

		case RiskConstants.Accion.RiskModel.ADD_CONDITION:
			return {
				...state,
				condition: {
					...state.condition,
					...action.payload,
				}
			}
			
		case RiskConstants.Accion.RiskModel.TOGGLE_STATE_EVALUATION:
			return {
				...state,
				isNewEvaluation: {
					...state.isNewEvaluation,
					...action.payload,
				}
			}

      case RiskConstants.Accion.RiskModel.TOGGLE_STATE_COPY:
        return {
          ...state,
          dataForCopy: {
            ...state.dataForCopy,
            ...action.payload,
          }
        }

		default:
			return state;
	}
}