export class RiskMatrixConstans {
  static Accion = class {
    static MatrixManagement = class {
      static get GET_DATA() {
        return "@MATRIX_MANAGEMENT.GET_DATA";
      }
      static get SET_MATRIX() {
        return "@MATRIX_MANAGEMENT.SET_MATRIX";
      }
      static get SET_TABLE() {
        return "@MATRIX_MANAGEMENT.SET_TABLE";
      }
      static get SET_VALUES() {
        return "@MATRIX_MANAGEMENT.SET_VALUES";
      }
      static get SET_DOWNLOAD() {
        return "@MATRIX_MANAGEMENT.SET_DOWNLOAD";
      }
    };
  };
  static Types = class {
    static MatrixManagement = class {
      static get SELECCION_SIMPLE() {
        return "SELECCION_SIMPLE";
      }
      static get TEXTO() {
        return "TEXTO";
      }
      static get SELECCION_MULTIPLE() {
        return "SELECCION_MULTIPLE";
      }
    };
  };
}
