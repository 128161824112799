import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RiskManagementAction } from '../store/actions/RiskManagementAction';
import './Square.scss'

const Square = ({color, id}) => {

	const dispatch = useDispatch()
	const selection = useSelector(state => state.riskManagementReducer.selectSquare)

	const select = () => {
		dispatch(RiskManagementAction.setSelectSquare(id))
	}

	return (
		<div className={`flex cursor-pointer square h-16 w-16 ${selection.includes(id) && 'select'}`} style={{backgroundColor: color}} onClick={select}>
			{
				selection.includes(id) && <div className='m-auto'>X</div>
			}
		</div>
	);
};

export default Square;