import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';



const useSurvey = (defaultValues, getQuestionTypeText, getQuestionTypeRadio, isCloseStatus, setSurveySectionRegularId, setSurveySectionGridId) => {

  
  // [{ id, text, type, questionOptions, extraOptions }]
  const [listSections, setListSections] = useState([])
  // const [listSections, setListSections] = useState([
  //   {
  //     id: uuidv4(),
  //     title: 'Sección sin título',
  //     description: '',
  //     position: 1,
  //     // listQuestion: []
  //     listQuestion: [
  //       {
  //         id: uuidv4(),
  //         body: "",
  //         questionTypeId: getQuestionTypeText(),
  //         surveyQuestionOptions: [],
  //         extraOptions: [],
  //         rankingQuantity: 0,
  //         icon: null,
  //         position: 1,
  //         isRequired: false
  //       }
  //     ],
  //     type: SurveySectionType.Regular,
  //     // type: 'grid',
  //   }
  // ])
  // const [listQuestion, setListQuestion] = useState([])
  // console.log(listSections)

  // const currentPath = useSelector(state => state.surveyReducer.currentPath);
  // const advancedFilter = useSelector((state) => state.surveyReducer.advancedFilter);

  const {
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const valuesFields = watch();
  // console.log(valuesFields)

  const existError = Object.keys(errors).length > 0
  const validateLength = (value) => {
    if (typeof value === 'string') {
      return value.replace(/<[^>]+>/g, '')?.length;
    } else if (value?.htmlValue) {
      return value.htmlValue.replace(/<[^>]+>/g, '').length;
    }
    return '0';
  };

  // Agregar nueva sección despues de la sección actual seleccionada
  const addNewSectionAfterSelectedSection = (id) => {
    const newListSections = [...listSections]
    // Encontrar la posición de la sección actual seleccionada
    const index = newListSections.findIndex(section => section.id === id)
    const newSection = {
      id: uuidv4(),
      title: 'Sección sin título',
      description: '',
      position: index + 2,
      // listQuestion: []
      listQuestion: [
        {
          id: uuidv4(),
          body: "",
          questionTypeId: getQuestionTypeText(),
          surveyQuestionOptions: [],
          extraOptions: [],
          rankingQuantity: 0,
          icon: null,
          position: 1,
          isRequired: false
        }
      ],
      // type: SurveySectionType.Regular,
      sectionTypeId: setSurveySectionRegularId(),
    }
    newListSections.splice(index + 1, 0, newSection)

    // Reordenar las secciones
    const newListSectionsReorder = newListSections.map((section, index) => {
      const newSection = {
        ...section,
        position: index + 1
      }
      return newSection
    })

    setListSections(newListSectionsReorder)
  }
  

  const addNewSectionGridAfterSelectedSection = (id) => {
    const newListSections = [...listSections]
    // Encontrar la posición de la sección actual seleccionada
    const index = newListSections.findIndex(section => section.id === id)
    const newSection = {
      id: uuidv4(),
      title: 'Sección sin título',
      description: '',
      position: index + 2,
      // listQuestion: [],
      listQuestion: [
        {
          id: uuidv4(),
          body: "Pregunta 1",
          questionTypeId: getQuestionTypeRadio(),
          surveyQuestionOptions: [
            {
              caption: 'Respuesta 1', 
              position: 1, 
              id: uuidv4() 
            }
          ],
          extraOptions: [],
          rankingQuantity: 0,
          icon: null,
          position: 1,
          isRequired: false
        }
      ],
      // type: SurveySectionType.Grid,
      sectionTypeId: setSurveySectionGridId()
    }
    newListSections.splice(index + 1, 0, newSection)

    // Reordenar las secciones
    const newListSectionsReorder = newListSections.map((section, index) => {
      const newSection = {
        ...section,
        position: index + 1
      }
      return newSection
    })

    setListSections(newListSectionsReorder)
  }

  function removeTags(input) {
    return input.replace(/<[^>]*>/g, '');
  }

  const addTagsToText = (input) => {
    if (input.includes('</p>')) return input
    return `<p>${input}</p>`
  }

  const changeSectionToGrid = (id) => {
    const newListSections = [...listSections]
    // Encontrar la posición de la sección actual seleccionada
    const index = newListSections.findIndex(section => section.id === id)

    const firstQuestion = newListSections[index].listQuestion[0]
    const newSection = {
      ...newListSections[index],
      sectionTypeId: setSurveySectionGridId(),
      // Convertir las preguntas de la sección actual seleccionada a preguntas de tipo CV
      listQuestion: newListSections[index].listQuestion.map((question, index) => {
        const newQuestion = {
          ...question,
          questionTypeIdRegular: question.questionTypeId, //*
          questionTypeId: getQuestionTypeRadio(), 
          // body: `Pregunta ${index + 1}`,
          body: removeTags(question.body),
          surveyQuestionOptions: //*
          firstQuestion?.surveyQuestionOptions.length > 0 
          ? firstQuestion?.surveyQuestionOptions 
          : [
            {
              caption: 'Respuesta 1', 
              position: 1, 
              id: uuidv4() 
            }
          ],
          surveyQuestionOptionsRegular: question.surveyQuestionOptions, //*
          extraOptions: [],
          rankingQuantity: 0,
          rankingQuantityRegular: question.rankingQuantity,
          icon: null,
          iconRegular: question.icon,
          position: index + 1,
          isRequired: false
        }
        return newQuestion
      })
    }
    newListSections[index] = newSection

    setListSections(newListSections)
  }

  const changeSectionToRegular = (id) => {
    const newListSections = [...listSections]
    // Encontrar la posición de la sección actual seleccionada
    const index = newListSections.findIndex(section => section.id === id)

    const newSection = {
      ...newListSections[index],
      sectionTypeId: setSurveySectionRegularId(),
      // Convertir las preguntas de la sección actual seleccionada a preguntas de tipo CV
      listQuestion: newListSections[index].listQuestion.map((question, index) => {
        const newQuestion = {
          ...question,
          // body: addTagsToText(question.body),
          surveyQuestionOptions: question?.surveyQuestionOptionsRegular || question.surveyQuestionOptions,
          questionTypeId: question?.questionTypeIdRegular || getQuestionTypeRadio(),
          extraOptions: [],
          rankingQuantity: question?.rankingQuantityRegular || 0, //*
          icon: question?.iconRegular || null, //*
          position: index + 1,
          // isRequired: false
        }
        return newQuestion
      })
    }
    newListSections[index] = newSection

    setListSections(newListSections)
  }

  const deleteSection = (id) => {
    if (isCloseStatus) return
    const copyListSections = [...listSections]
    const newListSections = copyListSections.filter(section => section.id !== id)

    const newListSectionsReorder = newListSections.map((section, index) => {
      const newSection = {
        ...section,
        position: index + 1
      }
      return newSection
    })

    setListSections(newListSectionsReorder)
  }

  // Agregar nueva pregunta en una sección seleccionada en su campo listQuestion al principio
  const addNewQuestionInSection = (id) => {
    const newListSections = [...listSections]
    // Encontrar la posición de la sección actual seleccionada
    const sectionIndex = newListSections.findIndex(section => section.id === id)
    const newQuestion = {
      id: uuidv4(),
      body: "",
      questionTypeId: getQuestionTypeText(),
      surveyQuestionOptions: [],
      extraOptions: [],
      rankingQuantity: 0,
      icon: null,
      position: newListSections[sectionIndex].listQuestion.length === 0 ? 1 : newListSections[sectionIndex].listQuestion[newListSections[sectionIndex].listQuestion.length - 1].position + 1,
      isRequired: false
    }
    newListSections[sectionIndex].listQuestion.unshift(newQuestion)

    // Reordenar las preguntas de la sección actual seleccionada
    const newListQuestionsReorder = newListSections[sectionIndex].listQuestion.map((question, index) => {
      const newQuestion = {
        ...question,
        position: index + 1
      }
      return newQuestion
    })

    newListSections[sectionIndex].listQuestion = newListQuestionsReorder
    setListSections(newListSections)
  }

  // Agregar una nueva pregunta despues de la pregunta actual seleccionada de la sección actual seleccionada
  const addNewQuestionAfterSelectedQuestion = (sectionId, questionId) => {
    const newListSections = [...listSections]
    // Encontrar la posición de la sección actual seleccionada
    const sectionIndex = newListSections.findIndex(section => section.id === sectionId)
    // Encontrar la posición de la pregunta actual seleccionada
    const questionIndex = newListSections[sectionIndex].listQuestion.findIndex(question => question.id === questionId)
    const newQuestion = {
      id: uuidv4(),
      body: "",
      questionTypeId: getQuestionTypeText(),
      surveyQuestionOptions: [],
      extraOptions: [],
      rankingQuantity: 0,
      icon: null,
      position: newListSections[sectionIndex].listQuestion[questionIndex].position + 1,
      isRequired: false
    }
    newListSections[sectionIndex].listQuestion.splice(questionIndex + 1, 0, newQuestion)

    const newListQuestionsReorder = newListSections[sectionIndex].listQuestion.map((question, index) => {
      const newQuestion = {
        ...question,
        position: index + 1
      }
      return newQuestion
    })

    newListSections[sectionIndex].listQuestion = newListQuestionsReorder

    setListSections(newListSections)
  }

  // const addNewQuestion = () => {
  //   setListQuestion([...listQuestion, {
  //     id: uuidv4(),
  //     body: "",
  //     questionTypeId: getQuestionTypeText(),
  //     surveyQuestionOptions: [],
  //     extraOptions: [],
  //     rankingQuantity: 0,
  //     icon: null,
  //     position: listQuestion.length === 0 ? 1 : listQuestion[listQuestion.length - 1].position + 1
  //   }])
  // }

   // const deleteQuestion = (id) => {
  //   if (isCloseStatus) return
  //   const copyListQuestion = [...listQuestion]
  //   const newListQuestion = copyListQuestion.filter(option => option.id !== id)
  //   setListQuestion(newListQuestion)
  // }

  const deleteQuestionInSection = (sectionId, questionId) => {
    if (isCloseStatus) return
    const newListSections = [...listSections]
    // Encontrar la posición de la sección actual seleccionada
    const sectionIndex = newListSections.findIndex(section => section.id === sectionId)
    // Encontrar la posición de la pregunta actual seleccionada
    const questionIndex = newListSections[sectionIndex].listQuestion.findIndex(question => question.id === questionId)
    newListSections[sectionIndex].listQuestion.splice(questionIndex, 1)

    // Reordenar las preguntas de la sección actual seleccionada
    const newListQuestions = newListSections[sectionIndex].listQuestion.map((question, index) => {
      const newQuestion = {
        ...question,
        position: index + 1
      }
      return newQuestion
    })

    newListSections[sectionIndex].listQuestion = newListQuestions
    setListSections(newListSections)
  }

  // Reordenar los item Dnd
  const reorder = (list, startIndex, endIndex) => {
    if (isCloseStatus) return
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    const newList = result.map((option, index) => {
      const newOption = {
        ...option,
        position: index + 1
      }
      return newOption
    })
  
    return newList;
    // return result;
  };

  // Funcion para mover las preguntas entre las secciones
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    // Remover el item de la lista de origen
    const [removed] = sourceClone.splice(droppableSource.index, 1); 
  
    // Agregar el item a la lista de destino
    destClone.splice(droppableDestination.index, 0, removed); 
  
    const result = {};
    // Se actualiza las posiciones de la lista de origen
    result[droppableSource.droppableId] = sourceClone.map((option, index) => {
      const newOption = {
        ...option,
        position: index + 1
      }
      return newOption
    }); 

    // Se actualiza las posiciones de la lista de destino
    result[droppableDestination.droppableId] = destClone.map((option, index) => {
      const newOption = {
        ...option,
        position: index + 1
      }
      return newOption
    });
  
    return result;
  }

  const onDragEnd = (result, list, setList) => {
    if (isCloseStatus) return
    if (!result.destination) return
    if (result.destination.index === result.source.index) return
    // console.log({ result, list, setList })
    const items = reorder(
      // listQuestion,
      list,
      result.source.index,
      result.destination.index
    );

    // setListQuestion(items)
    setList(items)
  }

  const onDragSectionEnd = (result) => {
    if (isCloseStatus) return
    const { source, destination } = result;
    // console.log(result)
    if (!destination) return
    // Permitir si son misma index pero de diferente sección
    if (destination.index === source.index && destination.droppableId === source.droppableId) return
    if (source.droppableId === destination.droppableId) {
      const sectionId = source.droppableId.split('_')[2]
      const newListSections = [...listSections]
      const newListSectionReorder = newListSections.map((section, index) => {
        if (section.id === +sectionId) {
          console.log({ sectionListQuestion: section.listQuestion, source, destination })

          return {
            ...section,
            listQuestion: reorder(
              section.listQuestion,
              source.index,
              destination.index
            )
          }
        }
        return section
      })
      // console.log({ result, newListSectionReorder })
      setListSections(newListSectionReorder)
    } else {
      // Mover entre las secciones las preguntas
      const sourceSectionId = +source.droppableId.split('_')[2]
      const destinationSectionId = +destination.droppableId.split('_')[2]
      const newListSections = [...listSections]
      const sourceSectionIndex = newListSections.findIndex(section => section.id === sourceSectionId)
      const destinationSectionIndex = newListSections.findIndex(section => section.id === destinationSectionId)
      const sectionsMoved = move(
        newListSections[sourceSectionIndex].listQuestion,
        newListSections[destinationSectionIndex].listQuestion,
        source,
        destination
      );

      const newListSectionsReorder = newListSections.map((section, index) => {
        if (section.id === sourceSectionId) {
          return {
            ...section,
            listQuestion: sectionsMoved[source.droppableId]
          }
        }
        if (section.id === destinationSectionId) {
          return {
            ...section,
            listQuestion: sectionsMoved[destination.droppableId]
          }
        }
        return section
      })
      setListSections(newListSectionsReorder)
    }
  }

  return {
    //* Form
    control,
    errors,
    valuesFields,
    existError,
    setValue,
    reset,
    handleSubmit,
    validateLength,
    
    //* Question
    // listQuestion,
    // setListQuestion,
    // addNewQuestion,
    // deleteQuestion,
    
    //* Section
    listSections,
    setListSections,
    addNewSectionAfterSelectedSection,
    addNewSectionGridAfterSelectedSection,
    deleteSection,
    changeSectionToGrid,
    changeSectionToRegular,

    //* Section - Question
    addNewQuestionInSection,
    addNewQuestionAfterSelectedQuestion,
    deleteQuestionInSection,

    //* Dnd
    onDragEnd,
    onDragSectionEnd,

    //* Constans
    // SurveySectionType
  }
}

export default useSurvey