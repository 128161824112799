import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import useSetTitlePage from "shared/hooks/useSetTitlePage";

import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { Editor } from "primereact/editor";
import { Tree } from 'primereact/tree';

import { ProcessManagementServices } from "../services/ProcessManagementServices";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { Ficha } from "../components/Ficha";
import { GeneralDataProcess } from "../components/GeneralDataProcess";
import { toastAction } from "core/store/actions/ToastAction";
import { CoreConstants } from "core/commons/CoreConstants";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DocumentComponent } from "../components/Documents/DocumentComponent";

const header = <>
<span className="ql-formats">
	<button className="ql-bold" aria-label="Bold"></button>
	<button className="ql-italic" aria-label="Italic"></button>
	<button className="ql-underline" aria-label="Underline"></button>
</span>
<span className="ql-formats">
	<button
		className="ql-list"
		aria-label="Ordered"
		value="ordered"
	></button>
	<button className="ql-list" aria-label="Bullet" value="bullet"></button>
</span>
</>

const validateLength = (value) => {
	if (typeof value === 'string') {
		return value.replace(/<[^>]+>/g, '')?.length;
	} else if (value?.htmlValue) {
		return value.htmlValue.replace(/<[^>]+>/g, '').length;
	}
	return '0';
};

const Users = ({labor, nombre, fecha, rol, className}) => {
	return (
		<div className={`flex flex-col pl-2 ${className}`}>
			<div>
				<span className="font-bold">{labor.toUpperCase()}: </span>{fecha}
			</div>
			<div>
				{nombre} - {rol}
			</div>
		</div>
	)
}

export const PageNewProcess = () => {

	// const dataMaster = useSelector(state => state.ProcessManagementReducer?.dataMaster)
	const dispatch = useDispatch()
	const [dataMaster, setDataMaster] = useState({})
	const [isLoadingMap, setIsLoadingMap] = useState(true);
	const [isLoadingMaster, setIsLoadingMaster] = useState(true);
	const [visible, setVisible] = useState(false)
	const [responsibleSelect, setResponsibleSelect] = useState()
	const [responsibles, setResponsibles] = useState([])
	const [date, setDate] = useState(new Date())
	const [list, setList] = useState({})
	const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
	const [processFormSectionId, setProcessFormSectionId] = useState()
	const [processFormId, setProcessFormId] = useState()

	const [controls, setControls] = useState([])
	const [type, setType] = useState([])
	const [isLoadingSheet, setIsLoadingSheet] = useState(true)
	const [isLoadingControls, setIsLoadingControls] = useState(true)
	const [viewModalDocument, setViewModalDocument] = useState(false)
	const [listDocuments, setListDocuments] = useState([])
	const [visibleDocument, setVisibleDocument] = useState(false)
	const [selectDoc, setSelectDoc] = useState(null)

	const history = useHistory();
	const {updateTitle } = useSetTitlePage()

	useEffect(()=> {
    updateTitle({
      title: "Gestión de Procesos",
      subtitle: "Mapa de Procesos",
      description: "Nuevo proceso",
      previousUrl: "/mapa-procesos/mapa-proceso",
    })
  }, [])

	useEffect(()=>{
		setIsLoadingMaster(true)
		ProcessManagementServices.getDataMaster().then(({data})=>{
			setDataMaster(data.data)
			
			setResponsibles(data.data.responsibles)
			setIsLoadingMaster(false)
		})
	},[])

	const getCreationDate = (rowData) => {
		if(rowData.creationDate)
			return moment(rowData.creationDate).format(CoreConstants.Format.LOCALE)
		else
			return null
	}

	const getEndedDate = (rowData) => {
		if(rowData.latestChangeDate)
			return moment(rowData.latestChangeDate).format(CoreConstants.Format.LOCALE)
		else
			return null
	}

	const columns = [
    { field: 'name', header: 'Nombre' },
    { field: 'owner', header: 'Propietario' },
    { field: 'creationDate', header: 'Fecha de Creación' },
    { field: 'latestChangeDate', header: 'Última Modificación' }
  ];


	const getNameColumn = (rowData) => {
		return <div><a href={rowData.url}>{rowData.name}</a></div>
	}

	const columnComponents = columns.map(col => {

		if (col.field === 'creationDate')
				return <Column key={col.field} field={col.field} header={col.header} body={getCreationDate} sortable />;

		if (col.field === 'latestChangeDate')
				return <Column key={col.field} field={col.field} header={col.header} body={getEndedDate} sortable />;

		if (col.field === 'name')
				return <Column key={col.field} field={col.field} header={col.header} body={getNameColumn} sortable />;

		return <Column key={col.field} field={col.field} header={col.header} sortable />;
});

	const actionBodyTemplate = (row) => {
		return <div className="flex justify-center"><i className="pi pi-trash cursor-pointer" onClick={()=> {
			setSelectDoc(row)
			setVisibleDocument(true)
		}}></i></div>
	}

	useEffect(()=>{
		setIsLoadingSheet(true)
		setIsLoadingControls(true)
		ProcessManagementServices.getActiveSheet().then(({data})=>{
			setProcessFormId(data.data?.processId)
			if(data.data?.section){
				const aux = {}
				data.data?.section?.controls.filter((item)=>item.controlId === 21).forEach((item)=>{
					aux[item.id] = item.details
				})
				setProcessFormSectionId(data.data?.section?.id)
				setList(aux)
				setControls(data.data?.section?.controls)
				setIsLoadingSheet(false)
			}else{
				const aux = [
					{
							"id": 656,
							"text": "Texto simple requerido ( solo es etiqueta del campo y requerido )",
							"position": 1,
							"prefix": null,
							"sufix": null,
							"startSequence": null,
							"identifier": "texto_simple_requerido_(_solo_es_etiqueta_del_campo_y_requerido_)",
							"required": true,
							"controlId": 18,
							"readonly": true,
							"showHeader": false,
							"details": []
					},
					{
							"id": 655,
							"text": "Texto simple no requerido ( solo es etiqueta del campo y no requerido )",
							"position": 2,
							"prefix": null,
							"sufix": null,
							"startSequence": null,
							"identifier": "texto_simple_no_requerido_(_solo_es_etiqueta_del_campo_y_no_requerido_)",
							"required": false,
							"controlId": 18,
							"readonly": true,
							"showHeader": false,
							"details": []
					},
					{
							"id": 657,
							"text": "Texto largo requerido ( solo es etiqueta del campo y requerido )",
							"position": 3,
							"prefix": null,
							"sufix": null,
							"startSequence": null,
							"identifier": "texto_largo_requerido_(_solo_es_etiqueta_del_campo_y_requerido_)",
							"required": true,
							"controlId": 19,
							"readonly": true,
							"showHeader": false,
							"details": []
					},
					{
							"id": 658,
							"text": "Texto largo no requerido ( solo es etiqueta del campo y no requerido )",
							"position": 4,
							"prefix": null,
							"sufix": null,
							"startSequence": null,
							"identifier": "texto_largo_no_requerido_(_solo_es_etiqueta_del_campo_y_requerido_)",
							"required": false,
							"controlId": 19,
							"readonly": true,
							"showHeader": false,
							"details": []
					},
					{
							"id": 659,
							"text": "Texto dinamico requerido ( solo es etiqueta del campo y requerido )",
							"position": 5,
							"prefix": null,
							"sufix": null,
							"startSequence": null,
							"identifier": "texto_dinamico_requerido_(_solo_es_etiqueta_del_campo_y_requerido_)",
							"required": true,
							"controlId": 20,
							"readonly": true,
							"showHeader": false,
							"details": []
					},
					{
							"id": 660,
							"text": "Texto dinamico no requerido ( solo es etiqueta del campo y no requerido )",
							"position": 6,
							"prefix": null,
							"sufix": null,
							"startSequence": null,
							"identifier": "texto_dinamico_no_requerido_(_solo_es_etiqueta_del_campo_y_no_requerido_)",
							"required": false,
							"controlId": 20,
							"readonly": true,
							"showHeader": false,
							"details": []
					},
					{
							"id": 661,
							"text": "Lista desplegable requerido ( solo es etiqueta y requerido y opciones, pondre 3)",
							"position": 7,
							"prefix": null,
							"sufix": null,
							"startSequence": null,
							"identifier": "lista_desplegable_requerido_(_solo_es_etiqueta_y_requerido_y_opciones,_pondre_3)",
							"required": true,
							"controlId": 21,
							"readonly": true,
							"showHeader": false,
							"details": [
									{
											"id": 667,
											"text": "Opcion 1",
											"controlId": 24,
											"details": []
									},
									{
											"id": 681,
											"text": "Opcion 3",
											"controlId": 24,
											"details": []
									},
									{
											"id": 682,
											"text": "Opcion 2",
											"controlId": 24,
											"details": []
									}
							]
					},
					{
							"id": 662,
							"text": "Lista desplegable no requierido (solo es etiqueta y no requerido, y 3 opciones)",
							"position": 8,
							"prefix": null,
							"sufix": null,
							"startSequence": null,
							"identifier": "lista_desplegable_no_requierido_(solo_es_etiqueta_y_no_requerido,_y_3_opciones)",
							"required": false,
							"controlId": 21,
							"readonly": true,
							"showHeader": false,
							"details": [
									{
											"id": 678,
											"text": "Opcion 3",
											"controlId": 24,
											"details": []
									},
									{
											"id": 679,
											"text": "Opcion 2",
											"controlId": 24,
											"details": []
									},
									{
											"id": 680,
											"text": "Opcion 1",
											"controlId": 24,
											"details": []
									}
							]
					},
					// {
					// 		"id": 663,
					// 		"text": "Seleccion simple requerido (es etiqueta , requerido y pondre 3 opciones)",
					// 		"position": 9,
					// 		"prefix": null,
					// 		"sufix": null,
					// 		"startSequence": null,
					// 		"identifier": "seleccion_simple_requerido_(es_etiqueta_,_requerido_y_pondre_3_opciones)",
					// 		"required": true,
					// 		"controlId": 22,
					// 		"readonly": true,
					// 		"showHeader": false,
					// 		"details": [
					// 				{
					// 						"id": 675,
					// 						"text": "opcccion 3",
					// 						"controlId": 24,
					// 						"details": []
					// 				},
					// 				{
					// 						"id": 676,
					// 						"text": "opcion 2",
					// 						"controlId": 24,
					// 						"details": []
					// 				},
					// 				{
					// 						"id": 677,
					// 						"text": "opcion 1",
					// 						"controlId": 24,
					// 						"details": []
					// 				}
					// 		]
					// },
					// {
					// 		"id": 664,
					// 		"text": "Seleccion simple no requerido ( etiqueta, no requerido y 3 opciones)",
					// 		"position": 10,
					// 		"prefix": null,
					// 		"sufix": null,
					// 		"startSequence": null,
					// 		"identifier": "seleccion_simple_no_requerido_(_etiqueta,_no_requerido_y_3_opciones)",
					// 		"required": false,
					// 		"controlId": 22,
					// 		"readonly": true,
					// 		"showHeader": false,
					// 		"details": [
					// 				{
					// 						"id": 672,
					// 						"text": "opc 3",
					// 						"controlId": 24,
					// 						"details": []
					// 				},
					// 				{
					// 						"id": 673,
					// 						"text": "opc 2",
					// 						"controlId": 24,
					// 						"details": []
					// 				},
					// 				{
					// 						"id": 674,
					// 						"text": "opc 1",
					// 						"controlId": 24,
					// 						"details": []
					// 				}
					// 		]
					// },
					{
							"id": 665,
							"text": "Tabla (etiqueta, con cabecera y 3 columnas por ahora solo texto)",
							"position": 11,
							"prefix": null,
							"sufix": null,
							"startSequence": null,
							"identifier": "tabla_(etiqueta,_con_cabecera_y_3_columnas_por_ahora_solo_texto)",
							"required": true,
							"controlId": 23,
							"readonly": false,
							"showHeader": false,
							"details": [
									{
											"id": 669,
											"text": "label 3",
											"controlId": 18,
											"details": []
									},
									{
											"id": 670,
											"text": "label 2",
											"controlId": 18,
											"details": []
									},
									{
											"id": 671,
											"text": "label 1",
											"controlId": 18,
											"details": []
									}
							]
					},
					// {
					// 		"id": 666,
					// 		"text": "Tabla ( etiqueta, sin cabcera y 3 columnas de texto)",
					// 		"position": 12,
					// 		"prefix": null,
					// 		"sufix": null,
					// 		"startSequence": null,
					// 		"identifier": "tabla_(_etiqueta,_sin_cabcera_y_3_columnas_de_texto)",
					// 		"required": true,
					// 		"controlId": 23,
					// 		"readonly": false,
					// 		"showHeader": true,
					// 		"details": [
					// 				{
					// 						"id": 668,
					// 						"text": "label 1",
					// 						"controlId": 18,
					// 						"details": []
					// 				},
					// 				{
					// 						"id": 683,
					// 						"text": "label 2",
					// 						"controlId": 18,
					// 						"details": []
					// 				},
					// 				{
					// 						"id": 684,
					// 						"text": "label 3",
					// 						"controlId": 18,
					// 						"details": []
					// 				}
					// 		]
					// }
				]
				setControls([])
				setIsLoadingSheet(false)
			}
		})
		ProcessManagementServices.listControls().then(({data})=>{
			
			setType(data.data)
			setIsLoadingControls(false)
		})
	},[])

	const {
		register,
		control,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
} = useForm({
		defaultValues: {
			code: "",
			name: "",
			processTypeId: "",
			responsibleId: "",
			description: "",
			scope: "",
			processDate: new Date(),
			elaboratedById: "",
	}	
});

	const valuesFields = watch()

	const submit = (data, type) => {
		const {
			code,
			name,
			processTypeId,
			responsibleId,
			description,
			scope,
			processDate,
			elaboratedById,
			...details
		} = data

		Object.keys(details).forEach(key=>{
			if(details[key]===undefined){
				delete details[key]
			}
		})
		
		const aux = Object.keys(details).map(key=>{
			if(Object.keys(list).includes(key)){
				return {id:null, processFormControlId: Number(key), valueText: list[key].filter(item=>Number(item.id)===Number(details[key]))[0].text, processFormSectionId, valueReferenceId: Number(details[key])}
			}
			return {id:null, processFormControlId: isNaN(Number(key))?Number(key.split("-")[0]):Number(key), valueText: String(details[key]), processFormSectionId, valueReferenceId: null}
		
		})
		const send = {
			processFormId,
			code,
			name,
			processTypeId,
			responsibleId,
			description,
			scope,
			processDate,
			elaboratedById,
			details: aux,
			documents: listDocuments.map(el=> {
				return {
					id: el.mode==='edit'?el.id:null,
					processId: processFormId,
					documentId: el.documentId
				}
			})
		}

		Object.keys(send).forEach((key)=>{
			if(send[key] === ""){
				delete send[key]
			}
		})

		

		

		if(type === 1){
			
			
			setIsLoadingSubmit(true)
			ProcessManagementServices.saveProcess(send).then(({data})=>{
				setIsLoadingSubmit(false)
				if(data.status === CoreConstants.HttpResponse.OK){
					dispatch(toastAction.success("Proceso" ,'Guardado correctamente'))
					history.push('/mapa-procesos/mapa-proceso')
				}else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
					dispatch(toastAction.warn('Error', data.message))
				}else if(data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS){
					dispatch(toastAction.warn('Error', data.message))
				}
			}).catch((error)=>{
				setIsLoadingSubmit(false)
			});
		}
	}
	
	return (
		<div className="bg-white">
		<div className="bg-white flex gap-4 flex-wrap md:flex-nowrap">
			<div style={{width: "-webkit-fill-available"}}>
				<form
						className="form-custom p-0"
						
				>
						<div className="form-modal p-0 w-auto">
						<Panel header="DATOS GENERALES" toggleable>
										<div className="grid sm:grid-cols-3 md:grid-cols-3 gap-2">
												{isLoadingMap && isLoadingMaster ? (
														<Skeleton height="2.2rem" width="100%"/>
												) : (
														<span className="p-float-label w-full mt-4">
																<Controller
																		control={control}
																		render={({
																				field: { onChange, onBlur, value, name, ref },
																				fieldState: { invalid, isTouched, isDirty, error },
																		}) => (
																				<InputText
																						type="text"
																						id="code"
																						name="code"
																						onChange={onChange}
																						onBlur={onBlur}
																						value={value}
																						maxLength={10}
																						className={errors?.code ? 'p-invalid w-full' : 'w-full'}
																				/>
																		)}
																		name="code"
																		rules={{
																				required: 'El Código del Proceso es requerido',
																		}}
																/>
																<label htmlFor="code">Código del Proceso *</label>
														</span>
												)}

												<span className="p-float-label w-full mt-4 hidden md:block">
														&nbsp;
												</span>
												<span className="p-float-label w-full mt-4 hidden md:block">
														&nbsp;
												</span>
										</div>

										{/* PROCESS NAME */}

										<div className="grid  sm:grid-cols-1 md:grid-cols-1 gap-2 mt-2 ">
												{isLoadingMap && isLoadingMaster ? (
																<Skeleton height="2.2rem" width="20rem"/>
														) : (
																<span className="p-float-label w-full mt-4">

																		<Controller
																				control={control}
																				render={({
																						field: { onChange, onBlur, value, name, ref },
																						fieldState: { invalid, isTouched, isDirty, error },
																				}) => (
																						<InputText
																								id="name"
																								name="name"                        
																								onChange={onChange}
																								onBlur={onBlur}
																								value={value}
																								className={errors?.name ? 'p-invalid w-full' : 'w-full'}
																						/>
																				)}
																				name="name"
																				rules={{
																				required: 'El Nombre de Proceso es requerido',
																		}}
																		/>
																		<label htmlFor="name">Nombre del Proceso *</label>
																</span>
																
												)}
										</div>

										{/* PROCESS TYPE */}

										<div className="grid sm:grid-cols-3 md:grid-cols-3 gap-2 mt-2">
												{isLoadingMap && isLoadingMaster ? (
														<Skeleton height="2.2rem" />
												) : (
														<span className="p-float-label w-full mt-4">
																<Controller
																		control={control}
																		render={({
																				field: { onChange, onBlur, value, name, ref },
																				fieldState: { invalid, isTouched, isDirty, error },
																		}) => (
																			<Dropdown
																			options={dataMaster.processTypes?.map(item=> ({label: item.name, value: item.id}))}  
																						
																						id="processTypeId"
																						name="processTypeId"
																						
																						
																						showClear
																						inputRef={ref}

																						onChange={onChange}
																						value={value}
																						className={errors?.processTypeId ? 'p-invalid w-full' : 'w-full'}
																				/>
																		)}
																		name="processTypeId"
																		rules={{
																				required: 'El Tipo del proceso es requerido',	
																		}}
																/>
																<label htmlFor="processTypeId">Tipo del proceso *</label>
														</span>
												)}
                    </div>

												<div className="grid sm:grid-cols-3 md:grid-cols-3 gap-2 mt-2">
												{isLoadingMap && isLoadingMaster ? (
														<Skeleton height="2.2rem" />
												) : (
														<span className="p-float-label w-full mt-4">

																<Controller
																		control={control}
																		render={({
																				field: { onChange, onBlur, value, name, ref },
																				fieldState: { invalid, isTouched, isDirty, error },
																		}) => (
																				<Dropdown
																						optionLabel="label"
																						filter
																						filterBy="label"
																						showClear
																						id="responsibleId"
																						name="responsibleId"
																						options={dataMaster.responsibles?.map(item=> ({label: item.fullName, value: item.id}))}     
																						onChange={onChange}
																						value={value}
																						className={errors?.responsibleId ? 'p-invalid w-full' : 'w-full'}
																				/>
																		)}
																		name="responsibleId"
																/>
																<label htmlFor="responsibleId">Responsable del proceso</label>
														</span>
												)}
												<span className="p-float-label w-full mt-4 hidden md:block">
														&nbsp;
												</span>
										</div>

                    {/* <div className="grid sm:grid-cols-3 md:grid-cols-3 gap-2 mt-2">
												{isLoadingMap && isLoadingMaster ? (
														<Skeleton height="2.2rem" />
												) : (
														<span className="p-float-label w-full mt-4">
																<Controller
                                  control={control}
                                  name="sectionNumber"
                                  rules={{
                                      required: 'La sección es requerida',	
                                  }}
                                  render={({field: { onChange, value }}) => (
                                    <Dropdown
                                      options={ (() => {
                                        const quantity = dataMaster.processTypes
                                        ?.find( proc => proc.id ===  valuesFields.processTypeId)
                                        ?.sectionQuantity

                                        const options = []

                                        for( let i = 0; i<quantity; i++){
                                          options.push(
                                            {
                                              value: i+1,
                                              label: `Sección ${i+1}`
                                            }
                                          )
                                        }

                                        return options
                                      })()

                                      }  
                                      name="sectionNumber"
                                      showClear
                                      onChange={onChange}
                                      value={value}
                                      className={errors?.sectionNumber ? 'p-invalid w-full' : 'w-full'}
                                    />
                                  )}
																/>
																<label htmlFor="sectionNumber">Sección *</label>
														</span>
												)}
                    </div> */}

							<div className="mt-5">
								{isLoadingMap && isLoadingMaster ? (
														<Skeleton height="2.2rem" />
												) : (
                <Controller
									name="description"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <span className="w-full mt-4">
											<label htmlFor="description" style={{fontSize: "0.75rem", color:"#ced4da"}}>Objetivo del proceso</label>
                      <Editor
												id="description"
                        headerTemplate={header}
                        onTextChange={(e) => onChange(e.htmlValue)}
                        value={value}
												name="description"
                        className={`${errors.description?.type ==='required' ? 'p-invalid': ''}`}
                      />
                      <p className="font-semibold text-base text-gray-500 lg:mt-1 mt-3 text-right">
                        Caracteres{' '}
                        {validateLength(
                          valuesFields.description
                        )}{' '}
                        / 4000{' '}
                      </p>
                    </span>
                  )}
                />)
												}
            	</div>          

							<div className="mt-5">
							{isLoadingMap && isLoadingMaster ? (
														<Skeleton height="2.2rem" />
												) : (
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <span className="w-full mt-4">
											<label htmlFor="scope" style={{fontSize: "0.75rem", color:"#ced4da"}}>Alcance del proceso</label>
                      <Editor
                        headerTemplate={header}
                        onTextChange={(e) => onChange(e.htmlValue)}
                        value={value}
												name="scope"
												id="scope"
                        className={`${errors.scope?.type ==='required' ? 'p-invalid': ''}`}
                        
                      />
                      <p className="font-semibold text-base text-gray-500 lg:mt-1 mt-3 text-right">
                        Caracteres{' '}
                        {validateLength(
                          valuesFields.scope
                        )}{' '}
                        / 4000{' '}
                      </p>
                    </span>
                  )}
                  name="scope"
                />)
							}
            </div>    

						

								</Panel>
							
							<Panel header="FICHA DE CARACTERIZACIÓN " toggleable>
								<div>
									<Ficha control={control} errors={errors} list={list} setList={setList} setProcessFormSectionId={setProcessFormSectionId} setProcessFormId={setProcessFormId} setValue={setValue} row={1} 
									isLoadingSheet={isLoadingSheet} isLoadingControls={isLoadingControls} controls={controls} type={type}/>
								</div>
							</Panel>
							<Panel header="DOCUMENTOS">
								<Button className="sig-button sig-primary mb-3" type="button" label="Añadir Documento" onClick={()=> setViewModalDocument(true)}/>
								<DataTable
									value={listDocuments}
									stripedRows
									responsiveLayout="scroll"
									size="small"
									emptyMessage="No se encontraron documentos"
								>
									{columnComponents}
									<Column header="" body={actionBodyTemplate} ></Column>
								</DataTable>
							</Panel>
							<DocumentComponent visible={viewModalDocument} setVisible={setViewModalDocument} listDocuments={listDocuments} setListDocuments={setListDocuments}/>
							{
	(Object.keys(errors).length > 0) && 
		<div className='message error'>
			<ul>
					{
						Object.keys(errors).map((item, index)=>{return(
									<li key={index} className="p-error">{errors[item].message}</li>
						)})
					}      
			</ul>
		</div>
}
							{/* {

(errors.code ||
errors.name || 
errors.processTypeId || 
errors.responsibleId || 
errors.description || 
errors.scope ||
errors.elaboratedById ||
errors.processDate
) && <div className='message error'>
		<ul>
				{errors.code ? (
						<>
								<li className="p-error">{errors.code.message}</li>
						</>
				) : null}
				{errors.name ? (
						<>
								<li className="p-error">{errors.name.message}</li>
						</>
				) : null}
				{errors.processTypeId ? (
						<>
								<li className="p-error">{errors.processTypeId.message}</li>
						</>
				) : null}
				{errors.responsibleId ? (
						<>
								<li className="p-error">{errors.responsibleId.message}</li>
						</>
				) : null}
				{errors.description ? (
						<>
								<li className="p-error">{errors.description.message}</li>
						</>
				) : null}

				{errors.scope ? (
						<>
								<li className="p-error">{errors.scope.message}</li>
						</>
				) : null}   

				{errors.processDate ? (
						<>
								<li className="p-error">{errors.processDate.message}</li>
						</>
				) : null}      

				{errors.elaboratedById ? (
						<>
								<li className="p-error">{errors.elaboratedById.message}</li>
						</>
				) : null}                    
		</ul>

</div>
} */}
						</div>
				</form>
				<ConfirmDialog
						visible={visible}
						onHide={() => setVisible(false)}
						message="¿Está seguro que desea cancelar?"
						header="Cancelar"
						icon="pi pi-exclamation-triangle"
						accept={() => { 
							// dispatch(BusinessAction.getDataMaster())
							history.push('/mapa-procesos/mapa-proceso')}}
				/>
				<ConfirmDialog
						visible={visibleDocument}
						onHide={() => {
							setVisibleDocument(false)
							setSelectDoc(null)
						}}
						message="¿Está seguro que desea eliminar el documento?"
						header="Eliminar"
						icon="pi pi-exclamation-triangle"
						accept={() => {
							setListDocuments(listDocuments.filter(el=> el.documentId!==selectDoc?.documentId))
						}}
				/>
				</div>
				<div style={{width: '20rem'}}> 
				<form>
					<GeneralDataProcess isLoadingMaster={isLoadingMaster} control={control} errors={errors} dataMaster={dataMaster} handleSubmit={handleSubmit} />
				</form> 
			</div>
			</div>
			<div className="flex justify-end gap-4 mt-3">
								<Button
										icon="pi pi-times"
										type="button"
										label="Cancelar"
										loading={isLoadingSubmit}
										onClick={() => { setVisible(true); }}
										className="sig-button sig-secondary mt-4"
								/>
								<Button
										icon="pi pi-save"
										type="submit"
										label="Guardar"
										loading={isLoadingSubmit}
										className="sig-button sig-primary mt-4"
										onClick={handleSubmit((data)=>submit(data, 1))}
								/>
						</div>
		</div>

)
}