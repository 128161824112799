export class ProcessManagementConstans {
  static Accion = class {

    static ProcessDataMaster = class {
      static get GET_DATA_MASTER() {
        return "@PROCESS.GET_DATA_MASTER";
      }
      static get ADVANCED_FILTER() {
        return "@SITES.ADVANCED_FILTER";
      }
      static get SAVE_SITE() {
        return "@SITES.SAVE_SITE";
      }
      static get GET_REPORT() {
        return "@SITES.GET_REPORT";
      }
      static get IS_STORAGE_ENABLED() {
        return "@SITES.IS_STORAGE_ENABLED";
      }
      static get ADVANCED_FILTER_TOGGLE() {
        return "@SITES.ADVANCED_FILTER_TOGGLE";
      }
    };
    //ACADEMIC DEGREE CONSTANTS
    static AcademicDegree = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return "@ACADEMIC_DEGREE.ADVANCED-FILTER-TOGGLE";
      }

      static get SEARCH_ACADEMIC_DEGREES() {
        return "@ACADEMIC_DEGREE.SEARCH_ACADEMIC_DEGREES";
      }

      static get UPDATE_FILTER() {
        return "@ACADEMIC_DEGREE.UPDATE_FILTER";
      }

      static get GET_ACADEMIC_DEGREE_BY_ID() {
        return "@ACADEMIC_DEGREE.GET_ACADEMIC_DEGREE_BY_ID";
      }

      static get SAVE_ACADEMIC_DEGREE() {
        return "@ACADEMIC_DEGREE.SAVE_ACADEMIC_DEGREE";
      }

      static get DELETE_ACADEMIC_DEGREE() {
        return "@ACADEMIC_DEGREE.DELETE_ACADEMIC_DEGREE";
      }

      static get GET_DATA_MASTER() {
        return "@ACADEMIC_DEGREE.GET_DATA_MASTER";
      }

      static get RESET_AFTER_SAVED() {
        return "@ACADEMIC_DEGREE.RESET_AFTER_SAVED";
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return "@ACADEMIC_DEGREE.DOWNLOAD_REPORT_STATUS";
      }
    };

    //OCCUPATION  CONSTANTS
    static Occupation = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return "@OCCUPATION.ADVANCED-FILTER-TOGGLE";
      }

      static get SEARCH_OCCUPATIONS() {
        return "@OCCUPATION.SEARCH_OCCUPATIONS";
      }

      static get UPDATE_FILTER() {
        return "@OCCUPATION.UPDATE_FILTER";
      }

      static get GET_OCCUPATION_BY_ID() {
        return "@OCCUPATION.GET_OCCUPATION_BY_ID";
      }

      static get SAVE_OCCUPATION() {
        return "@OCCUPATION.SAVE_OCCUPATION";
      }

      static get DELETE_OCCUPATION() {
        return "@OCCUPATION.DELETE_OCCUPATION";
      }

      static get GET_DATA_MASTER() {
        return "@OCCUPATION.GET_DATA_MASTER";
      }

      static get RESET_AFTER_SAVED() {
        return "@OCCUPATION.RESET_AFTER_SAVED";
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return "@OCCUPATION.DOWNLOAD_REPORT_STATUS";
      }
    };
    //PROFESSION  CONSTANTS
    static Profession = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return "@PROFESSION.ADVANCED-FILTER-TOGGLE";
      }

      static get SEARCH_PROFESSIONS() {
        return "@PROFESSION.SEARCH_PROFESSIONS";
      }

      static get UPDATE_FILTER() {
        return "@PROFESSION.UPDATE_FILTER";
      }

      static get GET_PROFESSION_BY_ID() {
        return "@PROFESSION.GET_PROFESSION_BY_ID";
      }

      static get SAVE_PROFESSION() {
        return "@PROFESSION.SAVE_PROFESSION";
      }

      static get DELETE_PROFESSION() {
        return "@PROFESSION.DELETE_PROFESSION";
      }

      static get GET_DATA_MASTER() {
        return "@PROFESSION.GET_DATA_MASTER";
      }

      static get RESET_AFTER_SAVED() {
        return "@PROFESSION.RESET_AFTER_SAVED";
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return "@PROFESSION.DOWNLOAD_REPORT_STATUS";
      }
    };

    //INSTITUTION  CONSTANTS
    static Institution = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return "@INSTITUTION.ADVANCED-FILTER-TOGGLE";
      }

      static get SEARCH_INSTITUTIONS() {
        return "@INSTITUTION.SEARCH_INSTITUTIONS";
      }

      static get UPDATE_FILTER() {
        return "@INSTITUTION.UPDATE_FILTER";
      }

      static get GET_INSTITUTION_BY_ID() {
        return "@INSTITUTION.GET_INSTITUTION_BY_ID";
      }

      static get SAVE_INSTITUTION() {
        return "@INSTITUTION.SAVE_INSTITUTION";
      }

      static get DELETE_INSTITUTION() {
        return "@INSTITUTION.DELETE_INSTITUTION";
      }

      static get GET_DATA_MASTER() {
        return "@INSTITUTION.GET_DATA_MASTER";
      }

      static get RESET_AFTER_SAVED() {
        return "@INSTITUTION.RESET_AFTER_SAVED";
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return "@INSTITUTION.DOWNLOAD_REPORT_STATUS";
      }
    };

    //BUSINESSLINE CONSTANTS
    static BusinessLine = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return "@BUSINESS_LINE.ADVANCED-FILTER-TOGGLE";
      }

      static get SEARCH_BUSINESS_LINES() {
        return "@BUSINESS_LINE.SEARCH_BUSINESS_LINES";
      }

      static get UPDATE_FILTER() {
        return "@BUSINESS_LINE.UPDATE_FILTER";
      }

      static get GET_BUSINESS_LINE_BY_ID() {
        return "@BUSINESS_LINE.GET_BUSINESS_LINE_BY_ID";
      }

      static get SAVE_BUSINESS_LINE() {
        return "@BUSINESS_LINE.SAVE_BUSINESS_LINE";
      }

      static get DELETE_BUSINESS_LINE() {
        return "@BUSINESS_LINE.DELETE_BUSINESS_LINE";
      }

      static get GET_DATA_MASTER() {
        return "@BUSINESS_LINE.GET_DATA_MASTER";
      }

      static get RESET_AFTER_SAVED() {
        return "@BUSINESS_LINE.RESET_AFTER_SAVED";
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return "@BUSINESS_LINE.DOWNLOAD_REPORT_STATUS";
      }
    };

    //BUSINESSLINE CONSTANTS
    static BusinessSector = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return "@BUSINESS_SECTOR.ADVANCED-FILTER-TOGGLE";
      }

      static get SEARCH_BUSINESS_SECTORS() {
        return "@BUSINESS_SECTOR.SEARCH_BUSINESS_SECTORS";
      }

      static get UPDATE_FILTER() {
        return "@BUSINESS_SECTOR.UPDATE_FILTER";
      }

      static get GET_BUSINESS_SECTOR_BY_ID() {
        return "@BUSINESS_SECTOR.GET_BUSINESS_SECTOR_BY_ID";
      }

      static get SAVE_BUSINESS_SECTOR() {
        return "@BUSINESS_SECTOR.SAVE_BUSINESS_SECTOR";
      }

      static get DELETE_BUSINESS_SECTOR() {
        return "@BUSINESS_SECTOR.DELETE_BUSINESS_SECTOR";
      }

      static get GET_DATA_MASTER() {
        return "@BUSINESS_SECTOR.GET_DATA_MASTER";
      }

      static get RESET_AFTER_SAVED() {
        return "@BUSINESS_SECTOR.RESET_AFTER_SAVED";
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return "@BUSINESS_SECTOR.DOWNLOAD_REPORT_STATUS";
      }
    };

    //PIPELINE  CONSTANTS
    static Pipeline = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return "@PIPELINE.ADVANCED-FILTER-TOGGLE";
      }

      static get SEARCH_PIPELINES() {
        return "@PIPELINE.SEARCH_PIPELINES";
      }

      static get UPDATE_FILTER() {
        return "@PIPELINE.UPDATE_FILTER";
      }

      static get GET_PIPELINE_BY_ID() {
        return "@PIPELINE.GET_PIPELINE_BY_ID";
      }

      static get SAVE_PIPELINE() {
        return "@PIPELINE.SAVE_PIPELINE";
      }

      static get DELETE_PIPELINE() {
        return "@PIPELINE.DELETE_PIPELINE";
      }

      static get GET_DATA_MASTER() {
        return "@PIPELINE.GET_DATA_MASTER";
      }

      static get RESET_AFTER_SAVED() {
        return "@PIPELINE.RESET_AFTER_SAVED";
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return "@PIPELINE.DOWNLOAD_REPORT_STATUS";
      }
    };

    //EMPLOYER  CONSTANTS
    static Employer = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return "@EMPLOYER.ADVANCED-FILTER-TOGGLE";
      }

      static get SEARCH_EMPLOYERS() {
        return "@EMPLOYER.SEARCH_EMPLOYERS";
      }

      static get UPDATE_FILTER() {
        return "@EMPLOYER.UPDATE_FILTER";
      }

      static get GET_EMPLOYER_BY_ID() {
        return "@EMPLOYER.GET_EMPLOYER_BY_ID";
      }

      static get SAVE_EMPLOYER() {
        return "@EMPLOYER.SAVE_EMPLOYER";
      }

      static get DELETE_EMPLOYER() {
        return "@EMPLOYER.DELETE_EMPLOYER";
      }

      static get GET_DATA_MASTER() {
        return "@EMPLOYER.GET_DATA_MASTER";
      }

      static get RESET_AFTER_SAVED() {
        return "@EMPLOYER.RESET_AFTER_SAVED";
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return "@EMPLOYER.DOWNLOAD_REPORT_STATUS";
      }
    };

    //PROJECT ROLES CONSTANTS
    static ProjectRole = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return "@PROJECT_ROLE.ADVANCED-FILTER-TOGGLE";
      }

      static get SEARCH_PROJECT_ROLES() {
        return "@PROJECT_ROLE.SEARCH_PROJECT_ROLE";
      }

      static get UPDATE_FILTER() {
        return "@PROJECT_ROLE.UPDATE_FILTER";
      }

      static get GET_PROJECT_ROLE_BY_ID() {
        return "@PROJECT_ROLE.GET_PROJECT_ROLE_BY_ID";
      }

      static get SAVE_PROJECT_ROLE() {
        return "@PROJECT_ROLE.SAVE_PROJECT_ROLE";
      }

      static get DELETE_PROJECT_ROLE() {
        return "@PROJECT_ROLE.DELETE_PROJECT_ROLE";
      }

      static get GET_DATA_MASTER() {
        return "@PROJECT_ROLE.GET_DATA_MASTER";
      }

      static get RESET_AFTER_SAVED() {
        return "@PROJECT_ROLE.RESET_AFTER_SAVED";
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return "@PROJECT_ROLE.DOWNLOAD_REPORT_STATUS";
      }
    };

    static ProcesseSheet = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return '@PROCESSESHEET.ADVANCED-FILTER-TOGGLE';
      }

      static get SEARCH_PROCESSESHEET() {
        return '@PROCESSESHEET.SEARCH_PROCESSESHEET';
      }

      static get getListVersions() {
        return '@PROCESSESHEET.getListVersions';
      }

      static get GET_PRODUCED_BY() {
        return '@PROCESSESHEET.GET_PRODUCED_BY';
      }

      static get UPDATE_FILTER() {
        return '@PROCESSESHEET.UPDATE_FILTER';
      }

      static get RESTORE_PROCESSHEET() {
        return 'RESTORE_PROCESSHEET';
      }

      static get GET_PROCESSESHEET_BY_ID() {
        return '@PROCESSESHEET.GET_PROCESSESHEET_BY_ID';
      }

      static get SAVE_PROCESSESHEET() {
        return '@PROCESSESHEET.SAVE_PROCESSESHEET';
      }

      static get DELETE_PROCESSESHEET() {
        return '@PROCESSESHEET.DELETE_PROCESSESHEET';
      }

      static get GET_DATA_MASTER() {
        return '@PROCESSESHEET.GET_DATA_MASTER';
      }

      static get RESET_AFTER_SAVED() {
        return '@PROCESSESHEET.RESET_AFTER_SAVED';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@PROCESSESHEET.DOWNLOAD_REPORT_STATUS';
      }

      static get GET_OPTIONS_DROPDOWN() {
        return '@PROCESSESHEET.GET_OPTIONS_DROPDOWN';
      }

      static get SEARCH_SUMMARY() {
        return '@PROCESSESHEET.SEARCH_SUMMARY';
      }

      static get GET_ACTIVE_SHEET() {
        return '@PROCESSESHEET.GET_ACTIVE_SHEET';
      }

      static get GENERATE_VERSION() {
        return '@PROCESSESHEET.GENERATE_VERSION';
      }

      static get GET_LAST_SHEET() {
        return '@PROCESSESHEET.GET_LAST_SHEET';
      }
    };
  };
  static Aprobacion = class {

    static Tipo = class {
      static get PROCESO() {
        return "WPRC";
      }
      static get FICHA() {
        return "WSHT";
      }
    };
    static Estado = class {
      static get PUBLICADO(){
        return "PUBLICADO";
      }
      static get BORRADOR(){
        return "BORRADOR";
      }
      static get Borrador(){
        return "Borrador";
      }
      static get DESHABILITADO(){
        return "DESHABILITADO";
      }
      static get REVISION(){
        return "REVISION";
      }
    }
  };
}
