import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import ListQuestionSurvey from 'features/survey/components/Survey/ListQuestionSurvey';
import LogoComponent from 'shared/components/logo/LogoComponent';
import { Skeleton } from 'primereact/skeleton';
import useSurveyPublic from 'features/survey/hooks/Survey/useSurveyPublic';
import { SurveyPublicAction } from 'features/public/store/actions/SurveyPublicAction';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import { useLocation, useParams } from 'react-router-dom';
import MessageEmptyQuestion from 'features/survey/components/Survey/MessageEmptyQuestion';
import { SurveyPublicSection } from 'features/survey/components/Survey/SurveyPublicSection';
import { SurveyPublicSectionGrid } from 'features/survey/components/Survey/SurveyPublicSectionGrid';
import SurveyPublicMessage from 'features/survey/components/Survey/SurveyPublicMessage';

const SurveySectionType = Object.freeze({
  Regular: 'STRG',
  Grid: 'STML',
})

const SurveyPublic = ({title}) => {
  const {updateTitle } = useSetTitlePage()
  const params = useParams();
  const location = useLocation()
  const dispatch = useDispatch();
  const [isFirstLoading, setFirstLoading] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [questionResponseTime, setQuestionResponseTime] = useState([])
  const [isIosPlatform, setIsIosPlatform] = useState(false)
  // const { surveySectionTypes, isLoading: isLoadingDataMaster} = useSelector((state) => state.surveyReducer.dataMaster);
  const { surveySectionTypes, isLoading: isLoadingDataMaster} = useSelector((state) => state.surveyPublicReducer.dataMaster);

  // const setSurveySectionRegularId = () => surveySectionTypes.find(sectionType => sectionType.code === SurveySectionType.Regular)?.id
  // const setSurveySectionGridId = () => surveySectionTypes.find(sectionType => sectionType.code === SurveySectionType.Grid)?.id

  const getSurveySectionTypeCodeById = (id) => surveySectionTypes.find(sectionType => sectionType.id === id)?.code 

  // console.log(questionResponseTime)

  useEffect(() => {
    const isIos = (() => {
      // if (/windows/i.test(navigator.userAgent)) {
      //     return "Windows"
      // }
      if (/iphone/i.test(navigator.userAgent)) {
          return true
      }
      else if (/ipad/i.test(navigator.userAgent)) {
          return true
      }
      else if (/ipod/i.test(navigator.userAgent)) {
          return true
      }
      return false
      // else if (/macintosh/i.test(navigator.userAgent)) {
      //     return "Mac OS"
      // }
    })();

    setIsIosPlatform(isIos)
  }, [])
  
  const { 
      control, valuesFields, errors, setValue, handleSubmit, 
      surveyData, activity,
      answerSaved,
      toast,
      isLoadingSurveyData,
      questions,
      trigger,
      clearErrors,
      errorSurvey,
      message
    } = useSurveyPublic()

  // console.log(valuesFields)
  // console.log(questions)
  // console.log(surveyData?.sections)
  // console.log(questionResponseTime)
  // console.log((location.search).split('=')[1])
  const isClassicSurvey = surveyData?.surveyType.code === 'STCL'

  const searchParams = new URLSearchParams(location.search)
  const source = searchParams.get('source')
  const rcpt = searchParams.get('rcpt')

  // Listar las questions y si hay preguntas que pertenecen a una sección y la seccion es de sectionTypeId = 2 (grid) entonces de todas las pr eguntas de la seccion solo dejar la primera en la lista de questions
  const questionsWithoutRepeatedGridSectionId = surveyData ? surveyData.sections.reduce((acc, cur) => {
    if (getSurveySectionTypeCodeById(cur.sectionTypeId) === SurveySectionType.Grid) {
      acc.push(cur.questions[0]) 
    } else {
      acc.push(...cur.questions)
    }
    return acc
  }, []) : []

  // console.log(questionsWithoutRepeatedGridSectionId)


  useEffect(()=> {
    updateTitle({
      title: "Gestión de Encuestas",
      subtitle: "Encuestas",
      description: title,
      previousUrl: "/encuestas/encuesta/"
    })
    if (isFirstLoading) {
      setFirstLoading(false);
      if (!params.id) onHide()
      if (params.id) {
        const model = {
          guid: params.id,
          // obtener query params source y rcpt
          // source: (location.search).split('=')[1],
          // rcpt: (location.search).split('=')[2]
          sourceCode: source || undefined,
          recipientId: rcpt ? Number(rcpt) : undefined
        }
        
        dispatch(SurveyPublicAction.getSurveyByGuid(model));
      }
    }
  }, [])

  useEffect(() => {
    // dispatch(SurveyAction.getDataMaster());
    dispatch(SurveyPublicAction.getDataMaster());
  }, [dispatch])

  const onHide = () => {
    // setValue("name", ''); 
    // reset()
    // dispatch(IndicatorsAction.resetAfterSaved());
  }

  const saveResponseTime = (currentQuestionTotal) => {
    if (questionResponseTime.length === 0) {
      setQuestionResponseTime([...questionResponseTime, {
        questionPosition: currentQuestion + 1,
        responseTime: moment().format('')
      }])
    } else {
      // const existQuestionInList = questionResponseTime.some(question => question.questionPosition === currentQuestion + 1)
      const existQuestionInList = questionResponseTime.some(question => question.questionPosition === currentQuestionTotal)
      if (existQuestionInList) {
        const newListQuestion = questionResponseTime.map(question => {
          // if (question.questionPosition === currentQuestion + 1) {
          if (question.questionPosition === currentQuestionTotal) {
            return {
              ...question,
              responseTime: moment().format('')
            }
          }
          return question
        })
        setQuestionResponseTime([...newListQuestion])
      } else {
        setQuestionResponseTime([...questionResponseTime, {
          // questionPosition: currentQuestion + 1,
          questionPosition: currentQuestionTotal,
          responseTime: moment().format('')
        }])
      }
    }
  }

  const onQuestionChange = (e, questionField, onChange, error, indexTotal) => {
    let _selectedAnswers = [...valuesFields[questionField]];

    if (e.checked) {
        _selectedAnswers.push(e.value);
        if (!isClassicSurvey) {
          saveResponseTime(indexTotal)
          if (error) clearErrors(questionField)
        }
    }
    else {
        for (let i = 0; i < _selectedAnswers.length; i++) {
            const selectedAnswer = _selectedAnswers[i];

            if (selectedAnswer.id === e.value.id) {
                _selectedAnswers.splice(i, 1);
                if (valuesFields[`${questionField}_other`]) {
                  setValue(`${questionField}_other`, null);
                }
                break;
            }
        }
    }

    // setValue(questionField, _selectedAnswers);
    onChange(_selectedAnswers)
    if (!isClassicSurvey) saveResponseTime(indexTotal)
  }

  const onChangeWithOther = (onChange, value, field, error, indexTotal) => {
    if (value !== 'Otra' && valuesFields[`${field}_other`]) {
      setValue(`${field}_other`, null);
    }
    onChange(value)
    if (!isClassicSurvey) {
      saveResponseTime(indexTotal)
      if (error) clearErrors(field)
    }
  }

  const onChangeSaveTime = (onChange, value, error, indexTotal) => {
    onChange(value)
    if (!isClassicSurvey) {
      saveResponseTime(indexTotal)
      if (value !== '' && error) clearErrors(`question_${indexTotal}`)
    }
  }

  const onChangeText = (onChange, value, error, indexTotal) => {
    onChange(value)
    if (!isClassicSurvey) {
      if (value !== '' && error) clearErrors(`question_${indexTotal}`)
    }
  }

  const onBlurSaveTime = (indexTotal) => {
    // onBlur(value)
    if (!isClassicSurvey) {
      saveResponseTime(indexTotal)
    }
  }

  const generateModelQuestions = (data) => {
    const modelQuestions = questions.map((question, index) => {
      // const modelQuestions = surveyData?.questions.map((question, index) => {

      const modelQuestion = {
        // surveyId: surveyData.id,
        // guid: params.id,
        // sessionId: activity.sessionId,
        questionId: question.id,
        date: moment().format(),
      }

      if (question.questionType.code === 'TX') {
        modelQuestion.answerText = data[`question_${index+1}`]
        modelQuestion.selectedOptionsId = null
        if (surveyData.surveyType.code === 'STQT' ) {
          modelQuestion.date = questionResponseTime.find(question => question.questionPosition === index + 1)?.responseTime
        }
      }
      if (question.questionType.code === 'OM') {
        modelQuestion.answerText =  data[`question_${index+1}_other`] ? data[`question_${index+1}_other`] : null
        modelQuestion.selectedOptionsId = data[`question_${index+1}_other`] ? null : data[`question_${index+1}`].map((option) => option.id) 
        if (surveyData.surveyType.code === 'STQT' ) {
          modelQuestion.date = questionResponseTime.find(question => question.questionPosition === index + 1)?.responseTime
        }
      }
      if (question.questionType.code === 'CV' || question.questionType.code === 'LD') {
        modelQuestion.answerText = data[`question_${index+1}_other`] ? data[`question_${index+1}_other`] : null
        modelQuestion.selectedOptionsId = data[`question_${index+1}_other`] ? null : [data[`question_${index+1}`].id] 
        if (surveyData.surveyType.code === 'STQT' ) {
          modelQuestion.date = questionResponseTime.find(question => question.questionPosition === index + 1)?.responseTime
        }
      }
      if (question.questionType.code === 'RE') {
        modelQuestion.answerText = null
        modelQuestion.selectedOptionsId = [data[`question_${index+1}`]]
        if (surveyData.surveyType.code === 'STQT' ) {
          modelQuestion.date = questionResponseTime.find(question => question.questionPosition === index + 1)?.responseTime
        }
      }

      // if (!modelQuestion.date) modelQuestion.date = null

      return modelQuestion
    })

    const filterEmptyQuestions = modelQuestions.filter(question => {
      if (question.answerText === '' && question.selectedOptionsId === null) return false
      if (question.answerText === null) {
        if (question.selectedOptionsId.length === 0 || !question.selectedOptionsId[0] || question.selectedOptionsId[0] === '' ) return false
      }
      if (!question.date ) return false
      return true
    })

    return filterEmptyQuestions    
  }

  const onSubmit = (data) => {
    const models = {
      surveyId: surveyData.id,
      sessionId: activity.sessionId,
      answers: generateModelQuestions(data)
    }
    // console.log(models)
    dispatch(SurveyPublicAction.saveSurveyAnswer(models));
  };

  const renderQuestionOrSectionBySectionTypeId = (questions, sections, currentQuestion, questionsWithoutRepeatedGridQuestion) => {
    // const section = sections.find(section => section.id === questions[currentQuestion].surveySectionId)
    const section = sections.find(section => section.id === questionsWithoutRepeatedGridQuestion[currentQuestion].surveySectionId)

    const questionTotalIndex = questions.findIndex(question => question.id === questionsWithoutRepeatedGridQuestion[currentQuestion].id)

    if (!section.sectionTypeId || getSurveySectionTypeCodeById(section.sectionTypeId) === SurveySectionType.Regular) return (
      <ListQuestionSurvey 
        // question={surveyData?.questions[currentQuestion]}
        // question={questions[currentQuestion]}
        question={questionsWithoutRepeatedGridQuestion[currentQuestion]}
        control={control} 
        index={currentQuestion} 
        indexTotal={questionTotalIndex + 1}
        valuesFields={valuesFields} 
        onQuestionChange={onQuestionChange} 
        onChangeWithOther={onChangeWithOther} 
        onChangeSaveTime={onChangeSaveTime}
        onBlurSaveTime={onBlurSaveTime}
        onChangeText={onChangeText}
      />
    )
    return (
      <SurveyPublicSectionGrid 
        questions={section.questions}
        totalQuestions={questions}
        control={control}
        onChangeWithOther={onChangeWithOther}
        valuesFields={valuesFields}
        isLoadingDataMaster={isLoadingDataMaster}
      />
    )
  }

  if (errorSurvey) {
    return (
      <SurveyPublicMessage message={errorSurvey} type='error' />
      )
    }
    
    
    return (
      answerSaved ? (
        // <ThankYouMessage />
        <SurveyPublicMessage message={message} type='success' />
      )
      : (
        (!surveyData && isLoadingSurveyData ) ? (
          <div className="flex justify-center items-center h-screen">
            <img src="loading.gif" width={'200px'} alt='spinner loading' ></img>
            <Toast ref={toast} />
          </div>
        )
        : (
          <div className='h-screen overflow-y-auto py-1 px-1 md:px-4'>
            <div className='flex flex-col p-2 my-4'>
              <div className={`menu-component rounded-t-lg ${(!surveyData ) ? 'invisible' : ''}`} >
                <div className="icon-w-logo flex justify-start items-center">
                  <LogoComponent noRedirect={true} />
                </div>
              </div>
              <div className={`border-2 shadow mb-0 rounded-b-lg bg-gray-100 ${(!surveyData ) ? 'invisible' : ''}`}>
                <h1 className={`font-semibold   py-2 px-3 text-center text-2xl`}>{surveyData?.title}</h1>
                <h2 className='  py-1 px-3  font-medium text-left'>
                  <div dangerouslySetInnerHTML={{ __html: surveyData?.description }} />
                </h2>
              </div>
              <form 
                className=""
                onSubmit={handleSubmit(onSubmit)}
              >
                {/* <SurveyPublicSection /> */}
                <div className='flex flex-col gap-y-2'>
                  {
                    isClassicSurvey 
                    ? (
                      (Object.keys(valuesFields).length > 0) 
                      ? surveyData.sections.map((section, index) => (
                        <div className='flex flex-col' key={`section-${section.id}`}>
                          <SurveyPublicSection 
                            index={index + 1} 
                            title={section.title} 
                            description={section.description} 
                            totalSections={surveyData.sections.length}
                          />
                          <div className='flex flex-col gap-y-2'>
                            {
                              (!section.sectionTypeId || getSurveySectionTypeCodeById(section.sectionTypeId) === SurveySectionType.Regular ) ?
                              section.questions.map((question, index) => (
                                <ListQuestionSurvey 
                                  question={question} 
                                  control={control} 
                                  // index={index} //*
                                  index={questions?.findIndex(q => q.id === question.id)} //*
                                  indexTotal={questions?.findIndex(q => q.id === question.id) + 1}
                                  valuesFields={valuesFields} 
                                  onQuestionChange={onQuestionChange} 
                                  onChangeWithOther={onChangeWithOther} 
                                  onChangeSaveTime={onChangeSaveTime}
                                  onBlurSaveTime={onBlurSaveTime}
                                  onChangeText={onChangeText}
                                  key={index}
                                />
                              )) : (
                                <SurveyPublicSectionGrid 
                                  questions={section.questions}
                                  totalQuestions={questions}
                                  control={control}
                                  onChangeWithOther={onChangeWithOther}
                                  valuesFields={valuesFields}
                                  isLoadingDataMaster={isLoadingDataMaster}
                                />
                              )
                            }
                          </div>
                        </div>
                      ))
                      : ( <MessageEmptyQuestion /> )
                    ) 
                    : (
                      !surveyData 
                      ? <Skeleton height='0rem' />
                      : (Object.keys(valuesFields).length > 0) ? (
                        <div className='flex flex-col'>
                          <SurveyPublicSection 
                            // index={surveyData.sections.findIndex(section => section.id === questions[currentQuestion].surveySectionId) + 1} 
                            index={surveyData.sections.findIndex(section => section.id === questionsWithoutRepeatedGridSectionId[currentQuestion].surveySectionId) + 1} 
                            title={surveyData.sections.find(section => section.id === questions[currentQuestion].surveySectionId)?.title} 
                            description={surveyData.sections.find(section => section.id === questions[currentQuestion].surveySectionId)?.description} 
                            totalSections={surveyData.sections.length}
                          />
                          {
                            renderQuestionOrSectionBySectionTypeId(questions, surveyData?.sections, currentQuestion, questionsWithoutRepeatedGridSectionId)
                            // (renderQuestionOrSectionBySectionTypeId(questions, surveyData?.sections, currentQuestion) ) ? (
                            //   <ListQuestionSurvey 
                            //     // question={surveyData?.questions[currentQuestion]}
                            //     question={questions[currentQuestion]}
                            //     control={control} 
                            //     index={currentQuestion} 
                            //     valuesFields={valuesFields} 
                            //     onQuestionChange={onQuestionChange} 
                            //     onChangeWithOther={onChangeWithOther} 
                            //     onChangeSaveTime={onChangeSaveTime}
                            //     onBlurSaveTime={onBlurSaveTime}
                            //     onChangeText={onChangeText}
                            //   />
                            // ) : (
                            //   <SurveyPublicSectionGrid 
                            //     questions={section.questions}
                            //     totalQuestions={questions}
                            //     control={control}
                            //     onChangeWithOther={onChangeWithOther}
                            //     valuesFields={valuesFields}
                            //   />
                            // )
                          }

                          {/* TODO: SurveySectionGrid */}
                        </div>
                      ) : ( <MessageEmptyQuestion /> )
                    ) 
                  }
                </div>
                <div className={`flex ${!isClassicSurvey ? 'justify-between' : 'justify-end'} items-center gap-x-1 md:gap-x-4 mt-5 mb-2`}>
                  {
                    (!isClassicSurvey && Object.keys(valuesFields).length > 0) && (
                      <div className='font-medium'>
                        {/* <p className='flex md:hidden'>Pregunta:</p> */}
                        {/* <p>Pregunta {currentQuestion + 1} de {surveyData?.questions.length} </p> */}

                        {/* <p><span className='hidden md:inline'>Pregunta </span>{currentQuestion + 1} de {questions.length} </p> */}
                        <p><span className='hidden md:inline'>Página </span>{currentQuestion + 1} de {questionsWithoutRepeatedGridSectionId.length} </p>
                      </div>
                    )
                  }
                  <div className='flex gap-x-1 md:gap-x-4'>
                    {
                      (isClassicSurvey && Object.keys(valuesFields).length > 0) ? (
                        <Button
                          type="submit"
                          label="Enviar encuesta"
                          className="sig-button sig-primary "
                        />
                      ) : Object.keys(valuesFields).length > 0 && (
                        <>
                          <Button
                            type="button"
                            label="Anterior pregunta"
                            onClick={() => setCurrentQuestion( prev => prev > 0 ? prev - 1 : prev)}
                            className={`sig-button sig-primary ${currentQuestion === 0 ? 'hidden' : ''}`}
                          />
                          <Button
                            type="submit"
                            label='Enviar encuesta'
                            // className={`sig-button sig-primary ${currentQuestion === surveyData?.questions.length - 1 ? '' : 'hidden'}`}
                            // className={`sig-button sig-primary ${currentQuestion === questions.length - 1 ? '' : 'hidden'}`}
                            className={`sig-button sig-primary ${currentQuestion === questionsWithoutRepeatedGridSectionId.length - 1 ? '' : 'hidden'}`}
                          />
                          <Button
                            type="button"
                            label='Siguiente pregunta'
                            // onClick={saveResponseTime}
                            onClick={ async() => {
                              const isValid = await trigger(`question_${currentQuestion + 1}`)
                              if (isValid) {
                                setCurrentQuestion( prev => prev <= questions.length - 2 ? prev + 1 : prev)
                              }
                            }}
                            // onClick={ () => setCurrentQuestion( prev => prev <= surveyData?.questions.length - 2 ? prev + 1 : prev) }
                            // className={`sig-button sig-primary ${currentQuestion === surveyData?.questions.length - 1 ? 'hidden' : ''}`}
                            // className={`sig-button sig-primary ${currentQuestion === questions.length - 1 ? 'hidden' : ''}`}
                            className={`sig-button sig-primary ${currentQuestion === questionsWithoutRepeatedGridSectionId.length - 1 ? 'hidden' : ''}`}
                          />
                        </>
                      )
                    }
                  </div>
                </div>
              </form>
              <div className={`h-12 w-full ${isIosPlatform ? 'visible' : 'hidden'}`} />
            </div>
            <Toast ref={toast} />
          </div>
        )
      )
    )
}

export default SurveyPublic