import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UsersAction } from 'features/configuration/store/actions/UsersAction';
import { UsersTableData } from 'features/configuration/data/users/UsersTableData';
import validateTypeValue from 'shared/helpers/validateTypeValue';
import { useClaimUsers } from '../useClaimUsers';

const useUserTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const users = useSelector((state) => state.userReducer.users);

  const { isEdit, isDelete, isRestorePassword } = useClaimUsers();

  /* CURRENT USER ------------------- */
  const currentUserId = useSelector(
    (state) => state.userReducer.currentUser.id
  );
  const setCurrentUser = (user_id) => {
    dispatch(UsersAction.setCurrentUser(user_id));
  };

  /* TYPE TABLE VIEW ---------------- */
  const [selectColumns, _] = useState(UsersTableData.columns);

  /* TABLE -------------------------- */
  const [tableUsers, setTableUsers] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState([]);
  useEffect(() => {
    setTableUsers(validateTypeValue(users?.data));
    setTablePagination(users?.pagination);
    setTableLoading(users?.loading);
  }, [users]);

  /* TABLE CURRENT ACTION ------------- */
  const [currentAction, setCurrentAction] = useState('');
  const onCurrentAction = ({ item: { name } }) => setCurrentAction(name);
  useEffect(() => {
    if (currentUserId && currentAction) {
      const userId = currentUserId;
      const action_ = currentAction.toLocaleLowerCase().replaceAll(' ', '');
      if (action_ === 'editar') {
        history.push(`/configuracion/usuarios/editar/${userId}`);
      } else if (action_ === 'eliminar') {
        dispatch(UsersAction.showDeleteUserModal({ isOpen: true, userId }));
      } else if (action_ === 'resetearclave') {
        dispatch(UsersAction.userResetPassword(userId));
      }
    }
    setCurrentAction('');
  }, [currentAction]);

  /* TABLE ACTIONS -------------------- */
  const userOptions = useSelector(
    (state) => state.userReducer.userActions.actions
  );
  const [tableActions, setTableActions] = useState([]);
  const [showActions, setShowActions] = useState(false);
  const tableCurrentActions = useRef(null);
  useEffect(() => {
    if (userOptions.length > 0) {
      let newActions = userOptions.map((option) => {
        option.label = option.name;
        option.command = onCurrentAction;
        return option;
      });
      if (!isEdit)
        newActions = newActions.filter(c => c.label !== 'Editar');
      if (!isDelete)
        newActions = newActions.filter(c => c.label !== 'Eliminar');
      if (!isRestorePassword)
        newActions = newActions.filter(c => c.label !== 'Resetear Clave');
      setTableActions(newActions);
    }
    setShowActions(userOptions.length > 0);
  }, [isEdit, isDelete, isRestorePassword]);

  /* MODAL DELETE (ACTIONS) */
  const confirmDelete = () => {
    dispatch(UsersAction.deleteUser(currentUserId));
  };
  const cancelDelete = () => {
    dispatch(UsersAction.showDeleteUserModal({ isOpen: false, userId: '' }));
  };

  /* TABLE PAGINATION --------------- */
  const usersSearch = useSelector((state) => state.userReducer.users);
  const [tableRows, setTableRows] = useState(10);
  const [tableNum, setTableNum] = useState(0);
  const [page, setPage] = useState(1);
  let currentFilterFields = {
    columnOrder: usersSearch.currentCol,
    order: usersSearch.order,
  };

  const filterValues = useSelector(
    (state) => state.userReducer.userFilter.values
  );
  const buildFilterValues = () => {
    if (filterValues.length > 0) {
      filterValues.forEach(({ field, value, date, option }) => {
        let currentValue = '';
        const key = field.toLocaleLowerCase();
        if (key.includes('date')) currentValue = date;
        else if (key.includes('id') && key !== 'statusid')
          currentValue = option.id;
        else if (key === 'statusid') currentValue = option;
        else if (key === 'fullname') currentValue = value;

        currentFilterFields[field] = currentValue;
      });
    }
  };

  const onChangePage = ({ rows, page }) => {
    currentFilterFields.page = page + 1;
    currentFilterFields.pageSize = rows;
    setTableRows(rows);
    setTableNum(rows * page);
    setPage(page + 1);
    buildFilterValues();
    const fields = currentFilterFields;
    dispatch(UsersAction.getUsers({ change: true, fields }));
  };

  /* TABLE ORDER SORT */
  const [tableSort, setTableSort] = useState({
    sortField: 'email',
    sortOrder: '1',
  });
  const onSort = (event) => {
    const params = {
      first: tableNum,
      rows: tableRows,
      multiSortMeta: [],
      filters: null,
      ...event,
    };
    buildFilterValues();

    currentFilterFields.order = event.sortOrder + '' === '1' ? 'asc' : 'desc';
    currentFilterFields.columnOrder = event.sortField;
    currentFilterFields.pageSize = tableRows;
    currentFilterFields.page = page;
    const fields = currentFilterFields;
    dispatch(UsersAction.getUsers({ change: true, fields }));
    setTableSort(params);
  };

  return {
    selectColumns,
    tableUsers,
    tableLoading,
    tableActions,
    tableCurrentActions,
    showActions,
    currentAction,
    onCurrentAction,
    setCurrentUser,
    confirmDelete,
    cancelDelete,
    onChangePage,
    tableNum,
    tableRows,
    tablePagination,
    tableSort,
    onSort,
  };
};

export default useUserTable;
