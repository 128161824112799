/* eslint-disable import/no-anonymous-default-export */
// import { ProcessManagementConstans } from "../../commons/ProcessManagementConstans";
import { ProcessManagementConstans } from "features/processManagement/commons/ProcessManagementConstans"
const initialState = {
    dataMaster: {
    isLoading: false,
    // academicDegreeParent: null,
    // manager: null
    },
    activeSheet:{
      data: null,
      isSaving: false,
      isSaved: false,
      isDeleted: false,
      isLoading: false,
      approvers: [],
    },
  formularies: {
      data: null,
      isSaving: false,
      isSaved: false,
      isDeleted: false,
      isLoading: false
  },
  currentVersion: {
      data: null,
      isSaving: false,
      isSaved: false,
      isDeleted: false,
      isLoading: false
  },
  processSheet: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false
  },
  dataProcesSheet: {
    isLoading: false,
    data: null
  },
  optionsDropDown: {
    isLoading: false,
    data: null
  },
  listVersions: {
    isLoading: false,
    data: null
  },
  producedBy: {
    isLoading: false,
    data: null
  },
  procesSheet: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false
  },
  Summary: {
    isLoading: false,
    data: null
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'versionNumber',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {},
    status: null
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ProcessManagementConstans.Accion.ProcesseSheet.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
    case ProcessManagementConstans.Accion.ProcesseSheet.UPDATE_FILTER:
        return {
            ...state,
            advancedFilter: state.advancedFilter
        };

    case ProcessManagementConstans.Accion.ProcesseSheet.SEARCH_PROCESSESHEET:
        return {
            ...state,
            dataProcesSheet: {
                isLoading: action.payload.isLoading,
                data: action.payload.data
            }
        };
    case ProcessManagementConstans.Accion.ProcesseSheet.GET_PROCESSESHEET_BY_ID:
      return {
          ...state,
          processSheet: {
              data: action.payload.data,
              isSaving: false,
              isLoading: action.payload.isLoading
          }
        };

    case ProcessManagementConstans.Accion.ProcesseSheet.GET_LAST_SHEET:
      return {
          ...state,
          processSheet: {
              data: action.payload.data?.sheet,
              isSaving: false,
              isLoading: action.payload.isLoading,
              approvers: action.payload.data?.approvers,
          },
          listVersions: {
            data: action.payload.data?.versions,
            isLoading: action.payload.isLoading
          }
        };

    case ProcessManagementConstans.Accion.ProcesseSheet.SAVE_PROCESSESHEET:
        return {
            ...state,
            formularies: {
                data: action.payload.currentOccupation,
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };
    case ProcessManagementConstans.Accion.ProcesseSheet.GENERATE_VERSION:
        return {
            ...state,
            currentVersion: {
                data: action.payload.data,
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };
        case ProcessManagementConstans.Accion.ProcesseSheet.RESTORE_PROCESSHEET:
          return {
              ...state,
              processSheet: {
                data: null,
                isSaving: false,
                isSaved: false,
                isDeleted: false,
                isLoading: false
              }
            };
        case ProcessManagementConstans.Accion.ProcesseSheet.GET_ACTIVE_SHEET:
          return {
              ...state,
              activeSheet: {
                data: action.payload.data,
                isLoading: action.payload.isLoading
              }
            };

    case ProcessManagementConstans.Accion.ProcesseSheet.DELETE_PROCESSESHEET:
        return {
            ...state,
            processSheet: {
                isDeleted: action.payload.isDeleted
            }
        };

    case ProcessManagementConstans.Accion.ProcesseSheet.RESET_AFTER_SAVED:

        return {
            ...state,
            processSheet: {
                data: null,
                isSaving: false,
                isSaved: false,
                isLoading: false,
                isDeleted: false
            }
        };

      case ProcessManagementConstans.Accion.ProcesseSheet.GET_DATA_MASTER:
          return {
              ...state,
              dataMaster: {
                  occupationParent: action.payload.occupationParent,
                  manager: action.payload.manager,
                  isLoading: action.payload.isLoading
              }
          };

      case ProcessManagementConstans.Accion.ProcesseSheet.DOWNLOAD_REPORT_STATUS:
          return {
              ...state,
              processSheet: {
                  ...state,
                  isLoading: action.payload.isLoading,
              },
          };
      case ProcessManagementConstans.Accion.ProcesseSheet.GET_OPTIONS_DROPDOWN:
          return {
              ...state,
              optionsDropDown: {
                data: action.payload.data,
                isLoading: action.payload.isLoading
              },
          };
      case ProcessManagementConstans.Accion.ProcesseSheet.GET_LIST_VERSIONS:
          return {
              ...state,
              listVersions: {
                data: action.payload.data,
                isLoading: action.payload.isLoading
              },
          };
      case ProcessManagementConstans.Accion.ProcesseSheet.GET_PRODUCED_BY:
          return {
              ...state,
              producedBy: {
                data: action.payload.data,
                isLoading: action.payload.isLoading
              },
          };
      case ProcessManagementConstans.Accion.ProcesseSheet.SEARCH_SUMMARY:
          return {
              ...state,
              Summary: {
                data: action.payload.data,
                isLoading: action.payload.isLoading
              },
          };
      default:
      return state;
  }

}