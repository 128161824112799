import { Panel } from "primereact/panel";
import { useSelector } from "react-redux";
import { EditorText, InputSimple } from "./FIeldsComponents";
import { DynamicFieldsConstants } from "features/configuration/commons/DynamicFieldsConstants";

export const PreViewField = ({watchField}) => {
  const dataSection = useSelector(
    (state) => state.dynamicFieldsReducer.section.data
  );
  const dataMaster = useSelector(
    (state) => state.dynamicFieldsReducer.dataMaster.data
  );
  const codeSimpleText = DynamicFieldsConstants.Action.CodeFields.SIMPLE_TEXT
  const codeRichText = DynamicFieldsConstants.Action.CodeFields.RICH_TEXT

  const codeField = dataMaster.dynammicFieldType.find(({id}) => id === watchField("typeId"))?.code

  return(
    <Panel header={dataSection.find(({id}) => id === watchField("sectionId"))?.name} toggleable>
      { watchField("typeId") &&
        codeField === codeSimpleText && <InputSimple label={watchField("label")} isRequired={watchField("isRequired")} />
      }
      { watchField("typeId") &&
        codeField === codeRichText && <EditorText label={watchField("label")} isRequired={watchField("isRequired")} />
      }
    </Panel>
  )
}