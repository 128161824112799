import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import TableComponent from 'features/rrhh/components/department/TableComponent';
import FilterComponent from 'features/rrhh/components/department/FilterComponent';
import AdvancedFilterComponent from 'features/rrhh/components/department/AdvacedFilterComponent';
import { DeparmentsAction } from 'features/rrhh/store/actions/DepartmentAction';
import { useClaimDepartment } from 'features/rrhh/hooks/useClaimDepartment';
const SearchDepartmentPage = () => {
    const history = useHistory();
    const { updateTitle } = useSetTitlePage();
    const [isFirstLoading, setFirstLoading] = useState(true);
    const dispatch = useDispatch();
    const { isSearch, isDownload, isCreate } = useClaimDepartment();

    useEffect(() => {
        updateTitle({
            title: 'Gestión de la organización',
            subtitle: 'Departamento',
        });

        if (isFirstLoading) {
            setFirstLoading(false);
            dispatch(DeparmentsAction.getDataMaster());
        }
    }, []);


    return (
        <Fragment>
            {
                (isSearch||isDownload)&&(
                    <FilterComponent />
                )
            }
            {
                isCreate&&(
                    <Button
                        icon="pi pi-plus"
                        type="button"
                        label="Nuevo"
                        className="sig-button sig-dark mt-4"
                        onClick={() => {
                            history.push('/rrhh/departamento/nuevo');
                        }}
                    />
                )
            }
            <TableComponent />
            <AdvancedFilterComponent />
        </Fragment>
    );
};

export default SearchDepartmentPage;
