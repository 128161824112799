import { CoreConstants } from "core/commons/CoreConstants";
import { toastAction } from "core/store/actions/ToastAction";
import { BusinessSheetPublicConstants } from "features/business/commons/BusinessSheetPublicConstants";
import { BusinessSheetPublicService } from "features/business/services/BusinessSheetPublicService";

const setBusinessSheetPublic = (payload) => ({
  type: BusinessSheetPublicConstants.Action.BusinessSheetPublic.BUSINESS_SHEET,
  payload,
});

const getByGuId = (guid) => {
  return (dispatch) => {
    dispatch(setBusinessSheetPublic({ loading: true }));
    BusinessSheetPublicService.getByGuId(guid).then(({ data }) => {
      const status = data.status;
      if (status === CoreConstants.HttpResponse.OK) {
        const businessSheet = data.data.businessSheet;
        dispatch(setBusinessSheetPublic({ businessSheet }));
      } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
        dispatch(setBusinessSheetPublic({ error: true }));
      }else{
        dispatch(setBusinessSheetPublic({ error: true }));
      }
    })
    .catch(( e ) => {
      console.error(e)
      dispatch(setBusinessSheetPublic({ error: true }));
    })
    .finally(() => {
      dispatch(setBusinessSheetPublic({ loading: false }));
    });
  }
}

const setSave = (payload) => ({
  type: BusinessSheetPublicConstants.Action.BusinessSheetPublic.SAVE,
  payload,
})

const save = (data, cb) => {
  return (dispatch) => {
    dispatch(setSave({ isLoadingSave: true }));
    BusinessSheetPublicService.save(data)
      .then(({ data }) => {
        const status = data?.status ?? '';
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(setSave({ isLoadingSave: false, success: true }));
          dispatch(toastAction.success("Ficha de Negocios", "La Ficha de Negocios se guardó correctamente"));
          cb && cb();
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Ficha de Negocios", data.message));
          dispatch(setSave({ error: true }));
        }else{
          dispatch(toastAction.error("Ficha de Negocios", data.message));
          dispatch(setSave({ error: true }));
        }

      })
      .catch((error)=>{
        dispatch(toastAction.error("Ficha de Negocios", error.message));
        dispatch(setSave({ error: true }));
      })
      .finally(() => {
        dispatch(setSave({ isLoadingSave: false }));
      });
  }
}

const resetBusinessSheetPublic = () => ({
  type: BusinessSheetPublicConstants.Action.BusinessSheetPublic.BUSINESS_SHEET,
  payload: {
    businessSheet: {},
    loading: false,
  },
});

export const BusinessSheetPublicAction = {
  getByGuId,
  save,
  resetBusinessSheetPublic,
}