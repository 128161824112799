import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ModalAction, SidebarMenu, TableComponent } from 'features/document/components/DocumentManagement'
import ModalPermissions from 'features/document/components/DocumentManagement/ModalPermissions';
import { DocumentManagementAction } from 'features/document/store/actions/DocumentManagementAction';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { Message } from 'primereact/message';
import { Splitter, SplitterPanel } from 'primereact/splitter';

const SearchDocumentManagementPage = () => {

  const dispatch = useDispatch();
  const { updateTitle } = useSetTitlePage();
  const [selectedNode, setSelectedNode] = useState('');

  const [documentSelected, setDocumentSelected] = useState(null)
  const [actionSelected, setActionSelected] = useState(null)
  const [displayModalAction, setDisplayModalAction] = useState(false)
  const [displayModalPermissions, setDisplayModalPermissions] = useState(false)

  const { isLoading: isLoadingEnabled , isEnabled } = useSelector((state) => state.documentManagementReducer.isStorageEnabled);

  useEffect(() => {
    updateTitle({
        title: 'Gestión Documental',
        subtitle: 'Administración',
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(DocumentManagementAction.isStorageEnabled());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
          {
            !isLoadingEnabled && (
              isEnabled ? null : (
                  <div className="my-1">
                      <Message severity="warn" text="El almacenamiento de archivos no está configurado para esta suscripción. Por favor contacte con el Administrador" />
                  </div>
              )
            )
          }
          {/* <div className='grid grid-cols-12 gap-1 h-96 mt-2'>
            <div className='col-span-5 sm:col-span-3 2xl:col-span-2 flex flex-col py-2 px-3 border-r border-gray-300'>
              <SidebarMenu 
                setSelectedNode={setSelectedNode} 
                setDisplayModal={setDisplayModalAction}
                setActionSelected={setActionSelected}
              />
            </div>
            <div className='col-span-7 sm:col-span-9 2xl:col-span-10 px-3'>
              <TableComponent 
                selectedNode={selectedNode} setSelectedNode={setSelectedNode}
                documentSelected={documentSelected} setDocumentSelected={setDocumentSelected} 
                setActionSelected={setActionSelected}
                setDisplayModalAction={setDisplayModalAction}
                setDisplayModalPermissions={setDisplayModalPermissions}
              />
            </div>
          </div> */}
          <Splitter  className="mt-2 flex gap-x-3">
            <SplitterPanel className="" size={24}>
              <SidebarMenu 
                setSelectedNode={setSelectedNode} 
                setDisplayModal={setDisplayModalAction}
                setActionSelected={setActionSelected}
              />
            </SplitterPanel>
            <SplitterPanel className="" size={76}>
              <TableComponent 
                selectedNode={selectedNode} setSelectedNode={setSelectedNode}
                documentSelected={documentSelected} setDocumentSelected={setDocumentSelected} 
                setActionSelected={setActionSelected}
                setDisplayModalAction={setDisplayModalAction}
                setDisplayModalPermissions={setDisplayModalPermissions}
              />
            </SplitterPanel>
          </Splitter>
          <ModalAction 
            displayModal={displayModalAction}  setDisplayModal={setDisplayModalAction} 
            documentSelected={documentSelected} setDocumentSelected={setDocumentSelected}  
            actionSelected={actionSelected} setActionSelected={setActionSelected}
            selectedNode={selectedNode}
          />
          <ModalPermissions 
            displayModal={displayModalPermissions}  setDisplayModal={setDisplayModalPermissions} 
            documentSelected={documentSelected} setDocumentSelected={setDocumentSelected}  
            // actionSelected={actionSelected} setActionSelected={setActionSelected}
          />
    </Fragment>
  )
}

export default SearchDocumentManagementPage