import { PublicConstants } from "features/public/commons/PublicConstants";
import { CoreConstants } from "core/commons/CoreConstants";
import { userService } from "features/public/services/UserService";
import { toastAction } from "core/store/actions/ToastAction";
function mostrar() {
  return (dispatch) => {
    dispatch({ type: PublicConstants.Accion.RecuperapContrasena.MOSTRAR });
  };
}

function ocultar() {
  return (dispatch) => {
    dispatch({ type: PublicConstants.Accion.RecuperapContrasena.OCULTAR });
  };
}

function solicitarRecuperacionContrasena(email) {
  return (dispatch) => {
    dispatch({ type: PublicConstants.Accion.RecuperapContrasena.REQUEST });
    userService
      .solicitarRecuperarContrasena(email)
      .then((model) => {
        evaluarErrores(dispatch, model);
        evaluarSolitudRecuperarContrasena(dispatch, model);
      })
      .catch((err) =>
        dispatch({ type: PublicConstants.Accion.RecuperapContrasena.DONE })
      );
  };
}

function recuperarContrasena(token, newPassword) {
  return (dispatch) => {
    userService
      .recuperarContrasena(token, newPassword)
      .then((model) => {
        evaluarErrores(dispatch, model);
        evaluarRecuperarContrasena(dispatch, model);
      })
      .catch((err) =>
        dispatch({ type: PublicConstants.Accion.RecuperapContrasena.DONE })
      );
  };
}

function evaluarErrores(dispatch, model) {
  dispatch({ type: PublicConstants.Accion.RecuperapContrasena.DONE });
  if (model.data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
    dispatch(toastAction.warn("Nueva Contraseña", model.data.message));
    // return dispatch({
    //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
    //   toast: {
    //     titulo: 'Nueva Contraseña',
    //     mensaje: model.data.message,
    //     severidad: 'warn',
    //   },
    // });
  }
  // else if (model.data.status === CoreConstants.HttpResponse.ERROR_TECHNICAL) {
  //   dispatch(toastAction.danger('Nueva Contraseña',model.data.message));
  //   // return dispatch({
  //   //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
  //   //   toast: {
  //   //     titulo: 'Nueva Contraseña',
  //   //     mensaje: model.data.message,
  //   //     severidad: 'danger',
  //   //   },
  //   // });
  // }
}

function evaluarRecuperarContrasena(dispatch, model) {
  if (model.data.status === CoreConstants.HttpResponse.OK) {
    dispatch({ type: PublicConstants.Accion.RecuperapContrasena.OCULTAR });
    dispatch(
      toastAction.success(
        "Cambiar Contraseña",
        "Tu contraseña ha sido cambiada,tendrás que iniciar sesión nuevamente con la nueva contraseña."
      )
    );
    // dispatch({
    //   type: CoreConstants.Accion.FeedBack.MOSTRAR_MENSAJE,
    //   feedBack: {
    //     titulo: 'CONTRASEÑA CAMBIADA',
    //     mensaje:
    //       'Tu contraseña ha sido cambiada,tendrás que iniciar sesión nuevamente con la nueva contraseña.',
    //     // severidad: 'success',
    //     btnText: 'Aceptar',
    //   },
    // });
  } else if (model.data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
    dispatch(toastAction.warn("Nueva Contraseña", model.data.message));
  }
}

function evaluarSolitudRecuperarContrasena(dispatch, model) {
  if (model.data.status === CoreConstants.HttpResponse.OK) {
    dispatch({ type: PublicConstants.Accion.RecuperapContrasena.OCULTAR });
    dispatch(
      toastAction.success(
        "Solicitud Enviada",
        "En breve, recibirás un correo con los pasos para restablecer contraseña."
      )
    );
    // dispatch({
    //   type: CoreConstants.Accion.FeedBack.MOSTRAR_MENSAJE,
    //   feedBack: {
    //     titulo: 'SOLICITUD ENVIADA',
    //     mensaje:
    //       'En breve, recibirás un correo con los pasos para restablecer contraseña.',
    //     // severidad: 'success',
    //     btnText: 'Iniciar Sesión',
    //   },
    // });
  }
}

export const RecoveryPasswordAction = {
  mostrar,
  ocultar,
  solicitarRecuperacionContrasena,
  recuperarContrasena,
};
