import { Link } from "react-router-dom";

import { Avatar } from "primereact/avatar"
import { Panel } from "primereact/panel"
import { Skeleton } from "primereact/skeleton";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";

import './GeneralData.scss'
import moment from "moment";
import { CoreConstants } from "core/commons/CoreConstants";

// isLinkEnable = isProject && title!=='Ver Proyecto'
// isDownloadEnable = isDownload && title!=='Ver Proyecto'
// download = () => downloadViewProjectReport(params.id)
// onClickCancel = () => setIsVisibleCancel(true)
export const GeneralData = ({data, loadingEdit, isLinkEnable, isDownloadEnable, download, loadingReport, showGenerateModal, onClickCancel, linkToSnapShots, textCancelar = 'Cancelar', iconCancelar = 'pi-times', isSnapshot = false  }) => {
  const history = useHistory()

  const SkeletonCustom = ({ width = "100%" }) => {
    return <Skeleton width={width} height="2.2rem" />;
  };

  return(
    <Panel header="DATOS GENERALES" toggleable={true}>
      <div>
        <div className="m-1 w-full" id="generalData-viewDeliverables">
          {
            isSnapshot &&
            <>
              <div className="flex items-center gap-2 mb-1">
                <Avatar icon="pi pi-camera" shape="circle" style={{ backgroundColor: '#2196F3', color: '#ffffff', width: "1.5rem", height: "1.5rem", fontSize: 10 }} />
                <span style={{ fontWeight: "bold", fontSize: 14 }}>PLANIFICACIÓN:</span>
              </div>
              <div>
                {loadingEdit ?
                  <SkeletonCustom />
                  :
                  <h2>{data?.name}</h2>
                }
              </div>

              <div className="flex items-center gap-2 mb-1">
                <Avatar icon="pi pi-calendar" shape="circle" style={{ backgroundColor: '#2196F3', color: '#ffffff', width: "1.5rem", height: "1.5rem", fontSize: 10 }} />
                <span style={{ fontWeight: "bold", fontSize: 14 }}>FECHA:</span>
              </div>
              <div>
                {loadingEdit ?
                  <SkeletonCustom />
                  :
                  <h2>{moment(data?.creationDate).format(CoreConstants.Format.LOCALE)}</h2>
                }
              </div>
            </>
          }
          <div className="flex items-center gap-2">
            <Avatar icon="pi pi-user" shape="circle" style={{ backgroundColor: '#2196F3', color: '#ffffff', width: "1.5rem", height: "1.5rem" }} />
            <span style={{ fontWeight: "bold", fontSize: 14 }}>CLIENTE:</span>
          </div>
          <div>
            {loadingEdit ?
              <SkeletonCustom />
              :
              <h2>{data?.customer?.name}</h2>
            }
          </div>           
          <div className="flex items-center gap-2 mt-1">
            <Avatar icon="pi pi-briefcase" shape="circle" style={{ backgroundColor: '#2196F3', color: '#ffffff', width: "1.5rem", height: "1.5rem" }} />
            <span style={{ fontWeight: "bold", fontSize: 14 }}>PROYECTO:</span>
          </div>
          <div>
            {loadingEdit ?
              <SkeletonCustom />
              :
              <>
                {
                  isLinkEnable ?(
                    <Link to={`/cliente/empresas/editar/${data?.customerId}/proyectos/editar/${data?.id}?source=projects`} style={{ textDecoration: "underline" }}>
                      <h2>{isSnapshot ? data?.projectName :data?.name}</h2>
                    </Link>
                  ):(<h2>{isSnapshot ? data?.projectName :data?.name}</h2>)
                }
            
              </>
            }
          </div>
          <div className="flex items-center gap-2 mt-1">
            <div className="flex items-center gap-x-2">
              <Avatar icon="pi pi-book" shape="circle" style={{ backgroundColor: '#2196F3', color: '#ffffff', width: "1.5rem", height: "1.5rem" }} />
          
              <span style={{ fontWeight: "bold", fontSize: 14 }}>DESCRIPCIÓN:</span>
            </div>
          </div>
          <div>      
            {loadingEdit ?
            <SkeletonCustom />
            :
            <div style={{wordWrap: "break-word"}}>
            <h2>
              <div dangerouslySetInnerHTML={{ __html: data?.description}} />
            </h2>
          </div>
            
            }
            
          </div>
        </div>
          <div className="flex justify-end gap-4 mt-2 flex-wrap">
            {
              (isDownloadEnable)&&(
                <>
                  <Button
                    icon="pi pi-download"
                    type="button"
                    label="Descargar"
                    onClick={download}
                    loading={loadingReport}
                    disabled={loadingReport}
                    className="sig-button sig-success"
                    style={{maxHeight:"2rem"}}
                  />
                </>
              )
            }
            <Button
              icon={`pi ${iconCancelar}`}
              type="button"
              onClick={onClickCancel}
              label={textCancelar}
              loading={loadingReport}
              disabled={loadingReport}
              className="sig-button sig-secondary"
              style={{maxHeight:"2rem"}}
            />
          </div>
          <div className="flex gap-4 mt-2 flex-wrap">
                {
                  showGenerateModal && 
                  <Button
                    icon="pi pi-camera"
                    type="button"
                    label="Guardar Planificación"
                    onClick={showGenerateModal}
                    loading={loadingReport}
                    disabled={loadingReport}
                    className="sig-button sig-primary"
                    style={{maxHeight:"2rem", minWidth: "173px"}}
                  />
                }
                {
                  linkToSnapShots && 
                  <Button
                    icon="pi pi-eye"
                    type="button"
                    label="Ver Planificaciones"
                    onClick={() => history.push(linkToSnapShots)}
                    loading={loadingReport}
                    disabled={loadingReport}
                    className="sig-button sig-success"
                    style={{maxHeight:"2rem", minWidth: "156px"}}
                  />
                }
          </div>
      </div>
    </Panel>
  )
}