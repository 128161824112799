import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { RecoveryPasswordAction } from "features/public/store/actions/RecoveryPasswordAction";
import PublicLayout from "shared/components/public-layout/PublicLayout";
import "./NewPasswordPage.scss";
import { useParams, useHistory } from "react-router";
import SuccessRecoveryPasswordModal from "features/public/components/recovery-password/SuccessRecoveryPasswordModal";
import { userService } from "features/public/services/UserService";
import { PublicConstants } from "features/public/commons/PublicConstants";
import { CoreConstants } from "core/commons/CoreConstants";
import { toastAction } from "core/store/actions/ToastAction";
import { useFormPassword } from "features/public/hooks/useFormPassword";
import { PasswordForm } from "features/public/components/PasswordForm";

const NewPasswordPage = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const {checkErrors, dataForm, errors, handleChange} = useFormPassword()

  const dispatch = useDispatch();
  const mostrarFeedback = useSelector(
    (state) => state.FeedBackReducer.mostrarFeedback
  );

  const { token } = useParams();

  const submit = (e) => {
    e.preventDefault()
    const isValidate = checkErrors()
    if(isValidate){
      setIsLoading(true);
      userService
        .recuperarContrasena(token, dataForm.password)
        .then((model) => {
          setIsLoading(false);
  
          if (model.data.status === CoreConstants.HttpResponse.OK) {
            dispatch(
              toastAction.success(
                "Cambiar Contraseña",
                "Tu contraseña ha sido cambiada,tendrás que iniciar sesión nuevamente con la nueva contraseña."
              )
            );
            setTimeout(() => {
              history.push("/seguridad/inicio-sesion");
            }, 2500);
          } else if (
            model.data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL
          ) {
            dispatch(toastAction.warn("Nueva Contraseña", model.data.message));
          }
        })
        .catch((err) => {
          dispatch({ type: PublicConstants.Accion.RecuperapContrasena.DONE });
          setIsLoading(false);
        });
    }
  };

  return (
    <PublicLayout page="new-password">
      <div className='form-custom'>
        <header className="header">
          <div className="title">
            <h3 className="mb-2 font-bold">Recuperar Contraseña</h3>
          </div>
          <div className="description text-left">
            <p className="text-sm mb-2">
              Elige una contraseña segura y no la utilices en otras cuentas.
            </p>
            <p className="text-sm">
              Si cambias la contraseña se cerrará la sesión en todos tus
              dispositivos y tendrás que iniciar sesión nuevamente.
            </p>
          </div>
        </header>
        <PasswordForm
          dataForm={dataForm}
          errors={errors}
          handleChange={handleChange}
          isLoading={isLoading}
          submit={submit}
        />
      </div>
      <SuccessRecoveryPasswordModal isOpen={mostrarFeedback} />
    </PublicLayout>
  );
};

export default NewPasswordPage;
