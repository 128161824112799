import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmployerAction } from "features/maintenance/store/actions/EmployerAction";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { Dropdown } from "primereact/dropdown";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { useClaimEmployer } from "features/maintenance/hooks/useClaimEmployer";

const { useForm, Controller } = require("react-hook-form")

const EmployerPage = ({title, mode}) => {
  
  const {updateTitle } = useSetTitlePage()
  const history = useHistory();
  const dispatch = useDispatch();
  // TODO: Se usa al editar 
  const employer = useSelector((state) => state.employerReducer.employer); 
  // const {countries} = useSelector((state) => state.employerReducer.dataMaster);  
  const [isFirstLoading, setFirstLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  const { isCreate, isEdit } = useClaimEmployer();

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    name: "",
    id: null,
  });

  const existError = Object.keys(errors).length > 0

  const params = useParams();
  const redirectToSearch = () => {
    history.push("/mantenimiento/empleador");
    dispatch(EmployerAction.resetAfterSaved());
  }


  useEffect(() => {

    if (employer?.data) {
        setValue("name", employer.data.name);
        setValue("id", employer.data.id);
    }
    if (employer?.isSaved)
        redirectToSearch();
  }, [employer]);
  
  useEffect(()=> {
    setValue("name", '');
    setValue("id", '');

    updateTitle({
      title: "Mantenimiento",
      subtitle: "Empleador",
      description: title,
      previousUrl: "/mantenimiento/empleador/"
    })
    if (isFirstLoading) {
      setFirstLoading(false);
      if (params.id)
          getEmployerById(params.id)
    }
  }, [])

  const getEmployerById = (empleadorId) => {
    dispatch(EmployerAction.getEmployerById(empleadorId));
  }

  const onSubmit = (data) => {
    if (params.id) {
        const model = {
          id: data.id,
          name: data.name,
        }
      dispatch(EmployerAction.saveEmployer(model));
      return
    }
    const model = {
      name: data.name,
    }
    dispatch(EmployerAction.saveEmployer(model));
  };


  const reject = () => {
      setVisible(false);
  };

  return (
    <div className="bg-white">
      <form 
        className="form-custom p-0"
        onSubmit={handleSubmit(onSubmit)}
      >
      <div className="form-modal p-0">
        <Panel header="DATOS GENERALES" toggleable>
          <div className=" grid  sm:grid-cols-1 md:grid-cols-2 gap-2 ">
          
            {employer.isLoading ? (
              <Skeleton />
            ): (
              <span className="p-float-label w-full mt-2">
                  <Controller
                      control={control}
                      render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                      }) => (
                        <InputText
                            type="text"
                            id="name"
                            name="name"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            //className="w-full"
                            className={errors?.name ? 'p-invalid w-full' : 'w-full'}
                        />
                      )}
                      name="name"
                      rules={{
                          required: 'El nombre del empleador es requerido',
                      }}
                  />
                    
                  <label htmlFor="code">Nombre del empleador  *</label>
              </span>
            )}
          </div>              
        </Panel>        
      </div>
      {
        (existError) && <div className='message error'>
            <ul>
                {errors.name ? (
                    <>
                        <li className="p-error">{errors.name.message}</li>
                    </>
                ) : null}
            </ul>

        </div>
        }
      <div className="flex justify-end gap-4 mt-3">
                    <Button
                        icon="pi pi-times"
                        type="button"
                        label="Cancelar"
                        onClick={() => { setVisible(true); }}
                        className="sig-button sig-secondary mt-4"
                    />
                    {
                      (mode!=='view')&&(<>
                      {
                        (isCreate || isEdit)&&
                          <Button
                              icon="pi pi-save"
                              type="submit"
                              disabled={existError ? true : false}
                              label="Guardar"
                              loading={employer?.isSaving || employer?.isLoading}
                              className="sig-button sig-primary mt-4"
                          />
                      }
                      </>
                      )
                    }
                </div>
      </form>
      <ConfirmDialog
                visible={visible}
                onHide={() => setVisible(false)}
                message="¿Está seguro que desea cancelar?"
                header="Cancelar"
                icon="pi pi-exclamation-triangle"
                accept={redirectToSearch}
                reject={reject}
            />
    </div>
  )
}

export default EmployerPage;