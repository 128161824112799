import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { useEffect, useState, useRef } from "react";
// import "../../../subscription/pages/Subscription/SubscriptionPage.scss";
import "./SubscriptionPage.scss"
import { useForm, Controller } from "react-hook-form";
import Message from "shared/components/messages/Message";
import { ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Skeleton } from "primereact/skeleton";
import { Column } from "primereact/column";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import useSubscriptions from "features/subscription/hooks/useSubscriptions";
import { useParams } from "react-router-dom";
import useModals from "features/subscription/hooks/useModals";
import moment from "moment";
import ModalParent from "features/subscription/components/subscriptions/ModalParent";
import { useClaimSubscription } from "features/subscription/hooks/useClaimSubscription";
import { CoreConstants } from "core/commons/CoreConstants";
export const SubscriptionPage = ({ title, mode }) => {
  const { updateTitle } = useSetTitlePage();
  const { openModal } = useModals();
  const history = useHistory();
  const menu = useRef(null);
  const {isBlock,
    isActivate,
    isRenew,
    isCreate,
    isEdit,
    isCancel} = useClaimSubscription();
  let items = {
    PENDIENTE: [
      {
        label: "Activar",
        icon: "pi pi-check-circle",
        command: () => {
          openModal("Activo");
        },
      },
    ],
    ACTIVO: [
      {
        label: "Bloquear",
        icon: "pi pi-ban",
        command: () => {
          openModal("Bloqueado");
        },
      },
      {
        label: "Renovar",
        icon: "pi pi-bell",
        command: () => {
          openModal("Renovado");
        },
      },
      {
        label: "Cancelar",
        icon: "pi pi-trash",
        command: () => {
          openModal("Cancelado");
        },
      },
    ],
    EXPIRADO: [
      {
        label: "Renovar",
        icon: "pi pi-bell",
        command: () => {
          openModal("Renovado");
        },
      },
    ],
    CANCELADO : [
      {
        label: "Re-Activar",
        icon: "pi pi-check-circle",
        command: () => {
          openModal("Activo");
        },
      }
    ]
  };


  if (!isBlock)
        items.ACTIVO = items.ACTIVO.filter(c => c.label !== 'Bloquear');
  if (!isActivate)
    items.PENDIENTE = []
    items.CANCELADO = []
  if (!isRenew)
        items.EXPIRADO = []
        items.ACTIVO = items.ACTIVO.filter(c => c.label !== 'Renovar');
  if (!isCancel)
    items.ACTIVO = items.ACTIVO.filter(c => c.label !== 'Cancelar');


  const errorsArray = [];
  const [minCompanies, setMinCompanies] = useState(0);
  const [minLocations, setMinLocations] = useState(0);
  const {
    formState: { errors },
    handleSubmit,
    control,
    setError,
    setValue,
    getValues,
    clearErrors,
  } = useForm();

  const [visibleFila, setVisibleFila] = useState(false);
  const [idEmpresa, setIdEmpresa] = useState(0);
  const [cancel, setCancel] = useState(false);
  const [isStorageEnabled, setIsStorageEnabled] = useState(false);
  const params = useParams();
  const isNew = title === "Nueva Suscripción";
  const styles = {
    PENDIENTE: {
      border: "1px solid #808080 ",
      padding: "0rem 0.50rem",
      borderRadius: "5px",
    },
    ACTIVO: {
      border: "1px solid #8CC9A6",
      padding: "0rem 0.50rem",
      borderRadius: "5px",
    },
    BLOQUEADO: {
      border: "1px solid #F6697C",
      padding: "0rem 0.50rem",
      borderRadius: "5px",
    },
    CANCELADO: {
      border: "1px solid #F6697C",
      padding: "0rem 0.50rem",
      borderRadius: "5px",
    },
    EXPIRADO: {
      border: "1px solid #F6697C",
      padding: "0rem 0.50rem",
      borderRadius: "5px",
    },
    Default: {
      border: "1px solid #808080",
      padding: "0rem 0.50rem",
      borderRadius: "5px",
    },
  };
  const {
    dataEmpresa,
    loadingEdit,
    EmpresaSelect,
    companies,
    setCompanies,
    createCompanie,
    customers,
    deleteEmpresa,
    loadingDataManager,
    dataManager,
    isos,
    setIsos,
    saveSubscription,
    updateFila,
    clearSave,
    isSave,
    dataSubscription,
    users
  } = useSubscriptions(isNew ? "Nuevo" : "Editado", true);

  // console.log(users)
  useEffect(() => {
    updateTitle({
      title: "Administración del Servicio",
      subtitle: "Suscripciones",
      description: title,
      previousUrl: "/suscripcion/suscripciones",
    });
  }, []);

  const setearIsos = () => {
    let isosInfo = {};
    dataSubscription.isoIds.forEach((iso) => {
      isosInfo[iso] = {
        id: iso,
        value: true,
      };
    });
    setIsos(isosInfo);
  };

  useEffect(() => {
    if (!isNew) {
      if (
        dataSubscription !== undefined &&
        Object.entries(dataSubscription).length > 0
      ) {
        //Datos Generales
        setValue("id", parseInt(params.id));
        setValue("customerId", dataSubscription.customerId);

        //Información de la Subscripcion
        setValue("locationQuantity", dataSubscription.locationQuantity);
        setValue("companyQuantity", dataSubscription.companyQuantity);
        setValue("countryId", dataSubscription.countryId);
        setValue("userQuantity", dataSubscription.userQuantity);
        setValue("timeZone", dataSubscription.timeZone);
        setValue("numberActiveUsers",dataSubscription.numberActiveUsers);
        setValue("maxFileSizeAllowed",dataSubscription.maxFileSizeAllowed);
        setValue("maxStorageSizeAllowed",dataSubscription.maxStorageSizeAllowed);
        setValue("storageUsed",dataSubscription.storageUsed);
        setValue("storageName",dataSubscription.storageName);
        setValue("storageURL",dataSubscription.storageURL);

        setIsStorageEnabled(dataSubscription.isStorageEnabled === isStorageEnabled ? true : false)
        

        setCompanies(dataSubscription.companies);

        setearIsos();

        setMinCompanies(dataSubscription.companies.length);
        setMinLocations(dataSubscription.locationQuantity);
      }
    }
  }, [dataSubscription]);
  useEffect(() => {
    if (isSave) {
      clearSave();
      history.push("/suscripcion/suscripciones");
    }
  }, [isSave]);
  const SkeletonCustom = ({ width = "100%" }) => {
    return <Skeleton width={width} height="2.2rem" className="my-4" />;
  };

  const isDisabled = (change = false) => {
    switch (dataSubscription.statusCode) {
      case undefined:
        return false
      case "PENDIENTE":
        return false
      case "ACTIVO" :
        return change ? true : false
      default:
        return true
    }
  }

  const getDataEmpresa = (id) => {
    dataEmpresa(id);
  };

  const createCompanies = () => {
    let cantity = parseInt(getValues("companyQuantity"));
    let cantity_old = companies.length;
    if (cantity === undefined || cantity === "")
      setError("companyQuantity", {
        type: "manual",
        message: "Tiene que ingresar una cantidad para agregar empresas",
      });
    else {
      if (cantity < 0) {
        setError("companyQuantity", {
          type: "manual",
          message: "No se admiten números negativos",
        });
      } else {
        let add = cantity - cantity_old;
        if (cantity > cantity_old) {
          clearErrors("companyQuantity");
          for (let i = 0; i < add; i++) {
            createCompanie();
          }
        } else {
          if (cantity === cantity_old)
            setError("companyQuantity", {
              type: "manual",
              message:
                "Tiene que ingresar un numero de empresas mayor al ingresado anteriormente",
            });
          else
            setError("companyQuantity", {
              type: "manual",
              message: "Tiene que eliminar " + add * -1 + " filas",
            });
        }
      }
    }
  };
  const submit = (data) => {
    if (parseInt(data.companyQuantity) !== companies.length) {
      setError("companyQuantity", {
        type: "manual",
        message:
          "El número de empresas debe ser igual al número de empresas añadidas",
      });
      return;
    }

    if (Object.entries(isos).length === 0) {
      setError("isoIds", {
        type: "manual",
        message: "Al menos debe seleccionar una solución",
      });
      return;
    }
    if (!isNew && dataSubscription.statusCode === "ACTIVO") {
      if (companies.length < minCompanies) {
        setError("companyQuantity", {
          type: "min",
          message:
            "El número de empresas debe ser mayor o igual a las que se registro inicialmente",
        });
        return;
      }
    }
    if (!isNew && dataSubscription.statusCode === "ACTIVO") {
      let locationQuantity = getValues("locationQuantity");

      if (locationQuantity < minLocations) {
        setError("locationQuantity", {
          type: "min",
          message:
            "El número de sedes debe ser mayor o igual a las que se registro inicialmente",
        });
        return;
      }
    }
    saveSubscription(data,minCompanies);
  };
  const template = (rowData) => {
    return (
      <div className="flex items-center justify-center gap-x-4">
        <InputText
          disabled={isDisabled()}
          className={isDisabled() ? "disabled" : ''}
          onChange={(e) => {
            updateFila({
              id: rowData.id,
              name: e.target.name,
              value: e.target.value,
            });
          }}
          value={isNew ? null : rowData.name}
          name={rowData.id + "-" + rowData.name}
        />
        {dataSubscription.statusCode === "PENDIENTE"&& (
          <i
            onClick={() => {
              setVisibleFila(true);
              setIdEmpresa(rowData.id)
            }}
            className="pi pi-trash cursor-pointer"
          ></i>
        )}
        {dataSubscription.statusCode === undefined && (
          <i
            onClick={() => {
              setVisibleFila(true);
              setIdEmpresa(rowData.id)
            }}
            className="pi pi-trash cursor-pointer"
          ></i>
        )}
      </div>
    );
  };
  const columns = [{ field: "name", header: "Empresa" }];
  const columnsRenewals = [
    { field: "startValidity", header: "Inicio" },
    { field: "endValidity", header: "Fin" },
  ];

  const columnsClients = [
    { field: "name", header: "Nombre" },
    { field: "statusName", header: "Status" },
    { field: "email", header: "Email" },
  ];

  const ColumnComponents = columns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable
        className="companie"
        body={template}
      />
    );
  });

  const Field = ({ row, name }) => {
    const dateFormat = moment.parseZone(row[name]).format(CoreConstants.Format.LOCALE);
    return <h4>{dateFormat}</h4>;
  };

  const FieldClient = ({ row, name }) => {
    const dateFormat = row[name]
    return <h4>{dateFormat}</h4>;
  };

  const ColumnComponentsRenewals = columnsRenewals.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable
        body={(row) => <Field name={col.field} row={row} />}
      />
    );
  });

  const ColumnComponentsClients = columnsClients.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable
        body={(row) => <FieldClient name={col.field} row={row} />}
      />
    );
  });
  return (
    <div className="bg-white">
      <form className="form-custom p-0" onSubmit={handleSubmit(submit)}>
        <div className="form-modal p-0">
          <Panel header="DATOS GENERALES" toggleable>
            <div className="grid sm:grid-cols-1 md:grid-cols-2">
              <span className="p-float-label w-full mt-6">
                <Controller
                  control={control}
                  rules={{
                    required: "La Empresa es Obligatoria.",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Dropdown
                        options={customers}
                        optionLabel="description"
                        disabled={isDisabled(true)}

                        optionValue={(e) => {
                          return e.id;
                        }}
                        filter
                        className={`w-full ${isDisabled(true) ? 'disabled' : ''} ${
                          errors.customerId?.type === "required"
                            ? "p-invalid"
                            : ""
                        }`}
                        onChange={(e) => {
                          onChange(e);
                          getDataEmpresa(e.target.value);
                        }}
                        value={value}
                      />
                      <label htmlFor="dropdown">Empresa *</label>
                    </>
                  )}
                  name="customerId"
                />
              </span>
              <span className="w-full mt-6 text-right">
                {dataSubscription && (
                  <label
                    style={
                      isNew
                        ? styles.Default
                        : styles[dataSubscription.statusCode]
                    }
                  >
                    {isNew ? "Estado" : dataSubscription.statusCode}
                  </label>
                )}
              </span>
            </div>

            <div className="grid sm:grid-cols-1 md:grid-cols-2">
              {loadingEdit || loadingDataManager ? (
                <SkeletonCustom />
              ) : (
                <span className="p-float-label w-full mt-6 pr-2">
                  <InputText
                    type="text"
                    id="ruc"
                    name="ruc"
                    value={
                      EmpresaSelect.documentNumber
                        ? EmpresaSelect.documentNumber
                        : null
                    }
                    readOnly
                    disabled
                    className="disabled"
                  />
                  <label htmlFor="name">Ruc</label>
                </span>
              )}
            </div>
            <div className={`grid sm:grid-cols-1 md:grid-cols-2 ${
                loadingEdit || loadingDataManager ? "gap-x-4" : ""
              }`}
            >
              {loadingEdit || loadingDataManager ? (
                <SkeletonCustom />
              ) : (
                <span className="p-float-label w-full mt-6 pr-2">
                  <InputText
                    type="text"
                    id="  "
                    name="rubro"
                    value={
                      EmpresaSelect.businessSector
                        ? EmpresaSelect.businessSector
                        : null
                    }
                    readOnly
                    disabled
                    className="disabled"
                  />
                  <label htmlFor="name">Rubro</label>
                </span>
              )}
              {loadingEdit || loadingDataManager ? (
                <SkeletonCustom />
              ) : (
                <span className="p-float-label w-full mt-6">
                  <InputText
                    type="text"
                    id="businessLine"
                    name="businessLine"
                    value={
                      EmpresaSelect.businessLineName
                        ? EmpresaSelect.businessLineName
                        : null
                    }
                    readOnly
                    disabled
                    className="disabled"
                  />
                  <label htmlFor="name">Linea de Negocio</label>
                </span>
              )}
            </div>
          </Panel>

          <Panel header="INFORMACIÓN DE LA SUSCRIPCIÓN" toggleable>
            <h6 className="p-float-label w-full mt-2 text-xs font-bold pl-2">
              EMPRESAS Y SEDES
            </h6>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-x-5">
              <span className="p-float-label w-full mt-6 col-span-1">
                <Controller
                  control={control}
                  rules={{
                    required: "El Número de Sedes es Obligatorio",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputText
                        disabled={isDisabled()}
                        type="number"
                        onChange={onChange}
                        id="locationQuantity"
                        value={value}
                        name="locationQuantity"
                        className={`w-full ${isDisabled() ? 'disabled' : ''} ${
                          errors.locationQuantity?.type === "required" ||
                          errors.locationQuantity?.type === "min"
                            ? "p-invalid"
                            : ""
                        }`}
                      />
                      <label htmlFor="locationQuantity">Número de Sedes *</label>
                    </>
                  )}
                  name="locationQuantity"
                />
              </span>
              <div className="col-span-1"></div>
              <span className="p-float-label w-full mt-6 col-span-1">
                <Controller
                  control={control}
                  rules={{
                    required: "El Número de Empresas es Obligatorio",
                    min:{
                      message:"El Número mínimo de Empresas es 1",
                      value:1
                    }
                  }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputText
                        type="number"
                        onChange={(e) => {
                          if (Math.sign(e.target.value) === -1) {
                            e.target.value = "";
                          }
                          onChange(e);
                        }}
                        disabled={isDisabled()}
                        min="1"
                        max="100"
                        value={value}
                        id="companyQuantity"
                        className={`w-full ${isDisabled()  ? 'disabled' : ''} ${
                          errors.locationQuantity?.type === "required" ||
                          errors.locationQuantity?.type === "min"
                            ? "p-invalid"
                            : ""
                        }`}
                      />
                      <label htmlFor="companyQuantity">
                        Número de Empresas *
                      </label>
                    </>
                  )}
                  name="companyQuantity"
                />
              </span>
              <span className="p-float-label w-full mt-6 col-span-1 flex items-center">
                <Button
                  type="button"
                  label="Añadir Empresas"
                  onClick={createCompanies}
                  disabled={isDisabled()}
                  className="btn btn-primary rounded-none w-8/12 px-10"
                />
              </span>
            </div>

            <DataTable
              responsiveLayout="scroll"
              value={companies}
              dataKey="id"
              className="mt-6 table-companies"
              stripedRows
              paginator
              editMode="row"
              rows={5}
              rowsPerPageOptions={[5, 10, 15]}
              emptyMessage="No se configuraron Empresas"
            >
              {ColumnComponents}
            </DataTable>

            <h6 className="p-float-label w-full mt-8 text-xs font-bold pl-2">
              SOLUCIONES
            </h6>
            {!loadingDataManager && (
              <div className="grid-roles scroll mt-8">
                <div className="rol">
                  <Controller
                    control={control}
                    render={() => (
                      <div className="rol-list mt-2">
                        {dataManager.optionISO.map((item, index) => {
                          return (
                            <div className="mb-4 flex gap-3" key={index}>
                              <InputSwitch
                              
                                inputId={item.id}
                                disabled = {isDisabled()}
                                className={`w-full ${
                                  errors.isoIds?.type === "manual"
                                    ? "p-invalid"
                                    : ""
                                }`}
                                id={item.id}
                                checked={isos[item.id]?.id === item.id}
                                onChange={(e) => {
                                  let isos_temp = isos;
                                  let id_value = e.target.id;
                                  let info = {};
                                  info[id_value] = {
                                    id: e.target.id,
                                    value: e.target.value,
                                  };
                                  if (e.target.value) {
                                    setIsos({ ...isos, ...info });
                                  } else {
                                    delete isos_temp[e.target.id];
                                    setIsos({ ...isos_temp });
                                  }
                                }}
                              />
                              <p>{item.description}</p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    name="isoIds"
                  />
                </div>
              </div>
            )}                        

            {loadingDataManager && <SkeletonCustom />}
          </Panel>          
              <Panel header="CONFIGURACIÓN" toggleable>
                <div className="grid sm:grid-cols-1 md:grid-cols-2">
                  <Controller
                    control={control}
                    rules={{
                      required: "La Zona horaria es obligatoria",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full mt-6">
                        <Dropdown
                          options={dataManager.country}
                          disabled={isDisabled(true)}
                          optionLabel="description"
                          optionValue="id"
                          className={`w-full ${isDisabled(true) ? "disabled" : ''} ${
                            errors.countryId?.type === "required" ||
                            errors.countryId?.type === "manual"
                              ? "p-invalid"
                              : ""
                          }`}
                          onChange={onChange}
                          value={value}
                        />
                        <label htmlFor="dropdown">Zona Horaria *</label>
                      </span>
                    )}
                    name="countryId"
                  />
                </div>
                <div className="grid sm:grid-cols-1 md:grid-cols-2">
                  <Controller
                    control={control}
                    rules={{
                      required: "El Número de usuarios es obligatorio",
                      min: {
                        value: 1,
                        message: "El Número mínimo de usuarios es de 1",
                      },
                      maxLength: {
                        value: 4,
                        message: "El Número máximo de digitos es 4",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full mt-6 pr-2">
                        <InputText
                          type="number"
                          id="licenses"
                          name="licenses"
                          onChange={onChange}
                          disabled={isDisabled()}
                          value={value}
                          className={`w-full ${isDisabled() ? "disabled" : ''} ${
                            errors.userQuantity?.type === "required" ||
                            errors.userQuantity?.type === "manual" ||
                            errors.userQuantity?.type === "min" ||
                            errors.userQuantity?.type === "maxLength"
                              ? "p-invalid"
                              : ""
                          }`}
                        />
                        <label htmlFor="name">Número de Usuarios *</label>
                      </span>
                    )}
                    name="userQuantity"
                  />
                  {!isNew && (
                    <Controller
                      control={control}
                      render={({ field: { value } }) => (
                        <span className="p-float-label w-full mt-6 pr-2">
                          <InputText
                            type="text"
                            id="active"
                            name="active"
                            value={value}
                            readOnly
                            disabled
                            className="disabled"
                          />
                          <label htmlFor="name">Usuarios Activos</label>
                        </span>
                      )}
                      name="numberActiveUsers"
                    />
                  )}
                </div>
                <h6 className="p-float-label w-full mt-2 text-xs font-bold pl-2">
                   ALMACENAMIENTO
                </h6>

                <div className="grid sm:grid-cols-1 md:grid-cols-2">
                  <span className="p-float-label w-full mt-6">
                        {loadingEdit || loadingDataManager ? (
                          <SkeletonCustom />
                        ) : (
                          <Controller control={control}                          
                                      render={({ field: { onChange, value } }) => (
                              <InputText
                                id="storageName"
                                name="storageName"
                                readOnly
                                disabled
                                className="disabled"
                                //onChange={onChange}                                
                                value={value}
                              />
                            )}
                            name="storageName"
                          />
                        )}
                        <label htmlFor="storageName">Nombre del almacenamiento</label>
                  </span>
                  <span className="ml-6">
                      {loadingEdit || loadingDataManager ? (
                          <SkeletonCustom />
                        ) : (
                          <InputSwitch                            
                            checked={isStorageEnabled}
                            onChange={(e) => {
                              setValue("isStorageEnabled",e.target.value ? true : false)
                              setIsStorageEnabled(!isStorageEnabled);
                            }}
                            name="isStorageEnabled"
                            id="isStorageEnabled"
                          />                          
                        )}
                        <p>Habilitar</p>                        
                  </span>
                </div>
                <div className="grid sm:grid-cols-1 md:grid-cols-2">
                  <span className="p-float-label w-full mt-6">
                        {loadingEdit || loadingDataManager ? (
                          <SkeletonCustom />
                        ) : (
                          <Controller control={control}                          
                                      render={({ field: { onChange, value } }) => (
                              <InputText
                                id="storageURL"
                                name="storageURL"
                                readOnly
                                disabled
                                className="disabled"
                                //onChange={onChange}                                
                                value={value}
                              />
                            )}
                            name="storageURL"
                          />
                        )}
                        <label htmlFor="storageURL">Nombre del url</label>
                  </span>
                  <span className="w-full mt-6 text-right">                      
                      
                  </span>
                </div>
                <div className="grid sm:grid-cols-1 md:grid-cols-2">
                  <span className="p-float-label w-full mt-2">
                        {loadingEdit || loadingDataManager ? (
                          <SkeletonCustom />
                        ) : (
                          <Controller control={control}                          
                                      render={({ field: { onChange, value } }) => (
                              <span className="p-float-label w-full mt-6 pr-2">
                                <InputText
                                  type="number"
                                  id="maxStorageSizeAllowed"
                                  name="maxStorageSizeAllowed"
                                  onChange={onChange}
                                  value={value}
                                />
                                <label htmlFor="maxStorageSizeAllowed">Maximo almacenamiento  (MB)</label>
                              </span>                              
                            )}
                            name="maxStorageSizeAllowed"
                          />
                        )}                        
                  </span>
                  <span className="p-float-label w-full mt-2">                      
                        {loadingEdit || loadingDataManager ? (
                          <SkeletonCustom />
                        ) : (
                          <Controller control={control}                          
                                      render={({ field: { onChange, value } }) => (
                              <span className="p-float-label w-full mt-6 pr-2">
                                <InputText
                                  type="number"
                                  id="maxFileSizeAllowed"
                                  name="maxFileSizeAllowed"
                                  onChange={onChange}                                
                                  value={value}
                                />
                                <label htmlFor="maxFileSizeAllowed">Maximo por archivo (MB)</label>
                              </span>
                            )}
                            name="maxFileSizeAllowed"
                          />
                        )}                        
                  </span>
                </div>
                <div className="grid sm:grid-cols-1 md:grid-cols-2">
                <span className="p-float-label w-full mt-6">
                        {loadingEdit || loadingDataManager ? (
                          <SkeletonCustom />
                        ) : (
                          <Controller control={control}                          
                                      render={({ field: { onChange, value } }) => (
                              <InputText
                                id="storageUsed"
                                name="storageUsed"
                                readOnly
                                disabled
                                className="disabled"
                                //onChange={onChange}
                                value={value}
                              />
                            )}
                            name="storageUsed"
                          />
                        )}
                        <label htmlFor="storageUsed">Almacenamiento Utilizado (%)</label>
                  </span>
                  <span className="w-full mt-6 text-right">                      
                      
                  </span>
                </div>



              </Panel>          
          {!isNew && (
              <Panel header="USUARIOS" toggleable>
                {
                  dataSubscription.statusCode==='ACTIVO'&&(
                    <DataTable
                      value={users}
                      dataKey="id"
                      className="mt-6 table-companies"
                      stripedRows
                      paginator
                      editMode="row"
                      rows={5}
                      rowsPerPageOptions={[5, 10, 15]}
                      emptyMessage="No se encontraron usuarios"
                    >
                      {ColumnComponentsClients}
                    </DataTable>
                  )
                }
              </Panel>
          )}
          {!isNew && (
            <>
              <Panel header="RENOVACIÓN" toggleable>
                <DataTable
                  value={dataSubscription.renewals}
                  dataKey="id"
                  className="mt-6 table-companies"
                  stripedRows
                  paginator
                  editMode="row"
                  rows={5}
                  rowsPerPageOptions={[5, 10, 15]}
                  emptyMessage="No se encontraron renovaciones"
                >
                  {ColumnComponentsRenewals}
                </DataTable>
              </Panel>
              <Panel header="HISTORIAL" toggleable></Panel>
            </>
          )}
        </div>
        {Object.entries(errors).forEach((key, value) => {
          errorsArray.push(key[1].message);
        })}
        {errorsArray.length > 0 && (
          <Message messages={errorsArray} status="error" />
        )}
        <div className="flex justify-end gap-4 mt-3">
          <Button
            icon="pi pi-times"
            type="button"
            label="Cancelar"
            onClick={() => setCancel(true)}
            loading={loadingEdit || loadingDataManager}
            disabled={loadingEdit || loadingDataManager}
            className="sig-button  sig-secondary mt-4"
          />
          {
            (mode!=='view')&&(<>
              {
                (isCreate || isEdit)&&
                  <Button
                    icon="pi pi-save"
                    type="submit"
                    label="Guardar"
                    // onClick={handleClick}
                    loading={loadingEdit || loadingDataManager}
                    className="sig-button sig-primary mt-4"
                  />
              }
            </>
            )
          }
          {
            (title==='Editar Suscripción')&& (
            <>
              <Button
                icon="pi pi-envelope"
                type="button"
                label="Cambiar Estado"
                onClick={(event) => menu.current.toggle(event)}
                loading={loadingEdit || loadingDataManager}
                className="sig-button sig-error mt-4"
              />
              <Menu
                model={items[dataSubscription.statusCode]}
                popup
                ref={menu}
              />
            </>
          )}
        </div>
      </form>
      {!isNew && <ModalParent />}
      <ConfirmDialog
        visible={visibleFila}
        onHide={() => setVisibleFila(false)}
        message="¿Está seguro que desea eliminar la empresa?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={() => deleteEmpresa(idEmpresa)}
        reject={() => setVisibleFila(false)}
      />
      <ConfirmDialog
        visible={cancel}
        onHide={() => setCancel(false)}
        message="¿Está seguro que desea cancelar?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={() => history.push("/suscripcion/suscripciones")}
        reject={() => setCancel(false)}
      />
    </div>
  );
};