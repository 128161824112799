/* eslint-disable react-hooks/exhaustive-deps */

import { CoreConstants } from "core/commons/CoreConstants";
import { BusinessSheetTypeConstants } from "features/maintenance/commons/BusinessSheetTypeConstants";
import {
  getFilterValuesFromPiners,
  getPinersValues,
} from "features/maintenance/helpers/businessSheetType/BusinessSheetTypeHelper";
import { BusinessSheetTypeAction } from "features/maintenance/store/actions/BusinessSheetTypeAction";
import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Panel } from "primereact/panel";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ModalFilterComponent from "shared/components/modal-filter/ModalFilterComponent";
import PinerComponent from "shared/components/Piner/PinerComponent";
import { FilterSearchComponent } from "shared/components/searchPageComponents/FilterSearchComponent";
import { useFilter } from "shared/hooks/useFilter";

export const FilterComponent = () => {
  const {
    changeShowModal,
    onChangeValues,
    onSubmit,
    piners,
    removePiner,
    showModal,
    values,
    onDownload,
    resetValues,
    resetState,
  } = useFilter(
    "name",
    getFilterValuesFromPiners,
    getPinersValues,
    BusinessSheetTypeAction.search,
    BusinessSheetTypeAction.downloadReport
  );
  const history = useHistory();

  const loading = useSelector(
    (state) => state.businessSheetTypeReducer.businessSheet.loading
  );

  const isLoadingDownload = useSelector(
    (state) => state.businessSheetTypeReducer.businessSheet.isLoadingDownload
  );

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  return (
    <>
      <Panel header="FILTRO POR" toggleable>
        <FilterSearchComponent
          label="Buscar por nombre de Tipo de Ficha de Negocio"
          changeShowModal={changeShowModal}
          isLoadingDownload={isLoadingDownload}
          loading={loading}
          name={BusinessSheetTypeConstants.FieldName.Filter.NAME}
          onChangeValues={onChangeValues}
          onDownload={onDownload}
          onSubmit={onSubmit}
          value={values[BusinessSheetTypeConstants.FieldName.Filter.NAME]?.value || ""}
        />
        {piners.length > 0 && (
          <div className="filter-piners mt-3">
            {piners.map(({ value, field }, index) => (
              <PinerComponent
                name={value}
                field={field}
                removePiner={removePiner}
                key={index}
              />
            ))}
          </div>
        )}
        {showModal && (
          <ModalFilterComponent
            closeModal={() => changeShowModal(false)}
            handleSubmit={onSubmit}
            clearFields={resetValues}
          >
            <div className="w-full sm:w-80">
              <div className="flex gap-4 pt-3">
                <div className="w-full">
                <span className="p-float-label">
                      <Calendar
                        className="w-full"
                        style={{height: 37.19}}
                        name={BusinessSheetTypeConstants.FieldName.Filter.FROM_DATE}
                        value={values[BusinessSheetTypeConstants.FieldName.Filter.FROM_DATE]?.value}
                        onChange={(e) => {
                          const pinerValue = e.target.value && moment(e.target.value).format(CoreConstants.Format.DDMMYYYY)
                          onChangeValues({
                            name: e.target.name, 
                            value: {
                              value: e.target.value,
                              searchValue: e.target.value,
                              pinerValue: pinerValue,
                            }
                          })
                        }}
                      />
                      <label htmlFor="openDate">Creación desde</label>
                    </span>
                </div>
                <div className="w-full">
                  <span className="p-float-label">
                  <Calendar
                        className="w-full"
                        style={{height: 37.19}}
                        name={BusinessSheetTypeConstants.FieldName.Filter.TO_DATE}
                        value={values[BusinessSheetTypeConstants.FieldName.Filter.TO_DATE]?.value}
                        onChange={(e) => {
                          const pinerValue = e.target.value && moment(e.target.value).format(CoreConstants.Format.DDMMYYYY)
                          onChangeValues({
                            name: e.target.name, 
                            value: {
                              value: e.target.value,
                              searchValue: e.target.value,
                              pinerValue: pinerValue,
                            }
                          })
                        }}
                      />
                      <label htmlFor="openDate">Creación hasta</label>
                  </span>
                </div>
              </div>
            </div>
          </ModalFilterComponent>
        )}
      </Panel>
      <Button
        label="Nuevo"
        icon="pi pi-plus"
        className="sig-button sig-dark mt-4"
        onClick={(e) => {
          history.push("/mantenimiento/ficha/nuevo");
        }}
      />
    </>
  );
};
