import { ProjectsConstants } from "features/projects/commons/ProjectsConstants";
const initialState = {
  projects: {
    currentData: [],
    data: [],
    loading: false,
    pagination: {},
    currentCol: "firstName",
    order: "asc",
  },
  tasks: {
    tasks:[],
    isLoadingTasks:false,
    pagination: {},
  },
  projectsFilter: {
    // showModal: false,
    values: [],
  },
  taskEdit: {
    loadingEdit: true,
    loadingReport:false,
    isLoadingTask:false,
    // loadingSave:false,
    modalTask:false,
    mode:"new",
    // typeModal:"",
    // isSave:false,
    // EmpresaSelect:{},
    // customers:[],
    dataProject: {},
  },
  dataManager: {
    loadingDataManager: true,
    dataManager: {
      responsibles: [],
      status: [],
      companyCustomers: [],
    },
  },
  snapshot: {
    data: {},
    loading: false,
    downloadLoading: false,
    dataTable: [],
    pagination: {},
    values: [],
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case ProjectsConstants.Accion.Projects.SEARCH_PROJECTS:
      return {
        ...state,
        projects: {
          ...state.projects,
          ...action.payload,
        },
      };
    // case SubscriptionConstants.Accion.Subscriptions.ADVANCED_FILTER_TOGGLE:
    //   return {
    //     ...state,
    //     subscriptionsFilter: {
    //       ...state.subscriptionsFilter,
    //       ...action.payload,
    //     },
    //   };
    // case SubscriptionConstants.Accion.Subscriptions.REMOVE_FILTER_VALUES:
    //   return {
    //     ...state,
    //     subscriptionsFilter: {
    //       values: [...action.payload],
    //     },
    //   };
    case ProjectsConstants.Accion.Projects.SET_FILTER_VALUES:
      return {
        ...state,
        projectsFilter: {
          ...state.projectsFilter,
          ...action.payload,
        },
      };

    case ProjectsConstants.Accion.Projects.DATA_MASTER:
      return {
        ...state,
        dataManager: {
          ...state.dataManager,
          ...action.payload,
        },
      };
    case ProjectsConstants.Accion.Projects.EDIT_TASKS:
      return {
        ...state,
        taskEdit: {
          ...state.taskEdit,
          ...action.payload,
        },
      };
    case ProjectsConstants.Accion.Projects.GET_TASKS:
      return {
        ...state,
        tasks: {
          // ...state.tasks,
          // ...action.payload,
          tasks: action.payload.tasks,
          isLoadingTasks : action.payload.isLoadingTasks,
          pagination : action.payload.pagination
      }
    };
    case ProjectsConstants.Accion.Projects.CREATE_SNAPSHOT:
      return {
        ...state,
        snapshot: {
          ...state.snapshot,
          ...action.payload
      }
    };
    default:
    return state;
  }
};
