import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import FilterComponent from 'features/riskManagement/components/FilterComponent';
import TableComponent from 'features/riskManagement/components/TableComponent';
import AdvancedFilterComponent from '../components/AdvancedFilterComponent';

const RiskManagementPage = () => {
	const { updateTitle } = useSetTitlePage();
	const [showModal, setShowModal] = useState(false)
	const history = useHistory();

  useEffect(() => {
		updateTitle({
				title: 'Gestión de Riesgos',
				subtitle: 'Riesgos',
		});
// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<FilterComponent setShowModal={setShowModal}/>
			<div className="flex items-center justify-between p-4">
				<Button
					icon="pi pi-plus"
					type="button"
					label="Nuevo"
					className="sig-button sig-dark w-36 pointer" 
				/>
				<Tooltip target=".customClassName" position="top"/>
				<div className="customClassName" data-pr-tooltip="Configurar Riesgo" data-pr-position="left">
					<i className="pi pi-cog mr-4 text-xl cursor-pointer" onClick={()=> {
						history.push('/gestion-riesgos/inventario');
					}}/>
				</div>
			</div>
			<TableComponent />
			<AdvancedFilterComponent showModal={showModal} setShowModal={setShowModal} /> 
		</div>
	);
};

export default RiskManagementPage;