
import { CoreConstants } from 'core/commons/CoreConstants';
import { PublicConstants } from "features/public/commons/PublicConstants";

import { toastAction } from "core/store/actions/ToastAction";

import { SurveyService } from 'features/survey/services/SurveyServices';


const getSurveyByGuid = (surveyData) => {
  const setSurvey = ({ dispatch, isLoading, data, activity, error }) => {
    dispatch({
      type: PublicConstants.Accion.SurveyPublic.GET_SURVEY_BY_GUID,
      payload: { isLoading, data, activity, error },
    });
  };

  return (dispatch) => {
    setSurvey({ dispatch: dispatch, isLoading: true, data: null, activity: null, error: false });
    SurveyService.getSurveyByGuid(surveyData).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setSurvey({
            dispatch: dispatch,
            isLoading: false,
            data: data.data?.result,
            activity: data.data?.surveyActivity,
            error: data.data ? false : true
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          // setSurvey({ dispatch: dispatch, isLoading: false, data: null, activity: null, error: true });
          setSurvey({ dispatch: dispatch, isLoading: false, data: null, activity: null, error: data?.message || 'Hubo un error al cargar la encuesta'});
          dispatch(toastAction.warn('Gestión de Encuestas', data.message));
        }
      },
      // () => {
      //   setSurvey({ dispatch: dispatch, isLoading: false, data: null, activity: null, error: true });
      // }
    )
    .catch((error) => {
      setSurvey({ dispatch: dispatch, isLoading: false, data: null, activity: null, error: true });
    });
  };
};

const saveSurveyAnswer = (answers) => {
  const isLoading = ({ dispatch, isSaving, isSaved, message, error  }) => {
    dispatch({
      type: PublicConstants.Accion.SurveyPublic.SAVE_SURVEY_ANSWER,
      payload: {
        isSaving,
        isSaved,
        message,
        error
      },
    });
  };

  return (dispatch) => {

    isLoading({ dispatch: dispatch, isSaving: true, isSaved: false, message: null, error: false });
    SurveyService.saveSurveyAnswer(answers).then(
      ( {data} ) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gestión de Encuestas', 'La encuesta de guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, message: data.data, error: false });
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          // isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, message: null, error: true });
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, message: null, error: true });
          // dispatch(toastAction.warn('Gestión de Encuestas', data.message));
        }
        else {
          // isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, message: null, error: true });
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, message: null, error: true });
          // dispatch(toastAction.warn('Gestión de Encuestas', data.message));
        }
      }
    )
    .catch((error) => {
      isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, message: null, error: true });
    });
  };
};

const getDataMaster = () => {
  const setDataMaster = ({ dispatch, isLoading, surveySectionTypes }) => {
    dispatch({
      type: PublicConstants.Accion.SurveyPublic.GET_DATA_MASTER,
      payload: { isLoading, surveySectionTypes },
    });
  };

  return (dispatch) => {
    setDataMaster({ dispatch: dispatch, isLoading: true, surveySectionTypes: [] });

    SurveyService.getPublicDataMaster().then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            surveySectionTypes: data.data.surveySectionTypes
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            surveySectionTypes: []
          });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            surveySectionTypes: []
          });
          dispatch(toastAction.warn('Gestión de Encuestas', data.message));
        }
      },
      () => {
        setDataMaster({ dispatch: dispatch, isLoading: false, surveySectionTypes: [] });
      }
    );
  };
};

export const SurveyPublicAction = {

  //* Actions Implementadas
  getSurveyByGuid,
  saveSurveyAnswer,
  getDataMaster

  //* Actions sin Implementar
}