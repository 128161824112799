// import { ConfirmDialog } from "primereact/confirmdialog";
// import { useState } from "react";

// const useConfirm = () => {
//   const [visible, setVisible] = useState(false);
//   const [modalConfig, setModalConfig] = useState({
//     header: "",
//     message: "",
//     icon: "",
//     accept: () => {},
//     reject: () => {},
//   });

//   const confirm = ({ header, message, icon, onConfirm, onCancel }) => {
//     setModalConfig({
//       header,
//       message,
//       icon,
//       accept: () => {
//         if (onConfirm) onConfirm();
//         setVisible(false);
//       },
//       reject: () => {
//         if (onCancel) onCancel();
//         setVisible(false);
//       },
//     });
//     setVisible(true);
//   };

//   return { confirm, visible, modalConfig, setVisible };
// };

// const ConfirmModal = ({ visible, modalConfig, onHide }) => (
//   <ConfirmDialog
//     visible={visible}
//     onHide={onHide}
//     header={modalConfig.header}
//     message={modalConfig.message}
//     icon={modalConfig.icon}
//     accept={modalConfig.accept}
//     reject={modalConfig.reject}
//   />
// );

// export { useConfirm, ConfirmModal };
import { ConfirmDialog } from 'primereact/confirmdialog';
import React, { useImperativeHandle, useState, forwardRef } from 'react';

export const ConfirmModal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);
  const [modalConfig, setModalConfig] = useState({
    header: '',
    message: '',
    icon: '',
    accept: () => {},
    reject: () => {},
  });

  useImperativeHandle(ref, () => ({
    openModal: ({ header, message, icon, onConfirm, onCancel }) => {
      setModalConfig({
        header,
        message,
        icon,
        accept: () => {
          onConfirm();
          setVisible(false);
        },
        reject: () => {
          if (onCancel) onCancel();
          setVisible(false);
        },
      });
      setVisible(true);
    },
  }));

  return (
    <ConfirmDialog
      visible={visible}
      onHide={() => setVisible(false)}
      header={modalConfig.header}
      message={modalConfig.message}
      icon={modalConfig.icon}
      accept={modalConfig.accept}
      reject={modalConfig.reject}
    />
  );
});
