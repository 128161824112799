import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import EvaluacionCriterioComponent from './EvaluacionCriterioComponent';
import { Button } from 'primereact/button';
import FormulaComponent from './FormulaComponent';
import ConditionComponent from './ConditionComponent';
import { useDispatch, useSelector } from 'react-redux';
import { RiskModelAction } from 'features/riskManagement/store/actions/RiskModelAction';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useHistory, useParams } from 'react-router-dom';
import { InputNumber } from 'primereact/inputnumber';
import { RiskEvaluationAction } from 'features/riskManagement/store/actions/RiskEvaluationAction';
import RiskMapPage from 'features/riskManagement/pages/RiskMapPage';
import { RiskManagementAction } from 'features/riskManagement/store/actions/RiskManagementAction';

const NewEvaluacionComponent = () => {

	const dispatch = useDispatch()
	const { evaluationTypes } = useSelector(state => state.riskModelReducer.dataMasterEvaluation.data)
	const { isLoadingSave, isLoading, data } = useSelector(state => state.riskEvaluationReducer.editEvaluation)
	const { value } = useSelector(state => state.riskModelReducer.formulaInput)
	const { data: dataEvaluacion  } = useSelector(state => state.riskEvaluationReducer.dataEvaluations)
	const { mathOperators: dataOperator  } = useSelector(state => state.riskModelReducer.dataMasterEvaluation.data)
	const { data: dataCriterias } = useSelector(state => state.riskModelReducer.criteria)
	const { data: dataMatrix, probability, impact, level  } = useSelector(state => state.riskManagementReducer.data)
	const { status: mapHeatisLoaded } = useSelector(state => state.riskManagementReducer.data)
	const [ showConfirmDialog, setShowConfirmDialog] = useState(false)
	const history = useHistory()
	const { idModelo, id } = useParams()

	const {
		control,
		handleSubmit,
		setValue,
		watch,
		reset,
		trigger,
		getValues,
		formState: { errors },
	} = useForm({});

	const valuesFields = watch()

	useEffect(()=>{
		if(id){
			dispatch(RiskEvaluationAction.editEvaluation(id))
		}
		return () => {
			dispatch(RiskEvaluationAction.clearEdit())
			dispatch(RiskEvaluationAction.clearEvaluationsById())
			dispatch(RiskModelAction.clearCriteria())
			dispatch(RiskModelAction.clearCondition())
			dispatch(RiskModelAction.clearFormula())
			dispatch(RiskManagementAction.clearData())
		}
	},[id, dispatch])

	useEffect(()=> {
		if(!isLoading && id){
			reset({
				code: data.code || "",
				name: data.name || "",
				type: data.evaluationTypeId || "",
				position: data.decimalPositions,
				join: data.joinResults,
        isFinalResult: data.isFinalResult,
			})
			if(data.criterias?.length > 0){
				data.criterias.forEach( item => {
					dispatch(RiskModelAction.addCriteria(item.id, item.name))
					setValue(`codeCriteria_${item.id}`,item.code)
					setValue(`nameCriteria_${item.id}`,item.name)
					setValue(`typeCriteria_${item.id}`, item.criteriaTypeId)
					item.values.forEach( register => {
						
						dispatch(RiskModelAction.addRegister(item.id, register.id))
						setValue(`valueRegister_${item.id}_${register.id}`, register.value)
						setValue(`nameRegister_${item.id}_${register.id}`, register.name)						
					})
				})
			}
			if(data.conditions?.length > 0){
				data.conditions.forEach( item => {
					dispatch(RiskModelAction.addCondition(item.id))
					Object.keys(item).forEach( key => {
						if( key === "colorId" || key === "leftConditionTypeId" || key === "leftValue" || key === "logicalOperatorId" || key === "name" || key === "rightConditionTypeId" || key === "rightValue" || key === "processId")
						setValue(`condition_${key}_${item.id}`, item[key])
					})
				})
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[isLoading, dispatch, reset])

	const submit = data => {
		
		const {
			code,
			name,
			type,
			position,
			join,
      isFinalResult,
			...rest
		} = data

		Object.keys(rest).forEach( key => {
			if(!rest[key]){
				delete rest[key]
			}
		})

		const aux = []

		Object.keys(rest).forEach( item => {
			if(!item.split("_")[2]){
				const idAux = item.split("_")[1]
				const auxCriteria = aux.find( item => item.id === Number(idAux))
				if(auxCriteria){
					if(item.split("_")[0]==="codeCriteria") auxCriteria.code = rest[item]
					if(item.split("_")[0]==="nameCriteria") auxCriteria.name = rest[item]
					if(item.split("_")[0]==="typeCriteria") auxCriteria.criteriaTypeId = rest[item]
				}else{
					if(item.split("_")[0]==="codeCriteria") aux.push({
						id: Number(idAux),
						code: rest[item],
						evaluationId: id ? Number(id) : null,
						values: []
					})
					if(item.split("_")[0]==="nameCriteria") aux.push({
						id: Number(idAux),
						name: rest[item],
						evaluationId: id ? Number(id) : null,
						values: []
					})
					if(item.split("_")[0]==="typeCriteria") aux.push({
						id: Number(idAux),
						criteriaTypeId: rest[item],
						evaluationId: id ? Number(id) : null,
						values: []
					})
				}
			}
		})

		Object.keys(rest).forEach( item => {
			if(item.split("_")[2]){
				const idAux = item.split("_")[1]
				const idRegister = item.split("_")[2]
				const auxCriteria = aux.find( item => item.id === Number(idAux))
				if(auxCriteria){
					const values = auxCriteria.values
					const value = values.find( item => item.id === Number(idRegister))
					if(value){
						if(item.split("_")[0]==="nameRegister") value.name = rest[item]
						if(item.split("_")[0]==="valueRegister") value.value = rest[item]
					}else{
						if(item.split("_")[0]==="nameRegister") values.push({
							id: Number(idRegister),
							name: rest[item],
							criteriaId: Number(idAux)
						})
						if(item.split("_")[0]==="valueRegister") values.push({
							id: Number(idRegister),
							value: rest[item],
							criteriaId: Number(idAux)
						})
					}
				}
			}
		})

		const conditions = []

		Object.keys(rest).forEach( item => {
			if(item.split("_")[0] === 'condition'){
				const idAux = item.split("_")[2]
				const auxCondition = conditions.find( item => item.id === Number(idAux))
				if( auxCondition ){
					auxCondition[item.split("_")[1]] = rest[item]
				}else{
					conditions.push({
						id: Number(idAux),
						[item.split("_")[1]]: rest[item]
					})
				}
			}
		})

		const formula = []
		value.forEach( name => {
			if(dataEvaluacion?.map( item => item.name).includes(name)){
				formula.push({
					parentEvaluationId: id ? Number(id) : null,
					selectedEvaluationId: Number(dataEvaluacion.find( item => item.name === name).id)
				})
			}else if(dataCriterias?.map(item => item.name).includes(name)){
				formula.push({
					parentEvaluationId: id ? Number(id) : null,
					selectedCriteriaId: Number(dataCriterias?.find( item => item.name === name).id)
				})
			}
			else if(dataOperator.map( item => item.description).includes(name)){
				formula.push({
					parentEvaluationId: id ? Number(id) : null,
					operatorId: Number(dataOperator.find( item => item.description === name).id)
				})
			} else {
					formula.push({
					parentEvaluationId: id ? Number(id) : null,
					value: Number(name)
				})
			}
		})

		const auxMatrix = []

		dataMatrix.forEach(row => {
			row.forEach( item => {
				auxMatrix.push({
					"id": Number(item.id) * (-1),
					"rowId": Number(item.rowId),
					"colId": Number(item.colId),
					"levelId": Number(level.find(lev => lev.colorId === item.colorId)?.id),
					"colorId": Number(item.colorId),
					"colNumber": Number(item.colNumber),
					"rowNumber": Number(item.rowNumber),
				})
			})
		})

		const probabilitySettings = probability.map( (item, key) => ({
      "id": Number(item.id),
      "evaluationId": id ? Number(id) : null,
      "name": item.name,
      "value": Number(item.value),
      "position": key,
    }))

		const impactSettings = impact.map( (item, key) => ({
      "id": Number(item.id),
			"evaluationId": id ? Number(id) : null,
      "name": item.name,
      "value": Number(item.value),
      "position": key,
    }))

		const levelSettings = level.map( (item, key) => ({
			id: Number(item.id),
			evaluationId: id ? Number(id) : null,
			name: item.name,
			colorId: Number(item.colorId),
			position: key,
      processId: item.processId ? Number(item.processId) : null,
		}))

		// console.log(formula)

		const send = {
			id: id ? Number(id) : null,
			modelId: Number(idModelo),
			code: data.code,
			name: data.name,
			evaluationTypeId: data.type,
			decimalPositions: data.position,
      isFinalResult: data.isFinalResult,
			joinResults: data.type === 251 ? false : data.join,
			criterias: data.type === 251 ? [] : aux,
			conditions: data.type === 251 ? [] : conditions,
			formulaComponents: data.type === 251 ? [] : formula,
			probabilities: data.type === 251 ? probabilitySettings : [],
			impacts: data.type === 251 ? impactSettings : [],
			levels: data.type === 251 ? levelSettings : [],
			heatMap: data.type === 251 ? auxMatrix : [],
		}

    dispatch(RiskEvaluationAction.saveModel({model: send, isNewModel: id ? false:true, history}))
	}
	
	return (
		<div>
			<Panel header='DATOS GENERALES' toggleable>
			<div className="grid sm:grid-cols-4 md:grid-cols-4 gap-2">
				<span className="p-float-label w-full mt-4">
					<Controller
						control={control}
						name="code"
						rules={{
							required: 'Código es requerido',	
						}}
						render={({field: { onChange, value }}) => (
							<InputText
								type="text"
								id="code"
								onChange={onChange}
								value={value || ""}
								className={errors?.code ? 'p-invalid w-full' : 'w-full'}
							/>
						)}
					/>
					<label htmlFor="code">Código *</label>
				</span>
				<span className="p-float-label w-full mt-4 col-span-2">
					<Controller
						control={control}
						name="name"
						rules={{
							required: 'Nombre es requerido',	
						}}
						render={({field: { onChange, value }}) => (
							<InputText
								type="text"
								id="name"
								onChange={onChange}
								value={value || ""}
								className={errors?.name ? 'p-invalid w-full' : 'w-full'}
							/>
						)}
					/>
					<label htmlFor="name">Nombre *</label>
				</span>
				<span className="p-float-label w-full mt-4 hidden md:block">
						&nbsp;
				</span>
			</div>
			<div className="grid sm:grid-cols-4 md:grid-cols-4 gap-2">
				<span className="p-float-label w-full mt-6">
					<Controller
						control={control}
						name="type"
						rules={{
							required: 'Tipo es requerido',	
						}}
						render={({field: { onChange, value }}) => (
							<Dropdown
								options={evaluationTypes}
								optionLabel= 'description'
								optionValue='id'
								id="type"
								onChange={(e) => {
									onChange(e);
									if(!mapHeatisLoaded){
										dispatch(RiskManagementAction.getData(true));
									}
								}}
								value={value}
								className={errors?.type ? 'p-invalid w-full' : 'w-full'}
							/>
						)}
					/>
					<label htmlFor="type">Tipo *</label>
				</span>
				<span className="p-float-label w-full mt-4 col-span-3 hidden md:block">
						&nbsp;
				</span>
			</div>
			<div className="grid sm:grid-cols-4 md:grid-cols-4 gap-2">
				<span className="p-float-label w-full mt-6">
					<Controller
						control={control}
						name="position"
						rules={{
							required: 'Posición de decimales es requerido',	
						}}
						render={({field: { onChange, value }, fieldState: { error },}) => (
							<InputNumber
								inputId="position"
								min={0}
								max={2}
								value={value}
								onValueChange={(e) => onChange(e.value)} 
								useGrouping={false}
								className={error ? 'p-invalid w-full' : 'w-full'}
							/>
						)}
					/>
					<label htmlFor="position">Posición de decimales *</label>
				</span>
				<span className="p-float-label w-full mt-4 col-span-3 hidden md:block">
						&nbsp;
				</span>
			</div>
			{
				valuesFields.type === 250 &&
				<Controller
					control={control}
					name="join"
					defaultValue={false}
					render={({ field: { onChange, value }	}) => (
						<div className='flex mt-6'>
							<InputSwitch
                checked={value}
                onChange={(e) => {
                  onChange(e)
                  setValue('isFinalResult', e.target.value)
                  }}/>
							<h5 className='ml-2'>
								Unir Resultado
							</h5>
						</div>
					)}
				/>
			}
      <Controller
        control={control}
        name="isFinalResult"
        defaultValue={false}
        render={({ field: { onChange, value }	}) => (
          <div className='flex mt-6'>
            <InputSwitch 
              checked={value}
              onChange={(e) => {
                if(!getValues().join) onChange(e)
              }}
            tooltip={getValues().join && "No se puede editar si Unir Resultado esta seleccionado"}
            />
            <h5 className='ml-2'>
              Resultado Final
            </h5>
          </div>
        )}
      />
			</Panel>
			{ valuesFields.type === 251 && 
				<RiskMapPage control = {control} setValue={setValue} getValues={getValues} valuesFields={valuesFields}/> 
			}
			{
				(!valuesFields.join && valuesFields.type === 250) && 
				<Panel header='CRITERIO'>
					<EvaluacionCriterioComponent control={control} setValue={setValue} valuesFields={valuesFields} trigger={trigger}	getValues={getValues}/>
				</Panel>
			}
			
				{
					valuesFields.type === 250 &&
					<Panel header='FÓRMULA'>
					<FormulaComponent valuesFields={valuesFields}/>
					</Panel>
				}
				{
					((valuesFields.join || valuesFields.result) && valuesFields.type === 250) &&
					<Panel header='CONDICIÓN'>
						<ConditionComponent control={control} valuesFields={valuesFields} setValue={setValue}/>
					</Panel>
				}
			
			{
				(Object.keys(errors).length > 0) && 
				<div className='message error'>
					<ul>
							{
								Object.keys(errors).map((item, index)=>{return(
									<li key={index} className="p-error">{errors[item].message}</li>
								)})
							}      
					</ul>
				</div>
			}
			<div className='flex justify-end mt-4'>
			<Button
				loading={isLoadingSave}
				icon='pi pi-times'
				label='Cancelar'
				className='sig-button sig-secondary'
				onClick={() => { setShowConfirmDialog(true); }}
			/>
			<Button 
				// type='submit'
				loading={isLoadingSave}
				onClick={handleSubmit(submit)}
				icon='pi pi-save'
				label='Guardar'
				className='sig-button sig-primary ml-4'
			/>
			<ConfirmDialog
				visible={showConfirmDialog}
				onHide={()=>setShowConfirmDialog(false)}
				message="¿Está seguro que desea cancelar?"
				header="Cancelar"
				icon="pi pi-exclamation-triangle"
				accept={()=>{history.push(`/gestion-riesgos/modelo/${idModelo}/evaluaciones`)}}
				reject={()=>setShowConfirmDialog(false)}
			/>
		</div>
		</div>
	);
};

export default NewEvaluacionComponent;