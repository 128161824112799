import { useForm } from 'react-hook-form';

const useProcesses = () => {

  let defaultValues = {
    name: '',
    responsibleId: null,
    processDescription: null,
    formDinamicName: '',
    statusData: null,
    // FANumNC: false,
    // dateDetection: false,
    // Origin: false,
    // LINumNC: false,
    // LIdateDetection: false,
    // LIOrigin: false,
    // LIFinding: false,
    // LIDescriptionNC: false
  };

  const {
    // register,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });


  const valuesFields = watch();
  const existError = Object.keys(errors).length > 0
  // console.log(valuesFields)
  const validateLength = (value) => {
    if (typeof value === 'string') {
      return value.replace(/<[^>]+>/g, '')?.length;
    } else if (value?.htmlValue) {
      return value.htmlValue.replace(/<[^>]+>/g, '').length;
    }
    return '0';
  };

  return {
    control,
    errors,
    valuesFields,
    existError,
    setValue,
    handleSubmit,
    validateLength,
  }
}

export default useProcesses