import { MaintenanceConstants } from "features/maintenance/commons/MaintenanceConstans"; 
import { CoreConstants } from 'core/commons/CoreConstants';
import moment from 'moment';
import { toastAction } from "core/store/actions/ToastAction";
import FileSaver from 'file-saver';
import { AcademicDegreeService } from "features/maintenance/services/AcademicDegreeServices";


const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: MaintenanceConstants.Accion.AcademicDegree.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: MaintenanceConstants.Accion.AcademicDegree.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.name)
      pines.push({ field: 'name', value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });


    advancedFilter.values = pines;
    dispatch({
      type: MaintenanceConstants.Accion.AcademicDegree.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setAcademicDegrees = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.AcademicDegree.SEARCH_ACADEMIC_DEGREES,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const academicDegrees = getState().academicDegreeReducer.academicDegrees;
    setAcademicDegrees({
      dispatch: dispatch,
      isLoading: true,
      data: academicDegrees.data,
    });

    const criteria = {};
    const advancedFilter = getState().academicDegreeReducer.advancedFilter;
    const filter = getState().academicDegreeReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;
  
    //criteria.academicDegreeId = filter?.academicDegreeParent?.id;
    //criteria.managerId = filter.manager?.id;

    
    AcademicDegreeService.searchAcademicDegrees(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setAcademicDegrees({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });          
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          setAcademicDegrees({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Grado Académico', data.message));
        }else{
          setAcademicDegrees({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Grado Académico', data.message));
        }
      },
      () => {
        setAcademicDegrees({ dispatch: dispatch, isLoading: false, data: null });
      }
    ).catch((error)=>{
    });
  };
};

const getAcademicDegreeById = (academicDegreeId) => {
  const setAcademicDegree = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.AcademicDegree.GET_ACADEMIC_DEGREE_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setAcademicDegree({ dispatch: dispatch, isLoading: true });
    AcademicDegreeService.getAcademicDegreeById(academicDegreeId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setAcademicDegree({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setAcademicDegree({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Grado Académico', data.message));
          // dispatch({
          //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
          //   toast: {
          //     titulo: 'Departamento',
          //     mensaje: data.message,
          //     severidad: 'warn',
          //   },
          // });
        }else{
          dispatch(toastAction.error('Grado Académico', data.message));
        }
      },
      () => {
        setAcademicDegree({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};

const saveAcademicDegree = (academicDegree) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentAcademicDegree }) => {
    dispatch({
      type: MaintenanceConstants.Accion.AcademicDegree.SAVE_ACADEMIC_DEGREE,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    const currentAcademicDegree = getState().academicDegreeReducer.academicDegree;
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
      currentAcademicDegree: currentAcademicDegree,
    });
    AcademicDegreeService.saveAcademicDegree(academicDegree).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Grado Académico', 'El Grado Académico se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentAcademicDegree, });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentAcademicDegree });
          dispatch(toastAction.warn('Grado Académico', data.message));
        }else
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
          dispatch(toastAction.error('Grado Académico', data.message));
      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};



const deleteAcademicDegree = (academicDegreeId) => {

  return (dispatch, getState) => {

    AcademicDegreeService.deleteAcademicDegree(academicDegreeId).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Grado Académico', 'El Grado Académico se eliminó correctamente'));
          dispatch({ type: MaintenanceConstants.Accion.AcademicDegree.DELETE_ACADEMIC_DEGREE, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Grado Académico', data.message));
          dispatch({ type: MaintenanceConstants.Accion.AcademicDegree.DELETE_ACADEMIC_DEGREE, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: MaintenanceConstants.Accion.AcademicDegree.DELETE_ACADEMIC_DEGREE, payload: { isDeleted: false } });
      }
    );
  };
};

const getDataMaster = () => {
  const setDataMaster = ({
    dispatch,
    isLoading,
    academicDegreeParent,
    manager,
  }) => {
    dispatch({
      type: MaintenanceConstants.Accion.AcademicDegree.GET_DATA_MASTER,
      payload: { isLoading, academicDegreeParent, manager },
    });
  };

  return (dispatch, getState) => {
    setDataMaster({
      dispatch: dispatch,
      isLoading: true,
      academicDegreeParent: null,
      manager: null,
    });

    const model = {
      isArea: true,
      isAreaPosition: true,
      isEmployee: true,
    };

    AcademicDegreeService.getDataMaster(model).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            academicDegreeParent: data.data.areas,
            manager: data.data.employees,
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            academicDegreeParent: null,
            manager: null,
          });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            academicDegreeParent: null,
            manager: null,
          });
          dispatch(toastAction.warn('Grado Académico', data.message));
          // dispatch({
          //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
          //   toast: {
          //     titulo: 'Departamento',
          //     mensaje: data.message,
          //     severidad: 'warn',
          //   },
          // });
        }
      },
      () => {
        setDataMaster({
          dispatch: dispatch,
          isLoading: false,
          academicDegreeParent: null,
          manager: null,
        });
      }
    );
  };
};


const generateReport = (payload) => {
  const setAcademicDegrees = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.AcademicDegree.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setAcademicDegrees({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().academicDegreeReducer.advancedFilter.filter;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    AcademicDegreeService.generateReport(criteria)
      .then(({ data }) => {
        setAcademicDegrees({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'GradosAcademicos.xls');
      })
      .catch((err) => {
        setAcademicDegrees({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Grado Académico', 'Hubo un error al descargar el archivo'));
      });
  };
};

export const AcademicDegreeAction = {
  toggleAdvancedFilter,
  updateFilter,
  search,
  saveAcademicDegree,
  deleteAcademicDegree,
  getDataMaster,
  generateReport,
  getAcademicDegreeById,
  resetAfterSaved,
}