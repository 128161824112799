import { apiService } from "core/services/ApiService";

const searchPipelines = (criteria) => {
  return apiService.Post("customer/api/Pipeline/Search", criteria);
}

const savePipelines = (data) => {
  return apiService.Post("customer/api/Pipeline/Save", data);
}

const getPipelineById = (pipelineId) => {
  return apiService.Get("customer/api/Pipeline/GetPipelineById?id=" + pipelineId);
}

const generateReport = (payload) => {
  return apiService.Post("customer/api/Pipeline/GenerateReport", payload, {
    responseType: 'blob',
  })
}

const deletePipeline = (pipelineId) => {
  return apiService.Delete("customer/api/Pipeline/Delete?id=" + pipelineId);
}

export const PipelinesService = {
  searchPipelines,
  savePipelines,
  getPipelineById,
  generateReport,
  deletePipeline,
};