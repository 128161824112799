import React, { useEffect, useState } from 'react'
import { Tree } from 'primereact/tree';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'primereact/skeleton';
import { DocumentLibraryAction } from 'features/document/store/actions/DocumentLibraryAction';
import { Message } from 'primereact/message';

const EstructuraCarpetas = ({setSelectedPath}) => {
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [isFirstLoading, setFirstLoading] = useState(true); 
  const [expandedKeys, setExpandedKeys] = useState({});
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const { isLoading , data } = useSelector(state => state.documentLibraryReducer.listFolders)
  const { isEnabled } = useSelector((state) => state.documentLibraryReducer.isStorageEnabled);

  useEffect(() => {
    if (isFirstLoading) {
      setFirstLoading(false);
      dispatch(DocumentLibraryAction.getDataListFolders());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pathDocument = (path) => {
    if (!path) return
    const pathSplit = path.split('/')
    pathSplit.shift();
    const newPath = pathSplit.join('/')
    return newPath
  }

  const onSelect = (event) => {
    if (event.node.canWrite === false) {
      setError('Esta carpeta no tiene permisos de escritura')
      setSelectedPath('error')
      return
    }
    setError(null)
    if (event.node.label === 'Todos') return setSelectedPath('')
    setSelectedPath(pathDocument(event.node.path))

  }

  const nodeTemplate = (node, options) => {
    let label = <p>{node.label}</p>;

    return (
        <span className={`${ node.canWrite=== false ? 'text-gray-300 cursor-not-allowed' : ''} ${options.className}`} >
            {label}
        </span>
    )
  }

  return (
    <div className='h-full flex flex-col justify-between'>
      <div className='w-full menu-document '>
        {
          isLoading ?(
            <Skeleton className='mt-3' height="8.2rem" />
          ) : (
            <Tree 
              className='px-0' value={data} selectionMode="single" selectionKeys={selectedNodeKey}
              onSelectionChange={e => setSelectedNodeKey(e.value)} onSelect={onSelect} 
              expandedKeys={expandedKeys} onToggle={e => setExpandedKeys(e.value)}
              disabled={isEnabled ? false : true } 
              nodeTemplate={nodeTemplate}
            />  
          )
        }
        
      </div>    
      {
        error && (
          <Message severity="warn" text={error} />
        )
      }       
    </div>
  )
}

export default EstructuraCarpetas