import { Chart } from 'primereact/chart';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ReactSpeedometer from "react-d3-speedometer"
import { Tag } from 'primereact/tag';
import moment from 'moment';
import { Skeleton } from 'primereact/skeleton';
import { CoreConstants } from 'core/commons/CoreConstants';


const IndicatorModitoringComponent = ({typeChart}) => {
    const {dataSets, labels} = useSelector(state => state.managmentReducer.dataGraphicsIndicator.data);
    // const { goalValue, maxGoalValue } = useSelector(state => state.managmentReducer.dataTableIndicator.data?);
    const goalValue = useSelector(state => state.managmentReducer.dataTableIndicator.data?.goalValue);
    const maxGoalValue = useSelector(state => state.managmentReducer.dataTableIndicator.data?.maxGoalValue);
    const [dateSelected, setDateSelected] = useState(0)

    const dataArray = dataSets ? dataSets.reduce((acc, cur) => {
        acc.push(cur.value)
        return acc
    }, []) : []

    const [barData, setBarData] = useState({
        labels: labels,
        datasets: [
            {
                label: 'Indicadores Bar',
                backgroundColor: ['#42A5F5', '#EC6A1D', '#ECC21D', '#B2EC1D', '#EC3C1D', '#1DECB4', '#9F1DEC', '#1D2AEC', '#1DEC48', '#EC1D4F', '#1DADEC', '#EC1DC6'],
                data: dataArray
            },
        ]
    });

    const [lineData, setLineData] = useState({
        labels: labels,
        datasets: [
            {
                label: 'Indicadores Line',
                backgroundColor: ['#EC3C1D'],
                data: dataArray,
                tension: 0,
                borderColor: '#EC3C1D'
            },
        ]
    });

    useEffect(() => {
        setBarData({
            labels: labels,
            datasets: [
                {
                    label: 'Indicadores Bar',
                    backgroundColor: ['#42A5F5', '#EC6A1D', '#ECC21D', '#B2EC1D', '#EC3C1D', '#1DECB4', '#9F1DEC', '#1D2AEC', '#1DEC48', '#EC1D4F', '#1DADEC', '#EC1DC6'],
                    data: dataArray
                },
            ]
        })
        setLineData({
            labels: labels,
            datasets: [
                {
                    label: 'Indicadores Line',
                    backgroundColor: ['#EC3C1D'],
                    data: dataArray,
                    tension: 0,
                    borderColor: '#EC3C1D'
                },
            ]
        })
    }, [dataSets, labels])
    

    const getLightTheme = () => {
        let barOptions = {
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                   /* TODO: Poner varios titulos legends por cada valor */
                    labels: {
                        color: '#495057',
                    },
                    display: false,
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            },
        };

        let lineOptions = { ...barOptions, aspectRatio: .8}
        let chartSelectedOptions = barOptions

        return {
            barOptions,
            lineOptions,
            chartSelectedOptions
        } 
    }

    const { barOptions, lineOptions, chartSelectedOptions } = getLightTheme();

    return (
        <>
            {
                //barras
                typeChart?.code === CoreConstants.Entity.DashbaordIndicatorModes.BAR && (
                    <div className="" style={{width: '100%'}}>
                        <Chart type="bar" data={barData} options={chartSelectedOptions} />
                    </div>
                 )
            }
            {
                //líneas
                typeChart?.code === CoreConstants.Entity.DashbaordIndicatorModes.LINE && (
                    <div className="" style={{width: '100%'}}>
                        <Chart type="line" data={lineData} options={chartSelectedOptions} />
                    </div>
                 )
            }
            {
                //medidor
                (typeChart?.code === CoreConstants.Entity.DashbaordIndicatorModes.METER) &&
                (
                    <div className="w-full flex flex-col mt-3 gap-y-5" style={{width: '100%'}}>
                        <div className='flex justify-evenly flex-wrap my-4' style={{color: 'white'}}>
                            {
                                dataSets ? dataSets.map(({value, valueDate}) => (
                                    <div className='transition ease-in-out bg-blue-500 hover:bg-blue-600 px-2 pt-1 rounded h-6 flex items-center' key={valueDate} onClick={() => setDateSelected(value)}>
                                        {/* <Tag value={moment(valueDate).format('L')} className='cursor-pointer text-white' ></Tag> */}
                                        <p className='cursor-pointer text-white text-xs font-bold h-full' >{moment(valueDate).format('L')}</p>
                                    </div>
                                )) : <Skeleton width='100%' />
                            }
                        </div>
                        <div className='w-full flex justify-center'>
                            <ReactSpeedometer
                                maxValue={maxGoalValue}
                                value={dateSelected}
                                segments={goalValue}
                                needleColor="black"
                                startColor="red"
                                endColor="green"
                                textColor="grey"
                                width={500}
                                currentValueText={'Indicador ${value}'}
                                // textColor={textColor}
                            />
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default IndicatorModitoringComponent