import { useEffect } from "react";
import { StudentsAction } from "features/customers/store/actions/StudentsAction";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { useDispatch } from "react-redux";

export const useStudents = () => {
  const { updateTitle } = useSetTitlePage();
  const dispatch = useDispatch();

  useEffect(() => {
    updateTitle({
      title: "Clientes",
      subtitle: "Alumnos",
    });
  }, []);

  const generateReport = () => {
    dispatch(StudentsAction.generateReport());
  };
  return { generateReport };
};
