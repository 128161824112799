import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import HeaderGroup from "features/riskManagement/components/RiskMatrix/HeaderGroup";
import InputCustom from "./InputCustom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useDispatch, useSelector } from "react-redux";
import { RiskMatrixAction } from "features/riskManagement/store/actions/RiskMatrixAction";
// import Resultado from "./Resultado";
import Formula from "./Formula";
// import Condicion from "./Condicion";
import "./MatrixComponent.scss";
import Expediente from "./Expediente";
import { RiskMatrixConstans } from "features/riskManagement/commons/RiskMatrixConstans";
import { useParams } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import { toggleSidebar } from "features/configuration/store/actions/ToggleSidebarAction";

const MatrixComponent = ({ table, mode, setMode }) => {
  const dispatch = useDispatch();
  const { id, results } = useSelector(
    (state) => state.riskMatrixReducer.matrix.data
  );
  const { isLoading } = useSelector((state) => state.riskMatrixReducer.matrix);
  const { isSaved, data } = useSelector((state) => state.riskMatrixReducer.matrix);
  // const dataValue = useSelector((state) => state.riskMatrixReducer.values);
  const dataValue = useRef({})

  const {
    data: dataLectura,
    initialValues,
    dataInitialModel,
    isLoadingData,
  } = useSelector((state) => state.riskMatrixReducer.data);

  const { model, columns } = useSelector(
    (state) => state.riskMatrixReducer.table
  );

  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmDialogDelete, setShowConfirmDialogDelete] = useState(false);

  const [filas, setFilas] = useState([]);

  const { id: idParam } = useParams();

  const {
    // register,
    setValue,
    handleSubmit,
    control,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ shouldUnregister: true });

  const values = watch();

  const setValuesForm = () => {
    Object.keys(initialValues).forEach((key) => {
      const keyAux = `${key.split("-")[0]}-${key.split("-")[1]}`;
      if (
        dataInitialModel[0][keyAux]?.code ===
          RiskMatrixConstans.Types.MatrixManagement.SELECCION_SIMPLE &&
        key.split("-")[1].startsWith("InventoryId")
      ) {
        if(initialValues[key]?.length > 0){
          setValue(key, initialValues[key][0]);
        }
      } else {
        setValue(key, initialValues[key]);
      }
    });
  }

  useEffect(()=>{
    setFilas(dataInitialModel);
    setValuesForm();
  },[dataInitialModel])

  useEffect(()=>{
    dispatch(toggleSidebar.toggle(true));
  },[idParam])

  const changeModeEdition = () => {
    setFilas(dataInitialModel);
    setValuesForm();
    setMode("edicion");
    dispatch(toggleSidebar.toggle(true));
  };

  const send = (data) => {
    const items = [];
    filas.forEach((item, index) => {
      const row = {};
      const valuesForResults = {};
      let total = 0;

      Object.keys(item).forEach((key) => {
        if (key.split("-")[1] && key.split("-")[1].startsWith("CriteriaId")) {
          valuesForResults[key.split("-")[1]] = Number(
            values[`${key}-row-${item.id}`]?.itemValue
          );
        }
      });

      row.id = item.id;
      row.rowNumber = index + 1;
      row.inventories = [];
      row.evaluations = [];
      Object.keys(item).forEach((element, key) => {
        if (element?.split("-")[1]?.startsWith("InventoryId")) {
          let inventory = {};

          if (
            item[element].code ===
            RiskMatrixConstans.Types.MatrixManagement.SELECCION_MULTIPLE
          ) {
            inventory = {
              id: item[element].idItem,
              inventoryId: item[element].id,
              columnNumber: item[element].column,
              valueIdList:
                data[`${element}-row-${item.id}`]?.map((opc) => opc.id) || null,
              valueText:
                data[`${element}-row-${item.id}`]?.map((opc) => opc.text) ||
                null,
            };
          } else if (
            item[element].code ===
            RiskMatrixConstans.Types.MatrixManagement.SELECCION_SIMPLE
          ) {
            inventory = {
              id: item[element].idItem,
              inventoryId: item[element].id,
              columnNumber: item[element].column,
              valueIdList: data[`${element}-row-${item.id}`]?.id ? [data[`${element}-row-${item.id}`]?.id] : null,
              valueText: data[`${element}-row-${item.id}`]?.id ? [data[`${element}-row-${item.id}`]?.text] : null,
            };
          } else {
            inventory = {
              id: item[element].idItem,
              inventoryId: item[element].id,
              columnNumber: item[element].column,
              valueIdList: null,
              valueText: [data[`${element}-row-${item.id}`]],
            };
          }

          row.inventories.push(inventory);
        }

        if (element?.split("-")[1]?.startsWith("CriteriaId")) {
          let evaluation = row.evaluations.find(
            (eva) => eva?.evaluationId === item[element]?.evaluationId
          );
          if (!evaluation) {
            row.evaluations.push({
              evaluationId: item[element]?.evaluationId,
              criteria: [],
            });
            evaluation = row.evaluations.find(
              (eva) => eva?.evaluationId === item[element]?.evaluationId
            );
          }

          const criteriaItem = {
            id: item[element].idItem,
            criteriaId: item[element].id,
            columnNumber: item[element].column,
            valueId: data[`${element}-row-${item.id}`]?.id,
            valueText: data[`${element}-row-${item.id}`]?.text,
          };

          evaluation?.criteria?.push(criteriaItem);
        }

        if (element?.split("-")[1]?.startsWith("ProbabilityId")) {
          let evaluation = row.evaluations.find(
            (eva) => eva?.evaluationId === item[element]?.evaluationId
          );
          if (!evaluation) {
            row.evaluations.push({
              evaluationId: item[element]?.evaluationId,
            });
            evaluation = row.evaluations.find(
              (eva) => eva?.evaluationId === item[element]?.evaluationId
            );
          }
          const probability = {
            id: item[element].idItem,
            valueId: data[`${element}-row-${item.id}`]?.id,
            valueText: data[`${element}-row-${item.id}`]?.text,
            columnNumber: item[element].column,
            sourceValue: data[`${element}-row-${item.id}`]?.itemValue,
          };

          evaluation.probability = probability
        }

        if (element?.split("-")[1]?.startsWith("ImpactId")) {
          let evaluation = row.evaluations.find(
            (eva) => eva?.evaluationId === item[element]?.evaluationId
          );
          if (!evaluation) {
            row.evaluations.push({
              evaluationId: item[element]?.evaluationId,
            });
            evaluation = row.evaluations.find(
              (eva) => eva?.evaluationId === item[element]?.evaluationId
            );
          }

          const impact = {
            id: item[element].idItem,
            valueId: data[`${element}-row-${item.id}`]?.id,
            valueText: data[`${element}-row-${item.id}`]?.text,
            columnNumber: item[element].column,
            sourceValue: data[`${element}-row-${item.id}`]?.itemValue,
          };

          evaluation.impact = impact
        }

        if (element?.split("-")[1]?.startsWith("LevelId")) {
          let evaluation = row.evaluations.find(
            (eva) => eva?.evaluationId === item[element]?.evaluationId
          );

          if (!evaluation) {
            row.evaluations.push({
              evaluationId: item[element]?.evaluationId,
            });
            evaluation = row.evaluations.find(
              (eva) => eva?.evaluationId === item[element]?.evaluationId
            );
          }

          const process = {
            id: item[element].idItem,
            valueId: data[`${element}-row-${item.id}`]?.Id,
            valueText: data[`${element}-row-${item.id}`]?.Text,
            columnNumber: item[element].column,
          };

          if( process.valueId ){
            evaluation.process = process;
          }
        }

        if (element?.split("-")[1]?.startsWith("formula")) {
          let colorCode
          if( dataValue.current[`${element}-row-${item.id}`]?.colorCode){
            colorCode =  dataValue.current[`${element}-row-${item.id}`]?.colorCode
          } else {
            colorCode = dataValue.current[`${element}-row-${item.id}`]?.condition?.colorCode
          }
          let result
          if(dataValue.current[`${element}-row-${item.id}`]?.value){
            result = dataValue.current[`${element}-row-${item.id}`]?.value

          } else {
            result = dataValue.current[`${element}-row-${item.id}`]?.operationResult
          }

          const evaluation = row.evaluations.find(
            (evalu) => evalu.evaluationId === item[element].evaluationId
          ) || {};

          evaluation.result = {
            id: item[element].idItem,
            columnNumber: item[element].column,
            resultValue: isNaN(result) ? 0 : result,
            colorCode: colorCode ?? null,
          };

          if(dataValue.current[`${element}-row-${item.id}`].levelId){
            evaluation.level = {
              columnNumber: item[element].column,
              id: item[element].idItem,
              valueId: dataValue.current[`${element}-row-${item.id}`].levelId,
              valueText: dataValue.current[`${element}-row-${item.id}`].levelName,
            }
          }
          if(dataValue.current[`${element}-row-${item.id}`]?.condition){
            evaluation.conditions = [
              {
                conditionId: dataValue.current[`${element}-row-${item.id}`]?.condition?.id,
                id: item[element].idItem,
                valueId: dataValue.current[`${element}-row-${item.id}`]?.condition?.id,
                valueText: dataValue.current[`${element}-row-${item.id}`]?.condition?.name,
              }
            ]
          }
        }
      });
      items.push(row);
    });

    const send = {
      modelId: id,
      items,
    };

    console.log(send)

    dispatch(RiskMatrixAction.saveMatrix(send, idParam, setMode));
  };

  const agregar = () => {
    setFilas((state) => {
      const newId = Math.min(...state.map((item) => item.id), 0) - 1;
      return state.concat([
        {
          id: newId,
          ...model,
        },
      ]);
    });
  };

  const eliminar = () => {
    const selectedId = selectedCustomers.map((item) => item.id);
    const newFilas = filas.filter((item) => !selectedId.includes(item.id));
    setSelectedCustomers(null);
    setFilas(newFilas);
  };

  const cancelar = () => {
    dispatch(RiskMatrixAction.getMatrixStructure(idParam));

    setMode("lectura");
  };

  return (
    <div>
      {mode === "lectura" ? (
        <div className="m-4 flex gap-4">
          <Button
            label="Editar"
            loading={isLoading || isLoadingData ||!isSaved}
            icon="pi pi-pencil"
            className="sig-button sig-dark"
            onClick={changeModeEdition}
          />
        </div>
      ) : (
        <div className="m-4 flex gap-4">
          <Button
            label="Añadir Riesgo"
            loading={!isSaved}
            icon="pi pi-plus"
            className="sig-button sig-dark"
            onClick={agregar}
          />
          <Button
            label="Eliminar Riesgo"
            loading={!isSaved}
            icon="pi pi-trash"
            className="sig-button sig-dark"
            disabled={selectedCustomers?.length === 0 || !selectedCustomers}
            onClick={() => setShowConfirmDialogDelete(true)}
          />
        </div>
      )}
      <div>
      {
        (isLoadingData || isLoading) ? 
        <Skeleton height="10rem"/>
        :
        <DataTable
        selection={selectedCustomers}
        onSelectionChange={(e) => setSelectedCustomers(e.value)}
        id="riskTableMatriz"
        dataKey="id"
        headerColumnGroup={HeaderGroup({ data, getValues })}
        value={mode === "lectura" ? dataLectura : filas}
        emptyMessage="No se encontró Riesgos"
        stripedRows
        selectionMode={mode === "edicion" && "checkbox"}
        rowHover
        size="small"
        responsiveLayout="scroll"
        selectionAutoFocus={false}
        cellClassName={(data) => {
          const condition =
            (data ? String(data[0]).startsWith("#") : false) ||
            Array.isArray(data);
          return { condicion: condition };
        }}
      >
        {mode === "edicion" ? (
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3em" }}
          ></Column>
        ) : (
          <Column headerStyle={{ width: "3em" }}></Column>
        )}

        {columns.map((item, index) => {
          if (mode === "lectura")
            return (
              <Column
                key={item.field}
                field={item.field}
                body={(rowData) => {
                  if (item.field.split("-")[1] === "formula") {
                    return (
                      <div
                        className='font-bold grid place-content-center'
                        style={{
                          background: rowData[item.field][1],
                        }}
                      >
                        {rowData[item.field][0]}
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        {rowData[item.field]?.map((el) => (
                          <div key={el}>
                            {el}
                          </div>
                        ))}
                      </div>
                    );
                  }
                }}
              />
            );
          else {
            if (item.field.split("-")[1] === "formula") {
              return (
                <Column
                  key={item.field}
                  field={item.field}
                  body={(rowData) => (
                    <Formula
                      rowData={rowData}
                      control={control}
                      column={item}
                      getValues={getValues()}
                      dataValue={dataValue}
                    />
                  )}
                />
              );
            } else if (item.field.split("-")[1] === "expediente") {
              return (
                <Column
                  key={item.field}
                  field={item.field}
                  body={(rowData) => (
                    <Expediente
                      rowData={rowData}
                      control={control}
                      column={item}
                      getValues={getValues}
                    />
                  )}
                />
              );
            } else if (item.field.split("-")[1].startsWith("LevelId")) {
              return (
                <Column
                  key={item.field}
                  field={item.field}
                  body={(rowData) => (
                    <Expediente
                      rowData={rowData}
                      control={control}
                      column={item}
                      getValues={getValues()}
                      setValue={setValue}
                    />
                  )}
                />
              );
            } else {
              return (
                <Column
                  key={item.field}
                  field={item.field}
                  body={(rowData) => (
                    <InputCustom
                      rowData={rowData}
                      control={control}
                      column={item}
                      getValues={getValues}
                    />
                  )}
                />
              );
            }
          }
        })}
        </DataTable>
      }
      </div>
      {mode === "edicion" && (
        <div className="m-4 flex justify-end gap-4">
          <Button
            label="Cancelar"
            loading={!isSaved}
            icon="pi pi-times"
            className="sig-button sig-secondary"
            onClick={() => {
              setShowConfirmDialog(true);
            }}
          />
          <Button
            label="Guardar"
            loading={!isSaved}
            icon="pi pi-save"
            className="sig-button sig-primary"
            onClick={handleSubmit(send)}
          />
        </div>
      )}
      <ConfirmDialog
        visible={showConfirmDialog}
        onHide={() => setShowConfirmDialog(false)}
        message="¿Está seguro que desea cancelar?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={cancelar}
        reject={() => setShowConfirmDialog(false)}
      />

      <ConfirmDialog
        visible={showConfirmDialogDelete}
        onHide={() => setShowConfirmDialogDelete(false)}
        message="¿Está seguro que desea eliminar los riesgos?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={eliminar}
        reject={() => setShowConfirmDialogDelete(false)}
      />
    </div>
  );
};

export default MatrixComponent;
