import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HashRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Provider } from "react-redux";

import PrivateLayout from "shared/components/private-layout/PrivateLayout";
import ConfiguracionRouting from "./configuration/ConfigurationRouting";
import PublicoRouting from "./public/PublicRouting";
import RequireAuth from "../core/routes/RequiredAuth";
import MaintenanceRouting from "./maintenance/MaintenanceRouting";
import configureStore from "core/store/ConfigStore";
import ReducerRegistry from "core/store/RegisterReducer";
import authReducer from "../core/store/reducers/AuthReducer";
import attachmentsReducer from "core/store/reducers/AttachmentsReducer";
import breadcrumpReducer from "../core/store/reducers/BreadcrumpReducer";
import toastReducer from "./../core/store/reducers/ToastReducer";
import FeedBackReducer from "./../core/store/reducers/FeedBackReducer";
import toggleSidebarReducer from "./configuration/store/reducers/ToggleSidebarReducer";
import dinamicProcessReducer from "./configuration/store/reducers/DinamicProcessReducer";

import RrhhRouting from './rrhh/RrhhRouting';
import PageHomePage from 'shared/pages/home/PageHomePage';
import NewPasswordPage from './public/pages/new-password/NewPasswordPage';
import ActivateAccountPage from './public/pages/activate-account/ActivateAccountPage';
import { CustomersRouting } from './customers/CustomersRouting';
import SubscriptionRouting from './subscription/SubscriptionRouting';
import ProcessMapRouting from "./processManagement/ProcessMapRouting";
import { ProjectsRouting } from './projects/ProjectsRouting';
import { BusinessRouting } from './business/BusinessRouting';
import CoursesManagmentRouting from "./coursesManagment/CoursesManagmentRouting";
import DocumentRouting from './document/DocumentRouting';
import IndicatorsRouting from './indicators/IndicatorsRouting';
import SurveyRouting from "./survey/SurveyRouting";
import SurveyPublic from "./survey/pages/Survey/Survey/SurveyPublic";
import surveyPublicReducer from "./public/store/reducers/SurveyPublicReducer";
import RiskManagementRouting from "./riskManagement/RiskManagementRouting";
import DinamicProcessViewPage from "./configuration/pages/dinamicProcess/dinamicProcessView/DinamicProcessViewPage";
import DinamicProcessFormPage from "./configuration/pages/dinamicProcess/dinamicProcessView/DinamicProcessFormPage";
import RegistrationFormReducer from "./public/store/reducers/RegistrationFormReducer";
import { PageRegistrationForm } from "./registrationForm/pages/PageRegistrationForm";
import { MaintenanceModePage } from "./system/pages/MaintenanceModePage";
import surveyReducer from './survey/store/reducers/SurveyReducer'
import filterPaginationReducer from "shared/store/reducers/FilterPaginationReducer";
import { PageBusinessSheetPublic } from "./business/pages/businessSheet/PageBusinessSheetPublic";
import businessSheetPublicReducer from "./business/store/reducers/BusinessSheetPublicReducer";
import tasksReducer from "features/customers/store/reducers/TasksReducer";
import { appReducer } from "shared/store/reducers/AppReducer";

export const AppRouting = () => {
  var reducerRegistry = new ReducerRegistry({
    authReducer,
    toastReducer,
    breadcrumpReducer,
    toggleSidebarReducer,
    FeedBackReducer,
    attachmentsReducer,
    surveyPublicReducer,
    surveyReducer,
    dinamicProcessReducer,
    RegistrationFormReducer,
    filterPaginationReducer,
    businessSheetPublicReducer,
    tasksReducer,
    appReducer,
  });

  var store = configureStore(reducerRegistry);

  return (
    <>
      <Provider store={store}>
        <Router>
          <HashRouter>
  {/*       
  <MaintenanceModePage/> 
  */}
            <Switch>
              <Route
                path={`/seguridad`}
                component={() => PublicoRouting(reducerRegistry)}
              />
              <Route
                exact
                path={`/recuperar-contrasena/:token`}
                component={NewPasswordPage}
              />
              <Route
                exact
                path={`/confirmar-cuenta/:token`}
                component={ActivateAccountPage}
              />

              <Route
                exact
                path={`/`}
                component={() => PublicoRouting(reducerRegistry)}
              />

              <Route
                path={`/encuestas/encuesta/r/:id`}
                exact
                // component={() => <SurveyPreviewPage title="Encuesta Publica" />}
                component={() => <SurveyPublic title="Encuesta Publica" />}
              />

              <Route
                path={`/negocios/public/ficha/:id`}
                exact
                // component={() => <SurveyPreviewPage title="Encuesta Publica" />}
                component={() => <PageBusinessSheetPublic />}
              />


              <Route
                path={`/curso/registro`}
                // component={() => <SurveyPreviewPage title="Encuesta Publica" />}
                component={() => <PageRegistrationForm />}
              />

              <PrivateLayout>
                <RequireAuth path="/inicio">
                  <Route path={`/inicio`} component={() =>PageHomePage(reducerRegistry)} />
                </RequireAuth>
                <RequireAuth path="/configuracion">
                  <Route
                    path={`/configuracion`}
                    component={() => ConfiguracionRouting(reducerRegistry)}
                  />
                </RequireAuth>
                <RequireAuth path="/mapa-procesos">
                  <Route
                    path={`/mapa-procesos`}
                    component={() => ProcessMapRouting(reducerRegistry)}
                  />
                </RequireAuth>
                <RequireAuth path="/rrhh">
                  <Route
                    path={`/rrhh`}
                    component={() => RrhhRouting(reducerRegistry)}
                  />
                </RequireAuth>
                <RequireAuth path="/mantenimiento">
                  <Route
                    path={`/mantenimiento`}
                    component={() => MaintenanceRouting(reducerRegistry)}
                  />
                </RequireAuth>
                <RequireAuth path="/cliente">
                  <Route
                    path={`/cliente`}
                    component={() => CustomersRouting(reducerRegistry)}
                  />
                </RequireAuth>
                <RequireAuth path="/negocios">
                  <Route
                    path={`/negocios`}
                    component={() => BusinessRouting(reducerRegistry)}
                  />
                </RequireAuth>
                <RequireAuth path="/suscripcion">
                  <Route
                    path={`/suscripcion`}
                    component={() => SubscriptionRouting(reducerRegistry)}
                  />
                </RequireAuth>
                <RequireAuth path="/proyecto">
                  <Route
                    path={`/proyecto`}
                    component={() => ProjectsRouting(reducerRegistry)}
                  />
                </RequireAuth>
                <RequireAuth path="/curso">
                  <Route
                    path={`/curso`}
                    component={() => CoursesManagmentRouting(reducerRegistry)}
                  />
                </RequireAuth>
                <RequireAuth path="/documentos">
                  <Route
                    path={`/documentos`}
                    component={() => DocumentRouting(reducerRegistry)}
                  />
                </RequireAuth>
                <RequireAuth path="/indicadores">
                  <Route
                    path={`/indicadores`}
                    component={() => IndicatorsRouting(reducerRegistry)}
                  />
                </RequireAuth>
                <RequireAuth path="/encuestas">
                  <Route
                    path={`/encuestas`}
                    component={() => SurveyRouting(reducerRegistry)}
                  />
                </RequireAuth>
                <RequireAuth path="/gestion-riesgos">
                  <Route
                    path={`/gestion-riesgos`}
                    component={() => RiskManagementRouting(reducerRegistry)}
                  />
                </RequireAuth>
                <RequireAuth path="/matriz-riesgo">
                  <Route
                    path={`/matriz-riesgo`}
                    component={() => RiskManagementRouting(reducerRegistry)}
                  />
                </RequireAuth>
                <RequireAuth path="/proceso-dinamico">
                  <Switch>
                    <Route
                      exact
                      path={`/proceso-dinamico/:id`}
                      component={()=> <DinamicProcessViewPage title="Proceso Dinámico" />}
                    />
                    <Route
                      exact
                      path={`/proceso-dinamico/:id/nuevo`}
                      component={()=> <DinamicProcessFormPage title="Nuevo" />}
                    />
                    <Route
                      exact
                      path={`/proceso-dinamico/:id/:idrow`}
                      component={()=> <DinamicProcessFormPage title="Editar" />}
                    />
                  </Switch>
                </RequireAuth>
              </PrivateLayout>
            </Switch>
          </HashRouter>
        </Router>
      </Provider>
    </>
  );
};
