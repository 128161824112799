import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { RiskMatrixAction } from 'features/riskManagement/store/actions/RiskMatrixAction';
import { RiskEvaluationServices } from 'features/riskManagement/services/RiskEvaluationServices';

const ExpedienteEstandar = ({rowData, getValues, column, control, setValue}) => {
	const dispatch = useDispatch()
  const [existeExpediente, setExisteExpediente] = useState(false)
	const [options, setOptions] = useState([])
  const [result, setResult] = useState()
  const [loading, setLoading] = useState(false)

	const obtenerValor = useCallback(() => {
		const data = {}
    Object.keys(rowData).forEach( key => {
      if(key.split('-')[1]){
        data[key.split('-')[1]] = Number(getValues[`${key}-row-${rowData.id}`]?.itemValue)
      }
    })

		const decimalPositions = rowData[column.field]?.decimalPositions;		

		const formu = rowData[column.field]?.formula.split(' ').map( column => {
			
			if( column.startsWith('[CriteriaId') || column.startsWith('[ProbabilityId')|| column.startsWith('[ImpactId'))
			 return `data['${column.replace('[','').replace(']','').replace(':','_')}']`
			return	column.replace('[','').replace(']','').replace(':','_')
		}).join(' ')

		let result = {value: Number(eval(formu))}

    result.value = isNaN(result.value) ? 0 : Number(result.value.toFixed(decimalPositions));

    const conditions = rowData[column.field]?.condition
    conditions?.forEach((condition) => {
      if( condition.from !== null && condition.to !== null){
        if( condition.from < result.value && result.value < condition.to){
          result.condition = condition
        }
      } else if(condition.from !== null){
        if( condition.from < result.value){
          result.condition = condition
        }
      } else if(condition.to !== null){
        if(result.value < condition.to){
          result.condition = condition
        }
      } else if( condition.values?.length > 0 ){
        if(condition.values.includes(result.value)){
          result.condition = condition
        }
      }else{
        result.condition = {}
      }
    })

    // dispatch(RiskMatrixAction.setValues({[`${column.field}-${rowData.id}`]: result}))
    setResult(result)
    if(result?.condition?.showFileRecord){
      setExisteExpediente(result?.condition?.showFileRecord)
    } else {
      setExisteExpediente(false)
    }
	},[getValues])

	useEffect(() => {
		obtenerValor()
	},[obtenerValor])

  useEffect(()=>{
    if(existeExpediente){
      const id = result?.condition?.dynamicProcessId
      setLoading(true)
      RiskEvaluationServices.getDynamicProcessDataById(id).then(({data})=>{
        setOptions(data.data)
        setLoading(false)
      })
    }
  },[existeExpediente, result?.value])
  if(existeExpediente){
    return(
    <div
      // style={{maxWidth: "8rem"}}
    >
      {
        loading ? <Skeleton height='35px' width='8rem'/> :
        <Controller
          control={control}
          name={`${column.field}-row-${rowData.id}`}
          render={({ field: { onChange, value } }) => {
            return (
              <Dropdown
                filter
                options={options}
                optionLabel="Text"
                value={value}
                onChange={onChange}
              />
            );
          }}
        />
      }
    </div>
    )
  }
  return (
    <div
      // style={{height: "30px", background: "#BBB"}}
    >
      
    </div>
  );
};

export default ExpedienteEstandar;