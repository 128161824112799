import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";

export const DroppableCustom = (props) => {

	const onDragOver = (e) => {
    e.preventDefault()
  }

	

	return(
		<Panel 
			header={props.isDownload ? `${props.item?.name}` :
				<form onSubmit={(e)=> props.changeName(e,props.item)}>
					<InputText 
						disabled={!props.isLastVersion}
						name="name"
						defaultValue={props.item?.name}
						className={`bg-transparent border-0 input-title`}
						onBlur={(e)=> props.changeNameBlur(e,props.item)}
					/>
				</form>} 
			toggleable 
			className="p-0 panel-process-map" 
		>
			<div 
				className='flex flex-wrap justify-start items-center w-auto pb-4'
				dropid = {props.dropid}
				onDragOver={onDragOver} 
				onDrop={props.onDrop}
		>
			{props.children}
			</div>
		</Panel>)
}