import { useDispatch, useSelector } from 'react-redux';
import { ConfigurationConstants } from 'features/configuration/commons/ConfigurationConstants';
import { UsersAction } from 'features/configuration/store/actions/UsersAction';
import { useForm } from 'shared/hooks/useForm';

const useFilter = () => {
  const dispatch = useDispatch();
  const usersLoading = useSelector((state) => state.userReducer.users.loading);
  const [formValues, handleInputChange, reset] = useForm({ fullName: null });

  const toggleModal = (isOpen) => {
    dispatch({
      type: ConfigurationConstants.Accion.Users.CHANGE_FILTER_VALUES,
      payload: { showModal: isOpen },
    });
  };

  const removePiner = (field) => {
    const fieldsReset = { fullName: null };
    if (field === 'fullName') {
      reset(fieldsReset);
    }
    dispatch(UsersAction.removeFilterValues(field));
  };

  const usersSearch = (e) => {
    e.preventDefault();
    if (formValues.fullName) {
      dispatch(UsersAction.usersSearch(formValues));
    }
  };

  return {
    toggleModal,
    usersLoading,
    removePiner,
    formValues,
    handleInputChange,
    usersSearch,
  };
};

export default useFilter;
