import { apiService } from "core/services/ApiService";

const getDataMaster = () => {
  return apiService.Post("customer/api/CoursePublic/GetDataMaster")
}

const getCourseRegistrationForm = (token) => {
  return apiService.Get(`customer/api/CoursePublic/GetCourseRegistrationForm?token=${token}`)
}

const submitRegistrationForm = (data) => {
  return apiService.Post("customer/api/CoursePublic/SubmitRegistrationForm", data)
}

const getLogoAndColorSchema = (token) => {
  return apiService.Get(`customer/api/CoursePublic/GetLogoAndColorSchema?guid=${token}`)
}

export const RegistrationFormService = {
  getDataMaster,
  getCourseRegistrationForm,
  submitRegistrationForm,
  getLogoAndColorSchema,
};