import { useClaimStudents } from "features/customers/hooks/useClaimStudents";
import { Link } from "react-router-dom";

const TableCard = ({
  id,
  key,
  fullName,
  documentType,
  documentNumber,
  businessLine,
  country,
  emailAddress,
  phoneNumber,
  selectedRow,
}) => {
  const validateValue = (val) => {
    if (val === "-") return "";
    else return val;
  };
  const { isView, isEdit, isDelete } = useClaimStudents();
  return (
    <div
      key={`card-${key}`}
      className="block p-4 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
    >
      <div className="flex justify-between">
        {
          isView?(
            <Link className="underline text-blue-700" to={{ pathname: `/cliente/alumnos/editar/` + id }} ><h5>{fullName}</h5></Link>
          ):(
            <h5 className="mb-1 text-md font-bold text-gray-900 dark:text-white">
              {fullName}
            </h5>
          )
        }
        {
          (isEdit||isDelete)&&(
            <i
              className="pi pi-ellipsis-v cursor-pointer"
              onClick={(event) => selectedRow(event, { id })}
            ></i>
          )
        }
      </div>
      <hr />
      <div className="grid grid-cols-2 mt-3 text-gray-700 dark:text-gray-400">
        <div>
          <p className="text-xs">{documentType}:</p>
          <p className="text-xs">{validateValue(documentNumber)}</p>
        </div>
        <div>
          <p className="text-xs">Línea de Negocios:</p>
          <p className="text-xs">{validateValue(businessLine)}</p>
        </div>
      </div>
      <div className="grid text-gray-700 dark:text-gray-400 mt-3">
        <div>
          <p className="text-xs">País:</p>
          <p className="text-xs">{validateValue(country)}</p>
        </div>
      </div>
      <div className="grid grid-cols-2 text-gray-700 dark:text-gray-400 mt-3">
        <div>
          <p className="text-xs">Teléfono:</p>
          <p className="text-xs">
            <i className="pi pi-phone text-xs mr-1"></i>
            {validateValue(phoneNumber)}
          </p>
        </div>
        <div>
          <p className="text-xs">Correo:</p>
          <p className="text-xs break-all pr-1">
            {validateValue(emailAddress)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TableCard;
