import { BusinessSheetPublicConstants } from "features/business/commons/BusinessSheetPublicConstants";

const initialState = {
  businessSheet: {
    businessSheet: {},
    loading: true,
    error: false,
  },
  save: {
    isLoadingSave: false,
    success: false,
    error: false
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case BusinessSheetPublicConstants.Action.BusinessSheetPublic.BUSINESS_SHEET:
      return {
        ...state,
        businessSheet: {
          ...state.businessSheet,
          ...action.payload,
        },
      };
    
    case BusinessSheetPublicConstants.Action.BusinessSheetPublic.SAVE:
      return {
        ...state,
        save: {
          ...state.save,
          ...action.payload,
        },
      };
      
    default:
      return state;
  }
} 