/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Message from "shared/components/messages/Message";
import { FieldsFormDynamicField } from "./FieldsFormDynamicField";
import { PreViewField } from "./PreViewField";
import { DynamicFieldsConstants } from "features/configuration/commons/DynamicFieldsConstants";

export const FormDynamicField = ({selectId, setDataTable, setIsOpen, dataTable}) => {
  const dataMaster = useSelector(
    (state) => state.dynamicFieldsReducer.dataMaster.data
  );
  const statusEnable = dataMaster.status.find( ({code}) => code === DynamicFieldsConstants.Action.Status.ENABLE)
  const statusDisable = dataMaster.status.find( ({code}) => code === DynamicFieldsConstants.Action.Status.DISABLE)
  const { control: controlField, handleSubmit: handleSubmitField, setValue: setValueField , formState: { errors: errorsField }, watch: watchField } = useForm({shouldUnregister: true})
  
  const [ showModalCancelField, setShowModalCancelField] = useState(false)
  const cancelAcceptField = () => setIsOpen(false)
  const cancelDeniedField = () => setShowModalCancelField(false)
  const errorsArrayField = [];

  const submitField = (data) => {
    const id = Number(data.id)
    const fieldData = {
      id: Number(id),
      typeId: data.typeId,
      label: data.label,
      sectionId: data.sectionId,
      maxLength: data.maxLength,
      statusId: data.enable ? statusEnable?.id : statusDisable?.id,
      isRequired: data.isRequired ? true : false,
    }

    if( id <= 0){
      setDataTable( prevData => ([
        ...prevData,
        fieldData,
      ]))
    } else {
      setDataTable( prevData => prevData.map( item => {
        if(item.id === id){
          return fieldData
        }

        return item
      }))
    }


    setIsOpen(false)
  }

  useEffect(() => {
    setValueField("id", selectId)
    const item = dataTable.find( ({ id}) => id === selectId)
    console.log(item)
    if(item){
      setValueField("enable", statusEnable.id === item.statusId)
      setValueField("maxLength", item.maxLength)
      setValueField("label", item.label)
      setValueField("isRequired", item.isRequired)
      setValueField("sectionId", item.sectionId)
      setValueField("typeId", item.typeId)
    }
  }, [])

  return(
    <form className="w-full" onSubmit={handleSubmitField(submitField)}>
      
      <FieldsFormDynamicField controlField={controlField} dataTable={dataTable} watchField={watchField} />

      <div className="mt-4" style={{fontSize: 14}}>
        {
          selectId > 0 &&
          <div className="mb-2">
            <strong>Identificador: </strong>{dataTable.find( ({ id}) => id === selectId)?.identifier}
          </div>
        }
        <h1 className="mb-2">
          Vista previa
        </h1>

        <PreViewField watchField={watchField}/>

      </div>

      {Object.entries(errorsField).forEach((key, value) => {
        errorsArrayField.push(key[1].message);
      })}
      {errorsArrayField.length > 0 && (
        <Message messages={errorsArrayField} status="error" />
      )}

      <div className="flex justify-end gap-4 mt-3">
        <Button
          icon="pi pi-times"
          type="button"
          label="Cancelar"
          // disabled={isLoadingCompany || isLoadingDataMaster}
          onClick={() => setShowModalCancelField(true)}
          className="sig-button  sig-secondary mt-4"
        />
        <Button
          icon="pi pi-save"
          type="submit"
          label="Aceptar"
          // loading={isLoadingCompany}
          className="sig-button sig-primary mt-4"
        />
      </div>

      <ConfirmDialog
        visible={showModalCancelField}
        onHide={() => setShowModalCancelField(false)}
        message="¿Está seguro que desea cancelar?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={cancelAcceptField}
        reject={cancelDeniedField}
      />
    </form>
  )
}