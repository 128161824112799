import { CoursesManagmentConstants } from "features/coursesManagment/commons/CoursesManagmentConstants";

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  dataMaster: {
    frequencyDays: [],
    frecuencyHours: [],
    isLoading: false,
  },
  schedule: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false,
  },
  schedules: {
    isLoading: false,
    data: null,
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: "name",
    order: "asc",
    page: 1,
    pageSize: 10,
    filter: {},
  },

  // editSchedule: {
  //   isLoading: false,
  //   isLoadingSchedule: false,
  //   data: {},
  //   /*  0 - success   */
  //   status: null,
  //   errors: [],
  // },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CoursesManagmentConstants.Accion.Schedule.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload,
        },
      };

    case CoursesManagmentConstants.Accion.Schedule.UPDATE_FILTER:
      return {
        ...state,
        advancedFilter: state.advancedFilter,
      };

    case CoursesManagmentConstants.Accion.Schedule.SEARCH_SCHEDULES:
      return {
        ...state,
        schedules: {
          isLoading: action.payload.isLoading,
          data: action.payload.data,
        },
      };

    case CoursesManagmentConstants.Accion.Schedule.GET_SCHEDULE_BY_ID:
      return {
        ...state,
        schedule: {
          data: action.payload.data,
          isSaving: false,
          isLoading: action.payload.isLoading,
        },
      };

    case CoursesManagmentConstants.Accion.Schedule.SAVE_SCHEDULE:
      return {
        ...state,
        schedule: {
          data: action.payload.currentSchedule,
          isSaving: action.payload.isSaving,
          isSaved: action.payload.isSaved,
        },
      };

    // case CoursesManagmentConstants.Accion.Schedule.EDIT_SCHEDULE:
    //   return {
    //     ...state,
    //     editSchedule: {
    //       ...state.editSchedule,
    //       ...action.payload,
    //     },
    //   };
    //borre el case :delete

    case CoursesManagmentConstants.Accion.Schedule.RESET_AFTER_SAVED:
      return {
        ...state,
        schedule: {
          data: null,
          isSaving: false,
          isSaved: false,
          isLoading: false,
          isDeleted: false,
        },
      };

    case CoursesManagmentConstants.Accion.Schedule.GET_DATA_MASTER:
      return {
        ...state,
        dataMaster: {
          ...state.dataMaster,
          ...action.payload,
        },
      };
    //preguntar
    case CoursesManagmentConstants.Accion.Schedule.DOWNLOAD_REPORT_STATUS:
      return {
        ...state,
        schedule: {
          ...state,
          isLoading: action.payload.isLoading,
        },
      };
    default:
      return state;
  }
};
