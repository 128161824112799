import { Fragment } from 'react';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useAdvancedFilterBusiness } from 'features/customers/hooks/business/useAdvancedFilterBusiness';
import PinerComponent from 'shared/components/Piner/PinerComponent';

export const FilterComponent = () => {
  const {
    onToggleModal,
    businessLoading,
    reportLoading,
    showPiners,
    piners,
    removePiner,
    onAdvancedFilter,
    formValues,
    handleChange,
    generateReport
  } = useAdvancedFilterBusiness();

  return (
    <Fragment>
      <Panel header="FILTRO POR" toggleable>
        <form className="filter-roles" onSubmit={onAdvancedFilter}>
          <div className="w-full">
            <span className="p-float-label p-input-icon-left w-full">
              <i className="pi pi-search" />
              <InputText
                id="input-search"
                name="name"
                value={formValues?.name ? formValues?.name : ''}
                onChange={handleChange}
              />
              <label htmlFor="input-search">Buscar por nombre del negocio</label>
            </span>
            {showPiners && (
              <div className="filter-piners mt-3">
                {piners.map(({ value, field }, index) => (
                  <PinerComponent
                    name={value}
                    field={field}
                    removePiner={removePiner}
                    key={index}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="filter-users-action">
            <div className="flex gap-3 w-full">
              <Button
                icon="pi pi-search"
                type="submit"
                label="Buscar"
                loading={businessLoading || reportLoading}
                className="sig-button sig-primary w-full"
              />
              <Button
                icon="pi pi-download"
                type="button"
                onClick={generateReport}
                loading={reportLoading}
                disabled={businessLoading || reportLoading}
                label="Descargar"
                className="sig-button sig-success w-full"
              />
            </div>
            <div className="flex mt-3">
              <Button
                type="button"
                label="Búsqueda avanzada"
                disabled={businessLoading || reportLoading}
                className="sig-button  sig-secondary w-full"
                onClick={() => onToggleModal(true)}
              />
            </div>
          </div>
        </form>
      </Panel>
    </Fragment>
  );
};
