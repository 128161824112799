import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button';
import { Tree } from 'primereact/tree';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';
import './SidebarMenu.scss'
import { useHistory } from 'react-router-dom';
import { SurveyAction } from 'features/survey/store/actions/SurveyAction';

const dataExtra = [
  {
      "key": "100",
      "label": "Recientes",
      "data": "Documents Folder",
      "icon": "pi pi-fw pi-clock",
      "children": [],
  },
  {
      "key": "101",
      "label": "Vigentes",
      "data": "Documents Folder",
      "icon": "pi pi-fw pi-exclamation-circle",
      "children": [],
  },
  {
      "key": "102",
      "label": "Mis Encuestas",
      "data": "Documents Folder",
      "icon": "pi pi-fw pi-star",
      "children": [],
  },
  // {
  //     "key": "103",
  //     "label": "Papelera",
  //     "data": "Documents Folder",
  //     "icon": "pi pi-fw pi-trash",
  //     "children": [],
  // },
]

const excludedKeys = ['', '0', '100', '101', '102']

const SidebarMenu = ({setSelectedNode, selectedNode}) => {
  const history = useHistory()
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [isFirstLoading, setFirstLoading] = useState(true); 
  const [expandedKeys, setExpandedKeys] = useState({});
  const dispatch = useDispatch()
  const { isLoading , data } = useSelector(state => state.surveyReducer.listFolders)
  const advancedFilter = useSelector((state) => state.surveyReducer.advancedFilter);
  const {status} = useSelector((state) => state.surveyReducer.dataMaster);
  const newData = !isLoading ? [...data, ...dataExtra] : []
  const mySession = JSON.parse(localStorage.getItem('sig-session'))
  

  useEffect(() => {
    dispatch(SurveyAction.getListFolders());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data.length > 0) {
      if (isFirstLoading) {
        setFirstLoading(false);
        advancedFilter.filter.location = data[0];
        dispatch(SurveyAction.updateFilter(advancedFilter));
      } 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  
  
  const pathDocument = (path) => {
    if (!path) return
    const pathSplit = path.split('/')
    pathSplit.shift();
    const newPath = pathSplit.join('/')
    return newPath
  }

  const onSelect = (event) => {
    setSelectedNode(event.node.key)
    if (event.node.label === 'Todos') {
      return
    }
    if (event.node.label === 'Recientes') {
      advancedFilter.filter = {}
      const lastMonth = new Date()
      lastMonth.setMonth(lastMonth.getMonth() - 1)
      advancedFilter.filter.from = lastMonth
      advancedFilter.filter.to = new Date()
      return
    }
    if (event.node.label === 'Vigentes') {
      advancedFilter.filter = {}
      const active = status.find(item => item.code === 'SQPB')
      advancedFilter.filter.status = active
      return
    }
    if (event.node.label === 'Mis Encuestas') {
      advancedFilter.filter = {}
      const id = mySession.id
      advancedFilter.filter.creator = {id}
      return
    }
    advancedFilter.filter = {}
    advancedFilter.filter.location = event.node;
    dispatch(SurveyAction.updateFilter(advancedFilter));

    // setSelectedNode(pathDocument(event.node.path))
    
  }

  useEffect(() => {
    if (advancedFilter.filter?.location || advancedFilter.filter?.creator || advancedFilter.filter?.from || advancedFilter.filter?.to || advancedFilter.filter?.status ) {
      dispatch(SurveyAction.search());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancedFilter.filter?.location, advancedFilter.filter?.creator, advancedFilter.filter?.from, advancedFilter.filter?.to, advancedFilter.filter.status, data])

  // console.log(selectedNode)

const nodeTemplate = (node, options) => {
  let label = <p>{node.label}</p>;

  if (node.label === 'Recientes' ) {
      label = (
      <>
        <Tooltip className='ml-2' target=".recent-node"  />
        <p className='recent-node' data-pr-tooltip="Encuestas de los últimos 30 días">{node.label}</p>
      </>
    )
  }
  if (node.label === 'Vigentes') {
      label = (
        <>
          <Tooltip className='ml-2' target=".active-node"  />
          <p className='active-node' data-pr-tooltip="Encuestas aprobados">{node.label}</p>
        </>
      )
  }
  if (node.label === 'Mis Encuestas' ) {
      label = (
        <>
          <Tooltip className='ml-2' target=".mydocuments-node" />
          <p className='mydocuments-node' data-pr-tooltip="Encuestas creados por mí">{node.label}</p>
        </>
      )
  }

  return (
      <span className={options.className}>
          {label}
      </span>
  )
}

  return (
    <div className='flex flex-col items-center justify-center gap-2'>
      
      
      
      <div className='w-full flex justify-center'>
        <Button 
          icon="pi pi-plus" type="button" label="Nuevo" 
          className={`sig-button sig-dark w-36 pointer ${excludedKeys.includes(selectedNode) ? 'invisible' : 'visible'}`}
          onClick={() => {
            history.push('/encuestas/encuesta/nuevo');
        }}
        />  
      </div>
      <div className='w-full menu-survey'>
        {
          isLoading ?(
            <Skeleton className='mt-3' height="6.2rem" />
          ) : (
            <Tree 
              nodeTemplate={nodeTemplate}
              className='px-0 menu-survey-list' value={newData} selectionMode="single" selectionKeys={selectedNodeKey}
              onSelectionChange={e => setSelectedNodeKey(e.value)} onSelect={onSelect} 
              expandedKeys={expandedKeys} onToggle={e => setExpandedKeys(e.value)}
            />  
          )
        }
      </div>           
    </div>
  )
}

export default SidebarMenu