import React  from "react";
import { Panel } from "primereact/panel";
import { ProgressBar } from 'primereact/progressbar'
import { ConfirmDialog } from "primereact/confirmdialog";
import ListAttachments from "./ListAttachments";
import useAttachments from "shared/hooks/useAttachments";
import Message from "shared/components/messages/Message";
import ListFiles from "./ListFiles";

//* Props requeridos setValue, field(nombre del campo de archivos), filesValues(valor de los archivos)
const Attachments = ({ title, setValue, isLoading, fieldFiles, filesValues = [], fieldNewFiles, newFilesValues = [], validExtensions, isFullWidth = true, isMultiple = true, isShowHeader = true}) => {
  const {
    files,
    filesDB,
    // isLoading,
    // file,
    // submit,
    loadingFile,
    loadingPercent,
    onDragEnter,
    onDragLeave,
    onDrop,
    onFileDrop,
    wrapperRef,
    deleteAttachment,
    deleteFileDB,
    setFileId,
    error,
    visible,
    setVisible,
    visibleConfirmation,
    setVisibleConfirmation,
    onOpenConfirmation,
    onAcceptConfirmation
  } = useAttachments({
    // addEndpoint,deleteEndpoint,listEndpoint,module,title,documentId, 
    setValue, fieldFiles, filesValues, fieldNewFiles, newFilesValues, validExtensions, isMultiple
  })
  
  return (
    <Panel header={title?title:"ADJUNTOS"} headerTemplate={isShowHeader ? null : <div className="bg-gray-200 w-full h-0.5"></div>} toggleable className="">
      <div className="attachments-container" id="form-attachment">
        <div
          ref={wrapperRef}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          className="drag-drop attachment text-center border-2 bg-opacity-25  border-gray-400 border-dashed p-3 bg-gray-400 relative"
        > 
          {loadingFile ?
            <>
              <ProgressBar value={loadingPercent} />
            </>
          :
            <>
              <i className="pi pi-cloud-download text-5xl opacity-50"></i>
              <h5 className="opacity-50">
                Arrastar y soltar archivos para adjuntar
              </h5>
            </>  
          }
          
          <input 
            accept="*" 
            id="input-file-att" 
            className="left-0 top-0 h-full opacity-0 absolute" 
            type="file"
            multiple={isMultiple}
            // onChange={onFileDrop}
            onChange={(isMultiple || filesDB.length === 0) ? onFileDrop : onOpenConfirmation}
          />
        </div>
        {files.length > 0  && ( 
          <ListAttachments files={files} deleteAttachment={deleteAttachment} isFullWidth={isFullWidth} />
        )}

         {error && (
          <Message messages={[error]} status="error" />
        )}

        {/* <div className="flex justify-end ">
          <Button
            icon="pi pi-cloud-download"
            type="button"
            label="Subir"
            disabled={isLoading}
            onClick={submit}
            loading={isLoading}
            className="sig-button sig-primary mt-4"
          />
        </div> */}
        {
          filesDB.length > 0 && !isLoading 
          ? (
            <ListFiles 
              files={filesDB} 
              isLoading={isLoading} 
              confirmDelete={(id) => {
                setFileId(id)
                setVisible(true)
              }} 
            />
          )
          : isLoading && <h2 className="text-center mt-5">Cargando Documentos...</h2>
        }
      </div>
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="¿Está seguro que desea eliminar el archivo adjunto?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={deleteFileDB}
        reject={() => setVisible(false)}
      />
      <ConfirmDialog
        visible={visibleConfirmation}
        onHide={() => setVisibleConfirmation(false)}
        message="¿Está seguro que deseas reemplazar el archivo adjunto?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={onAcceptConfirmation}
        reject={() => setVisibleConfirmation(false)}
      />
    </Panel>
  );
};

export default Attachments;
