import React, { useRef } from 'react';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import PinerComponent from 'shared/components/Piner/PinerComponent';
import { useParams } from 'react-router-dom';
import { RiskEvaluationAction } from 'features/riskManagement/store/actions/RiskEvaluationAction';

const FilterComponent = ({setShowModal}) => {
  const dispatch = useDispatch()
  
  const { isLoading } = useSelector( state => state.riskEvaluationReducer.evaluations)

  const advancedFilter = useSelector((state) => state.riskEvaluationReducer.advancedFilter);

  const values = useSelector((state) => state.riskEvaluationReducer.advancedFilter.values);

  const { idModelo } = useParams()

  const openModal = () => {    
    dispatch(RiskEvaluationAction.toggleAdvancedFilter({ showModal: true }));
};

  const criteriaSearch = useRef()

  const generateReport = () => {
    const value = criteriaSearch.current.value;
    if (value !== null && value !== undefined && value.trim().length > 0) {
        advancedFilter.filter.name = value;
        dispatch(RiskEvaluationAction.updateFilter(advancedFilter));
    }
    dispatch(RiskEvaluationAction.generateReport(idModelo));
};

  const search = (e) => {
    e.preventDefault()
    const value = e.target.inputSearch.value 
    if(value !== null && value !== undefined && value.trim().length > 0){
      advancedFilter.filter.name = value;
      dispatch(RiskEvaluationAction.updateFilter(advancedFilter));
    }
    dispatch(RiskEvaluationAction.search(idModelo));
  }

  const showPiners = () => {
    return values?.length > 0;
  }

  const removePiner = (field) => {
    switch (field) {
      case 'name':
        criteriaSearch.current.value = '';
        advancedFilter.filter.name = null;
        break;

      case 'from':
        advancedFilter.filter.from = null;
        break;
      
      case 'to':
        advancedFilter.filter.to = null;
        break;
    
      default:
        break;
    }

    dispatch(RiskEvaluationAction.updateFilter(advancedFilter));
    dispatch(RiskEvaluationAction.search(idModelo));
  }

	return (
    <Panel header="FILTRO POR" toggleable>
      <form className="filter-components" onSubmit={search}>
        <div className="w-full ">
            <span className="p-float-label p-input-icon-left w-full ">
                <i className="pi pi-search" />
                <InputText
                    id="inputSearch"
                    name="inputSearch"
                    ref={criteriaSearch}
                    // onKeyDown={handleKeyDown}
                />
                <label htmlFor="inputSearch">Buscar Evaluación</label>
            </span>
            {showPiners() && (
                <div className="filter-piners mt-3">
                    {values.map(({ value, field }, index) => (
                        <PinerComponent
                            name={value}
                            field={field}
                            removePiner={removePiner}
                            key={index}
                        />
                    ))}
                </div>
            )}
        </div>
        

            <div className="filter-users-action">
                <div className="flex gap-3 w-full">
										
											<Button
                        loading={isLoading}
                        icon="pi pi-search"
                        type="submit"
                        label="Buscar"
                        className="sig-button sig-primary w-full"
											/>
										
											<Button
                        loading={isLoading}
                        icon="pi pi-download"
                        type="button"
                        label="Descargar"
                        className="sig-button sig-success w-full"
                        onClick={generateReport}
											/>
										
                </div>
                <div className="flex mt-3">
                    <Button
                        loading={isLoading}
                        type="button"
                        label="Búsqueda avanzada"
                        className="sig-button sig-secondary w-full"
												onClick={openModal}
                    />
                </div>
            </div>
        
      </form>
    </Panel>
	);
};

export default FilterComponent;