export class ProjectExpensesConstants {
  static Accion = class {

    //PROJECT EXPENSES CONSTANTS
    static ProjectExpenses = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return '@PROJECT_EXPENSES.ADVANCED-FILTER-TOGGLE';
      }

      static get SEARCH_PROJECT_EXPENSES() {
        return '@PROJECT_EXPENSES.SEARCH_PROJECT_EXPENSES';
      }

      static get UPDATE_FILTER() {
        return '@PROJECT_EXPENSES.UPDATE_FILTER';
      }

      static get RESET_AFTER_SAVED() {
        return '@PROJECT_EXPENSES.RESET_AFTER_SAVED';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@PROJECT_EXPENSES.DOWNLOAD_REPORT_STATUS';
      }

      static get SAVE_PROJECT_EXPENSE() {
        return '@PROJECT_EXPENSES.SAVE_PROJECT_EXPENSE';
      }

      static get GET_DATA_MASTER() {
        return '@PROJECT_EXPENSES.GET_DATA_MASTER';
      }

      static get GET_PROJECT_EXPENSE_BY_ID() {
        return '@PROJECT_EXPENSES.GET_PROJECT_EXPENSE_BY_ID';
      }

      static get DELETE_PROJECT_EXPENSE() {
        return '@PROJECT_EXPENSES.DELETE_PROJECT_EXPENSE';
      }
      static get GET_COMMENTS_BY_PROJECT_EXPENSE_ID() {
        return '@PROJECT_EXPENSES.GET_COMMENTS_BY_PROJECT_EXPENSE_ID';
      }
      static get DELETE_COMMENT() {
        return '@PROJECT_EXPENSES.DELETE_COMMENT';
      }
      static get ADD_COMMENT() {
        return '@PROJECT_EXPENSES.ADD_COMMENT';
      }

    };
  };

  static SectionsExpensesProjectsName = class {
    static get INFORMACION_DEL_GASTO() {
      return 'INFORMACIÓN DEL GASTO';
    }
    static get DATOS_DEL_GASTO() {
      return 'DATOS DEL GASTO';
    }
  }
}
