import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ProjectAction } from "features/customers/store/actions/ProjectAction";
import validateTypeValue from "shared/helpers/validateTypeValue";
import { ViewProjectTableData } from "features/customers/data/project/ViewProjectTableData";

export const useTableViewProject = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const project = useSelector((state) => state.projectReducer.project);

  /* CURRENT PROJECT ------------------- */
  const id = useSelector((state) => state.projectReducer.currentAction.id);
  const setCurrentProject = (id) => {
    dispatch(ProjectAction.currentAction({ id }));
  };

  /* LOAD WHEN PROJECT LIST CHANGES -------------------------- */
  const [tableProject, setTableProject] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState([]);

  useEffect(() => {
    const { data, pagination, loading } = project;
    setTableProject(validateTypeValue(data));
    setTablePagination(pagination);
    setTableLoading(loading);
  }, [project]);

  /* HANDLE TABLE HEADER COLUMNS */
  const [selectColumns, setSelectColumns] = useState(
    ViewProjectTableData.columns
  );

  useEffect(() => {
    if (localStorage.getItem("columns-view-projects")) {
      setSelectColumns(
        JSON.parse(localStorage.getItem("columns-view-projects"))
      );
    }
  }, []);

  const onColumnToggle = (e, item) => {
    let _selectedColumns = [...selectColumns];

    if (e.checked) _selectedColumns.push(item);
    else _selectedColumns = _selectedColumns.filter((c) => c.field !== e.value);

    if (_selectedColumns.length === 0) return;
    localStorage.setItem(
      "columns-view-projects",
      JSON.stringify(_selectedColumns)
    );
    setSelectColumns(_selectedColumns);
  };

  /* TABLE HEADER ACTIONS */
  const headerActions = useRef(null);

  /* TABLE CURRENT ACTION ------------- */
  const [currentAction, setCurrentAction] = useState("");
  const onCurrentAction = ({ item: { name } }) => setCurrentAction(name);
  useEffect(() => {
    const action_ = currentAction.toLocaleLowerCase();
    if (action_ === "editar") {
      history.push(`/cliente/empresas/editar/proyectos/editar/${id}`);
    } else if (action_ === "eliminar") {
      dispatch(ProjectAction.currentAction({ isOpen: true }));
    }
    setCurrentAction("");
  }, [currentAction]);

  /* TABLE ACTIONS -------------------- */
  const options = useSelector((state) => state.projectReducer.actions.items);

  const [tableActions, setTableActions] = useState([]);
  const [showActions, setShowActions] = useState(false);
  const tableCurrentActions = useRef(null);

  useEffect(() => {
    if (options.length > 0) {
      const newActions = options.map((option) => {
        option.label = option.name;
        option.command = onCurrentAction;
        return option;
      });
      setTableActions(newActions);
    }
    setShowActions(options.length > 0);
  }, []);

  /* TABLE PAGINATION --------------- */
  const search = useSelector((state) => state.projectReducer.project);
  const [tableRows, setTableRows] = useState(10);
  const [tableNum, setTableNum] = useState(0);
  const [page, setPage] = useState(1);
  let currentFilterFields = {
    columnOrder: search.columnOrder,
    order: search.order,
  };

  /* TABLE PAGINATION - FILTER VALUES --------------- */
  const filterValues = useSelector(
    (state) => state.projectReducer.advancedFilter.values
  );
  const buildFilterValues = () => {
    filterValues.map((item) => {
      let valueItem = item.field.split("-");
      if (!currentFilterFields[valueItem[0]]) {
        currentFilterFields[valueItem[0]] = [Number(valueItem[1])];
      } else {
        currentFilterFields[valueItem[0]].push(Number(valueItem[1]));
      }
    });
    // for (let valueKey in filterValues){
    //   if(filterValues[valueKey]){
    //     if(Array.isArray([filterValues[valueKey]])){
    //       if(filterValues[valueKey].length>0){
    //         currentFilterFields[valueKey] = filterValues[valueKey].map(el => {
    //           return el.id
    //         })
    //       }
    //     }else{
    //       currentFilterFields[valueKey] = filterValues[valueKey]
    //     }
    //   }
    // }
    // if (filterValues.length > 0) {
    //   filterValues.forEach(({ field, value, option }) => {
    //     let currentValue = '';
    //     const key = field.toLocaleLowerCase();
    //     if (key.includes('id')) currentValue = option.id;
    //     else currentValue = value;
    //     currentFilterFields[field] = currentValue;
    //   });
    // }
  };

  const onChangePage = ({ rows, page }) => {
    buildFilterValues();
    currentFilterFields.page = page + 1;
    currentFilterFields.pageSize = rows;
    setTableRows(rows);
    setTableNum(rows * page);
    setPage(page + 1);
    const fields = currentFilterFields;
    dispatch(ProjectAction.getProject({ change: true, fields }));
  };

  /* TABLE ORDER SORT */
  const [tableSort, setTableSort] = useState({
    sortField: "name",
    sortOrder: "1",
  });
  const onSort = (event) => {
    buildFilterValues();
    const params = {
      first: tableNum,
      rows: tableRows,
      multiSortMeta: [],
      filters: null,
      ...event,
    };
    currentFilterFields.order = event.sortOrder + "" === "1" ? "asc" : "desc";
    currentFilterFields.columnOrder = event.sortField;
    currentFilterFields.pageSize = tableRows;
    currentFilterFields.page = page;
    const fields = currentFilterFields;
    dispatch(ProjectAction.getProject({ change: true, fields }));
    setTableSort(params);
  };

  return {
    headerActions,
    tableActions,
    tableCurrentActions,
    tableProject,
    tableLoading,
    tablePagination,
    tableSort,
    tableNum,
    tableRows,
    selectColumns,
    showActions,
    setCurrentProject,
    onChangePage,
    onColumnToggle,
    onSort,
  };
};
