import { apiService } from "core/services/ApiService";

const searchDocumentLibraries = (criterio) => {
  return apiService.Post("customer/api/AcademicDocumentLibrary/Search", criterio);
}


const getDocumentLibraryById = (documentId) => {
  return apiService.Get("storage/api/Storage/GetDocumentStorageById?id=" + documentId);
}

// APIS Funcionando
const getDataListFolders = (path = '') => {
  return apiService.Get("storage/api/Storage/ListFolders?path=" + path);
}

const getListFoldersTable = (path = '') => {
  return apiService.Get("storage/api/Storage/ListCurrentFolders?path=" + path);
}

const createFolder = (folderName) => {
  return apiService.Post("storage/api/Storage/CreateFolder?pathAndFolderName="+folderName);
}

const renameFolder = (data) => {
  return apiService.Post("storage/api/Storage/RenameFolder", data);
}

const deleteFolder = (folderName) => {
  // return apiService.Delete(`storage/api/Storage/DeleteFolder?id=${documentId}&fileName=${documentName}`);
  return apiService.Delete(`storage/api/Storage/DeleteFolder?pathAndFolderName=${folderName}`);
}

const getDataMaster = () => {
  return apiService.Post("storage/api/Storage/GetDataMaster");
}

const getPermissionByPath = (path) => {
  return apiService.Get("storage/api/Permission/GetPermissionByPath?path=" + path);
}

const savePermission = (permissions) => {
  return apiService.Post("storage/api/Permission/Save", permissions);
}

const isStorageEnabled = () => {
  return apiService.Get("storage/api/Storage/IsStorageEnabled");
}

export const DocumentManagementServices = {
  searchDocumentLibraries,
  
  getDocumentLibraryById,
  
  getDataListFolders,
  getListFoldersTable,
  createFolder,
  deleteFolder,
  renameFolder,
  getDataMaster,
  getPermissionByPath,
  savePermission,
  isStorageEnabled
};