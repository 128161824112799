import { BusinessSheetAnswerConstants } from "features/business/commons/BusinessSheetAnswerConstants";

const initialState = {
  businessSheetAnswers: {
    data: [],
    loading: false,
    order: 'asc',
    businessSheetId: 0,
    pagination: {},
    columnOrder: 'customerName',
    isLoadingDownload: false,
  },
  dataMaster: {
    data: {},
    isLoadingDataMaster: false,
  },
  data: {
    businessSheetAnswer: {},
    loading: false,
  },
  save: {
    isLoadingSave: false,
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export const businessSheetAnswerReducer = (state = initialState, action) => {
  switch (action.type) {
    case BusinessSheetAnswerConstants.Action.BusinessSheetAnswer.SEARCH:
      return {
        ...state,
        businessSheetAnswers: {
          ...state.businessSheetAnswers,
          ...action.payload,
        },
      };
      
    case BusinessSheetAnswerConstants.Action.BusinessSheetAnswer.DATA_MASTER:
      return {
        ...state,
        dataMaster: {
          ...state.dataMaster,
          ...action.payload,
        },
      };
    
    case BusinessSheetAnswerConstants.Action.BusinessSheetAnswer.EDIT:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case BusinessSheetAnswerConstants.Action.BusinessSheetAnswer.SAVE:
      return {
        ...state,
        save: {
          ...state.save,
          ...action.payload,
        },
      };
    default:
      return state;
  }
} 