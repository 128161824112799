import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { ViewActions } from "features/business/store/actions/ViewAction";
import { Calendar } from "primereact/calendar";
import { useFilter } from "features/business/hooks/view/useFilter";


const customerOptionsSelect = createSelector(
  (state) => state.viewBusinessReducer.dataManager?.customers,
  (customers) => customers?.map((i) => ({ label: i.name, value: i.id }))
);

const pipelineOptionsSelect = createSelector(
  (state) => state.viewBusinessReducer.dataManager?.pipelines,
  (pipelines) => pipelines?.map((i) => ({ label: i.description, value: i.id }))
);

const responsibleOptionsSelect = createSelector(
  (state) => state.viewBusinessReducer.dataManager?.responsibles,
  (responsibles) =>
    responsibles?.map((i) => ({ label: i.fullName, value: i.id }))
);
// este filtro es diferente
export const FilterComponent = () => {
  const dispatch = useDispatch();
  const customerOptions = useSelector(customerOptionsSelect);
  const pipelineOptions = useSelector(pipelineOptionsSelect);
  const responsibleOptions = useSelector(responsibleOptionsSelect);
  const { formValues, setValue, clearFields, applyFilter, setValueFilter } = useFilter();
  const isLoadingSearch = useSelector((state) => state.viewBusinessReducer.search?.isLoadingSearch);
  const [showErrors, setShowErrors] = useState(false);
  const criteria = useSelector((state) => state.viewBusinessReducer.search?.criteria);

  const stageOptionsSelect = createSelector(
    [
      (state) => state.viewBusinessReducer.dataManager?.stages,
      (state, formValues) => formValues.pipelineId,
    ],
    (stages, pipelineId) =>
      stages?.filter((r) => r.pipelineId === pipelineId)?.map((i) => ({ label: i.stageName, value: i.id }))
  );
  const stageOptions = useSelector((state) =>
    stageOptionsSelect(state, formValues)
  );

  useEffect(() => {
    dispatch(ViewActions.getDataMaster());
    //applyFilter()
    if (criteria.pipelineId)
      setValueFilter(criteria);

  }, [dispatch]);

  useEffect(()=>{
    if (criteria.pipelineId)
      dispatch(ViewActions.search(criteria));
  },[])

  const search = () => {

    setShowErrors(true);
    if (formValues.pipelineId > 0)
      applyFilter()
  }

  return (
    <>
      <Panel header="FILTRO POR" toggleable>
        <form className="filter-roles mt-3"
        >
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
            <div>
              <span className="p-float-label">
                <Dropdown
                  id="pipelineId"
                  name="pipeline"
                  filter
                  showClear
                  optionLabel="label"
                  filterBy="label"
                  value={formValues.pipelineId}
                  onChange={setValue}
                  options={pipelineOptions}
                  className={(!formValues.pipelineId > 0 && showErrors === true) ? 'p-invalid w-full' : ''}
                />
                <label htmlFor="pipelineId">Por Pipeline</label>
              </span>
            </div>
            <div>
              <span className="p-float-label">
                <Dropdown
                  id="customerId"
                  name="customer"
                  filter
                  showClear
                  optionLabel="label"
                  filterBy="label"
                  value={formValues.customerId}
                  onChange={setValue}
                  options={customerOptions}

                />
                <label htmlFor="customerId">Por Empresa</label>
              </span>
            </div>
            <div>
              <span className="p-float-label">
                <Dropdown
                  options={responsibleOptions}
                  id="responsibleId"
                  name="responsible"
                  filter
                  showClear
                  optionLabel="label"
                  filterBy="label"
                  value={formValues.responsibleId}
                  onChange={setValue}
                />
                <label htmlFor="responsibleId">Por Responsable</label>
              </span>
            </div>
            <div>
              <span className="p-float-label">
                <Dropdown
                  id="stageId"
                  name="stage"
                  filter
                  showClear
                  optionLabel="label"
                  filterBy="label"
                  value={formValues.stageId}
                  onChange={setValue}
                  options={stageOptions}
                />
                <label htmlFor="stageId">Por Etapa</label>
              </span>
            </div>
            <div>
              <span className="p-float-label">
                <Calendar
                  id="openDate"
                  value={formValues.openDate}
                  onChange={setValue}
                  className="w-full"
                  style={{height: 37.19}}
                />
                <label htmlFor="openDate">Apertura</label>
              </span>
            </div>
            <div>
              <span className="p-float-label">
                <Calendar
                  id="closeDate"
                  value={formValues.closeDate}
                  onChange={setValue}
                  className="w-full"
                  style={{height: 37.19}}
                />
                <label htmlFor="closeDate">Cierre</label>
              </span>
            </div>
            {(!formValues.pipelineId > 0 && showErrors === true) &&
              <div className='message error w-full'>
                <ul><li className="p-error">Ingrese el Pipeline</li></ul>
              </div>
            }
          </div>
          <div className="filter-users-action">
            <div className="flex gap-3 w-full">
              <Button
                icon="pi pi-search"
                type="button"
                label="Buscar"
                className="sig-button sig-primary w-full"
                loading={isLoadingSearch}
                onClick={search}
              />
              <Button
                icon="pi pi-times-circle"
                type="button"
                label="Limpiar"
                className="sig-button sig-success w-full"
                loading={isLoadingSearch}
                onClick={() => { clearFields(); setShowErrors(false) }}
              />
            </div>

          </div>


        </form>
      </Panel>
    </>
  );
};
