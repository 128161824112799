import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BusinessAction } from "features/customers/store/actions/BusinessAction";
import { useForm } from "shared/hooks/useForm";
import { CoreConstants } from "core/commons/CoreConstants";
import moment from "moment";
import { useParams } from "react-router-dom";

export const useAdvancedFilterBusiness = () => {
  const dispatch = useDispatch();

  /* CURRENT CUSTOMER ------------------- */
  // const customerId = useSelector((state) => state.companiesReducer.currentAction.companyId);
  const { customerId } = useParams()

  const businessLoading = useSelector(
    (state) => state.businessReducer.business.loading
  );
  const reportLoading = useSelector(
    (state) => state.businessReducer.downloadReport.loading
  );

  const dataMaster = useSelector(
    (state) => state.businessReducer.dataMaster.data
  );

  const { values, showModal } = useSelector(
    (state) => state.businessReducer.advancedFilter
  );

  const showPiners = values.length > 0;

  const initialValues = {
    name: null,
    customerId: null,
    code: null,
    pipelineId: null,
    responsibleId: null,
    openDate: null,
    closeDate: null,
  };
  const [formValues, handleChange, reset] = useForm(initialValues);
  const [errors, setErrors] = useState([]);

  const onToggleModal = (showModal) => {
    setErrors([]);
    dispatch(BusinessAction.setAdvancedFilter({ showModal }));
  };
  const clearFields = () => {
    reset(initialValues);
    setErrors([]);
    dispatch(BusinessAction.getBusiness({ change: true, fields: {customerId: Number(customerId) }}));
    dispatch(BusinessAction.setAdvancedFilter({ showModal: false, values: [] }));
  };

  useEffect(() => {
    if (values.length === 0) {
      // clearFields();
      setErrors([]);
    }
  }, [values]);

  const buildFilterValues = () => {
    const currentValues = {};
    const currentPiners = [...values];
    for (let valueKey in formValues) {
      const key = valueKey.toLocaleLowerCase();
      const field = valueKey;
      const hasValue = formValues[valueKey];
      if (hasValue) {
        if (key.includes("date")) {
          const value = moment(formValues[valueKey]).format(
            CoreConstants.Format.DDMMYYYY
          );
          const date = formValues[valueKey];
          // currentValues[field] = date;
          const index = currentPiners
            .map((piner) => piner.field)
            .indexOf(field);
          index === -1
            ? currentPiners.push({ field, value, date })
            : currentPiners.splice(index, 1, { field, value, date });
        } else if (
          key.includes("id") ||
          key === "pipeline" ||
          key === "responsible"
        ) {
          const option = formValues[valueKey];
          let value = "";
          if (formValues[valueKey].description) {
            value = formValues[valueKey].description;
          } else if (formValues[valueKey].niceName) {
            value = formValues[valueKey].niceName;
          } else if (formValues[valueKey].fullName) {
            value = formValues[valueKey].fullName;
          }
          // currentValues[field] = option.id;
          const index = currentPiners
            .map((piner) => piner.field)
            .indexOf(field);
          index === -1
            ? currentPiners.push({ field, value, option })
            : currentPiners.splice(index, 1, { field, value, option });
        } else if (key === "iscompleted") {
          const option = formValues[valueKey];
          const value = formValues[valueKey].description;
          // currentValues[field] = option.code === "TCO";
          const index = currentPiners
            .map((piner) => piner.field)
            .indexOf(field);
          index === -1
            ? currentPiners.push({ field, value, option })
            : currentPiners.splice(index, 1, { field, value, option });
        } else {
          const value = formValues[valueKey];
          // currentValues[field] = value;
          const index = currentPiners
            .map((piner) => piner.field)
            .indexOf(field);
          index === -1
            ? currentPiners.push({ field, value })
            : currentPiners.splice(index, 1, { field, value });
        }
      }
    }

    currentPiners.forEach((piner) => {
      if (piner.field === "isCompleted") {
        currentValues[piner.field] = piner.option.code === "TCO";
      } else if (piner.option) {
        currentValues[piner.field] = piner.option.id;
      } else if (piner.field.includes("Date")) {
        currentValues[piner.field] = piner.date;
      } else {
        currentValues[piner.field] = piner.value;
      }
    });

    return {
      currentPiners,
      currentValues,
    };
  };

  const onAdvancedFilter = (e) => {
    e.preventDefault();
    setErrors([]);

    let { currentValues, currentPiners } = buildFilterValues();

    dispatch(
      BusinessAction.setAdvancedFilter({
        showModal: false,
        values: currentPiners,
      })
    );
    dispatch(
      BusinessAction.getBusiness({ fields: {...currentValues, customerId: Number(customerId)}, change: true })
    );
  };

  const removePiner = (field) => {
    dispatch(BusinessAction.removeFilterValues(field, Number(customerId)));
  };

  const generateReport = () => {
    dispatch(BusinessAction.generateReport(Number(customerId)));
  }

  return {
    onToggleModal,
    clearFields,
    showModal,
    onAdvancedFilter,
    businessLoading,
    generateReport,
    reportLoading,
    dataMaster,
    formValues,
    handleChange,
    showPiners,
    piners: values,
    removePiner,
    errors,
  };
};
