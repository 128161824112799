import useApprovalFlow from 'features/configuration/hooks/processes/useApprovalFlow';
import { useClaimApprovalFlow } from 'features/configuration/hooks/useClaimApprovalFlow';
import { ApprovalFlowActions } from 'features/configuration/store/actions/ApprovalFlowAction';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { v4 as uuidv4 } from 'uuid';

export const NewApprovalFlow = ({title, subtitle, mode, description}) => {

  const { updateTitle } = useSetTitlePage();
  const [expandedRows, setExpandedRows] = useState(null);
  const [visible, setVisible] = useState(false)
  const [dataFlow, setDataFlow] = useState([])
  const [levelsReject, setLevelsReject] = useState([])
  const [mnsjValidate, setMnsjValidate] = useState([])
  const [mnsjSendValidate, setMnsjSendValidate] = useState([])
  const [validaetPlazo, setValidatePlazo] = useState(null)
  const [validateNameFlow, setValidateNameFlow] = useState(null)

  const { control, errors, valuesFields, existError, setValue, handleSubmit, validateLength} = useApprovalFlow()

  const dataUsers = useSelector((state) => state.approvalFlowReducer.users.data);
  const dataEditFlow = useSelector((state) => state.approvalFlowReducer.flow.data)
  const {isSaving, isSaved} = useSelector((state) => state.approvalFlowReducer.flow)

  const { isCreate, isEdit } = useClaimApprovalFlow();

  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  useEffect(()=> {
    if(isSaved){
      reject()
    }
  },[isSaved])

  useEffect(() => {
    updateTitle({
        title: title,
        subtitle: subtitle,
        description: description,
        previousUrl: '/configuracion/flujo-aprobacion',
    });
    if(!dataUsers){
      dispatch(ApprovalFlowActions.getUsers())
    }
    if(!dataEditFlow && params?.id){
      dispatch(ApprovalFlowActions.getApprovalFlowById(params?.id))
    }else{
      setValue('sourceTypeId', null)
      setValue('approvalDeadline', "")
      setValue('nameApprovalFlow', "")
      setDataFlow([])
      setLevelsReject([])
    }
}, []);


useEffect(()=> {
  if(dataEditFlow){
    chargeApprovalFlow()
  }
},[dataEditFlow])

const chargeApprovalFlow = () => {
  setValue('sourceTypeId', {code: dataEditFlow.sourceType.code,description:dataEditFlow.sourceType.description,id:dataEditFlow.sourceType.id})
  setValue('approvalDeadline', dataEditFlow.rejectionDeadline)
  setValue('nameApprovalFlow', dataEditFlow.name)
  setValidateNameFlow(dataEditFlow.name)
  setValidatePlazo(dataEditFlow.rejectionDeadline)
  setLevelsReject(dataEditFlow?.workflowConfigurationLevels.map(el => {
    return {
      id: el.levelNumber,
      fullName: el.levelDescription
    }
  }))
  setDataFlow(dataEditFlow?.workflowConfigurationLevels.map(elem => {
    return {
      id: elem.id,
      levelDescription: elem.levelDescription,
      levelNumber: elem.levelNumber,
      mainApproverId: elem.mainApproverId,
      substituteApprovedId: elem.substituteApproverId,
      rejectLevelNumber: elem.rejectLevelNumber,
      workflowConfigurationId: dataEditFlow.id,
      mode: "edit"
    }
  }))
}


const reject = () => {
  history.push("/configuracion/flujo-aprobacion")
}

const changeValueLevel = (e,id,row) => {
    setMnsjSendValidate(mnsjSendValidate.filter(el => {
      if(el.id!==id){
        return el
      }else if(el.nameRow!==row){
        return el
      }
    }))
    setDataFlow(dataFlow.map(el=>{
      if(el.id===id){
        el[row] = e.target.value
      }
      return el
    }))
    setMnsjValidate([])
}

const duplicateLevel = (id, row, e, levelNumber) => {
  let countValidate = 0

  const value = (e.target.value).trim()

  if(value!==""){
    dataFlow.map(elem => {
      if((elem.levelDescription).trim()===value){
        countValidate+=1
      }
      return elem
    })
    if(countValidate===1){
      setDataFlow(dataFlow.map(elem=> {
        if(elem.id===id){
          elem[row] = value
        }
        return elem
      }))
      setLevelsReject(levelsReject.map(el=> {
        if(el.id===levelNumber){
          el.fullName = value
        }
        return el
      }))
    }else{
      setMnsjValidate([...mnsjValidate,{id:"DUPLICATE", mnsj: "El Nivel ya se encuentra en uso."}])
      setDataFlow(dataFlow.map(elem=> {
        if(elem.id===id){
          elem[row] = ""
        }
        return elem
      }))
      setLevelsReject(levelsReject.map(el=> {
        if(el.id===levelNumber){
          el.fullName = ""
        }
        return el
      }))
    }
  }
}

const changeDropDown = (event, row, data) => {
  if(row==='rejectLevelNumber'){
    if(event.value?.id){
      if(event.value.id!==data.levelNumber){
        setDataFlow(dataFlow.map(el=>{
          if(el.id===data.id){
            el[row] = event.value.id
          }
          return el
        }))
        setMnsjValidate(mnsjValidate.filter(el=> el.id!=="DRPDWM"))
      }else{
        setDataFlow(dataFlow.map(el=>{
          if(el.id===data.id){
            el[row] = null
          }
          return el
        }))
        setMnsjValidate([...mnsjValidate,{id:"DRPDWM", mnsj: "No se puede asignar el mismo Nivel al Nivel de rechazo."}])
      }
    }else{
      setDataFlow(dataFlow.map(el=>{
        if(el.id===data.id){
          el[row] = null
        }
        return el
      }))
    }
  }else{
    if(event.value?.id){
      let newRow = row
      if(newRow==="mainApproverId"){
        newRow = "substituteApprovedId"
      }else{
        newRow = "mainApproverId"
      }
      if(event.value.id!==data[newRow]){
        setMnsjSendValidate(mnsjSendValidate.filter(el => {
          if(el.id!==data.id){
            return el
          }else if(el.nameRow!==row){
            return el
          }
        }))
        setDataFlow(dataFlow.map(el=>{
          if(el.id===data.id){
            el[row] = event.value.id
          }
          return el
        }))
        setMnsjValidate(mnsjValidate.filter(el=> el.id!=="APROB"))
      }else{
        setDataFlow(dataFlow.map(el=>{
          if(el.id===data.id){
            el[row] = null
          }
          return el
        }))
        setMnsjValidate([...mnsjValidate,{id:"APROB", mnsj:"El aprobador sustituto debe ser distinto al aprobador principal."}])
      }
    }else{
      setDataFlow(dataFlow.map(el=>{
        if(el.id===data.id){
          el[row] = null
        }
        return el
      }))
    }
  }
}

const viewInputNumber = (data, row) => {
  return <InputText value={data[row]} onChange={(e)=>changeValueLevel(e,data.id, row)} onBlur={(e)=> duplicateLevel(data.id, row, e, data.levelNumber)} className='w-full' />
}

const viewInputDropDown = (data, row) => {
  return <Dropdown
    onChange={(e)=>changeDropDown(e,row, data)}
    options={row==="rejectLevelNumber"?levelsReject:dataUsers?.users}
    value={row==="rejectLevelNumber"?levelsReject.find(elem => elem.id===data[row]):dataUsers?.users.find(elem => elem.id===data[row])}
    // optionValue={(e) => {
    //   return `${e.id}-${e.description}`;
    // }}
    optionLabel="fullName"
    filter
    showClear
  />
}

const trashFunction = (data) => {
  return <i
  className='pi pi-trash cursor-pointer'
  onClick={(e)=> {
    setMnsjSendValidate(mnsjSendValidate.filter(el => el.id!==data.id))
    setLevelsReject(levelsReject.filter(elem=>elem.id!==data.levelNumber))
    const data2 = ([...dataFlow].filter(el=>el.id!==data.id))
    setDataFlow(data2.map(elem => {
      if(elem.rejectLevelNumber===data.levelNumber){
        elem.rejectLevelNumber = null
      }
      return elem
    }))
  }}
  />
}

const addRow = () => {
  setMnsjValidate(mnsjValidate.filter(el=> el.id!=="NVL"))
  const newId = uuidv4();
  if(dataFlow?.length===0){
    setLevelsReject([{id: 1, fullName: ""}])
    setDataFlow([{
      "id": newId,
      "workflowConfigurationId": null,
      "levelNumber": 1,
      "levelDescription": '',
      "mainApproverId": null,
      "substituteApprovedId": null,
      "rejectLevelNumber": null,
      "mode":"new"
    }])
  }else{
    let numMax = 1;
    dataFlow.map(el=>{
      if(el.levelNumber>numMax){
        numMax = el.levelNumber
      }
      return el
    })
    setLevelsReject([...levelsReject, {id: numMax + 1, fullName: ""}])
    setDataFlow([...dataFlow, {
      "id": newId,
      "workflowConfigurationId": null,
      "levelNumber": numMax + 1,
      "levelDescription": '',
      "mainApproverId": null,
      "substituteApprovedId": null,
      "rejectLevelNumber": null,
      "mode":"new"
    }])
  }
}

const  redirectToSearch = () => {
  history.push('/configuracion/flujo-aprobacion')
}

const onSubmit = (data) => {
  const newData = [...dataFlow]
  const mnjsError = []
  let workflowConfigurationLevels = []
  let validateData = true

  const msjVal = []

  if(!data.sourceTypeId){
    msjVal.push({id:"TPSL", mnsj: "El Tipo de Solicitud es requerida."})
  }

  if(validateNameFlow==="" || !validateNameFlow){
    msjVal.push({id:"NFLJ", mnsj: "El nombre del flujo es requerido."})
  }

  if(validaetPlazo==="" || !validaetPlazo){
    msjVal.push({id:"PLZ", mnsj: "El plazo es requerido."})
  }

  if(newData.length===0){
    msjVal.push({id:"NVL", mnsj:"Se debe tener al menos un nivel de aprobación."})
  }
  setMnsjValidate(msjVal)

  if(newData.length!==0){
    workflowConfigurationLevels = newData.map((elem,index) => {
        if(elem.levelDescription.trim()===''){
          mnjsError.push({
            id: elem.id,
            nameRow: "levelDescription",
            name: `El campo Nivel es requerido en la fila ${index + 1}.`,
            row: index
          })
          validateData = false
        }
        if(elem.mainApproverId===null){
          mnjsError.push({
            id: elem.id,
            nameRow: "mainApproverId",
            name: `El campo Aprobador es requerido en la fila ${index + 1}.`,
            row: index
          })
          validateData = false
        }
        if(elem.substituteApprovedId===null){
          mnjsError.push({
            id: elem.id,
            nameRow: "substituteApprovedId",
            name: `El campo Sustituto es requerido en la fila ${index + 1}.`,
            row: index
          })
          validateData = false
        }
      return {
        id: elem.mode==="edit"?elem.id:null,
        workflowConfigurationId: dataEditFlow?.id?dataEditFlow.id:null,
        levelNumber: index + 1,
        levelDescription: elem.levelDescription?elem.levelDescription:null,
        mainApproverId: elem.mainApproverId?elem.mainApproverId:null,
        substituteApprovedId: elem.substituteApprovedId?elem.substituteApprovedId:null,
        rejectLevelNumber: elem.rejectLevelNumber?elem.rejectLevelNumber:null
      }
    })
    if(validateData && msjVal.length===0){
      const sendData = {
        id: dataEditFlow?.id?dataEditFlow.id:null,
        sourceTypeId: data.sourceTypeId.id,
        name: data.nameApprovalFlow.trim(),
        rejectionDeadline: Number(data.approvalDeadline),
        workflowConfigurationLevels: workflowConfigurationLevels
      }
      // console.log(sendData)
      dispatch(ApprovalFlowActions.saveApprovalFlow(sendData))
    }else{
      setMnsjSendValidate(mnjsError)
      return
    }
  }
}


  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="form-custom p-0">
        <div className='grid grid-cols-6 items-center'>
          <span className="p-float-label mt-3 col-span-6 md:col-span-3 lg:col-span-2">
              <Controller
                        control={control}
                        render={({
                            field: { onChange, onBlur, value },
                        }) => (
                      <Dropdown
                        onChange={(e)=> {
                          onChange(e.value)
                          setMnsjValidate(mnsjValidate.filter(el=> el.id!=="TPSL"))
                          }
                        }
                        options={dataUsers?.sourceTypes}
                        value={value}
                        id="typeDropDown"
                        optionLabel="description"
                        filter
                        showClear
                        className={mnsjValidate.find(elem => elem.id==="TPSL") ? 'p-invalid w-full' : 'w-full'}
                      />
                      )}
                        name="sourceTypeId"
                    />
                      <label htmlFor="typeDropDown">Tipo de Ficha de Solicitud *</label>
          </span>
        </div>
        <div className='grid grid-cols-6 items-center mt-6'>
          <span className='p-float-label col-span-6 md:col-span-3 lg:col-span-2'>
              <Controller
                        control={control}
                        render={({
                            field: { onChange, onBlur, value },
                        }) => (
                          <InputText
                            value={value}
                            type="text"
                            onChange={(e)=> {
                              setValidateNameFlow(e.target.value)
                              onChange(e.target.value)
                              setMnsjValidate(mnsjValidate.filter(el=> el.id!=="NFLJ"))
                            }}
                            id='nameApprovalFlow'
                            className={mnsjValidate.find(elem => elem.id==="NFLJ") ? 'p-invalid w-full' : 'w-full'}
                            onBlur={(e)=> setValue('nameApprovalFlow', e.target.value.trim())}
                            />
                          )}
                          name="nameApprovalFlow"
                      />
                      <label htmlFor="nameApprovalFlow">Nombre del Flujo *</label>
          </span>
        </div>
        <div className='mt-6 grid grid-cols-10 mb-2'>
          <span className='p-float-label col-span-5 md:col-span-4 lg:col-span-2'>
              <Controller
                        control={control}
                        render={({
                            field: { onChange, onBlur, value },
                        }) => (
                          <InputText
                            value={value}
                            type="number"
                            onChange={(e)=> {
                              setValidatePlazo(e.target.value)
                              onChange(e.target.value)
                              setMnsjValidate(mnsjValidate.filter(el=> el.id!=="PLZ"))
                            }}
                            inputId="integeronly"
                            id='approvalDeadline'
                            className={mnsjValidate.find(elem => elem.id==="PLZ") ? 'p-invalid w-full' : 'w-full'}
                            />
                          )}
                          name="approvalDeadline"
                      />
                      <label htmlFor="approvalDeadline">Plazo *</label>
          </span><div className='mt-2 ml-2'>Días</div>
        </div>
        <Button
        className='sig-button sig-primary mt-2 mb-2'
        type='button'
        icon="pi pi-plus"
        label='Añadir Nivel'
        onClick={addRow}
        />
        <div>
        <DataTable
                          value={dataFlow}
                          emptyMessage="No se encontraron datos"
                          stripedRows
                          rows={5}
                          editMode="row"
                          dataKey="id" //TODO: Cambiar luego por id
                          rowHover
                          size="small"
                          responsiveLayout="scroll"
                          expandedRows={expandedRows}
                          // onRowToggle={(e) => setExpandedRows(e.data)}
                          // onRowExpand={(e) => onRowExpand(e.data)}
                          lazy
                      >
                          <Column field="levelDescription" header="Nivel" body={(e)=>viewInputNumber(e, "levelDescription")}/>
                          <Column field="mainApproverId" header="Aprobador Principal" body={(e)=>viewInputDropDown(e, "mainApproverId")}/>
                          <Column field="substituteApprovedId" header="Aprobador Sustituto" body={(e)=>viewInputDropDown(e, "substituteApprovedId")}/>
                          <Column field="rejectLevelNumber" header="Nivel de Rechazo" body={(e)=>viewInputDropDown(e, "rejectLevelNumber")}/>
                          <Column field="" header={null} body={(e)=>trashFunction(e)}/>
                      </DataTable>
        </div>
        {
              (errors.sourceTypeId || errors.approvalDeadline || mnsjValidate.length!==0 || mnsjSendValidate.length!==0) && <div className='message error'>
                  <ul>
                    {
                        mnsjValidate.map((el, index)=> <li className="p-error" key={index}>{el.mnsj}</li>)
                    }
                    {
                      mnsjSendValidate.map(el=> <li className="p-error" key={el.id}>{el?.name}</li>)
                    }
                      {errors.sourceTypeId ? (
                          <>
                              <li className="p-error">{errors.sourceTypeId.message}</li>
                          </>
                      ) : null}
                  </ul>
                  <ul>
                      {errors.approvalDeadline ? (
                          <>
                              <li className="p-error">{errors.approvalDeadline.message}</li>
                          </>
                      ) : null}
                  </ul>
              </div>
            }
        <div className="flex justify-end gap-4 mt-3">
                      <Button
                          icon="pi pi-times"
                          type="button"
                          label="Cancelar"
                          loading={isSaving}
                          onClick={() => { setVisible(true); }}
                          className="sig-button sig-secondary mt-4"
                      />
                      {
                        mode!=='view'&&(
                          <>
                          {
                            (isCreate || isEdit)&&(
                              <Button
                                  icon="pi pi-save"
                                  label="Guardar"
                                  type='submit'
                                  loading={isSaving}
                                  // loading={academicDegree?.isSaving || academicDegree?.isLoading}
                                  className="sig-button sig-primary mt-4"
                              />
                            )
                          }
                          </>
                        )
                      }
                  </div>
        <ConfirmDialog
                  visible={visible}
                  onHide={() => setVisible(false)}
                  message="¿Está seguro que desea cancelar?"
                  header="Cancelar"
                  icon="pi pi-exclamation-triangle"
                  accept={redirectToSearch}
                  reject={reject}
              />
      </form>
    </div>
  )
}
