import FileSaver from 'file-saver';
import { toastAction } from 'core/store/actions/ToastAction';
import { RrhhConstants } from 'features/rrhh/commons/RrhhConstants';
import { EmployeesService } from 'features/rrhh/services/EmployeesService';
import { CoreConstants } from 'core/commons/CoreConstants';
import { PositionsServices } from 'features/rrhh/services/PositionsServices';
import moment from 'moment';

const setEmployees = (fields) => {
  return (dispatch) =>
    dispatch({
      type: RrhhConstants.Accion.Employees.SEARCH_EMPLOYEES,
      payload: { ...fields },
    });
};

const toggleModalFilters = (payload) => {
  return (dispatch) =>
    dispatch({
      type: RrhhConstants.Accion.Employees.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const modalEmployee = (payload) => {
  return (dispatch) =>
    dispatch({
      type: RrhhConstants.Accion.Employees.MODALS,
      payload,
    });
}


const getEmployees = (fields) => {
  return (dispatch) => {
    dispatch(setEmployees({ loading: true }));
    EmployeesService.getEmployees(fields)
      .then(({ data }) => {
        const status = data?.status ?? '';
        if (status === CoreConstants.HttpResponse.OK) {
          const { results, ...rest } = data.data;
          let objFields = {
            loading: false,
            data: results,
            currentData: results,
            pagination: { ...rest },
            first:0
          };
          if (fields.fields?.columnOrder) {
            objFields.currentCol = fields.fields.columnOrder;
          }
          if (fields.fields?.order) {
            objFields.order = fields.fields.order;
          }
          dispatch(setEmployees(objFields));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn('Empleados', data.message));
          dispatch(setEmployees({ loading: false }));
        }else{
          dispatch(toastAction.error('Empleados', data.message));
          dispatch(setEmployees({ loading: false }));
        }
      })
      .catch((error)=>{
        dispatch(setEmployees({ loading: false }));
      });
  };
};


const setDataMaster = (infoData) => {
  return (dispatch) =>
    dispatch({
      type: RrhhConstants.Accion.Employees.DATA_MASTER,
      payload: infoData,
    });
}

const setEmployeeEdit = (info) =>{
  return (dispatch) =>
    dispatch({
      type: RrhhConstants.Accion.Employees.EDIT_EMPLOYEE,
      payload: info,
    });
}

const getDataDepartmentGerente = (id) =>{
  return (dispatch) => {
    dispatch(setEmployeeEdit({ loadingEdit: true }));
    PositionsServices.getPositionResultById(id)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
           let infoData = {
             cargoSelect : data.data,
             loadingEdit: false
           }
          dispatch(setEmployeeEdit(infoData))

          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.success('Empleados', data.message));
        }
      })
      .catch((error)=>{
        dispatch(setEmployeeEdit({ loadingEdit: false }));
      });
  };
}


const saveEmployee = (info) => {
  return (dispatch) => {
    dispatch(setDataMaster({ loadingDataManager: true }));
    EmployeesService.saveEmployee(info)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
           let infoData = {
             cargoSelect : data.data,
             loadingSave: false,
             isSave:true
           }
          dispatch(toastAction.success("Registro de Empleado" ,'Empleado registrado exitosamente'));
          dispatch(setEmployeeEdit(infoData))
          dispatch(setDataMaster({ loadingDataManager: false }));

          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn('Empleados', data.message));
          dispatch(setDataMaster({ loadingDataManager: false }));
        }else{
          dispatch(toastAction.error('Empleados', data.message));
          dispatch(setDataMaster({ loadingDataManager: false }));
        }
      })
      .catch((error)=>{
        dispatch(setDataMaster({ loadingDataManager: false }));
      });
  };
}

const getDataMaster = (title,id) => {
  return (dispatch) => {
    dispatch(setDataMaster({ loadingDataManager: true }));
    EmployeesService.getDataMaster()
      .then(({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          let { countries, employeeTypes, employees, isAcademicDegree, 
            isEmployeeSituation, maritalStatus, positions, departments,
            cities , districts , isDocumentType , isGender ,  branches , workDays , areas, dynamicFields } = data.data;
          let infoData = {
            loadingDataManager: false,
            dataManager: {
              employees: employees,
              departments: departments,
              nationalities: countries,
              employeesTypes: employeeTypes,
              instructions: isAcademicDegree,
              positions: positions,
              statusCivil: maritalStatus,
              status: isEmployeeSituation,
              cities: cities,
              gender:isGender,
              districts:districts,
              documentsTypes:isDocumentType,
              branches:branches,
              workDays:workDays,
              areas:areas,
              dynamicFields: dynamicFields
            }
          }
          dispatch(setDataMaster(infoData));
          dispatch(getEmployees({}))
          if(title!==undefined && title.title === "Editar Empleado"){
            dispatch(getEmployeeId(id));
          }
          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.success('Empleados', data.message));
        }
      })
      .catch((error)=>{
        dispatch(setDataMaster({ loadingDataManager: false }));
      });
  };
}

const getDataMasterSearch = (title,id) => {
  return (dispatch) => {
    dispatch(setDataMaster({ loadingDataManager: true }));
    // EmployeesService.getDataMaster()
    EmployeesService.getDataMasterSearch()
      .then(({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          let { areas, countries,  employees, isAcademicDegree, maritalStatus, positions,
            // isEmployeeSituation,  departments, employeeTypes,
            // cities , districts , isDocumentType , isGender ,  branches , workDays  
          } = data.data;
            // console.log(data.data)
          let infoData = {
            loadingDataManager: false,
            dataManager: {
              areas:areas, //* Por Departamento
              positions: positions, //* Por Cargo
              employees: employees, //* Por Gerente 
              // TODO: employeeTypes: Por tipo de empleado
              nationalities: countries, //* Por Nacionalidad
              statusCivil: maritalStatus, //* Estado Civil
              instructions: isAcademicDegree, //* isAcademicDegree
              // TODO: isEmployeeSituation: Por estado
              // departments: departments,
              // employeesTypes: employeeTypes,
              // status: isEmployeeSituation,
              // cities: cities,
              // gender:isGender,
              // districts:districts,
              // documentsTypes:isDocumentType,
              // branches:branches,
              // workDays:workDays,
            }
          }
          dispatch(setDataMaster(infoData));

          // dispatch(getEmployees({})) // Traer data del Search
          if(title!==undefined && title.title === "Editar Empleado"){
            dispatch(getEmployeeId(id));
          }
          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.success('Empleados', data.message));
        }
      })
      .catch((error)=>{
        dispatch(setDataMaster({ loadingDataManager: false }));
      });
  };
}


const setFilterValues = (payload) => {
  return (dispatch) => {
    dispatch({
      type: RrhhConstants.Accion.Employees.SET_FILTER_VALUES,
      payload,
    });
  };
};

const generateReport = () =>{
  return (dispatch,getState) => {
    dispatch(setDataMaster({ loadingDataManager: true }));
    let { values } = getState().employeeReducer.employeeFilter;
    let fields = getFields(values);
    EmployeesService.generateReport(fields).then(({data}) =>{
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      });
      FileSaver.saveAs(blob, 'Empleados.xls');
      dispatch(setDataMaster({ loadingDataManager: false }));
      return;
    }).catch((err) =>{
      dispatch(setDataMaster({ loadingDataManager: false }));
    });
  }
}

const getFields = (values) => {
  let fields = {};
  values.forEach(value => {
    if (value.field === "from" || value.field === "to") {
      fields[value.field] = moment.parseZone(value.date).utc().format();
    } else {
      if(value.field !== "names")
        fields[value.field] = parseInt(value.id)
      else
        fields[value.field] = value.value
    }
  })
  return fields;
}

const removeFilterValues = (field) => {
  return (dispatch, getState) => {
    let { values } = getState().employeeReducer.employeeFilter;
    let payload = [];
    if (values.length > 0)
      payload = values.filter((val) => val.field !== field);
    let fields = getFields(payload);
    dispatch(EmployeesAction.getEmployees(fields));
    dispatch({
      type: RrhhConstants.Accion.Employees.REMOVE_FILTER_VALUES,
      payload,
    });
  };
};

const deleteEmployeId = (id) =>{
  return (dispatch) => {
    dispatch(setEmployees({ loading: true }));
    EmployeesService.deleteEmployeId(id).then(({data}) =>{
      if (data.status === CoreConstants.HttpResponse.OK) {
        dispatch(toastAction.success('Empleado', "Empleado eliminado exitosamente"));
        dispatch(getEmployees({}))
        return;
      } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
        dispatch(toastAction.warn('Empleado', data.message));
        dispatch(setEmployees({ loading: false }));
      }else{
        dispatch(toastAction.error('Empleado', data.message));
        dispatch(setEmployees({ loading: false }));
      }
    }).catch((error)=>{
      dispatch(setEmployees({ loading: false }));
    });
  }
}

const getEmployeeId = (ID) => {
  return (dispatch) => {
    dispatch(setDataMaster({ loadingDataManager: true }));
    EmployeesService.getEmployeeId(ID)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          // const employee = data.data.employee;
          // employee.dynamicFieldValues = data.data?.dynamicData
          const { dynamicData, ...restData } = data.data
          restData.dynamicFieldValues = data.data?.dynamicData
          
          let info = {
            // dataEmployee:data.data,
            dataEmployee:restData,
            loadingSave: false,
          };
          dispatch(setEmployeeEdit(info))
          dispatch(setDataMaster({ loadingDataManager: false }));
          if(data.data.positionId){
            dispatch(getDataDepartmentGerente(data.data.positionId))
          }
        } else if (data.Status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn('Empleado', data.message));
          dispatch(setDataMaster({ loadingDataManager: false }));
        }else{
          dispatch(toastAction.error('Empleado', data.message));
          dispatch(setDataMaster({ loadingDataManager: false }));
        }
      })
      .catch((error)=>{
        dispatch(setDataMaster({ loadingDataManager: false }));
      });
  };
}


const getFilterValues = (fields) =>{

  let fieldsTemp = {}
  fields.forEach(field => {
    if(field.date){
      fieldsTemp[field.field] =moment.parseZone(field.date).utc().format()
    }else
      fieldsTemp[field.field] =parseInt(field.id)
  })
  fields = fieldsTemp

  
  return  {
    fields,
  }
}
const searchTable = ({page}) =>{
  return (dispatch,getState) =>{
    let { values } = getState().employeeReducer.employeeFilter;
    const {fields } = getFilterValues(values);
    fields.page = page;
    dispatch(getEmployees({fields}))
  }
}

const clearValues = () => {
  return (dispatch) =>{
    let payload = []
    dispatch({
      type: RrhhConstants.Accion.Employees.SET_FILTER_VALUES,
      payload,
    });
  }
}

const downloadTemplate = () => {

  return (dispatch) => {
    dispatch(setDataMaster({ loadingDataTemplate: true }));
    EmployeesService.downloadTemplate().then(({data}) =>{
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      });
      FileSaver.saveAs(blob, 'Empleados.xlsx');
      dispatch(setDataMaster({ loadingDataTemplate: false }));
      return;
    }).catch((err) =>{
      dispatch(setDataMaster({ loadingDataTemplate: false }));
    });
  }
}

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: RrhhConstants.Accion.Employees.RESET_AFTER_SAVED });
};

const uploadBatchInfo = (file) => {

  const setUpload = (isLoading, data, errors) =>{
    return (dispatch) =>
      dispatch({
        type: RrhhConstants.Accion.Employees.UPLOAD_BATCH_INFO,
        payload: isLoading, data, errors
      });
  }

  return (dispatch) => {
    dispatch(setUpload({ isLoading: true, data: null, errors: [] }));
    EmployeesService.uploadBatchInfo(file)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setUpload({ 
            isLoading: false, 
            data: data.data.pageEmployees,
            errors: data.data.errors,
          }));

          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn('Empleados', data.message));
          dispatch(setUpload({ isLoading: false, data: null }));
        }else{
          dispatch(toastAction.error('Empleados', data.message));
          dispatch(setUpload({ isLoading: false, data: null, errors: [] }));
        }
      }).catch((error)=>{
        dispatch(setUpload({ isLoading: false, data: null, errors: [] }));
      });
  };
}

const saveEmployeeBulk = (data, lastRow) => {
  const setSave = (isSaved) =>{
    return (dispatch) =>
      dispatch({
        type: RrhhConstants.Accion.Employees.SAVE_EMPLOYEES_BULK,
        payload: isSaved
      });
  }

  return (dispatch) => {
    if (lastRow) dispatch(setSave({ isSaved: false }));
    EmployeesService.saveEmployee(data)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          if (lastRow) {
            dispatch(setSave({ isSaved: true }));
            // dispatch(toastAction.success("Empleado" ,'Registro exitoso'));
          } 
          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn('Empleados', data.message));
          dispatch(setSave({ isSaved: false }));
        }else{
          dispatch(toastAction.error('Empleados', data.message));
          dispatch(setSave({ isSaved: false }));
        }
      }).catch((error)=>{
        dispatch(setSave({ isSaved: false }));
      });
  };
}



export const EmployeesAction = {
  clearValues,
  getDataMaster,
  getDataMasterSearch,
  getEmployees,
  toggleModalFilters,
  setFilterValues,
  getEmployeeId,
  removeFilterValues,
  modalEmployee,
  getDataDepartmentGerente,
  setEmployeeEdit,
  saveEmployee,
  generateReport,
  deleteEmployeId,
  searchTable,
  resetAfterSaved,
  downloadTemplate,
  uploadBatchInfo,
  saveEmployeeBulk,
};
