import { RiskModelAction } from 'features/riskManagement/store/actions/RiskModelAction';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

const InputTextCustom = (rowData, control) => {
	return <Controller
	control={control}
	name={`condition_name_${rowData.id}`}
	rules={{
		required: 'Nombre de condición es requerido',	
	}}
	render={({field: { onChange, value }, fieldState: { error },}) => (
		<InputText
			value={value || ""}
			onChange={onChange} 
			className={error ? 'p-invalid w-full' : 'w-full'}
			maxLength={250}
		/>
		)}
	/>
}

const DropDownCustomCondition = (rowData, control, type, conditions) => {
	return <Controller
	control={control}
	name={`condition_${type}ConditionTypeId_${rowData.id}`}
	rules={type === 'left' ? {
		required: 'Tipo de condición es requerido',	
	} : {}}
	render={({field: { onChange, value }, fieldState: { error }}) => (		
		<Dropdown 
			showClear
			value={value}
			onChange={onChange}
			options={conditions}
			optionLabel='description'
			optionValue='id'
			className={error ? 'p-invalid w-full' : 'w-full'}
		/>
	)}
	/>
}

const InputNumberCustom = (rowData, control, type, valuesFields) => {
	return (
		<Controller
		control={control}
		name={`condition_${type}Value_${rowData.id}`}
		rules={ type === 'left' ? {
			required: 'Valor de condición es requerido',	
		} : {}}
		render={({field: { onChange, value }, fieldState: { error },}) => (
			<InputNumber 
				value={value}
				onValueChange={(e) => onChange(e.value)} 
				mode="decimal"
				locale="en-US"
				useGrouping={false}
				maxFractionDigits={(valuesFields.position && Number(valuesFields.position)) || 0} 
				className={error ? 'p-invalid w-12' : 'w-12'}
			/>)}
		/>
	)
}

const DropDownCustomOperator = (rowData, control, logicalOperators) => {
	return (
		<Controller
		control={control}
		name={`condition_logicalOperatorId_${rowData.id}`}
		// rules={{
		// 	required: 'Operador de condición es requerido',	
		// }}
		render={({field: { onChange, value }, fieldState: { error }}) => (
			<Dropdown
				showClear
				options={logicalOperators}
				optionLabel= 'description'
				optionValue='id'
				onChange={onChange}
				value={value}
				className={error ? 'p-invalid w-full' : 'w-full'}
			/>
		)}
	/>
	)
}

const DropDownCustomColor = (rowData, control, colors) => {
	return (
		<Controller
		control={control}
		name={`condition_colorId_${rowData.id}`}
		rules={{
			required: 'Color de condición es requerido',	
		}}
		render={({field: { onChange, value }, fieldState: { error }}) => (
			<Dropdown 
				showClear
				options={colors}
				optionLabel='description'
				optionValue='id'
				itemTemplate={(option) => <div className='w-6 h-6' style={{backgroundColor: option?.description}}></div>}
				valueTemplate={(option) => <div className='w-6 h-6' style={{backgroundColor: option?.description}}></div>}
				value={value}
				onChange={onChange}
				className={error ? 'p-invalid w-full' : 'w-full'}
			/>
		)}
	/>
	)
}

const DropDownVincular = (rowData, control, processes) => {
	return (
		<Controller
		control={control}
		name={`condition_processId_${rowData.id}`}
		render={({field: { onChange, value }, fieldState: { error }}) => (
			<Dropdown
				showClear
				options={processes}
				optionLabel= 'name'
				optionValue='id'
				onChange={onChange}
				value={value}
				className={error ? 'p-invalid w-full' : 'w-full'}
			/>
		)}
	/>
	)
}

const ConditionComponent = ({control, valuesFields, setValue}) => {

	const dispatch = useDispatch()
	const { data } = useSelector(state => state.riskModelReducer.condition)
	const { conditions, colors, logicalOperators, processes	} = useSelector(state => state.riskModelReducer.dataMasterEvaluation.data)

	const addCondition = () => {
		dispatch(RiskModelAction.addCondition())
	}

	const ConditionDelete = (criteriaSelect) => {
		
		return (
			<i
			onClick={() => {
				dispatch(RiskModelAction.deleteCondition(criteriaSelect))
				setValue(`condition_name_${criteriaSelect.id}`, undefined)
				setValue(`condition_leftConditionTypeId_${criteriaSelect.id}`, undefined)
				setValue(`condition_leftValue_${criteriaSelect.id}`, undefined)
				setValue(`condition_logicalOperatorId_${criteriaSelect.id}`, undefined)
				setValue(`condition_rightConditionTypeId_${criteriaSelect.id}`, undefined)
				setValue(`condition_rightValue_${criteriaSelect.id}`, undefined)
				setValue(`condition_colorId_${criteriaSelect.id}`, undefined)
			}}
			className="pi pi-trash cursor-pointer"
		></i>
		)
	}

	return (
		<div>
			<Button
				label='Añadir Condición'
				icon='pi pi-plus'
				className='sig-button sig-primary mb-4'
				onClick={addCondition}
			/>
			<DataTable 
				value={data}
				responsiveLayout="scroll"
				dataKey="id"
				// autoLayout
			>
				<Column field="name" body={(rowData)=>InputTextCustom(rowData, control)} header={<div style={{minWidth: '200px'}}>Nombre</div>}/>
				<Column field="firstCondition" body={(rowData)=>DropDownCustomCondition(rowData, control, 'left', conditions)} header="Condición"/>
				<Column field="firstValue" body={(rowData)=>InputNumberCustom(rowData, control, 'left', valuesFields)} header="Valor"/>
				<Column field="operator" body={(rowData)=>DropDownCustomOperator(rowData, control, logicalOperators)} header="Operador"/>
				<Column field="secondCondition" body={(rowData)=>DropDownCustomCondition(rowData, control, 'right', conditions)} header="Condición"/>
				<Column field="secondValue" body={(rowData)=>InputNumberCustom(rowData, control, 'right', valuesFields)} header="Valor"/>
				<Column field="color" body={(rowData)=>DropDownCustomColor(rowData, control, colors)} header="Color"/>
				<Column field="vincular" body={(rowData)=>DropDownVincular(rowData, control, processes)} header="Vincular"/>
				<Column body={ConditionDelete} header=""/>
			</DataTable>
		</div>
	);
};

export default ConditionComponent;