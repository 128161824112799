import { locale, addLocale } from 'primereact/api';
import { AppRouting } from './AppRouting';
import localeTexts from '../shared/utils/localeTexts';
import moment from 'moment';
import 'moment/locale/es';
function App() {

  const userLocale =  navigator.languages && navigator.languages.length
  ? navigator.languages[0]
  : navigator.language;

  if(userLocale?.substring(0,2)==="es"){
    addLocale('es', localeTexts);
    locale('es');
    moment.locale('es');
  }else{
    locale('en');
    moment.locale('en');
  }

  return <AppRouting />;
}

export default App;
