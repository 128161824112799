import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { DynamicFieldsAction } from "features/configuration/store/actions/DynamicFieldsAction"; 
import { useForm } from "shared/hooks/useForm";
import { CoreConstants } from "core/commons/CoreConstants";

export const useAdvancedFilterDynamicFields = () => {
  const dispatch = useDispatch();

  const dynamicFieldsLoading = useSelector(
    (state) => state.dynamicFieldsReducer.dynamicFields.loading
  );
  const reportLoading = useSelector(
    (state) => state.dynamicFieldsReducer.downloadReport.loading
  );

  const dataMaster = useSelector(
    (state) => state.dynamicFieldsReducer.dataMaster.data
  );
  const showRemovePinerCompany = dataMaster.subscription?.isSystem

  const { values, showModal } = useSelector(
    (state) => state.dynamicFieldsReducer.advancedFilter
  );

  const showPiners = values.length > 0;

  const inititalValues = {
    entityName: null,
    companyId: null,
    statusId: null,
  };
  const [formValues, handleChange, reset] = useForm(inititalValues);
  const [errors, setErrors] = useState([]);

  const onToggleModal = (showModal) => {
    setErrors([]);
    dispatch(DynamicFieldsAction.setAdvancedFilter({ showModal }));
  };
  const clearFields = () => {
    setErrors([]);
    let values = []
    if(!showRemovePinerCompany) {
      const currentCompany = dataMaster.companies[0]
      values = [{
        field: "companyId",
        value: currentCompany.businessName,
        option: {
            id: currentCompany.id,
            businessName: currentCompany.businessName
        }
      }]
      inititalValues.companyId = dataMaster.companies[0]
    }

    const currentValues = {}
    values.forEach((piner) => {
      if (piner.option) {
        currentValues[piner.field] = piner.option.id;
      } else if (piner.field.includes("Date")) {
        currentValues[piner.field] = piner.date;
      } else {
        currentValues[piner.field] = piner.value;
      }
    });

    reset(inititalValues);
    dispatch(
      DynamicFieldsAction.setAdvancedFilter({
        showModal: false,
        values
      })
    );
    dispatch(
      DynamicFieldsAction.getDynamicFields({ fields: currentValues, change: true })
    );
  };

  useEffect(() => {
    if (values.length === 0) {
      // clearFields();
      setErrors([]);
    }
  }, [values]);

  const buildFilterValues = () => {
    const currentValues = {};
    const currentPiners = [...values];
    for (let valueKey in formValues) {
      const key = valueKey.toLocaleLowerCase();
      const field = valueKey;
      const hasValue = formValues[valueKey];
      if (hasValue) {
        if (key.includes("date")) {
          const value = moment(formValues[valueKey]).format(
            CoreConstants.Format.DDMMYYYY
          );
          const date = formValues[valueKey];
          // currentValues[field] = date;
          const index = currentPiners
            .map((piner) => piner.field)
            .indexOf(field);
          index === -1
            ? currentPiners.push({ field, value, date })
            : currentPiners.splice(index, 1, { field, value, date });
        } else if (key.includes("id")) {
          const option = formValues[valueKey];
          let value = "";
          if (formValues[valueKey].description) {
            value = formValues[valueKey].description;
          } else if (formValues[valueKey].businessName) {
            value = formValues[valueKey].businessName;
          } else if (formValues[valueKey].fullName) {
            value = formValues[valueKey].fullName;
          } else if (formValues[valueKey].name) {
            value = formValues[valueKey].name;
          }
          // currentValues[field] = option.id;
          const index = currentPiners
            .map((piner) => piner.field)
            .indexOf(field);
          index === -1
            ? currentPiners.push({ field, value, option })
            : currentPiners.splice(index, 1, { field, value, option });
        } else if (key === "iscompleted") {
          const option = formValues[valueKey];
          const value = formValues[valueKey].description;
          // currentValues[field] = option.code === "TCO";
          const index = currentPiners
            .map((piner) => piner.field)
            .indexOf(field);
          index === -1
            ? currentPiners.push({ field, value, option })
            : currentPiners.splice(index, 1, { field, value, option });
        } else {
          const value = formValues[valueKey];
          // currentValues[field] = value;
          const index = currentPiners
            .map((piner) => piner.field)
            .indexOf(field);
          index === -1
            ? currentPiners.push({ field, value })
            : currentPiners.splice(index, 1, { field, value });
        }
      }
    }

    currentPiners.forEach((piner) => {
      if (piner.option) {
        currentValues[piner.field] = piner.option.id;
      } else if (piner.field.includes("Date")) {
        currentValues[piner.field] = piner.date;
      } else {
        currentValues[piner.field] = piner.value;
      }
    });
    return {
      currentPiners,
      currentValues,
    };
  };

  const onAdvancedFilter = (e) => {
    e.preventDefault();
    setErrors([]);
    // if (formValues.documentNo) {
    //   const document = formValues.documentNo + "";

    //   if (document.length > 50) {
    //     setErrors(["El campo RUC admite 50 dígitos como máximo"]);
    //     return;
    //   }
    // }
    let { currentValues, currentPiners } = buildFilterValues();
    // const currentSearchValue =
    //   values.find(({ field }) => field === "names") ?? {};
    // if (Object.values(currentSearchValue).length > 0) {
    //   currentValues.names = currentSearchValue.value;
    //   currentPiners = [
    //     ...currentPiners.filter((p) => p.field !== "names"),
    //     currentSearchValue,
    //   ];
    // }

    dispatch(
      DynamicFieldsAction.setAdvancedFilter({
        showModal: false,
        values: currentPiners,
      })
    );
    dispatch(
      DynamicFieldsAction.getDynamicFields({ fields: currentValues, change: true })
    );
  };
  const removePiner = (field) => {
    dispatch(DynamicFieldsAction.removeFilterValues(field));
  };

  return {
    onToggleModal,
    clearFields,
    showModal,
    onAdvancedFilter,
    dynamicFieldsLoading,
    reportLoading,
    dataMaster,
    formValues,
    handleChange,
    showPiners,
    piners: values,
    removePiner,
    errors,
    showRemovePinerCompany,
  };
};
