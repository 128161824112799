import React, { useEffect, useState } from 'react'
import { ControllerCoin } from './controllers/ControllerCoin'
import { ControllerConectionProcess } from './controllers/ControllerConectionProcess'
import { ControllerDropDown } from './controllers/ControllerDropDown'
import { ControllerSelectionMultiple } from './controllers/ControllerSelectionMultiple'
import { ControllerSelectionSimple } from './controllers/ControllerSelectionSimple'
import { ControllerTable } from './controllers/ControllerTable'

export const Modal2 = ({positionSoruce, listOptions, inputSelected, displayExtraModal, setDisplayExtraModal, dataExtraModal, setDataExtraModal, dinamicStage, setDinamicStage, setInputSelected,destinationControl}) => {

  const [idTypeControl, setIdTypeControl] = useState(null)
  const [titleModal, setTitleModal] = useState('')
  // if(displayExtraModal.mode==='editar' && dataExtraModal.typeOptionId===null){
  //   setDataExtraModal(inputSelected)
  // }

  useEffect(()=>{
    listOptions?.forEach((item)=>{
      if(item.code===dataExtraModal.typeOptionId || item.code===inputSelected?.typeOptionId){
        setIdTypeControl(item.id)
        setTitleModal(item.name.toUpperCase())
      }
    })
  },[dataExtraModal?.typeOptionId])

  // useEffect(()=>{
    if(displayExtraModal.mode==='editar' && dataExtraModal.typeOptionId===null && inputSelected){
      setDataExtraModal(inputSelected)
      setInputSelected(null)
    }
  // },[])

  const normalizeIndentifier = (str) => {
    str = (((str.trim()).replace(/ /g,"_")).toLowerCase())
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }


  return (
    <>
      {
        dataExtraModal.typeOptionId==='LISTA_DESPLEGABLE'&&(
          <ControllerDropDown
          titleModal={titleModal}
          listOptions={listOptions}
          positionSoruce={positionSoruce}
          setInputSelected={setInputSelected}
          displayExtraModal={displayExtraModal}
          setDisplayExtraModal={setDisplayExtraModal}
          dataExtraModal={dataExtraModal}
          setDataExtraModal={setDataExtraModal}
          dinamicStage={dinamicStage}
          idTypeControl={idTypeControl}
          normalizeIndentifier={normalizeIndentifier}
          setDinamicStage={setDinamicStage}
          destinationControl={destinationControl}
          />
        )
      }
      {
        dataExtraModal.typeOptionId==='SELECCION_MULTIPLE'&&(
          <ControllerSelectionMultiple
            titleModal={titleModal}
            listOptions={listOptions}
            positionSoruce={positionSoruce}
            setInputSelected={setInputSelected}
            displayExtraModal={displayExtraModal}
            setDisplayExtraModal={setDisplayExtraModal}
            dataExtraModal={dataExtraModal}
            setDataExtraModal={setDataExtraModal}
            dinamicStage={dinamicStage}
            idTypeControl={idTypeControl}
            normalizeIndentifier={normalizeIndentifier}
            setDinamicStage={setDinamicStage}
            destinationControl={destinationControl} />
        )
      }
      {
        dataExtraModal.typeOptionId==='SELECCION_SIMPLE'&&(
          <ControllerSelectionSimple
            titleModal={titleModal}
            listOptions={listOptions}
            positionSoruce={positionSoruce}
            setInputSelected={setInputSelected}
            displayExtraModal={displayExtraModal}
            setDisplayExtraModal={setDisplayExtraModal}
            dataExtraModal={dataExtraModal}
            setDataExtraModal={setDataExtraModal}
            dinamicStage={dinamicStage}
            idTypeControl={idTypeControl}
            normalizeIndentifier={normalizeIndentifier}
            setDinamicStage={setDinamicStage}
            destinationControl={destinationControl} />
        )
      }
      {
        dataExtraModal.typeOptionId==='TABLA'&&(
          <ControllerTable
            titleModal={titleModal}
            listOptions={listOptions}
            positionSoruce={positionSoruce}
            setInputSelected={setInputSelected}
            displayExtraModal={displayExtraModal}
            setDisplayExtraModal={setDisplayExtraModal}
            dataExtraModal={dataExtraModal}
            setDataExtraModal={setDataExtraModal}
            dinamicStage={dinamicStage}
            idTypeControl={idTypeControl}
            normalizeIndentifier={normalizeIndentifier}
            setDinamicStage={setDinamicStage}
            destinationControl={destinationControl} />
        )
      }
      {
        dataExtraModal.typeOptionId==='MONEDA'&&(
          <ControllerCoin
            titleModal={titleModal}
            listOptions={listOptions}
            positionSoruce={positionSoruce}
            setInputSelected={setInputSelected}
            displayExtraModal={displayExtraModal}
            setDisplayExtraModal={setDisplayExtraModal}
            dataExtraModal={dataExtraModal}
            setDataExtraModal={setDataExtraModal}
            dinamicStage={dinamicStage}
            idTypeControl={idTypeControl}
            normalizeIndentifier={normalizeIndentifier}
            setDinamicStage={setDinamicStage}
            destinationControl={destinationControl} />
        )
      }
      {/* {
        dataExtraModal.typeOptionId==='MONEDA'&&(
          <ControllerConectionProcess
            setInputSelected={setInputSelected}
            displayExtraModal={displayExtraModal}
            setDisplayExtraModal={setDisplayExtraModal}
            dataExtraModal={dataExtraModal}
            setDataExtraModal={setDataExtraModal}
            dinamicStage={dinamicStage}
            idTypeControl={idTypeControl}
            setDinamicStage={setDinamicStage} />
        )
      } */}
    </>
  )
}