import { useEffect  , Fragment } from 'react'
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { useHistory } from 'react-router';
import { Button } from 'primereact/button';
import SitesFilter from 'features/maintenance/components/Sites/SitesFilter';
import SitesTableComponent from 'features/maintenance/components/Sites/SitesTableComponent';
import { useClaimSites } from 'features/maintenance/hooks/useClaimSites';
const Sites = () =>{
  const { updateTitle } = useSetTitlePage();
  const history = useHistory();

  useEffect(() => {
    updateTitle({
        title: 'Mantenimiento',
        subtitle: 'Sede',
    });
  });

  const { isSearch, isDownload, isCreate } = useClaimSites()

  return (
    <Fragment>
      {
        (isSearch||isDownload)&&(
          <SitesFilter />
        )
       }
      {
        isCreate&&(
          <Button
            icon="pi pi-plus"
            type="button"
            label="Nuevo"
            className="sig-button sig-dark mt-4"
            onClick={() => {
              history.push('/mantenimiento/sede/nuevo');
            }}
          />
        )
      }
      <SitesTableComponent />
    </Fragment>
  )
}


export default Sites