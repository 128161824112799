import { useState , Fragment , useRef} from "react";
import { Link, useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu  } from "primereact/menu";
import { Paginator } from "primereact/paginator";
import { ConfirmDialog } from "primereact/confirmdialog";
import useSite from "features/maintenance/hooks/Sites/useSite";
import useTable from 'features/maintenance/hooks/Sites/useTable';
import moment from "moment";
import { CoreConstants } from "core/commons/CoreConstants";
import { useClaimSites } from "features/maintenance/hooks/useClaimSites";
const SitesTableComponent = () =>{
  const [visible,setVisible] = useState(false);
  const history = useHistory();
  const [idLocation,setidLocation] = useState(0);

  const { isView ,isEdit, isDelete } = useClaimSites()

  const {
    data,
    removeSite,
    pagination
  } = useSite()

  const {
    onPageChange,
    firstTable,
    rowsTable
  } = useTable();

  let items = [
  {
      label: 'Editar',
      icon: 'pi pi-pencil',
      command: () => {
        history.push('/mantenimiento/sede/editar/'+idLocation)
      }
  },
  {
      label: 'Eliminar',
      icon: 'pi pi-times',
      command: () => {
        setVisible(true)
      }
  }
];
    if (!isEdit)
        items = items.filter(c => c.label !== 'Editar');
    if (!isDelete)
        items = items.filter(c => c.label !== 'Eliminar');

    const getViewField = (rowData) => {
        if (isView)
          return <Link className="underline text-blue-700" to={{ pathname: `/mantenimiento/sede/visualizar/` + rowData.id }} >{rowData.name}</Link>
        else
          return rowData.name;
      }

const menu = useRef(null);
const getCreationDate = (rowData) => { return moment(rowData.creationDate).format(CoreConstants.Format.LOCALE); }
const columns = [
  { field: 'address', header: 'Dirección' },
  { field: 'status', header: 'Estado' },
  { field: 'creationDate', header: 'Fecha Creación',body: getCreationDate },
];

const [selectedColumns, setSelectedColumns] = useState(columns);
  const columnComponents = selectedColumns.map(col => {
    return <Column key={col.field} field={col.field} header={col.header} sortable />;
  });

const actionBodyTemplate = (rowData) => {
  if(isEdit||isDelete){
    return <i
        key={rowData.locationId}
        className="pi pi-ellipsis-v cursor-pointer"
        onClick={(event) => {
          setidLocation(rowData.id);
          menu.current.toggle(event)
        }}></i>
  }else{
    return <div></div>
  }
}

  return (
    <Fragment>
      <div className="table-main table-roles mt-3 ">
        <DataTable
            value={data}
            responsiveLayout="scroll"
            emptyMessage="No se encontraron Sedes"
            rows={rowsTable}
            // first={firstTable}
            size="small"
            stripedRows
            > 
            <Column field="name" header="Sede" body={getViewField} sortable />
            {columnComponents}
            <Column  body={actionBodyTemplate} ></Column>
        </DataTable>
        <Paginator
          className="paginator-custom mt-4"
          first={firstTable}
          totalRecords={pagination?.rowCount}
          rows={rowsTable}
          onPageChange={onPageChange}
          // rowsPerPageOptions={[5, 10, 15]}
          // onPageChange={onChangePage}
        ></Paginator>
        <Menu model={items} popup ref={menu} id="popup_menu" />
        <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea eliminar la sede?"
          header="Eliminar"
          icon="pi pi-exclamation-triangle"
          accept={() => removeSite(idLocation)} />
      </div>
    </Fragment>
  )
}

export default SitesTableComponent


