import { CoreConstants } from 'core/commons/CoreConstants';
import moment from 'moment';
import { toastAction } from "core/store/actions/ToastAction";
import FileSaver from 'file-saver';
import { ManagmentService } from "features/indicators/services/ManagmentService";
import { IndicatorsConstants } from "features/indicators/commons/IndicatorsConstans";

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: IndicatorsConstants.Accion.Managment.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: IndicatorsConstants.Accion.Managment.RESET_AFTER_SAVED });
};

const getRestoreDataIndicator = () => {
  return (dispatch) =>
    dispatch({ type: IndicatorsConstants.Accion.Managment.RESET_DATA_INDICATOR });
};


const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload;

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if(advancedFilter.filter.name)
      pines.push({
        field: 'name',
        value: advancedFilter.filter.name
      })

    if(advancedFilter.filter.roleId)
      pines.push({
        field: 'roleId',
        value: advancedFilter.filter.roleId?.name
      })

    advancedFilter.values = pines;
    dispatch({
      type: IndicatorsConstants.Accion.Managment.UPDATE_FILTER,
      advancedFilter,
    });
  };
};



const search = () => {
  const setManagments = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Managment.SEARCH_MANAGMENTS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const managments = getState().managmentReducer.managments;
    setManagments({
      dispatch: dispatch,
      isLoading: true,
      data: managments.data,
    });

    const criteria = {};
    const advancedFilter = getState().managmentReducer.advancedFilter;
    const filter = getState().managmentReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.roleId = filter.roleId?.id
    criteria.name = filter.name

    criteria.to = filter.to;
    criteria.form = filter.from;

    ManagmentService.searchManagment(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setManagments({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Managment', data.message));
          setManagments({ dispatch: dispatch, isLoading: false, data: null });
        }else{
          setManagments({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Managment', data.message));
        }
      },
      () => {
        setManagments({ dispatch: dispatch, isLoading: false, data: null });
      }
    ).catch((error)=>{
    });
  };
};


const getManagmentById = (managmentId) => {
  const setManagment = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Managment.GET_MANAGMENT_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setManagment({ dispatch: dispatch, isLoading: true });
    ManagmentService.getManagmentById(managmentId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setManagment({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setManagment({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Managment', data.message));
        }else{
          setManagment({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.error('Managment', data.message));
        }
      },
      () => {
        setManagment({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};

const getdataTableIndicatorById = (id) => {
  const setManagmentTable = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Managment.GET_MANAGMENT_TABLE_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setManagmentTable({ dispatch: dispatch, isLoading: true });
    ManagmentService.getdataTableIndicatorById(id).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setManagmentTable({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setManagmentTable({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Managment', data.message));
        }else{
          setManagmentTable({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.error('Managment', data.message));
        }
      })
      .catch((error)=>{
        setManagmentTable({ dispatch: dispatch, isLoading: false });
      });
  }
};

const getdataGraphics = (id, from, to) => {
  const setdataGraphics  = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Managment.GET_MANAGMENT_GRAPHICS_BY_ID,
      payload: { isLoading, data },
    });
  };
  let dateFrom = from
  let dateTo = to
  if(from){
    dateFrom = moment(from).format()
  }
  if(to){
    dateTo = moment(from).format()
  }

  return (dispatch, getState) => {
    setdataGraphics({ dispatch: dispatch, isLoading: true });
    ManagmentService.getdataGraphics(id, dateFrom, dateTo).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setdataGraphics({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setdataGraphics({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Managment', data.message));
        }else{
          setdataGraphics({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.error('Managment', data.message));
        }
      })
      .catch((error)=>{
        setdataGraphics({ dispatch: dispatch, isLoading: false });
      });
  };
};


const saveIndicators = (managment) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentPipeline }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Managment.SAVE_MANAGMENT,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    const currentManagment = getState().managmentReducer.managment;
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
      currentManagment: currentManagment,
    });
    ManagmentService.saveIndicators(managment).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Managment', 'El Indicador se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentManagment, });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Managment', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentManagment });
        }else{
          dispatch(toastAction.error('Managment', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }
      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};

const deleteManagment = (managmentId) => {

  return (dispatch, getState) => {

    ManagmentService.deleteManagment(managmentId).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Managment', 'El Indicador se eliminó correctamente'));
          dispatch({ type: IndicatorsConstants.Accion.Managment.DELETE_MANAGMENT, payload: { isDeleted: true } });
        } else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Managment', data.message));
          dispatch({ type: IndicatorsConstants.Accion.Managment.DELETE_MANAGMENT, payload: { isDeleted: false } });
        }else{
          dispatch(toastAction.error('Managment', data.message));
          dispatch({ type: IndicatorsConstants.Accion.Managment.DELETE_MANAGMENT, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: IndicatorsConstants.Accion.Managment.DELETE_MANAGMENT, payload: { isDeleted: false } });
      }
    ).catch((error)=>{
    });
  };
};

const getDataMaster = () => {
  const setDataMaster = ({dispatch, data, isLoading}) => {
    dispatch({
      type: IndicatorsConstants.Accion.Managment.GET_DATAMASTER,
      payload: {data, isLoading}
    })
  }

  return (dispatch) => {
    setDataMaster({dispatch: dispatch, isLoading: true})
    ManagmentService.getDataMaster().then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          // dispatch(toastAction.success('Managment', 'La data se cargó correctamente'));
          setDataMaster({dispatch: dispatch, data: data.data, isLoading: false})
        } else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn('Managment', data.message));
          setDataMaster({dispatch: dispatch, isLoading: false})
        }else{
          dispatch(toastAction.error('Managment', data.message));
          setDataMaster({dispatch: dispatch, isLoading: false})
        }
      }
    )
    .catch((error)=>{
      setDataMaster({dispatch: dispatch, isLoading: false})
    });
  };
};

const generateReport = (payload) => {
  const setReport = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Managment.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setReport({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().managmentReducer.advancedFilter.filter;

    criteria.roleId = filter.roleId?.id;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    ManagmentService.generateReport(criteria)
      .then(({ data }) => {
        setReport({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Managment.xls');
      })
      .catch((err) => {
        setReport({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Managment', 'Hubo un error al descargar el archivo'));
      });
  };
};

export const ManagmentAction = {
  toggleAdvancedFilter,
  updateFilter,
  search,
  saveIndicators,
  deleteManagment,
  generateReport,
  resetAfterSaved,
  getManagmentById,
  getDataMaster,
  getdataTableIndicatorById,
  getRestoreDataIndicator,
  getdataGraphics
}
