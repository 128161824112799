import { apiService } from 'core/services/ApiService';

const currentModule = 'customer/api/Project';

const getDataMaster = () => {
  return apiService.Post(`${currentModule}/GetDataMaster`);
};

const search = (fields) => {
  let initialFields ={
    "page": 1,
    "pageSize": 10,
    "columnOrder": "name",
    "order": "desc",
    "name": null,
    "customerId": null,
    "fromDate": null,
    "toDate": null,
    "responsibleId": null,
    "amount": null,
    "statusId": null,
    "situationId": null
  }

  initialFields = {...initialFields,...fields}
  return apiService.Post(`${currentModule}/Search`,initialFields)
}

const getProjectById = (id) => {
  return apiService.Get(`${currentModule}/GetProjectById?id=${id}`)  
}

const deleteProjectById = (id) => {
  return apiService.Delete(`${currentModule}/Delete?id=${id}`)  
}

const generateViewReport = (id) => {
  return apiService.Get(`${currentModule}/GenerateViewProjectReport?projectId=${id}`, true);
}

const generateViewProjectReport = (id) => {
  return apiService.Get(`${currentModule}/GenerateViewProjectReport?projectId=${id}`, true);
}

const getTaskDeliverable = (id,page) => {
  let initialFields =  {
    "page": page,
    "pageSize": 10,
    "columnOrder": "title",
    "order": "asc",
    "documentId": id,
    "sourceId":70
   }
   return apiService.Post('customer/api/Task/Search',initialFields)
}

const generateSnapshot = (body) => {
  return apiService.Post(`${currentModule}/GenerateSnapshot`, body);
}

const getSnapshotById = (snapshotId) => {
  return apiService.Get(`${currentModule}/GetSnapshotById?snapshotId=${snapshotId}`);
}

const downloadSnapshot = (payload) => {
  return apiService.Post(`${currentModule}/DownloadSnapshot`, payload, {
    responseType: 'blob',
  });
}

const searchSnapshot = (payload) => {
  return apiService.Post(`${currentModule}/SearchSnapshot`, payload);
}

const downloadSearchSnapshot = (payload) => {
  return apiService.Post(`${currentModule}/DownloadSearchSnapshot`, payload, {
    responseType: 'blob',
  });
}

const deleteSnapshotById = (snapshotId) => {
  return apiService.Delete(`${currentModule}/DeleteSnapshotById?snapshotId=${snapshotId}`);
}

export const ProjectServices = {
  getTaskDeliverable,
  generateViewReport,
  generateViewProjectReport,
  getDataMaster,
  search,
  getProjectById,
  deleteProjectById,
  generateSnapshot,
  getSnapshotById,
  downloadSnapshot,
  searchSnapshot,
  downloadSearchSnapshot,
  deleteSnapshotById,
}