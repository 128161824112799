import { Fragment } from 'react';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import useFilter from 'features/subscription/hooks/useFilter';
import PinerComponent from 'shared/components/Piner/PinerComponent';
import useSubscriptions from 'features/subscription/hooks/useSubscriptions';
import SubscriptionsModalFilterComponent from './SubscriptionsModalFilterComponent'
import { useClaimSubscription } from 'features/subscription/hooks/useClaimSubscription';
const FilterComponent = () => {

  const {
    openModal,
    loadingDataManager,
    search,
    setCustomerName,
    customerName,
    values,
    showPiners,
    removePiner
  } = useFilter()

  const { isSearch, isDownload } = useClaimSubscription()

  const {
    loading,
    generateReport
  } = useSubscriptions()

  const submit = (e) =>{
    e.preventDefault();
    search()
  }
  return (
    <Fragment>
      <Panel header={`${isSearch?"FILTRO POR":"DESCARGA"}`} toggleable>
        <div className="filter-components">
          <form onSubmit={submit}>
            <div className={`w-full ${isSearch?'visible':'invisible'}`}>
              <span className="p-float-label p-input-icon-left w-full">
                <i className="pi pi-search" />
                <InputText
                  id="input-search"
                  name="name"
                  onChange={e => setCustomerName(e.target.value)}
                  value={customerName}/>
                <label htmlFor="input-search">Buscar por suscripción</label>
              </span>
              {showPiners() && (
              <div className="filter-piners mt-3">
                {values.map(({ value, field }, index) => (
                  <PinerComponent
                    name={value}
                    field={field}
                    removePiner={removePiner}
                    key={index}
                  />
                ))}
              </div>
            )}
            </div>
          </form>

            <div className="filter-users-action">
              <div className="flex gap-3 w-full">
                {
                  isSearch&&(
                    <Button
                        icon="pi pi-search"
                        type="button"
                        label="Buscar"
                        onClick={search}
                        loading={loadingDataManager || loading}
                        className="sig-button sig-primary w-full"
                    />
                  )
                }
                {
                  isDownload&&(
                    <Button
                        icon="pi pi-download"
                        type="button"
                        loading={loadingDataManager || loading}
                        onClick={generateReport}
                        disabled={loadingDataManager || loading}
                        label="Descargar"
                        className="sig-button sig-success  w-full"
                    />
                  )
                }
              </div>
              <div className="flex mt-3">
                {
                  isSearch&&(
                    <Button
                        type="button"
                        label="Búsqueda avanzada"
                        className="sig-button  sig-secondary w-full"
                        onClick={openModal}
                    />
                  )
                }
              </div>
           </div>
        </div>
      </Panel>
      <SubscriptionsModalFilterComponent />
    </Fragment>
  )
}

export default FilterComponent;