import { apiService } from "core/services/ApiService";

//* Endpoints Implementadas
const searchSurveys = (criterio) => {
  return apiService.Post("customer/api/Survey/Search", criterio);
}

const generateReport = (payload) => {
  return apiService.Post("customer/api/Survey/GenerateReport", payload, {
    responseType: 'blob',
  })
}

const getDataMaster = () => {
  return apiService.Post("customer/api/Survey/GetDataMaster");
}

const saveSurvey = (data) => {
  return apiService.Post("customer/api/Survey/Save", data);
}

const getListFolders = () => {
  return apiService.Get("customer/api/Survey/ListFolders");
}

const getSurveyById = (surveyId) => {
  return apiService.Get("customer/api/Survey/GetSurveyById?id=" + surveyId);
}

const deleteSurvey = (surveyId) => {
  return apiService.Delete(`customer/api/Survey/DeleteSurvey?id=${surveyId}`);
}

const shareSurvey = (surveyId) => {
  return apiService.Post("customer/api/Survey/Share?id=" + surveyId);
}

const getSurveyByGuid = (surveyData) => {
  return apiService.Post("customer/api/SurveyPublic/GetSurveyByGuid", surveyData);
}

const getPublicDataMaster = () => {
  return apiService.Post("customer/api/SurveyPublic/GetDataMaster");
}

const saveSurveyAnswer = (data) => {
  return apiService.Post("customer/api/SurveyPublic/SaveSurveyAnswers", data);
}

const getLogoAndColorSchema = (surveyGuid) => {
  return apiService.Get("customer/api/SurveyPublic/GetLogoAndColorSchema?guid=" + surveyGuid);
}

const closeSurvey = (surveyId) => {
  return apiService.Post("customer/api/Survey/Close?surveyId=" + surveyId);
}

const getSurveySummaryById = (surveyId) => {
  return apiService.Get("customer/api/Survey/GetSurveySummaryById?id=" + surveyId);
}

const getSurveyAnswerPerRecipient = (criterio) => {
  return apiService.Post("customer/api/Survey/GetSurveyAnswerPerRecipient", criterio);
}

const getSurveyAnswerById = (surveyId, answerId) => {
  return apiService.Get(`customer/api/Survey/GetSurveyAnswerById?surveyId=${surveyId}&answerId=${answerId}`);
}

const getSurveyTrendInfoById = (id, from, to) => {
  let queryString = `id=${id}`
  if (from) queryString = queryString + `&from=${from}`
  if (to) queryString = queryString + `&to=${to}`
  
  return apiService.Get(`customer/api/Survey/GetSurveyTrendInfoById?${queryString}`);
}

const getSurveyTotalByQuestion = (id, from, to) => {
  let queryString = `id=${id}`
  if (from) queryString = queryString + `&from=${from}`
  if (to) queryString = queryString + `&to=${to}`
  // return apiService.Get(`customer/api/Survey/GetSurveyTotalByQuestion?id=${id}&from=${from}&to=${to}`);
  return apiService.Get(`customer/api/Survey/GetSurveyTotalByQuestion?${queryString}`);
}

const getSurveyInformationById = (data) => {
  return apiService.Post(`customer/api/Survey/GetSurveyInformationById`, data);
}

const getSurveyAnswerPerQuestion= (data) => {
  return apiService.Post(`customer/api/Survey/GetSurveyAnswerPerQuestion`, data);
}

const generateSurveyTrendInfoReport = (surveyId, from, to) => {
  return apiService.Post(`customer/api/Survey/GenerateSurveyTrendInfoReport?surveyId=${surveyId}&from=${from}&to=${to}`, {}, {
    responseType: 'blob',
  })
}

const generateSurveyTotalByQuestionReport = (surveyId, from, to) => {
  return apiService.Post(`customer/api/Survey/GenerateSurveyTotalByQuestionReport?surveyId=${surveyId}&from=${from}&to=${to}`, {}, {
    responseType: 'blob',
  })
}

const generateSurveyAnswerPerQuestionReport = (activityId) => {
  return apiService.Post(`customer/api/Survey/GenerateSurveyAnswerPerQuestionReport?activityId=${activityId}`, {}, {
    responseType: 'blob',
  })
}

const searchContacts = (name) => {
  return apiService.Get(`customer/api/Survey/SearchContacts?name=${name}`);
}


const shareSendByEmail = (data) => {
  return apiService.Post(`customer/api/Survey/ShareSendByEmail`, data);
}

const deleteSurveyRecipient = (surveyId, recipientId) => {
  return apiService.Delete(`customer/api/Survey/DeleteRecipient?surveyId=${surveyId}&recipientId=${recipientId}`);
}

export const SurveyService = {
  //* Implementados
  searchSurveys,
  generateReport,
  getDataMaster,
  saveSurvey,
  deleteSurvey,
  getListFolders,
  getSurveyById,
  shareSurvey,
  searchContacts,
  getSurveySummaryById,
  getSurveyTrendInfoById,
  generateSurveyTrendInfoReport,
  getSurveyTotalByQuestion,
  generateSurveyTotalByQuestionReport,
  getSurveyInformationById,
  getSurveyAnswerPerQuestion,
  generateSurveyAnswerPerQuestionReport,
  shareSendByEmail,
  closeSurvey,
  getSurveyAnswerPerRecipient,
  getSurveyAnswerById,
  deleteSurveyRecipient,

  // Public
  saveSurveyAnswer,
  getSurveyByGuid,
  getLogoAndColorSchema,
  getPublicDataMaster,

  //* Falta implementar
};