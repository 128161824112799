import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';
import { useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import { RadioButton } from 'primereact/radiobutton';
import { bar, line, gauge } from 'shared/assets/img/images';
import { MonitoringAction } from 'features/indicators/store/actions/MonitoringAction';
import { CoreConstants } from 'core/commons/CoreConstants';
import MonitoringIndicatorChart from './MonitoringIndicatorChart';
import useMonitoringModal from 'features/indicators/hooks/Monitoring/useMonitoringModal';
import './MonitoringModal.scss'
import { Panel } from 'primereact/panel';
import { TableCommentsComponent } from 'shared/components/comments/TableCommentsComponent';
import html2pdf  from 'html2pdf.js';
import { useClaimIndicatorMonitoring } from 'features/indicators/hooks/useClaimIndicatorMonitoring';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { InputTextarea } from 'primereact/inputtextarea';

const Field = ({ name, row, updateEmployee, onBlurValueDecimals, employeeErrors }) => {
    if (name === 'value') {
        return (
            // <InputText 
            <InputText 
                type='number'
                placeholder='Ingrese valor'
                name={name}
                value={row[name]}
                onChange={(e) => {
                    // if (e.nativeEvent.data === '-') return
                    updateEmployee({
                        id: row.id,
                        value: e.target.value,
                        // value: (e.nativeEvent.data === '-') 
                        //     ? (row[name] > 0 ? `${-row[name]}` : `-${-row[name]}` )
                        //     : e.target.value,
                        name: name,
                    })}
                }
                onBlur={(e) => onBlurValueDecimals({
                    id: row.id,
                    name: name,
                })}
                className={employeeErrors.find(error => error.id === row.id && error.value) ? 'p-invalid text-red-400' : ''}
                // className={'p-invalid '}
            />
        )
    } else if( name === 'valueDate') {
        return (
            <Calendar
                monthNavigator
                yearNavigator
                yearRange="1950:2050"
                showIcon={true}
                value={row[name] === null ? null : new Date(row[name])}
                name={name}
                className={employeeErrors.find(error => error.id === row.id && error.valueDate) ? 'p-invalid w-auto' : 'w-auto'}
                placeholder='Ingrese fecha'
                onChange={(e) => {
                    updateEmployee({
                        id: row.id,
                        value: e.value,
                        name: name,
                    })}
                }
            ></Calendar>
        )
    } else if( name === 'comments') {
        return (
            <div className='w-full'>

                <InputTextarea 
                    // rows={5} cols={30} 
                    rows={1} 
                    // cols={30}
                    placeholder='Comentario'
                    name={name}
                    value={row[name]}
                    onChange={(e) => {
                        updateEmployee({
                            id: row.id,
                            value: e.target.value,
                            name: name,
                        })}
                    }
                    className={employeeErrors.find(error => error.id === row.id && error.value) ? 'p-invalid text-red-400 w-full' : ' w-full'}
                />
            </div>
        )
    }
};

const MonitoringModal = ({displayModal, setDisplayModal, title, indicatorSelected, setIndicatorSelected, typeModal, setTypeModal}) => {

    // console.log(indicatorSelected)
    const monitoringValues = useSelector(state => state.monitoringReducer.monitoringValues);
    const { data, valuesData } = useSelector(state => state.monitoringReducer.monitoringValues);
    const { goalType, goalTypeRange } = useSelector(state => state.monitoringReducer.goal);
    // const goal = useSelector(state => state.monitoringReducer.goal);
    const { data: commentsData, isLoading: isLoadingComments } = useSelector(state => state.monitoringReducer.comments);
    const { isDeleted: isDeletedComment, isSaved: isSavedComment  } = useSelector(state => state.monitoringReducer.comment);
    const { isSaved } = useSelector((state) => state.monitoringReducer.monitoringValues);
    const [visible, setVisible] = useState(false);
    const [idDelete, setIdDelete] = useState(0);
    const [selectedChart, setSelectedChart] = useState(null);
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const { employeesList, createEmployee, deleteEmployee, updateEmployee, onBlurValueDecimals, setEmployeesList, dispatch, employeeErrors, setEmployeeErrors } = useMonitoringModal(indicatorSelected)
    const [expandedRows, setExpandedRows] = useState(null);
    const toast = useRef(null);

    // console.log(employeesList)
    // console.log(employeesList)
    // console.log(indicatorSelected)

    const isTypeRange = () => {
        if (goalType && goalTypeRange) return goalType === goalTypeRange
    }

    const { isDownload } = useClaimIndicatorMonitoring();

    useEffect(() => {
        setSelectedChart('bar')
    }, [])

    useEffect(() => {
        // setFromDate(moment(0).format())
        //codigo para obtener la fecha actual
        let fecha = Date.now();
        let fechaAtual = new Date(fecha)
        setToDate(fechaAtual)
    }, [])

    useEffect(() => {
        if (indicatorSelected && toDate) {
            dispatch(MonitoringAction.getValuesByIndicatorIdAndRange(indicatorSelected.id, fromDate, toDate));
        }
    }, [indicatorSelected, fromDate, toDate])

    useEffect(() => {
        if (isSaved) {
            dispatch(MonitoringAction.getValuesByIndicatorIdAndRange(indicatorSelected.id, fromDate, toDate));
        }
    }, [indicatorSelected, isSaved, fromDate, toDate])

    useEffect(() => {
        if (indicatorSelected) {
            dispatch(MonitoringAction.getCommentsById(indicatorSelected.id));
        }
    }, [indicatorSelected])

    useEffect(() => {
        if (valuesData) {
            setEmployeesList(valuesData)
        }
    }, [valuesData])
    
    const deletePermissionConfirm = () => {
        deleteEmployee(idDelete);
    };

    const downloadFollowUp = () => {
        dispatch(MonitoringAction.generateFollowUpReport(indicatorSelected.id));
    }

    const onHide = () => {
        setDisplayModal(false)
        setIndicatorSelected(null)
        setEmployeesList([])
        setTypeModal(null)
        // setValue('code', '')
        // reset()
        // if (data) {
        //   dispatch(DocumentLibraryAction.resetAfterSaved());
        // }
    }

    const redirectToSearch = () => {
        onHide()
    }
    
    const columns = [
        { field: 'valueDate', header: 'Fecha' },
        { field: 'elaborator', header: 'Medido por' },
        { field: 'value', header: 'Valor' },
        { field: 'goalType', header: 'Meta' },
        { field: 'goalValue', header: 'Valor Meta' },
        // { field: 'comments', header: 'Comentarios' },
        { field: 'goalColor', header: '' },
    ];

    const getCreationDate = (rowData) => rowData.creationDate ? <p>{moment(rowData.creationDate).format(CoreConstants.Format.LOCALE)}</p> : ''; 
    
    const goalTypeTemplate = () => {
        // console.log(rowData)
        return (
           <span className=''>{data?.goal.description}</span>
        )
    }
    const measurerTemplate = (row) => {
        // console.log(rowData)
        return (
           <span className=''>{row.elaborator}</span>
        )
    }
    const goalValueTemplate = () => {
        return (
           <span className=''>{data?.goalValue} {data?.maxGoalValue ? `- ${data?.maxGoalValue}` : ''}</span>
        )
    }

    const getClassColor = (color) => {
        switch (color) {
            case 'green':
                return 'indicator-green'
            case 'red':
                return 'indicator-red'
            case 'yellow':
                return 'indicator-yellow'
            default:
                return '';
        }
    }

    const goalColorTemplate = (row) => {
        return (
            <div 
                // className={`flex justify-center items-center rounded-full bg-${row?.color}-600 h-5 w-5 z-50`}
                className={`flex justify-center items-center rounded-full h-5 w-5`}
                style={{backgroundColor: row.color}}
             >
            </div>
        )
    }

    const ColumnComponents = columns.map((col) => {
        if (col.field === 'value') {
            return (
                <Column
                    key={col.field}
                    field={col.field}
                    header={col.header}
                    sortable
                    body={(row) => <Field name={col.field} row={row} updateEmployee={updateEmployee} onBlurValueDecimals={onBlurValueDecimals} employeeErrors={employeeErrors} />}
                    style={{ minWidth: '90px', maxWidth: '100px' }}
                />
            );
        }
        if (col.field === 'valueDate') {
            return (
                <Column
                    key={col.field}
                    field={col.field}
                    header={col.header}
                    sortable
                    body={(row) => <Field name={col.field} row={row} updateEmployee={updateEmployee} employeeErrors={employeeErrors} />}
                    // style={{ minWidth: '18%' }}
                    style={{ minWidth: '140px', maxWidth: '160px' }}
                />
            );
        }
        // if (col.field === 'comments') {
        //     return (
        //         <Column
        //             key={col.field}
        //             field={col.field}
        //             header={col.header}
        //             sortable
        //             body={(row) => <Field name={col.field} row={row} updateEmployee={updateEmployee} employeeErrors={employeeErrors} />}
        //             // style={{ minWidth: '18%' }}
        //             style={{ minWidth: '200px' }}
        //         />
        //     );
        // }
        if (col.field === 'goalType') {
            return (
                <Column
                    key={col.field}
                    field={col.field}
                    header={col.header}
                    body={goalTypeTemplate}
                    style={{ minWidth: '90px', maxWidth: '110px' }}
                />
            );
        }
        if (col.field === 'elaborator') {
            return (
                <Column
                    key={col.field}
                    field={col.field}
                    header={col.header}
                    body={measurerTemplate}
                    // style={{ minWidth: '22%' }}
                    style={{ minWidth: '200px' }}
                />
            );
        }
        if (col.field === 'goalValue') {
            return (
                <Column
                    key={col.field}
                    field={col.field}
                    header={col.header}
                    body={goalValueTemplate}
                    style={{ minWidth: '90px', maxWidth: '110px' }}
                />
            );
        }
        if (col.field === 'goalColor') {
            return (
                <Column
                    key={col.field}
                    field={col.field}
                    header={col.header}
                    body={goalColorTemplate}
                    // style={{ minWidth: '35px' }}
                    style={{ minWidth: '50px', maxWidth: '50px' }}
                />
            );
        }

        if (col.field === 'creationDate') {
            return <Column key={col.field} field={col.field} body={getCreationDate} header={col.header}  />;
        }
        
        return <Column key={col.field} field={col.field} header={col.header} />;
    });

    const RowDelete = ({ id }) => {
        return (
            <i
                onClick={() => {
                    setVisible(true);
                    setIdDelete(id);
                }}
                className="pi pi-trash cursor-pointer text-center "
            ></i>
        );
      };

    const onChangeFrom = (e) => {
        // if (e.value) setToDate(null) 
        setFromDate(e.value);
    }

    const onPrintChart = () => {
        // const $elementoParaConvertir = document.body; // <-- Aquí puedes elegir cualquier elemento del DOM
        const $elementoParaConvertir = document.getElementById('print_indicator_chart'); // <-- Aquí puedes elegir cualquier elemento del DOM
        const elemento = document.getElementById("button_download_indicator");
        // const dates = document.getElementById("dates-indicator");
        elemento.classList.add('hidden-button-print');
        // dates.style.paddingTop = '0'
        elemento.style.visibility = 'hidden';
        
        html2pdf()
            .set({
                margin: 1,
                filename: 'indicador.pdf',
                image: {
                    type: 'jpeg',
                    quality: 0.98
                },
                html2canvas: {
                    scale: 4, // A mayor escala, mejores gráficos, pero más peso
                    letterRendering: true,
                },
                jsPDF: {
                    unit: "in",
                    format: "a4",
                    orientation: 'portrait' // landscape o portrait
                }
            })
            .from($elementoParaConvertir)
            .save()
            .catch(err => console.log(err))
            .finally()
            .then(() => {
                elemento.classList.remove('hidden-button-print');
                // dates.style.paddingTop = '4px'
                elemento.style.visibility = 'visible';
            })
    }


    const employeeListSave = (employeesList) => {
        const newEmployeesList = employeesList.map((employee) => {
            if (!isNaN(employee.id)) {
                const { value, valueDate, id, comments } = employee
                const newEmployee = {
                    indicatorId: indicatorSelected.id,
                    value: Number(value),
                    valueDate,
                    id,
                    comments
                }
                return newEmployee
            }
            const { value, valueDate, comments } = employee
                const newEmployee = {
                    indicatorId: indicatorSelected.id,
                    value: Number(value),
                    valueDate,
                    comments
                }
                return newEmployee
        })

        return newEmployeesList
    }

    const existIndicatorEmpty = () => {
        const employeesListEmpty = employeesList.filter((employee) => employee.value === '' || employee.valueDate === null) 
        // return employeesListEmpty

        const employeesErrorsByRow = employeesListEmpty.map((employee) => {
            const model = {
                id: employee.id,
                value: false,
                valueDate: false
            }
            if (employee.value === '') {
                model.value = true
            }
            if (employee.valueDate === null) {
                model.valueDate = true
            }
            return model
        })
        return employeesErrorsByRow
    }
    
    
    const onSubmit = (e) => {
        e.preventDefault()

        const indicatorsEmpty = existIndicatorEmpty()

        if (indicatorsEmpty.length > 0 || employeesList.length === 0) {
            // dispatch(toastAction.success('Indicador', 'No se permiten campos vacios'));
            toast.current.show({severity:'error', summary: 'Indicador', detail:'No se permiten campos vacios', life: 3000});
            setEmployeeErrors(indicatorsEmpty)
            return
        }
        // setEmployeeError(null)
        setEmployeeErrors([])
        const model = {
            indicatorId: indicatorSelected.id,
            indicatorValueList: employeeListSave(employeesList),
        }
        // if (valuesData.length > 0) {
        //     const model = {
        //         indicatorId: indicatorSelected.id,
        //         indicatorValueList: employeeListSave(employeesList),
        //     }
        //     dispatch(MonitoringAction.saveIndicatorValues(model));
        //     return
        // }
        // console.log(model)
        dispatch(MonitoringAction.saveIndicatorValues(model));
    };

    const bodyTemplate = () => {
        return <Skeleton></Skeleton>
    }

    const rowExpansionTemplate = (data) => {
        // console.log(data)
        return (
            <div className="w-full">
                {/* <h5>Comentarios del indicador {data.id}</h5> */}
                <InputTextarea 
                    // rows={5} cols={30} 
                    rows={3} 
                    // cols={30}
                    placeholder='Comentario'
                    name={'comments'}
                    value={data['comments']}
                    onChange={(e) => {
                        updateEmployee({
                            id: data.id,
                            value: e.target.value,
                            name: 'comments',
                        })}
                    }
                    className={employeeErrors.find(error => error.id === data.id && error.value) ? 'p-invalid text-red-400 w-full' : ' w-full'}
                />
                {/* <DataTable value={[data]}>
                    <Column
                        field='comments'
                        header='Comentarios'
                        // sortable
                        body={(row) => <Field name={'comments'} row={row} updateEmployee={updateEmployee} employeeErrors={employeeErrors} />}
                        // style={{ minWidth: '18%' }}
                        style={{ minWidth: '100%' }}
                    />
                </DataTable> */}
            </div>
        );
    };

    return (
        <Dialog header={title} visible={displayModal} className='w-11/12 max-h-4/5 md:w-3/4' onHide={onHide}>
            {
                (typeModal === 'seguimiento' || typeModal === 'vista')&& (
                    <>
                        <Panel header="INDICADORES" toggleable>
                            <form 
                                className="form-custom p-0"
                                onSubmit={onSubmit}
                            >
                                <div className="">
                                    <div className='flex justify-between'>
                                        {
                                            typeModal!=='vista'&&(
                                                <Button
                                                    icon="pi pi-plus"
                                                    type="button"
                                                    label="Nuevo"
                                                    onClick={createEmployee}
                                                    className="sig-button sig-dark mt-2 mb-4"
                                                />
                                            )
                                        }
                                        {
                                            (typeModal!=='vista' && isDownload)&&(
                                                <Button
                                                    icon="pi pi-download"
                                                    type="button"
                                                    label="Descargar"
                                                    onClick={downloadFollowUp}
                                                    className="sig-button sig-success mt-2 mb-4"
                                                />
                                            )
                                        }
                                    </div>
                                    {
                                        <div className="table-indicators mb-3">
                                            {
                                                monitoringValues.isLoading ? (
                                                    <DataTable 
                                                        value={Array.from({ length: 3 }, (v, i) => i)} 
                                                        className="p-datatable-striped text-center"
                                                        responsiveLayout="scroll"
                                                    >
                                                        <Column field="valueDate" header="Fecha"  body={bodyTemplate}></Column>
                                                        <Column field="elaborator" header="Medido por"  body={bodyTemplate}></Column>
                                                        <Column field="value" header="Valor"  body={bodyTemplate}></Column>
                                                        <Column field="goalType" header="Meta"  body={bodyTemplate}></Column>
                                                        <Column field="goalValue" header="Valor Meta"  body={bodyTemplate}></Column>
                                                        <Column field="goalColor" header=""  body={bodyTemplate}></Column>
                                                    </DataTable> 
                                                ) : (
                                                    <DataTable
                                                        value={employeesList}
                                                        emptyMessage="No se encontraron indicadores"
                                                        scrollable scrollHeight="320px" 
                                                        // loading={monitoringValues.isLoading}
                                                        stripedRows
                                                        editMode="row"
                                                        dataKey="id"
                                                        rowHover
                                                        size="small"
                                                        responsiveLayout="scroll"
                                                        header={indicatorSelected?.name}
                                                        // className='text-center'

                                                        // expandedRowIcon='pi pi-book'
                                                        // collapsedRowIcon='pi pi-book'

                                                        expandedRowIcon='pi pi-comments'
                                                        collapsedRowIcon='pi pi-comments'
                                                        expandedRows={expandedRows} 
                                                        onRowToggle={(e) => setExpandedRows(e.data)}
                                                        rowExpansionTemplate={rowExpansionTemplate}
                                                    >
                                                        {ColumnComponents}
                                                        <Column 
                                                            expander={true} 
                                                            style={{ minWidth: '50px', maxWidth: '50px' }} 
                                                            className='text-lg' 
                                                        />
                                                        <Column 
                                                            header={<div></div>} 
                                                            body={RowDelete}
                                                            style={{ minWidth: '50px' , maxWidth: '50px'}}
                                                        ></Column>
                                                    </DataTable>
                                                )
                                            }
                                        </div>
                                    }
                                    {/* <div className='flex justify-center'>
                                        {employeeError && (
                                        <Message className='w-1/2' severity="error" text={employeeError} />
                                        )}
                                    </div> */}

                                </div>
                                <div className="flex justify-end gap-4 mt-1">
                                    <Button
                                        icon="pi pi-times"
                                        type="button"
                                        label="Cancelar"
                                        onClick={redirectToSearch}
                                        className="sig-button sig-secondary mt-4"
                                    />
                                    {
                                        (typeModal!=='vista')&&(
                                            <Button
                                                icon="pi pi-save"
                                                type="submit"
                                                // disabled={existError ? true : false}
                                                label="Guardar"
                                                loading={monitoringValues?.isSaving || monitoringValues?.isLoading}
                                                className="sig-button sig-primary mt-4"
                                            />
                                        )
                                    }
                                </div>
                            </form>
                        </Panel>
                        <Panel header="COMENTARIOS" toggleable>
                            <TableCommentsComponent 
                                enableComments={true}
                                sourceId={indicatorSelected?.id}
                                comments={commentsData}
                                Action={MonitoringAction}
                                isDeletedComment={isDeletedComment}
                                isSavedComment={isSavedComment}
                                isLoadingComments={isLoadingComments}
                                sourceCode={CoreConstants.Task.Source.INDICATOR}
                            />
                        </Panel>
                    </>
                ) 
            }
            <Panel header="GRAFICOS" toggleable>
                <div className='grid grid-cols-12'>
                    <div className='col-span-3 flex flex-col'>
                        <div className='flex flex-col'>
                            <RadioButton inputId="city1" name="selectedChart" value="bar" onChange={(e) => setSelectedChart(e.value)} checked={selectedChart === 'bar'} />
                            <img src={bar} alt="bar" className='w-48' />
                            {/* <MonitoringIndicatorChart typeChart='barOption' /> */}
                        </div>
                        <div className='flex flex-col'>
                            <RadioButton inputId="city2" name="selectedChart" value="line" onChange={(e) => setSelectedChart(e.value)} checked={selectedChart === 'line'} />
                            {/* <MonitoringIndicatorChart typeChart='lineOption' /> */}
                            <img src={line} alt="line" className='w-48 ml-4' />
                        </div>
                        {
                            isTypeRange() ? (
                                <div className='flex flex-col'>
                                    <RadioButton inputId="city3" name="selectedChart" value="gauge" onChange={(e) => setSelectedChart(e.value)} checked={selectedChart === 'gauge'} />
                                    <img src={gauge} alt="line" className='w-48 ml-4' />
                                </div>
                            ) : null
                        }
                       
                    </div>
                    <Divider layout='vertical'/>
                    <div className='col-span-8' id='print_indicator_chart'>
                        {/* <div className='flex flex-col justify-center'> */}
                            <div className='flex gap-3 flex-wrap'>
                                {/* <span className="p-float-label flex-1"> */}
                                    <Calendar
                                        monthNavigator
                                        yearNavigator
                                        placeholder='Desde'
                                        yearRange="1950:2050"
                                        // showIcon={true}
                                        value={fromDate === null ? '' : new Date(fromDate)}
                                        name="from"
                                        onChange={(e) => onChangeFrom(e)}
                                        className='w-full indicator-calendar flex-1'
                                        style={{minWidth: 90}}
                                    ></Calendar>
                                    {/* <label htmlFor="desde">Desde</label> */}
                                {/* </span>
                                <span className="p-float-label flex-1"> */}
                                    <Calendar
                                        monthNavigator
                                        yearNavigator
                                        yearRange="1950:2050"
                                        placeholder='Hasta'
                                        // showIcon={true}
                                        value={toDate === null ? '' : new Date(toDate)}
                                        name="to"
                                        minDate={fromDate === null ? '' : new Date(fromDate)}
                                        onChange={(e) => setToDate(e.value)}
                                        className='w-auto indicator-calendar flex-1'
                                        style={{minWidth: 90}}
                                    ></Calendar>
                                    {/* <p className='indicator-calendar-p flex-1'>10/05/2022</p> */}
                                    {/* <label htmlFor="hasta">Hasta</label> */}
                                {/* </span> */}
                                <div className=''>
                                    {
                                        isDownload&&(
                                            <Button
                                                type="button"
                                                className='sig-button sig-success' 
                                                label="Descargar" 
                                                id='button_download_indicator'
                                                icon="pi pi-download" 
                                                onClick={onPrintChart}
                                                // style={{visibility: 'hidden'}}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            <div className='w-full mt-5'>
                                <MonitoringIndicatorChart typeChart={selectedChart} isLoading={monitoringValues.isLoading}/>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            </Panel>
            <ConfirmDialog
                visible={visible}
                onHide={() => setVisible(false)}
                message="¿Está seguro que desea eliminar el contacto?"
                header="Cancelar"
                icon="pi pi-exclamation-triangle"
                accept={() => deletePermissionConfirm()}
                reject={() => setVisible(false)}
            />
            <Toast ref={toast}></Toast>

        </Dialog>
    )
}

export default MonitoringModal