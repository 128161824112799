import { useForm } from "react-hook-form";


const useModalAction = () => {
  let defaultValues = {
    roles: [],
    name: '',
    // condition: '',
    conditions: [],
    stepTo: null,
    logicalOperatorId: null,
  };

  const { setValue, handleSubmit, control, watch, formState: { errors }, reset } = useForm({ defaultValues });

  const valuesFields = watch();
  const existError = Object.keys(errors).length > 0


  // console.log(valuesFields)

  return {
    control,
    errors,
    valuesFields,
    existError,
    setValue,
    handleSubmit,
    reset
  }
}

export default useModalAction