import React, { useEffect, useState } from 'react';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import ModalSelect from '../components/ModalSelect';

const RiskConfigurationPage = () => {

	const [showModal, setShowModal] = useState(false)
	
	const { updateTitle } = useSetTitlePage();

	useEffect(() => {
		updateTitle({
				title: 'Gestión de Riesgos',
				subtitle: 'Riesgos',
				description: 'Configuración',
				previousUrl: '/gestion-riesgos/matrices',
		});
// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div>
				<strong>Aquí podrás configurar las variables que intervienen en la gestión de riesgos de tu organización.</strong>
			</div>
			<div className='flex justify-around p-4'>
				<div className="hover:bg-gray-100 p-2 justify-between items-center flex cursor-pointer rounded-lg border-2 shadow-xl h-32 w-96">
					<div className='p-4'>
						Dibujo
					</div>
					<div>
						<div>
							<strong>
								Formulario
							</strong>
						</div>
						<span>
						Configura los campos que tendrá el formulario de registro de riesgos.
						</span>
					</div>
				</div>
				<div className="hover:bg-gray-100 p-2 justify-between items-center flex cursor-pointer rounded-lg border-2 shadow-xl h-32 w-96" onClick={()=> {setShowModal(true)}}>
					<div className='p-4'>
						Dibujo
					</div>
					<div>
						<div>
							<strong>
								Mapa de calor
							</strong>
						</div>
						<span>
						Configura los criterios de evaluación del riesgo.
						</span>
					</div>
				</div>
			</div>
			<ModalSelect 
					visibleModalVersion={showModal}
					setVisibleModalVersion={setShowModal}
				/>
		</div>
	);
};

export default RiskConfigurationPage;