const columns = [
  { field: "userName", header: "Usuario" },
  { field: "firstName", header: "Nombre" },
  { field: "lastName", header: "Apellidos" },
  { field: "status", header: "Estado" },
  { field: "creationDate", header: "Fecha de Creación" },
  { field: "lastLogin", header: "Ultimo Acceso" },
];

export const UsersTableData = {
  columns,
};
