/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { DynamicFieldsAction } from 'features/configuration/store/actions/DynamicFieldsAction'; 
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { useDispatch } from 'react-redux';

export const useDynamicFields = () => {
  const { updateTitle } = useSetTitlePage();
  const dispatch = useDispatch();

  useEffect(() => {
    updateTitle({
      title: 'Configuración',
      subtitle: 'Campos Dinámicos',
    });
  }, []);

  const generateReport = () => {
    dispatch(DynamicFieldsAction.generateReport());
  };
  return { generateReport };
};
