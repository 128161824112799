import { ProcessesAction } from 'features/configuration/store/actions/ProcessesAction'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalAction from './ModalAction'
import WorkFlowCard from './WorkFlowCard'
import useWorkflow from 'features/configuration/hooks/processes/workflow/useWorkflow'
import './Workflow.scss'
import { Divider } from 'primereact/divider'
import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu'
import { Controller } from 'react-hook-form'
import { InputText } from 'primereact/inputtext'

const Workflow = ({ dinamicStage, processFields, workflow, setWorkflow, initialProcessId, children, setLastStepName }) => {
    const dispatch = useDispatch()
    const [displayModal, setDisplayModal] = useState(false)
    const [actionSelected, setActionSelected] = useState(null)
    const [listControls, setListControls] = useState([])
    const [stepSelected, setStepSelected] = useState(null)
    const [stepFather, setStepFather] = useState(null)
    const [showDiagram, setShowDiagram] = useState(false)
    const [firstLoading, setFirstLoading] = useState(true)

    const {data} = useSelector(state => state.processReducer.listResponsibles)
    const {roles} = useSelector(state => state.processReducer.dataMaster)
    const dataProcess = useSelector(state => state.processReducer.process?.data)
    // console.log(dataProcess)
    // console.log(workflow)

    
    const stepType = data?.stepType

    const getTypeByCode = (type) => {
      const result = stepType.find(item => item.code === type)
      return result.id
    }

    const getTypeById = (typeId) => {
      const result = stepType?.find(item => item.id === typeId)
      return result
    }

    const getIdentifierByControlId = (controlId) => {
      const result = listControls?.find(item => item.id === controlId)
      return result?.identifier
    }

    const { 
      control, errors, existError, valuesFields, setValue, handleSubmit, reset,
      stepMethods 
    } = useWorkflow(processFields, workflow, setWorkflow, getTypeByCode, getTypeById, initialProcessId)

    
    
    const getChildrenRecursive = ( workFlowSteps, step ) => {
      // step.predecessorCode = step.predecessorCodes
      // step.successorCode = step.successorCodes
      if (step.successorCode) {
        // console.log(step)
        const stepChild = workFlowSteps.find(item => item.code === step.successorCode)
        const stepChildrenListCodes = step?.successorCode.split(',')
        const stepChildren = workFlowSteps.filter(item => stepChildrenListCodes.includes(item.code))
        // if (stepChild || stepChildrenListCodes.length === stepChildren.length) {
        //* Para Pasos, Goto y Ramas
        if (stepChild) {
          //* Asignar roles
          if (step.roles.length > 0) {
            const myRoles = roles.filter(role => {
              const roleFindend = step.roles.find(stepRole => stepRole.roleId === role.id)
              if (roleFindend) return true
              return false
            })

            const newRoles = [...myRoles]

            step.roles2 = newRoles.map(role => {
              const roleFindend = step.roles.find(stepRole => stepRole.roleId === role.id)
              let newRole = {...role}
              if (roleFindend) {
                newRole = {
                  ...newRole,
                  dbId: roleFindend.id
                }
              }
              return newRole
            })

            step.roles = [...myRoles]
            // console.log(step.roles2)
            // console.log(step)
          }
          //* Asignar identifier a las conditions
          if (step.conditions.length > 0) {
            step.conditions = step.conditions.map(condition => {
              const { processFormControlId, ...newModelCondition } = condition
              newModelCondition.identifierControl = getIdentifierByControlId(processFormControlId)
              return newModelCondition
            })
          }
          //* Asignar gotoCode a los step goto
          if (step.gotoCode) {
            const stepTo = workFlowSteps.find(workflowStep => workflowStep.code === step.gotoCode)
            if (stepTo) {
              const {children, ...restStepTo} = stepTo
              restStepTo.id =  restStepTo.code.split('__')[1]
              step.stepTo = restStepTo
            }
          }
          
          // console.log(step)

          
          stepChild.id = stepChild.code.split('__')[1]
          step.children = [stepChild]

          //* Asignamos el branch incluida la rama
          if (step.branch) {
            //todo:
            // step.branch = `rama__${step.branch}`
            //* Quitamos el sucesor que no forma parte de las ramas pero que continua al ultimo paso de las ramas
            if (!stepChild.branch) {
              step.children = []
            }
            // return
          }
          if (getTypeById(step?.typeId)?.code === 'CONDITION' && step.code.split('__')[0] === 'rama') {
            // console.log(step)
            // const stepBranchWithSuccessorNoStepBranch = workFlowSteps.find(stp => stp.code === step.successorCode && !stp.branch)
            const succesorBranch = workFlowSteps.find(stp => stp.code === step.successorCode)
            // console.log(succesorBranch)
            if (!succesorBranch.branch) {
              step.children = []
            }
          }

          //* Config Paso anterior al final
          if (getTypeById(stepChild.typeId).code === 'END') {
            // console.log(step)
            // console.log(stepChild)
            step.children = []
            step.successorCode = ''
            getChildrenRecursive(workFlowSteps, stepChild)
            return
          }
          
          getChildrenRecursive(workFlowSteps, stepChild)
        }

        //* Para desicion
        if (stepChildrenListCodes.length === stepChildren.length) {
          // console.log(step)
          //* Config para el Decision (rombo)
          // if (getTypeById(stepChild.typeId).code === 'CONDITION' && step.code.split('__')[0] === 'decision') {
          if (getTypeById(stepChildren[0]?.typeId)?.code === 'CONDITION' && getTypeById(stepChildren[1]?.typeId)?.code === 'CONDITION' && step.code.split('__')[0] === 'decision') {
            const stepChildrenBranch = workFlowSteps.filter(item => item.predecessorCode === step.code)
            const stepChildrenBranchWithId = stepChildrenBranch.map(item => {
              item.id = item.code.split('__')[1]
              return item
              
            })
            step.childrenBranch = [...stepChildrenBranchWithId]
          }
          
          if (getTypeById(stepChildren[0]?.typeId)?.code === 'CONDITION' && getTypeById(stepChildren[1]?.typeId)?.code === 'CONDITION' && step.code.split('__')[0] === 'decision'){

            workFlowSteps.forEach(workFlowStep => {
              if (stepChildrenListCodes.includes(`rama__${workFlowStep.branch}`)) {
                //* Ultimo paso de cada rama que no tenga succesorCode poner el code del nuevo paso fuera de la rama
                // if (workFlowStep.successorCode === '') {
                //   const workFlowUpdated = workflow.map(stp => {
                //     if (stp.code === workFlowStep.predecessorCode) {
                //       stp.successorCode = ""
                //     }
                //   })
                // }
                //* Ultimo paso de cada rama cuyo succesorCode no sea parte de la rama
                const stepBranchWithSuccessorNoStepBranch = workFlowSteps.find(stp => stp.code === workFlowStep.successorCode && !stp.branch)
                // console.log(stepBranchWithSuccessorNoStepBranch)
                // if (!getTypeById(step.typeId).code === 'END') {
                if (getTypeById(stepBranchWithSuccessorNoStepBranch?.typeId)?.code !== 'END') {
                  step.children = [stepBranchWithSuccessorNoStepBranch]
                  // console.log(stepBranchWithSuccessorNoStepBranch)
                  return
                }
                // console.log(stepBranchWithSuccessorNoStepBranch)
                step.children = []
              }
            })

            // step.children = []
            stepChildren.forEach(stpChild => {
              getChildrenRecursive(workFlowSteps, stpChild)
            })
            return
          }
        }

      } else {
        // step.children = []
        if (getTypeById(step.typeId).code === 'END') {
          // console.log(step)
          setValue('finalName', step.name)
          setFirstLoading(false)
        }
        return step
      }
    }
    

    useEffect(() => {
      if (dataProcess?.workFlowSteps && stepType && roles && listControls.length > 0 && firstLoading) {
        const newWorkFlowInSingular = dataProcess?.workFlowSteps.map((step) => {
          const newStep = { ...step }
          newStep.successorCode = step.successorCodes
          newStep.predecessorCode = step.predecessorCodes
          delete newStep.successorCodes
          delete newStep.predecessorCodes
          return newStep
        })
        // const workFlowSteps = dataProcess?.workFlowSteps.find(step => getTypeById(step.typeId).code === 'START')
        const workFlowSteps = newWorkFlowInSingular.find(step => getTypeById(step.typeId).code === 'START')
        workFlowSteps.id = workFlowSteps.code.split('__')[1]
        // getChildrenRecursive(dataProcess?.workFlowSteps, workFlowSteps)
        getChildrenRecursive(newWorkFlowInSingular, workFlowSteps)
        setValue('configurations', workFlowSteps)

        // const newListSteps = dataProcess?.workFlowSteps.filter(step => step.typeCode === 'STEP')
        const newListSteps = newWorkFlowInSingular.filter(step => step.typeCode === 'STEP')
        setValue('listSteps', newListSteps)

        // console.log(workFlowSteps)
        // const newWorkFlow = dataProcess?.workFlowSteps.filter(step => getTypeById(step.typeId)?.code !== 'END')
        //* Quitamos el paso final
        const newWorkFlow = newWorkFlowInSingular.filter(step => getTypeById(step.typeId)?.code !== 'END')
        //* Le ponemos los rolesDB
        const newWorkFlowWithRolesDB = newWorkFlow.map(step => {
          if (step.roles2) {
            step.roles = step.roles2
            delete step.roles2
          }
          return step
        })
        setWorkflow(newWorkFlowWithRolesDB)
      }
    }, [dataProcess?.workFlowSteps, data, roles, dinamicStage, listControls, firstLoading])

    // useEffect(() => {
    //   if (dataProcess?.workFlowSteps && stepType && roles && listControls.length > 0) {
    //     // const workFlowSteps = dataProcess?.workFlowSteps.map(step => {
    //     //   if (step.roles.length > 0) {
    //     //     console.log(roles)
    //     //     console.log(step)
    //     //     step.roles = roles.filter(role => {
    //     //       const roleFindend = step.roles.find(stepRole => stepRole.roleId === role.id)
    //     //       if (roleFindend) return true
    //     //       return false
    //     //     })
    //     //   }
    //     //   //* Asignar identifier a las conditions
    //     //   if (step.conditions.length > 0) {
    //     //     step.conditions = step.conditions.map(condition => {
    //     //       const { processFormControlId, ...newModelCondition } = condition
    //     //       newModelCondition.identifierControl = getIdentifierByControlId(processFormControlId)
    //     //       return newModelCondition
    //     //     })
    //     //   }
          
    //     //   return step
    //     // })
        
    //     // console.log(workFlowSteps)
    //     // console.log(dataProcess?.workFlowSteps)
    //     // setWorkflow(workFlowSteps)
    //   }
    // }, [dataProcess, data, roles, dinamicStage, listControls])
    

    const openModalAction = (action, stepFather, stepSelected) => {
      setDisplayModal(true)
      setActionSelected(action)
      setStepFather(stepFather)
      setStepSelected(stepSelected)
    }

    useEffect(() => {
        dispatch(ProcessesAction.getDataMaster());
    },[dispatch])

    useEffect(() => {
      if (processFields?.name) {
        const newConfigurations = {...valuesFields.configurations}
        newConfigurations.name = processFields.name
        setValue('configurations', newConfigurations)
      } 
    }, [processFields?.name])

    useEffect(() => {
      if (valuesFields?.finalName) {
        setLastStepName(valuesFields?.finalName)
      } 
    }, [valuesFields?.finalName])

    useEffect(() => {
      if (stepType) {
        const newConfigurations = {...valuesFields.configurations}
        newConfigurations.typeId = getTypeByCode('START')
        setValue('configurations', newConfigurations)

        const newInitialWorkFlow = workflow.map((item, index) => {
          if (index === 0) {
            return {
              ...item,
              typeId: getTypeByCode('START')
            }
          }
          return item
        })
        setWorkflow( newInitialWorkFlow )
      } 
    }, [stepType])
    

    useEffect(()=>{
      if(dinamicStage){
        const data = [...dinamicStage]
        const controls = []
        data.map(section => {
          section.dinamicInput?.map(control=> {
                controls.push(control)
                return control
          })
          return section
        })
        setListControls(controls)
      }
    },[dinamicStage])

    // console.log(listControls)
    // console.log(processFields)

    // const StepsComponent = ({step}) => {
    //   return (
    //     <div className='flex w-full overscroll-x-auto flex-col justify-center items-center'>
    //       {/* <Divider  className='w-3/4'/> */}
    //       <WorkFlowCard 
    //         openModalAction={openModalAction}
    //         step={step}
    //         stepMethods={stepMethods}
    //         workFlowFields={valuesFields}
    //         control={control}
    //       />
    //       {
    //         step?.children.length <= 1 ? (
    //           <div className='pt-2'>
    //             {
    //               step?.children?.map((child) => {
    //                 return (
    //                   <div className=''>
    //                     <StepsComponent step={child} />
    //                   </div>
    //                 );
    //               })
    //             }
    //           </div>
    //         ) : (
    //           <div className='flex gap-x-4 border-t pt-2 w-full overflow-auto'>
    //             {
    //               step?.children?.map((child) => {
    //                 return (
    //                   <div className=''>
    //                     <StepsComponent step={child} />
    //                   </div>
    //                 );
    //               })
    //             }
    //           </div>
    //         )
    //       }
    //     </div>
    //   )
    // }

    const lastStep = stepMethods.getLastStep(valuesFields.configurations)

    // console.log(valuesFields.configurations)
    // console.log(lastStep)

    const StepsComponent = ({step}) => {
      const menu = useRef(null);
      const items = [
        {
          label: 'Agregar un nuevo paso',
          command: () => {
            openModalAction('step_configuration', step, null)
            
          }
        },
        {
            label: 'Agregar validación',
            command: () => {
              stepMethods.createNewParallelBranch(step)
            }
        },
        {
            label: 'Ir al paso',
            command: () => {
              openModalAction('go_to_step', step, null)
            }
        },
      ];
      const isCondition = getTypeById(step?.typeId)?.code === 'CONDITION'

      return (
        // <div className='branch_step flex w-full flex-col justify-center items-center'>
        <div className='branch_step flex w-full flex-col justify-start items-center'>
          {
            // step.type === 'parallel_branch_root' ? (
            (isCondition && step.childrenBranch) ? (
              <>
                <div className='h-7 w-7 border-2 process-rhombus-figure my-1' />
                <Divider layout="vertical" className=''>
                  <Button 
                    type='button' 
                    className=" sig-primary" 
                    label='Añadir una rama'
                    aria-controls="popup_menu" aria-haspopup
                    onClick={() => stepMethods.createNewStepBranch(step)} 
                  />
                </Divider>
              </>
            ) : (
              <WorkFlowCard 
                openModalAction={openModalAction}
                step={step}
                stepMethods={stepMethods}
                workFlowFields={valuesFields}
                control={control}
                lastStep={lastStep}
                getTypeById={getTypeById}
              />
            )
          }
          {
            // (step?.children.length <= 1 && step.type !== 'parallel_branch_root') ? (
            (!step.childrenBranch) ? (
              <div className={`pt-0 w-full flex-1 ${step?.children.length > 0 ? '' : 'hidden'}`}>
                {
                  step?.children?.map((child) => {
                    return (
                      <div className={`w-full ${child.branch ? 'h-full' : ''} step1`} key={child.id}>
                        <StepsComponent step={child} />
                      </div>
                    );
                  })
                }
              </div>
            ) : (
              <>
                <div className='w-full branch_container grid auto-rows-auto content-start'>
                  <div className='branchs  flex justify-between gap-x-6 border-t border-b pt-2 w-full'>
                    {
                      step?.childrenBranch?.map((child) => {
                        // const stepsInBranch = workflow.filter(stp => `rama_${stp.branch}` === child.code)
                        // console.log(stepsInBranch)
                        return (
                          <div className='branch w-full' key={child.id}>
                            {/* <div className=''> */}
                            <StepsComponent step={child} />
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
                <Divider layout="vertical">
                    <Button 
                      icon="pi pi-plus" 
                      type='button' 
                      className="p-button-rounded sig-primary h-full" 
                      // className={`p-button-rounded sig-primary ${showButton ? 'visible' : 'hidden'}`}
                      style={{minWidth: 10, width: 30, height: 30}} 
                      onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup
                    />
                </Divider>
                {
                  (lastStep.id === step.id)  && (
                    <div className='rounded sig-success py-1 px-3 final-process-name'>
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value },
                          }) => (
                            <InputText
                              onChange={onChange}
                              // onBlur={onBlur}
                              value={value}
                              className='w-full text-center'
                            />
                          )}
                          name="finalName"
                      />
                    </div>
                  )
                }
                <Menu model={items} popup ref={menu} id="popup_menu" />
                <div className='pt-2 w-full'>
                {
                  step?.children?.map((child) => {
                    return (
                      <div className='w-full step2' key={child.id}>
                        <StepsComponent step={child} />
                      </div>
                    );
                  })
                }
              </div>
              </>
            )
          }
        </div>
      )
    }
    
    return (
        <div className='h-full'>
          {/* <div className="flex justify-start gap-4 my-2 w-full">
              <Button
                  type="button"
                  label="Flujo de Trabajo"
                  onClick={() => { setShowDiagram(false); }}
                  className={`sig-button ${showDiagram ? 'sig-secondary' : 'sig-primary'} `}
              />
              <Button
                  type="button"
                  label="Diagrama de Flujo"
                  onClick={() => { setShowDiagram(true); }}
                  className={`sig-button ${showDiagram ? 'sig-primary' : 'sig-secondary'} `}
              />
          </div>
          {
            showDiagram ? (
              <div className='px-3 py-1'>
                <h1>Diagrama de Flujo</h1>
              </div> 
            ) : (
              <div className='w-full border-gray-600 px-3 py-1 content-scroll scroll flex flex-col justify-between'>
                <div className='flex flex-col items-center gap-2 w-full'>
                  {
                    <StepsComponent step={valuesFields.configurations} />
                  }
                </div>
                {children} 

              </div>
            )
          } */}

          <div className='w-full border-gray-600 px-3 py-1 content-scroll scroll flex flex-col justify-between' style={{height: 400}}>
            <div className='flex flex-col items-center gap-2 w-full step-component-container'>
              {
                
                <StepsComponent step={valuesFields.configurations} />
              }
            </div>
            {children} 

          </div>
         

          <ModalAction 
            displayModal={displayModal} setDisplayModal={setDisplayModal} 
            actionSelected={actionSelected} setActionSelected={setActionSelected} 
            listControls={listControls}
            stepMethods={stepMethods}
            stepFather={stepFather} setStepFather={setStepFather}
            stepSelected={stepSelected} setStepSelected={setStepSelected}
            workFlowFields={valuesFields}
            getTypeById={getTypeById}
          />
        </div>
    )
}

export default Workflow