export class RegistrationFormConstans {
  static Accion = class {

    static RegistrationDataMaster = class {
      static get GET_DATA_MASTER() {
        return "@PROCESS.GET_DATA_MASTER";
      }
      static get GET_DATA_FORM() {
        return "@PROCESS.GET_DATA_FORM";
      }
      static get SAVE_FORM(){
        return "@PROCESS.SAVE_FORM"
      }
      static get TOAST(){
        return "@TOAST"
      }
    }
  };
}
