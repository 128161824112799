import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Skeleton } from 'primereact/skeleton';
import { Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { DeparmentsAction } from 'features/rrhh/store/actions/DepartmentAction';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useClaimDepartment } from 'features/rrhh/hooks/useClaimDepartment';

const DepartmentPage = ({ title, mode }) => {

    const history = useHistory();
    const { updateTitle } = useSetTitlePage();
    const dispatch = useDispatch();
    const department = useSelector((state) => state.departmentReducer.department);
    const [isFirstLoading, setFirstLoading] = useState(true);
    const dataMaster = useSelector((state) => state.departmentReducer.dataMaster);
    const [visible, setVisible] = useState(false);
    const [departmentParents, setDepartmentParents] = useState([]);
    const [managers, setManagers] = useState([]);

    const { isCreate, isEdit } = useClaimDepartment();

    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: "",
            id: null,
            manager: null,
            departmentParent: null
        }
    });
    const params = useParams();

    const redirectToSearch = () => {
        history.push('/rrhh/departamento');
        dispatch(DeparmentsAction.resetAfterSaved());
    }

    useEffect(() => {

        if (department?.data) {
            setValue("name", department.data.name);
            setValue("id", department.data.id);

            if (dataMaster.departmentParent)
                evaluateDepartmentParent();

            if (dataMaster.manager)
                evaluateManager();

        }
        if (department?.isSaved)
            redirectToSearch();

        if (department?.data === null && dataMaster) {
            setDepartmentParents(dataMaster?.departmentParent);
            setManagers(dataMaster?.manager);
        }

    }, [dataMaster, department]);

    useEffect(() => {

        setValue("name", undefined);
        setValue("id", undefined);
        setValue("manager", undefined);

        updateTitle({
            title: 'Gestión de la organización',
            subtitle: 'Departamento',
            description: title,
            previousUrl: '/rrhh/departamento'
        });

        if (isFirstLoading) {
            setFirstLoading(false);
            dispatch(DeparmentsAction.getDataMaster());

            if (params.id)
                getDepartmentById(params.id);
        }
    }, []);

    const evaluateDepartmentParent = () => {
        const currentDepartment = dataMaster?.departmentParent.filter(c => { return c.id !== department.data.id });
        setDepartmentParents(currentDepartment);

        const currentValue = dataMaster?.departmentParent.filter(c => { return c.id === department.data.parentCompanyAreaId });
        if (currentValue && currentValue.length > 0)
            setValue("departmentParent", currentValue[0]);
        else
            setValue("departmentParent", null);
    }

    const evaluateManager = () => {

        setManagers(dataMaster?.manager);
        const currentValue = dataMaster?.manager.filter(c => { return c.id === department.data.managerId });
        if (currentValue && currentValue.length > 0)
            setValue("manager", currentValue[0]);
        else
            setValue("manager", null);
    }

    const getDepartmentById = (departmentId) => {
        dispatch(DeparmentsAction.getDepartmentById(departmentId));
    }

    const onSubmit = (data) => {
        //debugger;
        const model = {
            id: data.id,
            managerId: data.manager?.id,
            parentDepartmentId: data.departmentParent?.id,
            name: data.name,
        }
        dispatch(DeparmentsAction.saveDepartment(model));
    };

    const reject = () => {
        setVisible(false);
    };

    return (
        <div className="bg-white">
            <form
                className="form-custom p-0"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="form-modal p-0">
                    <Panel header="DATOS GENERALES" toggleable>

                        <div className=" grid  sm:grid-cols-1 md:grid-cols-2 gap-2 ">

                            {department.isLoading ? (
                                <Skeleton height="2.2rem" />
                            ) : (
                                <span className="p-float-label w-full mt-4">

                                    <Controller
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { invalid, isTouched, isDirty, error },
                                        }) => (
                                            <InputText
                                                type="text"
                                                id="name"
                                                name="name"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                className={errors?.name ? 'p-invalid w-full' : 'w-full'}
                                            />
                                        )}
                                        name="name"
                                        rules={{
                                            required: 'El nombre del departamento es requerido',
                                        }}
                                    />
                                    <label htmlFor="name">Departamento *</label>
                                </span>
                            )}

                            <span className="p-float-label w-full mt-4 hidden md:block">
                                &nbsp;
                            </span>

                            {department.isLoading ? (
                                <Skeleton height="2.2rem" />
                            ) : (
                                <span className="p-float-label w-full mt-4">

                                    <Controller
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { invalid, isTouched, isDirty, error },
                                        }) => (
                                            <Dropdown
                                                id="departmentParent"
                                                name="departmentParent"
                                                options={departmentParents}
                                                optionLabel="name"
                                                filter
                                                showClear
                                                filterBy="name"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                className={errors?.departmentParent ? 'p-invalid w-full' : 'w-full'}
                                            />
                                        )}
                                        name="departmentParent"
                                    />

                                    <label htmlFor="departmentParent">Departamento Principal</label>
                                </span>
                            )}

                            {department.isLoading ? (
                                <Skeleton height="2.2rem" />
                            ) : (
                                <span className="p-float-label w-full mt-4">
                                    <Controller
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { invalid, isTouched, isDirty, error },
                                        }) => (
                                            <Dropdown
                                                id="manager"
                                                name="manager"
                                                optionLabel="fullName"
                                                options={managers}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                className={errors?.manager ? 'p-invalid w-full' : 'w-full'}
                                            />
                                        )}
                                        name="manager"                                        
                                    />


                                    <label htmlFor="managerId">Gerente o jefe Encargado</label>
                                </span>
                            )}

                        </div>

                    </Panel>
                    <br />
                    {
                        params?.id && <Panel header="HISTORIAL" toggleable>

                        </Panel>
                    }
                    {

                        (errors.name || errors.manager) && <div className='message error'>
                            <ul>
                                {errors.name ? (
                                    <>
                                        <li className="p-error">{errors.name.message}</li>
                                    </>
                                ) : null}
                                {errors.manager ? (
                                    <>
                                        <li className="p-error">{errors.manager.message}</li>
                                    </>
                                ) : null}
                            </ul>

                        </div>
                    }

                </div>
                <div className="flex justify-end gap-4 mt-3">
                    <Button
                        icon="pi pi-times"
                        type="button"
                        label="Cancelar"
                        onClick={() => { setVisible(true); }}
                        className="sig-button sig-secondary mt-4"
                    />
                    {
                        (mode!=='view')&&(<>
                            {
                                (isCreate || isEdit)&&
                                <Button
                                    icon="pi pi-save"
                                    type="submit"
                                    label="Guardar"
                                    loading={department?.isSaving || department?.isLoading}
                                    className="sig-button sig-primary mt-4"
                                />
                            }
                        </>
                        )
                    }
                </div>
            </form>
            <ConfirmDialog
                visible={visible}
                onHide={() => setVisible(false)}
                message="¿Está seguro que desea cancelar?"
                header="Cancelar"
                icon="pi pi-exclamation-triangle"
                accept={redirectToSearch}
                reject={reject}
            />
        </div>

    )
}

export default DepartmentPage;