import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModulesActions } from 'features/subscription/store/actions/ModulesIsoActions';

const useFilter = () => {
  const dispatch = useDispatch();
  const fieldName = useRef(null);

  const { valuesModule  } = useSelector((state) => state.modulesIsoReducer.moduleFilter);
  const { loadingModules } = useSelector((state) => state.modulesIsoReducer.modules);

  const reportLoading = useSelector(
    (state) => state.modulesIsoReducer.downloadReport.loading
  );

  const buildFields = () => {
    if(fieldName.current!==null){
      const value = fieldName.current.value;
      const fields = {};
      fields.names = value;

      if (valuesModule.length > 0) {
        const fieldTo = valuesModule.filter((val) => val.field === 'toDate');
        if (fieldTo.length > 0)
          fields.to = fieldTo[0].date;
        else 
          fields.to = null;

        const fieldFrom = valuesModule.filter((val) => val.field === 'fromDate');
        if (fieldFrom.length > 0) 
          fields.from = fieldFrom[0].date;
        else 
          fields.from = null;
      }
      if (!fields.names) 
        delete fields.names;
      return fields;
    }
  };

  const filterNames = () => {
    dispatch(ModulesActions.getModules({ change: true, fields: buildFields() }));
  };

  const filterModules = (payLoad) => {    
    if (payLoad.name === "") {      
      dispatch(ModulesActions.searchModules(payLoad));
    }else if(payLoad.name.length >= 3){
      dispatch(ModulesActions.searchModules(payLoad));
    }    
  };

  const handleChange = () => {
    if (fieldName.current.value === "") {
      filterNames();
      dispatch(ModulesActions.removeFilterValues('name'));
    }
  };

  const showPiners = () => {    
    if (valuesModule.length > 0 && valuesModule[0].value !== null && valuesModule[0].value !== '') return true;
    else return false;
  };

  const removePiner = (field) => {
    dispatch(ModulesActions.removeFilterValues(field));
  };

  const generateReport = () => {
    dispatch(ModulesActions.generateReport(valuesModule));    
  };

  return {
    valuesModule,
    loadingModules,
    reportLoading,
    filterModules,
    handleChange,
    showPiners,
    removePiner,
    generateReport
  };
};

export default useFilter;



