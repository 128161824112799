import { MaintenanceConstants } from "shared/commons/MaintenanceConstans";
import { CoreConstants } from 'core/commons/CoreConstants';
import moment from 'moment';
import { toastAction } from "core/store/actions/ToastAction";
import FileSaver from 'file-saver';
import { HomeService } from "shared/services/HomeServices";


const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: MaintenanceConstants.Accion.Home.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};
const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: MaintenanceConstants.Accion.Home.RESET_AFTER_SAVED });
};

const getOptionsDropDown = () =>{
  const setOptionsDropDown = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Home.GET_OPTIONS_DROPDOWN,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    HomeService.getOptions().then(
      ({data}) => {
        if (data.status === CoreConstants.HttpResponse.OK){
          setOptionsDropDown({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        }else if (data.status < CoreConstants.HttpResponse.OK){
          setOptionsDropDown({ dispatch: dispatch, isLoading: false, data: null });
        }
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setOptionsDropDown({ dispatch: dispatch, isLoading: false, data: null });
        }
      },
      () => {
        setOptionsDropDown({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  }
}
const getIndentifiers = () =>{
  const setIdentifiers = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Home.GET_IDENTIFIERS,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setIdentifiers({
      dispatch: dispatch,
      isLoading: true,
      data: null,
    });
    HomeService.getIndentifiers().then(
      ({data}) => {
        if (data.status === CoreConstants.HttpResponse.OK){
          setIdentifiers({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        }else if (data.status === CoreConstants.HttpResponse.OK){
          setIdentifiers({ dispatch: dispatch, isLoading: false, data: null });
        }
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn('Identificadores', data.message));
          setIdentifiers({ dispatch: dispatch, isLoading: false, data: null });
        }else{
          dispatch(toastAction.error('Identificadores', data.message));
          setIdentifiers({ dispatch: dispatch, isLoading: false, data: null });
        }
      },
      () => {
        setIdentifiers({ dispatch: dispatch, isLoading: false, data: null });
      }
    ).catch((error)=>{
    });
  }
}
const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload;

    if (advancedFilter.filter.title)
      pines.push({ field: 'title', value: advancedFilter.filter.title });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.business)
      pines.push({
        field: 'business',
        value: advancedFilter.filter.business.code
      });

      if (advancedFilter.filter.customers)
      pines.push({
        field: 'customers',
        value: advancedFilter.filter.customers.name
      })

      if (advancedFilter.filter.projects)
      pines.push({
        field: 'projects',
        value: advancedFilter.filter.projects.name
      })

      if (advancedFilter.filter.taskSource)
      pines.push({
        field: 'taskSource',
        value: advancedFilter.filter.taskSource.description
      })

      if (advancedFilter.filter.taskStatus)
      pines.push({
        field: 'taskStatus',
        value: advancedFilter.filter.taskStatus.description
      })

      if (advancedFilter.filter.responsibles){
        advancedFilter.filter.responsibles.forEach((responsible)=>{
          pines.push({
            field: 'responsibles',
            value: responsible.fullName,
            id: responsible.id,
          })
        })
      }

      // console.log(advancedFilter)

    advancedFilter.values = pines;
    dispatch({
      type: MaintenanceConstants.Accion.Home.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = (NumStatus) => {
  const setHome = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Home.SEARCH_HOME,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const dataHome = getState().homeReducer.dataHome;
    const user = getState().authReducer.user;
    const isAdmin = user?.roles?.some((role) => role.codigo === CoreConstants.Roles.ADMIN);
    setHome({
      dispatch: dispatch,
      isLoading: true,
      data: dataHome.data,
    });

    const criteria = {};
    const advancedFilter = getState().homeReducer.advancedFilter;
    const filter = getState().homeReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;
    // criteria.toDate = filter.to?moment(filter.to).format(CoreConstants.Format.DDMMYYYY):null;
    // criteria.fromDate = filter.from?moment(filter.from).format(CoreConstants.Format.DDMMYYYY):null;
    criteria.title = filter.title?filter.title:null;
    criteria.customerId = filter.customers?.id?filter.customers.id:null;
    criteria.projectId = filter.projects?.id?filter.projects.id:null;
    criteria.businessId = filter.business?.id?filter.business.id:null;
    criteria.sourceId = filter.taskSource?.id?filter.taskSource.id:null;
    criteria.statusId = filter.taskStatus?.id?filter.taskStatus.id:null;
    // criteria.responsibles = filter.responsibles?.length > 0 ? filter.responsibles.map( ({ id }) => id ) : null;
    criteria.responsibles = !isAdmin ? [user.id] : filter.responsibles?.length > 0 ? filter.responsibles.map( ({ id }) => id ) : null;

    criteria.status = advancedFilter.status?advancedFilter.status:null

    HomeService.searchHome(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setHome({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
        setHome({ dispatch: dispatch, isLoading: false, data: null });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setHome({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Ocupaciones', data.message));
        }
      },
      () => {
        setHome({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  };
};

const getHomeById = (taskId) => {
  const setTask = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Home.GET_HOME_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setTask({ dispatch: dispatch, isLoading: true });
    HomeService.getHomeById(taskId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setTask({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setTask({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Ocupaciones', data.message));
          // dispatch({
          //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
          //   toast: {
          //     titulo: 'Departamento',
          //     mensaje: data.message,
          //     severidad: 'warn',
          //   },
          // });
        }
      },
      () => {
        setTask({ dispatch: dispatch, isLoading: false });
      }
    );
  };
};

const saveHome = (task) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentTask }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Home.SAVE_HOME,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    const currentTask = getState().homeReducer.task;
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
      currentTask: currentTask,
    });
    HomeService.saveHome(currentTask).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Tarea', 'La tarea se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentTask, });
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL)
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentTask });
        else
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });

      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    );
  };
};



const deleteHome = (taskId) => {

  return (dispatch, getState) => {

    HomeService.deleteHome(taskId).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Ocupación', 'La ocupación se eliminó correctamente'));
          dispatch({ type: MaintenanceConstants.Accion.Home.DELETE_HOME, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Ocupación', data.message));
          dispatch({ type: MaintenanceConstants.Accion.Home.DELETE_HOME, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: MaintenanceConstants.Accion.Home.DELETE_HOME, payload: { isDeleted: false } });
      }
    );
  };
};

const generateReport = (payload) => {
  const setHome = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Home.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const user = getState().authReducer.user;
    const isAdmin = user?.roles?.some((role) => role.codigo === CoreConstants.Roles.ADMIN);
    setHome({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const advancedFilter = getState().homeReducer.advancedFilter;
    const filter = getState().homeReducer.advancedFilter.filter;

    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;
    // criteria.toDate = filter.to?moment(filter.to).format(CoreConstants.Format.DDMMYYYY):null;
    // criteria.fromDate = filter.from?moment(filter.from).format(CoreConstants.Format.DDMMYYYY):null;
    criteria.title = filter.title?filter.title:null;
    criteria.customerId = filter.customers?.id?filter.customers.id:null;
    criteria.projectId = filter.projects?.id?filter.projects.id:null;
    criteria.businessId = filter.business?.id?filter.business.id:null;
    criteria.sourceId = filter.taskSource?.id?filter.taskSource.id:null;
    criteria.statusId = filter.taskStatus?.id?filter.taskStatus.id:null;
    // criteria.responsibles = filter.responsibles?.length > 0 ? filter.responsibles.map( ({ id }) => id ) : null;
    criteria.responsibles = !isAdmin ? [user.id] : filter.responsibles?.length > 0 ? filter.responsibles.map( ({ id }) => id ) : null;

    criteria.status = advancedFilter.status?advancedFilter.status:null

    HomeService.generateReport(criteria)
      .then(({ data }) => {
        setHome({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Tareas.xls');
      })
      .catch((err) => {
        setHome({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Tareas', 'Hubo un error al descargar el archivo'));
      });
  };
};

const searchSummary = () => {
  const setSummary = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Home.SEARCH_SUMMARY,
      payload: { isLoading, data },
    });
  };
  return (dispatch, getState) => {
    setSummary({
      dispatch: dispatch,
      isLoading: true,
      data: null,
    });

    const criteria = {};
    const advancedFilter = getState().homeReducer.advancedFilter;
    const filter = getState().homeReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;
    // criteria.toDate = filter.to?moment(filter.to).format(CoreConstants.Format.DDMMYYYY):null;
    // criteria.fromDate = filter.from?moment(filter.from).format(CoreConstants.Format.DDMMYYYY):null;
    criteria.title = filter.title?filter.title:null;
    criteria.customerId = filter.customers?.id?filter.customers.id:null;
    criteria.projectId = filter.projects?.id?filter.projects.id:null;
    criteria.businessId = filter.business?.id?filter.business.id:null;
    criteria.sourceId = filter.taskSource?.id?filter.taskSource.id:null;
    criteria.statusId = filter.taskStatus?.id?filter.taskStatus.id:null;
    criteria.responsibles = filter.responsibles?.length > 0 ? filter.responsibles.map( ({ id }) => id ) : null;

    criteria.status = advancedFilter.status?advancedFilter.status:null

    HomeService.searchSummary(criteria).then(
      ({data}) => {
        if (data.status === CoreConstants.HttpResponse.OK){
          setSummary({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        }else if (data.status < CoreConstants.HttpResponse.OK){
          setSummary({ dispatch: dispatch, isLoading: false, data: null });
        }
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setSummary({ dispatch: dispatch, isLoading: false, data: null });
        }
      },
      () => {
        setSummary({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  }
}


export const HomeActions = {
  toggleAdvancedFilter,
  updateFilter,
  search,
  saveHome,
  deleteHome,
  generateReport,
  resetAfterSaved,
  getHomeById,
  getOptionsDropDown,
  searchSummary,
  getIndentifiers
}