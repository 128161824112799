import { useAdvancedFilterCompanies } from "features/customers/hooks/companies/useAdvancedFilterCompanies";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import Message from "shared/components/messages/Message";

import ModalFilterComponent from "shared/components/modal-filter/ModalFilterComponent";

export const AdvancedFilterComponent = () => {
  const {
    onToggleModal,
    clearFields,
    onAdvancedFilter,
    showModal,
    dataMaster,
    formValues,
    handleChange,
    errors,
  } = useAdvancedFilterCompanies();

  if (showModal) {
    return (
      <ModalFilterComponent
        closeModal={() => onToggleModal(false)}
        clearFields={clearFields}
        handleSubmit={onAdvancedFilter}
      >
        <div className="flex gap-4 pt-3">
          <div className="w-full">
            <span className="p-float-label">
              <Calendar
                name="fromDate"
                value={formValues.fromDate}
                onChange={handleChange}
              />
              <label htmlFor="desde">Desde</label>
            </span>
          </div>
          <div className="w-full">
            <span className="p-float-label">
              <Calendar
                name="toDate"
                value={formValues.toDate}
                onChange={handleChange}
              />
              <label htmlFor="hasta">Hasta</label>
            </span>
          </div>
        </div>

        <div className="flex gap-4 pt-7">
          <div className="w-full">
            <span className="p-float-label">
              <Calendar
                name="fromRegistrationDate"
                // dateFormat={CoreConstants.Format.DDMMYYYY}
                value={formValues.fromRegistrationDate}
                onChange={handleChange}
              />
              <label htmlFor="fromRegistrationDate">Registro desde</label>
            </span>
          </div>
          <div className="w-full">
            <span className="p-float-label">
              <Calendar
                name="toRegistrationDate"
                // dateFormat={CoreConstants.Format.DDMMYYYY}
                value={formValues.toRegistrationDate}
                onChange={handleChange}
              />
              <label htmlFor="toRegistrationDate">Registro hasta</label>
            </span>
          </div>
        </div>

        <div className="flex pt-7">
          <div className="w-full">
            <span className="p-float-label">
              <Dropdown
                options={dataMaster.businessLines}
                optionLabel="description"
                filter
                filterBy="description"
                showClear
                className="w-full"
                name="businessLineId"
                value={formValues.businessLineId}
                onChange={handleChange}
              />

              <label htmlFor="dropdown">Por Línea de Negocio</label>
            </span>
          </div>
        </div>
        <div className="flex pt-7">
          <div className="w-full">
            <span className="p-float-label">
              <Dropdown
                options={dataMaster.countries}
                optionLabel="niceName"
                filter
                filterBy="niceName"
                showClear
                className="w-full"
                name="countryId"
                value={formValues.countryId}
                onChange={handleChange}
              />

              <label htmlFor="dropdown">Por País</label>
            </span>
          </div>
        </div>
        <div className="pt-7 w-2/4">
          <span className="p-float-label w-full">
            <InputText
              type="number"
              name="documentNo"
              className="w-full"
              value={formValues.documentNo}
              onChange={(e) => {
                if(e.target.value?.length > 50){
                  e.target.value = e.target.value.slice(0,50)
                }
                handleChange(e)
              }}
            />
            <label htmlFor="quantityActiveEmployees">RUC</label>
          </span>
        </div>
        {errors.length > 0 && <Message messages={errors} status="error" />}
      </ModalFilterComponent>
    );
  } else {
    return null;
  }
};
