import { SurveyAction } from "features/survey/store/actions/SurveyAction";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
// import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

const useSurveyPreview = () => {
  const dispatch = useDispatch();
  const {isLoading, data: surveyData} = useSelector((state) => state.surveyReducer.survey);
  // const {categories, surveyTypes, questionTypes} = useSelector((state) => state.surveyReducer.dataMaster);

  useEffect(() => {
    dispatch(SurveyAction.getDataMaster());
  }, [dispatch])

  const typeQuestionValue = (type) => {
    switch (type) {
      case 'TX':
        return '';
      case 'OM':
        return [];
      case 'CV':
        return '';
      case 'LD':
        return '';
      case 'RE':
        return '';
      default:
        return 'dasd';
    }
  }

  const questions = surveyData ? surveyData.sections.reduce((acc, cur) => {
    acc.push(...cur.questions)
    return acc
  }, []) : null

  const defaultValues = (surveyData && questions) ? questions.reduce((acc, cur, index) => {
    acc[`question_${index+1}`] = typeQuestionValue(cur.questionType?.code)
    return acc
  }, {}) : null

  const { setValue, handleSubmit, control, watch, formState: { errors }, reset, trigger, clearErrors } = useForm({defaultValues});

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues)
    }
  }, [surveyData])
  
  const valuesFields = watch();
  const existError = Object.keys(errors).length > 0
  // console.log(defaultValues)
  // console.log(valuesFields)
  

  

  return {
    control,
    valuesFields,
    errors,
    setValue,
    handleSubmit,
    reset,
    existError,
    surveyData,
    questions,
    trigger,
    clearErrors
  }
}

export default useSurveyPreview