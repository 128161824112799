import { apiService } from 'core/services/ApiService';

const getSubscriptions = (fields = {}) => {
  let initialFields = {
    "page": 1,
    "pageSize": 10,
    "columnOrder": "customerName",
    "order": "desc",
    "customerName": null,
    "from": null,
    "to": null,
    "customerId": null,
    "usersQuantity": null,
    "numberActiveUsers":null,
    "statusId": null
  }
  initialFields = { ...initialFields, ...fields };
  return apiService.Post('management/api/Subscription/Search', initialFields);
};


const getDataMaster = () =>{
  let fields = {
    "isCustomer": true,
    "isStatus": true,
    "isCountry": true,
    "isOptionIso": true
  }
  return apiService.Post('management/api/Subscription/GetDataMaster',fields);
}


const saveSubscription = (info) =>{
  // console.log('save', info);
  return apiService.Post('management/api/Subscription/Save',info);

}

const generateReport = (info) =>{
  let fields ={
    "customerName": null,
    "from": null,
    "to": null,
    "customerId": null,
    "usersQuantity": null,
    "numberActiveUsers": null,
    "statusId": null
  }
  fields = { ...fields, ...info };
  return apiService.Post('management/api/Subscription/GenerateReport',fields, {
    responseType: 'blob',
  });
}

const deleteCustomerId = (id) =>{
  return apiService.Get('management/api/Subscription/DeleteSubscription?subscriptionId='+parseInt(id))
}

const getSubscriptionById = (id = null) =>{
  if(!id){
    return apiService.Get('management/api/Subscription/GetSubscriptionById')

  }else{
    return apiService.Get('management/api/Subscription/GetSubscriptionById?subscriptionId='+id)

  }
}

const changeStatus = (info) =>{
  return apiService.Post('management/api/Subscription/ChangeStatus',info);
}

const renewSubscription = (info) => {
  return apiService.Post('management/api/Subscription/Renew' , info)
}

export const SubscriptionsService = {
  getSubscriptions,
  renewSubscription,
  changeStatus,
  generateReport,
  getDataMaster,
  deleteCustomerId,
  getSubscriptionById,
  saveSubscription
};
