import { Excel , Pdf , Word, Image} from '../../../shared/assets/img/images'

const getIconByExtension = (name) => {
  if (name.includes(".pdf")) {
    return Pdf
  } else if (name.includes(".xlsx") || name.includes(".xls")) {
    return Excel
  } else if (name.includes(".docx") || name.includes(".doc")) {
    return Word
  } else if (name.includes(".jpg") || name.includes(".jpeg") || name.includes(".png")) {
    return Image
  } else {
    return Pdf
  }
}

const Attachment = ({ file , deleteAttachment, isFullWidth }) =>{
  return (
    <div className={`relative border border-gray-300 flex-col items-center justify-center p-2 col-span-4  ${isFullWidth ? 'sm:col-span-2' : 'lg:col-span-2'}  ${isFullWidth ? 'lg:col-span-1' : '3xl:col-span-1'}`}>
      <i onClick={ () => deleteAttachment(file.id)} className='pi pi-times-circle absolute -right-2 text-red-500' style={{top:"-10px"}}></i>
      <img 
        className="m-auto" 
        // src={file.name.includes(".pdf") ? Pdf : file.name.includes(".xlsx") ? Excel : Word} 
        src={getIconByExtension(file.name)}
        alt={file.name} 
        title={file.name} 
        style={{height:"60px",width:"60px",objectFit:"contain"}} 
      />
      <div className=''>
        {/* <h6 className='text-xs text-center'>{file.name.substr(0,10)}</h6> */}
        <h6 className='text-xs text-center truncate'>{file.name}</h6>
      </div>
    </div>
  )
}


export default Attachment