import { CoreConstants } from "core/commons/CoreConstants";
import moment from "moment";

export const getByIdAdapter = (data, isTemplate) => ({
  id: data.id,
  code: data.code,
  name: data.name,
  description: data.description,
  typeId: data.typeId,
  statusId: isTemplate ? null : data.statusId,
  statusCode: isTemplate ? null : data.businessSheetStatus.code,
  link: isTemplate ? null : data.link,
  questions: data.question.map(questionAdapter(data.sections, isTemplate)),
  notifications: isTemplate ? [] : data.notifications,
  sections: data.sections.map(sectionAdapter(isTemplate)),
});

const sectionAdapter = (isTemplate) => (section, index) => ({
  id: isTemplate ? -1 * (index + 1) : section.id,
  label: section.label,
});

const questionAdapter = (sections, isTemplate) => (question) => ({
  id: question.id,
  fieldLabel: question.configuration.label,
  required: question.configuration.isRequired,
  controlId: question.configuration.controlId,
  allowAddRows: question.configuration.addRowsAllowed,
  fixFirstColumn: question.configuration.firstColumnFixed,
  multiple: question.configuration.allowMultiple,
  linkTo: question.configuration.linkedFieldId,
  isContactList: question.configuration.isContactList,
  options: question.configuration.options?.map(questionOptionsAdapter),
  rows: question.configuration.rows?.map(questionRowAdapter),
  columns: question.configuration.columns?.map(questionColumAdapter),
  sectionId: isTemplate
    ? question.configuration.businessSheetSectionId
      ? -1 *
        (sections.findIndex(
          (section) => section.id === question.configuration.businessSheetSectionId
        ) +
          1)
      : null
    : question.configuration.businessSheetSectionId,
});

const questionOptionsAdapter = (option) => ({
  id: option.id,
  name: option.name,
});

const questionColumAdapter = (column) => ({
  id: column.id,
  header: column.header,
});

const questionRowAdapter = (row) => ({
  id: row.id,
  title: row.label,
});

export const sheetSearchAdapter = (sheet) => ({
  id: sheet.id,
  code: sheet.code,
  name: sheet.name,
  status: sheet.status,
  type: sheet.type,
  creationDate: sheet.creationDate
    ? moment(sheet.creationDate).format(CoreConstants.Format.DDMMYYYY)
    : "-",
  statusCode: sheet.statusCode,
});
