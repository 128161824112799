import { useSelector } from "react-redux";
import { Checkbox } from "primereact/checkbox";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Skeleton } from "primereact/skeleton";
import { InputSwitch } from "primereact/inputswitch";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Message from "shared/components/messages/Message";

import "./UserPage.scss";
import TableEmpty from "shared/components/table-empty/TableEmpty";
import limitCharacters from "shared/utils/limitCharacters";
import pluralOrSingularText from "shared/utils/pluralOrSingularText";
import useUser from "features/configuration/hooks/users/useUser";
import { Dropdown } from "primereact/dropdown";
import { useClaimUsers } from "features/configuration/hooks/useClaimUsers";

const UserPage = ({ title = "Nuevo Usuario", mode }) => {
  const {
    loading,
    data: { roles, company },
  } = useSelector((state) => state.userReducer.dataManager);
  const { status } = useSelector((state) => state.userReducer.saveUser);

  const { isEdit, isCreate } = useClaimUsers();

  const {
    onSelectedImage,
    isUserNew,
    inputFile,
    srcAvatar,
    isAdmin,
    setUserData,
    isActive,
    setIsActive,
    userData,
    isCheckedLocation,
    isCheckedRole,
    createOrEditUser,
    setSrcAvatar,
    handleRoleChange,
    handleLocationChange,

    errors,
    setErrors,
    handleClick,
    visible,
    setVisible,
    accept,
    reject,
    disabledButtonState,
    userEdit,
    dataManager,
  } = useUser({ title });

  const rolesFilter = roles?.filter((r) => r.code !== "ADMIN");
  const roleAdmin = roles?.filter((r) => r.code === "ADMIN")[0];
  const roleAdminId = roleAdmin?.id;
  const { data } = userEdit;

  const handlerChange = (e) => {
    setErrors({
      ...errors,
      [e.target.name]: false,
    });
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const SkeletonCustom = ({ width = "100%" }) => {
    return <Skeleton width={width} height="2.2rem" />;
  };

  return (
    <div className="bg-white">
      <form
        className="form-custom p-0"
        onSubmit={createOrEditUser}
        onChange={handlerChange}
      >
        <Panel header="DATOS GENERALES" toggleable>
          <div className="flex lg:flex-row flex-col items-start gap-3 justify-between mb-6 w-full">
            <div className="field-row w-full lg:order-0 order-1">
              {userEdit.loading || loading ? (
                <SkeletonCustom />
              ) : (
                <span className="p-float-label w-full mt-4">
                  <InputText
                    type="email"
                    id="user_name"
                    name="userName"
                    className={errors?.userName ? "p-invalid w-full" : "w-full"}
                    value={userData?.userName}
                    readOnly={mode === 'view'}
                  />
                  <label htmlFor="user_name">Usuario *</label>
                </span>
              )}
              {userEdit.loading || loading ? (
                <SkeletonCustom />
              ) : (
                <span className="p-float-label w-full">
                  <InputText
                    type="email"
                    id="user_email"
                    name="email"
                    className={errors?.email ? "p-invalid w-full" : "w-full"}
                    value={userData?.email}
                    readOnly={mode === 'view'}
                  />
                  <label htmlFor="user_email">Correo Eléctronico * </label>
                </span>
              )}
              {userEdit.loading || loading ? (
                <SkeletonCustom />
              ) : (
                <span className="p-float-label w-full">
                  <InputText
                    type="text"
                    id="user_firstname"
                    name="firstName"
                    className={
                      errors?.firstName ? "p-invalid w-full" : "w-full"
                    }
                    value={userData?.firstName}
                    readOnly={mode === 'view'}
                  />
                  <label htmlFor="user_firstname">Nombres *</label>
                </span>
              )}
              <div className="user-picture">
                {userEdit.loading || loading ? (
                  <Skeleton shape="circle" size="6rem"></Skeleton>
                ) : (
                  <div>
                    <div className="foreground-user-picture">
                      {srcAvatar === null ? (
                        <i
                          className="pi pi-camera cursor-pointer"
                          onClick={() => mode === 'view' ? () => {} : inputFile.current.click()}
                        ></i>
                      ) : (
                        <i
                          className="pi pi-trash cursor-pointer"
                          onClick={() => mode === 'view' ? () => {} :  setSrcAvatar(null)}
                        ></i>
                      )}
                    </div>

                    <img
                      src={
                        srcAvatar === null
                          ? "/images/decorations/avatar.png"
                          : srcAvatar
                      }
                      className="avatar"
                      alt={isUserNew ? "" : data?.avatar}
                    />
                    <input
                      type="file"
                      className="hidden"
                      ref={inputFile}
                      onChange={onSelectedImage}
                    />
                  </div>
                )}
              </div>
              {userEdit.loading || loading ? (
                <SkeletonCustom />
              ) : (
                <span className="p-float-label w-full">
                  <InputText
                    type="text"
                    id="user_lastname"
                    name="lastName"
                    className={errors?.lastName ? "p-invalid w-full" : "w-full"}
                    value={userData?.lastName}
                    readOnly={mode === 'view'}
                  />
                  <label htmlFor="user_lastname">Apellidos *</label>
                </span>
              )}
              {userEdit.loading || loading ? (
                <SkeletonCustom />
              ) : (
                <span className="p-float-label w-full">
                  <Dropdown
                    id="employeeId"
                    name="employeeId"
                    optionLabel="name"
                    showClear
                    value={userData?.employeeId}
                    options={dataManager?.data.employees}
                    disabled={mode === 'view'}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        employeeId: e.target.value,
                      })
                    }
                    optionValue={(e) => {
                      return e.id;
                    }}
                  />
                  <label htmlFor="employeeId">Empleado</label>
                </span>
              )}
            </div>
            <div className="flex gap-2 justify-end lg:order-1 order-0">
              {userEdit.loading || loading ? (
                <>
                  <SkeletonCustom width="100%" />
                  <SkeletonCustom width="100%" />
                </>
              ) : (
                <div className="state-field">
                  <span htmlFor="user_state">Estado</span>
                  <InputSwitch
                    checked={isActive}
                    
                    disabled={disabledButtonState || mode === 'view'}
                    onChange={(e) => setIsActive(e.value)}
                  />
                </div>
              )}
            </div>
          </div>
        </Panel>
        {!loading && roles?.length === 0 && (
          <div className="mt-4">
            <TableEmpty textAditional="Listado de Roles no encontrado" />
          </div>
        )}
        {!loading && roles?.length > 0 && (
          <Panel header="ROLES *" toggleable className="mt-3">
            <div className="user-roles py-2 px-5">
              <div className="flex items-center gap-6">
                <InputSwitch
                  name={roleAdminId}
                  checked={isCheckedRole({ id: roleAdminId })}
                  onChange={handleRoleChange}
                  className={errors?.roleIds ? "p-invalid " : ""}
                  disabled={mode === 'view'}
                />
                <p title={roleAdmin?.description}>
                  {limitCharacters(roleAdmin?.description, 28)}{" "}
                </p>
              </div>
              {rolesFilter.map(({ description, id }, index) => (
                <div className="flex items-center gap-6" key={index}>
                  <InputSwitch
                    disabled={isAdmin || mode === 'view'}
                    name={id}
                    checked={isCheckedRole({ id })}
                    className={errors?.roleIds ? "p-invalid " : ""}
                    onChange={handleRoleChange}
                  />
                  <p title={description}>{limitCharacters(description, 28)} </p>
                </div>
              ))}
            </div>
          </Panel>
        )}

        {loading && (
          <Panel header="ROLES *" toggleable className="mt-3">
            <div className="user-roles py-2 px-5">
              {[1, 2, 3, 4].map((i) => (
                <SkeletonCustom width="100%" key={i} />
              ))}
            </div>
          </Panel>
        )}

        {!loading && company?.length === 0 && (
          <div className="mt-4">
            <TableEmpty textAditional="Listado de Compañias no encontrado" />
          </div>
        )}

        {!loading && company?.length > 0 && (
          <Panel
            header={pluralOrSingularText({
              cant: company.length,
              textPlural: "EMPRESAS *",
              textSingular: "EMPRESA *",
            })}
            toggleable
            className="mt-3"
          >
            {company.map((c, i) => (
              <Accordion key={i}>
                <AccordionTab
                  header={
                    <div>
                      <i className="pi pi-building"></i> {c.businessName}
                    </div>
                  }
                >
                  {c.locations.length > 0 ? (
                    <div>
                      <h3 className="mb-3 font-bold">
                        {pluralOrSingularText({
                          cant: c.locations.length,
                          textPlural: "SEDES",
                          textSingular: "SEDE",
                        })}
                      </h3>
                      {c.locations.map(({ id, name }, index) => (
                        <div className="item-location mb-1" key={index}>
                          <p>{name}</p>
                          <Checkbox
                            name={id}
                            checked={isCheckedLocation({ id })}
                            onChange={handleLocationChange}
                            className={errors?.locationIds ? "p-invalid " : ""}
                            // disabled={mode === 'view'}
                            readOnly={mode === 'view'}
                          ></Checkbox>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="mt-4">
                      <TableEmpty textAditional="Listado de locaciones no encontrado" />
                    </div>
                  )}
                </AccordionTab>
              </Accordion>
            ))}
          </Panel>
        )}

        {loading && (
          <Panel header="EMPRESAS *" toggleable className="mt-3">
            <div className="user-roles py-2 px-5">
              {[1, 2, 3, 4].map((i) => (
                <SkeletonCustom width="100%" key={i} />
              ))}
            </div>
          </Panel>
        )}
        {errors?.list?.length > 0 && (
          <Message messages={errors.list} status="error" />
        )}

        <div className="flex justify-end gap-4 mt-3">
          <Button
            icon="pi pi-times"
            type="button"
            label="Cancelar"
            onClick={() => setVisible(true)}
            disabled={status === ""}
            className="sig-button  sig-secondary mt-4"
          />
          {
            (mode!=='view')&&(<>
            {
              (isCreate || isEdit)&&
              <Button
                icon="pi pi-save"
                type="submit"
                label="Guardar"
                onClick={handleClick}
                loading={status === ""}
                className="sig-button sig-primary mt-4"
              />
            }
            </>
            )
          }
        </div>
      </form>
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="¿Está seguro que desea cancelar?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
    </div>
  );
};

export default UserPage;
