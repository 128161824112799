import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useParams } from 'react-router-dom';
import useSubscriptions from 'features/subscription/hooks/useSubscriptions';
const BlockSubscription = ({close,visible,statusId}) => {
  const params = useParams();
  const {
    changeStatus,
    loadingDataManager
  } = useSubscriptions("Editado",false)

  const headerTemplate = () => {
    return <h1 className="fs-title-modal">¿ESTÁ SEGURO?</h1>;
  }

  const changeState = () =>{
    const data = {
      "subscriptionId": parseInt(params.id),
      "statusId": statusId,
      "fromDate": null,
      "toDate": null,
      "firstName": null,
      "lastName": null,
      "email": null
    }
    close();
    changeStatus(data,"BLOQUEADO")
  }
  return (
    <Dialog
    header={headerTemplate}
    visible={visible}
    closable
    breakpoints={{ "960px": "75vw", "640px": "100vw" }}
    style={{ width: "50vw" }}
    onHide={() => close()}
  >
    <div
      className="line-through bg-blue-800"
      style={{ height: "7.5px", width: "50px" }}
    ></div>
    <h2 className="font-bold fs-subtitle-modal mt-3 mb-16">
      ¿Desea bloquear la suscripción?
    </h2>
    <div className="col-span-5 flex justify-end gap-2 w-full">
      <Button
        icon="pi pi-times"
        type="button"
        label="Cancelar"
        onClick={() => {
          close()
        }}
        disabled={loadingDataManager}
        className="sig-button  sig-secondary mt-4"
      />
      <Button
        icon="pi pi-save"
        type="button"
        label="Guardar"
        onClick={() => changeState()}
        loading={loadingDataManager}
        disabled={loadingDataManager}
        // loading={status === ''}
        className="sig-button sig-primary mt-4"
      />
    </div>
  </Dialog>
  );
};

export default BlockSubscription
