import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Checkbox } from 'primereact/checkbox'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { Editor } from 'primereact/editor'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { RadioButton } from 'primereact/radiobutton'
import React, { useState } from 'react'
import AttachmentInput from '../AttachmentInput'
import HeaderEditor from 'shared/components/header-editor/HeaderEditor'
import '../GeneralData.scss'
import { MultiSelect } from 'primereact/multiselect'

export const PrevisualizationRender= ({input, dropRows, addRows, index, disabled, responsables}) => {

    const header = HeaderEditor();

    // console.log(input)

  return (
    <>
      {
        (input.typeOptionId==='ID') &&(
          <div className='w-2/5 mt-2'>
          <InputText value={input.viewID}
            disabled
            className="disabled"
            />
          </div>)
      }
      {
        input.typeOptionId==='TEXTO'&&(<div className={`borderInput h-10 mt-2 ${disabled&&'disabled'}`}></div>)
      }
      {
        input.typeOptionId==='TEXTO_LARGO'&&<div className={`borderInput h-16 mt-2 ${disabled&&'disabled'}`}></div>
      }
      {
        input.typeOptionId==='TEXTO_DINAMICO'&&<Editor headerTemplate={header} style={{ height: '70px'}} value='' readOnly/>
      }
      {
        input.typeOptionId==='NUMERO'&&<div className={`borderInput h-10 mt-2 ${disabled&&'disabled'}`}></div>
      }
     {
      input.typeOptionId==='FECHA'&&(
        <Calendar
          showButtonBar
          showIcon
          placeholder="Seleccione fecha"
          className='mt-2'
          disabled
        />
       )
     }
     {
      input.typeOptionId==='FECHA_HORA'&&(
        <Calendar
          className='mt-2'
          showButtonBar
          showTime
          showIcon
          placeholder='Seleccione fecha y hora'
          disabled
        />)
     }
     {
      input.typeOptionId==='LISTA_DESPLEGABLE'&&(
        <Dropdown 
          id={`dropDown-${input.fieldLabel}`} 
          options={input.options} 
          optionLabel="name" 
          placeholder="Seleccione una opción" 
          className={`mt-2 ${disabled&&'disabled'}`}
        />)
     }
     {
      input.typeOptionId==='SELECCION_MULTIPLE'&&(
        input.options.map((item, i) => {
          return (
          <div key={`checkb-${i}`} className="field-checkbox">
            <Checkbox inputId={item.code} name={item.code} value={item.name} disabled={item.disabled} />
            <label htmlFor={item.code}> {item.name}</label>
          </div>)
         })
      )
     }
    {
      input.typeOptionId==='SELECCION_SIMPLE'&&(
        input.options.map((item, i) => {
          return (
              <div key={`radiob-${i}`} className="field-radiobutton">
                  <RadioButton inputId={item.code} name={item.code} value={item} disabled={item.disabled} />
                  <label htmlFor={item.code}> {item.name}</label>
              </div>
          )
      })
      )
     }
    {
        input.typeOptionId==='TABLA'&&(
          <>
          <div className='flex text-center mt-2 pb-4'>
          <DataTable
            value={input.numRows}
            stripedRows
            rows={5}
            editMode="row"
            dataKey="id"
            rowHover
            size="small"
            responsiveLayout="scroll"
            className='overflow-x-auto'
           >
                {input.options.map((item) => 
                <Column
                  key={item.name}
                  field={item.name}
                  header={()=>
                    !input.showHeader&&<div className='flex items-center'><i className={`mt-1 mr-2 ${item.Column.icon}`} />{item.name} </div>
                }
                  // sortable
                  style={{width: item.name? 'w-2':'4%'}}
                  body={() => {
                    if(item?.Column?.code === "USUARIOS"){
                      return(
                        <>
                          {
                            disabled?<div className='borderInput h-10 mt-2 w-44 disabled'></div>:(<>
                                {
                                  input.readOnly===false?<div className='w-44'><Dropdown placeholder='Seleccione un usuario' className='w-44' /></div>:<Dropdown disabled placeholder='Seleccione un usuario' className='w-44' />
                                }
                            </>)
                          }
                        </>
                      )
                    }
                  return(
                    <>
                      {
                        disabled?<div className='borderInput h-10 mt-2 w-44 disabled'></div>:(<>
                            {
                              input.readOnly===false?<div className='w-44'><InputText className='w-44' /></div>:<div className='borderInput h-10 mt-2 w-44'></div>
                            }
                        </>)
                      }
                    </>
                  )}}
                />
                )}
                {
                  !disabled&&(<Column header={<div></div>} body={
                    <i
                      onClick={() => {
                        dropRows(index, input)
                      }}
                      className="pi pi-trash cursor-pointer"
                    />}
                  />)
                }
              </DataTable>
            </div>
            {
              !disabled&&(<>
              {
              input.readOnly===false&&(
                <Button icon="pi pi-plus"
                         type="button"
                         label="Añadir Fila"
                         className={` sig-button sig-primary w-auto pointer mt-3`}
                         onClick={()=> {addRows(index,input)}}/>
              )
            }
              </>)
            }
            </>
          )
        }
        {
          input.typeOptionId==='MONEDA'&&(
            <div className='borderInput h-10 mt-2 flex items-center pl-2'><p>{input.selectedCoin?.typeColumn==='Nuevos Soles'?'S/. ':'$ '}</p></div>
            // <InputText value=/>
          )
        }
        {
          input.typeOptionId==='IMAGEN'&&(
            // <div className='flex flex-col justify-center items-center'>
            //   <i className="pi pi-image mt-3 p-5" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
            //   <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}}>Selecciona el área o arrastra tu archivo para adjuntar</span>
            // </div>
            <AttachmentInput typeOptionId={input.typeOptionId} readOnly={input.readOnly}  />
          )
        }
        {
          input.typeOptionId==='ADJUNTAR_ARCHIVO'&&(
            // <div className='flex flex-col justify-center items-center'>
            //   <i className="pi pi-cloud-upload mt-3 p-5" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
            //   <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}}>Selecciona el área o arrastra tu archivo para adjuntar</span>
            // </div>
            <AttachmentInput typeOptionId={input.typeOptionId} readOnly={input.readOnly}  />
          )
        }
        {
          input.typeOptionId==='ETIQUETA'&&(<div className='border-2 rounded-md border-blue-400 h-12 border-dashed flex items-center pl-3 text-blue-600 text-sm'><strong>+ {input.fieldLabel}</strong></div>)
        }
        {
          input.typeOptionId==='USUARIOS' && (
              input.numberOfEntry?.code === 'entry_single' ? (
                <Dropdown 
                  id={`Users-${input.fieldLabel}`} 
                  options={[]} 
                  optionLabel="fullName" 
                  placeholder="Seleccione un usuario" 
                  className={`mt-2 ${disabled&&'disabled'}`}
                  filterBy="fullName"
                  filter 
                  disabled={true}
                />
              ) : (
                <MultiSelect 
                  // value={value} 
                  options={[]} 
                  // onChange={(e) => onChange(e.value)} 
                  optionLabel="fullName" 
                  maxSelectedLabels={3} 
                  placeholder='Seleccione los usuarios'
                  style={{width: '100%'}}
                  disabled={true}
                />
              )
            )
        }
        {
          input.typeOptionId==='CONEXION_A_PROCESOS' && (
            <div 
              // className='flex mt-3 text-indigo-600 text-xs gap-x-1 items-center font-bold'
              className='flex mt-3 controls-text-primary-color text-xs gap-x-1 items-center font-bold'
              onClick={()=>{}}
              >
              <i className="pi pi-plus text-xs font-bold"></i>
              <p className=''>Añadir Registro</p>
            </div>
          )
        }
        {
          input.typeOptionId==='CONEXION_A_DATOS' && (
            <div 
              className='flex mt-3 controls-text-primary-color text-xs gap-x-1 items-center font-bold'
              onClick={()=>{}}
              >
              <i className="pi pi-plus text-xs font-bold"></i>
              <p>Añadir Registro</p>
            </div>
          )
        }
    </>
  )
}
