export class CompaniesConstants {
  static Accion = class {
    static Companies = class {
      static get ADVANCED_FILTER() {
        return '@COMPANIES.ADVANCED-FILTER';
      }
      static get SEARCH_COMPANIES() {
        return '@COMPANIES.SEARCH-COMPANIES';
      }
      static get DATA_MASTER() {
        return '@COMPANIES.DATA-MASTER';
      }
      static get CURRENT_ACTION() {
        return '@COMPANIES.CURRENT-ACTION';
      }
      static get EDIT_COMPANY() {
        return '@COMPANIES.EDIT-COMPANY';
      }
      static get REPORT_STATUS() {
        return '@COMPANIES.REPORT-STATUS';
      }
      static get RESET_AFTER_SAVED() {
        return '@COMPANIES.RESET_AFTER_SAVED';
      }
      static get SEARCH_BUSINESS() {
        return '@COMPANIES.SEARCH_BUSINESS';
      }
      static get UPLOAD_BATCH_INFO() {
        return '@COMPANIES.UPLOAD_BATCH_INFO';
      }
      static get SAVE_COMPANY_BULK() {
        return '@COMPANIES.SAVE_COMPANY_BULK';
      }
    };
  };
  static SectionsName = class {
    static get DATOS_GENERALES() {
      return 'DATOS GENERALES';
    }
    static get OFICINA_PRINCIPAL() {
      return 'OFICINA PRINCIPAL';
    }
    static get INFORMACION_DE_CONTACTO() {
      return 'INFORMACIÓN DE CONTACTO';
    }
    static get CONTACTOS() {
      return 'CONTACTOS';
    }
  }
}
