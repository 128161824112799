/* eslint-disable default-case */
import { Fragment, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import PinerComponent from 'shared/components/Piner/PinerComponent';
import { Panel } from 'primereact/panel';
import { DeparmentsAction } from 'features/rrhh/store/actions/DepartmentAction';
import { useDispatch, useSelector } from 'react-redux';
import { useClaimDepartment } from 'features/rrhh/hooks/useClaimDepartment';

const FilterComponent = () => {
    const dispatch = useDispatch();

    const advancedFilter = useSelector((state) => state.departmentReducer.advancedFilter);
    const values = useSelector((state) => state.departmentReducer.advancedFilter.values);
    const criteriaDeparment = useRef(null);
    const { isLoading, data } = useSelector((state) => state.departmentReducer.departments);
    

    const openModal = () => {
        dispatch(DeparmentsAction.toggleAdvancedFilter({ showModal: true }));
    };
    const { isSearch, isDownload } = useClaimDepartment()

    const showPiners = () => {
        if (values?.length > 0)
            return true;
        return false;
    };

    const removePiner = (field) => {
        switch (field) {
            case 'name':
                advancedFilter.filter.name = null;
                criteriaDeparment.current.value = '';
                break;
            case 'to':
                advancedFilter.filter.to = null;
                break;
            case 'from':
                advancedFilter.filter.from = null;
                break;
            case 'departmentParent':
                advancedFilter.filter.departmentParent = null;
                break;
            case 'manager':
                advancedFilter.filter.manager = null;
                break;
        }

        dispatch(DeparmentsAction.updateFilter(advancedFilter));
        dispatch(DeparmentsAction.search());

    };


    const search = () => {
        const value = criteriaDeparment.current.value;

        if (value !== null && value !== undefined && value.trim().length > 0) {
            advancedFilter.filter.name = value;
            dispatch(DeparmentsAction.updateFilter(advancedFilter));
        }
        dispatch(DeparmentsAction.search());
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter')
            search();
    }

    const generateReport = () => {
        const value = criteriaDeparment.current.value;
        if (value !== null && value !== undefined && value.trim().length > 0) {
            advancedFilter.filter.name = value;
            dispatch(DeparmentsAction.updateFilter(advancedFilter));
        }
        dispatch(DeparmentsAction.generateReport());
    };


    return (
        <Fragment>
            <Panel header={`${isSearch?"FILTRO POR":"DESCARGA"}`} toggleable>
                <div className="filter-components">

                <div className={`${isSearch?'visible':'invisible'}`}>
                        <div className="w-full ">
                            <span className="p-float-label p-input-icon-left w-full">
                                <i className="pi pi-search" />
                                <InputText
                                    id="input-search"
                                    ref={criteriaDeparment}
                                    onKeyDown={handleKeyDown}
                                />
                                <label htmlFor="input-search">Buscar por departamentos</label>
                            </span>
                            {showPiners() && (
                                <div className="filter-piners mt-3">
                                    {values.map(({ value, field }, index) => (
                                        <PinerComponent
                                            name={value}
                                            field={field}
                                            removePiner={removePiner}
                                            key={index}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    

                        <div className="filter-users-action">
                            <div className="flex gap-3 w-full">
                                {
                                    isSearch&&(
                                        <Button
                                            icon="pi pi-search"
                                            type="button"
                                            label="Buscar"
                                            onClick={search}
                                            loading={isLoading}
                                            className="sig-button sig-primary w-full"
                                        />
                                    )
                                }
                                {
                                    isDownload&&(
                                        <Button
                                            icon="pi pi-download"
                                            type="button"
                                            loading={isLoading}
                                            onClick={generateReport}
                                            label="Descargar"
                                            className="sig-button sig-success  w-full"
                                        />
                                    )
                                }
                            </div>
                            <div className="flex mt-3">
                                {
                                    isSearch&&(
                                        <Button
                                            type="button"
                                            label="Búsqueda avanzada"
                                            className="sig-button  sig-secondary w-full"
                                            onClick={openModal}
                                        />
                                    )
                                }
                            </div>
                        </div>

                    

                </div>
            </Panel>

        </Fragment>
    );
};

export default FilterComponent;
