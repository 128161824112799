import React, { useRef } from 'react'
import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu'

const itemsSplitButtonSaveExample = [
  {
    label: 'Guardar',
    icon: "pi pi-save",
    type: "submit",
    command: ()=>{},
  },
  {
    label: 'Guardar y Aprobar',
    icon: "pi pi-upload",
    type: "button",
    command: ()=>{},
  }
];

export const SplitButtonSave = ({items = itemsSplitButtonSaveExample, styleMenu, ...propsButton}) => {
  const menu = useRef(null)
  return (
    <>
      <Button
        {...propsButton}
        icon="pi pi-angle-double-up"
        onClick={(event) => menu.current.toggle(event)}
      />
      <Menu
         model={items}
         popup
         ref={menu}
         style={styleMenu}
      />
    </>
  )
}
