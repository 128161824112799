
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { ProcessOrDataCard } from './ProcessOrDataCard'

// const ProcessOrDataCard = ({process, onSelected, dataSelected}) => {

//   const isSelected = dataSelected.some((item) => item.id === process.id)
//   // ${isSelected ? 'border-blue-500' : ''}

//   return (
//     <div 
//       className={`flex w-full border border-l-4 py-2 px-4 rounded-md gap-x-4 shadow-sm cursor-pointer 
//         ${isSelected ? 'bg-gray-100 shadow-md' : ''}
//         hover:bg-gray-50
//       `}
//       onClick={() => onSelected(process)}
//     >
//       <div className='flex justify-center items-center px-2'>
//         <i className={`pi ${process.icon} shadow-md text-2xl rounded opacity-80`} />
//       </div>
//       <div className='flex-1 flex flex-col gap-y-1'>
//         <p className='font-bold'>{process.text || 'dad'}</p>
//         <div className='text-gray-400 text-sm font-semibold'>
//           <p>Creado el {moment(process.date).format('LL')}</p>
//           <p>{process.status} </p>
//         </div>
//       </div>
//     </div>
//   )
// }

// const temporalData = [
//   {
//     id: 1,
//     name: 'Proceso 1',
//     date: '2022-12-16T22:13:48.6172065+00:00',
//     status: 'En Proceso',
//     icon: 'pi-server',
//   },
//   {
//     id: 2,
//     name: 'Proceso 2',
//     date: '2022-12-16T22:13:48.6172065+00:00',
//     status: 'Completado',
//     icon: 'pi-server',
//   },
// ]

const ModalDataAndProcesses = ({displayModal, setDisplayModal, title, dataDetails, isMultiple, setValue, control, formValues}) => {

  const [search, setSearch] = useState('')
  const [showDoneRecords, setShowDoneRecords] = useState(true)
  const [dataSelected, setDataSelected] = useState([])

  // const [data, setData] = useState(temporalData)

  useEffect(() => {
    if (displayModal && formValues && formValues.length > 0) {
      setDataSelected(formValues)
      return
    }
    setDataSelected([])
  }, [formValues, displayModal])
  

  //* Borrar el agregado de campos luego de que la bd lo implemente
  const [data, setData] = useState(dataDetails?.map((process, index) => {
    return {
      ...process,
      date: '2022-12-16T22:13:48.6172065+00:00',
      status: index % 2 === 0 ? 'En Proceso' : 'Completado',
      icon: 'pi-server',
    }
  }))
  const [temporalData] = useState(dataDetails?.map((process, index) => {
    return {
      ...process,
      date: '2022-12-16T22:13:48.6172065+00:00',
      status: index % 2 === 0 ? 'En Proceso' : 'Completado',
      icon: 'pi-server',
    }
  }))


  const onChangeSearch = (event) => {
    setSearch(event.target.value)
  }

  const onSearch = () => {
    if (search === '') {
      setData(temporalData)
      return
    }
    const newData = temporalData.filter((process) => process.text.toLowerCase().includes(search.toLowerCase()))
    setData(newData)
  }

  const onHide = () => {
    setDisplayModal(false)
    setSearch('')
    setDataSelected([])
    setShowDoneRecords(true)
  }

  const onConfirm = () => {
    setValue(control, dataSelected)
    onHide()
  }

  // proccess: {id, name}
  const onSelected = (process) => {
    if (isMultiple) {
      const index = dataSelected.findIndex((item) => item.id === process.id)
      if (index === -1) {
        setDataSelected([...dataSelected, process])
        return
      }
      setDataSelected(dataSelected.filter((item) => item.id !== process.id))
      return
    }
    setDataSelected([process])
  }

  useEffect(() => {
    if (showDoneRecords) {
      setData(temporalData.filter((process) => process.status === 'Completado'))
      return
    }
    setData(temporalData)
  }, [showDoneRecords])

  return (
    <Dialog header={title} headerStyle={{padding: "15px"}} visible={displayModal} className='w-3/4 md:w-2/4 xl:w-2/5'  onHide={() => onHide()}>
      <div className='w-full flex flex-col gap-y-4 mt-4 border-bottom-modal'>
        <span className="p-float-label p-input-icon-right w-full">
          {/* <label htmlFor='searchProcessOrData'>Buscar</label> */}
          <InputText 
            id='searchProcessOrData'
            value={search} 
            onChange={onChangeSearch}
            className='w-full'
            placeholder='Buscar'
            onKeyPress={(e) => {
              if (e.key === 'Enter') onSearch()
            }}
          />
          <i className="pi pi-search" />
        </span>
        {/* Texto que dependiendo isMultiple mostrar que seleccione uno o mas */}
        <p className='text-gray-400 text-sm font-semibold text-center'>
          {isMultiple ? 'Seleccione uno o más registros' : 'Seleccione un registro'}
        </p>
        <div className='flex flex-col gap-y-2'>
          {
            data.map((process) => (
              <ProcessOrDataCard 
                process={process} 
                key={process.id} 
                onSelected={onSelected} 
                dataSelected={dataSelected}
              />
            ))
          }
        </div>
        <div className='flex gap-x-1 items-center'>
          <InputSwitch checked={showDoneRecords} onChange={(e) => setShowDoneRecords(e.value)} />
          <p>Mostrar solo los registros finalizados</p>
        </div>
        <div className='flex justify-end items-center gap-x-2'>
          <Button
              icon="pi pi-times"
              type="button"
              label="Cancelar"
              onClick={onHide}
              className="sig-button sig-secondary mt-4"
          />
          <Button
              icon="pi pi-save"
              type="button"
              // disabled={existError ? true : false}
              label={`Guardar ${dataSelected.length > 0 ? `(${dataSelected.length})` : ''}`}
              className={`sig-button sig-primary mt-4`}
              onClick={onConfirm}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default ModalDataAndProcesses