import { useForm } from 'react-hook-form';

const useFilePeople = () => {

  const {
    // register,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    id: null,
    producedBy: null,
    dateElaboration: null,
    modifyDate: null,
    version: null,
    modifyName: "",
    flowId: null,
  });

  const valuesFields = watch();
  const existError = Object.keys(errors).length > 0

  const validateLength = (value) => {
    if (typeof value === 'string') {
      return value.replace(/<[^>]+>/g, '')?.length;
    } else if (value?.htmlValue) {
      return value.htmlValue.replace(/<[^>]+>/g, '').length;
    }
    return '0';
  };

  return {
    control,
    errors,
    valuesFields,
    existError,
    setValue,
    handleSubmit,
    validateLength,
  }
}

export default useFilePeople