import { CoursesManagmentConstants } from 'features/coursesManagment/commons/CoursesManagmentConstants';

const initialState = {
  paymentCourses: {
    data: [],
    isLoading: true,
    isSaved: false,
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'id',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {},
    status: null
  },
  studentSelected: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false
  },
  coursesAndPaymentMethod: {
    data: null,
    isLoading: false,
  },
  students: {
    data: null,
    isLoading: false,
  },
  reports: {
    data: null,
    isLoading: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CoursesManagmentConstants.Accion.PaymentCourse.SAVE_PAYMENT_COURSE:
        return {
            ...state,
            studentSelected: {
                ...state.studentSelected.data,
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };
    case CoursesManagmentConstants.Accion.PaymentCourse.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
    case CoursesManagmentConstants.Accion.PaymentCourse.UPDATE_FILTER:
        return {
            ...state,
            advancedFilter: state.advancedFilter
    };
    case CoursesManagmentConstants.Accion.PaymentCourse.DOWNLOAD_REPORT_STATUS:
        return {
            ...state,
            reports:{
              data: action.payload.data,
              isLoading: action.payload.isLoading
            },
    };
    case CoursesManagmentConstants.Accion.PaymentCourse.SEARCH:
      return {
        ...state,
        paymentCourses: {
          data: action.payload.data,
          isLoading: action.payload.isLoading
        },
      };
      case CoursesManagmentConstants.Accion.PaymentCourse.GET_COURSES_AND_PAYMENT:
        return {
            ...state,
            coursesAndPaymentMethod: {
              data: action.payload.data,
              isLoading: action.payload.isLoading
            },
        };
      case CoursesManagmentConstants.Accion.PaymentCourse.GET_LIST_STUDENTS:
        return {
            ...state,
            students: {
              data: action.payload.data,
              isLoading: action.payload.isLoading
            },
        };
        case CoursesManagmentConstants.Accion.PaymentCourse.RESET_AFTER_SAVED:
          return {
              ...state,
              studentSelected: {
                  data: null,
                  isSaving: false,
                  isSaved: false,
                  isLoading: false,
                  isDeleted: false
              },
              students: {
                data: null,
                isLoading: false
              },
          };

        case CoursesManagmentConstants.Accion.PaymentCourse.GET_COURSE_PAYMENT_BY_ID:
            return {
                ...state,
                studentSelected: {
                    data: action.payload.data,
                    isSaving: false,
                    isLoading: action.payload.isLoading
                }
              };
          case CoursesManagmentConstants.Accion.PaymentCourse.DELETE_PAYMENT:
            return {
                ...state,
                studentSelected: {
                    isDeleted: action.payload.isDeleted
                }
            };
    default:
      return state;
  }
};
