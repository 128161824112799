/* eslint-disable react-hooks/exhaustive-deps */
import { CoreConstants } from "core/commons/CoreConstants";
import { toastAction } from "core/store/actions/ToastAction";
import { AttachmentSheet } from "features/business/components/businessSheet/fieldsPublic/AttachmentSheet";
import { CheckBoxSheet } from "features/business/components/businessSheet/fieldsPublic/CheckBoxSheet";
import { DropDownSheet } from "features/business/components/businessSheet/fieldsPublic/DropDownSheet";
import { InputTextSheet } from "features/business/components/businessSheet/fieldsPublic/InputTextSheet";
import { RadioButtonSheet } from "features/business/components/businessSheet/fieldsPublic/RadioButtonSheet";
import { TableSheet } from "features/business/components/businessSheet/fieldsPublic/TableSheet";
import { BusinessSheetPublicService } from "features/business/services/BusinessSheetPublicService";
import { BusinessSheetPublicAction } from "features/business/store/actions/BusinessSheetPublicAction";
import SurveyPublicMessage from "features/survey/components/Survey/SurveyPublicMessage";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LogoComponent from "shared/components/logo/LogoComponent";
import useSetTitlePage from "shared/hooks/useSetTitlePage";

export const PageBusinessSheetPublic = () => {
  const { updateTitle } = useSetTitlePage();
  const [isIosPlatform, setIsIosPlatform] = useState(false);
  const [loadingSchema, setLoadingSchema] = useState(true);
  const contacts = useState([]);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ shouldUnregister: true });
  const dispatch = useDispatch();
  const businessSheet = useSelector(
    (state) => state.businessSheetPublicReducer.businessSheet.businessSheet
  );
  const loadingConfig = useSelector(
    (state) => state.businessSheetPublicReducer.businessSheet.loading
  );
  const errorFetch = useSelector(
    (state) => state.businessSheetPublicReducer.businessSheet.error
  );
  const isLoadingSave = useSelector(
    (state) => state.businessSheetPublicReducer.save.isLoadingSave
  );
  const success = useSelector((state) => state.businessSheetPublicReducer.save.success);
  const error = useSelector((state) => state.businessSheetPublicReducer.save.error);
  const params = useParams();
  const businessSheetGuId = params.id;
  const loading = loadingSchema && loadingConfig;

  useEffect(() => {
    updateTitle({
      title: "Ficha de negocio",
      subtitle: "Nueva Ficha",
    });

    dispatch(BusinessSheetPublicAction.getByGuId(businessSheetGuId));
  }, []);

  useEffect(() => {
    const isIos = (() => {
      // if (/windows/i.test(navigator.userAgent)) {
      //     return "Windows"
      // }
      if (/iphone/i.test(navigator.userAgent)) {
        return true;
      } else if (/ipad/i.test(navigator.userAgent)) {
        return true;
      } else if (/ipod/i.test(navigator.userAgent)) {
        return true;
      }
      return false;
      // else if (/macintosh/i.test(navigator.userAgent)) {
      //     return "Mac OS"
      // }
    })();

    setIsIosPlatform(isIos);
  }, []);

  useEffect(() => {
    setLoadingSchema(true);
    BusinessSheetPublicService.getLogoAndColorSchema(params.id)
      .then((c) => {
        if (c.data.status === CoreConstants.HttpResponse.OK) {
          const colorSchema = c.data.data.filter((c) => {
            return c[0].code === "COLOR_SCHEMA";
          })[0];
          const companyLogo = c.data.data.filter((c) => {
            return c[0].code === "COMPANY_LOGO";
          })[0];
          if (colorSchema.length > 0) {
            const headerBackgroundColor = colorSchema[0].details.filter((c) => {
              return c.code === "BACKGROUND_COLOR";
            })[0];
            const menuDefault = colorSchema[0].details.filter((c) => {
              return c.code === "MENU_DEFAULT";
            })[0];
            const menuHover = colorSchema[0].details.filter((c) => {
              return c.code === "MENU_HOVER";
            })[0];
            const menuSelected = colorSchema[0].details.filter((c) => {
              return c.code === "MENU_SELECTED";
            })[0];

            const btnPrimary = colorSchema[0].details.filter((c) => {
              return c.code === "BUTTON_PRIMARY";
            })[0];
            const btnSeconday = colorSchema[0].details.filter((c) => {
              return c.code === "BUTTON_SECONDARY";
            })[0];
            const btnSuccess = colorSchema[0].details.filter((c) => {
              return c.code === "BUTTON_SUCCESS";
            })[0];
            const btnDark = colorSchema[0].details.filter((c) => {
              return c.code === "BUTTON_DARK";
            })[0];
            const btnError = colorSchema[0].details.filter((c) => {
              return c.code === "BUTTON_ERROR";
            })[0];
            const btnWarning = colorSchema[0].details.filter((c) => {
              return c.code === "BUTTON_WARNING";
            })[0];

            document.documentElement.style.setProperty(
              "--sig-header-background-color",
              headerBackgroundColor.value
            );
            document.documentElement.style.setProperty(
              "--sig-menu-default",
              menuDefault.value
            );
            document.documentElement.style.setProperty(
              "--sig-menu-hover",
              menuHover.value
            );
            document.documentElement.style.setProperty(
              "--sig-menu-selected",
              menuSelected.value
            );

            document.documentElement.style.setProperty("--sig-primary", btnPrimary.value);
            document.documentElement.style.setProperty(
              "--sig-secondary",
              btnSeconday.value
            );
            document.documentElement.style.setProperty("--sig-success", btnSuccess.value);
            document.documentElement.style.setProperty("--sig-dark", btnDark.value);
            document.documentElement.style.setProperty("--sig-error", btnError.value);
            document.documentElement.style.setProperty("--sig-warning", btnWarning.value);
          }

          if (companyLogo.length > 0) {
            // const logoDark = companyLogo[0].details.filter(c => { return c.code === 'LOGO_DARK' })[0];
            const logoDefault = companyLogo[0].details.filter((c) => {
              return c.code === "LOGO_DEFAULT";
            })[0];

            if (logoDefault.value.length > 0)
              document.documentElement.style.setProperty(
                "--sig-logo-default",
                'url("' + logoDefault.value + '")'
              );
          }
        } else {
          toastAction.error("Error", "Se produjo un error al cargar la configuración");
        }
        setLoadingSchema(false);
      })
      .catch((c) => {
        // setLoading(false);
      });
  }, [params.id]);

  const onSubmit = (data) => {
    const send = {
      guId: businessSheetGuId,
      answers: [],
    };
    const fieldRuc = businessSheet.questions.find(
      (question) => question.linkedFieldCode === "BSNS_SHT_CUST_RUC"
    );
    if (fieldRuc) {
      send.ruc = data[`question_${fieldRuc.id}`];
    }
    const fieldName = businessSheet.questions.find(
      (question) => question.linkedFieldCode === "BSNS_SHT_CUST_NAME"
    );
    if (fieldRuc) {
      send.customerName = data[`question_${fieldName.id}`];
    }

    Object.keys(data).forEach((key) => {
      const keysSplit = key.split("_");
      if (Array.isArray(data[key])) {
        if (data[key].length === 0) {
          send.answers.push({
            questionId: Number(keysSplit[1]),
            value: null,
            valueId: null,
            rowNumber: keysSplit[3] ? Number(keysSplit[3]) : null,
            columnNumber: keysSplit[4] ? Number(keysSplit[4]) : null,
          });
        } else {
          data[key].forEach((value) => {
            send.answers.push({
              questionId: Number(keysSplit[1]),
              value: value.name,
              valueId: value.id,
              rowNumber: keysSplit[3] ? Number(keysSplit[3]) : null,
              columnNumber: keysSplit[4] ? Number(keysSplit[4]) : null,
            });
          });
        }
      } else if (typeof data[key] === "string") {
        send.answers.push({
          questionId: Number(keysSplit[1]),
          value: data[key] === "" ? null : data[key],
          valueId: null,
          rowNumber: keysSplit[3] ? Number(keysSplit[3]) : null,
          columnNumber: keysSplit[4] ? Number(keysSplit[4]) : null,
        });
      } else if (typeof data[key] === "object") {
        send.answers.push({
          questionId: Number(keysSplit[1]),
          value: data[key].name,
          valueId: data[key].id,
          rowNumber: keysSplit[3] ? Number(keysSplit[3]) : null,
          columnNumber: keysSplit[4] ? Number(keysSplit[4]) : null,
        });
      } else if (!data[key]) {
        send.answers.push({
          questionId: Number(keysSplit[1]),
          value: null,
          valueId: null,
          rowNumber: keysSplit[3] ? Number(keysSplit[3]) : null,
          columnNumber: keysSplit[4] ? Number(keysSplit[4]) : null,
        });
      } else {
        send.answers.push({
          questionId: Number(keysSplit[1]),
          value: null,
          valueId: null,
          rowNumber: keysSplit[3] ? Number(keysSplit[3]) : null,
          columnNumber: keysSplit[4] ? Number(keysSplit[4]) : null,
        });
      }
    });

    const tableContatcList = businessSheet.questions.find(
      (question) => question.isContactList
    );

    let contacts = [];

    if (tableContatcList) {
      const questionId = tableContatcList.id;
      const notifications = {};
      // Filtrar y estructurar las notificaciones
      for (const key in data) {
        if (key.startsWith(`question_${questionId}_table_`)) {
          const parts = key.split("_");
          const row = parts[3];
          const col = parts[4];

          if (!notifications[row]) {
            notifications[row] = {};
          }

          // eslint-disable-next-line default-case
          switch (col) {
            case "1":
              notifications[row].name = data[key];
              break;
            case "2":
              notifications[row].emailAddress = data[key];
              break;
            case "3":
              notifications[row].phoneNo = data[key];
              break;
            case "4":
              notifications[row].position = data[key];
              break;
          }
        }
      }

      // Convertir el objeto a un array
      contacts = Object.values(notifications);
    }

    send.contacts = contacts;

    console.log(send);

    dispatch(BusinessSheetPublicAction.save(send));
  };

  if (success) {
    return (
      <SurveyPublicMessage
        message="Se han registrado sus respuestas"
        type="success"
        title="¡Muchas gracias por llenar la ficha!"
      />
    );
  }

  if (errorFetch) {
    return (
      <SurveyPublicMessage
        message="La ficha no se encuentra disponible"
        type="error"
        title="¡Algo salió mal!"
      />
    );
  }

  if (error) {
    return (
      <SurveyPublicMessage
        message="Ocurrió un error al registrar sus respuestas"
        type="error"
        title="¡Algo salió mal!"
      />
    );
  }

  const renderQuestions = () => {
    return businessSheet.questions?.map((question, index) => {
      if (question.sectionId !== null && question.sectionId !== undefined) {
        const section = businessSheet.sections.find((s) => s.id === question.sectionId);
        const isFirstInSection =
          index === 0 ||
          businessSheet.questions[index - 1].sectionId !== question.sectionId;

        if (isFirstInSection) {
          const sectionQuestions = businessSheet.questions.filter(
            (q) => q.sectionId === question.sectionId
          );

          return (
            <div className="flex flex-col gap-2">
              <div className="header-title py-4 px-4 bg-gray-100 shadow border-2 flex flex-col page-break">
                <div className="flex items-center gap-1">
                  <span dangerouslySetInnerHTML={{ __html: section.label }}></span>
                </div>
              </div>
              <div className="pl-8 flex flex-col gap-2">
                {sectionQuestions.map((question, indexQuestion) => {
                  if (question.controlCode === "TEXTO")
                    return (
                      <InputTextSheet
                        key={question.id}
                        control={control}
                        numberQuestion={index + indexQuestion + 1}
                        question={question}
                      />
                    );

                  if (question.controlCode === "LISTA_DESPLEGABLE")
                    return (
                      <DropDownSheet
                        key={question.id}
                        control={control}
                        numberQuestion={index + indexQuestion + 1}
                        question={question}
                      />
                    );

                  if (question.controlCode === "SELECCION_MULTIPLE")
                    return (
                      <CheckBoxSheet
                        key={question.id}
                        control={control}
                        numberQuestion={index + indexQuestion + 1}
                        question={question}
                      />
                    );

                  if (question.controlCode === "SELECCION_SIMPLE")
                    return (
                      <RadioButtonSheet
                        key={question.id}
                        control={control}
                        numberQuestion={index + indexQuestion + 1}
                        question={question}
                      />
                    );

                  if (question.controlCode === "TABLA")
                    return (
                      <TableSheet
                        key={question.id}
                        control={control}
                        numberQuestion={index + indexQuestion + 1}
                        question={question}
                      />
                    );

                  if (question.controlCode === "ADJUNTAR_ARCHIVO")
                    return (
                      <AttachmentSheet
                        key={question.id}
                        control={control}
                        numberQuestion={index + indexQuestion + 1}
                        question={question}
                      />
                    );

                  return null;
                })}
              </div>
            </div>
          );
        } else {
          return null;
        }
      } else {
        if (question.controlCode === "TEXTO")
          return (
            <InputTextSheet
              key={question.id}
              control={control}
              numberQuestion={index + 1}
              question={question}
            />
          );

        if (question.controlCode === "LISTA_DESPLEGABLE")
          return (
            <DropDownSheet
              key={question.id}
              control={control}
              numberQuestion={index + 1}
              question={question}
            />
          );

        if (question.controlCode === "SELECCION_MULTIPLE")
          return (
            <CheckBoxSheet
              key={question.id}
              control={control}
              numberQuestion={index + 1}
              question={question}
            />
          );

        if (question.controlCode === "SELECCION_SIMPLE")
          return (
            <RadioButtonSheet
              key={question.id}
              control={control}
              numberQuestion={index + 1}
              question={question}
            />
          );

        if (question.controlCode === "TABLA")
          return (
            <TableSheet
              key={question.id}
              control={control}
              numberQuestion={index + 1}
              question={question}
            />
          );

        if (question.controlCode === "ADJUNTAR_ARCHIVO")
          return (
            <AttachmentSheet
              key={question.id}
              control={control}
              numberQuestion={index + 1}
              question={question}
            />
          );

        return null;
      }
    });
  };

  return (
    <>
      <div className="h-screen overflow-y-auto py-1 px-1 md:px-4">
        <div className="flex flex-col p-2 my-4">
          {loading ? (
            <Skeleton width="10rem" />
          ) : (
            <>
              <div className={`menu-component rounded-t-lg`}>
                <div className="icon-w-logo flex justify-start items-center">
                  <LogoComponent noRedirect={true} />
                </div>
              </div>
              <div className={`border-2 shadow mb-0 rounded-b-lg bg-gray-100 `}>
                <h1 className={`font-semibold   py-2 px-3 text-center text-2xl`}>
                  {businessSheet?.name}
                </h1>
                <h2 className="  py-1 px-3  font-medium text-left">
                  <div dangerouslySetInnerHTML={{ __html: businessSheet?.description }} />
                </h2>
              </div>
            </>
          )}
          <div className="mt-4">
            <div className="flex justify-between p-2 border-2 border-b-0 border-gray-300 section-survey-component text-white rounded-t-lg">
              <h2 className="font-semibold">Preguntas</h2>
            </div>
            <div className="flex flex-col gap-2">
              {loading ? (
                <Skeleton width="15rem" />
              ) : (
                renderQuestions()
                // businessSheet.questions?.map((question, index) => {
                //   if (question.controlCode === "TEXTO")
                //     return (
                //       <InputTextSheet
                //         key={question.id}
                //         control={control}
                //         numberQuestion={index + 1}
                //         question={question}
                //       />
                //     );

                //   if (question.controlCode === "LISTA_DESPLEGABLE")
                //     return (
                //       <DropDownSheet
                //         key={question.id}
                //         control={control}
                //         numberQuestion={index + 1}
                //         question={question}
                //       />
                //     );

                //   if (question.controlCode === "SELECCION_MULTIPLE")
                //     return (
                //       <CheckBoxSheet
                //         key={question.id}
                //         control={control}
                //         numberQuestion={index + 1}
                //         question={question}
                //       />
                //     );

                //   if (question.controlCode === "SELECCION_SIMPLE")
                //     return (
                //       <RadioButtonSheet
                //         key={question.id}
                //         control={control}
                //         numberQuestion={index + 1}
                //         question={question}
                //       />
                //     );

                //   if (question.controlCode === "TABLA")
                //     return (
                //       <TableSheet
                //         key={question.id}
                //         control={control}
                //         numberQuestion={index + 1}
                //         question={question}
                //       />
                //     );

                //   if (question.controlCode === "ADJUNTAR_ARCHIVO")
                //     return (
                //       <AttachmentSheet
                //         key={question.id}
                //         control={control}
                //         numberQuestion={index + 1}
                //         question={question}
                //       />
                //     );
                //   else return <div key={question.id}>Falta implementar</div>;
                // })
              )}
            </div>
            {Object.values(errors).length > 0 && (
              <div className="message error col-span-10">
                {Object.values(errors).map((error) => (
                  <li key={error.ref.name} className="p-error">
                    {error.message}
                  </li>
                ))}
              </div>
            )}
            <div className="flex justify-end mt-4">
              <Button
                type="button"
                label="Enviar ficha"
                loading={isLoadingSave}
                className={`sig-button sig-primary`}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
          <div className={`h-12 w-full ${isIosPlatform ? "visible" : "hidden"}`} />
        </div>
      </div>
    </>
  );
};
