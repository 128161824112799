import React, { Fragment, useEffect, useState } from 'react'
// import { useDispatch } from 'react-redux';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import AdvancedFilterComponent from 'features/indicators/components/Monitoring/AdvancedFilterComponent';
import FilterComponent from 'features/indicators/components/Monitoring/FilterComponent';
import TableComponent from 'features/indicators/components/Monitoring/TableComponent';
import MonitoringModal from 'features/indicators/components/Monitoring/MonitoringModal';
import ActionPlanModal from 'features/indicators/components/Monitoring/ActionPlanModal';
import { useClaimIndicatorMonitoring } from 'features/indicators/hooks/useClaimIndicatorMonitoring';
// import { Button } from 'primereact/button';
// import IndicatorModalImport from 'features/indicators/components/Indicator/IndicatorModalImport';
// import { IndicatorsAction } from 'features/indicators/store/actions/IndicatorAction';

const SearchMonitoring = () => {
  const { updateTitle } = useSetTitlePage();
  // const history = useHistory();
  // const dispatch = useDispatch()
  const [displayModal, setDisplayModal] = useState(false)
  const [displayActionPlanModal, setDisplayActionPlanModal] = useState(false)
  const [indicatorSelected, setIndicatorSelected] = useState(null)
  const [typeModal, setTypeModal] = useState(null)
  
  useEffect(() => {
    updateTitle({
        title: 'Indicadores',
        subtitle: 'Seguimiento',
    });
  });

  const { isSearch, isDownload, isCreate } = useClaimIndicatorMonitoring();

  return (
    <Fragment>
      {
        (isSearch||isDownload)&&(
          <FilterComponent />
        )
      }
      {
        isCreate&&(
          <div className='mt-4'>
            {/* <Button
                icon="pi pi-plus"
                type="button"
                label="Nuevo"
                className="sig-button sig-dark mr-3"
                onClick={() => {
                    history.push('/indicadores/indicador/nuevo');
                }}
            /> */}
          </div>
        )
      }
      <TableComponent 
        setDisplayModal={setDisplayModal} setDisplayActionPlanModal={setDisplayActionPlanModal} 
        setIndicatorSelected={setIndicatorSelected} 
        setTypeModal={setTypeModal}
      />
      <MonitoringModal 
        displayModal={displayModal} setDisplayModal={setDisplayModal} 
        title={typeModal === 'seguimiento' ? 'Seguimiento del Indicador' : 'Gráficos del Indicador'}
        indicatorSelected={indicatorSelected} setIndicatorSelected={setIndicatorSelected}
        typeModal={typeModal} setTypeModal={setTypeModal}
      />
      <ActionPlanModal 
        displayModal={displayActionPlanModal} setDisplayModal={setDisplayActionPlanModal} 
        title={'Plan de Acción'}
        indicatorSelected={indicatorSelected} setIndicatorSelected={setIndicatorSelected}
      />
      <AdvancedFilterComponent /> 
    </Fragment>
  )
}

export default SearchMonitoring