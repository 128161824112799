/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useQuestions = (newQuestionsTemplate) => {
  const { businessSheet, loading } = useSelector(
    (state) => state.businessSheetReducer.data
  );
  const listControls = useSelector(
    (state) => state.businessSheetReducer.listControls.listControls
  );
  const [questions, setQuestions] = useState([
    {
      type: "",
      id: -1,
      fieldLabel: "",
      required: false,
      options: [],
      newQuestion: true,
      isContactList: false,
      sectionId: null,
    },
  ]);
  const [sections, setSections] = useState([]);

  const setQuestionsData = () => {
    const questions = [];
    businessSheet.questions?.forEach((question) => {
      const type = listControls.find(
        (control) => control.id === question.controlId
      )?.code;
      const nuevo = JSON.parse(JSON.stringify(newQuestionsTemplate[type]));
      nuevo.id = question.id;
      nuevo.fieldLabel = question.fieldLabel;
      nuevo.required = question.required;
      nuevo.linkTo = question.linkTo;
      nuevo.allowAddRows = question.allowAddRows;
      nuevo.columns = question.columns;
      nuevo.fixFirstColumn = question.fixFirstColumn;
      nuevo.isContactList = question.isContactList;
      nuevo.multiple = question.multiple;
      nuevo.options = question.options;
      nuevo.rows = question.rows;
      nuevo.newQuestion = false;
      nuevo.controlId = question.controlId;
      nuevo.sectionId = question.sectionId;
      questions.push(nuevo);
    });
    setQuestions(questions);
  };

  const setSectionsData = () => {
    const sections = [];
    businessSheet.sections?.forEach((section) => {
      const nuevo = {
        id: section.id,
        description: section.label,
      };
      sections.push(nuevo);
    });

    setSections(sections);
  };

  useEffect(() => {
    if (
      businessSheet.id &&
      Object.entries(newQuestionsTemplate).length > 0 &&
      listControls?.length > 0
    ) {
      setQuestionsData();
      setSectionsData();
    }
  }, [businessSheet.id, newQuestionsTemplate, listControls?.length]);

  const addSection = (index) => {
    setSections((prevSections) => {
      const newSection = {
        id: Math.min(...prevSections.map((s) => s.id), 0) - 1,
        description: "",
      };

      addQuestion(index, newSection);
      return [...prevSections, newSection];
    });
  };

  const removeSection = (sectionId) => {
    setSections((prevSections) =>
      prevSections.filter((section) => section.id !== sectionId)
    );
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.sectionId === sectionId ? { ...q, sectionId: null } : q
      )
    );
  };

  const deleteSection = (sectionId) => {
    setSections((prevSections) =>
      prevSections.filter((section) => section.id !== sectionId)
    );
    setQuestions((prevQuestions) =>
      prevQuestions.filter((q) => q.sectionId !== sectionId)
    );
  };

  const onChangeDescriptionSection = ({ id, value }) => {
    setSections((prevSections) => {
      const newSections = prevSections.map((section) => {
        if (section.id === id) section.description = value;
        return section;
      });
      return newSections;
    });
  };

  const onChangeOptions = ({ field, value, id }) => {
    setQuestions((prevQuestions) => {
      const newQuestions = prevQuestions.map((question) => {
        if (question.id !== id) return question;
        else {
          question[field] = value;
          return question;
        }
      });
      return newQuestions;
    });
  };

  const removeQuestion = (id) => {
    setQuestions((prevQuestions) =>
      prevQuestions.filter((question) => question.id !== id)
    );
  };

  const onDragEnd = (result) => {
    console.log(result);
    if (result?.source?.droppableId === "items-menu") {
      if (result?.destination?.droppableId?.startsWith("items-pregunta-destino")) {
        const id = Number(result?.destination?.droppableId?.split(":")[1]);
        setQuestions((prevQuestions) => {
          const code = result.draggableId.split(":")[0];
          const controlId = Number(result.draggableId.split(":")[1]);
          const nuevo = JSON.parse(JSON.stringify(newQuestionsTemplate[code]));
          nuevo.id = id;
          nuevo.controlId = controlId;
          return prevQuestions.map((q) => {
            if (q.id === id) {
              nuevo.fieldLabel = q.fieldLabel;
              nuevo.sectionId = q.sectionId;
              return nuevo;
            }
            return q;
          });
        });
      }
    } else if (result?.source?.droppableId === "items-destino") {
      if (result?.destination?.droppableId === "items-destino") {
        setQuestions((prevQuestions) => {
          const newQuestions = JSON.parse(JSON.stringify(prevQuestions));
          const destination = newQuestions[result.destination.index];
          const source = newQuestions[result.source.index];
          // origen y destino son elementos sin seccion
          if (destination.sectionId === null && source.sectionId === null) {
            const [removed] = newQuestions.splice(result.source.index, 1);
            newQuestions.splice(result.destination.index, 0, removed);
          }
          // origgen sin seccion y destino con seccion
          else if (source.sectionId === null) {
            const minIndex = result.destination.index;
            const sectionQuestions = newQuestions.filter(
              (q) => q.sectionId === destination.sectionId
            );
            const sectionLength = sectionQuestions.length;
            //estaba antes del grupo
            if (result.source.index < minIndex) {
              const [removed] = newQuestions.splice(result.source.index, 1);
              newQuestions.splice(
                result.destination.index + sectionLength - 1,
                0,
                removed
              );
            }
            //estaba despues del grupo
            else {
              const [removed] = newQuestions.splice(result.source.index, 1);
              newQuestions.splice(minIndex, 0, removed);
            }
          }
          // origen con seccion y destino sin seccion
          else if (destination.sectionId === null) {
            const minIndex = result.source.index;
            const sectionQuestions = newQuestions.filter(
              (q) => q.sectionId === source.sectionId
            );
            const sectionLength = sectionQuestions.length;

            const removed = newQuestions.splice(minIndex, sectionLength);
            if (minIndex < result.destination.index) {
              newQuestions.splice(
                result.destination.index - sectionLength + 1,
                0,
                ...removed
              );
            }
            //estaba despues del grupo
            else {
              newQuestions.splice(result.destination.index, 0, ...removed);
            }
          }
          // origin y destino con seccion
          else {
            const minIndexDestination = result.destination.index;
            const sectionQuestionsDestination = newQuestions.filter(
              (q) => q.sectionId === destination.sectionId
            );
            const sectionLengthDestination = sectionQuestionsDestination.length;

            const minIndexSource = result.source.index;
            const sectionQuestionsSource = newQuestions.filter(
              (q) => q.sectionId === source.sectionId
            );
            const sectionLengthSource = sectionQuestionsSource.length;

            //estaba antes del grupo
            if (minIndexSource < minIndexDestination) {
              const removed = newQuestions.splice(minIndexSource, sectionLengthSource);
              newQuestions.splice(
                minIndexDestination + sectionLengthDestination - 1,
                0,
                ...removed
              );
            }
            //estaba despues del grupo
            else {
              const removed = newQuestions.splice(minIndexSource, sectionLengthSource);
              newQuestions.splice(minIndexDestination, 0, ...removed);
            }
          }
          return newQuestions;
        });
      }
    } else if (
      result?.source?.droppableId.startsWith("section_") &&
      result?.destination?.droppableId.startsWith("section_")
    ) {
      const sectionId = Number(result?.source?.droppableId.split("_")[1]);
      const indexFirsElement = questions.findIndex(
        (question) => question.sectionId === sectionId
      );
      if (indexFirsElement >= 0) {
        const sourceIndex = result.source.index + indexFirsElement;
        const destinationIndex = result.destination.index + indexFirsElement;
        setQuestions((prevQuestions) => {
          const newQuestions = JSON.parse(JSON.stringify(prevQuestions));
          const [removed] = newQuestions.splice(sourceIndex, 1);
          newQuestions.splice(destinationIndex, 0, removed);
          return newQuestions;
        });
      }
    }
  };

  const addQuestion = (index, section) => {
    setQuestions((prevQuestions) => {
      const newId = Math.min(...prevQuestions.map((q) => q.id), 0) - 1;
      const leftQuestions = prevQuestions.slice(0, index + 1);
      const rightQuestions = prevQuestions.slice(index + 1);
      const nuevo = {
        type: "",
        id: newId,
        fieldLabel: "",
        required: false,
        options: [],
        newQuestion: true,
        sectionId: section ? section.id : null,
      };
      // return prevQuestions
      return [...leftQuestions, nuevo, ...rightQuestions];
    });
  };

  const removeField = (id) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question) => {
        if (question.id !== id) return question;
        question.type = "";
        question.newQuestion = true;
        question.options = [];
        return question;
      })
    );
  };

  return {
    loading,
    questions,
    sections,
    addSection,
    removeSection,
    deleteSection,
    addQuestion,
    onChangeDescriptionSection,
    removeQuestion,
    onChangeOptions,
    removeField,
    onDragEnd,
  };
};
