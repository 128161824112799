import { ConfigurationConstants } from 'features/configuration/commons/ConfigurationConstants';

const initialState = {
  approvalFlow: {
    data: [],
    isLoading: true,
    isSaved: false,
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'sourceTypeName',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {},
    status: null
  },
  flow: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false
  },
  users: {
    data: null,
    isLoading: false,
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ConfigurationConstants.Accion.ApprovalFlow.SAVE_APPROVAL_FLOW:
        return {
            ...state,
            flow: {
                data: action.payload.data,
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };
    case ConfigurationConstants.Accion.ApprovalFlow.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
    case ConfigurationConstants.Accion.ApprovalFlow.UPDATE_FILTER:
        return {
            ...state,
            advancedFilter: state.advancedFilter
    };
    case ConfigurationConstants.Accion.ApprovalFlow.SEARCH_FLOW:
      return {
        ...state,
        approvalFlow: {
          data: action.payload.data,
          isLoading: action.payload.isLoading
        },
      };
      case ConfigurationConstants.Accion.ApprovalFlow.DOWNLOAD_REPORT_STATUS:
          return {
              ...state,
              flow: {
                  ...state,
                  isLoading: action.payload.isLoading,
              },
          };
      case ConfigurationConstants.Accion.ApprovalFlow.GET_USERS:
        return {
            ...state,
            users: {
              data: action.payload.data,
              isLoading: action.payload.isLoading
            },
        };
        case ConfigurationConstants.Accion.ApprovalFlow.RESET_AFTER_SAVED:

          return {
              ...state,
              flow: {
                  data: null,
                  isSaving: false,
                  isSaved: false,
                  isLoading: false,
                  isDeleted: false
              }
          };

        case ConfigurationConstants.Accion.ApprovalFlow.GET_FLOW_BY_ID:
            return {
                ...state,
                flow: {
                    data: action.payload.data,
                    isSaving: false,
                    isLoading: action.payload.isLoading
                }
              };
          case ConfigurationConstants.Accion.ApprovalFlow.DELETE_FLOW:
            return {
                ...state,
                flow: {
                    isDeleted: action.payload.isDeleted
                }
            };
    default:
      return state;
  }
};
