import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { RolesAction } from 'features/configuration/store/actions/RolesAction';
import { useForm } from 'shared/hooks/useForm';
import { CoreConstants } from 'core/commons/CoreConstants';

const useAdvancedFilter = () => {
  const dispatch = useDispatch();
  const { showModal, values } = useSelector(
    (state) => state.roleReducer.filterRole
  );

  const { loading } = useSelector((state) => state.roleReducer.roles);

  const [formValues, handleInputChange, reset] = useForm({
    from: null,
    to: null,
  });

  const closeModal = () => {
    dispatch(RolesAction.toggleModalFilters({ showModal: false }));
  };

  useEffect(() => {
    if (values.length === 0) {
      reset({
        from: null,
        to: null,
      });
    } else if (values.length > 0) {
      const fields = values.map((val) => val.field);
      const fields_reset = {};

      if (!fields.includes('from')) fields_reset.from = null;
      if (!fields.includes('to')) fields_reset.to = null;

      reset({
        ...formValues,
        ...fields_reset,
      });
    }
  }, [values]);

  useEffect(() => {
    if (loading && showModal) {
      setTimeout(() => {
        closeModal();
      }, 500);
    }
  }, [loading]);

  const clearFields = () => reset();

  const handleSubmit = (e) => {
    e.preventDefault();

    const fields = formValues;
    if (values.length > 0) {
      const fieldName = values.filter((val) => val.field === 'name');
      if (fieldName.length > 0) fields.name = fieldName[0].value;
      for (let key in fields) {
        if (!fields[key]) delete fields[key];
      }
    }

    dispatch(RolesAction.getRoles({ change: true, fields: formValues }));
    const arrValues = [];

    if (fields.from)
      arrValues.push({
        field: 'from',
        value: moment(fields.from).format(CoreConstants.Format.DDMMYYYY),
        date: fields.from,
      });

    if (fields.to)
      arrValues.push({
        field: 'to',
        value: moment(fields.to).format(CoreConstants.Format.DDMMYYYY),
        date: fields.to,
      });
    if (arrValues.length > 0) dispatch(RolesAction.setFilterValues(arrValues));
  };
  return {
    showModal,
    closeModal,
    clearFields,
    handleSubmit,
    handleInputChange,
    formValues,
  };
};

export default useAdvancedFilter;
