export class DocumentConstants {
  static Accion = class {

    //DOCUMENT LIBRARY  CONSTANTS
    static DocumentLibrary = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return '@DOCUMENT_LIBRARY.ADVANCED-FILTER-TOGGLE';
      }

      static get REMOVE_FILE_DOCUMENT () {
        return '@DOCUMENT_LIBRARY.REMOVE_FILE_DOCUMENT';
      }

      static get APPROVED_DOCUMENT() {
        return '@DOCUMENT_LIBRARY.APPROVED_DOCUMENT';
      }

      static get REJECT_DOCUMENT() {
        return '@DOCUMENT_LIBRARY.REJECT_DOCUMENT';
      }

      static get GENERATE_VERSION() {
        return '@DOCUMENT_LIBRARY.GENERATE_VERSION';
      }

      static get SEARCH_DOCUMENT_LIBRARIES() {
        return '@DOCUMENT_LIBRARY.SEARCH_DOCUMENT_LIBRARIES';
      }

      static get UPDATE_FILTER() {
        return '@DOCUMENT_LIBRARY.UPDATE_FILTER';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@DOCUMENT_LIBRARY.DOWNLOAD_REPORT_STATUS';
      }

      // ACTIONS USADAS

      static get UPLOAD_FILE() {
        return '@DOCUMENT_LIBRARY.UPLOAD_FILE';
      }

      static get GET_DATA_MASTER() {
        return '@DOCUMENT_LIBRARY.GET_DATA_MASTER';
      }

      static get GET_DATA_LIST_FOLDERS() {
        return '@DOCUMENT_LIBRARY.GET_DATA_LIST_FOLDERS';
      }

      static get GET_DATA_LIST_FILES_AND_FOLDERS() {
        return '@DOCUMENT_LIBRARY.GET_DATA_LIST_FILES_AND_FOLDERS';
      }

      static get GET_DOCUMENT_LIBRARY_BY_ID() {
        return '@DOCUMENT_LIBRARY.GET_DOCUMENT_LIBRARY_BY_ID';
      }

      static get DELETE_DOCUMENT_LIBRARY() {
        return '@DOCUMENT_LIBRARY.DELETE_DOCUMENT_LIBRARY';
      }

      static get RESET_AFTER_SAVED() {
        return '@DOCUMENT_LIBRARY.RESET_AFTER_SAVED';
      }

      static get COPY_DOCUMENT_LIBRARY() {
        return '@DOCUMENT_LIBRARY.COPY_DOCUMENT_LIBRARY';
      }

      static get MOVE_DOCUMENT_LIBRARY() {
        return '@DOCUMENT_LIBRARY.MOVE_DOCUMENT_LIBRARY';
      }

      static get RENAME_DOCUMENT_LIBRARY() {
        return '@DOCUMENT_LIBRARY.RENAME_DOCUMENT_LIBRARY';
      }

      static get GET_RECENT_FILES() {
        return '@DOCUMENT_LIBRARY.GET_RECENT_FILES';
      }

      static get GET_CURRENT_FILES() {
        return '@DOCUMENT_LIBRARY.GET_CURRENT_FILES';
      }

      static get GET_MY_FILES() {
        return '@DOCUMENT_LIBRARY.GET_MY_FILES';
      }
      
      static get GET_TRASH_FILES() {
        return '@DOCUMENT_LIBRARY.GET_TRASH_FILES';
      }

      static get IS_STORAGE_ENABLED() {
        return '@DOCUMENT_LIBRARY.IS_STORAGE_ENABLED';
      }

    };

    //DOCUMENT MANAGEMENT  CONSTANTS
    static DocumentManagement = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return '@DOCUMENT_MANAGEMENT.ADVANCED-FILTER-TOGGLE';
      }

      static get SEARCH_DOCUMENT_LIBRARIES() {
        return '@DOCUMENT_MANAGEMENT.SEARCH_DOCUMENT_MANAGEMENT';
      }

      static get UPDATE_FILTER() {
        return '@DOCUMENT_MANAGEMENT.UPDATE_FILTER';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@DOCUMENT_MANAGEMENT.DOWNLOAD_REPORT_STATUS';
      }

      static get CREATE_FOLDER() {
        return '@DOCUMENT_MANAGEMENT.CREATE_FOLDER';
      }

      static get GET_DATA_MASTER() {
        return '@DOCUMENT_MANAGEMENT.GET_DATA_MASTER';
      }

      static get GET_DATA_LIST_FOLDERS() {
        return '@DOCUMENT_MANAGEMENT.GET_DATA_LIST_FOLDERS';
      }

      static get GET_LIST_FOLDERS_TABLE() {
        return '@DOCUMENT_MANAGEMENT.GET_LIST_FOLDERS_TABLE';
      }

      static get GET_DOCUMENT_MANAGEMENT_BY_ID() {
        return '@DOCUMENT_MANAGEMENT.GET_DOCUMENT_MANAGEMENT_BY_ID';
      }

      static get DELETE_DOCUMENT_MANAGEMENT() {
        return '@DOCUMENT_MANAGEMENT.DELETE_DOCUMENT_MANAGEMENT';
      }

      static get RESET_AFTER_SAVED() {
        return '@DOCUMENT_MANAGEMENT.RESET_AFTER_SAVED';
      }

      static get RENAME_DOCUMENT_MANAGEMENT() {
        return '@DOCUMENT_MANAGEMENT.RENAME_DOCUMENT_MANAGEMENT';
      }

      static get GET_PERMISSION_BY_PATH() {
        return '@DOCUMENT_MANAGEMENT.GET_PERMISSION_BY_PATH';
      }

      static get SAVE_FOLDER_PERMISSIONS() {
        return '@DOCUMENT_MANAGEMENT.SAVE_FOLDER_PERMISSIONS';
      }

      static get IS_STORAGE_ENABLED() {
        return '@DOCUMENT_MANAGEMENT.IS_STORAGE_ENABLED';
      }

    };

  };

  static SectionsName = class {
    static get DATOS_DOCUMENTO() {
      return 'DATOS DOCUMENTO';
    }
  }
}
