import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Chart } from 'primereact/chart';
import { Skeleton } from 'primereact/skeleton';
import ReactSpeedometer from "react-d3-speedometer"
import moment from 'moment';
import { Chart as ChartReg } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation'
import { TabPanel, TabView } from 'primereact/tabview';
ChartReg.register(annotationPlugin);

const MonitoringIndicatorChart = ({typeChart, isLoading}) => {
    const {dataSets, labels} = useSelector(state => state.monitoringReducer.monitoringChart);
    const { goalValue, maxGoalValue } = useSelector(state => state.monitoringReducer.goal);
    const [dateSelected, setDateSelected] = useState(0)


    const dataArray = dataSets ? dataSets.reduce((acc, cur) => {
        acc.push(cur.value)
        return acc
    }, []) : []

    const [barData, setBarData] = useState({
        labels: labels,
        datasets: [
            {
                label: 'Indicadores Bar',
                backgroundColor: ['#42A5F5', '#EC6A1D', '#ECC21D', '#B2EC1D', '#EC3C1D', '#1DECB4', '#9F1DEC', '#1D2AEC', '#1DEC48', '#EC1D4F', '#1DADEC', '#EC1DC6'],
                data: dataArray
            },
        ]
    });

    const [lineData, setLineData] = useState({
        labels: labels,
        datasets: [
            {
                label: 'Indicadores Line',
                backgroundColor: ['#EC3C1D'],
                data: dataArray,
                tension: 0,
                borderColor: '#EC3C1D'
            },
        ]
    });

    useEffect(() => {
        setBarData({
            labels: labels,
            datasets: [
                {
                    label: 'Indicadores Bar',
                    backgroundColor: ['#42A5F5', '#EC6A1D', '#ECC21D', '#B2EC1D', '#EC3C1D', '#1DECB4', '#9F1DEC', '#1D2AEC', '#1DEC48', '#EC1D4F', '#1DADEC', '#EC1DC6'],
                    data: dataArray
                },
            ]
        })
        setLineData({
            labels: labels,
            datasets: [
                {
                    label: 'Indicadores Line',
                    backgroundColor: ['#EC3C1D'],
                    data: dataArray,
                    tension: 0,
                    borderColor: '#EC3C1D'
                },
            ]
        })
    }, [dataSets, labels])
    

    const getLightTheme = () => {
        let barOptions = {
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
              legend: {
                  /* TODO: Poner varios titulos legends por cada valor */
                  labels: {
                      color: '#495057',
                  },
                  display: false,
              },
              annotation: {
                annotations: {
                  line1: {
                    type: 'line',
                    scaleID: "y",
                    value: goalValue,
                    borderColor: 'rgb(255, 47, 47)',
                    borderWidth: 3,
                  },
                  line2: {
                    type: 'line',
                    scaleID: "y",
                    value: maxGoalValue,
                    borderColor: 'rgb(255, 47, 47)',
                    borderWidth: 3,
                  },
                },
              },
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            },
        };

        let lineOptions = { ...barOptions, aspectRatio: .8}
        let chartSelectedOptions = barOptions

        return {
            barOptions,
            lineOptions,
            chartSelectedOptions
        } 
    }

    const { chartSelectedOptions } = getLightTheme();
  
    
    const values = dataSets?.map(data => data.value) || []

    const customSegmentStops = []
    const segmentColors = []

    if(dataSets && goalValue && maxGoalValue){
      if(Math.min(dataSets) < goalValue){
        customSegmentStops.push(Math.min(dataSets))
        segmentColors.push('red')
      }
  
      customSegmentStops.push(goalValue)
      segmentColors.push('yellow')
      customSegmentStops.push(maxGoalValue)
  
      if(Math.max(dataSets) > goalValue){
        customSegmentStops.push(Math.max(dataSets))
        segmentColors.push('green')
      }
    }


    return (
        <>
            {
                (typeChart === 'bar'&& !isLoading) && (
                    <div className="" style={{width: '100%'}}>
                        <Chart type="bar" data={barData} options={chartSelectedOptions} />
                    </div>
                 )
            }  
            {
                (typeChart === 'line' && !isLoading) && (
                    <div className="" style={{width: '100%'}}>
                        <Chart type="line" data={lineData} options={chartSelectedOptions} />
                    </div>
                 )
            }  
            {
                (typeChart === 'gauge' && dataSets && goalValue && maxGoalValue && !isLoading) && (
                    <div className="w-full flex flex-col mt-3 gap-y-5" style={{width: '100%', overflowX: 'scroll'}}>
                        <TabView scrollable className='container_date_tabview'>
                          {
                            dataSets ? dataSets.map(({value, valueDate}) => (
                              <TabPanel
                                key={valueDate}
                                headerTemplate={(options ) => (
                                <div className='transition ease-in-out bg-blue-500 hover:bg-blue-600 px-2 pt-1 rounded h-6 flex items-center m-2' onClick={() => setDateSelected(value)}>
                                    <p className='cursor-pointer text-white text-xs font-bold h-full' >{moment(valueDate).format('L')}</p>
                                </div>
                              )}></TabPanel>
                            )) : <Skeleton width='100%' />
                          }
                        </TabView>
                        <div className='w-full container-speedometer-indicator-chart'>
                            <ReactSpeedometer
                                minValue={Math.min(...values, goalValue)}
                                maxValue={Math.max(maxGoalValue, ...values)}
                                value={dateSelected || Math.min(...values, goalValue)}
                                customSegmentStops={[Math.min(...values, goalValue), goalValue, maxGoalValue, Math.max(maxGoalValue, ...values)]}
                                needleColor="black"
                                segmentColors={['red', 'yellow', 'green']}
                                textColor="grey"
                                width={400}
                                currentValueText={'Indicador ${value}'}
                                // textColor={textColor}
                            />
                        </div>
                    </div>
                )
            }  
        </>
    )
}

export default MonitoringIndicatorChart