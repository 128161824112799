import React, { useEffect, useState } from 'react'
import useModalAction from 'features/document/hooks/useModalAction';
import { DocumentLibraryAction } from 'features/document/store/actions/DocumentLibraryAction';
import { Button } from 'primereact/button';
// import { ConfirmDialog } from 'primereact/confirmdialog'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import EstructuraCarpetas from './EstructuraCarpetas';

const ModalActionFile = ({displayModal, setDisplayModal, documentSelected, setDocumentSelected, actionSelected, setActionSelected}) => {
  
  const dispatch = useDispatch()
  const [selectedPath, setSelectedPath] = useState(null)
  const { isDeleted, isUpdated } = useSelector((state) => state.documentLibraryReducer.document);
  // const [visible, setVisible] = useState(false);

  const { control, errors, existError, setValue, handleSubmit } = useModalAction()

  const titleAction = ( ) => {
    if (actionSelected) {
      if (actionSelected === 'copy') return 'Copiar Archivo'
      if (actionSelected === 'move') return 'Mover Archivo'
      if (actionSelected === 'rename') return 'Cambiar de nombre al archivo'
    }
    return 'Documento'
  }

  useEffect(() => {
    if (actionSelected === 'rename') {
      setValue('newName', documentSelected.name)
    }
  }, [documentSelected, actionSelected, setValue])

  useEffect(() => {
    if (actionSelected === 'copy' || actionSelected === 'move') {
      setValue('targetPath', documentSelected.name)
    }
  }, [documentSelected, actionSelected, setValue])
  
  useEffect(() => {
    if (isUpdated) {
       onHide()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated])

  const onHide = () => {
    setDisplayModal(false)
    setActionSelected(null)
    setDocumentSelected(null)
    setValue('targetPath', '')
    setValue('newName', '')
    // if (data) {
    //   dispatch(DocumentLibraryAction.resetAfterSaved());
    // }
  }

  const redirectToSearch = () => {
    onHide()
  }

  // const reject = () => {
  //   setVisible(false);
  // };
  
  const onSubmit = (newFile) => {
    if (actionSelected === 'copy') {
      if (selectedPath === 'error') return
      const modelName = {
        id: documentSelected.id,
        sourceFileName: documentSelected.name,
        targetPath: selectedPath
      }
      
      dispatch(DocumentLibraryAction.copyDocument(modelName));
    }
    if (actionSelected === 'move') {
      if (selectedPath === 'error') return
      const modelName = {
        id: documentSelected.id,
        sourceFileName: documentSelected.name,
        targetPath: selectedPath
      }

      dispatch(DocumentLibraryAction.moveDocument(modelName));
    }
    if (actionSelected === 'rename') {
      const modelName = {
        id: documentSelected.id,
        currentName: documentSelected.name,
        newName: newFile.newName
      }
      dispatch(DocumentLibraryAction.renameDocument(modelName));
    }
  };

  return (
    <Dialog header={titleAction} visible={displayModal} className='w-3/4 sm:w-1/3' onHide={onHide}>
      <form 
        className="form-custom p-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <hr />
        <div className="grid grid-cols-1 gap-2">
          {
            (actionSelected === 'copy' || actionSelected === 'move' ) ?
            (
              <span className="p-float-label w-full h-64 overflow-y-auto">
                <EstructuraCarpetas setSelectedPath={setSelectedPath} />
              </span>
            ) : null
          }
          {
            (actionSelected === 'rename') ?
            (
              <span className="p-float-label w-full mt-1 md:mt-8">
                <Controller
                    control={control}
                    render={({
                        field: { onChange, onBlur, value},
                    }) => (
                      <InputText
                          type="text"
                          id='newName'
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          className={errors?.name ? 'p-invalid w-full' : 'w-full'}
                      />
                    )}
                    name="newName"
                    rules={{
                      required: 'El nuevo nombre es requerido',
                  }}
                />
                <label htmlFor="newName">Nuevo Nombre *</label>
              </span>
            ) : null
          }
        </div>
        {
        (existError) && <div className='message error'>
            <ul>
                {errors.targetPath && (actionSelected === 'copy' || actionSelected === 'move' )? (
                    <>
                        <li className="p-error">{errors.targetPath.message}</li>
                    </>
                ) : null}
                {errors.newName && actionSelected === 'rename' ? (
                    <>
                        <li className="p-error">{errors.newName.message}</li>
                    </>
                ) : null}
            </ul>

        </div>
        }
        <div className="flex justify-end gap-4 mt-3">
            <Button
                icon="pi pi-times"
                type="button"
                label="Cancelar"
                onClick={redirectToSearch}
                className="sig-button sig-secondary mt-4"
            />
            <Button
                icon="pi pi-save"
                type="submit"
                disabled={existError ? true : false}
                label="Guardar"
                loading={document?.isSaving || document?.isLoading}
                className="sig-button sig-primary mt-4"
            />
        </div>
      </form>

      {/* <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={redirectToSearch}
          reject={reject}
      /> */}
    </Dialog>
  )
}

export default ModalActionFile