import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppSettingsAction } from 'features/configuration/store/actions/AppSettingsAction';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';

const useLogos = () => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState({ LOGO_DARK: null, LOGO_DEFAULT: null });
  const [urlImage, setUrlImage] = useState({
    LOGO_DARK: null,
    LOGO_DEFAULT: null,
  });
  const currentLogos = useSelector(
    (state) => state.appSettingsReducer.currentLogos.data
  );

  const { isLoading, isLoadingSave } = useSelector(
    (state) => state.appSettingsReducer.currentLogos
  );

  const { updateTitle } = useSetTitlePage();

  useEffect(() => {
    updateTitle({
      title: 'Configuración',
      subtitle: 'Sistema',
      description: 'Logos de Empresa',
    });
  }, []);




  const handlerSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    if (currentLogos.length > 0) {
      currentLogos.forEach(({ Id, ApplicationSettingId, Code, Value }) => {
        if (Code === 'LOGO_DEFAULT') {
          formData.append('Id', Id);
          formData.append('ApplicationSettingId', ApplicationSettingId);
          formData.append('Code', Code);
          formData.append('Value', Value);
          let LogoImage = '';
          if (files[Code]) {
            // console.log(files?.LOGO_DEFAULT?.name)
            const myFile = files[Code];
            LogoImage = new File([myFile], files?.LOGO_DEFAULT?.name, {
              lastModified: Date.now(),
              type: files[Code].type,
            });

          }
          formData.append('LogoImage', LogoImage);
        }
      });
    }

    dispatch(
      AppSettingsAction.saveSettingLogos({
        data: formData,
        settingName: 'setting-logos',
      })
    );

  };
  return {
    isLoading,
    setFiles,
    handlerSubmit,
    isLoadingSave,
    urlImage,
    setUrlImage,
  };
};;

export default useLogos;
