const { apiService } = require("core/services/ApiService");

const getEvaluationByModelId = (modelId) => {
  return apiService.Get(`management/api/RiskManagementModelEvaluation/GetEvaluationByModelId?id=${modelId}`);
};

const getDataMasterEvaluation = () => {
  return apiService.Get('management/api/RiskManagementModelEvaluation/GetDataMaster');
};

const searchEvaluation = (criteria) => {
  return apiService.Post('management/api/RiskManagementModelEvaluation/Search', criteria)
}

const saveEvaluation = (data) => {
  return apiService.Post('management/api/RiskManagementModelEvaluation/Save', data)
}

const deleteEvaluation = (evaluationId) => {
  return apiService.Post(`management/api/RiskManagementModelEvaluation/Delete?id=${evaluationId}`)
}

const getEvaluationById = (evaluationId) => {
  return apiService.Get(`management/api/RiskManagementModelEvaluation/GetEvaluationById?id=${evaluationId}`)
}

const generateEvaluationReport = (payload) => {
  return apiService.Post('management/api/RiskManagementModelEvaluation/GenerateReport', payload, {responseType: 'blob'})
}

const getDefaultHeatMapInfo = (evaluationId = 1) => {
  return apiService.Get(`management/api/RiskManagementModelEvaluation/GetDefaultHeatMapInfo`)
}

const getEvaluationHeatMapByEvaluationId = (evaluationId) => {
  return apiService.Get(`management/api/RiskManagementModelEvaluation/GetEvaluationHeatMapByEvaluationId?id=${evaluationId}`)
}

const getDynamicProcessDataById = (id) => {
	return apiService.Get(`management/api/RiskManagementModelEvaluation/GetDynamicProcessDataById?id=${id}`)
}

export const RiskEvaluationServices = {	
	getEvaluationByModelId,
	getDataMasterEvaluation,
	searchEvaluation,
	saveEvaluation,
	deleteEvaluation,
	getEvaluationById,
	generateEvaluationReport,
	getDefaultHeatMapInfo,
	getEvaluationHeatMapByEvaluationId,
  getDynamicProcessDataById,
}