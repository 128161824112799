export const UpArrowSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={26}
    viewBox="0 0 158 70"
    {...props}
  >
    <path d="M38.5 18.4-.4 35.5l19.7.3L39 36v34h79V36h19.2c10.9 0 18.8-.4 18.2-.9-.9-.8-77-34.2-77.6-34-.2.1-17.8 7.8-39.3 17.3z" />
  </svg>
)