import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

export const useClaimViewBusiness = () => {

  const menu = useSelector((state) => state.authReducer.user.menuPrincipal);
  const pathName = useLocation()?.pathname;
  const [isSearch, setSearch] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isView, setView] = useState(false);

  useEffect(() => {
    search();
    edit();
    view();
  }, [menu]);


  const search = () => {
    const actionCode = 'CUSTOMER_BUSINESS_SEARCH';
    const optionCode = "VIBU";
    setSearch(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const edit = () => {
    const actionCode = 'CUSTOMER_BUSINESS_EDIT_BUSINESS';
    const optionCode = "VIBU";
    setEdit(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const view = () => {
    const actionCode = 'CUSTOMER_BUSINESS_VIEW';
    const optionCode = "VIBU";
    setView(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
 };

  return {
    isSearch,
    isEdit,
    isView,
  }
}