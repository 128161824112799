import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";

import { FilterComponent } from "features/customers/components/students/FilterComponent";
import { useStudents } from "features/customers/hooks/students/useStudents";
import { TableComponent } from "features/customers/components/students/TableComponent";
import { AdvancedFilterComponent } from "features/customers/components/students/AdvancedFilterComponent";
import { StudentsAction } from "features/customers/store/actions/StudentsAction";
import scrollTop from "shared/utils/scrollTop";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useTableStudents } from "features/customers/hooks/students/useTableStudents";
import { Menu } from "primereact/menu";
import StudentModalImport from "features/customers/components/students/modals/StudentModalImport";
import { useClaimStudents } from "features/customers/hooks/useClaimStudents";

export const PageStudents = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useStudents();
  const { confirmDelete, cancelDelete, isOpenModalDelete } = useTableStudents();

  const optionsButton = useRef(null);
  const [displayModal, setDisplayModal] = useState(false)

  const { isSearch, isDownload, isCreate } = useClaimStudents();

  useEffect(() => {
    dispatch(StudentsAction.getStudents({ change: false }));
    dispatch(StudentsAction.getDataMaster());
    dispatch(StudentsAction.setCurrentStudent({ errors: [] }));
  }, []);


  const items = [
    {
        label: 'Descargar Plantilla',
        command: (e) => {
          dispatch(StudentsAction.downloadTemplate())
        }
    },
    {
        label: 'Cargar Plantilla',
        command: (e) => {
          setDisplayModal(true)
        }
    },
  ];

  useEffect(() => {
    scrollTop();
  }, []);
  return (
    <Fragment>
      {
          (isSearch||isDownload)&&(
              <FilterComponent />
           )
      }
      {
          isCreate&&(<>
            <Button
              icon="pi pi-plus"
              type="button"
              label="Nuevo"
              className="sig-button sig-dark mt-4"
              onClick={() => {
                history.push("/cliente/alumnos/nuevo");
              }}
            />
            <Button
                  label='Importar' className="sig-button sig-primary ml-3"  
                  onClick={(event) => optionsButton.current.toggle(event)} 
                />
            </>
          )
      }
      <TableComponent />
      <AdvancedFilterComponent />
      <Menu model={items} popup ref={optionsButton} />
      <StudentModalImport
        displayModal={displayModal} setDisplayModal={setDisplayModal} title={'Importar Alumnos'}
      />
      <ConfirmDialog
        visible={isOpenModalDelete}
        onHide={cancelDelete}
        message="¿Está seguro que desea eliminar el alumno?"
        header="Eliminar"
        icon="pi pi-trash"
        accept={confirmDelete}
        reject={cancelDelete}
      />
    </Fragment>
  );
};
