export class BusinessViewConstants {
  static Action = class {
    static BusinessView = class {
      static get DATA_MASTER() {
        return "@BUSINESSVIEW.DATA-MASTER"
      }
      static get SEARCH() {
        return "@BUSINESSVIEW.SEARCH"
      }
      static get CHANGE_STATUS() {
        return "@BUSINESSVIEW.CHANGE_STATUS"
      }
    }
  }
}