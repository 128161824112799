import { Avatar } from 'primereact/avatar';

export const CommentCard = (props) => {
  const { row, deleteComment, enableComments } = props;
  
  return(
    <div className="grid sm:grid-cols-1 md:grid-cols-1 rounded-lg border border-gray-200 p-4 mb-4">
      <div className="flex justify-between">
        <div className="flex gap-4">
          <span className="mb-1 text-sm font-bold title-card-wt-status">
            <Avatar label="IC" className="mr-2" style={{ backgroundColor: '#2196F3', color: '#ffffff' }} shape="circle" />
          </span> 
        </div>
        <div className="flex flex-col">
          <span className="mb-1 text-sm font-bold title-card-wt-status">
            {row.CreatedBy}
          </span>
          <span className="text-xs">{row.CreationDate}</span>
        </div>
        {
          enableComments &&
            <i className="pi pi-trash mt-2 cursor-pointer" onClick={(e) => deleteComment(e, row.Id, row.DocumentId)}></i>
        }
      </div>
      <div className="grid grid-cols-2 mt-3 text-gray-700 dark:text-gray-400">
        <div><p>{row.Comments}</p></div>      
      </div>
      <div className="grid">
      </div>
    </div>
  );
}