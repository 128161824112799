import { useEffect, useRef, useState } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { useClaimCoursesExpenses } from 'features/coursesManagment/hooks/useClaimCoursesExpenses';
import { Divider } from 'primereact/divider';
import Attachments from 'shared/components/attachments/Attachments';
import { useForm } from 'react-hook-form';

const Attachment = ({ iconTypeOfFileTable, displayModal, setDisplayModal, updateExpense, dataRowExpense, setDataRowExpense}) => {

  let defaultValues = {
    savedFiles: [],
    addedFiles: [],
  };
  const {
    setValue,
    watch,
    // formState: { errors },
    // handleSubmit,
  } = useForm({ defaultValues });

  const valuesFields = watch();
  const {isCreate, isEdit} = useClaimCoursesExpenses()
  
  const [error, setError] = useState(null)

  useEffect(() => {
    if (dataRowExpense?.row?.attachmentDocument) {
      setValue('addedFiles', [dataRowExpense.row.attachmentDocument])
    }
  }, [dataRowExpense?.row?.attachmentDocument, setValue])
  
  // const [fileValue, setFileValue] = useState(null)
  // const [value1, setValue1] = useState(0);
  // const interval = useRef(null);

  // const changeImage = (e) => {
  //   setFileValue(e.target.files[0])
  //   setValue1(0)
  //   setError(null)
  // };

  // useEffect(() => {
  //   let val = value1;
  //   interval.current = setInterval(() => {
  //       val += Math.floor(Math.random() * 10) + 6;
  //       if (val >= 100) {
  //           val = 100;
  //           clearInterval(interval.current);
  //       }
  //       setValue1(val);
  //   }, 300);

  //   return () => {
  //       if (interval.current) {
  //           clearInterval(interval.current);
  //           interval.current = null;
  //       }
  //   }
  // }, [fileValue]); // eslint-disable-line react-hooks/exhaustive-deps

  // const onRemoveFile = () => {
  //   setFileValue(null)
  //   setValue1(0)
  // }

  const onHide = () => {
    setDisplayModal(false)
    setDataRowExpense(null)
    // setFileValue(null)
    // setValue1(0)
    setError(null)
    setValue('savedFiles', [])
    setValue('addedFiles', [])
  }

  const onSavefile = () => {
    // if (!fileValue){
    if (valuesFields.addedFiles.length === 0){
      setError('Debe seleccionar un archivo')
      return
    }
    setError(null)
    updateExpense({
      id: dataRowExpense.row.id,
      // value: fileValue,
      value: valuesFields.addedFiles[0],
      name: dataRowExpense.name,
    })
    onHide()
  }

  // console.log(dataRowExpense)
  // console.log(valuesFields)
  return (
    <Dialog header={'Adjuntar archivo de gasto'} visible={displayModal} className='w-4/5 md:w-3/5' onHide={onHide}>
     
      <Divider />
      {/* <div className='style-drag-area'>
        <div className="image-upload-wrap">
          <input
            className="file-upload-input"
            type="file"
            accept="*"
            multiple
            onChange={(e) => {
              changeImage(e);
            }}
          />
          <div className="h-full">
            {
              !fileValue ?
              (
                <div className='flex flex-col justify-center items-center'>
                  <i className="pi pi-image mt-3 p-5" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
                  <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}}>Selecciona el área o arrastra tu archivo para adjuntar</span>
                </div>
              ) :
              (
                <div className='flex flex-col justify-center items-center h-full gap-y-4'>
                  <div className='flex justify-center items-center gap-x-5'>
                    {iconTypeOfFileTable(fileValue)}
                    <div className="flex flex-col ">
                      <p>{fileValue.name}</p>
                      <p>{fileValue.size}B</p>
                    </div>
                    <i className="pi pi-times z-50 cursor-pointer hover:text-gray-500 text-lg" onClick={onRemoveFile}></i>
                  </div>
                  <ProgressBar className='w-4/5' value={value1}></ProgressBar>
                </div>
              )
            }
          </div>
        </div>
      </div> */}
      <Attachments
        setValue={setValue}
        fieldFiles="savedFiles"
        fieldNewFiles="addedFiles"
        filesValues={valuesFields?.["savedFiles"]}
        newFilesValues={valuesFields?.["addedFiles"]}
        isLoading={!dataRowExpense}
        validExtensions={["docx", "pdf", "xlsx"]}
        isMultiple={false}
        isShowHeader={false}
        isFullWidth={false}
      />
      <div className='flex justify-center mt-3'>
        {
          error && (
            <Message className='w-1/2' severity="error" text={error} />
          )
        }
      </div>
      <div className="flex justify-end gap-4 mt-1">
        <Button
            icon="pi pi-times"
            type="button"
            label="Cancelar"
            onClick={onHide}
            className="sig-button sig-secondary mt-4"
        />
        {
          (isCreate || isEdit)&&(
            <Button
                icon="pi pi-save"
                type="button"
                // disabled={existError ? true : false}
                label="Guardar"
                className="sig-button sig-primary mt-4"
                onClick={onSavefile}
            />
          )
        }
    </div>
      
    </Dialog>
  )
}

export default Attachment