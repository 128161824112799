import { apiService } from "core/services/ApiService";

const getLastFilePeople = (criteria) =>{
  return apiService.Get('management/api/GenericConfigProcess/GetLastConfiguration?processType='+ criteria);
}

const getListControls = (type) =>{
  return apiService.Get('management/api/GenericConfigProcess/ListControls?processType=' + type);
}

const getListResponsibles = (criteria) =>{
  return apiService.Post('management/api/GenericConfigProcess/GetDataMaster', criteria);
}

const saveFilePeople = (criteria) =>{
  return apiService.Post('management/api/GenericConfigProcess/SaveConfiguration', criteria);
}

const saveAndApproved = (criteria) =>{
  return apiService.Post('management/api/GenericConfigProcess/SaveConfigurationAndSubmitWorkflow', criteria);
}

const getVersionsDetailedList = (id, version) => {
  return apiService.Get(`management/api/ConfigDynamicProcessSheet/GetVersionsDetailedList?id=${id}&versionNumber=${version}`)
}

const generateVersion = (criteria) =>{
  return apiService.Post('management/api/GenericConfigProcess/GenerateVersion', criteria);
}

const getFilePeopleById = (criteria) => {
  return apiService.Get(`management/api/GenericConfigProcess/GetConfigurationById?id=${criteria.id}&processType=${criteria.typeProcess}`)
}

const setActiveConfiguration = (criteria) => {
  return apiService.Get(`management/api/GenericConfigProcess/GetActiveConfiguration?processType=`+criteria)
}

export const CustomFieldServices = {
  getLastFilePeople,
  getListControls,
  getListResponsibles,
  saveFilePeople,
  saveAndApproved,
  getVersionsDetailedList,
  generateVersion,
  getFilePeopleById,
  setActiveConfiguration
}