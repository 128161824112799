import { miniExcel, miniWord, miniPdf, Image } from "shared/assets/img/images";
import { Button } from "primereact/button";
import moment from "moment";

const getIconByExtension = (name) => {
  if (name.includes(".pdf")) {
    return miniPdf;
  } else if (name.includes(".xlsx") || name.includes(".xls")) {
    return miniExcel;
  } else if (name.includes(".docx") || name.includes(".doc")) {
    return miniWord;
  } else if (name.includes(".jpg") || name.includes(".jpeg") || name.includes(".png")) {
    return Image;
  } else {
    return miniPdf;
  }
}

const onOpenFileUrl = (url) => {
  // Descargar o abrir el archivo
  window.open(url, "_blank");
}

const File = ({ file, confirmDelete, isLoading }) => {
  return (
    <div className="relative border-2 mb-4 rounded border-gray-300 flex-col justify-between p-2 grid grid-cols-12">
      <div className="col-span-10">
        <div className="inline-flex items-center gap-1" >
          <img
            // src={
            //   file.FileName.includes(".docx")
            //     ? miniWord
            //     : file.FileName.includes(".xlsx")
            //     ? miniExcel
            //     : miniPdf
            // }
            src={getIconByExtension(file.fileName)}
            alt="icon-extension-file"
            style={{ maxWidth: "25px", objectFit: "cover" }}
          />
          {/* <h6 className=" text-blue-600 font-bold overflow-hidden">{file.fileName}</h6> */}
          <a 
            className=" text-blue-600 font-bold overflow-hidden cursor-pointer"
            href={file.url} download target="_blank" rel="noreferrer">{file.fileName}</a>
        </div>
        <h6 className="font-light">{file.createdBy}</h6>
        {/* <h6 className="font-light">{moment(new Date(file.creationDate)).format()}</h6> */}
        <h6 className="font-light">{moment(file.creationDate).format("DD/MM/YYYY")}</h6>
      </div>
      <div className="col-span-2 text-right">
        <Button
          icon="pi pi-trash"
          type="button"
          disabled={isLoading}
          onClick={() => confirmDelete(file.id)}
          loading={isLoading}
          className="sig-primary"
          style={{ width: "100%", maxWidth: "30px", minWidth: "30px" }}
        />
      </div>
    </div>
  );
};

export default File;
