import { apiService } from "core/services/ApiService";

const searchRiskInventory = (data) => {
	return apiService.Post('management/api/RiskInventory/Search', data)
}

const getDataMaster = () => {
	return apiService.Get('management/api/RiskInventory/GetDataMaster')
}

const save = (data) => {
	return apiService.Post('management/api/RiskInventory/Save', data)
}

const getInventoryById = (id) =>{
	return apiService.Get(`management/api/RiskInventory/GetInventoryById?id=${id}`)
}

const deleteInventory = id => {
	return apiService.Delete(`management/api/RiskInventory/DeleteInventory?id=${id}`)
}

const download = data => {
	return apiService.Post('management/api/RiskInventory/GenerateReport', data, {
    responseType: 'blob',
  })
}

export const RiskInventoryServices = { 
	searchRiskInventory,
	getDataMaster,
	save,
	getInventoryById,
	deleteInventory,
	download,
}