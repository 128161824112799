import { useState, useEffect } from 'react';
import { Fragment } from "react";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { CommentCard } from './CommentCard';
import { useDispatch } from 'react-redux';
import validateTypeValue from 'shared/helpers/validateTypeValue';
import { Skeleton } from 'primereact/skeleton';

export const TableCommentsComponent = (props) => {
    //* enableComments: Bool para mostrar los comments, sourceId: Id del recurso
    //* comments: Lista de comments que venga del reducer, Action es el objeto con las actions que usara el componente
    //* isDeletedComment, isSavedComment, isLoadingComments son las propiedad del estado comment del reducer
    //* sourceCode: Codigo del recurso en el CoreConstants.Task.Source
    const { enableComments, sourceId, comments, Action, isDeletedComment, isSavedComment, isLoadingComments, sourceCode } = props;
    const [tableComments, setTableComments] = useState([]);
    const [comment, setComment] = useState('')
    const dispatch = useDispatch();

    useEffect(() => {
        if(sourceId){
            getComments(sourceId);
        }
    }, [])

    useEffect(() => {
        if (comments.length > 0) {
            setTableComments(validateTypeValue(comments));
        }
      }, [comments]);

    useEffect(() => {
        if (isSavedComment) {
            getComments(sourceId);
            setTableComments(validateTypeValue(comments));
        }
      }, [isSavedComment]);

    useEffect(() => {
        if (isDeletedComment) {
            getComments(sourceId);
            setTableComments(validateTypeValue(comments));
        }
      }, [isDeletedComment]);

    const commentsList = () => {
        return tableComments?.map ((row, index) => {
            return <CommentCard row={row} index={index} deleteComment={deleteComment} enableComments={enableComments}/>
        });
    }

    const addComment = (e, sourceId, comment) => {
        e.preventDefault();
        dispatch(Action.addComment(sourceCode, Number(sourceId), comment));
      }
    
      const deleteComment = (e, commentId, documentId) => {
        e.preventDefault();
        dispatch(Action.deleteComment(commentId, Number(documentId)));
      }
    
      const getComments = (taskId) => {
        dispatch(Action.getCommentsById(Number(taskId)));
      }

    return (
        <Fragment>
            {enableComments && 
                <div className="grid sm:grid-cols-1 md:grid-cols-1">
                    <div>
                        <span className="p-float-label w-full pr-3 ml-2 mt-2">
                        <InputText
                            type="text"
                            id="Comment"
                            maxLength={100}
                            className={`w-full`}
                            onChange={(e) => setComment(e.target.value)}
                            onKeyPress={(event) => {
                                if(event.key === 'Enter') {
                                    addComment(event, sourceId, comment); setComment('')
                                }
                            }}
                            value={comment}/>
                        <label htmlFor="Title">Añadir un comentario</label>
                        </span>
                        <div className="flex justify-end mt-1">
                            <Button
                                icon="pi pi-cloud-download"
                                type="button"
                                label="Comentar"
                                onClick={(e) => {addComment(e, sourceId, comment); setComment('');}}
                                className="sig-button sig-primary mt-1 mr-2"
                            />
                        </div>
                    </div>
                </div>
            }
            <div className="grid sm:grid-cols-1 md:grid-cols-1 mt-2">
                <div className="col-12 md:col-12 lg:col-12 mb-3 overflow-y-auto max-h-80">
                    {
                        isLoadingComments
                        ? <ul className="m-0 p-0  border-2 rounded py-2 px-3">
                            <li className="mb-3 flex flex-col gap-y-2">
                                <div className="flex items-center gap-x-2">
                                    <Skeleton shape="circle" size="2rem" className="mr-2"></Skeleton>
                                    <div style={{ flex: '1' }}>
                                        <Skeleton width="60%" height='1.4rem'></Skeleton>
                                    </div>
                                </div>
                                <div>
                                    <Skeleton width="80%" height='1.4rem'></Skeleton>
                                </div>
                            </li>
                        </ul>
                        : commentsList()
                    }
                </div>
            </div>
        </Fragment>
    )
}
