import { useSchedule } from "features/coursesManagment/pages/courses/schedule/useSchedule";
import { set, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { Fragment, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ScheduleAction } from "features/coursesManagment/store/actions/ScheduleAction";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { SchedulesTableData } from "features/coursesManagment/pages/courses/schedule/SchedulesTableData";
import { Column } from "primereact/column";
import { CoreConstants } from "core/commons/CoreConstants";
import { useClaimSchedule } from "features/coursesManagment/hooks/useClaimSchedule";

const SchedulePage = ({ title, mode }) => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const history = useHistory();
  const { updateTitle } = useSetTitlePage();
  const dispatch = useDispatch();
  const schedule = useSelector((state) => state.scheduleReducer.schedule);
  const [isFirstLoading, setFirstLoading] = useState(true);
  const dataMaster = useSelector((state) => state.scheduleReducer.dataMaster);
  const [visible, setVisible] = useState(false);
  const [visibleDialogFrecuency, setVisibleFrecuency] = useState(false);
  const [visibleDialogInstructor, setVisibleInstructor] = useState(false);
  const [visibleDialogEvaluation, setVisibleEvaluation] = useState(false);
  const [idDeleteFrecuency, setidDeleteFrecuency] = useState(0);
  const [idDeleteInstructor, setidDeleteInstructor] = useState(0);
  const [idDeleteEvaluation, setidDeleteEvaluation] = useState(0);
  // const [stageList, setStageList] = useState([]);
  // const [scheduletErrors, setScheduletErrors] = useState([]);

  const { isEdit } = useClaimSchedule();

  const {
    setFrecuencyList,
    setInstructorList,
    setEvaluationList,
    data,
    frecuencyList,
    createFrecuency,
    updateFrecuency,
    frecuencyDelete,

    instructorList,
    createInstructor,
    updateInstructor,
    instructorDelete,

    evaluationList,
    createEvaluation,
    updateEvaluation,
    evaluationDelete,
  } = useSchedule();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  //   {
  //     defaultValues: {
  //         //name: "",
  //         id: null,
  //         courseScheduleId: null,
  //         initialDate:null,
  //         daysId: null,
  //         hoursId: null,
  //         instructorName: "",
  //         description:"",
  //         //startDate:null,
  //         endDate:null,
  //         evaluationWeight:"",

  //     }
  // }
  const params = useParams();

  // const currentID = params.id;

  const redirectToSearch = () => {
    history.push("/curso/programacion");
    dispatch(ScheduleAction.resetAfterSaved());
  };

  useEffect(() => {
    if (schedule?.data) {
      setValue("name", schedule.data?.name);
      setValue("code", schedule.data?.code);
      setValue("courseId", schedule.data?.id);
      setFromDate(null);
      setToDate(null);
      setValue("frequency", frecuencyList);
      setValue("instructors", instructorList);
      setValue("evaluations", evaluationList);
      if (schedule.data.schedule) {
        setValue("id", schedule.data?.schedule.id);
        setFromDate(new Date(schedule.data?.schedule.initialDate));
        setToDate(new Date(schedule.data?.schedule.endDate));
        setFrecuencyList(schedule.data?.schedule.frequency);
        setInstructorList(schedule.data?.schedule.instructors);
        setEvaluationList(schedule.data?.schedule.sessions);
        setValue("initialDate", schedule.data?.schedule.initialDate);
        setValue("endDate", schedule.data?.schedule.endDate);
      }
    }

    if (schedule?.isSaved) redirectToSearch();
  }, [dataMaster, schedule]);
  useEffect(() => {
    // setValue("name", undefined);
    // setValue("id", undefined);
    // setValue("code", undefined);

    updateTitle({
      title: "Cursos",
      subtitle: "Programación",
      description: title,
      previousUrl: "/curso/programacion/",
    });

    if (isFirstLoading) {
      setFirstLoading(false);
      dispatch(ScheduleAction.getDataMaster());

      if (params?.id) getScheduleById(params?.id);
    }
  }, []);

  const getScheduleById = (scheduleId) => {
    dispatch(ScheduleAction.getScheduleById(Number(scheduleId)));
  };
  const generateStagesToPass = (currentStage, id) => {
    return [...currentStage].map((stage) => {
      if (stage.evaluationWeight) {
        stage.evaluationWeight = Number(stage.evaluationWeight);
      }

      const { id, ...rest } = stage;
      const newStage = rest;

      return newStage;
    });
  };

  const onSubmit = (data) => {
    if (schedule?.data) {
      const model = {
        id: data.id,
        courseId: data.courseId,
        //code: data.code,
        description: data.name,
        initialDate: fromDate,
        endDate: toDate,
        frequency: generateStagesToPass(frecuencyList, data.id),
        instructors: generateStagesToPass(instructorList, data.id),
        evaluations: generateStagesToPass(evaluationList, data.id),
        //stagesList: generateStagesToPass(frecuencyList, data.id)
      };

      dispatch(ScheduleAction.saveSchedule(model));
      return;
    }
    const model = {
      courseId: data.id,
      //code: data.code,
      description: data.name,
      initialDate: fromDate,
      endDate: toDate,
      frequency: generateStagesToPass(frecuencyList, data.id),
      instructors: generateStagesToPass(instructorList, data.id),
      evaluations: generateStagesToPass(evaluationList, data.id),
      //stagesList: generateStagesToPass(frecuencyList, data.id)
    };

    dispatch(ScheduleAction.saveSchedule(model));
  };
  const reject = () => {
    setVisible(false);
  };
  const deleteFrecuency = () => {
    frecuencyDelete(idDeleteFrecuency);
  };
  const deleteInstructor = () => {
    instructorDelete(idDeleteInstructor);
  };
  const deleteEvaluation = () => {
    evaluationDelete(idDeleteEvaluation);
  };
  const FieldFrecuency = ({ name, row }) => {

    if (name === "daysId")
      return (
        <Dropdown
          options={dataMaster.frequencyDays}
          optionLabel="description"
          filter
          filterBy="description"
          showClear
          className="w-full"
          placeholder="Seleccione día "
          optionValue="id"
          value={row[name]}
          onChange={(e) => {
            setName(e.target.name);
            setNameField(e.target.name);
            updateFrecuency({
              id: row.id,
              name: name,
              value: e.value,
            });
          }}
        />
      );
    else if (name === "hoursId")
      return (
        <Dropdown
          options={dataMaster.frequencyHours}
          optionLabel="description"
          filter
          filterBy="description"
          showClear
          className="w-full"
          placeholder="Seleccione hora"
          optionValue="id"
          value={row[name]}
          onChange={(e) => {
            setName(e.target.name);
            setNameField(e.target.name);
            updateFrecuency({
              id: row.id,
              name: name,
              value: e.value,
            });
          }}
        />
      );
  };
  const [idInstructor, setIdInstructor] = useState("");
  const [idEvaluation, setIdEvaluation] = useState("");
  const FieldInstructor = ({ name, row }) => {
    return (
      <InputText
        type={name === "name"}
        autoFocus={
          name_ === name && nameField === name && row.id === idInstructor
            ? true
            : false
        }
        onChange={({ target }) => {
          setIdInstructor(row.id);
          setName(target.name);
          setNameField(target.name);
          updateInstructor({
            id: row.id,
            name: target.name,
            value: target.value,
          });
        }}
        value={row[name]}
        name={name}
      />
    );
  };

  const FieldEvaluation = ({ name, row }) => {
    if (name === "creationDate" || name === "endDate") {
      return (
        <Calendar
          name="creationDate"
          monthNavigator
          yearNavigator
          yearRange="1950:2050"
          showIcon={true}
          className="w-full"
          onChange={(e) => {
            setName(e.target.name);
            setNameField(e.target.name);
            updateEvaluation({
              id: row.id,
              name: name,
              value: e.value,
            });
          }}
          value={row[name] == null ? null : new Date(row[name])}
        ></Calendar>
      );
    } else
      return (
        <InputText
          type={"text"}
          onChange={({ target }) => {
            setIdEvaluation(row.id);
            setName(target.name);
            setNameField(target.name);
            updateEvaluation({
              id: row.id,
              name: target.name,
              value: target.value,
            });
          }}
          value={row[name]}
          name={name}
          className="w-full"
          autoFocus={
            name_ === name && nameField === name && row.id === idEvaluation
              ? true
              : false
          }
        />
      );
  };
  //preguntar a roberth 2 desciprion an dname
  const [name_, setName] = useState("");
  const [nameField, setNameField] = useState("");
  //const [idDeliverable, setIdDeliverable] = useState("");

  const ColumnFrecuencyComponents = SchedulesTableData.frecuencyColumns.map(
    (col) => {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          style={{ width: col.field.includes("Id") ? "48%" : "4%" }}
          body={(row) => <FieldFrecuency name={col.field} row={row} />}
        />
      );
    }
  );

  const ColumnInstructorComponents = SchedulesTableData.instructorColumns.map(
    (col) => {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={(row) => <FieldInstructor name={col.field} row={row} />}
        />
      );
    }
  );
  const ColumnEvaluationComponents = SchedulesTableData.evaluationColumns.map(
    (col) => {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          style={{ width: col.field == "name" ? "50%" : "25%" }}
          body={(row) => <FieldEvaluation name={col.field} row={row} />}
        />
      );
    }
  );

  const RowDeleteFrecuency = ({ id }) => {
    return (
      <i
        onClick={() => {
          setVisibleFrecuency(true);
          setidDeleteFrecuency(id);
        }}
        className="pi pi-trash cursor-pointer"
      ></i>
    );
  };

  const RowDeleteInstructor = ({ id }) => {
    return (
      <i
        onClick={() => {
          setVisibleInstructor(true);
          setidDeleteInstructor(id);
        }}
        className="pi pi-trash cursor-pointer"
      ></i>
    );
  };

  const RowDeleteEvaluation = ({ id }) => {
    return (
      <i
        onClick={() => {
          setVisibleEvaluation(true);
          setidDeleteEvaluation(id);
        }}
        className="pi pi-trash cursor-pointer"
      ></i>
    );
  };

  return (
    <div className="bg-white">
      <form className="form-custom p-0" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-modal p-0">
          <Panel header="DATOS GENERALES" toggleable>
            <div className=" grid  sm:grid-cols-3 md:grid-cols-3 gap-2 ">
              {schedule.isLoading ? (
                <Skeleton height="2.2rem" />
              ) : (
                <span className="p-float-label w-full mt-4">
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                    }) => (
                      <InputText
                        readOnly
                        disabled
                        //type="text"
                        id="code"
                        name="code"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        // className={errors?.code ? 'p-invalid w-full' : 'w-full'}
                      />
                    )}
                    name="code"
                    // rules={{
                    //     required: 'El codigo es requerido',
                    // }}
                  />
                  <label htmlFor="code">Código del Curso *</label>
                </span>
              )}

              <span className="p-float-label w-full mt-4 hidden md:block">
                &nbsp;
              </span>
              <span className="p-float-label w-full mt-4 hidden md:block">
                &nbsp;
              </span>
            </div>

            {/* COURSE NAME */}

            <div className="grid  sm:grid-cols-1 md:grid-cols-1 gap-2 mt-2 ">
              {schedule.isLoading ? (
                <Skeleton height="2.2rem" />
              ) : (
                <span className="p-float-label w-full mt-4">
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                    }) => (
                      <InputText
                        id="name"
                        name="name"
                        readOnly
                        disabled
                        showClear
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        // className={errors?.name ? 'p-invalid w-full' : 'w-full'}
                      />
                    )}
                    name="name"
                    //     rules={{
                    //     required: 'El Nombre de Curso es requerido',
                    // }}
                  />
                  <label htmlFor="name">Nombre del Curso</label>
                </span>
              )}
            </div>
          </Panel>
        </div>
        <Panel header="PROGRAMACIÓN" toggleable>
          <div className="flex items-center gap-x-2">
            <div className="field col-12 md:col-4">
              <Controller
                name="initialDate"
                control={control}
                rules={{
                  required: "La Fecha de Inicio es requerida.",
                }}
                render={({ field: { onChange, value } }) => (
                  <Calendar
                    name="initialDate"
                    id="startdate"
                    value={fromDate}
                    placeholder="Fecha de inicio"
                    onChange={(e) => {
                      setFromDate(e.value);
                      onChange(e.value);
                    }}
                    showIcon
                    className={`w-full ${
                      errors.initialDate?.type === "required" ? "p-invalid" : ""
                    }`}
                  />
                )}
              />
            </div>

            <div className="field col-12 md:col-4">
              <Controller
                name="endDate"
                control={control}
                rules={{
                  required: "La Fecha de Fin es requerida.",
                }}
                render={({ field: { onChange, value } }) => (
                  <Calendar
                    name="endDate"
                    id="endate"
                    value={toDate}
                    placeholder="Fecha de fin"
                    onChange={(e) => {
                      setToDate(e.value);
                      onChange(e.value);
                    }}
                    showIcon
                    className={`w-full ${
                      errors.endDate?.type === "required" ? "p-invalid" : ""
                    }`}
                  />
                )}
              />
            </div>
            {/* AQUI COLOQUE LO DE LAS FECHAS */}
          </div>
          {/* AQUIva el button */}
          <Button
            icon="pi pi-plus"
            type="button"
            label="Añadir frecuencia"
            onClick={createFrecuency}
            className="btn btn-primary mt-2 mb-4"
          />
          <div className="table-main table-contact mb-3">
            <DataTable
              value={frecuencyList}
              emptyMessage="No se encontro frecuencia"
              stripedRows
              rows={5}
              editMode="row"
              dataKey="id"
              rowHover
              size="small"
              responsiveLayout="scroll"
            >
              {ColumnFrecuencyComponents}
              <Column header={<div></div>} body={RowDeleteFrecuency}></Column>
            </DataTable>
          </div>
          {/* INSTRUCTOR */}
          <Button
            icon="pi pi-plus"
            type="button"
            label="Añadir instructor"
            onClick={createInstructor}
            className="btn btn-primary mt-2 mb-4"
          />
          <div className="table-main table-contact mb-3">
            <DataTable
              value={instructorList}
              emptyMessage="No se encontro instructor"
              stripedRows
              rows={5}
              editMode="row"
              dataKey="id"
              rowHover
              size="small"
              responsiveLayout="scroll"
            >
              {ColumnInstructorComponents}
              <Column header={<div></div>} body={RowDeleteInstructor}></Column>
            </DataTable>
          </div>
        </Panel>

        {/* EVALUACIONES */}
        <Panel header="EVALUACIONES DEL CURSO" toggleable>
          <Button
            icon="pi pi-plus"
            type="button"
            label="Añadir evaluación"
            onClick={createEvaluation}
            className="btn btn-primary mt-2 mb-4"
          />

          <div className="table-main table-contact mb-3">
            <DataTable
              value={evaluationList}
              emptyMessage="No se encontraron contactos"
              stripedRows
              rows={5}
              editMode="row"
              dataKey="id"
              rowHover
              size="small"
              responsiveLayout="scroll"
            >
              {ColumnEvaluationComponents}
              <Column header={<div></div>} body={RowDeleteEvaluation}></Column>
            </DataTable>
          </div>
        </Panel>
        {(errors.initialDate || errors.endDate) && (
          <div className="message error">
            <ul>
              {errors.initialDate ? (
                <>
                  <li className="p-error">{errors.initialDate.message}</li>
                </>
              ) : null}
              {errors.endDate ? (
                <>
                  <li className="p-error">{errors.endDate.message}</li>
                </>
              ) : null}
            </ul>
          </div>
        )}
        <div className="flex justify-end gap-4 mt-3">
          <Button
            icon="pi pi-times"
            type="button"
            label="Cancelar"
            onClick={() => {
              setVisible(true);
            }}
            className="sig-button sig-secondary mt-4"
          />
          {
            (mode!=='view' && isEdit)&&(
              <Button
                icon="pi pi-save"
                type="submit"
                label="Guardar"
                loading={schedule?.isSaving || schedule?.isLoading}
                className="sig-button sig-primary mt-4"
              />
            )
          }
        </div>
      </form>

      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="¿Está seguro que desea cancelar?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={redirectToSearch}
        reject={reject}
      />
      <ConfirmDialog
        visible={visibleDialogFrecuency}
        onHide={() => setVisibleFrecuency(false)}
        message="¿Está seguro que desea eliminar el registro?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={() => deleteFrecuency()}
        reject={() => setVisibleFrecuency(false)}
      />
      <ConfirmDialog
        visible={visibleDialogInstructor}
        onHide={() => setVisibleInstructor(false)}
        message="¿Está seguro que desea eliminar el instructor?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={() => deleteInstructor()}
        reject={() => setVisible(false)}
      />

      <ConfirmDialog
        visible={visibleDialogEvaluation}
        onHide={() => setVisibleEvaluation(false)}
        message="¿Está seguro que desea eliminar la evaluación?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={() => deleteEvaluation()}
        reject={() => setVisible(false)}
      />
    </div>
  );
};

export default SchedulePage;
