
import moment from 'moment';
import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from "core/store/actions/ToastAction";

import { SurveysConstants } from 'features/survey/commons/SurveyConstants';
import FileSaver from 'file-saver';
import { SurveyService } from 'features/survey/services/SurveyServices';

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: SurveysConstants.Accion.Survey.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: SurveysConstants.Accion.Survey.RESET_AFTER_SAVED });
};

const resetAfterCloseModalAnalysis = () => {
  return (dispatch) =>
    dispatch({ type: SurveysConstants.Accion.Survey.RESET_AFTER_CLOSE_MODAL_ANALYSIS });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.title) pines.push({ field: 'title', value: advancedFilter.filter.title });
    if (advancedFilter.filter.creator) pines.push({ field: 'creator', value: advancedFilter.filter.creator.fullName });
    if (advancedFilter.filter.status) pines.push({ field: 'status', value: advancedFilter.filter.status.description });
   
    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });

    advancedFilter.values = pines;

    dispatch({
      type: SurveysConstants.Accion.Survey.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

//* Gestión de Encuestas

const search = () => {
  const setSurveys = ({ dispatch, isLoading, data, isLoaded }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.SEARCH_SURVEYS,
      payload: { isLoading, data, isLoaded },
    });
  };

  return (dispatch, getState) => {
    const criteria = {};
    const filter = getState().surveyReducer.advancedFilter.filter;
    
    // criteria.columnOrder = advancedFilter.columnOrder;
    // criteria.order = advancedFilter.order;
    // criteria.page = advancedFilter.page;
    // criteria.pageSize = advancedFilter.pageSize;

    criteria.title = filter.title;
    criteria.to = filter.to;
    criteria.from = filter.from;
    criteria.ownerId = filter.creator?.id;
    // criteria.ownerId = filter.owner;
    criteria.statusId = filter.status?.id;
    // criteria.statusId = filter.statusId;
    criteria.locationId = filter.location?.id;

    setSurveys({ dispatch: dispatch, isLoading: true, data: [], isLoaded: false });

    SurveyService.searchSurveys(criteria).then(
      ({ data }) => {
        // console.log(data)
        if (data.status === CoreConstants.HttpResponse.OK)
        setSurveys({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
            isLoaded: true
          });          
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Gestión de Encuestas', data.message));
          setSurveys({ dispatch: dispatch, isLoading: false, data: [], isLoaded: false });
        }else{
          setSurveys({ dispatch: dispatch, isLoading: false, data: [], isLoaded: false });
          dispatch(toastAction.error('Gestión de Encuestas', data.message));
        }
      },
    ).catch((error)=>{
    });
  };
};


const generateReport = (payload) => {
  const setDocuments = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setDocuments({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().surveyReducer.advancedFilter.filter;

    criteria.title = filter.title;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;
    criteria.ownerId = filter.creator?.id;
    criteria.statusId = filter.status?.id;

    SurveyService.generateReport(criteria)
      .then(({ data }) => {
        setDocuments({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Gestión de Encuestas.xlsx');
      })
      .catch((err) => {
        setDocuments({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Gestión de Encuestas', 'Hubo un error al descargar el archivo'));
      });
  };
};

const saveSurvey = (archivo) => {
  const isLoading = ({ dispatch, isSaving, isSaved,  }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.SAVE_SURVEY,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch) => {

    isLoading({ dispatch: dispatch, isSaving: true, isSaved: false });
    SurveyService.saveSurvey(archivo).then(
      ( {data} ) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gestión de Encuestas', 'La encuesta de guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true });
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL)
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        else
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    );
  };
};

const getListFolders = () => {
  const setListFolders = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.GET_DATA_LIST_FOLDERS,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setListFolders({ dispatch: dispatch, isLoading: true, data: [] });

    SurveyService.getListFolders()
      .then(({ data }) => {
        setListFolders({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setListFolders({ dispatch: dispatch, isLoading: false, data: [] });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error al descargar el archivo'));
      });
  }
};

const getSurveyById = (surveyId) => {
  const setSurvey = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.GET_SURVEY_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setSurvey({ dispatch: dispatch, isLoading: true, data: null });
    SurveyService.getSurveyById(surveyId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setSurvey({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setSurvey({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Gestión de Encuestas', data.message));
        }
      },
      () => {
        setSurvey({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  };
};

const getSurveyCopyById = (surveyId) => {
  const setSurvey = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.GET_SURVEY_COPY_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setSurvey({ dispatch: dispatch, isLoading: true, data: null });
    SurveyService.getSurveyById(surveyId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setSurvey({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setSurvey({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Gestión de Encuestas', data.message));
        }
      },
      () => {
        setSurvey({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  };
};

const deleteSurvey = (id) => {

  return (dispatch) => {

    SurveyService.deleteSurvey(id).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gestión de Encuestas', 'La encuesta se eliminó correctamente'));
          dispatch({ type: SurveysConstants.Accion.Survey.DELETE_SURVEY, payload: { isDeleted: true, id } });
        } else {
          dispatch(toastAction.warn('Gestión de Encuestas', data.message));
          dispatch({ type: SurveysConstants.Accion.Survey.DELETE_SURVEY, payload: { isDeleted: false, id: 0 } });
        }
      },
      // () => {
      //   dispatch({ type: SurveysConstants.Accion.Survey.DELETE_SURVEY, payload: { isDeleted: false } });
      // }
    );
  };
};

const deleteSurveyRecipient = (surveyId, recipientId) => {

  return (dispatch) => {
    dispatch({ type: SurveysConstants.Accion.Survey.DELETE_SURVEY_RECIPIENT, payload: { isDeleted: false } });
    SurveyService.deleteSurveyRecipient(surveyId, recipientId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gestión de Encuestas', 'La respuesta se eliminó correctamente'));
          dispatch({ type: SurveysConstants.Accion.Survey.DELETE_SURVEY_RECIPIENT, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Gestión de Encuestas', data.message));
          dispatch({ type: SurveysConstants.Accion.Survey.DELETE_SURVEY_RECIPIENT, payload: { isDeleted: true } });
        }
      },
      // () => {
      //   dispatch({ type: SurveysConstants.Accion.Survey.DELETE_SURVEY, payload: { isDeleted: false } });
      // }
    );
  };
};

const getDataMaster = () => {
  const setDataMaster = ({ dispatch, isLoading, areas, categories, permissionTypes, permissions, positions, questionTypes, roles, status, surveyTypes, users, surveySectionTypes }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.GET_DATA_MASTER,
      payload: { isLoading, areas, categories, permissionTypes, permissions, positions, questionTypes, roles, status, surveyTypes, users, surveySectionTypes },
    });
  };

  return (dispatch) => {
    setDataMaster({ dispatch: dispatch, isLoading: true, areas: [], categories: [], permissionTypes: [], permissions: [], positions: [], questionTypes: [], roles: [], status: [], surveyTypes: [], users: [], surveySectionTypes: [] });

    SurveyService.getDataMaster().then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            areas: data.data.areas,
            categories: data.data.categories,
            permissionTypes: data.data.permissionTypes,
            permissions: data.data.permissions,
            positions: data.data.positions,
            questionTypes: data.data.questionTypes,
            roles: data.data.roles,
            status: data.data.status,
            surveyTypes: data.data.surveyTypes,
            users: data.data.users,
            surveySectionTypes: data.data.surveySectionTypes
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            areas: [], categories: [], permissionTypes: [], permissions: [], positions: [], questionTypes: [], roles: [], status: [], surveyTypes: [], users: [], surveySectionTypes: []
          });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            areas: [], categories: [], permissionTypes: [], permissions: [], positions: [], questionTypes: [], roles: [], status: [], surveyTypes: [], users: [], surveySectionTypes: []
          });
          dispatch(toastAction.warn('Gestión de Encuestas', data.message));
        }
      },
      () => {
        setDataMaster({ dispatch: dispatch, isLoading: false, areas: [], categories: [], permissionTypes: [], permissions: [], positions: [], questionTypes: [], roles: [], status: [], surveyTypes: [], users: [], surveySectionTypes: [] });
      }
    );
  };
};

const setCurrentPath = (currentPath) => {
  const setCurrentPathDispatch = ({ dispatch, currentPath }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.SET_CURRENT_PATH,
      payload: { currentPath },
    });
  };
  return (dispatch) => {
    setCurrentPathDispatch({ dispatch: dispatch, currentPath });
  }
};

const shareSurvey = (surveyId) => {
  const setSurvey = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.SURVEY_SHARE,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setSurvey({ dispatch: dispatch, isLoading: true, data: null });
    SurveyService.shareSurvey(surveyId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setSurvey({
            dispatch: dispatch,
            isLoading: false,
            data: data.data.newGuid,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setSurvey({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Gestión de Encuestas', data.message));
        }
      },
      () => {
        setSurvey({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  };
};

const searchContacts = (name = '') => {
  const setSurvey = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.SEARCH_CONTACTS,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setSurvey({ dispatch: dispatch, isLoading: true, data: [] });
    SurveyService.searchContacts(name).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setSurvey({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setSurvey({ dispatch: dispatch, isLoading: false, data: [] });
          dispatch(toastAction.warn('Gestión de Encuestas', data.message));
        }
      },
      () => {
        setSurvey({ dispatch: dispatch, isLoading: false, data: [] });
      }
    );
  };
};

const setSurveyStatus = (surveyStatus) => {
  const isCloseSurvey = surveyStatus ? surveyStatus.code === 'SQCL' : false

  const setSurveyStatus = ({ dispatch, isCloseSurvey }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.SET_SURVEY_STATUS,
      payload: { isCloseSurvey },
    });
  };
  return (dispatch) => {
    setSurveyStatus({ dispatch: dispatch, isCloseSurvey });
  }
};

//* Encuesta Analisis

const getSurveySummaryById  = (surveyId) => {
  const setSurvey = ({ dispatch, isLoading, data, title }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.GET_SURVEY_SUMMARY_BY_ID,
      payload: { isLoading, data, title },
    });
  };

  return (dispatch) => {
    setSurvey({ dispatch: dispatch, isLoading: true, data: null, title: '' });
    SurveyService.getSurveySummaryById(surveyId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setSurvey({
            dispatch: dispatch,
            isLoading: false,
            data: data.data.cards,
            title: data.data.title,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setSurvey({ dispatch: dispatch, isLoading: false, data: null, title: '' });
          dispatch(toastAction.warn('Gestión de Encuestas', data.message));
        }
      },
      () => {
        setSurvey({ dispatch: dispatch, isLoading: false, data: null, title: '' });
      }
    );
  };
};

const getSurveyTrendInfoById  = (surveyId, from, to) => {
  // const fromDate = from ? moment(from).format() : moment(0).format()
  const fromDate = from ? moment(from).format() : null
  const toDate = moment(to).format()
  const setSurvey = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.GET_SURVEY_TREND_INFO_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setSurvey({ dispatch: dispatch, isLoading: true, data: null });
    SurveyService.getSurveyTrendInfoById(surveyId, fromDate, toDate).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setSurvey({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setSurvey({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Análisis de Resultados', data.message));
        }
      },
      () => {
        setSurvey({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  };
};

const generateSurveyTrendInfoReport = (surveyId, from, to) => {
  // const fromDate = from ? moment(from).format() : moment(0).format()
  const fromDate = from ? moment(from).format() : null

  const toDate = moment(to).format()
  const setDocuments = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.GENERATE_SURVEY_TREND_INFO_REPORT,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setDocuments({ dispatch: dispatch, isLoading: true });

    SurveyService.generateSurveyTrendInfoReport(surveyId, fromDate, toDate)
      .then(({ data }) => {
        setDocuments({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Análisis de Resultados por Tendencias.xlsx');
      })
      .catch((err) => {
        setDocuments({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Gestión de Encuestas', 'Hubo un error al descargar el archivo'));
      });
  };
};

const getSurveyTotalByQuestion  = (surveyId, from, to) => {
  // const fromDate = from ? moment(from).format() : moment(0).format()
  const fromDate = from ? moment(from).format() : null
  const toDate = moment(to).format()
  const setSurvey = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.GET_SURVEY_TOTAL_BY_QUESTION,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setSurvey({ dispatch: dispatch, isLoading: true, data: null });
    SurveyService.getSurveyTotalByQuestion(surveyId, fromDate, toDate).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setSurvey({
            dispatch: dispatch,
            isLoading: false,
            data: data.data.result,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setSurvey({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Análisis de Resultados', data.message));
        }
      },
      () => {
        setSurvey({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  };
};

const generateSurveyTotalByQuestionReport = (surveyId, from, to) => {
  const fromDate = from ? moment(from).format() : moment(0).format()
  const toDate = moment(to).format()
  const setDocuments = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.GENERATE_SURVEY_TOTAL_BY_QUESTION_REPORT,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setDocuments({ dispatch: dispatch, isLoading: true });

    SurveyService.generateSurveyTotalByQuestionReport(surveyId, fromDate, toDate)
      .then(({ data }) => {
        setDocuments({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Análisis de Resultados de totales por pregunta.xlsx');
      })
      .catch((err) => {
        setDocuments({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Análisis de Resultados', 'Hubo un error al descargar el archivo'));
      });
  };
};
const getSurveyInformationById  = (data) => {
  const setSurvey = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.GET_SURVEY_INFORMATION_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setSurvey({ dispatch: dispatch, isLoading: true, data: null });
    SurveyService.getSurveyInformationById(data).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setSurvey({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setSurvey({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Análisis de Resultados', data.message));
        }
      },
      () => {
        setSurvey({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  };
};
const getSurveyAnswerPerQuestion  = (data) => {
  const setSurvey = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.GET_SURVEY_ANSWER_PER_QUESTION,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setSurvey({ dispatch: dispatch, isLoading: true, data: null });
    SurveyService.getSurveyAnswerPerQuestion(data).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setSurvey({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setSurvey({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Análisis de Resultados', data.message));
        }
      },
      () => {
        setSurvey({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  };
};

const generateSurveyAnswerPerQuestionReport = (activityId) => {
  const setDocuments = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.GENERATE_SURVEY_ANSWER_PER_QUESTION_REPORT,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setDocuments({ dispatch: dispatch, isLoading: true });

    // const criteria = {};
    // const filter = getState().surveyReducer.advancedFilter.filter;

    // criteria.title = filter.title;
    // criteria.toDate = filter.from;
    // criteria.fromDate = filter.to;
    // criteria.ownerId = filter.creator?.id;
    // criteria.statusId = filter.status?.id;

    SurveyService.generateSurveyAnswerPerQuestionReport(activityId)
      .then(({ data }) => {
        setDocuments({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Análisis de Resultados por respuestas individuales.xlsx');
      })
      .catch((err) => {
        setDocuments({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Análisis de Resultados', 'Hubo un error al descargar el archivo'));
      });
  };
};

const shareSendByEmail  = (data) => {
  const setSurvey = ({ dispatch, isSent, isSending }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.SHARE_SEND_BY_EMAIL,
      payload: { isSent, isSending },
    });
  };

  return (dispatch) => {
    setSurvey({ dispatch: dispatch, isSent: false, isSending: true });
    SurveyService.shareSendByEmail(data).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setSurvey({
            dispatch: dispatch,
            isSent: true,
            isSending: false
          });
          dispatch(toastAction.success('Gestión de Encuestas', 'La encuesta se compartió correctamente'));
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setSurvey({ dispatch: dispatch, isSent: false, isSending: false });
          dispatch(toastAction.warn('Gestión de Encuestas', data.message));
        }
      },
      () => {
        setSurvey({ dispatch: dispatch, isSent: false, isSending: false });
      }
    );
  };
};

const closeSurvey = (surveyId) => {
  const isLoading = ({ dispatch, isClosed, surveyId }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.CLOSE_SURVEY,
      payload: { isClosed, surveyId },
    });
  };

  return (dispatch) => {

    isLoading({ dispatch: dispatch, isClosed: false, surveyId: 0 })
    SurveyService.closeSurvey(surveyId).then(
      ( {data} ) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gestión de Encuestas', 'La encuesta se cerró correctamente'));
          isLoading({ dispatch: dispatch, isClosed: true, surveyId });
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          isLoading({ dispatch: dispatch, isClosed: false, surveyId: 0 });
          dispatch(toastAction.warn('Gestión de Encuestas', data.message));
        }
        else {
          isLoading({ dispatch: dispatch, isClosed: false, surveyId: 0 });
          dispatch(toastAction.warn('Gestión de Encuestas', data.message));
        }
      }
    )
    .catch((error) => {
      isLoading({ dispatch: dispatch, isClosed: false, surveyId: 0 });
    });
  };
};

const getSurveyAnswerPerRecipient = (surveyId) => {
  const setSurveys = ({ dispatch, isLoading, data, isLoaded }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.GET_SURVEY_ANSWERS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const criteria = {};

    const advancedFilterSurveyAnswers = getState().surveyReducer.advancedFilterSurveyAnswers;

    
    criteria.columnOrder = advancedFilterSurveyAnswers.columnOrder;
    criteria.order = advancedFilterSurveyAnswers.order;
    criteria.page = advancedFilterSurveyAnswers.page;
    criteria.pageSize = advancedFilterSurveyAnswers.pageSize;
    criteria.surveyId = surveyId;

    // console.log(criteria)

    setSurveys({ dispatch: dispatch, isLoading: true, data: [] });

    SurveyService.getSurveyAnswerPerRecipient(criteria).then(
      ({ data }) => {
        // console.log(data)
        if (data.status === CoreConstants.HttpResponse.OK)
        setSurveys({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });          
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Gestión de Encuestas', data.message));
          setSurveys({ dispatch: dispatch, isLoading: false, data: [] });
        }else{
          setSurveys({ dispatch: dispatch, isLoading: false, data: [] });
          dispatch(toastAction.error('Gestión de Encuestas', data.message));
        }
      },
    ).catch((error)=>{
    });
  };
};

const getSurveyAnswerById = (surveyId, answerId) => {
  const setSurveys = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.GET_SURVEY_ANSWER_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {

    setSurveys({ dispatch: dispatch, isLoading: true, data: [] });

    SurveyService.getSurveyAnswerById(surveyId, answerId).then(
      ({ data }) => {
        // console.log(data)
        if (data.status === CoreConstants.HttpResponse.OK)
        setSurveys({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });          
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Gestión de Encuestas', data.message));
          setSurveys({ dispatch: dispatch, isLoading: false, data: [] });
        }else{
          setSurveys({ dispatch: dispatch, isLoading: false, data: [] });
          dispatch(toastAction.error('Gestión de Encuestas', data.message));
        }
      },
    ).catch((error)=>{
    });
  };
};

const updateFilterSurveyAnswers = (payload) => {
  return (dispatch, getState) => {
    const advancedFilterSurveyAnswers = payload; 

    dispatch({
      type: SurveysConstants.Accion.Survey.UPDATE_FILTER_SURVEY_ANSWERS,
      advancedFilterSurveyAnswers,
    });
  };
};



export const SurveyAction = {
  toggleAdvancedFilter,
  updateFilter,
  resetAfterSaved,
  resetAfterCloseModalAnalysis,
  setCurrentPath,

  //* Actions Implementadas
  generateReport,
  search,
  getListFolders,
  getSurveyById,
  getSurveyCopyById,
  getDataMaster,
  saveSurvey,
  deleteSurvey,
  shareSurvey,
  searchContacts,
  getSurveySummaryById,
  getSurveyTrendInfoById,
  generateSurveyTrendInfoReport,
  getSurveyTotalByQuestion,
  generateSurveyTotalByQuestionReport,
  getSurveyInformationById,
  getSurveyAnswerPerQuestion,
  generateSurveyAnswerPerQuestionReport,
  shareSendByEmail,
  closeSurvey,
  setSurveyStatus,
  getSurveyAnswerPerRecipient,
  updateFilterSurveyAnswers,
  getSurveyAnswerById,
  deleteSurveyRecipient

  //* Actions sin Implementar
}