import NewEvaluacionComponent from 'features/riskManagement/components/ModelManagement/Evaluacion/NewEvaluacionComponent';
import { RiskEvaluationAction } from 'features/riskManagement/store/actions/RiskEvaluationAction';
import { RiskModelAction } from 'features/riskManagement/store/actions/RiskModelAction';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';

const ModelEvaluacionPage = () => {

	const dispatch = useDispatch()
	const { updateTitle } = useSetTitlePage();
	const { idModelo, id } = useParams()

	useEffect(()=>{
		if(id){
			updateTitle({
				title: "Gestión de Riesgos",
				subtitle: "Modelo de Gestión",
				// description: <>Evaluar <i className="pi pi-angle-right"></i> Editar</>,
        description: "Editar Evaluación",
				previousUrl: `/gestion-riesgos/modelo/${idModelo}/evaluaciones`,
			})
		}else{
		updateTitle({
			title: "Gestión de Riesgos",
			subtitle: "Modelo de Gestión",
			// description: <>Evaluar <i className="pi pi-angle-right"></i> Nuevo</>,
      description: "Nueva Evaluación",
			previousUrl: `/gestion-riesgos/modelo/${idModelo}/evaluaciones`,
		})}
		dispatch(RiskModelAction.getDataMasterEvaluation())
		dispatch(RiskEvaluationAction.getEvaluationsById(idModelo))
	},[])

	return (
		<div>
			<NewEvaluacionComponent/>
		</div>
	);
};

export default ModelEvaluacionPage;