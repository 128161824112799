import { useEffect, useState } from "react";

export const useFormPassword = () => {
  const validations = [
    {
      type: "required",
      message: "La contraseña es requerida",
      validator: (password) => password && password.trim() !== "",
      status: true
    },
    {
      type: "minLength",
      message: "Debe tener al menos 8 caracteres",
      validator: (password) => password && password.trim().length >= 8,
      status: true
    },
    {
      type: "hasUpperCase",
      message: "Debe tener al menos 1 mayúscula",
      validator: (password) => password && /^(?=.*[A-Z]).*$/.test(password),
      status: true
    },
    {
      type: "hasLowerCase",
      message: "Debe tener al menos 1 minúscula",
      validator: (password) =>  password && /^(?=.*[a-z]).*$/.test(password),
      status: true
    },
    {
      type: "hasDigit",
      message: "Debe tener al menos 1 dígito",
      validator: (password) =>  password && /^(?=.*\d).*$/.test(password),
      status: true
    },
    {
      type: "hasSpecialChar",
      message: "Debe tener al menos 1 caracter especial ($#&.%()=-!)",
      validator: (password) => password && /^(?=.*[$#&.%()=\-!]).*$/.test(password),
      status: true
    },
    {
      type: "checkPasswords",
      message: "Las contraseñas no coinciden",
      validator: (password, confirmPassword) => password && password === confirmPassword,
      status: true
    },
  ]
  const [errors, setErrors] = useState(validations)
  const [dataForm, setDataForm] = useState({});

  const checkErrors = () => {
    const errors = []
    validations.forEach((validation) => {
      errors.push({
        ...validation,
        status: !validation.validator(dataForm.password, dataForm.confirmPassword)
      })
    })
    setErrors(errors)
    return errors.filter(error => error.status).length === 0
  }

  useEffect(() => {
    checkErrors()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm]);

  const handleChange = (e) => {
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    });
  };

  return {
    dataForm,
    checkErrors,
    handleChange,
    errors,
  }
}