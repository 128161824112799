import { useRef , useEffect  , useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SitesAction } from '../../store/actions/SitesAction';
import { MaintenanceConstants } from 'features/maintenance/commons/MaintenanceConstans';
const useFilter = () =>{
  const dispatch = useDispatch();
  const { showModal  , values} = useSelector((state) => state.sitesReducer.siteFilter)
  const { loading } = useSelector((state) => state.sitesReducer.sites);
  const { loadingReport } = useSelector((state) => state.sitesReducer.report);
  
  const closeModal = () =>{
    dispatch(SitesAction.toggleModalFilters({ showModal: false }));
  }

  const openModal = () =>{
    dispatch(SitesAction.toggleModalFilters({ showModal: true }));
  }

  const filterSites = (payLoad) =>{
    if (payLoad.name === "") {
      dispatch(SitesAction.searchSites(payLoad));
    }else if(payLoad.name.length >= 3){
      dispatch(SitesAction.searchSites(payLoad));
    }
  }

  const filterSitesAdvanced = (payLoad) =>{
    dispatch(SitesAction.searchSitesAdvanced({...payLoad}));

  }
  const showPiners = () => {
    if (values.length > 0 && values[0].value !== null && values[0].value !== '') return true;
    else return false;
  };

  const removePiner = (field) => {
    dispatch(SitesAction.removeFilterValues(field));
  };

  const generateReport = () =>{
    dispatch(SitesAction.generateReport(values))
  }

  const setNameFilter = (name) =>{
    dispatch({
      type: MaintenanceConstants.Accion.Sites.ADVANCED_FILTER,
      payload: { values: [{ field : 'name' , value : name}] },
    });
  }


  return {
    showModal,
    openModal,
    closeModal,
    loading,
    filterSites,
    generateReport,
    values,
    showPiners,
    removePiner,
    filterSitesAdvanced,
    loadingReport,
    setNameFilter
  }
}


export default useFilter