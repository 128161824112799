import { BusinessSheetTypeConstants } from "features/maintenance/commons/BusinessSheetTypeConstants"; 

const initialState = {
  businessSheet: {
    data: [],
    loading: false,
    order: 'asc',
    pagination: {},
    columnOrder: 'name',
    isLoadingDownload: false,
  },
  data: {
    businessSheet: {},
    loading: false,
  },
  save: {
    isLoadingSave: false,
  },
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case BusinessSheetTypeConstants.Action.BusinessSheet.SEARCH:
      return {
        ...state,
        businessSheet: {
          ...state.businessSheet,
          ...action.payload,
        },
      };
    
    case BusinessSheetTypeConstants.Action.BusinessSheet.EDIT:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    case BusinessSheetTypeConstants.Action.BusinessSheet.SAVE:
      return {
        ...state,
        save: {
          ...state.save,
          ...action.payload,
        },
      };

    default:
      return state;
  }
} 