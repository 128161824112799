import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SearchSurveyPage from './pages/Survey/Search/SearchSurveyPage';
import SurveyAnalysis from './pages/Survey/Survey/SurveyAnalysis';
import SurveyPage from './pages/Survey/Survey/SurveyPage';
import SurveyPreviewPage from './pages/Survey/Survey/SurveyPreviewPage';



import surveyReducer from './store/reducers/SurveyReducer';
import surveyManagementReducer from './store/reducers/SurveyManagementReducer';
import SearchSurveyManagementPage from './pages/SurveyManagement/Search/SearchSurveyManagementPage';
import { SurveyAnswers } from './pages/Survey/Survey/SurveyAnswers';


export default function SurveyRouting(reducerRegistry) {
  const { path } = useRouteMatch();
  reducerRegistry.register({ surveyReducer });
  reducerRegistry.register({ surveyManagementReducer });

  return (
    <>
      <Switch>
        <Route path={`${path}/encuesta`}>
          <Switch>
            <Route
              exact
              path={`${path}/encuesta`}
              component={SearchSurveyPage}
            />
            <Route
              path={`${path}/encuesta/nuevo`}
              exact
              component={() => <SurveyPage title="Nueva Encuesta" />}
            />
            <Route
              path={`${path}/encuesta/:id`}
              exact
              component={() => <SurveyPage title="Editar Encuesta" />}
            />
            <Route
              path={`${path}/encuesta/vista-previa/:id`}
              exact
              component={() => <SurveyPreviewPage title="Vista Previa" />}
            />
            <Route
              path={`${path}/encuesta/analisis/:id`}
              exact
              component={() => <SurveyAnalysis title="Análisis de Resultados" />}
            />
            <Route
              path={`${path}/encuesta/:surveyId/respuestas/:answerId`}
              exact
              component={() => <SurveyAnswers title="Análisis de Resultados" />}
            />
          </Switch>
        </Route>

        <Route path={`${path}/administracion`}>
          <Switch>
            <Route
              exact
              path={`${path}/administracion`}
              component={SearchSurveyManagementPage}
            />
          </Switch>
        </Route>
      </Switch>
    </>
  )
}