import { useEffect, useRef, useState } from "react";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { TableComponent } from "features/projects/components/viewDeliverables/TableComponent";
import { Panel } from "primereact/panel";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useDeliverable from '../../hooks/useDeliverable'
import useProject from "features/projects/hooks/useProject"
import { useParams } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import { Link } from "react-router-dom";
import { useClaimViewProject } from "features/projects/hooks/useClaimViewProject";
import { GenerateSnapshot } from "features/projects/components/viewDeliverables/modals/GenerateSnapshot";
import { useForm } from "react-hook-form";
import { NavigationSnapShot } from "features/projects/components/viewDeliverables/modals/NavigationSnapShot";
import { GeneralData } from "features/projects/components/viewDeliverables/GeneralData";
import { useSelector } from "react-redux";
export const PageViewDeliverables = ({title}) => {
  const history = useHistory()
  const params = useParams()
  const name = useRef()
  const { updateTitle } = useSetTitlePage();
  const [visibleCancel, setIsVisibleCancel] = useState(false);
  const {isDownload, isProject} = useClaimViewProject()
  const {
    dataProject,
    loadingEdit,
    deleteTask
  } = useDeliverable("edit")
  const {
    loadingReport,
    downloadViewProjectReport,
    visibleGenerate,
    visibleSuccess,
    showGenerateModal,
    closeGenerateModal,
    showSuccessModal,
    closeSuccessModal,
    generateSnapshot,
  } = useProject()

  // Generate Snapshoot
  const { control, handleSubmit, formState: { errors } } = useForm({ defaultValues: { name: ''}, shouldUnregister: true})

  const submit = ( data ) => {
    name.current = data.name
    generateSnapshot({projectId: params.id, name: data.name, showModal: showSuccessModal, closeGenerateModal})
  }

  /* TITLE ------------------------- */
  const pageTitle = {
    title: "Gestión de Proyectos",
    subtitle: "Ver Proyectos",
    description: title,
    previousUrl: "/proyecto/vista-proyectos/",
  };
  useEffect(() => {
    updateTitle(pageTitle);
  }, []);
  const SkeletonCustom = ({ width = "100%" }) => {
    return <Skeleton width={width} height="2.2rem" />;
  };

  return (
    <div className="bg-white">
      <div className="div-custom p-0">
        <GeneralData 
          data={dataProject}
          download={() => downloadViewProjectReport(params.id)}
          isDownloadEnable={isDownload && title!=='Ver Proyecto'}
          isLinkEnable={isProject && title!=='Ver Proyecto'}
          loadingEdit={loadingEdit}
          loadingReport={loadingReport}
          onClickCancel={() => setIsVisibleCancel(true)}
          showGenerateModal={showGenerateModal}
          linkToSnapShots={`/proyecto/vista-proyectos/planificaciones/${params.id}`}
        />
        
        <Panel header="ENTREGABLES" toggleable={true}>
          <div className="w-full">
            <TableComponent dataProject={dataProject} deleteTask={deleteTask}/>
          </div>
        </Panel>
      </div>
      <ConfirmDialog
        visible={visibleCancel}
        onHide={() => setIsVisibleCancel(false)}
        message="Estás seguro que desea Cancelar?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={() => {
          history.push(
            "/proyecto/vista-proyectos/"
          );
        }}
        reject={() => setIsVisibleCancel(false)}
      />
      <GenerateSnapshot
        close={closeGenerateModal}
        visible={visibleGenerate}
        name={dataProject?.name}
        projectId={params.id}
        showModal={showSuccessModal}
        loadingReport={loadingReport}
        control={control}
        errors={errors}
        onSubmit={handleSubmit(submit)}
      />
      <NavigationSnapShot
        close={closeSuccessModal}
        projectId={params.id}
        planificationId={2}
        projectName={dataProject?.name}
        snapShotName={name.current}
        visible={visibleSuccess}
      />
    </div>
  );
};
