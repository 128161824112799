import React, { useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Column } from 'primereact/column';
import { SelectButton } from 'primereact/selectbutton';
import { Paginator } from 'primereact/paginator';
import { useHistory } from 'react-router';
import { Fragment } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import { CoreConstants } from 'core/commons/CoreConstants';
import { Menu } from 'primereact/menu';


import useModuleIso from 'features/subscription/hooks/modulesIso/useModulesIso';
import useTable from 'features/subscription/hooks/modulesIso/useTable';

const ModulesTableComponent = () => {
  const history = useHistory();
  const [visible, setIsVisible] = useState(false);
  const { onPageChange, firstTable, rowsTable } = useTable();
  const typeViews = [
      { icon: 'pi pi-list', value: CoreConstants.TypeView.LIST },
      { icon: 'pi pi-microsoft', value: CoreConstants.TypeView.CANVA }
  ];
  const columns = [
    { field: 'name', header: 'Nombre' },
    { field: 'optionsCount', header: 'N° Opciones' },
    { field: 'creationDate', header: 'Fecha de Creacion' }    
  ];
  const [selectedColumns, setSelectedColumns] = useState(columns);
  const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);
  const actionsHeader = useRef(null);
  const menu = useRef(null);
  const styles ={
      Activo:{
          border:"1px solid #8CC9A6",
          padding:"0rem 0.50rem",
          borderRadius:"5px"
      },
      Cesado:{
          border:"1px solid #F6697C",
          padding:"0rem 0.50rem",
          borderRadius:"5px"
      }
  }
  const [idModule, setIdModule] = useState(null);  
  const { dataModules, deleteModuleId, pagination } = useModuleIso();
  
  
  const columnComponents = selectedColumns.map(col => {
    return <Column key={col.field} field={col.field} header={col.header} sortable />;
  });


  const actionHeaderTemplate = (rowData) => {
      return <i
          key="action"
          className="pi pi-ellipsis-v cursor-pointer"
          onClick={(e) => {
              actionsHeader.current.toggle(e)
          }}></i>;
  }

  const onColumnToggle = (e, item) => {
    let _selectedColumns = [...selectedColumns];
    if (e.checked)
        _selectedColumns.push(item);
    else
        _selectedColumns = _selectedColumns.filter(c => { return c.field !== e.value });
    setSelectedColumns(_selectedColumns);
}

  const actionBodyTemplate = (rowData) => {
      return <i
          key={rowData.id}
          className="pi pi-ellipsis-v cursor-pointer"
          onClick={(event) => {
              setIdModule(rowData.id)
              menu.current.toggle(event)
          }}></i>
  }  

  const getColumns = () => {
      let array = [];
      columns.map((item, index) => {
          const column = <div key={item.field} className="p-field-checkbox py-1">
                            <Checkbox
                                inputId={item.field}
                                name="column"
                                value={item.field}
                                onChange={(e) => onColumnToggle(e, item)}
                                checked={selectedColumns.some((x) => x.field === item.field)}
                            />
                            <label class="ml-1" htmlFor={item.field}>{item.header}</label>
                        </div>
          array.push(column);
      });
      return array;
  };

  const items = [
      {
          label: 'Editar',
          icon: 'pi pi-pencil',
          command: () => {
              history.push("/suscripcion/modulo_iso/editar/" + idModule)
          }
      },
      {
          label: 'Eliminar',
          icon: 'pi pi-times',
          command: () => {
              setIsVisible(true)
          }
      }
  ];
  return (
      <Fragment>

          <div className="flex justify-end mb-2">
              <SelectButton
                  unselectable={false}
                  value={typeView}
                  options={typeViews}
                  onChange={(e) => setTypeView(e.value)}
                  itemTemplate={(option) => { return <i className={option.icon}></i>; }}
              />
          </div>

          {typeView === CoreConstants.TypeView.CANVA && <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2">
              {
                  dataModules.map((item, index) => {
                      return (
                          <div className="block p-4 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

                              <div className="flex justify-between">
                                  <h5 className="mb-1 text-md font-bold   text-gray-900 dark:text-white">
                                      {item.name}
                                  </h5>
                                  <i key={item.id} className="pi pi-ellipsis-v cursor-pointer"
                                      onClick={(e) => {
                                          setIdModule(item.id)
                                          menu.current.toggle(e)
                                      }}></i>
                              </div>
                              <div className="block">
                                  <h5 className="text-xs text-gray-700 dark:text-gray-400">
                                      N° Opciones:  {item.optionsCount}
                                  </h5>
                                  <h5 className="text-xs text-gray-700 dark:text-gray-400">
                                      {item.creationDate}
                                  </h5>
                              </div>
                          </div>
                      );
                  })
              }

          </div>}


          {typeView === CoreConstants.TypeView.LIST &&
              <div className="table-main table-roles mt-3 ">
                  <DataTable value={dataModules} stripedRows responsiveLayout="scroll" rows={rowsTable} size="small">
                      <Column field="name" header="Nombre" sortable />
                      {columnComponents}
                      <Column header={actionHeaderTemplate} body={actionBodyTemplate} ></Column>
                  </DataTable>
                  <Paginator
                      className="paginator-custom mt-4"
                      first={firstTable}
                      totalRecords={pagination?.rowCount}
                      rows={rowsTable}
                      onPageChange={onPageChange}
                  ></Paginator>
              </div>
          }

          <OverlayPanel ref={actionsHeader} showCloseIcon style={{ width: '300px' }}>{getColumns()}</OverlayPanel>

          <Menu model={items} popup ref={menu} id="popup_menu" />
          <ConfirmDialog visible={visible} onHide={() => setIsVisible(false)} message="Estás seguro de querer eliminar el modulo ?"
              header="Confirmation" icon="pi pi-exclamation-triangle" 
              accept={() => { deleteModuleId(idModule) }} 
              reject={() => setIsVisible(false)} />
      </Fragment>
  );
};

export default ModulesTableComponent;