import { MaintenanceConstants } from "features/maintenance/commons/MaintenanceConstans"; 
import { CoreConstants } from 'core/commons/CoreConstants';
import moment from 'moment';
import { toastAction } from "core/store/actions/ToastAction";
import FileSaver from 'file-saver';
import { BusinessSectorsService } from "features/maintenance/services/BusinessSectorsServices";


const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: MaintenanceConstants.Accion.BusinessSector.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};
const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: MaintenanceConstants.Accion.BusinessSector.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.name)
      pines.push({ field: 'name', value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });


    advancedFilter.values = pines;
    dispatch({
      type: MaintenanceConstants.Accion.BusinessSector.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setBusinessSectors = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.BusinessSector.SEARCH_BUSINESS_SECTORS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const businessSectors = getState().businessSectorReducer.businessSectors;
    setBusinessSectors({
      dispatch: dispatch,
      isLoading: true,
      data: businessSectors.data,
    });

    const criteria = {};
    const advancedFilter = getState().businessSectorReducer.advancedFilter;
    const filter = getState().businessSectorReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;
    
    
    BusinessSectorsService.searchBusinessSectors(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setBusinessSectors({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });          
        else if (data.status < CoreConstants.HttpResponse.OK)
        setBusinessSectors({ dispatch: dispatch, isLoading: false, data: null });
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setBusinessSectors({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Rubro', data.message));
        }else{
          setBusinessSectors({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Rubro', data.message));
        }
      },
      () => {
        setBusinessSectors({ dispatch: dispatch, isLoading: false, data: null });
      }
    ).catch((error)=>{
    });
  };
};

const getBusinessSectorById = (businessSectorId) => {
  const setBusinessSector = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.BusinessSector.GET_BUSINESS_SECTOR_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setBusinessSector({ dispatch: dispatch, isLoading: true });
    BusinessSectorsService.getBusinessSectorById(businessSectorId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setBusinessSector({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setBusinessSector({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Rumbro', data.message));
          // dispatch({
          //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
          //   toast: {
          //     titulo: 'Departamento',
          //     mensaje: data.message,
          //     severidad: 'warn',
          //   },
          // });
        }else{
          setBusinessSector({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.error('Rubro', data.message));
        }
      },
      () => {
        setBusinessSector({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};

const saveBusinessSector = (businessSector) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentBusinessSector }) => {
    dispatch({
      type: MaintenanceConstants.Accion.BusinessSector.SAVE_BUSINESS_SECTOR,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    const currentBusinessSector = getState().businessSectorReducer.businessSector;
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
      //currentBusinessSector: currentBusinessSector,
    });
    BusinessSectorsService.saveBusinessSectors(businessSector).then(
      ({ data }) => {      
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Rubro', 'El rubro se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentBusinessSector, });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Rubro', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentBusinessSector });
        }else{
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
          dispatch(toastAction.error('Rubro', data.message));
        }

      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};



const deleteBusinessSector = (businessSectorId) => {

  return (dispatch, getState) => {

    BusinessSectorsService.deleteBusinessSector(businessSectorId).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Rubro', 'El rubro se eliminó correctamente'));
          dispatch({ type: MaintenanceConstants.Accion.BusinessSector.DELETE_BUSINESS_SECTOR, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Rubro', data.message));
          dispatch({ type: MaintenanceConstants.Accion.BusinessSector.DELETE_BUSINESS_SECTOR, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: MaintenanceConstants.Accion.BusinessSector.DELETE_BUSINESS_SECTOR, payload: { isDeleted: false } });
      }
    );
  };
};

const generateReport = (payload) => {
  const setBusinessSectors = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.BusinessSector.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setBusinessSectors({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().businessSectorReducer.advancedFilter.filter;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    BusinessSectorsService.generateReport(criteria)
      .then(({ data }) => {
        setBusinessSectors({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Rubro.xls');
      })
      .catch((err) => {
        setBusinessSectors({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Línea de negocio', 'Hubo un error al descargar el archivo'));
      });
  };
};

export const BusinessSectorAction = {
  toggleAdvancedFilter,
  updateFilter,
  search,
  saveBusinessSector,
  deleteBusinessSector,
  generateReport,
  resetAfterSaved,
  getBusinessSectorById,
}