import { Link } from "react-router-dom";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { SelectButton } from "primereact/selectbutton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Menu } from "primereact/menu";
import { Skeleton } from "primereact/skeleton";

import TableCard from "./TableCard";

import { CoreConstants } from "core/commons/CoreConstants";
import typeViews from "shared/utils/typeViews";

import { useTableDynamicFields } from "features/configuration/hooks/dynamicFields/useTableDynamicFields";
import { DynamicFieldsTableData } from "features/configuration/data/dynamicFields/DynamicFieldsTableData";
import { ConfirmDialog } from "primereact/confirmdialog";
// import { useClaimCompanies } from "features/customers/hooks/useClaimCompanies";

export const TableComponent = () => {
  const {
    selectColumns,
    typeView,
    setTypeView,
    headerActions,
    showActions,
    onColumnToggle,
    tableCurrentActions,
    tableActions,
    tableDynamicFields,
    tableLoading,
    tablePagination,
    setCurrentDynamicFields,
    tableSort,
    onSort,
    tableNum,
    tableRows,
    onChangePage,
    isOpenModalDelete,
    cancelDelete,
    confirmDelete,
  } = useTableDynamicFields();

  // const { isView ,isEdit, isDelete } = useClaimCompanies();
  const isView = true
  const isEdit = true
  const isDelete = true


  const getViewField = (rowData) => {
    if (isView)
      return <Link className="underline text-blue-700" to={{ pathname: `/configuracion/campos-dinamicos/view/` + rowData.id }} >{rowData.entityName}</Link>
    else
      return rowData.entityName;
  }

  const ColumnComponents = selectColumns.map((col) => {

    if (col.field === 'entityName')
        return <Column key={col.field} field={col.field} header={col.header} body={(rowData) => getViewField(rowData)} sortable />

    return (
      <Column key={col.field} field={col.field} header={col.header} sortable />
    );
  });
  const TableHeaderActions = () => {
    return (
      <i
        key="action"
        className="pi pi-ellipsis-v cursor-pointer ml-1"
        onClick={(e) => headerActions.current.toggle(e)}
      ></i>
    );
  };

  const selectedRow = (event, { id }) => {
    setCurrentDynamicFields(id);
    tableCurrentActions.current.toggle(event);
  };

  const TableBodyActions = (selectedItem) => {
    if(isEdit||isDelete){
      return (
        <i
          onClick={(event) => selectedRow(event, selectedItem)}
          className="pi pi-ellipsis-v cursor-pointer"
        ></i>
      );
    }else{
      return <div></div>
    }
  };
  const HeaderColumns = () => {
    return DynamicFieldsTableData.columns.map(({ field, header }) => (
      <div key={field} className="p-field-checkbox py-1">
        <Checkbox
          inputId={field}
          name="column"
          value={field}
          onChange={(e) => onColumnToggle(e, { field, header })}
          checked={selectColumns.some((x) => x.field === field)}
        />
        <label class="ml-1" htmlFor={field}>
          {header}
        </label>
      </div>
    ));
  };
  const showPagination = !tableLoading && tableDynamicFields.length > 0;
  return (
    <div className="mt-5">
      <div className="flex justify-end mb-2">
        <SelectButton
          unselectable={false}
          value={typeView}
          options={typeViews}
          itemTemplate={({ icon }) => <i className={icon}></i>}
          onChange={(e) => setTypeView(e.value)}
        />
      </div>
      {typeView === CoreConstants.TypeView.LIST && (
        tableLoading ? <Skeleton height="8rem"/> :
        <DataTable
        value={tableDynamicFields}
        emptyMessage="No se encontraron empresas"
        stripedRows
        rows={10}
        rowHover
        size="small"
        responsiveLayout="scroll"
        onSort={onSort}
        first={tableSort?.first}
        sortField={tableSort?.sortField}
        sortOrder={tableSort?.sortOrder}
        filters={tableSort?.filters}
      >
        {ColumnComponents}
        {showActions && (
          <Column
            header={TableHeaderActions}
            body={TableBodyActions}
          ></Column>
        )}
        </DataTable>
      )}

      {typeView === CoreConstants.TypeView.CANVA && (
        <div 
          className="grid gap-2"
          style={{gridTemplateColumns: "repeat(auto-fill, minmax(192px, 1fr))"}}
        >
          {tableDynamicFields.map((item, index) => (
            <TableCard
              {...item}
              key={index}
              isEdit={isEdit}
              isDelete={isDelete}
              isView={isView}
              id={item.id}
              selectedRow={selectedRow}
            />
          ))}
        </div>
      )}

      {showPagination && typeView === CoreConstants.TypeView.LIST && (
        <Paginator
          className="paginator-custom mt-4"
          rowsPerPageOptions={[5, 10, 15]}
          first={tableNum}
          rows={tableRows}
          totalRecords={tablePagination?.rowCount}
          onPageChange={onChangePage}
        ></Paginator>
      )}

      <OverlayPanel
        ref={headerActions}
        showCloseIcon
        style={{ width: "300px" }}
      >
        <HeaderColumns />
      </OverlayPanel>
      <Menu model={tableActions} popup ref={tableCurrentActions} />
      <ConfirmDialog
        visible={isOpenModalDelete}
        onHide={cancelDelete}
        message="¿Está seguro que desea eliminar los campos dinámicos?"
        header="Eliminar"
        icon="pi pi-trash"
        accept={confirmDelete}
        reject={cancelDelete}
      />
    </div>
  );
};
