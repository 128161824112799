import { useState, useRef, useEffect } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Menu } from "primereact/menu";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { TasksViewTableData } from "features/projects/data/viewDeliverables/TasksViewTableData";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import { CoreConstants } from "core/commons/CoreConstants";
import useTable from "features/projects/hooks/useTable";
import { Paginator } from "primereact/paginator";
import ModalFormTaskComponent from "features/customers/components/tasks/ModalFormComponent";
import { useClaimViewProject } from "features/projects/hooks/useClaimViewProject";
import { useDispatch, useSelector } from "react-redux";
import './TableComponent.scss'
import { AppActions } from "shared/store/actions/AppAction";

export const TableComponent = ({ deleteTask, dataProject, isSnapshot = false }) => {
  const { id: projectId } = useParams()

  const {isTask: isTaskShow} = useClaimViewProject()
  const isTask = isTaskShow && !isSnapshot

  const deliverableId = useSelector(
    (state) => state.appReducer.parameters.deliverableId
  );

  const {
    rowsTable,
    search,
    first,
    pagination,
    onPage,
    idTask,
    setIdTask,
    documentId,
    setDocumentId
  } = useTable();

  const [deliverables, setDeliverables] = useState({});

  const currentDate = moment(new Date()).format(CoreConstants.Format.LOCALE)

  const styles ={
    Completed:{
        border:"1px solid #8CC9A6",
        padding:"0rem 0.50rem",
        borderRadius:"5px"
    },
    DatePassed:{
        border:"1px solid #F6697C",
        padding:"0rem 0.50rem",
        borderRadius:"5px"
    },
    Default:{
      border:"1px solid #000",
      padding:"0rem 0.50rem",
      borderRadius:"5px"
  },
  }

  const onBodyStatusTemplate  = (rowData) =>{

    if(rowData.IsCompleted === true){
      return <h1 className='text-center' style={styles["Completed"]}>{rowData.status}</h1>
    }else if(rowData.IsCompleted === false){
      if(rowData.endDate){
        let endDate = moment(rowData.endDate).format(CoreConstants.Format.LOCALE)
          if(endDate<currentDate){
            return <h1 className='text-center' style={styles["DatePassed"]}>{rowData.status}</h1>
          }else{
            return <h1 className='text-center' style={styles["Default"]}>{rowData.status}</h1>
          }
      }else{
        return <h1 className='text-center' style={styles["Default"]}>{rowData.status}</h1>
      }
    }
}



  const [selectData, setSelectData] = useState([]);
  const [showTaskModal, setShowTaskModal] = useState(false);
  /* TABLE HEADER COLUMNS */
  const [selectColumns, setSelectColumns] = useState(
    TasksViewTableData.columns
  );
  const dispatch = useDispatch()

  useEffect(() => {
    if (Object.entries(dataProject).length > 0) {
      setSelectData(dataProject?.deliverables);
      let object = {};
      dataProject?.deliverables.forEach((deliverable) => {
        object[deliverable.id] = [];
      });
      setDeliverables(object);
    }
  }, [dataProject]);
  /* TABLE DATA */
  const [visible, setIsVisible] = useState(false);
  const actionsHeader = useRef(null);
  const menu = useRef(null);
  const [expandedRows, setExpandedRows] = useState({});
  const getColumns = () => {
    let array = [];
    TasksViewTableData.columns.map((item, index) => {
      const column = (
        <div key={item.field} className="p-field-checkbox py-1">
          <Checkbox
            inputId={item.field}
            name="column"
            value={item.field}
            onChange={(e) => onColumnToggle(e, item)}
            checked={selectColumns.some((x) => x.field === item.field)}
          />
          <label class="ml-1" htmlFor={item.field}>
            {item.header}
          </label>
        </div>
      );
      array.push(column);
    });
    return array;
  };
  const onColumnToggle = (e, item) => {
    let _selectedColumns = [...selectColumns];

    if (e.checked) _selectedColumns.push(item);
    else
      _selectedColumns = _selectedColumns.filter((c) => {
        return c.field !== e.value;
      });

    setSelectColumns(_selectedColumns);
  };

  useEffect(()=>{
    let _expandedRows = {};
      selectData?.forEach(p => {
        _expandedRows[p.id]= true
      });
      setExpandedRows(_expandedRows);
  },[selectData])

  let items = [
    {
      label: "Editar",
      icon: "pi pi-pencil",
      command: () => {
        setShowTaskModal(true);
      },
    },
    {
      label: "Eliminar",
      icon: "pi pi-trash",
      command: () => {
        // setShowTaskModal(true)
        setIsVisible(true);
      },
    },
  ];

  if(!isTask){
    items = items.filter(el=> el.label!=='Editar')
  }

  const templateDeliverable = (data) => {
    return (
      <div className="flex items-center">
        <h5>{data.name}</h5>
        {
          isTask&&(
            <Button
              icon="pi pi-plus"
              onClick={() => {
                // showModalTask()
                setIdTask("");
                setDocumentId(data.id);
                setShowTaskModal(true);
              }}
              style={{ minWidth: "auto" }}
              className="p-button p-component p-button-rounded p-button-text p-button-icon-only"
            />
          )
        }
      </div>
    );
  };

  const bodyTable = {
    Name: templateDeliverable,
    BeginDate: (row) => moment( new Date(row.beginDate)).format(CoreConstants.Format.LOCALE),
    EndDate: (row) => moment( new Date(row.endDate)).format(CoreConstants.Format.LOCALE),
    Progress: (row) => <div>{row.progress.toFixed(2)} %</div>
  }

  const ColumnComponents = selectColumns?.map((col) => {
    return (
      <Column
        key={col?.field}
        field={col?.field}
        header={col?.header}
        sortable
        body={bodyTable[col?.field]}
      />
    );
  });

  const actionBodyTemplate = (rowData, documentId) => {
    return (
      <i
        key={rowData?.id}
        className="pi pi-ellipsis-v cursor-pointer"
        onClick={(event) => {
          setIdTask(rowData?.id);
          setDocumentId(documentId);
          menu?.current.toggle(event);
        }}
      ></i>
    );
  };
  const rowExpansionTemplate = (data) => {
    const dataTasks = data.tasks.map((item) => {
      const aux = {
        id: item.id,
        title: item.title,
        scheduleDate: item.scheduleDate,
        endDate: item.endDate,
        // responsibleName: item.responsible?.fullName,
        responsibleName: item.responsibleName,
        progress: item.progress,
        status: item.progress<100? "Pendiente": "Completo",
        // IsCompleted: item.isCompleted
        IsCompleted: item.progress === 100
      }
      return aux
    })
  
    return (
      <div className="tasks-subtable">
        <DataTable
          value={dataTasks}
          stripedRows
          responsiveLayout="scroll"
          // loading={isLoadingTasks}
          loading={false}
          rows={rowsTable}
          size="small"
        >
          <Column field="title" header="Tarea" sortable />
          <Column
            field="scheduleDate"
            header="Inicio"
            body={(e) => (
              <h2>
                {moment(e?.scheduleDate.substring(0, 10)).format(CoreConstants.Format.LOCALE)}
              </h2>
            )}
          />
          <Column
            field="endDate"
            header="Fin"
            body={(e) => (
              <h2>
                {e.endDate
                  ? moment(e?.endDate.substring(0, 10)).format(CoreConstants.Format.LOCALE)
                  : "-"}
              </h2>
            )}
          />
          <Column
            field="responsibleName"
            header="Responsable"
            body={(e) => <h2>{e.responsibleName}</h2>}
          />
          <Column
            field="progress"
            header="Avance"
            body={(e) => <h2 className="text-right">{e.progress} %</h2>}
          />
          <Column
            field="status"
            header="Estado"
            body={onBodyStatusTemplate}
          />
          {
            !isSnapshot && <Column body={(e) => actionBodyTemplate(e, data.id)}></Column>
          }
        </DataTable>
        <Paginator
          rows={rowsTable}
          totalRecords={pagination?.rowCount}
          first={first}
          onPageChange={(e) => onPage(e, data.id)}
        ></Paginator>
      </div>
    );
  };

  const [goToRow, setGoToRow] = useState(true)

  useEffect(() => {
    const element = document.querySelector(".table-deliverable-selected");
    if(Object.keys(expandedRows).length > 0 && goToRow && element){
      console.log(element)
      element.scrollIntoView({ behavior: "smooth" });
      setGoToRow(false)
    }
  }, [expandedRows]);

  useEffect(() => {
    return () => {
      dispatch(AppActions.setParameters({deliverableId: null}))
    }
  },[])

  return (
    <div className="mt-5">
      <div className="mt-3 w-full">
        <DataTable
          value={selectData}
          stripedRows
          rows={rowsTable}
          rowHover
          responsiveLayout="scroll"
          loading={false}
          size="small"
          emptyMessage="No se encontraron Entregables"
          rowClassName={(data) => {
            return data.id === deliverableId ? "table-deliverable-selected" : ""
          }}
          rowExpansionTemplate={(e) =>
            rowExpansionTemplate(selectData.filter((x) => x.id === e.id)[0])
          }
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          onRowExpand={(e) => {
            setDocumentId(e.data.id);
            search(e.data.id);
          }}
          dataKey="id"
          paginator
        >
          <Column expander style={{ width: "2em" }} />
          {ColumnComponents}
        </DataTable>
      </div>
      <OverlayPanel
        ref={actionsHeader}
        showCloseIcon
        style={{ width: "300px" }}
      >
        {getColumns()}
      </OverlayPanel>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <ConfirmDialog
        visible={visible}
        onHide={() => {
          setIsVisible(false);
        }}
        message="Estás seguro que desea eliminar la Tarea?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={() => {
          deleteTask(idTask,documentId);
        }}
        reject={() => {
          setIsVisible(false);
        }}
      />

      <ModalFormTaskComponent
        isOpen={showTaskModal}
        onClose={() => setShowTaskModal(false)}
        sourceDocumentCode={CoreConstants.Task.Source.PROJECT}
        sourceDocumentId={documentId}
        taskId={idTask}
        projectId={projectId}
      />
    </div>
  );
};
