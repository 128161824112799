import { Button } from "primereact/button";
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";

export const Notifications = ({control, data, setData}) => {

  const addRow = () => {
    setData( prevState => {
      const newId = Math.min(...(data.map(row => row.id) || []), 0) - 1
      console.log(newId)
      return [
        ...prevState,
        {
          id: newId,
          name: "",
          email: ""
        }
      ]
    })
  }
  
  const removeRow = (id) => () => {
    setData(prevState => {
      const newData = JSON.parse(JSON.stringify(prevState)).filter(row => row.id !== id)
      console.log(newData)
      return newData
    })
  }

  const TableBodyActions = (row) => {
    return (
      <i
        onClick={removeRow(row.id)}
        className="pi pi-trash cursor-pointer"
      ></i>
    );
  };

  return(
    <Panel header="NOTIFICACIONES" toggleable className='col-span-10 lg:col-span-10'>
      {/* <Button
        icon="pi pi-plus"
        type="button"
        label="guardar"
        className="sig-button sig-dark my-2"
        onClick={handleSubmit(d => console.log(d))}
      /> */}
      <Button
        icon="pi pi-plus"
        type="button"
        label="Añadir"
        className="sig-button sig-dark my-2"
        onClick={addRow}
      />
      <DataTable
        value={data}
        // emptyMessage="No existe "
        stripedRows
        rowHover
        size="small"
        responsiveLayout="scroll"
        dataKey="id"
      >
        <Column
          field="name"
          header="Nombre"
          body={(row) => (
            <Controller
              control={control}
              name={`notification_name_${row.id}`}
              defaultValue={row.name}
              rules={{
                required: 'La información de las notificaciones debe estar completa',
              }}
              render={({field: {name, onChange, value}, fieldState: { error }}) => {
                return <InputText
                  name={name}
                  onChange={onChange}
                  value={value || ""}
                  className={error ? 'p-invalid w-full' : 'w-full'}
                />
              }}
            />
          )}
        />
        <Column
          field="email"
          header="Email"
          body={(row) => (
            <Controller
              control={control}
              name={`notification_email_${row.id}`}
              rules={{
                required: 'La información de las notificaciones debe estar completa',
              }}
              defaultValue={row.email}
              render={({field: {name, onChange, value}, fieldState: { error }}) => {
                return <InputText
                  name={name}
                  onChange={onChange}
                  value={value || ""}
                  className={error ? 'p-invalid w-full' : 'w-full'}
                />
              }}
            />
          )}
        />
        <Column body={TableBodyActions}></Column>
      </DataTable>
    </Panel>
  )
}