import React, { useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import FormComponent from 'features/riskManagement/components/ModelManagement/FormComponent';
import ConfigurationComponent from 'features/riskManagement/components/ModelManagement/ConfigurationComponent';
import { RiskModelAction } from 'features/riskManagement/store/actions/RiskModelAction';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useHistory, useParams } from 'react-router-dom';
import './NewModelManagementPage.scss'
import { useClaimModels } from 'features/riskManagement/hooks/useClaimModels';

const NewModelManagementPage = ({mode}) => {

	const dispatch = useDispatch()
	const { items, isLoading: isLodingSelect } = useSelector( state => state.riskModelReducer.itemsSelection)
	const { items: itemsConfiguration, isLoading: isLoadingConfig } = useSelector( state => state.riskModelReducer.itemsConfiguration)
	const { item: itemFilterSelect } = useSelector( state => state.riskModelReducer.itemFilter)
	const { isLoading: isLoadingSave } = useSelector( state => state.riskModelReducer.dataMaster)
	const { data, isLoading } = useSelector(state => state.riskModelReducer.model)
  const { id: idCopy, isCopy } = useSelector( state => state.riskModelReducer.dataForCopy )

	const { updateTitle } = useSetTitlePage();
	const history = useHistory()
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const { id: idParams } = useParams()

  const id = isCopy ? idCopy : idParams

	const [error, setError] = useState(false)
	const [isFirstLoading, setIsFirstLoading] = useState(true)


	const { isCreate, isEdit } = useClaimModels();

	useEffect(()=>{
		if(mode==='view'){
			updateTitle({
				title: "Gestión de Riesgos",
				subtitle: "Modelo de Gestión",
				description: "Visualizar",
				previousUrl: "/gestion-riesgos/modelo",
			})
		}else if(id){
			updateTitle({
				title: "Gestión de Riesgos",
				subtitle: "Modelo de Gestión",
				description: "Edición",
				previousUrl: "/gestion-riesgos/modelo",
			})
		}else{
		updateTitle({
			title: "Gestión de Riesgos",
			subtitle: "Modelo de Gestión",
			description: "Nuevo",
			previousUrl: "/gestion-riesgos/modelo",
		})}
	},[])

	useEffect(()=>{
		if(id){
      if( isCopy ) {
        dispatch(RiskModelAction.getCopyById(id))
      } else {
        dispatch(RiskModelAction.getDataById(id))
      }
		}
		return () => {
			dispatch(RiskModelAction.clearModel())
			dispatch(RiskModelAction.clearSelected())
			dispatch(RiskModelAction.removeSelectedFilterItem())
      dispatch(RiskModelAction.setCopyData({id: null, isCopy: false}))
		}
	},[id, dispatch])

	useEffect(()=>{
		dispatch(RiskModelAction.getDataMaster())
	},[])

	const {
		control,
		handleSubmit,
		setValue,
		watch,
		reset,
		formState: { errors },
	} = useForm({});

	useEffect(()=> {
		if(!isLoading){
			reset({
				name: data.name || "",
				description: data.description || "",
			})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[isLoading, dispatch, reset])

	useEffect(()=>{
		if(items.length <= 0 || itemFilterSelect.length !== 0){
			setError(false)
		}
	},[items, itemFilterSelect])

	useEffect(()=>{
		if(isFirstLoading && !isLoadingConfig && !isLodingSelect && id){
			setIsFirstLoading(false)
			dispatch(RiskModelAction.update())
		}
	},[isFirstLoading, isLoadingConfig, isLodingSelect])

	const submit = (data) => {
		const send = {
			...data,
			id: isCopy ? null : (id ? Number(id) : null),
			configuration: items.map( (item, index) => ({
				inventoryId: item.inventoryId,
				modelId: isCopy ? null : (id ? Number(id) : null),
				position: index + 1,
			})),
			filter: itemFilterSelect.map( (item, index) => ({
				inventoryId: item.inventoryId,
				modelId: isCopy ? null : (id ? Number(id) : null),
				position: index + 1,
			}))
		}			

		if(items.length > 0 && itemFilterSelect.length === 0){
			setError(true)
		}else{
			dispatch(RiskModelAction.save(send, history))
		}
	}
	
	return (
		<div>
			
				<TabView className="tabview-custom" renderActiveOnly={false} orientation="bottom">
					<TabPanel header="Formulario">
							<FormComponent watch={watch} control={control} errors={errors} />
					</TabPanel>
					<TabPanel header="Configuración">
						<div className="card tabs-vertical configuration-model">
							<TabView className="tabview-custom" renderActiveOnly={false} orientation="bottom">
								<TabPanel header="Filtro Simple">
									<ConfigurationComponent type="simple"/>
								</TabPanel>
								<TabPanel header="Listado">
								<ConfigurationComponent />
								</TabPanel>
							</TabView>
						</div>
					</TabPanel>
				</TabView>
			
			{
				(Object.keys(errors).length > 0 || error) && 
				<div className='message error'>
					<ul>
							{
								Object.keys(errors).map((item, index)=>{return(
									<li key={index} className="p-error">{errors[item].message}</li>
								)})
							}
							{
								error && 
								<li className="p-error">Debe elegir un filtro simple</li>
							}      
					</ul>
				</div>
			}
		<div className='flex justify-end mt-4'>
			<Button
				loading={isLoadingSave}
				icon='pi pi-times'
				label='Cancelar'
				className='sig-button sig-secondary'
				onClick={() => { setShowConfirmDialog(true); }}
			/>
			{
				(mode!=="view" && (isCreate || isEdit))&&
					<Button
						loading={isLoadingSave}
						onClick={handleSubmit(submit)}
						icon='pi pi-save'
						label='Guardar'
						className='sig-button sig-primary ml-4'
					/>
			}
		</div>
		<ConfirmDialog
				visible={showConfirmDialog}
				onHide={()=>setShowConfirmDialog(false)}
				message="¿Está seguro que desea cancelar?"
				header="Cancelar"
				icon="pi pi-exclamation-triangle"
				accept={()=>history.push('/gestion-riesgos/modelo')}
				reject={()=>setShowConfirmDialog(false)}
			/>
	</div>
	);
};

export default NewModelManagementPage;