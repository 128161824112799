import { useRef, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTable } from "shared/hooks/useTable";
import { CoreConstants } from "core/commons/CoreConstants";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { Skeleton } from "primereact/skeleton";
import { SelectButton } from "primereact/selectbutton";
import { Menu } from "primereact/menu";
import { ConfirmDialog } from "primereact/confirmdialog";

import { CanvaComponent } from "./CanvaComponent";
import { getFilterValuesFromPiners } from "features/business/helpers/businessSheet/BusinessSheetHelper";
import { BusinessSheetAction } from "features/business/store/actions/BusinessSheetAction";
import { ModalSharedSheet } from "./ModalSharedSheet";
import { BusinessSheetConstants } from "features/business/commons/BusinessSheetConstants";

export const TableComponent = () => {
  const {
    pageSize,
    onPageChange,
    first,
    rowCount,
    columnOrder,
    onSort,
    order,
    searchData,
  } = useTable("name", getFilterValuesFromPiners, BusinessSheetAction.search);

  const data = useSelector((state) => state.businessSheetReducer.businessSheet.data);
  const loading = useSelector(
    (state) => state.businessSheetReducer.businessSheet.loading
  );
  const actionsPublished = useRef(null);
  const actionsClosed = useRef(null);
  const actionsDraft = useRef(null);
  const [currentBusinessSheet, setCurrentBusinessSheet] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [showConfirmClose, setShowConfirmClose] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);
  const [showConfirmDialog, setshowConfirmDialog] = useState(false);
  const typeViews = [
    { icon: "pi pi-list", value: CoreConstants.TypeView.LIST },
    { icon: "pi pi-microsoft", value: CoreConstants.TypeView.CANVA },
  ];

  const columns = [
    { field: "code", header: "Código" },
    { field: "name", header: "Nombre" },
    { field: "status", header: "Estado" },
    { field: "type", header: "Tipo" },
    { field: "creationDate", header: "Fecha de Creación" },
  ];

  const getViewField = (rowData) => (
    <Link
      className="underline text-blue-700"
      to={{ pathname: `/negocios/fichas/configuracion/` + rowData.id }}
    >
      {rowData.code}
    </Link>
  );

  const ColumnComponents = columns.map((col) => {
    if (col.field === "code")
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={getViewField}
          sortable
        />
      );

    return <Column key={col.field} field={col.field} header={col.header} sortable />;
  });

  let itemsPublished = [
    {
      label: "Editar",
      icon: "pi pi-pencil",
      command: (e) => {
        history.push("/negocios/fichas/configuracion/" + currentBusinessSheet);
      },
    },
    {
      label: "Eliminar",
      icon: "pi pi-times",
      command: (e) => {
        setshowConfirmDialog(true);
      },
    },
    {
      label: "Ver Respuestas",
      icon: "pi pi-eye",
      command: (e) => {
        history.push("/negocios/fichas/respuestas/" + currentBusinessSheet);
      },
    },
    {
      label: "Compartir",
      icon: "pi pi-share-alt",
      command: (e) => {
        setDisplayModal(true);
        // dispatch(SurveyAction.shareSurvey(surveySelected.id));
        // setDisplayModalAction(true)
      },
    },
    {
      label: "Cerrar Encuesta",
      icon: "pi pi-minus-circle",
      command: (e) => {
        setShowConfirmClose(true);
      },
    },
  ];

  let itemsClosed = [
    {
      label: "Editar",
      icon: "pi pi-pencil",
      command: (e) => {
        history.push("/negocios/fichas/configuracion/" + currentBusinessSheet);
      },
    },
    {
      label: "Eliminar",
      icon: "pi pi-times",
      command: (e) => {
        setshowConfirmDialog(true);
      },
    },
    {
      label: "Ver Respuestas",
      icon: "pi pi-eye",
      command: (e) => {
        history.push("/negocios/fichas/respuestas/" + currentBusinessSheet);
      },
    },
  ];

  let itemsDraft = [
    {
      label: "Editar",
      icon: "pi pi-pencil",
      command: (e) => {
        history.push("/negocios/fichas/configuracion/" + currentBusinessSheet);
      },
    },
    {
      label: "Eliminar",
      icon: "pi pi-times",
      command: (e) => {
        setshowConfirmDialog(true);
      },
    },
    {
      label: "Compartir",
      icon: "pi pi-share-alt",
      command: (e) => {
        setDisplayModal(true);
        // dispatch(SurveyAction.shareSurvey(surveySelected.id));
        // setDisplayModalAction(true)
      },
    },
  ];
  const selectedRow = (event, { id, statusCode }) => {
    setCurrentBusinessSheet(id);
    console.log("aca");
    if (statusCode === BusinessSheetConstants.Status.CLOSED)
      actionsClosed.current.toggle(event);
    if (statusCode === BusinessSheetConstants.Status.DRAFT)
      actionsDraft.current.toggle(event);
    if (statusCode === BusinessSheetConstants.Status.PUBLISHED)
      actionsPublished.current.toggle(event);
  };

  const TableBodyActions = (selectedItem) => {
    return (
      <i
        onClick={(event) => selectedRow(event, selectedItem)}
        className="pi pi-ellipsis-v cursor-pointer"
      ></i>
    );
  };

  const confirmDelete = () => {
    dispatch(BusinessSheetAction.remove(currentBusinessSheet, searchData));
    setshowConfirmDialog(false);
  };

  const confirmClose = () => {
    dispatch(BusinessSheetAction.close(currentBusinessSheet));
    setShowConfirmClose(false);
  };

  if (loading) return <Skeleton height="8rem" />;

  return (
    <>
      <div className="flex justify-end mb-5">
        <SelectButton
          unselectable={false}
          value={typeView}
          options={typeViews}
          onChange={(e) => setTypeView(e.value)}
          itemTemplate={(option) => {
            return <i className={option.icon}></i>;
          }}
        />
      </div>
      {typeView === CoreConstants.TypeView.LIST ? (
        <DataTable
          value={data}
          emptyMessage="No se encontraron Fichas de Negocio"
          stripedRows
          rowHover
          size="small"
          responsiveLayout="scroll"
          onSort={onSort}
          sortField={columnOrder}
          sortOrder={order}
        >
          {ColumnComponents}
          <Column body={TableBodyActions}></Column>
        </DataTable>
      ) : (
        <CanvaComponent data={data} selectedRow={selectedRow} />
      )}

      <Paginator
        first={first}
        rows={pageSize}
        onPageChange={onPageChange}
        totalRecords={rowCount}
        className="paginator-custom mt-4"
        rowsPerPageOptions={[5, 10, 15]}
      />

      <Menu model={itemsPublished} popup ref={actionsPublished} />
      <Menu model={itemsClosed} popup ref={actionsClosed} />
      <Menu model={itemsDraft} popup ref={actionsDraft} />

      <ConfirmDialog
        visible={showConfirmDialog}
        onHide={() => setshowConfirmDialog(false)}
        message="¿Está seguro que desea eliminar el registro?"
        header="Eliminar"
        icon="pi pi-trash"
        accept={confirmDelete}
        reject={() => setshowConfirmDialog(false)}
      />

      <ConfirmDialog
        visible={showConfirmClose}
        onHide={() => setShowConfirmClose(false)}
        message="¿Está seguro que desea cerrar la Ficha de Negocio?"
        header="Cerrar Encuesta"
        icon="pi pi-minus-circle"
        accept={confirmClose}
        reject={() => setShowConfirmClose(false)}
      />

      <ModalSharedSheet
        displayModal={displayModal}
        setDisplayModal={setDisplayModal}
        currentBusinessSheet={currentBusinessSheet}
      />
    </>
  );
};
