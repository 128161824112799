import React from 'react'
import { OrganizationChart } from 'primereact/organizationchart';
import './OrganizationChart.scss'
import { useSelector } from 'react-redux';
import { Skeleton } from 'primereact/skeleton';
import { useClaimOrganizationChart } from 'features/rrhh/hooks/useClaimOrganizationChart';

const OrgChart = ({setNodeSelected, setDisplayModal}) => {
    const {data, isLoading} = useSelector(state => state.organizationChartReducer.nodes);
    const companyAreaParents = useSelector(state => state.organizationChartReducer.companyAreaParents);
    const { isEdit } = useClaimOrganizationChart()

    // for (let i = 0; i < data1.length; i++) {
    //     // console.log(data1[i])
    //     for (let j = 0; j < data1[i].children.length; j++) {
    //         // console.log(data1[i].children)
    //         for (let k = 0; k < data1[i].children[j].children.length; k++) {
    //             // console.log(data1[i].children[j].children)
    //             for (let x = 0; x < data1[i].children[j].children[k].children.length; x++) {
    //                 console.log(data1[i].children[j].children[k].children)
                    
    //             }
    //         }
    //     }
    // }
  
    
    const nodeTemplate = (node) => {
        const names = node.data.name ? node.data.name.split(',') : []

        if (node.type === "person") {
            return (
                <div>
                    <div className="node-header">{node.label}</div>
                    <div className="node-content">
                        {/* <img alt={node.data ? data : [].avatar} src={`images/organization/${node.data.avatar}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} style={{ width: '32px' }} /> */}
                        <i className="pi pi-user text-xl"></i>
                        {
                            names.map((name, index) => {
                                if (index <= names.length) {
                                    return <div key={index}>{name}</div>
                                }
                            })
                        }
                    </div>
                </div>
            );
        }

        return node.label;
    }
 
    const onNodeSelect = (node) => {
        if(isEdit){
            setNodeSelected(node)
            setDisplayModal(true)
        }
    }

    return (
        <div id='muestra-print'>
            <div className="organizationchart-demo">
                <div className="card flex justify-center">
                    {
                        
                            isLoading ? <Skeleton className='mt-3' width="20%" height="8rem" /> : (
                                companyAreaParents.data.length > 0 &&
                                (
                                    <OrganizationChart 
                                    value={data} 
                                    nodeTemplate={nodeTemplate} 
                                    selectionMode="single"
                                    // selection={selection}  onSelectionChange={event => setSelection(event.data)} 
                                    className="company className='mt-3'"
                                    onNodeSelect={event => onNodeSelect(event.node)}
                                    ></OrganizationChart> 
                                )
                            ) 
                        
                    }
                    
                </div>
            </div>
        </div>
    )
}

export default OrgChart