import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';

import { FilterComponent } from 'features/customers/components/companies/FilterComponent';
import { useCompanies } from 'features/customers/hooks/companies/useCompanies';
import { TableComponent } from 'features/customers/components/companies/TableComponent';
import { AdvancedFilterComponent } from 'features/customers/components/companies/AdvancedFilterComponent';
import { CompaniesAction } from 'features/customers/store/actions/CompaniesAction';
import scrollTop from 'shared/utils/scrollTop';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useTableCompanies } from 'features/customers/hooks/companies/useTableCompanies';
import { Menu } from 'primereact/menu';
import CompanyModalImport from 'features/customers/components/companies/modals/CompanyModalImport';
import { useClaimCompanies } from 'features/customers/hooks/useClaimCompanies';

export const PageCompanies = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [displayModal, setDisplayModal] = useState(false)
  const optionsButton = useRef(null);
  const { isSearch, isDownload, isCreate } = useClaimCompanies();
  const items = [
    {
        label: 'Descargar Plantilla',
        command: (e) => {
          dispatch(CompaniesAction.downloadTemplate())
        }
    },
    {
        label: 'Cargar Plantilla',
        command: (e) => {
          setDisplayModal(true)
        }
    },
  ];


  useCompanies();
  const { confirmDelete, cancelDelete, isOpenModalDelete } =
    useTableCompanies();

  useEffect(() => {
    dispatch(CompaniesAction.getCompanies({ change: false }));
    dispatch(CompaniesAction.getDataMaster());
    dispatch(CompaniesAction.setCurrentCompany({ errors: [] }));

    return () => {
      dispatch(CompaniesAction.setAdvancedFilter({values: []}))
    }

  }, []);

  useEffect(() => {
    scrollTop();
  }, []);
  return (
    <Fragment>
      {
          (isSearch||isDownload)&&(
              <FilterComponent />
          )
      }
      {
          isCreate&&(<>
            <Button
            icon="pi pi-plus"
            type="button"
            label="Nuevo"
            className="sig-button sig-dark mt-4"
            onClick={() => {
              history.push('/cliente/empresas/nuevo');
            }}
          />
          <Button
            label='Importar' className="sig-button sig-primary ml-3"
            onClick={(event) => optionsButton.current.toggle(event)}
          />
          </>)
      }
      <Menu model={items} popup ref={optionsButton} />
      <CompanyModalImport
        displayModal={displayModal} setDisplayModal={setDisplayModal} title={'Importar Empresa'}
      />
      <TableComponent />
      <AdvancedFilterComponent />
      <ConfirmDialog
        visible={isOpenModalDelete}
        onHide={cancelDelete}
        message="¿Está seguro que desea eliminar la empresa?"
        header="Eliminar"
        icon="pi pi-trash"
        accept={confirmDelete}
        reject={cancelDelete}
      />
    </Fragment>
  );
};
