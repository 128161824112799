/* eslint-disable import/no-anonymous-default-export */
import { MaintenanceConstants } from "features/maintenance/commons/MaintenanceConstans";

const initialState = {
  dataMaster: {
    isLoading: false,
    countries: []
  },
  institution: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false
  },
  institutions: {
    isLoading: false,
    data: null
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'name',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {}
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MaintenanceConstants.Accion.Institution.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
      case MaintenanceConstants.Accion.Institution.UPDATE_FILTER:
        return {
            ...state,
            advancedFilter: state.advancedFilter
        };

    case MaintenanceConstants.Accion.Institution.SEARCH_INSTITUTIONS:
        return {
            ...state,
            institutions: {
                isLoading: action.payload.isLoading,
                data: action.payload.data
            }
        };
    case MaintenanceConstants.Accion.Institution.GET_INSTITUTION_BY_ID:
      return {
          ...state,
          institution: {
              data: action.payload.data,
              isSaving: false,
              isLoading: action.payload.isLoading
          }
        };

    case MaintenanceConstants.Accion.Institution.SAVE_INSTITUTION:
        return {
            ...state,
            institution: {
                data: action.payload.currentInstitution,
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };

    case MaintenanceConstants.Accion.Institution.DELETE_INSTITUTION:
        return {
            ...state,
            institution: {
                isDeleted: action.payload.isDeleted
            }
        };

    case MaintenanceConstants.Accion.Institution.RESET_AFTER_SAVED:
        return {
            ...state,
            institution: {
                data: null,
                isSaving: false,
                isSaved: false,
                isLoading: false,
                isDeleted: false
            }
        };

      case MaintenanceConstants.Accion.Institution.GET_DATA_MASTER:
          return {
              ...state,
              dataMaster: {
                  countries: action.payload.data,
                  isLoading: action.payload.isLoading
              }
          };

      case MaintenanceConstants.Accion.Institution.DOWNLOAD_REPORT_STATUS:
          return {
              ...state,
              institution: {
                  ...state,
                  isLoading: action.payload.isLoading,
              },
          };
      
      default: 
      return state;
  }

}