import { TabCustomerMenu } from "./TabCustomerMenu";

export const ContainerComponent = ({ children }) => {
  return (
    <>
      <TabCustomerMenu/>
      <div>
        {children}
      </div>
    </>
  );
};