import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

export const FilterSearchComponent = ({
  onChangeValues,
  name,
  value,
  onSubmit,
  loading,
  isLoadingDownload,
  onDownload,
  changeShowModal,
  label,
}) => {
  return (
    <form className="filter-roles">
      <div className={"w-full visible"}>
        <span className="p-float-label p-input-icon-left w-full">
          <i className="pi pi-search" />
          <InputText
            id="input-search"
            name={name}
            value={value || ""}
            onChange={(e) => {
              onChangeValues({
                name: e.target.name,
                value: {
                  value: e.target.value,
                  searchValue: e.target.value,
                  pinerValue: e.target.value,
                },
              });
            }}
          />
          <label htmlFor="input-search">{label}</label>
        </span>
      </div>
      <div className="filter-users-action">
        <div className="flex gap-3 w-full">
          <Button
            icon="pi pi-search"
            type="submit"
            label="Buscar"
            className="sig-button sig-primary w-full"
            onClick={onSubmit}
            loading={loading || isLoadingDownload}
          />
          <Button
            icon="pi pi-download"
            type="button"
            label="Descargar"
            className="sig-button sig-success w-full"
            onClick={onDownload}
            loading={loading || isLoadingDownload}
          />
        </div>
        <div className="flex mt-3">
          <Button
            type="button"
            label="Búsqueda avanzada"
            className="sig-button  sig-secondary w-full"
            onClick={() => changeShowModal(true)}
            loading={loading || isLoadingDownload}
          />
        </div>
      </div>
    </form>
  );
};
