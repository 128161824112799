import { apiService } from "core/services/ApiService";

const getDataMaster = () => {
  return apiService.Get("management/api/ProcessMap/GetDataMaster")
}

const getProcessMap = () => {
  return apiService.Get("management/api/ProcessMap/GetProcessMap");
}

const removeProcess = (id) => {
  return apiService.Delete("management/api/Process/RemoveProcess?processId="+id)
}

const saveProcess = (data) => {
  return apiService.Post("management/api/Process/SaveProcess", data)
}

const updateProcessTypeName = (data) => {
  return apiService.Post("management/api/ProcessMap/UpdateProcessTypeName", data)
}

const saveAndGenerateVersion = (data) => {
  return apiService.Post("management/api/ProcessMap/SaveAndGenerateVersion", data)
}

const saveProcessAndGenerateVersion = (data) => {
  return apiService.Post("management/api/Process/SaveProcessAndGenerateVersion", data)
}

const moveProcessPosition = (data) => {
  return apiService.Post("management/api/ProcessMap/MoveProcessPosition", data)
}

const moveProcess = (data) => {
  return apiService.Post("management/api/ProcessMap/MoveProcess", data)
}

const getLogo = () =>{
  return apiService.Get("management/api/ApplicationSetting/GetApplicationSettingsLogos")
}

const getLogo64 = () => {
  return apiService.Get("management/api/ApplicationSetting/GetApplicationSettingsLogoBase64")
}

const getProcessById = (id) => {
  return apiService.Get(`management/api/Process/GetProcessById?id=${id}`);
}

const updateCreationInfo = (data) => {
  return apiService.Post('management/api/ProcessMap/UpdateCreationInfo', data)
}

const getActiveSheet = () => {
  return apiService.Get('management/api/Process/GetActiveSheet')
}

const listControls = () => {
  return apiService.Get('management/api/ConfigDynamicProcessSheet/ListControls')
}

const getSheetById = () => {
  return apiService.Get('management/api/ConfigDynamicProcessSheet/GetSheetById?id=134')
}

const getVersionByVersionNumber = (id, version) => {
  return apiService.Get(`management/api/ProcessMap/GetVersionByVersionNumber?id=${id}&versionNumber=${version}`)
}


const getProcessVersionByVersionNumber = (id, version) => {
  return apiService.Get(`management/api/Process/GetVersionByVersionNumber?id=${id}&versionNumber=${version}`)
}

const saveProcessAndSubmitWorkflow = (data) => {
  return apiService.Post('management/api/Process/SaveProcessAndSubmitWorkflow',data)
}

const getVersions = (id) => {
  return apiService.Get(`management/api/Process/GetVersions?processId=${id}`);
}

const approveDocument = (data) => {
  return apiService.Post(`management/api/Workflow/ApproveDocument`, data)
}

const rejectDocument = (data) => {
  return apiService.Post(`management/api/Workflow/RejectDocument`, data)
}

const getVersionsDetailedList = (id, version) => {
  return apiService.Get(`management/api/Process/GetVersionsDetailedList?processId=${id}&versionNumber=${version}`)
}

export const ProcessManagementServices = {
  getDataMaster,
  getProcessMap,
  removeProcess,
  saveProcess,
  updateProcessTypeName,
  saveAndGenerateVersion,
  saveProcessAndGenerateVersion,
  moveProcessPosition,
  moveProcess,
  getLogo,
  getLogo64,
  getProcessById,
  updateCreationInfo,
  getActiveSheet,
  listControls,
  getSheetById,
  getVersionByVersionNumber,
  getProcessVersionByVersionNumber,
  saveProcessAndSubmitWorkflow,
  getVersions,
  approveDocument,
  rejectDocument,
  getVersionsDetailedList,
};