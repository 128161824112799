import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import AdvancedFilterComponent from 'features/configuration/components/approval-flow/AdvancedFilterComponent';
import FilterComponent from 'features/configuration/components/approval-flow/FilterComponent';
import { ApprovalFlowTable } from 'features/configuration/components/approval-flow/ApprovalFlowTable';
import Modal from 'features/configuration/components/processes/Formulario/Modal';
import { ApprovalFlowActions } from 'features/configuration/store/actions/ApprovalFlowAction';
import { useClaimApprovalFlow } from 'features/configuration/hooks/useClaimApprovalFlow';



const SearchApprovalFlow = () => {
    const history = useHistory();
    const [displayModal, setDisplayModal] = useState(false)
    const [displayActionPlanModal, setDisplayActionPlanModal] = useState(false)
    const [indicatorSelected, setIndicatorSelected] = useState(null)

    const { isSearch, isDownload, isCreate } = useClaimApprovalFlow();

    const { updateTitle } = useSetTitlePage();
    // const [isFirstLoading, setFirstLoading] = useState(true);
    const dispatch = useDispatch();
    //comentario test
    useEffect(() => {
        updateTitle({
            title: 'Configuración',
            subtitle: 'Flujo de Aprobación',
        });
        dispatch(ApprovalFlowActions.search())
        dispatch(ApprovalFlowActions.getUsers()) // dataMaster
        dispatch(ApprovalFlowActions.resetAfterSaved())
        // dispatch(ProcessesAction.restoreSaved())
        // dispatch(ProcessesAction.searchListControls())
        // dispatch(ProcessesAction.searchResponsibles())
    }, []);

    return (
        <Fragment>
            {
                (isSearch||isDownload)&&(
                    <FilterComponent />
                )
            }
            {
                isCreate&&(
                    <Button
                        icon="pi pi-plus"
                        type="button"
                        label="Nuevo"
                        className="sig-button sig-dark mt-4"
                        onClick={() => {
                            history.push('/configuracion/flujo-aprobacion/nuevo');
                        }}
                    />
                )
            }
            <ApprovalFlowTable />
            <AdvancedFilterComponent />
        </Fragment>
    );
};

export default SearchApprovalFlow