import { ApplicationSettingsService } from 'features/configuration/services/ApplicationSettingsService';
import { ConfigurationConstants } from 'features/configuration/commons/ConfigurationConstants';
import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from 'core/store/actions/ToastAction';

const setTypeMoney = (payload) => {
  return (dispatch) => {
    const type =
      ConfigurationConstants.Accion.ApplicationSettings.GET_TYPE_MONEY;
    dispatch({ type, payload });
  };
};

const getTypeMoney = () => {
  return (dispatch) => {
    ApplicationSettingsService.getCurrencyType()
      .then(({ data }) => {

        if (data.Status === CoreConstants.HttpResponse.OK) {
          const currentTypeMoney = data.Data;
          dispatch(setTypeMoney({ data: currentTypeMoney }));
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setTypeMoney({ isLoading: false }));
      });
  };
};

const setLogos = (payload) => {
  return (dispatch) => {
    const type = ConfigurationConstants.Accion.ApplicationSettings.GET_LOGOS;
    dispatch({ type, payload });
  };
};

const getLogos = () => {
  return (dispatch) => {
    ApplicationSettingsService.getLogos()
      .then(({ data }) => {
        if (data.Status === CoreConstants.HttpResponse.OK) {
          const currentLogos = data.Data.ApplicationSettingDetails;
          dispatch(setLogos({ data: currentLogos }));
          document.documentElement.style.setProperty(
            '--sig-logo-default',
            'url("' + currentLogos[0].Value + '")'
          );
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setLogos({ isLoading: false }));
      });
  };
};

const setColors = (payload) => {
  return (dispatch) => {
    const type = ConfigurationConstants.Accion.ApplicationSettings.GET_COLORS;
    dispatch({ type, payload });
  };
};

const getColors = () => {
  return (dispatch) => {
    ApplicationSettingsService.getColors()
      .then(({ data }) => {
        if (data.Status === CoreConstants.HttpResponse.OK) {
          let currentColors = data.Data.ApplicationSettingDetails;
          if (currentColors.length > 0) {
            currentColors = currentColors.map((c) => ({
              code: c.Code,
              applicationSettingId: c.ApplicationSettingId,
              id: c.Id,
              value: c.Value,
            }));
          }
          dispatch(setColors({ data: currentColors }));
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setColors({ isLoading: false }));
      });
  };
};

const setUnitsOfMeasure = (payload) => {
  return (dispatch) => {
    const type =
      ConfigurationConstants.Accion.ApplicationSettings.GET_UNITS_MEASURE;
    dispatch({ type, payload });
  };
};
const getUnitsOfMeasure = () => {
  return (dispatch) => {
    ApplicationSettingsService.getUnitsOfMeasure()
      .then(({ data }) => {
        if (data.Status === CoreConstants.HttpResponse.OK) {
          let currentUnits = data.Data.ApplicationSettingDetails.reverse();
          if (currentUnits.length > 0) {
            currentUnits = currentUnits.map((unit) => ({
              ...unit,
              action: 'save',
            }));
          }
          dispatch(setUnitsOfMeasure({ data: currentUnits, id: data.Data.Id }));
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setUnitsOfMeasure({ isLoading: false }));
      });
  };
};

const currentChangeSetting = (name, isLoadingSave) => {
  return (dispatch) => {
    const loading = { isLoadingSave };
    if (name === 'type-money') {
      dispatch(setTypeMoney(loading));
    } else if (
      name === 'setting-units-save' ||
      name === 'setting-units-delete'
    ) {
      dispatch(setUnitsOfMeasure(loading));
    } else if (name === 'settings-colors') {
      dispatch(setColors(loading));
    } else if (name === 'setting-logos') {
      dispatch(setLogos(loading));
    } else if (name === 'setting-logos') {
      dispatch(setLogos(loading));
    }
  };
};

const showToast = (name, status) => {
  return (dispatch) => {
    const module = 'Configuración de Sistema';
    const messageError = '¡Ocurrió un problema, inténtelo nuevamente!';
    let messageSuccess = '';
    if (name === 'type-money') {
      messageSuccess = '¡Tipo de Moneda actualizado!';
      dispatch(getTypeMoney());
    } else if (name === 'setting-units-save') {
      messageSuccess = '¡Unidad de medida actualizada!';
      dispatch(getUnitsOfMeasure());
    } else if (name === 'setting-units-delete') {
      messageSuccess = '¡Unidad de medida eliminada!';
      dispatch(getUnitsOfMeasure());
    } else if (name === 'settings-colors') {
      messageSuccess = '¡Colores coorporativos actualizados!';
      dispatch(getColors());
    } else if (name === 'setting-logos') {
      messageSuccess = '¡Logo actualizado!';
      dispatch(getLogos());
    }
    dispatch(
      toastAction[status](
        module,
        status === 'success' ? messageSuccess : messageError
      )
    );
  };
};

const saveSettings = ({ data, settingName }) => {
  return (dispatch) => {
    dispatch(currentChangeSetting(settingName, true));
    ApplicationSettingsService.save(data)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(showToast(settingName, 'success'));
        } else {
          dispatch(showToast(settingName, 'error'));
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        dispatch(currentChangeSetting(settingName, false));
      });
  };
};

const saveSettingLogos = ({ data, settingName }) => {
  return (dispatch) => {
    dispatch(currentChangeSetting(settingName, true));
    ApplicationSettingsService.updateLogo(data)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(showToast(settingName, 'success'));
        } else {
          dispatch(showToast(settingName, 'error'));
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(currentChangeSetting(settingName, false));
      });
  };
};

export const AppSettingsAction = {
  getTypeMoney,
  getLogos,
  getColors,
  getUnitsOfMeasure,
  saveSettings,
  saveSettingLogos,
};
