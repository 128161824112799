/* eslint-disable default-case */
import { DinamicProcessAction } from "features/configuration/store/actions/DinamicProcessAction";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PinerComponent from "shared/components/Piner/PinerComponent";

const FilterComponent = () => {
    const dispatch = useDispatch();
    const params = useParams()
    const criteriaOccupation = useRef(null);
    const advancedFilter = useSelector((state) => state.dinamicProcessReducer.advancedFilter);
    const values = useSelector((state) => state.dinamicProcessReducer.advancedFilter.values);
    const { isLoading, data } = useSelector((state) => state.dinamicProcessReducer.process)
    const processSections = useSelector(state => state.dinamicProcessReducer.process.data?.sections)
    const listControls = useSelector(state => state.dinamicProcessReducer.listControls.data)
    const [formControls, setFormControls] = useState([])
    const [filterName, setFilterName] = useState(null)
    const openModal = () => {
        dispatch(DinamicProcessAction.toggleAdvancedFilter({ showModal: true}));
    };

    const processSelected = useSelector(state => state.dinamicProcessReducer.process.data?.configSearch)
    
    const showPiners = () => {
        if (values?.length > 0)
            return true;
        return false;
    }

    useEffect(() => {
        if(processSelected && listControls){
          let controls = []
          processSections.map(section=> {
            section.controls.map(control => {
              const typeControl = listControls.find(el => el.id === control.controlId).code
              control.type = typeControl
              
            //   if (typeControl !== 'ID' && typeControl !== 'ETIQUETA') controls.push(control)
              if (typeControl !== 'ETIQUETA') controls.push(control)
              return control
            })
            return section
          })
          
          setFormControls(controls)
        }
      }, [processSelected, listControls, processSections]);

    useEffect(() => {
        if (formControls && processSelected) {
            const filterName = formControls.find(control => control.identifier === processSelected.simpleFilters[0])
            setFilterName(filterName)
        }
    }, [formControls, processSelected])

    const removePiner = (field) => {
        processSelected?.advacedFilters.map(el => {
            if(el===field){
                advancedFilter.filter[field] = null
            }
            return el
        })
        processSelected?.simpleFilters.map(el => {
            if(el===field){
                advancedFilter.filter[field] = null
            }
            return el
        })
        dispatch(DinamicProcessAction.updateFilter(advancedFilter));
        dispatch(DinamicProcessAction.searchDynamicProcess(params.id));
    };

    const search = () => {
        const value = criteriaOccupation.current.value;
        if(value !== null && value !== undefined && value.trim().length > 0) {
            advancedFilter.filter[processSelected?.simpleFilters[0]] = value;
            criteriaOccupation.current.value = null
            dispatch(DinamicProcessAction.updateFilter(advancedFilter));
        }
        dispatch(DinamicProcessAction.searchDynamicProcess(params.id));
    }

    const handleKeyDown = (event) => {
        if(event.key === 'Enter') {
            search();
        }
    }

    const generateReport = () => {
        const value = criteriaOccupation.current.value;
        if(value !== null && value !== undefined && value.trim().length > 0) {
            advancedFilter.filter[processSelected?.simpleFilters[0]] = value;
            dispatch(DinamicProcessAction.updateFilter(advancedFilter));
        }
        dispatch(DinamicProcessAction.generateReport());
    }

  return (
    <Panel header="FILTRO" toggleable>
      <div className="filter-components">
            <div className="w-full ">
                <span className="p-float-label p-input-icon-left w-full ">
                    <i className="pi pi-search" />
                    <InputText
                        id="input-search"
                        ref={criteriaOccupation}
                         onKeyDown={handleKeyDown}
                    />
                    <label htmlFor="input-search">{filterName ? `Buscar por ${(filterName?.text).toLowerCase()}` : 'Buscar'} </label>
                </span>
                {showPiners() && (
                    <div className="filter-piners mt-3">
                        {values.map(({ valueText, field }, index) => (
                            <PinerComponent
                                name={valueText}
                                field={field}
                                removePiner={removePiner}
                                key={index}
                            />
                        ))}
                    </div>
                )}
            </div>
            <div className="filter-users-action">
                <div className="flex gap-3 w-full">
                    
                        <Button
                            icon="pi pi-search"
                            type="button"
                            label="Buscar"
                            onClick={search}
                            loading={isLoading}
                            className="sig-button sig-primary w-full"
                        />
                    

                        <Button
                            icon="pi pi-download"
                            type="button"
                            loading={isLoading}
                            onClick={generateReport}
                            label="Descargar"
                            className="sig-button sig-success  w-full"
                        />
                    
                </div>
                <div className="flex mt-3">
                    <Button
                        type="button"
                        label="Búsqueda avanzada"
                        className="sig-button  sig-secondary w-full"
                        onClick={openModal}
                    />
                </div>
            </div>
        </div>
    </Panel>
  )
}

export default FilterComponent;