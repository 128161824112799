/* eslint-disable react-hooks/exhaustive-deps */
import { FormDynamicField } from "features/configuration/components/dynamicFields/FormDynamicField";
import { DynamicFieldsTableData } from "features/configuration/data/dynamicFields/DynamicFieldsTableData";
import { DynamicFieldsAction } from "features/configuration/store/actions/DynamicFieldsAction";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown"
import { InputSwitch } from "primereact/inputswitch";
import { Skeleton } from "primereact/skeleton";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Message from "shared/components/messages/Message";
import useSetTitlePage from "shared/hooks/useSetTitlePage";

export const DynamicFieldsPage = ({mode = "edit"}) => {
  const { updateTitle } = useSetTitlePage();
  const params = useParams();
  const isModeEdit =  mode === "edit"
  const id = params.id;
  const isNew = id === undefined

  const history = useHistory()
  const dataMaster = useSelector(
    (state) => state.dynamicFieldsReducer.dataMaster.data
  );
  const isSystem = dataMaster.subscription?.isSystem
  const isLoadingDataMaster = useSelector(
    (state) => state.dynamicFieldsReducer.dataMaster.isLoadingDataMaster
  );
  const dataDynamicField = useSelector(
    (state) => state.dynamicFieldsReducer.editDynamicFields.data
  );
  const isLoadingDynamicFields = useSelector(
    (state) => state.dynamicFieldsReducer.editDynamicFields.isLoadingDynamicFields
  );

  const isSaving = useSelector(
    (state) => state.dynamicFieldsReducer.editDynamicFields.isSaving
  );
  
  const dataSection = useSelector(
    (state) => state.dynamicFieldsReducer.section.data
  );
  const isLoadingSection = useSelector(
    (state) => state.dynamicFieldsReducer.section.isLoadingSection
  );

  const isDataFetched = !isLoadingDynamicFields && !isLoadingDataMaster
  const { control, handleSubmit, setValue, formState: { errors } } = useForm()
  const [ isOpen, setIsOpen] = useState(false)
  const [ dataTable, setDataTable ] = useState([])
  const dataFields = dataTable.map( data => ({
    ...data,
    typeId:  dataMaster.dynammicFieldType.find(({id}) => id === data.typeId).description,
    sectionId:  dataSection.find(({id}) => id === data.sectionId)?.name,
    statusId:  dataMaster.status.find(({id}) => id === data.statusId).description,
    isRequired: data.isRequired ? "Si" : "No",
  }))

  const [ selectId, setSelectId ] = useState(null)

  const [ showModalDelete, setShowModalDelete] = useState(false)
  const [ idDelete, setIdDelete ] = useState(null)
  
  const deleteClose = () => {
    setIdDelete(null)
    setShowModalDelete(false)
  }
  const deleteOpen = (idItem) => () => {
    setIdDelete(idItem)
    setShowModalDelete(true)
  }
  const deleteRow = () => {
    setDataTable( prev => prev.filter(({id}) => id !== idDelete))
    deleteClose()
  }

  const [alertMessage, setShowAlerMessage ] = useState(false)
  const closeAlertMessage = () => setShowAlerMessage(false)
  const openAlertMessage = () => setShowAlerMessage(true)

  const [ showModalCancel, setShowModalCancel] = useState(false)
  const cancelAccept = () => history.push("/configuracion/campos-dinamicos")
  const cancelDenied = () => setShowModalCancel(false)

  const [ visible, setVisible ] = useState(false)

  const errorsArray = [];
  const onClose = () => setIsOpen(false)

  const columns = DynamicFieldsTableData.columnsFields

  const ColumnComponents = columns.map((col) => {
    return (
      <Column key={col.field} field={col.field} header={col.header} sortable />
    );
  });

  const submit = (data) => {
    const entityBusinessConfigurationItemList = dataTable.map( dataItem => ({
      id: dataItem.id,
      configurationId: id ? Number(id) : null,
      fieldTypeId: dataItem.typeId,
      sectionId: dataItem.sectionId,
      statusId: dataItem.statusId,
      label: dataItem.label,
      isRequired: dataItem.isRequired,
      maxLength: dataItem.maxLength,
    }))

    const dataSubmit = {
      entityBusinessId: data.entityBusinessId,
      companyId: data.companyId,
      statusId: data.statusId ? dataMaster.status.find( ({code}) => code === "ENA").id : dataMaster.status.find( ({code}) => code === "DIS").id,
      entityBusinessConfigurationItemList: entityBusinessConfigurationItemList
    }
 
    if(!isNew){
      dataSubmit.id = Number(id)
    }
    dispatch(DynamicFieldsAction.save({dynamicFields: dataSubmit, isNewDynamicFields: isNew, cb: cancelAccept}))
  }

  const TableBodyActions = (selectedItem) => {
    
    return (
      <div style={{width: 50}}>
        <i
          onClick={(event) => {
            setSelectId(selectedItem.id)
            setIsOpen(true)
          }}
          className="pi pi-pencil cursor-pointer"
        ></i>

        <i
          className="pi pi-trash cursor-pointer ml-4"
          onClick={deleteOpen(selectedItem.id)}
        ></i>
      </div>
    );
  };

  const showActions = true

  const dispatch = useDispatch();

  useEffect(() => {
    updateTitle({
      title: 'Configuración',
      subtitle: 'Sistema',
      description: 'Campos Dinámicos',
      previousUrl: '/configuracion/campos-dinamicos'
    });

    dispatch(DynamicFieldsAction.getDataMaster());

    if (!isNew) {
      dispatch(DynamicFieldsAction.editDynamicFields(Number(id)));
    } else {
      dispatch(DynamicFieldsAction.setCurrentDynamicFields({ isLoadingDynamicFields: false}));
    }

    return () => {
      dispatch(DynamicFieldsAction.setCurrentDynamicFields({ isLoadingDynamicFields: true, data: {}}));
      dispatch(DynamicFieldsAction.setGetDataMaster({ isLoadingDataMaster: true }));
    }
  }, []);

  useEffect(() => {
    if(isDataFetched){
      if(!isNew){
        for( const [key, value] of Object.entries(dataDynamicField)){
          if(key === "items"){
            value.forEach( itemList => {
              setDataTable( prev => {
                const newItem =  {
                  id: itemList.id,
                  typeId: itemList.fieldTypeId,
                  label: itemList.label,
                  sectionId: itemList.sectionId,
                  statusId: itemList.statusId,
                  isRequired: itemList.isRequired,
                  maxLength: itemList.maxLength,
                  identifier: itemList.identifier,
                }

                return [...prev, newItem]
              })
            })
          } else if( key === "statusId"){
            const valueBoolean = dataMaster.status.find( ({id}) => id === value).code === "ENA"
            setValue(key, valueBoolean)
          }else if(key === "entityBusinessId"){
            setValue(key, value)
            dispatch(DynamicFieldsAction.getSection({idEntity: value}))
          }else{
            setValue(key, value)
          }
        }
      }else{
        if(!isSystem) setValue("companyId", dataMaster.companies[0].id)
      }
    }
  },[isDataFetched])

  return(
    <div className="bg-white">
      <form onSubmit={handleSubmit(submit)}>
        <div className="flex pt-7">
          <div className="w-full md:w-1/2">
            {
              isDataFetched ?
              <Controller
                name="companyId"
                control={control}
                rules={{required: "El Cliente es requerido"}}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <span className="p-float-label">
                    <Dropdown
                      disabled={!isSystem}
                      options={dataMaster.companies}
                      optionLabel="businessName"
                      filter
                      filterBy="businessName"
                      showClear
                      className={`w-full${error ? ' p-invalid':''}`}
                      name="companyId"
                      optionValue="id"
                      value={value}
                      onChange={(e) => {
                        if(isModeEdit){
                          onChange(e)
                        }
                      }}
                    />
                    <label htmlFor="companyId">Seleccione cliente *</label>
                  </span>
                )}
              />
              :
              <Skeleton width="100%" height="2.5rem" className="w-full" />
            }
          </div>
        </div>

        <div className="flex pt-7">
          <div className="w-full md:w-1/2">
            {
              isDataFetched ? 
              <Controller
                name="entityBusinessId"
                control={control}
                rules={{required: "La Entidad es requerida"}}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <span className="p-float-label">
                    <Dropdown
                      options={dataMaster.entities}
                      optionLabel="name"
                      filter
                      filterBy="name"
                      showClear
                      optionValue="id"
                      className={`w-full${error ? ' p-invalid':''}`}
                      name="entityBusinessId"
                      value={value}
                      onChange={(e) => {
                        if(isModeEdit){
                          if( dataTable.length === 0 ){
                            dispatch(DynamicFieldsAction.getSection({idEntity: e.target.value}))
                            onChange(e)
                          }else{
                            openAlertMessage()
                          }
                        }
                      }}
                    />
        
                    <label htmlFor="entityBusinessId">Seleccione entidad *</label>
                  </span>
                )}
              />
              :
              <Skeleton width="100%" height="2.5rem" className="w-full" />
            }
          </div>
        </div>

        <div className="pt-7 flex">
          {
            isDataFetched ?   
            <Controller
              name="statusId"
              control={control}
              render={({field: {value, onChange}}) => (
                <>
                  <InputSwitch
                    checked={value}
                    onChange={(e) => {
                      if(isModeEdit){
                        onChange(e)
                      }
                    }}
                  />
                  <h5 className="text-sm ml-2">Habilitar campos dinámicos</h5>
                </>
              )}
            />
            :
            <Skeleton width="10rem" height="2.5rem" className="w-full" />
          }
        </div>

        {
          isModeEdit &&
          <Button
            icon="pi pi-plus"
            type="button"
            label="Nuevo"
            className="sig-button sig-dark mt-4"
            loading={isLoadingSection || !isDataFetched || isSaving}
            onClick={() => {
              const id = Math.min( ...dataTable.map( ({id}) => id), 0 ) - 1
              setSelectId(id)
              setIsOpen(true)
            }}
          />
        }
        

        <div className="mt-4">
          {
            isDataFetched ? 
            <DataTable
              value={dataFields}
              emptyMessage=""
              stripedRows
              rows={10}
              rowHover
              size="small"
              responsiveLayout="scroll"
            >
              {ColumnComponents}
              {(showActions && isModeEdit) && (
                <Column
                  header=""
                  body={TableBodyActions}
                ></Column>
              )}
            </DataTable>
            :
            <Skeleton width="100%" height="8rem" className="w-full" />
          }
        </div>

        {Object.entries(errors).forEach((key, value) => {
          errorsArray.push(key[1].message);
        })}
        {errorsArray.length > 0 && (
          <Message messages={errorsArray} status="error" />
        )}

        <div className="flex justify-end gap-4 mt-3">
          <Button
            icon="pi pi-times"
            type="button"
            label="Cancelar"
            loading={!isDataFetched || isSaving}
            // disabled={isLoadingCompany || isLoadingDataMaster}
            onClick={() => setShowModalCancel(true)}
            className="sig-button sig-secondary mt-4"
          />
          {
            isModeEdit &&
            <Button
              icon="pi pi-save"
              type="submit"
              label="Guardar"
              loading={!isDataFetched || isSaving}
              // loading={isLoadingCompany}
              className="sig-button sig-primary mt-4"
            />
          }
        </div>
      </form>

      <ConfirmDialog
        visible={showModalCancel}
        onHide={() => setShowModalCancel(false)}
        message="¿Está seguro que desea cancelar?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={cancelAccept}
        reject={cancelDenied}
      />

      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="¿Está seguro que desea eliminar el contacto?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        // accept={() => deleteContact()}
        reject={() => setVisible(false)}
      />

      <ConfirmDialog
        visible={showModalDelete}
        onHide={deleteClose}
        message="¿Está seguro que desea eliminar el registro?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={deleteRow}
        reject={() => setVisible(false)}
      />

      <ConfirmDialog
        acceptLabel="Aceptar"
        rejectClassName="hidden"
        visible={alertMessage}
        onHide={closeAlertMessage}
        message="Debe eliminar los detalles para cambiar de Entidad"
        header="Advertencia"
        icon="pi pi-exclamation-triangle"
        accept={closeAlertMessage}
      /> 

      <Dialog visible={isOpen} onHide={onClose} style={{ width: '72vw' }}>
        <FormDynamicField selectId={selectId} setDataTable={setDataTable} setIsOpen={setIsOpen} dataTable={dataTable}/>
      </Dialog>

    </div>
    
  )
}
