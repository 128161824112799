import { apiService } from "core/services/ApiService";

const searchProjectRole = (criteria) => {
  return apiService.Post("customer/api/RoleProject/Search", criteria);
};

const saveProjectRole = (data) => {
  // console.log(data);
  return apiService.Post("customer/api/RoleProject/Save", data);
};

const getProjectRoleById = (projectRoleId) => {
  return apiService.Get(
    "customer/api/RoleProject/GetRoleProjectById?id=" + projectRoleId
  );
};

const generateReport = (payload) => {
  return apiService.Post("customer/api/RoleProject/GenerateReport", payload, {
    responseType: "blob",
  });
};

const deleteProjectRole = (projectRoleId) => {
  return apiService.Delete(
    "customer/api/RoleProject/Delete?id=" + projectRoleId
  );
};

export const ProjectRoleService = {
  searchProjectRole,
  saveProjectRole,
  getProjectRoleById,
  generateReport,
  deleteProjectRole,
};
