/* eslint-disable import/no-anonymous-default-export */
import { DocumentsConstants } from "features/processManagement/commons/DocumentsConstants"; 

const initialState = {
  dataMaster: {
    isLoading: false,
    users: null,
    itemType: null,
    formatFile: null,
    fileStatus: null,
  },
  isStorageEnabled: {
    isLoading: false,
    isEnabled: null
  },
  document: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false,
    isUpdated: false,
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'name',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {}
  },
  listFolders: {
    isLoading: false,
    data: []
  },
  listFilesFolders: {
    isLoading: false,
    data: []
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DocumentsConstants.Accion.Documents.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
    case DocumentsConstants.Accion.Documents.SEARCH:
        return {
            ...state,
            listFilesFolders: {
                isLoading: action.payload.isLoading,
                data: action.payload.data
            }
        };
      case DocumentsConstants.Accion.Documents.UPDATE_FILTER:
        return {
            ...state,
            advancedFilter: state.advancedFilter
        };
      case DocumentsConstants.Accion.Documents.IS_STORAGE_ENABLED:
            return {
                ...state,
                isStorageEnabled: {
                    isLoading: action.payload.isLoading,
                    isEnabled: action.payload.isEnabled,
                },
            };
      case DocumentsConstants.Accion.Documents.GET_DATA_MASTER:
              return {
                  ...state,
                  dataMaster: {
                      users: action.payload.users,
                      itemType: action.payload.itemType,
                      formatFile: action.payload.formatFile,
                      fileStatus: action.payload.fileStatus,
                      isLoading: action.payload.isLoading
                  }
              };
          case DocumentsConstants.Accion.Documents.DOWNLOAD_REPORT_STATUS:
        return {
            ...state,
            document: {
                ...state,
                isLoading: action.payload.isLoading,
            },
        };
        case DocumentsConstants.Accion.Documents.GET_DATA_LIST_FOLDERS:
          return {
              ...state,
              listFolders: {
                  data: action.payload.data,
                  isLoading: action.payload.isLoading
              }
          };
        case DocumentsConstants.Accion.Documents.GET_DATA_LIST_FILES_AND_FOLDERS:
            return {
                ...state,
                listFilesFolders: {
                    data: action.payload.data,
                    isLoading: action.payload.isLoading
                }
            };
          case DocumentsConstants.Accion.Documents.GET_RECENT_FILES:
          case DocumentsConstants.Accion.Documents.GET_CURRENT_FILES:
          case DocumentsConstants.Accion.Documents.GET_MY_FILES:
          case DocumentsConstants.Accion.Documents.GET_TRASH_FILES:
              return {
                    ...state,
                    listFilesFolders: {
                        data: action.payload.data,
                        isLoading: action.payload.isLoading
                    }
                };
          case DocumentsConstants.Accion.Documents.RESET_AFTER_SAVED:
            return {
                ...state,
                document: {
                    data: null,
                    isSaving: false,
                    isSaved: false,
                    isLoading: false,
                    isDeleted: false,
                    isUpdated: false,
                }
            };
        case DocumentsConstants.Accion.Documents.GET_DOCUMENT_LIBRARY_BY_ID:
        return {
            ...state,
            document: {
                data: action.payload.data,
                isSaving: false,
                isLoading: action.payload.isLoading
            }
          };
      case DocumentsConstants.Accion.Documents.DELETE_DOCUMENT_LIBRARY:
            return {
                ...state,
                document: {
                    isDeleted: action.payload.isDeleted
                }
            };
      case DocumentsConstants.Accion.Documents.COPY_DOCUMENT_LIBRARY:
      case DocumentsConstants.Accion.Documents.MOVE_DOCUMENT_LIBRARY:
      case DocumentsConstants.Accion.Documents.RENAME_DOCUMENT_LIBRARY:
        return {
            ...state,
            document: {
                isUpdated: action.payload.isUpdated
            }
        };
      case DocumentsConstants.Accion.Documents.UPLOAD_FILE:
          return {
              ...state,
              document: {
                  data: action.payload.currentDocument,
                  isSaving: action.payload.isSaving,
                  isSaved: action.payload.isSaved
              }
          };
      default:
      return state;
  }
}