import { MaintenanceConstants } from "features/maintenance/commons/MaintenanceConstans"; 
import { CoreConstants } from 'core/commons/CoreConstants';
import moment from 'moment';
import { toastAction } from "core/store/actions/ToastAction";
import FileSaver from 'file-saver';
import { PipelinesService } from "features/maintenance/services/PipelinesServices";

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: MaintenanceConstants.Accion.Pipeline.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: MaintenanceConstants.Accion.Pipeline.RESET_AFTER_SAVED });
};


const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.description)
      pines.push({ field: 'description', value: advancedFilter.filter.description });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });


    advancedFilter.values = pines;
    dispatch({
      type: MaintenanceConstants.Accion.Pipeline.UPDATE_FILTER,
      advancedFilter,
    });
  };
};



const search = () => {
  const setPipelines = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Pipeline.SEARCH_PIPELINES,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const pipelines = getState().pipelineReducer.pipelines;
    setPipelines({
      dispatch: dispatch,
      isLoading: true,
      data: pipelines.data,
    });

    const criteria = {};
    const advancedFilter = getState().pipelineReducer.advancedFilter;
    const filter = getState().pipelineReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.description = filter.description;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;
    
    
    PipelinesService.searchPipelines(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setPipelines({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });          
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          setPipelines({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Pipeline', data.message));
        }else {
          setPipelines({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Pipeline', data.message));
        }
      },
      () => {
        setPipelines({ dispatch: dispatch, isLoading: false, data: null });
      }
    ).catch((error)=>{
    });
  };
};


const getPipelineById = (pipelineId) => {
  const setPipeline = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Pipeline.GET_PIPELINE_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setPipeline({ dispatch: dispatch, isLoading: true });
    PipelinesService.getPipelineById(pipelineId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setPipeline({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setPipeline({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Pipeline', data.message));
          // dispatch({
          //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
          //   toast: {
          //     titulo: 'Departamento',
          //     mensaje: data.message,
          //     severidad: 'warn',
          //   },
          // });
        }else{
          dispatch(toastAction.error('Pipeline', data.message));
        }
      },
      () => {
        setPipeline({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};


const savePipeline = (pipeline) => {
  // console.log(pipeline);
  const isLoading = ({ dispatch, isSaving, isSaved, currentPipeline }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Pipeline.SAVE_PIPELINE,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    const currentPipeline = getState().pipelineReducer.pipeline;
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
      currentPipeline: currentPipeline,
    });
    PipelinesService.savePipelines(pipeline).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Pipeline', 'El pipeline se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentPipeline, });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentPipeline });
          dispatch(toastAction.warn('Pipeline', data.message));
        }else{
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
          dispatch(toastAction.error('Pipeline', data.message));
        }

      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};

const deletePipeline = (pipelineId) => {

  return (dispatch, getState) => {

    PipelinesService.deletePipeline(pipelineId).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Pipeline', 'El Pipeline se eliminó correctamente'));
          dispatch({ type: MaintenanceConstants.Accion.Pipeline.DELETE_PIPELINE, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Pipeline', data.message));
          dispatch({ type: MaintenanceConstants.Accion.Pipeline.DELETE_PIPELINE, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: MaintenanceConstants.Accion.Pipeline.DELETE_PIPELINE, payload: { isDeleted: false } });
      }
    );
  };
};

const generateReport = (payload) => {
  const setPipelines = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Pipeline.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setPipelines({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().pipelineReducer.advancedFilter.filter;

    criteria.description = filter.description;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    PipelinesService.generateReport(criteria)
      .then(({ data }) => {
        setPipelines({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Pipeline.xls');
      })
      .catch((err) => {
        setPipelines({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Pipelines', 'Hubo un error al descargar el archivo'));
      });
  };
};

export const PipelineAction = {
  toggleAdvancedFilter,
  updateFilter,
  search,
  savePipeline,
  deletePipeline,
  generateReport,
  resetAfterSaved,
  getPipelineById,
}
