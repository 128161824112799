import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from "core/store/actions/ToastAction";
import { RiskConstants } from '../../commons/RiskConstants';
import { RiskManagementServices } from 'features/riskManagement/services/RiskManagementServices';
import { RiskEvaluationServices } from 'features/riskManagement/services/RiskEvaluationServices';

const setGetDataMaster = (payload) => {
  return (dispatch) =>
    dispatch({
      type: RiskConstants.Accion.RiskManagement.GET_DATA_MASTER,
      payload,
    });
};

const setId = (payload) => {
  return (dispatch) => 
  dispatch({
    type: RiskConstants.Accion.RiskManagement.SET_NEW_ID,
    payload,
  })
}

const setGetData = (payload) => ({
  type: RiskConstants.Accion.RiskManagement.GET_DATA,
  payload,
});

const setAllMatrix = (payload) => ({
  type: RiskConstants.Accion.RiskManagement.GET_ALL_MATRIX,
  payload,
})

const getDataMaster = () => {
  return (dispatch) => {
    dispatch(setGetDataMaster({isLoading: true}))
    RiskManagementServices.getDataMaster().then(({data})=>{
      if(data.status === CoreConstants.HttpResponse.OK){
        const noColor = data.data.colors.find(item => item.code === 'NOCOL')
        const colors = data.data.colors.filter(item => item.code !== 'NOCOL').concat([noColor])
        dispatch(setGetDataMaster({colors, isLoading: false}))
      } else if(data.status > CoreConstants.HttpResponse.OK){
        dispatch(setGetDataMaster({isLoading: false, error: true}))
        dispatch(toastAction.warn('Gestión de Riesgos', data.message));
      } else {
        dispatch(setGetDataMaster({isLoading: false, error: true}))
      }
    }).catch(error=>{
      dispatch(toastAction.error('Gestión de Riesgos', 'Error'));
      dispatch(setGetDataMaster({isLoading: false, error: true}))
    })
  }
}

const clearData = () => (dispatch) => {
  dispatch(setGetData(
    {
      id: null,
      isLoading: false,
      data: [],
      name: "",
      probability: [],
      impact: [],
      level: [],
      error: false,
      status: false
    }))
}

const getData = (isNew, id) => {
  return (dispatch) => {
    if(isNew){
      dispatch(setGetData({isLoading: true}))
      RiskEvaluationServices.getDefaultHeatMapInfo().then(({data})=>{
        if(data.status === CoreConstants.HttpResponse.OK){
          
          const numRow = Math.max(...data.data.heatMap.map(item => item.rowNumber), 0) + 1;
          const numCol = Math.max(...data.data.heatMap.map(item => item.colNumber), 0) + 1;
          dispatch(setId(numRow*numCol + 1));
          const aux = [];
          for(let i = 0; i< numRow; i++){
            aux[i] = []
          }

          let {impacts: impact, levels: level, probabilities: probability} = data.data     

          data.data.heatMap.forEach( (item, key) => {
            aux[numRow - item.rowNumber - 1][item.colNumber] = {
              id: key + 1,
              rowId: item.cellRowId,
              rowName: item.rowName,
              colId: item.cellColId,
              colName: item.colName,
              colorId: item.colorId,
              color: item.color.description,
              colorCode: item.color.code,
              rowNumber: item.rowNumber,
              colNumber: item.colNumber,
            }
          })

          level = level.map( item => ({
            id: item.id, 
            name: item.name, 
            colorId: item.colorId, 
            color: item.color.description, 
            colorCode: item.color.code,
            processId: item.processId,
          }))

          
          
          dispatch(setGetData({isLoading: false, data: aux, impact, level, probability, status: true}))
        }else if(data.status > CoreConstants.HttpResponse.OK){
          dispatch(setGetData({isLoading: false, error: true, status: true}))
          dispatch(toastAction.warn('Gestión de Riesgos', data.message));
        }else{
          dispatch(setGetData({isLoading: false, error: true, status: true}))
        }
      }).catch(e => {
        
        dispatch(toastAction.error('Gestión de Riesgos', 'Error'));
        dispatch(setGetData({isLoading: false, error: true, status: true}))
      })
    }else{
      dispatch(setGetData({isLoading: true}))
    RiskEvaluationServices.getEvaluationHeatMapByEvaluationId(id).then(({data})=>{
      if(data.status === CoreConstants.HttpResponse.OK){
        
        const numRow = Math.max(...data.data.heatMap.map(item => item.rowNumber), 0) + 1;
        const numCol = Math.max(...data.data.heatMap.map(item => item.colNumber), 0) + 1;
        dispatch(setId(numRow*numCol + 1));
        const aux = [];
        for(let i = 0; i< numRow; i++){
          aux[i] = []
        }

        let {impacts: impact, levels: level, probabilities: probability} = data.data     

        data.data.heatMap.forEach( (item, key) => {
          aux[numRow - item.rowNumber - 1][item.colNumber] = {
            id: key + 1,
            rowId: item.cellRowId,
            rowName: item.rowName,
            colId: item.cellColId,
            colName: item.colName,
            colorId: item.colorId,
            color: item.color.description,
            colorCode: item.color.code,
            rowNumber: item.rowNumber,
            colNumber: item.colNumber,
          }
        })

        

        level = level.map( item => ({
          id: item.id, 
          name: item.name, 
          colorId: item.colorId, 
          color: item.color.description, 
          colorCode: item.color.code,
          processId: item.processId,
        }))

        
        
        dispatch(setGetData({isLoading: false, data: aux, impact, level, probability, status: true}))
      }else if(data.status > CoreConstants.HttpResponse.OK){
        dispatch(setGetData({isLoading: false, error: true, status: true}))
        dispatch(toastAction.warn('Gestión de Riesgos', data.message));
      }else{
        dispatch(setGetData({isLoading: false, error: true, status: true}))
      }
    }).catch(e => {
      
      dispatch(toastAction.error('Gestión de Riesgos', 'Error'));
      dispatch(setGetData({isLoading: false, error: true, status: true}))
    })
    }
  }
}

const setSquare = (payload) => ({
  type: RiskConstants.Accion.RiskManagement.SET_SELECT_SQUARE,
  payload,
})

const setSelectSquare = (id) => {

  return (dispatch, getState) => {
    const selection = getState().riskManagementReducer.selectSquare;
    let aux = []
    if(selection.includes(id)){
      aux = selection.filter(item => item !== id)
    }else{
      aux = selection.concat(id)
    }
    dispatch(setSquare(aux))
  }
}

const changeColor = (color) => {
  return (dispatch, getState) => {
    const selection = getState().riskManagementReducer.selectSquare;
    const {data, level} = getState().riskManagementReducer.data;
    
    
    data.forEach(row => {
      row.forEach(item => {
        if(selection.includes(item.id)){
          item.colorId = color.id;
          item.color = color.code; //nombre
          item.colorCode = color.description; //hex
        }
      })
    });

    if(!level.map(item=>item.colorId).includes(color.id) && color.code !== 'NOCOL'){
      const id = Math.min(...level.map(item => item.id),0)-1
      const aux = {id, name: `Nivel del riesgo ${id}`, colorId: color.id, color: color.code, colorCode: color.description };
      dispatch(setGetData({level: level.concat([aux])}))
    }

    dispatch(setGetData({ data }))
    dispatch(setSquare([]))
  }
}

const deleteRow = (idProb) => {
  return (dispatch, getState) => {
    const {data, probability} = getState().riskManagementReducer.data;
    const newData = data.filter(row => row[0].rowId !== idProb)
    const newProbability = probability.filter(item => item.id !== idProb)
    dispatch(setGetData({ data: newData, probability: newProbability }))
  }
}

const addRow = () => {
  return (dispatch, getState) => {
    const {data, probability, impact} = getState().riskManagementReducer.data;
    const { colors }= getState().riskModelReducer.dataMasterEvaluation.data;
    const noColor = colors.find(item => item.code === 'NOCOL');
    const row = [...data[0]];
    const newId = getState().riskManagementReducer.newId;
    const newProb = {...probability[0], id: Math.min(...probability.map(item=>item.id), 0)- 1, name: `Probabilidad ${probability.length + 1}`, value: 0 };

    const newRow = row.map((item, key) => {
      return ({
        id: newId+key+1,
        rowId: newProb.id,
        rowName: newProb.name,
        colId: item.colId,
        colName: item.colName,
        colorId: noColor.id,
        color: noColor.code,
        colorCode: noColor.description,
        rowNumber: item.rowNumber + 1,
        colNumber: item.colNumber,
    })})

    const newData = [newRow].concat(data)
    const newProbs = probability.concat([newProb])
    dispatch(setGetData({ data: newData, probability: newProbs }))
    dispatch(setId(newId+impact.length))
  }
}
  
const addCol = () => {
  return (dispatch, getState) => {
    const {data, probability, impact} = getState().riskManagementReducer.data;
    const newId = getState().riskManagementReducer.newId
    const newImpacto = { ...impact[0], id: Math.min(...impact.map(item=>item.id), 0)-1, name: `Impacto ${impact.length + 1}`, value: 0 }

    const { colors }= getState().riskModelReducer.dataMasterEvaluation.data;
    
    const noColor = colors.find(item => item.code === 'NOCOL');

    data.forEach((row, key) => {
      const aux = row[0]
      row.push({
        id: newId+key+1,
        rowId: aux.rowId,
        rowName: aux.rowName,
        colId: newImpacto.id,
        colName: newImpacto.name,
        colorId: noColor.id,
        color: noColor.code,
        colorCode: noColor.description,
        rowNumber: aux.rowNumber,
        colNumber: row.length,
      })
    })
    const newImpactos = impact.concat([newImpacto])
    dispatch(setGetData({ data, impact: newImpactos }))
    dispatch(setId(newId+probability.length))
  }
}

const deleteCol = (impactoId) => {
  return (dispatch, getState) => {
    const {data, impact} = getState().riskManagementReducer.data;
    const newData = []
    data.forEach((row,key)=>{
      const aux = row.filter(item => item.colId !== impactoId)
      newData.push(aux)
    })
    const newImpact = impact.filter(item=> item.id !== impactoId)
    dispatch(setGetData({ data: newData, impact: newImpact }))
  }
}

const changeNikelihoodName = (name, id) => {
  return (dispatch, getState)=> {
    const { probability, data }= getState().riskManagementReducer.data;
    const newProb = probability.map(item => {
      if(item.id === id){
        return({
          ...item,
          name 
        })
      }else{
        return item
      }
    })
    data.forEach( row => {
      row.forEach( item => {
        if(item.rowId === id){
          item.rowName = name
        }
      })
    })
    dispatch(setGetData({probability: newProb, data}))
  }
}

const changeNikelihoodValue = (value, id) => {
  return (dispatch, getState)=> {
    const { probability }= getState().riskManagementReducer.data;
    const newProb = probability.map(item => {
      if(item.id === id){
        return({
          ...item,
          value
        })
      }else{
        return item
      }
    })
    dispatch(setGetData({probability: newProb }))
  }
}

const changeImpactName = (name, id) => {
  return (dispatch, getState)=> {
    const { impact, data }= getState().riskManagementReducer.data;
    const newImpacto = impact.map(item => {
      if(item.id === id){
        return({
          ...item,
          name 
        })
      }else{
        return item
      }
    })
    data.forEach( row => {
      row.forEach( item => {
        if(item.colId === id){
          item.colName = name
        }
      })
    })
    dispatch(setGetData({impact: newImpacto, data}))
  }
}

const changeImpactValue = (value, id) => {
  return (dispatch, getState)=> {
    const { impact }= getState().riskManagementReducer.data;
    const newImpacto = impact.map(item => {
      if(item.id === id){
        return({
          ...item,
          value
        })
      }else{
        return item
      }
    })
    dispatch(setGetData({ impact: newImpacto }))
  }
}

const deleteRiskLevel = (id) => {
  return (dispatch, getState) => {
    const {data, level} = getState().riskManagementReducer.data;
    const { colors }= getState().riskModelReducer.dataMasterEvaluation.data;
    const noColor = colors.find(item => item.code === 'NOCOL');
    const levelSelect = level.find(item => item.id === id)
    const newRiskLevel = level.filter( item => item.id !== id)
    data.forEach(row => {
      row.forEach( item => {
        if(item.colorId === levelSelect.colorId){
          item.colorId = noColor.id;
          item.color= noColor.code;
          item.colorCode = noColor.description;
        }
      })
    })
    dispatch(setGetData({ data, level: newRiskLevel }))
  }
}

const changeNameRiskLevel = (id, name) => {
  return(dispatch, getState) => {
    const {level} = getState().riskManagementReducer.data;
    const newRiskLevel = level.map(item => {
      if(item.id === id){
        return ({
          ...item,
          name          
        })
      }else{
       return item   
      }
    })
    dispatch(setGetData({ level: newRiskLevel }))
  }
}

const changeProcessRiskLevel = (id, processId) => {
  return(dispatch, getState) => {
    const {level} = getState().riskManagementReducer.data;
    const newRiskLevel = level.map(item => {
      if(item.id === id){
        return ({
          ...item,
          processId,
        })
      }else{
       return item   
      }
    })
    dispatch(setGetData({ level: newRiskLevel }))
  }
}

const getAllMatrix = (data) => {
  return (dispatch) => {
    dispatch(setAllMatrix({isLoading: true}))
    RiskManagementServices.search(data).then(({data})=>{
      if(data.status === CoreConstants.HttpResponse.OK){
        dispatch(setAllMatrix({data: data.data, isLoading: false}))
      } else if(data.status > CoreConstants.HttpResponse.OK){
        dispatch(setAllMatrix({data: {}, isLoading: false}))
        dispatch(toastAction.warn('Gestión de Riesgos', data.message));
      } else {
        dispatch(setAllMatrix({data: {}, isLoading: false}))
      }
    }).catch(error=>{
      dispatch(setAllMatrix({data: {}, isLoading: false}))
    })
  }
}

export const RiskManagementAction = {
  getDataMaster,
  getData,
  setSelectSquare,
  changeColor,
  deleteRow,
  addRow,
  addCol,
  deleteCol,
  changeNikelihoodName,
  changeNikelihoodValue,
  deleteRiskLevel,
  changeNameRiskLevel,
  changeProcessRiskLevel,
  changeImpactName,
  changeImpactValue,
  getAllMatrix,
  clearData,
}