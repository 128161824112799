import React from 'react'
// import { useSelector } from 'react-redux';
import pulgar from '../../../../shared/assets/img/pulgar-arriba.png'
import error from '../../../../shared/assets/img/error.png'

const SurveyPublicMessage = ({message, type, title}) => {
  // const {message} = useSelector((state) => state.surveyPublicReducer.answers);
  
  return (
    <div className='h-screen flex justify-center items-center '>
      <div className='h-1/2 md:h-2/3 w-11/12 md:w-2/3 xl:w-1/2 flex flex-col justify-center items-center'>
        <div className='h-1/2 w-full border-4 sig-primary flex justify-center items-center rounded-t'>
          {/* <img className='h-44 md:h-52' alt='pulgar' src={pulgar}  /> */}
          {
            type === 'error' 
            ? <img className='h-44 md:h-52  ' alt='pulgar' src={error}  />
            : <img className='h-44 md:h-52' alt='pulgar' src={pulgar}  />
          }
        </div>
        <div className='h-1/2 w-full flex border-2 px-3 py-2 bg-gray-100 flex-col justify-center items-center gap-y-3 md:gap-y-5 rounded-b shadow-lg'>
          {
            type === 'error' 
            ? <h1 className='text-2xl md:text-4xl font-light text-center '>¡Hubo un error al cargar la {title ? "ficha" : "encuesta"}!</h1>
            : <h1 className='text-2xl md:text-4xl font-light text-center '>{title ? title : "¡Muchas gracias por realizar la encuesta!"}</h1>
          }
          <h2 className='text-base md:text-lg'>{message}</h2>
        </div>
      </div>
    </div>
  )
}

export default SurveyPublicMessage