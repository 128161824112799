import useModalAction from 'features/configuration/hooks/processes/workflow/useModalAction'
import { Button } from 'primereact/button'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { MultiSelect } from 'primereact/multiselect';
import { useSelector } from 'react-redux'
import './ModalAction.scss'
import { InputText } from 'primereact/inputtext'
import { AutoComplete } from 'primereact/autocomplete';
import { useEffect } from 'react'
import { Dropdown } from 'primereact/dropdown'
import ModalGenerateFormula from './ModalGenerateFormula'

const FormulaComponent = ({value, onChange, suggestions, searchStep, openModalFormula}) => {

  return (
    <>
      {/* <div className="p-inputgroup w-full process-input-autocomplete">
          <span className="p-inputgroup-addon process_condition_icon italic bg-blue-500 text-white" ><span className='text-xl'>f</span>x</span>
          <span className="p-float-label">
              <AutoComplete 
              value={value} 
              suggestions={suggestions} 
              completeMethod={searchStep} 
              field="identifier" 
              // field="label" 
              onChange={(e) => onChange(e.value)} 
              aria-label="Countries" 
              dropdownAriaLabel="Select Country" 
              // className={errors?.condition ? 'p-invalid w-full process_condition_text' : 'w-full process_condition_text'}
              placeholder='Escribir la formula'
              className='disabled'
              disabled
            /> */}
            {/* <label htmlFor="condition">Escribir la formula</label> */}
          {/* </span>
      </div> */}
      <div 
        className='w-full bg-blue-50 flex items-center gap-x-2 text-blue-600 hover:text-blue-800 cursor-pointer font-semibold py-1 px-2'
        onClick={() => openModalFormula(true)}
      >
        <i className="pi pi-external-link"></i>
        <p>Abrir el generador de fórmulas</p>
      </div>
    </>
  )
}

const ModalAction = ({
    displayModal, setDisplayModal, 
    actionSelected, setActionSelected, 
    stepSelected, setStepSelected, 
    stepFather, setStepFather,
    listControls, 
    stepMethods,
    workFlowFields,
    getTypeById,
  }) => {

  const {roles} = useSelector(state => state.processReducer.dataMaster)
  
  const [visible, setVisible] = useState(false)
  const [displayModalFormula, setDisplayModalFormula] = useState(false)
  const { control, errors, existError, valuesFields, setValue, handleSubmit, reset } = useModalAction()

  const titleAction = ( ) => {
    if (actionSelected) {
      if (actionSelected === 'initial_setup') return 'Configuración de Inicio'
      if (actionSelected === 'step_configuration') return 'Configuración del Paso'
      if (actionSelected === 'go_to_step') return 'Ir al paso'
      if (actionSelected === 'parallel_branch') return 'Configuración de Rama'
    }
    return 'Configuración'
  }

  const [suggestions, setSuggestions] = useState(listControls)

  const onHide = () => {
    setDisplayModal(false)
    setActionSelected(null)
    setStepFather(null)
    setStepSelected(null)
    reset()
  }

  useEffect(() => {
    if (stepSelected) {
      setValue('name', stepSelected.name)
      setValue('roles', stepSelected.roles)
      // setValue('condition', stepSelected.condition)
      setValue('conditions', stepSelected.conditions)
      setValue('logicalOperatorId', stepSelected.logicalOperatorId)
    }
  }, [stepSelected, setValue])

  useEffect(() => {
    if (stepSelected?.stepTo && workFlowFields.listSteps.length > 0) {
      setValue('stepTo', workFlowFields.listSteps.find(step => step.id === stepSelected.stepTo.id) )
    }
  }, [stepSelected, setValue, workFlowFields.listSteps])
  

  const redirect = () => {
    onHide()
  }
  const reject = () => {
      setVisible(false);
  };

  const openModalFormula = () => {
    setDisplayModalFormula(true)
  }

  const searchStep = (event) => {
    setTimeout(() => {
        let _filteredCountries;
        if (!event.query.trim().length) {
            _filteredCountries = [...listControls];
        }
        else {
            _filteredCountries = listControls.filter((suggestion) => {
                return suggestion.identifier.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }

        setSuggestions(_filteredCountries);
    }, 250);
}

  const onSubmit = (data) => {
    const {roles, name, conditions, logicalOperatorId, stepTo} = data
    
    if (actionSelected === 'initial_setup') {
      data.conditions = []
      stepMethods.updateStep(actionSelected, data)
      redirect()
    }
    if (actionSelected === 'step_configuration') {
      if (stepSelected) {
        stepMethods.updateStep(actionSelected, data, stepSelected)
        redirect()
        return
      }
      // if (stepFather.type === 'parallel_branch' || stepFather.branch) {
      if ((getTypeById(stepFather.typeId)?.code === 'CONDITION' && !stepFather.childrenBranch) || stepFather.branch) {
        stepMethods.createNewStepInBranch(roles, name, conditions, logicalOperatorId, stepFather)
        redirect()
        return
      }
      stepMethods.createNewStep(roles, name, conditions, logicalOperatorId, stepFather)
      redirect()
    }
    if (actionSelected === 'go_to_step') {
      if (stepSelected) {
        const model = {conditions, logicalOperatorId, stepTo}
        model.gotoCode = stepTo?.code
        stepMethods.updateStepGoTo(model, stepSelected)
        redirect()
        return
      }
      stepMethods.createNewStepGoTo(conditions, stepTo, logicalOperatorId, stepFather)
      redirect()
    }
    if (actionSelected === 'parallel_branch') {
      const model = {conditions, logicalOperatorId, name}
      stepMethods.updateStepBranch(model, stepSelected)
      redirect()
    }
  };

  return (
    <Dialog header={titleAction} visible={displayModal} className={`w-4/5 md:w-3/5 ${actionSelected === 'go_to_step' ? 'xl:w-1/3' : 'xl:w-3/5'}  `} onHide={onHide}>
      <form 
        className="form-custom p-0"
        id="modal-action-form"
      >
        {/* <hr /> */}
          <div className='grid grid-cols-10 gap-y-2 mt-2 border-bottom-modal'>
            {
              actionSelected === 'initial_setup' && (
                <div className='col-span-10'>
                  <p className='font-semibold'>Responsable</p>
                  <div className='w-3/4 mt-1'>
                    <Controller
                        control={control}
                        render={({
                          field: { onChange, onBlur, value },
                        }) => (
                            <div className='multiselect-demo'>
                              <MultiSelect 
                                value={value} 
                                options={roles} 
                                onChange={(e) => onChange(e.value)} 
                                optionLabel="description" 
                                placeholder="Seleccione el roles o roles que pueden iniciar el proceso" 
                                display="chip" 
                                className='w-full'
                                // maxSelectedLabels={3}
                                // selectionLimit={3}
                                selectedItemsLabel={`${2} roles seleccionados`}
                              />
                            </div>
                            )}
                        name="roles"
                    />
                  </div>
                </div>
              )
            }
            {
              actionSelected === 'step_configuration' && (
                <>
                  <div className='col-span-10 mt-3'>
                    <span className="p-float-label w-full">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value },
                          }) => (
                            <InputText
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                            />
                          )}
                          name="name"
                      />
                      <label htmlFor="name">Ingrese el nombre del paso</label>
                    </span>
                  </div>
                  <div className='col-span-10'>
                    <p className='font-semibold'>Responsable</p>
                    <div className='w-3/4 mt-1'>
                      <Controller
                          control={control}
                          render={({
                            field: { onChange, onBlur, value },
                          }) => (
                              <div className='multiselect-demo'>
                                <MultiSelect 
                                  value={value} 
                                  options={roles} 
                                  onChange={(e) => onChange(e.value)} 
                                  // optionValue="id"
                                  optionLabel="description" 
                                  placeholder="Seleccione el roles o roles que pueden iniciar el proceso" 
                                  // maxSelectedLabels={3}
                                  display="chip" 
                                  className='w-full'
                                />
                              </div>
                              )}
                          name="roles"
                      />
                    </div>
                  </div>
                  <div className='col-span-10'>
                    <p className='font-semibold'>Condición</p>
                    <div className='w-3/4 mt-4'>
                      <Controller
                          control={control}
                          render={({
                            field: { onChange, onBlur, value },
                          }) => (
                                <>
                                    <FormulaComponent 
                                      value={value} 
                                      onChange={onChange}
                                      suggestions={suggestions}
                                      searchStep={searchStep}
                                      openModalFormula={openModalFormula}
                                    />
                                    
                                </>
                              )}
                          name="condition"
                      />
                    </div>
                  </div>
                </>
              )
            }
            {
              actionSelected === 'go_to_step' && (
                <>
                  <div className='col-span-10 mt-3'>
                    {/* <p className='font-semibold'>Ir a</p> */}
                    <div className='w-full mt-1'>
                      <Controller
                          control={control}
                          render={({
                            field: { onChange, onBlur, value },
                          }) => (
                                <span className="p-float-label my-1 w-1/2">
                                  <Dropdown 
                                    options={workFlowFields.listSteps} 
                                    optionLabel="name" 
                                    // optionValue='id'
                                    value={value}
                                    onChange={onChange}
                                    className={'w-full'}
                                    id='stepTo'
                                  />
                                  <label htmlFor='stepTo'>Seleccionar paso</label>
                                </span>
                              )}
                          name="stepTo"
                      />
                    </div>
                  </div>
                  <div className='col-span-10'>
                    {/* <p className='font-semibold'>Condición</p> */}
                    <p className='font-semibold'>¿Cuándo ocurre?</p>
                    <div className='w-full mt-2'>
                      <Controller
                          control={control}
                          render={({
                            field: { onChange, onBlur, value },
                          }) => (
                                  <FormulaComponent 
                                  value={value} 
                                  onChange={onChange}
                                  suggestions={suggestions}
                                  searchStep={searchStep}
                                  openModalFormula={openModalFormula}
                                />
                              )}
                          name="condition"
                      />
                    </div>
                  </div>
                </>
              )
            }
            {
              actionSelected === 'parallel_branch' && (
                <>
                  <div className='col-span-10 mt-3'>
                    <span className="p-float-label w-full">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value },
                          }) => (
                            <InputText
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                            />
                          )}
                          name="name"
                      />
                      <label htmlFor="name">Ingrese el nombre del paso</label>
                    </span>
                  </div>
                  <div className='col-span-10'>
                    <p className='font-semibold'>Condición</p>
                    <div className='w-3/4 mt-4'>
                      <Controller
                          control={control}
                          render={({
                            field: { onChange, onBlur, value },
                          }) => (
                                <>
                                    <FormulaComponent 
                                      value={value} 
                                      onChange={onChange}
                                      suggestions={suggestions}
                                      searchStep={searchStep}
                                      openModalFormula={openModalFormula}
                                    />
                                    
                                </>
                              )}
                          name="condition"
                      />
                    </div>
                  </div>
                </>
              )
            }
            {/* Botones */}
            <div className='col-span-10'>
              <div className="flex justify-end gap-4 mt-3">
                <Button
                    icon="pi pi-times"
                    type="button"
                    label="Cancelar"
                    onClick={() => setVisible(true)}
                    className="sig-button sig-secondary mt-4"
                />
                <Button
                    icon="pi pi-save"
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={existError ? true : false}
                    label="Guardar"
                    className="sig-button sig-primary mt-4"
                    form="modal-action-form"
                />
              </div>
            </div>
          </div>
      </form>
      <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={redirect}
          reject={reject}
      />
      <ModalGenerateFormula 
        displayModal={displayModalFormula} setDisplayModal={setDisplayModalFormula}
        listFields={listControls}
        stepMethods={stepMethods}
        setModalValue={setValue}
        stepSelected={stepSelected}
      />
    </Dialog>
  )
}

export default ModalAction