import { PageTasks } from "./PageTasks"
import { useCustomerTask } from "features/customers/hooks/tasks/useCustomerTask";

export const PageCustomerTasks = () => {
  const { setNewPage } = useCustomerTask();

  return (
    <>
      <PageTasks onNew={setNewPage}/>
    </>
  )
}