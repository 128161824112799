import { BusinessConstants } from 'features/customers/commons/BusinessConstants';

const initialState = {
  business: {
    data: [],
    loading: false,
    order: 'asc',
    pagination: {},
    columnOrder: 'name',
  },
  actions: {
    loading: false,
    items: [
      { name: 'Editar', icon: 'pi pi-pencil' },
      { name: 'Eliminar', icon: 'pi pi-trash' },
    ],
  },
  advancedFilter: {
    showModal: false,
    values: [],
  },
  downloadReport: {
    loading: false,
  },
  currentAction: {
    isOpen: false,
    customerId: null,
    businessId: null,
  },
  editBusiness: {
    isLoading: false,
    isLoadingBusiness: false,
    data: {},
    attachments: [],
    status: null,
    errors: [],
  },
  dataMaster: {
    isLoadingDataMaster: false,
    dataMaster: {
      pipelines: [],
      stages: [],
      responsibles: [],
      departments: [],
      currencies: []
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BusinessConstants.Action.Business.ADVANCED_FILTER:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload,
        },
      };
    case BusinessConstants.Action.Business.SEARCH_BUSINESS:
      return {
        ...state,
        business: {
          ...state.business,
          ...action.payload,
        },
      };
    case BusinessConstants.Action.Business.DATA_MASTER:
      return {
        ...state,
        dataMaster: {
          ...state.dataMaster,
          ...action.payload,
        },
      };
    case BusinessConstants.Action.Business.CURRENT_ACTION:
      return {
        ...state,
        currentAction: {
          ...state.currentAction,
          ...action.payload,
        },
      };
    case BusinessConstants.Action.Business.EDIT_BUSINESS:
      return {
        ...state,
        editBusiness: {
          ...state.editBusiness,
          // isLoadingBusiness: action.payload.isLoadingBusiness,
          // data: action.payload.data,
          // attachments: action.payload.attachments,
          ...action.payload,
        },
      };
    case BusinessConstants.Action.Business.REPORT_STATUS:
      return {
        ...state,
        downloadReport: {
          loading: action.payload,
        },
      };
    default:
      return state;
  }
};