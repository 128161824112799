import React, { useState } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { InputTextarea } from 'primereact/inputtextarea';
// import { ScrollPanel } from 'primereact/scrollpanel';
import { Tree } from 'primereact/tree';
import { v4 as uuidv4 } from 'uuid';
import './ModalImportQuestion.scss'
import { useSelector } from 'react-redux';

const nodeTemplate = (node, options) => {
  return(
    <small>{node.label}</small>
  )
}


function CustomPregunta({props}){
  const [expandedKeys, setExpandedKeys] = useState({0: true})
  const [label, setLabel] = useState('Ocultar respuestas')
  if(props.pregunta!==''){
  // if(!props.alternativas){
  if(!props.surveyQuestionOptions){
    return(
      <div className="bg-gray-100 border-2 border-gray-300" style={{padding: "10px", marginBottom: "10px"}}>
        <div>
        {/* <strong>{props.pregunta}</strong> */}
        <strong>{props.body}</strong>
        </div>
        <div>
          <small>Cuadro de texto simple</small>
        </div>
      </div>
    )
  }else{
    // const children = props.alternativas.map((item, index)=>{
    const children = props.surveyQuestionOptions.map((item, index)=>{
      return(
        {
          key: `0-${index}`,
          label: `R${index+1}. ${item}`
        }
      )
    })

    const data = [{
      key: "0",
      label: expandedKeys[0] ? "Ocultar respuestas": "Mostrar respuestas" ,
      children,
    }]

    return(
      <div className="bg-gray-100 border-2 border-gray-300" style={{padding: "10px", marginBottom: "10px"}}>
        <div>
        {/* <strong>{props.pregunta}</strong> */}
        <strong>{props.body}</strong>
        </div>
        <Tree className="bg-gray-100 border-0 p-0" value={data} 
          id="modal-question"
          expandedKeys={expandedKeys}
          nodeTemplate={nodeTemplate}
          onToggle={e => setExpandedKeys(e.value)}/>
      </div>
    )}
  }

}

const ModalImportQuestion = ({displayModal, setDisplayModal, listSections, setListSections, sectionSelected}) => {

  const [value, setValue] = useState("")
	const [cuestionario, setCuestionario] = useState([])
  const {questionTypes} = useSelector((state) => state.surveyReducer.dataMaster);

  const getQuestionType = (questionTypeCode) => questionTypes.find(question => question.code === questionTypeCode)


  const onHide = () => {
    setValue("")
    setCuestionario([])
    setDisplayModal(false)
    // setValue('newName', '')
    // if (data) {
    //   dispatch(DocumentLibraryAction.resetAfterSaved());
    // }
  }

  const read = (e) => {
		const content = e.target.value
    const filter = deleteEnd(content)
    setValue(content)
		const aux = convertir(filter+"\n\n")
    const clean = aux.filter(item=>item.pregunta!=="")
    setCuestionario(clean)
	}

  const deleteEnd = (text) => {
    let aux = ""
    let count = 0
    for(let letter of text){
      if(letter !== "\n"){
        aux = aux + letter
        count = 0
      } else {
        count = count +1
        if(count <=2){
          
          aux = aux + letter
        }
      }
    }
    return aux
  }

  const convertir = (text) => {
    var isP = true
    var isR = false
    var aux = []
    var pregunta = ""
    var alternativa = ""
    var alternativas = []
    var item = {}
    var countP = 0
    var countR = 0
    for(let letter of text){
      if(isP && letter !== "\n"){
        pregunta = pregunta + letter
      }else if(isR && letter !== "\n"){
        countP = 0
        countR = 0
        alternativa = alternativa + letter
      }else if(isP && letter === "\n"){
        // item["pregunta"] = pregunta
        item["body"] = pregunta
        isP = false
        isR = true
        pregunta = ""
        countP = countP + 1
      }else if(isR && letter === "\n"){
        if(countP === 1){
          aux.push(item)
  
          isP = true
          isR = false
          pregunta = ""
          alternativa = ""
          alternativas = []
          item = {}
          countP = 0
          countR = 0
        }else{
          if(countR === 0){
            alternativas.push(alternativa)
            alternativa = ""
            countR = countR + 1
          }else{
            // item["alternativas"] = alternativas
            item["surveyQuestionOptions"] = alternativas
            aux.push(item)
            isP = true
            isR = false
            pregunta = ""
            alternativa = ""
            alternativas = []
            item = {}
            countP = 0
            countR = 0
          }
        }
      }
    }
    return aux;
  }

  // caption: "2a"
  // id: "34c593bb-14be-4fd5-b98d-998ecd063c4e"
  // position: 2
  const transformOptions = (options) => {
    return options.map((option, index) => {
      const modelOption = {
        caption: option,
        id: uuidv4(),
        position: index + 1
      }

      return modelOption
    })
  }

  const transformQuestion = (questions) => {
    const newQuestions = questions.map((question, index)=>{
      const modelQuestion = {
        ...question,
        extraOptions: [],
        icon: null,
        id: uuidv4(),
        // position: index + 1,
        // position: listQuestion.length + index + 1,
        position: listSections.reduce((acc, section) => acc + section.listQuestion.length, 0) + index + 1,
        questionTypeId: question.surveyQuestionOptions ? getQuestionType('OM') : getQuestionType('TX'),
        surveyQuestionOptions: question.surveyQuestionOptions ? transformOptions(question.surveyQuestionOptions) : [],
        rankingQuantity: 0
      }

      return modelQuestion
    })
    return newQuestions
  }

  const preguntas = () => {
    // Agregar las preguntas al final de la lista de preguntas de la sección actual seleccionada
    const newListSections = [...listSections]
    // Encontrar la posición de la sección actual seleccionada
    const index = newListSections.findIndex(section => section.id === sectionSelected.id)
    newListSections[index].listQuestion = [...newListSections[index].listQuestion, ...transformQuestion(cuestionario)]
    setListSections(newListSections)

    // setListQuestion(prev => [...prev, ...transformQuestion(cuestionario)])
    onHide()
  }

  return (
    <Dialog header={`Importar Preguntas a la sección ${sectionSelected.position}`} visible={displayModal} className='w-4/5 md:w-3/5' onHide={onHide}>
      <div className='flex gap-4'>
        <div className='flex-1'>
          <InputTextarea rows="18" cols="40" value={value} onChange={(e)=>read(e)}/>
        </div>
        <div className='flex flex-col flex-1 justify-between w-1/2'>
            <div className='h-4/5'>
              <div>Vista previa</div>
                <div className="overflow-y-scroll mb-2" style={{height: "370px"}}>
                  {
                  cuestionario.length === 0
                  ? <div> No se agregó ninguna pregunta</div> 
                  : cuestionario.map((item, index) => (
                    <CustomPregunta key={index} props={item} />
                    ))
                  }
                </div>
              <Button
                type="button"
                label={`Agregar (${cuestionario.length}) Pregunta${cuestionario.length > 1? "s":""}`} 
                className="sig-button sig-success w-full"
                onClick={preguntas}
                disabled={cuestionario.length===0}
              />
            </div>
        </div>
    </div>
    </Dialog>
  )
}

export default ModalImportQuestion