import { useState , useRef  } from "react"
import { v4 as uuidv4 } from "uuid";

const useAttachments = ({ setValue, fieldFiles, filesValues, fieldNewFiles, newFilesValues, validExtensions, isMultiple}) =>{
  const wrapperRef = useRef(null);
  const [visible,setVisible] = useState(false)
  const [visibleConfirmation, setVisibleConfirmation] = useState(false)
  const [loadingFile,setLoadingFile] = useState(false)
  const [loadingPercent,setLoadingPercent] = useState(0)
  const [error,setError] = useState(null)
  const [fileId,setFileId] = useState(0);
  const [temporalFiles, setTemporalFiles] = useState([])
  // console.log(filesValues)
  // console.log(temporalFiles)

  const onDragEnter = (e) => {
    wrapperRef.current.classList.add("opacity-60");
  };
  const onDragLeave = (e) => {
    wrapperRef.current.classList.remove("opacity-60");
  };

  const onDrop = (e) => {
    wrapperRef.current.classList.remove("opacity-60");
  };

  const resetForm = () => {
    let input = document.getElementById("input-file-att");
    input.value = null
  }

  const deleteAttachment = (id) => {
    const newFiles = newFilesValues.filter(file => file.id !== id);
    setValue(fieldNewFiles, newFiles)
    resetForm()
  }

  const deleteFileDB = () => {
    const newFiles = filesValues.filter(file => file.id !== fileId);
    setValue(fieldFiles, newFiles)
    setVisible(false)
    setFileId(0)
  }

  const onOpenConfirmation = (e) => {
    const newFiles = Array.from(e.target.files);
    setTemporalFiles(newFiles)
    setVisibleConfirmation(true)
  }

  const onAcceptConfirmation = () => {
    setVisibleConfirmation(false)
    onFileDrop({target:{files:temporalFiles}})
    setTemporalFiles([])
  }

  const onFileDrop = (e) => {
    const newFiles = Array.from(e.target.files);
    setLoadingFile(true)

    const filesValuesNames = filesValues.map(file => file.fileName)
    const newFilesValuesNames = newFilesValues.map(file => file.name)
    const newFilesNames = newFiles.map(file => file.name)
    const allNames = [...filesValuesNames,...newFilesValuesNames,...newFilesNames]

    let num = 0;
    let intervalId = setInterval(() => {
      num += 20;
      if(num > 100){
        const filesWithId = newFiles.map(file =>{
          let name = file.name;
          const repeatedNames = allNames.filter((name,index) => allNames.indexOf(name) !== index) 
          if(repeatedNames.length > 0){
            setError("No se pueden adjuntar archivos con el mismo nombre")
            resetForm()
            return null
          }
          
          if(validExtensions.includes(name.substring(name.lastIndexOf(".") + 1))){
            file.id = uuidv4()
            setError(null)
            return file
          }else{
            setError("Solo se aceptan documentos con las siguientes extensiones: " + validExtensions.join(", "))
            resetForm()
            return null
          }
        })
        
        const validFiles = filesWithId.filter(file => file !== null)
        if (isMultiple) {
          setValue(fieldNewFiles, [...newFilesValues, ...validFiles])
        } else {
          setValue(fieldNewFiles, validFiles)
          setValue(fieldFiles, [])
        }
        clearInterval(intervalId)
        setLoadingFile(false)
        setLoadingPercent(0)
      }else{
        setLoadingPercent(num)
      }
    },1000)
  }

  return {
    files: newFilesValues,
    filesDB: filesValues,
    onFileDrop,
    onDrop,
    wrapperRef,
    loadingFile,
    setLoadingFile,
    loadingPercent,
    setLoadingPercent,
    onDragEnter,
    onDragLeave,
    error,
    deleteAttachment,
    deleteFileDB,
    visible,
    setFileId,
    setVisible,
    visibleConfirmation,
    setVisibleConfirmation,
    onOpenConfirmation,
    onAcceptConfirmation
  }
}

export default useAttachments;