/* eslint-disable import/no-anonymous-default-export */
import { MaintenanceConstants } from "features/maintenance/commons/MaintenanceConstans";

const initialState = {
    dataMaster: {
    isLoading: false,
    // academicDegreeParent: null,
    // manager: null
    },
 businessSector: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false
  },
  businessSectors: {
    isLoading: false,
    data: null
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'name',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {}
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MaintenanceConstants.Accion.BusinessSector.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
      case MaintenanceConstants.Accion.BusinessSector.UPDATE_FILTER:
        return {
            ...state,
            advancedFilter: state.advancedFilter
        };

    case MaintenanceConstants.Accion.BusinessSector.SEARCH_BUSINESS_SECTORS:
        return {
            ...state,
            businessSectors: {
                isLoading: action.payload.isLoading,
                data: action.payload.data
            }
        };
    case MaintenanceConstants.Accion.BusinessSector.GET_BUSINESS_SECTOR_BY_ID:
      return {
          ...state,
          businessSector: {
              data: action.payload.data,
              isSaving: false,
              isLoading: action.payload.isLoading
          }
        };

    case MaintenanceConstants.Accion.BusinessSector.SAVE_BUSINESS_SECTOR:
        return {
            ...state,
            businessSector: {
                data: action.payload.currentBusinessSector,
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };

    case MaintenanceConstants.Accion.BusinessSector.DELETE_BUSINESS_SECTOR:
        return {
            ...state,
            businessSector: {
                isDeleted: action.payload.isDeleted
            }
        };

    case MaintenanceConstants.Accion.BusinessSector.RESET_AFTER_SAVED:

        return {
            ...state,
            businessSector: {
                data: null,
                isSaving: false,
                isSaved: false,
                isLoading: false,
                isDeleted: false
            }
        };

      case MaintenanceConstants.Accion.BusinessSector.GET_DATA_MASTER:
          return {
              ...state,
              dataMaster: {
                  businessSectorParent: action.payload.businessSectorParent,
                  manager: action.payload.manager,
                  isLoading: action.payload.isLoading
              }
          };

      case MaintenanceConstants.Accion.BusinessSector.DOWNLOAD_REPORT_STATUS:
          return {
              ...state,
              businessSector: {
                  ...state,
                  isLoading: action.payload.isLoading,
              },
          };
      
      default: 
      return state;
  }

}