import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { SelectButton } from 'primereact/selectbutton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { ConfirmDialog } from 'primereact/confirmdialog';

import moment from 'moment';

import { CoreConstants } from 'core/commons/CoreConstants';
import typeViews from "shared/utils/typeViews";

import { RiskInventoryAction } from 'features/riskManagement/store/actions/RiskInventoryAction';
import { useClaimInventory } from 'features/riskManagement/hooks/useClaimInventory';
import { Paginator } from 'primereact/paginator';

const TableComponent = () => {

	/* TYPE TABLE VIEW ---------------- */
  const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);

	const menu = useRef(null)
	const history = useHistory()
	const [select, setSelect] = useState({})
	const [showDelete, setShowDelete] = useState(false)
	const dispatch = useDispatch()
	const { data } = useSelector( state => state.riskInventoryReducer.inventories)
  const advancedFilter = useSelector((state) => state.riskInventoryReducer.advancedFilter);


	const { isEdit, isDelete, isView } = useClaimInventory()

	useEffect(()=>{
		dispatch(RiskInventoryAction.getData({
			page: 1,
			pageSize: 10,
			columnOrder: "name",
			order: "asc",
			name: "",
		}))
	},[dispatch])

	let items = [
		{
				label: 'Editar',
				icon: 'pi pi-pencil',
				command: (e) => {
					history.push('/gestion-riesgos/inventario/editar/'+select.id);
			}
		},
		{
			label: 'Eliminar',
			icon: 'pi pi-times',
			command: (e) => {
				setShowDelete(true)
			}
		},
	]

	if(!isEdit)
		items = items.filter(c => c.label!=='Editar')
	if(!isDelete)
		items = items.filter(c => c.label!=='Eliminar')

	const getViewField = (rowData, col) => {
			if (isView)
				return <Link className="underline text-blue-700" to={{ pathname: `/gestion-riesgos/inventario/visualizar/` + rowData.id }} >{rowData.name}</Link>
			else
				return rowData.name;
		}

	const TableBodyActions = (selectedItem) => {
		if(isEdit || isDelete){
			return (
				<i
					onClick={(event) => {
						menu?.current?.toggle(event); 
						setSelect(selectedItem)
					}}
					className="pi pi-ellipsis-v cursor-pointer"
				></i>
			);
		}else{
			return <div></div>
		}
	};

  const onPage = (event) => {
		advancedFilter.page = event.page + 1;
		// dispatch(RiskInventoryAction.updateFilter(advancedFilter));
		dispatch(RiskInventoryAction.getData())
	}

	return (
		<div>
			<div className="flex justify-end mb-2">
        <SelectButton
          unselectable={false}
          value={typeView}
          options={typeViews}
          itemTemplate={({ icon }) => <i className={icon}></i>}
          onChange={(e) => setTypeView(e.value)}
        />
      </div>
			{ typeView === CoreConstants.TypeView.LIST &&
        <>
          <DataTable 
            value={data.results.map( item => ({...item, creationDate: moment(item.creationDate).format(CoreConstants.Format.LOCALE)}))}
            sortField="name"
            sortOrder={-1}
            emptyMessage="No se encontró data"
            stripedRows
            rowHover
            size="small"
            responsiveLayout="scroll"
          >
            <Column field="name" header="Nombre" sortable body={getViewField}></Column>
            <Column field="inventoryTypeName" header="Tipo" sortable></Column>
            <Column field="creationDate" header="Fecha de Creación" sortable></Column>
            <Column
                header={""}
                body={TableBodyActions}
              ></Column>
          </DataTable>
          <Paginator
            key={"pag-1"}
            className="paginator-custom mt-4"
            rows={10}
            first={(data?.currentPage - 1) * data?.pageSize}
            totalRecords={data?.rowCount}
            onPageChange={onPage}
          ></Paginator>
        </>
      }
			{ typeView === CoreConstants.TypeView.CANVA && 
				<div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2  gap-2">
				{data.results?.map((item, index) => {
					return (
						<div
							key={"card" + item.id}
							className="
								block 
								p-3
								max-w-sm
								bg-white
								rounded-lg 
								border 
								border-gray-200 
								shadow-md 
								hover:bg-gray-100 
								dark:bg-gray-800 
								dark:border-gray-700 
								dark:hover:bg-gray-700"
							>
							<div>
								<div className="flex justify-between">
									{
                     isView?(
                        <Link className="underline text-blue-700" to={{ pathname: `/gestion-riesgos/inventario/visualizar/` + item.id }} ><h5>{item.name}</h5></Link>
                    ):(
                        <h5 key={"title" + item.id} className="mb-1 text-md font-bold   text-gray-900 dark:text-white">{item.name}</h5>
                    )
                	}
									{
										(isEdit || isDelete)&&
											<i
												key={"options" + item.id}
												className="pi pi-ellipsis-v cursor-pointer"
												onClick={(event) => {
													setSelect(item)
													menu.current.toggle(event);
												}}
											></i>
									}
								</div>
								<hr />
								<div className="flex flex-col justify-around h-16 text-xs text-gray-600 dark:text-gray-400">
									<div>
									<h5 key={"category" + item.id}>
										<span className="text-xs">Tipo:</span>{" "}
										{item.inventoryTypeName}
									</h5>
									</div>
									<div>
									<h5>
										<span className="text-xs">
											Fecha de Creación: {moment(item.creationDate).format(CoreConstants.Format.LOCALE)}
										</span>{" "}
									</h5>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
			}
			<Menu model={items} popup ref={menu} />
			<ConfirmDialog
				visible={showDelete}
				onHide={() => setShowDelete(false)}
				message="¿Está seguro que desea eliminar el registro?"
				header="Eliminar"
				icon="pi pi-trash" 
				reject={() => setShowDelete(false)}
				accept={()=> dispatch(RiskInventoryAction.deleteInventory(select.id))}
			/>
		</div>
	);
};

export default TableComponent;