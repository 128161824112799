import React, { useState } from 'react';

import {Column} from 'primereact/column';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';

const HeaderGroup = ({data, getValues}) => {	
 
	return (
		<ColumnGroup>
			<Row>
				{
					data.evaluations?.length > 0  && 
					<Column />
				}
				{ 
					data.evaluations?.length > 0  && 
					<Column header={<div className='column-header-matrix'></div>}  colSpan={data?.inventory?.length}/>
				}
				{
					data.evaluations?.map( (evaluation, index, array) => {    
            if( evaluation.formula === ""){
              return (
                <Column key={evaluation.id} header={<div className='column-header-matrix'><h2>{evaluation.name}</h2></div>}  colSpan={4} />
              )
            }
            return (
						  <Column key={evaluation.id} header={<div className='column-header-matrix'><h2>{evaluation.name}</h2></div>}  colSpan={evaluation.criterias.length+2} />
					  )
          })
				}
			</Row>
			<Row>
				<Column header=''/>
				{
					data.inventory?.map( item => (
						<Column key={item.id} header={<div style={{minWidth: '8rem'}}>{item.name}</div>}/>
					))
				}
				{
					data.evaluations?.map( eva => {
            if(eva.formula === ""){
              return (
                [ 
                  <Column key={`pro-${eva.probabilities.id}`} header={<div style={{minWidth: '8rem'}}>{eva.probabilities.name}</div>}/>,
                  <Column key={eva.impacts.id} header={<div style={{minWidth: '8rem'}}>{eva.impacts.name}</div>}/>,
                  <Column key={eva.level.id} header={<div style={{minWidth: '8rem'}}>Expediente</div>}/>,
                  <Column key={eva.id} header={<div style={{minWidth: '8rem'}}>Nivel de Riesgo</div>}/>
                ]
              )
            }
            
            return (eva.criterias.map( item => (
              <Column key={item.id} header={item.name}/>
              )
            )
            .concat(<Column header='Expediente'/>)
            .concat(<Column header='Nivel de Riesgo'/>)
            )}
					)
				}
				{/* <Column header='Resultado'/>
				<Column header='Condicion'/>
				<Column header='Expediente'/> */}
			</Row>
		</ColumnGroup>
	);
};

export default HeaderGroup;