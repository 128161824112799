import useModalNodes from 'features/rrhh/hooks/OrganizationChart/useModalNodes'
import { useClaimOrganizationChart } from 'features/rrhh/hooks/useClaimOrganizationChart';
import { OrganizationChartAction } from 'features/rrhh/store/actions/OrganizationChartAction';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ModalPositions from './ModalPositions';


const ModalNodes = ({displayModal, setDisplayModal, nodeSelected, setNodeSelected}) => {

  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [displayModalPosition, setDisplayModalPosition] = useState(false)

  const {isLoading, areas} = useSelector(state => state.organizationChartReducer.dataMaster);

  const {isSaved} = useSelector(state => state.organizationChartReducer.companyArea);
  const {isSaved: nodeIsSaved, isDeleted, data: nodeData} = useSelector(state => state.organizationChartReducer.node);
  const {data} = useSelector(state => state.organizationChartReducer.companyAreaParents);
  const { control, errors, existError, setValue, handleSubmit, reset } = useModalNodes()

  const { isDelete, isCreate, isEdit } = useClaimOrganizationChart()

  const findAreaById = (id) => {
    const areaSelected = areas ? areas.find(area => area.id === id) : ''
    return areaSelected
  }
  
  useEffect(() => {
    dispatch(OrganizationChartAction.getDataMaster());
  }, [isSaved])

  useEffect(() => {
    if (nodeSelected) {
      dispatch(OrganizationChartAction.getNodeById(nodeSelected.id));
    }
  }, [nodeSelected])

  useEffect(() => {
    if (nodeData) {
      setValue('parentCompanyArea', findAreaById(nodeData.parentAreaId));
      setValue('companyArea', findAreaById(nodeData.areaId));
    }
  }, [nodeData])
  
  useEffect(() => {
    if (nodeIsSaved) {
      dispatch(OrganizationChartAction.getOrganizationChart());
      onHide()
    }
  }, [nodeIsSaved])

  useEffect(() => {
    if (isDeleted) {
      dispatch(OrganizationChartAction.getOrganizationChart());
      onHide()
    }
  }, [isDeleted])

  const parentCompanyAreas = (areas && data.length > 0) ? areas.filter((area) => data.includes(area.id)) : []

 
  
  const onHide = () => {
    setValue('companyArea', null)
    setValue('parentCompanyArea', null)
    setDisplayModal(false)
    setNodeSelected(null)
    reset()
    dispatch(OrganizationChartAction.resetAfterSaved());
  }

  const reject = () => {
    setVisible(false);
  };

  const rejectDelete = () => {
    setVisible(false);
  };

  const onCreatePosition = () => {
    setDisplayModalPosition(true)
  }

  const onDelete = () => {
    dispatch(OrganizationChartAction.deleteNode(nodeSelected.id));
    onHide()
  }

  const onSubmit = (newFile) => {
    if (nodeSelected) {
      const model = {
        id: nodeSelected.id,
        parentAreaId: newFile.parentCompanyArea.id,
        areaId: newFile.companyArea.id,
      }
      dispatch(OrganizationChartAction.saveNode(model));
      return
    }
    const model = {
      parentAreaId: newFile.parentCompanyArea.id,
      areaId: newFile.companyArea.id,
    }
    dispatch(OrganizationChartAction.saveNode(model));
    return
  };

  return (
    <Dialog header={`${nodeSelected ? 'Actualizar Área' : 'Nueva Área' }`} visible={displayModal} className='w-3/4 md:w-2/5 xl:w-1/3' onHide={onHide}>
      <form 
        className="form-custom p-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <hr className='mb-2'/>
        <div className="form-modal p-0">
          {/* <Panel header="DATOS GENERALES" toggleable> */}
            <div className="grid grid-cols-1 md:grid-cols-2 justify-items-center gap-4">
              <div className='col-span-2 sm:col-span-2 gap-5'>
                {isLoading ? (
                  <Skeleton />
                ): (
                  <span className="p-float-label w-64 lg:w-96 my-4">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value},
                          }) => (
                            <Dropdown
                                options={parentCompanyAreas}
                                optionLabel="name"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                filter
                                showClear
                                filterBy="name"
                                className="w-full"
                            />
                          )}
                          name="parentCompanyArea"
                      />
                      <label htmlFor="parentCompanyArea">Seleccione el área al que reporta</label>
                  </span>
                )}       
                {isLoading ? (
                  <Skeleton />
                ): (
                  <div className='flex justify-center items-center gap-x-2 mt-6'>
                    <span className="p-float-label w-64 lg:w-96">
                        <Controller
                            control={control}
                            rules={{
                              required: 'El área es requerido',
                            }}
                            render={({
                                field: { onChange, onBlur, value},
                            }) => (
                              <Dropdown
                                  options={areas.filter(area => !data.includes(area.id) || area.id === nodeData?.areaId)}
                                  optionLabel="name"
                                  value={value}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  filter
                                  showClear
                                  filterBy="name"
                                  className="w-full"
                              />
                            )}
                            name="companyArea"
                        />
                        <label htmlFor="companyArea">Seleccione un área *</label>
                    </span>
                    <i onClick={onCreatePosition} className="pi pi-plus cursor-pointer text-lg hover:text-gray-500"></i>
                  </div>
                )}       
              </div>
            </div>            
          {/* </Panel> */}
        </div>
        {
          (existError) && <div className='message error'>
              <ul>
                  {errors.parentCompanyArea ? (
                      <>
                          <li className="p-error">{errors.parentCompanyArea.message}</li>
                      </>
                  ) : null}
                  {errors.companyArea ? (
                      <>
                          <li className="p-error">{errors.companyArea.message}</li>
                      </>
                  ) : null}
              </ul>

          </div>
          }
          <div className="flex justify-end gap-4 mt-3">
            {
              nodeSelected && (<>
                {
                  isDelete&&
                    <Button
                      icon="pi pi-trash"
                      type="button"
                      label="Eliminar"
                      onClick={() => { setVisibleDelete(true); }}
                      className="sig-button sig-error mt-4"
                    />
                }
              </>
              )
            }
              <Button
                  icon="pi pi-times"
                  type="button"
                  label="Cancelar"
                  onClick={() => { setVisible(true); }}
                  className="sig-button sig-secondary mt-4"
              />
              {
                (isCreate || isEdit)&&
                <Button
                    icon="pi pi-save"
                    type="submit"
                    disabled={(existError) ? true : false}
                    label="Guardar"
                    loading={document?.isSaving || document?.isLoading}
                    className="sig-button sig-primary mt-4"
                />
              }
          </div>
      </form>
      <ModalPositions displayModal={displayModalPosition}  setDisplayModal={setDisplayModalPosition}  />
      <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={onHide}
          reject={reject}
      />
      <ConfirmDialog
          visible={visibleDelete}
          onHide={() => setVisibleDelete(false)}
          message="¿Está seguro que desea eliminar el área?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={onDelete}
          reject={rejectDelete}
      />
    </Dialog>
  )
}

export default ModalNodes