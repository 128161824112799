
import moment from 'moment';
import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from "core/store/actions/ToastAction";

import { DocumentConstants } from "features/document/commons/DocumentConstants";
import { DocumentLibrariesService } from 'features/document/services/DocumentLibraryServices';
import FileSaver from 'file-saver';

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: DocumentConstants.Accion.DocumentLibrary.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.title) pines.push({ field: 'title', value: advancedFilter.filter.title });
    if (advancedFilter.filter.fileName) pines.push({ field: 'fileName', value: advancedFilter.filter.fileName });
    if (advancedFilter.filter.code) pines.push({ field: 'code', value: advancedFilter.filter.code });
    if (advancedFilter.filter.users) pines.push({ field: 'users', value: advancedFilter.filter.users.fullName });
    if (advancedFilter.filter.itemType) pines.push({ field: 'itemType', value: advancedFilter.filter.itemType.description });
    if (advancedFilter.filter.formatFile) pines.push({ field: 'formatFile', value: advancedFilter.filter.formatFile.description });
    if (advancedFilter.filter.fileStatus) pines.push({ field: 'fileStatus', value: advancedFilter.filter.fileStatus.description });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });

    advancedFilter.values = pines;

    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setDocuments = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.SEARCH_DOCUMENT_LIBRARIES,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const listFilesFolders = getState().documentLibraryReducer.listFilesFolders;
    setDocuments({
      dispatch: dispatch,
      isLoading: true,
      data: listFilesFolders.data,
    });

    const criteria = {};
    const filter = getState().documentLibraryReducer.advancedFilter.filter;
    
    criteria.title = filter.title;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;
    criteria.fileName = filter.fileName;
    criteria.code = filter.code;
    criteria.ownerId = filter.users?.id;
    criteria.itemTypeId = filter.itemType?.id;
    criteria.formatFile = filter.formatFile?.id;
    criteria.statusId = filter.fileStatus?.id;

    DocumentLibrariesService.searchDocumentLibraries(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setDocuments({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          setDocuments({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Gestión Documental', data.message));
        }else {
          setDocuments({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Gestión Documental', data.message));
        }
      },
    ).catch((error)=>{
    });
  };
};


const generateReport = (payload) => {
  const setDocuments = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setDocuments({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().documentLibraryReducer.advancedFilter.filter;

    criteria.title = filter.title;
    // criteria.toDate = filter.to;
    // criteria.fromDate = filter.from;

    DocumentLibrariesService.generateReport(criteria)
      .then(({ data }) => {
        setDocuments({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Gestión Documental.xls');
      })
      .catch((err) => {
        setDocuments({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Gestión Documental', 'Hubo un error al descargar el archivo'));
      });
  };
};

const uploadFile = (archivo) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentFile }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.UPLOAD_FILE,
      payload: {
        isSaving,
        isSaved,
        // currentFile
      },
    });
  };

  return (dispatch, getState) => {
    const currentFile = getState().documentLibraryReducer.document;

    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
      // currentInstitution,
    });
    DocumentLibrariesService.uploadFile(archivo).then(
      ( {data} ) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gestión Documental', 'El archivo se subió correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentFile });
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL)
        {
          dispatch(toastAction.error('Gestión Documental', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentFile });
        }
        else
        {
          dispatch(toastAction.error('Gestión Documental', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }
      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    );
  };
};

const uploadandApproveFile = (archivo) => {
  const isLoading = ({ dispatch, isSaving, isSaved }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.UPLOAD_FILE,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    const currentFile = getState().documentLibraryReducer.document;

    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
     });
    DocumentLibrariesService.uploadandApproveFile(archivo).then(
      ( {data} ) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gestión Documental', 'El archivo fue grabó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentFile });
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Gestión Documental', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentFile });
        }
        else
        {
          dispatch(toastAction.error('Gestión Documental', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }
      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    );
  };
};

const getDataListFolders = () => {
  const setListFolders = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.GET_DATA_LIST_FOLDERS,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setListFolders({ dispatch: dispatch, isLoading: true });

    DocumentLibrariesService.getDataListFolders()
      .then(({ data }) => {
        setListFolders({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setListFolders({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error al descargar el archivo'));
      });
  }
};

const getDataListFilesAndFolders = (path) => {
  const setListFilesFolders = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.GET_DATA_LIST_FILES_AND_FOLDERS,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setListFilesFolders({ dispatch: dispatch, isLoading: true });

    DocumentLibrariesService.getDataListFilesAndFolders(path)
      .then(({ data }) => {
        setListFilesFolders({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setListFilesFolders({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error al descargar el archivo'));
      });
  }
};

const getDocumentById = (institutionId) => {
  const setDocument = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.GET_DOCUMENT_LIBRARY_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setDocument({ dispatch: dispatch, isLoading: true });
    DocumentLibrariesService.getDocumentLibraryById(institutionId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          // const document = data.data.document;
          // document.dynamicFieldValues = data.data?.dynamicData
          setDocument({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setDocument({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Gestión Documental', data.message));
        }
      },
      () => {
        setDocument({ dispatch: dispatch, isLoading: false });
      }
    );
  };
};

const getDocumentForVersion = (id, versionNumber) => {
  const setDocument = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.GET_DOCUMENT_LIBRARY_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setDocument({ dispatch: dispatch, isLoading: true });
    DocumentLibrariesService.getDocumentForVersion(id, versionNumber).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setDocument({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setDocument({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Gestión Documental', data.message));
        }
      },
      () => {
        setDocument({ dispatch: dispatch, isLoading: false });
      }
    );
  };
};


const deleteDocument = (id, fileName) => {

  return (dispatch, getState) => {

    DocumentLibrariesService.deleteDocumentLibrary(id, fileName).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gestión Documental', 'El archivo se eliminó correctamente'));
          dispatch({ type: DocumentConstants.Accion.DocumentLibrary.DELETE_DOCUMENT_LIBRARY, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Gestión Documental', data.message));
          dispatch({ type: DocumentConstants.Accion.DocumentLibrary.DELETE_DOCUMENT_LIBRARY, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: DocumentConstants.Accion.DocumentLibrary.DELETE_DOCUMENT_LIBRARY, payload: { isDeleted: false } });
      }
    );
  };
};

const copyDocument = (dataPath) => {
  const setDocument = ({ dispatch, isUpdated }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.COPY_DOCUMENT_LIBRARY,
      payload: { isUpdated },
    });
  };

  return (dispatch) => {
    DocumentLibrariesService.copyFile(dataPath).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setDocument({ dispatch, isUpdated: true });
          dispatch(toastAction.success('Gestión Documental', 'El archivo se copió correctamente'));
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setDocument({ dispatch, isUpdated: false });
          dispatch(toastAction.warn('Gestión Documental', data.message));
        }
      }
    );
  };
};

const moveDocument = (dataPath) => {
  const setDocument = ({ dispatch, isUpdated }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.MOVE_DOCUMENT_LIBRARY,
      payload: { isUpdated },
    });
  };

  return (dispatch) => {
    DocumentLibrariesService.moveFile(dataPath).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setDocument({ dispatch, isUpdated: true });
          dispatch(toastAction.success('Gestión Documental', 'El archivo se movió correctamente'));
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setDocument({ dispatch, isUpdated: false });
          dispatch(toastAction.warn('Gestión Documental', data.message));
        }
      }
    );
  };
};

const renameDocument = (dataName) => {
  const setDocument = ({ dispatch, isUpdated }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.RENAME_DOCUMENT_LIBRARY,
      payload: { isUpdated },
    });
  };

  return (dispatch) => {
    DocumentLibrariesService.renameFile(dataName).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setDocument({ dispatch, isUpdated: true });
          dispatch(toastAction.success('Gestión Documental', 'El archivo se renombró correctamente'));
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setDocument({ dispatch, isUpdated: false });
          dispatch(toastAction.warn('Gestión Documental', data.message));
        }
      }
    );
  };
};

const getRecentFiles = () => {
  const setListFolders = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.GET_RECENT_FILES,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setListFolders({ dispatch: dispatch, isLoading: true });

    DocumentLibrariesService.getRecentFiles()
      .then(({ data }) => {
        setListFolders({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setListFolders({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error al descargar el archivo'));
      });
  }
};

const getCurrentFiles = () => {
  const setListFolders = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.GET_CURRENT_FILES,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setListFolders({ dispatch: dispatch, isLoading: true });

    DocumentLibrariesService.getCurrentFiles()
      .then(({ data }) => {
        setListFolders({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setListFolders({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error al descargar el archivo'));
      });
  }
};

const getMyFiles = () => {
  const setListFolders = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.GET_MY_FILES,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setListFolders({ dispatch: dispatch, isLoading: true });

    DocumentLibrariesService.getMyFiles()
      .then(({ data }) => {
        setListFolders({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setListFolders({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error al descargar el archivo'));
      });
  }
};
const getTrashFiles = () => {
  const setListFolders = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.GET_TRASH_FILES,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setListFolders({ dispatch: dispatch, isLoading: true });

    DocumentLibrariesService.getTrashFiles()
      .then(({ data }) => {
        setListFolders({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setListFolders({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error al descargar el archivo'));
      });
  }
};

const getDataMaster = () => {
  const setDataMaster = ({ dispatch, isLoading, users, itemType, formatFile, fileStatus, workflowDocumentTypes, dynamicFields }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.GET_DATA_MASTER,
      payload: { isLoading, users, itemType, formatFile, fileStatus, workflowDocumentTypes, dynamicFields },
    });
  };

  return (dispatch) => {
    setDataMaster({ dispatch: dispatch, isLoading: true, users: null, itemType: null, formatFile: null, fileStatus: null, workflowDocumentTypes: [], dynamicFields: [] });

    DocumentLibrariesService.getDataMaster().then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            users: data.data.users,
            itemType: data.data.itemType,
            formatFile: data.data.formatFile,
            fileStatus: data.data.fileStatus,
            workflowDocumentTypes: data.data.workflowDocumentTypes,
            dynamicFields: data.data.dynamicFields,
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            users: null,
            itemType: null,
            formatFile: null,
            fileStatus: null,
            workflowDocumentTypes: [],
            dynamicFields: []
          });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            users: null,
            itemType: null,
            formatFile: null,
            fileStatus: null,
            workflowDocumentTypes: [],
            dynamicFields: []
          });
          dispatch(toastAction.warn('Gestión Documental', data.message));
        }
      },
      () => {
        setDataMaster({ dispatch: dispatch, isLoading: false, users: null, itemType: null, formatFile: null, fileStatus: null, workflowDocumentTypes: [], dynamicFields: [] });
      }
    );
  };
};


const isStorageEnabled = () => {
  const setIsStorageEnabled = ({ dispatch, isLoading, isEnabled }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.IS_STORAGE_ENABLED,
      payload: { isLoading, isEnabled },
    });
  };
  return (dispatch) => {
    setIsStorageEnabled({ dispatch: dispatch, isLoading: true });

    DocumentLibrariesService.isStorageEnabled()
      .then(({ data }) => {
        setIsStorageEnabled({ dispatch: dispatch, isLoading: false, isEnabled: data.data});
      })
      .catch((err) => {
        setIsStorageEnabled({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error, inténtelo despues'));
      });
  }
};

const generateVersion = (reason, currentId) => {
  const isLoading = ({ dispatch, isLoading, isSaving, isSaved, data }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.GENERATE_VERSION,
      payload: { isLoading, isSaving, isSaved, data },
    });
  };
  return (dispatch, getState) => {
    isLoading({ dispatch: dispatch, isSaving: true, isSaved: false});
    DocumentLibrariesService.generateVersion(reason).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Versión', 'La versión se generó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, data: data.data});
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL)
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        else
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    );
  };
};

const approvedDocument = (data) => {
  const isLoading = ({ dispatch, isLoading, isSaved }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.APPROVED_DOCUMENT,
      payload: { isLoading, isSaved },
    });
  };

  return (dispatch, getState) => {
    isLoading({ dispatch: dispatch, isLoading: true, isSaved: false});
    DocumentLibrariesService.approvedDocument(data).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Documento', 'El documento se aprobó correctamente'));
          isLoading({ dispatch: dispatch, isLoading: false, isSaved: true });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Documento', data.message));
          isLoading({ dispatch: dispatch, isLoading: false, isSaved: false });
        }else{
          dispatch(toastAction.error('Documento', data.message));
          isLoading({ dispatch: dispatch, isLoading: false, isSaved: false });
        }
      },
      () => {
        isLoading({ dispatch: dispatch, isLoading: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};

const rejectDocument = (data) => {
  const isLoading = ({ dispatch, isLoading, isSaved }) => {
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.REJECT_DOCUMENT,
      payload: { isLoading, isSaved },
    });
  };

  return (dispatch, getState) => {
    isLoading({ dispatch: dispatch, isLoading: true, isSaved: false});
    DocumentLibrariesService.rejectDocument(data).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Documento', 'El documento se rechazó correctamente'));
          isLoading({ dispatch: dispatch, isLoading: false, isSaved: true});
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Documento', data.message));
          isLoading({ dispatch: dispatch, isLoading: false, isSaved: false });
        }else{
          dispatch(toastAction.error('Documento', data.message));
          isLoading({ dispatch: dispatch, isLoading: false, isSaved: false });
        }
      },
      () => {
        isLoading({ dispatch: dispatch, isLoading: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};

 const removeFileReducer = () => {
  return (dispatch, getState)=>{
    dispatch({
      type: DocumentConstants.Accion.DocumentLibrary.REMOVE_FILE_DOCUMENT
    })
  }
 }


export const DocumentLibraryAction = {
  toggleAdvancedFilter,
  removeFileReducer,
  getDocumentForVersion,
  updateFilter,
  uploadFile,
  uploadandApproveFile,
  resetAfterSaved,
  // uploadandApproveFile,
  generateReport,
  search,
  generateVersion,
  getDataListFolders,
  getDataListFilesAndFolders,
  getDocumentById,
  deleteDocument,
  copyDocument,
  moveDocument,
  renameDocument,
  getRecentFiles,
  getCurrentFiles,
  getMyFiles,
  getTrashFiles,
  getDataMaster,
  isStorageEnabled,
  approvedDocument,
  rejectDocument
}