import { Aprobacion } from 'features/processManagement/components/Aprobacion';
import { ButtonMultipleSend } from 'features/processManagement/components/ProceseSheet/ButtonMultipleSend';
import { ModalGenerateVersion } from 'features/processManagement/components/ProceseSheet/ModalGenerateVersion';
import { RrhhConstants } from 'features/rrhh/commons/RrhhConstants';
import useFilePeople from 'features/rrhh/hooks/employees/useFilePeople';
import { CustomFieldActions } from 'features/rrhh/store/actions/CustomFieldActions';
import { EmployeesAction } from 'features/rrhh/store/actions/EmployeesAction';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { VersionHistory } from 'shared/components/processManagement/VersionHistory';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { CustomField } from '../../components/employee/CustomField';
import { ModalSelectFlow } from 'shared/components/ModalSelectFlow/ModalSelectFlow';

const flows = [
  {
    id: 1,
    name: 'Flujo 1'
  },
  {
    id: 2,
    name: 'Flujo 2'
  },
  {
    id: 3,
    name: 'Flujo 3'
  },
]

export const FilePeople = ({disabled, title, subtitle, description, previousUrl}) => {
  const { updateTitle } = useSetTitlePage();
  const dispatch = useDispatch()
  const history = useHistory()

  const [dataExtraModal, setDataExtraModal] = useState([])
  const [visibleModalVersion, setVisibleModalVersion] = useState(false)
  const [mnsjValidate, setMnsjValidate] = useState([])
  const [visible, setVisible] = useState(false);
  const [displayFlowModal, setDisplayFlowModal] = useState(false)
  const querys = new URLSearchParams(useLocation().search)
  const modeview = querys.get("modeview") === "readonly"
  const id = querys.get("id")

  const historyVersions = useSelector(state => state.customFieldReducer.versionList.data)
  const listControls = useSelector(state => state.customFieldReducer.listControls.data)
  const producedBy = useSelector(state => state.customFieldReducer.listResponsibles?.producedBy)
  const dataMaster = useSelector(state => state.customFieldReducer.listResponsibles)
  // const { isSaving } = useSelector(state => state.customFieldReducer.currentVersion)
  const lastFilePeople = useSelector(state => state.customFieldReducer.getLastFilePeople)
  const filePeople = useSelector(state => state.customFieldReducer.filePeople)
  const listVersions = useSelector(state => state.customFieldReducer.listVersions)
  const generateVersion = useSelector(state => state.customFieldReducer.generateVersion)

  useEffect(()=> {
    if(generateVersion.isSaved){
      dispatch(CustomFieldActions.getLastFilePeople(RrhhConstants.Accion.Employees.GET_TYPE_PROCESS))
      setVisibleModalVersion(false)
    }
  },[generateVersion.isSaved])

  useEffect(()=> {
    if(filePeople.isSaved){
      history.push('/rrhh/personas')
    }
  },[filePeople.isSaved])


  useEffect(() => {
    updateTitle({
      title: title,
      subtitle : subtitle,
      description: description,
      previousUrl: previousUrl
    });
    dispatch(CustomFieldActions.getListResponsibles({
      "producedBy": true,
      "responsible": true,
    }))
    if(!listControls){
      dispatch(CustomFieldActions.getListControls(RrhhConstants.Accion.Employees.GET_TYPE_PROCESS))
    }
    if(id){
      dispatch(CustomFieldActions.getFilePeopleById({id: Number(id), typeProcess: RrhhConstants.Accion.Employees.GET_TYPE_PROCESS}))
    }else{
      dispatch(CustomFieldActions.getLastFilePeople(RrhhConstants.Accion.Employees.GET_TYPE_PROCESS))
    }
  }, []);

  useEffect(()=> {
    if(lastFilePeople.data && listControls){
      setValue('version', lastFilePeople.data?.versionNumber)
      setValue('producedBy', lastFilePeople.data?.producedById)
      setValue('dateElaboration', lastFilePeople.data?.dateElaboration)
      setValue('modifyDate', lastFilePeople.data?.modifyDate)
      setValue('modifyName', lastFilePeople.data?.modifyName)
      chargeData()
      dispatch(CustomFieldActions.getVersionsDetailedList(lastFilePeople.data?.processId, lastFilePeople.data?.versionNumber))
    }
  },[lastFilePeople.data, listControls])

  const redirectToSearch = () => {
    history.push("/rrhh/personas");
  }

  const {
    control,
    errors,
    valuesFields,
    existError,
    setValue,
    handleSubmit,
    validateLength,
  } = useFilePeople()

  const reject = () => {
    setVisible(false);
  };

  const chargeData = () => {
    setDataExtraModal(lastFilePeople.data?.section?.controls?.map(el=> {
      return {
        id: el.id,
        text: el.text,
        identifier: el.identifier,
        controlId: el.controlId,
        required: el.required,
        collapse: disabled,
        typeControl: (listControls.find(item => item.id===el.controlId)).name,
        icon: (listControls.find(item => item.id===el.controlId)).icon,
        errorText: false,
        errorDrop: false,
        errorIdentifier: false,
        details: el.details.map(elem=> {
          return {
            id: elem.id,
            mode: "edit",
            text: elem.text
          }}),
        mode: "edit",
        visibleButton: listControls?.some(item => item.id===el.controlId && (item.code === "SELECCION_SIMPLE" || item.code === "SELECCION_MULTIPLE"))
      }
    }))
  }


  const onSubmit = (dataSubmit, typeSend) => {
    let validate = false;
    let mnsjs = [...mnsjValidate]
    let data = [...dataExtraModal].map(el => {
      el.collapse = false
      if(el.text===""){
        validate = true
        if(!mnsjs.some(est => est==='Debes especificar un título para el campo.')){
            mnsjs.push('Debes especificar un título para el campo.')
        }
        el.errorText = true
        el.collapse = true
      }
      if(!el.controlId){
        validate = true
        if(!mnsjs.some(est => est==='Debes especificar el tipo de control.')){
            mnsjs.push('Debes especificar el tipo de control.')
        }
        el.errorDrop = true
        el.collapse = true
      }
      if(el.typeControl === 'Selección Simple' || el.typeControl === 'Selección Múltiple'){
        if(el.details.length>0){
          el.details.map((item)=> {
            if((item.text.trim())===""){
              validate=true
              el.collapse = true
              if(!mnsjs.some(est => est==='Los nombres de las opciones son requeridas.')){
                mnsjs.push('Los nombres de las opciones son requeridas.')
              }
            }
          })
        }else{
          validate=true
          el.collapse = true
          if(!mnsjs.some(est => est==='El control debe tener mínimo una opción.')){
            mnsjs.push('El control debe tener mínimo una opción.')
          }
        }
      }
      return el
    })
    if(validate===false){
      if(dataExtraModal.length!==0){
        const sendData = {
          id: lastFilePeople.data?.processId ? lastFilePeople.data?.processId : null,
          producedById: dataSubmit.producedBy,
          processType: RrhhConstants.Accion.Employees.GET_TYPE_PROCESS,
          dateOfElaboration: dataSubmit.dateElaboration,
          section: {
            id: lastFilePeople.data?.section?.id ? lastFilePeople.data?.section?.id : null,
            controls: dataExtraModal?.map((item,index) => {
              return {
                id: item.mode==="edit" ? item.id : null,
                text: item.text,
                position: index + 1,
                prefix: null,
                sufix: null,
                readonly: false,
                showHeader: false,
                startSequence: null,
                identifier: item.identifier,
                required: item.required,
                errorText: false,
                errorDrop: false,
                errorIdentifier: false,
                controlId: item.controlId,
                details: item.details?.map(el => {
                  return {
                    id: el.mode==="edit" ? el.id : null,
                    text: el.text,
                    controlId: null,
                    details: []
                  }
                })
              }
            })
          }
        }
        //cambiar cuando existan los demás endpoints
        if(typeSend===1){
          dispatch(CustomFieldActions.saveFilePeople(sendData))
        }else if(typeSend===2){ //guardar y aprobar
          // console.log(dataSubmit)
          sendData.workflowId = dataSubmit.flowId
          dispatch(CustomFieldActions.saveAndApproved(sendData))
        }

      }else{
        // mnsjs.push()
        setMnsjValidate(['Debe existir mínimo un control.'])
      }
    }else{
      setMnsjValidate(mnsjs)
      setDataExtraModal(data)
    }

  }

  const onSubmitGenerateVersion = (value) => {
    dispatch(CustomFieldActions.generateVersion({reason: value, processType: RrhhConstants.Accion.Employees.GET_TYPE_PROCESS}))
  }

  const items = [
    {
        label: 'Guardar',
        icon: "pi pi-save",
        type: "submit",
        command: ()=>{
          handleSubmit((e)=>{onSubmit(e,1)})()
        }
    },
    {
      label: 'Guardar y Aprobar',
      icon: "pi pi-upload",
      command: ()=>{
        // handleSubmit((e)=>{onSubmit(e,2)})()
        setDisplayFlowModal(true)
      }
  }
  ];

  const onSubmitApprove = () => {
    handleSubmit((e)=>{onSubmit(e,2)})()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
    <div className='grid grid-cols-10 gap-2'>
      <div className='col-span-8'>
          <CustomField disabled={disabled} mnsjValidate={mnsjValidate} setMnsjValidate={setMnsjValidate} listOptions={listControls} dataExtraModal={dataExtraModal} setDataExtraModal={setDataExtraModal} isLoading={lastFilePeople.isLoading}/>
      </div>
      <div className='col-span-2'>
        <Panel header="DATOS GENERALES">
              {
                lastFilePeople.data?.statusName&&<div><p><strong>Estado: {lastFilePeople.data.statusName}</strong></p></div>
              }
                <span className="p-float-label w-full mt-2">
                          <p className='text-sm'>Fecha de Elaboración *</p>
                          <Controller
                              control={control}
                              render={({
                                  field: { onChange, value },
                              }) => (
                                <Calendar
                                value={valuesFields.dateElaboration!== null&&new Date(valuesFields.dateElaboration)}
                                onChange={onChange}
                                disabled={disabled}
                                className={`${errors?.dateElaboration ? 'p-invalid w-full' : 'w-full'}`}
                                showIcon />
                              )}
                              name="dateElaboration"
                              rules={{
                                  required: 'La fecha de elaboración es requerida.',
                              }}
                          />
                </span>
                <span className="p-float-label w-full mt-4">
                          <p className='text-sm'>Elaborado Por *</p>
                          <Controller
                              control={control}
                              render={({
                                  field: { onChange, onBlur, value },
                                  fieldState: { invalid, isTouched, isDirty, error },
                              }) => (
                                <Dropdown
                                  optionLabel='fullName'
                                  value={producedBy?.find(elem => elem.id===value)}
                                  options={producedBy}
                                  onChange={(e) => { onChange(e.value.id)}}
                                  disabled={disabled}
                                  className={`${errors?.producedBy ? 'p-invalid w-full' : 'w-full'}`}
                                />
                              )}
                              name="producedBy"
                              rules={{
                                  required: 'Encargado de la elaboración es requerido.',
                              }}
                          />
                </span>
                <span className="p-float-label w-full mt-4">
                  <p className='text-sm'>Ultima Actualización</p>
                  <Controller
                      control={control}
                      render={({
                          field: { onChange, onBlur, value },
                          fieldState: { invalid, isTouched, isDirty, error },
                      }) => (
                        <Calendar id="modifyDate"
                        name='modifyDate'
                        value={valuesFields.modifyDate!== null&&new Date(valuesFields.modifyDate)}
                        onChange={onChange}
                        disabled
                        className={errors?.modifyDate ? 'p-invalid w-full disabled' : 'w-full disabled'}
                        />
                      )}
                      name="modifyDate"
                  />
                  </span>
                  <span className="p-float-label w-full mt-4">
                          <p className='text-sm'>Actualizado Por</p>
                          <Controller
                              control={control}
                              render={({
                                  field: { onChange, onBlur, value },
                                  fieldState: { invalid, isTouched, isDirty, error },
                              }) => (
                                <InputText
                                    type="text"
                                    id="modifyName"
                                    name="modifyName"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    className={errors?.modifyName ? 'p-invalid w-full disabled' : 'w-full disabled'}
                                    disabled
                                />
                              )}
                              name="modifyName"
                          />
                </span>
                <span className="p-float-label w-full mt-4">
                          <p className='text-sm'>Versión</p>
                          {
                            disabled===false?(<Controller
                              control={control}
                              render={({
                                  field: { onChange, onBlur, value },
                                  fieldState: { invalid, isTouched, isDirty, error },
                              }) => (
                                <Dropdown
                                  optionLabel="versionNumber"
                                  value={listVersions?.data?listVersions.data?.find(elem => elem?.versionNumber===value):null}
                                  options={listVersions?.data?listVersions?.data:null}
                                  isLoading={listVersions.isLoading || lastFilePeople.isLoading}
                                  onChange={(e) => {
                                    onChange(e.value.id)
                                    dispatch(CustomFieldActions.getFilePeopleById({id: e.value.id, typeProcess: RrhhConstants.Accion.Employees.GET_TYPE_PROCESS}))
                                  }}
                                  disabled={disabled}
                                />
                              )}
                              name="version"
                          />):(<Controller
                            control={control}
                            render={({
                                field: { onChange, onBlur, value },
                                fieldState: { invalid, isTouched, isDirty, error },
                            }) => (
                              <InputText
                                value={value}
                                disabled={true}
                              />
                            )}
                            name="version"
                        />)
                          }
                </span>
                {
                  (lastFilePeople.data?.statusName==="Publicado"&&disabled===false)&&(<span className="flex justify-center w-full mt-6">
                    <Button
                    label='Generar Versión'
                    type='button'
                    className='sig-button sig-primary'
                    loading={generateVersion.isLoading}
                    onClick={(e)=> {
                      setVisibleModalVersion(true)
                    }}
                    />
                  </span>)
                }
        </Panel>
      </div>
      {
          historyVersions.length > 0 &&
          <VersionHistory className='h-full col-span-12 md:col-span-8' historyVersions={historyVersions}/>
        }
        {
          (mnsjValidate?.length>0||Object.entries(errors).length>0)&&(<div className='message error col-span-10'>
            <ul>
              {
                mnsjValidate?.map((ele, ind) => <li className="p-error" key={ind}>{ele}</li>)
              }
              {
                errors?.dateElaboration&&<li className="p-error">{errors?.dateElaboration.message}</li>
              }
              {
                errors?.producedBy&&<li className="p-error">{errors?.producedBy.message}</li>
              }
            </ul>
          </div>)
        }
        <div className="col-span-10 flex justify-end gap-4 mt-3">
                  {
                    (disabled) ? (<Aprobacion id={id} source="ficha" modeview={modeview}/>) :(<div className='mr-3'>
                    <Button
                      icon="pi pi-times"
                      type="button"
                      label="Cancelar"
                      loading={filePeople.isSaving}
                      onClick={() => { setVisible(true); }}
                      className="sig-button sig-secondary mt-4 mr-3"
                    />
                  <ButtonMultipleSend 
                    items={items} 
                    disabled={lastFilePeople.data?.isReadOnly} 
                    isLoading={filePeople.isSaving}
                  />
                </div>)
                }
      </div>
      <ModalGenerateVersion visibleModalVersion={visibleModalVersion} setVisibleModalVersion={setVisibleModalVersion} onSubmitValue={onSubmitGenerateVersion} isLoading={generateVersion.isLoading}/>
    </div>
    <ModalSelectFlow 
      title={"Seleccione el flujo"}
      description={"Seleccione el flujo al que desea enviar el documento"}
      setDisplayModal={setDisplayFlowModal}
      displayModal={displayFlowModal}
      flows={dataMaster.workflowDocumentTypes}
      onSubmitApprove={onSubmitApprove}
      setValueField={setValue}
      field="flowId"
    />
    <ConfirmDialog
            visible={visible}
            onHide={() => setVisible(false)}
            message="¿Está seguro que desea cancelar?"
            header="Cancelar"
            icon="pi pi-exclamation-triangle"
            accept={redirectToSearch}
            reject={reject}
        />
    </form>
  )
}