import { EmployeesAction } from 'features/rrhh/store/actions/EmployeesAction'
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
const useFilter = () => {
  const dispatch = useDispatch();
  const fieldName = useRef(null)
  const { values , showModal  } = useSelector((state) => state.employeeReducer.employeeFilter);
  const { loading } = useSelector((state) => state.employeeReducer.employees);

  const reportLoading = useSelector(
    (state) => state.employeeReducer.downloadReport.loading
  );
  const filterEmployees = () => {
    const value = fieldName.current.value;
    if (value && value.length >= 3) {
      dispatch(EmployeesAction.setFilterValues({values:[{ field: 'names', value }]}));
      filterNames();
    }
  };


  const openModal = () => {
    dispatch(EmployeesAction.toggleModalFilters({ showModal: true }));
  };
  const closeModal = () =>{
    dispatch(EmployeesAction.toggleModalFilters({ showModal: false }));

  }

  const handleChange = () => {
    if (fieldName.current.value === "") {
      filterNames();
      dispatch(EmployeesAction.removeFilterValues('names'));
    }
  };


  const showPiners = () => {
    if (values.length > 0) return true;
    else return false;
  };

  const buildFields = () => {
    if(fieldName.current!==null){
      const value = fieldName.current.value;
      const fields = {};
      fields.names = value;
      if (values.length > 0) {
        const fieldTo = values.filter((val) => val.field === 'to');
        if (fieldTo.length > 0) fields.to = fieldTo[0].date;
        else fields.to = null;
        const fieldFrom = values.filter((val) => val.field === 'from');
        if (fieldFrom.length > 0) fields.from = fieldFrom[0].date;
        else fields.from = null;
      }
      if (!fields.names) delete fields.names;
      return fields;
    }
  };

  const filterNames = () => {
    dispatch(EmployeesAction.getEmployees({ change: true, fields: buildFields() }));
  };

  const removePiner = (field) => {
    if (field === 'names') fieldName.current.value = null;
    dispatch(EmployeesAction.removeFilterValues(field));
  };

  const generateReport = () => {
    dispatch(EmployeesAction.generateRoleReport(buildFields()));
  };

  return {
    fieldName,
    handleChange,
    openModal,
    removePiner,
    showPiners,
    filterEmployees,
    values,
    generateReport,
    reportLoading,
    loading,
    showModal,
    closeModal
  };
};

export default useFilter;
