import { RadioButton } from 'primereact/radiobutton'
import { Skeleton } from 'primereact/skeleton'
import React from 'react'
import { Controller } from 'react-hook-form'

export const SurveyPublicSectionGrid = ({questions, totalQuestions, control, onChangeWithOther, valuesFields, isLoadingDataMaster}) => {

  if (isLoadingDataMaster) {
    return (
      <Skeleton height='200px'width='100%' />
    )
  }
  return (
    <div className='flex flex-col w-full'>
      <div className='px:0 md:px-5 py-3 border-2 bg-gray-100 w-full overflow-x-auto'>
        {/* Header de fila de opciones */}
        <div className='flex'>
          <div className='flex flex-col items-center justify-center flex-1  p-2 survey-grid-cell bg-gray-100 sticky left-0 z-50' />
          {
            questions[0].options.map((option, index) => (
              <div className='flex flex-col items-center justify-center flex-1 border border-white p-2 survey-grid-cell' key={`header-${index}`}>
                <p className='text-center '>{option.caption}</p>
              </div>
            ))
          }
        </div>
        {
          questions.map((question) => {
            const indexQuestion = totalQuestions?.findIndex(q => q.id === question.id)
            const indexQuestionField = `question_${indexQuestion + 1}`
            return (
            <div className='flex p-float-label' key={`row-${question.id}`}>
              <div className='flex  items-center flex-1 border border-white bg-gray-100 p-2 survey-grid-cell sticky left-0 z-50'>
                <p className=''>{question.body}</p>
              </div>
              {
                question.options.map((option) => {
                  return (
                    <div 
                      className='flex flex-col items-center justify-center flex-1 border border-white p-2 survey-grid-cell' 
                      key={`cell-${option.id}`}
                    >
                      <Controller
                        control={control}
                        name={`${indexQuestionField}`}
                        rules={{ required: {
                          value: question.isRequired,
                          message: 'Esta pregunta es requerida'
                        }}}
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                        }) => (
                          <div key={question.id} className='flex justify-center items-center gap-x-2'>

                              <RadioButton 
                                inputId={question.id} 

                                value={option}
                                onChange={(e) => {
                                  e.preventDefault()
                                  onChangeWithOther(onChange, e.value, `${indexQuestionField}}`, error, indexQuestion + 1 )
                                }}  
                                checked={valuesFields[`${indexQuestionField}`].id === option.id}
                                className={`${error ? 'p-invalid' : ''}`}
                                // key={`radio-${option.id}`}
                              />
                          </div>
                        )}
                      />
                    </div>
                  )
                })
              }
            </div>
          )})
        }
      </div>
    </div>
  )
}
