//table participantes
const attendanceColumns = [
  { field: "StudentId", header: "Apellidos y Nombres" },
  { field: "EnrollmentDate", header: "Fech.Inscripción" },
  { field: "StatusId", header: "Estado" },
];

export const AttendancesTableData = {
  attendanceColumns,
};
