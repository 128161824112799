import { apiService } from 'core/services/ApiService';

const getDataMaster = () => {
  const payload = {
    isRole: true,
    isCompany: true,
    isLocation: true,
    isStatus: true,
    isEmployee: true
  };
  return apiService.Post('management/api/User/GetDataMaster', payload);
};

const userSearch = ({ fields = {}, change }) => {
  let initialFields = {
    page: 1,
    pageSize: 10,
    columnOrder: 'email',
    order: 'asc',
    fromDate: null,
    toDate: null,
    companyId: null,
    locationId: null,
    roleId: null,
    fullName: null
  };
  if (change) initialFields = { ...initialFields, ...fields };
  return apiService.Post('management/api/User/Search', initialFields);
};

const getUserById = (userId) => {
  return apiService.Get(`management/api/User/GetUserById?userId=${userId}`);
};

const saveUser = (payload) => {
  return apiService.Post('management/api/User/SaveUser', payload);
};

const resetPassword = (userId) => {
  return apiService.Get(
    `management/api/User/RequestResetPassword?userId=${userId}`
  );
};

const removeUser = (userId) => {
  return apiService.Get(`management/api/User/RemoveUser?userId=${userId}`);
};

const generateReport = (payload) => {
  return apiService.Post('management/api/User/GenerateReport', payload, {
    responseType: 'blob',
  });
};

export const UserService = {
  getDataMaster,
  userSearch,
  generateReport,
  getUserById,
  saveUser,
  resetPassword,
  removeUser,
};
