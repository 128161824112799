import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Controller } from "react-hook-form"

export const GenerateSnapshot = ({ visible, close, name, control, errors, onSubmit, loadingReport }) => {

  return(
    <Dialog header='GUARDAR PLANIFICACIÓN' visible={visible} onHide={close} style={{width: 650}}>
      <p className="mt-6">Se va a generar una nueva planificación para el proyecto:</p>
      <p>{name}</p>
      <Controller 
        control={control}
        name='name'
        rules={{required: 'El nombre es requerido'}}
        render={({field: {value, onChange}, fieldState: {error}}) => (
          <span className="p-float-label mt-6">
            <InputText
              autoFocus
              value={value}
              onChange={onChange}
              className={error ? 'p-invalid w-full' : 'w-full'}
            />
            <label>Ingrese nombre para la nueva planificación</label>
          </span>
        )}
      />
      {
        errors.name && 
        <div className='message error'>
          <ul>
            <li className="p-error">{errors.name.message}</li>
          </ul>
        </div>
      }
      <div className="flex justify-end gap-4 mt-4">
        <Button
          icon="pi pi-times"
          type="button"
          onClick={close}
          label="Cancelar"
          loading={loadingReport}
          className="sig-button sig-secondary"
          style={{maxHeight:"2rem"}}
          />
        <Button
          icon="pi pi-save"
          type="button"
          label="Guardar Planificación"
          onClick={onSubmit}
          loading={loadingReport}
          className="sig-button sig-primary"
          style={{maxHeight:"2rem"}}
        />
      </div>
    </Dialog>
  )
}