
import useSurveyQuestionGrid from 'features/survey/hooks/Survey/useSurveyQuestionGrid';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext'
import React, { useState } from 'react'
import { useSelector } from 'react-redux';

export const SurveyQuestionGrid = ({section, listSections, setListSections, getQuestionTypeRadio, deleteQuestionInSection}) => {

  const [newValue, setNewValue] = useState('')
  const [newAnswer, setNewAnswer] = useState('')
  const { isCloseStatus } = useSelector((state) => state.surveyReducer.survey);

  const { addQuestion, listQuestionGrid, addAnswer, listAnswerGrid, setRequiredQuestions } = useSurveyQuestionGrid(section, isCloseStatus, listSections, setListSections, section.id, getQuestionTypeRadio, deleteQuestionInSection)

  const onAddQuestion = (newValue) => {
    if (isCloseStatus) return
    // addQuestion(newValue)
    addQuestion(newValue)
    setNewValue('')
  }

  const onKeyQuestionHandler = (e, newValue) => {
    if (isCloseStatus) return
    if (e.key === 'Enter') {
      e.preventDefault();
      onAddQuestion(newValue);
    }
  };

  const onAddAnswer = (newAnswer) => {
    if (isCloseStatus) return
    addAnswer(newAnswer)
    setNewAnswer('')
  }

  const onKeyAnswerHandler = (e, newAnswer) => {
    if (isCloseStatus) return
    if (e.key === 'Enter') {
      e.preventDefault();
      onAddAnswer(newAnswer);
    }
  };


  
  
  return (
    <div className='flex flex-col mb-2 bg-white'>
      <div  className='flex justify-between p-2 border-2 border-b-0 border-gray-300 bg-gray-100'>
        <h2 className='font-semibold'>Cuadrícula de Preguntas</h2>
      </div>
      <div className='px-2 py-3 border-2 border-gray-300'>
        <div className='flex items-center my-1 justify-end'>
          <InputSwitch 
            inputId={`isRequired_questions_grid`} 
            disabled={isCloseStatus} 
            name="extraOptions" 
            value={section.listQuestion[0].isRequired} 
            onChange={setRequiredQuestions} 
            checked={section.listQuestion[0].isRequired} 
          />
          <label htmlFor={`isRequired_questions_grid`} className='ml-2'>
            Requerido
          </label>
        </div>
        <div className='grid grid-cols-2 gap-4'>
          <div className='col-span-2 md:col-span-1'>
            <div className='flex justify-center items-center gap-x-1'>
              <span className="p-float-label w-full my-5">
                  <InputText
                      onChange={(e) => setNewValue(e.target.value)}
                      value={newValue}
                      name='newValue'
                      type='text'
                      onKeyPress={(e) => onKeyQuestionHandler(e, newValue)}
                      readOnly={isCloseStatus}
                  />
                  <label htmlFor="newValue">Ingrese una pregunta</label>
              </span>
              <button disabled={isCloseStatus} type='button' onClick={() => onAddQuestion(newValue)} className='h-8 w-8 flex justify-center items-center cursor-pointer'>
                <i className={`pi pi-plus text-xl text-gray-600`}></i>
              </button>
            </div>
            {
              listQuestionGrid()
            }
          </div>
          <div className='col-span-2 md:col-span-1'>
            <div className='flex justify-center items-center gap-x-1'>
              <span className="p-float-label w-full my-5">
                  <InputText
                      onChange={(e) => setNewAnswer(e.target.value)}
                      value={newAnswer}
                      name='newAnswer'
                      type='text'
                      onKeyPress={(e) => onKeyAnswerHandler(e, newAnswer)}
                      readOnly={isCloseStatus}
                  />
                  <label htmlFor="newAnswer">Ingrese una respuesta</label>
              </span>
              <button disabled={isCloseStatus} type='button' onClick={() => onAddAnswer(newAnswer)} className='h-8 w-8 flex justify-center items-center cursor-pointer'>
                <i className={`pi pi-plus text-xl text-gray-600`}></i>
              </button>
            </div>
            {
              listAnswerGrid()
            }
          </div>
        </div>
      </div>
    </div>
  )
}
