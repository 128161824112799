// import PropTypes from 'prop-types';
import { useEffect, Fragment, useRef, useState } from 'react'
import { useHistory } from 'react-router';
import EmployeesTableComponent from 'features/rrhh/components/employee/EmployeesTableComponent';

import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import EmployeesFilterComponent from 'features/rrhh/components/employee/EmployeesFilterComponent';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import EmployeeModalImport from 'features/rrhh/components/employee/modals/EmployeeModalImport';
import { useDispatch } from 'react-redux';
import { EmployeesAction } from 'features/rrhh/store/actions/EmployeesAction';
import { useClaimPeople } from 'features/rrhh/hooks/useClaimPeople';



const PersonasPage = (props) => {
  const history = useHistory();
  const [displayModal, setDisplayModal] = useState(false)
  const optionsButton = useRef(null);
  const dispatch = useDispatch()

  const items = [
    {
        label: 'Descargar Plantilla',
        command: (e) => {
          dispatch(EmployeesAction.downloadTemplate())
        }
    },
    {
        label: 'Cargar Plantilla',
        command: (e) => {
          setDisplayModal(true)
        }
    },
  ];

  const { isSearch, isDownload, isCreate, isImport } = useClaimPeople();

  const { updateTitle } = useSetTitlePage();
  useEffect(() => {
    updateTitle({
      title: 'Gestión de la organización',
      subtitle: 'Empleados',
    });
  }, []);

  useEffect(() =>{
    dispatch(EmployeesAction.getDataMasterSearch())
  },[dispatch])
  
  useEffect(() =>{
    dispatch(EmployeesAction.getEmployees({}))
  },[dispatch])


  
  return (
    <Fragment>
      {
        (isSearch||isDownload)&&(
          <EmployeesFilterComponent />
        )
      }
      {
                (isCreate || isImport)&&(
                  <div className='flex justify-between'>
                  <div>
                    {
                      isCreate&&(
                        <Button
                          icon="pi pi-plus"
                          type="button"
                          label="Nuevo"
                          className={`sig-button sig-dark mt-4`}
                          onClick={() => {
                            history.push('/rrhh/personas/nuevo');
                          }}
                        />
                      )
                    }
                    <Button 
                      label='Importar' className={`sig-button sig-dark ml-3 mt-4 ${isImport?'visible':'invisible'} ${isCreate?'mt-4':''}`}
                      onClick={(event) => optionsButton.current.toggle(event)} 
                    />
                  </div>
                  <div className='p-4'>
                    <i className={`pi pi-cog text-lg cursor-pointer`}  onClick={() => {
                        history.push('/rrhh/personas/ficha-persona');
                      }}/>
                  </div>
                </div>
                )
       }
      <Menu model={items} popup ref={optionsButton} />
      <EmployeeModalImport
        displayModal={displayModal} setDisplayModal={setDisplayModal} title={'Importar Empleado'}
      />
      <EmployeesTableComponent />
    </Fragment>
  );
};


export default PersonasPage;
