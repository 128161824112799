import { useState } from "react"
import { ControlGeneralConfiguration } from "./ControlGeneralConfiguration"
import { InputSwitch } from "primereact/inputswitch"

const AttachmentInput = () => (
  <div className='drag-drop attachment text-center border-2 bg-opacity-25  border-gray-400 h-16 border-dashed bg-gray-400'>
    <div className="h-14">
      <input
        className="absolute m-0 p-0 cursor-pointer outline-none opacity-0"
        style={{height: "100%"}}
        type="file"
      />
      <div className="h-full">
        <div className='flex flex-col justify-center items-center'>
          <i className="pi pi-cloud-download text-2xl opacity-50"></i>
          <h3 className="opacity-50">
            Arrastar y soltar archivos para adjuntar
          </h3>
        </div>
      </div>
    </div>
  </div>
)

export const AttachmentConfiguration = ({question, onChangeOptions, removeField}) => {
  const [required, setRequired] = useState(question.required)
  const [multiple, setMultiple] = useState(question.multiple)
  const changeRequired = (e) => {
    setRequired(e.value)
  }

  const changeMultiple = (e) => {
    setMultiple(e.value)
  }

  const changeOptionsAditional = () => {
    onChangeOptions({field: "required", value: required, id: question.id})
    onChangeOptions({field: "multiple", value: multiple, id: question.id})
  }

  const onCancel = () => {
    setRequired(question.required);
    setMultiple(question.multiple);
  }

  return(
    <ControlGeneralConfiguration
      changeOptionsAditional={changeOptionsAditional}
      onChangeOptions={onChangeOptions}
      question={question}
      onCancel={onCancel}
      render={() => <AttachmentInput />}
      removeField={removeField}
      headerTitle="ADJUNTAR ARCHIVOS"
    >
      <div className='flex items-center mt-4'>
        <InputSwitch checked={required} name="required" onChange={changeRequired} />
        <h5 className='ml-2'>Requerido</h5>
      </div>
      {/* <div className='flex items-center mt-4'>
        <InputSwitch checked={multiple} name="multiple" onChange={changeMultiple} />
        <h5 className='ml-2'>Múltiples Archivos</h5>
      </div> */}
    </ControlGeneralConfiguration>
  )
}