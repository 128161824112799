import { apiService } from "core/services/ApiService";

const getMatrixStructure = (id) => {
  return apiService.Get(`management/api/RiskManagementModel/GetMatrixStructure?identifier=${id}`);
};

const saveMatrix = (data) => {
	return apiService.Post('management/api/RiskManagementModel/SaveMatrix', data)
}

const searchMatrix = (payload) => {
	return apiService.Post('management/api/RiskManagementModel/SearchMatrix', payload)
}

const generateReport = (payload) => {
  return apiService.Post('management/api/RiskManagementModel/GenerateMatrixReport', payload, {
    responseType: 'blob',
  });
};

export const RiskMatrixServices = {
	getMatrixStructure,
	saveMatrix,
	searchMatrix,
	generateReport,
}