import { PositionsAction } from 'features/rrhh/store/actions/PositionsAction';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const useFilter = () => {
  const dispatch = useDispatch();
  const positionsLoading = useSelector(
    (state) => state.positionsReducer.positions.loading
  );
  const reportLoading = useSelector(
    (state) => state.positionsReducer.downloadReport.loading
  );

  const isOpenModal = useSelector(
    (state) => state.positionsReducer.currentAction.isOpen
  );

  const downloadReport = () => {
    dispatch(PositionsAction.generateReport());
  };
  return { positionsLoading, downloadReport, reportLoading, isOpenModal };
};

export default useFilter;
