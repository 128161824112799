import { CoreConstants } from "core/commons/CoreConstants";
import { Calendar } from "primereact/calendar";
import { useAdvancedFilterTasks } from "features/customers/hooks/tasks/useAdvancedFilterTasks";
import { Dropdown } from "primereact/dropdown";
import Message from "shared/components/messages/Message";
import ModalFilterComponent from "shared/components/modal-filter/ModalFilterComponent";

export const AdvancedFilterComponent = () => {
  const {
    dataMaster,
    errors,
    formValues,
    showModal,
    handleChange,
    onToggleModal,
    clearFields,
    onAdvancedFilter,
  } = useAdvancedFilterTasks();

  if (showModal) {
    return (
      <ModalFilterComponent
        closeModal={() => onToggleModal(false)}
        clearFields={clearFields}
        handleSubmit={onAdvancedFilter}
      >
        {errors.length > 0 && <Message messages={errors} status="error" />}
        <div className="flex gap-4 pt-3">
          <div className="w-full">
            <span className="p-float-label">
              <Calendar
                name="fromDate"
                // dateFormat={CoreConstants.Format.DDMMYYYY}
                value={formValues.fromDate}
                onChange={handleChange}
              />
              <label htmlFor="fromDate">Desde</label>
            </span>
          </div>
          <div className="w-full">
            <span className="p-float-label">
              <Calendar
                name="toDate"
                // dateFormat={CoreConstants.Format.DDMMYYYY}
                value={formValues.toDate}
                onChange={handleChange}
              />
              <label htmlFor="toDate">Hasta</label>
            </span>
          </div>
        </div>
        <div className="flex pt-7">
          <div className="w-full">
            <span className="p-float-label">
              <Dropdown
                options={dataMaster.responsibles}
                optionLabel="fullName"
                filter
                filterBy="fullName"
                showClear
                className="w-full"
                name="responsibleId"
                value={formValues.responsibleId}
                onChange={handleChange}
              />
              <label htmlFor="dropdown">Por Responsable</label>
            </span>
          </div>
        </div>
        <div className="flex pt-7">
          <div className="w-full">
            <span className="p-float-label">
              <Dropdown
                options={dataMaster.taskStatus}
                optionLabel="description"
                filter
                filterBy="description"
                showClear
                className="w-full"
                name="isCompleted"
                value={formValues.isCompleted}
                onChange={handleChange}
              />
              <label htmlFor="dropdown">Por Estado</label>
            </span>
          </div>
        </div>
      </ModalFilterComponent>
    );
  } else {
    return null;
  }
};
