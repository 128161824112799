/* eslint-disable import/no-anonymous-default-export */

import { ProjectExpensesConstants } from "features/projects/commons/ProjectExpensesConstants";

const initialState = {
  dataMaster: {
    isLoading: false,
    categories: [], 
    paymentModes: [], 
    types: [], 
    projects: [], 
    dynamicFields: [],
  },
  projectExpense: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false,
    isUpdated: false,
  },
  ProjectExpenses: {
    data: [],
    isLoading: false,
    isLoaded: false,
  },
  comments: {
    data: [],
    isLoading: false,
  },
  comment: {
    isLoading: false,
    isSaved: false,
    isDeleted: false,
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'name',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {}
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ProjectExpensesConstants.Accion.ProjectExpenses.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
    case ProjectExpensesConstants.Accion.ProjectExpenses.UPDATE_FILTER:
      return {
        ...state,
        advancedFilter: state.advancedFilter
      };
    case ProjectExpensesConstants.Accion.ProjectExpenses.RESET_AFTER_SAVED:
      return {
          ...state,
          projectExpense: {
              data: null,
              isSaving: false,
              isSaved: false,
              isLoading: false,
              isDeleted: false,
              isUpdated: false,
          },
        };
    case ProjectExpensesConstants.Accion.ProjectExpenses.DOWNLOAD_REPORT_STATUS:
      return {
          ...state,
          projectExpense: {
              ...state,
              isLoading: action.payload.isLoading,
          },
      };
    case ProjectExpensesConstants.Accion.ProjectExpenses.SEARCH_PROJECT_EXPENSES:
      return {
          ...state,
          ProjectExpenses: {
              isLoading: action.payload.isLoading,
              data: action.payload.data,
              isLoaded: action.payload.isLoaded,
          }
      }; 
    case ProjectExpensesConstants.Accion.ProjectExpenses.GET_PROJECT_EXPENSE_BY_ID:
      return {
        ...state,
        projectExpense: {
            data: action.payload.data,
            isLoading: action.payload.isLoading
        }
      };
    case ProjectExpensesConstants.Accion.ProjectExpenses.SAVE_PROJECT_EXPENSE:
      return {
        ...state,
        projectExpense: {
            isSaving: action.payload.isSaving,
            isSaved: action.payload.isSaved
        }
      };

    case ProjectExpensesConstants.Accion.ProjectExpenses.DELETE_PROJECT_EXPENSE:
      return {
        ...state,
        projectExpense: {
            isDeleted: action.payload.isDeleted
        }
      };

    case ProjectExpensesConstants.Accion.ProjectExpenses.GET_DATA_MASTER:
      return {
        ...state,
        dataMaster: {
          isLoading: action.payload.isLoading,
          categories: action.payload.categories,
          paymentModes: action.payload.paymentModes,
          types: action.payload.types,
          projects: action.payload.projects,
          dynamicFields: action.payload.dynamicFields,
        }
      };
    case ProjectExpensesConstants.Accion.ProjectExpenses.GET_COMMENTS_BY_PROJECT_EXPENSE_ID:
      return {
          ...state,
          comments: {
            data: action.payload.data,
            isLoading: action.payload.isLoading
          },
      };
    case ProjectExpensesConstants.Accion.ProjectExpenses.DELETE_COMMENT:
      return {
          ...state,
          comment: {
            isDeleted: action.payload.isDeleted,
            isLoading: action.payload.isLoading,
          },
      };
    case ProjectExpensesConstants.Accion.ProjectExpenses.ADD_COMMENT:
      return {
          ...state,
          comment: {
            isSaved: action.payload.isSaved,
            isLoading: action.payload.isLoading,
          },
      };
    default: 
    return state;
  }

}