import { apiService } from 'core/services/ApiService';

const sitesSearch = ({ fields = {}, change }) => {
  let initialFields = {
    page: 1,
    pageSize: 10,
    columnOrder: 'name',
    order: 'asc',
    fromDate: null,
    toDate: null,
    name: null,
  };
  fields.statusId = parseInt(fields.statusId)
  if (change) initialFields = { ...initialFields, ...fields };
  return apiService.Post('management/api/Location/Search', initialFields);
};

const removeSite = (locationId) => {
  return apiService.Post('management/api/Location/RemoveLocation?locationId='+locationId)
}

const saveSite = (siteInfo) => {
  delete siteInfo.status
  return apiService.Post('management/api/Location/Save',siteInfo)
}

const getDataMaster = () =>{
  return apiService.Post('management/api/Location/GetDataMaster',{"isStatus":true})
}

const getSiteById = (locationId) =>{
  return apiService.Get('management/api/Location/GetLocationById?locationId='+locationId)
}

const generateReport =( fields ={} ) =>{
  return apiService.Post('management/api/Location/GenerateReport', fields, {
    responseType: 'blob',
  });

}
export const SitesService = {
  sitesSearch,
  removeSite,
  generateReport,
  saveSite,
  getDataMaster,
  getSiteById
};
