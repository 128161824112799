import { useForm } from 'react-hook-form';

const useViewFields = () => {

  const {
    // register,
    setValue,
    handleSubmit,
    control,
    setError,
    watch,
    getValues,
    formState: { errors },
  } = useForm({id: null, savedFiles: [], addedFiles: []});

  const valuesFields = watch();
  const existError = Object.keys(errors).length > 0

  // console.log(valuesFields)

  const validateLength = (value) => {
    if (typeof value === 'string') {
      return value.replace(/<[^>]+>/g, '')?.length;
    } else if (value?.htmlValue) {
      return value.htmlValue.replace(/<[^>]+>/g, '').length;
    }
    return '0';
  };

  return {
    control,
    errors,
    setError,
    valuesFields,
    existError,
    setValue,
    handleSubmit,
    validateLength,
    getValues,
  }
}

export default useViewFields