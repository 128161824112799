/* eslint-disable import/no-anonymous-default-export */
import { MaintenanceConstants } from "features/maintenance/commons/MaintenanceConstans";

const initialState = {
  dataMaster: {
    isLoading: false,
    // academicDegreeParent: null,
    // manager: null
  },
  projectRole: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false,
  },
  projectRoles: {
    isLoading: false,
    data: null,
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: "name",
    order: "asc",
    page: 1,
    pageSize: 10,
    filter: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MaintenanceConstants.Accion.ProjectRole.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload,
        },
      };
    case MaintenanceConstants.Accion.ProjectRole.UPDATE_FILTER:
      return {
        ...state,
        advancedFilter: state.advancedFilter,
      };

    case MaintenanceConstants.Accion.ProjectRole.SEARCH_PROJECT_ROLES:
      return {
        ...state,
        projectRoles: {
          isLoading: action.payload.isLoading,
          data: action.payload.data,
        },
      };
    case MaintenanceConstants.Accion.ProjectRole.GET_PROJECT_ROLE_BY_ID:
      return {
        ...state,
        projectRole: {
          data: action.payload.data,
          isSaving: false,
          isLoading: action.payload.isLoading,
        },
      };

    case MaintenanceConstants.Accion.ProjectRole.SAVE_PROJECT_ROLE:
      return {
        ...state,
        projectRole: {
          data: action.payload.currentProjectRole,
          isSaving: action.payload.isSaving,
          isSaved: action.payload.isSaved,
        },
      };

    case MaintenanceConstants.Accion.ProjectRole.DELETE_PROJECT_ROLE:
      return {
        ...state,
        projectRole: {
          isDeleted: action.payload.isDeleted,
        },
      };

    case MaintenanceConstants.Accion.ProjectRole.RESET_AFTER_SAVED:
      return {
        ...state,
        projectRole: {
          data: null,
          isSaving: false,
          isSaved: false,
          isLoading: false,
          isDeleted: false,
        },
      };

    case MaintenanceConstants.Accion.ProjectRole.GET_DATA_MASTER:
      return {
        ...state,
        dataMaster: {
          projectRoleParent: action.payload.projectRoleParent,
          manager: action.payload.manager,
          isLoading: action.payload.isLoading,
        },
      };

    case MaintenanceConstants.Accion.ProjectRole.DOWNLOAD_REPORT_STATUS:
      return {
        ...state,
        projectRole: {
          ...state,
          isLoading: action.payload.isLoading,
        },
      };

    default:
      return state;
  }
};
