export const contactsAdapter = ( contact ) => {
  return {
    Id: contact.id,
    CustomerId: contact.customerId,
    Name: contact.name,
    PositionId: contact.positionId,
    EmailAddress: contact.emailAddress,
    PhoneNo: contact.phoneNo,
    CustomerCompany: contact.customerCompany,
    CreationUser: contact.creationUser,
    CreationDate: contact.creationDate,
    ModificationUser: contact.modificationUser,
    ModificationDate: contact.modificationDate,
    RowStatus: contact.rowStatus,
  }
}

export const getCompanyByIdAdapter = ( data ) => {
  return {
    Name: data.name,
    BusinessSectorId: data.businessSectorId,
    DepartmentId: data.departmentId,
    CityId: data.cityId,
    DistrictId: data.districtId,
    RegistrationDate: data.registrationDate,
    ContactsList: data.contactsList.map( contact => contactsAdapter(contact)),
    TasksList: data.tasksList,
    ProjectsList: data.projectsList,
    BusinessSector: data.businessSector,
    Department: data.department,
    City: data.city,
    District: data.district,
    Suscription: data.suscription,
    Id: data.id,
    CompanyId: data.companyId,
    DocumentTypeId: data.documentTypeId,
    DocumentNumber: data.documentNumber,
    BusinessLineId: data.businessLineId,
    CountryId: data.countryId,
    Address: data.address,
    PhoneNo: data.phoneNo,
    EmailAddress: data.emailAddress,
    WebPageAddress: data.webPageAddress,
    FacebookProfileAddress: data.facebookProfileAddress,
    LinkedInProfileAddress: data.linkednProfileAddress,
    InstagramProfileAddress: data.instagramProfileAddress,
    DocumentType: data.documentType,
    Company: data.company,
    BusinessLine: data.businessLine,
    Country: data.country,
    CreationUser: data.creationUser,
    CreationDate: data.creationDate,
    ModificationUser: data.modificationUser,
    ModificationDate: data.modificationDate,
    RowStatus: data.rowStatus,
  }
}