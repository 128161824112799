
import ModalAction from 'features/survey/components/SurveyManagement/ModalAction';
import ModalPermissions from 'features/survey/components/SurveyManagement/ModalPermissions';
import SidebarMenu from 'features/survey/components/SurveyManagement/SidebarMenu';
import TableComponent from 'features/survey/components/SurveyManagement/TableComponent';
import React, { Fragment, useEffect, useState } from 'react'
import useSetTitlePage from 'shared/hooks/useSetTitlePage';

const SearchSurveyManagementPage = () => {
  const { updateTitle } = useSetTitlePage();

  const [selectedNode, setSelectedNode] = useState(null);
  
  const [surveySelected, setSurveySelected] = useState(null)
  const [actionSelected, setActionSelected] = useState(null)
  const [displayModalPermissions, setDisplayModalPermissions] = useState(false)
  const [displayModalAction, setDisplayModalAction] = useState(false)

  useEffect(() => {
      updateTitle({
          title: 'Gestión de Encuestas',
          subtitle: 'Administración',
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(selectedNode)
  // console.log(surveySelected)

  return (
    <Fragment>
          <div className='grid grid-cols-12 gap-1 h-96 mt-2'>
            <div className='col-span-5 sm:col-span-3 2xl:col-span-2 flex flex-col py-2 px-3 border-r border-gray-300'>
              <SidebarMenu 
                setSelectedNode={setSelectedNode} 
                setDisplayModal={setDisplayModalAction} 
                setActionSelected={setActionSelected}
                //* selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} 
              />
            </div>
            <div className='col-span-7 sm:col-span-9 2xl:col-span-10 px-3'>
              <TableComponent 
                selectedNode={selectedNode} setSelectedNode={setSelectedNode}
                surveySelected={surveySelected} setSurveySelected={setSurveySelected}  
                setDisplayModalAction={setDisplayModalAction}
                setDisplayModalPermissions={setDisplayModalPermissions} 
                setActionSelected={setActionSelected}
              />
            </div>
          </div>
          <ModalAction 
            displayModal={displayModalAction}  setDisplayModal={setDisplayModalAction} 
            surveySelected={surveySelected} setSurveySelected={setSurveySelected}  
            actionSelected={actionSelected} setActionSelected={setActionSelected}
            selectedNode={selectedNode}
          />
          <ModalPermissions 
            displayModal={displayModalPermissions}  setDisplayModal={setDisplayModalPermissions} 
            surveySelected={surveySelected} setSurveySelected={setSurveySelected}  
            selectedNode={selectedNode} setSelectedNode={setSelectedNode}
          /> 
    </Fragment>
  )
}

export default SearchSurveyManagementPage