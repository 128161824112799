import { Editor } from "primereact/editor";
import { Droppable } from "react-beautiful-dnd";
import { InputTextConfiguration } from "./InputTextConfiguration";
import { RadioButtonConfiguration } from "./RadioButtonConfiguration";
import { CheckBoxConfiguration } from "./CheckBoxConfiguration";
import { DropDownConfiguration } from "./DropDownConfiguration";
import { TableGridConfiguration } from "./TableGridConfiguration";
import { AttachmentConfiguration } from "./AttachmentConfiguration";

export const WrapperFieldConfiguration = ({onChangeOptions, question, getListStyleOptions, removeField, droppableId, type}) => {
  const renderHeader = () => {
    return (
        <span className="ql-formats">
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
        </span>
    );
  };
  const header = renderHeader();

  return(
    <div className='px-2 py-3 border-2 border-gray-300'>
      <div className='flex justify-between'>
        <div className="w-full">
          <Editor
            headerTemplate={header}
            className='w-full'
            onTextChange={(e) => onChangeOptions({field: "fieldLabel", value: e.htmlValue, id: question.id})}
            value={question?.fieldLabel}
            placeholder="Texto de la pregunta"
          />
            <Droppable
              droppableId={droppableId}
              type={type}
              direction="vertical"
              isDropDisabled={question.type !== ""}
            >
            {(droppableProvided, snapshot) => (
              <div 
                ref={droppableProvided.innerRef}
                style={getListStyleOptions(snapshot.isDraggingOver)}
                {...droppableProvided.droppableProps}
                className='grid grid-cols-1 gap-y-2 md:gap-2 items-center w-full border border-black'  
                >
                <div>
                  {question.type === "INPUTTEXT" ? (
                    <InputTextConfiguration key={question.id} question={question} onChangeOptions={onChangeOptions} removeField={removeField}/>
                  ) : question.type === "RADIOBUTTON" ? (
                    <RadioButtonConfiguration key={question.id} question={question} onChangeOptions={onChangeOptions} removeField={removeField}/>
                  ) : question.type === "CHECKBOX" ? (
                    <CheckBoxConfiguration key={question.id} question={question} onChangeOptions={onChangeOptions} removeField={removeField}/>
                  ) : question.type === "DROPDOWN" ? (
                    <DropDownConfiguration key={question.id} question={question} onChangeOptions={onChangeOptions} removeField={removeField}/>
                  ) : question.type === "DATATABLE" ? (
                    <TableGridConfiguration key={question.id} question={question} onChangeOptions={onChangeOptions} removeField={removeField}/>
                  ) : question.type === "ATTACHMENT" ? (
                    <AttachmentConfiguration key={question.id} question={question} onChangeOptions={onChangeOptions} removeField={removeField}/>
                  ) : (
                    <div className='flex items-center justify-center lg:h-8 xl:h-10 border-2 border-gray-300 w-full border-dashed'>
                      <p className='text-center'><i className="pi pi-plus mr-1 text-sm"></i>Arrastre aqui para añadir un campo</p>
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </div>
              </div>)}
            </Droppable>
        </div>
      </div>
    </div>
  )
}