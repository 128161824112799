import { useRef } from 'react';
import { Button } from 'primereact/button';
// import Modal from './Modal';

export const LogoInputFile = ({
  dark,
  label,
  setFiles,
  name,
  setUrlImage,
  urlImage,
}) => {
  // const [enableModal, setEnableModal] = useState(false);

  const inputFileRef = useRef(null);
  const imageRef = useRef(null);

  const handlerFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      /*       setFiles((f) => ({
              ...f,
              [e.target.name]: file,
            })); */
      const imgCodified = URL.createObjectURL(file);
      setUrlImage((prev) => ({
        ...prev,
        [name]: imgCodified,
      }));
      // setFiles(e.target.files[0])
      setFiles((f) => ({
        ...f,
        [e.target.name]: file,
      }));
      // setEnableModal(true);
    }
  };

  // const renderFeedback = () => {
  //   if (enableModal) {
  //     return (
  //       <Modal
  //         setUrlImage={setUrlImage}
  //         setFiles={setFiles}
  //         urlImage={urlImage}
  //         setEnableModal={setEnableModal}
  //         name={name}
  //       />
  //     );
  //   }
  // };

  return (
    <>
      {urlImage ? (
        <div
          className={`logo ${dark ? 'logo-dark mt-4' : 'logo-light mt-6'}  `}
        >
          <img className="hidden" ref={imageRef} src={urlImage} alt="" />
          <div className="container-logo">
            <div className="foreground-logo">
              <i
                className="pi pi-times-circle"
                onClick={() => {
                  setUrlImage((f) => ({
                    ...f,
                    [name]: null,
                  }));
                  setFiles((f) => ({
                    ...f,
                    [name]: null,
                  }));
                }}
              ></i>
            </div>
            <img
              src={urlImage}
              className="logo h-10 object-fill"
              
              alt="Calidar"
              title="Calidar"
            />
          </div>
        </div>
      ) : (
        <div className="btn-upload">
          <div className="logo-preview">
            <Button
              onClick={() => inputFileRef.current.click()}
              type="button"
              icon="pi pi-upload"
              label={label}
              className="btn btn-secondary"
            />
          </div>
          <input
            name={name}
            ref={inputFileRef}
            onChange={handlerFileChange}
            className="hidden"
            type="file"
          />
        </div>
      )}
      {/* {renderFeedback()} */}
    </>
  );
};

export default LogoInputFile;
