import { useEffect } from 'react';
import { CompaniesAction } from 'features/customers/store/actions/CompaniesAction';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { useDispatch } from 'react-redux';

export const useCompanies = () => {
  const { updateTitle } = useSetTitlePage();
  const dispatch = useDispatch();

  useEffect(() => {
    updateTitle({
      title: 'Clientes',
      subtitle: 'Empresas',
    });
  }, []);

  const generateReport = () => {
    dispatch(CompaniesAction.generateReport());
  };
  return { generateReport };
};
