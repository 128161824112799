/* eslint-disable react-hooks/exhaustive-deps */


import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SelectButton } from 'primereact/selectbutton';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { Paginator } from 'primereact/paginator';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { CoreConstants } from 'core/commons/CoreConstants';
import { IndicatorsAction } from 'features/indicators/store/actions/IndicatorAction';
import { useClaimIndicators } from 'features/indicators/hooks/useClaimIndicators';
import { Link } from 'react-router-dom';
import { Skeleton } from 'primereact/skeleton';

const TableComponent = () => {
    const advancedFilter = useSelector((state) => state.indicatorReducer.advancedFilter);

    const history = useHistory();
    const dispatch = useDispatch();
    const typeViews = [
        { icon: 'pi pi-list', value: CoreConstants.TypeView.LIST },
        { icon: 'pi pi-microsoft', value: CoreConstants.TypeView.CANVA }
    ];

    const { isView ,isEdit, isDelete, isCopy } = useClaimIndicators();

    const { data, isLoading } = useSelector((state) => state.indicatorReducer.indicators);
    const { isDeleted, isSaved } = useSelector((state) => state.indicatorReducer.indicator);
    const getFromDate = (rowData) => moment(rowData.from).format(CoreConstants.Format.LOCALE); 
    const getToDate = (rowData) => moment(rowData.to).format(CoreConstants.Format.LOCALE); 
    
    const columns = [
        { field: 'code', header: 'Código'},
        { field: 'name', header: 'Nombre'},
        { field: 'period', header: 'Periodo'},
        // { field: 'from', header: 'Fecha Inicio' },
        // { field: 'to', header: 'Fecha Final' },
        // { field: 'status', header: 'Estado'},
    ];

    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);
    const actionsHeader = useRef(null);
    const menu = useRef(null);
    const [showConfirmDialog, setshowConfirmDialog] = useState(false);
    const [indicatorSelected, setIndicatorSelected] = useState(null);
    const [isFirstLoading, setFirstLoading] = useState(true); 

	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState(null);

    
    useEffect(() => {
        if (isFirstLoading) {
            tryParseFilter();
            setFirstLoading(false);
            setSortField("code");
            setSortOrder(1);
            dispatch(IndicatorsAction.search());
        }
    }, []);


    useEffect(() => {
        if (isDeleted) {
            setshowConfirmDialog(false);
            dispatch(IndicatorsAction.resetAfterSaved());
            dispatch(IndicatorsAction.search());
        }
    }, [isDeleted])

    useEffect(() => {
        if (isSaved) {
            dispatch(IndicatorsAction.resetAfterSaved());
            dispatch(IndicatorsAction.search());
        }
    }, [isSaved])

    let items = [
        {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: (e) => {
                history.push('/indicadores/indicador/' + indicatorSelected.id);
            }
        },
        {
            label: 'Eliminar',
            icon: 'pi pi-times',
            command: (e) => {
                setshowConfirmDialog(true);
                
            }
        },
        {
            label: 'Copiar',
            icon: 'pi pi-copy',
            command: (e) => {
                dispatch(IndicatorsAction.copyIndicator(indicatorSelected.id));
                history.push('/indicadores/indicador/nuevo');
                // setshowConfirmDialog(true);
            }
        },
    ];

    if (!isEdit)
        items = items.filter(c => c.label !== 'Editar');
    if (!isDelete)
        items = items.filter(c => c.label !== 'Eliminar');
    if (!isCopy)
        items = items.filter(c => c.label !== 'Copiar');

    const onSortCustom = (field) => {
        sortField === field ? setSortOrder(sortOrder * -1) : setSortField(field)
        // setSortField(field);
        // setSortOrder((prev) => (prev === 1 ? -1 : 1));
        
        advancedFilter.columnOrder = field;
        advancedFilter.order = (sortOrder * (sortField === field ? -1 : 1)) === 1 ? 'asc' : 'desc';
        // advancedFilter.order = sortOrder === 1 ? 'asc' : 'desc';
        advancedFilter.page = 1;
        dispatch(IndicatorsAction.updateFilter(advancedFilter));
        dispatch(IndicatorsAction.search());
    }

    const headerTemplateWithToogleSort = (rowData) => {
        return (
            <div 
                className="flex justify-between items-center gap-x-2 cursor-pointer" 
                onClick={(() => onSortCustom(rowData.field))}
            >
                <div>{rowData.header}</div>
                <div className="flex">
                    {/* <i 
                        className="pi pi-sort-amount-up-alt " 
                        // onClick={() => sortField === rowData.field ? setSortOrder(sortOrder * -1) : setSortField(rowData.field)}
                    ></i> */}
                    {
                        sortOrder === 1 && sortField === rowData.field ? <i className="pi pi-sort-amount-up-alt"></i> : <i className="pi pi-sort-amount-down-alt"></i>
                    }
                </div>
            </div>
        )
    }

    const getViewField = (rowData) => {
        if (isView)
          return <Link className="underline text-blue-700" to={{ pathname: `/indicadores/indicador/visualizar/` + rowData.id }} >{rowData.code}</Link>
        else
          return rowData.code;
      }

    
    const columnComponents = selectedColumns.map(col => {
        if (col.field === 'from' ) 
            return <Column key={col.field} field={col.field} header={col.header} body={getFromDate} sortable />;
        if (col.field === 'to') 
            return <Column key={col.field} field={col.field} header={col.header} body={getToDate} sortable />;
        if (col.field === 'code') 
            return (
                <Column 
                    key={col.field} field={col.field} 
                    // header={col.header} 
                    header={() => headerTemplateWithToogleSort(col)} 
                    headerClassName={col.field === sortField ? `p-sortable-column p-highlight` : ''}
                    // headerClassName={`p-sortable-column p-highlight`}
                    body={getViewField} 
                    // sortable 
                />
            )

        if (col.body)
            return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable />;
            
        // return <Column key={col.field} field={col.field} header={col.header} sortable  />;
        return <Column key={col.field} field={col.field} header={() => headerTemplateWithToogleSort(col)} 
        headerClassName={col.field === sortField ? `p-sortable-column p-highlight` : ''}  />;
    });

    const actionHeaderTemplate = (rowData) => {
        return <i
            key="action"
            className="pi pi-ellipsis-v cursor-pointer"
            onClick={(e) => actionsHeader.current.toggle(e)}></i>;
    }

    

    const actionBodyTemplate = (rowData) => {
        if(isCopy || isDelete || isEdit){
            return <i
                key={rowData.id}
                className="pi pi-ellipsis-v cursor-pointer"
                onClick={(event) => {
                    setIndicatorSelected(rowData);
                    menu.current.toggle(event)
                }
                }></i>
        }else{
            return <div></div>
        }
    }

    const tryParseFilter = () => {
        try {
            const filters = JSON.parse(localStorage.getItem("indicator-filter"));
            // const myFilters = filters.filter(item => item.field === 'code' || item.field === 'owner' || item.field === 'startDate' || item.field === 'endDate' || item.field === 'status');
            const myFilters = filters.filter(item => item.field === 'code'  || item.field === 'name'  || item.field === 'from' || item.field === 'to');
            if (filters)
                setSelectedColumns(myFilters);
            else
                setSelectedColumns(columns);
        } catch (error) {
            localStorage.removeItem('indicator-filter');
        }
    }

    const onColumnToggle = (e, item) => {
        let _selectedColumns = [...selectedColumns];

        if (e.checked)
            _selectedColumns.push(item);
        else
            _selectedColumns = _selectedColumns.filter(c => { return c.field !== e.value });

        setSelectedColumns(_selectedColumns);
        localStorage.setItem("indicator-filter", JSON.stringify(_selectedColumns));
    }

    const getColumns = () => {
        let array = [];
        
        columns.filter(col => col.field !== 'code').forEach((item) => {
            const column = <div key={item.field} className="p-field-checkbox py-1">
                <Checkbox
                    inputId={item.field}
                    name="column"
                    value={item.field}
                    onChange={(e) => onColumnToggle(e, item)}
                    checked={selectedColumns.some((x) => x.field === item.field)}
                />
                <label className="ml-1" htmlFor={item.field}>{item.header}</label>
            </div>
            array.push(column);
        });
        return array;
    };

    const confirmDelete = () => {
        dispatch(IndicatorsAction.deleteIndicator(indicatorSelected.id));
    }

    const cancelDelete = () => {
        setshowConfirmDialog(false);
    }

    const [rows, setRows] = useState(10)

    const onPageChange = (e) => {

        setRows(e.rows);
        advancedFilter.page = e.page + 1;
        advancedFilter.pageSize = e.rows;
        dispatch(IndicatorsAction.updateFilter(advancedFilter));
        dispatch(IndicatorsAction.search());
    }

    const onSort = (e) => {
		setSortField(e.sortField);
		// setSortOrder(e.sortOrder);
		setSortOrder((prev) => (prev === 1 ? -1 : 1));
		
		advancedFilter.columnOrder = e.sortField;
		advancedFilter.order = e.sortOrder === 1 ? 'asc' : 'desc';
		// advancedFilter.order = sortOrder === 1 ? 'asc' : 'desc';
		advancedFilter.page = 1;
		dispatch(IndicatorsAction.updateFilter(advancedFilter));
		dispatch(IndicatorsAction.search());
    }

    const bodyTemplate = () => <Skeleton></Skeleton>

    return (
        <Fragment>

            <div className="flex justify-end mb-2">
                <SelectButton
                    unselectable={false}
                    value={typeView}
                    options={typeViews}
                    onChange={(e) => setTypeView(e.value)}
                    itemTemplate={(option) => { return <i className={option.icon}></i>; }}
                />
            </div>

            {typeView === CoreConstants.TypeView.CANVA && <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2  gap-2">
                {
                    data?.results.map((item, index) => {
                        return (
                            <div key={"card" + item.id}
                                className="
                                block 
                                p-4 
                                max-w-sm 
                                bg-white 
                                rounded-lg 
                                border 
                                border-gray-200 
                                shadow-md 
                                hover:bg-gray-100 
                                dark:bg-gray-800 
                                dark:border-gray-700 
                                dark:hover:bg-gray-700">

                                <div className="flex justify-between">
                                    {
                                        (isView)?(
                                            <Link className="underline text-blue-700" to={{ pathname: `/indicadores/indicador/visualizar/` + item.id }} ><h5>{item.name}</h5></Link>
                                        ):(
                                            <h5 key={"title" + item.id} className="mb-1 text-md font-bold   text-gray-900 dark:text-white">{item.name}</h5>
                                        )
                                    }
                                    {
                                        (isCopy || isEdit || isDelete)&&(
                                            <i
                                                key={"options" + item.id}
                                                className="pi pi-ellipsis-v cursor-pointer"
                                                onClick={(event) => {
                                                    setIndicatorSelected(item);
                                                    menu.current.toggle(event)
                                                }}
                                            ></i>
                                        )
                                    }
                                </div>
                                <div className="flex flex-wrap justify-between">
                                    <span className="text-xs float-right px-2 py-1 text-black bg-white rounded-full border border-gray-200">
                                    <span className='font-semibold'>De:</span>  {moment(item.from).format(CoreConstants.Format.LOCALE)}
                                    </span>
                                    <span className="text-xs float-right px-2 py-1 text-black bg-white rounded-full border border-gray-200">
                                    <span className='font-semibold'>Hasta:</span> {moment(item.to).format(CoreConstants.Format.LOCALE)}
                                    </span>
                                </div>
                            </div>
                        );
                    })
                }

            </div>}

            {typeView === CoreConstants.TypeView.LIST &&
                <div className="mt-3 " style={{ maxWidth: (window.innerWidth - 100) + 'px' }}>           
                    {
                        isLoading ? (
                            <DataTable value={items} className="p-datatable-striped" responsiveLayout="scroll">
                                <Column field="code" header="Código" style={{ width: '40%' }} body={bodyTemplate}></Column>
                                <Column field="name" header="Nombre" style={{ width: '50%' }} body={bodyTemplate}></Column>
                                <Column header="" style={{ width: '10%' }} body={bodyTemplate}></Column>
                            </DataTable>
                        ) : (
                            <DataTable
                                value={data?.results}
                                stripedRows
                                responsiveLayout="scroll"
                                size="small"
                                emptyMessage="No se encontraron indicadores"

                                sortField={sortField}
                                // sortOrder={sortOrder}
                                onSort={onSort}
                                // defaultSortOrder={0}
                            >
                                {/* <Column 
                                    // header="Código"
                                    header={headerTemplateWithToogleSort}
                                    field="code"  body={getViewField} sortable 
                                />
                                <Column field="name" header="Nombre"  sortable /> */}
                                {columnComponents}
                                <Column header={actionHeaderTemplate} body={actionBodyTemplate} ></Column>
                            </DataTable>
                        )
                    }         
                </div>
            }

            <Paginator
                key={"pag-1"}
                className="paginator-custom mt-4"
                rows={rows} 
                first={(data?.currentPage - 1) * rows}
                rowsPerPageOptions={[10,20,30]}
                totalRecords={data?.rowCount}
                onPageChange={onPageChange}
            ></Paginator>

            <OverlayPanel ref={actionsHeader} showCloseIcon style={{ width: '300px' }}>{getColumns()}</OverlayPanel>

            <Menu model={items} popup ref={menu} id="popup_menu" />
            <ConfirmDialog
                visible={showConfirmDialog}
                onHide={cancelDelete}
                message="¿Está seguro que desea eliminar el registro?"
                header="Eliminar"
                icon="pi pi-trash" 
                accept={confirmDelete}
                reject={cancelDelete}
            />
        </Fragment >
    );
};

export default TableComponent;
