import { apiService } from "core/services/ApiService";

const searchModel = (criteria) => {
  return apiService.Post('management/api/RiskManagementModel/Search', criteria);
};

const saveModel = (data) => {
  return apiService.Post('management/api/RiskManagementModel/Save', data);
};

const getModelById = (modelId) => {
  return apiService.Get('management/api/RiskManagementModel/GetRiskManagementModelById?id=' + modelId);
};

const deleteModel = (modelId) => {
  return apiService.Post('management/api/RiskManagementModel/Delete?id=' + modelId);
};

const getDataMaster = () => {
  return apiService.Get('management/api/RiskManagementModel/GetDataMaster');
};

const generateReport = (payload) => {
  return apiService.Post('management/api/RiskManagementModel/GenerateReport', payload, {
    responseType: 'blob',
  });
};

const copyModel = (modelId) => {
  return apiService.Get('management/api/RiskManagementModel/CopyModel?id='+modelId);
};

/* Evaluacion de modelo */

const getEvaluationByModelId = (modelId) => {
  return apiService.Get(`management/api/RiskManagementModelEvaluation/GetEvaluationByModelId?id=${modelId}`);
};

const getDataMasterEvaluation = () => {
  return apiService.Get('management/api/RiskManagementModelEvaluation/GetDataMaster');
};

const searchEvaluation = (filter) => {
  return apiService.Post('management/api/RiskManagementModelEvaluation/Search', filter)
}

const saveEvaluation = (data) => {
  return apiService.Post('management/api/RiskManagementModelEvaluation/Save', data)
}

const deleteEvaluation = (evaluationId) => {
  return apiService.Post(`management/api/RiskManagementModelEvaluation/Delete?id=${evaluationId}`)
}

const getEvaluationById = (evaluationId) => {
  return apiService.Get(`management/api/RiskManagementModelEvaluation/GetEvaluationById?id=${evaluationId}`)
}

const generateEvaluationReport = (data) => {
  return apiService.Post('management/api/RiskManagementModelEvaluation/GenerateReport', data)
}

export const RiskModelServices = { 
	searchModel,
	saveModel,
	getModelById,
	deleteModel,
	getDataMaster,
	generateReport,
  getEvaluationByModelId,
  getDataMasterEvaluation,
  searchEvaluation,
  saveEvaluation,
  deleteEvaluation,
  getEvaluationById,
  generateEvaluationReport,
  copyModel,
}