import React from 'react';
import { useDispatch } from 'react-redux';
import { SplitButton } from 'primereact/splitbutton';
import { TabView, TabPanel } from 'primereact/tabview';
import { Panel } from 'primereact/panel';

import useData from 'features/riskManagement/hooks/useData';
import { RiskManagementAction } from 'features/riskManagement/store/actions/RiskManagementAction'

import Row from 'features/riskManagement/components/Row';
import ColorPicker from 'features/riskManagement/components/ColorPicker';
import RiskLevel from 'features/riskManagement/components/RiskLevel';
import Probabilidad from 'features/riskManagement/components/Probabilidad';
import Impacto from 'features/riskManagement/components/Impacto';

const RiskMapPage = ({
	control,
	setValue,
	getValues,
	valuesFields
}) => {
	const dispatch = useDispatch()

	const {
		data,
		probability,
		impact,
	}= useData()

const items = [
	{
		label: 'Probabilidad',
		command: (e) => {
			dispatch(RiskManagementAction.addRow())
		}
	},
	{
		label: 'Impacto',
		command: (e) => {
			dispatch(RiskManagementAction.addCol())
		}
	}
]

	return (
		<Panel header='CRITERIO'>
		<div>
			<div className='flex flex-wrap gap-y-8'>
				<div className='left-section px-2 overflow-auto scroll' style={{minWidth: "50%", flexGrow: 1}}>	
					<div className='flex'>
						<div>
							<div className='mb-4'>
							<SplitButton label="Añadir" icon="pi pi-plus" className="split-button-risk" model={items}></SplitButton>
							</div>
							<div className='flex gap-1 w-max'>
							<div className='w-16 flex justify-center m-auto'></div>
								{
									impact.length > 2 && impact.map((impacto, key) => 
									<div className='w-16 flex justify-center m-auto' key={key} style={{fontSize: '10px'}}>
									<i 
										className="pi pi-times-circle text-xl cursor-pointer" 
										onClick={()=> {
											dispatch(RiskManagementAction.deleteCol(impacto.id))
											setValue(`impact-name-${impacto.id}`, undefined)
											setValue(`impact-value-${impacto.id}`, undefined)
										}}
									/>
									</div>
									)
								}
							</div>
							<div className='flex flex-col gap-1 matriz w-max'>
								{
									data.map((row,fil)=>
												<Row row={row} probabilidad={probability[fil]} key={`row-${fil}`} setValue={setValue}/>
									)
								}					
							</div>
							<div className='flex gap-1 w-max'>
									<div className='w-16 flex m-auto text-xs'>

									</div>
								{
									impact.map((item, key) => 
									<div className='w-16 flex m-auto' key={key} style={{fontSize: '10px'}}>
										{item.name}
									</div>
									)
								}
							</div>
						</div>
						<div className='w-20 ml-4'>
							<ColorPicker />
						</div>
					</div>
				</div>
				<div className='rigth-section px-2' style={{width: "50%", flexGrow: 1}}>
					<div>
						Establece los niveles de riesgos y las variables de probabilidad e impacto
					</div>
					<TabView renderActiveOnly={false}>
						<TabPanel header={<div style={{fontSize: '14px'}}>Probabilidad e Impacto</div>}>
							<TabView renderActiveOnly={false}>
								<TabPanel header={<div style={{fontSize: '12px'}}>Probabilidad</div>}>
									<Probabilidad valuesFields={valuesFields} control={control} getValues={getValues}/>
								</TabPanel>
								<TabPanel header={<div style={{fontSize: '12px'}}>Impacto</div>}>
									<Impacto valuesFields={valuesFields} control={control} getValues={getValues}/>
								</TabPanel>
							</TabView>
						</TabPanel>
						<TabPanel header={<div style={{fontSize: '14px'}}>Nivel de riesgo</div>}>
								<RiskLevel control={control} getValues={getValues} setValue={setValue}/>
						</TabPanel>
					</TabView>
				</div>
			</div>
		</div>
		</Panel>
	);
};

export default RiskMapPage;