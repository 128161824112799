/* eslint-disable react-hooks/exhaustive-deps */
import { BusinessSheetTypeAction } from "features/maintenance/store/actions/BusinessSheetTypeAction";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ConfirmModal } from "shared/components/confirmModal/ConfirmModal";
import useSetTitlePage from "shared/hooks/useSetTitlePage";

const { useForm, Controller } = require("react-hook-form");

export const BusinessSheetTypePage = ({ title, mode }) => {
  const { updateTitle } = useSetTitlePage();
  const history = useHistory();
  const dispatch = useDispatch();
  const { businessSheet, loading } = useSelector(
    (state) => state.businessSheetTypeReducer.data
  );
  const isLoadingSave = useSelector(
    (state) => state.businessSheetTypeReducer.save.isLoadingSave
  );

  const confirmRef = useRef();

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    name: "",
  });

  const params = useParams();
  const id = params?.id;

  const redirectToSearch = () => {
    history.push("/mantenimiento/ficha");
    dispatch(BusinessSheetTypeAction.resetDataBusinessSheet());
  };

  useEffect(() => {
    if (Object.keys(businessSheet).length > 0) {
      setValue("name", businessSheet.name);
    }
  }, [businessSheet]);

  useEffect(() => {
    updateTitle({
      title: "Tipo de Ficha de Negocio",
      subtitle: title,
      previousUrl: "/mantenimiento/ficha",
    });
    if (id) dispatch(BusinessSheetTypeAction.getById(id));
  }, []);

  const onSubmit = (data) => {
    const model = {
      id: id ? Number(id) : null,
      name: data.name,
    };

    dispatch(BusinessSheetTypeAction.save(model, redirectToSearch));
  };

  return (
    <div className="bg-white">
      <div className="form-custom p-0">
        <div className="form-modal p-0">
          <Panel header="DATOS GENERALES" toggleable>
            {loading ? (
              <Skeleton height="2.2rem" />
            ) : (
              <form
                id="form-business-line"
                className=" grid  sm:grid-cols-1 md:grid-cols-1 gap-1 mt-2 mb-1"
                onSubmit={handleSubmit(onSubmit)}
              >
                <span className="p-float-label w-full mt-4">
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => (
                      <InputText
                        maxLength={100}
                        type="text"
                        id="name"
                        name="name"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value || ""}
                        className={error ? "p-invalid w-full" : "w-full"}
                      />
                    )}
                    name="name"
                    rules={{
                      required: "El nombre del tipo de ficha de negocio es requerido",
                    }}
                  />

                  <label htmlFor="name">Nombre del tipo de ficha de negocio *</label>
                </span>
              </form>
            )}
          </Panel>
        </div>
        {errors.name && (
          <div className="message error">
            <ul>
              {errors.name ? (
                <>
                  <li className="p-error">{errors.name.message}</li>
                </>
              ) : null}
            </ul>
          </div>
        )}
        <div className="flex justify-end gap-4 mt-3">
          <Button
            icon="pi pi-times"
            type="button"
            label="Cancelar"
            loading={loading || isLoadingSave}
            onClick={() => {
              confirmRef.current.openModal({
                header: "Eliminar",
                message: "¿Está seguro que desea cancelar",
                icon: "pi pi-trash",
                onConfirm: redirectToSearch,
              });
            }}
            className="sig-button sig-secondary mt-4"
          />
          {mode !== "view" && (
            <Button
              icon="pi pi-save"
              type="submit"
              form="form-business-line"
              label="Guardar"
              loading={loading || isLoadingSave}
              className="sig-button sig-primary mt-4"
            />
          )}
        </div>
      </div>
      <ConfirmModal ref={confirmRef} />
    </div>
  );
};
