import { AppConstans } from "shared/commons/AppConstans";

const initialState = {
  parameters: {
    deliverableId: null,
    taskName: null,
    searchHome: false,
  },
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case AppConstans.Accion.Parameters:
      return {
        ...state,
        parameters: {
          ...state.parameters,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
