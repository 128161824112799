import { RiskModelAction } from 'features/riskManagement/store/actions/RiskModelAction';
import { Calendar } from 'primereact/calendar';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalFilterComponent from 'shared/components/modal-filter/ModalFilterComponent';

const AdvancedFilterComponent = () => {

  const dispatch = useDispatch()
  const advancedFilter = useSelector( state => state.riskModelReducer.advancedFilter)
  
  const { showModal } = useSelector((state) => state.riskModelReducer.advancedFilter);

  const [ formValues, setFormValues] = useState({
    name: null,
    from: null,
    to: null,
  })

  const closeModal = () => {
    dispatch(RiskModelAction.toggleAdvancedFilter({ showModal: false }));
};

  useEffect(() => {
    setFormValues({
        from: advancedFilter.filter.from,
        to: advancedFilter.filter.to,
        name: advancedFilter.filter.name,
    });
}, [advancedFilter]);

  const onChangeFrom = (e) => {
    if (e.value)
      formValues.to = null;
    setFormValues({
      ...formValues,
      [e.target.name]: e.value,
    });
  }

  const onChangeTo = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.value,
    });
  }

  const handleSubmit = (e) => {
      e.preventDefault()
      const fields = formValues;

      if (fields?.from)
          advancedFilter.filter.from = fields.from;
      else
          advancedFilter.filter.from = null;

      if (fields?.to)
          advancedFilter.filter.to = fields.to;
      else
          advancedFilter.filter.to = null;
      
      dispatch(RiskModelAction.updateFilter(advancedFilter))
      dispatch(RiskModelAction.search())
      dispatch(RiskModelAction.toggleAdvancedFilter({ showModal: false }))
  }

  const clearFields = () => {
    setFormValues({
      ...formValues,
      from: null,
      to: null,
    });
  }

	if(showModal)
		return (
			<ModalFilterComponent
        disabledBtn={false}
        closeModal={closeModal}
        clearFields={clearFields}
        handleSubmit={handleSubmit}
        searchLoading={advancedFilter?.loading}
      >
        <div className="flex gap-4 pt-3">
          <div className="w-full">
            <span className="p-float-label">
              <Calendar
                value={formValues.from || ""}
                name="from"
                id="desde"
                onChange={(e) => onChangeFrom(e)}
              />
              <label htmlFor="desde">Desde</label>
            </span>
          </div>
          <div className="w-full">
            <span className="p-float-label">
              <Calendar

                  value={formValues.to}
                  name="to"
                  id="hasta"
                  minDate={formValues?.from}
                  onChange={(e) => onChangeTo(e)}
              />
              <label htmlFor="hasta">Hasta</label>
            </span>
          </div>
        </div>
      </ModalFilterComponent>
		);
	return null;
};

export default AdvancedFilterComponent;