import { apiService } from 'core/services/ApiService';

function selecEmpresaSede(email, password, companyId, locationId) {
  return apiService.Post('security/api/Auth/LoginUser', {
    userName: email,
    password: password,
    companyId,
    locationId,
  });
}

function solicitarRecuperarContrasena(email) {
  return apiService.Get(
    `security/api/Auth/RequestForgotPassword?email=${email}`
  );
}

function recuperarContrasena(token, newPassword) {
  return apiService.Post('security/api/Auth/ForgotPassword', {
    token,
    newPassword,
  });
}

function activarCuenta(model) {
  return apiService.Post('security/api/Auth/ActivateAccount', model);
}

export const userService = {
  selecEmpresaSede,
  solicitarRecuperarContrasena,
  recuperarContrasena,
  activarCuenta
};
