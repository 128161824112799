import { Button } from "primereact/button"
import { Password } from "primereact/password"

export const PasswordForm = ({handleChange, submit, dataForm, errors, isLoading}) => (
  <form
    onChange={handleChange}
    onSubmit={submit}
  >
    <div className="fields">
      <span className="p-float-label field w-full">
        <Password
          type="password"
          toggleMask
          name="password"
          feedback={false}
          className='w-full'
        />
        <label htmlFor="password">Contraseña</label>
      </span>

      <span className="p-float-label field w-full">
        <Password
          type="password"
          toggleMask
          name="confirmPassword"
          feedback={false}
          className='w-full'
        />
        <label htmlFor="confirmPassword">Confirmar contraseña</label>
      </span>
    </div>
    <div className='mt-2'>
      {
        dataForm.password && 
        errors.filter(error => error.type !== 'required').map( error => (
          <div
            className={error.status ? "p-error":"text-green-500"}
            key={error.type} style={{display: 'grid', gridTemplateColumns: "10px 1fr", gap: "4px"}}
          >
            <small>
              {
                error.status ?
                <i className="pi pi-times" style={{ fontSize: '10px' }}></i>
                :
                <i className="pi pi-check" style={{ fontSize: '10px' }}></i>
              }
            </small>
            <small>
              {error.message}
            </small>
          </div>
        ))
      }
    </div>
    <div className="text-center mt-2">
      <Button
        label="Activar cuenta"
        loading={isLoading}
        className="btn btn-primary" />
    </div>
  </form>
)
