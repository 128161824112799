import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import ModalFilterComponent from 'shared/components/modal-filter/ModalFilterComponent';

const AdvancedFilterComponent = ({showModal, setShowModal}) => {
	if(showModal)
		return (
			<ModalFilterComponent
                disabledBtn={false}
                closeModal={()=> setShowModal(false)}
                // clearFields={clearFields}
                // handleSubmit={handleSubmit}
                // searchLoading={advancedFilter?.loading}
            >

                <div className="flex gap-4 pt-3">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                // value={formValues.from}
                                name="from"
                                id="desde"
                                // onChange={(e) => onChangeFrom(e)}
                            />
                            <label htmlFor="desde">Desde</label>
                        </span>
                    </div>
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                // value={formValues.to}
                                name="to"
                                id="hasta"
                                // minDate={formValues?.from}
                                // onChange={(e) => onChangeTo(e)}
                            />
                            <label htmlFor="hasta">Hasta</label>
                        </span>
                    </div>

                </div>

                <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            <InputText
                                type="text"
                                id='title'
                                name="title"
                                // onChange={(e) => { onChangeTitle(e) }}
                                // value={formValues?.title}
                                maxLength={100}
                            />
                            <label htmlFor="code">Nombre de la Matriz</label>
                        </span>
                    </div>
                </div>
            </ModalFilterComponent>
		);
	return null;
};

export default AdvancedFilterComponent;