

import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { Paginator } from 'primereact/paginator';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { SolutionsAction } from "features/subscription/store/actions/SolutionsActions";
import { useClaimSolutions } from 'features/subscription/hooks/useClaimSolutions';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { CoreConstants } from 'core/commons/CoreConstants';

const TableComponent = () => {
    const advancedFilter = useSelector((state) => state.solutionReducer.advancedFilter);

    const history = useHistory();
    const dispatch = useDispatch();

    const { isView ,isEdit, isDelete } = useClaimSolutions();

    const { data } = useSelector((state) => state.solutionReducer.solutions);


    const { isDeleted } = useSelector((state) => state.solutionReducer.solution);

    const getCreationDate = (rowData) => { return moment(rowData.creationDate).format(CoreConstants.Format.LOCALE); }

    const columns = [
        // { field: 'name', header: 'Nombre' },
        { field: 'creationDate', header: 'Fecha Creación', body: getCreationDate }
    ];

    const [selectedColumns, setSelectedColumns] = useState(columns);
    const actionsHeader = useRef(null);
    const menu = useRef(null);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [showConfirmDialog, setshowConfirmDialog] = useState(false);
    const [solutionSelected, setSolutionSelected] = useState(null);
    const [isFirstLoading, setFirstLoading] = useState(true); 

    useEffect(() => {
        if (isFirstLoading) {
            tryParseFilter();
            setFirstLoading(false);
            setSortField("name");
            setSortOrder(1);
            dispatch(SolutionsAction.search());
        }
    }, []);

    let items = [
        {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: (e) => {
                history.push('/suscripcion/solucion/' + solutionSelected.key);
            }
        },
        {
            label: 'Eliminar',
            icon: 'pi pi-times',
            command: (e) => {
                setshowConfirmDialog(true);
            }
        }
    ];

    if (!isEdit)
      items = items.filter(c => c.label !== 'Editar');
    if (!isDelete)
      items = items.filter(c => c.label !== 'Eliminar');

    useEffect(() => {
        if (isDeleted) {
            setshowConfirmDialog(false);
            dispatch(SolutionsAction.resetAfterSaved());
            dispatch(SolutionsAction.search());
        }
    }, [isDeleted])

    const getViewField = (rowData) => {
        if (isView && rowData?.data?.isFirstRow)
          return <Link className="underline text-blue-700" to={{ pathname: `/suscripcion/solucion/visualizar/` + rowData.key }} >{rowData?.data.name}</Link>
        else
          return rowData?.data.name;
      }

    const columnComponents = selectedColumns.map(col => {

        if (col.field === 'creationDate')
            return <Column key={col.field} field={col.field} header={col.header} body={getCreationDate} sortable />;

        if (col.body)
            return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable />;

        return <Column key={col.field} field={col.field} header={col.header} sortable />;
    });


    const onSort = (e) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);

        advancedFilter.columnOrder = e.sortField;
        advancedFilter.order = e.sortField === 1 ? 'asc' : 'desc';
        advancedFilter.page = 1;
        dispatch(SolutionsAction.updateFilter(advancedFilter));
        // dispatch(SolutionsAction.search());
    }

    const actionHeaderTemplate = (rowData) => {
        return <i
            key="action"
            className="pi pi-ellipsis-v cursor-pointer"
            onClick={(e) => actionsHeader.current.toggle(e)}></i>;
    }

    const actionBodyTemplate = (rowData) => {
        if(rowData?.data?.isFirstRow){
            if(isEdit && isDelete){
                return <i
                    key={rowData.key}
                    className="pi pi-ellipsis-v cursor-pointer"
                    onClick={(event) => {
                        setSolutionSelected(rowData);
                        menu.current.toggle(event)
                    }
                    }></i>
            }else{
                return <div></div>
            }
        }else{
            return <div></div>
        }
    }

    const tryParseFilter = () => {
        try {
            const filters = JSON.parse(localStorage.getItem("solutions-filter") || '[]');
            const myFilters = filters.filter(item => item.field === 'creationDate')
            if (filters.length > 0){
                setSelectedColumns(myFilters);
            }
            else {
                setSelectedColumns(columns);
            }
        } catch (error) {
            localStorage.removeItem('solutions-filter');
        }
    }

    const onColumnToggle = (e, item) => {
        let _selectedColumns = [...selectedColumns];

        if (e.checked)
            _selectedColumns.push(item);
        else
            _selectedColumns = _selectedColumns.filter(c => { return c.field !== e.value });

        setSelectedColumns(_selectedColumns);
        localStorage.setItem("solutions-filter", JSON.stringify(_selectedColumns));
    }

    const getColumns = () => {
        let array = [];
        
        columns.map((item) => {
            const column = <div key={item.field} className="p-field-checkbox py-1">
                <Checkbox
                    inputId={item.field}
                    name="column"
                    value={item.field}
                    onChange={(e) => onColumnToggle(e, item)}
                    checked={selectedColumns.some((x) => x.field === item.field)}
                />
                <label className="ml-1" htmlFor={item.field}>{item.header}</label>
            </div>
            array.push(column);
        });
        return array;
    };

    const confirmDelete = () => {
        dispatch(SolutionsAction.deleteSolution(solutionSelected.key));
    }

    const cancelDelete = () => {
        setshowConfirmDialog(false);
    }

    return (
        <Fragment>
            
            <div className="mt-3 " style={{ maxWidth: (window.innerWidth - 100) + 'px' }}>                    
                {/* <DataTable
                    value={data}
                    stripedRows
                    responsiveLayout="scroll"
                    size="small"
                    sortField={sortField}
                    sortOrder={sortOrder}
                    emptyMessage="No se encontraron instituciones"
                    rowExpansionTemplate={rowExpansionTemplate}
                    onSort={(e) => onSort(e)}>
                    <Column expander={allowExpansion} style={{ width: '3em' }} />
                    <Column field="name" header="Institución" body={onBody} sortable/>
                    {columnComponents}
                    <Column header={actionHeaderTemplate} body={actionBodyTemplate} ></Column>
                </DataTable> */}
                <TreeTable
                    value={data} 
                    sortField={sortField}
                    sortOrder={sortOrder}
                    paginator rows={10}
                    >
                    <Column field="name" header="Nombre" body={getViewField} sortable expander></Column>
                    {columnComponents}
                    <Column header={actionHeaderTemplate} body={actionBodyTemplate} ></Column>
                </TreeTable>
            </div>
            

            {/* <Paginator
                key={"pag-1"}
                className="paginator-custom mt-4"
                rows={rows} 
                first={(data?.currentPage - 1) * rows}
                rowsPerPageOptions={[10,20,30]}
                totalRecords={data?.rowCount}
                onPageChange={onPageChange}
            ></Paginator> */}

            <OverlayPanel ref={actionsHeader} showCloseIcon style={{ width: '300px' }}>{getColumns()}</OverlayPanel>

            <Menu model={items} popup ref={menu} id="popup_menu" />
            <ConfirmDialog
                visible={showConfirmDialog}
                onHide={cancelDelete}
                message="¿Está seguro que desea eliminar el registro?"
                header="Eliminar"
                icon="pi pi-trash" 
                accept={confirmDelete}
                reject={cancelDelete}
            />
        </Fragment >
    );
};

export default TableComponent;
