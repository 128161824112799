import { useRef, useState } from "react"
import { useHistory, Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useTable } from "shared/hooks/useTable"
import { CoreConstants } from "core/commons/CoreConstants"

import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Paginator } from "primereact/paginator"
import { Skeleton } from "primereact/skeleton"
import { SelectButton } from "primereact/selectbutton"
import { Menu } from "primereact/menu"
import { ConfirmDialog } from "primereact/confirmdialog"

import { CanvaComponent } from "./CanvaComponent"
import { getFilterValuesFromPiners } from "features/business/helpers/businessSheetAnswer/BusinessSheetAnswerHelper"
import { businessSheetAnswerAction } from "features/business/store/actions/BusinessSheetAnswerAction"

export const TableComponent = () => {
  const params = useParams();
  const businessSheetId = Number(params.id);
  const { pageSize, onPageChange, first, rowCount, columnOrder, onSort, order, searchData } = useTable("customerName", getFilterValuesFromPiners(businessSheetId), businessSheetAnswerAction.search)

  const data = useSelector(state => state.businessSheetAnswerReducer.businessSheetAnswers.data)
  const loading = useSelector(state => state.businessSheetAnswerReducer.businessSheetAnswers.loading)

  const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST)

  const typeViews = [
    { icon: "pi pi-list", value: CoreConstants.TypeView.LIST },
    { icon: "pi pi-microsoft", value: CoreConstants.TypeView.CANVA },
  ];

  const columns = [
    { field: 'customerName', header: 'Empresa' },
    { field: 'businessSheetName', header: 'Nombre de Ficha' },
    { field: 'code', header: 'Código' },
    { field: 'activityDate', header: 'Fecha de Creación' },
  ];

  const getViewField = (rowData) => (
    <Link className="underline text-blue-700" to={{ pathname: `/negocios/fichas/respuestas/${businessSheetId}/respuesta/${rowData.id}`}} >{rowData.customerName}</Link>
  )
  
  const ColumnComponents = columns.map((col) => {
    if(col.field === 'customerName')
      return (
        <Column key={col.field} field={col.field} header={col.header} body={getViewField} sortable />
      );

    return (
      <Column key={col.field} field={col.field} header={col.header} sortable />
    );
  });

  if(loading) return <Skeleton height="8rem"/>

  return(
    <>
      <div className="flex justify-end mb-5">
        <SelectButton
          unselectable={false}
          value={typeView}
          options={typeViews}
          onChange={(e) => setTypeView(e.value)}
          itemTemplate={(option) => {
            return <i className={option.icon}></i>;
          }}
        />
      </div>
      {
        typeView === CoreConstants.TypeView.LIST ?
        <DataTable
          value={data}
          emptyMessage="No se encontraron Fichas de Negocio"
          stripedRows
          rowHover
          size="small"
          responsiveLayout="scroll"
          onSort={onSort}
          sortField={columnOrder}
          sortOrder={order}
        >
          {ColumnComponents}
        </DataTable>
        :
        <CanvaComponent
          data={data}
          // selectedRow={selectedRow}
        />
      }

      <Paginator
        first={first}
        rows={pageSize}
        onPageChange={onPageChange}
        totalRecords={rowCount}
        className="paginator-custom mt-4"
        rowsPerPageOptions={[5, 10, 15]}
      />
    </>
  )
}