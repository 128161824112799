import useModals from 'features/subscription/hooks/useModals'
import ActiveSubscription from './modals/ActiveSubscription'
import CancelSusbcription from './modals/CancelSubscription'
import BlockSubscription from './modals/BlockSubscription'
import RenewSusbcription from './modals/RenewSubscription'
import useSubscriptions from 'features/subscription/hooks/useSubscriptions'
const ModalParent = () => {
  const {
    dataManager
  } = useSubscriptions('Editado',false)
 
  const {
    showModalState,
    typeModal,
    closeModal
  } = useModals()
  const found = dataManager.status.filter(p => p.description === typeModal)[0];

  if(showModalState)
    if(typeModal === "Activo")
      return <ActiveSubscription close={closeModal} visible={showModalState} statusId={found.id} /> 
    if(typeModal === "Renovado")
      return <RenewSusbcription close={closeModal} visible={showModalState} /> 
    if(typeModal === "Cancelado")
    return <CancelSusbcription close={closeModal} visible={showModalState}  statusId={found.id}/> 
    if(typeModal === "Bloqueado")
    return <BlockSubscription close={closeModal} visible={showModalState}  statusId={found.id}/> 


  return null
}

export default ModalParent