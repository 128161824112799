import { useEffect , useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { EmployeesAction } from 'features/rrhh/store/actions/EmployeesAction';
import { CoreConstants } from 'core/commons/CoreConstants';
const useAdvancedFilter = () => {
  const dispatch = useDispatch();
  const { values   } = useSelector((state) => state.employeeReducer.employeeFilter);
  const initialState = {
    from: null,
    to: null,
    area:null,
    managerId:null,
    employeeType:null,
    countryOfBirth: null,
    maritalStatusId: null,
    academicDegreeId: null,
    rowStatus: null,
    situationId:null,
    positionId:null,
    // porEmpresa:null
  }
  const [formValues,setFormValues] = useState(initialState)

  useEffect(() => {
    let newFormValue = {};
    values.forEach(value =>{
      if(value.date){
        newFormValue[value.field] = value.date
      }else{
        newFormValue[value.field] = `${value.id}-${value.value}`
      }
    })
    setFormValues({...initialState,...newFormValue})
  },[values])


  
  const clearFields = () => {
    dispatch(EmployeesAction.setFilterValues({values:[]}));
    setFormValues(initialState)
    dispatch(EmployeesAction.getEmployees({}));
  }
  const getFields = () => {
    let fields = {};
    let newValues  ={}
      Object.entries(formValues).forEach(([key,value]) =>{
        if(value!==null){
          if(typeof value === 'object'){
            fields[key] =  moment.parseZone(value).utc().format()
            newValues[key] = value
          }else{
            if(value !== undefined){
              if(value.includes('-')){
                let arrayValue = value.split('-');
                fields[key] = parseInt(arrayValue[0])
                newValues[key] = value
              }else{
                fields[key] = value
                newValues[key] = value
              }
            }
          }
        }
      });
   
    return  {fields , newValues}
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const  { newValues , fields } = getFields();
    if (values.length > 0) {
      const fieldName = values.filter((val) => val.field === 'names');
      if (fieldName.length > 0) fields.name = fieldName[0].value;
      for (let key in fields) {
        if (!fields[key]) delete fields[key];
      }
    }

    const arrValues = [];
    
    Object.entries(newValues).forEach(([key,value]) =>{
      if (key === "from" && value !== null)
        arrValues.push({
          field: key,
          value: moment(value).format(CoreConstants.Format.LOCALE),
          date: value,
      });

      if (key === "to" && value !== null)
        arrValues.push({
          field: key,
          value: moment(value).format(CoreConstants.Format.LOCALE),
          date: value,
      });
      if(key !== "to" && key !== "from" && value !== null && value !== ""){
        let arrayValue = (value+"").split('-');
        arrValues.push({
          field:key,
          value:arrayValue[1],
          id:arrayValue[0]
        })
      }
    })
    dispatch(EmployeesAction.setFilterValues({values:arrValues}));
    dispatch(EmployeesAction.getEmployees({fields}));
    dispatch(EmployeesAction.toggleModalFilters({ showModal: false }));
  };



  
  return {
    clearFields,
    handleSubmit,
    formValues,
    setFormValues
  };
};

export default useAdvancedFilter;
