import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { InstitutionAction } from "features/maintenance/store/actions/InstitutionAction";

import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputSwitch } from "primereact/inputswitch";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { SolutionsAction } from "features/subscription/store/actions/SolutionsActions";
import useSolutions from '../../../hooks/solutions/useSolutions'
import { useClaimSolutions } from "features/subscription/hooks/useClaimSolutions";
const { useForm, Controller } = require("react-hook-form")

const SolutionPage = ({title, mode}) => {
  const {updateTitle } = useSetTitlePage()
  const history = useHistory();
  const dispatch = useDispatch();
  const solution = useSelector((state) => state.solutionReducer.solution);
  const [activeIndex, setActiveIndex] = useState(null);
  const {isLoading, dataOptions} = useSelector((state) => state.solutionReducer.dataMaster);
  const [isFirstLoading, setFirstLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const { setValue, handleSubmit, control, formState: { errors } } = useForm({ name: "", id: null });
  const {solOptions, isSolutionOptionsEmpty, error, menuData, setMenuData, setError, isChecked, handleChange, getOptionsList, getMenuList, setSolOptions} = useSolutions()
  // const menus = getOptionsList(isLoading, dataOptions)
  const existError = Object.keys(errors).length > 0 || error
  const params = useParams();
  const redirectToSearch = () => {
    history.push("/suscripcion/solucion");
    dispatch(SolutionsAction.resetAfterSaved());
  }

  const { isCreate, isEdit } = useClaimSolutions();

  // console.log(menuData)
  // const menus = params.id ? getOptionsList(isLoading, menuData) : []
  // const menusActive = menus.filter(menu => menu.isSelected === true)

  useEffect(() => {
    dispatch(SolutionsAction.getDataOptions());
  }, [])

  useEffect(() => {
    if (params.id) {
      updateTitle({
        title: "Administración de Servicio",
        subtitle: "Soluciones",
        description: title,
        previousUrl: "/suscripcion/solucion"
      })
      if (solution?.data) {
          setValue("name", solution.data.name);
          setValue("id", solution.data.id);
          setMenuData(solution.data.options)
      } 
      if (solution?.isSaved)
          redirectToSearch();
    }
  }, [solution]);

  useEffect(()=> {
    if (!params.id) {
      setValue("name", '');
      setValue("id", '');
      getMenuList(isLoading, dataOptions)
      updateTitle({
        title: "Administración de Servicio",
        subtitle: "Soluciones - Nuevo"
      })
      
      if (solution?.isSaved)
          redirectToSearch();
    }

    if (isFirstLoading) {
      setFirstLoading(false);
      if (params.id)
          getInstitutionById(params.id)
         
    }
  }, [isLoading, solution?.isSaved])

  useEffect(() => {
    const menus = params.id ? getOptionsList(isLoading, menuData) : []
    if (menus.length > 0) {
      setSolOptions( menus.filter(menu => menu.isSelected === true) )
    }
  }, [menuData])
  
  useEffect(() => {
      if (solOptions) {
        
        const idMenus = solOptions ? solOptions.reduce((acc, option) => {
          acc.push(option.parentKey)
          return acc
        }, []).map(opt => opt - 1) : []
        const idMenusFinal = [...new Set(idMenus)]
      // console.log(solOptions)
      setActiveIndex(idMenusFinal)
      }
  }, [solOptions])

  const getInstitutionById = (institutionId) => {
    dispatch(SolutionsAction.getSolutionById(institutionId));
  }

  const onSubmit = (data) => {
    if (isSolutionOptionsEmpty) {
      setError(true)
      return
    }
    setError(false)
    if (params.id) {
        const model = {
          id: data.id,
          name: data.name,
          solutionOptions: solOptions,
        }
      dispatch(SolutionsAction.saveSolution(model));
      return
    }
    const model = {
        name: data.name,
        solutionOptions: solOptions,
    }
    dispatch(SolutionsAction.saveSolution(model));
  };

  const reject = () => {
      setVisible(false);
  };

  const onChangeTab = (e) => {
    // console.log(e)
    // const idMenus = solOptions.reduce((acc, option) => {
    //   acc.push()
    // }, [])
    setActiveIndex(e.index)

  }

  return (
    <div className="bg-white">
      <form
        className="form-custom p-0"
        onSubmit={handleSubmit(onSubmit)}
      >
      <div className="form-modal p-0">
        <Panel header="DATOS GENERALES" toggleable>
          <div className=" grid  sm:grid-cols-1 md:grid-cols-2 gap-2 ">

            {solution.isLoading ? (
              <Skeleton />
            ): (
              <span className="p-float-label w-full mt-2">
                  <Controller
                      control={control}
                      render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                      }) => (
                        <InputText
                            type="text"
                            id="name"
                            name="name"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            //className="w-full"
                            className={errors?.name ? 'p-invalid w-full' : 'w-full'}
                        />
                      )}
                      name="name"
                      rules={{
                          required: 'El nombre de la solución es requerido',
                      }}
                  />

                  <label htmlFor="name">Nombre de la solución  *</label>
              </span>
            )}

              </div>
              {
                isLoading  ? (
                  <Skeleton height="4.2rem" className="mt-4" />
                ) : (
                  <div className="grid sm:grid-cols-1 md:grid-cols-1 gap-1 mt-2 mb-1">
                    <span className="p-float-label w-full sm:w-1/2 mt-4">
                        <div>
                            <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => onChangeTab(e)}>
                                {
                                   menuData ? menuData.map(item => (
                                    <AccordionTab header={item.description} key={item.id}  >
                                        <ComponenteAcordion options={item.subMenus} modulo={item} handleChange={handleChange} isChecked={isChecked} />
                                    </AccordionTab>
                                  )) : []
                                }
                            </Accordion>
                        </div>
                    </span>
                  </div>
                )
              }
        </Panel>
      </div>
      {
        (existError) && <div className='message error'>
            <ul>
                {errors.name ? (
                    <>
                        <li className="p-error">{errors.name.message}</li>
                    </>
                ) : null}
                {error ? (
                    <>
                        <li className="p-error">Debe escoger una opción como minimo</li>
                    </>
                ) : null}
            </ul>

        </div>
      }
          <div className="flex justify-end gap-4 mt-3">
            <Button
                icon="pi pi-times"
                type="button"
                label="Cancelar"
                onClick={() => { setVisible(true); }}
                className="sig-button sig-secondary mt-4"
            />
            {
              (mode!=='view')&&(<>
                {
                  (isCreate || isEdit)&&
                  <Button
                      icon="pi pi-save"
                      type="submit"
                      disabled={existError ? true : false}
                      label="Guardar"
                      loading={solution?.isSaving || solution?.isLoading}
                      className="sig-button sig-primary mt-4"
                  />
                }
              </>
              )
            }
        </div>
      </form>
      <ConfirmDialog
                visible={visible}
                onHide={() => setVisible(false)}
                message="¿Está seguro que desea cancelar?"
                header="Cancelar"
                icon="pi pi-exclamation-triangle"
                accept={redirectToSearch}
                reject={reject}
            />
    </div>
  )
}



export default SolutionPage;

const ComponenteAcordion = ({options, modulo, handleChange, isChecked}) => {
  return (
    <>
      {
        options.map((item) => (
          <SwitchComponent item={item} key={item.id} handleChange={handleChange} isChecked={isChecked} modulo={modulo} />
        ))
      }
    </>
  )
}


const SwitchComponent = ({item, handleChange, isChecked, modulo}) => {

  
  return (
    <>
      <div className="flex gap-2 items-center mb-1" key={item.id}>
        <InputSwitch  inputId={item.description} name={item.description} id={item.id} checked={isChecked(item, modulo)}  onChange={(e) => handleChange(e, modulo, item) } />
        <p>{item.description}</p>
      </div>
    </>
  )
}
