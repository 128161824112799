/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProjectAction } from "features/customers/store/actions/ProjectAction";
import { TaskAction } from "features/customers/store/actions/TaskAction";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import scrollTop from "shared/utils/scrollTop";
import { lowercaseFirstLetter } from "shared/utils/textTransform";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { v4 as uuidv4 } from "uuid";
import { getBase64 } from "shared/utils/getBase64";
import { CoreConstants } from "core/commons/CoreConstants";
import { getDynamicFields, getDynamicFieldsList } from "shared/utils/getDynamicFields";
import { ProjectConstants } from "features/customers/commons/ProjectConstants";

export const useProject = (title) => {
 
  const history = useHistory();
  const dispatch = useDispatch();
  const {search} = useLocation()
  const [loading, setLoading] = useState({});;

  let query = new URLSearchParams(search)

  let source = query.get("source")

 

  const params = useParams();
  const customerId = params.customerId;
  const currentId = params.id;

  let previousUrl = `/cliente/empresas/editar/${customerId}/proyectos`;
  if(source==='projects'){
    previousUrl= `/proyecto/vista-proyectos/proyecto/${currentId}`
  }

  const { updateTitle } = useSetTitlePage();
  const description = title === "Nuevo" ? "Nuevo Proyecto" : "Editar Proyecto";
  const pageTitle = {
    title: "Clientes",
    subtitle: "Empresas",
    description,
    previousUrl: previousUrl,
  };

  useEffect(() => {
    updateTitle(pageTitle);
  }, []);
  
  const { data: dataMaster, isLoadingDataMaster } = useSelector(
    (state) => state.projectReducer.dataMaster
  );

  useEffect(() => {
    dispatch(ProjectAction.getDataMaster());   
  }, []);
  
  const isNewProject = title === "Nuevo";   
//NO HE USADO ESTO D ENUEVO
  const { 
    isLoading,
    isLoadingProject, 
    status: currentStatus,
    attachments,
    data: currentProject,
    errors: fieldsErrors } = useSelector(
    (state) => state.projectReducer.editProject
  );

  const dynamicFields = dataMaster?.dynamicFields || []

  const dynamicFieldsDatosGenerales = getDynamicFields(dynamicFields, ProjectConstants.SectionsName.DATOS_GENERALES)
  const dynamicFieldsParticipantes = getDynamicFields(dynamicFields, ProjectConstants.SectionsName.PARTICIPANTES)
  const dynamicFieldsEntregables = getDynamicFields(dynamicFields, ProjectConstants.SectionsName.ENTREGABLES)

  let defaultValues = {
    Name: null,
    Description: null,
    BusinessId: null,
    EstimatedOpenDate: null,
    EstimatedCloseDate: null,    
    RealOpenDate: null,
    RealCloseDate: null,
    AssignedAmount: null,
    ResponsibleId: null,
    StatusId: null,
    SituationId: null, //*

    savedFiles: [],
    addedFiles: [],
  };

  const {
    control,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues, shouldUnregister: true, shouldFocusError: true });
  const valuesFields = watch();

  const editProjectId = () => {
    dispatch(ProjectAction.editProject(currentId));
  }
 
  const [colaboratorList, setColaboratorList] = useState([]);
  const [deliverableList, setDeliverableList] = useState([]);
  
  useEffect(() => {
    if (!isNewProject) {
      editProjectId();
    }
  }, []);
//NO USO ESTO
  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    const finishLoading = !isLoadingDataMaster && !isLoadingProject;
    const hasValues = Object.values(currentProject).length > 0;

    const datas = {
      SituationId: "situation",
      StatusId: "status",
      ResponsibleId: "responsibles",
      BusinessId: "businessList"
    };
    
    if (!isNewProject) {
      setColaboratorList(currentProject.members)
      setDeliverableList(currentProject.deliverables)
      // setColaboratorList(currentProject.Members)
      // setDeliverableList(currentProject.Deliverables)
    }

    if (!isNewProject && finishLoading && hasValues && currentId) {        
      if(currentProject.dynamicFieldValues?.length > 0){
        currentProject.dynamicFieldValues.forEach( dynamicFieldValue => {
          setValue(`dynamicFieldData:${dynamicFieldValue.identifier}`, dynamicFieldValue.value)
        })
      }
      for (let key in defaultValues) {        
        const keyLowercase = key.toLowerCase();
        const keyLowercaseFirstLetter = lowercaseFirstLetter(key);
        // if (currentProject[key]) {
        if (currentProject[keyLowercaseFirstLetter]) {
          if (keyLowercase.includes("id") && keyLowercase !== 'customerid') {            
            // const ID = currentProject[key];          
            const ID = currentProject[keyLowercaseFirstLetter];          
            if(dataMaster[datas[key]])  {
              const value = dataMaster[datas[key]].filter(
                ({ id }) => id === ID
              )[0];
              setValue(key, value);
            }            
          } else {
            // setValue(key, currentProject[key]);
            setValue(key, currentProject[keyLowercaseFirstLetter]);
          }
        } else {

          if (key === 'savedFiles') return setValue(key, attachments);
          // if (key === 'savedFiles') return setValue(key, []);
          if (key === 'addedFiles') return setValue(key, []);
          setValue(key, null);

        }
      }
    }
  }, [isLoadingDataMaster, isLoadingProject]);

  useEffect(() => {
    if (!isLoading && currentStatus === 0) {
      dispatch(ProjectAction.setCurrentProject({ status: null }));
      history.push(previousUrl);
    }
  }, [currentStatus, isLoading]);

  const onSubmit = async(data) => {

    const dynamicDataValues = getDynamicFieldsList(data, dynamicFields, isNewProject, currentProject)

    //validar si se ha creado recién
    let addedFiles = []

    if(data.addedFiles?.length > 0) {
      const addedFilesPromise = await Promise.all(data.addedFiles.map(async(file) => {
        delete file.id;
        const fileBase64 = await getBase64(file);
        let dataBase64 = fileBase64.split("base64,")[1]
        const newFile = {
          fileName: file.name,
          fileType: file.type,
          fileBase64: dataBase64
        }
        return newFile;
      }));
      addedFiles = addedFilesPromise;
    }

    const project = {
      assignedAmount: data.AssignedAmount,
      businessId: data.BusinessId.id,
      customerId: Number(customerId),
      currencyId: dataMaster.currencies.find(currency => currency.code === CoreConstants.Currencies.SOLES)?.id,
      projectTeam: colaboratorList.map(x => {
        x.id = typeof x.id == 'string'? 0: x.id
        return x;
      }),
      projectDeliverables: deliverableList.map(x => {
        x.id = typeof x.id == 'string'? 0: x.id
        return x;
      }),
      name: data.Name,
      description: data.Description,
      estimatedCloseDate: data.EstimatedCloseDate,
      estimatedOpenDate: data.EstimatedOpenDate,
      responsibleId: data.ResponsibleId.id,
      realCloseDate: data.RealCloseDate,
      realOpenDate: data.RealOpenDate,
      statusId: data.StatusId.id,
      situationId: data.SituationId.id,
      savedFiles: data.savedFiles?.map(({ id }) => id),
      addedFiles: addedFiles,
      id: isNewProject ? null : Number(currentId),
      dynamicDataValues,
  }
  
  dispatch(ProjectAction.save({ project, isNewProject }));
  };

  const [showModalCancel, setShowModalCancel] = useState(false);
  const cancelAccept = () => {
    const { description, ...rest } = pageTitle;
    updateTitle(rest);
    history.push(previousUrl);
  };

  const cancelDenied = () => {
    setShowModalCancel(false);
  };

  const setActiveIndex = (v) => {
    history.push("/cliente/empresas/proyectos");
  };

  const createColaborator = () => {
    setColaboratorList((prev) => [
      ...prev,
      {
        memberId: null,
        roleId: null,
        projectId: isNewProject?0:Number(currentId),
        id: uuidv4(),
      },
    ]);
  };

  const updateColaborator = ({ id, name, value }) => {    
    const currentColaboratorList = colaboratorList.map((colaborator) => {
      if (colaborator.id === id) colaborator[name] = value;
      return colaborator;
    });
    setColaboratorList(currentColaboratorList);
  };

  const colaboratorDelete = (id) => {
    const currentColaboratorList = colaboratorList.filter(
      (colaborator) => colaborator.id !== id
    );
    setColaboratorList(currentColaboratorList);
  };

  const createDeliverable = () => {
    setDeliverableList((prev) => [
      ...prev,
      {
        name: "",
        beginDate: null,
        endDate: null,
        deliveryDate: null,
        projectId: isNewProject?0:Number(currentId),
        Tasks: [],
        id: uuidv4(),
      },
    ]);
  };

  const updateDeliverable = ({ id, name, value }) => {  
    const currentDeliverableList = deliverableList.map((deliverable) => {
      if (deliverable.id === id) deliverable[name] = value;
      return deliverable;
    });   
    setDeliverableList(currentDeliverableList);
  };

  const deliverableDelete = (id) => {
    const currentDeliverableList = deliverableList.filter(
      (deliverable) => deliverable.id !== id
    );
    setDeliverableList(currentDeliverableList);
  };

  const taskDelete = async (taskId, documentId, sourceId) => {
    await TaskAction.deleteTaskBoolean(taskId, documentId, sourceId, currentId)
    .then((success) => {if(success) updateTasksFromDeliverable(documentId)})
  }

  const updateTasksFromDeliverable = async (idDeliverable) => {
    setLoading( pre => ({
      ...pre,
      [idDeliverable]: true,
    }))
    ProjectAction.getTasksFromDeliverable(idDeliverable)
    .then(( value ) => {
      updateDeliverable({ id: idDeliverable, name: 'Tasks', value});
    })
    .finally(()=>{
      setLoading( pre => ({
        ...pre,
        [idDeliverable]: false,
      }))
    })
  }

  return {
    cancelAccept,
    cancelDenied,
    setShowModalCancel,
    setActiveIndex,
    showModalCancel,
    isLoading,
    isLoadingProject,
    isLoadingDataMaster,
    data: dataMaster,
    control,
    valuesFields,
    handleSubmit,
    onSubmit,
    fieldsErrors,
    errors,
    colaboratorList,
    createColaborator,
    updateColaborator,
    colaboratorDelete,
    deliverableList,
    createDeliverable,
    updateDeliverable,
    deliverableDelete,
    taskDelete,
    isNewProject,
    currentId,
    editProjectId,
    updateTasksFromDeliverable,
    setValue,
    loading,
    dynamicFieldsDatosGenerales,
    dynamicFieldsParticipantes,
    dynamicFieldsEntregables,
  };
}