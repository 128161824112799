import React, { Fragment, useEffect } from 'react'
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { useHistory } from 'react-router-dom';
import AdvancedFilterComponent from 'features/indicators/components/Indicator/AdvancedFilterComponent';
import TableComponent from 'features/indicators/components/Indicator/TableComponent';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import FilterComponent from 'features/indicators/components/Indicator/FilterComponent';
import IndicatorModalImport from 'features/indicators/components/Indicator/IndicatorModalImport';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { IndicatorsAction } from 'features/indicators/store/actions/IndicatorAction';
import { useClaimIndicators } from 'features/indicators/hooks/useClaimIndicators';

const SearchIndicator = () => {
  const { updateTitle } = useSetTitlePage();
  const history = useHistory();
  const dispatch = useDispatch()
  const [displayModal, setDisplayModal] = useState(false)
  const optionsButton = useRef(null);
  const items = [
    {
        label: 'Descargar Plantilla',
        command: (e) => {
          dispatch(IndicatorsAction.downloadTemplate())
        }
    },
    {
        label: 'Cargar Plantilla',
        command: (e) => {
          setDisplayModal(true)
        }
    },
  ];
  useEffect(() => {
    updateTitle({
        title: 'Indicadores',
        subtitle: 'Indicador',
    });
  });

  const { isSearch, isDownload, isCreate, isImport } = useClaimIndicators()

  return (
    <Fragment>
      {
        (isSearch||isDownload)&&(
          <FilterComponent />
        )
      }
        <div className='mt-4'>
          {
            isCreate&&(
            <Button
                icon="pi pi-plus"
                type="button"
                label="Nuevo"
                className="sig-button sig-dark mr-3"
                onClick={() => {
                    history.push('/indicadores/indicador/nuevo');
                }}
            />)
          }
          {
            isImport&&
            <Button 
              label='Importar' className="sig-button sig-dark ml-3"  
              onClick={(event) => optionsButton.current.toggle(event)} 
            />
          }
          <Menu model={items} popup ref={optionsButton} />
        </div>
      <TableComponent />
      <IndicatorModalImport 
        displayModal={displayModal} setDisplayModal={setDisplayModal} title={'Importar Indicadores'}
      />
      <AdvancedFilterComponent /> 
    </Fragment>
  )
}

export default SearchIndicator