import { apiService } from 'core/services/ApiService';
const currentModule = "management/api/Module";

const modulesSearch = ({ fields = {}, change }) => {
  let initialFields = {
    "page": 1,
    "pageSize": 10,
    "columnOrder": 'name',
    "order": 'asc',
    "fromDate": null,
    "toDate": null,
    "name": null,
  };
  if (change) initialFields = { ...initialFields, ...fields };  
  return apiService.Post(`${currentModule}/Search`, initialFields);
};

const generateReport = (info) =>{
  let fields ={
    "name": null,
    "fromDate": null,
    "toDate": null,
    "rowStatus": true
  }
  fields = { ...fields, ...info };
  return apiService.Post(`${currentModule}/GenerateReport`,fields, {
    responseType: 'blob',
  });
}


const getDataMaster = () =>{
  return apiService.Post(`${currentModule}/GetDataMaster`, {});  
}


const saveModule = (module) =>{
  return apiService.Post(`${currentModule}/Save`, module);
}

const deleteModule = (id) =>{  
  return apiService.Delete(`${currentModule}/Delete?id=${id}`);
}

const getModuleById = (id) =>{  
  return apiService.Get(`${currentModule}/GetModuleById?id=${id}`);
}

export const ModulesIsoService = {
  modulesSearch,
  generateReport,
  getDataMaster,
  saveModule,
  deleteModule,
  getModuleById
};