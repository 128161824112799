import { RrhhConstants } from "features/rrhh/commons/RrhhConstants";

const initialState = {
    dataMaster: {
        isLoading: false,
        departmentParent: null,
        manager: null
    },
    department: {
        data: null,
        isSaving: false,
        isSaved: false,
        isDeleted: false,
        isLoading: false
    },
    departments: {
        isLoading: false,
        data: null
    },
    advancedFilter: {
        showModal: false,
        values: [],
        columnOrder: 'name',
        order: 'asc',
        page: 1,
        pageSize: 10,
        filter: {}
    },
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {

        case RrhhConstants.Accion.Departments.ADVANCED_FILTER_TOGGLE:
            return {
                ...state,
                advancedFilter: {
                    ...state.advancedFilter,
                    ...action.payload
                }
            };

        case RrhhConstants.Accion.Departments.UPDATE_FILTER:
            return {
                ...state,
                advancedFilter: state.advancedFilter
            };

        case RrhhConstants.Accion.Departments.SEARCH_DEPARTMENTS:
            return {
                ...state,
                departments: {
                    isLoading: action.payload.isLoading,
                    data: action.payload.data
                }
            };

        case RrhhConstants.Accion.Departments.GET_DEPARTMENT_BY_ID:
            return {
                ...state,
                department: {
                    data: action.payload.data,
                    isSaving: false,
                    isLoading: action.payload.isLoading
                }
            };

        case RrhhConstants.Accion.Departments.SAVE_DEPARTMENT:
            return {
                ...state,
                department: {
                    data: action.payload.currentDepartment,
                    isSaving: action.payload.isSaving,
                    isSaved: action.payload.isSaved
                }
            };

        case RrhhConstants.Accion.Departments.DELETE_DEPARTMENT:
            return {
                ...state,
                department: {
                    isDeleted: action.payload.isDeleted
                }
            };

        case RrhhConstants.Accion.Departments.RESET_AFTER_SAVED:

            return {
                ...state,
                department: {
                    data: null,
                    isSaving: false,
                    isSaved: false,
                    isLoading: false,
                    isDeleted: false
                }
            };

        case RrhhConstants.Accion.Departments.GET_DATA_MASTER:
            return {
                ...state,
                dataMaster: {
                    departmentParent: action.payload.departmentParent,
                    manager: action.payload.manager,
                    isLoading: action.payload.isLoading
                }
            };

        case RrhhConstants.Accion.Departments.DOWNLOAD_REPORT_STATUS:
            return {
                ...state,
                departments: {
                    ...state,
                    isLoading: action.payload.isLoading,
                },
            };
        default:
            return state;

    }
}
