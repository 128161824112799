import { SubscriptionConstants } from "features/subscription/commons/SubscriptionConstants";
import { SubscriptionsService } from "features/subscription/services/SubscriptionsService";
import { CoreConstants } from "core/commons/CoreConstants";
import { toastAction } from "core/store/actions/ToastAction";
import FileSaver from "file-saver";
import moment from "moment";
const setDataMaster = (infoData) => {
  return (dispatch) =>
    dispatch({
      type: SubscriptionConstants.Accion.Subscriptions.DATA_MASTER,
      payload: infoData,
    });
};
const getDataMaster = () => {
  return (dispatch) => {
    dispatch(setDataMaster({ loadingDataManager: true }));
    SubscriptionsService.getDataMaster()
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          let { country, customers, optionISO, status } = data.data;
          let infoData = {
            loadingDataManager: false,
            dataManager: {
              country,
              customers,
              optionISO,
              status,
            },
          };
          dispatch(setDataMaster(infoData));
          dispatch(getSubscriptions({}));

          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.success("Roles y Privilegios", data.message));
          dispatch(setDataMaster({ loadingDataManager: false }));
        }
      })
      .catch((error)=>{
        dispatch(setDataMaster({ loadingDataManager: false }));
      });
  };
};

const setSubscriptions = (fields) => {
  return (dispatch) =>
    dispatch({
      type: SubscriptionConstants.Accion.Subscriptions.SEARCH_SUBSCRIPCIONES,
      payload: { ...fields },
    });
};

const toggleModalFilters = (payload) => {
  return (dispatch) =>
    dispatch({
      type: SubscriptionConstants.Accion.Subscriptions.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const getSubscriptions = (fields) => {
  return (dispatch, getState) => {
    const subscriptions = getState().subscriptionsReducer.subscriptions;
    fields.columnOrder = subscriptions.currentCol;
    fields.order = subscriptions.order;
    dispatch(setSubscriptions({ loading: true }));
    SubscriptionsService.getSubscriptions(fields)
      .then(({ data }) => {
        const status = data?.status ?? "";
        if (status === CoreConstants.HttpResponse.OK) {
          const { results, ...rest } = data.data;
          results.forEach((result) => {
            if (result.fromDate) {
              result.fromDate = moment(
                result.fromDate
              ).format(CoreConstants.Format.LOCALE);
            }
            if (result.fromDate) {
              result.toDate = moment(result.toDate).format(
                CoreConstants.Format.LOCALE
              );
            }
            if (result.activationDate) {
              result.activationDate = moment(
                // result.activationDate
                result.creationDate
              ).format(CoreConstants.Format.LOCALE);
            }
          });
          let objFields = {
            loading: false,
            data: results,
            currentData: results,
            pagination: { ...rest },
            first: 0,
          };
          if (fields.fields?.columnOrder) {
            objFields.currentCol = fields.fields.columnOrder;
          }
          if (fields.fields?.order) {
            objFields.order = fields.fields.order;
          }
          dispatch(setSubscriptions(objFields));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.error("Roles y Privilegios", data.message));
          dispatch(setSubscriptions({ loading: false }));
          return;
        } else {
          dispatch(toastAction.error("Roles y Privilegios", data.message));
          dispatch(setSubscriptions({ loading: false }));
          return;
        }
      })
      .catch((error)=>{
        dispatch(setSubscriptions({ loading: false }));
      });
  };
};

const setFilterValues = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SubscriptionConstants.Accion.Subscriptions.SET_FILTER_VALUES,
      payload,
    });
  };
};

const removeFilterValues = (field) => {
  return (dispatch, getState) => {
    let { values } = getState().subscriptionsReducer.subscriptionsFilter;
    console.log(values)
    let payload = [];
    if (values.length > 0)
      payload = values.filter((val) => val.field !== field);
    let fields = getFields(payload);
    dispatch(getSubscriptions(fields));
    dispatch({
      type: SubscriptionConstants.Accion.Subscriptions.REMOVE_FILTER_VALUES,
      payload,
    });
  };
};

const getFields = (values) => {
  let fields = {};
  values.forEach((value) => {
    if (value.field === "from" || value.field === "to") {
      fields[value.field] = moment.parseZone(value.date).utc().format();
    } else {
      if (value.field === "customerId") fields[value.field] = parseInt(value.id);
      if (value.field === "statusId") fields[value.field] = parseInt(value.id);
      if (value.field === "customerName") fields[value.field] = value.value;
      if (value.field === "usersQuantity") fields[value.field] = value.number;
      if (value.field === "numberActiveUsers") fields[value.field] = value.number;      
    }
  });
  return fields;
};

const getFilterValues = (fields) => {
  let fieldsTemp = {};
  fields.forEach((field) => {
    if (field.date) {
      fieldsTemp[field.field] = moment.parseZone(field.date).utc().format();
    } else if (field.field === "customerName")
      fieldsTemp[field.field] = field.value
    else
      fieldsTemp[field.field] = parseInt(field.id);
  });
  fields = fieldsTemp;

  return {
    fields,
  };
};
const searchTable = ({ page }) => {
  return (dispatch, getState) => {
    let { values } = getState().subscriptionsReducer.subscriptionsFilter;
    const { fields } = getFilterValues(values);
    fields.page = page;
    dispatch(getSubscriptions({ ...fields }));
  };
};

const deleteCustomerId = (id) => {
  return (dispatch) => {
    dispatch(setSubscriptions({ loading: true }));
    SubscriptionsService.deleteCustomerId(id)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(
            toastAction.success(
              "Gestión de Subscripciones",
              "La Subscripción ah sido eliminado exitósamente"
            )
          );
          dispatch(getSubscriptions({}));
          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn("Roles y Privilegios", data.message));
          dispatch(setSubscriptions({ loading: false }));
        } else {
          dispatch(toastAction.warn("Roles y Privilegios", data.message));
          dispatch(setSubscriptions({ loading: false }));
        }
      })
      .catch((error)=>{
        dispatch(setSubscriptions({ loading: false }));
      });
  };
};

const generateReport = () => {
  return (dispatch, getState) => {
    dispatch(setDataMaster({ loadingDataManager: true }));
    let { values } = getState().subscriptionsReducer.subscriptionsFilter;
    let { fields } = getFilterValues(values);
    SubscriptionsService.generateReport(fields)
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        FileSaver.saveAs(blob, "Subscripciones.xls");
        dispatch(setDataMaster({ loadingDataManager: false }));
        return;
      })
      .catch((err) => {
        dispatch(setDataMaster({ loadingDataManager: false }));
      });
  };
};

const setSubscriptionsEdit = (info) => {
  return (dispatch) =>
    dispatch({
      type: SubscriptionConstants.Accion.Subscriptions.EDIT_SUBSCRIPTIONS,
      payload: info,
    });
};

const getInfoSubscription = (id) => {
  return (dispatch) => {
    dispatch(setSubscriptionsEdit({ loadingEdit: true }));
    SubscriptionsService.getSubscriptionById(id)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          let data_info = data.data;
          let infoData = {
            customers: data_info.customers,
            loadingEdit: false,
            dataSubscription: id !== null ? data_info.subscription : {},
            EmpresaSelect:{},
            users: data_info.users
          };
          if(id!==null){
            infoData.EmpresaSelect.documentNumber = infoData.dataSubscription.documentNumber
            infoData.EmpresaSelect.businessLineName = infoData.dataSubscription.businessLineName
            infoData.EmpresaSelect.businessSector = infoData.dataSubscription.businessSector
          }
          dispatch(setSubscriptionsEdit(infoData));

          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn("Roles y Privilegios", data.message));
        }else{
          dispatch(toastAction.error("Roles y Privilegios", data.message));
        }
      })
      .catch((error)=>{
        dispatch(setSubscriptionsEdit({ loadingEdit: false }));
      });
  };
};

const saveSubscription = (info) => {
  return (dispatch) => {
    dispatch(setDataMaster({ loadingDataManager: true }));
    SubscriptionsService.saveSubscription(info)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          let infoData = {
            loadingSave: false,
            isSave: true,
          };
          dispatch(
            toastAction.success(
              "Registro de Suscripción",
              "Suscripción registrado exitosamente"
            )
          );
          dispatch(setSubscriptionsEdit(infoData));
          dispatch(setDataMaster({ loadingDataManager: false }));

          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn("Roles y Privilegios", data.message));
          dispatch(setDataMaster({ loadingDataManager: false }));
        } else {
          dispatch(toastAction.error("Roles y Privilegios", data.message));
          dispatch(setDataMaster({ loadingDataManager: false }));
        }
      })
      .catch((error)=>{
        dispatch(setDataMaster({ loadingDataManager: false }));
      });
  };
};

const modalState = (payload) => {
  return (dispatch) =>
    dispatch({
      type: SubscriptionConstants.Accion.Subscriptions.MODALS,
      payload,
    });
}

const changeStatus = (info,type) =>{
  return (dispatch) => {
    dispatch(setDataMaster({ loadingDataManager: true }));
    SubscriptionsService.changeStatus(info)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          let infoData = {
            cargoSelect: data.data,
            loadingSave: false,
            isSave: true,
          };
          dispatch(
            toastAction.success(
              type === "ACTIVO" ? "Activación de Suscripción" : type === "BLOQUEADO" ? "Bloqueo de Suscripción" : "Cancelación de Suscripción",
              type === "ACTIVO" ? "Suscripción activada exitosamente" : type === "BLOQUEADO" ? "Suscripción bloqueada exitosamente" : "Suscripción cancelada exitosamente"
            )
          );
          dispatch(setSubscriptionsEdit(infoData));
          dispatch(setDataMaster({ loadingDataManager: false }));

          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn("Roles y Privilegios", data.message));
          dispatch(setDataMaster({ loadingDataManager: false }));
        } else {
          dispatch(toastAction.error("Roles y Privilegios", data.message));
          dispatch(setDataMaster({ loadingDataManager: false }));
        }
      })
      .catch((error)=>{
        dispatch(setDataMaster({ loadingDataManager: false }));
      });
  };
}

const renewSubscription = (info) =>{
  return (dispatch) => {
    dispatch(setDataMaster({ loadingDataManager: true }));
    SubscriptionsService.renewSubscription(info)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          let infoData = {
            cargoSelect: data.data,
            loadingSave: false,
            isSave: true,
          };
          dispatch(
            toastAction.success("Renovación suscripción","Suscripcipon renovada exitosamente")
          );
          dispatch(setSubscriptionsEdit(infoData));
          dispatch(setDataMaster({ loadingDataManager: false }));

          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn("Roles y Privilegios", data.message));
          dispatch(setDataMaster({ loadingDataManager: false }));
        } else {
          dispatch(toastAction.error("Roles y Privilegios", data.message));
          dispatch(setDataMaster({ loadingDataManager: false }));
        }
      })
      .catch((error)=>{
        dispatch(setDataMaster({ loadingDataManager: false }));
      });
  };
}

export const SubscriptionsAction = {
  getInfoSubscription,
  renewSubscription,
  modalState,
  changeStatus,
  getDataMaster,
  setFilterValues,
  setDataMaster,
  deleteCustomerId,
  getSubscriptions,
  setSubscriptions,
  removeFilterValues,
  toggleModalFilters,
  searchTable,
  getFields,
  generateReport,
  saveSubscription,
  setSubscriptionsEdit,
};
