export class AttachmentsConstants {
  static Accion = class {
    static Attachments = class {
      // static get ADVANCED_FILTER_TOGGLE() {
      //   return '@DEPARTAMENTS.ADVANCED-FILTER-TOGGLE';
      // }

      // static get SEARCH_DEPARTMENTS() {
      //   return '@DEPARTAMENTS.SEARCH_DEPARTMENTS';
      // }

      // static get UPDATE_FILTER() {
      //   return '@DEPARTAMENTS.UPDATE_FILTER';
      // }

      // static get GET_DEPARTMENT_BY_ID() {
      //   return '@DEPARTAMENTS.GET_DEPARTMENT_BY_ID';
      // }

      static get SAVE_ATTACHMENTS() {
        return '@ATTACHMENTS.SAVE_ATTACHMENTS';
      }

      // static get DELETE_DEPARTMENT() {
      //   return '@DEPARTAMENTS.DELETE_DEPARTMENT';
      // }

      // static get GET_DATA_MASTER() {
      //   return '@DEPARTAMENTS.GET_DATA_MASTER';
      // }

      // static get RESET_AFTER_SAVED() {
      //   return '@DEPARTAMENTS.RESET_AFTER_SAVED';
      // }

      // static get DOWNLOAD_REPORT_STATUS() {
      //   return '@DEPARTAMENTS.DOWNLOAD_REPORT_STATUS';
      // }

    };
   
  };
}