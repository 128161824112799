import { ConfigurationConstants } from 'features/configuration/commons/ConfigurationConstants';
import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from 'core/store/actions/ToastAction';
import { ApprovalFlowService } from 'features/configuration/services/ApprovalFlowService';
import moment from 'moment';
import FileSaver from 'file-saver';

const search = () => {
  const setFlow = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ConfigurationConstants.Accion.ApprovalFlow.SEARCH_FLOW,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setFlow({
      dispatch: dispatch,
      isLoading: true,
      data: null,
    });

    const criteria = {};
    const advancedFilter = getState().approvalFlowReducer.advancedFilter;
    const filter = getState().approvalFlowReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;


    criteria.to = filter.to;
    criteria.from = filter.from;
    // criteria.name = filter.name;
    criteria.workflowName = filter.name;
    criteria.sourceTypeId = filter.sourceTypeId?.id;
    // criteria.sourceTypeName = filter.sourceType;

    ApprovalFlowService.searchFlow(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK){
          setFlow({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        }else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          setFlow({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Flujo de Aprobación', data.message));
        }else if (data.status > CoreConstants.HttpResponse.OK) {
          setFlow({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Flujo de Aprobación', data.message));
        }
      },
      () => {
        setFlow({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  };
};

const saveApprovalFlow = (criteria) => {
  const isLoading = ({ dispatch, isSaving, isSaved }) => {
    dispatch({
      type: ConfigurationConstants.Accion.ApprovalFlow.SAVE_APPROVAL_FLOW,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch) => {
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
    });
    ApprovalFlowService.saveApprovalFlow(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Flujo de Aprobación', 'El flujo se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Flujo de Aprobaciónnnnn', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }else{
          dispatch(toastAction.error('Flujo de Aprobaciónn', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }
      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: ConfigurationConstants.Accion.ApprovalFlow.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload;

    if (advancedFilter.filter.sourceType)
      pines.push({ field: 'sourceType', value: advancedFilter.filter.sourceType });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.sourceTypeId) pines.push({ field: 'sourceTypeId', value: advancedFilter.filter.sourceTypeId.description });

    if (advancedFilter.filter.name)
      pines.push({
        field: 'name',
        value: advancedFilter.filter.name
      });

    advancedFilter.values = pines;
    dispatch({
      type: ConfigurationConstants.Accion.ApprovalFlow.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const getUsers = () => {
  const setUsers = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ConfigurationConstants.Accion.ApprovalFlow.GET_USERS,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setUsers({ dispatch: dispatch, isLoading: true, data: null });
    ApprovalFlowService.getUsers().then(
      ({data}) => {
        if (data.status === CoreConstants.HttpResponse.OK){
          setUsers({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        }else if (data.status < CoreConstants.HttpResponse.OK){
          setUsers({ dispatch: dispatch, isLoading: false, data: null });
        }
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setUsers({ dispatch: dispatch, isLoading: false, data: null });
        }
      },
      () => {
        setUsers({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  }
}

const generateReport = (payload) => {
  const setFlow = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ConfigurationConstants.Accion.ApprovalFlow.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setFlow({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().approvalFlowReducer.advancedFilter.filter;

    criteria.sourceTypeName = filter.sourceType?filter.sourceType.trim():null;
    criteria.to = filter.to?filter.to:null;
    criteria.from = filter.from?filter.from:null;

    ApprovalFlowService.generateReport(criteria)
      .then(({ data }) => {
        setFlow({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Flujo.xls');
      })
      .catch((err) => {
        setFlow({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Fujo de Aprobación', 'Hubo un error al descargar el archivo'));
      });
  };
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: ConfigurationConstants.Accion.ApprovalFlow.RESET_AFTER_SAVED });
};

const deletedFlow = (idFlow) => {
  return (dispatch) => {

    ApprovalFlowService.deletedFlow(idFlow).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Flujo de Aprobación', 'La ficha se eliminó correctamente'));
          dispatch({ type: ConfigurationConstants.Accion.ApprovalFlow.DELETE_FLOW, payload: { isDeleted: true } });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Flujo de Aprobación', data.message));
          dispatch({ type: ConfigurationConstants.Accion.ApprovalFlow.DELETE_FLOW, payload: { isDeleted: false } });
        }else{
          dispatch(toastAction.error('Flujo de Aprobación', data.message));
          dispatch({ type: ConfigurationConstants.Accion.ApprovalFlow.DELETE_FLOW, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: ConfigurationConstants.Accion.ApprovalFlow.DELETE_FLOW, payload: { isDeleted: false } });
      }
    ).catch((error)=>{
    });
  };
}

const getApprovalFlowById = (Id) => {
  const setFlow = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ConfigurationConstants.Accion.ApprovalFlow.GET_FLOW_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setFlow({ dispatch: dispatch, isLoading: true });
    ApprovalFlowService.getApprovalFlowById(Id).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setFlow({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setFlow({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Flujo de Aprobación', data.message));
        }else{
          setFlow({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.error('Flujo de Aprobación', data.message));
        }
      },
      () => {
        setFlow({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};

export const ApprovalFlowActions = {
  search,
  updateFilter,
  saveApprovalFlow,
  toggleAdvancedFilter,
  generateReport,
  getUsers,
  resetAfterSaved,
  getApprovalFlowById,
  deletedFlow
};