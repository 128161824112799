import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  fieldLabel: '',
  typeOptionId: null,
  options: [],
  required: false
}

const coin = [
  { typeColumn: 'Nuevos Soles', icon: 'S/.' },
  { typeColumn: 'Dólares', icon: '$' },
];

export const ControllerCoin = ({titleModal, normalizeIndentifier, listOptions, positionSoruce, idTypeControl, displayExtraModal, setDisplayExtraModal, dataExtraModal, setDataExtraModal, dinamicStage, setDinamicStage, destinationControl}) => {
  
  const [btnValidate, setBtnValidate] = useState(true)
  const [validateDuplicate, setValidateDuplicate] = useState(false)
  const [selectedCoin, setSelectedCoin] = useState({ typeColumn: 'Nuevos Soles', icon: 'S/.' },);


  const changeTitle = (e) => {
    setDataExtraModal({
      ...dataExtraModal,
      fieldLabel: e.target.value})
  }

  const onHide = () => {
    setDataExtraModal(initialState)
    setValidateDuplicate(false)
    setDisplayExtraModal({title: '', visible: false, mode: "nuevo"})
  }

  const addDinamicOption = () => {
    const data = dataExtraModal
    data.selectedCoin = selectedCoin;

    data.fieldLabel = (data.fieldLabel).trim()

    if(data.fieldLabel===""){
      setValidateDuplicate("La etiqueta del campo es requerida.")
    }else{

      if(displayExtraModal.mode==='nuevo'){

        let validateRepeat = false
        const indentifier = normalizeIndentifier(data.fieldLabel)

        dinamicStage.map(elem =>
          elem.dinamicInput?.map(el => {
            if(el.identifier===indentifier){
              validateRepeat = true
            }
            return el
          })
        )

        if(validateRepeat===true){
          setValidateDuplicate("La etiqueta del campo, ya se encuentra en uso.")
          return
        }else{
          data.stageId = positionSoruce
          data.id = uuidv4()
          data.identifier = indentifier
          data.mode='new'
          const visibleType = listOptions.find(el => el.id===idTypeControl)
          data.visibleSearch=visibleType.visibleSearch
          data.visibleAdvancedFilter=visibleType.visibleAdvancedFilter
          data.visibleSimpleFilter=visibleType.visibleSimpleFilter
          data.idTypeControl = idTypeControl
          const clone = ([...dinamicStage])
          const indexElement = (([...dinamicStage]).findIndex(elem => elem.id===positionSoruce))
          clone[indexElement].dinamicInput.splice(destinationControl,0,data)
          setDinamicStage(clone)
        }

      }else if(displayExtraModal.mode==='editar'){
        const clone = (([...dinamicStage]).map(elem=> {
          if(elem.id===dataExtraModal.stageId){
            let newData = elem.dinamicInput.map(input => {
              if (input.id === dataExtraModal.id) {
                data.id = dataExtraModal.id
                data.typeOptionId = dataExtraModal.typeOptionId?dataExtraModal.typeOptionId:idTypeControl
                return data
              }
              return input
            })
            elem.dinamicInput= newData
            return elem
          }
          return elem
        }))
        setDinamicStage(clone)
      }
      onHide()
    }


  }

  const onCoinChange = (e) => {
    setSelectedCoin(e.value);
  }

  const selectedCoinTemplate = (option, props) => {
    if (option) {
      return (
          <div className="coin-item coin-item-value flex items-center">
              <div className='ml-3'>{option.icon} {option?.typeColumn}</div>
          </div>
      );
    }
    return (
        <span>
            {props.placeholder}
        </span>
    );
  }

  const coinOptionTemplate = (option) => {
    return (
        <div className="coin-item coin-item-value flex items-center">
          <div className='ml-3'>{option.icon} {option?.typeColumn}</div>
        </div>
    );
  }

  const footer = (
    <div>
        {/* <Button label="Cancelar" className="p-button-text" icon="pi pi-times" onClick={onHide} /> */}
        <Button
          type="button"
          label="Cancelar"
          icon="pi pi-times"
          className="sig-button sig-secondary"
           onClick={onHide}
        />
        <Button
          type="button"
          label="Guardar"
          icon="pi pi-save"
          className="sig-button sig-primary"
          disabled={btnValidate}
          onClick={addDinamicOption}
        />
    </div>
  );

   //validaciones
   useEffect(() => {
    if(dataExtraModal.fieldLabel.trim()!==''){
      setBtnValidate(false)
    }else{
      setBtnValidate(true)
    }
  },[dataExtraModal])

  return (
    <>
      <Dialog header={titleModal} footer={footer} visible={displayExtraModal?.visible} style={{width: '35vw'}} modal onHide={onHide}>
          <hr/>
          <div className=" grid grid-cols-2 gap-2 mt-2">
            <span className="p-float-label col-span-2 w-f mt-4">
              <InputText id="textAddSelect" value={dataExtraModal.fieldLabel} autoFocus onChange={(e) => changeTitle(e)} />
              <label htmlFor="textAddSelect">Etiqueta del Campo *</label>
            </span>
            <Dropdown value={selectedCoin} options={coin} onChange={onCoinChange} optionLabel="coin" placeholder="Tipo de moneda"
                    valueTemplate={selectedCoinTemplate} itemTemplate={coinOptionTemplate} />
          </div>
          <div className={`rounded bg-red-200 p-5 mt-6 text-sm text-red-600 ${validateDuplicate===false&&'hidden'}`}>
            <ul>
              <li>{validateDuplicate}</li>
            </ul>
          </div>
        </Dialog>
      </>
  )
}
