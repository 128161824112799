import Attachment from "./Attachment"
const ListAttachments = ({files, deleteAttachment, isFullWidth}) =>{
  return (
    <div className="mt-4 grid grid-cols-8 gap-3">
      {files.map((file,index) => {
        return (
          <Attachment file={file} deleteAttachment={deleteAttachment} key={index} isFullWidth={isFullWidth} />
        )
      })}
    </div>
  )
}

export default ListAttachments