

import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Column } from 'primereact/column';
import { SelectButton } from 'primereact/selectbutton';
import moment from 'moment';
import { Paginator } from 'primereact/paginator';
import { useHistory } from 'react-router';
import { Fragment } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import '../../pages/department/search/SearchDepartmentPage';
import { CoreConstants } from 'core/commons/CoreConstants';
import { Menu } from 'primereact/menu';
import useEmployees from 'features/rrhh/hooks/employees/useEmployees'
import useTable from 'features/rrhh/hooks/employees/useTable';
import { useClaimPeople } from 'features/rrhh/hooks/useClaimPeople';
import { Link } from 'react-router-dom';
const TableComponent = () => {
    const history = useHistory();
    const [idEmployee, setIdEmployee] = useState(null);
    const [visible, setIsVisible] = useState(false);
    const [isFirstLoading, setFirstLoading] = useState(true);
    const [sortOrder, setSortOrder] = useState(null);
    const {
        data,
        deleteEmployeeId,
        pagination,
    } = useEmployees();
    const typeViews = [
        { icon: 'pi pi-list', value: CoreConstants.TypeView.LIST },
        { icon: 'pi pi-microsoft', value: CoreConstants.TypeView.CANVA }
    ];

    const { isView ,isEdit, isDelete } = useClaimPeople();

    const columns = [
        { field: 'employeeType', header: 'Tipo Empleado' },
        { field: 'areaName', header: 'Departamento' },
        { field: 'positionName', header: 'Cargo' },
        { field: 'companyPhone', header: 'Telef. Laboral' },
        { field: 'companyEmail', header: 'Correo Laboral' },
        { field: 'employeeSituationName', header: 'Situación' }

    ];

    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);
    const actionsHeader = useRef(null);
    const menu = useRef(null);
    const styles ={
        Activo:{
            border:"1px solid #8CC9A6",
            padding:"0rem 0.50rem",
            borderRadius:"5px"
        },
        Cesado:{
            border:"1px solid #F6697C",
            padding:"0rem 0.50rem",
            borderRadius:"5px"
        }
    }

    let items = [
        {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: () => {
                history.push("/rrhh/personas/editar/" + idEmployee)
            }
        },
        {
            label: 'Eliminar',
            icon: 'pi pi-times',
            command: () => {
                setIsVisible(true)
            }
        }
    ];

    if (!isEdit)
        items = items.filter(c => c.label !== 'Editar');
    if (!isDelete)
        items = items.filter(c => c.label !== 'Eliminar');

    const getViewField = (rowData, col) => {

        if (isView)
          return <Link className="underline text-blue-700" to={{ pathname: `/rrhh/personas/visualizar/` + rowData.id }} >{rowData.fullName}</Link>
        else
          return rowData.fullName;
      }

    const onBodyStatusTemplate  = (rowData) =>{
        return <h1 className='text-center' style={styles[rowData.employeeSituationName]}>{rowData.employeeSituationName}</h1>
    }

    const columnComponents = selectedColumns.map(col => {
        return <Column key={col.field} field={col.field}   header={col.header} sortable body={col.field ===  "employeeSituationName" ? onBodyStatusTemplate : null } />;
    });

    const {
        onPageChange,
        firstTable,
        rowsTable
    } = useTable();

    // const onPage = (event) => { }
    // const onSort = (event) => { }

    useEffect(() => {
        if (isFirstLoading) {
            tryParseFilter();
            setFirstLoading(false);
            //setSortField("name");
            setSortOrder(1);
            // dispatchEvent(.search());
        }
    }, []);

    const actionHeaderTemplate = (rowData) => {
        return <i
            key="action"
            className="pi pi-ellipsis-v cursor-pointer"
            onClick={(e) => {
                actionsHeader.current.toggle(e)
            }}></i>;
    }

    const actionBodyTemplate = (rowData) => {
        if(isEdit||isDelete){
            return <i
                key={rowData.id}
                className="pi pi-ellipsis-v cursor-pointer"
                onClick={(event) => {
                    setIdEmployee(rowData.id)
                    menu.current.toggle(event)
                }}></i>
        }else{
            return <div></div>
        }
    }

    const tryParseFilter = () => {
        try {
            const filters = JSON.parse(localStorage.getItem("employees-filter"));
            //debugger;
            if (filters)
                setSelectedColumns(filters);
            else
                setSelectedColumns(columns);
        } catch (error) {
            localStorage.removeItem('employees-filter');
        }
    }

    const onColumnToggle = (e, item) => {
        let _selectedColumns = [...selectedColumns];

        if (e.checked)
            _selectedColumns.push(item);
        else
            _selectedColumns = _selectedColumns.filter(c => { return c.field !== e.value });

        setSelectedColumns(_selectedColumns);
        localStorage.setItem("employees-filter", JSON.stringify(_selectedColumns));
        ;
    }

    const getColumns = () => {
        let array = [];
        columns.map((item, index) => {
            const column = <div key={item.field} className="p-field-checkbox py-1">
                <Checkbox
                    inputId={item.field}
                    name="column"
                    value={item.field}
                    onChange={(e) => onColumnToggle(e, item)}
                    checked={selectedColumns.some((x) => x.field === item.field)}
                />
                <label class="ml-1" htmlFor={item.field}>{item.header}</label>
            </div>
            array.push(column);
        });
        return array;
    };

    return (
        <Fragment>

            <div className="flex justify-end mb-2">
                <SelectButton
                    unselectable={false}
                    value={typeView}
                    options={typeViews}
                    onChange={(e) => setTypeView(e.value)}
                    itemTemplate={(option) => { return <i className={option.icon}></i>; }}
                />
            </div>

            {typeView === CoreConstants.TypeView.CANVA && <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2">
                {
                    data.map((item, index) => {
                        return (
                            <div className="block p-4 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

                                <div className="flex justify-between">
                                    {
                                        isView?(
                                            <Link className="underline text-blue-700" to={{ pathname: `/rrhh/personas/editar/` + item.id }} ><h5>{item.fullName}</h5></Link>
                                        ):(
                                        <h5 className="mb-1 text-md font-bold   text-gray-900 dark:text-white">
                                            {item.fullName}
                                        </h5>
                                        )
                                    }
                                    <span className="h-full text-xs float-right px-2 py-1 text-black bg-white rounded-full border border-gray-200"  style={styles[item.employeeSituationName]}>
                                        {item.employeeSituationName}
                                    </span>
                                    {
                                        (isEdit || isDelete)&&(
                                            <i
                                                key={item.id}
                                                className="pi pi-ellipsis-v cursor-pointer"
                                                onClick={(e) => {
                                                    setIdEmployee(item.id)
                                                    menu.current.toggle(e)
                                                }}></i>
                                        )
                                    }
                                </div>
                                <div className="block">
                                    <h5 className="text-xs text-gray-700 dark:text-gray-400">
                                        {item.companyEmail} -  {item.companyPhone}
                                    </h5>
                                    <h5 className="text-xs text-gray-700 dark:text-gray-400">
                                        {item.positionName}
                                    </h5>
                                    <h5 className="text-xs text-gray-700 dark:text-gray-400">
                                        {item.areaName}
                                    </h5>

                                </div>
                                <h5 className="text-xs text-gray-700 dark:text-gray-400 flex justify-end">
                                    {item.branchName}
                                </h5>
                            </div>
                        );
                    })
                }

            </div>}


            {typeView === CoreConstants.TypeView.LIST &&
                <div className="table-main table-roles mt-3 ">
                    <DataTable
                        value={data}
                        stripedRows
                        responsiveLayout="scroll"
                        rows={rowsTable}
                        size="small"
                    // onPage={(e) => onPage(e)}
                    // onSort={(e) => onSort(e)}>
                    >
                        <Column field="fullName" header="Empleado" body={getViewField} sortable />
                        {columnComponents}
                        <Column header={actionHeaderTemplate} body={actionBodyTemplate} ></Column>
                    </DataTable>
                    <Paginator
                        className="paginator-custom mt-4"
                        first={firstTable}
                        totalRecords={pagination?.rowCount}
                        rows={rowsTable}
                        onPageChange={onPageChange}
                    // rowsPerPageOptions={[5, 10, 15]}
                    ></Paginator>
                </div>
            }

            <OverlayPanel ref={actionsHeader} showCloseIcon style={{ width: '300px' }}>{getColumns()}</OverlayPanel>

            <Menu model={items} popup ref={menu} id="popup_menu" />
            <ConfirmDialog visible={visible} onHide={() => setIsVisible(false)} message="Estás seguro de querer eliminar el empleado ?"
                header="Confirmation" icon="pi pi-exclamation-triangle" accept={() => {
                    deleteEmployeeId(idEmployee)
                }} reject={() => setIsVisible(false)} />
        </Fragment>
    );
};

export default TableComponent;
