/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { DragDropContext } from "react-beautiful-dnd"

import { Button } from "primereact/button"
import { ConfirmDialog } from "primereact/confirmdialog"

import { BusinessSheetConstants } from "features/business/commons/BusinessSheetConstants"

import { ModalSharedSheet } from "features/business/components/businessSheet/ModalSharedSheet"
import { Notifications } from "features/business/components/businessSheet/Notifications"
import { ListControlsConfigurations } from "features/business/components/businessSheet/ListControlsConfigurations"
import { GeneralData } from "features/business/components/businessSheet/GeneralData"
import { Preguntas } from "features/business/components/businessSheet/Preguntas"

import { useQuestions } from "features/business/hooks/businessSheetConfiguration/useQuestions"
import { useFetchData } from "features/business/hooks/businessSheetConfiguration/useFetchData"
import { useFormData } from "features/business/hooks/businessSheetConfiguration/useFormData"

import { useNotifications } from "features/business/hooks/businessSheetConfiguration/useNotifications"
import { useQuestionsTemplate } from "features/business/hooks/businessSheetConfiguration/useQuestionsTemplate"

import "./PageConfiguration.scss"

export const PageConfigurationBusinessSheet = () => {
  const [showModalCancel, setShowModalCancel] = useState(false)
  const [displayModal, setDisplayModal] = useState(false)

  const history = useHistory();
  const params = useParams();
  const businessSheetId = params.id;

  const { newQuestionsTemplate } = useQuestionsTemplate()

  const {
    businessSheet,
    dataMaster,
    isLoadingDataMaster,
    isLoadingSave,
    listControls,
    isLoadingListControls,
  } = useFetchData()

  const { 
    loading,
    questions,
    sections,
    removeSection,
    deleteSection,
    addSection,
    onChangeDescriptionSection,
    addQuestion,
    removeQuestion,
    onChangeOptions,
    removeField,
    onDragEnd,
   } = useQuestions(newQuestionsTemplate)
   
  const {
    errorsQuestions,
    errorsFields,
    errorsLinkedTo,
    setValue,
    handleSubmit,
    control,
    watch,
    errors,
    onError,
    submit,
  } = useFormData(questions, newQuestionsTemplate, sections)

  const {
    notifications,
    setNotifications,
  } = useNotifications(newQuestionsTemplate, setValue)

  const isLoading = loading || isLoadingDataMaster
  const valuesFields = watch();

  return(
    <div>
      <div className="flex gap-x-2">
        <DragDropContext onDragEnd={onDragEnd}>
          <ListControlsConfigurations
            dataMaster = {dataMaster}
            businessSheet = {businessSheet}
            isLoadingListControls = {isLoadingListControls}
            listControls = {listControls}
          />
          <div className="flex-1 grid grid-cols-10 content-divbusiness scroll content-start">
            <GeneralData
              control={control}
              dataMaster={dataMaster}
              isLoading={isLoading}
              valuesFields={valuesFields}
            />
            <Preguntas
              questions={questions}
              addQuestion={addQuestion}
              removeQuestion={removeQuestion}
              onChangeOptions={onChangeOptions}
              removeField={removeField}
              addSection={addSection}
              sections={sections}
              removeSection={removeSection}
              onChangeDescriptionSection={onChangeDescriptionSection}
              deleteSection={deleteSection}
            />
            <Notifications
              data={notifications}
              setData={setNotifications}
              control={control}
            />
            {
              (errorsQuestions.length > 0 || errorsFields.length > 0 || errorsLinkedTo.length > 0 || Object.values(errors).length > 0) &&
              <div className='message error col-span-10'>
                {
                  Object.values(errors).map( error => <li key={error.ref.name} className="p-error">{error.message}</li>)
                }
                {
                  errorsLinkedTo.map( message => <li key={message} className="p-error">{message}</li>)
                }
                {
                  errorsQuestions.map( number => <li key={number} className="p-error">La pregunta {number} no contiene texto</li>)
                }
                {
                  errorsFields.map( number => <li key={number} className="p-error">La pregunta {number} no contiene un campo válido</li>)
                }
              </div>
            }
            <div className="col-span-10">
              <div className="flex justify-end gap-4 mt-3 mr-1 w-full">
                {
                  (businessSheet.statusCode && businessSheet.statusCode !== BusinessSheetConstants.Status.CLOSED) &&
                  <Button
                    icon="pi pi-share-alt"
                    label="Compartir"
                    type='button'
                    onClick={() => setDisplayModal(true)}
                    className="sig-button sig-success mt-4"
                    loading={isLoadingSave}
                  />
                }
                <Button
                    icon="pi pi-times"
                    type="button"
                    label="Cancelar"
                    onClick={() => setShowModalCancel(true)}
                    className="sig-button sig-secondary mt-4"
                    loading={isLoadingSave}
                />
                {
                  (!businessSheet.statusCode || businessSheet.statusCode === BusinessSheetConstants.Status.DRAFT) &&
                  <Button
                    icon="pi pi-save"
                    type="button"
                    label="Guardar"
                    onClick={handleSubmit(submit, onError)}
                    className="sig-button sig-primary mt-4"
                    loading={isLoadingSave}
                  />
                }
              </div>
            </div>
          </div>
        </DragDropContext> 
      </div>      
      <ConfirmDialog
        visible={showModalCancel}
        onHide={() => setShowModalCancel(false)}
        message="¿Está seguro que desea cancelar?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={() => history.push('/negocios/fichas')}
        reject={() => setShowModalCancel(false)}
      />

      <ModalSharedSheet
        displayModal={displayModal}
        setDisplayModal={setDisplayModal}
        currentBusinessSheet={Number(businessSheetId)}
      />
    </div>
  )
}