import AppSettings from "core/config/appsettings";

const FooterComponent = () => {
  return (
    <footer className='w-full flex flex-col items-center justify-around' style={{height: 'var(--sig-footer-height)', background: 'var(--sig-footer-background-color)', color: 'var(--sig-footer-color)', fontSize: 'var(--sig-footer-fontsize)'}}>
      <div>
        {AppSettings.APP_NAME} - Version: {process.env.REACT_APP_VERSION}
      </div>      
    </footer>
  );
};

export default FooterComponent;
