import { useEffect, useState } from "react";
import { TaskAction } from "features/customers/store/actions/TaskAction";
import { CoreConstants } from "core/commons/CoreConstants";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "shared/hooks/useForm";
import moment from "moment";
import { AppActions } from "shared/store/actions/AppAction";

export const useAdvancedFilterTasks = (search = false) => {
  const dispatch = useDispatch();

  const companyId = useSelector(
    (state) => state.tasksReducer.currentAction.companyId
  );
  const sourceId = useSelector(
    (state) => state.tasksReducer.currentAction.sourceId
  );

  const tasksLoading = useSelector((state) => state.tasksReducer.tasks.loading);
  const reportLoading = useSelector(
    (state) => state.tasksReducer.downloadReport.loading
  );
  const { values, showModal } = useSelector(
    (state) => state.tasksReducer.advancedFilter
  );

  const { data } = useSelector((state) => state.tasksReducer.dataMaster);

  const showPiners = values.length > 0;

  const initialValues = {
    title: null,
    fromDate: null,
    toDate: null,
    responsibleId: null,
    isCompleted: false,
  };
  const [formValues, handleChange, reset] = useForm(initialValues);
  const [errors, setErrors] = useState([]);

  const taskName = useSelector(
    (state) => state.appReducer.parameters.taskName
  );
  const [searchHome, setSearchHome] = useState(false)

  const onToggleModal = (showModal) => {
    setErrors([]);
    dispatch(TaskAction.setAdvancedFilter({ showModal }));
  };

  const clearFields = () => {
    reset(initialValues);
    setErrors([]);
    dispatch(
      TaskAction.getTasksByCustomer({
        change: false,
        documentId: companyId,
        sourceId: sourceId,
      })
    );
    dispatch(TaskAction.setAdvancedFilter({ showModal: false, values: [] }));
  };

  const getTasksByCustomer = (sourceTaskId) => {
    dispatch(
      TaskAction.getTasksByCustomer({
        change: false,
        documentId: companyId,
        sourceId: sourceTaskId ? sourceTaskId : sourceId,
      })
    );
  };

  useEffect(() => {
    if(sourceId && search && taskName == null) {
      getTasksByCustomer(sourceId);
    }

    return ()=>{
      dispatch(
        TaskAction.setAdvancedFilter({
          showModal: false,
          values: [],
        })
      );
    }
  }, [sourceId]);

  useEffect(() => {
    if(search){
      if(taskName != null){
        handleChange({target: {name: 'title', value: taskName}})
        setSearchHome(true)
      }
    }
  },[])

  useEffect(() => {
    if(search && searchHome){
      onAdvancedFilter()
      dispatch(AppActions.setParameters({taskName: null}))
      setSearchHome(false)
    }
  },[searchHome])

  useEffect(() => {
    if (values.length === 0) {
      // clearFields();
      setErrors([]);
    }
  }, [values]);

  const buildFilterValues = () => {
    const currentValues = {};
    const currentPiners = [...values];
    for (let valueKey in formValues) {
      const key = valueKey.toLocaleLowerCase();
      const field = valueKey;
      const hasValue = formValues[valueKey];
      if (hasValue) {
        if (key.includes("date")) {
          const value = moment(formValues[valueKey]).format(
            CoreConstants.Format.LOCALE
          );
          const date = formValues[valueKey];
          // currentValues[field] = date;
          const index = currentPiners
            .map((piner) => piner.field)
            .indexOf(field);
          index === -1
            ? currentPiners.push({ field, value, date })
            : currentPiners.splice(index, 1, { field, value, date });
        } else if (key.includes("id")) {
          const option = formValues[valueKey];
          let value = "";
          if (formValues[valueKey].description) {
            value = formValues[valueKey].description;
          } else if (formValues[valueKey].fullName) {
            value = formValues[valueKey].fullName;
          }
          const index = currentPiners
            .map((piner) => piner.field)
            .indexOf(field);
          index === -1
            ? currentPiners.push({ field, value, option })
            : currentPiners.splice(index, 1, { field, value, option });
        } else if (key === "iscompleted") {
          const option = formValues[valueKey];
          const value = formValues[valueKey].description;
          const index = currentPiners
            .map((piner) => piner.field)
            .indexOf(field);
          index === -1
            ? currentPiners.push({ field, value, option })
            : currentPiners.splice(index, 1, { field, value, option });
        } else {
          const value = formValues[valueKey];
          const index = currentPiners
            .map((piner) => piner.field)
            .indexOf(field);
          index === -1
            ? currentPiners.push({ field, value })
            : currentPiners.splice(index, 1, { field, value });
        }
      }
    }

    currentPiners.forEach((piner) => {
      if (piner.field === "isCompleted") {
        currentValues[piner.field] = piner.option.code === "TCO";
      } else if (piner.option) {
        currentValues[piner.field] = piner.option.id;
      } else if (piner.field.includes("Date")) {
        currentValues[piner.field] = piner.date;
      } else {
        currentValues[piner.field] = piner.value;
      }
    });

    return {
      currentPiners,
      currentValues,
    };
  };

  const onAdvancedFilter = (e) => {
    if(e) e.preventDefault();
    setErrors([]);

    let { currentValues, currentPiners } = buildFilterValues();
    // const currentSearchValue =
    //   values.find(({ field }) => field === "title") ?? {};
    // if (Object.values(currentSearchValue).length > 0) {
    //   currentValues.title = currentSearchValue.value;
    //   currentPiners = [
    //     ...currentPiners.filter((p) => p.field !== "title"),
    //     currentSearchValue,
    //   ];
    // }

    dispatch(
      TaskAction.setAdvancedFilter({
        showModal: false,
        values: currentPiners,
      })
    );
    dispatch(
      TaskAction.getTasksByCustomer({
        fields: currentValues,
        change: true,
        documentId: companyId,
        sourceId: sourceId,
      })
    );
  };

  const removePiner = (field) => {
    dispatch(TaskAction.removeFilterValues(field));
  };

  const generateReport = () => {
    dispatch(TaskAction.generateReport({documentId: companyId, sourceId: sourceId}));
  };

  return {
    showModal,
    onToggleModal,
    clearFields,
    onAdvancedFilter,
    tasksLoading,
    reportLoading,
    formValues,
    handleChange,
    showPiners,
    piners: values,
    removePiner,
    errors,
    dataMaster: data,
    generateReport,
  };
};
