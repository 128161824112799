import { Dialog } from 'primereact/dialog';
import { FormTaskComponent } from './FormComponent';

const ModalFormTaskComponent = (props) => {
  const { isOpen, onClose, sourceDocumentId, taskId, sourceDocumentCode, viewMode,projectId, searchHomePage= false, disableInputs} = props;

  return(
  <Dialog visible={isOpen} onHide={onClose} style={{ width: '72vw' }}>
      <FormTaskComponent sourceDocumentId={sourceDocumentId} sourceDocumentCode={sourceDocumentCode} taskId={taskId} setCloseModal={onClose} viewMode={viewMode} projectId={projectId} searchHomePage={searchHomePage} disableInputs={disableInputs}/>
  </Dialog>
  );
};

export default ModalFormTaskComponent;