import { apiService } from "core/services/ApiService";

const getHeatMapByMatrixId = (id) => {
  return apiService.Get(`management/api/RiskManagement/GetHeatMapByMatrixId?matrixId=${id}`)
}

const search = (data) => {
	return apiService.Post('management/api/RiskManagement/Search', data)
}

const getDataMaster = () => {
	return apiService.Get('management/api/RiskManagement/GetDataMaster')
}


const save = (data) => {
	return apiService.Post('management/api/RiskManagement/Save', data)	
}

export const RiskManagementServices = { 
	getHeatMapByMatrixId,
	search,
	getDataMaster,
	save,
}