import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

export const useClaimApprovalFlow = () => {

  const menu = useSelector((state) => state.authReducer.user.menuPrincipal);
  const pathName = useLocation()?.pathname;
  const [isSearch, setSearch] = useState(false);
  const [isDownload, setDownload] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isCreate, setCreate] = useState(false);
  const [isView, setView] = useState(false);

  useEffect(() => {
    search();
    download();
    remove();
    edit();
    create();
    view();
  }, [menu]);

  const search = () => {
    const actionCode = 'SEARCH';
    const optionCode = "COWF";
    setSearch(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  const download = () => {
    const actionCode = 'DOWNLOAD';
    const optionCode = "COWF";
    setDownload(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };
  const remove = () => {
    const actionCode = 'DEL';
    const optionCode = "COWF";
    setDelete(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  const edit = () => {
    const actionCode = 'EDIT';
    const optionCode = "COWF";
    setEdit(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  const create = () => {
    const actionCode = 'NEW';
    const optionCode = "COWF";
    setCreate(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
 };

  const view = () => {
    const actionCode = 'VIEW';
    const optionCode = "COWF";
    setView(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  return {
    isSearch,
    isDownload,
    isCreate,
    isDelete,
    isEdit,
    isView,
  }
}