import { useClaimBusinessLine } from "features/maintenance/hooks/useClaimBusinessLine";
import { BusinessLineAction } from "features/maintenance/store/actions/BusinessLineAction";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useSetTitlePage from "shared/hooks/useSetTitlePage";

const { useForm, Controller } = require("react-hook-form")

const BusinessLinePage = ({title, mode}) => {
  const {updateTitle } = useSetTitlePage()
  const history = useHistory();
  const dispatch = useDispatch();
  const businessLine = useSelector((state) => state.businessLineReducer.businessLine);  
  
  const [isFirstLoading, setFirstLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  const { isCreate, isEdit } = useClaimBusinessLine()

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    name: "",
    id: null,
    code: "",
  });

    
  const params = useParams();

  const redirectToSearch = () => {
    history.push("/mantenimiento/linea-negocio");
    dispatch(BusinessLineAction.resetAfterSaved());
  }

  useEffect(() => {
    if (businessLine?.data) {
        setValue("name", businessLine.data.name);
        setValue("id", businessLine.data.id);
        setValue("code", businessLine.data.code);
    }
    if (businessLine?.isSaved)
        redirectToSearch();
  }, [businessLine]);

  
  useEffect(()=> {
    setValue("name", undefined);
    setValue("id", undefined);
    setValue("code", undefined);
    updateTitle({
      title: "Mantenimiento",
      subtitle: "Línea de negocio",
      description: title,
      previousUrl: "/mantenimiento/linea-negocio/",
    })
    if (isFirstLoading) {
      setFirstLoading(false);
      if (params.id)
          getBusinessLineById(params.id)
    }
  }, [])

  const getBusinessLineById = (businessLineId) => {
    dispatch(BusinessLineAction.getBusinessLineById(businessLineId));
  }

  const onSubmit = (data) => {
    const model = {
        id: data.id,
        name: data.name,
        code: data.code,
    }
    
    dispatch(BusinessLineAction.saveBusinessLine(model));
  };


  const reject = () => {
      setVisible(false);
  };

  return (
    <div className="bg-white">
      <div 
        className="form-custom p-0"
      >
      <div className="form-modal p-0">
        <Panel header="DATOS GENERALES" toggleable>
          
              {
                businessLine.isLoading ?(
                  <Skeleton height="2.2rem" />
                ) : (  
                  <form id="form-business-line" className=" grid  sm:grid-cols-1 md:grid-cols-1 gap-1 mt-2 mb-1" onSubmit={handleSubmit(onSubmit)}>
                    <span className="p-float-label w-full mt-4">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                          <InputText
                            type="text"
                            id="name"
                            name="name"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            className={errors?.name ? 'p-invalid w-full' : 'w-full'}
                          />
                          )}
                          name="name"
                          rules={{
                              required: 'El nombre de la linea de negocio es requerida',
                          }}
                    />
                    
                    <label htmlFor="name">Nombre de la linea de negocio *</label>
                  </span>              
                  </form>
                )
              }                 
        </Panel>        
      </div>
      {
        (errors.name) && <div className='message error'>
            <ul>
                {errors.name ? (
                    <>
                        <li className="p-error">{errors.name.message}</li>
                    </>
                ) : null}
                {errors.code ? (
                    <>
                        <li className="p-error">{errors.code.message}</li>
                    </>
                ) : null}
            </ul>

        </div>
        }
      <div className="flex justify-end gap-4 mt-3">
                    <Button
                        icon="pi pi-times"
                        type="button"
                        label="Cancelar"
                        loading={businessLine?.isSaving || businessLine?.isLoading}
                        onClick={() => { setVisible(true); }}
                        className="sig-button sig-secondary mt-4"
                    />
                    {
                      (mode!=='view')&&(<>
                      {
                        (isCreate || isEdit)&&
                          <Button
                              icon="pi pi-save"
                              type="submit"
                              form="form-business-line"
                              label="Guardar"
                              loading={businessLine?.isSaving || businessLine?.isLoading}
                              className="sig-button sig-primary mt-4"
                          />
                      }
                      </>
                      )
                    }
                </div>
      </div>
      <ConfirmDialog
                visible={visible}
                onHide={() => setVisible(false)}
                message="¿Está seguro que desea cancelar?"
                header="Cancelar"
                icon="pi pi-exclamation-triangle"
                accept={redirectToSearch}
                reject={reject}
            />
    </div>
  )
}

export default BusinessLinePage;
