import { DynamicField } from "./DynamicField"

export const DynamicFieldList = ({ fields, control }) => {
  return(
    fields.map( ({sectionName, label, isRequired, id, fieldTypeCode, identifier, maxLength}) => (
      <div key={id}>
        <DynamicField
          control={control}
          fieldTypeCode={fieldTypeCode}
          identifier={identifier}
          isRequired={isRequired}
          label={label}
          maxLength={maxLength}
        />
      </div>
    ))
  )
}