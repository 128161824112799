import { useSelector } from "react-redux";
import { ProjectsActions } from "../store/actions/ProjectsActions";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
const useDeliverable = (type) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { modalTask, mode } = useSelector(
    (state) => state.projectsReducer.taskEdit
  );
  const { dataProject , loadingEdit } = useSelector(
    (state) => state.projectsReducer.taskEdit
  );
  const showModalTask = (mode = "new") => {
    dispatch(ProjectsActions.setTaskEdit({ modalTask: true, mode: mode }));
  };

  const closeModalTask = () => {
    dispatch(ProjectsActions.setTaskEdit({ modalTask: false }));
  };
  
  const deleteTask = (id,documentId) => {
    dispatch(ProjectsActions.deleteTask(id,documentId))
  }

  useEffect(() => {
      dispatch(ProjectsActions.getProjectById(params.id))
  },[])

  return {
    showModalTask,
    modalTask,
    deleteTask,
    closeModalTask,
    mode,
    dataProject,
    loadingEdit
  };
};

export default useDeliverable;
