import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputSwitch } from 'primereact/inputswitch'
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { useSelector } from 'react-redux'
import { CoreConstants } from 'core/commons/CoreConstants';


const CompaniesTableUploadNotes = ({listCompanies, setListCompanies}) => {

  const [selectedRow, setSelectedRow] = useState(null)
  const {data} = useSelector(state => state.companiesReducer.bulkLoad)

  const columns = [
    {field: 'name', header: 'Nombre de Empresa'},
    {field: 'ruc', header: 'RUC'},
    {field: 'businessLine', header: 'Línea de Negocio'},
    {field: 'registrationDate', header: 'Fecha de Resgistro', body: (data) => data.registrationDate ? moment(data.registrationDate).format(CoreConstants.Format.LOCALE) : ''},
    {field: 'conctactName', header: 'Primer Contacto'},
  ];

  // console.log(data)

  const dynamicColumns = columns.map((col,i) => {
    return <Column key={col.field} field={col.field} header={col.header} body={col.body}/>;
  });

  const switchBodyTemplate = (rowData) => {
    return <SwitchBody rowData={rowData} setListCompanies={setListCompanies} listCompanies={listCompanies} />
  }

  const rowClass = (data) => {
    return {
        'row-errors': data.error === true
    }
  }

  const newData = data.map((company, i) => {
    const newCompany = {
      registrationDate: new Date(company.registrationDate),
      name: company.name,
      ruc: company.ruc,
      conctactName: company.pageContacts[0] ? company.pageContacts[0]?.conctactName : null,
      businessLine: company.businessLine,
      companyId: 0,
      documentTypeId: 0,
      documentNo: company.documentNo ? company.documentNo : company.ruc,
      businessLineId: company.businessLineId,
      businessSectorId: company.businessSectorId ? company.businessSectorId : null,
      countryId: company.countryId,
      departmentId: company.departmentId ? company.departmentId : null,
      cityId: company.cityId ? company.cityId : null,
      districtId: company.districtId ? company.districtId : null,
      rowStatus: true,
      companyCustomerContactList: company.pageContacts?.map(el=> {
        return {
          customerId: el.customerId ? el.customerId : null,
          name: el.conctactName,
          positionId: el.positionId,
          emailAddress: el.mail,
          phoneNo: el.phone,
          rowStatus: true
        }
      }),
      address: company.address,
      phoneNo: company.phone,
      emailAddress: company.mail,
      webPageAddress: company.webPage,
      facebookProfileAddress: company.facebook,
      linkednProfileAddress: company.linkedin,
      instagramProfileAddress: company.instagram,
      error: company.error
    }
    return newCompany
  })

  const newListCompanies = newData.filter(company => company.error === false)

  useEffect(() => {
    let sendData = newListCompanies.map(elem => {
      delete(elem.conctactName)
      return elem
    })
    setListCompanies(sendData)
  }, [data])

  return (
    <DataTable 
      value={newData} size="small" responsiveLayout="scroll" className='empleoyees-table-upload'
      rowClassName={rowClass} 
      selection={selectedRow} onSelectionChange={e => setSelectedRow(e.value)} dataKey="numeroDocumento"
      paginator rows={10} 
    >
      <Column header="" body={switchBodyTemplate}></Column>
        {dynamicColumns}
    </DataTable>
  )
}

export default CompaniesTableUploadNotes

const SwitchBody = ({rowData, setListCompanies, listCompanies}) => {
  const {position, ...newRowData} = rowData

  const handleChange = (e) => {
    const id = e.target.id
    let listOptions = []
    if (listCompanies.length === 0) {
      listOptions.push(newRowData)
    } else {
      const hasOption = listCompanies.some(company => company.ruc === id );
      if (hasOption) {
        listOptions = listCompanies.filter((solOption) =>  solOption.ruc !== id)
      } else {
        listOptions = [...listCompanies, newRowData];
      }
    }
    setListCompanies([...listOptions])
  }

  const isChecked = (newRowData) => {
    if (listCompanies.length === 0) return false;
    if (listCompanies.length > 0) {
       return listCompanies.some(company => company.ruc === newRowData.ruc)
    }
    return false;
  };

  return <InputSwitch inputId={newRowData.ruc} id={newRowData.ruc} checked={isChecked(newRowData)} onChange={handleChange} disabled={rowData.error} />
}