import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { SelectButton } from 'primereact/selectbutton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { ConfirmDialog } from 'primereact/confirmdialog';

import moment from 'moment';

import { CoreConstants } from 'core/commons/CoreConstants';
import typeViews from "shared/utils/typeViews";

import { RiskManagementAction } from 'features/riskManagement/store/actions/RiskManagementAction';

const TableComponent = () => {

	/* TYPE TABLE VIEW ---------------- */
  const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);

	const menu = useRef(null)
	const history = useHistory()
	const [select, setSelect] = useState({})
	const [showDelete, setShowDelete] = useState(false)
	const dispatch = useDispatch()
	const matrix = useSelector( state => state.riskManagementReducer.allMatrix.data?.results)

	useEffect(()=>{
		dispatch(RiskManagementAction.getAllMatrix({
			page: 1,
			pageSize: 10,
			columnOrder: "name",
			order: "asc",
			name: "",
		}))
	},[dispatch])

	const items = [
		{
				label: 'Editar',
				icon: 'pi pi-pencil',
				command: (e) => {
					
			}
		},
		{
			label: 'Eliminar',
			icon: 'pi pi-times',
			command: (e) => {
				setShowDelete(true)
			}
		},
		{
			label: 'Evaluar',
			icon: 'pi pi-align-justify',
						command: () => {
				history.push('/gestion-riesgos/matriz/'+select.id);
			}
		}
	]

	const TableBodyActions = (selectedItem) => {
		return (
			<i
				onClick={(event) => {
					menu?.current?.toggle(event); 
					setSelect(selectedItem)
				}}
				className="pi pi-ellipsis-v cursor-pointer"
			></i>
		);
	};

	return (
		<div>
			<div className="flex justify-end mb-2">
        <SelectButton
          unselectable={false}
          value={typeView}
          options={typeViews}
          itemTemplate={({ icon }) => <i className={icon}></i>}
          onChange={(e) => setTypeView(e.value)}
        />
      </div>
			{ typeView === CoreConstants.TypeView.LIST && 
				<DataTable 
					value={matrix?.map((item, key) => ({...item, creationDate: moment(item.creationDate).format(CoreConstants.Format.DDMMYYYY)}))}
					sortField="name"
					sortOrder={-1}
					emptyMessage="No se encontró data"
          stripedRows
					rowHover
          size="small"
					responsiveLayout="scroll"
				>
					<Column field="name" header="Nombre" sortable></Column>
					<Column field="creatorName" header="Creado Por" sortable></Column>
					<Column field="creationDate" header="Fecha de Creación" sortable></Column>
					<Column
              header={""}
              body={TableBodyActions}
            ></Column>
				</DataTable>}
			{ typeView === CoreConstants.TypeView.CANVA && 
				<div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2  gap-2">
				{matrix?.map((item, index) => {
					return (
						<div
							key={"card" + item.id}
							className="
								block 
								p-3
								max-w-sm
								bg-white
								rounded-lg 
								border 
								border-gray-200 
								shadow-md 
								hover:bg-gray-100 
								dark:bg-gray-800 
								dark:border-gray-700 
								dark:hover:bg-gray-700"
							>
							<div>
								<div className="flex justify-between">
									<h5
										key={"title" + item.id}
										className="mb-1  font-bold text-sm  text-gray-900 dark:text-white"
									>
										{item.name}
									</h5>
									<i
										key={"options" + item.id}
										className="pi pi-ellipsis-v cursor-pointer"
										onClick={(event) => {
											setSelect(item)
											menu.current.toggle(event);
										}}
									></i>
								</div>
								<hr />
								<div className="flex flex-col justify-around h-16 text-xs text-gray-600 dark:text-gray-400">
									<div>
									<h5 key={"category" + item.id}>
										<span className="text-xs text-gray-900 ">Creado Por:</span>{" "}
										{item.creatorName}
									</h5>
									</div>
									<div>
									<h5>
										<span className="text-xs text-gray-900">
											Fecha de Creación:
										</span>{" "}
										<i className="pi pi-calendar">
										{moment(item.creationDate).format(CoreConstants.Format.LOCALE)}
										</i>
									</h5>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
			}
			<Menu model={items} popup ref={menu} />
			<ConfirmDialog
				visible={showDelete}
				onHide={() => setShowDelete(false)}
				message="¿Está seguro que desea eliminar el registro?"
				header="Eliminar"
				icon="pi pi-trash" 
				reject={() => setShowDelete(false)}
				accept={()=> setShowDelete(false)}
			/>
		</div>
	);
};

export default TableComponent;