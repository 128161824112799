import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { ProgressBar } from 'primereact/progressbar';
import { toastAction } from 'core/store/actions/ToastAction';
import '../StudentsTableUploadNotes.scss'
import { StudentsAction } from 'features/customers/store/actions/StudentsAction';
import StudentsAttachment from '../StudentsAttachment';
import StudentsTableUploadNotes from '../StudentsUploadNotes';
import Attachments from 'shared/components/attachments/Attachments';
import { ProgressSpinner } from 'primereact/progressspinner';

const StudentModalImport = ({displayModal, setDisplayModal, title}) => {

  const dispatch = useDispatch()
  const defaultValues = { 
    file: null,
    savedFiles: [],
    addedFiles: [],
  }
  const { setValue, handleSubmit, watch } = useForm({ defaultValues });
  const valuesFields = watch();

  const [isUploaded, setIsUploaded] = useState(false)
  const buttonRef = useRef(null)
  const [visible, setVisible] = useState(false);

  const [error, setError] = useState({})
  const [listErrors, setListErrors] = useState([])

  const [listStudents, setListStudents] = useState([])
  // const [value1, setValue1] = useState(0);
  const interval = useRef(null);
  const { data, isLoading: isLoadingBulkLoad } = useSelector(state => state.studentsReducer.bulkLoad)
  const bulkLoadErrors = useSelector(state => state.studentsReducer.bulkLoadErrors)
  const { isSaved, isLoading } = useSelector(state => state.studentsReducer.studentBulk)


  const existError = Object.keys(error).length > 0

  const onHide = () => {
    setDisplayModal(false)
    setIsUploaded(false)
    setValue('file', null)
    setListStudents([])
    // setValue1(0)
    setError({})
    setListErrors([])
    dispatch(StudentsAction.resetAfterSaved());
  }

  const redirectToSearch = () => {
    onHide()
  }

  const reject = () => {
    setVisible(false);
  };
  
  useEffect(() => {
    if (data) {
      setIsUploaded(true)
    }
  }, [data])

  useEffect(() => {
    if (bulkLoadErrors.length > 0) {
      setListErrors(bulkLoadErrors)
    }
  }, [bulkLoadErrors])

  useEffect(() => {
    if (valuesFields.addedFiles.length > 0) {
      buttonRef.current.click()
      setValue('addedFiles', [])
    }
  }, [valuesFields.addedFiles.length, setValue])

  useEffect(() => {
    // if (!isSaved) {
    //   setValue1(0)
    // }
    // let val = 0;
    if (isSaved) {
      onHide()
      dispatch(StudentsAction.getStudents({}))

    //   interval.current = setInterval(() => {
    //     val += Math.floor(Math.random() * 10) + 8;
    //     if (val >= 100) {
    //         val = 100;
    //         clearInterval(interval.current);
    //         onHide()
    //         dispatch(StudentsAction.getStudents({}))
    //         dispatch(toastAction.success("Estudiantes" ,'Registro exitoso'));
    //     }
    //     setValue1(val);
    //   }, 300);

    //   return () => {
    //       if (interval.current) {
    //           clearInterval(interval.current);
    //           interval.current = null;
    //       }
    //   }
    }
  }, [isSaved])

  const onSubmit = (newFile) => {
    if (!isUploaded) {
      // if (Object.values(newFile).length === 0 || newFile.file === null) {
      //   setError({file: {type: 'required', message: 'Se requiere subir un archivo'}})
      //   return
      // }
      if (newFile.addedFiles.length === 0) {
        setError({file: {type: 'required', message: 'Se requiere subir un archivo'}})
        return
      }
      setError({})
      let bodyFormData = new FormData();
      // bodyFormData.append('File', newFile.file)
      bodyFormData.append('File', newFile.addedFiles[0])
      dispatch(StudentsAction.uploadBatchInfo(bodyFormData));
      return
    }
    if (listStudents.length === 0) return

    dispatch(StudentsAction.saveStudentBulk({students: listStudents}))

    // listStudents.forEach((student, index) => {
    //   if (index === listStudents.length - 1) {
    //     dispatch(StudentsAction.saveStudentBulk(student, `${index + 1}/${listStudents.length}`))
    //   } else {
    //     dispatch(StudentsAction.saveStudentBulk(student))
    //   }
    // })
  }

  return (
    <Dialog header={title} visible={displayModal} breakpoints={{'960px': '78vw'}} style={{width: '55vw'}} onHide={onHide}>
        <form 
          className="form-custom p-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          <hr className='mb-2'/>
          {
            !isUploaded
            ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div className='col-span-2'>
                  {/* <StudentsAttachment setValue={setValue} iconTypeOfFile={iconTypeOfFileTable} buttonRef={buttonRef} /> */}
                  {
                    isLoadingBulkLoad 
                    ? (
                      <div className='flex justify-center items-center mt-2'>
                        <ProgressSpinner style={{width: '50px', height: '50px'}} />
                      </div>
                    )
                    : (
                      <Attachments
                        setValue={setValue}
                        fieldFiles="savedFiles"
                        fieldNewFiles="addedFiles"
                        filesValues={valuesFields?.["savedFiles"]}
                        newFilesValues={valuesFields?.["addedFiles"]}
                        isLoading={false}
                        validExtensions={["xlsx"]}
                        isMultiple={false}
                        isShowHeader={false}
                      />
                    )
                  }
                </div>
              </div>
            )
            :
            (
              <div className='flex flex-col empleoyees-table-error'>
                <StudentsTableUploadNotes listStudents={listStudents} setListStudents={setListStudents} />
                <div className='flex justify-center'>
                  {/* {
                    (value1 === 0 || value1 === 100) ? null : (<ProgressBar className='w-4/5 mt-2' value={value1}></ProgressBar>)
                  } */}
                </div>
              </div>
            )
          }
          {
            (existError || listErrors.length > 0) && <div className='message error overflow-y-auto max-h-36'>
                <ul>
                    {error.file ? (
                        <>
                            <li className="p-error">{error.file.message}</li>
                        </>
                    ) : null}
                </ul>
                {
                  listErrors.map((error, index) => (
                    <div key={index}>
                      <ul>
                          {error ? (
                              <>
                                  <li className="p-error">{`Fila ${error.line}: ${error.error}`}</li>
                              </>
                          ) : null}
                      </ul>
                  </div>
                  ))
                }
                
            </div>
          }
          <div className="flex justify-end gap-4 mt-3">
              <Button
                  icon="pi pi-times"
                  type="button"
                  label="Cancelar"
                  loading={isLoading}
                  onClick={() => { setVisible(true); }}
                  className="sig-button sig-secondary mt-4"
              />
              <Button
                  icon="pi pi-save"
                  type="submit"
                  label="Guardar"
                  ref={buttonRef}
                  loading={document?.isSaving || document?.isLoading || isLoading}
                  // disabled={listErrors.length > 0}
                  className="sig-button sig-primary mt-4"
              />
          </div>
        </form> 
        <ConfirmDialog
            visible={visible}
            onHide={() => setVisible(false)}
            message="¿Está seguro que desea cancelar?"
            header="Cancelar"
            icon="pi pi-exclamation-triangle"
            accept={redirectToSearch}
            reject={reject}
        />
    </Dialog>
  )
}

export default StudentModalImport