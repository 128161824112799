import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { RiskManagementAction } from '../store/actions/RiskManagementAction';
import { Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';

const RiskLevel = ({control, getValues, setValue}) => {

	const { level } = useSelector(state => state.riskManagementReducer.data)
  const { processes	} = useSelector(state => state.riskModelReducer.dataMasterEvaluation.data)
	const dispatch = useDispatch()

	return (
		<div>
			{
				level.map( item => 
					<div className='flex gap-2 mb-2 items-center' key={item.id}>
						<div>
							<div className={`w-8 h-8`} style={{backgroundColor: item.colorCode}}> 

							</div>
						</div>
						<div style={{width: 100}}>
						<Controller
							control={control}
							name={`risklevel-name-${item.id}`}
							defaultValue={item.name}
							rules={{
								required: 'El Nombre de Nivel de Riesgo es requerido',
								validate: {
									isNotRepeat: value => {
										const values = getValues()
										const riskLevels = Object.keys(values).filter( key => key.slice(0, 14) === 'risklevel-name' && key !== `risklevel-name-${item.id}`).map( item => values[item])
										return !riskLevels.includes(value) || "No se deben repetir los nombres de los niveles de riesgos"						
									}
								}
							}}
							render={({
								field: { onChange, onBlur, value, name, ref },
								fieldState: { invalid, isTouched, isDirty, error },
							}) => (
								<InputText
									ref={ref}
									value={value}
									onChange={(e)=>{ dispatch(RiskManagementAction.changeNameRiskLevel(item.id, e.target.value)); onChange(e)}}
									className={error && 'p-invalid'}
                  style={{minWidth: 100}}
								/>
							)}
						/>
						</div>
            {
              getValues().isFinalResult &&
              <div style={{flexGrow: 1, width: "50%", maxWidth: 400}}>
              <Controller
                control={control}
                name={`risklevel-processId-${item.id}`}
                defaultValue={item.processId}
                render={({field: {onChange, value}, fieldState: { error }})=>(
                  <Dropdown
                    showClear
                    tooltip={processes.find( ele => ele.id === item.processId)?.name}
                    options={processes}
                    optionLabel= 'name'
                    optionValue='id'
                    onChange={(e)=>{ dispatch(RiskManagementAction.changeProcessRiskLevel(item.id, e.target.value)); onChange(e)}}
                    value={value}
                    className={error ? 'p-invalid w-full' : 'w-full'}
                    
                  />
                )}
              />
              </div>
            }
						<div style={{width: 20}}>
							<i 
								className="pi pi-times-circle text-xl cursor-pointer" 
								onClick={()=> {
									dispatch(RiskManagementAction.deleteRiskLevel(item.id))
									setValue(`risklevel-name-${item.id}`, undefined)
								}}
							/>
						</div>
					</div>	
				)
			}
		</div>
	);
};

export default RiskLevel;