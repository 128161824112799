import { apiService } from 'core/services/ApiService';

const getRoles = ({ fields = {}, change }) => {
  let initialFields = {
    page: 1,
    pageSize: 10,
    columnOrder: 'roleName',
    order: 'asc',
    from: null,
    to: null,
    name: null,
  };

  if (change) {
    initialFields = { ...initialFields, ...fields };
  }

  return apiService.Post('management/api/Role/Search', initialFields);
};

const getRolesOptions = () => {
  return apiService.Get('management/api/Role/GetSubscriptionOptions');
};

const getUserByRoleId = (roleId) => {
  return apiService.Get(`management/api/Role/GetUserByRoleId?roleId=${roleId}`);
};

const getRoleById = (roleId) => {
  return apiService.Get(`management/api/Role/GetRoleById?roleId=${roleId}`);
};

const saveRole = (role) => {
  return apiService.Post('management/api/Role/Save', role);
};

const deleteRole = (roleId) => {
  return apiService.Get(`management/api/Role/RemoveRole?roleId=${roleId}`);
};

const generateReport = (payload) => {
  return apiService.Post('management/api/Role/GenerateReport', payload, {
    responseType: 'blob',
  });
};

export const RoleService = {
  getRoles,
  getRolesOptions,
  getUserByRoleId,
  getRoleById,
  saveRole,
  deleteRole,
  generateReport,
};
