import { apiService } from 'core/services/ApiService';

const getDataMaster = () => {
  let fields = {
    "isArea": true,
    "isAreaPosition": true,
    "isEmployee": true,
  }
  return apiService.Post('humanresource/api/CompanyArea/GetDataMaster', fields);
};

const saveCompanyArea = (data) =>{
  return apiService.Post('humanresource/api/CompanyArea/Save', data);
}

const deletePosition = (positionId) => {
  return apiService.Delete('humanresource/api/CompanyAreaPosition/DeleteCompanyAreaPosition?id=' + positionId);
};

const getOrganizationChart = () => {
  return apiService.Get('humanresource/api/OrganizationChart/GetOrganizationChart');
};

const saveNode = (data) =>{
  return apiService.Post('humanresource/api/OrganizationChart/Save', data);
}

const deleteNode = (nodeId) =>{
  return apiService.Delete('humanresource/api/OrganizationChart/Delete?id=' + nodeId);
}

const getNodeById = (nodeId) => {
  return apiService.Get('humanresource/api/OrganizationChart/GetOrganizationChartByNodeId?id=' + nodeId);
};

export const OrganizationChartService = {
  getDataMaster,
  deletePosition,
  getOrganizationChart,
  saveNode,
  saveCompanyArea,
  deleteNode,
  getNodeById
};
