/* eslint-disable react-hooks/exhaustive-deps */
import { BusinessSheetAction } from "features/business/store/actions/BusinessSheetAction"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Skeleton } from "primereact/skeleton"
import { Toast } from "primereact/toast"
import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

export const ModalSharedSheet = ({displayModal, setDisplayModal, currentBusinessSheet}) => {
  const toast = useRef(null);
  const dispatch = useDispatch()
  const link = useSelector(state => state.businessSheetReducer.share.link)
  const isLoadingShare = useSelector(state => state.businessSheetReducer.share.isLoadingShare)
  const { origin: baseURL } = window.location;
  const linkToSend = link ? `${baseURL}/#/negocios/public/ficha/${link}` : ""

  useEffect(() => {
    if(displayModal){
      dispatch(BusinessSheetAction.share(currentBusinessSheet))
    }
  },[displayModal, currentBusinessSheet])

  const onCopyLink = () => {
    if(link){
      navigator.clipboard.writeText(linkToSend)
      toast.current.show({severity:'success', summary: 'Ficha de Negocio', detail:'Enlace copiado exitosamente', life: 3000});
    }
	}

  return(
    <Dialog
      header="Compartir Ficha"
      visible={displayModal}
      className={`w-4/5 md:w-3/5 xl:'w-1/2'`}
      onHide={() => setDisplayModal(false)}
    >
      <hr />
      {
        isLoadingShare ? <Skeleton height="5rem" className="my-3"/> :
        <div className='my-3 flex flex-col gap-y-5'>
          <div className='flex flex-col px-4 py-5 border-2'>
            <div className='flex gap-x-2'>
              <span className="p-float-label w-full">
                <InputText
                  value={linkToSend}
                  className='disabled'
                  disabled
                />
                <label>Compartir enlace:</label>
              </span>
              <span 
                onClick={onCopyLink}
                className="p-inputgroup-addon p-0 cursor-pointer hover:text-gray-800 hover:bg-gray-200">
                  <i className="pi pi-copy text-xl"></i>
              </span>
            </div>
          </div>
        </div>
      }
      <Toast ref={toast}/>
    </Dialog>
  )
}