/* eslint-disable import/no-anonymous-default-export */
import { CompaniesConstants } from "features/customers/commons/CompaniesConstants";

const initialState = {
  companies: {
    data: [],
    loading: false,
    order: "asc",
    pagination: {},
    columnOrder: "name",
  },
  employees: {
    currentData: [],
    data: [],
    loading: false,
    pagination: {},
    currentCol: 'firstName',
    order: 'asc',
  },
  actions: {
    loading: false,
    items: [
      { name: "Editar", icon: "pi pi-pencil" },
      { name: "Eliminar", icon: "pi pi-trash" },
    ],
  },
  advancedFilter: {
    showModal: false,
    values: [],
  },
  downloadReport: {
    loading: false,
  },
  currentAction: {
    isOpen: false,
    companyId: null,
  },
  editCompany: {
    isLoading: false,
    isLoadingCompany: false,
    data: {},
    /*  0 - success   */
    status: null,
    errors: [],
  },
  bulkLoad: {
    data: null,
    isLoading: false,
  },
  bulkLoadErrors: [],
  companyBulk: {
    isSaved: false,
  },
  dataMaster: {
    isLoading: true,
    data: {
      businessLines: [],
      businessSectors: [],
      countries: [],
      departments: [],
      cities: [],
      districts: [],
      documentTypes: [],
      companiesReducer: [],
      dynamicFields: [],
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CompaniesConstants.Accion.Companies.ADVANCED_FILTER:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload,
        },
      };
    case CompaniesConstants.Accion.Companies.UPLOAD_BATCH_INFO:
        return {
          ...state,
          bulkLoad: {
            data: action.payload.data,
            isLoading: action.payload.isLoading,
          },
          bulkLoadErrors: action.payload.errors,
    };
    case CompaniesConstants.Accion.Companies.SEARCH_COMPANIES:
      return {
        ...state,
        companies: {
          ...state.companies,
          ...action.payload,
        },
      };
    case CompaniesConstants.Accion.Companies.DATA_MASTER:
      return {
        ...state,
        dataMaster: {
          ...state.dataMaster,
          ...action.payload,
        },
      };
      case CompaniesConstants.Accion.Companies.SEARCH_EMPLOYEES:
      return {
        ...state,
        employees: {
          ...state.employees,
          ...action.payload,
        },
      };
      case CompaniesConstants.Accion.Companies.RESET_AFTER_SAVED:
        return {
          ...state,
          bulkLoad: {
              data: null,
              isLoading: false,
          },
          bulkLoadErrors: [],
          companyBulk: {
              isSaved: false,
          }
        };
    case CompaniesConstants.Accion.Companies.CURRENT_ACTION:
      return {
        ...state,
        currentAction: {
          ...state.currentAction,
          ...action.payload,
        },
      };
    case CompaniesConstants.Accion.Companies.EDIT_COMPANY:
      return {
        ...state,
        editCompany: {
          ...state.editCompany,
          ...action.payload,
        },
      };
    case CompaniesConstants.Accion.Companies.REPORT_STATUS:
      return {
        ...state,
        downloadReport: {
          loading: action.payload,
        },
      };
      case CompaniesConstants.Accion.Companies.SAVE_COMPANY_BULK:
        return {
          ...state,
          companyBulk: {
              isSaved: action.payload.isSaved,
          }
        };
    default:
      return state;
  }
};
