/* eslint-disable import/no-anonymous-default-export */

import { SurveysConstants } from "features/survey/commons/SurveyConstants";


const initialState = {
  dataMaster: {
    isLoading: false,
    dropdowns: []
  },
  survey: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false,
    isUpdated: false,
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'name',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {}
  },
  listFolders: {
    data: [],
    isLoading: false,
  },
  listFoldersTree: {
    isLoading: false,
    data: []
  },
  listPermissions: {
    isLoading: false,
    data: []
  },
  permission: {
    data: null,
    isSaving: false,
    isSaved: false,
    isLoading: false,
    isUpdated: false,
  },
  currentPath: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SurveysConstants.Accion.SurveyManagement.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
    case SurveysConstants.Accion.SurveyManagement.UPDATE_FILTER:
        return {
            ...state,
            advancedFilter: state.advancedFilter
        };
    case SurveysConstants.Accion.SurveyManagement.RESET_AFTER_SAVED:
      return {
          ...state,
          survey: {
              data: null,
              isSaving: false,
              isSaved: false,
              isLoading: false,
              isDeleted: false,
              isUpdated: false,
          },
          permission: {
            data: null,
            isSaving: false,
            isSaved: false,
            isLoading: false,
            isUpdated: false,
          },
        };
    case SurveysConstants.Accion.SurveyManagement.DOWNLOAD_REPORT_STATUS:
      return {
          ...state,
          survey: {
              ...state,
              isLoading: action.payload.isLoading,
          },
      };
    case SurveysConstants.Accion.SurveyManagement.CREATE_FOLDER:
        return {
            ...state,
            survey: {
                data: action.payload.currentDocument,
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };

    case SurveysConstants.Accion.SurveyManagement.DELETE_FOLDER:
        return {
            ...state,
            survey: {
                isDeleted: action.payload.isDeleted
            }
        };

    case SurveysConstants.Accion.SurveyManagement.RENAME_FOLDER:
        return {
            ...state,
            survey: {
                isDeleted: action.payload.isUpdated
            }
        };

    //* GET DATA
    case SurveysConstants.Accion.SurveyManagement.GET_DATA_MASTER:
        return {
            ...state,
            dataMaster: {
                dropdowns: action.payload.data,
                isLoading: action.payload.isLoading
            }
        };

    case SurveysConstants.Accion.SurveyManagement.GET_LIST_FOLDERS_TABLE:
      return {
          ...state,
          listFoldersTree: {
            data: action.payload.data,
            isLoading: action.payload.isLoading
          }
      };
    case SurveysConstants.Accion.SurveyManagement.GET_LIST_FOLDERS:
      return {
          ...state,
          listFolders: {
              data: action.payload.data,
              isLoading: action.payload.isLoading
          }
      };  
    case SurveysConstants.Accion.Survey.SET_CURRENT_PATH:
      return {
        ...state,
        currentPath: action.payload.currentPath
      };

    //* PERMISSIONS
    case SurveysConstants.Accion.SurveyManagement.GET_PERMISSIONS_BY_ID:
      return {
          ...state,
          listPermissions: {
            data: action.payload.data,
            isLoading: action.payload.isLoading
        }
      };

    case SurveysConstants.Accion.SurveyManagement.SAVE_PERMISSIONS:
      return {
          ...state,
          permission: {
              isSaving: action.payload.isSaving,
              isSaved: action.payload.isSaved
          }
      };
    
    default: 
    return state;
  }

}