import { FilterPaginationConstants } from "shared/commons/FilterPaginationConstants"

const changePage = (payload) => ({
  type: FilterPaginationConstants.Accion.FilterPagination.CHANGE_PAGE,
  payload,
})

const changeSort = (payload) => ({
  type: FilterPaginationConstants.Accion.FilterPagination.CHANGE_SORT,
  payload,
})

const resetState = (payload) => ({
  type: FilterPaginationConstants.Accion.FilterPagination.RESET_REDUCER,
})

export const FilterPaginationAction = {
  changePage,
  changeSort,
  resetState,
}