import { useState , useRef } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { OverlayPanel } from "primereact/overlaypanel";
import { Menu } from "primereact/menu";
import { useTableViewProject } from "features/customers/hooks/project/useTableViewProject";
import { ViewProjectTableData } from "features/customers/data/project/ViewProjectTableData";
import { Checkbox } from "primereact/checkbox";
import { Link, useHistory } from 'react-router-dom';
import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup'
import { ModalViewDetails } from './ModalViewDetails';
import { Tooltip } from 'primereact/tooltip';
import { useClaimViewProject } from 'features/projects/hooks/useClaimViewProject';

export const TableComponent = () => {
  const {isEdit, isView, isDelete} = useClaimViewProject()
  const history = useHistory()
  const {
    selectColumns,
    headerActions,
    showActions,
    tableProject,
    tableLoading,
    tablePagination,
    tableCurrentActions,
    // tableActions,
    tableNum,
    tableRows,
    tableSort,
    onSort,
    onChangePage,
    onColumnToggle,
    setCurrentProject,
  } = useTableViewProject();

  const [idProyect,setIdProyect] = useState(0);
  const [dataModalDetails, setDataModalDetails] = useState({
    visible: false,
    data: null
  })
  let items = [
    {
        label: 'Editar',
        icon: 'pi pi-pencil',
        command:() => {
          history.push("/proyecto/vista-proyectos/proyecto/" + idProyect)
        }
    },
    {
        label: 'Eliminar',
        icon: 'pi pi-trash'
    }
]

  if (!isEdit)
    items = items.filter(c => c.label !== 'Editar');
  if (!isDelete)
    items = items.filter(c => c.label !== 'Eliminar');

    const getViewField = (rowData) => {

      if (isView)
        return <Link className="underline text-blue-700" to={{ pathname: `/proyecto/vista-proyectos/proyecto/visualizar/` + rowData.id }} >{rowData.name}</Link>
      else
        return rowData.name;
    }

  const onProgressTemplate  = (rowData) =>{
    const number = rowData.progress === '-' ? 0.00 : parseFloat(rowData.progress).toFixed(2)
    return <h1 className='text-right' >{number} %</h1>
  }

  const onTeamTemplate  = (rowData) =>{
    return <AvatarGroup>
            {
              rowData.projectTeamMembers.map((member) => { 
                  return <span title={member.fullName} style={{cursor: 'default'}}><Avatar label={member.initials} shape="circle" className='sig-primary' style={{ color: '#ffffff' }}/></span>
              }
            )}
          </AvatarGroup>
  }

  const onDescriptionTemplate  = (rowData) =>{
    return (<div className='flex items-center justify-center'>
      <div className='pi pi-file cursor-pointer' data-pr-tooltip="Abrir" data-pr-position="left" onClick={()=>{
      setDataModalDetails({visible:true, data: rowData})
    }}/></div>)
  }

  const ColumnComponents = selectColumns.map((col) => {
    if (col.field === 'name')
          return <Column key={col.field} header={col.header} body={(rowData) => getViewField(rowData)} sortable />

    return (
      <Column key={col.field} 
        field={col.field}
        header={col.header} 
        sortable={col.field!=="id"}
        body={
          col.field === "id" ? onDescriptionTemplate :
          col.field === "progress" ? onProgressTemplate :
          col.field === "projectTeamMembers" ? onTeamTemplate : null
        }/>
    );
  });

  const TableHeaderActions = () => {
    return (
      <i
        key="action"
        className="pi pi-ellipsis-v cursor-pointer ml-1"
        onClick={(e) => headerActions.current.toggle(e)}
      ></i>
    );
  };

  const selectedRow = (event, { id }) => {
    setCurrentProject(id);
    tableCurrentActions.current.toggle(event);
  };

  const TableBodyActions = (selectedItem) => {
    if(isEdit || isDelete){
      return (
        <i
          onClick={(event) => {
            setIdProyect(selectedItem.id)
            selectedRow(event, selectedItem)
          }}
          className="pi pi-ellipsis-v cursor-pointer"
        ></i>
      );
    }else{
      return <div></div>
    }
  };

  const HeaderColumns = () => {
    return ViewProjectTableData?.columns?.map(({ field, header }) => (
      <div key={field} className="p-field-checkbox py-1">
        <Checkbox
          inputId={field}
          name="column"
          value={field}
          onChange={(e) => onColumnToggle(e, { field, header })}
          checked={selectColumns.some((x) => x.field === field)}
        />
        <label className="ml-1" htmlFor={field}>
          {header}
        </label>
      </div>
    ));
  };

  return (
    <div className="mt-5">
      <div
        className="mt-3 w-full"
      >
        <DataTable
          value={tableProject}
          stripedRows
          rows={10}
          rowHover
          responsiveLayout="scroll"
          size="small"
          first={tableSort?.first}
          sortField={tableSort?.sortField}
          sortOrder={tableSort?.sortOrder}
          filters={tableSort?.filters}
          emptyMessage="No se encontraron proyectos"
          onSort={onSort}>
            {ColumnComponents}
            {showActions && (
              <Column
                header={TableHeaderActions}
                body={TableBodyActions}
              ></Column>
            )}
        </DataTable>
        <Paginator
          className="paginator-custom mt-4"
          rowsPerPageOptions={[5, 10, 15]}
          first={tableNum}
          rows={tableRows}
          totalRecords={tablePagination?.rowCount}
          onPageChange={onChangePage}
        ></Paginator>
        <OverlayPanel
          ref={headerActions}
          showCloseIcon
          style={{ width: "300px" }}
        >
          <HeaderColumns/>
        </OverlayPanel>
        <Menu model={items} popup ref={tableCurrentActions} />
    </div>
    <ModalViewDetails dataModalDetails={dataModalDetails} setDataModalDetails={setDataModalDetails}/>
    </div>
  );
};
