/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { CompaniesAction } from "features/customers/store/actions/CompaniesAction";
import scrollTop from "shared/utils/scrollTop";
import { lowercaseFirstLetter } from "shared/utils/textTransform";
import { isValidateEmail } from "shared/utils/isValidateEmail";
import { CompaniesConstants } from "features/customers/commons/CompaniesConstants";
import { getDynamicFields } from "shared/utils/getDynamicFields";

export const useCompany = ({ title }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { updateTitle } = useSetTitlePage();
  const description = title === "Nueva" ? "Nueva Empresa" : "Editar Empresa";
  const pageTitle = {
    title: "Clientes",
    subtitle: "Empresas",
    description,
    previousUrl: "/cliente/empresas",
  };

  useEffect(() => {
    updateTitle(pageTitle);
  }, []);

  const { isLoadingCompany } = useSelector(
    (state) => state.companiesReducer.editCompany
  );
  const isLoadingDataMaster = useSelector(
    (state) => state.companiesReducer.dataMaster.loading
  );

  const dataMaster = useSelector(
    (state) => state.companiesReducer.dataMaster.data
  );

  const dynamicFields = dataMaster?.dynamicFields || []

  const dynamicFieldsDatosGenerales = getDynamicFields(dynamicFields, CompaniesConstants.SectionsName.DATOS_GENERALES)
  const dynamicFieldsOficinaPrincipal = getDynamicFields(dynamicFields, CompaniesConstants.SectionsName.OFICINA_PRINCIPAL)
  const dynamicFieldsInformacionContacto = getDynamicFields(dynamicFields, CompaniesConstants.SectionsName.INFORMACION_DE_CONTACTO)
  const dynamicFieldsContactos = getDynamicFields(dynamicFields, CompaniesConstants.SectionsName.CONTACTOS)

  useEffect(() => {
    dispatch(CompaniesAction.getDataMaster());
  }, []);

  const defaultValues = {
    Name: null,
    DocumentTypeId: null,
    DocumentNumber: null,
    BusinessSectorId: null,
    BusinessLineId: null,
    CountryId: null,
    DepartmentId: null,
    CityId: null,
    DistrictId: null,
    Address: null,
    PhoneNo: null,
    EmailAddress: null,
    WebPageAddress: null,
    FacebookProfileAddress: null,
    LinkednProfileAddress: null,
    InstagramProfileAddress: null,
    RegistrationDate: null,
  };
  const {
    control,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({ 
    defaultValues,
    shouldUnregister: true 
  });
  const valuesFields = watch();
  
  const {
    isLoading,
    status: currentStatusCompany,
    data: currentCompany,
    errors: fieldsErrors,
  } = useSelector((state) => state.companiesReducer.editCompany);

  const params = useParams();
  const currentID = params.customerId;
  const [contactList, setContactList] = useState([]);
  const [contactErrors, setContactErrors] = useState([]);

  const isNewCompany = title === "Nueva";

  useEffect(() => {
    if (!isNewCompany) {
      dispatch(CompaniesAction.editCompany(currentID));
    }
  }, []);

  useEffect(() => {
    scrollTop();
  }, []);
  useEffect(() => {
    const finishLoading = !isLoadingDataMaster && !isLoading;
    const hasValues = Object.values(currentCompany).length > 0;

    const datas = {
      BusinessSectorId: "businessSectors",
      BusinessLineId: "businessLines",
      CountryId: "countries",
      DepartmentId: "departments",
      CityId: "cities",
      DistrictId: "districts",
      DocumentTypeId: "documentTypes",
    };

    if (!isNewCompany && finishLoading && hasValues && currentID) {
      if (currentCompany.ContactsList.length > 0) {
        const contacts = currentCompany.ContactsList.map((c) => {
          const contact = {};
          for (let key in c) {
            const keyLowercase = lowercaseFirstLetter(key);
            contact[keyLowercase] = c[key];
          }
          return contact;
        });
        setContactList(contacts);
      }
   
      for (let key in defaultValues) {
        const keyLowercase = key.toLowerCase();
        if (currentCompany[key]) {
          if (keyLowercase.includes("id")) {
            const ID = currentCompany[key];
            const value = dataMaster[datas[key]].filter(
              ({ id }) => id === ID
            )[0];
            setValue(key, value);
          } else {
            setValue(key, currentCompany[key]);
          }
        } else {
          setValue(key, null);
        }
      }

      if(currentCompany.dynamicFieldValues?.length > 0){
        currentCompany.dynamicFieldValues.forEach( dynamicFieldValue => {
          setValue(`dynamicFieldData:${dynamicFieldValue.identifier}`, dynamicFieldValue.value)
        })
      }
    }
  }, [isLoadingDataMaster, isLoading]);

  useEffect(() => {
    if (contactErrors.length === 0) setContactList([]);
    if (!isLoadingCompany && currentStatusCompany === 0) {
      dispatch(CompaniesAction.setCurrentCompany({ status: null }));
      history.push("/cliente/empresas");
    }
  }, [currentStatusCompany, isLoadingCompany]);

  const createContact = () => {
    setContactList((prev) => [
      ...prev,
      {
        name: "",
        emailAddress: "",
        phoneNo: "",
        positionId: null,
        id: uuidv4(),
      },
    ]);
  };

  const updateContact = ({ id, name, value }) => {
    const currentContactList = contactList.map((contact) => {
      if (contact.id === id) contact[name] = value;
      return contact;
    });

    setContactList(currentContactList);
  };
  const contactDelete = (id) => {
    const currentContactList = contactList.filter(
      (contact) => contact.id !== id
    );
    setContactList(currentContactList);
  };

  const onSubmit = (data) => {
    const dynamicDataValues = []

    for( const [key, value] of Object.entries(data)){
      if(key.startsWith("dynamicFieldData")){
        const identifier = key.split(":")[1]
        const dynamicField = dynamicFields.find( ( field ) => field.identifier === identifier)
        let id = null
        if(!isNewCompany){
          id = currentCompany.dynamicFieldValues.find( field => field.identifier === identifier)?.id
        }
        dynamicDataValues.push(
          {
            id,
            entityConfigurationItemId: dynamicField.id,
            value: value || null,
            identifier: identifier,
          }
        )
        delete data[key]
      }
    }

    const company = {};
    company.dynamicDataValues = dynamicDataValues
    setContactErrors([]);

    for (let key in data) {
      if (data[key]) {
        const keyLowercase = key.toLowerCase();
        const key_ = lowercaseFirstLetter(key);

        if (key === "DocumentNumber") {
          company["documentNo"] = data[key];
        } else if (keyLowercase.includes("id")) {
          company[key_] = data[key].id;
        } else {
          company[key_] = data[key];
        }
      }
    }

    let invalidEmails = [];
    if (contactList.length > 0) {
      invalidEmails = contactList.filter(
        (e) => !isValidateEmail(e.emailAddress) && e.emailAddress
      );
      company["companyCustomerContactList"] = contactList.map((c) => {
        if (!isNewCompany) {
          c.customerId = Number(currentID);
          if (typeof c.id === "string") delete c.id;
        } else {
          c.id = null;
          c.customerId = null;
        }
        return c;
      });
    } else {
      company["companyCustomerContactList"] = [];
    }
    if (!isNewCompany) {
      company.id = Number(currentID);
    } else {
      company.id = null;
    }

    if (invalidEmails.length > 0) {
      setContactErrors([
        "El Formato de correo electrónico en grilla de contactos no es válido",
      ]);
    }

    if (invalidEmails.length === 0) {
      dispatch(CompaniesAction.saveCompany({ company, isNewCompany }));
    }
  };



  const [showModalCancel, setShowModalCancel] = useState(false);
  const cancelAccept = () => {
    const { description, ...rest } = pageTitle;
    updateTitle(rest);
    history.push("/cliente/empresas");
  };

  const cancelDenied = () => {
    setShowModalCancel(false);
  };

  const setActiveIndex = (v) => {
    history.push("/cliente/empresas/tareas");
  };

  return {
    cancelAccept,
    cancelDenied,
    setShowModalCancel,
    setActiveIndex,
    showModalCancel,
    isLoading,
    isLoadingCompany,
    isLoadingDataMaster,
    dataMaster,
    control,
    valuesFields,
    handleSubmit,
    onSubmit,
    fieldsErrors,
    errors,
    contactList,
    createContact,
    updateContact,
    contactDelete,
    contactErrors,
    dynamicFieldsDatosGenerales,
    dynamicFieldsOficinaPrincipal,
    dynamicFieldsInformacionContacto,
    dynamicFieldsContactos,
  };
};
