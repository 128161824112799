import { apiService } from "core/services/ApiService";

const getDataMaster = () => {
  return apiService.Post("customer/api/Survey/GetDataMaster");
}

const getListFolders = () => {
  return apiService.Get("customer/api/Survey/ListFolders");
}

const getListFoldersInCurrentPath = (id = 1) => {
  return apiService.Get(`customer/api/Survey/ListFoldersInCurrentPath?id=${id}`);
}

const savePermission = (data) => {
  return apiService.Post("customer/api/Survey/SavePermission", data);
}

const createLocation = (data) => {
  return apiService.Post("customer/api/Survey/CreateLocation", data);
}

const renameLocation = (data) => {
  return apiService.Post("customer/api/Survey/RenameLocation", data);
}

const deleteLocation = (id) => {
  return apiService.Delete(`customer/api/Survey/DeleteLocation?id=${id}`);
}

const getPermissionByLocationId = (locationId) => {
  return apiService.Get(`customer/api/Survey/GetPermissionByLocationId?id=${locationId}`);
}

// const searchSurveys = (criterio) => {
//   return apiService.Post("customer/api/Survey/Search", criterio);
// }

export const SurveyManagementServices = {
  getDataMaster,
  getListFolders,
  getListFoldersInCurrentPath,
  savePermission,
  createLocation,
  renameLocation,
  deleteLocation,
  getPermissionByLocationId
};



