import { apiService } from "core/services/ApiService";

const searchSolutions = (criterio) => {
  return apiService.Post("management/api/Solution/Search", criterio);
}

const saveSolutions = (data) => {
  return apiService.Post("management/api/Solution/Save", data);
}

const getSolutionById = (solutionId) => {
  return apiService.Get("management/api/Solution/GetSolutionById?id=" + solutionId);
}

const generateReport = (payload) => {
  return apiService.Post("management/api/Solution/GenerateReport", payload, {
    responseType: 'blob',
  })
}

const deleteSolution = (solutionId) => {
  return apiService.Delete("management/api/Solution/Delete?id=" + solutionId);
}

const getDataOptions = () => {
  return apiService.Post("management/api/Solution/GetDataMaster");
}

export const SolutionsService = {
  searchSolutions,
  saveSolutions,
  getSolutionById,
  generateReport,
  deleteSolution,
  getDataOptions
};