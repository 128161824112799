/* eslint-disable react-hooks/exhaustive-deps */
import { IndicatorsConstants } from 'features/indicators/commons/IndicatorsConstans';
import useIndicator from 'features/indicators/hooks/Indicator/useIndicator';
import { useClaimIndicators } from 'features/indicators/hooks/useClaimIndicators';
import { IndicatorsAction } from 'features/indicators/store/actions/IndicatorAction';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Panel } from 'primereact/panel';
import { RadioButton } from 'primereact/radiobutton';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { DynamicFieldList } from 'shared/components/dynamicFields/DynamicFieldList';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { getDynamicFields, getDynamicFieldsList } from 'shared/utils/getDynamicFields';
import './IndicatorPage.scss'
import { SplitButtonSave } from 'shared/components/button/SplitButtonSave';

// const sources = [{name: 'Objetivo', id: '1', icon: 'pi-upload'}, {name: 'Proceso', id: '2', icon: 'pi-cog'}, {name: 'Ninguno', id: '3', icon: 'pi-cog'}];
const decimalPlacesOptions = [{value: '0', key: '0'}, {value: '1', key: '1'}, {value: '2', key: '2'}];

const IndicatorPage = ({title, mode}) => {
  const {updateTitle } = useSetTitlePage()
  const history = useHistory();
  const params = useParams();
  const isNewIndicator = params.id ? false : true
  const dispatch = useDispatch();
  const indicator = useSelector((state) => state.indicatorReducer.indicator); 
  const {data: indicatorData} = useSelector((state) => state.indicatorReducer.indicator); 
  const {data: indicatorCopyData, isLoading: isLoadingIndicatorCopy} = useSelector((state) => state.indicatorReducer.indicatorCopy); 
  const {indicatorFrecuency, indicatorGoal, indicatorSources, indicatorType, responsibles, indicatorStatus, processes, dynamicFields, isLoading: isLoadingDataMaster} = useSelector((state) => state.indicatorReducer.dataMaster);  
  const { data: unitMeasure } = useSelector((state) => state.indicatorReducer.unitMeasure);  
  const version  = useSelector((state) => state.indicatorReducer.version);  
  const {data: dataVersions}  = useSelector((state) => state.indicatorReducer.versions);  
  const [isFirstLoading, setFirstLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [visibleNewVersion, setVisibleNewVersion] = useState(false);
  const [versionType, setVersionType] = useState(null)
  const isActualVersion = (versionType === 'Actual' || versionType === null) ? false : true
  
  const { control, errors, valuesFields, existError, setValue, handleSubmit, reset, clearErrors} = useIndicator()

  const redirectToSearch = () => {
    history.push("/indicadores/indicador");
    onHide();
  }

  const dynamicFieldsOrigen = getDynamicFields(dynamicFields, IndicatorsConstants.SectionsName.ORIGEN)
  const dynamicFieldsDatosIndicador = getDynamicFields(dynamicFields, IndicatorsConstants.SectionsName.DATOS_DEL_INDICADOR)
  const dynamicFieldsMeta = getDynamicFields(dynamicFields, IndicatorsConstants.SectionsName.META)

  const { isCreate, isEdit } = useClaimIndicators()

  const getTypeIconSource = (type) => {
    switch (type) {
      case 'IGOL':
        return 'pi-upload'
      case 'IPRC':
        return 'pi-cog'
      case 'INON':
        return 'pi-ban'
      default:
        break;
    }
  }

  const getTypeIconGoal = (type) => {
    switch (type) {
      case 'IEQU': // Igual que
        return 'pi-pause'
      case 'IMTH': // Mayor que
        return 'pi-angle-right'
      case 'ILTH': // Menor que
        return 'pi-angle-left'
      case 'IRNG': // Rango
        return 'pi-arrows-h'
      case 'IGEQ': // Mayor o igual que
        return 'pi-angle-double-right'
      case 'ILEQ': // Menor o igual que
        return 'pi-angle-double-left'
      default:
        break;
    }
  }

  const onHide = () => {
    setValue("code", ''); 
    setValue("name", ''); 
    setValue("status", ''); 
    setValue("createdBy", ''); 
    setValue("updatedBy", ''); 
    setValue("creationDate", ''); 
    setValue("updateDate", '') ; 
    setValue("version", ''); 
    setValue("owner", ''); 
    setValue("type", ''); 
    setValue("decimalPlaces", ''); 
    setValue("measurementFrequency", ''); 
    setValue("unitMeasurement", ''); 
    setValue("from", ''); 
    setValue("to", ''); 
    setValue("formula", null); 
    setValue("goalValue", ''); 
    setValue("source", '');
    setValue("range", ''); 
    setValue("period", ''); 
    setValue("process", null); 
    reset()
    // dispatch(IndicatorsAction.resetAfterSaved());
  }

  const onCreateNewVersion = () => {
    const model = {
      id: Number(params.id),
      sourceTypeId: valuesFields.source.id,
      sourceEnityId: null, 
      code: valuesFields.code,
      name: valuesFields.name,
      // versionNumber: valuesFields.version,
      responsibleId: valuesFields.owner,
      indicatorTypeId: valuesFields.type,
      decimalPositions: Number(valuesFields.decimalPlaces),
      measureFrequencyId: valuesFields.measurementFrequency,
      measureUnitId: valuesFields.unitMeasurement,
      from: valuesFields.from,
      to: valuesFields.to,
      formula: valuesFields.formula,
      goalTypeId: valuesFields.range.id,
      goalValue: valuesFields.goalValue,
      period: valuesFields.period,
    }
    model.versionNumber = indicatorData.versionNumber
    if (valuesFields.range?.code === 'IRNG') {
      model.maxGoalValue = valuesFields.highValue
      dispatch(IndicatorsAction.saveAndGenerateVersion(model));
      return
    }
    dispatch(IndicatorsAction.saveAndGenerateVersion(model));
  }

  const sourceFinded = indicatorData ? indicatorSources.find(s => s.id === indicatorData?.sourceTypeId) : indicatorSources.find(s => s.id === indicatorCopyData?.sourceTypeId)
  const rangeFinded = indicatorData ? indicatorGoal.find(g => g.id === indicatorData?.goalTypeId) : indicatorGoal.find(g => g.id === indicatorCopyData?.goalTypeId)

  

  useEffect(() => {
    if (indicatorData) {
        setValue("code", indicatorData.code); 
        setValue("name", indicatorData.name); 

        setValue("status", indicatorData.statusId); 
        setValue("createdBy", indicatorData.creationUser); 
        setValue("updatedBy", indicatorData.modificationUser ? indicatorData.modificationUser : ''); 
        setValue("creationDate", new Date(indicatorData.creationDate)); 
        setValue("updateDate", indicatorData.modificationDate ? new Date(indicatorData.modificationDate) : '') ; 

        setValue("process", indicatorData.processId); 
        setValue("version", indicatorData.versionNumber); 
        setValue("owner", indicatorData.responsibleId); 
        setValue("type", indicatorData.indicatorTypeId); 
        setValue("decimalPlaces", `${indicatorData.decimalPositions}`); 
        setValue("measurementFrequency", indicatorData.measureFrequencyId); 
        setValue("unitMeasurement", indicatorData.measureUnitId); 
        setValue("from", new Date(indicatorData.from)); 
        setValue("to", new Date(indicatorData.to)); 
        setValue("formula", indicatorData.formula || ''); 
        setValue("goalValue", indicatorData.goalValue); 
        setValue("highValue", indicatorData.maxGoalValue ? indicatorData.maxGoalValue : '');
        if(indicatorData.year){
          let period = `${indicatorData.year}`
          setValue("period", `${'0'.repeat(4-period.length)}${indicatorData.year}`)
        }

        if(indicatorData?.dynamicFieldValues?.length > 0){
          indicatorData?.dynamicFieldValues.forEach( dynamicFieldValue => {
            setValue(`dynamicFieldData:${dynamicFieldValue.identifier}`, dynamicFieldValue.value)
          })
        }
    }
  }, [indicatorData]);

  useEffect(() => {
    if (indicatorCopyData) {
        setValue("code", indicatorCopyData.code); 
        setValue("name", indicatorCopyData.name); 

        setValue("status", indicatorCopyData.statusId); 
        setValue("createdBy", indicatorCopyData.creationUser); 
        setValue("updatedBy", indicatorCopyData.modificationUser ? indicatorCopyData.modificationUser : ''); 
        setValue("creationDate", new Date(indicatorCopyData.creationDate)); 
        setValue("updateDate", indicatorCopyData.modificationDate ? new Date(indicatorCopyData.modificationDate) : '') ; 

        setValue("process", indicatorCopyData.processId); 
        setValue("version", indicatorCopyData.versionNumber); 
        setValue("owner", indicatorCopyData.responsibleId); 
        setValue("type", indicatorCopyData.indicatorTypeId); 
        setValue("decimalPlaces", `${indicatorCopyData.decimalPositions}`); 
        setValue("measurementFrequency", indicatorCopyData.measureFrequencyId); 
        setValue("unitMeasurement", indicatorCopyData.measureUnitId); 
        setValue("from", new Date(indicatorCopyData.from)); 
        setValue("to", new Date(indicatorCopyData.to)); 
        setValue("formula", indicatorCopyData.formula || ''); 
        setValue("goalValue", indicatorCopyData.goalValue); 
        setValue("highValue", indicatorCopyData.maxGoalValue ? indicatorCopyData.maxGoalValue : '');
        if(indicatorCopyData.year){
          let period = `${indicatorCopyData.year}`
          setValue("period", `${'0'.repeat(4-period.length)}${indicatorCopyData.year}`)
        }

        if(indicatorCopyData?.dynamicFieldValues?.length > 0){
          indicatorCopyData?.dynamicFieldValues.forEach( dynamicFieldValue => {
            setValue(`dynamicFieldData:${dynamicFieldValue.identifier}`, dynamicFieldValue.value)
          })
        }
    }
  }, [indicatorCopyData]);

  useEffect(() => {
    if (indicatorData) {
        setValue("source", indicatorData ? sourceFinded : ''); 
        setValue("range", indicatorData ? rangeFinded : '');
    }
  }, [indicatorData, sourceFinded, rangeFinded]);

  useEffect(() => {
    if (indicatorCopyData) {
        setValue("source", indicatorCopyData ? sourceFinded : '');
        setValue("range", indicatorCopyData ? rangeFinded : '');
    }
  }, [indicatorCopyData, sourceFinded, rangeFinded]);

  useEffect(() => {
    if (indicator?.isSaved) redirectToSearch();
  }, [indicator]);

  useEffect(() => {
    if (version?.isSaved) {
      if (params.id) getIndicatorById(params.id)
    }
  }, [version]);

  // useEffect(() => {
  //   setValue('decimalPlaces', 0)
  // }, [])

  useEffect(()=> {
    updateTitle({
      title: "Indicadores",
      subtitle: "Indicador",
      description: title,
      previousUrl: "/indicadores/indicador/"
    })
    if (isFirstLoading) {
      setFirstLoading(false);
      if (!params.id) {
        onHide()
        // if (!indicatorCopyData && !isLoadingIndicatorCopy) {
        //   dispatch(IndicatorsAction.resetAfterSaved());
        // }
      }
      if (params.id) getIndicatorById(params.id)
    }
  }, [])

  useEffect(() => {
    return () => {
      dispatch(IndicatorsAction.resetAfterSaved());
    }
  }, [dispatch])
  

  useEffect(() => {
    dispatch(IndicatorsAction.getDataMaster());
    dispatch(IndicatorsAction.getIndicatorUnitMeasure());
  }, [])

  
  
  

  const getIndicatorById = (indicatorId) => {
    dispatch(IndicatorsAction.getIndicatorById(indicatorId));
  }

  const onVersionChange = (e) => {
    setValue("version", e.value)
    setVersionType(e.value.versionNumberLabel)
    if (e.value.versionNumberLabel === 'Actual') {
      getIndicatorById(params.id)
      return
    }
    dispatch(IndicatorsAction.getIndicatorByVersion(params.id, e.value.versionNumber))
  }

  const onSubmit = (data, generate) => {
    const dynamicDataValues = getDynamicFieldsList(data, dynamicFields, isNewIndicator, indicatorData || indicatorCopyData)

    const model = {
      sourceTypeId: data.source.id,
      sourceEnityId: null, 
      code: data.code,
      name: data.name,
      versionNumber: data.version,
      responsibleId: data.owner,
      indicatorTypeId: data.type,
      decimalPositions: Number(data.decimalPlaces),
      measureFrequencyId: data.measurementFrequency,
      measureUnitId: data.unitMeasurement,
      from: data.from,
      to: data.to,
      formula: data.formula,
      goalTypeId: data.range.id,
      goalValue: data.goalValue,
      statusId: data.status,
      processId: data.process,
      period: Number(data.period),
      dynamicDataValues
    }
    if (params.id) {
      model.id = Number(params.id)
      model.versionNumber = indicatorData.versionNumber
      if (data.range?.code === 'IRNG') {
        model.maxGoalValue = data.highValue
        dispatch(IndicatorsAction.saveIndicator(model, generate));
        return
      }
      dispatch(IndicatorsAction.saveIndicator(model, generate));
      return
    }
    model.versionNumber = 0

    if (data.range?.code === 'IRNG') {
      model.maxGoalValue = data.highValue
      dispatch(IndicatorsAction.saveIndicator(model, generate));
      return
    }
    dispatch(IndicatorsAction.saveIndicator(model, generate));
  };

  const items = [
    {
      label: 'Guardar',
      icon: "pi pi-save",
      command: handleSubmit((data) => onSubmit(data, false)),
    },
    {
      label: 'Guardar y Generar Valores',
      icon: "pi pi-upload",
      command: handleSubmit((data) => onSubmit(data, true)),
    }
  ]

  const reject = () => {
      setVisible(false);
  };
  const rejectNewVersion = () => {
      setVisibleNewVersion(false);
  };

  return (
    <div className="bg-white">
      <form 
        className=" p-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='grid grid-cols-12 gap-x-3 2xl:gap-x-4'>
          <div className='col-span-12 sm:col-span-3 2xl:col-span-2 order-1 sm:order-2'>
            <Panel header="DATOS GENERALES" toggleable>
              <div className="grid grid-cols-1">
                <div className="">
                  {indicator.isLoading || isLoadingIndicatorCopy ? (
                      <div className='w-full mt-2'>
                        <Skeleton width="100%" height="2.5rem"></Skeleton>
                      </div>
                    ): (
                      <span className="p-float-label w-full mt-2">
                          <Controller
                              control={control}
                              render={({
                                  field: { onChange, onBlur, value },
                              }) => (
                                <InputText
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    className="disabled"
                                    disabled
                                />
                              )}
                              name="createdBy"
                          />
                          <label htmlFor="createdBy">Creado por</label>
                      </span>
                    )}
                    {indicator.isLoading || isLoadingIndicatorCopy ? (
                      <div className='w-full my-5'>
                        <Skeleton width="100%" height="2.5rem"></Skeleton>
                      </div>
                    ): (
                      <span className="p-float-label w-full my-5">
                          <Controller
                              control={control}
                              render={({
                                  field: { onChange, onBlur, value },
                              }) => (
                                <InputText
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    className="disabled"
                                    disabled
                                />
                              )}
                              name="updatedBy"
                          />
                          <label htmlFor="updatedBy">Modificador por:</label>
                      </span>
                    )}
                    {indicator.isLoading || isLoadingIndicatorCopy ? (
                      <div className='w-full my-5'>
                        <Skeleton width="100%" height="2.5rem"></Skeleton>
                      </div>
                    ): (
                      <span className="p-float-label w-full my-5">
                          <Controller
                              control={control}
                              render={({
                                  field: { onChange, onBlur, value },
                              }) => (
                                <Calendar
                                    value={value}
                                    onChange={onChange}
                                    className='w-full disabled'
                                    disabled
                                />
                              )}
                              name="creationDate"
                          />
                          <label htmlFor="creationDate">Fecha Creación:</label>
                      </span>
                    )}
                    {indicator.isLoading || isLoadingIndicatorCopy ? (
                      <div className='w-full my-5'>
                        <Skeleton width="100%" height="2.5rem"></Skeleton>
                      </div>
                    ): (
                      <span className="p-float-label w-full my-5">
                          <Controller
                              control={control}
                              render={({
                                  field: { onChange, onBlur, value },
                              }) => (
                                <Calendar
                                    value={value}
                                    onChange={onChange}
                                    className='w-full disabled'
                                    disabled
                                />
                              )}
                              name="updateDate"
                          />
                          <label htmlFor="updateDate">Fecha Modificación:</label>
                      </span>
                    )}
                    {indicator.isLoading || isLoadingIndicatorCopy ? (
                      <div className='w-full my-5'>
                        <Skeleton width="100%" height="2.5rem"></Skeleton>
                      </div>
                    ): (
                      <span className="p-float-label w-full my-5">
                          <Controller
                              control={control}
                              render={({
                                  field: { onChange, value },
                              }) => (
                                <Dropdown
                                    options={dataVersions}
                                    // optionLabel="versionNumberLabel"
                                    value={value}
                                    onChange={(e) => onVersionChange(e)}
                                    showClear
                                    className={`w-full ${!params.id ? 'disabled' : ''}`}
                                    disabled={!params.id}
                                />
                                
                              )}
                              name="version"
                          />
                          <label htmlFor="version">Versión:</label>
                      </span>
                    )}
                    <div className='flex justify-center'>
                      <Button
                        type="button"
                        disabled={!params.id || mode==='view'}
                        label="Nueva Versión"
                        onClick={() => { setVisibleNewVersion(true); }}
                        loading={version?.isSaving}
                        className="sig-button sig-primary"
                      />
                    </div>
                </div>
              </div>      
            </Panel>   
          </div>
          <div className="form-modal p-0 col-span-12 sm:col-span-9 2xl:col-span-10 order-2 sm:order-1">
            <Panel header="ORIGEN" toggleable>
              <div className="">
                {/* {true ? ( */}
                {(indicator.isLoading || isLoadingIndicatorCopy || isLoadingDataMaster) ? (
                    <div className='w-full flex flex-wrap md:flex-nowrap justify-evenly gap-4'>
                      {
                        [1,2].map((source) => (
                            <Skeleton width="10rem" height="10rem" key={source}></Skeleton>
                          )
                        )
                      }
                    </div>
                  ): (
                    <span className="p-float-label w-full mt-2">
                        <Controller
                            control={control}
                            rules={{required: 'El origen del indicador es requerido'}}
                            defaultValue={indicatorSources.find(({code})=> code === IndicatorsConstants.Constans.Indicators.SOURCE_NONE)}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                              <div className='w-full flex flex-wrap md:flex-nowrap justify-evenly gap-4'>
                                {
                                  indicatorSources.map((source) => (
                                      <div key={source.id} className='flex flex-col w-40 items-center gap-y-3 shadow-md py-2 px-2'>
                                        <div className='flex justify-center items-center gap-x-2'>
                                          <RadioButton 
                                            inputId={source.id} 
                                            value={source} 
                                            onChange={(e) => {
                                              if (e.value.code === 'INON') {
                                                setValue("process", null)
                                                clearErrors('process')
                                              }
                                              onChange(e.value)
                                            }} 
                                            disabled={isActualVersion} 
                                            checked={value?.id === source.id} 
                                          />
                                          <p className='text-sm'>{source.description}</p>
                                        </div>
                                        <div className='h-16 flex justify-center items-center'>
                                          <i className={`pi ${getTypeIconSource(source.code)} text-3xl text-blue-600`}></i>
                                        </div>
                                        {
                                          source.code === 'IPRC' && (
                                            <div className='w-full'>
                                              <Controller
                                                  control={control}
                                                  rules={{ required: (!valuesFields.source || valuesFields.source?.code !== 'IPRC') ? false : 'El proceso es requerido'}}
                                                  render={({
                                                      field: { onChange, onBlur, value },
                                                  }) => (
                                                    <Dropdown
                                                        options={processes}
                                                        optionLabel="name"
                                                        optionValue='id'
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        filter
                                                        filterBy="description"
                                                        className={errors?.process ? 'p-invalid w-full' : 'w-full'}
                                                        disabled={isActualVersion || !valuesFields.source || valuesFields.source?.code !== 'IPRC'}
                                                    />
                                                  )}
                                                  name="process"
                                              />
                                            </div>
                                          )
                                        }
                                      </div>
                                    )
                                  )
                                }
                              </div>
                            )}
                            name="source"
                        />
                    </span>
                  )}
              </div>
              <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-6 md:px-4">
                {
                  ((!indicator.isLoading || !isLoadingIndicatorCopy) && !isLoadingDataMaster) &&
                  <DynamicFieldList control={control} fields={dynamicFieldsOrigen} />
                }
              </div>  
            </Panel>
            <Panel header="DATOS DEL INDICADOR" toggleable>
              <div className='grid grid-cols-4 mt-1 gap-x-4 justify-items-start md:px-4'>
                <div className='col-span-4 md:col-span-2 w-full'>
                {indicator.isLoading || isLoadingIndicatorCopy ? (
                    <div className='w-full lg:w-4/5 mt-2'>
                      <Skeleton width="100%" height="2.5rem"></Skeleton>
                    </div>
                  ): (
                    <span className="p-float-label w-full lg:w-4/5 mt-2">
                        <Controller
                            control={control}
                            rules={{required: 'El nombre del indicador es requerido'}}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                              <InputText
                                  disabled={isActualVersion}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                  className={errors?.name ? 'p-invalid w-full' : 'w-full'}
                              />
                            )}
                            name="name"
                        />
                        <label htmlFor="name">Nombre: *</label>
                    </span>
                  )}
                  {indicator.isLoading || isLoadingIndicatorCopy ? (
                    <div className='w-full lg:w-4/5 my-5'>
                      <Skeleton width="100%" height="2.5rem"></Skeleton>
                    </div>
                  ): (
                    <span className="p-float-label w-full lg:w-4/5 my-5">
                        <Controller
                            control={control}
                            rules={{required: 'El código es requerido'}}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                              <InputText
                                disabled={isActualVersion}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                  maxLength={10}
                                  className={errors?.code ? 'p-invalid w-full' : 'w-full'}
                              />
                            )}
                            name="code"
                        />
                        <label htmlFor="code">Código: *</label>
                    </span>
                  )}
                  {indicator.isLoading || isLoadingIndicatorCopy || isLoadingDataMaster ? (
                    <div className='w-full lg:w-4/5 my-5'>
                      <Skeleton width="100%" height="2.5rem"></Skeleton>
                    </div>
                  ): (
                    <span className="p-float-label w-full lg:w-4/5 my-5">
                        <Controller
                            defaultValue={indicatorStatus.find(({code}) => code === IndicatorsConstants.Constans.Indicators.STATUS_CREATE)?.id}
                            control={control}
                            rules={{ required: 'El estado es requerido'}}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                              <Dropdown
                                    options={indicatorStatus}
                                    optionLabel="description"
                                    optionValue='id'
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    filterBy="description"
                                    className={errors?.status ? 'p-invalid w-full' : 'w-full'}
                                    disabled={isActualVersion}
                                />
                            )}
                            name="status"
                        />
                        <label htmlFor="status">Estado *:</label>
                    </span>
                  )}
                  {/*  */}
                  {indicator.isLoading || isLoadingIndicatorCopy ? (
                      <div className='w-full lg:w-4/5 my-5'>
                        <Skeleton width="100%" height="2.5rem"></Skeleton>
                      </div>
                    ): (
                      <span className="p-float-label w-full lg:w-4/5 my-5">
                          <Controller
                              control={control}
                              rules={{ required: 'El responsable es requerido'}}
                              render={({
                                  field: { onChange, onBlur, value },
                              }) => (
                                <Dropdown
                                    options={responsibles}
                                    optionLabel="fullName"
                                    optionValue='id'
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    filter
                                    showClear
                                    filterBy="fullName"
                                    className={errors?.owner ? 'p-invalid w-full' : 'w-full'}
                                    disabled={isActualVersion}
                                />
                              )}
                              name="owner"
                          />
                          <label htmlFor="owner">Seleccione responsable: *</label>
                      </span>
                  )}
                  {indicator.isLoading || isLoadingIndicatorCopy ? (
                      <div className='w-full lg:w-4/5 my-5'>
                        <Skeleton width="100%" height="2.5rem"></Skeleton>
                      </div>
                    ): (
                      <span className="p-float-label w-full lg:w-4/5 my-5">
                          <Controller
                              control={control}
                              rules={{ required: 'El tipo de indicador es requerido'}}
                              render={({
                                  field: { onChange, onBlur, value },
                              }) => (
                                <Dropdown
                                    options={indicatorType}
                                    optionLabel="description"
                                    optionValue='id'
                                    value={value}
                                    onChange={onChange}
                                    showClear
                                    className={errors?.type ? 'p-invalid w-full' : 'w-full'}
                                    disabled={isActualVersion}
                                />
                              )}
                              name="type"
                          />
                          <label htmlFor="type">Seleccione tipo *:</label>
                      </span>
                  )}
                </div>
                <div className='col-span-4 md:col-span-2  w-full'>
                    {indicator.isLoading || isLoadingIndicatorCopy ? (
                        <div className='lg:w-4/5 w-full mt-2'>
                          <Skeleton width="100%" height="2.5rem"></Skeleton>
                        </div>
                      ): (
                        <span className="p-float-label mt-2 lg:w-4/5 w-full">
                            <Controller
                                control={control}
                                rules={{ required: 'La fecha de inicio es requerido'}}
                                render={({
                                    field: { onChange, onBlur, value },
                                }) => (
                                  <Calendar
                                      value={value}
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      className={errors?.from ? 'p-invalid w-full' : 'w-full'}
                                      disabled={isActualVersion}
                                  />
                                )}
                                name="from"
                            />
                            <label htmlFor="from">Desde: *</label>
                        </span>
                    )}
                     {indicator.isLoading || isLoadingIndicatorCopy ? (
                      <div className='lg:w-4/5 w-full my-5'>
                          <Skeleton width="100%" height="2.5rem"></Skeleton>
                        </div>
                    ): (
                      <span className="p-float-label my-5 lg:w-4/5 w-full">
                          <Controller
                              control={control}
                              rules={{ required: 'La fecha final es requerida'}}
                              render={({
                                  field: { onChange, onBlur, value },
                              }) => (
                                <Calendar
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    minDate={valuesFields?.from}
                                    className={errors?.to ? 'p-invalid w-full' : 'w-full'}
                                    disabled={isActualVersion}
                                />
                              )}
                              name="to"
                          />
                          <label htmlFor="to">Hasta: *</label>
                      </span>
                    )}
                    {indicator.isLoading || isLoadingIndicatorCopy ? (
                        <div className='w-fulllg:w-4/5 w-full my-5'>
                          <Skeleton width="100%" height="2.5rem"></Skeleton>
                        </div>
                      ): (
                        <span className="p-float-label my-5 lg:w-4/5 w-full">
                            <Controller
                                control={control}
                                rules={{ required: 'Las posiciones decimales son requeridas'}}
                                render={({
                                    field: { onChange, onBlur, value },
                                }) => (
                                  <Dropdown
                                      options={decimalPlacesOptions}
                                      optionLabel="value"
                                      optionValue='key'
                                      value={value}
                                      
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      className={errors?.decimalPlaces ? 'p-invalid w-full' : 'w-full'}
                                      disabled={isActualVersion}
                                  />
                                )}
                                name="decimalPlaces"
                            />
                            <label htmlFor="decimalPlaces">Posiciones Decimales: *</label>
                        </span>
                    )}
                    {indicator.isLoading || isLoadingIndicatorCopy ? (
                        <div className='lg:w-4/5 w-full my-5'>
                          <Skeleton width="100%" height="2.5rem"></Skeleton>
                        </div>
                      ): (
                        <span className="p-float-label mt-5 lg:w-4/5 w-full">
                            <Controller
                                control={control}
                                rules={{ required: 'La frecuencia es requerida'}}
                                render={({
                                    field: { onChange, onBlur, value },
                                }) => (
                                  <Dropdown
                                      options={indicatorFrecuency}
                                      optionLabel="description"
                                      optionValue='id'
                                      value={value}
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      filter
                                      showClear
                                      filterBy="description"
                                      className={errors?.measurementFrequency ? 'p-invalid w-full' : 'w-full'}
                                      disabled={isActualVersion}
                                  />
                                )}
                                name="measurementFrequency"
                            />
                            <label htmlFor="measurementFrequency">Seleccione frecuencia: *</label>
                        </span>
                    )}
                      {indicator.isLoading || isLoadingIndicatorCopy ? (
                        <div className='lg:w-4/5 w-full my-5'>
                          <Skeleton width="100%" height="2.5rem"></Skeleton>
                        </div>
                      ): (
                        <div className='lg:w-4/5 w-full my-5' id="period-indicator">
                          <span className="p-float-label">
                              <Controller
                                control={control}
                                render={({
                                    field: { onChange, value },
                                }) => (
                                  <InputText
                                    onBlur={(e)=>{
                                      if(value && value.length > 0 && Number(value) === 0){
                                        e.target.value = ''
                                        onChange(e)
                                        return
                                      }
                                      if(value && value.length > 0 && value.startsWith('0')){
                                        e.target.value = e.target.value.replaceAll('0','')
                                        if(e.target.value && e.target.value.length > 0 && e.target.value.length < 5){
                                          e.target.value = `${'0'.repeat(4-value.length)}${e.target.value}`
                                        }
                                        onChange(e)
                                        return
                                      }
                                      if(value && value.length > 0 && value.length < 5){
                                        e.target.value = `${'0'.repeat(4-value.length)}${value}`
                                        onChange(e)
                                      }
                                    }}
                                    onChange={(e)=>{
                                      if(e.target.value && e.target.value.length < 5){
                                        onChange(e)
                                        return;
                                      }
                                      if(!e.target.value){
                                        onChange(e)
                                      }
                                    }}
                                    value={value}
                                    disabled={isActualVersion}
                                    type='number'
                                    className={errors?.period ? 'p-invalid w-full' : 'w-full'}
                                    style={{height: 37.19}}
                                  />
                                )}
                                name="period"
                              />
                              <label style={{fontSize: 12, color: "#ced4da"}}>Periodo</label>
                          </span>
                        </div>
                    )}
                </div>
                <div className='col-span-4 w-full'>
                    {indicator.isLoading || isLoadingIndicatorCopy ? (
                        <div className='w-full'>
                          <Skeleton width="100%" height="3.75rem"></Skeleton>
                        </div>
                      ): (
                        <span className="p-float-label">
                            <Controller
                              name="formula"
                              control={control}
                              render={({ field, fieldState }) => (
                                  <InputTextarea 
                                    id={field.name} 
                                    {...field}
                                    value={field.value || ""}
                                    rows={2} 
                                    className='w-full'
                                  />
                              )}
                          />
                            <label htmlFor="decimalPlaces">Formula</label>
                        </span>
                    )}
                </div>
              </div>
              <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-1 md:px-4">
                {
                  ((!indicator.isLoading || !isLoadingIndicatorCopy) && !isLoadingDataMaster) &&
                  <DynamicFieldList control={control} fields={dynamicFieldsDatosIndicador} />
                }
              </div> 
            </Panel>     
            <Panel header="META" toggleable>
              <div className=''>
                {indicator.isLoading || isLoadingIndicatorCopy ? (
                    <div className='w-full flex flex-wrap 2xl:flex-nowrap justify-evenly gap-y-4 gap-x-3'>
                      {
                        [1,2,3,4,5,6].map((source) => (
                            <Skeleton width="10rem" height="8rem" key={source}></Skeleton>
                          )
                        )
                      }
                    </div>
                  ): (
                    <span className="p-float-label w-full mt-2">
                        <Controller
                            control={control}
                            rules={{
                              required: 'Seleccionar un rango es requerido',
                            }}
                            render={({
                                field: { onChange, value },
                            }) => (
                              // <div className='w-full flex flex-wrap xl:flex-nowrap justify-evenly gap-4'>
                              <div className='w-full flex flex-wrap 2xl:flex-nowrap justify-evenly gap-y-4 gap-x-3'>
                                {
                                  indicatorGoal.map((range) => (
                                      <div key={range.id} className='flex flex-col w-40 items-center gap-y-3 shadow-md py-2 px-2'>
                                        <div className='flex justify-center items-center gap-x-1'>
                                          <RadioButton  inputId={range.id} value={range} onChange={(e) => onChange(e.value)} checked={valuesFields.range?.id === range.id} disabled={isActualVersion} />
                                          <p className='text-xs'>{range.description}</p>
                                        </div>
                                        <div className='h-20 w-20 flex justify-center items-center'>
                                          <i className={`pi ${getTypeIconGoal(range.code)} text-3xl text-blue-600`}></i>
                                        </div>
                                      </div>
                                    )
                                  )
                                }
                              </div>
                            )}
                            name="range"
                        />
                    </span>
                  )}
              </div>
              <div className='h-4 md:h-8' />
              <div className='grid grid-cols-1 md:grid-cols-2 md:px-4 gap-4'>
                <div className='col-span-1 w-full lg:w-1/2'>
                  <>
                    {indicator.isLoading || isLoadingIndicatorCopy ? (
                        <div className='w-full mt-4'>
                          <Skeleton width="100%" height="2.5rem"></Skeleton>
                        </div>
                      ): (
                        <span className="p-float-label mt-4">
                            <Controller
                                control={control}
                                rules={{ required: 'El valor para la meta del indicador es requerido'}}
                                render={({
                                    field: { onChange, onBlur, value },
                                }) => (
                                  <InputText
                                      type='number'
                                      onChange={e => onChange(Number(e.target.value))}
                                      onBlur={onBlur}
                                      value={value}
                                      className={errors?.goalValue ? 'p-invalid w-full' : 'w-full'}
                                      disabled={isActualVersion}
                                  />
                                )}
                                name="goalValue"
                            />
                            <label htmlFor="goalValue">{valuesFields.range?.code === 'IRNG' ? 'Valor Bajo:' : 'Valor:'}</label>
                        </span>
                    )}
                    {
                      valuesFields.range?.code === 'IRNG' && (
                        <>
                          {indicator.isLoading || isLoadingIndicatorCopy ? (
                              <div className='w-full mt-4'>
                                <Skeleton width="100%" height="2.5rem"></Skeleton>
                              </div>
                            ): (
                              <span className="p-float-label mt-5">
                                  <Controller
                                      control={control}
                                      rules={{ required: 'El valor alto es requerido'}}
                                      render={({
                                          field: { onChange, onBlur, value },
                                      }) => (
                                        <InputText
                                            type='number'
                                            onChange={e => onChange(Number(e.target.value))}
                                            onBlur={onBlur}
                                            value={value}
                                            disabled={isActualVersion}
                                        />
                                      )}
                                      name="highValue"
                                  />
                                  <label htmlFor="highValue">Valor Alto:</label>
                              </span>
                          )}
                        </>
                      )
                    }
                  </>
                </div>
                <div className='col-span-1 w-full lg:w-4/5'>
                  {indicator.isLoading || isLoadingIndicatorCopy ? (
                      <div className='w-full mt-4'>
                        <Skeleton width="100%" height="2.5rem"></Skeleton>
                      </div>
                    ): (
                      <span className="p-float-label mt-4">
                          <Controller
                              control={control}
                              rules={{ required: 'La unidad es requerida'}}
                              render={({
                                  field: { onChange, onBlur, value },
                              }) => (
                                <Dropdown
                                    options={unitMeasure}
                                    optionLabel="Code"
                                    optionValue='Id'
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    showClear
                                    className={errors?.unitMeasurement ? 'p-invalid w-full' : 'w-full'}
                                    disabled={isActualVersion}
                                />
                              )}
                              name="unitMeasurement"
                          />
                          <label htmlFor="unitMeasurement">Seleccione unidad: *</label>
                      </span>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-6 md:px-4">
                {
                  ((!indicator.isLoading || !isLoadingIndicatorCopy) && !isLoadingDataMaster) &&
                  <DynamicFieldList control={control} fields={dynamicFieldsMeta} />
                }
              </div> 
            </Panel>
          </div>
        </div>
        {
          (existError) && <div className='message error'>
            <ul>
              {errors.source ? (
                  <>
                      <li className="p-error">{errors.source.message}</li>
                  </>
              ) : null}
              {errors.process ? (
                  <>
                      <li className="p-error">{errors.process.message}</li>
                  </>
              ) : null}
              {errors.code ? (
                  <>
                      <li className="p-error">{errors.code.message}</li>
                  </>
              ) : null}
              {errors.status ? (
                  <>
                      <li className="p-error">{errors.status.message}</li>
                  </>
              ) : null}
              {errors.name ? (
                  <>
                      <li className="p-error">{errors.name.message}</li>
                  </>
              ) : null}
              {errors.owner ? (
                  <>
                      <li className="p-error">{errors.owner.message}</li>
                  </>
              ) : null}
              {errors.type ? (
                  <>
                      <li className="p-error">{errors.type.message}</li>
                  </>
              ) : null}
              {errors.from ? (
                  <>
                      <li className="p-error">{errors.from.message}</li>
                  </>
              ) : null}
              {errors.decimalPlaces ? (
                  <>
                      <li className="p-error">{errors.decimalPlaces.message}</li>
                  </>
              ) : null}
              {errors.measurementFrequency ? (
                  <>
                      <li className="p-error">{errors.measurementFrequency.message}</li>
                  </>
              ) : null}
              {errors.to ? (
                  <>
                      <li className="p-error">{errors.to.message}</li>
                  </>
              ) : null}
              {errors.range ? (
                  <>
                      <li className="p-error">{errors.range.message}</li>
                  </>
              ) : null}
              {errors.goalValue ? (
                  <>
                      <li className="p-error">{errors.goalValue.message}</li>
                  </>
              ) : null}
              {errors.highValue && valuesFields.range?.code === 'IRNG' ? (
                  <>
                      <li className="p-error">{errors.highValue.message}</li>
                  </>
              ) : null}
              {errors.unitMeasurement ? (
                  <>
                      <li className="p-error">{errors.unitMeasurement.message}</li>
                  </>
              ) : null}
              {errors.period ? (
                  <>
                      <li className="p-error">{errors.period.message}</li>
                  </>
              ) : null}
            </ul>
          </div>
        }
        <div className="flex justify-end gap-4 mt-3">
            <Button
                icon="pi pi-times"
                type="button"
                label="Cancelar"
                onClick={() => { setVisible(true); }}
                className="sig-button sig-secondary mt-4"
            />
            {
              (mode!=='view' && (isCreate || isEdit))&&(
                <SplitButtonSave
                  items={items}
                  icon="pi pi-save"
                  type="button"
                  label="Guardar"
                  className="sig-button sig-primary mt-4"
                  styleMenu={{width: 250}}
                />
              )
            }
        </div>
      </form>
      <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={redirectToSearch}
          reject={reject}
      />
      <ConfirmDialog
          visible={visibleNewVersion}
          onHide={() => setVisibleNewVersion(false)}
          message="¿Desea guardar los cambios y generar una nueva versión?"
          header="Nueva Versión"
          icon="pi pi-exclamation-triangle"
          accept={onCreateNewVersion}
          reject={rejectNewVersion}
      />
    </div>
  )
}

export default IndicatorPage