import { apiService } from 'core/services/ApiService';
const currentModule = 'customer/api/CompanyCustomer';

const searchCompany = ({ fields = {}, change }) => {
  let initialFields = {
    page: 1,
    pageSize: 10,
    columnOrder: 'name',
    order: 'asc',
  };
  if (change) initialFields = { ...initialFields, ...fields };
  return apiService.Post(`${currentModule}/Search`, initialFields);
};

const saveCompany = (payload) => {
  return apiService.Post(`${currentModule}/Save`, payload);
};

const getCompanyById = (companyId) => {
  return apiService.Get(
    `${currentModule}/GetCompanyCustomerById?id=${companyId}`
  );
};

const deleteCompany = (companyId) => {
  return apiService.Delete(`${currentModule}/Delete?id=${companyId}`);
};

const getDataMaster = () => {
  return apiService.Post(`${currentModule}/GetDataMaster`, {
    isBusinessLine: true,
    isBusinessCode: true,
    isCountry: true,
    isDepartment: true,
    isCity: true,
    isDistrict: true,
    isContactPosition: true,
    isDocumentType: true,
  });
};

const generateReport = (payload) => {
  return apiService.Post(`${currentModule}/GenerateReport`, payload, {
    responseType: 'blob',
  });
};

const downloadTemplate = (fields ={}) =>{
  return apiService.Post('customer/api/CompanyCustomer/BulkUpload/DownloadTemplate', fields, {
    responseType: 'blob',
  });
}

const uploadBatchInfo = (file) =>{
  return apiService.Post('customer/api/CompanyCustomer/BulkUpload/UploadBatchInfo', file);
}

const batchSave = (payload) => {
  return apiService.Post(`${currentModule}/BatchSave`, payload)
}

export const CompanyServices = {
  searchCompany,
  saveCompany,
  getCompanyById,
  deleteCompany,
  generateReport,
  getDataMaster,
  downloadTemplate,
  uploadBatchInfo,
  batchSave,
};
