import { CoreConstants } from 'core/commons/CoreConstants';
import moment from 'moment';
import { toastAction } from "core/store/actions/ToastAction";
import FileSaver from 'file-saver';
import { ProcessService } from "features/configuration/services/ProcessService";
import { ConfigurationConstants } from "features/configuration/commons/ConfigurationConstants";

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: ConfigurationConstants.Accion.Processes.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};
const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: ConfigurationConstants.Accion.Processes.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload;

    if (advancedFilter.filter.name)
      pines.push({ field: 'name', value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });


    advancedFilter.values = pines;
    dispatch({
      type: ConfigurationConstants.Accion.Processes.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const updateFilterView = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload;

    const processSelected = getState().processReducer.process.data?.configSearch

    if (advancedFilter.filter[processSelected?.simpleFilters[0]])
      pines.push({ field: processSelected?.simpleFilters[0], value: advancedFilter.filter[processSelected?.simpleFilters[0]] });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });


    advancedFilter.values = pines;
    dispatch({
      type: ConfigurationConstants.Accion.Processes.UPDATE_FILTER_VIEW,
      advancedFilter,
    });
  };
};

const search = () => {
  const setOccupations = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ConfigurationConstants.Accion.Processes.SEARCH_PROCESSES,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const occupations = getState().processReducer.occupations;
    setOccupations({
      dispatch: dispatch,
      isLoading: true,
      data: occupations.data,
    });

    const criteria = {};
    const advancedFilter = getState().processReducer.advancedFilter;
    const filter = getState().processReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    ProcessService.searchProcesses(criteria).then(
      ({ data }) => {
        // console.log(data)
        if (data.status === CoreConstants.HttpResponse.OK)
        setOccupations({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
        setOccupations({ dispatch: dispatch, isLoading: false, data: null });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setOccupations({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Procesos', data.message));
        }
      },
      () => {
        setOccupations({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  };
};

const searchView = () => {
  const setView = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ConfigurationConstants.Accion.Processes.SEARCH_PROCESSES_VIEW,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const occupations = getState().processReducer.occupations;
    const processSelected = getState().processReducer.process.data?.configSearch
    setView({
      dispatch: dispatch,
      isLoading: true,
      data: occupations.data,
    });

    const criteria = {};
    const advancedFilterView = getState().processReducer.advancedFilterView;
    const filter = getState().processReducer.advancedFilterView.filter;

    criteria.columnOrder = advancedFilterView.columnOrder;
    criteria.order = advancedFilterView.order;
    criteria.page = advancedFilterView.page;
    criteria.pageSize = advancedFilterView.pageSize;

    criteria.name = filter[processSelected.simpleFilters[0]];
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    ProcessService.searchProcessesView(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setView({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
        setView({ dispatch: dispatch, isLoading: false, data: null });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setView({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Procesos', data.message));
        }
      },
      () => {
        setView({ dispatch: dispatch, isLoading: false, data: null });
      }
    );
  };
};

const getProcessById = (processId) => {
  const setProcess = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ConfigurationConstants.Accion.Processes.GET_PROCESS_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setProcess({ dispatch: dispatch, isLoading: true });
    ProcessService.getProcessById(processId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setProcess({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setProcess({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Procesos', data.message));
          // dispatch({
          //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
          //   toast: {
          //     titulo: 'Departamento',
          //     mensaje: data.message,
          //     severidad: 'warn',
          //   },
          // });
        }
      },
      () => {
        setProcess({ dispatch: dispatch, isLoading: false });
      }
    );
  };
};

const restoreProcessById = () => {

  return (dispatch) => {
    dispatch({
      type: ConfigurationConstants.Accion.Processes.RESTORE_PROCESS,
    });
  }
}

const restoreSaved = () => {
  const isLoading = ({ dispatch, isSaving, isSaved}) => {
    dispatch({
      type: ConfigurationConstants.Accion.Processes.SAVE_PROCESS,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };
  return (dispatch, getState) => {
    isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
  }
}


const saveProcesses = (formulary) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentFormulary }) => {
    dispatch({
      type: ConfigurationConstants.Accion.Processes.SAVE_PROCESS,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    const currentFormulary = getState().processReducer.formularies;
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
     currentFormulary: currentFormulary,
    });
    ProcessService.saveProcesses(formulary).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Formulario', 'El formulario se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentFormulary, });
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          // dispatch(toastAction.error('Formulario', "No se puede guardar la configuración de la ficha porque su estado es distinto a borrador."));
          dispatch(toastAction.error('Formulario', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentFormulary });}
        else
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });

      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    );
  };
};



const deleteProcess = (occupationId) => {

  return (dispatch, getState) => {

    ProcessService.deleteProcess(occupationId).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Proceso Dinámico', 'El Proceso Dinámico se eliminó correctamente'));
          dispatch({ type: ConfigurationConstants.Accion.Processes.DELETE_PROCESS, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Proceso Dinámico', data.message));
          dispatch({ type: ConfigurationConstants.Accion.Processes.DELETE_PROCESS, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: ConfigurationConstants.Accion.Processes.DELETE_PROCESS, payload: { isDeleted: false } });
      }
    );
  };
};

const generateReport = (payload) => {
  const setOccupations = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ConfigurationConstants.Accion.Processes.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setOccupations({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().processReducer.advancedFilter.filter;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    ProcessService.generateReport(criteria)
      .then(({ data }) => {
        setOccupations({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Procesos.xls');
      })
      .catch((err) => {
        setOccupations({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Procesos', 'Hubo un error al descargar el archivo'));
      });
  };
};

const searchListControls = () => {
  const setControls = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ConfigurationConstants.Accion.Processes.LIST_CONTROLS,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    ProcessService.searchListControls().then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setControls({
            dispatch: dispatch,
            isLoading: false,
            data: [...data.data 
              // {
              //   "icon": "pi pi-users",
              //   "visibleTable": true,
              //   "visibleSearch": true,
              //   "visibleAdvancedFilter": true,
              //   "visibleSimpleFilter": true,
              //   "id": 35,
              //   "code": "USUARIOS",
              //   "name": "Usuarios",
              //   "description": null
              // },
              // {
              //   code: "CONEXION_A_PROCESOS",
              //   description: null,
              //   icon: "pi pi-hashtag",
              //   id: 991,
              //   name: "Conexión a procesos",
              //   visibleAdvancedFilter: true,
              //   visibleSearch: true,
              //   visibleSimpleFilter: true,
              //   visibleTable: false
              // },
              // {
              //   code: "CONEXION_A_DATOS",
              //   description: null,
              //   icon: "pi pi-hashtag",
              //   id: 992,
              //   name: "Conexión a datos",
              //   visibleAdvancedFilter: true,
              //   visibleSearch: true,
              //   visibleSimpleFilter: true,
              //   visibleTable: false
              // },
            ],
          });
        }else if (data.status > CoreConstants.HttpResponse.OK) {
          setControls({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Lista de Controles', data.message));
        }
    })
  }
}

const searchResponsibles = () =>{
  const setResponsibles = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ConfigurationConstants.Accion.Processes.LIST_RESPONSIBLES,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    ProcessService.searchResponsibles().then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setResponsibles({
            dispatch: dispatch,
            isLoading: false,
            data: {
              ...data.data,
              "taskFields": [
              {
                "id": 325,
                "code": "TASK_FIELD_RESPONSIBLE",
                "name": null,
                "description": "Responsable"
              },
              {
                "id": 326,
                "code": "TASK_FIELD_SCHEDULE_DATE",
                "name": null,
                "description": "Fecha"
              },
              {
                "id": 327,
                "code": "TASK_FIELD_TITLE",
                "name": null,
                "description": "Titulo"
              }
            ]
            },
          });
        }else if (data.status > CoreConstants.HttpResponse.OK) {
          setResponsibles({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Lista de responsables', data.message));
        }
    })
  }
}

const setDateMasters = (payload) => {
  return (dispatch) =>
    dispatch({
      type: ConfigurationConstants.Accion.Processes.GET_DATA_MASTER,
      payload,
    });
};

const getDataMaster = () => {
  return (dispatch) => {
    dispatch(setDateMasters({ isLoading: true, roles: [] }));
    ProcessService.getDataMaster().then(({ data }) => {
      dispatch(setDateMasters({ 
        isLoading: false, 
        roles: data.data.roles,
      }));
    });
  };
};

export const ProcessesAction = {
  toggleAdvancedFilter,
  updateFilter,
  updateFilterView,
  search,
  searchView,
  saveProcesses,
  restoreSaved,
  deleteProcess,
  generateReport,
  resetAfterSaved,
  getProcessById,
  restoreProcessById,
  searchListControls,
  searchResponsibles,
  getDataMaster
}
