/* eslint-disable import/no-anonymous-default-export */

import { DocumentConstants } from "features/document/commons/DocumentConstants";


const initialState = {
  dataMaster: {
    isLoading: false,
    users: null,
    itemType: null,
    formatFile: null,
    fileStatus: null,
    workflowDocumentTypes: [],
    dynamicFields: [],
  },
  document: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false,
    isUpdated: false,
  },
  statusDocument:{
    isLoading: false,
    isSaved: false
  },
  listFolders: {
    isLoading: false,
    data: []
  },
  listFilesFolders: {
    isLoading: false,
    data: []
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'name',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {}
  },
  isStorageEnabled: {
    isLoading: false,
    isEnabled: null
  },
  currentVersion: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false
},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DocumentConstants.Accion.DocumentLibrary.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
      case DocumentConstants.Accion.DocumentLibrary.UPDATE_FILTER:
        return {
            ...state,
            advancedFilter: state.advancedFilter
        };
      case DocumentConstants.Accion.DocumentLibrary.APPROVED_DOCUMENT:
        return {
            ...state,
            statusDocument: {
                isLoading: action.payload.isLoading,
                isSaved: action.payload.isSaved
            }
        };
      case DocumentConstants.Accion.DocumentLibrary.REJECT_DOCUMENT:
        return {
            ...state,
            statusDocument: {
                isLoading: action.payload.isLoading,
                isSaved: action.payload.isSaved
            }
        };

    case DocumentConstants.Accion.DocumentLibrary.SEARCH_DOCUMENT_LIBRARIES:
        return {
            ...state,
            listFilesFolders: {
                isLoading: action.payload.isLoading,
                data: action.payload.data
            }
        };
    case DocumentConstants.Accion.DocumentLibrary.GET_DOCUMENT_LIBRARY_BY_ID:
      return {
          ...state,
          document: {
              data: action.payload.data,
              isSaving: false,
              isLoading: action.payload.isLoading
          }
        };

    case DocumentConstants.Accion.DocumentLibrary.COPY_DOCUMENT_LIBRARY:
    case DocumentConstants.Accion.DocumentLibrary.MOVE_DOCUMENT_LIBRARY:
    case DocumentConstants.Accion.DocumentLibrary.RENAME_DOCUMENT_LIBRARY:
        return {
            ...state,
            document: {
                isUpdated: action.payload.isUpdated
            }
        };
    case DocumentConstants.Accion.DocumentLibrary.UPLOAD_FILE:
        return {
            ...state,
            document: {
                data: action.payload.currentDocument,
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };
    case DocumentConstants.Accion.DocumentLibrary.GENERATE_VERSION:
        return {
            ...state,
            currentVersion: {
                data: action.payload.data,
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };

    case DocumentConstants.Accion.DocumentLibrary.DELETE_DOCUMENT_LIBRARY:
        return {
            ...state,
            document: {
                isDeleted: action.payload.isDeleted
            }
        };

    case DocumentConstants.Accion.DocumentLibrary.RESET_AFTER_SAVED:
        return {
            ...state,
            document: {
                data: null,
                isSaving: false,
                isSaved: false,
                isLoading: false,
                isDeleted: false,
                isUpdated: false,
            }
        };

    case DocumentConstants.Accion.DocumentLibrary.GET_DATA_MASTER:
        return {
            ...state,
            dataMaster: {
                users: action.payload.users,
                itemType: action.payload.itemType,
                formatFile: action.payload.formatFile,
                fileStatus: action.payload.fileStatus,
                workflowDocumentTypes: action.payload.workflowDocumentTypes,
                dynamicFields: action.payload.dynamicFields,
                isLoading: action.payload.isLoading
            }
        };
    case DocumentConstants.Accion.DocumentLibrary.GET_DATA_LIST_FOLDERS:
        return {
            ...state,
            listFolders: {
                data: action.payload.data,
                isLoading: action.payload.isLoading
            }
        };
    case DocumentConstants.Accion.DocumentLibrary.GET_DATA_LIST_FILES_AND_FOLDERS:
        return {
            ...state,
            listFilesFolders: {
                data: action.payload.data,
                isLoading: action.payload.isLoading
            }
        };
    case DocumentConstants.Accion.DocumentLibrary.GET_RECENT_FILES:
    case DocumentConstants.Accion.DocumentLibrary.GET_CURRENT_FILES:
    case DocumentConstants.Accion.DocumentLibrary.GET_MY_FILES:
    case DocumentConstants.Accion.DocumentLibrary.GET_TRASH_FILES:
        return {
            ...state,
            listFilesFolders: {
                data: action.payload.data,
                isLoading: action.payload.isLoading
            }
        };
    case DocumentConstants.Accion.DocumentLibrary.DOWNLOAD_REPORT_STATUS:
        return {
            ...state,
            document: {
                ...state,
                isLoading: action.payload.isLoading,
            },
        };
    case DocumentConstants.Accion.DocumentLibrary.IS_STORAGE_ENABLED:
        return {
            ...state,
            isStorageEnabled: {
                isLoading: action.payload.isLoading,
                isEnabled: action.payload.isEnabled,
            },
        };
    case DocumentConstants.Accion.DocumentLibrary.REMOVE_FILE_DOCUMENT:
        return {
            ...state,
            document: {
                ...state.document,
                data: {
                    // versions: state.document.data.versions,
                    ...state.document.data,
                    document: {
                        ...state.document.data.document,
                        fileName: null
                    }
                }
            }
        }
    default: 
    return state;
  }

}