import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PeoplePage from './pages/people/PeoplePage';
import NewPeoplePage from './pages/people/NewPeoplePage';
import SearchDepartmentPage from './pages/department/search/SearchDepartmentPage';
import PositionsPage from './pages/positions/PositionsPage';
import PositionPage from './pages/positions/PositionPage';

import departmentReducer from './store/reducers/DepartmentReducer';
import positionsReducer from './store/reducers/PositionsReducer';
import DepartmentPage from './pages/department/department/DepartmentPage';

import employeeReducer from 'features/rrhh/store/reducers/EmployeesReducer';
import organizationChartReducer from 'features/rrhh/store/reducers/OrganizationChartReducer';
import customFieldReducer from 'features/rrhh/store/reducers/CustomFieldReducer';

import SearchOrganizationChart from './pages/OrganizationChart/Search/SearchOrganizationChart';
import { FilePeople } from './pages/people/FilePeople';

export default function RrhhRouting(reducerRegistry) {
  const { path } = useRouteMatch();
  reducerRegistry.register({ departmentReducer });
  reducerRegistry.register({ positionsReducer });
  reducerRegistry.register({ employeeReducer });
  reducerRegistry.register({ organizationChartReducer });
  reducerRegistry.register({ customFieldReducer });

  return (
    <>
      <Switch>
        <Route path={`${path}/personas`}>
          <Route exact path={`${path}/personas`} component={PeoplePage} ren />
          <Route
            exact
            path={`${path}/personas/editar/:id`}
            component={() => <NewPeoplePage title="Editar Empleado" />}
          />
          <Route
            exact
            path={`${path}/personas/visualizar/:id`}
            component={() => <NewPeoplePage title="Ver Empleado" mode='view' />}
          />
          <Route
            exact
            path={`${path}/personas/nuevo`}
            component={() => <NewPeoplePage title="Nuevo Empleado" />}
          />
          <Route exact path={`${path}/personas/ficha-persona/ShowWf`}
            component={()=>
            <FilePeople title='Aprobación Ficha'
            subtitle='Mis Tareas'
            description='Aprobar Ficha'
            previousUrl="/inicio"
            disabled={true}/>}/>
          <Route
            exact
            path={`${path}/personas/ficha-persona`}
            component={()=> <FilePeople
              title='Gestión de la organización'
              subtitle='Empleados'
              description='Ficha de Personas'
              previousUrl='/rrhh/personas'
            disabled={false}/>}
          />
        </Route>
        <Route path={`${path}/cargos`} component={PositionsPage}>
          <Route exact path={`${path}/cargos`} component={PositionsPage} />
          <Route
            exact
            path={`${path}/cargos/nuevo`}
            component={() => <PositionPage title="NUEVO CARGO" />}
          />
          <Route
            exact
            path={`${path}/cargos/editar/:id`}
            component={() => <PositionPage title="EDITAR CARGO" />}
          />
          <Route
            exact
            path={`${path}/cargos/visualizar/:id`}
            component={() => <PositionPage title="VER CARGO" mode='view' />}
          />
        </Route>
        <Route path={`${path}/departamento`}>
          <Switch>
            <Route
              exact
              path={`${path}/departamento`}
              component={SearchDepartmentPage}
            />
            <Route
              path={`${path}/departamento/nuevo`}
              exact
              component={() => <DepartmentPage title="Nuevo Departamento" />}
            />
            <Route
              path={`${path}/departamento/:id`}
              exact
              component={() => <DepartmentPage title="Editar Departamento" />}
            />
            <Route
              path={`${path}/departamento/visualizar/:id`}
              exact
              component={() => <DepartmentPage title="Ver Departamento" mode='view' />}
            />
          </Switch>
        </Route>
        <Route path={`${path}/organigrama`}>
          <Switch>
            <Route
              exact
              path={`${path}/organigrama`}
              component={SearchOrganizationChart}
            />
          </Switch>
        </Route>
      </Switch>
    </>
  );
}
