import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

export const useClaimProcessSheet = () => {

  const menu = useSelector((state) => state.authReducer.user.menuPrincipal);
  const pathName = useLocation()?.pathname;
  const [isDelete, setDelete] = useState(false);
  const [isEdit, setEdit] = useState(false);

  //CUSTOM
  const [isGenerateVersion, setGenerateVersion] = useState(false)

  useEffect(() => {
    remove();
    edit();
    generateVersion();
  }, [menu]);

  const remove = () => {
    const actionCode = 'PROCESS-SHEEP_DELETE';
    const optionCode = "PROCE";
    // setDelete(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
    setDelete(true);
  };

  const edit = () => {
    const actionCode = 'PROCESS-SHEEP_EDIT';
    const optionCode = "PROCE";
    // setEdit(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
    setEdit(true);
  };

  //CUSTOM

  const generateVersion = () => {
    const actionCode = 'PROCESS-SHEEP_VIEW';
    const optionCode = "GAD";
    // setGenerateVersion(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
    setGenerateVersion(true);
  };

  return {
    isDelete,
    isEdit,
    isGenerateVersion
  }
}