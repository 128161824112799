
import moment from 'moment';
import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from "core/store/actions/ToastAction";

import { SurveysConstants } from 'features/survey/commons/SurveyConstants';
import { SurveyManagementServices } from 'features/survey/services/SurveyManagementServices';
import FileSaver from 'file-saver';

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: SurveysConstants.Accion.SurveyManagement.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: SurveysConstants.Accion.SurveyManagement.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.title) pines.push({ field: 'title', value: advancedFilter.filter.title });
    if (advancedFilter.filter.creator) pines.push({ field: 'users', value: advancedFilter.filter.creator.fullName });
    if (advancedFilter.filter.status) pines.push({ field: 'itemType', value: advancedFilter.filter.status.description });
   
    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.DDMMYYYY
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.DDMMYYYY
        ),
      });

    advancedFilter.values = pines;

    dispatch({
      type: SurveysConstants.Accion.SurveyManagement.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setDocuments = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.SurveyManagement.SEARCH_SURVEY_MANAGEMENT,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    // const listFilesFolders = getState().documentLibraryReducer.listFilesFolders;
    // setDocuments({
    //   dispatch: dispatch,
    //   isLoading: true,
    //   data: listFilesFolders.data,
    // });

    const criteria = {};
    // const advancedFilter = getState().documentLibraryReducer.advancedFilter;
    const filter = getState().surveyReducer.advancedFilter.filter;
    
    // criteria.columnOrder = advancedFilter.columnOrder;
    // criteria.order = advancedFilter.order;
    // criteria.page = advancedFilter.page;
    // criteria.pageSize = advancedFilter.pageSize;

    criteria.title = filter.title;
    criteria.toDate = filter.from;
    criteria.fromDate = filter.to;
    // criteria.fileName = filter.title;
    criteria.ownerId = filter.creator?.id;
    criteria.statusId = filter.status?.id;

    SurveyManagementServices.searchDocumentLibraries(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setDocuments({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });          
        else if (data.status < CoreConstants.HttpResponse.OK)
        setDocuments({ dispatch: dispatch, isLoading: false, data: null });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setDocuments({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Gestión de Encuestas', data.message));
        }
      },
    );
  };
};


const generateReport = (payload) => {
  const setDocuments = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.SurveyManagement.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setDocuments({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().documentLibraryReducer.advancedFilter.filter;

    criteria.title = filter.title;
    // criteria.toDate = filter.from;
    // criteria.fromDate = filter.to;
    // criteria.ownerId = filter.creator?.id;
    // criteria.statusId = filter.status?.id;

    SurveyManagementServices.generateReport(criteria)
      .then(({ data }) => {
        setDocuments({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Gestión de Encuestas.xls');
      })
      .catch((err) => {
        setDocuments({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Gestión de Encuestas', 'Hubo un error al descargar el archivo'));
      });
  };
};

const createFolder = (folder) => {
  const isLoading = ({ dispatch, isSaving, isSaved }) => {
    dispatch({
      type: SurveysConstants.Accion.SurveyManagement.CREATE_FOLDER,
      payload: { isSaving, isSaved }
    });
  };

  return (dispatch) => {
    isLoading({ dispatch: dispatch, isSaving: true, isSaved: false });
    SurveyManagementServices.createLocation(folder).then(
      ( {data} ) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gestión Encuestas', 'La carpeta se creó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Gestión Encuestas', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }else{
          dispatch(toastAction.error('Gestión Encuestas', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }
      },
    ).catch((error)=>{
    });
  };
};

const deleteFolder = (locationId) => {

  return (dispatch) => {
    dispatch({ type: SurveysConstants.Accion.SurveyManagement.DELETE_FOLDER, payload: { isDeleted: false } });
    SurveyManagementServices.deleteLocation(locationId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gestión Encuestas', 'La carpeta se eliminó correctamente'));
          dispatch({ type: SurveysConstants.Accion.SurveyManagement.DELETE_FOLDER, payload: { isDeleted: true } });
        } else if(data.sta === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Gestión Encuestas', data.message));
          dispatch({ type: SurveysConstants.Accion.SurveyManagement.DELETE_FOLDER, payload: { isDeleted: false } });
        }else{
          dispatch(toastAction.warn('Gestión Encuestas', data.message));
          dispatch({ type: SurveysConstants.Accion.SurveyManagement.DELETE_FOLDER, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: SurveysConstants.Accion.SurveyManagement.DELETE_FOLDER, payload: { isDeleted: false } });
      }
    ).catch((error)=>{
    });
  };
};

const renameFolder = (dataFolder) => {
  const setDocument = ({ dispatch, isUpdated }) => {
    dispatch({
      type: SurveysConstants.Accion.SurveyManagement.RENAME_FOLDER,
      payload: { isUpdated },
    });
  };

  return (dispatch) => {
    SurveyManagementServices.renameLocation(dataFolder).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setDocument({ dispatch, isUpdated: true });
          dispatch(toastAction.success('Gestión Encuestas', 'La carpeta se renombró correctamente'));
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setDocument({ dispatch, isUpdated: false });
          dispatch(toastAction.warn('Gestión Encuestas', data.message));
        }else{
          setDocument({ dispatch, isUpdated: false });
          dispatch(toastAction.error('Gestión Encuestas', data.message));
        }
      }
    ).catch((error)=>{
    });
  };
};

const getListFolders = () => {
  const setListFolders = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.SurveyManagement.GET_LIST_FOLDERS,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setListFolders({ dispatch: dispatch, isLoading: true });

    SurveyManagementServices.getListFolders()
      .then(({ data }) => {
        setListFolders({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setListFolders({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error al descargar el archivo'));
      });
  }
};

const getListFoldersInCurrentPath = (locationId) => {
  const setListFolders = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.SurveyManagement.GET_LIST_FOLDERS_TABLE,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setListFolders({ dispatch: dispatch, isLoading: true });

    SurveyManagementServices.getListFoldersInCurrentPath(locationId)
      .then(({ data }) => {
        setListFolders({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setListFolders({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Bibioteca de Documentos', 'Hubo un error al descargar el archivo'));
      });
  }
};

const getPermissionByLocationId = (surveyId) => {
  const setSurvey = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.SurveyManagement.GET_PERMISSIONS_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setSurvey({ dispatch: dispatch, isLoading: true, data: [] });
    SurveyManagementServices.getPermissionByLocationId(surveyId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setSurvey({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setSurvey({ dispatch: dispatch, isLoading: false, data: [] });
          dispatch(toastAction.warn('Gestión de Encuestas', data.message));
        }
      },
      () => {
        setSurvey({ dispatch: dispatch, isLoading: false, data: [] });
      }
    );
  };
};

const savePermission = (permission) => {
  const isLoading = ({ dispatch, isSaving, isSaved }) => {
    dispatch({
      type: SurveysConstants.Accion.SurveyManagement.SAVE_PERMISSIONS,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch) => {

    isLoading({ dispatch: dispatch, isSaving: true, isSaved: false });
    SurveyManagementServices.savePermission(permission).then(
      ( {data} ) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gestión de Encuestas', 'Los permisos se guardaron correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true });
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL)
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        else
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      },
      // () => {
      //   isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      // }
    );
  };
};

const getDataMaster = () => {
  const setDataMaster = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SurveysConstants.Accion.SurveyManagement.GET_DATA_MASTER,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setDataMaster({ dispatch: dispatch, isLoading: true });

    SurveyManagementServices.getDataMaster()
      .then(({ data }) => {
        setDataMaster({ dispatch: dispatch, isLoading: false, data: data.data});
      })
      .catch((err) => {
        setDataMaster({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Administración de Encuestas', 'Hubo un error al cargar los datos'));
      });
  }
};

const setCurrentPath = (currentPath) => {
  const setCurrentPathDispatch = ({ dispatch, currentPath }) => {
    dispatch({
      type: SurveysConstants.Accion.Survey.SET_CURRENT_PATH,
      payload: { currentPath },
    });
  };
  return (dispatch) => setCurrentPathDispatch({ dispatch: dispatch, currentPath });
};

export const SurveyManagementAction = {
  //* Helpers
  toggleAdvancedFilter,
  updateFilter,
  resetAfterSaved,
  setCurrentPath,
  
  //* Funcionales
  getDataMaster,
  getListFolders,
  getListFoldersInCurrentPath,
  getPermissionByLocationId,
  savePermission,
  createFolder,
  deleteFolder,
  renameFolder,
}