import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import useSetTitlePage from "shared/hooks/useSetTitlePage";

import { useDispatch, useSelector } from "react-redux";
import { ProcessManagementAction } from "../store/actions/ProcessManagementAction";
import { ProcessManagementServices } from "../services/ProcessManagementServices";

import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from 'primereact/progressspinner';

import { GeneralDataProcessMap } from "../components/GeneralDataProcessMap";
import { DroppableCustom } from "../components/DroppableCustom";
import { DraggableCustom } from "../components/DraggableCustom";
import { MenuCustom } from "../components/MenuCustom";
import html2pdf from "html2pdf.js";
import { ProcessMapDownload } from "../components/ProcessMapDownload";
import { Skeleton } from "primereact/skeleton";
import { ProcesseSheetActions } from "../store/actions/ProcesseSheetActions";
import './PageProcessMap.scss'
import { toastAction } from "core/store/actions/ToastAction";
import { useClaimProcessManagment } from "../hooks/useClaimProcessManagment";
import { CoreConstants } from "core/commons/CoreConstants";
import { NewProcessMapDownload } from "../components/MapProcessDownload/NewProcessMapDownload";
import { ProcessMapService } from "features/configuration/services/ProcessMapService";

export const ProcessMap = ( ) => {
	const dispatch = useDispatch()
	const map = useRef()
	const history = useHistory();
	const [processType, setProcessType] = useState([])
	const [processTypeInput, setProcessTypeInput] = useState([])
	const [responsibles, setResponsibles] = useState([])
	const [idSelect, setIdSelect] = useState(null)
	const [showDelete, setShowDelete] = useState(false)
	const [data, setData] = useState({})
	const [version, setVersion] = useState('')
	const [versions, setVersions] = useState([])
	const [isLastVersion, setIsLastVersion] = useState(true)
	const [date, setDate] = useState(null)
	const [isLoading, setIsLoading] = useState([])	
	const [url, setUrl] = useState("")
	const [isDownlading, setIsDownlading] = useState(false)
	const [isForm, setIsForm] = useState({map: true, master: true})
	const [isValidateDate, setIsValidateDate] = useState(true)
  const [invalidCode, setInvalidCode] = useState(false)
  const [isLoadingForDownload, setIsLoadingForDownload] = useState(true)
  const [dataForDownload, setDataForDownload] = useState({})
  const [codeForDownload, setCodeForDownload] = useState()
	const [id, setId] = useState(null)
	const {updateTitle } = useSetTitlePage()

	const { isDownload, isCreate, isView, isSheet } = useClaimProcessManagment();

	const onDrop = (e,drop_target) => {
		let drop_source = Number(e.dataTransfer.getData("source_dropid"))
    let source_order = Number(e.dataTransfer.getData("source_order"))
		let dragidsource = Number(e.dataTransfer.getData("source_dragid"))
    const order_destiny = Number(e.target.getAttribute('order'))
		let dragidtarget = Number(e.target.getAttribute("dragid"))
		if(drop_target === drop_source && dragidtarget===dragidsource){
			return;
		}
		if(drop_target === drop_source && dragidtarget===0){
			return;
		}
		if(drop_target === drop_source){
			const send = {
				sourceProcessPosition: {
					id: dragidsource,
					position: order_destiny
				},
				targetProcessPosition: {
					id: dragidtarget,
					position: source_order
				}
			}
			const full = isLoading
			const aux = isLoading.map(item => {
				if(item.id === drop_target){
					return ({...item, state: true})
				}else{
					return item
				}
			})
			setIsLoading(aux)
			ProcessManagementServices.moveProcessPosition(send).then(({data})=>{
				ProcessManagementServices.getProcessMap().then(({data})=>{
					setProcessType(data.data.processMap.processTypes)
					setIsLoading(full)
				})
			})
		} else {
			const position = Math.max(...processType.find(item => item.id === drop_target).processes.map(item => item.position),0)+1
			const send =
			{
				id: dragidsource,
				sourceProcessTypeId: drop_source,
				targetProcessTypeId: drop_target,
				position,
			}
			const full = isLoading
			const aux = isLoading.map(item => {
				if(item.id === drop_target){
					return ({...item, state: true})
				}else{
					return item
				}
			})
			setIsLoading(aux)
			ProcessManagementServices.moveProcess(send).then(({data})=>{
				ProcessManagementServices.getProcessMap().then(({data})=>{
					setProcessType(data.data.processMap.processTypes)
					setIsLoading(full)
				})
			})

		}
	}

	useEffect(()=> {
    updateTitle({
      title: "Gestión de Procesos",
      subtitle: "Mapa de procesos",
    })
		dispatch(ProcesseSheetActions.restoreProcesSheetById())
  }, [])

	useEffect(()=>{
		setIsForm((prev) => ({...prev, map: false, master: false}))
		ProcessManagementServices.getProcessMap().then(({data})=>{
			setId(data.data.processMap.id)
			setProcessType(data.data.processMap.processTypes)
			setData(data.data.processMap)
      setCodeForDownload(data.data.processMap.code)
			setVersion(data.data.processMap.versionNumber)
			setVersions(data.data.versions)
			setDate(new Date(data.data.processMap.creationDate))
			setIsLoading([{id: data.data.processMap.processTypes[0].id, state: false},
				{id: data.data.processMap.processTypes[1].id, state: false},
				{id: data.data.processMap.processTypes[2].id, state: false}
			])
			setIsForm((prev) => ({...prev, map: true}))
		})
		ProcessManagementServices.getDataMaster().then(({data})=>{
			setResponsibles(data.data.responsibles)
			setProcessTypeInput(data.data.processTypes)
			setIsForm((prev) => ({...prev, master: true}))
		})
		ProcessManagementServices.getLogo64().then(({data})=>{
			const url = `data:${data.Data}`
      console.log(url)
			setUrl(url)
		})
    ProcessMapService.getProcessMapConfiguration().then(({data}) => {
      setDataForDownload(data.data)
    }).finally(()=> { setIsLoadingForDownload(false) })
	},[])

	const remove = async() => {
		const dataRemoved = await ProcessManagementServices.removeProcess(idSelect)
		if(dataRemoved.data.status===CoreConstants.HttpResponse.OK){
			dispatch(toastAction.success('Proceso', "El proceso se eliminó correctamente."))
		}else if(dataRemoved.data.status===CoreConstants.HttpResponse.ERROR_FUNTIONAL){
			dispatch(toastAction.warn('Proceso', dataRemoved.data.message))
		}else{
			dispatch(toastAction.error('Proceso', dataRemoved.data.message))
		}
		dispatch(ProcessManagementAction.getDataMaster())
		ProcessManagementServices.getProcessMap().then(({data})=>{
			setProcessType(data.data.processMap.processTypes)
			setData(data.data.processMap)
			setVersion(data.data.processMap.versionNumber)
			setVersions(data.data.versions)
			setDate(new Date(data.data.processMap.creationDate))
		})
		ProcessManagementServices.getDataMaster().then(({data})=>{
			setProcessTypeInput(data.data.processTypes)
		})
	}

	const changeName = (e, item) => {
		e.preventDefault()
		// if(e.target.name.value!==""){
		// 	const id = processTypeInput.filter((proce)=>proce.name === item.name)[0].id
			
		// 	e.target.name.placeholder=e.target.name.value
		// 	ProcessManagementServices.updateProcessTypeName({id, name: e.target.name.value})
		// 	.then(({data})=>{
		// 		e.target.name.value=""
		// 	})
		// }
		e.target.name.blur()
	}

	const changeNameBlur = (e, item) => {
		if(e.target.value!=="" && e.target.value.trim() !== item.name){
			if(processTypeInput.length === 0){
				const { defaultId } = processType.find((proce)=>proce.name === item.name)
				ProcessManagementServices.updateProcessTypeName({defaultId, name: e.target.value})
				.then(({data})=>{
					if(data.status === 0){
						ProcessManagementServices.getProcessMap().then(({data})=>{
							setProcessType(data.data.processMap.processTypes)
							setData(data.data.processMap)
							setVersion(data.data.processMap.versionNumber)
							setVersions(data.data.versions)
							setDate(new Date(data.data.processMap.creationDate))
						})
						ProcessManagementServices.getDataMaster().then(({data})=>{
							setProcessTypeInput(data.data.processTypes)
						})
					}else{
						dispatch(toastAction.error("Mapa de Proceso" ,data.message))
						e.target.value=item.name
					}
				}).catch(()=>{
					dispatch(toastAction.error("Mapa de Proceso", "Algo salio mal"))
					e.target.value=item.name
				})
				
			}else{
				const id = processTypeInput.filter((proce)=>proce.name === item.name)[0]?.id
				ProcessManagementServices.updateProcessTypeName({id, name: e.target.value})
				.then(({data})=>{
					if(data.status === 0){
						ProcessManagementServices.getProcessMap().then(({data})=>{
							setProcessType(data.data.processMap.processTypes)
							setData(data.data.processMap)
							setVersion(data.data.processMap.versionNumber)
							setVersions(data.data.versions)
							setDate(new Date(data.data.processMap.creationDate))
						})
						ProcessManagementServices.getDataMaster().then(({data})=>{
							setProcessTypeInput(data.data.processTypes)
						})
					}else{
						dispatch(toastAction.error("Mapa de Proceso" , "Algo salio mal"))
						e.target.value=item.name
					}
				}).catch(()=>{
					dispatch(toastAction.error("Mapa de Proceso", "Algo salio mal"))
					e.target.value=item.name
				})
			}
		}else{
			e.target.value = item.name
		}
	}

	const changeVersion = (e) => {
		setIsForm((prev) => ({...prev, map: false}))
			setVersion(e.value);
			setIsLastVersion(e.value === versions[versions.length -1].versionNumber)
			if(e.value === versions[versions.length -1].versionNumber){
				
				ProcessManagementServices.getProcessMap().then(({data})=>{
					
					setProcessType(data.data.processMap.processTypes)
					setData(data.data.processMap)
					setVersion(data.data.processMap.versionNumber)
					setVersions(data.data.versions)
					setDate(new Date(data.data.processMap.creationDate))
					setIsLoading([{id: data.data.processMap.processTypes[0].id, state: false},
						{id: data.data.processMap.processTypes[1].id, state: false},
						{id: data.data.processMap.processTypes[2].id, state: false}
					])
					setIsForm((prev) => ({...prev, map: true}))
				})
			}else{
				ProcessManagementServices.getVersionByVersionNumber(id, e.value).then(({data})=>{
					
					setProcessType(data.data.processMap.processTypeVersionList)
					setData(data.data.processMap)
					setVersion(data.data.processMap.versionNumber)
					setVersions(data.data.versions)
					setDate(new Date(data.data.processMap.creationDate))
					setIsForm((prev) => ({...prev, map: true}))
				})
			}
	}

	const download= async() => {
		// map.current.classList.remove("hidden")
		setIsDownlading(true)
		setTimeout(()=>{
      // const element = map.current
      // console.log(map.current.offsetWidth)
      // console.log(map.current.offsetHeight)
			// 	html2pdf().set({
      //   margin: [30, 30, 0, 30],
      //   filename: `Mapa de Procesos - v${version}`,
      //   image: {
      //       type: 'jpeg',
      //       quality: 0.98
      //   },
      //   html2canvas: {
      //       scale: 3, // A mayor escala, mejores gráficos, pero más peso
      //       letterRendering: true,
      //   },
      //   jsPDF: {
      //       unit: "px",
      //       format: [ map.current.offsetWidth + 27 + 30 + 30, map.current.offsetHeight + 30],
      //       orientation: 'landscape' // landscape o portrait
      //   }
			const element = map.current
				html2pdf().set({
        margin: 1,
        filename: `Mapa de Procesos - v${version}`,
        image: {
            type: 'jpeg',
            quality: 0.98
        },
        html2canvas: {
            scale: 3, // A mayor escala, mejores gráficos, pero más peso
            letterRendering: true,
        },
        jsPDF: {
            unit: "cm",
            format: "a4",
            orientation: 'landscape' // landscape o portrait
        }
    })
		.from(element)
    .save()
		.then((res) => {
			setIsDownlading(false)
			// map.current.classList.add("hidden")
		})
    .catch(err => console.log(err));
		}, 100)
		
	}


	return(
	<>
	<div className="flex items-center justify-between">
		<div>
		{
			(isLastVersion && isCreate) &&
			<Button
				icon="pi pi-plus"
				type="button"
				label="Nuevo Proceso"
				loading={isDownlading}
				className="sig-button sig-primary mt-4 mb-4 mr-4"
				onClick={() => {
					history.push('/mapa-procesos/nuevo-proceso');
				}}
			/>
		}
		{
			isDownload&&
				<Button
				type="button"
				label="Descargar"
				loading={isDownlading}
				className="sig-button sig-success mt-4 mb-4"
				onClick={download}
				icon={'pi pi-download'}
        disabled={isLoadingForDownload}
		/>
		}
	</div>
	{/* <Tooltip target=".customClassNameShow" position="top"/>
	<div className="customClassNameShow" data-pr-tooltip="Aceptar Ficha" data-pr-position="left">
		<i className="pi pi-cog mr-4 text-xl cursor-pointer" onClick={()=> {
			dispatch(ProcesseSheetActions.getLastSheet())
			history.push('/mapa-procesos/fichaProcesos/ShowWf')
		}}/>
	</div> */}
	<Tooltip target=".customClassName" position="top"/>
	<div className="customClassName" data-pr-tooltip="Configurar Ficha" data-pr-position="left">
		{
			isSheet&&
				<i className="pi pi-cog mr-4 text-xl cursor-pointer" onClick={()=> {
					history.push('/mapa-procesos/fichaProcesos')
				}}/>
		}
	</div>
	</div>
		<div className='flex gap-3 flex-wrap md:flex-nowrap sm:flex-nowrap'>
			<div style={{width: "-webkit-fill-available"}}>
				{
					!isForm?.map || !isForm?.master ? (
						<>
							<Skeleton height="9.2rem" />
						</>
				) : 
					(processType?.map((item, index)=>{
						return (
							<DroppableCustom isLastVersion={isLastVersion} changeNameBlur={changeNameBlur} isDownload={false} key={item.defaultId || item.id} changeName={changeName} item={item} dropid={item.id} onDrop={(e)=>onDrop(e,item.id)}>
								{
									isLoading.find(pro => {
										return Number(pro.id) === Number(item.id)
									})?.state ? <ProgressSpinner className="custom-spinner"/> : item.processes?.map((processes, index)=>(
										(<DraggableCustom isLastVersion={isLastVersion} key={processes.id} order={processes.position} dropid={item.id} dragid={processes.id}>
										<div className="hover:bg-gray-100 p-2 justify-between items-center flex cursor-pointer rounded-lg border-2 shadow-xl h-20 w-48" order={processes.position} dragid={processes.id}>
											<div className={'self-start h-full'} style={{width: "inherit"}} onClick={() => {if(isLastVersion){
												if(isView){
													history.push(`/mapa-procesos/editar-proceso/${processes.id}/readonly`)};
												}
												}} order={processes.position} dragid={processes.id}>
												{processes.name}
											</div>
											<div className="flex flex-col justify-between h-full" order={processes.position} dragid={processes.id}>
												<div className={'cursor-pointer'} order={processes.position} dragid={processes.id}>
													{
														isLastVersion &&

														<MenuCustom remove={remove} processes={processes} idSelect={idSelect} setIdSelect={setIdSelect} setShowDelete={setShowDelete} order={processes.position}/>
													}
													</div>
													<div order={processes.position}>
														<Tooltip target=".responsible" position="top"/>
														<i className='pi pi-user responsible' data-pr-tooltip={responsibles.filter((item)=>item.id===processes.responsibleId)[0]?.fullName}></i>
													</div>
												</div>
											</div>
										</DraggableCustom>)
									))
								}
							</DroppableCustom>
						)
					}
					))
				}
				{
					!isValidateDate && 				
					<div className='message error'>
						<ul>
							<li className="p-error">
								Fecha de elaboración es requerida
							</li>
						</ul>
					</div>
				}
			</div>
			<div> 
				<GeneralDataProcessMap source="mapa-procesos" setIsValidateDate={setIsValidateDate} date={date} versions={versions} setDate={setDate} data={data} version={version} setVersion={setVersion} setIsLastVersion={setIsLastVersion} isLastVersion={isLastVersion} setVersions={setVersions} isForm={isForm} responsibles={responsibles} changeVersion={changeVersion} invalidCode={invalidCode} setInvalidCode={setInvalidCode} setCodeForDownload={setCodeForDownload}/>
			</div>
			<ConfirmDialog
				visible={showDelete}
				onHide={() => setShowDelete(false)}
				message="¿Está seguro que desea eliminar el registro?"
				header="Eliminar"
				icon="pi pi-trash" 
				reject={() => setShowDelete(false)}
				accept={()=> remove()}
			/>
		</div>
    {
      isDownlading &&
      <div
        ref={map}
        // className="hidden"
      >
        <NewProcessMapDownload dataForDownload={dataForDownload} url={url} version={version} data={data} code={codeForDownload}/>				
      </div>
    }
		

	</>
	)
}