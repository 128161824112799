import AdvancedFilterComponent from 'features/riskManagement/components/RiskInventory/AdvancedFilterComponent';
import FilterComponent from 'features/riskManagement/components/RiskInventory/FilterComponent';
import TableComponent from 'features/riskManagement/components/RiskInventory/TableComponent';
import { useClaimInventory } from 'features/riskManagement/hooks/useClaimInventory';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';

const RiskInventoryPage = () => {
	const { updateTitle } = useSetTitlePage();
	const [showModal, setShowModal] = useState(false)
	const history = useHistory();

  useEffect(() => {
		updateTitle({
				title: 'Gestión de Riesgos',
				subtitle: 'Inventario',
		});
// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { isCreate, isDownload, isSearch } = useClaimInventory()

	return (
		<div>
			{
				(isSearch || isDownload)&&
				<FilterComponent setShowModal={setShowModal}/>
			}
			{
				isCreate&&
					<div className="flex items-center justify-between p-4">
						<Button
							icon="pi pi-plus"
							type="button"
							label="Nuevo"
							className="sig-button sig-dark pointer"
							onClick={()=>history.push('/gestion-riesgos/inventario/nuevo')} 
						/>
					</div>
			}
			<TableComponent />
			<AdvancedFilterComponent showModal={showModal} setShowModal={setShowModal} /> 
		</div>
	);
};

export default RiskInventoryPage;