import React from 'react'

const MessageEmptyQuestion = () => {
  return (
    <p className='mt-2 flex items-center justify-center rounded sig-warning py-2 text-white'>
      <i className="pi pi-info-circle mr-2"></i>
      No hay preguntas disponibles por el momento.
    </p>
  )
}

export default MessageEmptyQuestion