import { Dropdown } from "primereact/dropdown"
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Controller } from "react-hook-form"
import { useSelector } from "react-redux";


export const FieldsFormDynamicField = ({controlField, dataTable, watchField}) => {
  const dataMaster = useSelector(
    (state) => state.dynamicFieldsReducer.dataMaster.data
  );
  const dataSection = useSelector(
    (state) => state.dynamicFieldsReducer.section.data
  );
  
  const dataTableSectionIds = dataTable?.map( ({sectionId}) => sectionId)

  const inputTypeCode = dataMaster.dynammicFieldType?.find( ({id}) => id === watchField("typeId"))?.code

  return(
    <div className="w-full grid sm:grid-cols-2 gap-6 mt-4">
      <div className="w-full grid grid-rows-4 gap-6">
        <div className="flex w-full">
          <Controller
            name="typeId"
            control={controlField}
            rules={{required: "El Tipo es requerido"}}
            render={({field: {value, onChange}, fieldState: {error}}) => (
              <span className="p-float-label w-full">
                <Dropdown
                  options={dataMaster.dynammicFieldType}
                  optionLabel="description"
                  optionValue="id"
                  showClear
                  className={`w-full${error ? ' p-invalid':''}`}
                  name="typeId"
                  value={value}
                  onChange={onChange}
                />
                <label htmlFor="typeId">Seleccione Tipo *</label>
              </span>
            )}
          />
        </div>

        <div className="flex w-full">
          <Controller
            name="label"
            control={controlField}
            rules={{required: "La Etiqueta es requerida"}}
            render={({field: {value, onChange}, fieldState: {error}}) => (
              <span className="p-float-label w-full">
                <InputText
                  className={`w-full${error ? ' p-invalid':''}`}
                  name="label"
                  value={value || ""}
                  onChange={onChange}
                />
                <label htmlFor="label">Ingrese Etiqueta *</label>
              </span>
            )}
          />
        </div>

        <div className="flex w-full">
          <Controller
            name="sectionId"
            control={controlField}
            rules={{required: "La Sección es requerida"}}
            render={({field: {value, onChange}, fieldState: {error}}) => (
              <span className="p-float-label w-full">
                <Dropdown
                  options={dataSection}
                  optionLabel="name"
                  optionValue="id"
                  filter
                  filterBy="name"
                  showClear
                  className={`w-full${error ? ' p-invalid':''}`}
                  name="sectionId"
                  value={value}
                  onChange={onChange}
                  optionDisabled={(option) => !option.enabled || dataTableSectionIds.includes(option.id)}
                />
                <label htmlFor="sectionId">Seleccione Sección *</label>
              </span>
            )}
          />
        </div>

        <div className="flex w-1/2">
          <Controller
            name="maxLength"
            control={controlField}
            rules={{
              required: "El máximo de caracteres es requerido",
              validate: (value) => {
                if(value <= 0){
                  return "El máximo de caracteres es positivo"
                }
                if(inputTypeCode === "TXT" && value > 400){
                  return "El máximo de caracteres no puede ser mayor a 400"
                }
                if(inputTypeCode === "TRH" && value > 4000){
                  return "El máximo de caracteres no puede ser mayor a 4000"
                }
              },
            }}
            render={({field: {value, onChange}, fieldState: {error}}) => (
              <span className="p-float-label w-full">
                <InputNumber
                  className={`w-full ${!watchField("typeId") ? 'disabled' : ''}  ${error ? 'p-invalid' : ''}`}
                  name="maxLength"
                  value={value}
                  onValueChange={(e) => onChange(e.value)}
                  locale="es-PE"
                  // disabled={!watchField("typeId")}
                />
                <label htmlFor="maxLength">Máximo de caracteres*</label>
              </span>
            )}
          />
        </div>
      </div>

      <div className="w-full grid grid-rows-2 sm:grid-rows-4 gap-6">

        <div className="flex items-center">
          <Controller 
            name="enable"
            control={controlField}
            render={({field: {value, onChange}}) => (
              <>
                <InputSwitch
                  checked={value}
                  onChange={onChange}
                />
                <h5 className="text-sm ml-2">Habilitado</h5>
              </>
            )}
          />
        </div>

        <div className="flex items-center">
          <Controller
            name="isRequired"
            control={controlField}
            render={({field: {value, onChange}}) => (
              <>
                <InputSwitch
                  checked={value}
                  onChange={onChange}
                />
                <h5 className="text-sm ml-2">Mandatorio</h5>
              </>
            )}
          />
        </div>

      </div>
    </div>
  )
}