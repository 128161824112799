import { CoreConstants } from 'core/commons/CoreConstants';
import { BusinessSheetTypeConstants } from 'features/maintenance/commons/BusinessSheetTypeConstants';
import { toastAction } from 'core/store/actions/ToastAction';
import { BusinessSheetTypeService } from 'features/maintenance/services/BusinessSheetTypeService'; 

import FileSaver from "file-saver";
import { sheetTypeSearchAdapter } from 'features/maintenance/helpers/businessSheetType/BusinessSheetTypeAdapter';

const setBusinessSheetType = (payload) => ({
  type: BusinessSheetTypeConstants.Action.BusinessSheet.SEARCH,
  payload,
});

const setEditBusinessSheet = (payload) => ({
  type: BusinessSheetTypeConstants.Action.BusinessSheet.EDIT,
  payload,
});

const search = (pagination, setRowCount) => {
  return (dispatch) => {
    dispatch(setBusinessSheetType({ loading: true }));
    BusinessSheetTypeService.search({ fields: pagination })
      .then(({ data }) => {
        const status = data?.status ?? '';
        if (status === CoreConstants.HttpResponse.OK) {
          const { results, ...rest } = data.data;
          setRowCount(rest.rowCount)
          dispatch(setBusinessSheetType({ data: results.map(sheetTypeSearchAdapter) }));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Tipo de Ficha de Negocio", data.message));
        }else{
          dispatch(toastAction.error("Tipo de Ficha de Negocio", data.message));
        }

      })
      .catch((error)=>{
      })
      .finally(() => {
        dispatch(setBusinessSheetType({ loading: false }));
      });
  };
};

const downloadReport = (searchFields) => {
  return (dispatch) => {       
    dispatch(setBusinessSheetType({ isLoadingDownload: true }));
    BusinessSheetTypeService.generateReport(searchFields)
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        FileSaver.saveAs(blob, "TipoDeFichaDeNegocio.xlsx");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(setBusinessSheetType({ isLoadingDownload: false }));
      });
  };
};

const remove = (id, searchData) => {
  return (dispatch) => {
    dispatch(setBusinessSheetType({ loading: true }));
    BusinessSheetTypeService.remove(id).then(({ data }) => {
      const status = data.status;
      if (status === CoreConstants.HttpResponse.OK) {
        dispatch(toastAction.success('Tipos de Ficha de Negocio', 'Ficha de Negocio Eliminado'));
        searchData()
      } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
        dispatch(toastAction.warn("Tipo de Ficha de Negocio", data.message));
      }else{
        dispatch(toastAction.error("Tipo de Ficha de Negocio", data.message));
      }
    })
    .catch(console.error)
    .finally(() => {
      dispatch(setBusinessSheetType({ loading: false }));
    })
  }
}

const getById = (id) => {
  return (dispatch) => {
    dispatch(setEditBusinessSheet({ loading: true }));
    BusinessSheetTypeService.getById(id).then(({ data }) => {
      const status = data.status;
      if (status === CoreConstants.HttpResponse.OK) {
        dispatch(setEditBusinessSheet({ businessSheet: data.data }));
      } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
        dispatch(toastAction.warn("Tipos de Ficha de Negocio", data.message));
      }else{
        dispatch(toastAction.error("Tipos de Ficha de Negocio", data.message));
      }
    })
    .catch(console.error)
    .finally(() => {
      dispatch(setEditBusinessSheet({ loading: false }));
    });
  }
}

const resetDataBusinessSheet = () => ({
  type: BusinessSheetTypeConstants.Action.BusinessSheet.EDIT,
  payload: {
    businessSheet: {},
    loading: false,
  },
})

const setSave = (payload) => ({
  type: BusinessSheetTypeConstants.Action.BusinessSheet.SAVE,
  payload,
})

const save = (data, goToBack) => {
  return (dispatch) => {
    dispatch(setSave({ isLoadingSave: true }));
    BusinessSheetTypeService.save(data)
      .then(({ data }) => {
        const status = data?.status ?? '';
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(setSave({ isLoadingSave: false }));
          dispatch(toastAction.success("Tipos de Ficha de Negocio", "El Tipo de Ficha de Negocio se guardó correctamente"));
          goToBack()
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Tipos de Ficha de Negocio", data.message));
        }else{
          dispatch(toastAction.error("Tipos de Ficha de Negocio", data.message));
        }

      })
      .catch((error)=>{
        dispatch(toastAction.error("Tipos de Ficha de Negocio", error.message));
      })
      .finally(() => {
        dispatch(setSave({ isLoadingSave: false }));
      });
  }
}

export const BusinessSheetTypeAction = {
  search,
  downloadReport,
  save,
  remove,
  getById,
  resetDataBusinessSheet,
}
