/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CoreConstants } from 'core/commons/CoreConstants';
import validateTypeValue from 'shared/helpers/validateTypeValue';
import { DynamicFieldsAction } from 'features/configuration/store/actions/DynamicFieldsAction';

// import { useClaimCompanies } from '../useClaimCompanies';
import { DynamicFieldsTableData } from 'features/configuration/data/dynamicFields/DynamicFieldsTableData';

export const useTableDynamicFields = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams()
  const dynamicFields = useSelector((state) => state.dynamicFieldsReducer.dynamicFields);

  // const { isView ,isEdit, isDelete } = useClaimCompanies();
  // const isView = true
  const isEdit = true
  const isDelete = true
  /* TYPE TABLE VIEW ---------------- */
  const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);

  /* TABLE -------------------------- */
  const [tableDynamicFields, setTableDynamicFields] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState([]);
  useEffect(() => {
    const { data, pagination, loading } = dynamicFields;
    setTableDynamicFields(validateTypeValue(data));
    setTablePagination(pagination);
    setTableLoading(loading);
  }, [dynamicFields]);

  /* TABLE HEADER COLUMNS */
  const [selectColumns, setSelectColumns] = useState(
    DynamicFieldsTableData.columns
  );

  // useEffect(() => {
  //   if (localStorage.getItem('columnas-empresas')) {
  //     setSelectColumns(JSON.parse(localStorage.getItem('columnas-empresas')));
  //   }
  // }, []);

  const onColumnToggle = (e, item) => {
    let _selectedColumns = [...selectColumns];

    if (e.checked) _selectedColumns.push(item);
    else _selectedColumns = _selectedColumns.filter((c) => c.field !== e.value);

    if (_selectedColumns.length === 0) return;
    // localStorage.setItem('columnas-empresas', JSON.stringify(_selectedColumns));
    setSelectColumns(_selectedColumns);
  };

  /* TABLE HEADER ACTIONS */
  const headerActions = useRef(null);

  /* CURRENT COMPANY ------------------- */
  const dynamicFieldsIdSelect = useSelector(
    (state) => state.dynamicFieldsReducer.currentAction.dynamicFieldsId
  );

  const dynamicFieldsId = Number( params.dynamicFieldsId || dynamicFieldsIdSelect)
  
  const setCurrentDynamicFields = (dynamicFieldsId) => {
    dispatch(DynamicFieldsAction.currentAction({ dynamicFieldsId }));
  };

  /* TABLE CURRENT ACTION ------------- */
  const [currentAction, setCurrentAction] = useState('');
  const onCurrentAction = ({ item: { name } }) => setCurrentAction(name);
  useEffect(() => {
    const action_ = currentAction.toLocaleLowerCase();
    if (action_ === 'editar') {
      history.push(`/configuracion/campos-dinamicos/editar/${dynamicFieldsId}`);
    } else if (action_ === 'eliminar') {
      dispatch(DynamicFieldsAction.currentAction({ isOpen: true }));
    }
    setCurrentAction('');
  }, [currentAction]);

  /* TABLE ACTIONS -------------------- */
  const dynamicFieldsOptions = useSelector(
    (state) => state.dynamicFieldsReducer.actions.items
  );

  const [tableActions, setTableActions] = useState([]);
  const [showActions, setShowActions] = useState(false);
  const tableCurrentActions = useRef(null);

  useEffect(() => {
    if (dynamicFieldsOptions.length > 0) {
      let newActions = dynamicFieldsOptions.map((option) => {
        option.label = option.name;
        option.command = onCurrentAction;
        return option;
      });
      if (!isEdit)
        newActions = newActions.filter(c => c.label !== 'Editar');
      if (!isDelete)
        newActions = newActions.filter(c => c.label !== 'Eliminar');
      setTableActions(newActions);
    }
    setShowActions(dynamicFieldsOptions.length > 0);
  }, [isEdit, isDelete]);

  /* MODAL DELETE (ACTIONS) */
  const isOpenModalDelete = useSelector(
    (state) => state.dynamicFieldsReducer.currentAction.isOpen
  );
  const confirmDelete = () => {
    dispatch(DynamicFieldsAction.deleteById(dynamicFieldsId));
  };
  const cancelDelete = () => {
    dispatch(DynamicFieldsAction.currentAction({ isOpen: false, companyId: '' }));
  };

  /* TABLE PAGINATION --------------- */
  const dynamicFieldsSearch = useSelector(
    (state) => state.dynamicFieldsReducer.dynamicFields
  );
  const [tableRows, setTableRows] = useState(10);
  const [tableNum, setTableNum] = useState(0);
  const [page, setPage] = useState(1);
  let currentFilterFields = {
    columnOrder: dynamicFieldsSearch.columnOrder,
    order: dynamicFieldsSearch.order,
  };

  const filterValues = useSelector(
    (state) => state.dynamicFieldsReducer.advancedFilter.values
  );
  const buildFilterValues = () => {
    if (filterValues.length > 0) {
      filterValues.forEach(({ field, value, option }) => {
        let currentValue = '';
        const key = field.toLocaleLowerCase();
        if (key.includes('id')) currentValue = option.id;
        else currentValue = value;
        currentFilterFields[field] = currentValue;
      });
    }
  };

  const onChangePage = ({ rows, page }) => {
    buildFilterValues();
    currentFilterFields.page = page + 1;
    currentFilterFields.pageSize = rows;
    setTableRows(rows);
    setTableNum(rows * page);
    setPage(page + 1);
    const fields = currentFilterFields;
    dispatch(DynamicFieldsAction.getDynamicFields({ change: true, fields }));
  };

  /* TABLE ORDER SORT */
  const [tableSort, setTableSort] = useState({
    sortField: 'name',
    sortOrder: '1',
  });
  const onSort = (event) => {
    buildFilterValues();
    const params = {
      first: tableNum,
      rows: tableRows,
      multiSortMeta: [],
      filters: null,
      ...event,
    };

    currentFilterFields.order = event.sortOrder + '' === '1' ? 'asc' : 'desc';
    currentFilterFields.columnOrder = event.sortField;
    currentFilterFields.pageSize = tableRows;
    currentFilterFields.page = page;
    const fields = currentFilterFields;
    dispatch(DynamicFieldsAction.getDynamicFields({ change: true, fields }));
    setTableSort(params);
  };

  return {
    selectColumns,
    typeView,
    setTypeView,
    headerActions,
    onColumnToggle,
    showActions,
    tableCurrentActions,
    tableActions,
    tableDynamicFields,
    tableLoading,
    tablePagination,
    setCurrentDynamicFields,
    confirmDelete,
    cancelDelete,
    isOpenModalDelete,
    tableSort,
    tableNum,
    tableRows,
    onSort,
    onChangePage,
  };
};
