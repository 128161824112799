export class ProjectConstants {
  static Action = class {
    static Project = class {
      static get ADVANCED_FILTER() {
        return '@PROJECT.ADVANCED-FILTER';
      }
      static get SEARCH_PROJECT() {
        return '@PROJECT.SEARCH-PROJECT';
      }
      static get DATA_MASTER() {
        return '@PROJECT.DATA-MASTER';
      }
      static get CURRENT_ACTION() {
        return '@PROJECT.CURRENT-ACTION';
      }
      static get EDIT_PROJECT() {
        return '@PROJECT.EDIT-PROJECT';
      }
      static get DOWNLOAD_REPORT_STATUS() {
        return '@PROJECT.DOWNLOAD-REPORT-STATUS';
      }
    };
  };

  static SectionsName = class {
    static get DATOS_GENERALES() {
      return 'DATOS GENERALES';
    }
    static get PARTICIPANTES() {
      return 'PARTICIPANTES';
    }
    static get ENTREGABLES() {
      return 'ENTREGABLES';
    }
  }
}
