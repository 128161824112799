import { apiService } from "core/services/ApiService";

const searchProcesseSheet = (criteria) => {
  return apiService.Post("management/api/ConfigDynamicProcessSheet/Search", criteria);
}

const getOptions = () => {
  return apiService.Get("management/api/ConfigDynamicProcessSheet/ListControls");
}

const saveProcessesSheet = (data) => {
  return apiService.Post("management/api/ConfigDynamicProcessSheet/SaveSheet", data);
}

const getProcesseSheetById = (procesSheetId) => {
  return apiService.Get("management/api/ConfigDynamicProcessSheet/GetSheetById?id=" + procesSheetId);
}

const getProcesseSheetByVersion = (procesSheetId) => {
  return apiService.Get("management/api/ConfigDynamicProcessSheet/GetSheetById?id=" + procesSheetId);
}

const getActiveSheet = () => {
  return apiService.Get("management/api/ConfigDynamicProcessSheet/GetActiveSheet");
}

const generateVersion = (reason) => {
  return apiService.Post("management/api/ConfigDynamicProcessSheet/GenerateVersion", reason)
}

const generateReport = (payload) => {
  return apiService.Post("customer/api/Task/GenerateReport", payload, {
    responseType: 'blob',
  })
}

const deleteProcesseSheet = (procesSheetId) => {
  return apiService.Delete("management/api/ConfigDynamicProcessSheet/RemoveProcess?processId=" + procesSheetId);
}

const searchSummary = () => {
  return apiService.Post("customer/api/Task/Dashboard/Summary");
}

const getProducedBy = () => {
  return apiService.Post("management/api/ConfigDynamicProcessSheet/GetDataMaster")
}

const getListVersions = () => {
  return apiService.Get("management/api/ConfigDynamicProcessSheet/listVersions")
}

const getLastSheet = () => {
  return apiService.Get("management/api/ConfigDynamicProcessSheet/GetLastSheet")
}

const saveSheetAndSubmitWorkflow = (data) => {
  return apiService.Post("management/api/ConfigDynamicProcessSheet/SaveSheetAndSubmitWorkflow", data);
}

const getVersionsDetailedList = (id, version) => {
  return apiService.Get(`management/api/ConfigDynamicProcessSheet/GetVersionsDetailedList?id=${id}&versionNumber=${version}`)
}



export const ProcesseSheetServices = {
  searchProcesseSheet,
  saveProcessesSheet,
  getProcesseSheetById,
  getProcesseSheetByVersion,
  generateReport,
  deleteProcesseSheet,
  getOptions,
  searchSummary,
  getProducedBy,
  getActiveSheet,
  generateVersion,
  getListVersions,
  getLastSheet,
  saveSheetAndSubmitWorkflow,
  getVersionsDetailedList,
};