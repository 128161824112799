import { apiService } from 'core/services/ApiService';
const currentModule = 'humanresource/api/CompanyAreaPosition';

const searchPositions = ({ fields = {}, change }) => {
  let initialFields = {
    page: 1,
    pageSize: 10,
    columnOrder: 'name',
    order: 'asc',
  };
  if (change) initialFields = { ...initialFields, ...fields };
  return apiService.Post(`${currentModule}/Search`, initialFields);
};

const savePosition = (payload) => {
  return apiService.Post(`${currentModule}/Save`, payload);
};

const getPositionById = (positionId) => {
  return apiService.Get(
    `${currentModule}/GetCompanyAreaPositionById?id=${positionId}`
  );
};

const deletePosition = (positionId) => {
  return apiService.Delete(
    `${currentModule}/DeleteCompanyAreaPosition?id=${positionId}`
  );
};

const generateReport = (payload) => {
  return apiService.Post(`${currentModule}/GenerateReport`, payload, {
    responseType: 'blob',
  });
};

const getPositionResultById = (positionId) =>{
  return apiService.Get(
    `${currentModule}/GetAreaPositionResultById?id=${positionId}`
  );
}

const getDataMaster = () => {
  return apiService.Post(`${currentModule}/GetDataMaster`, {
    isArea: true,
    isAreaPosition: true,
    isEmployee: false,
    isEmployeeType: false,
    isPositionType: true,
    isMaritalStatus: false,
    isAcademicDegree: false,
    isEmployeeSituation: false,
    isGender: false,
    isDocumentType: false,
    isCountry: false,
    isDepartment: false,
    isCity: false,
    isDistrict: false,
    IsPositionStatus: true,
  });
};

export const PositionsServices = {
  searchPositions,
  savePosition,
  getPositionResultById,
  getPositionById,
  deletePosition,
  generateReport,
  getDataMaster,
};
