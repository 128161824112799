import { useState } from "react";
import { ControlGeneralConfiguration } from "./ControlGeneralConfiguration";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";

export const InputTextConfiguration = ({question, onChangeOptions, removeField}) => {
  const [required, setRequired] = useState(question.required)
  const [linkTo, setLinkTo] = useState(question.linkTo?JSON.parse(JSON.stringify(question.linkTo)):null)
  const changeRequired = (e) => {
    setRequired(e.value)
  }

  const changeLinkTo = (e) => {
    if(e.value){
      setRequired(true)
    }
    setLinkTo(e.value)
  }

  const changeOptionsAditional = () => {
    onChangeOptions({field: "required", value: required, id: question.id})
    onChangeOptions({field: "linkTo", value: linkTo, id: question.id})
  }

  const onCancel = () => {
    setRequired(question.required);
    setLinkTo(question.linkTo?JSON.parse(JSON.stringify(question.linkTo)):null);
  }

  return(
    <ControlGeneralConfiguration
      changeOptionsAditional={changeOptionsAditional}
      onChangeOptions={onChangeOptions}
      question={question}
      removeField={removeField}
      onCancel={onCancel}
      render={() => <div className={`borderInput h-16`}/>}
      headerTitle="TEXTO"
    >
      <div className='flex items-center mt-4'>
        <InputSwitch checked={required} name="required" onChange={changeRequired} disabled={linkTo}/>
        <h5 className='ml-2'>Requerido</h5>
      </div>
      <div>
        <span className="p-float-label w-full md:w-2/3 lg:w-1/2 my-5">
          <Dropdown
            options={question.optionsLinkTo || []}
            optionLabel="description"
            optionValue="id"
            value={linkTo}
            onChange={changeLinkTo} 
            showClear
            className={`w-1/2 `}
            name="linkTo"
          />
          <label>Relacionar con:</label>
        </span>
      </div>
    </ControlGeneralConfiguration>
  )
}