import React, { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Column } from "primereact/column";
import { SelectButton } from "primereact/selectbutton";
import moment from "moment";
import { Paginator } from "primereact/paginator";
import { useHistory } from "react-router";
import { Fragment } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { CoreConstants } from "core/commons/CoreConstants";
import { Menu } from "primereact/menu";
import useSubscriptions from "features/subscription/hooks/useSubscriptions";
import useTable from "features/subscription/hooks/useTable";
import { useClaimSubscription } from "features/subscription/hooks/useClaimSubscription";
import { Link } from "react-router-dom";
const TableComponent = () => {
  const history = useHistory();
  const [idCustomer, setIdCustomer] = useState(null);
  const [visible, setIsVisible] = useState(false);
  const [pendiente,setIsPendiente] = useState(false)
  const { isView ,isEdit, isDelete } = useClaimSubscription();
  const { 
    data,
    deleteCustomerId
   } = useSubscriptions();
  const typeViews = [
    { icon: "pi pi-list", value: CoreConstants.TypeView.LIST },
    { icon: "pi pi-microsoft", value: CoreConstants.TypeView.CANVA },
  ];
  const {
    onPageChange,
    pagination,
    firstTable,
    rowsTable,
    onSort,
    tableSort,
  } = useTable()
  const columns = [
    { field: "locationQuantity", header: "N° Sedes"},
    { field: "companyQuantity", header: "N° Empresas"},
    { field: "numberActiveUsers", header: "N° Usuarios" },
    { field: "activationDate", header: "Fecha de Alta" },
    { field: "vigencia", header: "Vigencia" },
    { field: "statusName", header: "Estado" },
  ];
  const [selectedColumns, setSelectedColumns] = useState(columns);
  const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);
  const actionsHeader = useRef(null);
  const menu = useRef(null);
  const styles = {
    Activo: {
      border: "1px solid #8CC9A6",
      padding: "0rem 0.50rem",
      borderRadius: "5px",
      maxWidth:"100px"
    },
    Cancelado: {
      border: "1px solid #F6697C ",
      padding: "0rem 0.50rem",
      borderRadius: "5px",
      maxWidth:"100px"
    },
    Expirado: {
      border: "1px solid #F6697C ",
      padding: "0rem 0.50rem",
      borderRadius: "5px",
      maxWidth:"100px"
    },
    Pendiente: {
      border: "1px solid #808080 ",
      padding: "0rem 0.50rem",
      borderRadius: "5px",
      maxWidth:"100px"
    },
    Bloqueado: {
      border: "1px solid #F6697C",
      padding: "0rem 0.50rem",
      borderRadius: "5px",
      maxWidth:"100px"
    },
  };

  let items = [
    {
      label: "Editar",
      icon: "pi pi-pencil",
      command: () => {
        history.push("/suscripcion/suscripciones/editar/" + idCustomer);
      },
    },
    {
      label: "Eliminar",
      icon: "pi pi-trash",
      command: () => {
        setIsVisible(true);
      },
    },
  ];

  if (!isEdit)
      items = items.filter(c => c.label !== 'Editar');
  if (!isDelete)
      items = items.filter(c => c.label !== 'Eliminar');

  const getViewField = (rowData, col) => {
        if (isView)
          return <Link className="underline text-blue-700" to={{ pathname: `/suscripcion/suscripciones/visualizar/` + rowData.id }} >{rowData.customerName}</Link>
        else
          return rowData.customerName;
      }

  const onBodyStatusTemplate = (rowData) => {
    return (
      <h1 className="text-center"  style={styles[rowData.statusName]}>
        {rowData.statusName}
      </h1>
    );
  };

  const onBodyUsersTemplate = (rowData) => {
    return (
      <h1>
        {rowData.numberActiveUsers} / {rowData.userQuantity}
      </h1>
    );
  };

  const onVigenciaTeTemplate = (rowData) => {
    if(rowData.statusCode === "PENDIENTE" || rowData.fromDate === null)
      return(
        <h1>-</h1>
      )
    else
      return (
        <h1 >
          {!rowData.fromDate
            ? "Vigente"
            : `${rowData.fromDate} - ${rowData.toDate}`}
        </h1>
      );
  };

  const onActivationTemplate = (rowData) => {
    return (
      <h1>
        {!rowData.activationDate ? "-" : rowData.activationDate}
      </h1>
    );
  };

  const columnComponents = selectedColumns.map((col) => {
    if(col.field !== "numberActiveUsers")
      return <Column
      key={col.field}
      field={col.field}
      header={col.header}
      sortable={col.field !== "vigencia"}
      body={
        col.field === "statusName"
          ? onBodyStatusTemplate
          : col.field === "numberActiveUsers"
          ? onBodyUsersTemplate
          : col.field === "vigencia"
          ? onVigenciaTeTemplate
          : col.field === "activationDate"
          ? onActivationTemplate
          : null
      }
    />
    else
      return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        body={
          col.field === "statusName"
            ? onBodyStatusTemplate
            : col.field === "numberActiveUsers"
            ? onBodyUsersTemplate
            : col.field === "vigencia"
            ? onVigenciaTeTemplate
            : col.field === "activationDate"
            ? onActivationTemplate
            : null
        }
      />
    );
  });

  const actionHeaderTemplate = (rowData) => {
    return (
      <i
        key="action"
        className="pi pi-ellipsis-v cursor-pointer"
        onClick={(e) => {
          actionsHeader.current.toggle(e);
        }}
      ></i>
    );
  };

  const actionBodyTemplate = (rowData) => {
    if(isEdit||isDelete){
      return (
        <i
          key={rowData.id}
          className="pi pi-ellipsis-v cursor-pointer"
          onClick={(event) => {
            setIdCustomer(rowData.id);
            menu.current.toggle(event);
            rowData.statusName === "Pendiente" ? setIsPendiente(false) :  setIsPendiente(true)
          }}
        ></i>
      );
    }else{
      return <div></div>
    }
  };

  const onColumnToggle = (e, item) => {
    let _selectedColumns = [...selectedColumns];

    if (e.checked) _selectedColumns.push(item);
    else
      _selectedColumns = _selectedColumns.filter((c) => {
        return c.field !== e.value;
      });

    setSelectedColumns(_selectedColumns);
  };

  const getColumns = () => {
    let array = [];
    columns.map((item, index) => {
      const column = (
        <div key={item.field} className="p-field-checkbox py-1">
          <Checkbox
            inputId={item.field}
            name="column"
            value={item.field}
            onChange={(e) => onColumnToggle(e, item)}
            checked={selectedColumns.some((x) => x.field === item.field)}
          />
          <label class="ml-1" htmlFor={item.field}>
            {item.header}
          </label>
        </div>
      );
      array.push(column);
    });
    return array;
  };
  console.log(data)
  return (
    <Fragment>
      <div className="flex justify-end mb-2">
        <SelectButton
          unselectable={false}
          value={typeView}
          options={typeViews}
          onChange={(e) => setTypeView(e.value)}
          itemTemplate={(option) => {
            return <i className={option.icon}></i>;
          }}
        />
      </div>

      {typeView === CoreConstants.TypeView.CANVA && (
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-2">
          {data.map((item, index) => {
            return (
              <div className="block p-4 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <div className="flex justify-between">
                  {
                    isView?(
                      <Link className="underline text-blue-700" to={{ pathname: `/suscripcion/suscripciones/editar/` + item.id }} ><h5>{item.customerName}</h5></Link>
                    ):(
                      <h5 className="mb-1 text-md font-bold   text-gray-900 dark:text-white">
                        {item.customerName}
                      </h5>
                    )
                  }
                  <div class="flex items-center gap-4">
                    <span
                      className="flex align-items-center text-xs float-right px-2 text-black bg-white rounded-full border border-gray-200"
                      style={styles[item.statusName]}
                    >
                      {item.statusName}
                    </span>
                    {
                      (isEdit||isDelete)&&(
                        <i
                          key={item.id}
                          className="pi pi-ellipsis-v cursor-pointer"
                          onClick={(e) => {
                            setIdCustomer(item.id);
                            menu.current.toggle(e);
                            item.statusName === "Pendiente" ? setIsPendiente(false) :  setIsPendiente(true)
                          }}
                        ></i>
                      )
                    }
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-3 items-center">
                  <div className="col-span-2">
                    <h5 className="text-xs text-gray-700 dark:text-gray-400">
                      Fecha de Alta:{" "}
                      {item.activationDate
                        ? item.activationDate
                        : "No activado"}
                    </h5>
                    <h5 className="text-xs text-gray-700 dark:text-gray-400">
                      Vigencia: {item.fromDate} - {item.toDate}
                    </h5>
                    <h5 className="text-xs text-gray-700 dark:text-gray-400">
                      {item.areaName}
                    </h5>
                    <div className="flex justify-between text-xs text-gray-700 dark:text-gray-400">
                      <span>N° Empresas: {item.companyQuantity}</span>
                      <span>N° Sedes: {item.locationQuantity}</span>
                    </div>
                  </div>
                  <div className="col-span-1 flex flex-col items-center justify-center text-center">
                    <div
                      className="flex justify-center items-center text-sm"
                      style={{ borderRadius: "50%", border: "1px solid #0006",width:"40px",height:"40px" }}
                    >
                      {item.numberActiveUsers}/{item.userQuantity}
                    </div>
                    <span className="text-xs text-gray-700 dark:text-gray-400">
                      Usuarios activos
                    </span>
                  </div>
                </div>
                <h5 className="text-xs text-gray-700 dark:text-gray-400 flex justify-end">
                  {item.branchName}
                </h5>
              </div>
            );
          })}
        </div>
      )}

      {typeView === CoreConstants.TypeView.LIST && (
        <div className="table-main table-roles mt-3 ">
          <DataTable
            value={data}
            stripedRows
            responsiveLayout="scroll"
            rows={rowsTable}
            size="small"
            sortField={tableSort?.sortField}
            sortOrder={tableSort?.sortOrder === "asc" ? 1 : -1}
            // filters={tableSort?.filters}
            emptyMessage="No se encontraron Subscripciones"
            onSort={onSort}
          >
            <Column field="customerName" header="Empresa" body={getViewField} sortable />
            {columnComponents}
            <Column
              header={actionHeaderTemplate}
              body={actionBodyTemplate}
            ></Column>
          </DataTable>
          <Paginator
            className="paginator-custom mt-4"
            first={firstTable}
            totalRecords={pagination?.rowCount}
            rows={rowsTable}
            onPageChange={onPageChange}
          ></Paginator>
        </div>
      )}

      <OverlayPanel
        ref={actionsHeader}
        showCloseIcon
        style={{ width: "300px" }}
      >
        {getColumns()}
      </OverlayPanel>

      {/* <Menu model={items} popup ref={menu} id="popup_menu" onShow={e => {
        pendiente ? document.querySelectorAll('.p-menuitem')[1].classList.add('hidden') : document.querySelectorAll('.p-menuitem')[0].classList.remove('hidden')
      }} /> */}
       <Menu model={items} popup ref={menu} id="popup_menu"/>
      <ConfirmDialog
        visible={visible}
        onHide={() => setIsVisible(false)}
        message="Estás seguro que desea eliminar el registro ?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={() => {
          deleteCustomerId(idCustomer)
        }}
        reject={() => setIsVisible(false)}
      />
    </Fragment>
  );
};

export default TableComponent;
