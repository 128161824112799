import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import Message from "shared/components/messages/Message";
import useSubscriptions from "features/subscription/hooks/useSubscriptions";
const ActiveSubscription = ({ close, visible , statusId }) => {
  const errorsArray = [];
  const params = useParams();
  const {
    formState: { errors },
    handleSubmit,
    control,
    setError,
  } = useForm();

  const {
    loadingEdit,
    loadingDataManager,
    changeStatus,
  } = useSubscriptions("Editado",false)
  
  const headerTemplate = () => {
    return <h1 className="fs-title-modal">¿ESTÁ SEGURO?</h1>;
  };
  const submit = (data) => {
    if(data.fromDate > data.toDate){
      setError("fromDate", {
        type: "manual",
        message: "La Fecha de Inicio no puede ser mayor a la del Fin",
      });
      return
    }
    data = {...data,subscriptionId:parseInt(params.id),statusId}
    close()
    changeStatus(data,"ACTIVO")
  };
  return (
    <Dialog
      header={headerTemplate}
      visible={visible}
      closable
      breakpoints={{ "960px": "75vw", "640px": "100vw" }}
      style={{ width: "50vw" }}
      onHide={() => close()}
    >
      <div
        className="line-through bg-blue-800"
        style={{ height: "7.5px", width: "50px" }}
      ></div>
      <h2 className="font-bold fs-subtitle-modal mt-3">
        ¿Desea dar de alta a la suscripción?
      </h2>
      <form onSubmit={handleSubmit(submit)}>
        <h2 className="mt-3 font-bold">VIGENCIA</h2>
        <div className="grid grid-cols-2 gap-10 mt-3">
          <span className="p-float-label">
            <Controller
              control={control}
              rules={{
                required: "La Fecha de Inicio es obligatorio",
              }}
              render={({ field: { onChange, value } }) => (
                <Calendar
                  onChange={onChange}
                  className={`w-full ${
                    errors.fromDate?.type === "required" ||  errors.fromDate?.type === "manual" ? "p-invalid" : ""
                  }`}
                  value={value}
                  name="fromDate"
                  id="fromDate"
                />
              )}
              name="fromDate"
            />
            <label htmlFor="fromDate">Inicio</label>
          </span>
          <span className="p-float-label">
            <Controller
              control={control}
              rules={{
                required: "La Fecha de  Fin es obligatorio",
              }}
              render={({ field: { onChange, value } }) => (
                <Calendar
                  onChange={onChange}
                  className={`w-full ${
                    errors.toDate?.type === "required" ? "p-invalid" : ""
                  }`}
                  value={value}
                  name="toDate"
                  id="toDate"
                />
              )}
              name="toDate"
            />
            <label htmlFor="toDate">fin</label>
          </span>
        </div>
        <h2 className="mt-3 mb-3 font-bold">DATOS DE LA CUENTA PRINCIPAL </h2>
        <div className="grid grid-cols-2 gap-x-5">
          <span className="p-float-label mb-4 col-span-2 w-full">
            <Controller
              control={control}
              rules={{
                required: "El Correo es obligatorio",
                pattern: {
                  value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message:
                    "El Formato de correo del administrador no es válido",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <InputText
                  id="email"
                  value={value}
                  className={`w-full ${
                    errors.email?.type === "required" || errors.email?.type === "pattern" ? "p-invalid" : ""
                  }`}
                  onChange={onChange}
                />
              )}
              name="email"
            />
            <label htmlFor="email">
              Ingrese el correo del usuario administrador
            </label>
          </span>
          <span className="p-float-label col-span-1 w-full">
            <Controller
              control={control}
              rules={{
                required: "Los Nombres son obligatorios",
              }}
              render={({ field: { onChange, value } }) => (
                <InputText
                  id="firstName"
                  value={value}
                  className={`w-full ${
                    errors.firstName?.type === "required" ? "p-invalid" : ""
                  }`}
                  onChange={onChange}
                />
              )}
              name="firstName"
            />
            <label htmlFor="centro">Nombres</label>
          </span>
          <span className="p-float-label col-span-1 w-full">
            <Controller
              control={control}
              rules={{
                required: "Los Apellidos son  obligatorios",
              }}
              render={({ field: { onChange, value } }) => (
                <InputText
                  id="lastName"
                  value={value}
                  className={`w-full ${
                    errors.lastName?.type === "required" ? "p-invalid" : ""
                  }`}
                  onChange={onChange}
                />
              )}
              name="lastName"
            />
            <label htmlFor="lastName">Apellidos</label>
          </span>
        </div>
        {Object.entries(errors).forEach((key, value) => {
          errorsArray.push(key[1].message);
        })}
        {errorsArray.length > 0 && (
          <Message messages={errorsArray} status="error" />
        )}
        <div className="col-span-5 flex justify-end gap-2 w-full">
          <Button
            icon="pi pi-times"
            type="button"
            label="Cancelar"
            onClick={() => {
              close();
            }}
            disabled={loadingEdit || loadingDataManager}
            className="sig-button  sig-secondary mt-4"
          />
          <Button
            icon="pi pi-save"
            type="submit"
            label="Guardar"
            loading={loadingEdit || loadingDataManager}
            disabled={loadingEdit || loadingDataManager}
            className="sig-button sig-primary mt-4"
          />
        </div>
      </form>
    </Dialog>
  );
};

export default ActiveSubscription;
