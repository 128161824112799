import './PinerComponent.scss';

const PinerComponent = ({ name, field, removePiner, id, showRemovePinerButton = true }) => {
  return (
    <div className="piner">
      <p>{name}</p>
      {
        showRemovePinerButton &&
        <i
          className="pi pi-times cursor-pointer"
          onClick={() => removePiner(field, id)}
        ></i>
      }
    </div>
  );
};

export default PinerComponent;
