import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form'
import { Panel } from 'primereact/panel';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Editor } from 'primereact/editor';
import { Dialog } from 'primereact/dialog';
import Attachment from './Attachment';
import moment from 'moment';
import HeaderEditor from 'shared/components/header-editor/HeaderEditor';
import useDocumentLibrary from 'features/document/hooks/useDocumentLibrary';
import { DocumentLibraryAction } from 'features/document/store/actions/DocumentLibraryAction';
import { Calendar } from 'primereact/calendar';
import { ModalGenerateVersion } from 'features/processManagement/components/ProceseSheet/ModalGenerateVersion';
import { Dropdown } from 'primereact/dropdown';
import { ButtonMultipleSend } from 'features/processManagement/components/ProceseSheet/ButtonMultipleSend';
import { CoreConstants } from 'core/commons/CoreConstants';
import { useClaimDocumentLibrary } from 'features/document/hooks/useClaimDocumentLibrary';
import Attachments from 'shared/components/attachments/Attachments';
import { v4 as uuidv4 } from "uuid";
import { ModalSelectFlow } from 'shared/components/ModalSelectFlow/ModalSelectFlow';
import { getDynamicFields, getDynamicFieldsList } from 'shared/utils/getDynamicFields';
import { DocumentConstants } from 'features/document/commons/DocumentConstants';
import { DynamicFieldList } from 'shared/components/dynamicFields/DynamicFieldList';

const flows = [
  {
    id: 1,
    name: 'Flujo 1'
  },
  {
    id: 2,
    name: 'Flujo 2'
  },
  {
    id: 3,
    name: 'Flujo 3'
  },
]

const ModalDocumentLibrary = ({displayModal, setDisplayModal, selectedNode, actionSelected, setActionSelected, mode, documentCode}) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [displayFlowModal, setDisplayFlowModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showApprovedModal, setShowApprovedModal] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [errorFile, setErrorFile] = useState(null)

  const document = useSelector((state) => state.documentLibraryReducer.document); 
  const {data} = useSelector((state) => state.documentLibraryReducer.document);
  const {isLoading} = useSelector((state) => state.documentLibraryReducer.document)
  const { isSaving, isSaved } = useSelector(state => state.documentLibraryReducer.currentVersion)
  const versions = useSelector((state) => state.documentLibraryReducer.document.data?.versions)
  const statusDocument = useSelector((state) => state.documentLibraryReducer.statusDocument)
  const { control, errors, valuesFields, existError, setValue, handleSubmit, validateLength, clearErrors} = useDocumentLibrary()
  // console.log(valuesFields)
  const dataMaster = useSelector((state) => state.documentLibraryReducer.dataMaster);
  const header = HeaderEditor();

  const { isVersion } = useClaimDocumentLibrary()

  const dynamicFields = dataMaster?.dynamicFields || []
  const dynamicFieldsDatosDocumento = getDynamicFields(dynamicFields, DocumentConstants.SectionsName.DATOS_DOCUMENTO)
  
  const getRealDate = (date) => {
    let fecha = new Date(date)
    fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
    return fecha
  }

  useEffect(()=> {
    if(statusDocument.isSaved===true){
      onHide()
    }
  },[statusDocument.isSaved])


  const generarVersion = (value) => {
    dispatch(DocumentLibraryAction.generateVersion({"reason": value}))
  }

  const approvedDocument = (value) => {
    dispatch(DocumentLibraryAction.approvedDocument({
      documentId: Number(data.document.id),
      comment: value,
      documentCode: documentCode
    }))
  }

  const rejectDocument = (value) => {
    dispatch(DocumentLibraryAction.rejectDocument({
      documentId: Number(data.document.id),
      comment: value,
      documentCode: documentCode
  }))
  }

  useEffect(() => {
    setErrorFile(null)
    // dispatch(DocumentLibraryAction.getDataMaster());
  },[])

  useEffect(() => {
    if (!data) {
    setValue('code', '')
    setValue('title', '')
    setValue('comments', '')
    setValue('file', null)
    setValue('statusName', '')
    setValue('statusCode', '')
    setValue('ownerId', '')
    setValue('version', '')
    setValue('documentDate', '')
    setValue('version', '')
    setValue('version_number', 1)
    setValue('updateDate', '')
    setValue('savedFiles', [])
    setValue('addedFiles', [])
  }
  }, [])

  useEffect(() => {
    if (data && mode!=='new') {
      setValue('code', data.document.code)
      setValue('title', data.document.title)
      setValue('comments', data.document.comments)
      setValue('statusName', data.document.statusName)
      setValue('statusCode', data.document.statusCode)
      setValue('ownerId', data.document.ownerId)
      setValue('version',data.document.versionNumber)
      setValue('documentDate', data.document.documentDate ? getRealDate(data.document.documentDate) : '')
      setValue('updateDate', data.document.modificationDate ? moment(data.document.modificationDate).format(CoreConstants.Format.LOCALE) : '')
      setValue('file', null)
      const savedFile = {
        fileName: data.document.fileName,
        url: data.document.url,
        createdBy: data.document.createdBy,
        creationDate: data.document.documentDate,
        id: uuidv4(),
      }
      setValue('savedFiles', [savedFile])
      setValue('addedFiles', [])

      if(data.dynamicData?.length > 0){
        data.dynamicData.forEach( dynamicFieldValue => {
          setValue(`dynamicFieldData:${dynamicFieldValue.identifier}`, dynamicFieldValue.value)
        })
      }
    }else{
      setValue('code', '')
      setValue('title', '')
      setValue('comments', '')
      setValue('file', null)
      setValue('statusName', '')
      setValue('statusCode', '')
      setValue('ownerId', '')
      setValue('version', '')
      setValue('documentDate', '')
      setValue('version', '')
      setValue('version_number', 1)
      setValue('updateDate', '')
      setValue('savedFiles', [])
      setValue('addedFiles', [])
    }
  }, [data])

  useEffect(() => {
    if (document?.isSaved){
      onHide()
      dispatch(DocumentLibraryAction.getDataListFilesAndFolders(selectedNode));
    }
  }, [document])

  useEffect(()=>{
    if(isSaved===true){
      setShowModal(false)
    }
  },[isSaved])

  const onHide = () => {
    setShowModal(false)
    setShowApprovedModal(false)
    setShowRejectModal(false)
    setDisplayModal(false)
    setValue('code', '')
    setValue('title', '')
    setValue('comments', '')
    setValue('file', '')
    setValue('statusName', '')
    setValue('version', '')
    setValue('documentDate', '')
    setValue('version', '')
    setValue('version_number', 1)
    setValue('updateDate', '')
    setValue('savedFiles', [])
    setValue('addedFiles', [])
    setActionSelected(null)
    clearErrors()
    if (data) {
      dispatch(DocumentLibraryAction.resetAfterSaved());
    }
  }

  const redirectToSearch = () => {
    onHide()
  }

  const reject = () => {
    setVisible(false);
  };

  const onSubmit = (newFile, type) => {
    const currentDocument = {
      dynamicFieldValues: data?.dynamicData || []
    }
    const dynamicDataValues = getDynamicFieldsList(newFile, dynamicFields, mode === 'new', currentDocument)
    if (mode==='edit') {
      if(data){
        let bodyFormData = new FormData();
        dynamicDataValues.forEach((dynamicDataValue, index) => {
          Object.keys(dynamicDataValue).forEach(key => {
            if (key === 'id' && !dynamicDataValue[key]) return
            const dinamicKey = `DynamicDataValues[${index}].${key}`;
            const valor = dynamicDataValue[key];
            bodyFormData.append(dinamicKey, valor);
          });
        });
        bodyFormData.append('Id', data.document.id)
        bodyFormData.append('TargetPath', selectedNode)
        bodyFormData.append('Code', newFile.code)
        bodyFormData.append('Title', newFile.title)
        bodyFormData.append('Comments', newFile.comments ? newFile.comments : '')
        bodyFormData.append('VersionNumber', Number(newFile.version))
        bodyFormData.append('DocumentDate', moment(newFile.documentDate).format('YYYY/MM/DD'))
        bodyFormData.append('OwnerId', newFile.ownerId)
        if(newFile.addedFiles.length > 0){
          bodyFormData.append('File', newFile.addedFiles[0])
        }
        if(Number(type)===1){
          dispatch(DocumentLibraryAction.uploadFile(bodyFormData));
        }else if(Number(type)===2){
          bodyFormData.append('WorkflowId', newFile.flowId)
          dispatch(DocumentLibraryAction.uploadandApproveFile(bodyFormData));
        }
      }
    }else if(!mode || mode==='new'){
      if (newFile.addedFiles.length === 0){
        setErrorFile("Cargue un documento")
        return null
      }else{
        setErrorFile(null)
      }
      let bodyFormData = new FormData();
      dynamicDataValues.forEach((dynamicDataValue, index) => {
        Object.keys(dynamicDataValue).forEach(key => {
          if (key === 'id' && !dynamicDataValue[key]) return
          const dinamicKey = `DynamicDataValues[${index}].${key}`;
          const valor = dynamicDataValue[key];
          bodyFormData.append(dinamicKey, valor);
        });
      });
      bodyFormData.append('TargetPath', selectedNode)
      bodyFormData.append('Code', newFile.code)
      bodyFormData.append('Title', newFile.title)
      bodyFormData.append('Comments', newFile.comments ? newFile.comments : '')
      bodyFormData.append('File', newFile.addedFiles[0])
      bodyFormData.append('VersionNumber', Number(newFile.version))
      bodyFormData.append('OwnerId', newFile.ownerId);
      bodyFormData.append('DocumentDate', moment(newFile.documentDate).format('YYYY/MM/DD'))

      if(Number(type)===1){
        dispatch(DocumentLibraryAction.uploadFile(bodyFormData));
      }else if(Number(type)===2){
        bodyFormData.append('WorkflowId', newFile.flowId)
        dispatch(DocumentLibraryAction.uploadandApproveFile(bodyFormData));
      }
    };
  }

  const titleModal = () => {
    if(!mode){
      if (actionSelected === 'readOnly') return 'Ver Documento'
      if (!data) {
        return 'Nuevo Documento'
      } else {
        return 'Editar Documento'
      }
    }else{
      if(mode==='view'){
        return 'Vista Documento'
      }else if(mode==='edit'){
        return 'Editar Documento'
      }else if(mode==='new'){
        return 'Nuevo Documento'
      }else if(mode==='aprovedOrReject'){
        return 'Editar Documento'
      }
    }
  }

  const items = [
    {
        label: 'Guardar',
        icon: "pi pi-save",
        type: "submit",
        command: ()=>{
          handleSubmit((e)=>{onSubmit(e,1)})()
        }
    },
    {
      label: 'Guardar y Aprobar',
      icon: "pi pi-upload",
      command: ()=>{
        // handleSubmit((e)=>{onSubmit(e,2)})()
        // trigger()
        // if (Object.keys(errors).length === 0) {
        //   console.log(errors)
        // }
        setDisplayFlowModal(true)
      }
  }
  ];

  const onSubmitApprove = () => {
    handleSubmit((e)=>{onSubmit(e,2)})()
  }

  return (
    <Dialog header={titleModal} visible={displayModal} className='w-4/5 lg:w-3/4 xl:w-3/5' onHide={onHide}>
      <form 
        className="form-custom p-0"
      >
      <div className="form-modal p-0">
        <Panel header="DATOS DOCUMENTO" toggleable>
          <div className="grid grid-cols-6 gap-2">
            <div className='col-span-6 md:col-span-4 px-3'
                // style={{"border": "1px solid #dee2e6"}}
            >
              <div className=''>
                {document.isLoading ? (
                  <Skeleton />
                ): (
                  <span className="p-float-label w-full my-2">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value},
                          }) => (
                            <InputText
                                type="text"
                                id="code"
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                maxLength={10}
                                className={`${errors?.code ? 'p-invalid w-full' : 'w-full'} ${(actionSelected === 'readOnly' ? true : false || mode==='view' || mode==='aprovedOrReject')&&'disabled'}`}
                                disabled={actionSelected === 'readOnly' ? true : false || mode==='view' || mode==='aprovedOrReject'}
                            />
                          )}
                          name="code"
                      />
                        
                      <label htmlFor="code">Codigo</label>
                  </span>
                )}          
                  <span className="p-float-label w-full my-3 md:mt-5">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value},
                          }) => (
                            <InputText
                                type="text"
                                id='title'
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                maxLength={100}
                                className={`${errors?.title ? 'p-invalid w-full' : 'w-full'} ${(actionSelected === 'readOnly' ? true : false || mode==='view' || mode==='aprovedOrReject')&&'disabled'}`}
                                disabled={actionSelected === 'readOnly' ? true : false || mode==='view' || mode==='aprovedOrReject'}
                            />
                          )}
                          name="title"
                          rules={{
                            required: 'El título es requerido',
                        }}
                      />
                      <label htmlFor="title">Título *</label>
                  </span>
              </div>
              {document.isLoading ? (
                  <Skeleton height="8rem"/>
                ): (
              <div className=''>
                 <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Editor
                        headerTemplate={header}
                        onTextChange={(e) => onChange(e.htmlValue)}
                        value={value}
                        placeholder="Añade un comentario del archivo"
                        style={{'height':'260px'}}
                        className={`${(actionSelected === 'readOnly' ? true : false || mode==='view' || mode==='aprovedOrReject')&&'disabled'}`}
                        // className={`h-52`}
                        readOnly={actionSelected === 'readOnly' ? true : false || mode==='view' || mode==='aprovedOrReject'}
                      />
                      <p className="font-semibold text-base text-gray-500 lg:mt-1 mt-3 text-right">
                        Caracteres{' '}
                        {validateLength(
                          valuesFields.comments
                        )}{' '}
                        / 4000{' '}
                      </p>
                    </>
                  )}
                  name="comments"
                />
            </div>)
            }
            {
              (document.isLoading) ? <Skeleton /> : (
                data 
                ? (null)
                : (
                    <span className="p-float-label w-1/3 my-2">
                        <Controller
                            control={control}
                            render={({
                                field: { onChange, onBlur, value},
                            }) => (
                              <InputText
                                  type="number"
                                  onChange={(e) => onChange(e.target.value <= 1 ? 1 : e.target.value)}
                                  onBlur={onBlur}
                                  value={value}
                                  maxLength={10}
                                  className={`${errors?.version_number ? 'p-invalid w-full' : 'w-full'} ${(actionSelected === 'readOnly' ? true : false || mode==='view' || mode==='aprovedOrReject')&&'disabled'}`}
                                  // disabled={actionSelected === 'readOnly' ? true : false || mode==='view' || mode==='aprovedOrReject'}
                              />
                            )}
                            name="version_number"
                        />
                          
                        <label htmlFor="version_number">Versión</label>
                    </span>
                )
              )
            }    
            <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-6">
              {
                (!document.isLoading && !dataMaster.isLoading) &&
                <DynamicFieldList control={control} fields={dynamicFieldsDatosDocumento} />
              }
            </div>
            </div>
            <Panel header="DATOS GENERALES" className='col-span-6 md:col-span-2'>
              <div className='flex flex-col justify-start'>
                {
                  valuesFields?.statusName && valuesFields?.statusName!=='' && <div><p><strong>Estado: {valuesFields?.statusName}</strong></p></div>
                }
                <div></div>
                {document.isLoading ? (
                  <Skeleton />
                ): (
                  <span className="p-float-label w-full mt-2">
                      <p className='text-sm'>Fecha del Documento *</p>
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, value},
                          }) => (
                            <Calendar
                                  value={value}
                                  id="documentDate"
                                  onChange={onChange}
                                  disabled={actionSelected === 'readOnly' ? true : false || mode==='view' || mode==='aprovedOrReject'}
                                  className={`${errors?.documentDate ? 'p-invalid w-full' : 'w-full'} ${(actionSelected === 'readOnly' ? true : false || mode==='view' || mode==='aprovedOrReject')&&'disabled'}`}
                              />
                          )}
                          rules={{
                            required: 'La fecha del documento es requerida.',
                          }}
                          name="documentDate"
                      />
                      {/* <label htmlFor="documentDate">Fecha del Documento</label> */}
                  </span>
                )}
                {document.isLoading ? (
                  <Skeleton />
                ): (
                  <span className="p-float-label w-full mt-4">
                      <p>Última Actualización</p>
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value},
                          }) => (
                            <InputText
                                type="text"
                                id='updateDate'
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                readOnly
                                disabled
                                className="disabled"
                            />
                          )}
                          name="updateDate"
                      />
                      {/* <label htmlFor="updateDate">Última Actualización</label> */}
                  </span>
                )}
                {document.isLoading ? (
                  <Skeleton />
                ): (
                  <span className="p-float-label w-full mt-4">
                      <p>Elaborado por *</p>
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value},
                          }) => (
                            <span className="p-float-label">
                              <Dropdown
                                options={dataMaster?.users}
                                optionLabel="fullName" 
                                value={value}
                                onChange={onChange}
                                className={`${errors?.ownerId ? 'p-invalid w-full' : 'w-full'} ${(actionSelected === 'readOnly' ? true : false || mode==='view' || mode==='aprovedOrReject')&&'disabled'}`}
                                readOnly={control.readonly}
                                disabled={actionSelected === 'readOnly' ? true : false || mode==='view' || mode==='aprovedOrReject'}
                                optionValue={(e)=>{
                                return e.id
                                }}
                              />
                          </span>
                          )}
                          rules={{
                            required: 'La campo "Elaborado por" es requerido.',
                          }}
                          name="ownerId"
                      />
                      {/* <label htmlFor="updateDate">Última Actualización</label> */}
                  </span>
                )}
                {document.isLoading ? (
                  <Skeleton />
                ): (
                  <span className="p-float-label w-full mt-4">
                      <h3>Versión</h3>
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value},
                          }) => (
                            <span className="p-float-label">
                              <Dropdown
                                options={versions}
                                value={value}
                                onChange={(e)=> {
                                  dispatch(DocumentLibraryAction.getDocumentForVersion(data.document.id, e.value))
                                  onChange(e)}}
                                className={`${errors?.version ? 'p-invalid w-full' : 'w-full'} ${(actionSelected === 'readOnly' ? true : false || mode==='view' || mode==='aprovedOrReject')&&'disabled'}`}
                                disabled={!versions || actionSelected === 'readOnly' ? true : false || mode==='view' || mode==='aprovedOrReject'}
                              />
                          </span>
                          )}
                          name="version"
                      />
                  </span>
                )}
                <div className="flex justify-center">
                  {
                    (isVersion && valuesFields?.statusName === 'PUBLICADO' && data) &&
                      <Button
                        type="button"
                        label="Generar Versión"
                        // className={`sig-button mt-4 sig-primary ${(mode==='edit' && valuesFields?.statusCode===CoreConstants.Entity.WorkflowStatus.PUBLICADO)?'visible':'invisible'}`}
                        className={`sig-button mt-4 sig-primary`}
                        onClick={()=>{ setShowModal(true)}}
                      />
                  }
                </div>
              </div>
            </Panel>
            {/* <div className='col-span-6'>
              {document.isLoading ? (
                  <Skeleton height='8rem' />
                ): (
                  // <Attachment 
                  //   setValue={setValue} 
                  //   iconTypeOfFileTable={iconTypeOfFileTable} 
                  //   valuesFields={valuesFields} 
                  //   mode={mode} 
                  //   setErrorFile={setErrorFile}
                  // />
                  <Attachments
                    // module="customer"
                    // addEndpoint={"api/Business/AddAttachment"}
                    // deleteEndpoint={"api/Business/DeleteAttachment"}
                    // listEndpoint={"api/Business/GetAttachmentsByBusinessId"}
                    
                    setValue={setValue}
                    fieldFiles="savedFiles"
                    fieldNewFiles="addedFiles"
                    filesValues={valuesFields?.["savedFiles"]}
                    newFilesValues={valuesFields?.["addedFiles"]}
                    isLoading={document.isLoading}
                    validExtensions={["docx", "pdf", "xlsx"]}
                    // isMultiple={true}
                  />
                )
              }
            </div> */}
          </div>
        </Panel>
        <Attachments
          // module="customer"
          // addEndpoint={"api/Business/AddAttachment"}
          // deleteEndpoint={"api/Business/DeleteAttachment"}
          // listEndpoint={"api/Business/GetAttachmentsByBusinessId"}
          
          setValue={setValue}
          fieldFiles="savedFiles"
          fieldNewFiles="addedFiles"
          filesValues={valuesFields?.["savedFiles"]}
          newFilesValues={valuesFields?.["addedFiles"]}
          isLoading={document.isLoading}
          validExtensions={["docx", "pdf", "xlsx"]}
          isMultiple={false}
        />
      </div>
      {
        (existError || errorFile) && <div className='message error'>
            <ul>
                {/* {errors.code ? (
                    <>
                        <li className="p-error">{errors.code.message}</li>
                    </>
                ) : null} */}
                {errors.title ? (
                    <>
                        <li className="p-error">{errors.title.message}</li>
                    </>
                ) : null}
                {errors.file ? (
                    <>
                        <li className="p-error">{errors.file.message}</li>
                    </>
                ) : null}
                {errors.documentDate ? (
                    <>
                        <li className="p-error">{errors.documentDate.message}</li>
                    </>
                ) : null}
                {errors.ownerId ? (
                    <>
                        <li className="p-error">{errors.ownerId.message}</li>
                    </>
                ) : null}
                {errorFile ? (
                    <>
                        <li className="p-error">{errorFile}</li>
                    </>
                ) : null}
            </ul>

        </div>
        }
        <div className="flex justify-end gap-4 mt-3">
            <Button
                icon="pi pi-times"
                type="button"
                label="Cancelar"
                onClick={() => { setVisible(true); }}
                className="sig-button sig-secondary mt-4"
            />
            {
              (!mode || mode==='edit' || mode==='new')&&<ButtonMultipleSend
              isLoading={document?.isSaving || document?.isLoading}
              items={items}
              />
            }
            { mode==='aprovedOrReject'&&(<>
              <Button
              icon="pi pi-times"
              type="button"
              label="Rechazar"
              loading={isLoading}
              className="sig-button sig-error mt-4"
              onClick={()=>{setShowRejectModal(true)}}
              />
              <Button
              icon="pi pi-save"
              type="button"
              label="Aprobar"
              loading={isLoading}
              className="sig-button sig-success mt-4"
              onClick={()=>{setShowApprovedModal(true)}}
              />
              </>)
              }
        </div>
      </form>
      <ModalGenerateVersion visibleModalVersion={showModal} setVisibleModalVersion={setShowModal}  onSubmitValue={generarVersion} isLoading={isSaving}/>
      <ModalGenerateVersion visibleModalVersion={showApprovedModal} setVisibleModalVersion={setShowApprovedModal}  onSubmitValue={approvedDocument} isLoading={statusDocument.isLoading} header={"Aprobar"} title={"Ingrese el motivo de la aprobación *"}/>
      <ModalGenerateVersion visibleModalVersion={showRejectModal} setVisibleModalVersion={setShowRejectModal}  onSubmitValue={rejectDocument} isLoading={statusDocument.isLoading} header={"Rechazo"} title={"Ingrese el motivo del rechazo *"}/>
      <ModalSelectFlow 
        title={"Seleccione el flujo"}
        description={"Seleccione el flujo al que desea enviar el documento"}
        setDisplayModal={setDisplayFlowModal}
        displayModal={displayFlowModal}
        flows={dataMaster.workflowDocumentTypes}
        onSubmitApprove={onSubmitApprove}
        setValueField={setValue}
        field="flowId"
      />
      <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={redirectToSearch}
          reject={reject}
      />
    </Dialog>
  )
}

export default ModalDocumentLibrary