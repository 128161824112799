import React from "react";
import { Route, Switch } from "react-router-dom";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { PageCompanies } from "./pages/Companies/PageCompanies";
import { PageCompany } from "./pages/Companies/PageCompany";
import { PageStudents } from "./pages/Students/PageStudents";
import { PageStudent } from "./pages/Students/PageStudent";
import companiesReducer from "./store/reducers/CompaniesReducer";
import studentsReducer from "./store/reducers/StudentsReducer";
import { CustomersChildRouting } from "./CustomersChildRouting";

export const CustomersRouting = (reducerRegistry) => {
  const { path } = useRouteMatch();
  reducerRegistry.register({ companiesReducer });
  reducerRegistry.register({ studentsReducer });

  return (
    <Switch>
      <Route path={`${path}/empresas`}>
        <Route exact path={`${path}/empresas`} component={PageCompanies} />
        <Route
          path={`${path}/empresas/editar/:customerId`}
          component={() => CustomersChildRouting(reducerRegistry)}
        />
        <Route
          path={`${path}/empresas/nuevo`}
          component={() => <PageCompany title="Nueva" />}
        />
        <Route
          path={`${path}/empresas/visualizar/:customerId`}
          component={() => <PageCompany title="Ver" mode='view' />}
        />
      </Route>
      <Route path={`${path}/alumnos`}>
        <Route exact path={`${path}/alumnos`} component={PageStudents} />
        <Route
          path={`${path}/alumnos/editar/:id`}
          component={() => <PageStudent title="Editar Alumno" />}
        />
        <Route
          path={`${path}/alumnos/visualizar/:id`}
          component={() => <PageStudent title="Ver Alumno" mode='view' />}
        />
        <Route
          path={`${path}/alumnos/nuevo`}
          component={() => <PageStudent title="Nuevo Alumno" />}
        />
      </Route>
    </Switch>
  );
};
