import { CoreConstants } from "core/commons/CoreConstants";
import moment from "moment";

const getCreationDate = (rowData) => { return moment(rowData.creationDate).format(CoreConstants.Format.LOCALE); }

const columns = [
  { field: 'name', header: 'Nombre' },
  { field: 'customer', header: 'Empresa' },
  { field: 'status', header: 'Estado' }, 
  { field: 'responsible', header: 'Responsable' },
  { field: 'projectTeamMembers', header: 'Equipo' },
  { field: 'progress', header: 'Avance' },
  { field: 'estimatedOpenDate', header: 'Fecha Inicio', body: getCreationDate },
  { field: 'estimatedCloseDate', header: 'Fecha Fin', body: getCreationDate },
  { field: 'id', header: 'Descripción' },
];

export const ViewProjectTableData = {
  columns
};
