import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SelectButton } from "primereact/selectbutton";
import moment from "moment";
import { Fragment } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { CoreConstants } from "core/commons/CoreConstants";
import { Menu } from "primereact/menu";
import { Paginator } from "primereact/paginator";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ProjectRoleAction } from "features/maintenance/store/actions/ProjectRoleAction";
import { useClaimProjectRoles } from "features/maintenance/hooks/useClaimProjectRoles";
import { Link } from "react-router-dom";

const TableComponent = () => {
  const advancedFilter = useSelector(
    (state) => state.projectRoleReducer.advancedFilter
  );
  const [pageSizeCustom, setPageSizeCustom] = useState(10);

  const { isView ,isEdit, isDelete } = useClaimProjectRoles();

  const history = useHistory();
  const dispatch = useDispatch();
  const typeViews = [
    { icon: "pi pi-list", value: CoreConstants.TypeView.LIST },
    { icon: "pi pi-microsoft", value: CoreConstants.TypeView.CANVA },
  ];
  const { data } = useSelector(
    (state) => state.projectRoleReducer.projectRoles
  );
  const { isDeleted } = useSelector(
    (state) => state.projectRoleReducer.projectRole
  );

  const getCreationDate = (rowData) => {
    return moment(rowData.creationDate).format(CoreConstants.Format.LOCALE);
  };
  const columns = [
    { field: "name", header: "Nombre" },
    { field: "creationDate", header: "Fecha Creación", body: getCreationDate },
  ];

  const [selectedColumns, setSelectedColumns] = useState(columns);
  const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);
  const actionsHeader = useRef(null);
  const menu = useRef(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [showConfirmDialog, setshowConfirmDialog] = useState(false);
  const [ProjectRoleSelected, setProjectRoleSelected] = useState(null);
  const [isFirstLoading, setFirstLoading] = useState(true);

  useEffect(() => {
    if (isFirstLoading) {
      tryParseFilter();
      setFirstLoading(false);
      //setSortField("name");
      setSortOrder(1);
      dispatch(ProjectRoleAction.search());
    }
  }, []);

  useEffect(() => {
    if (isDeleted) {
      setshowConfirmDialog(false);
      dispatch(ProjectRoleAction.resetAfterSaved());
      dispatch(ProjectRoleAction.search());
    }
  }, [isDeleted]);

  let items = [
    {
      label: "Editar",
      icon: "pi pi-pencil",
      command: (e) => {
        history.push("/mantenimiento/rol-proyecto/" + ProjectRoleSelected.id);
      },
    },
    {
      label: "Eliminar",
      icon: "pi pi-times",
      command: (e) => {
        setshowConfirmDialog(true);
      },
    },
  ];

  if (!isEdit)
    items = items.filter(c => c.label !== 'Editar');
  if (!isDelete)
    items = items.filter(c => c.label !== 'Eliminar');

  const getViewField = (rowData, col) => {

        if (isView)
          return <Link className="underline text-blue-700" to={{ pathname: `/mantenimiento/rol-proyecto/visualizar/` + rowData.id }} >{rowData.name}</Link>
        else
          return rowData.name;
  }

  const columnComponents = selectedColumns.map((col) => {

    if (col.field === 'name')
          return <Column key={col.field} header={col.header} body={(rowData) => getViewField(rowData, col)} sortable />

    if (col.field === "creationDate")
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={getCreationDate}
          sortable
        />
      );

    if (col.body)
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={col.body}
          sortable
        />
      );

    return (
      <Column key={col.field} field={col.field} header={col.header} sortable />
    );
  });

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    advancedFilter.columnOrder = e.sortField;
    advancedFilter.order = e.sortField === 1 ? "asc" : "desc";
    advancedFilter.page = 1;
    dispatch(ProjectRoleAction.updateFilter(advancedFilter));
    dispatch(ProjectRoleAction.search());
  };

  const actionHeaderTemplate = (rowData) => {
    return (
      <i
        key="action"
        className="pi pi-ellipsis-v cursor-pointer"
        onClick={(e) => actionsHeader.current.toggle(e)}
      ></i>
    );
  };

  const actionBodyTemplate = (rowData) => {
    if(isEdit||isDelete){
      return (
        <i
          key={rowData.id}
          className="pi pi-ellipsis-v cursor-pointer"
          onClick={(event) => {
            setProjectRoleSelected(rowData);
            menu.current.toggle(event);
          }}
        ></i>)
    }else{
      return <div></div>
    }
  };

  const tryParseFilter = () => {
    try {
      const filters = JSON.parse(localStorage.getItem("project-role-filter"));
      if (filters) setSelectedColumns(filters);
      else setSelectedColumns(columns);
    } catch (error) {
      localStorage.removeItem("project-role-filter");
    }
  };

  const onColumnToggle = (e, item) => {
    let _selectedColumns = [...selectedColumns];

    if (e.checked) _selectedColumns.push(item);
    else
      _selectedColumns = _selectedColumns.filter((c) => {
        return c.field !== e.value;
      });

    setSelectedColumns(_selectedColumns);
    localStorage.setItem(
      "project-role-filter",
      JSON.stringify(_selectedColumns)
    );
  };

  const getColumns = () => {
    let array = [];
    columns.map((item, index) => {
      const column = (
        <div key={item.field} className="p-field-checkbox py-1">
          <Checkbox
            inputId={item.field}
            name="column"
            value={item.field}
            onChange={(e) => onColumnToggle(e, item)}
            checked={selectedColumns.some((x) => x.field === item.field)}
          />
          <label className="ml-1" htmlFor={item.field}>
            {item.header}
          </label>
        </div>
      );
      array.push(column);
    });
    return array;
  };

  const confirmDelete = () => {
    dispatch(ProjectRoleAction.deleteProjectRole(ProjectRoleSelected.id));
  };

  const cancelDelete = () => {
    setshowConfirmDialog(false);
  };
  const [rows, setRows] = useState(10);

  const onPageChange = (e) => {
    setRows(e.rows);
    advancedFilter.page = e.page + 1;
    advancedFilter.pageSize = e.rows;
    dispatch(ProjectRoleAction.updateFilter(advancedFilter));
    dispatch(ProjectRoleAction.search());
  };

  return (
    <Fragment>
      <div className="flex justify-end mb-2">
        <SelectButton
          unselectable={false}
          value={typeView}
          options={typeViews}
          onChange={(e) => setTypeView(e.value)}
          itemTemplate={(option) => {
            return <i className={option.icon}></i>;
          }}
        />
      </div>

      {typeView === CoreConstants.TypeView.CANVA && (
        <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2  gap-2">
          {data?.results.map((item, index) => {
            return (
              <div
                key={"card" + item.id}
                className="
                                block 
                                p-4 
                                max-w-sm 
                                bg-white 
                                rounded-lg 
                                border 
                                border-gray-200 
                                shadow-md 
                                hover:bg-gray-100 
                                dark:bg-gray-800 
                                dark:border-gray-700 
                                dark:hover:bg-gray-700"
              >
                <div className="flex justify-between">
                  {
                    isView?(
                      <Link className="underline text-blue-700" to={{ pathname: `/mantenimiento/rol-proyecto/visualizar/` + item.id }} ><h5>{item.name}</h5></Link>
                    ):(
                      <h5
                        key={"title" + item.id}
                        className="mb-1 text-md font-bold   text-gray-900 dark:text-white"
                      >
                        {item.name}
                      </h5>
                    )
                  }
                  {
                    (isEdit || isDelete)&&(
                      <i
                        key={"options" + item.id}
                        className="pi pi-ellipsis-v cursor-pointer"
                        onClick={(event) => {
                          setProjectRoleSelected(item);
                          menu.current.toggle(event);
                        }}
                      ></i>
                    )
                  }
                </div>
                <div className="flex justify-between">
                  <span className="text-xs float-right px-2 py-1 text-black bg-white rounded-full border border-gray-200">
                    {moment(item.creationDate).format(CoreConstants.Format.LOCALE)}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {typeView === CoreConstants.TypeView.LIST && (
        <div
          className="mt-3 "
          style={{ maxWidth: window.innerWidth - 100 + "px" }}
        >
          <DataTable
            value={data?.results}
            stripedRows
            responsiveLayout="scroll"
            size="small"
            sortField={sortField}
            sortOrder={sortOrder}
            emptyMessage="No se encontró este rol."
            onSort={(e) => onSort(e)}
          >
            {columnComponents}
            <Column
              header={actionHeaderTemplate}
              body={actionBodyTemplate}
            ></Column>
          </DataTable>
        </div>
      )}

      <Paginator
        key={"pag-1"}
        className="paginator-custom mt-4"
        rows={rows}
        first={(data?.currentPage - 1) * rows}
        rowsPerPageOptions={[10, 20, 30]}
        totalRecords={data?.rowCount}
        onPageChange={onPageChange}
      ></Paginator>

      <OverlayPanel
        ref={actionsHeader}
        showCloseIcon
        style={{ width: "300px" }}
      >
        {getColumns()}
      </OverlayPanel>

      <Menu model={items} popup ref={menu} id="popup_menu" />
      <ConfirmDialog
        visible={showConfirmDialog}
        onHide={cancelDelete}
        message="¿Está seguro que desea eliminar el registro?"
        header="Eliminar"
        icon="pi pi-trash"
        accept={confirmDelete}
        reject={cancelDelete}
      />
    </Fragment>
  );
};

export default TableComponent;
