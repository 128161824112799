import { ProjectsConstants } from 'features/projects/commons/ProjectsConstants';

const initialState = {
  paymentCourses: {
    data: [],
    isLoading: true,
    isSaved: false,
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'id',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {},
    status: null
  },
  studentSelected: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false
  },
  projectsAndPaymentMethod: {
    data: null,
    isLoading: false,
  },
  reports: {
    data: null,
    isLoading: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ProjectsConstants.Accion.PaymentProjects.SAVE_PAYMENT_PROJECT:
        return {
            ...state,
            studentSelected: {
                ...state.studentSelected.data,
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };
    case ProjectsConstants.Accion.PaymentProjects.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
    case ProjectsConstants.Accion.PaymentProjects.UPDATE_FILTER:
        return {
            ...state,
            advancedFilter: state.advancedFilter
    };
    case ProjectsConstants.Accion.PaymentProjects.DOWNLOAD_REPORT_STATUS:
        return {
            ...state,
            reports:{
              data: action.payload.data,
              isLoading: action.payload.isLoading
            },
    };
    case ProjectsConstants.Accion.PaymentProjects.SEARCH:
      return {
        ...state,
        paymentCourses: {
          data: action.payload.data,
          isLoading: action.payload.isLoading
        },
      };
      case ProjectsConstants.Accion.PaymentProjects.GET_COURSES_AND_PAYMENT:
        return {
            ...state,
            projectsAndPaymentMethod: {
              data: action.payload.data,
              isLoading: action.payload.isLoading
            },
        };
        case ProjectsConstants.Accion.PaymentProjects.RESET_AFTER_SAVED:
          return {
              ...state,
              studentSelected: {
                  data: null,
                  isSaving: false,
                  isSaved: false,
                  isLoading: false,
                  isDeleted: false
              },
          };

        case ProjectsConstants.Accion.PaymentProjects.GET_COURSE_PAYMENT_BY_ID:
            return {
                ...state,
                studentSelected: {
                    data: action.payload.data,
                    isSaving: false,
                    isLoading: action.payload.isLoading
                }
              };
          case ProjectsConstants.Accion.PaymentProjects.DELETE_PAYMENT:
            return {
                ...state,
                studentSelected: {
                    isDeleted: action.payload.isDeleted
                }
            };
    default:
      return state;
  }
};
