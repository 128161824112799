import { apiService } from "core/services/ApiService";

const search = (criteria) => {
  return apiService.Post("customer/api/CourseStudentPayment/Search", criteria);
}

const getListStudents = (courseId) => {
  return apiService.Get("customer/api/CourseStudent/GetStudentsByCourseId?id=" + courseId);
}

const savePaymentCourse = (criteria) => {
  return apiService.Post("customer/api/CourseStudentPayment/Save", criteria)
}

const getCoursesAndPayment = () => {
  return apiService.Get("customer/api/CourseStudentPayment/GetDataMaster");
}

const getPaymentById = (Id) => {
  return apiService.Get("customer/api/CourseStudentPayment/GetCourseStudentPaymentsByCourseStudentId?courseStudentPaymentId=" + Id);
}

const generateReport = (criteria) => {
  return apiService.Post("customer/api/CourseStudentPayment/GenerateReport", criteria, {
    responseType: 'blob',
  });
}

const deletedPayment = (id) => {
  return apiService.Delete(`customer/api/CourseStudentPayment/Delete?id=${id}`);
}

export const PaymentCourseService = {
  search,
  savePaymentCourse,
  getCoursesAndPayment,
  generateReport,
  deletedPayment,
  getPaymentById,
  getListStudents
};