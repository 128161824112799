import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';

export const ModalSelectFlow = ({displayModal, setDisplayModal, title, description = '', flows, onSubmitApprove, setValueField, field}) => {

  let defaultValues = {
    flowId: null
  };

  const { setValue, handleSubmit, control, watch, formState: { errors } } = useForm({ defaultValues });

  const existError = Object.keys(errors).length > 0

  const onHide = () => {
    setDisplayModal(false)
    // setDocumentSelected(null)
    setValue('flowId', null)
    setValueField('flowId', null)
  }

  const onSubmit = (data) => {
    setValueField(field, data.flowId)
    onSubmitApprove()
    onHide()
  };

  return (
    <Dialog header={title} visible={displayModal} className='w-4/5 sm:w-3/4 md:w-1/2 lg:w-1/3' onHide={onHide}>
      <form 
        className="form-custom p-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <hr />
        <p className='mt-1 md:mt-2 text-sm'>{description}</p>
        <div className="grid grid-cols-1 gap-2 mt-6">
          <span className="p-float-label w-full">
            <Controller
                control={control}
                render={({
                    field: { onChange, onBlur, value},
                }) => (
                  <Dropdown
                      options={flows}
                      optionLabel="name"
                      optionValue='id'
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      className={errors?.name ? 'p-invalid w-full' : 'w-full'}
                  />
                )}
                name="flowId"
                rules={{
                  required: 'El flujo es requerido',
              }}
            />
            <label htmlFor="flowId">Flujo *</label>
          </span>
        </div>
        {
          (existError) && <div className='message error'>
              <ul>
                  {errors.flowId ? (
                      <>
                          <li className="p-error">{errors.flowId.message}</li>
                      </>
                  ) : null}
              </ul>
          </div>
        }
        <div className="flex justify-end gap-4 mt-3">
            <Button
                icon="pi pi-times"
                type="button"
                label="Cancelar"
                onClick={onHide}
                className="sig-button sig-secondary mt-4"
            />
            <Button
                icon="pi pi-save"
                type="submit"
                disabled={existError ? true : false}
                label="Guardar"
                className="sig-button sig-primary mt-4"
            />
        </div>
      </form>

      {/* <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={redirectToSearch}
          reject={reject}
      /> */}
    </Dialog>
  )
}
