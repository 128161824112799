import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const ModalSelect = ({type='mapa', visibleModalVersion, setVisibleModalVersion}) => {

	const history = useHistory()

	return (
		<Dialog 
			visible={visibleModalVersion} 
			onHide={()=>{setVisibleModalVersion(false)}}
			header={<div className='text-xl text-blue-800'>Seleccione la matriz</div>}
			style={{width: '350px'}}
		>
			<hr/>
			<div className='flex flex-col gap-4 px-8 pt-4'>
				<Button 
					label='Calidad'
					onClick={()=>history.push('/gestion-riesgos/matriz/calidad/nuevo')}
					className='sig-button sig-primary'
				/>
				<Button 
					label='Ambiental'
					onClick={()=>history.push('/gestion-riesgos/matriz/ambiental/nuevo')}
					className='sig-button sig-primary'
				/>
				<Button 
					label='Seguridad'
					onClick={()=>history.push('/gestion-riesgos/matriz/seguridad/nuevo')}
					className='sig-button sig-primary'
				/>
			</div>
		</Dialog>
	);
};

export default ModalSelect;