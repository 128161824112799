import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

export const useClaimOrganizationChart = () => {

  const menu = useSelector((state) => state.authReducer.user.menuPrincipal);
  const pathName = useLocation()?.pathname;
  const [isDownload, setDownload] = useState(false);
  const [isCreate, setCreate] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isDelete, setDelete] = useState(false);

  useEffect(() => {
    download();
    create();
    deleted();
    edit();
  }, [menu]);

  const download = () => {
    const actionCode = 'HUMAN-RESOURCE_ORGANIZATION-CHART_DOWNLOAD';
    const optionCode = "ORGR";
    setDownload(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
 };

  const create = () => {
    const actionCode = 'HUMAN-RESOURCE_ORGANIZATION-CHART_CREATE';
    const optionCode = "ORGR";
    setCreate(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const deleted = () => {
    const actionCode = 'HUMAN-RESOURCE_ORGANIZATION-CHART_DELETE';
    const optionCode = "ORGR";
    setDelete(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const edit = () => {
    const actionCode = 'HUMAN-RESOURCE_ORGANIZATION-CHART_EDIT';
    const optionCode = "ORGR";
    setEdit(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  return {
    isDownload,
    isCreate,
    isEdit,
    isDelete
  }
}