import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import useTypeMoney from 'features/configuration/hooks/sistem/useTypeMoney';
import { useClaimSystemConfiguration } from 'features/configuration/hooks/useClaimSystemConfiguration';

const TypeMoneyComponent = () => {
  const {
    currentValue,
    isLoading,
    setCurrentValue,
    saveTypeMoney,
    isLoadingSave,
  } = useTypeMoney();

  const { isCreate, isEdit, isDeleted } = useClaimSystemConfiguration();

  return (
    <div className="content-tab-sistem">
      <h2 className="mt-lg-0 mt-3">Tipo de Moneda</h2>
      <p className="description mb-3 mt-5 font-semibold">
        Aquí puedes seleccionar la moneda qué por defecto utilizará la
        aplicación.
      </p>
      <div>
        <div className="p-field-radiobutton">
          {isLoading ? (
            <Skeleton width="35%" height="2rem" />
          ) : (
            <>
              <RadioButton
                inputId="soles"
                name="type-money"
                value="PEN"
                checked={currentValue === 'PEN'}
                onChange={({ value }) => setCurrentValue(value)}
              />
              <label htmlFor="soles" className="pl-2">
                S/. - Nuevos Soles
              </label>
            </>
          )}
        </div>
        <div className="p-field-radiobutton mt-3">
          {isLoading ? (
            <Skeleton width="35%" height="2rem" />
          ) : (
            <>
              <RadioButton
                inputId="dollar"
                name="type-money"
                value="USD"
                checked={currentValue === 'USD'}
                onChange={({ value }) => setCurrentValue(value)}
              />
              <label htmlFor="dollar" className="pl-2">
                $ - Dolares
              </label>
            </>
          )}
        </div>
      </div>
      <div className="flex gap-3 items-center justify-start mt-6">
        <Button
          type="button"
          label="Cancelar"
          className="sig-button  sig-secondary"
          disabled={isLoadingSave}
        />
        {
          (isCreate && isEdit && isDeleted)&&(<Button
            type="button"
            label="Guardar"
            onClick={saveTypeMoney}
            loading={isLoadingSave}
            className="sig-button sig-primary"
          />)
        }
      </div>
    </div>
  );
};

export default TypeMoneyComponent;
