

import React, {Fragment, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import { SelectButton } from 'primereact/selectbutton';
import { Skeleton } from 'primereact/skeleton';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import { SurveyManagementAction } from 'features/survey/store/actions/SurveyManagementAction';
import { CoreConstants } from 'core/commons/CoreConstants';
import { useClaimSurveyManagment } from 'features/survey/hooks/useClaimSurveyManagment';

// const temporalData = [
//     {
//         id: '1',
//         name: 'Carpeta 1_1_1',
//         owner: 'Anibal Torres',
//         surveys: '10',
//         latestChangeDate: "2022-07-24T13:56:17+00:00",
//     }
// ]

const TableComponent = ({selectedNode, setSelectedNode, surveySelected, setSurveySelected, setDisplayModalAction, setActionSelected, setDisplayModalPermissions}) => {
    const dispatch = useDispatch();
    const { isDeleted, isUpdated, isSaved } = useSelector((state) => state.surveyManagementReducer.survey);
    const { isLoading , data } = useSelector(state => state.surveyManagementReducer.listFoldersTree)
  const { data: dataListFolders } = useSelector(state => state.surveyManagementReducer.listFolders)

    // const { isLoading , data } = useSelector(state => state.surveyManagementReducer.surveys)

    const getCreationDate = (rowData) => rowData.creationDate ? moment(rowData.creationDate).format(CoreConstants.Format.LOCALE) : ''
    const getUpdateDate = (rowData) => rowData.modificationDate ? moment(rowData.modificationDate).format(CoreConstants.Format.LOCALE) : ''

    const columns = [
        { field: 'name', header: 'Nombre' },
        { field: 'creationUser', header: 'Propietario'},
        { field: 'surveys', header: 'Encuestas'},
        { field: 'creationDate', header: 'Fecha de Creación', body: getCreationDate },
        { field: 'modificationDate', header: 'Ultima Modificación', body: getUpdateDate }
    ];

    // console.log(surveySelected)
    // console.log(selectedNode)

    const [selectedColumns, setSelectedColumns] = useState(columns);
    const actionsHeader = useRef(null);
    const menu = useRef(null);
    const dragItem = useRef();
    const dragOverItem = useRef();
    const [showConfirmDialog, setshowConfirmDialog] = useState(false);
    const [showConfirmRestore, setShowConfirmRestore] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isFirstLoading, setFirstLoading] = useState(true); 

    const {isConfigurate, isDelete, isRename} = useClaimSurveyManagment();

    useEffect(() => {
        if (isFirstLoading) {
            tryParseFilter();
            setFirstLoading(false);
        }
             
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedNode) {
            dispatch(SurveyManagementAction.getListFoldersInCurrentPath(selectedNode.id));
        }
    }, [selectedNode])

    useEffect(() => {
        if (isDeleted) {
            setshowConfirmDialog(false);
            // dispatch(SurveyManagementAction.getListFoldersInCurrentPath(selectedNode.id));
            dispatch(SurveyManagementAction.getListFolders());
            dispatch(SurveyManagementAction.resetAfterSaved());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeleted])

    useEffect(() => {
        if (isUpdated) {
            // dispatch(SurveyManagementAction.getListFoldersInCurrentPath(selectedNode.id));
            dispatch(SurveyManagementAction.getListFolders());
            dispatch(SurveyManagementAction.resetAfterSaved());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated])

    useEffect(() => {
        if (isSaved) {
            // dispatch(SurveyManagementAction.getListFoldersInCurrentPath(selectedNode.id));
            dispatch(SurveyManagementAction.getListFolders());
            dispatch(SurveyManagementAction.resetAfterSaved());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSaved])

    useEffect(() => {
        if (selectedRow) {
            setSelectedRow(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRow])

    const iconTypeOfFileCanva = (file) => {
        const fileSplit = file.name.split('.')
        if (fileSplit.includes('docx')) {
            return <div className="icon-word h-full"></div>
        } else if(fileSplit.includes('xlsx')) {
            return <div className="icon-excel h-full"></div>
        } else {
            return <div className="icon-file h-28"></div>
        }
    }

    const iconTypeOfFileTable = (file) => {
        const fileSplit = file.name.split('.')
        if (fileSplit.includes('docx')) {
            return <div className="icon-word-table"></div>
        } else if(file.itemType === 'F') {
            return <div className="icon-carpeta-table"></div>
        } else if(fileSplit.includes('xlsx')) {
            return <div className="icon-excel-table"></div>
        } else if(file.mimeType === 'application/pdf') {
            return <img alt='icon-pdf' className='icon-image' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAACQ0lEQVRoge2Yz0tUQRzAP293rTRb09rMwl1UkFqQ9tAhKPDQrT8gTx5FCtFLvy4SnbJDoKQSdIjoEBoFEZ66RF7qaAgF4rLs0masWSIsbaTTYZR3eNX63sw2uzAfWN7Mm+/MfD/Mm/fePrBYlHDKBYi+TlGRmQV3nNn0DdVhQjpy8YtoaQOH6+Ji55jqWEYENkemEIeOaZEwIkBzK5vDk1okzAiANglzAqBFwqwAKEuYFwAlieoQgMAS1SMAgSSqSwA8EuXCI/8jJ8+kw2e1jVV9K+ATK2AaK2AaK2CamhcI9iA73g0j9936zxJk3sPzcVj7DB09cGnCbf+ah9dP4N2crPf0Qv9N77jjA5Bf9pVKsBUIh6EhCqEIZD9C8TukzsPQFET2QGi73QnBpyWItUP/Lejt2+4fke1bv2T/nV/ph+9U1F4lVnMweRkcB648gkQSuk9DqSjbV9IwMQixOIw+hQsD8GbW7b+8AA+uKqWgZw8IAV8yshw97G0vZKGQg/0Hofmolil3UFuBun0QPwkNByB5Rp7LL0HdXm9scUMem2Luua5TMDQty4UczNz2nYKaQGsCrj126/PPIPsBulLe2D99HquPQvyELIfDgVJQE9hYg7cv5TGzCOmFv8fWN8rj+qq7CovzyntATWC9AC/ulY9raYMj7fIy+rYiLztNVPYPTSwBg3ehIyVvna8egtjSOkVlBRqbIHlO3m7npuUe0Yy5r9O7xJlJ/zPHmn8XsgKmsQKmsQKmsQKmsQIWS43zG41eqcJmZbBOAAAAAElFTkSuQmCC"/>
        } else if(file.mimeType === 'image/jpeg'|| file.mimeType === 'image/jpg' || file.mimeType === 'image/png' ) {
            return <img alt='icon-img' className='icon-image' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAADI0lEQVRoge2ZyW7TUBRAT9IhcdKgoqqTmjKpn4DER7CDBX/AT8CSNWv4A9ix4xtYtBJCqBIFVEpoaZqENB4S2/G7LNKiUPxc22ncIuVI2dzr4R7fN0mBCRMmTLhMcrrEgwO5L8JLgbUsCwqhRo7Hb1Zzb8OSed1dSnhxBYoHqCK81CW1AkB1DMWkZV2XiBL4L/jvBaazfuHdGYdHRpvqlEctmOVVd55Nv5T6eZl24O6Mw5PKIRvTLsWcsDHt8rRyyL1ZJ/UzMxV4ZLRD4w+L4fE4ZCqwNuWHxquaeBwyFfgRzITGa5p4HDIVeNWdD42/1sTjkKnApl/imbnMTr9AT/Ls9As8M5dHWoVGXkaPj3p0Gi7kYX6pSOV6IfL6Tb80UsFnSS0gAgdfO7TrvT8xs+WyWC2zuF6+kOLikEpARKh96tBp9P7J1fcsVCAs35obubg4JBYQEb5vH9Npudprjmo2AqxkIJFIQAJh9+MvrLZ37rVHexaIsHK78lfc+uVitTwEuLZQoDw/m6jgs8QWCPqK3Q8t7OP4m079m4UIrN6pgEBz36bTdBE16KTd9ri+YrCwNuZVqO8pvrxv0bWS75iHJxKzhTx2SOea+w4qEBZvpJv45wr4vYDPW0163X6qFwAc7poYczMY5fDXtQ4cRGDpZnKJSAGvG7Cz1cB10hd/itPxECUUdRL7DiAs3Uw28bUCPavPp80GvhskemAUjumjFNpONH8MOpFkCdYKbL+r0/dV8irPwTE9EH0nmjUbEVi5HU9CK3CRX/4sdsdDBYIxF34KbXy3B0vwnUpofhitgEj6AuPgWD4CFEvhJRzt2YiC1Y1oCb2AGrMBQxM7QgKiJSI6MH4BGMwJpRRGKXxHrn+zyEUc+rUC6uLnrxbH7CMKjHL4nPj5xdTee6lDaBjH9E8kkp0vL30IDeNYHqIUxXL8A15EBy6kpsQ41mA4FTXD6SwRcyD7DpxiW6c79vkSV2oIDePYHoJglKIlrtwQGmZwdhJKERJRq1CNK/AfQdf0QXGsy2u3iECpxyJSExEu85fL5wIVyPPxfJ4JEyZMGJXfXUfSuVI0IAgAAAAASUVORK5CYII="/>
        } else {
            return <div className="icon-file-table"></div>
        }
    }

    const titleDocuments = (pathTitle, type) => {
        if (!pathTitle) return
        // Para las carpetas
        const pathSplit = pathTitle.split('/')
        if (type === 'F') {
            const folderName = pathSplit[pathSplit.length-2]
            return folderName
        }
        // Para los archivos
        return pathTitle
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='flex items-center gap-x-4'>
                    {/* { iconTypeOfFileTable(rowData) } */}
                    <div className="icon-carpeta-table"></div>
                    {/* <span className="flex-1 truncate w-full">{titleDocuments(rowData.title, rowData.itemType)}</span> */}
                    <span className="flex-1 truncate w-full">{rowData.name}</span>
                </div>
            </React.Fragment>
        );
    }

    let items = [
        {
            label: 'Configurar Permisos',
            icon: 'pi pi-pencil',
            command: (e) => {
                // console.log(surveySelected.id)
                dispatch(SurveyManagementAction.getPermissionByLocationId(surveySelected.id));
                setDisplayModalPermissions(true)
            }
        },
        {
            label: 'Eliminar',
            icon: 'pi pi-times',
            command: (e) => {
                setshowConfirmDialog(true);
            }
        },
        {
            label: 'Renombrar',
            icon: 'pi pi-clone',
            command: (e) => {
                setActionSelected('rename')
                setDisplayModalAction(true)
            }
        },
    ];

    if (!isConfigurate)
        items = items.filter(c => c.label !== 'Configurar Permisos');
    if (!isDelete)
        items = items.filter(c => c.label !== 'Eliminar');
    if (!isRename)
        items = items.filter(c => c.label !== 'Renombrar');


    const columnComponents = selectedColumns.map(col => {
        if (col.field === 'name')
            return <Column key={col.field} field={col.field} header={col.header} style={{width: '40%'}} body={nameBodyTemplate} sortable />;

        if (col.body)
            return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable />;

        return <Column key={col.field} field={col.field} header={col.header} sortable />;
    });

    const actionHeaderTemplate = (rowData) => {
        return <i
            key="action"
            className="pi pi-ellipsis-v cursor-pointer"
            onClick={(e) => actionsHeader.current.toggle(e)}></i>;
    }

    // Si queremos crearle un menu diferente para las carpetas crear un menu personalizado para 'F'
    const actionBodyTemplate = (rowData) => {
        if(isConfigurate || isDelete || isRename){
            return (
                <i
                    key={rowData.id}
                    className="pi pi-ellipsis-v cursor-pointer"
                    onClick={(event) => {
                        setSurveySelected(rowData)
                        menu.current.toggle(event)
                        }}
                ></i>
            )
        }else{
            return <div></div>
        }
    }

    const tryParseFilter = () => {
        try {
            const filters = JSON.parse(localStorage.getItem("document-filter"));
            if (filters)
                setSelectedColumns(filters);
            else
                setSelectedColumns(columns);
        } catch (error) {
            localStorage.removeItem('document-filter');
        }
    }

    const onColumnToggle = (e, item) => {
        let _selectedColumns = [...selectedColumns];

        if (e.checked)
            _selectedColumns.push(item);
        else
            _selectedColumns = _selectedColumns.filter(c => { return c.field !== e.value });

        setSelectedColumns(_selectedColumns);
        localStorage.setItem("document-filter", JSON.stringify(_selectedColumns));
        ;
    }

    const getColumns = () => {
        let array = [];
        columns.map((item) => {
            const column = <div key={item.field} className="p-field-checkbox py-1">
                <Checkbox
                    inputId={item.field}
                    name="column"
                    value={item.field}
                    onChange={(e) => onColumnToggle(e, item)}
                    checked={selectedColumns.some((x) => x.field === item.field)}
                />
                <label className="ml-1" htmlFor={item.field}>{item.header}</label>
            </div>
            return array.push(column);
        });
        
        return array.slice(1,3);
    };

    const onRowSelect = (data) => {
        if (data.field === 'name' || data.field === 'creationUser' || data.field === 'surveys' || data.field === 'creationDate' || data.field === 'modificationDate') {
            setSelectedNode(data.rowData)
        }
    }

    // const onCanvaSelect = (data) => {
    //     setSelectedNode(data)
    // }

    const confirmDelete = () => {
        dispatch(SurveyManagementAction.deleteFolder(surveySelected.id));
    }

    const cancelDelete = () => {
        setshowConfirmDialog(false);
    }

    const cancelRestore = () => {
        setShowConfirmRestore(false);
    }
    
    const dragStart = (e, position) => {
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
        // console.log(dragOverItem.current);
      };

    const drop = (e) => {
        if (dragItem.current.itemType === 'F') return
        if (dragOverItem.current) {
            const path = dragOverItem.current.name.split('/')
            path.pop()
            const targetPath = path.join('/')
            const fileMoved = {
                id: dragItem.current.id,
                sourceFileName: dragItem.current.name,
                targetPath
            }
            // dispatch(SurveyAction.moveDocument(fileMoved));
            dragItem.current = null;
            dragOverItem.current = null;
        }
    };

    const tituloDirectorio = (path) => {
        const pathSplit = path.split('/')
        const pathsList = pathSplit.map( (item, index) => {
            if (index > 0) {
                const pathSlice = pathSplit.slice(0, index + 1)
                const pathJoin = pathSlice.join('/')
                const newPath = {
                    label: `${item}`,
                    command: () => setSelectedNode(pathJoin)
                }
                return newPath
            }

            const newPath = {
                label: item,
                command: () => setSelectedNode(item)
            }
            return newPath
        })
        return pathsList
    }

    const home = { icon: 'pi pi-home', command: () => setSelectedNode(dataListFolders && dataListFolders[0]) }

    return (
        <Fragment>
            <div className="flex items-center justify-between px-2 mb-2">
                <BreadCrumb 
                    // model={tituloDirectorio(selectedNode)} 
                    className='bread-crumb text-lg' 
                    home={home} 
                />
            </div>
            
            <div className="mt-3" style={{ maxWidth: (window.innerWidth - 100) + 'px' }}>          
                {
                    isLoading ?(
                        <Skeleton height="14.2rem" />
                        ) : (
                        <DataTable
                            className='tableSurvey'
                            // value={data}
                            value={data}
                            responsiveLayout="scroll"
                            size="small"
                            emptyMessage="No se encontraron encuestas"
                            selectionMode="single"
                            cellSelection
                            selection={selectedRow} onSelectionChange={e => setSelectedRow(e.value)}
                            onCellSelect={onRowSelect}
                            dataKey="id"
                            // sortField={sortField} sortOrder={sortOrder} onSort={(e) => onSort(e)}
                            >
                            {columnComponents}
                            <Column header={actionHeaderTemplate} body={actionBodyTemplate} ></Column>
                        </DataTable>
                        )
                }          
            </div>

            <OverlayPanel ref={actionsHeader} showCloseIcon style={{ width: '300px' }}>{getColumns()}</OverlayPanel>

            <Menu model={items} popup ref={menu} id="popup_menu" />
            <ConfirmDialog
                visible={showConfirmDialog}
                onHide={cancelDelete}
                message="¿Está seguro que desea eliminar el documento?"
                header="Eliminar"
                icon="pi pi-trash"
                accept={confirmDelete}
                reject={cancelDelete}
            />
        </Fragment >
    );
};

export default TableComponent;
