import { EmployeesAction } from 'features/rrhh/store/actions/EmployeesAction';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import  { useForm } from 'shared/hooks/useForm';
import { useHistory } from 'react-router';
import moment from 'moment';
const useUser = (title) => {
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const { loadingDataManager , dataManager } = useSelector((state) => state.employeeReducer.dataManager);
  const { data , pagination } = useSelector((state) => state.employeeReducer.employees) 
  const [studies,setStudies] = useState([]);
  const [experiencies,setExperiencies] = useState([]);
  const history = useHistory();
  const {loadingEdit , cargoSelect , isSave , dataEmployee} = useSelector((state) => state.employeeReducer.employeeEdit)
  const [isActive, setIsActive] = useState(true);
  const [srcAvatar, setSrcAvatar] = useState(null);
  const [file, setFile] = useState(null);
  const [visible, setVisible] = useState(false);
  const [disabledButtonState, setDisabledButtonState] = useState(false);
  const [errors,setErrors] = useState({})
  const [form,handleInputChange,reset] = useForm()
  const [payload, setPayload] = useState({
    id: null,
    firstName: null,
    fatherLastName: null,
    motherLastName: null,
    companyEmail: null,
    companyPhone: null,
    positionId: null,
    employeeTypeId: null,
    situationId: null,
    countryOfBirthId: null,
    dateOfBirth: null,
    genderId: null,
    documentTypeId: null,
    documentNo: null,
    maritalStatusId: null,
    childrenQuantity: null,
    countryOfResidenceId: null,
    departmentId: null,
    cityId: null,
    address: null,
    personalPhoneNo: null,
    mobilePhoneNo: null,
    personalEmail: null,
    emergencyPhoneNo: null,
    emergencyContactName: null,
    pictureData: null,
    branchId: null,
    contractStartDate: null,
    contractEndtDate: null,
    weeklyLaborHours: null,
    workPermitNumber: null,
    rowStatus: true,
    academicProfileList: [],
    workExperienceList: [],

    savedFiles: [],
    addedFiles: []
  })





  const accept = () => {
    history.push('/rrhh/personas');
  };

  const reject = () => {
    setVisible(false);
  };
  const onSelectedImage = ({ target }) => {
    const file = target.files[0];
    if (!file) {
      setSrcAvatar(dataEmployee.picture);
      return;
    }
    setFile(file);
    const fr = new FileReader();
    fr.onloadend = () => setSrcAvatar(fr.result);
    fr.readAsDataURL(file);
  };

  const convertData = (infoData, resultSearch, data) => {
    let keys = Object.keys(infoData)
        let sendData = {}
        let details = []

      keys.map(el=> {
          if(Number(el) > 0){
            if(Array.isArray(data[el])){
              data[el].map(item=> {
                details.push({
                  id: null,
                  processFormControlId: Number(el),
                  processFormSectionId: resultSearch?.section?.id,
                  valueReferenceId: Number(item),
                  valueText: (((resultSearch?.section?.controls?.find(itm => itm.id===Number(el)))?.details)?.find(ite => ite.id===item))?.text
                })
              })
            }else{
              details.push({
                id: null,
                processFormControlId: Number(el),
                processFormSectionId: resultSearch?.processId,
                valueReferenceId: null,
                valueText: data[el]
              })
            }
          }else{
            sendData[el] = infoData[el]
          }
        return el
      })

      sendData.details = details
      sendData.processFormId = resultSearch?.processId
      // sendData.processId = resultSearch?.section?.id
      dispatch(EmployeesAction.saveEmployee(sendData))
  }



  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const createOrEditEmployee = (data,titleView, site, resultSearch) =>{
    if(titleView === "Editar Empleado"){
      setDates()
    }else{
      setDates(data)
    }
    if(file){
      getBase64(file).then(
        data2 => {
          let infoPicture = data2.split("base64,")[1]
          let infoData = {...payload,...data,workExperienceList:experiencies,academicProfileList:studies,pictureData:infoPicture}
          convertData(infoData, resultSearch, data)
        }
      );
    }else{
      let infoData = {...payload,...data,workExperienceList:experiencies,academicProfileList:studies}
      convertData(infoData, resultSearch, data)
    }
  }

  const dataDepartmentGerente = (id) =>{
    dispatch(EmployeesAction.getDataDepartmentGerente(id));
  }

  const clearSave = () =>{
    dispatch(EmployeesAction.setEmployeeEdit({isSave:false}))
  }

  const generateReport = () => {
    dispatch(EmployeesAction.generateReport())
  }

  const deleteEmployeeId = (id) => {
    dispatch(EmployeesAction.deleteEmployeId(id));
  }

  const setDates = (data = payload ) =>{
    Object.entries(data).forEach(([key,value]) =>{
      if(typeof value === "object" && value !== null){
        if(!Array.isArray(value)){
          let newPayload = payload;
          newPayload[key] = moment.parseZone(value).utc().format();
          setPayload({...newPayload});
        }
      }
    })
  }

  
  return {
    setDates,
    generateReport,
    deleteEmployeeId,
    createOrEditEmployee,
    dataDepartmentGerente,
    onSelectedImage,
    inputFile,
    srcAvatar,
    isActive,
    setIsActive,
    setSrcAvatar,
    errors,
    setErrors,
    visible,
    setVisible,
    accept,
    reject,
    disabledButtonState,
    loadingDataManager,
    dataManager,
    handleInputChange,
    form,
    reset,
    payload,
    setPayload,
    data,
    studies,
    experiencies,
    setStudies,
    loadingEdit,
    cargoSelect,
    setExperiencies,
    setFile,
    dataEmployee,
    isSave,
    clearSave,
    pagination
  };
};

export default useUser;
