import { apiService } from 'core/services/ApiService';

const searchDepartments = (criteria) => {
  return apiService.Post('humanresource/api/CompanyArea/Search', criteria);
};

const saveDepartment = (data) => {
  return apiService.Post('humanresource/api/CompanyArea/Save', data);
};

const getDepartmentById = (departmentId) => {
  return apiService.Get('humanresource/api/CompanyArea/GetCompanyAreaById?id=' + departmentId);
};

const deleteDepartment = (departmentId) => {
  return apiService.Delete('humanresource/api/CompanyArea/DeleteCompanyArea?id=' + departmentId);
};

const getDataMaster = (model) => {
  return apiService.Post('humanresource/api/CompanyArea/GetDataMaster', model);
};

const generateReport = (payload) => {
  return apiService.Post('humanresource/api/CompanyArea/GenerateReport', payload, {
    responseType: 'blob',
  });
};

export const DepartmentService = {
  searchDepartments,
  saveDepartment,
  getDepartmentById,
  deleteDepartment,
  getDataMaster,
  generateReport
};