import { useSelector, useDispatch } from 'react-redux';
import ModalFilterComponent from 'shared/components/modal-filter/ModalFilterComponent';
import { Calendar } from 'primereact/calendar';
import { useEffect, useState } from 'react';
import { PaymentProjectAction } from 'features/projects/store/actions/PaymentProjectAction';
import { InputText } from 'primereact/inputtext';

const AdvancedFilterComponent = () => {
    const dispatch = useDispatch();
    const [formValues, setValues] = useState({
        from: null,
        to: null,
        projectName: null,
        customerName: null
    });

    const advancedFilter = useSelector((state) => state.paymentProjectReducer.advancedFilter);
    const { showModal } = useSelector((state) => state.paymentProjectReducer.advancedFilter);

    useEffect(() => {
        setValues({
            from: advancedFilter.filter.from,
            to: advancedFilter.filter.to,
            customerName: advancedFilter.filter.customerName,
            projectName: advancedFilter.filter.projectName
        });
    }, [advancedFilter]);


    const clearFields = () => {
        setValues({
            from: null,
            to: null,
            customerName: null,
            projectName: null
        });
    };
    const closeModal = () => {
        dispatch(PaymentProjectAction.toggleAdvancedFilter({ showModal: false }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const fields = formValues;

        if (fields?.from)
            advancedFilter.filter.from = fields.from;
        else
            advancedFilter.filter.from = null;

        if (fields?.to)
            advancedFilter.filter.to = fields.to;
        else
            advancedFilter.filter.to = null;

        if (fields?.customerName)
            advancedFilter.filter.customerName = fields.customerName;
        else
            advancedFilter.filter.customerName = null;

        if (fields?.projectName)
            advancedFilter.filter.projectName = fields.projectName;
        else
            advancedFilter.filter.projectName = null;

        dispatch(PaymentProjectAction.updateFilter(advancedFilter));
        dispatch(PaymentProjectAction.search());
        dispatch(PaymentProjectAction.toggleAdvancedFilter({ showModal: false }));
    }

    const onChangeFrom = (e) => {
        if (e.value)
            formValues.to = null;
        setValues({
            ...formValues,
            [e.target.name]: e.value,
        });
    }

    const onChangeTo = (e) => {
        setValues({
            ...formValues,
            [e.target.name]: e.value,
        });
    }

    const onChangeText = (e) => {
        setValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    }

    const onBlurText = (e) => {
        if(e.target.defaultValue.trim()!=="" || e.target.defaultValue!==null){
            setValues({
                ...formValues,
                [e.target.name]: e.target.defaultValue.trim(),
            });
        }else{
            setValues({
                ...formValues,
                [e.target.name]: null
            });
        }
    }

    if (showModal)
        return (
            <ModalFilterComponent
                disabledBtn={false}
                closeModal={closeModal}
                clearFields={clearFields}
                handleSubmit={handleSubmit}
                searchLoading={advancedFilter?.loading}
            >

                <div className="flex gap-4 pt-3">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                value={formValues.from}
                                name="from"
                                id="desde"
                                onChange={(e) => onChangeFrom(e)}
                            />
                            <label htmlFor="desde">Desde</label>
                        </span>
                    </div>
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                value={formValues.to}
                                name="to"
                                id="hasta"
                                minDate={formValues?.from}
                                onChange={(e) => onChangeTo(e)}
                            />
                            <label htmlFor="hasta">Hasta</label>
                        </span>
                    </div>
                </div>
                <div className='mt-2'>
                <div className="w-full">
                        {/* <span className="p-float-label mt-6">
                            <InputText
                                value={formValues.projectName}
                                name="projectName"
                                id="curso"
                                onChange={(e) => onChangeText(e)}
                                onBlur={onBlurText}
                             />
                            <label htmlFor="projectName">Nombre del Proyecto</label>
                        </span> */}
                        <span className="p-float-label mt-6">
                            <InputText
                                value={formValues.customerName}
                                name="customerName"
                                id="estudiante"
                                onChange={(e) => onChangeText(e)}
                                onBlur={onBlurText}
                             />
                            <label htmlFor="estudiante">Nombre del Cliente</label>
                        </span>
                    </div>
                </div>

            </ModalFilterComponent>
        );
    else
        return null;
}

export default AdvancedFilterComponent;
