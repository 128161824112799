import { CoursesManagmentConstants } from "features/coursesManagment/commons/CoursesManagmentConstants";

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  dataMaster: {
    isLoading: false,
    category: null,
    mode: null,
    service: null,
    scale: null,
    type: null,
    passingGrade: null,
    status: null,
  },
  course: {
      data: null,
      isSaving: false,
      isSaved: false,
      isDeleted: false,
      isLoading: false
  },
  courseSaved: {
    isSaving: false,
    isSaved: false,
  },
  courses: {
      isLoading: false,
      data: null
  },
  isApproved: {
      isLoading: false,
      data: null
  },
  advancedFilter: {
      showModal: false,
      values: [],
      columnOrder: 'name',
      order: 'asc',
      page: 1,
      pageSize: 10,
      filter: {}
  },
}

export default (state = initialState, action) => {
  switch (action.type) {

    case CoursesManagmentConstants.Accion.CoursesMonitoring.ADVANCED_FILTER_TOGGLE:
        return {
            ...state,
            advancedFilter: {
                ...state.advancedFilter,
                ...action.payload
            }
        };

    case CoursesManagmentConstants.Accion.CoursesMonitoring.UPDATE_FILTER:
        return {
            ...state,
            advancedFilter: state.advancedFilter
        };

    case CoursesManagmentConstants.Accion.CoursesMonitoring.SEARCH_COURSES_MONITORING:
        return {
            ...state,
            courses: {
                isLoading: action.payload.isLoading,
                data: action.payload.data
            }
        };

    case CoursesManagmentConstants.Accion.CoursesMonitoring.GET_COURSE_MONITORING_BY_ID:
        return {
            ...state,
            course: {
                data: action.payload.data,
                isSaving: false,
                isLoading: action.payload.isLoading
            }
        };
    case CoursesManagmentConstants.Accion.CoursesMonitoring.GET_IS_APPROVED:
        return {
            ...state,
            isApproved: {
                data: action.payload.data,
                isLoading: action.payload.isLoading
            }
        };

    case CoursesManagmentConstants.Accion.CoursesMonitoring.SAVE_COURSE_MONITORING:
        return {
            ...state,
            courseSaved: {
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };

    case CoursesManagmentConstants.Accion.CoursesMonitoring.RESET_AFTER_SAVED:

        return {
            ...state,
            course: {
                data: null,
                isSaving: false,
                isSaved: false,
                isLoading: false,
                isDeleted: false
            },
            courseSaved: {
                isSaving: false,
                isSaved: false
            }
        };

    case CoursesManagmentConstants.Accion.CoursesMonitoring.GET_DATA_MASTER:
        return {
            ...state,
            dataMaster: {
                category: action.payload.category,
                mode: action.payload.mode,
                service: action.payload.service,
                status: action.payload.status,
                // type: action.payload.type,
                // scale: action.payload.scale,
                // passingGrade: action.payload.passingGrade,
                isLoading: action.payload.isLoading,
                
            }
        };

    case CoursesManagmentConstants.Accion.CoursesMonitoring.DOWNLOAD_REPORT_STATUS:
        return {
            ...state,
            courses: {
                ...state,
                isLoading: action.payload.isLoading,
            },
        };
    default:
        return state;

}
}