import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { MonitoringAction } from 'features/indicators/store/actions/MonitoringAction';
import { Editor } from 'primereact/editor';
import HeaderEditor from 'shared/components/header-editor/HeaderEditor';
import { Controller, useForm } from 'react-hook-form';
import { Divider } from 'primereact/divider';
import './ActionPlanModal.scss'

const ActionPlanModal = ({displayModal, setDisplayModal, title, indicatorSelected, setIndicatorSelected}) => {

    const dispatch = useDispatch()
    const { data: dataAction, isSaved } = useSelector(state => state.monitoringReducer.actionPlan)
    const [visible, setVisible] = useState(false);
    
   
    const {
        setValue,
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors },
      } = useForm({
        actionPlan: '',
      });
      
    const valuesFields = watch();
    const existError = Object.keys(errors).length > 0
    const onHide = () => {
        setDisplayModal(false)
        setIndicatorSelected(null)
        reset()
    }

    useEffect(() => {
        if (indicatorSelected) {
            dispatch(MonitoringAction.getIndicatorActionPlanByIndicatorId(indicatorSelected.id))
        }
    }, [indicatorSelected])

    useEffect(() => {
        if (dataAction) {
            setValue('actionPlan', dataAction.body)
        }
    }, [dataAction])
    
    useEffect(() => {
        if (isSaved) {
            dispatch(MonitoringAction.getIndicatorActionPlanByIndicatorId(indicatorSelected.id))
            dispatch(MonitoringAction.resetAfterSaved())
            onHide()
        }
    }, [isSaved])
    
      

    const validateLength = (value) => {
        if (typeof value === 'string') {
          return value.replace(/<[^>]+>/g, '')?.length;
        } else if (value?.htmlValue) {
          return value.htmlValue.replace(/<[^>]+>/g, '').length;
        }
        return '0';
      };

    const header = HeaderEditor();

    const redirectToSearch = () => {
        onHide()
    }

    const reject = () => {
        setVisible(false);
      };

    const onSubmit = (data) => {
        if (dataAction) {
            const model = {
                id: dataAction.id,
                indicatorId: indicatorSelected.id,
                body: data.actionPlan
            }
            dispatch(MonitoringAction.saveIndicatorActionPlan(model));
            return
        }
        const model = {
            // id: indicatorSelected.id,
            indicatorId: indicatorSelected.id,
            body: data.actionPlan
        }
        dispatch(MonitoringAction.saveIndicatorActionPlan(model));
    };

    
    return (
        <Dialog header={title} visible={displayModal} className='w-4/5 md:w-3/5' onHide={onHide}>
            <Divider />
            <form 
                className="form-custom p-0"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="form-modal p-0">
                    <div className='col-span-2 mt-2'>
                        <Controller
                            control={control}
                            rules={{ required: 'El plan de acción es requerido'}}
                            render={({ field: { onChange, value } }) => (
                            <>
                            <Editor
                                headerTemplate={header}
                                className='action-plan-editor'
                                onTextChange={(e) => onChange(e.htmlValue)}
                                value={value}
                                placeholder="Añade un comentario"
                            />
                            <p className="font-semibold text-base text-gray-500 lg:mt-1 mt-3 text-right">
                                Caracteres{' '}
                                {validateLength(
                                valuesFields.actionPlan
                                )}{' '}
                                / 4000{' '}
                            </p>
                            </>
                        )}
                        name="actionPlan"
                        /> 
                    </div>
                </div>
                {
                    (existError) && <div className='message error'>
                        <ul>
                            {errors.actionPlan ? (
                                <>
                                    <li className="p-error">{errors.actionPlan.message}</li>
                                </>
                            ) : null}
                        </ul>

                    </div>
                }
                <div className="flex justify-end gap-4 mt-3">
                    <Button
                        icon="pi pi-times"
                        type="button"
                        label="Cancelar"
                        onClick={() => { setVisible(true); }}
                        className="sig-button sig-secondary mt-4"
                    />
                    <Button
                        icon="pi pi-save"
                        type="submit"
                        label="Guardar"
                        className="sig-button sig-primary mt-4"
                    />
                </div>
            </form>
          
            <ConfirmDialog
                visible={visible}
                onHide={() => setVisible(false)}
                message="¿Está seguro que desea cancelar?"
                header="Cancelar"
                icon="pi pi-exclamation-triangle"
                accept={redirectToSearch}
                reject={reject}
            />
        </Dialog>
    )
}

export default ActionPlanModal