import { useState } from "react"
import { SitesAction } from "features/maintenance/store/actions/SitesAction"
import { useDispatch } from "react-redux"
const useTable = () =>{
  const dispatch = useDispatch();
  const [firstTable , setFirst] = useState(0)
  const [rowsTable , setRows] = useState(10)
  const onPageChange = ({rows , page , first}) =>{
    dispatch(SitesAction.searchTable({page:page+1}))
    setFirst(first)
  }
  return {
    onPageChange,
    firstTable,
    rowsTable
  }
}

export default useTable