import { BusinessSheetConstants } from "features/business/commons/BusinessSheetConstants";

const initialState = {
  businessSheet: {
    data: [],
    loading: false,
    order: 'asc',
    pagination: {},
    columnOrder: 'name',
    isLoadingDownload: false,
  },
  businessSheetTemplates: {
    data: [],
    loading: false,
    error: false,
    currentTemplateId: null,
  },
  dataMaster: {
    data: {},
    isLoadingDataMaster: false,
  },
  data: {
    businessSheet: {},
    loading: false,
  },
  listControls: {
    listControls: [],
    isLoadingListControls: false,
  },
  save: {
    isLoadingSave: false,
  },
  share: {
    link: null,
    isLoadingShare: false,
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case BusinessSheetConstants.Action.BusinessSheet.SEARCH:
      return {
        ...state,
        businessSheet: {
          ...state.businessSheet,
          ...action.payload,
        },
      };
      
    case BusinessSheetConstants.Action.BusinessSheet.DATA_MASTER:
      return {
        ...state,
        dataMaster: {
          ...state.dataMaster,
          ...action.payload,
        },
      };
    
    case BusinessSheetConstants.Action.BusinessSheet.EDIT:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case BusinessSheetConstants.Action.BusinessSheet.LIST_CONTROLS:
      return {
        ...state,
        listControls: {
          ...state.listControls,
          ...action.payload,
        },
      };
    case BusinessSheetConstants.Action.BusinessSheet.SAVE:
      return {
        ...state,
        save: {
          ...state.save,
          ...action.payload,
        },
      };
    case BusinessSheetConstants.Action.BusinessSheet.SHARE:
      return {
        ...state,
        share: {
          ...state.share,
          ...action.payload,
        },
      };

    case BusinessSheetConstants.Action.BusinessSheet.TEMPLATES:
      return {
        ...state,
        businessSheetTemplates: {
          ...state.businessSheetTemplates,
          ...action.payload,
        },
      };
    default:
      return state;
  }
} 