import { Fragment, useState } from "react";
import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Skeleton } from "primereact/skeleton";
import { Calendar } from "primereact/calendar";
import { useStudent } from "features/customers/hooks/students/useStudent";
import Message from "shared/components/messages/Message";
import { DynamicFieldList } from "shared/components/dynamicFields/DynamicFieldList";

export const PageStudent = ({ title, mode }) => {
  const errorsArray = [];
  const {
    cancelAccept,
    cancelDenied,
    setShowModalCancel,
    showModalCancel,
    isLoadingStudent,
    isLoadingDataMaster,
    control,
    handleSubmit,
    onSubmit,
    errors,
    data,
    dynamicFieldsDatosGenerales,
    dynamicFieldsDireccion,
    dynamicFieldsInformacionAcademica,
    dynamicFieldsInformacionDeContacto,
    dynamicFieldsInformacionLaboral,
  } = useStudent({ title });

  const [countrySelected, setCountrySelected] = useState("");
  let today = new Date();

  return (
    <>
      <div className="bg-white">
        <form onSubmit={handleSubmit(onSubmit)} noValidate="novalidate">
          <Panel header="DATOS GENERALES" toggleable>
            <div className="flex lg:flex-row flex-col lg:gap-3 gap-6 justify-between mt-4 mb-6">
              {!isLoadingStudent || !isLoadingDataMaster ? (
                <Controller
                  control={control}
                  rules={{
                    required: "El Nombre es obligatorio. ",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <span className="p-float-label w-full">
                      <InputText
                        type="text"
                        id="firstName"
                        className={`w-full ${
                          errors.firstName?.type === "required"
                            ? " p-invalid"
                            : ""
                        }`}
                        onChange={onChange}
                        value={value}
                        name="firstName"
                      />
                      <label htmlFor="firstName">Nombres *</label>
                    </span>
                  )}
                  name="firstName"
                />
              ) : (
                <Skeleton
                  width="100%"
                  height="2.5rem"
                  className="mb-3 w-full"
                />
              )}
              {!isLoadingStudent || !isLoadingDataMaster ? (
                <Controller
                  control={control}
                  rules={{
                    required: "Los Apellidos son obligatorios. ",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <span className="p-float-label w-full">
                      <InputText
                        type="text"
                        id="fatherLastName"
                        className={`w-full ${
                          errors.fatherLastName?.type === "required"
                            ? " p-invalid"
                            : ""
                        }`}
                        onChange={onChange}
                        value={value}
                        name="fatherLastName"
                      />
                      <label htmlFor="fatherLastName">Apellidos *</label>
                    </span>
                  )}
                  name="fatherLastName"
                />
              ) : (
                <Skeleton
                  width="100%"
                  height="2.5rem"
                  className="mb-3 w-full"
                />
              )}
              {!isLoadingStudent || !isLoadingDataMaster ? (
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <span className="p-float-label w-full">
                        <Calendar
                          monthNavigator
                          yearNavigator
                          maxDate={today}
                          yearRange="1950:2030"
                          showIcon={false}
                          onChange={onChange}
                          value={value}
                          className="w-full"
                          id="birthday"
                        ></Calendar>
                        <label htmlFor="birthday">Fecha de nacimiento</label>
                      </span>
                    </>
                  )}
                  name="birthday"
                />
              ) : (
                <Skeleton
                  width="100%"
                  height="2.5rem"
                  className="mb-3 w-full"
                />
              )}
            </div>
            <div className="flex lg:flex-row flex-col lg:gap-3 gap-6 mb-6">
              {!isLoadingStudent || !isLoadingDataMaster ? (
                <Fragment>
                  <Controller
                    control={control}
                    rules={{
                      required: "El Tipo de Documento es obligatorio. ",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          className={`w-full ${
                            errors.documentTypeId?.type === "required"
                              ? " p-invalid"
                              : ""
                          }`}
                          options={data.documentTypes}
                          optionLabel="description"
                          filter
                          filterBy="description"
                          showClear
                          onChange={onChange}
                          value={value}
                        />
                        <label htmlFor="dropdown">Tipo de Documento *</label>
                      </span>
                    )}
                    name="documentTypeId"
                  />

                  <Controller
                    control={control}
                    rules={{
                      required: "El Número de Documento es obligatorio. ",
                      minLength: {
                        message:
                          "El Número de documento solo admite 8 dígitos.",
                        value: 8,
                      },
                      maxLength: {
                        message:
                          "El Número de documento solo admite 11 dígitos.",
                        value: 11,
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <InputText
                          type="number"
                          className={`w-full ${
                            errors.documentNumber?.type === "minLength" ||
                            errors.documentNumber?.type === "maxLength"
                              ? " p-invalid"
                              : ""
                          }`}
                          onChange={onChange}
                          name="documentNumber"
                          value={value}
                        />
                        <label htmlFor="documentNumber">
                          Número de Documento *
                        </label>
                      </span>
                    )}
                    name="documentNumber"
                  />
                  <Controller
                    control={control}
                    rules={{
                      required: "La Línea de Negocio es obligatoria. ",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          className={`w-full ${
                            errors.businessLineId?.type === "required"
                              ? " p-invalid"
                              : ""
                          }`}
                          options={data.businessLines}
                          optionLabel="description"
                          filter
                          filterBy="description"
                          showClear
                          onChange={onChange}
                          value={value}
                        />
                        <label htmlFor="dropdown">Línea de Negocio *</label>
                      </span>
                    )}
                    name="businessLineId"
                  />
                </Fragment>
              ) : (
                [1, 2, 3].map((i) => (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                    key={i}
                  />
                ))
              )}
            </div>
            <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-1">
              {
                (!isLoadingStudent && !isLoadingDataMaster) &&
                <DynamicFieldList control={control} fields={dynamicFieldsDatosGenerales} />
              }
            </div>
          </Panel>
          <Panel header="INFORMACIÓN DE CONTACTO" toggleable>
            <div className="flex lg:flex-row flex-col lg:gap-3 gap-6 justify-between mt-4 mb-6">
              {!isLoadingStudent || !isLoadingDataMaster ? (
                <Fragment>
                  <Controller
                    control={control}
                    rules={{
                      required: "El Correo Eléctronico es obligatorio. ",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message:
                          "El Formato de correo electrónico no es válido.",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <InputText
                          type="email"
                          id="emailAddress"
                          onChange={onChange}
                          name="emailAddress"
                          value={value}
                          pattern=".+@globex\.com"
                          className={`w-full ${
                            errors.emailAddress?.type === "required"
                              ? " p-invalid"
                              : ""
                          }`}
                        />
                        <label htmlFor="emailAddress">
                          Correo eléctronico(*)
                        </label>
                      </span>
                    )}
                    name="emailAddress"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <InputText
                          type="number"
                          id="phoneNo"
                          onChange={(e) => {
                            const value = e.target.value;
                            if (Math.sign(value) === -1) {
                              e.target.value = "";
                            }
                            onChange(e);
                          }}
                          name="phoneNo"
                          value={value}
                        />
                        <label htmlFor="phoneNo">Teléfono</label>
                      </span>
                    )}
                    name="phoneNo"
                  />
                </Fragment>
              ) : (
                [1, 2].map((i) => (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                    key={i}
                  />
                ))
              )}
            </div>

            <div className="flex lg:flex-row flex-col lg:gap-3 gap-6 mb-6">
              {!isLoadingStudent || !isLoadingDataMaster ? (
                <Fragment>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          options={data.socialNetworks}
                          optionLabel="description"
                          filter
                          filterBy="description"
                          showClear
                          className="w-full"
                          onChange={onChange}
                          value={value}
                        />
                        <label htmlFor="dropdown">Red Social</label>
                      </span>
                    )}
                    name="socialNetworkId"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <InputText
                          type="text"
                          id="facebookProfileAddress"
                          value={value}
                          onChange={onChange}
                          name="facebookProfileAddress"
                        />
                        <label htmlFor="socialNetwork">Red Social</label>
                      </span>
                    )}
                    name="facebookProfileAddress"
                  />
                </Fragment>
              ) : (
                [1, 2].map((i) => (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                    key={i}
                  />
                ))
              )}
            </div>
            <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-1">
              {
                (!isLoadingStudent && !isLoadingDataMaster) &&
                <DynamicFieldList control={control} fields={dynamicFieldsInformacionDeContacto} />
              }
            </div>
          </Panel>
          <Panel header="DIRECCIÓN" toggleable>
            <div className="flex lg:flex-row flex-col lg:gap-3 gap-6 mt-4 mb-6">
              {!isLoadingStudent || !isLoadingDataMaster ? (
                <Fragment>
                  <Controller
                    control={control}
                    rules={{
                      required: "El Pais es obligatorio. ",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          options={data.countries}
                          optionLabel="niceName"
                          filter
                          filterBy="niceName"
                          showClear
                          className={`w-full ${
                            errors.countryId?.type === "required"
                              ? " p-invalid"
                              : ""
                          }`}
                          onChange={(e) => {
                            setCountrySelected(e.value);
                            onChange(e);
                            if (e.value && e.value.isoCode !== "PE") {
                              control._formValues.departmentId = null;
                              control._formValues.cityId = null;
                              control._formValues.districtId = null;
                            }
                          }}
                          value={value}
                        />
                        <label htmlFor="dropdown">País *</label>
                      </span>
                    )}
                    name="countryId"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          disabled={
                            countrySelected && countrySelected.isoCode !== "PE"
                          }
                          options={data.departments}
                          optionLabel="name"
                          filter
                          filterBy="name"
                          showClear
                          className={
                            countrySelected && countrySelected.isoCode !== "PE"
                              ? "disabled"
                              : "w-full"
                          }
                          onChange={onChange}
                          value={
                            countrySelected && countrySelected.isoCode !== "PE"
                              ? null
                              : value
                          }
                        />
                        <label htmlFor="dropdown">Departamento</label>
                      </span>
                    )}
                    name="departmentId"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          disabled={
                            countrySelected && countrySelected.isoCode !== "PE"
                          }
                          options={data.cities}
                          optionLabel="name"
                          filter
                          filterBy="name"
                          showClear
                          className={
                            countrySelected && countrySelected.isoCode !== "PE"
                              ? "disabled"
                              : "w-full"
                          }
                          onChange={onChange}
                          value={
                            countrySelected && countrySelected.isoCode !== "PE"
                              ? null
                              : value
                          }
                        />
                        <label htmlFor="dropdown">Provincia</label>
                      </span>
                    )}
                    name="cityId"
                  />
                </Fragment>
              ) : (
                [1, 2, 3].map((i) => (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                    key={i}
                  />
                ))
              )}
            </div>
            <div className="flex lg:flex-row flex-col lg:gap-3 gap-6 justify-between mt-4">
              {!isLoadingStudent || !isLoadingDataMaster ? (
                <Fragment>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          disabled={
                            countrySelected && countrySelected.isoCode !== "PE"
                          }
                          options={data.districts}
                          optionLabel="name"
                          filter
                          filterBy="name"
                          showClear
                          className={
                            countrySelected && countrySelected.isoCode !== "PE"
                              ? "disabled"
                              : "w-full"
                          }
                          onChange={onChange}
                          value={
                            countrySelected && countrySelected.isoCode !== "PE"
                              ? null
                              : value
                          }
                        />
                        <label htmlFor="dropdown">Distrito</label>
                      </span>
                    )}
                    name="districtId"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <InputText
                          type="text"
                          id="address"
                          onChange={onChange}
                          name="address"
                          value={value}
                        />
                        <label htmlFor="address">Dirección</label>
                      </span>
                    )}
                    name="address"
                  />
                </Fragment>
              ) : (
                [1, 2].map((i) => (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                    key={i}
                  />
                ))
              )}
            </div>
            <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-1">
              {
                (!isLoadingStudent && !isLoadingDataMaster) &&
                <DynamicFieldList control={control} fields={dynamicFieldsDireccion} />
              }
            </div>
          </Panel>
          <Panel header="INFORMACIÓN ACADÉMICA" toggleable>
            <div className="flex lg:flex-row flex-col lg:gap-3 gap-6 justify-between mt-4 mb-6">
              {!isLoadingStudent || !isLoadingDataMaster ? (
                <Fragment>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          className="w-full"
                          options={data.professionalDegrees}
                          optionLabel="description"
                          filter
                          filterBy="description"
                          showClear
                          onChange={onChange}
                          value={value}
                        />
                        <label htmlFor="dropdown">Grado Académico</label>
                      </span>
                    )}
                    name="professionalDegreeId"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          className="w-full"
                          options={data.professions}
                          optionLabel="name"
                          filter
                          filterBy="name"
                          showClear
                          onChange={onChange}
                          value={value}
                        />
                        <label htmlFor="dropdown">Profesión</label>
                      </span>
                    )}
                    name="professionId"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          className="w-full"
                          options={data.academicInstitutions}
                          optionLabel="name"
                          filter
                          filterBy="name"
                          showClear
                          onChange={onChange}
                          value={value}
                        />
                        <label htmlFor="dropdown">Institución</label>
                      </span>
                    )}
                    name="academicInstitutionId"
                  />
                </Fragment>
              ) : (
                [1, 2, 3].map((i) => (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                    key={i}
                  />
                ))
              )}
            </div>
            <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-1">
              {
                (!isLoadingStudent && !isLoadingDataMaster) &&
                <DynamicFieldList control={control} fields={dynamicFieldsInformacionAcademica} />
              }
            </div>
          </Panel>
          <Panel header="INFORMACIÓN LABORAL" toggleable>
            <div className="flex lg:flex-row flex-col lg:gap-3 gap-6 justify-between mt-4 mb-6">
              {!isLoadingStudent || !isLoadingDataMaster ? (
                <Fragment>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          className="w-full"
                          options={data.employers}
                          optionLabel="name"
                          filter
                          filterBy="name"
                          showClear
                          onChange={onChange}
                          value={value}
                        />
                        <label htmlFor="dropdown">Empresa</label>
                      </span>
                    )}
                    name="employerId"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          className="w-full"
                          options={data.professionalSectors}
                          optionLabel="description"
                          filter
                          filterBy="description"
                          showClear
                          onChange={onChange}
                          value={value}
                        />
                        <label htmlFor="dropdown">Sector Profesional</label>
                      </span>
                    )}
                    name="professionalSectorId"
                  />
                </Fragment>
              ) : (
                [1, 2].map((i) => (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                    key={i}
                  />
                ))
              )}
            </div>

            <div className="flex lg:flex-row flex-col lg:gap-3 gap-6 mb-6">
              {!isLoadingStudent || !isLoadingDataMaster ? (
                <Fragment>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          className="w-full"
                          options={data.positions}
                          optionLabel="name"
                          filter
                          filterBy="name"
                          showClear
                          onChange={onChange}
                          value={value}
                        />
                        <label htmlFor="dropdown">Cargo</label>
                      </span>
                    )}
                    name="positionId"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full">
                        <Dropdown
                          className="w-full"
                          options={data.professionalProfiles}
                          optionLabel="description"
                          filter
                          filterBy="description"
                          showClear
                          onChange={onChange}
                          value={value}
                        />
                        <label htmlFor="dropdown">Perfil Profesional</label>
                      </span>
                    )}
                    name="professionalProfileId"
                  />
                </Fragment>
              ) : (
                [1, 2].map((i) => (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                    key={i}
                  />
                ))
              )}
            </div>
            <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-1">
              {
                (!isLoadingStudent && !isLoadingDataMaster) &&
                <DynamicFieldList control={control} fields={dynamicFieldsInformacionLaboral} />
              }
            </div>
          </Panel>
          {Object.entries(errors).forEach((key, value) => {
            errorsArray.push(key[1].message);
          })}
          {errorsArray.length > 0 && (
            <Message messages={errorsArray} status="error" />
          )}

          {(!isLoadingStudent || !isLoadingDataMaster) && (
            <div className="flex justify-end gap-4 mt-3">
              <Button
                icon="pi pi-times"
                type="button"
                label="Cancelar"
                disabled={isLoadingStudent || isLoadingDataMaster}
                onClick={() => setShowModalCancel(true)}
                className="sig-button  sig-secondary mt-4"
              />
              {
                (mode!=='view')&&(
                  <Button
                    icon="pi pi-save"
                    type="submit"
                    label="Guardar"
                    loading={isLoadingStudent}
                    className="sig-button sig-primary mt-4"
                  />
                )
              }
            </div>
          )}
        </form>
        <ConfirmDialog
          visible={showModalCancel}
          onHide={() => setShowModalCancel(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={cancelAccept}
          reject={cancelDenied}
        />
      </div>
    </>
  );
};
