export class CoursesManagmentConstants {
  static Accion = class {
    static Courses = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return '@COURSES.ADVANCED-FILTER-TOGGLE';
      }

      static get SEARCH_COURSES() {
        return '@COURSES.SEARCH_COURSES';
      }

      static get UPDATE_FILTER() {
        return '@COURSES.UPDATE_FILTER';
      }

      static get GET_COURSE_BY_ID() {
        return '@COURSES.GET_COURSE_BY_ID';
      }

      static get SAVE_COURSE() {
        return '@COURSES.SAVE_COURSE';
      }

      static get DELETE_COURSE() {
        return '@COURSES.DELETE_COURSE';
      }

      static get GET_DATA_MASTER() {
        return '@COURSES.GET_DATA_MASTER';
      }

      static get RESET_AFTER_SAVED() {
        return '@COURSES.RESET_AFTER_SAVED';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@COURSES.DOWNLOAD_REPORT_STATUS';
      }

    }

    static CoursesMonitoring = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return '@COURSES_MONITORING.ADVANCED-FILTER-TOGGLE';
      }

      static get SEARCH_COURSES_MONITORING() {
        return '@COURSES_MONITORING.SEARCH_COURSES';
      }

      static get UPDATE_FILTER() {
        return '@COURSES_MONITORING.UPDATE_FILTER';
      }

      static get GET_COURSE_MONITORING_BY_ID() {
        return '@COURSES_MONITORING.GET_COURSE_BY_ID';
      }

      static get SAVE_COURSE_MONITORING() {
        return '@COURSES_MONITORING.SAVE_COURSE';
      }

      static get GET_DATA_MASTER() {
        return '@COURSES_MONITORING.GET_DATA_MASTER';
      }

      static get GET_IS_APPROVED() {
        return '@COURSES_MONITORING.GET_IS_APPROVED';
      }

      static get RESET_AFTER_SAVED() {
        return '@COURSES_MONITORING.RESET_AFTER_SAVED';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@COURSES_MONITORING.DOWNLOAD_REPORT_STATUS';
      }
    }

    //SCHEDULE  CONSTANTS
    static Schedule = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return '@SCHEDULE.ADVANCED-FILTER-TOGGLE';
      }

      static get SEARCH_SCHEDULES() {
        return '@SCHEDULE.SEARCH_SCHEDULES';
      }

      static get UPDATE_FILTER() {
        return '@SCHEDULE.UPDATE_FILTER';
      }

      static get GET_SCHEDULE_BY_ID() {
        return '@SCHEDULE.GET_SCHEDULE_BY_ID';
      }
      
      static get SAVE_SCHEDULE() {
        return '@SCHEDULE.SAVE_SCHEDULE';
      }

      static get GET_DATA_MASTER() {
        return '@SCHEDULE.GET_DATA_MASTER';
      }

      static get RESET_AFTER_SAVED() {
        return '@SCHEDULE.RESET_AFTER_SAVED';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@SCHEDULE.DOWNLOAD_REPORT_STATUS';
      }
    };

    //ATTENDANCE
    static Attendance = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return '@ATTENDANCE.ADVANCED-FILTER-TOGGLE';
      }

      static get SEARCH_ATTENDANCES() {
        return '@ATTENDANCE.SEARCH_ATTENDANCES';
      }

      static get UPDATE_FILTER() {
        return '@ATTENDANCE.UPDATE_FILTER';
      }

      static get GET_ATTENDANCE_BY_ID() {
        return '@ATTENDANCE.GET_ATTENDANCE_BY_ID';
      }
      
      static get SAVE_ATTENDANCE() {
        return '@ATTENDANCE.SAVE_ATTENDANCE';
      }
      
      static get DELETE_ATTENDANCE() {
        return '@ATTENDANCES.DELETE_ATTENDANCE';
      }
      static get GET_DATA_MASTER() {
        return '@ATTENDANCE.GET_DATA_MASTER';
      }

      static get RESET_AFTER_SAVED() {
        return '@ATTENDANCE.RESET_AFTER_SAVED';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@ATTENDANCE.DOWNLOAD_REPORT_STATUS';
      }
    }

    static CoursesExpenses = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return '@COURSES_EXPENSES.ADVANCED-FILTER-TOGGLE';
      }

      static get SEARCH_COURSES_EXPENSES() {
        return '@COURSES_EXPENSES.SEARCH_COURSES_EXPENSES';
      }

      static get UPDATE_FILTER() {
        return '@COURSES_EXPENSES.UPDATE_FILTER';
      }

      static get RESET_AFTER_SAVED() {
        return '@COURSES_EXPENSES.RESET_AFTER_SAVED';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@COURSES_EXPENSES.DOWNLOAD_REPORT_STATUS';
      }

      static get SAVE_COURSE_EXPENSE() {
        return '@COURSES_EXPENSES.SAVE_COURSE_EXPENSE';
      }

      static get GET_DATA_MASTER() {
        return '@COURSES_EXPENSES.GET_DATA_MASTER';
      }

      static get GET_COURSE_EXPENSE_BY_ID() {
        return '@COURSES_EXPENSES.GET_COURSE_EXPENSE_BY_ID';
      }

      static get DELETE_COURSE_EXPENSE() {
        return '@COURSES_EXPENSES.DELETE_COURSE_EXPENSE';
      }

      static get GET_COMMENTS_BY_COURSE_EXPENSE_ID() {
        return '@COURSES_EXPENSES.GET_COMMENTS_BY_COURSE_EXPENSE_ID';
      }

      static get DELETE_COMMENT() {
        return '@COURSES_EXPENSES.DELETE_COMMENT';
      }
      
      static get ADD_COMMENT() {
        return '@COURSES_EXPENSES.ADD_COMMENT';
      }
    };
    
    static PaymentCourse = class {
      static get SEARCH() {
        return '@PAYMENT_COURSE.SEARCH';
      }

      static get SAVE_PAYMENT_COURSE() {
        return '@PAYMENT_COURSE.SAVE_PAYMENT_COURSE';
      }

      static get ADVANCED_FILTER_TOGGLE() {
        return '@PAYMENT_COURSE.ADVANCED_FILTER_TOGGLE';
      }

      static get UPDATE_FILTER() {
        return '@PAYMENT_COURSE.UPDATE_FILTER';
      }

      static get GET_COURSES_AND_PAYMENT() {
        return '@PAYMENT_COURSE.GET_COURSES_AND_PAYMENT';
      }

      static get GET_LIST_STUDENTS() {
        return '@PAYMENT_COURSE.GET_LIST_STUDENTS';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@PAYMENT_COURSE.DOWNLOAD_REPORT_STATUS';
      }

      static get RESET_AFTER_SAVED() {
        return '@PAYMENT_COURSE.RESET_AFTER_SAVED';
      }

      static get GET_COURSE_PAYMENT_BY_ID() {
        return '@PAYMENT_COURSE.GET_COURSE_PAYMENT_BY_ID';
      }

      static get DELETE_PAYMENT() {
        return '@PAYMENT_COURSE.DELETE_PAYMENT';
      }
    };
  }

  static SectionsName = class {
    static get DATOS_GENERALES() {
      return 'DATOS GENERALES';
    }
    static get CALIFICACION() {
      return 'CALIFICACIÓN';
    }
  }
}
