/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { RadioButton } from 'primereact/radiobutton'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid';


const initialState = {
  fieldLabel: '',
  typeOptionId: null,
  options: [],
  required: false,
  readOnly: false,
  showHeader: false
}

// const optionsColums = [
//   { typeColumn: 'Texto', icon: 'pi pi-amazon' },
//   { typeColumn: 'Parrafo', icon: 'pi pi-align-left' },
// ];
const numberOfEntries = [
  {name: 'Entrada única', code: 'entry_single'}, 
  {name: 'Entradas multiples', code: 'entry_multiple'},  
]

export const ControllerTable = ({titleModal, normalizeIndentifier, listOptions, positionSoruce, idTypeControl, displayExtraModal, setDisplayExtraModal, dataExtraModal, setDataExtraModal, dinamicStage, setDinamicStage, destinationControl}) => {

  const [optionsColums, setOptionsColumns] = useState(null)
  const [validateDuplicate, setValidateDuplicate] = useState('')
  const [showModalUserOptions, setShowModalUserOptions] = useState(false)
  const [userOptionsSelected, setUserOptionsSelected] = useState(null)
  const [dataModalUser, setDataModalUser] = useState({})
  const [dataCodeFieldTasks, setDataCodeFieldTasks] = useState([])
  const {data: dataMaster} = useSelector(state => state.processReducer.listResponsibles)

  useEffect(()=> {
    if(listOptions && optionsColums===null){
      setOptionsColumns(listOptions.filter(elem => elem.visibleTable))
    }
  },[])

  // useEffect(()=>{

  // })

  const onHide = () => {
    setDataExtraModal(initialState)
    setValidateDuplicate('')
    setDisplayExtraModal({title: '', visible: false, mode: "nuevo"})
  }

  const selectedColumnTemplate = (option, props) => {
    if (option) {
        return (
            <div className="column-item column-item-value flex items-center">
                <i className={option?.icon?option?.icon:"pi pi-align-justify"} style={{'fontSize': '1em'}} />
                <div className='ml-3'>{option?.code}</div>
            </div>
        );
    }
    return (
      <span>
          {props.placeholder}
      </span>
  );
  }

  const changeNameOption = (e,i) => {
    const data = dataExtraModal.options
    data[i].name = e.target.value
    setDataExtraModal({
      ...dataExtraModal,
      options: data
    })
  }

  const changeTaskField = (e,i) => {
    const data = dataExtraModal.options
    data[i].taskField = e.target.value
    setDataExtraModal({
      ...dataExtraModal,
      options: data
    })
  }

  const onTypeColumnChange = (e,i) => {
    if(e.value.code === "USUARIOS"){
      setUserOptionsSelected({value: e.value, i: i})
      setShowModalUserOptions(true)
    } else {
      const data = dataExtraModal.options
      data[i].Column = e.value
      setDataExtraModal({
        ...dataExtraModal,
        options: data
      })
    }
    // setSelectedColumn(e.value);
  }

  const columnOptionTemplate = (option) => {
    return (
        <div className="column-item flex items-center">
            <i className={option?.icon?option?.icon:"pi pi-align-justify pl-2"} style={{'fontSize': '1em'}} />
            <div className='pl-2'>{option?.code}</div>
        </div>
    );
  }

  const changeTitle = (e) => {
    setDataExtraModal({
      ...dataExtraModal,
      fieldLabel: e.target.value})
  }

  const addDinamicOption = () => {
    const data = dataExtraModal
    data.visible=true
    if(data.numRows===undefined){
      data.numRows = [""];
    }

    data.fieldLabel = (data.fieldLabel).trim()

    if(data.fieldLabel===""){
      setValidateDuplicate("La etiqueta del campo es requerida.")
    }else{
      let dataEmpty = false
      data.options = data.options.map(el => {
        if(el.name.trim()===''){
          dataEmpty = true
        }
        el.name = (el.name.trim())
        return el
      })

      if(data.options?.length===0){
        setValidateDuplicate("Ingrese al menos un elemento para la tabla.")
        return
      }else if(dataEmpty===true){
        setValidateDuplicate("Existen elementos vacíos en las opciones.")
        return
      }

      if(dataExtraModal.option){
        const options = dataExtraModal.options;
        const taskFields = options.filter(option => option.taskField).map(option => option.taskField.code) || []
        const hashMapTaskFields = {
          "TASK_FIELD_SCHEDULE_DATE": 0,
          "TASK_FIELD_RESPONSIBLE": 0,
          "TASK_FIELD_TITLE": 0
        }

        taskFields.forEach(code => {
          hashMapTaskFields[code] = hashMapTaskFields[code] + 1;
        });

        const errors = []

        if(hashMapTaskFields["TASK_FIELD_SCHEDULE_DATE"] === 0){
          errors.push("Debe existir un campo asociando a Fecha")
        }

        if(hashMapTaskFields["TASK_FIELD_RESPONSIBLE"] === 0){
          errors.push("Debe existir un campo asociando a Responsable")
        }

        if(hashMapTaskFields["TASK_FIELD_TITLE"] === 0){
          errors.push("Debe existir un campo asociando a Título")
        }

        if(hashMapTaskFields["TASK_FIELD_SCHEDULE_DATE"] > 1){
          errors.push("Solo puede existir un campo asociando a Fecha")
        }

        if(hashMapTaskFields["TASK_FIELD_RESPONSIBLE"] > 1){
          errors.push("Solo puede existir un campo asociando a Responsable")
        }

        if(hashMapTaskFields["TASK_FIELD_TITLE"] > 1){
          errors.push("Solo puede existir un campo asociando a Título")
        }

        if(errors.length > 0){
          setDataCodeFieldTasks(errors)
          return;
        } else {
          setDataCodeFieldTasks([])
        }
      } else {
        setDataCodeFieldTasks([])
      }

      if(displayExtraModal.mode==='nuevo'){

        let validateRepeat = false
        const indentifier = normalizeIndentifier(data.fieldLabel)

        dinamicStage.map(elem =>
          elem.dinamicInput?.map(el => {
            if(el.identifier===indentifier){
              validateRepeat = true
            }
            return el
          })
        )

        if(validateRepeat===true){
          setValidateDuplicate("La etiqueta del campo, ya se encuentra en uso.")
          return
        }else{
          data.stageId = positionSoruce
          data.id = uuidv4()
          data.identifier = indentifier
          data.mode='new'
          const visibleType = listOptions.find(el => el.id===idTypeControl)
          data.visibleSearch=visibleType.visibleSearch
          data.visibleAdvancedFilter=visibleType.visibleAdvancedFilter
          data.visibleSimpleFilter=visibleType.visibleSimpleFilter
          data.idTypeControl = idTypeControl
          const clone = ([...dinamicStage])
          const indexElement = (([...dinamicStage]).findIndex(elem => elem.id===positionSoruce))
          clone[indexElement].dinamicInput.splice(destinationControl,0,data)
          setDinamicStage(clone)
        }
      }else if(displayExtraModal.mode==='editar'){
        const clone = (([...dinamicStage]).map(elem=> {
          if(elem.id===dataExtraModal.stageId){
            let newData = elem.dinamicInput.map(input => {
              if (input.id === dataExtraModal.id) {
                data.id = dataExtraModal.id
                data.typeOptionId = dataExtraModal.typeOptionId?dataExtraModal.typeOptionId:idTypeControl
                return data
              }
              return input
            })
            elem.dinamicInput= newData
            return elem
          }
          return elem
        }))
        setDinamicStage(clone)
      }
      onHide()
    }

  }

  const addOption = () => {
    setDataExtraModal({
        ...dataExtraModal,
        options: [...dataExtraModal.options, { name: '', Column: optionsColums[0] }]
    })
  }

  const deleteOption = (index) =>{
    const data = dataExtraModal.options
    data.splice(index, 1);
    const showGenerateTask = data?.some(column => column.Column.code === "USUARIOS") && dataExtraModal.option
    setDataExtraModal({
      ...dataExtraModal,
      options: data,
      option: showGenerateTask,
    })
  }

  const changeAditionalValues = (e) => {
    setDataExtraModal({
      ...dataExtraModal,
      [e.target.name] : e.value
    })
  }

  const footer = (
    <div>
        {/* <Button label="Cancelar" className="p-button-text" icon="pi pi-times" onClick={onHide} /> */}
        <Button
          type="button"
          label="Cancelar"
          icon="pi pi-times"
          className="sig-button sig-secondary"
           onClick={onHide}
        />
        <Button
          type="button"
          label="Guardar"
          icon="pi pi-save"
          className="sig-button sig-primary"
          onClick={addDinamicOption}
        />
    </div>
  );

  const footerTable = (
    <div>
        {/* <Button label="Cancelar" className="p-button-text" icon="pi pi-times" onClick={onHide} /> */}
        <Button
          type="button"
          label="Cancelar"
          icon="pi pi-times"
          className="sig-button sig-secondary"
          onClick={(e)=>{
            setShowModalUserOptions(false)
            setUserOptionsSelected(null)
            setDataModalUser({})
          }}
        />
        <Button
          type="button"
          label="Guardar"
          icon="pi pi-save"
          className="sig-button sig-primary"
          onClick={(e) => {
            const data = dataExtraModal.options
            data[userOptionsSelected.i].Column = userOptionsSelected.value
            data[userOptionsSelected.i].dataTableUsers = dataModalUser
            data[userOptionsSelected.i].name = dataModalUser.fieldLabel
            setDataExtraModal({
              ...dataExtraModal,
              options: data
            })
            setShowModalUserOptions(false)
            setUserOptionsSelected(null)
            setDataModalUser({})
          }}
        />
    </div>
  )

  const taskFields = dataMaster.taskFields

  const inputGenerateTask = dataExtraModal?.option

  return (
    <>
      <Dialog header={titleModal} footer={footer} visible={displayExtraModal?.visible} style={{width: '70vw'}} modal onHide={onHide}>
          <hr/>
          <div className=" grid grid-cols-2 gap-2 mt-2">
            <span className="p-float-label col-span-2 w-f mt-4">
              <InputText id="textAddSelect" value={dataExtraModal.fieldLabel} autoFocus onChange={(e) => changeTitle(e)} />
              <label htmlFor="textAddSelect">Etiqueta del Campo *</label>
            </span>
            <div className='flex items-center mt-4 col-span-2'>
                <InputSwitch checked={dataExtraModal?.showHeader} name="showHeader" onChange={(e) => changeAditionalValues(e)} />
                <h5 className='ml-2'>Ocultar Cabecera</h5>
            </div>
            <div className='flex items-center col-span-2'>
                <InputSwitch checked={dataExtraModal?.readOnly} name="readOnly" onChange={(e) => changeAditionalValues(e)} />
                <h5 className='ml-2'>Editar Registros</h5>
            </div>
            <div className='flex items-center col-span-2'>
                <InputSwitch
                  checked={dataExtraModal?.option}
                  name="option"
                  onChange={(e) => changeAditionalValues(e)}
                  // disabled={!showGenerateTask}
                />
                <h5 className='ml-2'>Crear Tarea automaticamente</h5>
            </div>
          </div>
          <div>
            <div className='mb-2'>
              <Button
                type="button"
                label='Añadir Columna'
                icon="pi pi-plus"
                // sig-button sig-primary
                className="sig-button sig-primary min-h-full mt-2"
                onClick={addOption}
              />
            </div>
          </div>

          <div className=" grid grid-cols-2 gap-2 mt-2">
            <>
              {
                dataExtraModal?.options.map((el, i)=>{
                    return <div key={i} className='col-span-2 w-f flex items-center'>
                      <span className="p-float-label w-1/3 pt-2 pb-2 mr-4">
                        {
                          el.Column.code === "USUARIOS" ?
                          <span className="p-input-icon-right">
                            <i
                              className="pi pi-pencil cursor-pointer"
                              onClick={(e) => {
                                const _userOptionsSelected = {value: el.Column, i: i}
                                const data = dataExtraModal.options
                                setDataModalUser(data[_userOptionsSelected.i].dataTableUsers)
                                setUserOptionsSelected(_userOptionsSelected)
                                setShowModalUserOptions(true)
                              }}
                            />
                            <InputText
                              className='rounded-lg border-indigo-400 border-2'
                              value={el?.dataTableUsers?.fieldLabel}
                              // onChange={(e)=> changeNameOption(e,i)}
                              readOnly
                            />
                          </span>
                          :
                          <InputText className='rounded-lg border-indigo-400 border-2' value={el.name} onChange={(e)=> changeNameOption(e,i)} />
                        }
                      </span>
                      <span className="p-float-label w-1/3 pt-2 pb-2 pl-2 mr-4">
                        <Dropdown value={el.Column} options={optionsColums} onChange={(e) => {onTypeColumnChange(e,i)}} optionLabel={`typeColumn-${i}`}
                          valueTemplate={selectedColumnTemplate} itemTemplate={columnOptionTemplate} placeholder="Seleccione 1"/>
                      </span>

                      <span className="p-float-label w-1/3 pt-2 pb-2 pl-2 mr-4">
                        <Dropdown
                          value={el.taskField}
                          options={taskFields}
                          onChange={(e) => {changeTaskField(e,i)}}
                          optionLabel="description"
                          placeholder="Seleccione campo"
                          disabled={!inputGenerateTask}
                          showClear
                        />
                      </span>

                      <i className='pi pi-trash block ml-2 pr-10' style={{'fontSize': '1.5em'}} onClick={() => {deleteOption(i)}}/>
                    </div>}
                  )
              }
              </>
            </div>
            <div className={`rounded bg-red-200 p-5 mt-6 text-sm text-red-600 ${(validateDuplicate==='' && dataCodeFieldTasks.length === 0)&&'hidden'}`}>
              <ul>
                <li>{validateDuplicate}</li>
                {
                  dataCodeFieldTasks.map( (text, index) => (<li key={index}>{text}</li>))
                }
              </ul>
          </div>
      </Dialog>

      <Dialog header={"Usuarios"} footer={footerTable} visible={showModalUserOptions} style={{width: '40vw'}} modal onHide={() => {setShowModalUserOptions(false); setDataModalUser({})}}>
          <hr/>
          <div className=" grid grid-cols-2 gap-2 mt-2">
            <span className="p-float-label col-span-2 w-f mt-4">
              <InputText
                autoFocus
                name="fieldLabel"
                onChange={(e) => setDataModalUser( data => ({...data, [e.target.name]: e.target.value}))}
                value={dataModalUser["fieldLabel"] || ""}
              />
              <label>Etiqueta del Campo *</label>
            </span>
            <div className='items-center mt-4'>
              Seleccione el origen de datos:
              {
                  dataMaster?.processFormControlUserRecords
                  .map((dataSource) => {
                      return (
                          <div key={dataSource.id} className="field-radiobutton flex gap-x-1 items-center">
                              <RadioButton 
                                inputId={dataSource.id} 
                                name="dataSource" 
                                value={dataSource} 
                                onChange={({ value }) => setDataModalUser( data => ({...data, "dataSource": value}))}  
                                checked={dataModalUser.dataSource?.id === dataSource.id}  
                              />
                              <label htmlFor={dataSource.id}>{dataSource.description}</label>
                          </div>
                      )
                  })
              }

              {/* Número de entradas:
              {
                  numberOfEntries.map((numberOfEntry) => {
                      return (
                          <div key={numberOfEntry.code} className="field-radiobutton flex gap-x-1 items-center">
                              <RadioButton 
                                inputId={numberOfEntry.code} 
                                name="numberOfEntry" 
                                value={numberOfEntry} 
                                onChange={({ value }) => {
                                  console.log(value)
                                  setDataModalUser( data => ({...data, "numberOfEntry": value}))}
                                }
                                checked={dataModalUser.numberOfEntry?.code === numberOfEntry.code}  
                              />
                              <label htmlFor={numberOfEntry.code}>{numberOfEntry.name}</label>
                          </div>
                      )
                  })
              } */}

              <div className='flex gap-2 mt-4 items-center'>
                <div className='flex items-center'>
                  <InputSwitch
                    checked={dataModalUser.numberOfEntry?.code === numberOfEntries[1].code}
                    name="numberOfEntry"
                    onChange={({ value }) => {
                      setDataModalUser( data => ({...data, "numberOfEntry": value ? numberOfEntries[1] : numberOfEntries[0]}))
                    }}
                  />
                </div>
                <h5 className='ml-1'>Entradas multiples</h5>
              </div>

              <div className='flex gap-2 mt-1 items-center'>
                <div className='flex items-center'>
                  <InputSwitch
                    checked={dataModalUser?.required}
                    name="required"
                    onChange={({ value }) => setDataModalUser( data => ({...data, "required": value}))}
                  />
                </div>
                <h5 className='ml-1'>Requerido</h5>
              </div>
            </div>
          </div>
      </Dialog>
    </>
  )
}
