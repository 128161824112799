
import { Panel } from 'primereact/panel';
import { Droppable } from "react-beautiful-dnd";
import { Draggable } from "react-beautiful-dnd";
import { DragDropContext } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from 'primereact/badge';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { ViewActions } from "features/business/store/actions/ViewAction";
import { useEffect, useState } from "react";
import { Paginator } from "primereact/paginator";
import { Tooltip } from 'primereact/tooltip';
import { TableCommentsComponent } from 'features/customers/components/comments/TableCommentsComponent';
import { CoreConstants } from 'core/commons/CoreConstants';
import { useClaimViewBusiness } from 'features/business/hooks/useClaimViewBusiness';
import './TableComponent.scss';

export const TableComponent = () => {
  const stages = useSelector((state) => state.viewBusinessReducer.dataManager?.stages);
  const history = useHistory();
  const data = useSelector((state) => state.viewBusinessReducer.search?.results);
  const businessProcesing = useSelector((state) => state.viewBusinessReducer.changeStatus?.businessSaving);
  const criteria = useSelector((state) => state.viewBusinessReducer.search?.criteria);
  const [paginationNumber, setPaginationNumber] = useState(5);
  const dispatch = useDispatch();



  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "white",
    padding: '10',
    width: 250,
    margin: '0 5px 0 0'
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: `0 0 0 0`,
    background: isDragging ? "lightgreen" : "white",
    ...draggableStyle
  });

  const {isEdit}= useClaimViewBusiness();

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if( source.droppableId === destination.droppableId) return;

    const currentBusinessId = source.index;
    const currentStageId = source.droppableId.replace('item-', '');
    const nextStageId = parseInt(destination.droppableId.replace('item-', ''));

    dispatch(ViewActions.changeStatus({ id: currentBusinessId, stageId: nextStageId, currentStageId: currentStageId }));
  };

  const getDate = (item) => {
    if (item)
      return moment(item).format(CoreConstants.Format.LOCALE);
    return '';
  }

  const getMontoAdjudicado = (businessValue) => {
    return parseFloat(businessValue || 0).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  const getSumMontoAdjudicado = (data) => {
    if (data === null || data === undefined)
      return ('');
    let total = 0;
    data.map((item) => total += item.businessValue);
    return total;
  }

  const headerTemplate = (options, element) => {
    if (data === null || data === undefined)
      return ('');
    return (

      <div className="flex"
        style={{
          border: '1px solid #dee2e6',
          background: '#f8f9fa',
          color: '#495057',
          padding: '3px'
        }}>
        <div className="flex-1">
          <label className="text-sm">{element.stageName} <br />
            <label className="text-xs">
              S/. {getMontoAdjudicado(getSumMontoAdjudicado(data?.filter(c => c.stageId === element.id)))}
            </label>
          </label>
        </div>
        <div className="flex-2">
          <Badge
            value={data?.filter(c => c.stageId === element.id).length}
            style={{ width: '24px', height: '24px' }}
            severity="info"></Badge>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-5">
      <div
        className="mt-3 "
        style={{ maxWidth: window.innerWidth - 100 + "px" }}
      >

        {Object.keys(criteria || {}).length > 0 && <div style={{ display: 'inline-flex' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            {stages?.filter(c => c.pipelineId === criteria.pipelineId).map((el, ind) => (
              
              <Droppable
                key={`drop-${el.id}`}
                droppableId={`item-${el.id}`}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >

                    <Panel
                      headerTemplate={(event) => headerTemplate(event, el)}
                      header={el.stageName}
                      className='h-full content-business-drag'
                    >
                      {data?.filter(c => c.stageId === el.id).map((item, index) => (
                        <Draggable
                          key={`drag-${item.id}`}
                          draggableId={`item-${item.id}`}
                          index={item.id}
                          isDragDisabled={businessProcesing?.filter(c => c.id === item.id)?.length > 0 || !isEdit}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around"
                                }}
                              >

                                <div className="card-canva my-1"
                                  onClick={() => { 
                                    // dispatch(BusinessAction.getDataMaster())
                                    history.push(`/cliente/empresas/editar/${item.customerId}/negocios/editar/${item.id}?source=CRM`); }}
                                >
                                  <div className="flex justify-between">
                                    <Tooltip target={`.tooltip-${item.id}`} />
                                    <div className={`tooltip-${item.id}`}
                                          data-pr-tooltip={item.code===null||item.code===undefined?(`${item.name}`):(`${item.code} - ${item.name}`)}
                                          data-pr-position="top" >
                                      <p  className='title h-12 truncate-text'>
                                        {item.code===null||item.code===undefined?(
                                          <>
                                            {item.name}
                                          </>):(
                                            <>
                                            {item.code} - {item.name}
                                            </>
                                          )
                                        }
                                      </p>
                                    </div>
                                    {businessProcesing?.filter(c => c.id === item.id)?.length > 0 && (<span><i className="pi pi-spin pi-spinner" ></i></span>)}
                                  </div>
                                  <hr />
                                  <div className="body  ">
                                    <div>
                                      <p className="text-xs">Cliente:</p>
                                      <p className="text-xs font-bold">{item.customerName || '-'}</p>
                                    </div>
                                    <div className="mt-2">

                                      <div className="flex flex-row ">
                                        <div style={{ width: '50%' }}>
                                          <p className="text-xs">Apertura:</p>
                                          <p className="text-xs font-bold">
                                            {getDate(item.openDate) || '-'}
                                          </p>
                                        </div>
                                        <div style={{ width: '50%' }}>
                                          <p className="text-xs">Cierre:</p>
                                          <p className="text-xs font-bold">
                                            {getDate(item.closeDate) || '-'}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-2">
                                      <p className="text-xs">Monto adjudicado:</p>
                                      <p className="text-xs font-bold">{getMontoAdjudicado(item.businessValue)}</p>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </Panel>



                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

            ))}
          </DragDropContext>
        </div>}
      </div>
    </div>
  );
};