const TableHeaderComponent = () => {
  return (
    <div className="table-header">
      <div className="header-title flex items-center gap-x-3">
        <h1 className="text-sm">Unidad de medida</h1>
      </div>
      <div className="header-title flex items-center gap-x-3">
        <h1 className="text-sm">Abreviatura</h1>
      </div>
      <div className="header-title flex items-center gap-x-3"></div>
    </div>
  );
};

export default TableHeaderComponent;
