import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { CoreConstants } from "core/commons/CoreConstants";
import { TasksTableData } from "features/customers/data/tasks/TasksTableData";
import validateTypeValue from "shared/helpers/validateTypeValue";
import { TaskAction } from "features/customers/store/actions/TaskAction";

export const useTableTasks = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const tasks = useSelector((state) => state.tasksReducer.tasks);
  const comments = useSelector((state) => state.tasksReducer.comments);
  const [showModalComplete, setShowModalComplete] = useState(false);

  /* CURRENT COMPANY AND SOURCE ------------------- */
  const companyIdSelector = useSelector(
    (state) => state.tasksReducer.currentAction.companyId
  );

  const companyId = Number(params.customerId || companyIdSelector)

  const sourceId = useSelector(
    (state) => state.tasksReducer.currentAction.sourceId
  );

  /* CURRENT TASK ------------------- */
  const taskId = useSelector(
    (state) => state.tasksReducer.currentAction.taskId
  );
  const newPagePath = useSelector(
    (state) => state.tasksReducer.currentAction.newPagePath
  );
  const editPagePath = useSelector(
    (state) => state.tasksReducer.currentAction.editPagePath
  );

  const setCurrentTask = (taskId) => {
    dispatch(TaskAction.currentAction({ taskId }));
  };

  /* TYPE TABLE VIEW ---------------- */
  const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);

  /* TABLE -------------------------- */
  const [tableTasks, setTableTasks] = useState([]);
  const [tableTaskComments, setTableTaskComments] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState([]);
  const [tableCommentsLoading, setTableCommentsLoading] = useState(false);

  useEffect(() => {
    const { data, pagination, loading } = tasks;
    const { data: commentsList, loading: loadingComments } = comments;

    setTableTasks(validateTypeValue(data));
    setTablePagination(pagination);
    setTableLoading(loading);

    setTableTaskComments(validateTypeValue(commentsList));
    setTableCommentsLoading(loadingComments);
  }, [tasks, comments]);

  /* TABLE HEADER COLUMNS */
  const [selectColumns, setSelectColumns] = useState(TasksTableData.columns);

  useEffect(() => {
    if (localStorage.getItem("tasks-columns")) {
      setSelectColumns(JSON.parse(localStorage.getItem("tasks-columns")));
    }
  }, []);

  const setNewPage = () => {
    history.push(newPagePath);
  };

  const onColumnToggle = (e, item) => {
    let _selectedColumns = [...selectColumns];

    if (e.checked) _selectedColumns.push(item);
    else _selectedColumns = _selectedColumns.filter((c) => c.field !== e.value);

    if (_selectedColumns.length === 0) return;
    localStorage.setItem("tasks-columns", JSON.stringify(_selectedColumns));
    setSelectColumns(_selectedColumns);
  };
  const estadoTask = useSelector((state) => state.tasksReducer);
  // console.log(estadoTask)
  /* TABLE HEADER ACTIONS */
  const headerActions = useRef(null);

  /* TABLE CURRENT ACTION ------------- */
  const [currentAction, setCurrentAction] = useState("");
  const onCurrentAction = ({ item: { name } }) => setCurrentAction(name);
  useEffect(() => {
    const action_ = currentAction.toLocaleLowerCase();
    if (action_ === "editar") {
      history.push(`${editPagePath}/${taskId}`);
    } else if (action_ === "eliminar") {
      dispatch(TaskAction.currentAction({ isOpen: true }));
    }
    setCurrentAction("");
  }, [currentAction]);

  /* TABLE ACTIONS -------------------- */
  const tasksOptions = useSelector((state) => state.tasksReducer.actions.items);

  const [tableActions, setTableActions] = useState([]);
  const [showActions, setShowActions] = useState(false);
  const tableCurrentActions = useRef(null);

  useEffect(() => {
    if (tasksOptions.length > 0) {
      const newActions = tasksOptions.map((option) => {
        option.label = option.name;
        option.command = onCurrentAction;
        return option;
      });
      setTableActions(newActions);
    }
    setShowActions(tasksOptions.length > 0);
  }, []);

  /* MODAL DELETE (ACTIONS) */
  const isOpenModalDelete = useSelector(
    (state) => state.tasksReducer.currentAction.isOpen
  );
  const confirmDelete = () => {
    dispatch(TaskAction.deleteTask(taskId, companyId, sourceId));
  };
  const cancelDelete = () => {
    dispatch(TaskAction.currentAction({ isOpen: false }));
  };

  /* MODAL COMPLETE (ACTIONS) */
  const confirmComplete = () => {
    dispatch(TaskAction.completeTask(taskId));
  };
  const cancelComplete = () => {
    dispatch(TaskAction.currentAction({ isOpen: false }));
  };

  /* TABLE PAGINATION --------------- */
  const tasksSearch = useSelector((state) => state.tasksReducer.tasks);
  const [tableRows, setTableRows] = useState(10);
  const [tableNum, setTableNum] = useState(0);
  const [page, setPage] = useState(1);
  let currentFilterFields = {
    columnOrder: tasksSearch.columnOrder,
    order: tasksSearch.order,
  };

  const filterValues = useSelector(
    (state) => state.tasksReducer.advancedFilter.values
  );
  const buildFilterValues = () => {
    if (filterValues.length > 0) {
      filterValues.forEach(({ field, value, option }) => {
        let currentValue = "";
        const key = field.toLocaleLowerCase();
        if (key.includes("id")) currentValue = option.id;
        else currentValue = value;
        currentFilterFields[field] = currentValue;
      });
    }
  };

  const onChangePage = ({ rows, page }) => {
    buildFilterValues();
    currentFilterFields.page = page + 1;
    currentFilterFields.pageSize = rows;
    setTableRows(rows);
    setTableNum(rows * page);
    setPage(page + 1);
    const fields = currentFilterFields;

    dispatch(
      TaskAction.getTasksByCustomer({
        change: true,
        fields,
        documentId: companyId,
        sourceId: sourceId,
      })
    );
  };

  const getTasksByCustomer = (sourceTaskId) => {
    dispatch(
      TaskAction.getTasksByCustomer({
        change: false,
        documentId: companyId,
        sourceId: sourceTaskId ? sourceTaskId : sourceId,
      })
    );
  };

  /* TABLE ORDER SORT */
  const [tableSort, setTableSort] = useState({
    sortField: "scheduleDate",
    sortOrder: "desc",
  });
  const onSort = (event) => {
    buildFilterValues();
    const params = {
      first: tableNum,
      rows: tableRows,
      multiSortMeta: [],
      filters: null,
      ...event,
    };

    currentFilterFields.order = event.sortOrder + "" === "1" ? "asc" : "desc";
    currentFilterFields.columnOrder = event.sortField;
    currentFilterFields.pageSize = tableRows;
    currentFilterFields.page = page;
    const fields = currentFilterFields;

    dispatch(
      TaskAction.getTasksByCustomer({
        change: true,
        fields,
        documentId: companyId,
        sourceId: sourceId,
      })
    );
    setTableSort(params);
  };

  return {
    tableTasks,
    tableLoading,
    tablePagination,
    tableActions,
    showActions,
    tableCurrentActions,
    selectColumns,
    typeView,
    setTypeView,
    headerActions,
    getTasksByCustomer,
    onColumnToggle,
    onChangePage,
    setCurrentTask,
    setShowModalComplete,
    showModalComplete,
    confirmDelete,
    cancelDelete,
    isOpenModalDelete,
    confirmComplete,
    cancelComplete,
    setNewPage,
    sourceId,
    tableSort,
    tableNum,
    tableRows,
    onSort,
    onChangePage,
    tableTaskComments,
    tableCommentsLoading,
  };
};
