import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import AdvancedFilterComponent from 'features/coursesManagment/components/paymentCouses/AdvancedFilterComponent';
import FilterComponent from 'features/coursesManagment/components/paymentCouses/FilterComponent';
import Modal from 'features/configuration/components/processes/Formulario/Modal';
import {TableComponent} from 'features/coursesManagment/components/paymentCouses/TableComponent';
import { PaymentCouseAction } from 'features/coursesManagment/store/actions/PaymentCourseAction';
import { useClaimPaymentCourse } from 'features/coursesManagment/hooks/useClaimPaymentCourse';



const SearchPaymentCourse = () => {
    const history = useHistory();
    const [displayModal, setDisplayModal] = useState(false)
    const [displayActionPlanModal, setDisplayActionPlanModal] = useState(false)
    const [indicatorSelected, setIndicatorSelected] = useState(null)

    const { updateTitle } = useSetTitlePage();
    // const [isFirstLoading, setFirstLoading] = useState(true);
    const dispatch = useDispatch();
    const advancedFilter = useSelector((state) => state.paymentCourseReducer.advancedFilter);
    //comentario test

    const { isSearch, isDownload, isCreate } = useClaimPaymentCourse();
    useEffect(() => {
        updateTitle({
            title: 'Cursos',
            subtitle: 'Pagos de Cursos',
        });
        dispatch(PaymentCouseAction.updateFilter(advancedFilter))
        dispatch(PaymentCouseAction.search())
        dispatch(PaymentCouseAction.getCoursesAndPayment())
        dispatch(PaymentCouseAction.resetAfterSaved())
    }, []);

    return (
        <Fragment>
            {
                (isSearch||isDownload)&&(
                    <FilterComponent />
                )
            }
            {
                isCreate&&(
                    <Button
                        icon="pi pi-plus"
                        type="button"
                        label="Nuevo"
                        className="sig-button sig-dark mt-4"
                        onClick={() => {
                            history.push('/curso/pagos/nuevo');
                        }}
                    />
                )
            }
            <TableComponent />
            <AdvancedFilterComponent />
        </Fragment>
    );
};

export default SearchPaymentCourse
