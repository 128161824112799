import { Dropdown } from 'primereact/dropdown'
import { RadioButton } from 'primereact/radiobutton'
import React from 'react'
import { useSelector } from 'react-redux'

const rankingTypes = [
  {
    id: 1,
    name: 'Estrella',
    icon: 'pi-star-fill'
  },
  {
    id: 2,
    name: 'Sonrisa',
    icon: 'pi-sun'
  },
  {
    id: 3,
    name: 'Corazón',
    icon: 'pi-heart-fill'
  },
  {
    id: 4,
    name: 'Pulgar arriba',
    icon: 'pi-thumbs-up'
  },
]

const scales = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]


const QuestionRanking = ({addOptionRanking, question}) => {
  const { isCloseStatus } = useSelector((state) => state.surveyReducer.survey);


  const onChangeIcon = (e) => {
    // console.log(e.value)
    const model = {value: e.value, type: 'icon'}
    addOptionRanking(model)
  }

  const onChangeScale = (e) => {
    // console.log(e)
    const model = {value: e.value, type: 'scale'}
    addOptionRanking(model)
  }

  return (
    <div className='my-3'>
        <span className="p-float-label w-full mt-2">
          <div className='w-full flex flex-wrap md:flex-nowrap justify-evenly items-center gap-3'>
            <div className='flex flex-col mt-2'>
              <p className=''>Seleccione escala:</p>
              <div className=''>
                {/* <Dropdown value={scale} options={scales} onChange={(e) => onChangeScale(e)}  /> */}
                <Dropdown disabled={isCloseStatus} value={question.rankingQuantity} options={scales} onChange={(e) => onChangeScale(e)}  />
              </div>
            </div>
            {
              rankingTypes.map((type) => (
                  <div className='flex justify-center items-center gap-x-1' key={type.id} >
                      {/* <RadioButton inputId={type.id} value={type} onChange={(e) => onChangeIcon(e)} checked={value?.id === type.id} /> */}
                      <RadioButton inputId={type.id} disabled={isCloseStatus} value={type} onChange={(e) => onChangeIcon(e)} checked={question.icon === type.icon} />
                      <div className='h-8 w-8 flex justify-center items-center'>
                        <i className={`pi ${type.icon} text-xl text-gray-600`}></i>
                      </div>
                      <p>{type.name}</p>
                  </div>
                )
              )
            }
          </div>
        </span>
    </div>
  )
}

export default QuestionRanking