/* eslint-disable import/no-anonymous-default-export */
import { EvaluationConstants } from "features/riskManagement/commons/EvaluationConstans";

const initialState = {
	dataMaster: {
		data: {
			evaluationTypes: [],
			conditions: [],
			criterias: [],
			mathOperators: [],
			logicalOperators: [],
		},
		isLoading: false,
		error: false,
	},
	evaluations: {
		data: {
			results: [],
			currentPage: null,
			pageCount: null,
			pageSize: null,
			rowCount: null,
		},
		isLoading: false,
		error: false,
	},
	editEvaluation: {
		isLoading: false,
    isLoadingSave: false,
		error: false,
    data: {},
	},
	downloadReport: {
    loading: false,
  },
	advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'name',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {}
  },
	dataEvaluations: {
		data: null,
	}
}

export default (state = initialState, action) => {
	switch (action.type) {
		case EvaluationConstants.Accion.Evaluation.ADVANCED_FILTER_TOGGLE:
			return {
				...state,
				advancedFilter: {
					...state.advancedFilter,
					...action.payload
				}
			}

		case EvaluationConstants.Accion.Evaluation.UPDATE_FILTER:
			return {
				...state,
				advancedFilter: state.advancedFilter
		};

		case EvaluationConstants.Accion.Evaluation.GET_DATA_MASTER:
			return {
				...state,
				dataMaster: {
					...state.dataMaster,
					...action.payload
				}
			};

		case EvaluationConstants.Accion.Evaluation.SEARCH_EVALUATIONS:
			return {
				...state,
				evaluations: {
					...state.evaluations,
					...action.payload
				}
			};
	
		case EvaluationConstants.Accion.Evaluation.GET_DATA_BY_ID:
			return {
				...state,
				editEvaluation: {
					...state.editEvaluation,
					...action.payload
				}
			};

		case EvaluationConstants.Accion.Evaluation.REPORT_STATUS:
			return {
				...state,
				evaluations: {
					...state.evaluations,
					...action.payload
				}
			};

		case EvaluationConstants.Accion.Evaluation.ADVANCED_FILTER:
			return {
				...state,
				advancedFilter: {
					...state.advancedFilter,
					...action.payload,
				},
			};

		case EvaluationConstants.Accion.Evaluation.GET_EVALUATIONS_BY_ID:
			return {
				...state,
				dataEvaluations: {
					...state.dataEvaluations,
					...action.payload,
				},
			};

		default:
			return state;
	}
}