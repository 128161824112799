import { Calendar } from "primereact/calendar";
import { useEffect, useState } from "react";
import ModalFilterComponent from "shared/components/modal-filter/ModalFilterComponent";
import useFilter from "features/rrhh/hooks/employees/useFilter";
import { Dropdown } from "primereact/dropdown";
import useAdvancedFilter from "features/rrhh/hooks/employees/useAdvancedFilter";
import { RadioButton } from "primereact/radiobutton";
import useEmployees from "features/rrhh/hooks/employees/useEmployees";
const styleLabel = {
  color: "#004680",
  fontWeight: 600,
  marginBottom: "5px",
};

const RolesModalFilterComponent = () => {
  const { showModal, closeModal, loading } = useFilter();
  const { dataManager, loadingDataManager } = useEmployees();
  const { formValues, handleSubmit, setFormValues, clearFields } =
    useAdvancedFilter();
  // console.log(dataManager)
  const submit = (e) => {
    handleSubmit(e);
  };

  if (showModal) {
    return (
      <ModalFilterComponent
        closeModal={closeModal}
        clearFields={clearFields}
        handleSubmit={(e) => submit(e)}
        searchLoading={loading}
      >
        <div className="flex gap-4 pt-3">
          <div className="w-full">
            <span className="p-float-label">
              <Calendar
                onChange={(e) =>
                  setFormValues({ ...formValues, from: e.target.value })
                }
                value={formValues.from}
                name="from"
                id="desde"
              />
              <label htmlFor="desde">Desde</label>
            </span>
          </div>
          <div className="w-full">
            <span className="p-float-label">
              <Calendar
                onChange={(e) =>
                  setFormValues({ ...formValues, to: e.target.value })
                }
                value={formValues.to}
                name="to"
                id="hasta"
              />
              <label htmlFor="hasta">Hasta</label>
            </span>
          </div>
        </div>
        {!loadingDataManager && (
          <>
            {/* <div className="flex pt-7">
              <div className="w-full">
                <span className="p-float-label">
                  <Dropdown
                    // onChange={(e) =>
                    //   setFormValues({ ...formValues, porEmpresa: e.target.value })
                    // }
                    options={dataManager.empresas}
                    // value={formValues?.porEmpresa}
                    name="area"
                    optionValue={(e) => {
                      return `${e.id}-${e.name}`;
                    }}
                    optionLabel="name"
                    filter
                    showClear
                    className="w-full"
                  />

                  <label htmlFor="dropdown">Por Empresa</label>
                </span>
              </div>
            </div> */}
            <div className="flex pt-7">
              <div className="w-full">
                <span className="p-float-label">
                  <Dropdown
                    onChange={(e) =>
                      setFormValues({ ...formValues, area: e.target.value })
                    }
                    options={dataManager.areas}
                    value={formValues?.area}
                    name="area"
                    optionValue={(e) => {
                      return `${e.id}-${e.name}`;
                    }}
                    optionLabel="name"
                    filter
                    showClear
                    className="w-full"
                  />

                  <label htmlFor="dropdown">Por Departamento</label>
                </span>
              </div>
            </div>
            <div className="flex pt-7">
              <div className="w-full">
                <span className="p-float-label">
                  <Dropdown
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        positionId: e.target.value,
                      })
                    }
                    options={dataManager.positions}
                    value={formValues?.positionId}
                    name="positionId"
                    optionValue={(e) => {
                      return `${e.id}-${e.name}`;
                    }}
                    optionLabel="name"
                    filter
                    showClear
                    className="w-full"
                  />

                  <label htmlFor="dropdown">Por Cargo</label>
                </span>
              </div>
            </div>
            <div className="flex pt-7">
              <div className="w-full">
                <span className="p-float-label">
                  <Dropdown
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        managerId: e.target.value,
                      })
                    }
                    options={dataManager.employees}
                    value={formValues?.managerId}
                    name="managerId"
                    optionLabel={(e) => {
                      return `${e.firstName} ${e.fatherLastName} ${e.motherLastName}`;
                    }}
                    optionValue={(e) => {
                      return `${e.id}-${e.firstName} ${e.fatherLastName} ${e.motherLastName}`;
                    }}
                    filter
                    showClear
                    className="w-full"
                  />
                  <label htmlFor="dropdown">Por Gerente o Jefe Inmediato</label>
                </span>
              </div>
            </div>
            <div className="flex pt-7">
              <div className="w-full">
                <span className="p-float-label">
                  <Dropdown
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        employeeType: e.target.value,
                      })
                    }
                    options={dataManager.employeesTypes}
                    value={formValues?.employeeType}
                    name="employeeType"
                    optionValue={(e) => {
                      return `${e.id}-${e.description}`;
                    }}
                    optionLabel="description"
                    filter
                    showClear
                    className="w-full"
                  />

                  <label htmlFor="dropdown">Por Tipo de Empleado</label>
                </span>
              </div>
            </div>
            <div className="flex pt-7">
              <div className="w-full">
                <span className="p-float-label">
                  <Dropdown
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        countryOfBirth: e.target.value,
                      })
                    }
                    options={dataManager.nationalities}
                    value={formValues?.countryOfBirth}
                    name="countryOfBirth"
                    optionValue={(e) => {
                      return `${e.id}-${e.niceName}`;
                    }}
                    optionLabel="niceName"
                    filter
                    showClear
                    className="w-full"
                  />

                  <label htmlFor="dropdown">Por Nacionalidad</label>
                </span>
              </div>
            </div>
            <div className="flex pt-7">
              <div className="w-full">
                <span className="p-float-label">
                  <Dropdown
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        maritalStatusId: e.target.value,
                      })
                    }
                    options={dataManager.statusCivil}
                    value={formValues?.maritalStatusId}
                    name="maritalStatusId"
                    optionValue={(e) => {
                      return `${e.id}-${e.description}`;
                    }}
                    optionLabel="description"
                    filter
                    showClear
                    className="w-full"
                  />

                  <label htmlFor="dropdown">Por Estado Civil </label>
                </span>
              </div>
            </div>
            <div className="flex pt-7">
              <div className="w-full">
                <span className="p-float-label">
                  <Dropdown
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        academicDegreeId: e.target.value,
                      })
                    }
                    options={dataManager.instructions}
                    value={formValues?.academicDegreeId}
                    name="academicDegreeId"
                    optionValue={(e) => {
                      return `${e.id}-${e.description}`;
                    }}
                    optionLabel="description"
                    filter
                    showClear
                    className="w-full"
                  />

                  <label htmlFor="dropdown">Por Grado de instrucción</label>
                </span>
              </div>
            </div>
            <div className="flex mt-3">
              <div className="w-full">
                <h3 style={styleLabel}>Por Estado</h3>
                <div className="flex gap-3">
                  {dataManager?.status?.map((state, index) => {
                    return (
                      <div className="flex gap-3" key={index}>
                        <RadioButton
                          value={`${state.id}-${state.description}`}
                          name="situationId"
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              situationId: e.target.value,
                            })
                          }
                          checked={
                            formValues?.situationId ===
                            `${state.id}-${state.description}`
                          }
                        />
                        <p>{state.description}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}
        {loadingDataManager && (
          <h2 className="mt-4 text-center">Cargando...</h2>
        )}
      </ModalFilterComponent>
    );
  }

  return null;
};

export default RolesModalFilterComponent;
