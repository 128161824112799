import { CoreConstants } from 'core/commons/CoreConstants';
import { DynamicFieldsConstants } from 'features/configuration/commons/DynamicFieldsConstants';
import { DynamicFieldsService } from 'features/configuration/services/DynamicFieldsService'; 
import { toastAction } from 'core/store/actions/ToastAction';
import FileSaver from "file-saver";

const setDynamicFields = (fields) => {
  return (dispatch) =>
    dispatch({
      type: DynamicFieldsConstants.Action.DynamicFields.SEARCH_DYNAMICFIELDS,
      payload: { ...fields },
    });
};

const getDynamicFields = (fields) => {
  return (dispatch) => {
    dispatch(setDynamicFields({ loading: true }));

    DynamicFieldsService.search(fields)
      .then(({ data }) => {
        const status = data?.status ?? '';
        if (status === CoreConstants.HttpResponse.OK) {
          const { results, ...rest } = data.data;
          let currentFields = {
            loading: false,
            data: results,
            pagination: { ...rest },
          };

          if (fields?.fields?.order) {
            currentFields.order = fields.fields.order;
          }

          if (fields?.fields?.columnOrder) {
            currentFields.columnOrder = fields.fields.columnOrder;
          }

          dispatch(setDynamicFields(currentFields));
          return;
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setDynamicFields({ loading: false }));
      });
  };
};

const setAdvancedFilter = (payload) => {
  return (dispatch) => {
    dispatch({
      type: DynamicFieldsConstants.Action.DynamicFields.ADVANCED_FILTER,
      payload,
    });
  };
};

const getFilterValues = (newValues) => {
  const newFilterValues = {};
  newValues.forEach((val) => {
    const field = val.field.toLocaleLowerCase();
    if (field.includes('date')) newFilterValues[val.field] = val.date;
    else if (field.includes('id')) newFilterValues[val.field] = val.option.id;
    else newFilterValues[val.field] = val.value;
  });
  return newFilterValues;
};

const removeFilterValues = (removeField) => {
  return (dispatch, getState) => {
    let { values } = getState().dynamicFieldsReducer.advancedFilter;
    const newValues = values.filter(({ field }) => field !== removeField);
    const fields = getFilterValues(newValues);
    dispatch(getDynamicFields({ change: true, fields }));
    dispatch(setAdvancedFilter({ values: newValues }));
  };
};

const setGetDataMaster = (payload) => {
  return (dispatch) => {
    dispatch({
      type: DynamicFieldsConstants.Action.DynamicFields.DATA_MASTER,
      payload,
    });
  };
};

const getDataMaster = () => {
  return (dispatch) => {
    dispatch(setGetDataMaster({ isLoadingDataMaster: true }));

    DynamicFieldsService.getDataMaster()
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          const subscription = data.data.subscription
          if(!subscription.isSystem){
            const company = data.data.companies.find(x => x.id === subscription.companyId)
            data.data.companies = [company]
            const currentPiners = [{
              field: "companyId",
              value: company.businessName,
              option: {
                  id: subscription.companyId,
                  businessName: company.businessName
              }
            }]
            dispatch(
              setAdvancedFilter({
                showModal: false,
                values: currentPiners,
              })
            );
          }
          dispatch(setGetDataMaster({ data: data.data }));
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setGetDataMaster({ isLoadingDataMaster: false, getDinamycFields: true }));
      });
  };
};

const currentAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: DynamicFieldsConstants.Action.DynamicFields.CURRENT_ACTION,
      payload,
    });
  };
};

const setCurrentDynamicFields = (payload) => {
  return (dispatch) => {
    dispatch({
      type: DynamicFieldsConstants.Action.DynamicFields.EDIT_DYNAMICFIELDS,
      payload,
    });
  };
};

const editDynamicFields= (id) => {
  return (dispatch) => {
    dispatch(setCurrentDynamicFields({ isLoadingDynamicFields: true, data: {}, attachments: [] }));
    dispatch(getDynamicFieldsById(id));
  };
};

const getDynamicFieldsById = (id) => {
  return (dispatch) => {
    dispatch(setCurrentDynamicFields({ isLoadingDynamicFields: true, data: {}, attachments: [] }));

    DynamicFieldsService.getById(id)
    .then(({ data }) => {
      const status = data.status;
      if (status === CoreConstants.HttpResponse.OK) {
        // const business = data.Data;
        const dynamicFields = data.data;

        Object.entries(dynamicFields).forEach(([key, value]) => {
          if (value !== null) {
            if (key.includes('Date')) {
                let date = new Date(value);
                dynamicFields[key] = date;
            }
          }
        })

        // dispatch(setCurrentBusiness({ data: data.Data }));
        dispatch(setCurrentDynamicFields({ data: dynamicFields, isLoadingDynamicFields: false }));
      }
    })
    .catch(console.error)
    .finally(() => {
      dispatch(setCurrentDynamicFields({ isLoadingDynamicFields: false }));
    });
  }
}

const reportStatus = (payload) => ({
  type: DynamicFieldsConstants.Action.DynamicFields.REPORT_STATUS,
  payload,
});

const generateReport = (id) => {
  return (dispatch, getState) => {        
    // let { values } = getState().projectReducer.advancedFilter;
    dispatch(reportStatus(true));
    // const {name, customerId, statusId} = getFilterValues(values);

    const requestGenerateReport = { 
    }    
    DynamicFieldsService.generateReport(requestGenerateReport)
      .then(({ data }) => {
        dispatch(reportStatus(false));
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        FileSaver.saveAs(blob, "CamposDinamicos.xlsx");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(reportStatus(false));
      });
  };
};

const save = ({ dynamicFields, isNewDynamicFields, cb = () => {}}) => {
  return (dispatch) => {
    // dispatch(setCurrentDynamicFields({ isLoadingDynamicFields: true }));
    dispatch(setCurrentDynamicFields({ isSaving: true }));

    DynamicFieldsService.save(dynamicFields)
      .then(({ data }) => {
        const status = data.status;
        if (data.status === CoreConstants.HttpResponse.OK) {
          const message = isNewDynamicFields
            ? '¡Registro exitoso!'
            : '¡Edición exitosa!';
          dispatch(toastAction.success('Campos Dinámicos', message));
          dispatch(setCurrentDynamicFields({ errors: [], isSaving: false }));
          cb()
        } else if (
          data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS &&
          data.data.length > 0
        ) {
          dispatch(setCurrentDynamicFields({ errors: data.data }));
        }
        dispatch(setCurrentDynamicFields({ status }));
      })
      .catch((error) => {
        console.error(error);
        dispatch(setCurrentDynamicFields({ status: 2 }));
      })
      .finally(() => {
        dispatch(setCurrentDynamicFields({ isLoadingDynamicFields: true }));
      });
  };
};

const deleteById = (id) => {
  return (dispatch) => {
    DynamicFieldsService.deleteById(id)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Campos Dinámicos', 'Campos Dinámicos Eliminados'));
          dispatch(getDynamicFields({ change: true }));
        }
      })
      .catch(console.error);
  };
};

const setSection = (payload) => ({
  type: DynamicFieldsConstants.Action.DynamicFields.GET_SECTION,
  payload,
});

const getSection = ({ idEntity }) => {
  return (dispatch) => {
    dispatch(setSection({ isLoadingSection: true }));

    DynamicFieldsService.getSectionsByEntity(idEntity)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setSection({ data: data.data }));
        } else if (
          data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS &&
          data.data.length > 0
        ) {
          dispatch(setSection({ data: [] }));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(setSection({ isLoadingSection: false }));
      });
  };
};


export const DynamicFieldsAction = {
  currentAction,
  setCurrentDynamicFields,
  setAdvancedFilter,
  removeFilterValues,
  getDynamicFields,
  getDataMaster,
  editDynamicFields,
  generateReport,
  save,
  deleteById,
  setGetDataMaster,
  getSection,
}

