import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { useDispatch, useSelector } from 'react-redux';
import useModalAction from 'features/survey/hooks/Survey/useModalAction';
import EstructuraCarpetas from './EstructuraCarpetas';
import ShareSurvey from './ShareSurvey';
import { SurveyAction } from 'features/survey/store/actions/SurveyAction';
import { useParams } from 'react-router-dom';
// import { ConfirmDialog } from 'primereact/confirmdialog'

const ModalActions = ({displayModal, setDisplayModal, actionSelected, setActionSelected, toast, setValueForm, surveySelected}) => {
  const dispatch = useDispatch()
  const params = useParams();
  const [selectedPath, setSelectedPath] = useState(null)
  const [selectedContact, setSelectedContact] = useState([])
  // console.log(selectedContact)
  const link = useSelector((state) => state.surveyReducer.link);
  const currentPath = useSelector(state => state.surveyReducer.currentPath);
  const { isSent, isSending } = useSelector((state) => state.surveyReducer.contacts);
  // const [visible, setVisible] = useState(false);
  const { origin: baseURL } = window.location;
  const { control, errors, existError, valuesFields, setValue, handleSubmit, reset } = useModalAction()

  const titleAction = ( ) => {
    if (actionSelected) {
      if (actionSelected === 'locate') return 'Seleccionar ubicación'
      if (actionSelected === 'share') return 'Compartir encuesta'
    }
    return 'Documento'
  }

  useEffect(() => {
    if (actionSelected === 'share' ) {
      setValue('link', link ? `${baseURL}/#/encuestas/encuesta/r/${link}` : null)
    }
  }, [link, actionSelected, setValue])
  
  useEffect(() => {
    if (isSent) {
       onHide()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSent])

  const onHide = () => {
    setDisplayModal(false)
    setActionSelected(null)
    setSelectedContact([])
    setValue('targetPath', '')
    setValue('newName', '')
    reset()
    // if (data) {
    //   dispatch(DocumentLibraryAction.resetAfterSaved());
    // }
  }

  const redirect = () => {
    onHide()
  }

  // const reject = () => {
  //   setVisible(false);
  // };

  const onCopyLink = () => {
		navigator.clipboard.writeText(valuesFields.link)
    toast.current.show({severity:'success', summary: 'Gestión de Encuestas', detail:'Enlace copiado exitosamente', life: 3000});
	}

  const getNameAndEmail = (contacts) => {
    const newContacts = contacts.map((contact) => {
      const modelContact = {
        fullName: contact.name,
        email: contact.emailAddress
      }
      return modelContact
    })
    return newContacts
  }
  
  const onSubmit = (values) => {
    if (actionSelected === 'locate') {
      if (selectedPath === 'error') return
      setValueForm('location', currentPath)
      redirect()
    }
    if (actionSelected === 'share') {
      if (selectedPath === 'error') return
      const model = {
        surveyId: params.id ? Number(params.id) : surveySelected.id,
        recipients: getNameAndEmail(selectedContact)
      }

      // console.log(model)
      dispatch(SurveyAction.shareSendByEmail( model ))
    }
  };

  return (
    <Dialog header={titleAction} visible={displayModal} className={`w-4/5 md:w-3/5 xl:${actionSelected === 'share' ? 'w-1/2' : 'w-2/5'} `} onHide={onHide}>
      <form 
        className="form-custom p-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <hr />
        <div className="grid grid-cols-1 gap-2">
          {
            (actionSelected === 'locate' ) ?
            (
              <div className='flex flex-col mt-3'>
                <p>Elegir la ubicación de la encuesta:</p>
                <span className="p-float-label w-full h-64 overflow-y-auto">
                  <EstructuraCarpetas setSelectedPath={setSelectedPath} />
                </span>
              </div>
            ) : null
          }
          {
            (actionSelected === 'share') ?
            (
              <ShareSurvey 
                control={control} 
                selectedContact={selectedContact} 
                setSelectedContact={setSelectedContact} 
                onCopyLink={onCopyLink} 
                actionSelected={actionSelected}
                valuesFields={valuesFields}
                surveyId={params.id ? Number(params.id) : surveySelected.id}
                toast={toast}
              />
            ) : null
          }
        </div>
        {
        (existError) && <div className='message error'>
            <ul>
                {errors.targetPath && (actionSelected === 'copy' ) ? (
                    <>
                        <li className="p-error">{errors.targetPath.message}</li>
                    </>
                ) : null}
                {errors.newName && actionSelected === 'rename' ? (
                    <>
                        <li className="p-error">{errors.newName.message}</li>
                    </>
                ) : null}
            </ul>
        </div>
        }
        {
          (actionSelected === 'locate' ) ? (
            <div className="flex justify-end gap-4 mt-3">
              <Button
                  icon="pi pi-times"
                  type="button"
                  label="Cancelar"
                  onClick={redirect}
                  className="sig-button sig-secondary mt-4"
              />
              <Button
                  icon="pi pi-save"
                  type="submit"
                  disabled={existError ? true : false}
                  label="Guardar"
                  className="sig-button sig-primary mt-4"
              />
            </div>
          ) : null
        }
        {
          (actionSelected === 'share' ) ? (
            <div className="flex justify-end gap-4 mt-3">
              <Button
                  icon="pi pi-envelope"
                  type="submit"
                  disabled={existError || isSending ? true : false}
                  label={`Enviar${selectedContact ? ` (${selectedContact.length})` : ''}`}
                  className="sig-button sig-primary mt-4"
              />
            </div>
          ) : null
        }
        
      </form>

      {/* <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={redirect}
          reject={reject}
      /> */}
    </Dialog>
  )
}

export default ModalActions