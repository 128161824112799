import { apiService } from "core/services/ApiService";

//* Endpoints Implementadas

const searchProjectExpenses = (criterio) => {
  return apiService.Post("customer/api/ProjectExpense/Search", criterio);
}

const generateReport = (payload) => {
  return apiService.Post("customer/api/ProjectExpense/GenerateReport", payload, {
    responseType: 'blob',
  })
}

const getDataMaster = () => {
  return apiService.Post("customer/api/ProjectExpense/GetDataMaster");
}

const deleteProjectExpense = (surveyId) => {
  return apiService.Delete(`customer/api/ProjectExpense/Delete?id=${surveyId}`);
}


const saveProjectExpense = (data) => {
  return apiService.Post("customer/api/ProjectExpense/Save", data);
}

const getProjectExpenseById = (id) => {
  return apiService.Get("customer/api/ProjectExpense/GetProjectExpenseById?id=" + id);
}

const addComment = (payload) => {
  return apiService.Post(`customer/api/Task/AddComment`, payload);
}

const deleteComment = (id) => {
  return apiService.Delete(`customer/api/Task/DeleteComment?id=${id}`);
}

const getCommentsByProjectExpenseId = (id) => {
  return apiService.Get(`customer/api/ProjectExpense/GetCommentsByProjectExpenseId?id=${id}`);
}



//* Endpoints sin Implementar
// const addAttachment = (payload) => {
//   return apiService.Post(`customer/api/ProjectExpense/AddAttachment`, payload);
// }

// const deleteAttachment = (id) => {
//   return apiService.Delete(`customer/api/ProjectExpense/DeleteAttachment?id=${id}`);
// }

// const getAttachmentsByEmployeeId = (id) => {
//   return apiService.Get(`customer/api/ProjectExpense/GetAttachmentsByEmployeeId?id=${id}`);
// }



export const ProjectExpensesService = {
  //* Endpoints Implementadas
  searchProjectExpenses,
  generateReport,
  getDataMaster,
  deleteProjectExpense,
  saveProjectExpense,
  getProjectExpenseById,
  addComment,
  deleteComment,
  getCommentsByProjectExpenseId,
  
  //* Endpoints sin Implementar
  
  // addAttachment,
  // deleteAttachment,
  // getAttachmentsByEmployeeId,
}