import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Link } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { Fragment } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import { CoreConstants } from 'core/commons/CoreConstants';
import { Menu } from 'primereact/menu';
import { Paginator } from 'primereact/paginator';
import { useSelector, useDispatch } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { HomeActions } from 'shared/store/actions/HomeAction';
import { TaskAction } from 'features/customers/store/actions/TaskAction';
import { Skeleton } from 'primereact/skeleton';
import { useHistory } from "react-router";
import { DocumentLibraryAction } from 'features/document/store/actions/DocumentLibraryAction';

const TableComponent = ({selectedSumary, setShowTaskModal, isLoading, setDisplayModal, setMode, setWorkFlowDocId}) => {
    const history = useHistory();
    const advancedFilter = useSelector((state) => state.homeReducer.advancedFilter);
    const dispatch = useDispatch();
    const typeViews = [
        { icon: 'pi pi-list', value: CoreConstants.TypeView.LIST },
        { icon: 'pi pi-microsoft', value: CoreConstants.TypeView.CANVA }
    ];
    const { data } = useSelector((state) => state.homeReducer.dataHome);

    const { roles } = useSelector(state => state.authReducer.user);
    const isAdmin = roles.some( rol => rol.codigo === CoreConstants.Roles.ADMIN )
    const querys = new URLSearchParams(useLocation().search)
    const taskId = querys.get("taskId");   
    
    const { isDeleted } = useSelector((state) => state.homeReducer.task);
    const getCreationDate = (rowData) => { return moment(rowData.fromDate).format(CoreConstants.Format.LOCALE); }
    const getEndDate = (rowData) => { return moment(rowData.toDate).format(CoreConstants.Format.LOCALE); }

    const styles ={
        Completado:{
            border:"1px solid #8CC9A6",
            padding:"0rem 0.50rem",
            borderRadius:"5px"
        },
        Pendiente:{
            border:"1px solid #F6697C",
            padding:"0rem 0.50rem",
            borderRadius:"5px"
        }
    }

    const onBodyStatusTemplate  = (rowData) =>{
        return <h1 className='text-center' style={styles[rowData.status]}>{rowData.status}</h1>
    }

    const columnsAdmin = [
        { field: 'title', header: 'Nombre' },
        { field: 'sourceDocumentName', header: 'Origen' },
        { field: 'customerName', header: 'Cliente' },
        { field: 'responsible', header: 'Responsable' },
        { field: 'fromDate', header: 'Fec. Inicio', body: getCreationDate},
        { field: 'toDate', header: 'Fec. Fin', body: getEndDate },
        { field: 'status', header: 'Estado', body: onBodyStatusTemplate },
    ];

    let columns

    if( isAdmin ){
      columns = columnsAdmin
    } else {
      columns = [
        { field: 'title', header: 'Nombre' },
        { field: 'sourceDocumentName', header: 'Origen' },
        { field: 'customerName', header: 'Cliente' },
        { field: 'fromDate', header: 'Fec. Inicio', body: getCreationDate},
        { field: 'toDate', header: 'Fec. Fin', body: getEndDate },
        { field: 'status', header: 'Estado', body: onBodyStatusTemplate },
      ];
    }

    const [selectedColumns, setSelectedColumns] = useState(columns);
    const actionsHeader = useRef(null);
    const menu = useRef(null);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [showConfirmDialog, setshowConfirmDialog] = useState(false);
    const [TaskSelected, setTaskSelected] = useState(null);
    const [isFirstLoading, setFirstLoading] = useState(true);

    useEffect(() => {
        if (isFirstLoading) {
            tryParseFilter();
            setFirstLoading(false);
            setSortField("title");
            setSortOrder(1);
            dispatch(HomeActions.search());
        }
    }, []);

    useEffect(() => {
        if (isDeleted) {
            setshowConfirmDialog(false);
            dispatch(HomeActions.resetAfterSaved());
            dispatch(HomeActions.search());
        }
    }, [isDeleted])



    const generatePath = (sourceRecordId, sourceDocumentCode) => {

      const hashPath = {
        [CoreConstants.Task.Source.CUSTOMER]: `/cliente/empresas/editar/${sourceRecordId}/tareas`,
        [CoreConstants.Task.Source.BUSINESS]: `/cliente/empresas/editar/${sourceRecordId}/negocios`,
        [CoreConstants.Task.Source.PROJECT]: `/proyecto/vista-proyectos/proyecto/${sourceRecordId}`,
      }

      return hashPath[sourceDocumentCode]
    }

    const columnComponents = selectedColumns.map(col => {
        // if(col.field === "title"){
        //   return (
        //     <Column
        //       key={col.field}
        //       field={col.field}
        //       header={col.header}
        //       body={(rowData) => {
        //         const pathname = generatePath(rowData.sourceRecordId, rowData.sourceDocumentCode) || null
        //         return pathname ? <Link className="underline text-blue-700" to={{ pathname }} target="_blank">{rowData.title}</Link> : rowData.title
        //       }}
        //       sortable
        //     />
        //   );
        // }

        if (col.body)
            return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable />;

        return <Column key={col.field} field={col.field} header={col.header} sortable />;
    });


    const onSort = (e) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);

        advancedFilter.columnOrder = e.sortField;
        advancedFilter.order = e.sortOrder === 1 ? 'asc' : 'desc';
        advancedFilter.page = 1;
        dispatch(HomeActions.updateFilter(advancedFilter));
        dispatch(HomeActions.search());
    }

    const actionHeaderTemplate = (rowData) => {
        return <i
            key="action"
            className="pi pi-ellipsis-v cursor-pointer"
            onClick={(e) => actionsHeader.current.toggle(e)}></i>;
    }

    const actionBodyTemplate = (rowData) => {
        return <i
            key={rowData.id}
            className="pi pi-ellipsis-v cursor-pointer"
            onClick={(event) => {
                setTaskSelected(rowData);
                menu.current.toggle(event)
            }
            }></i>
    }

    const tryParseFilter = () => {
        try {
            const filters = JSON.parse(localStorage.getItem("Home-filter"));
            if (filters)
                setSelectedColumns(filters);
            else
                setSelectedColumns(columns);
        } catch (error) {
            localStorage.removeItem('Home-filter');
        }
    }

    const onColumnToggle = (e, item) => {
        let _selectedColumns = [...selectedColumns];

        if (e.checked)
            _selectedColumns.push(item);
        else
            _selectedColumns = _selectedColumns.filter(c => { return c.field !== e.value });

        setSelectedColumns(_selectedColumns);
        localStorage.setItem("taskHome-filter", JSON.stringify(_selectedColumns));
        ;
    }

    const getColumns = () => {
        let array = [];
        columns.map((item, index) => {
            const column = <div key={item.field} className="p-field-checkbox py-1">
                <Checkbox
                    inputId={item.field}
                    name="column"
                    value={item.field}
                    onChange={(e) => onColumnToggle(e, item)}
                    checked={selectedColumns.some((x) => x.field === item.field)}
                />
                <label className="ml-1" htmlFor={item.field}>{item.header}</label>
            </div>
            array.push(column);
        });
        return array;
    };

    const items = [
        {
            label: 'Ver',
            icon: 'pi pi-eye',
            command: (e) => {
                if(TaskSelected.sourceWorkflowDocumentCode === 'WPRC'){
                    history.push(`/mapa-procesos/editar-proceso/${TaskSelected.workflowDocumentId}/ShowWf?modeview=readonly`)
                }
                if(TaskSelected.sourceWorkflowDocumentCode === 'WSHT'){
                    history.push(`/mapa-procesos/fichaProcesos/ShowWf?modeview=readonly&&id=${TaskSelected.workflowDocumentId}`)
                }
                if(TaskSelected.sourceWorkflowDocumentCode === 'WEST'){
                    history.push(`/rrhh/personas/ficha-persona/ShowWf?modeview=readonly&&id=${TaskSelected.workflowDocumentId}`)
                }
                if(TaskSelected.sourceWorkflowDocumentCode === 'WDOC'){
                    dispatch(DocumentLibraryAction.getDocumentById(TaskSelected.workflowDocumentId));
                    setMode('view')
                    setDisplayModal(true)
                    return
                }
                if (TaskSelected.id) {
                    setShowTaskModal({showModal: false, selectedTask: true, viewMode: true})
                    dispatch(TaskAction.getDataMaster());
                    dispatch(TaskAction.editTask(TaskSelected.id));
                }
            }
        },
        {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: (e) => {
                if(TaskSelected.sourceWorkflowDocumentCode === 'WPRC'){
                    history.push(`/mapa-procesos/editar-proceso/${TaskSelected.workflowDocumentId}/ShowWf`)
                }
                if(TaskSelected.sourceWorkflowDocumentCode === 'WSHT'){
                    history.push(`/mapa-procesos/fichaProcesos/ShowWf?id=${TaskSelected.workflowDocumentId}`)
                }
                if(TaskSelected.sourceWorkflowDocumentCode === 'WEST'){
                    history.push(`/rrhh/personas/ficha-persona/ShowWf?id=${TaskSelected.workflowDocumentId}`)
                }
                if(TaskSelected.sourceWorkflowDocumentCode === 'WDOC'){
                    dispatch(DocumentLibraryAction.getDocumentById(TaskSelected.workflowDocumentId));
                    setWorkFlowDocId(TaskSelected.sourceWorkflowDocumentCode)
                    setMode('aprovedOrReject')
                    setDisplayModal(true)
                    return
                }
                if (TaskSelected.id) {
                    setShowTaskModal({showModal: false, selectedTask: true, viewMode: false})
                    dispatch(TaskAction.getDataMaster());
                    dispatch(TaskAction.editTask(TaskSelected.id));
                }
            }
        }
    ];

    const confirmDelete = () => {
        // dispatch(HomeActions.deleteHome(TaskSelected.id));
    }

    const cancelDelete = () => {
        setshowConfirmDialog(false);
    }
    const [rows, setRows] = useState(10)

    const onPageChange = (e) => {

        setRows(e.rows);
        advancedFilter.page = e.page + 1;
        advancedFilter.pageSize = e.rows;
        dispatch(HomeActions.updateFilter(advancedFilter));
        dispatch(HomeActions.search());
    }

    return (
        <Fragment>

            <div className="mt-3 " style={{ maxWidth: (window.innerWidth - 100) + 'px' }}>                    
                { isLoading ? 
                <Skeleton height='20rem'/>
                :
                <DataTable
                    id="table-home"
                    value={data?.results}
                    stripedRows
                    responsiveLayout="scroll"
                    size="small"
                    sortField={sortField}
                    sortOrder={sortOrder}
                    emptyMessage="No se encontraron tareas"
                    rowClassName={(data)=>{
                      return{
                        "bg-column-selected": data.id === Number(taskId)
                      }
                    }}
                    onSort={(e) => onSort(e)}

                >
                    {columnComponents}
                    <Column header={actionHeaderTemplate} body={actionBodyTemplate} ></Column>
                </DataTable>}
            </div>

            <Paginator
                key={"pag-1"}
                className="paginator-custom mt-4"
                rows={rows} 
                first={(data?.currentPage - 1) * rows}
                rowsPerPageOptions={[10,20,30]}
                totalRecords={data?.rowCount}
                onPageChange={onPageChange}
            ></Paginator>

            <OverlayPanel ref={actionsHeader} showCloseIcon style={{ width: '300px' }}>{getColumns()}</OverlayPanel>

            <Menu model={items} popup ref={menu} id="popup_menu" />
            <ConfirmDialog
                visible={showConfirmDialog}
                onHide={cancelDelete}
                message="¿Está seguro que desea eliminar el registro?"
                header="Eliminar"
                icon="pi pi-trash" 
                accept={confirmDelete}
                reject={cancelDelete}
            />
        </Fragment >
    );
};

export default TableComponent;