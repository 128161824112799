export default class AppSettings {
  static get BASE_API_URL() {
    // STAGING
    return 'https://sig-gateway-service.azurewebsites.net/';
    
    // CLOUD DEVELOPMENT
    // return 'https://calidar-gateway-dev.azurewebsites.net/';
    
    // DEVELOPMENT
    // return 'http://localhost:5555/';
  }
  static get APP_NAME() {
    // STAGING
    //return 'https://sig-gateway-service.azurewebsites.net/';
    
    // CLOUD DEVELOPMENT
    return 'Manglar Software';
    
    // DEVELOPMENT
    // return 'http://localhost:5555/';
  }
}
