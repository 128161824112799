import { CoreConstants } from "core/commons/CoreConstants";
import moment from "moment";

const getCreationDate = (rowData) => { return moment(rowData.creationDate).format(CoreConstants.Format.LOCALE); }

const columns = [
  { field: 'name', header: 'Cargo' },
  { field: 'areaName', header: 'Departamento' },
  { field: 'quantityRequiredEmployees', header: 'N° Empleados Requeridos' },
  { field: 'quantityActiveEmployees', header: 'N° Empleados Activos' },
  { field: 'parentPositionName', header: 'Cargo al que reporta' },
  { field: 'positionTypeName', header: 'Modalidad' },
  { field: 'creationDate', header: 'Fecha de Creación', body: getCreationDate },
];

export const PositionsTableData = {
  columns,
};
