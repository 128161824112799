import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RiskManagementAction } from '../store/actions/RiskManagementAction';

const Impacto = ({control, getValues, valuesFields}) => {
	const { impact } = useSelector(state => state.riskManagementReducer.data)
	const dispatch = useDispatch()

	return (
		<div className='grid grid-cols-3 gap-2'>
			<div className='col-span-2'>
				<strong>Nombre</strong>
			</div>
			<div>
				<strong>Valor</strong>
			</div>
			{
				impact.map((item)=>
				<Fragment key={item.id}>
					<div className='col-span-2'>
						<Controller 
							control={control}
							name={`impact-name-${item.id}`}
							defaultValue={item.name}
							rules={{
								required: 'El Nombre de Impacto es requerido',
								validate: {
									isNotRepeat: value => {
										const values = getValues()
										const impacts = Object.keys(values).filter( key => key.slice(0, 11) === 'impact-name' && key !== `impact-name-${item.id}`).map( item => values[item])
										return !impacts.includes(value) || "No se deben repetir los nombres de los impactos"						
									}
								}
							}}
							render={({
								field: { onChange, onBlur, value, name, ref },
								fieldState: { invalid, isTouched, isDirty, error },
							}) => (
							<InputText
								ref={ref}
								value={value}
								onChange={(e)=> {dispatch(RiskManagementAction.changeImpactName(e.target.value, item.id)); onChange(e)}}
								className={error && 'p-invalid'}
							/>
						)}
						/>
					</div>
					<div>
					<Controller 
						control={control}
						name={`impact-value-${item.id}`}
						defaultValue={item.value}
						rules={{
							required: 'El Valor de Impacto es requerido',
							validate: {
								isNotRepeat: value => {
									const values = getValues()
									const impactsValues = Object.keys(values).filter( key => key.slice(0, 12) === 'impact-value' && key !== `impact-value-${item.id}`).map( item => values[item])
									return !impactsValues.includes(value) || "No se deben repetir los valores de los impactos"						
									}
								}
						}}
						render={({
							field: { onChange, onBlur, value, name, ref },
							fieldState: { invalid, isTouched, isDirty, error },
							}) => (
								<InputNumber
									value={value}
									onValueChange={(e) => {dispatch(RiskManagementAction.changeImpactValue(e.value, item.id)); onChange(e.value)}} 
									mode="decimal"
									locale="en-US"
									useGrouping={false}
									maxFractionDigits={(valuesFields.position && Number(valuesFields.position)) || 0} 
									className={error ? 'p-invalid w-16' : 'w-16'}
								/>
							)}
					/>
					</div>
				</Fragment>
				)
			}
		</div>
	);
};

export default Impacto;