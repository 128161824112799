import React, { Fragment, useEffect, useState } from 'react'
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import FilterComponent from 'features/document/components/DocumentLibrary/FilterComponent';
import TableComponent from 'features/document/components/DocumentLibrary/TableComponent';
import SidebarMenu from 'features/document/components/DocumentLibrary/SidebarMenu';
import ModalDocumentLibrary from 'features/document/components/DocumentLibrary/ModalDocumentLibrary';
import ModalActionFile from 'features/document/components/DocumentLibrary/ModalActionFile';
import AdvancedFilterComponent from 'features/document/components/DocumentLibrary/AdvacedFilterComponents';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentLibraryAction } from 'features/document/store/actions/DocumentLibraryAction';
import { useClaimDocumentLibrary } from 'features/document/hooks/useClaimDocumentLibrary';
import { Button } from 'primereact/button';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import './SearchDocumentLibraryPage.scss'
import { toggleSidebar } from 'features/configuration/store/actions/ToggleSidebarAction';

const excludedPath = ['Recientes', 'Vigentes', 'Mis Documentos', 'Papelera', '']

const SearchDocumentLibraryPage = () => {
  
  const dispatch = useDispatch();
  // const isOpen = useSelector((state) => state.toggleSidebarReducer.isOpen);
  const { updateTitle } = useSetTitlePage();
  const [selectedNode, setSelectedNode] = useState('');
  const [selectedFolder, setSelectedFolder] = useState('');
  const [displayModal, setDisplayModal] = useState(false);
  const [displayModalAction, setDisplayModalAction] = useState(false)
  const [documentSelected, setDocumentSelected] = useState(null)
  const [actionSelected, setActionSelected] = useState(null)
  const [mode, setmode] = useState(null)

  // console.log(selectedNode)

  useEffect(() => {
    dispatch(toggleSidebar.toggle(true));
  }, []);
  // const [width, setWidth] = useState(24);
  // const handleMouseDown = (e) => {
  //   document.addEventListener('mousemove', handleMouseMove);
  //   document.addEventListener('mouseup', handleMouseUp);
  // };

  // const handleMouseMove = (e) => {
  //   const newWidth = (e.clientX - (isOpen ? 56 : 218)) / window.innerWidth * 100;
  //   setWidth(newWidth);
  // };
  
  // const handleMouseUp = () => {
  //   document.removeEventListener('mousemove', handleMouseMove);
  //   document.removeEventListener('mouseup', handleMouseUp);
  // };

  useEffect(() => {
      updateTitle({
          title: 'Gestión Documental',
          subtitle: 'Biblioteca de Documentos',
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isSearch, isDownload } = useClaimDocumentLibrary();

  useEffect(() => {
    dispatch(DocumentLibraryAction.getDataMaster());
  },[])

  useEffect(() => {
    dispatch(DocumentLibraryAction.isStorageEnabled());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <Fragment>
          {
                (isSearch||isDownload)&&(
                    <FilterComponent />
                )
          }
          <Button 
            className={`sig-button sig-dark mt-3 ${excludedPath.includes(selectedNode) ? 'invisible' : 'visible'}`}
            // className={`sig-button sig-dark mt-3 ${selectedNode === '' ? 'hidden' : ''}`}
            label='Nuevo'
            icon='pi pi-plus'
            // disabled={selectedNode === '' ? true : false}
            onClick={()=> {
              setmode('new')
              setDisplayModal(true)
            }}
          />

          {/* <div className="resizable-line">
            <div className="resizable-line-left" style={{ width: `${width}%`}}>
              <SidebarMenu 
                setSelectedNode={setSelectedNode} 
                setDisplayModal={setDisplayModal} 
                selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} 
                setMode={setmode}
              />
            </div>
            <div className="resizable-line-divider" onMouseDown={handleMouseDown} />
            <div className="resizable-line-right " style={{ width: `${100 - width}%`}}>
              <TableComponent 
                selectedNode={selectedNode} setSelectedNode={setSelectedNode}
                documentSelected={documentSelected} setDocumentSelected={setDocumentSelected} 
                setDisplayModal={setDisplayModal} 
                setDisplayModalAction={setDisplayModalAction}
                setActionSelected={setActionSelected}
                setSelectedFolder={setSelectedFolder}
                setmode={setmode}
              />
            </div>
          </div> */}
          <Splitter  className="mt-2 flex gap-x-3">
              <SplitterPanel className="" size={24}>
                <SidebarMenu 
                  setSelectedNode={setSelectedNode} 
                  setDisplayModal={setDisplayModal} 
                  selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} 
                  setMode={setmode}
                />
              </SplitterPanel>
              <SplitterPanel className="" size={76}>
                <TableComponent 
                  selectedNode={selectedNode} setSelectedNode={setSelectedNode}
                  documentSelected={documentSelected} setDocumentSelected={setDocumentSelected} 
                  setDisplayModal={setDisplayModal} 
                  setDisplayModalAction={setDisplayModalAction}
                  setActionSelected={setActionSelected}
                  setSelectedFolder={setSelectedFolder}
                  setmode={setmode}
                />
              </SplitterPanel>
          </Splitter>
          {/* <div className='grid grid-cols-12 gap-1 h-96 mt-2'>
            <div className='col-span-5 sm:col-span-3 2xl:col-span-2 flex flex-col py-2 px-3 border-r border-gray-300'>
              <SidebarMenu 
                setSelectedNode={setSelectedNode} 
                setDisplayModal={setDisplayModal} 
                selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} 
                setMode={setmode}
              />
            </div>
            <div className='col-span-7 sm:col-span-9 2xl:col-span-10 px-3'>
              <TableComponent 
                selectedNode={selectedNode} setSelectedNode={setSelectedNode}
                documentSelected={documentSelected} setDocumentSelected={setDocumentSelected} 
                setDisplayModal={setDisplayModal} 
                setDisplayModalAction={setDisplayModalAction}
                setActionSelected={setActionSelected}
                setSelectedFolder={setSelectedFolder}
                setmode={setmode}
              />
            </div>
          </div> */}

          <ModalDocumentLibrary 
            displayModal={displayModal}  setDisplayModal={setDisplayModal} 
            selectedNode={selectedNode} 
            actionSelected={actionSelected} setActionSelected={setActionSelected}
            mode={mode}
          />
          <ModalActionFile 
            displayModal={displayModalAction}  setDisplayModal={setDisplayModalAction} 
            documentSelected={documentSelected} setDocumentSelected={setDocumentSelected}  
            actionSelected={actionSelected} setActionSelected={setActionSelected}
          />
        <AdvancedFilterComponent /> 
    </Fragment>
  )
}

export default SearchDocumentLibraryPage