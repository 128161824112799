import { Fragment , useState } from "react"
import { Panel } from "primereact/panel"
import { InputText } from "primereact/inputtext"
import PinerComponent from "shared/components/Piner/PinerComponent"
import { Button } from "primereact/button"
import useFilter from "features/maintenance/hooks/Sites/useFilter"
import useStatus from "features/maintenance/hooks/Sites/useStatus";
import SitesModalFilter from "./SitesModalFilter"
import moment from "moment"
import { CoreConstants } from "core/commons/CoreConstants"
import { useClaimSites } from "features/maintenance/hooks/useClaimSites"
const SitesFilter = () =>{

  const [payLoad, setpayLoad] = useState({name:""});
  const {
    openModal,
    filterSites,
    loading,
    values,
    showPiners,
    removePiner,
    loadingReport,
    generateReport,
  } = useFilter()

  const {
    loadingDataMaster
  } = useStatus()

  const removeName = (field) =>{
    setpayLoad({name:""})
    removePiner(field)
  }

  const { isSearch, isDownload } = useClaimSites()

  const submit = (e) => {
    e.preventDefault();
    filterSites(payLoad)
  }

  return(
    <Fragment>
      <Panel header={`${isSearch?"FILTRO POR":"DESCARGA"}`} toggleable>
        <form className="filter-components" onSubmit={submit}>
          <div className={`${isSearch?'visible':'invisible'}`}>
            <span className="p-float-label p-input-icon-left w-full">
              <i className="pi pi-search" />
              <InputText
                id="input-search"
                name="name"
                value={payLoad.name}
                onChange={e => setpayLoad({name:e.target.value})}
              />
              <label htmlFor="input-search">
                Buscar por nombre de Sede
              </label>
            </span>
            {showPiners() && (
              <div className="filter-piners mt-3">
                {values.map(({ value, field }, index) => {
                  return (
                  <PinerComponent
                    name={(field==='fromDate')?value:value}
                    field={field}
                    removePiner={e => removeName(field)}
                    key={index}
                  />)}
                )}
              </div>
            )}
          </div>
          <div className="filter-users-action">
            <div className="flex gap-3 w-full">
              {
                isSearch&&(
                  <Button
                    icon="pi pi-search"
                    type="submit"
                    label={loadingDataMaster || loading || loadingReport ? "Buscando" : "Buscar"}
                    // onClick={e => filterSites(payLoad)}
                    disabled={loadingDataMaster || loading || loadingReport}
                    className="sig-button sig-primary pr-2 mr-"
                  />
                )
              }
              {
                isDownload&&(
                  <Button
                    icon="pi pi-download"
                    type="button"
                    onClick={generateReport}
                    loading={loadingDataMaster || loading || loadingReport}
                    label="Descargar"
                    className="sig-button sig-success"
                  />
                )
              }
            </div>
            <div className="flex mt-3">
              {
                isSearch&&(
                  <Button
                    type="button"
                    label="Búsqueda avanzada"
                    className="sig-button  sig-secondary w-full"
                    onClick={openModal}
                  />
                )
              }
            </div>
          </div>
        </form>
      </Panel>
      <SitesModalFilter/>
    </Fragment>
  )
}


export default SitesFilter