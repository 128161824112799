import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

export const useClaimOccupation = () => {

  const menu = useSelector((state) => state.authReducer.user.menuPrincipal);
  const pathName = useLocation()?.pathname;
  const [isSearch, setSearch] = useState(false);
  const [isDownload, setDownload] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isCreate, setCreate] = useState(false);
  const [isView, setView] = useState(false);

  useEffect(() => {
    search();
    download();
    remove();
    edit();
    create();
    view();
  }, [menu]);

  const search = () => {
    const actionCode = 'MANAGEMENT_JOB-POSITION_SEARCH';
    const optionCode = "OCUP";
    setSearch(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const download = () => {
    const actionCode = 'MANAGEMENT_JOB-POSITION_DOWNLOAD';
    const optionCode = "OCUP";
    setDownload(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  const remove = () => {
    const actionCode = 'MANAGEMENT_JOB-POSITION_DELETE';
    const optionCode = "OCUP";
    setDelete(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  const edit = () => {
    const actionCode = 'MANAGEMENT_JOB-POSITION_EDIT';
    const optionCode = "OCUP";
    setEdit(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  const create = () => {
    const actionCode = 'MANAGEMENT_JOB-POSITION_CREATE';
    const optionCode = "OCUP";
    setCreate(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  const view = () => {
    const actionCode = 'MANAGEMENT_JOB-POSITION_VIEW';
    const optionCode = "OCUP";
    setView(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  return {
    isSearch,
    isDownload,
    isCreate,
    isDelete,
    isEdit,
    isView,
  }
}