import { Controller } from "react-hook-form";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Slider } from 'primereact/slider';
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Skeleton } from "primereact/skeleton";
import { InputNumber } from "primereact/inputnumber";
// import { TableCommentsComponent } from "features/customers/components/comments/TableCommentsComponent";
import { useTask } from "features/customers/hooks/tasks/useTask";
import Attachments from "shared/components/attachments/Attachments";
import Message from "shared/components/messages/Message";
import { CoreConstants } from "core/commons/CoreConstants";
import { Editor } from "primereact/editor";
import HeaderEditor from 'shared/components/header-editor/HeaderEditor';
import { Badge } from "primereact/badge";
import './FormComponent.scss';
import { TableCommentsComponent } from "shared/components/comments/TableCommentsComponent";
import { useDispatch, useSelector } from "react-redux";
import { TaskAction } from "features/customers/store/actions/TaskAction";
import { DynamicFieldList } from "shared/components/dynamicFields/DynamicFieldList";
import { useHistory } from "react-router-dom";
import { AppActions } from "shared/store/actions/AppAction";

export const FormTaskComponent = (props) => {

  const { disableInputs = false } = props

  const errorsArray = [];
  const {
    dataMaster,
    handleSubmit,
    isLoadingTask,
    isLoadingDataMaster,
    onSubmit,
    control,
    currentTask,
    errors,
    cancelAccept,
    cancelDenied,
    setShowModalCancel,
    confirmComplete,
    cancelComplete,
    isNewTask,
    showModalComplete,
    setShowModalComplete,
    showModalCancel,
    sourceDocumentCode,
    showModalOpen, 
    setShowModalOpen,
    confirmOpen,
    cancelOpen,
    valuesFields,
    setValue,
    dynamicFieldsDatosGenerales,
    dynamicFieldsInformacionTarea,
    searchHomePage,
    sourceName,
    taskId,
    sourceDocumentId,
    editTask,
  } = useTask(props);

  const { data: commentsData, isLoading: isLoadingComments } = useSelector(state => state.tasksReducer.comments);
  const { isDeleted: isDeletedComment, isSaved: isSavedComment  } = useSelector(state => state.tasksReducer.comment);
  const { roles } = useSelector(state => state.authReducer.user);
  const isAdmin = roles.some( rol => rol.codigo === CoreConstants.Roles.ADMIN )
  const history = useHistory()
  const dispatch = useDispatch()
  
  const header = HeaderEditor();

  const hashPath = {
    [CoreConstants.Task.Source.CUSTOMER]: `/cliente/empresas/editar/${sourceDocumentId}/tareas`,
    [CoreConstants.Task.Source.BUSINESS]: `/cliente/empresas/editar/${editTask?.data?.business?.customerId}/negocios/editar/${editTask?.data?.business?.id}`,
    [CoreConstants.Task.Source.PROJECT]: `/proyecto/vista-proyectos/proyecto/${editTask?.data?.projectDeliverable?.projectId}`,
  }

  const headerTemplate = (options) => {
    const className = `${options.className} flex justify-between`;

    return (
        <div className={className}>
            <div className="flex align-items-center gap-2">
              INFORMACIÓN DE LA TAREA
            </div>
            {
              (searchHomePage && !isNewTask && (sourceDocumentCode === CoreConstants.Task.Source.CUSTOMER || sourceDocumentCode === CoreConstants.Task.Source.BUSINESS || sourceDocumentCode === CoreConstants.Task.Source.PROJECT)) &&
              <div>
                <Button
                  icon="pi pi-file"
                  type="button"
                  onClick={() => {
                    if(sourceDocumentCode === CoreConstants.Task.Source.CUSTOMER){
                      dispatch(AppActions.setParameters({taskName: editTask?.data?.title}))
                    }
                    if(sourceDocumentCode === CoreConstants.Task.Source.PROJECT){
                      dispatch(AppActions.setParameters({deliverableId: sourceDocumentId}))
                    }
                    history.push(hashPath[sourceDocumentCode])
                  }}
                  loading={isLoadingTask}
                  label={`Ver ${sourceName}`}
                  className="sig-button sig-primary"
                />
                {options.togglerElement}
              </div>
            }
        </div>
    );
  };

  return (
    <>
      <div className="bg-white">
        <form>
          <Panel headerTemplate={headerTemplate} header="INFORMACIÓN DE LA TAREA">
              <div className={`grid mt-2 sm:grid-cols-1 md:grid-cols-${isNewTask ? '1' : '2'} ${isNewTask ? '' : 'gap-2'}`}>
                {!isLoadingTask || !isLoadingDataMaster ? (
                  <Controller
                    name="Title"
                    control={control}
                    rules={{
                      required: "El Título es obligatorio ",
                      maxLength: {
                        message:
                          "El título de la tarea no debe exceder los 100 caracteres",
                        value: 100,
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full mt-1 pr-2">
                        <InputText
                          type="text"
                          id="Title"
                          maxLength={100}
                          className={`w-full ${
                            errors.Title?.type === "required"
                              ? " p-invalid"
                              : ""
                          }`}
                          onChange={onChange}
                          readOnly={(!isNewTask && currentTask.IsCompleted && !isAdmin) || disableInputs}
                          value={value || ""}
                        />
                        <label htmlFor="Title">Título de la Tarea *</label>
                      </span>
                    )}
                  />
                ) : (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                  />
                )}
                {!isLoadingTask && !isNewTask && !currentTask.IsCompleted && (
                  <div className="filter-users-action mt-2 float-right text-right">
                    <Button
                      icon="pi pi-check-circle"
                      type="button"
                      onClick={() => setShowModalComplete(true)}
                      loading={isLoadingTask}
                      disabled={props?.viewMode}
                      label="Completar Tarea"
                      className="sig-button sig-success"
                    />
                  </div>
                )}
                {!isLoadingTask && !isNewTask && currentTask.IsCompleted && !isAdmin && (
                  <div className="filter-users-action mt-2 float-right text-right">
                    <Badge value={"Completada"} size="large" className="sig-badge" severity="success" />
                  </div>
                )}
                {!isLoadingTask && !isNewTask && currentTask.IsCompleted && isAdmin && (
                  <div className="filter-users-action mt-2 float-right text-right">
                    <Button
                      icon="pi pi-lock"
                      type="button"
                      onClick={() => setShowModalOpen(true)}
                      loading={isLoadingTask}
                      disabled={props?.viewMode}
                      label="Abrir Tarea"
                      className="sig-button sig-primary"
                    />
                  </div>
                )}
              </div>
              <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-6">
                {
                  (!isLoadingTask && !isLoadingDataMaster) &&
                  <DynamicFieldList control={control} fields={dynamicFieldsInformacionTarea} />
                }
              </div>
          </Panel>
          <Panel header="DATOS GENERALES">
            <div className="grid sm:grid-cols-1 md:grid-cols-1">
              {!isLoadingTask || !isLoadingDataMaster ? (
                <Controller
                  name="ResponsibleId"
                  control={control}
                  rules={{ required: "El Responsable es obligatorio " }}
                  render={({ field: { onChange, value } }) => (
                    <span className="p-float-label w-full mt-3">
                      <Dropdown
                        options={dataMaster.responsibles}
                        optionLabel="fullName"
                        filter
                        filterBy="fullName"
                        showClear
                        disabled={(!isNewTask && currentTask.IsCompleted && !isAdmin) || disableInputs}
                        className={`w-full ${
                          errors.ResponsibleId?.type === "required"
                            ? " p-invalid"
                            : ""
                        }`}
                        onChange={onChange}
                        value={value}
                      />
                      <label htmlFor="dropdown">Responsable *</label>
                    </span>
                  )}
                />
              ) : (
                <Skeleton
                  width="100%"
                  height="2.5rem"
                  className="mb-3 w-full"
                />
              )}
              {!isLoadingTask || !isLoadingDataMaster ? (
                <Controller
                  name="Description"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <span className="w-full mt-4">
                      <label htmlFor="inputtextarea" style={{fontSize: "0.75rem", color:"#ced4da"}}>Notas</label>
                      <Editor 
                        id="description"
                        value={value} 
                        headerTemplate={header}
                        disabled={!isNewTask && currentTask.IsCompleted && !isAdmin}
                        className={`w-full ${
                          errors.Description?.type === "required"
                            ? " p-invalid"
                            : ""
                        }`}
                        readOnly={!isNewTask && currentTask.IsCompleted && !isAdmin}
                        onTextChange={(e) => onChange(e.htmlValue)} 
                      />
                    </span>
                  )}
                />
              ) : (
                <Skeleton
                  width="100%"
                  height="2.5rem"
                  className="mb-3 w-full"
                />
              )}
            </div>


            <div className={`grid sm:grid-cols-1 md:grid-cols-${sourceDocumentCode === CoreConstants.Task.Source.PROJECT ? '1' : '3'} gap-2 mt-3`}>
              {sourceDocumentCode === CoreConstants.Task.Source.PROJECT? (
                <>
                <div className="grid sm:grid-cols-3 gap-2">
                {!isLoadingTask || !isLoadingDataMaster ? (
                  <Controller
                    name="TypeId"
                    control={control}
                    rules={{
                      required: "El tipo de la tarea es obligatoria ",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full mt-5">
                        <Dropdown
                          options={dataMaster.taskTypes}
                          id="typeId"
                          optionLabel="description"
                          filter
                          filterBy="description"
                          showClear
                          className={`w-full ${
                            errors.TypeId?.type === "required"
                              ? " p-invalid"
                              : ""
                          }`}
                          disabled={!isNewTask && currentTask.IsCompleted && !isAdmin}
                          onChange={onChange}
                          value={value}
                        />
                        <label htmlFor="typeId">Tipo *</label>
                      </span>
                    )}
                  />
                ) : (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                  />
                )}

                {!isLoadingTask || !isLoadingDataMaster ? (
                <Controller
                  name="PriorityId"
                  control={control}
                  rules={{ required: "La prioridad es obligatoria" }}
                  render={({ field: { onChange, value } }) => (
                    <span className="p-float-label w-full mt-5">
                      <Dropdown
                        options={dataMaster.taskPriorities}
                        optionLabel="description"
                        id="priorityId"
                        filter
                        filterBy="description"
                        showClear
                        className={`w-full ${
                          errors.PriorityId?.type === "required"
                            ? " p-invalid"
                            : ""
                        }`}
                        disabled={!isNewTask && currentTask.IsCompleted && !isAdmin}
                        onChange={onChange}
                        value={value}
                      />
                      <label htmlFor="dropdown">Prioridad *</label>
                    </span>
                  )}
                />
              ) : (
                <Skeleton
                  width="100%"
                  height="2.5rem"
                  className="mb-3 w-full"
                />
              )}
              <span className="p-float-label w-full mt-3">
                {!isLoadingTask || !isLoadingDataMaster ? (
                  <Controller
                    name="ReminderId"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full mt-2">
                        <Dropdown
                          options={dataMaster.taskReminders}
                          optionLabel="description"
                          id="reminderId"
                          filter
                          filterBy="description"
                          showClear
                          className="w-full"
                          onChange={onChange}
                          disabled={!isNewTask && currentTask.IsCompleted && !isAdmin}
                          value={value}
                        />
                        <label htmlFor="reminderId">Recordatorio</label>
                      </span>
                    )}
                  />
                ) : (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                  />
                )}
              </span>
              </div>
              
              <div className="grid sm:grid-cols-3 md:grid-cols-3 gap-2">
                {!isLoadingTask || !isLoadingDataMaster ? (
                  <Controller
                    name="ScheduleDate"
                    control={control}
                    rules={{ required: "La fecha es obligatoria " }}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full mt-5">
                        <Calendar
                          monthNavigator
                          yearNavigator
                          yearRange="1950:2050"
                          showIcon={false}
                          onChange={onChange}
                          value={value}
                          disabled={(!isNewTask && currentTask.IsCompleted && !isAdmin) || disableInputs}
                          className={`w-full ${
                            errors.ScheduleDate?.type === "required"
                              ? " p-invalid"
                              : ""
                          }`}
                          id="scheduleDate"
                        ></Calendar>
                        <label htmlFor="scheduleDate">Fecha *</label>
                      </span>
                    )}
                  />
                ) : (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                  />
                )}
                  <span className="mt-1">
                  {!isLoadingTask || !isLoadingDataMaster ? (
                    <Controller
                      name="EndDate"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <span className="p-float-label w-full mt-4">
                          <Calendar
                            monthNavigator
                            yearNavigator
                            yearRange="1950:2050"
                            onChange={onChange}
                            value={value}
                            readOnly={!isNewTask && currentTask.IsCompleted && !isAdmin}
                            className={`w-full ${
                              errors.EndDate?.type === "required"
                                ? " p-invalid"
                                : ""
                            }`}
                            id="endDate"
                          ></Calendar>
                          <label htmlFor="endDate">Fecha Fin *</label>
                        </span>
                      )}
                    />
                  ) : (
                    <Skeleton
                      width="100%"
                      height="2.5rem"
                      className="mb-3 w-full"
                    />
                  )}
                </span>
                {!isLoadingTask || !isLoadingDataMaster ? (
                  <Controller
                    name="ScheduleHour"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full mt-5">
                        <Calendar 
                          id="scheduleHour" 
                          timeOnly 
                          hourFormat="24" 
                          value={value} 
                          disabled={!isNewTask && currentTask.IsCompleted && !isAdmin}
                          className={`w-full ${
                            errors.ScheduleHour?.type === "required"
                              ? " p-invalid"
                              : ""
                          }`}
                          onChange={onChange}/>
                        <label htmlFor="scheduleHour">Hora</label>
                      </span>
                    )}
                  />
                ) : (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                  />
                )}
                </div>

                <div className="grid sm:grid-cols-3 gap-2">
                {!isLoadingTask || !isLoadingDataMaster ? (
                <Controller
                  name="LoggedHours"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <span className="p-float-label w-full mt-5">
                      <InputNumber
                        id="loggedHours"
                        className={`w-full ${errors.LoggedHours?.type === "required" ? " p-invalid" : ""}`}
                        onValueChange={onChange}
                        inputStyle={{ textAlign: "right" }}
                        disabled={!isNewTask && currentTask.IsCompleted && !isAdmin}
                        value={Number(value)}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        locale="es-PE"
                      />
                      <label htmlFor="loggedHours">Horas invertidas</label>
                    </span>
                  )}
                />
            ) : (
                <Skeleton
                  width="100%"
                  height="2.5rem"
                  className="mb-3 w-full"
                />
              )}
                </div>
              </>
              ) :
                <>            
                    {!isLoadingTask || !isLoadingDataMaster ? (
                    <Controller
                      name="TypeId"
                      control={control}
                      rules={{
                        required: "El tipo de la tarea es obligatoria ",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <span className="p-float-label w-full mt-5">
                          <Dropdown
                            options={dataMaster.taskTypes}
                            id="typeId"
                            optionLabel="description"
                            filter
                            filterBy="description"
                            showClear
                            className={`w-full ${
                              errors.TypeId?.type === "required"
                                ? " p-invalid"
                                : ""
                            }`}
                            disabled={!isNewTask && currentTask.IsCompleted && !isAdmin}
                            onChange={onChange}
                            value={value}
                          />
                          <label htmlFor="typeId">Tipo *</label>
                        </span>
                      )}
                    />
                  ) : (
                    <Skeleton
                      width="100%"
                      height="2.5rem"
                      className="mb-3 w-full"
                    />
                  )}


                  {!isLoadingTask || !isLoadingDataMaster ? (
                <Controller
                  name="PriorityId"
                  control={control}
                  rules={{ required: "La prioridad es obligatoria" }}
                  render={({ field: { onChange, value } }) => (
                    <span className="p-float-label w-full mt-5">
                      <Dropdown
                        options={dataMaster.taskPriorities}
                        optionLabel="description"
                        id="priorityId"
                        filter
                        filterBy="description"
                        showClear
                        className={`w-full ${
                          errors.PriorityId?.type === "required"
                            ? " p-invalid"
                            : ""
                        }`}
                        disabled={!isNewTask && currentTask.IsCompleted && !isAdmin}
                        onChange={onChange}
                        value={value}
                      />
                      <label htmlFor="dropdown">Prioridad *</label>
                    </span>
                  )}
                />
              ) : (
                <Skeleton
                  width="100%"
                  height="2.5rem"
                  className="mb-3 w-full"
                />
              )}
              
            <span className="p-float-label w-full mt-3">
                {!isLoadingTask || !isLoadingDataMaster ? (
                  <Controller
                    name="ReminderId"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full mt-2">
                        <Dropdown
                          options={dataMaster.taskReminders}
                          optionLabel="description"
                          id="reminderId"
                          filter
                          filterBy="description"
                          showClear
                          className="w-full"
                          onChange={onChange}
                          disabled={!isNewTask && currentTask.IsCompleted && !isAdmin}
                          value={value}
                        />
                        <label htmlFor="reminderId">Recordatorio</label>
                      </span>
                    )}
                  />
                ) : (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                  />
                )}
              </span>
              {/* <div className="grid sm:grid-cols-3 md:grid-cols-3 gap-2"> */}
                {!isLoadingTask || !isLoadingDataMaster ? (
                  <Controller
                    name="ScheduleDate"
                    control={control}
                    rules={{ required: "La fecha es obligatoria " }}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label w-full mt-5">
                        <Calendar
                          monthNavigator
                          yearNavigator
                          yearRange="1950:2050"
                          showIcon={false}
                          onChange={onChange}
                          value={value}
                          disabled={(!isNewTask && currentTask.IsCompleted && !isAdmin) || disableInputs}
                          className={`w-full ${
                            errors.ScheduleDate?.type === "required"
                              ? " p-invalid"
                              : ""
                          }`}
                          id="scheduleDate"
                        ></Calendar>
                        <label htmlFor="scheduleDate">Fecha *</label>
                      </span>
                    )}
                  />
                ) : (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                  />
                )}
                {!isLoadingTask || !isLoadingDataMaster ? (
                  <Controller
                    name="ScheduleHour"
                    control={control}
                    render={({ field: { onChange, value} }) => (
                      <span className="p-float-label w-full mt-5">
                        <Calendar 
                          id="scheduleHour" 
                          timeOnly 
                          hourFormat="24" 
                          value={value} 
                          disabled={!isNewTask && currentTask.IsCompleted && !isAdmin}
                          className={`w-full ${
                            errors.ScheduleHour?.type === "required"
                              ? " p-invalid"
                              : ""
                          }`}
                          onChange={onChange}/>
                        <label htmlFor="scheduleHour">Hora</label>
                      </span>
                    )}
                  />
                ) : (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                  />
                )}
                {!isLoadingTask || !isLoadingDataMaster ? (
                  <Controller
                    name="LoggedHours"
                    control={control}
                    rules={{
                      min: {
                        message: "Las horas invertidas no deben ser menor a 0",
                        value: 0,
                      },
                    }}
                    render={({ field: { onChange, value} }) => (
                      <span className="p-float-label w-full mt-5 p-inputwrapper-filled p-inputnumber-buttons-stacked">
                        <InputNumber
                          inputId="loggedHours"
                          className={`w-full ${errors.LoggedHours?.type === "required" ? " p-invalid" : "" }`}
                          onValueChange={onChange}
                          inputStyle={{ textAlign: "right" }}
                          disabled={!isNewTask && currentTask.IsCompleted && !isAdmin}
                          value={Number(value)}
                        />
                        <label htmlFor="loggedHours">Horas invertidas</label>
                      </span>
                    )}
                  />
                ) : (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                  />
                )}
              {/* </div> */}
                </>
              }
            </div>
        

            {sourceDocumentCode === CoreConstants.Task.Source.PROJECT && 
              <div className="grid sm:grid-cols-1 md:grid-cols-1">
                {!isLoadingTask || !isLoadingDataMaster ? (
                  <Controller
                    name="Progress"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <div className="flex items-center">
                        <div className="mr-2">0</div>
                        <div className="range">
                          <div className="field">
                            <input
                                type='range' min='0' max='100' step='1'
                                value={value?value:0} onChange ={(e) => {
                                  onChange(Number(e.target.value))
                                }}
                                readOnly={!isNewTask && currentTask.IsCompleted && !isAdmin}
                              />
                          </div>
                          <div className="sliderValue" style={{left:
                            value<30?((value+0.7) + "%"):(value>70?((value - 0.7) + "%"):(value + "%"))
                            }}>
                            <span>{value?value:'0'}</span>
                          </div>
                          {/* <label>Progreso: {value}</label>
                          <span className="p-float-label w-full mt-1">
                            <input 
                              type='range' min='0' max='100' step='25'
                              value={value} onChange ={(e) => onChange(Number(e.target.value))}
                              readOnly={!isNewTask && currentTask.IsCompleted}
                            />
                          </span> */}
                        </div>
                        <div className="ml-2">100</div>
                      </div>
                    )}
                  />
                ) : (
                  <Skeleton
                    width="100%"
                    height="2.5rem"
                    className="mb-3 w-full"
                  />
                )}
              </div>
            }

            <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-3">
              {
                (!isLoadingTask && !isLoadingDataMaster) &&
                <DynamicFieldList control={control} fields={dynamicFieldsDatosGenerales} />
              }
            </div>
          </Panel>
          <Panel>
            <div className="grid sm:grid-cols-2 md:grid-cols-2 gap-2">
            {!isNewTask ?(
              <Panel header="COMENTARIOS">
                <div className="grid sm:grid-cols-1 md:grid-cols-1 pt-4">
                  {/* <TableCommentsComponent enableComments={!isNewTask && !currentTask.IsCompleted} sourceId={currentTask.Id}/> */}
                  <TableCommentsComponent
                    enableComments={(!currentTask.IsCompleted && !props.viewMode) || isAdmin}
                    sourceId={props.taskId}
                    comments={commentsData}
                    Action={TaskAction}
                    isDeletedComment={isDeletedComment}
                    isSavedComment={isSavedComment}
                    isLoadingComments={isLoadingComments}
                    sourceCode={CoreConstants.Task.Source.INDICATOR}
                  />
                </div>
              </Panel>):null
            }
            {!isNewTask ?(
              <Attachments
                // documentId={props.taskId}
                // module="customer"
                // addEndpoint={"api/Task/AddAttachment"}
                // deleteEndpoint={"api/Task/DeleteAttachment"}
                // listEndpoint={"api/Task/GetAttachmentsByTaskId"}

                setValue={setValue}
                fieldFiles="savedFiles"
                fieldNewFiles="addedFiles"
                filesValues={valuesFields?.["savedFiles"]}
                newFilesValues={valuesFields?.["addedFiles"]}
                isLoading={isLoadingTask}
                validExtensions={["docx", "pdf", "xlsx"]}
                isFullWidth={false}
              />
            ): null}
            </div>
          </Panel>
          {Object.entries(errors).forEach((key, value) => {
            errorsArray.push(key[1].message);
          })}
          {errorsArray.length > 0 && (
            <Message messages={errorsArray} status="error" />
          )}
          {(!isLoadingTask || !isLoadingDataMaster) && (
            <div className="flex justify-end gap-4 mt-3">
              <Button
                icon="pi pi-times"
                type="button"
                label="Cancelar"
                onClick={() => setShowModalCancel(true)}
                className="sig-button  sig-secondary mt-4"
              />
              <Button
                icon="pi pi-save"
                type="button"
                label="Guardar"
                disabled={(!isNewTask && currentTask.IsCompleted && !isAdmin) | props?.viewMode}
                loading={isLoadingTask}
                className="sig-button sig-primary mt-4"
                form="form-task"
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          )}
        </form>
        <ConfirmDialog
          visible={showModalCancel}
          onHide={() => setShowModalCancel(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={cancelAccept}
          reject={cancelDenied}
        />
        <ConfirmDialog
          visible={showModalComplete}
          onHide={() => setShowModalComplete(false)}
          message="¿Está seguro que desea completar la Tarea?"
          header="Completar Tarea"
          icon="pi pi-check"
          accept={confirmComplete}
          reject={cancelComplete}
        />
        <ConfirmDialog
          visible={showModalOpen}
          onHide={() => setShowModalOpen(false)}
          message="¿Está seguro que desea abrir la Tarea?"
          header="Abrir Tarea"
          icon="pi pi-check"
          accept={confirmOpen}
          reject={cancelOpen}
        />
      </div>
    </>
  );
};