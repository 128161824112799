import { CoreConstants } from "core/commons/CoreConstants";
import { FormTaskComponent } from "features/customers/components/tasks/FormComponent";
import { useCustomerTask } from "features/customers/hooks/tasks/useCustomerTask";

export const PageCustomerTask = (props) => {
  const { companyId: customerId, taskId } = useCustomerTask({pageCustomerTask: true});
  return (
    <>
      <FormTaskComponent sourceDocumentId={customerId} sourceDocumentCode={CoreConstants.Task.Source.CUSTOMER} taskId={taskId} returnUrl={`/cliente/empresas/editar/${customerId}/tareas`}/>
    </>
  )
}