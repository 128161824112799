export class SubscriptionConstants {
  static Accion = class {
    static Subscriptions = class {
      static get SEARCH_SUBSCRIPCIONES() {
        return '@SUBSCRIPTIONS.SEARCH_SUBSCRIPCIONES';
      }
      static get REMOVE_FILTER_VALUES() {
        return '@SUBSCRIPTIONS.REMOVE_FILTER_VALUES';
      }
      static get SET_FILTER_VALUES() {
        return '@SUBSCRIPTIONS.SET_FILTER_VALUES';
      }
      static get ADVANCED_FILTER_TOGGLE() {
        return '@SUBSCRIPTIONS.ADVANCED-FILTER-TOGGLE';
      }
      static get MODALS() {
        return '@EMPLOYEES.MODALS';
      }
      static get DATA_MASTER() {
        return '@SUBSCRIPTIONS.DATA_MASTER'
      }
      static get EDIT_SUBSCRIPTIONS() {
        return '@SUBSCRIPTIONS.EDIT_SUBSCRIPTIONS';
      }
    };

    static ModulesIso = class {
      static get SEARCH_MODULES() {
        return '@MODULES.SEARCH_MODULES';
      }
      static get REMOVE_FILTER_VALUES() {
        return '@MODULES.REMOVE_FILTER_VALUES';
      }
      static get SET_FILTER_VALUES() {
        return '@MODULES.SET_FILTER_VALUES';
      }      
      static get DATA_MASTER() {
        return '@MODULES.DATA_MASTER'
      }
      static get EDIT_MODULES() {
        return '@MODULES.EDIT_MODULES';
      }
      static get GET_REPORT(){
        return '@MODULES.GET_REPORT'
      }
    };

    static Solution = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return '@SOLUTION.ADVANCED-FILTER-TOGGLE';
      }

      static get SEARCH_SOLUTIONS() {
        return '@SOLUTION.SEARCH_SOLUTIONS';
      }

      static get UPDATE_FILTER() {
        return '@SOLUTION.UPDATE_FILTER';
      }

      static get GET_SOLUTION_BY_ID() {
        return '@SOLUTION.GET_SOLUTION_BY_ID';
      }

      static get SAVE_SOLUTION() {
        return '@SOLUTION.SAVE_SOLUTION';
      }

      static get DELETE_SOLUTION() {
        return '@SOLUTION.DELETE_SOLUTION';
      }

      static get GET_DATA_MASTER() {
        return '@SOLUTION.GET_DATA_MASTER';
      }

      static get RESET_AFTER_SAVED() {
        return '@SOLUTION.RESET_AFTER_SAVED';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@SOLUTION.DOWNLOAD_REPORT_STATUS';
      }
    };
  };
}