import moment from 'moment';
import { Tooltip } from 'primereact/tooltip';
import { useState } from 'react';
import './TableCardComponent.scss'


const TableCardComponent = ({history, params, principalColumn, listColumns, columns, process, menu, setRowSelected}) => {

  // console.log(process)

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  }

  // const truncateText = (text, maxLength) => {
  //   if (text.length <= maxLength) {
  //     return text;
  //   }
  //   return text.substr(0, maxLength) + '...';
  // }

  // const text = 'props.text dasdsadasdsadsadsadsad';
  // const maxLength = 10;
  // const truncatedText = truncateText(text, maxLength);

  // console.log(listColumns.filter(col => Object.keys(process).includes(col)).length)

  const setValuesByType = (column) => {
    const type = columns?.find(c => c.field === column)?.type
    // console.log(type)
    switch (type) {
      case 'FECHA':
      case 'FECHA_HORA':
        return moment(process[column]).format('DD/MM/YYYY')
      case 'TEXTO_DINAMICO':
        return <div dangerouslySetInnerHTML={{ __html: process[column] }} className='w-full text-dinamic-process' />
        // return process[column]

      default:
        return process[column]
    }
  }

  const quantityColumns = listColumns.filter(col => Object.keys(process).includes(col))?.length;

  // console.log(columns)
  // console.log(process)
  // console.log(first)

  return (
    <div
      style={{
          display: "flex",
          justifyContent: "space-around",
      }}
      className=''
      key={process.id}
    >
        <div 
            className={`card-canva my-1 cursor-pointer ${isExpanded ? 'card-visible' : 'card-hidden'} `}
            // onClick={() => { history.push(`/proceso-dinamico/${params.id}/${process.ProcessId}`) }}
        >
            {/* HEADER */}
            <div 
              className="flex justify-between"
            >
                <Tooltip target={`.tooltip-${process.ProcessId}`} />
                <div 
                  className={`tooltip-${process.ProcessId} flex-1`}
                  data-pr-tooltip={ process[principalColumn[0]] && (`${process[principalColumn[0]]}`)}
                  data-pr-position="top" 
                  onClick={() => { history.push(`/proceso-dinamico/${params.id}/${process.ProcessId}`) }}
                >
                  <p  className='title truncate-text '>
                      {
                          process[principalColumn[0]]
                      }
                  </p>
                </div>
                <div className='mb-1'>
                  <i
                    key={process.id}
                    className="pi pi-ellipsis-v cursor-pointer"
                    onClick={(event) => {
                        setRowSelected(process);
                        menu.current.toggle(event)
                    }
                    }></i>
                </div>
            </div>
            <hr />
            {/* BODY */}
            <div className="body flex flex-col">
                <div 
                  className='flex flex-col gap-y-2 flex-1'
                  onClick={() => { history.push(`/proceso-dinamico/${params.id}/${process.ProcessId}`) }}
                >
                  {
                      listColumns.filter(col => Object.keys(process).includes(col)).map((column, index) => (
                          isExpanded ? (
                            <div className='' key={column}>
                                  <p className="text-xs capitalize">{column}:</p>
                                  <p className="text-xs font-bold w-full sm:w-52 truncate">
                                      {
                                          // columns?.find(c => c.field === column)?.type === ('FECHA' || 'FECHA_HORA') ?
                                          // moment(process[column]).format('DD/MM/YYYY')
                                          // :
                                          // process[column]
                                          setValuesByType(column)
                                      }
                                  </p>
                              </div>
                          ) : (
                            index < 2 ?
                            (
                              <div className='flex-1 relative' key={column}>
                                    <p className="text-xs capitalize">{column}:</p>
                                    <p className="text-xs font-bold w-full sm:w-52 truncate">
                                        {
                                            // columns?.find(c => c.field === column)?.type === ('FECHA' || 'FECHA_HORA') ?
                                            // moment(process[column]).format('DD/MM/YYYY')
                                            // :
                                            // process[column]
                                            setValuesByType(column)
                                        }
                                    </p>
                                </div>
                            ) :
                            null
                          )
                      ))
                  }
                </div>
                <div className='flex justify-end'>
                  {(!isExpanded && quantityColumns > 2) && (
                      <button onClick={toggleExpand} type='button' className="btn-expand">Ver más</button>
                    )}
                    {isExpanded && (
                      <button onClick={toggleExpand} type='button' className="btn-collapse">Cerrar</button>
                    )}
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default TableCardComponent