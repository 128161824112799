import { RiskMatrixAction } from 'features/riskManagement/store/actions/RiskMatrixAction';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Formula = ({rowData, getValues, column, dataValue}) => {

	const dispatch = useDispatch()
	const [valor, setValor] = useState(null)
  const decimalPositions = rowData[column.field]?.decimalPositions;		
  // const dataValue = useSelector((state) => state.riskMatrixReducer.values);

	const obtenerValor =  useCallback(() => {
		const data = {}
    const dataId = {}
    if(rowData[column.field]?.evaluationType === "RMMAT"){
      Object.keys(rowData).forEach( key => {
        if(key.split('-')[1]){
          data[key.split('-')[1]] = Number(getValues[`${key}-row-${rowData.id}`]?.itemValue)
          dataId[key.split('-')[1]] = Number(getValues[`${key}-row-${rowData.id}`]?.id)
        }
      })
    } else {
      Object.keys(rowData).forEach( key => {
        if(key.split('-')[1]){
          data[key.split('-')[1]] = Number(getValues[`${key}-row-${rowData.id}`]?.itemValue)
        }
      })
    }

		const formu = rowData[column.field]?.formula.split(' ').map( column => {
			
			if( column.startsWith('[CriteriaId') || column.startsWith('[ProbabilityId')|| column.startsWith('[ImpactId'))
			 return `data['${column.replace('[','').replace(']','').replace(':','_')}']`
			return	column.replace('[','').replace(']','').replace(':','_')
		}).join(' ')
    const formEval = Number(eval(formu))

		let result = {value: isNaN(formEval) ? 0 : formEval}
   
    // result.value = isNaN(result.value) ? 0 : Number(result.value.toFixed(decimalPositions));
    const conditions = rowData[column.field]?.condition
    conditions?.forEach((condition) => {
      if( condition.from !== null && condition.to !== null){
        if( condition.from < result.value && result.value < condition.to){
          result.condition = condition
        }
      } else if(condition.from !== null){
        if( condition.from < result.value){
          result.condition = condition
        }
      } else if(condition.to !== null){
        if(result.value < condition.to){
          result.condition = condition
        }
      } else if( condition.values?.length > 0 ){
        if(condition.values.includes(result.value)){
          result.condition = condition
        }
      }else{
        result.condition = {}
      }
    })

    if(rowData[column.field]?.evaluationType === "RMMAT"){

      const rowId = dataId[rowData[column.field]?.rowId]
      const colId = dataId[rowData[column.field]?.colId]


      rowData[column.field]?.matrix.forEach( item => {
        if( item.colId === colId && item.rowId === rowId )
          result = item
      })
      // dispatch(RiskMatrixAction.setColumn({[`${column.field}-${rowData.id}`]: result.showFileRecord}))
      // dispatch(RiskMatrixAction.setValues({[`${column.field}-${rowData.id}`]: result}))
      dataValue.current[`${column.field}-row-${rowData.id}`] = result
      return result
      
    }else{
      // dispatch(RiskMatrixAction.setValues({[`${column.field}-${rowData.id}`]: result}))
      dataValue.current[`${column.field}-row-${rowData.id}`] = result
      return result
    }


	},[getValues])

	useEffect(() => {
		const result = obtenerValor()
		setValor(result)
	},[obtenerValor])

  if(rowData[column.field]?.evaluationType === "RMMAT"){
    return (
      <div className='font-bold grid place-content-center' style={{background: valor?.colorCode, height: "30px"}}>
        {valor?.levelName}
      </div>
    );
  }

	return (
		<div className='font-bold grid place-content-center' style={{background: valor?.condition?.colorCode, height: "30px"}}>
			<p>{isNaN(valor?.value.toFixed(decimalPositions)) ? '' : valor?.value.toFixed(decimalPositions)}</p>
		</div>
	);
};

export default Formula;