import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { RadioButton } from 'primereact/radiobutton';
import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'


const numberOfEntries = [
  {name: 'Entrada única', code: 'entry_single'}, 
  {name: 'Entradas multiples', code: 'entry_multiple'},  
]



export const ControllerUsers = ({control, errors, ValidateInput, validateDuplicate, valuesFields, dataMaster}) => {
  return (
    <>
      <span className="col-span-2 p-float-label w-full mt-4">
          <Controller
              control={control}
              render={({
                  field: { onChange, onBlur, value }
              }) => (
                <InputText
                    autoFocus
                    id="fieldLabel"
                    name="fieldLabel"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    className={errors?.fieldLabel ? 'p-invalid w-full' : 'w-full'}
                />
              )}
              name="fieldLabel"
              rules={{
                  required: 'La etiqueta del campo es requerida.',
                  validate: ValidateInput
              }}
          />
          <label htmlFor="fieldLabel">Etiqueta del campo  *</label>
      </span>

      <span className="col-span-2  w-full mt-1">
          <Controller
              control={control}
              render={({
                  field: { onChange, onBlur, value }
              }) => (
                <div className='flex flex-col gap-y-1'>
                    <p>Seleccione el origen de datos</p>
                    {
                      dataMaster?.processFormControlUserRecords
                      .map((dataSource) => {
                          return (
                              <div key={dataSource.id} className="field-radiobutton flex gap-x-1 items-center">
                                  <RadioButton 
                                    inputId={dataSource.id} 
                                    name="dataSource" 
                                    value={dataSource} 
                                    onChange={(e) => onChange(e.value)}  
                                    checked={value?.id === dataSource.id}  
                                    className={errors?.dataSource ? 'p-invalid' : ''}
                                  />
                                  <label htmlFor={dataSource.id}>{dataSource.description}</label>
                              </div>
                          )
                      })
                  }
                </div>
              )}
              rules={{
                required: 'El origen de datos es requerido.',
              }}
              name="dataSource"
          />
      </span>

      {/* <span className="col-span-2  w-full mt-1">
          <Controller
              control={control}
              render={({
                  field: { onChange, onBlur, value }
              }) => (
                <div className='flex flex-col gap-y-1'>
                    <p>Número de entradas</p>
                    {
                      numberOfEntries.map((numberOfEntry) => {
                          return (
                              <div key={numberOfEntry.code} className="field-radiobutton flex gap-x-1 items-center">
                                  <RadioButton 
                                    inputId={numberOfEntry.code} 
                                    name="numberOfEntry" 
                                    value={numberOfEntry} 
                                    onChange={(e) => {
                                      console.log(e.value)
                                      onChange(e.value)
                                    }}  
                                    checked={valuesFields.numberOfEntry?.code === numberOfEntry.code}  
                                    className={errors?.numberOfEntry ? 'p-invalid' : ''}
                                  />
                                  <label htmlFor={numberOfEntry.code}>{numberOfEntry.name}</label>
                              </div>
                          )
                      })
                  }
                </div>
              )}
              rules={{
                required: 'El número de entradas es requerido.',
              }}
              name="numberOfEntry"
          />
      </span> */}

      <span className="col-span-2  w-full mt-1">
          <Controller
              control={control}
              render={({
                  field: { onChange, onBlur, value }
              }) => (
                <div className='flex gap-2 mt-4 align-center'>
                  <div>
                    <InputSwitch
                      checked={value?.code === numberOfEntries[1].code}
                      name="numberOfEntry"
                      onChange={({ value }) => {
                        const inputValue = value ? numberOfEntries[1] : numberOfEntries[0]
                        onChange(inputValue)
                      }}
                    />
                  </div>
                  <h5 className='ml-1'>Entradas multiples</h5>
                </div>
              )}
              rules={{
                required: 'El número de entradas es requerido.',
              }}
              name="numberOfEntry"
          />
      </span>

      <div className='col-span-2 p-float-label w-full'>
        <span className="p-float-label">
            <Controller
                control={control}
                render={({
                    field: { onChange, value }
                }) => (
                  <div className='flex items-center'>
                    <InputSwitch checked={value} onChange={onChange} />
                    <h5 className='ml-2'>Requerido</h5>
                  </div>
                )}
                name="required"
            />
        </span>
      <div className={`rounded bg-red-200 p-5 mt-6 text-sm text-red-600 ${validateDuplicate===false&&'hidden'}`}>
        <ul>
          <li>La etiqueta del campo, ya se encuentra en uso.</li>
        </ul>
      </div>
      </div>
    </>
  )
}
