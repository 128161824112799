import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { FilterComponent } from "features/maintenance/components/BusinessSheetType/FilterComponent";
import { TableComponent } from "features/maintenance/components/BusinessSheetType/TableComponent";

export const BusinessSheetTypeSearch = () => {
  const { updateTitle } = useSetTitlePage();

  useEffect(() => {
    updateTitle({
      title: "Mantenimiento",
      subtitle: "Tipos de Ficha de Negocio"
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return(
    <>
      <FilterComponent />
      <div className="mt-5">
        <TableComponent />
      </div>
    </>
  )
}