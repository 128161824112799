import { useState , useEffect } from "react"
import { EmployeesAction } from "features/rrhh/store/actions/EmployeesAction";
import { useDispatch , useSelector } from "react-redux"
const useTable = () =>{
  const { pagination } = useSelector((state) => state.employeeReducer.employees);
  const dispatch = useDispatch();
  const [firstTable , setFirst] = useState(0)
  const [rowsTable , setRows] = useState(10)
  useEffect(() =>{
    if(pagination?.currentPage === 1){
      setFirst(0);
    }
  },[pagination])
  const onPageChange = ({rows , page , first}) =>{
    dispatch(EmployeesAction.searchTable({page:page+1}))
    setFirst(first)
  }
  return {
    onPageChange,
    firstTable,
    rowsTable
  }
}

export default useTable