import { Button } from 'primereact/button'
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Divider } from 'primereact/divider'
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import React, { useRef, useState } from 'react'
import { Controller } from 'react-hook-form';

const WorkFlowCardFinal = ({control}) => {
  return (
    <div className='rounded sig-success py-1 px-3 final-process-name'>
      <Controller
          control={control}
          render={({
              field: { onChange, onBlur, value },
          }) => (
            <InputText
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              className='w-full text-center'
            />
          )}
          name="finalName"
      />
    </div>
  )
}

const WorkFlowCard = ({openModalAction, step, stepMethods, workFlowFields, control, lastStep, getTypeById}) => {
  const menu = useRef(null);
  const [visible, setVisible] = useState(false);
  // const [showButton, setShowButton] = useState(false)
  // console.log(step)
  
  const items = [
    {
      label: 'Agregar un nuevo paso',
      // icon: 'pi pi-refresh',
      command: () => {
        openModalAction('step_configuration', step, null)
      }
    },
    {
        label: 'Agregar validación',
        // icon: 'pi pi-times',
        command: () => {
          stepMethods.createNewParallelBranch(step)
        }
    },
    {
        label: 'Ir al paso',
        // icon: 'pi pi-times',
        command: () => {
          openModalAction('go_to_step', step, null)
        }
    },
  ];

  // if (lastStep?.id === step.id) {
    // console.log(lastStep)
    // console.log(step)
  // }

  const isInitialProcess = getTypeById(step?.typeId)?.code === 'START'
  const isStep = getTypeById(step?.typeId)?.code === 'STEP'
  const isGoToStep = getTypeById(step?.typeId)?.code === 'GOTO'
  const isCondition = getTypeById(step?.typeId)?.code === 'CONDITION'
  // const isFinalProcess = getTypeById(step?.typeId)?.code === 'END'

  const reject = () => {
    setVisible(false);
  };

  const onAcceptDelete = () => {
    // if (step.type === 'parallel_branch') {
    if (isCondition) {
      stepMethods.deleteStepBranch(step)
      return
    }
      
    stepMethods.deleteStep(step)
  }

  const getEditFunction = () => {
    // if (step?.key === '1') {
    if (isInitialProcess) {
      return openModalAction('initial_setup', step, step)
    }
    if (step.stepTo || isGoToStep) {
      return openModalAction('go_to_step', step, step)
    }
    // if (step.type === 'parallel_branch') {
    if (isCondition) {
      return openModalAction('parallel_branch', step, step)
    }
    openModalAction('step_configuration', step, step)
  }

  
  
  // const lastStep = stepMethods.getLastStep(workFlowFields.configurations)
  // console.log(lastStep)
  return (
    // <div className={`flex flex-col items-center w-full`}>
    <div className={`flex flex-col items-center justify-center w-52 md:w-64 h-full flex-1`}>
      {/* <Divider layout="vertical" /> */}
      {/* Card */}
      <div className={`w-full shadow-md rounded  ${(step.stepTo || isGoToStep) ? 'border-4 border-indigo-500' : ''}`}>
        {/* Card Header */}
        {
            // step?.key === '1' && (
              isInitialProcess && (
            <div className='w-full border border-gray-600 rounded-t h-4 sig-primary'>
            </div>
          )
        }
        {/* Card Body */}
        <div className='w-full border border-b-0 border-gray-200 rounded-t  h-24 flex justify-evenly py-2 px-3 gap-x-1'>
          {
            (step?.name.length > 28 || step?.stepTo?.name.length > 25) ? (
              <Tooltip target={`.tooltip-step-${step?.id}`} />
            ) : null
          }

          <div className='flex flex-col justify-center w-full h-full gap-y-2'>
            <h1 
              className={`font-semibold truncate w-full text-base tooltip-step-${step?.id}`} 
              data-pr-tooltip={step?.name + ' ' + (step.stepTo ? step.stepTo.name : '')} 
              data-pr-position="top"
            >{step?.name} {step.stepTo ? step.stepTo.name : ''} </h1>
            {
              // (isStep || isInitialProcess) && (
              (isStep) && (
                step.roles.length === 0 ? (
                  <p className='text-xs'>Configure quien inicia el proceso</p>
                ) : (
                  <div className={`flex ${step.roles.length >= 5 ? 'gap-x-1' : 'flex-col'} `}>
                    <p className='text-xs'>Asignado a: </p>
                    {
                      step.roles.length >= 5 ? (
                        <p className='text-xs'>{`${step.roles.length} roles`}</p>
                      ) : (
                        <div className='flex flex-wrap justify-start'>
                          {
                            step.roles.map((role, index) => (
                              <span key={role.id} className='text-xs rounded-full mr-1 truncate'>{role.description}{index === step.roles.length - 1 ? '' : ',' } </span>
                            ))
                          }
                        </div>
                      )
                    }
                  </div>
                )
              )
            }
            {
              // (step.type === 'step_go_to' || step.type === 'parallel_branch') && (
              (isGoToStep || isCondition) && (
                <div className='flex flex-col'>
                  {
                    // (step.stepTo && !step.condition) && (
                    (step.stepTo && step.conditions.length === 0) && (
                      <>
                        <p className='text-xs'>¿Cuándo ocurre esta tarea?</p>
                        <p className='text-xs font-semibold'>Siempre</p>
                      </>
                    )
                  }
                </div>
              )
            }
          </div>
          {/* <div className={`${step?.key === '1' ? 'invisible' : ''}`}> */}
          <div className={`${isInitialProcess ? 'invisible' : ''}`}>
            <i className="pi pi-trash cursor-pointer hover:opacity-90" onClick={() => setVisible(true)}></i>
          </div>
        </div>

        {/* Card Footer */}
        <div className='w-full border border-gray-200 rounded-b h-9 p-2 flex justify-between items-center'>
          {/* <span className={`italic ml-2 text-gray-400 text-lg ${((step.type === 'step_go_to' || step.type === 'parallel_branch') && step.conditions.length > 0) ? '' : 'invisible'}`} ><span className='text-xl'>f</span>x</span> */}
          <span className={`italic ml-2 text-gray-400 text-lg ${((isGoToStep || isCondition) && step.conditions.length > 0) ? '' : 'invisible'}`} ><span className='text-xl'>f</span>x</span>
            <Button
                type="button"
                label="Editar"
                className={`sig-button sig-primary h-6 w-8 ${isInitialProcess ? 'invisible' : ''}`}
                onClick={getEditFunction}
            />
        </div>
      </div>
      {/* Boton Add */}
      <div className='button-add-step h-full'>
        {/* <div className='button-add-step' onMouseOver={() => setShowButton(true)} onMouseOut={() => setShowButton(false)}> */}
        <Divider layout="vertical" className=''>
            <Button 
              icon="pi pi-plus" 
              type='button' 
              className="p-button-rounded sig-primary" 
              // className={`p-button-rounded sig-primary ${showButton ? 'visible' : 'hidden'}`}
              style={{minWidth: 10, width: 30, height: 30}} 
              onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup
            />
        </Divider>
      </div>
      {/* Input Cierre*/}
      {
        // step?.position === workFlowFields.configurations.length  && (
          // (level === totalLevels && positionStep === (quantitySteps/2))  && (
          (lastStep?.id === step.id)  && (
            // <div className='rounded sig-success py-1 px-3 final-process-name'>
            //   <Controller
            //       control={control}
            //       render={({
            //           field: { onChange, onBlur, value },
            //       }) => (
            //         <InputText
            //           onChange={onChange}
            //           onBlur={onBlur}
            //           value={value}
            //           className='w-full text-center'
            //         />
            //       )}
            //       name="finalName"
            //   />
            // </div>
            <WorkFlowCardFinal control={control} />
        )
      }
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea eliminar este paso?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={onAcceptDelete}
          reject={reject}
        />
    </div>
  )
}

export default WorkFlowCard