import AdvancedFilterComponent from 'features/survey/components/Survey/AdvacedFilterComponents';
import FilterComponent from 'features/survey/components/Survey/FilterComponent';
import TableComponent from 'features/survey/components/Survey/TableComponent';
import SidebarMenu from 'features/survey/components/Survey/SidebarMenu';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import ModalActions from 'features/survey/components/Survey/ModalActions';
import { Toast } from 'primereact/toast';
import { useClaimSurvey } from 'features/survey/hooks/useClaimSurvey';

const SearchSurveyPage = () => {
  const { updateTitle } = useSetTitlePage();

  const [selectedNode, setSelectedNode] = useState('');
  const [surveySelected, setSurveySelected] = useState(null)
  const [displayModalAction, setDisplayModalAction] = useState(false)
  const [actionSelected, setActionSelected] = useState(null)
  const toast = useRef(null);

  // console.log(selectedNode)

  const {isSearch, isDownload} = useClaimSurvey();

  useEffect(() => {
      updateTitle({
          title: 'Gestión de Encuestas',
          subtitle: 'Encuestas',
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {
        (isSearch||isDownload)&&(
          <FilterComponent />
        )
      }
          <div className='grid grid-cols-12 gap-1 h-96 mt-2'>
            <div className='col-span-5 sm:col-span-3 2xl:col-span-2 flex flex-col py-2 px-3 border-r border-gray-300'>
              <SidebarMenu 
                setSelectedNode={setSelectedNode} 
                selectedNode={selectedNode}
                //* setDisplayModal={setDisplayModal} 
                //* selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} 
              />
            </div>
            <div className='col-span-7 sm:col-span-9 2xl:col-span-10 px-3'>
              <TableComponent 
                selectedNode={selectedNode} setSelectedNode={setSelectedNode}
                surveySelected={surveySelected} setSurveySelected={setSurveySelected} 
                setDisplayModalAction={setDisplayModalAction} 
                setActionSelected={setActionSelected}
                //* setDisplayModal={setDisplayModal} 
                //* setDisplayModalAction={setDisplayModalAction}
              />
            </div>
          </div>
          <ModalActions
            displayModal={displayModalAction}  setDisplayModal={setDisplayModalAction} 
            actionSelected={actionSelected} setActionSelected={setActionSelected}
            toast={toast}
            surveySelected={surveySelected}
          />
          <Toast ref={toast} />
        <AdvancedFilterComponent /> 
    </Fragment>
  )
}

export default SearchSurveyPage