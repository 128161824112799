export const getByIdAdapter = ( data ) => ({
  id : data.id,
  name : data.name,
  type : data.type,
  typeCode : data.typeCode,
  statusCode : data.statusCode,
  description : data.description,
  companyId : data.companyId,
  questions : data.questions.map(questionsAdapter),
  sections: data.sections,
})

const questionsAdapter = (question) => ({
  id : question.id,
  position : question.position,
  controlId : question.controlId,
  controlCode : question.controlCode,
  label : question.label,
  isRequired : question.isRequired,
  addRowsAllowed : question.addRowsAllowed,
  firstColumnFixed : question.firstColumnFixed,
  linkedFieldId : question.linkedFieldId,
  linkedFieldCode : question.linkedFieldCode,
  allowMultiple : question.allowMultiple,
  columns : question.columns,
  rows : question.rows,
  sectionId: question.sectionId,
  options: question.options.map(optionAdapter)
})

const optionAdapter = (option) => ({
  id: option.id,
  name: option.name,
})