import React from 'react'

const SurveyAnalysisCardLarge = ({data}) => {

  return (
    <div className='flex flex-col justify-center items-center w-full my-5 gap-y-4'>
    {
      data && <>
        {
          data.map((item) =>
            <div 
              key={item.code} className='w-full flex justify-center'
            >
              <div 
                className={`w-full px-4 hover:bg-gray-100  text-center justify-center items-center flex cursor-pointer rounded-lg border-2 shadow-xl h-28`} 
                // style={{minWidth: '20rem'}} 
                onClick={() => item.openModal()}

                data-pr-tooltip={item.description} data-pr-position="top"
              >
                <div className='w-20 flex justify-center items-center'>
                  <i className={`pi ${item.icon} h-full`} style={{fontSize: "38px", color:"#54AEFD"}}/>
                </div>
                <div className='w-full px-2 flex-1'>
                  <div className="w-full h-1/2 flex justify-start items-center text-xl">
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </>
    }
  </div>
  )
}

export default SurveyAnalysisCardLarge