const columns = [
  { field: 'title', header: 'Título' },
  { field: 'scheduleDate', header: 'Fecha' },
  { field: 'responsibleName', header: 'Responsable' },
  { field: 'status', header: 'Estado' },
];

export const TasksTableData = {
  columns
};
