import { Fragment } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import EmployeesModalFilterComponent from './EmployeesModalFilterComponent';
import PinerComponent from 'shared/components/Piner/PinerComponent';
import { Panel } from 'primereact/panel';
import useFilter from 'features/rrhh/hooks/employees/useFilter';
import useEmployees from 'features/rrhh/hooks/employees/useEmployees'
import { useClaimPeople } from 'features/rrhh/hooks/useClaimPeople';
const EmployeesFilterComponent = () => {
  const {
    openModal,
    loading,
    showPiners,
    removePiner,
    fieldName,
    filterEmployees,
    handleChange,
    values
  } = useFilter();

  const { isSearch, isDownload } = useClaimPeople();

  const {
    loadingDataManager,
    generateReport
  } = useEmployees()

  return (
    <Fragment>
      <Panel header={`${isSearch?"FILTRO POR":"DESCARGA"}`} toggleable>
        <form onSubmit={e => {
          e.preventDefault()
          filterEmployees()
        }} className="filter-components">
          <div className={`${isSearch?'visible':'invisible'}`}>
            <span className="p-float-label p-input-icon-left w-full">
              <i className="pi pi-search" />
              <InputText
                id="input-search"
                ref={fieldName}
                onChange={handleChange}
              />
              <label htmlFor="input-search">
                Buscar por apellidos y/o nombres
              </label>
            </span>
            {showPiners() && (
              <div className="filter-piners mt-3">
                {values.map(({ value, field }, index) => (
                  <PinerComponent
                    name={value}
                    field={field}
                    removePiner={removePiner}
                    key={index}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="filter-users-action">
            <div className="flex gap-3 w-full">
              {
                isSearch&&(
                  <Button
                    icon="pi pi-search"
                    type="submit"
                    loading={loading || loadingDataManager}
                    label={loading || loadingDataManager ? "Buscando" : "Buscar"}
                    disabled={loadingDataManager}
                    className="sig-button sig-primary pr-2"
                  />
                )
              }
              {
                isDownload&&(
                  <Button
                    icon="pi pi-download"
                    type="button"
                    onClick={generateReport}
                    // loading={loadingDataManager}
                    label="Descargar"
                    className="sig-button sig-success"
                  />
                )
              }
            </div>
            <div className="flex mt-3">
              {
                isSearch&&(
                  <Button
                    type="button"
                    label="Búsqueda avanzada"
                    className="sig-button  sig-secondary w-full"
                    onClick={openModal}
                  />
                )
              }
            </div>
          </div>
        </form>
      </Panel>

      <EmployeesModalFilterComponent />
    </Fragment>
  );
};

export default EmployeesFilterComponent;
