import { apiService } from "core/services/ApiService";

const searchOccupations = (criteria) => {
  return apiService.Post("customer/api/JobPosition/Search", criteria);
}

const saveOccupations = (data) => {
  return apiService.Post("customer/api/JobPosition/Save", data);
}

const getOccupationById = (occupationId) => {
  return apiService.Get("customer/api/JobPosition/GetJobPositionById?id=" + occupationId);
}

const generateReport = (payload) => {
  return apiService.Post("customer/api/JobPosition/GenerateReport", payload, {
    responseType: 'blob',
  })
}

const deleteOccupation = (occupationId) => {
  return apiService.Delete("customer/api/JobPosition/Delete?id=" + occupationId);
}

export const OccupationsService = {
  searchOccupations,
  saveOccupations,
  getOccupationById,
  generateReport,
  deleteOccupation,
};