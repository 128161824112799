import { useState } from "react";

const useSolutions = () => {
  
  const [menuData, setMenuData] = useState([])
  const [solOptions, setSolOptions] = useState([])
  const [error, setError] = useState(false)

  const isChecked = (item, modulo) => {
    if (solOptions.length === 0) return false;
    if (solOptions.length > 0) {
       return solOptions.some(solOption => solOption.parentKey === modulo.key && solOption.optionId === item.id && solOption.isSelected)
    }
    return false;
  };

  const isSolutionOptionsEmpty = solOptions.length === 0

  const handleChange = (e, modulo, item) => {
    const id = e.target.id;
    const ids = solOptions
    const solutionKey = modulo.key
    let solutionOptions = [];
    if (ids.length === 0) {
      const solutionOption = {
        parentKey: solutionKey,
        optionId: id,
        isSelected: e.value
      }
      solutionOptions.push(solutionOption);
      setError(false)
    } 
    else {
      const hasOption = ids.some(solOption => solOption.parentKey === solutionKey && solOption.optionId === id );
      if (hasOption) {
        //solutionOptions = ids.filter((solOption) =>  !(solOption.parentKey === solutionKey && solOption.optionId === id));
         ids.forEach(c => {
          if (c.parentKey == solutionKey && c.optionId === id)
            c.isSelected = false;
        });
        solutionOptions=ids;
        if (ids.length === 1) {
          setError(true)
        }
      } 
      else {
        const solutionOption = {
          parentKey: solutionKey,
          optionId: id,
          isSelected: e.value
        }
        solutionOptions = [...ids, solutionOption];
        setError(false)
      }
    }
    setSolOptions( [ ...solutionOptions] );
  }

  const getOptionsList = (isLoading, dataOptions) => {
    if (!isLoading) {
      // const options = dataOptions.options ? dataOptions.options : []
      const options = dataOptions.length > 0 ? dataOptions : []
      const menus = options.reduce((acc, option) => {
        acc.push(...option.subMenus)
        return acc
      }, [])
      return menus
    }
    return []
  }

  const getMenuList = (isLoading, dataOptions) => {
    if (!isLoading) {

      const options = dataOptions.options ? dataOptions.options : []
      setMenuData(options)
    }
  }

  return {
    solOptions,
    isSolutionOptionsEmpty,
    error, 
    menuData, 
    setMenuData,
    isChecked,
    handleChange,
    setError,
    getOptionsList,
    getMenuList,
    setSolOptions
  };
};

export default useSolutions;
