import { apiService } from 'core/services/ApiService';
const currentModule = 'customer/api/Project';

const search = ({ fields = {}, change }) => {   
  let initialFields = {
    page: 1,
    pageSize: 10,
    columnOrder: 'name',
    order: 'asc',
  };
  if (change) initialFields = { ...initialFields, ...fields };
  return apiService.Post(`${currentModule}/Search`, initialFields);
};

const save = (payload) => {
  return apiService.Post(`${currentModule}/Save`, payload);
};

const getById = (id) => {
  return apiService.Get(`${currentModule}/GetProjectById?id=${id}`);
};

const deleteById = (id) => {
  return apiService.Delete(`${currentModule}/Delete?id=${id}`);
};

const getDataMaster = () => {
  return apiService.Post(`${currentModule}/GetDataMaster`, {
    isProjectLine: true,
    isProjectCode: true,
    isCountry: true,
    isDepartment: true,
    isCity: true,
    isDistrict: true,
    isDocumentType: true,
    isContactPosition: true
  });
};

const generateReport = (payload) => {
  return apiService.Post(`${currentModule}/GenerateReport`, payload, {
    responseType: 'blob',
  });
};

export const ProjectServices = {
  search,
  save,
  getById,
  deleteById,
  generateReport,
  getDataMaster,
};
