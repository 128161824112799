import { DataTable } from "primereact/datatable"
import { ControlGeneralConfiguration } from "./ControlGeneralConfiguration"
import { Column } from "primereact/column"
import { InputSwitch } from "primereact/inputswitch"
import { useState } from "react"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"

const TableComponent = ({data, headers, fixFirstColumn}) => (
  <DataTable
    value={data || []}
    size={fixFirstColumn && "small"}
    responsiveLayout="scroll"
  >
    {
      headers.map( (column, index) => <Column key={column.id} field={column.header} header={column.header}
        style={ (index === 0 && fixFirstColumn) ? {background: "#DEE2E6"} : {}}/>)
    }
  </DataTable>
)

export const TableGridConfiguration = ({question, onChangeOptions, removeField}) => {
  const [allowAddRows, setAllowAddRows] = useState(question.allowAddRows);
  const [required, setRequired] = useState(question.required);
  const [isContactList, setIsContact] = useState(question.isContactList);
  const [fixFirstColumn, setFixFirstColumn] = useState(question.fixFirstColumn);
  const [columnHeaders, setColumnHeaders] = useState(JSON.parse(JSON.stringify(question.columns)))
  const [rowTitles, setRowTitles] = useState(JSON.parse(JSON.stringify(question.rows)))
  const [error, setError] = useState({
    status: false,
    errors: []
  })

  const dataFixFirstColumn = rowTitles.map(row => {
    const rowData = {
      id: row.id,
    };
  
    columnHeaders.forEach((column, index) => {
      rowData[column.header] = (index === 0) ? row.title : "";
    });
  
    return rowData;
  });

  const dataAllowAddRows = [1].map(row => {
    const rowData = {
      id: row,
    };
  
    columnHeaders.forEach((column, index) => {
      rowData[column.header] = " ";
    });
  
    return rowData;
  });

  const data = fixFirstColumn ? dataFixFirstColumn : dataAllowAddRows


  const changeHeaderColumn = (id) => (e) => {
    setColumnHeaders( prevColumHeaders => {
      return prevColumHeaders.map( column => {
        if(id === column.id) column.header = e.target.value
        return column
      })
    })
  }

  const deleteHeaderColumn = (id) => () => {
    if(isContactList) return;
    setColumnHeaders( prevColumHeaders => {
      return prevColumHeaders.filter( column => id !== column.id)
    })
  }

  const addColumn = (e) => {
    setColumnHeaders(prevColumnHeaders => {
      const newId = Math.min(...prevColumnHeaders.map(column => column.id), 0) - 1
      const newPosition = Math.max(...prevColumnHeaders.map(column => column.position), 0) + 1
      const newOption = {
        id: newId,
        position: newPosition,
        header: null,
      }
      return [...prevColumnHeaders, newOption]
    })
  }

  const changeTitleRow = (id) => (e) => {
    setRowTitles( prevsetRowTitles => {
      return prevsetRowTitles.map( row => {
        if(id === row.id) row.title = e.target.value
        return row
      })
    })
  }

  const deleteTitleRow = (id) => () => {
    setRowTitles( prevsetRowTitles => {
      return prevsetRowTitles.filter( row => id !== row.id)
    })
  }

  const addRow = (e) => {
    setRowTitles(prevsetRowTitles => {
      const newId = Math.min(...prevsetRowTitles.map(row => row.id), 0) - 1
      const newPosition = Math.max(...prevsetRowTitles.map(row => row.position), 0) + 1
      const newOption = {
        id: newId,
        position: newPosition,
        title: null,
      }
      return [...prevsetRowTitles, newOption]
    })
  }

  const changeOptionsAditional = () => {
    if(columnHeaders.length <= 0){
      setError({
        status: true,
        errors: ["Debe existir por lo menos una columna"]
      })
      return true;
    }
    if((fixFirstColumn || !allowAddRows) && rowTitles.length <= 0){
      setError({
        status: true,
        errors: ["Debe existir por lo menos una fila."]
      })
      return true;
    }

    const statusColumn = columnHeaders.map(o => o.header?.trim()).some( n => n === "" || n === null || n === undefined)
    if(statusColumn){
      setError({
        status: true,
        errors: ["Existen elementos vacíos en las columnas."]
      })
      return true;
    }

    const statusRow = rowTitles.map(o => o.title?.trim()).some( n => n === "" || n === null || n === undefined)
    if(statusRow && fixFirstColumn){
      setError({
        status: true,
        errors: ["Existen elementos vacíos en las filas."]
      })
      return true;
    }

    setError({
      status: false,
      errors: []
    })
    onChangeOptions({field: "required", value: required, id: question.id})
    onChangeOptions({field: "allowAddRows", value: allowAddRows, id: question.id})
    onChangeOptions({field: "fixFirstColumn", value: fixFirstColumn, id: question.id})
    onChangeOptions({field: "isContactList", value: isContactList, id: question.id})
    onChangeOptions({field: "columns", value: JSON.parse(JSON.stringify(columnHeaders)), id: question.id})
    onChangeOptions({field: "rows", value: JSON.parse(JSON.stringify(rowTitles)), id: question.id})
  }

  const onCancel = () => {
    setAllowAddRows(question.allowAddRows);
    setRequired(question.required);
    setFixFirstColumn(question.fixFirstColumn);
    setColumnHeaders(JSON.parse(JSON.stringify(question.columns)))
    setRowTitles(JSON.parse(JSON.stringify(question.rows)))
    setError({
      status: false,
      errors: []
    })
  }

  const handleSetIsContact = (e) => {
    if(e.value){
      setAllowAddRows(true);
      setFixFirstColumn(false);
      setRequired(true);
      setColumnHeaders([{
          id: -1,
          position: 1,
          header: "Nombre",
        },
        {
          id: -2,
          position: 2,
          header: "Correo",
        },
        {
          id: -3,
          position: 3,
          header: "Teléfono",
        },
        {
          id: -4,
          position: 4,
          header: "Cargo",
        }
      ])
      setRowTitles([])
    } else {
      setAllowAddRows(question.allowAddRows);
      setRequired(question.required);
      setFixFirstColumn(question.fixFirstColumn);
      setColumnHeaders(JSON.parse(JSON.stringify(question.columns)))
      setRowTitles(JSON.parse(JSON.stringify(question.rows)))
      setError({
        status: false,
        errors: []
      })
    }
  }

  return (
    <ControlGeneralConfiguration
      question={question}
      onChangeOptions={onChangeOptions}
      render={() => <TableComponent data={data} headers={columnHeaders} fixFirstColumn={fixFirstColumn}/>}
      changeOptionsAditional={changeOptionsAditional}
      onCancel={onCancel}
      removeField={removeField}
      headerTitle="TABLA"
    >
      <>
        <div className='flex items-center col-span-2 mt-4'>
          <InputSwitch
            disabled={isContactList}
            checked={allowAddRows}
            name="allowAddRows"
            onChange={(e) => {
              setAllowAddRows(e.value)
              if(fixFirstColumn) setFixFirstColumn(false)
            }}
          />
          <h5 className='ml-2'>Permitir Aumentar Filas</h5>
        </div>
        <div className='flex items-center mt-4 col-span-2 mb-4'>
          <InputSwitch
            disabled={isContactList}
            checked={fixFirstColumn}
            name="fixFirstColumn"
            onChange={(e) => {
              setFixFirstColumn(e.value)
              if(allowAddRows) setAllowAddRows(false)
            }}
          />
          <h5 className='ml-2'>Primera Columna Fija</h5>
        </div>
        <div className='flex items-center mt-4 col-span-2 mb-4'>
          <InputSwitch
            checked={isContactList}
            name="required"
            onChange={(e) => {
              setIsContact(e.value)
              handleSetIsContact(e)
            }}
          />
          <h5 className='ml-2'>Lista de contactos</h5>
        </div>
        <div className='flex items-center mt-4 col-span-2 mb-4'>
          <InputSwitch
            disabled={isContactList}
            checked={required}
            name="required"
            onChange={(e) => {
              setRequired(e.value)
            }}
          />
          <h5 className='ml-2'>Requerido</h5>
        </div>
        <div className='mb-2'>
          <Button
            disabled={isContactList}
            type="button"
            label='Añadir Columna'
            icon="pi pi-plus"
            className="sig-button sig-primary mt-2"
            onClick={addColumn}
          />
          {
            columnHeaders.map( column => (
              <div key={column.id} className='col-span-2 w-f flex items-center'>
                <span className="p-float-label w-2/3 pt-2 pb-2 mr-4">
                  <InputText 
                    disabled={isContactList}
                    className='rounded-lg border-indigo-400 border-2'
                    value={column.header || ""}
                    onChange={changeHeaderColumn(column.id)}
                  />
                </span>
                <i
                  className='pi pi-trash block ml-2 pr-10'
                  style={{'fontSize': '1.5em'}}
                  onClick={deleteHeaderColumn(column.id)}
                />
              </div>
            ))
          }
        </div>

        {
          !allowAddRows &&
          <div className='mb-2'>
            <Button
              type="button"
              label='Añadir Fila'
              icon="pi pi-plus"
              className="sig-button sig-primary mt-2"
              onClick={addRow}
            />
            {
              rowTitles.map( row => (
                <div key={row.id} className='col-span-2 w-f flex items-center'>
                  <span className="p-float-label w-2/3 pt-2 pb-2 mr-4">
                    <InputText 
                      className={`rounded-lg border-indigo-400 border-2${fixFirstColumn ?"":" disabled"}`}
                      value={row.title || ""}
                      onChange={changeTitleRow(row.id)}
                      disabled={!fixFirstColumn}
                    />
                  </span>
                  <i
                    className='pi pi-trash block ml-2 pr-10'
                    style={{'fontSize': '1.5em'}}
                    onClick={deleteTitleRow(row.id)}
                  />
                </div>
              ))
            }
            {
              error.status &&
              <div className='message error'>
                {
                  error.errors.map( message => <li key={message} className="p-error">{message}</li>)
                }
              </div>
            }
          </div>
        }

      </>
    </ControlGeneralConfiguration>
  )
}