import { CoreConstants } from "core/commons/CoreConstants";
import moment from "moment";

const getCreationDate = (rowData) => { return moment(rowData.creationDate).format(CoreConstants.Format.LOCALE); }

const columnsGrid = [
  { field: 'userName', header: 'Usuario' },
  { field: 'firstName', header: 'Nombres' },
  { field: 'lastName', header: 'Apellidos' },
  { field: 'creationDate', header: 'Fecha Creación', body: getCreationDate },
];

const columns = [
  { field: 'roleName', header: 'Rol' },
  { field: 'quantityUsers', header: 'N° Usuarios' },
  { field: 'quantityPermissions', header: 'N° Permisos' },
  { field: 'isSystem', header: 'Sistema' },
  { field: 'creationDate', header: 'Fecha Creación', body: getCreationDate },
];

export const RolesTableData = {
  columns,
  columnsGrid,
};
