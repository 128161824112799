import { apiService } from "core/services/ApiService";

const searchManagment = (criteria) => {
  return apiService.Post("management/api/DashboardIndicator/Search", criteria);
}

const saveIndicators = (data) => {
  return apiService.Post("management/api/DashboardIndicator/Save", data);
}

const getManagmentById = (managmentId) => {
  return apiService.Get("management/api/DashboardIndicator/GetDashboardContainerById?indicatorId=" + managmentId);
}

const getdataTableIndicatorById = (id) => {
  return apiService.Get("management/api/Indicator/GetValuesByIndicatorId?id=" + id);
}

const getdataGraphics = (id,from, to) => {
  let urlsend = `management/api/Indicator/GetValuesAndPeriodByIndicatorIdDateRange?id=${id}`
  if(from){
    urlsend+= `&from=${from}`
  }
  if(to){
    urlsend+= `&to=${to}`
  }
  return apiService.Get(urlsend);
}

const generateReport = (payload) => {
  return apiService.Post("management/api/DashboardIndicator/GenerateReport", payload, {
    responseType: 'blob',
  })
}

const deleteManagment = (id) => {
  return apiService.Delete("management/api/DashboardIndicator/Delete?id=" + id);
}

const getDataMaster = () => {
  return apiService.Post("management/api/DashboardIndicator/GetDataMaster");
}

export const ManagmentService = {
  searchManagment,
  saveIndicators,
  getManagmentById,
  getdataTableIndicatorById,
  generateReport,
  deleteManagment,
  getDataMaster,
  getdataGraphics
};