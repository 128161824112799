import { useEffect, Fragment } from 'react'
import { useHistory } from 'react-router';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { Button } from 'primereact/button';

import ModulesTableComponent from 'features/subscription/components/modulesIso/ModulesIsoTableComponent';
import ModulesIsoFilterComponent from 'features/subscription/components/modulesIso/ModulesIsoFilterComponent';

const SearchModuleIsoPage = (props) => {
  const history = useHistory();

  const { updateTitle } = useSetTitlePage();
  useEffect(() => {
    updateTitle({
      title: 'Gestión de Modulos ISO',
      subtitle: 'Gestión de Modulos',
    });
  }, []);
  return (
    <Fragment>
      <ModulesIsoFilterComponent />
      <Button
        icon="pi pi-plus"
        type="button"
        label="Nuevo"
        className="sig-button sig-dark mt-4"
        onClick={() => {
          history.push('/suscripcion/modulo_iso/nuevo');
        }}
      />
      <ModulesTableComponent />

    </Fragment>
  );
};


export default SearchModuleIsoPage;
