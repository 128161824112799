import { ConfigurationConstants } from "features/configuration/commons/ConfigurationConstants";

const initialState = {
  process: {
    isLoading: false,
    data: null,
  },
  processData: {
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false,
    data: null,
  },
  listProcess: {
    isLoading: false,
    data: []
  },
  // formularies: {
  //   data: null,
  //   isSaving: false,
  //   isSaved: false,
  //   isDeleted: false,
  //   isLoading: false
  // },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'name',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {}
  },
  listControls: {
    data: null,
    isLoading: false
  },
  dataMaster: {
    data: null,
    // controlUser: [],
    // controlData: [],
    // controlProcess: [],
    isLoading: false
  },
  // nextStepStatus: false,
  stepStatus: {
    isSended: false,
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case ConfigurationConstants.Accion.DinamicProcess.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
    case ConfigurationConstants.Accion.DinamicProcess.UPDATE_FILTER:
      return {
          ...state,
          advancedFilter: state.advancedFilter
      };
    case ConfigurationConstants.Accion.DinamicProcess.RESET_AFTER_SAVED:
      return {
          ...state,
          process: {
              data: null,
              isLoading: false,
          },
          processData: {
            isSaving: false,
            isSaved: false,
            isDeleted: false,
            isLoading: false,
            data: null,
          },
          advancedFilter: {
            showModal: false,
            values: [],
            columnOrder: 'name',
            order: 'asc',
            page: 1,
            pageSize: 10,
            filter: {}
          },
          stepStatus: {
            isSended: false,
          }
      };
    //* No Implementados
    case ConfigurationConstants.Accion.DinamicProcess.RESTORE_PROCESS:
      return {
          ...state,
          process: {
            data: null,
            isSaving: false,
            isSaved: false,
            isDeleted: false,
            isLoading: false
          }
        };

    case ConfigurationConstants.Accion.DinamicProcess.DELETE_PROCESS:
      return {
          ...state,
          occupation: {
              isDeleted: action.payload.isDeleted
          }
      };

    case ConfigurationConstants.Accion.DinamicProcess.DOWNLOAD_REPORT_STATUS:
      return {
          ...state,
          occupation: {
              ...state,
              isLoading: action.payload.isLoading,
          },
      };

    //* Reducer Implementados
    case ConfigurationConstants.Accion.DinamicProcess.LIST_CONTROLS:
      return {
          ...state,
          listControls: {
              data: action.payload.data,
              isLoading: action.payload.isLoading
          }
        };
    case ConfigurationConstants.Accion.DinamicProcess.GET_CONFIG_DINAMIC_PROCESS_BY_IDENTIFIER:
      return {
          ...state,
          process: {
              ...state.process,
              data: action.payload.data,
              isLoading: action.payload.isLoading
          }
        };
      case ConfigurationConstants.Accion.DinamicProcess.SEARCH_DYNAMIC_PROCESS:
        return {
            ...state,
            listProcess: {
                isLoading: action.payload.isLoading,
                data: action.payload.data
            }
        };
      case ConfigurationConstants.Accion.DinamicProcess.GET_DYNAMIC_PROCESS_BY_ID:
        return {
            ...state,
            processData: {
                ...state.processData,
                data: action.payload.data,
                isLoading: action.payload.isLoading
            }
          };
      case ConfigurationConstants.Accion.DinamicProcess.SAVE_PROCESS_DATA:
        return {
            ...state,
            processData: {
                ...state.processData,
                isSaved: action.payload.isSaved,
                isSaving: action.payload.isSaving,
            }
          };
      case ConfigurationConstants.Accion.DinamicProcess.NEXT_STEP:
      case ConfigurationConstants.Accion.DinamicProcess.BACK_STEP:
        return {
            ...state,
            stepStatus: {
                isSended: action.payload.isSended,
            }
          };

      case ConfigurationConstants.Accion.DinamicProcess.GET_DATA_MASTER:
        return {
          ...state,
          dataMaster: {
              // controlUser: action.payload.controlUser,
              // controlData: action.payload.controlData,
              // controlProcess: action.payload.controlProcess,
              isLoading: action.payload.isLoading
          }
        };
      default: 
      return state;
  }

}