import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import { Controller } from 'react-hook-form'

export const ControllerID = ({control, errors, ValidateInput, validateDuplicate, setValidateDuplicate}) => {

  return (
    <>
      <span className="col-span-2 p-float-label w-full mt-4">
          <Controller
              control={control}
              render={({
                  field: { onChange, onBlur, value }
              }) => (
                <InputText
                    type="text"
                    id="fieldLabel"
                    name="fieldLabel"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    className={errors?.fieldLabel ? 'p-invalid w-full' : 'w-full'}
                />
              )}
              name="fieldLabel"
              rules={{
                  required: 'La etiqueta del campo es requerida.',
                  validate: ValidateInput
              }}
          />
          <label htmlFor="fieldLabel">Etiqueta del campo  *</label>
      </span>
      <span>
        <p className='mt-2'>Inicio de secuencia</p>
        <Controller
              control={control}
              render={({
                  field: { onChange, onBlur, value }
              }) => (
                <InputText
                    type="number"
                    id="initialSecuency"
                    name="initialSecuency"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    className={errors?.initialSecuency ? 'p-invalid w-full' : 'w-full'}
                />
              )}
              name="initialSecuency"
              rules={{
                required: 'Inicio de secuencia requerido',
            }}
          />
      </span>
      <span className="col-span-2 p-float-label w-full mt-4">
          <div className='flex'>
          <Controller
            control={control}
            name="prefix"
            render={({
                field: { onChange, onBlur, value, name, ref },
            }) => (
                     <div className="field-checkbox flex items-center mr-3">
                        <Checkbox
                            onBlur={onBlur} // notify when input is touched
                            onChange={onChange} // send value to hook form
                            checked={value}
                            inputRef={ref}
                            />
                        <p className='ml-2'>Prefijo</p>
                     </div>
            )}
            />
            <Controller
            control={control}
            name="sufix"
            render={({
                field: { onChange, onBlur, value, name, ref },
            }) => (
                     <div className="field-checkbox flex items-center mr-3">
                        <Checkbox
                            onBlur={onBlur} // notify when input is touched
                            onChange={onChange} // send value to hook form
                            checked={value}
                            inputRef={ref}
                            />
                        <p className='ml-2'>Sufijo</p>
                     </div>
            )}
            />
          </div>
          <Controller
              control={control}
              render={({
                  field: { onChange, onBlur, value }
              }) => (
                <span className="p-input-icon-right">
                    <i className="pi pi-tags" />
                <InputText
                    type="text"
                    id="formatSecuency"
                    name="formatSecuency"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    className={`mb-4 mt-3 ${errors?.formatSecuency ? 'p-invalid w-full' : 'w-full'}`}
                    placeholder='Ejemplo "CRM"'
                />
              </span>)}
              name="formatSecuency"
          />
          <div className={`rounded bg-red-200 p-5 mt-6 text-sm text-red-600 ${validateDuplicate===false&&'hidden'}`}>
        <ul>
          <li>La etiqueta del campo, ya se encuentra en uso.</li>
        </ul>
      </div>
      </span>
    </>
  )
}
