import React from "react";
import { Switch, Route } from "react-router-dom";
import Site from "./pages/Sites/Site";
import Sites from "./pages/Sites/Sites";
import { useRouteMatch } from "react-router-dom";
import sitesReducer from "features/maintenance/store/reducers/SitesReducer";
import AcademicDegreePage from "./pages/AcademicDegree/AcademicDegree/AcademicDegreePage";
import SearchAcademicDegreePage from "./pages/AcademicDegree/search/SearhcAcademicDegreePage";
import academicDegreeReducer from "./store/reducers/AcademicDegreeReducer";
import occupationReducer from "./store/reducers/OccupationReducer";
import institutionReducer from "./store/reducers/InstitutionsReducer";
import SearchOccupationPage from "./pages/Occupations/Search/SearchOccupationPage";
import OccupationPage from "./pages/Occupations/Occupation/OccupationPage";
import SearchInstitutionsPage from "./pages/Institutions/Search/SearchInstitutionPage";
import InstitutionPage from "./pages/Institutions/Institution/InstitutionPage";
import pipelineReducer from "./store/reducers/PipelineReducer";
import professionReducer from "./store/reducers/ProfessionReducer";
import SearchProfessionPage from "./pages/Professions/Search/SearchProfessionPage";
import ProfessionPage from "./pages/Professions/Profession/ProfessionPage";
import businessLineReducer from "./store/reducers/BusinessLineReducer";
import projectRoleReducer from "./store/reducers/ProjectRoleReducer";
import SearchBusinessLinePage from "./pages/BusinessLines/Search/SearchBusinessLinePage";
import BusinessLinePage from "./pages/BusinessLines/BusinessLine/BusinessLinePage";
import businessSectorReducer from "./store/reducers/BusinessSectorReducer";
import SearchBusinessSectorPage from "./pages/BusinessSectors/Search/SearchBusinessSectorPage";
import BusinessSectorPage from "./pages/BusinessSectors/BusinessSector/BusinessSectorPage";
import PipelinePage from "./pages/Pipelines/Pipeline/PipelinePage";
import SearchPipelinePage from "./pages/Pipelines/Search/SearchPipelinePage";
import employerReducer from "./store/reducers/EmployerReducer";
import SearchEmployerPage from "./pages/Employers/Search/SearchEmployerPage";
import EmployerPage from "./pages/Employers/Employer/EmployerPage";
import ProjectRolePage from "./pages/ProjectRoles/ProjectRole/ProjectRolePage";
import SearchProjectRolePage from "./pages/ProjectRoles/Search/SearchProjectRolePage";
import { BusinessSheetTypeSearch } from "./pages/BusinessSheetTypes/BusinessSheetTypeSearch";
import businessSheetTypeReducer from "./store/reducers/BusinessSheetTypeReducer";
import { BusinessSheetTypePage } from "./pages/BusinessSheetTypes/BusinessSheetTypePage";

export default function RrhhRouting(reducerRegistry) {
  const { path } = useRouteMatch();
  reducerRegistry.register({ sitesReducer });
  reducerRegistry.register({ academicDegreeReducer });
  reducerRegistry.register({ occupationReducer });
  reducerRegistry.register({ institutionReducer });
  reducerRegistry.register({ professionReducer });
  reducerRegistry.register({ businessLineReducer });
  reducerRegistry.register({ businessSectorReducer });
  reducerRegistry.register({ pipelineReducer });
  reducerRegistry.register({ employerReducer });
  reducerRegistry.register({ projectRoleReducer });
  reducerRegistry.register({ businessSheetTypeReducer });

  return (
    <>
      <Switch>
        <Route path={`${path}/pipeline`}>
          <Switch>
            <Route
              exact
              path={`${path}/pipeline`}
              component={SearchPipelinePage}
              ren
            />
            <Route
              path={`${path}/pipeline/nuevo`}
              exact
              component={() => <PipelinePage title="Nuevo Pipeline" />}
            />
            <Route
              path={`${path}/pipeline/:id`}
              exact
              component={() => <PipelinePage title="Editar Pipeline" />}
            />
            <Route
              path={`${path}/pipeline/visualizar/:id`}
              exact
              component={() => <PipelinePage title="Ver Pipeline" mode='view'/>}
            />
          </Switch>
        </Route>

        <Route path={`${path}/ocupacion`}>
          <Switch>
            <Route
              exact
              path={`${path}/ocupacion`}
              component={SearchOccupationPage}
              ren
            />
            <Route
              path={`${path}/ocupacion/nuevo`}
              exact
              component={() => <OccupationPage title="Nueva Ocupación" />}
            />
            <Route
              path={`${path}/ocupacion/:id`}
              exact
              component={() => <OccupationPage title="Editar Ocupación" />}
            />
            <Route
              path={`${path}/ocupacion/visualizar/:id`}
              exact
              component={() => <OccupationPage title="Ver Ocupación" mode='view' />}
            />
          </Switch>
        </Route>

        <Route path={`${path}/profesion`}>
          <Switch>
            <Route
              exact
              path={`${path}/profesion`}
              component={SearchProfessionPage}
              ren
            />
            <Route
              path={`${path}/profesion/nuevo`}
              exact
              component={() => <ProfessionPage title="Nueva Profesión" />}
            />
            <Route
              path={`${path}/profesion/:id`}
              exact
              component={() => <ProfessionPage title="Editar Profesión" />}
            />
            <Route
              path={`${path}/profesion/visualizar/:id`}
              exact
              component={() => <ProfessionPage title="Ver Profesión" mode='view' />}
            />
          </Switch>
        </Route>
        <Route path={`${path}/linea-negocio`}>
          <Switch>
            <Route
              exact
              path={`${path}/linea-negocio`}
              component={SearchBusinessLinePage}
              ren
            />
            <Route
              path={`${path}/linea-negocio/nuevo`}
              exact
              component={() => (
                <BusinessLinePage title="Nueva línea de negocio" />
              )}
            />
            <Route
              path={`${path}/linea-negocio/:id`}
              exact
              component={() => (
                <BusinessLinePage title="Editar línea de negocio" />
              )}
            />
            <Route
              path={`${path}/linea-negocio/visualizar/:id`}
              exact
              component={() => (
                <BusinessLinePage title="Ver línea de negocio" mode='view' />
              )}
            />
          </Switch>
        </Route>
        <Route path={`${path}/rubro`}>
          <Switch>
            <Route
              exact
              path={`${path}/rubro`}
              component={SearchBusinessSectorPage}
              ren
            />
            <Route
              path={`${path}/rubro/nuevo`}
              exact
              component={() => <BusinessSectorPage title="Nuevo rubro" />}
            />
            <Route
              path={`${path}/rubro/:id`}
              exact
              component={() => <BusinessSectorPage title="Editar rubro" />}
            />
            <Route
              path={`${path}/rubro/visualizar/:id`}
              exact
              component={() => <BusinessSectorPage title="Ver rubro" mode='view' />}
            />
          </Switch>
        </Route>
        <Route path={`${path}/grado-academico`}>
          <Switch>
            <Route
              exact
              path={`${path}/grado-academico`}
              component={SearchAcademicDegreePage}
              ren
            />
            <Route
              path={`${path}/grado-academico/nuevo`}
              exact
              component={() => (
                <AcademicDegreePage title="Nuevo Grado Académico" />
              )}
            />
            <Route
              path={`${path}/grado-academico/:id`}
              exact
              component={() => (
                <AcademicDegreePage title="Editar Grado Académico" />
              )}
            />
            <Route
              path={`${path}/grado-academico/visualizar/:id`}
              exact
              component={() => (
                <AcademicDegreePage title="Ver Grado Académico" mode='view' />
              )}
            />
          </Switch>
        </Route>
        <Route path={`${path}/sede`}>
          <Route exact path={`${path}/sede`} component={Sites} ren />
          <Route
            path={`${path}/sede/editar/:id`}
            component={() => <Site title="Editar Sede" />}
          />
          <Route
            path={`${path}/sede/visualizar/:id`}
            component={() => <Site title="Ver Sede" mode='view' />}
          />
          <Route
            path={`${path}/sede/nuevo`}
            component={() => <Site title="Nueva Sede" />}
          />
        </Route>
        <Route path={`${path}/institucion`}>
          <Switch>
            <Route
              exact
              path={`${path}/institucion`}
              component={SearchInstitutionsPage}
              ren
            />
            <Route
              path={`${path}/institucion/nuevo`}
              exact
              component={() => <InstitutionPage title="Nueva Institución" />}
            />
            <Route
              path={`${path}/institucion/:id`}
              exact
              component={() => <InstitutionPage title="Editar Institución" />}
            />
            <Route
              path={`${path}/institucion/visualizar/:id`}
              exact
              component={() => <InstitutionPage title="Ver Institución" mode='view' />}
            />
          </Switch>
        </Route>
        <Route path={`${path}/empleador`}>
          <Switch>
            <Route
              exact
              path={`${path}/empleador`}
              component={SearchEmployerPage}
              ren
            />
            <Route
              path={`${path}/empleador/nuevo`}
              exact
              component={() => <EmployerPage title="Nuevo Empleador" />}
            />
            <Route
              path={`${path}/empleador/:id`}
              exact
              component={() => <EmployerPage title="Editar Empleador" />}
            />
            <Route
              path={`${path}/empleador/visualizar/:id`}
              exact
              component={() => <EmployerPage title="Ver Empleador" mode='view' />}
            />
          </Switch>
        </Route>

        <Route path={`${path}/rol-proyecto`}>
          <Switch>
            <Route
              exact
              path={`${path}/rol-proyecto`}
              component={SearchProjectRolePage}
              ren
            />
            <Route
              path={`${path}/rol-proyecto/nuevo`}
              exact
              component={() => <ProjectRolePage title="Nuevo Rol" />}
            />
            <Route
              path={`${path}/rol-proyecto/:id`}
              exact
              component={() => <ProjectRolePage title="Editar Rol" />}
            />
            <Route
              path={`${path}/rol-proyecto/visualizar/:id`}
              exact
              component={() => <ProjectRolePage title="Ver Rol" mode='view' />}
            />
          </Switch>
        </Route>

        <Route path={`${path}/ficha`}>
          <Switch>
            <Route
              exact
              path={`${path}/ficha`}
              component={BusinessSheetTypeSearch}
            />
            <Route
              path={`${path}/ficha/nuevo`}
              exact
              component={() => <BusinessSheetTypePage title="Nuevo Tipo de Ficha de Negocio" />}
            />
            <Route
              path={`${path}/ficha/:id`}
              exact
              component={() => <BusinessSheetTypePage title="Editar Tipo de Ficha de Negocio" />}
            />
            <Route
              path={`${path}/ficha/visualizar/:id`}
              exact
              component={() => <BusinessSheetTypePage title="Ver Tipo de Ficha de Negocio" mode='view'/>}
            />
          </Switch>
        </Route>
      </Switch>
    </>
  );
}
