import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PanelMenu } from "primereact/panelmenu";
import "./SidebarComponent.scss";
import useCurrenItem from "shared/hooks/useCurrenItem";

const SidebarComponent = () => {
  const usuarioInformation = useSelector((state) => state.authReducer.user);
  const [menus, setMenus] = useState([]);

  const { selectItem, selectSubItems } = useCurrenItem();

  useLayoutEffect(() => {
    if (usuarioInformation?.menuPrincipal?.length > 0) {
      let options = usuarioInformation.menuPrincipal.map((item) => {
        const items = item?.subMenus.map((subItem) => ({
          label: subItem.descripcion,
          url: "#" + subItem.url,
        }));

        return {
          label: item.descripcion,
          icon: item.icono,
          items,
        };
      });
      setMenus(options);
    }
  }, [usuarioInformation]);

  useLayoutEffect(() => {
    if (menus?.length > 0) selectItem();
  }, [menus]);

  document.querySelectorAll(".p-submenu-list a").forEach((i) => {
    i.addEventListener("click", selectSubItems());
  });

  return (
    <div className="sidebar">
      <PanelMenu model={menus} />
    </div>
  );
};

export default SidebarComponent;
