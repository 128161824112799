const columns = [
  { field: 'code', header: 'Código' },
  { field: 'name', header: 'Nombre' },
  { field: 'stageName', header: 'Etapa' },
  { field: 'businessValue', header: 'Valor' },
  { field: 'closeDate', header: 'Fecha de Cierre' },
];

export const BusinessTableData = {
  columns
};
