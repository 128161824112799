import { apiService } from 'core/services/ApiService';

const currentModule = 'management/api/ApplicationSetting';

const getByCode = (code) => {
  return apiService.Get(
    `${currentModule}/GetApplicationSettingsByCode?code=${code}`
  );
};

const getCurrencyType = () => {
  return apiService.Get(`${currentModule}/GetApplicationSettingsCurrencyType`);
};

const getColors = () => {
  return apiService.Get(`${currentModule}/GetApplicationSettingsColors`);
};

const getLogos = () => {
  return apiService.Get(`${currentModule}/GetApplicationSettingsLogos`);
};

const getUnitsOfMeasure = () => {
  return apiService.Get(
    `${currentModule}/GetApplicationSettingsUnitsOfMeasure`
  );
};

const save = (payload) => {
  return apiService.Post(`${currentModule}/Save`, payload);
};

const updateLogo = (payload) => {
  return apiService.Post(`${currentModule}/SaveLogo`, payload);
};

export const ApplicationSettingsService = {
  getByCode,
  getCurrencyType,
  getColors,
  getLogos,
  getUnitsOfMeasure,
  save,
  updateLogo,
};
