import { ProjectsActions } from 'features/projects/store/actions/ProjectsActions'
import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { useSelector } from "react-redux";

const useProject = () => {
  const [visibleGenerate, setVisibleGenerate] = useState(false);
  const [visibleSuccess, setVisibleSuccess] = useState(false)
  const dispatch = useDispatch()
  const { loadingReport } = useSelector(
    (state) => state.projectsReducer.taskEdit
  );

  const showGenerateModal = () => setVisibleGenerate(true);
  const closeGenerateModal = () => setVisibleGenerate(false);
  const showSuccessModal = () => setVisibleSuccess(true)
  const closeSuccessModal = () => setVisibleSuccess(false)

  const deleteProject = (id) => {
    dispatch(ProjectsActions.deleteProjectById(id))
  }
  const downloadReport = (idProyect) => {
    dispatch(ProjectsActions.generateViewReport(idProyect))
  }
  const downloadViewProjectReport = (idProyect) => {
    dispatch(ProjectsActions.generateViewProjectReport(idProyect))
  }
  const generateSnapshot = ({projectId, name, showModal, closeGenerateModal}) => {
    dispatch(ProjectsActions.generateSnapshot({projectId, name, showModal, closeGenerateModal}))
  }
  return {
    loadingReport,
    deleteProject,
    downloadReport,
    downloadViewProjectReport,
    generateSnapshot,
    visibleGenerate,
    visibleSuccess,
    showGenerateModal,
    closeGenerateModal,
    showSuccessModal,
    closeSuccessModal,
  }
}


export default useProject