import { useHistory, useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AttendanceAction } from "features/coursesManagment/store/actions/AttendanceAction";
import scrollTop from "shared/utils/scrollTop";
import { v4 as uuidv4 } from "uuid";
import AttendanceReducer from "features/coursesManagment/store/reducers/AttendanceReducer";

export const useAttendance = (title) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const currentId = params.id;
  const { data, isLoadingDataMaster } = useSelector(
    (state) => state.attendanceReducer.dataMaster
  );
  useEffect(() => {
    dispatch(AttendanceAction.getDataMaster());
  }, []);

  const [attendanceList, setAttendanceList] = useState([]);

  useEffect(() => {
    scrollTop();
  }, []);

  const createAttendance = () => {
    setAttendanceList((prev) => [
      ...prev,
      {
        studentId: null,
        enrollmentDate: null,
        statusId: null,
        //rowStatus:true,
        id: uuidv4(),
      },
    ]);
  };
  const updateAttendance = ({ id, name, value }) => {
    const currentAttendanceList = attendanceList.map((attendance) => {
      if (attendance.id === id) attendance[name] = value;
      return attendance;
    });
    setAttendanceList(currentAttendanceList);
  };
  const attendanceDelete = (id) => {
    const currentAttendanceList = attendanceList.filter(
      (attendance) => attendance.id !== id
    );
    setAttendanceList(currentAttendanceList);
  };

  return {
    isLoadingDataMaster,
    data,
    setAttendanceList,
    attendanceList,
    createAttendance,
    updateAttendance,
    attendanceDelete,
  };
};
