const columns = [
  { field: 'entityName', header: 'Entidad' },
  { field: 'companyName', header: 'Clientes' },
  { field: 'createdBy', header: 'Creado por' },
  { field: 'status', header: 'Estado' },
  { field: 'creationDate', header: 'Fecha de Creación' },
];

const columnsFields = [
  { field: 'typeId', header: 'Tipo' },
  { field: 'label', header: 'Etiqueta' },
  { field: 'sectionId', header: 'Sección' },
  { field: 'statusId', header: 'Estado' },
  { field: 'isRequired', header: 'Mandatorio' },
]

export const DynamicFieldsTableData = {
  columns,
  columnsFields,
};
