import React from 'react'

export const SurveyPublicSection = ({title, description, index, totalSections}) => {
  return (
    <div className='flex flex-col mt-4'>
      {/* <div className='flex justify-between p-2 border-2 border-b-0 border-gray-300 menu-component text-white rounded-t-lg'> */}
      <div className='flex justify-between p-2 border-2 border-b-0 border-gray-300 section-survey-component text-white rounded-t-lg'>
      {/* <div className='flex justify-center p-2 border-2 border-b-0 border-gray-300 bg-gray-100 rounded-t-lg'>*/}
        <h2 className='font-semibold'>{`Sección ${index} de ${totalSections}`}</h2>
      </div> 
      {/* Body de la seccion */}
      <div className='px-5 py-3 border-2 bg-gray-100'>
        <div className="flex flex-col">
            {/* Titulo de la sección */}
            <div className="flex flex-col gap-y-3">
                {/* <label className="mb-1 font-semibold">Título de la sección</label> */}
                <div className='w-full'>
                  <h2 className='font-semibold text-lg'>{title}</h2>
                </div>
                <div className='w-full'>
                  <h2>{description}</h2>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}
