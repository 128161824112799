import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

export const useClaimDocumentManagment = () => {

  const menu = useSelector((state) => state.authReducer.user.menuPrincipal);
  const pathName = useLocation()?.pathname;
  const [isSetConfig, setConfig] = useState(false);
  const [isRename, setRename] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isCreate, setCreate] = useState(false);

  useEffect(() => {
    remove();
    create();
    config();
    rename();
  }, [menu]);

  const config = () => {
    const actionCode = 'DOCUMENT_ADMIN_VIEW';
    const optionCode = "DADM";
    setConfig(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };
  const rename = () => {
    const actionCode = 'DOCUMENT_ADMIN_RENAME';
    const optionCode = "DADM";
    setRename(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };
  const remove = () => {
    const actionCode = 'DOCUMENT_ADMIN_DELETE';
    const optionCode = "DADM";
    setDelete(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  const create = () => {
    const actionCode = 'DOCUMENT_ADMIN_CREATE';
    const optionCode = "DADM";
    setCreate(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
 };

  return {
    isCreate,
    isDelete,
    isRename,
    isSetConfig
  }
}