import { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';

import FilterComponent from 'features/configuration/components/roles/FilterComponent';
import TableComponent from 'features/configuration/components/roles/TableComponent';

import { RolesAction } from '../../store/actions/RolesAction';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import useTable from 'features/configuration/hooks/roles/useTable';
import 'shared/styles/components/tables.scss';
import { useClaimRolesPrivileges } from 'features/configuration/hooks/useClaimRolesPrivileges';

const RolesPrivilegioPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const rolesInformation = useSelector((state) => state.roleReducer.roles);
  const { updateTitle } = useSetTitlePage();
  const { isOpen } = useSelector((state) => state.roleReducer.deleteRoleModal);
  const { cancelDelete, confirmDelete } = useTable();
  const { isSearch, isDownload, isCreate } = useClaimRolesPrivileges()

  useEffect(() => {
    updateTitle({
      title: 'Seguridad',
      subtitle: 'Roles y Privilegios',
    });
  }, []);

  useEffect(() => {
    const { pagination } = rolesInformation;

    const hasInformation = Object.values(pagination)?.length > 0;
    if (!hasInformation) {
      dispatch(RolesAction.getRoles({ change: false }));
    }
  }, []);

  return (
    <Fragment>
      {
        (isSearch||isDownload)&&(
          <FilterComponent />
        )
      }
      {
        isCreate&&(
          <Button
            icon="pi pi-plus"
            type="button"
            label="Nuevo"
            className="sig-button sig-dark mt-4"
            onClick={() => {
              history.push('/configuracion/rol-privilegios/nuevo');
            }}
          />
        )
      }
      <TableComponent />

      <ConfirmDialog
        visible={isOpen}
        onHide={cancelDelete}
        message="¿Está seguro que desea eliminar el registro?"
        header="Eliminar"
        icon="pi pi-trash"
        accept={confirmDelete}
        reject={cancelDelete}
      />
    </Fragment>
  );
};

export default RolesPrivilegioPage;
