/* eslint-disable import/no-anonymous-default-export */

import { IndicatorsConstants } from "features/indicators/commons/IndicatorsConstans";

const initialState = {
  dataMaster: {
    isLoading: false,
    indicatorFrecuency: [],
    indicatorGoal: [],
    indicatorSources: [],
    indicatorType: [],
    indicatorUnitMeasure: [],
    responsibles: [],
    indicatorStatus: []
  },
  monitoringValues: {
    valuesData: [],
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false
  },
  monitoringDownload: {
    isLoading: false
  },
  goal: {
    goalType: null,
    goalTypeRange: null,
    goalValue: null,
    maxGoalValue: null,
    isLoading: false
  },
  monitoringChart: {
    dataSets: [],
    labels: [],
    isLoading: false
  },
  monitorings: {
    isLoading: false,
    data: []
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'code',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {}
  },
  comments: {
    data: [],
    isLoading: false,
  },
  comment: {
    isLoading: false,
    isSaved: false,
    isDeleted: false,
  },
  actionPlan: {
    data: null,
    isLoading: false,
    isSaved: false,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case IndicatorsConstants.Accion.Monitoring.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
    case IndicatorsConstants.Accion.Monitoring.UPDATE_FILTER:
      return {
          ...state,
          advancedFilter: state.advancedFilter
      };

    case IndicatorsConstants.Accion.Monitoring.SEARCH_MONITORING:
        return {
            ...state,
            monitorings: {
                isLoading: action.payload.isLoading,
                data: action.payload.data
            }
        };

    case IndicatorsConstants.Accion.Monitoring.DELETE_MONITORING:
        return {
            ...state,
            monitoring: {
                isDeleted: action.payload.isDeleted
            }
        };

    case IndicatorsConstants.Accion.Monitoring.RESET_AFTER_SAVED:
        return {
            ...state,
            monitoring: {
                data: null,
                isSaving: false,
                isSaved: false,
                isLoading: false,
                isDeleted: false
            },
            comment: {
              isLoading: false,
              isSaved: false,
              isDeleted: false,
            },
            actionPlan: {
              data: null,
              isLoading: false,
              isSaved: false,
            },
        };

    case IndicatorsConstants.Accion.Monitoring.GET_DATA_MASTER:
        return {
            ...state,
            dataMaster: {
                isLoading: action.payload.isLoading,
                indicatorFrecuency: action.payload.indicatorFrecuency,
                indicatorGoal: action.payload.indicatorGoal,
                indicatorSources: action.payload.indicatorSources,
                indicatorType: action.payload.indicatorType,
                indicatorUnitMeasure: action.payload.indicatorUnitMeasure,
                responsibles: action.payload.responsibles,
                indicatorStatus: action.payload.indicatorStatus
            }
        };
      case IndicatorsConstants.Accion.Monitoring.DOWNLOAD_REPORT_STATUS:
          return {
              ...state,
              monitoring: {
                  ...state,
                  isLoading: action.payload.isLoading,
              },
          };

      case IndicatorsConstants.Accion.Monitoring.GET_VALUES_INDICATOR_BY_ID:
        return {
            ...state,
            monitoringValues: {
                data: action.payload.data,
                valuesData: action.payload.valuesData,
                isLoading: action.payload.isLoading
            }
          };

      case IndicatorsConstants.Accion.Monitoring.GET_GOAL:
        return {
            ...state,
            goal: {
                isLoading: action.payload.isLoading,
                goalType: action.payload.goalType,
                goalTypeRange: action.payload.goalTypeRange,
                goalValue: action.payload.goalValue,
                maxGoalValue: action.payload.maxGoalValue,
            }
          };

      case IndicatorsConstants.Accion.Monitoring.SAVE_INDICATOR_VALUES:
        return {
            ...state,
            monitoringValues: {
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };

      case IndicatorsConstants.Accion.Monitoring.GET_VALUES_INDICATOR_BY_ID_AND_RANGE:
        return {
            ...state,
            monitoringChart: {
                dataSets: action.payload.dataSets,
                labels: action.payload.labels,
                isLoading: action.payload.isLoading
            }
        };

      case IndicatorsConstants.Accion.Monitoring.GET_COMMENTS_BY_INDICATORID:
        return {
            ...state,
            comments: {
              data: action.payload.data,
              isLoading: action.payload.isLoading
            },
        };
      case IndicatorsConstants.Accion.Monitoring.DELETE_COMMENT:
        return {
            ...state,
            comment: {
              isDeleted: action.payload.isDeleted,
              isLoading: action.payload.isLoading,
            },
        };
      case IndicatorsConstants.Accion.Monitoring.ADD_COMMENT:
        return {
            ...state,
            comment: {
              isSaved: action.payload.isSaved,
              isLoading: action.payload.isLoading,
            },
        };
      case IndicatorsConstants.Accion.Monitoring.GET_INDICATOR_ACTION_PLAN_BY_ID:
        return {
            ...state,
            actionPlan: {
              data: action.payload.data,
              isLoading: action.payload.isLoading
            },
        };
      case IndicatorsConstants.Accion.Monitoring.SAVE_INDICATOR_ACTION_PLAN:
        return {
            ...state,
            actionPlan: {
              isSaved: action.payload.isSaved,
            },
        };

      case IndicatorsConstants.Accion.Monitoring.DOWNLOAD_FOLLOW_UP_REPORT:
        return {
            ...state,
            monitoringDownload: {
                isLoading: action.payload.isLoading,
            },
        };


      default: 
      return state;
  }

}