import React, { useRef, useState } from 'react'
import useSurveyQuestion from 'features/survey/hooks/Survey/useSurveyQuestion'
import { Checkbox } from 'primereact/checkbox'
import { Editor } from 'primereact/editor'
import { RadioButton } from 'primereact/radiobutton'
import { Controller } from 'react-hook-form'
import HeaderEditor from 'shared/components/header-editor/HeaderEditor'
import { QuestionCheckBoxes, QuestionDropdown, QuestionMultipleChoice, QuestionRanking, QuestionText } from './QuestionComponents'
import './SurveyQuestion.scss'
import { useSelector } from 'react-redux'
import { Menu } from 'primereact/menu';
import { ConfirmDialog } from 'primereact/confirmdialog'
import { InputSwitch } from 'primereact/inputswitch'

const options = [
  {caption: 'Otra' , id: '1a', position: 998}, 
  {caption: 'Ninguna de las Anteriores' , id: '2a', position: 999}
];

const SurveyQuestion = ({question, index, provided, surveyData, listSections, setListSections, addNewQuestionAfterSelectedQuestion, deleteQuestionInSection, sectionId, sectionListQuestion}) => {

  // const { control, errors, valuesFields, existError, setValue, handleSubmit, reset, validateLength} = useSurveyQuestion()
  const { isCloseStatus } = useSelector((state) => state.surveyReducer.survey);
  const { control, valuesFields, validateLength, addOption, addOptionRanking, listOptions} = useSurveyQuestion(question, isCloseStatus, listSections, setListSections, sectionId)
  const {questionTypes} = useSelector((state) => state.surveyReducer.dataMaster);
  const header = HeaderEditor();
  const menu = useRef(null);
  const [showConfirmDialog, setshowConfirmDialog] = useState(false);


  // console.log(valuesFields)
  // console.log(question)
  
  // Cambio de texto body de la pregunta segun la sección a la que pertenece listSections
  const onTextChange = (e, onChange) => {

    const newListSectionQuestion = listSections.map(section => {
      if (section.id === sectionId) {
        const newListQuestion = section.listQuestion.map(option => {
          if (option.id === question.id) {
            return {
              ...option,
              body: e.htmlValue
            }
          }
          return option
        })
        return {
          ...section,
          listQuestion: newListQuestion
        }
      }
      return section
    })
    onChange(e.htmlValue)
    setListSections(newListSectionQuestion)

    // const newListQuestion = listQuestion.map(option => {
    //   if (option.id === question.id) {
    //     return {
    //       ...option,
    //       body: e.htmlValue
    //     }
    //   }
    //   return option
    // })

    // onChange(e.htmlValue)
    // setListQuestion(newListQuestion)
  }

  const onTypeChange = (e, onChange) => {
    const newListSectionQuestion = listSections.map(section => {
      if (section.id === sectionId) {
        const newListQuestion = section.listQuestion.map(option => {
          if (option.id === question.id) {
            return {
              ...option,
              questionTypeId: e.value,
              surveyQuestionOptions: (e.value.code === 'TX' || e.value.code === 'RE') ? [] : option.surveyQuestionOptions
            }
          }
          return option
        })
        return {
          ...section,
          listQuestion: newListQuestion
        }
      }
      return section
    })
    onChange(e.value)
    setListSections(newListSectionQuestion)

    // const newListQuestion = listQuestion.map(option => {
    //   if (option.id === question.id) {
    //     return {
    //       ...option,
    //       questionTypeId: e.value,
    //       surveyQuestionOptions: (e.value.code === 'TX' || e.value.code === 'RE') ? [] : option.surveyQuestionOptions
    //     }
    //   }
    //   return option
    // })

    // onChange(e.value)
    // setListQuestion(newListQuestion)
  }

  const onOptionsChange = (e) => {
    if (e.checked) {
        //* addOption(e.value.text)
        // const newListQuestion = listQuestion.map(option => {
        //   if (option.id === question.id) {
        //     return {
        //       ...option,
        //       extraOptions: [...option.extraOptions, e.value]
        //     }
        //   }
        //   return option
        // })
        // setListQuestion(newListQuestion)

        const newListSectionQuestion = listSections.map(section => {
          if (section.id === sectionId) {
            const newListQuestion = section.listQuestion.map(option => {
              if (option.id === question.id) {
                return {
                  ...option,
                  extraOptions: [...option.extraOptions, e.value]
                }
              }
              return option
            })
            return {
              ...section,
              listQuestion: newListQuestion
            }
          }
          return section
        })
        setListSections(newListSectionQuestion)

    } else {
        // const newListQuestion = listQuestion.map(item => {
        //   if (item.id === question.id) {
        //     return {
        //       ...item,
        //       // extraOptions: item.extraOptions.filter(option => option.id !== e.value.id)
        //       extraOptions: item.extraOptions.filter(option => option.position !== e.value.position)
        //     }
        //   }
        //   return item
        // })
        // setListQuestion(newListQuestion)
        //* deleteOptionExtra(e.value.text)

        const newListSectionQuestion = listSections.map(section => {
          if (section.id === sectionId) {
            const newListQuestion = section.listQuestion.map(item => {
              if (item.id === question.id) {
                return {
                  ...item,
                  extraOptions: item.extraOptions.filter(option => option.position !== e.value.position)
                }
              }
              return item
            })
            return {
              ...section,
              listQuestion: newListQuestion
            }
          }
          return section
        })
        setListSections(newListSectionQuestion)
    }
  }

  const onRequiredChange = (e) => {
    const newListSectionQuestion = listSections.map(section => {
      if (section.id === sectionId) {
        const newListQuestion = section.listQuestion.map(item => {
          if (item.id === question.id) {
            return {
              ...item,
              isRequired: e.value
            }
          }
          return item
        })
        return {
          ...section,
          listQuestion: newListQuestion
        }
      }
      return section
    })
    setListSections(newListSectionQuestion)
  }

  let items = [
    {
        label: 'Agregar Pregunta',
        icon: 'pi pi-pencil',
        command: (e) => {
            // dispatch(SurveyAction.getSurveyById(surveySelected.id));
            // console.log('Agregar pregunta')
            addNewQuestionAfterSelectedQuestion(sectionId, question.id)
            // setDisplayModal(true)
        }
    },
    {
        label: 'Eliminar Pregunta',
        icon: 'pi pi-times',
        command: (e) => {
          // deleteQuestion(question.id)
          setshowConfirmDialog(true);
          // console.log('Eliminar')
        }
    },
  ];
  
  return (
    <div className='flex flex-col mb-2 bg-white'>
      {/* Header */}
      <div {...provided.dragHandleProps} className='flex justify-between p-2 border-2 border-b-0 border-gray-300 bg-gray-100'>
        <h2 className='font-semibold'>{`Pregunta ${index}`}</h2>
        <button type='button' disabled={isCloseStatus}>
          {/* <i className={`pi pi-trash block mr-1 ${isCloseStatus ? '' : 'cursor-pointer hover:text-gray-900'}`} style={{'fontSize': '1.3em'}} onClick={() => deleteQuestion(question.id)}/> */}
          <i
              className={`pi pi-ellipsis-v block mr-1 ${isCloseStatus ? '' : 'cursor-pointer hover:text-gray-900'}`}
              onClick={(event) => {
                  menu.current.toggle(event)
              }}
          ></i>
        </button>
      </div>
    {/* Body de la pregunta */}
      <div className='px-2 py-3 border-2 border-gray-300'>
        <div className="flex flex-col">
          {/* Nombre de la pregunta */}
          <div className='flex items-center my-1 justify-end'>
            <InputSwitch 
              inputId={`isRequired_${question?.id}`} 
              disabled={isCloseStatus} 
              name="extraOptions" 
              value={question.isRequired} 
              onChange={onRequiredChange} 
              checked={question.isRequired} 
            />
            <label htmlFor={`isRequired_${question?.id}`} className='ml-2'>
              Requerido
            </label>
          </div>
          <div className='my-2'>
            <Controller
              control={control}
              // rules={{ required: 'El plan de acción es requerido'}}
              render={({ field: { onChange, value } }) => (
              <>
                <Editor
                    headerTemplate={header}
                    className='survey-editor'
                    // value={value} onTextChange={(e) => onChange(e.htmlValue)}
                    onTextChange={(e) => onTextChange(e, onChange)}
                    value={question?.body}
                    placeholder="Texto de la pregunta"
                    readOnly={surveyData?.status.code === 'SQCL'}
                />
                <p className="font-semibold text-base text-gray-500 lg:mt-1 mt-3 text-right">
                    Caracteres{' '}
                    {validateLength(valuesFields.body)}{' '}/ 4000{' '}
                </p>
              </>
              )}
              name="body"
            /> 
          </div>
          {/* Tipo de pregunta */}
          <div className='my-2'>
            <span className="p-float-label w-full mt-2">
              <Controller
                control={control}
                rules={{required: 'El origen del indicador es requerido'}}
                render={({
                    field: { onChange }
                }) => (
                  <div className='w-full flex flex-wrap lg:flex-nowrap justify-evenly gap-4'>
                    {
                      questionTypes.map((type) => (
                          <div className='flex justify-center items-center gap-x-2' key={`questionType_${type.id}_${question?.id}`} >
                            {/* <RadioButton inputId={`questionType_${type.id}_${question?.id}`} value={type} onChange={(e) => onTypeChange(e, onChange)} checked={valuesFields.type?.id === type.id} /> */}
                            <RadioButton inputId={`questionType_${type.id}_${question?.id}`} disabled={isCloseStatus} value={type} onChange={(e) => onTypeChange(e, onChange)} checked={question.questionTypeId?.code === type.code} />
                            <p>{type.description}</p>
                          </div>
                        )
                      )
                    }
                  </div>
                )}
                name="type"
              />
            </span>
          </div>
        </div>
          {
            (question.questionTypeId?.code === 'TX') && (
              <QuestionText />
            )
          }
          {
            (question.questionTypeId?.code === 'OM') && (
              <QuestionMultipleChoice addOption={addOption} listOptions={listOptions} />
            )
          }
          {
            (question.questionTypeId?.code === 'CV') && (
              <QuestionCheckBoxes addOption={addOption} listOptions={listOptions} />
            )
          }
          {
            (question.questionTypeId?.code === 'LD') && (
              <QuestionDropdown addOption={addOption} listOptions={listOptions} />
            )
          }
          {
            (question.questionTypeId?.code === 'RE') && (
              <QuestionRanking addOptionRanking={addOptionRanking} question={question} />
            )
          }
          {
            // ((valuesFields.type?.code !== 'text' || valuesFields.type?.description === 'Texto') && valuesFields.type) && 
            // (valuesFields.type?.description !== 'Texto' && valuesFields.type) && 
            (question.questionTypeId?.code !== 'TX' && question.questionTypeId) && 
            (
              <div className="flex flex-col py-2 px-3 border-2 rounded mt-3">
                {
                  options.map((option) => {
                    return (
                      <div key={option.id}>
                        {/* <Checkbox inputId={`extraOption_${option.id}_${question?.id}`} name="extraOptions" value={option} onChange={onOptionsChange} checked={question.surveyQuestionOptions.some((item) => item.name === option.text)} /> */}
                        {/* <Checkbox inputId={`extraOption_${option.id}_${question?.id}`} name="extraOptions" value={option} onChange={onOptionsChange} checked={question.extraOptions.some((item) => item.id === option.id)} /> */}
                        <Checkbox inputId={`extraOption_${option.id}_${question?.id}`} disabled={isCloseStatus} name="extraOptions" value={option} onChange={onOptionsChange} checked={question.extraOptions.some((item) => item.caption === option.caption)} />
                        <label htmlFor={`extraOption_${option.id}_${question?.id}`} className='ml-2'>
                          {option.caption === 'Otra' ? 'Añadir opción "Otra" al final' : 'Añadir opción "Ninguna de las Anteriores" al final'}
                        </label>
                      </div>
                    )
                  })
                }
              </div>
            )
          }
      </div>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <ConfirmDialog
          visible={showConfirmDialog}
          onHide={() => setshowConfirmDialog(false)}
          message="¿Está seguro que desea eliminar la pregunta?"
          header="Eliminar"
          icon="pi pi-trash"
          accept={() => deleteQuestionInSection(sectionId, question.id)}
          reject={() => setshowConfirmDialog(false)}
      />
    </div>
  )
}

export default SurveyQuestion