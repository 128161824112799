import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import FilterComponent from 'features/configuration/components/dinamicProcess/dinamicProcessView/FilterComponent';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { DinamicProcessAction } from 'features/configuration/store/actions/DinamicProcessAction';
import AdvanceFilterComponent from 'features/configuration/components/dinamicProcess/dinamicProcessView/AdvanceFilterComponent';
import TableComponent from 'features/configuration/components/dinamicProcess/dinamicProcessView/TableComponent';

const DinamicProcessViewPage = ({title}) => {
    const history = useHistory();
    const params = useParams();
    const {data: processSelected} = useSelector(state => state.dinamicProcessReducer.process)
    const listControls = useSelector(state => state.dinamicProcessReducer.listControls.data)
    const {isSended} = useSelector(state => state.dinamicProcessReducer.stepStatus)
    const { updateTitle } = useSetTitlePage();
    const dispatch = useDispatch();
    
    useEffect(() => {
        updateTitle({
            title: title,
            subtitle: processSelected?.name,
        });
        // dispatch(ProcessesAction.restoreSaved())
        if(!listControls){
          dispatch(DinamicProcessAction.searchListControls())
        }
    }, [processSelected]);

    useEffect(()=> {
      if (params.id){
        // dispatch(DinamicProcessAction.getProcessById(params.id))
        dispatch(DinamicProcessAction.resetAfterSaved())
        dispatch(DinamicProcessAction.getConfigDynamicProcessByIdentifier(params.id))
        dispatch(DinamicProcessAction.searchDynamicProcess(params.id))
      }
      
    }, [params.id])

    useEffect(() => {
      if (isSended) {
        dispatch(DinamicProcessAction.resetAfterSaved())
        dispatch(DinamicProcessAction.searchDynamicProcess(params.id))
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSended])

    return (
        <Fragment>
          <FilterComponent />
          <div className='my-4'>
                <Button
                    icon="pi pi-plus"
                    type="button"
                    label="Nuevo"
                    className="sig-button sig-dark"
                    onClick={() => {
                        history.push(`/proceso-dinamico/${params.id}/nuevo`);
                    }}
                />
                
          </div>
          <TableComponent />
          <AdvanceFilterComponent />
        </Fragment>
    );
};

export default DinamicProcessViewPage
