import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import TableComponent from 'features/configuration/components/processes/TableComponent';
import AdvancedFilterComponent from 'features/configuration/components/processes/AdvancedFilterComponent';
import FilterComponent from 'features/configuration/components/processes/FilterComponent';
import { ProcessesAction } from 'features/configuration/store/actions/ProcessesAction';


const SearchProcessesPage = () => {
    const history = useHistory();

    const { updateTitle } = useSetTitlePage();
    // const [isFirstLoading, setFirstLoading] = useState(true);
    const dispatch = useDispatch();
    //comentario test
    useEffect(() => {
        updateTitle({
            title: 'Configuración',
            subtitle: 'Procesos',
        });
        dispatch(ProcessesAction.restoreSaved())
        dispatch(ProcessesAction.searchListControls())
        dispatch(ProcessesAction.searchResponsibles())
    }, []);

    return (
        <Fragment>
            <FilterComponent />
            <Button
                icon="pi pi-plus"
                type="button"
                label="Nuevo"
                className="sig-button sig-dark mt-4"
                onClick={() => {
                    history.push('/configuracion/procesos/nuevo');
                }}
            />
            <TableComponent />
            <AdvancedFilterComponent />
        </Fragment>
    );
};

export default SearchProcessesPage;