import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Controller } from "react-hook-form";

export const FieldTable = ({ name, type, row, updateRow, required, details, isMultiple, fieldCode, controlValidation }) => {
  if (type === 'TEXTO' || type === 'NUMERO') {
      return (
        <Controller
          control={controlValidation}
          rules={{ required: 'Los valores de la tabla son requeridos'}}
          defaultValue={row[name]}
          render={({
              field: { onChange, value, onBlur },
              fieldState: { error }
          }) => (
            <InputText
              type={type === 'TEXTO' ? 'text' : 'number'}
              placeholder='Ingrese valor'
              name={name}
              value={row[name]}
              onChange={(e) => {
                onChange(e)
                updateRow({
                  id: row.id,
                  value: e.target.value,
                  name: name,
                  type,
                  fieldCode,
                })}
              }
              className={error ? 'p-invalid' : ''}
            />
            )
          }
          name={`${name}_${row.id}`}
        />)
              
                  
  } else if (type === 'FECHA') {
      return (
        <Controller
          control={controlValidation}
          rules={{ required: 'Los valores de la tabla son requeridos'}}
          defaultValue={row[name]}
          render={({
              field: { onChange, value, onBlur },
              fieldState: { error }
          }) => (
          <Calendar
              // monthNavigator
              // yearNavigator
              // yearRange="1950:2050"
              showIcon={true}
              value={row[name] === null ? null : new Date(row[name])}
              name={name}
              placeholder='Ingrese fecha'
              className={error ? 'p-invalid w-full' : 'w-full'}
              onChange={(e) => {
                  onChange(e)
                  updateRow({
                      id: row.id,
                      value: e.value,
                      name: name,
                      type,
                      fieldCode,
                  })}
              }
          ></Calendar>)
        }
        name={`${name}_${row.id}`}
      />
      )
  } else if (type === 'FECHA_HORA') {
    return (
      <Controller
          control={controlValidation}
          rules={{ required: 'Los valores de la tabla son requeridos'}}
          defaultValue={row[name]}
          render={({
              field: { onChange, value, onBlur },
              fieldState: { error }
          }) => (
            <Calendar
              // monthNavigator
              // yearNavigator
              // yearRange="1950:2050"
              showIcon={true}
              showTime={true}
              value={row[name] === null ? null : new Date(row[name])}
              name={name}
              placeholder='Ingrese fecha'
              className={error ? 'p-invalid w-full' : 'w-full'}
              onChange={(e) => {
                  onChange(e)
                  updateRow({
                      id: row.id,
                      value: e.value,
                      name: name,
                      type,
                      fieldCode,
                  })}
              }
            ></Calendar>
            )
          }
          name={`${name}_${row.id}`}
        />
    )
  } else if (type === 'USUARIOS') {
    if(isMultiple){
      return (
        <Controller
        control={controlValidation}
        rules={{ required: 'Los valores de la tabla son requeridos'}}
        defaultValue={row[name]}
        render={({
            field: { onChange, value, onBlur },
            fieldState: { error }
        }) => (
          <MultiSelect
            value={row[name]} 
            options={details} 
            onChange={(e)=>{
              onChange(e)
              updateRow({
                id: row.id,
                value: e.value,
                name: name,
                type,
                fieldCode,
              })
            }} 
            className={error ? 'p-invalid' : ''}
            optionLabel="text" 
            maxSelectedLabels={3} 
            placeholder={`Seleccione el responsable${required?"*":""}`}
            
            style={{height: 35.19, fontSize: 12.8}}
            // className={errors?.[control.identifier] ? 'p-invalid w-full' : 'w-full'}
            // readOnly={ permissionReadOnly }
            // disabled={ permissionReadOnly }
          />
          )
        }
        name={`${name}_${row.id}`}
      />
      )
    }
    
    return (
      <Controller
        control={controlValidation}
        rules={{ required: 'Los valores de la tabla son requeridos'}}
        defaultValue={row[name]}
        render={({
            field: { onChange, value, onBlur },
            fieldState: { error }
        }) => (
          <Dropdown
            value={row[name].value ? row[name].value : row[name]} 
            // options={temporalData} 
            options={details} 
            // // options={control.details} 
            onChange={(e)=>{
              onChange(e)
              updateRow({
                id: row.id,
                value: e.value,
                name: name,
                type,
                fieldCode,
              })
            }} 
            optionLabel="text" 
            className={error ? 'p-invalid' : ''}
            // placeholder='Seleccione el usuario'
            placeholder={`Seleccione el responsable${required?"*":""}`}
            // className={errors?.[control.identifier] ? 'p-invalid w-full' : 'w-full'}
            // readOnly={ permissionReadOnly }
            // disabled={ permissionReadOnly }
          />
          )
        }
        name={`${name}_${row.id}`}
      />
    )
  }else{
    return (
      <Controller
      control={controlValidation}
      rules={{ required: 'Los valores de la tabla son requeridos'}}
      defaultValue={row[name]}
      render={({
          field: { onChange, value, onBlur },
          fieldState: { error }
      }) => (
        <InputText 
          type='text'
          placeholder='Ingrese valor'
          name={name}
          value={row[name]}
          onChange={(e) => {
              onChange(e)
              updateRow({
                  id: row.id,
                  value: e.target.value,
                  name: name,
                  type,
                  fieldCode,
              })}
          }
          className={error ? 'p-invalid' : ''}
        />
        )
      }
      name={`${name}_${row.id}`}
    />
    )
  }
};