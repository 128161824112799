import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const TableRowComponent = ({
  action,
  code,
  value,
  id,
  deleteUnit,
  changeAction,
  changeField,
  currentUnitID,
  isLoadingSave,
  currentAction,
  isError,
}) => {
  const classes = 'bg-transparent border-0 border-transparent';
  const isSaveAction = action === 'save';

  const changeActionTo = action === 'save' ? 'edit' : 'save';
  const isLoadingEdit = currentAction === 'save' && currentUnitID === id;
  const isLoadingDelete = currentAction === 'delete' && currentUnitID === id;

  return (
    <div className="table-item">
      <InputText
        value={code}
        className={`${isSaveAction ? classes : ' '} ${
          isError && !code ? 'p-invalid' : ' '
        }`}
        name="code"
        maxLength={200}
        onChange={({ target }) => changeField(id, target)}
        required
      />
      <InputText
        value={value}
        name="value"
        className={`${isSaveAction ? classes : ''} ${
          isError && !value ? 'p-invalid' : ' '
        }`}
        maxLength={3}
        onChange={({ target }) => changeField(id, target)}
        required
      />
      <div className="flex gap-2">
        <Button
          label={isSaveAction ? 'Editar' : 'Guardar'}
          onClick={() => changeAction(id, changeActionTo)}
          icon={isSaveAction ? 'pi pi-pencil' : 'pi pi-save'}
          type="button"
          loading={isLoadingEdit && isLoadingSave}
          className="p-button-outlined p-button-secondary"
        />

        <Button
          label="Eliminar"
          type="button"
          icon="pi pi-trash"
          onClick={() => deleteUnit(id)}
          loading={isLoadingDelete && isLoadingSave}
          className="p-button-outlined p-button-danger"
        />
      </div>
    </div>
  );
};

export default TableRowComponent;
