import { useRef, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTable } from "shared/hooks/useTable";
import { CoreConstants } from "core/commons/CoreConstants";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { Skeleton } from "primereact/skeleton";
import { SelectButton } from "primereact/selectbutton";
import { Menu } from "primereact/menu";

import { CanvaComponent } from "./CanvaComponent";
import { getFilterValuesFromPiners } from "features/maintenance/helpers/businessSheetType/BusinessSheetTypeHelper";
import { BusinessSheetTypeAction } from "features/maintenance/store/actions/BusinessSheetTypeAction";
import { ConfirmModal } from "shared/components/confirmModal/ConfirmModal";

export const TableComponent = () => {
  const {
    pageSize,
    onPageChange,
    first,
    rowCount,
    columnOrder,
    onSort,
    order,
    searchData,
  } = useTable("name", getFilterValuesFromPiners, BusinessSheetTypeAction.search);

  const data = useSelector((state) => state.businessSheetTypeReducer.businessSheet.data);
  const loading = useSelector(
    (state) => state.businessSheetTypeReducer.businessSheet.loading
  );
  const actions = useRef(null);
  const confirmRef = useRef();

  const [currentBusinessSheet, setCurrentBusinessSheet] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);
  const typeViews = [
    { icon: "pi pi-list", value: CoreConstants.TypeView.LIST },
    { icon: "pi pi-microsoft", value: CoreConstants.TypeView.CANVA },
  ];

  const columns = [
    { field: "name", header: "Nombre" },
    { field: "creationDate", header: "Fecha de Creación" },
  ];

  const getViewField = (rowData) => (
    <Link
      className="underline text-blue-700"
      to={{ pathname: `/mantenimiento/ficha/visualizar/` + rowData.id }}
    >
      {rowData.name}
    </Link>
  );

  const ColumnComponents = columns.map((col) => {
    if (col.field === "name")
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={getViewField}
          sortable
        />
      );

    return <Column key={col.field} field={col.field} header={col.header} sortable />;
  });

  const confirmDelete = () => {
    dispatch(BusinessSheetTypeAction.remove(currentBusinessSheet, searchData));
  };

  let items = [
    {
      label: "Editar",
      icon: "pi pi-pencil",
      command: (e) => {
        history.push("/mantenimiento/ficha/" + currentBusinessSheet);
      },
    },
    {
      label: "Eliminar",
      icon: "pi pi-times",
      command: (e) => {
        confirmRef.current.openModal({
          header: "Eliminar",
          message: "¿Está seguro que desea cancelar",
          icon: "pi pi-trash",
          onConfirm: confirmDelete,
        });
      },
    },
  ];

  const selectedRow = (event, { id }) => {
    setCurrentBusinessSheet(id);
    actions.current.toggle(event);
  };

  const TableBodyActions = (selectedItem) => {
    return (
      <i
        onClick={(event) => selectedRow(event, selectedItem)}
        className="pi pi-ellipsis-v cursor-pointer"
      ></i>
    );
  };

  if (loading) return <Skeleton height="8rem" />;

  return (
    <>
      <div className="flex justify-end mb-5">
        <SelectButton
          unselectable={false}
          value={typeView}
          options={typeViews}
          onChange={(e) => setTypeView(e.value)}
          itemTemplate={(option) => {
            return <i className={option.icon}></i>;
          }}
        />
      </div>
      {typeView === CoreConstants.TypeView.LIST ? (
        <DataTable
          value={data}
          emptyMessage="No se encontraron Tipos de Ficha de Negocio"
          stripedRows
          rowHover
          size="small"
          responsiveLayout="scroll"
          onSort={onSort}
          sortField={columnOrder}
          sortOrder={order}
        >
          {ColumnComponents}
          <Column body={TableBodyActions}></Column>
        </DataTable>
      ) : (
        <CanvaComponent
          data={data}
          selectedRow={selectedRow}
        />
      )}

      <Paginator
        first={first}
        rows={pageSize}
        onPageChange={onPageChange}
        totalRecords={rowCount}
        className="paginator-custom mt-4"
        rowsPerPageOptions={[5, 10, 15]}
      />

      <Menu model={items} popup ref={actions} />

      <ConfirmModal ref={confirmRef} />
    </>
  );
};
