import { Panel } from "primereact/panel";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { BusinessSheetQuestion } from "./BusinessSheetQuestion";
import { WrapperFieldConfiguration } from "shared/components/controlFields/WrapperFieldConfiguration";
import { BusinessSheetSection } from "./BusinessSheetSection";

export const Preguntas = ({
  questions,
  addQuestion,
  removeQuestion,
  onChangeOptions,
  removeField,
  addSection,
  sections,
  removeSection,
  deleteSection,
  onChangeDescriptionSection,
}) => {
  const getListStyleOptions = (isDraggingOver) => ({
    border: isDraggingOver ? "1px dashed rgb(75 85 99)" : "none",
    padding: "10px",
    margin: "0 0 0 0",
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    userSelect: "none",
    background: isDragging ? "rgba(239, 246, 255)" : "none",
  });

  const renderQuestions = () => {
    return questions.map((question, index) => {
      if (question.sectionId !== null && question.sectionId !== undefined) {
        const section = sections.find((s) => s.id === question.sectionId);
        const isFirstInSection =
          index === 0 || questions[index - 1].sectionId !== question.sectionId;

        if (isFirstInSection) {
          const sectionQuestions = questions.filter(
            (q) => q.sectionId === question.sectionId
          );

          return (
            <BusinessSheetSection
              key={section?.id}
              removeSection={removeSection}
              addQuestion={addQuestion}
              section={section}
              onChangeDescriptionSection={onChangeDescriptionSection}
              index={index}
              deleteSection={deleteSection}
            >
              {sectionQuestions.map((question, indexQuestion) => {
                return (
                  <Draggable
                    key={`drop-${question.id}`}
                    draggableId={`${question.id}`}
                    index={indexQuestion}
                  >
                    {(draggableProvided, snapshot) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          draggableProvided.draggableProps.style
                        )}
                        className="flex flex-col"
                      >
                        <BusinessSheetQuestion
                          section={section}
                          addQuestion={addQuestion}
                          draggableProvided={draggableProvided}
                          index={index + indexQuestion}
                          removeQuestion={removeQuestion}
                          idQuestion={question.id}
                          withDelete={questions.length > 1 || index !== 0}
                          addSection={addSection}
                        >
                          <WrapperFieldConfiguration
                            onChangeOptions={onChangeOptions}
                            question={question}
                            droppableId={`items-pregunta-destino:${question.id}`}
                            getListStyleOptions={getListStyleOptions}
                            removeField={removeField}
                            type={"items-questions"}
                          />
                        </BusinessSheetQuestion>
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </BusinessSheetSection>
          );
        } else {
          return null; // Ya hemos manejado las preguntas de esta sección
        }
      } else {
        return (
          <Draggable
            key={`drop-${question.id}`}
            draggableId={`${question.id}`}
            index={index}
          >
            {(draggableProvided, snapshot) => (
              <div
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  draggableProvided.draggableProps.style
                )}
                className="flex flex-col"
              >
                <BusinessSheetQuestion
                  addQuestion={addQuestion}
                  draggableProvided={draggableProvided}
                  index={index}
                  removeQuestion={removeQuestion}
                  idQuestion={question.id}
                  withDelete={questions.length > 1 || index !== 0}
                  addSection={addSection}
                >
                  <WrapperFieldConfiguration
                    onChangeOptions={onChangeOptions}
                    question={question}
                    droppableId={`items-pregunta-destino:${question.id}`}
                    getListStyleOptions={getListStyleOptions}
                    removeField={removeField}
                    type={"items-questions"}
                  />
                </BusinessSheetQuestion>
              </div>
            )}
          </Draggable>
        );
      }
    });
  };

  return (
    <Panel header="PREGUNTAS" toggleable className="col-span-10 lg:col-span-10">
      <Droppable droppableId="items-destino" direction="vertical">
        {(droppableProvided, snapshot) => (
          <div
            ref={droppableProvided.innerRef}
            style={getListStyleOptions(snapshot.isDraggingOver)}
            {...droppableProvided.droppableProps}
            className="grid grid-cols-1 gap-y-2 md:gap-2 items-center w-full border border-black"
          >
            {renderQuestions()}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </Panel>
  );
};
