import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { InstitutionAction } from "features/maintenance/store/actions/InstitutionAction";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { TabPanel, TabView } from "primereact/tabview";
import { GeneralData } from "features/configuration/components/processes/Formulario";
import { ConfigurationComponent } from "features/configuration/components/processes/Configuracion/ConfigurationComponent";
import useProcesses from "features/configuration/hooks/processes/useProcesses";
import { ProcessesAction } from "features/configuration/store/actions/ProcessesAction";
import Modal from '../../../components/processes/Formulario/Modal';
import Workflow from "features/configuration/components/processes/Workflow/Workflow";
import { v4 as uuidv4 } from 'uuid';
import Permission from "features/configuration/components/processes/Permission/Permission";
import { ButtonMultipleSend } from "features/processManagement/components/ProceseSheet/ButtonMultipleSend";



const ProcessesPage = ({title}) => {
  const {updateTitle } = useSetTitlePage()
  const history = useHistory();
  const dispatch = useDispatch()
  const params = useParams();

  // const institution = useSelector((state) => state.institutionReducer.institution);
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  //Estado posición del control al destino de la etapa a la que va
  const [destinationControl, setDestinationControl] = useState(0)

  //Estados Formulario
  const [dinamicStage, setDinamicStage] = useState([])
  const [dinamicInput, setDinamicInput] = useState([])
  const [listStages,setListStage] = useState([])
  const [positionSoruce, setPositionSource] = useState(null)
  const [typeModal, setTypeModal] = useState(null)
  const [displayModal, setDisplayModal] = useState(false);
  const [optionSelected, setOptionSelected] = useState(null)
  const [inputSelected, setInputSelected] = useState(null)
  const { control, errors, valuesFields, existError, setValue, handleSubmit, validateLength} = useProcesses()

  // Estados del Workflow
  // "code": "string",
  //     "name": "string",
  //     "predecessorCode": "string",
  //     "successorCode": "string",
  //     "typeId": 0,
  //     "logicalOperatorId": 0,
  //     "conditionsForDelete": [
  //       0
  //     ],
  //     "conditions": [
  //       {
  //         "id": 0,
  //         "identifierControl": "string",
  //         "conditionalOperatorId": 0,
  //         "value": "string"
  //       }
  //     ],
  //     "rolesForDelete": [
  //       0
  //     ],
  //     "roles": [
  //       {
  //         "id": 0,
  //         "roleId": 0
  //       }
  //     ]
  const {data: dataMaster} = useSelector(state => state.processReducer.listResponsibles)
      
  const stepType = dataMaster?.stepType
  const initialProcessId = uuidv4()
  const [lastStepName, setLastStepName] = useState('')
  const [workflow, setWorkflow] = useState([{
    id: initialProcessId,
    // code: valuesFields?.name + initialProcessId,
    code: 'Inicio__' + initialProcessId,
    name: valuesFields?.name ? valuesFields?.name : 'Nombre del Proceso',
    predecessorCode: '',
    successorCode: '',
    typeId: null,
    logicalOperatorId: null,
    conditionsForDelete: [],
    conditions: [],
    rolesForDelete: [],
    roles: [],
  }])

  const getTypeByCode = (type) => {
    const result = stepType.find(item => item.code === type)
    return result.id
  }

  const getTypeById = (typeId) => {
    const result = stepType?.find(item => item.id === typeId)
    return result
  }

  useEffect(() => {
    if (valuesFields?.name && stepType) {
      const newInitialWorkFlow = workflow.map((item, index) => {
        // if (index === 0) {
        if (getTypeById(item.typeId).code === 'START') {
          return {
            ...item,
            name: valuesFields?.name
          }
        }
        return item
      })
      setWorkflow( newInitialWorkFlow )
    } 
  }, [valuesFields?.name, stepType])

  const processSelected = useSelector(state => state.processReducer.process)
  const listOptions = useSelector(state => state.processReducer.listControls.data)
  const formulary = useSelector(state => state.processReducer.formularies)


  //state de error diferentes pantallas por falta de datos
  const [stateInsufficient, setStateInsufficient] = useState('')

  //estado Configuración
  const [stateConfigPage, setStateConfigPage] = useState({advanceFilters: [],simpleFilters: [], searchColumns: []})

  const redirectToSearch = () => {
    history.push("/configuracion/procesos");
    dispatch(ProcessesAction.resetAfterSaved())
  }

  const scrollBottom = () => {
    const element = document.getElementById('content-main');
    element.scrollTop = element.scrollHeight;
  };

  //Cargar data al editar formulario
  const chargeProcess = () => {
    setValue('name', processSelected.data?.name)
    setValue('responsibleId', processSelected.data?.responsibleId)
    setValue('processDescription', processSelected.data?.description)
    setValue('statusData', {
      statusCode: processSelected.data?.statusCode,
      statusId: processSelected.data?.statusId,
      statusName: processSelected.data?.statusName
    })

    const numberOfEntries = [
      {name: 'Entrada única', code: 'entry_single'}, 
      {name: 'Entradas multiples', code: 'entry_multiple'},  
    ]

    const newSections = processSelected.data?.sections.map(elem=>{
      return {
        id: 'Dropable-'+elem.id,
        mode: "edit",
        position: elem.position,
        text: elem.title,
        dinamicInput: elem.controls.map((element)=> {
          let typeControl = ((listOptions.find((el) => el.id===element.controlId)))
          if(typeControl.code==="ID"){

            let viewID = ""
              if(element.prefix && !element.sufix){
                viewID = element.prefix + "-" + element.startSequence
              }else if (element.sufix && !element.prefix){
                viewID = element.startSequence + "-" + element.sufix
            }

            return {
              id: element.id,
              description: null,
              fieldLabel: element.text,
              formatSecuency: (!element.prefix&&!element.sufix)?"":element.prefix?element.prefix:element.sufix,
              idTypeControl: element.controlId,
              identifier: element.identifier,
              initialSecuency: element.startSequence,
              visibleSearch:typeControl?.visibleSearch,
              visibleAdvancedFilter: typeControl?.visibleAdvancedFilter,
              visibleSimpleFilter: typeControl?.visibleSearch,
              mode: "edit",
              viewID: viewID,
              prefix: element.prefix?true:false,
              required: true,
              stageId: 'Dropable-'+elem.id,
              sufix: element.sufix?true:false,
              typeOptionId: "ID",
              position: element.position,
              }
          }else if(typeControl.code==="TEXTO" || typeControl.code==="TEXTO_LARGO" || typeControl.code==="TEXTO_DINAMICO" || typeControl.code==="NUMERO" || typeControl.code==="FECHA" || typeControl.code==="FECHA_HORA" || typeControl.code==="IMAGEN" || typeControl.code==="ADJUNTAR_ARCHIVO"){
            return {
              id: element.id,
              defaultValue: null,
              fieldLabel: element.text,
              formatSecuency: null,
              visibleSearch:typeControl?.visibleSearch,
              visibleAdvancedFilter: typeControl?.visibleAdvancedFilter,
              visibleSimpleFilter: typeControl?.visibleSearch,
              position: element.position,
              stageId: 'Dropable-'+elem.id,
              idTypeControl: element.controlId,
              prefix: false,
              required: element.required,
              sufix: false,
              typeOptionId: typeControl.code,
              identifier: element.identifier,
              mode: 'edit'
            }
          }else if(typeControl.code==="LISTA_DESPLEGABLE" || typeControl.code==="SELECCION_MULTIPLE" || typeControl.code==="SELECCION_SIMPLE"){
            return {
              id: element.id,
              fieldLabel: element.text,
              idTypeControl: element.controlId,
              identifier: element.identifier,
              visibleSearch:typeControl?.visibleSearch,
              visibleAdvancedFilter: typeControl?.visibleAdvancedFilter,
              visibleSimpleFilter: typeControl?.visibleSearch,
              stageId: 'Dropable-'+elem.id,
              position: element.position,
              options: element.details.map((elem, indice)=> {
                return {
                  id: elem.id,
                  mode: 'edit',
                  name: elem.text,
                  code: (((elem.text).substr(0,2)).toUpperCase()) + "-" + indice,
                  disabled: 'disabled'
                }
              }),
              required: element.required,
              typeOptionId: typeControl.code,
              mode: 'edit'
            }
          }else if(typeControl.code==="TABLA"){
            return{
              id: element.id,
              fieldLabel: element.text,
              idTypeControl: element.controlId,
              mode: "edit",
              numRows: [''],
              visibleSearch:typeControl?.visibleSearch,
              visibleAdvancedFilter: typeControl?.visibleAdvancedFilter,
              visibleSimpleFilter: typeControl?.visibleSearch,
              option: element?.generateTasks || false,
              options: element.details.map((elem, indice)=> {
                return{
                  id: elem.id,
                  mode: 'edit',
                  name: elem.text,
                  Column: listOptions.find(elm => elm.id === elem.controlId),
                  taskField: dataMaster.taskFields.find(taskFIeld => taskFIeld.code === elem.fieldCode),
                  dataTableUsers: {
                    fieldLabel: elem.text,
                    dataSource: dataMaster?.processFormControlUserRecords.find( x => x.id === elem.dataMemberId),
                    numberOfEntry: elem.isMultiple ? numberOfEntries[1] : numberOfEntries[0],
                    required: elem.required,
                  }
                }
              }),
              readOnly: element.readonly?element.readonly:false,
              required: true,
              identifier: element.identifier,
              showHeader: element.showHeader?element.showHeader:false,
              stageId: 'Dropable-'+elem.id,
              typeOptionId: typeControl.code,
              visible: true
            }
          }else if(typeControl.code==="MONEDA"){
            return {
              id: element.id,
              fieldLabel: element.text,
              identifier: element.identifier,
              stageId: 'Dropable-'+elem.id,
              visibleSearch:typeControl?.visibleSearch,
              visibleAdvancedFilter: typeControl?.visibleAdvancedFilter,
              visibleSimpleFilter: typeControl?.visibleSearch,
              position: element.position,
              idTypeControl: element.controlId,
              options: [],
              required: element.required,
              typeOptionId: typeControl.code,
              mode: 'edit'
            }
          } else if(typeControl.code==="USUARIOS" || typeControl.code==="CONEXION_A_PROCESOS" || typeControl.code==="CONEXION_A_DATOS"){
            const dataControl = {
              id: element.id,
              defaultValue: null,
              fieldLabel: element.text,
              formatSecuency: null,
              visibleSearch:typeControl?.visibleSearch,
              visibleAdvancedFilter: typeControl?.visibleAdvancedFilter,
              visibleSimpleFilter: typeControl?.visibleSearch,
              position: element.position,
              stageId: 'Dropable-'+elem.id,
              idTypeControl: element.controlId,
              prefix: false,
              required: element.required,
              sufix: false,
              typeOptionId: typeControl.code,
              identifier: element.identifier,
              mode: 'edit',
              dataMemberId: element.dataMemberId,
              dataSourceId: element.dataSourceId,
              isMultiple: element.isMultiple,
              dataSource: dataMaster?.processFormControlUserRecords.find( x => x.id === element.dataMemberId),
            }
            if(typeControl.code==="CONEXION_A_PROCESOS") dataControl.processId = element.processId
            return dataControl
          }
        })
      }
    })

    //reordenamiento por posición
    // newSections.sort((val1, val2) => val1.position - val2.position)

    const listStag = []

    const newSections2 = newSections.map(elem1 => {
      listStag.push(elem1.id)
      // elem1.dinamicInput.sort((a, b) => a.position - b.position)
      return elem1
    })

    setStateConfigPage({
      advanceFilters: processSelected.data?.configSearch.advacedFilters,
      simpleFilters: processSelected.data?.configSearch.simpleFilters,
      searchColumns: processSelected.data?.configSearch.searchColumns})

    setListStage(listStag)
    return newSections2
  }


  // TODO: quitar id de conditions, dejar solo roleId de roles
  const getWorkFlowModel = (workflow) => {

    const lastStepModel = {
      code: lastStepName,
      name: lastStepName ? lastStepName : 'Cierre',
      predecessorCode: workflow.find(step => step.successorCode === '' || step.successorCode === null)?.code,
      successorCode: '',
      typeId: getTypeByCode('END'),
      logicalOperatorId: null,
      conditionsForDelete: [],
      conditions: [],
      rolesForDelete: [],
      roles: [],
    }

    const newWorkFlow = workflow.map((step) => {
      const newStep = { ...step }
      newStep.conditions = newStep.conditions.map(condition => {
        const { id, ...restCondition } = condition
        //* Si id es un numero, es una condicion nueva, si no es una condicion que ya existe
        if ( !isNaN(id) ) {
          restCondition.id = id
        }
        return restCondition
      })

      newStep.roles = newStep.roles.map(condition => {
        const newConditionModel = {
          roleId: condition.id,
        }
        if (condition.dbId) {
          newConditionModel.id = condition.dbId
        }
        return newConditionModel
      })

      if (step.successorCode === '') {
        newStep.successorCode = lastStepModel.code
      }

      return newStep
    })

    //TODO: Ponerle el succesorCode a los ultimos con el code de lastStepModel
    

    newWorkFlow.push(lastStepModel)

    const newWorkFlowInPlural = newWorkFlow.map((step) => {
      const newStep = { ...step }
      newStep.successorCodes = step.successorCode
      newStep.predecessorCodes = step.predecessorCode
      delete newStep.successorCode
      delete newStep.predecessorCode
      return newStep
    })

    // return newWorkFlow
    return newWorkFlowInPlural
  }

  const onSubmit = (data, typeSend) => {
    // typeSend === 1 guardar
    // typeSend === 2 guardar y revisar
    // typeSend === 3 guardar y publicar

    let verifyNull = false

    if(stateConfigPage.advanceFilters?.length===0 || stateConfigPage.simpleFilters?.length===0 || stateConfigPage.searchColumns?.length===0){
      if((typeSend === 2 || typeSend === 3)){ 
        setStateInsufficient('Configuracion: Los campos de filtrado deben tener mínimo una opción marcada')
        setActiveIndex(3)
        scrollBottom()
        return
      }
    }



    if((!data.name || (data.name).trim()==="") || (data.responsibleId===null || !data.responsibleId)){
      setStateInsufficient('El nombre del proceso y responsable son obligatorios')
      setActiveIndex(0)
      scrollBottom()
      return
    }else{
      const busqueda = dinamicStage.reduce((acc, stage) => {
        if((stage.text)?.trim()===""){
          verifyNull = true
        }else{
          acc[stage.text] = ++acc[stage.text] || 0;
        }
        return acc;
      }, {});

      const duplicados = dinamicStage.filter( (stage) => {
        return busqueda[stage.text];
      });

      if(dinamicStage.length === 0 && (typeSend === 2 || typeSend === 3)){
        setStateInsufficient('Debe existir como mínimo una etapa.')
        setActiveIndex(0)
        scrollBottom()
      }else if(verifyNull && (typeSend === 2 || typeSend === 3)){
        setStateInsufficient('Los nombres de las etapas son requeridas.')
        setActiveIndex(0)
        scrollBottom()
      }else if(duplicados.length > 0 && (typeSend === 2 || typeSend === 3)){
        setStateInsufficient('Existen nombres de etapas duplicadas.')
        setActiveIndex(0)
        scrollBottom()
      }else if(workflow.length <= 1 && (typeSend === 2 || typeSend === 3)){
        setStateInsufficient('Debe existir como mínimo un paso en el flujo.')
        setActiveIndex(0)
        scrollBottom()
      }else{
        const newData = {
          id: params?.id ? processSelected.data.processId : null,
          title: (data.name).trim(),
          responsibleId: data.responsibleId,
          description: data.processDescription ? data.processDescription.trim() : '',
        }

        


        if(typeSend === 1){ //guardar
          if (valuesFields.statusData?.statusCode === 'REVISION') {
            newData.statusCode = "REVISION"
          } else {
            newData.statusCode = "BORRADOR"
          }
        }
        if(typeSend === 2){ //guardar y revisar
          newData.statusCode = "REVISION"
        }
        if(typeSend === 3){ //guardar y publicar
          newData.statusCode = "PUBLICADO"
        }

        const newSections = dinamicStage.map((elem, ind)=>{
          return {
            id: elem.mode==="edit"?Number((elem.id).substr(9)):null,
            title: elem.text,
            subTitle: "",
            position: (ind + 1),
            controls: elem.dinamicInput?.map((el, index)=> {
              if(el.typeOptionId==='ID'){
                return {
                  id: el.mode==='edit'?Number(el.id):null,
                  text: el.fieldLabel,
                  position: (index+1),
                  prefix: (el.prefix&&el.prefix===true)?el.formatSecuency:null,
                  sufix: (el.sufix&&el.sufix===true)?el.formatSecuency:null,
                  startSequence: Number(el.initialSecuency),
                  identifier: el.identifier,
                  required: true,
                  controlId: el.idTypeControl,
                  readonly: false,
                  showHeader: true,
                  details: []
                }
              }else if(el.typeOptionId==='TEXTO' || el.typeOptionId==='TEXTO_LARGO' || el.typeOptionId==='TEXTO_DINAMICO' || el.typeOptionId==='NUMERO' || el.typeOptionId==='FECHA' || el.typeOptionId==='FECHA_HORA' || el.typeOptionId==='IMAGEN' || el.typeOptionId==='ADJUNTAR_ARCHIVO'){
                  return{
                    id: el.mode==='edit'?Number(el.id):null,
                    text: el.fieldLabel,
                    position: (index+1),
                    prefix: (el.prefix&&el.prefix===true)?el.formatSecuency:null,
                    sufix: (el.sufix&&el.sufix===true)?el.formatSecuency:null,
                    startSequence: el.initialSecuency?Number(el.initialSecuency):null,
                    identifier: el.identifier,
                    required: el.required?el.required:false,
                    controlId: el.idTypeControl,
                    readonly: false,
                    showHeader: true,
                    details: []
                  }
              }else if(el.typeOptionId==='LISTA_DESPLEGABLE' || el.typeOptionId==='SELECCION_MULTIPLE' || el.typeOptionId==='SELECCION_SIMPLE'){
                    return{
                      id: el.mode==='edit'?Number(el.id):null,
                      text: el.fieldLabel,
                      position: (index+1),
                      prefix: (el.prefix&&el.prefix===true)?el.formatSecuency:null,
                      sufix: (el.sufix&&el.sufix===true)?el.formatSecuency:null,
                      startSequence: el.initialSecuency?Number(el.initialSecuency):null,
                      identifier: el.identifier,
                      required: el.required?el.required:false,
                      controlId: el.idTypeControl,
                      readonly: false,
                      showHeader: true,
                      details: el.options.map(elem => {
                        return {
                          id: elem.mode==='edit'?Number(elem.id):null,
                          text: elem.name,
                          controlId: null
                        }
                      })
                    }
                  }
                  else if(el.typeOptionId==='TABLA'){
                    return{
                      id: el.mode==='edit'?Number(el.id):null,
                      text: el.fieldLabel,
                      position: (index+1),
                      prefix: null,
                      sufix: null,
                      startSequence: null,
                      identifier: el.identifier,
                      required: true,
                      controlId: el.idTypeControl,
                      readonly: el.readOnly?(!el.readOnly):false,
                      showHeader: el.showHeader?(el.showHeader):false,
                      generateTasks: el.option,
                      details: el.options.map(elem => {
                        if(elem.Column.code === "USUARIOS"){
                          return {
                            id: elem.mode==='edit'?Number(elem.id):null,
                            text: elem?.dataTableUsers?.fieldLabel,
                            controlId: elem.Column.id,
                            required: el.required?el.required:false,
                            details: [],
                            // isMultiple: el.numberOfEntry.code === 'entry_multiple' ? true : false,
                            fieldCode: el.option ? elem.taskField?.code : null,
                            isMultiple: elem?.dataTableUsers?.isMultiple ? elem?.dataTableUsers?.isMultiple : elem?.dataTableUsers?.numberOfEntry?.code === 'entry_multiple',
                            // dataMemberId: el.dataSource.id,
                            dataMemberId: elem?.dataTableUsers?.dataMemberId ? elem?.dataTableUsers.dataMemberId : elem?.dataTableUsers?.dataSource?.id,
                            dataSourceId: dataMaster.processFormControlDataSources.find((item) => item.code === 'PROCESS_FORM_CUSTOM_CONTROL_USER').id
                          } 
                        } else {
                          return {
                            id: elem.mode==='edit'?Number(elem.id):null,
                            text: elem.name,
                            controlId: elem.Column.id,
                            details: [],
                            fieldCode: el.option ? elem.taskField?.code : null,
                          }
                        }
                      })
                    }
                  }
                  else if(el.typeOptionId==='MONEDA'){
                    return{
                      id: el.mode==='edit'?Number(el.id):null,
                      text: el.fieldLabel,
                      position: (index+1),
                      prefix: (el.prefix&&el.prefix===true)?el.formatSecuency:null,
                      sufix: (el.sufix&&el.sufix===true)?el.formatSecuency:null,
                      startSequence: el.initialSecuency?Number(el.initialSecuency):null,
                      identifier: el.identifier,
                      required: el.required?el.required:false,
                      controlId: el.idTypeControl,
                      readonly: false,
                      showHeader: true,
                      details: [{
                        id: elem.mode==='edit'?Number(elem.id):null,
                        text: el?.selectedCoin?.typeColumn
                      }]
                    }
                  }
                  else if(el.typeOptionId==='ETIQUETA'){
                    return{
                      id: el.mode==='edit'?Number(el.id):null,
                      text: el.fieldLabel,
                      // description: el.description?el.description:'',
                      position: (index+1),
                      prefix: (el.prefix&&el.prefix===true)?el.formatSecuency:null,
                      sufix: (el.sufix&&el.sufix===true)?el.formatSecuency:null,
                      startSequence: el.initialSecuency?Number(el.initialSecuency):null,
                      identifier: el.identifier,
                      required: el.required?el.required:false,
                      controlId: el.idTypeControl,
                      readonly: false,
                      showHeader: true,
                      details: []
                    }
                  }
                  else if(el.typeOptionId==='USUARIOS'){
                    return {
                      id: el.mode==='edit'?Number(el.id):null,
                      text: el.fieldLabel,
                      position: (index+1),
                      prefix: (el.prefix&&el.prefix===true)?el.formatSecuency:null,
                      sufix: (el.sufix&&el.sufix===true)?el.formatSecuency:null,
                      startSequence: el.initialSecuency?Number(el.initialSecuency):null,
                      identifier: el.identifier,
                      required: el.required?el.required:false,
                      controlId: el.idTypeControl,
                      readonly: false,
                      showHeader: true,
                      details: [],
                      // isMultiple: el.numberOfEntry.code === 'entry_multiple' ? true : false,
                      isMultiple: el.isMultiple ? el.isMultiple : (el?.numberOfEntry?.code === 'entry_multiple' ? true : false),
                      // dataMemberId: el.dataSource.id,
                      dataMemberId: el.dataMemberId ? el.dataMemberId : el?.dataSource?.id,
                      dataSourceId: dataMaster.processFormControlDataSources.find((item) => item.code === 'PROCESS_FORM_CUSTOM_CONTROL_USER').id
                    }
                  }
                  else if(el.typeOptionId==='CONEXION_A_DATOS' ){
                    return {
                      id: el.mode==='edit'?Number(el.id):null,
                      text: el.fieldLabel,
                      position: (index+1),
                      prefix: (el.prefix&&el.prefix===true)?el.formatSecuency:null,
                      sufix: (el.sufix&&el.sufix===true)?el.formatSecuency:null,
                      startSequence: el.initialSecuency?Number(el.initialSecuency):null,
                      identifier: el.identifier,
                      required: true,
                      controlId: el.idTypeControl,
                      readonly: false,
                      showHeader: true,
                      details: [],
                      // isMultiple: el.numberOfEntry.code === 'entry_multiple' ? true : false,
                      isMultiple: el.isMultiple ? el.isMultiple : (el?.numberOfEntry?.code === 'entry_multiple' ? true : false),
                      // dataMemberId: el.tableToConnect.id,
                      dataMemberId: el.dataMemberId ? el.dataMemberId : el?.tableToConnect?.id,
                      dataSourceId: dataMaster.processFormControlDataSources.find((item) => item.code === 'PROCESS_FORM_CUSTOM_CONTROL_DATA').id
                    }
                  }
                  else if(el.typeOptionId==='CONEXION_A_PROCESOS'){
                    return {
                      id: el.mode==='edit'?Number(el.id):null,
                      text: el.fieldLabel,
                      position: (index+1),
                      prefix: (el.prefix&&el.prefix===true)?el.formatSecuency:null,
                      sufix: (el.sufix&&el.sufix===true)?el.formatSecuency:null,
                      startSequence: el.initialSecuency?Number(el.initialSecuency):null,
                      identifier: el.identifier,
                      required: true,
                      controlId: el.idTypeControl,
                      readonly: false,
                      showHeader: true,
                      details: [],
                      // isMultiple: el.numberOfEntry.code === 'entry_multiple' ? true : false,
                      isMultiple: el.isMultiple ? el.isMultiple : (el?.numberOfEntry?.code === 'entry_multiple' ? true : false),
                      dataMemberId: dataMaster.processFormControlDataMemberProcess.find((item) => item.code === 'PROCESS_FORM_CUSTOM_CONTROL_DATA_PROCESS').id,
                      processId: el.processToConnect.id,
                      dataSourceId: dataMaster.processFormControlDataSources.find((item) => item.code === 'PROCESS_FORM_CUSTOM_CONTROL_PROCESS').id
                    }
                  }
            })
          }
        })
  
        newData.sections = newSections
  
        newData.configSearch = {
          advacedFilters: stateConfigPage.advanceFilters,
          simpleFilters: stateConfigPage.simpleFilters,
          searchColumns: stateConfigPage.searchColumns
        }



        newData.workFlowSteps = getWorkFlowModel(workflow)
        
        dispatch(ProcessesAction.restoreProcessById())
        dispatch(ProcessesAction.saveProcesses(newData))
      }
    }

    return
  };

  const items = [
    {
        label: 'Guardar',
        icon: "pi pi-save",
        type: "submit",
        command: ()=>{
          handleSubmit((e)=>{onSubmit(e,1)})()
        }
    },
    {
      label: 'Guardar y Revisar',
      icon: "pi pi-eye",
      command: ()=>{
        handleSubmit((e)=>{onSubmit(e,2)})()
      }
    },
    {
      label: 'Guardar y Publicar',
      icon: "pi pi-upload",
      command: ()=>{
        handleSubmit((e)=>{onSubmit(e,3)})()
      }
    },
  ];

  const itemsWithoutReview = [
    {
      label: 'Guardar',
      icon: "pi pi-save",
      type: "submit",
      command: ()=>{
        handleSubmit((e)=>{onSubmit(e,1)})()
      }
    },
    {
      label: 'Guardar y Publicar',
      icon: "pi pi-upload",
      command: ()=>{
        handleSubmit((e)=>{onSubmit(e,3)})()
      }
    },
  ]

  useEffect(()=> {
    updateTitle({
      title: "Configuracion",
      subtitle: "Proceso",
      description: title,
      previousUrl: "/configuracion/procesos",
    })
    if(!listOptions){
      dispatch(ProcessesAction.searchListControls())
    }
  }, [])

  const reject = () => {
      setVisible(false);
  };

  //revisa si el formulario se guardó
  useEffect(()=> {
    if(formulary?.isSaved){
      setDinamicStage([])
      history.push("/configuracion/procesos");
    }
  },[formulary.isSaved])

  //Cargar data al iniciar
  useEffect(()=> {
    if (params.id && listOptions && (processSelected.data) && (dinamicStage?.length===0)){
      let charginData = chargeProcess()
      if(charginData){
        setDinamicStage(charginData)
      }
    }
  },[processSelected.data])

  const ComponentButtons = () => (
    <div className="flex justify-end gap-4 mt-3 mr-1">
        <Button
            icon="pi pi-times"
            type="button"
            label="Cancelar"
            onClick={() => { setVisible(true); }}
            className="sig-button sig-secondary mt-4"
        />
        {/* <Button
            icon="pi pi-save"
            type="submit"
            disabled={existError ? true : false}
            label="Guardar"
            loading={formulary?.isSaving || formulary?.isLoading}
            className="sig-button sig-primary mt-4"
        />
        <Button
            icon="pi pi-save"
            type="submit"
            disabled={existError ? true : false}
            label="Guardar y Publicar"
            loading={formulary?.isSaving || formulary?.isLoading}
            className="sig-button sig-primary mt-4"
        /> */}
        {
          // (valuesFields.statusData?.statusCode === 'BORRADOR' || !params.id) && (
          (valuesFields.statusData?.statusCode === 'PUBLICADO') ? null : (
            <ButtonMultipleSend 
              items={valuesFields.statusData?.statusCode === 'REVISION' ? itemsWithoutReview : items} 
              disabled={processSelected.data?.isReadOnly} 
              isLoading={formulary.isSaving}
            />
          )
        }
    </div>
  )

  return (
    <div className="bg-white">
        <form
          className="form-custom p-0"
          onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form-modal p-0 process-style-custom">
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} renderActiveOnly={false}>
                    <TabPanel header="Formulario" contentStyle={{height: 350}}>
                        <GeneralData
                          setVisible={setVisible}
                          dinamicStage={dinamicStage}
                          setDinamicStage={setDinamicStage}
                          control={control}
                          errors={errors}
                          valuesFields={valuesFields}
                          existError={existError}
                          setValue={setValue}
                          validateLength={validateLength}
                          listOptions={listOptions}
                          listStages={listStages}
                          setListStage={setListStage}
                          positionSoruce={positionSoruce}
                          setPositionSource={setPositionSource}
                          typeModal={typeModal}
                          setTypeModal={setTypeModal}
                          displayModal={displayModal}
                          setDisplayModal={setDisplayModal}
                          optionSelected={optionSelected}
                          setOptionSelected={setOptionSelected}
                          inputSelected={inputSelected}
                          setInputSelected={setInputSelected}
                          stateConfigPage={stateConfigPage}
                          setStateConfigPage={setStateConfigPage}
                          stateInsufficient={stateInsufficient}
                          setDestinationControl={setDestinationControl}
                          destinationControl={destinationControl}
                          >
                            <ComponentButtons />
                        </GeneralData>
                    </TabPanel>
                    <TabPanel header="Flujo">
                        <Workflow 
                          dinamicStage={dinamicStage}
                          processFields={valuesFields}
                          workflow={workflow} setWorkflow={setWorkflow}
                          initialProcessId={initialProcessId}
                          setLastStepName={setLastStepName}
                        >
                        <ComponentButtons />
                        </Workflow>
                    </TabPanel>
                    <TabPanel header="Permiso">
                        <Permission 
                          dinamicStage={dinamicStage}
                          processFields={valuesFields}
                          typeModal={typeModal} setTypeModal={setTypeModal}
                          workflow={workflow} setWorkflow={setWorkflow}
                          getTypeById={getTypeById}
                        />
                        <ComponentButtons />
                    </TabPanel>
                    <TabPanel header="Configuración" contentStyle={{height: 375}}>
                        <ConfigurationComponent stateConfigPage={stateConfigPage} setStateConfigPage={setStateConfigPage} dinamicStage={dinamicStage} stateInsufficient={stateInsufficient}>
                          <ComponentButtons />
                        </ConfigurationComponent>
                    </TabPanel>
                </TabView>
            </div>
        </form>
        <Modal
          positionSoruce={positionSoruce}
          dinamicStage={dinamicStage}
          setDinamicStage={setDinamicStage}
          typeModal={typeModal}
          displayModal={displayModal} 
          optionSelected={optionSelected} 
          dinamicInput={dinamicInput} 
          inputSelected={inputSelected}
          setDisplayModal={setDisplayModal} 
          setDinamicInput={setDinamicInput} 
          setOptionSelected={setOptionSelected}
          setInputSelected={setInputSelected}
          listOptions={listOptions}
          destinationControl={destinationControl}
          dataMaster={dataMaster}
        />
        <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={redirectToSearch}
          reject={reject}
        />
    </div>
  )
}

export default ProcessesPage;