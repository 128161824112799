export class ConfigurationConstants {
  static Accion = class {
    static ToggleSidebar = class {
      static get TOGGLE() {
        return '@SIDEBAR.TOGGLE';
      }
    };
    static Roles = class {
      static get SETLIST() {
        return '@ROLES.SET-ROLES';
      }
      static get CHANGEFILTERMODAL() {
        return '@ROLES.FILTER-MODAL';
      }
      static get LISTOPTIONS() {
        return '@ROLES.LIST-OPTIONS';
      }
      static get GET_USER_BY_ROLE_ID() {
        return '@ROLES.GET-USER-BY-ROLE-ID';
      }
      static get EDIT_ROLE() {
        return '@ROLES.EDIT-ROLE';
      }
      static get SET_FILTER_VALUES() {
        return '@ROLES.SET-FILTER-VALUES';
      }
      static get REMOVE_FILTER_VALUES() {
        return '@ROLES.REMOVE-FILTER-VALUES';
      }
      static get SAVE_ROLE_STATUS() {
        return '@ROLES.SAVE-ROLE-STATUS';
      }
      static get SHOW_DELETE_ROLE_MODAL() {
        return `@ROLES.SHOW-DELETE-ROLE-MODAL`;
      }
      static get DOWNLOAD_REPORT_STATUS() {
        return '@ROLES.DOWNLOAD-REPORT-STATUS';
      }
      static get CURRENT_USER() {
        return '@ROLES.CURRENT-USER';
      }
      static get ACTIONS() {
        return '@ROLES.ACTIONS';
      }
    };
    static Users = class {
      static get SET_LIST() {
        return '@USERS.SET-LIST';
      }
      static get GET_DATA_MASTER() {
        return '@USERS.GET-DATA-MASTER';
      }
      static get GET_USER() {
        return '@USERS.GET-USER';
      }
      static get EDIT_USER() {
        return '@USERS.EDIT-USER';
      }
      static get CHANGE_FILTER_VALUES() {
        return '@USERS.CHANGE-FILTER-VALUES';
      }
      static get REMOVE_FILTER_VALUES() {
        return '@USERS.REMOVE-FILTER-VALUES';
      }
      static get SAVE_USER_STATUS() {
        return '@USERS.SAVE-USER-STATUS';
      }
      static get SHOW_DELETE_USER_MODAL() {
        return `@USERS.SHOW-DELETE-ROLE-MODAL`;
      }
      static get DOWNLOAD_REPORT_STATUS() {
        return '@USERS.DOWNLOAD-REPORT-STATUS';
      }
      static get CURRENT_USER() {
        return '@USERS.CURRENT-USER';
      }
    };
    static ApplicationSettings = class {
      static get GET_TYPE_MONEY() {
        return '@APPLICATION-SETTINGS.GET-TYPE-MONEY';
      }
      static get GET_LOGOS() {
        return '@APPLICATION-SETTINGS.GET-LOGOS';
      }
      static get GET_COLORS() {
        return '@APPLICATION-SETTINGS.GET-COLORS';
      }
      static get GET_UNITS_MEASURE() {
        return '@APPLICATION-SETTINGS.GET-UNITS-MEASURE';
      }
    };

    static Processes = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return '@PROCESS.ADVANCED-FILTER-TOGGLE';
      }

      static get SEARCH_PROCESSES() {
        return '@PROCESS.SEARCH_PROCESSES';
      }

      static get UPDATE_FILTER() {
        return '@PROCESS.UPDATE_FILTER';
      }

      static get UPDATE_FILTER_VIEW() {
        return '@PROCESS.UPDATE_FILTER_VIEW';
      }

      static get GET_PROCESS_BY_ID() {
        return '@PROCESS.GET_PROCESS_BY_ID';
      }

      static get RESTORE_PROCESS() {
        return '@RESTORE_PROCESS';
      }

      static get SAVE_PROCESS() {
        return '@PROCESS.SAVE_PROCESS';
      }

      static get DELETE_PROCESS() {
        return '@PROCESS.DELETE_PROCESS';
      }

      static get GET_DATA_MASTER() {
        return '@PROCESS.GET_DATA_MASTER';
      }

      static get RESET_AFTER_SAVED() {
        return '@PROCESS.RESET_AFTER_SAVED';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@PROCESS.DOWNLOAD_REPORT_STATUS';
      }

      static get LIST_CONTROLS() {
        return '@PROCESS.LIST_CONTROLS';
      }

      static get LIST_RESPONSIBLES() {
        return '@PROCESS.LIST_RESPONSIBLES';
      }

    };

    static DinamicProcess = class {
      static get UPDATE_FILTER() {
        return '@DINAMIC_PROCESS.UPDATE_FILTER';
      }
      static get SEARCH_PROCESSES() {
        return '@DINAMIC_PROCESS.SEARCH_PROCESSES';
      }
      static get RESTORE_PROCESS() {
        return '@DINAMIC_PROCESS.RESTORE_PROCESS';
      }
      static get LIST_CONTROLS() {
        return '@DINAMIC_PROCESS.LIST_CONTROLS';
      }
      static get SAVE_PROCESS() {
        return '@DINAMIC_PROCESS.SAVE_PROCESS';
      }
      static get DELETE_PROCESS() {
        return '@DINAMIC_PROCESS.DELETE_PROCESS';
      }
      static get DOWNLOAD_REPORT_STATUS() {
        return '@DINAMIC_PROCESS.DOWNLOAD_REPORT_STATUS';
      }
      static get GET_PROCESS_BY_ID() {
        return '@DINAMIC_PROCESS.GET_PROCESS_BY_ID';
      }
      static get RESET_AFTER_SAVED() {
        return '@DINAMIC_PROCESS.RESET_AFTER_SAVED';
      }
      static get ADVANCED_FILTER_TOGGLE() {
        return '@DINAMIC_PROCESS.ADVANCED_FILTER_TOGGLE';
      }

      static get GET_CONFIG_DINAMIC_PROCESS_BY_IDENTIFIER() {
        return '@DINAMIC_PROCESS.GET_CONFIG_DINAMIC_PROCESS_BY_IDENTIFIER';
      }
      static get GET_DYNAMIC_PROCESS_BY_ID() {
        return '@DINAMIC_PROCESS.GET_DYNAMIC_PROCESS_BY_ID';
      }
      static get SAVE_PROCESS_DATA() {
        return '@DINAMIC_PROCESS.SAVE_PROCESS_DATA';
      }
      static get SEARCH_DYNAMIC_PROCESS() {
        return '@DINAMIC_PROCESS.SEARCH_DYNAMIC_PROCESS';
      }
      static get NEXT_STEP() {
        return '@DINAMIC_PROCESS.NEXT_STEP';
      }
      static get BACK_STEP() {
        return '@DINAMIC_PROCESS.BACK_STEP';
      }
      static get GET_DATA_MASTER() {
        return '@DINAMIC_PROCESS.GET_DATA_MASTER';
      }
    };

    static ApprovalFlow = class {
      static get SEARCH_FLOW() {
        return '@PROCESS.SEARCH_FLOW';
      }

      static get SAVE_APPROVAL_FLOW() {
        return '@PROCESS.SAVE_APPROVAL_FLOW';
      }

      static get ADVANCED_FILTER_TOGGLE() {
        return '@PROCESS.ADVANCED_FILTER_TOGGLE';
      }

      static get UPDATE_FILTER() {
        return '@PROCESS.UPDATE_FILTER';
      }

      static get GET_USERS() {
        return '@PROCESS.GET_USERS';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@PROCESS.DOWNLOAD_REPORT_STATUS';
      }

      static get RESET_AFTER_SAVED() {
        return '@PROCESS.RESET_AFTER_SAVED';
      }

      static get GET_FLOW_BY_ID() {
        return '@PROCESS.GET_FLOW_BY_ID';
      }

      static get DELETE_FLOW() {
        return '@PROCESS.DELETE_FLOW';
      }
    };
  };
}
