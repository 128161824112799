const columns = [
  { field: 'name', header: 'Nombre' },
  { field: 'documentNumber', header: 'Ruc' },
  { field: 'businessLine', header: 'Línea de Negocio' },
  { field: 'contactName', header: 'Contacto' },
  { field: 'contactEmailAddress', header: 'Correo' },
  { field: 'contactPhoneNo', header: 'Teléfono' },
  { field: 'registrationDate', header: 'Fecha de Registro' },
];

const contactColumns = [
  { field: 'name', header: 'Nombre' },
  { field: 'emailAddress', header: 'Correo' },
  { field: 'phoneNo', header: 'Teléfono' },
  { field: 'positionId', header: 'Cargo' },
];

export const CompaniesTableData = {
  columns,
  contactColumns,
};
