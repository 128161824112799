import { apiService } from "core/services/ApiService";
const currentModule = "customer/api/BusinessSheetType";
// const currentModule = 'customer/api/Business/Report';

const search = ({ fields = {} }) => {
  let initialFields = {
    page: 1,
    pageSize: 10,
    columnOrder: "name",
    order: "asc",
    ...fields,
  };

  return apiService.Post(`${currentModule}/Search`, initialFields);
};

const generateReport = (payload) => {
  return apiService.Post(`${currentModule}/GenerateReport`, payload, {
    responseType: "blob",
  });
};

const save = (data) => {
  return apiService.Post(`${currentModule}/Save`, data);
};

const getById = (id) => {
  return apiService.Get(`${currentModule}/GetById?id=${id}`);
};

const remove = (id) => {
  return apiService.Delete(`${currentModule}/DeleteById?id=${id}`);
};

export const BusinessSheetTypeService = {
  search,
  generateReport,
  remove,
  getById,
  save,
};
