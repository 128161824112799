import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import useSubscriptions from "features/subscription/hooks/useSubscriptions";
import Message from "shared/components/messages/Message";
const CancelSusbcription = ({ close, visible }) => {
  const errorsArray = [];
  const {
    renewSubscription
  } = useSubscriptions('Editado',false)
  const params = useParams();
  const {
    formState: { errors },
    handleSubmit,
    control,
    setError,
  } = useForm();

  const headerTemplate = () => {
    return <h1 className="fs-title-modal">¿ESTÁ SEGURO?</h1>;
  }

  const submit = (data) =>{
    if(data.fromDate < data.toDate){
      setError("fromDate", {
        type: "manual",
        message: "La Fecha de Inicio debe ser mayor a la del Fin",
      });
      return
    }
    close()
    renewSubscription({...data,subscriptionId:parseInt(params.id)})
  } 
  return (
    <Dialog
      header={headerTemplate}
      visible={visible}
      closable
      breakpoints={{ "960px": "75vw", "640px": "100vw" }}
      style={{ width: "50vw" }}
      onHide={() => close()}
    >
      <div
        className="line-through bg-blue-800"
        style={{ height: "7.5px", width: "50px" }}
      ></div>
      <h2 className="font-bold fs-subtitle-modal mt-3">
        ¿Desea renovar la vigencia de la subscripción?
      </h2>
      <form onSubmit={handleSubmit(submit)}>
        <div className="grid grid-cols-2 gap-10 mt-3 mb-4">
          <span className="p-float-label">
            <Controller
              control={control}
              rules={{
                required: "La Fecha de Inicio es obligatorio",
              }}
              render={({ field: { onChange, value } }) => (
                <Calendar
                  onChange={onChange}
                  className={`w-full ${
                    errors.fromDate?.type === "required" ||  errors.fromDate?.type === "manual" ? "p-invalid" : ""
                  }`}
                  value={value}
                  name="fromDate"
                  id="fromDate"
                />
              )}
              name="fromDate"
              />
            <label htmlFor="fromDate">Inicio</label>
          </span>
          <span className="p-float-label">
            <Controller
              control={control}
              rules={{
                required: "La Fecha de Inicio es obligatorio",
              }}
              render={({ field: { onChange, value } }) => (
                <Calendar
                  onChange={onChange}
                  className={`w-full ${
                    errors.toDate?.type === "required" ? "p-invalid" : ""
                  }`}
                  value={value}
                  name="toDate"
                  id="toDate"
                />
              )}
              name="toDate"
              />
            <label htmlFor="toDate">fin</label>
          </span>
        </div>
        {Object.entries(errors).forEach((key, value) => {
          errorsArray.push(key[1].message);
        })}
        {errorsArray.length > 0 && (
          <Message messages={errorsArray} status="error" />
        )}
        <div className="col-span-5 flex justify-end gap-2 w-full">
          <Button
            icon="pi pi-times"
            type="button"
            label="Cancelar"
            onClick={() => {
              // setIsEditModal(false)
              close();
              // reset()
              // clearErrors()
            }}
            // disabled={status === ''
            className="sig-button  sig-secondary mt-4"
          />
          <Button
            icon="pi pi-save"
            type="submit"
            label="Guardar"
            // loading={status === ''}
            className="sig-button sig-primary mt-4"
          />
        </div>
      </form>
    </Dialog>
  );
};

export default CancelSusbcription;
