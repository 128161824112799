import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Skeleton } from 'primereact/skeleton';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { CoursesAction } from "features/coursesManagment/store/actions/CourseAction";
// import LogoInputFile from "./LogoInputFile";
import Attachments from "shared/components/attachments/Attachments";
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useClaimCourses } from 'features/coursesManagment/hooks/useClaimCourses';
import { getBase64 } from 'shared/utils/getBase64';
import { getDynamicFields, getDynamicFieldsList } from 'shared/utils/getDynamicFields';
import { CoursesManagmentConstants } from 'features/coursesManagment/commons/CoursesManagmentConstants';
import { DynamicFieldList } from 'shared/components/dynamicFields/DynamicFieldList';

const CoursePage = ({ title, typeMode }) => {

    const history = useHistory();
    const { updateTitle } = useSetTitlePage();
    const dispatch = useDispatch();
    const course = useSelector((state) => state.courseReducer.course);
    const [isFirstLoading, setFirstLoading] = useState(true);
    const dataMaster = useSelector((state) => state.courseReducer.dataMaster);
    const [visible, setVisible] = useState(false);
    const [category, setCategory] = useState([]);
    const [mode, setMode] = useState([]);
    const [courseType, setCourseType] = useState([]);
    const [service, setService] = useState([]);
    const [scale, setScale] = useState([]);
    const { origin: baseURL } = window.location
    const toast = useRef(null);

    const { isCreate, isEdit } = useClaimCourses()

    const {
        // register,
        control,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: "",
            id: null,
            category: null,
            mode: null,
            code: "",
            courseType: null,
            service: null,
            score: null,
            scale: null,
            registrationForms: null,

            savedFiles: [],
            addedFiles: [],
        }
    });

    const valuesFields = watch();
    const params = useParams();
    const currentID = params?.id;
    // console.log(valuesFields)
    const redirectToSearch = () => {
        history.push('/curso/cursos');
        dispatch(CoursesAction.resetAfterSaved());
    }

    const dynamicFields = dataMaster?.dynamicFields || []

    const dynamicFieldsDatosGenerales = getDynamicFields(dynamicFields, CoursesManagmentConstants.SectionsName.DATOS_GENERALES)
    const dynamicFieldsCalificacion = getDynamicFields(dynamicFields, CoursesManagmentConstants.SectionsName.CALIFICACION)

    
    useEffect(() => {

        if (course?.data) {
            setValue("name", course.data.name);
            setValue("id", course.data.id);            
            setValue("code", course.data.code);
            setValue("score", course.data.passingGrade);
            setValue("registrationForms", course.data.token ? `${baseURL}/#/curso/registro?token=${course.data.token}` : '');
            setValue("savedFiles", course.attachments);

            if(course?.data.dynamicFieldValues?.length > 0){
                course?.data.dynamicFieldValues.forEach( dynamicFieldValue => {
                    setValue(`dynamicFieldData:${dynamicFieldValue.identifier}`, dynamicFieldValue.value)
                })
            }

            if(dataMaster.category)
                evaluateCategory()
            
            if(dataMaster.mode)
                evaluateMode()

            if(dataMaster.type)
                evaluateType()
            
            if(dataMaster.service)
                evaluateService()

            if(dataMaster.scale)
                evaluateScale()
        }
        
        if (course?.isSaved)
            redirectToSearch();

        if (course?.data === null && dataMaster) {
            setCategory(dataMaster?.category);
            setMode(dataMaster?.mode);
            setCourseType(dataMaster?.type);
            setService(dataMaster?.service);
            setScale(dataMaster?.scale);
        }
        return () => {
            reset({
                name: "",
                id: null,
                category: null,
                mode: null,
                code: "",
                courseType: null,
                service: null,
                score: null,
                scale: null,
                registrationForms: null,

                savedFiles: [],
                addedFiles: [],
            })
        }
        

    }, [dataMaster, course]);
    

    useEffect(() => {

        setValue("name", "");
        setValue("id", null);
        setValue("code", null);
        setValue("passingGrade", undefined);
        setValue("registrationForms", null);
        setValue("savedFiles", []);
        setValue("addedFiles", []);
        // setValue("category", undefined);
        // setValue("mode", undefined);
        // setValue("code", undefined);
        // setValue("courseType", undefined);
        // setValue("service", undefined);
        // setValue("scale", undefined);


        updateTitle({
            title: 'Cursos',
            subtitle: 'Curso',
            description: title,
            previousUrl: "/curso/cursos/"
        });

        if (isFirstLoading) {
            setFirstLoading(false);
            dispatch(CoursesAction.getDataMaster());
            dispatch(CoursesAction.resetAfterSaved());
            if (params?.id)
            getCourseById(params?.id);
        }
    }, []);

    const onCopyLink = () => {
		navigator.clipboard.writeText(valuesFields.registrationForms)
        toast.current.show({severity:'success', summary: 'Cursos', detail:'Enlace copiado exitosamente', life: 3000});
	}

    const evaluateCategory = () => {
        const currentCourse = dataMaster?.category.filter(c => {return c.id !== course.data.id})
    
        setCategory(currentCourse);

        const currentValue = dataMaster?.category.filter(c => {return c.id === course.data.categoryId})
        if(currentValue && currentValue.length > 0) {
            setValue("category", currentValue[0]);
        } else {
            setValue("category", null);
        }
    }

    const evaluateMode = () => {
        setMode(dataMaster?.mode);
        const currentCourse = dataMaster?.mode.filter(c => {return c.id !== course.data.id})
        setMode(currentCourse);

        const currentValue = dataMaster?.mode.filter(c => {return c.id === course.data.modeId})
        if(currentValue && currentValue.length > 0) {
            setValue("mode", currentValue[0]);
        } else {
            setValue("mode", null);
        }
    }

    const evaluateType = () => {
        setCourseType(dataMaster?.type);
        const currentCourse = dataMaster?.type.filter(c => {return c.id !== course.data.id})
        setCourseType(currentCourse);

        const currentValue = dataMaster?.type.filter(c => {return c.id === course.data.typeId})
        if(currentValue && currentValue.length > 0) {
            setValue("courseType", currentValue[0]);
        } else {
            setValue("courseType", null);
        }

    }

    const evaluateService = () => {
        setService(dataMaster?.service);
        const currentCourse = dataMaster?.service.filter(c => {return c.id !== course.data.id})
        setService(currentCourse);

        const currentValue = dataMaster?.service.filter(c => {return c.id === course.data.serviceId})
        if(currentValue && currentValue.length > 0) {
            setValue("service", currentValue[0]);
        }   
        else {
            setValue("service", null);
        }
    }

    const evaluateScale = () => {
        setScale(dataMaster?.scale);
        const currentCourse = dataMaster?.scale.filter(c => {return c.id !== course.data.id})
        setScale(currentCourse);

        const currentValue = dataMaster?.scale.filter(c => {return c.id === course.data.ratingScaleId})
        if(currentValue && currentValue.length > 0) {
            setValue("scale", currentValue[0]);
        } else {
            setValue("scale", null);
        }
    }

    const getCourseById = (courseId) => {
        dispatch(CoursesAction.getCourseById(Number(courseId)));
    }
    
    const onSubmit = async(data) => {
//    console.log(data)
        // console.log(currentID)
        const dynamicDataValues = getDynamicFieldsList(data, dynamicFields, !currentID, course?.data)

        const model = {
            // id: data.id,
            code: data.code,
            name: data.name,
            serviceId: data.service?.id,
            typeId: data.courseType.id,
            categoryId: data.category.id,
            modeId: data.mode.id,
            ratingScaleId: data.scale.id,
            passingGrade: Number(data.score),
            dynamicDataValues
        }

        model.savedFiles = data.savedFiles?.map(({ id }) => id);
        model.addedFiles = []
        if(data.addedFiles?.length > 0) {
            const addedFiles = await Promise.all(data.addedFiles.map(async(file) => {
                delete file.id;
                const fileBase64 = await getBase64(file);
                let dataBase64 = fileBase64.split("base64,")[1]
                const newFile = {
                    fileName: file.name,
                    fileType: file.type,
                    fileBase64: dataBase64
                }
                return newFile;
            }));
    
            model.addedFiles = addedFiles;
        }

        if (params.id) {
            model.id = Number(params.id);
        }
        // console.log(model)
        dispatch(CoursesAction.saveCourse(model));
    };

    const reject = () => {
        setVisible(false);
    };

    return (
        <div className="bg-white">
            <form
                className="form-custom p-0"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="form-modal p-0">
                    <Panel header="DATOS GENERALES" toggleable>
                        <div className=" grid  sm:grid-cols-3 md:grid-cols-3 gap-2 ">
                            {course.isLoading ? (
                                <Skeleton height="2.2rem" />
                            ) : (
                                <span className="p-float-label w-full mt-4">
                                    <Controller
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { invalid, isTouched, isDirty, error },
                                        }) => (
                                            <InputText
                                                type="text"
                                                id="code"
                                                name="code"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                className={errors?.code ? 'p-invalid w-full' : 'w-full'}
                                            />
                                        )}
                                        name="code"
                                        rules={{
                                            required: 'El codigo es requerido',
                                        }}
                                    />
                                    <label htmlFor="code">Código del Curso *</label>
                                </span>
                            )}

                            <span className="p-float-label w-full mt-4 hidden md:block">
                                &nbsp;
                            </span>
                            <span className="p-float-label w-full mt-4 hidden md:block">
                                &nbsp;
                            </span>
                        </div>

                        {/* COURSE NAME */}

                        <div className="grid  sm:grid-cols-1 md:grid-cols-1 gap-2 mt-2 ">
                            {course.isLoading ? (
                                    <Skeleton height="2.2rem" />
                                ) : (
                                    <span className="p-float-label w-full mt-4">

                                        <Controller
                                            control={control}
                                            render={({
                                                field: { onChange, onBlur, value, name, ref },
                                                fieldState: { invalid, isTouched, isDirty, error },
                                            }) => (
                                                <InputText
                                                    id="name"
                                                    name="name"                                                    
                                                    // showClear
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    className={errors?.name ? 'p-invalid w-full' : 'w-full'}
                                                />
                                            )}
                                            name="name"
                                            rules={{
                                            required: 'El Nombre de Curso es requerido',
                                        }}
                                        />
                                        <label htmlFor="name">Nombre del Curso</label>
                                    </span>
                                    
                            )}
                        </div>
                        <div className={`grid  sm:grid-cols-1 md:grid-cols-1 gap-2 mt-2 ${!params.id ? 'hidden' : ''} `} >
                            {course.isLoading ? (
                                    <Skeleton height="2.2rem" />
                                ) : (
                                    <div className='flex gap-x-2 mt-4'>
                                        <span className="p-float-label w-full">
                                            <Controller
                                                control={control}
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                }) => (
                                                    <InputText
                                                        id="registrationForms"
                                                        name="registrationForms"                         
                                                        // showClear
                                                        onChange={onChange}
                                                        value={value}
                                                        readOnly
                                                        className={'w-full disabled'}
                                                    />
                                                )}
                                                name="registrationForms"
                                            />
                                            <label htmlFor="registrationForms">Formulario de Registro</label>
                                        </span>
                                        <span 
                                            onClick={(e) => onCopyLink(e)}
                                            className="p-inputgroup-addon p-0 cursor-pointer hover:text-gray-800 hover:bg-gray-200">
                                                <i className="pi pi-copy text-xl"></i>
                                        </span>
                                    </div>
                            )}
                        </div>

                        {/* CATEGORY AND MODE */}

                        <div className=" grid  sm:grid-cols-3 md:grid-cols-3 gap-2 mt-2 ">
                            {course.isLoading ? (
                                <Skeleton height="2.2rem" />
                            ) : (
                                <span className="p-float-label w-full mt-4">
                                    <Controller
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { invalid, isTouched, isDirty, error },
                                        }) => (
                                            <Dropdown
                                                id="category"
                                                name="category"
                                                options={category}
                                                optionLabel="description"
                                                filter
                                                showClear
                                                filterBy="description"                                                                                         
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                className={errors?.category ? 'p-invalid w-full' : 'w-full'}
                                            />
                                        )}
                                        name="category"
                                        rules={{
                                            required: 'La categoría es requerida',	
                                        }}
                                    />
                                    <label htmlFor="category">Categoría</label>
                                </span>
                            )}

                            {course.isLoading ? (
                                <Skeleton height="2.2rem" />
                            ) : (
                                <span className="p-float-label w-full mt-4">

                                    <Controller
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { invalid, isTouched, isDirty, error },
                                        }) => (
                                            <Dropdown
                                                id="mode"
                                                name="mode"
                                                options={mode}
                                                optionLabel="description"
                                                filter
                                                showClear
                                                filterBy="description"                                                                                         
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                className={errors?.mode ? 'p-invalid w-full' : 'w-full'}
                                            />
                                        )}
                                        name="mode"
                                        rules={{
                                            required: 'La modalidad es requerida',
                                        }}
                                    />
                                    <label htmlFor="mode">Modalidad</label>
                                </span>
                            )}
                            <span className="p-float-label w-full mt-4 hidden md:block">
                                &nbsp;
                            </span>
                        </div>    

                        
                        {/* TYPE AND SERVICE */}

                        <div className=" grid  sm:grid-cols-3 md:grid-cols-3 gap-2 mt-2 ">
                            {course.isLoading ? (
                                <Skeleton height="2.2rem" />
                            ) : (
                                <span className="p-float-label w-full mt-4">

                                    <Controller
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { invalid, isTouched, isDirty, error },
                                        }) => (
                                            <Dropdown
                                                id="courseType"
                                                name="courseType"
                                                options={courseType}
                                                optionLabel="description"
                                                filter
                                                showClear
                                                filterBy="description"                                                                                         
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                className={errors?.courseType ? 'p-invalid w-full' : 'w-full'}
                                            />
                                        )}
                                        name="courseType"
                                        rules={{
                                            required: 'El tipo de curso es requerido',
                                        }}
                                    />
                                    <label htmlFor="courseType">Tipo de Curso</label>
                                </span>
                            )}

                            {course.isLoading ? (
                                <Skeleton height="2.2rem" />
                            ) : (
                                <span className="p-float-label w-full mt-4">

                                    <Controller
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { invalid, isTouched, isDirty, error },
                                        }) => (
                                            <Dropdown
                                                id="service"
                                                name="service"
                                                options={service}
                                                optionLabel="description"
                                                filter
                                                showClear
                                                filterBy="description"                                                                                         
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                className={errors?.service ? 'p-invalid w-full' : 'w-full'}
                                                
                                            />
                                        )}
                                        name="service"
                                        rules={{
                                            required: 'El servicio es requerido',
                                        }}
                                    />
                                    <label htmlFor="service">Servicio</label>
                                </span>
                            )}
                            <span className="p-float-label w-full mt-4 hidden md:block">
                                &nbsp;
                            </span>
                        </div> 

                        <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-6">
                            {
                                (!course.isLoading && !dataMaster.isLoading) &&
                                <DynamicFieldList control={control} fields={dynamicFieldsDatosGenerales} />
                            }
                        </div>            
                    </Panel>
                    <br />
                    
                    {/* CALIFICATION PANEL */}

                    <Panel header="CALIFICACION" toggleable>
                        <div className=" grid  sm:grid-cols-2 md:grid-cols-2 gap-2 ">
                        {course.isLoading ? (
                                <Skeleton height="2.2rem" />
                            ) : (
                                <span className="p-float-label w-full mt-4">

                                    <Controller
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { invalid, isTouched, isDirty, error },
                                        }) => (
                                            <Dropdown
                                                id="scale"
                                                name="scale"
                                                options={scale}
                                                optionLabel="description"
                                                filter
                                                showClear
                                                filterBy="description"                                                                                         
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                className={errors?.scale ? 'p-invalid w-full' : 'w-full'}
                                            />
                                        )}
                                        name="scale"
                                        rules={{
                                            required: 'La Escala de calificación es requerida',
                                        }}
                                    />
                                    <label htmlFor="scale">Escala de Calificación</label>
                                </span>
                            )}
                            {course.isLoading ? (
                                <Skeleton height="2.2rem" />
                            ) : (
                                <span className="p-float-label w-full mt-4">

                                    <Controller
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { invalid, isTouched, isDirty, error },
                                        }) => (
                                            <InputText
                                                type="text"
                                                id="score"
                                                name="score"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                className={errors?.score ? 'p-invalid w-full' : 'w-full'}
                                            />
                                        )}
                                        name="score"
                                        rules={{
                                            required: 'La Nota aprobatoria es requerida',
                                        }}
                                    />
                                    <label htmlFor="score">Nota Aprobatoria</label>
                                </span>
                            )}
                        </div>
                        <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-4">
                            {
                                (!course.isLoading && !dataMaster.isLoading) &&
                                <DynamicFieldList control={control} fields={dynamicFieldsCalificacion} />
                            }
                        </div>  
                    </Panel>

                                        
                    {/* SYLABUS */}
                    <br />
                    {/* {
                        params?.id &&     <Panel header="SYLLABUS" toggleable>
                    {course.isLoading ? (
                        [1].map((n) => (
                            <Skeleton width="40%" height="4rem" className="mt-4" key={n} />
                        ))
                        ) : (
                        <>
                            <Attachments
                                setValue={setValue}
                                fieldFiles="savedFiles"
                                fieldNewFiles="addedFiles"
                                filesValues={valuesFields?.["savedFiles"]}
                                newFilesValues={valuesFields?.["addedFiles"]}
                                isLoading={course.isLoading}
                                validExtensions={["docx", "pdf", "xlsx"]}
                            />
                        </>
                    )}
                    </Panel>
                    } */}
                    <Attachments
                        // module="customer"
                        // addEndpoint={"api/Business/AddAttachment"}
                        // deleteEndpoint={"api/Business/DeleteAttachment"}
                        // listEndpoint={"api/Business/GetAttachmentsByBusinessId"}

                        setValue={setValue}
                        fieldFiles="savedFiles"
                        fieldNewFiles="addedFiles"
                        filesValues={valuesFields?.["savedFiles"]}
                        newFilesValues={valuesFields?.["addedFiles"]}
                        isLoading={course.isLoading}
                        validExtensions={["docx", "pdf", "xlsx"]}
                        title="SYLLABUS"
                    />
                    {
                        (errors.name ||
                         errors.score || 
                         errors.scale || 
                         errors.service || 
                         errors.courseType || 
                         errors.mode || 
                         errors.code ||
                         errors.category
                         ) && <div className='message error'>
                            <ul>

                                {errors.code ? (
                                    <>
                                        <li className="p-error">{errors.code.message}</li>
                                    </>
                                ) : null}
                                {errors.name ? (
                                    <>
                                        <li className="p-error">{errors.name.message}</li>
                                    </>
                                ) : null}
                                {errors.category ? (
                                    <>
                                        <li className="p-error">{errors.category.message}</li>
                                    </>
                                ) : null}
                                {errors.mode ? (
                                    <>
                                        <li className="p-error">{errors.mode.message}</li>
                                    </>
                                ) : null}
                                {errors.courseType ? (
                                    <>
                                        <li className="p-error">{errors.courseType.message}</li>
                                    </>
                                ) : null}
                                {errors.service ? (
                                    <>
                                        <li className="p-error">{errors.service.message}</li>
                                    </>
                                ) : null}
            
                                {errors.scale ? (
                                    <>
                                        <li className="p-error">{errors.scale.message}</li>
                                    </>
                                ) : null}
                                {errors.score ? (
                                    <>
                                        <li className="p-error">{errors.score.message}</li>
                                    </>
                                ) : null}                            
                            </ul>

                        </div>
                    }

                </div>
                <div className="flex justify-end gap-4 mt-3">
                    <Button
                        icon="pi pi-times"
                        type="button"
                        label="Cancelar"
                        onClick={() => { setVisible(true); }}
                        className="sig-button sig-secondary mt-4"
                    />
                    {
                        (typeMode!=='view' && (isEdit || isCreate))&&(
                            <Button
                                icon="pi pi-save"
                                type="submit"
                                label="Guardar"
                                loading={course?.isSaving || course?.isLoading}
                                className="sig-button sig-primary mt-4"
                            />
                        )
                    }
                </div>
            </form>
            <ConfirmDialog
                visible={visible}
                onHide={() => setVisible(false)}
                message="¿Está seguro que desea cancelar?"
                header="Cancelar"
                icon="pi pi-exclamation-triangle"
                accept={redirectToSearch}
                reject={reject}
            />
            <Toast ref={toast} />
        </div>

    )
}

export default CoursePage;