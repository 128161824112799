export class FilterPaginationConstants
 {
  static Accion = class {
    static FilterPagination = class {
      static get CHANGE_PAGE() {
        return '@FILTER.PAGINATION.CHANGE_PAGE';
      }

      static get CHANGE_SORT() {
        return '@FILTER.PAGINATION.CHANGE_SORT';
      }

      static get RESET_REDUCER() {
        return '@FILTER.PAGINATION.RESET_REDUCER';
      }
    }
  }
}