import React, { useState } from 'react'

const AttachmentInput = ({typeOptionId, readOnly}) => {
  const [fileValue, setFileValue] = useState(null)
  // console.log(fileValue)
  const iconTypeOfFile = (file) => {
    if (!file) return
    
      const fileSplit = file.name.split('.')
      if (fileSplit.includes('docx')) {
          return <div className="icon-word-table w-12 h-12"></div>
      } else if(fileSplit.includes('xlsx') || fileSplit.includes('xls') ) {
          return <div className="icon-excel-table w-12 h-12"></div>
      } else if(file.type === 'application/pdf') {
          return <img alt='icon-pdf' className='icon-image w-12 h-12' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAACQ0lEQVRoge2Yz0tUQRzAP293rTRb09rMwl1UkFqQ9tAhKPDQrT8gTx5FCtFLvy4SnbJDoKQSdIjoEBoFEZ66RF7qaAgF4rLs0masWSIsbaTTYZR3eNX63sw2uzAfWN7Mm+/MfD/Mm/fePrBYlHDKBYi+TlGRmQV3nNn0DdVhQjpy8YtoaQOH6+Ji55jqWEYENkemEIeOaZEwIkBzK5vDk1okzAiANglzAqBFwqwAKEuYFwAlieoQgMAS1SMAgSSqSwA8EuXCI/8jJ8+kw2e1jVV9K+ATK2AaK2AaK2CamhcI9iA73g0j9936zxJk3sPzcVj7DB09cGnCbf+ah9dP4N2crPf0Qv9N77jjA5Bf9pVKsBUIh6EhCqEIZD9C8TukzsPQFET2QGi73QnBpyWItUP/Lejt2+4fke1bv2T/nV/ph+9U1F4lVnMweRkcB648gkQSuk9DqSjbV9IwMQixOIw+hQsD8GbW7b+8AA+uKqWgZw8IAV8yshw97G0vZKGQg/0Hofmolil3UFuBun0QPwkNByB5Rp7LL0HdXm9scUMem2Luua5TMDQty4UczNz2nYKaQGsCrj126/PPIPsBulLe2D99HquPQvyELIfDgVJQE9hYg7cv5TGzCOmFv8fWN8rj+qq7CovzyntATWC9AC/ulY9raYMj7fIy+rYiLztNVPYPTSwBg3ehIyVvna8egtjSOkVlBRqbIHlO3m7npuUe0Yy5r9O7xJlJ/zPHmn8XsgKmsQKmsQKmsQKmsQIWS43zG41eqcJmZbBOAAAAAElFTkSuQmCC"/>
      } else if(file.type === 'image/jpeg'|| file.type === 'image/jpg' || file.type === 'image/png' ) {
          return <img alt='icon-img' className='icon-image w-12 h-12' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAADI0lEQVRoge2ZyW7TUBRAT9IhcdKgoqqTmjKpn4DER7CDBX/AT8CSNWv4A9ix4xtYtBJCqBIFVEpoaZqENB4S2/G7LNKiUPxc22ncIuVI2dzr4R7fN0mBCRMmTLhMcrrEgwO5L8JLgbUsCwqhRo7Hb1Zzb8OSed1dSnhxBYoHqCK81CW1AkB1DMWkZV2XiBL4L/jvBaazfuHdGYdHRpvqlEctmOVVd55Nv5T6eZl24O6Mw5PKIRvTLsWcsDHt8rRyyL1ZJ/UzMxV4ZLRD4w+L4fE4ZCqwNuWHxquaeBwyFfgRzITGa5p4HDIVeNWdD42/1sTjkKnApl/imbnMTr9AT/Ls9As8M5dHWoVGXkaPj3p0Gi7kYX6pSOV6IfL6Tb80UsFnSS0gAgdfO7TrvT8xs+WyWC2zuF6+kOLikEpARKh96tBp9P7J1fcsVCAs35obubg4JBYQEb5vH9Npudprjmo2AqxkIJFIQAJh9+MvrLZ37rVHexaIsHK78lfc+uVitTwEuLZQoDw/m6jgs8QWCPqK3Q8t7OP4m079m4UIrN6pgEBz36bTdBE16KTd9ri+YrCwNuZVqO8pvrxv0bWS75iHJxKzhTx2SOea+w4qEBZvpJv45wr4vYDPW0163X6qFwAc7poYczMY5fDXtQ4cRGDpZnKJSAGvG7Cz1cB10hd/itPxECUUdRL7DiAs3Uw28bUCPavPp80GvhskemAUjumjFNpONH8MOpFkCdYKbL+r0/dV8irPwTE9EH0nmjUbEVi5HU9CK3CRX/4sdsdDBYIxF34KbXy3B0vwnUpofhitgEj6AuPgWD4CFEvhJRzt2YiC1Y1oCb2AGrMBQxM7QgKiJSI6MH4BGMwJpRRGKXxHrn+zyEUc+rUC6uLnrxbH7CMKjHL4nPj5xdTee6lDaBjH9E8kkp0vL30IDeNYHqIUxXL8A15EBy6kpsQ41mA4FTXD6SwRcyD7DpxiW6c79vkSV2oIDePYHoJglKIlrtwQGmZwdhJKERJRq1CNK/AfQdf0QXGsy2u3iECpxyJSExEu85fL5wIVyPPxfJ4JEyZMGJXfXUfSuVI0IAgAAAAASUVORK5CYII="/>
      } else {
          return <div className="icon-file-table"></div>
      }
  }

  const changeImage = (e) => {
    if (typeOptionId === '13') {
      if (e.target.files.length === 0) return
      const typeFileSplit = e.target.files[0].type.split('/')
      if (!typeFileSplit.includes('image')) return
      setFileValue(e.target.files[0])
      return
    }
    setFileValue(e.target.files[0])
  };

  return (
    <div>
        <div className='drag-drop attachment text-center border-2 bg-opacity-25  border-gray-400 h-16 border-dashed bg-gray-400'>
          <div className="h-14">
            <input
              className="absolute m-0 p-0 cursor-pointer outline-none opacity-0"
              style={{height: "100%"}}
              type="file"
              accept={`${typeOptionId === '13' ? 'image/*' : '*'}`}
              multiple
              disabled={readOnly ? true : false}
              onChange={(e) => {
                changeImage(e);
              }}
            />
            <div className="h-full">
              {
                !fileValue ?
                (
                  <div className='flex flex-col justify-center items-center'>
                    {/* <i className={`pi ${typeOptionId === '13' ? 'pi-image' : 'pi-cloud-upload'}  mt-3 p-5`} style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i> */}
                    {
                      typeOptionId === '13' ? null
                      : (
                        <>
                          <i className="pi pi-cloud-download text-2xl opacity-50"></i>
                          <h3 className="opacity-50">
                            Arrastar y soltar archivos para adjuntar
                          </h3>
                          </>
                      )
                    }
                  </div>
                ) :
                (
                  <div className='flex justify-center items-center h-full gap-x-3'>
                    {
                      typeOptionId === '13' ?
                      (
                        <>
                          <img src={URL.createObjectURL(fileValue)} className='h-32 w-36 object-scale-down' alt='imagenNew'/>
                          <div className="flex flex-col ml-2">
                            <p>{fileValue.name}</p>
                            <p>{fileValue.size}B</p>
                          </div>
                        </>
                      ) :
                      (
                        <>
                            {iconTypeOfFile(fileValue)}
                            <div className="flex flex-col ml-2">
                              <p>{fileValue.name}</p>
                              <p>{fileValue.size}B</p>
                            </div>
                        </>
                      )
                    }
                  </div>
                )
              }
            </div>
          </div>
        </div>
    </div>
  )
}

export default AttachmentInput