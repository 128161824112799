import { Fragment , useState } from "react"
import { Calendar } from 'primereact/calendar';
import { RadioButton } from "primereact/radiobutton";
import ModalFilterComponent from 'shared/components/modal-filter/ModalFilterComponent';
import useFilter from "features/maintenance/hooks/Sites/useFilter";
import useStatus from "features/maintenance/hooks/Sites/useStatus";
const styleLabel = {
  color: '#004680',
  fontWeight: 600,
  marginBottom: '5px',
};
const SitesModalFilter = () => {
  const { showModal , closeModal , loading , filterSitesAdvanced } = useFilter()
  const { 
    loadingDataMaster,
    status,
   } = useStatus()
   const [payLoad, setpayLoad] = useState({})

   const clearFields = () =>{
     setpayLoad({});
   }
   
   const searchAdvanced = (e) =>{
     e.preventDefault();
     filterSitesAdvanced(payLoad)
   }
  if(showModal){
    return (
      <ModalFilterComponent
      closeModal={closeModal}
      clearFields={clearFields}
      handleSubmit={e => searchAdvanced(e) }
      searchLoading={loading}
      >
        <div className="flex gap-4 pt-3">
          <div className="w-full">
            <span className="p-float-label">
              <Calendar
                onChange={e => setpayLoad({...payLoad,fromDate :new Date(e.value)})}
                value={payLoad?.fromDate}
                name="from"
                id="desde"
              />
              <label htmlFor="desde">Desde</label>
            </span>
          </div>
          <div className="w-full">
            <span className="p-float-label">
              <Calendar
                onChange={e => setpayLoad({...payLoad,toDate : new Date(e.value)})}
                value={payLoad?.toDate}
                name="to"
                id="hasta"
              />
              <label htmlFor="hasta">Hasta</label>
            </span>
          </div>
        </div>
        <div className="flex mt-3">
          <div className="w-full">
            <h3 style={styleLabel}>Por Estado</h3>
            <div className="flex gap-3">
              {!loadingDataMaster && (
                status?.map((state,index) => {
                  return <div className="flex gap-3" key={index}>
                    <RadioButton value={state.id} 
                    name="estado" 
                    code={state.code}
                    onChange={e => setpayLoad({...payLoad,statusId : `${e.target.value}-${state.description}`})}
                    checked={payLoad.statusId === `${state.id}-${state.description}`}
                    />
                    <p>{state.description}</p>
                  </div>
                })
              )}
              {loadingDataMaster && (
                <h2>Cargando...</h2>
              )}
            </div>
          </div>
        </div>
      </ModalFilterComponent>
    )
  }
  return null
}


export default SitesModalFilter