import { Panel } from 'primereact/panel';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import useColorCorporate from 'features/configuration/hooks/sistem/useColorCorporate';
import './SystemColorCorporateComponent.scss';
import { useClaimSystemConfiguration } from 'features/configuration/hooks/useClaimSystemConfiguration';

const ColorCorporateComponent = () => {
  const {
    handlerChangeColor,
    colors,
    DEFAULT_COLOR,
    saveColors,
    isLoading,
    isLoadingSave,
  } = useColorCorporate();

  const { isCreate, isEdit, isDeleted } = useClaimSystemConfiguration();

  return (
    <div className="content-tab-sistem">
      <h2 className="mt-lg-0 mt-3">Color Corporativo </h2>
      {!isLoading ? (
        <>
          <Panel header="Barra de Navegación" toggleable className="mt-5">
            <div className="option-color">
              <p>Color fondo</p>
              <input
                type="color"
                name="BACKGROUND_COLOR"
                onChange={handlerChangeColor}
                value={
                  colors?.BACKGROUND_COLOR
                    ? colors?.BACKGROUND_COLOR
                    : DEFAULT_COLOR
                }
              />
            </div>
          </Panel>
          <Panel header="Barra de Menú" toggleable className="mt-4">
            <div className="option-color max-width">
              <p>Por defecto</p>
              <input
                type="color"
                name="MENU_DEFAULT"
                onChange={handlerChangeColor}
                value={
                  colors?.MENU_DEFAULT ? colors?.MENU_DEFAULT : DEFAULT_COLOR
                }
              />
              <div
                className="option-color-example-item"
                style={{
                  backgroundColor: colors?.MENU_DEFAULT
                    ? colors?.MENU_DEFAULT
                    : DEFAULT_COLOR,
                }}
              >
                <i className="pi pi-sitemap"></i>
                <p>Gestión de la organización</p>
                <i className="pi pi-chevron-down"></i>
              </div>
            </div>
            <div className="option-color max-width">
              <p>Mouse encima</p>
              <input
                type="color"
                name="MENU_HOVER"
                value={colors?.MENU_HOVER ? colors?.MENU_HOVER : DEFAULT_COLOR}
                onChange={handlerChangeColor}
              />
              <div
                className="option-color-example-item"
                style={{
                  backgroundColor: colors?.MENU_HOVER
                    ? colors?.MENU_HOVER
                    : DEFAULT_COLOR,
                }}
              >
                <i className="pi pi-sitemap"></i>
                <p>Gestión de la organización</p>
                <i className="pi pi-chevron-down"></i>
              </div>
            </div>
            <div className="option-color max-width">
              <p>Seleccionado</p>
              <input
                type="color"
                name="MENU_SELECTED"
                value={
                  colors?.MENU_SELECTED ? colors?.MENU_SELECTED : DEFAULT_COLOR
                }
                onChange={handlerChangeColor}
              />
              <div
                className="option-color-example-item flex "
                style={{
                  backgroundColor: colors?.MENU_SELECTED
                    ? colors?.MENU_SELECTED
                    : DEFAULT_COLOR,
                }}
              >
                <i className="pi pi-sitemap"></i>
                <p>Gestión de la organización</p>
                <i className="pi pi-chevron-down"></i>
              </div>
            </div>
          </Panel>
          <Panel header="Botones" toggleable className="mt-4">
            <div className="option-color">
              <p>Primario</p>
              <input
                type="color"
                name="BUTTON_PRIMARY"
                value={
                  colors?.BUTTON_PRIMARY
                    ? colors?.BUTTON_PRIMARY
                    : DEFAULT_COLOR
                }
                onChange={handlerChangeColor}
              />
              <Button
                type="button"
                label="Aceptar"
                className="btn btn-primary"
                style={{
                  backgroundColor: colors?.BUTTON_PRIMARY
                    ? colors?.BUTTON_PRIMARY
                    : DEFAULT_COLOR,
                }}
              />
            </div>
            <div className="option-color">
              <p>Secundario</p>
              <input
                type="color"
                name="BUTTON_SECONDARY"
                value={
                  colors?.BUTTON_SECONDARY
                    ? colors?.BUTTON_SECONDARY
                    : DEFAULT_COLOR
                }
                onChange={handlerChangeColor}
              />
              <Button
                type="button"
                label="Aceptar"
                className="btn btn-primary"
                style={{
                  backgroundColor: colors?.BUTTON_SECONDARY
                    ? colors?.BUTTON_SECONDARY
                    : DEFAULT_COLOR,
                }}
              />
            </div>
            <div className="option-color">
              <p>Exito</p>
              <input
                type="color"
                name="BUTTON_SUCCESS"
                value={
                  colors?.BUTTON_SUCCESS
                    ? colors?.BUTTON_SUCCESS
                    : DEFAULT_COLOR
                }
                onChange={handlerChangeColor}
              />
              <Button
                type="button"
                label="Aceptar"
                className="btn btn-primary"
                style={{
                  backgroundColor: colors?.BUTTON_SUCCESS
                    ? colors?.BUTTON_SUCCESS
                    : DEFAULT_COLOR,
                }}
              />
            </div>
            <div className="option-color">
              <p>Advertencia</p>
              <input
                type="color"
                name="BUTTON_WARNING"
                value={
                  colors?.BUTTON_WARNING
                    ? colors?.BUTTON_WARNING
                    : DEFAULT_COLOR
                }
                onChange={handlerChangeColor}
              />
              <Button
                type="button"
                label="Aceptar"
                className="btn btn-primary"
                style={{
                  backgroundColor: colors?.BUTTON_WARNING
                    ? colors?.BUTTON_WARNING
                    : DEFAULT_COLOR,
                }}
              />
            </div>
            <div className="option-color">
              <p>Error</p>
              <input
                type="color"
                name="BUTTON_ERROR"
                value={
                  colors?.BUTTON_ERROR ? colors?.BUTTON_ERROR : DEFAULT_COLOR
                }
                onChange={handlerChangeColor}
              />
              <Button
                type="button"
                label="Aceptar"
                className="btn btn-primary"
                style={{
                  backgroundColor: colors?.BUTTON_ERROR
                    ? colors?.BUTTON_ERROR
                    : DEFAULT_COLOR,
                }}
              />
            </div>
            <div className="option-color">
              <p>Oscuro</p>
              <input
                type="color"
                name="BUTTON_DARK"
                value={
                  colors?.BUTTON_DARK ? colors?.BUTTON_DARK : DEFAULT_COLOR
                }
                onChange={handlerChangeColor}
              />
              <Button
                type="button"
                label="Aceptar"
                className="btn btn-primary"
                style={{
                  backgroundColor: colors?.BUTTON_DARK
                    ? colors?.BUTTON_DARK
                    : DEFAULT_COLOR,
                }}
              />
            </div>
          </Panel>
          <div className="flex gap-5 justify-start mt-5">
            <Button
              type="button"
              label="Cancelar"
              className="sig-button  sig-secondary"
              disabled={isLoadingSave}
            />
            {
              (isCreate && isEdit && isDeleted)&&
              <Button
                type="button"
                label="Guardar"
                className="sig-button sig-primary"
                onClick={saveColors}
                loading={isLoadingSave}
              />
            }
          </div>
        </>
      ) : (
        <>
          {[1, 2, 3, 4, 5].map((n) => (
            <Skeleton width="100%" height="4rem" className="mt-4" key={n} />
          ))}
        </>
      )}
    </div>
  );
};

export default ColorCorporateComponent;
