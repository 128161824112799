import { Calendar } from "primereact/calendar";
import ModalFilterComponent from "shared/components/modal-filter/ModalFilterComponent";
import useFilter from "features/subscription/hooks/useFilter";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from 'primereact/inputnumber'
import { useState } from "react";
const styleLabel = {
  color: "#004680",
  fontWeight: 600,
  marginBottom: "5px",
};

const SubscriptionsModalFilterComponent = () => {
  const {
    showModal,
    closeModal,
    loading,
    dataManager,
    loadingDataManager,
    empresas,
    formValues,
    setFormValues,
    clearFields,
    handleSubmit
  } = useFilter();
  const submit = (e) => {
    e.preventDefault();
    handleSubmit();
  };
  if (showModal) {
    return (
      <ModalFilterComponent
        closeModal={closeModal}
        clearFields={clearFields}
        handleSubmit={(e) => submit(e)}
        searchLoading={loading}
      >
        <div className="flex gap-4 pt-3">
          <div className="w-full">
            <span className="p-float-label">
              <Calendar
                onChange={(e) =>
                  setFormValues({ ...formValues, from: e.target.value })
                }
                value={formValues.from}
                name="from"
                id="desde"
              />
              <label htmlFor="desde">Desde</label>
            </span>
          </div>
          <div className="w-full">
            <span className="p-float-label">
              <Calendar
                onChange={(e) =>
                  setFormValues({ ...formValues, to: e.target.value })
                }
                value={formValues.to}
                name="to"
                id="hasta"
              />
              <label htmlFor="hasta">Hasta</label>
            </span>
          </div>
        </div>
        {!loadingDataManager && (
          <>
            <div className="flex pt-5">
              <div className="w-full">
                <span className="p-float-label">
                  <Dropdown
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        customerId: e.target.value,
                      })
                    }
                    options={dataManager.customers}
                    value={formValues?.customerId}
                    name="customerId"
                    optionValue={(e) => {
                      return `${e.id}-${e.description}`;
                    }}
                    optionLabel="description"
                    filter
                    showClear
                    className="w-full"
                  />

                  <label htmlFor="dropdown">Por Empresa</label>
                </span>
              </div>
            </div>
            <div className="mt-5 grid grid-cols-2">
              <span className="p-float-label">
                <InputNumber
                  id="users"
                  value={formValues.usersQuantity}
                  onValueChange={(e) => setFormValues({...formValues,usersQuantity:e.target.value})}
                  className="w-full"
                />

                <label htmlFor="users">Nro Usuarios</label>
              </span>
            </div>
            <div className="mt-5 grid grid-cols-2">
              <span className="p-float-label">
                <InputNumber
                  id="activo"
                  className="w-full"
                  value={formValues.numberActiveUsers}
                  onValueChange={(e) => setFormValues({...formValues,numberActiveUsers:e.target.value})}
                />

                <label htmlFor="activo">Nro Usuarios Activos</label>
              </span>
            </div>
            <div className="flex mt-5">
              <div className="w-full">
                <h3 style={styleLabel}>Por Estado</h3>
                <div className="gap-3 grid grid-cols-3">
                  {dataManager.status.map((state, index) => {
                    if (state.id === 53 || state.id === 55 || state.id === 56)
                      return (
                        <div className="flex gap-3" key={index}>
                          <RadioButton
                            value={`${state.id}-${state.description}`}
                            name="statusId"
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                statusId: e.target.value,
                              })
                            }
                            checked={
                              formValues.statusId ===
                              `${state.id}-${state.description}`
                            }
                          />
                          <p>{state.description}</p>
                        </div>
                      );
                  })}
                </div>
              </div>
            </div>
          </>
        )}

        {loadingDataManager && (
          <h2 className="mt-4 text-center">Cargando...</h2>
        )}
      </ModalFilterComponent>
    );
  }

  return null;
};

export default SubscriptionsModalFilterComponent;
