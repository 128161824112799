import { Checkbox } from 'primereact/checkbox'
import { Editor } from 'primereact/editor'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Panel } from 'primereact/panel'
import { RadioButton } from 'primereact/radiobutton'
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import HeaderEditor from 'shared/components/header-editor/HeaderEditor'
import Message from 'shared/components/messages/Message'

export const ViewCustomField = ({data, listOptions, control, errors, valuesFields}) => {

  const [newData, setNewData] = useState([])
  const [sendData, setSendData] = useState({})

  const header = HeaderEditor();

  useEffect(()=> {
    if(data){
      chargeData()
    }
  },[data])

  const chargeData = () => {
    // data.map(item=> {
    //   setValue(item.text.trim(), "")
    //   return item
    // })
    setNewData(data.map(el=> {
      return {
        id: el.id,
        text: el.text,
        controlId: el.controlId,
        identifier: el.identifier,
        codeControl: (listOptions?.find(elem => elem.id===el.controlId).code),
        required: el.required,
        details: el.details
      }
    }))
  }

  return (
    <Panel header="CAMPO PERSONALIZADO">
      {
        data&&<div className='mt-4 pb-2'>
          {
            newData?.map((item,index)=> {
              return(<div key={index} className="w-full md:w-6/12 mb-6">
                 {/* <h6 className="md:font-bold text-xs mb-6">{item.text.toUpperCase()} {item.required&&"*"}</h6> */}
                  {
                    (item.codeControl==="TEXTO")&&(
                      <span className="col-span-2 p-float-label w-full">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value }
                          }) => (
                            <InputText
                                type="text"
                                id={item.id.toString()}
                                name={item.id.toString()}
                                onChange={(e)=>
                                  onChange(e.target.value)
                                }
                                value={value}
                                className={errors[item.id] ? 'p-invalid w-full' : 'w-full'}
                            />
                          )}
                          name={item.id.toString()}
                          rules={{
                              required: item.required===true?`El campo '${item.text}' es requerido`:false
                          }}
                      />
                      <label htmlFor={item.id.toString()}>{item.text} {item.required&&"*"}</label>
                      </span>
                    )
                  }
                  {
                    (item.codeControl==="TEXTO_LARGO")&&(
                      <span className="col-span-2 p-float-label w-full">
                      <h5 style={{"font-size":"0.75rem", "color": "rgb(206, 212, 218)", "margin-bottom": "2px"}}>{item.text} {item.required&&"*"}</h5>
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value }
                          }) => (
                            <InputTextarea
                            value={value}
                            rows={5}
                            cols={30}
                            autoResize
                            onChange={(e)=>
                              onChange(e.target.value)
                            }
                            className={errors[item.id] ? 'p-invalid w-full' : 'w-full'}
                             />
                          )}
                          name={item.id.toString()}
                          rules={{
                            required: item.required===true?`El campo '${item.text}' es requerido`:false
                          }}
                      />
                      </span>
                    )
                  }
                  {
                    (item.codeControl==="TEXTO_DINAMICO")&&(
                      <span className="col-span-2 p-float-label w-full">
                      <h5 style={{"font-size":"0.75rem", "color": "rgb(206, 212, 218)", "margin-bottom": "2px"}}>{item.text} {item.required&&"*"}</h5>
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value }
                          }) => (
                            <Editor
                            headerTemplate={header}
                            style={{ height: '70px'}}
                            value={value}
                            onTextChange={(e) => onChange(e.htmlValue)}
                            className={errors[item.id] ? 'p-invalid w-full' : 'w-full'}
                            />
                          )}
                          name={item.id.toString()}
                          rules={{
                            required: item.required===true?`El campo '${item.text}' es requerido`:false
                          }}
                      />
                      <label htmlFor={item.id.toString()}>{item.text} {item.required&&"*"}</label>
                      </span>
                    )
                  }
                  {
                    (item.codeControl==="SELECCION_SIMPLE")&&(
                      <span className="col-span-2 p-float-label w-full">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value }
                          }) => (
                            <>
                            <h5 style={{"font-size":"0.75rem", "color": "rgb(206, 212, 218)", "margin-bottom": "2px"}}>{item.text} {item.required&&"*"}</h5>
                            {item.details.map(el=> {
                                return (<div key={el.id} className="p-field-radiobutton flex items-center">
                                <RadioButton inputId={el.id} className={errors[item.id]&&'p-invalid'} name={el.text} value={el}
                                onChange={(e)=>{
                                  if(!valuesFields[item.id]){
                                    onChange([e.value.id])
                                  }else{
                                    if(e.checked){
                                      onChange([e.value.id])
                                    }else{
                                      onChange(valuesFields[item.id]= null)
                                    }
                                  }
                                }}
                                checked={valuesFields[item.id]?.some(elnm => elnm===el.id)}
                                />
                                <div className='ml-2'>{el.text}</div>
                            </div>)
                              })}
                            </>
                          )}
                          name={item.id.toString()}
                          rules={{
                            required: item.required===true?`El campo '${item.text}' es requerido`:false
                          }}
                      />
                      </span>
                    )
                  }
                  {
                    (item.codeControl==="SELECCION_MULTIPLE")&&(
                      <span className="col-span-2 p-float-label w-full">
                      <h5 style={{"font-size":"0.75rem", "color": "rgb(206, 212, 218)", "margin-bottom": "2px"}}>{item.text} {item.required&&"*"}</h5>
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value }
                          }) => (
                            <>
                            {item.details.map(el=> {
                                return (<div key={el.id} className="p-field-radiobutton flex items-center">
                                <Checkbox inputId={el.id} className={errors[item.id]&&'p-invalid'} name={el.text} value={el.id}
                                onChange={(e)=>{
                                  if(!valuesFields[item.id]){
                                    onChange([e.value])
                                  }else{
                                    if(e.checked){
                                      onChange([...valuesFields[item.id],e.value])
                                    }else{
                                      onChange(valuesFields[item.id].filter(element=>element!==e.value))
                                    }
                                  }
                                }}
                                checked={valuesFields[item.id]?.some(it=> it===el.id)}
                                />
                                <div className='ml-2'>{el.text}</div>
                            </div>)
                              })}
                            </>
                          )}
                          name={item.id.toString()}
                          rules={{
                            required: item.required===true?`El campo '${item.text}' es requerido`:false
                          }}
                      />
                      </span>
                    )
                  }
                {/* {
                  (item.codeControl==="TEXTO")&&<>
                    <div><InputText value={sendData[item.id]} onChange={(e)=> onChangeText(e, item.id)}/></div>
                  </>
                }
                {
                  (item.codeControl==="TEXTO_LARGO")&&<>
                    <div><InputTextarea value={sendData[item.id]} onChange={(e)=> onChangeText(e, item.id)} rows={5} cols={30} autoResize /></div>
                  </>
                }
                {
                  (item.codeControl==="TEXTO_DINAMICO")&&<>
                    <div><Editor headerTemplate={header} style={{ height: '70px'}} value={sendData[item.id]} onTextChange={(e)=> onChangeDinamicText(e, item.id)}/></div>
                  </>
                }
                {
                  (item.codeControl==="SELECCION_SIMPLE")&&<>
                      {item.details.map(el=> {
                        return (<div key={el.id} className="p-field-radiobutton">
                        <RadioButton inputId={el.id} name={el.name} value={el.id} onChange={(e) => onChangeSelectionSimple(e, item.id)}  checked={sendData[item.id] === el.id} />
                        <label className='ml-2' htmlFor={el.id}>{el.name}</label>
                    </div>)
                      })}
                  </>
                }
                {
                  (item.codeControl==="SELECCION_MULTIPLE")&&<>
                      {item.details.map(el=> {
                        return (<div key={el.id} className="p-field-checkbox">
                        <Checkbox inputId={el.id} name={el.name} value={el.id} onChange={(e)=>onChangeMultipleSelect(e,item.id)} checked={sendData[item.id]?.some((element) => element === el.id)}/>
                        <label className='ml-2' htmlFor={el.id}>{el.name}</label>
                    </div>)
                      })}
                  </>
                } */}
              </div>)
            })
          }
        </div>
      }
    </Panel>
  )
}
