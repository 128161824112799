import { apiService } from "core/services/ApiService";

const searchProcesses = (criteria) => {
  return apiService.Post("management/api/ConfigDynamicProcess/Search", criteria);
}

const searchProcessesView = (criteria) => {
  return apiService.Post("management/api/ConfigDynamicProcess/Search", criteria);
}

const searchListControls = () => {
  return apiService.Get("management/api/ConfigDynamicProcess/ListControls");
}

const searchResponsibles = () => {
  return apiService.Post("management/api/ConfigDynamicProcess/GetDataMaster");
}

const saveProcesses = (data) => {
  return apiService.Post("management/api/ConfigDynamicProcess/SaveProcess", data);
}

const getProcessById = (processesId) => {
  return apiService.Get("management/api/ConfigDynamicProcess/GetDynamicProcessById?id=" + processesId);
}

const generateReport = (payload) => {
  return apiService.Post("customer/api/JobPosition/GenerateReport", payload, {
    responseType: 'blob',
  })
}

const deleteProcess = (processId) => {
  return apiService.Delete("management/api/ConfigDynamicProcess/RemoveProcess?processId=" + processId);
}

const getDataMaster = () => {
  const payload = {
    isRole: true,
    // isCompany: true,
    // isLocation: true,
    // isStatus: true,
    // isEmployee: true
  };
  return apiService.Post('management/api/User/GetDataMaster', payload);
};

export const ProcessService = {
  searchProcesses,
  searchProcessesView,
  saveProcesses,
  getProcessById,
  generateReport,
  deleteProcess,
  searchListControls,
  searchResponsibles,
  getDataMaster
};