import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import scrollTop from "shared/utils/scrollTop";
import { PositionsAction } from "features/rrhh/store/actions/PositionsAction";
import useSetTitlePage from "shared/hooks/useSetTitlePage";

const usePosition = ({ title }) => {
  const isNewPosition = title === "NUEVO CARGO";
  const description = isNewPosition ? "Nuevo Cargo" : "Editar Cargo";
  const { updateTitle } = useSetTitlePage();
  const pageTitle = {
    title: "Gestión de la Organización",
    subtitle: "Cargos",
    description,
    previousUrl: "/rrhh/cargos",
  };

  useEffect(() => {
    updateTitle(pageTitle);
  }, []);

  const history = useHistory();
  const params = useParams();
  const currentID = params.id;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isNewPosition) {
      dispatch(PositionsAction.editPosition(currentID));
    }
  }, []);

  useEffect(() => {
    scrollTop();
  }, []);

  const { data: dataMaster, loading: dataMasterLoading } = useSelector(
    (state) => state.positionsReducer.dataMaster
  );
  const {
    isLoading,
    status: currentStatusPosition,
    data: currentPosition,
    isLoadingPosition,
    errors: fieldsErrors,
  } = useSelector((state) => state.positionsReducer.editPosition);

  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    dispatch(PositionsAction.getDataMaster());
  }, []);

  useEffect(() => {
    if (isNewPosition) {
      setIsActive(true);
      return;
    }
    if (!dataMasterLoading && dataMaster.positionStatus.length > 0) {
      const currentStatus = currentPosition.statusId;
      const isActive_ = dataMaster.positionStatus.filter(
        (s) => s.id === currentStatus
      )[0]?.id;
      setIsActive(isActive_ === 11);
    }
  }, [dataMasterLoading, dataMaster]);

  let defaultValues = {
    name: null,
    quantityEmployeesRequired: null,
    parentPositionId: null,
    companyAreaId: null,
    positionTypeId: null,
    responsibilitiesDescription: null,
    profileDescription: null,
    skillsDescription: null,
  };
  const {
    register,
    control,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });
  const valuesFields = watch();

  useEffect(() => {
    const finishLoading = !dataMasterLoading && !isLoading;
    const hasValues = Object.values(currentPosition).length > 0;
    const datas = {
      companyAreaId: "areas",
      parentPositionId: "positions",
      positionTypeId: "positionTypes",
    };

    if (!isNewPosition && finishLoading && hasValues) {
      setIsActive(currentPosition.statusId === 11);
      for (let key in defaultValues) {
        const keyLowercase = key.toLowerCase();

        if (currentPosition[key]) {
          if (keyLowercase.includes("id")) {
            const ID = currentPosition[key];
            const value = dataMaster[datas[key]].filter(
              ({ id }) => id === ID
            )[0];
            setValue(key, value);
          } else {
            setValue(key, currentPosition[key]);
          }
        } else {
          setValue(key, null);
        }
      }
    }
  }, [dataMasterLoading, isLoading]);

  useEffect(() => {
    if (!isLoading && currentStatusPosition === 0) {
      dispatch(PositionsAction.setCurrentPosition({ status: null }));
      history.push("/rrhh/cargos");
    }
  }, [currentStatusPosition, isLoading]);

  const onSubmit = (data) => {
    const position = {};

    for (let key in data) {
      const fields = ["name", "quantityEmployeesRequired"];
      if (data[key]) {
        if (fields.includes(key)) {
          position[key] = data[key];
        } else if (data[key]?.id) {
          position[key] = data[key].id;
        } else if (key.includes("Description")) {
          let currentVal = null;
          const currentType = typeof data[key];
          if (currentType === "string") {
            currentVal = data[key];
          } else if (currentType === "object") {
            if (data[key].htmlValue) currentVal = data[key].htmlValue;
          }
          position[key] = currentVal;
        }
      }
    }

    if (!isNewPosition) {
      position.id = Number(currentID);
      position.statusId = isActive ? 11 : 12;
    } else {
      position.statusId = 11;
    }

    dispatch(PositionsAction.savePosition({ position, isNewPosition }));
  };

  const [showModalCancel, setShowModalCancel] = useState(false);

  const cancelAccept = () => {
    const { description, ...rest } = pageTitle;
    updateTitle(rest);
    history.push("/rrhh/cargos");
    dispatch(PositionsAction.setCurrentPosition({ status: null }));
  };

  const cancelDenied = () => {
    setShowModalCancel(false);
  };
  return {
    dataMaster,
    dataMasterLoading,
    handleSubmit,
    register,
    errors,
    onSubmit,
    control,
    isLoading,
    isLoadingPosition,
    currentStatusPosition,
    currentPosition,
    showModalCancel,
    setShowModalCancel,
    cancelAccept,
    cancelDenied,
    isActive,
    setIsActive,
    isNewPosition,
    valuesFields,
    fieldsErrors,
  };
};

export default usePosition;
