import { ConfirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from 'uuid';

const getItemStyle = (isDragging, draggableStyle) => ({
  // userSelect: "none",
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,
  // background: isDragging ? "lightgreen" : "white",
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? "lightblue" : "white",
  // border: isDraggingOver ? "1px dashed gray" : "none",
  padding: 2,
  // width: '100%'
});

const useSurveyQuestionGrid = (section, isCloseStatus, listSections, setListSections, sectionId, getQuestionTypeRadio, deleteQuestionInSection) => {

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmDialogAnswer, setShowConfirmDialogAnswer] = useState(false);
  const [questionSelected, setQuestionSelected] = useState(null);
  const [answerSelected, setAnswerSelected] = useState(null)
  
  // console.log(showConfirmDialog)
  // console.log(questionSelected)
  const onCancelDeleteQuestion = () => {
    console.log('hola1')
    setShowConfirmDialog(false)
    setQuestionSelected(null)
  }

  const onCancelDeleteAnswer = () => {
    console.log('hola2')
    setShowConfirmDialogAnswer(false)
    setAnswerSelected(null)
  }

  // const validateLength = (value) => {
  //   if (typeof value === 'string') {
  //     return value.replace(/<[^>]+>/g, '')?.length;
  //   } else if (value?.htmlValue) {
  //     return value.htmlValue.replace(/<[^>]+>/g, '').length;
  //   }
  //   return '0';
  // };

  const addQuestion = (valueOption) => {
    if (valueOption.trim() === '') return

    const newListSections = [...listSections]
    // Encontrar la posición de la sección actual seleccionada
    const sectionIndex = newListSections.findIndex(section => section.id === sectionId)
    // Encontrar la posición de la pregunta actual seleccionada
    // const questionIndex = newListSections[sectionIndex].listQuestion.findIndex(question => question.id === questionId)
    const firstQuestion = newListSections[sectionIndex].listQuestion[0]

    const newQuestion = {
      id: uuidv4(),
      body: valueOption,
      questionTypeId: getQuestionTypeRadio(),
      surveyQuestionOptions: firstQuestion.surveyQuestionOptions,
      extraOptions: [],
      rankingQuantity: 0,
      icon: null,
      position: newListSections[sectionIndex].listQuestion.length + 1,
      isRequired: firstQuestion.isRequired
    }


    newListSections[sectionIndex].listQuestion.push(newQuestion)

    setListSections(newListSections)
  }

  const addAnswer = (valueOption) => {
    if (valueOption.trim() === '') return

    const newListSections = [...listSections]

    const newListSectionQuestion = newListSections.map(section => {
      if (section.id === sectionId) {
        const newListQuestion = section.listQuestion.map(question => {
          return {
            ...question,
            surveyQuestionOptions: [...question.surveyQuestionOptions, {
              caption: valueOption, 
              position: question.surveyQuestionOptions.length === 0 ? 1 : question.surveyQuestionOptions[question.surveyQuestionOptions.length - 1].position + 1, 
              id: uuidv4() 
            }]
          }
        })
        
        return {
          ...section,
          listQuestion: newListQuestion
        }
      }
      return section
    })


    setListSections(newListSectionQuestion)
  }

  const onChangeNameQuestion = (e, questionId) => {
    const newListSections = [...listSections]


    const newListSectionQuestion = newListSections.map(section => {
      if (section.id === sectionId) {
        const newListQuestion = section.listQuestion.map(question => {
          if (question.id === questionId) {
            return {
              ...question,
              body: e.target.value
            }
          }
          return question
        })
        
        return {
          ...section,
          listQuestion: newListQuestion
        }
      }
      return section
    })
    
    setListSections(newListSectionQuestion)
  }

  const onChangeNameOption = (e, answerId) => {
    const newListSections = [...listSections]

    const sectionIndex = newListSections.findIndex(section => section.id === sectionId)

    const firstQuestion = newListSections[sectionIndex].listQuestion[0]
    
    const newSurveyQuestionOptions = firstQuestion.surveyQuestionOptions.map(option => {
      if (option.id === answerId) {
        return {
          ...option,
          caption: e.target.value
        }
      }
      return option
    })

    const newListSectionQuestion = newListSections.map(section => {
      if (section.id === sectionId) {
        const newListQuestion = section.listQuestion.map(question => {
          return {
            ...question,
            surveyQuestionOptions: newSurveyQuestionOptions
          }
        })
        return {
          ...section,
          listQuestion: newListQuestion
        }
      }
      return section
    })

    setListSections(newListSectionQuestion)
  }

  // Reordenar los item Dnd
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    
    const newList = result.map((option, index) => {
      const newOption = {
        ...option,
        position: index + 1
      }
      return newOption
    })
  
    return newList;
  };

  const onDragEndQuestion = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const copyListSectionQuestion = [...section.listQuestion]

    const items = reorder(
      copyListSectionQuestion,
      result.source.index,
      result.destination.index
    );

    const newListSectionQuestion = listSections.map(section => {
      if (section.id === sectionId) {
        return {
          ...section,
          listQuestion: items
        }
      }
      return section
    })
    setListSections(newListSectionQuestion)
  }

  const onDragEndAnswer = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const copyListSectionQuestion = [...listSections]
    const sectionIndex = copyListSectionQuestion.findIndex(section => section.id === sectionId)

    const firstQuestion = copyListSectionQuestion[sectionIndex].listQuestion[0]

    const items = reorder(
      firstQuestion.surveyQuestionOptions,
      result.source.index,
      result.destination.index
    );

    const newListSectionQuestion = copyListSectionQuestion.map(section => {
      if (section.id === sectionId) {
        const newListQuestion = section.listQuestion.map(question => {
          return {
            ...question,
            surveyQuestionOptions: items
          }
        })
        return {
          ...section,
          listQuestion: newListQuestion
        }
      }
      return section
    })

    setListSections(newListSectionQuestion)
  }

  const listQuestionGrid = () => {
    return (
      <DragDropContext onDragEnd={onDragEndQuestion}>
        <h2 className='font-semibold pl-1'>Preguntas:</h2>
        <Droppable droppableId="droppable_questions_grid">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {section?.listQuestion.map((question, index) => {
                return(
                  <Draggable key={`${question.id}`} draggableId={`${question.id}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        // {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        className='flex w-full items-center mt-2'
                      >
                        <div className="p-input-icon-right w-full">
                          <i { ...provided.dragHandleProps} className={`pi pi-th-large ${isCloseStatus ? 'invisible' : 'cursor-pointer hover:text-gray-900'} text`} />
                          <InputText
                            value={question.body} 
                            className='inline rounded-lg border-indigo-400 border-2 p-2' 
                            onChange={(e)=> onChangeNameQuestion(e, question.id)}
                            readOnly={isCloseStatus}
                          />
                        </div>
                        <button type='button' disabled={isCloseStatus || section?.listQuestion.length === 1}>
                          <i 
                            className={`pi pi-trash block ml-2 ${isCloseStatus || section?.listQuestion.length === 1 ? '' : 'cursor-pointer hover:text-gray-900'}`} 
                            style={{'fontSize': '1.5em'}} 
                            // onClick={() =>  deleteQuestion(index) }
                            onClick={() =>  {
                              // console.log(question.id)
                              setQuestionSelected(question.id)
                              setShowConfirmDialog(true)
                            }}
                          />
                        </button>
                      </div>
                    )}
                  </Draggable>
              )
              }
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <ConfirmDialog
            visible={showConfirmDialog}
            onHide={onCancelDeleteQuestion}
            message="¿Está seguro que desea eliminar la pregunta?"
            header="Eliminar"
            icon="pi pi-trash"
            accept={() => {
              deleteQuestionInSection(sectionId, questionSelected)
              onCancelDeleteQuestion()
            }}
            reject={onCancelDeleteQuestion}
        />
      </DragDropContext>
    )
  }

   const deleteAnswer = (optionId) => {
    if (isCloseStatus) return
    const newListSections = [...listSections]

    const sectionIndex = newListSections.findIndex(section => section.id === sectionId)

    const firstQuestion = newListSections[sectionIndex].listQuestion[0]
    const newSurveyQuestionOptions = firstQuestion.surveyQuestionOptions.filter(option => option.id !== optionId)

    const newListOptions = newSurveyQuestionOptions.map((option, index) => {
      const newOption = {
        ...option,
        position: index + 1
      }
      return newOption
    })

    const newListSectionQuestion = newListSections.map(section => {
      if (section.id === sectionId) {
        const newListQuestion = section.listQuestion.map(question => {
          return {
            ...question,
            surveyQuestionOptions: newListOptions
          }
        })
        return {
          ...section,
          listQuestion: newListQuestion
        }
      }
      return section
    })
    
    setListSections(newListSectionQuestion)
  }

  const listAnswerGrid = () => {
    return (
      <DragDropContext onDragEnd={onDragEndAnswer}>
        <h2 className='font-semibold pl-1'>Respuestas:</h2>
        <Droppable droppableId="droppable_answers_grid">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {section?.listQuestion[0].surveyQuestionOptions.map((option, index) => {
                return(
                  <Draggable key={`${option.id}`} draggableId={`${option.id}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        // {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        className='flex w-full items-center mt-2 gap-2'
                      >
                        <div className='h-5 w-5 rounded-full border-2 border-gray-400'></div>
                        <div className="p-input-icon-right w-full">
                          <i { ...provided.dragHandleProps} className={`pi pi-th-large ${isCloseStatus ? 'invisible' : 'cursor-pointer hover:text-gray-900'} text`} />
                          <InputText
                            value={option.caption} 
                            className='inline rounded-lg border-indigo-400 border-2 p-2' 
                            onChange={(e)=> onChangeNameOption(e, option.id)}
                            readOnly={isCloseStatus}
                          />
                        </div>
                        <button type='button' disabled={isCloseStatus || section?.listQuestion[0].surveyQuestionOptions.length === 1}>
                          <i 
                            className={`pi pi-trash block ${(isCloseStatus || section?.listQuestion[0].surveyQuestionOptions.length === 1) ? '' : 'cursor-pointer hover:text-gray-900'}`} 
                            style={{'fontSize': '1.5em'}} 
                            // onClick={() =>  deleteQuestion(index) }
                            onClick={() =>  {
                              setAnswerSelected(option.id)
                              setShowConfirmDialogAnswer(true)
                            }}
                          />
                        </button>
                      </div>
                    )}
                  </Draggable>
              )
              }
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <ConfirmDialog
            visible={showConfirmDialogAnswer}
            onHide={onCancelDeleteAnswer}
            message="¿Está seguro que desea eliminar la opción?"
            header="Eliminar"
            icon="pi pi-trash"
            accept={() => {
              deleteAnswer(answerSelected)
              onCancelDeleteAnswer()
            }}
            reject={onCancelDeleteAnswer}
        />
      </DragDropContext>
    )
  }

  const setRequiredQuestions = () => {
    const newListSections = [...listSections]

    const newListSectionQuestion = newListSections.map(section => {
      if (section.id === sectionId) {
        const newListQuestion = section.listQuestion.map(question => {
          return {
            ...question,
            isRequired: !question.isRequired
          }
        })
        return {
          ...section,
          listQuestion: newListQuestion
        }
      }
      return section
    })

    setListSections(newListSectionQuestion)
  }

  return {
    // validateLength,
    // Question
    addQuestion,
    listQuestionGrid,

    // Answer
    addAnswer,
    listAnswerGrid,
    setRequiredQuestions
  }
}

export default useSurveyQuestionGrid