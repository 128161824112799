import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from 'core/store/actions/ToastAction';
import { CoursesManagmentConstants } from "features/coursesManagment/commons/CoursesManagmentConstants";
import { CoursesService } from "features/coursesManagment/services/CourseServices";
import FileSaver from 'file-saver';
import moment from 'moment';

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: CoursesManagmentConstants.Accion.Courses.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: CoursesManagmentConstants.Accion.Courses.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 

    if (advancedFilter.filter.name)
      pines.push({ field: 'name', value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.category)
      pines.push({
        field: 'category',
        value: advancedFilter.filter.category.description,
      });

    if (advancedFilter.filter.mode)
      pines.push({
        field: 'mode',
        value: advancedFilter.filter.mode.description,
      });

    
    if (advancedFilter.filter.service)
      pines.push({
        field: 'service',
        value: advancedFilter.filter.service.description,
      });  

    advancedFilter.values = pines;

    dispatch({
      type: CoursesManagmentConstants.Accion.Courses.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setCourses = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.Courses.SEARCH_COURSES,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const courses = getState().courseReducer.courses;
    setCourses({
      dispatch: dispatch,
      isLoading: true,
      data: courses.data,
    });

    const criteria = {};
    const advancedFilter = getState().courseReducer.advancedFilter;
    const filter = getState().courseReducer.advancedFilter.filter;
    
    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    criteria.categoryId = filter?.category?.id;
    criteria.modeId = filter?.mode?.id;
    criteria.serviceTypeId = filter?.service?.id;
    
  
    CoursesService.searchCourses(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          setCourses({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          setCourses({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Curso', data.message));
        }else{
          setCourses({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Curso', data.message));
        }
      },
      () => {
        setCourses({ dispatch: dispatch, isLoading: false, data: null });
      }
    ).catch((error)=>{
    });
  };
};

const getCourseById = (courseId) => {
  const setCourse = ({ dispatch, isLoading, data, attachments }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.Courses.GET_COURSE_BY_ID,
      payload: { isLoading, data, attachments },
    });
  };

  return (dispatch, getState) => {
    setCourse({ dispatch: dispatch, isLoading: true, attachments: [] });
    CoursesService.getCourseById(courseId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {  
          const course = data.data?.course;
          const attachments = data.data?.attachment;
          course.dynamicFieldValues = data.data?.dynamicData
          setCourse({
            dispatch: dispatch,
            isLoading: false,
            data: course,
            attachments
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setCourse({ dispatch: dispatch, isLoading: false, attachments: [] });
          dispatch(toastAction.warn('Curso', data.message));
          // dispatch({
          //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
          //   toast: {
          //     titulo: 'Courseo',
          //     mensaje: data.message,
          //     severidad: 'warn',
          //   },
          // });
        }else{
          setCourse({ dispatch: dispatch, isLoading: false, attachments: [] });
          dispatch(toastAction.warn('Curso', data.message));
        }
        //setCourse({ dispatch: dispatch, isLoading: false });
      },
      () => {
        setCourse({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};

const saveCourse = (course) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentCourse }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.Courses.SAVE_COURSE,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    const currentCourse = getState().courseReducer.course;
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
      currentCourse: currentCourse,
    });
    CoursesService.saveCourses(course).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(
            toastAction.success(
              'Curso', 
              'El Curso se guardó correctamente'
              )
            );
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentCourse, });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentCourse });
          dispatch(toastAction.warn('Curso', data.message));
        }else{
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
          dispatch(toastAction.error('Curso', data.message));
        }

      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};


const deleteCourse = (courseId) => {

  return (dispatch, getState) => {

    CoursesService.deleteCourse(courseId).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Curso', 'El Curso se eliminó correctamente'));
          dispatch({ type: CoursesManagmentConstants.Accion.Courses.DELETE_COURSE, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Curso', data.message));
          dispatch({ type: CoursesManagmentConstants.Accion.Courses.DELETE_COURSE, payload: { isDeleted: false } });
        }


      },
      () => {
        dispatch({ type: CoursesManagmentConstants.Accion.Courses.DELETE_COURSE, payload: { isDeleted: false } });
      }
    );
  };
};


const getDataMaster = () => {
  const setDataMaster = ({
    dispatch,
    isLoading,
    category,
    mode,
    service,
    type,
    scale,
    dynamicFields
  }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.Courses.GET_DATA_MASTER,
      payload: { isLoading, category, mode, service, type, scale, dynamicFields},
    });
  };

  return (dispatch, getState) => {
    setDataMaster({
      dispatch: dispatch,
      isLoading: true,
      category: null,
      mode: null,
      service: null,
      type: null,
      scale: null,
      dynamicFields: [],
    });

    CoursesService.getDataMaster().then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            category: data.data.categories,
            mode: data.data.modes,
            service: data.data.serviceTypes,
            type: data.data.courseTypes,
            scale: data.data.scales,
            dynamicFields: data.data.dynamicFields,
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            category: null,
            mode: null,
            service: null,
            type: null,
            scale: null,
            dynamicFields: [],
          });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            category: null,
            mode: null,
            service: null,
            type: null,
            scale: null,
            dynamicFields: [],
          });
          dispatch(toastAction.warn('Curso', data.message));
          // dispatch({
          //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
          //   toast: {
          //     titulo: 'Courseo',
          //     mensaje: data.message,
          //     severidad: 'warn',
          //   },
          // });
        }
      },
      () => {
        setDataMaster({
          dispatch: dispatch,
          isLoading: false,
          category: null,
          mode: null,
          service: null,
          type: null,
          scale: null,
          dynamicFields: [],
        });
      }
    );
  };
};

const generateReport = (payload) => {

  const setCourses = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.Courses.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setCourses({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    //const advancedFilter = getState().courseReducer.advancedFilter;
    const filter = getState().courseReducer.advancedFilter.filter;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    criteria.parentCourseId = filter?.courseParent?.id;
    criteria.managerId = filter.manager?.id;

    CoursesService.generateReport(criteria)
      .then(({ data }) => {
        setCourses({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Cursos.xls');
      })
      
      .catch((err) => {
        setCourses({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Cursos', 'Hubo un error al descargar el archivo'));
      });
  };
};


export const CoursesAction = {
  toggleAdvancedFilter,
  search,
  updateFilter,
  getCourseById,
  saveCourse,
  getDataMaster,
  resetAfterSaved,
  deleteCourse,
  generateReport,

};
