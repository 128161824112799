import { useForm } from 'react-hook-form';

  const usePaymentProject = () => {
    let defaultValuesGeneral = {
      name: '',
      typeCourse: null,
      nameCustomer: null,
    };

    const {
      // register,
      setValue,
      handleSubmit,
      control,
      watch,
      formState: { errors },
    } = useForm({ defaultValuesGeneral });
  
    const valuesFields = watch();
    const existError = Object.keys(errors).length > 0
  
    const validateLength = (value) => {
      if (typeof value === 'string') {
        return value.replace(/<[^>]+>/g, '')?.length;
      } else if (value?.htmlValue) {
        return value.htmlValue.replace(/<[^>]+>/g, '').length;
      }
      return '0';
    };
  
    return {
      control,
      errors,
      valuesFields,
      existError,
      setValue,
      handleSubmit,
      validateLength,
    }
  }

export default usePaymentProject