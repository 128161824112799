import { CoreConstants } from "core/commons/CoreConstants";
import { toastAction } from "core/store/actions/ToastAction";
import { SurveyService } from "features/survey/services/SurveyServices";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";


const useSurveyPublic = () => {
  const toast = useRef(null);
  const params = useParams();
  const {data: surveyData, activity, error: errorSurvey, isLoading: isLoadingSurveyData} = useSelector((state) => state.surveyPublicReducer.survey);
  const {isSaved: answerSaved, error: errorAnswers, message} = useSelector((state) => state.surveyPublicReducer.answers);

  // console.log(surveyData)
  // const [isLoading, setLoading] = useState(true);

  // useEffect(() => {
  //   if (errorAnswers) {
  //     toast.current.show({severity:'error', summary: 'Encuestas', detail:'Hubo un error al enviar la encuesta', life: 3000});
  //   }
  // }, [errorAnswers])

  // useEffect(() => {
  //   if (errorSurvey) {
  //     // console.log(errorSurvey)
  //     // toast.current?.show({severity:'error', summary: 'Encuestas', detail:'Hubo un error al cargar la encuesta', life: 5000});
  //     toast.current?.show({severity:'error', summary: 'Encuestas', detail: errorSurvey, life: 5000});
  //   }
  // }, [errorSurvey])

  const typeQuestionValue = (type) => {
    switch (type) {
      case 'TX':
        return '';
      case 'OM':
        return [];
      case 'CV':
        return '';
      case 'LD':
        return '';
      case 'RE':
        return '';
      default:
        return 'dasd';
    }
  }

  // Juntar todas las preguntas de las secciones en un solo array
  const questions = surveyData ? surveyData.sections.reduce((acc, cur) => {
    acc.push(...cur.questions)
    return acc
  }, []) : null

  // Creamos un campo por cada pregunta para las respuestas
  const defaultValues = (surveyData && questions) ? questions.reduce((acc, cur, index) => {
    acc[`question_${index+1}`] = typeQuestionValue(cur.questionType?.code)
    return acc
  }, {}) : null

  const { setValue, handleSubmit, control, watch, formState: { errors }, reset, trigger, clearErrors } = useForm({defaultValues});


  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues)
    }
  }, [surveyData])

  useEffect(() => {

    SurveyService.getLogoAndColorSchema(params.id).then(c => {
      if (c.data.status === CoreConstants.HttpResponse.OK) {
        const colorSchema = c.data.data.filter(c => { return c[0].code === 'COLOR_SCHEMA' })[0];
        const companyLogo = c.data.data.filter(c => { return c[0].code === 'COMPANY_LOGO' })[0];
        if (colorSchema.length > 0) {
          const headerBackgroundColor = colorSchema[0].details.filter(c => { return c.code === 'BACKGROUND_COLOR' })[0];
          const menuDefault = colorSchema[0].details.filter(c => { return c.code === 'MENU_DEFAULT' })[0];
          const menuHover = colorSchema[0].details.filter(c => { return c.code === 'MENU_HOVER' })[0];
          const menuSelected = colorSchema[0].details.filter(c => { return c.code === 'MENU_SELECTED' })[0];

          const btnPrimary = colorSchema[0].details.filter(c => { return c.code === 'BUTTON_PRIMARY' })[0];
          const btnSeconday = colorSchema[0].details.filter(c => { return c.code === 'BUTTON_SECONDARY' })[0];
          const btnSuccess = colorSchema[0].details.filter(c => { return c.code === 'BUTTON_SUCCESS' })[0];
          const btnDark = colorSchema[0].details.filter(c => { return c.code === 'BUTTON_DARK' })[0];
          const btnError = colorSchema[0].details.filter(c => { return c.code === 'BUTTON_ERROR' })[0];
          const btnWarning = colorSchema[0].details.filter(c => { return c.code === 'BUTTON_WARNING' })[0];

          document.documentElement.style.setProperty('--sig-header-background-color', headerBackgroundColor.value);
          document.documentElement.style.setProperty('--sig-menu-default', menuDefault.value);
          document.documentElement.style.setProperty('--sig-menu-hover', menuHover.value);
          document.documentElement.style.setProperty('--sig-menu-selected', menuSelected.value);

          document.documentElement.style.setProperty('--sig-primary', btnPrimary.value);
          document.documentElement.style.setProperty('--sig-secondary', btnSeconday.value);
          document.documentElement.style.setProperty('--sig-success', btnSuccess.value);
          document.documentElement.style.setProperty('--sig-dark', btnDark.value);
          document.documentElement.style.setProperty('--sig-error', btnError.value);
          document.documentElement.style.setProperty('--sig-warning', btnWarning.value);
        }

        if (companyLogo.length > 0) {
          const logoDark = companyLogo[0].details.filter(c => { return c.code === 'LOGO_DARK' })[0];
          const logoDefault = companyLogo[0].details.filter(c => { return c.code === 'LOGO_DEFAULT' })[0];

          if (logoDefault.value.length > 0)
            document.documentElement.style.setProperty('--sig-logo-default', 'url("' + logoDefault.value + '")');
        }

      } else {
        toastAction.error('Error', 'Se produjo un error al cargar la configuración');
      }
      // setLoading(false);
    }).catch(c => {     
      // setLoading(false);
    })
  }, [params.id]);
  
  
  const valuesFields = watch();
  const existError = Object.keys(errors).length > 0

  // console.log(valuesFields)
  // console.log(errors)
  // console.log(surveyData.sections)
  
  
  return {
    control,
    valuesFields,
    errors,
    setValue,
    handleSubmit,
    reset,
    existError,
    surveyData,
    activity,
    answerSaved,
    toast,
    isLoadingSurveyData,
    questions,
    trigger,
    clearErrors,
    errorSurvey,
    message
  }
}

export default useSurveyPublic