import { TableCard } from "./TableCard";

export const CanvaComponent = ({ data, selectedRow }) => {
  return (
    <div
      style={{
        display: "grid",
        gap: 10,
        gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
      }}
    >
      {data.map((item, index) => (
        <TableCard {...item} key={index} id={item.id} selectedRow={selectedRow} />
      ))}
    </div>
  );
};
