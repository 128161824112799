import React, { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { useDispatch, useSelector } from 'react-redux';

import './ConfigurationComponent.scss'
import { RiskModelAction } from 'features/riskManagement/store/actions/RiskModelAction';
import { Panel } from 'primereact/panel';

const ConfigurationComponent = ({type}) => {

	const dispatch = useDispatch()

	const { items } = useSelector( state => state.riskModelReducer.itemsConfiguration)

	const { items: itemsSelect } = useSelector( state => state.riskModelReducer.itemsSelection)

	const { item: itemFilterSelect } = useSelector( state => state.riskModelReducer.itemFilter)

	const onDragEnd = ({destination, draggableId, source}) => {
		
		if(itemFilterSelect.length > 0 && type === 'simple') {
			return;
		}
		if(destination?.droppableId === "container-inventarios" && source?.droppableId !== "container-inventarios"){
			if(type === 'simple'){
				dispatch(RiskModelAction.setSelectedFilterItem({id: draggableId}))
			}else{
				dispatch(RiskModelAction.setSelectedItems({id: draggableId, order: destination.index}))
				dispatch(RiskModelAction.deleteInventory(draggableId))
			}
		}else if(destination?.droppableId === "container-inventarios" && source?.droppableId === "container-inventarios"){
			if(type === 'simple') {
				return;
			}	else {
				dispatch(RiskModelAction.changeOrder({start: source.index, end: destination.index}))
			}
		}
	}

	const remove = (id) => {
		if(type === 'simple'){
			dispatch(RiskModelAction.removeSelectedFilterItem())
		}else{
			dispatch(RiskModelAction.removeSelectedItems(id))
			if(itemFilterSelect.map( item => Number(item.inventoryId)).includes(Number(id))){
				dispatch(RiskModelAction.removeSelectedFilterItem())
			}
		}
	}

	return (
		<div>
			<div>Aquí podrás configurar los elementos que se mostrarán en la vista de listado.
			</div>
			<div className='mt-4'>
				<span className="w-40 p-float-label p-input-icon-right">
					<InputText
						id="inventarysearch"
						name="inventarysearch"
						// onKeyDown={handleKeyDown}
						/>
					<i className="pi pi-search" />
					<label htmlFor="inventarysearch">Buscar Inventario</label>
				</span>
				<DragDropContext onDragEnd={onDragEnd}>
					<div className='flex container-inventarios'>
						<div className='mt-4 w-40 overflow-auto scroll'>
							<Droppable droppableId='items-inventarios' direction="vertical">
							{
								(droppableProvided, snapshot) => (
									<div 
										ref={droppableProvided.innerRef}
										{...droppableProvided.droppableProps}
										className='grid grid-cols-1 gap-y-2 md:gap-2 items-center bg-white p-4 pl-0 pr-2'  
										>
											{
												(type === 'simple' ? itemsSelect.filter( item => {
													
													if(itemFilterSelect[0]){
														return Number(item.inventoryId) !== Number(itemFilterSelect[0].inventoryId);
													}
													return true; 
												}) : items).map( (item, key) => (
												<Draggable 
												key={`drag1-${item.inventoryId}`}
												draggableId={`inv-${item.inventoryId}`}
												index={key}
												>
												{
													(draggableProvided, snapshot) => (
														<div 
															ref={draggableProvided.innerRef}
															{...draggableProvided.draggableProps} 
															{...draggableProvided.dragHandleProps}
															className='customClassName border-2 border-stone-700 flex justify-start items-center w-full rounded-lg hover:bg-gray-200 bg-white px-2 h-8' data-pr-tooltip={item.name}
														>
															<Tooltip target=".customClassName" mouseTrack mouseTrackLeft={10} />
															<p className='lg:block text-center text-xs lg:text-xs lg:text-left truncate'>{item.name}</p>
														</div>
													)
												}
											</Draggable>
												))
											}										
											{droppableProvided.placeholder}
									</div>
								)
							}
							</Droppable>
						</div>
						<Panel header="Inventarios" className='mt-4 ml-8 flex-grow'>
						<Droppable droppableId='container-inventarios' direction="vertical">
							{
								(droppableProvided, snapshot) => (
									<div 
										ref={droppableProvided.innerRef}
										{...droppableProvided.droppableProps}
										className='flex flex-col gap-y-2 md:gap-2 bg-white p-4 container-inventarios'  
										>
											{
												(type === 'simple' ? itemFilterSelect : itemsSelect).map( (item, key) => {
													
													return (
												<Draggable 
												key={`drag2-${item.inventoryId}`}
												draggableId={`cont-${item.inventoryId}`}
												index={key}
												>
												{
													(draggableProvided, snapshot) => (
														<div 
															ref={draggableProvided.innerRef}
															{...draggableProvided.draggableProps} 
															{...draggableProvided.dragHandleProps}
															className='flex h-8'>
															<Tooltip target=".customClassName" mouseTrack mouseTrackLeft={10} />
															<div className='customClassName border-2 border-stone-700 flex justify-start items-center h-8 rounded-lg hover:bg-gray-200 bg-white pl-2 pr-2 w-40' data-pr-tooltip={item.name}>
															<p className='lg:block text-center text-xs lg:text-xs lg:text-left truncate'>{item.name}</p>
															</div>
															<div className='mt-auto mb-auto ml-4 cursor-pointer' onClick={()=> remove(item.inventoryId)}>
																<i className='pi pi-trash' ></i>
															</div>
														</div>
													)
												}
											</Draggable>
												)})
											}												
											{droppableProvided.placeholder}
									</div>
								)
							}
						</Droppable>
						</Panel>
					</div>
				</DragDropContext>
			</div>
		</div>
	);
};

export default ConfigurationComponent;