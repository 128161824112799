import { Fragment, useEffect } from 'react';
import { Button } from 'primereact/button';
import { FilterComponent } from 'features/customers/components/tasks/FilterComponent';
import { TableComponent } from 'features/customers/components/tasks/TableComponent';
import { AdvancedFilterComponent } from 'features/customers/components/tasks/AdvancedFilterComponent';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useTableTasks } from 'features/customers/hooks/tasks/useTableTasks';
import { useDispatch } from 'react-redux';
import { TaskAction } from 'features/customers/store/actions/TaskAction';

export const PageTasks = (props) => {
  const { confirmDelete, cancelDelete, isOpenModalDelete, getTasksByCustomer, sourceId } = useTableTasks();
  const { onNew: setNewPage } = props;
  const dispatch = useDispatch()

  return (
    <Fragment>
      {!props.hideFilterComponent && <FilterComponent/>}
      <Button
        icon="pi pi-plus"
        type="button"
        label="Nueva"
        className="sig-button sig-dark mt-4"
        onClick={setNewPage}
      />
      <TableComponent editRow={setNewPage} />
      <AdvancedFilterComponent/>
      <ConfirmDialog
        visible={isOpenModalDelete}
        onHide={cancelDelete}
        message="¿Está seguro que desea eliminar la Tarea?"
        header="Eliminar"
        icon="pi pi-trash"
        accept={confirmDelete}
        reject={cancelDelete}
      />
    </Fragment>
  );
};