import { FilterComponent } from "features/business/components/businessSheetAnswers/FilterComponent";
import { TableComponent } from "features/business/components/businessSheetAnswers/TableComponent";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useSetTitlePage from "shared/hooks/useSetTitlePage";

export const PageBusinessSheetAnswersSearch = () => {
  const params = useParams();
  const businessSheetId = Number(params.id);
  const { updateTitle } = useSetTitlePage();

  useEffect(() => {
    updateTitle({
      title: "Ficha de Negocios",
      subtitle: "Respuestas"
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return(
    <>
      <FilterComponent />
      <div className="mt-5">
        <TableComponent />
      </div>
    </>
  )
}