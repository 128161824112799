import { apiService } from "core/services/ApiService";
const currentModule = "customer/api/BusinessSheet/BusinessSheetActivity";
// const currentModule = 'customer/api/Business/Report';

const search = ({ fields = {} }) => {
  let initialFields = {
    page: 1,
    pageSize: 10,
    columnOrder: "customerName",
    order: "asc",
    ...fields,
  };

  return apiService.Post(`${currentModule}/Search`, initialFields);
};

const generateReport = (payload) => {
  return apiService.Post(`${currentModule}/GenerateReport`, payload, {
    responseType: "blob",
  });
};

const getById = (id) => {
  return apiService.Get(`${currentModule}/GetBusinessSheetActivityById?id=${id}`);
};

export const BusinessSheetAnswerService = {
  search,
  generateReport,
  getById,
};
