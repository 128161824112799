import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

export const useClaimMonitoring = () => {

  const menu = useSelector((state) => state.authReducer.user.menuPrincipal);
  const pathName = useLocation()?.pathname;
  const [isSearch, setSearch] = useState(false);
  const [isDownload, setDownload] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isView, setView] = useState(false);

  useEffect(() => {
    search();
    download();
    edit();
    view();
  }, [menu]);


  const search = () => {
    const actionCode = 'COURSE_SESSION_SEARCH';
    const optionCode = "COSG";
    setSearch(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const download = () => {
    const actionCode = 'COURSE_SESSION_DOWNLOAD';
    const optionCode = "COSG";
    setDownload(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const edit = () => {
    const actionCode = 'COURSE_SESSION_EDIT';
    const optionCode = "COSG";
    setEdit(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };


  const view = () => {
    const actionCode = 'COURSE_SESSION_VIEW';
    const optionCode = "COSG";
    setView(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
 };

  return {
    isSearch,
    isDownload,
    isEdit,
    isView,
  }
}