import { CoreConstants } from "core/commons/CoreConstants";
import { toastAction } from "core/store/actions/ToastAction";
import { CoursesManagmentConstants } from "features/coursesManagment/commons/CoursesManagmentConstants";
import { AttendanceServices } from "features/coursesManagment/services/AttendanceServices";
import FileSaver from "file-saver";
import moment from "moment";

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: CoursesManagmentConstants.Accion.Attendance.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({
      type: CoursesManagmentConstants.Accion.Attendance.RESET_AFTER_SAVED,
    });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload;

    if (advancedFilter.filter.name)
      pines.push({ field: "name", value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: "from",
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: "to",
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.category)
      pines.push({
        field: "category",
        value: advancedFilter.filter.category.description,
      });

    if (advancedFilter.filter.mode)
      pines.push({
        field: "mode",
        value: advancedFilter.filter.mode.description,
      });

    if (advancedFilter.filter.service)
      pines.push({
        field: "service",
        value: advancedFilter.filter.service.description,
      });

    advancedFilter.values = pines;

    dispatch({
      type: CoursesManagmentConstants.Accion.Attendance.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setAttendances = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.Attendance.SEARCH_ATTENDANCES,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const attendances = getState().attendanceReducer.attendances;
    setAttendances({
      dispatch: dispatch,
      isLoading: true,
      data: attendances.data,
    });

    const criteria = {};
    const advancedFilter = getState().attendanceReducer.advancedFilter;
    const filter = getState().attendanceReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    criteria.categoryId = filter?.category?.id;
    criteria.modeId = filter?.mode?.id;
    criteria.serviceTypeId = filter?.service?.id;

    AttendanceServices.searchAttendances(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          setAttendances({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          setAttendances({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn("Participantes", data.message));
        }else{
          setAttendances({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error("Participantes", data.message));
        }
      },
      () => {
        setAttendances({ dispatch: dispatch, isLoading: false, data: null });
      }
    ).catch((error)=>{
    });
  };
};

const getAttendanceById = (attendanceId) => {
  // console.log(attendanceId);
  const setAttendance = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.Attendance.GET_ATTENDANCE_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setAttendance({ dispatch: dispatch, isLoading: true });
    AttendanceServices.getAttendanceById(attendanceId).then(
      ({ data }) => {
        if (data.Status === CoreConstants.HttpResponse.OK) {
          setAttendance({
            dispatch: dispatch,
            isLoading: false,
            data: data.Data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setAttendance({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn("Participantes", data.message));
        }else{
          setAttendance({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn("Participantes", data.message));
        }
      },
      () => {
        setAttendance({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};

const saveAttendance = (attendance) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentAttendance }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.Attendance.SAVE_ATTENDANCE,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    const currentAttendance = getState().attendanceReducer.attendance;
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
      currentAttendance: currentAttendance,
    });
    AttendanceServices.saveAttendance(attendance).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(
            toastAction.success(
              "Participantes",
              "El participante se guardó correctamente"
            )
          );
          isLoading({
            dispatch: dispatch,
            isSaving: false,
            isSaved: true,
            currentAttendance,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          isLoading({
            dispatch: dispatch,
            isSaving: false,
            isSaved: false,
            currentAttendance,
          });
          dispatch(toastAction.warn("Participante", data.message));
        }else{
          dispatch(toastAction.warn("Participante", data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }

      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};

const deleteAttendance = (attendanceId) => {
  return (dispatch, getState) => {
    AttendanceServices.deleteAttendance(attendanceId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(
            toastAction.success(
              "Participantes",
              "El Participante se eliminó correctamente"
            )
          );
          dispatch({
            type: CoursesManagmentConstants.Accion.Attendance.DELETE_ATTENDANCE,
            payload: { isDeleted: true },
          });
        } else {
          dispatch(toastAction.warn("Participante", data.message));
          dispatch({
            type: CoursesManagmentConstants.Accion.Attendance.DELETE_ATTENDANCE,
            payload: { isDeleted: false },
          });
        }
      },
      () => {
        dispatch({
          type: CoursesManagmentConstants.Accion.Attendance.DELETE_ATTENDANCE,
          payload: { isDeleted: false },
        });
      }
    );
  };
};

const setDataMaster = (payload) => {
  return (dispatch) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.Attendance.GET_DATA_MASTER,
      payload,
    });
  };
};

const getDataMaster = () => {
  return (dispatch) => {
    AttendanceServices.getDataMaster()
      .then(({ data }) => {
        const { data: data2, status, message } = data;
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(
            setDataMaster({
              ...data2,
              isLoading: false,
            })
          );
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          // dispatch(toastAction.error("Gestión de Usuarios", data.message));
          dispatch(toastAction.error("Gestión de Cursos", message));
        }
      })
      .catch((error)=>{
      })
      .finally(() => {
        dispatch(
          setDataMaster({
            isLoading: false,
          })
        );
      });
    return;
    //elimine anterior estructura
  };
};

const generateReport = (payload) => {
  const setAttendances = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.Attendance.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setAttendances({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    //const advancedFilter = getState().courseReducer.advancedFilter;
    const filter = getState().attendanceReducer.advancedFilter.filter;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    criteria.parentAttendanceId = filter?.attendanceParent?.id;
    criteria.managerId = filter.manager?.id;

    AttendanceServices.generateReport(criteria)
      .then(({ data }) => {
        setAttendances({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });

        FileSaver.saveAs(blob, "Participantes.xls");
      })

      .catch((err) => {
        setAttendances({ dispatch: dispatch, isLoading: false });
        dispatch(
          toastAction.error(
            "Participantes",
            "Hubo un error al descargar el archivo"
          )
        );
      });
  };
};

export const AttendanceAction = {
  toggleAdvancedFilter,
  search,
  updateFilter,
  getAttendanceById,
  saveAttendance,
  getDataMaster,
  resetAfterSaved,
  deleteAttendance,
  generateReport,
};
