import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';


const initialState = {
  fieldLabel: '',
  typeOptionId: null,
  options: [],
  required: false
}

export const ControllerDropDown = ({titleModal, normalizeIndentifier, listOptions, positionSoruce, idTypeControl, displayExtraModal, dataExtraModal, setDataExtraModal, setDisplayExtraModal, dinamicStage, setDinamicStage, destinationControl}) => {

  // const [btnValidate, setBtnValidate] = useState(true)
  const [validateDuplicate, setValidateDuplicate] = useState('')


  // console.log(dataExtraModal)

  // useEffect(()=> {
  //   if(displayExtraModal.mode==='editar'){
  //     setDataExtraModal(inputSelected)
  //   }
  // },[])

  const addOption = () => {
    setDataExtraModal({
        ...dataExtraModal,
        options: [...dataExtraModal.options, {name: '', code: '', disabled: 'disabled'}]
      })
    }

    const changeTitle = (e) => {
      setDataExtraModal({
        ...dataExtraModal,
        fieldLabel: e.target.value})
    }

    const changeNameOption = (e,i) => {
      const data = dataExtraModal.options
        data[i].name = e.target.value
        data[i].code = (((e.target.value).substr(0,2)).toUpperCase()) + "-" + i
      setDataExtraModal({
        ...dataExtraModal,
        options: data
      })
    }

    //eliminar opción por indice
    const deleteOption = (index) =>{
    const data = dataExtraModal.options
    data.splice(index, 1);
    setDataExtraModal({
      ...dataExtraModal,
      options: data
    })
  }

  const onHide = () => {
    setDataExtraModal(initialState)
    setValidateDuplicate('')
    setDisplayExtraModal({title: '', visible: false, mode: "nuevo"})
  }

  const addDinamicOption = () => {
    const data = dataExtraModal

    data.fieldLabel = (data.fieldLabel).trim()

    if(data.fieldLabel===""){
      setValidateDuplicate("La etiqueta del campo es requerida.")
    }else{
      let dataEmpty = false
      data.options = data.options.map(el => {
        if(el.name.trim()===''){
          dataEmpty = true
        }
        el.name = (el.name.trim())
        return el
      })

      if(data.options?.length===0){
        setValidateDuplicate("Ingrese al menos un elemento para la lista desplegable.")
        return
      }else if(dataEmpty===true){
        setValidateDuplicate("Existen elementos vacíos en las opciones.")
        return
      }


      if(displayExtraModal.mode==='nuevo'){

        let validateRepeat = false
        const indentifier = normalizeIndentifier(data.fieldLabel)

        dinamicStage.map(elem =>
          elem.dinamicInput?.map(el => {
            if(el.identifier===indentifier){
              validateRepeat = true
            }
            return el
          })
        )

        if(validateRepeat===true){
          setValidateDuplicate("La etiqueta del campo, ya se encuentra en uso.")
          return
        }else{
          data.stageId = positionSoruce
          data.id = uuidv4()
          data.identifier = indentifier
          data.idTypeControl = idTypeControl
          data.mode='new'
          const visibleType = listOptions.find(el => el.id===idTypeControl)
          data.visibleSearch=visibleType.visibleSearch
          data.visibleAdvancedFilter=visibleType.visibleAdvancedFilter
          data.visibleSimpleFilter=visibleType.visibleSimpleFilter
          const clone = ([...dinamicStage])
          const indexElement = (([...dinamicStage]).findIndex(elem => elem.id===positionSoruce))
          clone[indexElement].dinamicInput.splice(destinationControl,0,data)
          setDinamicStage(clone)
        }
      }else if(displayExtraModal.mode==='editar'){
        const clone = (([...dinamicStage]).map(elem=> {
          if(elem.id===dataExtraModal.stageId){
            let newData = elem.dinamicInput.map(input => {
              if (input.id === dataExtraModal.id) {
                data.id = dataExtraModal.id
                data.typeOptionId = dataExtraModal.typeOptionId?dataExtraModal.typeOptionId:idTypeControl
                return data
              }
              return input
            })
            elem.dinamicInput= newData
            return elem
          }
          return elem
        }))
        setDinamicStage(clone)
      }
      onHide()
    }

  }

  // useEffect(()=> {
  //   if(dataExtraModal?.fieldLabel!=='' && dataExtraModal?.options.length>0){
  //     let optionState = false;
  //     dataExtraModal?.options.forEach(el=> {
  //       if(el===''){
  //         optionState = true;
  //       }
  //       if((el.name).trim()===''){
  //         optionState = true;
  //       }
  //     })
  //     if(optionState){
  //       setBtnValidate(true)
  //     }else{
  //       setBtnValidate(false)
  //     }
  //   }else{
  //     setBtnValidate(true)
  //   }
  // },[dataExtraModal])

  const footer = (
    <div>
        {/* <Button label="Cancelar" className="p-button-text" icon="pi pi-times" onClick={onHide} /> */}
        <Button
          type="button"
          label="Cancelar"
          icon="pi pi-times"
          className="sig-button sig-secondary"
           onClick={onHide}
        />
        <Button
          type="button"
          label="Guardar"
          icon="pi pi-save"
          className="sig-button sig-primary"
          onClick={addDinamicOption}
        />
    </div>
  );

  const changeAditionalValues = (e) => {
    setDataExtraModal({
      ...dataExtraModal,
      [e.target.name] : e.value
    })
  }

  return (
  <>
   <Dialog header={titleModal} footer={footer} visible={displayExtraModal?.visible} style={{width: '35vw'}} modal onHide={onHide}>
    <hr/>
    <div className=" grid grid-cols-2 gap-2 mt-2">
      <span className="p-float-label col-span-2 w-f mt-4">
                <span className="p-float-label w-full">
                    <InputText id="textAddSelect" value={dataExtraModal?.fieldLabel} autoFocus onChange={(e) => changeTitle(e)} />
                    <label htmlFor="textAddSelect">Etiqueta del Campo *</label>
                </span>
              {
                dataExtraModal?.options.map((el, i)=> <div key={i} className='col-span-2 flex w-3/5 items-center mt-2'><InputText value={el.name} className='inline rounded-lg border-indigo-400 border-2 pt-2 pb-2 pl-2' onChange={(e)=> changeNameOption(e,i)}/><i className='pi pi-trash block ml-2' style={{'fontSize': '1.5em'}} onClick={() => {deleteOption(i)}}/></div>)
              }
              <Button
                  type="button"
                  label='Añadir'
                  icon="pi pi-plus"
                  className="sig-button sig-primary w-1-/3 mt-2"
                  onClick={addOption}
                />
      </span>
        <>
          <div className='flex items-center mt-4'>
            <InputSwitch checked={dataExtraModal?.required} name="required" onChange={(e) => changeAditionalValues(e)} />
            <h5 className='ml-2'>Requerido</h5>
          </div>
        </>
      </div>
      <div className={`rounded bg-red-200 p-5 mt-6 text-sm text-red-600 ${validateDuplicate===''&&'hidden'}`}>
        <ul>
          <li>{validateDuplicate}</li>
        </ul>
      </div>
    </Dialog>
  </>
  )
}
