
import moment from 'moment';
import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from "core/store/actions/ToastAction";

import FileSaver from 'file-saver';
import { ProjectExpensesConstants } from 'features/projects/commons/ProjectExpensesConstants';
import { ProjectExpensesService } from 'features/projects/services/ProjectExpensesServices';

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: ProjectExpensesConstants.Accion.ProjectExpenses.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: ProjectExpensesConstants.Accion.ProjectExpenses.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.name) pines.push({ field: 'name', value: advancedFilter.filter.name });
    if (advancedFilter.filter.category) pines.push({ field: 'category', value: advancedFilter.filter.category.description });
   
    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });

    advancedFilter.values = pines;

    dispatch({
      type: ProjectExpensesConstants.Accion.ProjectExpenses.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setExpenses = ({ dispatch, isLoading, data, isLoaded }) => {
    dispatch({
      type: ProjectExpensesConstants.Accion.ProjectExpenses.SEARCH_PROJECT_EXPENSES,
      payload: { isLoading, data, isLoaded },
    });
  };

  return (dispatch, getState) => {
    const criteria = {};
    const filter = getState().projectExpensesReducer.advancedFilter.filter;

    criteria.to = filter.to;
    criteria.from = filter.from;
    criteria.projectName = filter.name;
    criteria.category = filter.category?.id;

    setExpenses({ dispatch: dispatch, isLoading: true, data: [], isLoaded: false });

    ProjectExpensesService.searchProjectExpenses(criteria).then(
      ({ data }) => {
        // console.log(data)
        if (data.status === CoreConstants.HttpResponse.OK)
        setExpenses({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
            isLoaded: true
          });
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Gastos del Proyecto', data.message));
          setExpenses({ dispatch: dispatch, isLoading: false, data: [], isLoaded: false });
        }else{
          setExpenses({ dispatch: dispatch, isLoading: false, data: [], isLoaded: false });
          dispatch(toastAction.error('Gastos del Proyecto', data.message));
        }
      },
    ).catch((error)=>{
    });
  };
};


const generateReport = () => {
  const setExpenses = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ProjectExpensesConstants.Accion.ProjectExpenses.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setExpenses({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().projectExpensesReducer.advancedFilter.filter;

    criteria.from = filter.from;
    criteria.to = filter.to;
    criteria.projectName = filter.name;
    criteria.category = filter.category?.id;

    ProjectExpensesService.generateReport(criteria)
      .then(({ data }) => {
        setExpenses({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Gastos del Proyecto.xlsx');
      })
      .catch((err) => {
        setExpenses({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Gastos del Proyecto', 'Hubo un error al descargar el archivo'));
      });
  };
};

const saveProjectExpense = (expense) => {
  const isLoading = ({ dispatch, isSaving, isSaved,  }) => {
    dispatch({
      type: ProjectExpensesConstants.Accion.ProjectExpenses.SAVE_PROJECT_EXPENSE,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch) => {

    isLoading({ dispatch: dispatch, isSaving: true, isSaved: false });
    ProjectExpensesService.saveProjectExpense(expense).then(
      ( {data} ) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gastos del Proyecto', 'El gasto del proyecto se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Gastos del Proyecto', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }else{
          dispatch(toastAction.error('Gastos del Proyecto', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }
      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};

const getProjectExpenseById = (expenseId) => {
  const setExpense = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ProjectExpensesConstants.Accion.ProjectExpenses.GET_PROJECT_EXPENSE_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setExpense({ dispatch: dispatch, isLoading: true, data: null });
    ProjectExpensesService.getProjectExpenseById(expenseId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          const expense = data.data.result;
          expense.dynamicFieldValues = data.data?.dynamicData
          setExpense({
            dispatch: dispatch,
            isLoading: false,
            data: expense,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setExpense({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Gastos del Proyecto', data.message));
        }else{
          setExpense({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Gastos del Proyecto', data.message));
        }
      },
      () => {
        setExpense({ dispatch: dispatch, isLoading: false, data: null });
      }
    ).catch((error)=>{
    });
  };
};

const deleteProjectExpense = (id) => {
  return (dispatch) => {
    ProjectExpensesService.deleteProjectExpense(id).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gastos del Proyecto', 'El gasto del proyecto se eliminó correctamente'));
          dispatch({ type: ProjectExpensesConstants.Accion.ProjectExpenses.DELETE_PROJECT_EXPENSE, payload: { isDeleted: true } });
        } else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Gastos del Proyecto', data.message));
          dispatch({ type: ProjectExpensesConstants.Accion.ProjectExpenses.DELETE_PROJECT_EXPENSE, payload: { isDeleted: false } });
        }else{
          dispatch(toastAction.error('Gastos del Proyecto', data.message));
          dispatch({ type: ProjectExpensesConstants.Accion.ProjectExpenses.DELETE_PROJECT_EXPENSE, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: ProjectExpensesConstants.Accion.ProjectExpenses.DELETE_PROJECT_EXPENSE, payload: { isDeleted: false } });
      }
    ).catch((error)=>{
    });
  };
};

const getDataMaster = () => {
  const setDataMaster = ({ dispatch, isLoading, categories, paymentModes, types, projects, dynamicFields }) => {
    dispatch({
      type: ProjectExpensesConstants.Accion.ProjectExpenses.GET_DATA_MASTER,
      payload: { isLoading, categories, paymentModes, types, projects, dynamicFields },
    });
  };

  return (dispatch) => {
    setDataMaster({ dispatch: dispatch, isLoading: true, categories: [], paymentModes: [], types: [], projects: [], dynamicFields: []});

    ProjectExpensesService.getDataMaster().then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            categories: data.data.projectExpenseCategories,
            paymentModes: data.data.projectExpensePaymentModes,
            types: data.data.projectExpenseTypes,
            projects: data.data.projects,
            dynamicFields: data.data.dynamicFields
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            categories: [], paymentModes: [], types: [], projects: [], dynamicFields: []
          });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            categories: [], paymentModes: [], types: [], projects: [], dynamicFields: []
          });
          dispatch(toastAction.warn('Gastos del Proyecto', data.message));
        }
      },
      () => {
        setDataMaster({ dispatch: dispatch, isLoading: false, categories: [], paymentModes: [], types: [], projects: [], dynamicFields: [] });
      }
    );
  };
};

const getCommentsById = (id) => {
  const setComments = ({ dispatch, isLoading, data  }) => {
    dispatch({
      type: ProjectExpensesConstants.Accion.ProjectExpenses.GET_COMMENTS_BY_PROJECT_EXPENSE_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setComments({ dispatch: dispatch, isLoading: true, data: [] })
    ProjectExpensesService.getCommentsByProjectExpenseId(id)
    .then(({ data }) => {
      if (data.Status === CoreConstants.HttpResponse.OK) {
          // setComments({ dispatch: dispatch, isLoading: true, data: [] })
          data.Data.forEach(result =>{
            result.CreationDate =  moment(result.CreationDate).format(CoreConstants.Format.DDMMYYYYhhmmss)
          })

          setComments({ dispatch: dispatch, isLoading: false, data: data.Data })
          return;
        }
      })
      .catch(console.error)
      .finally(() => {
        setComments({ dispatch: dispatch, isLoading: false, data: [] })
      });
  };
}

const addComment = (sourceCode, documentId, comment) => {
  const setComments = ({ dispatch, isSaved, isLoading  }) => {
    dispatch({
      type: ProjectExpensesConstants.Accion.ProjectExpenses.ADD_COMMENT,
      payload: { isSaved, isLoading },
    });
  };
  return (dispatch) => {
    const payload = { sourceCode, documentId, comments: comment}

    setComments({ dispatch: dispatch, isSaved: false, isLoading: true });
    ProjectExpensesService.addComment(payload)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          setComments({ dispatch: dispatch, isSaved: true, isLoading: false });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setComments({ dispatch: dispatch, isSaved: false, isLoading: false });
      });
  };
}

const deleteComment = (commentId) => {
  const setComments = ({ dispatch, isDeleted  }) => {
    dispatch({
      type: ProjectExpensesConstants.Accion.ProjectExpenses.DELETE_COMMENT,
      payload: { isDeleted },
    });
  };

  return (dispatch) => {
    ProjectExpensesService.deleteComment(commentId)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          setComments({ dispatch: dispatch, isDeleted: true });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setComments({ dispatch: dispatch, isDeleted: false });
      });
  };
}

export const ProjectExpensesAction = {
  toggleAdvancedFilter,
  updateFilter,
  resetAfterSaved,

  //* Actions Implementadas
  search,
  generateReport,
  deleteProjectExpense,
  getDataMaster,
  getProjectExpenseById,
  saveProjectExpense,
  getCommentsById,
  addComment,
  deleteComment,

  //* Actions sin Implementar
}