import { useRef, useState } from "react";

export const useControlConfiguration = () => {
  const [showModal, setShowModal] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const menu = useRef(null);
  const renderHeader = () => {
    return (
        <span className="ql-formats">
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
        </span>
    );
  };
  const header = renderHeader();
  const items = [
    {
      label: 'Editar',
      icon: 'pi pi-pencil',
      command: (e) => {
        setShowModal(true)
      }
    },
    {
      label: 'Eliminar',
      icon: 'pi pi-times',
      command: (e) => {
        setShowDelete(true)
      }
    }
  ];

  return {
    showModal,
    showDelete,
    menu,
    header,
    items,
    setShowModal,
    setShowDelete,
  }
  
}