/* eslint-disable import/no-anonymous-default-export */
import { RegistrationFormConstans } from "features/registrationForm/commons/RegistrationFormConstans";

const initialState = {
  dataMaster: {
    data: null,
    isLoading: false,
  },
  course: {
    data: null,
    isLoading: true,
    error: true,
  },
  saveForm: {
    isLoading: false,
    isSaved: false,
    message: null,
  },
  toast: null
}

export default (state = initialState, action) => {
  switch (action.type) {
      case RegistrationFormConstans.Accion.RegistrationDataMaster.GET_DATA_MASTER:
          return {
              ...state,
              dataMaster: {
                  ...state.dataMaster,
                  ...action.payload,
              }
          };
      
      case RegistrationFormConstans.Accion.RegistrationDataMaster.GET_DATA_FORM:
        return {
            ...state,
            course: {
                ...state.course,
                ...action.payload,
            }
        };
      
      case RegistrationFormConstans.Accion.RegistrationDataMaster.SAVE_FORM:
        return {
          ...state,
          saveForm: {
            ...state.saveForm,
            ...action.payload,
          }
        }
      
      case RegistrationFormConstans.Accion.RegistrationDataMaster.TOAST:
        return {
          ...state,
          toast: {
            ...action.payload
          }
        }

      default: 
      return state;
  }
}
