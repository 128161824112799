import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { ModalGenerateVersion } from "./ProceseSheet/ModalGenerateVersion";
import { ProcessManagementServices } from "../services/ProcessManagementServices";
import { ProcessManagementConstans } from "../commons/ProcessManagementConstans";
import { CoreConstants } from 'core/commons/CoreConstants';
import { useDispatch } from "react-redux";
import { toastAction } from "core/store/actions/ToastAction";

export const Aprobacion = ({source, id, modeview}) => {
	const [showModalGrabar, setShowModalGrabar] = useState(false)
	const [showModalRechazar, setShowModalRechazar] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useDispatch()
	const history = useHistory();

	const submit = (motivo) => {
		setIsLoading(true)
		let tipo
		if(source === "process"){
			tipo = ProcessManagementConstans.Aprobacion.Tipo.PROCESO
		}else if(source === "ficha"){
			tipo = ProcessManagementConstans.Aprobacion.Tipo.FICHA
		}
		const data = {
			documentId: Number(id),
			comment: motivo,
			documentCode: tipo,
		}
		ProcessManagementServices.approveDocument(data).then(({data})=>{
			if(data.status === CoreConstants.HttpResponse.OK){
				setIsLoading(false)
				setShowModalGrabar(false)
				dispatch(toastAction.success('Aprobación', 'Se aceptó la aprobación'))
				history.push('/inicio')
			}else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
				dispatch(toastAction.warn('Error', data.message))
				setIsLoading(false)
				setShowModalGrabar(false)
			}else if(data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS){
				dispatch(toastAction.warn('Error', data.message))
				setIsLoading(false)
				setShowModalGrabar(false)
			}else{
				setIsLoading(false)
				setShowModalGrabar(false)
			}
		}).catch((error)=>{
			setIsLoading(false)
			setShowModalGrabar(false)
    });
	}

	const rechazar = (motivo) => {
		setIsLoading(true)
		let tipo
		if(source === "process"){
			tipo = ProcessManagementConstans.Aprobacion.Tipo.PROCESO
		}else if(source === "ficha"){
			tipo = ProcessManagementConstans.Aprobacion.Tipo.FICHA
		}
		const data = {
			documentId: Number(id),
			comment: motivo,
			documentCode: tipo,
		}
		ProcessManagementServices.rejectDocument(data).then(({data})=>{
			if(data.status === CoreConstants.HttpResponse.OK){
				setIsLoading(false)
				setShowModalRechazar(false)
				dispatch(toastAction.success('Aprobación', 'Se Rechazó la aprobación'))
				history.push('/inicio')
			}else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
				dispatch(toastAction.warn('Error', data.message))
				setIsLoading(false)
				setShowModalRechazar(false)
			}else if(data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS){
				dispatch(toastAction.warn('Error', data.message))
				setIsLoading(false)
				setShowModalGrabar(false)
			}else{
				setIsLoading(false)
				setShowModalRechazar(false)
			}
		}).catch((error)=>{
			setIsLoading(false)
			setShowModalRechazar(false)
    });
	}

	return(
		<div className="flex justify-end gap-4 mt-3">
				<Button
					icon="pi pi-times"
					type="button"
					label="Cancelar"
					loading={isLoading}
					onClick={() => { history.push('/inicio') }}
					className="sig-button sig-secondary mt-4"
				/>
				{ !modeview && 
					<>
					<Button
					// loading={isDownlading | isLoadingVersion || isLoadingSubmit}
					icon="pi pi-times"
					type="button"
					label="Rechazar"
					loading={isLoading}
					className="sig-button sig-error mt-4"
					onClick={()=>{setShowModalRechazar(true)}}
					/>
					<Button
					// loading={isDownlading | isLoadingVersion || isLoadingSubmit}
					icon="pi pi-save"
					type="button"
					label="Aprobar"
					loading={isLoading}
					className="sig-button sig-success mt-4"
					onClick={()=>{setShowModalGrabar(true)}}
					/>
					</>
				}
				<ModalGenerateVersion visibleModalVersion={showModalGrabar} setVisibleModalVersion={setShowModalGrabar} onSubmitValue={submit} isLoading={isLoading} header="Aprobar" title="Ingrese el motivo de la aprobación *"/>

				<ModalGenerateVersion visibleModalVersion={showModalRechazar} setVisibleModalVersion={setShowModalRechazar} onSubmitValue={rechazar} isLoading={isLoading} header="Rechazar" title="Ingrese el motivo del rechazo *"/>
			</div>
	)
}