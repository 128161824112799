import { Fragment } from 'react';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useAdvancedFilterDynamicFields } from 'features/configuration/hooks/dynamicFields/useAdvancedFilterDynamicFields';
import { useDynamicFields } from 'features/configuration/hooks/dynamicFields/useDynamicFields';
import PinerComponent from 'shared/components/Piner/PinerComponent';

export const FilterComponent = () => {
  const {
    onToggleModal,
    dynamicFieldsLoading,
    reportLoading,
    showPiners,
    piners,
    removePiner,
    onAdvancedFilter,
    formValues,
    handleChange,
    showRemovePinerCompany,
  } = useAdvancedFilterDynamicFields();
  
  const isSearch = true
  const isDownload = true
  const { generateReport } = useDynamicFields();
  // const { isSearch, isDownload } = useClaimCompanies();
  return (
    <Fragment>
      <Panel header={`${isSearch?"FILTRO POR":"DESCARGA"}`} toggleable>
        <form className="filter-roles" onSubmit={onAdvancedFilter}>
        <div className={`w-full ${isSearch?'visible':'invisible'}`}>
            <span className="p-float-label p-input-icon-left w-full">
              <i className="pi pi-search" />
              <InputText
                id="input-search"
                name="entityName"
                value={formValues?.entityName ? formValues?.entityName : ''}
                onChange={handleChange}
              />
              <label htmlFor="input-search">Buscar por entidad</label>
            </span>
            {showPiners && (
              <div className="filter-piners mt-3">
                {piners.map(({ value, field }, index) => (
                  <PinerComponent
                    name={value}
                    field={field}
                    removePiner={removePiner}
                    key={index}
                    showRemovePinerButton={showRemovePinerCompany || field !== "companyId"}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="filter-users-action">
            <div className="flex gap-3 w-full">
              {
                isSearch&&(
                  <Button
                    icon="pi pi-search"
                    type="submit"
                    label="Buscar"
                    loading={dynamicFieldsLoading || reportLoading}
                    className="sig-button sig-primary w-full"
                  />
                )
              }
              {
                isDownload&&(
                  <Button
                    icon="pi pi-download"
                    type="button"
                    onClick={generateReport}
                    loading={reportLoading}
                    disabled={dynamicFieldsLoading || reportLoading}
                    label="Descargar"
                    className="sig-button sig-success w-full"
                  />
                )
              }
            </div>
            <div className="flex mt-3">
              {
                isSearch&&(
                  <Button
                    type="button"
                    label="Búsqueda avanzada"
                    disabled={dynamicFieldsLoading || reportLoading}
                    className="sig-button  sig-secondary w-full"
                    onClick={() => onToggleModal(true)}
                  />
                )
              }
            </div>
          </div>
        </form>
      </Panel>
    </Fragment>
  );
};
