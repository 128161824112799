import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { SearchSubscriptionPage } from './pages/Subscription/SearchSubscriptionPage';
import { SubscriptionPage } from './pages/Subscription/SubscriptionPage';

import SearchModuleIsoPage from './pages/modulesIso/SearchModuleIsoPage';

import SolutionPage from './pages/Solutions/Solution/SolutionPage';
import SearchSolutionPage from './pages/Solutions/Search/SearchSolutionPage';

import subscriptionsReducer from './store/reducers/SusbcriptionsReducer';
import modulesIsoReducer from './store/reducers/ModulesIsoReducer';
import solutionReducer from './store/reducers/SolutionsReducer';



export default function SubscriptionRouting(reducerRegistry) {
  const { path } = useRouteMatch();
  reducerRegistry.register({ subscriptionsReducer });
  reducerRegistry.register({ modulesIsoReducer });
  reducerRegistry.register({ solutionReducer });

  return (
    <>
      <Switch>
        <Route path={`${path}/suscripciones`}>
          <Route exact path={`${path}/suscripciones`} component={SearchSubscriptionPage} ren />
          <Route
            path={`${path}/suscripciones/editar/:id`}
            component={() => <SubscriptionPage title="Editar Suscripción" />}
          />
          <Route
            path={`${path}/suscripciones/visualizar/:id`}
            component={() => <SubscriptionPage title="Ver Suscripción" mode='view' />}
          />
          <Route
            path={`${path}/suscripciones/nuevo`}
            component={() => <SubscriptionPage title="Nueva Suscripción" />}
          />
        </Route>
        <Route path={`${path}/modulo_iso`}>
          <Route exact path={`${path}/modulo_iso`} component={SearchModuleIsoPage} ren />
          <Route
            path={`${path}/modulo_iso/editar/:id`}
            component={() => <SubscriptionPage title="Editar Modulo" />}
          />
          <Route
            path={`${path}/modulo_iso/nuevo`}
            component={() => <SubscriptionPage title="Nuevo Modulo" />}
          />
        </Route>
        <Route path={`${path}/solucion`}>
          <Switch>
            <Route
              exact
              path={`${path}/solucion`}
              component={SearchSolutionPage}
              ren
            />
            <Route
              path={`${path}/solucion/nuevo`}
              exact
              component={() => <SolutionPage title="Nueva Solución" />}
            />
            <Route
              path={`${path}/solucion/:id`}
              exact
              component={() => <SolutionPage title="Editar Solución" />}
            />
            <Route
              path={`${path}/solucion/visualizar/:id`}
              exact
              component={() => <SolutionPage title="Ver Solución" mode='view' />}
            />
          </Switch>
        </Route>
      </Switch>
    </>
  )
}