import { CoreConstants } from "core/commons/CoreConstants";
import moment from "moment";

export const sheetTypeSearchAdapter = (sheetType) => ({
  id: sheetType.id,
  name: sheetType.name,
  creationDate: sheetType.creationDate
    ? moment(sheetType.creationDate).format(CoreConstants.Format.DDMMYYYY)
    : "-",
});
