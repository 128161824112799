import { useState , useEffect } from "react"
import { SubscriptionsAction } from "../store/actions/SubscriptionsAction";
import { useDispatch , useSelector } from "react-redux"
const useTable = () =>{
  const { pagination   } = useSelector((state) => state.subscriptionsReducer.subscriptions);
  const subscriptions = useSelector((state) =>state.subscriptionsReducer.subscriptions);
  const dispatch = useDispatch();
  const [firstTable , setFirst] = useState(0)
  const [rowsTable , setRows] = useState(10)
  useEffect(() =>{
    if(pagination?.currentPage === 1){
      setFirst(0);
    }
  },[pagination])
  const onPageChange = ({rows , page , first}) =>{
    dispatch(SubscriptionsAction.searchTable({page:page+1}))
    setFirst(first)
  }
  const onSort = (event) =>{
    subscriptions.order = event.sortOrder + "" === "1" ? "asc" : "desc";
    subscriptions.currentCol = event.sortField;
    dispatch(SubscriptionsAction.searchTable({page:1}))
  }

  return {
    onPageChange,
    firstTable,
    rowsTable,
    pagination,
    onSort,
    tableSort: { sortField: subscriptions.currentCol, sortOrder: subscriptions.order }
  }
}

export default useTable