import { BusinessViewConstants } from "features/business/commons/BusinessViewConstants"

// eslint-disable-next-line import/no-anonymous-default-export
export const viewBusinessReducer = (state = {
  search: {
    // currentPage: 1,
    // pageCount: 1,
    // pageSize: 10,
    // rowCount: 0,
    isLoadingSearch: false,
    results: [],
    criteria: {}
  },
  dataManager: {
    loadingDataManager: false,
    currencies: [],
    customers: [],
    pipelines: [],
    responsibles: [],
    stages: [],
  },
  changeStatus: {
    businessSaving: []
  }
}, { type, payload }) => {
  switch (type) {
    case BusinessViewConstants.Action.BusinessView.DATA_MASTER:
      return {
        ...state,
        dataManager: {
          ...state.dataManager,
          ...payload,
        }
      }
    case BusinessViewConstants.Action.BusinessView.SEARCH:
      return {
        ...state,
        search: {
          results: payload.results,
          criteria: payload.criteria,
          isLoadingSearch: payload.isLoadingSearch
          //...state.search,
          // ...payload,
        }
      }
    case BusinessViewConstants.Action.BusinessView.CHANGE_STATUS:
      return {
        ...state,
        changeStatus: {
          businessSaving: payload
        }
      }
    default:
      return state
  }
}