export class CoreConstants {
    static HttpResponse = class {
        static get OK() { return 0; }
        static get ERROR_FUNTIONAL() { return 2; }
        static get REQUIRED_FIELDS() { return 1; }
        static get ERROR_TECHNICAL() { return -1; }
        static get FORBIDDEN() { return 403; }

    }
    static TypeView = class {
        static get CANVA() { return 'CANVA'; }
        static get LIST() { return 'LIST'; }
        static get STAGE() { return 'STAGE'; }
    }
    static Roles = class {
      static get ADMIN() { return 'ADMIN'; }
    }
    static Accion = class {

        static Login = class {
            static get REQUEST() { return '@AUTH.LOGIN_REQUEST'; }
            static get SUCCESS() { return '@AUTH.LOGIN_SUCCESS'; }
            static get FAILURE() { return '@AUTH.LOGIN_FAILURE'; }
            static get LOGOUT() { return '@AUTH.LOGOUT'; }
            static get DONE() { return '@AUTH.DONE'; }
            static get STATUS() { return '@AUTH.STATUS_TOKEN'; }
        }

        static Breadcrump = class {
            static get CAMBIAR_TITULO() { return '@BREADCRUMP.CAMBIAR_TITULO'; }
        }

        static Toast = class {
            static get MOSTRAR_MENSAJE() { return '@CORE.TOAST.MOSTRAR'; }
            static get OCULTAR_MENSAJE() { return '@CORE.TOAST.OCULTAR'; }
        }
        static FeedBack = class {
            static get MOSTRAR_MENSAJE() { return '@CORE.FEEDBACK.MOSTRAR'; }
            static get OCULTAR_MENSAJE() { return '@CORE.FEEDBACK.OCULTAR'; }
        }

    }
    static Format = class {
        static get DDMMYYYY() { return 'DD/MM/YYYY'; }
        static get DDMMYYYYhhmmss() { return 'DD/MM/YYYY hh:mm:ss a'; }
        static get ddmmyy() { return 'dd/mm/yy'; }
        static get LOCALE() { return 'L'; }
    }
    static Task = class {
        static Source = class {
            static get CUSTOMER() { return 'TCUS'}
            static get BUSINESS() { return 'TBUS'}
            static get PROJECT() { return 'TPRJ'}
            static get INDICATOR() { return 'IND'}
            static get EXPENSE() { return 'PEX'}
            static get COURSE_EXPENSE() { return 'UEX'}
            static get DYNAMIC() { return 'TDYN'}
        }
    }

    static StatusTask = class {
        static get 
    }

    static Entity = class {
        static CommentsSource = class {
            static get TASK() { return 'TSK'}
        }
        
        static DashbaordIndicatorModes = class {
            static get TABLE() { return 'DITB'}
            static get CHART() { return 'DICH'}
            static get BAR() { return 'DCHBAR'}
            static get LINE() { return 'DCHLIN'}
            static get METER() { return 'DCHGAU'}
        }

        static WorkflowStatus = class {
            static get PUBLICADO() { return 'PUBLICADO' }
            static get BORRADOR() { return 'BORRADOR' }
            static get DESHABILITADO() { return 'DESHABILITADO' }
            static get REVISION() { return 'REVISION' }
        }
    }

    static Role = class {
        static get ADMIN() { return 'ADMIN'; }
    }

    static Currencies = class {
      static get SOLES() { return 'PEN' }
      static get DOLARES() { return 'USD'}
    }
}