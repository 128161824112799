/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { usePagination } from "shared/hooks/usePagination"

export const useTable = (initialColumnOrder, getFilterValuesFromPiners, searchAction) => {
  const { first, onPageChange, pageSize, rowCount, setRowCount, currentPage, columnOrder, onSort, order, piners, orderTable, searchData } = usePagination()
  const dispatch = useDispatch()

  useEffect(() => {
    const filterValues = getFilterValuesFromPiners(piners)
    const searchFields = { 
      page: currentPage,
      pageSize,
      columnOrder: columnOrder ?? initialColumnOrder,
      order,
      ...filterValues,
    }

    dispatch(searchAction(searchFields, setRowCount))
  }, [pageSize, first, columnOrder, order])

  return {
    pageSize,
    onPageChange,
    first,
    rowCount,
    onSort,
    columnOrder,
    order: orderTable,
    searchData,
  }
}