import { CoreConstants } from "core/commons/CoreConstants";
import { BusinessViewServices } from "features/business/services/businessViewService";
import { BusinessViewConstants } from "features/business/commons/BusinessViewConstants";
import { toastAction } from "core/store/actions/ToastAction";

const getDataMaster = () => async (dispatch) => {
  dispatch({
    type: BusinessViewConstants.Action.BusinessView.DATA_MASTER,
    payload: { isLoadingDataMaster: true },
  });
  try {
    const data = await BusinessViewServices.getDataMaster();
    if (data.status === 200) {
      dispatch({
        type: BusinessViewConstants.Action.BusinessView.DATA_MASTER,
        payload: data.data.data,
      });
    }else{
      dispatch(toastAction.warn('Negocios', data.data.message));
    }
  } catch (e) {
    console.error(e);
  } finally {
    dispatch({
      type: BusinessViewConstants.Action.BusinessView.DATA_MASTER,
      payload: { isLoadingDataMaster: false },
    });
  }
};

const search = (searcCriteria) => async (dispatch) => {
  dispatch({
    type: BusinessViewConstants.Action.BusinessView.SEARCH,
    payload: { isLoadingSearch: true },
  });
  try {
    const data = await BusinessViewServices.search(searcCriteria);
    if (data.data.status === CoreConstants.HttpResponse.OK) {
      dispatch({
        type: BusinessViewConstants.Action.BusinessView.SEARCH,
        payload: { results: data.data.data, criteria: searcCriteria }
      });
    }else if (data.data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
      dispatch(toastAction.warn('Negocios', data.message));
    }else{
      dispatch(toastAction.error('Negocios', data.message));
    }
  } catch(error){
  }
  //  finally {
  //   dispatch({
  //     type: BusinessViewConstants.Action.BusinessView.SEARCH,
  //     payload: { isLoadingSearch: false, results: [] },
  //   });
  // }
};

const changeStatus = (data) => async (dispatch, getState) => {

  const setState = (data) => {
    dispatch({
      type: BusinessViewConstants.Action.BusinessView.CHANGE_STATUS,
      payload: data
    });
  };

  let resultSearch = getState().viewBusinessReducer.search?.results;
  let businessProcess = getState().viewBusinessReducer.changeStatus?.businessSaving;

  if (!businessProcess)
    businessProcess = [];
  businessProcess.push(data);
  setState(businessProcess);


  resultSearch?.forEach(element => {
    if (element.id === data.id) element.stageId = data.stageId
  });

  //dispatch({ type: BusinessViewConstants.Action.BusinessView.SEARCH, payload: resultSearch });

  try {
    const response = await BusinessViewServices.changeStatus(data);
    if (response.data.status === CoreConstants.HttpResponse.OK) {
      businessProcess = businessProcess.filter(c => c.id !== data.id);
      setState(businessProcess);
    }else if(response.data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
      dispatch(toastAction.warn('Negocios', response.data.message));
      resultSearch?.forEach(element => {
        if (element.id === data.id) element.stageId = data.currentStageId
      });
    }else{
      dispatch(toastAction.warn('Negocios', response.data.message));
    }

  } catch(error){
    resultSearch?.forEach(element => {
      if (element.id === data.id) element.stageId = data.currentStageId
    });
  };
};


export const ViewActions = {
  getDataMaster,
  search,
  changeStatus
};
