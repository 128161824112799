import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import Message from "shared/components/messages/Message";
import { useForm, Controller } from "react-hook-form";
import { InputTextarea } from "primereact/inputtextarea";
const EmployeeModalExperience = ({
  showModal,
  closeModal,
  isEditModal,
  objectEdit,
  setIsEditModal,
  dataManager,
  setExperiencies,
  experiencies,
}) => {
  const header = () => {
    return (
      <div className="header-modal">
        <h5 className="line-separator">Registrar nueva experiencia laboral</h5>
      </div>
    );
  };
  const months = [
    {
      month: "Enero",
      value: 1,
    },
    {
      month: "Febrero",
      value: 2,
    },
    {
      month: "Marzo",
      value: 3,
    },
    {
      month: "Abril",
      value: 4,
    },
    {
      month: "Mayo",
      value: 5,
    },
    {
      month: "Junio",
      value: 6,
    },
    {
      month: "Julio",
      value: 7,
    },
    {
      month: "Agosto",
      value: 8,
    },
    {
      month: "Septiembre",
      value: 9,
    },
    {
      month: "Octubre",
      value: 10,
    },
    {
      month: "Noviembre",
      value: 11,
    },
    {
      month: "Diciembre",
      value: 12,
    },
  ];
  const errorsArray = [];
  const {
    formState: { errors },
    handleSubmit,
    control,
    clearErrors,
    resetField,
    setValue,
    setError,
  } = useForm();

  const years = [];

  const currentYear = new Date().getFullYear();

  for (let i = 1950; i <= currentYear; i++) {
    years.push(i);
  }

  const setInfo = () => {
    setValue("companyName", objectEdit.companyName);
    setValue("positionName", objectEdit.positionName);
    setValue("jobTypeId", objectEdit.jobTypeId);
    setValue("beginMonth", objectEdit.beginMonth);
    setValue("beginYear", objectEdit.beginYear);
    setValue("endMonth", objectEdit.endMonth);
    setValue("endYear", objectEdit.endYear);
    setValue("jobFunctions", objectEdit.jobFunctions);
    setValue("jobAchievements", objectEdit.jobAchievements);
  };

  useEffect(() => {
    if (isEditModal) {
      setInfo();
    }
  }, [isEditModal]);

  const addExperiencie = (experiencie) => {
    experiencies.push({ ...experiencie, idReference: experiencies.length });
    setExperiencies(experiencies);
    setIsEditModal(false);
    reset();
    closeModal();
  };

  const validDates = (experiencie) => {
    if (experiencie.endYear === null && experiencie.endMonth === null) {
      return true;
    } else {
      if (experiencie.endYear === null || experiencie.endMonth === null) {
        if (experiencie.endYear === null)
          setError("endYear", {
            type: "manual",
            message: "El Año es Obligatorio",
          });
        if (experiencie.endMonth === null)
          setError("endMonth", {
            type: "manual",
            message: "El Mes es obligatorio",
          });
        return false;
      } else {
        if (experiencie.endYear < experiencie.beginYear) {
          setError("endYear", {
            type: "manual",
            message: "El Año de fin no puede ser menor al Año de inicio!",
          });
          return false;
        } else {
          if (experiencie.endYear === experiencie.beginYear) {
            if (experiencie.beginMonth > experiencie.endMonth) {
              setError("endMonth", {
                type: "manual",
                message: "El Mes de fin no puede ser menos al Mes de inicio!",
              });
              return false;
            } else {
              return true;
            }
          }
          return true;
        }
      }
    }
  };
  const setExperiencieData = (experiencie) => {
    if (isEditModal) {
      let idReference = experiencie.idReference;
      let isValid = validDates(experiencie);
      if (isValid) experiencies[idReference] = experiencie;
    } else {
      let isValid = validDates(experiencie);
      if (isValid) addExperiencie(experiencie);
    }
    closeModal();
  };
  const onSubmit = (data) => {
    // console.log(data);
    Object.entries(data).forEach(([key, value]) => {
      if (value === undefined) data[key] = null;
    });
    if (isEditModal) data.idReference = objectEdit.idReference;
    setExperiencieData(data);
  };
  const reset = () => {
    resetField("companyName");
    resetField("positionName");
    resetField("jobTypeId");
    resetField("beginMonth");
    resetField("beginYear");
    resetField("endMonth");
    resetField("endYear");
    resetField("jobFunctions");
    resetField("jobAchievements");
  };
  return (
    <Dialog
      header={header}
      visible={showModal}
      onHide={() => {
        setIsEditModal(false);
        closeModal();
        reset();
        clearErrors();
      }}
      closable
      breakpoints={{ "960px": "75vw", "640px": "100vw" }}
      style={{ width: "50vw" }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-1 md:grid-cols-2 gap-5 pt-4"
      >
        <div className="col-span-1">
          <span className="p-float-label mb-4">
            <Controller
              control={control}
              rules={{
                required: "La Empresa es obligatoria",
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputText
                    id="in"
                    value={value}
                    onChange={onChange}
                    className={`w-full ${
                      errors.companyName?.type === "required" ? "p-invalid" : ""
                    }`}
                  />
                  <label htmlFor="in">Empresa</label>
                </>
              )}
              name="companyName"
            />
          </span>
          <span className="p-float-label mb-3">
            <Controller
              control={control}
              rules={{
                required: "La Cargo es obligatorio",
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputText
                    id="cargo"
                    value={value}
                    onChange={onChange}
                    className={`w-full ${
                      errors.positionName?.type === "required"
                        ? "p-invalid"
                        : ""
                    }`}
                  />
                  <label htmlFor="cargo">Cargo</label>
                </>
              )}
              name="positionName"
            />
          </span>
          <Controller
            control={control}
            rules={{
              required: "El tipo de Empleo es obligatorio",
            }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                onChange={onChange}
                options={dataManager.employeesTypes}
                value={value}
                optionValue={(e) => {
                  return e.id;
                }}
                optionLabel="description"
                filter
                showClear
                className={`w-full ${
                  errors.jobTypeId?.type === "required" ? "p-invalid" : ""
                }`}
                placeholder="Tipo de Empleo"
              />
            )}
            name="jobTypeId"
          />

          <div className="grid grid-cols-2 col-span-2 gap-3">
            <h5 className="col-span-2 mt-3">Inicio</h5>
            <Controller
              control={control}
              rules={{
                required: "EL Mes de Inicio es obligatorio",
              }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  value={value}
                  optionValue="value"
                  className={`w-full ${
                    errors.beginMonth?.type === "required" ? "p-invalid" : ""
                  }`}
                  optionLabel="month"
                  options={months}
                  onChange={onChange}
                  placeholder="Mes"
                />
              )}
              name="beginMonth"
            />
            <Controller
              control={control}
              rules={{
                required: "EL Año de Inicio es obligatorio",
              }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  value={value}
                  options={years}
                  onChange={onChange}
                  className={`w-full ${
                    errors.beginYear?.type === "required" ? "p-invalid" : ""
                  }`}
                  placeholder="Año"
                />
              )}
              name="beginYear"
            />

            <h5 className="col-span-2">Fin</h5>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  value={value}
                  options={months}
                  optionValue="value"
                  optionLabel="month"
                  className={`w-full ${
                    errors.endMonth?.type === "required" ||
                    errors.endMonth?.type === "manual"
                      ? "p-invalid"
                      : ""
                  }`}
                  onChange={onChange}
                  placeholder="Mes"
                />
              )}
              name="endMonth"
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  value={value}
                  options={years}
                  className={`w-full ${
                    errors.endYear?.type === "required" ||
                    errors.endYear?.type === "manual"
                      ? "p-invalid"
                      : ""
                  }`}
                  onChange={onChange}
                  placeholder="Año"
                />
              )}
              name="endYear"
            />
          </div>
        </div>
        <div className="col-span-1">
          <h3 className="mb-2">Funciones Realizadas</h3>
          <Controller
            control={control}
            rules={{
              required: "Las Funciones son obligatorias",
            }}
            render={({ field: { onChange, value } }) => (
              <InputTextarea
                rows={5}
                cols={30}
                className={`w-full ${errors.jobFunctions?.type === 'required' ? 'p-invalid' : ''}`}
                value={value}
                onChange={onChange}
                autoResize
              />
            )}
            name="jobFunctions"
          />

          <h3 className="mt-2">Logros </h3>
          <Controller
            control={control}
            rules={{
              required: "Los Logros son obligatorios",
            }}
            render={({ field: { onChange, value } }) => (
              <InputTextarea
                rows={5}
                cols={30}
                value={value}
                className={`mt-2 w-full ${errors.jobAchievements?.type === 'required' ? 'p-invalid' : ''}`}
                onChange={onChange}
                autoResize
              />
            )}
            name="jobAchievements"
          />
        </div>
        <div className="col-span-5 w-full">
          {Object.entries(errors).forEach((key, value) => {
            errorsArray.push(key[1].message);
          })}
          {errorsArray.length > 0 && (
            <Message messages={errorsArray} status="error" />
          )}
        </div>
        <div className="col-span-5 flex justify-end gap-2 w-full">
          <Button
            icon="pi pi-times"
            type="button"
            label="Cancelar"
            onClick={() => {
              setIsEditModal(false);
              closeModal();
              clearErrors();
              reset();
            }}
            // disabled={status === ''
            className="sig-button  sig-secondary mt-4"
          />
          <Button
            icon="pi pi-save"
            type="submit"
            label="Guardar"
            // loading={status === ''}
            className="sig-button sig-primary mt-4"
          />
        </div>
      </form>
    </Dialog>
  );
};

export default EmployeeModalExperience;
