import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { CoreConstants } from 'core/commons/CoreConstants';
import { PositionsAction } from 'features/rrhh/store/actions/PositionsAction';
import { useForm } from 'shared/hooks/useForm';

const useAdvancedFilter = () => {
  const dispatch = useDispatch();
  const { departmentParent } = useSelector(
    (state) => state.departmentReducer.dataMaster
  );

  const { values, showModal } = useSelector(
    (state) => state.positionsReducer.advancedFilter
  );

  const showPiners = values.length > 0;

  const inititalValues = {
    name: null,
    fromDate: null,
    toDate: null,
    departmentId: null,
    quantityRequiredEmployees: null,
    quantityActiveEmployees: null,
  };
  const [formValues, handleChange, reset] = useForm(inititalValues);
  const onToggleModal = (showModal) => {
    dispatch(PositionsAction.setAdvancedFilter({ showModal }));
  };

  const clearFields = () => {
    reset(inititalValues);
  };

  useEffect(() => {
    if (values.length === 0) clearFields();
  }, [values]);

  const buildFilterValues = () => {
    const currentValues = { ...formValues };
    const currentPiners = [];
    for (let valueKey in currentValues) {
      const key = valueKey.toLocaleLowerCase();
      const field = valueKey;
      const isHasValue = formValues[valueKey];
      if (isHasValue) {
        if (key.includes('date')) {
          const value = moment(currentValues[valueKey]).format(
            CoreConstants.Format.LOCALE
          );
          const date = currentValues[valueKey];
          currentPiners.push({ field, value, date });
        } else if (key === 'departmentid') {
          const option = currentValues[valueKey];
          const value = currentValues[valueKey].name;
          currentValues[valueKey] = option.id;
          currentPiners.push({ field, value, option });
        } else {
          const value = currentValues[valueKey];
          currentPiners.push({ field, value });
        }
      }
    }
    return {
      currentPiners,
      currentValues,
    };
  };
  const onAdvancedFilter = (e) => {
    e.preventDefault();
    let { currentValues, currentPiners } = buildFilterValues();
    const currentSearchValue =
      values.find(({ field }) => field === 'name') ?? {};
    if (Object.values(currentSearchValue).length > 0) {
      currentValues.name = currentSearchValue.value;
      currentPiners = [...currentPiners.filter(p=> p.field !== 'name'), currentSearchValue];
    }
    dispatch(
      PositionsAction.setAdvancedFilter({
        showModal: false,
        values: currentPiners,
      })
    );
    dispatch(
      PositionsAction.getPositions({ fields: currentValues, change: true })
    );
  };

  const removePiner = (field) => {
    dispatch(PositionsAction.removeFilterValues(field));
  };

  return {
    departments: departmentParent,
    piners: values,
    onToggleModal,
    showModal,
    formValues,
    handleChangeInput: handleChange,
    clearFields,
    onAdvancedFilter,
    showPiners,
    removePiner,
  };
};

export default useAdvancedFilter;
