import { apiService } from "core/services/ApiService";
const currentModule = "customer/api/CompanyStudent";

const searchStudent = ({ fields = {}, change }) => {
  let initialFields = {
    page: 1,
    pageSize: 10,
    columnOrder: "fullName",
    order: "asc",
  };
  if (change) initialFields = { ...initialFields, ...fields };
  return apiService.Post(`${currentModule}/Search`, initialFields);
};

const saveStudent = (payload) => {

  return apiService.Post(`${currentModule}/Save`, payload);
};

const getStudentById = (id) => {
  return apiService.Get(`${currentModule}/GetCompanyStudentById?id=${id}`);
};

const deleteStudent = (id) => {
  return apiService.Delete(`${currentModule}/Delete?id=${id}`);
};

const getDataMaster = () => {
  return apiService.Post(`${currentModule}/GetDataMaster`, {});
};

const generateReport = (payload) => {
  return apiService.Post(`${currentModule}/GenerateReport`, payload, {
    responseType: "blob",
  });
};

const downloadTemplate = (fields ={}) =>{
  return apiService.Post('customer/api/CompanyStudent/BulkUpload/DownloadTemplate', fields, {
    responseType: 'blob',
  });
}

const uploadBatchInfo = (file) =>{
  return apiService.Post('customer/api/CompanyStudent/BulkUpload/UploadBatchInfo', file);
}

const saveStudents = (info) =>{
  return apiService.Post('customer/api/CompanyStudent/BulkUpload/Save', info);
}

export const StudentService = {
  searchStudent,
  saveStudent,
  getStudentById,
  deleteStudent,
  generateReport,
  getDataMaster,
  downloadTemplate,
  uploadBatchInfo,
  saveStudents
};
