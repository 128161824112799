import { apiService } from "core/services/ApiService";

const searchEmployers = (criterio) => {
  return apiService.Post("customer/api/Employer/Search", criterio);
}

const saveEmployers = (data) => {
  return apiService.Post("customer/api/Employer/Save", data);
}

const getEmployerById = (employerId) => {
  return apiService.Get("customer/api/Employer/GetEmployerById?id=" + employerId);
}

const generateReport = (payload) => {
  return apiService.Post("customer/api/Employer/GenerateReport", payload, {
    responseType: 'blob',
  })
}

const deleteEmployer = (employerId) => {
  return apiService.Delete("customer/api/Employer/Delete?id=" + employerId);
}


export const EmployersService = {
  searchEmployers,
  saveEmployers,
  getEmployerById,
  generateReport,
  deleteEmployer
};