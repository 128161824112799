import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import FilterComponent from "features/coursesManagment/components/courses/FilterComponent";
import TableComponent from "features/coursesManagment/components/courses/TableComponent";
import AdvancedFilterComponent from "features/coursesManagment/components/courses/AdvanceFilterComponent";
import { useClaimCourses } from 'features/coursesManagment/hooks/useClaimCourses';

const SearchCourseComponent = () => {
    const history = useHistory();
    const { updateTitle } = useSetTitlePage();
    const [isFirstLoading, setFirstLoading] = useState(true);
    const dispatch = useDispatch();

    const { isSearch, isDownload, isCreate } = useClaimCourses()

    useEffect(() => {
        updateTitle({
            title: 'Cursos',
            subtitle: 'Curso',
        });

        // if (isFirstLoading) {
        //     setFirstLoading(false);
        //     dispatch(CoursesAction.getDataMaster());
        // }
    }, []);


    return (
        <Fragment>
            {
                (isSearch||isDownload)&&(
                    <FilterComponent />
                )
            }
            {
                isCreate&&(
                    <Button
                        icon="pi pi-plus"
                        type="button"
                        label="Nuevo"
                        className="sig-button sig-dark mt-4"
                        onClick={() => {
                            history.push('/curso/cursos/nuevo');
                        }}
                    />
                )
            }
            <TableComponent />
            <AdvancedFilterComponent />
        </Fragment>
    );
};

export default SearchCourseComponent;
