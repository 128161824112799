import { CoreConstants } from 'core/commons/CoreConstants';
import { BusinessConstants } from 'features/customers/commons/BusinessConstants';
import { BusinessServices } from 'features/customers/services/BusinessService';
import { TaskAction } from './TaskAction';
import { toastAction } from 'core/store/actions/ToastAction';
import FileSaver from "file-saver";
import moment from 'moment';

const setBusiness = (fields) => {
  return (dispatch) =>
    dispatch({
      type: BusinessConstants.Action.Business.SEARCH_BUSINESS,
      payload: { ...fields },
    });
};

const getBusiness = (fields) => {
  return (dispatch) => {
    dispatch(setBusiness({ loading: true }));
    BusinessServices.search(fields)
      .then(({ data }) => {
        const status = data?.status ?? '';
        if (status === CoreConstants.HttpResponse.OK) {
          const { results, ...rest } = data.data;

          results.forEach(results =>{
            if(results.closeDate != null) {
              results.closeDate =  moment(results.closeDate.substring(0,10)).format(CoreConstants.Format.LOCALE)
            }
          })

          let currentFields = {
            loading: false,
            data: results,
            pagination: { ...rest },
          };

          if (fields?.fields?.order) {
            currentFields.order = fields.fields.order;
          }

          if (fields?.fields?.columnOrder) {
            currentFields.columnOrder = fields.fields.columnOrder;
          }

          dispatch(setBusiness(currentFields));
          return;
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setBusiness({ loading: false }));
      });
  };
};

const setAdvancedFilter = (payload) => {
  return (dispatch) => {
    dispatch({
      type: BusinessConstants.Action.Business.ADVANCED_FILTER,
      payload,
    });
  };
};

const getFilterValues = (newValues) => {
  const newFilterValues = {};
  newValues.forEach((val) => {
    const field = val.field.toLocaleLowerCase();
    if (field.includes('date')) newFilterValues[val.field] = val.date;
    else if (field.includes('id')) newFilterValues[val.field] = val.option.id;
    else newFilterValues[val.field] = val.value;
  });
  return newFilterValues;
};

const removeFilterValues = (removeField, customerId) => {
  return (dispatch, getState) => {
    let { values } = getState().businessReducer.advancedFilter;
    const newValues = values.filter(({ field }) => field !== removeField);
    const fields = getFilterValues(newValues);
    dispatch(getBusiness({ change: true, fields: {...fields, customerId} }));
    dispatch(setAdvancedFilter({ values: newValues }));
  };
};

const setGetDataMaster = (payload) => {
  return (dispatch) => {
    dispatch({
      type: BusinessConstants.Action.Business.DATA_MASTER,
      payload,
    });
  };
};

const getDataMaster = () => {
  return (dispatch) => {
    dispatch(setGetDataMaster({ isLoadingDataMaster: true }));
    BusinessServices.getDataMaster()
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setGetDataMaster({ data: data.data }));
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setGetDataMaster({ isLoadingDataMaster: false }));
      });
  };
};

const currentAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: BusinessConstants.Action.Business.CURRENT_ACTION,
      payload,
    });
  };
};

const setCurrentBusiness = (payload) => {
  return (dispatch) => {
    dispatch({
      type: BusinessConstants.Action.Business.EDIT_BUSINESS,
      payload,
    });
  };
};

const editBusiness = (id) => {
  return (dispatch) => {
    dispatch(setCurrentBusiness({ isLoadingBusiness: true, data: {}, attachments: [] }));
    dispatch(getBusinessById(id));
  };
};

const getBusinessById = (id) => {
  return (dispatch) => {
    dispatch(setCurrentBusiness({ isLoadingBusiness: true, data: {}, attachments: [] }));
    setTimeout(() => {
      BusinessServices.getById(id)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          // const business = data.Data;
          const business = data.data?.business;
          business.dynamicFieldValues = data.data.dynamicData
          const attachments = data.data?.attachment;
          Object.entries(business).forEach(([key, value]) => {
            if (value !== null) {
              if (key === "OpenDate" || key === 'CloseDate') {
                  let date = new Date(value);
                  business[key] = date;
              }
            }
          })
  
          // dispatch(setCurrentBusiness({ data: data.Data }));
          dispatch(setCurrentBusiness({ data: business, attachments, isLoadingBusiness: false }));
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setCurrentBusiness({ isLoadingBusiness: false }));
      });
    }, 1000)
  }
}

const reportStatus = (payload) => ({
  type: BusinessConstants.Action.Business.REPORT_STATUS,
  payload,
});

const generateReport = (id) => {
  return (dispatch, getState) => {        
    let { values } = getState().businessReducer.advancedFilter;
    dispatch(reportStatus(true));
    const filter = getFilterValues(values);

    const requestGenerateReport = { 
      // name: name == undefined? null: name, 
      customerId: id, 
      ...filter,
    }
    
    BusinessServices.generateReport(requestGenerateReport)
      .then(({ data }) => {
        dispatch(reportStatus(false));
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        FileSaver.saveAs(blob, "Negocios.xls");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(reportStatus(false));
      });
  };
};

const save = ({ business, isNewBusiness }) => {
  return (dispatch) => {
    const currentLoading = { isLoadingBusiness: true };

    dispatch(setCurrentBusiness(currentLoading));
    BusinessServices.save(business)
      .then(({ data }) => {
        const status = data.status;
        if (data.status === CoreConstants.HttpResponse.OK) {
          const message = isNewBusiness
            ? '¡Registro exitoso!'
            : '¡Edición exitosa!';
          dispatch(toastAction.success('Negocios', message));
          dispatch(setCurrentBusiness({ errors: [] }));
        } else if (
          data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS &&
          data.data.length > 0
        ) {
          dispatch(setCurrentBusiness({ errors: data.data }));
        }
        dispatch(setCurrentBusiness({ status }));
      })
      .catch((error) => {
        console.error(error);
        dispatch(setCurrentBusiness({ status: 2 }));
      })
      .finally(() => {
        dispatch(setCurrentBusiness({ isLoadingBusiness: false }));
      });
  };
};

const deleteById = (id, customerId) => {
  return (dispatch) => {
    BusinessServices.deleteById(id)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Negocio', 'Negocio Eliminado'));
          dispatch(getBusiness({ change: true, fields: {customerId} }));
        }
      })
      .catch(console.error);
  };
};

const getTasks = async (documentId) => {
  const fields = {
    "page": 1,
    "pageSize": 20,
    "columnOrder": "title",
    "order": "asc",
    "documentId": Number(documentId),
    "sourceId": 69
  }
  return await TaskAction.getTasksByFields(fields); 
}

export const BusinessAction = {
  currentAction,
  setCurrentBusiness,
  setAdvancedFilter,
  removeFilterValues,
  getBusiness,
  getDataMaster,
  editBusiness,
  generateReport,
  save,
  deleteById,
  getTasks
}
