
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

const useModalGenerateFormula = () => {

  const [formulasList, setFormulasList] = useState([])
  const [formulasError, setFormulasError] = useState([])

  // console.log(formulasList)
  // console.log(formulasError)

  const getMessageError = (formula) => {
    let message = ''
    if (!formula.identifierControl && formula.conditionalOperatorId && formula.value) message = 'Campo'
    if (formula.identifierControl && !formula.conditionalOperatorId && formula.value) message = 'Condición'
    if (formula.identifierControl && formula.conditionalOperatorId && !formula.value) message = 'Valor'
    if (!formula.identifierControl && !formula.conditionalOperatorId && formula.value) message = 'Campo y Condición'
    if (!formula.identifierControl && formula.conditionalOperatorId && !formula.value) message = 'Campo y Valor'
    if (formula.identifierControl && !formula.conditionalOperatorId && !formula.value) message = 'Condición y Valor'
    if (!formula.identifierControl && !formula.conditionalOperatorId && !formula.value) message = 'Campo, Condición y Valor'
    return message
  }

  const existRowEmpty = (list) => {
    const isRowEmpty = list.some(formula => formula.identifierControl === null || formula.conditionalOperatorId === null || formula.value === '')
    let errorRows = []
    list.forEach((formula, index) => {
      if (formula.identifierControl === null || formula.conditionalOperatorId === null || formula.value === '') {
        const modelErrow = {
          errorRow: index + 1,
          errorField: getMessageError(formula)
        }
        errorRows.push(modelErrow)
      }
    })

    return {
      isRowEmpty,
      errorRows
    }
  }

  const createFormula = () => {
    setFormulasList((prev) => [
      ...prev,
      {
        id: uuidv4(),
        identifierControl: null,
        conditionalOperatorId: null,
        value: '',
        // operator: null,
      },
    ]);
  };

  const deleteFormula= (id) => {
    const currentExpensesList = formulasList.filter((formula) => formula.id !== id);
    setFormulasList(currentExpensesList);
  };

  const updateFormula = ({ id, value, name}) => {
    const currentExpensesList = formulasList.map((formula) => {
      if (formula.id === id) {
        formula[name] = value;
        // if (name === 'amount' && value >= 0) formula[name] = Number(value);
        // if (name === 'paymentDate' || name === 'paymentMode') formula[name] = value;
      }
      return formula;
    });

    setFormulasList(currentExpensesList);
    const { errorRows } = existRowEmpty(formulasList)
    setFormulasError(errorRows)
  };

  // const onBlurAmount = (id) => {
  //   const currentExpensesList = formulasList.map((formula) => {
  //     if (formula.id === id) {
  //      formula.amount = Number(formula.amount).toFixed(2)
  //     }
  //     return formula;
  //   });
  
  //   setFormulasList(currentExpensesList);
  // };



  return {
    formulasList, setFormulasList, formulasError, setFormulasError,
    createFormula, deleteFormula, updateFormula,
    existRowEmpty
  }
}

export default useModalGenerateFormula