import { BusinessSheetAction } from "features/business/store/actions/BusinessSheetAction";
import { businessSheetAnswerAction } from "features/business/store/actions/BusinessSheetAnswerAction";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import useSetTitlePage from "shared/hooks/useSetTitlePage";

import { AttachmentSheet } from "features/business/components/businessSheet/fieldsPublic/AttachmentSheet";
import { CheckBoxSheet } from "features/business/components/businessSheet/fieldsPublic/CheckBoxSheet";
import { DropDownSheet } from "features/business/components/businessSheet/fieldsPublic/DropDownSheet";
import { InputTextSheet } from "features/business/components/businessSheet/fieldsPublic/InputTextSheet";
import { RadioButtonSheet } from "features/business/components/businessSheet/fieldsPublic/RadioButtonSheet";
import { TableSheet } from "features/business/components/businessSheet/fieldsPublic/TableSheet";
import { useForm } from "react-hook-form";
import { BusinessSheetPublicAction } from "features/business/store/actions/BusinessSheetPublicAction";
import { Skeleton } from "primereact/skeleton";
import html2pdf from "html2pdf.js";
import "./PageBusinessSheetAsnwer.scss";

export const PageBusinessSheetAnswer = () => {
  const { updateTitle } = useSetTitlePage();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const businessSheetId = params.id;
  const activityId = params.idAnswer;
  const { control, setValue } = useForm({ shouldUnregister: true });

  const businessSheet = useSelector(
    (state) => state.businessSheetPublicReducer.businessSheet.businessSheet
  );
  const loadingConfiguration = useSelector(
    (state) => state.businessSheetPublicReducer.businessSheet.loading
  );

  const businessSheetAnswer = useSelector(
    (state) => state.businessSheetAnswerReducer.data.businessSheetAnswer
  );
  const loadingData = useSelector(
    (state) => state.businessSheetAnswerReducer.data.loading
  );

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [rowsAndColumns, setRowsAndColumns] = useState({});

  const loading = loadingConfiguration && loadingData;

  useEffect(() => {
    updateTitle({
      title: "Ficha de negocios",
      subtitle: "Respuesta",
    });

    if (businessSheetId) dispatch(businessSheetAnswerAction.getById(activityId));

    return () => {
      dispatch(businessSheetAnswerAction.resetDataBusinessSheetAnswer());
      dispatch(BusinessSheetPublicAction.resetBusinessSheetPublic());
    };
  }, []);

  useEffect(() => {
    if (
      businessSheetAnswer.answers !== undefined &&
      businessSheet.questions !== undefined
    ) {
      const result = businessSheetAnswer.answers.reduce((acc, item) => {
        if (item.rowNumber !== null) {
          if (!acc[item.questionId]) {
            acc[item.questionId] = { rows: item.rowNumber };
          } else {
            acc[item.questionId].rows = Math.max(
              acc[item.questionId].rows,
              item.rowNumber
            );
          }
        }
        return acc;
      }, {});

      setRowsAndColumns(result);

      const values = {};
      businessSheetAnswer.answers.forEach((answer) => {
        let key;
        let value;
        if (answer.rowNumber && answer.columnNumber)
          key = `question_${answer.questionId}_table_${answer.rowNumber}_${answer.columnNumber}`;
        else key = `question_${answer.questionId}`;

        if (answer.valueId) {
          const field = businessSheet.questions.find(
            (question) => question.id === answer.questionId
          );
          if (field.controlCode === "SELECCION_MULTIPLE") {
            value = [{ id: answer.valueId, name: answer.value }];
          } else {
            value = { id: answer.valueId, name: answer.value };
          }
        } else value = answer.value;

        values[key] = values[key]?.length > 0 ? values[key].concat(value) : value;
      });
      Object.keys(values).forEach((key) => {
        setValue(key, values[key], { shouldValidate: true });
      });
    }
  }, [businessSheetAnswer, businessSheet]);

  const exportar = () => {
    setIsLoadingDownload(true);
    const $elementoParaConvertir = document.getElementById("form_to_print");
    let contentClone = $elementoParaConvertir.cloneNode(true);
    contentClone.classList.add("print");
    html2pdf()
      .set({
        margin: 1,
        filename: `Respuesta-${businessSheet?.name}`,
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        html2canvas: {
          scale: 3, // A mayor escala, mejores gráficos, pero más peso
          letterRendering: true,
        },
        jsPDF: {
          unit: "cm",
          format: "a4",
          orientation: "portrait", // landscape o portrait
        },
      })
      .from(contentClone)
      .save()
      .catch((err) => console.log(err))
      .finally(() => {
        contentClone = null;
        setIsLoadingDownload(false);
      });
  };

  const renderQuestions = () => {
    return businessSheet.questions?.map((question, index) => {
      console.log(businessSheet)
      if (question.sectionId !== null && question.sectionId !== undefined) {
        console.log(businessSheet)
        const section = businessSheet.sections.find((s) => s.id === question.sectionId);
        const isFirstInSection =
          index === 0 ||
          businessSheet.questions[index - 1].sectionId !== question.sectionId;

        if (isFirstInSection) {
          const sectionQuestions = businessSheet.questions.filter(
            (q) => q.sectionId === question.sectionId
          );

          return (
            <div className="flex flex-col gap-2">
              <div className="header-title py-4 px-4 bg-gray-100 shadow border-2 flex flex-col page-break">
                <div className="flex items-center gap-1">
                  <span dangerouslySetInnerHTML={{ __html: section.label }}></span>
                </div>
              </div>
              <div className="pl-8 flex flex-col gap-2">
                {sectionQuestions.map((question, indexQuestion) => {
                  if (question.controlCode === "TEXTO")
                    return (
                      <InputTextSheet
                        key={question.id}
                        control={control}
                        numberQuestion={index + indexQuestion + 1}
                        question={question}
                      />
                    );

                  if (question.controlCode === "LISTA_DESPLEGABLE")
                    return (
                      <DropDownSheet
                        key={question.id}
                        control={control}
                        numberQuestion={index + indexQuestion + 1}
                        question={question}
                      />
                    );

                  if (question.controlCode === "SELECCION_MULTIPLE")
                    return (
                      <CheckBoxSheet
                        key={question.id}
                        control={control}
                        numberQuestion={index + indexQuestion + 1}
                        question={question}
                      />
                    );

                  if (question.controlCode === "SELECCION_SIMPLE")
                    return (
                      <RadioButtonSheet
                        key={question.id}
                        control={control}
                        numberQuestion={index + indexQuestion + 1}
                        question={question}
                      />
                    );

                  if (question.controlCode === "TABLA")
                    return (
                      <TableSheet
                        key={question.id}
                        control={control}
                        numberQuestion={index + indexQuestion + 1}
                        question={question}
                      />
                    );

                  if (question.controlCode === "ADJUNTAR_ARCHIVO")
                    return (
                      <AttachmentSheet
                        key={question.id}
                        control={control}
                        numberQuestion={index + indexQuestion + 1}
                        question={question}
                      />
                    );

                  return null;
                })}
              </div>
            </div>
          );
        } else {
          return null;
        }
      } else {
        if (question.controlCode === "TEXTO")
          return (
            <InputTextSheet
              key={question.id}
              control={control}
              numberQuestion={index + 1}
              question={question}
            />
          );

        if (question.controlCode === "LISTA_DESPLEGABLE")
          return (
            <DropDownSheet
              key={question.id}
              control={control}
              numberQuestion={index + 1}
              question={question}
            />
          );

        if (question.controlCode === "SELECCION_MULTIPLE")
          return (
            <CheckBoxSheet
              key={question.id}
              control={control}
              numberQuestion={index + 1}
              question={question}
            />
          );

        if (question.controlCode === "SELECCION_SIMPLE")
          return (
            <RadioButtonSheet
              key={question.id}
              control={control}
              numberQuestion={index + 1}
              question={question}
            />
          );

        if (question.controlCode === "TABLA")
          return (
            <TableSheet
              key={question.id}
              control={control}
              numberQuestion={index + 1}
              question={question}
            />
          );

        if (question.controlCode === "ADJUNTAR_ARCHIVO")
          return (
            <AttachmentSheet
              key={question.id}
              control={control}
              numberQuestion={index + 1}
              question={question}
            />
          );

        return null;
      }
    });
  };

  return (
    <div>
      <div className="py-1 px-1 md:px-4">
        <div className="flex flex-col p-2 my-4" id="form_to_print">
          <div className={`border-2 shadow mb-0 rounded-lg bg-gray-100 header`}>
            {loading ? (
              <Skeleton height="4rem" />
            ) : (
              <>
                <h1 className={`font-semibold   py-2 px-3 text-center text-2xl`}>
                  {businessSheet?.name}
                </h1>
                <h2 className="  py-1 px-3  font-medium text-left">
                  <div dangerouslySetInnerHTML={{ __html: businessSheet?.description }} />
                </h2>
              </>
            )}
          </div>
          <div className="mt-4">
            <div className="flex justify-between p-2 border-2 border-b-0 border-gray-300 section-survey-component text-white rounded-t-lg">
              <h2 className="font-semibold">Preguntas</h2>
            </div>
            <div className="flex flex-col gap-2">
              {
                loading ? <Skeleton height="8rem" /> : renderQuestions()
                // businessSheet.questions?.map((question, index) => {
                //   if(question.controlCode === "TEXTO")
                //     return <InputTextSheet key={question.id} control={control} numberQuestion={index+1} question={question} readOnly/>

                //   if(question.controlCode === "LISTA_DESPLEGABLE")
                //     return <DropDownSheet key={question.id} control={control} numberQuestion={index+1} question={question} readOnly/>

                //   if(question.controlCode === "SELECCION_MULTIPLE")
                //     return <CheckBoxSheet key={question.id} control={control} numberQuestion={index+1} question={question} readOnly/>

                //   if(question.controlCode === "SELECCION_SIMPLE")
                //     return <RadioButtonSheet key={question.id} control={control} numberQuestion={index+1} question={question} readOnly/>

                //   if(question.controlCode === "TABLA")
                //     return <TableSheet key={question.id} control={control} numberQuestion={index+1} question={question} totalRows={rowsAndColumns[question.id].rows} readOnly/>

                //   if(question.controlCode === "ADJUNTAR_ARCHIVO")
                //     return <AttachmentSheet key={question.id} control={control} numberQuestion={index+1} question={question} readOnly/>

                //   else
                //     return <div key={question.id}>Falta implementar</div>
                // })
              }
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-4 gap-4">
          <Button
            type="button"
            label="Exportar"
            className={`sig-button sig-primary`}
            loading={loading || isLoadingDownload}
            onClick={exportar}
          />
          <Button
            type="button"
            label="Regresar"
            className={`sig-button sig-primary`}
            loading={loading || isLoadingDownload}
            onClick={() => history.push(`/negocios/fichas/respuestas/${businessSheetId}`)}
          />
        </div>
      </div>
    </div>
  );
};
