import React from 'react';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';


const FilterComponent = ({setShowModal}) => {
	return (
    <Panel header="FILTRO POR" toggleable>
      <div className="filter-components">
        <div>
            <div className="w-full ">
                <span className="p-float-label p-input-icon-left w-full ">
                    <i className="pi pi-search" />
                    <InputText
                        id="input-search"
                        // ref={criteriaSearch}
                        // onKeyDown={handleKeyDown}
                    />
                    <label htmlFor="input-search">Buscar por riesgo</label>
                </span>
                {/* {showPiners() && (
                    <div className="filter-piners mt-3">
                        {values.map(({ value, field }, index) => (
                            <PinerComponent
                                name={value}
                                field={field}
                                removePiner={removePiner}
                                key={index}
                            />
                        ))}
                    </div>
                )} */}
            </div>
        </div>



            <div className="filter-users-action">
                <div className="flex gap-3 w-full">
                    <Button
                            icon="pi pi-search"
                            type="button"
                            label="Buscar"
                            className="sig-button sig-primary w-full"
                    />
                
                
                    <Button
                            icon="pi pi-download"
                            type="button"
                            label="Descargar"
                            className="sig-button sig-success w-full"
                    />
                    
                </div>
                <div className="flex mt-3">
                    <Button
                        type="button"
                        label="Búsqueda avanzada"
                        className="sig-button sig-secondary w-full"
												onClick={()=>setShowModal(true)}
                    />
                </div>
            </div>

        </div>
    </Panel>
	);
};

export default FilterComponent;