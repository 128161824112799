import { apiService } from 'core/services/ApiService';

const getEmployees = ({ fields = {} }) => {
  let initialFields = {
    "page": 1,
    "pageSize": 10,
    "columnOrder": "firstName",
    "order": "asc",
    "names": null,
    "from": null,
    "to": null,
    "area": null,
    "managerId": null,
    "employeeType": null,
    "countryOfBirth": null,
    "maritalStatusId": null,
    "academicDegreeId": null,
    "situationId":null,
    "rowStatus": true
  };
  initialFields = { ...initialFields, ...fields };
  return apiService.Post('humanresource/api/Employee/Search', initialFields);
};


const getDataMaster = () =>{
  let fields = {
    "isArea": true,
    "isAreaPosition": true,
    "isEmployee": true,
    "isEmployeeType": true,
    "isPositionType": true,
    "isMaritalStatus": true,
    "isAcademicDegree": true,
    "isEmployeeSituation": true,
    "isGender": true,
    "isDocumentType": true,
    "isCountry": true,
    "isDepartment": true,
    "isCity": true,
    "isDistrict": true,
    "isPositionStatus": true,
    "isBranch": true,
    "isWorkDay": true
  }
  return apiService.Post('humanresource/api/Employee/GetDataMaster',fields);
}

const getDataMasterSearch = () =>{
  return apiService.Get('humanresource/api/Employee/GetDataMasterSearch');
}


const saveEmployee = (info) =>{
  return apiService.Post('humanresource/api/Employee/Save', info);
}

const generateReport = (info) =>{
  let fields ={
    "names":  null,
    "fromDate": null,
    "toDate": null,
    "areaId": null,
    "positionId": null,
    "managerId": null,
    "employeeTypeId": null,
    "countryOfBirthId": null,
    "maritalStatusId": null,
    "academicDegreeId": null,
    "rowStatus": true
  }
  fields = { ...fields, ...info };
  return apiService.Post('humanresource/api/Employee/GenerateReport',fields, {
    responseType: 'blob',
  });
}

const deleteEmployeId = (id) =>{
  return apiService.Delete('humanresource/api/Employee/DeleteEmployee?id='+id)
}

const getEmployeeId = (id) =>{
  return apiService.Get('humanresource/api/Employee/GetEmployeeById?id='+id)
}

const downloadTemplate = (fields ={}) =>{
  return apiService.Post('humanresource/api/Employee/BulkUpload/DownloadTemplate', fields, {
    responseType: 'blob',
  });
}


const uploadBatchInfo = (file) =>{
  return apiService.Post('humanresource/api/Employee/BulkUpload/UploadBatchInfo', file);
}

export const EmployeesService = {
  getEmployees,
  getEmployeeId,
  getDataMaster,
  getDataMasterSearch,
  saveEmployee,
  generateReport,
  deleteEmployeId,
  downloadTemplate,
  uploadBatchInfo,
};
