import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React,{ useEffect, useState } from 'react'

export const ModalGenerateVersion = ({title="Ingrese el motivo del cambio *",header="Nueva Versión",visibleModalVersion, setVisibleModalVersion, onSubmitValue, isLoading}) => {
  const [valor, setValor] = useState('')
  const[validate, setValidate] = useState(true)

  // const dispatch = useDispatch()
  const onHide = () => {
    setVisibleModalVersion(false)
  }

  useEffect(()=> {
    setValor('')
  },[])

  const onSubmit = () => {
    if(valor.trim()===''){
      setValor(valor.trim())
      setValidate(false)
      return
    }else{
      setValor(valor.trim())
      onSubmitValue(valor)
      return
    }
  }

  const newHeader = () => {
    return (<div className='text-xl text-blue-800'>{header}</div>)
  }

  return (
    <Dialog header={newHeader} visible={visibleModalVersion} onHide={onHide} breakpoints={{'960px': '75vw'}} style={{width: '70vw'}} >
      <hr/>
      <span className='p-float-label mt-6'>
        <InputText
          id='inputVersion'
          type="text"
          value={valor}
          onChange={(e) => {
            setValidate(true)
            setValor(e.target.value)}
          }
          className='mt-2'
        />
        <label htmlFor='inputVersion'>{title}</label>
      </span>
      <div className={`text-red-400 bg-red-200 rounded-md p-4 mt-2 text-sm ${validate===false?'block':'hidden'}`}>
        El motivo es requerido
      </div>
      <div className='mt-2 w-full flex justify-end'>
        <Button
          type='button'
          label='Cancelar'
          icon='pi pi-times'
          className='sig-button sig-secondary'
          onClick={onHide}
          loading={isLoading}
        />
        <Button
          type='button'
          label='Guardar'
          icon='pi pi-save'
          className='sig-button sig-primary ml-2'
          loading={isLoading}
          onClick={onSubmit}
        />
      </div>
    </Dialog>
  )
}
