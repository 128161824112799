import { Fragment, useCallback, useRef, useState } from "react"
import { RightArrowSvg } from "./RightArrowSvg"
import { DownArrowSvg } from "./DownArrowSvg"
import { UpArrowSvg } from "./UpArrowSvg"
import moment from "moment"
import { CoreConstants } from "core/commons/CoreConstants"

const color = "#bcbcbc"

  const dataForDownload = {
    "id": 1,
    "inputMapProcess": "Entrada del Proceso prueba 1",
    "outputMapProcess": "Salida del Proceso prueba 2",
    "processTypes": [
        {
            "id": 1,
            "processMapId": 1,
            "processTypeId": 30,
            "name": "Procesos Estratégicoss",
            "backgroundColor": "#404040",
            "processColor": "#a380d6",
            "textColor": "#000000",
            "sectionNumber": 1,
            "processMapSections": [
                {
                    "number": 1,
                    "processMapGroups": [
                        {
                            "id": 46,
                            "isGroup": true,
                            "processes": [
                                {
                                    "id": 146,
                                    "processTypeId": 30,
                                    "position": 1,
                                    "code": "Code 1",
                                    "name": "Prueba asdsajd asod sad osad sadui sadsad kias jsaui asdiasd ashui sad",
                                    "description": "<p>aaaa version 5 editado</p>",
                                    "section": 1
                                },
                                {
                                    "id": 148,
                                    "processTypeId": 30,
                                    "position": 7,
                                    "code": "Evelyn",
                                    "name": "Evelyn",
                                    "description": "<p>addd</p>",
                                    "section": 1
                                },
                                {
                                    "id": 149,
                                    "processTypeId": 30,
                                    "position": 3,
                                    "code": "POD1",
                                    "name": "Proceso de deterioro",
                                    "description": null,
                                    "section": 1
                                }
                            ]
                        },
                        {
                            "id": -1,
                            "isGroup": false,
                            "processes": [
                                {
                                    "id": 162,
                                    "processTypeId": 30,
                                    "position": 4,
                                    "code": "111",
                                    "name": "Prueba",
                                    "description": "<p>Prueba</p>",
                                    "section": 0
                                },
                                {
                                    "id": 172,
                                    "processTypeId": 30,
                                    "position": 8,
                                    "code": "111123",
                                    "name": "Prueba2",
                                    "description": "<p>desc</p>",
                                    "section": 0
                                },
                                {
                                    "id": 175,
                                    "processTypeId": 30,
                                    "position": 6,
                                    "code": "prueba3df",
                                    "name": "prueba3",
                                    "description": "<p>prueba3</p>",
                                    "section": 0
                                },
                                {
                                    "id": 192,
                                    "processTypeId": 30,
                                    "position": 2,
                                    "code": "code1",
                                    "name": "proceso prueba",
                                    "description": "<p>abbb123</p>",
                                    "section": 0
                                },
                                {
                                    "id": 354,
                                    "processTypeId": 30,
                                    "position": 9,
                                    "code": "Proceso Pr",
                                    "name": "Proceso Pr",
                                    "description": "<p>dasd</p>",
                                    "section": 0
                                },
                                {
                                    "id": 369,
                                    "processTypeId": 30,
                                    "position": 10,
                                    "code": "dasddasda",
                                    "name": "Prueba WF 1",
                                    "description": "<p>dsadad</p>",
                                    "section": 0
                                },
                                {
                                    "id": 370,
                                    "processTypeId": 30,
                                    "position": 11,
                                    "code": "dasdsadsa",
                                    "name": "Prueba WF 2",
                                    "description": "<p>dasdsadas</p>",
                                    "section": 0
                                },
                                {
                                    "id": 371,
                                    "processTypeId": 30,
                                    "position": 12,
                                    "code": "EVAL",
                                    "name": "prueba 10",
                                    "description": "<p>abc</p>",
                                    "section": 0
                                },
                                {
                                    "id": 373,
                                    "processTypeId": 30,
                                    "position": 13,
                                    "code": "prueba 14",
                                    "name": "prueba 14",
                                    "description": "<p>objt</p>",
                                    "section": 0
                                },
                                {
                                    "id": 375,
                                    "processTypeId": 30,
                                    "position": 14,
                                    "code": "pruea 15",
                                    "name": "pruea 15",
                                    "description": "<p>bbb</p>",
                                    "section": 0
                                },
                                {
                                    "id": 376,
                                    "processTypeId": 30,
                                    "position": 15,
                                    "code": "c45",
                                    "name": "prueba 16",
                                    "description": "<p>bbbb</p>",
                                    "section": 0
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "id": 2,
            "processMapId": 1,
            "processTypeId": 31,
            "name": "Procesos Clave",
            "backgroundColor": "#808080",
            "processColor": "#000000",
            "textColor": "#ffffff",
            "sectionNumber": 1,
            "processMapSections": [
                {
                    "number": 1,
                    "processMapGroups": [
                        {
                            "id": 47,
                            "isGroup": true,
                            "processes": [
                                {
                                    "id": 147,
                                    "processTypeId": 31,
                                    "position": 1,
                                    "code": "E01",
                                    "name": "PROCESO COMERCIAL",
                                    "description": "<p>SSSS</p>",
                                    "section": 1
                                },
                                {
                                    "id": 197,
                                    "processTypeId": 31,
                                    "position": 3,
                                    "code": "CSS12",
                                    "name": "Prueba CSS",
                                    "description": null,
                                    "section": 1
                                }
                            ]
                        },
                        {
                            "id": 48,
                            "isGroup": true,
                            "processes": [
                                {
                                    "id": 355,
                                    "processTypeId": 31,
                                    "position": 8,
                                    "code": "PEEE",
                                    "name": "PEEE",
                                    "description": null,
                                    "section": 1
                                },
                                {
                                    "id": 356,
                                    "processTypeId": 31,
                                    "position": 7,
                                    "code": "PPPPrueba",
                                    "name": "PPPPrueba",
                                    "description": "<p>PPPPrueba</p>",
                                    "section": 1
                                },
                                {
                                    "id": 357,
                                    "processTypeId": 31,
                                    "position": 6,
                                    "code": "PPPPrueba2",
                                    "name": "PPPPrueba2",
                                    "description": "<p>PPPPrueba2</p>",
                                    "section": 1
                                }
                            ]
                        },
                        {
                            "id": -1,
                            "isGroup": false,
                            "processes": [
                                {
                                    "id": 358,
                                    "processTypeId": 31,
                                    "position": 5,
                                    "code": "PPPPrueba3",
                                    "name": "PPPPrueba3",
                                    "description": "<p>PPPPrueba3</p>",
                                    "section": 0
                                },
                                {
                                    "id": 361,
                                    "processTypeId": 31,
                                    "position": 4,
                                    "code": "P VENTAS",
                                    "name": "P VENTAS",
                                    "description": "<p>P VENTAS</p>",
                                    "section": 0
                                },
                                {
                                    "id": 374,
                                    "processTypeId": 31,
                                    "position": 10,
                                    "code": "PROC1234",
                                    "name": "Proceso de selección",
                                    "description": null,
                                    "section": 0
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "id": 3,
            "processMapId": 1,
            "processTypeId": 32,
            "name": "Procesos Soporte",
            "backgroundColor": "#808080",
            "processColor": "#000000",
            "textColor": "#ffffff",
            "sectionNumber": 3,
            "processMapSections": [
                {
                    "number": 1,
                    "processMapGroups": [
                        {
                            "id": 49,
                            "isGroup": true,
                            "processes": []
                        },
                        {
                            "id": 50,
                            "isGroup": true,
                            "processes": []
                        },
                        {
                            "id": -1,
                            "isGroup": false,
                            "processes": [
                                {
                                    "id": 150,
                                    "processTypeId": 32,
                                    "position": 1,
                                    "code": "PRV01",
                                    "name": "Proceso de Ventas",
                                    "description": null,
                                    "section": 0
                                },
                                {
                                    "id": 372,
                                    "processTypeId": 32,
                                    "position": 4,
                                    "code": "MILEI",
                                    "name": "PROCESO DE MILEI",
                                    "description": null,
                                    "section": 0
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 2,
                    "processMapGroups": [
                        {
                            "id": 49,
                            "isGroup": true,
                            "processes": [
                                {
                                    "id": 191,
                                    "processTypeId": 32,
                                    "position": 3,
                                    "code": "Proc 1",
                                    "name": "Planificación de Servicios de Consultoría",
                                    "description": "<p>aasaa</p>",
                                    "section": 2
                                }
                            ]
                        },
                        {
                            "id": 50,
                            "isGroup": true,
                            "processes": []
                        },
                        {
                            "id": -1,
                            "isGroup": false,
                            "processes": [
                                {
                                    "id": 150,
                                    "processTypeId": 32,
                                    "position": 1,
                                    "code": "PRV01",
                                    "name": "Proceso de Ventas",
                                    "description": null,
                                    "section": 0
                                },
                                {
                                    "id": 372,
                                    "processTypeId": 32,
                                    "position": 4,
                                    "code": "MILEI",
                                    "name": "PROCESO DE MILEI",
                                    "description": null,
                                    "section": 0
                                }
                            ]
                        },
                        {
                          "id": -2,
                          "isGroup": false,
                          "processes": [
                              {
                                  "id": 150,
                                  "processTypeId": 32,
                                  "position": 1,
                                  "code": "PRV01",
                                  "name": "Proceso de Ventas",
                                  "description": null,
                                  "section": 0
                              },
                              {
                                  "id": 372,
                                  "processTypeId": 32,
                                  "position": 4,
                                  "code": "MILEI",
                                  "name": "PROCESO DE MILEI",
                                  "description": null,
                                  "section": 0
                              }
                          ]
                        },
                        {
                          "id": -3,
                          "isGroup": false,
                          "processes": [
                              {
                                  "id": 150,
                                  "processTypeId": 32,
                                  "position": 1,
                                  "code": "PRV01",
                                  "name": "Proceso de Ventas",
                                  "description": null,
                                  "section": 0
                              },
                              {
                                  "id": 372,
                                  "processTypeId": 32,
                                  "position": 4,
                                  "code": "MILEI",
                                  "name": "PROCESO DE MILEI",
                                  "description": null,
                                  "section": 0
                              }
                          ]
                      }
                    ]
                },
                {
                    "number": 3,
                    "processMapGroups": [
                        {
                            "id": 49,
                            "isGroup": true,
                            "processes": []
                        },
                        {
                            "id": 50,
                            "isGroup": true,
                            "processes": [
                                {
                                    "id": 368,
                                    "processTypeId": 31,
                                    "position": 9,
                                    "code": "prueba sec",
                                    "name": "prueba sec",
                                    "description": null,
                                    "section": 3
                                }
                            ]
                        },
                        {
                            "id": -1,
                            "isGroup": false,
                            "processes": [
                                {
                                    "id": 150,
                                    "processTypeId": 32,
                                    "position": 1,
                                    "code": "PRV01",
                                    "name": "Proceso de Ventas",
                                    "description": null,
                                    "section": 0
                                },
                                {
                                    "id": 372,
                                    "processTypeId": 32,
                                    "position": 4,
                                    "code": "MILEI",
                                    "name": "PROCESO DE MILEI",
                                    "description": null,
                                    "section": 0
                                }
                            ]
                        }
                    ]
                },
                {
                  "number": 4,
                  "processMapGroups": [
                      {
                          "id": 49,
                          "isGroup": true,
                          "processes": []
                      },
                      {
                          "id": 50,
                          "isGroup": true,
                          "processes": []
                      },
                      {
                          "id": -1,
                          "isGroup": false,
                          "processes": [
                              {
                                  "id": 150,
                                  "processTypeId": 32,
                                  "position": 1,
                                  "code": "PRV01",
                                  "name": "Proceso de Ventas",
                                  "description": null,
                                  "section": 0
                              },
                              {
                                  "id": 372,
                                  "processTypeId": 32,
                                  "position": 4,
                                  "code": "MILEI",
                                  "name": "PROCESO DE MILEI",
                                  "description": null,
                                  "section": 0
                              }
                          ]
                      }
                  ]
                },
                {
                  "number": 5,
                  "processMapGroups": [
                      {
                          "id": 49,
                          "isGroup": true,
                          "processes": []
                      },
                      {
                          "id": 50,
                          "isGroup": true,
                          "processes": []
                      },
                      {
                          "id": -1,
                          "isGroup": false,
                          "processes": [
                              {
                                  "id": 150,
                                  "processTypeId": 32,
                                  "position": 1,
                                  "code": "PRV01",
                                  "name": "Proceso de Ventas",
                                  "description": null,
                                  "section": 0
                              },
                              {
                                  "id": 372,
                                  "processTypeId": 32,
                                  "position": 4,
                                  "code": "MILEI",
                                  "name": "PROCESO DE MILEI",
                                  "description": null,
                                  "section": 0
                              }
                          ]
                      }
                  ]
                },
                {
                  "number": 6,
                  "processMapGroups": [
                      {
                          "id": 49,
                          "isGroup": true,
                          "processes": []
                      },
                      {
                          "id": 50,
                          "isGroup": true,
                          "processes": []
                      },
                      {
                          "id": -1,
                          "isGroup": false,
                          "processes": [
                              {
                                  "id": 150,
                                  "processTypeId": 32,
                                  "position": 1,
                                  "code": "PRV01",
                                  "name": "Proceso de Ventas",
                                  "description": null,
                                  "section": 0
                              },
                              {
                                  "id": 372,
                                  "processTypeId": 32,
                                  "position": 4,
                                  "code": "MILEI",
                                  "name": "PROCESO DE MILEI",
                                  "description": null,
                                  "section": 0
                              }
                          ]
                      }
                  ]
                },
                {
                  "number": 7,
                  "processMapGroups": [
                      {
                          "id": 49,
                          "isGroup": true,
                          "processes": []
                      },
                      {
                          "id": 50,
                          "isGroup": true,
                          "processes": []
                      },
                      {
                          "id": -1,
                          "isGroup": false,
                          "processes": [
                              {
                                  "id": 150,
                                  "processTypeId": 32,
                                  "position": 1,
                                  "code": "PRV01",
                                  "name": "Proceso de Ventas",
                                  "description": null,
                                  "section": 0
                              },
                              {
                                  "id": 372,
                                  "processTypeId": 32,
                                  "position": 4,
                                  "code": "MILEI",
                                  "name": "PROCESO DE MILEI",
                                  "description": null,
                                  "section": 0
                              }
                          ]
                      }
                  ]
                },
                {
                  "number": 8,
                  "processMapGroups": [
                      {
                          "id": 49,
                          "isGroup": true,
                          "processes": []
                      },
                      {
                          "id": 50,
                          "isGroup": true,
                          "processes": []
                      },
                      {
                          "id": -1,
                          "isGroup": false,
                          "processes": [
                              {
                                  "id": 150,
                                  "processTypeId": 32,
                                  "position": 1,
                                  "code": "PRV01",
                                  "name": "Proceso de Ventas",
                                  "description": null,
                                  "section": 0
                              },
                              {
                                  "id": 372,
                                  "processTypeId": 32,
                                  "position": 4,
                                  "code": "MILEI",
                                  "name": "PROCESO DE MILEI",
                                  "description": null,
                                  "section": 0
                              }
                          ]
                      }
                  ]
                },
            ]
        }
    ]
  }

export const NewProcessMapDownload = ({ url, version, data, dataForDownload, code}) => {
  const widthRef = useRef(null)
  const [isHeightContent, setIsHeightContent] = useState(true)
  const [render, setRender] = useState(false)
  const [ heigthAux, setHeighAux ] = useState(0)
  const { processTypes, inputMapProcess, outputMapProcess } = dataForDownload
  const scale = useRef()

  const divRef = useCallback((divNode) => {
    const heightDiv = divNode?.offsetHeight
    // const height = 400
    // 50 caracteres -> 365 px
    const widthOfInput = Math.floor(inputMapProcess.length * 365 / 50) + 10
    const widthOfOutput = Math.floor(outputMapProcess.length * 365 / 50) + 10
    const height = Math.max(heightDiv, widthOfInput, widthOfOutput)
    
    if(heightDiv){
      if( height > heightDiv){
        setIsHeightContent(false)
        widthRef.current = height + 40
      }else{
        widthRef.current = height
      }
    }

    const heightFooter = 120
    const maxHeight =  700 - heightFooter
    //width total 1123
    //height total 794
    //width max 1030
    //height max 700
    //1 = 93
    if(heightDiv){
      if( height && height > maxHeight){
        scale.current = 1
        scale.current = (maxHeight)/height
      } else {
        scale.current = 1
        setHeighAux(maxHeight - 40 - height)
      }
      setRender(true)
    }

  },[])

  const truncarTexto = (text, maxWidth) => {
    const maxChars = Math.floor(maxWidth / 5) * 4;

    if (text.length > maxChars) {
      return text.substring(0, maxChars-2) + '...';
    }
    return text
  }

  return(
    <div>
      <div className="grid grid-cols-3" style={{width: "1030px", marginBottom: 20}}>
        <div className="border-dark flex">
          <img
            style={{background: "var(--sig-header-background-color)"}}
            className="h-8 m-auto"
            src={url}
            alt="Calidar"
            title="Calidar"
          />
        </div>
        <div className="border-dark flex justify-center pt-2 border-l-0 border-r-0">
          {data.title}
        </div>
        <div className="grid grid-cols-2">
          <div className="grid grid-cols-4 col-span-2">
            <div className="border-dark border-r-0 pl-2 border-b-0">
              <p className="text-xs font-bold mb-2">
                Código: 
              </p>
            </div>
            <div className="border-dark pl-2 border-b-0 border-r-0">
              <p className="text-xs font-bold mb-2">
                {code || ""}
              </p>
            </div>
            <div className="border-dark border-r-0 pl-2 border-b-0">
              <p className="text-xs font-bold mb-2">
                Version: 
              </p>
            </div>
            <div className="border-dark pl-2 border-b-0">
              <p className="text-xs font-bold mb-2">
                {version}
              </p>
            </div>
          </div>
          <div className="border-dark border-r-0 pl-2">
            <p className="text-xs font-bold mb-2">
              Fecha de aprobación
            </p>
          </div>
          <div className="border-dark pl-2">
            <p className="text-xs font-bold mb-2">
              {data?.approvalDate ? moment(data.approvalDate).format(CoreConstants.Format.LOCALE) : ''}
            </p>
          </div>
        </div>
      </div>
      <div className="grid esto"
        style={{gridTemplateColumns: "80px 1fr 80px", width: "1030px", transform: `scaleY(${scale?.current})`, transformOrigin: "top"}}
      >
        <div>

        </div>

        <div style={{width: "870px"}}>
          <div ref={divRef} style={{ height: isHeightContent ? "auto" :  widthRef?.current}}>
            {
              processTypes?.map((item, index)=>{
                const widthOfSection = 862/(item.processMapSections.length)
                const widthOfProcess = Math.min(widthOfSection - 12 - 26, 150)
                return (
                  <div key={index}>
                    <div className="pl-2 pb-4 text-sm" style={{transform: `scaleY(${1/scale?.current})`}}>
                      {item.name}
                    </div>
                    <div 
                      className={`border-dark rounded w-auto p-4 ${item.processMapSections.length > 1 ? 'grid':'flex'}`}
                      style={{background: item.backgroundColor,
                        gridTemplateColumns: `repeat(${item.processMapSections.length-1}, ${widthOfSection}px) ${widthOfSection-26}px`
                      }}
                    >
                      {
                        item.processMapSections.map( (section, index) => (
                          <div key={section.number} className="flex flex-1">
                            <div className="flex flex-wrap justify-center items-center w-full gap-4">
                              {
                                section.processMapGroups.map( group => {
                                if( group.isGroup && group.processes.length > 0){
                                  return (
                                    <div className="flex flex-wrap justify-center items-center w-auto gap-4 border-2 p-1 rounded" key={group.id} style={{borderColor: color}}>
                                      {
                                        group.processes?.map((proces, index)=>(
                                          <div 
                                            key={index}
                                            className="p-2 justify-between items-center flex rounded-lg border-2 shadow-xl border-dark pt-0"
                                            style={{background: item.processColor, maxWidth: widthOfProcess }}
                                          >
                                            <div 
                                              className={'self-start'}
                                              style={{color: item.textColor,
                                                wordWrap: "break-word",
                                                maxWidth: widthOfProcess - 20,
                                                fontSize: 10,
                                                // transform: `scaleY(${1/scale})`, transformOrigin: "top"
                                              }}
                                            >
                                              {truncarTexto(proces.name, widthOfProcess - 23)}
                                            </div>
                                        </div>
                                        ))
                                      }
                                    </div>
                                  )
                                } else {
                                  return (<Fragment key={group.id}>
                                    {
                                      group.processes?.map((proces, index)=>(
                                        <div 
                                          key={index}
                                          className="p-2 justify-between items-center flex rounded-lg border-2 shadow-xl border-dark pt-0"
                                          style={{background: item.processColor, maxWidth: widthOfProcess }}
                                        >
                                          <div 
                                            className={'self-start'}
                                            style={{color: item.textColor,
                                              wordWrap: "break-word",
                                               maxWidth: widthOfProcess - 20,
                                              fontSize: 10,
                                              // transform: `scaleY(${1/scale})`, transformOrigin: "top"
                                            }}
                                          >
                                            {truncarTexto(proces.name, widthOfProcess - 23)}
                                          </div>
                                      </div>
                                      ))
                                    }
                                  </Fragment>)
                                }
                                })
                              }
                            </div>
                            {
                              index !== item.processMapSections?.length - 1 && item.processMapSections?.length > 1 &&
                              <div className="flex items-center justify-center" style={{flex: 1}}>
                                <RightArrowSvg style={{fill: color, height: 30}}/>
                              </div>
                            }
                          </div>
                        ))
                      }
                    </div>
                    {
                      index === 0 &&
                      <div className="flex justify-around">
                        <DownArrowSvg style={{fill: color}}/>
                        <UpArrowSvg style={{fill: color}}/>
                      </div>
                    }
                    {
                      index === 1 &&
                      <div className="flex justify-around">
                        <UpArrowSvg style={{fill: color}}/>
                        <DownArrowSvg style={{fill: color}}/>
                      </div>
                    }
                  </div>
                )
              }
              )
            }
          </div>
          <div style={{transform: "rotate(-90deg)", height: "80px",transformOrigin: "bottom left", width: widthRef?.current, position: "relative", top: "-80px"}}>
            <div 
              style={{ height: "80px" }}
            >
              <div
                className="flex justify-center py-2 pt-0 rounded"
                style={{background: color, height: "40px"}}
              >
                <p style={{transform: `scaleX(${1/scale?.current})`}}>
                  { inputMapProcess }
                </p>
              </div>
              <div className="flex justify-center mt-1">
                <DownArrowSvg style={{fill: color}}/>
              </div>
            </div>
          </div>
        </div>
        <div style={{height: "80px", width: widthRef?.current, position: "relative", top: "-80px"}}>
            <div 
              style={{ transform: "rotate(90deg)", height: "80px",transformOrigin: "bottom left"}}
            >
              <div
                className="flex justify-center py-2 pt-0 rounded"
                style={{background: color, height: "40px"}}
              >
                <p style={{transform: `scaleX(${1/scale?.current})`}}>
                  { outputMapProcess }
                </p>
              </div>
              <div className="flex justify-center mt-1">
                <UpArrowSvg style={{fill: color}}/>
              </div>
            </div>
        </div>
        {
          heigthAux !== 0 &&
          <div className="col-span-3" style={{height: heigthAux}}></div>
        }
        <div className="grid grid-cols-2" style={{width: "1030px", position: "relative", top: "-60px", columnSpan: 3, transform: `scaleY(${1/scale?.current})`}}>
          <div className="border-dark flex">
            <p className="text-xs m-auto mb-2">
              <span className="font-bold">Elaborado por: </span>{data.elaboratorName}
            </p>
          </div>
          <div className="border-dark flex border-l-0">
            <p className="text-xs m-auto mb-2">
              <span className="font-bold">Revisador y aprobado por: </span>{data.approverName}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}