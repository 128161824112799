import { useState , useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
import { ModulesActions } from "features/subscription/store/actions/ModulesIsoActions";

const useTable = () =>{
  const { pagination } = useSelector((state) => state.modulesIsoReducer.modules);
  const dispatch = useDispatch();
  const [firstTable , setFirst] = useState(0)
  const [rowsTable , setRows] = useState(10)
  useEffect(() =>{
    if(pagination?.currentPage === 1){
      setFirst(0);
    }
  },[pagination])
  const onPageChange = ({rows , page , first}) =>{
    dispatch(ModulesActions.searchTable({page:page+1}))
    setFirst(first)
  }
  return {
    onPageChange,
    firstTable,
    rowsTable
  }
}

export default useTable