import { apiService } from "../../../core/services/ApiService";

const currentModule = "customer/api/Business";

const getDataMaster = () => {
  return apiService.Post(`${currentModule}/GetDataMaster`);
};

const search = (data) => {
  return apiService.Post(`${currentModule}/SearchView`, data);
};

const changeStatus = (data) => {
  return apiService.Post(`${currentModule}/ChangeStage`, data);
};

export const BusinessViewServices = {
  getDataMaster,
  search,
  changeStatus
};
