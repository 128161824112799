/* eslint-disable default-case */
import { HomeActions } from "shared/store/actions/HomeAction";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PinerComponent from "shared/components/Piner/PinerComponent";

const FilterComponent = () => {
    const dispatch = useDispatch();
    const criteriaSearch = useRef(null);
    const advancedFilter = useSelector((state) => state.homeReducer.advancedFilter);
    const values = useSelector((state) => state.homeReducer.advancedFilter.values);
    const { isLoading, data } = useSelector((state) => state.homeReducer.task)
    const openModal = () => {
        dispatch(HomeActions.toggleAdvancedFilter({ showModal: true}));
    };

    const showPiners = () => {
        if (values?.length > 0)
            return true;
        return false;
    }

    const removePiner = (field, idValue) => {
        switch (field) {
            case 'title':
                advancedFilter.filter.title = null;
                document.getElementById('input-search').value = ''
                break;
            case 'to':
                advancedFilter.filter.to = null;
                break;
            case 'from':
                advancedFilter.filter.from = null;
                break;
            case 'business':
                advancedFilter.filter.business = null;
                criteriaSearch.current.value = '';
                break;
            case 'projects':
                advancedFilter.filter.projects = null;
                criteriaSearch.current.value = '';
                break;
            case 'customers':
                advancedFilter.filter.customers = null;
                criteriaSearch.current.value = '';
                break;
            case 'taskSource':
                advancedFilter.filter.taskSource = null;
                criteriaSearch.current.value = '';
                break;
            case 'taskStatus':
                advancedFilter.filter.taskStatus = null;
                criteriaSearch.current.value = '';
                break;
            case 'responsibles':
              console.log(advancedFilter.filter.responsibles)
              const newResponsibles = advancedFilter.filter.responsibles.filter (({id})=> id !== idValue)
              advancedFilter.filter.responsibles = newResponsibles.length > 0 ? newResponsibles : null;
              break;
        }
        dispatch(HomeActions.updateFilter(advancedFilter));
        dispatch(HomeActions.search());
    };

    const search = () => {
        const value = criteriaSearch.current.value;
        if(value !== null && value !== undefined && value.trim().length > 0) {
            advancedFilter.filter.title = value;
            dispatch(HomeActions.updateFilter(advancedFilter));
        }
        dispatch(HomeActions.search());
    }

    const handleKeyDown = (event) => {
        if(event.key === 'Enter') {
            search();
        }
    }

    const generateReport = () => {
        const value = criteriaSearch.current.value;
        if(value !== null && value !== undefined && value.trim().length > 0) {
            advancedFilter.filter.title = value;
            dispatch(HomeActions.updateFilter(advancedFilter));
        }
        dispatch(HomeActions.generateReport());
    }

  return (
    <Panel header="FILTRO POR" toggleable collapsed={+true}>
      <div className="filter-components">
        <div>
            <div className="w-full ">
                <span className="p-float-label p-input-icon-left w-full ">
                    <i className="pi pi-search" />
                    <InputText
                        id="input-search"
                        ref={criteriaSearch}
                         onKeyDown={handleKeyDown}
                    />
                    <label htmlFor="input-search">Buscar por tarea</label>
                </span>
                {showPiners() && (
                    <div className="filter-piners mt-3">
                        {values.map(({ value, field, id }, index) => (
                            <PinerComponent
                                name={value}
                                field={field}
                                removePiner={removePiner}
                                key={index}
                                id={id}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>

        

            <div className="filter-users-action">
                <div className="flex gap-3 w-full">
                    <Button
                        icon="pi pi-search"
                        type="button"
                        label="Buscar"
                        onClick={search}
                        loading={isLoading}
                        className="sig-button sig-primary w-full"
                    />
                    <Button
                        icon="pi pi-download"
                        type="button"
                        loading={isLoading}
                        onClick={generateReport}
                        label="Descargar"
                        className="sig-button sig-success  w-full"
                    />
                </div>
                <div className="flex mt-3">
                    <Button
                        type="button"
                        label="Búsqueda avanzada"
                        className="sig-button  sig-secondary w-full"
                        onClick={openModal}
                    />
                </div>
            </div>

        

        </div>
    </Panel>
  )
}

export default FilterComponent;