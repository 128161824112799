
import moment from 'moment';
import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from "core/store/actions/ToastAction";
import FileSaver from 'file-saver';
import { IndicatorsConstants } from 'features/indicators/commons/IndicatorsConstans';
import { IndicatorsService } from 'features/indicators/services/IndicatorServices';

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: IndicatorsConstants.Accion.Indicators.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: IndicatorsConstants.Accion.Indicators.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch) => {
    const pines = [];
    const advancedFilter = payload; 

    if (advancedFilter.filter.title) pines.push({ field: 'title', value: advancedFilter.filter.title });
    if (advancedFilter.filter.name) pines.push({ field: 'name', value: advancedFilter.filter.name });
    if (advancedFilter.filter.code) pines.push({ field: 'code', value: advancedFilter.filter.code });
    if (advancedFilter.filter.status) pines.push({ field: 'status', value: advancedFilter.filter.status.description });
    if (advancedFilter.filter.owner) pines.push({ field: 'owner', value: advancedFilter.filter.owner.fullName });
    if (advancedFilter.filter.source) pines.push({ field: 'source', value: advancedFilter.filter.source.description });
    if (advancedFilter.filter.period) pines.push({ field: 'period', value: advancedFilter.filter.period });
   
    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });

    advancedFilter.values = pines;

    dispatch({
      type: IndicatorsConstants.Accion.Indicators.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const generateReport = (payload) => {
  const setIndicator = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Indicators.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setIndicator({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().indicatorReducer.advancedFilter.filter;

    criteria.title = filter.title;
    criteria.name = filter.name;
    criteria.to = filter.to;
    criteria.from = filter.from;
    criteria.code = filter.code;
    criteria.statusId = filter.status?.id;
    criteria.period = filter.period ? Number(filter.period) : null;
    criteria.responsibleId = filter.owner?.id;
    criteria.sourceId = filter.source?.id;

    IndicatorsService.generateReport(criteria)
      .then(({ data }) => {
        setIndicator({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Indicador.xls');
      })
      .catch((err) => {
        setIndicator({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Indicador', 'Hubo un error al descargar el archivo'));
      });
  };
};

const search = () => {
  const setIndicators = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Indicators.SEARCH_INDICATORS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    // const listFilesFolders = getState().indicatorReducer.listFilesFolders;
    // setIndicators({ dispatch: dispatch, isLoading: true, data: listFilesFolders.data });

    const criteria = {};
    const advancedFilter = getState().indicatorReducer.advancedFilter;
    const filter = getState().indicatorReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;
    

    criteria.title = filter.title;
    criteria.name = filter.name;
    criteria.to = filter.to;
    criteria.from = filter.from;
    criteria.code = filter.code;
    criteria.statusId = filter.status?.id;
    criteria.period = filter.period ? Number(filter.period) : null;
    criteria.responsibleId = filter.owner?.id;
    criteria.sourceId = filter.source?.id;

    setIndicators({ dispatch: dispatch, isLoading: true, data: []});
    IndicatorsService.searchIndicators(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK){
          data.data.results = data.data.results.map( indicator => {
            if(indicator.period === "0") indicator.period = "-"
            return indicator
          })
          setIndicators({ dispatch: dispatch, isLoading: false, data: data.data});
          // setIndicators({ dispatch: dispatch, isLoading: false, data: data.data.results});
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Indicador', data.message));
          setIndicators({ dispatch: dispatch, isLoading: false, data: [] });
        }else{
          setIndicators({ dispatch: dispatch, isLoading: false, data: [] });
          dispatch(toastAction.error('Indicador', data.message));
        }
      }
    ).catch((error)=>{
    });
  };
};


const saveIndicator = (indicator, generate) => {
  const isLoading = ({ dispatch, isSaving, isSaved }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Indicators.SAVE_INDICATOR,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  const saveService = generate ? 
    IndicatorsService.saveAndGenerateIndicator :
    IndicatorsService.saveIndicator 

  return (dispatch) => {
    isLoading({ dispatch: dispatch, isSaving: true, isSaved: false });
    saveService(indicator).then(
      ( {data} ) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Indicador', 'El indicador se guardo correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Indicador', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }else{
          dispatch(toastAction.error('Indicador', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }
      }
    ).catch((error)=>{
    });
  };
};

const getIndicatorById = (indicatorId) => {
  const setIndicator = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Indicators.GET_INDICATOR_BY_ID,
      payload: { isLoading, data },
    });
  };
  const setVersions = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Indicators.GET_VERSIONS,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setIndicator({ dispatch: dispatch, isLoading: true, data: null });
    setVersions({ dispatch: dispatch, isLoading: true, data: [] });
    IndicatorsService.getIndicatorById(indicatorId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {

          const indicator = data.data.indicator;
          indicator.dynamicFieldValues = data.data?.dynamicData

          setIndicator({
            dispatch: dispatch,
            isLoading: false,
            data: indicator,
          });
          setVersions({
            dispatch: dispatch,
            isLoading: false,
            data: data.data.versions,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setIndicator({ dispatch: dispatch, isLoading: false, data: null });
          setVersions({ dispatch: dispatch, isLoading: false, data: [] });
          dispatch(toastAction.warn('Indicador', data.message));
        }else{
          setIndicator({ dispatch: dispatch, isLoading: false, data: null });
          setVersions({ dispatch: dispatch, isLoading: false, data: [] });
          dispatch(toastAction.error('Indicador', data.message));
        }
      }
    ).catch((error)=>{
    });
  };
};


const deleteIndicator = (id) => {

  return (dispatch) => {

    IndicatorsService.deleteIndicator(id).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Indicador', 'El indicador se eliminó correctamente'));
          dispatch({ type: IndicatorsConstants.Accion.Indicators.DELETE_INDICATOR, payload: { isDeleted: true } });
        } else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Indicador', data.message));
          dispatch({ type: IndicatorsConstants.Accion.Indicators.DELETE_INDICATOR, payload: { isDeleted: false } });
        }else{
          dispatch(toastAction.warn('Indicador', data.message));
          dispatch({ type: IndicatorsConstants.Accion.Indicators.DELETE_INDICATOR, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: IndicatorsConstants.Accion.Indicators.DELETE_INDICATOR, payload: { isDeleted: false } });
      }
    ).catch((error)=>{
    });
  };
};


const getDataMaster = () => {
  const setDataMaster = ({ dispatch, isLoading, indicatorFrecuency, indicatorGoal, indicatorSources, indicatorType, indicatorUnitMeasure, responsibles, indicatorStatus, processes, dynamicFields }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Indicators.GET_DATA_MASTER,
      payload: { isLoading, indicatorFrecuency, indicatorGoal, indicatorSources, indicatorType, indicatorUnitMeasure, responsibles, indicatorStatus, processes, dynamicFields },
    });
  };

  return (dispatch) => {
    setDataMaster({ dispatch: dispatch, isLoading: true, indicatorFrecuency: [], indicatorGoal: [], indicatorSources: [], indicatorType: [], indicatorUnitMeasure:[], responsibles:[], indicatorStatus:[], processes: [], dynamicFields: [] });

    IndicatorsService.getDataMaster().then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            indicatorFrecuency: data.data.indicatorFrecuency,
            indicatorGoal: data.data.indicatorGoal,
            indicatorSources: data.data.indicatorSources,
            indicatorType: data.data.indicatorType,
            indicatorUnitMeasure: data.data.indicatorUnitMeasure,
            responsibles: data.data.responsibles,
            indicatorStatus: data.data.indicatorStatus,
            processes: data.data.processes,
            dynamicFields: data.data.dynamicFields,
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            indicatorFrecuency: [],
            indicatorGoal: [],
            indicatorSources: [],
            indicatorType: [],
            indicatorUnitMeasure: [],
            responsibles: [],
            indicatorStatus: [],
            processes: [],
            dynamicFields: [],
          });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            indicatorFrecuency: [],
            indicatorGoal: [],
            indicatorSources: [],
            indicatorType: [],
            indicatorUnitMeasure: [],
            responsibles: [],
            indicatorStatus: [],
            processes: [],
            dynamicFields: [],
          });
          dispatch(toastAction.warn('Indicador', data.message));
        }
      },
    );
  };
};

const getIndicatorUnitMeasure = () => {
  const setUnitMeasure = ({ dispatch, isLoading, data,  }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Indicators.GET_UNIT_MEASURE,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setUnitMeasure({ dispatch: dispatch, isLoading: true, data: [] });

    IndicatorsService.getIndicatorUnitMeasure().then(
      ({ data }) => {
        if (data.Status === CoreConstants.HttpResponse.OK)
          setUnitMeasure({
            dispatch: dispatch,
            isLoading: false,
            data: data.Data.ApplicationSettingDetails,
          });
        else if (data.Status < CoreConstants.HttpResponse.OK)
          setUnitMeasure({
            dispatch: dispatch,
            isLoading: false,
            data: [],
          });
        else if (data.Status > CoreConstants.HttpResponse.OK) {
          setUnitMeasure({
            dispatch: dispatch,
            isLoading: false,
            data: [],
          });
          dispatch(toastAction.warn('Indicador', data.message));
        }
      },
    );
  };
};

const getIndicatorByVersion = (indicatorId, versionNumber) => {
  const setIndicator = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Indicators.GET_INDICATOR_BY_VERSION,
      payload: { isLoading, data },
    });
  };
  const setVersions = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Indicators.GET_VERSIONS,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setIndicator({ dispatch: dispatch, isLoading: true, data: null });
    setVersions({ dispatch: dispatch, isLoading: true, data: [] });
    IndicatorsService.getIndicatorByVersion(indicatorId, versionNumber).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setIndicator({
            dispatch: dispatch,
            isLoading: false,
            data: data.data.indicator,
          });
          setVersions({
            dispatch: dispatch,
            isLoading: false,
            data: data.data.versions,
          });
        } else if (data.status > CoreConstants.HttpResponse.OK) {
          setIndicator({ dispatch: dispatch, isLoading: false, data: null });
          setVersions({ dispatch: dispatch, isLoading: false, data: [] });
          dispatch(toastAction.warn('Indicador', data.message));
        }
      }
    );
  };
};

const saveAndGenerateVersion = (indicator) => {
  const isLoading = ({ dispatch, isSaving, isSaved }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Indicators.SAVE_AND_GENERATE_VERSION,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch) => {
    isLoading({ dispatch: dispatch, isSaving: true, isSaved: false });
    IndicatorsService.saveAndGenerateVersion(indicator).then(
      ( {data} ) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Indicador', 'El versión se guardo correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true });
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL)
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        else
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    );
  };
};

const downloadTemplate = () => {

  const setTemplate = ({ dispatch, isLoading }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Indicators.DOWNLOAD_TEMPLATE,
      payload: {
        isLoading
      },
    });
  };

  return (dispatch) => {
    setTemplate({ dispatch, isLoading: true })
    IndicatorsService.downloadTemplate().then(({data}) =>{
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      });
      FileSaver.saveAs(blob, 'Plantilla Indicador.xlsx');
     setTemplate({ dispatch, isLoading: false })
      return;
    }).catch((err) =>{
      setTemplate({ dispatch, isLoading: false })
    });
  }
}

const copyIndicator = (indicatorId) => {
  const setIndicator = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Indicators.COPY_INDICATOR,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setIndicator({ dispatch: dispatch, isLoading: true, data: null });
    IndicatorsService.copyIndicator(indicatorId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          const indicator = data.data.result;
          indicator.dynamicFieldValues = data.data?.dynamicData

          setIndicator({
            dispatch: dispatch,
            isLoading: false,
            data: indicator,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setIndicator({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Indicador', data.message));
        }else{
          setIndicator({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Indicador', data.message));
        }
      }
    ).catch((error)=>{
    });
  };
};

const uploadBatchInfo = (file) => {

  const setUpload = (isLoading, data, errors) =>{
    return (dispatch) =>
      dispatch({
        type: IndicatorsConstants.Accion.Indicators.UPLOAD_BATCH_INFO,
        payload: isLoading, data, errors
      });
  }

  return (dispatch) => {
    dispatch(setUpload({ isLoading: true, data: null, errors: [] }));
    IndicatorsService.uploadBatchInfo(file)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setUpload({ 
            isLoading: false, 
            data: data.data.pageIndicators,
            errors: data.data.errors,
          }));
          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn('Empleados', data.message));
          dispatch(setUpload({ isLoading: false, data: null, errors: [] }));
        }else{
        dispatch(setUpload({ isLoading: false, data: null, errors: [] }));
        }
      })
      .catch((error)=>{
        dispatch(setUpload({ isLoading: false, data: null, errors: [] }));
      });
  };
}

// const saveIndicatorBulk = (data, lastRow) => {

//   const setSave = (isSaved) =>{
//     return (dispatch) =>
//       dispatch({
//         type: IndicatorsConstants.Accion.Indicators.SAVE_INDICATOR_BULK,
//         payload: isSaved
//       });
//   }

//   return (dispatch) => {
//     if (lastRow) dispatch(setSave({ isSaved: false }));
//     IndicatorsService.saveEmployee(data)
//       .then(({ data }) => {
//         if (data.status === CoreConstants.HttpResponse.OK) {
//           if (lastRow) {
//             dispatch(setSave({ isSaved: true }));
//           } 
//           return;
//         } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
//           dispatch(toastAction.success('Indicador', data.message));
//           dispatch(setSave({ isSaved: false }));
//         }else{
//         dispatch(setSave({ isSaved: false }));
//         }
//       })
//       .catch((error)=>{
//         dispatch(setSave({ isSaved: false }));
//       });
//   };
// }

const saveIndicatorValues = (indicator, lastRow) => {
  const isLoading = ({ dispatch, isSaving, isSaved }) => {
    dispatch({
      type: IndicatorsConstants.Accion.Indicators.SAVE_INDICATOR_VALUES,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch) => {
    if (lastRow) isLoading({ dispatch: dispatch, isSaving: true, isSaved: false });
    IndicatorsService.saveIndicatorValues(indicator).then(
      ( {data} ) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          if (lastRow) {
            // dispatch(toastAction.success('Indicador', 'El indicador se guardo correctamente'));
            isLoading({ dispatch: dispatch, isSaving: false, isSaved: true });
          }
        } else if (data.status >= CoreConstants.HttpResponse.ERROR_FUNTIONAL)
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        else
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    );
  };
};


export const IndicatorsAction = {
  toggleAdvancedFilter,
  updateFilter,
  resetAfterSaved,
  
  search,
  getIndicatorById,
  generateReport,
  saveIndicator,
  getDataMaster,
  getIndicatorUnitMeasure,
  getIndicatorByVersion,
  saveAndGenerateVersion,

  downloadTemplate,
  copyIndicator,
  uploadBatchInfo,

  deleteIndicator,
  // saveIndicatorBulk,
  saveIndicatorValues
}