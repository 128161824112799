import { Fragment, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import PinerComponent from 'shared/components/Piner/PinerComponent';
import { Panel } from 'primereact/panel';

import useFilter from 'features/subscription/hooks/modulesIso/useFilter';
import useModulesIso from 'features/subscription/hooks/modulesIso/useModulesIso'


const ModulesIsoFilterComponent = () => {

  const [payLoad, setpayLoad] = useState({name:""});

  const {
    loading,
    showPiners,
    removePiner,
    fieldName,
    filterModules,
    handleChange,
    valuesModule,
    generateReport
  } = useFilter();

  const {
    loadingDataMaster    
  } = useModulesIso()

  const removeName = (field) =>{
    setpayLoad({name:""})
    removePiner(field)
  }

  const submit = (e) => {    
    e.preventDefault();
    filterModules(payLoad)
  }

  return (
    <Fragment>
      <Panel header="FILTRO POR" toggleable>
        <form className="filter-components" onSubmit={submit}>
          <div>
            <span className="p-float-label p-input-icon-left w-full">
              <i className="pi pi-search" />              
              <InputText
                id="input-search"
                name="name"
                value={payLoad.name}
                onChange={e => setpayLoad({name:e.target.value})}
              />
              <label htmlFor="input-search">
                Buscar por nombre de modulo ISO
              </label>
            </span>
            {showPiners() && (
              <div className="filter-piners mt-3">
                {valuesModule.map(({ value, field }, index) => (
                  <PinerComponent
                    name={value}
                    field={field}
                    removePiner={e => removeName(field)}
                    key={index}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="filter-users-action">
            <div className="flex gap-3 w-full">
              <Button
                icon="pi pi-search"
                type="submit"
                label={loadingDataMaster || loading ? "Buscando" : "Buscar"}
                disabled={loadingDataMaster || loading}
                className="sig-button sig-primary pr-2 mr-"
              />
              <Button
                icon="pi pi-download"
                type="button"
                onClick={generateReport}
                loading={loadingDataMaster || loading}
                label="Descargar"
                className="sig-button sig-success"
              />
            </div>            
          </div>
        </form>
      </Panel>

    </Fragment>
  );
};

export default ModulesIsoFilterComponent;