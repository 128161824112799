import { InputTextarea } from 'primereact/inputtextarea'
import React, { useState } from 'react'

const QuestionText = () => {

  const [value, setValue] = useState('')
  
  return (
    <div className='mt-2'>
       {/* <InputTextarea value={value} onChange={(e) => setValue(e.target.value)} rows={5} cols={30} /> */}
    </div>
  )
}

export default QuestionText