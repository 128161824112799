import { MaintenanceConstants } from "features/maintenance/commons/MaintenanceConstans"; 
import { CoreConstants } from 'core/commons/CoreConstants';
import moment from 'moment';
import { toastAction } from "core/store/actions/ToastAction";
import FileSaver from 'file-saver';
import { BusinessLinesService } from "features/maintenance/services/BusinessLinesServices";


const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: MaintenanceConstants.Accion.BusinessLine.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};
const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: MaintenanceConstants.Accion.BusinessLine.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.name)
      pines.push({ field: 'name', value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });


    advancedFilter.values = pines;
    dispatch({
      type: MaintenanceConstants.Accion.BusinessLine.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setBusinessLines = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.BusinessLine.SEARCH_BUSINESS_LINES,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const businessLines = getState().businessLineReducer.businessLines;
    setBusinessLines({
      dispatch: dispatch,
      isLoading: true,
      data: businessLines.data,
    });

    const criteria = {};
    const advancedFilter = getState().businessLineReducer.advancedFilter;
    const filter = getState().businessLineReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;
    
    
    BusinessLinesService.searchBusinessLines(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setBusinessLines({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });          
        else if (data.status < CoreConstants.HttpResponse.OK)
        setBusinessLines({ dispatch: dispatch, isLoading: false, data: null });
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setBusinessLines({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Lineas de negocio', data.message));
        }else{
          setBusinessLines({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Lineas de negocio', data.message));
        }
      },
      () => {
        setBusinessLines({ dispatch: dispatch, isLoading: false, data: null });
      }
    ).catch((error)=>{
    });
  };
};

const getBusinessLineById = (businessLineId) => {
  const setBusinessLine = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.BusinessLine.GET_BUSINESS_LINE_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setBusinessLine({ dispatch: dispatch, isLoading: true });
    BusinessLinesService.getBusinessLineById(businessLineId).then(
      ({ data }) => {
       
        if (data.status === CoreConstants.HttpResponse.OK) {
          setBusinessLine({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setBusinessLine({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Lineas de negocios', data.message));
          // dispatch({
          //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
          //   toast: {
          //     titulo: 'Departamento',
          //     mensaje: data.message,
          //     severidad: 'warn',
          //   },
          // });
        }else{
          setBusinessLine({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.error('Lineas de negocio', data.message));
        }
      },
      () => {
        setBusinessLine({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};

const saveBusinessLine = (businessLine) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentBusinessLine }) => {
    dispatch({
      type: MaintenanceConstants.Accion.BusinessLine.SAVE_BUSINESS_LINE,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    const currentBusinessLine = getState().businessLineReducer.businessLine;
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
      //currentBusinessLine: currentBusinessLine,
    });
    BusinessLinesService.saveBusinessLines(businessLine).then(
      ({ data }) => {      
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Línea de negocio', 'La línea de negocio se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentBusinessLine, });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentBusinessLine });
          dispatch(toastAction.warn('Lineas de negocio', data.message));
        }else{
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
          dispatch(toastAction.error('Lineas de negocio', data.message));
        }

      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};



const deleteBusinessLine = (businessLineId) => {

  return (dispatch, getState) => {

    BusinessLinesService.deleteBusinessLine(businessLineId).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Línea de negocio', 'La linea de negocio se eliminó correctamente'));
          dispatch({ type: MaintenanceConstants.Accion.BusinessLine.DELETE_BUSINESS_LINE, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Línea de negocio', data.message));
          dispatch({ type: MaintenanceConstants.Accion.BusinessLine.DELETE_BUSINESS_LINE, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: MaintenanceConstants.Accion.BusinessLine.DELETE_BUSINESS_LINE, payload: { isDeleted: false } });
      }
    );
  };
};

const generateReport = (payload) => {
  const setBusinessLines = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.BusinessLine.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setBusinessLines({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().businessLineReducer.advancedFilter.filter;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    BusinessLinesService.generateReport(criteria)
      .then(({ data }) => {
        setBusinessLines({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Linea_negocio.xls');
      })
      .catch((err) => {
        setBusinessLines({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Línea de negocio', 'Hubo un error al descargar el archivo'));
      });
  };
};

export const BusinessLineAction = {
  toggleAdvancedFilter,
  updateFilter,
  search,
  saveBusinessLine,
  deleteBusinessLine,
  generateReport,
  resetAfterSaved,
  getBusinessLineById,
}