import { Fragment } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import AdvancedFilterComponent from './AdvancedFilterComponent';
import useFilter from 'features/configuration/hooks/users/useFilter';
import useAdvancedFilter from 'features/configuration/hooks/users/useAdvancedFilter';
import PinerComponent from 'shared/components/Piner/PinerComponent';
import { useClaimUsers } from 'features/configuration/hooks/useClaimUsers';

const UsersFilterComponent = () => {
  const {
    toggleModal,
    usersLoading,
    removePiner,
    formValues,
    handleInputChange,
    usersSearch,
  } = useFilter();
  const { generateReport, reportLoading, showPiners, piners } =
    useAdvancedFilter();

  const { isSearch, isDownload } = useClaimUsers();

  const hasValues = formValues.fullName;

  return (
    <Fragment>
      <Panel header={`${isSearch?"FILTRO POR":"DESCARGA"}`} toggleable>
        <form className="filter-users" onSubmit={usersSearch}>
          <div className={`w-full ${isSearch?'visible':'invisible'}`} >
            <span className="p-float-label p-input-icon-left w-full">
              <i className="pi pi-search" />
              <InputText
                name="fullName"
                id="input-search"
                value={hasValues ? formValues.fullName : ''}
                onChange={handleInputChange}
              />
              <label htmlFor="input-search">Buscar por usuario o nombres</label>
            </span>
            {showPiners && (
              <div className="filter-piners mt-3">
                {piners.map(({ value, field }, index) => (
                  <PinerComponent
                    name={value}
                    field={field}
                    removePiner={removePiner}
                    key={index}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="filter-users-action">
            <div className="flex gap-3 w-full">
              {
                isSearch&&(
                  <Button
                    icon="pi pi-search"
                    type="submit"
                    label="Buscar"
                    loading={usersLoading || reportLoading}
                    className="sig-button sig-primary w-full"
                  />
                )
              }
              {
                isDownload&&(
                  <Button
                    icon="pi pi-download"
                    type="button"
                    label="Descargar"
                    onClick={generateReport}
                    loading={reportLoading}
                    className="sig-button sig-success w-full"
                  />
                )
              }
            </div>
            <div className="flex mt-3">
              {
                isSearch&&(
                  <Button
                    type="button"
                    label="Búsqueda avanzada"
                    className="sig-button  sig-secondary w-full"
                    onClick={() => toggleModal(true)}
                  />
                )
              }
            </div>
          </div>
        </form>
      </Panel>

      <AdvancedFilterComponent />
    </Fragment>
  );
};

export default UsersFilterComponent;
