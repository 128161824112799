export class BusinessSheetAnswerConstants {
  static Action = class {
    static BusinessSheetAnswer = class {
      static get SEARCH() {
        return '@BUSINESS-SHEET-ANSWER.SEARCH';
      }
      static get DATA_MASTER() {
        return '@BUSINESS-SHEET-ANSWER.DATA-MASTER';
      }
      static get EDIT() {
        return '@BUSINESS-SHEET-ANSWER.EDIT';
      }
      static get DOWNLOAD_STATUS() {
        return '@BUSINESS-SHEET-ANSWER.DOWNLOAD-STATUS';
      }
      static get LIST_CONTROLS(){
        return '@BUSINESS-SHEET-ANSWER.LIST-CONTROLS';
      }
      static get SAVE(){
        return '@BUSINESS-SHEET-ANSWER.SAVE';
      }
      static get SHARE(){
        return '@BUSINESS-SHEET-ANSWER.SHARE';
      }
    };
  };

  static Status = class {
    static get DRAFT() {
      return "BSNS_SHT_DRFT";
    }

    static get PUBLISHED() {
      return "BSNS_SHT_PUBL";
    }

    static get CLOSED() {
      return "BSNS_SHT_CLSD";
    }
  }

  static FieldName = class {
    static Filter = class {
      static get CUSTOMER_NAME(){
        return 'customerName'
      }
      static get FROM_DATE(){
        return 'fromDate'
      }
      static get TO_DATE(){
        return 'toDate'
      }
    }
  }
}