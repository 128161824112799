import { useEffect , useState } from 'react'
import { useDispatch , useSelector } from 'react-redux'
import { v4 as uuidv4 } from "uuid";
import { useParams } from 'react-router-dom';
import { SubscriptionsAction } from 'features/subscription/store/actions/SubscriptionsAction';
const useSubscriptions = (title,loadingInfo) =>{
  const dispatch = useDispatch()
  const initialState = {
    "id": null,
    "customerId": null,
    "userQuantity": null,
    "locationQuantity": null,
    "companyQuantity": null,
    "isoIds": [],
    "companiesForCreate": [],
    "companiesForDelete": [],
    "countryId": null
  }
  const { loading , data   } = useSelector((state) => state.subscriptionsReducer.subscriptions);
  const { loadingEdit, EmpresaSelect , customers , isSave , dataSubscription, users } = useSelector((state) => state.subscriptionsReducer.subscriptionEdit);
  const [companies,setCompanies] = useState([]);
  const [companiesDelete,setCompaniesDelete] = useState([])
  const [isos , setIsos] = useState({})
  const { dataManager , loadingDataManager  } = useSelector((state) => state.subscriptionsReducer.dataManager);
  const [payload,setPayload] = useState(initialState)
  const params = useParams();


  const deleteCustomerId = (id) => {
    dispatch(SubscriptionsAction.deleteCustomerId(id));
  }

  const generateReport = () =>{
    dispatch(SubscriptionsAction.generateReport())
  }

  const createCompanie = () => {
    setCompanies((prev) => [
      ...prev,
      {
        name: "",
        id: uuidv4(),
      },
    ]);
  };

  const dataEmpresa = (id) =>{
    let found = customers.filter(p => p.id === id)[0];
    dispatch(SubscriptionsAction.setSubscriptionsEdit({EmpresaSelect:found}))
  } 

  const deleteEmpresa = (id) =>{
    if(title === "Editado"){
      if(typeof id === "number") 
        setCompaniesDelete([...companiesDelete,id])
    }
    setCompanies(companies.filter(p => p.id !== id))
  }

  const updateFila = ({ id, name, value }) => {
    const companieslist = companies.map((companie) => {
      if (companie.id === id) companie.name = value;
      return companie;
    })
    setCompanies(companieslist);
  }

  const getIsos = () =>{
    let isos_new = []
    Object.entries(isos).forEach(([key,value]) => {
      isos_new.push(value.id)
    })
    return isos_new;
  }

  const getCompanies = () =>{
    let companies_new = [];
    companies.forEach( companie => {
      companies_new.push(companie.name);
    })
    return companies_new
  }

  const saveSubscription = (data,minCompanies) =>{
    const isosIds_new = getIsos() 
    let companies_new = getCompanies();
    Object.entries(data).forEach(([key,value]) =>{
      if(key === "companyQuantity" || 
         key === "locationQuantity" || 
         key === "userQuantity" ||
         key === "maxFileSizeAllowed" ||
         key === "maxStorageSizeAllowed")
        data[key] = parseInt(value)
    })
    if(title === "Editado"){
      if(minCompanies >= companies_new.length){
        companies_new = []
      }else{
        companies_new = companies_new.splice(minCompanies)
      }
    }
    let payload = {...data,isoIds:isosIds_new,companiesForCreate: companies_new ,companiesForDelete:companiesDelete}
    dispatch(SubscriptionsAction.saveSubscription(payload))
  }

  const clearSave = () =>{
    dispatch(SubscriptionsAction.setSubscriptionsEdit({isSave:false}))
  }

  const changeStatus = (data,type) =>{
    dispatch(SubscriptionsAction.changeStatus(data,type))
  }

  const renewSubscription = (data) =>{
    dispatch(SubscriptionsAction.renewSubscription(data))
  }

  useEffect(() =>{
    if(title === "Nuevo"){
      dispatch(SubscriptionsAction.setSubscriptionsEdit({EmpresaSelect:{}}))
      dispatch(SubscriptionsAction.getInfoSubscription(null));
    }else if(title === "Editado"){
      if(loadingInfo)
        dispatch(SubscriptionsAction.getInfoSubscription(params.id));
    }
    if(loadingInfo)
      dispatch(SubscriptionsAction.getDataMaster())
  },[])
  return {
    changeStatus,
    renewSubscription,
    clearSave,
    saveSubscription,
    updateFila,
    createCompanie,
    dataEmpresa,
    loading,
    data,
    deleteCustomerId,
    generateReport,
    payload,
    setPayload,
    loadingEdit,
    EmpresaSelect,
    companies,
    setCompanies,
    customers,
    deleteEmpresa,
    dataManager,
    loadingDataManager,
    dataSubscription,
    isos,
    setIsos,
    isSave,
    users
  }
}

export default useSubscriptions