import { useSelector, useDispatch } from 'react-redux';
import ModalFilterComponent from 'shared/components/modal-filter/ModalFilterComponent';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { DocumentActions } from 'features/processManagement/store/actions/DocumentActions';

const AdvancedFilterComponent = () => {


    const dispatch = useDispatch();
    const [formValues, setValues] = useState({
        from: null,
        to: null,
        users: null,
        itemType: null,
        formatFile: null,
        fileStatus: null,
        fileName: null,
    });

    const advancedFilter = useSelector((state) => state.DocumentsReducer.advancedFilter);
    const { showModal } = useSelector((state) => state.DocumentsReducer.advancedFilter);
    const dataMaster = useSelector((state) => state.DocumentsReducer.dataMaster);

    useEffect(() => {
        setValues({
            from: advancedFilter.filter.from,
            to: advancedFilter.filter.to,
            users: advancedFilter.filter.users,
            itemType: advancedFilter.filter.itemType,
            formatFile: advancedFilter.filter.formatFile,
            // fileStatus: advancedFilter.filter.fileStatus
        });
        dispatch(DocumentActions.getDataMaster());
    }, [advancedFilter]);

    useEffect(() => {
        dispatch(DocumentActions.getDataMaster());
    },[])


    const clearFields = () => {
        setValues({
            from: null,
            to: null,
            users: null,
            itemType: null,
            formatFile: null,
            fileStatus: null,
            fileName: null,
        });
    };
    const closeModal = () => {
        clearFields()
        dispatch(DocumentActions.toggleAdvancedFilter({ showModal: false }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const fields = formValues;

        if (fields?.from)
            advancedFilter.filter.from = fields.from;
        else
            advancedFilter.filter.from = null;


        if (fields?.to)
            advancedFilter.filter.to = fields.to;
        else
            advancedFilter.filter.to = null;


        if (fields?.users)
            advancedFilter.filter.users = fields.users;
        else 
            advancedFilter.filter.users = null;


        if (fields?.itemType)
            advancedFilter.filter.itemType = fields.itemType;
        else
            advancedFilter.filter.itemType = null;


        if (fields?.formatFile)
            advancedFilter.filter.formatFile = fields.formatFile;
        else
            advancedFilter.filter.formatFile = null;

        if (fields?.fileStatus)
            advancedFilter.filter.fileStatus = fields.fileStatus;
        else
            advancedFilter.filter.fileStatus = null;

        if (fields?.fileName)
            advancedFilter.filter.fileName = fields.fileName;
        else
            advancedFilter.filter.fileName = null;

        dispatch(DocumentActions.updateFilter(advancedFilter));
        dispatch(DocumentActions.search());
        dispatch(DocumentActions.toggleAdvancedFilter({ showModal: false }));
    }

    const onChangeFrom = (e) => {
        if (e.value)
            formValues.to = null;
        setValues({
            ...formValues,
            [e.target.name]: e.value,
        });
    }

    const onChangeTo = (e) => {
        setValues({
            ...formValues,
            [e.target.name]: e.value,
        });
    }

    const onChangeUsers = (e) => {
        setValues({
            ...formValues,
            users: e.value,
        });
    }

    const onChangeItemType = (e) => {
        setValues({
            ...formValues,
            itemType: e.value,
        });
    }

    const onChangeFormatFile = (e) => {
        setValues({
            ...formValues,
            formatFile: e.value,
        });
    }

    const onChangeFileStatus = (e) => {
        setValues({
            ...formValues,
            fileStatus: e.value,
        });
    }

    const onChangeFileName = (e) => {
        setValues({
            ...formValues,
            fileName: e.target.value,
        });
    }
    
    if (showModal)
        return (
            <ModalFilterComponent
                disabledBtn={false}
                closeModal={closeModal}
                clearFields={clearFields}
                handleSubmit={handleSubmit}
                searchLoading={advancedFilter?.loading}
            >

                <div className="flex gap-4 pt-3">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                value={formValues.from}
                                name="from"
                                id="desde"
                                onChange={(e) => onChangeFrom(e)}
                            />
                            <label htmlFor="desde">Desde</label>
                        </span>
                    </div>
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                value={formValues.to}
                                name="to"
                                id="hasta"
                                minDate={formValues?.from}
                                onChange={(e) => onChangeTo(e)}
                            />
                            <label htmlFor="hasta">Hasta</label>
                        </span>
                    </div>

                </div>

                <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            <InputText
                                type="text"
                                id='fileName'
                                name="fileName"
                                onChange={(e) => { onChangeFileName(e) }}
                                value={formValues?.fileName}
                                maxLength={100}
                            />
                            <label htmlFor="code">Nombre del archivo</label>
                        </span>
                    </div>
                </div>
        
                <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Dropdown
                                options={dataMaster?.users}
                                optionLabel="fullName"
                                value={formValues?.users}
                                onChange={(e) => { onChangeUsers(e) }}
                                filter
                                showClear
                                filterBy="fullName"
                                className="w-full"
                            />

                            <label htmlFor="dropdown">Por Responsable</label>
                        </span>
                    </div>
                </div>

                <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Dropdown
                                options={dataMaster?.itemType}
                                optionLabel="description"
                                value={formValues?.itemType}
                                onChange={(e) => { onChangeItemType(e) }}
                                filter
                                showClear
                                filterBy="description"
                                className="w-full"
                            />

                            <label htmlFor="dropdown">Por Tipo</label>
                        </span>
                    </div>
                </div>

                <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Dropdown
                                options={dataMaster?.formatFile}
                                optionLabel="description"
                                value={formValues?.formatFile}
                                onChange={(e) => { onChangeFormatFile(e) }}
                                filter
                                showClear
                                filterBy="description"
                                className="w-full"
                            />

                            <label htmlFor="dropdown">Por Formato</label>
                        </span>
                    </div>
                </div>

                {/* <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Dropdown
                                options={dataMaster?.fileStatus}
                                optionLabel="description"
                                value={formValues?.fileStatus}
                                onChange={(e) => { onChangeFileStatus(e) }}
                                filter
                                showClear
                                filterBy="description"
                                className="w-full"
                            />

                            <label htmlFor="dropdown">Por Estado</label>
                        </span>
                    </div>
                </div> */}

            </ModalFilterComponent>
        );
    else
        return null;
}

export default AdvancedFilterComponent;