import { useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";

import useAdvancedFilter from "features/configuration/hooks/users/useAdvancedFilter";
import useFilter from "features/configuration/hooks/users/useFilter";
import ModalFilterComponent from "shared/components/modal-filter/ModalFilterComponent";

const AdvancedFilterComponent = () => {
  const {
    formValues,
    handleChange,
    advancedfilter,
    companies,
    locations,
    roles,
    status,
    clearFields,
  } = useAdvancedFilter();
  const { toggleModal } = useFilter();

  const styleLabel = {
    color: "#004680",
    fontWeight: 600,
    marginBottom: "5px",
  };

  const isModalOpen = useSelector(
    (state) => state.userReducer.userFilter.showModal
  );
  if (isModalOpen) {
    return (
      <ModalFilterComponent
        closeModal={() => toggleModal(false)}
        handleSubmit={advancedfilter}
        clearFields={clearFields}
      >
        <div className="body">
          <div className="flex gap-4 pt-3">
            <div className="w-full">
              <span className="p-float-label">
                <Calendar
                  onChange={handleChange}
                  value={formValues?.fromDate}
                  name="fromDate"
                  id="fromDate"
                />
                <label htmlFor="desde">Desde</label>
              </span>
            </div>
            <div className="w-full">
              <span className="p-float-label">
                <Calendar
                  onChange={handleChange}
                  value={formValues?.toDate}
                  name="toDate"
                  id="toDate"
                />
                <label htmlFor="hasta">Hasta</label>
              </span>
            </div>
          </div>

          <div className="flex pt-7">
            <div className="w-full">
              <span className="p-float-label">
                <Dropdown
                  onChange={handleChange}
                  options={companies}
                  value={formValues?.companyId}
                  name="companyId"
                  optionLabel="businessName"
                  filter
                  showClear
                  filterBy="businessName"
                  className="w-full"
                />

                <label htmlFor="dropdown">Por Empresa</label>
              </span>
            </div>
          </div>
          <div className="flex pt-7">
            <div className="w-full">
              <span className="p-float-label">
                <Dropdown
                  name="locationId"
                  value={formValues?.locationId}
                  onChange={handleChange}
                  options={locations}
                  optionLabel="name"
                  filter
                  showClear
                  filterBy="name"
                  className="w-full"
                />

                <label htmlFor="dropdown">Por Sede</label>
              </span>
            </div>
          </div>
          <div className="flex pt-7">
            <div className="w-full">
              <span className="p-float-label">
                <Dropdown
                  onChange={handleChange}
                  options={roles}
                  value={formValues?.roleId}
                  name="roleId"
                  optionLabel="description"
                  filter
                  showClear
                  filterBy="description"
                  className="w-full"
                />

                <label htmlFor="dropdown">Por Rol</label>
              </span>
            </div>
          </div>
          <div className="flex mt-3">
            <div className="w-full">
              <h3 style={styleLabel}>Por Estado</h3>
              <div className="flex gap-3">
                {status?.map(({ description, id }, index) => (
                  <div className="flex gap-3" key={index}>
                    <Checkbox
                      name="statusId"
                      onChange={handleChange}
                      value={id}
                      checked={formValues?.statusId === id}
                    ></Checkbox>
                    <p>{description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </ModalFilterComponent>
    );
  }

  return null;
};

export default AdvancedFilterComponent;
