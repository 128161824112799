import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Menu } from "primereact/menu";
import { Paginator } from "primereact/paginator";
import useTable from "features/configuration/hooks/users/useTable";
import { useClaimUsers } from "features/configuration/hooks/useClaimUsers";
import { Link } from "react-router-dom";
import moment from "moment";
import { CoreConstants } from "core/commons/CoreConstants";

const TableComponent = () => {
  const {
    selectColumns,
    tableUsers,
    tableCurrentActions,
    tableActions,
    showActions,
    setCurrentUser,
    onChangePage,
    tableNum,
    tableRows,
    tablePagination,
    onSort,
    tableSort,
    tableLoading,
  } = useTable();

  const { isView ,isEdit, isDelete, isRestorePassword } = useClaimUsers();

  const getViewField = (rowData, col) => {

    if (isView)
      return <Link className="underline text-blue-700" to={{ pathname: `/configuracion/usuarios/visualizar/` + rowData.userId}} >{rowData.userName}</Link>
    else
      return rowData.userName;
  }

  const ColumnComponents = selectColumns.map((col) => {
    if (col.field === 'userName')
          return <Column key={col.field} header={col.header} body={getViewField} sortable />
    return (
      <Column key={col.field} field={col.field} header={col.header} sortable />
    );
  });

  const TableHeaderActions = () => {
    return <div></div>;
  };

  const TableBodyActions = (selectedRow) => {
    if(isEdit||isDelete||isRestorePassword){
      return (
        <i
          onClick={(event) => {
            setCurrentUser(selectedRow.userId);
            tableCurrentActions.current.toggle(event);
          }}
          className="pi pi-ellipsis-v cursor-pointer"
        ></i>
      );
    }else{
      return <div></div>
    }
  };
  return (
    <div className="mt-5">
      <DataTable
        value={tableUsers}
        emptyMessage="No se encontraron usuarios"
        stripedRows
        rows={10}
        rowHover
        size="small"
        responsiveLayout="scroll"
        onSort={onSort}
        first={tableSort?.first}
        sortField={tableSort?.sortField}
        sortOrder={tableSort?.sortOrder}
        filters={tableSort?.filters}
      >
        {ColumnComponents}
        {showActions && (
          <Column header={TableHeaderActions} body={TableBodyActions}></Column>
        )}
      </DataTable>

      {!tableLoading && tableUsers.length > 0 && (
        <Paginator
          className="paginator-custom mt-4"
          first={tableNum}
          rows={tableRows}
          totalRecords={tablePagination?.rowCount}
          rowsPerPageOptions={[5, 10, 15]}
          onPageChange={onChangePage}
        ></Paginator>
      )}

      <Menu
        model={tableActions}
        popup
        ref={tableCurrentActions}
        id="popup_menu"
      />
    </div>
  );
};

export default TableComponent;
