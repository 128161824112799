import { CoreConstants } from "core/commons/CoreConstants"
import moment from "moment"
import { Editor } from "primereact/editor"
import { InputText } from "primereact/inputtext"
import { Fragment, useEffect, useState } from "react"
import { ProcessManagementServices } from "../services/ProcessManagementServices"

import "./ProcessDownload.scss"

export const ProcessDownload = ({data, dataMaster, version, children, id, approvers}) => {
	const [url, setUrl] = useState("")
	const [versions, setVersions] = useState([])

	useEffect(()=>{
		ProcessManagementServices.getLogo64().then(({data})=>{
			const url = `data:${data.Data}`
			setUrl(url)
		})
	},[])

	useEffect(()=>{
		ProcessManagementServices.getVersions(id).then(({data})=>{
			setVersions(data.data)
		})
	},[])

	return (
		<div>
			<div className="grid grid-cols-3 mb-4">
					<div className="border-dark flex ">
							<img
								className="h-3/4 m-auto"
								src={url}
								alt="Calidar"
								title="Calidar"
							/>
					</div>
					<div className="border-dark border-l-0 border-r-0 flex">
						<div className="m-auto text-sm">{data?.process?.name}</div>
					</div>
					<div className="flex flex-col justify-around">
						<div className="border-dark border-b-0 flex place-content-center" style={{height: "-webkit-fill-available"}}>
							<div className="m-auto pb-1 text-sm">
								CÓDIGO: {data?.process?.code} 
							</div>
						</div>
						<div className="border-dark border-b-0 flex place-content-center" style={{height: "-webkit-fill-available"}}>
							<div className="m-auto pb-1 text-sm">
								VERSIÓN: {version}
							</div>
						</div>
						<div className="border-dark flex place-content-center" style={{height: "-webkit-fill-available"}}>
							<div className="m-auto pb-1 text-sm">
								FECHA: {moment(data.process?.processDate).format(CoreConstants.Format.LOCALE)}
							</div>
						</div>
					</div>
			</div>

			<div className="grid grid-cols-12 ">
				<div className="border-dark col-span-3 border-r-0 border-b-0 text-white flex header-primary pb-4 pl-2 text-sm">Objetivo:</div>
				<Editor
					style={{ width: '100%' }}
					id="editor-download"
					className="border-dark border-b-0 flex w-full pl-2 col-span-9 text-sm"
					headerTemplate={<></>}
					value={data?.process?.description}
				/>
			</div>
			<div className="grid grid-cols-12">
				<div className="border-dark border-r-0 border-b-0 text-white flex header-primary pb-4 col-span-3 pl-2 text-sm">Alcance:</div>
				<Editor
					style={{ width: '100%' }}
					id="editor-download"
					className="border-dark border-b-0 flex w-full  pl-2 col-span-9 text-sm"
					headerTemplate={<></>}
					value={data?.process?.scope}
				/>
			</div>
			<div className="grid grid-cols-12 mb-6">
				<div className="border-dark border-r-0 text-white flex header-primary pb-4  pl-2 col-span-3 text-sm">Responsable:</div>
				<div className="border-dark flex pl-2 col-span-9 text-sm">
					{dataMaster?.data?.responsibles.find(item=>item.id === data?.process?.responsibleId)?.fullName}
				</div>
			</div>
			<div id="ficha-download">
				{children}
			</div>
			<div className="page-break">
				<div className="pb-4 mb-4 text-sm">
					Historial de versiones:
				</div>
				<div className="grid grid-cols-3">
					<div className="border-dark border-r-0 border-b-0 text-white flex header-primary pb-4 pl-2 text-sm">Versión:</div>
					<div className="border-dark text-white border-r-0 border-b-0 flex header-primary pl-2 text-sm">Fecha:</div>
					<div className="border-dark text-white border-b-0 flex header-primary pl-2 text-sm">Motivo:</div>
				</div>
				<div className="grid grid-cols-3 mb-12">
					{
						versions.map((item,key)=>(
						<Fragment key={key}>
						<div className="border-dark flex pb-4 pl-2 text-sm">{item.versionNumber}</div>
						<div className="border-dark border-l-0 border-r-0 flex pl-2 text-sm">{moment(item.date).format(CoreConstants.Format.LOCALE)}</div>
						<div className="border-dark flex pl-2 text-sm">{item.description}</div>
						</Fragment>))
					}
					{/* <div className="border-dark flex pb-4 pl-2 text-sm">{version}</div>
					<div className="border-dark border-l-0 border-r-0 flex pl-2 text-sm">{moment(data.process?.creationDate).format(CoreConstants.Format.LOCALE)}</div>
					<div className="border-dark flex pl-2 text-sm">{data.process?.reason}</div> */}
				</div>
			</div>

			<div className="page-break mt-2">
			<div className="grid grid-cols-3 text-sm">
				<div className="border-dark border-r-0 border-b-0 text-white flex header-primary pb-4 pl-2">Elaboró:</div>
				<div className="border-dark text-white border-r-0 border-b-0 flex header-primary pl-2">Revisó:</div>
				<div className="border-dark text-white border-b-0 flex header-primary pl-2">Aprobó:</div>
				<div className="border-dark border-r-0 pl-2">
					<div>
						Nombre: {dataMaster?.data?.responsibles.find(item=>item.id === data.process?.elaboratedById)?.fullName}
					</div>
					<div>
						Cargo: {}
					</div>
					<div className="pb-4">
						Fecha: { data.process?.creationDate ? moment(data.process?.creationDate).format(CoreConstants.Format.LOCALE) : '' }
					</div>
				</div>
				<div className="border-dark border-r-0 pl-2">
					<div>
						Nombre: {approvers[0]?.name}
					</div>
					<div>
						Cargo: {approvers[0]?.role}
					</div>
					<div>
						Fecha:  { approvers[0]?.date ? moment(approvers[0]?.date).format(CoreConstants.Format.DDMMYYYY) : ''}
					</div>
				</div>
				<div className="border-dark pl-2">
					<div>
						Nombre: {approvers[1]?.name}
					</div>
					<div>
						Cargo: {approvers[1]?.role}
					</div>
					<div>
						Fecha: { approvers[1]?.date ? moment(approvers[1]?.date).format(CoreConstants.Format.DDMMYYYY) : ''}
					</div>
				</div>
			</div>
			</div>
	</div>
	)
}
