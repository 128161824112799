import { usePageSnapshots } from "features/projects/hooks/usePageSnapshots";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { Paginator } from "primereact/paginator";
import { Panel } from "primereact/panel";
import PinerComponent from "shared/components/Piner/PinerComponent";
import ModalFilterComponent from "shared/components/modal-filter/ModalFilterComponent";


export const PageViewSnapshots = () => {
  const { 
    dataTable,
    loading,
    rowCount,
    first,
    showModal,
    piners,
    items,
    handleChange,
    clearFields,
    openModal,
    search,
    onPageChange,
    download,
    columns,
    values,
    removePiner,
    menu,
    closeModal,
    onSort,
    sortOrder,
    deleteSnapshotById,
    pageSize,
    closeDialog,
    showConfirmDialog,
   } = usePageSnapshots()

  return(
    <>
      <Panel header="FILTRO POR" toggleable>
        <form className="filter-snapshots" onSubmit={search}>
          <div className="w-full">
            <span className="p-float-label p-input-icon-left w-full mb-3">
              <i className="pi pi-search" />
              <InputText
                id="input-search"
                name="name"
                value={values.name || ''}
                onChange={handleChange}
              />
              <label htmlFor="input-search">Buscar por nombre del proyecto</label>
            </span>
            {
              <div className="filter-piners">
                {piners.map(({ name, field }, index) => (
                  <PinerComponent
                    name={name}
                    field={field}
                    removePiner={removePiner}
                    key={index}
                  />
                ))}
              </div>
            }
          </div>
          <div className="filter-users-action">
            <div className="flex gap-3 w-full">
              <Button
                icon="pi pi-search"
                type="submit"
                label="Buscar"
                loading={loading}
                className="sig-button sig-primary w-full"
              />
              <Button
                icon="pi pi-download"
                type="button"
                onClick={download}
                loading={loading}
                // disabled={projectLoading || reportLoading}
                label="Descargar"
                className="sig-button sig-success w-full"
              />
            </div>
            <div className="flex mt-3">
              <Button
                type="button"
                label="Búsqueda avanzada"
                loading={loading}
                className="sig-button  sig-secondary w-full"
                onClick={openModal}
              />
            </div>
          </div>
        </form>
      </Panel>
      <div className="mt-6">
        <DataTable
          emptyMessage="No hay planificaciones existentes"
          value={dataTable}
          sortField={sortOrder.columnOrder}
          sortOrder={sortOrder.order === 'asc' ? 1 : -1}
          onSort={onSort}
          size="small"
        >
          {
            columns.map( ({field, header, style = {}, body}) => (
              <Column
                key={field}
                header={header}
                field={field}
                style={style}
                body={body}
                sortable
              />
            ) )
          }
          <Column
            header=""
            field="actions"
            style={{ width: '20px'}}
            body={(rowData) => <i className="pi pi-ellipsis-v cursor-pointer" onClick={(event) => {
              menu.current.rowId = rowData.id
              menu.current.toggle(event)
            }}/>}
          />
        </DataTable>
        <Paginator
          className="paginator-custom"
          first={first}
          rows={pageSize}
          totalRecords={rowCount}
          onPageChange={onPageChange}
          rowsPerPageOptions={[5,10,15]}
        />
      </div>
      {
        showModal && 
        <ModalFilterComponent
          clearFields={clearFields}
          closeModal = {closeModal}
          handleSubmit = {search}
        >
          <div className="flex gap-4 pt-7">
            <div className="w-full">
              <span className="p-float-label">
                <Calendar
                  name="fromDate"
                  value={values.fromDate}
                  onChange={handleChange}
                />
                <label htmlFor="fromDate">Desde</label>
              </span>
            </div>
            <div className="w-full">
              <span className="p-float-label">
                <Calendar
                  name="toDate"
                  value={values.toDate}
                  onChange={handleChange}
                />
                <label htmlFor="toDate">Hasta</label>
              </span>
            </div>
          </div>   
        </ModalFilterComponent>
      }
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <ConfirmDialog
        visible={showConfirmDialog}
        onHide={closeDialog}
        message="¿Está seguro que desea eliminar la planificación?"
        header="Eliminar"
        icon="pi pi-trash" 
        accept={deleteSnapshotById}
      />
    </>
  )
}