import { apiService } from "core/services/ApiService";

const saveCourseMonitoring = (data) => {
  return apiService.Post("customer/api/CourseStudentSessionEvaluation/Save", data);
};

// En uso

const getDataMaster = () => {
  return apiService.Get("customer/api/CourseStudentSessionEvaluation/GetDataMaster");
};

const searchCoursesMonitoring = (criteria) => {
  return apiService.Post("customer/api/CourseStudentSessionEvaluation/Search", criteria);
};

const getCourseMonitoringById = (courseId) => {
  return apiService.Get("customer/api/CourseStudentSessionEvaluation/GetCourseStudentSessionEvaluationById?courseId=" + courseId);
};

const getIsApproved = (course) => {
  return apiService.Post("customer/api/CourseStudentSessionEvaluation/IsApproved", course);
};

const generateReport = (payload) => {
  return apiService.Post("customer/api/CourseStudentSessionEvaluation/GenerateReport", payload, {
    responseType: 'blob',
  })
};


export const CoursesMonitoringService = {
  searchCoursesMonitoring,
  saveCourseMonitoring,
  getCourseMonitoringById,
  getDataMaster,
  generateReport,
  getIsApproved
}