import { TableComponent } from "features/business/components/view/TableComponent";
import { useClaimViewBusiness } from "features/business/hooks/useClaimViewBusiness";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import scrollTop from "shared/utils/scrollTop";
import { FilterComponent } from "../../components/view/FilterComponent";

export const PageView = () => {
  const { updateTitle } = useSetTitlePage();
  useEffect(() => {
    updateTitle({
      title: "Negocios",
      subtitle: "Vista de Negocios",
    });
  }, [updateTitle]);
  const { isSearch } = useClaimViewBusiness();

  useEffect(()=>{
    scrollTop()
  },[])

  return (
    <>
    {
      isSearch&&(
        <FilterComponent />
      )
    }
      <TableComponent />
    </>
  );
};
