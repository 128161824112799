import { apiService } from "core/services/ApiService";

const searchProfessions = (criteria) => {
  return apiService.Post("customer/api/Profession/Search", criteria);
}

const saveProfessions = (data) => {
  return apiService.Post("customer/api/Profession/Save", data);
}

const getProfessionById = (professionId) => {
  return apiService.Get("customer/api/Profession/GetProfessionById?id=" + professionId);
}

const generateReport = (payload) => {
  return apiService.Post("customer/api/Profession/GenerateReport", payload, {
    responseType: 'blob',
  })
}

const deleteProfession = (professionId) => {
  return apiService.Delete("customer/api/Profession/Delete?id=" + professionId);
}

export const ProfessionsService = {
  searchProfessions,
  saveProfessions,
  getProfessionById,
  generateReport,
  deleteProfession,
};