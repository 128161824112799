import { InputText } from 'primereact/inputtext';
import { useForm } from 'react-hook-form';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from 'uuid';

const getItemStyle = (isDragging, draggableStyle) => ({
  // userSelect: "none",
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,
  // background: isDragging ? "lightgreen" : "white",
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? "lightblue" : "white",
  // border: isDraggingOver ? "1px dashed gray" : "none",
  padding: 2,
  // width: '100%'
});

const useSurveyQuestion = (question, isCloseStatus, listSections, setListSections, sectionId) => {

  let defaultValues = {
    text: '',
    type: null
  };

  // console.log(question)

  const {
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const valuesFields = watch();
  const existError = Object.keys(errors).length > 0

  const validateLength = (value) => {
    if (typeof value === 'string') {
      return value.replace(/<[^>]+>/g, '')?.length;
    } else if (value?.htmlValue) {
      return value.htmlValue.replace(/<[^>]+>/g, '').length;
    }
    return '0';
  };

  const setPositionByQuestion = (questionType) => {
    switch (questionType) {
      default:
        // return question.surveyQuestionOptions.length + 1;
        return question.surveyQuestionOptions[question.surveyQuestionOptions.length - 1].position + 1;
    }
  }

  // [{ id, text, type, surveyQuestionOptions, extraOptions }]
  const addOption = (valueOption) => {
    if (valueOption.trim() === '') return

    const newListSectionQuestion = listSections.map(section => {
      if (section.id === sectionId) {
        const newListQuestion = section.listQuestion.map(option => {
          if (option.id === question.id) {
            return {
              ...option,
              surveyQuestionOptions: [
                ...option.surveyQuestionOptions, 
                { caption: valueOption, 
                  position: question.surveyQuestionOptions.length === 0 ? 1 : setPositionByQuestion(valueOption), 
                  id: uuidv4() 
                }
              ]
            }
          }
          return option
        })
        return {
          ...section,
          listQuestion: newListQuestion
        }
      }
      return section
    })

    setListSections(newListSectionQuestion)
    // const newListQuestion = listQuestion.map((option) => {
    //   if (option.id === question.id) {
    //     return {
    //       ...option,
    //       surveyQuestionOptions: [...option.surveyQuestionOptions, { caption: valueOption, position: question.surveyQuestionOptions.length === 0 ? 1 : setPositionByQuestion(valueOption), id: uuidv4() }]
    //     }
    //   }
    //   return option
    // })

    // setListQuestion(newListQuestion)
  }

  const addOptionRanking = (ranking) => {
    if (ranking.type === 'scale') {
      const newListSectionQuestion = listSections.map(section => {
        if (section.id === sectionId) {
          const newListQuestion = section.listQuestion.map(option => {
            if (option.id === question.id) {
              return {
                ...option,
                rankingQuantity: ranking.value
              }
            }
            return option
          })
          return {
            ...section,
            listQuestion: newListQuestion
          }
        }
        return section
      })
  
      setListSections(newListSectionQuestion)
      return
      // const newListQuestion = listQuestion.map((option, index) => {
      //   if (option.id === question.id) {
      //     return {
      //       ...option,
      //       rankingQuantity: ranking.value
      //     }
      //   }
      //   return option
      // })
  
      // setListQuestion(newListQuestion)
    }
    if (ranking.type === 'icon') {
      const newListSectionQuestion = listSections.map(section => {
        if (section.id === sectionId) {
          const newListQuestion = section.listQuestion.map(option => {
            if (option.id === question.id) {
              return {
                ...option,
                icon: ranking.value.icon
              }
            }
            return option
          })
          return {
            ...section,
            listQuestion: newListQuestion
          }
        }
        return section
      })
  
      setListSections(newListSectionQuestion)
      // const newListQuestion = listQuestion.map((option, index) => {
      //   if (option.id === question.id) {
      //     return {
      //       ...option,
      //       icon: ranking.value.icon
      //     }
      //   }
      //   return option
      // })
  
      // setListQuestion(newListQuestion)
    }
  }

  const deleteOption = (index) => {
    if (isCloseStatus) return
    const newQuestionOptions = [...question.surveyQuestionOptions]
    newQuestionOptions.splice(index, 1)

    const newListSectionQuestion = listSections.map(section => {
      if (section.id === sectionId) {
        const newListQuestion = section.listQuestion.map(option => {
          if (option.id === question.id) {
            return {
              ...option,
              surveyQuestionOptions: [...newQuestionOptions]
            }
          }
          return option
        })
        return {
          ...section,
          listQuestion: newListQuestion
        }
      }
      return section
    })

    setListSections(newListSectionQuestion)

    // const newListQuestion = listQuestion.map(option => {
    //   if (option.id === question.id) {
    //     return {
    //       ...option,
    //       surveyQuestionOptions: [...newQuestionOptions]
    //     }
    //   }
    //   return option
    // })
    // setListQuestion(newListQuestion)
  }

  // const deleteOptionExtra = (optionExtra) => {
  //   const copyQuestionOptions = [...question.surveyQuestionOptions]
  //   const newQuestionOptions = copyQuestionOptions.filter(option => option.caption !== optionExtra)

  //   const newListQuestion = listQuestion.map(option => {
  //     if (option.id === question.id) {
  //       return {
  //         ...option,
  //         surveyQuestionOptions: [...newQuestionOptions]
  //       }
  //     }
  //     return option
  //   })

  //   setListQuestion(newListQuestion)
  // }

  const onChangeNameOption = (e, i) => {
    // const newQuestionOptions = [...question.surveyQuestionOptions]
    // newQuestionOptions[i].caption = e.target.value

    // const newListQuestion = listQuestion.map(option => {
    //   if (option.id === question.id) {
    //     return {
    //       ...option,
    //       surveyQuestionOptions: [...newQuestionOptions]
    //     }
    //   }
    //   return option
    // })

    // setListQuestion(newListQuestion)

    const newQuestionOptions = [...question.surveyQuestionOptions]
    newQuestionOptions[i].caption = e.target.value

    const newListSectionQuestion = listSections.map(section => {
      if (section.id === sectionId) {
        const newListQuestion = section.listQuestion.map(option => {
          if (option.id === question.id) {
            return {
              ...option,
              surveyQuestionOptions: [...newQuestionOptions]
            }
          }
          return option
        })
        return {
          ...section,
          listQuestion: newListQuestion
        }
      }
      return section
    })
    
    setListSections(newListSectionQuestion)
  }

  // Reordenar los item Dnd
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    
    const newList = result.map((option, index) => {
      const newOption = {
        ...option,
        position: index + 1
      }
      return newOption
    })
  
    return newList;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    // console.log(result)
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(
      question?.surveyQuestionOptions,
      result.source.index,
      result.destination.index
    );

    // const newListQuestion = listQuestion.map(option => {
    //   if (option.id === question.id) {
    //     return {
    //       ...option,
    //       surveyQuestionOptions: [...items]
    //     }
    //   }
    //   return option
    // })

    // setListQuestion(newListQuestion)

    const newListSectionQuestion = listSections.map(section => {
      if (section.id === sectionId) {
        const newListQuestion = section.listQuestion.map(option => {
          if (option.id === question.id) {
            return {
              ...option,
              surveyQuestionOptions: [...items]
            }
          }
          return option
        })
        return {
          ...section,
          listQuestion: newListQuestion
        }
      }
      return section
    })
    setListSections(newListSectionQuestion)
  }

  const listOptions = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {question?.surveyQuestionOptions.map((option, index) => {
                
                if (option.caption === 'Otra' || option.caption === "Ninguna de las Anteriores") return null

                return(
                  <Draggable key={`${option.id}`} draggableId={`${option.id}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        // {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        className='flex w-full lg:w-3/5 items-center mt-2'
                      >
                        <div className="p-input-icon-right w-full">
                          <i { ...provided.dragHandleProps} className={`pi pi-th-large ${isCloseStatus ? 'invisible' : 'cursor-pointer hover:text-gray-900'} text`} />
                          <InputText
                            value={option.caption} 
                            className='inline rounded-lg border-indigo-400 border-2 p-2' 
                            onChange={(e)=> onChangeNameOption(e, index)}
                            readOnly={isCloseStatus}
                          />
                        </div>
                        <button type='button' disabled={isCloseStatus}>
                          <i className={`pi pi-trash block ml-2 ${isCloseStatus ? '' : 'cursor-pointer hover:text-gray-900'}`} style={{'fontSize': '1.5em'}} onClick={() =>  deleteOption(index) }/>
                        </button>
                      </div>
                    )}
                  </Draggable>
              )
              }
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  return {
    control,
    errors,
    valuesFields,
    existError,
    setValue,
    reset,
    handleSubmit,
    validateLength,
    addOption,
    addOptionRanking,
    listOptions,
    // deleteOptionExtra
  }
}

export default useSurveyQuestion