import { useEffect } from "react";
import { useDispatch } from 'react-redux';
import scrollTop from 'shared/utils/scrollTop';
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { TableComponent } from "features/projects/components/viewProjects/TableComponent"
import { FilterComponent } from "features/projects/components/viewProjects/FilterComponent";
import { ProjectAction } from 'features/customers/store/actions/ProjectAction';
import { useClaimViewProject } from "features/projects/hooks/useClaimViewProject";

export const PageViewProjects = () => {
  const dispatch = useDispatch();
  const { updateTitle } = useSetTitlePage();

  /* TITLE ------------------------- */
  const pageTitle = {
    title: "Gestión de Proyectos",
    subtitle: "Ver Proyectos"
  };

  const {isSearch} = useClaimViewProject()

  useEffect(() => {
    updateTitle(pageTitle);
  }, []);  

  useEffect(() => {
    dispatch(ProjectAction.getDataMaster());
    dispatch(ProjectAction.getProject({ change: true }));
    dispatch(ProjectAction.setCurrentProject({ errors: [] }));

    return ()=>{
      dispatch(ProjectAction.setAdvancedFilter({values: []}));
    }
  }, []);

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <>
    {
      isSearch&&(
        <FilterComponent/>
      )
    }
      <TableComponent/>
    </>
  );
}
