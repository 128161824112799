import { apiService } from "core/services/ApiService"

const isStorageEnabled = () => {
  return apiService.Get("storage/api/Storage/IsStorageEnabled");
}

const searchDocumentLibraries = (criterio) => {
  return apiService.Post("storage/api/Storage/Search", criterio);
}

const generateReport = (payload) => {
  return apiService.Post("storage/api/Storage/GenerateReport", payload, {
    responseType: 'blob',
  })
}

const getDataListFolders = () => {
  return apiService.Get("storage/api/Storage/ListFolders");
}

const getRecentFiles = () => {
  return apiService.Get("storage/api/Storage/RecentFiles");
}

const getCurrentFiles = () => {
  return apiService.Get("storage/api/Storage/CurrentFiles");
}

const getMyFiles = () => {
  return apiService.Get("storage/api/Storage/MyFiles");
}

const getTrashFiles = () => {
  return apiService.Get("storage/api/Storage/TrashFiles");
}

const getDataListFilesAndFolders = (path = '') => {
  return apiService.Get("storage/api/Storage/ListFilesAndFolders?path=" + path);
}

const getDocumentLibraryById = (documentId) => {
  return apiService.Get("storage/api/Storage/GetDocumentStorageById?id=" + documentId);
}

const deleteDocumentLibrary = (documentId, documentName) => {
  return apiService.Delete(`storage/api/Storage/DeleteFile?id=${documentId}&fileName=${documentName}`);
}

const moveFile = (data) => {
  return apiService.Post("storage/api/Storage/MoveFile", data);
}

const uploadFile = (data) => {
  return apiService.Post("storage/api/Storage/SaveFile", data);
}

const copyFile = (data) => {
  return apiService.Post("storage/api/Storage/CopyFile", data);
}

const renameFile = (data) => {
  return apiService.Post("storage/api/Storage/RenameFile", data);
}

const getDataMaster = () => {
  return apiService.Post("storage/api/Storage/GetDataMaster");
}

export const DocumentServices = {
  isStorageEnabled,
  searchDocumentLibraries,
  generateReport,
  getDataListFolders,
  getRecentFiles,
  getCurrentFiles,
  getMyFiles,
  getTrashFiles,
  getDataListFilesAndFolders,
  getDocumentLibraryById,
  deleteDocumentLibrary,
  moveFile,
  uploadFile,
  copyFile,
  renameFile,
  getDataMaster
}