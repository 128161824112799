import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import moment from "moment";
import { ProcessManagementServices } from "../services/ProcessManagementServices";
import { apiService } from "core/services/ApiService";
import { Skeleton } from "primereact/skeleton";
import { ModalGenerateVersion } from "./ProceseSheet/ModalGenerateVersion";
import { CoreConstants } from "core/commons/CoreConstants";
import { toastAction } from "core/store/actions/ToastAction";
import { useDispatch } from "react-redux";
import { useClaimProcessManagment } from "../hooks/useClaimProcessManagment";

const getProcessMap = () => {
  return apiService.Get("management/api/ProcessMap/GetProcessMap");
}

export const GeneralDataProcessMap = ({source, date, responsibles, data, version, setVersion, isLastVersion, versions, setVersions, isForm, changeVersion, setIsValidateDate, invalidCode, setInvalidCode, setCodeForDownload}) => {

	const [showModal, setShowModal] = useState(false)
	const [dateSelect, setDateSelect] = useState(date)
	const [responsibleSelect, setResponsibleSelect] = useState({})
  const [code, setCode] = useState()
  const [isSavedCode, setIsSavedCode] = useState(true)
	const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCode, setIsLoadingCode] = useState(false)
	const dispatch = useDispatch()

	const { isGenerateVersion } = useClaimProcessManagment()

	useEffect(()=>{
		setDateSelect(date)
		setResponsibleSelect(data.elaboratedById)
    setCode(data.code)
	},[date, data])

	const generarVersion = (value) => {
		setIsLoading(true)
		const send = {
			reasonChange: value
		}
		ProcessManagementServices.saveAndGenerateVersion(send).then(({data})=>{
			setIsLoading(false)
			if(data.status === CoreConstants.HttpResponse.OK){
				dispatch(toastAction.success("Proceso" ,'Guardado correctamente'))
				getProcessMap().then(({data})=>{
					setVersion(data.data.processMap.versionNumber)
					setVersions(data.data.versions)
					setShowModal(false)
				})
			}else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
				dispatch(toastAction.warn('Error', data.message))
				setShowModal(false)
			}else if(data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS){
				dispatch(toastAction.warn('Error', data.message))
				setShowModal(false)
			}else{
				dispatch(toastAction.error('Error', data.message))
				setShowModal(false)
			}
		}).catch((error)=>{
			setIsLoading(false)
			setShowModal(false)
    })
		.finally(()=>{setIsLoading(false);setShowModal(false)})
	}

	const updateCreationInfoE = (value) => {
		setResponsibleSelect(value)
		const send = {
			id: data.id,
			creatorId: value,
			elaborationDate: dateSelect,
      code,
		}
		
		if(value){
			ProcessManagementServices.updateCreationInfo(send).then(({data})=>{
				if(data.status === CoreConstants.HttpResponse.OK){
					dispatch(toastAction.success("Proceso" ,'Actualizado correctamente'))
				}else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
					dispatch(toastAction.warn('Error', data.message))
				}else if(data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS){
					dispatch(toastAction.warn('Error', data.message))
				}else{
					dispatch(toastAction.error('Error', data.message))
				}
			}).catch((error)=>{
			});
		}
	}

	const updateCreationInfoD = (value) => {
		setDateSelect(value)
		if(value){
			setIsValidateDate(true)
		}else{
			setIsValidateDate(false)
		}
		const send = {
			id: data.id,
			creatorId: responsibleSelect,
			elaborationDate: value,
      code,
		}
		
		if(value){
			ProcessManagementServices.updateCreationInfo(send).then(({data})=>{
				if(data.status === CoreConstants.HttpResponse.OK){
					dispatch(toastAction.success("Proceso" ,'Actualizado correctamente'))
				}else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
					dispatch(toastAction.warn('Error', data.message))
				}else if(data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS){
					dispatch(toastAction.warn('Error', data.message))
				}else{
					dispatch(toastAction.error('Error', data.message))
				}
			}).catch((error)=>{
			});
		}
	}

  const onBlurCode = (e) => {
    if(!isSavedCode && !invalidCode){
      setInvalidCode("Presione enter en la caja de texto para actualizar")
    }
  }

  const updateCreationInfoCode = (code) => {
    if(code?.length < 10){
      setInvalidCode(false)
      setCode(code)
    }
    setIsSavedCode(false)
	}

  const onKeyPress = (e) => {
    if(e.key === 'Enter'){
      e.preventDefault()
      let messageInvalid = null
      if(!code || code.length === 0) {
        messageInvalid = "El Código es requerido"
        setInvalidCode(messageInvalid)
      }
      if(!messageInvalid){
        setIsSavedCode(true)
        setInvalidCode(false)
        const send = {
          id: data.id,
          creatorId: responsibleSelect,
          elaborationDate: dateSelect,
          code: code
        }
        setIsLoadingCode(true)
        ProcessManagementServices.updateCreationInfo(send).then(({data})=>{
          if(data.status === CoreConstants.HttpResponse.OK){
            dispatch(toastAction.success("Proceso" ,'Actualizado correctamente'))
            setCodeForDownload(code)
          }else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
            dispatch(toastAction.warn('Error', data.message))
          }else if(data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS){
            dispatch(toastAction.warn('Error', data.message))
          }else{
            dispatch(toastAction.error('Error', data.message))
          }
        }).catch((error)=>{
          dispatch(toastAction.error('Error', data.message || ""))
        }).finally(()=>{
          setIsLoadingCode(false)
        })
      }
    }
  }
	
	return (
		<>
			<form style={{width: '15rem'}}>
				<Panel header='DATOS GENERALES'>
          <div>
            <label htmlFor="code">Código *</label>
            {
            (!isForm?.map || !isForm?.master) || isLoadingCode ? (
              <Skeleton height="2.2rem" />
          ) : (
                !isLastVersion ? 
                <InputText
                  name="code"
                  type="text"
                  id="code"
                  maxLength={100}
                  value={code}
                  readOnly
                  disabled
                  className="disabled"
                />
                :
                <InputText
                  name="code"
                  type="text"
                  id="code"
                  maxLength={100}
                  value={code || ""}
                  className={invalidCode ? 'p-invalid w-full' : 'w-full'}
                  onChange={(e)=>updateCreationInfoCode(e.target.value)}
                  onKeyPress={onKeyPress}
                  onBlur={onBlurCode}
                />
              )
            }
          </div>
          {
            invalidCode && 				
            <div style={{fontSize: 10, color: 'red'}}>
              {invalidCode}
            </div>
          }
				<div className='mb-4 mt-4'>
				<label htmlFor="Create" className="whitespace-nowrap">Fecha de Elaboración *</label>
				{
					!isForm?.map || !isForm?.master ? (
						<Skeleton height="2.2rem" />
				) : (
					!isLastVersion ? 
					<InputText
					name="update"
					type="text"
					id="Update"
					maxLength={100}
					readOnly
					disabled
					className="disabled"
					value={(data.creationDate && moment(data.creationDate).format(CoreConstants.Format.LOCALE)) || ""}
				/> : 
				<>
					<Calendar
						showIcon
						name="dateCreate"
						monthNavigator
						yearNavigator
						yearRange="1950:2050"

						id="Create"
						value={dateSelect}
						maxDate={new Date()}
						onChange={(e)=>updateCreationInfoD(e.value)}
						className={!dateSelect ? 'p-invalid w-full' : 'w-full'}
					></Calendar>
					</>
						)}
					</div>
					<div className='mb-4'>
					<label htmlFor="CreateFor">Elaborado Por *</label>
					{
					!isForm?.map || !isForm?.master ? (
						<Skeleton height="2.2rem" />
				) : (			
						!isLastVersion ? 
						<InputText
						name="responsibleId"
						type="text"
						id="responsibleId"
						maxLength={100}
						readOnly
						disabled
						className="disabled"
						value={data.creationUser || ""}
					/> : 
					<Dropdown
						filter
						optionLabel="fullName" 
						filterBy="fullName"
						id="responsibleId"
						name="responsibleId"
						options={responsibles}  
						optionValue="id"   
						onChange={(e)=>{updateCreationInfoE(e.value)}}
						value={responsibleSelect}
					/>					
					)}
					{/* <InputText
						name="elaborado"
						type="text"
						id="CreateFor"
						maxLength={100}
						readOnly
						disabled
						className="disabled"
						value={data.creationUser || ""}
					/> */}
					</div>
					<div className='mb-4'>
					<label htmlFor="Update">Última Actualización</label>
					{
					!isForm?.map || !isForm?.master ? (
						<Skeleton height="2.2rem" />
				) : (
            !isLastVersion ? 
            <InputText
              name="update"
              type="text"
              id="Update"
              maxLength={100}
              readOnly
              disabled
              className="disabled"
              value={data.lastElaborationDate?moment(data.lastElaborationDate).format(CoreConstants.Format.LOCALE):""}
            />
            :
            <InputText
              name="update"
              type="text"
              id="Update"
              maxLength={100}
              readOnly
              disabled
              className="disabled"
              value={(data.modificationDate && moment(data.modificationDate).format(CoreConstants.Format.LOCALE)) || ""}
            />
          )}
					</div>
					<div className='mb-4'>
					<label htmlFor="UpdateFor">Actualizado por</label>
					{
					!isForm?.map || !isForm?.master ? (
						<Skeleton height="2.2rem" />
				) : (
            !isLastVersion ? 
            <InputText
              name="updatefor"
              type="text"
              id="UpdateFor"
              maxLength={100}
              readOnly
              disabled
              className="disabled"
              value={data.lastUpdateBy || ""}
            />
            :
            <InputText
              name="updatefor"
              type="text"
              id="UpdateFor"
              maxLength={100}
              readOnly
              disabled
              className="disabled"
              value={data.modificationUser || ""}
            />
          )}
					</div>
					<div className='mb-4'>
					<label htmlFor="Versions">Versión</label>
					{
					!isForm?.map || !isForm?.master ? (
						<Skeleton height="2.2rem" />
				) : (
					<Dropdown
						name="version"
						value={version || ""}
						onChange={(e)=> changeVersion(e)}
						id="Versions"
						options={versions.map(item=>({label: item.versionNumberLabel,value: item.versionNumber}))}
					/>)}
					</div>
					{
						(source==="mapa-procesos")?<>
							{
								(isLastVersion && isGenerateVersion)&&
								<div className="flex justify-center">
									<Button
										type="button"
										label="Generar Versión"
										className="sig-button mt-4 sig-primary"
										onClick={()=>{if(dateSelect){setShowModal(true)}}}
									/>
								</div>
							}
						</>:<>
							{
							isLastVersion &&
							<div className="flex justify-center">
								<Button
									type="button"
									label="Generar Versión"
									className="sig-button mt-4 sig-primary"
									onClick={()=>{if(dateSelect){setShowModal(true)}}}
								/>
							</div>
						}
						</>
					}
				</Panel>
			</form>
			<ModalGenerateVersion visibleModalVersion={showModal} setVisibleModalVersion={setShowModal}  onSubmitValue={generarVersion} isLoading={isLoading}/>
		</>
	)
}