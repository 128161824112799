import { useHistory } from 'react-router-dom';
import './LogoComponent.scss';
const LogoComponent = ({noRedirect = false}) => {
  const history = useHistory();

  return (
    <div className="logo-main flex items-center gap-2 h-full">
      <img
        className={`logo-icon sig-logo h-full object-fill ${noRedirect ? '' : 'cursor-pointer'}`}
        alt="Calidar"
        title="Calidar"
        onClick={noRedirect ?  () => {} : () => history.push('/inicio') }
      />
    </div>
  );
};

export default LogoComponent;
