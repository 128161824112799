import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from 'core/store/actions/ToastAction';
import { BusinessSheetAnswerConstants } from 'features/business/commons/BusinessSheetAnswerConstants';
import { BusinessSheetPublicConstants } from 'features/business/commons/BusinessSheetPublicConstants';

import { sheetAnswerSearchAdapter } from 'features/business/helpers/businessSheetAnswer/businessSheetAnswerAdapter';
import { getByIdAdapter } from 'features/business/helpers/businessSheetPublic/BusinessSheetPublicAdapter';
import { BusinessSheetAnswerService } from 'features/business/services/BusinessSheetAnswerService';
import FileSaver from "file-saver";

const setBusinessAnswerSheet = (payload) => ({
  type: BusinessSheetAnswerConstants.Action.BusinessSheetAnswer.SEARCH,
  payload,
});

const search = (pagination, setRowCount) => {
  return (dispatch) => {
    dispatch(setBusinessAnswerSheet({ loading: true }));
    BusinessSheetAnswerService.search({ fields: pagination })
      .then(({ data }) => {
        const status = data?.status ?? '';
        if (status === CoreConstants.HttpResponse.OK) {
          const { results, ...rest } = data.data;
          setRowCount(rest.rowCount)
          dispatch(setBusinessAnswerSheet({ data: results.map(sheetAnswerSearchAdapter) }));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Ficha de Negocios", data.message));
        }else{
          dispatch(toastAction.error("Ficha de Negocios", data.message));
        }

      })
      .catch((error)=>{
      })
      .finally(() => {
        dispatch(setBusinessAnswerSheet({ loading: false }));
      });
  };
};

const downloadReport = (searchFields) => {
  return (dispatch) => {       
    dispatch(setBusinessAnswerSheet({ isLoadingDownload: true }));
    BusinessSheetAnswerService.generateReport(searchFields)
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        FileSaver.saveAs(blob, "ActividadDeNegocio.xlsx");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(setBusinessAnswerSheet({ isLoadingDownload: false }));
      });
  };
};

const setEditBusinessSheetAnswer = (payload) => ({
  type: BusinessSheetAnswerConstants.Action.BusinessSheetAnswer.EDIT,
  payload,
});

const setBusinessSheetPublic = (payload) => ({
  type: BusinessSheetPublicConstants.Action.BusinessSheetPublic.BUSINESS_SHEET,
  payload,
});


const getById = (id) => {
  return (dispatch) => {
    dispatch(setEditBusinessSheetAnswer({ loading: true }));
    dispatch(setBusinessSheetPublic({ loading: true }));
    BusinessSheetAnswerService.getById(id).then(({ data }) => {
      const status = data.status;
      if (status === CoreConstants.HttpResponse.OK) {
        const { businessSheet, response } = data.data;
        dispatch(setBusinessSheetPublic({ businessSheet: getByIdAdapter(businessSheet) }));
        dispatch(setEditBusinessSheetAnswer({ businessSheetAnswer: response }));
      } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
        dispatch(toastAction.warn("Ficha de Negocios", data.message));
      }else{
        dispatch(toastAction.error("Ficha de Negocios", data.message));
      }
    })
    .catch(console.error)
    .finally(() => {
      dispatch(setEditBusinessSheetAnswer({ loading: false }));
      dispatch(setBusinessSheetPublic({ loading: false }));
    });
  }
}

const resetDataBusinessSheetAnswer = () => ({
  type: BusinessSheetAnswerConstants.Action.BusinessSheetAnswer.EDIT,
  payload: {
    businessSheetAnswer: {},
    loading: false,
  },
})

const resetBusinessSheetPublic = () => ({
  type: BusinessSheetPublicConstants.Action.BusinessSheetPublic.BUSINESS_SHEET,
  payload: {
    businessSheet: {},
    loading: false,
  },
});

export const businessSheetAnswerAction = {
  search,
  downloadReport,
  getById,
  resetDataBusinessSheetAnswer,
  resetBusinessSheetPublic,
}