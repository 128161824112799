import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Square from './Square';
import { RiskManagementAction } from '../store/actions/RiskManagementAction';

const Row = ({row, probabilidad, setValue}) => {

	const dispatch = useDispatch()
	const { probability } = useSelector(state => state.riskManagementReducer.data)

	return (
		<div className='row-custom flex'>
			<div className='w-16 flex m-auto' style={{fontSize: '10px'}}>
				{row[0].rowName}
			</div>
			<div className='flex gap-1 w-max ml-1'>
			{
				row.map((item, key)=>
					<Square key={key} color={item.colorCode} id={item.id}/>
				)
			}	
			</div>
			<div className='flex m-auto ml-2'>
			{
				probability?.length > 2 &&
				<i 
					className="pi pi-times-circle mr-4 text-xl cursor-pointer" 
					onClick={()=> {
					dispatch(RiskManagementAction.deleteRow(row[0].rowId))
					setValue(`likelihood-name-${row[0].rowId}`, undefined)
					setValue(`likelihood-value-${row[0].rowId}`, undefined)
					}}
				/>
			}
			</div>
		</div>
	);
};

export default Row;