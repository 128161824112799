/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { BusinessSheetAction } from "features/business/store/actions/BusinessSheetAction"
import useSetTitlePage from "shared/hooks/useSetTitlePage"

export const useFetchData = () => {
  const dispatch = useDispatch()
  const params = useParams();
  const businessSheet = useSelector(state => state.businessSheetReducer.data.businessSheet)
  const dataMaster = useSelector(state => state.businessSheetReducer.dataMaster.data)
  const isLoadingDataMaster = useSelector(state => state.businessSheetReducer.dataMaster.isLoadingDataMaster)
  const isLoadingSave = useSelector(state => state.businessSheetReducer.save.isLoadingSave)
  const listControls = useSelector(state => state.businessSheetReducer.listControls.listControls)
  const isLoadingListControls = useSelector(state => state.businessSheetReducer.listControls.isLoadingListControls)
  const currentTemplateId = useSelector(state => state.businessSheetReducer.businessSheetTemplates.currentTemplateId)
  const {updateTitle } = useSetTitlePage()

  const businessSheetId = params.id;

  useEffect(() => {
    updateTitle({
      title: "Ficha de negocio",
      subtitle: "Configuración"
    })
    dispatch(BusinessSheetAction.getListControls())
    dispatch(BusinessSheetAction.getDataMaster())
    if(businessSheetId) dispatch(BusinessSheetAction.getById(businessSheetId))
    else if(currentTemplateId) {
      dispatch(BusinessSheetAction.getById(currentTemplateId, true))
    }
    return () => {
      dispatch(BusinessSheetAction.resetListControls())
      dispatch(BusinessSheetAction.resetDataBusinessSheet())
      dispatch(BusinessSheetAction.setCurrentTemplateId(null))
    }
  },[])

  return {
    businessSheet,
    dataMaster,
    isLoadingDataMaster,
    isLoadingSave,
    listControls,
    isLoadingListControls,
  }
}