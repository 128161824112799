

import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ProjectExpensesAction } from 'features/projects/store/actions/ProjectExpensesActions';
import { Skeleton } from 'primereact/skeleton';
import { CoreConstants } from 'core/commons/CoreConstants';
import { useClaimProjectExpenses } from 'features/projects/hooks/useClaimProjectExpenses';
import { Link } from 'react-router-dom';

// const staticData = [
//     {
//         id: '1',
//         project: 'Proyecto de desarrollo',
//         data: [
//             {
//                 id: '1-1',
//                 category: 'Contratos',
//                 initialDate: moment().format(CoreConstants.Format.LOCALE),
//                 endDate: moment().format(CoreConstants.Format.LOCALE),
//                 amount: 'S/. 1000',
//             },
//             {
//                 id: '1-2',
//                 category: 'Compras',
//                 initialDate: moment().format(CoreConstants.Format.LOCALE),
//                 endDate: moment().format(CoreConstants.Format.LOCALE),
//                 amount: 'S/. 100',
//             },
//         ]
//     },
//     {
//         id: '2',
//         project: 'Proyecto de construcción',
//         data: [
//             {
//                 id: '1-1',
//                 category: 'Contratos',
//                 initialDate: moment().format(CoreConstants.Format.LOCALE),
//                 endDate: moment().format(CoreConstants.Format.LOCALE),
//                 amount: 'S/. 1000',
//             },
//             {
//                 id: '1-2',
//                 category: 'Compras',
//                 initialDate: moment().format(CoreConstants.Format.LOCALE),
//                 endDate: moment().format(CoreConstants.Format.LOCALE),
//                 amount: 'S/. 100',
//             },
//         ]
//     },
// ]

const TableComponent = () => {
    
    const dispatch = useDispatch();
    const history = useHistory();
    
    const advancedFilter = useSelector((state) => state.projectExpensesReducer.advancedFilter);
    const { data } = useSelector((state) => state.projectExpensesReducer.ProjectExpenses);
    const ProjectExpenses = useSelector((state) => state.projectExpensesReducer.ProjectExpenses);
    const {isDeleted} = useSelector((state) => state.projectExpensesReducer.projectExpense);
    const { categories } = useSelector((state) => state.projectExpensesReducer.dataMaster);

    const columns = [
        { field: 'source', header: 'Proceso'},
    ];

    const { isView ,isEdit, isDelete } = useClaimProjectExpenses()

    const [selectedColumns, setSelectedColumns] = useState(columns);
    const actionsHeader = useRef(null);
    const menu = useRef(null);
    const [showConfirmDialog, setshowConfirmDialog] = useState(false);
    const [isFirstLoading, setFirstLoading] = useState(true); 
    const [expandedRows, setExpandedRows] = useState(null);
    const [projectExpenseSelected, setProjectExpenseSelected] = useState(null);

    let items = [
        {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: (e) => {
                history.push('/proyecto/gastos/' + projectExpenseSelected.id);
            }
        },
        {
            label: 'Eliminar',
            icon: 'pi pi-times',
            command: (e) => {
                setshowConfirmDialog(true);
            }
        },
    ];

    if (!isEdit)
        items = items.filter(c => c.label !== 'Editar');
    if (!isDelete)
        items = items.filter(c => c.label !== 'Eliminar');

    const getViewField = (rowData) => {
        const category = categories.find(project => project.id === rowData.categoryId);
        if (isView)
          return <Link className="underline text-blue-700" to={{ pathname: `/proyecto/gastos/visualizar/` + rowData.id }} >{category.description}</Link>
        else
          return category.description;
      }

    useEffect(() => {
        if (isFirstLoading) {
            tryParseFilter();
            setFirstLoading(false);
            // setSortField("name");
            // setSortOrder(1);
            dispatch(ProjectExpensesAction.search());
        }
    }, []);


    useEffect(() => {
        if (isDeleted) {
            setshowConfirmDialog(false);
            dispatch(ProjectExpensesAction.resetAfterSaved());
            dispatch(ProjectExpensesAction.search());
        }
    }, [isDeleted])

    // const actionHeaderTemplate = (rowData) => {
    //     return <i
    //         key="action"
    //         className="pi pi-ellipsis-v cursor-pointer"
    //         onClick={(e) => actionsHeader.current.toggle(e)}></i>;
    // }

    const actionBodyTemplate = (rowData) => {
        if(isEdit||isDelete){
            return <i
                key={rowData.id}
                className="pi pi-ellipsis-v cursor-pointer"
                onClick={(event) => {
                    setProjectExpenseSelected(rowData);
                    menu.current.toggle(event)
                }
                }></i>
        }else{
            return <div></div>
        }
    }

    const tryParseFilter = () => {
        try {
            const filters = JSON.parse(localStorage.getItem("occupation-filter") || '[]');
            const myFilters = filters.filter(item => item.field === 'source');
            if (filters)
                setSelectedColumns(myFilters);
            else
                setSelectedColumns(columns);
        } catch (error) {
            localStorage.removeItem('occupation-filter');
        }
    }

    const onColumnToggle = (e, item) => {
        let _selectedColumns = [...selectedColumns];

        if (e.checked)
            _selectedColumns.push(item);
        else
            _selectedColumns = _selectedColumns.filter(c => { return c.field !== e.value });

        setSelectedColumns(_selectedColumns);
        localStorage.setItem("occupation-filter", JSON.stringify(_selectedColumns));
    }

    const getColumns = () => {
        let array = [];
        
        columns.map((item) => {
            const column = <div key={item.field} className="p-field-checkbox py-1">
                <Checkbox
                    inputId={item.field}
                    name="column"
                    value={item.field}
                    onChange={(e) => onColumnToggle(e, item)}
                    checked={selectedColumns.some((x) => x.field === item.field)}
                />
                <label className="ml-1" htmlFor={item.field}>{item.header}</label>
            </div>
            array.push(column);
        });
        return array;
    };


    const confirmDelete = () => {
        dispatch(ProjectExpensesAction.deleteProjectExpense(projectExpenseSelected.id));
    }

    const cancelDelete = () => {
        setshowConfirmDialog(false);
    }

    const [rows, setRows] = useState(10)

    const onPageChange = (e) => {
        setRows(e.rows);
        advancedFilter.page = e.page + 1;
        advancedFilter.pageSize = e.rows;
        dispatch(ProjectExpensesAction.updateFilter(advancedFilter));
        dispatch(ProjectExpensesAction.search());
    }

    const onRowExpand = (event) => {
        // toast.current.show({severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000});
    }

    const getFromDate = (rowData) => rowData.from ? moment(rowData.from).format(CoreConstants.Format.LOCALE) : ''
    const getToDate = (rowData) => rowData.to ? moment(rowData.to).format(CoreConstants.Format.LOCALE) : ''

    const getCategory = (rowData) => {
        const category = categories.find(project => project.id === rowData.categoryId);
        return <p>{category.description}</p>
    }
    const getAmount = (rowData) => {
        return <p>S/. {rowData.totalAmount}</p>
    }

    const rowExpansionTemplate = (data) => {
        return (
          <div className="tasks-subtable">
            <DataTable value={data.children} header={null} className='ml-0' 
                // lazy loading={loading}
                emptyMessage="No se encontraron gastos">
                <Column field="categoryName" header="Categoria" body={getViewField} style={{width: '25%'}}   />
                <Column field="from" header="Inicio" body={getFromDate} style={{width: '25%'}} sortable />
                <Column field="to" header="Fin" body={getToDate} style={{width: '25%'}} sortable />
                {/* <Column field="amount" header="Monto" style={{textAlign: 'right'}} /> */}
                <Column field="totalAmount" header="Monto" body={getAmount} style={{width: '25%'}} />
                <Column header={<div></div>} body={actionBodyTemplate} ></Column>
            </DataTable>
          </div>
        );
      };

    const projectBodyTemplate = (rowData) => {
        return <span className=''>{rowData.data.projectName}</span>;
    } 

    return (
        <Fragment>
            {
                ProjectExpenses.isLoading ? <Skeleton height='12rem' /> : (
                    <div className="">
                        <DataTable
                            value={data}
                            // loading={ProjectExpenses.isLoading}
                            emptyMessage="No se encontraron proyectos"
                            stripedRows
                            paginator rows={10}
                            dataKey="key"
                            rowHover
                            size="small"
                            responsiveLayout="scroll"
                            rowExpansionTemplate={rowExpansionTemplate}
                            expandedRows={expandedRows}
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            onRowExpand={(e) => onRowExpand(e.data)}
                        >                      
                            <Column expander style={{ width: '3em' }} />
                            <Column field="project" header="Proyecto" body={projectBodyTemplate} sortable />
                        </DataTable>
                    </div>
                )
            }

            {/* <Paginator
                key={"pag-1"}
                className="paginator-custom mt-4"
                rows={rows} 
                first={(data?.currentPage - 1) * rows}
                rowsPerPageOptions={[10,20,30]}
                totalRecords={data?.rowCount}
                onPageChange={onPageChange}
            ></Paginator> */}

            <OverlayPanel ref={actionsHeader} showCloseIcon style={{ width: '300px' }}>{getColumns()}</OverlayPanel>

            <Menu model={items} popup ref={menu} id="popup_menu" />
            <ConfirmDialog
                visible={showConfirmDialog}
                onHide={cancelDelete}
                message="¿Está seguro que desea eliminar el registro?"
                header="Eliminar"
                icon="pi pi-trash" 
                accept={confirmDelete}
                reject={cancelDelete}
            />
        </Fragment >
    );
};

export default TableComponent;
