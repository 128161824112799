import React, { useEffect, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import { Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { RiskModelAction } from 'features/riskManagement/store/actions/RiskModelAction';
import { InputNumber } from 'primereact/inputnumber';

const RegisterDelete = (registerSelect,idCriteria ,dispatch, setValue) => {
	return (
		<i
		onClick={() => {
			dispatch(RiskModelAction.deleteRegister(registerSelect,idCriteria))
			setValue(`nameRegister_${idCriteria}_${registerSelect.idRegister}`, undefined)
			setValue(`valueRegister_${idCriteria}_${registerSelect.idRegister}`, undefined)
		}}
		className="pi pi-trash cursor-pointer"
	></i>
	)
}

const rowExpansionTemplate = (data, control, dispatch, valuesFields, setValue) => {
	
	return (
			<div className="orders-subtable">
					<DataTable value={data.registro} responsiveLayout="scroll" dataKey='idRegister'>
							<Column field="nameRegister" style={{paddingLeft: '15px'}} header="Nombre" body={(rowData) => NameRegisterInput(rowData, control, data.id)}></Column>
							<Column field="value" style={{paddingLeft: '0px', width: '100px'}} header="Valor" body={(rowData) => ValueRegister(rowData, control, data.id, valuesFields)}></Column>
							<Column header="" body={(registerSelect)=>RegisterDelete(registerSelect,data.id, dispatch, setValue)}/>
					</DataTable>
			</div>
	);
}

const CodeInput = (rowData, control) => {
	return <Controller
	control={control}
	name={`codeCriteria_${rowData.id}`}
	rules={{
		required: 'Código de criterio es requerido',	
	}}
	render={({field: { onChange, value }, fieldState: { error },}) => (
		<InputText
			value={value || ""}
			onChange={onChange} 
			className={error ? 'p-invalid w-full' : 'w-full'}
		/>
		)}
	/>
}

const NameCriteriaInput = (rowData, control, dispatch, trigger, getValues) => {
	return <Controller
	control={control}
	name={`nameCriteria_${rowData.id}`}
	rules={{
		required: 'Nombre de criterio es requerido',	
		validate:	{
			isNotRepeat: value => {
			const values = getValues()
			const valuesNames = Object.keys(values).filter( key => key.slice(0, 12) === 'nameCriteria' && key !== `nameCriteria_${rowData.id}`).map( item => values[item])
			return !valuesNames.includes(value) || "No se deben repetir los nombres"
		}}
	}}
	render={({field: { onChange, value }, fieldState: { error },}) => (
		<InputText
			value={value || ""}
			onChange={(e)=>{
				onChange(e)
				const values = getValues()
				const valuesNames = Object.keys(values).filter( key => key.slice(0, 12) === 'nameCriteria' && values[key] && values[key] !== "")
				trigger(valuesNames)
				dispatch(RiskModelAction.changeNameCriteria(e.target.value, rowData.id))
			}}
			maxLength={250}
			className={error ? 'p-invalid w-full' : 'w-full'}
		/>
		)}
	/>
}

const TypeCriteria =(rowData, control, criterias) => {
	return <Controller
	control={control}
	name={`typeCriteria_${rowData.id}`}
	rules={{
		required: 'Tipo de criterio es requerido',	
	}}
	render={({field: { onChange, value }, fieldState: { error },}) => (
		<Dropdown
			options={criterias}
			optionLabel= 'description'
			optionValue='id'
			onChange={onChange}
			value={value}
			className={error ? 'p-invalid w-full' : 'w-full'}
		/>
		)}
	/>
}

const NameRegisterInput = (rowData, control, id) => {
	return <Controller
	control={control}
	name={`nameRegister_${id}_${rowData.idRegister}`}
	rules={{
		required: 'Nombre de registro es requerido',	
	}}
	render={({field: { onChange, value }, fieldState: { error },}) => (
		<InputText
			value={value || ""}
			onChange={onChange} 
			maxLength={250}
			className={error ? 'p-invalid w-full' : 'w-full'}
		/>
		)}
	/>
}

const ValueRegister = (rowData, control, id, valuesFields) => {
	return (
		<Controller
		control={control}
		name={`valueRegister_${id}_${rowData.idRegister}`}
		rules={{
			required: 'Valor de registro es requerido',	
		}}
		render={({field: { onChange, value }, fieldState: { error },}) => (
			<InputNumber 
				value={value}
				onValueChange={(e) => onChange(e.value)} 
				mode="decimal"
				locale="en-US"
				useGrouping={false}
				maxFractionDigits={(valuesFields.position && Number(valuesFields.position)) || 0} 
				className={error ? 'p-invalid w-full' : 'w-full'}
			/>)}
		/>
	)
}

const EvaluacionCriterioComponent = ({control, setValue, valuesFields, trigger,
	getValues}) => {

	const dispatch = useDispatch()
	const { data } = useSelector(state => state.riskModelReducer.criteria)
	const { criterias } = useSelector(state => state.riskModelReducer.dataMasterEvaluation.data)
	const [expandedRows, setExpandedRows] = useState(null);
	const [firstLoad, setFirstLoad] = useState(true)

	const addCriteria = () => {
		dispatch(RiskModelAction.addCriteria())
	}

	useEffect(()=>{
		if(firstLoad && data !== null){
			let _expandedRows = {};
			data.forEach(p => _expandedRows[`${p.id}`] = true);
			setExpandedRows(_expandedRows);
			setFirstLoad(false)
		}
	},[data, firstLoad])

	const CriteriaDelete = (criteriaSelect) => {
		return (
			<i
			onClick={() => {
				dispatch(RiskModelAction.deleteCriteria(criteriaSelect))
				setValue(`codeCriteria_${criteriaSelect.id}`, undefined)
				setValue(`nameCriteria_${criteriaSelect.id}`, undefined)
				setValue(`typeCriteria_${criteriaSelect.id}`, undefined)
			}}
			className="pi pi-trash cursor-pointer"
		></i>
		)
	}

	const expandById = (id) => {
		setExpandedRows(prev => ({...prev, [id]: true }));
	}

	const RegisterAdd = (criteriaSelect) => {
		return (
			<i
			onClick={() => {
				expandById(Number(criteriaSelect.id))
				dispatch(RiskModelAction.addRegister(criteriaSelect.id))}
			}
			className="pi pi-plus-circle cursor-pointer"
		></i>
		)
	}

	return (
		<div>
		<Button 
			type='button'
			label='Añadir Criterio'
			icon='pi pi-plus'
			className='sig-button sig-primary mb-4'
			onClick={addCriteria}
		/>
		<DataTable value={data || []}
			
			expandedRows={expandedRows}
			onRowToggle={(e) => {setExpandedRows(e.data)}}
			responsiveLayout="scroll"
			rowExpansionTemplate={(data) => rowExpansionTemplate(data, control, dispatch, valuesFields, setValue)}
			dataKey="id"
		>
			<Column expander style={{ width: '3em' }} />
			<Column field="code" style={{paddingLeft: '0px', width: '100px'}} body={(rowData) => CodeInput(rowData, control)} header="Código"/>
			<Column field="name" style={{paddingLeft: '0px'}} header="Nombre" body={(rowData) => NameCriteriaInput(rowData, control, dispatch, trigger, getValues)} />
			<Column field="type" style={{paddingLeft: '0px', width: '100px'}} header="Tipo" body={(rowData) => TypeCriteria(rowData, control, criterias)}/>
			<Column header="" style={{paddingRight: '0px'}} body={RegisterAdd}/>
			<Column header="" style={{paddingLeft: '0px'}} body={CriteriaDelete}/>
		</DataTable>
		</div>
	);
};

export default EvaluacionCriterioComponent;