import { InputSwitch } from 'primereact/inputswitch';
import { Panel } from 'primereact/panel'
import { RadioButton } from 'primereact/radiobutton'
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useEffect, useState } from 'react'

export const ConfigurationComponent = ({stateConfigPage, setStateConfigPage, dinamicStage, stateInsufficient, children}) => {

  const [dataPanel, setDataPanel] = useState({simpleFilters:[], advanceFilters: [], searchColumns: []})
  const [options, setOptions] = useState([
    {code: 'simpleFilters',name:'Filtro Simple', description: 'Aquí podras configurar la busqueda por filtro simple. Recuerda que solo podrá seleccionar un elemento.'},
    {code: 'advanceFilters',name:'Filtro Avanzado', description: 'Aquí podras configurar la busqueda por filtro avanzado. Puedes seleccionar uno o más elementos.'},
    {code: 'searchColumns',name:'Listado', description: 'Aquí podras configurar los elementos que se mostrarán en la vista de listado.'}])


  useEffect(()=>{
    if(dinamicStage){
      const data = [...dinamicStage]
      const newDataPanel = {simpleFilters:[], advanceFilters: [], searchColumns: []}
      data.map(element => {
        element.dinamicInput?.map(el=> {
              if(el.visibleSimpleFilter){
                newDataPanel.simpleFilters.push({identifier: el.identifier, name: el.fieldLabel})
              }
              if(el.visibleAdvancedFilter){
                newDataPanel.advanceFilters.push({identifier: el.identifier, name: el.fieldLabel})
              }
              if(el.visibleSearch){
                newDataPanel.searchColumns.push({identifier: el.identifier, name: el.fieldLabel})
              }
              return el
        })
        return element
      })
      setDataPanel(newDataPanel)
    }
  },[dinamicStage])

  const changeFilterSimple = (e) => {
    setStateConfigPage({...stateConfigPage, simpleFilters: [e.value]})
  }

  const changeFilter = (e, selected, identifier) => {
    if(e.value===true){
      setStateConfigPage({...stateConfigPage, [selected]: [...stateConfigPage[selected], identifier]})
    }else{
      setStateConfigPage({...stateConfigPage, [selected]: stateConfigPage[selected].filter(el => el!==identifier)})
    }
  }

  return (
    <div className='flex flex-col h-full'>
      <div className="card tabs-vertical content-div1 scroll flex flex-col justify-between">
        <TabView className="tabview-custom " orientation="bottom">
          {
            options.map((el, ind) => {
              return (
                <TabPanel header={el.name} key={ind}>
                  <div>
                    <h3 className='font-semibold'>{el.description}</h3>
                  </div>
                  {
                    dataPanel[el.code].length > 0 ? (
                    // <div className='overflow-y-scroll' style={{height: '50vh'}} >
                    <div>
                      {
                        dataPanel[el.code].map((elem, index)=> {
                          return (<div key={index} >
                            {
                              el.code==='simpleFilters'&&(
                                <div className={`field-radiobutton mb-6 ${index===0&&'mt-6'}`}>
                                  <RadioButton className='mr-2' inputId={elem.identifier} name="category" value={elem.identifier} onChange={(e) => changeFilterSimple(e)}  checked={(stateConfigPage.simpleFilters[0])?stateConfigPage.simpleFilters[0]===elem.identifier:false} />
                                  <label htmlFor={elem.identifier}>{elem.name}</label>
                                </div>
                              )
                            }
                            {
                              el.code==='advanceFilters'&&(
                                <span className="p-float-label mt-6">
                                  <div className='flex items-center'>
                                      <InputSwitch checked={stateConfigPage.advanceFilters?.includes(elem.identifier)} onChange={(e)=> changeFilter(e, "advanceFilters", elem.identifier)} />
                                    <h5 className='ml-2'>{elem.name}</h5>
                                </div>
                              </span>
                              )
                            }
                            {
                              el.code==='searchColumns'&&(
                                <span className="p-float-label mt-6">
                                  <div className='flex items-center'>
                                      <InputSwitch checked={stateConfigPage.searchColumns?.includes(elem.identifier)} onChange={(e)=> changeFilter(e, "searchColumns", elem.identifier)} />
                                      <h5 className='ml-2'>{elem.name}</h5>
                                  </div>
                              </span>
                              )
                            }
                          </div>
                          )
                        })
                      }
                    </div>
                    ) : (<></>)
                  }
                </TabPanel>
              )
            })
          }
        </TabView>
        <div className={`rounded bg-red-200 p-5 mt-6 text-sm text-red-600 ${stateInsufficient===''&&'hidden'}`}>
          <ul>
            <li>{stateInsufficient}</li>
          </ul>
        </div>
      </div>
      
      <div className='mb-2'>
        {children}
      </div>
    </div>
  //   <div className='h-full'>
  //   <div className='flex gap-x-4'>
  //     <Panel header='FILTRO SIMPLE' className='w-1/5'>
  //       {
  //             dataPanel?.simpleFilters?.map((elem, index)=> {
  //               return (
  //                 <div key={index} className={`field-radiobutton mb-6 ${index===0&&'mt-6'}`}>
  //                   <RadioButton className='mr-2' inputId={elem.identifier} name="category" value={elem.identifier} onChange={(e) => changeFilterSimple(e)}  checked={(stateConfigPage.simpleFilters[0])?stateConfigPage.simpleFilters[0]===elem.identifier:false} />
  //                   <label htmlFor={elem.identifier}>{elem.name}</label>
  //                 </div>
  //               )
  //             })
  //       }
  //     </Panel>
  //     <Panel header='FILTROS AVANZADOS' className='w-1/5'>
  //       {
  //         dataPanel?.advanceFilters?.map((item,index)=>{
  //         return(
  //           <span className="p-float-label mt-6" key={index}>
  //               <div className='flex items-center'>
  //                   <InputSwitch checked={stateConfigPage.advanceFilters?.includes(item.identifier)} onChange={(e)=> changeFilter(e, "advanceFilters", item.identifier)} />
  //                   <h5 className='ml-2'>{item.name}</h5>
  //               </div>
  //           </span>
  //         )
  //         })
  //       }
  //     </Panel>
  //     <Panel header='LISTADO' className='w-1/5'>
  //       <span className="p-float-label mt-6">
  //         {
  //           dataPanel?.searchColumns?.map((item,index)=>{
  //           return(
  //             <span className="p-float-label mt-6" key={index}>
  //                 <div className='flex items-center'>
  //                     <InputSwitch checked={stateConfigPage.searchColumns?.includes(item.identifier)} onChange={(e)=> changeFilter(e, "searchColumns", item.identifier)} />
  //                     <h5 className='ml-2'>{item.name}</h5>
  //                 </div>
  //             </span>
  //           )
  //           })
  //         }
  //       </span>
  //     </Panel>
  //   </div>
  //   <div className={`rounded bg-red-200 p-5 mt-6 text-sm text-red-600 ${stateInsufficient===''&&'hidden'}`}>
  //   <ul>
  //     <li>{stateInsufficient}</li>
  //   </ul>
  // </div>
  //   </div>
  )
}