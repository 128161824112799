import { useState } from "react";
import { RadioButton } from "primereact/radiobutton";
import { InputSwitch } from "primereact/inputswitch";
import { ControlGeneralConfiguration } from "./ControlGeneralConfiguration";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

export const RadioButtonConfiguration = ({question, onChangeOptions, removeField}) => {
  const [required, setRequired] = useState(question.required)
  const [options, setOptions] = useState(JSON.parse(JSON.stringify(question.options)) || [])
  const [error, setError] = useState({
    status: false,
    errors: []
  })

  const changeRequired = (e) => {
    setRequired(e.value)
  }

  const changeNameOption = (id) => (e) => {
    setOptions(prevOptions => {
      const newOptions = prevOptions.map(option => {
        if(option.id !== id) return option
        else {
          option.name = e.target.value
          return option
        }
      })
      return newOptions
    })
  }

  const deleteOption = (id) => {
    setOptions(prevOptions => prevOptions.filter(option => option.id !== id))
  }

  const addOption = (e) => {
    setOptions(prevOptions => {
      const newId = Math.min(...prevOptions.map(option => option.id), 0) - 1
      const newOption = {
        id: newId,
        name: null,
      }
      return [...prevOptions, newOption]
    })
  }

  const changeOptionsAditional = () => {
    if(options.length <= 0){
      setError({
        status: true,
        errors: ["Ingrese al menos un elemento para la Lista Desplegable."]
      })
      return true;
    } else {
      const status = options.map(o => o.name?.trim()).some( n => n === "" || n === null || n === undefined)
      if(status){
        setError({
          status: true,
          errors: ["Existen elementos vacíos en las opciones."]
        })
        return true;
      }
      setError({
        status: false,
        errors: []
      })
      onChangeOptions({field: "options", value: options, id: question.id})
      onChangeOptions({field: "required", value: required, id: question.id})
    }
  }

  const onCancel = () => {
    setRequired(question.required);
    setOptions(JSON.parse(JSON.stringify(question.options)))
    setError({
      status: false,
      errors: []
    })
  }

  return(
    <ControlGeneralConfiguration
      onChangeOptions={onChangeOptions}
      question={question}
      changeOptionsAditional={changeOptionsAditional}
      onCancel={onCancel}
      removeField={removeField}
      render={(question) => question.options.map((item) => (
        <div key={`${item.id}`} className="field-radiobutton">
          <RadioButton inputId={item.id} name={item.id} value={item} disabled={item.disabled} />
          <label htmlFor={item.id}> {item.name}</label>
        </div>))
      }
      headerTitle="SELECCIÓN SIMPLE"
    >
      <>
        <div className='flex items-center mt-4 mb-4'>
          <InputSwitch checked={required} name="required" onChange={changeRequired} />
          <h5 className='ml-2'>Requerido</h5>
        </div>
        <Button
          type="button"
          label="Añadir"
          icon="pi pi-plus"
          className="sig-button sig-primary"
          onClick={addOption}
        />
        {
          options.map(option => (<div key={option.id} className='col-span-2 flex w-3/5 items-center mt-2'>
            <InputText
              value={option.name || ""}
              className='inline rounded-lg border-indigo-400 border-2 pt-2 pb-2 pl-2'
              onChange={changeNameOption(option.id)}
            />
            <i className='pi pi-trash block ml-2 cursor-pointer' style={{'fontSize': '1.5em'}} onClick={() => {deleteOption(option.id)}}/>
            </div>)
          )
        }
        {
          error.status &&
          <div className='message error'>
            {
              error.errors.map( message => <li key={message} className="p-error">{message}</li>)
            }
          </div>
        }
      </>
    </ControlGeneralConfiguration>
  )
}