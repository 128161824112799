import React, { useRef } from 'react';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { RiskInventoryAction } from 'features/riskManagement/store/actions/RiskInventoryAction';
import PinerComponent from 'shared/components/Piner/PinerComponent';
import { useClaimInventory } from 'features/riskManagement/hooks/useClaimInventory';

const FilterComponent = ({setShowModal}) => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector( state => state.riskInventoryReducer.downloadStatus)

  const { isLoading: isLoadingSearch } = useSelector( state => state.riskInventoryReducer.inventories)

  const { filter, values } = useSelector( state => state.riskInventoryReducer.advancedFilter)
  const advancedFilter = useSelector((state) => state.riskInventoryReducer.advancedFilter);

  const criteriaSearch = useRef()

  const download = () => {
    dispatch(RiskInventoryAction.download())
  }

  const { isDownload, isSearch } = useClaimInventory()

  const search = (e) => {
    e.preventDefault()
    const name = e.target.inputSearch.value 
    advancedFilter.page = 1;
    if(name !== "") dispatch(RiskInventoryAction.setFilter({filter: {name}}))
    else dispatch(RiskInventoryAction.setFilter({filter: {name: null}}))
    dispatch(RiskInventoryAction.getData())
  }

  const showPiners = () => {
    return values?.length > 0;
  }

  const removePiner = (field) => {
    advancedFilter.page = 1;
    switch (field) {
      case 'name':
        criteriaSearch.current.value = '';
        dispatch(RiskInventoryAction.setFilter({filter: {name: null}}))
        break;

      case 'from':
        dispatch(RiskInventoryAction.setFilter({filter: {from: null}}))
        break;
      
      case 'to':
        dispatch(RiskInventoryAction.setFilter({filter: {to: null}}))
        break;
    
      default:
        break;
    }

    dispatch(RiskInventoryAction.getData())
  }

	return (
    <Panel header="FILTRO POR" toggleable>
      <form className="filter-components" onSubmit={search}>
        <div>
            <div className={`w-full ${isSearch?'visible':'invisible'}`}>
                <span className="p-float-label p-input-icon-left w-full ">
                    <i className="pi pi-search" />
                    <InputText
                        id="inputSearch"
                        name="inputSearch"
                        ref={criteriaSearch}
                        // onKeyDown={handleKeyDown}
                    />
                    <label htmlFor="inputSearch">Buscar por inventario</label>
                </span>
                {showPiners() && (
                    <div className="filter-piners mt-3">
                        {values.map(({ value, field }, index) => (
                            <PinerComponent
                                name={value}
                                field={field}
                                removePiner={removePiner}
                                key={index}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>

        

            <div className="filter-users-action">
                <div className="flex gap-3 w-full">
                  {
                    isSearch&&
                      
                        <Button
                          loading={isLoading || isLoadingSearch}
                          icon="pi pi-search"
                          type="submit"
                          label="Buscar"
                          className="sig-button sig-primary w-full"
                        />
                      
                  }
                    {
                      isDownload&&
                        
                          <Button
                            loading={isLoading || isLoadingSearch}
                            icon="pi pi-download"
                            type="button"
                            label="Descargar"
                            className="sig-button sig-success w-full"
                            onClick={()=> download()}
                          />
                        
                    }
                </div>
                {
                  isSearch&&
                    <div className="flex mt-3">
                        <Button
                            loading={isLoading || isLoadingSearch}
                            type="button"
                            label="Búsqueda avanzada"
                            className="sig-button sig-secondary w-full"
                            onClick={()=>setShowModal(true)}
                        />
                    </div>
                }
            </div>
        
      </form>
    </Panel>
	);
};

export default FilterComponent;