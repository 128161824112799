import { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import SidebarComponent from './sidebar/SidebarComponent';
import MenuComponent from './menu/MenuComponent';
import HeaderComponent from './header/HeaderComponent';
import 'shared/styles/components/buttons.scss';
import './PrivateLayout.scss';
import { SharedService } from './services/SharedService';
import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from 'core/store/actions/ToastAction';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import FooterComponent from './footer/FooterComponent';

export default function PrivateLayout({ children }) {
  const toast = useRef(null);
  const message = useSelector((state) => state.toastReducer.toast);
  const isOpen = useSelector((state) => state.toggleSidebarReducer.isOpen);
  const [isLoading, setLoading] = useState(true);
  const [isFirstLoading, setFirstLoading] = useState(true);

  const location = useLocation()
  const dataSplit = location.pathname.split("/")
  dataSplit.pop()
  dataSplit.push("")
  const dataJoin = dataSplit.join("/")

  useEffect(() => {

    SharedService.getAppSettings().then(c => {
   
      if (c.data.Status === CoreConstants.HttpResponse.OK) {
        const colorSchema = c.data.Data.filter(c => { return c.Code === 'COLOR_SCHEMA' });
        const companyLogo = c.data.Data.filter(c => { return c.Code === 'COMPANY_LOGO' });

        if (colorSchema.length > 0) {
          const headerBackgroundColor = colorSchema[0].Details.filter(c => { return c.Code === 'BACKGROUND_COLOR' })[0];
          const menuDefault = colorSchema[0].Details.filter(c => { return c.Code === 'MENU_DEFAULT' })[0];
          const menuHover = colorSchema[0].Details.filter(c => { return c.Code === 'MENU_HOVER' })[0];
          const menuSelected = colorSchema[0].Details.filter(c => { return c.Code === 'MENU_SELECTED' })[0];

          const btnPrimary = colorSchema[0].Details.filter(c => { return c.Code === 'BUTTON_PRIMARY' })[0];
          const btnSeconday = colorSchema[0].Details.filter(c => { return c.Code === 'BUTTON_SECONDARY' })[0];
          const btnSuccess = colorSchema[0].Details.filter(c => { return c.Code === 'BUTTON_SUCCESS' })[0];
          const btnDark = colorSchema[0].Details.filter(c => { return c.Code === 'BUTTON_DARK' })[0];
          const btnError = colorSchema[0].Details.filter(c => { return c.Code === 'BUTTON_ERROR' })[0];
          const btnWarning = colorSchema[0].Details.filter(c => { return c.Code === 'BUTTON_WARNING' })[0];

          document.documentElement.style.setProperty('--sig-header-background-color', headerBackgroundColor.Value);
          document.documentElement.style.setProperty('--sig-menu-default', menuDefault.Value);
          document.documentElement.style.setProperty('--sig-menu-hover', menuHover.Value);
          document.documentElement.style.setProperty('--sig-menu-selected', menuSelected.Value);

          document.documentElement.style.setProperty('--sig-primary', btnPrimary.Value);
          document.documentElement.style.setProperty('--sig-secondary', btnSeconday.Value);
          document.documentElement.style.setProperty('--sig-success', btnSuccess.Value);
          document.documentElement.style.setProperty('--sig-dark', btnDark.Value);
          document.documentElement.style.setProperty('--sig-error', btnError.Value);
          document.documentElement.style.setProperty('--sig-warning', btnWarning.Value);
        }

        if (companyLogo.length > 0) {
          const logoDark = companyLogo[0].Details.filter(c => { return c.Code === 'LOGO_DARK' })[0];
          const logoDefault = companyLogo[0].Details.filter(c => { return c.Code === 'LOGO_DEFAULT' })[0];

          if (logoDefault.Value.length > 0)
            document.documentElement.style.setProperty('--sig-logo-default', 'url("' + logoDefault.Value + '")');
        }

      } else {
        toastAction.error('Error', 'Se produjo un error al cargar la configuración');
      }
      setLoading(false);
    }).catch(c => {     
      setLoading(false);
    })
  }, []);

  useEffect(() => {
    //debugger;
    if (message != null && message !== undefined && toast.current) toast.current.show(message);
  }, [message]);

  if (isLoading) {
    return <div className="img-container">
      <img src="loading.gif" width={'200px'} ></img>

    </div>;
  } else {
    return (
      <Fragment>
        <div className="private-layout">
          <MenuComponent />

          <main
            className={`private-layout-content ${isOpen ? 'closed-sidebar' : ''}`}
          >
            <SidebarComponent />
            <HeaderComponent />
            <div>
            {/* location.pathname==='/mapa-procesos/fichaProcesos' || */}
            {/* dataJoin==='/configuracion/procesos/' ||   */}
            <section className={`content scroll${(dataJoin==='/mapa-procesos/fichaProcesos/ver/' || dataJoin==='/mapa-procesos/fichaProcesos/editar/')?' w-9/12':''}${(dataJoin==='/configuracion/procesos/' || location.pathname==='/mapa-procesos/fichaProcesos')?' content-main2 2323':''}`} id={`content-main`}>
                {children}
              </section>
            </div>
          </main>
          <FooterComponent />
          <Toast ref={toast}></Toast>
        </div>
      </Fragment>
    );
  }

}
