/* eslint-disable import/no-anonymous-default-export */

import { CoursesManagmentConstants } from "features/coursesManagment/commons/CoursesManagmentConstants";

const initialState = {
  dataMaster: {
    isLoading: false,
    categories: [], 
    paymentModes: [], 
    types: [], 
    courses: [], 
  },
  courseExpense: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false,
    isUpdated: false,
  },
  courseExpenses: {
    data: [],
    isLoading: false,
    isLoaded: false,
  },
  comments: {
    data: [],
    isLoading: false,
  },
  comment: {
    isLoading: false,
    isSaved: false,
    isDeleted: false,
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'name',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {}
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CoursesManagmentConstants.Accion.CoursesExpenses.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
    case CoursesManagmentConstants.Accion.CoursesExpenses.UPDATE_FILTER:
      return {
        ...state,
        advancedFilter: state.advancedFilter
      };
    case CoursesManagmentConstants.Accion.CoursesExpenses.RESET_AFTER_SAVED:
      return {
          ...state,
          courseExpense: {
              data: null,
              isSaving: false,
              isSaved: false,
              isLoading: false,
              isDeleted: false,
              isUpdated: false,
          },
        };
    case CoursesManagmentConstants.Accion.CoursesExpenses.DOWNLOAD_REPORT_STATUS:
      return {
          ...state,
          courseExpense: {
              ...state,
              isLoading: action.payload.isLoading,
          },
      };
    case CoursesManagmentConstants.Accion.CoursesExpenses.SEARCH_COURSES_EXPENSES:
      return {
          ...state,
          courseExpenses: {
              isLoading: action.payload.isLoading,
              data: action.payload.data,
              isLoaded: action.payload.isLoaded,
          }
      }; 
    case CoursesManagmentConstants.Accion.CoursesExpenses.GET_COURSE_EXPENSE_BY_ID:
      return {
        ...state,
        courseExpense: {
            data: action.payload.data,
            isLoading: action.payload.isLoading
        }
      };
    case CoursesManagmentConstants.Accion.CoursesExpenses.SAVE_COURSE_EXPENSE:
      return {
        ...state,
        courseExpense: {
            isSaving: action.payload.isSaving,
            isSaved: action.payload.isSaved
        }
      };

    case CoursesManagmentConstants.Accion.CoursesExpenses.DELETE_COURSE_EXPENSE:
      return {
        ...state,
        courseExpense: {
            isDeleted: action.payload.isDeleted
        }
      };

    case CoursesManagmentConstants.Accion.CoursesExpenses.GET_DATA_MASTER:
      return {
        ...state,
        dataMaster: {
          isLoading: action.payload.isLoading,
          categories: action.payload.categories,
          paymentModes: action.payload.paymentModes,
          types: action.payload.types,
          courses: action.payload.courses,
        }
      };
    case CoursesManagmentConstants.Accion.CoursesExpenses.GET_COMMENTS_BY_COURSE_EXPENSE_ID:
      return {
          ...state,
          comments: {
            data: action.payload.data,
            isLoading: action.payload.isLoading
          },
      };
    case CoursesManagmentConstants.Accion.CoursesExpenses.DELETE_COMMENT:
      return {
          ...state,
          comment: {
            isDeleted: action.payload.isDeleted,
            isLoading: action.payload.isLoading,
          },
      };
    case CoursesManagmentConstants.Accion.CoursesExpenses.ADD_COMMENT:
      return {
          ...state,
          comment: {
            isSaved: action.payload.isSaved,
            isLoading: action.payload.isLoading,
          },
      };
    default: 
    return state;
  }

}