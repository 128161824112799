/* eslint-disable import/no-anonymous-default-export */
import { ProcessManagementConstans } from "features/processManagement/commons/ProcessManagementConstans"; 

const initialState = {
  dataMaster: {
    isLoading: false,
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
      case ProcessManagementConstans.Accion.ProcessDataMaster.GET_DATA_MASTER:
          return {
              ...state,
              dataMaster: {
                  ...state.dataMaster,
                  ...action.payload,
              }
          };
      default: 
      return state;
  }
}
