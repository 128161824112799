import { apiService } from "core/services/ApiService";

const searchInstitutions = (criterio) => {
  return apiService.Post("customer/api/AcademicInstitution/Search", criterio);
}

const saveInstitutions = (data) => {
  return apiService.Post("customer/api/AcademicInstitution/Save", data);
}

const getInstitutionById = (institutionId) => {
  return apiService.Get("customer/api/AcademicInstitution/GetAcademicInstitutionById?id=" + institutionId);
}

const generateReport = (payload) => {
  return apiService.Post("customer/api/AcademicInstitution/GenerateReport", payload, {
    responseType: 'blob',
  })
}

const deleteInstitution = (institutionId) => {
  return apiService.Delete("customer/api/AcademicInstitution/Delete?id=" + institutionId);
}

const getDataCountries = () => {
  return apiService.Post("customer/api/AcademicInstitution/GetDataMaster");
}

export const InstitutionsService = {
  searchInstitutions,
  saveInstitutions,
  getInstitutionById,
  generateReport,
  deleteInstitution,
  getDataCountries
};