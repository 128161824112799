import Modal from 'features/configuration/components/processes/Formulario/Modal'
import { Modal2 } from 'features/configuration/components/processes/Formulario/Modal2'
import useProceseSheet from 'features/processManagement/hooks/useProceseSheet'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Panel } from 'primereact/panel'
import React, { useEffect, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { v4 as uuidv4 } from 'uuid';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { PrevisualizationRender } from 'features/configuration/components/processes/Formulario/controllers/PrevisualizationRender'
import { Menu } from 'primereact/menu'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { CoreConstants } from 'core/commons/CoreConstants'
import { Skeleton } from 'primereact/skeleton'
import '../ProcessDownload.scss'
import { Tooltip } from 'primereact/tooltip'
import { ModalGenerateVersion } from './ModalGenerateVersion'
import { useDispatch, useSelector } from 'react-redux'
import { ProcesseSheetActions } from 'features/processManagement/store/actions/ProcesseSheetActions'
import { ComponentFormularyCustom } from './ComponentFormularyCustom'
import { VersionHistory } from 'shared/components/processManagement/VersionHistory'
import { Approvers } from 'shared/components/processManagement/Approvers'
import { ProcesseSheetServices } from 'features/processManagement/services/ProcesseSheetServices'
import { useClaimProcessSheet } from 'features/processManagement/hooks/useClaimProcessSheet'

export const NewProceseSheet = ({modeview, disabled, lastVersion, dinamicStage, setDinamicStage, control, errors, valuesFields, setValue, listOptions, listStages, setListStage, positionSoruce, setPositionSource, typeModal, setTypeModal, displayModal, setDisplayModal, optionSelected, setOptionSelected, inputSelected, setInputSelected, stateConfigPage, setStateConfigPage, stateInsufficient, setDestinationControl, destinationControl, producedBy, setStateInsufficient, title, children}) => {

  // const { control, errors, valuesFields, existError, setValue, handleSubmit, validateLength} = useProceseSheet()

  const { isSaving, isSaved } = useSelector(state => state.processeSheetReducer.currentVersion)
  const listVersions = useSelector(state => state.processeSheetReducer.listVersions.data)
  const processSheet = useSelector(state => state.processeSheetReducer.processSheet)

  const [showConfirmDialog, setshowConfirmDialog] = useState(false);
  const [idDeleteStage, setIdDeleteStage] = useState(null)
  const [historyVersions, setHistoryVersions] = useState([])
  const [loadingPlantilla, setLoadingPlantilla] = useState(false)

  const menu = useRef(null);

  const { isGenerateVersion} = useClaimProcessSheet();

  const [visibleModalVersion, setVisibleModalVersion] = useState(false)

  const dispatch = useDispatch()

  //states ExtraModal
  const [displayExtraModal, setDisplayExtraModal] = useState({title: '', visible: false, mode: "nuevo"})
  const [dataExtraModal, setDataExtraModal] = useState({
    fieldLabel: '',
    typeOptionId: null,
    options: [],
    required: false,
    stageId: null
  })

  const getListStyleOptions = (isDraggingOver) => ({
    border: isDraggingOver ? '1px dashed rgb(75 85 99)' : 'none',
    padding: '10px',
    margin: '0 0 0 0'
  });

  const getListStyleZone = (isDraggingOver, otro) => ({
    background: isDraggingOver ? "rgb(191 219 254)" : "white",
    border: isDraggingOver ? '1px dashed rgb(75 85 99)' : 'none',
    // borderTop: isDraggingOver ? 'none' : '1px solid rgb(75 85 99)',
    margin: '0 0 0 0'
  });

  const getItemStyleZone = (isDragging, draggableStyle) => ({
    // userSelect: "none",
    margin: `0px 0 0px 0`,
    // background: isDragging ? "red" : "blue",
    // border: isDragging ? '1px dashed rgb(75 85 99)' : 'none',
    ...draggableStyle
  });

  //Reordenar los controles
  const reorder = (list, startIndex, endIndex) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) return
    if ( source.index === destination.index && source.droppableId === destination.droppableId ) return
    if ((listStages.includes(source.droppableId)) && destination.droppableId === "items-menu") return
    if ( source.droppableId === destination.droppableId && (listStages.includes(source.droppableId))) {
      const clone = (([...dinamicStage]).map(elem => {
        if(elem.id===source.droppableId){
          elem.dinamicInput = reorder(elem.dinamicInput, source.index, destination.index)
        }
        return elem
      }))
      setDinamicStage(clone)
      // setDinamicInput((prevInputs) => reorder(prevInputs, source.index, destination.index) )
      return
    }
    // if (!destination) return
    // if ( source.index === destination.index && source.droppableId === destination.droppableId ) return
    // if (source.droppableId === "items-drop" && destination.droppableId === "items-menu") return
    // if ( source.droppableId === destination.droppableId && source.droppableId === "items-drop"  ) {
    //   setDinamicInput((prevInputs) => reorder(prevInputs, source.index, destination.index) )
    //   return
    // }

    setPositionSource(destination.droppableId)

    if (source.droppableId === "items-menu" && (listStages.includes(destination.droppableId))) {
      const idsModal1 = ['ID','TEXTO','TEXTO_LARGO','TEXTO_DINAMICO','NUMERO','FECHA','FECHA_HORA','IMAGEN','ADJUNTAR_ARCHIVO','ETIQUETA']
      const idsModal2 = ['LISTA_DESPLEGABLE','SELECCION_MULTIPLE','SELECCION_SIMPLE','TABLA','MONEDA']
      setDestinationControl(destination.index)
      setStateInsufficient('')
      if (idsModal1.includes(draggableId)) {
        setInputSelected(null)
        setDisplayModal(true)
        setOptionSelected(draggableId)
        return
      }else if (idsModal2.includes(draggableId)) {
        setDataExtraModal({
              ...dataExtraModal,
              typeOptionId: draggableId
        })
        // setOptionSelected(draggableId)
        setDisplayExtraModal({ title: draggableId, visible: true, mode: "nuevo"})
        return
      }
    }
  };

  const items = [
    {
        label: 'Editar',
        icon: 'pi pi-pencil',
        command: (e) => {
            // setDisplayExtraModal({ title: '', visible: true, mode: "editar"})
            if(typeModal===1){
              setTypeModal(1)
              setDisplayModal(true)
            }else if(typeModal===2){
              setTypeModal(2)
              setDisplayExtraModal({ title: '', visible: true, mode: "editar"})
            }
            // history.push('/mantenimiento/institucion/' + institutionSelected.id);
        }
    },
    {
        label: 'Eliminar',
        icon: 'pi pi-times',
        command: (e) => {
            setshowConfirmDialog(true);
        }
    }
  ];

  const confirmDelete = () => {
    const dataFiltered = (([...dinamicStage]).map(elem=> {
      if(elem.id===inputSelected.stageId){
        const newDinput = elem.dinamicInput.filter(el=> el.id!==inputSelected.id)
        elem.dinamicInput= newDinput
      }
      return elem
    }))

    let simpleFilters = []
    let advanceFilters = []
    let searchColumns = []

    if(stateConfigPage.simpleFilters?.length){
        simpleFilters = stateConfigPage.simpleFilters.filter(el => el!==inputSelected.identifier)
    }
    if(stateConfigPage.advanceFilters?.length){
      advanceFilters = stateConfigPage.advanceFilters.filter(el => el!==inputSelected.identifier)
    }

    if(stateConfigPage.searchColumns?.length){
      searchColumns = stateConfigPage.searchColumns.filter(el => el!==inputSelected.identifier)
    }

    const newDataConfig = {simpleFilters, advanceFilters, searchColumns}
    setInputSelected(null)
    setStateConfigPage(newDataConfig)
    setDinamicStage(dataFiltered)
  }

  const cancelDelete = () => {
    setshowConfirmDialog(false);
}

// Agregar fila en tipo tabla
const addRows = (index, input) => {
  const clone = [...dinamicStage]
  clone.map(elem => {
    if(elem.id===input.stageId){
      elem.dinamicInput.map(el => {
        if(el.id===input.id){
          el.numRows.push('')
        }
        return el
      })
    }
    return elem
  })
  setDinamicStage(clone)
}

// Quitar fila en tipo tabla
const dropRows = (index, input) => {
  const clone = [...dinamicStage]
  clone.map(elem => {
    if(elem.id===input.stageId){
      elem.dinamicInput.map(el => {
        if(el.id===input.id){
          if(el.numRows.length>1){
            el.numRows.pop()
          }
        }
        return el
      })
    }
    return elem
  })
  setDinamicStage(clone)
}

const addNewStage = () => {
  const newId = uuidv4()
  setDinamicStage([...dinamicStage, {
    id: newId,
    mode: "new",
    text: "",
    dinamicInput: []
  }])
  setListStage([...listStages, newId])
}

const onChangeNameStage = (e, index) => {
  const clone = (([...dinamicStage]).map(el=> {
    if(el.id===index){
      el.text=e.target.value
    }
    return el
  }))
  setDinamicStage(clone)
}

const cancelDeleteStage = () => {
  setIdDeleteStage(null)
}

const confirmDeleteStage = () => {
    const clone = ((([...dinamicStage]).filter(el=> el.id!==idDeleteStage)))
    setDinamicStage(clone)
}

useEffect(()=>{
  if(isSaved===true){
    setVisibleModalVersion(false)
    dispatch(ProcesseSheetActions.getLastSheet())
  }
},[isSaved])

useEffect(()=>{
  setLoadingPlantilla(processSheet.isLoading)
},[processSheet.isLoading])

useEffect(()=>{
  if(processSheet?.data?.processId && listVersions){
    ProcesseSheetServices.getVersionsDetailedList(processSheet.data.processId, listVersions.find(elem => elem.sheetId===processSheet.data.processId).versionNumber
    ).then(({data})=>{
      setHistoryVersions(data.data)
    })
  }
},[processSheet, listVersions])

const onSubmitValue = (value) => {
  dispatch(ProcesseSheetActions.generateVersion({"reason": value}))
}

const template = (options) => {
  const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
  const className = `${options.className} justify-content-start`;

  return (
      <div className={className}>
          <div className='flex w-full justify-between items-center'>
            <div className='w-4/5 lg:w-4/5 xl:w-3/5'>
              <InputText value={(dinamicStage[options.props.positionP].text)} onChange={(e) => {onChangeNameStage(e,options.props.valueID)}} className={`bg-transparent border-0`} placeholder="Click aquí para escribir"/>
            </div>
            <div className='flex items-center w-16 justify-between'>
              <div>
                <i className="pi pi-trash" onClick={()=> {setIdDeleteStage(options.props.valueID)}}></i>
              </div>
              <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                  <span className={toggleIcon}></span>
              </button>
            </div>
          </div>
      </div>
  )
}
  return (
    <div className='flex gap-x-4'>
      <DragDropContext onDragEnd={(result) => onDragEnd(result) } >
      {
    !disabled&&(
      <div className='listControls1'>
        <div className='ml-3 mb-3 hidden lg:block'><p><strong>Tipos de Campos</strong></p><p className='text-sm text-gray-400'>Arrastre para agregar el campo en el formulario</p></div>
          <div className='overflow-auto scroll w-max'>
                    {
                      listOptions?(<>
                        <Droppable droppableId='items-menu' direction="vertical">
                            {
                              (droppableProvided, snapshot) => (
                                <div 
                                  ref={droppableProvided.innerRef}
                                  style={getListStyleOptions(snapshot.isDraggingOver)}
                                  {...droppableProvided.droppableProps}
                                  className='grid grid-cols-1 gap-y-2 md:gap-2 items-center bg-blue-200 lg:bg-white'  
                                  >
                                    {
                                      listOptions?.map(({code, icon, name}, index) => (
                                        <Draggable 
                                            key={`drag-${code}`}
                                            draggableId={`${code}`}
                                            index={index}
                                        >
                                          {
                                            (draggableProvided, snapshot) => (
                                              <>
                                              <Tooltip target=".customClassName" mouseTrack mouseTrackLeft={10} className="lg:hidden"/>
                                              <div 
                                                ref={draggableProvided.innerRef}
                                                {...draggableProvided.draggableProps} 
                                                {...draggableProvided.dragHandleProps}
                                                // style={getItemStyleOptions(
                                                //   snapshot.isDragging,
                                                //   draggableProvided.draggableProps.style
                                                // )}
                                                data-pr-tooltip={name} data-pr-position="top"
                                                className='customClassName border-2 border-stone-700 flex justify-center md:justify-start items-center h-10 w-10 md:h-12 md:w-12 lg:w-full lg:rounded-lg hover:bg-gray-200 lg:bg-white'
                                              >
                                                  <i className={`${icon} ml-4 mr-4 lg:mr-1`} style={{color: "#43A1F3"}} ></i>
                                                  <p className='hidden lg:block text-center text-xs lg:text-xs lg:text-left'>{name}</p>
                                              </div></>
                                            )
                                          }
                                        </Draggable>
                                      ))
                                    }
                                    {droppableProvided.placeholder}
                                </div>
                              )
                          }
                        </Droppable>
                      </>):<Skeleton width="100%" height="150px" />
                    }
          </div>
      </div>
    )
  }
      {/* ${disabled ? 'col-span-12':'col-span-4'} */}
      <div id='plantilla-ficha-procesos' style={{width: '-webkit-fill-available'}} className={`grid grid-cols-12 gap-2 ${!disabled && 'content-div scroll'}`} >
        <Panel header="PLANTILLA" toggleable className='col-span-12 md:col-span-8'>
                {
                  loadingPlantilla?<Skeleton width="100%" height="30rem" />:<>
                  {
                    dinamicStage?.length!==0?(
                      <>
                      {
                            dinamicStage?.map((elem, indiceP)=> {
                              return (
                              // <Panel key={elem.id} headerTemplate={template} positionP={indiceP} valueID={elem.id} toggleable className='mt-1 mb-1'>
                                <div key={elem.id} className='w-full'>
                                  <Droppable droppableId={elem.id} direction='vertical' className='w-full'>
                                      {
                                      (droppableProvided, snapshot) => (
                                        <div 
                                          {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}  
                                          style={getListStyleZone(snapshot.isDraggingOver, snapshot.isUsingPlaceholder)}
                                          className='flex flex-col items-center rounded-lg gap-y-4 max-auto py-3 border-t border-gray-600 bg-red-500'
                                        >
                                            {
                                              elem?.dinamicInput.length > 0 ? null
                                              : (
                                                <>
                                                <div className='flex items-center justify-center lg:h-8 xl:h-10 border-2 border-gray-300 w-full border-dashed'>
                                                  <p className='text-center'><i className="pi pi-plus mr-1 text-sm"></i>Arrastre aqui para añadir campos</p>
                                                </div>
                                                </>
                                              )
                                            }
                                            {
                                              elem?.dinamicInput.map((input, index) => (
                                                <ComponentFormularyCustom input={input}
                                                  key={index}
                                                  index={index}
                                                  getItemStyleZone={getItemStyleZone}
                                                  setInputSelected={setInputSelected}
                                                  menu={menu}
                                                  setTypeModal={setTypeModal}
                                                  dropRows={dropRows}
                                                  addRows={addRows}
                                                  disabled={disabled}
                                                  />
                                              ))
                                            }
                                            {droppableProvided.placeholder}
                                        </div>
                                        )
                                      }
                                  </Droppable>
                                </div>)
                            // </Panel>)
                            })
                          }
                      </>
                    ):<Skeleton width="100%" height="30rem" />
                  }
                  </>
                }
              <div className={`rounded bg-red-200 p-5 mt-6 text-sm text-red-600 ${stateInsufficient===''&&'hidden'}`}>
                <ul>
                  <li>{stateInsufficient}</li>
                </ul>
              </div>
        </Panel>
        <Panel header="DATOS GENERALES" className='h-full col-span-12 md:col-span-4'>
              {
                processSheet.data&&<div><p><strong>Estado: {processSheet.data.statusName}</strong></p></div>
              }
              <div></div>
              <span className="p-float-label w-full mt-2">
                        <p className='text-sm'>Fecha de Elaboración *</p>
                        <Controller
                            control={control}
                            render={({
                                field: { onChange, value },
                            }) => (
                              <Calendar
                              value={valuesFields.dateElaboration!== null&&new Date(valuesFields.dateElaboration)}
                              onChange={onChange}
                              disabled={disabled}
                              className={`${errors?.dateElaboration ? 'p-invalid w-full' : 'w-full'}`}
                              showIcon />
                            )}
                            name="dateElaboration"
                            rules={{
                                required: 'La fecha de elaboración es requerida.',
                            }}
                        />
              </span>
              <span className="p-float-label w-full mt-4">
                        <p className='text-sm'>Elaborado Por *</p>
                        <Controller
                            control={control}
                            render={({
                                field: { onChange, onBlur, value },
                                fieldState: { invalid, isTouched, isDirty, error },
                            }) => (
                              <Dropdown
                                optionLabel='fullName'
                                value={producedBy?.find(elem => elem.id===value)}
                                options={producedBy}
                                onChange={(e) => { onChange(e.value.id)}}
                                disabled={disabled}
                                className={`${errors?.producedBy ? 'p-invalid w-full' : 'w-full'}`}
                              />
                            )}
                            name="producedBy"
                            rules={{
                                required: 'Encargado de la elaboración es requerido.',
                            }}
                        />
              </span>
              <span className="p-float-label w-full mt-4">
                <p className='text-sm'>Ultima Actualización</p>
                <Controller
                    control={control}
                    render={({
                        field: { onChange, onBlur, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                    }) => (
                      <Calendar id="lastUpdate"
                      name='lastUpdate'
                      value={valuesFields.lastUpdate!== null&&new Date(valuesFields.lastUpdate)}
                      onChange={onChange}
                      disabled
                      className={errors?.lastUpdate ? 'p-invalid w-full disabled' : 'w-full disabled'}
                      />
                    )}
                    name="lastUpdate"
                    // rules={{
                    //     required: 'not have last update',
                    // }}
                />
                </span>
                <span className="p-float-label w-full mt-4">
                        <p className='text-sm'>Actualizado Por</p>
                        <Controller
                            control={control}
                            render={({
                                field: { onChange, onBlur, value },
                                fieldState: { invalid, isTouched, isDirty, error },
                            }) => (
                              <InputText
                                  type="text"
                                  id="updateBy"
                                  name="updateBy"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                  className={errors?.updateBy ? 'p-invalid w-full disabled' : 'w-full disabled'}
                                  disabled
                              />
                            )}
                            name="updateBy"
                        />
              </span>
              <span className="p-float-label w-full mt-4">
                        <p className='text-sm'>Versión</p>
                        <Controller
                            control={control}
                            render={({
                                field: { onChange, onBlur, value },
                                fieldState: { invalid, isTouched, isDirty, error },
                            }) => (
                              <Dropdown
                                optionLabel="versionNumber"
                                value={listVersions?listVersions?.find(elem => elem?.sheetId===value):null}
                                options={listVersions?listVersions:null}
                                onChange={(e) => {
                                  onChange(e.value.sheetId)
                                  dispatch(ProcesseSheetActions.getProcessSheetByVersion(e.value.sheetId))
                                }}
                                disabled={!listVersions || disabled}
                              />
                            )}
                            name="version"
                            // rules={{
                            //     required: 'not have creation date',
                            // }}
                        />
              </span>
              {
                (((processSheet.data?.statusName.toLowerCase())==="publicado") && modeview===false)&&(
                  <span className="flex justify-center w-full mt-6">
                    {
                      isGenerateVersion&&(
                        <Button
                        label='Generar Versión'
                        type='button'
                        // disabled={processSheet.data?.isReadOnly || processSheet.data===null}
                        className='sig-button sig-primary'
                        loading={processSheet.isLoading}
                        onClick={(e)=> {
                          setVisibleModalVersion({
                            visible: true,
                            value: valuesFields.version
                            })
                        }}
                        />
                      )
                    }
              </span>
                )
              }
              <span className="p-float-label w-full mt-10">
                <p className='underline font-bold mb-3'>APROBADORES</p>
                <Approvers approvers={processSheet?.approvers}/>
                {/* <div className='grid grid-cols-4 gap-y-3'>
                  <i className='pi pi-user col-span-1 text-xs'/>
                  <div className='col-span-3 text-sm'>
                    <p><b>REVISÓ:</b> 12/03/2022</p>
                    <p>Info Calidar - Alfonso Guevara</p>
                  </div>
                  <i className='pi pi-user col-span-1 text-xs'/>
                  <div className='col-span-3 text-sm'>
                    <p><b>APROBÓ:</b> 17/03/2022</p>
                    <p>Empresa x - Molder</p>
                  </div>
                </div> */}
              </span>
        </Panel>
        {
          historyVersions.length > 0 &&
          <VersionHistory className='h-full col-span-12 md:col-span-8' historyVersions={historyVersions}/>
        }
        <div className="col-span-12">
        {children}
        </div>
      </div>
  
  <Modal2
        positionSoruce={positionSoruce}
        dinamicStage={dinamicStage}
        setDinamicStage={setDinamicStage}
        displayExtraModal={displayExtraModal}
        setDisplayExtraModal={setDisplayExtraModal}
        dataExtraModal={dataExtraModal}
        setDataExtraModal={setDataExtraModal}
        inputSelected={inputSelected}
        setInputSelected={setInputSelected}
        listOptions={listOptions}
        destinationControl={destinationControl}
      />
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <ConfirmDialog
        visible={showConfirmDialog}
        onHide={cancelDelete}
        message="¿Está seguro que desea eliminar el registro?"
        header="Eliminar"
        icon="pi pi-trash" onSubmitValue
        accept={confirmDelete}
        reject={cancelDelete}
    />
    <ConfirmDialog
        visible={idDeleteStage}
        onHide={cancelDelete}
        message="¿Está seguro que desea eliminar la etapa?"
        header="Eliminar"
        icon="pi pi-trash" 
        accept={confirmDeleteStage}
        reject={cancelDeleteStage}
    />
    <ModalGenerateVersion visibleModalVersion={visibleModalVersion} setVisibleModalVersion={setVisibleModalVersion} onSubmitValue={onSubmitValue} isLoading={isSaving}/>
  </DragDropContext>
  </div>
  )
}
