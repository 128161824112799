
import moment from 'moment';
import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from "core/store/actions/ToastAction";
import { CoursesManagmentConstants } from 'features/coursesManagment/commons/CoursesManagmentConstants';
import { CoursesExpensesService } from 'features/coursesManagment/services/CoursesExpensesServices';
import FileSaver from 'file-saver';

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: CoursesManagmentConstants.Accion.CoursesExpenses.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: CoursesManagmentConstants.Accion.CoursesExpenses.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.name) pines.push({ field: 'name', value: advancedFilter.filter.name });
    if (advancedFilter.filter.category) pines.push({ field: 'category', value: advancedFilter.filter.category.description });
   
    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });

    advancedFilter.values = pines;

    dispatch({
      type: CoursesManagmentConstants.Accion.CoursesExpenses.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setCourses = ({ dispatch, isLoading, data, isLoaded }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.CoursesExpenses.SEARCH_COURSES_EXPENSES,
      payload: { isLoading, data, isLoaded },
    });
  };

  return (dispatch, getState) => {
    const criteria = {};
    const filter = getState().courseExpensesReducer.advancedFilter.filter;

    criteria.to = filter.to;
    criteria.from = filter.from;
    criteria.courseName = filter.name;
    criteria.category = filter.category?.id;

    setCourses({ dispatch: dispatch, isLoading: true, data: [], isLoaded: false });

    CoursesExpensesService.searchCourseExpenses(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setCourses({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
            isLoaded: true
          });
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Gastos del Curso', data.message));
          setCourses({ dispatch: dispatch, isLoading: false, data: [], isLoaded: false });
        }else{
          setCourses({ dispatch: dispatch, isLoading: false, data: [], isLoaded: false });
          dispatch(toastAction.error('Gastos del Curso', data.message));
        }
      },
    ).catch((error)=>{
    });
  };
};


const generateReport = () => {
  const setCourses = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.CoursesExpenses.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setCourses({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().courseExpensesReducer.advancedFilter.filter;

    criteria.from = filter.from;
    criteria.to = filter.to;
    criteria.courseName = filter.name;
    criteria.category = filter.category?.id;

    CoursesExpensesService.generateReport(criteria)
      .then(({ data }) => {
        setCourses({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Gastos del Curso.xlsx');
      })
      .catch((err) => {
        setCourses({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Gastos del Curso', 'Hubo un error al descargar el archivo'));
      });
  };
};

const saveCourseExpense = (course) => {
  const setCourse = ({ dispatch, isSaving, isSaved,  }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.CoursesExpenses.SAVE_COURSE_EXPENSE,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch) => {

    setCourse({ dispatch: dispatch, isSaving: true, isSaved: false });
    CoursesExpensesService.saveCourseExpense(course).then(
      ( {data} ) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gastos del Curso', 'El gasto del curso se guardó correctamente'));
          setCourse({ dispatch: dispatch, isSaving: false, isSaved: true });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Gastos del Curso', data.message));
          setCourse({ dispatch: dispatch, isSaving: false, isSaved: false });
        }else{
          dispatch(toastAction.error('Gastos del Curso', data.message));
          setCourse({ dispatch: dispatch, isSaving: false, isSaved: false });
        }
      },
      () => {
        setCourse({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};

const getCourseExpenseById = (courseId) => {
  const setCourse = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.CoursesExpenses.GET_COURSE_EXPENSE_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setCourse({ dispatch: dispatch, isLoading: true, data: null });
    CoursesExpensesService.getCourseExpenseById(courseId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setCourse({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setCourse({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Gastos del Curso', data.message));
        }else{
          setCourse({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Gastos del Curso', data.message));
        }
      },
      () => {
        setCourse({ dispatch: dispatch, isLoading: false, data: null });
      }
    ).catch((error)=>{
    });
  };
};

const deleteCourseExpense = (id) => {
  return (dispatch) => {
    CoursesExpensesService.deleteCourseExpense(id).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Gastos del Curso', 'El gasto del curso se eliminó correctamente'));
          dispatch({ type: CoursesManagmentConstants.Accion.CoursesExpenses.DELETE_COURSE_EXPENSE, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Gastos del Curso', data.message));
          dispatch({ type: CoursesManagmentConstants.Accion.CoursesExpenses.DELETE_COURSE_EXPENSE, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: CoursesManagmentConstants.Accion.CoursesExpenses.DELETE_COURSE_EXPENSE, payload: { isDeleted: false } });
      }
    );
  };
};

const getDataMaster = () => {
  const setDataMaster = ({ dispatch, isLoading, categories, paymentModes, types, courses }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.CoursesExpenses.GET_DATA_MASTER,
      payload: { isLoading, categories, paymentModes, types, courses },
    });
  };

  return (dispatch) => {
    setDataMaster({ dispatch: dispatch, isLoading: true, categories: [], paymentModes: [], types: [], courses: []});

    CoursesExpensesService.getDataMaster().then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            categories: data.data.courseExpenseCategories,
            paymentModes: data.data.courseExpensePaymentModes,
            types: data.data.courseExpenseTypes,
            courses: data.data.courses,
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            categories: [], paymentModes: [], types: [], courses: []
          });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            categories: [], paymentModes: [], types: [], courses: []
          });
          dispatch(toastAction.warn('Gastos del Curso', data.message));
        }
      },
      () => {
        setDataMaster({ dispatch: dispatch, isLoading: false, categories: [], paymentModes: [], types: [], courses: [] });
      }
    );
  };
};

const getCommentsById = (id) => {
  const setComments = ({ dispatch, isLoading, data  }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.CoursesExpenses.GET_COMMENTS_BY_COURSE_EXPENSE_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setComments({ dispatch: dispatch, isLoading: true, data: [] })
    CoursesExpensesService.getCommentsByCourseExpenseId(id)
    .then(({ data }) => {
      if (data.Status === CoreConstants.HttpResponse.OK) {
          // setComments({ dispatch: dispatch, isLoading: true, data: [] })
          data.Data.forEach(result =>{
            result.CreationDate =  moment(result.CreationDate).format(CoreConstants.Format.DDMMYYYYhhmmss)
          })

          setComments({ dispatch: dispatch, isLoading: false, data: data.Data })
          return;
        }
      })
      .catch(console.error)
      .finally(() => {
        setComments({ dispatch: dispatch, isLoading: false, data: [] })
      });
  };
}

const addComment = (sourceCode, documentId, comment) => {
  const setComments = ({ dispatch, isSaved, isLoading  }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.CoursesExpenses.ADD_COMMENT,
      payload: { isSaved, isLoading },
    });
  };
  return (dispatch) => {
    const payload = { sourceCode, documentId, comments: comment}

    setComments({ dispatch: dispatch, isSaved: false, isLoading: true });
    CoursesExpensesService.addComment(payload)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          setComments({ dispatch: dispatch, isSaved: true, isLoading: false });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setComments({ dispatch: dispatch, isSaved: false, isLoading: false });
      });
  };
}

const deleteComment = (commentId) => {
  const setComments = ({ dispatch, isDeleted  }) => {
    dispatch({
      type: CoursesManagmentConstants.Accion.CoursesExpenses.DELETE_COMMENT,
      payload: { isDeleted },
    });
  };

  return (dispatch) => {
    CoursesExpensesService.deleteComment(commentId)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          setComments({ dispatch: dispatch, isDeleted: true });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setComments({ dispatch: dispatch, isDeleted: false });
      });
  };
}

export const CoursesExpensesAction = {
  toggleAdvancedFilter,
  updateFilter,
  resetAfterSaved,

  //* Actions Implementadas
  search,
  generateReport,
  deleteCourseExpense,
  getDataMaster,
  getCourseExpenseById,
  saveCourseExpense,
  getCommentsById,
  addComment,
  deleteComment,
  
  //* Actions sin Implementar
}