import React, { useEffect, useState } from 'react'
import { SurveyAction } from 'features/survey/store/actions/SurveyAction';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { Divider } from 'primereact/divider';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'primereact/skeleton';
import moment from 'moment';
import { CoreConstants } from 'core/commons/CoreConstants';

const SurveyAnalysisModalAnswer = ({displayModal, setDisplayModal, surveyTitle}) => {

  const params = useParams();
  const dispatch = useDispatch();
  const { data: dataSurveyQuestion } = useSelector((state) => state.surveyReducer.surveyQuestionInfo);
  const { data: surveyDataAnswers } = useSelector((state) => state.surveyReducer.surveyDataAnswers);

  useEffect(() => {
    if (params.id && displayModal) {
      const model = {
        surveyId: Number(params.id),
      }
      dispatch(SurveyAction.getSurveyInformationById(model));
      dispatch(SurveyAction.getSurveyAnswerPerQuestion(model));
    }
  }, [params.id, dispatch, displayModal]);

  const onHide = () => {
    setDisplayModal(false)
  }

  const onDownloadSurveyTrendInfo = () => {
    // {
    //   "locationId": 0,
    //   "from": "2022-09-12T17:00:49.419Z",
    //   "to": "2022-09-12T17:00:49.419Z",
    //   "title": "string",
    //   "ownerId": 0,
    //   "statusId": 0
    // }
    // console.log(dataSurveyQuestion.id)
    dispatch(SurveyAction.generateSurveyAnswerPerQuestionReport(dataSurveyQuestion.id));
  }

  const onPreviosQuestion = () => {
    const model = {
        surveyId: Number(params.id),
        activityId: dataSurveyQuestion.id,
        previous: true,
        next: false
      }
    dispatch(SurveyAction.getSurveyInformationById(model));
    dispatch(SurveyAction.getSurveyAnswerPerQuestion(model));
  }

  const onNextQuestion = () => {
    const model = {
        surveyId: Number(params.id),
        activityId: dataSurveyQuestion.id,
        previous: false,
        next: true
      }
    dispatch(SurveyAction.getSurveyInformationById(model));
    dispatch(SurveyAction.getSurveyAnswerPerQuestion(model));
  }

  return (
    <Dialog header='Respuestas Individuales' visible={displayModal} className='w-4/5 lg:w-3/5' onHide={onHide}>
      <Divider />
      <div className='grid grid-cols-12 gap-x-4'>
        <div className='col-span-10 flex flex-col gap-y-0'>
          <h1 className='font-semibold text-lg'>{surveyTitle}</h1>
          <Divider />
          <div className='flex flex-col gap-y-2'>
            <div className='flex justify-end items-center gap-x-2'>
              <Button
                type="button"
                className='sig-button sig-primary' 
                label="Anterior" 
                onClick={onPreviosQuestion}
              />
              <Button
                type="button"
                className='sig-button sig-primary' 
                label="Siguiente" 
                onClick={onNextQuestion}
              />
            </div>
            <div className='flex flex-col'>
              {
                !dataSurveyQuestion ? <Skeleton height='1.3rem' /> : (
                  <div className='flex gap-x-2'>
                    <h1 className='font-semibold'>Fecha:</h1>                
                    <p className=''>{moment(dataSurveyQuestion.date).format(CoreConstants.Format.LOCALE)}</p>                
                  </div>
                )
              }
              {
                !dataSurveyQuestion ? <Skeleton height='1.3rem' className='mt-2' /> : (
                  <div className='flex gap-x-2'>
                    <h1 className='font-semibold'>Hora Inicio:</h1>                
                    <p className=''>{dataSurveyQuestion.beginTime}</p>                
                  </div>
                )
              }
              {
                !dataSurveyQuestion ? <Skeleton height='1.3rem' className='mt-2' /> : (
                  <div className='flex gap-x-2'>
                    <h1 className='font-semibold'>Hora Fin:</h1>                
                    <p className=''>{dataSurveyQuestion.endTime}</p>                
                  </div>
                )
              }
              {
                !dataSurveyQuestion ? <Skeleton height='1.3rem' className='mt-2' /> : (
                  <div className='flex gap-x-2'>
                    <h1 className='font-semibold'>Dirección IP:</h1>                
                    <p className=''>{dataSurveyQuestion.ipAddress}</p>                
                  </div>
                )
              }
              {
                !dataSurveyQuestion ? <Skeleton height='1.3rem' className='mt-2' /> : (
                  <div className='flex gap-x-2'>
                    <h1 className='font-semibold'>Ubicación:</h1>                
                    <p className=''>{dataSurveyQuestion.location}</p>                               
                  </div>
                )
              }
              {
                !dataSurveyQuestion ? <Skeleton height='1.3rem' className='mt-2' /> : (
                  <div className='flex gap-x-2'>
                    <h1 className='font-semibold'>Recopilador:</h1>                
                    <p className=''>{dataSurveyQuestion.source}</p>                
                  </div>
                )
              }
            </div>
          </div>
          {/* PREGUNTAS */}
          <div className='flex flex-col'>
            {
              surveyDataAnswers?.map((question) => (
                <>
                  <Divider />
                  <div className='flex flex-col gap-y-2'>
                    {(question.sectionTitle && question.sectionTitle !== "") &&
                      <div style={{background: "#f3f0f0"}}>
                        <h1 className="inline font-semibold text-lg">{ question.sectionTitle }</h1><span>{question.sectionDescription && question.sectionDescription !== "" ? ` - ${question.sectionDescription}`: ""}</span>
                      </div>
                    }
                    <h1 className='font-semibold text-lg'>
                      {/* {question.question} */}
                      <div dangerouslySetInnerHTML={{ __html: question.question }} />
                    </h1>
                    <div className='text-sm flex flex-col gap-y-1'>
                      {/* <p>{question.type !== 'text' ? '* ' + question.text : question.text}</p> */}
                      <div>
                        {
                          question?.answers.map((answer) => (
                            // <p>{answer}</p>
                            <p>{question.questionType !== 'TX' ? '* ' + answer : answer}</p>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </>
              ))
            }
          </div>
          {/* <div className='flex flex-col gap-y-2'>
            <h1 className='font-semibold text-lg'>Pregunta 1</h1>
            <SurveyAnalysisModalChart typeModal='answerQuestion' labels={labels} data={data} />
          </div> */}
        </div>
        <div className='col-span-2 flex justify-center'>
          <div className=''>
              <Button
                type="button"
                className='sig-button sig-success' 
                label="Descargar" 
                icon="pi pi-download" 
                onClick={onDownloadSurveyTrendInfo}
              />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default SurveyAnalysisModalAnswer