import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { AppSettingsAction } from 'features/configuration/store/actions/AppSettingsAction';
import { useDispatch } from 'react-redux';

const useTypeMoney = () => {
  const { updateTitle } = useSetTitlePage();
  useEffect(() => {
    updateTitle({
      title: 'Configuración',
      subtitle: 'Sistema',
      description: 'Tipo de moneda',
    });
  }, []);

  const { isLoading, isLoadingSave } = useSelector(
    (state) => state.appSettingsReducer.currentTypeMoney
  );

  const currentTypeMoney = useSelector(
    (state) => state.appSettingsReducer.currentTypeMoney.data
  );

  const [currentValue, setCurrentValue] = useState(
    currentTypeMoney?.ApplicationSettingDetails[0]?.Value ?? ''
  );

  const dispatch = useDispatch();

  const saveTypeMoney = () => {
    const id = currentTypeMoney.Id;
    const { Code, SubscriptionId } = currentTypeMoney;

    const newTypeMoney = {
      id,
      applicationSettingDetails: [
        {
          id: SubscriptionId,
          applicationSettingId: id,
          code: Code,
          value: currentValue,
        },
      ],
    };
    dispatch(
      AppSettingsAction.saveSettings({
        data: newTypeMoney,
        settingName: 'type-money',
      })
    );
  };

  return {
    isLoading,
    currentValue,
    setCurrentValue,
    saveTypeMoney,
    isLoadingSave,
  };
};

export default useTypeMoney;
