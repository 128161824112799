import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { StudentsTableData } from "features/customers/data/students/StudentsTableData";
import { CoreConstants } from "core/commons/CoreConstants";
import validateTypeValue from "shared/helpers/validateTypeValue";
import { StudentsAction } from "features/customers/store/actions/StudentsAction";
import { useClaimStudents } from "../useClaimStudents";
// import { useEffect } from 'react';

export const useTableStudents = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const students = useSelector((state) => state.studentsReducer.students);

  /* TYPE TABLE VIEW ---------------- */
  const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);

  const { isEdit, isDelete } = useClaimStudents();

  /* TABLE -------------------------- */
  const [tableStudents, setTableStudents] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState([]);
  useEffect(() => {
    const { data, pagination, loading } = students;
    setTableStudents(validateTypeValue(data));
    setTablePagination(pagination);
    setTableLoading(loading);
  }, [students]);

  /* TABLE HEADER COLUMNS */
  const [selectColumns, setSelectColumns] = useState(StudentsTableData.columns);

  useEffect(() => {
    if (localStorage.getItem("columnas-alumnos")) {
      setSelectColumns(JSON.parse(localStorage.getItem("columnas-alumnos")));
    }
  }, []);
  const onColumnToggle = (e, item) => {
    let _selectedColumns = [...selectColumns];

    if (e.checked) _selectedColumns.push(item);
    else _selectedColumns = _selectedColumns.filter((c) => c.field !== e.value);

    if (_selectedColumns.length === 0) return;
    localStorage.setItem("columnas-alumnos", JSON.stringify(_selectedColumns));
    setSelectColumns(_selectedColumns);
  };

  /* TABLE HEADER ACTIONS */
  const headerActions = useRef(null);

  /* CURRENT STUDENT ------------------- */
  const id = useSelector((state) => state.studentsReducer.currentAction.id);
  const setCurrentStudent = (id) => {
    dispatch(StudentsAction.currentAction({ id }));
  };

  /* TABLE CURRENT ACTION ------------- */
  const [currentAction, setCurrentAction] = useState("");
  const onCurrentAction = ({ item: { name } }) => setCurrentAction(name);
  useEffect(() => {
    const action_ = currentAction.toLocaleLowerCase();
    if (action_ === "editar") {
      history.push(`/cliente/alumnos/editar/${id}`);
    } else if (action_ === "eliminar") {
      dispatch(StudentsAction.currentAction({ isOpen: true }));
    }
    setCurrentAction("");
  }, [currentAction]);

  /* TABLE ACTIONS -------------------- */
  const studentsOptions = useSelector(
    (state) => state.studentsReducer.actions.items
  );

  const [tableActions, setTableActions] = useState([]);
  const [showActions, setShowActions] = useState(false);
  const tableCurrentActions = useRef(null);

  useEffect(() => {
    if (studentsOptions.length > 0) {
      let newActions = studentsOptions.map((option) => {
        option.label = option.name;
        option.command = onCurrentAction;
        return option;
      });
        if (!isEdit)
          newActions = newActions.filter(c => c.label !== 'Editar');
        if (!isDelete)
          newActions = newActions.filter(c => c.label !== 'Eliminar');
      setTableActions(newActions);
    }
    setShowActions(studentsOptions.length > 0);
  }, [isEdit, isDelete]);

  /* MODAL DELETE (ACTIONS) */
  const isOpenModalDelete = useSelector(
    (state) => state.studentsReducer.currentAction.isOpen
  );
  const confirmDelete = () => {
    dispatch(StudentsAction.deleteStudent(id));
  };
  const cancelDelete = () => {
    dispatch(StudentsAction.currentAction({ isOpen: false, id: "" }));
  };

  /* TABLE PAGINATION --------------- */
  const studentsSearch = useSelector((state) => state.studentsReducer.students);
  const [tableRows, setTableRows] = useState(10);
  const [tableNum, setTableNum] = useState(0);
  const [page, setPage] = useState(1);
  let currentFilterFields = {
    columnOrder: studentsSearch.columnOrder,
    order: studentsSearch.order,
  };

  const filterValues = useSelector(
    (state) => state.studentsReducer.advancedFilter.values
  );
  const buildFilterValues = () => {
    if (filterValues.length > 0) {
      filterValues.forEach(({ field, value, option }) => {
        let currentValue = "";
        const key = field.toLocaleLowerCase();
        if (key.includes("id")) currentValue = option.id;
        else currentValue = value;
        currentFilterFields[field] = currentValue;
      });
    }
  };

  const onChangePage = ({ rows, page }) => {
    buildFilterValues();
    currentFilterFields.page = page + 1;
    currentFilterFields.pageSize = rows;
    setTableRows(rows);
    setTableNum(rows * page);
    setPage(page + 1);
    const fields = currentFilterFields;
    dispatch(StudentsAction.getStudents({ change: true, fields }));
  };

  /* TABLE ORDER SORT */
  const [tableSort, setTableSort] = useState({
    sortField: "documentNumber",
    sortOrder: "1",
  });
  const onSort = (event) => {
    buildFilterValues();
    const params = {
      first: tableNum,
      rows: tableRows,
      multiSortMeta: [],
      filters: null,
      ...event,
    };

    currentFilterFields.order = event.sortOrder + "" === "1" ? "asc" : "desc";
    currentFilterFields.columnOrder = event.sortField;
    currentFilterFields.pageSize = tableRows;
    currentFilterFields.page = page;
    const fields = currentFilterFields;
    dispatch(StudentsAction.getStudents({ change: true, fields }));
    setTableSort(params);
  };

  return {
    selectColumns,
    typeView,
    setTypeView,
    headerActions,
    onColumnToggle,
    showActions,
    tableCurrentActions,
    tableActions,
    tableStudents,
    tableLoading,
    tablePagination,
    setCurrentStudent,
    confirmDelete,
    cancelDelete,
    isOpenModalDelete,
    tableSort,
    tableNum,
    tableRows,
    onSort,
    onChangePage,
  };
};
