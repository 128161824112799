import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button';
import { Tree } from 'primereact/tree';
import { DocumentLibraryAction } from 'features/document/store/actions/DocumentLibraryAction';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';
import { useClaimDocumentLibrary } from 'features/document/hooks/useClaimDocumentLibrary';
import './SidebarMenu.scss'

const dataExtra = [
  {
      "key": "100",
      "label": "Recientes",
      "data": "Documents Folder",
      "icon": "pi pi-fw pi-clock",
      "children": [],
  },
  {
      "key": "101",
      "label": "Vigentes",
      "data": "Documents Folder",
      "icon": "pi pi-fw pi-exclamation-circle",
      "children": [],
  },
  {
      "key": "102",
      "label": "Mis Documentos",
      "data": "Documents Folder",
      "icon": "pi pi-fw pi-star",
      "children": [],
  },
  {
      "key": "103",
      "label": "Papelera",
      "data": "Documents Folder",
      "icon": "pi pi-fw pi-trash",
      "children": [],
  },
]

const SidebarMenu = ({setSelectedNode, setDisplayModal, selectedFolder, setSelectedFolder, setMode}) => {
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [isFirstLoading, setFirstLoading] = useState(true); 
  const [expandedKeys, setExpandedKeys] = useState({});
  const dispatch = useDispatch()
  const { isLoading , data } = useSelector(state => state.documentLibraryReducer.listFolders)
  const { isEnabled } = useSelector((state) => state.documentLibraryReducer.isStorageEnabled);
  // const newData = !isLoading ? [...data, ...dataExtra] : []

  const { isCreate } = useClaimDocumentLibrary()

  useEffect(() => {
    if (isFirstLoading) {
      setFirstLoading(false);
      dispatch(DocumentLibraryAction.getDataListFolders());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pathDocument = (path) => {
    if (!path) return
    const pathSplit = path.split('/')
    pathSplit.shift();
    const newPath = pathSplit.join('/')
    return newPath
  }

  const onSelect = (event) => {
    if (event.node.label === 'Todos') {
      setSelectedNode('')
      setSelectedFolder(event.node)
      return
    }
    if (event.node.label === 'Recientes') {
      dispatch(DocumentLibraryAction.getRecentFiles());
      setSelectedNode('Recientes')
      return
    }
    if (event.node.label === 'Vigentes') {
      dispatch(DocumentLibraryAction.getCurrentFiles());
      setSelectedNode('Vigentes')
      return
    }
    if (event.node.label === 'Mis Documentos') {
      dispatch(DocumentLibraryAction.getMyFiles());
      setSelectedNode('Mis Documentos')
      return
    }
    if (event.node.label === 'Papelera') {
      dispatch(DocumentLibraryAction.getTrashFiles());
      setSelectedNode('Papelera')
      return
    }
    setSelectedNode(pathDocument(event.node.path))
    setSelectedFolder(event.node)
}

const nodeTemplate = (node, options) => {
  let label = <p>{node.label}</p>;

  if (node.label === 'Recientes' ) {
      label = (
      <div>
        <Tooltip className='ml-2' target=".recent-node"  />
        <p className='recent-node' data-pr-tooltip="Archivos de los últimos 30 días">{node.label}</p>
      </div>
    )
  }
  if (node.label === 'Vigentes') {
      label = (
        <>
          <Tooltip className='ml-2' target=".active-node"  />
          <p className='active-node' data-pr-tooltip="Documentos aprobados">{node.label}</p>
        </>
      )
  }
  if (node.label === 'Mis Documentos' ) {
      label = (
        <>
          <Tooltip className='ml-2' target=".mydocuments-node" />
          <p className='mydocuments-node' data-pr-tooltip="Archivos creados por mí">{node.label}</p>
        </>
      )
  }

  return (
      <span className={options.className} id='Recientes'>
          {label}
      </span>
  )
}

  return (
    <div className='flex flex-col items-center justify-center gap-2'>
      
      
      
      <div className='w-full flex justify-center'>
        {
          isCreate&&
          <Button
            icon="pi pi-plus" type="button" label="Nuevo" className="sig-button sig-dark w-36 pointer" 
            onClick={() => {
              setMode(null)
              setDisplayModal(true)}}
            disabled={(!isEnabled || selectedFolder?.canWrite === false) ? true : false }
          />
        }
      </div>
      <div className='w-full menu-document'>
        {
          isLoading ?(
            <Skeleton className='mt-3' height="6.2rem" />
          ) : (
            <>
            {/* <Tree 
              nodeTemplate={nodeTemplate}
              className='px-0' value={newData} selectionMode="single" selectionKeys={selectedNodeKey}
              onSelectionChange={e => setSelectedNodeKey(e.value)} onSelect={onSelect} 
              expandedKeys={expandedKeys} onToggle={e => setExpandedKeys(e.value)}
              disabled={isEnabled ? false : true }
            />   */}
            <Tree 
              className='px-0' value={data} selectionMode="single" selectionKeys={selectedNodeKey}
              onSelectionChange={e => setSelectedNodeKey(e.value)} onSelect={onSelect} 
              expandedKeys={expandedKeys} onToggle={e => setExpandedKeys(e.value)}
              disabled={isEnabled ? false : true }
            />  
            <div className='splitter-sidebar-document' />
            <Tree 
              nodeTemplate={nodeTemplate}
              className='px-0' value={dataExtra} selectionMode="single" selectionKeys={selectedNodeKey}
              onSelectionChange={e => setSelectedNodeKey(e.value)} onSelect={onSelect} 
              expandedKeys={expandedKeys} onToggle={e => setExpandedKeys(e.value)}
              disabled={isEnabled ? false : true }
            />  
            </>
          )
        }
      </div>           
    </div>
  )
}

export default SidebarMenu