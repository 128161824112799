import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Skeleton } from 'primereact/skeleton';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { CoursesMonitoringAction } from "features/coursesManagment/store/actions/CourseMonitoringAction";

import useCourseMonitoring from 'features/coursesManagment/hooks/CourseMonitoring/useCourseMonitoring';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useClaimMonitoring } from 'features/coursesManagment/hooks/useClaimMonitoring';

const CourseMonitoringPage = ({title, mode}) => {

  const { control, errors, existError, data, setValue, handleSubmit, isPositiveInteger, getEvaluationRange } = useCourseMonitoring()
  
  const [isFirstLoading, setFirstLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [participantsList, setParticipantsList] = useState([])
  
  const newData = participantsList.map((student, i) => {
    const newArray = student.Evaluations.reduce((acc, current) => {
      acc.push(current.Evaluation)
      return acc
    }, [])

    student.Evaluations.map((evaluation, index) => {
      student[`evaluation${index+1}`] = evaluation.Evaluation
      student[`evaluations`] = newArray
    })
    
    return student
  })


  const course = useSelector((state) => state.courseMonitoringReducer.course);
  const courseSaved = useSelector((state) => state.courseMonitoringReducer.courseSaved);

  const history = useHistory();
  const { updateTitle } = useSetTitlePage();
  const dispatch = useDispatch();
  const params = useParams();

  const { isEdit } = useClaimMonitoring();

  const redirectToSearch = () => {
    history.push('/curso/seguimiento');
    dispatch(CoursesMonitoringAction.resetAfterSaved());
  }

  useEffect(() => {
    if (courseSaved.isSaved) {
      redirectToSearch()
    }
  }, [courseSaved])
  

  useEffect(() => {
    updateTitle({
        title: 'Cursos',
        subtitle: 'Seguimiento',
        description: title,
        previousUrl: "/curso/seguimiento",
    });

    if (isFirstLoading) {
      setFirstLoading(false);
      if (params?.id) getCourseById(params?.id);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setValue("id", data.Id);
      setValue("code", data.Code);
      setValue("name", data.Name);
    }
  }, [data])

  useEffect(() => {
      if (data) {
        if (participantsList.length > 0) return 
        setParticipantsList(data?.Students)
      } 
  }, [data])

  const getCourseById = (courseId) => {
    dispatch(CoursesMonitoringAction.getCourseById(Number(courseId)));
  }

  const reject = () => {
    setVisible(false);
  };

  const newListUpdated = (oldList, newRow) => {
    const newList = oldList.map((item, index) => {
      if (item.Id === newRow.Id) {
        
        const arrayOnlyScores = newRow.Evaluations.reduce((acc, current, i) => {
          acc.push(newRow[`evaluation${i+1}`])
          return acc
        }, [])

        newRow.Evaluations.map((evaluation, idx) => {
          newRow.evaluations = arrayOnlyScores

          evaluation.Evaluation = newRow.evaluations[idx]
          return evaluation
        })

        return newRow
      }

      return item
    })
    return newList
  }

  const onCellEditComplete = (e) => {
    let { newRowData, newValue, field, originalEvent: event } = e;

    if (isPositiveInteger(newValue) && newValue <= 20){
        newRowData[field] = newValue;
        setParticipantsList(newListUpdated(newData, newRowData))
    } else
        event.preventDefault();
  }

  const textEditor = (options) => {
    const range = getEvaluationRange(data.EvaluationRange)
    return <InputText type="number" min={range.min} max={range.max} value={options.value} onChange={(e) => options.editorCallback(Number(e.target.value))} />;
  }

  const cellEditor = (options) => {
    return textEditor(options);
  }
  
  const approvedBodyTemplate = (rowData) => {
    const promedio = rowData.Evaluations.reduce((acc, current) => {
      acc = acc + (current.Evaluation * current.EvaluationWeight)
      return acc
    }, 0)

    return <div className='flex justify-center'><div className={`rounded-full ${promedio > data.PassingGrade ? 'bg-green-600' : 'bg-red-600'}  h-5 w-5`}></div></div>
  }

  const arrayEvaluationSession = (participants) => {

    const participantsEvaluations = participants.map((participant) => {
      const dataEvaluation = participant.Evaluations.map((evaluation) => {
        const evaluationInfo = {
          id: evaluation.Id,
          studentId: evaluation.CourseStudentId,
          sessionId: evaluation.CourseSessionId,
          evaluation: evaluation.Evaluation
        }
        return evaluationInfo
      })
      return dataEvaluation
    })

    const participantsEvaluationsJoin = participantsEvaluations.reduce((acc, current) => {
      acc.push(...current)
      return acc
    }, [])
    
    return participantsEvaluationsJoin
  }

  const onSubmit = (data) => {
    const model = {
        courseId: data.id,
        evaluations: arrayEvaluationSession(participantsList)
    }

    dispatch(CoursesMonitoringAction.saveCourse(model));
  };

  return (
    <div>
        <form
            className="form-custom p-0"
            onSubmit={handleSubmit(onSubmit)}
        >
          <div className="form-modal p-0">
            <Panel header="DATOS GENERALES" toggleable>
                <div className=" grid  sm:grid-cols-3 md:grid-cols-3 gap-2 ">
                    {course.isLoading ? (
                        <Skeleton height="2.2rem" />
                    ) : (
                        <span className="p-float-label w-full mt-4">
                            <Controller
                                control={control}
                                render={({
                                    field: { onChange, onBlur, value },
                                }) => (
                                    <InputText
                                        type="text"
                                        id="code"
                                        name="code"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        disabled
                                        className={errors?.code ? 'p-invalid w-full' : 'w-full'}
                                    />
                                )}
                                name="code"
                                rules={{
                                    required: 'El codigo es requerido',
                                }}
                            />
                            <label htmlFor="code">Código del Curso *</label>
                        </span>
                    )}

                    <span className="p-float-label w-full mt-4 hidden md:block">
                        &nbsp;
                    </span>
                    <span className="p-float-label w-full mt-4 hidden md:block">
                        &nbsp;
                    </span>
                </div>

                <div className="grid  sm:grid-cols-1 md:grid-cols-1 gap-2 mt-2 ">
                    {course.isLoading ? (
                            <Skeleton height="2.2rem" />
                        ) : (
                            <span className="p-float-label w-full mt-4">
                                <Controller
                                    control={control}
                                    render={({
                                        field: { onChange, onBlur, value },
                                    }) => (
                                        <InputText
                                            id="name"
                                            name="name"                                                    
                                            showClear
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            disabled
                                            className={errors?.name ? 'p-invalid w-full' : 'w-full'}
                                        />
                                    )}
                                    name="name"
                                    rules={{
                                    required: 'El Nombre de Curso es requerido',
                                }}
                                />
                                <label htmlFor="name">Nombre del Curso</label>
                            </span>
                    )}
                </div>
            </Panel>
            <Panel header="DETALLE DE PARTICIPANTES" toggleable>
              {course.isLoading ? (
                  <Skeleton height="4.2rem" />
                ) : (
                  <DataTable value={participantsList} editMode="cell" size="small" stripedRows  className="editable-cells-table" responsiveLayout="scroll">
                      <Column field="Name"  header="Apellidos y Nombres" sortable/>
                      {/* <Column field="state" header="Estado" style={{ width: '25%' }} sortable/> */}
                      {
                          data?.Students.map((student, i) => {
                            if (i === 0) {
                              return student.Evaluations.map((evaluation, index) => {
                                  return <Column key={evaluation.Description} align='right' field={`evaluation${index+1}`} header={`Sesión - ${index+1}`}  sortable
                                      editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                              })
                            }
                          })
                      } 
                      <Column field="approved" align='center' header="Aprobado"  body={approvedBodyTemplate} sortable />
                  </DataTable>
              )}
            </Panel>
            {
              (existError) 
              && <div className='message error'>
                  <ul>
                      {errors.name ? (
                          <>
                              <li className="p-error">{errors.name.message}</li>
                          </>
                      ) : null}                 
                      {errors.code ? (
                          <>
                              <li className="p-error">{errors.code.message}</li>
                          </>
                      ) : null}                 
                  </ul>

              </div>
              }
          </div>
          <div className="flex justify-end gap-4 mt-3">
              <Button
                  icon="pi pi-times"
                  type="button"
                  label="Cancelar"
                  onClick={() => { setVisible(true); }}
                  className="sig-button sig-secondary mt-4"
              />
              {
                (mode!=='view' && isEdit)&&(
                  <Button
                      icon="pi pi-save"
                      type="submit"
                      label="Guardar"
                      disabled={courseSaved.isSaving ? true : false}
                      loading={course?.isSaving || course?.isLoading}
                      className="sig-button sig-primary mt-4"
                  />
                )
              }
          </div>
        </form>
        <ConfirmDialog
            visible={visible}
            onHide={() => setVisible(false)}
            message="¿Está seguro que desea cancelar?"
            header="Cancelar"
            icon="pi pi-exclamation-triangle"
            accept={redirectToSearch}
            reject={reject}
        />
    </div>
  )
}

export default CourseMonitoringPage