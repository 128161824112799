import { SitesAction } from '../../store/actions/SitesAction';
import { useDispatch, useSelector } from 'react-redux';
import { useState , useEffect  } from 'react';
import { useParams } from 'react-router-dom';

const useSite = (title = "Nueva Sede") => {
  const dispatch = useDispatch();
  const { loadingSaveOrEdit , isSave , dataSite } = useSelector( (state) => state.sitesReducer.site);
  const { loading , data , pagination } = useSelector((state) => state.sitesReducer.sites);

  const params  = useParams();
  const removeSite = (locationId) =>{
    dispatch(SitesAction.deleteSite(locationId))
  }

  const clearSave = () =>{
    dispatch(SitesAction.setSite({ isSave : false }));
  }

  const saveSite = (data) =>{
      dispatch(SitesAction.setSite({loadingSaveOrEdit:true}))
      dispatch(SitesAction.saveSite(data,title))
    }

  useEffect(() => {
    if(title !=="Nueva Sede"){
      dispatch(SitesAction.getSiteById(params.id))
    }
  },[])

  return {
    removeSite,
    clearSave,
    saveSite,
    loadingSaveOrEdit,
    isSave,
    loading,
    data,
    pagination,
    dataSite,
  }
}

export default useSite



