import { BusinessSheetTypeConstants } from "features/maintenance/commons/BusinessSheetTypeConstants";
import { Piner } from "shared/helpers/Piner";

export const getFilterValuesFromPiners = (piners) => {
  const filterValues = {}
  const fieldNames = BusinessSheetTypeConstants.FieldName.Filter
  
  piners.forEach(piner => {
    if(piner.field === fieldNames.NAME)
      filterValues[fieldNames.NAME] = piner.searchValue
    if(piner.field === fieldNames.FROM_DATE) 
      filterValues[fieldNames.FROM_DATE] = piner.searchValue
    if(piner.field === fieldNames.TO_DATE) 
      filterValues[fieldNames.TO_DATE] = piner.searchValue
  });

  return filterValues
}

export const getPinersValues = (values) => {
  const piners = []
  const fieldNames = BusinessSheetTypeConstants.FieldName.Filter

  if(values[fieldNames.NAME]?.value) piners.push(new Piner({
      values: values,
      fieldNameConstant: fieldNames.NAME,
    }).getPiner())
  
  if(values[fieldNames.FROM_DATE]?.value) piners.push(new Piner({
    values: values,
    fieldNameConstant: fieldNames.FROM_DATE,
  }).getPiner())

  if(values[fieldNames.TO_DATE]?.value) piners.push(new Piner({
    values: values,
    fieldNameConstant: fieldNames.TO_DATE,
  }).getPiner())

  return piners
}