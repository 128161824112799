import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { RadioButton } from 'primereact/radiobutton'
import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'

const numberOfEntries = [
  {name: 'Entrada única', code: 'entry_single'}, 
  {name: 'Entradas multiples', code: 'entry_multiple'},  
]

export const ControllerConectionToData = ({control, errors, ValidateInput, validateDuplicate, valuesFields, setValue, dataMaster, inputSelected}) => {

  // console.log(valuesFields)

  useEffect(() => {
    if (!inputSelected) {
      setValue('numberOfEntry', numberOfEntries[0])
    }
  }, [setValue])
  
  const onNumberOfEntryChange = (value, onChange) => {
    // setValue('tableToConnect', null)
    onChange(value)
  }
  
  return (
    <>
      <span className="col-span-2 p-float-label w-full mt-4">
          <Controller
              control={control}
              render={({
                  field: { onChange, onBlur, value }
              }) => (
                <InputText
                    type="text"
                    id="fieldLabel"
                    name="fieldLabel"
                    onChange={onChange}
                    autoFocus
                    onBlur={onBlur}
                    value={value}
                    className={errors?.fieldLabel ? 'p-invalid w-full' : 'w-full'}
                />
              )}
              name="fieldLabel"
              rules={{
                  required: 'La etiqueta del campo es requerida.',
                  validate: ValidateInput
              }}
          />
          <label htmlFor="fieldLabel">Etiqueta del campo  *</label>
      </span>

      <span className="col-span-2 p-float-label w-full md:w-1/2 mt-4">
            <Controller
                control={control}
                render={({
                    field: { onChange, onBlur, value }
                }) => (
                  <Dropdown 
                    value={value} 
                    options={dataMaster?.processFormControlDataMembers} 
                    onChange={onChange} 
                    optionLabel="description" 
                    placeholder='Seleccione la tabla que desea conectar'
                    // placeholder="Select a City" 
                  />
                )}
                name="tableToConnect"
            />
            {/* <label htmlFor="tableToConnect">Seleccione la tabla que desea conectar</label> */}
          </span>

      {
        // valuesFields?.numberOfEntry?.code === 'entry_single' ? (
        //   <span className="col-span-2 p-float-label w-full md:w-1/2 mt-4">
        //     <Controller
        //         control={control}
        //         render={({
        //             field: { onChange, onBlur, value }
        //         }) => (
        //           <Dropdown 
        //             value={value} 
        //             options={tables} 
        //             onChange={onChange} 
        //             optionLabel="name" 
        //             placeholder='Seleccione la tabla que desea conectar'
        //             // placeholder="Select a City" 
        //           />
        //         )}
        //         name="tableToConnect"
        //     />
        //     {/* <label htmlFor="tableToConnect">Seleccione la tabla que desea conectar</label> */}
        //   </span>
        // ) : (
        //   <span className="col-span-2 p-float-label w-full mt-4">
        //     <Controller
        //         control={control}
        //         render={({
        //             field: { onChange, onBlur, value }
        //         }) => (
        //           <div className='w-full'>
        //             <MultiSelect 
        //               value={value} 
        //               options={tables} 
        //               onChange={(e) => onChange(e.value)} 
        //               optionLabel="name" 
        //               maxSelectedLabels={3} 
        //               placeholder='Seleccione la tabla que desea conectar'
        //               style={{width: '50%'}}
        //             />
        //           </div>

        //         )}
        //         name="tableToConnect"
        //     />
        //     {/* <label htmlFor="tableToConnect">Seleccione la tabla que desea conectar</label> */}
        //   </span>
        // )
      }
{/* 
      <span className="col-span-2 p-float-label w-full mt-4">
        <Controller
            control={control}
            render={({
                field: { onChange, onBlur, value }
            }) => (
              <div className='w-full md:w-1/2'>
                {
                  valuesFields?.numberOfEntry?.code === 'entry_single' ? (
                    <Dropdown 
                      value={value} 
                      options={tables} 
                      onChange={onChange} 
                      optionLabel="name" 
                      // placeholder="Select a City" 
                    />
                  ) : (
                    <MultiSelect 
                      value={value} 
                      options={tables} 
                      onChange={(e) => onChange(e.value)} 
                      optionLabel="name" 
                      maxSelectedLabels={3} 
                      style={{width: '100%'}}
                    />
                  )
                }
                
              </div>

            )}
            name="tableToConnect"
        />
        <label htmlFor="tableToConnect">Seleccione la tabla que desea conectar</label>
      </span> */}
      
      <span className="col-span-2  w-full mt-1">
          <Controller
              control={control}
              render={({
                  field: { onChange, onBlur, value }
              }) => (
                <div className='flex flex-col gap-y-1'>
                    <p>Número de entradas</p>
                    {
                      numberOfEntries.map((numberOfEntry) => {
                          return (
                              <div key={numberOfEntry.code} className="field-radiobutton flex gap-x-1 items-center">
                                  <RadioButton 
                                    inputId={numberOfEntry.code} 
                                    name="numberOfEntry" 
                                    value={numberOfEntry} 
                                    onChange={(e) => onNumberOfEntryChange(e.value, onChange)}  
                                    checked={valuesFields.numberOfEntry?.code === numberOfEntry.code}  
                                  />
                                  <label htmlFor={numberOfEntry.code}>{numberOfEntry.name}</label>
                              </div>
                          )
                      })
                  }
                </div>
              )}
              name="numberOfEntry"
          />
      </span>
      <div className='col-span-2 p-float-label w-full mt-4 '>
        {/* <span className="p-float-label">
            <Controller
                control={control}
                render={({
                    field: { onChange, value }
                }) => (
                  <div className='flex items-center'>
                    <InputSwitch checked={value} onChange={onChange} />
                    <h5 className='ml-2'>Requerido</h5>
                  </div>
                )}
                name="required"
            />
        </span> */}
        <div className={`rounded bg-red-200 p-5 mt-6 text-sm text-red-600 ${validateDuplicate===false&&'hidden'}`}>
          <ul>
            <li>La etiqueta del campo, ya se encuentra en uso.</li>
          </ul>
        </div>
      </div>
    </>
  )
}
