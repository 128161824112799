import { Dialog } from 'primereact/dialog'
import React from 'react'

export const ModalViewDetails = ({dataModalDetails, setDataModalDetails}) => {
  const onHide = () => {
    setDataModalDetails({visible: false, data: null})
  }
  return (
    <Dialog header="Descripción" visible={dataModalDetails.visible} style={{ width: '35vw' }}  onHide={onHide}>
      <div className='flex flex-col' style={{ width: '100%', wordWrap: "break-word" }}>
        <span className=' p-4 m-4'>
          <div dangerouslySetInnerHTML={{ __html: dataModalDetails.data?.description ? dataModalDetails.data?.description : "-" }} />
          {/* {dataModalDetails.data?.description?dataModalDetails.data?.description:"-"} */}
        </span>
      </div>
    </Dialog>
  )
}
