

import React, {Fragment, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import { SelectButton } from 'primereact/selectbutton';
import { Skeleton } from 'primereact/skeleton';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import { CoreConstants } from 'core/commons/CoreConstants';
import './TableComponent.scss'
import { DocumentActions } from 'features/processManagement/store/actions/DocumentActions';
import { v4 as uuidv4 } from 'uuid';

const TableComponent = ({selectedNode, documentSelected, setDocumentSelected, setDisplayModal, setDisplayModalAction, setActionSelected, setSelectedNode, setSelectedFolder, selectedDocuments, setSelectedDocuments, setErrorDocuments}) => {
    const dispatch = useDispatch();
    const { isDeleted, isUpdated } = useSelector((state) => state.DocumentsReducer.document);
    // const advancedFilter = useSelector((state) => state.DocumentsReducer.advancedFilter);
    const { isLoading , data } = useSelector(state => state.DocumentsReducer.listFilesFolders)
    const { isEnabled } = useSelector((state) => state.DocumentsReducer.isStorageEnabled);
    const getCreationDate = (rowData) => {
        if(rowData.latestChangeDate !== null) {
            return  moment(rowData.latestChangeDate).format(CoreConstants.Format.LOCALE); 
        }else {
            return "";
        }
    }
    const typeViews = [
        { icon: 'pi pi-list', value: CoreConstants.TypeView.LIST },
        { icon: 'pi pi-microsoft', value: CoreConstants.TypeView.CANVA }
    ];
    const columns = [
        { field: 'name', header: 'Nombre' },
        { field: 'owner', header: 'Propietario'},
        { field: 'latestChangeDate', header: 'Ultima Modificación', body: getCreationDate }
    ];

    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);
    const actionsHeader = useRef(null);
    const menu = useRef(null);
    const dragItem = useRef();
    const dragOverItem = useRef();
    const [showConfirmDialog, setshowConfirmDialog] = useState(false);
    const [showConfirmRestore, setShowConfirmRestore] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isFirstLoading, setFirstLoading] = useState(true); 

    useEffect(() => {
        if (isFirstLoading) {
            tryParseFilter();
            setFirstLoading(false);
        }
        if (selectedNode === 'Recientes' || selectedNode === 'Vigentes' || selectedNode === 'Mis Documentos' || selectedNode === 'Papelera') return
            
        dispatch(DocumentActions.getDataListFilesAndFolders(selectedNode));
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode]);

    useEffect(() => {
        if (isDeleted) {
            setshowConfirmDialog(false);
            dispatch(DocumentActions.resetAfterSaved());
            dispatch(DocumentActions.getDataListFilesAndFolders(selectedNode));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeleted])

    useEffect(() => {
        if (isUpdated) {
            dispatch(DocumentActions.resetAfterSaved());
            dispatch(DocumentActions.getDataListFilesAndFolders(selectedNode));
            dispatch(DocumentActions.getDataListFolders());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated])

    useEffect(() => {
        if (selectedRow) {
            setSelectedRow(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRow])

    const iconTypeOfFileCanva = (file) => {
        const fileSplit = file.name.split('.')
        if (fileSplit.includes('docx')) {
            return <div className="icon-word h-full"></div>
        } else if(fileSplit.includes('xlsx')) {
            return <div className="icon-excel h-full"></div>
        } else {
            return <div className="icon-file h-28"></div>
        }
    }

    const iconTypeOfFileTable = (file) => {
        const fileSplit = file.name.split('.')
        if (fileSplit.includes('docx')) {
            return <div className="icon-word-table"></div>
        } else if(file.itemType === 'F') {
            return <div className="icon-carpeta-table"></div>
        } else if(fileSplit.includes('xlsx')) {
            return <div className="icon-excel-table"></div>
        } else if(file.mimeType === 'application/pdf') {
            return <img alt='icon-pdf' className='icon-image' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAACQ0lEQVRoge2Yz0tUQRzAP293rTRb09rMwl1UkFqQ9tAhKPDQrT8gTx5FCtFLvy4SnbJDoKQSdIjoEBoFEZ66RF7qaAgF4rLs0masWSIsbaTTYZR3eNX63sw2uzAfWN7Mm+/MfD/Mm/fePrBYlHDKBYi+TlGRmQV3nNn0DdVhQjpy8YtoaQOH6+Ji55jqWEYENkemEIeOaZEwIkBzK5vDk1okzAiANglzAqBFwqwAKEuYFwAlieoQgMAS1SMAgSSqSwA8EuXCI/8jJ8+kw2e1jVV9K+ATK2AaK2AaK2CamhcI9iA73g0j9936zxJk3sPzcVj7DB09cGnCbf+ah9dP4N2crPf0Qv9N77jjA5Bf9pVKsBUIh6EhCqEIZD9C8TukzsPQFET2QGi73QnBpyWItUP/Lejt2+4fke1bv2T/nV/ph+9U1F4lVnMweRkcB648gkQSuk9DqSjbV9IwMQixOIw+hQsD8GbW7b+8AA+uKqWgZw8IAV8yshw97G0vZKGQg/0Hofmolil3UFuBun0QPwkNByB5Rp7LL0HdXm9scUMem2Luua5TMDQty4UczNz2nYKaQGsCrj126/PPIPsBulLe2D99HquPQvyELIfDgVJQE9hYg7cv5TGzCOmFv8fWN8rj+qq7CovzyntATWC9AC/ulY9raYMj7fIy+rYiLztNVPYPTSwBg3ehIyVvna8egtjSOkVlBRqbIHlO3m7npuUe0Yy5r9O7xJlJ/zPHmn8XsgKmsQKmsQKmsQKmsQIWS43zG41eqcJmZbBOAAAAAElFTkSuQmCC"/>
        } else if(file.mimeType === 'image/jpeg'|| file.mimeType === 'image/jpg' || file.mimeType === 'image/png' ) {
            return <img alt='icon-img' className='icon-image' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAADI0lEQVRoge2ZyW7TUBRAT9IhcdKgoqqTmjKpn4DER7CDBX/AT8CSNWv4A9ix4xtYtBJCqBIFVEpoaZqENB4S2/G7LNKiUPxc22ncIuVI2dzr4R7fN0mBCRMmTLhMcrrEgwO5L8JLgbUsCwqhRo7Hb1Zzb8OSed1dSnhxBYoHqCK81CW1AkB1DMWkZV2XiBL4L/jvBaazfuHdGYdHRpvqlEctmOVVd55Nv5T6eZl24O6Mw5PKIRvTLsWcsDHt8rRyyL1ZJ/UzMxV4ZLRD4w+L4fE4ZCqwNuWHxquaeBwyFfgRzITGa5p4HDIVeNWdD42/1sTjkKnApl/imbnMTr9AT/Ls9As8M5dHWoVGXkaPj3p0Gi7kYX6pSOV6IfL6Tb80UsFnSS0gAgdfO7TrvT8xs+WyWC2zuF6+kOLikEpARKh96tBp9P7J1fcsVCAs35obubg4JBYQEb5vH9Npudprjmo2AqxkIJFIQAJh9+MvrLZ37rVHexaIsHK78lfc+uVitTwEuLZQoDw/m6jgs8QWCPqK3Q8t7OP4m079m4UIrN6pgEBz36bTdBE16KTd9ri+YrCwNuZVqO8pvrxv0bWS75iHJxKzhTx2SOea+w4qEBZvpJv45wr4vYDPW0163X6qFwAc7poYczMY5fDXtQ4cRGDpZnKJSAGvG7Cz1cB10hd/itPxECUUdRL7DiAs3Uw28bUCPavPp80GvhskemAUjumjFNpONH8MOpFkCdYKbL+r0/dV8irPwTE9EH0nmjUbEVi5HU9CK3CRX/4sdsdDBYIxF34KbXy3B0vwnUpofhitgEj6AuPgWD4CFEvhJRzt2YiC1Y1oCb2AGrMBQxM7QgKiJSI6MH4BGMwJpRRGKXxHrn+zyEUc+rUC6uLnrxbH7CMKjHL4nPj5xdTee6lDaBjH9E8kkp0vL30IDeNYHqIUxXL8A15EBy6kpsQ41mA4FTXD6SwRcyD7DpxiW6c79vkSV2oIDePYHoJglKIlrtwQGmZwdhJKERJRq1CNK/AfQdf0QXGsy2u3iECpxyJSExEu85fL5wIVyPPxfJ4JEyZMGJXfXUfSuVI0IAgAAAAASUVORK5CYII="/>
        } else {
            return <div className="icon-file-table"></div>
        }
    }

    const titleDocuments = (pathTitle, type) => {
        if (!pathTitle) return
        // Para las carpetas
        const pathSplit = pathTitle.split('/')
        if (type === 'F') {
            const folderName = pathSplit[pathSplit.length-2]
            return folderName
        }
        // Para los archivos
        // const fileName = pathSplit[pathSplit.length-1]
        return pathTitle
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='flex items-center gap-x-4'>
                    { iconTypeOfFileTable(rowData) }
                    <span className="flex-1 truncate w-full">{titleDocuments(rowData.title, rowData.itemType)}</span>
                </div>
            </React.Fragment>
        );
    }

    const columnComponents = selectedColumns.map(col => {
        if (col.field === 'latestChangeDate')
            return <Column key={col.field} field={col.field} header={col.header} body={getCreationDate} sortable />;
        
        if (col.field === 'name')
            return <Column key={col.field} field={col.field} header={col.header} style={{width: '40%'}} body={nameBodyTemplate} sortable />;

        if (col.body)
            return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable />;

        return <Column key={col.field} field={col.field} header={col.header} sortable />;
    });

    const actionHeaderTemplate = (rowData) => {
        return <i
            key="action"
            className="pi pi-ellipsis-v cursor-pointer"
            onClick={(e) => actionsHeader.current.toggle(e)}></i>;
    }

    // Si queremos crearle un menu diferente para las carpetas crear un menu personalizado para 'F'
    const actionBodyTemplate = (rowData) => {
        if(rowData.itemType === 'F') return null
        return (
            <i
                key={rowData.id}
                className="pi pi-ellipsis-v cursor-pointer"
                onClick={(event) => {
                    if (!isEnabled) return 
                    if (!rowData.canWrite) return
                    setDocumentSelected(rowData)
                    menu.current.toggle(event)
                    }}
            ></i>
        )
    }

    const onChangeAddDocument = (e) => {
        if(e.checked){
            setErrorDocuments(false)
            if(selectedDocuments.length===0){
                setSelectedDocuments([{
                    id: uuidv4(),
                    mode: "new",
                    documentId: e.value.id,
                    name: e.value.title,
                    owner: e.value.owner,
                    latestChangeDate: e.value.latestChangeDate,
                    url: e.value.url
                }])
            }else{
                setSelectedDocuments([...selectedDocuments,{
                    id: uuidv4(),
                    mode: "new",
                    documentId: e.value.id,
                    name: e.value.title,
                    owner: e.value.owner,
                    latestChangeDate: e.value.latestChangeDate,
                    url: e.value.url
                }])
            }
        }else{
            setSelectedDocuments(selectedDocuments.filter(el=> el.documentId!==e.value.id))
        }
    }

    const actionChechedTemplate = (rowData) => {
        if(rowData.itemType === 'F') return null
        return (
            <div className="field-checkbox">
                <Checkbox inputId={rowData.id}
                name="category"
                value={rowData}
                onChange={onChangeAddDocument}
                checked={selectedDocuments.some((item) => item?.documentId === rowData.id)}
                />
            </div>
        )
    }

    const tryParseFilter = () => {
        try {
            const filters = JSON.parse(localStorage.getItem("document-filter"));
            if (filters)
                setSelectedColumns(filters);
            else
                setSelectedColumns(columns);
        } catch (error) {
            localStorage.removeItem('document-filter');
        }
    }

    const onColumnToggle = (e, item) => {
        let _selectedColumns = [...selectedColumns];

        if (e.checked)
            _selectedColumns.push(item);
        else
            _selectedColumns = _selectedColumns.filter(c => { return c.field !== e.value });

        setSelectedColumns(_selectedColumns);
        localStorage.setItem("document-filter", JSON.stringify(_selectedColumns));
        ;
    }

    const getColumns = () => {
        let array = [];
        columns.map((item) => {
            const column = <div key={item.field} className="p-field-checkbox py-1">
                <Checkbox
                    inputId={item.field}
                    name="column"
                    value={item.field}
                    onChange={(e) => onColumnToggle(e, item)}
                    checked={selectedColumns.some((x) => x.field === item.field)}
                />
                <label className="ml-1" htmlFor={item.field}>{item.header}</label>
            </div>
            return array.push(column);
        });
        
        return array.slice(1,3);
    };

    const onRowSelect = (data) => {
        if (!isEnabled) return
        if (data.field === 'name' || data.field === 'owner'  || data.field === 'latestChangeDate'){
            const name = data.rowData.name.split('/')
            name.pop()
            const path = name.join('/')
            if (data.rowData.itemType === 'F') {
                setSelectedNode(path)
                setSelectedFolder(data.rowData)
                return
            }
            // if (data.rowData.itemType === 'D') {
            //     dispatch(DocumentActions.getDocumentById(data.rowData.id));
            //     setActionSelected('readOnly')
            //     setDisplayModal(true)
            //     return
            // }
        }
    }

    const onCanvaSelect = (data) => {
        if (!isEnabled) return
        const name = data.name.split('/')
        name.pop()
        const path = name.join('/')
        if (data.itemType === 'F') {
            setSelectedNode(path)
            setSelectedFolder(data)
            return
        }
        if (data.itemType === 'D') {
            dispatch(DocumentActions.getDocumentById(data.id));
            setActionSelected('readOnly')
            setDisplayModal(true)
            return
        }
    }

    const items = [
        {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: (e) => {
                // console.log(documentSelected.id)
                dispatch(DocumentActions.getDocumentById(documentSelected.id));
                setDisplayModal(true)
            }
        },
        {
            label: 'Eliminar',
            icon: 'pi pi-times',
            command: (e) => {
                setshowConfirmDialog(true);
            }
        },
        {
            label: 'Copiar',
            icon: 'pi pi-copy',
            command: (e) => {
                setActionSelected('copy')
                setDisplayModalAction(true)
            }
        },
        {
            label: 'Mover',
            icon: 'pi pi-window-minimize',
            command: (e) => {
                setActionSelected('move')
                setDisplayModalAction(true)
            }
        },
        {
            label: 'Renombrar',
            icon: 'pi pi-clone',
            command: (e) => {
                setActionSelected('rename')
                setDisplayModalAction(true)
            }
        },
        {
            label: 'Descargar',
            icon: 'pi pi-download',
            command: (e) => {
                const link = document.createElement('a');
                link.href = documentSelected.url;
                link.setAttribute('download',`${documentSelected.name}`);
                link.setAttribute('target',`_blank`);
                link.setAttribute('rel',`noopener noreferrer`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
        },
        {
            label: 'Nueva Versión',
            icon: 'pi pi-clock',
            command: (e) => {console.log('hola')} // no se si borrar
        },
    ];

    const itemRestaurar = [
        {
            label: 'Restaurar',
            icon: 'pi pi-replay',
            command: (e) => {
                setShowConfirmRestore(true);
            }
        }
    ];

    const confirmDelete = () => {
        dispatch(DocumentActions.deleteDocument(documentSelected.id, documentSelected.name));
    }

    const confirmRestore = () => {
        console.log('Restauración confirmada') // no se si borrar
        // dispatch(DocumentActions.deleteDocument(documentSelected.id, documentSelected.name));
    }

    const cancelDelete = () => {
        setshowConfirmDialog(false);
    }

    const cancelRestore = () => {
        setShowConfirmRestore(false);
    }
    
    const dragStart = (e, position) => {
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
        // console.log(dragOverItem.current);
      };

    const drop = (e) => {
        if (dragItem.current.itemType === 'F') return
        if (dragOverItem.current) {
            const path = dragOverItem.current.name.split('/')
            path.pop()
            const targetPath = path.join('/')
            const fileMoved = {
                id: dragItem.current.id,
                sourceFileName: dragItem.current.name,
                targetPath
            }
            dispatch(DocumentActions.moveDocument(fileMoved));
            dragItem.current = null;
            dragOverItem.current = null;
        }
    };

    const tituloDirectorio = (path) => {
        const pathSplit = path.split('/')
        const pathsList = pathSplit.map( (item, index) => {
            if (index > 0) {
                const pathSlice = pathSplit.slice(0, index + 1)
                const pathJoin = pathSlice.join('/')
                const newPath = {
                    label: `${item}`,
                    command: () => {
                        setSelectedNode(pathJoin)}
                }
                return newPath
            }

            const newPath = {
                label: item,
                command: () => {
                    setSelectedNode(item)
                }
            }
            return newPath
        })

        const lastItemPath = [pathsList[pathsList.length - 1]]

        return lastItemPath
    }

    const home = { icon: 'pi pi-home', command: () => {
        setSelectedDocuments([])
        setSelectedNode('')
        setSelectedFolder(null)
    }}

    return (
        <Fragment>
            <div className="flex items-center justify-between px-2 mb-2">
                <BreadCrumb model={tituloDirectorio(selectedNode)} className='bread-crumb text-lg' home={home} />
                {/* <SelectButton
                    unselectable={false}
                    value={typeView}
                    options={typeViews}
                    onChange={(e) => setTypeView(e.value)}
                    itemTemplate={(option) => { return <i className={option.icon}></i>; }}
                /> */}
            </div>
            
            {/* {typeView === CoreConstants.TypeView.CANVA && 
                <div 
                    className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2  gap-2"
                >
                    {
                        isLoading ? (<Skeleton className='mt-3' height="10.2rem" /> ) : 
                        (
                            data.map((item, index) => (
                                <div 
                                    draggable={item.itemType === 'F' ? false : true}
                                    onDragStart={(e) => dragStart(e, item)}
                                    onDragEnter={(e) => dragEnter(e, item)}
                                    onDragEnd={drop}
                                    key={"card" + index}
                                    data-pr-tooltip={titleDocuments(item.title, item.itemType)}
                                    className="flex flex-col max-w-sm gap-y-2
                                        rounded-lg border border-gray-200 hover:bg-gray-100 shadow-sm
                                        dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 document-card"
                                >
                                    <div className='flex justify-center items-center w-full h-32 cursor-pointer' onClick={() => onCanvaSelect(item)}>
                                        {
                                            item.mimeType === 'application/pdf' || item.mimeType === 'image/jpeg'|| item.mimeType === 'image/jpg' || item.mimeType === 'image/png'   || item.mimeType === 'text/plain'
                                            ?  
                                            (
                                                <object data={item.url} type={item.mimeType} width="100%" height="100%" className='object-cover'>
                                                </object>
                                            )
                                            : (
                                                item.itemType === 'F' ?
                                                <div className="icon-carpeta"></div>
                                                : ( iconTypeOfFileCanva(item) )
                                            )
                                        }
                                    </div>
                                    <Tooltip target=".document-card" position='top'/>
                                    <div className="flex justify-between items-center  px-3 pb-2" >
                                        <h5 key={"title" + item.id} className="mb-0 text-md font-medium truncate  text-gray-900 dark:text-white">{titleDocuments(item.title, item.itemType)}</h5>
                                        <i
                                            key={"options" + item.id}
                                            className="pi pi-ellipsis-v cursor-pointer"
                                            onClick={(event) => {
                                                if (!isEnabled) return 
                                                setDocumentSelected(item);
                                                menu.current.toggle(event)
                                            }}
                                        ></i>
                                    </div>
                                </div>
                            ))
                        )
                    }
                </div>
            } */}
            
            {typeView === CoreConstants.TypeView.LIST &&
                <div className="mt-3" style={{ maxWidth: (window.innerWidth - 100) + 'px' }}>          
                    {
                        isLoading ?(
                            <Skeleton height="14.2rem" />
                          ) : (
                            <DataTable
                                className='tableDocument'
                                value={data}
                                responsiveLayout="scroll"
                                size="small"
                                emptyMessage="No se encontraron documentos"
                                selectionMode="single"
                                cellSelection
                                selection={selectedRow} onSelectionChange={e => setSelectedRow(e.value)}
                                onCellSelect={onRowSelect}
                                dataKey="id"
                                // sortField={sortField} sortOrder={sortOrder} onSort={(e) => onSort(e)}
                                >
                                <Column header="" body={actionChechedTemplate}/>
                                {columnComponents}
                                {/* <Column header={actionHeaderTemplate} body={actionBodyTemplate} ></Column> */}
                            </DataTable>
                          )
                    }          
                </div>
            }

            <OverlayPanel ref={actionsHeader} showCloseIcon style={{ width: '300px' }}>{getColumns()}</OverlayPanel>

            <Menu model={selectedNode !== 'Papelera' ? items : itemRestaurar} popup ref={menu} id="popup_menu" />
            <ConfirmDialog
                visible={showConfirmDialog}
                onHide={cancelDelete}
                message="¿Está seguro que desea eliminar el documento?"
                header="Eliminar"
                icon="pi pi-trash"
                accept={confirmDelete}
                reject={cancelDelete}
            />
            <ConfirmDialog
                visible={showConfirmRestore}
                onHide={cancelRestore}
                message="¿Está seguro de que desea restaurar este archivo?"
                header="Restaurar"
                icon="pi pi-replay"
                accept={confirmRestore}
                reject={cancelRestore}
            />
        </Fragment >
    );
};

export default TableComponent;
