import { CoreConstants } from "core/commons/CoreConstants";
import { Calendar } from "primereact/calendar";
import { InputText } from 'primereact/inputtext';
import { useAdvancedFilterProject } from "features/customers/hooks/project/useAdvancedFilterProject";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from 'primereact/checkbox';
import Message from "shared/components/messages/Message";
import ModalFilterComponent from "shared/components/modal-filter/ModalFilterComponent";

export const AdvancedFilterComponent = () => {
  const { dataMaster, errors, formValues, showModal, handleChange, onToggleModal, clearFields, onAdvancedFilter} = useAdvancedFilterProject();

  if(showModal) {
    return (
      <ModalFilterComponent
        closeModal = {() => onToggleModal(false)}
        clearFields = {clearFields} 
        handleSubmit = {onAdvancedFilter}>
          {errors.length > 0 && <Message messages={errors} status="error" />}
          <div className="flex gap-4 pt-7">
            <div className="w-full">
              <span className="p-float-label">
                <Calendar
                  name="fromDate"
                  // dateFormat={CoreConstants.Format.ddmmyy}
                  value={formValues.fromDate}
                  onChange={handleChange}
                  readOnlyInput
                />
                <label htmlFor="fromDate">Apertura Estimada Desde</label>
              </span>
            </div>
            <div className="w-full">
              <span className="p-float-label">
                <Calendar
                  name="toDate"
                  // dateFormat={CoreConstants.Format.ddmmyy}
                  value={formValues.toDate}
                  onChange={handleChange}
                  readOnlyInput
                />
                <label htmlFor="toDate">Apertura Estimada Hasta</label>
              </span>
            </div>
          </div>          
          <div className="flex pt-7">
            <div className="w-full">
              <span className="p-float-label">
                <Dropdown
                  options={dataMaster.businessList}
                  optionLabel="name"
                  filter
                  filterBy="name"
                  showClear
                  className="w-full"
                  name="businessId"
                  value={formValues.businessId}
                  onChange={handleChange}
                />
                <label htmlFor="dropdown">Por Solicitud de Negocio</label>
              </span>
            </div>
          </div>
          <div className="flex pt-7">
            <div className="w-full">
              <span className="p-float-label">
                <Dropdown
                  options={dataMaster.responsibles}
                  optionLabel="fullName"
                  filter
                  filterBy="fullName"
                  showClear
                  className="w-full"
                  name="responsibleId"
                  value={formValues.responsibleId}
                  onChange={handleChange}
                />
                <label htmlFor="dropdown">Por Responsable</label>
              </span>
            </div>
          </div>   
          <div className="flex pt-7">
            <div className="w-full">
              <span className="p-float-label">
                <InputText
                  type="number"
                  name="amount"
                  className="w-full text-right"
                  value={formValues.amount || ""}
                  onChange={handleChange}
                />
                <label htmlFor="amount">Por Monto Adjudicado</label>
              </span>
            </div>
          </div>   
          <div className="flex pt-7">
            <div className="w-full">
              <span className="p-float-label">
                <Dropdown
                  options={dataMaster.status}
                  optionLabel="description"
                  filter
                  filterBy="description"
                  showClear
                  className="w-full"
                  name="statusId"
                  value={formValues.statusId}
                  onChange={handleChange}
                />
                <label htmlFor="dropdown">Por Estado</label>
              </span>
            </div>
          </div>
          <div className="flex pt-7">
            <div className="w-full"><span className="p-float-label">
                <Dropdown
                  options={dataMaster.situation}
                  optionLabel="description"
                  filter
                  filterBy="description"
                  showClear
                  className="w-full"
                  name="situationId"
                  value={formValues.situationId}
                  onChange={handleChange}
                />
                <label htmlFor="dropdown">Por Situación</label>
              </span>
            </div>
          </div>    
      </ModalFilterComponent>
    );
  } else {
    return null;
  }
}