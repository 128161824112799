import FileSaver from "file-saver";
import { ConfigurationConstants } from "features/configuration/commons/ConfigurationConstants";
import { UserService } from "features/configuration/services/UserService";
import { toastAction } from "core/store/actions/ToastAction";
import { CoreConstants } from "core/commons/CoreConstants";
import { useSelector } from "react-redux";
import useAdvancedFilter from "features/configuration/hooks/users/useAdvancedFilter";

const saveUserStatus = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ConfigurationConstants.Accion.Users.SAVE_USER_STATUS,
      payload,
    });
  };
};

const saveUser = (user, message) => {
  return (dispatch) => {
    dispatch(saveUserStatus({ status: "" }));

    UserService.saveUser(user)
      .then(({ data }) => {
        const status = data?.status ?? "";
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success("Gestión de Usuarios", message));
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn("Gestión de Usuarios", data.message));
        }else{
          dispatch(toastAction.error("Gestión de Usuarios", data.message));
        }
        dispatch(saveUserStatus({ status }));
      })
      .catch((error)=>{
        dispatch(saveUserStatus({ status: null }));
      });
  };
};

const setUsers = (fields) => {
  return (dispatch) =>
    dispatch({
      type: ConfigurationConstants.Accion.Users.SET_LIST,
      payload: { ...fields },
    });
};

const getUsers = (fields) => {
  return (dispatch) => {
    dispatch(setUsers({ loading: true }));
    UserService.userSearch(fields).then(({ data }) => {
      if (data.status === CoreConstants.HttpResponse.OK) {
        const { results, ...rest } = data.data;
        let objFields = {
          loading: false,
          data: results,
          currentData: results,
          pagination: { ...rest },
        };
        if (fields.columnOrder) objFields.currentCol = fields.columnOrder;
        dispatch(setUsers(objFields));
      }else if(data.status===CoreConstants.HttpResponse.ERROR_FUNTIONAL){
        dispatch(toastAction.warn("Gestión de Usuarios", data.message));
      }else{
        dispatch(toastAction.error("Gestión de Usuarios", data.message));
      }
    }).catch((error)=>{
    });
  };
};

const setDateMasters = (payload) => {
  return (dispatch) =>
    dispatch({
      type: ConfigurationConstants.Accion.Users.GET_DATA_MASTER,
      payload,
    });
};

const getDataMaster = () => {
  return (dispatch) => {
    dispatch(setDateMasters({ loading: true }));
    UserService.getDataMaster().then(({ data }) => {
      dispatch(setDateMasters({ loading: false, data: { ...data.data } }));
    });
  };
};

const setUser = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ConfigurationConstants.Accion.Users.GET_USER,
      payload,
    });
  };
};

const getUser = (userId) => {
  return (dispatch) => {
    dispatch(setUser({ loading: true }));
    UserService.getUserById(userId)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(setUser({ loading: false, data: data.data }));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn("Gestión de Usuarios", data.message));
        }else{
          dispatch(toastAction.error("Gestión de Usuarios", data.message));
        }
        dispatch(setUser({ loading: false }));
      })
      .catch((error)=>{
        dispatch(setUser({ loading: false }));
      });
  };
};

const usersReportStatus = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ConfigurationConstants.Accion.Users.DOWNLOAD_REPORT_STATUS,
      payload,
    });
  };
};

const deleteUser = (userId) => {
  return (dispatch) => {
    UserService.removeUser(userId)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(showDeleteUserModal({ isOpen: false, userId: "" }));
          dispatch(getUsers({ change: false }));
          dispatch(
            toastAction.success("Gestión de Usuarios", "Usuario Eliminado")
          );
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.error("Gestión de Usuarios", data.message));
        }
      })
      .catch((error)=>{
        dispatch(
          showDeleteUserModal({ isLoading: false, isOpen: false, userId: "" })
        );
      });
  };
};

const showDeleteUserModal = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ConfigurationConstants.Accion.Users.SHOW_DELETE_USER_MODAL,
      payload,
    });
  };
};

const setCurrentUser = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ConfigurationConstants.Accion.Users.CURRENT_USER,
      payload,
    });
  };
};

const getFilterValues = (newValues) => {
  const newFilterValues = {};

  newValues.forEach((val) => {
    const field = val.field.toLocaleLowerCase();
    if (field.includes("date")) newFilterValues[val.field] = val.date;
    else if (field.includes("id") && field !== "statusid")
      newFilterValues[val.field] = val.option.id;
    else if (field === "statusid") newFilterValues[val.field] = val.optionId;
    else if (field === "fullname") newFilterValues[val.field] = val.value;
  });
  return newFilterValues;
};
const generateUserReport = () => {
  return (dispatch, getState) => {
    let { values } = getState().userReducer.userFilter;
    dispatch(usersReportStatus(true));
    const fieldValues = getFilterValues(values);
    UserService.generateReport(fieldValues)
      .then(({ data }) => {
        dispatch(usersReportStatus(false));
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        FileSaver.saveAs(blob, "Usuario.xls");
      })
      .catch((err) => {
        console.error(err);
        dispatch(usersReportStatus(false));
      });
  };
};
const removeFilterValues = (removeField) => {
  return (dispatch, getState) => {
    let { values } = getState().userReducer.userFilter;
    const newValues = values.filter(({ field }) => field !== removeField);
    const fields = getFilterValues(newValues);
    dispatch(UsersAction.getUsers({ change: true, fields }));

    dispatch({
      type: ConfigurationConstants.Accion.Users.CHANGE_FILTER_VALUES,
      payload: { values: newValues },
    });
  };
};

const getInitialStatus = () => {
  return (dispatch, getState) => {
    let data = getState().userReducer.dataManager;
    let item = data?.data.status.find(el => el.code === "ACTI")
    if(item){
      dispatch({
        type: ConfigurationConstants.Accion.Users.CHANGE_FILTER_VALUES,
        payload: { values: [{
          field:"statusId",
          optionId:item.id,
          value: item.description
        }] },
      });

      dispatch(UsersAction.getUsers({ change: true,
        fields: {
          fromDate: null,
          toDate: null,
          companyId: null,
          locationId: null,
          roleId: null,
          statusId: item.id
        }
      }));
    }
  }
}

const usersSearch = (valueSearch) => {
  return (dispatch, getState) => {
    let { values } = getState().userReducer.userFilter;
    const fields = getFilterValues(values);
    fields.fullName = valueSearch.fullName;

    const hasValue = Object.values(valueSearch).length > 0;
    let newValues = [];

    if (values.length === 0 && hasValue) {
      newValues = [{ field: "fullName", value: valueSearch.fullName }];
    } else if (values.length > 0 && hasValue) {
      const currentValues = values.filter(({ field }) => field !== "fullName");
      newValues = [
        { field: "fullName", value: valueSearch.fullName },
        ...currentValues,
      ];
    } else if (values.length > 0 && !hasValue) {
      newValues = [...values];
    }
    dispatch({
      type: ConfigurationConstants.Accion.Users.CHANGE_FILTER_VALUES,
      payload: { values: newValues },
    });
    dispatch(UsersAction.getUsers({ change: true, fields }));
  };
};

const userResetPassword = (userId) => {
  return (dispatch) => {
    UserService.resetPassword(userId)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(
            toastAction.success(
              "Gestión de Usuarios",
              "Correo de cambiar contraseña enviado"
            )
          );
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.error("Gestión de Usuarios", data.message));
        }else{
          dispatch(toastAction.error("Gestión de Usuarios", data.message));
        }
      })
      .catch((error)=>{
      });
  };
};

export const UsersAction = {
  getUsers,
  getDataMaster,
  getUser,
  saveUser,
  saveUserStatus,
  generateUserReport,
  showDeleteUserModal,
  deleteUser,
  setCurrentUser,
  removeFilterValues,
  usersSearch,
  userResetPassword,
  getInitialStatus
};
