
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { getBase64 } from 'shared/utils/getBase64';
import { v4 as uuidv4 } from 'uuid';

const useCourseExpenses = () => {

  // const advancedFilter = useSelector((state) => state.surveyReducer.advancedFilter);
  const [expensesList, setExpensesList] = useState([])
  const [expenseError, setExpenseError] = useState([])

  // console.log(expensesList)

  let defaultValues = {
    // Información del gasto
    course: '',
    category: '',
    type: '',
    description: '',

    // Datoas del Gasto
    initialDate: null,
    endDate: null,
    consultantName: '',
    total: 0,

    // Información de los Pagos
    // paymentDate: '',
    // paymentMode: '',
    // amount: '',
    // attachmentDocument: '',

    // Datos generales
    createdBy: '',
    creationDate: '',
    updatedBy: '',
    updateDate: '',
  };

  const {
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const valuesFields = watch();
  // console.log(valuesFields)

  const existError = Object.keys(errors).length > 0

  const validateLength = (value) => {
    if (typeof value === 'string') {
      return value.replace(/<[^>]+>/g, '')?.length;
    } else if (value?.htmlValue) {
      return value.htmlValue.replace(/<[^>]+>/g, '').length;
    }
    return '0';
  };

  const getMessageError = (expense) => {
    let message = ''
    if (!expense.paymentDate && expense.paymentMode) message = 'Fecha'
    if (!expense.paymentMode && expense.paymentDate) message = 'Modo'
    if (expense.paymentMode === null && expense.paymentDate === null) message = 'Fecha y Modo'
    return message
  }

  const existRowEmpty = (list) => {
    const isRowEmpty = list.some(expense => expense.paymentDate === null || expense.paymentMode === null || expense.amount === null)
    let errorRows = []
    list.forEach((expense, index) => {
      if (expense.paymentDate === null || expense.paymentMode === null || expense.amount === null) {
        const modelErrow = {
          errorRow: index + 1,
          errorField: getMessageError(expense)
        }
        errorRows.push(modelErrow)
      }
    })

    return {
      isRowEmpty,
      errorRows
    }
  }

  const createExpense = () => {
    setExpensesList((prev) => [
      ...prev,
      {
        id: uuidv4(),
        paymentDate: null,
        paymentMode: null,
        amount: 0,
        attachmentDocument: null,
        fileBase64: null,
        file: null,
      },
    ]);
  };

  const deleteExpense= (id) => {
    const currentExpensesList = expensesList.filter((expense) => expense.id !== id);
    setExpensesList(currentExpensesList);
  };

  const updateExpense = ({ id, value, name}) => {
    const currentExpensesList = expensesList.map((expense) => {
      if (expense.id === id) {
        if (name === 'amount' && value >= 0) expense[name] = Number(value);
        if (name === 'paymentDate' || name === 'paymentMode') expense[name] = value;
        if (name === 'attachmentDocument') {
          expense[name] = value
          getBase64(value)
            .then(data => expense.fileBase64 = data)
            .catch(err => console.log(err))
        }
      }
      return expense;
    });

    setExpensesList(currentExpensesList);
    const { errorRows } = existRowEmpty(expensesList)
    setExpenseError(errorRows)
  };

  const onBlurAmount = (id) => {
    const currentExpensesList = expensesList.map((expense) => {
      if (expense.id === id) {
       expense.amount = Number(expense.amount).toFixed(2)
      }
      return expense;
    });
  
    setExpensesList(currentExpensesList);
  };

  const iconTypeOfFileTable = (file) => {
    if (!file) return
    if (file.name) {
      const fileSplit = file.name.split('.')
      if (fileSplit.includes('docx') || fileSplit.includes('doc')) {
          return <div className="icon-word-table h-10 w-10"></div>
      } else if(fileSplit.includes('xlsx') || fileSplit.includes('xls')) {
          return <div className="icon-excel-table h-10 w-10"></div>
      } else if(file.type === 'application/pdf') {
          return <img alt='icon-pdf' className='icon-image h-10 w-10' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAACQ0lEQVRoge2Yz0tUQRzAP293rTRb09rMwl1UkFqQ9tAhKPDQrT8gTx5FCtFLvy4SnbJDoKQSdIjoEBoFEZ66RF7qaAgF4rLs0masWSIsbaTTYZR3eNX63sw2uzAfWN7Mm+/MfD/Mm/fePrBYlHDKBYi+TlGRmQV3nNn0DdVhQjpy8YtoaQOH6+Ji55jqWEYENkemEIeOaZEwIkBzK5vDk1okzAiANglzAqBFwqwAKEuYFwAlieoQgMAS1SMAgSSqSwA8EuXCI/8jJ8+kw2e1jVV9K+ATK2AaK2AaK2CamhcI9iA73g0j9936zxJk3sPzcVj7DB09cGnCbf+ah9dP4N2crPf0Qv9N77jjA5Bf9pVKsBUIh6EhCqEIZD9C8TukzsPQFET2QGi73QnBpyWItUP/Lejt2+4fke1bv2T/nV/ph+9U1F4lVnMweRkcB648gkQSuk9DqSjbV9IwMQixOIw+hQsD8GbW7b+8AA+uKqWgZw8IAV8yshw97G0vZKGQg/0Hofmolil3UFuBun0QPwkNByB5Rp7LL0HdXm9scUMem2Luua5TMDQty4UczNz2nYKaQGsCrj126/PPIPsBulLe2D99HquPQvyELIfDgVJQE9hYg7cv5TGzCOmFv8fWN8rj+qq7CovzyntATWC9AC/ulY9raYMj7fIy+rYiLztNVPYPTSwBg3ehIyVvna8egtjSOkVlBRqbIHlO3m7npuUe0Yy5r9O7xJlJ/zPHmn8XsgKmsQKmsQKmsQKmsQIWS43zG41eqcJmZbBOAAAAAElFTkSuQmCC"/>
      } else if(file.type === 'image/jpeg'|| file.type === 'image/jpg' || file.type === 'image/png' ) {
          return <img alt='icon-img' className='icon-image h-10 w-10' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAADI0lEQVRoge2ZyW7TUBRAT9IhcdKgoqqTmjKpn4DER7CDBX/AT8CSNWv4A9ix4xtYtBJCqBIFVEpoaZqENB4S2/G7LNKiUPxc22ncIuVI2dzr4R7fN0mBCRMmTLhMcrrEgwO5L8JLgbUsCwqhRo7Hb1Zzb8OSed1dSnhxBYoHqCK81CW1AkB1DMWkZV2XiBL4L/jvBaazfuHdGYdHRpvqlEctmOVVd55Nv5T6eZl24O6Mw5PKIRvTLsWcsDHt8rRyyL1ZJ/UzMxV4ZLRD4w+L4fE4ZCqwNuWHxquaeBwyFfgRzITGa5p4HDIVeNWdD42/1sTjkKnApl/imbnMTr9AT/Ls9As8M5dHWoVGXkaPj3p0Gi7kYX6pSOV6IfL6Tb80UsFnSS0gAgdfO7TrvT8xs+WyWC2zuF6+kOLikEpARKh96tBp9P7J1fcsVCAs35obubg4JBYQEb5vH9Npudprjmo2AqxkIJFIQAJh9+MvrLZ37rVHexaIsHK78lfc+uVitTwEuLZQoDw/m6jgs8QWCPqK3Q8t7OP4m079m4UIrN6pgEBz36bTdBE16KTd9ri+YrCwNuZVqO8pvrxv0bWS75iHJxKzhTx2SOea+w4qEBZvpJv45wr4vYDPW0163X6qFwAc7poYczMY5fDXtQ4cRGDpZnKJSAGvG7Cz1cB10hd/itPxECUUdRL7DiAs3Uw28bUCPavPp80GvhskemAUjumjFNpONH8MOpFkCdYKbL+r0/dV8irPwTE9EH0nmjUbEVi5HU9CK3CRX/4sdsdDBYIxF34KbXy3B0vwnUpofhitgEj6AuPgWD4CFEvhJRzt2YiC1Y1oCb2AGrMBQxM7QgKiJSI6MH4BGMwJpRRGKXxHrn+zyEUc+rUC6uLnrxbH7CMKjHL4nPj5xdTee6lDaBjH9E8kkp0vL30IDeNYHqIUxXL8A15EBy6kpsQ41mA4FTXD6SwRcyD7DpxiW6c79vkSV2oIDePYHoJglKIlrtwQGmZwdhJKERJRq1CNK/AfQdf0QXGsy2u3iECpxyJSExEu85fL5wIVyPPxfJ4JEyZMGJXfXUfSuVI0IAgAAAAASUVORK5CYII="/>
      } else {
          return <div className="icon-file-table"></div>
      }
    }
    const fileSplit = file.split('.')
    if (fileSplit.includes('docx') || fileSplit.includes('doc')) {
      return <div className="icon-word-table h-10 w-10"></div>
    } else if(fileSplit.includes('xlsx') || fileSplit.includes('xls')) {
        return <div className="icon-excel-table h-10 w-10"></div>
    } else if(fileSplit.includes('pdf')) {
        return <img alt='icon-pdf' className='icon-image h-10 w-10' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAACQ0lEQVRoge2Yz0tUQRzAP293rTRb09rMwl1UkFqQ9tAhKPDQrT8gTx5FCtFLvy4SnbJDoKQSdIjoEBoFEZ66RF7qaAgF4rLs0masWSIsbaTTYZR3eNX63sw2uzAfWN7Mm+/MfD/Mm/fePrBYlHDKBYi+TlGRmQV3nNn0DdVhQjpy8YtoaQOH6+Ji55jqWEYENkemEIeOaZEwIkBzK5vDk1okzAiANglzAqBFwqwAKEuYFwAlieoQgMAS1SMAgSSqSwA8EuXCI/8jJ8+kw2e1jVV9K+ATK2AaK2AaK2CamhcI9iA73g0j9936zxJk3sPzcVj7DB09cGnCbf+ah9dP4N2crPf0Qv9N77jjA5Bf9pVKsBUIh6EhCqEIZD9C8TukzsPQFET2QGi73QnBpyWItUP/Lejt2+4fke1bv2T/nV/ph+9U1F4lVnMweRkcB648gkQSuk9DqSjbV9IwMQixOIw+hQsD8GbW7b+8AA+uKqWgZw8IAV8yshw97G0vZKGQg/0Hofmolil3UFuBun0QPwkNByB5Rp7LL0HdXm9scUMem2Luua5TMDQty4UczNz2nYKaQGsCrj126/PPIPsBulLe2D99HquPQvyELIfDgVJQE9hYg7cv5TGzCOmFv8fWN8rj+qq7CovzyntATWC9AC/ulY9raYMj7fIy+rYiLztNVPYPTSwBg3ehIyVvna8egtjSOkVlBRqbIHlO3m7npuUe0Yy5r9O7xJlJ/zPHmn8XsgKmsQKmsQKmsQKmsQIWS43zG41eqcJmZbBOAAAAAElFTkSuQmCC"/>
    } else if(fileSplit.includes('jpeg') || fileSplit.includes('jpg')  || fileSplit.includes('png') ) {
        return <img alt='icon-img' className='icon-image h-10 w-10' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAADI0lEQVRoge2ZyW7TUBRAT9IhcdKgoqqTmjKpn4DER7CDBX/AT8CSNWv4A9ix4xtYtBJCqBIFVEpoaZqENB4S2/G7LNKiUPxc22ncIuVI2dzr4R7fN0mBCRMmTLhMcrrEgwO5L8JLgbUsCwqhRo7Hb1Zzb8OSed1dSnhxBYoHqCK81CW1AkB1DMWkZV2XiBL4L/jvBaazfuHdGYdHRpvqlEctmOVVd55Nv5T6eZl24O6Mw5PKIRvTLsWcsDHt8rRyyL1ZJ/UzMxV4ZLRD4w+L4fE4ZCqwNuWHxquaeBwyFfgRzITGa5p4HDIVeNWdD42/1sTjkKnApl/imbnMTr9AT/Ls9As8M5dHWoVGXkaPj3p0Gi7kYX6pSOV6IfL6Tb80UsFnSS0gAgdfO7TrvT8xs+WyWC2zuF6+kOLikEpARKh96tBp9P7J1fcsVCAs35obubg4JBYQEb5vH9Npudprjmo2AqxkIJFIQAJh9+MvrLZ37rVHexaIsHK78lfc+uVitTwEuLZQoDw/m6jgs8QWCPqK3Q8t7OP4m079m4UIrN6pgEBz36bTdBE16KTd9ri+YrCwNuZVqO8pvrxv0bWS75iHJxKzhTx2SOea+w4qEBZvpJv45wr4vYDPW0163X6qFwAc7poYczMY5fDXtQ4cRGDpZnKJSAGvG7Cz1cB10hd/itPxECUUdRL7DiAs3Uw28bUCPavPp80GvhskemAUjumjFNpONH8MOpFkCdYKbL+r0/dV8irPwTE9EH0nmjUbEVi5HU9CK3CRX/4sdsdDBYIxF34KbXy3B0vwnUpofhitgEj6AuPgWD4CFEvhJRzt2YiC1Y1oCb2AGrMBQxM7QgKiJSI6MH4BGMwJpRRGKXxHrn+zyEUc+rUC6uLnrxbH7CMKjHL4nPj5xdTee6lDaBjH9E8kkp0vL30IDeNYHqIUxXL8A15EBy6kpsQ41mA4FTXD6SwRcyD7DpxiW6c79vkSV2oIDePYHoJglKIlrtwQGmZwdhJKERJRq1CNK/AfQdf0QXGsy2u3iECpxyJSExEu85fL5wIVyPPxfJ4JEyZMGJXfXUfSuVI0IAgAAAAASUVORK5CYII="/>
    } else {
        return <div className="icon-file-table"></div>
    }
}


  return {
    control, errors, valuesFields, setValue, reset, handleSubmit,
    existError, validateLength,
    expensesList, setExpensesList, expenseError, setExpenseError,
    createExpense, deleteExpense, updateExpense, onBlurAmount,
    iconTypeOfFileTable,
    existRowEmpty
  }
}

export default useCourseExpenses