import moment from 'moment'
import React from 'react'

export const ProcessOrDataCard = ({process, onSelected, dataSelected}) => {

  const isSelected = dataSelected ? dataSelected.some((item) => item.id === process.id) : false
  // ${isSelected ? 'border-blue-500' : ''}

  return (
    <div 
      className={`flex w-full border border-l-4 py-2 px-4 rounded-md gap-x-4 shadow-sm  
        ${isSelected ? 'bg-gray-100 shadow-md' : ''}
        ${dataSelected ? 'cursor-pointer hover:bg-gray-50' : ''}
      `}
      onClick={() => dataSelected ? onSelected(process) : () => {}}
    >
      <div className='flex justify-center items-center px-2'>
        <i className={`pi ${process.icon} shadow-md text-2xl rounded opacity-80`} />
      </div>
      <div className='flex-1 flex flex-col gap-y-1'>
        <p className='font-bold'>{process.text || 'dad'}</p>
        <div className='text-gray-400 text-sm font-semibold'>
          <p>Creado el {moment(process.date).format('LL')}</p>
          <p>{process.status} </p>
        </div>
      </div>
    </div>
  )
}