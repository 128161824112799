import moment from 'moment';
const TableCard = ({
  id,
  key,
  code,
  name,
  responsible,
  customer,
  estimatedCloseDate,
  estimatedOpenDate,
  realCloseDate,
  realOpenDate,
  status,
  assignedAmount,
  selectedRow
}) => {
  return (
    <div
      key={`card-${key}`}
      className="block p-4 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 card-project"
    >
      <div className="flex justify-between">
        <h5 className="mb-1 text-md font-bold text-gray-900 dark:text-white title-card-wt-status">
          {name}
        </h5>
        <div className="flex">
          <div className="mb-1 text-gray-700 dark:text-gray-400 status-card">
            <h5 className="text-md text-gray-900 dark:text-white">
              {status}
            </h5>
          </div>       
          <i
            className="pi pi-ellipsis-v cursor-pointer mt-auto mb-auto"
            onClick={(event) => selectedRow(event, { id })}
          ></i>
        </div>        
      </div>
      <hr />
      <div className="grid grid-cols-1 mt-3 text-gray-700 dark:text-gray-400">
        <div class="flex">
          <p className="text-xs font-bold">Responsable:</p>&nbsp;
          <p className="text-xs"> {responsible}</p>
        </div>              
      </div>
      <div className="grid grid-cols-3 text-gray-700 dark:text-gray-400 mt-3 gap-x-2">
        <div>
          <p className="text-xs font-bold">Apertura Estimada:</p>
          <p className="text-xs">{estimatedOpenDate}</p>
        </div>
        <div>
          <p className="text-xs font-bold">Cierre Estimado:</p>
          <p className="text-xs">
            {estimatedCloseDate}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-3 text-gray-700 dark:text-gray-400 mt-3 gap-x-2">
        <div>
          <p className="text-xs font-bold">Apertura Real:</p>
          <p className="text-xs">{realOpenDate}</p>
        </div>
        <div>
          <p className="text-xs font-bold">Cierre Real:</p>
          <p className="text-xs">
            {realCloseDate}
          </p>
        </div>
        <div>
          <p className="text-xs font-bold">Monto Adjudicado:</p>
          <p className="text-xs">
            {assignedAmount}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TableCard;
