import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { AppSettingsAction } from 'features/configuration/store/actions/AppSettingsAction';

const useUnitMeasure = () => {
  const { updateTitle } = useSetTitlePage();
  const { isLoading, isLoadingSave, data, id } = useSelector(
    (state) => state.appSettingsReducer.currentUnitsOfMeasure
  );

  useEffect(() => {
    updateTitle({
      title: 'Configuración',
      subtitle: 'Sistema',
      description: 'Unidad de Medida',
    });
  }, []);

  const [listUnitMeasure, setListUnitMeasure] = useState([]);
  const [currentUnitID, setCurrentUnitID] = useState('');
  const [currentAction, setCurrentAction] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isError, setIsError] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data.length > 0) {
      const units = formatingData(data);
      setListUnitMeasure(units);
    }else{
      setListUnitMeasure([])
    }
  }, [data]);

  const formatingData = (data) => {
    return data.map(({ Id, Code, Value, action }) => ({
      id: Id,
      code: Code,
      value: Value,
      action,
    }));
  };

  const createUnit = () => {
    const id = uuidv4();
    const currentUnits = [
      {
        id,
        code: '',
        value: '',
        action: 'edition',
        isNew: true,
      },
      ...listUnitMeasure.map((u) => ({ ...u, action: 'save' })),
    ];
    setCurrentUnitID(id);
    setCurrentAction('edition');
    setListUnitMeasure(currentUnits);
    setIsDisabled(true);
  };

  const changeAction = (id, changeActionTo) => {
    if (hasEmptyValues()) return;

    let units = [...listUnitMeasure];
    if (changeActionTo === 'edit' && currentUnitID) {
      units = [...formatingData(data)];
    }
    setCurrentAction(changeActionTo);
    setCurrentUnitID(id);
    if (changeActionTo === 'edit') {
      const newUnits = units.map((unit) => {
        if (unit.id == id) unit.action = changeActionTo;
        return unit;
      });
      setListUnitMeasure(newUnits);
    } else if (changeActionTo === 'save') {
      saveUnits();
    }
  };

  const changeField = (id, { name, value }) => {
    const newUnits = listUnitMeasure.map((unit) => {
      if (unit.id == id) unit[name] = value;
      return unit;
    });
    setListUnitMeasure(newUnits);
  };

  const buildapplicationSettingDetails = (currentUnits) => {
    return [...currentUnits].map((unit) => {
      const { action, ...rest } = unit;
      rest.applicationSettingId = id;
      if (rest.isNew) {
        rest.id = 0;
        delete rest.isNew;
      }
      return rest;
    });
  };
  const deleteUnit = (ID) => {
    setCurrentAction('delete');
    setCurrentUnitID(ID);
    setIsDisabled(false);

    const newsUnits = [...listUnitMeasure].filter((unit) => unit.id !== ID);
    const currentUnits = {
      id,
      applicationSettingDetails: buildapplicationSettingDetails(newsUnits),
    };
    dispatch(
      AppSettingsAction.saveSettings({
        data: currentUnits,
        settingName: 'setting-units-delete',
      })
    );
  };

  const saveUnits = () => {
    const currentUnits = {
      id,
      applicationSettingDetails:
        buildapplicationSettingDetails(listUnitMeasure),
    };
    dispatch(
      AppSettingsAction.saveSettings({
        data: currentUnits,
        settingName: 'setting-units-save',
      })
    );
  };

  const hasEmptyValues = () => {
    const emptyValues = listUnitMeasure.filter(
      ({ code, value }) => !code || !value
    );
    const emptyValuesLength = emptyValues.length > 0;
    setIsError(emptyValuesLength);
    setIsDisabled(emptyValuesLength);
    return emptyValuesLength;
  };
  return {
    isLoading,
    listUnitMeasure,
    createUnit,
    deleteUnit,
    changeAction,
    changeField,
    isLoadingSave,
    currentUnitID,
    currentAction,
    isDisabled,
    isError,
  };
};

export default useUnitMeasure;
