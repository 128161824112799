import { useForm } from 'react-hook-form';

const useManagment = () => {


  let defaultValuesModal = {
    isEnabled: false,
    name: null,
    roles: []
  };

  const {
    // register,
    setValue,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({defaultValuesModal});

  const existError = Object.keys(errors).length > 0
  const valuesFields = watch();

  return {
    control,
    errors,
    existError,
    setValue,
    handleSubmit,
    reset,
    valuesFields
  }
}

export default useManagment