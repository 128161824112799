import { Fragment } from 'react';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useAdvancedFilterProject } from 'features/customers/hooks/project/useAdvancedFilterProject';
import { MultiSelect } from 'primereact/multiselect';
import PinerComponent from 'shared/components/Piner/PinerComponent';

export const FilterComponent = () => {
  const { projectLoading, dataMaster, viewHandleChange, viewOnAdvancedFilter, viewFormValues, piners, removePiner, viewReset } = useAdvancedFilterProject();
  // este filtro es diferente
 
  return (
    <Fragment>
      <Panel header="FILTRO POR" toggleable>
        <form className="filter-roles" onSubmit={viewOnAdvancedFilter}>
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-1">
              <span className="p-float-label p-input-icon-left w-full">
                <span className="p-float-label">
                <MultiSelect
                  value={viewFormValues.customerId}
                  options={dataMaster?.companyCustomers}
                  tooltip={viewFormValues.customerId?.map( item => item.name).join(', ').length > 0 && viewFormValues.customerId.map( item => item.name).join(', ')}
                  
                  onChange={viewHandleChange}
                  optionLabel="name"
                  name="customerId"
                  className="w-full"
                  selectedItemsLabel={`${viewFormValues?.customerId?.length} elementos seleccionados`}
                  maxSelectedLabels={2} />
                  <label htmlFor="multiselect">Por Empresa</label>
                </span>
              </span>
            </div>
            <div className="col-span-1">
              <span className="p-float-label p-input-icon-left w-full">
                <span className="p-float-label">
                <MultiSelect
                  value={viewFormValues.responsibleId}
                  options={dataMaster?.responsibles}
                  tooltip={viewFormValues.responsibleId?.map( item => item.fullName).join(', ').length > 0 && viewFormValues.responsibleId.map( item => item.fullName).join(', ')}
                  tooltipOptions={{position: 'bottom'}}
                  onChange={viewHandleChange}
                  optionLabel="fullName"
                  name="responsibleId"
                  className="w-full"
                  selectedItemsLabel={`${viewFormValues?.responsibleId?.length} elementos seleccionados`}
                  maxSelectedLabels={3} />
                  <label htmlFor="multiselect">Por Responsable</label>
                </span>
              </span>
            </div>
            <div className="col-span-1">
              <span className="p-float-label p-input-icon-left w-full">
                <span className="p-float-label">
                <MultiSelect
                  value={viewFormValues.statusId}
                  tooltip={viewFormValues.statusId?.map( item => item.description).join(', ').length > 0 && viewFormValues.statusId.map( item => item.description).join(', ')}
                  options={dataMaster?.status}
                  onChange={viewHandleChange}
                  optionLabel="description"
                  name="statusId"
                  className="w-full"
                  selectedItemsLabel={`${viewFormValues?.statusId?.length} elementos seleccionados`}
                  maxSelectedLabels={3} />
                  <label htmlFor="multiselect">Por Estado</label>
                </span>
              </span>
            </div>
          </div>
          <div className="filter-users-action">
            <div className="flex gap-3 w-full">
              <Button
                icon="pi pi-search"
                type="submit"
                label="Buscar"
                loading={projectLoading}
                className="sig-button sig-primary w-full"
              />
              <Button
                icon="pi pi-times-circle"
                type="button"
                label="Limpiar"
                className="sig-button sig-success w-full"
                onClick={viewReset}
              />
            </div>
          </div>
        </form>
      </Panel>
      <div className="filter-piners mt-3">
          {piners.map((el,index) => {
            return <PinerComponent
              name={el.value}
              field={el.field}
              removePiner={removePiner}
              key={index}
        />
          })}
      </div>
    </Fragment>
  );
};
