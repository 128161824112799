import { useClaimProjectRoles } from "features/maintenance/hooks/useClaimProjectRoles";
import { ProjectRoleAction } from "features/maintenance/store/actions/ProjectRoleAction";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useSetTitlePage from "shared/hooks/useSetTitlePage";

const { useForm, Controller } = require("react-hook-form");

const ProjectRolePage = ({ title, mode }) => {
  const { updateTitle } = useSetTitlePage();
  const history = useHistory();
  const dispatch = useDispatch();
  const projectRole = useSelector(
    (state) => state.projectRoleReducer.projectRole
  );

  const [isFirstLoading, setFirstLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  const { isCreate, isEdit } = useClaimProjectRoles();

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    name: "",
    id: null,
  });

  const params = useParams();

  const redirectToSearch = () => {
    history.push("/mantenimiento/rol-proyecto");
    dispatch(ProjectRoleAction.resetAfterSaved());
  };

  useEffect(() => {
    if (projectRole?.data) {
      setValue("name", projectRole.data.name);
      setValue("id", projectRole.data.id);
    }
    if (projectRole?.isSaved) redirectToSearch();
  }, [projectRole]);

  useEffect(() => {
    setValue("name", undefined);
    setValue("id", undefined);

    updateTitle({
      title: "Mantenimietno",
      subtitle: "Rol de Proyecto",
      description: title,
      previousUrl: "/mantenimiento/rol-proyecto"
    });
    if (isFirstLoading) {
      setFirstLoading(false);
      if (params.id) getProjectRoleById(params.id);
    }
  }, []);

  const getProjectRoleById = (projectRoleId) => {
    dispatch(ProjectRoleAction.getProjectRoleById(projectRoleId));
  };

  const onSubmit = (data) => {
    const model = {
      id: data.id,
      name: data.name,
    };

    dispatch(ProjectRoleAction.saveProjectRole(model));
  };

  const reject = () => {
    setVisible(false);
  };

  return (
    <div className="bg-white">
      <div className="form-custom p-0">
        <div className="form-modal p-0">
          <Panel header="DATOS GENERALES" toggleable>
            {projectRole.isLoading ? (
              <Skeleton height="2.2rem" />
            ) : (
              <form id="form-project-role" className=" grid  sm:grid-cols-1 md:grid-cols-1 gap-1 mt-2 mb-1" onSubmit={handleSubmit(onSubmit)}>
                <span className="p-float-label w-full mt-4">
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                    }) => (
                      <InputText
                        type="text"
                        id="name"
                        name="name"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        className={errors?.name ? "p-invalid w-full" : "w-full"}
                      />
                    )}
                    name="name"
                    rules={{
                      required: "El nombre de la linea de negocio es requerida",
                    }}
                  />

                  <label htmlFor="name">Nombre de rol de proyecto *</label>
                </span>
              </form>
            )}
          </Panel>
        </div>
        {errors.name && (
          <div className="message error">
            <ul>
              {errors.name ? (
                <>
                  <li className="p-error">{errors.name.message}</li>
                </>
              ) : null}
              {errors.code ? (
                <>
                  <li className="p-error">{errors.code.message}</li>
                </>
              ) : null}
            </ul>
          </div>
        )}
        <div className="flex justify-end gap-4 mt-3">
          <Button
            icon="pi pi-times"
            type="button"
            label="Cancelar"
            loading={projectRole?.isSaving || projectRole?.isLoading}
            onClick={() => {
              setVisible(true);
            }}
            className="sig-button sig-secondary mt-4"
          />
          {
            (mode!=='view')&&(<>
            {
              (isCreate || isEdit)&&
                <Button
                  icon="pi pi-save"
                  type="submit"
                  form="form-project-role"
                  label="Guardar"
                  loading={projectRole?.isSaving || projectRole?.isLoading}
                  className="sig-button sig-primary mt-4"
                />
            }
            </>
            )
          }
        </div>
      </div>
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="¿Está seguro que desea cancelar?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={redirectToSearch}
        reject={reject}
      />
    </div>
  );
};

export default ProjectRolePage;
