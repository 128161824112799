import { Fragment, useEffect } from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';

import FilterComponent from 'features/configuration/components/users/FilterComponent';
import TableComponent from 'features/configuration/components/users/TableComponent';
import { UsersAction } from 'features/configuration/store/actions/UsersAction';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import useTable from 'features/configuration/hooks/users/useTable';
import './UsersPage.scss';
import { useClaimUsers } from 'features/configuration/hooks/useClaimUsers';

const UsersPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const dataManager = useSelector((state) => state.userReducer.dataManager);
  const { isOpen } = useSelector((state) => state.userReducer.userDeleteModal);
  const { cancelDelete, confirmDelete } = useTable();

  const { isSearch, isDownload, isCreate } = useClaimUsers();

  const { updateTitle } = useSetTitlePage();
  useEffect(() => {
    updateTitle({
      title: 'Configuración',
      subtitle: 'Gestión de Usuarios',
    });
  }, []);

  useEffect(() => {
    if(dataManager.data){
        dispatch(UsersAction.getInitialStatus());
    }
  }, [dataManager.data]);

  useEffect(() => {
    const { data } = dataManager;

    const cantList =
      data?.roles?.length + data?.status?.length + data?.company?.length;
    if (cantList === 0) dispatch(UsersAction.getDataMaster());
  }, []);

  return (
    <Fragment>
      {
            (isSearch||isDownload)&&(
                    <FilterComponent />
                )
            }
            {
                isCreate&&(
                  <Button
                    icon="pi pi-plus"
                    type="button"
                    label="Nuevo"
                    className="sig-button sig-dark mt-4"
                    onClick={() => {
                      history.push('/configuracion/usuarios/nuevo');
                    }}
                  />
                )
            }
      <TableComponent />

      <ConfirmDialog
        visible={isOpen}
        onHide={cancelDelete}
        message="¿Está seguro que desea eliminar el registro?"
        header="Eliminar"
        icon="pi pi-trash"
        accept={confirmDelete}
        reject={cancelDelete}
      />
    </Fragment>
  );
};

export default UsersPage;
