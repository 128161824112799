import React from 'react';
import { Panel } from 'primereact/panel';
import { Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Editor } from 'primereact/editor';

const header = <>
<span className="ql-formats">
	<button className="ql-bold" aria-label="Bold"></button>
	<button className="ql-italic" aria-label="Italic"></button>
	<button className="ql-underline" aria-label="Underline"></button>
</span>
<span className="ql-formats">
	<button
		className="ql-list"
		aria-label="Ordered"
		value="ordered"
	></button>
	<button className="ql-list" aria-label="Bullet" value="bullet"></button>
</span>
</>

const validateLength = (value) => {
	if (typeof value === 'string') {
		return value.replace(/<[^>]+>/g, '')?.length;
	} else if (value?.htmlValue) {
		return value.htmlValue.replace(/<[^>]+>/g, '').length;
	}
	return '0';
};

const FormComponent = ({watch, control, errors}) => {

	const valuesFields = watch()

	return (
		<div>
			<Panel header='Datos Generales'>
				
				<div className="grid sm:grid-cols-3 md:grid-cols-3 gap-2">
					<span className="p-float-label w-full mt-4">
						<Controller
							control={control}
							name="name"
							rules={{
								required: 'El Nombre es requerido',	
							}}
							render={({field: { onChange, value }}) => (
								<InputText
									type="text"
									id="name"
									onChange={onChange}
									value={value || ""}
									className={errors?.name ? 'p-invalid w-full' : 'w-full'}
								/>
							)}
						/>
						<label htmlFor="name">Nombre *</label>
				</span>
				<span className="p-float-label w-full mt-4 hidden md:block">
						&nbsp;
				</span>
				<span className="p-float-label w-full mt-4 hidden md:block">
						&nbsp;
				</span>
				</div>
				<div className='mt-5'>
				<Controller
					name="description"
					control={control}
					render={({ field: { onChange, value } }) => (
						<span className="w-full mt-4">
							<label htmlFor="description" style={{fontSize: "0.75rem", color:"#ced4da"}}>Descripción</label>
							<Editor
								headerTemplate={header}
								onTextChange={(e) => onChange(e.htmlValue)}
								value={value || ""}
								className={`${errors.description?.type ==='required' ? 'p-invalid': ''}`}
							/>
							<p className="font-semibold text-base text-gray-500 lg:mt-1 mt-3 text-right">
								Caracteres{' '}
								{validateLength(
									valuesFields.description
								)}{' '}
								/ 4000{' '}
							</p>
						</span>
					)}
				/>
				</div>
				
			</Panel>
		</div>
	);
};

export default FormComponent;