import { Editor } from "primereact/editor"
import { InputText } from "primereact/inputtext"
import { Controller } from "react-hook-form"
import { DynamicFieldsConstants } from "shared/commons/DynamicFieldsConstans";
import HeaderEditor from "shared/components/header-editor/HeaderEditor";

export const DynamicField = ({identifier, control, isRequired, label, fieldTypeCode, maxLength}) => {

  const header = HeaderEditor();


  // const removeTags = (input) => {
  //   if (!input) return "";
  //   return input.replace(/<[^>]*>/g, '');
  // }

  return(
    <Controller
      name={`dynamicFieldData:${identifier}`}
      control={control}
      rules={isRequired ? { required: `${label} es obligatorio` } : {}}
      render={({ field: { onChange, value }, fieldState: {error} }) => {
        if(fieldTypeCode === DynamicFieldsConstants.CodeFields.SIMPLE_TEXT){
          return (
            <span className="p-float-label w-full">
            <InputText
              className={`w-full ${ error ? " p-invalid": ""}`}
              onChange={onChange}
              value={value || ""}
              maxLength={maxLength}
              />
            <label>{label}{isRequired ? " *" : ""}</label>
          </span>
          )
        }
        
        if(fieldTypeCode === DynamicFieldsConstants.CodeFields.RICH_TEXT){
          // console.log(value.length)
          // console.log(removeTags(value)?.length > 5)

          return(
            <span className="w-full">
              <label style={{fontSize: 12, color: "rgb(206, 212, 218)"}}>{label}{isRequired ? " *" : ""}</label>
              <Editor
                value={value} 
                headerTemplate={header}
                className={`w-full ${ error ? " p-invalid": ""}`}
                onTextChange={(e) => {
                  onChange(e.htmlValue)
                }} 
              />
            </span>
          )
        }
      }}
    />
  )
}