import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RolesAction } from 'features/configuration/store/actions/RolesAction';
import { RolesTableData } from 'features/configuration/data/roles/RolesTableData';
import validateTypeValue from 'shared/helpers/validateTypeValue';
import { useClaimRolesPrivileges } from '../useClaimRolesPrivileges';

const useTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const roles = useSelector((state) => state.roleReducer.roles);

  const { isEdit, isDelete } = useClaimRolesPrivileges()

  /* CURRENT USER ------------------- */
  const currentUserId = useSelector(
    (state) => state.roleReducer.currentUser.id
  );
  const setCurrentUser = (user_id) => {
    dispatch(RolesAction.setCurrentUser(user_id));
  };

  /* TABLE COLUMNS ---------------- */
  const [selectColumns, _] = useState(RolesTableData.columns);
  const [columnsGrid, __] = useState(RolesTableData.columnsGrid);

  /* TABLE -------------------------- */
  const [tableRoles, setTableRoles] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState([]);

  useEffect(() => {
    setTableRoles(validateTypeValue(roles.data, "Num"));
    setTablePagination(roles.pagination);
    setTableLoading(roles.loading);
  }, [roles]);

  /* TABLE CURRENT ACTION -------------------- */
  const [currentAction, setCurrentAction] = useState('');
  const onCurrentAction = ({ item: { name } }) => setCurrentAction(name);

  useEffect(() => {
    if (currentUserId && currentAction) {
      const roleId = currentUserId;
      const action_ = currentAction.toLocaleLowerCase();
      if (action_ === 'editar') {
        history.push(`/configuracion/rol-privilegios/editar/${roleId}`);
      } else if (action_ === 'eliminar') {
        dispatch(RolesAction.showDeleteRoleModal({ isOpen: true, roleId }));
      }
    }
    setCurrentAction('');
  }, [currentAction]);

  /* TABLE ACTIONS ------------- */
  const userOptions = useSelector(
    (state) => state.roleReducer.roleActions.actions
  );
  const [tableActions, setTableActions] = useState([]);
  const tableCurrentActions = useRef(null);
  const [showActions, setShowActions] = useState(false);

  useEffect(() => {
    if (userOptions.length > 0) {
      let newActions = userOptions.map((option) => {
        option.label = option.name;
        option.command = onCurrentAction;
        return option;
      });
      if (!isEdit)
        newActions = newActions.filter(c => c.label !== 'Editar');
      if (!isDelete)
        newActions = newActions.filter(c => c.label !== 'Eliminar');
      setTableActions(newActions);
    }
    setShowActions(userOptions.length > 0);
  }, [isEdit, isDelete]);

  /* MODAL DELETE (ACTIONS) --------------------- */
  const confirmDelete = () => {
    dispatch(RolesAction.deleteRoleModal(currentUserId));
  };

  const cancelDelete = () => {
    dispatch(RolesAction.showDeleteRoleModal({ isOpen: false, roleId: '' }));
  };

  /* TABLE GRID ---------------------- */
  const [usersByRole, setUsersTable] = useState([]);
  const [currentUserGrid, setCurrentUserGrid] = useState(null);
  const currentUserByRol = useSelector(
    (state) => state.roleReducer.userByRoleId
  );

  useEffect(() => {
    /* COLLAPSE CURRENT GRID */
    if (Array.isArray(currentUserGrid) && currentUserGrid.length > 1) {
      setCurrentUserGrid([currentUserGrid.pop()]);
    }
    setUsersTable(validateTypeValue(currentUserByRol.data));
  }, [currentUserByRol]);
  const getUserRoleById = ({ data: { roleId } }) => {
    dispatch(RolesAction.getUserByRoleId(roleId));
  };

  const clearUserRoleById = () => {
    dispatch(RolesAction.setUserRoleById({ loading: false, data: [] }));
  };

  /* TABLE PAGINATION --------------- */
  const roleSearch = useSelector((state) => state.roleReducer.roles);
  const [tableRows, setTableRows] = useState(10);
  const [tableNum, setTableNum] = useState(0);
  const [page, setPage] = useState(1);

  let currentFilterFields = {
    columnOrder: roleSearch.currentCol,
    order: roleSearch.order,
  };

  const onChangePage = ({ rows, page }) => {
    currentFilterFields.page = page + 1;
    currentFilterFields.pageSize = rows;
    setTableRows(rows);
    setTableNum(rows * page);
    setPage(page + 1);
    buildFilterValues();
    const fields = currentFilterFields;
    dispatch(RolesAction.getRoles({ change: true, fields }));
  };

  /* TABLE ORDER SORT */
  const [tableSort, setTableSort] = useState({
    sortField: 'roleName',
    sortOrder: '1',
  });
  const onSort = (event) => {
    const params = {
      first: tableNum,
      rows: tableRows,
      multiSortMeta: [],
      filters: null,
      ...event,
    };
    buildFilterValues();

    currentFilterFields.order = event.sortOrder + '' === '1' ? 'asc' : 'desc';
    currentFilterFields.columnOrder = event.sortField;
    currentFilterFields.pageSize = tableRows;
    currentFilterFields.page = page;
    const fields = currentFilterFields;
    dispatch(RolesAction.getRoles({ change: true, fields }));
    setTableSort(params);
  };

  /* TABLE FILTERS VALUES -------------- */
  const filterValues = useSelector(
    (state) => state.roleReducer.filterRole.values
  );
  const buildFilterValues = () => {
    if (filterValues.length > 0) {
      filterValues.forEach(({ field, value, date }) => {
        currentFilterFields[field] = field === 'name' ? value : date;
      });
    }
  };

  return {
    roles,
    tableRoles,
    selectColumns,
    columnsGrid,
    onChangePage,
    tableLoading,
    tableRows,
    tableNum,
    tableCurrentActions,
    showActions,
    tablePagination,
    tableActions,
    setCurrentUser,
    confirmDelete,
    cancelDelete,
    currentUserGrid,
    setCurrentUserGrid,
    getUserRoleById,
    clearUserRoleById,
    currentUserByRol,
    usersByRole,
    tableSort,
    setTableSort,
    onSort,
  };
};

export default useTable;
