import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Menu } from "primereact/menu";
import { Paginator } from "primereact/paginator";
import { Checkbox } from "primereact/checkbox";
import { OverlayPanel } from "primereact/overlaypanel";
import { SelectButton } from "primereact/selectbutton";

import { CoreConstants } from "core/commons/CoreConstants";
import typeViews from "shared/utils/typeViews";
import { PositionsTableData } from "features/rrhh/data/positions/PositionsDataTable";
import TableCard from "./TableCard";
import useTable from "features/rrhh/hooks/positions/useTable";
import { useClaimPositions } from "features/rrhh/hooks/useClaimPositions";
import { Link } from "react-router-dom";

const TableComponent = () => {
  const {
    typeView,
    setTypeView,
    onColumnToggle,
    selectColumns,
    tableLoading,
    tablePositions,
    headerActions,
    tableCurrentActions,
    tableActions,
    showActions,
    tablePagination,
    onChangePage,
    tableNum,
    tableRows,
    tableSort,
    onSort,
    setCurrentPosition,
  } = useTable();

  const { isView ,isEdit, isDelete } = useClaimPositions();

  const getViewField = (rowData, col) => {
    if (isView)
      return <Link className="underline text-blue-700" to={{ pathname: `/rrhh/cargos/visualizar/` + rowData.id }} >{rowData.name}</Link>
    else
      return rowData.name;
  }

  const ColumnComponents = selectColumns.map((col) => {

    if (col.field === 'name')
          return <Column key={col.field} header={col.header} body={(rowData) => getViewField(rowData, col)} sortable />

    return (
      <Column key={col.field} field={col.field} header={col.header} sortable />
    );
  });

  const TableHeaderActions = () => {
    return (
      <i
        key="action"
        className="pi pi-ellipsis-v cursor-pointer ml-1"
        onClick={(e) => headerActions.current.toggle(e)}
      ></i>
    );
  };

  const selectedRow = (event, { id }) => {
    setCurrentPosition(id);
    tableCurrentActions.current.toggle(event);
  };

  const TableBodyActions = (selectedItem) => {
    if(isEdit||isDelete){
      return (
        <i
          onClick={(event) => selectedRow(event, selectedItem)}
          className="pi pi-ellipsis-v cursor-pointer"
        ></i>
      );
    }else{
      return <div></div>
    }
  };

  const HeaderColumns = () => {
    return PositionsTableData.columns.map(({ field, header }) => (
      <div key={field} className="p-field-checkbox py-1">
        <Checkbox
          inputId={field}
          name="column"
          value={field}
          onChange={(e) => onColumnToggle(e, { field, header })}
          checked={selectColumns.some((x) => x.field === field)}
        />
        <label class="ml-1" htmlFor={field}>
          {header}
        </label>
      </div>
    ));
  };

  const showPagination = !tableLoading && tablePositions.length > 0;
  return (
    <div className="mt-5">
      <div className="flex justify-end mb-2">
        <SelectButton
          unselectable={false}
          value={typeView}
          options={typeViews}
          itemTemplate={({ icon }) => <i className={icon}></i>}
          onChange={(e) => setTypeView(e.value)}
        />
      </div>
      {typeView === CoreConstants.TypeView.LIST && (
        <DataTable
          value={tablePositions}
          emptyMessage="No se encontraron cargos"
          stripedRows
          rows={10}
          rowHover
          size="small"
          responsiveLayout="scroll"
          onSort={onSort}
          first={tableSort?.first}
          sortField={tableSort?.sortField}
          sortOrder={tableSort?.sortOrder}
          filters={tableSort?.filters}
        >
          {ColumnComponents}
          {showActions && (
            <Column
              header={TableHeaderActions}
              body={TableBodyActions}
            ></Column>
          )}
        </DataTable>
      )}
      {typeView === CoreConstants.TypeView.CANVA && (
        <div className="grid grid-cols-4 gap-2">
          {tablePositions.map((item, index) => (
            <TableCard
              {...item}
              id={item.id}
              key={index}
              selectedRow={selectedRow}
            />
          ))}
        </div>
      )}
      {showPagination && typeView === CoreConstants.TypeView.LIST && (
        <Paginator
          className="paginator-custom mt-4"
          rowsPerPageOptions={[5, 10, 15]}
          first={tableNum}
          rows={tableRows}
          totalRecords={tablePagination?.rowCount}
          onPageChange={onChangePage}
        ></Paginator>
      )}

      <OverlayPanel
        ref={headerActions}
        showCloseIcon
        style={{ width: "300px" }}
      >
        <HeaderColumns />
      </OverlayPanel>
      <Menu model={tableActions} popup ref={tableCurrentActions} />
    </div>
  );
};

export default TableComponent;
