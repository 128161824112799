import React from "react";
import {
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min";
import CoursePage from "./pages/courses/course/CoursePage";
import SearchCoursePage from "./shared/search/SearchCourseComponent";

import SearchCourseMonitoring from './pages/CoursesMonitoring/Search/SearchCourseMonitoring';
import CourseMonitoringPage from './pages/CoursesMonitoring/CourseMonitoring/CourseMonitoringPage';

import courseReducer from "./store/reducers/CourseReducer";
import SchedulePage from "./pages/courses/schedule/SchedulePage";
import SearchSchedulePage from "./pages/courses/search/SearchSchedulePage";
import scheduleReducer from "./store/reducers/ScheduleReducer";
import AttendancePage from "./pages/courses/attendance/AttendancePage";
import SearchAttendancePage from "./pages/courses/search/SearchAttendancePage";
import attendanceReducer from "./store/reducers/AttendanceReducer";
import courseExpensesReducer from "./store/reducers/CourseExpensesReducer";
import courseMonitoringReducer from "./store/reducers/CourseMonitoringReducer";
import SearchCoursesExpensesPage from "./pages/CoursesExpenses/Search/SearchCoursesExpensesPage";
import CoursesExpensesPage from "./pages/CoursesExpenses/CoursesExpenses/CoursesExpensesPage";
import SearchPaymentCourse from "./pages/PaymentCourses/Search/SearchPaymentCourse";
import paymentCourseReducer from "./store/reducers/PaymentCourseReducer";
import { PaymentCoursePage } from "./pages/PaymentCourses/PaymentCoursePage/PaymentCoursePage";

export default function CoursesManagmentRouting(reducerRegistry) {
    const { path } = useRouteMatch();
    reducerRegistry.register({ courseReducer })
    reducerRegistry.register({ courseMonitoringReducer })
    reducerRegistry.register({ scheduleReducer })
    reducerRegistry.register({ attendanceReducer })
    reducerRegistry.register({ courseExpensesReducer })
    reducerRegistry.register({ paymentCourseReducer })

    return (
      <>
        <Switch>
          <Route path={`${path}/cursos`}>
            <Switch>
            <Route exact path={`${path}/cursos`} component={SearchCoursePage} />
            <Route
              path={`${path}/cursos/nuevo`}
              exact
              component={() => <CoursePage title="Nuevo Curso" />}
            />
            <Route
              path={`${path}/cursos/:id`}
              exact
              component={()=><CoursePage title="Editar Curso"/>} 
            />
            <Route
              path={`${path}/cursos/visualizar/:id`}
              exact
              component={()=><CoursePage title="Ver Curso" typeMode='view' />} 
            />
              </Switch>       
          </Route>

          <Route path={`${path}/seguimiento`}>
            <Switch>
              <Route exact path={`${path}/seguimiento`} component={SearchCourseMonitoring} />
              <Route 
                path={`${path}/seguimiento/:id`}
                exact
                component={()=><CourseMonitoringPage title="Editar Curso"/>} 
              />
              <Route 
                path={`${path}/seguimiento/visualizar/:id`}
                exact
                component={()=><CourseMonitoringPage title="Ver Curso" mode='view' />} 
              />
            </Switch>       
          </Route>
          
          <Route path={`${path}/programacion`}>
            <Switch>
              <Route
                exact
                path={`${path}/programacion`}
                component={SearchSchedulePage}
              />
              <Route
                path={`${path}/programacion/:id`}
                exact
                component={() => <SchedulePage title="Editar Programación" />}
              />
              <Route
                path={`${path}/programacion/visualizar/:id`}
                exact
                component={() => <SchedulePage title="Ver Programación" mode='view' />}
              />
            </Switch>
          </Route>

          <Route path={`${path}/participantes`}>
            <Switch>
              <Route
                exact
                path={`${path}/participantes`}
                component={SearchAttendancePage}
              />
              <Route
                path={`${path}/participantes/:id`}
                exact
                component={() => <AttendancePage title="Editar Participantes" />}
              />
              <Route
                path={`${path}/participantes/visualizar/:id`}
                exact
                component={() => <AttendancePage title="Ver Participantes" mode='view' />}
              />
            </Switch>
          </Route>

          <Route path={`${path}/gastos`}>
            <Switch>
              <Route
                exact
                path={`${path}/gastos`}
                component={SearchCoursesExpensesPage}
              />
              <Route
                path={`${path}/gastos/nuevo`}
                exact
                component={() => <CoursesExpensesPage title="Nuevo Gasto" />}
              />
              <Route
                path={`${path}/gastos/:id`}
                exact
                component={() => <CoursesExpensesPage title="Editar Gasto" />}
              />
              <Route
                path={`${path}/gastos/visualizar/:id`}
                exact
                component={() => <CoursesExpensesPage title="Ver Gasto" mode='view' />}
              />
            </Switch>
          </Route>
          <Route path={`${path}/pagos`}>
            <Switch>
              <Route
                exact
                path={`${path}/pagos`}
                component={SearchPaymentCourse}
              />
              <Route
                exact
                path={`${path}/pagos/nuevo`}
                component={()=> <PaymentCoursePage description="Nuevo Pago" />}
              />
              <Route
                exact
                path={`${path}/pagos/:id`}
                component={()=> <PaymentCoursePage description="Editar Pago" />}
              />
              <Route
                exact
                path={`${path}/pagos/visualizar/:id`}
                component={()=> <PaymentCoursePage description="Ver Pago" mode='view' />}
              />
            </Switch>
          </Route>
        </Switch>
      </>
    )
}
