import { MaintenanceConstants } from "features/maintenance/commons/MaintenanceConstans"; 
import { CoreConstants } from 'core/commons/CoreConstants';
import moment from 'moment';
import { toastAction } from "core/store/actions/ToastAction";
import FileSaver from 'file-saver';
import { ProfessionsService } from "features/maintenance/services/ProfessionsServices";


const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: MaintenanceConstants.Accion.Profession.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};
const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: MaintenanceConstants.Accion.Profession.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.name)
      pines.push({ field: 'name', value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });


    advancedFilter.values = pines;
    dispatch({
      type: MaintenanceConstants.Accion.Profession.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setProfessions = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Profession.SEARCH_PROFESSIONS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const professions = getState().professionReducer.professions;
    setProfessions({
      dispatch: dispatch,
      isLoading: true,
      data: professions.data,
    });

    const criteria = {};
    const advancedFilter = getState().professionReducer.advancedFilter;
    const filter = getState().professionReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;
    
    
    ProfessionsService.searchProfessions(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setProfessions({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });          
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Profesiones', data.message));
          setProfessions({ dispatch: dispatch, isLoading: false, data: null });
        }else{
          setProfessions({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Profesiones', data.message));
        }
      },
      () => {
        setProfessions({ dispatch: dispatch, isLoading: false, data: null });
      }
    ).catch((error)=>{
    });
  };
};

const getProfessionById = (professionId) => {
  const setProfession = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Profession.GET_PROFESSION_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setProfession({ dispatch: dispatch, isLoading: true });
    ProfessionsService.getProfessionById(professionId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setProfession({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setProfession({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Profesiones', data.message));
          // dispatch({
          //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
          //   toast: {
          //     titulo: 'Departamento',
          //     mensaje: data.message,
          //     severidad: 'warn',
          //   },
          // });
        }else{
          dispatch(toastAction.error('Profesiones', data.message));
        }
      },
      () => {
        setProfession({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};

const saveProfession = (profession) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentProfession }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Profession.SAVE_PROFESSION,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    const currentProfession = getState().professionReducer.profession;
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
      //currentProfession: currentProfession,
    });
    ProfessionsService.saveProfessions(profession).then(
      ({ data }) => {      
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Profesión', 'La profesión se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentProfession, });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentProfession });
          dispatch(toastAction.warn('Profesiones', data.message));
        }else{
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
          dispatch(toastAction.error('Profesiones', data.message));
        }
      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};



const deleteProfession = (professionId) => {

  return (dispatch, getState) => {

    ProfessionsService.deleteProfession(professionId).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Profesión', 'La profesión se eliminó correctamente'));
          dispatch({ type: MaintenanceConstants.Accion.Profession.DELETE_PROFESSION, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Profesión', data.message));
          dispatch({ type: MaintenanceConstants.Accion.Profession.DELETE_PROFESSION, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: MaintenanceConstants.Accion.Profession.DELETE_PROFESSION, payload: { isDeleted: false } });
      }
    );
  };
};

const generateReport = (payload) => {
  const setProfessions = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Profession.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setProfessions({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().professionReducer.advancedFilter.filter;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    ProfessionsService.generateReport(criteria)
      .then(({ data }) => {
        setProfessions({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Profesion.xls');
      })
      .catch((err) => {
        setProfessions({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Profesión', 'Hubo un error al descargar el archivo'));
      });
  };
};

export const ProfessionAction = {
  toggleAdvancedFilter,
  updateFilter,
  search,
  saveProfession,
  deleteProfession,
  generateReport,
  resetAfterSaved,
  getProfessionById,
}