import React, { useEffect, useState } from 'react'
import { CoreConstants } from 'core/commons/CoreConstants';
import { SurveyAction } from 'features/survey/store/actions/SurveyAction';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog'
import { Divider } from 'primereact/divider';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SurveyAnalysisModalChart from './SurveyAnalysisModalChart';
import { Skeleton } from 'primereact/skeleton';

const SurveyAnalysisModalTrend = ({displayModal, setDisplayModal, surveyTitle}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.surveyReducer.surveyDataTrend);
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)

  useEffect(() => {
    setToDate(moment().format())       
  }, [])

  useEffect(() => {
    if (toDate && displayModal) {
      dispatch(SurveyAction.getSurveyTrendInfoById(params.id, fromDate, toDate));
    }
  }, [fromDate, toDate, params.id, dispatch, displayModal]);

  const onHide = () => {
    dispatch(SurveyAction.resetAfterCloseModalAnalysis());
    setDisplayModal(false)
  }

  const onDownloadSurveyTrendInfo = () => {
    dispatch(SurveyAction.generateSurveyTrendInfoReport(params.id, fromDate, toDate));
  }

  return (
    <Dialog header='Tendencias' visible={displayModal} className='w-4/5 lg:w-3/5' onHide={onHide}>
      <Divider />
      <div className='grid grid-cols-12 gap-x-4'>
        <div className='col-span-10 flex flex-col gap-y-0'>
          <h1 className='font-semibold text-lg'>{surveyTitle}</h1>
          <Divider />
          <div className='flex justify-evenly items-center gap-x-3'>
            <Calendar
                monthNavigator
                yearNavigator
                placeholder='Desde'
                yearRange="1950:2050"
                // showIcon={true}
                dateFormat={CoreConstants.Format.ddmmyy}
                value={fromDate === null ? '' : new Date(fromDate)}
                name="from"
                onChange={(e) => setFromDate(e.value)}
                className='w-full indicator-calendar flex-1'
            ></Calendar>
            <Calendar
                monthNavigator
                yearNavigator
                yearRange="1950:2050"
                placeholder='Hasta'
                // showIcon={true}
                dateFormat={CoreConstants.Format.ddmmyy}
                value={toDate === null ? '' : new Date(toDate)}
                name="to"
                minDate={fromDate === null ? '' : new Date(fromDate)}
                onChange={(e) => setToDate(e.value)}
                className='w-auto indicator-calendar flex-1'
            ></Calendar>
          </div>
          <Divider />
          <div className='flex flex-col gap-y-2'>
            <h1 className='font-semibold text-lg'>Respuestas por Hora</h1>
            {
              !data ? <Skeleton height='200px' /> : (
                <SurveyAnalysisModalChart typeModal='trend' labels={data[0]?.labels} data={data[0]?.datasets} />
              )
            }
          </div>
          <Divider />
          <div className='flex flex-col gap-y-2'>
            <h1 className='font-semibold text-lg'>Respuestas por Ubicación</h1>
            {
              !data ? <Skeleton height='200px' /> : (
                <SurveyAnalysisModalChart typeModal='trend' labels={data[1]?.labels} data={data[1]?.datasets} />
              )
            }
          </div>
          {/* <Divider />
          <div className='flex flex-col gap-y-2'>
            <h1 className='font-semibold text-lg'>Respuestas por Pregunta</h1>
            <Dropdown
                options={questionListWithoutTag}
                optionLabel="body"
                showClear
                className="w-1/2"
                value={questionSelected}
                onChange={e => setQuestionSelected(e.value)}
            />
            <SurveyAnalysisModalChart typeModal='trend' labels={labels} data={data} />
          </div> */}
        </div>
        <div className='col-span-2 flex justify-center'>
          <div className=''>
              <Button
                type="button"
                className='sig-button sig-success' 
                label="Descargar" 
                icon="pi pi-download" 
                onClick={onDownloadSurveyTrendInfo}
              />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default SurveyAnalysisModalTrend