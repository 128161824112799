import React, { Fragment, useState, useEffect } from "react";
import { SelectButton } from "primereact/selectbutton";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { CoreConstants } from "core/commons/CoreConstants";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import { Paginator } from "primereact/paginator";
import { useTableTasks } from "features/customers/hooks/tasks/useTableTasks";
import TableCard from "./TableCard";
import { TasksTableData } from "features/customers/data/tasks/TasksTableData";
import { Skeleton } from "primereact/skeleton";

export const TableComponent = () => {
  const {
    selectColumns,
    typeView,
    setTypeView,
    headerActions,
    showActions,
    tableTasks,
    tableLoading,
    tablePagination,
    tableCurrentActions,
    tableActions,
    tableNum,
    tableRows,
    tableSort,
    onSort,
    onChangePage,
    onColumnToggle,
    setCurrentTask,
  } = useTableTasks();

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const showPagination = !tableLoading && tableTasks.length > 0;

  const styles = {
    Completado: {
      border: "1px solid #8CC9A6",
      padding: "0rem 0.50rem",
      borderRadius: "5px",
    },
    Pendiente: {
      border: "1px solid #F6697C",
      padding: "0rem 0.50rem",
      borderRadius: "5px",
    },
  };
  const onBodyStatusTemplate = (rowData) => {
    return (
      <h1 className="text-center" style={styles[rowData.status]}>
        {rowData.status}
      </h1>
    );
  };

  const ColumnComponents = selectColumns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable
        body={col.field === "status" ? onBodyStatusTemplate : null}
      />
    );
  });

  const typeViews = [
    { icon: "pi pi-list", value: CoreConstants.TypeView.LIST },
    { icon: "pi pi-microsoft", value: CoreConstants.TypeView.CANVA },
  ];

  const TableHeaderActions = () => {
    return (
      <i
        key="action"
        className="pi pi-ellipsis-v cursor-pointer ml-1"
        onClick={(e) => headerActions.current.toggle(e)}
      ></i>
    );
  };

  const selectedRow = (event, { id }) => {
    setCurrentTask(id);
    tableCurrentActions.current.toggle(event);
  };

  const TableBodyActions = (selectedItem) => {
    return (
      <i
        onClick={(event) => selectedRow(event, selectedItem)}
        className="pi pi-ellipsis-v cursor-pointer"
      ></i>
    );
  };

  const HeaderColumns = () => {
    return TasksTableData.columns.map(({ field, header }) => (
      <div key={field} className="p-field-checkbox py-1">
        <Checkbox
          inputId={field}
          name="column"
          value={field}
          onChange={(e) => onColumnToggle(e, { field, header })}
          checked={selectColumns.some((x) => x.field === field)}
        />
        <label className="ml-1" htmlFor={field}>
          {header}
        </label>
      </div>
    ));
  };

  return (
    <div className="mt-5">
      <div className="flex justify-end mb-2">
        <SelectButton
          unselectable={false}
          value={typeView}
          options={typeViews}
          onChange={(e) => setTypeView(e.value)}
          itemTemplate={(option) => {
            return <i className={option.icon}></i>;
          }}
        />
      </div>

      {typeView === CoreConstants.TypeView.LIST && (
        <div
          className="mt-3 "
          style={{ maxWidth: window.innerWidth - 100 + "px" }}
        >
          { tableLoading ? <Skeleton height="8rem"/> :
            <DataTable
              value={tableTasks}
              stripedRows
              rows={10}
              rowHover
              responsiveLayout="scroll"
              size="small"
              first={tableSort?.first}
              sortField={tableSort?.sortField}
              sortOrder={tableSort?.sortOrder}
              filters={tableSort?.filters}
              emptyMessage="No se encontraron tareas"
              onSort={onSort}
            >
              {ColumnComponents}
              {showActions && (
                <Column
                  header={TableHeaderActions}
                  body={TableBodyActions}
                ></Column>
              )}
            </DataTable>
          }
        </div>
      )}

      {typeView === CoreConstants.TypeView.CANVA && (
        <div className="grid grid-cols-3 gap-2">
          {tableTasks.map((item, index) => (
            <TableCard
              {...item}
              key={index}
              id={item.id}
              selectedRow={selectedRow}
            />
          ))}
        </div>
      )}

      {showPagination && typeView === CoreConstants.TypeView.LIST && (
        <Paginator
          className="paginator-custom mt-4"
          rowsPerPageOptions={[5, 10, 15]}
          first={tableNum}
          rows={tableRows}
          totalRecords={tablePagination?.rowCount}
          onPageChange={onChangePage}
        ></Paginator>
      )}

      <OverlayPanel
        ref={headerActions}
        showCloseIcon
        style={{ width: "300px" }}
      >
        <HeaderColumns />
      </OverlayPanel>

      <Menu model={tableActions} popup ref={tableCurrentActions} />
    </div>
  );
};
