import { Fragment, useEffect } from "react";
import { useHistory } from "react-router";
import { Button } from "primereact/button";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import TableComponent from "features/maintenance/components/ProjectRoles/TableComponent";
import AdvancedFilterComponent from "features/maintenance/components/ProjectRoles/AdvacedFilterComponents";
import FilterComponent from "features/maintenance/components/ProjectRoles/FilterComponent";
import { useClaimProjectRoles } from "features/maintenance/hooks/useClaimProjectRoles";

const SearchProjectRolePage = () => {
  const history = useHistory();
  const { updateTitle } = useSetTitlePage();
  // const [isFirstLoading, setFirstLoading] = useState(true);
  // const dispatch = useDispatch();

  const { isSearch, isDownload, isCreate } = useClaimProjectRoles();

  useEffect(() => {
    updateTitle({
      title: "Mantenimiento",
      subtitle: "Rol de proyecto",
    });
  }, []);

  return (
    <Fragment>
      {
        (isSearch||isDownload)&&(
          <FilterComponent />
        )
    }
    {
            isCreate&&(
              <Button
                icon="pi pi-plus"
                type="button"
                label="Nuevo"
                className="sig-button sig-dark mt-4"
                onClick={() => {
                  history.push("/mantenimiento/rol-proyecto/nuevo");
                }}
              />
            )
        }
      <TableComponent />
      <AdvancedFilterComponent />
    </Fragment>
  );
};

export default SearchProjectRolePage;
