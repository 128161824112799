import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button';
import { Tree } from 'primereact/tree';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'primereact/skeleton';
import { SurveyManagementAction } from 'features/survey/store/actions/SurveyManagementAction';
import { useClaimSurveyManagment } from 'features/survey/hooks/useClaimSurveyManagment';


const SidebarMenu = ({setSelectedNode, setDisplayModal, setActionSelected}) => {
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [isFirstLoading, setFirstLoading] = useState(true); 
  const [expandedKeys, setExpandedKeys] = useState({});
  const dispatch = useDispatch()
  const { isLoading , data } = useSelector(state => state.surveyManagementReducer.listFolders)
  // const advancedFilter = useSelector((state) => state.surveyReducer.advancedFilter);

  const {isCreate} = useClaimSurveyManagment()

  useEffect(() => {
    if (isFirstLoading) {
      setFirstLoading(false);
      dispatch(SurveyManagementAction.getListFolders());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data) {
      setSelectedNode(data[0])
    }
  }, [data])

  const pathDocument = (path) => {
    if (!path) return
    const pathSplit = path.split('/')
    pathSplit.shift();
    const newPath = pathSplit.join('/')
    return newPath
  }

  const onSelect = (event) => {
    if (event.node.label === 'Todos') {
      // setSelectedNode('')
      return
    }

    // setSelectedNode(pathDocument(event.node.path))
    setSelectedNode(event.node)
    dispatch(SurveyManagementAction.getListFoldersInCurrentPath(event.node.id));
    // advancedFilter.filter.location = event.node;
    // dispatch(SurveyManagementAction.updateFilter(advancedFilter));
}

const onOpenModalAction = () => {
  setDisplayModal(true)
  setActionSelected('create')
}

const nodeTemplate = (node, options) => {
  let label = <p>{node.label}</p>;

  return (
      <span className={options.className}>
          {label}
      </span>
  )
}

  return (
    <div className='flex flex-col items-center justify-center gap-2'>
      <div className='w-full flex justify-center'>
        {
          isCreate&&(
            <Button 
              icon="pi pi-plus" type="button" label="Nuevo" className="sig-button sig-dark w-36 pointer" 
              onClick={onOpenModalAction}
            />
          )
        }
      </div>
      <div className='w-full menu-survey'>
        {
          isLoading ?(
            <Skeleton className='mt-3' height="6.2rem" />
          ) : (
            <Tree 
              nodeTemplate={nodeTemplate}
              className='px-0' value={data} selectionMode="single" selectionKeys={selectedNodeKey}
              onSelectionChange={e => setSelectedNodeKey(e.value)} onSelect={onSelect} 
              expandedKeys={expandedKeys} onToggle={e => setExpandedKeys(e.value)}
            />  
          )
        }
      </div>           
    </div>
  )
}

export default SidebarMenu