import React, { useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import useModalAction from 'features/document/hooks/DocumentManagement/useModalAction';
import { DocumentManagementAction } from 'features/document/store/actions/DocumentManagementAction';
import { useClaimDocumentManagment } from 'features/document/hooks/useClaimDocumentManagment';

const ModalAction = ({displayModal, setDisplayModal, documentSelected, setDocumentSelected, actionSelected, selectedNode, setActionSelected}) => {

  // const { isDeleted, isUpdated } = useSelector((state) => state.documentLibraryReducer.document);

  const dispatch = useDispatch()

  const {
    isCreate,
    isRename
 } = useClaimDocumentManagment()

  const { control, errors, valuesFields, existError, setValue, handleSubmit } = useModalAction()

  // console.log(selectedNode)
  const titleAction = ( ) => {
    if (actionSelected) {
      if (actionSelected === 'create') return 'Crear Carpeta'
      if (actionSelected === 'rename') return 'Cambiar de nombre a la carpeta'
    }
    return 'Documento'
  }

  useEffect(() => {
    if (actionSelected === 'rename') {
      setValue('newName', documentSelected.name)
    }
  }, [documentSelected, actionSelected, setValue])


  // useEffect(() => {
  //   if (actionSelected === 'copy' || actionSelected === 'move') {
  //     setValue('targetPath', documentSelected.name)
  //   }
  // }, [documentSelected, actionSelected, setValue])

  // useEffect(() => {
  //   if (isUpdated) {
  //      onHide()
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isUpdated])

  const onHide = () => {
    setDisplayModal(false)
    setActionSelected(null)
    setDocumentSelected(null)
    setValue('folderName', '')
    setValue('newName', '')
  }

  const redirectToSearch = () => {
    onHide()
  }

  const onSubmit = (newFile) => {
    if (actionSelected === 'create') {
      // console.log(`${selectedNode}/${newFile.folderName}`)
        dispatch(DocumentManagementAction.createFolder(`${selectedNode}/${newFile.folderName}`));
        redirectToSearch()
        return
    }
    if (actionSelected === 'rename') {
        const modelName = {
          currentPath: documentSelected.name,
          newName: newFile.newName
        }
        dispatch(DocumentManagementAction.renameFolder(modelName));
    }
  };

  return (
    <Dialog header={titleAction} visible={displayModal} className='w-3/4 sm:w-1/3' onHide={onHide}>
      <form 
        className="form-custom p-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-1 gap-2">
          {
            (actionSelected === 'create' ) ?
            (
              <span className="p-float-label w-full mt-1 md:mt-5">
                <Controller
                    control={control}
                    render={({
                        field: { onChange, onBlur, value},
                    }) => (
                      <InputText
                          type="text"
                          id='folderName'
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          className={errors?.name ? 'p-invalid w-full' : 'w-full'}
                      />
                    )}
                    name="folderName"
                    rules={{
                      required: 'El nombre de la carpeta es requerida',
                  }}
                />
                <label htmlFor="folderName">Ingrese el nombre de la carpeta *</label>
              </span>
            ) : null
          }
          {
            (actionSelected === 'rename') ?
            (
              <span className="p-float-label w-full mt-1 md:mt-5">
                <Controller
                    control={control}
                    render={({
                        field: { onChange, onBlur, value},
                    }) => (
                      <InputText
                          type="text"
                          id='newName'
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          className={errors?.name ? 'p-invalid w-full' : 'w-full'}
                      />
                    )}
                    name="newName"
                    rules={{
                      required: 'El nuevo nombre es requerido',
                  }}
                />
                <label htmlFor="newName">Nuevo Nombre *</label>
              </span>
            ) : null
          }
        </div>
        {
        (existError) && <div className='message error'>
            <ul>
                {errors.targetPath && (actionSelected === 'copy' || actionSelected === 'move' )? (
                    <>
                        <li className="p-error">{errors.targetPath.message}</li>
                    </>
                ) : null}
                {errors.newName && actionSelected === 'rename' ? (
                    <>
                        <li className="p-error">{errors.newName.message}</li>
                    </>
                ) : null}
                {errors.folderName && actionSelected === 'create' ? (
                    <>
                        <li className="p-error">{errors.folderName.message}</li>
                    </>
                ) : null}
            </ul>

        </div>
        }
        <div className="flex justify-end gap-4 mt-3">
            <Button
                icon="pi pi-times"
                type="button"
                label="Cancelar"
                onClick={redirectToSearch}
                className="sig-button sig-secondary mt-4"
            />
            {
              (isCreate || isRename)&&
                <Button
                    icon="pi pi-save"
                    type="submit"
                    disabled={existError ? true : false}
                    label="Guardar"
                    loading={document?.isSaving || document?.isLoading}
                    className="sig-button sig-primary mt-4"
                />
            }
        </div>
      </form>
    </Dialog>
  )
}

export default ModalAction