const TableCard = ({
  id,
  key,
  code,
  name,
  stageName,
  businessValue,
  closeDate,
  selectedRow,
}) => {
  return (
    <div
      key={`card-${key}`}
      className="block p-4 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
    >
      <div className="flex justify-between">
        <h5 className="mb-1 text-md font-bold text-gray-900 dark:text-white">
          {name}
        </h5>
        <i
          className="pi pi-ellipsis-v cursor-pointer"
          onClick={(event) => selectedRow(event, { id })}
        ></i>
      </div>
      <hr />
      <div className="grid grid-cols-2 mt-3 text-gray-700 dark:text-gray-400">
        <div>
          <p className="text-xs">Código:</p>
          <p className="text-xs">{code}</p>
        </div>
        <div>
          <p className="text-xs">Etapa:</p>
          <p className="text-xs">{stageName}</p>
        </div>
      </div>
      <div className="grid text-gray-700 dark:text-gray-400 mt-3">
        <div>
          <p className="text-xs">Valor:</p>
          <p className="text-xs">{businessValue}</p>
        </div>
      </div>
      <div className="grid grid-cols-2 text-gray-700 dark:text-gray-400 mt-3">
        <div>
          <p className="text-xs">Cierre:</p>
          <p className="text-xs">
            {closeDate}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TableCard;
