import { CoreConstants } from "core/commons/CoreConstants";
import moment from "moment";

const Users = ({labor, nombre, fecha, rol, className}) => {
	return (
		<div className={`flex flex-col pl-2 ${className}`}>
			<div>
				<span className="font-bold">{labor?.toUpperCase()}: </span>{fecha}
			</div>
			<div>
				{nombre} - {rol}
			</div>
		</div>
	)
}

export const Approvers = ({approvers = []}) => {

	return (
		approvers?.length > 0 && 
		(
			approvers.map((item, key)=>(
				<div className='flex mb-6' key={key}>
					<div className='flex'>
						<i className="pi pi-user m-auto"></i>
					</div>
					<div>
					<Users labor={item.level} rol={item.role} nombre={item.name} fecha={moment(item.date || "").format(CoreConstants.Format.DDMMYYYY)} className='text-xs'/>
					</div>
				</div>
				)
			)
		)
	);
};
