import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { Dropdown } from "primereact/dropdown"
import { BusinessSheetAction } from "features/business/store/actions/BusinessSheetAction"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"


export const ModalUploadTemplate = ({displayModal, setDisplayModal}) => {
  const dispatch = useDispatch()
  const loading = useSelector(state => state.businessSheetReducer.businessSheetTemplates.loading)
  const data = useSelector(state => state.businessSheetReducer.businessSheetTemplates.data)
  const currentTemplateId = useSelector(state => state.businessSheetReducer.businessSheetTemplates.currentTemplateId)
  const history = useHistory()
  
  useEffect(() => {
    if(displayModal){
      dispatch(BusinessSheetAction.getBusinessSheetsTemplate())
    }
  },[displayModal])

  return(
    <Dialog header="Cargar desde plantilla" visible={displayModal} breakpoints={{'960px': '78vw'}} style={{width: '55vw'}} onHide={() => {setDisplayModal(false); dispatch(BusinessSheetAction.setCurrentTemplateId(null))}}>
    <span className="p-float-label mt-4">
      <Dropdown
        options={data}
        optionLabel="name"
        className="w-full"
        name="templateId"
        value={currentTemplateId}
        optionValue="id"
        onChange={(e) => {
          dispatch(BusinessSheetAction.setCurrentTemplateId(e.target.value))
        }}
      />
      <label>Seleccione plantilla</label>
    </span>
    <div className="flex justify-end gap-4 mt-4 w-full">
      <Button
        type="button"
        label="Aceptar"
        className="sig-button sig-primary"
        onClick={() => {
          setDisplayModal(false)
          history.push('/negocios/fichas/configuracion/nuevo');
        }}
      />
    </div>
  </Dialog>
  )
}