import { apiService } from "core/services/ApiService";

const searchSchedules = (criteria) => {
  return apiService.Post("customer/api/CourseSchedule/Search", criteria);
};

const saveSchedules = (data) => {
  return apiService.Post("customer/api/CourseSchedule/Save", data);
};

const getScheduleById = (courseId) => {
  return apiService.Get("customer/api/CourseSchedule/GetCourseScheduleById?id=" + courseId);
};

const generateReport = (payload) => {
  return apiService.Post("customer/api/CourseSchedule/GenerateReport", payload, {
    responseType: 'blob',
  })
};

const getDataMaster = () => {
  return apiService.Post("customer/api/CourseSchedule/GetDataMaster");

};



export const ScheduleServices = {
  searchSchedules,
  saveSchedules,
  getScheduleById,
  getDataMaster,
  generateReport,

}