import { ConfigurationConstants } from 'features/configuration/commons/ConfigurationConstants';

const initialState = {
  currentTypeMoney: {
    isLoading: true,
    data: [],
    isLoadingSave: false,
  },
  currentLogos: {
    isLoading: true,
    isLoadingSave: false,
    data: [],
  },
  currentColors: {
    isLoading: true,
    data: [],
    isLoadingSave: false,
  },
  currentUnitsOfMeasure: {
    isLoadingSave: false,
    isLoading: true,
    data: [],
  },
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ConfigurationConstants.Accion.ApplicationSettings.GET_TYPE_MONEY:
      return {
        ...state,
        currentTypeMoney: {
          ...state.currentTypeMoney,
          ...action.payload,
        },
      };

    case ConfigurationConstants.Accion.ApplicationSettings.GET_LOGOS:
      return {
        ...state,
        currentLogos: {
          ...state.currentLogos,
          ...action.payload,
        },
      };

    case ConfigurationConstants.Accion.ApplicationSettings.GET_COLORS:
      return {
        ...state,
        currentColors: {
          ...state.currentColors,
          ...action.payload,
        },
      };

    case ConfigurationConstants.Accion.ApplicationSettings.GET_UNITS_MEASURE:
      return {
        ...state,
        currentUnitsOfMeasure: {
          ...state.currentUnitsOfMeasure,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
