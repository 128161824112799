import { RrhhConstants } from 'features/rrhh/commons/RrhhConstants';
import { act } from 'react-dom/test-utils';
const initialState = {
  employees: {
    currentData: [],
    data: [],
    loading: false,
    pagination: {},
    currentCol: 'firstName',
    order: 'asc',
  },
  employeeFilter: {
    showModal: false,
    values: [],
  },
  employeeEdit: {
    loadingEdit: false,
    loadingSave:false,
    showModalExperience:false,
    showModalStudy:false,
    isSave:false,
    cargoSelect:{},
    dataEmployee: {},
  },
  dataManager: {
    loadingDataManager: true,
    loadingDataTemplate: true,
    dataManager: {
      departments: [],
      employees: [],
      employeesTypes: [],
      nationalities:[],
      status:[],
      statusCivil:[],
      intructions:[],
      positions:[],
      gender:[],
      cities:[],
      documentsTypes:[],
      districts:[],
      branches:[],
      workDays:[],
      areas:[],
      dynamicFields: [],
    },
  },
  userDeleteModal: {
    isOpen: false,
    userId: '',
    isLoading: false,
  },
  downloadReport: {
    loading: false,
  },
  bulkLoad: {
    data: null,
    isLoading: false,
  },
  bulkLoadErrors: [],
  employeeBulk: {
    isSaved: false,
  },
  currentVersion: {
    data: null,
    isSaving: false,
    isSaved: false,
    isLoading: false
},

};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case RrhhConstants.Accion.Employees.SEARCH_EMPLOYEES:
      return {
        ...state,
        employees: {
          ...state.employees,
          ...action.payload,
        },
      };
    case RrhhConstants.Accion.Employees.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        employeeFilter: {
          ...state.employeeFilter,
          ...action.payload,
        },

      };
    case RrhhConstants.Accion.Employees.REMOVE_FILTER_VALUES:
      return {
        ...state,
        employeeFilter: {
          values: [...action.payload],
        },
      };
    case RrhhConstants.Accion.Employees.MODALS:
      return {
        ...state,
        employeeEdit: {
          ...state.employeeFilter,
          ...action.payload,
        },
      }
    case RrhhConstants.Accion.Employees.SET_FILTER_VALUES:
      return {
        ...state,
        employeeFilter: {
          ...state.employeeFilter,
          ...action.payload,
        },
      };
      case RrhhConstants.Accion.Employees.DATA_MASTER:
        return {
          ...state,
          dataManager: {
            ...state.dataManager,
            ...action.payload,
          },
        };
      case RrhhConstants.Accion.Employees.EDIT_EMPLOYEE:
        return {
          ...state,
          employeeEdit: {
            ...state.employeeEdit,
            ...action.payload,
          },
        }
      case RrhhConstants.Accion.Employees.UPLOAD_BATCH_INFO:
        return {
          ...state,
          bulkLoad: {
            data: action.payload.data,
            isLoading: action.payload.isLoading,
          },
          bulkLoadErrors: action.payload.errors,
        };
      case RrhhConstants.Accion.Employees.RESET_AFTER_SAVED:
        return {
          ...state,
          bulkLoad: {
              data: null,
              isLoading: false,
          },
          bulkLoadErrors: [],
          employeeBulk: {
              isSaved: false,
          }
        };
      case RrhhConstants.Accion.Employees.SAVE_EMPLOYEES_BULK:
        return {
          ...state,
          employeeBulk: {
              isSaved: action.payload.isSaved,
          }
        };
      default:
      return state;
  }
};
