import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { ProjectTableData } from 'features/customers/data/project/ProjectTableData';
import { Dropdown } from "primereact/dropdown";
import { Controller } from "react-hook-form";
import Message from "shared/components/messages/Message";

export const Colaborators = ({colaboratorList, data, updateColaborator, setVisibleColaborator, setidDeleteColaborator, control, errors}) => {
  let errorsArray = [];
  const FieldColaborator = (name) => (row) => {
    if (name === "memberId")
      return (
        <Controller
          control={control}
          name={`colaborator_${name}_${row.id}`}
          rules={{required: `Nombre del Participante es obligatorio`}}
          defaultValue={row[name]}
          render={({field: {onChange, ref}, fieldState: {error}}) => {
            return(
              <Dropdown
                inputRef={ref}
                options={data.responsibles} 
                optionLabel="fullName" 
                filter
                filterBy='fullName'
                showClear
                className={`w-full ${error ? ' p-invalid' : ''}`}
                placeholder="Seleccione participante"
                optionValue='id'
                value={row[name]}
                onChange={(e) => {
                  onChange(e.value)
                  updateColaborator({
                    id: row.id,
                    name: name,
                    value: e.value,
                  })}
                }
              />
            )}}
            />
          );
    else
      if (name === "roleId")
        return (
          <Controller
            control={control}
            name={`colaborator_${name}_${row.id}`}
            rules={{required: `Rol del Participante es obligatorio`}}
            defaultValue={row[name]}
            render={({field: {onChange, ref}, fieldState: {error}}) => {
              return(
                <Dropdown
                  inputRef={ref}
                  options={data.roles} 
                  optionLabel="description" 
                  filter
                  filterBy='description'
                  showClear
                  className={`w-full ${error ? ' p-invalid' : ''}`}
                  placeholder="Seleccione rol"
                  optionValue='id'
                  value={row[name]}
                  onChange={(e) => {
                    onChange(e.value)
                    updateColaborator({
                      id: row.id,
                      name: name,
                      value: e.value,
                    })}
                  }
                />
              )}}
              />
            );
  };

  const RowDeleteColaborator = ({ id }) => {
    return (
      <i
        onClick={() => {
          setVisibleColaborator(true);
          setidDeleteColaborator(id);
        }}
        className="pi pi-trash cursor-pointer"
      ></i>
    );
  };

  const ColumnColaboratorComponents = ProjectTableData.colaboratorColumns.map((col) => {
    // console.log(col)
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable
        style={{width: col.field.includes('Id')? '48%':'4%'}}
        body={FieldColaborator(col.field)}
      />
    );
  });

  return (
    <>
      <div className="table-main table-contact mb-3">
        <DataTable
          value={colaboratorList}
          emptyMessage="No se encontraron participantes"
          stripedRows
          rows={5}
          editMode="row"
          dataKey="id"
          rowHover
          size="small"
          responsiveLayout="scroll"
        >
          {ColumnColaboratorComponents}
          <Column header={<div></div>} body={RowDeleteColaborator}></Column>
        </DataTable>
      </div>
      {Object.entries(errors).forEach((key, value) => {
        if(key[0].startsWith("colaborator")) errorsArray.push(key[1].message);
      })}
      {errorsArray.length > 0 && (
        <Message messages={[...new Set(errorsArray)]} status="error" />
      )}
    </>
  )
}