import { DocumentManagementAction } from 'features/document/store/actions/DocumentManagementAction';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid';

const useModalPermissions = () => {

  const { dropdowns  } = useSelector(state => state.documentManagementReducer.dataMaster)
  const { data , isLoading } = useSelector(state => state.documentManagementReducer.listPermissions)
  const { isSaved } = useSelector(state => state.documentManagementReducer.permission)

  const [permissionsList, setPermissionsList] = useState([])
  const [permissionError, setPermissionError] = useState(null)
  
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(DocumentManagementAction.getDataMaster());
  }, [])

  
  

  const createPermission = () => {
    setPermissionsList((prev) => [
      ...prev,
      {
        typeId: null,
        sourceId: null,
        allowedActionId: null,
        id: uuidv4(),
      },
    ]);
  };

  const deletePermission = (id) => {
    const currentPermissionsList = permissionsList.filter((permission) => permission.id !== id);
    setPermissionsList(currentPermissionsList);
  };

  const updatePermission = ({ id, name, value }) => {
    // console.log(name, value)
    const currentPermissionsList = permissionsList.map((permission) => {
      if (permission.id === id) permission[name] = value;
      return permission;
    });

    setPermissionsList(currentPermissionsList);
  };

  const titleFolder = (pathFolder) => {
    if (!pathFolder) return
    const pathSplit = pathFolder.split('/')
    // pathSplit.pop()
    const folderName = pathSplit[pathSplit.length-2]
    return folderName
}

  return {
    permissionsList,
    dropdowns,
    isLoading,
    permissionError,
    data,
    isSaved,
    createPermission,
    deletePermission,
    updatePermission,
    setPermissionsList,
    dispatch,
    titleFolder,
    setPermissionError
  }
}

export default useModalPermissions