import { useState } from "react";

import { Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea'
import { Skeleton } from 'primereact/skeleton';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';

import Message from "shared/components/messages/Message";
import ModalFormTaskComponent from 'features/customers/components/tasks/ModalFormComponent';
import Attachments from 'shared/components/attachments/Attachments';
import { Deliverables } from 'features/customers/components/project/Deliverables';
import { Colaborators } from 'features/customers/components/project/Colaborators';

import { useProject } from 'features/customers/hooks/project/useProject';

import { CoreConstants } from "core/commons/CoreConstants";

import { BusinessServices } from 'features/customers/services/BusinessService';
import { DynamicFieldList } from 'shared/components/dynamicFields/DynamicFieldList';

export const PageProject = (props) => {
  const errorsArray = [];
  const [visibleDialogColaborator, setVisibleColaborator] = useState(false);
  const [idDeleteColaborator, setidDeleteColaborator] = useState(0);
  const [visibleDialogDeliverable, setVisibleDeliverable] = useState(false);
  const [idDeleteDeliverable, setidDeleteDeliverable] = useState(0);
  const [visibleDialogTask, setVisibleTask] = useState(false);
  const [idDeleteTask, setidDeleteTask] = useState(0); 
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [idDeliverableShow, setidDeliverableShow] = useState(0);
  const [idTaskShow, setIdTaskShow] = useState(0);
  const [isLoadingSearchMount, setIsLoadingSearchMount] = useState(false)
  
  const { 
    showModalCancel,
    cancelAccept,
    cancelDenied,
    setShowModalCancel, 
    errors,
    handleSubmit, 
    isLoadingProject, 
    isLoadingDataMaster, 
    onSubmit, 
    control, 
    data,
    colaboratorList,
    createColaborator,
    updateColaborator,
    colaboratorDelete,
    deliverableList,
    createDeliverable,
    updateDeliverable,
    deliverableDelete,
    taskDelete,
    isNewProject,
    updateTasksFromDeliverable,
    setValue,
    loading,
    valuesFields,
    dynamicFieldsDatosGenerales,
    dynamicFieldsEntregables,
    dynamicFieldsParticipantes
  } = useProject(props.title);

  const deleteColaborator = () => {
    colaboratorDelete(idDeleteColaborator);
  };

  const deleteDeliverable = () => {
    deliverableDelete(idDeleteDeliverable);
  };  

  const deleteTask = () => {
    taskDelete(idDeleteTask, idDeliverableShow, 70);
  };  

  return (
    <>
      <div className="bg-white">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Panel header="DATOS GENERALES" toggleable>            
            {/* NOMBRE */}
            <div className="grid sm:grid-cols-1 md:grid-cols-1">
              {!isLoadingProject || !isLoadingDataMaster ? (
                <Controller 
                  name="Name"
                  control={control} 
                  rules={
                    { 
                      required: "El Nombre es obligatorio ",
                      maxLength:{
                        message: "El nombre del proyecto no debe exceder los 100 caracteres",
                        value:100
                      }
                    }
                  }
                  render= {({field: {onChange, value}}) => (
                    <span className="p-float-label w-full mb-4 mt-4">
                    <InputText
                        type="text"
                        id="Name"
                        maxLength={100}
                        className={`w-full ${errors.Name?.type === 'required' ? ' p-invalid' : ''}`}
                        onChange={( e ) => {
                        
                          
                          onChange(e)
                        }}
                        value={value}/>
                    <label htmlFor="name">Nombre *</label>
                  </span>
                )}/>
                ) : (
                  <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
              )}
            </div>
            {/* SOLICITUD DE NEGOCIO */}
            <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-x-4">
              {!isLoadingProject || !isLoadingDataMaster ? (
                <Controller 
                  name="BusinessId"
                  control={control} 
                  rules={{ required: "La Solicitud de Negocio es obligatoria"}}
                  render= {({field: {onChange, value}}) => (
                    <span className="p-float-label w-full mb-4 mt-4">
                      <Dropdown
                        disabled={isLoadingSearchMount}
                        options={data?.businessList}
                        optionLabel="name"
                        filter
                        filterBy="name"
                        showClear
                        className={`w-full ${errors.BusinessId?.type === 'required' ? ' p-invalid' : ''}`}
                        onChange={async ( e ) => {
                          onChange(e)
                          if(e.target.value?.id){
                            setIsLoadingSearchMount(true)
                            try {
                              const { data } = await BusinessServices.getById(e.target.value.id)
                              setValue('AssignedAmount', data.data?.business?.businessValue || "")
                            } catch (error) {
                              console.log(error.message)
                            } finally {
                              setIsLoadingSearchMount(false)
                            }
                          } else{
                            setValue('AssignedAmount', "")
                          }
                          // console.log(data.Data.BusinessValue)
                        }}
                        value={value}
                      />
                      <label htmlFor="BusinessId">Solicitud de Negocio *</label>
                    </span>
                )}/>
              ) : (
                <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
              )}
              {!isLoadingProject && !isLoadingDataMaster && !isLoadingSearchMount? (
                <Controller 
                  name="AssignedAmount"
                  control={control} 
                  rules={
                    { 
                      required: "El Monto Adjudicado es obligatorio ",
                      maxLength:{
                        message: "El monto adjudicado no debe exceder los 100 caracteres",
                        value:100
                      }
                    }
                  }
                  render= {({field: {onChange, value}}) => (
                    <span className="p-float-label w-full mb-4 mt-4">
                    <InputText
                        type="number"
                        id="AssignedAmount"
                        maxLength={100}
                        className={`w-full ${errors.AssignedAmount?.type === 'required' ? ' p-invalid' : ''}`}
                        style={{textAlign:'right'}} 
                        onChange={( e ) => {
                        
                          
                          onChange(e)
                        }}
                        value={value}/>
                    <label htmlFor="name">Monto Adjudicado *</label>
                  </span>
                )}/>
                ) : (
                  <Skeleton width="100%" height="2.5rem" className="mb-3 w-full mt-4" />
              )}
            </div>
            {/* DESCRIPCIÓN */}
            <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-x-4">
              {!isLoadingProject || !isLoadingDataMaster ? (
                  <Controller 
                    name="Description"
                    control={control} 
                    rules={
                      { required: "La descripción es obligatoria " }
                    }
                    render= {({field: {onChange, value}}) => (
                    <span className="p-float-label w-full mb-4 mt-4">
                      <InputTextarea 
                        id="Description" 
                        rows={5} 
                        cols={30} 
                        value={value} 
                        className={`w-full ${errors.Description?.type === 'required' ? ' p-invalid' : ''}`}
                        autoResize={'false'}
                        onChange={( e ) => {
                        
                          
                          onChange(e)
                        }} />
                      <label htmlFor="Description">Descripción *</label>
                    </span>
                  )}/>
                  ) : (
                    <Skeleton width="50%" height="2.5rem" className="mb-3 w-50" />
                    )}
              <div className="flex flex-col w-full mb-4">
              {!isLoadingProject || !isLoadingDataMaster ? (
                <Controller 
                name="EstimatedOpenDate"
                control={control} 
                rules={
                  { required: "La Fecha estimada de apertura es obligatoria " }
                }
                render= {({field: {onChange, value}}) => (
                  <span className="p-float-label w-full mb-4 mt-4">
                    <Calendar
                      monthNavigator 
                      yearNavigator 
                      yearRange="1950:2050"
                      dateFormat={CoreConstants.Format.ddmmyy}
                      showIcon={false} 
                      className={`w-full ${errors.EstimatedOpenDate?.type === 'required' ? ' p-invalid' : ''}`}
                      onChange={( e ) => {
                      
                        
                        onChange(e)
                      }}
                      value={value}
                      id="EstimatedOpenDate"></Calendar>
                    <label htmlFor="EstimatedOpenDate">Apertura Estimada *</label>
                  </span>
                )}/>
              ) : (
                <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
              )}
              {!isLoadingProject || !isLoadingDataMaster ? (
                <Controller 
                  name="RealOpenDate"
                  control={control} 
                  render= {({field: {onChange, value}}) => (
                  <span className="p-float-label w-full mb-4 mt-4">
                    <Calendar
                      monthNavigator 
                      yearNavigator 
                      yearRange="1950:2050"
                      dateFormat={CoreConstants.Format.ddmmyy}
                      showIcon={false} 
                      onChange={( e ) => {
                      
                        
                        onChange(e)
                      }}
                      value={value}
                      className={`w-full ${errors.openDate?.type === 'required' ? ' p-invalid' : ''}`}
                      id="RealOpenDate"></Calendar>
                    <label htmlFor="RealOpenDate">Apertura Real</label>
                  </span>
                )}/>
              ) : (
                <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
              )}
              </div>            
            </div>
            {/* RESPONSABLE */}
            <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-x-4">
              {!isLoadingProject || !isLoadingDataMaster ? (
                <Controller 
                  name="ResponsibleId"
                  control={control} 
                  rules={{ required: "El Responsable es obligatorio "}}
                  render= {({field: {onChange, value}}) => (
                    <span className="p-float-label w-full mb-4 mt-4">
                      <Dropdown
                        options={data?.responsibles}
                        optionLabel="fullName"
                        filter
                        filterBy="fullName"
                        showClear
                        className={`w-full ${errors.ResponsibleId?.type === 'required' ? ' p-invalid' : ''}`}
                        onChange={( e ) => {
                        
                          
                          onChange(e)
                        }}
                        value={value}
                      />
                      <label htmlFor="ResponsibleId">Responsable *</label>
                    </span>
                  )}/>
                ) : (
                <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
              )}
              {!isLoadingProject || !isLoadingDataMaster ? (
                <Controller 
                  name="EstimatedCloseDate"
                  control={control} 
                  render= {({field: {onChange, value}}) => (
                  <span className="p-float-label w-full mb-4 mt-4">
                    <Calendar
                      monthNavigator 
                      yearNavigator 
                      yearRange="1950:2050"
                      dateFormat={CoreConstants.Format.ddmmyy}
                      showIcon={false} 
                      onChange={( e ) => {
                      
                        
                        onChange(e)
                      }}
                      value={value}
                      className={`w-full ${errors.openDate?.type === 'required' ? ' p-invalid' : ''}`}
                      id="EstimatedCloseDate"></Calendar>
                    <label htmlFor="EstimatedCloseDate">Cierre Estimado</label>
                  </span>
                )}/>
              ) : (
                <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
              )}
            </div>
            {/* ESTADO */}
            <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-x-4">
              {!isLoadingProject || !isLoadingDataMaster ? (
                <Controller 
                  name="StatusId"
                  control={control} 
                  rules={{ required: "El Estado es obligatorio "}}
                  render= {({field: {onChange, value}}) => (
                    <span className="p-float-label w-full mb-4 mt-4">
                      <Dropdown
                        options={data?.status}
                        optionLabel="description"
                        filter
                        filterBy="description"
                        showClear
                        className={`w-full ${errors.StatusId?.type === 'required' ? ' p-invalid' : ''}`}
                        onChange={( e ) => {
                        
                          
                          onChange(e)
                        }}
                        value={value}
                      />
                      <label htmlFor="StatusId">Estado *</label>
                    </span>
                  )}/>
                ) : (
                <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
              )}
              {!isLoadingProject || !isLoadingDataMaster ? (
                <Controller 
                  name="RealCloseDate"
                  control={control} 
                  render= {({field: {onChange, value}}) => (
                  <span className="p-float-label w-full mb-4 mt-4">
                    <Calendar
                      monthNavigator 
                      yearNavigator 
                      yearRange="1950:2050"
                      dateFormat={CoreConstants.Format.ddmmyy}
                      showIcon={false} 
                      onChange={( e ) => {
                      
                        
                        onChange(e)
                      }}
                      value={value}
                      className={`w-full ${errors.openDate?.type === 'required' ? ' p-invalid' : ''}`}
                      id="RealCloseDate"></Calendar>
                    <label htmlFor="RealCloseDate">Cierre Real</label>
                  </span>
                )}/>
              ) : (
                <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
              )}
            </div>        
            {/* SITUACION */}
            <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-x-4">
              {!isLoadingProject || !isLoadingDataMaster ? (
                <Controller 
                  name="SituationId"
                  control={control} 
                  rules={{ required: "La Situación es obligatoria"}}
                  render= {({field: {onChange, value}}) => (
                    <span className="p-float-label w-full mb-4 mt-4">
                      <Dropdown
                        options={data?.situation}
                        optionLabel="description"
                        filter
                        filterBy="description"
                        showClear
                        className={`w-full ${errors.SituationId?.type === 'required' ? ' p-invalid' : ''}`}
                        onChange={( e ) => {
                        
                          
                          onChange(e)
                        }}
                        value={value}
                      />
                      <label htmlFor="SituationId">Situación *</label>
                    </span>
                  )}/>
                ) : (
                <Skeleton width="100%" height="2.5rem" className="mb-3 w-full" />
              )}
            </div>    
            <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-6">
              {
                (!isLoadingProject && !isLoadingDataMaster) &&
                <DynamicFieldList control={control} fields={dynamicFieldsDatosGenerales} />
              }
            </div>
          </Panel>
          <Panel header="PARTICIPANTES" toggleable>
            <Button
              icon="pi pi-plus"
              type="button"
              label="Añadir nuevo participante"
              onClick={createColaborator}
              className="btn btn-primary mt-2 mb-4"
            />

            <Colaborators
              colaboratorList={colaboratorList}
              control={control}
              data={data}
              errors={errors}
              setVisibleColaborator={setVisibleColaborator}
              setidDeleteColaborator={setidDeleteColaborator}
              updateColaborator={updateColaborator}
            />
            <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-6">
              {
                (!isLoadingProject && !isLoadingDataMaster) &&
                <DynamicFieldList control={control} fields={dynamicFieldsParticipantes} />
              }
            </div>
          </Panel>
          <Panel header="ENTREGABLES" toggleable>
            <Deliverables
              createDeliverable={createDeliverable}
              deliverableList={deliverableList}
              isNewProject={isNewProject}
              loading={loading}
              setIdTaskShow={setIdTaskShow}
              setShowTaskModal={setShowTaskModal}
              setVisibleDeliverable={setVisibleDeliverable}
              setVisibleTask={setVisibleTask}
              setidDeleteDeliverable={setidDeleteDeliverable}
              setidDeleteTask={setidDeleteTask}
              setidDeliverableShow={setidDeliverableShow}
              updateDeliverable={updateDeliverable}
              updateTasksFromDeliverable={updateTasksFromDeliverable}
              control={control}
              errors={errors}
            />
            <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-6">
              {
                (!isLoadingProject && !isLoadingDataMaster) &&
                <DynamicFieldList control={control} fields={dynamicFieldsEntregables} />
              }
            </div>
          </Panel>
          {!isNewProject && (
            <Attachments
              // module="customer"
              // addEndpoint={"api/Project/AddAttachment"}
              // deleteEndpoint={"api/Project/DeleteAttachment"}
              // listEndpoint={"api/Project/GetAttachmentsByProjectId"}

              setValue={setValue}
              fieldFiles="savedFiles"
              fieldNewFiles="addedFiles"
              filesValues={valuesFields?.["savedFiles"]}
              newFilesValues={valuesFields?.["addedFiles"]}
              isLoading={isLoadingProject}
              validExtensions={["docx", "pdf", "xlsx"]}
            />)
          }
          <Panel header="HISTORIAL" toggleable className={{'hidden': isNewProject}}>
          </Panel>
          {Object.entries(errors).forEach((key, value) => {
            if(!key[0].startsWith("deliverable") && !key[0].startsWith("colaborator")) errorsArray.push(key[1].message);
          })}
          {errorsArray.length > 0 && (
              <Message messages={errorsArray} status="error" />
          )}
          {(!isLoadingProject || !isLoadingDataMaster) && (
            <div className="flex justify-end gap-4 mt-3">
              <Button
                  icon="pi pi-times"
                  type="button"
                  label="Cancelar"
                  onClick={() => setShowModalCancel(true)}
                  className="sig-button  sig-secondary mt-4"
              />
              <Button
                  icon="pi pi-save"
                  type="submit"
                  label="Guardar"
                  disabled={false}
                  loading={isLoadingProject}
                  className="sig-button sig-primary mt-4"
              />
            </div>
          )}
        </form>
        <ConfirmDialog
          visible={showModalCancel}
          onHide={() => setShowModalCancel(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={cancelAccept}
          reject={cancelDenied}
        />
        <ConfirmDialog
          visible={visibleDialogColaborator}
          onHide={() => setVisibleColaborator(false)}
          message="¿Está seguro que desea eliminar el registro?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={() => deleteColaborator()}
          reject={() => setVisibleColaborator(false)}
        />
      </div>
      <ConfirmDialog
          visible={visibleDialogDeliverable}
          onHide={() => setVisibleDeliverable(false)}
          message="¿Está seguro que desea eliminar el registro?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={() => deleteDeliverable()}
          reject={() => setVisibleDeliverable(false)}
        />
        <ConfirmDialog
        visible={visibleDialogTask}
        onHide={() => setVisibleTask(false)}
        message="¿Está seguro que desea eliminar la tarea?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={() => deleteTask()}
        reject={() => setVisibleTask(false)}        
      />
      <ModalFormTaskComponent isOpen={showTaskModal} 
      onClose={() => {        
        setShowTaskModal(false);
        if(!isNewProject) updateTasksFromDeliverable(idDeliverableShow);
      }} 
      sourceDocumentCode={CoreConstants.Task.Source.PROJECT} sourceDocumentId={idDeliverableShow} taskId={idTaskShow}/>
    </>
  );
}
