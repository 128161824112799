import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { useEffect, useState } from "react"
import { Skeleton } from "primereact/skeleton";
import { DropDownCustom } from "./DropDownCustom"
import { InputTextareaCustom } from "./InputTextareaCustom"
import { InputTextCustom } from "./InputTextCustom"
import { SeleccionSimpleCustom } from "./SeleccionSimpleCustom"
import { TableCustom } from "./TableCustom"
import { TextoDinamicoCustom } from "./TextoDinamicoCustom"

const hash = {
	"TEXTO": (props) => <InputTextCustom {...props} />,
	"TEXTO_LARGO": (props) => <InputTextareaCustom {...props} /> ,
	"TEXTO_DINAMICO": (props) => <TextoDinamicoCustom {...props}/> ,
	"LISTA_DESPLEGABLE": (props) => <DropDownCustom {...props}/> ,
	"SELECCION_SIMPLE": (props) => <SeleccionSimpleCustom {...props}/> ,
	"TABLA": (props) => <TableCustom {...props} /> ,
  "ETIQUETA": (props) => <InputText />,
}



export const Ficha = ({control, errors, list, setList, setProcessFormSectionId, setProcessFormId, show, setValue, data, isLoadingMaster, isLoading, row,rowLec=true, forDownload=false, isLoadingSheet, isLoadingControls, controls, type}) => {

	const [res, setRes] = useState({})
  
	return (
		
			 
		<div id="ficha-process">
			{
				(!isLoadingSheet && !isLoadingControls) && controls?.map((item, index)=> {return (<div key={item.id}>{hash[type.find( c => c.id === item.controlId)?.code]({...item, res, setRes, control, errors, show: show === 'readonly' ? 'ShowWf' : show ,setValue,row,rowLec, forDownload, type})}</div>)})
			}
		</div>
		
	)
}