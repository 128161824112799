import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import FilterComponent from "features/coursesManagment/components/schedule/FilterComponent";
import TableComponent from "features/coursesManagment/components/schedule/TableComponent";
import AdvancedFilterComponent from "features/coursesManagment/components/schedule/AdvanceFilterComponent";
import { useClaimSchedule } from 'features/coursesManagment/hooks/useClaimSchedule';



const SearchSchedulePage = () => {
    const history = useHistory();
    const { updateTitle } = useSetTitlePage();
    const [isFirstLoading, setFirstLoading] = useState(true);
    const dispatch = useDispatch();

    const { isSearch, isDownload } = useClaimSchedule();

     useEffect(() => {
        updateTitle({
            title: 'Cursos',
            subtitle: 'Programación',
        });
    }, []);

    return (
        <Fragment>
            {
                (isSearch||isDownload)&&(
                    <FilterComponent />
                )
            }
            <TableComponent />
            <AdvancedFilterComponent />
        </Fragment>
    );
};

export default SearchSchedulePage;
