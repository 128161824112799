import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { FilterComponent } from 'features/customers/components/project/FilterComponent';
import { TableComponent } from 'features/customers/components/project/TableComponent';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { AdvancedFilterComponent } from 'features/customers/components/project/AdvancedFilterComponent';
import scrollTop from 'shared/utils/scrollTop';
import { useTableProject } from 'features/customers/hooks/project/useTableProject';
import { ProjectAction } from 'features/customers/store/actions/ProjectAction';
import { useParams } from 'react-router-dom';

export const PageProjectList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { customerId, confirmDelete, cancelDelete, isOpenModalDelete, setNewPage } = useTableProject();
  
  useEffect(() => {
    dispatch(ProjectAction.getProject({ change: true, fields: {customerId: [+customerId] }}));
    dispatch(ProjectAction.getDataMaster());
    dispatch(ProjectAction.setCurrentProject({ errors: [] }));

    return () => {
      dispatch(ProjectAction.setAdvancedFilter({values: []}));
    }
  }, []);

  useEffect(() => {
    scrollTop();
  }, []);
  
  return (
    <Fragment>
      <FilterComponent/>
      <Button
        icon="pi pi-plus"
        type="button"
        label="Nuevo"
        className="sig-button sig-dark mt-4"
        onClick={(e) => { setNewPage(); }}
      />
      <TableComponent source="client"/>
      <AdvancedFilterComponent />
      <ConfirmDialog
        visible={isOpenModalDelete}
        onHide={cancelDelete}
        message="¿Está seguro que desea eliminar el Proyecto?"
        header="Eliminar"
        icon="pi pi-trash"
        accept={confirmDelete}
        reject={cancelDelete}
      />
    </Fragment>
  );
};
