import { SubscriptionConstants } from "features/subscription/commons/SubscriptionConstants";
import { SolutionsService } from "features/subscription/services/SolutionsService";
import { CoreConstants } from 'core/commons/CoreConstants';
import moment from 'moment';
import { toastAction } from "core/store/actions/ToastAction";
import FileSaver from 'file-saver';


const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: SubscriptionConstants.Accion.Solution.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: SubscriptionConstants.Accion.Solution.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.name)
      pines.push({ field: 'name', value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });


    advancedFilter.values = pines;
    dispatch({
      type: SubscriptionConstants.Accion.Solution.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setSolutions = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SubscriptionConstants.Accion.Solution.SEARCH_SOLUTIONS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const solutions = getState().solutionReducer.solutions;
    setSolutions({
      dispatch: dispatch,
      isLoading: true,
      data: solutions.data,
    });

    const criteria = {};
    const advancedFilter = getState().solutionReducer.advancedFilter;
    const filter = getState().solutionReducer.advancedFilter.filter;
    
    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    SolutionsService.searchSolutions(criteria).then(
      ({ data } ) => {
        if (data.status === CoreConstants.HttpResponse.OK){
          setSolutions({
              dispatch: dispatch,
              isLoading: false,
              data: data.data?.map(el=> {
                el.data.isFirstRow = true
                return el
              }),
            })
        }
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          setSolutions({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Soluciones', data.message));
        }else{
          setSolutions({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Soluciones', data.message));
        }
      },
      // () => {
      //   setSolutions({ dispatch: dispatch, isLoading: false, data: null });
      // }
    ).catch((error)=>{
    });
  };
};

const getSolutionById = (solutionId) => {
  const setSolution = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SubscriptionConstants.Accion.Solution.GET_SOLUTION_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setSolution({ dispatch: dispatch, isLoading: true });
    SolutionsService.getSolutionById(solutionId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setSolution({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setSolution({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Soluciones', data.message));
        }else{
          setSolution({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.error('Soluciones', data.message));
        }
      },
      () => {
        setSolution({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};

const saveSolution = (solution) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentSolution }) => {
    dispatch({
      type: SubscriptionConstants.Accion.Solution.SAVE_SOLUTION,
      payload: {
        isSaving,
        isSaved,
        // currentSolution
      },
    });
  };

  return (dispatch, getState) => {
    const currentSolution = getState().solutionReducer.solution;

    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
      // currentSolution,
    });
    SolutionsService.saveSolutions(solution).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Solución', 'La solución se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentSolution });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentSolution });
          dispatch(toastAction.warn('Solución', data.message));
        }else{
          dispatch(toastAction.error('Solución', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }

      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};



const deleteSolution = (solutionId) => {
  return (dispatch, getState) => {

    SolutionsService.deleteSolution(solutionId).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Solución', 'La solución se eliminó correctamente'));
          dispatch({ type: SubscriptionConstants.Accion.Solution.DELETE_SOLUTION, payload: { isDeleted: true } });
        } else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Solución', data.message));
          dispatch({ type: SubscriptionConstants.Accion.Solution.DELETE_SOLUTION, payload: { isDeleted: false } });
        }else{
          dispatch(toastAction.error('Solución', data.message));
          dispatch({ type: SubscriptionConstants.Accion.Solution.DELETE_SOLUTION, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: SubscriptionConstants.Accion.Solution.DELETE_SOLUTION, payload: { isDeleted: false } });
      }
    ).catch((error)=>{
    });
  };
};

const generateReport = (payload) => {
  const setSolutions = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SubscriptionConstants.Accion.Solution.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setSolutions({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().solutionReducer.advancedFilter.filter;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    SolutionsService.generateReport(criteria)
      .then(({ data }) => {
        setSolutions({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Solución.xls');
      })
      .catch((err) => {
        setSolutions({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Solución', 'Hubo un error al descargar el archivo'));
      });
  };
};

const getDataOptions = () => {
  const setOptions = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: SubscriptionConstants.Accion.Solution.GET_DATA_MASTER,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setOptions({ dispatch: dispatch, isLoading: true });

    SolutionsService.getDataOptions()
      .then(({ data }) => {
        setOptions({ dispatch: dispatch, isLoading: false, data: data?.data, options: data.data?.options});
      })
      .catch((err) => {
        setOptions({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Solución', 'Hubo al obtener la información'));
      });
  }
};

export const SolutionsAction = {
  toggleAdvancedFilter,
  updateFilter,
  search,
  saveSolution,
  deleteSolution,
  generateReport,
  resetAfterSaved,
  getSolutionById,
  getDataOptions
}