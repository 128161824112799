import { RrhhConstants } from 'features/rrhh/commons/RrhhConstants';

const initialState = {
  positions: {
    data: [],
    loading: false,
    order: 'asc',
    pagination: {},
    columnOrder: 'name',
  },
  actions: {
    loading: false,
    items: [
      { name: 'Editar', icon: 'pi pi-pencil' },
      { name: 'Eliminar', icon: 'pi pi-trash' },
    ],
  },
  advancedFilter: {
    showModal: false,
    values: [],
  },
  downloadReport: {
    loading: false,
  },
  currentAction: {
    isOpen: false,
    positionId: null,
  },
  editPosition: {
    isLoading: false,
    isLoadingPosition: false,
    data: {},
    /*  0 - success   */
    status: null,
    errors: [],
  },
  dataMaster: {
    isLoading: false,
    data: {
      areas: [],
      positions: [],
      positionTypes: [],
      positionStatus: [],
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RrhhConstants.Accion.Positions.SEARCH_POSITIONS:
      return {
        ...state,
        positions: {
          ...state.positions,
          ...action.payload,
        },
      };
    case RrhhConstants.Accion.Positions.ADVANCED_FILTER:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload,
        },
      };
    case RrhhConstants.Accion.Positions.REPORT_STATUS:
      return {
        ...state,
        downloadReport: {
          loading: action.payload,
        },
      };
    case RrhhConstants.Accion.Positions.CURRENT_ACTION:
      return {
        ...state,
        currentAction: {
          ...state.currentAction,
          ...action.payload,
        },
      };
    case RrhhConstants.Accion.Positions.EDIT_POSITION:
      return {
        ...state,
        editPosition: {
          ...state.editPosition,
          ...action.payload,
        },
      };
    case RrhhConstants.Accion.Positions.GET_DATA_MASTER:
      return {
        ...state,
        dataMaster: {
          ...state.dataMaster,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
