import { Skeleton } from "primereact/skeleton";
import { Tooltip } from "primereact/tooltip";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";

export const ListControlsConfigurations = ({
  dataMaster,
  businessSheet,
  isLoadingListControls,
  listControls,
}) => {
  const params = useParams();
  const businessSheetId = params.id;

  const getListStyleOptions = (isDraggingOver) => ({
    border: isDraggingOver ? '1px dashed rgb(75 85 99)' : 'none',
    padding: '10px',
    margin: '0 0 0 0'
  });

  return (
    <div className="listControls-business-sheet">
      <div className="mb-2 border-b">
        <span className="w-full col-span-12 lg:col-span-4 items-center justify-start pl-3 hidden lg:block">
          {businessSheetId ? (
            dataMaster.businessSheetStatus?.length > 0 && businessSheet?.statusId ? (
              <p className="font-bold">
                Estado:{" "}
                {
                  dataMaster.businessSheetStatus.find(
                    (status) => status.id === businessSheet.statusId
                  ).description
                }
              </p>
            ) : (
              <Skeleton />
            )
          ) : (
            <p className="font-bold">Estado: Borrador</p>
          )}
        </span>
      </div>
      <div className="ml-3 mb-3 hidden lg:block">
        <p>
          <strong>Tipos de Campos</strong>
        </p>
        <p className="text-sm text-gray-400">
          Arrastre para agregar el campo en el formulario
        </p>
      </div>
      <div className="scroll options-business-sheet">
        <Droppable droppableId="items-menu" direction="vertical" type="items-questions">
          {(droppableProvided, snapshot) => (
            <div
              ref={droppableProvided.innerRef}
              style={getListStyleOptions(snapshot.isDraggingOver)}
              {...droppableProvided.droppableProps}
              className="grid grid-cols-1 gap-y-2 md:gap-2 items-center bg-blue-200 lg:bg-white"
            >
              {isLoadingListControls ? (
                <Skeleton height="16rem" />
              ) : (
                listControls?.map(({ code, icon, name, id }, index) => (
                  <Draggable
                    key={`drag-${code}`}
                    draggableId={`${code}:${id}`}
                    index={index}
                  >
                    {(draggableProvided, snapshot) => (
                      <>
                        <Tooltip
                          target=".customClassName"
                          mouseTrack
                          mouseTrackLeft={10}
                          className="lg:hidden"
                        />
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          data-pr-tooltip={name}
                          data-pr-position="top"
                          className="customClassName border-2 border-stone-700 flex justify-center md:justify-start items-center h-10 w-10 md:h-12 md:w-12 lg:w-full lg:rounded-lg hover:bg-gray-200 lg:bg-white"
                        >
                          <i
                            className={`${icon} ml-4 mr-4 lg:mr-1`}
                            style={{ color: "#43A1F3" }}
                          ></i>
                          <p className="hidden lg:block text-center text-xs lg:text-xs lg:text-left">
                            {name}
                          </p>
                        </div>
                      </>
                    )}
                  </Draggable>
                ))
              )}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};
