const { Editor } = require("primereact/editor");
const { InputText } = require("primereact/inputtext");
const { default: HeaderEditor } = require("shared/components/header-editor/HeaderEditor");

export const InputSimple = ({label, isRequired}) => {
  return(
    <span className="p-float-label w-full mt-4">
      <InputText
        name="label"
        className={`w-full`}
        readOnly
      />
       <label htmlFor="label">{label}{isRequired ? " *":""}</label>
    </span>
  )
}

const header = HeaderEditor();

export const EditorText = ({label, isRequired}) => {
  return(
    <span className="w-full">
      <label htmlFor="label" style={{fontSize: 12, color: "rgb(206, 212, 218)"}}>{label}{isRequired ? " *":""}</label>
      <Editor
        name="label"
        headerTemplate={header}
        readOnly
      />
    </span>
  )
}