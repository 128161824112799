import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RiskManagementAction } from '../store/actions/RiskManagementAction';

const ColorPicker = () => {

	const selection = useSelector(state => state.riskManagementReducer.selectSquare)
	const { colors } = useSelector(state => state.riskModelReducer.dataMasterEvaluation.data)
	
	const dispatch = useDispatch()

	const cambiar = (color) => {
		dispatch(RiskManagementAction.changeColor(color))
	}

	return (
		selection.length > 0 &&
		<div>
			<div className='col-span-2'>
				Seleccione un color
			</div>
			<div className='grid grid-cols-2 gap-2'>
				{
					colors?.map( item => (
						<Fragment key={item.id}>
							<div className='w-8 h-8 flex justify-center items-center cursor pointer selector-color' style={{border: `2px none ${item.colorCode}`}}>
							<div className={`h-6 w-6 cursor-pointer ${item.code === "NOCOL" && 'border border-black'}`} style={{backgroundColor: item.description}} onClick={()=>cambiar(item)}>
								{
									item.code === "NOCOL" && <div className='w-9 diagonal border-red-600 border-b-2'>

									</div>
								}</div></div>
						</Fragment>
					))
				}
			</div>
		</div>
	);
};

export default ColorPicker;