import { SubscriptionConstants } from 'features/subscription/commons/SubscriptionConstants';

const initialState = {
  modules: {
    currentData: [],
    dataModules: [],
    loadingModules: false,
    pagination: {},
    currentCol: 'name',
    order: 'asc',
  },
  moduleFilter: {    
    valuesModule: [],
  },
  moduleEdit: {
    loadingEdit: false,
    loadingSave: false,    
    isSave: false,    
    dataModule: {},
  },  
  dataMaster: {
    status:[],
    loadingDataManager: true,
    dataMaster: {
      
    },
  },
  moduleDeleteModal: {
    isOpen: false,
    moduleId: '',
    isLoading: false,
  },
  downloadReport: {
    loading: false,
  },

};

export default (state = initialState, action) => {
  switch (action.type) {
    case SubscriptionConstants.Accion.ModulesIso.SEARCH_MODULES:
      return {
        ...state,
        modules: {
          ...state.modules,
          ...action.payload,
        },
      };    
    case SubscriptionConstants.Accion.ModulesIso.REMOVE_FILTER_VALUES:
      return {
        ...state,
        moduleFilter: {
          ...state.siteFilter,
          ...action.payload,
        },
      };    
    case SubscriptionConstants.Accion.ModulesIso.SET_FILTER_VALUES:
      return {
        ...state,
        moduleFilter: {
          ...state.moduleFilter,
          ...action.payload,
        },
      };    
      case SubscriptionConstants.Accion.ModulesIso.DATA_MASTER:
        return {
          ...state,
          dataMaster: {
            ...state.dataMaster,
            ...action.payload,
          },
        };
      case SubscriptionConstants.Accion.ModulesIso.EDIT_MODULES:
        return {
          ...state,
          moduleEdit: {
            ...state.moduleEdit,
            ...action.payload,
          },
        }
      case SubscriptionConstants.Accion.ModulesIso.GET_REPORT:{
          return {
            ...state,
            report:{
              ...state,
              ...action.payload
            }
          }
        }
      default:
      return state;
  }
};