import { ConfigurationConstants } from "features/configuration/commons/ConfigurationConstants";

const initialState = {
    dataMaster: {
      isLoading: false,
      roles: [],
    },
    process: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false
  },
  formularies: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false
  },
  occupations: {
    isLoading: false,
    data: null
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'name',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {}
  },
  advancedFilterView: {
    showModal: false,
    values: [],
    columnOrder: 'name',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {}
  },
  listControls: {
    data: null,
    isLoading: false
  },
  listResponsibles: {
    data: null,
    controlUser: [],
    controlData: [],
    controlProcess: [],
    isLoading: false
  },
  operators: {
    logical: [],
    conditional: [],
    isLoading: false
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case ConfigurationConstants.Accion.Processes.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
      case ConfigurationConstants.Accion.Processes.UPDATE_FILTER:
        return {
            ...state,
            advancedFilter: state.advancedFilter
        };
      case ConfigurationConstants.Accion.Processes.UPDATE_FILTER_VIEW:
        return {
            ...state,
            advancedFilterView: state.advancedFilterView
        };
    case ConfigurationConstants.Accion.Processes.SEARCH_PROCESSES:
        return {
            ...state,
            occupations: {
                isLoading: action.payload.isLoading,
                data: action.payload.data
            }
        };
    case ConfigurationConstants.Accion.Processes.GET_PROCESS_BY_ID:
      return {
          ...state,
          process: {
              data: action.payload.data,
              isSaving: false,
              isLoading: action.payload.isLoading
          }
        };

    case ConfigurationConstants.Accion.Processes.RESTORE_PROCESS:
      return {
          ...state,
          process: {
            data: null,
            isSaving: false,
            isSaved: false,
            isDeleted: false,
            isLoading: false
          }
        };

    case ConfigurationConstants.Accion.Processes.SAVE_PROCESS:
        return {
            ...state,
            formularies: {
                data: action.payload.currentOccupation,
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };

    case ConfigurationConstants.Accion.Processes.DELETE_PROCESS:
        return {
            ...state,
            occupation: {
                isDeleted: action.payload.isDeleted
            }
        };

    case ConfigurationConstants.Accion.Processes.RESET_AFTER_SAVED:

        return {
            ...state,
            process: {
              data: null,
              isSaving: false,
              isSaved: false,
              isDeleted: false,
              isLoading: false
            },
        };

      case ConfigurationConstants.Accion.Processes.GET_DATA_MASTER:
          return {
              ...state,
              dataMaster: {
                  isLoading: action.payload.isLoading,
                  roles: action.payload.roles,
              }
          };

      case ConfigurationConstants.Accion.Processes.DOWNLOAD_REPORT_STATUS:
          return {
              ...state,
              occupation: {
                  ...state,
                  isLoading: action.payload.isLoading,
              },
          };
    
    // case ConfigurationConstants.Accion.Processes.GET_PROCESS_BY_ID:
    //   return {
    //       ...state,
    //       occupation: {
    //           data: action.payload.data,
    //           isSaving: false,
    //           isLoading: action.payload.isLoading
    //       }
    //     };
    case ConfigurationConstants.Accion.Processes.LIST_CONTROLS:
        return {
            ...state,
            listControls: {
                data: action.payload.data,
                isLoading: action.payload.isLoading
            }
          };
    case ConfigurationConstants.Accion.Processes.LIST_RESPONSIBLES:
        return {
            ...state,
            listResponsibles: {
                data: action.payload.data,
                isLoading: action.payload.isLoading,
                // controlUser: action.payload.controlUser,
                // controlData: action.payload.controlData,
                // controlProcess: action.payload.controlProcess,
            },
            // operators: {
            //   logical: action.payload.logical,
            //   conditional: action.payload.conditional,
            //   isLoading: action.payload.isLoading
            // }
          };
      
      default: 
      return state;
  }

}
