export class DocumentsConstants {
  static Accion = class {

    static Documents = class {
      static get GET_DATA_MASTER() {
        return "@DOCUMENTS.GET_DATA_MASTER";
      }
      static get ADVANCED_FILTER_TOGGLE() {
        return "@DOCUMENTS.ADVANCED_FILTER_TOGGLE";
      }
      static get IS_STORAGE_ENABLED() {
        return "@DOCUMENTS.IS_STORAGE_ENABLED";
      }
      static get UPDATE_FILTER() {
        return "@DOCUMENTS.UPDATE_FILTER";
      }
      static get SEARCH() {
        return "@DOCUMENTS.SEARCH";
      }
      static get DOWNLOAD_REPORT_STATUS() {
        return "@DOCUMENTS.DOWNLOAD_REPORT_STATUS";
      }
      static get GET_DATA_LIST_FOLDERS() {
        return "@DOCUMENTS.GET_DATA_LIST_FOLDERS";
      }
      static get GET_RECENT_FILES() {
        return "@DOCUMENTS.GET_RECENT_FILES";
      }
      static get GET_CURRENT_FILES() {
        return "@DOCUMENTS.GET_CURRENT_FILES";
      }
      static get GET_MY_FILES() {
        return "@DOCUMENTS.GET_MY_FILES";
      }
      static get GET_TRASH_FILES() {
        return "@DOCUMENTS.GET_TRASH_FILES";
      }
      static get GET_DATA_LIST_FILES_AND_FOLDERS() {
        return "@DOCUMENTS.GET_DATA_LIST_FILES_AND_FOLDERS";
      }
      static get RESET_AFTER_SAVED() {
        return "@DOCUMENTS.RESET_AFTER_SAVED";
      }
      static get GET_DOCUMENT_LIBRARY_BY_ID() {
        return "@DOCUMENTS.GET_DOCUMENT_LIBRARY_BY_ID";
      }
      static get DELETE_DOCUMENT_LIBRARY() {
        return "@DOCUMENTS.DELETE_DOCUMENT_LIBRARY";
      }
      static get MOVE_DOCUMENT_LIBRARY() {
        return "@DOCUMENTS.MOVE_DOCUMENT_LIBRARY";
      }
      static get COPY_DOCUMENT_LIBRARY() {
        return "@DOCUMENTS.COPY_DOCUMENT_LIBRARY";
      }
      static get COPY_DOCUMRENAME_DOCUMENT_LIBRARYENT_LIBRARY() {
        return "@DOCUMENTS.RENAME_DOCUMENT_LIBRARY";
      }
      static get UPLOAD_FILE() {
        return "@DOCUMENTS.UPLOAD_FILE";
      }
      static get RENAME_DOCUMENT_LIBRARY() {
        return "@DOCUMENTS.RENAME_DOCUMENT_LIBRARY";
      }
    };
  }
}