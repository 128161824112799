import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';

import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { useDispatch, useSelector } from 'react-redux';
import { RiskInventoryAction } from 'features/riskManagement/store/actions/RiskInventoryAction';
import { useClaimInventory } from 'features/riskManagement/hooks/useClaimInventory';

const NewRiskInventoryPage = ({mode}) => {

	const { updateTitle } = useSetTitlePage();
	const dispatch = useDispatch()
	const { inventoryTypes, typeManualId, dynamicProcesses, typeDynamicProcessId } = useSelector(state => state.riskInventoryReducer.dataMaster)
	const { data, isLoading } = useSelector(state => state.riskInventoryReducer.inventory)
	const [showConfirmDialog, setShowConfirmDialog] = useState(false)
	const history = useHistory()
	const {id} = useParams()

	const {
		control,
		handleSubmit,
		watch,
		reset,
		formState: { errors },
	} = useForm({
			shouldUnregister: true,
			defaultValues: {
				name: "",
				description: "",
				inventoryTypeId: "",
				dynamicProcessId: "",
				isMultiple: false,
		}	
	});

	const { isCreate, isEdit } = useClaimInventory()

	useEffect(()=> {
		if(mode==='view'){
			updateTitle({
				title: "Gestión de Riesgos",
				subtitle: "Inventario",
				description: "Ver Inventario",
				previousUrl: "/gestion-riesgos/inventario",
			})
		}else	if(id){
			updateTitle({
				title: "Gestión de Riesgos",
				subtitle: "Inventario",
				description: "Editar Inventario",
				previousUrl: "/gestion-riesgos/inventario",
			})
		}else{
			updateTitle({
				title: "Gestión de Riesgos",
				subtitle: "Inventario",
				description: "Nuevo Inventario",
				previousUrl: "/gestion-riesgos/inventario",
			})
		}
    
  }, [updateTitle,id])

	useEffect(()=> {
		dispatch(RiskInventoryAction.getDataMaster())
	},[dispatch])

	useEffect(()=>{
		if(id){
			dispatch(RiskInventoryAction.getDataById(id))
		}
	},[id, dispatch])

	useEffect(()=> {
		if(!isLoading){
			reset({
				name: data.name || "",
				description: data.description || "",
				inventoryTypeId: data.inventoryTypeId || "",
				isMultiple: data.isMultiple || false,
				dynamicProcessId: data.dynamicProcessId || "",
			})
		}

		return () => {
			dispatch(RiskInventoryAction.clearInventory())
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[isLoading, dispatch, reset])


const submit = (data) => {
	if(id){
		dispatch(RiskInventoryAction.save({ id: Number(id), ...data}, history))	
	} else {
		dispatch(RiskInventoryAction.save(data, history))
	}
}

const watchinventoryTypeId = watch("inventoryTypeId");

	return (
		<div className="bg-white">
			<form	className="form-custom p-0" onSubmit={handleSubmit(submit)}>
				<Panel header="Datos Generales" toggleable>
					<div className="grid sm:grid-cols-3 md:grid-cols-3 gap-2">
						<Controller
							control={control}
							name="name"
							rules={{required: 'El Nombre es requerido'}}
							render={({ field: { onChange, value }	}) => (
								<span className="p-float-label w-full mt-4">
									<InputText
										type="text"
										id="name"
										onChange={onChange}
										value={value}
										className={errors?.name ? 'p-invalid w-full' : 'w-full'}
									/>
									<label htmlFor="name">Nombre *</label>
								</span>
							)}
						/>
						<span className="p-float-label w-full mt-4 hidden md:block">
							&nbsp;
						</span>
						<span className="p-float-label w-full mt-4 hidden md:block">
							&nbsp;
						</span>
					</div>
					<div className="grid sm:grid-cols-4 md:grid-cols-4 gap-2">
						<Controller
							control={control}
							name="description"
							render={({ field: { onChange, value }	}) => (
								<span className="p-float-label w-full mt-6 col-span-3">
									<InputText
										type="text"
										id="description"
										onChange={onChange}
										value={value}
										className={errors?.description ? 'p-invalid w-full' : 'w-full'}
									/>
									<label htmlFor="description">Descripción</label>
								</span>
							)}
						/>
						<span className="p-float-label w-full mt-4 hidden md:block">
							&nbsp;
						</span>
					</div>
					<div className="grid sm:grid-cols-3 md:grid-cols-3 gap-2">
						<Controller
							control={control}
							name="inventoryTypeId"
							rules={{required: 'El Tipo es requerido'}}
							render={({ field: { onChange, value }	}) => (
								<span className="p-float-label w-full mt-6">
									<Dropdown
										options={inventoryTypes}
										optionLabel= 'description'
										optionValue='id'
										id="inventoryTypeId"
										onChange={onChange}
										value={value}
										className={errors?.inventoryTypeId ? 'p-invalid w-full' : 'w-full'}
									/>
									<label htmlFor="inventoryTypeId">Tipo *</label>
								</span>
							)}
						/>
						{
							watchinventoryTypeId !== typeManualId && 
							<Controller
							control={control}
							name="isMultiple"
							render={({ field: { onChange, value }	}) => (
								<div className='flex mt-6 items-center'>
									<InputSwitch checked={value} onChange={onChange}/>
									<h5 className='ml-2'>
										Múltiple
									</h5>
								</div>
							)}
							/>
						}
					</div>
					{
						watchinventoryTypeId === typeDynamicProcessId &&
						<div className="grid sm:grid-cols-3 md:grid-cols-3 gap-2">
							<Controller
								control={control}
								name="dynamicProcessId"
								rules={{required: 'El Proceso Dinámico es requerido'}}
								render={({ field: { onChange, value }	}) => (
									<span className="p-float-label w-full mt-6">
										<Dropdown
											options={dynamicProcesses}
											optionLabel= 'name'
											optionValue='id'
											id="dynamicProcessId"
											onChange={onChange}
											value={value}
											className={errors?.dynamicProcessId ? 'p-invalid w-full' : 'w-full'}
										/>
										<label htmlFor="dynamicProcessId">Seleccionar proceso *</label>
									</span>
								)}
							/>
						</div>
					}
					{
						(Object.keys(errors).length > 0) && 
							<div className='message error'>
								<ul>
										{
											Object.keys(errors).map((item, index)=>{return(
														<li key={index} className="p-error">{errors[item].message}</li>
											)})
										}      
								</ul>
							</div>
					}
					<div className='flex justify-end'>
						<Button
							loading={isLoading}
							icon="pi pi-times"
							type="button"
							label="Cancelar"
							onClick={() => { setShowConfirmDialog(true); }}
							className="sig-button sig-secondary mt-4"
						/>
						{
							(mode!=='view' && (isCreate || isEdit))&&
								<Button
									loading={isLoading}
									icon="pi pi-save"
									type="submit"
									label="Guardar"
									className="sig-button sig-primary mt-4 ml-4"
								/>
						}
					</div>
				</Panel>
			</form>
			<ConfirmDialog
				visible={showConfirmDialog}
				onHide={()=>setShowConfirmDialog(false)}
				message="¿Está seguro que desea cancelar?"
				header="Cancelar"
				icon="pi pi-exclamation-triangle"
				accept={()=>history.push('/gestion-riesgos/inventario')}
				reject={()=>setShowConfirmDialog(false)}
			/>
		</div>
	);
};

export default NewRiskInventoryPage;