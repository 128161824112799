/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Dropdown } from "primereact/dropdown";

import ModalFilterComponent from "shared/components/modal-filter/ModalFilterComponent";
import PinerComponent from "shared/components/Piner/PinerComponent";

import { getFilterValuesFromPiners, getPinersValues } from "features/business/helpers/businessSheet/BusinessSheetHelper"

import { BusinessSheetAction } from "features/business/store/actions/BusinessSheetAction"
import { useFilter } from "shared/hooks/useFilter";
import { BusinessSheetConstants } from "features/business/commons/BusinessSheetConstants"; 
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { CoreConstants } from "core/commons/CoreConstants";
import { useHistory } from "react-router-dom";
import { SplitButton } from "primereact/splitbutton";
import { ModalUploadTemplate } from "./ModalUploadTemplate";

export const FilterComponent = () => {
  const { changeShowModal, onChangeValues, onSubmit, piners, removePiner, showModal, values, onDownload, resetValues, resetState } = useFilter("name", getFilterValuesFromPiners, getPinersValues, BusinessSheetAction.search, BusinessSheetAction.downloadReport)
  const history = useHistory()

  const { data } = useSelector(state => state.businessSheetReducer.dataMaster)
  
  const loading = useSelector(state => state.businessSheetReducer.businessSheet.loading)

  const isLoadingDownload = useSelector(state => state.businessSheetReducer.businessSheet.isLoadingDownload)

  const [displayModal, setDisplayModal] = useState(false)

  const items = [
    {
        label: 'Desde Plantilla',
        command: (e) => {
          setDisplayModal(true)
        }
    },
  ];

  useEffect(() => {

    return () => {
      resetState()
    }
  },[])

  return (
    <>
      <Panel header="FILTRO POR" toggleable>
        <form className="filter-roles">
          <div className={'w-full visible'}>
            <span className="p-float-label p-input-icon-left w-full">
              <i className="pi pi-search" />
              <InputText
                id="input-search"
                name={BusinessSheetConstants.FieldName.Filter.NAME}
                value={values[BusinessSheetConstants.FieldName.Filter.NAME]?.value || ""}
                onChange={(e) => {
                  onChangeValues({
                    name: e.target.name,
                    value: {
                      value: e.target.value,
                      searchValue: e.target.value,
                      pinerValue: e.target.value,
                    }
                  })
                }}
              />
              <label htmlFor="input-search">Buscar por nombre de Ficha de Negocio</label>
            </span>
          </div>
          <div className="filter-users-action">
            <div className="flex gap-3 w-full">
              <Button
                icon="pi pi-search"
                type="submit"
                label="Buscar"                  
                className="sig-button sig-primary w-full"
                onClick={onSubmit}
                loading={loading || isLoadingDownload}
              />
              <Button
                icon="pi pi-download"
                type="button"
                label="Descargar"
                className="sig-button sig-success w-full"
                onClick={onDownload}
                loading={loading || isLoadingDownload}
              />
            </div>
            <div className="flex mt-3">
              <Button
                type="button"
                label="Búsqueda avanzada"
                className="sig-button  sig-secondary w-full"
                onClick={() => changeShowModal(true)}
                loading={loading || isLoadingDownload}
              />
            </div>
          </div>
        </form>
          {piners.length > 0 && (
            <div className="filter-piners mt-3">
              {piners.map(({ value, field }, index) => (
                <PinerComponent
                  name={value}
                  field={field}
                  removePiner={removePiner}
                  key={index}
                />
              ))}
            </div>
          )}
          {
            showModal && 
            <ModalFilterComponent
              closeModal={() => changeShowModal(false)}
              handleSubmit={onSubmit}
              clearFields={resetValues}
            >
              <div className="w-full sm:w-80">
              <div className="flex gap-4 pt-3">
                  <div className="w-full">
                    <span className="p-float-label">
                      <InputText
                        name={BusinessSheetConstants.FieldName.Filter.CODE}
                        value={values[BusinessSheetConstants.FieldName.Filter.CODE]?.value || ""}
                        onChange={(e) => {
                          onChangeValues({
                            name: e.target.name,
                            value: {
                              value: e.target.value,
                              searchValue: e.target.value,
                              pinerValue: e.target.value,
                            }
                          }
                        )}}
                      />
                      <label htmlFor="desde">Código</label>
                    </span>
                  </div>
                </div>
                <div className="flex gap-4 pt-7">
                  <div className="w-full">
                    <span className="p-float-label">
                      <Dropdown
                        options={data.businessSheetStatus}
                        optionLabel="description"
                        showClear
                        className="w-full"
                        name={BusinessSheetConstants.FieldName.Filter.STATUS_ID}
                        value={values[BusinessSheetConstants.FieldName.Filter.STATUS_ID]?.value}
                        onChange={(e) => {
                          onChangeValues({
                            name: e.target.name,
                            value: {
                              value: e.target.value,
                              searchValue: e.target.value.id,
                              pinerValue: e.target.value.description,
                            }
                          })
                        }}
                      />
                      <label htmlFor="desde">Estado</label>
                    </span>
                  </div>

                  <div className="w-full">
                    <span className="p-float-label">
                      <Dropdown
                        options={data.businessSheetType}
                        optionLabel="description"
                        showClear
                        className="w-full"
                        name={BusinessSheetConstants.FieldName.Filter.TYPE_ID}
                        value={values[BusinessSheetConstants.FieldName.Filter.TYPE_ID]?.value}
                        onChange={(e) => {
                          onChangeValues({
                            name: e.target.name,
                            value: {
                              value: e.target.value,
                              searchValue: e.target.id,
                              pinerValue: e.target.description,
                            }
                          })
                        }}
                      />
                      <label htmlFor="hasta">Tipo</label>
                    </span>
                  </div>
                </div>

                <div className="flex gap-4 pt-7">
                  <div className="w-full">
                    <span className="p-float-label">
                      <Calendar
                        className="w-full"
                        style={{height: 37.19}}
                        name={BusinessSheetConstants.FieldName.Filter.FROM_DATE}
                        value={values[BusinessSheetConstants.FieldName.Filter.FROM_DATE]?.value}
                        onChange={(e) => {
                          const pinerValue = e.target.value && moment(e.target.value).format(CoreConstants.Format.DDMMYYYY)
                          onChangeValues({
                            name: e.target.name, 
                            value: {
                              value: e.target.value,
                              searchValue: e.target.value,
                              pinerValue: pinerValue,
                            }
                          })
                        }}
                      />
                      <label htmlFor="openDate">Creación desde</label>
                    </span>
                  </div>

                  <div className="w-full">
                    <span className="p-float-label">
                      <Calendar
                        className="w-full"
                        style={{height: 37.19}}
                        name={BusinessSheetConstants.FieldName.Filter.TO_DATE}
                        value={values[BusinessSheetConstants.FieldName.Filter.TO_DATE]?.value}
                        onChange={(e) => {
                          const pinerValue = e.target.value && moment(e.target.value).format(CoreConstants.Format.DDMMYYYY)
                          onChangeValues({
                            name: e.target.name, 
                            value: {
                              value: e.target.value,
                              searchValue: e.target.value,
                              pinerValue: pinerValue,
                            }
                          })
                        }}
                      />
                      <label htmlFor="openDate">Creación hasta</label>
                    </span>
                  </div>
                </div>
              </div>
            </ModalFilterComponent>
          }
      </Panel>
      <SplitButton
        label="Nuevo"
        icon="pi pi-plus"
        className="split-button-risk sig-dark mt-4"
        onClick={(e) => {
          history.push('/negocios/fichas/configuracion/nuevo');
        }}
        model={items}
        buttonClassName="sig-button sig-dark"
        menuButtonClassName="sig-button sig-dark"
      />
      <ModalUploadTemplate displayModal={displayModal} setDisplayModal={setDisplayModal}/>
    </>
  )
}