export class DynamicFieldsConstants {
  static Action = class {
    static DynamicFields = class {
      static get ADVANCED_FILTER() {
        return '@DYNAMICFIELDS.ADVANCED-FILTER';
      }
      static get SEARCH_DYNAMICFIELDS() {
        return '@DYNAMICFIELDS.SEARCH-DYNAMICFIELDS';
      }
      static get DATA_MASTER() {
        return '@DYNAMICFIELDS.DATA-MASTER';
      }
      static get CURRENT_ACTION() {
        return '@DYNAMICFIELDS.CURRENT-ACTION';
      }
      static get EDIT_DYNAMICFIELDS() {
        return '@DYNAMICFIELDS.EDIT-COMPANY';
      }
      static get REPORT_STATUS() {
        return '@DYNAMICFIELDS.REPORT-STATUS';
      }
      static get GET_SECTION() {
        return '@DYNAMICFIELDS.GET_SECTION';
      }
    };
    static CodeFields = class {
      static get SIMPLE_TEXT() {
        return 'TXT';
      }
      static get RICH_TEXT() {
        return 'TRH';
      }
    }
    static Status = class {
      static get ENABLE(){
        return "ENA"
      }
      static get DISABLE(){
        return "DIS"
      }
    }
  };
}
