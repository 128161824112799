import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";
import { useState } from "react";
import { useControlConfiguration } from "shared/hooks/useControlConfiguration";

export const ControlGeneralConfiguration = ({children, render, question, changeOptionsAditional, onCancel, removeField, headerTitle}) => {
  const { items, menu, showDelete, showModal, setShowDelete, setShowModal } = useControlConfiguration();
  const [isNewQuestion, setIsNewQuestion] = useState(question.newQuestion)

  useState(() => {
    if(isNewQuestion){
      setShowModal(true)
    }
  },[])

  const footer = (
    <div>
        <Button
          type="button" 
          label="Cancelar"
          icon="pi pi-times"
          className="sig-button sig-secondary"
           onClick={() => {
            if(isNewQuestion){
              removeField(question.id)
              setIsNewQuestion(false)
            }else{
              onCancel()
            }
            setShowModal(false)
          }}
        />
        <Button
          type="button"
          label="Guardar"
          icon="pi pi-save"
          className="sig-button sig-primary"
          onClick={(e) => {
            const error = changeOptionsAditional()
            if(!error) {
              setShowModal(false)
              if(isNewQuestion) setIsNewQuestion(false)
            }
          }}
        />
    </div>
  );

  return(
    <>
      <div className='flex justify-between'>
        <div className="w-full">
          {render(question)}
        </div>
        <div>
          <i
            className="pi pi-ellipsis-v cursor-pointer"
            onClick={(event) => {
              menu.current.toggle(event)
            }}
          />
        </div>
      </div>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <ConfirmDialog
          visible={showDelete}
          onHide={() => setShowDelete(false)}
          message="¿Está seguro que desea eliminar el campo?"
          header="Eliminar"
          icon="pi pi-exclamation-triangle"
          accept={() => removeField(question.id)}
          reject={() => setShowDelete(false)}
        />
      <Dialog
        header={headerTitle} 
        footer={footer}
        visible={showModal}
        style={{width: '35vw'}}
        modal
        onHide={()=> {
          if(isNewQuestion){
            removeField(question.id)
            setIsNewQuestion(false)
          }else{
            onCancel()
          }
          setShowModal(false)
        }}
      >
        <hr/>
        {children}
      </Dialog>
    </>
  )
}