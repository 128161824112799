/* eslint-disable react-hooks/exhaustive-deps */
import { CoreConstants } from "core/commons/CoreConstants"
import { toastAction } from "core/store/actions/ToastAction"
import { ProcessMapService } from "features/configuration/services/ProcessMapService"
import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import useSetTitlePage from "shared/hooks/useSetTitlePage"

export const useConfigurationMap = () => {
  const dispatch = useDispatch()
  const { updateTitle } = useSetTitlePage()
 
  const [ processTypes, setProcessTypes ] = useState([])
  const [ data, setData ] = useState({})
  const processSections = useRef({})
  const [ showDeleteGroup, setShowDeleteGroup ] = useState(false)
  const elementSelected = useRef(null)
  const [ showDeleteSection, setShowDeleteSection ] = useState(false)

  const [expandedRows, setExpandedRows] = useState(null);
  const [sections, setSections] = useState({})
  const [optionsSections, setOptionsSections] = useState({})

  const [showAlert, setShowAlert] = useState(false)
  const [maxSections, setMaxSections] = useState(6)

  const [ isLoadingDataMaster, setIsLoadingDataMaster ] = useState(true)
  const [ isLoadingData, setIsLoadingData] = useState(true)
  const [loadingSave, setLoadingSave] = useState(false)
  const [errorSections, setErrorSections] = useState({ state: false, message: [] })
  const { control, handleSubmit, getValues, watch, setValue, reset , formState: { errors } } = useForm({})

  const isLoading = isLoadingData || isLoadingDataMaster

  useEffect(()=>{
    updateTitle(
      {
        title: "Configuración",
        subtitle: "Mapa de Procesos",
      }
    )
  }, [])

  useEffect(()=>{
    setIsLoadingDataMaster(true)
    ProcessMapService.getDataMaster().then(({data}) => {
      const { processTypes, process, maxSectionCount }  = data.data
      const options = {}
      const _sections = {}
      const _optionsSections = {}
      process.forEach(({id, sections}) => {
        options[id] = { id, sections }
        processSections.current[id] = sections.map(({processes}) => processes).flat().length
        _sections[id] = []
        sections.forEach(({name, processes, sectionId}) => {
          if(processes?.length > 0){
            _sections[id].push({
              id: `${id}_${sectionId}`,
              sectionId,
              groups: [],
              sectionKey: sectionId,
            })
            setValue(`section_${id}_${sectionId}`, processes.map( process => ({
              id: process.id,
              name: process.name,
              processTypeId: process.processTypeId,
              sectionNumber: process.sectionNumber,
            })))
            _optionsSections[`section_${id}_${sectionId}`] = processes.map( process => ({
              id: process.id,
              name: process.name,
              processTypeId: process.processTypeId,
              sectionNumber: process.sectionNumber,
            }))
          }
        })
      })
      setOptionsSections(_optionsSections)
      setSections(_sections)
      // setProcessOptions(options)
      setProcessTypes(processTypes)
      if(maxSectionCount){
        setMaxSections(maxSectionCount)
      }
    }).finally(() => {
      setIsLoadingDataMaster(false)
    })
  },[])

  useEffect(() => {

    if(!isLoadingDataMaster){
      setIsLoadingData(true)
      ProcessMapService.getProcessMapConfiguration()
      .then(({data}) => {
        setData(data.data)
        const { inputMapProcess, outputMapProcess, processTypes } = data.data
        setValue("inputMapProcess", inputMapProcess)
        setValue("outputMapProcess", outputMapProcess)
        
        processTypes.forEach(({processTypeId, backgroundColor, processColor, textColor, processMapSections}) => {
          setValue(`backgroundColor_${processTypeId}`, backgroundColor.slice(1))
          setValue(`processColor_${processTypeId}`, processColor.slice(1))
          setValue(`textColor_${processTypeId}`, textColor.slice(1))
          setValue(`sections_${processTypeId}`, processMapSections?.length)
          
          // const _options = {}
          const _values = {}
          processMapSections.forEach(({number, processMapGroups}) => {
            let index = 1
            processMapGroups.forEach( ({id, isGroup, processes}) => {
              if( isGroup && processes?.length > 0){
                setSections( prevSection => {
                  const copySection = JSON.parse(JSON.stringify(prevSection))
                  const sectionSelected = copySection[processTypeId].find(section => section.sectionId === number)
                  sectionSelected.groups.push({
                    id: id,
                    rowNumber: index,
                    rowNumberOrder: index,
                    processes: processes.map( proc => ({
                      id: proc.id,
                      name: proc.name,
                      processTypeId: proc.processTypeId,
                      sectionNumber: number,
                    }))
                  })
                  return copySection;
                })

                const values =  processes.map( proc => ({
                  id: proc.id,
                  name: proc.name,
                  processTypeId: proc.processTypeId,
                  sectionNumber: number,
                }))

                _values[`group_${processTypeId}_${number}_${id}`] = values
                index = index + 1
              }
            })
          })

          for( const [key, value] of Object.entries(_values)){
            setValue(key, value)
          }
        })
      })
      .finally(() => setIsLoadingData(false))
    }
  },[isLoadingDataMaster])

  const submit = ( formData ) => {
    // console.log(formData)
    const send = {
      id: data.id,
      inputMapProcess: formData.inputMapProcess,
      outputMapProcess: formData.outputMapProcess,
    }

    const processTypeConfiguration = []
    const error = { state: false, message: [] }

    data.processTypes.forEach(( {id, processMapId, processTypeId} ) => {
      const sectionsType = sections[processTypeId];
      const typeConfiguration = {
        id: id || null,
        processMapConfigurationId: processMapId,
        processTypeId: processTypeId,
        backgroundColorCode: `#${formData[`backgroundColor_${processTypeId}`]}`,
        processColorCode: `#${formData[`processColor_${processTypeId}`]}`,
        textColorCode: `#${formData[`textColor_${processTypeId}`]}`,
        sectionQuantity: formData[`sections_${processTypeId}`],
      }

      const _groups = []
      const _sections = []
      let groupNumber = 1;
      sectionsType.forEach(({groups, id, sectionId, sectionKey}) => {
        if(groups.length >= 0){
          groups.forEach( group => {
            const _group = {
              id: group.id > 0 ? group.id : null,
              sectionNumber: sectionId,
              number: groupNumber++,
              position: group.rowNumber,
              processes: formData[`group_${processTypeId}_${sectionKey}_${group.id}`].map( process => process.id )
            }
            _groups.push(_group)
          })
        }
        const _section = {
          sectionNumber: sectionId,
          processes: formData[`section_${processTypeId}_${sectionKey}`].map(({id}) => id)
        }
        _sections.push(_section)
      })
      
      typeConfiguration.groups = _groups
      typeConfiguration.sections = _sections
      typeConfiguration.sectionQuantity = _sections.length
      if(_sections.map(({processes}) => processes).flat().length < processSections.current[processTypeId]){
        const processesName = processTypes.find(({id}) => id === processTypeId)?.name || ''
        error.state = true;
        error.message.push(`Debe asignar una seccion a todos los procesos en ${processesName}`)
      }
      processTypeConfiguration.push(typeConfiguration)
    })

    send.processTypeConfiguration = processTypeConfiguration
    if(error.state){
      setErrorSections(error)
      return
    }

    setLoadingSave(true)
    ProcessMapService.saveProcessMapConfiguration(send)
    .then(({ data }) => {
      const status = data?.status ?? '';
      if (status === CoreConstants.HttpResponse.OK) {
        dispatch(toastAction.success('Mapa de Procesos', 'La configuración se guardó correctamente'));
      } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
        dispatch(toastAction.warn('Mapa de Procesos', data.message));
      }else{
        dispatch(toastAction.error('Mapa de Procesos', data.message));
      }
    })
    .catch( error => console.log(error))
    .finally(() => setLoadingSave(false))
  }

  const onChangeSection = (value, sectionKey, typeId, onChange) => (e) => {
    if(value?.length > e.value?.length){
      const newElement = value.find( ele => !e.value.some( selec => selec.id === ele.id ))
      const idStrat = Object.keys(getValues()).filter( key => key.startsWith(`section_${typeId}`))
      idStrat?.forEach( idS => {
        if(idS !== `section_${typeId}_${sectionKey}`){
          setOptionsSections( prev => ({
            ...prev,
            [idS]: [...(prev[idS] || []), newElement]
          }))
        }
      })
      // quitar de los grupos
      const key = Object.keys(getValues()).find( key => {
        if(Array.isArray(getValues(key)) && key.startsWith('group')){
          return getValues(key).map( ele => ele.id).includes(newElement.id)
        }
        return false
      })
      if(key){
        setValue(key, getValues(key).filter( element => element.id !== newElement.id))
      }
    }

    else{
      const newElement = e.value[e.value.length-1]
      const idStrat = Object.keys(getValues()).filter( key => key.startsWith(`section_${typeId}`))

      idStrat?.forEach( idS => {
        if(idS !== `section_${typeId}_${sectionKey}`){
          setOptionsSections( prev => {
            return {
            ...prev,
            [idS]: prev[idS].filter( process => process.id !== newElement.id )
            }}
          )
        }
      })
    }
    onChange(e)
  }

  const addGroup = (categoryId, sectionId) => () => {
    setSections( prevSections => {
      const copySection = JSON.parse(JSON.stringify(prevSections))
      const currentSection = copySection[categoryId].find(section => section.id === sectionId)
      const id = Math.min( ...(currentSection.groups?.map( ({id}) => id ) || []), 0) - 1
      const rowNumber = Math.max( ...(currentSection.groups?.map( ({rowNumber}) => rowNumber ) || []), 0) + 1
      const rowNumberOrder = Math.max( ...(currentSection.groups?.map( ({rowNumberOrder}) => rowNumberOrder ) || []), 0) + 1
      const newGroup = {
        id,
        rowNumber,
        rowNumberOrder,
        processes: []
      }
      currentSection.groups.push(newGroup)
      return copySection
    })
  }

  const deleteRow = () => {
    const {id, groupId, sectionKey} = elementSelected.current
    const { [`group_${id}_${sectionKey}_${groupId}`]: value, ...values} = getValues()
    reset(values)
    setSections( prevSections => {
      const copySection = JSON.parse(JSON.stringify(prevSections))
      const currentSection = copySection[id].find(section => section.sectionKey === sectionKey)
      const groups = currentSection.groups.filter( ({id}) => id !== groupId)
      groups.forEach((group, index) => {
        group.rowNumber = index+1
        group.rowNumberOrder = index+1
      })
      currentSection.groups = groups
      return copySection
    })
    setShowDeleteGroup(false)
  }

  const addSection = (categoryId) => () => {
    const currentCountSection = sections[categoryId]?.length || 0
    if(currentCountSection >= maxSections){
      setShowAlert(true)
    }
    else {
      setSections((rowsPrev) => {
        const rowPrev = rowsPrev[categoryId]
        const e = rowPrev?.map( row => row?.sectionId ) || []
        const idNew = Math.max( 0, ...e ) + 1;
  
        const eKeys = rowPrev?.map( row => row?.sectionKey) || []
        const keyNew = Math.min(0, ...eKeys) - 1
        const data = { sectionId: idNew, id: `${categoryId}_${keyNew}`, groups: [], sectionKey: keyNew}
        const newRow = [...rowPrev, data]
        const idStrat = Object.keys(getValues()).filter( key => key.startsWith(`section_${categoryId}`))
  
        const elemntsToFilter = []
        idStrat?.forEach( id => {
          elemntsToFilter.push(...(getValues(id)?.map( opt => opt.id) || []))
        })
  
        const idOptions = Object.keys(optionsSections).filter( key => key.startsWith(`section_${categoryId}`))
  
        const options = []
  
        idOptions?.forEach( id => {
          options.push(...optionsSections[id].filter( option => !elemntsToFilter.includes(option.id)))
        })
        
        setOptionsSections( prev => {
          return {...prev, [`section_${categoryId}_${keyNew}`]: [...new Set(options.map(JSON.stringify))].map( JSON.parse)}
        })
        return {...rowsPrev, [categoryId]: newRow}
      })
    }
  }

  const filterOptions = (options, typeId ,sectionKey, groupId) => {
    const optionsSelected = Object.keys(getValues())
      .filter(propiedad => propiedad.startsWith(`group_${typeId}_${sectionKey}`) && !propiedad.startsWith(`group_${typeId}_${sectionKey}_${groupId}`) && getValues(propiedad))
      .map(propiedad => getValues(propiedad)
      .map(({id}) => id))
      .flat()
    const newOptions = JSON.parse(JSON.stringify(options))?.filter( ({id}) => !optionsSelected.includes(id) )
    return newOptions;
  }
  
  const removeSection = () => {
    //Agrego elementos seleccionados a las otras opciones
    const {id, sectionKey} = elementSelected.current;
    const elementsSelected = getValues(`section_${id}_${sectionKey}`) || []
    const idStrat = Object.keys(getValues()).filter( key => key.startsWith(`section_${id}`))
    idStrat?.forEach( idS => {
      if(idS !== `section_${id}_${sectionKey}`){
        setOptionsSections( prev => ({
          ...prev,
          [idS]: [...(prev[idS] || []), ...elementsSelected]
        }))
      }
    })
    
    //Limpio los multiselect
    const idGroupsToDelete = Object.keys(getValues()).filter( key => key.startsWith(`group_${id}_${sectionKey}`))
    const newValues = {}
    Object.keys(getValues()).forEach( key => {
      if( key !== `section_${id}_${sectionKey}` && !idGroupsToDelete.includes(key)){
        newValues[key] = getValues(key)
      }
    })
    reset(newValues)

    //Elimino la seccion
    setSections( prevSections => {
      const copySections = JSON.parse(JSON.stringify(prevSections));
      const sections = copySections[id].filter( (section) => section.sectionKey !== sectionKey);
      sections.forEach((section, index) => {
        section.sectionId = index+1;
      })
      copySections[id] = sections;
      console.log(copySections)
      return copySections;
    })
  }


  return {
    handleSubmit,
    submit,
    isLoading,
    control,
    processTypes,
    watch,
    addGroup,
    deleteRow,
    errors,
    loadingSave,
    sections,
    optionsSections,
    onChangeSection,
    addSection,
    expandedRows,
    setExpandedRows,
    filterOptions,
    removeSection,
    errorSections,
    setErrorSections,
    showDeleteGroup,
    setShowDeleteGroup,
    showDeleteSection,
    setShowDeleteSection,
    elementSelected,
    showAlert,
    setShowAlert,
    maxSections,
  }
}