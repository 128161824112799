/* eslint-disable import/no-anonymous-default-export */

import { SurveysConstants } from "features/survey/commons/SurveyConstants";


const initialState = {
  dataMaster: {
    isLoading: false,
    areas: [], 
    categories: [], 
    permissionTypes: [], 
    permissions: [], 
    positions: [], 
    questionTypes: [], 
    roles: [], 
    status: [], 
    surveyTypes: [], 
    surveySectionTypes: [],
    users: []
  },
  survey: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false,
    isUpdated: false,
    isClosed: false,
    isCloseStatus: null,
  },
  surveyCopy: {
    data: null,
    isLoading: false,
  },
  surveySummary: {
    data: null,
    title: '',
    isLoading: false,
  },
  surveyDataTrend: {
    data: null,
    isLoading: false,
  },
  surveyDataTotal: {
    data: null,
    isLoading: false,
  },
  surveyQuestionInfo: {
    data: null,
    isLoading: false,
  },
  surveyDataAnswers: {
    data: null,
    isLoading: false,
  },
  surveys: {
    data: [],
    isLoading: false,
    isLoaded: false,
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'name',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {}
  },
  listFolders: {
    data: [],
    isLoading: false,
  },
  currentPath: {},
  link: null,
  contacts: {
    data: [],
    isLoading: false,
    isSending: false,
  },
  surveyAnswers: {
    data: [],
    isLoading: false,
    // isDeleting: false,
    isDeleted: false,
  },
  advancedFilterSurveyAnswers: {
    columnOrder: 'name',
    order: 'asc',
    page: 1,
    pageSize: 10,
  },
  surveyAnswerQuestion: {
    data: [],
    isLoading: false,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SurveysConstants.Accion.Survey.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
    case SurveysConstants.Accion.Survey.UPDATE_FILTER:
      return {
        ...state,
        advancedFilter: state.advancedFilter
      };
    case SurveysConstants.Accion.Survey.UPDATE_FILTER_SURVEY_ANSWERS:
      return {
        ...state,
        advancedFilterSurveyAnswers: state.advancedFilterSurveyAnswers
      };
    case SurveysConstants.Accion.Survey.RESET_AFTER_SAVED:
      return {
          ...state,
          survey: {
              data: null,
              isSaving: false,
              isSaved: false,
              isLoading: false,
              isDeleted: false,
              isUpdated: false,
          },
          surveyCopy: {
            data: null,
            isLoading: false,
          },
        };
    case SurveysConstants.Accion.Survey.DOWNLOAD_REPORT_STATUS:
      return {
          ...state,
          survey: {
              ...state,
              isLoading: action.payload.isLoading,
          },
      };
    case SurveysConstants.Accion.Survey.SEARCH_SURVEYS:
      return {
          ...state,
          surveys: {
              isLoading: action.payload.isLoading,
              data: action.payload.data,
              isLoaded: action.payload.isLoaded,
          }
      }; 
    case SurveysConstants.Accion.Survey.GET_SURVEY_ANSWERS:
      return {
          ...state,
          surveyAnswers: {
              ...state.surveyAnswers,
              isLoading: action.payload.isLoading,
              data: action.payload.data,
          }
      }; 
    case SurveysConstants.Accion.Survey.GET_SURVEY_ANSWER_BY_ID:
      return {
        ...state,
        surveyAnswerQuestion: {
            data: action.payload.data,
            isLoading: action.payload.isLoading
        }
      };
    case SurveysConstants.Accion.Survey.GET_SURVEY_BY_ID:
      return {
        ...state,
        survey: {
            data: action.payload.data,
            isLoading: action.payload.isLoading
        }
      };
    case SurveysConstants.Accion.Survey.GET_SURVEY_COPY_BY_ID:
      return {
        ...state,
        surveyCopy: {
            data: action.payload.data,
            isLoading: action.payload.isLoading
        }
      };
    case SurveysConstants.Accion.Survey.GET_SURVEY_BY_GUID:
      return {
        ...state,
        survey: {
            data: action.payload.data,
            isLoading: action.payload.isLoading
        }
      };
    case SurveysConstants.Accion.Survey.SAVE_SURVEY:
      return {
        ...state,
        survey: {
            isSaving: action.payload.isSaving,
            isSaved: action.payload.isSaved
        }
      };

    case SurveysConstants.Accion.Survey.DELETE_SURVEY:
      return {
        ...state,
        survey: {
            isDeleted: action.payload.isDeleted
        },
        surveys: {
          ...state.surveys,
          data: state.surveys.data.filter(survey => survey.id !== action.payload.id)
        }
      };
    case SurveysConstants.Accion.Survey.DELETE_SURVEY_RECIPIENT:
      return {
        ...state,
        // survey: {
        //     isDeleted: action.payload.isDeleted
        // },
        // surveys: {
        //   ...state.surveys,
        //   data: state.surveys.data.filter(survey => survey.id !== action.payload.id)
        // },
        surveyAnswers: {
          // isDeleting: action.payload.isDeleting
          ...state.surveyAnswers,
          isDeleted: action.payload.isDeleted
        }
      };

    case SurveysConstants.Accion.Survey.GET_DATA_MASTER:
      return {
        ...state,
        dataMaster: {
            areas: action.payload.areas,
            categories: action.payload.categories,
            permissionTypes: action.payload.permissionTypes,
            permissions: action.payload.permissions,
            positions: action.payload.positions,
            questionTypes: action.payload.questionTypes,
            roles: action.payload.roles,
            status: action.payload.status,
            surveyTypes: action.payload.surveyTypes,
            users: action.payload.users,
            surveySectionTypes: action.payload.surveySectionTypes,
            isLoading: action.payload.isLoading,
        }
      };
    case SurveysConstants.Accion.Survey.GET_DATA_LIST_FOLDERS:
      return {
        ...state,
        listFolders: {
            data: action.payload.data,
            isLoading: action.payload.isLoading,
        }
      };
    case SurveysConstants.Accion.Survey.SET_CURRENT_PATH:
      return {
        ...state,
        currentPath: action.payload.currentPath
      };
    case SurveysConstants.Accion.Survey.SURVEY_SHARE:
      return {
        ...state,
        link: action.payload.data
      };

    case SurveysConstants.Accion.Survey.SEARCH_CONTACTS:
      return {
        ...state,
        contacts: {
            data: action.payload.data,
            isLoading: action.payload.isLoading
        }
      };
    case SurveysConstants.Accion.Survey.SHARE_SEND_BY_EMAIL:
      return {
        ...state,
        contacts: {
            ...state.contacts,
            isSent: action.payload.isSent,
            isSending: action.payload.isSending
        }
      };
    //*  Analisis de Encuesta
    case SurveysConstants.Accion.Survey.RESET_AFTER_CLOSE_MODAL_ANALYSIS:
      return {
          ...state,
          surveyDataTrend: {
            data: null,
            isLoading: false,
          },
          surveyDataTotal: {
            data: null,
            isLoading: false,
          },
          surveyQuestionInfo: {
            data: null,
            isLoading: false,
          },
          surveyDataAnswers: {
            data: null,
            isLoading: false,
          },
        };
    case SurveysConstants.Accion.Survey.GET_SURVEY_SUMMARY_BY_ID:
      return {
        ...state,
        surveySummary: {
            data: action.payload.data,
            title: action.payload.title,
            isLoading: action.payload.isLoading
        }
      };
    case SurveysConstants.Accion.Survey.GET_SURVEY_TREND_INFO_BY_ID:
      return {
        ...state,
        surveyDataTrend: {
            data: action.payload.data,
            isLoading: action.payload.isLoading
        }
      };
    case SurveysConstants.Accion.Survey.GENERATE_SURVEY_TREND_INFO_REPORT:
      return {
        ...state,
        surveyDataTrend: {
            ...state.surveyDataTrend,
            isLoading: action.payload.isLoading,
        },
      };
    case SurveysConstants.Accion.Survey.GET_SURVEY_TOTAL_BY_QUESTION:
      return {
        ...state,
        surveyDataTotal: {
            data: action.payload.data,
            isLoading: action.payload.isLoading
        }
      };
    case SurveysConstants.Accion.Survey.GENERATE_SURVEY_TOTAL_BY_QUESTION_REPORT:
      return {
        ...state,
        surveyDataTotal: {
            ...state.surveyDataTotal,
            isLoading: action.payload.isLoading,
        },
      };
    case SurveysConstants.Accion.Survey.GET_SURVEY_INFORMATION_BY_ID:
      return {
        ...state,
        surveyQuestionInfo: {
            data: action.payload.data,
            isLoading: action.payload.isLoading
        }
      };
    case SurveysConstants.Accion.Survey.GET_SURVEY_ANSWER_PER_QUESTION:
      return {
        ...state,
        surveyDataAnswers: {
            data: action.payload.data,
            isLoading: action.payload.isLoading
        }
      };
    case SurveysConstants.Accion.Survey.GENERATE_SURVEY_ANSWER_PER_QUESTION_REPORT:
        return {
            ...state,
            surveyDataAnswers: {
                ...state.surveyDataAnswers,
                isLoading: action.payload.isLoading,
            },
        };
    case SurveysConstants.Accion.Survey.CLOSE_SURVEY:
        return {
          ...state,
          survey: {
              ...state.survey,
              isClosed: action.payload.isClosed
          },
          surveys: {
            ...state.surveys,
            data: state.surveys.data.map(survey => {
              if (survey.id === action.payload.surveyId) {
                survey.statusCode = 'SQCL';
                survey.statusName = 'Cerrada';
              }
              return survey;
            }),
          }
        };
    case SurveysConstants.Accion.Survey.SET_SURVEY_STATUS:
        return {
          ...state,
          survey: {
              ...state.survey,
              isCloseStatus: action.payload.isCloseSurvey
          },
        };
    default: 
    return state;
  }

}