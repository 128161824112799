import FileSaver from "file-saver";
import { CoreConstants } from "core/commons/CoreConstants";
import { CompaniesConstants } from "features/customers/commons/CompaniesConstants";
import { CompanyServices } from "features/customers/services/CompanyServices";
import { toastAction } from "core/store/actions/ToastAction";
import { getCompanyByIdAdapter } from "features/customers/helpers/CompanyAdapter";

const setCompanies = (fields) => {
  return (dispatch) =>
    dispatch({
      type: CompaniesConstants.Accion.Companies.SEARCH_COMPANIES,
      payload: { ...fields },
    });
};

const getCompanies = (fields) => {
  return (dispatch) => {
    dispatch(setCompanies({ loading: true }));
    CompanyServices.searchCompany(fields)
      .then(({ data }) => {
        const status = data?.status ?? "";
        if (status === CoreConstants.HttpResponse.OK) {
          const { results, ...rest } = data.data;
          let currentFields = {
            loading: false,
            data: results,
            pagination: { ...rest },
          };
          if (fields?.fields?.order) {
            currentFields.order = fields.fields.order;
          }
          if (fields?.fields?.columnOrder) {
            currentFields.columnOrder = fields.fields.columnOrder;
          }
          dispatch(setCompanies(currentFields));
          return;
        }else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Empresas", data.message));
        }else{
          dispatch(toastAction.error("Empresas", data.message));
        }
      })
      .catch((error)=>{
      })
      .finally(() => {
        dispatch(setCompanies({ loading: false }));
      });
  };
};

const setAdvancedFilter = (payload) => {
  return (dispatch) => {
    dispatch({
      type: CompaniesConstants.Accion.Companies.ADVANCED_FILTER,
      payload,
    });
  };
};

const getFilterValues = (newValues) => {
  const newFilterValues = {};
  newValues.forEach((val) => {
    const field = val.field.toLocaleLowerCase();
    if (field.includes("date")) newFilterValues[val.field] = val.date;
    else if (field.includes("id")) newFilterValues[val.field] = val.option.id;
    else newFilterValues[val.field] = val.value;
  });
  return newFilterValues;
};

const removeFilterValues = (removeField) => {
  return (dispatch, getState) => {
    let { values } = getState().companiesReducer.advancedFilter;
    const newValues = values.filter(({ field }) => field !== removeField);

    const fields = getFilterValues(newValues);
    dispatch(getCompanies({ change: true, fields }));
    dispatch(setAdvancedFilter({ values: newValues }));
  };
};

const setGetDataMaster = (payload) => {
  return (dispatch) => {
    dispatch({
      type: CompaniesConstants.Accion.Companies.DATA_MASTER,
      payload,
    });
  };
};

const getDataMaster = () => {
  return (dispatch) => {
    dispatch(setGetDataMaster({ loading: true }));
    CompanyServices.getDataMaster()
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          const dataMaster = {
            ...data.data,
            // dynamicFields: [
            //   {
            //     id: 3,
            //     identifier: "3_Nombre",
            //     sectionName: "Datos Generales",
            //     label: "Nombre",
            //     isRequired: false,
            //     fieldTypeCode: "TXT",
            //     maxLength: 70,
            //   },
            //   {
            //     id: 7,
            //     identifier: "7_Descripcion",
            //     sectionName: "Información de Contacto",
            //     label: "Descripción",
            //     isRequired: true,
            //     fieldTypeCode: "TRH",
            //     maxLength: 100,
            //   },
            // ]
          }
          dispatch(setGetDataMaster({ data: dataMaster }));
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setGetDataMaster({ loading: false }));
      });
  };
};

const currentAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: CompaniesConstants.Accion.Companies.CURRENT_ACTION,
      payload,
    });
  };
};
const setCurrentCompany = (payload) => {
  return (dispatch) => {
    dispatch({
      type: CompaniesConstants.Accion.Companies.EDIT_COMPANY,
      payload,
    });
  };
};

const editCompany = (id) => {
  return (dispatch) => {
    dispatch(setCurrentCompany({ loading: true }));
    CompanyServices.getCompanyById(id)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          const company = getCompanyByIdAdapter(data.data.result)
          Object.entries(company).forEach(([key, value]) => {
            if (value !== null) {
              if (key === "RegistrationDate") {
                let date = new Date(value);
                company[key] = date;
              }
            }
          });
          company.dynamicFieldValues = data.data.dynamicData
          dispatch(setCurrentCompany({ data: company }));
        }else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Empresas", data.message));
        }else{
          dispatch(toastAction.error("Empresas", data.message));
        }
      })
      .catch((error)=>{
      })
      .finally(() => {
        dispatch(setCurrentCompany({ loading: false }));
      });
  };
};

const reportStatus = (payload) => {
  return (dispatch) => {
    dispatch({
      type: CompaniesConstants.Accion.Companies.REPORT_STATUS,
      payload,
    });
  };
};

const generateReport = () => {
  return (dispatch, getState) => {
    dispatch(reportStatus(true));
    let { values } = getState().companiesReducer.advancedFilter;
    const fieldValues = getFilterValues(values);
    CompanyServices.generateReport(fieldValues)
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        FileSaver.saveAs(blob, "Empresas");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(reportStatus(false));
      });
  };
};

const saveCompany = ({ company, isNewCompany }) => {
  return (dispatch) => {
    const currentLoading = { isLoadingCompany: true };

    dispatch(setCurrentCompany(currentLoading));
    CompanyServices.saveCompany(company)
      .then(({ data }) => {
        const status = data.status;
        if (data.status === CoreConstants.HttpResponse.OK) {
          const message = isNewCompany
            ? "¡Registro exitoso!"
            : "¡Edición exitosa!";
          dispatch(toastAction.success("Empresas", message));
          dispatch(setCurrentCompany({ errors: [] }));
        } else if (
          data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS &&
          data.data.length > 0
        ) {
          dispatch(toastAction.warn("Empresas", data.message));
          dispatch(setCurrentCompany({ errors: data.data }));
        }else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.error("Empresas", data.message));
        }
        dispatch(setCurrentCompany({ status }));
      })
      .catch((error)=>{
        dispatch(setCurrentCompany({ status: 2 }));
      })
      .finally(() => {
        currentLoading.isLoadingCompany = false;
        dispatch(setCurrentCompany(currentLoading));
      });
  };
};

const deleteCompany = (id) => {
  return (dispatch) => {
    CompanyServices.deleteCompany(id)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success("Empresas", "Empresa Eliminada"));
          dispatch(getCompanies({ change: false }));
        }else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Empresas", data.message));
        }else{
          dispatch(toastAction.error("Empresas", data.message));
        }
      })
      .catch((error)=>{
      });
  };
};

const downloadTemplate = () => {

  return (dispatch) => {
    dispatch(setGetDataMaster({ loadingDataTemplate: true }));
    CompanyServices.downloadTemplate().then(({data}) =>{
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      });
      FileSaver.saveAs(blob, 'Companias.xlsx');
      dispatch(setGetDataMaster({ loadingDataTemplate: false }));
      return;
    }).catch((err) =>{
      dispatch(setGetDataMaster({ loadingDataTemplate: false }));
    });
  }
}

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: CompaniesConstants.Accion.Companies.RESET_AFTER_SAVED });
};

const uploadBatchInfo = (file) => {

  const setUpload = (isLoading, data, errors) =>{
    return (dispatch) =>
      dispatch({
        type: CompaniesConstants.Accion.Companies.UPLOAD_BATCH_INFO,
        payload: isLoading, data, errors
      });
  }

  return (dispatch) => {
    dispatch(setUpload({ isLoading: true, data: null, errors: [] }));
    CompanyServices.uploadBatchInfo(file)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {

          dispatch(setUpload({ 
            isLoading: false, 
            data: data.data.pageCompanies,
            errors: data.data.errors,
          }));

          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn('Empresa', data.message));
          dispatch(setUpload({ isLoading: false, data: null }));
        }else{
        dispatch(setUpload({ isLoading: false, data: null, errors: [] }));
        }
      })
      .catch((error)=>{
        dispatch(setUpload({ isLoading: false, data: null, errors: [] }));
      });
  };
}

const saveCompaniesBulk = (payload) => {
  const setSave = (isSaved) =>{
    return (dispatch) =>
      dispatch({
        type: CompaniesConstants.Accion.Companies.SAVE_COMPANY_BULK,
        payload: isSaved
      });
  }
  return (dispatch) => {
    dispatch(setSave({ isSaved: false }));
    CompanyServices.batchSave(payload)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setSave({ isSaved: true }));
          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.success('Empresa', data.message));
          dispatch(setSave({ isSaved: false }));
        }else{
        dispatch(setSave({ isSaved: false }));
        }
      })
      .catch((error)=>{
        dispatch(setSave({ isSaved: false }));
      });
  };
}

export const CompaniesAction = {
  setAdvancedFilter,
  getCompanies,
  getDataMaster,
  currentAction,
  editCompany,
  generateReport,
  deleteCompany,
  saveCompany,
  setCurrentCompany,
  removeFilterValues,
  downloadTemplate,
  resetAfterSaved,
  uploadBatchInfo,
  saveCompaniesBulk,
};
