import { ProjectsConstants } from 'features/projects/commons/ProjectsConstants';
import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from 'core/store/actions/ToastAction';
import moment from 'moment';
import FileSaver from 'file-saver';
import { PaymentProjectService } from 'features/projects/services/PaymentProjectService';

const search = () => {
  const setPayment = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ProjectsConstants.Accion.PaymentProjects.SEARCH,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setPayment({
      dispatch: dispatch,
      isLoading: true,
      data: null,
    });

    const criteria = {};
    // const advancedFilter = getState().paymentProjectReducer.advancedFilter;
    const filter = getState().paymentProjectReducer.advancedFilter.filter;

    // criteria.columnOrder = advancedFilter.columnOrder;
    // criteria.order = advancedFilter.order;
    // criteria.page = advancedFilter.page;
    // criteria.pageSize = advancedFilter.pageSize;

    criteria.to = filter.to?filter.to:null;
    criteria.from = filter.from?filter.from:null;
    criteria.projectName = filter.projectName?filter.projectName:null;
    criteria.customerName = filter.customerName?filter.customerName:null;
    PaymentProjectService.search(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK){
        setPayment({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        }else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setPayment({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Pagos del Proyecto', data.message));
        }else{
          setPayment({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Pagos del Proyecto', data.message));
        }
      },
      () => {
        setPayment({ dispatch: dispatch, isLoading: false, data: null });
      }
    ).catch((error)=>{
    });
  };
};

const savePaymentProject = (criteria) => {
  const isLoading = ({ dispatch, isSaving, isSaved }) => {
    dispatch({
      type: ProjectsConstants.Accion.PaymentProjects.SAVE_PAYMENT_PROJECT,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch) => {
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
    })
    PaymentProjectService.savePaymentProject(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Pago de Proyecto', 'El pago se guardó correctamente.'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Pagos del Proyecto', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }else{
          dispatch(toastAction.error('Pagos del Proyecto', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
        }
      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: ProjectsConstants.Accion.PaymentProjects.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload;

    if (advancedFilter.filter.sourceType)
      pines.push({ field: 'sourceType', value: advancedFilter.filter.sourceType });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.customerName)
      pines.push({
        field: 'customerName',
        value: advancedFilter.filter.customerName,
      });

    if (advancedFilter.filter.projectName)
      pines.push({
        field: 'projectName',
        value: advancedFilter.filter.projectName,
      });


    advancedFilter.values = pines;
    dispatch({
      type: ProjectsConstants.Accion.PaymentProjects.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const getCoursesAndPayment = () => {
  const setUsers = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ProjectsConstants.Accion.PaymentProjects.GET_COURSES_AND_PAYMENT,
      payload: { isLoading, data },
    });
  };
  return (dispatch) => {
    setUsers({
      dispatch: dispatch,
      isLoading: true,
      data: null,
    });
    PaymentProjectService.getCoursesAndPayment().then(
      ({data}) => {
        if (data.status === CoreConstants.HttpResponse.OK){
          setUsers({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        }else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Error", data.message))
          setUsers({ dispatch: dispatch, isLoading: false, data: null });
        }
        else {
          dispatch(toastAction.error("Error", data.message))
          setUsers({ dispatch: dispatch, isLoading: false, data: null });
        }
      },
      () => {
        setUsers({ dispatch: dispatch, isLoading: false, data: null });
      }
    ).catch((error)=>{
    });
  }
}


const generateReport = (payload) => {
  const setPayment = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ProjectsConstants.Accion.PaymentProjects.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setPayment({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().paymentProjectReducer.advancedFilter.filter;

    criteria.to = filter.to ? filter.to : null;
    criteria.from = filter.from ? filter.from : null;
    criteria.projectName = filter.projectName?filter.projectName:null;
    criteria.customerName = filter.customerName?filter.customerName:null;

    PaymentProjectService.generateReport(criteria)
      .then(({ data }) => {
        setPayment({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Proyectos.xls');
      })
      .catch((err) => {
        setPayment({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Pago de Proyectos', 'Hubo un error al descargar el archivo'));
      });
  };
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: ProjectsConstants.Accion.PaymentProjects.RESET_AFTER_SAVED });
};

const deletedPayment = (id) => {
  return (dispatch) => {

    PaymentProjectService.deletedPayment(id).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Pago de Proyecto', 'El Pago del Proyecto se eliminó correctamente'));
          dispatch({ type: ProjectsConstants.Accion.PaymentProjects.DELETE_PAYMENT, payload: { isDeleted: true } });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Pagos del Proyecto', data.message));
          dispatch({ type: ProjectsConstants.Accion.PaymentProjects.DELETE_PAYMENT, payload: { isDeleted: false } });
        } else{
          dispatch(toastAction.warn('Pagos del Proyecto', data.message));
          dispatch({ type: ProjectsConstants.Accion.PaymentProjects.DELETE_PAYMENT, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: ProjectsConstants.Accion.PaymentProjects.DELETE_PAYMENT, payload: { isDeleted: false } });
      }
    ).catch((error)=>{
    });
  };
}

const getPaymentById = (Id) => {
  const setPayment = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: ProjectsConstants.Accion.PaymentProjects.GET_COURSE_PAYMENT_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch) => {
    setPayment({ dispatch: dispatch, isLoading: true });
    PaymentProjectService.getPaymentById(Id).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setPayment({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setPayment({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Pagos del Proyecto', data.message));
        }else{
          setPayment({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.error('Pagos del Proyecto', data.message));
        }
      },
      () => {
        setPayment({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};

export const PaymentProjectAction = {
  search,
  updateFilter,
  savePaymentProject,
  toggleAdvancedFilter,
  generateReport,
  getCoursesAndPayment,
  resetAfterSaved,
  getPaymentById,
  deletedPayment
};