import { Checkbox } from "primereact/checkbox"
import { Controller } from "react-hook-form"
import { HeaderSheet } from "./HeaderSheet"

export const CheckBoxSheet = ({question, control, numberQuestion, readOnly}) => {
  return(
    <div className='py-4 px-4 bg-gray-100 shadow border-2 flex flex-col page-break' key={question.id}>
      <HeaderSheet numberQuestion={numberQuestion} question={question}/>
      <Controller
        control={control}
        rules={{ required: {
          value: question.isRequired,
          message: `La pregunta ${numberQuestion} es Requerida`
        }}}
        render={({
            field: { onChange, value },
            fieldState: { error },
        }) => {
          return(
          <div className='w-full flex flex-col items-start gap-4'>
            {
              question?.options.map((option) => (
                  <div key={option.id} className='flex justify-center items-center gap-x-2'>
                    <Checkbox
                      readOnly={readOnly}
                      inputId={option.id} 
                      value={option} 
                      onChange={(e) => {
                        const prevValue = value || []
                        const newValue = e.value
                        const checked = e.checked
                        if(checked) onChange([...prevValue, newValue])
                        else onChange(prevValue.filter( value => value.id !== newValue.id))
                      }}
                      checked={value?.some((item) => item.id === option.id)} 
                      className={`${error ? 'p-invalid' : ''}`}
                    />
                    <p>{option.name}</p>
                  </div>
                )
              )
            }
          </div>
          )}}
        name={`question_${question.id}`}
      />
    </div>
  )
}