import { StudentsConstants } from "features/customers/commons/StudentsConstants";

const initialState = {
  students: {
    data: [],
    loading: false,
    order: "asc",
    pagination: {},
    columnOrder: "documentNumber",
  },
  actions: {
    loading: false,
    items: [
      { name: "Editar", icon: "pi pi-pencil" },
      { name: "Eliminar", icon: "pi pi-trash" },
    ],
  },
  advancedFilter: {
    showModal: false,
    values: [],
  },
  downloadReport: {
    loading: false,
  },
  currentAction: {
    isOpen: false,
    id: null,
  },
  editStudent: {
    isLoading: false,
    isLoadingStudent: false,
    isSave: false,
    data: {},
    status: null,
    errors: [],
  },
  bulkLoad: {
    data: null,
    isLoading: false,
  },
  bulkLoadErrors: [],
  studentBulk: {
    isSaved: false,
    isLoading: false
  },
  dataMaster: {
    isLoading: false,
    loadingDataManager: true,
    loadingDataTemplate: true,
    data: {
      businessLines: [],
      professionalSectors: [],
      professionalProfiles: [],
      countries: [],
      departments: [],
      cities: [],
      districts: [],
      documentTypes: [],
      professionalDegrees: [],
      academicInstitutions: [],
      positions: [],
      professions: [],
      employers: [],
      socialNetworks: [],
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudentsConstants.Accion.Students.ADVANCED_FILTER:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload,
        },
      };
    case StudentsConstants.Accion.Students.SAVE_STUDENTS_BULK:
        return {
          ...state,
          studentBulk: {
              isSaved: action.payload.isSaved,
              isLoading: action.payload.isLoading
          }
        };
    case StudentsConstants.Accion.Students.UPLOAD_BATCH_INFO:
        return {
          ...state,
          bulkLoad: {
            data: action.payload.data,
            isLoading: action.payload.isLoading,
          },
          bulkLoadErrors: action.payload.errors,
        };
    case StudentsConstants.Accion.Students.RESET_AFTER_SAVED:
        return {
          ...state,
          bulkLoad: {
              data: null,
              isLoading: false,
          },
          bulkLoadErrors: [],
          studentBulk: {
              isSaved: false,
          }
        };
    case StudentsConstants.Accion.Students.SEARCH_STUDENTS:
      return {
        ...state,
        students: {
          ...state.students,
          ...action.payload,
        },
      };
    case StudentsConstants.Accion.Students.DATA_MASTER:
      return {
        ...state,
        dataMaster: {
          ...state.dataMaster,
          ...action.payload,
        },
      };
    case StudentsConstants.Accion.Students.CURRENT_ACTION:
      return {
        ...state,
        currentAction: {
          ...state.currentAction,
          ...action.payload,
        },
      };
    case StudentsConstants.Accion.Students.EDIT_STUDENT:
      return {
        ...state,
        editStudent: {
          ...state.editStudent,
          ...action.payload,
        },
      };
    case StudentsConstants.Accion.Students.REPORT_STATUS:
      return {
        ...state,
        downloadReport: {
          loading: action.payload,
        },
      };
    default:
      return state;
  }
};
