import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

export const useClaimProcessManagment = () => {

  const menu = useSelector((state) => state.authReducer.user.menuPrincipal);
  const pathName = useLocation()?.pathname;
  const [isDownload, setDownload] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isCreate, setCreate] = useState(false);
  const [isView, setView] = useState(false);

  //CUSTOM
  const [isGenerateVersion, setGenerateVersion] = useState(false)
  const [isGenerateVersionProcess, setGenerateVersionProcess] = useState(false)
  const [isSheet, setSheet] = useState(false)

  useEffect(() => {
    download();
    remove();
    edit();
    create();
    generateVersion();
    generateVersionProcess();
    view();
    sheet();
  }, [menu]);

  const download = () => {
    const actionCode = 'DOWNLOAD';
    const optionCode = "MAPR";
    setDownload(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };
  const remove = () => {
    const actionCode = 'DEL';
    const optionCode = "MAPR";
    setDelete(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  const edit = () => {
    const actionCode = 'EDIT';
    const optionCode = "MAPR";
    setEdit(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
    // setEdit(false)
};

  const create = () => {
    const actionCode = 'NEW';
    const optionCode = "MAPR";
    setCreate(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
 };

  const view = () => {
    const actionCode = 'VIEW';
    const optionCode = "MAPR";
    setView(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
 };

  //CUSTOM

  const generateVersion = () => {
    const actionCode = 'VERSION';
    const optionCode = "MAPR";
    setGenerateVersion(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  const generateVersionProcess = () => {
    // const actionCode = 'GENERATE-VERSION-PROCESS';
    const actionCode = 'VERSION';
    const optionCode = "MAPR";
    setGenerateVersionProcess(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  const sheet = () => {
    const actionCode = 'SHEET';
    const optionCode = "MAPR";
    setSheet(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))));
  };

  return {
    isDownload,
    isCreate,
    isDelete,
    isEdit,
    isGenerateVersion,
    isGenerateVersionProcess,
    isView,
    isSheet
  }
}