import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from "primereact/inputtext";
import { Controller } from 'react-hook-form';
import { RiskManagementAction } from '../store/actions/RiskManagementAction';
import { InputNumber } from 'primereact/inputnumber';

const Probabilidad = ({control, getValues, valuesFields}) => {

	const { probability } = useSelector(state => state.riskManagementReducer.data)
	const dispatch = useDispatch()
		
	return (
		<div className='grid grid-cols-3 gap-2'>
			<div className='col-span-2'>
				<strong>Nombre</strong>
			</div>
			<div>
				<strong>Valor</strong>
			</div>
			{
				probability.map((item)=>
				{
					
					return (<Fragment key={item.id}>
					<div className='col-span-2'>
						<Controller 
							control={control}
							name={`likelihood-name-${item.id}`}
							rules={{
								required: 'El Nombre de Probabilidad es requerido',
								// validate: {
								// 	isNotRepeat: value => {
								// 		const values = getValues()
								// 		const likehoods = Object.keys(values).filter( key => key.slice(0, 15) === 'likelihood-name' && key !== `likelihood-name-${item.id}`).map( item => values[item])
								// 		return !likehoods.includes(value) || "No se deben repetir los nombres de las probabilidades"						
								// 		}
								// 	}
							}}
							defaultValue={item.name}
							render={({
								field: { onChange, onBlur, value, name, ref },
								fieldState: { invalid, isTouched, isDirty, error },
							}) => (
							<InputText
								ref={ref}
								value={value}
								onChange={(e)=> {dispatch(RiskManagementAction.changeNikelihoodName(e.target.value, item.id)); onChange(e)}}
								className={error && 'p-invalid'}
							/>
						)}
						/>
						
					</div>
					<div>
					<Controller 
							control={control}
							name={`likelihood-value-${item.id}`}
							rules={{
								required: 'El Valor de Probabilidad es requerido',
								validate: {
									isNotRepeat: value => {
										const values = getValues()
										const likehoodsValues = Object.keys(values).filter( key => key.slice(0, 16) === 'likelihood-value' && key !== `likelihood-value-${item.id}`).map( item => values[item])
										return !likehoodsValues.includes(value) || "No se deben repetir los valores de las probabilidades"						
										}
									}
							}}
							defaultValue={item.value}
							render={({
								field: { onChange, onBlur, value, name, ref },
								fieldState: { invalid, isTouched, isDirty, error },
						}) => (
							<InputNumber
								value={value}
								onValueChange={(e) => {dispatch(RiskManagementAction.changeNikelihoodValue(e.value, item.id)); onChange(e.value)}} 
								mode="decimal"
								locale="en-US"
								useGrouping={false}
								maxFractionDigits={(valuesFields.position && Number(valuesFields.position)) || 0} 
								className={error ? 'p-invalid w-16' : 'w-16'}
							/>
						)}
						/>
					</div>
					</Fragment>)
				}
				)
			}
		</div>
	);
};

export default Probabilidad;