import axios from 'axios';
import AppSettings from 'core/config/appsettings';

function getHeader(params) {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.accessToken)
    return { Authorization: 'Bearer ' + user.accessToken };
  return {};
}

function Get(path,blob=false) {
  let API_URL = AppSettings.BASE_API_URL;
  // if(path.startsWith("customer/api/BusinessSheet")){
  //   API_URL = "https://localhost:5001/"
  //   path = path.replace("customer/","")
  // }
  if(!blob)
    return axios.get(API_URL + path, { headers: this.getHeader }) ;
  else
    return axios.get(API_URL + path, { headers: this.getHeader,responseType:'blob'}) ;
}

function Delete(path) {
  let API_URL = AppSettings.BASE_API_URL;
  //   if(path.startsWith("customer/api/BusinessSheet")){
  //   API_URL = "https://localhost:5001/"
  //   path = path.replace("customer/","")
  // }
  return axios.delete(API_URL + path, { headers: this.getHeader });
}

function Post(path, data, newOptions = {}) {
  let API_URL = AppSettings.BASE_API_URL;
  // if(path.startsWith("customer/api/BusinessSheet")){
  //   API_URL = "https://localhost:5001/"
  //   path = path.replace("customer/","")
  // }
  let options = {
    headers: this.getHeader,
  };

  if (Object.values(newOptions).length > 0)
    options = { ...options, ...newOptions };

  return axios.post(API_URL + path, data, options);
}

export const apiService = {
  Get,
  Post,
  Delete,
};
