import { useRef } from "react";
import { Editor } from "primereact/editor";
import { Menu } from "primereact/menu";
import { Panel } from "primereact/panel";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { ConfirmModal } from "shared/components/confirmModal/ConfirmModal";

export const BusinessSheetSection = ({
  section,
  children,
  onChangeDescriptionSection,
  index,
  deleteSection,
  removeSection,
}) => {
  const confirmRef = useRef(null);
  const menu = useRef(null);
  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
      </span>
    );
  };
  const header = renderHeader();
  const getListStyleOptions = (isDraggingOver) => ({
    border: isDraggingOver ? "1px dashed rgb(75 85 99)" : "none",
    margin: "0 0 0 0",
  });
  const items = [
    {
      label: "Eliminar Sección",
      icon: "pi pi-trash",
      command: (e) => {
        confirmRef.current.openModal({
          header: "Eliminar",
          message: "¿Está seguro que desea eliminar la sección?",
          icon: "pi pi-trash",
          onConfirm: () => {
            deleteSection(section.id);
          },
        });
      },
    },
    {
      label: "Remover Sección",
      icon: "pi pi-times",
      command: (e) => {
        removeSection(section.id);
      },
    },
  ];
  const headerTemplate = (draggableProvided) => (options) => {
    const className = `${options.className} justify-content-space-between`;

    return (
      <div className={className} {...draggableProvided.dragHandleProps}>
        <h2 className="font-semibold">Sección</h2>
        <div>
          <Menu model={items} popup ref={menu} id="popup_menu" />
          <button type="button">
            <i
              className={`pi pi-ellipsis-v block mr-1 cursor-pointer hover:text-gray-900`}
              onClick={(event) => {
                menu.current.toggle(event);
              }}
            ></i>
          </button>
          {options.togglerElement}
        </div>
      </div>
    );
  };
  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    userSelect: "none",
    background: isDragging ? "rgba(239, 246, 255)" : "none",
  });
  return (
    <Draggable draggableId={`section_${section.id}`} index={index}>
      {(draggableProvided, snapshot) => (
        <div
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            draggableProvided.draggableProps.style
          )}
          className="flex flex-col"
        >
          <Panel headerTemplate={headerTemplate(draggableProvided)} toggleable>
            <Editor
              headerTemplate={header}
              className="w-full mb-2"
              onTextChange={(e) =>
                onChangeDescriptionSection({ value: e.htmlValue, id: section.id })
              }
              value={section?.description}
              placeholder="Texto de la sección"
            />
            <Droppable
              droppableId={`section_${section.id}`}
              type={`section_${section.id}`}
              direction="vertical"
              // isDropDisabled={question.type !== ""}
            >
              {(droppableProvided, snapshot) => (
                <div
                  ref={droppableProvided.innerRef}
                  style={getListStyleOptions(snapshot.isDraggingOver)}
                  {...droppableProvided.droppableProps}
                  className="grid grid-cols-1 gap-y-2 md:gap-2 items-center w-full border border-black"
                >
                  {children}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </Panel>
          <ConfirmModal ref={confirmRef} />
        </div>
      )}
    </Draggable>
  );
};
