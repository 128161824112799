import { InputTextarea } from "primereact/inputtextarea"
import { Controller } from "react-hook-form"
import { HeaderSheet } from "./HeaderSheet"

export const InputTextSheet = ({question, control, numberQuestion, readOnly}) => {
  return(
    <div className='py-4 px-4 bg-gray-100 shadow border-2 flex flex-col page-break' key={question.id}>
      <HeaderSheet numberQuestion={numberQuestion} question={question}/>
      <Controller
        control={control}
        rules={{ required: {
          value: question.isRequired,
          message: `La pregunta ${numberQuestion} es Requerida`
        }}}
        render={({
            field: { onChange, value },
            fieldState: { error },
        }) => (
          <div className='w-full flex flex-col items-start gap-1'>
            <InputTextarea
              readOnly={readOnly}
              value={value || ""} 
              onChange={onChange} 
              rows={1}
              cols={30}
              autoResize
              className={`w-full ${error ? 'p-invalid' : ''}`}
            />
          </div>
          )}
        name={`question_${question.id}`}
      />
    </div>
  )
}