import { DinamicProcessAction } from 'features/configuration/store/actions/DinamicProcessAction';
import { useSelector, useDispatch } from 'react-redux';
import ModalFilterComponent from 'shared/components/modal-filter/ModalFilterComponent';
import { Calendar } from 'primereact/calendar';
import { useEffect, useState } from 'react';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { useParams } from 'react-router-dom';

const AdvancedFilterComponent = () => {
    const dispatch = useDispatch();
    const params = useParams()
    const processSelected = useSelector(state => state.dinamicProcessReducer.process.data)
    const advancedFilter = useSelector((state) => state.dinamicProcessReducer.advancedFilter);

    const processSections = useSelector(state => state.dinamicProcessReducer.process.data?.sections)
    const listControls = useSelector(state => state.dinamicProcessReducer.listControls.data)

    const [formValues, setValues] = useState(null);
    const [itemsModal, setItemsModal] = useState(null)

    // console.log(listControls)
    // console.log(processSelected)
    // console.log(formValues)
    // console.log(itemsModal)

    useEffect(() => {
      if(processSelected && listControls && advancedFilter){
        let valuesFilters = {}
        if(!formValues){
          processSelected?.configSearch?.advacedFilters?.map(el => {
            valuesFilters[el] = null
            return el
          })
        }else{
          valuesFilters = advancedFilter.filter
        }

        const valuesModal = {}
        processSections.map(elem=> {
          elem.controls.map(item => {
            if(processSelected?.configSearch.advacedFilters.includes(item.identifier)){
              valuesModal[item.identifier] = {
                type: (listControls.find(el => el.id===item.controlId)).code,
                name: item.text,
                details: item.details
              }
            }
            return item
          })
          return elem
        })
        setItemsModal(valuesModal)
        setValues(valuesFilters);
      }
    }, [advancedFilter, processSelected, listControls]);


    const clearFields = () => {
      if(processSelected?.configSearch.advacedFilters){
        const valuesFilters = {}
        processSelected?.configSearch.advacedFilters?.map(el => {
          valuesFilters[el] = null
          return el
        })
        setValues(valuesFilters);
      }
    };

    const closeModal = () => {
        dispatch(DinamicProcessAction.toggleAdvancedFilter({ showModal: false }));
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        const fields = formValues;

        processSelected?.configSearch.advacedFilters.map(elem=> {
          if(fields[elem]){
            if(typeof fields[elem]==="string"){
              advancedFilter.filter[elem] = fields[elem].trim()
            }else{
              advancedFilter.filter[elem] = fields[elem]
            }
          }
          return elem
        })
        dispatch(DinamicProcessAction.updateFilter(advancedFilter));
        dispatch(DinamicProcessAction.searchDynamicProcess(params.id));
        dispatch(DinamicProcessAction.toggleAdvancedFilter({ showModal: false }));
    }

    // const onChangeFrom = (e) => {
    //     if (e.value)
    //         formValues.to = null;
    //     setValues({
    //         ...formValues,
    //         [e.target.name]: e.value,
    //     });
    // }

    // const onChangeTo = (e) => {
    //     setValues({
    //         ...formValues,
    //         [e.target.name]: e.value,
    //     });
    // }

    // console.log(processSelected)


    const onChangeText = (e) => {
      setValues({...formValues, [e.target.name]: e.target.value})
    }

    const onDropDownChange = (e) => {
      setValues({...formValues, [e.target.name]: {id: e.target.value.id, text: e.value.text}})
    }

    const onChangeDate = (e) => {
      setValues({...formValues, [e.target.name]: e.value})
    }

    const onChangeSelectionSimple = (e) => {
      setValues({...formValues, [e.target.name]: {id: e.target.id, text: e.value}})
    }

    const onChangeMultiple = (e) => {
      if(e.checked){
        if(!formValues[e.target.name]){
          setValues({...formValues, [e.target.name]: [e.target]})
          // setValues({...formValues, [e.target.name]: [{id: e.target.id, type: e.target.type, text: e.value}]})
        }else{
          setValues({...formValues, [e.target.name]: [...formValues[e.target.name], e.target]})
        }
      }else{
        if(formValues[e.target.name]){
          setValues({...formValues, [e.target.name]: formValues[e.target.name].filter(el=> el.id !== e.target.id) })
        }
      }
    }

    if (advancedFilter.showModal)
        return (
            <ModalFilterComponent
                disabledBtn={false}
                closeModal={closeModal}
                clearFields={clearFields}
                handleSubmit={handleSubmit}
                searchLoading={advancedFilter?.loading}
            >
                <div>
                  {
                    processSelected?.configSearch.advacedFilters?processSelected?.configSearch.advacedFilters.map((elem, index)=> {
                      return <div key={index}>
                        <div className='mt-2'>
                          <p className='text-sm'>{itemsModal[elem]?.name}</p>
                        {
                          itemsModal[elem]?.type==="TEXTO"&&<InputText value={formValues[elem] ? formValues[elem] : ""} name={elem} onChange={onChangeText}/>
                        }
                        {
                          itemsModal[elem]?.type==="LISTA_DESPLEGABLE"&&<Dropdown options={itemsModal[elem].details} optionLabel="text" name={elem} value={itemsModal[elem].details.find(el => el.id===formValues[elem]?.id)} onChange={onDropDownChange} className="w-full"/>
                        }
                        {
                          (itemsModal[elem]?.type==="FECHA"||itemsModal[elem]?.type==="FECHA_HORA")&&<Calendar showTime={itemsModal[elem].type==="FECHA_HORA"} value={formValues[elem]} name={elem} onChange={onChangeDate} className="w-full"/>
                        }
                        {
                          itemsModal[elem]?.type==="SELECCION_SIMPLE"&&<>
                          {
                            itemsModal[elem].details.map(el=> {
                              return (
                                <div key={el.id} className="field-radiobutton">
                                <RadioButton inputId={el.id} id={el.id} checked={formValues[elem]?.id===el.id} value={el.text} name={elem} onChange={onChangeSelectionSimple} className="mr-2"/>
                                  <label htmlFor={el.key}>{el.text}</label>
                                </div>
                              )
                            })
                          }
                          </>
                        }
                        {/* {
                          itemsModal[elem].type==="SELECCION_MULTIPLE"&&<MultiSelect value={formValues[elem]} options={itemsModal[elem].details} name={elem} onChange={onChangeMultiple} optionLabel="text" placeholder="Selección Múltiple" maxSelectedLabels={3} />
                        } */}
                        {
                          itemsModal[elem]?.type==="SELECCION_MULTIPLE"&&<>
                          {
                            itemsModal[elem].details.map(el=> {
                              return (
                                <div key={el.id} className="p-field-checkbox">
                                <Checkbox 
                                  inputId={el.id} id={el.id} 
                                  name={elem} value={el.text} 
                                  // checked={formValues[elem]?.includes(el.id)} 
                                  checked={formValues[elem]?.some(option => option.id === el.id)} 
                                  onChange={onChangeMultiple} 
                                  className="mr-2"
                                />
                                <label htmlFor={el.id}>{el.text}</label>
                              </div>
                              )
                            })
                          }
                          </>
                        }
                        {
                          itemsModal[elem]?.type==="USUARIOS"&&<Dropdown options={itemsModal[elem].details} optionLabel="text" name={elem} value={itemsModal[elem].details.find(el => el.id===formValues[elem]?.id)} onChange={onDropDownChange} className="w-full"/>
                        }
                        </div>
                      </div>
                    }):<Skeleton height="5rem" />
                  }
                </div>
                {/* <div className="flex gap-4 pt-3">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                value={formValues.from}
                                name="from"
                                id="desde"
                                onChange={(e) => onChangeFrom(e)}
                            />
                            <label htmlFor="desde">Desde</label>
                        </span>
                    </div>
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                value={formValues.to}
                                name="to"
                                id="hasta"
                                minDate={formValues?.from}
                                onChange={(e) => onChangeTo(e)}
                            />
                            <label htmlFor="hasta">Hasta</label>
                        </span>
                    </div>

                </div> */}

            </ModalFilterComponent>
        );
    else
        return null;
}

export default AdvancedFilterComponent;

