import { ProjectServices } from "features/projects/services/ProjectServices";
import { TaskService } from "features/customers/services/TaskService";
import { ProjectsConstants } from "features/projects/commons/ProjectsConstants";
import { toastAction } from "core/store/actions/ToastAction";
import { CoreConstants } from "core/commons/CoreConstants";
import moment from "moment";
import FileSaver from "file-saver";

const setDataMaster = (infoData) => {
  return (dispatch) =>
    dispatch({
      type: ProjectsConstants.Accion.Projects.DATA_MASTER,
      payload: infoData,
    });
};

const setProjects = (fields) => {
  return (dispatch) =>
    dispatch({
      type: ProjectsConstants.Accion.Projects.SEARCH_PROJECTS,
      payload: { ...fields },
    });
};

const setTaskEdit = (info) => {
  return (dispatch) =>
    dispatch({
      type: ProjectsConstants.Accion.Projects.EDIT_TASKS,
      payload: info,
    });
};

const getFilterValues = (fields) => {
  let fieldsTemp = {};
  fields.forEach((field) => {
    if (field.date) {
      fieldsTemp[field.field] = moment.parseZone(field.date).utc().format();
    } else if (field.field === "name") fieldsTemp[field.field] = field.value;
    else fieldsTemp[field.field] = parseInt(field.id);
  });
  fields = fieldsTemp;

  return {
    fields,
  };
};

const searchTable = ({ page }) => {
  return (dispatch, getState) => {
    let { values } = getState().projectsReducer.projectsFilter;
    const { fields } = getFilterValues(values);
    fields.page = page;
    dispatch(getProjects({ ...fields }));
  };
};

const getDataMaster = () => {
  return (dispatch) => {
    dispatch(setDataMaster({ loadingDataManager: true }));
    ProjectServices.getDataMaster()
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          let { status, responsibles, companyCustomers } = data.data;
          let infoData = {
            loadingDataManager: false,
            dataManager: {
              responsibles,
              companyCustomers,
              status,
            },
          };
          dispatch(setDataMaster(infoData));
          dispatch(getProjects({}));
          return;
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.success("Roles y Privilegios", data.message));
          dispatch(setDataMaster({ loadingDataManager: false }));
        }
      }).catch((error)=>{
        dispatch(setDataMaster({ loadingDataManager: false }));
      });
  };
};

const getProjects = (fields) => {
  return (dispatch) => {
    dispatch(setProjects({ loading: true }));
    ProjectServices.search(fields)
      .then(({ data }) => {
        const status = data?.status ?? "";
        if (status === CoreConstants.HttpResponse.OK) {
          const { results, ...rest } = data.data;
          results.forEach((result) => {
            if (result.realOpenDate) {
              result.realOpenDate = moment(
                result.realOpenDate.substring(0, 10)
              ).format(CoreConstants.Format.LOCALE);
              result.realCloseDate = moment(
                result.realCloseDate.substring(0, 10)
              ).format(CoreConstants.Format.LOCALE);
            }
          });
          let objFields = {
            loading: false,
            data: results,
            currentData: results,
            pagination: { ...rest },
            first: 0,
          };
          dispatch(setProjects(objFields));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.error("Roles y Privilegios", data.message));
          dispatch(setProjects({ loading: false }));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_TECHNICAL) {
          dispatch(toastAction.error("Roles y Privilegios", data.message));
          dispatch(setProjects({ loading: false }));
          return;
        }
      })
      .catch((error)=>{
        dispatch(setProjects({ loading: false }));
      });
  };
};

const getProjectById = (id) => {
  return (dispatch) => {
    ProjectServices.getProjectById(id)
      .then(({ data }) => {
        const status = data?.status ?? data?.Status;
        if (status === CoreConstants.HttpResponse.OK) {
          const project = data.data.project;
          const attachments = data.data?.attachment;
          let infoData = {
            loadingEdit: false,
            dataProject: project,
            attachments
          };
          dispatch(setTaskEdit(infoData));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.error("Roles y Privilegios", data.message));
          dispatch(setTaskEdit({ loadingEdit: false }));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_TECHNICAL) {
          dispatch(toastAction.error("Roles y Privilegios", data.message));
          dispatch(setTaskEdit({ loadingEdit: false }));
          return;
        }
      })
      .catch((error)=>{
        dispatch(setTaskEdit({ loadingEdit: false }));
      });
  };
};

const deleteProjectById = (id) => {
  return (dispatch) => {
    dispatch(setProjects({ loading: true }));
    ProjectServices.deleteProjectById(id)
      .then(({ data }) => {
        const status = data?.status ?? "";
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(setProjects({ loading: false }));
          dispatch(getProjects({}));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.error("Roles y Privilegios", data.message));
          dispatch(setProjects({ loading: false }));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_TECHNICAL) {
          dispatch(toastAction.error("Roles y Privilegios", data.message));
          dispatch(setProjects({ loading: false }));
          return;
        }
      })
      .catch((error)=>{
        dispatch(setProjects({ loading: false }));
      });
  };
};

const deleteTask = (id,documentId) => {
  return (dispatch) => {
    TaskService.deleteTask(id)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success("Tareas", "Tarea Eliminada"));
          dispatch(getTasksDeliverable(documentId,1));
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn("Roles y Privilegios", data.message));
          dispatch(setProjects({ loading: false }));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_TECHNICAL) {
          dispatch(toastAction.error("Roles y Privilegios", data.message));
          dispatch(setProjects({ loading: false }));
          return;
        }


      })
      .catch((error)=>{
      });
  };
};

const generateViewReport = (id) => {
  return (dispatch) => {
    dispatch(setTaskEdit({ loadingReport: true }));
    ProjectServices.generateViewReport(id)
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        dispatch(setTaskEdit({ loadingReport: false }));
        FileSaver.saveAs(blob, "ViewProyectReport.xlsx");
      })
      .catch((error) => {
        console.error(error);
        dispatch(setTaskEdit({ loadingReport: false }));
      })
  };
};

const generateViewProjectReport = (id) => {
  return (dispatch) => {
    dispatch(setTaskEdit({ loadingReport: true }));
    ProjectServices.generateViewProjectReport(id)
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        dispatch(setTaskEdit({ loadingReport: false }));
        FileSaver.saveAs(blob, "ViewProyectReport.xlsx");
      })
      .catch((error) => {
        console.error(error);
        dispatch(setTaskEdit({ loadingReport: false }));
      })
  };
};

const getTasksDeliverable = (id,page) => {
  return (dispatch) => {
    dispatch({
      type: ProjectsConstants.Accion.Projects.GET_TASKS,
      payload: { tasks : [] , isLoadingTasks:true},
    });
    ProjectServices.getTaskDeliverable(id,page)
      .then(({ data }) => {
        let { results , rowCount , pageCount } = data.data
         dispatch({
          type: ProjectsConstants.Accion.Projects.GET_TASKS,
          payload: { tasks: results, isLoadingTasks:false , pagination:{
            rowCount,pageCount
          }},
        });
      })
      .catch((err) => {
        dispatch(setTaskEdit({ loadingEdit: false , tasks : [] }));
      });
  };
};

const generateSnapshot = ({projectId, name, showModal, closeGenerateModal}) => {

  return (dispatch) => {
    dispatch(setTaskEdit({ loadingReport: true }));
    ProjectServices.generateSnapshot({projectId: Number(projectId), name})
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch({
            type: ProjectsConstants.Accion.Projects.CREATE_SNAPSHOT,
            payload: { createId: data.data},
          })
          closeGenerateModal()
          showModal()
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.warn("Proyecto", data.message));
        } else if (status === CoreConstants.HttpResponse.ERROR_TECHNICAL) {
          dispatch(toastAction.error("Proyecto", data.message));
        }
      })
      .catch((err) => {
        
      })
      .finally(() => {
        dispatch(setTaskEdit({ loadingReport: false }));
      })
  };
};

const getSnapshotById = (snapshotId) => {
  return (dispatch) => {
    dispatch({
      type: ProjectsConstants.Accion.Projects.CREATE_SNAPSHOT,
      payload: { loading: true},
    })
    ProjectServices.getSnapshotById(snapshotId).then(({data})=> {
      const status = data?.status ?? data?.Status;
        if (status === CoreConstants.HttpResponse.OK) {
          const snapshot = data.data;
          snapshot.customer = {}
          snapshot.customer.name = snapshot.customerName
          snapshot.description = snapshot.projectDescription
          dispatch({
            type: ProjectsConstants.Accion.Projects.CREATE_SNAPSHOT,
            payload: { data: snapshot},
          })
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.error("Gestión de Proyectos", data.message));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_TECHNICAL) {
          dispatch(toastAction.error("Gestión de Proyectos", data.message));
          return;
        }
      })
      .catch((error)=>{
        dispatch(toastAction.error("Gestión de Proyectos", error.message));
      })
      .finally(()=>{
        dispatch({
          type: ProjectsConstants.Accion.Projects.CREATE_SNAPSHOT,
          payload: { loading: false},
        })
      });
  }
}

const downloadSnapshot = (snapshotId) => {
  return (dispatch) => {
    dispatch({
      type: ProjectsConstants.Accion.Projects.CREATE_SNAPSHOT,
      payload: { downloadLoading: true},
    })
    ProjectServices.downloadSnapshot({snapshotId})
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        FileSaver.saveAs(blob, "Planeamiento.xlsx");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(()=>{
        dispatch({
          type: ProjectsConstants.Accion.Projects.CREATE_SNAPSHOT,
          payload: { downloadLoading: false},
        })
      })
  };
};

const searchSnapshot = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ProjectsConstants.Accion.Projects.CREATE_SNAPSHOT,
      payload: { loading: true},
    })

    ProjectServices.searchSnapshot(payload)
      .then(({ data }) => {
        const status = data?.status ?? "";
        if (status === CoreConstants.HttpResponse.OK) {
          const { results, ...pagination } = data.data;
          dispatch({
            type: ProjectsConstants.Accion.Projects.CREATE_SNAPSHOT,
            payload: { pagination, dataTable: results},
          })

        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.error("Gestión de Proyectos", data.message));
          dispatch(setProjects({ loading: false }));
        } else if (status === CoreConstants.HttpResponse.ERROR_TECHNICAL) {
          dispatch(toastAction.error("Gestión de Proyectos", data.message));
          dispatch(setProjects({ loading: false }));
        }
      })
      .catch((error)=>{
        dispatch(toastAction.error("Gestión de Proyectos", error.message));
      })
      .finally(()=>{
        dispatch({
          type: ProjectsConstants.Accion.Projects.CREATE_SNAPSHOT,
          payload: { loading: false},
        })
      })
  }
}

const downloadSearchSnapshot = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ProjectsConstants.Accion.Projects.CREATE_SNAPSHOT,
      payload: { loading: true},
    })
    ProjectServices.downloadSearchSnapshot(payload)
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        FileSaver.saveAs(blob, "ProjectSnapshot");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(()=>{
        dispatch({
          type: ProjectsConstants.Accion.Projects.CREATE_SNAPSHOT,
          payload: { loading: false},
        })
      })
  };
}

const deleteSnapshotById = (id, payload) => {
  return (dispatch) => {
    dispatch({
      type: ProjectsConstants.Accion.Projects.CREATE_SNAPSHOT,
      payload: { loading: true},
    })
    ProjectServices.deleteSnapshotById(id)
      .then(({ data }) => {
        const status = data?.status ?? "";
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(searchSnapshot(payload))
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.error("Gestión de Proyectos", data.message));
          
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_TECHNICAL) {
          dispatch(toastAction.error("Gestión de Proyectos", data.message));
          
          return;
        }
      })
      .catch((error)=>{
        dispatch(toastAction.error("Gestión de Proyectos", error.message));
      })
      .finally(()=>{
        dispatch({
          type: ProjectsConstants.Accion.Projects.CREATE_SNAPSHOT,
          payload: { loading: false},
        })
      })
  };
};

export const ProjectsActions = {
  getTasksDeliverable,
  generateViewReport,
  generateViewProjectReport,
  getDataMaster,
  deleteTask,
  deleteProjectById,
  searchTable,
  getProjectById,
  setTaskEdit,
  generateSnapshot,
  getSnapshotById,
  downloadSnapshot,
  searchSnapshot,
  downloadSearchSnapshot,
  deleteSnapshotById,
};
