import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { Menu } from 'primereact/menu';

import useTable from 'features/configuration/hooks/roles/useTable';
import { useClaimRolesPrivileges } from 'features/configuration/hooks/useClaimRolesPrivileges';
import { Link } from 'react-router-dom';

const RolesTableComponent = () => {
  const {
    tableRoles,
    columnsGrid,
    tableActions,
    tableCurrentActions,
    showActions,
    tablePagination,
    selectColumns,
    onChangePage,
    tableLoading,
    tableRows,
    tableNum,
    setCurrentUser,
    currentUserGrid,
    setCurrentUserGrid,
    getUserRoleById,
    clearUserRoleById,
    currentUserByRol,
    usersByRole,
    tableSort,
    onSort,
  } = useTable();

  const { isView ,isEdit, isDelete } = useClaimRolesPrivileges()

  const getViewField = (rowData) => {
    if (isView && rowData.isSystem!==1)
      return <Link className="underline text-blue-700" to={{ pathname: `/configuracion/rol-privilegios/visualizar/` + rowData.roleId}} >{rowData.roleName}</Link>
    else
      return rowData.roleName;
  }

  const setIsSystem = (rowData) => {
    return rowData.isSystem ? "Si" : "No"
  }

  const ColumnComponents = selectColumns.map((col, index) => {
    // tableRoles[index]?.isSystem==="No"
    if (col.field==='roleName')
          return <Column key={col.field} header={col.header} body={getViewField} sortable />

  if (col.field==='isSystem')
        return <Column key={col.field} header={col.header} body={setIsSystem} sortable />


    return (
      <Column key={col.field} field={col.field} header={col.header} sortable />
    );
  });

  const TableHeaderActions = () => {
    return <div></div>;
  };

  const TableBodyActions = (selectedRow) => {
    if (selectedRow.isSystem === 1) return <div></div>;
    if(isEdit||isDelete){
      return (
        <i
          onClick={(event) => {
            setCurrentUser(selectedRow.roleId);
            tableCurrentActions.current.toggle(event);
          }}
          className="pi pi-ellipsis-v cursor-pointer"
        ></i>
      );
    }else{
      return <div></div>
    }
  };

  const TableGrid = () => {
    return (
      <div className="orders-subtable">
        <DataTable
          value={usersByRole}
          stripedRows
          responsiveLayout="scroll"
          loading={currentUserByRol.loading}
          emptyMessage="No se encontraron usuarios"
        >
          {columnsGrid.map(({ header, field }, index) => (
            <Column field={field} header={header} key={index}></Column>
          ))}
        </DataTable>
      </div>
    );
  };

  const onRowToggle = (user) => {
    setCurrentUserGrid(user.data);
  };

  return (
    <div className="mt-5">
      <DataTable
        value={tableRoles}
        stripedRows
        rows={10}
        rowHover
        size="small"
        expandedRows={currentUserGrid}
        onRowToggle={onRowToggle}
        onRowExpand={getUserRoleById}
        onRowCollapse={clearUserRoleById}
        responsiveLayout="scroll"
        rowExpansionTemplate={TableGrid}
        onSort={onSort}
        first={tableSort?.first}
        sortField={tableSort?.sortField}
        sortOrder={tableSort?.sortOrder}
        filters={tableSort?.filters}
        emptyMessage="No se encontraron roles"
      >
        <Column expander style={{ width: '2em' }} />
        {ColumnComponents}
        {showActions && (
          <Column header={TableHeaderActions} body={TableBodyActions}></Column>
        )}
      </DataTable>
      {!tableLoading && tableRoles.length > 0 && (
        <Paginator
          className="paginator-custom mt-4"
          first={tableNum}
          rows={tableRows}
          totalRecords={tablePagination.rowCount}
          rowsPerPageOptions={[5, 10, 15]}
          onPageChange={onChangePage}
        ></Paginator>
      )}

      <Menu
        model={tableActions}
        popup
        ref={tableCurrentActions}
        id="popup_menu"
      />
    </div>
  );
};

export default RolesTableComponent;
