import FileSaver from "file-saver";
import { PositionsServices } from "features/rrhh/services/PositionsServices";
import { RrhhConstants } from "features/rrhh/commons/RrhhConstants";
import { CoreConstants } from "core/commons/CoreConstants";
import { toastAction } from "core/store/actions/ToastAction";

const setPositions = (fields) => {
  return (dispatch) =>
    dispatch({
      type: RrhhConstants.Accion.Positions.SEARCH_POSITIONS,
      payload: { ...fields },
    });
};

const getPositions = (fields) => {
  return (dispatch) => {
    dispatch(setPositions({ loading: true }));
    PositionsServices.searchPositions(fields)
      .then(({ data }) => {
        const status = data?.status ?? "";
        if (status === CoreConstants.HttpResponse.OK) {
          const { results, ...rest } = data.data;
          let currentFields = {
            loading: false,
            data: results,
            pagination: { ...rest },
          };
          if (fields?.fields?.order) currentFields.order = fields.fields.order;
          if (fields?.fields?.columnOrder)
            currentFields.columnOrder = fields.fields.columnOrder;
          dispatch(setPositions(currentFields));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          dispatch(toastAction.error("Roles y Privilegios", data.message));
        }else{
          dispatch(toastAction.error("Roles y Privilegios", data.message));
        }
      })
      .catch((error)=>{
      })
      .finally(() => {
        dispatch(setPositions({ loading: false }));
      });
  };
};

const setAdvancedFilter = (payload) => {
  return (dispatch) => {
    dispatch({
      type: RrhhConstants.Accion.Positions.ADVANCED_FILTER,
      payload,
    });
  };
};

const getFilterValues = (newValues) => {
  const newFilterValues = {};
  newValues.forEach((val) => {
    const field = val.field.toLocaleLowerCase();
    if (field.includes("date")) newFilterValues[val.field] = val.date;
    else if (field === "name") newFilterValues[val.field] = val.value;
    else newFilterValues[val.field] = val.value;
  });
  return newFilterValues;
};

const removeFilterValues = (removeField) => {
  return (dispatch, getState) => {
    let { values } = getState().positionsReducer.advancedFilter;
    const newValues = values.filter(({ field }) => field !== removeField);
    const fields = getFilterValues(newValues);
    dispatch(getPositions({ change: true, fields }));
    dispatch(setAdvancedFilter({ values: newValues }));
  };
};

const reportStatus = (payload) => {
  return (dispatch) => {
    dispatch({
      type: RrhhConstants.Accion.Positions.REPORT_STATUS,
      payload,
    });
  };
};

const generateReport = () => {
  return (dispatch, getState) => {
    dispatch(reportStatus(true));
    let { values } = getState().positionsReducer.advancedFilter;
    const fieldValues = getFilterValues(values);
    PositionsServices.generateReport(fieldValues)
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        FileSaver.saveAs(blob, "Cargo.xls");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(reportStatus(false));
      });
  };
};

const currentAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: RrhhConstants.Accion.Positions.CURRENT_ACTION,
      payload,
    });
  };
};

const setCurrentPosition = (payload) => {
  return (dispatch) => {
    dispatch({
      type: RrhhConstants.Accion.Positions.EDIT_POSITION,
      payload,
    });
  };
};

const editPosition = (id) => {
  return (dispatch) => {
    dispatch(setCurrentPosition({ isLoadingPosition: true }));
    PositionsServices.getPositionById(id)
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setCurrentPosition({ data: data.data }));
        }else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Roles y Privilegios", data.message));
        }else{
          dispatch(toastAction.error("Roles y Privilegios", data.message));
        }
      })
      .catch((error)=>{
      })
      .finally(() => {
        dispatch(setCurrentPosition({ isLoadingPosition: false }));
      });
  };
};

const setGetDataMasters = (payload) => {
  return (dispatch) => {
    dispatch({
      type: RrhhConstants.Accion.Positions.GET_DATA_MASTER,
      payload,
    });
  };
};

const getDataMaster = () => {
  return (dispatch) => {
    dispatch(setGetDataMasters({ loading: true }));
    PositionsServices.getDataMaster()
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setGetDataMasters({ data: data.data }));
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setGetDataMasters({ loading: false }));
      });
  };
};

const savePosition = ({ position, isNewPosition }) => {
  return (dispatch) => {
    const currentLoading = { isLoading: true };

    dispatch(setCurrentPosition(currentLoading));
    PositionsServices.savePosition(position)
      .then(({ data }) => {
        const status = data.status;
        if (data.status === CoreConstants.HttpResponse.OK) {
          const message = isNewPosition
            ? "¡Registro exitoso!"
            : "¡Edición exitosa!";
          dispatch(toastAction.success("Cargos", message));
          dispatch(setCurrentPosition({ errors: [] }));
        } else if (
          data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL
        ) {
          dispatch(setCurrentPosition({ errors: data.data }));
          dispatch(toastAction.warn("Roles y Privilegios", data.message));
        }else{
          dispatch(toastAction.error("Roles y Privilegios", data.message));
        }
        dispatch(setCurrentPosition({ status }));
      })
      .catch((error) => {
        console.error(error);
        dispatch(setCurrentPosition({ status: 2 }));
      })
      .finally(() => {
        currentLoading.isLoading = false;
        dispatch(setCurrentPosition(currentLoading));
      });
  };
};

const deletePosition = (id) => {
  return (dispatch) => {
    PositionsServices.deletePosition(id)
      .then(({ data }) => {
        const status = data.status;
        if (status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success("Cargos", "Cargo Eliminado"));
          dispatch(getPositions({ change: false }));
        }
      })
      .catch(console.error);
  };
};

export const PositionsAction = {
  getPositions,
  setAdvancedFilter,
  removeFilterValues,
  generateReport,
  currentAction,
  editPosition,
  getDataMaster,
  setCurrentPosition,
  savePosition,
  deletePosition,
};
