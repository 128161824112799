import React, { useEffect, useRef, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Controller } from 'react-hook-form'
import { Dropdown } from 'primereact/dropdown'
import { Editor } from 'primereact/editor'
import { InputText } from 'primereact/inputtext'
import { Panel } from 'primereact/panel'
import { Button } from 'primereact/button';
import HeaderEditor from 'shared/components/header-editor/HeaderEditor'
import { useParams } from 'react-router-dom'
import { Menu } from 'primereact/menu';
import { ConfirmDialog } from 'primereact/confirmdialog';
import './GeneralData.scss'
import { PrevisualizationRender } from './controllers/PrevisualizationRender';
import {  Modal2 } from './Modal2';
import { useDispatch, useSelector } from 'react-redux';
import { ProcessesAction } from 'features/configuration/store/actions/ProcessesAction';
import { Skeleton } from 'primereact/skeleton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from 'primereact/tooltip';

const GeneralData = ({dinamicStage, setDinamicStage, control, errors, valuesFields, validateLength, listOptions, listStages, setListStage, positionSoruce, setPositionSource, typeModal, setTypeModal, displayModal, setDisplayModal, optionSelected, setOptionSelected, inputSelected, setInputSelected, stateConfigPage, setStateConfigPage, stateInsufficient, destinationControl, setDestinationControl, children}) => {
  const params = useParams();
  const [isFirstLoading, setFirstLoading] = useState(true);
  const [idDeleteStage, setIdDeleteStage] = useState(null)
  // const [dinamicStage, setDinamicStage] = useState([])
  const [showConfirmDialog, setshowConfirmDialog] = useState(false);
  const menu = useRef(null);

  // console.log(valuesFields)
  const dispatch = useDispatch()
  const responsables = useSelector(state => state.processReducer.listResponsibles.data?.responsibles)

  // console.log(responsables)
  const history = useHistory()

  //states ExtraModal
  const [displayExtraModal, setDisplayExtraModal] = useState({title: '', visible: false, mode: "nuevo"})
  const [dataExtraModal, setDataExtraModal] = useState({
    fieldLabel: '',
    typeOptionId: null,
    options: [],
    required: false,
    stageId: null
  })

  const getListStyleOptions = (isDraggingOver) => ({
    border: isDraggingOver ? '1px dashed rgb(75 85 99)' : 'none',
    padding: '10px',
    margin: '0 0 0 0'
  });

  const getListStyleZone = (isDraggingOver, otro) => ({
    background: isDraggingOver ? "white" : "white",
    //rgb(191 219 254)
    border: isDraggingOver ? '1px dashed rgb(75 85 99)' : 'none',
    // borderTop: isDraggingOver ? 'none' : '1px solid rgb(75 85 99)',
    margin: '0 0 0 0'
  });


  const getItemStyleZone = (isDragging, draggableStyle) => ({
    // userSelect: "none",
    margin: `0px 0 0px 0`,
    // background: isDragging ? "red" : "blue",
    // border: isDragging ? '1px dashed rgb(75 85 99)' : 'none',
    ...draggableStyle
  });

  // const getItemStyleOptions = (isDragging, draggableStyle) => ({
  //   // userSelect: "none",
  //   margin: `0 0 0 0`,
  //   // background: isDragging ? "lightgreen" : "white",
  //   border: isDragging ? '1px dashed rgb(75 85 99)' : '1px solid rgb(75 85 99)',
  //   borderRadius: '10px',
  //   ...draggableStyle
  // });

  useEffect(()=> {
    // setValue("name", '');
    if (isFirstLoading)
      setFirstLoading(false);
    if (params.id){
        getProcessesById(params.id)
    }
    return () => {
      dispatch(ProcessesAction.resetAfterSaved())
    }
  }, [params.id])
  

  useEffect(()=> {
    if(!params.id&&(dinamicStage.length===0)){
      const newId = uuidv4()
      setDinamicStage([{
        id: newId,
        mode: "new",
        text: "",
        dinamicInput: []
      }])
      setListStage([...listStages, newId])
    }
    if(!responsables){
      dispatch(ProcessesAction.searchResponsibles())
    }
  },[])

  const cancelAccept = () => {
    // const { description, ...rest } = pageTitle;
    // updateTitle(rest);
    history.push("/configuracion/procesos");
  };

  const getProcessesById = (processesId) => {
    return dispatch(ProcessesAction.getProcessById(processesId));
  }

  const header = HeaderEditor();

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) return
    if ( source.index === destination.index && source.droppableId === destination.droppableId ) return
    if ((listStages.includes(source.droppableId)) && destination.droppableId === "items-menu") return
    if ( source.droppableId === destination.droppableId && (listStages.includes(source.droppableId))) {
      const clone = (([...dinamicStage]).map(elem => {
        if(elem.id===source.droppableId){
          elem.dinamicInput = reorder(elem.dinamicInput, source.index, destination.index)
        }
        return elem
      }))
      setDinamicStage(clone)
      // setDinamicInput((prevInputs) => reorder(prevInputs, source.index, destination.index) )
      return
    }
    // if (!destination) return
    // if ( source.index === destination.index && source.droppableId === destination.droppableId ) return
    // if (source.droppableId === "items-drop" && destination.droppableId === "items-menu") return
    // if ( source.droppableId === destination.droppableId && source.droppableId === "items-drop"  ) {
    //   setDinamicInput((prevInputs) => reorder(prevInputs, source.index, destination.index) )
    //   return
    // }

    setPositionSource(destination.droppableId)

    if (source.droppableId === "items-menu" && (listStages.includes(destination.droppableId))) {
      const idsModal1 = ['ID','TEXTO','TEXTO_LARGO','TEXTO_DINAMICO','NUMERO','FECHA','FECHA_HORA','IMAGEN','ADJUNTAR_ARCHIVO','ETIQUETA','USUARIOS', 'CONEXION_A_PROCESOS','CONEXION_A_DATOS']
      const idsModal2 = ['LISTA_DESPLEGABLE','SELECCION_MULTIPLE','SELECCION_SIMPLE','TABLA','MONEDA']
      setDestinationControl(destination.index)
      if (idsModal1.includes(draggableId)) {
        setInputSelected(null)
        setOptionSelected(draggableId)
        setDisplayModal(true)
        return
      }else if (idsModal2.includes(draggableId)) {
        setDataExtraModal({
              ...dataExtraModal,
              typeOptionId: draggableId
        })
        // setOptionSelected(draggableId)
        setDisplayExtraModal({ title: draggableId, visible: true, mode: "nuevo"})
        return
      }
    }
  };

  const items = [
    {
        label: 'Editar',
        icon: 'pi pi-pencil',
        command: (e) => {
            // setDisplayExtraModal({ title: '', visible: true, mode: "editar"})
            if(typeModal===1){
              setTypeModal(1)
              setDisplayModal(true)
            }else if(typeModal===2){
              setTypeModal(2)
              setDisplayExtraModal({ title: '', visible: true, mode: "editar"})
            }
            // history.push('/mantenimiento/institucion/' + institutionSelected.id);
        }
    },
    {
        label: 'Eliminar',
        icon: 'pi pi-times',
        command: (e) => {
            setshowConfirmDialog(true);
        }
    }
  ];

  const confirmDelete = () => {
    const dataFiltered = (([...dinamicStage]).map(elem=> {
      if(elem.id===inputSelected.stageId){
        const newDinput = elem.dinamicInput.filter(el=> el.id!==inputSelected.id)
        elem.dinamicInput= newDinput
      }
      return elem
    }))

    let simpleFilters = []
    let advanceFilters = []
    let searchColumns = []

    if(stateConfigPage.simpleFilters?.length){
        simpleFilters = stateConfigPage.simpleFilters.filter(el => el!==inputSelected.identifier)
    }
    if(stateConfigPage.advanceFilters?.length){
      advanceFilters = stateConfigPage.advanceFilters.filter(el => el!==inputSelected.identifier)
    }

    if(stateConfigPage.searchColumns?.length){
      searchColumns = stateConfigPage.searchColumns.filter(el => el!==inputSelected.identifier)
    }

    const newDataConfig = {simpleFilters, advanceFilters, searchColumns}
    setStateConfigPage(newDataConfig)
    setDinamicStage(dataFiltered)
    setInputSelected(null)
  }


  const cancelDelete = () => {
      setshowConfirmDialog(false);
  }

  // Agregar fila en tipo tabla
  const addRows = (index, input) => {
    const clone = [...dinamicStage]
    clone.map(elem => {
      if(elem.id===input.stageId){
        elem.dinamicInput.map(el => {
          if(el.id===input.id){
            el.numRows.push('')
          }
          return el
        })
      }
      return elem
    })
    setDinamicStage(clone)
  }

  // Quitar fila en tipo tabla
  const dropRows = (index, input) => {
    const clone = [...dinamicStage]
    clone.map(elem => {
      if(elem.id===input.stageId){
        elem.dinamicInput.map(el => {
          if(el.id===input.id){
            if(el.numRows.length>1){
              el.numRows.pop()
            }
          }
          return el
        })
      }
      return elem
    })
    setDinamicStage(clone)
  }

  const addNewStage = () => {
    const newId = uuidv4()
    setDinamicStage([...dinamicStage, {
      id: newId,
      mode: "new",
      text: "",
      dinamicInput: []
    }])
    setListStage([...listStages, newId])
  }

  const onChangeNameStage = (e, index) => {
    const clone = (([...dinamicStage]).map(el=> {
      if(el.id===index){
        el.text=(e.target.value)
      }
      return el
    }))
    setDinamicStage(clone)
  }

  const cancelDeleteStage = () => {
    setIdDeleteStage(null)
  }

  const confirmDeleteStage = () => {
      const clone = ((([...dinamicStage]).filter(el=> el.id!==idDeleteStage)))
      setDinamicStage(clone)
  }

  const template = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} justify-content-start`;

    return (
        <div className={className}>
            <div className='flex w-full justify-between items-center'>
              <div className='w-4/5 lg:w-4/5 xl:w-3/5'>
                <InputText value={(dinamicStage[options.props.positionP].text)} onChange={(e) => {onChangeNameStage(e,options.props.valueID)}} className={`bg-transparent border-0 font-bold`} placeholder="Click aquí para escribir"/>
              </div>
              <div className='flex items-center w-16 justify-between'>
                <div>
                  <i className="pi pi-trash" onClick={()=> {setIdDeleteStage(options.props.valueID)}}></i>
                </div>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                </button>
              </div>
            </div>
        </div>
    )
  }

  return (
    // grid grid-cols-5
    <div className='flex gap-x-2'>
      <DragDropContext onDragEnd={(result) => onDragEnd(result) }>
        {/* col-span-1 */}
        <div className='listControls'>
            { 
              // valuesFields?.status?.description && (
              //   <span className="w-full col-span-12 lg:col-span-4 flex items-center justify-start lg:justify-center">

              //     <p className='font-bold'>Estado: {valuesFields?.status?.description.toUpperCase()}</p>
              //   </span>
              // ) 
              <div className='mb-2 border-b'>
                <span className="w-full col-span-12 lg:col-span-4 items-center justify-start pl-3 hidden lg:block">
                  {/* <p className='font-bold'>Estado: {'Borrador'}</p> */}
                  <p className='font-bold'>Estado: {valuesFields.statusData?.statusName}</p>
                </span>
                {/* <hr /> */}
              </div>
            }
            <div className='ml-3 mb-3 hidden lg:block'>
              <p><strong>Tipos de Campos</strong></p><p className='text-sm text-gray-400'>Arrastre para agregar el campo en el formulario</p>
            </div>
            {/* scrollbar-contend overflow-x-auto */}
              <div className='scroll' style={{overflow: 'auto'}}>
                        {
                          listOptions?(<>
                            <Droppable droppableId='items-menu' direction="vertical">
                                {
                                  (droppableProvided, snapshot) => (
                                    <div 
                                      ref={droppableProvided.innerRef}
                                      style={getListStyleOptions(snapshot.isDraggingOver)}
                                      {...droppableProvided.droppableProps}
                                      className='grid grid-cols-1 gap-y-2 md:gap-2 items-center bg-blue-200 lg:bg-white'  
                                      >
                                        {
                                          listOptions?.map(({code, icon, name}, index) => (
                                            <Draggable 
                                                key={`drag-${code}`}
                                                draggableId={`${code}`}
                                                index={index} 
                                            >
                                              {
                                                (draggableProvided, snapshot) => (
                                                  <>
                                                  <Tooltip target=".customClassName" mouseTrack mouseTrackLeft={10} className="lg:hidden"/>
                                                  <div 
                                                    ref={draggableProvided.innerRef}
                                                    {...draggableProvided.draggableProps} 
                                                    {...draggableProvided.dragHandleProps}
                                                    // style={getItemStyleOptions(
                                                    //   snapshot.isDragging,
                                                    //   draggableProvided.draggableProps.style
                                                    // )}
                                                    data-pr-tooltip={name} data-pr-position="top"
                                                    className='customClassName border-2 border-stone-700 flex justify-center md:justify-start items-center h-10 w-10 md:h-12 md:w-12 lg:w-full lg:rounded-lg hover:bg-gray-200 lg:bg-white'
                                                  >
                                                      <i className={`${icon} ml-4 mr-4 lg:mr-1`} style={{color: "#43A1F3"}} ></i>
                                                      <p className='hidden lg:block text-center text-xs lg:text-xs lg:text-left'>{name}</p>
                                                  </div></>
                                                )
                                              }
                                            </Draggable>
                                          ))
                                        }
                                        {droppableProvided.placeholder}
                                    </div>
                                  )
                              }
                            </Droppable>
                          </>):<Skeleton width="100%" height="150px" />
                        }
              </div>
        </div>
        {/* col-span-4 */}
        <div className='flex flex-col flex-1'>
          <div className='grid grid-cols-10 content-div1 scroll content-start' style={{width: '-webkit-fill-available'}}>
            <Panel header="DATOS GENERALES" toggleable className='col-span-10 lg:col-span-10'>
              <div className="grid  sm:grid-cols-1 md:grid-cols-2 gap-2">
                  <span className="p-float-label w-full mt-2">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value },
                              fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                            <InputText
                                type="text"
                                id="name"
                                name="name"
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                className={errors?.name ? 'p-invalid w-full' : 'w-full'}
                            />
                          )}
                          name="name"
                          rules={{
                              required: 'El nombre del proceso es requerido.',
                          }}
                      />
                      <label htmlFor="code">Nombre del Proceso  *</label>
                  </span>
                </div> 

                <div className="grid sm:grid-cols-1 md:grid-cols-1 gap-1 mt-2 mb-1">
                    <span className="p-float-label w-full sm:w-1/2 mt-4">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, value },
                          }) => (
                            <Dropdown
                              id="responsibleId"
                              name="responsibleId"
                              optionLabel="fullName"
                              showClear
                              value={value}
                              options={responsables}
                              onChange={onChange}
                              filter 
                              filterBy="name"
                              placeholder="Seleccione un responsable *" 
                              className={errors?.responsibleId ? 'p-invalid w-full' : 'w-full'}
                              optionValue={(e) => {
                                return e.id;
                                  }}
                                />
                          )}
                          name="responsibleId"
                          rules={{
                              required: 'El responsable es requerido.',
                          }}
                      />
                    </span>
                </div>

                <div className="mt-5">
                    <Controller
                      control={control}
                      // rules={{required: true}}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Editor
                            headerTemplate={header}
                            onTextChange={(e) => onChange(e.htmlValue)}
                            value={value}
                            // className={`${errors.processDescription?.type ==='required' ? 'p-invalid': ''}`}
                            placeholder="Añade la descripción del proceso"
                            name="processDescription"
                          />
                          <p className="font-semibold text-base text-gray-500 lg:mt-1 mt-3 text-right">
                            Caracteres{' '}
                            {validateLength(
                              valuesFields.processDescription
                            )}{' '}
                            / 4000{' '}
                          </p>
                        </>
                      )}
                      name="processDescription"
                    />
                </div>
                {/* {
                  // (existError) && <div className='message error'>
                  (errors.name || errors.responsibleId ) && <div className='message error'>
                      <ul>
                          {errors.name ? (
                              <>
                                  <li className="p-error">{errors.name.message}</li>
                              </>
                          ) : null}
                      </ul>
                      <ul>
                          {errors.responsibleId ? (
                              <>
                                  <li className="p-error">{errors.responsibleId.message}</li>
                              </>
                          ) : null}
                      </ul>
                  </div>
                } */}
            </Panel>
            {/* <div className='bg-red-500 h-11 w-full sticky top-0'>Hola</div> */}
            <Panel header="ETAPAS" toggleable className='col-span-10 lg:col-span-10'>
                <div className='grid grid-cols-12 gap-2'>
                    <div className='col-span-12 flex flex-col px-3'>
                        <div className='mb-2'>
                          <Button
                            icon="pi pi-plus" type="button" label="Añadir nueva etapa" className="sig-button sig-primary w-auto pointer" 
                            onClick={addNewStage}
                          />
                        </div>
                          {
                            dinamicStage?.map((elem, indiceP)=> {
                              return (<Panel key={elem.id} headerTemplate={template} positionP={indiceP} valueID={elem.id} toggleable className='mt-1 mb-1'>
                                <Droppable droppableId={elem.id} direction='vertical' >
                                    {
                                    (droppableProvided, snapshot) => (
                                      <div 
                                        {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}  
                                        style={getListStyleZone(snapshot.isDraggingOver, snapshot.isUsingPlaceholder)}
                                        // className='flex flex-col items-center rounded-lg gap-y-4 max-h-48 overflow-y-auto px-2 sm:px-6 py-3 border-t border-gray-600'
                                        className='flex flex-col items-center rounded-lg gap-y-4 px-2 sm:px-6 py-3 border-t border-gray-600'
                                      >
                                          {
                                            elem?.dinamicInput.length > 0 ? null
                                            : (
                                              <>
                                              <div className='flex items-center justify-center lg:h-8 xl:h-10 border-2 border-gray-300 w-full border-dashed'>
                                                <p className='text-center'><i className="pi pi-plus mr-1 text-sm"></i>Arrastre aqui para añadir campos</p>
                                              </div>
                                              </>
                                            )
                                          }
                                          {
                                            elem?.dinamicInput.map((input, index) => (
                                              <Draggable 
                                                  key={`drop-${input.id}`}
                                                  draggableId={`${input.id}`}
                                                  index={index} 
                                              >
                                                {
                                                  (draggableProvided, snapshot) => (
                                                    <div 
                                                      ref={draggableProvided.innerRef}
                                                      {...draggableProvided.draggableProps} 
                                                      {...draggableProvided.dragHandleProps}
                                                      style={getItemStyleZone(
                                                        snapshot.isDragging,
                                                        draggableProvided.draggableProps.style
                                                      )}
                                                      className={`w-full input-dinamic-hover py-3 px-5 block config-process-controls`} key={input.id}
                                                    >
                                                      <div className='flex justify-between'>
                                                          {
                                                            (input.typeOptionId!=="ETIQUETA")?<p className='text-sm text-gray-300'>{input.fieldLabel} {input.required ? '*' : ''}</p>:<p></p>
                                                          }
                                                          <i
                                                            className="pi pi-ellipsis-v cursor-pointer"
                                                            onClick={(event) => {
                                                                // console.log(input)
                                                                setInputSelected(input);
                                                                menu.current.toggle(event)
                                                                const idsModal1 = ['ID','TEXTO','TEXTO_LARGO','TEXTO_DINAMICO','NUMERO','FECHA','FECHA_HORA','IMAGEN','ADJUNTAR_ARCHIVO','ETIQUETA','USUARIOS','CONEXION_A_DATOS','CONEXION_A_PROCESOS']
                                                                const idsModal2 = ['LISTA_DESPLEGABLE','SELECCION_MULTIPLE','SELECCION_SIMPLE','TABLA','MONEDA']
                                                                if (idsModal1.includes(input.typeOptionId)) {
                                                                  setTypeModal(1)
                                                                }else if (idsModal2.includes(input.typeOptionId)) {
                                                                  setTypeModal(2)
                                                                }
                                                            }}
                                                          ></i>
                                                        </div>
                                                      <PrevisualizationRender 
                                                        input={input} 
                                                        dropRows={dropRows} 
                                                        addRows={addRows} 
                                                        index={index}
                                                        // responsables={responsables}
                                                      />
                                                    </div>
                                                  )
                                                }
                                              </Draggable>
                                            ))
                                          }
                                          {droppableProvided.placeholder}
                                      </div>
                                      )
                                    }
                                </Droppable>
                            </Panel>)
                            })
                          }
                    </div>
                </div>
                <div className={`rounded bg-red-200 p-5 mt-6 text-sm text-red-600 message error ${stateInsufficient===''&&'hidden'}`}>
                  <ul>
                    <li className='p-error'>{stateInsufficient}</li>
                  </ul>
                </div>
              {
                  // (existError) && <div className='message error'>
                  (errors.name || errors.responsibleId ) && <div className='message error'>
                      <ul>
                          {errors.name ? (
                              <>
                                  <li className="p-error">{errors.name.message}</li>
                              </>
                          ) : null}
                      </ul>
                      <ul>
                          {errors.responsibleId ? (
                              <>
                                  <li className="p-error">{errors.responsibleId.message}</li>
                              </>
                          ) : null}
                      </ul>
                  </div>
                }
            </Panel>
            <div className='col-span-10'>
              {children}
            </div>
          </div>
        </div>
          {/* <Modal
            positionSoruce={positionSoruce}
            dinamicStage={dinamicStage}
            setDinamicStage={setDinamicStage}
            typeModal={typeModal}
            displayModal={displayModal} 
            optionSelected={optionSelected} 
            dinamicInput={dinamicInput} 
            inputSelected={inputSelected}
            setDisplayModal={setDisplayModal} 
            setDinamicInput={setDinamicInput} 
            setOptionSelected={setOptionSelected}
            setInputSelected={setInputSelected}
          /> */}
          <Modal2
            positionSoruce={positionSoruce}
            dinamicStage={dinamicStage}
            setDinamicStage={setDinamicStage}
            displayExtraModal={displayExtraModal}
            setDisplayExtraModal={setDisplayExtraModal}
            dataExtraModal={dataExtraModal}
            setDataExtraModal={setDataExtraModal}
            inputSelected={inputSelected}
            setInputSelected={setInputSelected}
            listOptions={listOptions}
            destinationControl={destinationControl}
          />
          <Menu model={items} popup ref={menu} id="popup_menu" />
          <ConfirmDialog
            visible={showConfirmDialog}
            onHide={cancelDelete}
            message="¿Está seguro que desea eliminar el registro?"
            header="Eliminar"
            icon="pi pi-trash" 
            accept={confirmDelete}
            reject={cancelDelete}
        />
        <ConfirmDialog
            visible={idDeleteStage}
            onHide={cancelDelete}
            message="¿Está seguro que desea eliminar la etapa?"
            header="Eliminar"
            icon="pi pi-trash" 
            accept={confirmDeleteStage}
            reject={cancelDeleteStage}
        />
      </DragDropContext> 
    </div> 
  )
}

export default GeneralData