import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import AdvancedFilterComponent from 'features/riskManagement/components/ModelManagement/Evaluacion/AdvancedFilterComponent';
import FilterComponent from 'features/riskManagement/components/ModelManagement/Evaluacion/FilterComponent';
import TableComponent from 'features/riskManagement/components/ModelManagement/Evaluacion/TableComponent';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const EvaluacionComponent = () => {

	const [showModal, setShowModal] = useState(false)
	const { isLoading } = useSelector(state => state.riskEvaluationReducer.evaluations)
	const history = useHistory()
	const { updateTitle } = useSetTitlePage();
	const { idModelo } = useParams()

	useEffect(()=>{
		updateTitle({
			title: "Gestión de Riesgos",
			subtitle: "Modelo de Gestión",
			description: "Evaluar",
			previousUrl: "/gestion-riesgos/modelo",
		})
	},[])

	return (
		<div>
			<FilterComponent setShowModal={setShowModal}/>
			<div className="flex items-center justify-between p-4">
				<Button
					loading={isLoading}
					icon="pi pi-plus"
					type="button"
					label="Nuevo"
					className="sig-button sig-dark pointer"
					onClick={()=>{history.push(`/gestion-riesgos/modelo/${idModelo}/evaluaciones/evaluacion/nuevo`);}} 
				/>
			</div>
			<TableComponent />
			<AdvancedFilterComponent showModal={showModal} setShowModal={setShowModal} /> 
		</div>
	);
};

export default EvaluacionComponent;