import { MaintenanceConstants } from "features/maintenance/commons/MaintenanceConstans"; 
import { CoreConstants } from 'core/commons/CoreConstants';
import moment from 'moment';
import { toastAction } from "core/store/actions/ToastAction";
import FileSaver from 'file-saver';
import { OccupationsService } from "features/maintenance/services/OccupationsServices";


const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: MaintenanceConstants.Accion.Occupation.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};
const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: MaintenanceConstants.Accion.Occupation.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; 
  
    if (advancedFilter.filter.name)
      pines.push({ field: 'name', value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });


    advancedFilter.values = pines;
    dispatch({
      type: MaintenanceConstants.Accion.Occupation.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setOccupations = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Occupation.SEARCH_OCCUPATIONS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const occupations = getState().occupationReducer.occupations;
    setOccupations({
      dispatch: dispatch,
      isLoading: true,
      data: occupations.data,
    });

    const criteria = {};
    const advancedFilter = getState().occupationReducer.advancedFilter;
    const filter = getState().occupationReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;
    
    
    OccupationsService.searchOccupations(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
        setOccupations({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });          
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          setOccupations({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.warn('Ocupaciones', data.message));
        }else{
          setOccupations({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Ocupaciones', data.message));
        }
      },
      () => {
        setOccupations({ dispatch: dispatch, isLoading: false, data: null });
      }
    ).catch((error)=>{
    });
  };
};

const getOccupationById = (occupationId) => {
  const setOccupation = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Occupation.GET_OCCUPATION_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setOccupation({ dispatch: dispatch, isLoading: true });
    OccupationsService.getOccupationById(occupationId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setOccupation({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setOccupation({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Ocupación', data.message));
          // dispatch({
          //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
          //   toast: {
          //     titulo: 'Departamento',
          //     mensaje: data.message,
          //     severidad: 'warn',
          //   },
          // });
        }else{
          dispatch(toastAction.error('Ocupación', data.message));
        }
      },
      () => {
        setOccupation({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};

const saveOccupation = (occupation) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentOccupation }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Occupation.SAVE_OCCUPATION,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    const currentOccupation = getState().occupationReducer.occupation;
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
      currentOccupation: currentOccupation,
    });
    OccupationsService.saveOccupations(occupation).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Ocupación', 'La ocupación se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentOccupation, });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentOccupation });
          dispatch(toastAction.warn('Ocupación', data.message));
        }else{
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
          dispatch(toastAction.error('Ocupación', data.message));
        }
      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};



const deleteOccupation = (occupationId) => {

  return (dispatch, getState) => {

    OccupationsService.deleteOccupation(occupationId).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Ocupación', 'La ocupación se eliminó correctamente'));
          dispatch({ type: MaintenanceConstants.Accion.Occupation.DELETE_OCCUPATION, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Ocupación', data.message));
          dispatch({ type: MaintenanceConstants.Accion.Occupation.DELETE_OCCUPATION, payload: { isDeleted: false } });
        }
      },
      () => {
        dispatch({ type: MaintenanceConstants.Accion.Occupation.DELETE_OCCUPATION, payload: { isDeleted: false } });
      }
    );
  };
};

const generateReport = (payload) => {
  const setOccupations = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: MaintenanceConstants.Accion.Occupation.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setOccupations({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    const filter = getState().occupationReducer.advancedFilter.filter;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    OccupationsService.generateReport(criteria)
      .then(({ data }) => {
        setOccupations({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Ocupacion.xls');
      })
      .catch((err) => {
        setOccupations({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Ocupación', 'Hubo un error al descargar el archivo'));
      });
  };
};

export const OccupationAction = {
  toggleAdvancedFilter,
  updateFilter,
  search,
  saveOccupation,
  deleteOccupation,
  generateReport,
  resetAfterSaved,
  getOccupationById,
}