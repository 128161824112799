import { apiService } from "core/services/ApiService";

const searchAcademicDegrees = (criteria) => {
  return apiService.Post("management/api/AcademicDegree/Search", criteria);
}

const saveAcademicDegree = (data) => {
  return apiService.Post("management/api/AcademicDegree/Save", data);
}

const getAcademicDegreeById = (academicDegreeId) => {
  return apiService.Get("management/api/AcademicDegree/GetById?id=" + academicDegreeId);
}

const generateReport = (payload) => {
  return apiService.Post("management/api/AcademicDegree/GenerateReport", payload, {
    responseType: 'blob',
  })
}

const deleteAcademicDegree = (academicDegreeId) => {
  return apiService.Delete("management/api/AcademicDegree/Delete?id=" + academicDegreeId);
}

const isStorageEnabled = () => {
  return apiService.Get("storage/api/Storage/IsStorageEnabled");
}

export const AcademicDegreeService = {
  searchAcademicDegrees,
  saveAcademicDegree,
  getAcademicDegreeById,
  generateReport,
  deleteAcademicDegree,
  isStorageEnabled
};