import { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'primereact/button';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import TableComponent from "features/subscription/components/Solutions/TableComponent";
import AdvancedFilterComponent from "features/subscription/components/Solutions/AdvacedFilterComponents";
import FilterComponent from "features/subscription/components/Solutions/FilterComponent";
import { useClaimSolutions } from 'features/subscription/hooks/useClaimSolutions';

const SearchSolutionPage = () => {
    const history = useHistory();
    const { updateTitle } = useSetTitlePage();
    const { isSearch, isDownload, isCreate } = useClaimSolutions();
    
    useEffect(() => {
        updateTitle({
            title: 'Administración del Servicio',
            subtitle: 'Soluciones',
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {
                (isSearch||isDownload)&&(
                    <FilterComponent />
                )
            }
            {
                isCreate&&(
                    <Button
                        icon="pi pi-plus"
                        type="button"
                        label="Nuevo"
                        className="sig-button sig-dark mt-4"
                        onClick={() => {
                            history.push('/suscripcion/solucion/nuevo');
                        }}
                    />
                )
            }
            <TableComponent />
            <AdvancedFilterComponent /> 
        </Fragment>
    );
};

export default SearchSolutionPage;
