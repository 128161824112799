import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router";
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import LogoComponent from 'shared/components/logo/LogoComponent';
import { Button } from 'primereact/button';

import moment from "moment";

import './PageRegistrationForm.scss'
import { useDispatch, useSelector } from 'react-redux';
// import { RegistrationFormAction } from 'features/registrationForm/store/actions/RegistrationFormAction';
import { RegistrationFormAction } from 'features/public/store/actions/RegistrationFormAction';
import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from 'core/store/actions/ToastAction';
import ThankYouMessage from 'shared/components/Formulario/ThankYouMessage';
import { RegistrationFormService } from '../services/RegistrationFormService';

export const PageRegistrationForm = () => {

	const toast = useRef(null);
	const dispatch = useDispatch();
	const dataMaster = useSelector(state => state.RegistrationFormReducer.dataMaster.data)
	const dataCourse = useSelector(state => state.RegistrationFormReducer.course.data)
	const error = useSelector(state => state.RegistrationFormReducer.course.error)
	const isLoadingdataCourse = useSelector(state => state.RegistrationFormReducer.course.isLoading)
	const {isSaved, message, isLoading } = useSelector((state) => state.RegistrationFormReducer.saveForm);
	const {toast: mensaje } = useSelector((state) => state.RegistrationFormReducer)
	const [countries, setCountries] = useState([])
	const [documentType, setDocumentType] = useState([])
	const [loadingConfig, setLoadingConfig] = useState(true)
	const querys = new URLSearchParams(useLocation().search)
	const token = querys.get("token")

	useEffect(() => {

    RegistrationFormService.getLogoAndColorSchema(token).then(({data}) => {
   
      if (data.status === CoreConstants.HttpResponse.OK) {
        const colorSchema = data.data[1];
        const companyLogo = data.data[0]

        if (colorSchema.length > 0) {
					// console.log('aca primero color schema')
          const headerBackgroundColor = colorSchema[0].details.filter(c => { return c.code === 'BACKGROUND_COLOR' })[0];
          const menuDefault = colorSchema[0].details.filter(c => { return c.code === 'MENU_DEFAULT' })[0];
          const menuHover = colorSchema[0].details.filter(c => { return c.code === 'MENU_HOVER' })[0];
          const menuSelected = colorSchema[0].details.filter(c => { return c.code === 'MENU_SELECTED' })[0];

          const btnPrimary = colorSchema[0].details.filter(c => { return c.code === 'BUTTON_PRIMARY' })[0];
          const btnSeconday = colorSchema[0].details.filter(c => { return c.code === 'BUTTON_SECONDARY' })[0];
          const btnSuccess = colorSchema[0].details.filter(c => { return c.code === 'BUTTON_SUCCESS' })[0];
          const btnDark = colorSchema[0].details.filter(c => { return c.code === 'BUTTON_DARK' })[0];
          const btnError = colorSchema[0].details.filter(c => { return c.code === 'BUTTON_ERROR' })[0];
          const btnWarning = colorSchema[0].details.filter(c => { return c.code === 'BUTTON_WARNING' })[0];

          document.documentElement.style.setProperty('--sig-header-background-color', headerBackgroundColor.value);
          document.documentElement.style.setProperty('--sig-menu-default', menuDefault.value);
          document.documentElement.style.setProperty('--sig-menu-hover', menuHover.value);
          document.documentElement.style.setProperty('--sig-menu-selected', menuSelected.value);

          document.documentElement.style.setProperty('--sig-primary', btnPrimary.value);
          document.documentElement.style.setProperty('--sig-secondary', btnSeconday.value);
          document.documentElement.style.setProperty('--sig-success', btnSuccess.value);
          document.documentElement.style.setProperty('--sig-dark', btnDark.value);
          document.documentElement.style.setProperty('--sig-error', btnError.value);
          document.documentElement.style.setProperty('--sig-warning', btnWarning.value);
        }

        if (companyLogo.length > 0) {
          const logoDark = companyLogo[0].details.filter(c => { return c.code === 'LOGO_DARK' })[0];
          const logoDefault = companyLogo[0].details.filter(c => { return c.code === 'LOGO_DEFAULT' })[0];

          if (logoDefault.value.length > 0)
						// console.log('aca llamando')
            document.documentElement.style.setProperty('--sig-logo-default', 'url("' + logoDefault.value + '")');
        }

      } else {
        toastAction.error('Error', 'Se produjo un error al cargar la configuración');
      }
      setLoadingConfig(false);
    }).catch(c => {     
    	setLoadingConfig(false);
    })
  }, []);

	useEffect(() => {
    //debugger;
		toast.current?.show(mensaje);
  }, [mensaje]);

	useEffect(()=>{
			dispatch(RegistrationFormAction.getDataMaster())
			dispatch(RegistrationFormAction.getDataCourse(token))
	},[])

	useEffect(()=>{
		setCountries(dataMaster?.countries?.map(item=>({label: item.niceName, value: item.id})))
		setDocumentType(dataMaster?.documentTypes?.map((item)=>({label: item.description, value: item.id})))
	},[dataMaster])
	
	const {
		control,
		handleSubmit,
		setValue,
		watch,
		reset,
		formState: { errors },
} = useForm({
		defaultValues: {
				documentTypeId: null,
				documentNumber: null,
				studentName: null,
				studentEmailAddress: null,
				studentPhoneNumber: null,
				studentCountryId: null,
				birthDate: null,
				studentHomeAddress: null,
				studentAcademicDegree: null,
				studentProfession: null,
				studentAcademicInstitution: null,
				studentEmployerName: null,
				studentJobPosition: null,
				generateBill: false,
				termsAndConditionAccepted: null
		}
});

	const submit = (data) => {
		dispatch(RegistrationFormAction.submitForm({courseId: dataCourse.courseId, ...data}))
	}
	if(loadingConfig || isLoadingdataCourse){
		return (
		<div className="flex justify-center items-center h-screen">
			<img src="loading.gif" width={'200px'} alt='spinner loading' ></img>
		</div>);
	}else	{
		return (
		<div>
			{
				(error ) ? '':
			(isSaved && message)? 
			<ThankYouMessage message={message} title="¡Muchas gracias por registrarse!"/>
			:
			<div className='layout-registration-form-public overflow-auto scroll'>
			<div className="menu-component mb-2">
				<div className="icon-w-logo flex justify-start items-center">
					<LogoComponent noRedirect={true} />
				</div>
			</div>
			<div className='main-registration-form-public'>
			<h1 className={`background-title-form-registration text-white py-2 px-3 text-center text-3xl`}>FICHA DE INSCRIPCIÓN</h1>
			<div className='p-4'>
				<div className="font-semibold	">
					{ !isLoadingdataCourse && (dataCourse?.companyName || " ") }
				</div>
				<div>
					R.U.C.: {dataCourse?.companyDocument}
				</div>
				<div>
					{dataCourse?.companyAddress}
				</div>
				<div>
					{dataCourse?.ciudadPais}
				</div>
				<div>
					{(dataCourse?.companyPhone || dataCourse?.companyPhone!=="") && `Teléfonos: ${dataCourse?.companyPhone}`}
				</div>
				<div>
				{(dataCourse?.companyEmailAddress || dataCourse?.companyEmailAddress!=="") && `Teléfonos: ${dataCourse?.companyEmailAddress}`}
				</div>
				<div className='mt-4'>
					Señores de {dataCourse?.companyName} sirva el presente documento para la confirmación de mi participación en el Curso:
				</div>
				<div className='grid grid-cols-2 border border-black'>
					<div className='border-b sm:border-r sm:border-b-0 border-black box-border p-2 col-span-2 sm:col-span-1'>
						<div>
							{dataCourse?.courseName}
						</div>
						<div>
							Modalidad: {dataCourse?.courseMode}
						</div>
					</div>
					<div className='col-span-2 sm:col-span-1 p-2'>
						Inicio: {(dataCourse?.from && moment(dataCourse?.from).format(CoreConstants.Format.DDMMYYYY)) || ""}
					</div>
				</div>
			</div>
			<form className='grid grid-cols-6 gap-4 p-4'>
				<h1 className={`background-title-form-registration text-white px-3 font-semibold col-span-6`}>DATOS PERSONALES</h1>
				<div className='col-span-6 sm:col-span-2'>
					<span className="p-float-label w-full mt-4">
						<Controller
							control={control}
							name="documentTypeId"
							rules={{
								required: 'Debe seleccionar el tipo de documento',
							}}
							render={({field: { onChange, value, name}}) => (
									<Dropdown
											id="documentTypeId"
											name={name}
											options={documentType}  
											onChange={onChange}
											value={value}
											className={errors?.documentTypeId ? 'p-invalid w-full' : 'w-full'}
									/>
							)}
						/>
						<label htmlFor="documentTypeId">Tipo de Documento (*)</label>
					</span>
				</div>
				<div className='col-span-6 sm:col-span-4'>
				<span className="p-float-label w-full mt-4">
						<Controller
							control={control}
							name="documentNumber"
							rules={{
								required: 'Debe completar el número de documento',
							}}
							render={({field: { onChange, value, name}}) => (
									<InputText
											id="documentNumber"
											name={name}
											onChange={onChange}
											value={value || ""}
											className={errors?.documentNumber ? 'p-invalid w-full' : 'w-full'}
									/>
							)}
						/>
						<label htmlFor="documentNumber">Número Documento (*)</label>
					</span>
				</div>
				<div className='col-span-6'>
					<span className="p-float-label w-full mt-4">
						<Controller
							control={control}
							rules={{
								required: 'Debe completar Nombres y Apellidos',
							}}
							name="studentName"
							render={({field: { onChange, value, name}}) => (
									<InputText
											id="studentName"
											name={name}
											onChange={onChange}
											value={value || ""}
											className={errors?.studentName ? 'p-invalid w-full' : 'w-full'}
									/>
							)}
						/>
						<label htmlFor="studentName">Nombres y Apellidos (*) </label>
					</span>
				</div>
				<div className='col-span-6 sm:col-span-3'>
					<span className="p-float-label w-full mt-4">
						<Controller
							control={control}
							name="studentEmailAddress"
							render={({field: { onChange, value, name}}) => (
									<InputText
											id="studentEmailAddress"
											name={name}
											onChange={onChange}
											value={value || ""}
											className={errors?.studentEmailAddress ? 'p-invalid w-full' : 'w-full'}
									/>
							)}
						/>
						<label htmlFor="studentEmailAddress">E-Mail </label>
					</span>
				</div>
				<div className='col-span-6 sm:col-span-3'>
					<span className="p-float-label w-full mt-4">
						<Controller
							control={control}
							name="studentPhoneNumber"
							render={({field: { onChange, value, name}}) => (
									<InputText
											id="studentPhoneNumber"
											name={name}
											onChange={onChange}
											value={value || ""}
											className={errors?.studentPhoneNumber ? 'p-invalid w-full' : 'w-full'}
									/>
							)}
						/>
						<label htmlFor="studentPhoneNumber">Celular </label>
					</span>
				</div>
				<div className='col-span-6 sm:col-span-3'>
					<span className="p-float-label w-full mt-4">
						<Controller
							control={control}
							name="studentCountryId"
							render={({field: { onChange, value, name}}) => (
									<Dropdown
											id="studentCountryId"
											name={name}
											options={countries}  
											onChange={onChange}
											value={value}
											className={errors?.studentCountryId ? 'p-invalid w-full' : 'w-full'}
									/>
							)}
						/>
						<label htmlFor="studentCountryId">País</label>
					</span>
				</div>
				<div className='col-span-6 sm:col-span-3'>
					<span className="p-float-label w-full mt-4">
						<Controller
							control={control}
							name="birthDate"
							render={({field: { onChange, value, name}}) => (
								<Calendar
								monthNavigator
								yearNavigator
								yearRange="1950:2050"
								showIcon
								id="birthDate"
								value={value}
								maxDate={new Date()}
								onChange={onChange}
								className={errors?.birthDate ? 'p-invalid w-full' :'w-full'}
								/>
							)}
						/>
						<label htmlFor="birthDate">Nacimiento</label>
					</span>
				</div>
				<div className='col-span-6'>
					<span className="p-float-label w-full mt-4">
						<Controller
							control={control}
							name="studentHomeAddress"
							render={({field: { onChange, value, name}}) => (
									<InputText
											id="studentHomeAddress"
											name={name}
											onChange={onChange}
											value={value || ""}
											className={errors?.studentHomeAddress ? 'p-invalid w-full' : 'w-full'}
									/>
							)}
						/>
						<label htmlFor="studentHomeAddress">Dirección </label>
					</span>
				</div>
				<div className='col-span-6 sm:col-span-3'>
					<span className="p-float-label w-full mt-4">
						<Controller
							control={control}
							name="studentAcademicDegree"
							render={({field: { onChange, value, name}}) => (
									<InputText
											id="studentAcademicDegree"
											name={name}
											onChange={onChange}
											value={value || ""}
											className={errors?.studentAcademicDegree ? 'p-invalid w-full' : 'w-full'}
									/>
							)}
						/>
						<label htmlFor="studentAcademicDegree">Grado Académico </label>
					</span>
				</div>
				<div className='col-span-6 sm:col-span-3'>
					<span className="p-float-label w-full mt-4">
						<Controller
							control={control}
							name="studentProfession"
							render={({field: { onChange, value, name}}) => (
									<InputText
											id="studentProfession"
											name={name}
											onChange={onChange}
											value={value || ""}
											className={errors?.studentProfession ? 'p-invalid w-full' : 'w-full'}
									/>
							)}
						/>
						<label htmlFor="studentProfession">Profesión </label>
					</span>
				</div>
				<div className='col-span-6'>
					<span className="p-float-label w-full mt-4">
						<Controller
							control={control}
							name="studentAcademicInstitution"
							render={({field: { onChange, value, name}}) => (
									<InputText
											id="studentAcademicInstitution"
											name={name}
											onChange={onChange}
											value={value || ""}
											className={errors?.studentAcademicInstitution ? 'p-invalid w-full' : 'w-full'}
									/>
							)}
						/>
						<label htmlFor="studentAcademicInstitution">Isntituto de Procedencia </label>
					</span>
				</div>
				<div className='col-span-6'>
					(*) Datos que irán en el certificado
				</div>
				<h1 className={`background-title-form-registration text-white px-3 font-semibold col-span-6`}>DATOS LABORALES (**)</h1>
				<div className='col-span-6'>
					<span className="p-float-label w-full mt-4">
						<Controller
							control={control}
							name="studentEmployerName"
							render={({field: { onChange, value, name}}) => (
									<InputText
											id="studentEmployerName"
											name={name}
											onChange={onChange}
											value={value || ""}
											className={errors?.studentEmployerName ? 'p-invalid w-full' : 'w-full'}
									/>
							)}
						/>
						<label htmlFor="studentEmployerName">Nombre de la Empresa </label>
					</span>
				</div>
				<div className='col-span-6'>
					<span className="p-float-label w-full mt-4">
						<Controller
							control={control}
							name="studentJobPosition"
							render={({field: { onChange, value, name}}) => (
									<InputText
											id="studentJobPosition"
											name={name}
											onChange={onChange}
											value={value || ""}
											className={errors?.studentJobPosition ? 'p-invalid w-full' : 'w-full'}
									/>
							)}
						/>
						<label htmlFor="studentJobPosition">Cargo </label>
					</span>
				</div>
				<div className='col-span-6'>
					(**) Trabajo actual o último donde se desempeño
				</div>
				<div className='col-span-6'>
					<div className="flex mt-4">
						<Controller
							control={control}
							name="generateBill"
							render={({field: { onChange, value, name}}) => (
								<Checkbox
									inputId="generateBill"
									name={name}
									onChange={onChange}
									checked={value}
									// className={errors?.generateBill ? 'p-invalid w-full' : 'w-full'}
								/>
							)}
						/>
						<div className="ml-2">
							<label htmlFor="generateBill" style={{color: "#ced4da"}}>Deseo factura </label>
						</div>
					</div>
				</div>
				<div className='col-span-6'>
					<div className="flex mt-4">
						<Controller
							control={control}
							name="termsAndConditionAccepted"
							rules={{
								required: 'Debe aceptar los Términos y Condiciones',
							}}
							render={({field: { onChange, value, name}}) => (
								<Checkbox
									inputId="termsAndConditionAccepted"
									name={name}
									onChange={onChange}
									checked={value}
									className={errors?.termsAndConditionAccepted ? 'p-invalid' : ''}
								/>
							)}
						/>
						<div className="ml-2">
							<label htmlFor="termsAndConditionAccepted" style={{color: "#ced4da"}}>Declaro conocer y aceptar las condiciones establecidas en el Anexo: "Condiciones de Inscripción para actividades de Educación Ejecutiva" (*) </label>
						</div>
					</div>
				</div>
				<div className='col-span-6'>
				{
					(Object.keys(errors).length > 0) && 
						<div className='message error'>
							<ul>
									{
										Object.keys(errors).map((item, index)=>{return(
											<li key={index} className="p-error">{errors[item].message}</li>
										)})
									}      
							</ul>
						</div>
				}
				</div>
				<div className='col-span-6 flex justify-end gap-4'>
					<Button
						loading={isLoading}
						icon="pi pi-save"
						type="button"
						label="Enviar"
						onClick={handleSubmit(submit)}
						className="sig-button sig-primary mt-4"
					/>
				</div>
			</form>
			</div>
			</div>
			}
			<Toast ref={toast}></Toast>
		</div>
		
		
		);
	}
};

