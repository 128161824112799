import React, { useEffect, useState } from 'react'
import { CoreConstants } from 'core/commons/CoreConstants';
import { SurveyAction } from 'features/survey/store/actions/SurveyAction';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog'
import { Divider } from 'primereact/divider';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import SurveyAnalysisModalChart from './SurveyAnalysisModalChart';
import { useSelector } from 'react-redux';
const SurveyAnalysisModalTotalQuestion = ({displayModal, setDisplayModal, surveyTitle}) => {

  const params = useParams();
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const { data: surveyDataTotal } = useSelector((state) => state.surveyReducer.surveyDataTotal);

  const onHide = () => {
    dispatch(SurveyAction.resetAfterCloseModalAnalysis());
    setDisplayModal(false)
  }

  useEffect(() => {
    setToDate(moment().format())       
  }, [])

  useEffect(() => {
    if (toDate && displayModal) {
      dispatch(SurveyAction.getSurveyTotalByQuestion(params.id, fromDate, toDate));
    }
  }, [fromDate, toDate, params.id, dispatch, displayModal]);

  const onDownloadSurveyTotalByQuestion = () => {
    // console.log(params.id, fromDate, toDate)
    dispatch(SurveyAction.generateSurveyTotalByQuestionReport(params.id, fromDate, toDate));
  }
  
  return (
    <Dialog header='Totales por pregunta' visible={displayModal} className='w-4/5 lg:w-3/5' onHide={onHide}>
      <Divider />
      <div className='grid grid-cols-12 gap-x-4'>
        <div className='col-span-10 flex flex-col gap-y-0'>
          <h1 className='font-semibold text-lg'>{surveyTitle}</h1>
          <Divider />
          <div className='flex justify-evenly items-center gap-x-3'>
            <Calendar
                monthNavigator
                yearNavigator
                placeholder='Desde'
                yearRange="1950:2050"
                // showIcon={true}
                dateFormat={CoreConstants.Format.ddmmyy}
                value={fromDate === null ? '' : new Date(fromDate)}
                name="from"
                onChange={(e) => setFromDate(e.value)}
                className='w-full indicator-calendar flex-1'
            ></Calendar>
            <Calendar
                monthNavigator
                yearNavigator
                yearRange="1950:2050"
                placeholder='Hasta'
                // showIcon={true}
                dateFormat={CoreConstants.Format.ddmmyy}
                value={toDate === null ? '' : new Date(toDate)}
                name="to"
                minDate={fromDate === null ? '' : new Date(fromDate)}
                onChange={(e) => setToDate(e.value)}
                className='w-auto indicator-calendar flex-1'
            ></Calendar>
          </div>
          {/* PREGUNTAS */}
          <div className='flex flex-col'>
            {
              surveyDataTotal?.map((question) => (
                <>
                  <Divider />
                  <div className='flex flex-col gap-y-2'>
                    {/* <h1 className='font-semibold text-lg'>{question.questionName}</h1> */}
                    {(question.sectionTitle && question.sectionTitle !== "") &&
                      <div style={{background: "#f3f0f0"}}>
                        <h1 className="inline font-semibold text-lg">{ question.sectionTitle }</h1><span>{question.sectionDescription && question.sectionDescription !== "" ? ` - ${question.sectionDescription}`: ""}</span>
                      </div>
                    }
                    <div dangerouslySetInnerHTML={{ __html: question.questionName }} className='font-semibold text-lg' />
                    {/* <h1 className='font-semibold text-lg'>{question.questionName}</h1> */}
                    {
                      question.code !== 'TX' ? (
                        <SurveyAnalysisModalChart typeModal='totalQuestion' labels={question?.values.labels} data={question?.values.values} />
                      ) : (
                        <div className='text-sm flex flex-col gap-y-1'>
                          {
                            question?.values.labels.map((text, index) => (
                              <div className='flex gap-x-2'>
                                <span className='font-semibold'>Respuesta {index + 1}: </span>
                                <p>{text}</p>
                              </div>
                            ))
                          }
                        </div>
                      )
                    }
                  </div>
                </>
              ))
            }
          </div>
        </div>
        <div className='col-span-2 flex justify-center'>
            <div className=''>
                <Button
                  type="button"
                  className='sig-button sig-success' 
                  label="Descargar" 
                  icon="pi pi-download" 
                  onClick={onDownloadSurveyTotalByQuestion}
                />
            </div>
        </div>
      </div>
    </Dialog>
  )
}

export default SurveyAnalysisModalTotalQuestion