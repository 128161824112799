import useProjectExpenses from 'features/projects/hooks/ProjectExpenses/useProjectExpenses';
import { ProjectExpensesAction } from 'features/projects/store/actions/ProjectExpensesActions';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import HeaderEditor from 'shared/components/header-editor/HeaderEditor';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Skeleton } from 'primereact/skeleton';
import { Controller } from 'react-hook-form';
import { Editor } from 'primereact/editor';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { CoreConstants } from 'core/commons/CoreConstants';
import { TableCommentsComponent } from 'shared/components/comments/TableCommentsComponent';
import Attachment from 'features/projects/components/ProjectExpenses/Attachment';
import './ProjectExpensesPage.scss'
import { getDynamicFields, getDynamicFieldsList } from 'shared/utils/getDynamicFields';
import { ProjectExpensesConstants } from 'features/projects/commons/ProjectExpensesConstants';
import { DynamicFieldList } from 'shared/components/dynamicFields/DynamicFieldList';

const Field = ({ name, row, updateExpense, paymentModes, onBlurAmount }) => {
  if (name === 'amount') {
      return (
          <InputText 
              type='number'
              placeholder='Monto *'
              name={name}
              // value={row[name].toFixed(2)}
              value={row[name]}
              className='text-right'
              onChange={(e) => {
                  updateExpense({
                      id: row.id,
                      value: e.target.value,
                      name: name,
                  })}
              }
              onBlur={() => onBlurAmount(row.id)}
              step='0.01'
          />
      )
  } else if (name === 'paymentDate') {
    return (
      <Calendar
          monthNavigator
          yearNavigator
          yearRange="1950:2050"
          showIcon={true}
          dateFormat={CoreConstants.Format.ddmmyy}
          value={row[name] === null ? null : new Date(row[name])}
          name={name}
          className='w-full'
          placeholder='Fecha *'
          onChange={(e) => {
              updateExpense({
                  id: row.id,
                  value: e.value,
                  name: name,
              })}
          }
      ></Calendar>
  )
  } else if (name === 'paymentMode') {
    return <Dropdown 
      options={paymentModes} 
      placeholder="Modo *" 
      optionValue='id' 
      optionLabel="description" 
      value={row[name]}
      onChange={(e) => {
        updateExpense({
          id: row.id,
          value: e.value,
          name: name,
        })}
      }/>
  } 
};

const ProjectExpensesPage = ({title, mode}) => {
  const { updateTitle } = useSetTitlePage();
  const history = useHistory();
  const params = useParams();
  const isNewExpense = params?.id ? false : true
  const dispatch = useDispatch();

  const projectExpense = useSelector((state) => state.projectExpensesReducer.projectExpense);
  const {data: dataProjectExpense} = useSelector((state) => state.projectExpensesReducer.projectExpense);
  const { categories, paymentModes, types, projects, dynamicFields, isLoading: dataMasterIsLoading } = useSelector((state) => state.projectExpensesReducer.dataMaster);
  const { data: commentsData, isLoading: isLoadingComments } = useSelector(state => state.projectExpensesReducer.comments);
  const { isDeleted: isDeletedComment, isSaved: isSavedComment  } = useSelector(state => state.projectExpensesReducer.comment);
  const [visible, setVisible] = useState(false);
  const [visibleClose, setVisibleClose] = useState(false);
  const [isFirstLoading, setFirstLoading] = useState(true);
  const [idDelete, setIdDelete] = useState(0);
  const [displayModal, setDisplayModal] = useState(false)
  const [dataRowExpense, setDataRowExpense] = useState(null)
  const [isSended, setIsSended] = useState(false)

  const {
    control, errors, valuesFields, setValue, handleSubmit,
    existError, validateLength,
    expensesList, setExpensesList, expenseError, setExpenseError,
    createExpense, deleteExpense, updateExpense, onBlurAmount,
    iconTypeOfFileTable,
    existRowEmpty
  } = useProjectExpenses();
  // console.log(valuesFields)
  const dynamicFieldsInformacionGasto = getDynamicFields(dynamicFields, ProjectExpensesConstants.SectionsExpensesProjectsName.INFORMACION_DEL_GASTO)
  const dynamicFieldsDatosGasto = getDynamicFields(dynamicFields, ProjectExpensesConstants.SectionsExpensesProjectsName.DATOS_DEL_GASTO)
  // console.log(valuesFields)
  
  const onHide = () => {
    // setValue("name", ''); 
    // reset(defaultValues)
    // setListQuestion([])
    dispatch(ProjectExpensesAction.resetAfterSaved());
  }

  const redirectToSearch = () => {
    history.push("/proyecto/gastos/");
    onHide()
  }

  const reject = () => {
    setVisible(false);
  };

  const header = HeaderEditor();

  useEffect(()=> {
    updateTitle({
      title: 'Gestión de Proyectos',
      subtitle: 'Gastos de Proyecto',
      description: title,
      previousUrl: "/proyecto/gastos/"
    })
    if (isFirstLoading) {
      setFirstLoading(false);
    }
    if (!params.id) onHide()
    if (params.id) {
      dispatch(ProjectExpensesAction.getProjectExpenseById(params.id))
    }

    return () => {
      onHide()
    }
  }, [params.id])

  const getProyectId = (projectId) => {
    const projectFounded = projects.find(project => project.id === projectId);
    setValue('client', projectFounded?.customerName);
    return projectFounded
  }

  const getPaymentsRows = (payments) => {
    const modelPayments = payments.map((payment) => {
      const newPayment = {
        id: payment.id,
        paymentMode: payment.modeId,
        paymentDate: payment.expenseDate,
        amount: payment.amount.toFixed(2),
        file: 'payment.attachment',
        // file: payment.attachment,
        attachmentId: payment.attachmentId,
        projectExpenseId: payment.projectExpenseId,
      }
      
      return newPayment
    })
    return modelPayments
  }

  useEffect(() => {
    if (dataProjectExpense) {
        setValue("category", dataProjectExpense.categoryId); 
        setValue("type", dataProjectExpense.expenseTypeId); 
        setValue("description", dataProjectExpense.description) ;  
        setValue("initialDate", dataProjectExpense.from ? new Date(dataProjectExpense.from) : null); 
        setValue("endDate", dataProjectExpense.to ? new Date(dataProjectExpense.to) : null); 
        // setValue("consultantName", dataProjectExpense.instructorName); 
        setValue("total", Number(dataProjectExpense.totalAmount).toFixed(2));
        setValue("createdBy", dataProjectExpense.creationUser); 
        setValue("creationDate", dataProjectExpense.creationDate ? new Date(dataProjectExpense.creationDate) : ''); 
        setValue("updatedBy", dataProjectExpense.modificationUser); 
        setValue("updateDate", dataProjectExpense.updateDate ? new Date(dataProjectExpense.updateDate) : ''); 

        setExpensesList(getPaymentsRows(dataProjectExpense.payments));

        if(dataProjectExpense.dynamicFieldValues?.length > 0){
          dataProjectExpense.dynamicFieldValues.forEach( dynamicFieldValue => {
            setValue(`dynamicFieldData:${dynamicFieldValue.identifier}`, dynamicFieldValue.value)
          })
        }
    }
  }, [dataProjectExpense]);

  useEffect(() => {
    if (dataProjectExpense) {
      setValue("project", getProyectId(dataProjectExpense.project.id)); 
    }
  }, [dataProjectExpense, projects]);

  useEffect(() => {
    if (projectExpense?.isSaved) redirectToSearch();
  }, [projectExpense]);

  useEffect(() => {
    dispatch(ProjectExpensesAction.getDataMaster());
  }, [])

  const columns = [
    { field: 'paymentDate', header: 'Fecha *' },
    { field: 'paymentMode', header: 'Modo *' },
    { field: 'amount', header: 'Monto *' },
    { field: 'attachmentDocument', header: 'Documento' },
  ];

  const onSelectRowEspense = (name, row) => {
    setDataRowExpense({ name, row })
    setDisplayModal(true)
  }

  //TODO: Si existe el url(file) mostrarlo sino el nombre del archivo recien creado
  const FieldFile = ({ name, row }) => {
      return (
        <>
          {
            row.file
            ? (
              <div className='flex gap-x-1 p-inputgroup  '>
              <InputText
                  // onChange={onChange}
                  name={name}
                  // value={row.file}
                  value={row.attachmentDocument ? row.attachmentDocument?.name  : 'Archivo.pdf'}
                  readOnly
                  placeholder='Documento'
                  className='disabled'
              />
              <span 
                className="p-inputgroup-addon p-0 cursor-pointer hover:text-gray-800 hover:bg-gray-200">
                  <i className="pi pi-paperclip text-xl" onClick={() => onSelectRowEspense(name, row)} ></i>
              </span>
            </div>
              )
            : (
                <div className='flex gap-x-1 p-inputgroup  '>
                  <InputText
                      // onChange={onChange}
                      name={name}
                      value={row[name]?.name}
                      className='disabled'
                      readOnly
                      placeholder='Documento'
                  />
                  <span 
                    className="p-inputgroup-addon p-0 cursor-pointer hover:text-gray-800 hover:bg-gray-200">
                      <i className="pi pi-paperclip text-xl" onClick={() => onSelectRowEspense(name, row)} ></i>
                  </span>
                </div>
            )
          }
          
        </>
    )
  };

  const ColumnComponents = columns.map((col) => {
    if (col.field === 'paymentDate' || col.field === 'paymentMode' || col.field === 'amount') {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                sortable={col.field === 'paymentDate' || col.field === 'paymentMode' ? true : false}
                style={{width: '23%', minWidth: col.field === 'amount' ? '120px':'140px'}}
                body={(row) => <Field name={col.field} row={row} updateExpense={updateExpense} paymentModes={paymentModes} onBlurAmount={onBlurAmount} />}
            />
        );
    }
    if (col.field === 'attachmentDocument') {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                style={{width: '23%', minWidth:'120px'}}
                body={(row) => <FieldFile name={col.field} row={row} updateExpense={updateExpense} />}
            />
        );
    }
    
    return <Column key={col.field} field={col.field} header={col.header} />;
  });

  const deletePermissionConfirm = () => {
    deleteExpense(idDelete);
  };

  const RowDelete = ({ id }) => {
    return (
        <i
          onClick={() => {
              setVisible(true);
              setIdDelete(id);
          }}
          className="pi pi-trash cursor-pointer text-center text-lg"
        ></i>
    );
  };

  const onChangeProject = (onChange, e) => {
    onChange(e.value)
    setValue('client', e.value.customerName)
  }

  const getModelPayments = (payments) => {
    const modelPayments = payments.map((payment) => {
      const {id, fileBase64, file, ...modelPayment} = payment
      modelPayment.amount = Number(payment.amount)
      if (params.id) {
        modelPayment.projectExpenseId = Number(params.id)
        if (!isNaN(id)) modelPayment.id = id
      }
      // modelPayment.attachmentDocument = fileBase64 ? fileBase64 : file
      modelPayment.attachmentDocument = fileBase64 ? fileBase64 : null
        
      return modelPayment
    })
    return modelPayments
  }

  const onSubmit = (data) => {

    const dynamicDataValues = getDynamicFieldsList(data, dynamicFields, isNewExpense, dataProjectExpense)

    setIsSended(true)
    const { isRowEmpty, errorRows } = existRowEmpty(expensesList)
    if (isRowEmpty) {
      setExpenseError(errorRows)
      return
    }
    const model = {
      projectId: data.project.id,
      category: data.category,
      expenseType: data.type,
      description: data.description,
      from: data.initialDate,
      to: data.endDate,
      // coachName: data.consultantName,
      totalAmount: Number(data.total),
      payments: getModelPayments(expensesList),
      dynamicDataValues
    }
    setExpenseError([])

    if (params.id) {
      model.id = Number(params.id)
      dispatch(ProjectExpensesAction.saveProjectExpense(model));
      // console.log(model)
      return
    }
    dispatch(ProjectExpensesAction.saveProjectExpense(model));
  };

  return (
    <div className="bg-white">
      <form 
        className="grid grid-cols-12 gap-x-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form-modal p-0 col-span-12 md:col-span-9 2xl:col-span-10">
          <Panel header="INFORMACIÓN DEL GASTO" toggleable>
            <div className="">
              <div className='flex justify-between gap-x-4 my-4'>
                  {projectExpense.isLoading ? (
                      <Skeleton />
                    ): (
                      <span className="p-float-label w-full md:w-1/2 ">
                          <Controller
                              control={control}
                              rules={{required: 'El proyecto es requerido'}}
                              render={({
                                  field: { onChange, value },
                              }) => (
                                <Dropdown
                                    options={projects}
                                    optionLabel="name"
                                    value={value}
                                    onChange={(e) => onChangeProject(onChange, e)}
                                    className={errors?.project ? 'p-invalid w-1/2' : 'w-1/2'}
                                />
                                
                              )}
                              name="project"
                          />
                          <label htmlFor="project">Seleccione Proyecto: *</label>
                      </span>
                  )}
                  {projectExpense.isLoading ? (
                    <Skeleton />
                  ): (
                    <span className="p-float-label w-full md:w-1/2">
                        <Controller
                            control={control}
                            render={({
                                field: { onChange, value, onBlur },
                            }) => (
                              <InputText
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                  className='w-full disabled'
                                  disabled
                              />
                              
                            )}
                            name="client"
                        />
                        <label htmlFor="client">Cliente:</label>
                    </span>
                )}
              </div>
              {projectExpense.isLoading ? (
                  <Skeleton />
                ): (
                  <span className="p-float-label w-full md:w-1/2 my-5 pr-0 md:pr-2">
                      <Controller
                          control={control}
                          rules={{required: 'La categoría es requerida'}}
                          render={({
                              field: { onChange, value },
                          }) => (
                            <Dropdown
                                options={categories}
                                optionValue="id"
                                optionLabel="description"
                                value={value}
                                onChange={onChange}
                                className={errors?.category ? 'p-invalid w-1/2' : 'w-1/2'}
                            />
                            
                          )}
                          name="category"
                      />
                      <label htmlFor="category">Seleccione Categoría: *</label>
                  </span>
              )}
              {projectExpense.isLoading ? (
                  <Skeleton />
                ): (
                  <span className="p-float-label w-full md:w-1/2 my-4 pr-0 md:pr-2">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, value },
                          }) => (
                            <Dropdown
                                options={types}
                                optionLabel="description"
                                optionValue="id"
                                value={value}
                                onChange={onChange}
                                showClear
                                className={errors?.type ? 'p-invalid w-1/2' : 'w-1/2'}
                            />
                            
                          )}
                          name="type"
                      />
                      <label htmlFor="type">Seleccione Tipo:</label>
                  </span>
              )}
              {projectExpense.isLoading ? (
                <Skeleton />
                  ): (
                    <span className="p-float-label w-full mt-5 mb-2">
                        <Controller
                            control={control}
                            rules={{required: 'La descripción es requerida'}}
                            render={({
                                field: { onChange, value },
                            }) => (
                              <>
                                <Editor
                                    headerTemplate={header}
                                    className={errors?.description ? 'p-invalid w-full survey-editor' : 'w-full survey-editor'}
                                    value={value} onTextChange={(e) => onChange(e.htmlValue)}
                                    placeholder="Descripción *"
                                />
                                <p className="font-semibold text-base text-gray-500 lg:mt-1 mt-3 text-right">
                                    Caracteres{' '}
                                    {validateLength(valuesFields.description)}{' '}/ 4000{' '}
                                </p>
                            </>
                            )}
                            name="description"
                        />
                    </span>
                )}
            </div>
            <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-5">
                {
                    (!projectExpense.isLoading && !dataMasterIsLoading) &&
                    <DynamicFieldList control={control} fields={dynamicFieldsInformacionGasto} />
                }
            </div>   
          </Panel>
          <Panel header="DATOS DEL GASTO" toggleable>
            <div>
              <div className='flex justify-between gap-x-4 mt-3 mb-4 w-full'>
                  {projectExpense.isLoading ? (
                      <Skeleton />
                    ): (
                      <div className='w-full'>
                        <span className="p-float-label w-full lg:w-1/2">
                            <Controller
                                control={control}
                                render={({
                                    field: { onChange, value },
                                }) => (
                                  <Calendar
                                    monthNavigator
                                    yearNavigator
                                    yearRange="1950:2050"
                                    showIcon={true}
                                    dateFormat={CoreConstants.Format.ddmmyy}
                                    value={value}
                                    onChange={onChange}
                                    className='w-full'
                                />
                                  
                                )}
                                name="initialDate"
                            />
                            <label htmlFor="initialDate">Fecha Inicio:</label>
                        </span>
                      </div>
                  )}
                  {projectExpense.isLoading ? (
                    <Skeleton />
                  ): (
                    <div className='w-full'>
                      <span className="p-float-label w-full lg:w-1/2">
                          <Controller
                              control={control}
                              render={({
                                  field: { onChange, value, onBlur },
                              }) => (
                                <Calendar
                                    monthNavigator
                                    yearNavigator
                                    yearRange="1950:2050"
                                    showIcon={true}
                                    dateFormat={CoreConstants.Format.ddmmyy}
                                    minDate={valuesFields?.initialDate}
                                    value={value}
                                    onChange={onChange}
                                    className='w-full'
                                />
                                
                              )}
                              name="endDate"
                          />
                          <label htmlFor="endDate">Fecha Fin</label>
                      </span>
                    </div>
                )}
              </div>
              {projectExpense.isLoading ? (
                    <Skeleton />
                  ): (
                    <span className="p-float-label w-full sm:w-1/2 lg:w-1/4 mt-8 mb-2 pr-0 sm:pr-2 ">
                        <Controller
                            control={control}
                            rules={{required: 'El monto total a pagar es requerido'}}
                            render={({
                                field: { onChange, value, onBlur },
                            }) => (
                              <InputText
                                  type='number'
                                  onChange={onChange}
                                  onBlur={ () => setValue('total', Number(valuesFields.total).toFixed(2))}
                                  value={value}
                                  className={errors?.total ? 'p-invalid w-full text-right' : 'w-full text-right'}
                                  step='0.01'
                              />
                            )}
                            name="total"
                        />
                        <label htmlFor="total">Monto Total a pagar: *</label>
                    </span>
                )}
            </div>
            <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-1">
                {
                    (!projectExpense.isLoading && !dataMasterIsLoading) &&
                    <DynamicFieldList control={control} fields={dynamicFieldsDatosGasto} />
                }
            </div>   
          </Panel>
          {
            params.id && (
              <Panel header="COMENTARIOS" toggleable>
                <TableCommentsComponent 
                    enableComments={true}
                    sourceId={params.id}
                    comments={commentsData} //TODO: Falta el endpoint de comentarios
                    Action={ProjectExpensesAction}
                    isDeletedComment={isDeletedComment} 
                    isSavedComment={isSavedComment} 
                    isLoadingComments={isLoadingComments} 
                    sourceCode={CoreConstants.Task.Source.EXPENSE} 
                />
              </Panel>
            )
          }
          <Panel header="INFORMACIÓN DE LOS GASTOS" toggleable>
          <div className="">
              <div className=''>
                  <Button
                      icon="pi pi-plus"
                      type="button"
                      label="Añadir Gasto"
                      onClick={() => {
                        createExpense()
                        // setIsSended(false)
                      }}
                      className="sig-button sig-primary mt-2 mb-4"
                  />
              </div>
              {
                <div className="proyect-expenses-table mb-3">
                    <DataTable
                        value={expensesList}
                        emptyMessage="No se encontraron gastos del proyecto"
                        scrollable scrollHeight="320px" loading={projectExpense.isLoading}
                        stripedRows
                        editMode="row"
                        dataKey="id"
                        rowHover
                        size="small"
                        responsiveLayout="scroll"
                        className='text-center'
                        // tableStyle={{ minWidth: '50rem' }}
                    >
                        {ColumnComponents}
                        <Column header={<div></div>} body={RowDelete} style={{width: '8%'}}></Column>
                    </DataTable>
                </div>
              }
              <div className='flex justify-center'>
                {
                  (expenseError.length > 0 && isSended) && (
                    <div className='message error w-full'>
                      {
                        expenseError.map((error) => (
                          <li className="p-error">{`Fila ${error.errorRow}: Debe ingresar ${error.errorField}`}</li>
                        ))
                      }
                    </div>
                  )
                }
              </div>
          </div>
          {
            (existError) && <div className='message error'>
              <ul>
                {errors.project ? (
                    <>
                        <li className="p-error">{errors.project.message}</li>
                    </>
                ) : null}
                {errors.category ? (
                    <>
                        <li className="p-error">{errors.category.message}</li>
                    </>
                ) : null}
                {errors.description ? (
                    <>
                        <li className="p-error">{errors.description.message}</li>
                    </>
                ) : null}
                {errors.total ? (
                    <>
                        <li className="p-error">{errors.total.message}</li>
                    </>
                ) : null}
              </ul>
            </div>
          }
          <div className="flex justify-end gap-4 mt-1">
              <Button
                  icon="pi pi-times"
                  type="button"
                  label="Cancelar"
                  onClick={() => setVisibleClose(true)}
                  className="sig-button sig-secondary mt-4"
              />
              {
                (mode!=='view')&&(
                  <Button
                      icon="pi pi-save"
                      type="submit"
                      // disabled={existError ? true : false}
                      label="Guardar"
                      loading={projectExpense?.isSaving || projectExpense?.isLoading}
                      className="sig-button sig-primary mt-4"
                  />
                )
              }
          </div>
          </Panel>
        </div>
        <div className='col-span-12 md:col-span-3 2xl:col-span-2 order-first md:order-none'>
          <Panel header="DATOS GENERALES" toggleable>
            <div className="h-full">
                {projectExpense.isLoading ? (
                  <Skeleton />
                ): (
                  <span className="p-float-label w-full mt-2">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value },
                          }) => (
                            <InputText
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                className='disabled'
                                disabled
                            />
                          )}
                          name="createdBy"
                      />
                      <label htmlFor="createdBy">Creado por:</label>
                  </span>
                )}
                {projectExpense.isLoading ? (
                    <Skeleton />
                  ): (
                    <span className="p-float-label w-full my-6">
                        <Controller
                            control={control}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                              <Calendar
                                  value={value}
                                  onChange={onChange}
                                  className='w-full disabled'
                                  disabled
                              />
                            )}
                            name="creationDate"
                        />
                        <label htmlFor="creationDate">Fecha Creación:</label>
                    </span>
                )}
                {projectExpense.isLoading ? (
                  <Skeleton />
                ): (
                  <span className="p-float-label w-full my-6">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value },
                          }) => (
                            <InputText
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                className='disabled'
                                disabled
                            />
                          )}
                          name="updatedBy"
                      />
                      <label htmlFor="updatedBy">Modificador por:</label>
                  </span>
                )}
              
                {projectExpense.isLoading ? (
                  <Skeleton />
                ): (
                  <span className="p-float-label w-full mt-6">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value },
                          }) => (
                            <Calendar
                                value={value}
                                onChange={onChange}
                                className='w-full disabled'
                                disabled
                            />
                          )}
                          name="updateDate"
                      />
                      <label htmlFor="updateDate">Fecha Modificación:</label>
                  </span>
                )}
                {/* {projectExpense.isLoading ? (
                  <Skeleton />
                ): (
                  <span className="p-float-label w-full mt-5">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value },
                          }) => (
                            <InputText
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                className='disabled'
                                disabled
                            />
                          )}
                          name="status"
                      />
                      <label htmlFor="status">Estado:</label>
                  </span>
                )} */}
                {/* <div className='flex justify-center w-full mt-7'> */}
                  {/* <Button
                    icon="pi pi-share-alt"
                    label="Compartir"
                    type='button'
                    onClick={() => openModal('share')}
                    disabled={!surveyData}
                    className="sig-button sig-success"
                  /> */}
                {/* </div> */}
              </div>
          </Panel>
        </div>
      </form>
      <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea eliminar el gasto?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={() => deletePermissionConfirm()}
          reject={() => setVisible(false)}
      />
      <Attachment 
          displayModal={displayModal}  setDisplayModal={setDisplayModal} 
          iconTypeOfFileTable={iconTypeOfFileTable}
          updateExpense={updateExpense}
          dataRowExpense={dataRowExpense}  setDataRowExpense={setDataRowExpense} 
        />
      <ConfirmDialog
        visible={visibleClose}
        onHide={() => setVisibleClose(false)}
        message="¿Está seguro que desea cancelar?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={redirectToSearch}
        reject={reject}
      />
    </div>
  )
}

export default ProjectExpensesPage