export class RrhhConstants {
  static Accion = class {
    static Departments = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return '@DEPARTAMENTS.ADVANCED-FILTER-TOGGLE';
      }

      static get SEARCH_DEPARTMENTS() {
        return '@DEPARTAMENTS.SEARCH_DEPARTMENTS';
      }

      static get UPDATE_FILTER() {
        return '@DEPARTAMENTS.UPDATE_FILTER';
      }

      static get GET_DEPARTMENT_BY_ID() {
        return '@DEPARTAMENTS.GET_DEPARTMENT_BY_ID';
      }

      static get SAVE_DEPARTMENT() {
        return '@DEPARTAMENTS.SAVE_DEPARTMENT';
      }

      static get DELETE_DEPARTMENT() {
        return '@DEPARTAMENTS.DELETE_DEPARTMENT';
      }

      static get GET_DATA_MASTER() {
        return '@DEPARTAMENTS.GET_DATA_MASTER';
      }

      static get RESET_AFTER_SAVED() {
        return '@DEPARTAMENTS.RESET_AFTER_SAVED';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@DEPARTAMENTS.DOWNLOAD_REPORT_STATUS';
      }


    };
    static Positions = class {
      static get SEARCH_POSITIONS() {
        return '@POSITIONS.SEARCH-POSITIONS';
      }
      static get ADVANCED_FILTER() {
        return '@POSITIONS.ADVANCED-FILTER';
      }
      static get REPORT_STATUS() {
        return '@POSITIONS.REPORT-STATUS';
      }
      static get CURRENT_ACTION() {
        return '@POSITIONS.CURRENT-ACTION';
      }
      static get EDIT_POSITION() {
        return '@POSITIONS.EDIT-POSITION';
      }
      static get GET_DATA_MASTER() {
        return '@POSITIONS.GET-DATA-MASTER';
      }
    };
    static Employees = class {
      static get SEARCH_EMPLOYEES() {
        return '@EMPLOYEES.SEARCH_EMPLOYEES';
      }
      static get REMOVE_FILTER_VALUES() {
        return '@EMPLOYEES.REMOVE_FILTER_VALUES';
      }
      static get SET_FILTER_VALUES() {
        return '@EMPLOYEES.SET_FILTER_VALUES';
      }
      static get ADVANCED_FILTER_TOGGLE() {
        return '@EMPLOYEES.ADVANCED-FILTER-TOGGLE';
      }
      static get MODALS() {
        return '@EMPLOYEES.MODALS';
      }
      static get DATA_MASTER() {
        return '@EMPLOYEES.DATA_MASTER'
      }
      static get EDIT_EMPLOYEE() {
        return '@EMPLOYEES.EDIT_EMPLOYEES';
      }
      static get DOWNLOAD_TEMPLATE() {
        return '@EMPLOYEES.DOWNLOAD_TEMPLATE';
      }
      static get UPLOAD_BATCH_INFO() {
        return '@EMPLOYEES.UPLOAD_BATCH_INFO';
      }
      static get RESET_AFTER_SAVED() {
        return '@EMPLOYEES.RESET_AFTER_SAVED';
      }
      static get SAVE_EMPLOYEES_BULK() {
        return '@EMPLOYEES.SAVE_EMPLOYEES_BULK';
      }
      static get GET_TYPE_PROCESS() {
        return 'rrhh';
      }
    };

    static CustomField = class {
      static get GET_LAST_FILE_PEOPLE() {
        return '@CUSTOM_FIELD.GET_LAST_FILE_PEOPLE';
      }
      static get LIST_CONTROLS() {
        return '@CUSTOM_FIELD.LIST_CONTROLS';
      }
      static get LIST_RESPONSIBLES() {
        return '@CUSTOM_FIELD.LIST_RESPONSIBLES';
      }
      static get SAVE_FILE_PEOPLE() {
        return '@CUSTOM_FIELD.SAVE_FILE_PEOPLE';
      }
      static get GET_VERSION_LIST() {
        return '@CUSTOM_FIELD.GET_VERSION_LIST';
      }
      static get GENERATE_VERSION() {
        return '@CUSTOM_FIELD.GENERATE_VERSION';
      }
      static get GET_FILE_PEOPLE_BY_ID() {
        return '@CUSTOM_FIELD.GET_FILE_PEOPLE_BY_ID';
      }
    }

    static OrganizationChart = class {
      static get GET_NODE_BY_ID() {
        return '@ORGANIZATION_CHART.GET_NODE_BY_ID';
      }

      static get GET_ORGANIZATION_CHART() {
        return '@ORGANIZATION_CHART.GET_ORGANIZATION_CHART';
      }

      static get SAVE_NODE() {
        return '@ORGANIZATION_CHART.SAVE_NODE';
      }

      static get SAVE_COMPANY_AREA() {
        return '@ORGANIZATION_CHART.SAVE_COMPANY_AREA';
      }

      static get DELETE_POSITION() {
        return '@ORGANIZATION_CHART.DELETE_POSITION';
      }

      static get GET_DATA_MASTER() {
        return '@ORGANIZATION_CHART.GET_DATA_MASTER';
      }

      static get RESET_AFTER_SAVED() {
        return '@ORGANIZATION_CHART.RESET_AFTER_SAVED';
      }

      static get GET_COMPANY_AREA_PARENTS() {
        return '@ORGANIZATION_CHART.GET_COMPANY_AREA_PARENTS';
      }

      static get DELETE_NODE() {
        return '@ORGANIZATION_CHART.DELETE_NODE';
      }

    };
  };

  static SectionsEmployeeName = class {
    static get DATOS_GENERALES() {
      return 'DATOS GENERALES';
    }
    static get INFORMACION_PERSONAL() {
      return 'INFORMACIÓN PERSONAL';
    }
    static get INFORMACION_LABORAL() {
      return 'INFORMACIÓN LABORAL';
    }
  }
}