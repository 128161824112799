import { TableListPayments } from 'features/coursesManagment/components/paymentCouses/TableListPayments';
import usePaymentCourse from 'features/coursesManagment/hooks/PaymentCourse/usePaymentCourse';
import { useClaimPaymentCourse } from 'features/coursesManagment/hooks/useClaimPaymentCourse';
import { PaymentCouseAction } from 'features/coursesManagment/store/actions/PaymentCourseAction';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';

export const PaymentCoursePage = ({ description, mode }) => {

  const { updateTitle } = useSetTitlePage();

  const {control,
    errors,
    valuesFields,
    existError,
    setValue,
    handleSubmit,
    validateLength,
} = usePaymentCourse()

  const [visible, setVisible] = useState(false)
  const [mnsjSendValidate, setMnsjSendValidate] = useState([])
  const [dataPayment, setDataPayment] = useState([])

  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()

  const { isCreate, isEdit } = useClaimPaymentCourse();

  const dataCoursesAndPayment = useSelector((state) => state.paymentCourseReducer.coursesAndPaymentMethod.data);
  const listStudents = useSelector((state) => state.paymentCourseReducer.students);
  const paymentSelected = useSelector((state) => state.paymentCourseReducer.studentSelected);

  useEffect(()=> {
    if(paymentSelected.isSaved){
      redirectToSearch()
    }
    if(paymentSelected.data && dataPayment.length===0 && params?.id && dataCoursesAndPayment){
      if(!listStudents.data){
        dispatch(PaymentCouseAction.getListStudents(paymentSelected.data.CourseId))
      }else{
        chargeData()
      }
    }
  },[paymentSelected,dataCoursesAndPayment,listStudents.data])


  useEffect(()=>{
    updateTitle({
      title: 'Cursos',
      subtitle: 'Pagos de Cursos',
      description: description,
      previousUrl: '/curso/pagos'
    });
    if(params?.id){
      dispatch(PaymentCouseAction.getPaymentById(params.id))
    }
    if(!dataCoursesAndPayment){
      dispatch(PaymentCouseAction.getCoursesAndPayment())
    }
  },[])

  const chargeData = () => {
    //Llenando Curso
    const currentCourse = dataCoursesAndPayment.courses.find(el => el.id===paymentSelected.data.CourseId)
    setValue('typeCourse', currentCourse)
    setValue('codeCurse', currentCourse?.code)
    setValue('dateStart', new Date(currentCourse?.initialDate))
    setValue('dateEnd', new Date(currentCourse?.endDate))

    const currentStudent = listStudents.data.find(elem => elem.studentId===paymentSelected.data.StudentId)
    //LLenando Estudiante
    setValue('nameStudent', currentStudent)
    setValue('registrationDate', new Date(currentStudent?.enrollmentDate))


    if(paymentSelected.data.Payments.length>0){
      setDataPayment(paymentSelected.data.Payments.map(elem=> {
        return {
                id: elem.Id,
                courseStudentId: elem.CourseStudentId,
                issueDate: elem.IssueDate,
                dueDate: elem.DueDate,
                paymentDate: elem.PaymentDate,
                paymentModeId: elem.PaymentModeId,
                amount: elem.Amount.toFixed(2),
                mode: "edit"
        }
      }))
    }
  }


  const onSubmit = (data) => {
    if(dataPayment.length===0){
      setMnsjSendValidate([{id: 'notFound', message: 'Debe existir un pago como mínimo.'}])
    }else{
      let errores = []
      dataPayment.map((el,index)=> {
        if(!el.issueDate){
          errores.push({id: el.id, row: "issueDate", message: `La Fecha de Emisión es requerida en la fila ${index+1}.`})
        }
        if(!el.dueDate){
          errores.push({id: el.id, row: "dueDate", message: `La Fecha de Vencimiento es requerida en la fila ${index+1}.`})
        }
        if(!el.amount){
          errores.push({id: el.id, row: "amount", message: `El Monto es requerido en la fila ${index+1}.`})
        }
        return el
      })
      setMnsjSendValidate(errores)
      if(errores.length>0){
        return
      }else{
        const dataSend = {
          courseStudentId: data.nameStudent.courseStudentId,
          courseId: data.typeCourse.id,
          studentId: data.nameStudent.studentId,
          payments: dataPayment.map(el=> {
            return {
              id: el.mode==="edit"?el.id:null,
              courseStudentId: data.nameStudent.courseStudentId,
              issueDate: el.issueDate,
              dueDate: el.dueDate,
              paymentDate: el.paymentDate ? el.paymentDate : null,
              paymentModeId: el.paymentModeId ? el.paymentModeId : null,
              amount: Number(el.amount)
            }
          })
        }
        dispatch(PaymentCouseAction.savePaymentCourse(dataSend))
      }
    }
  }

  const reject = () => {
    history.push("/curso/pagos")
  }

  const  redirectToSearch = () => {
    history.push('/curso/pagos')
  }

  const autocompleteTypeCourse = (e) => {
    setValue('codeCurse', e.value ? e.value.code : "")
    setValue('dateStart', e.value ? new Date(e.value.initialDate) : null)
    setValue('dateEnd', e.value ? new Date(e.value.endDate) : null)

  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
      <Panel header="DATOS DEL CURSO">
        <span className="p-float-label w-full sm:w-1/3">
                <p className='mb-2'>Curso *</p>
                {
                  dataCoursesAndPayment?.courses ? <Controller
                  control={control}
                  render={({
                      field: { onChange, value },
                  }) => (
                    <Dropdown
                      id="typeCourse"
                      name="typeCourse"
                      optionLabel="name"
                      value={value}
                      options={dataCoursesAndPayment?.courses}
                      onChange={(e) =>{
                        onChange(e)
                        dispatch(PaymentCouseAction.getListStudents(e.value.id))
                        autocompleteTypeCourse(e)
                      }}
                      disabled={params?.id}
                      filter
                      filterBy="name"
                      placeholder="Seleccione Curso"
                      className={`${errors?.typeCourse&&'p-invalid'} ${params?.id&&'disabled'} 'w-full'`}
                      // optionValue={(e) => {
                      //   return e.id;
                      //     }}
                        />
                  )}
                  name="typeCourse"
                  rules={{
                      required: 'El curso es requerido.',
                  }}
              /> : <Skeleton width="100%" height='2rem'/>
                }
          </span>
          <span className="p-float-label w-full sm:w-1/3 mt-4">
                <p className='mb-2'>Código</p>
                <Controller
                    control={control}
                    render={({
                        field: { onChange, value },
                    }) => (
                      <InputText
                        id="codeCurse"
                        name="codeCurse"
                        value={value}
                        onChange={onChange}
                        disabled
                        className={errors?.codeCurse ? 'p-invalid w-full disabled' : 'w-full disabled'}
                          />
                    )}
                    name="codeCurse"
                    // rules={{
                    //     required: 'El curso es requerido',
                    // }}
                />
          </span>
          <div className='flex'>
            <span className="p-float-label w-full sm:w-1/3 mt-4 mr-4">
                  <p className='mb-2'>Fecha de Inicio</p>
                  <Controller
                      control={control}
                      render={({
                          field: { onChange, value },
                      }) => (
                        <Calendar
                          value={value}
                          id="dateStart"
                          name="dateStart"
                          onChange={onChange}
                          disabled
                          className={errors?.dateStart ? 'p-invalid w-full disabled' : 'w-full disabled'}
                            />
                      )}
                      name="dateStart"
                  />
            </span>
            <span className="p-float-label w-full sm:w-1/3 mt-4">
                <p className='mb-2'>Fecha de Fin</p>
                <Controller
                    control={control}
                    render={({
                        field: { onChange, value },
                    }) => (
                      <Calendar
                      value={value}
                      id="dateEnd"
                      name="dateEnd"
                      onChange={onChange}
                      disabled
                      className={errors?.dateEnd ? 'p-invalid w-full disabled' : 'w-full disabled'}
                        />
                    )}
                    name="dateEnd"
                />
            </span>
          </div>
      </Panel>
      <Panel header="DATOS DEL ALUMNO">
        <div className='flex'>
          <span className="p-float-label w-full sm:w-1/3">
                    <p className='mb-2'>Alumno *</p>
                    {
                      listStudents.isLoading?<Skeleton width="100%" height='2rem'/>:<>
                        <Controller
                        control={control}
                        render={({
                            field: { onChange, value },
                        }) => (
                          <Dropdown
                            id="nameStudent"
                            name="nameStudent"
                            optionLabel="fullName"
                            value={value}
                            options={listStudents.data}
                            disabled={params?.id}
                            onChange={(e) =>{
                              onChange(e)
                              setValue('registrationDate', e.value ? new Date(e.value.enrollmentDate) : null)
                            }}
                            filter
                            filterBy="name"
                            placeholder="Seleccione Alumno"
                            className={`${errors?.nameStudent&&'p-invalid'} ${params?.id&&'disabled'} 'w-full'`}
                            // optionValue={(e) => {
                            //   return e.id;
                            //     }}
                              />
                        )}
                        name="nameStudent"
                        rules={{
                            required: 'El Alumno es requerido.',
                        }}
                    />
                      </>
                    }

              </span>
              <span className="p-float-label w-full sm:w-1/3 ml-4">
                <p className='mb-2'>Fecha de Matricula</p>
                <Controller
                    control={control}
                    render={({
                        field: { onChange, value },
                    }) => (
                      <Calendar
                      value={value}
                      id="registrationDate"
                      name="registrationDate"
                      onChange={onChange}
                      disabled
                      className={errors?.registrationDate ? 'p-invalid w-full disabled' : 'w-full disabled'}
                        />
                    )}
                    name="registrationDate"
                />
            </span>
        </div>
      </Panel>
      <Panel header="DATOS DEL PAGO">
          <TableListPayments
            mnsjSendValidate={mnsjSendValidate}
            setMnsjSendValidate={setMnsjSendValidate}
            dataPayment={dataPayment}
            setDataPayment={setDataPayment}/>
          {
            // (existError) && <div className='message error'>
            (errors.typeCourse || errors.nameStudent || mnsjSendValidate.length!==0 ) && <div className='message error'>
                {
                  mnsjSendValidate.length!==0&&<ul>
                    {
                      mnsjSendValidate.map((el,index)=> {
                        return <li key={index} className="p-error">{el.message}</li>
                      })
                    }
                  </ul>
                }
                <ul>
                    {errors.typeCourse ? (
                        <>
                            <li className="p-error">{errors.typeCourse.message}</li>
                        </>
                    ) : null}
                </ul>
                <ul>
                    {errors.nameStudent ? (
                        <>
                            <li className="p-error">{errors.nameStudent.message}</li>
                        </>
                    ) : null}
                </ul>
            </div>
          }
        <div className="flex justify-end gap-4 mt-3">
                      <Button
                          icon="pi pi-times"
                          type="button"
                          label="Cancelar"
                          loading={paymentSelected?.isSaving}
                          onClick={() => { setVisible(true); }}
                          className="sig-button sig-secondary mt-4"
                      />
                      {
                        (mode!=='view' && (isEdit || isCreate))&&(
                          <Button
                              icon="pi pi-save"
                              label="Guardar"
                              type='submit'
                              loading={paymentSelected?.isSaving}
                              // loading={academicDegree?.isSaving || academicDegree?.isLoading}
                              className="sig-button sig-primary mt-4"
                          />
                        )
                      }
                  </div>
        <ConfirmDialog
                  visible={visible}
                  onHide={() => setVisible(false)}
                  message="¿Está seguro que desea cancelar?"
                  header="Cancelar"
                  icon="pi pi-exclamation-triangle"
                  accept={redirectToSearch}
                  reject={reject}
              />
      </Panel>
      </form>
    </>
  )
}
