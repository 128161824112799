import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';

const useWorkflow = (processFields, workflow, setWorkflow, getTypeByCode, getTypeById, initialProcessId) => {
  
  
  let defaultValues = {
    configurations: {
        id: initialProcessId,
        roles: [],
        name: processFields?.name ? processFields?.name : 'Nombre del Proceso',
        children: [],

        code: 'Inicio__' + initialProcessId,
    },
    listSteps: [],
    finalName: 'Cierre',
    state: 'normal',
  };

  const { setValue, handleSubmit, control, watch, formState: { errors }, reset } = useForm({ defaultValues });
  
  const valuesFields = watch();
  const existError = Object.keys(errors)?.length > 0

  // console.log(valuesFields)

  const reorder = (list, newStep, indexFather) => {
    const result = Array.from(list);
    result.splice(indexFather + 1, 0, newStep);
  
    const newList = result.map((option, index) => {
      const newOption = {
        ...option,
        position: index + 1
      }
      return newOption
    })
  
    return newList;
  };

  //* Crear nuevo paso
  const createChildInFather = (step, idFather, model) => {
    if (step.id === idFather) {
      if (model) {
        model.children = step.children
        step.children = [model]
      }
      return step
    } else {
      if (step.children) {
        if (step.childrenBranch) {
          for (let j = 0; j < step.childrenBranch.length; j++) {
            const result = createChildInFather(step.childrenBranch[j], idFather, model)
          }
        }
        for (let i = 0; i < step.children.length; i++) {
          const result = createChildInFather(step.children[i], idFather, model)
          // if (result) {
          //   if (model) {
          //     model.children = result.children
          //     result.children = [model]
          //   }
          //   return result
          // }
        }
      }
    }
  }

  const isDecision = (step) => {
    const prefixStep = step.code.split('__')[0]
    if (prefixStep === 'decision') return true
    return false
  }

  // TODO: Al crear un paso dentro de una rama de una desicion que tiene ya un paso que le siga, hacer que ese nuevo paso de la rama tenga como sucesor directamente al paso que le sigue a la desicion
  const createNewStep = (roles, name, conditions, logicalOperatorId, stepFather) => {
    const model = {
      id: uuidv4(),
      roles,
      name,
      conditions,
      children: [],
      typeId: getTypeByCode('STEP'),
      logicalOperatorId,
      permissions: []
      // code: '',
      // predecessorCode: '',
      // successorCode: '',
      // conditionsForDelete: '',
      // rolesForDelete: '',  
    }
    model.code = model.name + '__' + model.id
    model.predecessorCode = stepFather.code
    model.successorCode = ''
    model.conditionsForDelete = []
    model.rolesForDelete = []
    createChildInFather(valuesFields.configurations, stepFather.id, model)
    setValue('listSteps', [...valuesFields.listSteps, model])
    
    // const stepFatherWorkFlow = workflow.find((stp) => stp.code === stepFather.code);
    
    const stepFatherWorkFlow = workflow.find((stp) => stp.code === model.predecessorCode);
    // console.log(stepFatherWorkFlow)
    //* Si el stepFather es el desicion
    if (isDecision(stepFatherWorkFlow)) {
      let lastChildrenStepBranch 
      const existingBranches = stepFatherWorkFlow?.successorCode.split(',')
      const newWorkFlow = workflow.map(step => {
        //* Ultimo paso de cada rama que no tenga succesorCode poner el code del nuevo paso fuera de la rama
        if (existingBranches.includes(`rama__${step.branch}`) && step.successorCode === '') {
          lastChildrenStepBranch = step
          return {
            ...step,
            successorCode: model.code
          }
        }
        //* Para las ramas que no tienen paso que le siga, poner el code del nuevo paso fuera de la rama
        if (existingBranches.includes(`rama__${step.id}`) && step.successorCode === '') {
          lastChildrenStepBranch = step
          return {
            ...step,
            successorCode: model.code
          }
        }
        //* Ultimo paso de cada rama cuyo succesorCode no sea parte de la rama
        if (existingBranches.includes(`rama__${step.branch}`)) {
          const stepBranchWithSuccessorNoStepBranch = workflow.find(stp => stp.code === step.successorCode && !stp.branch)
          if (stepBranchWithSuccessorNoStepBranch) {
            const workFlowUpdated = workflow.map(stp => {
              if (stp.code === stepBranchWithSuccessorNoStepBranch.code) {
                stp.predecessorCode = model.code
                // return {
                //   ...stp,
                //   predecessorCode: model.code
                // }
              }
              return stp
            })
            lastChildrenStepBranch = step
            model.successorCode = stepBranchWithSuccessorNoStepBranch.code
            return {
              ...step,
              successorCode: model.code
            }
          }
          
        }
        return step
      })

      //TODO En caso ya existe un paso despues de la desicion y se quiere poner un paso antes que ese, poner como succesorCode ese paso existente y cambiar el predecessorCode del paso existente
      // if (lastChildrenStepBranch?.successorCode?.length > 0) {
      //   model.successorCode = lastChildrenStepBranch.successorCode
      //   const newWorkFlowUpdated = newWorkFlow.map((step) => {
      //     if (step.code === model.successorCode) {
      //       step.predecessorCode = model.code
      //     }
      //     return step
      //   })

      //   model.predecessorCode = lastChildrenStepBranch.code
      //   setWorkflow([...newWorkFlowUpdated, model])
      //   return
      // }

      model.predecessorCode = lastChildrenStepBranch?.code
      setWorkflow([...newWorkFlow, model])
      return
    }
    
    if (stepFatherWorkFlow?.successorCode?.length > 0) {
      model.successorCode = stepFatherWorkFlow.successorCode
      const newWorkFlow = workflow.map((step) => {
        // if (step.code === stepFatherWorkFlow.successorCode) {
        if (step.code === model.successorCode) {
          step.predecessorCode = model.code
        }
        if (step.code === stepFatherWorkFlow.code) {
          step.successorCode = model.code
        }
        return step
      })
      setWorkflow([...newWorkFlow, model])
      return
    }
    
    const newWorkFlow = workflow.map((step) => {
      if (step.code === stepFatherWorkFlow.code) {
        step.successorCode = model.code
      }
      return step
    })
    setWorkflow([...newWorkFlow, model])
  };

  //* Eliminar paso
  const deleteChildInFather = (step, idStep) => {
    // const existChildId = step.children?.some(child => child.id === idStep)
    const existChild = step.children?.find(child => child.id === idStep)
    if (existChild) {
      if (idStep) {
        step.children = step.children.filter(child => child.id !== idStep)
        step.children = [...step.children, ...existChild.children]
      }
      return step
    } else {
      if (step.childrenBranch) {
        for (let j = 0; j < step.childrenBranch.length; j++) {
          const result = deleteChildInFather(step.childrenBranch[j], idStep)
        }
      }
      if (step.children) {
        for (let i = 0; i < step.children.length; i++) {
          const result = deleteChildInFather(step.children[i], idStep)
          if (result) {
            step = {...result}
            return result
          }
        }
      }
    }
  }

  const deleteStep = (step) => {
    deleteChildInFather(valuesFields.configurations, step.id)

    const currentStepList = valuesFields.listSteps.filter((stp) => stp.id !== step.id);
    setValue('listSteps', currentStepList);

    let predecessor
    let successor
    workflow.forEach((stp) => {
      if (stp.code === step.predecessorCode) predecessor = stp.code
      if (stp.code === step.successorCode) successor = stp.code
    });
    const workflowChanged = workflow.map((stp) => {
      if (stp.code === step.predecessorCode) stp.successorCode = successor ? successor : ''
      if (stp.code === step.successorCode) stp.predecessorCode = predecessor
      return stp
    });
    const currentWorkflow = workflowChanged.filter((stp) => stp.code !== step.code);
    setWorkflow(currentWorkflow);
  }

  //* Actualizar paso
  const updateChildInFather = (step, stepData, idStep) => {
    const existChild = step.children?.some(child => child.id === idStep)
    if (existChild) {
      if (idStep) {
          step.children = step.children.map(child => {
          if (child.id === idStep) {
            child = {...child ,...stepData}
          }
          return child
        })
      }
      return step
    } else {
      if (step.childrenBranch) {
        for (let j = 0; j < step.childrenBranch.length; j++) {
          const result = updateChildInFather(step.childrenBranch[j], stepData, idStep)
        }
      }
      if (step.children) {
        for (let i = 0; i < step.children.length; i++) {
          const result = updateChildInFather(step.children[i], stepData, idStep)
          if (result) {
            step = {...result}
            return result
          }
        }
      }
    }
  }

  const changeRoles = (previousRoles, rolesToAdd, rolesToRemove) => {
    if (rolesToAdd.length > 0 && rolesToRemove.length === 0) {
      return [...previousRoles, ...rolesToAdd]
    } else if (rolesToAdd.length === 0 && rolesToRemove.length > 0) {
      const listWithRolesIdToRemove = rolesToRemove.map((role) => {
        const roleId = role.id
        return roleId
      })
      const newRoles = previousRoles.filter((role) => !listWithRolesIdToRemove.includes(role.id))
      return newRoles
    } else if (rolesToAdd.length > 0 && rolesToRemove.length > 0) {
      const listWithRolesIdToRemove = rolesToRemove.map((role) => {
        const roleId = role.id
        return roleId
      })
      const newRoles = previousRoles.filter((role) => !listWithRolesIdToRemove.includes(role.id))
      return [...newRoles, ...rolesToAdd]
    } else if (rolesToAdd.length === 0 && rolesToRemove.length === 0) {
      return previousRoles
    }
  }

  const updateStep = (actionSelected, stepData, step) => {
    if (actionSelected === 'initial_setup') {
      const modelCurrentStepList = {...valuesFields.configurations, roles: stepData.roles}
      setValue('configurations', modelCurrentStepList);
   
      const workflowUpdated = workflow.map((stp, index) => {
        if (index  === 0) {
          return {...stp, ...stepData}
        }
        return stp;
      });
      
      setWorkflow(workflowUpdated);
    }
    if (actionSelected === 'step_configuration') {
      updateChildInFather(valuesFields.configurations, stepData, step.id)
      const stepFatherWorkFlow = workflow.find((stp) => stp.id === step.id);
      
      const currentStepList = valuesFields.listSteps.map((stp) => {
          if (stp.id === step.id) {
              return {...stp, ...stepData}
            }
            return stp;
          });
          // console.log(stepData)
      const workflowUpdated = workflow.map((stp) => {
        if (stp.code === step.code) {
          
          const listPreviousRoles = stp.roles.map((role) => {
            const roleId = role.id
            return roleId
          })
          const listNewRoles = stepData.roles.map((role) => {
            const roleId = role.id
            return roleId
          })
          //* Para roles nuevos agregados
          const newRolestoAdd = stepData.roles.filter((role) => !listPreviousRoles.includes(role.id));

          //* Lista de roles a eliminar
          const rolsToRemove = stp.roles.filter((role) => !listNewRoles.includes(role.id));
          const rolsListToDelete = rolsToRemove.filter((role) => role.dbId).map((role) => role.dbId)
          // console.log(rolsToRemove)
          // console.log(rolsListToDelete)
          return {
            ...stp, 
            conditions: stepData.conditions,
            name: stepData.name,
            logicalOperatorId: stepData.logicalOperatorId,
            stepTo: stepData.stepTo,
            // roles: [...stp.roles, ...newRolestoAdd]
            roles: changeRoles(stp.roles, newRolestoAdd, rolsToRemove),
            rolesForDelete: stp.rolesForDelete ? [...stp.rolesForDelete, ...rolsListToDelete] : [...rolsListToDelete]
          }
        }
        return stp;
      });
      setValue('listSteps', currentStepList);
      setWorkflow(workflowUpdated);
    }
  }


  //* Crear un Goto
  const createNewStepGoTo = (conditions, stepTo, logicalOperatorId, stepFather) => {
    const branch = getTypeById(stepFather.typeId)?.code === 'CONDITION' ? stepFather.id : stepFather.branch
    const model = {
      id: uuidv4(),
      branch,
      name: 'Ir a ',
      stepTo,
      conditions,
      logicalOperatorId,
      children: [],
      // type: 'step_go_to',
      typeId: getTypeByCode('GOTO'),
      permissions: []
    }
    createChildInFather(valuesFields.configurations, stepFather.id, model)

    const stepFatherWorkFlow = workflow.find((stp) => stp.id === stepFather.id);
    model.code = model.name + '__' + model.id
    model.predecessorCode = stepFatherWorkFlow.code
    model.successorCode = ''
    model.conditionsForDelete = []
    model.roles = []
    model.rolesForDelete = []
    const stepGoToWorkFlow = workflow.find((stp) => stp.id === stepTo.id);
    // model.gotoCode = stepTo.code
    model.gotoCode = stepGoToWorkFlow.code

    // console.log(model)
    if (stepFatherWorkFlow?.successorCode?.length > 0) {
      model.successorCode = stepFatherWorkFlow.successorCode
      const newWorkFlow = workflow.map((step) => {
        if (step.code === model.successorCode) {
          step.predecessorCode = model.code
        }
        if (step.code === stepFatherWorkFlow.code) {
          step.successorCode = model.code
        }
        return step
      })
      setWorkflow([...newWorkFlow, model])
      return
    }

    // console.log(stepFatherWorkFlow)

    const newWorkFlow = workflow.map((step) => {
      if (step.code === stepFatherWorkFlow.code) {
        step.successorCode = model.code
      }
      return step
    })
    setWorkflow([...newWorkFlow, model])
  };

  //* Actualizar Goto
  const updateStepGoTo  = (stepData, step) => {
    updateChildInFather(valuesFields.configurations, stepData, step.id)

    // console.log(stepData)
    const stepGoToWorkFlow = workflow.find((stp) => stp.id === stepData.stepTo?.id);
    // stepData.gotoCode = stepData.stepTo?.code
    stepData.gotoCode = stepGoToWorkFlow.code
    const workflowUpdated = workflow.map((stp) => {
      if (stp.code === step.code) {
        return {...stp, ...stepData}
      }
      return stp;
    });
  setWorkflow(workflowUpdated);
  }

  //* Crear estructura de rama paralela
  const createParallelBranchInFather = (step, idFather, model) => {
    if (step.id === idFather) {
      if (model) {
        model.children = step.children
        step.children = [model]
      }
      return step
    } else {
      if (step.childrenBranch) {
        for (let j = 0; j < step.childrenBranch.length; j++) {
          const result = createParallelBranchInFather(step.childrenBranch[j], idFather, model)
        }
      }
      if (step.children) {
        for (let i = 0; i < step.children.length; i++) {
          const result = createParallelBranchInFather(step.children[i], idFather, model)
          if (result) {
            if (model) {
              // model.children = result.children
              // result.children = [model]
            }
            return result
          }
        }
      }
    }
  }

  const createNewParallelBranch = (stepFather) => {
    const id = uuidv4()
    const id2 = uuidv4()
    const id3 = uuidv4()
    const model = {
      id,
      typeId: getTypeByCode('CONDITION'),
      children: [],
      childrenBranch: [
        {
          id: id2,
          name: 'Rama sin nombre',
          idFather: id,
          conditions: [],
          children: [],
          typeId: getTypeByCode('CONDITION'),
        },
        {
          id: id3,
          name: 'Rama sin nombre',
          idFather: id,
          conditions: [],
          children: [],
          typeId: getTypeByCode('CONDITION'),
        },
      ],
    }
    setValue('state', 'loading');
    createParallelBranchInFather(valuesFields.configurations, stepFather.id, model)
    setValue('state', 'created');

    model.conditionsForDelete = []
    model.conditions = []
    model.roles = []
    model.rolesForDelete = []
    model.code = 'decision__' + model.id
    model.name = 'decision__' + model.id
    const model2 = {
      ...model, 
      id: id2, 
      code: 'rama__' + id2, 
      name: 'Rama sin nombre',
      predecessorCode: model.code,
      successorCode: '',
    }
    const model3 = {
      ...model, 
      id: id3, 
      code: 'rama__' + id3, 
      name: 'Rama sin nombre',
      predecessorCode: model.code,
      successorCode: '',
    }
    delete model2.childrenBranch
    delete model3.childrenBranch

    model.predecessorCode = stepFather.code
    // model.successorCode = model2.code
    model.successorCode = `${model2.code},${model3.code}`

    const stepFatherWorkFlow = workflow.find((stp) => stp.code === model.predecessorCode);
    if (stepFather?.successorCode?.length > 0) {
      model2.successorCode = stepFatherWorkFlow.successorCode
      model3.successorCode = stepFatherWorkFlow.successorCode
      const newWorkFlow = workflow.map((step) => {
        // if (step.code === stepFatherWorkFlow.successorCode) {
        if (step.code === model2.successorCode) {
          step.predecessorCode = model2.code
        }
        if (step.code === stepFatherWorkFlow.code) {
          step.successorCode = model.code
        }
        return step
      })
      setWorkflow([...newWorkFlow, model, model2, model3])
      return
    }

    const newWorkFlow = workflow.map((step) => {
      if (step.code === stepFatherWorkFlow?.code) {
        step.successorCode = model.code
      }
      return step
    })

    setWorkflow([...newWorkFlow, model, model2, model3])
  };

  //* Crear una nueva rama en la estructura de rama paralela -agregar childrenBranch
  const createStepBranchInFather = (step, idFather, model) => {
    if (step.id === idFather) {
      if (model) {
        // model.children = step.children
        step.childrenBranch = [...step.childrenBranch, model]
      }
      return step
    } else {
      if (step.children) {
        if (step.childrenBranch) {
          for (let j = 0; j < step.childrenBranch.length; j++) {
            const result = createStepBranchInFather(step.childrenBranch[j], idFather, model)
          }
        }
        for (let i = 0; i < step.children.length; i++) {
          // const result = createChildInFather(step.children[i], idFather)
          const result = createStepBranchInFather(step.children[i], idFather, model)
          if (result) return result
        }
      }
    }
  }

  const createNewStepBranch = (stepFather) => {
    const id = uuidv4()
    const model = {
      id,
      idFather: stepFather.id,
      name: 'Rama sin nombre',
      // condition: '',
      conditions: [],
      children: [],
      // type: 'parallel_branch',
      typeId: getTypeByCode('CONDITION'),
      permissions: []
    }
    setValue('state', 'loading');
    createStepBranchInFather(valuesFields.configurations, stepFather.id, model)
    setValue('state', 'created');

    model.conditionsForDelete = []
    model.roles = []
    model.rolesForDelete = []
    model.code = 'rama__' + id
    model.name = 'Rama sin nombre'
    model.predecessorCode = stepFather.code
    model.successorCode = ''
    
    const stepFatherWorkFlow = workflow.find((stp) => stp.code === model.predecessorCode);
    // TODO: Va a quedar vacio, en caso sea el ultimo al guardar se pondra como sucesor el cierre, en caso de agregar otro paso se pondra como sucesor el nuevo paso
    // model.successorCode = stepFatherWorkFlow.successorCode
    const newWorkFlow = workflow.map((step) => {
      // if (step.code === model.successorCode) {
      //   step.predecessorCode = model.code
      // }
      if (step.code === stepFatherWorkFlow.code) {
        step.successorCode = step.successorCode + ',' + model.code
      }
      return step
    })
    if (stepFatherWorkFlow.children.length > 0) {
      // console.log(stepFatherWorkFlow.children)
      model.successorCode = stepFatherWorkFlow.children[0].code
    }
    setWorkflow([...newWorkFlow, model])
      
    
  };

  //* Eliminar una rama en la estructura de rama paralela o toda la estructura
  const deleteStepBranchInFather = (step, idFatherStep, idStep) => {
    // const existBranchParallel = step.type === 'parallel_branch_root'
    // if (existBranchParallel) {
      if (step.id === idFatherStep) {
      // const existChild = step.childrenBranch?.find(child => child.id === idStep)
      // if (existChild) {
      //   step.childrenBranch = step.childrenBranch.filter(child => child.id !== idStep)
      // }
      step.childrenBranch = step.childrenBranch.filter(child => child.id !== idStep)
      return step
    } else {
      if (step.childrenBranch) {
        for (let j = 0; j < step.childrenBranch.length; j++) {
          const result = deleteStepBranchInFather(step.childrenBranch[j], idFatherStep, idStep)
        }
      }
      if (step.children) {
        for (let i = 0; i < step.children.length; i++) {
          const result = deleteStepBranchInFather(step.children[i], idFatherStep, idStep)
          if (result) {
            step = {...result}
            return result
          }
        }
      }
    }
  }

  const getQuantityStepBranchs = (step, idStep) => {
    // const existBranchParallel = step.type === 'parallel_branch_root'
    // if (existBranchParallel) {
    if (step.id === idStep) {
      // const existChild = step.childrenBranch?.find(child => child.id === idStep)
      // if (existChild) return step.childrenBranch.length
      return step.childrenBranch.length
    } else {
      if (step.childrenBranch) {
        for (let j = 0; j < step.childrenBranch.length; j++) {
          const result = getQuantityStepBranchs(step.childrenBranch[j], idStep)
          if (result) return result
        }
      }
      if (step.children) {
        for (let i = 0; i < step.children.length; i++) {
          const result = getQuantityStepBranchs(step.children[i], idStep)
          if (result) return result
        }
      }
    }
  }

  const deleteParallelBranch = (step, idStep) => {
    // const existBranchParallel = step.children?.find(child => child.type === 'parallel_branch_root')
    const existChild = step.children?.find(child => child.id === idStep)
    if (existChild) {
      // const existChild = step.children?.find(child => child.id === idStep)
      // if (existChild) {
        step.children = step.children.filter(child => child.id !== idStep)
        step.children = [...step.children, ...existChild.children]
      // }
    } else {
      if (step.childrenBranch) {
        for (let j = 0; j < step.childrenBranch.length; j++) {
          const result = deleteParallelBranch(step.childrenBranch[j], idStep)
        }
      }
      if (step.children) {
        for (let i = 0; i < step.children.length; i++) {
          const result = deleteParallelBranch(step.children[i], idStep)
          if (result) {
            step = {...result}
            return result
          }
        }
      }
    }
  }

  const deleteStepBranch = (step) => {
    setValue('state', 'loading');
    // const quantityChildrenBranch = getQuantityStepBranchs(valuesFields.configurations, step.id)
    //* Rama seleccionada para eliminar
    const stepWorkFlow = workflow.find((stp) => stp.id === step.id);
    // console.log(stepWorkFlow)
    const idFatherOrPredeccesorId = step.idFather ? step.idFather : stepWorkFlow.predecessorCode.split('__')[1]
    const quantityChildrenBranch = getQuantityStepBranchs(valuesFields.configurations, idFatherOrPredeccesorId)
    // console.log(quantityChildrenBranch)
    if (quantityChildrenBranch === 2) {
      deleteParallelBranch(valuesFields.configurations, idFatherOrPredeccesorId)
      setValue('state', 'deleted');

      //* Obtenemos referencia de la desicion
      const workFlowDesicion = workflow.find(item => (item.code === stepWorkFlow.predecessorCode))
      const workFlowDesicionBranchs = workFlowDesicion.successorCode.split(',')

      //* Actualizar el sucesor y predecesor de lo que rodea a decision
      workflow.forEach(step => {
        //* Si las ramas tienen pasos
        if (workFlowDesicionBranchs.includes(`rama__${step.branch}`)) {
          //* Ultimo paso de cada rama que no tenga succesorCode poner el code del nuevo paso fuera de la rama
          if (step.successorCode === '') {
            const workFlowUpdated = workflow.map(stp => {
              if (stp.code === workFlowDesicion.predecessorCode) {
                console.log(stp)
                stp.successorCode = ""
              }
            })
          }
          //* Ultimo paso de cada rama cuyo succesorCode no sea parte de la rama
          const stepBranchWithSuccessorNoStepBranch = workflow.find(stp => stp.code === step.successorCode && !stp.branch)
          if (stepBranchWithSuccessorNoStepBranch) {
            const workFlowUpdated = workflow.map(stp => {
              if (stp.code === workFlowDesicion.predecessorCode) {
                stp.successorCode = stepBranchWithSuccessorNoStepBranch.code
              }
              if (stp.code === stepBranchWithSuccessorNoStepBranch.code) {
                stp.predecessorCode = workFlowDesicion.predecessorCode
              }
            })
          }
        }
        //* Si solo estan las 2 ramas en la desición y las 2 ramas no tienen successorCode
        if (workFlowDesicionBranchs.includes(step.code) && step.successorCode === '') {
          const workFlowUpdated = workflow.map(stp => {
            if (stp.code === workFlowDesicion.predecessorCode) {
              stp.successorCode = ""
            }
          })
        }
        // Si solo estan las 2 ramas en la desición y una o ambas tienen successorCode
        //* Si solo estan las 2 ramas en la desición y hay un paso que sigue a la desicion
        if (workFlowDesicionBranchs.includes(step.code) && step.successorCode) {
          const workFlowUpdated = workflow.map(stp => {
            if (stp.code === workFlowDesicion.predecessorCode) {
              stp.successorCode = step.successorCode
            }
            if (stp.predecessorCode === step.code) {
              stp.predecessorCode = workFlowDesicion.predecessorCode
            }
          })
        }
      })

      //* Eliminamos los pasos dentro de las ramas
      const workFlowFilterStepsInDesicion = workflow.filter(item => !workFlowDesicionBranchs.includes(`rama__${item.branch}`))
      //* Eliminamos las ramas y la desicion, 
      const newWorkFlow = workFlowFilterStepsInDesicion.filter(item => (item.predecessorCode !== stepWorkFlow.predecessorCode && item.code !== stepWorkFlow.predecessorCode))
      setWorkflow(newWorkFlow)
      return
    }
    // deleteStepBranchInFather(valuesFields.configurations, step.id)
    deleteStepBranchInFather(valuesFields.configurations, idFatherOrPredeccesorId, step.id)
    setValue('state', 'deleted');

    //* Eliminamos los pasos dentro de las ramas
    // const workFlowFilterStepsInDesicion = workflow.filter(item => !workFlowDesicionBranchs.includes(`rama__${item.branch}`))

    //* Eliminar una de las ramas sucesores de desicion
    const workFlowDecisionUpdated = workflow.map(item => {
      if (item.code === stepWorkFlow.predecessorCode) {
        item.successorCode = item.successorCode.replace(`,${stepWorkFlow.code}`, '')
      }
      return item
    })
    const newWorkFlow = workFlowDecisionUpdated.filter(item => item.code !== stepWorkFlow.code &&  `rama__${item.branch}` !== stepWorkFlow.code)
    setWorkflow(newWorkFlow)
  }

  //* Actualizar rama paralela
  const updateStepBranchInFather = (step, stepData, idFatherStep, idStep) => {
    // const existBranchParallel = step.type === 'parallel_branch_root'
    // if (existBranchParallel) {
    if (step.id === idFatherStep) {
      const existChild = step.childrenBranch?.some(child => child.id === idStep)
      if (existChild) {
        if (stepData) {
            step.childrenBranch = step.childrenBranch.map(child => {
            if (child.id === idStep) {
              child = {...child ,...stepData}
            }
            return child
          })
        }
        return step
      }
    } else {
      if (step.childrenBranch) {
        for (let j = 0; j < step.childrenBranch.length; j++) {
          const result = updateStepBranchInFather(step.childrenBranch[j], stepData, idFatherStep, idStep)
        }
      }
      if (step.children) {
        for (let i = 0; i < step.children.length; i++) {
          const result = updateStepBranchInFather(step.children[i], stepData, idFatherStep, idStep)
          if (result) {
            step = {...result}
            return result
          }
        }
      }
    }
  }

  const updateStepBranch  = (stepData, step) => {
    // updateStepBranchInFather(valuesFields.configurations, stepData, step.id)
    // console.log(step)
    // console.log(stepData)
    const stepWorkFlow = workflow.find((stp) => stp.id === step.id);
    const idFatherOrPredeccesorId = step.idFather ? step.idFather : stepWorkFlow.predecessorCode.split('__')[1]
    // updateStepBranchInFather(valuesFields.configurations, stepData, step.idFather, step.id)
    updateStepBranchInFather(valuesFields.configurations, stepData, idFatherOrPredeccesorId, step.id)
    const workflowUpdated = workflow.map((stp) => {
      if (stp.id === step.id) {
        return {...stp, ...stepData}
      }
      return stp;
    });
    setWorkflow(workflowUpdated);
  }

  //* Crear un nuevo paso en una rama
  const createNewStepBranchInFather = (step, idFather, model) => {
    // const existBranchParallel = step.type === 'parallel_branch_root'
    if (step.id === idFather) {
      if (model) {
        model.children = step.children
        step.children = [model]
      }
      return step
    } else {
      //* Validar y continuar el recorrido para los childrenBranch y no solo children
      if (step.childrenBranch) {
        for (let j = 0; j < step.childrenBranch.length; j++) {
          const result = createNewStepBranchInFather(step.childrenBranch[j], idFather, model)
        }
      }
      if (step.children) {
        for (let i = 0; i < step.children.length; i++) {
          const result = createNewStepBranchInFather(step.children[i], idFather, model)
          // if (result) {
          //   if (model) {
          //     model.children = result.children
          //     result.children = [model]
          //   }
          //   return result
          // }
        }
      }
    }
  }

  const createNewStepInBranch = (roles, name, conditions, logicalOperatorId, stepFather) => {
    // const branch = stepFather.type === 'parallel_branch' ? stepFather.id : stepFather.branch
    const branch = getTypeById(stepFather.typeId)?.code === 'CONDITION' ? stepFather.id : stepFather.branch
    const model = {
      id: uuidv4(),
      branch,
      roles,
      name,
      conditions,
      logicalOperatorId,
      children: [],
      // type: 'step',
      typeId: getTypeByCode('STEP'),
      permissions: []
    }
    // console.log(stepFather)
    createNewStepBranchInFather(valuesFields.configurations, stepFather.id, model)
    // const { position, key, ...restStep } = model
    // setValue('listSteps', [...valuesFields.listSteps, restStep])
    const { position, key, ...restStep } = model
    setValue('listSteps', [...valuesFields.listSteps, restStep])

    const stepFatherWorkFlow = workflow.find((stp) => stp.id === stepFather.id);
    // console.log(stepFatherWorkFlow)
    model.code = model.name + '__' + model.id
    model.predecessorCode = stepFatherWorkFlow.code
    model.successorCode = ''
    model.conditionsForDelete = []
    model.rolesForDelete = []

    // console.log(stepFather)
    // console.log(stepFatherWorkFlow)
    // console.log(model)

    // if (stepFather?.successorCode?.length > 0) {
    if (stepFatherWorkFlow?.successorCode?.length > 0) {
      model.successorCode = stepFatherWorkFlow.successorCode
      // console.log(model.successorCode)
      const newWorkFlow = workflow.map((step) => {
        if (step.code === model.successorCode) {
          step.predecessorCode = model.code
        }
        if (step.code === stepFatherWorkFlow.code) {
          step.successorCode = model.code
        }
        return step
      })
      setWorkflow([...newWorkFlow, model])
      return
    }

    
    const newWorkFlow = workflow.map((step) => {
      if (step.code === stepFatherWorkFlow.code) {
        step.successorCode = model.code
      }
      return step
    })
    setWorkflow([...newWorkFlow, model])
  };
  
  const getLastStep = (step) => {
    // console.log(step)
    if (step?.children?.length === 0) {
      // console.log(step)
      return step
    } else {
      if (step.children) {
        for (let i = 0; i < step.children.length; i++) {
          const result = getLastStep(step.children[i])
          if (result) return result
        }
      }
    }
  }

  const setConditionsForDelete = (idCondition, stepSelected) => {
    const newWorkFlowWithConditionsDeleteUpdatead = workflow.map((stp) => {
      if (stp.id === stepSelected.id) {
        return {
          ...stp, 
          conditionsForDelete: stp.conditionsForDelete ? [...stp.conditionsForDelete, idCondition] : [idCondition]
        }
      }
      return stp
    });

    setWorkflow(newWorkFlowWithConditionsDeleteUpdatead)
  }

  const cleanConditionsForDelete = (stepSelected) => {
    const newWorkFlowWithConditionsDeleteUpdatead = workflow.map((stp) => {
      if (stp.id === stepSelected?.id) {
        return {
          ...stp, 
          conditionsForDelete: []
        }
      }
      return stp
    });

    setWorkflow(newWorkFlowWithConditionsDeleteUpdatead)
  }

  const stepMethods = {
    createNewStep,
    deleteStep,
    updateStep,
    createNewStepGoTo,
    updateStepGoTo,
    createNewParallelBranch,
    createNewStepBranch,
    deleteStepBranch,
    createNewStepInBranch,
    updateStepBranch,
    getLastStep,
    setConditionsForDelete,
    cleanConditionsForDelete
  }

  
  

  return {
    control,
    errors,
    valuesFields,
    existError,
    setValue,
    handleSubmit,
    reset,
    stepMethods
  }
}

export default useWorkflow