import moment from 'moment'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid';

const dataUsers = {
  users: [
      {
          id: 3,
          fullName: "Juan Carlos Tenorio",
          firstName: "Juan Carlos",
          lastName: "Tenorio"
      },
      {
          id: 9,
          fullName: "info calidar",
          firstName: "info",
          lastName: "calidar"
      },
      {
          id: 46,
          fullName: "Renzoe Jimenez",
          firstName: "Renzoe",
          lastName: "Jimenez"
      },
      {
          id: 51,
          fullName: "Nat Rojas",
          firstName: "Nat",
          lastName: "Rojas"
      },
      {
          id: 61,
          fullName: "Nataly Rojas",
          firstName: "Nataly",
          lastName: "Rojas"
      },
      {
          id: 70,
          fullName: "Nataly Rojas",
          firstName: "Nataly",
          lastName: "Rojas"
      },
      {
          id: 71,
          fullName: "Rodrigo Matteo",
          firstName: "Rodrigo",
          lastName: "Matteo"
      },
      {
          id: 72,
          fullName: "Renzo prueba Jimenez",
          firstName: "Renzo prueba",
          lastName: "Jimenez"
      },
      {
          id: 73,
          fullName: "Renzo Jimenez",
          firstName: "Renzo",
          lastName: "Jimenez"
      },
      {
          id: 93,
          fullName: "Rodrigo Matteo",
          firstName: "Rodrigo",
          lastName: "Matteo"
      },
      {
          id: 94,
          fullName: "Estela Carmona",
          firstName: "Estela",
          lastName: "Carmona"
      },
      {
          id: 95,
          fullName: "Hernan Barcos",
          firstName: "Hernan",
          lastName: "Barcos"
      },
      {
          id: 98,
          fullName: "estela 1 car",
          firstName: "estela 1",
          lastName: "car"
      }
  ],
  sourceTypes: [
      {
          id: 200,
          code: "WSHT",
          description: "Ficha de Proceso"
      },
      {
          id: 201,
          code: "WPRC",
          description: "Proceso"
      }
  ]
};

export const TableListPayments = ({mnsjSendValidate, setMnsjSendValidate, dataPayment, setDataPayment}) => {
  const [expandedRows, setExpandedRows] = useState(null);
  const [levelsReject, setLevelsReject] = useState([])

  const dataProjectsAndPayment = useSelector((state) => state.paymentProjectReducer.projectsAndPaymentMethod.data);

  const changeValueLevel = (e,id,row) => {
    setDataPayment(dataPayment.map(el=>{
      if(el.id===id){
        el[row] = Number(e.target.value)
      }
      return el
    }))
  }

  const onValidateAmount = (e,id,row) => {
    setDataPayment(dataPayment.map(el=>{
      if(el.id===id){
        el[row] = Number(e.target.value).toFixed(2)
      }
      return el
    }))
  }

  const changeValueDate = (e,id,row) => {
    setMnsjSendValidate(mnsjSendValidate.filter(elem => {
      if(elem.id===id){
        if(elem?.row!==row){
          return elem
        }
      }else{
        return elem
      }
    }))
    setDataPayment(dataPayment.map(el=>{
        if(el.id===id){
          el[row] = e.value
        }
        return el
    }))
  }

  const viewInputAmount = (data, row) => {
    return <InputText value={data[row]} type="number" onChange={(e)=>changeValueLevel(e,data.id, row)}
    onBlur={(e)=> onValidateAmount(e, data.id, row)}
     className='w-full text-right'
     style={{ minWidth:'90px'}}
     />
  }

  const viewInputDate = (data, row) => {
    return <Calendar showIcon value={data[row]?new Date(data[row]):""} onChange={(e)=>changeValueDate(e,data.id, row)}
    // onBlur={(e)=> duplicateLevel(data.id, row, e, data.levelNumber)}
    className='w-full' style={{ minWidth:'120px'}} />
  }

  const trashFunction = (data) => {
    return <i
    className='pi pi-trash cursor-pointer'
    onClick={(e)=> {
      setMnsjSendValidate(mnsjSendValidate.filter(el => el.id!==data.id))
      setLevelsReject(levelsReject.filter(elem=>elem.id!==data.levelNumber))
      const data2 = ([...dataPayment].filter(el=>el.id!==data.id))
      setDataPayment(data2.map(elem => {
        if(elem.rejectLevelNumber===data.levelNumber){
          elem.rejectLevelNumber = null
        }
        return elem
      }))
    }}
    />
  }


  const changeDropDown = (event, row, data) => {
    setMnsjSendValidate(mnsjSendValidate.filter(elem => {
      if(elem.id===data.id){
        if(elem?.row!==row){
          return elem
        }
      }else{
        return elem
      }
    }))
    setDataPayment(dataPayment.map(el=>{
      if(el.id===data.id){
        el[row] = event.value?.id ? event.value?.id : null
      }
      return el
    }))
  }

  const viewInputDropDown = (data, row) => {
    return <Dropdown
      onChange={(e)=>changeDropDown(e,row, data)}
      options={dataProjectsAndPayment?.paymentModes}
      value={dataProjectsAndPayment?.paymentModes.find(elem => elem.id===data[row])}
      optionLabel="description"
      filter
      showClear
    />
  }

  const addRow = () => {
    // setMnsjValidate([])------------------------------------------------
    const newId = uuidv4();
    if(dataPayment?.length===0){
      setMnsjSendValidate(mnsjSendValidate.filter(el=> el.id!=="notFound"))
      setDataPayment([{
        "id": newId,
        "issueDate": null,
        "dueDate": null,
        "paymentDate": null,
        "paymentModeId": null,
        "amount": "0.00",
        "mode":"new"
      }])
    }else{
      setDataPayment([...dataPayment, {
        "id": newId,
        "issueDate": null,
        "dueDate": null,
        "paymentDate": null,
        "paymentModeId": null,
        "amount": "0.00",
        "mode":"new"
      }])
    }
  }

  return (
    <div>
        <Button label='Añadir Pago'
        type='button'
            className='sig-button sig-primary mb-2'
            onClick={addRow}
          />
        <DataTable
                          value={dataPayment}
                          emptyMessage="No se encontraron datos"
                          stripedRows
                          // rows={5}
                          editMode="row"
                          dataKey="id" //TODO: Cambiar luego por id
                          rowHover
                          size="small"
                          responsiveLayout="scroll"
                          expandedRows={expandedRows}
                          // onRowToggle={(e) => setExpandedRows(e.data)}
                          // onRowExpand={(e) => onRowExpand(e.data)}
                          lazy
                      >

                          <Column field="issueDate" header="Fecha de Emisión *" body={(e)=>viewInputDate(e, "issueDate")}/>
                          <Column field="dueDate" header="Fecha de Vencimiento *" body={(e)=>viewInputDate(e, "dueDate")}/>
                          <Column field="paymentDate" header="Fecha de Pago" body={(e)=>viewInputDate(e, "paymentDate")}/>
                          <Column field="paymentModeId" header="Modo" body={(e)=>viewInputDropDown(e, "paymentModeId")}/>
                          <Column field="amount" header="Monto *" body={(e)=>viewInputAmount(e, "amount")}/>
                          <Column field="" header={null} body={(e)=>trashFunction(e)}/>
            </DataTable>
        </div>
  )
}
