import { useEffect, useState } from "react";

import { Panel } from "primereact/panel";
import { Tree } from "primereact/tree";

import moment from "moment"
import { CoreConstants } from "core/commons/CoreConstants";

const Users = ({nivelAprobacion, nombre, fecha, rol, className}) => {
	return (
		<div className={`flex flex-col pl-2 ${className}`}>
			<div>
				<span className="font-bold">{nivelAprobacion.toUpperCase()}: </span>{fecha}
			</div>
			<div>
				{nombre} - {rol}
			</div>
		</div>
	)
}

export const VersionHistory = ({historyVersions, className}) => {

	const [versions, setVersions] = useState([])

	useEffect(()=>{
		const aux = [...historyVersions]
		aux.forEach(item=>{
			item.label = <div><span className="font-bold">Version {item.version} - </span>{item.reason}</div>
			item.icon = "pi pi-fw pi-calendar"
			item.children.forEach(item => {
				item.label = <Users nivelAprobacion={item.approvalLevel} nombre={item.approverName} fecha={moment(item.approvalDate).format(CoreConstants.Format.LOCALE)} rol={item.role}/>
				item.icon = "pi pi-user"
			})
		})
		setVersions(aux)
	},[historyVersions])

	return (
		<Panel header="HISTORIAL DE VERSIONES" className={className} toggleable>
			{
				versions.length > 0 &&
				<Tree value={versions}/>
			}
		</Panel>
	);
};