import { apiService } from "core/services/ApiService";

const search = (criteria) => {
  return apiService.Post("customer/api/ProjectPayment/Search", criteria);
}

const getListProjects = (projectId) => {
  return apiService.Get("customer/api/CourseStudent/GetStudentsByCourseId?id=" + projectId);
}

const savePaymentProject = (criteria) => {
  return apiService.Post("customer/api/ProjectPayment/Save", criteria)
}

const getCoursesAndPayment = () => {
  return apiService.Get("customer/api/ProjectPayment/GetDataMaster");
}

const getPaymentById = (Id) => {
  return apiService.Get("customer/api/ProjectPayment/GetProjectPaymentById?id=" + Id);
}

const generateReport = (criteria) => {
  return apiService.Post("customer/api/ProjectPayment/GenerateReport", criteria, {
    responseType: 'blob',
  });
}

const deletedPayment = (id) => {
  return apiService.Delete(`customer/api/CourseStudentPayment/Delete?id=${id}`);
}

export const PaymentProjectService = {
  search,
  savePaymentProject,
  getCoursesAndPayment,
  generateReport,
  deletedPayment,
  getPaymentById,
  getListProjects
};