const { apiService } = require("core/services/ApiService");

const getProcessMapConfiguration = () => {
  return apiService.Get("management/api/ProcessMapConfiguration/GetProcessMapConfiguration");
}

const saveProcessMapConfiguration = (payload) => {
  return apiService.Post("management/api/ProcessMapConfiguration/SaveProcessMapConfiguration", payload);
}

const getDataMaster = () => {
  return apiService.Post("management/api/ProcessMapConfiguration/GetDataMaster");
}

export const ProcessMapService = {
  getProcessMapConfiguration,
  saveProcessMapConfiguration,
  getDataMaster,
}