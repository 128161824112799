/* eslint-disable default-case */
import { ProcessesAction } from "features/configuration/store/actions/ProcessesAction";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PinerComponent from "shared/components/Piner/PinerComponent";

const FilterComponent = () => {
    const dispatch = useDispatch();
    const criteriaOccupation = useRef(null);
    const advancedFilter = useSelector((state) => state.processReducer.advancedFilter);
    const values = useSelector((state) => state.processReducer.advancedFilter.values);
    // const { isLoading, data } = useSelector((state) => state.processReducer.process)
    const { isLoading } = useSelector((state) => state.processReducer.occupations);
    const openModal = () => {
        dispatch(ProcessesAction.toggleAdvancedFilter({ showModal: true}));
    };


    const showPiners = () => {
        if (values?.length > 0)
            return true;
        return false;
    }

    const removePiner = (field) => {
        switch (field) {
            case 'name':
                advancedFilter.filter.name = null;
                criteriaOccupation.current.value = '';
                break;
            case 'to':
                advancedFilter.filter.to = null;
                break;
            case 'from':
                advancedFilter.filter.from = null;
                break;
        }

        dispatch(ProcessesAction.updateFilter(advancedFilter));
        dispatch(ProcessesAction.search());

    };

    const search = () => {
        const value = criteriaOccupation.current.value;
        if(value !== null && value !== undefined && value.trim().length > 0) {
            advancedFilter.filter.name = value;
            dispatch(ProcessesAction.updateFilter(advancedFilter));
        }
        dispatch(ProcessesAction.search());
    }

    const handleKeyDown = (event) => {
        if(event.key === 'Enter') {
            search();
        }
    }

    const generateReport = () => {
        const value = criteriaOccupation.current.value;
        if(value !== null && value !== undefined && value.trim().length > 0) {
            advancedFilter.filter.name = value;
            dispatch(ProcessesAction.updateFilter(advancedFilter));
        }
        dispatch(ProcessesAction.generateReport());
    }

  return (
    <Panel header="FILTRO" toggleable>
      <div className="filter-components">
        
        <div className="w-full ">
            <span className="p-float-label p-input-icon-left w-full ">
                <i className="pi pi-search" />
                <InputText
                    id="input-search"
                    ref={criteriaOccupation}
                        onKeyDown={handleKeyDown}
                />
                <label htmlFor="input-search">Buscar por proceso</label>
            </span>
            {showPiners() && (
                <div className="filter-piners mt-3">
                    {values.map(({ value, field }, index) => (
                        <PinerComponent
                            name={value}
                            field={field}
                            removePiner={removePiner}
                            key={index}
                        />
                    ))}
                </div>
            )}
        </div>
        <div className="filter-users-action">
            <div className="flex gap-3 w-full">
                <Button
                    icon="pi pi-search"
                    type="button"
                    label="Buscar"
                    onClick={search}
                    loading={isLoading}
                    className="sig-button sig-primary w-full"
                />
                <Button
                    icon="pi pi-download"
                    type="button"
                    loading={isLoading}
                    onClick={generateReport}
                    label="Descargar"
                    className="sig-button sig-success  w-full"
                />
            </div>
            <div className="flex mt-3">
                <Button
                    type="button"
                    label="Búsqueda avanzada"
                    className="sig-button  sig-secondary w-full"
                    onClick={openModal}
                />
            </div>
        </div> 
      </div>
    </Panel>
  )
}

export default FilterComponent;
