import { InputText } from 'primereact/inputtext'
import React from 'react'
import { Controller } from 'react-hook-form'

export const ControllerLabel = ({control, errors, ValidateInput, validateDuplicate}) => {
  return (
    <>
      <span className="col-span-2 p-float-label w-full mt-4">
          <Controller
              control={control}
              render={({
                  field: { onChange, onBlur, value }
              }) => (
                <InputText
                    type="text"
                    id="fieldLabel"
                    name="fieldLabel"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    className={errors?.fieldLabel ? 'p-invalid w-full' : 'w-full'}
                />
              )}
              name="fieldLabel"
              rules={{
                  required: 'La etiqueta del campo es requerida.',
                  validate: ValidateInput
              }}
          />
          <label htmlFor="fieldLabel">Etiqueta del campo  *</label>
      </span>
      <span className="col-span-2 p-float-label w-full mt-4">
          <Controller
              control={control}
              render={({
                  field: { onChange, onBlur, value }
              }) => (
                <InputText
                    type="text"
                    id="description"
                    name="description"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    className={errors?.description ? 'p-invalid w-full' : 'w-full'}
                />
              )}
              name="description"
              rules={{
                  required: 'La etiqueta del campo es requerida.',
              }}
          />
          <label htmlFor="description">Descripción  *</label>
          <div className={`rounded bg-red-200 p-5 mt-6 text-sm text-red-600 ${validateDuplicate===false&&'hidden'}`}>
        <ul>
          <li>La etiqueta del campo, ya se encuentra en uso.</li>
        </ul>
      </div>
      </span>
    </>
  )
}