/* eslint-disable import/no-anonymous-default-export */

import { RiskConstants } from "../../commons/RiskConstants";


const initialState = {
  dataMaster: {
    isLoading: false,
    probabilidad: [],
    impacto: [],
    colors: [],
    error: false,
  },
  riskLevel: {
      isLoading: false,
      riskLevel: []
    },
  data: {
    id: null,
    isLoading: false,
    data: [],
    name: "",
    probability: [],
    impact: [],
    level: [],
    error: false,
    status: false,
  },
  selectSquare: [],
  newId: 0,
  allMatrix: {
    data: {},
    isLoading: false,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RiskConstants.Accion.RiskManagement.GET_DATA_MASTER:
      return {
        ...state,
        dataMaster: {
          ...state.dataMaster,
          ...action.payload
        }
      }

    case RiskConstants.Accion.RiskManagement.GET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      }
      
    case RiskConstants.Accion.RiskManagement.SET_SELECT_SQUARE:
      return {
        ...state,
        selectSquare: [
          ...action.payload
        ]
      } 
    
    case RiskConstants.Accion.RiskManagement.SET_NEW_ID:
      return {
        ...state,
        newId: action.payload
      }

    case RiskConstants.Accion.RiskManagement.SET_SELECT_COLOR:
      return {
        ...state,
        riskLevel: {
          ...state.riskLevel,
          ...action.payload
        }
      }
    
    case RiskConstants.Accion.RiskManagement.GET_ALL_MATRIX:
      return {
        ...state,
          allMatrix: {
            ...state.allMatrix,
            ...action.payload,
          }
      }
      
    default: 
    return state;
  }

}