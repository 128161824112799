//table frecuency
  const frecuencyColumns = [
    { field: 'daysId', header: 'Días' },
    { field: 'hoursId', header: 'Horas' },
  ];

//table nombre del instructor 
const instructorColumns = [
  { field: 'name', header: 'Nombre' },
];
//table añadir evaluacion
const evaluationColumns = [
  { field: 'description', header: 'Descripción' },
  { field: 'creationDate', header: 'Inicio' },
  { field: 'endDate', header: 'Fin' },
  { field: 'evaluationWeight', header: 'Peso' },
];

  export const SchedulesTableData = {
    frecuencyColumns,
    instructorColumns,
    evaluationColumns
    
  };