import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from 'core/store/actions/ToastAction';
import { RrhhConstants } from 'features/rrhh/commons/RrhhConstants';
import { DepartmentService } from 'features/rrhh/services/DepartmentServices';
import FileSaver from 'file-saver';
import moment from 'moment';

const toggleAdvancedFilter = (payload) => {
  return (dispatch) =>
    dispatch({
      type: RrhhConstants.Accion.Departments.ADVANCED_FILTER_TOGGLE,
      payload,
    });
};

const resetAfterSaved = () => {
  return (dispatch) =>
    dispatch({ type: RrhhConstants.Accion.Departments.RESET_AFTER_SAVED });
};

const updateFilter = (payload) => {
  return (dispatch, getState) => {
    const pines = [];
    const advancedFilter = payload; // getState().departmentReducer.advancedFilter;

    if (advancedFilter.filter.name)
      pines.push({ field: 'name', value: advancedFilter.filter.name });

    if (advancedFilter.filter.from)
      pines.push({
        field: 'from',
        value: moment(advancedFilter.filter.from).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.to)
      pines.push({
        field: 'to',
        value: moment(advancedFilter.filter.to).format(
          CoreConstants.Format.LOCALE
        ),
      });

    if (advancedFilter.filter.departmentParent)
      pines.push({
        field: 'departmentParent',
        value: advancedFilter.filter.departmentParent.name,
      });

    if (advancedFilter.filter.manager)
      pines.push({
        field: 'manager',
        value: advancedFilter.filter.manager.fullName,
      });

    advancedFilter.values = pines;

    dispatch({
      type: RrhhConstants.Accion.Departments.UPDATE_FILTER,
      advancedFilter,
    });
  };
};

const search = () => {
  const setDepartments = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: RrhhConstants.Accion.Departments.SEARCH_DEPARTMENTS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    const departments = getState().departmentReducer.departments;
    setDepartments({
      dispatch: dispatch,
      isLoading: true,
      data: departments.data,
    });

    const criteria = {};
    const advancedFilter = getState().departmentReducer.advancedFilter;
    const filter = getState().departmentReducer.advancedFilter.filter;

    criteria.columnOrder = advancedFilter.columnOrder;
    criteria.order = advancedFilter.order;
    criteria.page = advancedFilter.page;
    criteria.pageSize = advancedFilter.pageSize;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    criteria.parentDepartmentId = filter?.departmentParent?.id;
    criteria.managerId = filter.manager?.id;

    DepartmentService.searchDepartments(criteria).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK)
          setDepartments({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Departamento', data.message));
          setDepartments({ dispatch: dispatch, isLoading: false, data: null });
        }else{
          setDepartments({ dispatch: dispatch, isLoading: false, data: null });
          dispatch(toastAction.error('Departamento', data.message));
        }
      },
      () => {
        setDepartments({ dispatch: dispatch, isLoading: false, data: null });
      }
    ).catch((error)=>{
    });
  };
};

const getDepartmentById = (departmentId) => {
  const setDepartment = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: RrhhConstants.Accion.Departments.GET_DEPARTMENT_BY_ID,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setDepartment({ dispatch: dispatch, isLoading: true });
    DepartmentService.getDepartmentById(departmentId).then(
      ({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          setDepartment({
            dispatch: dispatch,
            isLoading: false,
            data: data.data,
          });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL) {
          setDepartment({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Departamento', data.message));
          // dispatch({
          //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
          //   toast: {
          //     titulo: 'Departamento',
          //     mensaje: data.message,
          //     severidad: 'warn',
          //   },
          // });
        }else{
          setDepartment({ dispatch: dispatch, isLoading: false });
          dispatch(toastAction.warn('Departamento', data.message));
        }
      },
      () => {
        setDepartment({ dispatch: dispatch, isLoading: false });
      }
    ).catch((error)=>{
    });
  };
};

const saveDepartment = (department) => {
  const isLoading = ({ dispatch, isSaving, isSaved, currentDepartment }) => {
    dispatch({
      type: RrhhConstants.Accion.Departments.SAVE_DEPARTMENT,
      payload: {
        isSaving,
        isSaved,
      },
    });
  };

  return (dispatch, getState) => {
    const currentDepartment = getState().departmentReducer.department;
    isLoading({
      dispatch: dispatch,
      isSaving: true,
      isSaved: false,
      currentDepartment: currentDepartment,
    });
    DepartmentService.saveDepartment(department).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Departamento', 'El Departamento se guardó correctamente'));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: true, currentDepartment, });
        } else if (data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn('Departamento', data.message));
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false, currentDepartment });
        }else{
          isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
          dispatch(toastAction.error('Departamento', data.message));
        }

      },
      () => {
        isLoading({ dispatch: dispatch, isSaving: false, isSaved: false });
      }
    ).catch((error)=>{
    });
  };
};


const deleteDepartment = (departmentId) => {

  return (dispatch, getState) => {

    DepartmentService.deleteDepartment(departmentId).then(
      ({ data }) => {

        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(toastAction.success('Departamento', 'El Departamento se eliminó correctamente'));
          dispatch({ type: RrhhConstants.Accion.Departments.DELETE_DEPARTMENT, payload: { isDeleted: true } });
        } else {
          dispatch(toastAction.warn('Departamento', data.message));
          dispatch({ type: RrhhConstants.Accion.Departments.DELETE_DEPARTMENT, payload: { isDeleted: false } });
        }


      },
      () => {
        dispatch({ type: RrhhConstants.Accion.Departments.DELETE_DEPARTMENT, payload: { isDeleted: false } });
      }
    );
  };
};


const getDataMaster = () => {
  const setDataMaster = ({
    dispatch,
    isLoading,
    departmentParent,
    manager,
  }) => {
    dispatch({
      type: RrhhConstants.Accion.Departments.GET_DATA_MASTER,
      payload: { isLoading, departmentParent, manager },
    });
  };

  return (dispatch, getState) => {
    setDataMaster({
      dispatch: dispatch,
      isLoading: true,
      departmentParent: null,
      manager: null,
    });

    const model = {
      isArea: true,
      isAreaPosition: true,
      isEmployee: true,
    };

    DepartmentService.getDataMaster(model).then(
      ({ data }) => {
        // console.log(data)
        if (data.status === CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            departmentParent: data.data.areas,
            manager: data.data.employees,
          });
        else if (data.status < CoreConstants.HttpResponse.OK)
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            departmentParent: null,
            manager: null,
          });
        else if (data.status > CoreConstants.HttpResponse.OK) {
          setDataMaster({
            dispatch: dispatch,
            isLoading: false,
            departmentParent: null,
            manager: null,
          });
          dispatch(toastAction.warn('Departamento', data.message));
          // dispatch({
          //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
          //   toast: {
          //     titulo: 'Departamento',
          //     mensaje: data.message,
          //     severidad: 'warn',
          //   },
          // });
        }
      },
      () => {
        setDataMaster({
          dispatch: dispatch,
          isLoading: false,
          departmentParent: null,
          manager: null,
        });
      }
    );
  };
};

const generateReport = (payload) => {

  const setDepartments = ({ dispatch, isLoading, data }) => {
    dispatch({
      type: RrhhConstants.Accion.Departments.DOWNLOAD_REPORT_STATUS,
      payload: { isLoading, data },
    });
  };

  return (dispatch, getState) => {
    setDepartments({ dispatch: dispatch, isLoading: true });

    const criteria = {};
    //    const advancedFilter = getState().departmentReducer.advancedFilter;
    const filter = getState().departmentReducer.advancedFilter.filter;

    criteria.name = filter.name;
    criteria.toDate = filter.to;
    criteria.fromDate = filter.from;

    criteria.parentDepartmentId = filter?.departmentParent?.id;
    criteria.managerId = filter.manager?.id;

    DepartmentService.generateReport(criteria)
      .then(({ data }) => {
        setDepartments({ dispatch: dispatch, isLoading: false });
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        FileSaver.saveAs(blob, 'Departamentos.xls');
      })
      
      .catch((err) => {
        setDepartments({ dispatch: dispatch, isLoading: false });
        dispatch(toastAction.error('Departamento', 'Hubo un error al descargar el archivo'));
      });
  };
};


export const DeparmentsAction = {
  toggleAdvancedFilter,
  search,
  updateFilter,
  getDepartmentById,
  saveDepartment,
  getDataMaster,
  resetAfterSaved,
  deleteDepartment,
  generateReport
};
