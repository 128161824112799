import { apiService } from "core/services/ApiService";

const searchBusinessSectors = (criteria) => {
  return apiService.Post("customer/api/BusinessSector/Search", criteria);
}

const saveBusinessSectors = (data) => {
  return apiService.Post("customer/api/BusinessSector/Save", data);
}

const getBusinessSectorById = (businesssectorId) => {
  return apiService.Get("customer/api/BusinessSector/GetBusinessSectorById?id=" + businesssectorId);
}

const generateReport = (payload) => {
  return apiService.Post("customer/api/BusinessSector/GenerateReport", payload, {
    responseType: 'blob',
  })
} 


const deleteBusinessSector = (businesssectorId) => {
  return apiService.Delete("customer/api/BusinessSector/Delete?id=" + businesssectorId);
}

export const BusinessSectorsService = {
  searchBusinessSectors,
  saveBusinessSectors,
  getBusinessSectorById,
  generateReport,
  deleteBusinessSector,
};