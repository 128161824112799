import { CoreConstants } from 'core/commons/CoreConstants';
import { toastAction } from 'core/store/actions/ToastAction';
import { PublicConstants } from 'features/public/commons/PublicConstants';
import { userService } from 'features/public/services/UserService';

function mostrar() {
  return (dispatch) => {
    dispatch({ type: PublicConstants.Accion.SelecEmpresaSede.MOSTRAR });
  };
}
function ocultar() {
  return (dispatch) => {
    dispatch({ type: PublicConstants.Accion.SelecEmpresaSede.OCULTAR });
  };
}

function seleccionar(userInformation, email, password) {
  return (dispatch) => {
    dispatch({ type: PublicConstants.Accion.SelecEmpresaSede.REQUEST });
    userService
      .selecEmpresaSede(
        email,
        password,
        userInformation.empresaId,
        userInformation.sedeId
      )
      .then(
        (model) => {
          dispatch({
            type: PublicConstants.Accion.SelecEmpresaSede.OCULTAR,
          });
          dispatch({
            type: CoreConstants.Accion.Toast.OCULTAR_MENSAJE,
          });

          switch (model.data.status) {
            case CoreConstants.HttpResponse.OK:
              const userInformation = model.data.data;
              dispatch({
                type: CoreConstants.Accion.Login.SUCCESS,
                userInformation,
              });
              return;
            case CoreConstants.HttpResponse.ERROR_FUNTIONAL:
              dispatch(toastAction.warn('Autenticación', 'Las credenciales ingresadas son incorrectas'));
              // dispatch({
              //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
              //   toast: {
              //     titulo: 'Autentcicación',
              //     mensaje: 'Las credenciales ingresadas son incorrectas',
              //     severidad: 'warn',
              //   },
              // });
              return;
            default:
              if (model.data.status > 1)
                dispatch(toastAction.warn('Autenticación', model.data.message));
              // dispatch({
              //   type: CoreConstants.Accion.Toast.MOSTRAR_MENSAJE,
              //   toast: {
              //     titulo: 'Autencicación',
              //     mensaje: model.data.message,
              //     severidad: 'warn',
              //   },
              // });
              return;
          }
        },
        (error) => {
          dispatch({
            type: PublicConstants.Accion.SelecEmpresaSede.FAILURE,
            error,
          });
        }
      );
  };
}

export const LoginSelectCompanySiteComponentAction = {
  mostrar,
  ocultar,
  seleccionar,
};
