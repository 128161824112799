import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import { CoreConstants } from 'core/commons/CoreConstants';
import { BusinessAction } from 'features/customers/store/actions/BusinessAction';
import { BusinessTableData } from 'features/customers/data/business/BusinessTableData';
import validateTypeValue from 'shared/helpers/validateTypeValue';

export const useTableBusiness = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { updateTitle } = useSetTitlePage();
  const history = useHistory();
  const business = useSelector((state) => state.businessReducer.business);

  const companyId = useSelector((state) => state.companiesReducer.currentAction.companyId);
  
  
  /* CURRENT CUSTOMER ------------------- */
  const customerId = Number(params.customerId || companyId);
  const setCurrentCustomer = (customerId) => {
    dispatch(BusinessAction.currentAction({ customerId }));
  };
  const setNewPage = () => {history.push(`/cliente/empresas/editar/${customerId}/negocios/nuevo`);};
  /* TITLE ------------------------- */
  const pageTitle = {
    title: "Clientes",
    subtitle: "Empresas",
    description: 'Negocios',
    previousUrl: `/cliente/empresas/editar/${customerId}`
  };

  useEffect(() => {
    updateTitle(pageTitle);
  }, []);

  /* CURRENT BUSINESS ------------------- */
  const id = useSelector((state) => state.businessReducer.currentAction?.id);
  const setCurrentBusiness = (id) => {
    dispatch(BusinessAction.currentAction({ id }));
  };


  /* TYPE TABLE VIEW ---------------- */
  const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);

  /* LOAD WHEN BUSINESS LIST CHANGES -------------------------- */
  const [tableBusiness, setTableBusiness] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState([]);
  
  useEffect(() => {
    const { data, pagination, loading } = business;
    setTableBusiness(validateTypeValue(data));
    setTablePagination(pagination);
    setTableLoading(loading);
  }, [business]);

  /* HANDLE TABLE HEADER COLUMNS */
  const [selectColumns, setSelectColumns] = useState(
    BusinessTableData.columns
  );

  useEffect(() => {
    if (localStorage.getItem('columnas-negocios')) {
      setSelectColumns(JSON.parse(localStorage.getItem('columnas-negocios')));
    }
  }, []);

  const onColumnToggle = (e, item) => {
    let _selectedColumns = [...selectColumns];

    if (e.checked) _selectedColumns.push(item);
    else _selectedColumns = _selectedColumns.filter((c) => c.field !== e.value);

    if (_selectedColumns.length === 0) return;
    localStorage.setItem('columnas-empresas', JSON.stringify(_selectedColumns));
    setSelectColumns(_selectedColumns);
  };

  /* TABLE HEADER ACTIONS */
  const headerActions = useRef(null);

  /* TABLE CURRENT ACTION ------------- */
  const [currentAction, setCurrentAction] = useState('');
  const onCurrentAction = ({ item: { name } }) => setCurrentAction(name);
  useEffect(() => {
    const action_ = currentAction.toLocaleLowerCase();
    if (action_ === 'editar') {
      history.push(`/cliente/empresas/editar/${customerId}/negocios/editar/${id}`);
    } else if (action_ === 'eliminar') {
      dispatch(BusinessAction.currentAction({ isOpen: true }));
    }
    setCurrentAction('');
  }, [currentAction]);

  /* TABLE ACTIONS -------------------- */
  const options = useSelector(
    (state) => state.businessReducer.actions?.items
  );

  const [tableActions, setTableActions] = useState([]);
  const [showActions, setShowActions] = useState(false);
  const tableCurrentActions = useRef(null);

  useEffect(() => {
    if (options.length > 0) {
      const newActions = options.map((option) => {
        option.label = option.name;
        option.command = onCurrentAction;
        return option;
      });
      setTableActions(newActions);
    }
    setShowActions(options.length > 0);
  }, []);

  /* MODAL DELETE (ACTIONS) */
  const isOpenModalDelete = useSelector((state) => state.businessReducer.currentAction.isOpen);
  const confirmDelete = () => {
    dispatch(BusinessAction.deleteById(id, customerId));
  };
  const cancelDelete = () => {
    dispatch(BusinessAction.currentAction({ isOpen: false, customerId: '', businessId: '' }));
  };

  /* TABLE PAGINATION --------------- */
  const search = useSelector(
    (state) => state.businessReducer.business
  );
  const [tableRows, setTableRows] = useState(10);
  const [tableNum, setTableNum] = useState(0);
  const [page, setPage] = useState(1);
  let currentFilterFields = {
    columnOrder: search.columnOrder,
    order: search.order,
  };

  /* TABLE PAGINATION - FILTER VALUES --------------- */
  const filterValues = useSelector((state) => state.businessReducer.advancedFilter.values);
  const buildFilterValues = () => {
    currentFilterFields['customerId'] = customerId;
    if (filterValues.length > 0) {
      filterValues.forEach(({ field, value, option }) => {
        let currentValue = '';
        const key = field.toLocaleLowerCase();
        if (key.includes('id')) currentValue = option.id;
        else currentValue = value;
        currentFilterFields[field] = currentValue;
      });
    }
  };

  const onChangePage = ({ rows, page }) => {
    buildFilterValues();
    currentFilterFields.page = page + 1;
    currentFilterFields.pageSize = rows;
    setTableRows(rows);
    setTableNum(rows * page);
    setPage(page + 1);
    const fields = currentFilterFields;
    dispatch(BusinessAction.getBusiness({ change: true, fields }));
  };

  /* TABLE ORDER SORT */
  const [tableSort, setTableSort] = useState({
    sortField: 'name',
    sortOrder: '1',
  });
  const onSort = (event) => {
    buildFilterValues();
    const params = {
      first: tableNum,
      rows: tableRows,
      multiSortMeta: [],
      filters: null,
      ...event,
    };

    currentFilterFields.order = event.sortOrder + '' === '1' ? 'asc' : 'desc';
    currentFilterFields.columnOrder = event.sortField;
    currentFilterFields.pageSize = tableRows;
    currentFilterFields.page = page;
    const fields = currentFilterFields;
    dispatch(BusinessAction.getBusiness({ change: true, fields }));
    setTableSort(params);
  };

  const onChangeTab = (e) => {
    if(e == 'info') {
      history.push(`/cliente/empresas/editar/${customerId}`);
    } else {
      if (e == 'task') {
        history.push(`/cliente/empresas/editar/${customerId}/tareas`);
      } else {
        if (e == 'business') {
          history.push(`/cliente/empresas/editar/${customerId}/negocios`);
        } else {
          if (e == 'project') {
            history.push(`/cliente/empresas/editar/${customerId}/proyectos`);
          }
        }
      }
    }
  }

  const generateReport = () => {
    dispatch(BusinessAction.generateReport());
  };

  return {
    headerActions,
    isOpenModalDelete,
    tableActions,
    tableCurrentActions,
    tableBusiness,
    tableLoading,
    tablePagination,
    tableSort,
    tableNum,
    tableRows,
    typeView,
    selectColumns,
    showActions,
    customerId,
    confirmDelete,
    cancelDelete,
    setCurrentBusiness,
    setCurrentCustomer,
    setNewPage,
    setTypeView,
    onChangePage,
    onChangeTab,
    onColumnToggle,
    onSort,
    generateReport
  };
}