import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { RadioButton } from 'primereact/radiobutton';
import { Skeleton } from 'primereact/skeleton';
import { Controller } from 'react-hook-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Editor } from 'primereact/editor';
import { Toast } from 'primereact/toast';
import { SurveyAction } from 'features/survey/store/actions/SurveyAction';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import useSurvey from 'features/survey/hooks/Survey/useSurvey';
import HeaderEditor from 'shared/components/header-editor/HeaderEditor';
import SurveyQuestion from 'features/survey/components/Survey/SurveyQuestion';
import ModalActions from 'features/survey/components/Survey/ModalActions';
// import ModalImportQuestion from 'features/survey/components/Survey/ModalImportQuestion';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { SurveySection } from 'features/survey/components/Survey/SurveySection';
import { Divider } from 'primereact/divider';
import { SurveyQuestionGrid } from 'features/survey/components/Survey/SurveyQuestionGrid';
import { v4 as uuidv4 } from 'uuid';


const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  userSelect: "none",
  background: isDragging ? "rgba(239, 246, 255)" : "none",
  // cursor: isDragging ? "grabbing" : "grab",
});

const getListStyle = isDraggingOver => ({
  border: isDraggingOver ? "1px dashed gray" : "none",
  background: isDraggingOver ? " rgba(239, 246, 255)" : "none",
  padding: 2,
});

const SurveySectionType = Object.freeze({
  Regular: 'STRG',
  Grid: 'STML',
})

const SurveyPage = ({title}) => {
  const {updateTitle } = useSetTitlePage()
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const survey = useSelector((state) => state.surveyReducer.survey);
  const { data: surveyData, isCloseStatus } = useSelector((state) => state.surveyReducer.survey);
  const { data: surveyCopyData } = useSelector((state) => state.surveyReducer.surveyCopy);
  const {categories, surveyTypes, questionTypes, status, surveySectionTypes, isLoading: isLoadingDataMaster} = useSelector((state) => state.surveyReducer.dataMaster);
  const { origin: baseURL } = window.location

  // console.log(surveyCopyData)
  // console.log(questionTypes)
  // console.log(surveyTypes)
  // console.log(isCloseStatus)
  // console.log(surveyData)
  
  const [displayModalAction, setDisplayModalAction] = useState(false)
  // const [displayModalImport, setDisplayModalImport] = useState(false)
  const [actionSelected, setActionSelected] = useState(null)
  const [isFirstLoading, setFirstLoading] = useState(true);
  const [errorLocation, setErrorLocation] = useState(false)
  const toast = useRef(null);
  
  const [visible, setVisible] = useState(false);

  const advancedFilter = useSelector((state) => state.surveyReducer.advancedFilter);
  
  const getTypeSurveyClassic = () => surveyTypes.find(type => type.code === 'STCL')
  const getQuestionTypeText = () => questionTypes.find(question => question.code === 'TX')
  const getQuestionTypeRadio = () => questionTypes.find(question => question.code === 'CV')
  
  let defaultValues = {
    // type: {id: 193, code: "STCL", description: "Clásico"},
    type: '',
    title: '',
    location: advancedFilter.filter.location,
    description: '',
    link: '',
    category: '',
    createdBy: '',
    creationDate: '',
    updatedBy: '',
    updateDate: '',
    status: '',
  };


  const setSurveySectionRegularId = () => surveySectionTypes.find(sectionType => sectionType.code === SurveySectionType.Regular)?.id
  const setSurveySectionGridId = () => surveySectionTypes.find(sectionType => sectionType.code === SurveySectionType.Grid)?.id

  const getSurveySectionTypeCodeById = (id) => surveySectionTypes.find(sectionType => sectionType.id === id)?.code 

  const { 
    // Form
    control,
    errors,
    valuesFields,
    existError,
    setValue,
    reset,
    handleSubmit,
    validateLength,
    
    // Question
    // listQuestion,
    // setListQuestion,
    // addNewQuestion,
    // deleteQuestion,
    
    // Section
    listSections,
    setListSections,
    addNewSectionAfterSelectedSection,
    addNewSectionGridAfterSelectedSection,
    deleteSection,
    changeSectionToGrid,
    changeSectionToRegular,

    // Section - Question
    addNewQuestionInSection,
    addNewQuestionAfterSelectedQuestion,
    deleteQuestionInSection,

    // Dnd
    onDragEnd,
    onDragSectionEnd,

    // //* Constans
    // SurveySectionType

  } = useSurvey(defaultValues, getQuestionTypeText, getQuestionTypeRadio, isCloseStatus, setSurveySectionRegularId, setSurveySectionGridId)

  // console.log(listSections)

  const redirectToSearch = () => {
    history.push("/encuestas/encuesta/");
    onHide()
  }

  // const redirectToSurveyPublic = () => {
  //   history.push(`/encuestas/encuesta/r/${surveyData.link}`);
  // }

  const header = HeaderEditor();

  const reject = () => {
    setVisible(false);
  };

  const onHide = () => {
    // setValue("name", ''); 
    reset(defaultValues)
    // setListQuestion([])
    // setListSections([])
    dispatch(SurveyAction.resetAfterSaved());
  }

  

  useEffect(()=> {
    updateTitle({
      title: "Gestión de Encuestas",
      subtitle: "Encuestas",
      description: title,
      previousUrl: "/encuestas/encuesta/"
    })
    if (isFirstLoading) {
      setFirstLoading(false);
    }
    if (!params.id) onHide()
    if (params.id)  dispatch(SurveyAction.getSurveyById(params.id));

    return () => {
      onHide()
    }
  }, [params.id])

  useEffect(() => {
    if (!params.id) {
        setValue("type", getTypeSurveyClassic()); 
    }
  }, [surveyTypes, params.id]);

  useEffect(() => {
    if (!params.id && !isLoadingDataMaster && surveySectionTypes.length > 0 && questionTypes.length > 0) {
      const firstSection = {
        id: uuidv4(),
        title: 'Sección sin título',
        description: '',
        position: 1,
        // listQuestion: []
        listQuestion: [
          {
            id: uuidv4(),
            body: "",
            questionTypeId: getQuestionTypeText(),
            surveyQuestionOptions: [],
            extraOptions: [],
            rankingQuantity: 0,
            icon: null,
            position: 1,
            isRequired: false
          }
        ],
        // type: 'regular',
        sectionTypeId: setSurveySectionRegularId(),
      }

      setListSections([firstSection])
    }
  }, [isLoadingDataMaster, params.id, surveySectionTypes, questionTypes]);

  const getTypeSurvey = (surveyId) => surveyTypes.find(type => type.id === surveyId)
  const getCategorySurvey = (categoryId) => categories.find(category => category.id === categoryId)
  const getQuestionType = (questionTypeId) => questionTypes.find(question => question.id === questionTypeId)
  const getStatus = (statusId) => status.find(question => question.id === statusId)
  
  //* cambiar nombre de campo option -> surveyQuestionOptions
  //* quitar otra y ninguna de las anteriores de las surveyQuestionOptions y crear un campo extraOptions
  //* Al guardar volver a poner las extraOptions en surveyQuestionOptions
  const getNewQuestions = (questions) => {
    const newListQuestion = questions.map((question) => {
      const uuid = uuidv4()
      const modelQuestion = {...question}
      const { options, id, surveySectionId, ...restQuestion } = modelQuestion
      restQuestion.id = surveyCopyData ? uuid : id
      restQuestion.surveySectionId = surveyCopyData ? undefined : surveySectionId
      const getExtraOptions = options.filter(option => option.caption === 'Otra' || option.caption === 'Ninguna de las Anteriores' )
      const getOptionsWithoutExtraOptions = options.filter(option => !(option.caption === 'Otra' || option.caption === 'Ninguna de las Anteriores') )
      const getOptionsWithoutExtraOptionsAndQuestionId = getOptionsWithoutExtraOptions.map((option) => {
        const uuidOption = uuidv4()
        const {questionId, id, question, ...restOption} = option
        restOption.id = surveyCopyData ? uuidOption : id
        return restOption
      })
      restQuestion.surveyQuestionOptions = surveyCopyData ? [...getOptionsWithoutExtraOptionsAndQuestionId] : [...getOptionsWithoutExtraOptions]
      restQuestion.extraOptions = [...getExtraOptions]
      restQuestion.questionTypeId = getQuestionType(modelQuestion.questionTypeId)
      return restQuestion
    })

    return newListQuestion
  }
  
  const getSections = (sections) => {
    const newListSections = sections.map((section) => {
      const uuid = uuidv4()
      return {
        // id: section.id,
        // surveyId: section.surveyId,
        id: surveyCopyData ? uuid : section.id,
        surveyId: surveyCopyData ? undefined : section.surveyId,
        title: section.title,
        description: section.description,
        position: section.position,
        listQuestion: [...getNewQuestions(section.questions)],
        sectionTypeId: section.sectionTypeId,
      }
    })

    return newListSections
  }

  // surveyData al editar
  useEffect(() => {
    if (surveyData) {
        setValue("createdBy", surveyData.creationUser); 
        setValue("creationDate", surveyData.creationDate ? new Date(surveyData.creationDate) : ''); 
        setValue("updatedBy", surveyData.modificationUser); 
        setValue("updateDate", surveyData.modificationDate ? new Date(surveyData.modificationDate) : '') ;  
        setValue("title", surveyData.title); 
        setValue("location", surveyData.location); 
        setValue("description", surveyData.description); 
        setValue("link", surveyData.link ? `${baseURL}/#/encuestas/encuesta/r/${surveyData.link}` : null); 
        dispatch(SurveyAction.setSurveyStatus(surveyData.status));
    }
  }, [surveyData]);

  useEffect(() => {
    if (surveyData) {
        setValue("type", getTypeSurvey(surveyData.surveyTypeId)); 
        setValue("category", getCategorySurvey(surveyData.categoryId)); 
        // setListQuestion(getNewQuestions(surveyData.questions))
        setListSections(getSections(surveyData.sections))
        setValue("status", getStatus(surveyData.status.id)); 
    }
  }, [surveyData, surveyTypes, categories, status]);

  // console.log(surveyCopyData)
  // surveyCopyData al copiar
  useEffect(() => {
    if (surveyCopyData) {
        setValue("createdBy", surveyCopyData.creationUser); 
        setValue("creationDate", surveyCopyData.creationDate ? new Date(surveyCopyData.creationDate) : ''); 
        setValue("updatedBy", surveyCopyData.modificationUser); 
        setValue("updateDate", surveyCopyData.modificationDate ? new Date(surveyCopyData.modificationDate) : '') ;  
        setValue("title", surveyCopyData.title); 
        setValue("location", surveyCopyData.location); 
        setValue("description", surveyCopyData.description); 
        setValue("link", surveyCopyData.link ? `${baseURL}/#/encuestas/encuesta/r/${surveyCopyData.link}` : null); 
    }
  }, [surveyCopyData]);

  useEffect(() => {
    if (surveyCopyData) {
        setValue("type", getTypeSurvey(surveyCopyData.surveyTypeId)); 
        setValue("category", getCategorySurvey(surveyCopyData.categoryId)); 
        // setListQuestion(getNewQuestions(surveyCopyData.questions))
        setListSections(getSections(surveyCopyData.sections))
    }
  }, [surveyCopyData, surveyTypes, categories]);

  useEffect(() => {
    if (survey?.isSaved) redirectToSearch();
  }, [survey]);

  useEffect(() => {
    dispatch(SurveyAction.getDataMaster());
  }, [])

  const openModal = (action) => {
    if (action === 'share') dispatch(SurveyAction.shareSurvey(params.id));
    setActionSelected(action)
    setDisplayModalAction(true)
  }

  const openPreview = () => {
    history.push(`/encuestas/encuesta/vista-previa/${params.id}`)
  }

  const addingExtraOptions = (listQuestion) => {

    const copyListQuestion = [...listQuestion]

    const newListQuestion = copyListQuestion.map((question) => {

      question.includeOptionOther = false
      question.includeOptionNone = false

      //* Quitar campo id dentro de los extraOptions
      const newListExtraOptions = question.extraOptions.map((extraOption) => {
        if (extraOption.caption === 'Otra') question.includeOptionOther = true
        if (extraOption.caption === 'Ninguna de las Anteriores') question.includeOptionNone = true
        
        // const {label, id, ...restExtraOption} = extraOption
        const {id, ...restExtraOption} = extraOption
        if (params.id && !isNaN(question.id)) {
          restExtraOption.questionId = question.id
          if (!isNaN(id)) restExtraOption.id = id
        }
        return restExtraOption
      })

      //* Quitar campo id dentro de las surveyQuestionOptions
      const newSurveyQuestionOptions = question.surveyQuestionOptions.map((option) => {
        const {id, ...restSurveyQuestionOptions} = option
        if (params.id && !isNaN(question.id)) {
          restSurveyQuestionOptions.questionId = question.id
          if (!isNaN(id)) restSurveyQuestionOptions.id = id
        }
        return restSurveyQuestionOptions
      })

      //* Agregar las extraOptions al campo surveyQuestionOptions y quitar los extraOptions
      question.surveyQuestionOptions = [...newSurveyQuestionOptions, ...newListExtraOptions]
      question.questionTypeId = question.questionTypeId.id ? question.questionTypeId.id : question.questionTypeId
      const {extraOptions, id, ...restOption} = question
      if (params.id && !isNaN(id)) restOption.id = id
      return restOption
    })

    return newListQuestion
  }

  const getModelSections = (sections) => {
    const newListSections = sections.map((section) => {
      const {listQuestion, ...restSection} = section
      return {
        ...restSection,
        id: isNaN(section.id) ? undefined : section.id,
        surveyId: params.id ? section.surveyId : undefined,
        questions: addingExtraOptions(listQuestion)
      }
    })

    return newListSections
  }

  const onSubmit = (data) => {
    if (valuesFields.location === '' || !valuesFields.location) {
      setErrorLocation(true)
      return
    }
    setErrorLocation(false)
    const model = {
      surveyTypeId: data.type.id,
      title: data.title,
      categoryId: data.category.id,
      locationId: data.location.id,
      description: data.description,
      // link: data.link, //* No se envía
      // surveyDate: data.surveyDate, //* Se envía
      // surveyQuestions: addingExtraOptions(listQuestion),
      sections: getModelSections(listSections),
    }
    if (params.id) {
      model.id = Number(params.id)
      dispatch(SurveyAction.saveSurvey(model));
      return
    }
    dispatch(SurveyAction.saveSurvey(model));
  };

  return (
    <div className="bg-white">
      <form 
        // className="grid grid-cols-12 gap-x-3"
        className=""
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form-modal p-0 col-span-12 md:col-span-9 2xl:col-span-10">
          <Panel header="DATOS DE LA ENCUESTA" toggleable>
            <div className="">
              <div className='mt-2 mb-5 grid grid-cols-12 gap-5'>
                {survey.isLoading ? (
                  <Skeleton className='col-span-12 lg:col-span-8' height='2rem' />
                    ): (
                      <div className='flex gap-x-2 col-span-12 lg:col-span-8'>
                        <span className="p-float-label w-full ">
                            <Controller
                                control={control}
                                // rules={{required: 'La ubicación es requerida', }}
                                render={({
                                    field: { onChange, onBlur, value },
                                }) => (
                                      <InputText
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value?.data ? value?.data : value?.name}
                                          className={errors?.location ? 'p-invalid w-full disabled' : 'w-full disabled'}
                                          disabled
                                      />
                                )}
                                name="location"
                            />
                            <label htmlFor="location">Ubicación: *</label>
                        </span>
                        <button 
                          type='button'
                          onClick={() => openModal('locate')}
                          disabled={isCloseStatus}
                          className="p-inputgroup-addon p-0 cursor-pointer hover:text-gray-800 hover:bg-gray-200">
                            <i className="pi pi-folder text-xl"></i>
                        </button>
                      </div>
                )}
                {
                  valuesFields?.status?.description && (
                    <span className="w-full col-span-12 lg:col-span-4 flex items-center justify-start lg:justify-center">

                      <p className='font-bold'>Estado: {valuesFields?.status?.description.toUpperCase()}</p>
                    </span>
                  ) 
                }
              </div>
              {survey.isLoading ? (
                  <Skeleton />
                ): (
                  <div className='flex flex-col'>
                    <p className=''>Seleccione un tipo de Encuesta</p>
                    <span className="p-float-label w-full my-2 ">
                        <Controller
                            control={control}
                            rules={{required: 'El origen del indicador es requerido'}}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                              <div className='w-full flex flex-wrap md:flex-nowrap justify-evenly gap-4'>
                                {
                                  surveyTypes.map((source) => (
                                      <div key={source.description + source.id} className='flex flex-col w-52 items-center gap-y-2 shadow-md p-2'>
                                        <div className='flex justify-center items-center gap-x-2'>
                                          <RadioButton inputId={source.id} disabled={isCloseStatus} value={source} onChange={(e) => onChange(e.value)} checked={valuesFields.type?.code === source.code} />
                                          <p>{source.description}</p>
                                        </div>
                                        <div className='flex justify-center items-center pb-2'>
                                          {/* <i className={`pi ${source.icon} text-8xl text-gray-600`}></i> */}
                                          <i className={`pi pi-file text-6xl text-gray-600`}></i>
                                        </div>
                                      </div>
                                    )
                                  )
                                }
                              </div>
                            )}
                            name="type"
                        />
                    </span>
                  </div>
              )}
              {survey.isLoading ? (
                <Skeleton />
                  ): (
                    <span className="p-float-label w-full my-5">
                        <Controller
                            control={control}
                            rules={{required: 'El Título es requerido'}}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                              <InputText
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                  className={errors?.title ? 'p-invalid w-full' : 'w-full'}
                                  readOnly={isCloseStatus}
                                  maxLength={100}
                              />
                            )}
                            name="title"
                        />
                        <label htmlFor="title">Título: *</label>
                    </span>
                )}
              {survey.isLoading ? (
                <Skeleton />
                  ): (
                    <span className="p-float-label w-full my-5">
                        <Controller
                            control={control}
                            // rules={{required: 'La descripción es requerida'}}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                              <>
                                <Editor
                                    headerTemplate={header}
                                    className={errors?.description ? 'p-invalid w-full survey-editor' : 'w-full survey-editor'}
                                    value={value} onTextChange={(e) => onChange(e.htmlValue)}
                                    placeholder="Descripción de la encuesta"
                                    readOnly={surveyData?.status.code === 'SQCL'}
                                />
                                <p className="font-semibold text-base text-gray-500 lg:mt-1 mt-3 text-right">
                                    Caracteres{' '}
                                    {validateLength(valuesFields.text)}{' '}/ 4000{' '}
                                </p>
                              </>
                            )}
                            name="description"
                        />
                    </span>
                )}

              {survey.isLoading ? (
                <Skeleton />
                  ): (
                    <span className="p-float-label w-full my-5">
                        <Controller
                            control={control}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                              <InputText
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                  className='disabled'
                                  readOnly
                                  // onClick={surveyData ? redirectToSurveyPublic : () => {}}
                              />
                            )}
                            name="link"
                        />
                        <label htmlFor="link">Enlace:</label>
                    </span>
                )}
            </div>
          </Panel>
          <Panel header="PREGUNTAS" toggleable>
            <div className="">
              <DragDropContext 
                onDragEnd={onDragSectionEnd}
              >
                {
                  listSections.map((section, index) => (
                    <div className='flex flex-col mb-2' key={section.id}>
                      <SurveySection 
                        section={section}
                        index={index + 1}
                        deleteSection={deleteSection}
                        addNewSectionAfterSelectedSection={addNewSectionAfterSelectedSection}
                        addNewSectionGridAfterSelectedSection={addNewSectionGridAfterSelectedSection}
                        listSections={listSections}
                        setListSections={setListSections}
                        key={section.id}
                        addNewQuestionInSection={addNewQuestionInSection}
                        onDragEnd={onDragEnd}
                        SurveySectionType={SurveySectionType}
                        getSurveySectionTypeCodeById={getSurveySectionTypeCodeById}
                        changeSectionToGrid={changeSectionToGrid}
                        changeSectionToRegular={changeSectionToRegular}
                    />
                      
                      {
                        (!section.sectionTypeId || getSurveySectionTypeCodeById(section.sectionTypeId) === SurveySectionType.Regular ) ? (
                          <Droppable droppableId={`droppable_sections_${section.id}`}>
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {
                                  section.listQuestion.map((question, index) => (
                                    <Draggable 
                                      key={question.id} 
                                      draggableId={isCloseStatus ? question.id : `question_${question.id}`} 
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                          className='flex flex-col'
                                        >
                                          <SurveyQuestion 
                                            key={question.id}
                                            question={question} 
                                            index={index + 1} 
                                            surveyData={surveyData} 
                                            
                                            listSections={listSections}
                                            setListSections={setListSections}
                                            sectionListQuestion={section.listQuestion}
                                            addNewQuestionAfterSelectedQuestion={addNewQuestionAfterSelectedQuestion}
                                            deleteQuestionInSection={deleteQuestionInSection}
                                            sectionId={section.id}
                                            
                                            provided={provided} 
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  ))
                                }
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        ) : (
                          <SurveyQuestionGrid 
                            section={section}
                            listSections={listSections}
                            setListSections={setListSections}
                            getQuestionTypeRadio={getQuestionTypeRadio}
                            deleteQuestionInSection={deleteQuestionInSection}
                          />
                        )
                      }
                      {
                        index !== listSections.length - 1 && (
                          <Divider />
                        )
                      }
                    </div>
                  ))
                }
              </DragDropContext>
              {
                (existError || (errorLocation && valuesFields.location === undefined)) && <div className='message error'>
                    <ul>
                      {errors.title ? (
                          <>
                              <li className="p-error">{errors.title.message}</li>
                          </>
                      ) : null}
                      {errors.category ? (
                          <>
                              <li className="p-error">{errors.category.message}</li>
                          </>
                      ) : null}
                        {/* {(errorLocation && (valuesFields.location === '' || valuesFields.location === undefined)) ? ( */}
                        {(errorLocation && valuesFields.location === undefined) ? (
                            <>
                                <li className="p-error">La ubicación es requerida</li>
                            </>
                        ) : null}
                    </ul>
                </div>
              }
              <div className="flex justify-between gap-4 mt-3">
                  <Button
                      // icon="pi pi-times"
                      type="button"
                      label="Vista Previa"
                      onClick={() => openPreview()}
                      className={`sig-button sig-success mt-4 ${surveyData ? '' : 'invisible'}`}
                      disabled={isCloseStatus}
                  />
                  <div className='flex gap-x-4'>
                      {/* <div className='flex justify-center w-full mt-7'> */}
                        <Button
                          icon="pi pi-share-alt"
                          label="Compartir"
                          type='button'
                          onClick={() => openModal('share')}
                          disabled={!surveyData || isCloseStatus}
                          className="sig-button sig-success mt-4"
                        />
                      {/* </div> */}
                      <Button
                          icon="pi pi-times"
                          type="button"
                          label="Cancelar"
                          onClick={() => setVisible(true)}
                          className="sig-button sig-secondary mt-4"
                      />
                      <Button
                          icon="pi pi-save"
                          type="submit"
                          disabled={(existError || isCloseStatus) ? true : false}
                          label="Guardar"
                          loading={survey?.isSaving || survey?.isLoading}
                          className="sig-button sig-primary mt-4"
                      />
                  </div>
              </div>
            </div>
          </Panel>
        </div>
      </form>
      <ModalActions
        displayModal={displayModalAction}  setDisplayModal={setDisplayModalAction} 
        actionSelected={actionSelected} setActionSelected={setActionSelected}
        toast={toast}
        setValueForm={setValue}
      />
      {/* <ModalImportQuestion
        displayModal={displayModalImport}  setDisplayModal={setDisplayModalImport} 
        listQuestion={listQuestion}  setListQuestion={setListQuestion}
      /> */}
      <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="¿Está seguro que desea cancelar?"
          header="Cancelar"
          icon="pi pi-exclamation-triangle"
          accept={redirectToSearch}
          reject={reject}
      />
      <Toast ref={toast} />
    </div>
  )
}

export default SurveyPage