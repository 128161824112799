import { RegistrationFormConstans } from 'features/registrationForm/commons/RegistrationFormConstans'; 

import { CoreConstants } from 'core/commons/CoreConstants';

import { toastAction } from "core/store/actions/ToastAction";

import { RegistrationFormService } from 'features/registrationForm/services/RegistrationFormService';



const setGetDataMaster = (payload) => {
  return (dispatch) => {
    dispatch({
      type: RegistrationFormConstans.Accion.RegistrationDataMaster.GET_DATA_MASTER,
      payload,
    });
  };
};

const getDataMaster = () => {
  return (dispatch) => {
    dispatch(setGetDataMaster({ isLoading: true }));
    RegistrationFormService.getDataMaster()
    .then(({data})=>{
      dispatch(setGetDataMaster({ data: data.data, isLoading: false }))
    })
  }
};

const setGetDataCourse = (payload) => {
  return (dispatch) => {
    dispatch({
      type: RegistrationFormConstans.Accion.RegistrationDataMaster.GET_DATA_FORM,
      payload,
    });
  };
};

const getDataCourse = (token) => {
  return (dispatch) => {
    dispatch(setGetDataCourse({ isLoading: true }));
    RegistrationFormService.getCourseRegistrationForm(token)
    .then(({data})=>{
      if(data.status === CoreConstants.HttpResponse.OK){
        dispatch(setGetDataCourse({ data: data.data, isLoading: false, error: false }))
      }else{
        dispatch(setGetDataCourse({ isLoading: false, error: true }))
        dispatch(setToast({severity: 'error', summary: 'Registro', detail: 'Hubo un error al cargar información del curso'}))
      }
    }).catch(e=>{
      dispatch(setGetDataCourse({ isLoading: false, error: true }))
      dispatch(setToast({severity: 'error', summary: 'Registro', detail: 'Hubo un error al cargar información del curso'}))
    })
  }
};

const setSaveForm = (payload) => {
  return (dispatch) => {
    dispatch({
      type: RegistrationFormConstans.Accion.RegistrationDataMaster.SAVE_FORM,
      payload
    })
  }
}

const setToast = (payload) => {
  return (dispatch) => {
    dispatch({
      type: RegistrationFormConstans.Accion.RegistrationDataMaster.TOAST,
      payload
    })
  }
}

const submitForm = (form) => {
  return (dispatch) => {
    dispatch(setSaveForm({isLoading: true, isSaved: false}))
    RegistrationFormService.submitRegistrationForm(form).then(({data})=>{
      if(data.status === CoreConstants.HttpResponse.OK){
        dispatch(setToast({severity: 'success', summary: 'Registro', detail: 'Se registró su inscripción'}))
        dispatch(setSaveForm({isLoading: false, isSaved: true, message: data.data}))
      }else if(data.status === CoreConstants.HttpResponse.ERROR_TECHNICAL){
        dispatch(setToast({severity: 'warn', summary: 'Registro', detail: data.message}))
        dispatch(setSaveForm({isLoading: false}))
      }else if(data.status === CoreConstants.HttpResponse.REQUIRED_FIELDS){
        dispatch(setToast({severity: 'warn', summary: 'Registro', detail: data.message}))
        dispatch(setSaveForm({isLoading: false}))
      }
      else if(data.status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
        dispatch(setToast({severity: 'warn', summary: 'Registro', detail: data.message}))
        dispatch(setSaveForm({isLoading: false}))
      }else{
        dispatch(setToast({severity: 'error', summary: 'Registro', detail: data.message}))
        dispatch(setSaveForm({isLoading: false}))
      }
    }).catch((error)=>{
      dispatch(setSaveForm({isLoading: false}))
    });
  }
}


export const RegistrationFormAction = {
  getDataMaster,
  getDataCourse,
  submitForm,
}