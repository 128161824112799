/* eslint-disable import/no-anonymous-default-export */

import { IndicatorsConstants } from "features/indicators/commons/IndicatorsConstans";

const initialState = {
  dataMaster: {
  isLoading: false,
  // academicDegreeParent: null,
  // manager: null
  },
  controlsDataMaster: {
    data: null,
    isLoading: false
  },
  managment: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false
  },
  dataTableIndicator: {
    data: null,
    isLoading: false
  },
  dataGraphicsIndicator: {
    data: {
        dataSets: [],
        labels: []
    },
    isLoading: false
  },
  managments: {
    isLoading: false,
    data: null
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: 'name',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {}
  },
  optionsDropDown:{
    data: [],
    isLoading: false
  }
}


export default (state = initialState, action) => {
  switch (action.type) {
    case IndicatorsConstants.Accion.Managment.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
      case IndicatorsConstants.Accion.Managment.UPDATE_FILTER:
        return {
            ...state,
            advancedFilter: state.advancedFilter
        };

    case IndicatorsConstants.Accion.Managment.SEARCH_MANAGMENTS:
        return {
            ...state,
            managments: {
                isLoading: action.payload.isLoading,
                data: action.payload.data
            }
        };
    case IndicatorsConstants.Accion.Managment.GET_MANAGMENT_TABLE_BY_ID:
        return {
            ...state,
            dataTableIndicator: {
                isLoading: action.payload.isLoading,
                data: action.payload.data
            }
        };
    case IndicatorsConstants.Accion.Managment.GET_MANAGMENT_GRAPHICS_BY_ID:
        return {
            ...state,
            dataGraphicsIndicator: {
                isLoading: action.payload.isLoading,
                data: action.payload.data
            }
        };
    case IndicatorsConstants.Accion.Managment.GET_MANAGMENT_BY_ID:
      return {
          ...state,
          managment: {
              data: action.payload.data,
              isSaving: false,
              isLoading: action.payload.isLoading
          }
        };

    case IndicatorsConstants.Accion.Managment.SAVE_MANAGMENT:
        return {
            ...state,
            managment: {
                data: action.payload.currentManagment,
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };
    case IndicatorsConstants.Accion.Managment.GET_DATAMASTER:
        return {
            ...state,
            controlsDataMaster: {
                data: action.payload.data,
                isLoading: action.payload.isLoading
            }
        };

    case IndicatorsConstants.Accion.Managment.DELETE_MANAGMENT:
        return {
            ...state,
            managment: {
                isDeleted: action.payload.isDeleted
            }
        };
    case IndicatorsConstants.Accion.Managment.RESET_DATA_INDICATOR:
        return {
            ...state,
            dataTableIndicator: {
                data: null,
                isLoading: false
            },
            dataGraphicsIndicator: {
                data: {
                    dataSets: [],
                    labels: []
                },
                isLoading: false
              }
        }

    case IndicatorsConstants.Accion.Managment.RESET_AFTER_SAVED:

        return {
            ...state,
            managment: {
                data: null,
                isSaving: false,
                isSaved: false,
                isLoading: false,
                isDeleted: false
            }
        };

      case IndicatorsConstants.Accion.Managment.GET_DATA_MASTER:
          return {
              ...state,
              dataMaster: {
                  pipelineParent: action.payload.pipelineParent,
                  manager: action.payload.manager,
                  isLoading: action.payload.isLoading
              }
          };

      case IndicatorsConstants.Accion.Managment.DOWNLOAD_REPORT_STATUS:
          return {
              ...state,
              managment: {
                  ...state,
                  isLoading: action.payload.isLoading,
              },
          };
      
      default: 
      return state;
  }

}