import React, { useEffect } from 'react'
import { SurveyAction } from 'features/survey/store/actions/SurveyAction';
import { Divider } from 'primereact/divider';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';

export const SurveyAnswers = () => {

  // surveyId answerId
  const params = useParams();
  const dispatch = useDispatch();
  const { updateTitle } = useSetTitlePage();
  const { data: surveyDataAnswers } = useSelector((state) => state.surveyReducer.surveyAnswerQuestion);
  const { title: surveyTitle } = useSelector((state) => state.surveyReducer.surveySummary);
  
  useEffect(() => {
    updateTitle({
      title: "Gestión de Encuestas",
      subtitle: "Respuestas",
      // description: title,
      // previousUrl: "/encuestas/encuesta/"
    })
    // if (params.id)  dispatch(SurveyAction.getSurveySummaryById(params.surveyId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.surveyId]);

  useEffect(() => {
    if (params.surveyId && params.answerId) {
      dispatch(SurveyAction.getSurveySummaryById(params.surveyId))
      dispatch(SurveyAction.getSurveyAnswerById(params.surveyId, params.answerId));

      // dispatch(SurveyAction.getSurveySummaryById(params.surveyId))
    }
  }, [params.surveyId, params.answerId, dispatch]);

  return (
    <div className='grid grid-cols-12 gap-x-4'>
        <div className='col-span-10 flex flex-col gap-y-0'>
          <h1 className='font-semibold text-lg'>{surveyTitle}</h1>
          {/* PREGUNTAS */}
          <div className='flex flex-col'>
            {
              // surveyDataAnswers?.map((question, index) => (
              //   <div key={`question-${index}`}>
              //     <Divider />
              //     <div className='flex flex-col gap-y-2'>
              //       <h1 className='font-semibold text-lg'>
              //         {/* {question.question} */}
              //         {question.questionName}
              //       </h1>
              //       <div className='text-sm flex flex-col gap-y-1'>
              //         <div>
              //           {
              //             question?.values.labels.map((answer) => (
              //               <p key={answer}>{question.code !== 'TX' ? '* ' + answer : answer}</p>
              //             ))
              //           }
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // ))
              surveyDataAnswers?.map((question, index) => (
                <div key={`question-${index}`}>
                  <Divider />
                  <div className='flex flex-col gap-y-2'>
                    <h1 className='font-semibold text-lg'>
                      {/* {question.question} */}
                      <div dangerouslySetInnerHTML={{ __html: question.question }} />
                    </h1>
                    <div className='text-sm flex flex-col gap-y-1'>
                      <div>
                        {
                          question?.answers.map((answer) => (
                            <p>{question.questionType !== 'TX' ? '* ' + answer : answer}</p>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
  )
}
