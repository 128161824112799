import { PrevisualizationRender } from 'features/configuration/components/processes/Formulario/controllers/PrevisualizationRender';
import React from 'react'
import { Draggable } from 'react-beautiful-dnd';

export const ComponentFormularyCustom = ({input, index, getItemStyleZone, setInputSelected, menu, setTypeModal, dropRows, addRows, disabled}) => {
  return (
    <Draggable 
                                                  key={`drop-${input.id}`}
                                                  draggableId={`${input.id}`}
                                                  index={index} 
                                                  isDragDisabled={disabled}
                                              >
                                                {
                                                  (draggableProvided, snapshot) => (
                                                    <div 
                                                      ref={draggableProvided.innerRef}
                                                      {...draggableProvided.draggableProps} 
                                                      {...draggableProvided.dragHandleProps}
                                                      style={getItemStyleZone(
                                                        snapshot.isDragging,
                                                        draggableProvided.draggableProps.style
                                                      )}
                                                      className={`w-full ${!disabled&&'input-dinamic-hover'} py-3 px-5 block`} key={input.id}
                                                    >
                                                      <div className='flex justify-between'>
                                                        {
                                                          (input.typeOptionId!=="ETIQUETA")?<p className='text-sm text-gray-300'>{input.fieldLabel} {input.required ? '*' : ''}</p>:<p></p>
                                                        }
                                                        {
                                                          !disabled&&(
                                                            <i
                                                              className="pi pi-ellipsis-v cursor-pointer"
                                                              onClick={(event) => {
                                                                  setInputSelected(input);
                                                                  menu.current.toggle(event)
                                                                  const idsModal1 = ['ID','TEXTO','TEXTO_LARGO','TEXTO_DINAMICO','NUMERO','FECHA','FECHA_HORA','IMAGEN','ADJUNTAR_ARCHIVO','ETIQUETA']
                                                                  const idsModal2 = ['LISTA_DESPLEGABLE','SELECCION_MULTIPLE','SELECCION_SIMPLE','TABLA','MONEDA']
                                                                  if (idsModal1.includes(input.typeOptionId)) {
                                                                    setTypeModal(1)
                                                                  }else if (idsModal2.includes(input.typeOptionId)) {
                                                                    setTypeModal(2)
                                                                  }
                                                              }}
                                                            ></i>
                                                          )
                                                        }
                                                      </div>
                                                    <PrevisualizationRender input={input} dropRows={dropRows} addRows={addRows} index={index} disabled={disabled}/>
                                                    </div>
                                                  )
                                                }
                                              </Draggable>
  )
}
