import { CoreConstants } from "core/commons/CoreConstants";
import { toastAction } from "core/store/actions/ToastAction";
import { TaskService } from "features/customers/services/TaskService";
import moment from "moment";

export const fetchTask = async({page = 1, documentId, size}) => {
  const fields = {
    page: page,
    pageSize: size,
    columnOrder: "title",
    order: "asc",
    documentId: documentId,
    sourceId: 317,
  }

  const { data }  = await TaskService.searchTaskByCustomer({fields, change: true})
  const status = data?.status
  if (status === CoreConstants.HttpResponse.OK) {
    const { results, rowCount } = data.data;

    results.forEach((result) => {
      result.scheduleDate = result.scheduleDate
        ? moment(result.scheduleDate.substring(0, 10)).format(
            CoreConstants.Format.LOCALE
          )
        : "";
      result.endDate = result.endDate
        ? moment(result.endDate.substring(0, 10)).format(
            CoreConstants.Format.LOCALE
          )
        : "";
    });
    return {results, rowCount};
  }
}

export const deleteTaskTableControl = async({dispatch, taskId, documentId, getTasks}) => {
  const { data } = await TaskService.deleteTask(taskId)
  const status = data.status
  if (status === CoreConstants.HttpResponse.OK) {
    dispatch(toastAction.success("Tareas", "Tarea Eliminada"));
    await getTasks(documentId)
  }
}