import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { SelectButton } from 'primereact/selectbutton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { ConfirmDialog } from 'primereact/confirmdialog';

import moment from 'moment';

import { CoreConstants } from 'core/commons/CoreConstants';
import typeViews from "shared/utils/typeViews";
import { RiskEvaluationAction } from 'features/riskManagement/store/actions/RiskEvaluationAction';
import { Paginator } from 'primereact/paginator';

const TableComponent = () => {

	/* TYPE TABLE VIEW ---------------- */
  const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);

	const menu = useRef(null)
	const history = useHistory()
	const [select, setSelect] = useState({})
	const [showDelete, setShowDelete] = useState(false)
	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState(null);
	const [isFirstLoading, setFirstLoading] = useState(true); 
	const dispatch = useDispatch()
	const { data } = useSelector( state => state.riskEvaluationReducer.evaluations)
	const advancedFilter = useSelector((state) => state.riskEvaluationReducer.advancedFilter);
	const { idModelo } = useParams()

	useEffect(() => {
		if (isFirstLoading) {
				setFirstLoading(false);
				setSortField("name");
				setSortOrder(1);
				dispatch(RiskEvaluationAction.search(idModelo));
		}
}, []);

	const items = [
		{
				label: 'Editar',
				icon: 'pi pi-pencil',
				command: (e) => {
					history.push(`/gestion-riesgos/modelo/${idModelo}/evaluaciones/evaluacion/${select.id}`)
			}
		},
		{
			label: 'Eliminar',
			icon: 'pi pi-times',
			command: (e) => {
				setShowDelete(true)
			}
		},
	]

	const TableBodyActions = (selectedItem) => {
		return (
			<i
				onClick={(event) => {
					menu?.current?.toggle(event); 
					setSelect(selectedItem)
				}}
				className="pi pi-ellipsis-v cursor-pointer"
			></i>
		);
	};

	const onPage = (event) => {
		advancedFilter.page = event.page + 1;
		dispatch(RiskEvaluationAction.updateFilter(advancedFilter));
		dispatch(RiskEvaluationAction.search(idModelo));
	}

	const onSort = (e) => {

		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
		
		advancedFilter.columnOrder = e.sortField;
		advancedFilter.order = e.sortOrder === 1 ? 'asc' : 'desc';
		advancedFilter.page = 1;
		dispatch(RiskEvaluationAction.updateFilter(advancedFilter));
		dispatch(RiskEvaluationAction.search(idModelo));
}

	return (
		<div>
			<div className="flex justify-end mb-2">
        <SelectButton
          unselectable={false}
          value={typeView}
          options={typeViews}
          itemTemplate={({ icon }) => <i className={icon}></i>}
          onChange={(e) => setTypeView(e.value)}
        />
      </div>
			{ typeView === CoreConstants.TypeView.LIST && 
				<>
					<DataTable 
						value={data.results}
						emptyMessage="No se encontró opciones"
						stripedRows
						rowHover
						size="small"
						responsiveLayout="scroll"
						sortField={sortField}
						sortOrder={sortOrder}
						onSort={onSort}
					>
						<Column field="name" header="Evaluación" sortable></Column>
						<Column field="formula" header="Formula" sortable></Column>
						<Column field="criterias" header="Criterio" sortable></Column>
						<Column field="typeName" header="Tipo" sortable></Column>
						<Column field="creationDate" header="Fecha de Creación" body={(row) => moment(row.creationDate).format(CoreConstants.Format.LOCALE)} sortable></Column>
						<Column
								header={""}
								body={TableBodyActions}
							></Column>
					</DataTable>
					<Paginator
						key={"pag-1"}
						className="paginator-custom mt-4"
						rows={10}
						first={(data?.currentPage - 1) * data?.pageSize}
						totalRecords={data?.rowCount}
						onPageChange={onPage}
					></Paginator>	
				</>
			}
			{ typeView === CoreConstants.TypeView.CANVA && 
				<div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2  gap-2">
				{data.results.map((item, index) => {
					return (
						<div
							key={"card" + item.id}
							className="
								block 
								p-3
								max-w-sm
								bg-white
								rounded-lg 
								border 
								border-gray-200 
								shadow-md 
								hover:bg-gray-100 
								dark:bg-gray-800 
								dark:border-gray-700 
								dark:hover:bg-gray-700"
							>
							<div>
								<div className="flex justify-between">
									<h5
										key={"title" + item.id}
										className="mb-1  font-bold text-sm  text-gray-900 dark:text-white"
									>
										{item.name}
									</h5>
									<i
										key={"options" + item.id}
										className="pi pi-ellipsis-v cursor-pointer"
										onClick={(event) => {
											setSelect(item)
											menu.current.toggle(event);
										}}
									></i>
								</div>
								<hr />
								<div className="flex flex-col justify-around h-16 text-xs text-gray-600 dark:text-gray-400">
									<div>
									<h5 key={"type" + item.id}>
										<span className="text-xs">Tipo:</span>{" "}
										{item.typeName}
									</h5>
									</div>
									<div>
									<h5>
										<span className="text-xs">
											Fecha de Creación: {moment(item.creationDate).format(CoreConstants.Format.LOCALE)}
										</span>{" "}
									</h5>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
			}
			
			<Menu model={items} popup ref={menu} />
			<ConfirmDialog
				visible={showDelete}
				onHide={() => setShowDelete(false)}
				message="¿Está seguro que desea eliminar el registro?"
				header="Eliminar"
				icon="pi pi-trash" 
				reject={() => setShowDelete(false)}
				accept={()=> dispatch(RiskEvaluationAction.deleteModel(select.id, Number(idModelo) ))}
			/>
		</div>
	);
};

export default TableComponent;