import { AppSettingsAction } from 'features/configuration/store/actions/AppSettingsAction';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useSelector } from 'react-redux';
import useSetTitlePage from 'shared/hooks/useSetTitlePage';

const useColorCorporate = () => {
  const { updateTitle } = useSetTitlePage();

  useEffect(() => {
    updateTitle({
      title: 'Configuración',
      subtitle: 'Sistema',
      description: 'Color corporativo',
    });
  }, []);

  const DEFAULT_COLOR = '#E6E4E4';
  const currentColors = useSelector(
    (state) => state.appSettingsReducer.currentColors.data
  );
  const { isLoading, isLoadingSave } = useSelector(
    (state) => state.appSettingsReducer.currentColors
  );
  const [colors, setColors] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const objColors = {};
    if (currentColors.length > 0) {
      currentColors.forEach(({ code, value }) => {
        objColors[code] = value;
      });
    }
    setColors(objColors);
  }, [currentColors]);

  const handlerChangeColor = (e) => {
    setColors({
      ...colors,
      [e.target.name]: e.target.value,
    });
  };

  const saveColors = () => {
    const currentColors_ = currentColors.map((c) => {
      c.value = colors[c.code] ? colors[c.code] : '';
      return c;
    });

    const id = currentColors_[0].applicationSettingId;
    const newColors = {
      id,
      applicationSettingDetails: currentColors_,
    };
    dispatch(
      AppSettingsAction.saveSettings({
        data: newColors,
        settingName: 'settings-colors',
      })
    );
  };
  return {
    handlerChangeColor,
    colors,
    DEFAULT_COLOR,
    saveColors,
    isLoading,
    isLoadingSave,
  };
};

export default useColorCorporate;
