import { useHistory, useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScheduleAction } from "features/coursesManagment/store/actions/ScheduleAction";
import scrollTop from "shared/utils/scrollTop";
import { v4 as uuidv4 } from "uuid";
// import ScheduleReducer from "features/coursesManagment/store/reducers/ScheduleReducer";

export const useSchedule = (title) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const currentId = params.id;
  const { data, isLoadingDataMaster } = useSelector(
    (state) => state.scheduleReducer.dataMaster
  );
  useEffect(() => {
    dispatch(ScheduleAction.getDataMaster());
  }, []);

  const [frecuencyList, setFrecuencyList] = useState([]);
  const [instructorList, setInstructorList] = useState([]);
  const [evaluationList, setEvaluationList] = useState([]);

  useEffect(() => {
    scrollTop();
  }, []);

  const createFrecuency = () => {
    setFrecuencyList((prev) => [
      ...prev,
      {
        daysId: null,
        hoursId: null,
        
        id: uuidv4(),
      },
    ]);
  };
  const updateFrecuency = ({ id, name, value }) => {
    const currentFrecuencyList = frecuencyList.map((frecuency) => {
      if (frecuency.id === id) frecuency[name] = value;
      return frecuency;
    });
    setFrecuencyList(currentFrecuencyList);
  };
  const frecuencyDelete = (id) => {
    const currentfrecuencyList = frecuencyList.filter(
      (frecuency) => frecuency.id !== id
    );
    setFrecuencyList(currentfrecuencyList);
  };

  const createInstructor = () => {
    setInstructorList((prev) => [
      ...prev,
      {
        instructorName: "",
        //projectId: isNewProject?0:Number(currentId),
      id: uuidv4(),
      },
    ]);
  };

  const updateInstructor = ({ id, name, value }) => {
    const currentInstructorList = instructorList.map((instructor) => {
      if (instructor.id === id) instructor[name] = value;
      return instructor;
    });

    setInstructorList(currentInstructorList);
  };

  const instructorDelete = (id) => {
    const currentInstructorList = instructorList.filter(
      (instructor) => instructor.id !== id
    );
    setInstructorList(currentInstructorList);
  };


  const createEvaluation = () => {
    setEvaluationList((prev) => [
      ...prev,
      {
        //description: "",
        //startDate: "",
        beginDate:"",
        endDate: " ",
        evaluationWeight:0,
        id: uuidv4(),
      },
    ]);
  };

  const updateEvaluation = ({ id, name, value }) => {
    const currentEvaluationList = evaluationList.map((evaluation) => {
      if (evaluation.id === id) evaluation[name] = value;
      return evaluation;
    });

    setEvaluationList(currentEvaluationList);
  };

  const evaluationDelete = (id) => {
    const currentEvaluationList = evaluationList.filter(
      (evaluation) => evaluation.id !== id
    );
    setEvaluationList(currentEvaluationList);
  };
  return {
    isLoadingDataMaster,
    data,
 setFrecuencyList,
 setEvaluationList,
 setInstructorList,
    frecuencyList,
    createFrecuency,
    updateFrecuency,
    frecuencyDelete,

    instructorList,
    createInstructor,
    updateInstructor,
    instructorDelete,

    evaluationList,
    createEvaluation,
    updateEvaluation,
    evaluationDelete,
  };
};
