import { EmployeesAction } from 'features/rrhh/store/actions/EmployeesAction'
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';


const useModalEmployee = () =>{
  const dispatch = useDispatch();
  const { showModalExperience , showModalStudy } = useSelector((state) => state.employeeReducer.employeeEdit);

  const openModal = (type) =>{
    dispatch(EmployeesAction.modalEmployee( type === "study" ? { showModalStudy: true } : { showModalExperience: true }));

  }

  const closeModal = (type) => {
    dispatch(EmployeesAction.modalEmployee( type === "study" ? { showModalStudy: false } : { showModalExperiencie: false }));

  }
  return {
    showModalExperience,
    showModalStudy,
    openModal,
    closeModal
  }
}

export default useModalEmployee