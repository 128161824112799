import { CoreConstants } from 'core/commons/CoreConstants';
import moment from 'moment';

const validateTypeValue = (array, mode) => {
  if (array.length === 0) return [];
  const filterType = (val) => {
    if(mode==='Num'){
      if (typeof val === 'boolean') return val ? 1 : 0;
      if (typeof val === 'number' && !val) return '0';
      if (typeof val !== 'boolean' && !val) return '-';
      else return val;
    }else{
      if (typeof val === 'boolean') return val ? 'Si' : 'No';
      if (typeof val === 'number' && !val) return '0';
      if (typeof val !== 'boolean' && !val) return '-';
      else return val;
    }
  };

  const newArray = array.map((object) => {
    const newValue = { ...object };
    if (newValue.realCloseDate) {
      newValue.realCloseDate = moment(newValue.realCloseDate).format(
        CoreConstants.Format.LOCALE
      );
    }
    if (newValue.realOpenDate) {
      newValue.realOpenDate = moment(newValue.realOpenDate).format(
        CoreConstants.Format.LOCALE
      );
    }
    if (newValue.estimatedCloseDate) {
      newValue.estimatedCloseDate = moment(newValue.estimatedCloseDate).format(
        CoreConstants.Format.LOCALE
      );
    }
    if (newValue.estimatedOpenDate) {
      newValue.estimatedOpenDate = moment(newValue.estimatedOpenDate).format(
        CoreConstants.Format.LOCALE
      );
    }
    if (newValue.creationDate) {
        newValue.creationDate = moment(newValue.creationDate).format(
          CoreConstants.Format.LOCALE
        );
      }
    if (newValue.registrationDate) {
      newValue.registrationDate = moment(newValue.registrationDate).format(
        CoreConstants.Format.LOCALE
      );
    }
    if (newValue.lastLogin) {
        newValue.lastLogin = moment(newValue.lastLogin).format(CoreConstants.Format.LOCALE);
    }
    for (let key in newValue) newValue[key] = filterType(newValue[key]);
    return newValue;
  });

  return newArray;
};;
export default validateTypeValue;
