import { InputTextarea } from "primereact/inputtextarea"
import { Controller } from 'react-hook-form'

export const InputTextareaCustom = (props) => {
	return (
		<Controller
				rules={!props.required ? {} : {
					required: `${props.text} es requerido`,
				}}
        control={props.control}
				name={props.id.toString()}
				render={({field: { onChange, value}})=>
				{
					if((!value || value === "") && props.forDownload ){
						return <div></div>
					}else if(props.forDownload){
						return(
							<div className='mb-4'>
              {
                !props.isTable && 
                <div style={{fontSize: "0.75rem", color: "black",fontWeight:"bold"}}>
                  {props.text}
                </div>
              }
							<div style={{fontSize: "0.75rem", color: "#495057"}}>
                <pre style={{fontFamily: 'inherit'}}>
								  {value}
                </pre>
							</div>
						</div>
						)
					}
					return (
					<div className={props.isTable ? '' : 'mb-6'}>
					<span className={`p-float-label w-full${props.isTable ? '' : 'mt-4'}`}>
						<InputTextarea disabled={props.show === "ShowWf"} rows={1} cols={10} className={`w-5 ${props.errors[props.id.toString()] ? 'p-invalid w-full' : 'w-full'}`} autoResize value={value} onChange={onChange} />
            {
              !props.isTable && <label htmlFor={props.id}>{props.text}</label>
            }
					</span>
					</div>
				)}
			
			}
			/>
	)
}