import { ConfirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import ModalImportQuestion from './ModalImportQuestion';
import { ModalMoveSections } from './ModalMoveSections';
import { InputTextarea } from 'primereact/inputtextarea';

export const SurveySection = ({section, index, deleteSection, addNewSectionAfterSelectedSection, addNewSectionGridAfterSelectedSection, listSections, setListSections, addNewQuestionInSection, onDragEnd, SurveySectionType, getSurveySectionTypeCodeById, changeSectionToGrid, changeSectionToRegular}) => {
  const menu = useRef(null);
  const [showConfirmDialog, setshowConfirmDialog] = useState(false);
  const [showConfirmChangeSectionToGrid, setShowConfirmChangeSectionToGrid] = useState(false)
  const [showConfirmChangeSectionToRegular, setShowConfirmChangeSectionToRegular] = useState(false)
  const { isCloseStatus } = useSelector((state) => state.surveyReducer.survey);
  const [displayModalImport, setDisplayModalImport] = useState(false)
  const [displayModalMove, setDisplayModalMove] = useState(false)

  let items = [
    {
        label: 'Eliminar Sección',
        icon: 'pi pi-times',
        command: (e) => {
          setshowConfirmDialog(true);
        }
    },
    {
        label: 'Agregar Sección',
        icon: 'pi pi-plus',
        command: (e) => {
          addNewSectionAfterSelectedSection(section.id)
        }
    },
    {
        label: 'Mover Sección',
        icon: 'pi pi-sort-alt',
        command: (e) => {
          setDisplayModalMove(true)
        }
    },
    {
        label: 'Cambiar a Cuadrícula',
        icon: 'pi pi-sync',
        command: (e) => {
          // changeSectionToGrid(section.id)
          setShowConfirmChangeSectionToGrid(true)
        }
    },
    {
        label: 'Agregar Pregunta',
        icon: 'pi pi-plus-circle',
        command: (e) => {
          addNewQuestionInSection(section.id)
        }
    },
    {
        label: 'Importar Preguntas',
        icon: 'pi pi-download',
        command: (e) => {
          // console.log('Importar Preguntas')
          setDisplayModalImport(true)
        }
    },
  ];

  const itemChangeToRegular = {
    label: 'Cambiar a Regular',
    icon: 'pi pi-sync',
    command: (e) => {
      // changeSectionToRegular(section.id)
      setShowConfirmChangeSectionToRegular(true)
    }
  }

  const [, ...itemsWithoutDelete] = items;

  // Crear un nuevo array de items sin los 3 ultimos elementos y agregar cambiar a regular
  const itemsGrid = [...items.slice(0, items.length - 3), itemChangeToRegular]
  
  // Remover el elemento de eliminar sección
  const [, ...itemsGridWithoutDelete] = itemsGrid;

  // onChange para title y description de la sección
  const onChangeNameStage = ({target}, id) => {
    const newListSections = [...listSections]

    const index = newListSections.findIndex(section => section.id === id)
    newListSections[index][target.name] = target.value
    setListSections(newListSections)
  }

  return (
    <div className='flex flex-col mb-2'>
      {/* Header */}
      <div 
        // {...provided.dragHandleProps} 
        // className='flex justify-between p-2 border-2 border-b-0 border-gray-300 bg-blue-100 rounded-t-xl'
        className='flex justify-between p-2 border-2 border-b-0 border-gray-300 sig-primary text-white rounded-t-lg'
      >
        <h2 className='font-semibold'>{`Sección ${index} de ${listSections.length}`}</h2>
        <button 
          type='button' 
          disabled={isCloseStatus}
        >
          <i
              className={`pi pi-ellipsis-v block mr-1 ${isCloseStatus ? '' : 'cursor-pointer hover:opacity-90'}`}
              onClick={(event) => {
                  menu.current.toggle(event)
              }}
          ></i>
        </button>
      </div>
      {/* Body de la seccion */}
      <div className='px-5 py-3 border-2 border-gray-300'>
        <div className="flex flex-col">
            {/* Titulo de la sección */}
            <div className="flex flex-col mb-2 gap-y-4">
                {/* <label className="mb-1 font-semibold">Título de la sección</label> */}
                <div className='w-4/5 lg:w-4/5 xl:w-3/5'>
                  <InputText 
                    // value={(dinamicStage[options.props.positionP].text)} 
                    // onChange={(e) => {onChangeNameStage(e,options.props.valueID)}} 
                    onChange={(e) => onChangeNameStage(e, section.id)}
                    name='title'
                    value={section.title}
                    className={`bg-transparent border-0 border-b font-bold  `} 
                    placeholder="Título de la sección *"
                  />

                </div>
                <div className='w-full'>
                <InputTextarea  
                    rows={1} 
                    // cols={30}
                    value={section.description}
                    onChange={(e) => onChangeNameStage(e, section.id)}
                    name='description'
                    className={`bg-transparent border-0 border-b`} 
                    placeholder="Descripción de la sección"
                  />
                </div>
            </div>
        </div>
      </div>
      <Menu 
        model={
          listSections.length > 1 
          ? (getSurveySectionTypeCodeById(section.sectionTypeId) === SurveySectionType.Grid ? itemsGrid : items) 
          : (getSurveySectionTypeCodeById(section.sectionTypeId) === SurveySectionType.Grid ? itemsGridWithoutDelete : itemsWithoutDelete )
        }
        popup ref={menu} id="popup_menu" 
      />
      <ConfirmDialog
          visible={showConfirmDialog}
          onHide={() => setshowConfirmDialog(false)}
          message="¿Está seguro que desea eliminar la pregunta?"
          header="Eliminar"
          icon="pi pi-trash"
          accept={() => deleteSection(section.id)}
          reject={() => setshowConfirmDialog(false)}
      />
      <ConfirmDialog
          visible={showConfirmChangeSectionToGrid}
          onHide={() => setShowConfirmChangeSectionToGrid(false)}
          message="¿Está seguro que desea cambiar el tipo de sección?."
          header="Cambiar a Cuadrícula"
          icon="pi pi-exclamation-triangle"
          accept={() => changeSectionToGrid(section.id)}
          reject={() => setShowConfirmChangeSectionToGrid(false)}
      />
      <ConfirmDialog
          visible={showConfirmChangeSectionToRegular}
          onHide={() => setShowConfirmChangeSectionToRegular(false)}
          message="¿Está seguro que desea cambiar el tipo de sección?."
          header="Cambiar a Regular"
          icon="pi pi-exclamation-triangle"
          accept={() => changeSectionToRegular(section.id)}
          reject={() => setShowConfirmChangeSectionToRegular(false)}
      />
      <ModalMoveSections
        displayModal={displayModalMove}  setDisplayModal={setDisplayModalMove} 
        listSections={listSections}  setListSections={setListSections}
        onDragEnd={onDragEnd}
      />
      <ModalImportQuestion
        displayModal={displayModalImport}  setDisplayModal={setDisplayModalImport} 
        listSections={listSections}  setListSections={setListSections}
        sectionSelected={section}
      />
    </div>
  )
}
