import { getBase64 } from "./getBase64";

export const getAddedFilesBase64 = async(addedFiles) => {
  if(addedFiles?.length > 0){
    const addedFilesPromise = await Promise.all(addedFiles.map(async(file) => {
      delete file.id;
      const fileBase64 = await getBase64(file);
      let dataBase64 = fileBase64.split("base64,")[1]
      const newFile = {
        fileName: file.name,
        fileType: file.type,
        fileBase64: dataBase64
      }
      return newFile;
    }));
    return addedFilesPromise;
  } else {
    return []
  }
}