import { apiService } from "core/services/ApiService";

const searchAttendances = (criteria) => {
  return apiService.Post("customer/api/CourseStudent/Search", criteria);
};

const saveAttendance = (data) => {
  return apiService.Post("customer/api/CourseStudent/Save", data);
};

const getAttendanceById = (courseId) => {
  return apiService.Get("customer/api/CourseStudent/GetCourseStudentById?id=" + courseId);
};
//en este caso el getattendancebyid tendria que llevar como parametro courseId
const generateReport = (payload) => {
  return apiService.Post("customer/api/CourseStudent/GenerateReport", payload, {
    responseType: 'blob',
  })
};

 const getDataMaster = () => {
  return apiService.Get("customer/api/CourseStudent/GetDataMaster");

 };

const deleteAttendance = (attendanceId) => {
  return apiService.Delete("customer/api/CourseStudent/Delete?id=" + attendanceId);
};
//el parametro debe de ser ese o courseid?


export const AttendanceServices = {
  searchAttendances,
  saveAttendance,
  getAttendanceById,
  getDataMaster,
  generateReport,
  deleteAttendance,
}