import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { InputText } from 'primereact/inputtext';
import { Chips } from 'primereact/chips';
import { useDispatch, useSelector } from 'react-redux';
import { RiskModelAction } from 'features/riskManagement/store/actions/RiskModelAction';
import { Tooltip } from 'primereact/tooltip';

const FormulaComponent = ({valuesFields}) => {

	const dispatch = useDispatch()
	const { data } = useSelector(state => state.riskModelReducer.formula)
	const { value } = useSelector(state => state.riskModelReducer.formulaInput)
	const { data: dataEvaluacion  } = useSelector(state => state.riskEvaluationReducer.dataEvaluations)
	const { mathOperators: dataOperator  } = useSelector(state => state.riskModelReducer.dataMasterEvaluation.data)
	const { data: dataCriterias } = useSelector(state => state.riskModelReducer.criteria)
	const { data: dataEdit } = useSelector(state => state.riskEvaluationReducer.editEvaluation)

	const onDragEnd = ({destination, draggableId, source}) => {
		if(source?.droppableId === 'formula-container' && destination?.droppableId !== 'formula-container') {
			const id = draggableId.split('_')[1]
			dispatch(RiskModelAction.deleteFormula(Number(id)))
			return;
		}
		if(destination?.droppableId === 'formula-container'){
			if(source?.droppableId === 'formula-container'){
				return;
				// if(source.index === destination.index) return;
				// dispatch(RiskModelAction.changeOrderFormula({start: source.index, end: destination.index}))
			}else{
				const id = draggableId.split('_')[1]
				dispatch(RiskModelAction.updateFormula({idSetting: id, order: destination.index, isEvaluation: valuesFields.join}))
			}
		}
		
	}

	return (
		<div>
		<div className='mb-4'>
			<Chips value={value} separator="," onChange={(e) => dispatch(RiskModelAction.deleteFormulaInput( e.value, valuesFields.join))} className="w-full"></Chips>
		</div>
		<DragDropContext onDragEnd={onDragEnd}>
		<div className=''>
			<Droppable droppableId='formula-container' direction="horizontal">
				{(droppableProvided, snapshot) => (
					<div 
						ref={droppableProvided.innerRef}
						{...droppableProvided.droppableProps}
						className='border-stone-700 border-2 w-full h-24 flex gap-y-2 md:gap-2 items-center lg:bg-white p-4'  
					>
						{
							data.map((item, key) => (
								<Draggable 
									key={`drag1-${item.id}`}
									draggableId={`formula_${item.id}`}
									index={key}
								>
									{
										(draggableProvided, snapshot) => (
											<div 
												ref={draggableProvided.innerRef}
												{...draggableProvided.draggableProps} 
												{...draggableProvided.dragHandleProps}
												className='customClassName border-2 border-stone-700 flex justify-center md:justify-start items-center lg:rounded-lg hover:bg-gray-200 lg:bg-white p-4' data-pr-tooltip={item.value}
											>
												<p className='text-center text-xs lg:text-xs lg:text-left truncate'>{item.value}</p>
											</div>
										)
									}
								</Draggable>
							))
						}
						{droppableProvided.placeholder}
					</div>
				)}
			</Droppable>
		</div>
		<div className='flex gap-4 justify-center'>
			{
				valuesFields.join ?
				<div className='flex flex-col gap-2'>
				<p><strong><u>Evaluación</u></strong></p>
				<Droppable droppableId='evaluacion-container' direction="vertical">
				{(droppableProvided, snapshot) => (
					<div className='flex flex-col gap-2' ref={droppableProvided.innerRef}
					{...droppableProvided.droppableProps}>
					{ 
						dataEvaluacion?.map((item, key) => (
							<Draggable 
								key={`drag2-${item.id}`}
								draggableId={`evaluacion_${item.id}`}
								index={key}
							>
								{
									(draggableProvided, snapshot) => (
										<div 
											ref={draggableProvided.innerRef}
											{...draggableProvided.draggableProps} 
											{...draggableProvided.dragHandleProps}
											className='customClassName border-2 border-stone-700 flex justify-center md:justify-start items-center lg:rounded-lg hover:bg-gray-200 lg:bg-white p-2 w-40' data-pr-tooltip={item.name}
										>
											<Tooltip target=".customClassName" position="left"/>
											<p className='text-center text-xs lg:text-xs lg:text-left truncate'>{item.name}</p>
										</div>
									)
								}
							</Draggable>
						))
					}
					{droppableProvided.placeholder}
					</div>
				)}
				</Droppable>				
				</div>
				:
				<div className='flex flex-col gap-2'>
				<p><strong><u>Criterios</u></strong></p>
				<Droppable droppableId='evaluacion-criteria' direction="vertical">
				{(droppableProvided, snapshot) => (
					<div className='flex flex-col gap-2' ref={droppableProvided.innerRef}
					{...droppableProvided.droppableProps}>
					{ 
						dataCriterias?.map((item, key) => (
							<Draggable 
								key={`drag3-${item.id}`}
								draggableId={`criteria_${item.id}`}
								index={key}
								isDragDisabled={item.name === ""}
							>
								{
									(draggableProvided, snapshot) => (
										<div 
											ref={draggableProvided.innerRef}
											{...draggableProvided.draggableProps} 
											{...draggableProvided.dragHandleProps}
											className='customClassName border-2 border-stone-700 flex justify-center md:justify-start items-center lg:rounded-lg hover:bg-gray-200 lg:bg-white p-2 w-40' data-pr-tooltip={item.name}
										>
											<Tooltip target=".customClassName" position="left"/>
											<p className='text-center text-xs lg:text-xs lg:text-left truncate'>{item.name}</p>
										</div>
									)
								}
							</Draggable>
						))
					}
					{droppableProvided.placeholder}
					</div>
				)}
				</Droppable>				
				</div>
			}
			
			<div className='flex flex-col gap-2'>
				<p><strong><u>Operador</u></strong></p>
				<Droppable droppableId='operator-container' direction="vertical">
				{(droppableProvided, snapshot) => (
					<div className='grid grid-cols-2 gap-2' ref={droppableProvided.innerRef}
					{...droppableProvided.droppableProps}>
					{
						dataOperator.map((item, key) => (
							<Draggable 
								key={`drag3-${item.id}`}
								draggableId={`operator_${item.id}`}
								index={key}
							>
								{
									(draggableProvided, snapshot) => (
										<div 
											ref={draggableProvided.innerRef}
											{...draggableProvided.draggableProps} 
											{...draggableProvided.dragHandleProps}
											className='customClassName border-2 border-stone-700 flex justify-center md:justify-start items-center lg:rounded-lg hover:bg-gray-200 lg:bg-white p-2' data-pr-tooltip={item.value}
										>
											<p className='text-center text-xs lg:text-xs lg:text-left truncate m-auto'>{item.description}</p>
										</div>
									)
								}
							</Draggable>
						))
					}
					{droppableProvided.placeholder}
					</div>
				)}
				</Droppable>				
			</div>
		</div>
		</DragDropContext>
		</div>
	);
};

export default FormulaComponent;