export class TasksConstants {
  static Action = class {
    static Tasks = class {
      static get SEARCH() {
        return '@TASKS.SEARCH';
      }
      static get CURRENT_ACTION() {
        return '@TASKS.CURRENT-ACTION';
      }
      static get ADVANCED_FILTER() {
        return '@TASKS.ADVANCED-FILTER';
      }
      static get EDIT_TASK() {
        return '@TASKS.EDIT-TASK';
      }
      static get DATA_MASTER() {
        return '@TASKS.DATA-MASTER';
      }
      static get OPEN_MODAL() {
        return '@TASKS.OPEN_MODAL';
      }
      static get CLOSE_MODAL() {
        return '@TASKS.CLOSE_MODAL';
      }
      static get CLEAR_EDIT_TASK() {
        return '@TASKS.CLEAR_EDIT_TASK';
      }
      static get GET_COMMENTS_BY_INDICATORID() {
        return '@TASKS.GET_COMMENTS_BY_INDICATORID';
      }
      static get DELETE_COMMENT() {
        return '@TASKS.DELETE_COMMENT';
      }
      static get ADD_COMMENT() {
        return '@TASKS.ADD_COMMENT';
      }
      static get REPORT_STATUS() {
        return '@TASKS.REPORT_STATUS';
      }
      
    }
    static Comments = class {
      static get SEARCH() {
        return '@COMMENTS.SEARCH';
      }
    }
  }
  static SectionsName = class {
    static get INFORMACION_DE_LA_TAREA() {
      return 'INFORMACIÓN DE LA TAREA';
    }

    static get DATOS_GENERALES() {
      return 'DATOS GENERALES';
    }
  }
};
