import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { useForm } from "react-hook-form";
import EmployeesAttachment from '../EmployeesAttachment';
import EmployeesTableUploadNotes from '../EmployeesTableUploadNotes';
import { EmployeesAction } from 'features/rrhh/store/actions/EmployeesAction';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { ProgressBar } from 'primereact/progressbar';
import { toastAction } from 'core/store/actions/ToastAction';
import '../EmployeesTableUploadNotes.scss'
import { ProgressSpinner } from 'primereact/progressspinner';
import Attachments from 'shared/components/attachments/Attachments';

const EmployeeModalImport = ({displayModal, setDisplayModal, title}) => {

  const dispatch = useDispatch()
  const defaultValues = { 
    file: null,
    savedFiles: [],
    addedFiles: [],
  }
  const { setValue, handleSubmit, watch } = useForm({ defaultValues });
  const valuesFields = watch();

  const [isUploaded, setIsUploaded] = useState(false)
  const buttonRef = useRef(null)
  const [visible, setVisible] = useState(false);

  const [error, setError] = useState({})
  const [listErrors, setListErrors] = useState([])

  const [listEmployees, setListEmployees] = useState([])
  const [value1, setValue1] = useState(0);
  const interval = useRef(null);
  const { data, isLoading } = useSelector(state => state.employeeReducer.bulkLoad)
  const bulkLoadErrors = useSelector(state => state.employeeReducer.bulkLoadErrors)
  const { isSaved } = useSelector(state => state.employeeReducer.employeeBulk)

  // const iconTypeOfFileTable = (file) => {
  //   if (!file) return
  //     const fileSplit = file.name.split('.')
  //     if (fileSplit.includes('docx')) {
  //         return <div className="icon-word-table w-12 h-12"></div>
  //     } else if(fileSplit.includes('xlsx') || fileSplit.includes('xls') ) {
  //         return <div className="icon-excel-table w-12 h-12"></div>
  //     } else if(file.type === 'application/pdf') {
  //         return <img alt='icon-pdf' className='icon-image w-12 h-12' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAACQ0lEQVRoge2Yz0tUQRzAP293rTRb09rMwl1UkFqQ9tAhKPDQrT8gTx5FCtFLvy4SnbJDoKQSdIjoEBoFEZ66RF7qaAgF4rLs0masWSIsbaTTYZR3eNX63sw2uzAfWN7Mm+/MfD/Mm/fePrBYlHDKBYi+TlGRmQV3nNn0DdVhQjpy8YtoaQOH6+Ji55jqWEYENkemEIeOaZEwIkBzK5vDk1okzAiANglzAqBFwqwAKEuYFwAlieoQgMAS1SMAgSSqSwA8EuXCI/8jJ8+kw2e1jVV9K+ATK2AaK2AaK2CamhcI9iA73g0j9936zxJk3sPzcVj7DB09cGnCbf+ah9dP4N2crPf0Qv9N77jjA5Bf9pVKsBUIh6EhCqEIZD9C8TukzsPQFET2QGi73QnBpyWItUP/Lejt2+4fke1bv2T/nV/ph+9U1F4lVnMweRkcB648gkQSuk9DqSjbV9IwMQixOIw+hQsD8GbW7b+8AA+uKqWgZw8IAV8yshw97G0vZKGQg/0Hofmolil3UFuBun0QPwkNByB5Rp7LL0HdXm9scUMem2Luua5TMDQty4UczNz2nYKaQGsCrj126/PPIPsBulLe2D99HquPQvyELIfDgVJQE9hYg7cv5TGzCOmFv8fWN8rj+qq7CovzyntATWC9AC/ulY9raYMj7fIy+rYiLztNVPYPTSwBg3ehIyVvna8egtjSOkVlBRqbIHlO3m7npuUe0Yy5r9O7xJlJ/zPHmn8XsgKmsQKmsQKmsQKmsQIWS43zG41eqcJmZbBOAAAAAElFTkSuQmCC"/>
  //     } else if(file.type === 'image/jpeg'|| file.type === 'image/jpg' || file.type === 'image/png' ) {
  //         return <img alt='icon-img' className='icon-image' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAADI0lEQVRoge2ZyW7TUBRAT9IhcdKgoqqTmjKpn4DER7CDBX/AT8CSNWv4A9ix4xtYtBJCqBIFVEpoaZqENB4S2/G7LNKiUPxc22ncIuVI2dzr4R7fN0mBCRMmTLhMcrrEgwO5L8JLgbUsCwqhRo7Hb1Zzb8OSed1dSnhxBYoHqCK81CW1AkB1DMWkZV2XiBL4L/jvBaazfuHdGYdHRpvqlEctmOVVd55Nv5T6eZl24O6Mw5PKIRvTLsWcsDHt8rRyyL1ZJ/UzMxV4ZLRD4w+L4fE4ZCqwNuWHxquaeBwyFfgRzITGa5p4HDIVeNWdD42/1sTjkKnApl/imbnMTr9AT/Ls9As8M5dHWoVGXkaPj3p0Gi7kYX6pSOV6IfL6Tb80UsFnSS0gAgdfO7TrvT8xs+WyWC2zuF6+kOLikEpARKh96tBp9P7J1fcsVCAs35obubg4JBYQEb5vH9Npudprjmo2AqxkIJFIQAJh9+MvrLZ37rVHexaIsHK78lfc+uVitTwEuLZQoDw/m6jgs8QWCPqK3Q8t7OP4m079m4UIrN6pgEBz36bTdBE16KTd9ri+YrCwNuZVqO8pvrxv0bWS75iHJxKzhTx2SOea+w4qEBZvpJv45wr4vYDPW0163X6qFwAc7poYczMY5fDXtQ4cRGDpZnKJSAGvG7Cz1cB10hd/itPxECUUdRL7DiAs3Uw28bUCPavPp80GvhskemAUjumjFNpONH8MOpFkCdYKbL+r0/dV8irPwTE9EH0nmjUbEVi5HU9CK3CRX/4sdsdDBYIxF34KbXy3B0vwnUpofhitgEj6AuPgWD4CFEvhJRzt2YiC1Y1oCb2AGrMBQxM7QgKiJSI6MH4BGMwJpRRGKXxHrn+zyEUc+rUC6uLnrxbH7CMKjHL4nPj5xdTee6lDaBjH9E8kkp0vL30IDeNYHqIUxXL8A15EBy6kpsQ41mA4FTXD6SwRcyD7DpxiW6c79vkSV2oIDePYHoJglKIlrtwQGmZwdhJKERJRq1CNK/AfQdf0QXGsy2u3iECpxyJSExEu85fL5wIVyPPxfJ4JEyZMGJXfXUfSuVI0IAgAAAAASUVORK5CYII="/>
  //     } else {
  //         return <div className="icon-file-table"></div>
  //     }
  // }

  const existError = Object.keys(error).length > 0

  const onHide = () => {
    setDisplayModal(false)
    setIsUploaded(false)
    setValue('file', null)
    setListEmployees([])
    setValue1(0)
    setError({})
    setListErrors([])
    dispatch(EmployeesAction.resetAfterSaved());
  }

  const redirectToSearch = () => {
    onHide()
  }

  const reject = () => {
    setVisible(false);
  };
  
  useEffect(() => {
    if (data) {
      setIsUploaded(true)
    }
  }, [data])

  useEffect(() => {
    if (bulkLoadErrors.length > 0) {
      setListErrors(bulkLoadErrors)
    }
  }, [bulkLoadErrors])

  useEffect(() => {
    if (valuesFields.addedFiles.length > 0) {
      buttonRef.current.click()
      setValue('addedFiles', [])
    }
  }, [valuesFields.addedFiles.length, setValue])

  useEffect(() => {
    if (!isSaved) {
      setValue1(0)
    }
    let val = 0;
    if (isSaved) {
      interval.current = setInterval(() => {
        val += Math.floor(Math.random() * 10) + 8;
        if (val >= 100) {
            val = 100;
            clearInterval(interval.current);
            onHide()
            dispatch(toastAction.success("Empleado" ,'Registro exitoso'));
            dispatch(EmployeesAction.getEmployees({}))
        }
        setValue1(val);
      }, 300);

      return () => {
          if (interval.current) {
              clearInterval(interval.current);
              interval.current = null;
          }
      }
    }
  }, [isSaved])
  

  const onSubmit = (newFile) => {
    if (!isUploaded) {
      // if (Object.values(newFile).length === 0 || newFile.file === null) {
      //   setError({file: {type: 'required', message: 'Se requiere subir un archivo'}})
      //   return
      // }
      if (newFile.addedFiles.length === 0) {
        setError({file: {type: 'required', message: 'Se requiere subir un archivo'}})
        return
      }
      setError({})
      let bodyFormData = new FormData();
      // bodyFormData.append('File', newFile.file)
      bodyFormData.append('File', newFile.addedFiles[0])
      dispatch(EmployeesAction.uploadBatchInfo(bodyFormData));
      return
    }
    if (listEmployees.length === 0) return

    listEmployees.forEach((employee, index) => {
      if (index === listEmployees.length - 1) {
        dispatch(EmployeesAction.saveEmployeeBulk(employee, `${index + 1}/${listEmployees.length}`))
      } else {
        dispatch(EmployeesAction.saveEmployeeBulk(employee))
      }
    })
  }

  return (
    <Dialog header={title} visible={displayModal} breakpoints={{'960px': '78vw'}} style={{width: '55vw'}} onHide={onHide}>
        <form 
          className="form-custom p-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          <hr className='mb-2'/>
          {
            !isUploaded
            ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div className='col-span-2'>
                  {/* <EmployeesAttachment setValue={setValue} iconTypeOfFile={iconTypeOfFileTable} buttonRef={buttonRef} /> */}
                  {
                    isLoading 
                    ? (
                      <div className='flex justify-center items-center mt-2'>
                        <ProgressSpinner style={{width: '50px', height: '50px'}} />
                      </div>
                    )
                    : (
                      <Attachments
                        setValue={setValue}
                        fieldFiles="savedFiles"
                        fieldNewFiles="addedFiles"
                        filesValues={valuesFields?.["savedFiles"]}
                        newFilesValues={valuesFields?.["addedFiles"]}
                        isLoading={false}
                        validExtensions={["xlsx"]}
                        isMultiple={false}
                        isShowHeader={false}
                      />
                    )
                  }
                </div>
              </div>
            )
            :
            (
              <div className='flex flex-col empleoyees-table-error'>
                <EmployeesTableUploadNotes listEmployees={listEmployees} setListEmployees={setListEmployees} />
                <div className='flex justify-center'>
                  {
                    (value1 === 0 || value1 === 100) ? null : (<ProgressBar className='w-4/5 mt-2' value={value1}></ProgressBar>)
                  }
                </div>
              </div>
            )
          }
          {
            (existError || listErrors.length > 0) && <div className='message error overflow-y-auto max-h-36'>
                <ul>
                    {error.file ? (
                        <>
                            <li className="p-error">{error.file.message}</li>
                        </>
                    ) : null}
                </ul>
                {
                  listErrors.map((error, index) => (
                    <div key={index}>
                      <ul>
                          {error ? (
                              <>
                                  <li className="p-error">{`Fila ${error.line}: ${error.error}`}</li>
                              </>
                          ) : null}
                      </ul>
                  </div>
                  ))
                }
                
            </div>
          }
          <div className="flex justify-end gap-4 mt-3">
              <Button
                  icon="pi pi-times"
                  type="button"
                  label="Cancelar"
                  onClick={() => { setVisible(true); }}
                  className="sig-button sig-secondary mt-4"
              />
              <Button
                  icon="pi pi-save"
                  type="submit"
                  label="Guardar"
                  ref={buttonRef}
                  loading={document?.isSaving || document?.isLoading}
                  // disabled={listErrors.length > 0}
                  className="sig-button sig-primary mt-4"
              />
          </div>
        </form> 
        <ConfirmDialog
            visible={visible}
            onHide={() => setVisible(false)}
            message="¿Está seguro que desea cancelar?"
            header="Cancelar"
            icon="pi pi-exclamation-triangle"
            accept={redirectToSearch}
            reject={reject}
        />
    </Dialog>
  )
}

export default EmployeeModalImport