import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

export const useClaimViewProject = () => {

  const menu = useSelector((state) => state.authReducer.user.menuPrincipal);
  const pathName = useLocation()?.pathname;
  const [isSearch, setSearch] = useState(false);
  const [isDownload, setDownload] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isView, setView] = useState(false);
  const [isTask, setTask] = useState(false);
  const [isProject, setProject] = useState(false);

  useEffect(() => {
    search();
    download();
    remove();
    edit();
    view();
    task();
    project();
  }, [menu]);

  const search = () => {
    const actionCode = 'CUSTOMER_PROJECT_SEARCH';
    const optionCode = "PRJVW";
    setSearch(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const download = () => {
    const actionCode = 'CUSTOMER_PROJECT_DOWNLOAD';
    const optionCode = "PRJVW";
    setDownload(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const remove = () => {
    const actionCode = 'CUSTOMER_PROJECT_DELETE';
    const optionCode = "PRJVW";
    setDelete(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const edit = () => {
    const actionCode = 'CUSTOMER_PROJECT_EDIT';
    const optionCode = "PRJVW";
    setEdit(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const view = () => {
    const actionCode = 'CUSTOMER_PROJECT_VIEW';
    const optionCode = "PRJVW";
    setView(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
 };

 //Custom
  const task = () => {
    const actionCode = 'CUSTOMER_PROJECT_TASKS';
    const optionCode = "PRJVW";
    setTask(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
    setTask(true)
 };

  const project = () => {
    const actionCode = 'CUSTOMER_PROJECT_PROJECTS';
    const optionCode = "PRJVW";
    setProject(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
 };

  return {
    isSearch,
    isDownload,
    isDelete,
    isEdit,
    isView,
    isTask,
    isProject
  }
}