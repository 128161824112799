/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import React from "react";
import { Calendar } from "primereact/calendar";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Menu } from "primereact/menu";
import { Skeleton } from "primereact/skeleton";
import { useHistory } from "react-router";
import { Dropdown } from "primereact/dropdown";
import useSetTitlePage from "shared/hooks/useSetTitlePage";
import { InputSwitch } from "primereact/inputswitch";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import useEmployees from "features/rrhh/hooks/employees/useEmployees";
import { useState, useRef } from "react";
import Message from "shared/components/messages/Message";
import "../../../configuration/pages//users/UserPage.scss";
import "./NewPeoplePage.scss";
import useModalEmployee from "features/rrhh/hooks/employees/useModalEmployee";
import EmployeeModalExperience from "features/rrhh/components/employee/modals/EmployeeModalExperience";
import { Controller } from "react-hook-form";
import EmployeeModalStudy from "features/rrhh/components/employee/modals/EmployeeModalStudy";
import Attachments from "shared/components/attachments/Attachments";
import { ViewCustomField } from "../ViewCustomField";
import useViewFields from "../useViewFields";
import { useDispatch, useSelector } from "react-redux";
import { CustomFieldActions } from "features/rrhh/store/actions/CustomFieldActions";
import { RrhhConstants } from "features/rrhh/commons/RrhhConstants";
import { useParams } from "react-router-dom";
import { EmployeesAction } from "features/rrhh/store/actions/EmployeesAction";
import { getBase64 } from "shared/utils/getBase64";
import { getDynamicFields, getDynamicFieldsList } from "shared/utils/getDynamicFields";
import { DynamicFieldList } from "shared/components/dynamicFields/DynamicFieldList";
const NewPeoplePage = ({ title = "Nuevo Empleado", mode }) => {
  let today = new Date();
  const history = useHistory();
  const [visibleDeleteStudy, setVisibleDeleteStudy] = useState(false);
  const [visibleDeleteExperiencie, setVisibleDeleteExperiencie] =
    useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [objectEdit, setObjectEdit] = useState(null);
  const errorsArray = [];

  const listControls = useSelector(state => state.customFieldReducer.listControls.data)
  const lastFile = useSelector(state => state.customFieldReducer.getLastFilePeople.data)


  const itemsStudy = [
    {
      label: "Editar",
      icon: "pi pi-pencil",
      command: () => {
        setIsEditModal(true);
        openModal("study");
      },
    },
    {
      label: "Eliminar",
      icon: "pi pi-times",
      command: () => {
        setVisibleDeleteStudy(true);
      },
    },
  ];
  const [idDelete, setidDelete] = useState(null);
  const itemsExperiencie = [
    {
      label: "Editar",
      icon: "pi pi-pencil",
      command: () => {
        setIsEditModal(true);
        openModal("experiencie");
      },
    },
    {
      label: "Eliminar",
      icon: "pi pi-times",
      command: () => {
        setVisibleDeleteExperiencie(true);
      },
    },
  ];
  const menu = useRef(null);
  const menu2 = useRef(null);
  const {
    // formState: { errors },
    handleSubmit,
    control,
    setError,
    valuesFields,
    errors,
    setValue,
    getValues,
  } = useViewFields();

  const months_ = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];
  const { openModal, closeModal, showModalExperience, showModalStudy } =
    useModalEmployee();

    const dispatch = useDispatch()

  const isNewEmployee = title === "Nuevo Empleado";

  const {
    dataDepartmentGerente,
    setExperiencies,
    experiencies,
    loadingEdit,
    studies,
    setStudies,
    onSelectedImage,
    inputFile,
    srcAvatar,
    isActive,
    setIsActive,
    setSrcAvatar,
    handleClick,
    visible,
    payload,
    setPayload,
    setVisible,
    accept,
    cargoSelect,
    dataManager,
    reject,
    loadingDataManager,
    createOrEditEmployee,
    setFile,
    isSave,
    clearSave,
    dataEmployee,
  } = useEmployees({ title });
  // console.log(dataEmployee)
  const dynamicFields = dataManager?.dynamicFields || []

  const dynamicFieldsDatosGenerales = getDynamicFields(dynamicFields, RrhhConstants.SectionsEmployeeName.DATOS_GENERALES)
  const dynamicFieldsInformacionPersonal = getDynamicFields(dynamicFields, RrhhConstants.SectionsEmployeeName.INFORMACION_PERSONAL)
  const dynamicFieldsInformacionLaboral = getDynamicFields(dynamicFields, RrhhConstants.SectionsEmployeeName.INFORMACION_LABORAL)

  const [countrySelected, setCountrySelected] = useState("");
  const status = dataManager?.status;

  useEffect(()=> {
    if (studies.length > 0) {
      let currentId = 0;
      studies.forEach((study) => {
        let id = study.professionalDegreeId;
        if (currentId < id) currentId = id;
      });
      setValue("academicDegreeId", currentId);
    }
  },[studies])

  const params = useParams();
  useEffect(() =>{
    dispatch(EmployeesAction.getDataMaster({title},params.id))
  },[])

  useEffect(() =>{
    if(title === "Nuevo Empleado"){
     dispatch(EmployeesAction.setEmployeeEdit({cargoSelect:{}}))
    }
  },[])


  useEffect(() => {
    if (title === "Editar Empleado" || title==='Ver Empleado') {
      if (
        dataEmployee !== undefined &&
        Object.entries(dataEmployee).length > 0 && 
        !loadingDataManager
      ) {
        if(Object.entries(dataEmployee?.employee).length > 0){

          Object.entries(dataEmployee?.employee).forEach(([key, value]) => {
            if (value !== null) {
              if (
                key === "dateOfBirth" ||
                key === "contractStartDate" ||
                key === "contractEndtDate" ||
                key === "workPermitExpirationDate"
              ) {
                let date = new Date(value);
                dataEmployee.employee[key] = date;
              }
            }
          });
          setPayload(dataEmployee?.employee);
          setExperiencies(dataEmployee?.employee?.workExperienceList);
          setStudies(dataEmployee?.employee?.academicProfileList);
          setSrcAvatar(dataEmployee?.employee?.picture);
          //SetValue IDATOS GENERALES
          setValue("firstName", dataEmployee?.employee?.firstName);
          setValue("fatherLastName", dataEmployee?.employee?.fatherLastName);
          setValue("motherLastName", dataEmployee?.employee?.motherLastName);
          setValue("positionId", dataEmployee?.employee?.positionId);
          setValue("companyEmail", dataEmployee?.employee?.companyEmail);
          setValue("employeeTypeId", dataEmployee?.employee?.employeeTypeId);
  
          //SetValue INFORMACIÓN PERSONAL
          setValue("countryOfBirthId", dataEmployee?.employee?.countryOfBirthId);
          setValue("personalPhoneNo", dataEmployee?.employee?.personalPhoneNo);
          setValue("dateOfBirth", dataEmployee?.employee?.dateOfBirth);
          setValue("mobilePhoneNo", dataEmployee?.employee?.mobilePhoneNo);
          setValue("genderId", dataEmployee?.employee?.genderId);
          setValue("documentTypeId", dataEmployee?.employee?.documentTypeId);
          setValue("documentNo", dataEmployee?.employee?.documentNo);
          setValue("maritalStatusId", dataEmployee?.employee?.maritalStatusId);
          setValue("personalEmail", dataEmployee?.employee?.personalEmail);
  
          //SetValue Estudios REALIZADOS
          setValue("academicDegreeId", dataEmployee?.employee?.academicDegreeId);
  
          //SetValue Iformación Laboral
          setValue("branchId", dataEmployee?.employee?.branchId);
          setValue("contractStartDate", dataEmployee?.employee?.contractStartDate);
          setValue("weeklyLaborHours", dataEmployee?.employee?.weeklyLaborHours);
          setValue("savedFiles", dataEmployee?.attachment);
          setValue("addedFiles", []);

          //setValue Campos Dinámicos
          dataEmployee?.detail?.forEach(elem => {
            let nameValue = String(elem.details[0]?.processFormControlId)
            if(elem.details[0]?.valueReferenceId!==null){
              let newValues = []
              elem.details?.forEach(item => {
                newValues.push(item.valueReferenceId)
              })
              setValue(nameValue, newValues);
            }else{
              setValue(nameValue, elem.details[0].valueText);
            }
          })
        }

        if(dataEmployee.dynamicFieldValues?.length > 0){
          dataEmployee.dynamicFieldValues.forEach( dynamicFieldValue => {
            setValue(`dynamicFieldData:${dynamicFieldValue.identifier}`, dynamicFieldValue.value)
          })
        }
      }
    }
  }, [dataEmployee, loadingDataManager]);
  useEffect(() => {
    if (isSave) {
      clearSave();
      history.push("/rrhh/personas/");
    }
  }, [isSave]);
  useEffect(() => {
    if (status?.length > 0)
      setPayload({ ...payload, situationId: status[0].id });
  }, [status]);

  const { updateTitle } = useSetTitlePage();
  const getDataDepartmentGerente = (id) => {
    if (id !== undefined) {
      dataDepartmentGerente(id);
    }
  };
  useEffect(() => {
    updateTitle({
      title: "Gestión de la Organización",
      subtitle: "Empleados",
      description: title,
      previousUrl: "/rrhh/personas/",
    });
    if(!listControls){
      dispatch(CustomFieldActions.getListControls(RrhhConstants.Accion.Employees.GET_TYPE_PROCESS))
    }
    dispatch(CustomFieldActions.getActiveConfiguration(RrhhConstants.Accion.Employees.GET_TYPE_PROCESS))
  },[]);

  const SkeletonCustom = ({ width = "100%" }) => {
    return <Skeleton width={width} height="2.2rem" />;
  };
  const validDocument = (found, data) => {
    let valid = true;
    // if (
    //   found?.description.toLowerCase() === "ruc" &&
    //   data.documentNo.length != 11
    // ) {
    //   setError("documentNo", {
    //     type: "manual",
    //     message: "El documento de tipo Ruc solo acepta 11 digitos",
    //   });
    //   valid = false;
    // } else 
    if (
      found?.description.toLowerCase() === "dni" &&
      data.documentNo.length !== 8
    ) {
      setError("documentNo", {
        type: "manual",
        message: "El documento de tipo Dni solo acepta 8 digitos",
      });
      valid = false;
    } else if (
      found?.description.toLowerCase() === "pasaporte" &&
      data.documentNo.length !== 12
    ) {
      setError("documentNo", {
        type: "manual",
        message: "El documento de tipo Pasaporte solo acepta 12 caracteres",
      });
      valid = false;
    } else if (
      found?.description.toLowerCase() === "carnet de extranjeria" &&
      data.documentNo.length !== 12
    ) {
      setError("documentNo", {
        type: "manual",
        message:
          "El documento de tipo Carnet de extranjeria solo acepta 12  caracteres",
      });
      valid = false;
    }
    return valid;
  };

  const onSubmit = async(data) => {
    const dynamicDataValues = getDynamicFieldsList(data, dynamicFields, isNewEmployee, dataEmployee)
    const model = {...data, dynamicDataValues}
    
    model.savedFiles = data.savedFiles?.map(({ id }) => id);
    model.addedFiles = []
    if(data.addedFiles?.length > 0) {
      const addedFiles = await Promise.all(data.addedFiles.map(async(file) => {
        delete file.id;
        const fileBase64 = await getBase64(file);
        let dataBase64 = fileBase64.split("base64,")[1]
        const newFile = {
          fileName: file.name,
          fileType: file.type,
          fileBase64: dataBase64
        }
        return newFile;
      }));

      model.addedFiles = addedFiles;
    }

    

    model.savedFiles = data.savedFiles?.map(({ id }) => id);

    model.addedFiles = []
    if(data.addedFiles?.length > 0) {
      const addedFiles = await Promise.all(data.addedFiles.map(async(file) => {
        delete file.id;
        const fileBase64 = await getBase64(file);
        let dataBase64 = fileBase64.split("base64,")[1]
        const newFile = {
          fileName: file.name,
          fileType: file.type,
          fileBase64: dataBase64
        }
        return newFile;
      }));
  
      model.addedFiles = addedFiles;
    }

    // setPayload({ ...payload, ...data });
    setPayload({ ...payload, ...model });
    let found = dataManager?.documentsTypes.filter(
      // (p) => p.id === data.documentTypeId
      (p) => p.id === model.documentTypeId
    )[0];
    // let isValid = validDocument(found, data);
    let isValid = validDocument(found, model);

    if (isValid) {
      // createOrEditEmployee(data, title, "people", lastFile);
      createOrEditEmployee(model, title, "people", lastFile);
    }
  };

  const deleteExperiencie = () => {
    let newExperiencies = experiencies.splice(idDelete, idDelete);
    setObjectEdit(null);
    setExperiencies(newExperiencies);
  };

  const deleteStudy = () => {
    let newStudies = studies.splice(idDelete, idDelete);
    setStudies(newStudies);
    setObjectEdit(null);
  };

  const getDiferencia = (item) => {
    let months;
    let years;
    if (item.beginMonth !== null && item.endMonth !== null) {
      if (item.beginMonth < item.endMonth) {
        years = item.endYear - item.beginYear;
        months = item.endMonth - item.beginMonth;
      } else {
        months = item.beginMonth - item.endMonth;
        years = item.endYear - item.beginYear;
      }
    } else {
      let currentYear = new Date().getFullYear();
      let currentMonth = new Date().getMonth();
      months = currentMonth + 1;
      years = currentYear - item.beginYear;
    }
    return { months, years };
  };
  return (
    <div className="bg-white">
      <form className="form-custom p-0" onSubmit={handleSubmit(onSubmit)}>
        <Panel header="DATOS GENERALES" toggleable>
          <div className="flex md:flex-row flex-col-reverse md:items-start justify-between items-center mb-7 mt-4 w-full">
            <div className="flex flex-wrap justify-between gap-y-3 md:gap-y-6 w-11/12 md:w-9/12">
              {/* Nombres */}
              {loadingDataManager ? (
                <SkeletonCustom />
              ) : (
                <span className="p-float-label md:pr-8 w-full md:w-6/12 mb-0">
                  <Controller
                    control={control}
                    rules={{
                      required: "Los Nombres son obligatorios.",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <span className="p-float-label">
                        <InputText
                          onChange={onChange}
                          autoFocus
                          value={value}
                          id="firstName"
                          className={`w-full ${
                            errors.firstName?.type === "required"
                              ? "p-invalid"
                              : ""
                          }`}
                        />
                        <label htmlFor="firstName">Nombres *</label>
                      </span>
                    )}
                    name="firstName"
                  />
                </span>
              )}
              {/* Paterno */}
              {loadingDataManager ? (
                <SkeletonCustom />
              ) : (
                <span className="p-float-label md:pr-8  w-full md:w-6/12 mb-0">
                  <Controller
                    control={control}
                    rules={{
                      required: "El Apellido Paterno es obligatorio.",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <InputText
                          type="text"
                          id="fatherLastName"
                          value={value}
                          onChange={onChange}
                          className={
                            errors?.fatherLastName
                              ? "p-invalid w-full"
                              : "w-full"
                          }
                        />
                        <label htmlFor="fatherLastName">
                          Apellidos Paterno *{" "}
                        </label>
                      </>
                    )}
                    name="fatherLastName"
                  />
                </span>
              )}
              {/* Materno */}
              {loadingDataManager ? (
                <SkeletonCustom />
              ) : (
                <div className="w-full">
                  <span className="p-float-label md:pr-8  w-full md:w-6/12 mb-0">
                    <Controller
                      control={control}
                      rules={{
                        required: "El Apellido Materno es obligatorio.",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <InputText
                            type="text"
                            id="motherLastName"
                            name="motherLastName"
                            onChange={onChange}
                            value={value}
                            className={
                              errors?.motherLastName
                                ? "p-invalid w-full"
                                : "w-full"
                            }
                          />
                          <label htmlFor="motherLastName">
                            Apellidos Materno *{" "}
                          </label>
                        </>
                      )}
                      name="motherLastName"
                    />
                  </span>
                </div>
              )}
              {/* Telefono */}
              {loadingDataManager ? (
                <SkeletonCustom />
              ) : (
                <span className="p-float-label md:pr-8  w-full md:w-6/12 mb-0">
                  <InputText
                    type="tel"
                    id="companyPhone"
                    name="companyPhone"
                    value={payload?.companyPhone}
                    onChange={(e) =>
                      setPayload({ ...payload, companyPhone: e.target.value })
                    }
                  />
                  <label htmlFor="companyPhone">Teléfono Laboral </label>
                </span>
              )}
              {/* Correo */}
              {loadingDataManager ? (
                <SkeletonCustom />
              ) : (
                <Controller
                  control={control}
                  rules={{
                    pattern: {
                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: "El Formato de correo laboral no es válido",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <span className="p-float-label md:pr-8  w-full md:w-6/12 mb-0">
                      <InputText
                        type="email"
                        id="companyEmail"
                        value={value}
                        onChange={onChange}
                        className={`w-full ${
                          errors.companyEmail?.type === "pattern"
                            ? "p-invalid"
                            : ""
                        }`}
                      />
                      <label htmlFor="companyEmail">Correo Laboral </label>
                    </span>
                  )}
                  name="companyEmail"
                />
              )}
              {/* Tipo de Empleado */}
              {loadingDataManager ? (
                <SkeletonCustom />
              ) : (
                <span className="p-float-label  w-full md:w-6/12 md:pr-8 mb-0">
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Dropdown
                          onChange={onChange}
                          options={dataManager.employeesTypes}
                          id="employeeTypeId"
                          optionValue={(e) => {
                            return e.id;
                          }}
                          value={value}
                          optionLabel="description"
                          filter
                          showClear
                          className={
                            errors?.employeeTypeId
                              ? "p-invalid w-full"
                              : "w-full"
                          }
                        />
                        <label htmlFor="user_email">Tipo de empleado</label>
                      </>
                    )}
                    name="employeeTypeId"
                  />
                </span>
              )}
              {/* Cargo */}
              {loadingDataManager ? (
                <SkeletonCustom />
              ) : (
                <span className="p-float-label  w-full md:w-6/12 md:pr-8 mb-0">
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Dropdown
                          onChange={(e) => {
                            getDataDepartmentGerente(e.target.value);
                            onChange(e);
                          }}
                          options={dataManager.positions}
                          value={value}
                          name="positionId"
                          optionValue={(e) => {
                            return e.id;
                          }}
                          optionLabel="name"
                          filter
                          showClear
                          className={
                            errors?.positionId ? "p-invalid w-full" : "w-full"
                          }
                          id="positionId"
                        />
                        <label htmlFor="positionId">Cargo</label>
                      </>
                    )}
                    name="positionId"
                  />
                </span>
              )}
              {/* Departamento */}
              {loadingDataManager || loadingEdit ? (
                <SkeletonCustom />
              ) : (
                <span className="p-float-label  w-full md:w-6/12 md:pr-8 mb-0">
                  <InputText
                    readOnly
                    disabled
                    className="disabled"
                    type="text"
                    name="departmentId"
                    id="departmentId"
                    value={cargoSelect?.areaName}
                  />
                  <label htmlFor="departmentId">Departamento</label>
                </span>
              )}
              {/* Gerente */}
              {loadingDataManager || loadingEdit ? (
                <SkeletonCustom />
              ) : (
                <span className="p-float-label  w-full md:w-6/12 md:pr-8 mb-0">
                  <InputText
                    readOnly
                    disabled
                    className="disabled"
                    type="text"
                    name="gerenteId"
                    id="gerenteId"
                    value={cargoSelect?.managerName}
                  />
                  <label htmlFor="gerenteId">Gerente o jefe inmediato</label>
                </span>
              )}
            </div>
            <div className="user-picture w-3/12">
              {loadingDataManager ? (
                <Skeleton shape="circle" size="6rem"></Skeleton>
              ) : (
                <div>
                  <div className="foreground-user-picture">
                    {srcAvatar === null || srcAvatar === "" ? (
                      <i
                        className="pi pi-camera cursor-pointer"
                        onClick={() => inputFile.current.click()}
                      ></i>
                    ) : (
                      <i
                        className="pi pi-trash cursor-pointer"
                        onClick={() => {
                          setFile(null);
                          setSrcAvatar(null);
                        }}
                      ></i>
                    )}
                  </div>

                  <img
                    src={
                      srcAvatar === null || srcAvatar === ""
                        ? "/images/decorations/avatar.png"
                        : srcAvatar
                    }
                    className="avatar"
                    alt="Avatar"
                  />
                  <input
                    type="file"
                    className="hidden"
                    ref={inputFile}
                    onChange={onSelectedImage}
                  />
                  <div className="flex gap-2  justify-center my-4 ">
                    {loadingDataManager ? (
                      <>
                        <SkeletonCustom width="30%" />
                        <SkeletonCustom width="30%" />
                      </>
                    ) : (
                      <div className="state-field">
                        <span htmlFor="situationId">
                          {isActive ? "Activo" : "Cesado"}
                        </span>
                        <InputSwitch
                          disabled
                          checked={isActive}
                          name="situationId"
                          id="situationId"
                          onChange={(e) => {
                            setIsActive(!isActive);
                            setPayload({
                              ...payload,
                              situationId: isActive
                                ? status[1]?.id
                                : status[0]?.id,
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-6">
            {
              (!loadingEdit && !loadingDataManager) &&
              <DynamicFieldList control={control} fields={dynamicFieldsDatosGenerales} />
            }
          </div>
        </Panel>
        <Panel header="INFORMACIÓN PERSONAL" toggleable>
          <div className="flex items-start gap-3 justify-between mb-7 w-full">
            <div className="flex flex-wrap w-full gap-0 md:gap-y-6">

              {loadingDataManager ? (
                <SkeletonCustom />
              ) : (
                <React.Fragment>
                  {/* Nacionalidad */}
                  <div className="w-full md:w-4/12 md:pr-8">
                    <h6 className="p-float-label mt-4 text-xs font-bold pl-2 mb-4">
                      CIUDADANÍA
                    </h6>
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Dropdown
                            onChange={onChange}
                            options={dataManager.nationalities}
                            optionValue={(e) => {
                              return e.id;
                            }}
                            value={value}
                            optionLabel="niceName"
                            filter
                            showClear
                            className={`w-full ${
                              errors.countryOfBirthId?.type === "required"
                                ? "p-invalid"
                                : ""
                            }`}
                            placeholder="Nacionalidad (Páis)"
                          />
                        </>
                      )}
                      name="countryOfBirthId"
                    />
                  </div>
                  {/* Fecha de Nacimiento */}
                  <div className="w-full md:w-4/12 md:pr-8">
                    <span className="p-float-label w-full mt-6 md:mt-12">
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <Calendar
                              monthNavigator
                              yearNavigator
                              yearRange="1950:2030"
                              showIcon={false}
                              maxDate={today}
                              onChange={(e) => {
                                // e.target.value = moment.parseZone(e.target.value).utc().format()
                                onChange(e);
                              }}
                              value={value}
                              className={`w-full ${
                                errors.dateOfBirth?.type === "required"
                                  ? "p-invalid"
                                  : ""
                              }`}
                              id="dateOfBirth"
                            ></Calendar>
                            <label htmlFor="dateOfBirth">
                              Fecha de nacimiento
                            </label>
                          </>
                        )}
                        name="dateOfBirth"
                      />
                    </span>
                  </div>
                   {/* Genero */}
                  <div className="w-full md:w-4/12 md:pr-8">
                    <span className="p-float-label w-full mt-6 md:mt-12">
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <Dropdown
                              onChange={onChange}
                              options={dataManager.gender}
                              optionValue={(e) => {
                                return e.id;
                              }}
                              value={value}
                              optionLabel="description"
                              filter
                              showClear
                              className={`w-full ${
                                errors.genderId?.type === "required"
                                  ? "p-invalid"
                                  : ""
                              }`}
                              placeholder="Género"
                            />
                          </>
                        )}
                        name="genderId"
                      />
                    </span>
                  </div>
                  {/* Tipo de documento */}
                  <div className="w-full md:w-4/12 md:pr-8">
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Dropdown
                            onChange={onChange}
                            options={dataManager?.documentsTypes}
                            // options={documentos}
                            optionValue={(e) => {
                              return e.id;
                            }}
                            value={value}
                            optionLabel="description"
                            // optionLabel="label"
                            filter
                            showClear
                            className={`w-full ${
                              errors.documentTypeId?.type === "required"
                                ? "p-invalid"
                                : ""
                            }`}
                            placeholder="Tipo de documento de identidad"
                          />
                        </>
                      )}
                      name="documentTypeId"
                    />
                  </div>
                  {/* Numero de documento */}
                  <div className="w-full md:w-8/12 md:pr-8">
                    <span className="p-float-label w-full">
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <InputText
                              type="number"
                              id="documentNo"
                              value={value}
                              onChange={(e) => {
                                const optionDocumentRuc = dataManager?.documentsTypes.find( 
                                  ({description}) => description.toLowerCase() === "ruc")
                                
                                if(e.target.value?.length > 11 && optionDocumentRuc?.id === getValues("documentTypeId")){
                                  e.target.value = e.target.value.slice(0,11)
                                }
                                
                                onChange(e)
                              }}
                              className={`w-6/12 ${
                                errors.documentNo?.type === "required" ||
                                errors.documentNo?.type === "manual"
                                  ? "p-invalid"
                                  : ""
                              }`}
                            />
                            <label htmlFor="documentNo">
                              Numero de Documento
                            </label>
                          </>
                        )}
                        name="documentNo"
                      />
                    </span>
                  </div>
                   {/* Estado */}
                  <div className="w-full md:w-4/12 md:pr-8 mt-0">
                    <h6 className="p-float-label w-full text-xs font-bold mb-4 pl-2">
                      ESTADO CIVIL
                    </h6>
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Dropdown
                            onChange={onChange}
                            options={dataManager.statusCivil}
                            value={value}
                            optionValue={(e) => {
                              return e.id;
                            }}
                            optionLabel="description"
                            filter
                            showClear
                            className={`w-full ${
                              errors.maritalStatusId?.type === "required"
                                ? "p-invalid"
                                : ""
                            }`}
                            placeholder="Estado Civil"
                          />
                        </>
                      )}
                      name="maritalStatusId"
                    />
                  </div>
                  {/* Numero de Hijos */}
                  <div className=" w-full md:w-8/12 md:pr-8">
                    <div className="w-6/12">
                      <h6 className="p-float-label w-full  mt-0 text-xs font-bold pl-2 mb-4">
                        DEPENDIENTE
                      </h6>
                      <span className="p-float-label w-full ">
                        <InputText
                          type="number"
                          id="childrenQuantity"
                          name="childrenQuantity"
                          value={payload?.childrenQuantity}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              childrenQuantity: parseInt(e.target.value),
                            })
                          }
                        />
                        <label htmlFor="childrenQuantity">Número de hijos</label>
                      </span>
                    </div>
                  </div>

                  {/* País de residencia */}
                  <div className="w-full md:w-4/12 md:pr-8">
                    <span className="p-float-label w-full mt-4">
                      <h6 className="p-float-label w-full mb-4 text-xs font-bold pl-2">
                        CONTACTO PRIVADO
                      </h6>
                      <Dropdown
                        onChange={(e) => {
                          if (e.target.value !== undefined) {
                            let value = dataManager.nationalities.find(el => el.id===e.target.value)
                            setCountrySelected(value);

                            if(value?.isoCode==='PE'){
                              setPayload({
                                ...payload,
                                countryOfResidenceId: e.target.value,
                              });
                            }else{
                              setPayload({
                                ...payload,
                                countryOfResidenceId: e.target.value,
                                departmentId: null,
                                cityId: null,
                                districtId: null
                              });
                            }
                          }
                        }}
                        options={dataManager.nationalities}
                        value={payload?.countryOfResidenceId}
                        name="countryOfResidenceId"
                        optionValue={(e) => {
                          return e.id;
                        }}
                        optionLabel="niceName"
                        filter
                        filterBy="niceName"
                        showClear
                        className="w-full"
                        placeholder="País de residencia"
                      />
                    </span>
                  </div>
                  {/* Departamento */}
                  <div className="w-full md:w-4/12 md:pr-8">
                    <span className="p-float-label w-full mt-6 md:mt-12">
                      <Dropdown
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            departmentId: e.target.value,
                          });
                        }}
                        disabled={countrySelected && countrySelected?.isoCode !== "PE"}
                        className={
                          countrySelected && countrySelected?.isoCode  !== "PE"
                            ? "disabled"
                            : "w-full"
                        }
                        options={dataManager.departments}
                        value={payload?.departmentId}
                        name="departmentId"
                        optionValue={(e) => {
                          return e.id;
                        }}
                        optionLabel="name"
                        filter
                        showClear
                        placeholder="Departamento"
                      />
                    </span>
                  </div>
                   {/* Provincia */}
                  <div className="w-full md:w-4/12 md:pr-8">
                    <Dropdown
                      onChange={(e) => {
                        setPayload({ ...payload, cityId: e.target.value });
                      }}
                      disabled={countrySelected && countrySelected?.isoCode !== "PE"}
                      className={
                        countrySelected && countrySelected?.isoCode !== "PE"
                          ? "mt-6 md:mt-12 disabled"
                          : "mt-6 md:mt-12 w-full"
                      }
                      options={dataManager.cities}
                      value={payload?.cityId}
                      name="cityId"
                      optionValue={(e) => {
                        return e.id;
                      }}
                      optionLabel="name"
                      filter
                      showClear
                      placeholder="Provincia"
                    />
                  </div>
                  {/* Distrito */}
                  <div className="w-full md:w-4/12 md:pr-8">
                    <span className="p-float-label w-full">
                      <Dropdown
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            districtId: e.target.value,
                          });
                        }}
                        disabled={countrySelected && countrySelected?.isoCode !== "PE"}
                        className={
                          countrySelected && countrySelected?.isoCode !== "PE"
                            ? "disabled"
                            : "w-full"
                        }
                        options={dataManager.districts}
                        value={payload?.districtId}
                        name="districtId"
                        optionValue={(e) => {
                          return e.id;
                        }}
                        optionLabel="name"
                        filter
                        showClear
                        placeholder="Distrito"
                      />
                    </span>
                  </div>
                   {/* Direccion */}
                  <div className="w-full md:w-8/12 md:pr-8">
                    <span className="p-float-label w-full">
                      <InputText
                        type="text"
                        id="address"
                        name="address"
                        value={payload?.address}
                        onChange={(e) =>
                          setPayload({ ...payload, address: e.target.value })
                        }
                      />
                      <label htmlFor="address">Dirección</label>
                    </span>
                  </div>
                   {/* Telefono */}
                  <div className="w-full md:w-4/12 md:pr-8">
                    <span className="p-float-label w-full">
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <InputText
                              value={value}
                              type="number"
                              id="personalPhoneNo"
                              onChange={onChange}
                              className={`w-full ${
                                errors.personalPhoneNo?.type === "required"
                                  ? "p-invalid"
                                  : ""
                              }`}
                            />
                            <label htmlFor="personalPhoneNo">Télefono</label>
                          </>
                        )}
                        name="personalPhoneNo"
                      />
                    </span>
                  </div>
                  {/* Celular */}
                  <div className="w-full md:w-4/12 md:pr-8">
                    <span className="p-float-label w-full">
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <InputText
                              value={value}
                              type="number"
                              id="mobilePhoneNo"
                              onChange={onChange}
                              className={`w-full ${
                                errors.mobilePhoneNo?.type === "required"
                                  ? "p-invalid"
                                  : ""
                              }`}
                            />
                            <label htmlFor="mobilePhoneNo">Celular</label>
                          </>
                        )}
                        name="mobilePhoneNo"
                      />
                    </span>
                  </div>
                  {/* Correo */}
                  <div className="w-full md:w-4/12 md:pr-8">
                    <Controller
                      control={control}
                      rules={{
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: "El Formato de correo personal no es válido",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <span className="p-float-label w-full ">
                          <InputText
                            type="email"
                            id="personalEmail"
                            value={value}
                            onChange={onChange}
                            className={`w-full ${
                              errors.personalEmail?.type === "pattern"
                                ? "p-invalid"
                                : ""
                            }`}
                          />
                          <label htmlFor="personalEmail">Correo *</label>
                        </span>
                      )}
                      name="personalEmail"
                    />
                  </div>
                  {/* Contacto */}
                  <div className="w-full md:w-4/12 md:pr-8">
                    <h6 className="p-float-label w-full text-xs mt-0 font-bold pl-2 mb-4">
                      CONTACTO DE EMERGENCIA
                    </h6>
                    <span className="p-float-label w-full">
                      <InputText
                        type="text"
                        id="emergencyContactName"
                        name="emergencyContactName"
                        value={payload?.emergencyContactName}
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            emergencyContactName: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="emergencyContactName">Contacto</label>
                    </span>
                  </div>
                   {/* Telefono */}
                   <div className="w-full md:w-8/12 md:pr-8">
                    <span className="p-float-label w-6/12 mt-8">
                      <InputText
                        type="tel"
                        id="emergencyPhoneNo"
                        name="emergencyPhoneNo"
                        value={payload?.emergencyPhoneNo}
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            emergencyPhoneNo: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="emergencyPhoneNo">Teléfono</label>
                    </span>
                  </div>
              
                </React.Fragment>
              )}
              
            </div>
          </div>
          <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-6">
            {
              (!loadingEdit && !loadingDataManager) &&
              <DynamicFieldList control={control} fields={dynamicFieldsInformacionPersonal} />
            }
          </div>
        </Panel>
        <Panel header="ESTUDIOS REALIZADOS" toggleable>
          {!loadingDataManager && (
            <>
              <div className="mb-7  w-full md:w-7/12">
                <Button
                  icon="pi pi-plus"
                  type="button"
                  label="Añadir nuevo estudio realizado "
                  onClick={() => openModal("study")}
                  // disabled={status === ''}
                  className="btn btn-primary mb-7 rounded-none w-full md:w-auto"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Dropdown
                        disabled
                        onChange={onChange}
                        options={dataManager.instructions}
                        optionValue={(e) => {
                          return e.id;
                        }}
                        value={value}
                        optionLabel="description"
                        filter
                        showClear
                        className={`w-full disabled`}
                        placeholder="Grado de instrucción (Máximo nivel alcanzado)"
                      />
                    </>
                  )}
                  name="academicDegreeId"
                />
              </div>
              <div className="mb-7  w-full grid grid-cols-1 md:grid-cols-2 gap-2">
                {studies.map((item, index) => {
                  let grado = dataManager.instructions.filter(
                    (p) => p.id === parseInt(item.professionalDegreeId)
                  )[0];
                  return (
                    <div
                      key={index}
                      className="rounded-none w-full block p-4  bg-white border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                    >
                      <div className="flex justify-between">
                        <h5 className="mb-1 text-md font-bold   text-gray-900 dark:text-white">
                          {item.academicInstitutionName}
                        </h5>
                        <i
                          key={index}
                          className="pi pi-ellipsis-v cursor-pointer"
                          onClick={(e) => {
                            setidDelete(index);
                            setObjectEdit(studies[index]);
                            menu.current.toggle(e);
                          }}
                        ></i>
                      </div>
                      <div className="block">
                        <p className="text-sm text-gray-700 dark:text-gray-400">
                          <span className="font-bold">Grado</span> :
                          {grado.description}
                        </p>
                        <p className="text-sm text-gray-700 dark:text-gray-400 mt-2">
                          <span className="font-bold">
                            Disciplina Académica :{" "}
                          </span>
                          {item.professionName}
                        </p>
                      </div>
                      <span className="text-xs float-left mt-2 px-2 py-1 text-black bg-white rounded-full border border-gray-200">
                        {item.beginYear} -{" "}
                        {item.endYear ? item.endYear : "Actualmente"}
                      </span>
                    </div>
                  );
                })}
                <Menu model={itemsStudy} popup ref={menu} id="popup_menu_2" />
              </div>
            </>
          )}
        </Panel>
        <Panel header="EXPERIENCIA LABORAL" toggleable>
          {!loadingDataManager && (
            <>
              <div className="mb-7  w-full md:w-7/12">
                <Button
                  icon="pi pi-plus"
                  type="button"
                  label="Añadir nueva experiencia laboral "
                  onClick={() => openModal("experience")}
                  className="btn btn-primary mb-7 rounded-none w-full md:w-auto"
                />
              </div>
              <div className="mb-7  w-full grid grid-cols-1 md:grid-cols-2 gap-2">
                {experiencies.map((item, index) => {
                  const { months, years } = getDiferencia(item);
                  return (
                    <div
                      key={index}
                      className="rounded-none w-full block p-4  bg-white border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                    >
                      <div className="flex justify-between">
                        <h5 className="mb-1 text-md font-bold   text-gray-900 dark:text-white">
                          {item.companyName}
                        </h5>
                        <i
                          key={index}
                          className="pi pi-ellipsis-v cursor-pointer"
                          onClick={(e) => {
                            menu2.current.toggle(e);
                            setidDelete(index);
                            setObjectEdit(experiencies[index]);
                          }}
                        ></i>
                      </div>
                      <div className="block">
                        <p className="text-sm text-gray-700 dark:text-gray-400">
                          {item.positionName}
                        </p>
                        <p className="text-sm text-gray-700 dark:text-gray-400 mt-2">
                          {months_[item.beginMonth - 1]} {item.beginYear} -{" "}
                          {item.endMonth === null
                            ? "Actualmente"
                            : `${months_[item.endMonth - 1]} ${
                                item.endYear
                              } `}{" "}
                          , {years} Años y {months} meses
                        </p>
                      </div>
                    </div>
                  );
                })}
                <Menu
                  model={itemsExperiencie}
                  popup
                  ref={menu2}
                  id="popup_menu"
                />
              </div>
            </>
          )}
        </Panel>
        <Panel header="INFORMACIÓN LABORAL" toggleable>
          <div
            className={
              (title === "Editar Empleado" || title==='Ver Empleado')
                ? "w-full grid grid-cols-1 md:grid-cols-5"
                : "w-full grid grid-cols-1 md:grid-cols-5"
            }
          >
            <div className={`w-full ${(title === "Editar Empleado"|| title==='Ver Empleado') ? "col-span-3" : "col-span-5"}`}>
              <div className="flex flex-wrap">
                {/* Ubicacion  */}
                {loadingDataManager ? (
                  <SkeletonCustom />
                ) : (
                  <div className="w-full">
                    <div className="w-full md:w-6/12">
                      <h6 className="md:font-bold text-xs mb-4">UBICACIÓN</h6>
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <Dropdown
                              value={value}
                              onChange={onChange}
                              options={dataManager.branches}
                              optionValue={(e) => {
                                return e.id;
                              }}
                              optionLabel="name"
                              filter
                              showClear
                              className={`w-full ${
                                errors.branchId?.type === "required"
                                  ? "p-invalid"
                                  : ""
                              }`}
                              placeholder="Sede"
                              id="branchId"
                            />
                          </>
                        )}
                        name="branchId"
                      />
                    </div>
                  </div>
                )}
                 {/* Inicio */}
                 {loadingDataManager ? (
                  <SkeletonCustom />
                ) : (
                  <div className="w-6/12">
                    <h6 className="font-bold text-xs mt-4 mb-4 pl-2">
                      PLANILLA
                    </h6>
                    <span className="p-float-label w-full">
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <Calendar
                              monthNavigator
                              yearNavigator
                              yearRange="1950:2030"
                              className={`w-full ${
                                errors.contractStartDate?.type === "required"
                                  ? "p-invalid"
                                  : ""
                              }`}
                              id="contractStartDate"
                              value={value}
                              showIcon={false}
                              onChange={(e) => {
                                // e.target.value = moment.parseZone(e.target.value).utc().format()
                                onChange(e);
                              }}
                            ></Calendar>
                            <label htmlFor="contractStartDate">
                              Fecha de Inicio
                            </label>
                          </>
                        )}
                        name="contractStartDate"
                      />
                    </span>
                  </div>
                )}
                {/* Fin */}
                {loadingDataManager ? (
                  <SkeletonCustom />
                ) : (
                  <div className="w-6/12 pl-4">
                    <Controller
                      control={control}
                      rules={
                        !isActive
                          ? {
                              required: "Los Fecha de Termino es obligatoria.",
                            }
                          : {}
                      }
                      render={({ field: { onChange, value } }) => (
                        <span className="p-float-label mt-12 w-full">
                          <Calendar
                            monthNavigator
                            yearNavigator
                            yearRange="1950:2030"
                            className={`w-full  ${
                              isActive ? "disabled" : ""
                            }  ${
                              errors.contractEndtDate?.type === "required"
                                ? "p-invalid"
                                : ""
                            }`}
                            // disabled={!isNewEmployee ? isActive : true}
                            disabled={isActive}
                            id="contractEndtDate"
                            value={value}
                            showIcon={false}
                            onChange={(e) => {
                              // e.target.value = moment.parseZone(e.target.value).utc().format()
                              onChange(e);
                            }}
                          ></Calendar>
                           <label htmlFor="contractEndtDate">
                            Fecha de Termino
                          </label>
                        </span>
                      )}
                      name="contractEndtDate"
                    />
                  </div>
                )}
                 {/* Horas laboral */}
                 {loadingDataManager ? (
                  <SkeletonCustom />
                ) : (
                  <div className="w-full">
                    <div className="w-6/12">
                      <Dropdown
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            weeklyLaborHours: e.target.value,
                          });
                        }}
                        options={dataManager?.workDays}
                        value={payload?.weeklyLaborHours}
                        name="weeklyLaborHours"
                        optionValue={(e) => {
                          return e.id;
                        }}
                        optionLabel="description"
                        filter
                        showClear
                        className="w-full md:w-6/12 mt-4"
                        placeholder="Horas Laborales"
                        id="weeklyLaborHours"
                      />
                    </div>
                  </div>
                )}
                {/* Permiso */}
                {loadingDataManager ? (
                  <SkeletonCustom />
                ) : (
                  <div className="w-full md:w-6/12">
                    <h6 className="md:font-bold text-xs mt-4 mb-4 pl-2">
                      PERMISO
                    </h6>
                    <span className="p-float-label w-full">
                      <InputText
                        type="text"
                        id="workPermitNumber"
                        name="workPermitNumber"
                        // className={errors?.userName ? 'p-invalid w-full' : 'w-full'}
                        value={payload?.workPermitNumber}
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            workPermitNumber: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="workPermitNumber" className="md:pt-0">
                        Número de permiso
                      </label>
                    </span>
                  </div>
                )}
                {/* Expiracion */}
                {loadingDataManager ? (
                  <SkeletonCustom />
                ) : (
                  <div className="w-full md:w-6/12 pl-4">
                    <span className="p-float-label w-full mt-6 md:mt-12">
                      <Calendar
                        monthNavigator
                        yearNavigator
                        yearRange="1950:2030"
                        className="w-full"
                        id="workPermitExpirationDate"
                        value={payload?.workPermitExpirationDate}
                        showIcon={false}
                        onChange={(e) => {
                          // setPayload({ ...payload, workPermitExpirationDate: moment.parseZone(e.target.value).utc().format()
                          setPayload({
                            ...payload,
                            workPermitExpirationDate: e.target.value,
                          });
                        }}
                      ></Calendar>
                      <label htmlFor="workPermitExpirationDate">
                        Expiración de permiso
                      </label>
                    </span>
                  </div>
                )}

                {/* Situacion */}
                {loadingDataManager ? (
                  <SkeletonCustom />
                ) : (
                  <div className="w-full md:w-6/12 pr-4">
                    <h6 className="font-bold text-xs mt-4 mb-4">
                      SITUACIÓN LABORAL
                    </h6>
                    <Dropdown
                      optionLabel="description"
                      disabled={isNewEmployee}
                      optionValue="id"
                      value={payload?.situationId}
                      options={status}
                      onChange={(e) => {
                        let found = status.filter(
                          (p) => p.id === e.target.value
                        )[0];
                        setIsActive(found.code === "ACT");
                        setPayload({
                          ...payload,
                          situationId: e.target.value,
                        });
                      }}
                      className={isNewEmployee ? "disabled" : ""}
                      placeholder="Estado"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:gap-3 gap-6 mt-6">
            {
              (!loadingEdit && !loadingDataManager) &&
              <DynamicFieldList control={control} fields={dynamicFieldsInformacionLaboral} />
            }
          </div>
        </Panel>
        <Attachments
          // module="humanresource"
          // addEndpoint={"api/Employee/AddAttachment"}
          // deleteEndpoint={"api/Employee/DeleteAttachment"}
          // listEndpoint={"api/Employee/GetAttachmentsByEmployeeId"}
          setValue={setValue}
          fieldFiles="savedFiles"
          fieldNewFiles="addedFiles"
          filesValues={valuesFields?.["savedFiles"]}
          newFilesValues={valuesFields?.["addedFiles"]}
          isLoading={loadingDataManager}
          validExtensions={["docx", "pdf", "xlsx"]}
          title="ADJUNTAR HOJA DE VIDA"
        />
        {
          (listControls && lastFile)&&<ViewCustomField data={lastFile?.section?.controls} control={control} errors={errors} setValue={setValue} listOptions={listControls} valuesFields={valuesFields}/>
        }
          {Object.entries(errors).forEach((key, value) => {
              errorsArray.push(key[1].message);
          })}
          {errorsArray.length > 0 && (
            <Message messages={errorsArray} status="error" />
          )}
        <div className="flex justify-end gap-4 mt-3">
          <Button
            icon="pi pi-times"
            type="button"
            label="Cancelar"
            onClick={() => setVisible(true)}
            loading={loadingDataManager}
            disabled={loadingDataManager}
            className="sig-button  sig-secondary mt-4"
          />
          {
            (mode!=='view')&&(
              <Button
                icon="pi pi-save"
                type="submit"
                label="Guardar"
                onClick={handleClick}
                loading={loadingDataManager}
                className="sig-button sig-primary mt-4"
              />
            )
          }
        </div>
      </form>
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="¿Está seguro que desea cancelar?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
      <ConfirmDialog
        visible={visibleDeleteStudy}
        onHide={() => setVisibleDeleteStudy(false)}
        message="¿Está seguro que desea eliminar el estudio?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={deleteStudy}
        reject={() => setVisibleDeleteStudy(false)}
      />
      <ConfirmDialog
        visible={visibleDeleteExperiencie}
        onHide={() => setVisibleDeleteExperiencie(false)}
        message="¿Está seguro que desea eliminar la experiencia?"
        header="Cancelar"
        icon="pi pi-exclamation-triangle"
        accept={deleteExperiencie}
        reject={() => setVisibleDeleteExperiencie(false)}
      />

      {!loadingDataManager && (
        <>
          <EmployeeModalExperience
            objectEdit={objectEdit}
            showModal={showModalExperience}
            experiencies={experiencies}
            setExperiencies={setExperiencies}
            dataManager={dataManager}
            closeModal={closeModal}
            isEditModal={isEditModal}
            setIsEditModal={setIsEditModal}
          />
          <EmployeeModalStudy
            objectEdit={objectEdit}
            isEditModal={isEditModal}
            setIsEditModal={setIsEditModal}
            dataManager={dataManager}
            showModal={showModalStudy}
            closeModal={closeModal}
            setStudies={setStudies}
            studies={studies}
          />
        </>
      )}
    </div>
  );
};

export default NewPeoplePage;
