import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubscriptionsAction } from 'features/subscription/store/actions/SubscriptionsAction'

const useModalEmployee = () =>{
  const dispatch = useDispatch();
  const {showModalState , typeModal} = useSelector((state) => state.subscriptionsReducer.subscriptionEdit);

  const openModal = (type) =>{
    dispatch(SubscriptionsAction.modalState({showModalState:true,typeModal:type}));

  }

  const closeModal = () => {
    dispatch(SubscriptionsAction.modalState({showModalState:false}));
  }
  return {
    showModalState,
    openModal,
    closeModal,
    typeModal
  }
}

export default useModalEmployee