import { apiService } from "core/services/ApiService";
const currentModule = "customer/api/BusinessSheetPublic";

const getByGuId = (guid) => {
  return apiService.Post(`${currentModule}/GetBusinessSheetByGuid?guid=${guid}`);
};

const getLogoAndColorSchema = (surveyGuid) => {
  return apiService.Get(`${currentModule}/GetLogoAndColorSchema?guid=${surveyGuid}`);
};

const save = (payload) => {
  return apiService.Post(`${currentModule}/SaveBusinessSheetActivity`, payload);
};

export const BusinessSheetPublicService = {
  getByGuId,
  getLogoAndColorSchema,
  save,
};
