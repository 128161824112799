import { useClaimOccupation } from "features/maintenance/hooks/useClaimOccupation";
import { OccupationAction } from "features/maintenance/store/actions/OccupationAction";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useSetTitlePage from "shared/hooks/useSetTitlePage";

const { useForm, Controller } = require("react-hook-form")

const OccupationPage = ({title, mode}) => {
  const {updateTitle } = useSetTitlePage()
  const history = useHistory();
  const dispatch = useDispatch();
  const occupation = useSelector((state) => state.occupationReducer.occupation);  
  const [isFirstLoading, setFirstLoading] = useState(true);

  const [visible, setVisible] = useState(false);

  const {isCreate, isEdit} = useClaimOccupation();

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    name: "",
    id: null,
    code: "",
  });

    
  const params = useParams();

  const redirectToSearch = () => {
    history.push("/mantenimiento/ocupacion");
    dispatch(OccupationAction.resetAfterSaved());
  }

  useEffect(() => {

    if (occupation?.data) {
        setValue("name", occupation.data.name);
        setValue("id", occupation.data.id);
        setValue("code", occupation.data.code);
    }
    if (occupation?.isSaved)
        redirectToSearch();
  }, [occupation]);

  
  useEffect(()=> {
    setValue("name", undefined);
    setValue("id", undefined);
    setValue("code", undefined);

    updateTitle({
      title: "Mantenimietno",
      subtitle: "Ocupación",
      description: title,
      previousUrl: "/mantenimiento/ocupacion/"
    })
    if (isFirstLoading) {
      setFirstLoading(false);
      if (params.id)
          getOccupationById(params.id)
    }
  }, [])

  const getOccupationById = (occupationId) => {
    dispatch(OccupationAction.getOccupationById(occupationId));
  }

  const onSubmit = (data) => {
    // console.log(data)
    const model = {
        id: data.id,
        name: data.name,
        code: data.code,
    }
    
    dispatch(OccupationAction.saveOccupation(model));
  };


  const reject = () => {
      setVisible(false);
  };

  return (
    <div className="bg-white">
      <form 
        className="form-custom p-0"
        onSubmit={handleSubmit(onSubmit)}
      >
      <div className="form-modal p-0">
        <Panel header="DATOS GENERALES" toggleable>
          <div className=" grid  sm:grid-cols-1 md:grid-cols-2 gap-2 ">
          
            {occupation.isLoading ? (
              <Skeleton />
            ): (
              <span className="p-float-label w-full mt-2">
              <Controller
                      control={control}
                      render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                      }) => (
                        <InputText
                            type="text"
                            id="code"
                            name="code"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}                
                            className={errors?.code ? 'p-invalid w-full' : 'w-full'}
                        />
                      )}
                      name="code"
                      rules={{
                          required: 'El código es requerido',
                      }}
                  />
                    
                  <label htmlFor="code">Código *</label>
              </span>
            )}
              <span className="p-float-label w-full mt-4 hidden md:block">
                &nbsp;
              </span>
              </div>
              {
                occupation.isLoading ?(
                  <Skeleton height="2.2rem" />
                ) : (  
                  <div className=" grid  sm:grid-cols-1 md:grid-cols-1 gap-1 mt-2 mb-1">
                    <span className="p-float-label w-full mt-4">
                      <Controller
                          control={control}
                          render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                          <InputText
                            type="text"
                            id="name"
                            name="name"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            className={errors?.name ? 'p-invalid w-full' : 'w-full'}
                          />
                          )}
                          name="name"
                          rules={{
                              required: 'El nombre de ocupación es requerido',
                          }}
                    />
                    
                    <label htmlFor="name">Nombre de Ocupación *</label>
                  </span>              
                  </div>
                )
              }                 
        </Panel>        
      </div>
      {
        (errors.name) && <div className='message error'>
            <ul>
                {errors.name ? (
                    <>
                        <li className="p-error">{errors.name.message}</li>
                    </>
                ) : null}
                {errors.code ? (
                    <>
                        <li className="p-error">{errors.code.message}</li>
                    </>
                ) : null}
            </ul>

        </div>
        }
      <div className="flex justify-end gap-4 mt-3">
                    <Button
                        icon="pi pi-times"
                        type="button"
                        label="Cancelar"
                        onClick={() => { setVisible(true); }}
                        className="sig-button sig-secondary mt-4"
                    />
                    {
                      (mode!=='view')&&(<>
                      {
                        (isCreate || isEdit)&&
                          <Button
                              icon="pi pi-save"
                              type="submit"
                              label="Guardar"
                              loading={occupation?.isSaving || occupation?.isLoading}
                              className="sig-button sig-primary mt-4"
                          />
                      }
                      </>
                      )
                    }
                </div>
      </form>
      <ConfirmDialog
                visible={visible}
                onHide={() => setVisible(false)}
                message="¿Está seguro que desea cancelar?"
                header="Cancelar"
                icon="pi pi-exclamation-triangle"
                accept={redirectToSearch}
                reject={reject}
            />
    </div>
  )
}

export default OccupationPage;