/* eslint-disable react-hooks/exhaustive-deps */
// import { DocumentLibraryAction } from './../../store/actions/DocumentLibraryAction';
import { useSelector, useDispatch } from 'react-redux';
import ModalFilterComponent from 'shared/components/modal-filter/ModalFilterComponent';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { IndicatorsAction } from 'features/indicators/store/actions/IndicatorAction';

const AdvancedFilterComponent = () => {


    const dispatch = useDispatch();
    const [formValues, setValues] = useState({
        from: null,
        to: null,
        code: null,
        owner: null,
        status: null,
        source: null,
        period: null,
    });

    const advancedFilter = useSelector((state) => state.indicatorReducer.advancedFilter);
    const { showModal } = useSelector((state) => state.indicatorReducer.advancedFilter);
    const dataMaster = useSelector((state) => state.indicatorReducer.dataMaster);

    useEffect(() => {
        setValues({
            from: advancedFilter.filter.from,
            to: advancedFilter.filter.to,
            code: advancedFilter.filter.code,
            owner: advancedFilter.filter.owner,
            status: advancedFilter.filter.status,
            source: advancedFilter.filter.source,
            period: advancedFilter.filter.period,
        });
        // dispatch(IndicatorsAction.getDataMaster());
    }, [advancedFilter]);

    useEffect(() => {
        dispatch(IndicatorsAction.getDataMaster());
    },[])


    const clearFields = () => {
        setValues({
            from: null,
            to: null,
            code: null,
            owner: null,
            status: null,
            source: null,
            period: null,
        });
    };
    const closeModal = () => {
        clearFields()
        dispatch(IndicatorsAction.toggleAdvancedFilter({ showModal: false }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const fields = formValues;
        if (fields?.from)
            advancedFilter.filter.from = fields.from;
        else
            advancedFilter.filter.from = null;


        if (fields?.to)
            advancedFilter.filter.to = fields.to;
        else
            advancedFilter.filter.to = null;

        if (fields?.code)
            advancedFilter.filter.code = fields.code;
        else
            advancedFilter.filter.code = null;


        if (fields?.owner)
            advancedFilter.filter.owner = fields.owner;
        else
            advancedFilter.filter.owner = null;

        if (fields?.status)
            advancedFilter.filter.status = fields.status;
        else
            advancedFilter.filter.status = null;

        if (fields?.source)
            advancedFilter.filter.source = fields.source;
        else
            advancedFilter.filter.source = null;

        if (fields?.period)
            advancedFilter.filter.period = fields.period;
        else
            advancedFilter.filter.period = null;

        dispatch(IndicatorsAction.updateFilter(advancedFilter));
        dispatch(IndicatorsAction.search());
        dispatch(IndicatorsAction.toggleAdvancedFilter({ showModal: false }));
    }

    const onChangeFrom = (e) => {
        if (e.value)
            formValues.to = null;
        setValues({
            ...formValues,
            [e.target.name]: e.value,
        });
    }

    const onChangeTo = (e) => {
        setValues({
            ...formValues,
            [e.target.name]: e.value,
        });
    }

    const onChangeCode = (e) => {
        setValues({
            ...formValues,
            code: e.target.value,
        });
    }

    const onChangeOwner = (e) => {
        setValues({
            ...formValues,
            owner: e.value,
        });
    }

    const onChangeStatus = (e) => {
        
        setValues({
            ...formValues,
            status: e.value,
        });
    }

    const onChangeSource = (e) => {
        
        setValues({
            ...formValues,
            source: e.value,
        });
    }

    const onChangePeriod = (value) => {
      setValues({
        ...formValues,
        period: value
      })
    }
    
    if (showModal)
        return (
            <ModalFilterComponent
                disabledBtn={false}
                closeModal={closeModal}
                clearFields={clearFields}
                handleSubmit={handleSubmit}
                searchLoading={advancedFilter?.loading}
            >

                <div className="flex gap-4 pt-3">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                value={formValues.from}
                                name="from"
                                id="desde"
                                onChange={(e) => onChangeFrom(e)}
                            />
                            <label htmlFor="desde">Desde</label>
                        </span>
                    </div>
                    <div className="w-full">
                        <span className="p-float-label">
                            <Calendar
                                value={formValues.to}
                                name="to"
                                id="hasta"
                                minDate={formValues?.from}
                                onChange={(e) => onChangeTo(e)}
                            />
                            <label htmlFor="hasta">Hasta</label>
                        </span>
                    </div>
                </div>

                <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            
                             <InputText
                                type="text"
                                id='code'
                                name="code"
                                onChange={(e) => { onChangeCode(e) }}
                                value={formValues?.code || ""}
                                maxLength={100}
                            />

                            <label htmlFor="dropdown">Por Código</label>
                        </span>
                    </div>
                </div>

                <div className='pt-7 w-full'>
                  <span className="p-float-label w-full">
                    <InputText
                      onBlur={(e)=>{
                        if(formValues?.period && formValues.period.length > 0 && Number(formValues.period) === 0){
                          e.target.value = ''
                          onChangePeriod(e.target.value)
                          return
                        }
                        if(formValues?.period && formValues?.period.length > 0 && formValues?.period.startsWith('0')){
                          e.target.value = e.target.value.replaceAll('0','')
                          if(e.target.value && e.target.value.length > 0 && e.target.value.length < 5){
                            e.target.value = `${'0'.repeat(4-formValues?.period.length)}${e.target.value}`
                          }
                          onChangePeriod(e.target.value)
                          return
                        }
                        if(formValues?.period && formValues?.period.length > 0 && formValues?.period.length < 5){
                          e.target.value = `${'0'.repeat(4-formValues?.period.length)}${formValues?.period}`
                          onChangePeriod(e.target.value)
                        }
                      }}
                      onChange={(e)=>{
                        if(e.target.value && e.target.value.length < 5){
                          onChangePeriod(e.target.value)
                          return;
                        }
                        if(!e.target.value){
                          onChangePeriod(e.target.value)
                        }
                      }}
                      value={formValues?.period || ""}
                      type='number'
                    />
                    <label>Por Periodo</label>
                  </span>
                </div>

                <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Dropdown
                                options={dataMaster?.indicatorSources}
                                optionLabel="description"
                                value={formValues?.source}
                                onChange={(e) => { onChangeSource(e) }}
                                filter
                                showClear
                                filterBy="description"
                                className="w-full"
                            />

                            <label htmlFor="dropdown">Por Origen</label>
                        </span>
                    </div>
                </div>

                <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Dropdown
                                options={dataMaster?.responsibles}
                                optionLabel="fullName"
                                value={formValues?.owner}
                                onChange={(e) => { onChangeOwner(e) }}
                                filter
                                showClear
                                filterBy="fullName"
                                className="w-full"
                            />

                            <label htmlFor="dropdown">Por Responsable</label>
                        </span>
                    </div>
                </div>

                <div className="flex pt-7">
                    <div className="w-full">
                        <span className="p-float-label">
                            <Dropdown
                                options={dataMaster?.indicatorStatus}
                                optionLabel="description"
                                value={formValues?.status}
                                onChange={(e) => { onChangeStatus(e) }}
                                filter
                                showClear
                                filterBy="description"
                                className="w-full"
                            />

                            <label htmlFor="dropdown">Por Estado</label>
                        </span>
                    </div>
                </div>

            </ModalFilterComponent>
        );
    else
        return null;
}

export default AdvancedFilterComponent;