import { ConfigurationConstants } from "features/configuration/commons/ConfigurationConstants";
const initialState = {
  users: {
    currentData: [],
    data: [],
    loading: false,
    pagination: {},
    currentCol: "firstName",
    order: "asc",
  },
  userFilter: {
    showModal: false,
    values: [],
  },
  userEdit: {
    loading: false,
    data: {},
  },
  dataManager: {
    loading: true,
    data: {
      roles: [],
      status: [],
      company: [],
    },
  },
  userActions: {
    loading: false,
    actions: [
      { name: "Editar", icon: "pi pi-pencil" },
      { name: "Eliminar", icon: "pi pi-trash" },
      { name: "Resetear Clave", icon: "pi pi-sync" },
    ],
  },
  saveUser: {
    status: null,
  },
  userDeleteModal: {
    isOpen: false,
    userId: "",
    isLoading: false,
  },
  downloadReport: {
    loading: false,
  },
  currentUser: {
    id: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ConfigurationConstants.Accion.Users.SET_LIST:
      return {
        ...state,
        users: {
          ...state.users,
          ...action.payload,
        },
      };
    case ConfigurationConstants.Accion.Users.GET_DATA_MASTER:
      return {
        ...state,
        dataManager: {
          ...state.dataManager,
          ...action.payload,
        },
      };
    case ConfigurationConstants.Accion.Users.GET_USER:
      return {
        ...state,
        userEdit: {
          ...state.userEdit,
          ...action.payload,
        },
      };
    case ConfigurationConstants.Accion.Users.SAVE_USER_STATUS:
      return {
        ...state,
        saveUser: action.payload,
      };
    case ConfigurationConstants.Accion.Users.SHOW_DELETE_USER_MODAL:
      return {
        ...state,
        userDeleteModal: {
          ...state.userDeleteModal,
          ...action.payload,
        },
      };
    case ConfigurationConstants.Accion.Users.DOWNLOAD_REPORT_STATUS:
      return {
        ...state,
        downloadReport: {
          loading: action.payload,
        },
      };
    case ConfigurationConstants.Accion.Users.CURRENT_USER:
      return {
        ...state,
        currentUser: {
          id: action.payload,
        },
      };
    case ConfigurationConstants.Accion.Users.CHANGE_FILTER_VALUES:
      return {
        ...state,
        userFilter: {
          ...state.userFilter,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
