import { CoreConstants } from 'core/commons/CoreConstants'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Divider } from 'primereact/divider'
import { Dropdown } from 'primereact/dropdown'
import { RadioButton } from 'primereact/radiobutton'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bar, line, gauge } from 'shared/assets/img/images';
import html2pdf  from 'html2pdf.js';
import IndicatorModitoringComponent from './IndicatorModitoringComponent'
import { modes } from 'react-transition-group/SwitchTransition'
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom'
import { ManagmentAction } from 'features/indicators/store/actions/ManagmentAction'
import { Skeleton } from 'primereact/skeleton'

export const IndicatorModalComponent = ({visible, setVisible, dataValues, setDataValues, addIndicator, mode, itemsSelected}) => {

  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const params = useParams()
  const dispatch = useDispatch()
  const [valuesDataTable, setValuesDataTable] = useState(null)
  const [errores, setErrores] = useState([])

  // const [dataValues, setDataValues] = useState(null)


  // const { goalType, goalTypeRange } = useSelector(state => state.monitoringReducer.goal);

  const controlsDataMaster = useSelector((state) => state.managmentReducer.controlsDataMaster.data);
  const valuesTable = useSelector((state) => state.managmentReducer.dataTableIndicator.data);
  const {isLoading} = useSelector((state) => state.managmentReducer.dataTableIndicator);

  const graphicsValues = useSelector(state => state.managmentReducer.dataGraphicsIndicator);

  const getCreationDate = (rowData) => { return moment(rowData.creationDate).format(CoreConstants.Format.LOCALE); }

  const columns = [
    { field: 'creationDate', header: 'Fecha', body: getCreationDate },
    { field: 'medidoPor', header: 'Medido Por' },
    { field: 'value', header: 'Valor' },
    { field: 'meta', header: 'Meta' },
    { field: 'valueMeta', header: 'Valor Meta' },
  ];

  useEffect(()=> {
    if(controlsDataMaster && visible===true){
      if(mode==='new'){
        setDataValues({
          id: uuidv4(),
          containerId: params?.id ? Number(params?.id) : null,
          mode:'new',
          indicatorId: null,
          chart: null,
          modeId: controlsDataMaster?.modes.find(el=> el.name==="Tabla"),
          from: null,
          to: null
        })
        setErrores([])
      }else if(mode==='edit'){
        setErrores([])
        dispatch(ManagmentAction.getdataTableIndicatorById(itemsSelected?.indicatorId?.id))
        setDataValues(itemsSelected)
      }
    }
  },[controlsDataMaster, visible])

  useEffect(()=> {
    if(valuesTable && dataValues){
      let meta = {
        meta: valuesTable.goal?.description,
        valuesMeta: valuesTable.maxGoalValue
      }
      let newData = valuesTable.result?.map(el=> {
        return {
          id: el.id,
          creationDate: el.valueDate,
          mode: el.mode,
          medidoPor: dataValues?.indicatorId?.name,
          value: el.value,
          meta: meta.meta,
          valueMeta: meta.valuesMeta,
          color: el.color
        }
      })
      setValuesDataTable(newData)
    }else{
      setValuesDataTable(null)
    }
  },[valuesTable, dataValues])

  const columnComponents = columns.map(col => {

    if (col.field === 'creationDate')
        return <Column key={col.field} field={col.field} header={col.header} body={getCreationDate} sortable />;

    if (col.field === 'medidoPor'|| col.field === 'meta' || col.field === 'valueMeta')
        return <Column key={col.field} field={col.field} header={col.header}/>;

    return <Column key={col.field} field={col.field} header={col.header} sortable />;
});

  const actionBodyTemplate = (e) => {
    return <div className={`bg-${e.color}-500 rounded-full h-4 w-4`}></div>
  }


const onAddIndicator = () => {
  if(!dataValues?.indicatorId || !dataValues?.modeId){
    setErrores([...errores,{field: "errorIndicator", message: "Seleccione un indicador."}])
    return
  }else{
    //código de gráficos
    if(dataValues?.modeId?.code===CoreConstants.Entity.DashbaordIndicatorModes.CHART){
      if(!dataValues?.chart){
        setErrores([...errores,{field: "errorChart", message: "Seleccione un gráfico."}])
        return
      }
    }
    addIndicator(dataValues)
    setVisible(false)
  }

}

  const footer = (
    <div>
      <Button
      type='button'
      icon='pi pi-times'
      className='sig-button  sig-secondary' label='Cancelar' onClick={()=> {
        dispatch(ManagmentAction.getRestoreDataIndicator())
        setVisible(false)}}/>
      <Button
      type='button'
      icon='pi pi-plus'
      className='sig-button sig-primary' label='Seleccionar' onClick={()=> {
        onAddIndicator()
        dispatch(ManagmentAction.getRestoreDataIndicator())
        }}/>
    </div>
  )


//   const isTypeRange = () => {
//     if (goalType && goalTypeRange) return goalType === goalTypeRange
// }

    const onChangeMode = (e) => {
      if(e.value.code===CoreConstants.Entity.DashbaordIndicatorModes.CHART){
        setErrores(errores.filter(el=>el.field!=='errorChart'))
      }
      setDataValues({...dataValues, modeId: e.value})
    }

    const onChangeChart = (e) => {
      setErrores(errores.filter(el=>el.field!=='errorChart'))
      setDataValues({...dataValues, chart: e.value})
    }

  return (
    <Dialog header={mode==='new'?'Nuevo':'Editar'} visible={visible} style={{ width: '50vw' }} onHide={()=> {
      setVisible(false)
      dispatch(ManagmentAction.getRestoreDataIndicator())}} footer={footer}>
        <div className='grid grid-cols-10 gap-2 items-center'>
          <span className='col-span-4'><p>Seleccione Indicador:</p>
            <Dropdown
              options={controlsDataMaster?.indicators}
              optionLabel='name'
              value={dataValues?.indicatorId}
              className={`${errores.some(elem=> elem.field==='errorIndicator')&&'p-invalid'}`}
              onChange={(e)=> {
                setDataValues({...dataValues, indicatorId: e.value})
                setErrores(errores.filter(el=>el.field!=='errorIndicator'))
                if(dataValues.modeId?.code===CoreConstants.Entity.DashbaordIndicatorModes.TABLE){
                  dispatch(ManagmentAction.getdataTableIndicatorById(e.value.id))
                }else if(dataValues.modeId?.code===CoreConstants.Entity.DashbaordIndicatorModes.CHART){
                  dispatch(ManagmentAction.getdataGraphics(e.value.id,dataValues.from, dataValues.to))
                }
              }}
            />
          </span>
          {
            controlsDataMaster?.modes.map((item) =>
            <span key={item.id} className='col-span-2 mt-4 ml-2'>
              <div className="p-field-radiobutton">
                <RadioButton inputId={item.id} name="mode" value={item} onChange={onChangeMode} checked={dataValues?.modeId?.id === item.id} className="mr-2" />
                <label htmlFor={item.inputId}>{item.name}</label>
              </div>
            </span>)
          }
        </div>
        <div className='mt-5'>
        <div className='text-center mb-4'>{dataValues?.indicatorId?.name}</div>
          {
            dataValues?.modeId?.code===CoreConstants.Entity.DashbaordIndicatorModes.TABLE?<>
              {
                isLoading?<Skeleton borderRadius="16px" width="100%" height="7rem"/>:
                <DataTable
                value={valuesDataTable}
                responsiveLayout="scroll">
                  {columnComponents}
                  <Column header="" body={actionBodyTemplate}/>
                </DataTable>
              }
            </>:<>
            <div className='grid grid-cols-12'>
                    <div className='col-span-3 flex flex-col'>
                      {
                        controlsDataMaster?.charts.map(elem =>
                        <div className='flex flex-col' key={elem.id}>
                          <RadioButton inputId={elem.id}
                          name="selectedChart"
                          value={elem}
                          onChange={onChangeChart}
                          className={`${errores.some(elem=> elem.field==='errorChart')&&'p-invalid'}`}
                          checked={dataValues?.chart?.id=== elem.id} />
                          {
                            elem.code===CoreConstants.Entity.DashbaordIndicatorModes.BAR&&<img src={bar} alt="bar" className='w-48' />
                          }
                          {
                            elem.code===CoreConstants.Entity.DashbaordIndicatorModes.LINE&&<img src={line} alt="line" className='w-48' />
                          }
                          {
                            elem.code===CoreConstants.Entity.DashbaordIndicatorModes.METER&&<img src={gauge} alt="gauge" className='w-48' />
                          }
                        </div>)
                      }
                    </div>
                    <Divider layout='vertical'/>
                    <div className='col-span-8' id='print_indicator_chart'>
                        {/* <div className='flex flex-col justify-center'> */}
                            <div className='flex justify-evenly items-center gap-x-3'>
                                {/* <span className="p-float-label flex-1"> */}
                                    <Calendar
                                        monthNavigator
                                        yearNavigator
                                        placeholder='Desde'
                                        yearRange="1950:2050"
                                        // showIcon={true}
                                        value={dataValues?.from === null ? '' : new Date(dataValues?.from)}
                                        name="from"
                                        onChange={(e) => {
                                          setDataValues({...dataValues, from: e.value})
                                          if(dataValues.modeId?.code===CoreConstants.Entity.DashbaordIndicatorModes.CHART && dataValues.indicatorId && e.value){
                                            dispatch(ManagmentAction.getdataGraphics(dataValues.indicatorId.id,e.value, dataValues.to))
                                          }
                                        }}
                                        onBlur={(e)=> {
                                          if(dataValues.modeId?.code===CoreConstants.Entity.DashbaordIndicatorModes.CHART && dataValues.indicatorId && (!e.target.value || e.target.value==="")){
                                            dispatch(ManagmentAction.getdataGraphics(dataValues.indicatorId.id, null, dataValues.to))
                                          }
                                        }}
                                        className='w-full indicator-calendar flex-1'
                                    ></Calendar>
                                    {/* <label htmlFor="desde">Desde</label> */}
                                {/* </span>
                                <span className="p-float-label flex-1"> */}
                                    <Calendar
                                        monthNavigator
                                        yearNavigator
                                        yearRange="1950:2050"
                                        placeholder='Hasta'
                                        // showIcon={true}
                                        dateFormat={CoreConstants.Format.ddmmyy}
                                        value={dataValues?.to === null ? '' : new Date(dataValues?.to)}
                                        name="to"
                                        minDate={dataValues?.from === null ? '' : new Date(dataValues?.from)}
                                        onChange={(e) => {
                                          setDataValues({...dataValues, to: e.value})
                                          if(dataValues.modeId?.code===CoreConstants.Entity.DashbaordIndicatorModes.CHART && dataValues.indicatorId && e.value){
                                            dispatch(ManagmentAction.getdataGraphics(dataValues.indicatorId.id,dataValues.from, e.value))
                                          }
                                        }}
                                        onBlur={(e)=> {
                                          if(dataValues.modeId?.code===CoreConstants.Entity.DashbaordIndicatorModes.CHART && dataValues.indicatorId && (!e.target.value || e.target.value==="")){
                                            dispatch(ManagmentAction.getdataGraphics(dataValues.indicatorId.id,dataValues.from, null))
                                          }
                                        }}
                                        className='w-auto indicator-calendar flex-1'
                                    ></Calendar>
                                    {/* <p className='indicator-calendar-p flex-1'>10/05/2022</p> */}
                                    {/* <label htmlFor="hasta">Hasta</label> */}
                                {/* </span> */}
                            </div>
                            <div className='w-full mt-5'>
                                {
                                  graphicsValues.isLoading?<Skeleton borderRadius="16px" width="100%" height="18rem" />:<IndicatorModitoringComponent typeChart={dataValues?.chart} />
                                }
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            </>
          }
        </div>
        {
          errores.length>0&&(
          <div className='message error'>
            <ul>
            {
              errores.map((el,index)=> <li key={index} className="p-error">{el.message}</li>)
            }
            </ul>
          </div>)
        }
    </Dialog>
  )
}
