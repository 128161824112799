export const HeaderSheet = ({numberQuestion, question}) => {
  return(
    <div 
      className='flex items-center gap-x-1 header-title'
    >
      <div className="flex items-center gap-1">
        <span dangerouslySetInnerHTML={{ __html: question?.label.replace(/(<[^>]+>)*([^<>\s]+)/, `$1 ${numberQuestion}. $2`) }}></span>
      </div>
      <p className='text-red-500'>{question.isRequired ? '*' : ''}</p>
    </div>
  )
}