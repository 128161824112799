export class MaintenanceConstants {
  static Accion = class {
    //OCCUPATION  CONSTANTS
    static Home = class {
      static get ADVANCED_FILTER_TOGGLE() {
        return '@HOME.ADVANCED-FILTER-TOGGLE';
      }

      static get SEARCH_HOME() {
        return '@HOME.SEARCH_HOME';
      }

      static get UPDATE_FILTER() {
        return '@HOME.UPDATE_FILTER';
      }

      static get GET_HOME_BY_ID() {
        return '@HOME.GET_HOME_BY_ID';
      }

      static get SAVE_HOME() {
        return '@HOME.SAVE_HOME';
      }

      static get DELETE_HOME() {
        return '@HOME.DELETE_HOME';
      }

      static get GET_DATA_MASTER() {
        return '@HOME.GET_DATA_MASTER';
      }

      static get RESET_AFTER_SAVED() {
        return '@HOME.RESET_AFTER_SAVED';
      }

      static get DOWNLOAD_REPORT_STATUS() {
        return '@HOME.DOWNLOAD_REPORT_STATUS';
      }

      static get GET_OPTIONS_DROPDOWN() {
        return '@HOME.GET_OPTIONS_DROPDOWN';
      }

      static get SEARCH_SUMMARY() {
        return '@HOME.SEARCH_SUMMARY';
      }

      static get GET_IDENTIFIERS() {
        return '@HOME.GET_IDENTIFIERS';
      }
    };
  };

  static Constats = class {
    static Home = class {
      static get Asignado() {
        return '1';
      }

      static get Completados() {
        return '2';
      }

      static get EnProgreso() {
        return '3';
      }

      static get PorVencer() {
        return '4';
      }
    }
  }
}
