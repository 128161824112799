import { Dialog } from 'primereact/dialog'
import React, { Fragment, useEffect, useState } from 'react'
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import FilterComponent from './FilterComponent';
import TableComponent from './TableComponent';
import SidebarMenu from './SidebarMenu';
import AdvancedFilterComponent from './AdvacedFilterComponents';
import { useDispatch } from 'react-redux';

import { DocumentActions } from 'features/processManagement/store/actions/DocumentActions';
import { Button } from 'primereact/button';


export const DocumentComponent = ({visible, setVisible, listDocuments, setListDocuments}) => {

  const dispatch = useDispatch();
  const { updateTitle } = useSetTitlePage();
  const [selectedNode, setSelectedNode] = useState('');
  const [selectedFolder, setSelectedFolder] = useState('');
  const [displayModal, setDisplayModal] = useState(false);
  const [displayModalAction, setDisplayModalAction] = useState(false)
  const [documentSelected, setDocumentSelected] = useState(null)
  const [actionSelected, setActionSelected] = useState(null)
  const [selectedDocuments, setSelectedDocuments] = useState([])
  const [errorDocuments, setErrorDocuments] = useState(false)

  // useEffect(() => {
  //     updateTitle({
  //         title: 'Gestión Documental',
  //         subtitle: 'Biblioteca de Documentos',
  //     });
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    dispatch(DocumentActions.isStorageEnabled());
    setSelectedDocuments([])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onHide = () => {
    setVisible(false)
  }

  const addDocuments = () => {
    if(selectedDocuments.length===0){
      setErrorDocuments(true)
      return
    }else{
      setErrorDocuments(false)
      const newList = [...listDocuments]
      selectedDocuments.map(el=> {
        if(listDocuments.some(item=> item.documentId===el.documentId)===false){
          newList.push(el)
        }
        return el
      })
      setListDocuments(newList)
      setSelectedDocuments([])
      onHide()
    }
  }

  const footer = () => {
    return (
      <div className='flex justify-end items-center'>
          <Button type='button' label="Cancelar" className="sig-button sig-secondary" onClick={()=>{
            setSelectedDocuments([])
            onHide()
          }} />
          <Button type='button' label="Aceptar" className='sig-button sig-primary' onClick={addDocuments}/>
      </div>
  );
  }

  return (
    <Dialog header="NUEVO DOCUMENTO" visible={visible} style={{ width: '70vw' }} onHide={onHide} footer={footer}>
      <FilterComponent />
          <div className='grid grid-cols-12 gap-1 h-96 mt-2'>
            <div className='col-span-5 sm:col-span-3 2xl:col-span-2 flex flex-col py-2 px-3 border-r border-gray-300'>
              <SidebarMenu 
                setSelectedNode={setSelectedNode} 
                setDisplayModal={setDisplayModal} 
                selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} 
                setSelectedDocuments={setSelectedDocuments}
              />
            </div>
            <div className='col-span-7 sm:col-span-9 2xl:col-span-10 px-3'>
              <TableComponent
                selectedNode={selectedNode} setSelectedNode={setSelectedNode}
                documentSelected={documentSelected} setDocumentSelected={setDocumentSelected} 
                setDisplayModal={setDisplayModal} 
                setDisplayModalAction={setDisplayModalAction}
                setActionSelected={setActionSelected}
                setSelectedFolder={setSelectedFolder}
                selectedDocuments={selectedDocuments}
                setSelectedDocuments={setSelectedDocuments}
                setErrorDocuments={setErrorDocuments}
              />
            </div>
          </div>
          {
            errorDocuments&&<div className='message error'>
            <ul>
              <li className="p-error">No hay documentos seleccionados.</li>
            </ul>
          </div>
          }
        <AdvancedFilterComponent /> 
    </Dialog>
  )
}
