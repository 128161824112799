import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext'
import { RadioButton } from 'primereact/radiobutton';
import { Ripple } from 'primereact/ripple';
import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'

const numberOfEntries = [
  {name: 'Entrada única', code: 'entry_single'}, 
  {name: 'Entradas multiples', code: 'entry_multiple'},  
]


export const ControllerConectionToProcess = ({control, errors, ValidateInput, validateDuplicate, valuesFields, setValue, dataMaster, inputSelected}) => {

  useEffect(() => {
    if (!inputSelected) {
      setValue('numberOfEntry', numberOfEntries[0])
    }
  }, [setValue])

  const template = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} justify-content-start`;
    const titleClassName = `${options.titleClassName} pl-1`;

    return (
        <div className={className}>
            <span className={titleClassName}>
                Opciones Avanzadas
            </span>
            <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span>
                <Ripple />
            </button>
        </div>
    )
  }

  const onNumberOfEntryChange = (value, onChange) => {
    // setValue('processToConnect', null)
    onChange(value)
  }

  return (
    <>
      <span className="col-span-2 p-float-label w-full mt-4">
          <Controller
              control={control}
              render={({
                  field: { onChange, onBlur, value }
              }) => (
                <InputText
                    type="text"
                    id="fieldLabel"
                    name="fieldLabel"
                    onChange={onChange}
                    autoFocus
                    onBlur={onBlur}
                    value={value}
                    className={errors?.fieldLabel ? 'p-invalid w-full' : 'w-full'}
                />
              )}
              name="fieldLabel"
              rules={{
                  required: 'La etiqueta del campo es requerida.',
                  validate: ValidateInput
              }}
          />
          <label htmlFor="fieldLabel">Etiqueta del campo  *</label>
      </span>

      <span className="col-span-2 p-float-label w-full md:w-1/2 mt-4">
          <Controller
              control={control}
              render={({
                  field: { onChange, onBlur, value }
              }) => (
                <Dropdown 
                  value={value} 
                  options={dataMaster?.processFormControlProcessRecords} 
                  onChange={onChange} 
                  optionLabel="name" 
                  placeholder='Seleccione el proceso que desea conectar'
                />
              )}
              name="processToConnect"
          />
          {/* <label htmlFor="processToConnect">Seleccione la tabla que desea conectar</label> */}
      </span>

      {
        // valuesFields?.numberOfEntry?.code === 'entry_single' ? (
        //   <span className="col-span-2 p-float-label w-full md:w-1/2 mt-4">
        //     <Controller
        //         control={control}
        //         render={({
        //             field: { onChange, onBlur, value }
        //         }) => (
        //           <Dropdown 
        //             value={value} 
        //             options={processes} 
        //             onChange={onChange} 
        //             optionLabel="name" 
        //             placeholder='Seleccione el proceso que desea conectar'
        //           />
        //         )}
        //         name="processToConnect"
        //     />
        //     {/* <label htmlFor="tableToConnect">Seleccione la tabla que desea conectar</label> */}
        //   </span>
        // ) : (
        //   <span className="col-span-2 p-float-label w-full mt-4">
        //     <Controller
        //         control={control}
        //         render={({
        //             field: { onChange, onBlur, value }
        //         }) => (
        //           <div className='w-full'>
        //             <MultiSelect 
        //               value={value} 
        //               options={processes} 
        //               onChange={(e) => onChange(e.value)} 
        //               optionLabel="name" 
        //               maxSelectedLabels={3} 
        //               placeholder='Seleccione el proceso que desea conectar'
        //               style={{width: '50%'}}
        //             />
        //           </div>

        //         )}
        //         name="processToConnect"
        //     />
        //     {/* <label htmlFor="tableToConnect">Seleccione la tabla que desea conectar</label> */}
        //   </span>
        // )
      }
      
      {/* <span className="col-span-2  w-full mt-1">
          <Controller
              control={control}
              render={({
                  field: { onChange, onBlur, value }
              }) => (
                <div className='flex flex-col gap-y-1'>
                    <p>Función de Conexión</p>
                    {
                      connectionsFunction.map((connectionFunction) => {
                          return (
                              <div key={connectionFunction.id} className="field-radiobutton flex gap-x-1 items-center">
                                  <RadioButton 
                                    inputId={connectionFunction.id} 
                                    name="connectionFunction" 
                                    value={connectionFunction} 
                                    onChange={(e) => onChange(e.value)}  
                                    checked={valuesFields.connectionFunction?.id === connectionFunction.id}  
                                  />
                                  <label htmlFor={connectionFunction.id}>{connectionFunction.name}</label>
                              </div>
                          )
                      })
                  }
                </div>
              )}
              name="connectionFunction"
          />
      </span> */}

      <span className="col-span-2  w-full my-1">
          <Controller
              control={control}
              render={({
                  field: { onChange, onBlur, value }
              }) => (
                <div className='flex flex-col gap-y-1'>
                    <p>Número de entradas</p>
                    {
                      numberOfEntries.map((numberOfEntry) => {
                          return (
                              <div key={numberOfEntry.code} className="field-radiobutton flex gap-x-1 items-center">
                                  <RadioButton 
                                    inputId={numberOfEntry.code} 
                                    name="numberOfEntry" 
                                    value={numberOfEntry} 
                                    onChange={(e) => onNumberOfEntryChange(e.value, onChange)}  
                                    checked={valuesFields.numberOfEntry?.code === numberOfEntry.code}  
                                  />
                                  <label htmlFor={numberOfEntry.code}>{numberOfEntry.name}</label>
                              </div>
                          )
                      })
                  }
                </div>
              )}
              name="numberOfEntry"
          />
      </span>

      {/* <Panel headerTemplate={template} toggleable collapsed="true">
        <div className='grid grid-cols-5 justify-items-center items-center'>
          <p className='col-span-4'>La creación de tarjetas conectadas es necesaria para que la tarjeta padre pueda ser finalizada</p>
          <InputSwitch name="Opt1" className='mt-2 col-span-1 content-center'/>
          <p className='col-span-4 mt-2'>Todas las tarjetas conectadas deben ser finalizadas antes de mover esta tarjeta a la siguiente fase</p>
          <InputSwitch name="Opt1" className='mt-2'/>
          <p className='col-span-4 mt-2'>Todas las tarjetas conectadas deben ser finalizadas antes que la tarjeta padre pueda serlo</p>
          <InputSwitch name="Opt1" className='mt-2'/>
          <p className='col-span-4 mt-2'>Autocompletar te permite tener campos</p>
          <InputSwitch name="Opt1" className='mt-2'/>
        </div>
      </Panel> */}

      <div className='col-span-2 p-float-label w-full mt-4 '>
        <div className={`rounded bg-red-200 p-5 mt-6 text-sm text-red-600 ${validateDuplicate===false&&'hidden'}`}>
          <ul>
            <li>La etiqueta del campo, ya se encuentra en uso.</li>
          </ul>
        </div>
      </div>
    </>
  )
}
