import { CoursesManagmentConstants } from "features/coursesManagment/commons/CoursesManagmentConstants";

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  dataMaster: {
    students: [],
    status: [],
    isLoading: false,
  },
  attendance: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false,
  },
  attendances: {
    isLoading: false,
    data: null,
  },
  advancedFilter: {
    showModal: false,
    values: [],
    columnOrder: "name",
    order: "asc",
    page: 1,
    pageSize: 10,
    filter: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CoursesManagmentConstants.Accion.Attendance.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload,
        },
      };

    case CoursesManagmentConstants.Accion.Attendance.UPDATE_FILTER:
      return {
        ...state,
        advancedFilter: state.advancedFilter,
      };

    case CoursesManagmentConstants.Accion.Attendance.SEARCH_ATTENDANCES:
      return {
        ...state,
        attendances: {
          isLoading: action.payload.isLoading,
          data: action.payload.data,
        },
      };

    case CoursesManagmentConstants.Accion.Attendance.GET_ATTENDANCE_BY_ID:
      return {
        ...state,
        attendance: {
          data: action.payload.data,
          isSaving: false,
          isLoading: action.payload.isLoading,
        },
      };

    case CoursesManagmentConstants.Accion.Attendance.SAVE_ATTENDANCE:
      return {
        ...state,
        attendance: {
          data: action.payload.currentAttendance,
          isSaving: action.payload.isSaving,
          isSaved: action.payload.isSaved,
        },
      };

    case CoursesManagmentConstants.Accion.Attendance.DELETE_ATTENDANCE:
      return {
        ...state,
        attendance: {
          isDeleted: action.payload.isDeleted,
        },
      };

    case CoursesManagmentConstants.Accion.Attendance.RESET_AFTER_SAVED:
      return {
        ...state,
        attendance: {
          data: null,
          isSaving: false,
          isSaved: false,
          isLoading: false,
          isDeleted: false,
        },
      };

    case CoursesManagmentConstants.Accion.Attendance.GET_DATA_MASTER:
      return {
        ...state,
        dataMaster: {
          ...state.dataMaster,
          ...action.payload,
        },
      };
    //preguntar
    case CoursesManagmentConstants.Accion.Attendance.DOWNLOAD_REPORT_STATUS:
      return {
        ...state,
        attendance: {
          ...state,
          isLoading: action.payload.isLoading,
        },
      };

    default:
      return state;
  }
};
