import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

export const useClaimSubscription = () => {

  const menu = useSelector((state) => state.authReducer.user.menuPrincipal);
  const pathName = useLocation()?.pathname;
  const [isSearch, setSearch] = useState(false);
  const [isDownload, setDownload] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isCreate, setCreate] = useState(false);
  const [isView, setView] = useState(false);
  const [isBlock, setBlock] = useState(false);
  const [isActivate, setActivate] = useState(false);
  const [isRenew, setRenew] = useState(false);
  const [isCancel, setCancel] = useState(false);

  useEffect(() => {
    search();
    download();
    remove();
    edit();
    create();
    view();
    blocking();
    activate();
    renew();
    cancel();
  }, [menu]);


  const search = () => {
    const actionCode = 'MANAGEMENT_SUBSCRIPTION_SEARCH';
    const optionCode = "GSUBSC";
    setSearch(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
 };

  const download = () => {
    const actionCode = 'MANAGEMENT_SUBSCRIPTION_DOWNLOAD';
    const optionCode = "GSUBSC";
    setDownload(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const remove = () => {
    const actionCode = 'MANAGEMENT_SUBSCRIPTION_DELETE';
    const optionCode = "GSUBSC";
    setDelete(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const edit = () => {
    const actionCode = 'MANAGEMENT_SUBSCRIPTION_EDIT';
    const optionCode = "GSUBSC";
    setEdit(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
 };

  const create = () => {
    const actionCode = 'MANAGEMENT_SUBSCRIPTION_CREATE';
    const optionCode = "GSUBSC";
    setCreate(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  const view = () => {
    const actionCode = 'MANAGEMENT_SUBSCRIPTION_VIEW';
    const optionCode = "GSUBSC";
    setView(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
  };

  //Custom
  const blocking = () => {
    const actionCode = 'MANAGEMENT_SUBSCRIPTION_LOCK';
    const optionCode = "GSUBSC";
    setBlock(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))
    
  };

  const activate = () => {
    const actionCode = 'MANAGEMENT_SUBSCRIPTION_ACTIVATE';
    const optionCode = "GSUBSC";
    setActivate(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))

  };

  const renew = () => {
    const actionCode = 'MANAGEMENT_SUBSCRIPTION_RENEW';
    const optionCode = "GSUBSC";
    setRenew(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))

  };

  const cancel = () => {
    const actionCode = 'MANAGEMENT_SUBSCRIPTION_CANCEL';
    const optionCode = "GSUBSC";
    setCancel(menu.some(p => p.subMenus.some(c => c.codigo === optionCode && c.acciones.some(y => y.codigo === actionCode))))

  };

  return {
    isSearch,
    isDownload,
    isCreate,
    isDelete,
    isEdit,
    isView,
    isBlock,
    isActivate,
    isRenew,
    isCancel
  }
}