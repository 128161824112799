import { ModalNodes, OrgChart } from 'features/rrhh/components/OrganizationChart';
import { useClaimOrganizationChart } from 'features/rrhh/hooks/useClaimOrganizationChart';
import { Button } from 'primereact/button';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import useSetTitlePage from 'shared/hooks/useSetTitlePage';
import html2pdf from "html2pdf.js";
import { useDispatch } from 'react-redux';
import { OrganizationChartAction } from 'features/rrhh/store/actions/OrganizationChartAction';
import './SearchOrganizationChart.scss'

const SearchOrganizationChart = () => {

  const { updateTitle } = useSetTitlePage();
  const [displayModalNodes, setDisplayModalNodes] = useState(false)
  const [nodeSelected, setNodeSelected] = useState(null)
  const [isDownloading, setIsDownloading] = useState(false)
  const [scale, setScale] = useState('scale(1, 1)');
  const dispatch = useDispatch();

  const map = useRef()
  const toPrint = useRef()

  useEffect(() => {
    updateTitle({
        title: 'Recursos Humanos',
        subtitle: 'Organigrama',
    });
    dispatch(OrganizationChartAction.getOrganizationChart());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isCreate, isDownload } = useClaimOrganizationChart()

  const imprimir = () => {
    setIsDownloading(true)
    const maxWidth = 1030;
    const maxHeight = 700
    const currentHeight = map.current?.offsetHeight
    const currentWidth = map.current?.offsetWidth
    const scaleX = maxWidth/currentWidth < 1 ? maxWidth/currentWidth : 1
    const scaleY = maxHeight/currentHeight < 1 ? maxHeight/currentHeight : 1
    const scale = `scale(${scaleX}, ${scaleY})`
    setScale(scale)

    setTimeout(()=>{
      const element = toPrint.current
      html2pdf().set({
        margin: 1,
        filename: `Organigrama`,
        image: {
            type: 'jpeg',
            quality: 0.98
        },
        html2canvas: {
            scale: 3, // A mayor escala, mejores gráficos, pero más peso
            letterRendering: true,
        },
        jsPDF: {
            unit: "cm",
            format: "a4",
            orientation: 'landscape' // landscape o portrait
        }
      })
      .from(element)
      .save()
      .then((res) => {
        setIsDownloading(false)
      })
      .catch(err => console.log(err))
      .finally(() => {
        setIsDownloading(false)
      })
    }, 200)
  }

  return (
    <Fragment>
      <div className='flex flex-col'>
        <div className='flex gap-x-3'>
          {
            isCreate&&(
              <Button
                  icon="pi pi-plus"
                  type="button"
                  label="Nuevo"
                  className="sig-button sig-dark"
                  onClick={() => setDisplayModalNodes(true)}
                  loading={isDownloading}
              />
            )
          }
          {
            isDownload&&(
              <Button
                  type="button"
                  className='sig-button sig-success' 
                  label="Descargar" 
                  icon="pi pi-download" 
                  onClick={imprimir}
                  loading={isDownloading}
              />
            )
          }
        </div>
        <div className='mt-2 w-min' ref={map}>
          <OrgChart setNodeSelected={setNodeSelected} setDisplayModal={setDisplayModalNodes} />
        </div>
      </div>
      <div className={isDownloading ? 'mt-40' : ''}>
      {
        isDownloading &&  
        <div className='w-min organization_chart_download' style={{transformOrigin: 'top left', transform: scale}} ref={toPrint}>
          <OrgChart setNodeSelected={setNodeSelected} setDisplayModal={setDisplayModalNodes} />
        </div>
      }
      </div>

      <ModalNodes 
        displayModal={displayModalNodes}  setDisplayModal={setDisplayModalNodes} 
        nodeSelected={nodeSelected} setNodeSelected={setNodeSelected}
      />
    </Fragment>
  )
}

export default SearchOrganizationChart