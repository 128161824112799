/* eslint-disable import/no-anonymous-default-export */

import { IndicatorsConstants } from "features/indicators/commons/IndicatorsConstans";

const initialState = {
  dataMaster: {
    isLoading: false,
    indicatorFrecuency: [],
    indicatorGoal: [],
    indicatorSources: [],
    indicatorType: [],
    indicatorUnitMeasure: [],
    responsibles: [],
    indicatorStatus: [],
    processes: [],
    dynamicFields: [],
  },
  indicator: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false
  },
  indicatorCopy: {
    data: null,
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false
  },
  indicators: {
    isLoading: false,
    data: []
  },
  unitMeasure: {
    isLoading: false,
    data: []
  },
  version: {
    isSaving: false,
    isSaved: false,
  },
  versions: {
    isLoading: false,
    data: []
  },
  advancedFilter: {
    showModal: false,
    values: [],
    // columnOrder: 'name',
    columnOrder: 'code',
    order: 'asc',
    page: 1,
    pageSize: 10,
    filter: {}
  },
  bulkLoad: {
    data: null,
    isLoading: false,
  },
  bulkLoadErrors: [],
  indicatorBulk: {
    isSaved: false,
  },
  indicatorValues: {
    data: [],
    isSaving: false,
    isSaved: false,
    isDeleted: false,
    isLoading: false
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case IndicatorsConstants.Accion.Indicators.ADVANCED_FILTER_TOGGLE:
      return {
        ...state,
        advancedFilter: {
          ...state.advancedFilter,
          ...action.payload
        }
      }
      case IndicatorsConstants.Accion.Indicators.UPDATE_FILTER:
        return {
            ...state,
            advancedFilter: state.advancedFilter
        };

    case IndicatorsConstants.Accion.Indicators.SEARCH_INDICATORS:
        return {
            ...state,
            indicators: {
                isLoading: action.payload.isLoading,
                data: action.payload.data
            }
        };
    case IndicatorsConstants.Accion.Indicators.GET_INDICATOR_BY_ID:
      return {
          ...state,
          indicator: {
              data: action.payload.data,
              isLoading: action.payload.isLoading
          }
        };

    case IndicatorsConstants.Accion.Indicators.SAVE_INDICATOR:
        return {
            ...state,
            indicator: {
                data: action.payload.currentEmployer,
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };

    case IndicatorsConstants.Accion.Indicators.DELETE_INDICATOR:
        return {
            ...state,
            indicator: {
                isDeleted: action.payload.isDeleted
            }
        };

    case IndicatorsConstants.Accion.Indicators.RESET_AFTER_SAVED:
        return {
            ...state,
            indicator: {
                data: null,
                isSaving: false,
                isSaved: false,
                isLoading: false,
                isDeleted: false
            },
            indicatorCopy: {
                data: null,
                isSaving: false,
                isSaved: false,
                isLoading: false,
                isDeleted: false
            },
            versions: {
              isLoading: false,
              data: []
            },
            version: {
              isSaving: false,
              isSaved: false,
            },
            bulkLoad: {
              data: null,
              isLoading: false,
            },
            bulkLoadErrors: [],
            indicatorBulk: {
                isSaved: false,
            },
            indicatorValues: {
              data: [],
              isSaving: false,
              isSaved: false,
              isDeleted: false,
              isLoading: false
            },
        };

    case IndicatorsConstants.Accion.Indicators.GET_DATA_MASTER:
        return {
            ...state,
            dataMaster: {
                isLoading: action.payload.isLoading,
                indicatorFrecuency: action.payload.indicatorFrecuency,
                indicatorGoal: action.payload.indicatorGoal,
                indicatorSources: action.payload.indicatorSources,
                indicatorType: action.payload.indicatorType,
                indicatorUnitMeasure: action.payload.indicatorUnitMeasure,
                responsibles: action.payload.responsibles,
                indicatorStatus: action.payload.indicatorStatus,
                processes: action.payload.processes,
                dynamicFields: action.payload.dynamicFields,
            }
        };
      case IndicatorsConstants.Accion.Indicators.DOWNLOAD_REPORT_STATUS:
          return {
              ...state,
              indicator: {
                  ...state,
                  isLoading: action.payload.isLoading,
              },
          };
      case IndicatorsConstants.Accion.Indicators.GET_UNIT_MEASURE:
        return {
            ...state,
            unitMeasure: {
                data: action.payload.data,
                isLoading: action.payload.isLoading
            }
          };
      case IndicatorsConstants.Accion.Indicators.GET_VERSIONS:
        return {
            ...state,
            versions: {
                data: action.payload.data,
                isLoading: action.payload.isLoading
            }
          };
        case IndicatorsConstants.Accion.Indicators.SAVE_AND_GENERATE_VERSION:
          return {
              ...state,
              version: {
                  isSaving: action.payload.isSaving,
                  isSaved: action.payload.isSaved
              }
          };
        case IndicatorsConstants.Accion.Indicators.GET_INDICATOR_BY_VERSION:
          return {
              ...state,
              indicator: {
                  data: action.payload.data,
                  isLoading: action.payload.isLoading
              }
            };

        case IndicatorsConstants.Accion.Indicators.COPY_INDICATOR:
          return {
              ...state,
              indicatorCopy: {
                  data: action.payload.data,
                  isLoading: action.payload.isLoading
              }
            };


        case IndicatorsConstants.Accion.Indicators.UPLOAD_BATCH_INFO:
          return {
            ...state,
            bulkLoad: {
              data: action.payload.data,
              isLoading: action.payload.isLoading,
            },
            bulkLoadErrors: action.payload.errors,
          };

        case IndicatorsConstants.Accion.Indicators.SAVE_INDICATOR_BULK:
          return {
            ...state,
            indicatorBulk: {
                isSaved: action.payload.isSaved,
            }
        };

        case IndicatorsConstants.Accion.Indicators.SAVE_INDICATOR_VALUES:
        return {
            ...state,
            indicatorValues: {
                isSaving: action.payload.isSaving,
                isSaved: action.payload.isSaved
            }
        };

      default: 
      return state;
  }

}