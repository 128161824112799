import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

export const useQuestionsTemplate = () => {
  const [newQuestionsTemplate, setNewQuestionsTemplate] = useState({})
  const dataMaster = useSelector(state => state.businessSheetReducer.dataMaster.data)

  useEffect( () => {
    if(dataMaster?.businessSheetLinkedField?.length > 0){
      const template = {
        LISTA_DESPLEGABLE: {
          type: "DROPDOWN",
          id: 0,
          fieldLabel: "",
          required: false,
          options: [],
          newQuestion: true,
          sectionId: null,
        },
        SELECCION_MULTIPLE: {
          type: "CHECKBOX",
          id: 0,
          fieldLabel: "",
          required: false,
          options: [],
          newQuestion: true,
          sectionId: null,
        },
        SELECCION_SIMPLE: {
          type: "RADIOBUTTON",
          id: 0,
          fieldLabel: "",
          required: false,
          options: [],
          newQuestion: true,
          sectionId: null,
        },
        TEXTO: {
          type: "INPUTTEXT",
          id: 0,
          fieldLabel: "",
          required: false,
          newQuestion: true,
          linkTo: null,
          optionsLinkTo: dataMaster?.businessSheetLinkedField,
          sectionId: null,
        },
        TABLA: {
          type: "DATATABLE",
          id: 0,
          fieldLabel: "",
          required: false,
          allowAddRows: false,
          fixFirstColumn: false,
          columns: [],
          rows: [],
          newQuestion: true,
          isContactList: false,
          sectionId: null,
        },
        ADJUNTAR_ARCHIVO: {
          type: "ATTACHMENT",
          id: 0,
          fieldLabel: "",
          required: false,
          newQuestion: true,
          multiple: false,
          sectionId: null,
        },
      }
      setNewQuestionsTemplate(template)
    }
  },[dataMaster?.businessSheetLinkedField])

  return {
    newQuestionsTemplate,
  }
}